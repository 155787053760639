export enum AnalyticsGeneralEvent {
  TopMenuOpened = 'top_menu_opened',
  BottomMenuOpened = 'bottom_menu_opened',
}

export enum TopTabType {
  All = 'All',
  Chats = 'Chats',
  Channels = 'Channels',
  Spaces = 'Spaces',
}

export enum TabType {
  Hub = 'Hub',
  Feed = 'Feed',
  Catalog = 'Catalog',
}
