import React, { Component } from 'react';
import ReactDOM from 'react-dom';

interface IPortalProps {
  children: React.ReactNode;
}

export class Portal extends Component<IPortalProps> {
  container: HTMLDivElement;

  constructor(props: IPortalProps) {
    super(props);
    this.container = document.createElement('div');
    document.body.appendChild(this.container);
  }

  componentWillUnmount() {
    document.body.removeChild(this.container);
  }

  render() {
    const { children } = this.props;

    return ReactDOM.createPortal(children, this.container);
  }
}
