import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import Tasks from 'APP/Tasks';
import { useTranslation } from 'APP/packages/translations';
import { RouterPage } from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';

export default ({ core }) => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const navigateTo = useNavigateTo();
  const history = useHistory();
  const source = useAsObservableSource({
    core,
    navigateTo,
  });

  const presenter = useLocalStore(() => ({
    get core() {
      return source.core;
    },

    onCloseArticle() {
      if (!(core.hasSomethingData && core.payloads.isChanged)) {
        return presenter.goBack();
      }

      if (source.core.isEditMode) {
        presenter.showEditCloseConfirm();
      } else {
        presenter.showDraftCloseConfirm();
      }
    },

    async showEditCloseConfirm() {
      const canGoBack = await Tasks.app.showConfirm({
        title: t('channels:channel_article_not_to_be_saved'),
        text: t('channels:channel_article_confirm_leaving'),
        primaryText: t('common:common_leave'),
        secondaryText: t('common_cancel'),
      });

      if (canGoBack) {
        presenter.goBack();
      }
    },

    showDraftCloseConfirm() {
      Tasks.app.showConfirmWithCloseBtn({
        title: t('channels:channel_article_save_as_draft'),
        text: t('channels:channel_article_save_as_draft_info'),
        primaryText: t('common_save'),
        secondaryText: t('common:common_discard'),
        async onSuccess() {
          await source.core.handleCreateDraft();
          source.navigateTo({
            to: match.url.replace('/article', '/article-drafts'),
          });
        },
        onCancel() {
          presenter.goBack();
        },
      });
    },

    goBack() {
      if (!source.core.isEditMode) {
        navigateTo({ to: `/${RouterPage.Channels}/${core.group.id}` });
      } else {
        history.goBack();
      }
    },
  }));

  return presenter;
};
