import classNames from 'classnames';
import React from 'react';

import CloseIcon from 'ICONS/ic-close.svg';
import { IconButton } from 'UIKIT/IconButton';

import styles from './BottomPopup.styles.m.scss';

interface IBottomPopupProps {
  className?: string;
  onClose?(): void;
  children?: React.ReactNode;
}
export const BottomPopup: React.FC<IBottomPopupProps> = (props) => {
  const { onClose, className, children } = props;

  return (
    <div className={classNames(styles.container, className)}>
      <IconButton
        className={styles.close}
        Icon={CloseIcon}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onClick={onClose}
      />
      {children}
    </div>
  );
};

