import classNames from 'classnames';
import React from 'react';

import { IconView } from 'APP/packages/UiKit/IconView/IconView';
import MicOff from 'ICONS/ic-mic-off-16.svg';

import styles from './styles.m.css';

const MutedBadge = ({ className }) => {
  return (
    <div className={classNames(styles.iconContainer, className)}>
      <IconView
        Icon={MicOff}
        size="small"
        className={styles.icon}
      />
    </div>
  );
};

export default MutedBadge;
