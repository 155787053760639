import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { useTranslation } from 'APP/packages/translations';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { ChannelForm } from '../../components/ChannelForm/ChannelForm';
import { useCloseFormPopup } from '../../hooks/useCloseFormPopup';
import {
  ICreateSpaceChannelPopupParams,
  useCreateSpaceChannelPopupPresenter,
} from './CreateSpaceChannelPopup.presenter';

export const CreateSpaceChannelPopup: FC<ICommonPopupsProps<ICreateSpaceChannelPopupParams>> =
  observer((props) => {
    const { t } = useTranslation();
    const presenter = useCreateSpaceChannelPopupPresenter(props);
    const closeForm = useCloseFormPopup(props, t('cancel_channel_creation_alert'));

    return (
      <Popup
        isOpened
        onClose={closeForm.closePopup}
      >
        <ChannelForm
          title={t('new_channel')}
          initialValues={presenter.initialValues}
          onSubmit={presenter.onSubmit}
          onFormStateChange={closeForm.onFormStateChange}
          onClose={closeForm.closePopup}
        />
      </Popup>
    );
  });
