import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { SendMessageStatus } from 'APP/model/message/messageModel.types';
import { IconView, IconViewSizes } from 'APP/packages/UiKit/IconView/IconView';
import SeenIcon from 'ICONS/ic-s-read-done-16.svg';
import SendingIcon from 'ICONS/ic-s-time.svg';
import ErrorIcon from 'ICONS/message-error.svg';
import SentIcon from 'ICONS/sent.svg';
import { Group } from 'STORE/Groups/Group';

import { useGroupStatusIconPresenter } from './GroupStatusIcon.presenter';
import styles from './GroupStatusIcon.style.m.css';

const ICONS = {
  [SendMessageStatus.Failed]: ErrorIcon,
  [SendMessageStatus.Seen]: SeenIcon,
  [SendMessageStatus.Sending]: SendingIcon,
  [SendMessageStatus.Sent]: SentIcon,
};

interface IGroupStatusIconProps {
  group: Group;
}

export const GroupStatusIcon: FC<IGroupStatusIconProps> = observer(({ group }) => {
  const presenter = useGroupStatusIconPresenter(group);

  return (
    <IconView
      className={classNames(styles.icon, styles[presenter.status || ''])}
      Icon={presenter.status ? ICONS[presenter.status] : null}
      size={IconViewSizes.Small}
    />
  );
});
