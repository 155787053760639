import classNames from 'classnames';
import React from 'react';

import { MultiLineField as BaseMultiLineField } from 'UIKIT/MultiLineField/MultiLineField';

import HelperText from './HelperText';
import styles from './styles.m.css';

const MultiLineField = ({
  className,
  input: { name, onChange, value, onFocus, onBlur },
  meta: { active, error, invalid, modified, validating },
  label,
  placeholder,
  maxLength,
  isDisabled = false,
  helperText,
  setRef,
  autoFocus,
}) => {
  const hasError = !validating && modified && invalid && Boolean(error.trim());

  return (
    <BaseMultiLineField
      className={classNames(styles.container, className)}
      name={name}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      active={active}
      value={value}
      label={label}
      placeholder={placeholder}
      maxLength={maxLength}
      hasError={hasError}
      setRef={setRef}
      autoFocus={autoFocus}
      isDisabled={isDisabled}
    >
      <HelperText
        hasError={hasError}
        errorText={error}
        helperText={helperText}
      />
    </BaseMultiLineField>
  );
};

export default MultiLineField;
