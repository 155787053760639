import Tasks from 'APP/Tasks';
import { ServerErrorCode } from 'APP/model/error/error.constants';
import { ModelError } from 'APP/model/error/errors/ModelError';
import { t } from 'MODULE/i18n/service';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';
import { MessageError } from 'STORE/Messages/Message/ChatMessage/ChatMessages.types';

interface IHandleMessageErrorData {
  message: ChatMessage;
  error: ModelError;
  messageErrorType: MessageError;
}

const TOAST_SHOW_DURATION = 5000;

export const handleMessageError = (data: IHandleMessageErrorData): void => {
  const { message, error, messageErrorType } = data;

  if (error.message === ServerErrorCode.FilterTriggered) {
    Tasks.app.showToast(t('сontent_filtering_error'), { durationMs: TOAST_SHOW_DURATION });
  }

  message.setError(messageErrorType);
};
