import { IUserDeviceResponse } from 'APP/model/session/sessionModel.types';
import Entities from 'STORE';

export async function handleAddSession(device: IUserDeviceResponse): Promise<void> {
  if (Entities.sessionsStore.firstTimeLoaded) {
    Entities.sessionsStore.addSession(device);
  }
}

export async function handleRemoveSession(registrationKey: string): Promise<void> {
  if (Entities.sessionsStore.firstTimeLoaded) {
    Entities.sessionsStore.removeSession(registrationKey);
  }
}
