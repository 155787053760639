import React, { FC, memo } from 'react';

import { FAB } from 'APP/packages/UiKit/FAB/FAB';
import { useTranslation } from 'APP/packages/translations';
import { Popup } from 'APP/store/App/Popups/Popup/Popup';
import PhoneIcon from 'ICONS/ic-call.svg';
import EditIcon from 'ICONS/ic-edit.svg';
import popupItemStyles from 'UIKIT/PopupItem/styles.m.scss';

import { ProviderLayout } from '../ProviderLayout/ProviderLayout';
import { usePhoneProviderPresenter } from './PhoneProvider.presenter';
import styles from './PhoneProvider.styles.m.css';
import { PhoneProviderAddPhone } from './PhoneProviderAddPhone/PhoneProviderAddPhone';

interface IPhoneProviderProps {
  displayName: string;
  userId: string;
  popupInstance: Popup;
}

export const PhoneProvider: FC<IPhoneProviderProps> = memo((props) => {
  const { displayName, popupInstance } = props;

  const { t } = useTranslation();
  const presenter = usePhoneProviderPresenter({ popupInstance, phoneNumber: displayName });

  return (
    <div className={popupItemStyles.container}>
      <ProviderLayout
        icon={<PhoneIcon className={styles.icon} />}
        title={t('account_phone_number')}
        description={displayName || <PhoneProviderAddPhone onClick={presenter.onAddPhoneNumber} />}
        actions={
          displayName ? (
            <FAB
              size="20"
              theme="3"
              className={styles.editIcon}
              Icon={EditIcon}
              onClick={presenter.onChangeNumber}
            />
          ) : null
        }
      />
    </div>
  );
});
