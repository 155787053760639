import { action, observable } from 'mobx';

import { UnauthorizedCallType } from 'APP/model/unauthorizedCall/unauthorizedCallModel.constants';
import { ICallInvitationInfo } from 'APP/model/unauthorizedCall/unauthorizedCallModel.types';

export class CallInvitation {
  @observable entityId?: string;
  @observable invitationInfo?: ICallInvitationInfo;
  @observable selfName?: string;
  @observable callType?: UnauthorizedCallType;
  @observable channelId: string | null;

  @action setEntityId(id: string): void {
    this.entityId = id;
  }

  @action setChannelId(id: string | null): void {
    this.channelId = id;
  }

  @action setCallType(type: UnauthorizedCallType): void {
    this.callType = type;
  }

  @action setSelfName(name: string): void {
    this.selfName = name;
  }

  @action setInvitationInfo(info: ICallInvitationInfo): void {
    this.invitationInfo = {
      title: info.title,
      description: info.description,
      imageUrl: info.imageUrl,
      userName: info.userName,
      inviteLink: info.inviteLink,
      groupName: info.groupName,
    };
  }

  @action reset(): void {
    this.invitationInfo = undefined;
    this.entityId = undefined;
    this.callType = undefined;
    this.channelId = null;
  }
}
