import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';

import secondsToString from 'APP/packages/date/helpers/secondsToString';
import { Call } from 'STORE/Calls/Call/Call';

import styles from './CallTime.styles.m.css';

interface ICallTimeProps {
  call: Call;
}

export const CallTime: FC<ICallTimeProps> = observer(({ call }) => {
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    function calculateSeconds(startTs: number): number {
      return Math.floor((Date.now() - startTs) / 1000);
    }

    if (call.startTs) {
      setSeconds(calculateSeconds(call.startTs));
    }

    const timer = setInterval(() => {
      if (call.startTs) {
        setSeconds(calculateSeconds(call.startTs));
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [call.startTs]);

  if (!call) {
    return null;
  }

  return <div className={styles.time}>{secondsToString(seconds)}</div>;
});
