import { observer } from 'mobx-react';
import React from 'react';

import { OnboardingType } from 'APP/constants/onboarding';
import { useOnboardingManager } from 'APP/main/hooks/onboardingManager/useOnboardingManager';
import { useTranslation } from 'APP/packages/translations';
import ArticleOnboardingIcon from 'ICONS/article-onboarding.svg';
import { OnboardingPopover } from 'UIKIT/OnboardingPopover/OnboardingPopover';

const FirstArticleOnboarding = ({ reference }) => {
  const { t } = useTranslation();
  const presenter = useOnboardingManager(OnboardingType.CreateFirstArticle);

  if (!presenter.isNeedToShow) {
    return null;
  }

  return (
    <OnboardingPopover
      title={t('channels:channel_article_first_article_title')}
      description={t('channels:channel_article_first_article')}
      Icon={ArticleOnboardingIcon}
      handleClose={presenter.handleClose}
      reference={reference}
      isOpened={presenter.isOpened}
    />
  );
};

export default observer(FirstArticleOnboarding);
