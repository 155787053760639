import { useLocalStore, useAsObservableSource } from 'mobx-react';
import { useRef, RefObject, useLayoutEffect } from 'react';

import Tasks from 'APP/Tasks';
import Entities from 'STORE';
import { GlobalVideoPlayerType } from 'STORE/GlobalVideoPlayer/GlobalVideoPlayer.types';
import type { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

interface ICircleVideoPayloadData {
  message: ChatMessage;
  isView: boolean;
}

interface ICircleVideoPayloadPresenter {
  isStarted: boolean;
  isPlaying: boolean;
  isVideoReady: boolean;
  isShowPreview: boolean;
  onPlay(): void;
  onStop(): void;
  onTogglePlay(): void;
}

interface IUseCircleVideoPayloadPresenter {
  containerRef: RefObject<HTMLDivElement>;
  presenter: ICircleVideoPayloadPresenter;
}

export const useCircleVideoPayloadPresenter = (
  data: ICircleVideoPayloadData
): IUseCircleVideoPayloadPresenter => {
  const { message, isView } = data;

  const containerRef = useRef<HTMLDivElement>(null);
  const source = useAsObservableSource({ message, isView });

  const presenter = useLocalStore<ICircleVideoPayloadPresenter>(() => ({
    get isStarted(): boolean {
      return (
        Entities.miniPlayer.payloadId === source.message.id ||
        Entities.miniPlayer.payloadId === source.message.clientUuid
      );
    },

    get isPlaying(): boolean {
      return presenter.isStarted && Entities.miniPlayer.isPlaying;
    },

    get isVideoReady(): boolean {
      return presenter.isStarted && Entities.GlobalVideoPlayer.isFirstFrameLoaded;
    },

    get isShowPreview(): boolean {
      return !source.isView || !presenter.isVideoReady;
    },

    onPlay(): void {
      if (!presenter.isStarted) {
        if (Entities.GlobalVideoPlayer.isOpened) {
          Tasks.globalVideoPlayer.closeVideoPlayer();
        }

        Tasks.miniPlayer.openMiniPlayer(
          source.message.groupId,
          source.message.id,
          source.message.clientUuid
        );

        return;
      }

      Entities.GlobalVideoPlayer.setPlaying(true);
    },

    onStop(): void {
      if (!presenter.isStarted) {
        return;
      }

      Entities.GlobalVideoPlayer.setPlaying(false);
    },

    onTogglePlay(): void {
      if (presenter.isPlaying) {
        presenter.onStop();
      } else {
        presenter.onPlay();
      }
    },
  }));

  useLayoutEffect(() => {
    if (presenter.isStarted) {
      const type = isView ? GlobalVideoPlayerType.Round : GlobalVideoPlayerType.MiniRound;

      if (type !== Entities.GlobalVideoPlayer.type) {
        Entities.GlobalVideoPlayer.setContainer(isView ? containerRef.current : null);
        Entities.GlobalVideoPlayer.setType(
          isView ? GlobalVideoPlayerType.Round : GlobalVideoPlayerType.MiniRound
        );
      }
    }
  }, [isView, presenter.isStarted, Entities.GlobalVideoPlayer.type]);

  useLayoutEffect(() => {
    return () => {
      if (presenter.isStarted) {
        Entities.GlobalVideoPlayer.setContainer(null);
        Entities.GlobalVideoPlayer.setType(GlobalVideoPlayerType.MiniRound);
      }
    };
  }, []);

  return { containerRef, presenter };
};
