import Tasks from 'APP/Tasks';
import { getMessagesBetweenTs } from 'APP/model/message/messageModel';
import { IMessage } from 'APP/model/message/messageModel.types';
import Entities from 'APP/store';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

export async function updateMessagesBetweenTs(
  groupId: string,
  startTs: number,
  endTs: number
): Promise<void> {
  try {
    const messages: IMessage[] = await getMessagesBetweenTs(groupId, startTs, endTs);

    const group = Entities.GroupsStore.getGroupById(groupId);
    if (group) {
      const storedMessages = group.messagesStore.getMessagesBetweenTs(
        startTs,
        endTs
      ) as ChatMessage[];
      // Remove messages from store which aren't returned from server
      const needRemoveMessages = storedMessages.filter(
        (message) => !messages.find(({ id }) => message.id === id && !message.isFake)
      );

      group.messagesStore.handleRemoveMessages(needRemoveMessages);
    }

    if (messages?.length) {
      await Tasks.relationships.loadUnknownDataFromMessages({ messages }, false);

      Tasks.messaging.updateOldOrFakeMessages({
        messages,
        groupId,
      });
    }
  } catch (error) {
    //
  }
}
