import React, { ElementType, FC, memo } from 'react';

import { EmojiCategoryName } from 'APP/constants/emojis';
import NatureIcon from 'ICONS/emoji/animal-new.svg';
import EmotionsIcon from 'ICONS/emoji/emotion-new.svg';
import FoodIcon from 'ICONS/emoji/food-new.svg';
import ObjectsIcon from 'ICONS/emoji/object-new.svg';
import ActivitiesIcon from 'ICONS/emoji/sport-new.svg';
import SymbolsIcon from 'ICONS/emoji/symbol-new.svg';
import TravelIcon from 'ICONS/emoji/travelling-new.svg';
import { FAB } from 'UIKIT/FAB/FAB';

import styles from './EmojiCategory.styles.m.css';

const iconByCategory: Record<EmojiCategoryName, ElementType<SVGAElement>> = {
  [EmojiCategoryName.Emotions]: EmotionsIcon,
  [EmojiCategoryName.Nature]: NatureIcon,
  [EmojiCategoryName.Food]: FoodIcon,
  [EmojiCategoryName.Travel]: TravelIcon,
  [EmojiCategoryName.Activities]: ActivitiesIcon,
  [EmojiCategoryName.Objects]: ObjectsIcon,
  [EmojiCategoryName.Symbols]: SymbolsIcon,
};

interface IEmojiCategoryProps {
  category: EmojiCategoryName;
  isActive: boolean;
  onEmojiCategoryClick(category: EmojiCategoryName): void;
}

export const EmojiCategory: FC<IEmojiCategoryProps> = memo(
  ({ category, isActive, onEmojiCategoryClick }) => {
    const theme = isActive ? '16' : '15';

    const handleClick = (): void => onEmojiCategoryClick(category);

    return (
      <FAB
        className={styles.button}
        Icon={iconByCategory[category]}
        size="30"
        theme={theme}
        onClick={handleClick}
      />
    );
  }
);
