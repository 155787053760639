import { FC, MutableRefObject, useEffect, useRef } from 'react';

import { NewsType } from 'APP/model/catalog/catalogModel.types';
import bus from 'APP/packages/bus';
import { GuestPost } from 'STORE/Newsfeed/NewsItems/GuestPost';
import { Post } from 'STORE/Newsfeed/NewsItems/Post';
import { TNewsItem } from 'STORE/Newsfeed/Newsfeed.types';
import { debounce } from 'UTILS/debounce';

import { INewsfeedContentItem } from './NewsfeedContent.types';
import { NewsfeedPostItem } from './NewsfeedPostItem/NewsfeedPostItem';
import Personalize from './Personalize';
import SuggestedChannels from './SuggestedChannels';

export interface INewsfeedContentPresenterParams {
  readonly news: TNewsItem[];
  readonly scrollPosition?: number;
  readonly scrollRef?: MutableRefObject<HTMLDivElement>;
  onScroll?(offset: number): void;
}

export interface INewsfeedContentPresenter {
  readonly items: INewsfeedContentItem[];
  readonly handleScroll: () => void;
  getKey(news: TNewsItem, position: number): string;
  getComponent(news: TNewsItem): FC | null;
  setScrollPosition(scrollPosition: number): void;
}

function isPost(news: TNewsItem): news is Post {
  return news instanceof Post || news instanceof GuestPost;
}

export const useNewsfeedContentPresenter = (data: INewsfeedContentPresenterParams) => {
  const scrollRef = useRef() as MutableRefObject<HTMLDivElement>;
  const { news, scrollPosition, onScroll } = data;

  const presenter: INewsfeedContentPresenter = {
    get items(): INewsfeedContentItem[] {
      const list: INewsfeedContentItem[] = [];
      news.forEach((item: TNewsItem, position: number) => {
        const component = presenter.getComponent(item);
        if (item.isVisible && component) {
          list.push({
            Component: component,
            key: presenter.getKey(item, position),
            newsItem: item,
            position,
          });
        }
      });

      return list;
    },

    getKey(news: TNewsItem, position: number): string {
      if (isPost(news)) {
        return `${news.type}-${news.groupId}-${news.messageId}`;
      }

      return `${news.type}-${position}`;
    },

    getComponent(news: TNewsItem): FC | null {
      switch (news.type) {
        case NewsType.Post:
        case NewsType.GuestPost:
          return NewsfeedPostItem;
        case NewsType.Categories:
          return Personalize as FC;
        case NewsType.SuggestedChannels:
          return SuggestedChannels as FC;
        default:
          return null;
      }
    },

    setScrollPosition(scrollPosition: number): void {
      window.requestAnimationFrame(() => {
        if (scrollRef.current) {
          scrollRef.current.scrollTo({ top: scrollPosition });
        }
      });
    },

    handleScroll: debounce(() => {
      bus.ui.broadcastScrollMessages();
      if (scrollRef?.current) {
        onScroll && onScroll(scrollRef.current.scrollTop);
      }
    }, 200),
  };

  useEffect(() => {
    const target = scrollRef?.current;

    if (target) {
      target.addEventListener('scroll', presenter.handleScroll, { passive: true });
    }

    return () => {
      if (target) {
        target.removeEventListener('scroll', presenter.handleScroll);
      }
    };
  }, [onScroll]);

  useEffect(() => {
    if (scrollPosition) {
      presenter.setScrollPosition(scrollPosition);
    }
  }, [scrollPosition]);

  return { presenter, scrollRef };
};
