import { stickersModel } from 'APP/model/model';
import Entities from 'APP/store';

export async function removeStickerPackFromProfile(stickerPackId: string): Promise<void> {
  const result = await stickersModel.removeStickerPackFromProfile({ stickerPackId });

  if (!result) {
    return;
  }

  Entities.stickersStore.setInstalledPackIds(
    Entities.stickersStore.installedPackIds.filter((x) => x !== stickerPackId)
  );
}
