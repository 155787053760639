import { v4 as uuidv4 } from 'uuid';

import { PayloadType } from 'APP/model/message/messageModel.types';
import Entities from 'APP/store';
import { extractMentionsFromString } from 'UTILS/mentions';

export const getPayloadByType = (type, data) => {
  switch (type) {
    case PayloadType.RichText:
      return createTextPayload(data);
    case PayloadType.Sticker:
      return createStickerPayload(data);
    case PayloadType.Location:
      return createLocationPayload(data);
    case PayloadType.File:
      return createFilePayload(data);
    case PayloadType.Image:
      return createImagePayload(data);
    case PayloadType.Video:
      return createVideoPayload(data);
    case PayloadType.CircleVideo:
      return createVideoCirclePayload(data);
    case PayloadType.Album:
      return createAlbumPayload(data);
    case PayloadType.Article:
      return createArticlePayload(data);
    case PayloadType.Advertisement:
      return createAdvertisementPayload(data);
    case PayloadType.AudioMessage:
      return createAudioPayload(data);
    case PayloadType.VoiceMessage:
      return createVoicePayload(data);
    case PayloadType.Contact:
      return createContactPayload(data);
    case PayloadType.ButtonsSelected:
      return createButtonsSelectedPayload(data);
    default:
      return null;
  }
};

function createTextPayload(data) {
  const mentions = extractMentionsFromString(data.text);
  const userMentions = [];

  for (const mention of mentions) {
    const nickname = mention.substring(1);
    const user = Entities.UsersStore.getUserByNickName(nickname);

    if (user) {
      userMentions.push({ userId: user.id, nickname });
    }
  }

  return {
    payloadType: PayloadType.RichText,
    text: data.text.trim(),
    linkPreview: data.linkPreview,
    linkPreviewCancelled: data.linkPreviewCancelled,
    userMentions,
  };
}

function createStickerPayload(data) {
  return {
    payloadType: PayloadType.Sticker,
    id: data.id,
    type: data.type,
    animationUrl: data.animationUrl,
    fileId: data.fileId,
    stickerPackId: data.stickerPackId,
    name: data.name,
    stickerUrl: data.stickerUrl,
    graphicSize: data.graphicSize,
    order: data.order,
  };
}

function createLocationPayload(data) {
  return {
    payloadType: PayloadType.Location,
    latitude: data.latitude,
    longitude: data.longitude,
  };
}

function createImagePayload(data) {
  const imageData = data.file;

  return {
    payloadType: PayloadType.Image,
    fileName: imageData?.name || data.fileName,
    fileSize: imageData?.size || data.fileSize,
    graphicSize: data.graphicSize,
    localPreview: data.localPreview,
    mimeType: imageData?.type || data.mimeType,
    userFile: imageData,
    comment: data.comment || '',
    url: data.url || null,
  };
}

function createVideoPayload(data) {
  const imageData = data.file;

  return {
    payloadType: PayloadType.Video,
    fileName: imageData?.name || data.fileName,
    fileSize: imageData?.size || data.fileSize,
    graphicSize: data.graphicSize,
    localPreview: data.localPreview,
    mimeType: imageData?.type || data.mimeType,
    userFile: imageData,
    comment: data.comment || '',
    url: data.url || null,
    previewUrl: data.previewUrl,
  };
}

function createVideoCirclePayload(data) {
  const imageData = data.file;

  return {
    payloadType: PayloadType.CircleVideo,
    fileName: imageData?.name || data.fileName,
    fileSize: imageData?.size || data.fileSize,
    graphicSize: data.graphicSize,
    mimeType: imageData?.type || data.mimeType,
    comment: data.comment || '',
    url: data.url || null,
    previewUrl: data.previewUrl,
    duration: data.duration || 0,
  };
}

function createAlbumPayload(data) {
  return {
    payloadType: PayloadType.Album,
    payloads: data.payloads.map(({ type, data }, idx) => ({
      id: uuidv4(),
      order: idx,
      payloadType: 'container.ordered',
      payload: type === PayloadType.Video ? createVideoPayload(data) : createImagePayload(data),
    })),
    comment: data.comment || '',
  };
}

function createArticlePayload(data) {
  const getPayload = (type, data) => {
    switch (type) {
      case PayloadType.RichText:
        return createTextPayload(data);
      case PayloadType.Image:
        return createImagePayload(data);
      case PayloadType.Location:
        return createLocationPayload(data);
      case PayloadType.AudioMessage:
        return createAudioPayload(data);
      case PayloadType.Video:
        return createVideoPayload(data);
      case PayloadType.File:
        return createFilePayload(data);
    }
  };

  return {
    payloadType: PayloadType.Article,
    title: data.title,
    payloads: data.payloads.map(({ type, data }, idx) => {
      return {
        id: uuidv4(),
        order: idx,
        payloadType: 'container.ordered',
        payload: getPayload(type, data),
      };
    }),
    comment: data.comment || '',
  };
}

function createAdvertisementPayload(data) {
  return {
    payloadType: PayloadType.Advertisement,
    payloads: data.payloads.map(({ type, data }, idx) => ({
      id: uuidv4(),
      order: idx,
      payloadType: 'container.ordered',
      payload: type === PayloadType.Video ? createVideoPayload(data) : createImagePayload(data),
    })),
    comment: data.comment || '',
    buttonUrl: data.buttonUrl || '',
    buttonCaption: data.buttonCaption || '',
  };
}

function createFilePayload(data) {
  const fileData = data.file;

  return {
    payloadType: PayloadType.File,
    fileName: fileData?.name || data.fileName,
    fileSize: fileData?.size || data.fileSize,
    mimeType: fileData?.type || data.mimeType,
    userFile: fileData,
    comment: data.comment || '',
    url: data.url || null,
  };
}

function createAudioPayload(data) {
  const fileData = data.file;

  return {
    payloadType: PayloadType.AudioMessage,
    fileName: fileData?.name || data.fileName,
    fileSize: fileData?.size || data.fileSize,
    mimeType: fileData?.type || data.mimeType,
    userFile: fileData,
    comment: data.comment || '',
    url: data.url || null,
    duration: data.duration || null,
  };
}

function createVoicePayload(data) {
  // There are can be either js File on message creating
  // or mobx Payload Voice on Forward Message
  const voiceData = data.file[0] || data.file;

  return {
    payloadType: PayloadType.VoiceMessage,
    fileName: voiceData?.fileName || voiceData.name,
    fileSize: voiceData?.fileSize || voiceData.size,
    mimeType: voiceData?.mimeType || voiceData.type,
    userFile: voiceData,
    duration: data.duration,
    histogram: data.histogram,
  };
}

function createContactPayload(data) {
  return {
    payloadType: PayloadType.Contact,
    phone: data.phone,
    userId: data.id,
  };
}

function createButtonsSelectedPayload(data) {
  return {
    payloadType: PayloadType.ButtonsSelected,
    button: data.button,
  };
}
