import { AgoraCallMemberStatusEnum } from 'APP/packages/api/types/model/agoraCallMember';
import { CallFinishRequestReasonEnum } from 'APP/packages/api/types/model/callFinishRequest';
import { CallMemberV2StatusEnum } from 'APP/packages/api/types/model/callMemberV2';

export enum CallStreamType {
  Audio = 'AUDIO',
  Video = 'VIDEO',
}

export const CallMemberStatus = {
  ...AgoraCallMemberStatusEnum,
  ...CallMemberV2StatusEnum,
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type CallMemberStatus = AgoraCallMemberStatusEnum | CallMemberV2StatusEnum;

export const FinishCallReason = CallFinishRequestReasonEnum;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type FinishCallReason = CallFinishRequestReasonEnum;
