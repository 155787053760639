import Tasks from 'APP/Tasks';
import { openPopup } from 'APP/Tasks/app/popup/popup';
import { RouterState } from 'APP/router/constants';
import { StickerPackPopup } from 'MAIN/PopupManager/Popups/StickerPackPopup/StickerPackPopup';
import Entities from 'STORE';
import { Group } from 'STORE/Groups/Group';

interface IHandleDeepLinkParams {
  link: string;
  // Todo Replace to the return data type of navigateTo hook when they will be refactored
  navigateTo: (data: Record<string, any>) => void;
  state?: Record<string, any>;
}

export async function handleDeepLink(data: IHandleDeepLinkParams): Promise<Group | null> {
  const { link, navigateTo, state = {} } = data;

  const params = await Tasks.deeplinks.getParamsByDeepLink({ link });
  if (!params) {
    return null;
  }

  const { messageId, stickerPackId, groupId, userId, isAlreadyJoined, spaceRoute, spaceId } =
    params;

  let group = groupId ? Entities.GroupsStore.getGroupById(groupId) : null;

  if (messageId && group) {
    Tasks.messaging.focusMessage({ groupId: group.id, messageId });
  }

  if (groupId && group) {
    await Tasks.group.setActiveGroup({ groupId, state });
    if (spaceId) {
      await Tasks.space.setActiveSpace(spaceId);
    }
  }

  if (group && isAlreadyJoined) {
    Tasks.calls.findGroupCallAndRun(group.id);
  }

  if (group && !isAlreadyJoined) {
    group.setIsAwaitCall(true);
  }

  if (stickerPackId) {
    openPopup(StickerPackPopup, { params: { stickerPackId } });
  }

  if (userId) {
    const me = Entities.UsersStore.Me;
    if (me?.id === userId) {
      group = await Tasks.group.goToSavedMessages(state);
    } else {
      group = await Tasks.group.createP2PGroup(userId);
    }
  }

  if (spaceRoute) {
    navigateTo({ to: spaceRoute, state: { [RouterState.fromDeepLink]: true } });
  }

  return group;
}
