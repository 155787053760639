import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, MutableRefObject } from 'react';

import { ISupportedLocale } from 'APP/model/catalog/catalogModel.types';
import SelectableItem from 'APP/packages/UiKit/SelectableItem';
import { AddKeyPressHandler } from 'APP/packages/keyBoardHandler';
import { useTranslation } from 'APP/packages/translations';
import { Button } from 'UIKIT/Button/Button';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';
import { Popover } from 'UIKIT/Popover/Popover';
import { PopupFooter } from 'UIKIT/PopupFooter/PopupFooter';

import { useLocalesListPresenter, ILocalesListLocalPresenter } from './LocalesList.presenter';
import styles from './LocalesList.styles.m.css';

interface ICatalogLocalesProps extends ILocalesListLocalPresenter {
  setRef: MutableRefObject<HTMLButtonElement | null>;
  locales: ISupportedLocale[];
}

export const LocalesList: FC<ICatalogLocalesProps> = observer((props) => {
  const { t } = useTranslation();
  const presenter = useLocalesListPresenter(props);

  return (
    <Popover
      isOpened
      className={styles.container}
      reference={props.setRef.current}
      placement="bottom-end"
      onClose={presenter.onClose}
    >
      <AddKeyPressHandler onEsc={presenter.onClose} />

      <div className={classNames(styles.content, customScrollStyles.container)}>
        {props.locales.map((item) => (
          <SelectableItem
            key={item.languageCode}
            className={styles.item}
            isActive={presenter.isSelected(item.languageCode)}
            onSelect={() => presenter.onToggleSelection(item.languageCode)}
          >
            <span className={styles.flag}>{item.countryFlagEmoji}</span>
            {item.displayLanguageName}
          </SelectableItem>
        ))}
      </div>

      <PopupFooter className={styles.footer}>
        <Button
          theme="3"
          isDisabled={!presenter.canChoose}
          onClick={presenter.onChoose}
        >
          {t('done')}
        </Button>
      </PopupFooter>
    </Popover>
  );
});
