import Decoder from 'wavesurfer.js/dist/decoder.js';

export async function convertBufferToPeaks(buffer: ArrayBuffer): Promise<number[]> {
  const sampleRate = 8000;
  const decodedData = await Decoder.decode(buffer, sampleRate);

  return exportPeaks(decodedData);
}

function exportPeaks(decodedData: AudioBuffer): number[] {
  const maxLength = 600;
  const precision = 10000;
  const channel = decodedData.getChannelData(0);
  const sampleSize = Math.round(channel.length / maxLength);
  const peaks = [];

  for (let i = 0; i < maxLength; i++) {
    const sample = channel.slice(i * sampleSize, (i + 1) * sampleSize);
    let max = 0;
    for (let x = 0; x < sample.length; x++) {
      const n = sample[x];
      if (Math.abs(n) > Math.abs(max)) max = n;
    }
    peaks.push(Math.round(max * precision) / precision);
  }

  return peaks;
}
