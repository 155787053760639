import { FOCUS_NAMES } from '../../constants';
import getPayloadByOrder from '../payloads/getPayloadByOrder';
import { getFirstTextPositionOnPayloads } from './getFirstTextPositionOnPayloads';

export const getPositionArrowsKeyboard = (payloads, order, action) => {
  if (order === -1) {
    return FOCUS_NAMES.TITLE;
  }

  if (order === null) {
    const position = getFirstTextPositionOnPayloads(payloads);
    return position !== null ? position : FOCUS_NAMES.CREATOR_PAYLOAD;
  }

  if (!getPayloadByOrder(payloads, order)) {
    return FOCUS_NAMES.CREATOR_PAYLOAD;
  }

  if (action === 'up') {
    const position = getFirstTextPositionOnPayloads(payloads.slice(0, order + 1).reverse());
    return position !== null ? position : FOCUS_NAMES.TITLE;
  } else {
    const position = getFirstTextPositionOnPayloads(payloads.slice(order));
    return position !== null ? position : FOCUS_NAMES.CREATOR_PAYLOAD;
  }
};
