import { useTranslation } from 'APP/packages/translations';
import phoneNumber from 'APP/packages/validations/fields/phoneNumber';
import asyncValidation from 'APP/packages/validations/hooks/asyncValidation';

import username from '../fields/username';
import syncValidation from '../hooks/syncValidation';

export default () => {
  const { t } = useTranslation();

  const usernameErrorToText = {
    [username.ERRORS.IS_EMPTY]: ' ',
    [username.ERRORS.TOO_SHORT]: t('username_min_length_error'),
  };

  const phoneNumberErrorToText = {
    [phoneNumber.ERRORS.E_PHONE_INVALID]: t('invalid_phone_number'),
  };

  const usernameValidator = syncValidation();
  const phoneNumberValidator = asyncValidation();

  return {
    userName: {
      validate: (value, formData, meta) => {
        return usernameValidator({
          value,
          meta,
          validate: username.validate,
          errorToText: usernameErrorToText,
        });
      },
    },
    phoneNumber: {
      validate: (value, formData, meta, data) => {
        return phoneNumberValidator({
          value,
          meta,
          validate: phoneNumber.validate,
          asyncValidate: phoneNumber.asyncValidate,
          errorToText: phoneNumberErrorToText,
          data,
        });
      },
    },
  };
};
