import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { OwnVideo } from 'MAIN/OngoingCall/Shared/OwnVideo/OwnVideo';
import Entities from 'STORE';

import { AnimatedBackgroundPanel } from '../Shared/AnimatedBackgroundPanel/AnimatedBackgroundPanel';
import { EventsBar } from '../Shared/EventsBar/EventsBar';
import styles from './CompactCallView.styles.m.css';
import { GroupAudioCall } from './GroupAudioCall/GroupAudioCall';
import { GroupVideoCall } from './GroupVideoCall/GroupVideoCall';
import { P2PAudioCall } from './P2PAudioCall/P2PAudioCall';
import { P2PVideoCall } from './P2PVideoCall/P2PVideoCall';

export const CompactCallView = observer(({ className }) => {
  useEffect(() => {
    Entities.OngoingCall.reset();
  }, []);

  return (
    <div className={classNames(styles.container, className)}>
      {Entities.OngoingCall.mode.isP2PAudio && <P2PAudioCall />}
      {Entities.OngoingCall.mode.isP2PVideo && <P2PVideoCall />}
      {Entities.OngoingCall.mode.isGroupAudio && <GroupAudioCall />}
      {Entities.OngoingCall.mode.isGroupVideo && <GroupVideoCall />}
      <OwnVideo className={styles.ownVideoContainer} />
      {Entities.OngoingCall.hasEventsBar && <EventsBar />}
      <AnimatedBackgroundPanel isCompact />
    </div>
  );
});
