import { downloadStrings } from './downloadStrings';

interface IStrings {
  [key: string]: {
    [key: string]: string;
  };
}

export default async (
  calculatedLanguage?: string,
  defaultLanguage?: string
): Promise<IStrings[]> => {
  const stringsRequests = [];

  if (calculatedLanguage) {
    stringsRequests.push(downloadStrings(calculatedLanguage));
  }

  if (defaultLanguage && calculatedLanguage !== defaultLanguage) {
    stringsRequests.push(downloadStrings(defaultLanguage));
  }

  if (!stringsRequests.length) {
    throw Error('[translations] Wrong configuration');
  }

  const stringsList = await Promise.all(stringsRequests);
  const filteredStringsList = stringsList.filter((strings) => Boolean(strings));

  if (!filteredStringsList.length) {
    throw Error('[translations] Wrong configuration');
  }

  return filteredStringsList;
};
