import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { IconButton, IconViewSizes } from 'APP/packages/UiKit/IconButton';
import MoreIcon from 'ICONS/dots.svg';
import { usePopover } from 'UIKIT/Popover/usePopover';
import { PopoverMenu } from 'UIKIT/PopoverMenu/PopoverMenu';

import { useChatSettingsPresenter } from './ChatSettings.presenter';
import styles from './ChatSettings.styles.m.css';

export const ChatSettings: FC = observer(() => {
  const presenter = useChatSettingsPresenter();
  const popover = usePopover<HTMLButtonElement>();

  const iconClass = classNames(styles.icon, {
    [styles.opened]: popover.isOpened,
  });

  return (
    <>
      <IconButton
        className={iconClass}
        setRef={popover.containerRef}
        Icon={MoreIcon}
        onClick={popover.toggle}
        size={IconViewSizes.Big}
        dataTest="chat-settings-button"
      />
      <PopoverMenu
        className={styles.popover}
        isOpened={popover.isOpened}
        placement="bottom-end"
        menuItems={presenter.menuItems}
        reference={popover.containerRef.current}
        onClose={popover.close}
      />
    </>
  );
});
