import { observer } from 'mobx-react';
import React from 'react';

import InitiatorView from './InitiatorView';
import ParticipantView from './ParticipantView';
import usePresenter from './presenter';

const Stream = () => {
  const presenter = usePresenter();

  if (!presenter.hasStream) {
    return null;
  }

  if (presenter.isMeInitiator) {
    return <InitiatorView />;
  }

  return <ParticipantView />;
};

export default observer(Stream);
