import classNames from 'classnames';
import React, { FC, memo } from 'react';

import { getImageByLargestSize } from 'APP/packages/imageResizer/getImageByLargestSize';

import styles from './StickerPack.styles.m.css';

const STICKER_SIZE = 32;

interface IStickerPackProps {
  stickerPackId: string;
  isActive: boolean;
  iconUrl: string;
  onClick(stickerPackId: string): void;
}

export const StickerPack: FC<IStickerPackProps> = memo(
  ({ isActive, iconUrl, stickerPackId, onClick }) => {
    const url = getImageByLargestSize(iconUrl, STICKER_SIZE);

    const classes = classNames(styles.container, {
      [styles.active]: isActive,
    });

    const handleClick = (): void => onClick(stickerPackId);

    return (
      <button
        onClick={handleClick}
        className={classes}
      >
        <img
          src={url}
          alt="sticker"
          width={STICKER_SIZE}
          height={STICKER_SIZE}
        />
      </button>
    );
  }
);
