import classNames from 'classnames';
import React from 'react';

import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';
import { SearchInput } from 'UIKIT/SearchInput/SearchInput';

import usePresenter from './presenter';
import styles from './styles.m.css';

const SelectItemsList = ({ value, values, placeholder, onSelect, ItemComponent, handleSearch }) => {
  const presenter = usePresenter({
    values,
    handleSearch,
  });

  return (
    <>
      <SearchInput
        className={styles.search}
        onChange={presenter.setSearchString}
        placeholder={placeholder}
        value={presenter.searchString}
        dataTest="search-input"
      />
      <div className={classNames(customScrollStyles.container, styles.scroll)}>
        <ul className={styles.list}>
          {presenter.filteredKeys.map((key) => {
            return (
              <li
                key={String(key)}
                className={styles.item}
              >
                <button
                  className={styles.button}
                  data-test={`item-${key}`}
                  onClick={() => onSelect(key)}
                >
                  <ItemComponent
                    {...values[key]}
                    isSelected={key === value}
                  />
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default SelectItemsList;
