import { observer } from 'mobx-react';
import React from 'react';

import { useTranslation } from 'APP/packages/translations';
import { Button } from 'UIKIT/Button/Button';
import { Portal } from 'UIKIT/Portal/Portal';

import usePresenter from './presenter';
import styles from './styles.m.scss';

const RequestPermission = () => {
  const presenter = usePresenter();
  const { t } = useTranslation();

  if (!presenter.isShowDialog) {
    return null;
  }

  return (
    <Portal>
      <div className={styles.popup}>
        <div className={styles.text}>{t('notifications_required_alert')}</div>
        <div className={styles.actionPanel}>
          <Button
            onClick={presenter.onClose}
            className={styles.button}
            theme="1"
          >
            {t('ok')}
          </Button>
        </div>
      </div>
    </Portal>
  );
};

export default observer(RequestPermission);
