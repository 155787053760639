import React, { FC, Fragment, MutableRefObject, RefObject } from 'react';

import { DatePlaceholder } from 'APP/components/DatePlaceholder/DatePlaceholder';

import { Message } from '../Message/Message';
import { MessageViewPageType } from '../Messages.types';
import { IGroupedMessages } from '../Messages.utils';
import { NewMessages } from '../NewMessages/NewMessages';

interface IGroupedMessagesProps {
  date: string;
  groupedMessages: IGroupedMessages[];
  scrollRef: RefObject<HTMLDivElement>;
  newMessagesPlaceholderRef?: MutableRefObject<HTMLDivElement | null>;
  messageViewPageType?: MessageViewPageType;
  stickyClassName: string;
}

export const GroupedMessages: FC<IGroupedMessagesProps> = ({
  date,
  groupedMessages,
  scrollRef,
  newMessagesPlaceholderRef,
  messageViewPageType = MessageViewPageType.Main,
  stickyClassName,
}: IGroupedMessagesProps) => {
  return (
    <div data-test={date}>
      {groupedMessages.map((data) => (
        <Fragment key={data.message.mapKey}>
          {data.hasDatePlaceholder && (
            <DatePlaceholder
              message={data.message}
              selectorName={stickyClassName}
            />
          )}

          {data.message.hasNewMessagePlaceholder && newMessagesPlaceholderRef && (
            <NewMessages reference={newMessagesPlaceholderRef} />
          )}
          <Message
            message={data.message}
            scrollRef={scrollRef}
            messageViewPageType={messageViewPageType}
          />
        </Fragment>
      ))}
    </div>
  );
};
