import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, useCallback } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { useEventsBarPresenter } from './EventsBar.presenter';
import styles from './EventsBar.styles.m.css';
import { Event } from './components/Event/Event';

const ANIMATION_TIMEOUT = 250;
const MAX_COUNT = 3;

const itemTransition = {
  enter: styles.enter,
  enterActive: styles.enterActive,
  exit: styles.exit,
  exitActive: styles.exitActive,
};

interface IEventsBarProps {
  fullScreen?: boolean;
}

export const EventsBar: FC<IEventsBarProps> = observer(({ fullScreen = false }) => {
  const presenter = useEventsBarPresenter();

  const items = presenter.items;

  const onHide = useCallback(
    (timestamp) => {
      presenter.setLastEvent(timestamp);
    },
    [presenter]
  );

  return (
    <TransitionGroup
      className={classNames(styles.eventsBar, {
        [styles.fullscreen]: fullScreen,
      })}
    >
      {items.slice(-MAX_COUNT).map((event) => {
        return (
          <CSSTransition
            key={event.timestamp}
            classNames={itemTransition}
            timeout={ANIMATION_TIMEOUT}
          >
            <Event
              text={event.text}
              timestamp={event.timestamp}
              onHide={onHide}
            />
          </CSSTransition>
        );
      })}
    </TransitionGroup>
  );
});
