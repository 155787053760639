import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';

import { useTextFormattingInputPresenter } from './TextFormattingInput.presenter';
import styles from './TextFormattingInput.styles.m.css';
import { ITextFormattingInputParams } from './TextFormattingInput.types';
import { TextFormattingMenu } from './TextFormattingMenu/TextFormattingMenu';

interface ITextFormattingInputProps extends ITextFormattingInputParams {
  className?: string;
  inputClassName?: string;
  placeholderClassName?: string;
}

export const TextFormattingInput: FC<ITextFormattingInputProps> = observer((props) => {
  const { className, inputClassName, placeholderClassName, ...rest } = props;
  const { presenter, inputRef } = useTextFormattingInputPresenter(rest);

  return (
    <div className={styles.container}>
      <div className={classNames(styles.inputContainer, className)}>
        <div
          className={classNames(styles.input, customScrollStyles.container, inputClassName)}
          ref={inputRef}
          role="textbox"
          tabIndex={0}
          contentEditable={true}
          onInput={presenter.onInputChange}
          onPaste={presenter.onInputPaste}
          onKeyDown={presenter.onKeyDown}
          onSelect={presenter.onSelectText}
        />

        {presenter.isShowPlaceholder && (
          <div className={classNames(styles.placeholder, placeholderClassName)}>
            {presenter.placeholder}
          </div>
        )}
      </div>

      {presenter.isShowMenu && presenter.selectionRange && (
        <TextFormattingMenu
          selectionRange={presenter.selectionRange}
          onClose={presenter.onCloseMenu}
          onBold={presenter.onBold}
          onItalic={presenter.onItalic}
          onUnderline={presenter.onUnderline}
          onStrikeThrough={presenter.onStrikeThrough}
          onAddLink={presenter.onAddLink}
          onRemoveLink={presenter.onRemoveLink}
        />
      )}
    </div>
  );
});
