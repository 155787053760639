import { observer } from 'mobx-react';
import React, { FC, ReactElement } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { RewardProgressInfoRewardEnum } from 'APP/model/userSettings/userSettingsModel.types';
import { useTranslation } from 'APP/packages/translations';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { useClosePopupOnNavigation } from '../../hooks/useClosePopupOnNavigation';
import { RewardsActiveUser } from './RewardsActiveUser/RewardsActiveUser';
import { RewardsInvite } from './RewardsInvite/RewardsInvite';
import { useRewardsPopupPresenter } from './RewardsPopup.presenter';
import styles from './RewardsPopup.styles.m.css';

const getReward = (
  rewardName: RewardProgressInfoRewardEnum,
  onOpenEditProfile: () => void
): ReactElement => {
  switch (rewardName) {
    case RewardProgressInfoRewardEnum.ExclusiveBackground1:
      return (
        <RewardsInvite
          key={rewardName}
          onOpenEditProfile={onOpenEditProfile}
        />
      );
    default:
      return (
        <RewardsActiveUser
          key={rewardName}
          onOpenEditProfile={onOpenEditProfile}
        />
      );
  }
};

export const RewardsPopup: FC<ICommonPopupsProps> = observer((props) => {
  const { t } = useTranslation();
  const presenter = useRewardsPopupPresenter(props);
  useClosePopupOnNavigation(props.popupInstance);

  return (
    <Popup
      title={t('common_rewards')}
      isOpened
      isHidden={props.popupInstance.isHidden}
      handlePrevStep={presenter.hasBackButton ? presenter.onBack : null}
      onClose={presenter.onClose}
    >
      <div className={styles.wrapper}>
        <h2 className={styles.title}>{t('common_rewards_gifts')}</h2>
        <div className={styles.subTitle}>{t('common_rewards_gifts_description')}</div>
        {presenter.rewardsKeys.map((item) => getReward(item, presenter.onOpenEditProfile))}
      </div>
    </Popup>
  );
});
