import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { useTranslation } from 'APP/packages/translations';
import ContactIcon from 'ICONS/ic-user.svg';
import { useUserProfile } from 'MAIN/hooks/userProfile/useUserProfile';
import { Avatar } from 'UIKIT/Avatar/Avatar';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';
import { Button } from 'UIKIT/Button/Button';

import styles from './styles.m.scss';

export const Contact = observer(({ message, className, avatarType = null }) => {
  const { user, name, phone, showStartChatButton, appContact } = message.payload;
  const { t } = useTranslation();
  const containerClass = classNames(styles.container, className);
  const userProfile = useUserProfile(user?.id, { groupId: message.groupId, messageId: message.id });

  return (
    <>
      <div className={containerClass}>
        {(!appContact || !user) && (
          <div className={styles.iconContainer}>
            <ContactIcon className={styles.icon} />
          </div>
        )}
        {appContact && user && (
          <button onClick={userProfile.show}>
            <Avatar
              title={user.avatarTitle}
              url={user.avatarUrl}
              backgroundColorIndex={user.avatarColorIndex}
              type={avatarType || AvatarTypes.Round_10}
            />
          </button>
        )}
        <div className={styles.previewContent}>
          <div className={styles.name}>{name}</div>
          {!!phone && <div className={styles.phone}>{`+${phone}`}</div>}
        </div>
      </div>
      {showStartChatButton && user && (
        <Button
          className={styles.button}
          size="0"
          onClick={user.startChat}
          theme="7"
        >
          {t('shared_contact_start_chat')}
        </Button>
      )}
    </>
  );
});
