import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, useRef } from 'react';

import ErrorImage from 'APP/images/gem-mascot-not-found.svg';
import { AddKeyPressHandler } from 'APP/packages/keyBoardHandler';
import { useTranslation } from 'APP/packages/translations';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';
import { InfoBlock, InfoBlockTypes } from 'UIKIT/InfoBlock/InfoBlock';

import { useCatalogPresenter } from './Catalog.presenter';
import styles from './Catalog.styles.m.css';
import { CatalogCategories } from './CatalogCategories/CatalogCategories';
import { CatalogCategoryContent } from './CatalogCategoryContent/CatalogCategoryContent';
import { CatalogHeader } from './CatalogHeader/CatalogHeader';
import { CatalogMainContent } from './CatalogMainContent/CatalogMainContent';
import { CatalogSearchContent } from './CatalogSearchContent/CatalogSearchContent';

export const Catalog: FC = observer(() => {
  const scrollRef = useRef(null);
  const presenter = useCatalogPresenter(scrollRef);
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <AddKeyPressHandler onEsc={presenter.onClose} />

      <div className={styles.header}>
        <CatalogHeader onSearchTextChange={presenter.onSearchTextChange} />
      </div>

      <div
        ref={scrollRef}
        className={classNames(styles.contentContainer, customScrollStyles.container)}
      >
        {presenter.isErrorView && (
          <InfoBlock
            descriptions={[t('nothing_found')]}
            type={InfoBlockTypes.Page}
            image={ErrorImage}
          />
        )}

        {presenter.isContentView && (
          <div className={styles.content}>
            {presenter.isCategoryListView && (
              <CatalogCategories
                onCategoryChange={presenter.onCategoryChange}
                onTypeChange={presenter.onTypeChange}
              />
            )}

            {presenter.isMainView && (
              <CatalogMainContent
                scrollRef={scrollRef}
                onCatalogReset={presenter.onReset}
              />
            )}

            {presenter.isCategoryView && (
              <CatalogCategoryContent
                scrollRef={scrollRef}
                onCatalogReset={presenter.onReset}
              />
            )}

            {presenter.isSearchView && (
              <CatalogSearchContent
                scrollRef={scrollRef}
                onCatalogReset={presenter.onReset}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
});
