import { action, computed, observable } from 'mobx';

class Streamer {
  @observable audioTrack = null;
  @observable videoTrack = null;
  @observable screenTrack = null;
  @observable _hasAudio = false;
  @observable _hasVideo = false;
  @observable volumeLevel = 0;

  uid = null;
  shareScreenUid = null;

  userId = null;
  initiatorId = null;
  username = null;
  avatarUrl = null;
  platform = null;

  constructor(data) {
    this.uid = data.uid || this.uid;
    this.shareScreenUid = data.shareScreenUid || this.shareScreenUid;

    this.userId = data.userId || this.userId;
    this.initiatorId = data.initiatorId || this.initiatorId;
    this.username = data.username || this.username;
    this.avatarUrl = data.avatarUrl || this.avatarUrl;
    this.platform = data.platform || this.platform;
  }

  @computed
  get avatarColorIndex() {
    return (this.uid.toString() + '0')[0];
  }

  @action
  setAudioTrack({ track, hasAudio }) {
    this.audioTrack = track;
    this._hasAudio = hasAudio;
  }

  @action
  removeAudioTrack() {
    this.audioTrack = null;
    this._hasAudio = false;
  }

  @action
  setSharedScreen(videoTrack) {
    this.screenTrack = videoTrack;
  }

  @action
  removeSharedScreen() {
    this.screenTrack = null;
  }

  @computed
  get isSharedScreen() {
    return Boolean(this.screenTrack);
  }

  @computed
  get isActiveSpeaker() {
    return this.volumeLevel > 5 && this.audioTrack && this._hasAudio;
  }

  @computed
  get isAudioMuted() {
    return !this.audioTrack || !this._hasAudio;
  }

  @action
  setVideoTrack({ track, hasVideo }) {
    this.videoTrack = track;
    this._hasVideo = hasVideo;
  }

  @action
  removeVideoTrack() {
    this.videoTrack = null;
    this._hasVideo = false;
  }

  @computed
  get hasVideo() {
    return this.videoTrack && this._hasVideo;
  }

  @action
  setVolumeLevel(volume) {
    this.volumeLevel = volume;
  }

  @computed
  get isInitiator() {
    return this.userId === this.initiatorId;
  }
}

export default Streamer;
