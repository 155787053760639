export const replaceDivTags = (innerHTML: string): string => {
  if (!innerHTML) {
    return innerHTML;
  }

  innerHTML = innerHTML.replace(/<div><br><\/div>/g, '\n');
  innerHTML = innerHTML.replace(/<div>/g, '\n');
  innerHTML = innerHTML.replace(/<\/div>/g, '');

  return innerHTML;
};
