import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { langService } from 'MODULE/i18n/service';

import getStrings from '../helpers/getStrings';
import prepareLanguages from '../helpers/prepareLanguages';
import prepareResources from '../helpers/prepareResources';

export const configure = async ({ language, defaultLanguage }) => {
  const { shortLanguage, shortDefaultLanguage } = prepareLanguages(language, defaultLanguage);

  const currentLanguage = shortLanguage || shortDefaultLanguage;

  const strings = await getStrings(currentLanguage, shortDefaultLanguage);

  // remove after full migration from module/i18n to current package
  langService.setLangs(strings[0], currentLanguage);

  const resources = prepareResources(strings, currentLanguage, shortDefaultLanguage);

  return i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      defaultNS: 'strings',
      lng: currentLanguage,
      fallbackLng: defaultLanguage,
    });
};
