import { useLocalStore } from 'mobx-react';

import Entities from 'APP/store';

export default () => {
  const presenter = useLocalStore(() => ({
    get isAvailableToShow() {
      return Boolean(presenter.group) && Entities.ChatStore.isShowSidebar;
    },

    get group() {
      return Entities.GroupsStore.activeGroup;
    },

    onClose() {
      return Entities.ChatStore.hideSidebar();
    },
  }));

  return presenter;
};
