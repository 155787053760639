import classNames from 'classnames';
import React from 'react';

import { PermissionsRequestStatuses } from 'APP/model/call/callModel.types';
import { useTranslation } from 'APP/packages/translations';
import IcClose from 'ICONS/ic-close-24.svg';
import IcDone from 'ICONS/ic-done.svg';
import { IconView } from 'UIKIT/IconView/IconView';
import popupItemStyles from 'UIKIT/PopupItem/styles.m.scss';

import { usePresenter } from './presenter';
import styles from './styles.m.css';

export const GlobalRequestButton = ({ status, onSuccess }) => {
  const { t } = useTranslation();
  const presenter = usePresenter(status, onSuccess);

  return (
    <button
      onClick={presenter.onClick}
      className={classNames(styles.button, popupItemStyles.interactive)}
    >
      <IconView
        Icon={status === PermissionsRequestStatuses.Approved ? IcDone : IcClose}
        className={styles.icon}
      />
      {status === PermissionsRequestStatuses.Approved
        ? t('call_permissions_approve_all')
        : t('call_permissions_decline_all')}
    </button>
  );
};
