import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import { ISupportedLocale } from 'APP/model/catalog/catalogModel.types';
import { useTranslation } from 'APP/packages/translations';
import Entities from 'APP/store';
import { getUserLanguage } from 'UTILS/getUserLanguage';
import { isEqualArrays } from 'UTILS/isEqualArrays';

import { loadLocales } from '../../Tasks/loadLocales';
import { updateUserLocales } from '../../Tasks/updateUserLocales';

interface ICatalogLocalesPresenter {
  isOpened: boolean;
  init(): Promise<void>;
  locales: ISupportedLocale[];
  selectedCodes: Set<string>;
  selectedLocales: ISupportedLocale[];
  mainLocale: ISupportedLocale | null;
  title: string;
  flag: string | null;
  onToggle(): void;
  onSelect(codes: Set<string>): Promise<void>;
}

export const useCatalogLocalesPresenter = (): ICatalogLocalesPresenter => {
  const { t } = useTranslation();

  const presenter = useLocalStore<ICatalogLocalesPresenter>(() => ({
    isOpened: false,

    async init(): Promise<void> {
      if (Entities.catalogStore.locales.length === 0) {
        await loadLocales();
      }
    },

    get locales(): ISupportedLocale[] {
      return Entities.catalogStore.locales;
    },

    get selectedCodes(): Set<string> {
      return new Set(Entities.userPreferences.localeIds);
    },

    get selectedLocales(): ISupportedLocale[] {
      return presenter.locales.filter((item) => presenter.selectedCodes.has(item.languageCode));
    },

    get mainLocale(): ISupportedLocale | null {
      if (presenter.selectedLocales.length === 0) {
        return null;
      }

      const userLanguage = getUserLanguage();
      const mainLocale = presenter.selectedLocales.find((item) => {
        return item.languageCode === userLanguage;
      });

      return mainLocale || presenter.selectedLocales[0];
    },

    get title(): string {
      const mainLocale = presenter.mainLocale;

      let title: string = mainLocale?.displayLanguageName || t('channel_catalog_choose_languages');
      if (presenter.selectedLocales.length > 1) {
        title = `${title} +${presenter.selectedLocales.length - 1}`;
      }

      return title;
    },

    get flag(): string | null {
      return presenter.mainLocale?.countryFlagEmoji || null;
    },

    onToggle(): void {
      presenter.isOpened = !presenter.isOpened;
    },

    async onSelect(codes: Set<string>): Promise<void> {
      const selectedIds = Array.from(codes);
      const prevSelectedIds = Array.from(presenter.selectedCodes);

      if (!isEqualArrays(selectedIds, prevSelectedIds, false)) {
        const result = await updateUserLocales(selectedIds);

        if (result) {
          Entities.userPreferences.setLocaleIds(selectedIds);
        } else {
          Entities.toast.show(t('something_went_wrong'));
        }
      }
    },
  }));

  useEffect(() => {
    presenter.init();
  }, []);

  return presenter;
};
