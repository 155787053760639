import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { GroupAvatar } from 'APP/components/Avatar/GroupAvatar/GroupAvatar';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';
import { IconView } from 'UIKIT/IconView/IconView';
import { MenuItem } from 'UIKIT/MenuItem/MenuItem';

import GroupUpdateLinkButton from '../components/GroupUpdateLinkButton';
import InviteLink from '../components/InviteLink';
import { ProfileDescription } from '../components/ProfileDescription/ProfileDescription';
import { SpaceLink } from '../components/SpaceLink';
import { SpaceVisibility } from '../components/SpaceVisibility/SpaceVisibility';
import { useChannelProfilePresenter } from './ChannelProfile.presenter';
import styles from './ChannelProfile.styles.m.css';

export const ChannelProfile = observer(({ className, groupId, actions }) => {
  const presenter = useChannelProfilePresenter({ groupId, actions });

  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.header}>
        <GroupAvatar
          className={styles.avatar}
          type={AvatarTypes.Square_40}
          group={presenter.group}
          withFullScreen={true}
        />
        <div className={styles.info}>
          <h3 className={styles.title}>{presenter.title}</h3>
          <p className={styles.subtitle}>{presenter.subtitle}</p>
        </div>
      </div>

      {presenter.description && (
        <ProfileDescription
          className={styles.contact}
          groupId={groupId}
          mentions={presenter.group.descriptionMentions}
          description={presenter.description}
        />
      )}
      {presenter.canInviteUsers && (
        <InviteLink
          className={styles.contact}
          deepLink={presenter.group.deepLink}
          Actions={<GroupUpdateLinkButton group={presenter.group} />}
        />
      )}
      {presenter.space && <SpaceLink space={presenter.space}></SpaceLink>}

      {presenter.actions.length > 0 && <hr className={styles.divider} />}
      {presenter.actions.map(({ key, title, Icon, onClick }) => (
        <MenuItem
          className={styles.listItem}
          titleClassName={styles.listItemTitle}
          key={key}
          title={title}
          icon={
            <IconView
              Icon={Icon}
              className={styles.listIcon}
            />
          }
          onClick={onClick}
        />
      ))}
      <SpaceVisibility
        className={styles.listItem}
        titleClassName={styles.listItemTitle}
        group={presenter.group}
      />

      {presenter.childPagesList.length > 0 && <hr className={styles.dividerBackground} />}
      {presenter.childPagesList.map(({ key, title, Icon, onClick, count }) => (
        <MenuItem
          className={styles.listItem}
          titleClassName={styles.listItemTitle}
          key={key}
          title={title}
          count={count}
          icon={
            <IconView
              Icon={Icon}
              className={styles.listIcon}
            />
          }
          onClick={onClick}
        />
      ))}

      <hr className={styles.dividerBackground} />
      {presenter.actionItems.map(({ key, title, Icon, onClick }) => (
        <MenuItem
          className={styles.listItem}
          titleClassName={styles.listItemTitle}
          key={key}
          title={title}
          icon={
            <IconView
              Icon={Icon}
              className={styles.listIcon}
            />
          }
          onClick={onClick}
        />
      ))}
    </div>
  );
});
