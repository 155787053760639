import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Tasks from 'APP/Tasks';
import { openPopup } from 'APP/Tasks/app/popup/popup';
import { MAX_PIN_SPACES } from 'APP/model/space/spaceModel.constants';
import { useTranslation } from 'APP/packages/translations';
import { RouterLink, RouterPage } from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import { DeleteSpacePopup } from 'MAIN/PopupManager/Popups/DeleteSpacePopup/DeleteSpacePopup';
import Entities from 'STORE';
import { Popup } from 'STORE/App/Popups/Popup/Popup';
import { Space } from 'STORE/Spaces/Space';

interface ISpaceActions {
  childPopup: Popup | null;
  canLeave: boolean;
  canDelete: boolean;
  canPin: boolean;
  canUnpin: boolean;
  canMarkAsRead: boolean;
  onLeave(): Promise<void>;
  checkAndLeaveSpacePage(): void;
  onMarkAsRead(): void;
  onDelete(): void;
  onPin(): void;
  onUnpin(): void;
  openChildPopup<T>(component: FC<ICommonPopupsProps<T>>, params: T): void;
}

export function useSpaceActions(space: Space, parentPopup?: Popup): ISpaceActions {
  const { t } = useTranslation();
  const navigateTo = useNavigateTo();
  const location = useLocation();
  const source = useAsObservableSource({ space, location, navigateTo });

  const presenter = useLocalStore<ISpaceActions>(() => ({
    childPopup: null,

    get canLeave(): boolean {
      return !source.space.isOwner && !source.space.isGuest;
    },

    async onLeave(): Promise<void> {
      const result = await Tasks.app.showConfirm({
        text: t('space_leave_space_alert'),
        primaryText: t('common_ok'),
        secondaryText: t('common_cancel'),
      });

      if (!result) {
        return;
      }

      const spaceId = source.space.id;
      const success = await Tasks.space.leaveSpace(spaceId);

      success ? presenter.checkAndLeaveSpacePage() : Entities.toast.show(t('something_went_wrong'));
    },

    checkAndLeaveSpacePage(): void {
      if (source.location.pathname.includes(`/${RouterPage.Spaces}/${source.space.id}`)) {
        source.navigateTo({ to: RouterLink.main });
      }
    },

    get canDelete(): boolean {
      return source.space.isOwner;
    },

    onDelete(): void {
      presenter.openChildPopup(DeleteSpacePopup, { spaceId: source.space.id });
    },

    get canPin(): boolean {
      return !source.space.isPinned;
    },

    onPin(): void {
      if (Entities.spacesStore.maxPinOrder >= MAX_PIN_SPACES) {
        Entities.toast.show(t('space_pin_max_alert', { 0: MAX_PIN_SPACES }));
        return;
      }

      Tasks.space.pinSpaces([source.space.id]);
    },

    get canUnpin(): boolean {
      return source.space.isPinned;
    },

    onUnpin(): void {
      Tasks.space.unpinSpaces([source.space.id]);
    },

    get canMarkAsRead(): boolean {
      return Entities.GroupsStore.getGroupsBySpaceId(source.space.id).some(
        (group) => group.numberOfUnread > 0 || group.unread
      );
    },

    onMarkAsRead(): void {
      Tasks.space.markSpaceAsRead(source.space.id);
    },

    openChildPopup<T>(component: FC<ICommonPopupsProps<T>>, params: T): void {
      parentPopup?.hide();

      presenter.childPopup = openPopup(component, {
        params,
        onClose: parentPopup ? (): void => parentPopup?.close() : undefined,
        onBack: parentPopup ? (): void => parentPopup?.show() : undefined,
      });
    },
  }));

  useEffect(() => {
    return () => presenter.childPopup?.close();
  }, []);

  return presenter;
}
