import { mapGroupResponse, mapPersonalizedGroupResponse } from 'APP/model/group/groupModel.mapper';
import { CatalogResponse } from 'APP/packages/api/types/model/catalogResponse';
import {
  GetCatalogRequest,
  GetCatalogRequestCatalogTypeEnum,
} from 'APP/packages/api/types/model/getCatalogRequest';
import { ItemResponse } from 'APP/packages/api/types/model/itemResponse';
import { ItemsResponse } from 'APP/packages/api/types/model/itemsResponse';
import { NewsFeedElementCategoriesResponse } from 'APP/packages/api/types/model/newsFeedElementCategoriesResponse';
import { NewsFeedElementPostResponse } from 'APP/packages/api/types/model/newsFeedElementPostResponse';
import { NewsFeedElementSuggestedChannelsResponse } from 'APP/packages/api/types/model/newsFeedElementSuggestedChannelsResponse';
import { NewsFeedResponse } from 'APP/packages/api/types/model/newsFeedResponse';
import { WidgetResponse } from 'APP/packages/api/types/model/widgetResponse';

import { IGroup } from '../group/groupModel.types';
import {
  CatalogItemType,
  CatalogWidgetType,
  ICatalog,
  ICatalogItem,
  ICatalogItemsList,
  IGetCatalog,
  INewsfeed,
  INewsItem,
  NewsType,
} from './catalogModel.types';

export const mapCatalogItemResponse = (data: ItemResponse): ICatalogItem => {
  return {
    ...data,
    itemType: data.itemType as CatalogItemType,
  };
};

export const mapGetCatalogRequest = (data: IGetCatalog): GetCatalogRequest => {
  return {
    catalogType: data.catalogType
      ? (data.catalogType as string as GetCatalogRequestCatalogTypeEnum)
      : GetCatalogRequestCatalogTypeEnum.All,
  };
};

export const mapGetCatalogResponse = (data: CatalogResponse): ICatalog => {
  return {
    widgets: data.widgets.map((widget) => ({
      ...widget,
      cursor: widget.cursor || null,
      items: widget.items.map(mapCatalogItemResponse),
      viewType: widget.viewType as CatalogWidgetType,
    })),
  };
};

export const mapItemsResponse = (data: ItemsResponse): ICatalogItemsList => {
  return {
    ...data,
    items: data.items.map(mapCatalogItemResponse),
  };
};

export const mapNewsfeedResponse = (data: NewsFeedResponse): INewsfeed => {
  return {
    ...data,
    values: data.values.map((newsItem): INewsItem => {
      if (newsItem.elementType === (NewsType.Post as string)) {
        const { group, message, title } = newsItem as NewsFeedElementPostResponse;
        return {
          elementType: NewsType.Post,
          group: mapPersonalizedGroupResponse(group),
          message,
          title,
        };
      }

      if (newsItem.elementType === (NewsType.Categories as string)) {
        const { categories } = newsItem as NewsFeedElementCategoriesResponse;
        return {
          elementType: NewsType.Categories,
          categories,
        };
      }
      const { channels } = newsItem as NewsFeedElementSuggestedChannelsResponse;
      return {
        elementType: NewsType.SuggestedChannels,
        channels: channels.map(mapGroupResponse),
      };
    }),
  };
};

export const mapChannelsStartPageResponse = (data: WidgetResponse): IGroup[] => {
  return data.channels;
};
