import logger from 'APP/packages/logger';
import Entities from 'STORE';

export abstract class BaseCache {
  protected db: IDBDatabase;
  protected openRequest: IDBOpenDBRequest;

  protected abstract dbName: string;
  protected abstract initDb(): void;

  protected getAll<T = any>(storeName: string): Promise<Record<string, T> | null> {
    return new Promise((resolve, reject) => {
      const transaction = this.createTransaction(storeName);

      if (!transaction) {
        resolve(null);
      } else {
        const request = transaction.openCursor();
        const result: Record<string, any> = {};

        request.onsuccess = function (event): void {
          const cursor = (event.target as IDBRequest)?.result as IDBCursorWithValue;

          if (cursor) {
            result[cursor.primaryKey as string] = cursor.value;
            cursor.continue();
          } else {
            resolve(result);
          }
        };
        request.onerror = reject;
      }
    });
  }

  protected get(storeName: string, id: string): Promise<any | null> {
    return new Promise((resolve, reject) => {
      const transaction = this.createTransaction(storeName);

      if (!transaction) {
        resolve(null);
      } else {
        const request = transaction.get(id);

        request.onsuccess = (event): void => {
          const data = (event.target as IDBRequest).result;
          resolve(data);
        };

        request.onerror = reject;
      }
    });
  }

  protected put(storeName: string, id: string, data: any): IDBRequest<IDBValidKey> | null {
    return this.createTransaction(storeName, 'readwrite')?.put(data, id) || null;
  }

  protected getDbVersion(): number {
    const [major, minor, patch] = Entities.App.version.split('.');
    const paddedMinor = minor.padStart(3, '0');
    const paddedPatch = patch.padStart(3, '0');
    return parseInt(`${major}${paddedMinor}${paddedPatch}`);
  }

  protected openDb(): void {
    this.openRequest = indexedDB.open(this.dbName, this.getDbVersion());
    this.openRequest.onupgradeneeded = this.onUpgradeNeeded.bind(this);
    this.openRequest.onsuccess = this.onSuccess.bind(this);
    this.openRequest.onerror = this.onError.bind(this);
  }

  protected onUpgradeNeeded(event: IDBVersionChangeEvent): void {
    this.db = (event.target as IDBOpenDBRequest).result;
    this.initDb();
  }

  protected onSuccess(event: Event): void {
    this.db = (event.target as IDBOpenDBRequest).result;
  }

  protected onError(event: Event): void {
    logger.get('Cache').error(this.constructor.name, event);
  }

  protected createTransaction(
    tableName: string,
    mode: IDBTransactionMode = 'readonly'
  ): IDBObjectStore | null {
    if (!this.db) {
      return null;
    }

    const transaction = this.db.transaction(tableName, mode);
    return transaction.objectStore(tableName);
  }

  protected createStore(storeName: string): void {
    if (!this.db.objectStoreNames.contains(storeName)) {
      this.db.createObjectStore(storeName);
    }
  }
}
