import api from 'APP/packages/api';
import logger from 'APP/packages/logger';
import Entities from 'STORE';

const request = async (space, isVisible) => {
  try {
    const requestResult = await api.space.updateGroupsVisibility({
      spaceId: space.id,
      visible: isVisible,
    });

    return requestResult?.result || false;
  } catch (error) {
    logger.get('API').error('spaces.updateGroupsVisibility', error);
    return false;
  }
};

export const changeAllGroupsVisibility = async ({ spaceId, isVisible }) => {
  const space = Entities.spacesStore.getById(spaceId);
  if (!space) {
    return false;
  }

  space.groupVisibility.setAllGroupsVisible(isVisible);

  const result = await request(space, isVisible);
  if (!result) {
    space.groupVisibility.setAllGroupsVisible(!isVisible);
  }

  return result;
};
