import classNames from 'classnames';
import React, { FC } from 'react';

import { ChatMessage } from 'APP/store/Messages/Message/ChatMessage/ChatMessage';

import { useDatePlaceholderPresenter } from './DatePlaceholder.presenter';
import styles from './DatePlaceholder.styles.m.css';

interface IDatePlaceholderProps {
  message: ChatMessage;
  selectorName: string;
}

export const DatePlaceholder: FC<IDatePlaceholderProps> = ({ message, selectorName }) => {
  const dayText = useDatePlaceholderPresenter(message);

  return (
    <div className={classNames(styles.container, selectorName)}>
      <span className={styles.text}>{dayText}</span>
    </div>
  );
};
