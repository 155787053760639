import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, ReactElement, useCallback } from 'react';

import { List } from 'APP/components/List/List';
import { Popup } from 'APP/components/Popup/Popup';
import { AddKeyPressHandler } from 'APP/packages/keyBoardHandler';
import { MenuItemsPlaceholder } from 'APP/packages/placeholders/MenuItemsPlaceholder/MenuItemsPlaceholder';
import { useTranslation } from 'APP/packages/translations';
import AddIcon from 'ICONS/ic-done.svg';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';
import { FAB } from 'UIKIT/FAB/FAB';
import { TextField } from 'UIKIT/TextField/TextField';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { useClosePopupOnNavigation } from '../../hooks/useClosePopupOnNavigation';
import { KeywordFilterItem } from './KeywordFilterItem/KeywordFilterItem';
import {
  IKeywordFiltersPopupParams,
  useKeywordFiltersPopupPresenter,
} from './KeywordFiltersPopup.presenter';
import styles from './KeywordFiltersPopup.styles.m.css';

type TKeywordFiltersPopupProps = ICommonPopupsProps<IKeywordFiltersPopupParams>;

export const KeywordFiltersPopup: FC<TKeywordFiltersPopupProps> = observer((props) => {
  const { t } = useTranslation();
  const presenter = useKeywordFiltersPopupPresenter(props);
  useClosePopupOnNavigation(props.popupInstance);

  const renderItem = useCallback((item: string): ReactElement => {
    return (
      <KeywordFilterItem
        value={item}
        onDelete={(): void => presenter.onDeleteValue(item)}
      />
    );
  }, []);

  return (
    <Popup
      title={t('manage_offencive_messages')}
      isOpened
      isHidden={props.popupInstance.isHidden}
      handlePrevStep={presenter.hasBackButton ? presenter.onBack : null}
      onClose={presenter.onClose}
    >
      <AddKeyPressHandler onEsc={presenter.onClose} />
      <div className={styles.content}>
        <div className={styles.header}>
          <p className={styles.description}>{t('content_filtering_description')}</p>
          <div className={styles.addContainer}>
            <TextField
              className={styles.addInput}
              name={'keywords-add-input'}
              value={presenter.addValue}
              label={t('comments_filtering_placeholder_add')}
              helperText={t('comments_filtering_placeholder')}
              maxLength={100}
              onChange={presenter.onAddValueChange}
              onKeyDown={presenter.onAddValueKeyDown}
            />
            <FAB
              className={styles.addButton}
              Icon={AddIcon}
              size="50"
              theme="21"
              isDisabled={!presenter.canAddValue}
              onClick={presenter.onAddValue}
            />
          </div>
        </div>

        <div className={styles.listContainer}>
          <div className={styles.divider} />
          <List
            className={classNames(styles.list, customScrollStyles.container)}
            isLoading={presenter.isLoading}
            data={presenter.list}
            keyExtractor={(item: string): string => item}
            renderItem={renderItem}
            SpinnerComponent={<MenuItemsPlaceholder count={5} />}
          />
        </div>
      </div>
    </Popup>
  );
});
