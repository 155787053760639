import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { InView } from 'react-intersection-observer';

import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

import { useLoadAboveObserverPresenter } from './LoadAboveObserver.presenter';
import styles from './LoadAboveObserver.styles.m.css';

interface ILoadAboveObserverProps {
  groupId: string;
  firstMessage: ChatMessage | null;
  rootElement: HTMLDivElement | null;
  offset: number;
  isDisabled: boolean;
}

export const LoadAboveObserver: FC<ILoadAboveObserverProps> = observer(
  ({ groupId, firstMessage, rootElement, offset, isDisabled }) => {
    const presenter = useLoadAboveObserverPresenter(groupId, firstMessage);

    return (
      <InView
        className={styles.container}
        root={rootElement}
        rootMargin={`${offset}px 0px 0px 0px`}
        skip={!presenter.hasLoadAboveObserver || isDisabled}
        onChange={presenter.onInViewChange}
      />
    );
  }
);
