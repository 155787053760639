import { observer } from 'mobx-react';
import React from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { PERMISSIONS_TYPES_TRANSLATIONS } from 'APP/constants/calls';
import { PermissionsTypes } from 'APP/model/callPermissions/callPermissionsModel.constants';
import { useTranslation } from 'APP/packages/translations';
import { RadioLabel } from 'UIKIT/RadioLabel/RadioLabel';

import { usePresenter } from './presenter';
import styles from './styles.m.css';

const descriptionTranslations = {
  [PermissionsTypes.SwitchOnCamera]: 'call_user_can_switch_camera',
  [PermissionsTypes.SwitchOnMic]: 'call_user_can_switch_mic',
  [PermissionsTypes.ShareScreen]: 'call_user_can_share_screen',
};

const CallChangeMemberPermissionPopupComponent = () => {
  const { t } = useTranslation();
  const presenter = usePresenter();

  if (!presenter.ongoingCall) {
    return null;
  }

  return (
    <Popup
      title={t(PERMISSIONS_TYPES_TRANSLATIONS[presenter.permissionType])}
      isOpened
      handlePrevStep={presenter.goBack}
      onClose={presenter.onClose}
    >
      <div className={styles.title}>{t(descriptionTranslations[presenter.permissionType])}</div>
      <div className={styles.wrapper}>
        {presenter.permissionValues.map((item) => (
          <RadioLabel {...item} />
        ))}
      </div>
    </Popup>
  );
};

export const CallChangeMemberPermissionPopup = observer(CallChangeMemberPermissionPopupComponent);
