import { IPersonalizedGroup } from 'APP/model/group/groupModel.types';

import { ICategoryInfo } from '../common/commonModel.types';
import { IGroup } from '../group/groupModel.types';
import { IMessageWithCommentCount } from '../message/messageModel.types';
import { ISpace } from '../space/spaceModel.types';
import { IBotUserDisplayData } from '../user/userModel.types';

export enum NewsType {
  Post = 'post',
  GuestPost = 'post.guest',
  Categories = 'categories',
  SuggestedChannels = 'suggested.channels',
}

export enum CatalogType {
  All = 'all',
  Channel = 'channel',
  Space = 'space',
  Bot = 'bot',
}

export enum CatalogItemType {
  Channel = 'channel',
  Space = 'space',
  Bot = 'bot',
}

export enum CatalogWidgetType {
  Type1 = 'VIEW_TYPE_1',
  Type2 = 'VIEW_TYPE_2',
  Type3 = 'VIEW_TYPE_3',
}

export interface ICatalogItem {
  itemType: CatalogItemType;
  channel?: IGroup;
  space?: ISpace;
  bot?: IBotUserDisplayData;
}

export interface ICatalogItemsList {
  cursor?: string;
  items: ICatalogItem[];
}

export interface ICatalogWidget {
  cursor: string | null;
  items: ICatalogItem[];
  order: number;
  title: string;
  viewType: CatalogWidgetType;
  widgetId: string;
}

export interface ICatalog {
  widgets: ICatalogWidget[];
}

export interface ISupportedLocale {
  countryCode: string;
  countryFlagEmoji: string;
  displayLanguageName: string;
  languageCode: string;
}

export interface ISupportedLocalesList {
  locales: ISupportedLocale[];
}

export interface INewsItem {
  elementType?: NewsType;
  title?: string;
  group?: IPersonalizedGroup;
  message?: IMessageWithCommentCount;
  channels?: IGroup[];
  categories?: ICategoryInfo[];
}

export interface INewsfeed {
  cursor?: string;
  values: INewsItem[];
}

export interface IGetCatalog {
  catalogType?: CatalogType;
}

export interface IGetCatalogWidget {
  cursor?: string;
  limit?: number;
  widgetId: string;
}

export interface ISearchCatalogItemsByName {
  catalogType: CatalogType;
  query: string;
  limit?: number;
  cursor?: string;
}

export interface ISearchCatalogItemsByCategory {
  catalogType: CatalogType;
  categoryId: string;
  limit?: number;
  cursor?: string;
}

export interface IUserLocaleChange {
  locales: string[];
}

export interface IGetNewsfeed {
  cursor?: string;
  limit?: number;
  spaceId?: string;
}

export interface IHideNewsfeedPosts {
  channelIds: string[];
}

export interface IUnhideNewsfeedPosts {
  channelIds: string[];
}

export interface IGetStartPageItems {
  catalogType: CatalogType.Space;
}

export enum RecommendationType {
  MostPopular = 'most_popular',
  Suggested = 'suggested',
}

export type StartPageItemType = ISpace;

export interface IStartPageItems<T extends StartPageItemType> {
  recommendationType: RecommendationType;
  items: T[];
}
