import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Entities from 'APP/store';
import { Call } from 'STORE/Calls/Call/Call';
import { Opponent } from 'STORE/Calls/Call/Opponent/Opponent';

interface IMinimizedCallViewPresenter {
  call: Call;
  videoOpponent: Opponent | null;
  hasVideo: boolean;
  groupLink: string | null;
  isOffset: boolean;
  onClick(): void;
}

export const useMinimizedCallViewPresenter = (): IMinimizedCallViewPresenter => {
  const presenter = useLocalStore<IMinimizedCallViewPresenter>(() => ({
    get call(): Call {
      return Entities.Calls.ongoingCall!;
    },

    get videoOpponent(): Opponent | null {
      const opponent = presenter.call.opponents.opponentsWithVideo.find(
        ({ uid }) => uid === presenter.call?.activeOpponentUid
      );
      return (
        opponent ||
        presenter.call.opponents.screenSharingOpponent ||
        presenter.call.opponents.opponentsWithVideo[0]
      );
    },

    get hasVideo(): boolean {
      return !!presenter.videoOpponent?.hasVideo;
    },

    get groupLink(): string | null {
      if (!presenter.call.group) {
        return null;
      }

      return presenter.call.group.routePath;
    },

    get isOffset(): boolean {
      return Entities.ChatStore.isShowSearch || Entities.ChatStore.isShowSidebar;
    },

    onClick(): void {
      Entities.ChatStore.hideSearch();
      Entities.ChatStore.hideSidebar();
    },
  }));

  useEffect(() => {
    Entities.OngoingCall.reset();
  }, []);

  return presenter;
};
