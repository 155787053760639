import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { useFileDownloadStatePresenter } from 'APP/hooks/useFileDownloadState/useFileDownloadState';
import DoneIcon from 'ICONS/ic-done-32.svg';
import { UploaderState } from 'MAIN/ChatView/components/UploaderState/UploaderState';
import type { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

import styles from './MediaUploadState.styles.m.css';

interface IVideoUploadStateProps {
  message: ChatMessage;
}

export const MediaUploadState: FC<IVideoUploadStateProps> = observer((props) => {
  const { message } = props;

  const presenter = useFileDownloadStatePresenter({
    payloadId: message.id,
    groupId: message.groupId,
    messageId: message.id,
  });

  return (
    <UploaderState
      className={styles.uploader}
      uploadFileData={{
        uploadProgress: message.payload.uploadProgress,
        cancelUpload: message.payload.cancelUploadAndDeleteMessage,
        hasError: message.hasError,
      }}
    >
      {message.isFake && !presenter.isWaitingDownloadProcess && !presenter.isDownloadInProcess ? (
        <DoneIcon className={styles.icon} />
      ) : null}
    </UploaderState>
  );
});
