import { TFunction } from 'react-i18next';

import {
  TSystemMessageTextHandler,
  ISystemMessageText,
  ISystemMessageTextPresenter,
} from '../useSystemMessageText.types';

export const chatCategoryChangedHandler: TSystemMessageTextHandler = (
  presenter: ISystemMessageTextPresenter,
  t: TFunction
): ISystemMessageText => {
  const result: ISystemMessageText = { html: '' };

  if (presenter.isPublicGroup || presenter.isChannel) {
    result.html = t('notification_chat_category_changed_without_name');
  } else {
    result.html = t('notification_chat_category_changed', {
      0: `<span class="system-message-title">${presenter.initiatorDisplayName}</span>`,
      interpolation: { escapeValue: false },
    });
    result.sourceText = t('notification_chat_category_changed');
    result.params = {
      '{0}': { text: presenter.initiatorDisplayName, userId: presenter.initiator?.id },
    };
  }

  return result;
};
