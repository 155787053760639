import Tasks from 'APP/Tasks';

const hasMediaPermissions = async (checkMicrophone = true) => {
  const hasAudioPermission = await Tasks.permissions.hasAudioPermissions(checkMicrophone);

  if (!hasAudioPermission) {
    return false;
  }

  return await Tasks.permissions.hasCameraPermission();
};

export default hasMediaPermissions;
