import { showContent as apiShowContent } from 'APP/model/group/groupModel';
import Entities from 'APP/store';

export async function showContent(groupId: string): Promise<void> {
  const group = Entities.GroupsStore.getGroupById(groupId);
  if (!group) {
    return;
  }

  try {
    group.setShowContent(true);
    await apiShowContent(groupId);
  } catch (e) {
    //
  }
}
