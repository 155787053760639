import execCommand from 'execcommand-copy';
import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { showConfirm, showConfirmWithCheckbox } from 'APP/Tasks/app/app';
import { openPopup } from 'APP/Tasks/app/popup/popup';
import { IDeleteMessagesMode } from 'APP/model/message/messageModel.types';
import { useTranslation } from 'APP/packages/translations';
import Entities from 'APP/store';
import { Schedule } from 'APP/store/Groups/Schedule';
import { ChatMessage } from 'APP/store/Messages/Message/ChatMessage/ChatMessage';
import { MultiSelect } from 'APP/store/Messages/MultiSelect/MultiSelect';
import { ForwardMessagePopup } from 'MAIN/PopupManager/Popups/ForwardMessagePopup/ForwardMessagePopup';
import { Popup } from 'STORE/App/Popups/Popup/Popup';
import { getMessageTextForCopy } from 'UTILS/message/message';

interface IMultiSelectPresenter {
  childPopup: Popup | null;
  multiSelect: MultiSelect;
  hasSelectedMessages: boolean;
  selectedMessagesIds: string[];
  selectedMessages: ChatMessage[];
  amountOfMessages: number;
  groupId: string | null;
  canBeDeleted: boolean;
  canBeCopied: boolean;
  canBeForwarded: boolean;
  canBeSendNow: boolean;
  copyToClipboard(): void;
  close(): void;
  forward(): void;
  sendNow(): void;
  showDeleteConfirm(): Promise<{
    canDelete: boolean;
    deleteMode: IDeleteMessagesMode;
  }>;
  delete(): Promise<void>;
}

export function useMultiSelectPresenter(): IMultiSelectPresenter {
  const { t } = useTranslation();

  const presenter = useLocalStore<IMultiSelectPresenter>(() => ({
    childPopup: null,

    get multiSelect(): MultiSelect {
      return Entities.GroupsStore.activeGroup!.messagesStore.multiSelect;
    },

    get hasSelectedMessages(): boolean {
      return presenter.multiSelect.hasSelectedMessages || false;
    },

    get selectedMessagesIds(): string[] {
      return Array.from(presenter.multiSelect.selectedMessagesIds || []);
    },

    get selectedMessages(): ChatMessage[] {
      return (
        presenter.multiSelect.selectedMessages.sort(
          (a, b) => a.expectedServerTime - b.expectedServerTime
        ) || []
      );
    },

    get amountOfMessages(): number {
      return presenter.selectedMessagesIds.length;
    },

    get groupId(): string | null {
      return Entities.GroupsStore.activeGroupId;
    },

    get canBeDeleted(): boolean {
      return presenter.selectedMessages.every(
        (message) => message.canBeDeleteForMe || message.canBeDelete
      );
    },

    get canBeCopied(): boolean {
      return presenter.selectedMessages.every((message) => message.canBeCopied);
    },

    get canBeForwarded(): boolean {
      return presenter.selectedMessages.every((message) => message.canBeForward);
    },

    get canBeSendNow(): boolean {
      return presenter.selectedMessages.every((message) => message.canBeSendNow);
    },

    copyToClipboard: (): void => {
      const textForCopyArr = presenter.selectedMessages.map((message) => {
        return getMessageTextForCopy(message, presenter.selectedMessages.length > 1);
      });
      const textForCopy = textForCopyArr.join('\n\n');

      execCommand.copy(textForCopy);
      presenter.multiSelect.clearSelectedMessages();
    },

    close: (): void => {
      presenter.multiSelect.clearSelectedMessages();
    },

    forward: (): void => {
      if (!presenter.groupId) {
        return;
      }
      presenter.childPopup = openPopup(ForwardMessagePopup, {
        params: { groupId: presenter.groupId, messageIds: presenter.selectedMessagesIds },
      });
    },

    sendNow: (): void => {
      Tasks.messaging.sendNowScheduledMessages({
        groupId: presenter.groupId,
        messages: presenter.selectedMessages,
      });
    },

    async showDeleteConfirm(): Promise<{
      canDelete: boolean;
      deleteMode: IDeleteMessagesMode;
    }> {
      let canDeleteAllMessagesForAll = true;
      let canDeleteAllMessagesByMe = true;
      let hasRegularMessages = false;
      let isAllMessagesDeletedByMe = true;
      let isAllRegularMessages = true;

      const isMultipleMessages = this.selectedMessages.length > 1;

      this.selectedMessages.forEach((selectMessage) => {
        if (!selectMessage.canBeDelete) {
          canDeleteAllMessagesForAll = false;
        }
        if (!selectMessage.canBeDeleteForMe) {
          canDeleteAllMessagesByMe = false;
        }

        if (selectMessage.isDeletedByMe) {
          isAllRegularMessages = false;
          return;
        }

        isAllMessagesDeletedByMe = false;
        hasRegularMessages = true;
      });

      let canDelete = false;
      let deleteMode = IDeleteMessagesMode.All;

      if (
        (isAllRegularMessages && !canDeleteAllMessagesByMe) ||
        (isAllMessagesDeletedByMe && canDeleteAllMessagesForAll)
      ) {
        const confirmTextTranslationKey = isMultipleMessages
          ? 'message_delete_several_alert'
          : 'delete_message';

        canDelete = await showConfirm({ text: t(confirmTextTranslationKey) });
        return { canDelete, deleteMode };
      }

      if (hasRegularMessages && !canDeleteAllMessagesForAll) {
        const confirmTextTranslationKey = isMultipleMessages
          ? 'common_delete_for_me_alert_plural'
          : 'common_delete_for_me_alert';

        canDelete = await showConfirm({ text: t(confirmTextTranslationKey) });
        deleteMode = IDeleteMessagesMode.Me;
        return { canDelete, deleteMode };
      }

      const confirmTextTranslationKey = isMultipleMessages
        ? 'message_delete_several_alert'
        : 'delete_message';

      const { isConfirmed, isChecked } = await showConfirmWithCheckbox({
        text: t(confirmTextTranslationKey),
        checkBoxText: t('delete_for_me'),
      });

      canDelete = isConfirmed;
      deleteMode = isChecked ? IDeleteMessagesMode.Me : IDeleteMessagesMode.All;

      return { canDelete, deleteMode };
    },

    async delete(): Promise<void> {
      const { canDelete, deleteMode } = await presenter.showDeleteConfirm();

      if (!canDelete) {
        return;
      }

      const group = Entities.GroupsStore.activeGroup;

      if (!group || !presenter.selectedMessages) {
        return;
      }

      const data = {
        groupId: group.id,
        messages: presenter.selectedMessages,
      };

      if (group instanceof Schedule && group.isSchedule) {
        await Tasks.messaging.deleteScheduledMessages(data);
      } else {
        await Tasks.messaging.deleteMessages({
          ...data,
          mode: deleteMode,
        });
      }
    },
  }));

  useEffect(() => {
    return (): void => presenter.childPopup?.close();
  }, []);

  return presenter;
}
