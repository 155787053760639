import { useAsObservableSource, useLocalStore } from 'mobx-react';

import Entities from 'APP/store';
import type { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

import { ISenderNamePresenterLocalStore } from './SenderName.types';

export const useSenderNamePresenter = (message: ChatMessage) => {
  const source = useAsObservableSource({ message });

  const presenter = useLocalStore<ISenderNamePresenterLocalStore>(() => ({
    get isShow() {
      return source.message.isSenderNameShow;
    },

    get isSavedMessages(): boolean {
      return Boolean(presenter.group?.isSavedMessages);
    },

    get isForwardToSavedMessages(): boolean {
      return source.message.isForwardToSavedMessages;
    },

    get senderUser() {
      return source.message.senderUser;
    },

    get group() {
      return Entities.GroupsStore.getGroupById(source.message.groupId);
    },

    get withMeInAdmins(): boolean {
      return Boolean(presenter.group?.withMeInAdmins);
    },
  }));

  return presenter;
};
