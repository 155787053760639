import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import { openPopup } from 'APP/Tasks/app/popup/popup';
import { IStickerPackExtend } from 'APP/model/stickers/stickersModel.types';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import { StickerPackPopup } from 'MAIN/PopupManager/Popups/StickerPackPopup/StickerPackPopup';
import { Popup } from 'STORE/App/Popups/Popup/Popup';

interface IStickersSettingsPopupPresenter {
  childPopup: Popup | null;
  onClose(): void;
  openStickerPackPopup(stickerPack: IStickerPackExtend): void;
}

export function useStickersSettingsPopupPresenter({
  popupInstance,
}: ICommonPopupsProps): IStickersSettingsPopupPresenter {
  const presenter = useLocalStore<IStickersSettingsPopupPresenter>(() => ({
    childPopup: null,

    onClose(): void {
      popupInstance.close();
    },

    openStickerPackPopup(stickerPack: IStickerPackExtend): void {
      popupInstance.hide();

      presenter.childPopup = openPopup(StickerPackPopup, {
        params: {
          stickerPackId: stickerPack.stickerPack.id,
        },
        onBack: () => popupInstance.show(),
        onClose: () => presenter.onClose(),
      });
    },
  }));

  useEffect(() => {
    return () => presenter.childPopup?.close();
  }, []);

  return presenter;
}
