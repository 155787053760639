/**
 * Rest API
 * Documentation Rest API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpdatePermissionsRequestForAll { 
    channelId: string;
    status: UpdatePermissionsRequestForAllStatusEnum;
}
export enum UpdatePermissionsRequestForAllStatusEnum {
    Approved = 'APPROVED',
    Rejected = 'REJECTED'
};



