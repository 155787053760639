import api from 'APP/packages/api';
import Entities from 'STORE';

async function request(data) {
  try {
    return await api.group.deleteGroupDeepLink(data);
  } catch (error) {
    console.error(error);
  }

  return null;
}

export default async ({ groupId }) => {
  const personalizedGroup = await request({ groupId });

  if (!personalizedGroup) {
    return;
  }

  Entities.GroupsStore.merge([personalizedGroup]);
};
