import api from 'APP/packages/api';
import logger from 'APP/packages/logger';

import { IBooleanResponse, ICursorRequest } from '../common/commonModel.types';
import { buildModelError } from '../error/errorBuilder';
import {
  IStickerPackExtend,
  IStickerPackRequest,
  IStickerPacksList,
  IUserStickerPacks,
} from './stickersModel.types';

export const getPublicStickerPacks = async (data: ICursorRequest): Promise<IStickerPacksList> => {
  try {
    const response = await api.stickers.getPublicStickerPacks(data);

    return {
      cursor: response.cursor || null,
      items: response.items,
    } as IStickerPacksList; //TODO: I have asked BE to add enum to type property inside ISticker
  } catch (error) {
    logger.get('API').error('stickers.getPublicStickerPacks', error);
    throw buildModelError(error);
  }
};

export const getUserInstalledStickerPacks = async (): Promise<IUserStickerPacks> => {
  try {
    return (await api.stickers.getUserInstalledStickerPacks()) as IUserStickerPacks; //TODO: I have asked BE to add enum to type property inside ISticker
  } catch (error) {
    logger.get('API').error('stickers.getUserInstalledStickerPacks', error);
    throw buildModelError(error);
  }
};

export const addStickerPackToProfile = async (
  data: IStickerPackRequest
): Promise<IBooleanResponse> => {
  try {
    return await api.stickers.addStickerPackToProfile(data);
  } catch (error) {
    logger.get('API').error('stickers.addStickerPackToProfile', error);
    throw buildModelError(error);
  }
};

export const getStickerPackById = async (
  data: IStickerPackRequest
): Promise<IStickerPackExtend> => {
  try {
    return (await api.stickers.getStickerPackById(data)) as IStickerPackExtend; //TODO: I have asked BE to add enum to type property inside ISticker
  } catch (error) {
    logger.get('API').error('stickers.getStickerPackById', error);
    throw buildModelError(error);
  }
};

export const removeStickerPackFromProfile = async (
  data: IStickerPackRequest
): Promise<IBooleanResponse> => {
  try {
    return await api.stickers.removeStickerPackFromProfile(data);
  } catch (error) {
    logger.get('API').error('stickers.removeStickerPackFromProfile', error);
    throw buildModelError(error);
  }
};
