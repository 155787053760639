import { useTranslation } from 'APP/packages/translations';
import CalendarIcon from 'ICONS/ic-calendar.svg';
import { IPopoverMenuItem } from 'UIKIT/PopoverMenu/PopoverMenu.types';

export interface IMediaMessageSubmitMenuParams {
  onSubmit(isScheduled?: boolean): Promise<void>;
}

interface IMediaMessageSubmitMenuPresenter {
  menuItems: IPopoverMenuItem[];
}

export const useMediaMessageSubmitMenuPresenter = (
  params: IMediaMessageSubmitMenuParams
): IMediaMessageSubmitMenuPresenter => {
  const { onSubmit } = params;
  const { t } = useTranslation();

  const presenter = {
    get menuItems(): IPopoverMenuItem[] {
      return [
        {
          key: 'scheduleMessage',
          icon: CalendarIcon,
          title: t('common:schedule_message'),
          onClick: () => onSubmit(true),
        },
      ];
    },
  };

  return presenter;
};
