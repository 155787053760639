export function enterFullScreen(element: HTMLElement = document.documentElement): Promise<void> {
  return element.requestFullscreen ? element.requestFullscreen() : Promise.resolve();
}

export function exitFullScreen(): Promise<void> {
  return document.exitFullscreen ? document.exitFullscreen() : Promise.resolve();
}

export function toggleFullScreen(element = document.documentElement): Promise<void> {
  return document.fullscreenElement ? exitFullScreen() : enterFullScreen(element);
}

export function isFullscreen(): boolean {
  return !!document.fullscreenElement;
}
