import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { Switch, Route } from 'react-router-dom';

import { AuthImage } from 'APP/components/AuthImage/AuthImage';
import { RouterLink } from 'APP/router/constants';
import GemLogo from 'ICONS/gem-logo-56.svg';
import { IconView } from 'UIKIT/IconView/IconView';
import { IconViewSizes } from 'UIKIT/IconView/IconView.types';

import { useCallInvitationPresenter } from './CallInvitation.presenter';
import styles from './CallInvitation.styles.m.css';
import { JoinBlock } from './JoinBlock/JoinBlock';
import { NameBlock } from './NameBlock/NameBlock';

export const CallInvitation: FC = observer(() => {
  const presenter = useCallInvitationPresenter();

  return (
    <div className={styles.container}>
      <AuthImage theme={presenter.currentTheme} />
      <div className={styles.content}>
        <IconView
          Icon={GemLogo}
          size={IconViewSizes.Auto}
          className={styles.logo}
        />
        <Switch>
          <Route
            exact
            path={
              presenter.isGroupCall ? RouterLink.callInvitationGroup : RouterLink.callInvitation
            }
          >
            <JoinBlock
              isGroupCall={presenter.isGroupCall}
              invitationInfo={presenter.invitationInfo}
            />
          </Route>
          <Route
            path={`${
              presenter.isGroupCall ? RouterLink.callInvitationGroup : RouterLink.callInvitation
            }/name`}
          >
            <NameBlock />
          </Route>
        </Switch>
      </div>
    </div>
  );
});
