import { useTranslation } from 'APP/packages/translations';

import spaceCategory from '../fields/category';
import spaceAlias from '../fields/spaceAlias';
import spaceName from '../fields/spaceName';
import asyncValidation from '../hooks/asyncValidation';
import syncValidation from '../hooks/syncValidation';

export default () => {
  const { t } = useTranslation();

  const nameErrorToText = {
    [spaceName.ERRORS.IS_EMPTY]: ' ',
    [spaceName.ERRORS.TOO_SHORT]: t('username_min_length_error'),
  };

  const spaceCategoryErrorToText = {
    [spaceCategory.ERRORS.IS_EMPTY]: ' ',
  };

  const aliasErrorToText = {
    [spaceName.ERRORS.IS_EMPTY]: ' ',
    [spaceAlias.ERRORS.TOO_SHORT]: t('username_min_length_error'),
    [spaceAlias.ERRORS.INCORRECT_SYMBOLS]: t('channel_alias_allowed_symbols_error'),
    [spaceAlias.ERRORS.IS_ALREADY_EXIST]: t('channel_link_is_not_available'),
  };

  const nameValidator = syncValidation();
  const spaceCategoryValidator = syncValidation();
  const aliasValidator = asyncValidation();

  return {
    spaceName: {
      validate: (value, formData, meta) => {
        return nameValidator({
          value,
          meta,
          validate: spaceName.validate,
          errorToText: nameErrorToText,
        });
      },
    },
    spaceCategory: {
      validate: (value, formData, meta) => {
        return spaceCategoryValidator({
          value,
          meta,
          validate: spaceCategory.validate,
          errorToText: spaceCategoryErrorToText,
        });
      },
    },
    spaceAliasCreate: {
      validate: (value, formData, meta) => {
        return aliasValidator({
          value,
          values: formData,
          meta,
          validate: spaceAlias.validateCreate,
          asyncValidate: spaceAlias.asyncValidate,
          errorToText: aliasErrorToText,
        });
      },
    },
    spaceAliasEdit: {
      validate: (value, formData, meta) => {
        return aliasValidator({
          value,
          values: formData,
          meta,
          validate: spaceAlias.validateEdit,
          asyncValidate: spaceAlias.asyncValidate,
          errorToText: aliasErrorToText,
        });
      },
    },
  };
};
