import TagManager from 'react-gtm-module';

import {
  IAnalyticsAdapter,
  IAnalyticsEvent,
  IAnalyticsOptions,
  IAnalyticsUserConfig,
} from '../AnalyticsClient.types';

export class GtmAdapter implements IAnalyticsAdapter {
  private readonly isInitialized: boolean = false;

  constructor(gtmId?: string) {
    if (gtmId) {
      TagManager.initialize({
        gtmId,
      });
      this.isInitialized = true;
    }
  }

  public setUserConfig(user: IAnalyticsUserConfig): void {
    return;
  }

  public track(event: IAnalyticsEvent, options: IAnalyticsOptions): void {
    return;
  }
}
