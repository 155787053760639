import classNames from 'classnames';
import React from 'react';

import HelperText from './HelperText';
import styles from './style.m.scss';

const FieldWithPrefix = ({
  className,
  input: { name, onChange, value },
  meta: { error, invalid, modified, validating, dirty, valid },
  label,
  placeholder,
  maxLength,
  isDisabled = false,
  helperText,
  validatingText,
  successText,
}) => {
  const hasError = !validating && (modified || value) && invalid && Boolean(error.trim());
  const hasSuccess = Boolean(successText) && !validating && dirty && valid;
  const isValidating = Boolean(validatingText) && dirty && validating;

  const contentClasses = classNames(styles.content, {
    [styles.error]: hasError,
    [styles.success]: hasSuccess,
    [styles.disabled]: isDisabled,
  });

  return (
    <div className={className}>
      <label className={contentClasses}>
        <span className={styles.label}>{label}</span>
        <input
          name={name}
          className={styles.input}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          maxLength={maxLength}
          disabled={isDisabled}
          autoComplete="off"
        />
      </label>
      <div className={styles.helperText}>
        <HelperText
          isValidating={isValidating}
          validatingText={validatingText}
          hasError={hasError}
          errorText={error}
          hasSuccess={hasSuccess}
          successText={successText}
          helperText={helperText}
        />
      </div>
    </div>
  );
};

export default FieldWithPrefix;
