import { groupModel } from 'APP/model/model';

export async function removeGroupAdmins(
  groupId: string,
  userIds: string[]
): Promise<{ error: string | null }> {
  try {
    await groupModel.removeGroupAdmins({ groupId, userIds });

    return { error: null };
  } catch (error) {
    return { error: error.message };
  }
}
