import { IClientConfigProperty } from 'APP/model/auth/authModel.types';
import logger from 'APP/packages/logger';
import Entities from 'APP/store';

export enum OnboardingFeatures {
  CollectionsIntro = 'collections_intro',
}

interface OnboardingItem {
  feature: OnboardingFeatures;
  url: string;
}

export function handleOnboarding(property: IClientConfigProperty): void {
  try {
    const onboardings: OnboardingItem[] = JSON.parse(property.value);

    if (!Array.isArray(onboardings)) {
      return;
    }

    onboardings.forEach((onboarding) => {
      switch (onboarding.feature) {
        case OnboardingFeatures.CollectionsIntro:
          Entities.clientConfig.setConfig(OnboardingFeatures.CollectionsIntro, onboarding.url);
      }
    });
  } catch (error) {
    logger.get('clientConfig').error('handleOnboarding', error);
  }
}
