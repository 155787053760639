/**
 * Rest API
 * Documentation Rest API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CallMemberV2 { 
    cameraId: string;
    screenId: string;
    userId: string;
    username: string;
    avatarUrl?: string;
    status: CallMemberV2StatusEnum;
    isHandRaised: boolean;
    platform?: CallMemberV2PlatformEnum;
    hasPermissionRequest: boolean;
}
export enum CallMemberV2StatusEnum {
    Declined = 'DECLINED',
    Finished = 'FINISHED',
    Initiated = 'INITIATED',
    Joined = 'JOINED',
    Added = 'ADDED',
    Removed = 'REMOVED'
};
export enum CallMemberV2PlatformEnum {
    Android = 'android',
    Huawei = 'huawei',
    Web = 'web',
    Ios = 'ios',
    Safari = 'safari',
    Desktop = 'desktop'
};



