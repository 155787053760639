import { useLocalStore } from 'mobx-react';

import {
  PermissionsPersonalizedValue,
  PermissionsTypes,
} from 'APP/model/callPermissions/callPermissionsModel.constants';
import { useTranslation } from 'APP/packages/translations';
import routerConst from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import Entities from 'STORE';

const permissionsTranslations = {
  [PermissionsTypes.SwitchOnCamera]: {
    [PermissionsPersonalizedValue.Always]: 'call_permissions_custom_camera_always',
    [PermissionsPersonalizedValue.Never]: 'call_permissions_custom_camera_never',
    [PermissionsPersonalizedValue.AfterApprove]: 'call_permissions_custom_camera_after_approval',
  },
  [PermissionsTypes.SwitchOnMic]: {
    [PermissionsPersonalizedValue.Always]: 'call_permissions_custom_mic_always',
    [PermissionsPersonalizedValue.Never]: 'call_permissions_custom_mic_never',
    [PermissionsPersonalizedValue.AfterApprove]: 'call_permissions_custom_mic_after_approval',
  },
  [PermissionsTypes.ShareScreen]: {
    [PermissionsPersonalizedValue.Always]: 'call_permissions_sharing_always',
    [PermissionsPersonalizedValue.Never]: 'call_permissions_sharing_never',
    [PermissionsPersonalizedValue.AfterApprove]: 'call_permissions_sharing_after_approval',
  },
};

export const usePresenter = (userId) => {
  const navigateTo = useNavigateTo();
  const { t } = useTranslation();

  const presenter = useLocalStore(() => ({
    get permissions() {
      const personalizedPermissions =
        Entities.Calls.ongoingCall?.permissions.customPermissions.get(userId);

      return Entities.Calls.ongoingCall?.permissions.getMemberPersonalizedPermissions(
        personalizedPermissions
      );
    },

    get member() {
      return Entities.Calls.ongoingCall.members.getMemberById(userId);
    },

    get permissionsTranslations() {
      return Object.values(PermissionsTypes)
        .map((permission) => {
          const permissionValue = presenter.permissions[permission];

          return t(permissionsTranslations[permission][permissionValue]);
        })
        .join(', ');
    },

    onMemberClick: () => {
      Entities.Calls.ongoingCall.permissions.setEditedPermissions(presenter.permissions, userId);

      navigateTo({
        query: {
          [routerConst.params.popup]: routerConst.paramsEnum.popup.callMemberPermissions,
          [routerConst.params.memberPermissionsId]: userId,
        },
      });
    },
  }));

  return presenter;
};
