import { createBrowserHistory } from 'history';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import Entities from 'STORE';

export function useIsBackNavigationHandler(): void {
  const location = useLocation();
  const locationKey = useRef<string>('');

  useEffect(() => {
    const history = createBrowserHistory();
    history.listen((location, action) => {
      if (action === 'POP') {
        locationKey.current = location.key || '';
        Entities.App.setIsBackPage(true);
      }
    });
  }, []);

  useEffect(() => {
    // browser event is trigger before changes from react router
    if (locationKey.current !== location.key) {
      Entities.App.setIsBackPage(false);
    }
  }, [location]);
}
