import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { GroupAvatar } from 'APP/components/Avatar/GroupAvatar/GroupAvatar';
import { VerifiedBadge } from 'APP/components/VerifiedBadge/VerifiedBadge';
import { Group } from 'STORE/Groups/Group';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';
import { Switch } from 'UIKIT/Switch/Switch';

import { useGroupVisibilityItemPresenter } from './GroupVisibilityItem.presenter';
import styles from './GroupVisibilityItem.styles.m.css';

interface IGroupVisibilityItemProps {
  group: Group;
}

export const GroupVisibilityItem: FC<IGroupVisibilityItemProps> = observer(({ group }) => {
  const presenter = useGroupVisibilityItemPresenter(group);

  return (
    <label className={styles.container}>
      <GroupAvatar
        group={group}
        type={group.isChannel ? AvatarTypes.Square_20 : AvatarTypes.Round_10}
        hasLockIcon={group.isChannel && group.isClosed}
      />

      <div className={styles.titleWrapper}>
        <span className={styles.title}>{group.avatarTitle}</span>
        {group.verified && <VerifiedBadge />}
      </div>

      <Switch
        className={styles.switch}
        isChecked={presenter.isVisible}
        onChange={presenter.onToggle}
      />
    </label>
  );
});
