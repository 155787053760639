import React, { FC } from 'react';

import { IStoryVideoPresenterOptions, useStoryVideoPresenter } from './StoryVideo.presenter';
import styles from './StoryVideo.styles.m.css';

type IStoryVideoProps = IStoryVideoPresenterOptions;

export const StoryVideo: FC<IStoryVideoProps> = (props) => {
  const { videoRef, onMouseDown } = useStoryVideoPresenter(props);

  return (
    <video
      className={styles.video}
      ref={videoRef}
      src={props.story.url}
      autoPlay
      onMouseDown={onMouseDown}
    />
  );
};
