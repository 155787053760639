import { action, computed, observable } from 'mobx';

import { platformInfo } from 'APP/packages/platformInfo/platformInfo';
import Entities from 'STORE';

class Devices {
  @observable data = new Map();
  @observable firstTimeLoaded = false;
  @observable currentSession = {
    platform: platformInfo.platform,
    device: platformInfo.device,
    osVersion: platformInfo.osVersion,
    appVersion: Entities.App.version,
  };
  @observable sessionTTl = null;

  constructor(root) {
    this.root = root;
  }

  @action reset() {
    this.data.clear();
    this.firstTimeLoaded = false;
    this.sessionTTl = null;
  }

  @computed
  get sessions() {
    return Array.from(this.data.values());
  }

  getSessionByKey = (sessionKey) => {
    return this.data.get(sessionKey);
  };

  @action
  setSessionTtl = (ttl) => {
    this.sessionTTl = ttl;
  };

  @action
  setCurrentSession = (session) => {
    this.firstTimeLoaded = true;
    this.currentSession = session;
  };

  @action
  setSessions = (sessions) => {
    const newData = new Map();
    sessions.forEach((item) => {
      newData.set(item.registrationKey, item);
    });

    this.data = newData;
  };

  @action
  addSession = (session) => {
    this.data.set(session.registrationKey, session);
  };

  @action
  removeSession = (registrationKey) => {
    if (this.data.has(registrationKey)) {
      this.data.delete(registrationKey);
    }
  };

  @action
  clearSessions = () => {
    this.data.clear();
  };
}

export default Devices;
