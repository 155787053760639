import classNames from 'classnames';
import React from 'react';

import TimeIcon from 'APP/icons/ic-time-s.svg';
import dateService from 'APP/packages/date';

import HelperText from './HelperText';
import prepareValue from './prepareValue';
import styles from './styles.m.css';

const TextField = ({
  className,
  input: { name, onChange, value, onFocus, onBlur },
  meta: { active, error, invalid, valid, dirty, modified, validating },
  label,
  placeholder,
  isDisabled = false,
  helperText,
  validatingText,
  successText,
  setRef,
  readOnly = false,
  min = '',
  max = '',
  ignoreTrimError = false,
}) => {
  const hasError =
    !validating && modified && invalid && Boolean(ignoreTrimError ? error : error.trim());
  const hasSuccess = Boolean(successText) && !validating && dirty && valid;
  const isValidating = Boolean(validatingText) && dirty && validating;
  const preparedValue = prepareValue(value, min, max);
  const isHour12 = dateService.isHour12(true);

  const containerClasses = classNames(className, {
    [styles.hour12]: isHour12,
    [styles.hour24]: !isHour12,
  });

  const contentClasses = classNames(styles.content, {
    [styles.focused]: active,
    [styles.error]: hasError,
    [styles.success]: hasSuccess,
    [styles.disabled]: isDisabled,
    [styles.withLabel]: label,
  });

  const labelClasses = classNames(styles.label, {
    [styles.smallLabel]: active || Boolean(placeholder) || Boolean(value),
  });

  const inputHandler = (event) => {
    event.currentTarget.value = prepareValue(event.currentTarget.value, min, max);
  };

  return (
    <div className={containerClasses}>
      <label className={contentClasses}>
        {label ? <span className={labelClasses}>{label}</span> : null}
        {placeholder && <span className={styles.placeholder}>{placeholder}</span>}
        <div className={styles.resizer}>
          <input
            name={name}
            type="time"
            onFocus={onFocus}
            onBlur={onBlur}
            className={styles.input}
            value={preparedValue}
            onInput={inputHandler}
            onChange={onChange}
            disabled={isDisabled}
            autoComplete="off"
            ref={setRef}
            readOnly={readOnly}
            min={min || ''}
            max={max || ''}
          />
          <div className={styles.inputIcon}>
            <TimeIcon />
          </div>
          {placeholder && <span className={styles.hiddenPlaceholder}>{preparedValue}</span>}
        </div>
      </label>
      <div
        className={styles.helperText}
        data-test={`${name}-helper-text`}
      >
        <HelperText
          isValidating={isValidating}
          validatingText={validatingText}
          hasError={hasError}
          errorText={error}
          hasSuccess={hasSuccess}
          successText={successText}
          helperText={helperText}
        />
      </div>
    </div>
  );
};

export default TextField;
