import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { useTranslation } from 'APP/packages/translations';
import { useClosePopupOnNavigation } from 'MAIN/PopupManager/hooks/useClosePopupOnNavigation';
import { SearchInput } from 'UIKIT/SearchInput/SearchInput';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { ParticipantList } from './ParticipantList/ParticipantList';
import {
  IParticipantsPopupParams,
  useParticipantsPopupPresenter,
} from './ParticipantsPopup.presenter';
import styles from './ParticipantsPopup.styles.m.css';

export const ParticipantsPopup: FC<ICommonPopupsProps<IParticipantsPopupParams>> = observer(
  (props) => {
    const { popupInstance } = props;

    const { t } = useTranslation();

    const presenter = useParticipantsPopupPresenter(props);

    useClosePopupOnNavigation(props.popupInstance);

    return (
      <Popup
        title={presenter.title}
        isOpened={!!presenter.group}
        isHidden={popupInstance.isHidden}
        handlePrevStep={presenter.hasBackButton ? presenter.onBack : null}
        onClose={presenter.onClose}
      >
        <SearchInput
          className={styles.search}
          placeholder={t('search_hint')}
          value={presenter.searchText}
          onChange={presenter.onSearch}
        />

        <ParticipantList
          group={presenter.group}
          searchText={presenter.searchText}
          onClosePopup={presenter.onClose}
          onAddParticipantClick={presenter.onAddParticipantClick}
        />
      </Popup>
    );
  }
);
