import { observer } from 'mobx-react';
import React from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { useTranslation } from 'APP/packages/translations';

import { useAddMembersPopupPresenter } from './AddMembersPopup.presenter';
import { Content } from './Content/Content';

export const AddMembersPopup = observer(() => {
  const { t } = useTranslation();
  const presenter = useAddMembersPopupPresenter();

  if (!presenter.group) {
    return null;
  }

  return (
    <Popup
      isOpened={presenter.isAvailableToShow}
      title={t('add_people_screen_title')}
      onClose={presenter.onClose}
    >
      <Content
        onClose={presenter.onClose}
        groupId={presenter.group.id}
      />
    </Popup>
  );
});
