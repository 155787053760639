import { observer } from 'mobx-react';
import React from 'react';

import { useSenderNamePresenter } from './SenderName.presenter';
import { SenderNameForAdmins } from './SenderNameForAdmins/SenderNameForAdmins';
import { SenderNameForUser } from './SenderNameForUser/SenderNameForUser';

export const SenderName = observer(({ message, className }) => {
  const presenter = useSenderNamePresenter(message);

  if (!presenter.isShow) {
    return null;
  }

  if (presenter.withMeInAdmins && !presenter.isSavedMessages) {
    return (
      <SenderNameForAdmins
        message={message}
        className={className}
      />
    );
  }

  return (
    <SenderNameForUser
      message={message}
      className={className}
    />
  );
});
