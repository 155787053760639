import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import { MEDIA_MAX_SIZE } from 'APP/constants/articles';
import { getWidthHeightByMaxValues } from 'APP/packages/imageResizer';

import useUploadFile from '../../../hooks/useUploadFile';
import prepareImageUrls from '../../../services/prepareImageUrls';

export default ({ parentPresenter, payload, order, id }) => {
  const { payloadType, userFile, graphicSize, url: payloadUrl, localPreview } = payload;
  const { error, uploadProgress, url, cancelUpload } = useUploadFile({
    userFile: userFile,
  });
  const source = useAsObservableSource({
    uploadProgress,
    url,
    cancelUpload,
    order,
    localPreview,
  });

  const presenter = useLocalStore(() => ({
    setPayload: () => {
      parentPresenter.editPayloadByOrder(presenter.payload, source.order);
    },

    cancelUploadAndDeleteMessage: () => {
      source.cancelUpload();

      parentPresenter.removeSomethingLoading(id);
      parentPresenter.deletePayloadByOrder(source.order);
    },

    waitingUrl: () => {
      if (!source.url && !payloadUrl) {
        parentPresenter.addSomethingLoading(id);

        return;
      }

      parentPresenter.removeSomethingLoading(id);
      presenter.setPayload();
    },

    get previewUrl() {
      return localPreview || payloadUrl || source.url;
    },

    get message() {
      return {
        payload: {
          ...presenter.payload,
          id,
          previewSize: { ...graphicSize },
          uploadProgress: source.uploadProgress,
          url: presenter.previewUrl,
          cancelUploadAndDeleteMessage: presenter.cancelUploadAndDeleteMessage,
        },
        hasError: Boolean(error),
      };
    },

    get containerStyle() {
      const { height } = getWidthHeightByMaxValues(
        graphicSize.width,
        graphicSize.height,
        MEDIA_MAX_SIZE.width,
        MEDIA_MAX_SIZE.height
      );

      return {
        maxHeight: `${MEDIA_MAX_SIZE.height}px`,
        height: `${height}px`,
      };
    },

    get url() {
      const { imageUrl } = prepareImageUrls(presenter.previewUrl);
      return imageUrl;
    },

    get payload() {
      return {
        payloadType: payloadType,
        comment: '',
        localPreview: payloadUrl || source.url,
        url: payloadUrl || source.url,
        graphicSize: { ...graphicSize },
        file: userFile,
      };
    },
  }));

  useEffect(() => {
    presenter.waitingUrl();
  }, [url]);

  return presenter;
};
