import { useAsObservableSource, useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { BackgroundCall } from 'STORE/BackgroundCalls/BackgroundCall/BackgroundCall';
import { Call } from 'STORE/Calls/Call/Call';
import { Group } from 'STORE/Groups/Group';

interface IGroupItemPresenter {
  hasMention: boolean;
  hasUnread: boolean;
  numberOfUnread: number;
  hasPinedIcon: boolean;
  backgroundCall: BackgroundCall | null;
  activeCall: Call | null;
  onJoinToCall(): void;
}

export const useGroupItemPresenter = (group: Group): IGroupItemPresenter => {
  const source = useAsObservableSource({ group });

  const presenter = useLocalStore<IGroupItemPresenter>(() => ({
    get hasMention(): boolean {
      return source.group.unreadUserMentionsCounter > 0;
    },

    get hasUnread(): boolean {
      return source.group.numberOfUnread > 0 || source.group.unread;
    },

    get numberOfUnread(): number {
      return source.group.numberOfUnread;
    },

    get hasPinedIcon(): boolean {
      return !source.group.numberOfUnread && source.group.isPinned && !source.group.unread;
    },

    get backgroundCall(): BackgroundCall | null {
      return source.group.backgroundCall;
    },

    get activeCall(): Call | null {
      return source.group.activeCall || null;
    },

    onJoinToCall(): void {
      if (!presenter.backgroundCall) {
        return;
      }

      Tasks.calls.joinToCall({
        groupId: source.group.id,
        channelId: presenter.backgroundCall.channelId,
        withAudio: source.group.isP2P,
        withVideo: false,
        callType: presenter.backgroundCall.callType,
        providerType: presenter.backgroundCall.callProvider,
      });
    },
  }));

  return presenter;
};
