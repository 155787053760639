import { LocationState } from 'history';
import React, { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import ForceUpdateView from 'APP/pages/ForceUpdate';
import { useMessengerRoutesPresenter } from 'APP/router/MessengerRoutes/MessengerRoute.presenter';
import { MainView } from 'MAIN/MainView';

import { RouterLink } from '../constants';
import { PageType } from './constants';

interface RouterComponentProps {
  location: Record<string, LocationState>;
}

export const MessengerRoutes: FC = () => {
  useMessengerRoutesPresenter();

  return (
    <Switch>
      <Route
        exact
        path={RouterLink.main}
      >
        <MainView pageType={PageType.Main} />
      </Route>

      <Route path={RouterLink.chat}>
        <MainView pageType={PageType.Chat} />
      </Route>
      <Route path={RouterLink.channel}>
        <MainView pageType={PageType.Channel} />
      </Route>
      <Route path={RouterLink.thread}>
        <MainView pageType={PageType.Thread} />
      </Route>
      <Route path={RouterLink.schedule}>
        <MainView pageType={PageType.Schedule} />
      </Route>

      <Route path={RouterLink.space}>
        <MainView pageType={PageType.Space} />
      </Route>
      <Route path={RouterLink.spaceMarket}>
        <MainView pageType={PageType.Space} />
      </Route>
      <Route path={RouterLink.spaceMarkets}>
        <MainView pageType={PageType.Space} />
      </Route>

      <Route path={RouterLink.catalog}>
        <MainView pageType={PageType.Catalog} />
      </Route>
      <Route path={RouterLink.newsfeed}>
        <MainView pageType={PageType.Newsfeed} />
      </Route>

      <Route path={RouterLink.hub}>
        <MainView pageType={PageType.Hub} />
      </Route>

      <Route path={RouterLink.market}>
        <MainView pageType={PageType.Market} />
      </Route>

      <Route path={RouterLink.forceUpdate}>
        <ForceUpdateView />
      </Route>

      <Route
        component={({ location }: RouterComponentProps) => (
          <Redirect
            to={{
              ...location,
              pathname: RouterLink.main,
            }}
          />
        )}
      />
    </Switch>
  );
};
