import React, { FC } from 'react';

import { IStory, storyIsComponent, storyIsImage, storyIsVideo } from '../Stories.types';
import { StoryComponent } from './StoryComponent/StoryComponent';
import { StoryImage } from './StoryImage/StoryImage';
import { StoryVideo } from './StoryVideo/StoryVideo';

interface IStoryProps {
  story: IStory;
  onProgress(progress: number): void;
}

export const Story: FC<IStoryProps> = ({ story, ...props }) => {
  if (storyIsComponent(story)) {
    return (
      <StoryComponent
        story={story}
        {...props}
      />
    );
  }

  if (storyIsVideo(story)) {
    return (
      <StoryVideo
        story={story}
        {...props}
      />
    );
  }

  if (storyIsImage(story)) {
    return (
      <StoryImage
        story={story}
        {...props}
      />
    );
  }

  return null;
};
