import { useCallback, useMemo } from 'react';

import Tasks from 'APP/Tasks';
import { ISpaceMarket } from 'APP/model/space/spaceModel.types';
import { useTranslation } from 'APP/packages/translations';
import DeleteIcon from 'ICONS/ic-s-delete.svg';
import EditIcon from 'ICONS/ic-s-edit.svg';
import { MenuItemStyle } from 'UIKIT/MenuItem/MenuItem.types';
import { IPopoverMenuItem } from 'UIKIT/PopoverMenu/PopoverMenu.types';

interface ISpaceMarketItemPresenter {
  menuItems: IPopoverMenuItem[];
  onOpenMarket(): void;
}

export function useSpaceMarketItemPresenter(
  market: ISpaceMarket,
  onEdit: (market: ISpaceMarket) => void,
  onDelete: (market: ISpaceMarket) => void
): ISpaceMarketItemPresenter {
  const { t } = useTranslation();

  const onOpenMarket = useCallback(async () => {
    const openLink = await Tasks.app.showConfirm({
      text: t('space_go_to_shop_alert', {
        0: decodeURI(market.url),
        interpolation: { escapeValue: false },
      }),
      primaryText: t('open_button'),
      secondaryText: t('cancel'),
    });

    if (openLink) {
      window.open(market.url, '_blank');
    }
  }, [market]);

  const menuItems = useMemo(() => {
    return [
      {
        key: 'edit',
        icon: EditIcon,
        title: t('message_edit'),
        onClick: () => onEdit(market),
      },
      {
        key: 'delete',
        icon: DeleteIcon,
        title: t('delete'),
        style: MenuItemStyle.Danger,
        onClick: () => onDelete(market),
      },
    ];
  }, [market, onEdit, onDelete]);

  return { menuItems, onOpenMarket };
}
