import { useLocalStore, useAsObservableSource } from 'mobx-react';
import { useEffect } from 'react';

import Entities from 'STORE';
import { debounce } from 'UTILS/debounce';

import { TSearchTextChangeHandler } from '../Catalog.types';

interface ICatalogHeaderLocalStore {
  searchText: string;
  onSearchChange(value: string): void;
  setDebouncedSearchValue(value: string): void;
}

const SEARCH_DEBOUNCE = 300;

export const useCatalogHeaderPresenter = (onSearchTextChange: TSearchTextChangeHandler) => {
  const source = useAsObservableSource({ onSearchTextChange });

  const presenter = useLocalStore<ICatalogHeaderLocalStore>(() => ({
    searchText: '',

    onSearchChange(text: string): void {
      presenter.setDebouncedSearchValue(text);
      presenter.searchText = text;
    },

    setDebouncedSearchValue: debounce((text: string): void => {
      source.onSearchTextChange(text);
    }, SEARCH_DEBOUNCE),
  }));

  useEffect(() => {
    if (presenter.searchText !== Entities.catalogStore.searchText) {
      presenter.searchText = Entities.catalogStore.searchText;
    }
  }, [Entities.catalogStore.searchText]);

  return presenter;
};
