import { PayloadType } from 'APP/model/message/messageModel.types';
import api from 'APP/packages/api';
import Entities from 'APP/store';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

export default async (data) => {
  try {
    const updatedDraft = await api.messaging.updateDraft({
      draftId: data.draftId,
      groupId: data.groupId,
      payload: {
        payloadType: PayloadType.Article,
        title: data.payload.title,
        payloads: data.payload.payloads,
      },
    });

    const group = Entities.GroupsStore.getGroupById(data.groupId);

    if (!group) {
      return;
    }

    const chatDraft = new ChatMessage(updatedDraft, group.messagesStore);

    group.draftsStore.updateDraft(chatDraft);
  } catch (e) {
    return null;
  }
};
