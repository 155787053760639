import api from 'APP/packages/api';
import logger from 'APP/packages/logger';
import Entities from 'STORE';

async function request(spaceId) {
  try {
    return await api.space.generateNewDeepLink({ spaceId });
  } catch (error) {
    logger.get('API').error('spaces.updateDeepLink', error);
  }

  return null;
}

export async function updateDeepLink(spaceId) {
  const space = await request(spaceId);

  if (space) {
    Entities.spacesStore.updateSpace(space);
  }

  return space;
}
