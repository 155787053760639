import AnCat from 'ANIMATIONS/cat.json';
import AnCrown from 'ANIMATIONS/crown.json';
import AnDiamond from 'ANIMATIONS/diamond-with-stop.json';
import AnHeart from 'ANIMATIONS/hearts.json';
import AnSummer from 'ANIMATIONS/island.json';

import { UserProfileUsernameBadge } from 'APP/model/user/userModel.types';

export interface IUserNameBadgeSource {
  id: UserProfileUsernameBadge;
  data: any;
}

export interface IUserNameBadgeIcon {
  animated: boolean;
  source: IUserNameBadgeSource;
}

export const mapUserProfileBadgeToIcon: Record<UserProfileUsernameBadge, IUserNameBadgeIcon> = {
  [UserProfileUsernameBadge.Badge1]: {
    animated: true,
    source: { id: UserProfileUsernameBadge.Badge1, data: AnDiamond },
  },
  [UserProfileUsernameBadge.Badge2]: {
    animated: true,
    source: { id: UserProfileUsernameBadge.Badge2, data: AnSummer },
  },
  [UserProfileUsernameBadge.Badge3]: {
    animated: true,
    source: { id: UserProfileUsernameBadge.Badge3, data: AnCat },
  },
  [UserProfileUsernameBadge.Badge4]: {
    animated: true,
    source: { id: UserProfileUsernameBadge.Badge4, data: AnCrown },
  },
  [UserProfileUsernameBadge.Badge5]: {
    animated: true,
    source: { id: UserProfileUsernameBadge.Badge5, data: AnHeart },
  },
};
