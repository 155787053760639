import React, { FC } from 'react';

import NoResultsImage from 'APP/images/gem-mascot-not-found.svg';
import { useTranslation } from 'APP/packages/translations';
import { InfoBlock, InfoBlockTypes } from 'UIKIT/InfoBlock/InfoBlock';

interface IScheduledMessagesEmptyProps {
  hasBackground: boolean;
}

export const ScheduledMessagesEmpty: FC<IScheduledMessagesEmptyProps> = ({ hasBackground }) => {
  const { t } = useTranslation();

  return (
    <InfoBlock
      type={hasBackground ? InfoBlockTypes.ChatWithPattern : InfoBlockTypes.Chat}
      descriptions={[t('common:no_schedule_messages')]}
      image={NoResultsImage}
    />
  );
};
