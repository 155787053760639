import { botCatalogModel } from 'APP//model/model';
import { IAllBotsPage } from 'APP/model/botCatalog/botCatalogModel.types';
import { ICursorRequest } from 'APP/model/common/commonModel.types';

const DEFAULT_LIMIT = 20;

export async function getAllBots(data: ICursorRequest = {}): Promise<IAllBotsPage> {
  try {
    return await botCatalogModel.getAllBots({
      ...data,
      limit: data.limit || DEFAULT_LIMIT,
    });
  } catch {
    return {
      bots: [],
      cursor: undefined,
    };
  }
}
