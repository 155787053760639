import { RefObject, useCallback, useEffect, useRef } from 'react';

import { IStory, IStoryVideo } from 'APP/components/Stories/Stories.types';

export interface IStoryVideoPresenterOptions {
  story: IStoryVideo;
  onProgress(progress: number, story?: IStory): void;
}

interface IStoryVideoPresenter {
  videoRef: RefObject<HTMLVideoElement>;
  onMouseDown(): void;
}

export function useStoryVideoPresenter({
  story,
  onProgress,
}: IStoryVideoPresenterOptions): IStoryVideoPresenter {
  const videoRef = useRef<HTMLVideoElement>(null);
  const playPromise = useRef(Promise.resolve());
  const unsubscribeFunctionsRef = useRef<() => void>();

  const onPlay = useCallback(() => {
    if (videoRef.current) {
      playPromise.current = videoRef.current?.play();
    }
  }, []);

  const onPause = useCallback(() => {
    playPromise.current.then(() => {
      videoRef.current?.pause();
    });
  }, []);

  const onMouseUp = useCallback(() => {
    onPlay();
    unsubscribeFunctionsRef.current?.();
  }, []);

  const onMouseDown = useCallback(() => {
    onPause();

    document.addEventListener('mouseup', onMouseUp);

    unsubscribeFunctionsRef.current = () => {
      document.removeEventListener('mouseup', onMouseUp);
    };
  }, []);

  const updateProgress = useCallback(() => {
    if (!videoRef.current) {
      return;
    }

    let progress = (videoRef.current.currentTime * 100) / videoRef.current.duration;
    progress = isNaN(progress) ? 0 : progress;
    onProgress(progress);
  }, [onProgress]);

  useEffect(() => {
    videoRef.current?.addEventListener('timeupdate', updateProgress);

    return () => {
      videoRef.current?.removeEventListener('timeupdate', updateProgress);
    };
  }, [updateProgress]);

  // if different stories has same video url, we need to start the video from the beginning
  useEffect(() => {
    if (videoRef.current?.src === story.url) {
      videoRef.current.load();
    }
  }, [story]);

  useEffect(() => {
    return () => {
      unsubscribeFunctionsRef.current?.();
    };
  }, []);

  return {
    videoRef,
    onMouseDown,
  };
}
