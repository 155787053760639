import { CallEventTypes, StreamType } from 'APP/constants/calls';
import Entities from 'APP/store';

let isMuteAudioRun = false;

export const muteAudio = async (): Promise<void> => {
  if (!isMuteAudioRun) {
    isMuteAudioRun = true;
    const call = Entities.Calls.calls[0];
    if (call) {
      await call.me.setAudioMuted(true);
      await call.me.audioVideoStreamer.muteAudio();
    }
    isMuteAudioRun = false;
  }
};

let isUnmuteAudioRun = false;

export const unmuteAudio = async (): Promise<void> => {
  if (!isUnmuteAudioRun) {
    isUnmuteAudioRun = true;
    const call = Entities.Calls.calls[0];
    if (call) {
      const canUnmute = await call.me.canUnmute(StreamType.Audio);

      if (canUnmute) {
        await call.me.audioVideoStreamer.unmuteAudio(true);
        await call.me.setAudioMuted(false);
      }
    }
    isUnmuteAudioRun = false;
  }
};

interface IMuteData {
  initiatorId: string;
  userId: string;
  displayName: string;
  action: CallEventTypes;
  channel: string;
  [key: string]: any;
}

// ToDo: [calls]: rewrite data after refactoring events module;
export const forceMute = (data: IMuteData): void => {
  const call = Entities.Calls.calls[0];
  if (call) {
    call.events.addEvent({ ...data, type: data.action });
    if (
      Entities.Calls.calls[0]?.channelId === data.channel &&
      data.userId.toString() === Entities.UsersStore.Me.id
    ) {
      muteAudio();
    }
  }
};
