import { observer } from 'mobx-react';
import React from 'react';

import { IconButton } from 'APP/packages/UiKit/IconButton';
import ForwardIcon from 'ICONS/ic-forward-newsfeed.svg';

import usePresenter from './presenter';

const ForwardButton = ({ message }) => {
  const presenter = usePresenter({ message });

  if (!presenter.isShow) {
    return null;
  }

  return (
    <IconButton
      Icon={ForwardIcon}
      onClick={presenter.onClick}
    />
  );
};

export default observer(ForwardButton);
