import { useLocalStore } from 'mobx-react';

import { useTranslation } from 'APP/packages/translations';
import Entities from 'STORE';
import { Group } from 'STORE/Groups/Group';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

interface ICircleVideoItemData {
  groupId: string;
  messageId: string;
}

interface ICircleVideoItemPresenter {
  message: ChatMessage | null;
  group: Group | null;
  title: string;
}

export const useCircleVideoItemPresenter = (
  data: ICircleVideoItemData
): ICircleVideoItemPresenter => {
  const { groupId, messageId } = data;
  const { t } = useTranslation();

  const presenter = useLocalStore<ICircleVideoItemPresenter>(() => ({
    get message(): ChatMessage | null {
      const group = Entities.GroupsStore.getGroupById(groupId);
      if (!group) {
        return null;
      }

      return group.sharedData.getMessageById(messageId);
    },

    get group(): Group | null {
      return Entities.GroupsStore.getGroupById(groupId);
    },

    get title(): string {
      if (!presenter.group || !presenter.message) {
        return '';
      }

      if (
        presenter.group.isChannel &&
        !presenter.group.withMeInAdmins &&
        presenter.message.forward === null
      ) {
        return presenter.group.name;
      }

      if (presenter.message.forward === null) {
        const senderUser = presenter.message.senderUser;

        if (senderUser.id === Entities.UsersStore.Me.id) {
          return t('common:appeal_to_user');
        }

        return senderUser.avatarTitle;
      }

      return t('forward_from', {
        0: presenter.message.forward.avatarTitle,
        interpolation: { escapeValue: false },
      });
    },
  }));

  return presenter;
};
