import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

interface IPostReactionsPresenter {
  isShow: boolean;
}

export const usePostFooterPresenter = (message: ChatMessage): IPostReactionsPresenter => {
  const presenter: IPostReactionsPresenter = {
    get isShow(): boolean {
      const group = message.group;
      if (!group) {
        return false;
      }

      return message.isAvailableReactions && (group.isOpen || !group.withoutMe);
    },
  };

  return presenter;
};
