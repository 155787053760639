export const getHash = (str: string): string => {
  let chr;
  let hash = 0;

  if (str.length === 0) {
    return String(hash);
  }

  for (let i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }

  return String(hash);
};
