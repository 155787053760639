import { useAsObservableSource, useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import Entities from 'STORE';
import { Group } from 'STORE/Groups/Group';

interface IMiniatureMessageLayoutData {
  messageId: string;
  imageUrl?: string;
  onClick?(): void;
}

interface IMiniatureMessageLayoutPresenter {
  group: Group | null;
  hasImage: boolean;
  focusMessage(): void;
}

export const useMiniatureMessageLayoutPresenter = (
  data: IMiniatureMessageLayoutData
): IMiniatureMessageLayoutPresenter => {
  const { messageId, imageUrl, onClick } = data;
  const source = useAsObservableSource({ messageId, imageUrl });

  const presenter = useLocalStore<IMiniatureMessageLayoutPresenter>(() => ({
    get group(): Group | null {
      return Entities.GroupsStore.activeGroup;
    },

    get hasImage(): boolean {
      return Boolean(source.imageUrl);
    },

    focusMessage(): void {
      if (!presenter.group || !source.messageId) {
        return;
      }

      Tasks.messaging.focusMessage({
        groupId: presenter.group.id,
        messageId: source.messageId,
      });

      onClick && onClick();
    },
  }));

  return presenter;
};
