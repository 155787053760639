import { useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import Entities from 'APP/store';

export default () => {
  const presenter = useLocalStore(() => ({
    get inputPanel() {
      return Entities.InputPanelsStore.activeInputPanel;
    },

    get message() {
      return presenter.inputPanel.quoteMessage;
    },

    get isQuoteMode() {
      return Boolean(presenter.message);
    },

    get isOnlyLeftSideMessages() {
      return Entities.ChatStore.isOnlyLeftSideMessages;
    },

    endQuote() {
      Tasks.messaging.endQuoteMessage({
        groupId: null,
      });
    },
  }));

  return presenter;
};
