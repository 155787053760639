import { observer } from 'mobx-react';
import React from 'react';

import useSharedDataItems from 'MAIN/Profile/hooks/sharedDataItems';
import { Media } from 'MAIN/SharedData/Media/Media';
import { IconView } from 'UIKIT/IconView/IconView';
import { MenuItem } from 'UIKIT/MenuItem/MenuItem';

import styles from './styles.m.css';

const SavedMessagesProfile = ({ className, groupId, actions }) => {
  const childPagesList = useSharedDataItems({ groupId, actions });

  return (
    <div className={className}>
      {childPagesList.items.map(({ key, title, Icon, onClick, count }) => (
        <MenuItem
          className={styles.listItem}
          titleClassName={styles.listItemTitle}
          key={key}
          title={title}
          count={count}
          icon={
            <IconView
              Icon={Icon}
              className={styles.listIcon}
            />
          }
          onClick={onClick}
        />
      ))}
      {childPagesList.length > 0 && <hr className={styles.dividerBackground} />}
      <Media groupId={groupId} />
    </div>
  );
};

export default observer(SavedMessagesProfile);
