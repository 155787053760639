import { useLocalStore } from 'mobx-react';
import { useRef } from 'react';

import useUserOnlineStatusText from 'APP/main/hooks/userOnlineStatusText';

export default ({ user, toggleMenu, onClick }) => {
  const menuRef = useRef();
  const userOnlineStatusText = useUserOnlineStatusText({ user });

  const presenter = useLocalStore(() => ({
    onMenuClick() {
      if (toggleMenu) {
        toggleMenu(menuRef.current, user);
      }
    },

    onClick(event) {
      if (!menuRef.current?.contains(event.target)) {
        onClick(user);
      }
    },
  }));

  return {
    menuRef,
    presenter,
    userOnlineStatusText,
  };
};
