import { observer } from 'mobx-react';
import React from 'react';

import { usePostMessagePresenter } from './PostMessage.presenter';

export const PostMessage = observer(({ message, isPreview, isView }) => {
  const presenter = usePostMessagePresenter({ payloadType: message.payload.payloadType });
  const PayloadComponent = presenter.payloadComponent;

  if (!PayloadComponent) {
    return null;
  }

  return (
    <PayloadComponent
      message={message}
      isPreview={isPreview}
      isView={isView}
    />
  );
});
