import Entities from 'APP/store';

export async function preloadImage(url: string): Promise<boolean> {
  if (!url || Entities.CachedImages.isCachedImage(url) !== undefined) {
    return false;
  }

  return new Promise((resolve) => {
    const img = new Image();
    img.onload = (): void => {
      Entities.CachedImages.addCachedImage(url);
      resolve(true);
    };
    img.onerror = (): void => {
      Entities.CachedImages.addCachedImage(url, false);
      resolve(false);
    };
    img.src = url;
  });
}
