import React, { FC } from 'react';

import { useTranslation } from 'APP/packages/translations';

import styles from './MessagePinTitle.styles.m.css';

interface IMessagePinTitleProps {
  pinMessagesCount: number;
  currentMessageIndex: number;
}

export const MessagePinTitle: FC<IMessagePinTitleProps> = (props) => {
  const { pinMessagesCount, currentMessageIndex } = props;
  const { t } = useTranslation();

  return (
    <p className={styles.container}>
      <span className={styles.title}>{t('pinned_message_tittle')}</span>
      {pinMessagesCount > 1 && (
        <span className={styles.counter}>
          {t('common_counter', { 0: currentMessageIndex, 1: pinMessagesCount })}
        </span>
      )}
    </p>
  );
};
