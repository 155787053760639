import { useAsObservableSource, useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { openPopup } from 'APP/Tasks/app/popup/popup';
import { MAX_PIN_GROUPS } from 'APP/constants/group';
import { AddParticipantsPopup } from 'APP/main/PopupManager/Popups/AddParticipantsPopup/AddParticipantsPopup';
import { ParticipantsPopup } from 'APP/main/PopupManager/Popups/ParticipantsPopup/ParticipantsPopup';
import { GroupType } from 'APP/model/group/groupModel.types';
import { useTranslation } from 'APP/packages/translations';
import { RouterLink } from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import Entities from 'APP/store';
import AddUserIcon from 'ICONS/ic-add-user.svg';
import MonetizationIcon from 'ICONS/ic-bank-account.svg';
import BlockIcon from 'ICONS/ic-block.svg';
import ChangeOrderIcon from 'ICONS/ic-change-order.svg';
import ClearIcon from 'ICONS/ic-clear.svg';
import CloseIcon from 'ICONS/ic-close.svg';
import DeleteIcon from 'ICONS/ic-delete.svg';
import EditIcon from 'ICONS/ic-edit.svg';
import LeaveIcon from 'ICONS/ic-log-out.svg';
import PinStrokeIcon from 'ICONS/ic-pin-stroke.svg';
import PrivacyIcon from 'ICONS/ic-privacy.svg';
import ReportIcon from 'ICONS/ic-report.svg';
import UsersIcon from 'ICONS/ic-users.svg';
import { EditChannelPopup } from 'MAIN/PopupManager/Popups/EditChannelPopup/EditChannelPopup';
import { GroupPermissionsPopup } from 'MAIN/PopupManager/Popups/GroupPermissionsPopup/GroupPermissionsPopup';
import { ReportGroupPopup } from 'MAIN/PopupManager/Popups/ReportGroupPopup/ReportGroupPopup';
import { useMoveGroupToSpace } from 'MAIN/hooks/space/useMoveGroupToSpace';

import useSharedDataItems from '../hooks/sharedDataItems';

export const useChannelProfilePresenter = ({ groupId, actions }) => {
  const { t } = useTranslation();
  const navigateTo = useNavigateTo();
  const sharedDataItems = useSharedDataItems({ groupId, actions });
  const source = useAsObservableSource({ groupId });
  const moveGroupToSpace = useMoveGroupToSpace(groupId);

  const presenter = useLocalStore(() => ({
    get group() {
      return Entities.GroupsStore.getGroupById(source.groupId);
    },

    get space() {
      const spaceId = presenter.group.spaceId;

      return spaceId ? Entities.spacesStore.getById(spaceId) : null;
    },

    get title() {
      return presenter.group.avatarTitle;
    },

    get subtitle() {
      return t('amount_of_subscribers_in_a_channel_many').replace(
        '{0}',
        presenter.group.usersCount
      );
    },

    get description() {
      return presenter.group.description;
    },

    get canInviteUsers() {
      if (presenter.group.isOpen) {
        return true;
      }

      return presenter.group.canInviteUsers;
    },

    get childPagesList() {
      return [
        {
          key: 'subscribers',
          Icon: UsersIcon,
          title: t('common_subscribers'),
          onClick: presenter.openSubscribers,
          count: presenter.group.usersCount,
          isVisible: presenter.isVisibleSubscribers,
        },
        ...sharedDataItems.items,
      ].filter((item) => item.isVisible);
    },

    get isVisibleSubscribers() {
      return presenter.group.withMeInAdmins;
    },

    openSubscribers() {
      openPopup(ParticipantsPopup, { params: { groupId: source.groupId } });
    },

    get actions() {
      const items = [
        {
          key: 'blacklist',
          isVisible: presenter.isVisibleBlacklist,
          Icon: BlockIcon,
          title: t('balck_list_title'),
          onClick: presenter.showBlacklist,
        },
        {
          key: 'monetization',
          isVisible: presenter.isVisibleMonetization,
          Icon: MonetizationIcon,
          title: t('common:common_monetization'),
          onClick: presenter.openMonetization,
        },
        {
          key: 'permissions',
          isVisible: presenter.isVisiblePermissions,
          Icon: PrivacyIcon,
          title: t('permissions_label'),
          onClick: presenter.showPermissions,
        },
      ];

      return items.filter((item) => item.isVisible);
    },

    get isVisibleBlacklist() {
      return presenter.group.withMeInAdmins;
    },

    showBlacklist() {
      navigateTo({
        keepOldPathname: true,
        to: '/blacklist',
      });
    },

    get isVisiblePermissions() {
      return presenter.group.withMeInAdmins;
    },

    showPermissions() {
      presenter.childPopup = openPopup(GroupPermissionsPopup, {
        params: { groupId: presenter.group.id },
      });
    },

    get isVisibleMonetization() {
      return presenter.group.isOwner && presenter.group.type === GroupType.ChannelOpen;
    },

    openMonetization() {
      navigateTo({
        keepOldPathname: true,
        to: RouterLink.monetization,
      });
    },

    get actionItems() {
      const items = [
        {
          key: 'add-people',
          isVisible: presenter.canAddPeople,
          Icon: AddUserIcon,
          title: t('group_chat_add_people_button'),
          onClick: presenter.addPeople,
        },
        {
          key: 'pin-channel',
          isVisible: presenter.canPinChannel,
          Icon: PinStrokeIcon,
          title: t('channel_pin'),
          onClick: presenter.pinChannel,
        },
        {
          key: 'unpin-channel',
          isVisible: presenter.canUnpinChannel,
          Icon: PinStrokeIcon,
          title: t('channel_unpin'),
          onClick: presenter.unpinChannel,
        },
        {
          key: 'edit-channel',
          isVisible: presenter.canEditChannel,
          Icon: EditIcon,
          title: t('channel_edit'),
          onClick: presenter.editChannel,
        },
        {
          key: 'move-to-space',
          isVisible: presenter.canMoveToSpace,
          Icon: ChangeOrderIcon,
          title: t('space_moveto_space_button'),
          onClick: presenter.moveToSpace,
        },
        {
          key: 'remove-from-space',
          isVisible: presenter.canRemoveFromSpace,
          Icon: CloseIcon,
          title: t('space_remove_from_button', { 0: presenter.space?.name }),
          onClick: presenter.removeFromSpace,
        },
        {
          key: 'report-channel',
          isVisible: presenter.canReportChannel,
          Icon: ReportIcon,
          title: t('common_report'),
          onClick: presenter.reportChannel,
        },
        {
          key: 'clear-messages',
          isVisible: presenter.canClearHistory,
          Icon: ClearIcon,
          title: t('profile_clear_messages'),
          onClick: presenter.clearHistory,
        },
        {
          key: 'delete-channel',
          isVisible: presenter.canDeleteChannel,
          Icon: DeleteIcon,
          title: t('channel_delete'),
          onClick: presenter.deleteChannel,
        },
        {
          key: 'leave-channel',
          isVisible: presenter.canLeaveChannel,
          Icon: LeaveIcon,
          title: t('channel_leave'),
          onClick: presenter.leaveChannel,
        },
      ];

      return items.filter((item) => item.isVisible);
    },

    get canAddPeople() {
      if (!presenter.group) {
        return false;
      }

      return (
        (presenter.group.isChannel && presenter.group.withMeInAdmins) ||
        presenter.group.canInviteUsers
      );
    },

    addPeople() {
      openPopup(AddParticipantsPopup, { params: { groupId: source.groupId } });
    },

    get canPinMore() {
      return Entities.GroupsStore.pinnedChannels.length < MAX_PIN_GROUPS;
    },

    get canPinChannel() {
      if (!presenter.group || !presenter.group.isChannel || presenter.group.withoutMe) {
        return false;
      }

      return presenter.canPinMore && !presenter.group.isPinned;
    },

    pinChannel() {
      if (!presenter.group) {
        return;
      }

      Tasks.group.pinGroup({ groupId: presenter.group.id });
    },

    get canUnpinChannel() {
      if (!presenter.group || !presenter.group.isChannel || presenter.group.withoutMe) {
        return false;
      }

      return presenter.group.isPinned;
    },

    unpinChannel() {
      if (!presenter.group) {
        return;
      }

      Tasks.group.unpinGroup({ groupId: presenter.group.id });
    },

    get canEditChannel() {
      if (!presenter.group) {
        return false;
      }

      return presenter.group.isChannel && presenter.group.withMeInAdmins;
    },

    editChannel() {
      openPopup(EditChannelPopup, { params: { groupId: source.groupId } });
    },

    get canMoveToSpace() {
      return moveGroupToSpace.canMoveToSpace;
    },

    moveToSpace() {
      presenter.childPopup = moveGroupToSpace.moveToSpace();
    },

    get canRemoveFromSpace() {
      return moveGroupToSpace.canRemoveFromSpace;
    },

    removeFromSpace() {
      moveGroupToSpace.removeFromSpace();
    },

    get canReportChannel() {
      if (!presenter.group) {
        return false;
      }

      return !presenter.group.isOwner && presenter.group.isChannel;
    },

    reportChannel() {
      openPopup(ReportGroupPopup, { params: { groupId: source.groupId } });
    },

    get canClearHistory() {
      if (!presenter.group) {
        return false;
      }

      return presenter.group.isOwner;
    },

    async clearHistory() {
      if (!presenter.group) {
        return;
      }

      const isConfirmed = await Tasks.app.showConfirm({
        text: t('channel_clear_history_alert'),
      });

      if (isConfirmed) {
        Tasks.group.clearHistory({
          groupId: presenter.group.id,
          isClearForAll: presenter.group.isChannel && presenter.group.isOwner,
        });
      }
    },

    get canDeleteChannel() {
      if (!presenter.group) {
        return false;
      }

      return presenter.group.isChannel && presenter.group.isOwner;
    },

    deleteChannel() {
      Tasks.app.showConfirm({
        text: t('delete_channel_alert'),
        async onSuccess() {
          await Tasks.group.deleteGroup({
            groupId: presenter.group.id,
          });
        },
      });
    },

    get canLeaveChannel() {
      if (!presenter.group || presenter.group.withoutMe) {
        return false;
      }

      return (
        !presenter.group.isOwner && presenter.group.isChannel && presenter.group.usersCount > 1
      );
    },

    leaveChannel() {
      if (!presenter.group) {
        return;
      }

      Tasks.app.showConfirm({
        text: t('leave_channel_alert'),
        async onSuccess() {
          await Tasks.group.leaveGroup({
            groupId: presenter.group.id,
          });
        },
      });
    },
  }));

  return presenter;
};
