import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { OnboardingType } from 'APP/constants/onboarding';
import Entities from 'APP/store';

export function useOnboardingManager(type: OnboardingType) {
  const presenter = useLocalStore(() => ({
    init(): void {
      Entities.onboarding.addToShow(type);
    },

    handleClose(): void {
      Tasks.app.onboarding.closeOnboarding(type);
    },

    get isNeedToShow(): boolean {
      return Entities.onboarding.getIsNeedToShow(type);
    },

    get isOpened(): boolean {
      return Entities.onboarding.isActiveOnboarding(type);
    },
  }));

  useEffect(() => {
    presenter.init();
  }, []);

  return presenter;
}
