import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { TextRenderer } from 'APP/components/TextRenderer/TextRenderer';
import { IUserMentionData } from 'APP/model/common/commonModel.types';
import { useTranslation } from 'APP/packages/translations';
import { Link } from 'UIKIT/Link/Link';

import { useSeeMoreDescriptionPresenter } from './ProfileSeeMoreDescription.presenter';
import styles from './ProfileSeeMoreDescription.styles.m.css';

interface IProfileSeeMoreDescriptionProps {
  className?: string;
  description: string;
  mentions?: IUserMentionData[];
  groupId?: string;
}

export const ProfileSeeMoreDescription: FC<IProfileSeeMoreDescriptionProps> = observer(
  ({ className, description, mentions, groupId }) => {
    const { t } = useTranslation();
    const presenter = useSeeMoreDescriptionPresenter(description);

    return (
      <div className={className}>
        <TextRenderer
          userMentions={mentions}
          groupId={groupId}
        >
          {presenter.description}
        </TextRenderer>
        <Link
          className={classNames(styles.link, styles.showMore, {
            [styles.hide]: !presenter.hasShowMore,
          })}
          onClick={presenter.showMore}
        >
          {t('group_description_more')}
        </Link>
      </div>
    );
  }
);
