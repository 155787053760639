export enum MemberState {
  OnCall = 'onCall',
  NotOnCall = 'notOnCall',
  Declined = 'declined',
  Left = 'left',
}

export const mapState: { [key: string]: MemberState } = {
  INITIATED: MemberState.NotOnCall,
  JOINED: MemberState.OnCall,
  DECLINED: MemberState.Declined,
  FINISHED: MemberState.Left,
  ADDED: MemberState.NotOnCall,
  REMOVED: MemberState.NotOnCall,
};
