import { observer } from 'mobx-react';
import React from 'react';

import { Popup } from 'APP/components/Popup/Popup';

import { IRenderer } from '../renderTree.types';
import { Animation } from './Animation';
import { useKeyWordRendererPresenter } from './KeyWordRenderer.presenter';
import styles from './KeyWordRenderer.styles.m.css';

interface IKeyWordRendererOptions {
  type: string;
}

export const KeyWordRenderer: IRenderer<IKeyWordRendererOptions> = observer((props) => {
  const { children, options } = props;
  const presenter = useKeyWordRendererPresenter(options.type);

  if (presenter.url) {
    return (
      <>
        <span
          role="button"
          tabIndex={0}
          className={styles.link}
          onClick={presenter.onOpen}
        >
          {children}
        </span>
        <Popup
          isOpened={presenter.isOpened}
          onClose={presenter.onClose}
          overlayClassName={styles.overlay}
          className={styles.popup}
        >
          <Animation
            onClick={presenter.onClose}
            className={styles.container}
            url={presenter.url}
          />
        </Popup>
      </>
    );
  } else {
    return <>{children}</>;
  }
});
