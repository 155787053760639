import React, { FC } from 'react';

import { IconButton } from 'APP/packages/UiKit/IconButton';
import CloseIcon from 'ICONS/close.svg';
import BackArrow from 'ICONS/ic-back.svg';
import CopyIcon from 'ICONS/ic-copy-stroke.svg';

import styles from './PopupHeader.style.m.scss';

interface PopupHeaderProps {
  onBackClick: (() => void) | null;
  onCopyClick: () => void;
  onClose: () => void;
}

export const PopupHeader: FC<PopupHeaderProps> = ({ onBackClick, onCopyClick, onClose }) => {
  return (
    <div className={styles.container}>
      <div className={styles.arrowBlock}>
        {onBackClick && (
          <IconButton
            Icon={BackArrow}
            onClick={onBackClick}
          />
        )}
      </div>
      <div className={styles.control}>
        <IconButton
          Icon={CopyIcon}
          className={styles.copy}
          onClick={onCopyClick}
        />
        <IconButton
          Icon={CloseIcon}
          onClick={onClose}
          dataTest="close-popup"
        />
      </div>
    </div>
  );
};
