import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';

import EmptyComponent from './EmptyComponent';
import ExtendedUsers from './ExtendedUsers';
import MyContacts from './MyContacts';
import styles from './styles.m.scss';

const Users = ({ common, myContacts, extendedUsers }) => {
  if (common.noResults) {
    return <EmptyComponent isSearchMode={common.isSearchMode} />;
  }

  return (
    <div className={classNames(customScrollStyles.container, styles.scroll)}>
      <MyContacts
        common={common}
        myContacts={myContacts}
      />
      {extendedUsers.isShowExtendedUsers && (
        <ExtendedUsers
          common={common}
          extendedUsers={extendedUsers}
        />
      )}
    </div>
  );
};

export default observer(Users);
