import ConversationImage from 'APP/images/gem-mascot-conversation.svg';
import HelpImage from 'APP/images/gem-mascot-help.svg';
import NewsImage from 'APP/images/gem-mascot-news.svg';
import PlansImage from 'APP/images/gem-mascot-plans.svg';
import SharingImage from 'APP/images/gem-mascot-sharing.svg';

import { FriendsSpaceMessagesEmptyConfig } from './FriendsSpaceMessagesEmpty.types';

export enum FriendsSpaceImage {
  DailyTalks = 'https://lh3.googleusercontent.com/TrocZHjLwRBCrwjB0642YiNefTOcNk0usObqKAuEjkxpzefdsuG4xhLUyR-IKrjCQMelqNwMPsuSHYnB1McYeRFIyL2LUg',
  Handouts = 'https://lh3.googleusercontent.com/rYQAQaOy5D3oqcEqMmzZTmAnHHJh28kdw84ePkx4dN-wYnLi9ghMNe7604MicA6AgDpL8o5XhW-YgOc-vSuNkZ24C8_X',
  Fun = 'https://lh3.googleusercontent.com/B8R5cDtVm3lzSGMXeDOKpDqcvim-Clk0v4QPHEJoK4zLqmwclZu0xDMln8bKbNQDIOW1CzORZM4JUdh4FgS90kxQpWwB',
  Helps = 'https://lh3.googleusercontent.com/CusdW6uR4ZcofahoMYBPGEb8pOuZPa-_Rm3rK8_lNU4CfxD_mSxeLojCSaxQzsCqWAKRtQzK8MboSjpQX1zPzpjCqNqsKA',
  News = 'https://lh3.googleusercontent.com/G_WcmgKWzEpDiRDKyWW2xz1MaTQSq0_zLZPVuPY1UUzUv-k0fPM7nubWW4YOm0pdbxEWvd-qeTADWnHumDB2Rfc09aE50g',
}

export const friendsSpaceImagesEmptyConfig: Record<string, FriendsSpaceMessagesEmptyConfig> = {
  [FriendsSpaceImage.DailyTalks]: {
    image: ConversationImage,
    titleKey: 'space_friends_daily_name',
    descriptionKey: 'space_friends_daily_description',
  },
  [FriendsSpaceImage.Handouts]: {
    image: PlansImage,
    titleKey: 'space_friends_hangouts_name',
    descriptionKey: 'space_friends_hangouts_description',
  },
  [FriendsSpaceImage.Fun]: {
    image: SharingImage,
    titleKey: 'space_friends_fun_name',
    descriptionKey: 'space_friends_fun_description',
  },
  [FriendsSpaceImage.Helps]: {
    image: HelpImage,
    titleKey: 'space_friends_help_name',
    descriptionKey: 'space_friends_help_description',
  },
  [FriendsSpaceImage.News]: {
    image: NewsImage,
    titleKey: 'space_friends_news_name',
    descriptionKey: 'space_friends_news_description',
  },
};
