import { useLocalStore } from 'mobx-react';
import { useEffect, useRef } from 'react';

import Tasks from 'APP/Tasks';
import { INACTIVE_PERIODS, MAP_PERIOD_TO_LABEL } from 'APP/constants';
import { useTranslation } from 'APP/packages/translations';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import Entities from 'STORE';

interface IPeriodValue {
  key: string;
  title: string;
  isActive: boolean;
  onChange(): void;
}

interface ISessionsSelfDestructPopupPresenter {
  currentPeriodValue: string | null;
  hasBackButton: boolean;
  periodValues: IPeriodValue[];
  setValue(value: string): void;
  onClose(): void;
  onBack(): void;
}

export function useSessionsSelfDestructPopupPresenter({
  popupInstance,
  onClose,
  onBack,
}: ICommonPopupsProps): ISessionsSelfDestructPopupPresenter {
  const { t } = useTranslation();

  const presenter = useLocalStore<ISessionsSelfDestructPopupPresenter>(() => ({
    get currentPeriodValue(): string | null {
      return Entities.sessionsStore.sessionTTl;
    },

    get hasBackButton(): boolean {
      return !!onBack;
    },

    get periodValues(): IPeriodValue[] {
      return [
        {
          key: INACTIVE_PERIODS.ONE_WEEK,
          title: t(MAP_PERIOD_TO_LABEL[INACTIVE_PERIODS.ONE_WEEK]),
          isActive: INACTIVE_PERIODS.ONE_WEEK === presenter.currentPeriodValue,
          onChange: () => presenter.setValue(INACTIVE_PERIODS.ONE_WEEK),
        },
        {
          key: INACTIVE_PERIODS.ONE_MONTH,
          title: t(MAP_PERIOD_TO_LABEL[INACTIVE_PERIODS.ONE_MONTH]),
          isActive: INACTIVE_PERIODS.ONE_MONTH === presenter.currentPeriodValue,
          onChange: () => presenter.setValue(INACTIVE_PERIODS.ONE_MONTH),
        },
        {
          key: INACTIVE_PERIODS.THREE_MONTHS,
          title: t(MAP_PERIOD_TO_LABEL[INACTIVE_PERIODS.THREE_MONTHS]),
          isActive: INACTIVE_PERIODS.THREE_MONTHS === presenter.currentPeriodValue,
          onChange: () => presenter.setValue(INACTIVE_PERIODS.THREE_MONTHS),
        },
        {
          key: INACTIVE_PERIODS.SIX_MONTHS,
          title: t(MAP_PERIOD_TO_LABEL[INACTIVE_PERIODS.SIX_MONTHS]),
          isActive: INACTIVE_PERIODS.SIX_MONTHS === presenter.currentPeriodValue,
          onChange: () => presenter.setValue(INACTIVE_PERIODS.SIX_MONTHS),
        },
      ];
    },

    setValue(value: string): void {
      Entities.sessionsStore.setSessionTtl(value);
    },

    onClose(): void {
      popupInstance.close();
      onClose?.();
    },

    onBack(): void {
      popupInstance.close();
      onBack?.();
    },
  }));

  const initialSessionTtlValue = useRef<string>();

  useEffect(() => {
    if (!presenter.currentPeriodValue) {
      Tasks.userSettings.getSessionsInfo();
    } else if (!initialSessionTtlValue.current) {
      initialSessionTtlValue.current = presenter.currentPeriodValue;
    }
  }, [presenter.currentPeriodValue]);

  useEffect(() => {
    return () => {
      if (initialSessionTtlValue.current !== presenter.currentPeriodValue) {
        Tasks.userSettings.setSessionsTtl(presenter.currentPeriodValue);
      }
    };
  }, []);

  return presenter;
}
