import { botCatalogModel } from 'APP/model/model';
import Entities from 'STORE';
import { Bot } from 'STORE/Users/User/Bot/Bot';

export async function removeBotFromProfile(botId: string): Promise<boolean> {
  try {
    // Todo Remove "as" after the users store will be refactored
    const bot = Entities.UsersStore.getUserById(botId) as Bot | null;
    const botModel = await botCatalogModel.removeBotFromProfile(botId);

    if (bot) {
      bot.update(botModel);
    }

    return true;
  } catch {
    return false;
  }
}
