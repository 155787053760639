import { observer } from 'mobx-react';
import React from 'react';

import Entities from 'STORE';

import { FullScreenCallViewPanel } from '../Shared/FullScreenCallViewPanel/FullScreenCallViewPanel';
import { FullScreenGridLayout } from '../Shared/FullScreenGridLayout/FullScreenGridLayout';
import { SpeakerVideo } from '../Shared/SpeakerVideo/SpeakerVideo';
import styles from './P2PVideoCall.styles.m.css';

export const P2PVideoCall = observer(() => {
  return (
    <div className={styles.container}>
      {Entities.OngoingCall.presentVideoMode.isGridLayout ? (
        <FullScreenGridLayout />
      ) : (
        <SpeakerVideo />
      )}
      <FullScreenCallViewPanel hasTitle />
    </div>
  );
});
