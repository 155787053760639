import { Options } from '@popperjs/core/lib/modifiers/offset';
import { observer } from 'mobx-react';
import React, { useState, useEffect, MutableRefObject, FC, ElementType } from 'react';

import { Popover } from 'UIKIT/Popover/Popover';

import { Onboarding } from '../Onboarding/Onboarding';
import styles from './OnboardingPopover.styles.m.css';

interface IOnboardingPopoverComponentProps {
  className?: string;
  title?: string;
  description: string;
  Icon?: ElementType;
  arrowOffset?: number;
  reference: MutableRefObject<HTMLElement | null>;
  isOpened: boolean;
  handleClose(): void;
}

export const OnboardingPopover: FC<IOnboardingPopoverComponentProps> = observer(
  ({ handleClose, isOpened, reference, ...props }) => {
    const [referenceElement, setReferenceElement] = useState(reference.current);
    const [arrowOffset, setArrowOffset] = useState(0);

    const calculateOffset: Options['offset'] = ({ popper, reference }) => {
      const pad = 8;
      const popX = popper.width / 2;
      const refX = reference.x + reference.width / 2;
      let offset = 0;

      if (refX - popX < pad) {
        offset = pad - refX + popX;
      } else if (refX + popX > document.body.clientWidth - pad) {
        offset = document.body.clientWidth - pad - refX - popX;
      }

      setArrowOffset(offset * -1);
      return [offset, 8];
    };

    useEffect(() => {
      setReferenceElement(reference.current);
    }, [reference.current]);

    return (
      <Popover
        className={styles.container}
        isOpened={isOpened}
        onClose={handleClose}
        reference={referenceElement}
        placement="top"
        isScrollEnabled
        modifiers={[
          {
            name: 'offset',
            options: { offset: calculateOffset },
          },
        ]}
      >
        <Onboarding
          {...props}
          arrowOffset={arrowOffset}
          handleClose={handleClose}
        />
      </Popover>
    );
  }
);
