import isFromGoogleDoc from './googleDoc/isFromGoogleDoc';
import transformGoogleDocHtml from './googleDoc/transformGoogleDocHtml';

const DEFAULT_OPTIONS = {
  ignoreImage: false,
};

export const htmlConverter = (htmlText, rawOptions) => {
  const options = Object.assign({}, DEFAULT_OPTIONS, rawOptions);

  const div = document.createElement('div');
  div.innerHTML = htmlText;
  const nodes = div.getElementsByTagName('*');

  if (isFromGoogleDoc(nodes)) {
    return transformGoogleDocHtml(htmlText, options);
  }

  return null;
};

export const textConverter = (text) => {
  return (text || '').replaceAll(/\r\n/g, '\n');
};

export default htmlConverter;
