import { useEffect } from 'react';

import Tasks from 'APP/Tasks';

export const useMessengerRoutesPresenter = (): void => {
  useEffect(() => {
    Tasks.market.getMarketConfig(true);

    return () => {
      Tasks.market.stopUpdateMarketConfig();
    };
  }, []);
};
