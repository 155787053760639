import base64 from '../base64';
import chunkArray from '../chunkArray';
import compose from '../compose';

const MAX_PEAK_VALUE = 255;
const TOTAL_PAYLOAD_VALUES = 300;

// only numbers > 0
const makeAbsPeaks = (peaks: number[]): number[] => {
  return peaks.map((peak) => {
    return Math.abs(peak);
  });
};

// multiply peaks by max value in array and create [0, 255] format
const normalizePeaksByMaxValue = (peaks: number[]): number[] => {
  const maxValue = Math.max(...peaks);

  return peaks.map((peak) => {
    return (peak / maxValue) * MAX_PEAK_VALUE;
  });
};

// chunked values on 300 arrays
const chunkedPeaks = (peaks: number[]): number[][] => {
  return chunkArray(peaks, peaks.length / TOTAL_PAYLOAD_VALUES).slice(0, -1);
};

// get max value on each peak
const calculateAverageForEachPiece = (peaks: number[][]): number[] => {
  return peaks.map((peak) => {
    return Math.trunc(Math.max(...peak));
  });
};

export function convertBufferToBase64(peaks: number[]): string {
  return compose(
    base64.encode,
    calculateAverageForEachPiece,
    chunkedPeaks,
    normalizePeaksByMaxValue,
    makeAbsPeaks
  )(peaks);
}
