import semver from 'semver';

import { default as Tasks } from 'APP/Tasks';
import { analyticsClient } from 'APP/packages/analytics/analytics';
import api, { setConfigApi } from 'APP/packages/api';
import logger from 'APP/packages/logger';
import { platformInfo } from 'APP/packages/platformInfo/platformInfo';
import { setSseConfig } from 'APP/packages/streamApi';
import { setWebsocketConfig } from 'APP/packages/websocket';
import Entities from 'APP/store';
import { getUserLanguage } from 'UTILS/getUserLanguage';

import handleClientConfig from './handleClientConfig';

async function checkUpdateDesktop(): Promise<void> {
  try {
    // Todo отключаем проверку до решения вопросов по подписи приложени. Иначе не работает как на mac так и на Windows
    //return;
    const res = await window.electronAPI.checkUpdates();
    const currentAppVersion = await window.electronAPI.appVersion;
    if (res && semver.gt(res?.versionInfo?.version, currentAppVersion)) {
      await window.electronAPI.downloadUpdate();
      Entities.AuthStore.setSoftUpdate(true);
    }
  } catch (e) {
    logger.get('electronAPI').error('electronAPI.checkUpdates', e);
  }
}

interface ILoginResult {
  isSuccess: boolean;
  error?: Error;
}
export async function login(
  temporaryConnectionToken: string | null,
  initStore = true
): Promise<ILoginResult> {
  const connectionToken = temporaryConnectionToken || Entities.AuthStore.connectionToken;

  if (!connectionToken) {
    return { isSuccess: false };
  }

  const requestData = {
    registrationKey: connectionToken,
    throwUnauthorized: true,
    device: {
      platform: platformInfo.platform,
      appVersion: Entities.App.version,
      locale: getUserLanguage(),
      device: platformInfo.device,
      osVersion: platformInfo.osVersion,
    },
  };

  try {
    const response = await api.authorization.login(requestData);

    if (!response) {
      return { isSuccess: false };
    }

    setConfigApi({ userSession: response.sessionId });
    setWebsocketConfig({ token: response.tokenV3 });
    setSseConfig({ token: response.tokenV3 });
    analyticsClient.setUserConfig(response.user);

    handleClientConfig(response.clientConfig);

    Entities.AuthStore.setDeleted(false);
    Entities.AuthStore.setSession(response.sessionId);
    Entities.AuthStore.setStreamToken(response.tokenV3);
    Entities.UsersStore.addMe({
      ...response.user,
      deepLink: response.referralLink,
    });
    Entities.UsersStore.Me.setIsValid(response.isUserValid);

    if (window.electronAPI) {
      checkUpdateDesktop();
    }

    if (response.isUserValid && initStore) {
      Tasks.users.getFriends();
      Tasks.userSettings.getPrivacySettings();
      Tasks.app.onboarding.initOnboarding();
      Tasks.userSettings.getRewardsProgress();
      Tasks.group.initGroups();
      Tasks.space.initSpaces();

      await Tasks.pushNotifications.initNotifications();
      Tasks.newsfeed.preloadNewsfeed({ cursor: null });
      Tasks.authorization.getAccountCountry(response.user.phone);
    }

    return { isSuccess: true };
  } catch (error) {
    logger.get('API').error('authorization.login', error);
    return { isSuccess: false, error };
  }
}
