import Entities from 'STORE';
import { IPushNotification } from 'STORE/PushNotifications/PushNotifications.types';

import { isInActiveChat } from '../utils/isInActiveChat';
import { onGroupNotificationClick } from '../utils/onGroupNotificationClick';
import { onNotificationClose } from '../utils/onNotificationClose';
import { prepareNotificationOptions } from '../utils/prepareNotificationOptions';

const alreadyReadMessage = (pushNotification: IPushNotification): boolean => {
  if (!pushNotification.group) {
    return false;
  }

  if (!pushNotification.messageId) {
    return false;
  }

  const group = Entities.GroupsStore.getGroupById(pushNotification.group);
  const message = group?.messagesStore.getMessageById(pushNotification.messageId);

  return message ? !message.unread : false;
};

export const handleDefaultNotification = (pushNotification: IPushNotification): void => {
  if (
    isInActiveChat(pushNotification) ||
    alreadyReadMessage(pushNotification) ||
    !pushNotification.group
  ) {
    return;
  }

  const options = prepareNotificationOptions(pushNotification);

  const notification = new Notification(pushNotification.title || '', options);
  Entities.pushNotifications.addNotification(notification);
  notification.onclick = (): Promise<void> =>
    onGroupNotificationClick(pushNotification, notification);
  notification.onclose = (): void => onNotificationClose(notification);
  notification.onerror = (): void => onNotificationClose(notification);
};
