import { observer } from 'mobx-react';
import React from 'react';

import { Popup } from 'APP/components/Popup/Popup';

import Calendar from './Calendar';
import Form from './Form';
import usePresenter from './presenter';
import styles from './styles.m.css';

const ScheduleSettings = () => {
  const presenter = usePresenter();

  return (
    <>
      <Popup
        className={styles.container}
        isOpened={presenter.isOpened}
        onClose={presenter.onClose}
      >
        {presenter.showCommon && <Form common={presenter} />}
        {presenter.showCalendar && <Calendar common={presenter} />}
      </Popup>
    </>
  );
};

export default observer(ScheduleSettings);
