import React from 'react';

import { Tooltip } from 'UIKIT/Tooltip/Tooltip';

import { Popover } from '../UsersPopover/UsersPopover';
import { useUsersLinkPresenter } from './UsersLink.presenter';

export const UsersLink = ({ usersIds, groupId, messageId, children }) => {
  const presenter = useUsersLinkPresenter({
    usersIds,
    groupId,
    messageId,
  });

  if (!children) {
    return null;
  }

  return (
    <Tooltip
      render={(tooltip) => (
        <Popover
          usersIds={usersIds}
          tooltip={tooltip}
          groupId={groupId}
          messageId={messageId}
          interactive={true}
        />
      )}
      arrow={true}
      delay={300}
    >
      <span
        onClick={presenter.onClick}
        role="button"
        tabIndex={-1}
      >
        {children}
      </span>
    </Tooltip>
  );
};
