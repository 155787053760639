import { observer } from 'mobx-react';
import React, { FC, ReactElement } from 'react';

import { List } from 'APP/components/List/List';
import { useTranslation } from 'APP/packages/translations';
import { PopupUserItem } from 'MAIN/Popups/GetParametersManager/components/PopupUserItem/PopupUserItem';

import { useGroupFormGeneralMembersPresenter } from './GroupFormGeneralMembers.presenter';
import styles from './GroupFormGeneralMembers.styles.m.css';

interface IGroupFormGeneralMembersProps {
  membersIds: string[];
}

export const GroupFormGeneralMembers: FC<IGroupFormGeneralMembersProps> = observer((props) => {
  const { membersIds } = props;
  const { t } = useTranslation();
  const presenter = useGroupFormGeneralMembersPresenter({ membersIds });

  if (!membersIds.length) {
    return null;
  }

  return (
    <div className={styles.members}>
      <div className={styles.membersCount}>
        {`${t('group_chat_members_tab_title')} (${membersIds.length})`}
      </div>
      <List
        data={presenter.members}
        keyExtractor={presenter.keyExtractor}
        renderItem={(contact): ReactElement => <PopupUserItem user={contact} />}
      />
    </div>
  );
});
