import Tasks from 'APP/Tasks';
import bus from 'APP/packages/bus';
import Entities from 'APP/store';
import type { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

export const startQuoteMessage = async (message: ChatMessage): Promise<void> => {
  if (!message) {
    return;
  }

  const inputPanel = Entities.InputPanelsStore.activeInputPanel;
  const activeGroup = Entities.GroupsStore.activeGroup;

  if (!inputPanel || !activeGroup) {
    return;
  }

  // you can't make quotes in public if u are not admin
  if (activeGroup.isChannel && !activeGroup.withMeInAdmins) {
    return;
  }

  if (inputPanel.editMessage) {
    const isEndEditMessageSuccess = await Tasks.messaging.endEditMessage(activeGroup.id);

    if (isEndEditMessageSuccess) {
      inputPanel.setQuoteMessage(message);
      bus.messaging.broadcastStartQuoteMessage();
    }
  } else {
    inputPanel.setQuoteMessage(message);
    bus.messaging.broadcastStartQuoteMessage();
  }
};
