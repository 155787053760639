import { FC } from 'react';

export enum HubPageType {
  Main = 'main',
  Market = 'market',
  Freelance = 'Freelance',
  Games = 'games',
  AdBoard = 'adBoard',
}

export type THubPage = FC<{
  setPage(page: HubPageType): void;
}>;
