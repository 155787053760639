import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { TextRenderer } from 'APP/components/TextRenderer/TextRenderer';

import { MessageLayout } from '../../MessageLayout/MessageLayout';
import { BubbleHeader } from '../components/BubbleHeader/BubbleHeader';
import StatusBar from '../components/StatusBar';
import { ThreadButton } from '../components/ThreadButton/ThreadButton';
import Translate from '../components/Translate';
import styles from './MediaBubble.styles.m.css';

export const MediaBubble = observer((props) => {
  const { message, children, className, messageViewPageType, isView } = props;
  const { comment } = message.renderPayload;

  const isShowForward = Boolean(message.forward && !message.isSavedMessagesLeft);

  const isExistHeader = Boolean(message.isSenderNameShow || message.quotedMessage || isShowForward);

  return (
    <MessageLayout
      message={message}
      className={classNames(styles.bubble, className)}
      containerClassName={styles.container}
      messageViewPageType={messageViewPageType}
      isView={isView}
    >
      {isExistHeader && (
        <BubbleHeader
          message={message}
          isShowForward={isShowForward}
        />
      )}
      <div className={styles.content}>
        {children}
        {!comment && (
          <StatusBar
            className={styles.statusBar}
            message={message}
            viewsClassName={styles.views}
          />
        )}
      </div>
      {!comment && <ThreadButton message={message} />}
      {comment && comment.length && (
        <div className={styles.comment}>
          <TextRenderer
            userMentions={message.payload.userMentions}
            keyWords={message.payload.keyWords}
          >
            {comment}
          </TextRenderer>
          <StatusBar
            message={message}
            className={styles.commentedStatusBar}
          />
          <ThreadButton
            message={message}
            className={styles.threadButton}
          />
          <Translate message={message} />
        </div>
      )}
    </MessageLayout>
  );
});
