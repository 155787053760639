import { UseGoogleLoginOptionsImplicitFlow } from '@react-oauth/google';
import { useLocalStore } from 'mobx-react';

import { addAlert } from 'APP/Tasks/app/showAlert/showAlert';
import { linkAccount } from 'APP/Tasks/authorization/linkAccount/linkAccount';
import { ALERT_TYPES } from 'APP/constants/app';
import { AuthProvider } from 'APP/model/auth/authModel.types';

interface ILinkGoogleProviderPresenterProps {
  setIsProcessing(isProcessing: boolean): void;
}

interface ILinkGoogleProviderPresenter {
  onSuccess: UseGoogleLoginOptionsImplicitFlow['onSuccess'];
  onError?: UseGoogleLoginOptionsImplicitFlow['onError'];
}

export const useLinkGoogleProviderPresenter = ({
  setIsProcessing,
}: ILinkGoogleProviderPresenterProps): ILinkGoogleProviderPresenter => {
  const presenter = useLocalStore<ILinkGoogleProviderPresenter>(() => ({
    async onSuccess(tokenResponse): Promise<void> {
      setIsProcessing(true);
      await linkAccount(tokenResponse.access_token, AuthProvider.Google);
      setIsProcessing(false);
    },

    onError(): void {
      addAlert({
        type: ALERT_TYPES.SOMETHING_WRONG_ERROR,
      });
    },
  }));

  return presenter;
};
