import classNames from 'classnames';
import React, { FC } from 'react';

import { useTranslation } from 'APP/packages/translations';
import ReadDoneIcon from 'ICONS/ic-s-read-done-16.svg';
import { ChatBackground } from 'MAIN/components/ChatBackground/ChatBackground';
import { useChatBackgroundClassName } from 'MAIN/components/ChatBackground/hooks/useChatBackgroundClassName';
import { IconView, IconViewSizes } from 'UIKIT/IconView/IconView';

import styles from './AppearanceExample.styles.m.css';

export const AppearanceExample: FC = () => {
  const { t } = useTranslation();
  const chatBackgroundClassName = useChatBackgroundClassName();

  return (
    <div className={classNames(styles.container, chatBackgroundClassName)}>
      <ChatBackground />

      <div className={styles.content}>
        <div className={classNames(styles.messageBubble, styles.left)}>
          <div className={styles.messageReply}>
            <div className={styles.replyName}>{t('chat_appearance_text_size_stub1')}</div>
            <div className={styles.replyText}>{t('chat_appearance_text_size_stub2')}</div>
          </div>

          <div className={styles.message}>
            <span className={styles.messageText}>{t('chat_appearance_text_size_stub3')}</span>

            <span className={styles.messageStatus}>10:15</span>
          </div>
        </div>

        <div className={classNames(styles.messageBubble, styles.right)}>
          <div className={styles.message}>
            <span className={styles.messageText}>{t('chat_appearance_text_size_stub4')}</span>

            <span className={styles.messageStatus}>
              10:16
              <IconView
                className={styles.statusIcon}
                Icon={ReadDoneIcon}
                size={IconViewSizes.Small}
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
