import { counterModel } from 'APP/model/model';
import Entities from 'APP/store';

import { addCounters } from '../addCounters/addCounters';
import { loadAllScheduledMessages } from '../loadAllScheduledMessages/loadAllScheduledMessages';
import loadGroupsByIds from '../loadGroupsByIds';

async function loadGroups(counterTs: number | null = null, limit = 20): Promise<void> {
  const { items, endPageTs } = await counterModel.getGroupCountersPage(counterTs, limit);
  const groupsIds = items.map((item) => item.groupId);
  await loadGroupsByIds(groupsIds);
  const counters = items
    .filter(({ groupId }) => Entities.GroupsStore.getGroupById(groupId))
    .sort(
      (a, b) =>
        Math.max(b.lastEventTs || 0, b.lastMessageTs || 0, b.seenTs) -
        Math.max(a.lastEventTs || 0, a.lastMessageTs || 0, a.seenTs)
    );
  await addCounters(counters);

  if (endPageTs !== null) {
    await loadGroups(endPageTs && Number(endPageTs), 100);
  }
}

export async function initGroups(): Promise<void> {
  Entities.GroupsStore.setLoading(true);
  try {
    await loadGroups();
  } catch {
    //
  }
  Entities.GroupsStore.setLoading(false);

  loadAllScheduledMessages();
}
