import { observer } from 'mobx-react';
import React from 'react';

import { IconButton, IconViewSizes } from 'APP/packages/UiKit/IconButton';
import CollapseIcon from 'ICONS/ic-collapse.svg';
import ExpandIcon from 'ICONS/ic-expand.svg';
import Entities from 'STORE';

export const FullScreenButton = observer(({ className = undefined }) => {
  return (
    <IconButton
      Icon={Entities.OngoingCall.mode.isFullScreen ? CollapseIcon : ExpandIcon}
      onClick={
        Entities.OngoingCall.mode.isFullScreen
          ? Entities.OngoingCall.mode.collapse
          : Entities.OngoingCall.mode.expand
      }
      size={IconViewSizes.Big}
      theme="12"
      iconSize={IconViewSizes.Medium}
      className={className}
    />
  );
});
