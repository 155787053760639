import React, { useEffect, useState } from 'react';

import dateService from 'APP/packages/date';

const formatDate = (date) => {
  let options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };

  if (dateService.isLastYear(date)) {
    options = {
      month: 'short',
      day: 'numeric',
    };
  }

  if (dateService.isLastWeek(date)) {
    options = {
      weekday: 'short',
    };
  }

  if (dateService.isToday(date)) {
    options = {
      hour: 'numeric',
      minute: 'numeric',
    };
  }
  return dateService.format(date, options);
};

export const GroupLastDate = ({ date, className }) => {
  const [now, setNow] = useState(dateService.now());

  useEffect(() => {
    const delay = dateService.millisecondToEndOfDay();
    const timer = setTimeout(() => setNow(dateService.now()), delay);
    return () => {
      clearTimeout(timer);
    };
  }, [date]);

  return (
    <div
      className={className}
      key={now.toString()}
    >
      {formatDate(date)}
    </div>
  );
};
