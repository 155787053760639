import React, { FC } from 'react';

import styles from './ProgressLoadIcon.styles.m.css';

interface IProgressUploadIcon {
  progress: number;
  onClick: React.ReactEventHandler<SVGAElement>;
  Icon: FC<{
    className: string;
    style: { strokeDashoffset: number };
    onClick: React.ReactEventHandler<SVGAElement>;
  }>;
}

export const ProgressLoadIcon: FC<IProgressUploadIcon> = (props) => {
  const { progress, onClick, Icon } = props;

  return (
    <Icon
      className={styles.icon}
      style={{ strokeDashoffset: 100 - progress }}
      onClick={onClick}
    />
  );
};
