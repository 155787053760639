import React, { memo } from 'react';

import styles from '../FileIcon.styles.m.css';
import { TFileTheme } from '../FileIcon.types';

interface IFileIconErrorProps {
  theme: TFileTheme;
  text: string;
}

export const FileIconError = memo<IFileIconErrorProps>((props) => {
  const { theme, text } = props;

  return (
    <>
      <path
        className={styles[`file-${theme}`]}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 3H31L43 15V29.8321C41.7751 29.2969 40.4222 29 39 29C33.4772 29 29 33.4772 29 39C29 40.4222 29.2969 41.7751 29.8321 43H11C7.68629 43 5 40.3137 5 37V9C5 5.68629 7.68629 3 11 3Z"
      />
      <path
        opacity="0.2"
        d="M43 15L31 3V11C31 13.2091 32.7909 15 35 15H43Z"
        fill="black"
      />
      <circle
        cx="39"
        cy="39"
        r="9"
        className={styles[`file-error-icon-${theme}`]}
      />
      <path
        d="M40 42C40 42.5523 39.5523 43 39 43C38.4477 43 38 42.5523 38 42C38 41.4477 38.4477 41 39 41C39.5523 41 40 41.4477 40 42ZM40 39C40 39.5523 39.5523 40 39 40C38.4477 40 38 39.5523 38 39V36C38 35.4477 38.4477 35 39 35C39.5523 35 40 35.4477 40 36V39Z"
        fill="white"
      />
      <text
        className={styles.text}
        x="50%"
        y="50%"
        dominantBaseline="middle"
        textAnchor="middle"
      >
        {text}
      </text>
    </>
  );
});
