import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import ViewsIcon from 'ICONS/ic-view-16.svg';
import { IconView } from 'UIKIT/IconView/IconView';
import { Tooltip } from 'UIKIT/Tooltip/Tooltip';

import { useMessageViewsPresenter } from './MessageViews.presenter';
import styles from './MessageViews.styles.m.css';
import { MessageViewsPopover } from './MessageViewsPopover/MessageViewsPopover';
import { MessageViewsStatusIcon } from './MessageViewsStatusIcon/MessageViewsStatusIcon';

export const MessageViews = observer(({ message, className, showStatus = true }) => {
  const presenter = useMessageViewsPresenter({ message });
  const containerClass = classNames(styles.views, className, {
    [styles.canShowPointer]: presenter.canBeShowPopover,
  });

  if (!presenter.isShow) {
    return showStatus ? <MessageViewsStatusIcon message={message} /> : null;
  }

  return (
    <div className={containerClass}>
      <Tooltip
        render={(tooltip) => (
          <MessageViewsPopover
            message={message}
            tooltip={tooltip}
            onOpenReactionPopup={presenter.onOpenReactionPopup}
          />
        )}
        arrow={true}
        delay={300}
        disabled={!presenter.canBeShowPopover}
      >
        <div
          className={styles.content}
          aria-expanded={false}
        >
          <IconView
            Icon={ViewsIcon}
            size="small"
            className={styles.icon}
          />
          <span>{presenter.views}</span>
        </div>
      </Tooltip>
    </div>
  );
});
