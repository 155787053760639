import { observer } from 'mobx-react';
import React, { FC, ReactElement } from 'react';

import { List } from 'APP/components/List/List';
import { IStickerPackExtend } from 'APP/model/stickers/stickersModel.types';

import { StickerPack } from './StickerPack/StickerPack';
import { useStickersSettingsContent } from './StickersSettingsContent.presenter';

interface IStickersSettingsContentProps {
  onStickerPackClick(stickerPack: IStickerPackExtend): void;
}
export const StickersSettingsContent: FC<IStickersSettingsContentProps> = observer(
  ({ onStickerPackClick }) => {
    const presenter = useStickersSettingsContent();

    const renderItem = (item: IStickerPackExtend): ReactElement => {
      return (
        <StickerPack
          data={item}
          onStickerPackClick={onStickerPackClick}
        />
      );
    };

    const keyExtractor = (stickerPack: IStickerPackExtend): string => stickerPack.stickerPack.id;

    return (
      <List
        isScrollable
        willChange={false}
        data={presenter.packs}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
      />
    );
  }
);
