import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

import { ProfileNameBadge } from 'APP/components/ProfileNameBadge/ProfileNameBadge';
import { User } from 'STORE/Users/User/User';
import { Avatar } from 'UIKIT/Avatar/Avatar';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';

import { ISearchResultItemProps } from '../SearchResultItem.types';
import { useSearchResultUserPresenter } from './SearchResultUser.presenter';
import styles from './SearchResultUser.style.m.css';

export const SearchResultUser: FC<ISearchResultItemProps<User>> = observer(
  ({ className, activeClassName, entity: user, isLocalResult }) => {
    const { presenter, userOnlineStatusText } = useSearchResultUserPresenter(user);

    const content = (
      <>
        <Avatar
          url={user.avatarUrl || undefined}
          title={user.avatarTitle}
          backgroundColorIndex={user.avatarColorIndex}
          type={AvatarTypes.Round_10}
        />
        <div className={styles.content}>
          <div className={styles.top}>
            <div className={styles.headerWrapper}>
              <div className={styles.header}>{user.avatarTitle}</div>
              <ProfileNameBadge
                inlineBadge
                verified={presenter.verified}
                source={presenter.userNameBadge?.source}
                animated={presenter.userNameBadge?.animated}
                displayUILayer={0}
              />
            </div>
          </div>
          <div className={styles.bottom}>
            <div className={styles.description}>
              {isLocalResult ? userOnlineStatusText : user.avatarSubTitle}
            </div>
          </div>
        </div>
      </>
    );

    if (presenter.hasGroupWithUser) {
      return (
        <NavLink
          className={className}
          activeClassName={activeClassName}
          to={presenter.p2pRoute}
        >
          {content}
        </NavLink>
      );
    }

    return (
      <div
        className={className}
        onClick={presenter.handleGroupClick}
        role="button"
        tabIndex={0}
      >
        {content}
      </div>
    );
  }
);
