/* global Range */
import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import IcBold from 'ICONS/ic-bold.svg';
import IcItalic from 'ICONS/ic-italic.svg';
import IcStrikeThrough from 'ICONS/ic-strikethrough.svg';
import IcUnderline from 'ICONS/ic-underline.svg';

export default ({ contentInstance, popoverInstance, onClose }) => {
  const presenter = useLocalStore(() => ({
    range: null,
    isEnterLinkView: false,

    activeKeys: {
      bold: false,
      italic: false,
      underline: false,
      strikeThrough: false,
      link: false,
    },

    saveRange: () => {
      const selection = window.getSelection();

      if (selection) {
        presenter.range = selection.getRangeAt(0) || null;
      }
    },

    restoreRange: () => {
      const selection = window.getSelection();

      if (
        selection.rangeCount > 0 &&
        selection.getRangeAt(0).compareBoundaryPoints(Range.START_TO_END, presenter.range) === 0
      ) {
        // if the range is the same we dont need to do anything
        return;
      }

      selection.removeAllRanges();
      selection.addRange(presenter.range);
    },

    getIsTag: (range, tagName) => {
      if (!range || !tagName) {
        return false;
      }

      const start = range.startContainer.parentNode.closest(tagName);
      const end = range.endContainer.parentNode.closest(tagName);

      if (!start || !end) {
        return false;
      }

      return start === end;
    },

    returnToMenu: () => {
      presenter.isEnterLinkView = false;
      presenter.restoreRange();
    },

    onClickOutsideOfButtons: () => {
      if (presenter.isEnterLinkView) {
        return;
      }

      presenter.restoreRange();
    },

    checkIsActive: () => {
      const selection = document.getSelection();
      const range = selection.getRangeAt(0);

      presenter.activeKeys = {
        bold: document.queryCommandState('bold'),
        italic: document.queryCommandState('italic'),
        underline: document.queryCommandState('underline'),
        strikeThrough: document.queryCommandState('strikeThrough'),
        link: presenter.getIsTag(range, 'a'),
      };
    },

    onButtonClick: (command, addionalParameter) => {
      document.execCommand(command, false, addionalParameter);
      presenter.saveRange();
    },

    options: [
      {
        key: 'bold',
        icon: IcBold,
        onClick: () => presenter.onButtonClick('bold'),
      },
      {
        key: 'italic',
        icon: IcItalic,
        onClick: () => presenter.onButtonClick('italic'),
      },
      {
        key: 'underline',
        icon: IcUnderline,
        onClick: () => presenter.onButtonClick('underline'),
      },
      {
        key: 'strikeThrough',
        icon: IcStrikeThrough,
        onClick: () => presenter.onButtonClick('strikeThrough'),
      },
    ],

    toggleLinkIcon: (event) => {
      if (presenter.activeKeys.link) {
        presenter.onButtonClick('unlink');
      } else {
        event.stopPropagation();
        presenter.isEnterLinkView = true;
      }
    },

    onAddLink: (link) => {
      presenter.returnToMenu();
      presenter.onButtonClick('createLink', link);
    },

    onEsc: () => {
      if (presenter.range) {
        presenter.range.collapse(); // set cursor to end position

        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(presenter.range);
      }

      onClose();
    },
  }));

  useEffect(() => {
    presenter.saveRange();
    presenter.checkIsActive();
  }, []);

  contentInstance.current = {
    update: () => {
      presenter.checkIsActive();
      popoverInstance.update();
    },
  };

  return presenter;
};
