import { useLocalStore, useAsObservableSource } from 'mobx-react';

import { ChatMessage } from 'APP/store/Messages/Message/ChatMessage/ChatMessage';
import { TPostInstance } from 'STORE/Newsfeed/Newsfeed.types';

import { TSetNewsVisibilityHandler } from '../NewsfeedContent.types';

interface INewsfeedPostItemPresenterParams {
  newsItem: TPostInstance;
  position: number;
  onSetNewsVisibility: TSetNewsVisibilityHandler;
}

interface INewsfeedPostItemPresenter {
  isPreview: boolean;
  isView: boolean;
  isShow: boolean;
  isShowHiddenInfo: boolean;
  title: string;
  message: ChatMessage;
  onView(isView: boolean): void;
  onPreview(isView: boolean): void;
}

export const useNewsfeedPostItemPresenter = (data: INewsfeedPostItemPresenterParams) => {
  const { newsItem, position, onSetNewsVisibility } = data;
  const source = useAsObservableSource({ newsItem, position });

  const presenter = useLocalStore<INewsfeedPostItemPresenter>(() => ({
    isPreview: false,
    isView: false,

    get isShow(): boolean {
      return !!source.newsItem.message;
    },

    get isShowHiddenInfo(): boolean {
      return source.newsItem.isShowHiddenInfo;
    },

    get title(): string {
      return source.newsItem.title;
    },

    get message(): ChatMessage {
      return source.newsItem.message as ChatMessage;
    },

    onView(isView: boolean): void {
      presenter.isView = isView;
      onSetNewsVisibility(source.position, isView);
    },

    onPreview(isView: boolean): void {
      presenter.isPreview = isView;
    },
  }));

  return presenter;
};
