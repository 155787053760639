import { useLocalStore } from 'mobx-react';
import { useEffect, useRef } from 'react';

import { ProviderUid } from 'APP/packages/callProviders/callProviders.types';
import Entities from 'APP/store';
import { Call } from 'STORE/Calls/Call/Call';
import { Opponent } from 'STORE/Calls/Call/Opponent/Opponent';

interface IShareScreenToggle {
  ongoingCall: Call;
  screenSharingOpponent: Opponent | null;
  activeOpponentUid: ProviderUid | null;
  isGridLayoutView: boolean;
  toggleGridLayout(): void;
}

export const useShareScreenToggle = (): IShareScreenToggle => {
  const presenter = useLocalStore<IShareScreenToggle>(() => ({
    get ongoingCall(): Call {
      return Entities.Calls.ongoingCall!;
    },

    get screenSharingOpponent(): Opponent | null {
      return presenter.ongoingCall.opponents.screenSharingOpponent;
    },

    get activeOpponentUid(): ProviderUid | null {
      return presenter.ongoingCall.activeOpponentUid;
    },

    get isGridLayoutView(): boolean {
      return (
        Entities.OngoingCall.mode.isGroup && Entities.OngoingCall.presentVideoMode?.isGridLayout
      );
    },

    toggleGridLayout(): void {
      Entities.OngoingCall.presentVideoMode.toggleGridLayout();
    },
  }));

  const prevScreenSharingOpponentUidRef = useRef<ProviderUid | null>();
  const prevOpponentUidRef = useRef<ProviderUid | null>();

  useEffect(() => {
    if (presenter.screenSharingOpponent) {
      if (presenter.isGridLayoutView) {
        presenter.toggleGridLayout();
      }
      Entities.OngoingCall.presentVideoMode.setActiveOpponent(presenter.screenSharingOpponent);
    } else if (
      prevScreenSharingOpponentUidRef.current === prevOpponentUidRef.current &&
      !presenter.isGridLayoutView
    ) {
      presenter.toggleGridLayout();
    }
  }, [presenter.screenSharingOpponent]);

  useEffect(() => {
    prevOpponentUidRef.current = presenter.activeOpponentUid;
    prevScreenSharingOpponentUidRef.current = presenter.screenSharingOpponent?.uid;
  }, [presenter.screenSharingOpponent, presenter.activeOpponentUid]);

  return presenter;
};
