import { runInAction } from 'mobx';

import { GroupUserRole } from 'APP/model/group/groupModel.types';
import { spaceModel } from 'APP/model/model';
import Entities from 'STORE';

export async function loadSpaceAvailableGroups(spaceId: string): Promise<void> {
  try {
    const availableGroups = await spaceModel.getAvailableGroups(spaceId);

    const groupList = availableGroups.map((group) => ({
      groupResponse: group,
      muted: false,
      pinOrder: 0,
      unread: false,
      userGroupRole: GroupUserRole.Guest,
    }));

    runInAction(() => {
      Entities.GroupsStore.getGroupsBySpaceId(spaceId).forEach((group) => {
        if (group.withoutMe) {
          group.setSpaceId(null);
        }
      });
      Entities.GroupsStore.merge(groupList);
    });
  } catch {
    //
  }
}
