import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import { initEventsListener } from 'APP/model/events/eventsModel';
import { EventName } from 'APP/model/events/eventsModel.types';
import Entities from 'APP/store';

import {
  handleStartedCall,
  handleBatchedActiveCalls,
  handleChangeActiveCall,
} from './callsHandlers';
import {
  handleBatchedCounters,
  handleChangeCounter,
  handleBatchedGroupUpdates,
} from './counterHandlers';
import { handleChangeOnlineStatus, handleBatchedOnlineStatuses } from './onlineStatusHandlers';
import { handlePushNotification } from './pushNotificationsHandlers';
import { handleRemoveSession, handleAddSession } from './sessionHandlers';
import { slowModeHandler } from './slowModeHandler';
import {
  handleSpacesCollectionUpdated,
  handleSpacesChangedBatched,
  handleSpacesUpdated,
} from './spaceEventsHandlers';
import { handleChangeActiveStream, handleBatchedActiveStreams } from './streamingHandlers';

interface IEventHandlePresenter {
  disposeListener: (() => void) | null;
  timeout: ReturnType<typeof setInterval> | null;
  isLoadedGroups: boolean;
  isLoadedSpaces: boolean;
  counterTs: number;
  spaceTs: number;
  connect(): void;
  disconnect(): void;
  onError(): void;
}

export function useEventHandlePresenter(): void {
  const presenter = useLocalStore<IEventHandlePresenter>(() => ({
    disposeListener: null,
    timeout: null,

    get isLoadedGroups(): boolean {
      return !Entities.GroupsStore.isLoading;
    },

    get isLoadedSpaces(): boolean {
      return !Entities.spacesStore.isLoading;
    },

    get counterTs(): number {
      const counterTsList = Entities.Counters.countersList.map((counter) => {
        return counter?.counterTs || 0;
      });
      if (counterTsList.length) {
        return Math.max(...counterTsList);
      }
      return 0;
    },

    get spaceTs(): number {
      return Number(Entities.spacesStore.spaceTs);
    },

    connect(): void {
      if (
        (!presenter.counterTs && !presenter.isLoadedGroups) ||
        !presenter.isLoadedSpaces ||
        presenter.disposeListener
      ) {
        return;
      }

      presenter.disposeListener = initEventsListener(
        presenter.counterTs,
        presenter.spaceTs,
        {
          [EventName.CallsActiveBatched]: handleBatchedActiveCalls,
          [EventName.CallsActiveCallChanged]: handleChangeActiveCall,
          [EventName.CallsActiveCallStarted]: handleStartedCall,

          [EventName.StreamsActiveBatched]: handleBatchedActiveStreams,
          [EventName.StreamsActiveStreamChanged]: handleChangeActiveStream,

          [EventName.GroupsCountersBatched]: handleBatchedCounters,
          [EventName.GroupsCountersGroupChanged]: handleChangeCounter,
          [EventName.GroupsUpdatesBatched]: handleBatchedGroupUpdates,

          [EventName.UsersOnlineBatched]: handleBatchedOnlineStatuses,
          [EventName.UsersOnlineUserChanged]: handleChangeOnlineStatus,

          [EventName.DevicesAdded]: handleAddSession,
          [EventName.DevicesDeleted]: handleRemoveSession,

          [EventName.NotificationsDesktopSent]: handlePushNotification,

          [EventName.SpacesChangedBatched]: handleSpacesChangedBatched,
          [EventName.SpacesUpdated]: handleSpacesUpdated,
          [EventName.SpacesCollectionUpdated]: handleSpacesCollectionUpdated,

          [EventName.SlowModeUpdate]: slowModeHandler,
        },
        presenter.onError
      );
    },

    disconnect(): void {
      if (presenter.disposeListener) {
        presenter.disposeListener();
        presenter.disposeListener = null;
        if (presenter.timeout) {
          clearTimeout(presenter.timeout);
        }
      }
    },

    onError(): void {
      // we should not use automatic reconnect
      // because parameter counterTs is cached for automatic reconnect
      presenter.disconnect();

      if (Entities.AuthStore.streamToken) {
        presenter.timeout = setTimeout(() => {
          presenter.connect();
        }, 3000); // it helps to avoid a lot of request to connect (dos attack)
      }
    },
  }));

  useEffect((): void => {
    presenter.connect();
  }, [presenter.isLoadedGroups, presenter.isLoadedSpaces, presenter.counterTs]);

  useEffect(() => {
    return (): void => {
      presenter.disconnect();
    };
  }, []);

  useEffect((): void => {
    if (Entities.AuthStore.streamToken) {
      presenter.connect();
    }
    if (!Entities.AuthStore.streamToken) {
      presenter.disconnect();
    }
  }, [Entities.AuthStore.streamToken]);
}
