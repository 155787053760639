import GoogleMapReact from 'google-map-react';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { MapMarker } from 'APP/components/MapMarker/MapMarker';
import { Popup } from 'APP/components/Popup/Popup';
import { GOOGLE_MAP_KEY } from 'APP/constants';
import { useTranslation } from 'APP/packages/translations';
import { Button } from 'UIKIT/Button/Button';
import { PopupFooter } from 'UIKIT/PopupFooter/PopupFooter';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { useClosePopupOnNavigation } from '../../hooks/useClosePopupOnNavigation';
import { CenterPinButton } from './CenterPinButton/CenterPinButton';
import { MapControl } from './MapControl/MapControl';
import { ISelectLocationParams, useSelectLocationPopup } from './SelectLocationPopup.presenter';
import styles from './SelectLocationPopup.styles.m.css';

type ISelectLocationProps = ISelectLocationParams;

export const SelectLocationPopup: FC<ICommonPopupsProps<ISelectLocationProps>> = observer(
  (props) => {
    const { t } = useTranslation();
    const presenter = useSelectLocationPopup(props);
    useClosePopupOnNavigation(props.popupInstance);

    if (!presenter.isShowPopup) {
      return null;
    }

    return (
      <Popup
        title={t('location_map_title')}
        isOpened
        onClose={presenter.onClose}
      >
        <div className={styles.mapContainer}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: GOOGLE_MAP_KEY }}
            onClick={presenter.setCoordinates}
            onGoogleApiLoaded={presenter.setMaps}
            yesIWantToUseGoogleMapApiInternals
            defaultCenter={
              presenter.defaultLatitude && presenter.defaultLongitude
                ? {
                    lat: presenter.defaultLatitude,
                    lng: presenter.defaultLongitude,
                  }
                : undefined
            }
            defaultZoom={presenter.defaultZoom}
          >
            <MapMarker
              lat={presenter.latitude}
              lng={presenter.longitude}
            />
            <MapControl
              map={presenter.map}
              controlPosition={presenter.controlPosition}
            >
              <CenterPinButton centerPin={presenter.centerPin} />
            </MapControl>
          </GoogleMapReact>
        </div>

        <PopupFooter>
          <Button
            onClick={presenter.onSubmit}
            theme="1"
          >
            {presenter.isSheduledGroup ? t('common:common_schedule') : t('send')}
          </Button>
        </PopupFooter>
      </Popup>
    );
  }
);
