import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { IconView, IconViewSizes } from 'APP/packages/UiKit/IconView/IconView';
import icMessage from 'ICONS/ic-message-16.svg';
import icNext from 'ICONS/ic-next.svg';
import IconMention from 'ICONS/mention.svg';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';
import { Badge } from 'UIKIT/Badge/Badge';

import { useThreadButtonPresenter } from './ThreadButton.presenter';
import styles from './ThreadButton.styles.m.css';

type TThreadButtonTheme = '1' | '2';

interface IThreadButtonProps {
  className?: string;
  theme?: TThreadButtonTheme;
  message: ChatMessage;
}

export const ThreadButton: FC<IThreadButtonProps> = observer((props) => {
  const { className, theme = '1', message } = props;
  const presenter = useThreadButtonPresenter(message);

  if (!presenter.isVisible) {
    return null;
  }

  return (
    <div
      className={classNames(styles.container, styles[`theme-${theme}`], className)}
      role="button"
      tabIndex={0}
      onClick={presenter.onClick}
    >
      <IconView
        Icon={icMessage}
        className={classNames(styles.icon, styles.iconThread)}
        size={IconViewSizes.Small}
      />

      <span className={styles.text}>{presenter.caption}</span>

      {presenter.hasUnreadMentions && (
        <IconView
          Icon={IconMention}
          className={classNames(styles.icon, styles.iconMention)}
          size={IconViewSizes.Small}
        />
      )}

      {presenter.hasUnread && (
        <Badge
          className={classNames(styles.icon, styles.iconUnread)}
          quantity={presenter.countUnread}
        />
      )}

      <IconView
        Icon={icNext}
        className={classNames(styles.icon, styles.iconNext)}
        size={IconViewSizes.Medium}
      />
    </div>
  );
});
