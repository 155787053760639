import React, { FC, Fragment, ReactNode } from 'react';

import { useTransPresenter } from './Trans.presenter';

interface ITrans {
  translateKey: string;
  keyParams: Record<string | number, ReactNode>;
}
export const Trans: FC<ITrans> = ({ translateKey, keyParams }) => {
  const presenter = useTransPresenter({ translateKey, keyParams });

  return (
    <>
      {presenter.chunks.map((chunk) => (
        <Fragment key={chunk}>{presenter.renderChunk(chunk)}</Fragment>
      ))}
    </>
  );
};
