import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { VoiceHistogram } from 'APP/components/VoiceHistogram/VoiceHistogram';
import PauseIcon from 'ICONS/ic-pause-s.svg';
import PlayIcon from 'ICONS/ic-play-s.svg';
import { FAB } from 'UIKIT/FAB/FAB';

import { usePreviewRecordPresenter } from './PreviewRecord.presenter';
import styles from './PreviewRecord.styles.m.css';

interface IPreviewRecordProps {
  className?: string;
}

export const PreviewRecord: FC<IPreviewRecordProps> = observer(({ className }) => {
  const {
    audioRef,
    src,
    histogram,
    isPlaying,
    time,
    progress,
    handlePlay,
    handlePause,
    onTimeUpdate,
    onEnded,
    onCanPlay,
    onChangeProgress,
  } = usePreviewRecordPresenter();

  return (
    <div className={className}>
      <div className={styles.container}>
        {!isPlaying && (
          <FAB
            Icon={PlayIcon}
            onClick={handlePlay}
            size="10"
            theme="1"
          />
        )}
        {isPlaying && (
          <FAB
            Icon={PauseIcon}
            onClick={handlePause}
            size="10"
            theme="1"
          />
        )}

        <VoiceHistogram
          className={styles.histogram}
          histogram={histogram}
          progress={progress}
          isMirrored={true}
          onChangeProgress={onChangeProgress}
        />

        <span className={styles.timer}>{time}</span>

        <audio
          src={src}
          ref={audioRef}
          onCanPlay={onCanPlay}
          onTimeUpdate={onTimeUpdate}
          onEnded={onEnded}
        />
      </div>
    </div>
  );
});
