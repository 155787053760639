import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { InView } from 'react-intersection-observer';

import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

import { useLoadBelowObserverPresenter } from './LoadBelowObserver.presenter';
import styles from './LoadBelowObserver.styles.m.css';

interface ILoadBelowObserverProps {
  groupId: string;
  lastMessage: ChatMessage | null;
  rootElement: HTMLDivElement | null;
  offset: number;
  isDisabled: boolean;
}

export const LoadBelowObserver: FC<ILoadBelowObserverProps> = observer(
  ({ groupId, lastMessage, rootElement, offset, isDisabled }) => {
    const presenter = useLoadBelowObserverPresenter(groupId, lastMessage);

    return (
      <InView
        className={styles.container}
        root={rootElement}
        rootMargin={`0px 0px ${offset}px 0px`}
        skip={!presenter.hasLoadBelowObserver || isDisabled}
        onChange={presenter.onInViewChange}
      />
    );
  }
);
