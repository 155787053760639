export function isMediaRecorderSupportedWavFormat() {
  return window.MediaRecorder && window.MediaRecorder.isTypeSupported('audio/wav');
}

export const isWebPSupported = () => {
  // export to helper?
  return new Promise((resolve) => {
    const webP = new Image();

    // eslint-disable-next-line max-len
    webP.src =
      'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
    webP.onload = webP.onerror = () => {
      resolve(webP.height === 2);
    };
  });
};
