import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect, useRef, RefObject } from 'react';

import Tasks from 'APP/Tasks';
import { DIRECTION } from 'APP/constants';
import { SharedDataType } from 'APP/constants/sharedData';
import Entities from 'APP/store';
import { IMediaGalleryState } from 'APP/types/mediaGallery';
import { Group } from 'STORE/Groups/Group';
import { SharedMedia } from 'STORE/SharedData/SharedMedia/SharedMedia';
import { debounce } from 'UTILS/debounce';

import { IMediaGalleryFile } from '../MediaGallery.types';

interface IMediaGalleryContentPresenter {
  isShowNavigation: boolean;
  group: Group | null;
  reverse: boolean;
  sharedMedia: SharedMedia | null;
  hasMore: boolean;
  hasAbove: boolean;
  isPrevButton: boolean;
  isNextButton: boolean;
  isPrevLoading: boolean;
  isNextLoading: boolean;
  isButton(isPrev: boolean): boolean;
  isButtonLoading(isPrev: boolean): boolean;
  to(isPrev: boolean): void;
  toPrev(): void;
  toNext(): void;
  onHideNavagation(): void;
  onShowNavigation(): void;
  onMouseLeave(): void;
}

interface IUseMediaGalleryContentPresenter {
  presenter: IMediaGalleryContentPresenter;
  contentRef: RefObject<HTMLDivElement>;
}

const BUTTON_HIDE_TIMEOUT = 3 * 1000;

export const useMediaGalleryContentPresenter = (
  file: IMediaGalleryFile,
  galleryState: IMediaGalleryState
): IUseMediaGalleryContentPresenter => {
  const contentRef = useRef<HTMLDivElement>(null);
  const source = useAsObservableSource({ file, galleryState });

  const presenter = useLocalStore<IMediaGalleryContentPresenter>(() => ({
    isShowNavigation: true,

    get group(): Group | null {
      return Entities.GroupsStore.getGroupById(source.file.message.groupId);
    },

    get reverse(): boolean {
      //  down up (chat view); top down (shared media)
      return !!source.galleryState.reverse;
    },

    get sharedMedia(): SharedMedia | null {
      return presenter.group?.sharedData?.media || null;
    },

    get hasMore(): boolean {
      return Boolean(presenter.sharedMedia?.hasMore && !source.galleryState.messageIds?.length);
    },

    get hasAbove(): boolean {
      return Boolean(
        presenter.sharedMedia?.lastMessageTs &&
          presenter.sharedMedia?.lastMessageTs < (presenter.group?.counter?.lastMessageTs || 0) &&
          !source.galleryState.messageIds?.length
      );
    },

    get isPrevButton(): boolean {
      return presenter.isButton(true);
    },

    get isNextButton(): boolean {
      return presenter.isButton(false);
    },

    get isPrevLoading(): boolean {
      return presenter.isButtonLoading(true);
    },

    get isNextLoading(): boolean {
      return presenter.isButtonLoading(false);
    },

    isButton(isPrev: boolean): boolean {
      if ((isPrev && !presenter.reverse) || (!isPrev && presenter.reverse)) {
        return Boolean(source.file.prevFile || presenter.sharedMedia?.isLoading);
      }

      return Boolean(source.file.nextFile || presenter.sharedMedia?.isLoading);
    },

    isButtonLoading(isPrev: boolean): boolean {
      if ((isPrev && !presenter.reverse) || (!isPrev && presenter.reverse)) {
        return Boolean(!source.file.prevFile && presenter.sharedMedia?.isLoading);
      }
      return Boolean(!source.file.nextFile && presenter.sharedMedia?.isLoading);
    },

    to(isPrev: boolean): void {
      if ((isPrev && !presenter.reverse) || (!isPrev && presenter.reverse)) {
        if (source.file.prevFile) {
          Tasks.group.updateMediaGallery({
            messageId: source.file.prevFile.messageId,
            mediaId: source.file.prevFile.id,
          });
        }
      } else {
        if (source.file.nextFile) {
          Tasks.group.updateMediaGallery({
            messageId: source.file.nextFile.messageId,
            mediaId: source.file.nextFile.id,
          });
        }
      }
    },

    toPrev(): void {
      presenter.to(true);
    },

    toNext(): void {
      presenter.to(false);
    },

    onHideNavagation: debounce((): void => {
      presenter.isShowNavigation = false;
    }, BUTTON_HIDE_TIMEOUT),

    onShowNavigation(): void {
      presenter.isShowNavigation = true;
      presenter.onHideNavagation();
    },

    onMouseLeave(): void {
      presenter.isShowNavigation = false;
    },
  }));

  useEffect(() => {
    const contentElement = contentRef.current;
    if (!contentElement) {
      return;
    }

    presenter.onHideNavagation();

    // The video player used the React portal.
    // In the virtual dom the components that were created through portal
    // are located outside the context of the parent element. They stand alone.
    // The react events that occur in these components cannot be caught in the parent element.
    // And the only way to catch such events in the parent component is native js events.
    // Se more about that at https://github.com/facebook/react/issues/11387
    contentElement.addEventListener('mousemove', presenter.onShowNavigation);
    contentElement.addEventListener('mouseenter', presenter.onShowNavigation);
    contentElement.addEventListener('mouseleave', presenter.onMouseLeave);
    contentElement.addEventListener('click', presenter.onShowNavigation);

    return () => {
      contentElement.removeEventListener('mousemove', presenter.onShowNavigation);
      contentElement.removeEventListener('mouseenter', presenter.onShowNavigation);
      contentElement.removeEventListener('mouseleave', presenter.onMouseLeave);
      contentElement.removeEventListener('click', presenter.onShowNavigation);
    };
  }, [contentRef.current]);

  useEffect(() => {
    if (!file.prevFile && presenter.hasAbove) {
      Tasks.messaging.getMessagesByMediaTypes({
        groupId: presenter.group?.id,
        cursorTs: presenter.sharedMedia?.lastMessageTs,
        direction: DIRECTION.NEWEST,
        type: SharedDataType.Media,
      });
    }
    if (!file.nextFile && presenter.hasMore) {
      Tasks.messaging.getMessagesByMediaTypes({
        groupId: presenter.group?.id,
        cursorTs: file.message.serverTime + 1,
        direction: DIRECTION.OLDEST,
        type: SharedDataType.Media,
      });
    }
  }, [file]);

  return { presenter, contentRef };
};
