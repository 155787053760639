import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';

interface IDraftInputPresenter {
  updateTimeout: ReturnType<typeof setTimeout> | null;
  value: string;
  update(value: string): void;
  reset(): void;
}

const UPDATE_TIMEOUT = 3000;

export const useDraftInput = (groupId: string): IDraftInputPresenter => {
  const source = useAsObservableSource({ groupId });

  const presenter = useLocalStore<IDraftInputPresenter>(() => ({
    updateTimeout: null,
    value: '',

    update(value: string): void {
      presenter.reset();
      presenter.value = value;
      presenter.updateTimeout = setTimeout(() => {
        Tasks.group.updateDraftInput(source.groupId, presenter.value);
      }, UPDATE_TIMEOUT);
    },

    reset(): void {
      if (presenter.updateTimeout) {
        clearTimeout(presenter.updateTimeout);
      }
    },
  }));

  useEffect(() => {
    const prevGroupId = groupId;
    return () => {
      presenter.reset();
      Tasks.group.updateDraftInput(prevGroupId, presenter.value);
      presenter.value = '';
    };
  }, [groupId]);

  return presenter;
};
