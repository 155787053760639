import { useLocalStore } from 'mobx-react';
import { useEffect, useRef } from 'react';

import Tasks from 'APP/Tasks';
import { showAlert } from 'APP/Tasks/app/app';
import { linkAccount } from 'APP/Tasks/authorization/linkAccount/linkAccount';
import { TelegramConfig } from 'APP/Tasks/authorization/login/handleClientConfig/handleTelegramConfig';
import { AuthProvider } from 'APP/model/auth/authModel.types';
import { ServerErrorCode } from 'APP/model/error/error.constants';
import { useTranslation } from 'APP/packages/translations';
import Entities from 'APP/store';

const REQUEST_INTERVAL = 5000;

interface ILinkTelegramProviderPresenter {
  uri: string;
  token: string | null;
  isLoading: boolean;

  getTelegramConfig(): Promise<void>;
  runLinkAccount(): void;
  linkAccount(): Promise<void>;
}

interface ILinkTelegramProviderPresenterParams {
  onProcessingChange(active: boolean): void;
}
export const useLinkTelegramProviderPresenter = ({
  onProcessingChange,
}: ILinkTelegramProviderPresenterParams): ILinkTelegramProviderPresenter => {
  const { t } = useTranslation();

  const timer = useRef<ReturnType<typeof setTimeout> | null>();

  const presenter = useLocalStore<ILinkTelegramProviderPresenter>(() => ({
    isLoading: false,

    get uri(): string {
      return Entities.clientConfig.getConfig(TelegramConfig.Uri) || '';
    },

    get token(): string | null {
      return Entities.clientConfig.getConfig(TelegramConfig.Token);
    },

    async getTelegramConfig(): Promise<void> {
      const { token, uri } = await Tasks.authorization.getTelegramConfig();

      if (!token || !uri) {
        return;
      }

      Entities.clientConfig.setConfig(TelegramConfig.Token, token);
      Entities.clientConfig.setConfig(TelegramConfig.Uri, uri);
    },

    runLinkAccount(): void {
      if (timer.current) {
        return;
      }

      presenter.isLoading = true;

      timer.current = setTimeout(() => {
        timer.current = undefined;
        presenter.linkAccount();
      }, REQUEST_INTERVAL);
    },

    async linkAccount(): Promise<void> {
      if (!presenter.token) {
        return;
      }

      const { success, error } = await linkAccount(presenter.token, AuthProvider.Telegram);

      if (success || !error) {
        presenter.isLoading = false;
        return;
      }

      if (error === ServerErrorCode.Unauthorized) {
        presenter.runLinkAccount();
        return;
      }

      if (error === ServerErrorCode.TokenExpired) {
        await presenter.getTelegramConfig();
      }

      presenter.isLoading = false;
      showAlert(t('common_somethings_wrong_error'));
    },
  }));

  useEffect(() => {
    return (): void => {
      timer.current && clearTimeout(timer.current);
      onProcessingChange(false);
    };
  }, []);

  useEffect(() => {
    onProcessingChange(presenter.isLoading);
  }, [presenter.isLoading]);

  return presenter;
};
