import classNames from 'classnames';
import React from 'react';

import { useTranslation } from 'APP/packages/translations';
import EditIcon from 'ICONS/ic-edit-16.svg';
import { Button } from 'UIKIT/Button/Button';

import usePresenter from './presenter';
import styles from './styles.m.scss';

const ArticleDraftsButton = ({ groupId, className }) => {
  const { t } = useTranslation();
  const presenter = usePresenter({ groupId });

  return (
    <Button
      className={classNames(styles.container, className)}
      onClick={presenter.onClick}
      Icon={EditIcon}
      theme="6"
    >
      <div className={styles.title}>{t('channels:channel_article_drafts')}</div>
    </Button>
  );
};

export default ArticleDraftsButton;
