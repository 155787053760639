export enum RouterPage {
  Login = 'login',
  All = 'all',
  Chats = 'chats',
  Channels = 'channels',
  Thread = 'threads',
  Schedule = 'schedule',
  Spaces = 'spaces',
  Catalog = 'catalog',
  Hub = 'hub',
  Market = 'market',
  Newsfeed = 'newsfeed',
  AppUpdate = 'app-update',
  Monetization = 'monetization',
  CallFinished = 'unauthorized/call-finished',
  LinkExpired = 'link-expired',
  CallInvitation = 'unauthorized/call-invitation',
  CallPreview = 'unauthorized/call-preview',
}

export const RouterLink = {
  main: '/',
  login: `/${RouterPage.Login}`,
  loginPhone: `/${RouterPage.Login}/phone`,
  loginTelegram: `/${RouterPage.Login}/telegram`,
  alternativeLogin: `/${RouterPage.Login}/alternative`,
  chat: `/${RouterPage.Chats}/:id`,
  allChatPins: `/${RouterPage.Chats}/:id/all-pins`,
  chatInfo: `/${RouterPage.Chats}/:id/info`,
  channel: `/${RouterPage.Channels}/:id`,
  allChannelPins: `/${RouterPage.Channels}/:id/all-pins`,
  channelInfo: `/${RouterPage.Channels}/:id/info`,
  space: `/${RouterPage.Spaces}/:id`,
  spaceMarkets: `/${RouterPage.Spaces}/:id/markets`,
  spaceMarket: `/${RouterPage.Spaces}/:id/market`,
  spaceCollection: `/${RouterPage.Spaces}/:id/collection/:collectionId`,
  newsfeed: `/${RouterPage.Newsfeed}`,
  catalog: `/${RouterPage.Catalog}`,
  thread: `/${RouterPage.Thread}/:id`,
  schedule: `/${RouterPage.Schedule}/:id`,
  forceUpdate: `/${RouterPage.AppUpdate}`,
  monetization: `/${RouterPage.Monetization}`,
  callInvitation: `/${RouterPage.CallInvitation}/:callType/:id`,
  callPreview: `/${RouterPage.CallPreview}/:callType/:id`,
  callFinished: `/${RouterPage.CallFinished}/:callType/:id`,
  callInvitationGroup: `/${RouterPage.CallInvitation}/:callType/:id/:channelId`,
  callPreviewGroup: `/${RouterPage.CallPreview}/:callType/:id/:channelId`,
  callFinishedGroup: `/${RouterPage.CallFinished}/:callType/:id/:channelId`,
  linkExpired: `/${RouterPage.LinkExpired}`,
  articleEdit: `/${RouterPage.Channels}/:id/article/:messageId`,
  hub: `/${RouterPage.Hub}`,
  market: `/${RouterPage.Market}`,
  greetingCard: '/greeting-card',
  unauthorized: '/unauthorized',
};

export enum RouterParams {
  permissionType = 'permission-type',
  memberPermissionsId = 'member-permissions-id',
  sessionKey = 'sessionKey',
  popup = 'popup',
  groupId = 'group-id',
  messageId = 'message-id',
  deepLink = 'deepLink',
  type = 'type',
  entityId = 'entityId',
  parentId = 'parentId',
  expired = 'expired',
  branchMatchId = '_branch_match_id',
  branchReferrer = '_branch_referrer',
  spaceId = 'space-id',
  reconnectKey = 'reconnect-key',
  channelId = 'channelId',
  source = 'source',
}

export enum RouterParamsPopup {
  newMessage = 'new-message',
  addMembers = 'add-members',
  article = 'article',
  callPermissionRequests = 'call-permissions-requests',
  callPermissions = 'call-permissions',
  callChangeMemberPermission = 'call-change-member-permission',
  callChangeDefaultPermission = 'call-change-default-permission',
  callSelectMember = 'call-select-member',
  callMemberPermissions = 'call-member-permissions-id',
  selectCategory = 'select-category',
}

export enum RouterState {
  popup = 'popup',
  url = 'url',
  goBackUrl = 'goBackUrl',
  hasGoBack = 'hasGoBack',
  isBack = 'isBack',
  groupId = 'groupId',
  userId = 'userId',
  usersIds = 'usersIds',
  messageIds = 'messageIds',
  message = 'message',
  messageId = 'messageId',
  files = 'files',
  caption = 'caption',
  channel = 'channel',
  sticker = 'sticker',
  type = 'type',
  fromDeepLink = 'fromDeepLink',
  spaceId = 'spaceId',
  marketId = 'marketId',
  collectionId = 'collectionId',
  focusedMessageId = 'focusedMessageId',
  mediaId = 'mediaId',
  backState = 'backState',
  mediaGallery = 'mediaGallery',
}

export enum RouterStatePopup {
  shareMessageTo = 'share-message-to',
  channelInfo = 'channel-info',
  sticker = 'sticker',
  scheduleSettings = 'schedule-settings',
  sharedMedia = 'shared-media',
  newUsers = 'new-users',
}

export default {
  link: RouterLink,
  params: RouterParams,
  paramsEnum: {
    popup: RouterParamsPopup,
  },
  state: RouterState,
  stateEnum: {
    popup: RouterStatePopup,
  },
};
