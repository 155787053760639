import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { useTranslation } from 'APP/packages/translations';
import { RadioLabel } from 'UIKIT/RadioLabel/RadioLabel';

import { ICommonPopupsProps } from '../../../PopupManager.types';
import { useClosePopupOnNavigation } from '../../../hooks/useClosePopupOnNavigation';
import { useSessionsSelfDestructPopupPresenter } from './SessionsSelfDestructPopup.presenter';
import styles from './SessionsSelfDestructPopup.styles.m.css';

export const SessionsSelfDestructPopup: FC<ICommonPopupsProps> = observer((props) => {
  const { t } = useTranslation();
  const presenter = useSessionsSelfDestructPopupPresenter(props);
  useClosePopupOnNavigation(props.popupInstance);

  return (
    <Popup
      title={t('common_auto_terminate_session_setting')}
      isOpened
      handlePrevStep={presenter.hasBackButton ? presenter.onBack : null}
      onClose={presenter.onClose}
    >
      <div className={styles.form}>
        {presenter.periodValues.map((item) => (
          <RadioLabel {...item} />
        ))}
      </div>
    </Popup>
  );
});
