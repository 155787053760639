import { AgoraCallMemberPlatformEnum } from 'APP/packages/api/types/model/agoraCallMember';
import { BooleanResponse } from 'APP/packages/api/types/model/booleanResponse';
import { CallMemberV2PlatformEnum } from 'APP/packages/api/types/model/callMemberV2';
import { CategoryInfo } from 'APP/packages/api/types/model/categoryInfo';
import { CursorRequest } from 'APP/packages/api/types/model/cursorRequest';
import { DeepLinkInfoResponse } from 'APP/packages/api/types/model/deepLinkInfoResponse';
import { DeviceInfoPlatformEnum } from 'APP/packages/api/types/model/deviceInfo';
import { DeviceInfoV2PlatformEnum } from 'APP/packages/api/types/model/deviceInfoV2';
import { FileInfoResponse } from 'APP/packages/api/types/model/fileInfoResponse';
import { GraphicSize } from 'APP/packages/api/types/model/graphicSize';
import { StreamMemberPlatformEnum } from 'APP/packages/api/types/model/streamMember';
import { UserDeviceResponsePlatformEnum } from 'APP/packages/api/types/model/userDeviceResponse';
import { UserMentionResponseData } from 'APP/packages/api/types/model/userMentionResponseData';

export type IFileInfoResponse = FileInfoResponse;

export type IBooleanResponse = BooleanResponse;

export type IDeepLinkInfo = DeepLinkInfoResponse;

export type IUserMentionData = UserMentionResponseData;

export type ICategoryInfo = CategoryInfo;

export type IGraphicSize = GraphicSize;

export type ICursorRequest = CursorRequest;

export const DeviceInfoPlatform = {
  ...AgoraCallMemberPlatformEnum,
  ...CallMemberV2PlatformEnum,
  ...DeviceInfoPlatformEnum,
  ...DeviceInfoV2PlatformEnum,
  ...StreamMemberPlatformEnum,
  ...UserDeviceResponsePlatformEnum,
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type DeviceInfoPlatform =
  | AgoraCallMemberPlatformEnum
  | CallMemberV2PlatformEnum
  | DeviceInfoPlatformEnum
  | DeviceInfoV2PlatformEnum
  | StreamMemberPlatformEnum
  | UserDeviceResponsePlatformEnum;

export interface ISlowModeUpdate {
  awaitDurationMillis: string;
  awaitLeftMillis: string;
}
