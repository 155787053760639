import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { IUserProfileInitiatorSource } from 'MAIN/Profile/UserProfile/UserProfile.presenter';
import { useUserProfile } from 'MAIN/hooks/userProfile/useUserProfile';
import Entities from 'STORE';
import { User } from 'STORE/Users/User/User';
import { AvatarTypes, TAvatarBackgroundColorIndex } from 'UIKIT/Avatar/Avatar.types';
import getAvatarUrlByType from 'UTILS/getAvatarUrlByType';

interface IUserAvatarWithNavigatePresenter {
  user: User | null;
  avatarUrl: string;
  avatarTitle: string;
  avatarColorIndex: TAvatarBackgroundColorIndex;
  openUserProfile(): void;
}

export function useUserAvatarPresenter(
  userId?: string,
  initiatorSource?: IUserProfileInitiatorSource
): IUserAvatarWithNavigatePresenter {
  const userProfile = useUserProfile(userId, initiatorSource);

  const source = useAsObservableSource({
    userProfile,
    userId,
  });

  const presenter = useLocalStore<IUserAvatarWithNavigatePresenter>(() => ({
    get user(): User | null {
      return Entities.UsersStore.getUserById(source.userId);
    },

    get avatarUrl(): string {
      return presenter.user?.avatarUrl || '';
    },

    get avatarTitle(): string {
      return presenter.user?.avatarTitle || '';
    },

    get avatarColorIndex(): TAvatarBackgroundColorIndex {
      return presenter.user
        ? presenter.user.avatarColorIndex
        : (source.userId?.at(-1) as TAvatarBackgroundColorIndex);
    },

    openUserProfile(): void {
      if (presenter.user) {
        source.userProfile.show();
      }
    },
  }));

  useEffect(() => {
    if (presenter.avatarUrl) {
      const url = getAvatarUrlByType(AvatarTypes.Round_40, presenter.avatarUrl);
      Tasks.app.preloadImage(url);
    }
  }, [presenter.avatarUrl]);

  return presenter;
}
