import { observer } from 'mobx-react';
import React from 'react';

import { AudioState } from 'MAIN/OngoingCall/Shared/AudioState/AudioState';
import { HugeCallAvatar } from 'MAIN/OngoingCall/Shared/Avatars/HugeCallAvatar/HugeCallAvatar';
import Entities from 'STORE';

import { CallingPlaceholder } from '../Shared/CallingPlaceholder/CallingPlaceholder';
import { FullScreenCallViewPanel } from '../Shared/FullScreenCallViewPanel/FullScreenCallViewPanel';
import styles from './P2PAudioCall.styles.m.css';

export const P2PAudioCall = observer(() => {
  const ongoingCall = Entities.OngoingCall;
  return (
    <div className={styles.container}>
      <div className={styles.centerContent}>
        <div className={styles.avatarContainer}>
          <HugeCallAvatar avatar={ongoingCall.call.avatar} />
        </div>
        <CallingPlaceholder call={ongoingCall.call} />
        <AudioState
          opponent={ongoingCall.p2pOpponent}
          className={styles.audioState}
        />
      </div>
      <FullScreenCallViewPanel />
    </div>
  );
});
