import classNames from 'classnames';
import React, { FC, useCallback, useMemo } from 'react';

import styles from './Tab.style.m.css';

interface ITabProps {
  title: string;
  onChange(index: number): void;
  value: number;
  activeValue: number;
  className?: string;
  tabTitleClassName?: string;
  underlineClassName?: string;
}

export const Tab: FC<ITabProps> = (props) => {
  const { title, onChange, value, activeValue, className, tabTitleClassName, underlineClassName } =
    props;

  const isActive = useMemo(() => value === activeValue, [value, activeValue]);

  const classes = classNames(styles.container, { [styles.active]: isActive }, className);

  const onTabClick = useCallback(() => {
    if (!isActive) {
      onChange(value);
    }
  }, [onChange, value, isActive]);

  return (
    <button
      className={classes}
      onClick={onTabClick}
    >
      <span className={classNames(styles.title, tabTitleClassName)}>{title}</span>
      <span className={classNames(styles.underline, underlineClassName)} />
    </button>
  );
};
