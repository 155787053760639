export enum EventName {
  CallsActiveBatched = 'calls.active.batched',
  CallsActiveCallChanged = 'calls.active.call-changed',
  CallsActiveCallStarted = 'calls.active.call-started',
  StreamsActiveBatched = 'streams.active.batched',
  StreamsActiveStreamChanged = 'streams.active.stream-changed',
  GroupsCountersBatched = 'groups.counters.batched',
  GroupsCountersGroupChanged = 'groups.counters.group-changed',
  GroupsUpdatesBatched = 'groups.updates.batched',
  UsersOnlineBatched = 'users.online.batched',
  UsersOnlineUserChanged = 'users.online.user-changed',
  DevicesAdded = 'devices.added',
  DevicesDeleted = 'devices.deleted',
  NotificationsDesktopSent = 'notifications.desktop.sent',
  SpacesChangedBatched = 'spaces.changed.batched',
  SpacesUpdated = 'spaces.updated',
  SpacesCollectionUpdated = 'spaces.collection.updated',
  SlowModeUpdate = 'slowmode.updated',
}
