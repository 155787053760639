import getSymbolsChecks from './getSymbolsChecks';
import removeDublicatePositions from './removeDublicatePositions';

export default ({ text, userMentions = [], hasBotCommands = false, keyWords = [] }) => {
  const symbolsChecks = getSymbolsChecks({
    hasBotCommands,
    userMentions,
    keyWords,
  });

  const symbolsPositions = {
    '***': [],
    '**': [],
    '*': [],
    '~~': [],
    __: [],
    '@': [],
    '/': [],
    link: [],
    email: [],
    '[': [],
    ']': [],
    '```': [],
  };

  if (keyWords && keyWords.length > 0) {
    keyWords.forEach(({ keyWord }) => {
      symbolsPositions[keyWord[0]] = [];
    });
  }

  let hasSymbols = false;

  for (let i = 0; i < text.length; i++) {
    const char = text[i];

    const symbolsCheck = symbolsChecks[char];

    if (!symbolsCheck) {
      continue;
    }

    if (!hasSymbols) {
      hasSymbols = true;
    }

    symbolsCheck(text, i, symbolsPositions);
  }

  if (!hasSymbols) {
    return {
      hasSymbols,
      symbolsPositions,
    };
  }

  return {
    symbolsPositions: removeDublicatePositions({ symbolsPositions }),
    hasSymbols,
  };
};
