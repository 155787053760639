import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Entities from 'APP/store';

export default () => {
  const presenter = useLocalStore(() => ({
    progress: 0,
    isShow: false,
    intervalId: null,

    startProgress: () => {
      presenter.isShow = true;
      presenter.intervalId = setInterval(presenter.incrementProgress, 100);
    },

    incrementProgress: () => {
      presenter.progress = presenter.progress + (100 - presenter.progress) / 8;
    },

    clearInterval: () => {
      if (presenter.intervalId) {
        clearInterval(presenter.intervalId);
        presenter.intervalId = null;
      }
    },

    stopProgress: () => {
      presenter.clearInterval();

      if (presenter.isShow) {
        presenter.progress = 100;

        setTimeout(() => {
          presenter.isShow = false;
          presenter.progress = 0;
        }, 300);
      }
    },

    handleChangeIsLoading: () => {
      if (Entities.GroupsStore.activeGroup?.messagesStore.isLoading) {
        presenter.startProgress();
      } else {
        presenter.stopProgress();
      }
    },
  }));

  useEffect(() => {
    presenter.handleChangeIsLoading();
  }, [Entities.GroupsStore.activeGroup?.messagesStore.isLoading]);

  useEffect(() => {
    return () => presenter.clearInterval();
  }, []);

  return presenter;
};
