import { action, observable, reaction, computed } from 'mobx';

class PresentVideoMode {
  @observable activeUid = null;
  @observable isGridLayout = true;
  @observable _isShowOpponents = false;

  constructor(parent) {
    this.parent = parent;
    reaction(
      () => (this.parent.hasCall ? this.parent.call.opponents.screenSharingOpponent : null),
      this.setActiveOpponent
    );
  }

  @computed
  get opponents() {
    return this.parent.call.opponents.opponentList;
  }

  @computed
  get opponentsWithVideo() {
    return this.parent.call.opponents.opponentsWithVideo.sort(
      (opponentA, opponentB) => opponentB.sortIndex - opponentA.sortIndex
    );
  }

  @computed
  get isShowOpponents() {
    return this._isShowOpponents && this.hasPreviewOpponents;
  }

  @computed
  get activeVideoOpponent() {
    const opponent = this.opponents.find(({ uid }) => uid === this.parent.call.activeOpponentUid);
    return (
      opponent ||
      this.parent.call.opponents.screenSharingOpponent ||
      this.opponentsWithVideo[0] ||
      this.opponents[0]
    );
  }

  @computed
  get previewOpponents() {
    return this.parent.call.opponents.opponentList.filter(
      (opponent) => opponent !== this.activeVideoOpponent
    );
  }

  @computed
  get hasPreviewOpponents() {
    return this.previewOpponents.length > 0;
  }

  @action
  setActiveOpponent = (opponent) => {
    if (opponent) {
      const activeOpponent = this.activeVideoOpponent;
      const sortIndex = activeOpponent.sortIndex;
      activeOpponent.setSortIndex(opponent.sortIndex);
      opponent.setSortIndex(sortIndex);
      this.parent.call.activeOpponentUid = opponent.uid;
    }
  };

  @action
  toggleGridLayout = (opponent) => {
    this.isGridLayout = !this.isGridLayout;
    if (this.isGridLayout) {
      this.parent.call.activeOpponentUid = null;
    } else {
      this.parent.call.activeOpponentUid =
        opponent?.uid ||
        this.parent.call.opponents.screenSharingOpponent?.uid ||
        this.opponentsWithVideo[0]?.uid ||
        this.opponents[0]?.uid;
    }
  };

  @action
  toggleShowOpponents = () => {
    this._isShowOpponents = !this._isShowOpponents;
  };
}

export default PresentVideoMode;
