import { useCommon } from './SelectContacts.common';
import { useExtendedUsers } from './SelectContacts.extendedUsers';
import { useMyContacts } from './SelectContacts.myContacts';

export const useSelectContactsPresenter = ({
  selectedContactsIds,
  setSelectedIds,
  setSearchValue,
  contacts,
  searchContacts,
  isLoading,
  groupId,
  targetGroupType,
}) => {
  const myContacts = useMyContacts({
    setSearchValue,
    contacts,
    searchContacts,
    isLoading,
  });

  const extendedUsers = useExtendedUsers({ myContacts, groupId });

  const common = useCommon({
    myContacts,
    extendedUsers,
    setSelectedIds,
    selectedContactsIds,
    targetGroupType,
  });

  return { common, myContacts, extendedUsers };
};
