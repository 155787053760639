import { observer } from 'mobx-react';
import React, { FC } from 'react';

import SelectContacts from 'APP/components/SelectContacts';
import { GroupType } from 'APP/model/group/groupModel.types';
import { useTranslation } from 'APP/packages/translations';
import { Button } from 'UIKIT/Button/Button';
import { PopupFooter } from 'UIKIT/PopupFooter/PopupFooter';

import { useGroupFormMembersPresenter } from './GroupFormMembers.presenter';

interface IGroupFormMembersProps {
  membersIds: string[];
  targetGroupType: GroupType;
  onSetMembersIds(membersIds: string[]): void;
  onNextStep(): void;
}

export const GroupFormMembers: FC<IGroupFormMembersProps> = observer((props) => {
  const { membersIds, onSetMembersIds, onNextStep, targetGroupType } = props;

  const { t } = useTranslation();
  const presenter = useGroupFormMembersPresenter();

  return (
    <>
      <SelectContacts
        contacts={presenter.candidates}
        selectedContactsIds={membersIds}
        setSelectedIds={onSetMembersIds}
        setSearchValue={presenter.setSearchValue}
        searchContacts={presenter.searchContacts}
        isLoading={presenter.isLoading}
        targetGroupType={targetGroupType}
      />
      <PopupFooter>
        <Button
          onClick={onNextStep}
          theme="1"
        >
          {t('group_chat_continue_button')}
        </Button>
      </PopupFooter>
    </>
  );
});
