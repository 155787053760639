import { useGoogleLogin, UseGoogleLoginOptionsImplicitFlow } from '@react-oauth/google';
import React, { FC } from 'react';

import { useTranslation } from 'APP/packages/translations';

import styles from './LinkGoogleProviderButton.styles.m.css';

interface ILinkGoogleProviderButtonProps {
  onSuccess: UseGoogleLoginOptionsImplicitFlow['onSuccess'];
  onError?: UseGoogleLoginOptionsImplicitFlow['onError'];
}

export const LinkGoogleProviderButton: FC<ILinkGoogleProviderButtonProps> = (props) => {
  const { onSuccess, onError } = props;
  const { t } = useTranslation();
  const login = useGoogleLogin({ onSuccess, onError });

  return (
    <button
      type="button"
      onClick={(): void => {
        login();
      }}
      className={styles.container}
    >
      {t('account_google_link_account')}
    </button>
  );
};
