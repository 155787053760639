import { getWidgetItems } from 'APP/model/catalog/catalogModel';
import { ICatalogItemsList } from 'APP/model/catalog/catalogModel.types';

interface ILoadCatalogWidgetData {
  cursor: string;
  widgetId: string;
}

const ITEMS_LOAD_COUNT = 20;

export const loadCatalogWidget = async (
  data: ILoadCatalogWidgetData
): Promise<ICatalogItemsList | null> => {
  try {
    return await getWidgetItems({ ...data, limit: ITEMS_LOAD_COUNT });
  } catch {
    return null;
  }
};
