import classNames from 'classnames';
import React, { FC } from 'react';
import { FormSpy } from 'react-final-form';

import { IMAGE_TYPE } from 'APP/constants';
import { Field } from 'APP/packages/form/Field/Field';
import { Form } from 'APP/packages/form/Form/Form';
import { IFormContentProps } from 'APP/packages/form/Form/Form.types';
import ImageUploader from 'APP/packages/form/ImageUploader';
import MultiLineField from 'APP/packages/form/MultiLineField';
import { TextField } from 'APP/packages/form/TextField/TextField';
import { useTranslation } from 'APP/packages/translations';
import { Button, ButtonTypes } from 'UIKIT/Button/Button';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';
import { PopupFooter } from 'UIKIT/PopupFooter/PopupFooter';

import { useSpaceMarketFormPresenter } from './SpaceMarketForm.presenter';
import styles from './SpaceMarketForm.styles.m.css';
import { ISpaceMarketFormData } from './SpaceMarketForm.types';

const FORM_ID = 'space-market-form';
const MARKET_NAME_MAX_LENGTH = 35;
const MARKET_DESCRIPTION_MAX_LENGTH = 400;

interface ISpaceMarketFormProps {
  initialValues: ISpaceMarketFormData;
  onFormStateChange(state: Partial<IFormContentProps> | null): void;
  onSubmit(marketData: ISpaceMarketFormData): Promise<void>;
}

export const SpaceMarketForm: FC<ISpaceMarketFormProps> = ({
  initialValues,
  onSubmit,
  onFormStateChange,
}) => {
  const { t } = useTranslation();
  const { marketNameValidateRef, marketLinkValidateRef } = useSpaceMarketFormPresenter();

  return (
    <Form
      initialValues={initialValues}
      subscription={{
        submitting: true,
        pristine: true,
        invalid: true,
        validating: true,
      }}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        submitting,
        pristine,
        invalid,
        validating,
      }: IFormContentProps): React.ReactElement => (
        <>
          <FormSpy
            subscription={{ submitting: true, pristine: true }}
            onChange={onFormStateChange}
          />

          <form
            id={FORM_ID}
            className={classNames(styles.form, customScrollStyles.container)}
            onSubmit={handleSubmit}
          >
            <Field
              name="avatarUrl"
              component={ImageUploader}
              imageType={IMAGE_TYPE.SQUARE}
            />

            <Field
              className={styles.textField}
              name="name"
              label={t('space_store_name_hint')}
              isNeedTrim
              component={TextField}
              maxLength={MARKET_NAME_MAX_LENGTH}
              validate={marketNameValidateRef.current}
            />

            <Field
              className={styles.textField}
              name="url"
              label={t('space_store_link_hint')}
              validatingText={t('checking_deeplink')}
              isNeedTrim
              component={TextField}
              validate={marketLinkValidateRef.current}
            />

            <Field
              isNeedTrim
              name="description"
              label={t('space_store_description_hint')}
              component={MultiLineField}
              maxLength={MARKET_DESCRIPTION_MAX_LENGTH}
            />
          </form>

          <PopupFooter>
            <Button
              type={ButtonTypes.Submit}
              form={FORM_ID}
              isLoading={submitting}
              isDisabled={pristine || invalid || validating}
              theme="1"
            >
              {t('add_button')}
            </Button>
          </PopupFooter>
        </>
      )}
    </Form>
  );
};
