/* global IS_PRODUCTION */
import { localStorageInstance } from '../instances/localStorageInstance';

const getLegacyKeyName = (userId) => {
  return `user-data-${userId}-is-production-${IS_PRODUCTION}`;
};

const getKeyName = (userId) => {
  return `is-push-notifications-enabled-for-${userId}`;
};

const set = (userId, isPushNotificationsEnabled) => {
  return localStorageInstance.setItem(getKeyName(userId), isPushNotificationsEnabled);
};

const get = (userId) => {
  migrateFromLegacyKeyName(userId);

  const isPushNotificationsEnabled = localStorageInstance.getItem(getKeyName(userId));

  return isPushNotificationsEnabled === null || isPushNotificationsEnabled === 'true';
};

const migrateFromLegacyKeyName = (userId) => {
  const keyName = getLegacyKeyName(userId);

  const isPushNotificationsEnabled = localStorageInstance.getItem(keyName);

  if (isPushNotificationsEnabled !== null) {
    set(userId, isPushNotificationsEnabled);

    localStorageInstance.removeItem(keyName);
  }
};

const remove = (userId) => {
  return localStorageInstance.removeItem(getKeyName(userId));
};

export default {
  set,
  get,
  remove,
};
