import classNames from 'classnames';
import React, { ChangeEvent, FC, useCallback, useEffect, useRef } from 'react';

import { IconButton } from 'APP/packages/UiKit/IconButton';
import { AddKeyPressHandler } from 'APP/packages/keyBoardHandler';
import CloseIcon from 'ICONS/ic-close.svg';
import UnionIcon from 'ICONS/union.svg';
import { Button } from 'UIKIT/Button/Button';
import { OverlayingPopup } from 'UIKIT/OverlayingPopup/OverlayingPopup';

import styles from './Dialog.styles.m.css';

interface IDialogProps {
  title?: string;
  text?: string;
  primaryText: string;
  secondaryText?: string;
  isOpened?: boolean;
  checkBoxText?: string;
  isSelected?: boolean;
  isLoading?: boolean;
  hasCloseButton?: boolean;
  className?: string;
  html?: string;
  primaryOnClick(): void;
  secondaryOnClick?(): void;
  onClose(): void;
  checkBoxChange?(event: ChangeEvent<HTMLInputElement>): void;
}

export const Dialog: FC<IDialogProps> = ({
  title,
  text,
  primaryText,
  primaryOnClick,
  secondaryText,
  secondaryOnClick,
  onClose,
  isOpened = true,
  checkBoxText,
  checkBoxChange,
  isSelected,
  isLoading = false,
  hasCloseButton = false,
  className,
  html,
}) => {
  const primaryButtonRef = useRef<HTMLButtonElement>(null);
  const secondaryButtonRef = useRef<HTMLButtonElement>(null);
  const containerClass = classNames(styles.dialogContainer, {
    [styles.hasCloseButton]: hasCloseButton,
  });

  const checkboxClass = classNames(styles.checkboxView, {
    [styles.selected]: isSelected,
  });

  const focusSecondaryBtn = useCallback(() => {
    secondaryButtonRef.current?.focus();
  }, []);

  const focusPrimaryBtn = useCallback(() => {
    primaryButtonRef.current?.focus();
  }, []);

  useEffect(() => {
    primaryButtonRef.current?.focus();
  }, []);

  return (
    <OverlayingPopup
      isOpened={isOpened}
      onClose={onClose}
      className={className}
      popupClassName={containerClass}
      isIgnoreClickOutside
    >
      <AddKeyPressHandler
        onEsc={onClose}
        onArrowLeft={focusSecondaryBtn}
        onArrowRight={focusPrimaryBtn}
      />
      {hasCloseButton && (
        <IconButton
          className={styles.closeButton}
          Icon={CloseIcon}
          onClick={onClose}
        />
      )}

      {title && <div className={styles.title}>{title}</div>}
      {text && (
        <div
          className={styles.text}
          data-test="dialog-text"
        >
          {text}
        </div>
      )}
      {html && (
        <div
          className={styles.text}
          data-test="dialog-text"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      )}
      {checkBoxText && checkBoxChange && (
        <label className={styles.checkbox}>
          <div className={checkboxClass}>
            <input
              className={styles.checkboxInput}
              type="checkbox"
              onChange={checkBoxChange}
              disabled={isLoading}
            />
            {isSelected && <UnionIcon />}
          </div>
          <span className={styles.checkboxText}>{checkBoxText}</span>
        </label>
      )}
      <div className={styles.actionPanel}>
        {secondaryText && (
          <Button
            setRef={secondaryButtonRef}
            className={styles.secondaryButton}
            onClick={secondaryOnClick}
            isDisabled={isLoading}
            dataTest="secondary-button"
            theme="6"
          >
            {secondaryText}
          </Button>
        )}
        <Button
          setRef={primaryButtonRef}
          onClick={primaryOnClick}
          className={styles.button}
          isLoading={isLoading}
          dataTest="primary-button"
          theme="1"
        >
          {primaryText}
        </Button>
      </div>
    </OverlayingPopup>
  );
};
