import dateService from 'APP/packages/date';
import Entities from 'APP/store';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

import { createMessageData } from './createMessageData';

export const convertMessageToChatMessage = ({ messages }) => {
  if (messages.length === 0) {
    return [];
  }

  const clientTime = dateService.now().getTime();

  const fakeMessages = messages.map((message, index) =>
    createMessageData({ message, clientTime, index })
  );

  return fakeMessages.map((fakeMessage) => {
    const groupStore = Entities.GroupsStore.getGroupById(fakeMessage.groupId);

    return new ChatMessage(fakeMessage, groupStore.messagesStore);
  });
};
