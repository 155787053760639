import classNames from 'classnames';
import React, { FC } from 'react';

import styles from './NewMessagesBadge.styles.m.css';

interface INewMessagesBadgeProps {
  className?: string;
}

export const NewMessagesBadge: FC<INewMessagesBadgeProps> = ({ className }) => {
  return <div className={classNames(styles.container, className)} />;
};
