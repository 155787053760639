import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { Field } from '../Field/Field';
import { FormRadioButton } from '../RadioButton/FormRadioButton';
import styles from './RadioButtonGroup.styles.m.css';

export const RadioButtonGroup = observer(({ className, name, options }) => {
  return (
    <fieldset className={classNames(styles.container, className)}>
      {options.map((option) => {
        return (
          <Field
            key={option.value}
            type="radio"
            name={name}
            value={option.value}
            label={option.label}
            component={FormRadioButton}
          />
        );
      })}
    </fieldset>
  );
});
