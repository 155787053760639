import React, { useLayoutEffect } from 'react';

import Entities from 'STORE';

export function withPopupLayer(Component) {
  return (props) => {
    useLayoutEffect(() => {
      Entities.App.addLayer();
      return () => {
        Entities.App.removeLayer();
      };
    }, []);

    return <Component {...props} />;
  };
}
