import { PureComponent } from 'react';

import { CLICK_OUTSIDE_IGNORE_ATTR_NAME } from './constants';

interface IClickOutsideProps {
  reference?: HTMLElement | null;
  target?: HTMLElement | null;
  onClickOutside?(e: MouseEvent): void;
}

// TODO: Rewrite on Functional Component
// TODO: If onClickOutside is undefined, we should no listen click events. Or we should add disabled prop
export class ClickOutside extends PureComponent<IClickOutsideProps> {
  static isClickedOutside(
    clickedElement: HTMLElement,
    referenceElement: HTMLElement,
    targetElement: HTMLElement
  ) {
    let clickedEl = clickedElement;

    while (clickedEl) {
      if (
        clickedEl === referenceElement ||
        (clickedEl.getAttribute &&
          clickedEl.getAttribute(CLICK_OUTSIDE_IGNORE_ATTR_NAME) === 'true')
      ) {
        return false;
      }
      clickedEl = clickedEl.parentNode as HTMLElement;
    }
    const isTargetClick = targetElement ? targetElement.contains(clickedElement) : false;
    return !referenceElement.contains(clickedElement) && !isTargetClick;
  }

  onClick = (e: MouseEvent) => {
    if (!this.props.reference) {
      return;
    }
    const targetElement = this.props.target;
    const referenceElement = this.props.reference;
    const clickedElement = e.target as HTMLElement;
    if (
      this.props.onClickOutside &&
      ClickOutside.isClickedOutside(clickedElement, referenceElement, targetElement as HTMLElement)
    ) {
      this.props.onClickOutside(e);
    }
  };

  componentDidMount() {
    document.addEventListener('click', this.onClick);
    document.addEventListener('contextmenu', this.onClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onClick);
    document.removeEventListener('contextmenu', this.onClick);
  }

  render() {
    return this.props.children;
  }
}
