import { useLocalStore } from 'mobx-react';

import Entities from 'STORE';
import { Space } from 'STORE/Spaces/Space';

interface ISortedSpaces {
  readonly spaces: Space[];
}

interface ISpaceSortState {
  activeCallOrStream: number;
  backgroundCallTs: number;
  backgroundStreamTs: number;
  groupLastTs: number;
  pinOrder: number;
}

function getSpaceSortState(space: Space): ISpaceSortState {
  const state: ISpaceSortState = {
    activeCallOrStream: 0,
    backgroundCallTs: 0,
    backgroundStreamTs: 0,
    groupLastTs: -1, // if there is no groups in space, this space should have low priority
    pinOrder: space.pinOrder,
  };

  // when groups are loading, sorting by groups has a very bad effect on space rendering performance
  if (Entities.GroupsStore.isLoading) {
    return state;
  }

  const spaceGroups = space.subscribedGroupIds.map((groupId) =>
    Entities.GroupsStore.getGroupById(groupId)
  );

  spaceGroups.forEach((group) => {
    if (!group) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TODO: remove when Stream typings will be ready
    if (group.activeCall || Entities.ActiveStream.stream?.groupId === group.id) {
      state.activeCallOrStream = 1;
    }

    state.backgroundCallTs = Math.max(state.backgroundCallTs, group.backgroundCall?.startTs || 0);
    state.backgroundStreamTs = Math.max(
      state.backgroundStreamTs,
      Entities.BackgroundStreams.getByGroupId(group.id)?.startTs || 0
    );

    state.groupLastTs = Math.max(state.groupLastTs, group.lastMessageTs, group.seenTs);
  });

  return state;
}

export function useSortedSpaces(): ISortedSpaces {
  return useLocalStore<ISortedSpaces>(() => ({
    get spaces(): Space[] {
      const sortStates: Record<string, ISpaceSortState> = {};

      return Entities.spacesStore.spaces
        .filter((space) => !space.isGuest)
        .sort((space1: Space, space2: Space) => {
          if (!sortStates[space1.id]) {
            sortStates[space1.id] = getSpaceSortState(space1);
          }

          if (!sortStates[space2.id]) {
            sortStates[space2.id] = getSpaceSortState(space2);
          }

          const sortState1 = sortStates[space1.id];
          const sortState2 = sortStates[space2.id];

          if (sortState1.activeCallOrStream || sortState2.activeCallOrStream) {
            return sortState2.activeCallOrStream - sortState1.activeCallOrStream;
          }

          if (sortState1.backgroundCallTs || sortState2.backgroundCallTs) {
            return sortState2.backgroundCallTs - sortState1.backgroundCallTs;
          }

          if (sortState1.backgroundStreamTs || sortState2.backgroundStreamTs) {
            return sortState2.backgroundStreamTs - sortState1.backgroundStreamTs;
          }

          if (sortState1.pinOrder || sortState2.pinOrder) {
            return sortState2.pinOrder - sortState1.pinOrder;
          }

          return sortState2.groupLastTs - sortState1.groupLastTs;
        });
    },
  }));
}
