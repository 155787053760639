import Tasks from 'APP/Tasks';
import { ALERT_TYPES } from 'APP/constants/app';
import api from 'APP/packages/api';
import logger from 'APP/packages/logger';
import { storage } from 'APP/packages/storage';
import Entities from 'APP/store';
import { ISendFeedbackRequest } from 'APP/types/feedback';
import { t } from 'MODULE/i18n/service';

export const sendFeedback = async (data: ISendFeedbackRequest): Promise<void> => {
  try {
    storage.feedback.setStartSkipValue();
    await api.calls.sendFeedback(data);
    Entities.toast.show(t('feedback_sent'));
  } catch (error) {
    Tasks.app.addAlert({
      type: ALERT_TYPES.SOMETHING_WRONG_ERROR,
    });
    storage.feedback.resetSkipValue();
    storage.feedback.setDontShowValue(false);
    logger.get('API').error('calls.sendFeedback', error);
  }
};
