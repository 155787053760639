import mime from 'mime-types';

import { IDownload, IDownloadMessageFile } from 'APP/Tasks/download/download.types';
import { getOriginalSizeImage } from 'APP/packages/imageResizer';
import Entities from 'APP/store';
import { getMessagePayload } from 'UTILS/message/message';

export function download(props: IDownload): XMLHttpRequest {
  const { url, fileName: name, mimeType, onProgress, onError } = props;

  let fileName = name;
  if (!mime.lookup(fileName)) {
    const splittedFileName = fileName.split('.');
    const fileExtension =
      splittedFileName.length > 1 ? splittedFileName[splittedFileName.length - 1] : null;

    if (!fileExtension) {
      const extension = mimeType ? mime.extension(mimeType) : null;
      fileName = `${fileName}${extension ? `.${extension}` : ''}`;
    }
  }

  const xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.responseType = 'blob';
  xhr.onload = (): void => {
    if (xhr.status === 200) {
      const blob = new Blob([xhr.response]);
      const a = document.createElement('a');
      a.download = fileName;
      document.body.appendChild(a);
      a.href = window.URL.createObjectURL(blob);
      a.click();
      document.body.removeChild(a);
    }
  };

  if (onProgress) {
    xhr.onprogress = onProgress;
  }

  if (onError) {
    xhr.onerror = onError;
  }

  xhr.send();
  return xhr;
}

export const downloadMessageFile = ({
  messageId,
  payloadId,
  groupId,
  startDownloadTs,
}: IDownloadMessageFile): void => {
  const group = Entities.GroupsStore.getGroupById(groupId);

  if (!group) {
    return;
  }

  const messagesStore = group.messagesStore;
  const downloadProgress = messagesStore.downloadProgressPerPayload.get(payloadId);
  const isDownloadProcessAlreadyStarted = Boolean(downloadProgress);
  const loaded = downloadProgress?.progressEvent?.loaded || 0;
  const total = downloadProgress?.progressEvent?.total || 0;
  const message = messagesStore.getMessageById(messageId || '');

  const payload = message ? getMessagePayload(message, payloadId) : undefined;

  const { url, userFileBlobUrl, fileName, mimeType } = payload || {};

  if (
    (!url && !userFileBlobUrl) ||
    !mimeType ||
    (isDownloadProcessAlreadyStarted && !downloadProgress?.hasError && loaded < total)
  ) {
    return;
  }

  const onProgress = (event: ProgressEvent): void => {
    messagesStore.downloadProgressPerPayload.setProgressEvent(payloadId, event);
  };

  const onError = (): void => {
    messagesStore.downloadProgressPerPayload.update(payloadId, { hasError: true });
  };

  const xhr = download({
    url: userFileBlobUrl || getOriginalSizeImage(url),
    fileName,
    mimeType,
    onProgress,
    onError,
  });

  messagesStore.downloadProgressPerPayload.update(payloadId, {
    xhr,
    hasError: false,
    messageId,
    groupId,
    payloadId,
    startDownloadTs: startDownloadTs || Date.now(),
    completeDownloadTs: 0,
  });
};

export const removeActiveMessageDownloads = (messageId: string, groupId: string): void => {
  const group = Entities.GroupsStore.getGroupById(groupId);

  if (group) {
    const messagesStore = group.messagesStore;
    const downloads = messagesStore.downloadProgressPerPayload.getDownloadsByMessageId(messageId);

    downloads.forEach((downloadProgress) => {
      if (downloadProgress) {
        downloadProgress.xhr?.abort();
        messagesStore.downloadProgressPerPayload.remove(messageId);
      }
    });
  }
};
