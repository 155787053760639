const HelperText = ({ hasError, errorText, helperText }) => {
  if (hasError) {
    return errorText;
  }

  if (helperText) {
    return helperText;
  }

  return null;
};

export default HelperText;
