import { MouseEventHandler, useCallback, useMemo } from 'react';

import Tasks from 'APP/Tasks';
import { useTranslation } from 'APP/packages/translations';
import { RouterLink } from 'APP/router/constants';
import Entities from 'STORE';
import { getHost, getHref, getPathname, matchPath } from 'UTILS/url';

interface IHiddenLinkRendererPresenter {
  href: string;
  pathname: string;
  isAppPath: boolean;
  isWhitelistUrl: boolean;
  onClickHandler: MouseEventHandler<HTMLAnchorElement>;
}

export const useHiddenLinkRendererPresenter = (link: string): IHiddenLinkRendererPresenter => {
  const { t } = useTranslation();

  const href = getHref(link);
  const pathname = getPathname(link);
  const isAppPath = matchPath(RouterLink, pathname);

  const isWhitelistUrl: boolean = useMemo(() => {
    const host = getHost(link);
    return Entities.App.whitelistHosts.some((whitelistHost) => host === whitelistHost);
  }, [link]);

  const onClickHandler: MouseEventHandler<HTMLAnchorElement> = useCallback(
    async (e) => {
      e.preventDefault();

      const confirmation = await Tasks.app.showConfirm({
        text: t('common_open_link_alert', { 0: link, interpolation: { escapeValue: false } }),
        primaryText: t('common_open_action'),
        secondaryText: t('common_cancel'),
      });

      if (confirmation) {
        window.open(href, '_blank');
      }
    },
    [link]
  );

  return { isWhitelistUrl, onClickHandler, href, pathname, isAppPath };
};
