import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { useTranslation } from 'APP/packages/translations';
import { useCloseFormPopup } from 'MAIN/PopupManager/hooks/useCloseFormPopup';
import { Popup } from 'STORE/App/Popups/Popup/Popup';

export function useCloseMediaMessageOnNavigation(popupInstance: Popup, groupId: string): void {
  const { t } = useTranslation();
  const closeForm = useCloseFormPopup(
    { popupInstance },
    t('cancel_channel_creation_alert'),
    (isClosed) => {
      if (!isClosed) {
        Tasks.group.setActiveGroup({ groupId });
      }
    }
  );

  useEffect(() => {
    closeForm.onFormStateChange({ pristine: false });
  }, []);
}
