import { UpdateReason } from 'APP/constants/scroll';
import Entities from 'APP/store';

export default async () => {
  const group = Entities.GroupsStore.activeGroup;
  if (!group || !group.counter?.lastMessage) {
    return;
  }

  Entities.ChatStore.setScrollChanged(UpdateReason.ScrollToBottom, {
    isAnimated: true,
  });
};
