import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { GroupAvatar } from 'APP/components/Avatar/GroupAvatar/GroupAvatar';
import Date from 'APP/components/Date';
import { VerifiedBadge } from 'APP/components/VerifiedBadge/VerifiedBadge';
import AddIcon from 'ICONS/ic-ad.svg';
import MoreIcon from 'ICONS/ic-more-vertical.svg';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';
import { TPostInstance } from 'STORE/Newsfeed/Newsfeed.types';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';
import { IconButton } from 'UIKIT/IconButton';
import { usePopover } from 'UIKIT/Popover/usePopover';
import { IPopoverMenuItem } from 'UIKIT/PopoverMenu/PopoverMenu.types';

import { PostContextMenu } from '../PostContextMenu/PostContextMenu';
import { usePostHeaderPresenter } from './PostHeader.presenter';
import styles from './PostHeader.styles.m.css';

interface IPostHeaderProps {
  newsItem: TPostInstance;
  getMenuItems?(message: ChatMessage, newsItem: TPostInstance): IPopoverMenuItem[];
}

export const PostHeader: FC<IPostHeaderProps> = observer(({ newsItem, getMenuItems }) => {
  const presenter = usePostHeaderPresenter(newsItem);
  const popover = usePopover<HTMLButtonElement>();

  return (
    <>
      {presenter.isShowTitle && (
        <div className={styles.title}>
          {presenter.title}
          <hr className={styles.row} />
        </div>
      )}

      <div className={styles.container}>
        <button
          className={styles.head}
          onClick={presenter.onClick}
        >
          <GroupAvatar
            group={presenter.message.group}
            type={presenter.message.group?.isChannel ? AvatarTypes.Square_10 : AvatarTypes.Round_5}
            hasLockIcon={presenter.isShowLockIcon}
          />

          <div className={styles.info}>
            <div className={styles.titleWrapper}>
              <h3 className={styles.group}>{presenter.message.group?.avatarTitle}</h3>
              {presenter.message.group?.verified && <VerifiedBadge />}
            </div>
            <Date
              className={styles.time}
              date={presenter.message.serverTime}
            />
          </div>
        </button>

        <div className={styles.actions}>
          {presenter.canBeSubscribed && (
            <IconButton
              Icon={AddIcon}
              theme="19"
              onClick={presenter.onSubscribe}
            />
          )}
          {getMenuItems && (
            <>
              <IconButton
                setRef={popover.containerRef}
                Icon={MoreIcon}
                isActive={popover.isOpened}
                theme="19"
                onClick={popover.toggle}
              />

              <PostContextMenu
                newsItem={newsItem}
                popover={popover}
                placement="bottom-end"
                getMenuItems={getMenuItems}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
});
