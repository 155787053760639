import { useAsObservableSource, useLocalStore } from 'mobx-react';

import Entities from 'APP/store';
import { useSystemMessageText } from 'MAIN/hooks/systemMessageText/useSystemMessageText';
import { splitByParams } from 'UTILS/i18n/splitByParams';

export const RENDER_TYPES = {
  TEXT: 'TEXT',
  USER: 'USER',
  USERS: 'USERS',
};

export const useSystemPresenter = (message) => {
  const {
    html: systemMessageText,
    sourceText = '',
    params = {},
  } = useSystemMessageText(message.group, message.payload);

  const source = useAsObservableSource({ message });

  const presenter = useLocalStore(() => ({
    get message() {
      return source.message;
    },

    get isLeftAligned() {
      return Entities.ChatStore.isOnlyLeftSideMessages;
    },

    get renderItems() {
      if (sourceText && Object.keys(params).length > 0) {
        const chunks = splitByParams(sourceText);
        return chunks.map((chunk) => {
          if (params[chunk] && params[chunk].userId) {
            return {
              type: RENDER_TYPES.USER,
              text: params[chunk].text,
              userId: params[chunk].userId,
            };
          }

          if (params[chunk] && params[chunk].usersIds) {
            return {
              type: RENDER_TYPES.USERS,
              text: params[chunk].text,
              usersIds: params[chunk].usersIds,
            };
          }

          if (params[chunk] && params[chunk].text) {
            return {
              type: RENDER_TYPES.TEXT,
              text: params[chunk].text,
            };
          }
          return {
            type: RENDER_TYPES.TEXT,
            text: chunk,
          };
        });
      }

      return null;
    },
  }));

  return { presenter, systemMessageText };
};
