import classNames from 'classnames';
import React, { ElementType, FC, memo } from 'react';

import { ApplicationTheme } from 'APP/constants/appearance';
import { IconViewSizes } from 'UIKIT/IconView/IconView.types';

import styles from './IconView.styles.m.scss';

export { IconViewSizes };

interface IIconViewProps {
  Icon: ElementType;
  className?: string;
  size?: IconViewSizes;
  theme?: ApplicationTheme;
  dataTest?: string;
}

export const IconView: FC<IIconViewProps> = memo((props) => {
  const { Icon, className, size = IconViewSizes.Medium, theme, dataTest } = props;
  if (!Icon) {
    return null;
  }

  const classes = classNames(styles.icon, styles[size], styles[`theme-${theme}`], className);

  return (
    <Icon
      className={classes}
      data-test={dataTest}
    />
  );
});
