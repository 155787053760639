import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { RecommendationType } from 'APP/model/catalog/catalogModel.types';
import { spaceAnalytics } from 'APP/packages/analytics/analytics';
import { useTranslation } from 'APP/packages/translations';
import { useSortedSpaces } from 'MAIN/hooks/space/useSortedSpaces';
import Entities from 'STORE';
import { Space } from 'STORE/Spaces/Space';

interface ISpaceListDelimiter {
  isDelimiter: boolean;
}

type ISpaceListItem = { space: Space; isRecommended: boolean } | ISpaceListDelimiter;

export interface ISpacesListPresenterParams {
  onShowSpace(spaceId: string): void;
}

export function isDelimiterItem(item: ISpaceListItem): item is ISpaceListDelimiter {
  return typeof (item as ISpaceListDelimiter)?.isDelimiter === 'boolean';
}

export interface ISpacesListPresenter {
  isShowPlaceholder: boolean;
  isShowEmptyInfo: boolean;
  listItems: ISpaceListItem[];
  itemsCount: number;
  recommendedLoading: boolean;
  recommendedSpaces: Space[];
  recommendedTitle: string;
  loadRecommendedSpaces(): Promise<void>;
  onItemClick(listItem: ISpaceListItem): void;
}

export function useSpacesListPresenter({
  onShowSpace,
}: ISpacesListPresenterParams): ISpacesListPresenter {
  const sortedSpaces = useSortedSpaces();
  const { t } = useTranslation();
  const source = useAsObservableSource({ onShowSpace });

  const presenter = useLocalStore<ISpacesListPresenter>(() => ({
    recommendedLoading: false,

    get isShowPlaceholder(): boolean {
      return Entities.spacesStore.isLoading || presenter.recommendedLoading;
    },

    get isShowEmptyInfo(): boolean {
      return (
        Entities.spacesStore.isEmpty &&
        !presenter.recommendedSpaces.length &&
        !Entities.spacesStore.isLoading &&
        !presenter.recommendedLoading
      );
    },

    get listItems(): ISpaceListItem[] {
      const spaces: ISpaceListItem[] = sortedSpaces.spaces.map((space) => ({
        space,
        isRecommended: false,
      }));

      const recommended: ISpaceListItem[] = presenter.recommendedSpaces.map((space) => ({
        space,
        isRecommended: true,
      }));

      return recommended.length ? spaces.concat([{ isDelimiter: true }], recommended) : spaces;
    },

    get itemsCount(): number {
      return presenter.listItems.length;
    },

    get recommendedTitle(): string {
      return Entities.spacesStore.recommendations?.type === RecommendationType.MostPopular
        ? t('channel_catalog_most_popular')
        : t('channel_catalog_suggested');
    },

    get recommendedSpaces(): Space[] {
      return Entities.spacesStore.recommendations.spaces;
    },

    async loadRecommendedSpaces(): Promise<void> {
      presenter.recommendedLoading = true;
      await Tasks.space.loadRecommendedSpaces();
      presenter.recommendedLoading = false;
    },

    onItemClick(listItem: ISpaceListItem): void {
      if (isDelimiterItem(listItem)) {
        return;
      }

      source.onShowSpace(listItem.space.id);

      if (listItem.isRecommended) {
        Entities.spacesStore.recommendations?.type === RecommendationType.MostPopular
          ? spaceAnalytics.trackSpacePopularClicked(listItem.space.id)
          : spaceAnalytics.trackSpaceSuggestedClicked(listItem.space.id);
      }
    },
  }));

  useEffect(() => {
    presenter.loadRecommendedSpaces();
  }, []);

  return presenter;
}
