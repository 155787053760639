import classNames from 'classnames';
import React, { FC, ReactElement } from 'react';

import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';

import styles from './ShareDataViewListContainer.styles.m.css';

interface IShareDataViewListContainerProps {
  children: ReactElement;
}

export const ShareDataViewListContainer: FC<IShareDataViewListContainerProps> = ({ children }) => {
  return (
    <div className={classNames(styles.listContainer, customScrollStyles.container)}>{children}</div>
  );
};
