import React, { ElementType, FC, memo, MouseEventHandler } from 'react';
import { NavLink } from 'react-router-dom';

import { IconView, IconViewSizes } from 'UIKIT/IconView/IconView';

import styles from './SidebarMenuItem.styles.m.css';

interface ISidebarMenuItemProps {
  title: string;
  link: string;
  exact?: boolean;
  Icon: ElementType;
  onClick?: MouseEventHandler<HTMLElement>;
}

export const SidebarMenuItem: FC<ISidebarMenuItemProps> = memo(
  ({ title, link, exact, Icon, onClick }) => {
    return (
      <NavLink
        className={styles.container}
        activeClassName={styles.active}
        to={link}
        exact={exact}
        onClick={onClick}
      >
        <span className={styles.icon}>
          <IconView
            Icon={Icon}
            size={IconViewSizes.Small}
          />
        </span>
        <span className={styles.title}>{title}</span>
      </NavLink>
    );
  }
);
