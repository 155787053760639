import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import { openPopup } from 'APP/Tasks/app/app';
import { useTranslation } from 'APP/packages/translations';
import { Popup } from 'APP/store/App/Popups/Popup/Popup';
import { Group } from 'APP/store/Groups/Group';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import Entities from 'STORE';

import { AddParticipantsPopup } from '../AddParticipantsPopup/AddParticipantsPopup';

export interface IParticipantsPopupPresenter {
  searchText: string;
  group: Group | null;
  hasBackButton: boolean;
  childPopup: Popup | null;
  title: string;

  onAddParticipantClick(onSuccess: () => void): void;
  onSearch(searchText: string): void;
  onClose(): void;
  onBack(): void;
}

export interface IParticipantsPopupParams {
  groupId: string;
}

export const useParticipantsPopupPresenter = ({
  params,
  onClose,
  onBack,
  popupInstance,
}: ICommonPopupsProps<IParticipantsPopupParams>): IParticipantsPopupPresenter => {
  const { t } = useTranslation();

  const { groupId } = params!;

  const presenter = useLocalStore<IParticipantsPopupPresenter>(() => ({
    searchText: '',
    childPopup: null,

    get group(): Group | null {
      return groupId ? Entities.GroupsStore.getGroupById(groupId) : null;
    },

    get hasBackButton(): boolean {
      return !!onBack;
    },

    get title(): string {
      return presenter.group?.isChannel
        ? t('common_subscribers')
        : t('chat_details_group_members_label');
    },

    onAddParticipantClick(onSuccess: () => void): void {
      if (!presenter.group) {
        return;
      }

      popupInstance.hide();

      presenter.childPopup = openPopup(AddParticipantsPopup, {
        params: {
          groupId: presenter.group.id,
          onSuccess,
        },
        onClose: () => {
          presenter.onClose();
        },
        onBack: () => {
          popupInstance.show();
        },
      });
    },

    onSearch(searchText: string): void {
      presenter.searchText = searchText;
    },

    onClose(): void {
      popupInstance.close();
      onClose?.();
    },

    onBack(): void {
      popupInstance.close();
      onBack?.();
    },
  }));

  useEffect(() => {
    return () => presenter.childPopup?.close();
  }, []);

  return presenter;
};
