import classNames from 'classnames';
import React from 'react';

import styles from 'UIKIT/Button/Button.styles.m.css';

const ExternalLink = ({
  className,
  href,
  size = 'medium',
  rounding = '0', //0,1,2,
  theme = '1',
  target = '_blank',
  children,
}) => {
  const classList = classNames(
    styles.container,
    styles[size],
    styles[`rounding-${rounding}`],
    styles[`theme-${theme}`],
    className
  );

  return (
    <a
      className={classList}
      href={href}
      target={target}
    >
      {children}
    </a>
  );
};

export default ExternalLink;
