import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { ProviderUid } from 'APP/packages/callProviders/callProviders.types';
import { useOpponentVideoItem } from 'MAIN/OngoingCall/hooks/useOpponentVideoItem/useOpponentVideoItem';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';

import { OpponentVideo } from '../../../OpponentVideo/OpponentVideo';
import styles from './OpponentVideoItem.styles.m.css';

interface IOpponentVideoItemProps {
  avatarType: AvatarTypes;
  opponentUid: ProviderUid;
}

export const OpponentVideoItem: FC<IOpponentVideoItemProps> = observer(
  ({ avatarType, opponentUid }) => {
    const { opponent, opponentPosition, onOpponentClick } = useOpponentVideoItem(opponentUid, true);

    if (!opponent) {
      return null;
    }

    return (
      <OpponentVideo
        isHighlightAudio
        opponent={opponent}
        opponentPosition={opponentPosition}
        containerClassName={styles.videoContainer}
        avatarType={avatarType}
        className={styles.video}
        onClick={onOpponentClick}
      />
    );
  }
);
