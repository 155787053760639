import { useLocalStore } from 'mobx-react';

import Entities from 'APP/store';

export default () => {
  const presenter = useLocalStore(() => ({
    get group() {
      return Entities.GroupsStore.activeGroup;
    },

    get inputPanel() {
      return Entities.InputPanelsStore.activeInputPanel;
    },

    get commands() {
      if (!presenter.inputPanel) {
        return [];
      }

      return presenter.inputPanel.bots.commands;
    },

    get bot() {
      if (!presenter.inputPanel) {
        return null;
      }

      return presenter.inputPanel.bots.bot;
    },

    get isOpened() {
      if (!presenter.inputPanel) {
        return false;
      }

      return presenter.inputPanel.bots.isOpenedCommandList;
    },
  }));

  return presenter;
};
