import { observer } from 'mobx-react';
import React, { useRef } from 'react';

import FileUploadButton from '../FileUploadButton';
import Menu from '../Menu';
import { TextPayload } from '../Payloads/TextPayload/TextPayload';
import { usePayloadCreatorPresenter } from './PayloadCreator.presenter';
import styles from './PayloadCreator.styles.m.css';

export const PayloadCreator = observer(({ parentPresenter }) => {
  const fileUploadRef = useRef();
  const { uploadFilesPresenter, presenter } = usePayloadCreatorPresenter({
    parentPresenter,
  });

  return (
    <div className={styles.container}>
      <FileUploadButton
        ref={fileUploadRef}
        uploadFiles={uploadFilesPresenter.uploadFiles}
      />
      <div className={styles.menu}>
        <Menu
          payload={presenter.payload}
          parentPresenter={presenter}
          fileUploadRef={fileUploadRef}
          isPayloadCreator
        />
      </div>
      <div className={styles.payload}>
        <TextPayload
          parentPresenter={presenter}
          payload={presenter.payload}
          order={null}
          placeholder={presenter.placeholder}
          isPayloadCreator
        />
      </div>
    </div>
  );
});
