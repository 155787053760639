import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { IconView } from 'APP/packages/UiKit/IconView/IconView';
import { useTranslation } from 'APP/packages/translations';
import TranslateIcon from 'ICONS/ic-translate-16.svg';

import styles from './styles.m.scss';

const Translate = ({ message, className }) => {
  const { t } = useTranslation();
  if (!message.hasTranslation) {
    return null;
  }

  let label = null;
  if (message.isLoadingTranslate) {
    label = t('common_loading');
  } else {
    if (message.isShowTranslate) {
      label = t('translated_label');
    }
  }

  return (
    <div className={classNames(styles.translate, className)}>
      <IconView
        Icon={TranslateIcon}
        className={styles.icon}
        size={'small'}
      />
      {label ? <span className={styles.label}>{label}</span> : null}
      {!message.isLoadingTranslate && (
        <div
          role="button"
          tabIndex={0}
          onClick={message.toggleTranslate}
          className={styles.toggle}
        >
          {message.isShowTranslate ? t('show_original_button') : t('show_translation_button')}
        </div>
      )}
    </div>
  );
};

export default observer(Translate);
