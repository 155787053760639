import { buildModelError } from 'APP/model/error/errorBuilder';
import api from 'APP/packages/api';
import logger from 'APP/packages/logger';

import {
  mapUpdateUserProfileRequest,
  mapAcquireRewardRequest,
  mapAckRewardsRequest,
  mapSetPrivacySettingsRequest,
  mapPrivacySettingsResponse,
  mapSelfUserDisplayDataResponse,
} from './userSettingsModel.mapper';
import {
  ISelfUserDisplayData,
  RewardProgressInfoRewardEnum,
  IPrivacySettings,
  IUpdateUserDisplayData,
} from './userSettingsModel.types';

export async function updateUserProfile(
  data: IUpdateUserDisplayData
): Promise<ISelfUserDisplayData> {
  try {
    const result = await api.userSettings.updateUserProfile(mapUpdateUserProfileRequest(data));
    return mapSelfUserDisplayDataResponse(result);
  } catch (error) {
    logger.get('API').error('userSettings.updateUserProfile', error);
    throw buildModelError(error);
  }
}

export async function setPrivacySettings(data: IPrivacySettings): Promise<IPrivacySettings> {
  try {
    const result = await api.userSettings.setPrivacySettings(mapSetPrivacySettingsRequest(data));
    return mapPrivacySettingsResponse(result);
  } catch (error) {
    logger.get('API').error('userSettings.setPrivacySettings', error);
    throw buildModelError(error);
  }
}

export async function acquireReward(reward: RewardProgressInfoRewardEnum): Promise<void> {
  try {
    await api.userSettings.acquireReward(mapAcquireRewardRequest(reward));
  } catch (error) {
    logger.get('API').error('userSettings.acquireReward', error);
    throw buildModelError(error);
  }
}

export async function ackRewards(rewards: RewardProgressInfoRewardEnum[]): Promise<void> {
  try {
    await api.userSettings.ackRewards(mapAckRewardsRequest(rewards));
  } catch (error) {
    logger.get('API').error('userSettings.ackRewards', error);
    throw buildModelError(error);
  }
}
