import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import PlayIcon from 'ICONS/ic-play-32.svg';

import { YouTubePlayer } from './YouTubePlayer/YouTubePlayer';
import { useYouTubePreviewPresenter } from './YouTubePreview.presenter';
import styles from './YouTubePreview.styles.m.css';

export const YouTubePreview = observer(
  ({ id, data, maxWidth, maxHeight, className, onStartPlay }) => {
    const presenter = useYouTubePreviewPresenter({
      id,
      data,
      maxWidth,
      maxHeight,
      onStartPlay,
    });

    if (!presenter.isShow) {
      return null;
    }

    return (
      <div
        role="button"
        tabIndex="0"
        className={classNames(styles.container, className)}
        style={presenter.containerStyle}
        onClick={presenter.onStartPlay}
      >
        {presenter.hasPreview && (
          <>
            <img
              className={styles.image}
              src={presenter.resizedImage.url}
              alt={presenter.title}
            />

            <div className={styles.playButton}>
              <PlayIcon className={styles.playIcon} />
            </div>
          </>
        )}

        {presenter.isShowPlayer && (
          <YouTubePlayer
            className={styles.video}
            id={presenter.playerId}
            videoId={presenter.videoId}
            title={presenter.title}
            isAutoplay={presenter.isAutoplay}
            timecode={presenter.timecode}
          />
        )}
      </div>
    );
  }
);
