import classNames from 'classnames';
import React, { memo } from 'react';

import AnimatedPartStyles from '../AnimatedPart/AnimatedPart.styles.m.css';
import styles from './styles.m.css';

const Image = ({ width = 200, height = 100 }) => {
  return (
    <div
      style={{ width, height, '--end-animation-position': `${width}px` }}
      className={classNames(styles.container, AnimatedPartStyles.animation)}
    />
  );
};

export default memo(Image);
