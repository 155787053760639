import { reaction } from 'mobx';
import { IReactionDisposer } from 'mobx/lib/internal';

import { Me } from '../../Me';

// ToDO: [Call] Rewrite or delete reactions
export const handleUnpublishStream = (store: Me): IReactionDisposer => {
  return reaction(
    () => store.parent.members.memberOnCallList,
    () => {
      store.unpublish();
    }
  );
};
