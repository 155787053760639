import { FieldState } from 'final-form';
import { useLocalStore, useAsObservableSource } from 'mobx-react';

import { GroupType } from 'APP/model/group/groupModel.types';
import { useTranslation } from 'APP/packages/translations';
import validations from 'APP/packages/validations';
import { IGroupFormInitialValues } from 'MAIN/PopupManager/components/GroupForm/GroupForm.types';

export interface IGroupFormTypeRadioOption {
  value: GroupType.Open | GroupType.Closed;
  label: string;
  description: string;
}

export type TValidateHandler = (
  value: string,
  values: string[],
  fieldState: FieldState<string>
) => Promise<boolean>;

export interface IGroupFormGeneralPresenterParams {
  initialValues: IGroupFormInitialValues;
  isEditMode: boolean;
}

export interface IGroupFormGeneralPresenter {
  initialValues: IGroupFormInitialValues;
  radioOptions: IGroupFormTypeRadioOption[];
  pristine: boolean;
  nameValidate: TValidateHandler;
  aliasValidate: TValidateHandler;
}

export const useGroupFormGeneralPresenter = (
  data: IGroupFormGeneralPresenterParams
): IGroupFormGeneralPresenter => {
  const { initialValues, isEditMode } = data;
  const { t } = useTranslation();
  const formValidations = validations.useCreateEditChatGroupValidations();

  const source = useAsObservableSource({ isEditMode });

  const presenter = useLocalStore<IGroupFormGeneralPresenter>(() => ({
    initialValues,
    radioOptions: [
      {
        value: GroupType.Closed,
        label: t('channel_private_option'),
        description: t('channel_private_option_subhint'),
      },
      {
        value: GroupType.Open,
        label: t('group_creation_open_type'),
        description: t('channel_public_option_subhint'),
      },
    ],

    get pristine(): boolean {
      return (
        source.isEditMode ||
        Object.values(presenter.initialValues).filter((value) => Boolean(value)).length <= 1
      );
    },

    get nameValidate(): TValidateHandler {
      return formValidations.groupName.validate;
    },

    get aliasValidate(): TValidateHandler {
      if (source.isEditMode) {
        return formValidations.groupAliasEdit.validate;
      }

      return formValidations.groupAliasCreate.validate;
    },
  }));

  return presenter;
};
