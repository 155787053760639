import { useState } from 'react';

export default ({ values, handleSearch }) => {
  const [searchString, setSearchString] = useState('');

  let filteredKeys = Object.keys(values);
  if (searchString) {
    filteredKeys = filteredKeys.filter((key) => {
      return handleSearch(values[key], searchString);
    });
  }

  return { searchString, setSearchString, filteredKeys };
};
