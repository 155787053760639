import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect, useLayoutEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';

import Tasks from 'APP/Tasks';
import { ALERT_TYPES } from 'APP/constants/app';
import { PayloadType } from 'APP/model/message/messageModel.types';
import { RouterPage } from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import Entities from 'APP/store';
import getActualSortedOrder from 'MAIN/ChatView/ContextPopups/Article/services/payloads/getActualSortedOrder';
import { replaceHtmlTagsToSpecialSymbols } from 'UTILS/html/replaceHtmlTagsToSpecialSymbols';

import { createDraft } from '../Tasks/createDraft';
import sendNewMessage from '../Tasks/sendNewMessage';
import updateMessage from '../Tasks/updateMessage';

const SUPPORTED_TYPES = [
  PayloadType.Video,
  PayloadType.File,
  PayloadType.Image,
  PayloadType.AudioMessage,
  PayloadType.Location,
  PayloadType.RichText,
];

export default ({ payloads, scroll, groupId }) => {
  const match = useRouteMatch();
  const navigateTo = useNavigateTo();
  const source = useAsObservableSource({
    payloads,
    scroll,
    groupId,
    match,
  });

  const presenter = useLocalStore(() => ({
    isEditMode: false,
    draft: null,

    initEditMode: async () => {
      presenter.isEditMode = true;
      if (!presenter.message) {
        presenter.draft = await Tasks.drafts.getDraft({
          groupId: source.groupId,
          draftId: source.match.params.id,
        });
        //filtered unsupported payloads
        if (presenter.draft) {
          presenter.draft.payload.payloads = getActualSortedOrder(
            presenter.draft.payload.payloads.filter((x) =>
              SUPPORTED_TYPES.includes(x.payload.payloadType)
            )
          );
        }
      }

      source.payloads.loadData(presenter.message);
    },

    handleCreateArticle: async () => {
      if (presenter.draft) {
        await Tasks.drafts.sendDraft(presenter.handleSendDraftData);
        return;
      }

      await sendNewMessage(presenter.handleCreateArticleData);
    },

    handleUpdateArticle: async () => {
      const data = presenter.handleUpdateArticleData;

      await updateMessage(data);
    },

    handleCreateDraft: async () => {
      const data = presenter.handleCreateDraftData;

      await createDraft(data);
    },

    handleUpdateDraft: async () => {
      const data = presenter.handleUpdateDraftData;

      await Tasks.drafts.updateDraft(data);
    },

    get group() {
      return Entities.GroupsStore.getGroupById(source.groupId);
    },

    get message() {
      if (!presenter.group) {
        return null;
      }

      return (
        presenter.draft ||
        presenter.group.messagesStore.getMessageById(source.match.params.id) ||
        null
      );
    },

    get messages() {
      return presenter.group.messagesStore.messages.length;
    },

    get payloads() {
      return source.payloads;
    },

    get scroll() {
      return source.scroll;
    },

    get isDisabled() {
      if (
        presenter.draft &&
        presenter.payloads.isTitleValid &&
        presenter.payloads.somethingTextPayload
      ) {
        return false;
      }

      if (!presenter.payloads.isTitleValid) {
        return true;
      }

      if (presenter.hasPayloadSomethingLoading) {
        return true;
      }

      if (!presenter.payloads.somethingTextPayload) {
        return true;
      }

      if (presenter.isEditMode && !presenter.payloads.isChanged) {
        return true;
      }

      return !replaceHtmlTagsToSpecialSymbols(presenter.payloads.title).trim().length;
    },

    get isLoading() {
      if (!presenter.isEditMode) {
        return true;
      }

      return presenter.message;
    },

    get hasPayloadSomethingLoading() {
      return presenter.payloads.somethingLoading.size;
    },

    get hasSomethingData() {
      return (
        presenter.payloads.title.trim().length ||
        presenter.payloads.somethingTextPayload ||
        presenter.payloads.data.length
      );
    },

    get handleCreateArticleData() {
      return {
        groupId: presenter.group.id,
        payload: {
          title: replaceHtmlTagsToSpecialSymbols(presenter.payloads.title.trim()),
          payloads: presenter.payloads.getFinalPayloads({
            isEditMode: presenter.isEditMode,
            isDraft: presenter.draft,
          }),
        },
      };
    },

    get handleSendDraftData() {
      return {
        id: presenter.draft.id,
        groupId: presenter.draft.groupId,
        clientUuid: presenter.draft.clientUuid,
        payload: {
          title: replaceHtmlTagsToSpecialSymbols(presenter.payloads.title.trim()),
          payloads: presenter.payloads.getFinalPayloads({
            isEditMode: presenter.isEditMode,
            isDraft: presenter.draft,
          }),
        },
      };
    },

    get handleUpdateArticleData() {
      return {
        groupId: presenter.group.id,
        messageId: match.params.id,
        title: replaceHtmlTagsToSpecialSymbols(presenter.payloads.title.trim()),
        payloads: presenter.payloads.getFinalPayloads({
          isEditMode: presenter.isEditMode,
        }),
      };
    },

    get handleCreateDraftData() {
      return {
        groupId: presenter.group.id,
        payload: {
          title: replaceHtmlTagsToSpecialSymbols(presenter.payloads.title.trim()),
          payloads: presenter.payloads.getFinalPayloads({ isEditMode: false }),
        },
      };
    },

    get handleUpdateDraftData() {
      return {
        groupId: presenter.draft.groupId,
        draftId: presenter.draft.id,
        payload: {
          title: replaceHtmlTagsToSpecialSymbols(presenter.payloads.title.trim()),
          payloads: presenter.payloads.getFinalPayloads({
            isEditMode: presenter.isEditMode,
          }),
        },
      };
    },
  }));

  useLayoutEffect(() => {
    if (match.params.id) {
      presenter.initEditMode();
    }
  }, [match.params.id, presenter.message]);

  useEffect(() => {
    if (presenter.group.isSchedule && presenter.isEditMode && !presenter.message) {
      Tasks.app.addAlert({ type: ALERT_TYPES.ARTICLE_WAS_PUBLISHED_ALERT });
      navigateTo({
        to: `/${RouterPage.Channels}/${presenter.group.groupId}`,
      });
    }
  }, [presenter.message, presenter.group, presenter.isEditMode]);

  return presenter;
};
