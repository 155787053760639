import api from 'APP/packages/api';
import Entities from 'APP/store';

async function request(data) {
  try {
    const response = await api.messaging.getUsersViewedMessage(data);

    return {
      hasMoreResults: response.hasMoreResults,
      users: response.users,
    };
  } catch (error) {
    console.error(error);
  }

  return { hasMoreResults: false, users: [] };
}

export default async (data) => {
  const { users, hasMoreResults } = await request(data);

  const group = Entities.GroupsStore.getGroupById(data.groupId);

  if (group) {
    const message = group.messagesStore.getMessageById(data.messageId);

    if (message && message.views < users.length) {
      message.setViews(users.length.toString());
    }
  }

  return {
    users,
    hasMoreResults,
  };
};
