import { action } from 'mobx';

import { Group } from 'STORE/Groups/Group';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

import { SharedArticles } from './SharedArticles/SharedArticles';
import { SharedAudio } from './SharedAudio/SharedAudio';
import { SharedFiles } from './SharedFiles/SharedFiles';
import { SharedLinks } from './SharedLinks/SharedLinks';
import { SharedMedia } from './SharedMedia/SharedMedia';
import { SharedMessages } from './SharedMessages/SharedMessages';
import { SharedVoices } from './SharedVoices/SharedVoices';

export class SharedData {
  messages: SharedMessages;
  media: SharedMedia;
  links: SharedLinks;
  files: SharedFiles;
  audio: SharedAudio;
  voices: SharedVoices;
  articles: SharedArticles;

  constructor(group: Group) {
    this.messages = new SharedMessages(group);

    this.media = new SharedMedia(this.messages);
    this.links = new SharedLinks(this.messages);
    this.files = new SharedFiles(this.messages);
    this.audio = new SharedAudio(this.messages);
    this.voices = new SharedVoices(this.messages);
    this.articles = new SharedArticles(this.messages);
  }

  @action
  addMessages(messages: ChatMessage[]): void {
    this.messages.addMessages(messages);
  }

  getMessageById(id: string): ChatMessage | null {
    return this.messages.getMessageById(id) || null;
  }

  @action
  clear(): void {
    this.media.updateCount(0);
    this.links.updateCount(0);
    this.files.updateCount(0);
    this.audio.updateCount(0);
    this.voices.updateCount(0);
    this.articles.updateCount(0);
  }
}
