import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { useTranslation } from 'APP/packages/translations';
import CallIcon from 'ICONS/ic-call.svg';
import EditIcon from 'ICONS/ic-edit.svg';
import CopyIcon from 'ICONS/ic-s-copy.svg';
import { IconButton } from 'UIKIT/IconButton';

import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';
import { MetaBlock } from '../MetaBlock/MetaBlock';
import styles from './styles.m.css';

interface IPhoneNumberProps {
  className: string;
  phoneNumber: string;
  onChangePhone?(): void;
}

export const PhoneNumber: FC<IPhoneNumberProps> = observer(
  ({ className, phoneNumber, onChangePhone }) => {
    const { t } = useTranslation();

    const copyToClipboard = useCopyToClipboard(phoneNumber, t('profile_user_number_copied'));

    return (
      <MetaBlock
        className={className}
        label={t('profile_user_phone_hint')}
        Icon={CallIcon}
        Actions={
          <>
            {onChangePhone && (
              <IconButton
                Icon={EditIcon}
                onClick={onChangePhone}
              />
            )}

            <IconButton
              Icon={CopyIcon}
              onClick={copyToClipboard}
            />
          </>
        }
      >
        <button
          className={styles.link}
          onClick={copyToClipboard}
        >
          {phoneNumber}
        </button>
      </MetaBlock>
    );
  }
);
