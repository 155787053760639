import React, { FC } from 'react';

import { useTranslation } from 'APP/packages/translations';

import styles from './DisabledMessagedPanel.styles.m.css';

export const DisabledMessagedPanel: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <span className={styles.description}>{t('moderation_disable_send_message')}</span>
    </div>
  );
};
