import Tasks from 'APP/Tasks';
import api from 'APP/packages/api';

import prepareImageUrls from '../../../../../services/prepareImageUrls';

async function request(data) {
  try {
    const { url, graphicSize } = await api.messaging.copyImageFromExternalResource(data);

    return {
      url,
      graphicSize,
    };
  } catch (error) {
    console.error(error);
  }

  return {
    url: null,
    graphicSize: null,
  };
}

export default async (link) => {
  const { url, graphicSize } = await request({ link });

  if (!url || !graphicSize) {
    return {
      url,
      graphicSize,
    };
  }

  const { imageUrl, blurredImageUrl } = prepareImageUrls(url);

  try {
    await Promise.all([Tasks.app.preloadImage(imageUrl), Tasks.app.preloadImage(blurredImageUrl)]);

    return {
      url,
      graphicSize,
    };
  } catch {
    return {
      url: null,
      graphicSize: null,
    };
  }
};
