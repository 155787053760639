import Tasks from 'APP/Tasks';
import api from 'APP/packages/api';

async function request(data) {
  try {
    const response = await api.group.getFriendsNotInvitedToGroup(data);

    return response.usersDisplayData.map((user) => user.id);
  } catch (error) {
    console.error(error);
  }

  return [];
}

export default async ({ groupId }) => {
  const usersIds = await request({ groupId });

  await Tasks.relationships.loadUnknownUsersByIds({
    usersIds,
  });

  return usersIds;
};
