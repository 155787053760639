import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { GroupAvatar } from 'APP/components/Avatar/GroupAvatar/GroupAvatar';
import { VerifiedBadge } from 'APP/components/VerifiedBadge/VerifiedBadge';
import { useTranslation } from 'APP/packages/translations';
import { Group } from 'STORE/Groups/Group';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';
import { Checkbox } from 'UIKIT/Checkbox';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';
import { getFormatNumberToString } from 'UTILS/getFormatNumberToString';

import styles from './DeleteSpaceGroupsList.styles.m.css';

interface IDeleteSpaceGroupsListProps {
  groups: Group[];
  selectedGroups: Set<string>;
  onToggleGroup(groupId: string): void;
}

export const DeleteSpaceGroupsList: FC<IDeleteSpaceGroupsListProps> = observer(
  ({ groups, selectedGroups, onToggleGroup }) => {
    const { t } = useTranslation();

    return (
      <div className={classNames(styles.list, customScrollStyles.container)}>
        {groups.map((group) => (
          // eslint-disable-next-line jsx-a11y/label-has-associated-control
          <label
            className={styles.listItem}
            key={group.id}
          >
            <GroupAvatar
              className={styles.avatar}
              group={group}
              type={group.isChannel ? AvatarTypes.Square_20 : AvatarTypes.Round_10}
              hasLockIcon={group.isChannel && group.isClosed}
            />

            <div className={styles.info}>
              <div className={styles.nameWrapper}>
                <h3 className={styles.name}>{group.name}</h3>
                {group.verified && <VerifiedBadge />}
              </div>

              <span className={styles.membersCount}>
                {(group.isChannel
                  ? t('amount_of_subscribers_in_a_channel_many')
                  : t('amount_of_members_in_a_group_many')
                ).replace('{0}', getFormatNumberToString(group.usersCount))}
              </span>
            </div>

            <Checkbox
              isChecked={selectedGroups.has(group.id)}
              onChange={(): void => onToggleGroup(group.id)}
            />
          </label>
        ))}
      </div>
    );
  }
);
