import api from 'APP/packages/api';
import Entities from 'APP/store';

async function request(data) {
  try {
    return await api.group.pinGroup(data);
  } catch (error) {
    console.error(error);
  }
}

export default async ({ groupId }) => {
  const rawGroup = await request({ groupId });

  if (!rawGroup) {
    return;
  }

  Entities.GroupsStore.merge([rawGroup]);
};
