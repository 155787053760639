import Entities from 'APP/store';

export default ({ groupId }) => {
  const inputPanel = Entities.InputPanelsStore.getById(groupId);

  if (!inputPanel) {
    return;
  }

  inputPanel.setLink(null);
  inputPanel.linkPreviews.clear();
  inputPanel.setIsShowLinkPreview(false);
  inputPanel.setIsLinkPreviewCancelled(false);
};
