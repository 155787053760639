import execCommand from 'execcommand-copy';
import { useCallback } from 'react';

import Entities from 'APP/store';

export function useCopyToClipboard(data: string, message: string): () => void {
  return useCallback(() => {
    execCommand.copy(data);
    Entities.toast.show(message);
  }, [data]);
}
