import classNames from 'classnames';
import React, {
  FC,
  useCallback,
  useState,
  KeyboardEvent,
  MutableRefObject,
  PropsWithChildren,
} from 'react';

import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';

import styles from './MultiLineField.style.m.scss';

interface IMultiLineFieldProps {
  className?: string;
  name?: string;
  value?: string;
  label?: string;
  placeholder?: string;
  maxLength?: number;
  hasError?: boolean;
  message?: string;
  active?: boolean;
  setRef?: MutableRefObject<HTMLTextAreaElement | null>;
  autoFocus?: boolean;
  isDisabled?: boolean;
  onKeyDown?(event: KeyboardEvent<HTMLTextAreaElement>): void;
  onChange(value: string, name: string): void;
  onFocus?(): void;
  onBlur?(): void;
}

export const MultiLineField: FC<PropsWithChildren<IMultiLineFieldProps>> = (props) => {
  const {
    className = undefined,
    name,
    onChange,
    value,
    label,
    placeholder,
    maxLength,
    hasError,
    message,
    onKeyDown,
    onFocus,
    onBlur,
    active,
    setRef,
    autoFocus,
    isDisabled,
    children,
  } = props;

  const [isFocused, setFocused] = useState(active);

  const handleOnFocus = useCallback(() => {
    setFocused(true);
    onFocus && onFocus();
  }, []);

  const handleOnBlur = useCallback(() => {
    setFocused(false);
    onBlur && onBlur();
  }, []);

  const handleOnChange = useCallback(
    (event) => {
      const { value, name } = event.target;
      onChange(value, name);
    },
    [onChange]
  );

  const contentClasses = classNames(styles.content, customScrollStyles.container, {
    [styles.focused]: isFocused,
    [styles.error]: hasError,
    [styles.disabled]: isDisabled,
  });

  const labelClasses = classNames(styles.label, {
    [styles.smallLabel]: isFocused || Boolean(placeholder) || Boolean(value),
  });

  return (
    <div className={className}>
      <label className={contentClasses}>
        <span className={labelClasses}>{label}</span>
        <textarea
          rows={1}
          ref={setRef}
          autoFocus={autoFocus}
          name={name}
          value={value}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          className={styles.textarea}
          onChange={handleOnChange}
          placeholder={placeholder}
          maxLength={maxLength}
          disabled={isDisabled}
          onKeyDown={onKeyDown}
        />
      </label>
      <div className={styles.message}>{message || children}</div>
    </div>
  );
};
