import { PLATFORMS } from 'APP/constants';

const matchPlatformNameToPlatform = (platform) => {
  switch (platform) {
    case PLATFORMS.ANDROID:
      return 'Android';
    case PLATFORMS.IOS:
      return 'iOS';
    case PLATFORMS.WEB:
      return 'Web';
    default:
      return 'Desktop';
  }
};

export default matchPlatformNameToPlatform;
