export const splitByParams = (string = ''): string[] => {
  const result: string[] = [];
  const matches = string.matchAll(/{\d+}/g);
  let lastIndex = 0;
  for (const match of matches) {
    if (string.slice(lastIndex, match.index)) {
      result.push(string.slice(lastIndex, match.index));
    }
    if (match.index === undefined) {
      break;
    }
    result.push(match[0]);
    lastIndex = match.index + match[0].length;
  }
  if (string.slice(lastIndex)) {
    result.push(string.slice(lastIndex));
  }
  return result;
};
