import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useRef, useEffect } from 'react';

const checkElement = (element) => element && document.body.contains(element);

export default (ref) => {
  const source = useAsObservableSource({ ref });
  const elementRef = useRef();
  const requestAnimationId = useRef();

  const presenter = useLocalStore(() => ({
    isNeedScroll: (ref) => {
      if (!checkElement(ref?.current)) {
        return false;
      }

      if (!checkElement(source.ref?.current)) {
        return true;
      }

      const style = getComputedStyle(source.ref?.current);
      const eRect = ref?.current.getBoundingClientRect();
      const cRect = source.ref?.current.getBoundingClientRect();
      const pRect = {
        top: parseInt(style.getPropertyValue('scroll-padding-top')) || 0,
        bottom: parseInt(style.getPropertyValue('scroll-padding-bottom')) || 0,
      };

      return (
        eRect.top - cRect.top + eRect.height + pRect.bottom > cRect.height ||
        eRect.top - cRect.top + eRect.height - pRect.top < 0
      );
    },

    scrollToRef: (ref, isImmediately = false) => {
      if (!checkElement(ref?.current)) {
        return;
      }

      if (isImmediately) {
        ref.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
        elementRef.current = null;
      } else {
        elementRef.current = ref.current;
      }
    },

    postRender: () => {
      if (requestAnimationId.current) {
        cancelAnimationFrame(requestAnimationId.current);
      }

      requestAnimationId.current = requestAnimationFrame(() => {
        requestAnimationId.current = null;
        if (presenter.isNeedScroll(elementRef)) {
          presenter.scrollToRef(elementRef, true);
        }
      });
    },
  }));

  useEffect(() => {
    presenter.postRender();
  });

  return presenter;
};
