import { action, observable } from 'mobx';

export class UserPreferences {
  @observable categoryIds: string[] = [];
  @observable localeIds: string[] = [];
  @observable userPreferencesSelected = false;

  @action reset(): void {
    this.categoryIds = [];
    this.localeIds = [];
  }

  @action
  setCategoryIds(ids: string[]): void {
    this.categoryIds = Array.from(ids, (id) => String(id));
  }

  @action
  setLocaleIds(ids: string[]): void {
    this.localeIds = Array.from(ids, (id) => String(id));
  }

  @action
  setUserPreferencesSelected(userPreferencesSelected: boolean): void {
    this.userPreferencesSelected = userPreferencesSelected;
  }
}
