import { useState } from 'react';

import { useTranslation } from 'APP/packages/translations';

export function useSeeMoreTextPresenter(
  text: string,
  maxTextLength: number
): { textToShow: string; hasShowMore: boolean; setHasShowMore(hasShowMore: boolean): void } {
  const { t } = useTranslation();
  const textLength = maxTextLength - t('group_description_more').length;

  const [hasShowMore, setHasShowMore] = useState(text.length > textLength);

  return {
    textToShow: hasShowMore ? text.slice(0, maxTextLength) + ' ' : text,
    hasShowMore,
    setHasShowMore,
  };
}
