import { useAsObservableSource, useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { debounce } from 'UTILS/debounce';

export const useSpaceVisibilityPresenter = ({ group }) => {
  const source = useAsObservableSource({ group });

  const presenter = useLocalStore(() => ({
    get isShow() {
      return source.group.space && !source.group.withoutMe;
    },

    get isVisible() {
      const space = source.group.space;
      if (!space) {
        return false;
      }

      return space.groupVisibility.isGroupVisible(source.group.id);
    },

    onToggle() {
      const space = source.group.space;
      if (!space) {
        return;
      }

      const newValue = !presenter.isVisible;
      space.groupVisibility.setGroupVisible(group.id, newValue);
      presenter.changeSpaceVisability(group.id, newValue);
    },

    changeSpaceVisability: debounce((groupId, isVisible) => {
      Tasks.space.changeGroupVisibility(groupId, isVisible);
    }, 1000),
  }));

  return presenter;
};
