import { observer } from 'mobx-react';
import React from 'react';

import { Sticker } from 'APP/components/Sticker/Sticker';
import { STICKER_BUBBLE_MAX_WIDTH, STICKER_BUBBLE_MAX_HEIGHT } from 'APP/constants/stickers';

import usePresenter from './presenter';

const StickerComponent = ({ message }) => {
  const { onOpenStickerPack } = usePresenter();

  return (
    <Sticker
      sticker={message.payload}
      displayUILayer={0}
      maxWidth={STICKER_BUBBLE_MAX_WIDTH}
      maxHeight={STICKER_BUBBLE_MAX_HEIGHT}
      stickerPackId={message.payload.stickerPackId}
      onClick={onOpenStickerPack}
    />
  );
};

export default observer(StickerComponent);
