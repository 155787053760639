import patterns from 'APP/packages/patterns';

export const extractMentionsFromString = (innerHTML: string): string[] => {
  if (!innerHTML) {
    return [];
  }

  const regExp = new RegExp(patterns.MENTION, 'ig');
  const mentions = innerHTML.match(regExp) || [];
  return mentions.map((mention) => mention.trim());
};
