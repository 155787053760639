export enum PermissionsValue {
  Everyone = 'EVERYONE',
  AfterApprove = 'AFTER_APPROVE',
  AdminsAndOwner = 'ADMINS_AND_OWNER',
}

export enum PermissionsPersonalizedValue {
  Always = 'ALWAYS',
  Never = 'NEVER',
  AfterApprove = 'AFTER_APPROVE',
}

export enum PermissionsTypes {
  ShareScreen = 'shareScreen',
  SwitchOnCamera = 'switchOnCamera',
  SwitchOnMic = 'switchOnMic',
}
