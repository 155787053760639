import { observer } from 'mobx-react';
import React, { FC, PropsWithChildren, ReactNode, MouseEventHandler } from 'react';
import { InView } from 'react-intersection-observer';

import IcDone from 'APP/icons/ic-s-done-16.svg';
import { RewardProgressInfoRewardEnum } from 'APP/model/userSettings/userSettingsModel.types';
import { useTranslation } from 'APP/packages/translations';
import { Button } from 'UIKIT/Button/Button';
import { IconView } from 'UIKIT/IconView/IconView';
import { IconViewSizes } from 'UIKIT/IconView/IconView.types';

import { useRewardsBaseItemPresenter } from './RewardsBaseItem.presenter';
import styles from './RewardsBaseItem.styles.m.css';

interface RewardsBaseItemProps {
  progressPercent?: number;
  currentPoints?: number;
  maxPoints?: number;
  image: string;
  title: string;
  subTitle: string;
  achieved?: boolean;
  imageAlt: string;
  childrenInsteadGetGift?: ReactNode;
  rewardType: RewardProgressInfoRewardEnum;
  onGetReward: MouseEventHandler<HTMLButtonElement>;
}

export const RewardsBaseItem: FC<PropsWithChildren<RewardsBaseItemProps>> = observer((props) => {
  const { t } = useTranslation();
  const presenter = useRewardsBaseItemPresenter(props.rewardType);

  const inViewProps = {
    onChange: presenter.onChangeInView,
    threshold: 0.8,
    className: styles.wrapper,
  };

  return (
    <InView {...inViewProps}>
      <div className={styles.progressContainer}>
        {props.achieved ? (
          <div className={styles.doneBadge}>
            <IconView
              Icon={IcDone}
              size={IconViewSizes.Small}
            />
            {t('done')}
          </div>
        ) : (
          <>
            <div className={styles.progressBar}>
              <div
                style={{ width: `${props.progressPercent}%` }}
                className={styles.progressBarProgress}
              />
            </div>
            <div className={styles.progressCount}>
              {`${props.currentPoints} / ${props.maxPoints}`}
            </div>
          </>
        )}
      </div>
      <img
        className={styles.image}
        src={props.image}
        alt={props.imageAlt}
      />
      <h3 className={styles.title}>{props.title}</h3>
      <div className={styles.subTitle}>{props.subTitle}</div>
      {props.children}
      {props.achieved ? (
        <Button
          className={styles.getButton}
          size="20"
          onClick={props.onGetReward}
        >
          {t('common_get_your_gift')}
        </Button>
      ) : (
        props.childrenInsteadGetGift
      )}
    </InView>
  );
});
