import noop from 'UTILS/noop';

import { ApiError } from '../error/ApiError';

interface IConfigApi {
  apiUrl: string;
  userSession: string | null;
  version: string;
  errorHandler(error: ApiError): void;
}

export const configApi: IConfigApi = {
  apiUrl: '',
  userSession: null,
  version: 'v1',
  errorHandler: noop,
};

export const setConfigApi = ({ apiUrl, errorHandler, userSession }: Partial<IConfigApi>): void => {
  if (apiUrl !== undefined) {
    configApi.apiUrl = apiUrl;
  }

  if (errorHandler !== undefined) {
    configApi.errorHandler = errorHandler;
  }

  if (userSession !== undefined) {
    configApi.userSession = userSession;
  }
};
