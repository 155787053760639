import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import Entities from 'APP/store';
import OngoingCall from 'STORE/ViewModels/Calls/OngoingCall';

interface ICallViewPresenter {
  ongoingCall: OngoingCall;
}

export const useCallViewPresenter = (): ICallViewPresenter => {
  const presenter = useLocalStore<ICallViewPresenter>(() => ({
    get ongoingCall(): OngoingCall {
      return Entities.OngoingCall;
    },
  }));

  useEffect(() => {
    if (presenter.ongoingCall.mode.isFullScreen) {
      Tasks.app.fullscreen.registerFullscreen(Entities.OngoingCall.mode.collapse);
    } else {
      Tasks.app.fullscreen.unregisterFullscreen(Entities.OngoingCall.mode.collapse);
    }

    return () => {
      Tasks.app.fullscreen.unregisterFullscreen(Entities.OngoingCall.mode.collapse);
    };
  }, [presenter.ongoingCall.mode.isFullScreen]);

  return presenter;
};
