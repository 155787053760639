import React, { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { useClosePopupOnNavigation } from '../../hooks/useClosePopupOnNavigation';
import { useUserPrivacyPopupPresenter } from './UserPrivacyPopup.presenter';
import { UserPrivacyView } from './UserPrivacyView/UserPrivacyView';

export const UserPrivacyPopup: FC<ICommonPopupsProps> = (props) => {
  const presenter = useUserPrivacyPopupPresenter(props);
  useClosePopupOnNavigation(props.popupInstance);

  return (
    <Popup
      isOpened
      dataTest="user-privacy-popup"
      onClose={presenter.onClose}
    >
      <UserPrivacyView
        onBack={presenter.hasBackButton ? presenter.onBack : null}
        onClose={presenter.onClose}
      />
    </Popup>
  );
};
