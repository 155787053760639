import { observer } from 'mobx-react';
import React from 'react';

import { InAppView } from 'APP/main/InAppView/InAppView';
import { HubEmptyScreen } from 'MAIN/Hub/Components/HubEmptyScreen/HubEmptyScreen';
import { HubPageType, THubPage } from 'MAIN/Hub/Hub.types';
import Entities from 'STORE';

export const HubFreelancePage: THubPage = observer(({ setPage }) => {
  const freelanceUrl = Entities.marketplace.baseFreelanceUrl;

  if (!freelanceUrl) {
    return <HubEmptyScreen setPage={setPage} />;
  }

  return (
    <InAppView
      isLocal={true}
      url={freelanceUrl}
      goBack={() => setPage(HubPageType.Main)}
    />
  );
});
