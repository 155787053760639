import { IClientConfigProperty } from 'APP/model/auth/authModel.types';
import logger from 'APP/packages/logger';
import Entities from 'APP/store';

export function handleWhitelistHosts(property: IClientConfigProperty): void {
  try {
    const whitelistHosts: string[] = JSON.parse(property.value);
    Entities.App.setWhitelistHosts(whitelistHosts);
  } catch (error) {
    logger.get('clientConfig').error('whitelist.urls', error);
  }
}
