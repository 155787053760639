import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { IconView } from 'APP/packages/UiKit/IconView/IconView';
import ActiveCheck from 'ICONS/ic-chech-active.svg';
import type { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

import styles from './MessageSelect.styles.m.css';

interface IMessageSelectProps {
  className?: string;
  message: ChatMessage;
  onMessageSelect: React.ChangeEventHandler<HTMLInputElement>;
  isChannel?: boolean;
  labelClassName?: string;
}

export const MessageSelect: FC<IMessageSelectProps> = observer((props) => {
  const { message, onMessageSelect, isChannel = true, labelClassName, className } = props;
  return (
    <div className={classNames(styles.select, className, { [styles.isChannel]: isChannel })}>
      {message.showSelect && (
        <label className={classNames(styles.checkbox, labelClassName)}>
          <input
            type="checkbox"
            checked={message.isSelected}
            onChange={onMessageSelect}
            className={styles.input}
          />
          {message.isSelected ? (
            <IconView
              Icon={ActiveCheck}
              className={styles.active}
            />
          ) : (
            <span className={styles.inactive} />
          )}
        </label>
      )}
    </div>
  );
});
