/**
 * Stream API
 * Documentation Stream API v1.0
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * calls.status-changed
 */
export interface StatusChanged { 
    channel: string;
    status: StatusChangedStatusEnum;
    callType: StatusChangedCallTypeEnum;
    callProvider: StatusChangedCallProviderEnum;
    event: string;
}
export enum StatusChangedStatusEnum {
    Started = 'STARTED',
    Finished = 'FINISHED',
    Initiated = 'INITIATED'
};
export enum StatusChangedCallTypeEnum {
    P2P = 'P2P',
    Group = 'GROUP',
    Conference = 'CONFERENCE',
    Stream = 'STREAM'
};
export enum StatusChangedCallProviderEnum {
    Agora = 'AGORA',
    Openvidu = 'OPENVIDU'
};



