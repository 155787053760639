import React, { ElementType, FC } from 'react';

import { IconView } from 'APP/packages/UiKit/IconView/IconView';

import styles from './UserSettingsPopupItemInfo.styles.m.css';

interface IUserSettingsPopupItemInfoProps {
  title: string;
  Icon: ElementType;
}

export const UserSettingsPopupItemInfo: FC<IUserSettingsPopupItemInfoProps> = ({ Icon, title }) => {
  return (
    <>
      <IconView
        Icon={Icon}
        className={styles.icon}
      />
      <span className={styles.title}>{title}</span>
    </>
  );
};
