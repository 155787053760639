import React, { memo, useEffect, useState } from 'react';

import { IconButton, IconViewSizes } from 'APP/packages/UiKit/IconButton';
import CancelEntireScreenIcon from 'ICONS/ic-cancel-full-screen.svg';
import EntireScreenIcon from 'ICONS/ic-full-screen.svg';
import { enterFullScreen, exitFullScreen } from 'UTILS/fullScreen';

interface IEntireScreenButtonProps {
  className?: string;
}
export const EntireScreenButton = memo<IEntireScreenButtonProps>((props) => {
  const [isFullScreen, setIsFullScreen] = useState(Boolean(document.fullscreenElement));

  const updateFullScreen = (): void => setIsFullScreen(Boolean(document.fullscreenElement));

  const onClick = async (): Promise<void> => {
    if (isFullScreen) {
      await exitFullScreen();
      setIsFullScreen(false);
      return;
    }

    await enterFullScreen();
    setIsFullScreen(true);
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', updateFullScreen);
    return (): void => {
      document.removeEventListener('fullscreenchange', updateFullScreen);
      if (isFullScreen) {
        exitFullScreen();
      }
    };
  }, [isFullScreen]);

  return (
    <IconButton
      className={props.className}
      Icon={isFullScreen ? CancelEntireScreenIcon : EntireScreenIcon}
      onClick={onClick}
      size={IconViewSizes.Big}
      iconSize={IconViewSizes.Medium}
      theme="12"
    />
  );
});
