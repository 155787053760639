import { observer } from 'mobx-react';
import React, { FC, ReactElement, useCallback, useMemo } from 'react';

import { VirtualizedList } from 'APP/components/VirtualizedList/VirtualizedList';
import {
  GROUPS_PLACEHOLDER_ITEM_SIZE,
  GroupsPlaceholder,
} from 'APP/packages/placeholders/GroupsPlaceholder/GroupsPlaceholder';

import { ChannelAvailableItem } from './ChannelAvailableItem/ChannelAvailableItem';
import { ChannelItem } from './ChannelItem/ChannelItem';
import { GroupItem } from './GroupItem/GroupItem';
import {
  useGroupListPresenter,
  IGroupListPresenterParams,
  isDelimiterItem,
} from './GroupList.presenter';
import styles from './GroupList.styles.m.css';
import { RewardsBanner } from './RewardsBanner/RewardsBanner';

const DELIMETER_HEIGHT = 34;
const GROUP_ITEM_HEIGHT = 64;
const PLACEHOLDER_ITEMS_COUNT = 14;

type IGroupItemProps = IGroupListPresenterParams;

export const GroupList: FC<IGroupItemProps> = observer((props) => {
  const presenter = useGroupListPresenter(props);

  const getItemSize = useCallback(
    (index: number): number => {
      const item = presenter.listItems[index];
      return isDelimiterItem(item) ? DELIMETER_HEIGHT : GROUP_ITEM_HEIGHT;
    },
    [presenter.groupHash]
  );

  const getListItem = useCallback(
    (index: number): ReactElement => {
      const item = presenter.listItems[index];

      if (isDelimiterItem(item)) {
        return <h3 className={styles.availableTitle}>{presenter.recommendedTitle}</h3>;
      } else if (item.isRecommended) {
        return <ChannelAvailableItem group={item.group} />;
      } else {
        const groupProps = {
          group: item.group,
          isShowSpaceIcon: true,
        };

        return item.group.isChannel ? (
          <ChannelItem {...groupProps} />
        ) : (
          <GroupItem {...groupProps} />
        );
      }
    },
    [presenter.groupHash]
  );

  const Placeholder = useMemo(() => {
    return (
      <GroupsPlaceholder
        count={PLACEHOLDER_ITEMS_COUNT}
        avatarType={presenter.avatarType}
      />
    );
  }, []);

  return (
    <div className={styles.container}>
      <RewardsBanner />

      <div className={styles.list}>
        <VirtualizedList
          itemCount={presenter.itemsCount}
          getListItem={getListItem}
          itemSize={getItemSize}
          isShowLoader={presenter.isShowPlaceholder}
          Loader={Placeholder}
          loaderSize={PLACEHOLDER_ITEMS_COUNT * GROUPS_PLACEHOLDER_ITEM_SIZE}
        />
      </div>
    </div>
  );
});
