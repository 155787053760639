import { Coords } from 'google-map-react';
import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import { showAlert } from 'APP/Tasks/app/showAlert/showAlert';
import { location } from 'APP/packages/permissions/permissions';
import { platformInfo } from 'APP/packages/platformInfo/platformInfo';
import { useTranslation } from 'APP/packages/translations';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import Entities from 'STORE';
import { Group } from 'STORE/Groups/Group';
import { Schedule } from 'STORE/Groups/Schedule';

export interface ILocation {
  latitude: number | null;
  longitude: number | null;
}

export interface ISelectLocationParams {
  groupId?: string;
  onSelect(location: ILocation): void;
}

interface ISelectLocationPopup extends ILocation {
  isShowPopup: boolean;
  defaultZoom: number;
  defaultLatitude: number | null;
  defaultLongitude: number | null;
  map: any | null;
  maps: any | null;
  controlPosition: number | null;
  hasNoLocationAccessText: string;
  group: Group | null;
  isSheduledGroup: boolean;
  initialize(locationAccess: ILocation): void;
  setCoordinates({ lat, lng }: Coords): void;
  setMaps({ map, maps }: { map: any; maps: any }): void;
  centerPin(): void;
  onSubmit(): void;
  onClose(): void;
}

export function useSelectLocationPopup({
  popupInstance,
  params,
}: ICommonPopupsProps<ISelectLocationParams>): ISelectLocationPopup {
  const { t } = useTranslation();
  const { groupId, onSelect } = params!;

  const presenter = useLocalStore<ISelectLocationPopup>(() => ({
    isShowPopup: false,

    defaultZoom: 18,
    defaultLatitude: null,
    defaultLongitude: null,

    latitude: null,
    longitude: null,

    map: null,
    maps: null,

    initialize(locationAccess: ILocation): void {
      presenter.isShowPopup = true;
      presenter.defaultLatitude = locationAccess.latitude;
      presenter.defaultLongitude = locationAccess.longitude;
      presenter.latitude = locationAccess.latitude;
      presenter.longitude = locationAccess.longitude;
    },

    get group(): Group | null {
      return groupId ? Entities.GroupsStore.getGroupById(groupId) : null;
    },

    get isSheduledGroup(): boolean {
      return presenter.group instanceof Schedule;
    },

    get controlPosition(): number | null {
      return presenter.maps ? presenter.maps.ControlPosition.RIGHT_BOTTOM : null;
    },

    setCoordinates({ lat, lng }: Coords): void {
      presenter.latitude = lat;
      presenter.longitude = lng;
    },

    setMaps({ map, maps }: { map: any; maps: any }): void {
      presenter.map = map;
      presenter.maps = maps;
    },

    centerPin(): void {
      presenter.map.setZoom(presenter.defaultZoom);
      presenter.map.setCenter({ lat: presenter.latitude, lng: presenter.longitude });
    },

    get hasNoLocationAccessText(): string {
      if (platformInfo.isElectron && window.electronAPI?.isMac) {
        return t('system_preferences_location', {
          0: `<a href="x-apple.systempreferences:com.apple.preference.security?Privacy_LocationServices">${t(
            'system_preferences_common'
          )}</a>`,
          interpolation: { escapeValue: false },
        });
      }

      return t('gem4me_needs_location_access');
    },

    onSubmit(): void {
      onSelect({
        latitude: presenter.latitude,
        longitude: presenter.longitude,
      });
      presenter.onClose();
    },

    onClose(): void {
      popupInstance.close();
    },
  }));

  useEffect(() => {
    const initLocation = async (): Promise<void> => {
      const locationAccess = await location.getCurrentPosition();

      if (locationAccess.access === 'denied') {
        showAlert({ html: presenter.hasNoLocationAccessText });
        presenter.onClose();
      } else {
        presenter.initialize(locationAccess);
      }
    };

    initLocation();
  }, []);

  return presenter;
}
