import classNames from 'classnames';
import React, { memo } from 'react';

import styles from './style.m.scss';

const Slider = ({ name, value, max, min, step, onChange, className }) => {
  const filled = ((value - min) / (max - min)) * 100;

  return (
    <div className={styles.slider}>
      <div
        className={styles.dot}
        style={{ width: `${filled}%` }}
      />
      <input
        className={classNames(styles.input, className)}
        type="range"
        name={name}
        min={min}
        value={value}
        max={max}
        step={step}
        onChange={onChange}
      />
    </div>
  );
};

export default memo(Slider);
