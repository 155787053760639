import { DEEP_LINK_DOMAINS } from 'APP/constants/deepLinks';

import { getParamsByDeepLink } from './getParamsByDeepLink/getParamsByDeepLink';
import { handleDeepLink } from './handleDeepLink/handleDeepLink';

function isDeepLink(link) {
  try {
    const url = new URL(link);
    return DEEP_LINK_DOMAINS.some((host) => url.host === host);
  } catch (error) {
    //
  }
  return false;
}

export default {
  getParamsByDeepLink,
  isDeepLink,
  handleDeepLink,
};
