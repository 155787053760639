import React from 'react';

import { FilePayload } from 'MAIN/ChatView/Messages/Message/Payload/FilePayload/FilePayload';

import styles from './styles.m.scss';

const ArticlePreviewPayloadFile = ({ message }) => {
  return (
    <FilePayload
      message={message}
      className={styles.container}
    />
  );
};

export default ArticlePreviewPayloadFile;
