import { useCallback, useMemo } from 'react';

import Tasks from 'APP/Tasks';
import { getFixedSizeImage } from 'APP/packages/imageResizer/getFixedSizeImage';

const IMAGE_SIZE = 56;

export const useMediaGalleryPreviewPresenter = (file) => {
  const previewUrl = useMemo(() => {
    const payload = file.payload;
    const url =
      payload.payloadType === 'Video' ? payload.previewUrl : payload.url || payload.previewUrl;

    return getFixedSizeImage(url, { height: IMAGE_SIZE });
  }, [file]);

  const onSelectFile = useCallback(() => {
    Tasks.group.updateMediaGallery({
      groupId: file.message.groupId,
      messageId: file.message.id,
      mediaId: file.id,
    });
  }, [file]);

  return { previewUrl, onSelectFile };
};
