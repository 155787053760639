import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { SpaceCollectionListPlaceholder } from 'APP/packages/placeholders/SpaceCollectionListPlaceholder/SpaceCollectionListPlaceholder';
import { useTranslation } from 'APP/packages/translations';
import PlusIcon from 'ICONS/ic-plus.svg';
import DoneIcon from 'ICONS/ic-s-done-16.svg';
import { Space } from 'STORE/Spaces/Space';
import { Avatar, AvatarTypes } from 'UIKIT/Avatar/Avatar';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';
import { PopupLink } from 'UIKIT/PopupLink/PopupLink';

import {
  ISpaceCollectionData,
  useSpaceCollectionListPresenter,
} from './SpaceCollectionList.presenter';
import styles from './SpaceCollectionList.styles.m.css';

interface ISpaceCollectionListProps {
  space: Space;
  groupId: string;
  messageId: string;
  onCreateNewCollectionStep(): void;
  onSelectCollection(collection: ISpaceCollectionData): void;
}

export const SpaceCollectionList: FC<ISpaceCollectionListProps> = observer(
  ({ space, groupId, messageId, onCreateNewCollectionStep, onSelectCollection }) => {
    const { t } = useTranslation();
    const presenter = useSpaceCollectionListPresenter({ space, groupId, messageId });

    return (
      <div className={classNames(styles.container, customScrollStyles.container)}>
        <PopupLink
          className={styles.createButton}
          title={t('space_create_new_collection_button')}
          Icon={PlusIcon}
          onClick={onCreateNewCollectionStep}
        />

        {!presenter.isLoading && (
          <>
            {presenter.collections.map((collection) => (
              <div
                className={styles.collection}
                key={collection.id}
                role="button"
                tabIndex={0}
                onClick={(): void => onSelectCollection(collection)}
              >
                <div className={styles.avatar}>
                  <Avatar
                    type={AvatarTypes.Square_20}
                    url={collection.avatarUrl}
                  />

                  {!collection.canAddMessage && (
                    <div className={styles.badge}>
                      <DoneIcon className={styles.badgeIcon} />
                    </div>
                  )}
                </div>

                <span className={styles.name}>{collection.name}</span>
              </div>
            ))}
          </>
        )}

        {presenter.isLoading && <SpaceCollectionListPlaceholder className={styles.placeholders} />}
      </div>
    );
  }
);
