import classNames from 'classnames';
import React from 'react';

import { TextRenderer } from 'APP/components/TextRenderer/TextRenderer';

import { MiniatureMessageLayout } from '../components/MiniatureMessageLayout/MiniatureMessageLayout';
import styles from './styles.m.css';

const Video = ({ titleClassName, textClassName, message, title, onClick }) => {
  const { typeDescription, previewUrl, graphicSize, comment } = message.payload;

  return (
    <MiniatureMessageLayout
      isVideo
      titleClassName={titleClassName}
      textClassName={textClassName}
      imageUrl={previewUrl}
      graphicSize={graphicSize}
      title={title}
      text={typeDescription}
      messageId={message.id}
      onClick={onClick}
    >
      {comment && (
        <TextRenderer
          isPlainText
          className={classNames(styles.text, textClassName)}
        >
          {comment}
        </TextRenderer>
      )}
    </MiniatureMessageLayout>
  );
};

export default Video;
