import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { useTranslation } from 'APP/packages/translations';

import { ProviderLayoutProcessing } from '../../ProviderLayout/ProviderLayoutProcessing/ProviderLayoutProcessing';
import { useLinkTelegramProviderPresenter } from './LinkTelegramProvider.presenter';
import styles from './LinkTelegramProvider.styles.m.css';

interface LinkTelegramProviderProps {
  onProcessingChange(active: boolean): void;
}

export const LinkTelegramProvider: FC<LinkTelegramProviderProps> = observer(
  ({ onProcessingChange }) => {
    const { t } = useTranslation();

    const presenter = useLinkTelegramProviderPresenter({ onProcessingChange });

    if (presenter.isLoading) {
      return <ProviderLayoutProcessing />;
    }

    return (
      <a
        href={presenter.uri}
        target="_blank"
        rel="noreferrer"
        className={styles.link}
        onClick={presenter.runLinkAccount}
      >
        {t('account_link_account')}
      </a>
    );
  }
);
