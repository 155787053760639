import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { TextRenderer } from 'APP/components/TextRenderer/TextRenderer';

import Audio from './Audio';
import Contact from './Contact';
import File from './File';
import { Image } from './Image';
import Location from './Location';
import { TextPayload } from './TextPayload/TextPayload';
import { Video } from './Video';
import ArticleTranslate from './components/ArticleTranslate';
import { usePresenter } from './presenter';
import styles from './styles.m.scss';

const payloadComponent = {
  RichText: TextPayload,
  Image,
  Video,
  AudioMessage: Audio,
  Location,
  File,
  Contact,
};

const Payloads = ({ message, openMedia }) => {
  const presenter = usePresenter({ message });

  if (!message) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <div className={styles.date}>{presenter.date}</div>
      </div>

      <TextRenderer className={styles.title}>{presenter.title}</TextRenderer>
      <ArticleTranslate message={message} />

      <div className={styles.content}>
        {presenter.payloads.map((payload) => {
          const PayloadComponent = payloadComponent[payload.payload.payloadType] || null;

          return (
            <div
              className={classNames(styles.payload, styles[payload.payload.payloadType])}
              key={payload.id}
            >
              <PayloadComponent
                message={{ ...payload, messageId: message.id, groupId: message.groupId }}
                openMedia={openMedia}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default observer(Payloads);
