import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { useTranslation } from 'APP/packages/translations';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { ReportForm } from '../../components/ReportForm/ReportForm';
import { useClosePopupOnNavigation } from '../../hooks/useClosePopupOnNavigation';
import { IReportGroupPopupParams, useReportGroupPresenter } from './ReportGroupPopup.presenter';

export const ReportGroupPopup: FC<ICommonPopupsProps<IReportGroupPopupParams>> = observer(
  (props) => {
    const { t } = useTranslation();
    const presenter = useReportGroupPresenter(props);
    useClosePopupOnNavigation(props.popupInstance);

    return (
      <Popup
        title={t('common_report')}
        isOpened
        onClose={presenter.onClose}
      >
        <ReportForm
          title={
            presenter.group?.isChannel
              ? t('content_moderation_channel')
              : t('content_moderation_group')
          }
          onSubmit={presenter.onSubmit}
        />
      </Popup>
    );
  }
);
