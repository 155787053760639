import { hideNewsfeedPosts } from 'APP/model/catalog/catalogModel';
import Entities from 'STORE';
import { TPostInstance } from 'STORE/Newsfeed/Newsfeed.types';

export async function hideNewsfeedPost(newsItem: TPostInstance): Promise<boolean> {
  if (!newsItem.groupId) {
    return false;
  }

  const groupPosts = Entities.newsfeed.getPostsByGroupId(newsItem.groupId);

  try {
    groupPosts.forEach((post) => post.setVisibility(false));
    newsItem.setHiddenInfoVisibility(true);
    newsItem.setVisibility(true);

    const response = await hideNewsfeedPosts({ channelIds: [newsItem.groupId] });
    return response.result;
  } catch (error) {
    groupPosts.forEach((post) => post.setVisibility(true));
    newsItem.setHiddenInfoVisibility(false);

    return false;
  }
}
