import { useLocalStore, useAsObservableSource } from 'mobx-react';
import { useEffect } from 'react';

import { openPopup } from 'APP/Tasks/app/popup/popup';
import { ReactionsDetailedPopup } from 'MAIN/PopupManager/Popups/ReactionsDetailedPopup/ReactionsDetailedPopup';
import { useReactions } from 'MAIN/hooks/useReactions/useReactions';
import { IReactionItem } from 'MAIN/hooks/useReactions/useReactions.types';
import { Popup } from 'STORE/App/Popups/Popup/Popup';
import { Channel } from 'STORE/Groups/Channel';
import { P2P } from 'STORE/Groups/P2P';
import { Thread } from 'STORE/Groups/Thread';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

interface IReactionsPresenterParams {
  message: ChatMessage;
  isVisible: boolean;
}

interface IReactionsPresenter {
  childPopup: Popup | null;
  myReactionId: string | null;
  animatedReactionId: string | null;
  isShowAnimations: boolean;
  isShow: boolean;
  reactions: IReactionItem[];
  myReaction: IReactionItem | null;
  canBeShowPopover: boolean;
  onOpenReactionPopup(reaction: string): void;
}

export const useReactionsPresenter = (data: IReactionsPresenterParams): IReactionsPresenter => {
  const { message, isVisible } = data;

  const reactions = useReactions(message);
  const source = useAsObservableSource({ message });

  const presenter = useLocalStore<IReactionsPresenter>(() => ({
    childPopup: null,
    myReactionId: null,
    animatedReactionId: null,
    isShowAnimations: false,

    get isShow(): boolean {
      return reactions.hasReactions;
    },

    get reactions(): IReactionItem[] {
      return reactions.reactionsMessage;
    },

    get myReaction(): IReactionItem | null {
      return presenter.reactions.find((reaction) => reaction.hasMyReaction) || null;
    },

    get canBeShowPopover(): boolean {
      const group = source.message.group;

      if (!group || group.isFake) {
        return false;
      }

      if (group instanceof P2P) {
        return false;
      }

      if (group instanceof Channel && !group.withMeInAdmins) {
        return false;
      }

      if (group instanceof Thread) {
        if (group.parentGroup instanceof P2P) {
          return false;
        }

        if (group.parentGroup instanceof Channel && !group.parentGroup.withMeInAdmins) {
          return false;
        }
      }

      return true;
    },

    onOpenReactionPopup(reaction: string): void {
      presenter.childPopup = openPopup(ReactionsDetailedPopup, {
        params: {
          groupId: source.message.groupId,
          messageId: source.message.id,
          tab: reaction,
        },
      });
    },
  }));

  useEffect(() => {
    presenter.myReactionId = presenter.myReaction?.id || null;
  }, []);

  useEffect(() => {
    const newReactionId = presenter.myReaction?.id || null;
    if (presenter.myReactionId === newReactionId) {
      return;
    }

    presenter.myReactionId = newReactionId;
    presenter.animatedReactionId = newReactionId;
  }, [presenter.myReaction?.id]);

  useEffect(() => {
    presenter.isShowAnimations = presenter.isShowAnimations || isVisible;
  }, [isVisible]);

  useEffect(() => {
    return () => presenter.childPopup?.close();
  }, []);

  return presenter;
};
