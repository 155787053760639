import React from 'react';

import { IRenderer } from '../renderTree.types';

export const BoldRenderer: IRenderer = ({ children }) => {
  if (!children) {
    return null;
  }

  return <strong>{children}</strong>;
};
