import { useRef } from 'react';

import useActions from './useActions';
import useCommon from './useCommon';
import useHandleFocus from './useHandleFocus';
import useKeyboard from './useKeyboard';
import useList from './useList';

export default () => {
  const containerRef = useRef();

  const common = useCommon();
  const list = useList({ common });
  const focusHandler = useHandleFocus({ containerRef, list, common });
  const actions = useActions({ focusHandler, list, common });
  const keyboardHandler = useKeyboard({ focusHandler, actions });

  return { containerRef, common, list, focusHandler, actions, keyboardHandler };
};
