import { TFunction } from 'react-i18next';

import {
  TSystemMessageTextHandler,
  ISystemMessageText,
  ISystemMessageTextPresenter,
} from '../useSystemMessageText.types';

export const chatNewlyCreatedUserJoinedHandler: TSystemMessageTextHandler = (
  presenter: ISystemMessageTextPresenter,
  t: TFunction
): ISystemMessageText => {
  const result: ISystemMessageText = { html: '' };
  const name = presenter.contactName || presenter.initiatorDisplayName;

  result.html = t('phonebook_user_joined_gem4me', {
    0: `<span class="system-message-title">${name}</span>`,
    interpolation: { escapeValue: false },
  });
  result.sourceText = t('phonebook_user_joined_gem4me');
  result.params = {
    '{0}': { text: name, userId: presenter.initiator?.id },
  };

  return result;
};
