import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Tasks from 'APP/Tasks';
import { openPopup } from 'APP/Tasks/app/popup/popup';
import { DeepLinkType } from 'APP/model/deeplinks/deeplinksModel.types';
import { RouterParams, RouterState } from 'APP/router/constants';
import useClearGetParameters from 'APP/router/hooks/useClearGetParameters';
import useGetParameter from 'APP/router/hooks/useGetParameter';
import { StickerPackPopup } from 'MAIN/PopupManager/Popups/StickerPackPopup/StickerPackPopup';
import Entities from 'STORE';

interface IDeepLinkHandlerPresenter {
  finishDeepLinkHandling(): void;
}

export async function useDeepLinkHandlerPresenter({
  finishDeepLinkHandling,
}: IDeepLinkHandlerPresenter): Promise<void> {
  const history = useHistory();

  const isDeepLink = useGetParameter(RouterParams.deepLink);
  // Todo Remove "as" and add generic with type when the useGetParameter hook will be refactored
  const deepLinkType = useGetParameter(RouterParams.type) as DeepLinkType;
  const entityId = useGetParameter(RouterParams.entityId) as string;
  const parentId = useGetParameter(RouterParams.parentId) as string | undefined;
  const expired = useGetParameter(RouterParams.expired);

  const clearGetParameters = useClearGetParameters([
    RouterParams.deepLink,
    RouterParams.type,
    RouterParams.entityId,
    RouterParams.parentId,
    RouterParams.expired,
    RouterParams.branchMatchId,
    RouterParams.branchReferrer,
    RouterParams.source,
  ]);

  const handleDeepLink = useCallback(async (): Promise<void> => {
    if (!isDeepLink) {
      finishDeepLinkHandling();
      return;
    }

    const params = await Tasks.deeplinks.getParamsByDeepLink({
      deepLink: {
        entityId,
        parentId,
        type: deepLinkType,
        expired: Boolean(expired),
      },
    });

    if (!params) {
      return;
    }

    const {
      groupRoute,
      messageId,
      stickerPackId,
      groupId,
      spaceId,
      userId,
      isAlreadyJoined,
      spaceRoute,
    } = params;

    if (userId) {
      const me = Entities.UsersStore.Me;
      if (me?.id === userId) {
        await Tasks.group.goToSavedMessages();
      } else {
        await Tasks.group.createP2PGroup(userId);
      }

      finishDeepLinkHandling();
      return;
    }

    if (stickerPackId) {
      openPopup(StickerPackPopup, { params: { stickerPackId } });

      finishDeepLinkHandling();
      return;
    }

    if (spaceRoute) {
      history.replace({ pathname: spaceRoute, state: { [RouterState.fromDeepLink]: true } });
      finishDeepLinkHandling();
      return;
    }

    if (!groupRoute) {
      finishDeepLinkHandling();
      return;
    }

    if (groupRoute && isAlreadyJoined && groupId) {
      Tasks.calls.findGroupCallAndRun(groupId);
    }

    if (groupRoute && !isAlreadyJoined && groupId) {
      const group = Entities.GroupsStore.getGroupById(groupId);
      group?.setIsAwaitCall(true);
    }

    await Tasks.group.setActiveGroup({ groupId, state: { [RouterState.fromDeepLink]: true } });
    history.replace({ pathname: groupRoute });

    if (messageId) {
      await Tasks.messaging.focusMessage({ groupId, messageId });
    }

    if (spaceId) {
      await Tasks.space.setActiveSpace(spaceId);
    }

    finishDeepLinkHandling();
  }, []);

  useEffect(() => {
    handleDeepLink();
    if (isDeepLink) {
      clearGetParameters();
    }
  }, []);
}
