import Tasks from 'APP/Tasks';
import { ALERT_TYPES } from 'APP/constants/app';
import * as authModel from 'APP/model/auth/authModel';
import { AuthProvider } from 'APP/model/auth/authModel.types';
import { ServerErrorCode } from 'APP/packages/api';
import Entities from 'STORE';

export const unlinkAccount = async (authProviderId: AuthProvider): Promise<any> => {
  try {
    await authModel.unlinkAccount({ authProviderId });

    Entities.UsersStore.Me.removeAccountProvider(authProviderId);
  } catch (error: any) {
    if (error.message === ServerErrorCode.AccountAlreadyUnlinked) {
      Tasks.app.addAlert({ type: ALERT_TYPES.ACCOUNT_ALREADY_UNLINKED });
    } else if (error.message === ServerErrorCode.AccountHasNoPermissions) {
      Tasks.app.addAlert({ type: ALERT_TYPES.ACCOUNT_UNLINK_ALERT });
    } else {
      Tasks.app.addAlert({ type: ALERT_TYPES.SOMETHING_WRONG_ERROR });
    }
  }
};
