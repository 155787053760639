import Tasks from 'APP/Tasks';
import api from 'APP/packages/api';
import logger from 'APP/packages/logger';
import Entities from 'STORE';
import { Group } from 'STORE/Groups/Group';

export const loadNewCallGroup = async (id: string): Promise<Group | null> => {
  try {
    const response = await api.group.getGroupsByIds({ groupIds: [id] });

    if (response?.items?.[0]) {
      Entities.GroupsStore.merge([response.items[0]]);
      await Tasks.relationships.loadUnknownDataFromGroups({
        groups: [Entities.GroupsStore.getGroupById(id)],
      });
    }

    return Entities.GroupsStore.getGroupById(id);
  } catch (error) {
    logger.get('API').error('group.loadNewCallGroup', error);

    return null;
  }
};
