import { useRef } from 'react';

import useCommon from './common';
import useFocus from './focus';
import useSubmit from './submit';

export default () => {
  const containerRef = useRef();

  const common = useCommon();
  const focus = useFocus({ containerRef, common });
  const submit = useSubmit({ common, focus });

  return {
    containerRef,
    common,
    focus,
    submit,
  };
};
