import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { useTranslation } from 'APP/packages/translations';
import AllTalksIcon from 'ICONS/ic-all-talks.svg';
import CatalogIcon from 'ICONS/ic-apps.svg';
import ChannelIcon from 'ICONS/ic-channel.svg';
import ChatIcon from 'ICONS/ic-comments.svg';
import HubIcon from 'ICONS/ic-jewerly.svg';
import NewsfeedIcon from 'ICONS/ic-news.svg';
import SpacesIcon from 'ICONS/ic-space.svg';
import LogoIcon from 'ICONS/logo.svg';

import { GroupBadge } from './GroupBadge/GroupBadge';
import { GroupBadgeType } from './GroupBadge/GroupBadge.constants';
import { NavigationTab } from './NavigationTab/NavigationTab';
import {
  useSidebarPanelNavigationPresenter,
  ISidebarPanelNavigationPresenterParams,
} from './SidebarPanelNavigation.presenter';
import styles from './SidebarPanelNavigation.styles.m.css';
import { SpaceBadge } from './SpaceBadge/SpaceBadge';

type ISidebarPanelNavigationProps = ISidebarPanelNavigationPresenterParams;

interface IGroupBadgeProps {
  className?: string;
}

const ChatsBadge: FC<IGroupBadgeProps> = ({ className }) => (
  <GroupBadge
    type={GroupBadgeType.Chat}
    className={className}
  ></GroupBadge>
);

const ChannelsBadge: FC<IGroupBadgeProps> = ({ className }) => (
  <GroupBadge
    type={GroupBadgeType.Channel}
    className={className}
  ></GroupBadge>
);

export const SidebarPanelNavigation: FC<ISidebarPanelNavigationProps> = observer((props) => {
  const presenter = useSidebarPanelNavigationPresenter(props);
  const { t } = useTranslation();

  return (
    <div
      className={styles.container}
      data-test="groups-navigation"
    >
      <div
        data-test="logo"
        className={styles.logo}
      >
        <LogoIcon />
      </div>

      <div className={styles.content}>
        <div className={styles.contentGroup}>
          <NavigationTab
            Icon={SpacesIcon}
            Badge={SpaceBadge}
            label={t('space_spaces')}
            isActive={presenter.isActiveSpaces}
            dataTest="spaces"
            onClick={presenter.setActiveSpaces}
          />

          <NavigationTab
            Icon={AllTalksIcon}
            label={t('chat_details_pending_filter_all')}
            isActive={presenter.isActiveAllGroups}
            dataTest="all_groups"
            onClick={presenter.setActiveAllGroups}
          />

          <NavigationTab
            Icon={ChatIcon}
            Badge={ChatsBadge}
            label={t('chats')}
            isActive={presenter.isActiveChat}
            dataTest="chats"
            onClick={presenter.setActiveChats}
          />

          <NavigationTab
            Icon={ChannelIcon}
            Badge={ChannelsBadge}
            label={t('channels')}
            isActive={presenter.isActiveChannels}
            dataTest="channels"
            onClick={presenter.setActiveChannels}
          />
        </div>

        <div className={styles.contentGroup}>
          {presenter.isShowMarketplace && (
            <NavigationTab
              Icon={HubIcon}
              label={t('common_hub')}
              onClick={presenter.openHub}
            />
          )}

          <NavigationTab
            Icon={NewsfeedIcon}
            label={t('bottom_tabs_newsfeed')}
            onClick={presenter.openNewsfeed}
          />

          <NavigationTab
            Icon={CatalogIcon}
            label={t('statistics_catalog')}
            onClick={presenter.openCatalog}
          />
        </div>
      </div>
    </div>
  );
});
