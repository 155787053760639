import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { GroupAvatar } from 'APP/components/Avatar/GroupAvatar/GroupAvatar';
import { SpaceAvatar } from 'APP/components/Avatar/SpaceAvatar/SpaceAvatar';
import { VerifiedBadge } from 'APP/components/VerifiedBadge/VerifiedBadge';
import NoResultImage from 'APP/images/gem-mascot-not-found.svg';
import { useTranslation } from 'APP/packages/translations';
import { Group } from 'STORE/Groups/Group';
import { Space } from 'STORE/Spaces/Space';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';
import { Checkbox } from 'UIKIT/Checkbox';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';
import { InfoBlock, InfoBlockTypes } from 'UIKIT/InfoBlock/InfoBlock';
import { getFormatNumberToString } from 'UTILS/getFormatNumberToString';

import styles from './MoveGroupsToSpaceList.styles.m.css';

interface IMoveGroupsToSpaceListProps {
  space: Space;
  groups: Group[];
  selectedGroups: Set<string>;
  hasSearch: boolean;
  onToggleGroup(groupId: string): void;
}

export const MoveGroupsToSpaceList: FC<IMoveGroupsToSpaceListProps> = observer(
  ({ space, groups, selectedGroups, hasSearch, onToggleGroup }) => {
    const { t } = useTranslation();

    if (!groups.length) {
      return (
        <InfoBlock
          className={classNames(styles.empty, customScrollStyles.container)}
          type={InfoBlockTypes.Modal}
          descriptions={[hasSearch ? t('search_no_results') : t('space_move_alert_list')]}
          image={NoResultImage}
        />
      );
    }

    return (
      <div className={classNames(styles.list, customScrollStyles.container)}>
        {groups.map((group) => (
          <label
            className={styles.listItem}
            key={group.id}
          >
            <div className={styles.avatar}>
              {group.isChannel ? (
                <GroupAvatar
                  group={group}
                  type={AvatarTypes.Square_20}
                  isShowOnlineIndicator
                  hasLockIcon={group.isClosed}
                />
              ) : (
                <GroupAvatar
                  group={group}
                  isShowOnlineIndicator
                />
              )}
              {group.space && (
                <SpaceAvatar
                  className={styles.spaceAvatar}
                  space={group.space}
                  type={AvatarTypes.Square_0}
                  isShowLockIcon={false}
                ></SpaceAvatar>
              )}
            </div>

            <div className={styles.info}>
              <div className={styles.nameWrapper}>
                <h3 className={styles.name}>{group.name}</h3>
                {group.verified && <VerifiedBadge />}
              </div>

              <span className={styles.membersCount}>
                {(group.isChannel
                  ? t('amount_of_subscribers_in_a_channel_many')
                  : t('amount_of_members_in_a_group_many')
                ).replace('{0}', getFormatNumberToString(group.usersCount))}
              </span>
            </div>

            <Checkbox
              isChecked={selectedGroups.has(group.id)}
              onChange={(): void => onToggleGroup(group.id)}
            />
          </label>
        ))}
      </div>
    );
  }
);
