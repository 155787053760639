import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Popup as PopupComponent } from 'APP/components/Popup/Popup';
import { useTranslation } from 'APP/packages/translations';
import { IShareSelection } from 'APP/types/share';
import CopyLinkImage from 'ICONS/ic-link-16.svg';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import { useCopyToClipboard } from 'MAIN/Profile/hooks/useCopyToClipboard';
import { Button, ButtonTypes } from 'UIKIT/Button/Button';
import { PopupFooter } from 'UIKIT/PopupFooter/PopupFooter';

import { ShareDataView } from '../../components/ShareDataView/ShareDataView';
import { useButtonsShareCardPresenter } from './ShareCardPopup.presenter';
import styles from './ShareCardPopup.styles.m.css';

export interface IShareCardPopupParams {
  cardDeepLink: string;
  onSubmit(selection: IShareSelection[]): Promise<void>;
}

export const ShareCardPopup: FC<ICommonPopupsProps<IShareCardPopupParams>> = observer((props) => {
  const { params, popupInstance } = props;
  const { cardDeepLink, onSubmit } = params!;
  const { t } = useTranslation();
  const copyToClipboard = useCopyToClipboard(cardDeepLink, t('common_link_copied'));

  const presenter = useButtonsShareCardPresenter({ onSubmit });

  return (
    <PopupComponent
      isOpened
      isHidden={popupInstance.isHidden}
      onClose={popupInstance.close}
      title={t('card_share_with')}
    >
      <ShareDataView
        multiselect
        onChangeSelection={presenter.onSelect}
      />
      <PopupFooter className={classNames(styles.footer)}>
        {cardDeepLink && (
          <button
            className={styles.copyLink}
            onClick={copyToClipboard}
          >
            <CopyLinkImage className={styles.copyLinkIcon} />
            <span className={styles.copyLinkText}>{t('common_copy_link')}</span>
          </button>
        )}
        <div className={styles.submitBtn}>
          <Button
            type={ButtonTypes.Submit}
            isDisabled={!presenter.hasSelected}
            isLoading={presenter.isSubmitting}
            onClick={presenter.submit}
            theme="1"
          >
            {t('send')}
          </Button>
        </div>
      </PopupFooter>
    </PopupComponent>
  );
});
