import { IAbortConfirmationRequest } from 'APP/model/auth/authModel.types';
import { authModel } from 'APP/model/model';

export const abortConfirmation = async (
  data: IAbortConfirmationRequest
): Promise<{ error: string | null }> => {
  try {
    await authModel.abortConfirmation(data);
    return { error: null };
  } catch (error) {
    return { error: error.message };
  }
};
