/* global Date */
import isValidDate from './isValidDate';
import { now } from './now';

export default (rawCompareDate) => {
  const compareDate = new Date(rawCompareDate);

  if (!isValidDate(compareDate)) {
    return false;
  }

  const nowDate = now();

  const lastWeek = new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate() - 7);

  return compareDate.getTime() > lastWeek.getTime();
};
