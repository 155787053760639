import { action, reaction } from 'mobx';
import { IReactionDisposer } from 'mobx/lib/internal';

import { Call } from '../../Call';

export class HandleUpdateResolution {
  call: Call;
  disposeReactions: IReactionDisposer[] = [];

  constructor(call: Call) {
    this.call = call;

    this.disposeReactions = [
      reaction(() => call.opponents.opponentsWithVideo.length, this.updateResolution.bind(this)),
      reaction(() => call.activeOpponentUid, this.updateResolution.bind(this)),
    ];
  }

  dispose(): void {
    this.disposeReactions.forEach((dispose) => {
      dispose();
    });
  }

  @action
  updateResolution(): void {
    const resolution = this.call.opponents.opponentsWithVideo.length > 1 ? 1 : 0;

    this.call.opponents.opponentsWithVideo.forEach((opponent) => {
      opponent.changeVideoStreamQuality(
        opponent.uid !== this.call.activeOpponentUid && Boolean(resolution)
      );
    });
  }
}
