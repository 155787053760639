import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { openPopup } from 'APP/Tasks/app/popup/popup';
import Entities from 'APP/store';
import { CreateSpaceChannelPopup } from 'MAIN/PopupManager/Popups/CreateSpaceChannelPopup/CreateSpaceChannelPopup';
import { Popup } from 'STORE/App/Popups/Popup/Popup';
import { Newsfeed } from 'STORE/Newsfeed/Newsfeed';
import { Space } from 'STORE/Spaces/Space';
import { CancelPromiseHandler, makeCancellablePromise } from 'UTILS/makeCancellablePromise';

const PRELOAD_POSITION = 5;

export interface ISpaceNewsfeedPresenter {
  childPopup: Popup | null;
  newsfeed: Newsfeed;
  isLoading: boolean;
  cursor: string | null;
  isShowGuestProfile: boolean;
  cancelLoad: CancelPromiseHandler | null;
  readonly isEmpty: boolean;
  readonly isShowPlaceholder: boolean;
  readonly isShowEmptyChannels: boolean;
  readonly isShowEmptyPosts: boolean;
  readonly isShowEmptyInfo: boolean;
  readonly hasBackgroundImage: boolean;
  hasMoreNews: boolean;
  load(): Promise<void>;
  onReload(): void;
  onSetNewsVisibility(position: number, isView: boolean): void;
  checkAndLoadMore(position: number): void;
  setViewMessage(position: number, isView: boolean): void;
  onOpenChannelCreate(): void;
  onOpenChannel(): void;
}

export const useSpaceNewsfeedPresenter = (space: Space | null): ISpaceNewsfeedPresenter => {
  const source = useAsObservableSource({ space });

  const presenter = useLocalStore<ISpaceNewsfeedPresenter>(() => ({
    childPopup: null,
    newsfeed: new Newsfeed(),
    isLoading: false,
    cursor: null,
    hasMoreNews: true,
    isShowGuestProfile: false,
    cancelLoad: null,

    async load(): Promise<void> {
      presenter.isLoading = true;

      const { promise: loadNewsfeed, cancel } = makeCancellablePromise(
        Tasks.newsfeed.loadNewsfeed({
          spaceId: source.space?.id,
          cursor: presenter.cursor,
        })
      );

      presenter.cancelLoad = cancel;

      const newsResponse = await loadNewsfeed;

      if (newsResponse) {
        presenter.newsfeed.addNews(newsResponse.news);
        presenter.cursor = newsResponse.cursor;
      }

      if (!presenter.cursor) {
        presenter.hasMoreNews = false;
      }

      presenter.cancelLoad = null;
      presenter.isLoading = false;
    },

    onReload(): void {
      presenter.newsfeed.reset();
      presenter.cursor = null;
      presenter.cancelLoad?.();
      presenter.load();
    },

    get hasBackgroundImage(): boolean {
      return Entities.appearance.hasChatBackground;
    },

    onSetNewsVisibility(position: number, isView: boolean): void {
      if (isView) {
        presenter.checkAndLoadMore(position);
      }
      presenter.setViewMessage(position, isView);
    },

    setViewMessage(position: number, isView: boolean): void {
      const news = presenter.newsfeed.getPostByPosition(position);
      if (news) {
        isView ? Tasks.messaging.inViewMessage(news) : Tasks.messaging.outViewMessage(news);
      }
    },

    checkAndLoadMore(position: number): void {
      const targetPosition = presenter.newsfeed.news.length - PRELOAD_POSITION;

      if (!presenter.isLoading && this.cursor && position >= targetPosition) {
        presenter.load();
      }
    },

    get isEmpty(): boolean {
      return !presenter.newsfeed.news.length;
    },

    get isShowPlaceholder(): boolean {
      return presenter.isLoading && presenter.isEmpty;
    },

    get isShowEmptyChannels(): boolean {
      const spaceId = source.space?.id;

      return !!(
        !presenter.isLoading &&
        spaceId &&
        source.space?.isOwner &&
        !Entities.GroupsStore.channels.some((channel) => channel.spaceId === spaceId)
      );
    },

    onOpenChannelCreate(): void {
      if (!source.space) {
        return;
      }

      presenter.childPopup = openPopup(CreateSpaceChannelPopup, {
        params: { spaceId: source.space.id },
      });
    },

    get isShowEmptyPosts(): boolean {
      return (
        this.isShowEmptyInfo &&
        !!source.space?.isOwner &&
        Entities.GroupsStore.channels.filter((channel) => channel.spaceId === source.space?.id)
          .length === 1
      );
    },

    onOpenChannel(): void {
      const channel = Entities.GroupsStore.channels.find(
        (channel) => channel.spaceId === source.space?.id
      );

      channel && Tasks.group.setActiveGroup({ groupId: channel.id });
    },

    get isShowEmptyInfo(): boolean {
      return this.isEmpty && !this.isLoading;
    },
  }));

  useEffect(() => {
    if (space) {
      presenter.onReload();
    }
  }, [space]);

  useEffect(() => {
    if (space?.isGuest) {
      presenter.isShowGuestProfile = true;
    }
  }, [space?.id]);

  useEffect(() => {
    return () => presenter.childPopup?.close();
  }, []);

  return presenter;
};
