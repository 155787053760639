import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { useTranslation } from 'APP/packages/translations';
import BackIcon from 'ICONS/ic-back-32.svg';
import { CloseButton } from 'MAIN/OngoingCall/Shared/CloseButton/CloseButton';
import Entities from 'STORE';
import { Call } from 'STORE/Calls/Call/Call';

import styles from './MembersPanelHeader.styles.m.css';

interface IMembersPanelHeaderProps {
  call: Call;
}

export const MembersPanelHeader: FC<IMembersPanelHeaderProps> = observer(({ call }) => {
  const { t } = useTranslation();
  const OngoingCall = Entities.OngoingCall;
  const membersQuantity = t('calls_users_on_call', {
    0: call.members.memberOnCallList.length,
    1: call.members.memberList.length,
  });

  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <CloseButton
          Icon={BackIcon}
          onClick={OngoingCall.members.hideMembers}
        />
      </div>
      <div className={styles.content}>
        <div className={styles.title}>{t('common_participants')}</div>
        <div className={styles.description}>{membersQuantity}</div>
      </div>
    </div>
  );
});
