import Tasks from 'APP/Tasks';
import { UpdateReason } from 'APP/constants/scroll';
import { mapCounterResponse } from 'APP/model/counter/counterModel.mapper';
import api from 'APP/packages/api';
import logger from 'APP/packages/logger';
import Entities from 'APP/store';

export default async ({ messages, groupId }) => {
  try {
    const messagesWithThread = messages.reduce((acc, message) => {
      if (message?.threadId) {
        acc.push(message);
      }
      return acc;
    }, []);
    const messageIds = messagesWithThread.map(({ id }) => id);
    if (!messageIds.length) {
      return;
    }
    const threadIds = messagesWithThread
      .map(({ threadId }) => threadId)
      .filter((id) => !Entities.GroupsStore.getGroupById(id));

    const requests = [api.group.getThreadsInfo({ messageIds, groupId })];
    if (threadIds.length) {
      requests.push(Tasks.group.loadGroupsByIds(threadIds));
    }

    const [threadsResponse] = await Promise.all(requests);
    if (threadsResponse && threadsResponse.items) {
      const counters = threadsResponse.items.reduce(
        (acc, { groupCounter, messageId, groupId, threadId }) => {
          const group = Entities.GroupsStore.getGroupById(threadId);
          if (group) {
            acc.push(groupCounter);
            group.setThreadInfo({ messageId, groupId });
          }
          return acc;
        },
        []
      );
      //Todo remove mapCounterResponse after migrate api.group.getThreadsInfo in model layer
      await Tasks.group.addCounters(counters.map(mapCounterResponse));

      if (groupId === Entities.GroupsStore.activeGroupId) {
        Entities.ChatStore.setScrollChanged(UpdateReason.EditMessage, {
          messageIds,
          isAnimated: true,
        });
      }
    }
  } catch (error) {
    logger.get('API').error('group.getThreadsInfo', error);
  }
  return null;
};
