import { observer } from 'mobx-react';
import React, { FC } from 'react';

import NoResultsImage from 'APP/images/gem-mascot-not-found.svg';
import { useTranslation } from 'APP/packages/translations';
import { NewsfeedContent } from 'MAIN/components/NewsfeedContent/NewsfeedContent';
import { usePostMenuItems } from 'MAIN/components/NewsfeedContent/NewsfeedPostItem/PostContextMenu/usePostMenuItems';
import { InfoBlock, InfoBlockTypes } from 'UIKIT/InfoBlock/InfoBlock';

import {
  useSpaceCollectionItemsPresenter,
  ISpaceCollectionItemsPresenterParams,
} from './SpaceCollectionItems.presenter';
import styles from './SpaceCollectionItems.styles.m.css';

type ISpaceCollectionItemsProps = ISpaceCollectionItemsPresenterParams;

export const SpaceCollectionItems: FC<ISpaceCollectionItemsProps> = observer((props) => {
  const { t } = useTranslation();
  const presenter = useSpaceCollectionItemsPresenter(props);
  const menuItems = usePostMenuItems({
    isShowHide: false,
    isShowRemoveCollectionItem: props.space.isOwner,
    onRemoveCollectionItem: presenter.onRemoveCollectionItem,
  });

  if (!presenter.hasMoreItems && presenter.isEmpty) {
    return (
      <InfoBlock
        className={styles.noResults}
        type={presenter.hasBackgroundImage ? InfoBlockTypes.ChatWithPattern : InfoBlockTypes.Chat}
        title={t('space_collection_no_posts_title')}
        descriptions={[t('space_collection_no_posts_subtitle')]}
        image={NoResultsImage}
      />
    );
  }

  return (
    <NewsfeedContent
      news={presenter.collectionItems.news}
      isShowPlaceholder={presenter.isShowPlaceholder}
      isShowLoader={presenter.isLoading}
      hasBackgroundImage={presenter.hasBackgroundImage}
      hasMoreNews={presenter.hasMoreItems}
      onReloadNewsfeed={presenter.onReload}
      onSetNewsVisibility={presenter.onSetNewsVisibility}
      getMenuItems={menuItems.getMenuItems}
    />
  );
});
