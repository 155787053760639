import { action, computed, observable, ObservableMap, values } from 'mobx';

import { IDownloadProgress } from 'APP/store/Messages/DownloadProgress/DownloadProgress.types';

export class DownloadProgress {
  @observable data = new ObservableMap<string, IDownloadProgress>();

  get(payloadId: string): IDownloadProgress | null {
    return this.data.get(payloadId) || null;
  }

  getDownloadsByMessageId(messageId: string): ReadonlyArray<IDownloadProgress> {
    return values(this.data).filter((downloadProgress) => downloadProgress.messageId === messageId);
  }

  @action
  update(payloadId: string, data: Partial<IDownloadProgress>): void {
    this.data.set(payloadId, {
      ...(this.data.get(payloadId) as IDownloadProgress),
      ...data,
    });
  }

  @action
  setProgressEvent(payloadId: string, progressEvent: ProgressEvent): void {
    const loaded = progressEvent?.loaded || 0;
    const total = progressEvent?.total || 0;
    const downloadPercent = (loaded / total) * 100;

    this.update(payloadId, { progressEvent, downloadPercent });

    if (downloadPercent === 100) {
      this.update(payloadId, {
        completeDownloadTs: Date.now(),
        xhr: undefined,
      });
    }
  }

  @action
  remove(payloadId: string): void {
    const downloadProgress = this.get(payloadId);

    downloadProgress?.xhr?.abort();

    this.data.delete(payloadId);
  }

  @computed
  get downloads(): readonly IDownloadProgress[] {
    return values(this.data);
  }
}
