import { EventType, IGroupEvent } from 'APP/model/counter/counterModel.types';
import logger from 'APP/packages/logger';

export class GroupsEvent<TData = unknown> {
  eventPayload: TData | undefined;
  groupId: string;
  type: EventType;
  ts: number;

  constructor(data: IGroupEvent<TData>) {
    this.eventPayload = data.eventPayload;
    this.groupId = data.groupId;
    this.type = data.type;
    this.ts = data.ts;
  }

  process(): void {
    logger
      .get('GROUP_EVENTS')
      .error('Unknown event type', { type: this.type, groupId: this.groupId });
  }
}
