import { useAsObservableSource, useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { useTranslation } from 'APP/packages/translations';
import CloseIcon from 'ICONS/ic-close.svg';
import { useMoveGroupToSpace } from 'MAIN/hooks/space/useMoveGroupToSpace';
import Entities from 'STORE';
import { Group } from 'STORE/Groups/Group';
import { Space } from 'STORE/Spaces/Space';
import { IPopoverMenuItem } from 'UIKIT/PopoverMenu/PopoverMenu.types';

export interface ISpaceGroupAvailableItemPresenter {
  space: Space | null;
  menuItems: IPopoverMenuItem[];
  onJoin(): Promise<void>;
  onClick(): Promise<void>;
}

export function useSpaceGroupAvailableItemPresenter(
  group: Group
): ISpaceGroupAvailableItemPresenter {
  const { t } = useTranslation();
  const source = useAsObservableSource({ group });
  const moveGroupToSpace = useMoveGroupToSpace(group.id);

  const presenter = useLocalStore<ISpaceGroupAvailableItemPresenter>(() => ({
    get space(): Space | null {
      return source.group.spaceId ? Entities.spacesStore.getById(source.group.spaceId) : null;
    },

    get menuItems(): IPopoverMenuItem[] {
      return [
        {
          key: 'removeFromSpace',
          title: t('space_remove_from_button', { 0: presenter.space?.name }),
          icon: CloseIcon,
          isVisible: moveGroupToSpace.canRemoveFromSpace,
          onClick: moveGroupToSpace.removeFromSpace,
        },
      ].filter((item) => item.isVisible);
    },

    async onJoin(): Promise<void> {
      presenter.space?.setJoinProcessing(true);

      const joinResult = await Tasks.group.joinGroup({ groupId: source.group.id });
      if (!joinResult) {
        Entities.toast.show(t('common_somethings_wrong_error'));
      }

      presenter.space?.setJoinProcessing(false);
    },

    async onClick(): Promise<void> {
      if (source.group.isOpen) {
        return await Tasks.group.setActiveGroup({ groupId: source.group.id });
      }

      const result = await Tasks.app.showConfirm({
        text: source.group.isChannel
          ? t('space_owner_join_private_channel_alert')
          : t('space_owner_join_private_group_alert'),
        primaryText: t('common_yes'),
        secondaryText: t('common_cancel'),
      });

      if (result) {
        await presenter.onJoin();
      }
    },
  }));

  return presenter;
}
