import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import {
  IGroupFormInitialValues,
  TSubmitHandler,
  TFormUpdateHandler,
  TFormSubmitHandler,
} from './GroupForm.types';

enum GroupFormStep {
  Members = 'members',
  GeneralInfo = 'general-info',
}

interface IGroupFormPresenterParams {
  initialValues: IGroupFormInitialValues;
  isShowMembers: boolean;
  onSubmit: TSubmitHandler;
}

interface IGroupFormPresenter {
  step: GroupFormStep;
  membersIds: string[];
  groupData: IGroupFormInitialValues;
  isMembers: boolean;
  isGeneralInfo: boolean;
  isShowMembers: boolean;
  isShowGeneralInfo: boolean;
  isShowPrevStep: boolean;
  onSetMembersIds(membersIds: string[]): void;
  onShowMembers(): void;
  onShowGeneralInfo(): void;
  onUpdateGroupData: TFormUpdateHandler;
  onSubmit: TFormSubmitHandler;
}

export const useGroupFormPresenter = (data: IGroupFormPresenterParams): IGroupFormPresenter => {
  const { initialValues, isShowMembers, onSubmit } = data;
  const source = useAsObservableSource({ isShowMembers, onSubmit });

  const presenter = useLocalStore<IGroupFormPresenter>(() => ({
    step: isShowMembers ? GroupFormStep.Members : GroupFormStep.GeneralInfo,
    membersIds: [],
    groupData: initialValues,

    get isMembers(): boolean {
      return presenter.step === GroupFormStep.Members;
    },

    get isGeneralInfo(): boolean {
      return presenter.step === GroupFormStep.GeneralInfo;
    },

    get isShowMembers(): boolean {
      return source.isShowMembers && presenter.isMembers;
    },

    get isShowGeneralInfo(): boolean {
      return !source.isShowMembers || presenter.isGeneralInfo;
    },

    get isShowPrevStep(): boolean {
      return source.isShowMembers && presenter.isShowGeneralInfo;
    },

    onSetMembersIds(membersIds: string[]): void {
      presenter.membersIds = membersIds;
    },

    onShowMembers(): void {
      presenter.step = GroupFormStep.Members;
    },

    onShowGeneralInfo(): void {
      presenter.step = GroupFormStep.GeneralInfo;
    },

    onUpdateGroupData({ values }): void {
      presenter.groupData = values;
    },

    async onSubmit(groupData: IGroupFormInitialValues): Promise<void> {
      await source.onSubmit({
        ...groupData,
        members: [...presenter.membersIds],
      });
    },
  }));

  useEffect(() => {
    presenter.groupData = initialValues;
  }, [initialValues]);

  return presenter;
};
