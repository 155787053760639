import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Entities from 'APP/store';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import { IUserProfileInitiatorSource } from 'MAIN/Profile/UserProfile/UserProfile.presenter';
import { User } from 'STORE/Users/User/User';

export interface IUserProfilePopupParams {
  userId: string;
  initiatorSource?: IUserProfileInitiatorSource;
}

interface IUserProfilePopupPresenter {
  groupId: string;
  user: User | null;
  initialActiveGroup: string | null;
  isActiveGroupChanged: boolean;
  onClose(): void;
}

export function useUserProfilePopupPresenter({
  params,
  popupInstance,
}: ICommonPopupsProps<IUserProfilePopupParams>): IUserProfilePopupPresenter {
  const { userId } = params!;
  const presenter = useLocalStore<IUserProfilePopupPresenter>(() => ({
    initialActiveGroup: Entities.GroupsStore.activeGroupId,

    get user(): User | null {
      return Entities.UsersStore.getUserById(userId);
    },

    get groupId(): string {
      return presenter.user?.group?.id || '';
    },

    get isActiveGroupChanged(): boolean {
      return presenter.initialActiveGroup !== Entities.GroupsStore.activeGroupId;
    },

    onClose(): void {
      popupInstance.close();
    },
  }));

  if (!presenter.user) {
    presenter.onClose();
  }

  useEffect(() => {
    if (presenter.isActiveGroupChanged) {
      presenter.onClose();
    }
  }, [presenter.isActiveGroupChanged]);

  return presenter;
}
