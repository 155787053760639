import classNames from 'classnames';
import React, { FC } from 'react';

import {
  useRoundVideoPlayerProgressPresenter,
  IRoundVideoPlayerProgressParams,
} from './RoundVideoPlayerProgress.presenter';
import styles from './RoundVideoPlayerProgress.styles.m.css';

interface IRoundVideoPlayerProgressProps extends IRoundVideoPlayerProgressParams {
  className?: string;
}

export const RoundVideoPlayerProgress: FC<IRoundVideoPlayerProgressProps> = (props) => {
  const { className } = props;
  const presenter = useRoundVideoPlayerProgressPresenter(props);

  const progressClassName = classNames(styles.progress, {
    [styles.animated]: presenter.isAnimatedProgress,
  });

  return (
    <svg
      className={classNames(styles.container, className)}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0,0,300,300"
    >
      <circle
        className={progressClassName}
        cx="150"
        cy="150"
        r={presenter.progressRadius}
        strokeDasharray={presenter.progressLength}
        strokeDashoffset={presenter.progressOffset}
      />
    </svg>
  );
};
