import { useLocalStore } from 'mobx-react';

import { IStickerPackExtend } from 'APP/model/stickers/stickersModel.types';
import Entities from 'APP/store';
import { sortStickers } from 'UTILS/sortStickers';

interface IContentPresenter {
  packs: IStickerPackExtend[];
}

export function useStickersSettingsContent(): IContentPresenter {
  const presenter = useLocalStore<IContentPresenter>(() => ({
    get packs(): IStickerPackExtend[] {
      return Entities.stickersStore.packs
        .filter(({ stickerPack }) => {
          return (
            !stickerPack.isPrivate ||
            Entities.stickersStore.installedPackIds.includes(stickerPack.id)
          );
        })
        .sort(sortStickers);
    },
  }));

  return presenter;
}
