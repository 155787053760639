import { observer } from 'mobx-react';
import React from 'react';

import { usePayloadPresenter } from './Payload.presenter';

export const Payload = observer(({ common, messageViewPageType }) => {
  const presenter = usePayloadPresenter({
    payloadType: common.message.renderPayload.payloadType,
  });

  const PayloadComponent = presenter.payloadComponent;
  const BubbleComponent = presenter.bubbleComponent;

  if (!PayloadComponent) {
    return null;
  }

  if (!BubbleComponent) {
    return (
      <PayloadComponent
        message={common.message}
        isPreview={common.isPreview}
        isView={common.isView}
        messageViewPageType={messageViewPageType}
      />
    );
  }

  return (
    <BubbleComponent
      message={common.message}
      messageViewPageType={messageViewPageType}
      isView={common.isView}
    >
      <PayloadComponent
        message={common.message}
        isPreview={common.isPreview}
        isView={common.isView}
        messageViewPageType={messageViewPageType}
      />
    </BubbleComponent>
  );
});
