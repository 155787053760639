import { action, observable } from 'mobx';

class ActiveStreamer {
  @observable streamer = null;
  @observable isSharedScreen = false;

  @action
  update(data) {
    this.streamer = data.streamer;
    this.isSharedScreen = data.isSharedScreen;
  }
}

export default ActiveStreamer;
