import { useMemo } from 'react';

import { useTranslation } from 'APP/packages/translations';
import DeleteIcon from 'ICONS/ic-s-delete.svg';
import EditIcon from 'ICONS/ic-s-edit.svg';
import { Space } from 'STORE/Spaces/Space';
import { MenuItemStyle } from 'UIKIT/MenuItem/MenuItem.types';
import { IPopoverMenuItem } from 'UIKIT/PopoverMenu/PopoverMenu.types';

interface ISpaceCollectionHeaderPresenterParams {
  space: Space | null;
  onEdit(): void;
  onDelete(): void;
}

export function useSpaceCollectionHeaderPresenter({
  space,
  onEdit,
  onDelete,
}: ISpaceCollectionHeaderPresenterParams): IPopoverMenuItem[] {
  const { t } = useTranslation();

  return useMemo(() => {
    if (!space?.isOwner) {
      return [];
    }

    return [
      {
        key: 'edit',
        title: t('message_edit'),
        onClick: onEdit,
        icon: EditIcon,
      },
      {
        key: 'delete',
        title: t('delete'),
        onClick: onDelete,
        icon: DeleteIcon,
        style: MenuItemStyle.Danger,
      },
    ];
  }, [space, onEdit, onDelete]);
}
