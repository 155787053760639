import { IChangeRequestVerifyCodeBody, VerifyCodeFlow } from 'APP/model/auth/authModel.types';
import { authModel } from 'APP/model/model';
import { platformInfo } from 'APP/packages/platformInfo/platformInfo';
import { getUserLanguage } from 'APP/utils/getUserLanguage';
import Entities from 'STORE';

export const requestChangePhoneVerifyCode = async ({
  phone,
}: IChangeRequestVerifyCodeBody): Promise<{
  error: string | null;
}> => {
  try {
    await authModel.requestChangePhoneVerifyCode({
      deviceInfo: {
        platform: platformInfo.platform,
        appVersion: Entities.App.version,
        locale: getUserLanguage(),
        device: platformInfo.device,
        osVersion: platformInfo.osVersion,
        uniqueDeviceId: '-1',
      },
      deviceUDID: '-1',
      phone: phone.replace('+', ''),
      verifyFlow: VerifyCodeFlow.WebFlow,
      signature: '',
    });

    return { error: null };
  } catch (error) {
    return { error: error.message };
  }
};
