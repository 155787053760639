import { useRef } from 'react';

import useCore from './core';
import usePayloads from './payloads';
import useScroll from './scroll';

export default ({ groupId }) => {
  const ref = useRef();

  const payloads = usePayloads();
  const scroll = useScroll(ref);
  const core = useCore({ payloads, scroll, groupId });

  return { core, ref };
};
