import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import bus from 'APP/packages/bus';
import Entities from 'STORE';

interface IEmojisStickersButtonPresenter {
  isOpened: boolean;
  onToggle(): void;
  onClose(): void;
}

export const useEmojisStickersButtonPresenter = (): IEmojisStickersButtonPresenter => {
  const presenter = useLocalStore<IEmojisStickersButtonPresenter>(() => ({
    isOpened: false,

    onToggle(): void {
      presenter.isOpened = !presenter.isOpened;
      if (presenter.isOpened) {
        Entities.App.addLayer();
      } else {
        Entities.App.removeLayer();
      }
    },

    onClose(): void {
      if (presenter.isOpened) {
        presenter.isOpened = false;
        Entities.App.removeLayer();
      }
    },
  }));

  useEffect(() => {
    const unsubscribe = bus.messaging.subscribeSendMessage(presenter.onClose);

    return () => {
      unsubscribe();
    };
  }, []);

  return presenter;
};
