import * as callBackground from './adapters/callBackground';
import * as chatBackground from './adapters/chatBackground';
import * as connectionToken from './adapters/connectionToken';
import * as countriesPhoneCodes from './adapters/countriesPhoneCodes';
import * as emojiStickersTab from './adapters/emojiStickersPanel/emojiStickersTab';
import * as installedStickerPacks from './adapters/emojiStickersPanel/installedStickerPacks';
import * as publicStickerPacks from './adapters/emojiStickersPanel/publicStickerPacks';
import * as selectedEmojiCategory from './adapters/emojiStickersPanel/selectedEmojiCategory';
import * as selectedStickerPack from './adapters/emojiStickersPanel/selectedStickerPack';
import * as feedback from './adapters/feedback';
import isPushNotificationsEnabled from './adapters/isPushNotificationsEnabled';
import * as onboarding from './adapters/onboarding';
import * as reactionsPack from './adapters/reactionsPack';
import * as rewards from './adapters/rewards';
import * as theme from './adapters/theme';
import videoPlayerConfig from './adapters/videoPlayerConfig';

export const storage = {
  connectionToken,
  isPushNotificationsEnabled,
  theme,
  chatBackground,
  onboarding,
  emojiStickersTab,
  selectedEmojiCategory,
  publicStickerPacks,
  selectedStickerPack,
  reactionsPack,
  installedStickerPacks,
  videoPlayerConfig,
  countriesPhoneCodes,
  feedback,
  rewards,
  callBackground,
};
