import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { useTranslation } from 'APP/packages/translations';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';

import { ICommonPopupsProps } from '../../../PopupManager.types';
import { useClosePopupOnNavigation } from '../../../hooks/useClosePopupOnNavigation';
import SessionItem from './SessionItem';
import SessionsPlaceholder from './SessionsPlaceholder';
import { useSessionsPopupPresenter } from './SessionsPopup.presenter';
import styles from './SessionsPopup.styles.m.css';
import SessionsSelfDestructLink from './SessionsSelfDestructLink';
import SessionsTerminateButton from './SessionsTerminateButton';

export const SessionsPopup: FC<ICommonPopupsProps> = observer((props) => {
  const { t } = useTranslation();
  const presenter = useSessionsPopupPresenter(props);
  useClosePopupOnNavigation(props.popupInstance);

  return (
    <Popup
      title={t('common_devices')}
      isOpened
      isHidden={props.popupInstance.isHidden}
      handlePrevStep={presenter.hasBackButton ? presenter.onBack : null}
      onClose={presenter.onClose}
    >
      <div className={classNames(styles.wrapper, customScrollStyles.container)}>
        <div className={styles.title}>{t('common_current_session')}</div>
        <SessionItem
          session={presenter.currentSession}
          isActive={true}
          onOpenSession={(): void => presenter.onOpenSession(presenter.currentSession)}
        />

        {(!!presenter.sessions.length || presenter.isLoading) && (
          <>
            <SessionsTerminateButton />
            <div className={styles.title}>{t('common_active_sessions')}</div>
          </>
        )}

        {presenter.isLoading ? (
          <SessionsPlaceholder />
        ) : (
          presenter.sessions.map((session) => (
            <SessionItem
              key={session.registrationKey}
              session={session}
              onOpenSession={(): void => presenter.onOpenSession(session)}
            />
          ))
        )}
      </div>
      <div className={styles.title}>{t('common_auto_terminate_session_setting')}</div>
      <SessionsSelfDestructLink
        isLoading={presenter.isLoading}
        sessionTtl={presenter.sessionTtl}
        onClick={presenter.onOpenSelfDestruct}
      />
    </Popup>
  );
});
