import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Bot } from 'STORE/Users/User/Bot/Bot';
import { Avatar } from 'UIKIT/Avatar/Avatar';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';

import { BotButtons } from '../../../components/BotButtons/BotButtons';
import styles from './BotsCatalogItem.styles.m.css';

interface IBotsCatalogItemProps {
  bot: Bot;
  onShowDetails(): void;
  onOpenBot(): void;
}

export const BotsCatalogItem: FC<IBotsCatalogItemProps> = observer(
  ({ bot, onShowDetails, onOpenBot }) => {
    return (
      <div className={styles.container}>
        <button
          className={styles.avatar}
          onClick={onShowDetails}
        >
          <Avatar
            type={AvatarTypes.Square_60}
            title={bot.userName}
            url={bot.avatarUrl}
          />
        </button>
        <div className={styles.content}>
          <div
            className={classNames(styles.title, styles.ellipsis)}
            onClick={onShowDetails}
            role="button"
            tabIndex={0}
          >
            {bot.userName}
          </div>
          <div className={classNames(styles.category, styles.ellipsis)}>
            {bot.categoryInfo?.displayName}
          </div>
          <div className={styles.description}>{bot.description}</div>
          <BotButtons
            bot={bot}
            onOpenBot={onOpenBot}
          />
        </div>
      </div>
    );
  }
);
