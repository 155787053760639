import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { useTranslation } from 'APP/packages/translations';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';

import { SpaceForm } from '../../components/SpaceForm/SpaceForm';
import { IEditSpacePopupParams, useEditSpacePopupPresenter } from './EditSpacePopup.presenter';

export const EditSpacePopup: FC<ICommonPopupsProps<IEditSpacePopupParams>> = observer((props) => {
  const { t } = useTranslation();
  const presenter = useEditSpacePopupPresenter(props);

  return (
    <Popup
      title={t('space_edit_space')}
      isOpened={!!presenter.space}
      handlePrevStep={presenter.hasBackButton ? presenter.onBack : null}
      onClose={presenter.onClose}
    >
      <SpaceForm
        initialValues={presenter.initialValues}
        avatarTitle={presenter.avatarTitle}
        avatarColor={presenter.avatarColor}
        isEditMode={true}
        onFormStateChange={presenter.onFormStateChange}
        onSubmit={presenter.onSubmit}
      ></SpaceForm>
    </Popup>
  );
});
