import { observer } from 'mobx-react';
import { FC } from 'react';

import { useThemeHandlerPresenter } from './ThemeHandler.presenter';

export const ThemeHandler: FC = observer(() => {
  useThemeHandlerPresenter();

  return null;
});
