import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { FileState } from 'MAIN/components/FileState/FileState';
import { FileUploadState } from 'MAIN/components/FileUploadState/FileUploadState';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';
import MessageStorePayloadFile from 'STORE/Messages/Message/Payload/File';
import { FileName } from 'UIKIT/FileName/FileName';

import { useFilePresenter } from './FilePayload.presenter';
import styles from './FilePayload.styles.m.css';

interface IFilePayloadProps {
  message: ChatMessage & { messageId: string };
  className?: string;
}

export const FilePayload: FC<IFilePayloadProps> = observer((props) => {
  const { message, className } = props;
  const { fileName } = message.payload as MessageStorePayloadFile;

  const { presenter, fileDownloadPresenter } = useFilePresenter({ message });
  const containerClass = classNames(styles.fileContainer, className);

  return (
    <div className={containerClass}>
      {message.payload.uploadProgress !== null ? (
        <FileUploadState
          message={message}
          onClick={fileDownloadPresenter.onDownload}
        />
      ) : (
        <FileState
          fileName={fileName}
          groupId={message.groupId}
          messageId={message.messageId || message.id}
          payloadId={message.payload?.id || message.id}
          onClick={fileDownloadPresenter.onDownload}
        />
      )}
      <div className={styles.previewContent}>
        <FileName
          className={styles.header}
          name={fileName}
        />
        <div className={styles.type}>{presenter.formattedBytes}</div>
      </div>
    </div>
  );
});
