import { observer } from 'mobx-react';
import React from 'react';

import { MiniatureMessage } from 'APP/main/ChatView/components/MiniatureMessage/MiniatureMessage';
import { IconView } from 'APP/packages/UiKit/IconView/IconView';
import { AddKeyPressHandler } from 'APP/packages/keyBoardHandler';
import IcClose from 'ICONS/ic-close-16.svg';
import ReplyIcon from 'ICONS/ic-reply.svg';
import { FAB } from 'UIKIT/FAB/FAB';

import usePresenter from './presenter';
import styles from './styles.m.css';

const QuotedMessage = ({ className }) => {
  const presenter = usePresenter();

  if (!presenter.isQuoteMode) {
    return null;
  }

  return (
    <div className={className}>
      <AddKeyPressHandler onEsc={presenter.endQuote} />
      <IconView
        Icon={ReplyIcon}
        className={styles.icon}
      />
      <div className={styles.miniature}>
        <MiniatureMessage
          message={presenter.message}
          title={<p className={styles.title}>{presenter.message.avatarTitle}</p>}
        />
      </div>
      <FAB
        Icon={IcClose}
        size="10"
        iconSize="0"
        theme="12"
        onClick={presenter.endQuote}
        className={styles.remove}
      />
    </div>
  );
};

export default observer(QuotedMessage);
