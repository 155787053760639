import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { TargetForwardType } from 'APP/constants/forward';
import { Tabs } from 'UIKIT/Tabs/Tabs';

import { ISharedDataViewItemData } from '../ShareDataView.types';
import { SharedDataContactItem } from '../SharedDataContactItem/SharedDataContactItem';
import { SharedDataGroupItem } from '../SharedDataGroupItem/SharedDataGroupItem';
import { SharedDataViewTabAll } from './SharedDataViewTabAll/SharedDataViewTabAll';
import { SharedDataViewTabChannels } from './SharedDataViewTabChannels/SharedDataViewTabChannels';
import { SharedDataViewTabChats } from './SharedDataViewTabChats/SharedDataViewTabChats';
import { useSharedDataTabsPresenter } from './SharedDataViewTabs.presenter';
import styles from './SharedDataViewTabs.styles.m.css';
import { SharedDataViewTabsType, TContentComponent } from './SharedDataViewTabs.types';

const TAB_CONTENT_COMPONENT: Record<SharedDataViewTabsType, TContentComponent> = {
  [SharedDataViewTabsType.All]: SharedDataViewTabAll,
  [SharedDataViewTabsType.Chats]: SharedDataViewTabChats,
  [SharedDataViewTabsType.Channels]: SharedDataViewTabChannels,
};

export const ITEM_COMPONENT = {
  [TargetForwardType.Chat]: SharedDataGroupItem,
  [TargetForwardType.Channel]: SharedDataGroupItem,
  [TargetForwardType.Contact]: SharedDataContactItem,
};

interface ISharedDataViewTabsProps {
  searchValue: string;
  selection: ISharedDataViewItemData[];
  multiselect: boolean;
  onClickItem(item: ISharedDataViewItemData): void;
}

export const SharedDataViewTabs: FC<ISharedDataViewTabsProps> = observer((props) => {
  const { searchValue, selection, multiselect, onClickItem } = props;

  const presenter = useSharedDataTabsPresenter();
  const ContentComponent = TAB_CONTENT_COMPONENT[presenter.tabs[presenter.activeTab]];

  return (
    <div className={styles.container}>
      <Tabs
        className={styles.tabs}
        tabTitleClassName={styles.tabTitle}
        tabClassName={styles.tab}
        underlineClassName={styles.tabUnderline}
        tabs={presenter.tabsNames}
        onChange={presenter.onChangeTab}
        selectedTabIndex={presenter.activeTab}
      />
      <ContentComponent
        searchValue={searchValue}
        selection={selection}
        multiselect={multiselect}
        onClickItem={onClickItem}
      />
    </div>
  );
});
