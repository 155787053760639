import { useLocalStore } from 'mobx-react';
import { useLayoutEffect } from 'react';

import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';

export interface ISelectDesktopSharingScreenPopupPresenterParams {
  onSelect(electronSourceId: string): void;
}

interface ISelectDesktopSharingScreenPopupPresenter {
  sources: any[];
  loadSources(): Promise<void>;
  onSelect(electronSourceId: string): void;
  onClose(): void;
}

export function useSelectDesktopSharingScreenPopupPresenter({
  popupInstance,
  onClose,
}: ICommonPopupsProps<ISelectDesktopSharingScreenPopupPresenterParams>): ISelectDesktopSharingScreenPopupPresenter {
  const presenter = useLocalStore<ISelectDesktopSharingScreenPopupPresenter>(() => ({
    sources: [],

    async loadSources(): Promise<void> {
      const sources = await window.electronAPI.getDesktopSources();
      presenter.sources = sources;
    },

    onSelect(electronSourceId: string): void {
      onClose?.(electronSourceId);
      popupInstance.close();
    },

    onClose(): void {
      onClose?.();
      popupInstance.close();
    },
  }));

  useLayoutEffect(() => {
    presenter.loadSources();
  });

  return presenter;
}
