import { useLocalStore } from 'mobx-react';

import { useTranslation } from 'APP/packages/translations';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';

import useInteractor from './interactor';

export default () => {
  const interactor = useInteractor();
  const { t } = useTranslation();
  const navigateTo = useNavigateTo();

  const presenter = useLocalStore(() => ({
    onShowNotification(event) {
      interactor.setLastEvent(event);
    },
    get notifications() {
      return interactor.events.map((event) => {
        const body = t('calls_raise_hand_event_description').replace(
          '{0}',
          event.user?.displayName
        );
        return {
          id: event.timestamp,
          title: interactor.avatar.title || '',
          icon: interactor.avatar.url || '',
          body,
          onClick: presenter.onClick,
        };
      });
    },
    onClick({ target }) {
      window.focus();
      target.close();
      const group = interactor.group;
      if (!group || group.isActive) {
        return null;
      }
      navigateTo({
        to: `/${group.route}/${group.id}`,
      });
    },
  }));

  return presenter;
};
