import { PayloadType } from 'APP/model/message/messageModel.types';
import { useTranslation } from 'APP/packages/translations';
import { Group } from 'STORE/Groups/Group';
import System from 'STORE/Messages/Message/Payload/System';

import { chatAddedHandler } from './handlers/chatAddedHandler';
import { chatAddedToSpaceHandler } from './handlers/chatAddedToSpaceHandler';
import { chatAdminsAddedHandler } from './handlers/chatAdminsAddedHandler';
import { chatAdminsRemovedHandler } from './handlers/chatAdminsRemovedHandler';
import { chatAvatarChangedHandler } from './handlers/chatAvatarChangedHandler';
import { chatCategoryChangedHandler } from './handlers/chatCategoryChangedHandler';
import { chatDescriptionChangedHandler } from './handlers/chatDescriptionChangedHandler';
import { chatJoinedHandler } from './handlers/chatJoinedHandler';
import { chatLeftHandler } from './handlers/chatLeftHandler';
import { chatMessagePinnedHandler } from './handlers/chatMessagePinnedHandler';
import { chatMessagesSettingsChanged } from './handlers/chatMessagesSettingsChanged';
import { chatNewlyCreatedUserJoinedHandler } from './handlers/chatNewlyCreatedUserJoinedHandler';
import { chatNewlyCreatedWelcomeHandler } from './handlers/chatNewlyCreatedWelcomeHandler';
import { chatOwnerChangedHandler } from './handlers/chatOwnerChangedHandler';
import { chatRemovedFromSpaceHandler } from './handlers/chatRemovedFromSpaceHandler';
import { chatRenamedHandler } from './handlers/chatRenamedHandler';
import { chatTypeChangedHandler } from './handlers/chatTypeChangedHandler';
import { chatUsersBannedHandler } from './handlers/chatUsersBannedHandler';
import { chatUsersRemovedHandler } from './handlers/chatUsersRemovedHandler';
import { threadNewlyCreatedWelcomeHandler } from './handlers/threadNewlyCreatedWelcomeHandler';
import { useSystemMessageTextPresenter } from './useSystemMessageText.presenter';
import { ISystemMessageText, TSystemMessageTextHandler } from './useSystemMessageText.types';

const PAYLOAD_TYPE_HANDLERS: { [key in PayloadType]?: TSystemMessageTextHandler } = {
  [PayloadType.SystemChatNewlyCreatedWelcome]: chatNewlyCreatedWelcomeHandler,
  [PayloadType.SystemThreadNewlyCreatedWelcome]: threadNewlyCreatedWelcomeHandler,
  [PayloadType.SystemChatNewlyCreatedPhoneBookUserJoined]: chatNewlyCreatedUserJoinedHandler,
  [PayloadType.SystemChatJoined]: chatJoinedHandler,
  [PayloadType.SystemChatAdded]: chatAddedHandler,
  [PayloadType.SystemChatAvatarChanged]: chatAvatarChangedHandler,
  [PayloadType.SystemChatMessagePinned]: chatMessagePinnedHandler,
  [PayloadType.SystemChatOwnerChanged]: chatOwnerChangedHandler,
  [PayloadType.SystemChatAdminsAdded]: chatAdminsAddedHandler,
  [PayloadType.SystemChatAdminsRemoved]: chatAdminsRemovedHandler,
  [PayloadType.SystemChatDescriptionChanged]: chatDescriptionChangedHandler,
  [PayloadType.SystemChatRenamed]: chatRenamedHandler,
  [PayloadType.SystemChatUsersRemoved]: chatUsersRemovedHandler,
  [PayloadType.SystemChatUsersBanned]: chatUsersBannedHandler,
  [PayloadType.SystemChatLeft]: chatLeftHandler,
  [PayloadType.SystemChatTypeChanged]: chatTypeChangedHandler,
  [PayloadType.SystemChatCategoryChanged]: chatCategoryChangedHandler,
  [PayloadType.SystemChatMessagesSettingsChanged]: chatMessagesSettingsChanged,
  [PayloadType.SystemChatAddedToSpace]: chatAddedToSpaceHandler,
  [PayloadType.SystemChatRemovedFromSpace]: chatRemovedFromSpaceHandler,
};

export const useSystemMessageText = (group: Group, payload: System): ISystemMessageText | null => {
  const presenter = useSystemMessageTextPresenter(group, payload);
  const { t } = useTranslation();
  let data = null;

  // Todo Remove casting to PayloadType after the messages payloads will be refactored
  const handler = PAYLOAD_TYPE_HANDLERS[payload.payloadType as PayloadType];
  if (handler) {
    data = handler(presenter, t);
  }

  return data;
};
