import Tasks from 'APP/Tasks';
import {
  PayloadType,
  IMessagePayloadData,
  IReachTextMessagePayloadData,
  IFileMessagePayloadData,
  IAlbumPayloadData,
  IArticlePayloadData,
} from 'APP/model/message/messageModel.types';
import { t } from 'MODULE/i18n/service';
import { Group } from 'STORE/Groups/Group';

export const getStickerPayloadError = (group: Group): string | null => {
  if (!group.memberCustomPermissions?.supportedMediaTypes?.canSendSticker) {
    return group.isChannel
      ? t('moderation_content_limit_stickers_сhannel')
      : t('moderation_content_limit_stickers');
  }

  return null;
};

export const getVoicePayloadError = (group: Group): string | null => {
  if (!group.memberCustomPermissions?.supportedMediaTypes?.canSendVoice) {
    return group.isChannel
      ? t('moderation_content_limit_voice_channel')
      : t('moderation_content_limit_voice');
  }

  return null;
};

export const getTextPayloadError = (group: Group, payload?: IMessagePayloadData): string | null => {
  if (group.memberCustomPermissions?.supportedMediaTypes?.canSendLink || !payload) {
    return null;
  }

  // Todo Fix after then messages payloads types will be refactored
  const text =
    payload.payloadType === PayloadType.RichText
      ? (payload as unknown as IReachTextMessagePayloadData).text
      : (payload as unknown as IFileMessagePayloadData).comment || '';

  if (Tasks.app.searchLinks(text).length > 0) {
    return group.isChannel
      ? t('moderation_content_limit_links_сhannels')
      : t('moderation_content_limit_links');
  }

  return null;
};

export const getImagePayloadError = (group: Group): string | null => {
  if (group.memberCustomPermissions?.supportedMediaTypes?.canSendImage) {
    return null;
  }

  if (!group.memberCustomPermissions?.supportedMediaTypes?.canSendVideo) {
    return group.isChannel
      ? t('moderation_content_limit_media_channel')
      : t('moderation_content_limit_media');
  }

  return group.isChannel
    ? t('moderation_content_limit_image_channel')
    : t('moderation_content_limit_image');
};

export const getVideoPayloadError = (group: Group): string | null => {
  if (group.memberCustomPermissions?.supportedMediaTypes?.canSendVideo) {
    return null;
  }

  if (!group.memberCustomPermissions?.supportedMediaTypes?.canSendImage) {
    return group.isChannel
      ? t('moderation_content_limit_media_channel')
      : t('moderation_content_limit_media');
  }

  return group.isChannel
    ? t('moderation_content_limit_video_channel')
    : t('moderation_content_limit_video');
};

export const getCircleVideoPayloadError = (group: Group): string | null => {
  if (!group.memberCustomPermissions?.supportedMediaTypes?.cansSendCircleVideo) {
    return group.isChannel
      ? t('moderation_content_limit_media_channel')
      : t('moderation_content_limit_media');
  }

  return null;
};

export const getAudioPayloadError = (group: Group): string | null => {
  if (!group.memberCustomPermissions?.supportedMediaTypes?.canSendAudio) {
    return group.isChannel
      ? t('moderation_content_limit_audio_channel')
      : t('moderation_content_limit_audio');
  }

  return null;
};

export const getFilePayloadError = (group: Group): string | null => {
  if (!group.memberCustomPermissions?.supportedMediaTypes?.canSendFile) {
    return group.isChannel
      ? t('moderation_content_limit_files_channel')
      : t('moderation_content_limit_files');
  }

  return null;
};

export const getAlbumPayloadError = (
  group: Group,
  payload?: IMessagePayloadData
): string | null => {
  if (!payload) {
    return null;
  }

  // Todo Fix after then messages payloads types will be refactored
  const payloads = (payload as unknown as IAlbumPayloadData).payloads;

  for (const payloadItem of payloads) {
    let errorText: string | null = null;

    switch (payloadItem.payload.payloadType) {
      case PayloadType.Image:
        errorText = getImagePayloadError(group);
        break;
      case PayloadType.Video:
        errorText = getVideoPayloadError(group);
        break;
      default:
        errorText = null;
    }

    if (errorText) {
      return errorText;
    }
  }

  return null;
};

export const getArticlePayloadError = (
  group: Group,
  payload?: IMessagePayloadData
): string | null => {
  if (!payload) {
    return null;
  }

  if (!group.memberCustomPermissions?.supportedMediaTypes?.canSendLink) {
    // Todo Fix after then messages payloads types will be refactored
    const title = (payload as unknown as IArticlePayloadData).title;
    if (Tasks.app.searchLinks(title).length > 0) {
      return group.isChannel
        ? t('moderation_content_limit_links_сhannels')
        : t('moderation_content_limit_links');
    }
  }

  // Todo Fix after then messages payloads types will be refactored
  const payloads = (payload as unknown as IArticlePayloadData).payloads;
  for (const payloadItem of payloads) {
    let errorText: string | null = null;

    switch (payloadItem.payload.payloadType) {
      case PayloadType.RichText:
        errorText = getTextPayloadError(group, payloadItem.payload);
        break;
      case PayloadType.Image:
        errorText = getImagePayloadError(group);
        break;
      case PayloadType.Video:
        errorText = getVideoPayloadError(group);
        break;
      case PayloadType.AudioMessage:
        errorText = getAudioPayloadError(group);
        break;
      case PayloadType.File:
        errorText = getFilePayloadError(group);
        break;
      default:
        errorText = null;
    }

    if (errorText) {
      return errorText;
    }
  }

  return null;
};
