import Tasks from 'APP/Tasks';
import { sendNewMessageInQueue } from 'APP/Tasks/messaging/createNewMessages/sendNewMessageInQueue';
import { UpdateReason } from 'APP/constants/scroll';
import { updateMessage } from 'APP/model/message/messageModel';
import bus from 'APP/packages/bus';
import { subscribeSentMessage } from 'APP/packages/bus/adapters/messaging/sentMessage';
import { TEventUnsubscribeHandler } from 'APP/packages/bus/eventBus';
import Entities from 'APP/store';
import { ChatMessage } from 'APP/store/Messages/Message/ChatMessage/ChatMessage';
import { MessageError } from 'STORE/Messages/Message/ChatMessage/ChatMessages.types';

interface EditMessageAfterSendRequestEndParams {
  localMessage: ChatMessage;
  realMessageId: string;
}

const editMessageAfterSendRequestEnd = async ({
  localMessage,
  realMessageId,
}: EditMessageAfterSendRequestEndParams): Promise<void> => {
  try {
    const updatedMessage = await updateMessage({
      messageId: realMessageId,
      groupId: localMessage.groupId,
      payload: localMessage.payload.toJSON(),
    });

    Tasks.messaging.updateOldOrFakeMessages({
      messages: [updatedMessage],
      groupId: localMessage.groupId,
    });
  } catch (error) {
    Tasks.messaging.handleMessageError({
      error,
      message: localMessage.messages.getMessageById(localMessage.id),
      messageErrorType: MessageError.Send,
    });
  }
};

let unsubscribeSentMessage: TEventUnsubscribeHandler;

export const editMessage = async (message: ChatMessage): Promise<void> => {
  const group = Entities.GroupsStore.getGroupById(message.groupId);
  if (!group) {
    return;
  }

  bus.messaging.broadcastSendMessage();

  group.messagesStore.setEditedPayload(message);

  // Todo need to review all cases of cleaning the input panel and remove unnecessary
  const inputPanel = Entities.InputPanelsStore.getById(group.id);
  inputPanel.setValue(null);
  inputPanel.setEditMessageId(null);

  try {
    if (message.id !== message.clientUuid) {
      if (message.error) {
        message.resetError();
      }

      const updatedMessage = await updateMessage({
        messageId: message.id,
        groupId: message.groupId,
        payload: message.renderPayload.toJSON(),
      });

      Tasks.messaging.updateOldOrFakeMessages({
        messages: [updatedMessage],
        groupId: message.groupId,
      });

      group.messagesStore.removeEditedPayload(message);
    } else {
      if (message.error === MessageError.Send) {
        message.resetError();
        return sendNewMessageInQueue({
          message,
          errorCallback: (error) =>
            Tasks.messaging.handleMessageError({
              error,
              message,
              messageErrorType: MessageError.Send,
            }),
        });
      }

      message.setIsEditQueue(true);

      if (unsubscribeSentMessage) {
        unsubscribeSentMessage();
      }

      unsubscribeSentMessage = subscribeSentMessage(message.groupId, async (data) => {
        const { messageUuid, messageId, isRequestFinished, hasError } = data;

        if (!hasError && messageUuid === message.clientUuid && isRequestFinished) {
          await editMessageAfterSendRequestEnd({ realMessageId: messageId, localMessage: message });
        }

        message.setIsEditQueue(false);
        unsubscribeSentMessage();
      });
    }

    if (group.isActive) {
      Entities.ChatStore.setScrollChanged(UpdateReason.EditMessage, {
        messageIds: [message.id],
        isAnimated: true,
      });
    }
  } catch (error) {
    Tasks.messaging.handleMessageError({
      error,
      message: group.messagesStore.getMessageById(message.id)!,
      messageErrorType: MessageError.Edit,
    });
  }
};
