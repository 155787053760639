import React, { FC } from 'react';

import GemMascotStickerImage from 'APP/images/gem-mascot-sticker-background.svg';
import { RewardProgressInfoRewardEnum } from 'APP/model/userSettings/userSettingsModel.types';
import { useTranslation } from 'APP/packages/translations';
import { Button } from 'UIKIT/Button/Button';

import { RewardsBaseItem } from '../RewardsBaseItem/RewardsBaseItem';
import { useRewardsInvitePresenter } from './RewardsInvite.presenter';
import styles from './RewardsInvite.styles.m.css';

interface IRewardsInviteProps {
  onOpenEditProfile(): void;
}

export const RewardsInvite: FC<IRewardsInviteProps> = ({ onOpenEditProfile }) => {
  const { t } = useTranslation();
  const presenter = useRewardsInvitePresenter();

  return (
    <RewardsBaseItem
      rewardType={RewardProgressInfoRewardEnum.ExclusiveBackground1}
      image={GemMascotStickerImage}
      imageAlt={'get-mascot-background'}
      title={t('common_invite_one_user_reward_heading')}
      subTitle={t('common_invite_one_user_reward_description')}
      progressPercent={presenter.rewardProgressBarPercent}
      maxPoints={presenter.rewardProgress?.maxPoints}
      currentPoints={presenter.rewardProgress?.currentPoints}
      achieved={presenter.rewardProgress?.achieved}
      onGetReward={onOpenEditProfile}
      childrenInsteadGetGift={
        <Button
          onClick={presenter.copyLink}
          className={styles.getLinkButton}
          size={'20'}
        >
          {t('common_copy_link')}
        </Button>
      }
    />
  );
};
