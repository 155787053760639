import { action, computed, observable } from 'mobx';

import Tasks from 'APP/Tasks';
import { UserOnlineStatus } from 'APP/model/user/userModel.types';
import {
  IBotUserDisplayData,
  IUserBlockStatus,
  IUserDisplayData,
  IUserPrivacyStatus,
  UserProfileUsernameBadge,
} from 'APP/model/user/userModel.types';
import { IUpdateUserDisplayData } from 'APP/model/userSettings/userSettingsModel.types';
import Entities from 'APP/store';
import { Group } from 'STORE/Groups/Group';
import Users from 'STORE/Users';
import { TAvatarBackgroundColorIndex } from 'UIKIT/Avatar/Avatar.types';

export class User {
  users: Users;

  @observable id: string;
  @observable phone?: string;
  @observable userName: string;
  @observable nickName: string;
  @observable contactName?: string;
  @observable avatarUrl: string | null;
  @observable blockedByMe: IUserBlockStatus | null = null;
  @observable blockedMe: IUserBlockStatus | null = null;
  @observable privacyStatus: IUserPrivacyStatus;
  @observable usernameBadge: UserProfileUsernameBadge | null = null;
  @observable isUnknown: boolean;
  @observable verified: boolean;
  @observable isLoadingBlockedByMe = false;
  @observable deepLink: string;
  @observable bio: string;

  constructor(users: Users, data: IUserDisplayData | IBotUserDisplayData) {
    this.users = users;

    this.id = data.id;
    this.phone = data.phone;
    this.userName = data.userName;
    this.nickName = data.nickName;
    this.contactName = data.contactName;
    this.avatarUrl = data.avatarUrl || null;
    this.blockedByMe = data.blockedByMe;
    this.blockedMe = data.blockedMe;
    this.privacyStatus = data.privacyStatus;
    this.usernameBadge = data.usernameBadge || null;
    this.verified = data.verified;
    this.isUnknown = data.isUnknown;
    this.deepLink = data.deepLink;
    this.bio = data.bio || '';
  }

  @action
  update(data: IUserDisplayData): void {
    this.phone = data.phone;
    this.userName = data.userName;
    this.nickName = data.nickName;
    this.avatarUrl = data.avatarUrl || null;
    this.privacyStatus = data.privacyStatus || this.privacyStatus;
    this.verified = data.verified;
    this.usernameBadge = data.usernameBadge || null;
    this.blockedMe = data.blockedMe;
    this.blockedByMe = data.blockedByMe;
    this.bio = data.bio || '';
  }

  @action
  updateDisplayData(data: IUpdateUserDisplayData): void {
    this.userName = data.userName;
    this.nickName = data.nickName;
    this.avatarUrl = data.avatarUrl || null;
    this.bio = data.bio || '';
    this.usernameBadge = data.usernameBadge || null;
  }

  @action
  setIsUnknown(isUnknown: boolean): void {
    this.isUnknown = isUnknown;
  }

  @action
  setBlockedByMe(blockedByMe: IUserBlockStatus): void {
    this.blockedByMe = blockedByMe;
  }

  @action
  setLoadingBlockedByMe(isLoading: boolean): void {
    this.isLoadingBlockedByMe = isLoading;
  }

  get isMe(): boolean {
    return false;
  }

  get isBot(): boolean {
    return false;
  }

  @computed
  get group(): Group | null {
    return Entities.GroupsStore.findGroupByUserId(this.id) || null;
  }

  @computed
  get route(): string {
    return this.group ? this.group.route : '';
  }

  @computed
  get isInPhoneBook(): boolean {
    return this.users.userContacts.some(({ id }) => id === this.id);
  }

  @computed
  get isBlockedByMe(): boolean {
    return this.isLoadingBlockedByMe || Boolean(this.blockedByMe?.value);
  }

  @computed
  get isBlockedMe(): boolean {
    return Boolean(this.blockedMe?.value);
  }

  @computed
  get isBlocked(): boolean {
    return Boolean(this.isBlockedByMe || this.isBlockedMe);
  }

  @computed
  get avatarTitle(): string {
    return this.displayName;
  }

  @computed
  get avatarSubTitle(): string {
    return this.displayPhone || this.displayNickname;
  }

  @computed
  get avatarColorIndex(): TAvatarBackgroundColorIndex {
    return this.id.toString().at(-1) as TAvatarBackgroundColorIndex;
  }

  @computed
  get nameToSort(): string {
    return this.displayName.toLowerCase();
  }

  @computed
  get displayName(): string {
    return (this.isInPhoneBook && this.contactName) || this.userName || this.displayNickname;
  }

  @computed
  get displayNickname(): string {
    return this.nickName ? `@${this.nickName}` : '';
  }

  @computed
  get displayPhone(): string {
    return this.phone ? `+${this.phone}` : '';
  }

  @computed
  get displayPhoneOrNickname(): string {
    return this.displayPhone || this.displayNickname;
  }

  get canAddToGroupMe(): boolean {
    return this.privacyStatus.addToGroup;
  }

  @computed
  get canAddToPublicMe(): boolean {
    return this.privacyStatus.addToPublic;
  }

  @computed
  get isOnline(): boolean {
    if (this.isBlocked) {
      return false;
    }

    const onlineStatus = this.users.getOnlineStatusByUserId(this.id);

    if (!onlineStatus) {
      return false;
    }

    return onlineStatus.status === UserOnlineStatus.Online;
  }

  @computed
  get lastOnlineTs(): number | null {
    const onlineStatus = this.users.getOnlineStatusByUserId(this.id);

    if (!onlineStatus) {
      return null;
    }

    return parseInt(onlineStatus.lastOnlineTs, 10);
  }

  startChat = (): Promise<Group | null> => Tasks.group.createP2PGroup(this.id);
}
