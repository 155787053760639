import { useLocalStore } from 'mobx-react';

import Entities from 'APP/store';

export default () => {
  const presenter = useLocalStore(() => ({
    get calls() {
      return Entities.Calls.calls;
    },

    get hasCalls() {
      return presenter.calls.length > 0;
    },
  }));

  return presenter;
};
