import { useHistory } from 'react-router-dom';

import usePrepareLink from './usePrepareLink';

export default () => {
  const history = useHistory();
  const prepareLink = usePrepareLink();

  return (data) => {
    const link = prepareLink(data);
    history.push(link);
  };
};
