import classNames from 'classnames';
import React, { FC, MouseEventHandler } from 'react';

import RetryIcon from 'ICONS/ic-add-pull-refresh-32.svg';
import RemoveIcon from 'ICONS/ic-close-32.svg';
import { FAB } from 'UIKIT/FAB/FAB';

import styles from './AllDownloadsItemActions.styles.m.css';

interface IAllDownloadsItemActionsProps {
  className?: string;
  onRetry: MouseEventHandler<HTMLButtonElement>;
  onRemove: MouseEventHandler<HTMLButtonElement>;
}

export const AllDownloadsItemActions: FC<IAllDownloadsItemActionsProps> = (props) => {
  const { className, onRetry, onRemove } = props;

  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.smoothOpacity} />
      <div className={styles.icons}>
        <FAB
          className={classNames(styles.retryIcon)}
          Icon={RetryIcon}
          size="10"
          iconSize="60"
          theme="3"
          onClick={onRetry}
        />
        <FAB
          className={classNames(styles.removeIcon)}
          Icon={RemoveIcon}
          size="10"
          iconSize="60"
          theme="3"
          onClick={onRemove}
        />
      </div>
    </div>
  );
};
