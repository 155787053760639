import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { ActiveSpeakerIcon } from 'APP/components/ActiveSpeakerIcon/ActiveSpeakerIcon';
import { IconButton, IconViewSizes } from 'APP/packages/UiKit/IconButton';
import { IconView } from 'APP/packages/UiKit/IconView/IconView';
import { useTranslation } from 'APP/packages/translations';
import RaiseHand from 'ICONS/ic-has_raise_hand.svg';
import { Member } from 'STORE/Calls/Call/Member/Member';
import { MemberState } from 'STORE/Calls/Call/Member/MemberState';
import { Avatar } from 'UIKIT/Avatar/Avatar';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';

import { MutedBadge } from '../../MutedBadge/MutedBadge';
import { useMemberCardPresenter } from './MemberCard.presenter';
import styles from './MemberCard.styles.m.css';

const i18nState = {
  [MemberState.NotOnCall]: 'calls_not_on_call',
  [MemberState.OnCall]: 'calls_on_call',
  [MemberState.Declined]: 'calls_declined_call',
  [MemberState.Left]: 'calls_left',
};

interface IMemberCardProps {
  member: Member;
}

export const MemberCard: FC<IMemberCardProps> = observer(({ member }) => {
  const { t } = useTranslation();
  const presenter = useMemberCardPresenter(member);

  return (
    <div
      id={member.userId}
      className={styles.memberCardContainer}
    >
      <div className={styles.avatarContainer}>
        <Avatar
          type={AvatarTypes.Round_10}
          title={member.displayName}
          url={member.avatarUrl}
          backgroundColorIndex={member.avatarColorIndex}
        />
        {member.isAudioMuted && <MutedBadge className={styles.mutedBadge} />}
      </div>
      <div className={styles.content}>
        <div className={styles.title}>
          <span className={styles.titleName}>{member.displayName}</span>&nbsp;
          {member.isInitiator && t('call_caller_short')}
        </div>
        <div className={classNames(styles.subtitle, styles[member.state])}>
          {t(i18nState[member.state])}
          {member.isActiveSpeaker && <ActiveSpeakerIcon />}
        </div>
      </div>
      <div className={styles.actions}>
        {member.isRaiseHand && (
          <IconView
            Icon={RaiseHand}
            size={IconViewSizes.Medium}
            className={styles.raiseHand}
          />
        )}
        {presenter.actions.map((action) => (
          <IconButton
            key={action.key}
            Icon={action.icon}
            onClick={action.onClick}
            className={styles.action}
            size={IconViewSizes.Big}
            iconSize={IconViewSizes.Medium}
            theme="12"
          />
        ))}
      </div>
    </div>
  );
});
