import { useAsObservableSource, useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { OnboardingType } from 'APP/constants/onboarding';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import Entities from 'APP/store';

export default ({ groupId }) => {
  const navigateTo = useNavigateTo();
  const source = useAsObservableSource({ groupId, navigateTo });

  const presenter = useLocalStore(() => ({
    onClick: () => {
      Tasks.app.onboarding.closeOnboarding(OnboardingType.CreateFirstArticle);

      source.navigateTo({
        keepOldPathname: true,
        to: `/article-drafts`,
      });
    },

    get group() {
      return Entities.GroupsStore.activeGroup;
    },
  }));

  return presenter;
};
