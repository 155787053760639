const HelperText = ({
  isValidating,
  validatingText,
  hasError,
  errorText,
  hasSuccess,
  successText,
  helperText,
}) => {
  if (isValidating) {
    return validatingText;
  }

  if (hasError) {
    return errorText;
  }

  if (hasSuccess) {
    return successText;
  }

  if (helperText) {
    return helperText;
  }

  return null;
};

export default HelperText;
