import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, MouseEventHandler } from 'react';

import { IReactionItem } from 'MAIN/hooks/useReactions/useReactions.types';

import { TReactionTheme } from '../Reactions.types';
import styles from './ReactionItem.styles.m.css';

interface IEmptyReactionItemProps {
  reaction: IReactionItem;
  className?: string;
  theme?: TReactionTheme;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export const EmptyReactionItem: FC<IEmptyReactionItemProps> = observer((props) => {
  const { reaction, className, theme = '1', onClick } = props;

  const buttonClasses = classNames(styles.container, styles[`theme-${theme}`], className, {
    [styles.selected]: reaction.hasMyReaction,
  });

  return (
    <button
      className={buttonClasses}
      onClick={onClick || reaction.onClick}
    >
      <span className={styles.icon} />
      {!!reaction.counter && <span className={styles.counter}>{reaction.counter}</span>}
    </button>
  );
});
