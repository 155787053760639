export enum ViewType {
  Main = 'main',
  Link = 'link',
}

export interface ITextFormattingMenuParams {
  selectionRange: Range;
  onClose(): void;
  onBold(): void;
  onItalic(): void;
  onUnderline(): void;
  onStrikeThrough(): void;
  onAddLink(url: string): void;
  onRemoveLink(): void;
}

export interface ITextFormattingMenuViewParams extends ITextFormattingMenuParams {
  onChangeView(view: ViewType): void;
}
