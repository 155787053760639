import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import StickerPackPlaceholder from 'APP/packages/placeholders/StickerPack';
import { useTranslation } from 'APP/packages/translations';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import { Button } from 'UIKIT/Button/Button';
import { PopupFooter } from 'UIKIT/PopupFooter/PopupFooter';

import { PopupHeader } from './PopupHeader/PopupHeader';
import { StickerCategory } from './StickerCategory/StickerCategory';
import {
  IStickerPackPopupParams,
  useStickerPackPopupPresenter,
} from './StickerPackPopup.presenter';
import styles from './StickerPackPopup.styles.m.css';
import { Stickers } from './Stickers/Stickers';

export const StickerPackPopup: FC<ICommonPopupsProps<IStickerPackPopupParams>> = observer(
  (props) => {
    const presenter = useStickerPackPopupPresenter(props);
    const { t } = useTranslation();

    return (
      <Popup
        isOpened={presenter.isAvailableToShow}
        className={styles.container}
        onClose={presenter.onClose}
      >
        <PopupHeader
          onCopyClick={presenter.copyLink}
          onBackClick={presenter.hasBackButton ? presenter.onBack : null}
          onClose={presenter.onClose}
        />
        {presenter.isLoading ? (
          <StickerPackPlaceholder />
        ) : (
          presenter.isAvailableToShow &&
          presenter.pack !== null && (
            <>
              <StickerCategory
                iconUrl={presenter.pack.stickerPack.iconUrl}
                displayName={presenter.pack.stickerPack.displayName}
                stickersCount={presenter.pack.stickers.length}
              />
              <Stickers
                stickerPackId={presenter.pack.stickerPack.id}
                stickers={presenter.pack.stickers}
                onClick={presenter.sendSticker}
              />
              <PopupFooter>
                {presenter.isSharable ? (
                  <Button
                    theme={presenter.isSharable ? '1' : '11'}
                    isLoading={presenter.isProgress}
                    isDisabled={presenter.isProgress}
                    onClick={presenter.toggle}
                  >
                    {presenter.isAddedPack ? t('common_remove') : t('add_button')}
                  </Button>
                ) : (
                  <div className={styles.privateTitle}>{t('common_private_sticker_pack')}</div>
                )}
              </PopupFooter>
            </>
          )
        )}
      </Popup>
    );
  }
);
