import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useState, useEffect } from 'react';
import Draggable from 'react-draggable';

import { ShareScreenButton } from 'APP/components/ShareScreenButton/ShareScreenButton';
import { IconButton } from 'APP/packages/UiKit/IconButton';
import ExpandIcon from 'ICONS/ic-maximize.svg';
import MicOff from 'ICONS/ic-mic-off.svg';
import Mic from 'ICONS/ic-mic-on.svg';
import VideoOff from 'ICONS/ic-video-off.svg';
import VideoOn from 'ICONS/ic-video-on.svg';

import NoVideoMinimize from '../../SharedComponents/NoVideoMinimize';
import Video from '../../SharedComponents/Video';
import styles from './styles.m.css';

const MinimizeView = ({ presenter, streamerPresenter, isFinished }) => {
  const [isDragging, setDragging] = useState();
  useEffect(() => {
    if (isFinished) {
      presenter.onClose();
    }
  }, [isFinished]);

  return (
    <Draggable
      cancel="button"
      bounds={'body'}
      onStart={() => setDragging(false)}
      onDrag={() => setDragging(true)}
      onStop={() => setDragging(false)}
    >
      <div
        className={classNames(styles.container, {
          [styles.dragging]: isDragging,
          [styles.showControls]: !presenter.primaryVideo,
        })}
      >
        {presenter.primaryVideo ? (
          <Video videoTrack={presenter.primaryVideo} />
        ) : (
          <NoVideoMinimize className={styles.noVideo} />
        )}
        <div className={styles.panel}>
          <IconButton
            Icon={streamerPresenter.audioMuted ? MicOff : Mic}
            onClick={
              streamerPresenter.audioMuted
                ? streamerPresenter.unmuteAudio
                : streamerPresenter.muteAudio
            }
            className={styles.button}
            size="large"
            iconSize="medium"
            theme={streamerPresenter.audioMuted ? 12 : 13}
          />
          <IconButton
            Icon={streamerPresenter.isVideoMuted ? VideoOff : VideoOn}
            onClick={
              streamerPresenter.videoMuted
                ? streamerPresenter.unmuteVideo
                : streamerPresenter.muteVideo
            }
            className={styles.button}
            size="large"
            iconSize="medium"
            theme={streamerPresenter.videoMuted ? 12 : 13}
          />
          <ShareScreenButton
            className={styles.button}
            isActive={streamerPresenter.isSharedScreen}
            shareScreen={streamerPresenter.startSharingScreen}
            stopSharingScreen={streamerPresenter.stopSharingScreen}
          />
        </div>
        <IconButton
          Icon={ExpandIcon}
          onClick={presenter.expand}
          className={styles.expand}
          size="large"
          iconSize="medium"
          theme="12"
        />
        {presenter.isStreamStarted && <div className={styles.live}>LIVE</div>}
      </div>
    </Draggable>
  );
};

export default observer(MinimizeView);
