import classNames from 'classnames';
import React, { FC } from 'react';

import { Tooltip } from 'UIKIT/Tooltip/Tooltip';
import noop from 'UTILS/noop';

import { IMediaPlayerProgressBuffer } from '../../hooks/useMediaPlayerProgress';
import { useMediaPlayerProgressPresenter } from './MediaPlayerProgress.presenter';
import styles from './MediaPlayerProgress.styles.m.css';

interface IMediaPlayerProgressProps {
  className?: string;
  isPlaying: boolean;
  progress: number;
  progressMax: number;
  isShowBuffer?: boolean;
  progressBuffer?: IMediaPlayerProgressBuffer[];
  isShowTooltip?: boolean;
  onPlay(): void;
  onPause(): void;
  onProgress(progress: number): void;
  onProgressSelection?(isSelected: boolean): void;
}

export const MediaPlayerProgress: FC<IMediaPlayerProgressProps> = (props) => {
  const {
    className,
    isPlaying,
    progress,
    progressMax,
    isShowBuffer = true,
    progressBuffer = [],
    isShowTooltip = false,
    onPlay,
    onPause,
    onProgress,
    onProgressSelection = noop,
  } = props;

  const presenter = useMediaPlayerProgressPresenter({
    isPlaying,
    progress,
    progressMax,
    isShowBuffer,
    progressBuffer,
    isShowTooltip,
    onPlay,
    onPause,
    onProgress,
    onProgressSelection,
  });

  const pinClass = classNames(styles.progressPin, {
    [styles.progressPinActive]: presenter.isChangingPosition,
  });

  const progressBarClass = classNames(styles.progressBar, {
    [styles.progressBarAnimated]: presenter.isAnimatedProgress,
  });

  return (
    <Tooltip
      text={presenter.tooltipTime}
      followCursor="horizontal"
      disabled={!isShowTooltip}
    >
      <div
        className={classNames(styles.progress, className)}
        ref={presenter.progressRef}
        role="progressbar"
        tabIndex={0}
        onMouseDown={presenter.onMouseDown}
      >
        <div className={styles.progressBarWrapper}>
          <div className={styles.progressBackground} />
          {isShowBuffer &&
            presenter.bufferPercentage.map((item) => (
              <div
                className={styles.progressBuffer}
                key={`${item.start}_${item.width}`}
                style={{ left: `${item.start}%`, width: `${item.width}%` }}
              />
            ))}

          <div
            className={progressBarClass}
            style={{ width: `${presenter.progressPercentage}%` }}
          >
            <div className={pinClass} />
          </div>
        </div>
      </div>
    </Tooltip>
  );
};
