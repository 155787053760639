import { useAsObservableSource, useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { IDownloadProgress } from 'APP/store/Messages/DownloadProgress/DownloadProgress.types';
import { MessagesStore } from 'APP/store/Messages/Messages';
import Entities from 'STORE';
import FilePayload from 'STORE/Messages/Message/Payload/File';
import { getMessagePayload } from 'UTILS/message/message';

export interface IFileDownloadStateParams {
  payloadId: string;
  groupId?: string;
  messageId?: string;
}

export interface IFileDownloadStatePresenter {
  messagesStore: MessagesStore | null;
  downloadProgress: IDownloadProgress | null;
  hasError: boolean;
  isDownloadInProcess: boolean;
  isDownloadComplete: boolean;
  isWaitingDownloadProcess: boolean;
  downloadPercent: number;
  progressEvent?: ProgressEvent<EventTarget>;
  loaded: number;
  payload: FilePayload | null;
  startDownloadTs: number;
  onAbort(): void;
  onRemove(): void;
  onDownload(): void;
}

export const useFileDownloadStatePresenter = ({
  groupId,
  messageId,
  payloadId,
}: IFileDownloadStateParams): IFileDownloadStatePresenter => {
  const source = useAsObservableSource({ payloadId, groupId, messageId });

  const presenter = useLocalStore<IFileDownloadStatePresenter>(() => ({
    get messagesStore(): MessagesStore | null {
      return source.groupId
        ? Entities.GroupsStore.getGroupById(source.groupId)?.messagesStore || null
        : null;
    },

    get downloadProgress(): IDownloadProgress | null {
      return presenter.messagesStore?.downloadProgressPerPayload.get(source.payloadId) || null;
    },

    get progressEvent(): ProgressEvent<EventTarget> | undefined {
      return presenter.downloadProgress?.progressEvent;
    },

    get loaded(): number {
      return presenter.progressEvent?.loaded || 0;
    },

    get downloadPercent(): number {
      const isFileEmpty = presenter.payload?.fileSize === 0;

      if (!presenter.hasError && isFileEmpty) {
        return 100;
      }

      return presenter.downloadProgress?.downloadPercent || 0;
    },

    get hasError(): boolean {
      return Boolean(presenter.downloadProgress?.hasError);
    },

    get isWaitingDownloadProcess(): boolean {
      return (
        !presenter.hasError &&
        Boolean(presenter.downloadProgress) &&
        presenter.downloadPercent === 0
      );
    },

    get isDownloadInProcess(): boolean {
      return (
        !presenter.hasError && presenter.downloadPercent > 0 && presenter.downloadPercent < 100
      );
    },

    get isDownloadComplete(): boolean {
      return (
        !presenter.hasError &&
        Boolean(presenter.downloadProgress) &&
        presenter.downloadPercent === 100
      );
    },

    get payload(): FilePayload | null {
      if (!source.messageId) {
        return null;
      }
      const message = presenter.messagesStore?.getMessageById(source.messageId);
      if (!message) {
        return null;
      }
      const payload = getMessagePayload(message, source.payloadId);

      return payload || null;
    },

    get startDownloadTs(): number {
      return presenter.downloadProgress?.startDownloadTs || 0;
    },

    onAbort(): void {
      presenter.downloadProgress?.xhr?.abort();
      presenter.onRemove();
    },

    onRemove(): void {
      presenter.messagesStore?.downloadProgressPerPayload.remove(source.payloadId);
    },

    onDownload(): void {
      if (source.groupId && source.payloadId && source.messageId) {
        Tasks.downloadMessageFile({
          payloadId: source.payloadId,
          groupId: source.groupId,
          messageId: source.messageId,
          startDownloadTs: presenter.startDownloadTs || Date.now(),
        });
      }
    },
  }));

  return presenter;
};
