import { observer } from 'mobx-react';
import React from 'react';

import { AddKeyPressHandler } from 'APP/packages/keyBoardHandler';
import BackIcon from 'ICONS/ic-back.svg';
import { IconButton, IconViewSizes } from 'UIKIT/IconButton';

import { useThreadInfoPresenter } from './ThreadInfo.presenter';
import styles from './ThreadInfo.styles.m.css';

export const ThreadInfo = observer(() => {
  const presenter = useThreadInfoPresenter();

  return (
    <div className={styles.container}>
      <AddKeyPressHandler onEsc={presenter.onBack} />
      <IconButton
        className={styles.back}
        Icon={BackIcon}
        onClick={presenter.onBack}
        size={IconViewSizes.Big}
        iconSize={IconViewSizes.Medium}
        theme="8"
      />
      {presenter.title}
    </div>
  );
});
