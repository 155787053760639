import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import CloseIcon from 'ICONS/close.svg';
import { Avatar, AvatarTypes } from 'UIKIT/Avatar/Avatar';
import { IconButton } from 'UIKIT/IconButton';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { BotButtons } from '../../components/BotButtons/BotButtons';
import { useClosePopupOnNavigation } from '../../hooks/useClosePopupOnNavigation';
import {
  IBotDetailedInfoPopupParams,
  useBotDetailedInfoPopupPresenter,
} from './BotDetailedInfoPopup.presenter';
import styles from './BotDetailedInfoPopup.styles.m.css';

export const BotDetailedInfoPopup: FC<ICommonPopupsProps<IBotDetailedInfoPopupParams>> = observer(
  (props) => {
    const bot = props.params!.bot;
    const popupInstance = props.popupInstance;
    const { onOpenBot } = useBotDetailedInfoPopupPresenter(props);
    useClosePopupOnNavigation(popupInstance);

    return (
      <Popup
        isOpened
        onClose={popupInstance.close}
      >
        <div className={styles.container}>
          <IconButton
            className={styles.close}
            Icon={CloseIcon}
            onClick={popupInstance.close}
          />
          <div className={styles.info}>
            <Avatar
              type={AvatarTypes.Square_50}
              title={bot.userName}
              url={bot.avatarUrl}
              backgroundColorIndex={bot.avatarColorIndex}
            />
            <div className={styles.content}>
              <div className={classNames(styles.title, styles.ellipsis)}>{bot.userName}</div>
              {bot.categoryInfo && (
                <div className={classNames(styles.category, styles.ellipsis)}>
                  {bot.categoryInfo.displayName}
                </div>
              )}
              {bot.nickName && (
                <div className={classNames(styles.nickName, styles.ellipsis)}>@{bot.nickName}</div>
              )}
              <BotButtons
                bot={bot}
                onOpenBot={onOpenBot}
              />
            </div>
          </div>
          <div className={styles.description}>
            <div>{bot.description}</div>
          </div>
        </div>
      </Popup>
    );
  }
);
