import { observer } from 'mobx-react';
import React from 'react';

import { AutoplayingLoopAudio } from 'APP/components/AutoplayingLoopAudio/AutoplayingLoopAudio';
import { useTranslation } from 'APP/packages/translations';
import busySound from 'ROOT/www/sound/busy.mp3';
import callingSound from 'ROOT/www/sound/ringback.mp3';
import { Portal } from 'UIKIT/Portal/Portal';

import usePresenter from './presenter';

const AudioSources = () => {
  const { t } = useTranslation();
  const presenter = usePresenter();

  if (!presenter.hasCalls) {
    return null;
  }

  return (
    <Portal>
      {presenter.calls.map((call) => {
        if (call.isBusy || call.isNoAnswer) {
          return (
            <AutoplayingLoopAudio
              key={call.groupId + '-busy'}
              src={busySound}
              permissionText={t('calls_hear_outgoing_call')}
              volume={0.5}
            />
          );
        }

        if (call.isCalling) {
          return (
            <AutoplayingLoopAudio
              key={call.groupId + '-calling'}
              src={callingSound}
              permissionText={t('calls_hear_outgoing_call')}
              volume={0.5}
            />
          );
        }

        return null;
      })}
    </Portal>
  );
};

export default observer(AudioSources);
