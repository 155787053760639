import { useLocalStore } from 'mobx-react';

import { useTranslation } from 'APP/packages/translations';
import ArticleIcon from 'ICONS/ic-article.svg';
import FilesIcon from 'ICONS/ic-document_stroke.svg';
import ImgIcon from 'ICONS/ic-img.svg';
import LinkIcon from 'ICONS/ic-link.svg';
import MicIcon from 'ICONS/ic-mic-on.svg';
import MusicIcon from 'ICONS/ic-music.svg';
import Entities from 'STORE';

export default ({ groupId, actions }) => {
  const { t } = useTranslation();
  const presenter = useLocalStore(() => ({
    get items() {
      const group = Entities.GroupsStore.getGroupById(groupId);
      if (!group) {
        return [];
      }
      const sharedData = group.sharedData;

      return [
        {
          key: 'media',
          Icon: ImgIcon,
          title: t('common_media'),
          onClick: actions.openMedia,
          count: sharedData.media.count,
          isVisible: sharedData.media.count > 0 && !group.isSavedMessages,
        },
        {
          key: 'files',
          Icon: FilesIcon,
          title: t('common_files'),
          onClick: actions.openFiles,
          count: sharedData.files.count,
          isVisible: sharedData.files.count > 0,
        },
        {
          key: 'links',
          Icon: LinkIcon,
          title: t('common_links'),
          onClick: actions.openLinks,
          count: sharedData.links.count,
          isVisible: sharedData.links.count > 0,
        },
        {
          key: 'audio',
          Icon: MusicIcon,
          title: t('common_audio_files'),
          onClick: actions.openAudioFiles,
          count: sharedData.audio.count,
          isVisible: sharedData.audio.count > 0,
        },
        {
          key: 'voices',
          Icon: MicIcon,
          title: t('common_voice'),
          onClick: actions.openVoices,
          count: sharedData.voices.count,
          isVisible: sharedData.voices.count > 0,
        },
        {
          key: 'articles',
          Icon: ArticleIcon,
          title: t('common_articles'),
          onClick: actions.openArticles,
          count: sharedData.articles.count,
          isVisible: sharedData.articles.count > 0,
        },
      ].filter((item) => item.isVisible);
    },
  }));

  return presenter;
};
