const tagToSymbols = {
  b: '**',
  strong: '**',
  i: '*',
  em: '*',
  u: '__',
  s: '~~',
  strike: '~~',
};

const tagToNewLine = {
  br: { ignoreInNewLine: false },
  div: { ignoreInNewLine: true },
};

const getReplacingChar = (text, tag) => {
  if (tagToNewLine[tag].ignoreInNewLine) {
    const match = text.match(new RegExp(`<${tag}(.*?)>`));
    if (match && (match.index === 0 || text[match.index - 1] === '\n')) {
      return '';
    }
  }

  return '\n';
};

export const replaceHtmlTagsToSpecialSymbols = (value) => {
  const div = document.createElement('div');
  div.innerHTML = value;

  let text = value;

  const nodes = div.getElementsByTagName('*');

  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];

    const lowercaseTag = node.tagName.toLowerCase();

    if (lowercaseTag === 'a') {
      text = text.replace(new RegExp(`<a(.*?)>`), '[');
      text = text.replace(new RegExp(`</a>`), `](${node.getAttribute('href')})`);
      continue;
    }

    if (tagToNewLine[lowercaseTag]) {
      const replaceTo = getReplacingChar(text, lowercaseTag);

      text = text.replace(new RegExp(`<${lowercaseTag}(.*?)>`), replaceTo);
      text = text.replace(new RegExp(`</${lowercaseTag}>`), '');
      continue;
    }

    // if we doesn't support tag it's replace to ''
    const symbols = tagToSymbols[lowercaseTag] || '';

    text = text.replace(new RegExp(`<${lowercaseTag}(.*?)>`, 'g'), symbols);
    text = text.replace(new RegExp(`</${lowercaseTag}>`, 'g'), symbols);
  }

  div.innerHTML = text;

  return div.innerText;
};
