/* global IS_DEVTOOLS */
import { Appearance } from 'STORE/Appearance/Appearance';
import { Toast } from 'STORE/Popups/Toast/Toast';

import { App } from './App/App';
import AuthStore from './AuthStore';
import { BackgroundCalls } from './BackgroundCalls/BackgroundCalls';
import CachedImages from './CachedImages';
import { CallInvitation } from './CallInvitation/CallInvitation';
import { Calls } from './Calls/Calls';
import { CatalogStore } from './CatalogStore/CatalogStore';
import { ClientConfig } from './ClientConfig/ClientConfig';
import { CountersStore } from './CountersStore/CountersStore';
import { Feedback } from './Feedback';
import { GlobalVideoPlayer } from './GlobalVideoPlayer/GlobalVideoPlayer';
import { Groups as GroupsStore } from './Groups';
import { IncomingCalls } from './IncomingCalls/IncomingCalls';
import InputPanelsStore from './InputPanelsStore';
import { ActiveStream } from './LiveStreaming/ActiveStream';
import BackgroundStreams from './LiveStreaming/BackgroundStreams';
import { Marketplace } from './Marketplace/Marketplace';
import { MiniPlayer } from './MiniPlayer/MiniPlayer';
import { Newsfeed } from './Newsfeed/Newsfeed';
import { Onboarding } from './Onboarding/Onboarding';
import { PrivacySettings } from './PrivacySettings/PrivacySettings';
import { PushNotifications } from './PushNotifications/PushNotifications';
import { ReactionsStore } from './ReactionsStore/ReactionsStore';
import { Rewards } from './Rewards/Rewards';
import { ScheduledEvents } from './ScheduledEvents/ScheduledEvents';
import Sessions from './Sessions';
import { SpacesStore } from './Spaces/Spaces';
import { Stickers } from './Stickers/Stickers';
import { UserPreferences } from './UserPreferences/UserPreferences';
import UsersStore from './Users';
import OngoingCall from './ViewModels/Calls/OngoingCall';
import ChatStore from './ViewModels/ChatStore';
import { InputPanel } from './ViewModels/InputPanel';
import ViewedMessages from './ViewedMessages';

export class Root {
  constructor() {
    this.App = new App();
    this.AuthStore = new AuthStore(this);
    this.CachedImages = new CachedImages();
    this.Counters = new CountersStore();
    this.GroupsStore = new GroupsStore(this);
    this.spacesStore = new SpacesStore();
    this.sessionsStore = new Sessions(this);
    this.UsersStore = new UsersStore();
    this.viewedMessages = new ViewedMessages();
    this.stickersStore = new Stickers();
    this.pushNotifications = new PushNotifications();
    this.InputPanelsStore = new InputPanelsStore(this);
    this.clientConfig = new ClientConfig();
    this.userPreferences = new UserPreferences();
    this.feedback = new Feedback();
    this.rewards = new Rewards();
    this.callInvitation = new CallInvitation();
    // incoming calls store init has to go before calls store init
    // in order to keep correct order of reactions
    this.IncomingCalls = new IncomingCalls(this);
    this.BackgroundCalls = new BackgroundCalls();
    this.Calls = new Calls(this);
    this.appearance = new Appearance();
    this.PrivacySettings = new PrivacySettings();
    this.onboarding = new Onboarding();
    this.marketplace = new Marketplace();
    this.newsfeed = new Newsfeed();
    this.catalogStore = new CatalogStore();
    this.reactionsStore = new ReactionsStore();

    this.BackgroundStreams = new BackgroundStreams();
    this.ActiveStream = new ActiveStream();

    this.GlobalVideoPlayer = new GlobalVideoPlayer();
    this.miniPlayer = new MiniPlayer();
    this.scheduledEvents = new ScheduledEvents();
    // ViewModels
    this.ChatStore = new ChatStore(this);
    this.InputPanel = new InputPanel(this);
    this.OngoingCall = new OngoingCall(this);
    this.toast = new Toast();
    this.initReactionsExplicitly();
  }

  initReactionsExplicitly() {
    for (const store in this) {
      if (this[store].initReactions) {
        this[store].initReactions();
      }
    }
  }

  reset() {
    for (const store in this) {
      if (this[store].reset) {
        this[store].reset();
      }
    }
  }
}

const stores = new Root();

if (IS_DEVTOOLS) {
  // For easier debugging
  window.Entities = stores;
}

export default stores;
