import React, { FC, memo } from 'react';

import useSyncAnimation from '../hooks/useSyncAnimations';
import styles from './NewsfeedPlaceholder.styles.m.css';
import { NewsfeedPlaceholderItem } from './NewsfeedPlaceholderItem/NewsfeedPlaceholderItem';

export const NewsfeedPlaceholder: FC = memo(() => {
  const { isFirstAnimated, isSecondAnimated } = useSyncAnimation({
    firstTimeout: 1100,
    secondTimeout: 1100,
  });

  return (
    <div className={styles.container}>
      <NewsfeedPlaceholderItem isAnimated={isFirstAnimated} />
      <NewsfeedPlaceholderItem isAnimated={isSecondAnimated} />
    </div>
  );
});
