import { action, computed, observable } from 'mobx';

import { RouterPage } from 'APP/router/constants';
import { IThreadInfo } from 'STORE/Groups/Thread/types';

import { Group } from '../Group';

export class Thread extends Group {
  @observable threadInfo: IThreadInfo | null = null;

  @action
  setThreadInfo({ messageId, groupId }: IThreadInfo): void {
    this.threadInfo = { messageId, groupId };
  }

  @computed
  get parentGroup(): Group | null {
    if (!this.threadInfo?.groupId) {
      return null;
    }
    return this.groups.getGroupById(this.threadInfo?.groupId);
  }

  get isThread(): boolean {
    return true;
  }

  get canInviteUsers(): boolean {
    return false;
  }

  get route(): string {
    return RouterPage.Thread;
  }

  get canMentionAll(): boolean {
    return false;
  }

  get canBeScheduled(): boolean {
    return false;
  }

  @computed
  get canLeaveComment(): boolean {
    return false;
  }
}
