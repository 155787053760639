import { getUsersDisplayData } from 'APP/model/user/userModel';
import Entities from 'APP/store';

import chunk from './chunk';
import preloadAvatarsForMessages from './preloadAvatarsForMessages';

const GET_USERS_DISPLAY_DATA_CHUNK_SIZE = 25;

async function getUsers(chunks) {
  try {
    return await Promise.all(
      chunks.map((userIds) => {
        return getUsersDisplayData({ userIds });
      })
    );
  } catch (error) {
    console.error(error);
  }

  return [];
}

export async function loadUnknownUsersByIds({ usersIds }) {
  const unknownUsersIds = usersIds.filter((userId) => {
    return Number(userId) && !Entities.UsersStore.getUserById(userId);
  });

  if (!unknownUsersIds.length) {
    return;
  }

  const chunks = chunk(unknownUsersIds, GET_USERS_DISPLAY_DATA_CHUNK_SIZE);

  const responses = await getUsers(chunks);

  if (responses.length === 0) {
    return;
  }

  const users = responses.reduce((memo, usersDisplayData) => {
    return [...memo, ...usersDisplayData];
  }, []);

  await preloadAvatarsForMessages(users);

  Entities.UsersStore.add(users);
}
