import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import GemLogo from 'APP/images/gem-logo-main.svg';
import { usePreviewPresenter } from 'APP/unauthorized/CallPreview/CallPreview.Preview.presenter';
import AudioSources from 'MAIN/AudioSources';
import CallStateHandler from 'MAIN/Modules/CallStateHandler';
import { CallView } from 'MAIN/OngoingCall/CallView/CallView';

import { CallDescription } from './CallDescription/CallDescription';
import { useCallPreviewPresenter } from './CallPreview.presenter';
import styles from './CallPreview.styles.m.css';
import { Preview } from './Preview/Preview';
import { StartCall } from './StartCall/StartCall';

export const CallPreview: FC = observer(() => {
  const previewPresenter = usePreviewPresenter();

  const presenter = useCallPreviewPresenter(
    previewPresenter.onCallStart,
    previewPresenter.audioMuted,
    previewPresenter.videoMuted
  );

  return (
    <div
      className={classNames(styles.container, { [styles.fixedContainer]: !!presenter.ongoingCall })}
    >
      <div className={styles.header}>
        <img
          className={styles.logo}
          src={GemLogo}
          alt=""
        />
      </div>
      <div className={styles.content}>
        <CallDescription
          isGroupCall={presenter.isGroupCall}
          className={styles.description}
        />
        <Preview presenter={previewPresenter} />
        <StartCall
          isGroupCall={presenter.isGroupCall}
          onStart={presenter.onStart}
          isLoading={presenter.isLoading}
        />
      </div>
      {presenter.ongoingCall && (
        <>
          <CallView />
          <CallStateHandler />
          <AudioSources />
        </>
      )}
    </div>
  );
});
