export enum GlobalVideoPlayerType {
  Default = 'default',
  Mini = 'mini',
  Round = 'round',
  MiniRound = 'miniRound',
}

export interface IGlobalVideoPlayerConfig {
  volume: number;
  isMuted: boolean;
  speed: number;
}
