import { observer } from 'mobx-react';
import React from 'react';

import { UsersPopover } from 'APP/components/UsersPopover/UsersPopover';

import { useMessageViewsPopoverPresenter } from './MessageViewsPopover.presenter';

export const MessageViewsPopover = observer(({ message, tooltip, onOpenReactionPopup }) => {
  const presenter = useMessageViewsPopoverPresenter({ message, tooltip, onOpenReactionPopup });

  if (!presenter.isOpened) {
    return null;
  }

  return (
    <UsersPopover
      isOpened={presenter.isOpened}
      data={presenter.users}
      moreCount={presenter.moreCount}
      onClick={presenter.onMoreClick}
    />
  );
});
