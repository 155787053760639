import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import { ENTER_KEY_CODE, DOWN_ARROW_KEY_CODE, DELETE_CODE } from 'APP/constants/keyCodes';

import { FOCUS_NAMES, MAX_TITLE_LENGTH } from '../../constants';
import services from '../../services';
import useTextHelper from '../presenters/textHelper';

export default ({ parentPresenter, ref }) => {
  const textHelper = useTextHelper();
  const presenter = useLocalStore(() => ({
    value: parentPresenter.core.payloads.title,

    init: () => {
      if (parentPresenter.actualFocusPosition === FOCUS_NAMES.TITLE) {
        ref.current.focus();
        textHelper.setFocusToTheEnd(ref);
      }
    },

    setValue: (event) => {
      presenter.value = event.target.innerHTML;
      parentPresenter.setTitle(presenter.value);
    },

    onEnter: (event) => {
      if (event.keyCode === ENTER_KEY_CODE && event.shiftKey === false) {
        event.preventDefault();
        event.stopPropagation();

        ref.current.blur();

        const firstTextPosition = services.focus.getFirstTextPositionOnPayloads(
          parentPresenter.data
        );

        // maybe 0
        if (firstTextPosition !== null) {
          parentPresenter.setActualPosition(firstTextPosition);

          return;
        }

        parentPresenter.setActualPosition(FOCUS_NAMES.CREATOR_PAYLOAD);
      }

      if (event.keyCode === ENTER_KEY_CODE && event.shiftKey === true) {
        event.preventDefault();
        event.stopPropagation();

        return;
      }

      if (event.keyCode === DOWN_ARROW_KEY_CODE) {
        parentPresenter.handleArrowsKeyboard(null);
      }

      if (presenter.value.length >= MAX_TITLE_LENGTH && event.keyCode !== DELETE_CODE) {
        event.preventDefault();
        event.stopPropagation();
      }
    },

    onPaste: (event) => {
      if (presenter.value.length >= MAX_TITLE_LENGTH) {
        event.preventDefault();
        event.stopPropagation();

        return;
      }

      const maxTextLength = MAX_TITLE_LENGTH - presenter.value.length;

      textHelper.onPaste(event, maxTextLength);
    },

    onFocus: () => {
      ref.current.focus();
      parentPresenter.setActualPosition(FOCUS_NAMES.TITLE);
    },

    onDrop: (event) => {
      event.preventDefault();
    },
  }));

  useEffect(() => {
    presenter.init();
  }, [parentPresenter.actualFocusPosition]);

  useEffect(() => {
    ref.current.innerHTML = parentPresenter.core.payloads.title;
    textHelper.setFocusToTheEnd(ref);
  }, [parentPresenter.core.isEditMode]);

  return presenter;
};
