import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { MemberList } from 'MAIN/OngoingCall/Shared/MemberList/MemberList';
import { Call } from 'STORE/Calls/Call/Call';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';

import styles from './MembersPanel.styles.m.css';
import { MembersPanelHeader } from './MembersPanelHeader/MembersPanelHeader';

interface IMembersPanelProps {
  call: Call;
}

export const MembersPanel: FC<IMembersPanelProps> = observer(({ call }) => {
  return (
    <div className={styles.container}>
      <MembersPanelHeader call={call} />
      <div className={classNames(styles.memberListContainer, customScrollStyles.container)}>
        <MemberList />
      </div>
    </div>
  );
});
