import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { useTranslation } from 'APP/packages/translations';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { SpaceMarketForm } from '../../components/SpaceMarketForm/SpaceMarketForm';
import { useCloseFormPopup } from '../../hooks/useCloseFormPopup';
import {
  IAddSpaceMarketPopupPresenterParams,
  useAddSpaceMarketPopupPresenter,
} from './AddSpaceMarketPopup.presenter';

type IAddSpaceMarketPopupProps = IAddSpaceMarketPopupPresenterParams;

export const AddSpaceMarketPopup: FC<ICommonPopupsProps<IAddSpaceMarketPopupProps>> = observer(
  (props) => {
    const { t } = useTranslation();
    const presenter = useAddSpaceMarketPopupPresenter(props);
    const closeForm = useCloseFormPopup(props, t('common_cancel_action_confirmation'));

    return (
      <Popup
        title={t('space_addition_store')}
        isOpened
        onClose={closeForm.closePopup}
      >
        <SpaceMarketForm
          initialValues={presenter.initialValues}
          onSubmit={presenter.onSubmit}
          onFormStateChange={closeForm.onFormStateChange}
        />
      </Popup>
    );
  }
);
