interface IGetHelperTextProps {
  isValidating: boolean;
  validatingText?: string;
  hasError: boolean;
  errorText?: string;
  hasSuccess: boolean;
  successText?: string;
  helperText?: string;
}

export const getHelperText = ({
  isValidating,
  validatingText,
  hasError,
  errorText,
  hasSuccess,
  successText,
  helperText,
}: IGetHelperTextProps) => {
  if (isValidating) {
    return validatingText;
  }

  if (hasError) {
    return errorText;
  }

  if (hasSuccess) {
    return successText;
  }

  if (helperText) {
    return helperText;
  }

  return null;
};
