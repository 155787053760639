import React from 'react';

import { MediaPreview } from 'APP/components/MediaPreview/MediaPreview';
import { MEDIA_PREVIEW_MAX_SIZE } from 'APP/constants/articles';
import PlayIcon from 'ICONS/ic-play-32.svg';

import styles from './styles.m.css';

export const Video = ({ message, openMedia }) => {
  const onClick = () => {
    openMedia(message.id);
  };

  return (
    <div className={styles.container}>
      <MediaPreview
        className={styles.preview}
        payload={message.payload}
        maxWidth={MEDIA_PREVIEW_MAX_SIZE.width}
        maxHeight={MEDIA_PREVIEW_MAX_SIZE.height}
        placeholderQuality={0.3}
        onClick={onClick}
        payloadId={message.payload?.id || message.id}
        groupId={message.groupId}
        messageId={message.messageId || message.id}
      />

      <div className={styles.iconContainer}>
        <PlayIcon
          className={styles.icon}
          onClick={onClick}
        />
      </div>
    </div>
  );
};
