/**
 * Rest API
 * Documentation Rest API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * system.call.started
 */
export interface CallStartedResponseData { 
    payloadType: string;
    initiator: string;
    initiatorNickName?: string;
    channelId: string;
    callType: CallStartedResponseDataCallTypeEnum;
    startTs: string;
}
export enum CallStartedResponseDataCallTypeEnum {
    P2P = 'P2P',
    Group = 'GROUP',
    Conference = 'CONFERENCE',
    Stream = 'STREAM'
};



