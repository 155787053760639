import { useLocalStore, useAsObservableSource } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { useTranslation } from 'APP/packages/translations';
import { RouterState } from 'APP/router/constants';
import Entities from 'APP/store';

export default ({ channel }) => {
  const { t } = useTranslation();
  const source = useAsObservableSource({ channel });

  const presenter = useLocalStore(() => ({
    isDisabled: false,

    get avatarColorIndex() {
      const id = source.channel.groupId?.toString() || '0';
      return id[id.length - 1];
    },

    get subscribeCaption() {
      return presenter.isSubscribed
        ? t('news_feed_unsubscribe')
        : t('channels:channel_catalog_subscribe');
    },

    get isViewDescription() {
      return String(source.channel.description || '').trim().length > 0;
    },

    get isSubscribed() {
      const group = Entities.GroupsStore.getGroupById(source.channel.id);
      if (!group) {
        return false;
      }

      return !group.withoutMe;
    },

    async onClick() {
      const state = {
        [RouterState.hasGoBack]: true,
      };

      await Tasks.group.setActiveGroup({ groupId: source.channel.id, state });
    },

    async onSubscribe() {
      if (presenter.isDisabled) {
        return;
      }

      const groupId = source.channel.id;
      presenter.isDisabled = true;

      if (presenter.isSubscribed) {
        const result = await Tasks.group.leaveGroup({ groupId });
        if (!result?.error) {
          Entities.toast.show(t('news_feed_you_unsubscribed'));
        }
      } else {
        const result = await Tasks.group.joinGroup({ groupId });
        if (result) {
          Entities.toast.show(t('news_feed_you_subscribed'));
        }
      }

      presenter.isDisabled = false;
    },
  }));

  return presenter;
};
