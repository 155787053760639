export default Object.freeze({
  env: {
    isProd: false,
    isPreprod: false,
    isStage2: false,
    isStage1: false,
  },
  features: {},
  supportEmail: 'support@gemspace.com',
});
