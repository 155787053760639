import { useAsObservableSource, useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import Entities from 'APP/store';
import type { MessagesStore } from 'APP/store/Messages/Messages';
import type { Group } from 'STORE/Groups/Group';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

export interface ISystemMessageLayoutPresenter {
  group: Group | null;
  hasSelectedMessages: boolean;
  onMessageSelect(): void;
  messagesStore: MessagesStore | null;
  withMeInAdmins: boolean;
  isShowMenu: boolean;
}

export const useSystemMessageLayoutPresenter = (
  message: ChatMessage
): ISystemMessageLayoutPresenter => {
  const source = useAsObservableSource({ message });

  const presenter = useLocalStore<ISystemMessageLayoutPresenter>(() => ({
    get group(): Group | null {
      return Entities.GroupsStore.getGroupById(message.groupId);
    },

    get messagesStore(): MessagesStore | null {
      return presenter.group?.messagesStore || null;
    },

    get hasSelectedMessages(): boolean {
      return Boolean(presenter.messagesStore?.multiSelect?.hasSelectedMessages);
    },

    get withMeInAdmins(): boolean {
      return Boolean(presenter.group?.withMeInAdmins);
    },

    get isShowMenu(): boolean {
      return !this.hasSelectedMessages && this.withMeInAdmins;
    },

    onMessageSelect(): void {
      Tasks.messaging.selectMessage({
        groupId: source.message.group?.id,
        messageId: source.message.id,
      });
    },
  }));

  return presenter;
};
