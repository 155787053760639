/* global clearTimeout, setTimeout, Promise */

export default async (timeout, callback) => {
  if (timeout.current) {
    clearTimeout(timeout.current.id);
  }

  return new Promise((resolve) => {
    const prevResolves = timeout.current ? timeout.current.resolves : [];

    const timeoutId = setTimeout(async () => {
      const callbackResult = await callback();

      prevResolves.forEach((resolve) => resolve()); // we need to resolve prev validations
      resolve(callbackResult);
    }, 300);

    timeout.current = {
      id: timeoutId,
      resolves: prevResolves.concat(resolve),
    };
  });
};
