import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { ServerErrorCode } from 'APP/model/error/error.constants';
import { useTranslation } from 'APP/packages/translations';
import Entities from 'APP/store';

import localTasks from './Tasks';

const RETRY_DELAY = 5000;
const PLACEHOLDER_HEIGHT = 64;

export const useSearchMessagesPresenter = ({ listRef }) => {
  const { t } = useTranslation();
  const source = useAsObservableSource({ listRef: listRef.current });
  const presenter = useLocalStore(() => ({
    value: '',
    isIndexing: false,
    results: [],
    cursor: null,
    isLoading: false,

    reset() {
      presenter.value = '';
      presenter.isIndexing = false;
      presenter.results = [];
      presenter.cursor = null;
      presenter.isLoading = false;
      clearTimeout(presenter.timer);
    },

    onChange(value) {
      const prevValue = presenter.value;
      presenter.value = value;
      if (value.trim() !== prevValue.trim()) {
        presenter.cursor = null;
        if (value.trim().length > 0) {
          clearTimeout(presenter.timer);
          presenter.timer = setTimeout(presenter.search, 250);
        } else {
          presenter.reset();
          presenter.value = value;
        }
      }
    },

    async search() {
      const value = presenter.value;
      presenter.isLoading = true;
      const { results, cursor, error } = await localTasks.search({
        value,
        groupId: presenter.group.id,
        cursor: presenter.cursor,
      });
      if (value === presenter.value) {
        presenter.isIndexing = false;
        if (error) {
          if (error.message === ServerErrorCode.SearchIndexingInProgress) {
            presenter.isIndexing = true;
            presenter.timer = setTimeout(presenter.search, RETRY_DELAY);
          }
          return;
        }
        presenter.results = [...(presenter.cursor ? presenter.results : []), ...results];
        presenter.cursor = cursor;
      }
      presenter.isLoading = false;
    },

    get group() {
      return Entities.GroupsStore.activeGroup;
    },
    get isShow() {
      return Entities.ChatStore.isShowSearch;
    },
    get title() {
      return t('common_search_hint');
    },
    get hasMore() {
      return Boolean(presenter.cursor);
    },
    get isShowLoader() {
      return presenter.isIndexing;
    },
    get noResultsMessage() {
      if (presenter.isIndexing) {
        return t('common_indexing');
      }
      if (presenter.value.trim().length === 0) {
        return t('search_enter_message_text');
      }
      return t('no_results_found');
    },
    close() {
      Entities.ChatStore.hideSearch();
      presenter.reset();
    },
    keyExtractor({ id }) {
      return id;
    },
    getNextCursor() {
      presenter.search();
    },
    goToMessage(message) {
      if (presenter.activeMessage) {
        presenter.activeMessage.isActive = false;
      }
      message.isActive = true;
      presenter.activeMessage = message;

      Tasks.messaging.focusMessage({
        groupId: presenter.group.id,
        messageId: message.id,
      });
    },
    getCountPlaceholders() {
      if (source.listRef && !presenter.cursor) {
        const count =
          Math.floor(source.listRef.offsetHeight / PLACEHOLDER_HEIGHT) - presenter.results.length;

        return count < 0 ? 0 : count;
      }

      return 1;
    },
  }));

  useEffect(() => {
    return presenter.reset;
  }, []);

  return presenter;
};
