import Tasks from 'APP/Tasks';
import { IClientConfigProperty } from 'APP/model/auth/authModel.types';
import { getUserReactions } from 'APP/model/reactions/reactionsModel';
import { IUserReaction } from 'APP/model/reactions/reactionsModel.types';
import { storage } from 'APP/packages/storage';
import Entities from 'APP/store';

const FILE_LOADED_COUNT = 3;

const loadReactionsPack = async (property: IClientConfigProperty): Promise<IUserReaction[]> => {
  try {
    const reactions = await getUserReactions();

    if (reactions.length === 0) {
      return [];
    }

    Entities.reactionsStore.setReactions(reactions);
    storage.reactionsPack.setReactionPack({
      lastUpdateTime: parseInt(property.value),
      pack: reactions,
    });

    return reactions;
  } catch {
    return [];
  }
};

const loadReactions = async (reactions: IUserReaction[]): Promise<void> => {
  if (reactions.length === 0) {
    return;
  }

  const loadPromises: Promise<Record<string, any> | null>[] = [];
  reactions.slice(0, FILE_LOADED_COUNT).forEach((reaction) => {
    loadPromises.push(Tasks.cache.loadLottieFile(reaction.animationUrl));
  });

  await Promise.all(loadPromises);
  return await loadReactions(reactions.slice(FILE_LOADED_COUNT));
};

export async function handleReactions(property: IClientConfigProperty): Promise<void> {
  const data = storage.reactionsPack.getReactionPack();

  if (!data || !data.lastUpdateTime || parseInt(property.value) > data.lastUpdateTime) {
    const reactions = await loadReactionsPack(property);
    loadReactions(reactions);
  } else {
    Entities.reactionsStore.setReactions(data.pack);
  }
}
