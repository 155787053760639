import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { OTHER_CATEGORY_ID } from 'APP/constants/categories';

import { ISelectCategoryFormLocalStore } from './SelectCategoryForm.types';

export const useSelectCategoryFormPresenter = () => {
  const presenter = useLocalStore<ISelectCategoryFormLocalStore>(() => ({
    categories: [],

    async loadCategories() {
      const categories = await Tasks.search.getAllCategoriesInfo();
      presenter.categories = categories.filter((category) => category.id !== OTHER_CATEGORY_ID);
    },
  }));

  useEffect(() => {
    presenter.loadCategories();
  }, []);

  return presenter;
};
