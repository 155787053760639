import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { MessageViewPageType } from 'MAIN/ChatView/Messages/Messages.types';
import { useStickyDate } from 'MAIN/hooks/stickyDate';

import { GroupedMessages } from '../GroupedMessages/GroupedMessages';
import styles from '../Messages.styles.m.css';
import { MessagesContentWrapper } from '../MessagesContentWrapper/MessagesContentWrapper';
import { MessagesEmpty } from '../MessagesEmpty/MessagesEmpty';
import { usePinMessagesPresenter } from './PinMessages.presenter';

interface IPinMessagesProps {
  groupId: string;
}

export const PinMessages: FC<IPinMessagesProps> = observer(({ groupId }) => {
  const { scrollRef, stickyClassName, onScroll } = useStickyDate();
  const presenter = usePinMessagesPresenter({ groupId, scrollRef });

  return (
    <>
      <div className={styles.container}>
        <MessagesEmpty />

        {presenter.pinnedMessages.length > 0 && (
          <MessagesContentWrapper
            scrollRef={scrollRef}
            onScroll={onScroll}
          >
            {presenter.messagesGroups.map(([date, groupedMessages]) => (
              <GroupedMessages
                key={date}
                date={date}
                groupedMessages={groupedMessages}
                scrollRef={scrollRef}
                messageViewPageType={MessageViewPageType.AllPins}
                stickyClassName={stickyClassName}
              />
            ))}
          </MessagesContentWrapper>
        )}
      </div>
    </>
  );
});
