import { storage } from 'APP/packages/storage';
import Entities from 'STORE';
import { IVideoPlayerConfig } from 'UIKIT/Media/VideoPlayer/VideoPlayer.types';

export function setPlayerConfig(config: Partial<IVideoPlayerConfig>, saveNewValue = true): void {
  const globalVideoPlayer = Entities.GlobalVideoPlayer;
  globalVideoPlayer.setConfig(config);

  if (saveNewValue) {
    storage.videoPlayerConfig.set(JSON.stringify(globalVideoPlayer.config));
  }
}

export function initPlayerConfig(): void {
  const config = storage.videoPlayerConfig.get();

  if (config) {
    try {
      setPlayerConfig(JSON.parse(config), false);
    } catch {
      // nothing have to do
    }
  }
}
