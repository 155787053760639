import Tasks from 'APP/Tasks';
import { TranscriptionStatus } from 'APP/constants/messages';
import { UpdateReason } from 'APP/constants/scroll';
import api from 'APP/packages/api';
import Entities from 'APP/store';

export default async (message) => {
  const group = Entities.GroupsStore.getGroupById(message.groupId);
  if (!group) {
    return;
  }
  if (message.payload.transcriptionStatus === TranscriptionStatus.NotTranscribed) {
    try {
      message.payload.setTranslationStatus(TranscriptionStatus.InProgress);
      const newMessage = await api.messaging.transcribeVoiceMessage({
        groupId: message.group.id,
        messageId: message.id,
      });

      if (newMessage) {
        Tasks.messaging.updateOldOrFakeMessages({
          messages: [newMessage],
          groupId: group.id,
        });
      }
    } catch (error) {
      message.payload.setTranslationStatus(TranscriptionStatus.TranscriptionFailed);
      console.error(error);
    }
  }

  if (group?.isActive) {
    Entities.ChatStore.setScrollChanged(UpdateReason.EditMessage, {
      messageIds: [message.id],
      isAnimated: true,
    });
  }
};
