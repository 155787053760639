import { getFixedSizeImage } from 'APP/packages/imageResizer';

const avatarTypesToSizes = {
  'round-0': { width: 32, height: 32 },
  'round-5': { width: 40, height: 40 },
  'round-10': { width: 48, height: 48 },
  'round-20': { width: 72, height: 72 },
  'round-25': { width: 96, height: 96 },
  'round-30': { width: 104, height: 104 },
  'round-40': { width: 120, height: 120 },
  'round-50': { width: 184, height: 184 },
  'square-0': { width: 48, height: 48 },
  'square-10': { width: 56, height: 56 },
  'square-20': { width: 72, height: 72 },
  'square-30': { width: 120, height: 120 },
  'square-40': { width: 160, height: 160 },
  'square-50': { width: 184, height: 184 },
  'square-60': { width: 246, height: 200 },
};

export default (type, url) => {
  return getFixedSizeImage(url, {
    ...avatarTypesToSizes[type],
    isNeedCrop: true,
  });
};
