import { EventName } from 'APP/model/events/eventsModel.types';

import { sseConfig } from '../config';

const RECONNECT_DELAY = 20000; //ms

const eventSources: Record<string, EventSource> = {};
const timeout: Record<string, ReturnType<typeof setInterval>> = {}; // save timeout for each connection

function prepareGetParams(token: string, params: Record<string, string> = {}): string {
  const query = new URLSearchParams();

  query.set('Authorization', `Bearer ${token}`);

  Object.keys(params).forEach((key) => {
    query.set(key, params[key]);
  });

  return query.toString();
}

interface IStreamRequestData {
  rootApi: string;
  params?: Record<string, string>;
}

interface IStreamEventHandler {
  eventName: EventName;
  onData(data: any): void;
}

export function openStream(
  requestData: IStreamRequestData,
  callbackMappings: IStreamEventHandler[],
  onError: (data: { target: EventSource }) => void
): (() => void) | null {
  const { rootApi, params } = requestData;

  if (!sseConfig.token) {
    return null;
  }

  const query = prepareGetParams(sseConfig.token, params);

  const sourceUrl = `${sseConfig.apiUrl}/${sseConfig.version}/${rootApi}?${query}`;

  if (eventSources[rootApi]) {
    eventSources[rootApi].close();
  }

  eventSources[rootApi] = new EventSource(sourceUrl);
  const errorHandler = (): void => {
    if (onError) {
      onError({ target: eventSources[rootApi] });
    }
  };

  eventSources[rootApi].onerror = errorHandler;

  if (callbackMappings) {
    callbackMappings.forEach(({ eventName, onData }): void => {
      if (!onData) {
        return;
      }
      eventSources[rootApi].addEventListener(eventName as string, ({ data }) => {
        clearTimeout(timeout[rootApi]);
        timeout[rootApi] = setTimeout(errorHandler, RECONNECT_DELAY);
        onData(JSON.parse(data));
      });
    });
  }

  // checking the life of the SSE
  eventSources[rootApi].addEventListener('keep-alive', () => {
    clearTimeout(timeout[rootApi]);
    timeout[rootApi] = setTimeout(errorHandler, RECONNECT_DELAY);
  });
  timeout[rootApi] = setTimeout(errorHandler, RECONNECT_DELAY);

  return (): void => {
    if (eventSources[rootApi]) {
      clearTimeout(timeout[rootApi]);
      eventSources[rootApi].close();
      delete eventSources[rootApi];
    }
  };
}
