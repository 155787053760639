import { useState, useCallback } from 'react';

import Tasks from 'APP/Tasks';
import { ALERT_TYPES } from 'APP/constants/app';
import uploader from 'APP/packages/file-upload/uploader';

const createPreCroppedImage = (image) => {
  const preCroppedImage = new Image();

  preCroppedImage.setAttribute('src', image);

  return preCroppedImage;
};

export default ({ image, onClose, onUploadImage }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getCroppedImg = useCallback(() => {
    const preCroppedImage = createPreCroppedImage(image);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const maxDimension = croppedAreaPixels.width || croppedAreaPixels.height;

    canvas.width = maxDimension;
    canvas.height = maxDimension;

    ctx.drawImage(
      preCroppedImage,
      croppedAreaPixels.x,
      croppedAreaPixels.y,
      maxDimension,
      maxDimension,
      0,
      0,
      maxDimension,
      maxDimension
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(window.URL.createObjectURL(blob));
      }, 'image/png');
    });
  }, [createPreCroppedImage, croppedAreaPixels, image]);

  const handleUploadImage = useCallback(async () => {
    setIsLoading(true);
    const base64Image = await getCroppedImg();

    const url = await uploader.uploadBase64(base64Image);

    const preloadImage = new Image();
    preloadImage.onload = () => {
      if (onUploadImage) {
        onUploadImage(url);
      }
    };

    preloadImage.src = url;
  }, [onUploadImage, getCroppedImg]);

  const onZoomChange = useCallback(
    (zoom) => {
      if (isLoading) {
        return;
      }

      setZoom(zoom);
    },
    [isLoading]
  );

  const onZoomChangeSlider = useCallback((event) => {
    if (isLoading) {
      return;
    }

    setZoom(event.target.value);
  }, []);

  const onCropComplete = useCallback(
    (croppedArea, croppedAreaPixels) => {
      if (isLoading) {
        return;
      }

      setCroppedAreaPixels(croppedAreaPixels);
    },
    [isLoading]
  );

  const onCropChange = useCallback(
    (crop) => {
      if (isLoading) {
        return;
      }

      setCrop(crop);
    },
    [isLoading]
  );

  const onCropperError = useCallback(() => {
    Tasks.app.addAlert({
      type: ALERT_TYPES.SOMETHING_WRONG_ERROR,
    });
    onClose();
  }, []);

  return {
    crop,
    zoom,
    isLoading,
    onCropperError,
    onCropChange,
    onCropComplete,
    onZoomChangeSlider,
    onZoomChange,
    handleUploadImage,
  };
};
