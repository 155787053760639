import { action, computed, observable } from 'mobx';

import { STREAM_STATUS } from 'APP/constants/stream';
import Entities from 'APP/store';

import Stream from '../Stream';

export class ActiveStream {
  @observable stream = null;

  @action
  createStream({ groupId, previewImageUrl, title }) {
    this.stream = new Stream({
      groupId,
      previewImageUrl,
      title,
      initiatorId: Entities.UsersStore.Me.id,
      status: STREAM_STATUS.INITIATED,
    });
  }

  @action
  setStream(data) {
    this.stream = new Stream(data);
  }

  @action
  reset() {
    if (this.stream) {
      this.stream.destroy();
    }
    this.stream = null;
  }

  @computed
  get hasStream() {
    return !!this.stream;
  }
}
