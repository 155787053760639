import { useLocalStore } from 'mobx-react';

import { moderationModel } from 'APP/model/model';
import { ComplaintType, IMessageComplaint } from 'APP/model/moderation/moderationModel.types';
import { useTranslation } from 'APP/packages/translations';
import Entities from 'APP/store';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { IReportFormData } from '../../components/ReportForm/ReportForm.types';

interface IReportMessagePopupPresenter {
  onSubmit(formData: IReportFormData): Promise<void>;
  onClose(): void;
}

export interface IReportMessagePopupParams {
  groupId: string;
  messageId: string;
}

export function useReportMessagePopupPresenter({
  params,
  popupInstance,
}: ICommonPopupsProps<IReportMessagePopupParams>): IReportMessagePopupPresenter {
  const { groupId, messageId } = params!;
  const { t } = useTranslation();

  const presenter = useLocalStore(() => ({
    async onSubmit(formData: IReportFormData): Promise<void> {
      try {
        await moderationModel.sendComplaint<IMessageComplaint>({
          ...formData,
          complaint: {
            complaintType: ComplaintType.Message,
            groupId,
            messageId,
          },
        });

        Entities.toast.show(t('content_moderation_sent_message'));
      } catch {
        Entities.toast.show(t('something_went_wrong'));
      }

      presenter.onClose();
    },

    onClose(): void {
      popupInstance.close();
    },
  }));

  return presenter;
}
