import { computed } from 'mobx';

import { PayloadType, IOrderedPayloadItem } from 'APP/model/message/messageModel.types';
import { ISharedDataItem } from 'APP/model/sharedData/sharedDataModel.types';
import ArticlePayload from 'STORE/Messages/Message/Payload/Article';
import AudioPayload from 'STORE/Messages/Message/Payload/Audio';

import { SharedDataItem } from '../SharedDataItem/SharedDataItem';

type TSharedAudioItem = ISharedDataItem<AudioPayload>;

export class SharedAudio extends SharedDataItem {
  @computed
  get items(): TSharedAudioItem[] {
    const items: TSharedAudioItem[] = [];
    const messages = this.messages.getFilteredMessages((message) =>
      [PayloadType.AudioMessage, PayloadType.Article].includes(message.payload.payloadType)
    );

    messages.forEach((message) => {
      if (message.payload instanceof ArticlePayload) {
        const payloads = message.payload.payloads.slice().reverse() as IOrderedPayloadItem[];

        payloads.forEach(({ id, payload }) => {
          if (payload instanceof AudioPayload) {
            items.push({
              id,
              messageId: message.id,
              groupId: message.groupId,
              payload,
              serverTime: message.serverTime,
            });
          }
        });
      } else {
        items.push({
          id: message.clientUuid,
          messageId: message.id,
          groupId: message.groupId,
          payload: message.payload,
          serverTime: message.serverTime,
        });
      }
    });

    return items;
  }

  @computed
  get count(): number {
    return this._count || this.items.length;
  }
}
