import { PayloadType } from 'APP/model/message/messageModel.types';
import { replaceHtmlTagsToSpecialSymbols } from 'UTILS/html/replaceHtmlTagsToSpecialSymbols';

const checkText = (text) => {
  return replaceHtmlTagsToSpecialSymbols(text).trim() !== '';
};

export default (draft) => {
  const title = checkText(draft.payload.title);
  const someTextPayload = draft.payload.payloads.some((item) =>
    PayloadType.RichText === item.payload.payloadType ? checkText(item.payload.text) : false
  );

  return title && someTextPayload;
};
