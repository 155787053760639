import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useParams, useRouteMatch } from 'react-router-dom';

import Tasks from 'APP/Tasks';
import { useTranslation } from 'APP/packages/translations';
import { RouterLink } from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import Entities from 'STORE';
import { Group } from 'STORE/Groups/Group';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

export interface IMessagePinLocalStore {
  group: Group | null;
  pinMessages: ChatMessage[];
  currentPinnedMessage: ChatMessage | null;
  isShowSingleMessageUnpin: boolean;
  currentMessageIndex: number;
  unpin(): Promise<void>;
  goToAllPins(): void;
  onClick(): void;
}

export const useMessagePinPresenter = (): IMessagePinLocalStore => {
  const { t } = useTranslation();
  const navigateTo = useNavigateTo();
  const match = useRouteMatch();
  const params = useParams<{ id: string }>();

  const source = useAsObservableSource({ groupId: params.id, match });

  const presenter = useLocalStore<IMessagePinLocalStore>(() => ({
    get group(): Group | null {
      return Entities.GroupsStore.activeGroup;
    },

    get pinMessages(): ChatMessage[] {
      return presenter.group?.pinnedMessages.messages || [];
    },

    get currentMessageIndex(): number {
      const pinMessageIndex = presenter.pinMessages.findIndex((pinMessage) => {
        return pinMessage.id === presenter.currentPinnedMessage?.id;
      });

      return pinMessageIndex + 1;
    },

    get currentPinnedMessage(): ChatMessage | null {
      return presenter.group?.pinnedMessages.currentPinnedMessage || null;
    },

    get isShowSingleMessageUnpin(): boolean {
      return presenter.currentPinnedMessage?.canUnpin && presenter.pinMessages.length === 1;
    },

    async unpin(): Promise<void> {
      const result = await Tasks.app.showConfirm({
        text: t('unpin_message_confirmation'),
        primaryText: t('common_yes'),
        secondaryText: t('common_cancel'),
      });

      if (result) {
        Tasks.messaging.pinUnpinMessage(presenter.currentPinnedMessage);
      }
    },

    goToAllPins(): void {
      const pageLink =
        source.match.path === RouterLink.channel
          ? RouterLink.allChannelPins
          : RouterLink.allChatPins;

      navigateTo({
        to: pageLink,
        params: { id: source.groupId },
      });
    },

    onClick(): void {
      presenter.group?.pinnedMessages.setPrevPinnedMessageId();
    },
  }));

  return presenter;
};
