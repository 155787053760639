import Tasks from 'APP/Tasks';
import { RouterLink } from 'APP/router/constants';
import { navigateTo } from 'APP/router/refs/navigateTo';
import Entities from 'APP/store';

export async function goToNewsfeed() {
  if (
    !Entities.newsfeed.isLoading &&
    (Entities.newsfeed.isNeedReload || Entities.newsfeed.isError)
  ) {
    Entities.newsfeed.reset();
    Tasks.newsfeed.preloadNewsfeed({ cursor: null });
  }

  Entities.newsfeed.setNeedReload(true);
  navigateTo({ to: RouterLink.newsfeed });
}
