import { analyticsClient } from '../client/AnalyticsClient';
import { AnalyticsCallEvent } from './callAnalytics.types';

export function trackJoinedGuest(callType: 'p2p' | 'group'): void {
  analyticsClient.track({
    eventType: AnalyticsCallEvent.CallJoinedGuest,
    eventProperties: {
      call_type: callType,
    },
  });
}
