import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import Tasks from 'APP/Tasks';
import { ShareScreenButton } from 'APP/components/ShareScreenButton/ShareScreenButton';
import { IconButton, IconViewSizes } from 'APP/packages/UiKit/IconButton';
import CopyLink from 'ICONS/ic-copy.svg';
import MicOff from 'ICONS/ic-mic-off.svg';
import Mic from 'ICONS/ic-mic-on.svg';
import RaiseHandIcon from 'ICONS/ic-raise_hand.svg';
import VideoOff from 'ICONS/ic-video-off.svg';
import VideoOn from 'ICONS/ic-video-on.svg';

import { useCallActionsPanelPresenter } from './CallActionsPanel.presenter';
import styles from './CallActionsPanel.styles.m.css';
import { FinishCallButton } from './FinishCallButton/FinishCallButton';

export const CallActionsPanel = observer(({ className }) => {
  const presenter = useCallActionsPanelPresenter();

  return (
    <div className={classNames(styles.container, className)}>
      {
        // ToDO: UNION_CALL - test functional for unauthorized calls
        presenter.isUnauthorisedCall && (
          <IconButton
            Icon={CopyLink}
            onClick={Tasks.calls.copyUnionCallLink}
            className={styles.buttonContainer}
            size={IconViewSizes.Large}
            iconSize={IconViewSizes.Medium}
            theme={'12'}
          />
        )
      }
      {presenter.isShowRiseHand && (
        <IconButton
          Icon={RaiseHandIcon}
          onClick={presenter.toggleHand}
          className={classNames(styles.buttonContainer, styles.hand)}
          size={IconViewSizes.Large}
          iconSize={IconViewSizes.Medium}
          theme={presenter.isRaiseHand ? '13' : '12'}
        />
      )}
      <IconButton
        Icon={presenter.isAudioMuted ? MicOff : Mic}
        onClick={presenter.isAudioMuted ? presenter.unmuteAudio : presenter.muteAudio}
        className={styles.buttonContainer}
        size={IconViewSizes.Large}
        iconSize={IconViewSizes.Medium}
        isDisabled={!presenter.isCallStarted}
        theme={presenter.isAudioMuted ? '12' : '13'}
        isLoading={!presenter.isCallStarted}
      />
      <IconButton
        Icon={presenter.isVideoMuted ? VideoOff : VideoOn}
        onClick={presenter.isVideoMuted ? presenter.unmuteVideo : presenter.muteVideo}
        className={styles.buttonContainer}
        size={IconViewSizes.Large}
        iconSize={IconViewSizes.Medium}
        isDisabled={!presenter.isCallStarted}
        theme={presenter.isVideoMuted ? '12' : '13'}
      />

      {!presenter.isGuestCall && (
        <ShareScreenButton
          className={styles.buttonContainer}
          isDisabled={presenter.me?.isDisabledShareScreen || !presenter.isCallStarted}
          isActive={presenter.isSharedScreen}
          shareScreen={presenter.shareScreen}
          stopSharingScreen={presenter.stopSharingScreen}
        />
      )}

      <FinishCallButton />
    </div>
  );
});
