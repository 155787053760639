import { localStorageInstance } from '../instances/localStorageInstance';

const KEY_NAME = 'reconnect-key';

export const set = (connectionToken: string): void => {
  localStorageInstance.setItem(KEY_NAME, connectionToken);
};

export function get(): string | null {
  return localStorageInstance.getItem(KEY_NAME);
}

export function remove(): void {
  localStorageInstance.removeItem(KEY_NAME);
}
