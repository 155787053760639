import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import SadImage from 'APP/images/gem-mascot-mistake.svg';
import { AddKeyPressHandler } from 'APP/packages/keyBoardHandler';
import { useTranslation } from 'APP/packages/translations';
import { ChatBackground } from 'MAIN/components/ChatBackground/ChatBackground';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';
import { InfoBlock, InfoBlockTypes } from 'UIKIT/InfoBlock/InfoBlock';

import { NewsfeedContent } from '../components/NewsfeedContent/NewsfeedContent';
import { usePostMenuItems } from '../components/NewsfeedContent/NewsfeedPostItem/PostContextMenu/usePostMenuItems';
import { useNewsfeedPresenter } from './Newsfeed.presenter';
import styles from './Newsfeed.styles.m.css';

export const Newsfeed: FC = observer(() => {
  const presenter = useNewsfeedPresenter();
  const menuItems = usePostMenuItems({ isShowHide: true, isShowAddCollectionItem: true });
  const { t } = useTranslation();

  let infoBlock = null;
  if (presenter.isShowError) {
    infoBlock = (
      <InfoBlock
        className={classNames(styles.infoBlock, customScrollStyles.container)}
        type={presenter.hasBackgroundImage ? InfoBlockTypes.ChatWithPattern : InfoBlockTypes.Chat}
        title={t('common_internal_server_error_title')}
        descriptions={[t('common_internal_server_error_message')]}
        image={SadImage}
        buttonTitle={t('chat_gallery_reload_button')}
        onClick={presenter.reload}
      />
    );
  } else if (presenter.isShowEmpty) {
    infoBlock = (
      <InfoBlock
        className={classNames(styles.infoBlock, customScrollStyles.container)}
        type={presenter.hasBackgroundImage ? InfoBlockTypes.ChatWithPattern : InfoBlockTypes.Chat}
        title={t('news_feed_empty_title')}
        descriptions={[t('news_feed_empty_subtitle')]}
        image={SadImage}
      />
    );
  }

  return (
    <>
      <AddKeyPressHandler onEsc={presenter.onClose} />
      <div className={styles.container}>
        <div className={styles.headerWrapper}>
          <div className={styles.header}>{t('channels_news_feed')}</div>
        </div>

        <div className={styles.contentWrapper}>
          <ChatBackground />
          {infoBlock ? (
            infoBlock
          ) : (
            <NewsfeedContent
              news={presenter.news}
              scrollPosition={presenter.scrollPosition}
              isShowPlaceholder={presenter.isShowPlaceholder}
              isShowLoader={presenter.isPreloading}
              emptyInfoText={t('newsfeed_no_more_news')}
              hasBackgroundImage={presenter.hasBackgroundImage}
              hasMoreNews={presenter.hasMoreNews}
              getMenuItems={menuItems.getMenuItems}
              onReloadNewsfeed={presenter.reload}
              onSetNewsVisibility={presenter.setNewsVisibility}
              onScroll={presenter.onScroll}
            />
          )}
        </div>
      </div>
    </>
  );
});
