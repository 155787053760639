import patterns from 'APP/packages/patterns';

const protocolRegex = new RegExp(`^${patterns.PROTOCOL}`, 'iu');

export default (url1, url2) => {
  if (!url1 || !url2) {
    return false;
  }

  const path1 = String(url1).replace(protocolRegex, '');
  const path2 = String(url2).replace(protocolRegex, '');

  return path1 === path2;
};
