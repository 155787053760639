import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { useTranslation } from 'APP/packages/translations';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { AppearanceExample } from './AppearanceExample/AppearanceExample';
import { useAppearanceSettingsPopupPresenter } from './AppearanceSettingsPopup.presenter';
import styles from './AppearanceSettingsPopup.styles.m.css';
import { ChatBackgroundSelection } from './ChatBackgroundSelection/ChatBackgroundSelection';
import { ThemeSelection } from './ThemeSelection/ThemeSelection';

export const AppearanceSettingsPopup: FC<ICommonPopupsProps> = observer((props) => {
  const { t } = useTranslation();
  const presenter = useAppearanceSettingsPopupPresenter(props);

  return (
    <Popup
      title={t('chat_appearance')}
      isOpened
      handlePrevStep={presenter.hasBackButton ? presenter.onBack : null}
      onClose={presenter.onClose}
    >
      <div className={classNames(styles.container, customScrollStyles.container)}>
        <AppearanceExample />

        <ChatBackgroundSelection
          activeChatBackground={presenter.activeChatBackground}
          onChatBackgroundChange={presenter.onChatBackgroundChange}
        />

        <ThemeSelection
          activeTheme={presenter.activeTheme}
          onChange={presenter.onThemeChange}
        />
      </div>
    </Popup>
  );
});
