import Tasks from 'APP/Tasks';
import { ALERT_TYPES } from 'APP/constants/app';
import { mapCounterResponse } from 'APP/model/counter/counterModel.mapper';
import api from 'APP/packages/api';
import logger from 'APP/packages/logger';
import Entities from 'APP/store';

export default async (id) => {
  try {
    let group = Entities.GroupsStore.getGroupById(id);
    const requests = [api.group.getThreadInfoById({ threadId: id })];
    if (!group) {
      requests.push(Tasks.group.loadGroupsByIds([id]));
    }
    const [{ groupCounter, messageId, groupId }] = await Promise.all(requests);
    group = Entities.GroupsStore.getGroupById(id);
    if (group) {
      Tasks.group.addCounters([mapCounterResponse(groupCounter)]);
      group.setThreadInfo({ messageId, groupId });

      // loading the parent group for the future
      if (!Entities.GroupsStore.getGroupById(groupId)) {
        //if open thread by url for private group or private channel without Me
        // do not block the loading of the thread by loading the parent chat
        Tasks.group.loadGroupsByIds([groupId]).then(() => {
          if (
            !Entities.GroupsStore.getGroupById(groupId) &&
            id === Entities.GroupsStore.activeGroupId
          ) {
            const alert = { type: ALERT_TYPES.CHAT_IS_NOT_ACCESSIBLE };
            Tasks.group.removeGroupLocally({ groupId: id, alert });
          }
        });
      }
    }
  } catch (error) {
    logger.get('API').error('group.getThreadInfoById', error);
  }
  return null;
};
