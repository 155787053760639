import classNames from 'classnames';
import React from 'react';
import { NavLink } from 'react-router-dom';

import { IconView } from '../IconView/IconView';
import styles from './styles.m.scss';

const IconLink = ({
  to,
  isDisabled = false,
  Icon,
  hasBadge = false,
  className,
  activeClassName = '',
  iconClassName = '',
  isActive = () => ({}),
  size = 'medium',
  dataTest = undefined,
  onClick = undefined,
}) => {
  const containerClasses = classNames(
    styles.container,
    styles[size],
    {
      [styles.disabled]: isDisabled,
    },
    className
  );

  const iconClasses = classNames(styles.icon, iconClassName);

  return (
    <NavLink
      onClick={onClick}
      className={containerClasses}
      activeClassName={activeClassName}
      to={to}
      isActive={isActive}
      data-test={dataTest}
    >
      {hasBadge && <div className={styles.dotBadge} />}
      <IconView
        Icon={Icon}
        size={size}
        className={iconClasses}
      />
    </NavLink>
  );
};

export default IconLink;
