import React, { CSSProperties, FC, memo, ReactElement, useEffect, useRef } from 'react';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';
import { ListOnScrollProps, VariableSizeList } from 'react-window';

import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';

const LOAD_MORE_SCROLL_DEFAULT_OFFSET = 1;

interface IVirtualizedListProps {
  itemCount: number;
  itemSize: number | ((index: number) => number);
  getListItem(index: number): ReactElement;
  isShowLoader?: boolean;
  Loader?: ReactElement;
  loaderSize?: number;
  hasMoreItems?: boolean;
  loadMoreScrollOffset?: number;
  onLoadMore?(): void;
}

export const VirtualizedList: FC<IVirtualizedListProps> = memo(
  ({
    itemCount,
    itemSize,
    isShowLoader = false,
    loaderSize,
    Loader,
    getListItem,
    hasMoreItems,
    loadMoreScrollOffset = LOAD_MORE_SCROLL_DEFAULT_OFFSET,
    onLoadMore,
  }) => {
    const listRef = useRef<VariableSizeList>(null);
    const listOuterRef = useRef<HTMLDivElement>(null);
    const totalItemsCount = itemCount + (isShowLoader ? 1 : 0);

    const getItemSize = (index: number): number => {
      if (index < itemCount) {
        return typeof itemSize === 'number' ? itemSize : itemSize(index);
      } else {
        return loaderSize || 0;
      }
    };

    const Row = ({ index, style }: { index: number; style: CSSProperties }): ReactElement => {
      if (index < itemCount) {
        return <div style={style}>{getListItem(index)}</div>;
      } else {
        return <div style={style}>{Loader}</div>;
      }
    };

    const handleScroll = ({
      scrollDirection,
      scrollUpdateWasRequested,
    }: ListOnScrollProps): void => {
      if (listOuterRef.current && !scrollUpdateWasRequested && scrollDirection === 'forward') {
        const { scrollHeight, clientHeight, scrollTop } = listOuterRef.current;
        if (scrollHeight - scrollTop - clientHeight <= loadMoreScrollOffset) {
          onLoadMore?.();
        }
      }
    };

    useEffect(() => {
      listRef.current?.resetAfterIndex(0);
    }, [totalItemsCount, itemSize]);

    return (
      <AutoSizer>
        {({ height, width }: Size): ReactElement => (
          <VariableSizeList
            className={customScrollStyles.container}
            ref={listRef}
            outerRef={listOuterRef}
            height={height}
            itemCount={totalItemsCount}
            itemSize={getItemSize}
            width={width}
            onScroll={hasMoreItems && onLoadMore ? handleScroll : undefined}
          >
            {Row}
          </VariableSizeList>
        )}
      </AutoSizer>
    );
  }
);
