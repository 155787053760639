import { observer } from 'mobx-react';
import React, { FC, useCallback, useRef } from 'react';

import { List } from 'APP/components/List/List';
import { Popup } from 'APP/components/Popup/Popup';
import { useTranslation } from 'APP/packages/translations';

import { ICommonPopupsProps } from '../../../PopupManager.types';
import { useClosePopupOnNavigation } from '../../../hooks/useClosePopupOnNavigation';
import { ScheduleCallsEmpty } from './ScheduleCallsEmpty/ScheduleCallsEmpty';
import styles from './ScheduledCallsList.styles.m.css';
import { IScheduledEventInfoWithDate } from './ScheduledCallsList.types';
import { useScheduledCallsListPopupPresenter } from './ScheduledCallsListPopup.presenter';
import { ScheduledCallsListPopupItem } from './ScheduledCallsListPopupItem/ScheduledCallsListPopupItem';
import { SchedulesCallListPlaceholder } from './SchedulesCallListPlaceholder/SchedulesCallListPlaceholder';

export const ScheduledCallsListPopup: FC<ICommonPopupsProps> = observer((props) => {
  const listRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  const presenter = useScheduledCallsListPopupPresenter({ ...props, listRef });
  useClosePopupOnNavigation(props.popupInstance);

  const renderItem = useCallback(
    (data: IScheduledEventInfoWithDate) => (
      <ScheduledCallsListPopupItem
        onStartClick={presenter.onClose}
        onClick={(): void => presenter.onItemClick(data.id)}
        scheduledEvent={data}
      />
    ),
    [props.popupInstance.close]
  );

  return (
    <Popup
      title={t('call_events')}
      isOpened
      isHidden={props.popupInstance.isHidden}
      handlePrevStep={presenter.hasBackButton ? presenter.onBack : null}
      onClose={presenter.onClose}
    >
      <div
        ref={listRef}
        className={styles.list}
      >
        <List
          isScrollable
          isLoading={presenter.isLoading}
          className={styles.results}
          hasMore={presenter.hasMore}
          data={presenter.scheduledEventsList}
          keyExtractor={presenter.keyExtractor}
          onEndReached={presenter.getNextCursor}
          renderItem={renderItem}
          ListEmptyComponent={<ScheduleCallsEmpty />}
          SpinnerComponent={
            <SchedulesCallListPlaceholder count={presenter.getCountPlaceholders()} />
          }
        />
      </div>
    </Popup>
  );
});
