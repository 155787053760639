import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { useTranslation } from 'APP/packages/translations';
import { Button } from 'UIKIT/Button/Button';

import Channel from './Channel';
import usePresenter from './presenter';
import styles from './styles.m.css';

const SuggestedChannels = ({ newsItem, className }) => {
  const presenter = usePresenter({ newsItem });
  const { t } = useTranslation();

  if (!presenter.isView) {
    return null;
  }

  return (
    <div className={classNames(className, styles.container)}>
      <h3 className={styles.title}>{t('channel_catalog_suggested')}</h3>

      {presenter.channels.map((channel) => (
        <Channel
          key={channel.id}
          channel={channel}
        />
      ))}

      <Button
        className={styles.button}
        theme="2"
        rounding="1"
        size="20"
        onClick={presenter.onSeeAll}
      >
        {t('channels:channel_catalog_see_all')}
      </Button>
    </div>
  );
};

export default observer(SuggestedChannels);
