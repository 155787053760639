import { TFunction } from 'react-i18next';

import {
  TSystemMessageTextHandler,
  ISystemMessageText,
  ISystemMessageTextPresenter,
} from '../useSystemMessageText.types';

export const chatNewlyCreatedWelcomeHandler: TSystemMessageTextHandler = (
  presenter: ISystemMessageTextPresenter,
  t: TFunction
): ISystemMessageText => {
  const result: ISystemMessageText = { html: '' };

  if (presenter.isChannel) {
    result.html = presenter.isInitiatorMe
      ? t('create_channel_system_message', {
          0: `<span class="system-message-title">${presenter.groupName}</span>`,
          interpolation: { escapeValue: false },
        })
      : t('channel_welcome_message', {
          0: `<span class="system-message-title">${presenter.groupName}</span>`,
          interpolation: { escapeValue: false },
        });
  } else {
    result.html = presenter.isInitiatorMe
      ? t('group_chat_creation_system_message', {
          0: `<span class="system-message-title">${presenter.groupName}</span>`,
          interpolation: { escapeValue: false },
        })
      : t('group_chat_welcome_message', {
          0: `<span class="system-message-title">${presenter.groupName}</span>`,
          interpolation: { escapeValue: false },
        });
  }

  return result;
};
