import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Avatar, TAvatarBackgroundColorIndex } from 'UIKIT/Avatar/Avatar';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';

interface ILargeCallAvatarProps {
  avatar: {
    title: string | null;
    url: string | null;
    colorIndex: TAvatarBackgroundColorIndex | null;
  };
}

export const LargeCallAvatar: FC<ILargeCallAvatarProps> = observer(({ avatar }) => {
  return (
    <Avatar
      type={AvatarTypes.Round_40}
      title={avatar.title || ''}
      url={avatar.url}
      backgroundColorIndex={avatar.colorIndex || '0'}
    />
  );
});
