import { MutableRefObject, useCallback, useEffect, useRef } from 'react';

import { useContextMenu } from 'APP/main/hooks/useContextMenu/useContextMenu';
import { IPopover, TPopoverModifiers } from 'APP/packages/UiKit/Popover/Popover.types';

interface IMessageActionsMenuPresenterProps {
  reference: MutableRefObject<HTMLElement | null>;
  onChange(popoverStatus: boolean): void;
}

interface IMessageActionsMenuPresenter {
  popover: IPopover<HTMLElement>;
  modifiers: TPopoverModifiers;
  targetClick: HTMLElement | null;
}

export const useMessageActionsMenuPresenter = ({
  reference,
  onChange,
}: IMessageActionsMenuPresenterProps): IMessageActionsMenuPresenter => {
  const { modifiers, popover } = useContextMenu();

  const targetClickRef = useRef<HTMLElement | null>(null);

  const clickHandler = useCallback(() => {
    popover.toggle();
  }, []);

  const contextMenuHandler = useCallback(() => {
    popover.close();
  }, []);

  useEffect(() => {
    onChange(popover.isOpened);
  }, [popover.isOpened]);

  useEffect(() => {
    if (!reference.current) {
      return;
    }

    popover.containerRef.current = reference.current;

    reference.current.addEventListener('contextmenu', contextMenuHandler);
    reference.current.addEventListener('click', clickHandler);

    return () => {
      if (!reference.current) {
        return;
      }
      reference.current.removeEventListener('contextmenu', contextMenuHandler);
      reference.current.removeEventListener('click', clickHandler);
    };
  }, [reference, clickHandler]);

  return {
    popover,
    modifiers,
    targetClick: targetClickRef.current,
  };
};
