import Entities from 'APP/store';

export default async ({ groupId, messageId }) => {
  const group = Entities.GroupsStore.getGroupById(groupId);

  if (!group || group.isFake) {
    return;
  }

  const message = group.messagesStore.getMessageById(messageId);

  if (!message) {
    return;
  }

  if (Entities.viewedMessages.viewedMessage(groupId, messageId)) {
    return;
  }

  if (message.forward && message.forward.groupId) {
    if (
      !Entities.viewedMessages.viewedMessage(message.forward.groupId, message.forward.messageId)
    ) {
      Entities.viewedMessages.increaseViewMessages(message.forward.groupId, [
        message.forward.messageId,
      ]);
    }
  }

  if (group.isP2P) {
    return;
  }

  Entities.viewedMessages.increaseViewMessages(groupId, [messageId]);
};
