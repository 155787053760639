import Tasks from 'APP/Tasks';
import { IDeleteScheduledMessagesRequest } from 'APP/model/message/messageModel.types';
import { messageModel } from 'APP/model/model';
import Entities from 'STORE';
import { Schedule } from 'STORE/Groups/Schedule';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';
import { MessageError } from 'STORE/Messages/Message/ChatMessage/ChatMessages.types';

interface IDeleteScheduledMessagesParams {
  groupId: string;
  messages: ChatMessage[];
}

const request = async (data: IDeleteScheduledMessagesRequest): Promise<boolean> => {
  try {
    return await messageModel.deleteScheduledMessages(data);
  } catch {
    return false;
  }
};

export const deleteScheduledMessages = async (
  data: IDeleteScheduledMessagesParams
): Promise<boolean> => {
  const { groupId, messages } = data;

  const group = Entities.GroupsStore.getGroupById(groupId) as Schedule;
  if (!group?.groupId) {
    return false;
  }

  const fakeMessageIds = messages.filter((message) => message.isFake).map((message) => message.id);
  if (fakeMessageIds.length > 0) {
    Tasks.messaging.clearScheduledMessages({ groupId: group.groupId, messageIds: fakeMessageIds });
  }

  const realMessages = messages.filter((message) => !message.isFake);
  if (realMessages.length === 0) {
    return true;
  }

  const realMessageIds = realMessages.map((message) => message.id);
  Tasks.messaging.clearScheduledMessages({ groupId: group.groupId, messageIds: realMessageIds });
  const result = await request({ groupId: group.groupId, messageIds: realMessageIds });

  if (!result) {
    realMessages.forEach((message) => message.setError(MessageError.Delete));
    group.messagesStore.handleRestoreMessages({ messages: realMessages });
  }

  return result;
};
