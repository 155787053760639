import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { useFileDownloadStatePresenter } from 'APP/hooks/useFileDownloadState/useFileDownloadState';
import { FileName } from 'UIKIT/FileName/FileName';

import { useAllDownloadsItemInfoPresenter } from './AllDownloadsItemInfo.presenter';
import styles from './AllDownloadsItemInfo.styles.m.css';

interface IAllDownloadsItemInfoProps {
  payloadId: string;
  groupId: string;
  messageId: string;
}

export const AllDownloadsItemInfo: FC<IAllDownloadsItemInfoProps> = observer((props) => {
  const { payloadId, groupId, messageId } = props;
  const { downloadPercent, payload, progressEvent } = useFileDownloadStatePresenter({
    payloadId,
    groupId,
    messageId,
  });

  if (!payload) {
    return null;
  }

  const { fileName, fileSize } = payload;

  const presenter = useAllDownloadsItemInfoPresenter({ progressEvent, downloadPercent, fileSize });

  return (
    <div className={styles.container}>
      <FileName
        name={fileName}
        className={styles.fileName}
      />
      <div className={styles.bytes}>{presenter.formattedBytes}</div>
    </div>
  );
});
