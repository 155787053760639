import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { ReactElement } from 'react';
import { FC } from 'react';
import { ReactEventHandler } from 'react';

import styles from './CommandButton.styles.m.css';

interface ICommandButtonProps {
  className?: string;
  title: string;
  onClick: ReactEventHandler;
  icon?: ReactElement;
}

export const CommandButton: FC<ICommandButtonProps> = observer((props) => {
  const { title, onClick, className, icon } = props;

  return (
    <button
      className={classNames(styles.container, className)}
      onClick={onClick}
    >
      <span>{title}</span>
      {icon}
    </button>
  );
});
