import React, { memo, useEffect, useState, ReactElement, useRef } from 'react';

import Entities from 'APP/store';

import Tasks from '../../../Tasks';

interface IAsyncImageProps {
  className?: string;
  src: string;
  alt: string;
  children: ReactElement;
  dataTest?: string;
}

export const AsyncImage = memo<IAsyncImageProps>((props) => {
  const { className, src, alt, children, dataTest } = props;
  const prevImageSrcRef = useRef('');

  const [isLoaded, setLoaded] = useState<boolean | null>(() => {
    return Entities.CachedImages.isCachedImage(src);
  });

  useEffect(() => {
    let isMounted = true;
    const loadImage = async (): Promise<void> => {
      const isLoaded = Entities.CachedImages.isCachedImage(src);
      if (isLoaded === undefined) {
        const result = await Tasks.app.preloadImage(src);
        if (isMounted) {
          prevImageSrcRef.current = result ? src : '';
          setLoaded(result);
        }
        return;
      }
      setLoaded(isLoaded);
      prevImageSrcRef.current = src;
    };

    loadImage();

    return (): void => {
      isMounted = false;
      setLoaded(null);
    };
  }, [src]);

  if (isLoaded || prevImageSrcRef.current) {
    return (
      <img
        alt={alt}
        className={className}
        src={src || prevImageSrcRef.current}
        data-test={dataTest}
      />
    );
  }

  return children;
});
