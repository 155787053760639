import { PayloadType } from 'APP/model/message/messageModel.types';
import Entities from 'APP/store';
import type { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

const allowedTypes = [
  PayloadType.RichText,
  PayloadType.Video,
  PayloadType.CircleVideo,
  PayloadType.Image,
  PayloadType.Album,
  PayloadType.Sticker,
  PayloadType.Location,
  PayloadType.VoiceMessage,
  PayloadType.File,
  PayloadType.Contact,
  PayloadType.ButtonsSelected,
  PayloadType.AudioMessage,
  PayloadType.Article,
  PayloadType.SystemStream,
  PayloadType.Advertisement,
  PayloadType.Buttons,
];

const editableTypes = [
  PayloadType.RichText,
  PayloadType.Video,
  PayloadType.Image,
  PayloadType.Album,
  PayloadType.Article,
  PayloadType.File,
  PayloadType.AudioMessage,
  PayloadType.VoiceMessage,
];

export const isMessageCanBeEdit = (message: ChatMessage): boolean => {
  if (!editableTypes.includes(message.payload.payloadType) || message.forward) {
    return false;
  }

  if (message.group?.isChannel) {
    return message.group.withMeInAdmins;
  }

  if (!message.fromMe) {
    return false;
  }

  if (message.group?.isChatGroup) {
    return !message.group.withoutMe;
  }

  return true;
};

export const isMessageCanBeQuoted = (message: ChatMessage): boolean => {
  return (
    !Entities.ChatStore.isDisableToWrite &&
    allowedTypes.includes(message.payload.payloadType) &&
    !message.isFake
  );
};

export const isMessageCanBeForward = (message: ChatMessage): boolean => {
  return (
    !message.isFake &&
    message.senderUser &&
    message.payload.payloadType !== PayloadType.ButtonsSelected &&
    message.payload.payloadType !== PayloadType.Advertisement
  );
};

export const isMessageCanBeAddedToCollection = (message: ChatMessage): boolean => {
  const {
    group,
    payload: { payloadType },
  } = message;

  const invalidPayloadType = [
    PayloadType.AudioMessage,
    PayloadType.VoiceMessage,
    PayloadType.File,
    PayloadType.CircleVideo,
  ];

  return Boolean(
    group?.spaceId &&
      !message.isFake &&
      !message.payload.isSystem &&
      Entities.spacesStore.getById(group?.spaceId)?.isOwner &&
      (group.isChatGroup || group.isChannel) &&
      !invalidPayloadType.includes(payloadType)
  );
};
