import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { PageType } from 'APP/router/MessengerRoutes/constants';

import { AllDownloads } from './AllDownloads/AllDownloads';
import { GroupList } from './GroupList/GroupList';
import { MiniPlayer } from './MiniPlayer/MiniPlayer';
import { NewMessageButton } from './NewMessageButton/NewMessageButton';
import { SearchResult } from './SearchResult/SearchResult';
import { useSidebarPanelPresenter } from './SidebarPanel.presenter';
import styles from './SidebarPanel.styles.m.css';
import { SidebarPanelHeader } from './SidebarPanelHeader/SidebarPanelHeader';
import { SidebarPanelNavigation } from './SidebarPanelNavigation/SidebarPanelNavigation';
import { SoftUpdate } from './SoftUpdate/SoftUpdate';
import { SpacePanel } from './SpacePanel/SpacePanel';
import { SpacesList } from './SpacesList/SpacesList';

interface ISidebarPanelProps {
  pageType: PageType;
}

export const SidebarPanel: FC<ISidebarPanelProps> = observer(({ pageType }) => {
  const presenter = useSidebarPanelPresenter(pageType);

  return (
    <div className={styles.container}>
      <SidebarPanelNavigation
        panelMode={presenter.panelMode}
        onPanelModeChange={presenter.onPanelModeChange}
      />

      <div className={classNames(styles.content)}>
        {presenter.isShowHeader && <SidebarPanelHeader onSearchChange={presenter.onSearchChange} />}

        <div className={classNames(styles.list)}>
          {presenter.isShowSearchResults && <SearchResult searchText={presenter.searchText} />}

          {presenter.isShowSpace && <SpacePanel space={presenter.space} />}
          {presenter.isShowSpaces && <SpacesList onShowSpace={presenter.onShowSpace} />}

          {presenter.isShowAllGroups && (
            <GroupList
              groups={presenter.allGroups}
              panelMode={presenter.panelMode}
            />
          )}
          {presenter.isShowChats && (
            <GroupList
              groups={presenter.chats}
              panelMode={presenter.panelMode}
            />
          )}
          {presenter.isShowChannels && (
            <GroupList
              groups={presenter.channels}
              panelMode={presenter.panelMode}
            />
          )}

          {presenter.isShowNewMessageButton && <NewMessageButton />}
        </div>
        <MiniPlayer />
        <AllDownloads />
        <SoftUpdate />
      </div>
    </div>
  );
});
