import React, { ReactElement, createElement } from 'react';

import Tasks from 'APP/Tasks';
import {
  TTree,
  ITreeItem,
  TTreeItemProps,
} from 'APP/packages/markdown/parseToTree/parseToTree.types';

import { BoldAndItalicRenderer } from './BoldAndItalicRenderer/BoldAndItalicRenderer';
import { BoldRenderer } from './BoldRenderer/BoldRenderer';
import { BotCommandRenderer } from './BotCommandRenderer/BotCommandRenderer';
import { DeepLinkRenderer } from './DeepLinkRenderer/DeepLinkRenderer';
import { EmailRenderer } from './EmailRenderer/EmailRenderer';
import { EscapeRenderer } from './EscapeRenderer/EscapeRenderer';
import { HiddenLinkRenderer } from './HiddenLinkRenderer/HiddenLinkRenderer';
import { ItalicRenderer } from './ItalicRenderer/ItalicRenderer';
import { KeyWordRenderer } from './KeyWordRenderer/KeyWordRenderer';
import { LinkRenderer } from './LinkRenderer/LinkRenderer';
import { MarketplaceLinkRenderer } from './MarketplaceLinkRenderer/MarketplaceLinkRenderer';
import { MentionRenderer } from './MentionRenderer/MentionRenderer';
import { StrikethroughRenderer } from './StrikethroughRenderer/StrikethroughRenderer';
import { TextRenderer } from './TextRenderer/TextRenderer';
import { UnderlineRenderer } from './UnderlineRenderer/UnderlineRenderer';
import { IAnyRenderer, IRendererOptions } from './renderTree.types';

type TTagRenderer = (options?: TTreeItemProps) => IAnyRenderer;

const tagRenderer: Record<string, TTagRenderer> = {
  text: () => TextRenderer,
  '@': () => MentionRenderer,
  '/': () => BotCommandRenderer,
  keyWord: () => KeyWordRenderer,
  email: () => EmailRenderer,
  '***': () => BoldAndItalicRenderer,
  '**': () => BoldRenderer,
  '*': () => ItalicRenderer,
  '~~': () => StrikethroughRenderer,
  __: () => UnderlineRenderer,
  ']': (options) => getLinkRenderer(options, HiddenLinkRenderer),
  '[': (options) => getLinkRenderer(options, HiddenLinkRenderer),
  link: (options) => getLinkRenderer(options, LinkRenderer),
  '```': () => EscapeRenderer,
};

function getLinkRenderer(
  options: TTreeItemProps = {},
  defaultRenderer: IAnyRenderer
): IAnyRenderer {
  if (Tasks.deeplinks.isDeepLink(options.link)) {
    return DeepLinkRenderer;
  }

  if (Tasks.market.isMarketUrl(options.link)) {
    return MarketplaceLinkRenderer;
  }

  return defaultRenderer;
}

function renderItem(item: ITreeItem, index: number, moreOptions: IRendererOptions): ReactElement {
  const options = { ...moreOptions, ...(item.props || {}) };
  const renderer = tagRenderer[item.tag](options);

  if (typeof item.content === 'string') {
    return createElement(renderer, { options, key: index }, item.content);
  }

  const children: ReactElement[] = item.content.map((item: ITreeItem, index: number) => {
    return renderItem(item, index, moreOptions);
  });

  return createElement(renderer, { options, key: index }, children);
}

export function renderTree(tree: TTree, options: IRendererOptions): ReactElement {
  return <>{tree.map((item: ITreeItem, index: number) => renderItem(item, index, options))}</>;
}
