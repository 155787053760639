import classNames from 'classnames';
import React, { FC } from 'react';

import CallIcon from 'ICONS/ic-s-call.svg';
import { DotLoader } from 'UIKIT/DotLoader/DotLoader';
import { IconView, IconViewSizes } from 'UIKIT/IconView/IconView';

import { useJoinCallButtonPresenter } from './JoinCallButton.presenter';
import styles from './JoinCallButton.styles.m.css';

interface IJoinCallButtonProps {
  readonly className?: string;
  readonly hasActiveCall?: boolean;
  readonly startTs?: number;
  onClick?(): void;
}

export const JoinCallButton: FC<IJoinCallButtonProps> = ({
  className,
  hasActiveCall,
  startTs,
  onClick,
}) => {
  const { time } = useJoinCallButtonPresenter(startTs);

  return (
    <button
      className={classNames(styles.button, className, {
        [styles.canPress]: !hasActiveCall && !!onClick,
      })}
      type="button"
      disabled={hasActiveCall}
      onClick={onClick}
    >
      <IconView
        className={styles.icon}
        Icon={CallIcon}
        size={IconViewSizes.Small}
      />
      {!startTs ? (
        <DotLoader itemClassName={classNames({ [styles.loaderActive]: hasActiveCall })} />
      ) : (
        <span className={styles.time}>{time}</span>
      )}
    </button>
  );
};
