import { observer } from 'mobx-react';
import React, { FC, useRef } from 'react';

import { SpaceAvatar } from 'APP/components/Avatar/SpaceAvatar/SpaceAvatar';
import { VerifiedBadge } from 'APP/components/VerifiedBadge/VerifiedBadge';
import { useTranslation } from 'APP/packages/translations';
import { useContextMenu } from 'MAIN/hooks/useContextMenu/useContextMenu';
import { Space } from 'STORE/Spaces/Space';
import { PopoverMenu } from 'UIKIT/PopoverMenu/PopoverMenu';
import { getFormatNumberToString } from 'UTILS/getFormatNumberToString';

import { SpaceBadge } from '../SpaceBadge/SpaceBadge';
import { SpaceFriendsOnboarding } from '../SpaceFriendsOnboarding/SpaceFriendsOnboarding';
import { useSpaceListItemPresenter } from './SpaceListItem.presenter';
import styles from './SpaceListItem.styles.m.css';

interface ISpaceListItemProps {
  space: Space;
  onClick(): void;
}

export const SpaceListItem: FC<ISpaceListItemProps> = observer((props) => {
  const { space, onClick } = props;
  const presenter = useSpaceListItemPresenter(space);
  const { t } = useTranslation();
  const avatarRef = useRef<HTMLDivElement | null>(null);
  const { popover, onContextMenu, modifiers } = useContextMenu<HTMLDivElement>();

  return (
    <>
      <div
        className={styles.listItem}
        ref={popover.containerRef}
        role="button"
        tabIndex={0}
        onClick={onClick}
        onContextMenu={presenter.hasContextMenu ? onContextMenu : undefined}
      >
        <div ref={avatarRef}>
          <SpaceAvatar
            className={styles.avatar}
            space={space}
          />
        </div>

        <div className={styles.info}>
          <div className={styles.nameWrapper}>
            <h3 className={styles.name}>{space.name}</h3>
            {space.verified && <VerifiedBadge />}
          </div>

          <span className={styles.members}>
            {t('space_members_count').replace(
              '{0}',
              getFormatNumberToString(space.participantsCount)
            )}
          </span>
        </div>

        <SpaceBadge space={space} />
      </div>

      {presenter.isShowOnboarding && <SpaceFriendsOnboarding reference={avatarRef} />}

      {presenter.hasContextMenu && (
        <PopoverMenu
          reference={popover.containerRef.current}
          menuItems={presenter.menuItems}
          isOpened={popover.isOpened}
          placement="bottom-start"
          modifiers={modifiers}
          onClose={popover.close}
        />
      )}
    </>
  );
});
