import { RefObject, useCallback, useEffect, useMemo, useRef } from 'react';

import { usePreviousValue } from 'APP/packages/hooks/usePreviousValue';

import { IAudioPlayerTrack } from '../../AudioPlayer.types';

const ITEM_HEIGHT = 56;
const MAX_CONTAINER_HEIGHT = 216;

interface IAudioPlayerListPresenterOptions {
  activeTrack: IAudioPlayerTrack | null;
  items: IAudioPlayerTrack[];
  isOpened: boolean;
  onTogglePlay(): void;
  onChangeTrack(track: IAudioPlayerTrack): void;
}

interface IAudioPlayerListPresenter {
  listRef: RefObject<HTMLDivElement>;
  maxHeight: string | number;
  changeTrack(track: IAudioPlayerTrack): void;
}

export function useAudioPlayerListPresenter({
  activeTrack,
  items,
  isOpened,
  onTogglePlay,
  onChangeTrack,
}: IAudioPlayerListPresenterOptions): IAudioPlayerListPresenter {
  const listRef = useRef<HTMLDivElement>(null);
  const isOpenedPrevious = usePreviousValue(isOpened);

  const maxHeight = useMemo(() => {
    const itemsHeight = items.length * ITEM_HEIGHT;

    return isOpened
      ? `${itemsHeight < MAX_CONTAINER_HEIGHT ? itemsHeight : MAX_CONTAINER_HEIGHT}px`
      : 0;
  }, [items, isOpened]);

  const changeTrack = useCallback(
    (track) => {
      if (track.id !== activeTrack?.id) {
        onChangeTrack(track);
      } else {
        onTogglePlay();
      }
    },
    [activeTrack, onTogglePlay, onChangeTrack]
  );

  useEffect(() => {
    if (!isOpened || isOpened === isOpenedPrevious) {
      return;
    }

    const itemIndex = activeTrack ? items.findIndex((track) => track.id === activeTrack.id) : -1;

    if (itemIndex < 1) {
      return;
    }

    listRef.current?.querySelector('[data-is-active=true]')?.scrollIntoView({
      behavior: 'auto',
      block: 'center',
      inline: 'center',
    });
  }, [isOpened, isOpenedPrevious, activeTrack, items]);

  return {
    listRef,
    maxHeight,
    changeTrack,
  };
}
