import PromiseQueue from 'p-queue/dist';

import { loadLottieFile } from 'APP/Tasks/cache/loadLottieFile/loadLottieFile';
import { animationsCache } from 'APP/cache/animations';
import { EventWorker } from 'SERVICES/EventWorker';

import { rLottieComposer } from './RLottieComposer';
import { rLottieRunner } from './RLottieRunner';
import {
  ICreateRLottieInstanceResponse,
  IRenderFrameResponse,
  WorkerMessageType,
} from './rLottie.types';
import { launchMediaWorkers, MAX_WORKERS } from './utils/launchMediaWorkers';

const DEFAULT_FPS = 60;

const workers = launchMediaWorkers();

enum DbQueueName {
  LottieObject = 'lottieObject',
  FirstFrameImageData = 'firstFrame',
}

const caches: Record<string, Record<DbQueueName, { cache: any; queue: PromiseQueue }>> = {};
const commonQueue = new PromiseQueue({ concurrency: 100 });

export interface IRLottieHandlerOptions {
  url?: string;
  container: HTMLElement;
  animationData?: {
    id?: string;
    data: any;
  };
  autoplay?: boolean;
  loop?: boolean;

  onLoopComplete?(handler: RLottieHandler): void;
  onEnterFrame?(handler: RLottieHandler): void;
}

export class RLottieHandler {
  static lastWorkerIndex = 0;
  static localUniqueId = 0;

  public isLoaded: boolean;
  public playing = false;

  private readonly id: string;
  private readonly url?: string;
  private readonly autoplay?: boolean;
  private loop?: boolean;
  private container: HTMLElement;
  private curFrame: number;
  private renderFrame: number;
  private curFrameData: ImageData | null;
  private totalFrame: number;
  private lastRenderAt: number;
  private msPerFrame: number;
  private worker?: EventWorker;
  private canvas: HTMLCanvasElement;
  private context: CanvasRenderingContext2D | null;
  private animationData?: {
    id?: string;
    data: any;
  };
  private isInitialized: boolean;
  private readonly localUniqueId: number;
  private readonly localId: string;
  private firstFrameQueue: PromiseQueue;
  private lottieObjectQueue: PromiseQueue;
  private lastIframeCache: ImageData | null;

  private onLoopComplete?(handler: RLottieHandler): void;

  private onEnterFrame?(handler: RLottieHandler): void;

  constructor(options: IRLottieHandlerOptions) {
    const {
      url,
      container,
      animationData,
      autoplay = false,
      loop = true,
      onLoopComplete,
      onEnterFrame,
    } = options;

    this.localUniqueId = RLottieHandler.localUniqueId++;
    this.container = container;
    this.url = url;
    this.animationData = animationData;
    this.autoplay = autoplay;
    this.loop = loop;
    this.curFrame = 0;
    this.renderFrame = -1;
    this.playing = false;
    this.totalFrame = 0;
    this.lastRenderAt = Date.now();
    this.msPerFrame = 1000 / DEFAULT_FPS;
    this.isLoaded = false;
    this.isInitialized = false;
    this.onLoopComplete = onLoopComplete;
    this.onEnterFrame = onEnterFrame;

    this.createCanvas();

    this.context = this.canvas.getContext('2d');
    this.id = `${this.url || this.animationData?.id}-${this.canvas.width}-${this.canvas.height}`;
    this.localId = `${this.id}-${this.localUniqueId}`;

    this.createCache();
    this.renderFirstCachedFrame();

    this.defineWorker();
    this.loadData();

    this.play = this.play.bind(this);
    this.pause = this.pause.bind(this);
    this.setLoop = this.setLoop.bind(this);
    this.reset = this.reset.bind(this);
    this.destroy = this.destroy.bind(this);
    this.onLoop = this.onLoop.bind(this);
    this.updateIsVisible = this.updateIsVisible.bind(this);
    this.lastIframeCache = null;
    rLottieRunner.addRerenderHandler(this.localId, this.rerender.bind(this));
  }

  public play(): void {
    rLottieRunner.addHandler(this);
    this.playing = true;
    this.lastRenderAt = Date.now();
  }

  public pause(): void {
    this.playing = false;
  }

  public setLoop(loop: boolean): void {
    this.loop = loop;
  }

  public reset(): void {
    this.playing = false;
    rLottieRunner.removeHandler(this);
    this.renderFrame = -1;
    this.requestFrame(0, true);
  }

  public destroy(): void {
    rLottieRunner.removeHandler(this);
    rLottieRunner.removeRerenderHandler(this.localId);
    this.curFrame = 0;
    this.isInitialized = false;
    this.removeCanvas();
    this.worker?.postMessage({
      type: WorkerMessageType.Destroy,
      data: { id: this.id, localId: this.localId },
    });
    this.worker?.removeEventListener(this.rLottieInstanceCreatedEventName, this.onInstanceCreated);
    this.worker?.removeEventListener(this.renderFrameEventName, this.onRenderFrame);
  }

  public onLoop(): void {
    if (!this.canvas || !this.totalFrame || !this.playing) return;

    const curFrame = this.renderFrame < 0 ? 0 : this.getCurFrameIndex();
    const deltaFrame = (this.totalFrame - this.renderFrame + curFrame) % this.totalFrame;

    if (this.curFrameData && deltaFrame > 0) {
      this.context?.putImageData(this.curFrameData, 0, 0);
      this.lastIframeCache = this.curFrameData;

      const now = Date.now();
      const curFrameRenderAt = this.lastRenderAt + deltaFrame * this.msPerFrame;
      this.lastRenderAt = curFrameRenderAt <= now ? curFrameRenderAt : now;
      this.renderFrame = curFrame;

      if (this.curFrame === 0 && !this.isInitialized) {
        animationsCache.putFrame(this.id, this.curFrameData);
        this.isInitialized = true;
        this.firstFrameQueue.clear();
      }

      this.onEnterFrame?.(this);

      this.curFrameData = null;
      this.curFrame = this.getNextFrameIndex(curFrame);
      this.requestFrame(this.curFrame);
    }

    if (!this.loop && this.curFrame >= this.totalFrame - 1) {
      this.pause();
      this.onLoopComplete?.(this);
      rLottieRunner.removeHandler(this);
    }
  }

  public updateIsVisible(isVisible: boolean): void {
    this.worker?.postMessage({
      type: WorkerMessageType.UpdateIsVisible,
      data: {
        id: this.id,
        localId: this.localId,
        isVisible,
      },
    });
  }

  public rerender(): void {
    const cachedImage = this.lastIframeCache || caches[this.id].firstFrame.cache;

    this.context?.putImageData(cachedImage, 0, 0);
  }

  private createCanvas(): void {
    this.canvas = document.createElement('canvas');

    const devicePixelRatio = window.devicePixelRatio || 1;
    this.canvas.width = this.container?.offsetWidth * devicePixelRatio;
    this.canvas.height = this.container?.offsetHeight * devicePixelRatio;
    this.canvas.style.width = '100%';
    this.canvas.style.height = '100%';
    this.canvas.style.transformOrigin = '0 0 0';
    this.canvas.classList.add('rlottie');

    this.container?.appendChild(this.canvas);
  }

  private removeCanvas(): void {
    this.container.querySelectorAll('.rlottie').forEach((el) => el.remove());
  }

  private get rLottieInstanceCreatedEventName(): string {
    return `${WorkerMessageType.RLottieInstanceCreated}-${this.localId}`;
  }

  private get renderFrameEventName(): string {
    return `${WorkerMessageType.RenderFrame}-${this.localId}`;
  }

  private defineWorker(): void {
    const definedWorker = rLottieComposer.getWorker(this.id);

    if (!definedWorker) {
      const workerIndex = this.getWorkerIndex();
      this.worker = workers[workerIndex];
      rLottieComposer.addWorker(this.id, this.worker);
    } else {
      this.worker = definedWorker;
    }

    this.onInstanceCreated = this.onInstanceCreated.bind(this);
    this.onRenderFrame = this.onRenderFrame.bind(this);

    this.worker?.addEventListener(this.rLottieInstanceCreatedEventName, this.onInstanceCreated);
    this.worker?.addEventListener(this.renderFrameEventName, this.onRenderFrame);
  }

  private createCache(): void {
    if (!caches[this.id]) {
      caches[this.id] = {
        [DbQueueName.LottieObject]: {
          cache: null,
          queue: new PromiseQueue({ concurrency: 1 }),
        },
        [DbQueueName.FirstFrameImageData]: {
          cache: null,
          queue: new PromiseQueue({ concurrency: 1 }),
        },
      };
    }

    this.lottieObjectQueue = caches[this.id][DbQueueName.LottieObject].queue;
    this.firstFrameQueue = caches[this.id][DbQueueName.FirstFrameImageData].queue;
  }

  private get lottieObjectCache(): { get(): Record<string, any>; set(cache: any): void } {
    return {
      get: () => caches[this.id][DbQueueName.LottieObject].cache,
      set: (cache: any) => (caches[this.id][DbQueueName.LottieObject].cache = cache),
    };
  }

  private async getLottieObject(url: string): Promise<void> {
    if (!this.lottieObjectCache.get()) {
      this.lottieObjectCache.set(await animationsCache.getLottieObject(url));
    }
  }

  private async loadData(): Promise<void> {
    if (this.url) {
      await commonQueue.add(() =>
        this.lottieObjectQueue.add(() => this.getLottieObject(this.url!))
      );

      if (!this.lottieObjectCache.get()) {
        this.lottieObjectCache.set(await loadLottieFile(this.url));

        if (!this.lottieObjectCache.get()) {
          return;
        }
      }

      const lottieObject = this.lottieObjectCache.get();
      this.msPerFrame = 1000 / (lottieObject.fr || DEFAULT_FPS);
      this.createRLottieInstance(lottieObject);
    }

    if (this.animationData?.data) {
      this.msPerFrame = 1000 / (this.animationData.data.fr || DEFAULT_FPS);
      this.createRLottieInstance(this.animationData.data);
    }
  }

  private get firstFrameCache(): { get(): ImageData; set(cache: any): void } {
    return {
      get: () => caches[this.id][DbQueueName.FirstFrameImageData].cache,
      set: (imageData: any) => (caches[this.id][DbQueueName.FirstFrameImageData].cache = imageData),
    };
  }

  private async getFirstCachedFrame(): Promise<void> {
    if (!this.firstFrameCache.get()) {
      this.firstFrameCache.set(await animationsCache.getFrame(this.id));
    }
  }

  private async renderFirstCachedFrame(): Promise<void> {
    await commonQueue.add(() => this.firstFrameQueue.add(() => this.getFirstCachedFrame()));

    if (!this.isInitialized && this.firstFrameCache.get()) {
      this.context?.putImageData(this.firstFrameCache.get(), 0, 0);
      this.isInitialized = true;
      this.lastRenderAt = Date.now();
    }
  }

  private getWorkerIndex(): number {
    const workerIndex = RLottieHandler.lastWorkerIndex++;

    if (workerIndex >= MAX_WORKERS - 1) {
      RLottieHandler.lastWorkerIndex = 0;
    }

    return workerIndex;
  }

  private getNextFrameIndex(curFrame: number): number {
    return (curFrame + 1) % this.totalFrame;
  }

  private getCurFrameIndex(): number {
    return Math.floor(
      (this.renderFrame + (Date.now() - this.lastRenderAt) / this.msPerFrame) % this.totalFrame
    );
  }

  private requestFrame(frameNumber: number, immediatelyRender = false): void {
    this.worker?.postMessage({
      type: WorkerMessageType.RequestFrame,
      data: {
        id: this.id,
        localId: this.localId,
        curFrame: frameNumber,
        width: this.canvas.width,
        height: this.canvas.height,
        immediatelyRender,
      },
    });
  }

  private onInstanceCreated(event: CustomEvent<ICreateRLottieInstanceResponse>): void {
    this.totalFrame = event.detail.totalFrame;
    this.requestFrame(0, !this.isInitialized);

    if (this.autoplay) {
      this.play();
    }

    if (!this.isLoaded) {
      this.isLoaded = true;
    }
  }

  private onRenderFrame(event: CustomEvent<IRenderFrameResponse>): void {
    const { curFrame, clampedArray, immediatelyRender } = event.detail;
    this.curFrameData = new ImageData(clampedArray, this.canvas.width, this.canvas.height);
    this.curFrame = curFrame;

    if (immediatelyRender) {
      this.context?.putImageData(this.curFrameData, 0, 0);
      if (this.curFrame === 0 && !this.isInitialized) {
        animationsCache.putFrame(this.id, this.curFrameData);
        this.isInitialized = true;
        this.firstFrameCache.set(this.curFrameData);
        this.firstFrameQueue.clear();
      }
    }
  }

  private createRLottieInstance(lottieObject: Record<string, any>): void {
    this.worker?.postMessage({
      type: WorkerMessageType.CreateRLottieInstance,
      data: {
        id: this.id,
        localId: this.localId,
        lottieObject,
        width: this.canvas.width,
        height: this.canvas.height,
      },
    });
  }
}
