import { runInAction } from 'mobx';

import { getUsersDisplayData } from 'APP/model/user/userModel';
import Entities from 'STORE';

export const updateUsersDisplayData = async (userIds: string[]): Promise<void> => {
  try {
    const usersDisplayData = await getUsersDisplayData({ userIds });

    const removedUsers = userIds.filter((id) => {
      return !usersDisplayData.find((user) => user.id === id);
    });
    runInAction(() => {
      usersDisplayData.forEach((userDisplayData) => {
        const user = Entities.UsersStore.getUserById(userDisplayData.id);

        if (user) {
          user.update(userDisplayData);
        } else {
          Entities.UsersStore.add([userDisplayData]);
        }
      });
      removedUsers.forEach((id) => {
        Entities.UsersStore.delete(id);
      });
    });
  } catch {
    //
  }
};
