const MIN_FIELD_USERNAME_LENGTH = 2;

const ERRORS = {
  IS_EMPTY: 'IS_EMPTY',
  TOO_SHORT: 'TOO_SHORT',
};

const validate = ({ value, errorToText }) => {
  if (!value) {
    return errorToText[ERRORS.IS_EMPTY];
  }

  const trimmedUsername = value.trim();

  if (!trimmedUsername || trimmedUsername.length < MIN_FIELD_USERNAME_LENGTH) {
    return errorToText[ERRORS.TOO_SHORT];
  }

  return null;
};

export default {
  validate,
  ERRORS,
};
