import Tasks from 'APP/Tasks';

import { loadMessagesAboveTheTime } from '../loadMessagesAboveTheTime/loadMessagesAboveTheTime';
import checkIsMissedMessages from './checkIsMissedMessages';

export const addMessagesToStore = async ({ previousMessageTs, messages, group }) => {
  const messagesWithoutEditQueue = messages.filter((message) => {
    return !group.messagesStore.getMessageById(message.clientUuid)?.isEditQueue;
  });

  if (!previousMessageTs) {
    Tasks.messaging.updateOldOrFakeMessages({
      messages: messagesWithoutEditQueue,
      groupId: group.id,
    });
    return;
  }

  const isMissedMessages = checkIsMissedMessages({
    group,
    previousMessageTs,
  });

  if (isMissedMessages) {
    const lastMessage = messages[messages.length - 1];
    await loadMessagesAboveTheTime(group.id, Number(lastMessage.serverTime), false);
    return;
  }

  Tasks.messaging.updateOldOrFakeMessages({
    messages: messagesWithoutEditQueue,
    groupId: group.id,
    prevMessageTs: Number(previousMessageTs),
  });
};
