import VirtualBackgroundExtension, {
  IVirtualBackgroundProcessor,
} from 'agora-extension-virtual-background';
import AgoraRTC, {
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
  ICameraVideoTrack,
  ILocalVideoTrack,
  IMicrophoneAudioTrack,
  RemoteStreamType,
} from 'agora-rtc-sdk-ng';
import { action, computed, observable } from 'mobx';

import { BlurOptions, CallBlurType } from 'APP/constants/callBackground';
import { AGORA_APP_ID, ClientRole, LOW_ENCODER_CONFIG } from 'APP/constants/calls';
import { ProviderUid } from 'APP/packages/callProviders/callProviders.types';
import { IDevices } from 'APP/packages/deviceInfo/getDefaultDevices';
import logger from 'APP/packages/logger';
import { platformInfo } from 'APP/packages/platformInfo/platformInfo';
import { storage } from 'APP/packages/storage';

import { ProviderMediaType, ProviderRemoteStreamType } from '../callProviders.constants';
import { AGORA_CLIENT_CALL_SETTINGS } from './AgoraClient.constants';
import {
  IAgoraClientConstructorParams,
  IAgoraClientHandlers,
  IAgoraClientJoinParams,
  IAgoraCreateClientParams,
  IVolumeIndicatorHandlerResult,
} from './AgoraClient.types';

AgoraRTC.setLogLevel(4);

class AgoraClient {
  @observable localVideoTrack: ICameraVideoTrack | null = null;
  @observable localAudioTrack: IMicrophoneAudioTrack | null = null;
  @observable localScreenTrack: ILocalVideoTrack | null = null;
  private isLocalTrackResetting = false;
  @observable blurType = storage.callBackground.getBlurBackgroundType() || CallBlurType.None;
  isJoined = false;
  @observable
  private role = 'audience';
  private processor: IVirtualBackgroundProcessor | null = null;
  private isDualStream: boolean;
  private withVirtualBackground: boolean;
  private publishPromises: Promise<any>[] = [];
  private channelId: string;
  private client: IAgoraRTCClient | undefined;
  private eventHandlers: Partial<IAgoraClientHandlers> = {};

  private devices: IDevices;
  isScreenSharing = false;
  uid: ProviderUid | null;
  screenUid: ProviderUid | null;

  constructor(props: IAgoraClientConstructorParams) {
    const { isScreenSharing, isDualStream, withVirtualBackground = true } = props;

    this.isScreenSharing = isScreenSharing;
    this.isDualStream = isDualStream;
    this.withVirtualBackground = withVirtualBackground;
  }

  async createClient(params: IAgoraCreateClientParams): Promise<void> {
    const role = params.role;
    this.devices = params.devices;

    if (!this.client) {
      this.client = AgoraRTC.createClient({ ...AGORA_CLIENT_CALL_SETTINGS, role });
      this.role = role;
    }

    if (!this.client || this.isScreenSharing) {
      return;
    }
    this.client.enableAudioVolumeIndicator();

    if (this.isDualStream) {
      try {
        this.client.setLowStreamParameter(LOW_ENCODER_CONFIG);
        await this.client.enableDualStream();
      } catch (e) {
        logger.get('AGORA').error('Does not support Virtual Background!');
      }
    }

    this.eventHandlers.onUserPublished = params.handlers?.onUserPublished;
    this.eventHandlers.onUserUnpublished = params.handlers?.onUserUnpublished;
    this.eventHandlers.onUserJoined = params.handlers?.onUserJoined;
    this.eventHandlers.onUserLeft = params.handlers?.onUserLeft;
    this.eventHandlers.onVolumeIndicator = params.handlers?.onVolumeIndicator;
    this.eventHandlers.onLeave = params.handlers?.onLeave;
    this.eventHandlers.onJoin = params.handlers?.onJoin;
    this.eventHandlers.onUserMediaChanged = params.handlers?.onUserMediaChanged;
    this.eventHandlers.onLocalAudioTrackEnd = params.handlers?.onLocalAudioTrackEnd;
    this.eventHandlers.onLocalAudioTrackStart = params.handlers?.onLocalAudioTrackStart;

    this.client.on('user-published', this.handleUserPublished.bind(this));
    this.client.on('user-unpublished', this.handleUserUnpublished.bind(this));
    this.client.on('user-joined', this.handleUserJoined.bind(this));
    this.client.on('user-left', this.handleUserLeft.bind(this));
    this.client.on('volume-indicator', this.handleVolumeIndicator.bind(this));
  }

  async join(params: IAgoraClientJoinParams): Promise<void> {
    if (!this.client) return;

    const { token, uid, screenUid, channelId } = params;
    const promise = (async (): Promise<void> => {
      if (!this.client) {
        return;
      }

      try {
        this.uid = uid;
        if (screenUid) {
          this.screenUid = screenUid;
        }
        this.channelId = channelId;
        await this.client.join(AGORA_APP_ID, channelId, token, uid);
        this.isJoined = true;
        if (!this.isScreenSharing && this.eventHandlers.onJoin) {
          await this.eventHandlers.onJoin({ channelId });
        }
      } catch (e) {
        logger.get('AgoraClient').error('join', e);
      }
    })();

    this.publishPromises.push(promise);
    await promise;
  }

  async setSomeUserTypeStream(uid: ProviderUid, type: ProviderRemoteStreamType): Promise<void> {
    if (this.client) {
      await this.client.setRemoteVideoStreamType(uid, type as unknown as RemoteStreamType);
    }
  }

  async leave(): Promise<void> {
    if (this.isJoined && this.client) {
      this.isJoined = false;
      await this.client.leave();
      if (!this.isScreenSharing && this.eventHandlers.onLeave) {
        await this.eventHandlers.onLeave({ channelId: this.channelId });
      }
    }
  }

  private async onTrackEnded(): Promise<void> {
    if (this.eventHandlers?.onLocalAudioTrackEnd) {
      this.eventHandlers.onLocalAudioTrackEnd();
    }
  }

  private async resetLocalAudioTrack(muted = false): Promise<void> {
    if (!this.localAudioTrack || this.isLocalTrackResetting) {
      return;
    }

    this.isLocalTrackResetting = true;
    this.localAudioTrack.off('track-ended', this.onTrackEnded);
    await this.unpublishAudio();
    await this.createAudioTrack();
    await this.publishTrack(this.localAudioTrack);
    await this.localAudioTrack.setMuted(muted);
    this.isLocalTrackResetting = false;
  }

  @action
  private async createAudioTrack(): Promise<IMicrophoneAudioTrack> {
    if (!this.localAudioTrack) {
      this.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack({
        AEC: true,
        ANS: true,
        microphoneId: this.devices.audioinput?.deviceId,
      });

      if (this.eventHandlers.onLocalAudioTrackStart) {
        this.eventHandlers.onLocalAudioTrackStart(this.devices.audioinput?.deviceId);
      }
    }
    this.localAudioTrack.on('track-ended', this.onTrackEnded.bind(this));

    return this.localAudioTrack;
  }

  @action
  private async createVideoTrack(): Promise<ICameraVideoTrack> {
    if (!this.localVideoTrack) {
      const localVideoTrack = await AgoraRTC.createCameraVideoTrack({
        encoderConfig: '480p_9',
        cameraId: this.devices.videoinput?.deviceId,
      });

      if (!this.withVirtualBackground) {
        this.localVideoTrack = localVideoTrack;

        return this.localVideoTrack;
      }

      const extension = new VirtualBackgroundExtension();

      if (!extension.checkCompatibility()) {
        logger.get('AgoraClient').error('Does not support Virtual Background!');
      }

      AgoraRTC.registerExtensions([extension]);
      this.processor = extension.createProcessor();
      // We don't need to specify parameters
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      await this.processor.init();
      localVideoTrack.pipe(this.processor).pipe(localVideoTrack.processorDestination);
      this.processor.setOptions(
        this.blurType && this.blurType !== CallBlurType.None
          ? BlurOptions[this.blurType]
          : BlurOptions[CallBlurType.Small]
      );

      this.localVideoTrack = localVideoTrack;
    }
    return this.localVideoTrack;
  }

  @action
  private async createScreenSharingTrack(electronSourceId?: string): Promise<ILocalVideoTrack> {
    if (!this.localScreenTrack) {
      if (electronSourceId) {
        const stream = await (navigator as Navigator).mediaDevices.getUserMedia({
          audio: false,
          video: {
            // ToDo: There are no such parameters. need to check for new syntax
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            mandatory: {
              chromeMediaSource: 'desktop',
              chromeMediaSourceId: electronSourceId,
            },
          },
        });

        const track = stream.getVideoTracks()[0];

        this.localScreenTrack = AgoraRTC.createCustomVideoTrack({
          mediaStreamTrack: track,
        });
      } else {
        const track = await AgoraRTC.createScreenVideoTrack({
          encoderConfig: '1080p_2',
        });
        this.localScreenTrack = Array.isArray(track) ? track[0] : track;
      }
    }

    return this.localScreenTrack;
  }

  @action
  private async publishTrack(
    track: ICameraVideoTrack | IMicrophoneAudioTrack | ILocalVideoTrack | null
  ): Promise<void> {
    if (!track || !this.isJoined || !this.client) {
      return;
    }

    try {
      if (this.role !== 'host') {
        await this.client.setClientRole('host');
        this.role = 'host';
      }

      const publishPromise = this.client.publish(track);
      this.publishPromises.push(publishPromise);
      await publishPromise;
    } catch (e) {
      logger.get('AgoraClient').error('AgoraClient.publishTrack', e);
    }
  }

  async publish(): Promise<void> {
    if (this.isScreenSharing) {
      await this.publishTrack(this.localScreenTrack);
    } else {
      await this.publishTrack(this.localVideoTrack);
      await this.publishTrack(this.localAudioTrack);
    }
  }

  async changeMicrophone(device: MediaDeviceInfo): Promise<void> {
    this.devices.audioinput = device;
    await this.resetLocalAudioTrack(this.localAudioTrack?.muted);
  }

  private handleUserJoined(user: IAgoraRTCRemoteUser): void {
    if (this.eventHandlers.onUserJoined) {
      this.eventHandlers.onUserJoined(user);
    }
  }

  private handleUserLeft(user: IAgoraRTCRemoteUser, reason: string): void {
    if (this.eventHandlers.onUserLeft) {
      this.eventHandlers.onUserLeft(user.uid, reason);
    }
  }

  private handleVolumeIndicator(volumes: IVolumeIndicatorHandlerResult[]): void {
    if (this.eventHandlers.onVolumeIndicator) {
      this.eventHandlers.onVolumeIndicator(volumes);
    }
  }

  private async handleUserPublished(
    user: IAgoraRTCRemoteUser,
    mediaType: ProviderMediaType
  ): Promise<void> {
    if (user.uid !== this.screenUid && this.client) {
      await this.client.subscribe(user, mediaType);
      if (mediaType === ProviderMediaType.Audio && user.audioTrack) {
        user.audioTrack.play();
      }

      if (this.eventHandlers.onUserPublished) {
        this.eventHandlers.onUserPublished(user, mediaType);
      }

      if (this.eventHandlers.onUserMediaChanged) {
        if (mediaType === ProviderMediaType.Audio) {
          this.eventHandlers.onUserMediaChanged(user.uid, mediaType, user.hasAudio);
        }
        if (mediaType === ProviderMediaType.Video) {
          this.eventHandlers.onUserMediaChanged(user.uid, mediaType, user.hasVideo);
        }
      }
    }
  }

  private handleUserUnpublished(user: IAgoraRTCRemoteUser, mediaType: ProviderMediaType): void {
    if (user.uid !== this.screenUid) {
      if (this.eventHandlers.onUserUnpublished) {
        this.eventHandlers.onUserUnpublished(user, mediaType);
      }

      if (this.eventHandlers.onUserMediaChanged) {
        if (mediaType === ProviderMediaType.Audio) {
          this.eventHandlers.onUserMediaChanged(user.uid, mediaType, user.hasAudio);
        }
        if (mediaType === ProviderMediaType.Video) {
          this.eventHandlers.onUserMediaChanged(user.uid, mediaType, user.hasVideo);
        }
      }
    }
  }

  async muteAudio(): Promise<void> {
    if (!this.localAudioTrack || this.isLocalTrackResetting) {
      return;
    }
    await this.localAudioTrack.setMuted(true);
  }

  async unpublishAudio(): Promise<void> {
    if (this.localAudioTrack && this.client) {
      if (this.isJoined) {
        await this.client.unpublish(this.localAudioTrack);
      }
      this.localAudioTrack.stop();
      this.localAudioTrack.close();
      this.localAudioTrack = null;
    }
  }

  @action
  async unpublish(): Promise<void> {
    await this.unpublishAudio();
    await this.unpublishVideo();
    try {
      if (this.role !== 'audience' && this.client) {
        await this.client.setClientRole('audience');
        this.role = 'audience';
      }
    } catch (e) {
      logger.get('AgoraClient').error('unpublish', e);
    }
  }

  async unmuteAudio(needToPublish?: boolean): Promise<void> {
    if (this.isLocalTrackResetting) {
      return;
    }

    const promise = (async (): Promise<void> => {
      if (!this.localAudioTrack) {
        await this.createAudioTrack();

        if (this.localAudioTrack && needToPublish) {
          await this.publishTrack(this.localAudioTrack);
        }
      }
      const track = this.localAudioTrack!;

      await track.setMuted(false);

      // https://allprojects.atlassian.net/browse/GEM-22713
      /*
         there is a bug in safari >16 version. if user is setting await this.localAudioTrack.setMuted(false),
         then only Agora local track is unmuting. The root MediaStreamTrack is staying muted.
         So we need to check this behavior and replace audio track.
       */
      if (
        (platformInfo.isMobile || platformInfo.isTablet) &&
        '_originMediaStreamTrack' in this.localAudioTrack! &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.localAudioTrack._originMediaStreamTrack.muted
      ) {
        await this.resetLocalAudioTrack();
      }
    })();

    this.publishPromises.push(promise);

    await promise;
  }

  async startScreenShare(params: {
    needToPublish?: boolean;
    electronSourceId?: string;
  }): Promise<ILocalVideoTrack | null> {
    const { needToPublish, electronSourceId } = params;
    const promise = (async (): Promise<ILocalVideoTrack | null> => {
      try {
        await this.createScreenSharingTrack(electronSourceId);
        if (!this.localScreenTrack) {
          this.dispose();
          return null;
        }
        if (needToPublish) {
          await this.publishTrack(this.localScreenTrack);
        }

        return this.localScreenTrack;
      } catch (e) {
        logger.get('AgoraClient').error('startScreenShare', e);

        this.dispose();

        return null;
      }
    })();

    this.publishPromises.push(promise);
    return await promise;
  }

  async stopScreenShare(): Promise<void> {
    if (this.localScreenTrack && this.client) {
      if (this.isJoined) {
        await this.client.unpublish(this.localScreenTrack);
      }
      this.localScreenTrack.stop();
      this.localScreenTrack.close();
      this.localScreenTrack = null;
    }
  }

  async muteVideo(): Promise<void> {
    await this.unpublishVideo();
  }

  async unpublishVideo(): Promise<void> {
    if (this.localVideoTrack && this.client) {
      if (this.isJoined) {
        await this.client.unpublish(this.localVideoTrack);
      }
      if (this.processor) {
        this.localVideoTrack.unpipe();

        await this.processor.disable();
        this.processor = null;
      }
      this.localVideoTrack.stop();
      this.localVideoTrack.close();
      this.localVideoTrack = null;
    }
  }

  async unmuteVideo(needToPublish?: boolean): Promise<void> {
    const promise = (async (): Promise<void> => {
      if (!this.localVideoTrack) {
        await this.createVideoTrack();

        if (this.localVideoTrack && needToPublish) {
          await this.publishTrack(this.localVideoTrack);
        }
      }

      if (this.localVideoTrack) {
        await this.localVideoTrack.setEnabled(true);
        await this.localVideoTrack.setMuted(false);
      }

      if (this.blurType !== CallBlurType.None && this.processor) {
        this.processor.enable();
      }
    })();

    this.publishPromises.push(promise);
    return await promise;
  }

  setBlur(blurType: CallBlurType): void {
    this.blurType = blurType;
    storage.callBackground.setBlurBackgroundType(blurType);

    if (!this.processor) {
      return;
    }
    if (blurType === CallBlurType.None) {
      this.processor.disable();
    } else {
      this.processor.setOptions(BlurOptions[blurType]);
      this.processor.enable();
    }
  }

  @computed
  get isAudience(): boolean {
    return this.role === ClientRole.Audience;
  }

  async dispose(): Promise<void> {
    if (!this.client) {
      return;
    }

    if (!this.isScreenSharing) {
      this.client.off('user-published', this.handleUserPublished);
      this.client.off('user-unpublished', this.handleUserUnpublished);
      this.client.off('user-joined', this.handleUserJoined);
      this.client.off('user-left', this.handleUserLeft);
      this.client.off('volume-indicator', this.handleVolumeIndicator);
    }

    await Promise.all(this.publishPromises);

    if (this.localAudioTrack) {
      this.localAudioTrack.off('track-ended', this.onTrackEnded);
      this.localAudioTrack.stop();
      this.localAudioTrack.close();
      this.localAudioTrack = null;
    }
    if (this.localVideoTrack && this.processor) {
      this.localVideoTrack.unpipe();

      await this.processor.disable();
    }
    if (this.localVideoTrack) {
      this.localVideoTrack.stop();
      this.localVideoTrack.close();
      this.localVideoTrack = null;
    }
    if (this.localScreenTrack) {
      this.localScreenTrack.stop();
      this.localScreenTrack.close();
      this.localScreenTrack = null;
    }

    await this.leave();
  }
}

export default AgoraClient;
