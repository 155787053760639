import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { SenderPopoverMenuForAdmin } from 'MAIN/ChatView/Messages/Message/components/SenderPopoverMenuForAdmin/SenderPopoverMenuForAdmin';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';
import { Avatar } from 'UIKIT/Avatar/Avatar';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';
import { usePopover } from 'UIKIT/Popover/usePopover';

import { useSenderAvatarPresenter } from '../SenderAvatar.presenter';
import styles from './SenderAvatarForAdmins.styles.m.css';

interface ISenderChannelAvatarProps {
  message: ChatMessage;
}

export const SenderAvatarForAdmins: FC<ISenderChannelAvatarProps> = observer((props) => {
  const { message } = props;
  const popover = usePopover<HTMLButtonElement>();

  const commonPresenter = useSenderAvatarPresenter(message);

  return (
    <>
      <button
        className={classNames({ [styles.avatar]: commonPresenter.senderId })}
        ref={popover.containerRef}
        onClick={popover.toggle}
      >
        <Avatar
          type={AvatarTypes.Round_0}
          url={message.avatarUrl}
          title={message.avatarTitle}
          backgroundColorIndex={message.avatarColorIndex || '0'}
        />
      </button>
      {commonPresenter.senderId && (
        <SenderPopoverMenuForAdmin
          message={message}
          senderId={commonPresenter.senderId}
          popover={popover}
        />
      )}
    </>
  );
});
