import { removeActiveMessageDownloads } from 'APP/Tasks/download/download';
import Entities from 'APP/store';

import { addAlert } from '../../app/showAlert/showAlert';

export function removeGroupLocally({ groupId, alert = null }) {
  const group = Entities.GroupsStore.getGroupById(groupId);

  if (!group) {
    return;
  }

  if (
    Entities.GroupsStore.activeGroupId === group.id ||
    group.id === Entities.GroupsStore.activeGroup?.parentGroup?.id
  ) {
    if (alert) {
      addAlert(alert);
    }
  }

  Entities.GroupsStore.rawGroups.forEach((x) => {
    if (x.parentGroup?.id === groupId) {
      Entities.Counters.delete(x.id);
      Entities.GroupsStore.delete(x.id);
      Entities.InputPanelsStore.deleteById(x.id);
      Entities.BackgroundCalls.delete(x.id);
      Entities.BackgroundStreams.delete(x.id);
    }
  });

  group.messagesStore.messages.forEach((message) => {
    removeActiveMessageDownloads(message.id, groupId);
  });

  Entities.Counters.delete(groupId);
  Entities.GroupsStore.delete(groupId);
  Entities.InputPanelsStore.deleteById(groupId);
  Entities.BackgroundCalls.delete(groupId);
  Entities.BackgroundStreams.delete(groupId);

  if (groupId === Entities.ActiveStream?.stream?.groupId) {
    Entities.ActiveStream.reset();
  }
}
