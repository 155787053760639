import { useLocalStore } from 'mobx-react';

import { EmojiCategoryName, Emojis } from 'APP/constants/emojis';
import bus from 'APP/packages/bus';
import { storage } from 'APP/packages/storage';

interface IEmojiPanelPresenter {
  selectedCategory: EmojiCategoryName;
  categories: EmojiCategoryName[];
  emojis: string[];
  setSelectedCategory(category: EmojiCategoryName): void;
  checkIsActiveCategory(category: EmojiCategoryName): boolean;
  broadcastSelectEmoji(emoji: string): void;
}

export const useEmojiPanelPresenter = (): IEmojiPanelPresenter => {
  const presenter = useLocalStore<IEmojiPanelPresenter>(() => ({
    selectedCategory: storage.selectedEmojiCategory.get() || EmojiCategoryName.Emotions,

    get categories(): EmojiCategoryName[] {
      return Object.keys(Emojis) as EmojiCategoryName[];
    },

    get emojis(): string[] {
      return Emojis[presenter.selectedCategory];
    },

    setSelectedCategory(category: EmojiCategoryName): void {
      presenter.selectedCategory = category;
      storage.selectedEmojiCategory.set(category);
    },

    checkIsActiveCategory(category: EmojiCategoryName): boolean {
      return presenter.selectedCategory === category;
    },

    broadcastSelectEmoji(emoji: string): void {
      bus.messaging.broadcastSelectEmoji(emoji);
    },
  }));

  return presenter;
};
