import Tasks from 'APP/Tasks';
import { ALERT_TYPES } from 'APP/constants/app';
import api from 'APP/packages/api';
import logger from 'APP/packages/logger';

export default async ({ channelId }) => {
  try {
    logger.get('Tasks').debug('streaming.startScreenSharing', { channelId });

    await api.streams.startScreenSharing({ channelId });
  } catch (e) {
    logger.get('Tasks').error('streaming.startScreenSharing', e);
    Tasks.app.addAlert({
      type: ALERT_TYPES.CALL_ERROR_APPEARED,
    });
  }
};
