import { useLocalStore } from 'mobx-react';
import { useLayoutEffect, useRef, useState } from 'react';

export default ({ textFormattingMenuInstance }) => {
  const [ref, setRef] = useState(null);
  const contentInstance = useRef();

  const presenter = useLocalStore(() => ({
    resetValue: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: 0,
      height: 0,
    },

    virtualReference: {
      getBoundingClientRect: () => {
        const selection = document.getSelection();

        if (!selection || !selection.rangeCount) {
          return presenter.resetValue;
        }

        return selection.getRangeAt(0).getBoundingClientRect();
      },
    },

    isOpened: false,

    update: () => {
      if (presenter.formattingMenuTimeoutId) {
        // decreasing count of checks
        clearTimeout(presenter.formattingMenuTimeoutId);
      }

      presenter.formattingMenuTimeoutId = setTimeout(() => {
        const selection = window.getSelection();

        const isOpened = !selection.isCollapsed;

        if (presenter.isOpened) {
          // it updates position of menu and active state of buttons
          contentInstance.current.update();
        }

        if (presenter.isOpened !== isOpened) {
          presenter.isOpened = isOpened;
        }
      }, 30);
    },

    onClickOutside: () => {
      const selection = window.getSelection();

      if (
        !selection.isCollapsed &&
        // if you started select text inside contenteditable and finish outside
        // it detects click like click outside of the area
        (selection.anchorNode.isContentEditable ||
          selection.anchorNode.parentElement.isContentEditable)
      ) {
        return;
      }

      presenter.isOpened = false;
    },

    onClose: () => {
      presenter.isOpened = false;
    },
  }));

  useLayoutEffect(() => {
    textFormattingMenuInstance.current = {
      update: presenter.update,
    };
  }, []);

  return {
    presenter,
    ref,
    setRef,
    contentInstance,
  };
};
