import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { useAvatarWithFullScreenPresenter } from './AvatarWithFullScreen.presenter';
import styles from './AvatarWithFullScreen.styles.m.css';

interface IAvatarWithFullScreenProps {
  className?: string;
  url?: string;
  dataTest?: string;
}

export const AvatarWithFullScreen: FC<IAvatarWithFullScreenProps> = observer(
  ({ className, children, url, dataTest }) => {
    const presenter = useAvatarWithFullScreenPresenter(url);

    return (
      <button
        className={classNames(styles.button, className)}
        disabled={!presenter.isClickable}
        data-test={dataTest}
        onClick={presenter.openFullScreen}
      >
        {children}
      </button>
    );
  }
);
