import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import { openPopup } from 'APP/Tasks/app/popup/popup';
import { Popup } from 'STORE/App/Popups/Popup/Popup';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { NewContactPopup } from '../../Popups/NewContactPopup/NewContactPopup';

interface IContactsPopupPresenter {
  popup: Popup | null;
  hasBackButton: boolean;
  onClose(): void;
  onBack(): void;
  onOpenNewContact(): void;
}

export function useContactsPopupPresenter({
  popupInstance,
  onClose,
  onBack,
}: ICommonPopupsProps): IContactsPopupPresenter {
  const presenter = useLocalStore<IContactsPopupPresenter>(() => ({
    popup: null,

    get hasBackButton(): boolean {
      return !!onBack;
    },

    onClose(): void {
      popupInstance.close();
      onClose?.();
    },

    onBack(): void {
      popupInstance.close();
      onBack?.();
    },

    onOpenNewContact(): void {
      popupInstance.hide();

      presenter.popup = openPopup(NewContactPopup, {
        onBack: () => popupInstance.show(),
        onClose: () => presenter.onClose(),
      });
    },
  }));

  useEffect(() => {
    return () => presenter.popup?.close();
  }, []);

  return presenter;
}
