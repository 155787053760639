import Entities from 'STORE';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

export const getLastEditableMessage = (groupId: string): ChatMessage | null => {
  const group = Entities.GroupsStore.getGroupById(groupId);

  if (!group) {
    return null;
  }

  for (let i = group.messagesStore.messages.length - 1; i >= 0; i--) {
    if (group.messagesStore.messages[i].canBeEdit) {
      return group.messagesStore.messages[i];
    }
  }

  return null;
};
