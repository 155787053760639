import { useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import Entities from 'APP/store';
import { Group } from 'STORE/Groups/Group';
import { User } from 'STORE/Users/User/User';

interface IBlockedByMeUserPanelPresenter {
  group: Group | null;
  user: User | null;
  isLoading: boolean;
  isDisabled: boolean;
  unblock(): void;
}

export function useBlockedByMeUserPanelPresenter(): IBlockedByMeUserPanelPresenter {
  const presenter = useLocalStore<IBlockedByMeUserPanelPresenter>(() => ({
    get group(): Group | null {
      return Entities.GroupsStore.activeGroup;
    },

    get user(): User | null {
      return presenter.group?.groupOpponent || null;
    },

    get isLoading(): boolean {
      return presenter.user?.isLoadingBlockedByMe || false;
    },

    get isDisabled(): boolean {
      return presenter.isLoading;
    },

    unblock(): void {
      if (presenter.user) {
        Tasks.relationships.unblockUser(presenter.user.id);
      }
    },
  }));

  return presenter;
}
