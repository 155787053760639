import { observer } from 'mobx-react';
import React from 'react';

import { useTranslation } from 'APP/packages/translations';
import VisibilityIcon from 'ICONS/ic-preview.svg';
import { IconView } from 'UIKIT/IconView/IconView';
import { SwitchMenuItem } from 'UIKIT/SwitchMenuItem/SwitchMenuItem';

import { useSpaceVisibilityPresenter } from './SpaceVisibility.presenter';

export const SpaceVisibility = observer(({ className, titleClassName, group }) => {
  const presenter = useSpaceVisibilityPresenter({ group });
  const { t } = useTranslation();

  if (!presenter.isShow) {
    return null;
  }

  return (
    <SwitchMenuItem
      className={className}
      titleClassName={titleClassName}
      title={t('space_visibility_visible')}
      isChecked={presenter.isVisible}
      icon={<IconView Icon={VisibilityIcon} />}
      onChange={presenter.onToggle}
    />
  );
});
