import React, { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { useTranslation } from 'APP/packages/translations';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { useClosePopupOnNavigation } from '../../hooks/useClosePopupOnNavigation';
import { AboutContent } from './AboutContent/AboutContent';
import { useAboutPopupPresenter } from './AboutPopup.presenter';

export const AboutPopup: FC<ICommonPopupsProps> = (props) => {
  const { t } = useTranslation();
  const presenter = useAboutPopupPresenter(props);
  useClosePopupOnNavigation(props.popupInstance);

  return (
    <Popup
      title={t('about_gem4me')}
      isOpened
      handlePrevStep={presenter.hasBackButton ? presenter.onBack : null}
      onClose={presenter.onClose}
    >
      <AboutContent />
    </Popup>
  );
};
