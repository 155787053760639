import { PayloadType } from 'APP/model/message/messageModel.types';
import { previewBuilder } from 'APP/packages/file-upload/previewBuilder';
import { getFixedSizeBlurredImage } from 'APP/packages/imageResizer';
import { t } from 'MODULE/i18n/service';

import File from '../File';

class Video extends File {
  graphicSize;
  _previewUrl;
  duration;

  static MIN_FORWARD_VIDEO_MESSAGE_WIDTH = 200;

  constructor(data) {
    super(data);

    this.graphicSize = data.graphicSize || {};
    this.localPreview = data.localPreview;
    this._previewUrl = data.previewUrl || data.localPreview;
    //TODO: couldn't find the duration property -> packages/core/www/app/packages/api/types/model/videoResponseData.ts
    this.duration = data.duration;
  }

  toJSON() {
    const data = super.toJSON();

    return {
      ...data,
      payloadType: PayloadType.Video,
      previewUrl: this._previewUrl,
      duration: this.duration,
      graphicSize: this.graphicSize,
    };
  }

  get previewSize() {
    const { width, height } = this.graphicSize;

    if (!width || !height) {
      return { width, height };
    }

    return File.calculatePreviewSize(
      width,
      height,
      File.PREVIEW_MAX_WIDTH,
      File.PREVIEW_MAX_HEIGHT
    );
  }

  get placeholderUrl() {
    return (
      this.localPreview ||
      getFixedSizeBlurredImage(
        this.previewUrl,
        {
          width: File.PREVIEW_MAX_WIDTH,
          height: 0,
        },
        this.graphicSize
      )
    );
  }

  get previewUrl() {
    return this.localPreview || this._previewUrl;
  }

  get hasPlaceholder() {
    const { width } = this.previewSize;

    return (
      (width < File.MIN_QUOTE_WIDTH && this.message.quotedMessage) ||
      (width < Video.MIN_FORWARD_VIDEO_MESSAGE_WIDTH && this.message.forward)
    );
  }

  get typeDescription() {
    return t('message_desctription_video_file');
  }

  async upload() {
    await super.upload();
    if (this.isLoaded) {
      this._previewUrl = await previewBuilder.getFilePreviewUrl(
        this.userFile,
        this.fileName,
        this.localPreview
      );
    }
  }
}

export default Video;
