import React, { FC, memo, useState } from 'react';

import { TextPlaceholder } from '../TextPlaceholder/TextPlaceholder';
import styles from './MenuItemsPlaceholder.styles.m.css';

export interface IMenuItemsPlaceholderProps {
  className?: string;
  count?: number;
}

export const MenuItemsPlaceholder: FC<IMenuItemsPlaceholderProps> = memo((props) => {
  const { className, count = 20 } = props;
  const [list] = useState<string[]>(Array.from(Array(count), (_, index) => `item_${index}`));

  return (
    <div className={className}>
      {list.map((item) => {
        return (
          <div
            key={item}
            className={styles.itemContainer}
          >
            <TextPlaceholder width={300} />
          </div>
        );
      })}
    </div>
  );
});
