import React, { FC, memo } from 'react';

import { ProviderUid } from 'APP/packages/callProviders/callProviders.types';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';

import { OpponentVideoItem } from '../OpponentVideoItem/OpponentVideoItem';

interface IGridLayoutListProps {
  opponentsKeys: ProviderUid[];
  avatarType: AvatarTypes;
}

export const GridLayoutList: FC<IGridLayoutListProps> = memo(({ opponentsKeys, avatarType }) => {
  return (
    <>
      {opponentsKeys.map((uid) => (
        <OpponentVideoItem
          key={uid}
          opponentUid={uid}
          avatarType={avatarType}
        />
      ))}
    </>
  );
});
