import classNames from 'classnames';
import React, { memo } from 'react';

import date from 'APP/packages/date';

import { VideoPlayerType } from '../VideoPlayer.constants';
import styles from './VideoPlayerTime.styles.m.css';

export const VideoPlayerTime = memo(({ type, progress, progressMax }) => {
  const containerClass = classNames(styles.container, {
    [styles.mini]: type === VideoPlayerType.Mini,
  });

  return (
    <div className={containerClass}>
      {date.secondsToString(progress)} / {date.secondsToString(progressMax)}
    </div>
  );
});
