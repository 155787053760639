import { FC } from 'react';

import { useDeepLinkHandlerPresenter } from './DeepLinkHandler.presenter';

interface IDeepLinkHandlerProps {
  finishDeepLinkHandling(): void;
}

export const DeepLinkHandler: FC<IDeepLinkHandlerProps> = ({ finishDeepLinkHandling }) => {
  useDeepLinkHandlerPresenter({ finishDeepLinkHandling });

  return null;
};
