import React, { useCallback, FC } from 'react';
import { useParams } from 'react-router-dom';

import { UnauthorizedCallType } from 'APP/model/unauthorizedCall/unauthorizedCallModel.constants';
import { ICallInvitationInfo } from 'APP/model/unauthorizedCall/unauthorizedCallModel.types';
import { platformInfo } from 'APP/packages/platformInfo/platformInfo';
import { useTranslation } from 'APP/packages/translations';
import { RouterLink } from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import { Avatar } from 'UIKIT/Avatar/Avatar';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';
import { Button } from 'UIKIT/Button/Button';

import styles from './CallFinishedInfo.styles.m.css';

interface ICallFinishedInfoProps {
  invitationInfo?: ICallInvitationInfo;
  isGroupCall?: boolean;
}

export const CallFinishedInfo: FC<ICallFinishedInfoProps> = ({ invitationInfo, isGroupCall }) => {
  const { t } = useTranslation();
  const navigateTo = useNavigateTo();
  const params = useParams<{ id: string; callType: UnauthorizedCallType; channelId?: string }>();

  const onJoinAsGuestClick = useCallback(() => {
    window.open(invitationInfo?.inviteLink);
  }, [invitationInfo?.inviteLink]);

  const onBack = useCallback(() => {
    navigateTo({
      to: RouterLink.callInvitationGroup,
      params: { id: params.id, callType: params.callType, channelId: params.channelId },
    });
  }, [navigateTo, params.id]);

  return (
    <div className={styles.container}>
      <Avatar
        className={styles.avatar}
        type={AvatarTypes.Round_50}
        title={invitationInfo?.userName}
        url={invitationInfo?.imageUrl}
      />
      <h2 className={styles.title}>{t('call_finished')}</h2>
      <h3 className={styles.subTitle}>{t('install_gem_guest')}</h3>
      <Button
        onClick={onJoinAsGuestClick}
        className={styles.joinAsGuestButton}
        size="20"
        theme="1"
      >
        {t(platformInfo.isMobile ? 'common_install_gem' : 'common_sign_into_gem')}
      </Button>
      {isGroupCall && (
        <Button
          onClick={onBack}
          className={styles.backButton}
          size="20"
          theme="2"
        >
          {t('calls_join')}
        </Button>
      )}
    </div>
  );
};
