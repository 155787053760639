import React, { FC } from 'react';

import BackIcon from 'APP/icons/ic-fill-back.svg';
import NextIcon from 'APP/icons/ic-fill-next.svg';
import PauseIcon from 'APP/icons/ic-pause.svg';
import PlayIcon from 'APP/icons/ic-play.svg';
import RepeatTrackIcon from 'APP/icons/ic-repeat-track.svg';
import RepeatIcon from 'APP/icons/ic-repeat.svg';
import ShuffleIcon from 'APP/icons/ic-shuffle.svg';
import { useTranslation } from 'APP/packages/translations';
import { FAB } from 'UIKIT/FAB/FAB';

import { AudioPlayerOrder, AudioPlayerRepeat, IAudioPlayerConfig } from '../../AudioPlayer.types';
import { AudioPlayerSpeed } from '../AudioPlayerSpeed/AudioPlayerSpeed';
import { useAudioPlayerControlsPresenter } from './AudioPlayerControls.presenter';
import styles from './AudioPlayerControls.styles.m.css';

interface IAudioPlayerControlsProps {
  isPlaying: boolean;
  config: IAudioPlayerConfig;
  isShowOrder: boolean;
  isShowRepeat: boolean;
  hasPrevious: boolean;
  hasNext: boolean;
  onTogglePlay(): void;
  onPrevious(): void;
  onNext(): void;
  onChangeSpeed(speed: number): void;
  onConfigUpdate(config: Partial<IAudioPlayerConfig>): void;
}

// TODO: add translations
const ORDER_TITLE_MAP = {
  [AudioPlayerOrder.Direct]: 'In Order',
  [AudioPlayerOrder.Reverse]: 'Reverse',
  [AudioPlayerOrder.Shuffle]: 'Shuffle List',
};

// TODO: add translations
const REPEAT_TITLE_MAP = {
  [AudioPlayerRepeat.None]: 'No Repeat List',
  [AudioPlayerRepeat.List]: 'Repeat List',
  [AudioPlayerRepeat.Track]: 'Repeat Track',
};

export const AudioPlayerControls: FC<IAudioPlayerControlsProps> = ({
  isPlaying,
  config,
  isShowOrder,
  isShowRepeat,
  hasPrevious,
  hasNext,
  onTogglePlay,
  onPrevious,
  onNext,
  onChangeSpeed,
  onConfigUpdate,
}) => {
  const { t } = useTranslation();
  const { changeOrder, changeRepeat } = useAudioPlayerControlsPresenter({
    config,
    onConfigUpdate,
  });

  return (
    <div className={styles.container}>
      <div className={styles.actions}>
        <FAB
          Icon={BackIcon}
          size="0"
          iconSize="10"
          theme="3_1"
          isDisabled={!hasPrevious}
          onClick={onPrevious}
        />

        <FAB
          Icon={isPlaying ? PauseIcon : PlayIcon}
          size="0"
          iconSize="10"
          theme="3_1"
          onClick={onTogglePlay}
        />

        <FAB
          Icon={NextIcon}
          size="0"
          iconSize="10"
          theme="3_1"
          isDisabled={!hasNext}
          onClick={onNext}
        />
      </div>

      <div className={styles.actions}>
        {isShowOrder && (
          <FAB
            title={t(ORDER_TITLE_MAP[config.order])}
            Icon={ShuffleIcon}
            size="0"
            iconSize="10"
            theme={config.order === AudioPlayerOrder.Direct ? '3_1' : '3_2'}
            onClick={changeOrder}
          />
        )}

        {isShowRepeat && (
          <FAB
            title={t(REPEAT_TITLE_MAP[config.repeat])}
            Icon={config.repeat === AudioPlayerRepeat.Track ? RepeatTrackIcon : RepeatIcon}
            size="0"
            iconSize="10"
            theme={config.repeat === AudioPlayerRepeat.None ? '3_1' : '3_2'}
            onClick={changeRepeat}
          />
        )}

        <AudioPlayerSpeed
          speed={config.speed}
          isDisabled={false}
          onChange={onChangeSpeed}
        />
      </div>
    </div>
  );
};
