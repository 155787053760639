/* global window, VERSION, BUILD_NUMBER, API_URL, STREAM_API_URL, WEBSOCKET_API_URL, HASH */
import i18n from 'i18next';
import { Provider as MobxProvider } from 'mobx-react';
import React from 'react';
import { render } from 'react-dom';

import { DEFAULT_LANGUAGE } from 'APP/constants/language';
import { analyticsClient } from 'APP/packages/analytics/analytics';
import { setConfigApi } from 'APP/packages/api';
import { configure as configureDate } from 'APP/packages/date';
import { ElectronApi } from 'APP/packages/electron/ElectronApi';
import { calculatePlatform } from 'APP/packages/platformInfo/platformInfo';
import { setSseConfig } from 'APP/packages/streamApi';
import { configure as configureTranslations } from 'APP/packages/translations';
import { setWebsocketConfig } from 'APP/packages/websocket';
import envConfig from 'CONFIG';
import { isWebPSupported } from 'UTILS/browser';

import BugsnagWrapper from './components/BugsnagWrapper';
import './polyfills';
import registerSW from './registerSW';
import AppRouter from './router';
import mobxStores from './store';

i18n.init({
  interpolation: {
    prefix: '{',
    suffix: '}',
    defaultVariables: { 0: '{0}', 1: '{1}', 2: '{2}', 3: '{3}' },
  },
});

window.app = {
  version: VERSION + (BUILD_NUMBER ? `.${BUILD_NUMBER}` : ''),
  hash: HASH,
  apiUrl: API_URL,
  streamApiUrl: STREAM_API_URL,
  websocketApiUrl: WEBSOCKET_API_URL,
};

const renderApp = () =>
  render(
    <MobxProvider {...mobxStores}>
      <BugsnagWrapper>
        <AppRouter />
      </BugsnagWrapper>
    </MobxProvider>,
    document.getElementById('gem')
  );

setConfigApi({
  apiUrl: API_URL,
});

setWebsocketConfig({
  apiUrl: WEBSOCKET_API_URL,
});

setSseConfig({
  apiUrl: STREAM_API_URL,
});

configureDate({
  language: window.navigator.language || DEFAULT_LANGUAGE,
});

analyticsClient.initialize({
  amplitudeApiKey: envConfig.amplitudeApiKey,
  gtmId: envConfig.gtmId,
});

Promise.all([
  configureTranslations({
    language: window.navigator.language || DEFAULT_LANGUAGE,
    defaultLanguage: DEFAULT_LANGUAGE,
  }),
  calculatePlatform(window.navigator.userAgent),
  registerSW(),
  (async function () {
    window.supportsWEBP = await isWebPSupported();
  })(),
]).then(() => {
  renderApp();
  ElectronApi.hideLoader();
});
