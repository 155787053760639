import Tasks from 'APP/Tasks';
import { showConfirm } from 'APP/Tasks/app/showConfirm/showConfirm';
import { CallEventTypes } from 'APP/constants/calls';
import { CallMemberStatus } from 'APP/model/call/callModel.constants';
import { ICallMember } from 'APP/model/call/callModel.types';
import { ICallInfo } from 'APP/model/call/callModel.types';
import { platformInfo } from 'APP/packages/platformInfo/platformInfo';
import Entities from 'APP/store';
import { t } from 'MODULE/i18n/service';

export const checkCanUserConnectToCall = async (callInfo: ICallInfo): Promise<boolean> => {
  const joinedMembers = callInfo.members.filter(
    ({ status }: ICallMember) => status === CallMemberStatus.Joined
  );
  const myId = Entities.UsersStore.Me.id;
  // the user is connected from a different platform
  if (
    joinedMembers.some(
      ({ userId, platform }: ICallMember) =>
        userId.toString() === myId && platform !== platformInfo.platform
    )
  ) {
    const confirmResult = await showConfirm({
      text: t('call_reconnect_alert'),
      primaryText: t('common_yes'),
      secondaryText: t('common_no'),
    });

    if (!confirmResult) {
      return false;
    }

    await Tasks.calls.setActionCall({
      action: CallEventTypes.ForceFinish,
      channelId: callInfo.channelId,
      userId: myId,
    });
  }
  // the maximum number of users has been exceeded
  if (callInfo.initiatorId.toString() !== myId) {
    const isJoinedInitiator = joinedMembers.some(
      ({ userId }: ICallMember) => userId.toString() === callInfo.initiatorId.toString()
    );
    const maxParticipants = Entities.Calls.maxParticipants - (isJoinedInitiator ? 0 : 1);
    if (joinedMembers.length >= maxParticipants) {
      Tasks.app.showAlert(t('calls_group_call_limit_exceed_alert'));

      return false;
    }
  }

  return true;
};
