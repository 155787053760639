import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { MiniatureMessage } from 'APP/main/ChatView/components/MiniatureMessage/MiniatureMessage';
import { Message } from 'STORE/Messages/Message/Message';

import styles from './QuotedMessage.styles.m.css';

type TQuotedMessageTheme = '1' | '2';

interface IQuotedMessageProps {
  className?: string;
  theme?: TQuotedMessageTheme;
  message: Message;
}

export const QuotedMessage: FC<IQuotedMessageProps> = observer((props) => {
  const { className, theme = '1', message } = props;

  if (!message) {
    return null;
  }

  return (
    <div
      className={classNames(styles.container, styles[`theme-${theme}`], className)}
      data-test="reply-message"
    >
      <div className={styles.content}>
        <MiniatureMessage
          message={message}
          titleClassName={styles.title}
          textClassName={styles.text}
          iconClassName={styles.icon}
          title={message.avatarTitle}
        />
      </div>
    </div>
  );
});
