import { computed, action } from 'mobx';

import { Edit } from './Edit';
import Mention from './Mention';
import Payloads from './Payloads';
import Send from './Send';
import { setValueToInput } from './Tasks/setValueToInput/setValueToInput';
import { Voice } from './Voice/Voice';

export class InputPanel {
  constructor(root) {
    this.root = root;

    this.payloads = new Payloads(root, this);
    this.send = new Send(root, this);
    this.edit = new Edit(root, this);
    this.mention = new Mention(root, this);
    this.voice = new Voice(root, this);
  }

  @action
  setValue(value) {
    setValueToInput({ value });
  }

  @computed
  get isOnlyLeftSideMessages() {
    return this.root.ChatStore.isOnlyLeftSideMessages;
  }

  @computed
  get group() {
    return this.root.GroupsStore.activeGroup;
  }

  @computed
  get inputPanel() {
    return this.root.InputPanelsStore.activeInputPanel;
  }

  @computed
  get value() {
    return this.inputPanel && this.inputPanel.value;
  }

  @computed
  get isEmpty() {
    return (
      !this.inputPanel ||
      (this.inputPanel.isEmptyValue && this.payloads.isEmpty && !this.voice.isShowRecordPanel)
    );
  }

  @computed
  get isEmptyValue() {
    return this.inputPanel && this.inputPanel.isEmptyValue;
  }

  @computed
  get showInputPanel() {
    return !this.voice.isShowRecordPanel;
  }

  get quoteMessage() {
    return this.inputPanel && this.inputPanel.quoteMessage;
  }

  get isDisabled() {
    return this.send.isDisabledSend && !this.voice.isShowRecordPanel;
  }
}
