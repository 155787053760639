import { useLocalStore, useAsObservableSource } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { mapUserProfileBadgeToIcon } from 'APP/constants/userBadges';
import useUserOnlineStatusText from 'APP/main/hooks/userOnlineStatusText';
import { GroupType } from 'APP/model/group/groupModel.types';
import { UserProfileUsernameBadge } from 'APP/model/user/userModel.types';
import Entities from 'APP/store';
import { IUserNameBadge } from 'APP/types/userNameBadges';
import { User } from 'STORE/Users/User/User';

interface ISearchResultUserPresenter {
  hasGroupWithUser: boolean;
  p2pRoute: string;
  userNameBadge: IUserNameBadge | null;
  verified: boolean;
  handleGroupClick(): void;
}

export const useSearchResultUserPresenter = (
  user?: User
): { presenter: ISearchResultUserPresenter; userOnlineStatusText: string } => {
  const source = useAsObservableSource({ user });

  const userOnlineStatusText = useUserOnlineStatusText({
    user,
  });

  const presenter = useLocalStore<ISearchResultUserPresenter>(() => ({
    get hasGroupWithUser(): boolean {
      return !!source.user?.group;
    },

    get p2pRoute(): string {
      return presenter.hasGroupWithUser
        ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore TODO: remove after User model refactored on TS
          `/${source.user?.group?.route}/${source.user?.group?.id}`
        : '';
    },

    handleGroupClick(): void {
      if (!source.user || presenter.hasGroupWithUser) {
        return;
      }

      Tasks.group.createGroupAndInvite({
        members: [Entities.UsersStore.Me.id, source.user?.id],
        type: GroupType.P2P,
      });
    },

    get userNameBadge(): IUserNameBadge | null {
      if (!source.user || !source.user.usernameBadge) {
        return null;
      }

      return mapUserProfileBadgeToIcon[source.user.usernameBadge as UserProfileUsernameBadge];
    },

    get verified(): boolean {
      return source.user?.verified || false;
    },
  }));

  return { presenter, userOnlineStatusText };
};
