import { useAsObservableSource, useLocalStore } from 'mobx-react';

import { UpdateReason } from 'APP/constants/scroll';
import Entities from 'STORE';
import { Group } from 'STORE/Groups/Group';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

interface ILoadAboveObserverPresenter {
  group: Group | null;
  hasLoadAboveObserver: boolean;
  onInViewChange(isInView: boolean): void;
}

export const useLoadAboveObserverPresenter = (
  groupId: string,
  firstMessage: ChatMessage | null
): ILoadAboveObserverPresenter => {
  const source = useAsObservableSource({ groupId, firstMessage });

  const presenter = useLocalStore<ILoadAboveObserverPresenter>(() => ({
    get group(): Group | null {
      return Entities.GroupsStore.getGroupById(source.groupId);
    },

    get hasLoadAboveObserver(): boolean {
      return !(
        presenter.group?.messagesStore.messages.length &&
        presenter.group.messagesStore.messages[0].id === source.firstMessage?.id &&
        presenter.group.messagesStore.isLoadedFirstMessages
      );
    },

    onInViewChange(isInView: boolean): void {
      if (isInView) {
        Entities.ChatStore.setScrollChanged(UpdateReason.LoadAbove);
      }
    },
  }));

  return presenter;
};
