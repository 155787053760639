import isValidStartPosition from '../../utils/isValidStartPosition';

const ALLOWED_SYMBOLS = ['', '\n', '*', '~', '_', ' '];

export default (keyWord, keyWordType, existingHandler) => (text, index, symbolMatches) => {
  if (existingHandler) {
    existingHandler(text, index, symbolMatches);
  }

  if (!isValidStartPosition(text, index, ALLOWED_SYMBOLS)) {
    return;
  }

  const keyWordStartIndex = index;
  const keyWordEndIndex = keyWordStartIndex + keyWord.length;
  const keyWordInText = text.substring(keyWordStartIndex, keyWordEndIndex);

  if (keyWordInText === keyWord) {
    symbolMatches[keyWord[0]].push({
      start: index,
      end: keyWordEndIndex,
      keyWord: { keyWordType, keyWord },
    });
  }
};
