import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { ProfileNameBadge } from 'APP/components/ProfileNameBadge/ProfileNameBadge';
import { mapUserProfileBadgeToIcon } from 'APP/constants/userBadges';
import { PayloadType } from 'APP/model/message/messageModel.types';
import { Avatar } from 'UIKIT/Avatar/Avatar';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';

import Album from './Payload/Album';
import { Article } from './Payload/Article/Article';
import File from './Payload/File';
import Image from './Payload/Image';
import Text from './Payload/Text';
import Video from './Payload/Video';
import styles from './styles.m.scss';

const PayloadComponents = {
  [PayloadType.RichText]: Text,
  [PayloadType.File]: File,
  [PayloadType.AudioMessage]: File,
  [PayloadType.Image]: Image,
  [PayloadType.Video]: Video,
  [PayloadType.CircleVideo]: Video,
  [PayloadType.Album]: Album,
  [PayloadType.Article]: Article,
};

const Message = ({ message, onClick }) => {
  const badgeObj = message.usernameBadge
    ? mapUserProfileBadgeToIcon[message.usernameBadge]
    : undefined;

  const PayloadComponent = PayloadComponents[message.payloadType];
  if (!PayloadComponent) {
    return null;
  }

  return (
    <div
      role="button"
      tabIndex={0}
      className={classNames(styles.message, {
        [styles.active]: message.isActive,
      })}
      onClick={() => {
        onClick(message);
      }}
    >
      <Avatar
        type={AvatarTypes.Round_10}
        title={message.avatarTitle}
        url={message.avatarUrl}
        backgroundColorIndex={message.avatarColorIndex}
        className={styles.avatar}
      />
      <div className={styles.body}>
        <div className={styles.header}>
          <div className={styles.senderNameWrapper}>
            <div className={styles.senderName}>{message.avatarTitle}</div>
            <ProfileNameBadge
              verified={message.verified}
              source={badgeObj?.source}
              animated={badgeObj?.animated}
              className={styles.badges}
            />
          </div>
          <div className={styles.date}>{message.date}</div>
        </div>
        <div className={styles.text}>
          <PayloadComponent message={message} />
        </div>
      </div>
    </div>
  );
};

export default observer(Message);
