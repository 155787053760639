const arrayToObjectWithKey = (array, key) =>
  array.reduce(
    (previousValue, currentValue) => ({
      ...previousValue,
      [currentValue[key]]: currentValue,
    }),
    {}
  );

export default arrayToObjectWithKey;
