import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { ScheduledCallGroupAvatar } from '../../components/ScheduledCallGroupAvatar/ScheduledCallGroupAvatar';
import { ScheduledCallsStartButton } from '../../components/ScheduledCallsStartButton/ScheduledCallsStartButton';
import { IScheduledEventInfoWithDate } from '../ScheduledCallsList.types';
import { useScheduledCallsListPopupItemPresenter } from './ScheduledCallsListPopupItem.presenter';
import styles from './ScheduledCallsListPopupItem.styles.m.css';

interface IScheduledCallsListPopupItemProps {
  scheduledEvent: IScheduledEventInfoWithDate;
  onStartClick?(): void;
  onClick?(): void;
}

export const ScheduledCallsListPopupItem: FC<IScheduledCallsListPopupItemProps> = observer(
  ({ scheduledEvent, onClick, onStartClick }) => {
    const presenter = useScheduledCallsListPopupItemPresenter({ scheduledEvent });

    return (
      <>
        {scheduledEvent.extendedDate && (
          <div className={styles.extendedDate}>{scheduledEvent.extendedDate}</div>
        )}
        <div
          role="button"
          tabIndex={0}
          onClick={onClick}
          className={styles.container}
        >
          <div className={styles.time}>{presenter.time}</div>
          <div className={styles.name}>{scheduledEvent.name}</div>
          <ScheduledCallGroupAvatar
            groupColorIndex={presenter.groupColorIndex}
            groupAvatar={scheduledEvent.groupAvatar}
            groupName={scheduledEvent.groupName}
          />
          <ScheduledCallsStartButton
            onClick={onStartClick}
            startTs={scheduledEvent.startTs}
            initiatorId={scheduledEvent.initiatorId}
            groupId={scheduledEvent.groupId}
          />
        </div>
      </>
    );
  }
);
