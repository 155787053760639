import React, { FC, MutableRefObject } from 'react';

import { useTranslation } from 'APP/packages/translations';

import styles from './NewMessages.styles.m.css';

interface INewMessagesProps {
  reference: MutableRefObject<HTMLDivElement | null>;
}

export const NewMessages: FC<INewMessagesProps> = ({ reference }) => {
  const { t } = useTranslation();

  return (
    <div
      className={styles.container}
      ref={reference}
    >
      <span className={styles.text}>{t('new_messages')}</span>
    </div>
  );
};
