import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { useTranslation } from 'APP/packages/translations';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { useClosePopupOnNavigation } from '../../hooks/useClosePopupOnNavigation';
import { useEditProfilePopupPresenter } from './EditProfilePopup.presenter';
import styles from './EditProfilePopup.styles.m.css';
import { EditProfileView } from './EditProfileView/EditProfileView';

export const EditProfilePopup: FC<ICommonPopupsProps> = observer((props) => {
  const { t } = useTranslation();
  const presenter = useEditProfilePopupPresenter(props);
  useClosePopupOnNavigation(props.popupInstance);

  return (
    <Popup
      className={styles.popup}
      title={t('user_profile_update_header')}
      isOpened
      isHidden={props.popupInstance.isHidden}
      handlePrevStep={presenter.hasBackButton ? presenter.onBack : null}
      onClose={presenter.onClose}
    >
      <EditProfileView
        onOpenRewards={presenter.openRewardsPopup}
        onSave={presenter.onBack}
      />
    </Popup>
  );
});
