import api from 'APP/packages/api';
import logger from 'APP/packages/logger';

export const setCallPermissionsForUser = async ({ channelId, userId, permissions }) => {
  try {
    await api.calls.setPermissionsForUser({
      channelId,
      userId,
      ...permissions,
    });
  } catch (error) {
    logger.get('API').error('calls.setCallPermissionsForUser', error);
  }
};
