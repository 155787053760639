import { useMemo } from 'react';

interface IStoriesProgressPresenter {
  progressItems: string[];
  getProgressWidth(index: number, activeIndex: number, activeProgress: number): number;
}

function getProgressWidth(index: number, activeIndex: number, activeProgress: number): number {
  if (index === activeIndex) {
    return activeProgress;
  } else {
    return index < activeIndex ? 100 : 0;
  }
}

export function useStoriesProgressPresenter(count: number): IStoriesProgressPresenter {
  const progressItems = useMemo(() => {
    return Array(count)
      .fill('story')
      .map((item, index) => `${item}_${index}`);
  }, [count]);

  return { progressItems, getProgressWidth };
}
