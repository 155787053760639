import { useAsObservableSource, useLocalStore } from 'mobx-react';

import { SpaceTemplate } from 'APP/model/space/spaceModel.types';
import { useTranslation } from 'APP/packages/translations';
import DeleteIcon from 'ICONS/ic-delete.svg';
import LeaveIcon from 'ICONS/ic-log-out.svg';
import MarkAsReadIcon from 'ICONS/ic-mark-as-read.svg';
import PinIcon from 'ICONS/ic-pin-stroke.svg';
import UnpinIcon from 'ICONS/ic-s-unpin.svg';
import { useSpaceActions } from 'MAIN/hooks/space/useSpaceActions';
import Entities from 'STORE';
import { Space } from 'STORE/Spaces/Space';
import { IPopoverMenuItem } from 'UIKIT/PopoverMenu/PopoverMenu.types';

interface ISpaceListItemPresenter {
  isShowOnboarding: boolean;
  hasContextMenu: boolean;
  menuItems: IPopoverMenuItem[];
}

export function useSpaceListItemPresenter(space: Space): ISpaceListItemPresenter {
  const { t } = useTranslation();
  const source = useAsObservableSource({ space });
  const spaceActions = useSpaceActions(space);

  const presenter = useLocalStore<ISpaceListItemPresenter>(() => ({
    get isShowOnboarding(): boolean {
      return (
        !Entities.GroupsStore.isLoading &&
        !Entities.spacesStore.isLoading &&
        source.space.template === SpaceTemplate.Friends &&
        source.space.isOwner
      );
    },

    get hasContextMenu(): boolean {
      return !!presenter.menuItems.length;
    },

    get menuItems(): IPopoverMenuItem[] {
      return [
        {
          key: 'markAsRead',
          title: t('action_over_chat_mark_as_read'),
          icon: MarkAsReadIcon,
          isVisible: spaceActions.canMarkAsRead,
          onClick: spaceActions.onMarkAsRead,
        },
        {
          key: 'pin',
          title: t('common_pin'),
          icon: PinIcon,
          isVisible: spaceActions.canPin,
          onClick: spaceActions.onPin,
        },
        {
          key: 'unpin',
          title: t('common_unpin'),
          icon: UnpinIcon,
          isVisible: spaceActions.canUnpin,
          onClick: spaceActions.onUnpin,
        },
        {
          key: 'leave',
          title: t('space_leave_space'),
          icon: LeaveIcon,
          isVisible: spaceActions.canLeave,
          onClick: spaceActions.onLeave,
        },
        {
          key: 'delete',
          title: t('space_delete_space'),
          icon: DeleteIcon,
          isVisible: spaceActions.canDelete,
          onClick: spaceActions.onDelete,
        },
      ].filter((item) => item.isVisible);
    },
  }));

  return presenter;
}
