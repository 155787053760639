import Tasks from 'APP/Tasks';
import { PayloadType } from 'APP/model/message/messageModel.types';
import { IButtonPayloadModel } from 'STORE/Messages/Message/Payload/Buttons/Buttons';

interface IDefaultCommandButtonPresenterProps {
  groupId: string;
  button: IButtonPayloadModel;
}

interface IDefaultCommandButtonPresenter {
  onClick(): void;
}

export const useDefaultCommandButtonPresenter = ({
  button,
  groupId,
}: IDefaultCommandButtonPresenterProps): IDefaultCommandButtonPresenter => {
  const onClick = (): void => {
    Tasks.messaging.createNewMessages({
      messages: [
        {
          type: PayloadType.ButtonsSelected,
          data: { button: { title: button.title, id: button.id } },
          groupId,
        },
      ],
    });
  };

  return { onClick };
};
