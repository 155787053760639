
export const STICKER_FULL_SCREEN_MAX_WIDTH = 330;
export const STICKER_FULL_SCREEN_MAX_HEIGHT = 330;

export const STICKER_BUBBLE_MAX_WIDTH = 220;
export const STICKER_BUBBLE_MAX_HEIGHT = 220;

export const STICKER_POPUP_MAX_WIDTH = 85;
export const STICKER_POPUP_MAX_HEIGHT = 96;

export const STICKER_PANEL_MAX_WIDTH = 88;
export const STICKER_PANEL_MAX_HEIGHT = 88;
