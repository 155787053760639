import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useRef } from 'react';

import MoreIcon from 'ICONS/ic-more.svg';
import { IconButton } from 'UIKIT/IconButton';
import { PopoverMenu } from 'UIKIT/PopoverMenu/PopoverMenu';
import popupItemStyles from 'UIKIT/PopupItem/styles.m.scss';

import { CallMember } from '../../../Shared/CallMember';
import { usePresenter } from './presenter';
import styles from './styles.m.css';

export const PermissionRequestItem = observer((props) => {
  const presenter = usePresenter(props.permissionType, props.userId);
  const buttonRef = useRef();
  const classes = classNames(popupItemStyles.container, styles.item);

  return (
    <CallMember
      userId={props.userId}
      className={classes}
    >
      <IconButton
        Icon={MoreIcon}
        onClick={presenter.togglePopover}
        size="medium"
        iconSize="medium"
        setRef={buttonRef}
      />
      <PopoverMenu
        isOpened={presenter.isPopoverOpened}
        placement="top-start"
        menuItems={presenter.menu}
        reference={buttonRef.current}
        onClose={presenter.closePopover}
      />
    </CallMember>
  );
});
