import { observer } from 'mobx-react';
import React from 'react';

import { ArticleRedactorAudioPlayer } from './ArticleRedactorAudioPlayer/ArticleRedactorAudioPlayer';
import usePresenter from './presenter';

export const Audio = observer(({ parentPresenter, payload, order, id }) => {
  const presenter = usePresenter({ parentPresenter, payload, order, id });

  return (
    <ArticleRedactorAudioPlayer
      src={presenter.message.payload.url}
      fileName={presenter.message.payload.fileName}
      fileSize={presenter.message.payload.fileSize}
      payloadId={presenter.message.payload.id}
      uploadProgress={presenter.message.payload.uploadProgress}
      cancelUploadAndDeleteMessage={presenter.message.payload.cancelUploadAndDeleteMessage}
    />
  );
});
