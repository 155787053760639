import Tasks from 'APP/Tasks';
import { addAlert } from 'APP/Tasks/app/showAlert/showAlert';
import { ALERT_TYPES } from 'APP/constants/app';
import { RouterLink } from 'APP/router/constants';
import { navigateTo } from 'APP/router/refs/navigateTo';
import Entities from 'STORE';

import { GroupsEvent } from '../GroupsEvent';

export class SpaceDelete extends GroupsEvent {
  process() {
    const { spaceId } = this.eventPayload;
    const isSpaceOpened =
      Entities.spacesStore.activeSpaceId === spaceId && !Entities.GroupsStore.activeGroup;

    Tasks.space.removeSpaceLocally(spaceId);

    if (isSpaceOpened) {
      navigateTo({ to: RouterLink.main });
      addAlert({ type: ALERT_TYPES.SPACE_IS_NOT_ACCESSIBLE });
    }
  }
}
