import { computed, observable, action } from 'mobx';

class Members {
  @observable isMembersShown = false;

  constructor(root, parent) {
    this.root = root;
    this.parent = parent;
  }

  @computed
  get isVisibleMembersButton() {
    return this.parent.call.isAnswered;
  }

  @computed
  get membersQuantity() {
    return `${this.parent.call.members.memberOnCallList.length} / ${this.parent.call.members.memberList.length}`;
  }

  @action
  showMembers = () => {
    this.isMembersShown = true;
  };

  @action
  hideMembers = () => {
    this.isMembersShown = false;
  };

  @action
  reset = () => {
    this.isMembersShown = false;
  };

  @action
  toggleShowMembersSection = () => {
    if (this.isMembersShown) {
      this.hideMembers();
    } else {
      this.showMembers();
    }
  };
}

export default Members;
