import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { PrivacyValue } from 'APP/model/userSettings/userSettingsModel.types';
import { useTranslation } from 'APP/packages/translations';
import { Button } from 'UIKIT/Button/Button';
import { ButtonTypes } from 'UIKIT/Button/Button.types';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';
import { PopupFooter } from 'UIKIT/PopupFooter/PopupFooter';
import { PopupHeader } from 'UIKIT/PopupHeader/PopupHeader';
import { RadioLabel } from 'UIKIT/RadioLabel/RadioLabel';

import { usePrivacyPagePresenter } from './PrivacyPage.presenter';
import styles from './PrivacyPage.styles.m.css';

interface IPrivacyPageProps {
  title: string;
  subtitle: string;
  labels: Record<PrivacyValue, string>;
  setValue(value: PrivacyValue): Promise<void>;
  value: PrivacyValue;
  goBack(): void;
  onClose(): void;
}

export const PrivacyPage: FC<IPrivacyPageProps> = observer((props) => {
  const { title, subtitle, labels, setValue, value, goBack, onClose } = props;
  const { t } = useTranslation();
  const presenter = usePrivacyPagePresenter({
    labels,
    setValue,
    value,
    goBack,
  });

  return (
    <>
      <PopupHeader
        title={title}
        handlePrevStep={goBack}
        onClose={onClose}
      />
      <div className={classNames(styles.content, customScrollStyles.container)}>
        <div className={styles.title}>{subtitle}</div>
        <form
          id="privacyPage"
          onSubmit={presenter.submit}
        >
          {presenter.list.map((item) => {
            return (
              <RadioLabel
                name="privacy-setting"
                key={item.key}
                title={item.title}
                isActive={item.isActive}
                onChange={item.onChange}
              />
            );
          })}
        </form>
      </div>
      <PopupFooter>
        <Button
          type={ButtonTypes.Submit}
          form="privacyPage"
          className={styles.button}
          theme="1"
        >
          {t('common_save')}
        </Button>
      </PopupFooter>
    </>
  );
});
