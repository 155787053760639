import { IImageSize } from '.';

const MIN_ITEM_WIDTH = 20; // минимальная ширина слота
const MAX_ROW_RATIO = 1; // максимальная высота 100% ширины
const MIN_ROW_RATIO = 0.2; // минимальная высота 20% ширины

interface ILayoutRow extends IImageSize {
  _w?: number;
}

export interface ICalculateLayoutResult {
  ratio: number;
  layout: IImageSize[][];
}

export const calculateLayout = (_layout: IImageSize[][]): ICalculateLayoutResult => {
  // клонируем альбом для дальнейшего мутирования элементов (дописать размеры)
  const layout = JSON.parse(JSON.stringify(_layout));

  // бежим по строкам
  const normalizeRows: ILayoutRow[] = layout.map((row: ILayoutRow[]) => {
    // пропускаем пустые строки
    if (row[0] === null) {
      return null;
    }
    // приводим к одинаковой высоте все элементы в строке
    // за базу берем высоту первого элемента
    let baseHeight = row[0].h;
    row.forEach((item) => {
      if (item) {
        const scale = baseHeight / item.h;
        item._w = item.w * scale;
      }
    });

    // находим общую шируну строки
    const absolutWidth = row.reduce((acc, x) => acc + (x ? Number(x._w) : 0), 0);

    // еще раз бежим по строке и считаем относительную ширину в % и удалаем временную переменную
    row.forEach((item) => {
      if (item) {
        // минимальная ширина элемента в строке
        item.containerWidth = (Number(item._w) / absolutWidth) * 100;

        delete item._w;
      }
    });

    // нормализуем получившиеся размеры контейнеров. Задаем минимальную ширину MIN_ITEM_WIDTH
    // и переспределяем ранее распределенные размеры

    // ищем узкие элементы
    const smallItems = row.filter((item) => item && item.containerWidth < MIN_ITEM_WIDTH);
    if (smallItems.length) {
      // ширина занимаемая узкими элементами
      const originalWidth = smallItems.reduce((acc, x) => acc + x.containerWidth, 0);

      row.forEach((item) => {
        if (!item) return;
        if (item.containerWidth < MIN_ITEM_WIDTH) {
          // задаем минимальную ширину
          item.containerWidth = MIN_ITEM_WIDTH;
        } else {
          // перераспределяем ширину
          item.containerWidth =
            ((100 - smallItems.length * MIN_ITEM_WIDTH) * item.containerWidth) /
            (100 - originalWidth);
        }
      });
    }

    // пересчитываем высоту с учетом ограничений
    baseHeight = Math.min(
      Math.max(baseHeight, absolutWidth * MIN_ROW_RATIO),
      absolutWidth * MAX_ROW_RATIO
    );

    // считаем новый ратио картинок с учетом ограничений
    row.forEach((item) => {
      if (item) {
        item.containerRatio = baseHeight / ((item.containerWidth / 100) * absolutWidth);
      }
    });
    // возвращаем размеры строки
    return {
      w: absolutWidth,
      h: baseHeight,
    };
  });

  // приводи все строкик одной ширине
  const baseWidth = normalizeRows[0].w;
  const normalizeCollums = normalizeRows.map((item) => {
    if (item === null) {
      return null;
    }
    const scale = baseWidth / item.w;
    return {
      w: scale * item.w,
      h: scale * item.h,
    };
  });

  // рассчитываем высоту альбома
  const albumHeight = normalizeCollums.reduce((acc, item) => acc + (item ? item.h : 0), 0);
  // возвращаем фльбом с рассчитанным ratio
  return {
    ratio: albumHeight / baseWidth,
    layout,
  };
};
