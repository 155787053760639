import React, { FC, memo, useCallback } from 'react';

import { useTranslation } from 'APP/packages/translations';
import { Tooltip } from 'UIKIT/Tooltip/Tooltip';

import styles from './AudioPlayerSpeed.styles.m.css';

interface IAudioPlayerSpeedProps {
  speed: number;
  isDisabled: boolean;
  onChange(speed: number): void;
}

const SPEED_OPTIONS: number[] = [1, 1.5, 2];

export const AudioPlayerSpeed: FC<IAudioPlayerSpeedProps> = memo(
  ({ speed, isDisabled = false, onChange }) => {
    const { t } = useTranslation();
    const changeSpeed = useCallback(() => {
      const newSpeed = SPEED_OPTIONS.find((item) => item > speed) || SPEED_OPTIONS[0];
      onChange(newSpeed);
    }, [speed, onChange]);

    return (
      <Tooltip text={t('audio_player_tooltip_speed')}>
        <button
          className={styles.button}
          type="button"
          disabled={isDisabled}
          onClick={changeSpeed}
        >
          {speed}x
        </button>
      </Tooltip>
    );
  }
);
