import routerConst from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';

export const useUsersLinkPresenter = ({ usersIds, groupId, messageId }) => {
  const navigateTo = useNavigateTo();

  const onClick = () => {
    const { state, stateEnum } = routerConst;

    navigateTo({
      state: {
        [state.popup]: stateEnum.popup.newUsers,
        [state.usersIds]: usersIds,
        [state.groupId]: groupId,
        [state.messageId]: messageId,
      },
    });
  };

  return {
    onClick,
  };
};
