import { runInAction } from 'mobx';

import Tasks from 'APP/Tasks';
import { messageModel } from 'APP/model/model';
import Entities from 'APP/store';
import { Counter } from 'APP/store/CountersStore/Counter';
import { Group } from 'APP/store/Groups/Group';
import { MessagesStore } from 'APP/store/Messages/Messages';
import { PinnedMessages } from 'APP/store/Messages/PinnedMessages/PinnedMessages';
import { SharedData } from 'APP/store/SharedData/SharedData';

interface IGroupMessagesState {
  messageStore: MessagesStore;
  sharedData: SharedData;
  pinnedMessages: PinnedMessages;
  counter: Counter | null;
}

interface IRestoreGroupMessagesState extends IGroupMessagesState {
  group: Group;
}

function saveGroupState(group: Group): IGroupMessagesState {
  const messageStore = group.messagesStore;
  const sharedData = group.sharedData;
  const pinnedMessages = group.pinnedMessages;
  const counter = group.counter;

  return { messageStore, sharedData, pinnedMessages, counter };
}

function clearGroupMessages(group: Group): void {
  runInAction(() => {
    group.messagesStore = new MessagesStore(group);
    group.sharedData = new SharedData(group);
    group.sharedData = new SharedData(group);

    if (!group.counter) {
      return;
    }

    Entities.Counters.add(
      new Counter({
        groupId: group.id,
        counter: 0,
        seenTs: group.counter.seenTs,
        unreadUserMentionsCounter: 0,
        lastUserMentionTs: 0,
      })
    );
  });
}

function restoreGroupState({
  group,
  messageStore,
  sharedData,
  pinnedMessages,
  counter,
}: IRestoreGroupMessagesState): void {
  runInAction(() => {
    group.messagesStore = messageStore;
    group.sharedData = sharedData;
    group.pinnedMessages = pinnedMessages;

    if (!group.counter || !counter) {
      return;
    }

    Entities.Counters.add(counter);
  });
}

interface IClearHistoryParams {
  groupId: string;
  isClearForAll?: boolean;
}

export async function clearHistory({
  groupId,
  isClearForAll = false,
}: IClearHistoryParams): Promise<void> {
  const group = Entities.GroupsStore.getGroupById(groupId);

  if (!group) {
    return;
  }

  const prevGroupState = saveGroupState(group);

  clearGroupMessages(group);

  const request = isClearForAll
    ? messageModel.clearGroupHistoryForAll
    : messageModel.clearGroupHistory;

  try {
    const timestamp = await request({ groupId: group.id });

    Tasks.messaging.clearHistoryLocally({ groupId: group.id, timestamp });
  } catch {
    restoreGroupState({ ...prevGroupState, group });
  }
}
