import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { ElementType, FC, useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { openPopup } from 'APP/Tasks/app/popup/popup';
import { MAX_PIN_GROUPS } from 'APP/constants/group';
import { IUserNameBadgeIcon, mapUserProfileBadgeToIcon } from 'APP/constants/userBadges';
import { UserProfileUsernameBadge } from 'APP/model/user/userModel.types';
import { useTranslation } from 'APP/packages/translations';
import Entities from 'APP/store';
import IcBlock from 'ICONS/ic-block.svg';
import IcClear from 'ICONS/ic-clear.svg';
import IcDelete from 'ICONS/ic-delete.svg';
import IcPin from 'ICONS/ic-pin-stroke.svg';
import IcReport from 'ICONS/ic-report.svg';
import IcShare from 'ICONS/ic-share_iOS.svg';
import IcUnlock from 'ICONS/ic-unlock.svg';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import { ReportUserPopup } from 'MAIN/PopupManager/Popups/ReportUserPopup/ReportUserPopup';
import { ShareContactPopup } from 'MAIN/PopupManager/Popups/ShareContactPopup/ShareContactPopup';
import useUserOnlineStatusText from 'MAIN/hooks/userOnlineStatusText';
import { Popup } from 'STORE/App/Popups/Popup/Popup';
import { Group } from 'STORE/Groups/Group';
import { User } from 'STORE/Users/User/User';

import useSharedDataItems from '../hooks/sharedDataItems';

export interface IUserProfileInitiatorSource {
  groupId?: string;
  messageId?: string;
}

interface IUserActionItem {
  key: string;
  Icon: ElementType;
  title: string;
  onClick(): void;
}

interface IUserProfilePresenterParams {
  userId: string;
  groupId: string;
  initiatorSource?: IUserProfileInitiatorSource;
  popupInstance?: Popup;
  actions: Record<string, () => void>;
  onStartCall(): void;
}

interface IUserProfilePresenter {
  childPopup: Popup | null;
  user: User | null;
  userOnlineStatusText: string;
  group: Group | null;
  userNameBadge: IUserNameBadgeIcon | null;
  verified: boolean;
  isAvailableJoinCall: boolean;
  hasConversationWithUser: boolean;
  isNotificationsEnabled: boolean;
  childPagesList: any[]; // TODO: add typings
  actionItems: IUserActionItem[];
  canPinMore: boolean;
  canPinChat: boolean;
  canUnpinChat: boolean;
  canShareContact: boolean;
  canBlockUser: boolean;
  canUnblockUser: boolean;
  canClearHistory: boolean;
  canReportUser: boolean;
  canDeleteChat: boolean;
  hasActiveCall: boolean;
  canJoinToCall: boolean;
  canStartCall: boolean;
  startChat(): Promise<void>;
  toggleNotifications(): void;
  pinChat(): void;
  unpinChat(): void;
  shareContact(): void;
  blockUser(): void;
  unblockUser(): void;
  clearHistory(): void;
  reportUser(): void;
  deleteChat(): void;
  joinToBackgroundCall(): Promise<void>;
  goToChat(): Promise<void>;
  startAudioCall(): Promise<void>;
  startVideoCall(): Promise<void>;
  openChildPopup<T>(component: FC<ICommonPopupsProps<T>>, params: T): void;
}

export function useUserProfilePresenter({
  userId,
  groupId,
  initiatorSource = {},
  popupInstance,
  actions,
  onStartCall,
}: IUserProfilePresenterParams): IUserProfilePresenter {
  const { t } = useTranslation();
  const sharedDataItems = useSharedDataItems({ groupId, actions });

  const source = useAsObservableSource({ initiatorSource });

  const presenter = useLocalStore<IUserProfilePresenter>(() => ({
    childPopup: null,

    get user(): User | null {
      return Entities.UsersStore.getUserById(userId);
    },

    get userOnlineStatusText(): string {
      return userOnlineStatusText;
    },

    get group(): Group | null {
      return presenter.user?.group || null;
    },

    get userNameBadge(): IUserNameBadgeIcon | null {
      if (!presenter.user?.usernameBadge) {
        return null;
      }

      return mapUserProfileBadgeToIcon[presenter.user.usernameBadge as UserProfileUsernameBadge];
    },

    get verified(): boolean {
      return presenter.user?.verified || false;
    },

    get isAvailableJoinCall(): boolean {
      if (!presenter.group?.id) {
        return false;
      }

      const activeCall = Entities.Calls.getCallByGroupId(presenter.group?.id);

      if (activeCall) {
        return false;
      }

      return Boolean(presenter.group?.backgroundCall);
    },

    get hasConversationWithUser(): boolean {
      return Boolean(presenter.group);
    },

    async startChat(): Promise<void> {
      await presenter.user?.startChat();
    },

    get isNotificationsEnabled(): boolean {
      return !presenter.group?.muted;
    },

    toggleNotifications(): void {
      presenter.group?.muted ? presenter.group?.unmute() : presenter.group?.mute();
    },

    get childPagesList(): any[] {
      return sharedDataItems.items;
    },

    get actionItems(): IUserActionItem[] {
      return [
        {
          key: 'pin-chat',
          isVisible: presenter.canPinChat,
          Icon: IcPin,
          title: t('group_chat_pin'),
          onClick: presenter.pinChat,
        },
        {
          key: 'unpin-chat',
          isVisible: presenter.canUnpinChat,
          Icon: IcPin,
          title: t('group_chat_unpin'),
          onClick: presenter.unpinChat,
        },
        {
          key: 'share',
          isVisible: presenter.canShareContact,
          Icon: IcShare,
          title: t('share_contact'),
          onClick: presenter.shareContact,
        },
        {
          key: 'block',
          isVisible: presenter.canBlockUser,
          Icon: IcBlock,
          title: t('block_userprofile'),
          onClick: presenter.blockUser,
        },
        {
          key: 'unblock',
          isVisible: presenter.canUnblockUser,
          Icon: IcUnlock,
          title: t('unblock_userprofile'),
          onClick: presenter.unblockUser,
        },
        {
          key: 'clear-messages',
          isVisible: presenter.canClearHistory,
          Icon: IcClear,
          title: t('profile_clear_messages'),
          onClick: presenter.clearHistory,
        },
        {
          key: 'report-user',
          isVisible: presenter.canReportUser,
          Icon: IcReport,
          title: t('common_report'),
          onClick: presenter.reportUser,
        },
        {
          key: 'delete-chat',
          isVisible: presenter.canDeleteChat,
          Icon: IcDelete,
          title: t('chat_details_delete_chat'),
          onClick: presenter.deleteChat,
        },
      ].filter((item) => item.isVisible);
    },

    get canPinMore(): boolean {
      return Entities.GroupsStore.pinnedChatGroups.length < MAX_PIN_GROUPS;
    },

    get canPinChat(): boolean {
      if (
        !presenter.group ||
        presenter.group.isBot ||
        presenter.group.isChannel ||
        presenter.group.withoutMe
      ) {
        return false;
      }

      return presenter.canPinMore && !presenter.group.isPinned;
    },

    pinChat(): void {
      if (!presenter.group) {
        return;
      }

      Tasks.group.pinGroup({ groupId: presenter.group.id });
    },

    get canUnpinChat(): boolean {
      if (
        !presenter.group ||
        presenter.group.isBot ||
        presenter.group.isChannel ||
        presenter.group.withoutMe
      ) {
        return false;
      }

      return presenter.group.isPinned;
    },

    unpinChat(): void {
      if (!presenter.group) {
        return;
      }

      Tasks.group.unpinGroup({ groupId: presenter.group.id });
    },

    get canShareContact(): boolean {
      return !!presenter.user?.isInPhoneBook;
    },

    shareContact(): void {
      if (presenter.user) {
        presenter.openChildPopup(ShareContactPopup, {
          userId: presenter.user.id,
        });
      }
    },

    get canBlockUser(): boolean {
      if (!presenter.user) {
        return false;
      }

      return !presenter.user.isBlockedByMe;
    },

    blockUser(): void {
      Tasks.app.showConfirm({
        title: t('block_user'),
        text: t('block_user_alert', { 0: presenter.user?.displayName }),
        primaryText: t('block'),
        async onSuccess(): Promise<void> {
          await Tasks.relationships.blockUser(presenter.user?.id);
        },
      });
    },

    get canUnblockUser(): boolean {
      if (!presenter.user) {
        return false;
      }

      return presenter.user.isBlockedByMe;
    },

    unblockUser(): void {
      Tasks.relationships.unblockUser(presenter.user?.id);
    },

    get canClearHistory(): boolean {
      return presenter.hasConversationWithUser;
    },

    async clearHistory(): Promise<void> {
      if (!presenter.hasConversationWithUser) {
        return;
      }

      const isConfirmed = await Tasks.app.showConfirm({
        text: t('chat_details_clear_history_message'),
      });

      if (isConfirmed) {
        Tasks.group.clearHistory({
          groupId: presenter.group!.id,
        });
      }
    },

    get canReportUser(): boolean {
      return !presenter.user?.isBot;
    },

    reportUser(): void {
      if (presenter.user) {
        presenter.openChildPopup(ReportUserPopup, {
          userId: presenter.user.id,
          groupId: source.initiatorSource?.groupId,
          messageId: source.initiatorSource?.messageId,
        });
      }
    },

    get canDeleteChat(): boolean {
      return presenter.hasConversationWithUser && !presenter.group?.isBot;
    },

    deleteChat(): void {
      Tasks.app.showConfirm({
        text: t('delete_chat_alert', { 0: presenter.group?.avatarTitle }),
        async onSuccess(): Promise<void> {
          await Tasks.group.leaveGroup({
            groupId: presenter.group?.id,
          });
        },
      });
    },

    get hasActiveCall(): boolean {
      return Boolean(presenter.group?.activeCall);
    },

    get canJoinToCall(): boolean {
      if (!presenter.hasConversationWithUser) {
        return false;
      }

      return presenter.isAvailableJoinCall;
    },

    async joinToBackgroundCall(): Promise<void> {
      if (!presenter.group?.id) {
        return;
      }

      const backgroundCall = Entities.BackgroundCalls.getCallByGroupId(presenter.group?.id);

      if (!backgroundCall) {
        return;
      }

      onStartCall();

      await Tasks.calls.joinToCall({
        groupId: presenter.group?.id,
        channelId: backgroundCall.channelId,
        providerType: backgroundCall.callProvider,
        withAudio: !!presenter.group?.isP2P,
        withVideo: false,
        callType: backgroundCall.callType,
      });
      await Tasks.group.setActiveGroup({ groupId: presenter.group?.id });
    },

    async goToChat(): Promise<void> {
      await Tasks.group.setActiveGroup({ groupId: presenter.group?.id });
      onStartCall && onStartCall();
    },

    get canStartCall(): boolean {
      return !presenter.canJoinToCall && !presenter.hasActiveCall && !presenter.user?.isBot;
    },

    async startAudioCall(): Promise<void> {
      if (!presenter.group?.id) {
        return;
      }

      onStartCall();
      await Tasks.group.createP2PGroup(presenter.user!.id, false);
      await Tasks.calls.startAudioCall({
        groupId: presenter.group?.id,
      });
    },

    async startVideoCall(): Promise<void> {
      if (!presenter.group?.id) {
        return;
      }
      onStartCall();
      await Tasks.group.createP2PGroup(presenter.user!.id, false);
      await Tasks.calls.startVideoCall({
        groupId: presenter.group?.id,
      });
    },

    openChildPopup<T>(component: FC<ICommonPopupsProps<T>>, params: T): void {
      popupInstance?.hide();

      presenter.childPopup = openPopup(component, {
        params,
        onClose: () => popupInstance?.close(),
        onBack: () => popupInstance?.show(),
      });
    },
  }));

  const userOnlineStatusText = useUserOnlineStatusText({
    user: presenter.user,
  });

  useEffect(() => {
    return () => presenter.childPopup?.close();
  }, []);

  return presenter;
}
