import { LocationState } from 'history';
import { useLocalStore } from 'mobx-react';
import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Tasks from 'APP/Tasks';
import { RouterLink } from 'APP/router/constants';
import Entities from 'APP/store';
import { getUserLanguage } from 'UTILS/getUserLanguage';
import { getHost } from 'UTILS/url';

interface IInAppViewPresenter {
  isLoading: boolean;
  isOpen: boolean;
  url: string;
  lang: string;
  authToken: string | null;
  isDarkTheme: boolean;
  hasError: boolean;

  onLoad(): void;

  getUrl(baseUrl: string): string;

  loadMarket(currentUrl: string): Promise<void>;
}

interface IInAppViewPresenterOptions {
  url?: string;
  isLocal?: boolean;
}

function mergeQSParams(qs: string, params: Record<string, string>): string {
  const searchParams: URLSearchParams = new URLSearchParams(qs);
  const newParams: Record<string, string> = {};
  for (const pair of searchParams.entries()) {
    newParams[pair[0]] = pair[1];
  }
  return new URLSearchParams({ ...newParams, ...params }).toString();
}

export const useInAppViewPresenter = (data: IInAppViewPresenterOptions): IInAppViewPresenter => {
  const { url, isLocal } = data;
  const location = useLocation<Record<string, LocationState>>();

  const presenter = useLocalStore<IInAppViewPresenter>(() => ({
    isLoading: true,
    isInit: false,
    isOpen: false,
    url: '',
    authToken: '',
    lang: getUserLanguage(),
    isDarkTheme: Entities.appearance.isDark,
    hasError: false,

    onLoad(): void {
      presenter.isLoading = false;
    },

    getUrl(baseUrl: string): string {
      const qsParams: Record<string, string> = {
        language: presenter.lang,
        darkMode: presenter.isDarkTheme.toString(),
      };
      if (presenter.authToken) {
        qsParams['authCode'] = presenter.authToken;
      }
      const qs = mergeQSParams(baseUrl.split('?')[1], qsParams);
      return `${baseUrl.split('?')[0]}?${qs}`;
    },

    async loadMarket(currentUrl: string): Promise<void> {
      presenter.isOpen = true;
      presenter.isLoading = true;

      const { authToken } = await Tasks.market.getMarketConfig(true);
      const host = getHost(currentUrl);
      const isAuthHost = Entities.marketplace.authHosts.includes(host);
      if (authToken || !isAuthHost) {
        presenter.hasError = false;
        presenter.lang = getUserLanguage();
        presenter.isDarkTheme = Entities.appearance.isDark;
        presenter.authToken = isAuthHost ? authToken : null;

        presenter.url = presenter.getUrl(currentUrl || Entities.marketplace.baseMarketUrl);

        // hide loading if market not triggered event
        setTimeout(presenter.onLoad, 10000);
      } else {
        presenter.hasError = true;
        presenter.isLoading = false;
      }
    },
  }));

  useLayoutEffect((): void => {
    const currentUrl: string = url || (location?.state?.url as string);
    if (isLocal || location.pathname === RouterLink.market) {
      const isMarketUrl =
        presenter.url && presenter.url.indexOf(Entities.marketplace.baseAdsUrl) < 0;
      const hasUrlUnChanged = !currentUrl || presenter.getUrl(currentUrl) === presenter.url;
      const hasThemeUnChanged = presenter.isDarkTheme === Entities.appearance.isDark;
      const hasLangUnChanged = presenter.lang === getUserLanguage();

      if (
        isMarketUrl &&
        hasUrlUnChanged &&
        hasThemeUnChanged &&
        hasLangUnChanged &&
        !presenter.hasError
      ) {
        presenter.isLoading = false;
        presenter.isOpen = true;
        return;
      }

      presenter.loadMarket(currentUrl);
    } else {
      presenter.isOpen = false;
    }
  }, [location, url]);

  return presenter;
};
