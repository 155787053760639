import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, useMemo } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import dateService from 'APP/packages/date';
import { useTranslation } from 'APP/packages/translations';
import IcStopPoll from 'ICONS/ic-stop-poll.svg';
import { IconView } from 'UIKIT/IconView/IconView';

import { ICommonPopupsProps } from '../../../PopupManager.types';
import { useClosePopupOnNavigation } from '../../../hooks/useClosePopupOnNavigation';
import matchIconToPlatform from '../utils/matchIconToPlatofrm';
import matchPlatformNameToPlatform from '../utils/matchPlatformNameToPlatofrm';
import CopyIpButton from './CopyIpButton';
import SessionItemDetailsPlaceholder from './Placeholder';
import {
  ISessionsItemDetailsPopupParams,
  useSessionsItemDetailsPopupPresenter,
} from './SessionsItemDetailsPopup.presenter';
import styles from './SessionsItemDetailsPopup.styles.m.css';

export const SessionsItemDetailsPopup: FC<ICommonPopupsProps<ISessionsItemDetailsPopupParams>> =
  observer((props) => {
    const { t } = useTranslation();
    const presenter = useSessionsItemDetailsPopupPresenter(props);
    useClosePopupOnNavigation(props.popupInstance);

    const platformName = matchPlatformNameToPlatform(presenter.session?.platform);

    const lastOnline = useMemo(() => {
      return presenter.session?.lastOnline
        ? dateService.format(parseInt(presenter.session.lastOnline), {
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          })
        : null;
    }, [presenter.session?.lastOnline]);

    return (
      <Popup
        isOpened
        handlePrevStep={presenter.hasBackButton ? presenter.onBack : null}
        onClose={presenter.onClose}
      >
        {presenter.session ? (
          <>
            <div className={styles.header}>
              <IconView
                Icon={matchIconToPlatform(presenter.session.platform)}
                className={styles.headerIcon}
              />
              <div className={styles.deviceName}>{presenter.session.device}</div>
              <div className={styles.lastOnline}>{lastOnline}</div>
            </div>
            <div className={styles.body}>
              <div className={styles.itemLabel}>{t('common_app')}</div>
              <div className={styles.itemValue}>
                Gem Space{` ${platformName} ${presenter.session.appVersion}`}
              </div>
              <div className={styles.itemLabel}>{t('common_location')}</div>
              <div className={styles.itemValue}>
                {presenter.session.city} {presenter.session.country}
              </div>
              <div className={styles.ipLine}>
                <div>
                  <div className={styles.itemLabel}>{t('common_ip')}</div>
                  <div className={classNames(styles.itemValue, styles.ipAddress)}>
                    {presenter.session.ipAddress}
                  </div>
                </div>
                <CopyIpButton
                  onClick={presenter.onCopyIp}
                  className={styles.copyIpButton}
                />
              </div>
            </div>
          </>
        ) : (
          <SessionItemDetailsPlaceholder />
        )}

        <button
          onClick={presenter.terminateSession}
          className={styles.terminateButton}
        >
          <IconView
            Icon={IcStopPoll}
            className={styles.terminateButtonIcon}
          />
          {t('common_terminate_session')}
        </button>
      </Popup>
    );
  });
