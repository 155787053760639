import Tasks from 'APP/Tasks';
import { openPopup } from 'APP/Tasks/app/popup/popup';
import { showAlert } from 'APP/Tasks/app/showAlert/showAlert';
import Entities from 'APP/store';
import { CreateStreamPopup } from 'MAIN/PopupManager/Popups/CreateStreamPopup/CreateStreamPopup';
import { t } from 'MODULE/i18n/service';

export async function openCreateStreamPopup(groupId: string): Promise<boolean> {
  if (Entities.Calls.hasCalls || Entities.ActiveStream.hasStream) {
    showAlert(t('calls_join_another_call'));
    return false;
  }

  const hasAudioPermission = await Tasks.permissions.hasMicrophonePermission();
  if (!hasAudioPermission) {
    return false;
  }

  openPopup(CreateStreamPopup, { params: { groupId } });

  return true;
}
