import classNames from 'classnames';
import React, { FC, useRef } from 'react';

import CloseIcon from 'ICONS/ic-close.svg';
import PlayListIcon from 'ICONS/ic-play-list.svg';
import { ClickOutside } from 'UIKIT/ClickOutside/ClickOutside';
import { FAB } from 'UIKIT/FAB/FAB';
import noop from 'UTILS/noop';

import { MediaPlayerProgress } from '../../components/MediaPlayerProgress/MediaPlayerProgress';
import { IAudioPlayerConfig, IAudioPlayerTrack } from '../AudioPlayer.types';
import { AudioPlayerControls } from './AudioPlayerControls/AudioPlayerControls';
import { AudioPlayerPlayList } from './AudioPlayerPlayList/AudioPlayerPlayList';
import { AudioPlayerTitle } from './AudioPlayerTitle/AudioPlayerTitle';
import styles from './AudioPlayerUI.styles.m.css';

export interface IAudioPlayerUIProps {
  className?: string;
  isPlaying: boolean;
  isShowPlayList?: boolean;
  isPlayListOpened?: boolean;
  isShowOrder?: boolean;
  isShowRepeat?: boolean;
  hasPrevious?: boolean;
  hasNext?: boolean;
  config: IAudioPlayerConfig;
  track: IAudioPlayerTrack | null;
  playList: IAudioPlayerTrack[];
  progress: number;
  progressMax: number;
  onPlay(): void;
  onPause(): void;
  onTogglePlay(): void;
  onTogglePlayList?(): void;
  onClosePlayList?(): void;
  onChangeTrack(track: IAudioPlayerTrack): void;
  onChangeProgress(progress: number): void;
  onTitleClick(): void;
  onChangeConfig(config: Partial<IAudioPlayerConfig>): void;
  onChangeSpeed(speed: number): void;
  onPrevious?(): void;
  onNext?(): void;
  onClose?(): void;
}

export const AudioPlayerUI: FC<IAudioPlayerUIProps> = (props) => {
  const {
    className,
    children,
    isPlaying,
    isShowPlayList = false,
    isPlayListOpened = false,
    isShowOrder = false,
    isShowRepeat = false,
    hasPrevious = false,
    hasNext = false,
    config,
    track,
    playList,
    progress,
    progressMax,
    onPlay,
    onPause,
    onTogglePlay,
    onTogglePlayList = noop,
    onClosePlayList = noop,
    onChangeTrack,
    onChangeProgress,
    onTitleClick,
    onChangeConfig,
    onChangeSpeed,
    onPrevious = noop,
    onNext = noop,
    onClose,
  } = props;

  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <ClickOutside
      reference={containerRef.current}
      onClickOutside={isShowPlayList ? onClosePlayList : undefined}
    >
      <div
        className={classNames(styles.container, className)}
        ref={containerRef}
      >
        {isShowPlayList && (
          <AudioPlayerPlayList
            isPlaying={isPlaying}
            activeTrack={track}
            items={playList}
            progress={progress}
            isOpened={isPlayListOpened}
            onTogglePlay={onTogglePlay}
            onChangeTrack={onChangeTrack}
          />
        )}

        <div className={classNames(styles.content, { [styles.listOpened]: isPlayListOpened })}>
          <div className={styles.panel}>
            {isShowPlayList && (
              <FAB
                Icon={PlayListIcon}
                size="10"
                iconSize="10"
                theme="15"
                onClick={onTogglePlayList}
              />
            )}

            <AudioPlayerTitle
              className={styles.title}
              title={track?.title || ''}
              description={track?.description || ''}
              onClick={onTitleClick}
            />

            {onClose && (
              <FAB
                Icon={CloseIcon}
                size="10"
                iconSize="10"
                theme="15"
                onClick={onClose}
              />
            )}
          </div>

          <div className={styles.controls}>
            <MediaPlayerProgress
              className={styles.progress}
              isPlaying={isPlaying}
              progress={progress}
              progressMax={progressMax}
              isShowBuffer={false}
              isShowTooltip={true}
              onPlay={onPlay}
              onPause={onPause}
              onProgress={onChangeProgress}
            />

            <AudioPlayerControls
              isPlaying={isPlaying}
              config={config}
              isShowOrder={isShowOrder}
              isShowRepeat={isShowRepeat}
              hasPrevious={hasPrevious}
              hasNext={hasNext}
              onTogglePlay={onTogglePlay}
              onPrevious={onPrevious}
              onNext={onNext}
              onConfigUpdate={onChangeConfig}
              onChangeSpeed={onChangeSpeed}
            />
          </div>

          {children}
        </div>
      </div>
    </ClickOutside>
  );
};
