import api from 'APP/packages/api';
import Entities from 'APP/store';

export default async (data) => {
  try {
    let drafts = [];

    const group = Entities.GroupsStore.getGroupById(data.groupId);

    if (!group) {
      return;
    }

    const response = await api.messaging.getDrafts(data);

    group.draftsStore.setDrafts(response.drafts);

    return { drafts: drafts, nextTs: response.nextTs };
  } catch (error) {
    console.error(error);
    return [];
  }
};
