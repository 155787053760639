import { useAsObservableSource, useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { ALERT_TYPES } from 'APP/constants/app';
import { SpaceType } from 'APP/model/space/spaceModel.types';
import { RouterPage } from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';

import { CreateSpaceStep, ISpaceFormData } from '../../components/SpaceForm/SpaceForm.presenter';

interface ICreateSpacePopupPresenter {
  initialValues: ISpaceFormData;
  step: CreateSpaceStep;
  isShowPrevStep: boolean;
  onStepChange(step: CreateSpaceStep): void;
  onShowPrevStep(): void;
  onSubmit(spaceData: ISpaceFormData): Promise<void>;
}

export function useCreateSpacePopupPresenter({
  popupInstance,
}: ICommonPopupsProps): ICreateSpacePopupPresenter {
  const navigateTo = useNavigateTo();
  const source = useAsObservableSource({ navigateTo });

  const presenter = useLocalStore<ICreateSpacePopupPresenter>(() => ({
    initialValues: {
      name: '',
      type: SpaceType.Public,
      categoryId: '',
    },
    step: CreateSpaceStep.GeneralInfo,

    get isShowPrevStep(): boolean {
      return presenter.step === CreateSpaceStep.AdditionalInfo;
    },

    onShowPrevStep(): void {
      presenter.step = CreateSpaceStep.GeneralInfo;
    },

    onStepChange(step: CreateSpaceStep): void {
      presenter.step = step;
    },

    async onSubmit(spaceData: ISpaceFormData): Promise<void> {
      const space = await Tasks.space.createSpace({
        ...spaceData,
        deepLinkAlias: spaceData.type === SpaceType.Public ? spaceData.deepLinkAlias : undefined,
      });

      if (space) {
        source.navigateTo({
          to: `/${RouterPage.Spaces}/${space.id}`,
        });
      } else {
        Tasks.app.addAlert({
          type: ALERT_TYPES.SOMETHING_WRONG_ERROR,
        });
      }
      popupInstance.close();
    },
  }));

  return presenter;
}
