import uploader from 'APP/packages/file-upload/uploader';

export default async (videoTrack) => {
  if (videoTrack) {
    const imageData = videoTrack.getCurrentFrameData();
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = imageData.width;
    canvas.height = imageData.height;
    ctx.putImageData(imageData, 0, 0);
    const preview = canvas.toDataURL('image/jpeg', 0.8);
    return uploader.dataURItoBlob(preview, { type: 'image/jpeg' });
  }

  return null;
};
