import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { showToast } from 'APP/Tasks/app/showToast/showToast';
import { ISpaceMarket } from 'APP/model/space/spaceModel.types';
import { useTranslation } from 'APP/packages/translations';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import Entities from 'STORE';
import { Space } from 'STORE/Spaces/Space';

import { ISpaceMarketFormData } from '../../components/SpaceMarketForm/SpaceMarketForm.types';

export interface IEditSpaceMarketPopupPresenterParams {
  spaceId: string;
  marketId: string;
}

interface IEditSpaceMarketPopupPresenter {
  initialValues: ISpaceMarketFormData;
  space: Space | null;
  market: ISpaceMarket | null;
  onClose(): void;
  onSubmit(marketData: ISpaceMarketFormData): Promise<void>;
}

export function useEditSpaceMarketPopupPresenter({
  params,
  popupInstance,
}: ICommonPopupsProps<IEditSpaceMarketPopupPresenterParams>): IEditSpaceMarketPopupPresenter {
  const { t } = useTranslation();
  const { spaceId, marketId } = params!;

  const presenter = useLocalStore<IEditSpaceMarketPopupPresenter>(() => ({
    get initialValues(): ISpaceMarketFormData {
      return {
        name: presenter.market?.name || '',
        url: presenter.market?.url || '',
        description: presenter.market?.description || '',
        avatarUrl: presenter.market?.avatarUrl || '',
      };
    },

    get space(): Space | null {
      return spaceId ? Entities.spacesStore.getById(spaceId) : null;
    },

    get market(): ISpaceMarket | null {
      return marketId && presenter.space ? presenter.space.markets.getMarket(marketId) : null;
    },

    async onSubmit(marketData: ISpaceMarketFormData): Promise<void> {
      try {
        await Tasks.space.updateSpaceMarket({
          ...marketData,
          id: marketId,
          spaceId,
        });

        presenter.onClose();
      } catch {
        showToast(t('something_went_wrong'));
      }
    },

    onClose(): void {
      popupInstance.close();
    },
  }));

  useEffect(() => {
    if (!Entities.spacesStore.isLoading && (!presenter.space?.isOwner || !presenter.market)) {
      presenter.onClose();
    }
  }, [presenter.space, Entities.spacesStore.isLoading]);

  return presenter;
}
