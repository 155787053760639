/* global Notification  */
import { observer } from 'mobx-react';

import { pushNotifications } from 'APP/packages/permissions/permissions';

import usePresenter from './presenter';

const PushNotification = () => {
  const presenter = usePresenter();
  presenter.notifications.forEach((item) => {
    if (pushNotifications.getCurrentPermission() === 'granted') {
      const notification = new Notification(item.title, {
        body: item.body,
        icon: item.icon,
      });
      notification.onclick = item.onClick;
      presenter.onShowNotification(item.id);
    }
  });
  return null;
};

export default observer(PushNotification);
