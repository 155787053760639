import api from 'APP/packages/api';
import Entities from 'APP/store';

const LIMIT = 50;

async function request(data) {
  try {
    const response = await api.search.searchUsersAddToGroup(data);

    return {
      hasMore: response.hasMore,
      users: response.users,
    };
  } catch (error) {
    console.error('searchUsersAddToGroup', error);
  }

  return {
    hasMore: false,
    users: [],
  };
}

export const searchUsersAddToGroup = async ({ searchValue, offset, group }) => {
  let groupProps = { groupType: 'PRIVATE_GROUP' };
  if (group) {
    if (group.isChannel) {
      groupProps.groupType = 'PUBLIC';
    }
    groupProps.groupId = group.id;
  }

  const { hasMore, users } = await request({
    query: searchValue,
    limit: LIMIT,
    offset,
    ...groupProps,
  });

  Entities.UsersStore.add(users);

  return {
    usersIds: users.map((user) => user.id),
    hasMore,
  };
};
