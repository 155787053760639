import { ClientRole } from 'APP/constants/calls';
import Entities from 'APP/store';

export const userLeft = (uid: number): void => {
  const call = Entities.Calls.ongoingCall;
  if (!call) {
    return;
  }

  const screenSharing = call.opponents.screenSharingOpponent;

  if (uid === screenSharing?.uid) {
    call.opponents.deleteOpponent(uid);
  } else {
    call.opponents.setOpponentRole(uid, ClientRole.Audience);
  }
};
