import { AudioSourceType } from 'APP/constants/app';
import Entities from 'STORE';
import noop from 'UTILS/noop';

import { removeSource } from './removeSource';

type TRemoveAudioSource = () => void;

export function setCurrentSource(
  type: AudioSourceType,
  callback: () => void = noop
): TRemoveAudioSource {
  const app = Entities.App;

  app.currentAudioSources.forEach((source) => source.callback && source.callback());

  app.setCurrentAudioSources([...app.currentAudioSources, { type, callback }]);

  return () => removeSource(type, callback);
}
