import { observable } from 'mobx';

import { CallType } from 'APP/model/call/callModel.types';
import { PayloadType } from 'APP/model/message/messageModel.types';
import { Payload } from 'STORE/Messages/Message/Payload';

class Call extends Payload {
  startInitiatorId;
  startTs;
  @observable duration;
  @observable reason;
  @observable finishReason;
  callType;
  startInitiatorNickName;
  @observable finishInitiatorNickname;
  @observable finishInitiatorId;
  @observable finishTs;
  payloadType;

  constructor(data) {
    super(data);
    this.startInitiatorId = data.initiatorId || data.initiator;
    this.callerId = data.callerId;
    this.startTs = data.startTs;
    this.duration = Number(data.duration);
    this.reason = data.reason;
    this.finishReason = data.finishReason;
    this.callType = data.callType;
    this.startInitiatorNickName = data.initiatorNickName;
    this.finishInitiatorNickname = data.finishInitiatorNickname;
    this.finishInitiatorId = data.finishInitiator && data.finishInitiator.toString();
    this.finishTs = data.finishTs;
    this.payloadType = data.payloadType;
  }

  get isSystem() {
    return true;
  }

  get isCall() {
    return true;
  }

  get isFinishCall() {
    if (this.payloadType === PayloadType.Call) {
      return true;
    }
    return Boolean(this.finishTs);
  }

  get initiatorNickName() {
    if (this.payloadType === PayloadType.Call) {
      return this.startInitiatorNickName;
    }
    if (this.isFinishCall) {
      return this.finishInitiatorNickname;
    }
    return this.startInitiatorNickName;
  }

  get initiatorId() {
    if (this.payloadType === PayloadType.Call) {
      return this.startInitiatorId;
    }
    if (this.isFinishCall) {
      return this.finishInitiatorId;
    }
    return this.startInitiatorId;
  }

  get isP2P() {
    return this.callType === CallType.P2P;
  }

  toJSON() {
    return {
      startInitiatorId: this.startInitiatorId,
      callerId: this.callerId,
      startTs: this.startTs,
      duration: this.duration,
      reason: this.reason,
      finishReason: this.finishReason,
      callType: this.callType,
      startInitiatorNickName: this.startInitiatorNickName,
      finishInitiatorNickname: this.finishInitiatorNickname,
      finishInitiatorId: this.finishInitiatorId,
      finishTs: this.finishTs,
      payloadType: this.payloadType,
    };
  }
}

export default Call;
