import { observer } from 'mobx-react';
import React from 'react';

import { MediaPreview } from 'APP/components/MediaPreview/MediaPreview';
import TextRenderer from 'APP/components/TextRendererCollapsed';
import { MEDIA_PREVIEW_MAX_SIZE } from 'APP/constants/newsfeed';

import { PostReactions } from '../components/PostReactions/PostReactions';
import Translate from '../components/Translate';
import { usePostImagePresenter } from './PostImage.presenter';
import styles from './PostImage.styles.m.css';

export const PostImage = observer(({ message, isPreview, isView }) => {
  const presenter = usePostImagePresenter({ message });

  if (!presenter.isShow) {
    return null;
  }

  return (
    <>
      <MediaPreview
        className={styles.preview}
        payload={presenter.payload}
        maxWidth={MEDIA_PREVIEW_MAX_SIZE.width}
        maxHeight={MEDIA_PREVIEW_MAX_SIZE.height}
        isPreview={isPreview}
        isView={isView}
        onClick={presenter.onOpenMediaGallery}
        payloadId={message.id}
        groupId={message.groupId}
      />

      <div className={styles.content}>
        {presenter.isShowComment && (
          <>
            <TextRenderer
              className={styles.textWrapper}
              textClassName={styles.text}
              keyWords={presenter.keyWords}
              userMentions={presenter.userMentions}
              groupId={message.groupId}
              rows={4}
            >
              {presenter.comment}
            </TextRenderer>
            <Translate message={message} />
          </>
        )}

        <PostReactions
          message={message}
          isVisible={isView}
        />
      </div>
    </>
  );
});
