import logger from 'js-logger';

import { AuthProvider } from 'APP/model/auth/authModel.types';
import { Me } from 'APP/store/Users/User/Me/Me';
import Entities from 'STORE';

export const updateMePhone = (phone: string): void => {
  const me: Me = Entities.UsersStore.Me;

  if (!me) {
    logger.get('TASK').error('users.updateMePhone', 'Me is not defined');
    return;
  }

  me.updatePhone(phone);

  me.updateAccountProvider({
    authProviderId: AuthProvider.Phone,
    displayName: me.phone || phone,
    userId: me.id,
  });
};
