import { getUsersReactionsForMessage as getUsersReactionsForMessageModel } from 'APP/model/reactions/reactionsModel';
import {
  IGetUsersReactionsForMessage,
  IUsersReactionsForMessage,
} from 'APP/model/reactions/reactionsModel.types';

export const getUsersReactionsForMessage = async (
  data: IGetUsersReactionsForMessage
): Promise<IUsersReactionsForMessage> => {
  try {
    const reactions = await getUsersReactionsForMessageModel(data);
    return reactions;
  } catch {
    return {};
  }
};
