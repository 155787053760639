import { MouseEvent, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { buildVoicePath } from './buildVoicePath';

interface IVoiceHistogramParams {
  histogram: string;
  height: number;
  isMirrored: boolean;
  onChangeProgress(progress: number): void;
}

interface IVoiceHistogramPresenter {
  containerRef: RefObject<HTMLDivElement>;
  path: string;
  width: number;
  gradientId: string;
  onSVGClick(event: MouseEvent): void;
}

export function useVoiceHistogramPresenter({
  histogram,
  height,
  isMirrored,
  onChangeProgress,
}: IVoiceHistogramParams): IVoiceHistogramPresenter {
  const containerRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const [path, setPath] = useState('');
  const gradientIdRef = useRef<string>(uuidv4());

  const onSVGClick = useCallback(
    (event) => {
      const { left, width } = event.currentTarget.getBoundingClientRect();
      onChangeProgress((event.clientX - left) / width);
    },
    [onChangeProgress]
  );

  useEffect(() => {
    if (containerRef.current) {
      const { width } = containerRef.current.getBoundingClientRect();
      setWidth(width);
      setPath(buildVoicePath(histogram, Math.floor(width), height, isMirrored));
    }
  }, [histogram, height]);

  return {
    containerRef,
    path,
    width,
    gradientId: gradientIdRef.current,
    onSVGClick,
  };
}
