import { spaceModel } from 'APP/model/model';
import { ISpaceCollectionRequest } from 'APP/model/space/spaceModel.types';
import { spaceAnalytics } from 'APP/packages/analytics/analytics';
import Entities from 'STORE';

export async function deleteSpaceCollection(data: ISpaceCollectionRequest): Promise<boolean> {
  try {
    const response = await spaceModel.deleteCollection(data);

    if (response) {
      const space = Entities.spacesStore.getById(data.spaceId);
      space?.collections.deleteCollection(data.collectionId);

      spaceAnalytics.trackSpaceCollectionDeleted(data.spaceId);
    }

    return response;
  } catch {
    return false;
  }
}
