import { PayloadType } from 'APP/model/message/messageModel.types';
import { replaceHtmlTagsToSpecialSymbols } from 'UTILS/html/replaceHtmlTagsToSpecialSymbols';

export default (payload) => {
  if (payload.payloadType !== PayloadType.RichText) {
    return payload;
  }

  return {
    ...payload,
    text: replaceHtmlTagsToSpecialSymbols(payload.text),
  };
};
