import { t } from 'MODULE/i18n/service';
import Entities from 'STORE';

export function checkSlowMode(): { result: boolean; error?: string } {
  const remainingTime = Entities.clientConfig.slowMode.endSlowModeTs - Date.now();
  if (remainingTime > 0) {
    const remainingMin = Math.floor(remainingTime / 1000 / 60);
    const remainingSec = Math.floor((remainingTime - remainingMin * 60 * 1000) / 1000);

    return {
      result: false,
      error: t('common_user_slowmode_warning')
        .replace('{0}', remainingMin.toString())
        .replace('{1}', remainingSec.toString()),
    };
  }
  return { result: true, error: undefined };
}
