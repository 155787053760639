import { login } from 'APP/Tasks/authorization/login/login';
import { logout } from 'APP/Tasks/authorization/logout/logout';
import { ErrorResponse } from 'APP/packages/api/types/model/errorResponse';
import { getUserLanguage } from 'UTILS/getUserLanguage';
import { wait } from 'UTILS/wait';

import { configApi } from '../config/config';
import { ServerErrorCode } from '../constants/constants';
import { ApiError, isApiErrorResponse } from '../error/ApiError';
import { IRequestData } from './request.types';

const AWAIT_RATE_LIMIT_RETRY = 1000;
const AWAIT_FIRST_RETRY = 3000;
const AWAIT_SECOND_RETRY = 10000;

enum HttpStatusCode {
  TOO_MANY_REQUESTS = 429,
  INTERNAL_SERVER_ERROR = 500,
  BAD_GATEWAY = 502,
  SERVICE_UNAVAILABLE = 503,
}

const REQUEST_WITH_RETRY = ['/sendNewMessage'];

const RETRY_ERROR_CODES = [
  HttpStatusCode.INTERNAL_SERVER_ERROR,
  HttpStatusCode.BAD_GATEWAY,
  HttpStatusCode.SERVICE_UNAVAILABLE,
];

let controller = new AbortController();

let loginPromise = Promise.resolve();

export function abortAllRequests(): void {
  controller.abort();
  controller = new AbortController();
}

export async function request<TResponse = any, TData = any>(
  requestData: IRequestData<TData>,
  attempt = 1
): Promise<TResponse> {
  const {
    rootApi,
    version = configApi.version,
    url,
    method,
    body = null,
    headers = {},
    isStatic,
  } = requestData;

  const baseUrl = isStatic ? '' : `${configApi.apiUrl}/${rootApi}/${version}`;

  const requestBody = body;
  const requestHeaders = {
    ...headers,
    session: configApi.userSession || '',
    locale: getUserLanguage(),
  };

  if (attempt > 1 && REQUEST_WITH_RETRY.includes(url) && requestBody) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    requestBody['retry'] = true;
  }

  const requestInfo = new Request(`${baseUrl}${url}`, {
    method,
    body: requestBody ? JSON.stringify(requestBody) : null,
    headers: requestHeaders,
    signal: controller.signal,
  });

  try {
    const response = await fetch(requestInfo);
    const responseData = await response.json();

    return handleResponse(response, responseData, requestData);
  } catch (error) {
    if (error instanceof ApiError) {
      if (error.message === ServerErrorCode.SessionExpired) {
        return new Promise((resolve, reject) => {
          handleSessionExpired(error, configApi.userSession, resolve, reject);
        });
      }

      if (error.name === 'AbortError') {
        throw error;
      }

      if (error.code === HttpStatusCode.TOO_MANY_REQUESTS) {
        let retryTime = AWAIT_RATE_LIMIT_RETRY;

        if (error.responseHeaders) {
          retryTime = (Number(error.responseHeaders.get('X-RateLimit-Reset')) || 1) * 1000;
        }

        await wait(retryTime);
        return request(requestData);
      }

      if (RETRY_ERROR_CODES.includes(error.code) && attempt === 1) {
        await wait(AWAIT_FIRST_RETRY);
        return request(requestData, attempt + 1);
      }

      throw error;
    } else {
      if (error.name === 'AbortError') {
        throw error;
      }
      // if another network errors
      if (attempt === 1) {
        await wait(AWAIT_FIRST_RETRY);
        return request(requestData, attempt + 1);
      }
      if (attempt === 2) {
        await wait(AWAIT_SECOND_RETRY);
        return request(requestData, attempt + 1);
      }
    }
    throw error;
  }
}

async function handleSessionExpired<TResponse, TData>(
  error: ApiError<TData>,
  prevUserSession: string | null,
  resolve: (request: Promise<TResponse>) => void,
  reject: (error: Error) => void
): Promise<void> {
  await loginPromise;

  // if there were no active logins, and we are not logged in, we initiate a session update
  if (prevUserSession === configApi.userSession && configApi.userSession !== null) {
    loginPromise = new Promise(async (resolveLogin) => {
      const { isSuccess, error } = await login(null, false);
      if (!isSuccess && error?.message === ServerErrorCode.Unauthorized) {
        await logout();
      }
      resolveLogin();
    });
  }

  await loginPromise;

  if (configApi.userSession === null) {
    return reject(error);
  }

  resolve(request(error.requestData));
}

function handleResponse<TResponse, TData>(
  response: Response,
  responseData: TResponse,
  requestData: IRequestData<TData>
): TResponse {
  if (response.status === 404) {
    throw buildApiError('Resource not found', response.status, requestData);
  }

  if (isApiErrorResponse(responseData)) {
    throw buildApiError(
      responseData.error.message,
      responseData.error.code,
      requestData,
      responseData,
      response.headers
    );
  }

  if (!response.ok) {
    throw buildApiError('API request failed', response.status, requestData);
  }

  return responseData;
}

function buildApiError<TData>(
  message: string,
  statusCode: number,
  requestData: IRequestData<TData>,
  responseData: ErrorResponse | null = null,
  responseHeaders?: Headers,
  originalError?: Error
): ApiError<TData> {
  return new ApiError(
    message,
    statusCode,
    requestData,
    responseData,
    responseHeaders,
    originalError
  );
}
