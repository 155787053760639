import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, ReactElement } from 'react';

import { TOOLTIP_DELAY } from 'APP/constants/animations';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';
import { Tooltip } from 'UIKIT/Tooltip/Tooltip';

import { EmptyReactionItem } from './ReactionItem/EmptyReactionItem';
import { ReactionItem } from './ReactionItem/ReactionItem';
import { ReactionUsersPopover } from './ReactionUsersPopover/ReactionUsersPopover';
import { useReactionsPresenter } from './Reactions.presenter';
import styles from './Reactions.styles.m.css';
import { TReactionTheme } from './Reactions.types';

interface IReactionsProps {
  message: ChatMessage;
  className?: string;
  theme?: TReactionTheme;
  isVisible?: boolean;
}

export const Reactions: FC<IReactionsProps> = observer((props) => {
  const { message, className, theme = '1', isVisible = true } = props;
  const presenter = useReactionsPresenter({ message, isVisible });

  if (!presenter.isShow) {
    return null;
  }

  return (
    <div className={classNames(styles.container, styles[`theme-${theme}`], className)}>
      <div className={styles.content}>
        {presenter.reactions.map((reaction) => (
          <Tooltip
            key={reaction.id}
            hideOnClick={true}
            render={(tooltip): ReactElement => (
              <ReactionUsersPopover
                message={message}
                reactionId={reaction.id}
                tooltip={tooltip}
                onOpenReactionPopup={presenter.onOpenReactionPopup}
              />
            )}
            arrow={true}
            delay={TOOLTIP_DELAY}
            disabled={!presenter.canBeShowPopover}
          >
            <div
              className={styles.reactionContainer}
              aria-expanded={false}
            >
              {presenter.isShowAnimations ? (
                <ReactionItem
                  reaction={reaction}
                  className={styles.reaction}
                  theme={theme}
                  isAnimated={presenter.animatedReactionId === reaction.id}
                  isVisible={isVisible}
                />
              ) : (
                <EmptyReactionItem
                  reaction={reaction}
                  className={styles.reaction}
                  theme={theme}
                />
              )}
            </div>
          </Tooltip>
        ))}
      </div>
    </div>
  );
});
