import api from 'APP/packages/api';
import logger from 'APP/packages/logger';
import Entities from 'STORE';

const getSessionsInfo = async () => {
  try {
    const response = await api.userSettings.getDevicesInfo();

    Entities.sessionsStore.setSessions(response.devices);
    Entities.sessionsStore.setCurrentSession(response.currentDevice);
    Entities.sessionsStore.setSessionTtl(response.sessionsTtl);
  } catch (error) {
    logger.get('API').error('userSettings.getSessionsInfo', error);
  }
};

export default getSessionsInfo;
