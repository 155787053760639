import isValidStartPosition from '../../utils/isValidStartPosition';

export default (userMentions, text, index, symbolMatches) => {
  if (!isValidStartPosition(text, index)) {
    return;
  }

  for (let i = 0; i < userMentions.length; i++) {
    const userMention = userMentions[i];

    const mentionStartIndex = index + 1;
    const mentionEndIndex = mentionStartIndex + userMention.nickname.length;

    const mention = text.substring(mentionStartIndex, mentionEndIndex);

    if (mention.toLowerCase() === userMention.nickname.toLowerCase()) {
      symbolMatches['@'].push({
        start: index,
        end: mentionEndIndex,
        userMention,
      });
      break;
    }
  }
};
