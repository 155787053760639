import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { InAppView } from 'APP/main/InAppView/InAppView';

import { useSpacePrimaryMarketPresenter } from './SpacePrimaryMarket.presenter';

interface ISpacePrimaryMarketProps {
  spaceId?: string;
}

export const SpacePrimaryMarket: FC<ISpacePrimaryMarketProps> = observer(({ spaceId }) => {
  const history = useHistory();
  const presenter = useSpacePrimaryMarketPresenter(spaceId);

  if (!presenter.primaryMarketUrl) {
    return null;
  }

  return (
    <InAppView
      isLocal={true}
      url={presenter.primaryMarketUrl}
      goBack={history.goBack}
    />
  );
});
