import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { SpaceAvatar } from 'APP/components/Avatar/SpaceAvatar/SpaceAvatar';
import { VerifiedBadge } from 'APP/components/VerifiedBadge/VerifiedBadge';
import MoreIcon from 'ICONS/dots.svg';
import BackIcon from 'ICONS/ic-back.svg';
import { Space } from 'STORE/Spaces/Space';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';
import { IconButton, IconViewSizes } from 'UIKIT/IconButton';

import { useSpacePanelHeaderPresenter } from './SpacePanelHeader.presenter';
import styles from './SpacePanelHeader.styles.m.css';

interface ISpacePanelHeaderProps {
  space: Space | null;
}

export const SpacePanelHeader: FC<ISpacePanelHeaderProps> = observer(({ space }) => {
  const presenter = useSpacePanelHeaderPresenter(space);

  return (
    <div className={styles.container}>
      <IconButton
        className={styles.button}
        Icon={BackIcon}
        theme="8"
        size={IconViewSizes.Big}
        iconSize={IconViewSizes.Medium}
        onClick={presenter.onClose}
      />

      {space && (
        <>
          <SpaceAvatar
            className={styles.avatar}
            space={space}
            type={AvatarTypes.Square_10}
          />

          <div className={styles.titleWrapper}>
            <h3
              className={styles.title}
              title={space.name}
            >
              {space.name}
            </h3>
            {presenter.verified && <VerifiedBadge />}
          </div>
          {presenter.canOpenProfile && (
            <IconButton
              className={styles.profile}
              Icon={MoreIcon}
              size={IconViewSizes.Big}
              onClick={presenter.onOpenProfile}
            />
          )}
        </>
      )}
    </div>
  );
});
