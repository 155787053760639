import api from 'APP/packages/api';
import Entities from 'APP/store';

export default async ({ categoryIds }) => {
  try {
    const response = await api.catalog.setUserPreferences({
      locales: Entities.userPreferences.localeIds,
      categoryIds,
    });

    return Boolean(response.result);
  } catch (error) {
    console.error(error);
    return false;
  }
};
