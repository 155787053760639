import { getFirstTextPositionOnPayloads } from './getFirstTextPositionOnPayloads';
import getNextPosition from './getNextPosition';
import { getPositionAfterDeleteSomePayload } from './getPositionAfterDeleteSomePayload';
import { getPositionArrowsKeyboard } from './getPositionArrowsKeyboard';

export default {
  getNextPosition,
  getFirstTextPositionOnPayloads,
  getPositionAfterDeleteSomePayload,
  getPositionArrowsKeyboard,
};
