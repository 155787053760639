import { useLocalStore, useAsObservableSource } from 'mobx-react';

import { ISupportedMediaTypes } from 'APP/model/group/groupModel.types';
import { useTranslation } from 'APP/packages/translations';

import {
  IGroupPermissionItem,
  TChangeSupportedMediaTypesHandler,
} from '../GroupPermissionsPopup.types';

export interface ISupportedMediaTypesParams {
  mediaTypes: ISupportedMediaTypes;
  onChange: TChangeSupportedMediaTypesHandler;
}

interface ISupportedMediaTypesPresenter {
  isOpen: boolean;
  items: IGroupPermissionItem[];
  countCheckedItems: number;
  isAllChecked: boolean;
  onToggleMediaType(key: keyof ISupportedMediaTypes): void;
  onToggleAll(): void;
  onToggleOpen(): void;
}

export function useSupportedMediaTypesPresenter(
  data: ISupportedMediaTypesParams
): ISupportedMediaTypesPresenter {
  const { mediaTypes, onChange } = data;
  const { t } = useTranslation();
  const source = useAsObservableSource({ mediaTypes, onChange });

  const presenter = useLocalStore<ISupportedMediaTypesPresenter>(() => ({
    isOpen: false,

    get items(): IGroupPermissionItem[] {
      return [
        {
          id: 'image',
          title: t('moderation_content_type_photos'),
          isChecked: source.mediaTypes.canSendImage,
          onChange: () => presenter.onToggleMediaType('canSendImage'),
        },
        {
          id: 'video',
          title: t('moderation_content_type_videos'),
          isChecked: source.mediaTypes.canSendVideo,
          onChange: () => presenter.onToggleMediaType('canSendVideo'),
        },
        {
          id: 'circleVideo',
          title: t('Video_Message'),
          isChecked: source.mediaTypes.cansSendCircleVideo,
          onChange: () => presenter.onToggleMediaType('cansSendCircleVideo'),
        },
        {
          id: 'sticker',
          title: t('moderation_content_type_stickers'),
          isChecked: source.mediaTypes.canSendSticker,
          onChange: () => presenter.onToggleMediaType('canSendSticker'),
        },
        {
          id: 'audio',
          title: t('moderation_content_type_audios'),
          isChecked: source.mediaTypes.canSendAudio,
          onChange: () => presenter.onToggleMediaType('canSendAudio'),
        },
        {
          id: 'file',
          title: t('moderation_content_type_files'),
          isChecked: source.mediaTypes.canSendFile,
          onChange: () => presenter.onToggleMediaType('canSendFile'),
        },
        {
          id: 'voice',
          title: t('moderation_content_type_voice'),
          isChecked: source.mediaTypes.canSendVoice,
          onChange: () => presenter.onToggleMediaType('canSendVoice'),
        },
        {
          id: 'link',
          title: t('moderation_content_type_links'),
          isChecked: source.mediaTypes.canSendLink,
          onChange: () => presenter.onToggleMediaType('canSendLink'),
        },
      ];
    },

    get countCheckedItems(): number {
      return presenter.items.filter((item) => item.isChecked).length;
    },

    get isAllChecked(): boolean {
      return !presenter.items.some((item) => !item.isChecked);
    },

    onToggleMediaType(key: keyof ISupportedMediaTypes): void {
      source.onChange({
        ...source.mediaTypes,
        [key]: !source.mediaTypes[key],
      });
    },

    onToggleAll(): void {
      const isChecked = !presenter.isAllChecked;

      source.onChange({
        canSendImage: isChecked,
        canSendVideo: isChecked,
        canSendSticker: isChecked,
        canSendAudio: isChecked,
        canSendVoice: isChecked,
        canSendFile: isChecked,
        canSendLink: isChecked,
        cansSendCircleVideo: isChecked,
      });
    },

    onToggleOpen(): void {
      presenter.isOpen = !presenter.isOpen;
    },
  }));

  return presenter;
}
