interface ILocationAccess {
  access: 'denied' | 'granted';
  latitude: number | null;
  longitude: number | null;
}

export async function getCurrentPosition(): Promise<ILocationAccess> {
  const defaultResult: ILocationAccess = {
    access: 'denied',
    latitude: null,
    longitude: null,
  };

  return new Promise((resolve) => {
    if (!navigator.geolocation) {
      return resolve(defaultResult);
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        if (position.coords) {
          resolve({
            access: 'granted',
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          return;
        }

        resolve(defaultResult);
      },
      () => {
        resolve(defaultResult);
      }
    );
  });
}
