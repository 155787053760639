import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import { moderationModel } from 'APP/model/model';
import { IRadioButtonOption } from 'APP/packages/form/RadioButtonGroup/RadioButtonGroup.types';
import { useTranslation } from 'APP/packages/translations';
import Entities from 'STORE';

import { IReportFormData, TSubmitHandler } from './ReportForm.types';

export interface IReportFormPresenter {
  isLoading: boolean;
  initialValues: IReportFormData;
  reasons: IRadioButtonOption[];
  loadReasons(): Promise<void>;
  onSubmit: TSubmitHandler;
}

export const useReportFormPresenter = (onSubmit: TSubmitHandler): IReportFormPresenter => {
  const { t } = useTranslation();

  const presenter = useLocalStore<IReportFormPresenter>(() => ({
    reasons: [],
    isLoading: true,
    initialValues: {
      reasonId: '',
      comment: '',
    },

    async loadReasons(): Promise<void> {
      presenter.isLoading = true;

      try {
        const reasons = await moderationModel.getComplaintReasons();

        presenter.reasons = reasons.map(({ displayName, reasonId }) => ({
          value: reasonId,
          label: displayName,
        }));
      } catch {
        Entities.toast.show(t('something_went_wrong'));
      }

      presenter.isLoading = false;
    },

    onSubmit(formData: IReportFormData): void {
      // The FinalForm put the empty values who has been changed as undefined
      // https://github.com/final-form/final-form/blob/main/docs/faq.md#why-does--final-form-set-my--field-value-to-undefined
      onSubmit({
        ...presenter.initialValues,
        ...formData,
      });
    },
  }));

  useEffect(() => {
    presenter.loadReasons();
  }, []);

  return presenter;
};
