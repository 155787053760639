import Tasks from 'APP/Tasks';
import api from 'APP/packages/api';

async function getBlockedUsersIds(cursor?: string | null) {
  try {
    const response = await api.relationships.getBlockedUsers({ cursor: cursor || undefined });

    if (!response || !response.items || !response.items.length) {
      return {
        blockedUsersIds: [],
        nextCursor: null,
      };
    }

    return {
      blockedUsersIds: response.items.map((item) => item.id),
      nextCursor: response.nextCursor,
    };
  } catch (error) {
    console.error(error);
  }

  return {
    blockedUsersIds: [],
    nextCursor: null,
  };
}

export const loadBlockedUsers = async (cursor?: string | null) => {
  const { blockedUsersIds, nextCursor } = await getBlockedUsersIds(cursor);

  if (blockedUsersIds.length > 0) {
    await Tasks.relationships.loadUnknownUsersByIds({
      usersIds: blockedUsersIds,
    });
  }

  return {
    blockedUsersIds,
    nextCursor,
  };
};
