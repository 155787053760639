import { computed } from 'mobx';

export class Edit {
  constructor(root, parent) {
    this.root = root;
    this.parent = parent;
  }

  @computed
  get message() {
    return this.parent.inputPanel && this.parent.inputPanel.editMessage;
  }

  @computed
  get isEditMode() {
    return this.parent.inputPanel && this.parent.inputPanel.isEditMode;
  }

  @computed
  get isAvailableStartEdit() {
    return this.parent.isEmptyValue && !this.isEditMode;
  }
}
