import React, { FC } from 'react';

import { IPayloadProps } from '../Payloads.types';
import { MiniatureMessageLayout } from '../components/MiniatureMessageLayout/MiniatureMessageLayout';

export const MiniatureMessageSticker: FC<IPayloadProps> = (props) => {
  const { titleClassName, textClassName, message, title, onClick } = props;

  return (
    <MiniatureMessageLayout
      titleClassName={titleClassName}
      textClassName={textClassName}
      imageUrl={message.payload.url}
      graphicSize={message.payload.graphicSize}
      title={title}
      text={message.payload.typeDescription}
      messageId={message.id}
      onClick={onClick}
    />
  );
};
