import { useLocalStore } from 'mobx-react';

import { CallEventTypes } from 'APP/constants/calls';
import dateService from 'APP/packages/date';
import Entities from 'APP/store';

export default () => {
  const interactor = useLocalStore(() => ({
    lastEvent: dateService.now(),
    setLastEvent(timestamp) {
      interactor.lastEvent = timestamp;
    },
    get call() {
      return Entities.Calls.calls[0];
    },
    get avatar() {
      return interactor.call.avatar || {};
    },
    get group() {
      return interactor.call.group;
    },
    get events() {
      return interactor.call.events.list
        .filter((event) => {
          const isActualRaiseHandEvent =
            event.timestamp > interactor.lastEvent && event.type === CallEventTypes.RaiseHand;

          if (!isActualRaiseHandEvent) {
            return false;
          }

          return !!Entities.Calls.ongoingCall.members.getMemberById(event.userId.toString());
        })
        .map((data) => {
          const user = Entities.Calls.ongoingCall.members.getMemberById(data.userId.toString());
          return { ...data, user };
        });
    },
  }));

  return interactor;
};
