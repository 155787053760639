/**
 * Rest API
 * Documentation Rest API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CallCreateRequest { 
    groupId?: string;
    streamType: CallCreateRequestStreamTypeEnum;
    callType: CallCreateRequestCallTypeEnum;
}
export enum CallCreateRequestStreamTypeEnum {
    Audio = 'AUDIO',
    Video = 'VIDEO'
};
export enum CallCreateRequestCallTypeEnum {
    P2P = 'P2P',
    Group = 'GROUP',
    Conference = 'CONFERENCE',
    Stream = 'STREAM'
};



