import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, MouseEvent } from 'react';

import { ICategoryInfo } from 'APP/model/common/commonModel.types';

import { useCategoryButtonPresenter } from './CategoryButton.presenter';
import styles from './CategoryButton.styles.m.css';

interface ICategoryButtonProps {
  readonly className?: string;
  readonly category: ICategoryInfo;
  readonly isDisabled: boolean;
  readonly isSelected: boolean;
  onClick(event?: MouseEvent<HTMLButtonElement>): void;
}

export const CategoryButton: FC<ICategoryButtonProps> = observer(
  ({ className, category, isDisabled, isSelected, onClick }) => {
    const presenter = useCategoryButtonPresenter(onClick);

    const buttonClass = classNames(styles.button, className, {
      [styles.selected]: isSelected,
    });

    const iconClass = classNames(styles.icon, {
      [styles.isDark]: presenter.isDark,
    });

    return (
      <button
        className={buttonClass}
        disabled={isDisabled}
        type="button"
        onClick={presenter.onClick}
      >
        <img
          className={iconClass}
          src={category.iconUrl}
          alt={category.displayName}
        />
        {category.displayName}
      </button>
    );
  }
);
