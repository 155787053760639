import {
  PermissionsValue,
  PermissionsPersonalizedValue,
  PermissionsTypes,
} from 'APP/model/callPermissions/callPermissionsModel.constants';

export const PERMISSIONS_NOTIFICATIONS_TRANSLATIONS = {
  [PermissionsTypes.SwitchOnCamera]: {
    [PermissionsValue.Everyone]: 'call_camera_allowed_to_everyone',
    [PermissionsValue.AfterApprove]: 'call_camera_allowed_to_everyone_after_approval',
    [PermissionsValue.AdminsAndOwner]: 'call_camera_access_locked',
  },
  [PermissionsTypes.SwitchOnMic]: {
    [PermissionsValue.Everyone]: 'call_mic_allowed_to_everyone',
    [PermissionsValue.AfterApprove]: 'call_mic_allowed_to_everyone_after_approval',
    [PermissionsValue.AdminsAndOwner]: 'call_mic_access_locked',
  },
  [PermissionsTypes.ShareScreen]: {
    [PermissionsValue.Everyone]: 'call_screensharing_allowed_to_everyone',
    [PermissionsValue.AfterApprove]: 'call_screensharing_allowed_to_everyone_after_approval',
    [PermissionsValue.AdminsAndOwner]: 'call_screensharing_access_locked',
  },
};

export const PERMISSIONS_GLOBAL_MEMBER_NOTIFICATIONS_TRANSLATIONS = {
  [PermissionsTypes.SwitchOnCamera]: {
    [PermissionsPersonalizedValue.Always]: 'call_camera_allowed_to_everyone',
    [PermissionsPersonalizedValue.AfterApprove]: 'call_camera_allowed_to_everyone_after_approval',
    [PermissionsPersonalizedValue.Never]: 'call_camera_access_locked',
  },
  [PermissionsTypes.SwitchOnMic]: {
    [PermissionsPersonalizedValue.Always]: 'call_mic_allowed_to_everyone',
    [PermissionsPersonalizedValue.AfterApprove]: 'call_mic_allowed_to_everyone_after_approval',
    [PermissionsPersonalizedValue.Never]: 'call_mic_access_locked',
  },
  [PermissionsTypes.ShareScreen]: {
    [PermissionsPersonalizedValue.Always]: 'call_screensharing_allowed_to_everyone',
    [PermissionsPersonalizedValue.AfterApprove]:
      'call_screensharing_allowed_to_everyone_after_approval',
    [PermissionsPersonalizedValue.Never]: 'call_screensharing_access_locked',
  },
};

export const PERMISSIONS_MEMBER_NOTIFICATIONS_TRANSLATIONS = {
  [PermissionsTypes.ShareScreen]: {
    [PermissionsPersonalizedValue.Always]: 'call_screensharing_allowed_to_you',
    [PermissionsPersonalizedValue.AfterApprove]: 'call_screensharing_allowed_to_you_after_approval',
    [PermissionsPersonalizedValue.Never]: 'call_screensharing_locked_for_you',
  },
  [PermissionsTypes.SwitchOnCamera]: {
    [PermissionsPersonalizedValue.Always]: 'call_camera_allowed_to_you',
    [PermissionsPersonalizedValue.AfterApprove]: 'call_camera_allowed_to_you_after_approval',
    [PermissionsPersonalizedValue.Never]: 'call_camera_access_locked_for_you',
  },
  [PermissionsTypes.SwitchOnMic]: {
    [PermissionsPersonalizedValue.Always]: 'call_mic_allowed_to_you',
    [PermissionsPersonalizedValue.AfterApprove]: 'call_mic_allowed_to_you_after_approval',
    [PermissionsPersonalizedValue.Never]: 'call_mic_access_locked_for_you',
  },
};

export const PERMISSIONS_REQUESTS_DECLINED_TRANSLATIONS = {
  [PermissionsTypes.SwitchOnCamera]: 'call_camera_request_declined',
  [PermissionsTypes.SwitchOnMic]: 'call_mic_request_declined',
  [PermissionsTypes.ShareScreen]: 'call_screensharing_request_declined',
};

export const PERMISSIONS_REQUEST_TRANSLATIONS = {
  [PermissionsTypes.SwitchOnCamera]: 'call_allow_camera',
  [PermissionsTypes.SwitchOnMic]: 'call_allow_mic',
  [PermissionsTypes.ShareScreen]: 'call_allow_share_screen',
};
