import React, { FC } from 'react';
import { useFormState } from 'react-final-form';

import { ProfileNameBadge } from 'APP/components/ProfileNameBadge/ProfileNameBadge';
import { mapUserProfileBadgeToIcon } from 'APP/constants/userBadges';
import { IUpdateUserDisplayData } from 'APP/model/userSettings/userSettingsModel.types';
import { useTranslation } from 'APP/packages/translations';
import { Avatar } from 'UIKIT/Avatar/Avatar';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';

import styles from './EditProfilePreview.styles.m.css';

interface IEditProfilePreviewProps {
  isVerified?: boolean;
}

export const EditProfilePreview: FC<IEditProfilePreviewProps> = (props) => {
  const { isVerified } = props;
  const { t } = useTranslation();
  const { values } = useFormState<IUpdateUserDisplayData>();

  const badgeObj = values.usernameBadge
    ? mapUserProfileBadgeToIcon[values.usernameBadge]
    : undefined;

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{t('common_profile_preview')}</h3>
      <div className={styles.preview}>
        <Avatar
          className={styles.avatar}
          type={AvatarTypes.Round_10}
          title={'presenter.member.displayName'}
          url={values.avatarUrl}
        />
        <div className={styles.text}>
          <div className={styles.name}>
            <span className={styles.nameText}>{values.userName}</span>
            {(!!badgeObj || isVerified) && (
              <ProfileNameBadge
                key={badgeObj?.source.id}
                inlineBadge
                className={styles.badge}
                source={badgeObj?.source}
                animated={badgeObj?.animated}
                displayUILayer={1}
                verified={isVerified}
              />
            )}
          </div>
          <div className={styles.message}>Hello</div>
        </div>
        <div className={styles.time}>23:56</div>
      </div>
    </div>
  );
};
