import { convertBase64ToPeaks } from 'UTILS/voice/convertBase64ToPeaks';

const BAR_GAP = 2;
const BAR_WIDTH = 2;
const BAR_MIN_HEIGHT = 2;

function drawBar(x: number, height: number, histogramHeight: number, isMirrored: boolean): string {
  const y = isMirrored ? histogramHeight / 2 + height / 2 : histogramHeight;
  return `M${x} ${y} v-${height} h${BAR_WIDTH} v${height} Z`;
}

export function buildVoicePath(
  histogram: string,
  histogramWidth: number,
  histogramHeight: number,
  isMirrored: boolean
): string {
  if (histogramWidth < BAR_WIDTH) {
    return '';
  }

  const peaks = convertBase64ToPeaks(histogram) || [];

  if (!peaks.length) {
    return '';
  }

  const absMax = Math.max(...peaks);

  const step = BAR_WIDTH + BAR_GAP;
  const scale = peaks.length / histogramWidth;
  let path = '';

  for (let i = 0; i < histogramWidth; i += step) {
    const peak = Math.max(
      ...peaks
        .slice(Math.floor(Math.max(0, i - step) * scale), Math.floor(i * scale) + 1)
        .map((x) => Math.abs(x))
    );

    let height = Math.round((peak / absMax) * histogramHeight);
    height = Math.max(height, BAR_MIN_HEIGHT);

    path += drawBar(i, height, histogramHeight, isMirrored);
  }

  return path;
}
