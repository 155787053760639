export enum ComplaintType {
  Group = 'Group',
  Message = 'Message',
  Space = 'Space',
  User = 'User',
}

export interface IComplaintReason {
  displayName: string;
  reasonId: string;
}

export interface IGroupComplaint {
  complaintType: ComplaintType.Group;
  groupId: string;
}

export interface IMessageComplaint {
  complaintType: ComplaintType.Message;
  groupId: string;
  messageId: string;
}

export interface ISpaceComplaint {
  complaintType: ComplaintType.Space;
  spaceId: string;
}

export interface IUserComplaint {
  complaintType: ComplaintType.User;
  userId: string;
  groupId?: string;
  messageId?: string;
}

export type IComplaintData = IGroupComplaint | IMessageComplaint | ISpaceComplaint | IUserComplaint;

export interface ISendComplaint<T = IComplaintData> {
  reasonId: string;
  comment: string;
  complaint: T;
}
