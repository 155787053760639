import { useLocalStore } from 'mobx-react';

import { textConverter } from 'APP/packages/copyPasteConverter';

export default () => {
  const presenter = useLocalStore(() => ({
    onPaste: (event, maxTextLength = null) => {
      event.preventDefault();

      let text = event.clipboardData.getData('text/plain') || '';
      text = textConverter(text);

      if (!maxTextLength) {
        document.execCommand('insertText', false, text);

        return;
      }

      text = text.substring(0, maxTextLength).replace(/[\s]+/g, ' ');

      if (text.length && text.length <= maxTextLength) {
        document.execCommand('insertText', false, text);
      }
    },

    setFocusToTheEnd: (ref) => {
      if (ref.current.hasChildNodes()) {
        const range = document.createRange();
        range.selectNodeContents(ref.current.lastChild);
        range.collapse(false);

        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
      } else {
        ref.current.focus();
      }
    },
  }));

  return presenter;
};
