import React from 'react';

import { MiniatureMessageLayout } from '../components/MiniatureMessageLayout/MiniatureMessageLayout';

const Location = ({ titleClassName, textClassName, message, title, onClick }) => {
  return (
    <MiniatureMessageLayout
      titleClassName={titleClassName}
      textClassName={textClassName}
      imageUrl={`${message.payload.url}&size=80x80`}
      title={title}
      text={message.payload.typeDescription}
      messageId={message.id}
      onClick={onClick}
    />
  );
};

export default Location;
