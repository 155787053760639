import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { MessageViewPageType } from 'MAIN/ChatView/Messages/Messages.types';
import type { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

import { MessageLayout } from '../../MessageLayout/MessageLayout';
import { SenderName } from '../components/SenderName/SenderName';
import StatusBar from '../components/StatusBar';
import styles from './UnsupportedBubble.styles.m.css';

interface IUnsupportedBubbleProps {
  message: ChatMessage;
  messageViewPageType: MessageViewPageType;
  isView: boolean;
}

export const UnsupportedBubble: FC<IUnsupportedBubbleProps> = observer((props) => {
  const { message, children, messageViewPageType, isView } = props;

  return (
    <MessageLayout
      message={message}
      className={styles.bubble}
      messageViewPageType={messageViewPageType}
      isView={isView}
      hasActions={false}
    >
      <SenderName
        message={message}
        className={styles.senderName}
      />
      <div
        className={styles.content}
        data-test="text-message"
      >
        {children}
        <StatusBar
          className={styles.statusBar}
          message={message}
        />
      </div>
    </MessageLayout>
  );
});
