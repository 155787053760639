export const STREAM_STATUS = {
  INITIATED: 'INITIATED',
  STARTED: 'STARTED',
  FINISHED: 'FINISHED',
  PUBLISHED: 'PUBLISHED',
};

export const STREAM_ACTION = {
  INVITE_AS_STREAMER: 'INVITE_AS_STREAMER',
  FORCE_MUTE: 'FORCE_MUTE',
};

export const PARTICIPANT_ROLE = {
  VIEWER: 'viewer',
  STREAMER: 'streamer',
};

export const MAX_COUNT_BROADCASTERS = 50;

export const MAX_COUNT_VIDEOS = 51;
