import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { useTranslation } from 'APP/packages/translations';
import { Button } from 'UIKIT/Button/Button';

import { IBotButtonsPresenterParams, useBotButtonsPresenter } from './BotButtons.presenter';
import styles from './BotButtons.styles.m.css';

type IBotButtonsProps = IBotButtonsPresenterParams;

export const BotButtons: FC<IBotButtonsProps> = observer((props) => {
  const { t } = useTranslation();
  const presenter = useBotButtonsPresenter(props);

  return (
    <>
      {presenter.isAddedToProfile ? (
        <Button
          className={styles.button}
          size="0"
          theme="7"
          onClick={presenter.goToBot}
        >
          {t('common_go_to_bot')}
        </Button>
      ) : (
        <Button
          className={styles.button}
          size="0"
          theme="1"
          onClick={presenter.activateBot}
          isDisabled={presenter.isActivating}
          isLoading={presenter.isActivating}
        >
          {t('common_activate')}
        </Button>
      )}
    </>
  );
});
