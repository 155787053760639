/* eslint-disable jsx-a11y/no-static-element-interactions */
import { observer } from 'mobx-react';
import React from 'react';

import { AddKeyPressHandler } from 'APP/packages/keyBoardHandler';

import EnterLink from './EnterLink';
import Menu from './Menu';
import usePresenter from './presenter';
import styles from './styles.m.scss';

const Content = ({ contentInstance, popoverInstance, onClose }) => {
  const presenter = usePresenter({
    contentInstance,
    popoverInstance,
    onClose,
  });

  return (
    <>
      <AddKeyPressHandler onEsc={presenter.onEsc} />
      <div
        ref={popoverInstance.ref}
        style={popoverInstance.style}
        className={styles.popover}
        data-placement={popoverInstance.placement}
        onClick={presenter.onClickOutsideOfButtons} // restore selection on layout click
      >
        {presenter.isEnterLinkView ? (
          <EnterLink
            onBackClick={presenter.returnToMenu}
            onAddLink={presenter.onAddLink}
          />
        ) : (
          <Menu presenter={presenter} />
        )}
      </div>
    </>
  );
};

export default observer(Content);
