import Tasks from 'APP/Tasks';
import { openPopup } from 'APP/Tasks/app/popup/popup';
import { ISendFiles } from 'APP/Tasks/messaging/sendFiles/sendFiles.types';
import Entities from 'APP/store';
import { MediaMessagePopup } from 'MAIN/PopupManager/Popups/MediaMessagePopup/MediaMessagePopup';

export const sendFiles = ({ groupId, files }: ISendFiles): void => {
  const group = Entities.GroupsStore.getGroupById(groupId);
  const inputPanel = Entities.InputPanelsStore.getById(groupId);
  if (!group || !inputPanel) {
    return;
  }

  openPopup(MediaMessagePopup, {
    params: {
      groupId,
      files: [...files],
      caption: inputPanel.markdownValue || '',
    },
  });

  inputPanel.setValue('');
  Tasks.messaging.resetLinkPreviewProcess({ groupId });
};
