import { spaceModel } from 'APP/model/model';
import { spaceAnalytics } from 'APP/packages/analytics/analytics';
import { SpaceJoinType } from 'APP/packages/analytics/space/spaceAnalytics.types';
import Entities from 'STORE';
import { Space } from 'STORE/Spaces/Space';

function trackJoinEvent(spaceId: string, fromDeepLink: boolean): void {
  spaceAnalytics.trackSpaceJoined(
    spaceId,
    fromDeepLink ? SpaceJoinType.DeepLink : SpaceJoinType.JoinButton
  );
}

export async function joinSpace(spaceId: string, fromDeepLink = false): Promise<Space | null> {
  const space = Entities.spacesStore.getById(spaceId);

  space?.setJoinProcessing(true);

  try {
    const spaceResponse = await spaceModel.joinSpace(spaceId);

    Entities.spacesStore.addSpace(spaceResponse);

    space?.setJoinProcessing(false);

    trackJoinEvent(spaceId, fromDeepLink);

    return Entities.spacesStore.getById(spaceId);
  } catch {
    space?.setJoinProcessing(false);
    return null;
  }
}
