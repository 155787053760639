import { useAsObservableSource, useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import Entities from 'STORE';
import { Group } from 'STORE/Groups/Group';
import { BackgroundStream } from 'STORE/LiveStreaming/BackgroundStreams/BackgroundStream';

interface IChannelItemPresenter {
  hasUnread: boolean;
  numberOfUnread: number;
  hasPinedIcon: boolean;
  backgroundStream: BackgroundStream | null;
  hasActiveStream: boolean;
  onJoinToStream(): void;
}

export const useChannelItemPresenter = (group: Group): IChannelItemPresenter => {
  const source = useAsObservableSource({ group });

  const presenter = useLocalStore<IChannelItemPresenter>(() => ({
    get hasUnread(): boolean {
      return source.group.numberOfUnread > 0 || source.group.unread;
    },

    get numberOfUnread(): number {
      return source.group.numberOfUnread;
    },

    get hasPinedIcon(): boolean {
      return !presenter.hasUnread && !presenter.backgroundStream && source.group.isPinned;
    },

    get backgroundStream(): BackgroundStream | null {
      return Entities.BackgroundStreams.getByGroupId(source.group.id);
    },

    get hasActiveStream(): boolean {
      // Todo remove this ts-ignore when the streams store will be rewritten to ts
      // Fix ts error "Property 'channelId' does not exist on type 'never'."
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return presenter.backgroundStream?.channelId === Entities.ActiveStream.stream?.channelId;
    },

    onJoinToStream(): void {
      Tasks.streaming.setActiveStream(presenter.backgroundStream, source.group.id);
    },
  }));

  return presenter;
};
