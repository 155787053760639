import { cancelScheduledEvent } from './cancelScheduledEvent';
import { getScheduledEventDetails } from './getScheduledEventDetails';
import { getScheduledEventInvitees } from './getScheduledEventInvitees';
import { getScheduledEvents } from './getScheduledEvents';

export default {
  getScheduledEvents,
  getScheduledEventInvitees,
  getScheduledEventDetails,
  cancelScheduledEvent,
};
