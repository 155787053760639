import Tasks from 'APP/Tasks';
import { GroupType, GroupUserRole } from 'APP/model/group/groupModel.types';
import { messageModel } from 'APP/model/model';
import Entities from 'STORE';
import { Group } from 'STORE/Groups/Group';
import { IStrangeServerGroup } from 'STORE/Groups/types';

// TODO: remove this code after scheduled messages refactoring: GEM-23214
export function createScheduledGroup(group: Group): Group | null {
  const scheduleGroup = Entities.GroupsStore.getGroupById(group.scheduleGroupId);
  if (scheduleGroup) {
    return scheduleGroup;
  }

  const data: IStrangeServerGroup = {
    id: group.scheduleGroupId,
    groupId: group.id,
    type: GroupType.Schedule,
    userGroupRole: GroupUserRole.Owner,
  };

  Entities.GroupsStore.merge([data]);

  return Entities.GroupsStore.getGroupById(group.scheduleGroupId);
}

export async function loadScheduledMessages(groupId: string): Promise<void> {
  const group = Entities.GroupsStore.getGroupById(groupId);

  if (
    !group ||
    !group.canBeScheduled ||
    group.isSheduledMessagesLoading ||
    Entities.GroupsStore.getGroupById(group.scheduleGroupId)
  ) {
    return;
  }

  try {
    group.isSheduledMessagesLoading = true;

    const messages = await messageModel.getGroupScheduledMessages(groupId);
    createScheduledGroup(group);

    Tasks.messaging.updateScheduledMessages({
      groupId,
      messages,
    });
  } catch {
    //
  }

  group.isSheduledMessagesLoading = false;
}
