import Tasks from 'APP/Tasks';
import { mapSpacePinOrderResponse } from 'APP/model/space/spaceModel.mapper';
import { PinSpacesResponse } from 'APP/packages/api/types/model/pinSpacesResponse';

import { GroupsEvent } from '../GroupsEvent';

export class SpacesPinChanged extends GroupsEvent<PinSpacesResponse> {
  process(): void {
    if (!this.eventPayload) {
      return;
    }

    Tasks.space.updateSpacesPinOrder(mapSpacePinOrderResponse(this.eventPayload));
  }
}
