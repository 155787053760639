import React from 'react';

import IcVoice16 from 'ICONS/ic-s-voice-message-16.svg';

import { MiniatureMessageLayout } from '../components/MiniatureMessageLayout/MiniatureMessageLayout';

const Voice = ({ titleClassName, textClassName, iconClassName, message, title, onClick }) => {
  return (
    <MiniatureMessageLayout
      titleClassName={titleClassName}
      textClassName={textClassName}
      iconClassName={iconClassName}
      title={title}
      Icon={IcVoice16}
      text={message.payload.typeDescription}
      messageId={message.id}
      onClick={onClick}
    />
  );
};

export default Voice;
