import { GetSpaceCollectionItemsResponse } from 'APP/packages/api/types/model/getSpaceCollectionItemsResponse';
import { PersonalizedSpaceResponse } from 'APP/packages/api/types/model/personalizedSpaceResponse';
import { PinSpacesResponse } from 'APP/packages/api/types/model/pinSpacesResponse';
import { SpaceCollectionResponse } from 'APP/packages/api/types/model/spaceCollectionResponse';
import { SpaceMessageCollectionItemResponse } from 'APP/packages/api/types/model/spaceMessageCollectionItemResponse';
import { SpaceResponse } from 'APP/packages/api/types/model/spaceResponse';
import { SpaceUserRolesResponse } from 'APP/packages/api/types/model/spaceUserRolesResponse';

import { mapPersonalizedGroupResponse } from '../group/groupModel.mapper';
import {
  IPersonalizedSpace,
  IPinSpaceResponse,
  ISpace,
  ISpaceCollection,
  ISpaceCollectionItems,
  ISpaceMessageCollectionItem,
  ISpaceUserRoleResponse,
  SpaceTemplate,
  SpaceType,
  SpaceUserRole,
} from './spaceModel.types';

export const mapSpaceResponse = (space: SpaceResponse): ISpace => {
  return {
    ...space,
    type: space.type as string as SpaceType,
    template: space.template as string as SpaceTemplate,
    primaryMarketUrl: space.primaryMarketUrl || '',
  };
};

export const mapPersonalizedSpaceResponse = (
  personalizedSpace: PersonalizedSpaceResponse
): IPersonalizedSpace => {
  return {
    ...personalizedSpace,
    space: mapSpaceResponse(personalizedSpace.space),
    role: personalizedSpace.role as string as SpaceUserRole,
    pinOrder: parseInt(personalizedSpace.pinOrder, 10),
  };
};

export const mapSpaceMessageCollectionItemResponse = (
  collectionItem: SpaceMessageCollectionItemResponse
): ISpaceMessageCollectionItem => {
  return {
    ...collectionItem,
    group: mapPersonalizedGroupResponse(collectionItem.group),
    message: collectionItem.message,
  };
};

export const mapSpaceCollectionItemsResponse = (
  collectionItems: GetSpaceCollectionItemsResponse
): ISpaceCollectionItems => {
  return {
    ...collectionItems,
    items: collectionItems.items.map((item) => mapSpaceMessageCollectionItemResponse(item)),
  };
};

export const mapSpaceCollectionResponse = (
  collection: SpaceCollectionResponse
): ISpaceCollection => {
  return {
    ...collection,
    updatedTs: parseInt(collection.updatedTs, 10),
  };
};

export const mapSpacePinOrderResponse = (data: PinSpacesResponse): IPinSpaceResponse[] => {
  return data.pinOrders.map((item) => ({
    spaceId: item.spaceId,
    pinOrder: parseInt(item.pinOrder, 10),
  }));
};

export const mapSpaceUserRoleResponse = (data: SpaceUserRolesResponse): ISpaceUserRoleResponse => {
  return {
    cursor: data.cursor || null,
    userRoles: data.userRoles.map((item) => ({
      userId: item.userId,
      role: item.role as string as SpaceUserRole,
    })),
  };
};
