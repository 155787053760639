import React from 'react';

import { useYouTubePlayerPresenter } from './YouTubePlayer.presenter';

export const YouTubePlayer = ({
  id,
  videoId,
  title,
  className,
  isAutoplay = false,
  timecode = null,
}) => {
  const presenter = useYouTubePlayerPresenter({ id, videoId, isAutoplay, timecode });

  return (
    <iframe
      id={id}
      src={presenter.videoUrl}
      className={className}
      title={title}
      width="100%"
      height="100%"
      frameBorder="0"
      allow={presenter.allows}
      allowFullScreen
    />
  );
};
