import { computed } from 'mobx';

import { PayloadType, IOrderedPayloadItem } from 'APP/model/message/messageModel.types';
import { ISharedDataItem } from 'APP/model/sharedData/sharedDataModel.types';
import AlbumPayload from 'STORE/Messages/Message/Payload/Album';
import ArticlePayload from 'STORE/Messages/Message/Payload/Article';
import ImagePayload from 'STORE/Messages/Message/Payload/Image';
import VideoPayload from 'STORE/Messages/Message/Payload/Video';

import { SharedDataItem } from '../SharedDataItem/SharedDataItem';

const MEDIA_TYPES = [PayloadType.Image, PayloadType.Video, PayloadType.Album, PayloadType.Article];

type TSharedMediaItem = ISharedDataItem<ImagePayload | VideoPayload>;

export class SharedMedia extends SharedDataItem {
  @computed
  get items(): TSharedMediaItem[] {
    const items: TSharedMediaItem[] = [];
    const messages = this.messages.getFilteredMessages((message) =>
      MEDIA_TYPES.includes(message.payload.payloadType)
    );

    messages.forEach((message) => {
      if (message.payload instanceof AlbumPayload || message.payload instanceof ArticlePayload) {
        const payloads = message.payload.payloads.slice().reverse() as IOrderedPayloadItem[];

        payloads.forEach(({ id, payload }) => {
          if (payload instanceof ImagePayload || payload instanceof VideoPayload) {
            items.push({
              id,
              messageId: message.id,
              groupId: message.groupId,
              payload,
              serverTime: message.serverTime,
            });
          }
        });
      } else {
        items.push({
          id: message.clientUuid,
          messageId: message.id,
          groupId: message.groupId,
          payload: message.payload,
          serverTime: message.serverTime,
        });
      }
    });

    return items;
  }

  @computed
  get count(): number {
    return this._count || this.items.length;
  }

  getItemById(id: string): TSharedMediaItem | null {
    return this.items.find((item) => item.id === id) || null;
  }
}
