import api from 'APP/packages/api';
import Entities from 'APP/store';

async function blockUser(userId) {
  try {
    const response = await api.relationships.blockUser({
      userId,
    });

    if (!response && !response.blockedByMe) {
      return null;
    }

    return response.blockedByMe;
  } catch (error) {
    console.error(error);
  }

  return null;
}

export default async (userId) => {
  const user = Entities.UsersStore.getUserById(userId.toString());

  if (!user) {
    return;
  }

  user.setLoadingBlockedByMe(true);

  const blockedByMe = await blockUser(user.id);

  if (!blockedByMe) {
    return;
  }

  if (parseInt(blockedByMe.ts, 10) > parseInt(user.blockedByMe.ts, 10)) {
    user.setBlockedByMe(blockedByMe);
  }

  user.setLoadingBlockedByMe(false);
};
