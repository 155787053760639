import Tasks from 'APP/Tasks';
import { ALERT_TYPES } from 'APP/constants/app';
import deviceInfo from 'APP/packages/deviceInfo';
import { media } from 'APP/packages/permissions/permissions';

export default async () => {
  const { hasMicrophone } = await deviceInfo.getMediaDevices();

  if (!hasMicrophone) {
    Tasks.app.addAlert({
      type: ALERT_TYPES.CALL_NO_MICROPHONE_CONNECTED,
    });
    return false;
  }

  const hasAudioPermission = await media.getAudioPermission();

  if (!hasAudioPermission) {
    Tasks.app.addAlert({
      type: ALERT_TYPES.CALL_NO_MICROPHONE_PERMISSIONS,
    });
    return false;
  }

  return true;
};
