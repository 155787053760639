import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { CSSTransition } from 'react-transition-group';

import { useChatHeaderPresenter } from './ChatHeader.presenter';
import styles from './ChatHeader.styles.m.css';
import DefaultHeader from './DefaultHeader';
import { MultiSelectPanel } from './MultiSelectPanel/MultiSelectPanel';

const multiSelectTransitions = {
  enter: styles.multiEnter,
  enterActive: styles.multiEnterActive,
  exit: styles.multiExit,
  exitActive: styles.multiExitActive,
};

const headerTransitions = {
  enter: styles.headerEnter,
  enterActive: styles.headerEnterActive,
  exit: styles.headerExit,
  exitActive: styles.headerExitActive,
};

const ANIMATION_TIMEOUT = 150;

export const ChatHeader: FC = observer(() => {
  const presenter = useChatHeaderPresenter();

  if (!presenter.group) {
    return null;
  }

  return (
    <div className={styles.container}>
      <CSSTransition
        classNames={multiSelectTransitions}
        in={presenter.hasSelectedMessages}
        timeout={ANIMATION_TIMEOUT}
        unmountOnExit
      >
        <MultiSelectPanel key={presenter.group.id} />
      </CSSTransition>
      <CSSTransition
        classNames={headerTransitions}
        in={!presenter.hasSelectedMessages}
        timeout={ANIMATION_TIMEOUT}
      >
        <DefaultHeader
          group={presenter.group}
          key={presenter.group.id}
        />
      </CSSTransition>
    </div>
  );
});
