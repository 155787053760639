import { useLocalStore, useAsObservableSource } from 'mobx-react';

import { PayloadType } from 'APP/model/message/messageModel.types';
import { getAlbumLayout, IImageSize } from 'APP/packages/album';
import date from 'APP/packages/date';

import { IMediaMessagePayload } from '../MediaMessagePopup.types';

export interface IAlbumLayout {
  id: number;
  items: IImageSize[];
}

export interface IMediaMessageAlbumPresenter {
  payloads: IMediaMessagePayload[];
  canBeRemoved: boolean;
  layout: IAlbumLayout[];
  hasPlayIcon(id: number): boolean;
  getDuration(id: number): string;
  previewUrl(id: number): string | undefined;
  onRemove(id: number): void;
}

export const useMediaMessageAlbumPresenter = (
  payloads: IMediaMessagePayload[],
  onRemove?: (payload: IMediaMessagePayload) => void
): IMediaMessageAlbumPresenter => {
  const source = useAsObservableSource({
    payloads,
    onRemove,
  });

  const presenter = useLocalStore<IMediaMessageAlbumPresenter>(() => ({
    isLoading: true,

    get payloads(): IMediaMessagePayload[] {
      return source.payloads;
    },

    get layout(): IAlbumLayout[] {
      const sizes = presenter.payloads.map((payload: IMediaMessagePayload, idx: number) => ({
        w: payload.data.graphicSize?.width,
        h: payload.data.graphicSize?.height,
        id: idx,
      })) as IImageSize[];
      return getAlbumLayout(sizes).map((row, idx) => ({ id: idx, items: row }));
    },

    get canBeRemoved(): boolean {
      return Boolean(source.onRemove);
    },

    hasPlayIcon(id: number): boolean {
      return presenter.payloads[id].type === PayloadType.Video;
    },

    getDuration(id: number): string {
      return date.secondsToString(presenter.payloads[id].data.duration || 0);
    },

    previewUrl(id: number): string | undefined {
      return presenter.payloads[id].data.localPreview;
    },

    onRemove(id: number): void {
      source.onRemove?.(presenter.payloads[id]);
    },
  }));

  return presenter;
};
