import Tasks from 'APP/Tasks';
import { UpdateReason } from 'APP/constants/scroll';
import Entities from 'APP/store';

export async function addMyFakeNewMessages({ groupId, messages = [] }) {
  if (groupId !== Entities.GroupsStore.activeGroupId || messages.length === 0) {
    return;
  }

  const group = Entities.GroupsStore.getGroupById(groupId);
  if (!group) {
    return;
  }
  group.messagesStore.unfocusMessage();

  const prevMessageTs = group.messagesStore.isLoadedLastMessages
    ? group.messagesStore.lastMessage?.expectedServerTime
    : group.lastMessage?.serverTime;
  let expectedServerTime = prevMessageTs ? prevMessageTs : Date.now();

  let serverTime = expectedServerTime;
  messages.forEach((message) => message.setMessageTime(++expectedServerTime, ++serverTime));
  Tasks.messaging.updateOldOrFakeMessages({ messages, groupId, prevMessageTs });
  group.messagesStore.setNewMessagesPlaceholderTs(0);

  Entities.ChatStore.setScrollChanged(UpdateReason.ScrollToBottom, { isAnimated: true });
}
