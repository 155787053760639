/**
 * format duration to [hh, mm, ss]
 * @param duration - milliseconds
 * @returns {array} - [hh, mm, ss]
 */
export default (duration) => {
  const allSeconds = duration ? Math.floor(Number(duration) / 1000) : 0;

  const seconds = parseInt(allSeconds % 60, 10);
  const minutes = parseInt((allSeconds / 60) % 60, 10);
  const hours = parseInt(allSeconds / 3600, 10);

  return [hours, minutes, seconds];
};
