/* global Date */
import isValidDate from './isValidDate';
import { now } from './now';

export default (rawCompareDate) => {
  const compareDate = new Date(rawCompareDate);
  if (!isValidDate(compareDate)) {
    return false;
  }

  compareDate.setDate(compareDate.getDate() + 1);

  return now().toDateString() === compareDate.toDateString();
};
