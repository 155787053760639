import { observer } from 'mobx-react';
import React, { useRef } from 'react';

import { useTranslation } from 'APP/packages/translations';

import usePresenter from './presenter';
import styles from './styles.m.css';

const Title = ({ parentPresenter }) => {
  const ref = useRef();
  const { t } = useTranslation();
  const presenter = usePresenter({
    parentPresenter,
    ref,
  });

  return (
    <div className={styles.container}>
      <div
        role="textbox"
        tabIndex="0"
        className={styles.title}
        ref={ref}
        placeholder={t('channels:channel_article_title')}
        contentEditable
        data-test="article-title-field"
        onChange={presenter.setValue}
        onInput={presenter.setValue}
        onKeyDown={presenter.onEnter}
        onFocus={presenter.onFocus}
        onDrop={presenter.onDrop}
        onPaste={presenter.onPaste}
      />
    </div>
  );
};

export default observer(Title);
