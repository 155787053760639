import { useLocalStore } from 'mobx-react';
import { useEffect, useRef } from 'react';

import { RewardProgressInfoRewardEnum } from 'APP/model/userSettings/userSettingsModel.types';
import { IRewardProgressInfo } from 'APP/types/rewards';
import Entities from 'STORE';
import { Popup } from 'STORE/App/Popups/Popup/Popup';

export interface IRewardsActiveUserPresenter {
  rewardProgress?: IRewardProgressInfo;
  rewardProgressPercent: number;
}

export const useRewardsActiveUserPresenter = (): IRewardsActiveUserPresenter => {
  const editProfileRef = useRef<Popup>();

  const presenter = useLocalStore<IRewardsActiveUserPresenter>(() => ({
    get rewardProgress(): IRewardProgressInfo | undefined {
      return Entities.rewards.rewardsProgress.get(RewardProgressInfoRewardEnum.UsernameBadge1);
    },

    get rewardProgressPercent(): number {
      if (!presenter.rewardProgress) {
        return 0;
      }

      return (presenter.rewardProgress.currentPoints / presenter.rewardProgress.maxPoints) * 100;
    },
  }));

  useEffect(() => {
    return () => editProfileRef.current?.close();
  }, []);

  return presenter;
};
