import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { openPopup } from 'APP/Tasks/app/popup/popup';
import { RewardProgressInfoRewardEnum } from 'APP/model/userSettings/userSettingsModel.types';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import { EditProfilePopup } from 'MAIN/PopupManager/Popups/EditProfilePopup/EditProfilePopup';
import Entities from 'STORE';
import { Popup } from 'STORE/App/Popups/Popup/Popup';

export interface IRewardsPopupPresenter {
  childPopup: Popup | null;
  rewardsKeys: RewardProgressInfoRewardEnum[];
  hasBackButton: boolean;
  getRewardsProgress(): void;
  onClose(): void;
  onBack(): void;
  onOpenEditProfile(): void;
}

export const useRewardsPopupPresenter = ({
  popupInstance,
  onClose,
  onBack,
}: ICommonPopupsProps): IRewardsPopupPresenter => {
  const presenter = useLocalStore<IRewardsPopupPresenter>(() => ({
    childPopup: null,

    get rewardsKeys(): RewardProgressInfoRewardEnum[] {
      return Entities.rewards.rewardsProgressKeys;
    },

    get hasBackButton(): boolean {
      return !!onBack;
    },

    onClose(): void {
      popupInstance.close();
      onClose?.();
    },

    onBack(): void {
      popupInstance.close();
      onBack?.();
    },

    getRewardsProgress(): void {
      Tasks.userSettings.getRewardsProgress();
    },

    onOpenEditProfile(): void {
      popupInstance.hide();

      presenter.childPopup = openPopup(EditProfilePopup, {
        onClose: () => presenter.onClose(),
        onBack: () => popupInstance.show(),
      });
    },
  }));

  useEffect(() => {
    presenter.getRewardsProgress();

    return (): void => presenter.childPopup?.close();
  }, []);

  return presenter;
};
