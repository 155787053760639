import { useLocalStore } from 'mobx-react';

export default ({ containerRef, common }) => {
  const presenter = useLocalStore(() => ({
    focusedIndex: 0,
    isDisabledMouseEvent: false,
    timeOutInstance: null,
    inViewState: {},

    onHover: (index) => {
      if (presenter.isDisabledMouseEvent) {
        return;
      }

      presenter.focusedIndex = index;
    },

    onChangeViewState: (index, inView) => {
      presenter.inViewState[index] = inView;
    },

    // disabled command focus by mouse enter
    // when focus changes by arrow navigation
    disableMouseEvent: () => {
      presenter.isDisabledMouseEvent = true;

      if (presenter.timeOutInstance) {
        clearTimeout(presenter.timeOutInstance);
      }

      presenter.timeOutInstance = setTimeout(() => {
        presenter.isDisabledMouseEvent = false;
      }, 100);
    },

    nextFocus: () => {
      if (common.commands.length - 1 === presenter.focusedIndex) {
        presenter.focusedIndex = 0;
        return;
      }

      presenter.focusedIndex = presenter.focusedIndex + 1;
    },

    prevFocus: () => {
      if (presenter.focusedIndex === 0) {
        presenter.focusedIndex = common.commands.length - 1;
        return;
      }

      presenter.focusedIndex = presenter.focusedIndex - 1;
    },

    onKeyDown: (event) => {
      event.stopPropagation();
      event.preventDefault();

      presenter.nextFocus();
      presenter.disableMouseEvent();

      if (!presenter.inViewState[presenter.focusedIndex] && containerRef.current.hasChildNodes()) {
        const node = containerRef.current.childNodes[presenter.focusedIndex];

        node.scrollIntoView({
          behavior: 'instant',
          block: 'end',
        });
      }
    },

    onKeyUp: (event) => {
      event.stopPropagation();
      event.preventDefault();

      presenter.prevFocus();
      presenter.disableMouseEvent();

      if (!presenter.inViewState[presenter.focusedIndex] && containerRef.current.hasChildNodes()) {
        const node = containerRef.current.childNodes[presenter.focusedIndex];

        node.scrollIntoView({
          behavior: 'instant',
          block: 'start',
        });
      }
    },
  }));

  return presenter;
};
