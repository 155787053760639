import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { IScheduledEventDetails } from 'APP/model/scheduledEvent/scheduledEventModel.types';
import { TextPlaceholder } from 'APP/packages/placeholders/TextPlaceholder/TextPlaceholder';
import IcDelete from 'ICONS/ic-delete.svg';
import { Avatar, AvatarTypes } from 'UIKIT/Avatar/Avatar';
import { IconButton, IconViewSizes } from 'UIKIT/IconButton';

import { ScheduledCallsStartButton } from '../../components/ScheduledCallsStartButton/ScheduledCallsStartButton';
import { IScheduledCallViewInitialData } from '../ScheduledCallViewPopup.presenter';
import { useScheduledCallViewInfoPresenter } from './ScheduledCallViewInfo.presenter';
import styles from './ScheduledCallViewInfo.styles.m.css';

interface IScheduledCallViewInfo {
  scheduledEvent: IScheduledEventDetails | IScheduledCallViewInitialData;
  onStartClick(): void;
  onCancel(): void;
}

export const ScheduledCallViewInfo: FC<IScheduledCallViewInfo> = observer(
  ({ scheduledEvent, onCancel, onStartClick }) => {
    const presenter = useScheduledCallViewInfoPresenter({ scheduledEvent, onCancel });

    return (
      <>
        <div className={styles.group}>
          <Avatar
            backgroundColorIndex={presenter.groupColorIndex}
            className={styles.avatar}
            type={AvatarTypes.Round_05}
            title={scheduledEvent.groupName}
            url={scheduledEvent.groupAvatar}
          />
          <div className={styles.groupName}>{scheduledEvent.groupName}</div>
        </div>
        <h1 className={styles.name}>{scheduledEvent.name}</h1>
        <h2 className={styles.time}>{presenter.time}</h2>
        {presenter.reminder ? (
          <h3 className={styles.reminder}>{presenter.reminder}</h3>
        ) : (
          <TextPlaceholder className={styles.reminderPlaceholder} />
        )}
        <div className={styles.actions}>
          <ScheduledCallsStartButton
            groupId={scheduledEvent.groupId}
            initiatorId={scheduledEvent.initiatorId}
            startTs={scheduledEvent.startTs}
            onClick={onStartClick}
          />
          {presenter.isMeAdminOwnerOrInitiator && (
            <IconButton
              size={IconViewSizes.Big}
              className={styles.actionButton}
              iconSize={IconViewSizes.Medium}
              theme="6"
              Icon={IcDelete}
              onClick={presenter.handleDeleteEvent}
            />
          )}
        </div>
      </>
    );
  }
);
