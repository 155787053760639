import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { useTranslation } from 'APP/packages/translations';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { ChannelForm } from '../../components/ChannelForm/ChannelForm';
import { useCloseFormPopup } from '../../hooks/useCloseFormPopup';
import {
  useEditChannelPopupPresenter,
  IEditChannelPopupPresenterParams,
} from './EditChannelPopup.presenter';

type IEditChannelProps = IEditChannelPopupPresenterParams;

export const EditChannelPopup: FC<ICommonPopupsProps<IEditChannelProps>> = observer((props) => {
  const { t } = useTranslation();
  const presenter = useEditChannelPopupPresenter(props);
  const closeForm = useCloseFormPopup(props, t('cancel_channel_creation_alert'));

  return (
    <Popup
      isOpened
      onClose={closeForm.closePopup}
    >
      <ChannelForm
        title={t('channel_edit_screen_title')}
        initialValues={presenter.initialValues}
        avatarTitle={presenter.avatarTitle}
        avatarColor={presenter.avatarColor}
        isEditMode={true}
        onSubmit={presenter.onSubmit}
        onFormStateChange={closeForm.onFormStateChange}
        onClose={closeForm.closePopup}
      />
    </Popup>
  );
});
