import { ICategoryInfo } from 'APP/model/common/commonModel.types';
import api from 'APP/packages/api';
import { now } from 'APP/packages/date/helpers/now';
import logger from 'APP/packages/logger';
import { getUserLanguage } from 'UTILS/getUserLanguage';

// ONE DAY
const CATEGORY_CACHE_SAVE_TS = 24 * 60 * 60 * 1000;

interface IGetAllCategoriesInfoRequest {
  readonly locale: string;
  readonly type: string;
}

interface ICategoriesCache {
  readonly locale: string;
  readonly categories: ICategoryInfo[];
  readonly loadTs: number;
}

let categoriesCache: ICategoriesCache | null = null;

async function request(data: IGetAllCategoriesInfoRequest): Promise<ICategoryInfo[]> {
  try {
    const response = await api.search.getAllCategoriesInfo(data);

    return response.categories;
  } catch (error) {
    logger.get('API').error('search.getAllCategoriesInfo', error);
    return [];
  }
}

const loadCategories = async (): Promise<void> => {
  const locale = getUserLanguage();

  const categories = await request({
    locale,
    type: 'group',
  });

  categoriesCache = {
    locale,
    loadTs: now().valueOf(),
    categories: categories.sort(
      (categoryA, categoryB) => parseInt(categoryA.order, 10) - parseInt(categoryB.order, 10)
    ),
  };
};

const isCacheInvalid = (): boolean => {
  const locale = getUserLanguage();
  return (
    !categoriesCache ||
    categoriesCache.locale !== locale ||
    now().valueOf() - categoriesCache.loadTs > CATEGORY_CACHE_SAVE_TS
  );
};

export const getAllCategoriesInfo = async (): Promise<ICategoryInfo[]> => {
  if (isCacheInvalid()) {
    await loadCategories();
  }

  return categoriesCache?.categories || [];
};
