import routerConst, { RouterState } from 'APP/router/constants';
import Entities from 'APP/store';

export default async ({ navigateTo, groupId = null, messageId = null }) => {
  let id = groupId;
  if (!id) {
    const group = Entities.GroupsStore.activeGroup;
    if (!group) {
      return;
    }

    id = group.isSchedule ? group.groupId : group.id;
  }
  //stop recording voice before open schedule settings
  Entities.InputPanel.send.prepareSend();
  await Promise.resolve();
  navigateTo({
    state: {
      [RouterState.popup]: routerConst.stateEnum.popup.scheduleSettings,
      [RouterState.groupId]: id,
      [RouterState.messageId]: messageId,
    },
  });
};
