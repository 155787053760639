import classNames from 'classnames';
import React, { FC } from 'react';

import { Button } from 'UIKIT/Button/Button';

import styles from './InfoBlock.styles.m.css';

export enum InfoBlockTypes {
  Page = 'page',
  Chat = 'chat',
  ChatWithPattern = 'chat-with-pattern',
  Modal = 'modal',
  Sidebar = 'sidebar',
}

interface IInfoBlockProps {
  readonly className?: string;
  readonly iconClassName?: string;
  readonly buttonClassName?: string;
  readonly type?: InfoBlockTypes;
  readonly title?: string;
  readonly descriptions?: string[];
  readonly image?: string;
  readonly buttonTitle?: string;
  readonly isLoading?: boolean;
  onClick?(): void;
}

export const InfoBlock: FC<IInfoBlockProps> = (props) => {
  const {
    className,
    iconClassName,
    buttonClassName,
    type = InfoBlockTypes.Chat,
    title = '',
    descriptions = [],
    image = null,
    buttonTitle = '',
    isLoading = false,
    children,
    onClick,
  } = props;
  const showButton = Boolean(buttonTitle && onClick);
  const containerClass = classNames(styles.container, styles[`type-${type}`], className);

  return (
    <div className={containerClass}>
      {image && (
        <img
          className={classNames(styles.image, iconClassName)}
          src={image}
          alt={title || ''}
          draggable="false"
        />
      )}

      {title && <h3 className={styles.title}>{title}</h3>}

      {descriptions.map((description) => (
        <p
          className={styles.description}
          key={String(description)}
        >
          {description}
        </p>
      ))}

      {children}

      {showButton && (
        <Button
          className={classNames(styles.button, buttonClassName)}
          theme="1"
          isLoading={isLoading}
          onClick={onClick}
        >
          {buttonTitle}
        </Button>
      )}
    </div>
  );
};
