import { IImageSize } from '.';
import { calculateLayout } from './calculateLayout';
import { MAX_ROW_COUNT, MAX_ITEMS_PER_ROW } from './constants';

const canMove = (layout: IImageSize[][], row: number, col: number) => {
  //нельзя передвинуть элемент если:
  // первый в строке
  // находится на посленей строке
  // нет свободных слотов на последней строке
  const freeSlotsInLast = layout[MAX_ROW_COUNT - 1].filter((x) => x === null);
  if (
    layout[row][col] !== null &&
    col !== 0 &&
    row < MAX_ROW_COUNT - 1 &&
    MAX_ITEMS_PER_ROW - col <= freeSlotsInLast.length
  ) {
    return true;
  }
  return false;
};

const move = (layout: IImageSize[][], row: number, col: number): IImageSize[][] => {
  const newFlatLayout = layout
    .flat()
    .slice(0, row * MAX_ITEMS_PER_ROW + col)
    .concat(new Array(MAX_ITEMS_PER_ROW - col).fill(null))
    .concat(layout.flat().slice(row * MAX_ITEMS_PER_ROW + col));
  return new Array(MAX_ROW_COUNT)
    .fill(undefined)
    .map((x, idx) => newFlatLayout.slice(idx * MAX_ITEMS_PER_ROW, (idx + 1) * MAX_ITEMS_PER_ROW));
};

const getAllLayout = (
  layout: IImageSize[][],
  row: number,
  col: number,
  acc: IImageSize[][][]
): IImageSize[][][] => {
  acc.push(layout);
  for (let y = row; y < MAX_ROW_COUNT; y++) {
    for (let x = col; x < MAX_ITEMS_PER_ROW; x++) {
      if (canMove(layout, y, x)) {
        const newLayout = move(layout, y, x);
        getAllLayout(newLayout, y + 1, 0, acc);
      }
    }
  }
  return acc;
};

const getInitLayout = (list: IImageSize[]) => {
  const layout = new Array(MAX_ROW_COUNT)
    .fill(null)
    .map(() => new Array(MAX_ITEMS_PER_ROW).fill(null));
  list
    .filter(({ w, h }) => w && h)
    .forEach((x, idx) => {
      const row = Math.floor(idx / MAX_ITEMS_PER_ROW);
      const col = idx % MAX_ITEMS_PER_ROW;
      layout[row][col] = x;
    });
  return layout;
};

export const getLayout = (list: IImageSize[]): IImageSize[][] | IImageSize[] => {
  if (list.length === 0) {
    return list;
  }
  const layouts = getAllLayout(getInitLayout(list.slice(0, 9)), 0, 0, []).map((layout) =>
    calculateLayout(layout)
  );
  // сортируем полченыые раскладки по отдаленности ратио от еденицы. Т.е находим самый квадратный альбом
  return layouts.sort((a, b) => Math.abs(1 - a.ratio) - Math.abs(1 - b.ratio))[0].layout;
};
