import { useEffect, useState } from 'react';

let enableTimeout;
let disableTimeout;

export default ({ firstTimeout, secondTimeout }) => {
  const [isFirstAnimated, setIsFirstAnimated] = useState(false);
  const [isSecondAnimated, setIsSecondAnimated] = useState(false);

  useEffect(() => {
    if (isFirstAnimated) {
      enableTimeout = setTimeout(() => {
        setIsSecondAnimated(true);
        disableTimeout = setTimeout(() => {
          setIsFirstAnimated(false);
        }, 200);
      }, firstTimeout - 200);
    }
  }, [isFirstAnimated]);

  useEffect(() => {
    if (isSecondAnimated) {
      enableTimeout = setTimeout(() => {
        setIsFirstAnimated(true);
        disableTimeout = setTimeout(() => {
          setIsSecondAnimated(false);
        }, 200);
      }, secondTimeout - 200);
    }
  }, [isSecondAnimated]);

  useEffect(() => {
    setIsFirstAnimated(true);

    return () => {
      if (enableTimeout) {
        clearTimeout(enableTimeout);
      }
      if (disableTimeout) {
        clearTimeout(disableTimeout);
      }
    };
  }, []);

  return {
    isFirstAnimated,
    isSecondAnimated,
  };
};
