const getPayloadData = (payload) => {
  if (payload.data) {
    return payload.data.toJSON ? payload.data.toJSON() : payload.data;
  }

  return payload.payload.toJSON();
};

export default (payloads) => {
  return payloads.map((payload) => {
    return {
      id: payload.id,
      order: payload.order,
      payload: getPayloadData(payload),
      payloadType: 'container.ordered',
    };
  });
};
