import { computed, observable } from 'mobx';

import { STREAM_STATUS } from 'APP/constants/stream';
import { PayloadType } from 'APP/model/message/messageModel.types';
import { getFixedSizeBlurredImage, getImageByLargestSize } from 'APP/packages/imageResizer';
import { Payload } from 'STORE/Messages/Message/Payload';
import File from 'STORE/Messages/Message/Payload/File';

class Stream extends Payload {
  initiatorId;
  @observable previewImageUrl;
  previewGraphicSize;
  startTs;
  @observable finishTs;
  channelId;
  @observable status;
  @observable comment;
  @observable totalViewers;

  constructor(data) {
    super(data);

    this.initiatorId = data.initiator;
    this.previewImageUrl = data.previewImageUrl || null;
    this.previewGraphicSize = data.previewGraphicSize || null;
    this.startTs = data.startTs;
    this.finishTs = data.finishTs;
    this.channelId = data.channelId;
    this.status = data.status;
    this.comment = data.title || '';
    this.totalViewers = data.totalViewers;
    this.userMentions = data.userMentions;
  }

  toJSON() {
    return {
      payloadType: PayloadType.Stream,
      initiator: this.initiatorId,
      userMentions: this.userMentions,
      channelId: this.channelId,
      startTs: this.startTs,
      finishTs: this.finishTs,
      previewImageUrl: this.previewImageUrl,
      status: this.status,
      totalViewers: this.totalViewers,
    };
  }

  get placeholderUrl() {
    return (
      this.previewImageUrl &&
      getFixedSizeBlurredImage(
        this.previewImageUrl,
        {
          width: File.PREVIEW_MAX_WIDTH,
          height: 0, //scaled by width
        },
        this.previewGraphicSize
      )
    );
  }

  get isSystem() {
    return true;
  }

  get isStream() {
    return true;
  }

  @computed
  get canBeDelete() {
    return this.status === STREAM_STATUS.FINISHED;
  }

  get previewUrl() {
    return (
      this.previewImageUrl && getImageByLargestSize(this.previewImageUrl, File.PREVIEW_MAX_WIDTH)
    );
  }
}

export default Stream;
