import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { MessageLayout } from '../../MessageLayout/MessageLayout';
import { ForwardMessage } from '../components/ForwardMessage/ForwardMessage';
import { QuotedMessage } from '../components/QuotedMessage/QuotedMessage';
import { SenderName } from '../components/SenderName/SenderName';
import StatusBar from '../components/StatusBar';
import { ThreadButton } from '../components/ThreadButton/ThreadButton';
import Translate from '../components/Translate';
import styles from './TextBubble.styles.m.scss';

export const TextBubble = observer((props) => {
  const { message, children, messageViewPageType, isView } = props;
  const isShowForward = Boolean(message.forward && !message.isSavedMessagesLeft);

  const senderNameClasses = classNames(styles.senderName, {
    [styles.wideGap]: message.quotedMessage || isShowForward,
  });

  return (
    <MessageLayout
      message={message}
      className={styles.bubble}
      messageViewPageType={messageViewPageType}
      isView={isView}
    >
      <SenderName
        message={message}
        className={senderNameClasses}
      />

      {message.quotedMessage && (
        <QuotedMessage
          className={styles.quote}
          message={message.quotedMessage}
        />
      )}

      {isShowForward && (
        <ForwardMessage
          className={styles.forward}
          message={message}
        />
      )}

      <div
        className={styles.content}
        data-test="text-message"
      >
        {children}
        <StatusBar
          className={styles.statusBar}
          message={message}
        />
      </div>

      <ThreadButton
        message={message}
        className={styles.threadButton}
      />
      <Translate
        message={message}
        className={styles.translate}
      />
    </MessageLayout>
  );
});
