import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, ReactElement } from 'react';

import { ISpaceMember } from 'APP/Tasks/space/getSpaceMembers/getSpaceMembers';
import { List } from 'APP/components/List/List';
import { GroupsPlaceholder } from 'APP/packages/placeholders/GroupsPlaceholder/GroupsPlaceholder';
import { useTranslation } from 'APP/packages/translations';
import { Space } from 'STORE/Spaces/Space';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';

import { SpaceMembersListItem } from '../SpaceMembersListItem/SpaceMembersListItem';
import { useSpaceMembersListPresenter } from './SpaceMembersList.presenter';
import styles from './SpaceMembersList.styles.m.css';

interface ISpaceMembersListProps {
  space: Space | null;
  searchText?: string;
  onClosePopup(): void;
}

export const SpaceMembersList: FC<ISpaceMembersListProps> = observer((props) => {
  const { space, searchText, onClosePopup } = props;
  const presenter = useSpaceMembersListPresenter({ space, searchText });
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.container, customScrollStyles.container)}>
      <List
        isLoading={presenter.isLoading}
        hasMore={presenter.hasMore}
        data={presenter.members}
        onEndReached={presenter.loadMore}
        keyExtractor={(member: ISpaceMember): string => member.user.id}
        renderItem={(member: ISpaceMember): ReactElement => (
          <SpaceMembersListItem
            space={space}
            member={member}
            onMemberRemove={presenter.onMemberRemove}
            onUpdateMemberRole={presenter.onUpdateMemberRole}
            onReloadMembersList={presenter.onReloadMembersList}
            onClosePopup={onClosePopup}
          />
        )}
        ListEmptyComponent={<div className={styles.noContent}>{t('no_results_found')}</div>}
        SpinnerComponent={
          <GroupsPlaceholder
            className={styles.placeholders}
            count={presenter.members.length > 0 ? 2 : 10}
          />
        }
      />
    </div>
  );
});
