import { observer } from 'mobx-react';
import React from 'react';

import { EntireScreenButton } from 'APP/components/EntireScreenButton/EntireScreenButton';
import { ShareScreenButton } from 'APP/components/ShareScreenButton/ShareScreenButton';
import { IconButton } from 'APP/packages/UiKit/IconButton';
import { useTranslation } from 'APP/packages/translations';
import MicOff from 'ICONS/ic-mic-off.svg';
import Mic from 'ICONS/ic-mic-on.svg';
import MinimizeIcon from 'ICONS/ic-minimize.svg';
import VideoOff from 'ICONS/ic-video-off.svg';
import VideoOn from 'ICONS/ic-video-on.svg';
import { Button } from 'UIKIT/Button/Button';

import ThreadButton from '../../../SharedComponents/ThreadButton';
import styles from './styles.m.css';

const ControlPanel = ({ presenter, streamerPresenter, isCommentsHidden }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <IconButton
        Icon={streamerPresenter.audioMuted ? MicOff : Mic}
        onClick={
          streamerPresenter.audioMuted ? streamerPresenter.unmuteAudio : streamerPresenter.muteAudio
        }
        className={styles.button}
        size="large"
        iconSize="medium"
        theme={streamerPresenter.audioMuted ? 12 : 13}
      />
      <IconButton
        Icon={streamerPresenter.isVideoMuted ? VideoOff : VideoOn}
        onClick={
          streamerPresenter.videoMuted ? streamerPresenter.unmuteVideo : streamerPresenter.muteVideo
        }
        className={styles.button}
        size="large"
        iconSize="medium"
        theme={streamerPresenter.videoMuted ? 12 : 13}
      />
      <ShareScreenButton
        className={styles.button}
        isActive={streamerPresenter.isSharedScreen}
        shareScreen={streamerPresenter.startSharingScreen}
        stopSharingScreen={streamerPresenter.stopSharingScreen}
      />
      <Button
        onClick={presenter.isStreamStarted ? presenter.finish : presenter.goLive}
        theme="1"
        size="20"
        className={styles.button}
      >
        {presenter.isStreamStarted ? t('stream_finish_button') : t('stream_go_live_button')}
      </Button>
      <div className={styles.right}>
        {!isCommentsHidden && (
          <ThreadButton
            className={styles.button}
            onClick={presenter.collapse}
          />
        )}
        {presenter.isStreamStarted ? (
          <IconButton
            Icon={MinimizeIcon}
            onClick={presenter.collapse}
            className={styles.button}
            size="large"
            iconSize="medium"
            theme="12"
          />
        ) : null}
        <EntireScreenButton className={styles.button} />
      </div>
    </div>
  );
};

export default observer(ControlPanel);
