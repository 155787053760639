import Tasks from 'APP/Tasks';
import { PayloadType } from 'APP/model/message/messageModel.types';

const STAY_PAYLOAD_TYPES = [
  PayloadType.Sticker,
  PayloadType.File,
  PayloadType.AudioMessage,
  PayloadType.Location,
];

export const clearInputPanel = (groupId, messages) => {
  const isStayInputPanel = messages.some((message) => {
    return STAY_PAYLOAD_TYPES.indexOf(message.type) >= 0;
  });

  Tasks.messaging.clearInputPanel({ groupId, clearInput: !isStayInputPanel });
};
