import { subscribe, broadcast } from '../../eventBus';

function getBusStation() {
  return 'start-quote-message';
}

export const subscribeStartQuoteMessage = (callback) => {
  return subscribe(getBusStation(), callback);
};

export const broadcastStartQuoteMessage = () => {
  broadcast(getBusStation());
};
