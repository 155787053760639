import classNames from 'classnames';
import React, { FC } from 'react';

import { IVideoPlayerConfig } from 'UIKIT/Media/VideoPlayer/VideoPlayer.types';
import noop from 'UTILS/noop';

import { useMediaPlayerProgress } from '../hooks/useMediaPlayerProgress';
import { useMediaPlayerSpeed } from '../hooks/useMediaPlayerSpeed';
import { defaultVideoPlayerConfig, VideoPlayerType } from './VideoPlayer.constants';
import styles from './VideoPlayer.styles.m.css';
import { VideoPlayerDefaultControls } from './VideoPlayerDefaultControls/VideoPlayerDefaultControls';
import { VideoPlayerMiniControls } from './VideoPlayerMiniControls/VideoPlayerMiniControls';
import { VideoPlayerSource } from './VideoPlayerSource/VideoPlayerSource';
import { useVideoActiveState } from './presenter/useVideoActiveState';
import { useVideoPlayer } from './presenter/useVideoPlayer';
import { useVideoPlayerFullscreenMode } from './presenter/useVideoPlayerFullscreenMode';
import { useVideoPlayerHotKeys } from './presenter/useVideoPlayerHotKeys';
import { useVideoPlayerVolume } from './presenter/useVideoPlayerVolume';

interface IVideoPlayerProps {
  url?: string | null;
  previewUrl?: string | null;
  autoPlay?: boolean;
  isPlaying?: boolean;
  isFullScreenMode?: boolean;
  type?: VideoPlayerType | null;
  progress?: number | null;
  config: Partial<IVideoPlayerConfig>;
  onTogglePlay?(isPlaying: boolean): void;
  onToggleFullScreenMode?(isFullScreenMode: boolean): void;
  onConfigUpdate?(config: Partial<IVideoPlayerConfig>): void;
  onShowMiniPlayer?(): void;
  onExpandMiniPlayer?(): void;
  onCloseMiniPlayer?(): void;
  onProgressUpdate?(progress: number, progressPercentage: number): void;
  onLoadedFirstFrame?(): void;
  onLoadedError?(): void;
}

export const VideoPlayer: FC<IVideoPlayerProps> = ({
  url = null,
  previewUrl = null,
  autoPlay = false,
  isPlaying: newIsPlayingState = false,
  isFullScreenMode: newIsFullScreenModeState = false,
  type = VideoPlayerType.Default,
  progress: newProgress = null,
  config: playerConfig = {},
  onTogglePlay = noop,
  onToggleFullScreenMode = noop,
  onConfigUpdate = noop,
  onShowMiniPlayer,
  onExpandMiniPlayer,
  onCloseMiniPlayer,
  onProgressUpdate,
  onLoadedFirstFrame,
  onLoadedError,
}) => {
  const config = {
    ...defaultVideoPlayerConfig,
    ...playerConfig,
  };

  const { videoRef, videoContainerRef, isPlaying, togglePlay, play, pause, onLoadedVideoData } =
    useVideoPlayer({
      url,
      autoPlay,
      newIsPlayingState,
      onTogglePlay,
      onLoadedFirstFrame,
    });

  const {
    progress,
    progressBuffer,
    progressMax,
    setProgress,
    updateProgressMax,
    updateProgress,
    updateProgressBuffered,
  } = useMediaPlayerProgress({
    url,
    mediaRef: videoRef,
    newProgress,
    onProgress: onProgressUpdate,
  });

  const { setVolume, toggleMute } = useVideoPlayerVolume({
    videoRef,
    config,
    onConfigUpdate,
  });

  const { setSpeed } = useMediaPlayerSpeed({
    url,
    mediaRef: videoRef,
    config,
    onConfigUpdate,
  });

  const { isFullScreenMode, toggleFullScreenMode } = useVideoPlayerFullscreenMode({
    videoContainerRef,
    newIsFullScreenModeState,
    onToggleFullScreenMode,
  });

  const { isControlsHidden, onMouseMove, isControlsActive, onToggleControlsActiveState } =
    useVideoActiveState();

  useVideoPlayerHotKeys({
    url,
    type,
    toggleFullScreenMode,
    onShowMiniPlayer,
    onExpandMiniPlayer,
    toggleMute,
    togglePlay,
    volume: config.volume,
    setVolume,
  });

  const containerClass = classNames(styles.container, {
    [styles.active]: isControlsActive,
    [styles.inactive]: isControlsHidden && !isControlsActive,
  });

  const videoClass = classNames(styles.video, {
    [styles.mini]: type === VideoPlayerType.Mini,
  });

  return (
    <div
      className={containerClass}
      ref={videoContainerRef}
      onMouseMove={onMouseMove}
    >
      <video
        className={videoClass}
        muted={config.isMuted}
        poster={previewUrl || undefined}
        ref={videoRef}
        preload="metadata"
        autoPlay={autoPlay}
        onClick={togglePlay}
        onLoadedData={onLoadedVideoData}
        onLoadedMetadata={updateProgressMax}
        onTimeUpdate={updateProgress}
        onProgress={updateProgressBuffered}
        onError={onLoadedError}
      >
        <VideoPlayerSource url={url} />
      </video>

      <div className={styles.controls}>
        {(!type || type === VideoPlayerType.Default) && (
          <VideoPlayerDefaultControls
            isPlaying={isPlaying}
            onPlay={play}
            onPause={pause}
            onTogglePlay={togglePlay}
            progress={progress}
            progressMax={progressMax}
            progressBuffer={progressBuffer}
            onSetProgress={setProgress}
            onProgressSelection={onToggleControlsActiveState}
            isMuted={config.isMuted}
            volume={config.volume}
            onToggleMute={toggleMute}
            onSetVolume={setVolume}
            onVolumeSelection={onToggleControlsActiveState}
            speed={config.speed}
            setSpeed={setSpeed}
            onToggleSpeedPopover={onToggleControlsActiveState}
            onShowMiniPlayer={onShowMiniPlayer}
            isFullScreenMode={isFullScreenMode}
            onToggleFullScreenMode={toggleFullScreenMode}
          ></VideoPlayerDefaultControls>
        )}

        {type === VideoPlayerType.Mini && (
          <VideoPlayerMiniControls
            isPlaying={isPlaying}
            onPlay={play}
            onPause={pause}
            onTogglePlay={togglePlay}
            progress={progress}
            progressMax={progressMax}
            progressBuffer={progressBuffer}
            onSetProgress={setProgress}
            onProgressSelection={onToggleControlsActiveState}
            onExpandMiniPlayer={onExpandMiniPlayer}
            onCloseMiniPlayer={onCloseMiniPlayer}
          ></VideoPlayerMiniControls>
        )}
      </div>
    </div>
  );
};
