export enum CallReminderTime {
  FiveMinutes = 300000,
  TenMinutes = 600000,
  HalfHour = 1800000,
  OneHour = 3600000,
  TwoHours = 7200000,
  OneDay = 86400000,
}

export const CallReminderMap = {
  [CallReminderTime.FiveMinutes]: 'сall_reminder_5min',
  [CallReminderTime.TenMinutes]: 'сall_reminder_10min',
  [CallReminderTime.HalfHour]: 'сall_reminder_30min',
  [CallReminderTime.OneHour]: 'сall_reminder_1h',
  [CallReminderTime.TwoHours]: 'сall_reminder_2h',
  [CallReminderTime.OneDay]: 'сall_reminder_1day',
};

export enum ScheduledCallsFormFields {
  Name = 'name',
  StartTsDate = 'startTsDate',
  StartTsTime = 'startTsTime',
  EndTsDate = 'endTsDate',
  EndTsTime = 'endTsTime',
  Reminder = 'reminder',
}

export const END_DATE_GAP = 1000 * 45 * 60;
