import React, { FC } from 'react';

import { useAnimateSticker } from 'APP/packages/stickerAnimation/useAnimateSticker';

interface IAnimationProps {
  className?: string;
  url: string;
  onClick(): void;
}

export const Animation: FC<IAnimationProps> = (props) => {
  const { className, url, onClick } = props;

  const { stickerContainerRef } = useAnimateSticker<HTMLDivElement>({
    autoplay: true,
    loop: false,
    url,
    onLoopComplete: onClick,
  });

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={onClick}
      className={className}
      ref={stickerContainerRef}
    />
  );
};
