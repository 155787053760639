import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Tasks from 'APP/Tasks';
import routerConst from 'APP/router/constants';
import useClearGetParameters from 'APP/router/hooks/useClearGetParameters';
import useGetParameter from 'APP/router/hooks/useGetParameter';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import Entities from 'STORE';

export const usePresenter = () => {
  const clearGetParameters = useClearGetParameters();
  const navigateTo = useNavigateTo();
  const history = useHistory();
  const memberPermissionsId = useGetParameter(routerConst.params.memberPermissionsId);

  const presenter = useLocalStore(() => ({
    onClose: () => {
      clearGetParameters();
    },

    goBack: () => {
      history.goBack();
    },

    onDeleteButtonClick: async () => {
      await Tasks.calls.clearCallPermissionsForUser({
        channelId: presenter.channelId,
        userId: presenter.userId,
      });

      navigateTo({
        query: {
          [routerConst.params.popup]: routerConst.paramsEnum.popup.callPermissions,
        },
      });
    },

    onAddClick: async () => {
      Tasks.calls.setCallPermissionsForUser({
        channelId: presenter.channelId,
        userId: presenter.userId,
        permissions: presenter.permissions,
      });
      navigateTo({
        query: {
          [routerConst.params.popup]: routerConst.paramsEnum.popup.callPermissions,
        },
      });
    },

    get userId() {
      return memberPermissionsId;
    },

    get isNewMemberPermissions() {
      return !presenter.ongoingCall?.permissions.customPermissions.has(memberPermissionsId);
    },

    get channelId() {
      return presenter.ongoingCall?.channelId;
    },

    get permissions() {
      return presenter.ongoingCall?.permissions?.editedPermissions?.permissions;
    },

    get ongoingCall() {
      return Entities.Calls.ongoingCall;
    },
  }));

  useEffect(() => {
    if (!presenter.ongoingCall || !presenter.ongoingCall?.isStarted) {
      presenter.onClose();
    }
  }, [presenter.ongoingCall?.isStarted]);

  return presenter;
};
