import Entities from 'STORE';

import { GroupsEvent } from '../GroupsEvent';

export class ConfigGroupPin extends GroupsEvent {
  process() {
    const { groupIds } = this.eventPayload;

    Entities.GroupsStore.updatePinnedGroups(groupIds);
  }
}
