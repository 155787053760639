import { useLocalStore, useAsObservableSource } from 'mobx-react';

import { UpdateReason } from 'APP/constants/scroll';
import Entities from 'STORE';
import { Group } from 'STORE/Groups/Group';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

interface ILoadBelowObserverPresenter {
  group: Group | null;
  hasLoadBelowObserver: boolean;
  onInViewChange(isInView: boolean): void;
}

export const useLoadBelowObserverPresenter = (
  groupId: string,
  lastMessage: ChatMessage | null
): ILoadBelowObserverPresenter => {
  const source = useAsObservableSource({ groupId, lastMessage });

  const presenter = useLocalStore<ILoadBelowObserverPresenter>(() => ({
    get group(): Group | null {
      return Entities.GroupsStore.getGroupById(source.groupId);
    },

    get hasLoadBelowObserver(): boolean {
      const lastChatMessage = presenter.group?.messagesStore.getMessageByTs(
        presenter.group.lastMessageTs
      );
      const isRenderLastMessage =
        lastChatMessage &&
        source.lastMessage?.expectedServerTime >= lastChatMessage.expectedServerTime;

      return !isRenderLastMessage;
    },

    onInViewChange(isInView: boolean): void {
      if (isInView) {
        Entities.ChatStore.setScrollChanged(UpdateReason.LoadBelow);
      }
    },
  }));

  return presenter;
};
