import React from 'react';

import { TextRenderer } from 'APP/components/TextRenderer/TextRenderer';
import { useTranslation } from 'APP/packages/translations';

import styles from './DraftMessageDescription.style.m.css';

export const DraftMessageDescription = ({ text }) => {
  const { t } = useTranslation();

  return (
    <>
      <span className={styles.draftHeader}>{t('common_draft')}</span>
      <TextRenderer
        isPlainText
        className={styles.text}
      >
        {text}
      </TextRenderer>
    </>
  );
};
