import { animationsCache } from 'APP/cache/animations';

export const loadLottieFile = async (url: string): Promise<Record<string, any> | null> => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      return null;
    }

    const lottieData = (await response.json()) as Record<string, any>;
    await animationsCache.putLottieObject(url, lottieData);

    return lottieData;
  } catch {
    return null;
  }
};
