import classNames from 'classnames';
import React, { ChangeEvent, MouseEvent, FC, ReactNode } from 'react';

import { Switch } from '../Switch/Switch';
import styles from './SwitchMenuItem.m.css';

type TSwitchMenuItemTheme = 'dark' | 'danger';

interface ISwitchMenuItemProps {
  className?: string;
  title: string;
  titleClassName?: string;
  description?: string;
  descriptionClassName?: string;
  icon?: ReactNode;
  iconClassName?: string;
  theme?: TSwitchMenuItemTheme;
  isChecked?: boolean;
  isDisabled?: boolean;
  dataTest?: string;
  onChange(event: ChangeEvent<HTMLInputElement>): void;
  onClick?(event: MouseEvent<HTMLLabelElement>): void;
}

export const SwitchMenuItem: FC<ISwitchMenuItemProps> = (props) => {
  const {
    title,
    description,
    icon,
    className,
    titleClassName,
    descriptionClassName,
    iconClassName,
    theme,
    isChecked,
    isDisabled,
    dataTest,
    onChange,
    onClick,
  } = props;

  const containerClassNames = classNames(
    styles.container,
    styles[`container--${theme}`],
    className
  );

  const iconClassNames = classNames(styles.icon, iconClassName);
  const titleClassNames = classNames(styles.title, styles[`title--${theme}`], titleClassName);
  const descriptionClassNames = classNames(styles.description, descriptionClassName);

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <label
      className={containerClassNames}
      data-test={dataTest}
      onClick={onClick}
    >
      {icon && <span className={iconClassNames}>{icon}</span>}
      <span>
        <span
          className={titleClassNames}
          title={title}
        >
          {title}
        </span>
        {description && <span className={descriptionClassNames}>{description}</span>}
      </span>

      <Switch
        className={styles.switch}
        isChecked={isChecked}
        onChange={onChange}
        isDisabled={isDisabled}
      />
    </label>
  );
};
