import Tasks from 'APP/Tasks';
import { loadScheduledMessages } from 'APP/Tasks/group/loadScheduledMessages/loadScheduledMessages';
import { markGroupsAsUnread } from 'APP/Tasks/group/markGroupsAsUnread/markGroupsAsUnread';
import { counterModel } from 'APP/model/model';
import { RouterLink } from 'APP/router/constants';
import { getLocation } from 'APP/router/refs';
import { navigateTo } from 'APP/router/refs/navigateTo';
import Entities from 'APP/store';
import type { Group } from 'STORE/Groups/Group';
import { parseScheduleGroupId } from 'STORE/Groups/Group/Group.utils';
import { Thread } from 'STORE/Groups/Thread';

const loadGroup = async (
  groupIdentifier: string,
  showAlert: boolean,
  isChannel: boolean
): Promise<Group | null> => {
  // TODO: remove code for schedule group after scheduled messages refactoring: GEM-23214
  const { groupId, scheduleGroupId } = parseScheduleGroupId(groupIdentifier);

  // you always need to check the availability of the group and display an alert when you try to go to the group.
  // If I'm banned or the group doesn't exist
  await Tasks.group.loadGroupsByIds([groupId], showAlert, isChannel);

  const newGroup = Entities.GroupsStore.getGroupById(groupId);
  if (newGroup instanceof Thread) {
    await Tasks.thread.getThreadInfoById(newGroup.id);
  }

  if (!Entities.Counters.getCounterById(groupId)) {
    const counters = await counterModel.getGroupsCounters([groupId]);
    await Tasks.group.addCounters(counters);
  }

  if (scheduleGroupId) {
    await loadScheduledMessages(groupId);

    const scheduledGroup = Entities.GroupsStore.getGroupById(scheduleGroupId);
    if (scheduledGroup) {
      return scheduledGroup;
    }
  }

  return newGroup;
};

const isGroupCurrentUrl = (group: Group): boolean => {
  const location = getLocation();
  if (!location) {
    return false;
  }

  return location.pathname.indexOf(`/${group.route}/${group.id}`) === 0;
};

interface ISetActiveGroupParams {
  groupId?: string | null;
  state?: Record<string, string | number | boolean>;
  /*
    Show alerts for groups if the user is banned or the group is not available.
    Will work at the time of updating or loading information about the group.
  */
  showAlert?: boolean;
  isChannel?: boolean;
}

export const setActiveGroup = async ({
  groupId,
  state = {},
  showAlert = false,
  isChannel = false,
}: ISetActiveGroupParams): Promise<void> => {
  if (Entities.GroupsStore.activeGroupId === groupId) {
    return;
  }

  Entities.ChatStore.reset();

  if (Entities.GroupsStore.activeGroup) {
    Entities.GroupsStore.activeGroup.messagesStore.setInViewMessageIds([]);
    Entities.GroupsStore.activeGroup.messagesStore.resetExpectedServerTime();
  }

  if (
    !groupId &&
    Entities.GroupsStore.activeGroup &&
    isGroupCurrentUrl(Entities.GroupsStore.activeGroup)
  ) {
    if (
      Entities.GroupsStore.activeGroup.spaceId &&
      Entities.GroupsStore.activeGroup.spaceId === Entities.spacesStore.activeSpaceId
    ) {
      navigateTo({ to: RouterLink.space, params: { id: Entities.spacesStore.activeSpaceId } });
    } else {
      navigateTo({ to: RouterLink.main });
    }
  }

  if (!groupId) {
    Entities.GroupsStore.setActiveGroup(null);
    return;
  }

  Entities.GroupsStore.setActiveGroup(groupId);

  let group = Entities.GroupsStore.getGroupById(groupId);
  if (!group) {
    group = await loadGroup(groupId, showAlert, isChannel);
  }

  if (!group) {
    Entities.GroupsStore.setActiveGroup(null);
    navigateTo({ to: RouterLink.main });
    return;
  }

  if (!isGroupCurrentUrl(group)) {
    navigateTo({
      to: `/${group.route}/${group.id}`,
      state: { ...state },
      keepOldQuery: true,
    });
  }

  if (!group.isFake && group.unread) {
    markGroupsAsUnread([groupId], false);
  }
};
