import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { GroupAvatar } from 'APP/components/Avatar/GroupAvatar/GroupAvatar';
import { SpaceAvatar } from 'APP/components/Avatar/SpaceAvatar/SpaceAvatar';
import { ProfileNameBadge } from 'APP/components/ProfileNameBadge/ProfileNameBadge';
import BotIcon from 'ICONS/bot.svg';
import MutedIcon from 'ICONS/notification-off.svg';
import { useContextMenu } from 'MAIN/hooks/useContextMenu/useContextMenu';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';
import { PopoverMenu } from 'UIKIT/PopoverMenu/PopoverMenu';

import { GroupLastDate } from './GroupLastDate/GroupLastDate';
import { useGroupListItemPresenter } from './GroupListItem.presenter';
import styles from './GroupListItem.styles.m.css';
import { GroupStatusIcon } from './GroupStatusIcon/GroupStatusIcon';

export const GROUP_LIST_ITEM_HEIGHT = 64;

export const GroupListItem = observer(
  ({ group, isShowSpaceIcon, description, badges, isIconsCentered }) => {
    const { popover, onContextMenu, modifiers } = useContextMenu();
    const presenter = useGroupListItemPresenter(group, isShowSpaceIcon, popover);

    const groupContainerClassName = classNames(styles.groupContainer, {
      [styles.focus]: presenter.isOpenActions && presenter.actions.length,
      [styles.active]: presenter.isActive,
    });

    return (
      <>
        <div
          className={groupContainerClassName}
          ref={popover.containerRef}
          role="button"
          tabIndex={0}
          style={{ height: `${GROUP_LIST_ITEM_HEIGHT}px` }}
          data-test="group-link"
          onClick={presenter.onClick}
          onContextMenu={onContextMenu}
        >
          <div className={styles.avatar}>
            <GroupAvatar
              isShowOnlineIndicator
              group={group}
              type={presenter.avatarType}
              hasLockIcon={presenter.hasAvatarLockIcon}
            />
            {presenter.isShowSpaceIcon && (
              <SpaceAvatar
                className={styles.spaceAvatar}
                space={presenter.space}
                type={AvatarTypes.Square_0}
                isShowLockIcon={false}
              />
            )}
          </div>

          <div className={styles.content}>
            <div className={styles.header}>
              <div className={styles.titleWrapper}>
                <h6 className={styles.title}>{group.avatarTitle}</h6>
                <ProfileNameBadge
                  verified={!!presenter.verified}
                  className={styles.badge}
                  source={presenter.userNameBadge?.source}
                  animated={presenter.userNameBadge?.animated}
                  displayUILayer={0}
                />
                {presenter.isShowBotIcon && (
                  <BotIcon className={classNames(styles.icon, styles.iconBot)} />
                )}
                {presenter.isShowMutedIcon && (
                  <MutedIcon className={classNames(styles.icon, styles.iconMuted)} />
                )}
              </div>

              {!isIconsCentered && (
                <>
                  <GroupStatusIcon group={group} />
                  <GroupLastDate
                    date={presenter.lastMessageTs}
                    className={styles.date}
                  />
                </>
              )}
            </div>

            <div className={styles.footer}>
              <div className={styles.description}>{description}</div>

              {!isIconsCentered && <div className={styles.iconPanel}>{badges}</div>}
            </div>
          </div>

          {isIconsCentered && <div className={styles.iconPanel}>{badges}</div>}
        </div>

        <PopoverMenu
          isOpened={popover.isOpened}
          menuItems={presenter.actions}
          onClose={popover.close}
          reference={popover.containerRef.current}
          placement="bottom-start"
          modifiers={modifiers}
        />
      </>
    );
  }
);
