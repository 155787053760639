import Tasks from 'APP/Tasks';
import { PayloadType } from 'APP/model/message/messageModel.types';
import Entities from 'APP/store';

export const createDraft = async (data) => {
  try {
    await Tasks.drafts.createDraft({
      message: {
        type: PayloadType.Article,
        data: data.payload,
        groupId: data.groupId,
        quotedMessage: Entities.InputPanelsStore.activeInputPanel.quoteMessage,
      },
    });
  } catch (error) {
    console.error(error);
    return [];
  }
};
