import { MutableState, Mutator, Tools } from 'final-form';
import { useLocalStore } from 'mobx-react';
import { FieldMetaState } from 'react-final-form';

import dateService from 'APP/packages/date';
import { useTranslation } from 'APP/packages/translations';
import validations from 'APP/packages/validations';
import time from 'APP/packages/validations/fields/time';
import {
  CallReminderMap,
  END_DATE_GAP,
  ScheduledCallsFormFields,
} from 'MAIN/PopupManager/Popups/ScheduledCalls/ScheduledCalls.constants';
import { IScheduledCallsFormValues } from 'MAIN/PopupManager/Popups/ScheduledCalls/ScheduledCalls.types';
import { getDateWithTime } from 'MAIN/PopupManager/Popups/ScheduledCalls/ScheduledCalls.utils';

interface IScheduledCallsFormPresenter {
  validateStartTime(value: string, formData: any, meta: any): string | null;
  onSubmit(values: IScheduledCallsFormValues): void;
  reminderOptions: { value: number; label: string }[];
  validateForm(values: IScheduledCallsFormValues): Record<string, string> | undefined;
  isLoading: boolean;
  compareStartEndAndChange: Mutator<IScheduledCallsFormValues>;
  checkSubmitErrorsAndMoveToErrors: Mutator<IScheduledCallsFormValues>;
}

interface IScheduledCallsFormPresenterParams {
  onSubmit(values: IScheduledCallsFormValues): void;
}

export function useScheduledCallsFormPresenter(
  props: IScheduledCallsFormPresenterParams
): IScheduledCallsFormPresenter {
  const formValidations = validations.dateTime();
  const { t } = useTranslation();

  const presenter = useLocalStore<IScheduledCallsFormPresenter>(() => ({
    isLoading: false,

    validateStartTime(
      value: string,
      formData: IScheduledCallsFormValues,
      meta: FieldMetaState<IScheduledCallsFormValues>
    ): string | null {
      const minTime = !dateService.isToday(formData[ScheduledCallsFormFields.StartTsDate])
        ? null
        : dateService.time(dateService.now(), true);

      return formValidations.time.validate(value, formData, meta, {
        minTime: minTime,
        errorText: t('call_events_start_validation'),
      });
    },

    validateForm(values: IScheduledCallsFormValues): Record<string, string> | undefined {
      const errors: Record<string, string> = {};
      const minTime = !dateService.isToday(values[ScheduledCallsFormFields.StartTsDate])
        ? null
        : dateService.time(dateService.getNextMinute(), true);

      const isErrorValid = time.validate({
        value: values[ScheduledCallsFormFields.StartTsTime],
        data: { minTime, errorText: t('call_events_start_validation') },
      });

      if (isErrorValid) {
        errors[ScheduledCallsFormFields.StartTsTime] = isErrorValid;
      }

      const startTs = getDateWithTime(
        values[ScheduledCallsFormFields.StartTsDate],
        values[ScheduledCallsFormFields.StartTsTime]
      );
      const endTs = getDateWithTime(
        values[ScheduledCallsFormFields.EndTsDate],
        values[ScheduledCallsFormFields.EndTsTime]
      );
      if (endTs - startTs < 0) {
        errors[ScheduledCallsFormFields.EndTsTime] = t('call_events_end_date');
      }

      return errors;
    },

    checkSubmitErrorsAndMoveToErrors(
      values,
      state: MutableState<IScheduledCallsFormValues, Partial<IScheduledCallsFormValues>>,
      utils: Tools<IScheduledCallsFormValues, Partial<IScheduledCallsFormValues>>
    ): void {
      const submitErrors = state.formState.submitErrors as Record<string, string>;
      const errors = state.formState.errors as Record<string, string>;
      if (submitErrors && Object.keys(submitErrors).length) {
        const newErrors: Record<string, string> = { ...(errors || {}) };
        Object.keys(submitErrors).forEach((key) => (newErrors[key] = submitErrors[key]));

        utils.setIn(state, 'state.formState.submitErrors', {});
        utils.setIn(state, 'state.formState.errors', newErrors);
      }
    },

    compareStartEndAndChange(values: Partial<IScheduledCallsFormValues>, state, utils): void {
      /*
        following the technical documentation, when changing the start date (only START date and time,
        you must also change the end date if it is earlier than the start date
       */
      const lastValues = state.formState.values as IScheduledCallsFormValues;

      if (
        !lastValues ||
        !lastValues[ScheduledCallsFormFields.StartTsDate] ||
        !lastValues[ScheduledCallsFormFields.StartTsTime] ||
        !lastValues[ScheduledCallsFormFields.EndTsDate] ||
        !lastValues[ScheduledCallsFormFields.EndTsTime]
      ) {
        return;
      }

      // since the object mutates when the fields change,
      // we take the actual changed fields from the arguments(values arg),
      // and the remaining fields from the common form values
      const startTs = getDateWithTime(
        values[ScheduledCallsFormFields.StartTsDate] ||
          lastValues[ScheduledCallsFormFields.StartTsDate],
        values[ScheduledCallsFormFields.StartTsTime] ||
          lastValues[ScheduledCallsFormFields.StartTsTime]
      );

      const endTs = getDateWithTime(
        lastValues[ScheduledCallsFormFields.EndTsDate],
        lastValues[ScheduledCallsFormFields.EndTsTime]
      );

      if (endTs < startTs) {
        const endDate = new Date(startTs + END_DATE_GAP);
        const endTime = dateService.time(endDate, true);
        utils.changeValue(state, ScheduledCallsFormFields.EndTsDate, () => endDate);
        utils.changeValue(state, ScheduledCallsFormFields.EndTsTime, () => endTime);
      }
    },

    async onSubmit(values: IScheduledCallsFormValues): Promise<void | Record<string, string>> {
      const errors = presenter.validateForm(values);
      if (errors && Object.keys(errors).length) {
        return errors;
      }
      presenter.isLoading = true;
      await props.onSubmit(values);
      presenter.isLoading = false;
    },

    get reminderOptions(): { value: number; label: string }[] {
      return Object.entries(CallReminderMap).map(([key, value]) => ({
        value: Number(key),
        label: t(value),
      }));
    },
  }));

  return presenter;
}
