import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { useInAppViewContentPresenter } from './InAppViewContent.presenter';
import styles from './InAppViewContent.styles.m.css';
import { InAppViewEmpty } from './InAppViewEmpty/InAppViewEmpty';

interface InAppViewContentProps {
  url: string;
  isLoading: boolean;
  hasError: boolean;
  isOpen: boolean;
  onLoad(): void;
  goBack(): void;
  onReload(currentUrl: string): Promise<void>;
}
export const InAppViewContent: FC<InAppViewContentProps> = observer((props) => {
  const { url, isLoading, hasError, onLoad, onReload, isOpen, goBack } = props;
  const presenter = useInAppViewContentPresenter({ onLoad, onReload, url, isOpen, goBack });

  return (
    <div className={styles.container}>
      {(isLoading || hasError) && (
        <InAppViewEmpty
          isLoading={isLoading}
          hasError={hasError}
          goBack={goBack}
        />
      )}
      {url && !hasError && (
        <iframe
          title="marketplace"
          src={url}
          className={styles.iframe}
          data-id={presenter.uuid}
          allow="clipboard-write"
        />
      )}
    </div>
  );
});
