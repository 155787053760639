import { computed, observable } from 'mobx';

import { RouterPage } from 'APP/router/constants';
import { Root } from 'STORE';
import { Groups } from 'STORE/Groups';
import { Group } from 'STORE/Groups/Group';
import { IScheduleData } from 'STORE/Groups/Schedule/types';

import { Fake } from '../Fake';

export class Schedule extends Fake {
  @observable groupId: string | null = null;

  constructor(group: IScheduleData, groups: Groups, root: Root) {
    super(group, groups, root);
    this.groupId = group.groupId || null;
  }

  @computed
  get parentGroup(): Group | null {
    if (!this.groupId) {
      return null;
    }
    return this.groups.getGroupById(this.groupId);
  }

  get isSchedule(): boolean {
    return true;
  }

  get route(): string {
    return RouterPage.Schedule;
  }

  get canMentionAll(): boolean {
    return false;
  }

  get canBeScheduled(): boolean {
    return false;
  }
}
