import { IGroupCounterView, IGroupEvent, EventType } from 'APP/model/counter/counterModel.types';
import { GroupCounterView } from 'APP/packages/api/types/model/groupCounterView';
import { GroupEvent } from 'APP/packages/api/types/model/groupEvent';

export function mapCounterResponse(counter: GroupCounterView): IGroupCounterView {
  return {
    counter: Number(counter.counter),
    groupId: counter.groupId,
    lastEvent: counter.lastEvent ? mapGroupEvent(counter.lastEvent) : undefined,
    lastEventTs: counter.lastEventTs ? Number(counter.lastEventTs) : undefined,
    lastMessage: counter.lastMessage ? counter.lastMessage : undefined,
    lastMessageTs: counter.lastMessageTs ? Number(counter.lastMessageTs) : undefined,
    lastUserMentionTs: Number(counter.lastUserMentionTs),
    opponentSeenTs: counter.opponentSeenTs ? Number(counter.opponentSeenTs) : undefined,
    previousEventTs: counter.previousEventTs ? Number(counter.previousEventTs) : undefined,
    previousMessageTs: counter.previousMessageTs ? Number(counter.previousMessageTs) : undefined,
    seenTs: Number(counter.seenTs),
    totalMessagesCount: counter.totalMessagesCount ? Number(counter.totalMessagesCount) : undefined,
    unreadUserMentionsCounter: Number(counter.unreadUserMentionsCounter),
  };
}

export function mapGroupEvent(groupEvent: GroupEvent): IGroupEvent {
  return {
    ...groupEvent,
    type: groupEvent.type as EventType,
    ts: Number(groupEvent.ts),
  };
}
