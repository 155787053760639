import classNames from 'classnames';
import React, { FC } from 'react';

import styles from './Badge.styles.m.css';

const MAX_QUANTITY = 99;

interface IBadgeProps {
  className?: string;
  isMuted?: boolean;
  quantity: number | null;
}

export const Badge: FC<IBadgeProps> = ({ className, isMuted, quantity }) => {
  return (
    <span
      className={classNames(className, styles.badge, {
        [styles.isMuted]: isMuted,
      })}
    >
      {quantity !== null && (quantity > MAX_QUANTITY ? `${MAX_QUANTITY}+` : quantity)}
    </span>
  );
};
