import { useTranslation } from 'APP/packages/translations';

import {
  SpaceMarketLinkError,
  spaceMarketLinkValidate,
  spaceMarketLinkAsyncValidate,
} from '../fields/spaceMarketLinkValidate';
import { SpaceMarketNameError, spaceMarketNameValidate } from '../fields/spaceMarketNameValidate';
import asyncValidation from '../hooks/asyncValidation';
import syncValidation from '../hooks/syncValidation';

export function useCreateEditSpaceMarketValidations() {
  const { t } = useTranslation();

  const nameErrorToText = {
    [SpaceMarketNameError.IsEmpty]: ' ',
    [SpaceMarketNameError.TooShort]: t('market_name_min_length_error'),
  };

  const linkErrorToText = {
    [SpaceMarketLinkError.IsEmpty]: ' ',
    [SpaceMarketLinkError.Invalid]: t('market_link_invalid_error'),
  };

  const nameValidator = syncValidation();
  const linkValidator = asyncValidation();

  return {
    marketName: {
      validate: (value, _, meta) => {
        return nameValidator({
          value,
          meta,
          validate: spaceMarketNameValidate,
          errorToText: nameErrorToText,
        });
      },
    },
    marketLink: {
      validate: (value, _, meta) => {
        return linkValidator({
          value,
          meta,
          validate: spaceMarketLinkValidate,
          asyncValidate: spaceMarketLinkAsyncValidate,
          errorToText: linkErrorToText,
        });
      },
    },
  };
}
