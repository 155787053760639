import { useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import Entities from 'STORE';

export const usePresenter = (status, onSuccess) => {
  const presenter = useLocalStore(() => ({
    get channelId() {
      return Entities.Calls.ongoingCall?.channelId;
    },

    onClick: async () => {
      await Tasks.calls.updatePermissionRequestsForAll({
        channelId: presenter.channelId,
        status,
      });
      onSuccess();
    },
  }));

  return presenter;
};
