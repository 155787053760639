import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Carousel } from 'APP/components/Carousel/Carousel';
import { useTranslation } from 'APP/packages/translations';
import { Space } from 'STORE/Spaces/Space';

import { SpaceCollectionOnboarding } from '../SpaceCollectionOnboarding/SpaceCollectionOnboarding';
import { useSpaceCollectionListPresenter } from './SpaceCollectionsList.presenter';
import styles from './SpaceCollectionsList.styles.m.css';
import { SpaceCollectionsListItem } from './SpaceCollectionsListItem/SpaceCollectionsListItem';

export const ITEMS_SHOW_COUNT = 4;
export const ITEMS_GAP = 16;

interface ISpaceCollectionsProps {
  className?: string;
  space: Space;
}

export const SpaceCollectionsList: FC<ISpaceCollectionsProps> = observer(({ className, space }) => {
  const { t } = useTranslation();
  const presenter = useSpaceCollectionListPresenter(space);

  if (presenter.isShowOnboarding) {
    return (
      <SpaceCollectionOnboarding
        className={className}
        space={space}
      />
    );
  }

  if (presenter.isEmpty) {
    return null;
  }

  return (
    <div className={classNames(styles.container, className)}>
      <h3 className={styles.title}>{t('space_title_collection')}</h3>

      <Carousel
        className={styles.carousel}
        itemsToShow={ITEMS_SHOW_COUNT}
        gap={ITEMS_GAP}
      >
        {presenter.collections.map((collection) => (
          <SpaceCollectionsListItem
            key={collection.id}
            collection={collection}
            onClick={(): void => presenter.onCollectionSelect(collection)}
          />
        ))}
      </Carousel>
    </div>
  );
});
