import classNames from 'classnames';
import React, { FC, memo, ReactElement } from 'react';

import { ChatBackgroundPattern } from 'APP/constants/appearance';
import ChatBackgroundPattern10Image from 'APP/images/chat-background-pattern-10.svg';
import ChatBackgroundPattern20Image from 'APP/images/chat-background-pattern-20.svg';
import ChatBackgroundPattern30Image from 'APP/images/chat-background-pattern-30.svg';
import ChatBackgroundPattern40Image from 'APP/images/chat-background-pattern-40.svg';
import ChatBackgroundPattern50Image from 'APP/images/chat-background-pattern-50.svg';
import ChatBackgroundPattern60Image from 'APP/images/chat-background-pattern-60.svg';
import ChatBackgroundPattern70Image from 'APP/images/chat-background-pattern-70.svg';
import ChatBackgroundPattern80Image from 'APP/images/chat-background-pattern-80.svg';
import ChatBackgroundPattern90Image from 'APP/images/chat-background-pattern-90.svg';
import ChatBackgroundPattern100Image from 'APP/images/chat-background-pattern-100.svg';
import ChatBackgroundPattern110Image from 'APP/images/chat-background-pattern-110.svg';

import styles from './BackgroundPattern.styles.m.css';

export const CHAT_IMAGE_MAP: { [key in ChatBackgroundPattern]?: ReactElement } = {
  [ChatBackgroundPattern.Pattern10]: ChatBackgroundPattern10Image,
  [ChatBackgroundPattern.Pattern20]: ChatBackgroundPattern20Image,
  [ChatBackgroundPattern.Pattern30]: ChatBackgroundPattern30Image,
  [ChatBackgroundPattern.Pattern40]: ChatBackgroundPattern40Image,
  [ChatBackgroundPattern.Pattern50]: ChatBackgroundPattern50Image,
  [ChatBackgroundPattern.Pattern60]: ChatBackgroundPattern60Image,
  [ChatBackgroundPattern.Pattern70]: ChatBackgroundPattern70Image,
  [ChatBackgroundPattern.Pattern80]: ChatBackgroundPattern80Image,
  [ChatBackgroundPattern.Pattern90]: ChatBackgroundPattern90Image,
  [ChatBackgroundPattern.Pattern100]: ChatBackgroundPattern100Image,
  [ChatBackgroundPattern.Pattern110]: ChatBackgroundPattern110Image,
};

interface IBackgroundPatternProps {
  pattern: ChatBackgroundPattern;
  maskPosition?: string;
  maskSize?: string;
}

export const BackgroundPattern: FC<IBackgroundPatternProps> = memo(
  ({ pattern, maskPosition, maskSize }) => {
    const image = CHAT_IMAGE_MAP[pattern];

    return (
      <div className={classNames(styles.container, styles[pattern])}>
        {!!image && (
          <div
            className={styles.backgroundImage}
            style={{
              WebkitMaskImage: `url(${image})`,
              maskImage: `url(${image})`,
              maskPosition,
              maskSize,
            }}
          />
        )}
      </div>
    );
  }
);
