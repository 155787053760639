import { useAsObservableSource, useLocalStore } from 'mobx-react';

import Entities from 'STORE';
import { formatCounter } from 'UTILS/formatCounter';

import { moveToBottom } from './Tasks/moveToBottom/moveToBottom';

export const useScrollToBottomPresenter = (lastMessage) => {
  const source = useAsObservableSource({ lastMessage });
  const presenter = useLocalStore(() => ({
    get group() {
      return Entities.GroupsStore.activeGroup;
    },

    get hasScrollToBottomButton() {
      return (
        presenter.group.messagesStore.messages.length &&
        (!presenter.group.messagesStore.saveStatePosition.isBottom ||
          !presenter.group.messagesStore.isLoadedLastMessages ||
          presenter.group.messagesStore.lastMessage?.expectedServerTime >
            source.lastMessage?.expectedServerTime)
      );
    },

    get numberOfUnread() {
      if (!Entities.GroupsStore.activeGroup) {
        return '';
      }

      return Entities.GroupsStore.activeGroup.numberOfUnread;
    },

    moveToBottom: () => {
      moveToBottom();
    },

    get badgeCount() {
      if (presenter.numberOfUnread) {
        return formatCounter(presenter.numberOfUnread);
      }
      return '';
    },

    get hasBadge() {
      return presenter.numberOfUnread > 0;
    },
  }));

  return presenter;
};
