import { pushNotifications } from 'APP/packages/permissions/permissions';

export const getUserPermission = async (): Promise<string> => {
  const permission = pushNotifications.getCurrentPermission();

  if (permission === 'default') {
    return pushNotifications.requestPermission();
  }

  return permission;
};
