import { LINK_PREVIEW_MAX_SIZE } from 'APP/constants/messages';
import { useImageResizer } from 'APP/main/hooks/useImageResizer/useImageResizer';

const usePresenter = ({ data }) => {
  const { image, video, imageGraphicSize } = data;
  const isYouTubeVideo = video?.videoId && video?.type === 'youtube';
  const resizedImage = useImageResizer({
    url: image?.url,
    maxWidth: LINK_PREVIEW_MAX_SIZE.width,
    maxHeight: LINK_PREVIEW_MAX_SIZE.height,
    graphicSize: imageGraphicSize || image?.graphicSize || { width: 0, height: 0 },
  });

  return {
    resizedImage,
    isYouTubeVideo,
    isImagePreview: image?.url && !isYouTubeVideo,
  };
};

export default usePresenter;
