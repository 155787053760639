import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, ElementType, ReactElement } from 'react';

import { IGraphicSize } from 'APP/model/common/commonModel.types';
import { IconView, IconViewSizes } from 'APP/packages/UiKit/IconView/IconView';

import { LayoutImagePreview } from './LayoutImagePreview/LayoutImagePreview';
import { useMiniatureMessageLayoutPresenter } from './MiniatureMessageLayout.presenter';
import styles from './MiniatureMessageLayout.styles.m.css';

interface IMiniatureMessageLayoutProsp {
  className?: string;
  titleClassName?: string;
  textClassName?: string;
  iconClassName?: string;
  imagePreviewClassName?: string;
  imageUrl?: string;
  graphicSize?: IGraphicSize;
  Icon?: ElementType;
  title: string | ReactElement;
  text?: string | ReactElement;
  messageId: string;
  isVideo?: boolean;
  link?: string;
  onClick?(): void;
}

export const MiniatureMessageLayout: FC<IMiniatureMessageLayoutProsp> = observer((props) => {
  const {
    className,
    titleClassName,
    textClassName,
    iconClassName,
    imagePreviewClassName,
    imageUrl,
    graphicSize,
    Icon,
    title,
    text = '',
    messageId,
    isVideo = false,
    children,
    link,
    onClick,
  } = props;

  const presenter = useMiniatureMessageLayoutPresenter({ messageId, imageUrl, onClick });

  return (
    <div className={classNames(styles.container, className)}>
      {link ? (
        <a
          className={classNames(styles.focus, styles.link)}
          href={link}
          target="_blank"
          rel="noreferrer"
        >
          {link}
        </a>
      ) : (
        <button
          aria-label="scroll to message"
          className={styles.focus}
          onClick={presenter.focusMessage}
        />
      )}

      {presenter.hasImage && (
        <LayoutImagePreview
          className={imagePreviewClassName}
          isVideo={isVideo}
          imageUrl={imageUrl!}
          graphicSize={graphicSize}
        />
      )}

      <div className={styles.content}>
        <span className={classNames(styles.title, titleClassName)}>{title}</span>
        <div className={classNames(styles.subtitle, textClassName)}>
          {Icon && (
            <IconView
              Icon={Icon}
              size={IconViewSizes.Small}
              className={classNames(styles.icon, iconClassName)}
            />
          )}
          {children ? children : text}
        </div>
      </div>
    </div>
  );
});
