import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, ReactElement } from 'react';
import { FormSpy } from 'react-final-form';

import { IMAGE_TYPE } from 'APP/constants';
import { IUpdateUserDisplayData } from 'APP/model/userSettings/userSettingsModel.types';
import { Field } from 'APP/packages/form/Field/Field';
import { Form } from 'APP/packages/form/Form/Form';
import { IFormContentProps } from 'APP/packages/form/Form/Form.types';
import ImageUploader from 'APP/packages/form/ImageUploader';
import MultiLineField from 'APP/packages/form/MultiLineField';
import { TextField } from 'APP/packages/form/TextField/TextField';
import { useTranslation } from 'APP/packages/translations';
import { Avatar } from 'UIKIT/Avatar/Avatar';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';
import { Button } from 'UIKIT/Button/Button';
import { ButtonTypes } from 'UIKIT/Button/Button.types';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';
import { PopupFooter } from 'UIKIT/PopupFooter/PopupFooter';

import { useEditProfileViewPresenter } from './EditProfileView.presenter';
import styles from './EditProfileView.styles.m.css';
import { EditProfileBadge } from './components/EditProfileBadge/EditProfileBadge';
import { EditProfilePreview } from './components/EditProfilePreview/EditProfilePreview';

interface IEditProfileViewProps {
  onSave(): void;
  onOpenRewards(): void;
}

export const EditProfileView: FC<IEditProfileViewProps> = observer(({ onOpenRewards, onSave }) => {
  const { t } = useTranslation();
  const { presenter, bioRef } = useEditProfileViewPresenter(onSave);

  return (
    <Form<IUpdateUserDisplayData>
      onSubmit={presenter.onSubmit}
      initialValues={presenter.initialValues}
    >
      {({ handleSubmit }: IFormContentProps): ReactElement => (
        <form
          onSubmit={handleSubmit}
          className={styles.container}
        >
          <div className={classNames(styles.content, customScrollStyles.container)}>
            <Field
              className={styles.avatar}
              name="avatarUrl"
              component={ImageUploader}
              imageType={IMAGE_TYPE.ROUND}
              customNoImage={
                <Avatar
                  type={AvatarTypes.Round_40}
                  url=""
                  title={presenter.avatarTitle}
                  backgroundColorIndex={presenter.avatarColorIndex}
                />
              }
            />

            <Field
              isNeedTrim
              className={styles.field}
              name="userName"
              label={t('profile_username_hint')}
              component={TextField}
              maxLength={50}
              validate={presenter.usernameValidate}
            />
            <Field
              className={styles.field}
              name="nickName"
              label={t('profile_nickname_hint')}
              component={TextField}
              maxLength={35}
              validate={presenter.nicknameValidate}
              helperText={t('nickanme_hint')}
              validatingText={t('checking_nickname')}
              successText={t('nickname_is_available')}
            />
            <Field
              setRef={bioRef}
              className={styles.multilineField}
              name="bio"
              label={t('common_about_optional')}
              component={MultiLineField}
              validate={presenter.bioValidate}
              helperText={t('common_about_hint')}
            />

            <EditProfileBadge onOpenRewards={onOpenRewards} />
            <EditProfilePreview isVerified={presenter.isVerified} />
          </div>

          <PopupFooter>
            <FormSpy
              subscription={{
                pristine: true,
                invalid: true,
                validating: true,
              }}
            >
              {({ pristine, invalid, validating }): ReactElement => (
                <Button
                  type={ButtonTypes.Submit}
                  isDisabled={pristine || invalid || validating}
                  theme="1"
                >
                  {t('profile_save_changes')}
                </Button>
              )}
            </FormSpy>
          </PopupFooter>

          <FormSpy onChange={presenter.onChange} />
        </form>
      )}
    </Form>
  );
});
