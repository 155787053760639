import { useLocalStore } from 'mobx-react';

import { ICallProviderPublisher } from 'APP/packages/callProviders/callProviders.types';
import Entities from 'APP/store';
import { Call } from 'STORE/Calls/Call/Call';

interface IOwnVideoPresenter {
  call: Call;
  publisher: ICallProviderPublisher | null;
}

export const useOwnVideoPresenter = (): IOwnVideoPresenter => {
  const presenter = useLocalStore<IOwnVideoPresenter>(() => ({
    get call(): Call {
      return Entities.Calls.ongoingCall!;
    },

    get publisher(): ICallProviderPublisher | null {
      return (
        (!presenter.call.backgroundPanelShown &&
          presenter.call.me.publisher.hasVideo &&
          presenter.call.me.publisher) ||
        null
      );
    },
  }));

  return presenter;
};
