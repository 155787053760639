import { DeleteMessagesEventPayloadResponse } from 'APP/packages/api/types/model/deleteMessagesEventPayloadResponse';

import { deleteGroupMessages } from '../../helpers/deleteGroupMessages';
import { GroupsEvent } from '../GroupsEvent';

export class MessageWereDeleted extends GroupsEvent<DeleteMessagesEventPayloadResponse> {
  process(): void {
    const { messageIds, groupId } = this.eventPayload || {};

    if (!groupId || !messageIds) {
      return;
    }

    deleteGroupMessages(groupId, messageIds);
  }
}
