import { closeMediaGallery } from 'APP/Tasks/group/mediaGallery/closeMediaGallery';
import { openMediaGallery } from 'APP/Tasks/group/mediaGallery/openMediaGallery';
import { updateMediaGallery } from 'APP/Tasks/group/mediaGallery/updateMediaGallery';

import { addCounters } from './addCounters/addCounters';
import { addGroupAdmins } from './addGroupAdmins/addGroupAdmins';
import { checkSendMessagePermissions } from './checkSendMessagePermissions/checkSendMessagePermissions';
import { clearHistory } from './clearHistory/clearHistory';
import { createGroupAndInvite } from './createGroupAndInvite';
import { createP2PGroup } from './createP2PGroup/createP2PGroup';
import deleteGroup from './deleteGroup';
import { getAvailableChannels } from './getAvailableChannels/getAvailableChannels';
import getFriendsNotInvitedToGroup from './getFriendsNotInvitedToGroup';
import { getGroupUserRoles } from './getGroupUserRoles/getGroupUserRoles';
import { goToSavedMessages } from './goToSavedMessages/goToSavedMessages';
import goToScheduleSettings from './goToScheduleSettings';
import { initGroups } from './initGroups/initGroups';
import { joinGroup } from './joinGroup';
import { leaveGroup } from './leaveGroup/leaveGroup';
import loadGroupsByIds from './loadGroupsByIds';
import { loadNewCallGroup } from './loadNewCallGroup';
import { loadScheduledMessages } from './loadScheduledMessages/loadScheduledMessages';
import { markGroupsAsUnread } from './markGroupsAsUnread/markGroupsAsUnread';
import { addParticipants } from './participants/addParticipants';
import { banParticipants } from './participants/banParticipants';
import { getParticipants } from './participants/getParticipants';
import { removeParticipants } from './participants/removeParticipants';
import { transferOwnership } from './participants/transferOwnership';
import pinGroup from './pinGroup';
import { removeGroupAdmins } from './removeGroupAdmins/removeGroupAdmins';
import { removeGroupLocally } from './removeGroupLocally/removeGroupLocally';
import { setActiveGroup } from './setActiveGroup/setActiveGroup';
import { showContent } from './showContent/showContent';
import unpinGroup from './unpinGroup';
import { updateDraftInput } from './updateDraftInput/updateDraftInput';
import { updateGroupInfo } from './updateGroupInfo/updateGroupInfo';
import updateUserGroupRole from './updateUserGroupRole';

export default {
  addCounters,
  createGroupAndInvite,
  getFriendsNotInvitedToGroup,
  initGroups,
  loadGroupsByIds,
  removeGroupLocally,
  setActiveGroup,
  updateGroupInfo,
  updateUserGroupRole,
  joinGroup,
  getGroupUserRoles,
  pinGroup,
  unpinGroup,
  markGroupsAsUnread,
  updateDraftInput,
  createP2PGroup,
  clearHistory,
  deleteGroup,
  leaveGroup,
  goToScheduleSettings,
  openMediaGallery,
  updateMediaGallery,
  closeMediaGallery,
  banParticipants,
  loadNewCallGroup,
  goToSavedMessages,
  showContent,
  checkSendMessagePermissions,
  getAvailableChannels,
  getParticipants,
  transferOwnership,
  addGroupAdmins,
  addParticipants,
  removeGroupAdmins,
  removeParticipants,
  loadScheduledMessages,
};
