import { observer } from 'mobx-react';
import React from 'react';

import { useTranslation } from 'APP/packages/translations';
import { Loader } from 'UIKIT/Loader/Loader';

import usePresenter from './presenter';
import styles from './styles.m.scss';

const OfflineStatus = ({ children }) => {
  const presenter = usePresenter();
  const { t } = useTranslation();

  if (!presenter.isOfflineNetworkStatus) {
    return children || null;
  }

  return (
    <span className={styles.container}>
      <Loader
        className={styles.loader}
        color="secondary"
      />
      {t('common_no_internet')}
    </span>
  );
};

export default observer(OfflineStatus);
