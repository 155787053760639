import { request } from '../request/request';

const ROOT_API = 'bot';

export const getBotCommands = (data) => {
  return request({
    rootApi: ROOT_API,
    url: '/getBotCommands',
    method: 'POST',
    body: data,
  });
};
