import api from 'APP/packages/api';
import logger from 'APP/packages/logger';

export const unregisterNotifications = async (tokenType: string): Promise<void> => {
  try {
    await api.push.unregisterForPushNotifications({
      type: tokenType,
      platform: 'desktop',
    });

    logger
      .get('Push Notifications')
      .info(`${tokenType} - push notification service was unregistered on the server`);
  } catch (error) {
    logger
      .get('Push Notifications')
      .error(`${tokenType} - push notification service was not unregistered on the server`, error);
  }
};
