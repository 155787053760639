import { observer } from 'mobx-react';
import React from 'react';

import { useTranslation } from 'APP/packages/translations';
import StopIcon from 'ICONS/ic-stop.svg';
import { FAB } from 'UIKIT/FAB/FAB';

import usePresenter from './presenter';
import styles from './styles.m.scss';

const RecordingSection = ({ className }) => {
  const { t } = useTranslation();
  const presenter = usePresenter();

  return (
    <div className={className}>
      <FAB
        className={styles.iconStop}
        Icon={StopIcon}
        onClick={presenter.stop}
        size="10"
        theme="1"
      />
      <span className={styles.text}>{t('voice_record_in_progress')}...</span>
      <span className={styles.record} />
      <span className={styles.timer}>{presenter.time}</span>
    </div>
  );
};

export default observer(RecordingSection);
