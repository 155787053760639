import { useAsObservableSource, useLocalStore } from 'mobx-react';

import { addToContact } from 'APP/model/contact/contactModel';
import { markAsUnknown } from 'APP/model/user/userModel';
import Entities from 'APP/store';
import { Group } from 'STORE/Groups/Group';
import type { User } from 'STORE/Users/User/User';

interface IUnknownUserPresenter {
  group: Group | null;
  opponentId: string | null;
  user: User | null;
  isShowUnknownUserBanner: boolean;
  onClose(): Promise<void>;
  onAddToContact(): Promise<void>;
}

export const useUnknownUserPresenter = (groupId: string): IUnknownUserPresenter => {
  const source = useAsObservableSource({ groupId });

  const presenter = useLocalStore<IUnknownUserPresenter>(() => ({
    get group(): Group | null {
      return Entities.GroupsStore.getGroupById(source.groupId) || null;
    },

    get opponentId(): string | null {
      return presenter.group?.opponentId || null;
    },

    get user(): User | null {
      return Entities.UsersStore.getUserById(presenter.opponentId) || null;
    },

    get isShowUnknownUserBanner(): boolean {
      return Boolean(presenter.user?.isUnknown);
    },

    async onAddToContact(): Promise<void> {
      if (!presenter.user) {
        return;
      }

      try {
        await addToContact({
          added: [
            {
              contactName: presenter.user.displayName,
              contactId: presenter.user.id,
            },
          ],
          last: true,
        });
      } catch (err: any) {
        //
      }
      presenter.user.setIsUnknown(false);
    },

    async onClose(): Promise<void> {
      if (!presenter.user) return;

      presenter.user.setIsUnknown(false);

      try {
        await markAsUnknown({ userId: presenter.user.id, isUnknown: false });
      } catch (err: any) {
        //
      }
    },
  }));

  return presenter;
};
