import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { MiniatureMessage } from 'APP/main/ChatView/components/MiniatureMessage/MiniatureMessage';
import { IconView } from 'APP/packages/UiKit/IconView/IconView';
import { AddKeyPressHandler } from 'APP/packages/keyBoardHandler';
import IcClose from 'ICONS/ic-close-16.svg';
import IcEdit from 'ICONS/ic-new-chat.svg';
import { FAB } from 'UIKIT/FAB/FAB';

import { useEditMessagePresenter } from './EditMessage.presenter';
import styles from './EditMessage.styles.m.css';

interface IEditMessageProps {
  className?: string;
}

export const EditMessage: FC<IEditMessageProps> = observer(({ className }) => {
  const presenter = useEditMessagePresenter();

  if (!presenter.message) {
    return null;
  }

  return (
    <div className={className}>
      <AddKeyPressHandler onEsc={presenter.cancel} />
      <IconView
        Icon={IcEdit}
        className={styles.icon}
      />
      <div className={styles.miniature}>
        <MiniatureMessage
          message={presenter.message}
          title={<p className={styles.title}>{presenter.message?.avatarTitle || ''}</p>}
        />
      </div>

      <FAB
        Icon={IcClose}
        size="10"
        iconSize="0"
        theme="12"
        onClick={presenter.cancel}
        className={styles.remove}
      />
    </div>
  );
});
