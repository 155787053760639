import { action, computed, observable, values } from 'mobx';

import SearchService from 'SERVICES/search';

import { Bot } from './User/Bot/Bot';
import { Me } from './User/Me/Me';
import { User } from './User/User';

class UsersStore {
  @observable data = new Map();
  @observable onlineStatuses = new Map();
  @observable userContacts = [];
  @observable isLoadedContacts = false;
  @observable myId = null;
  @observable isUserContactsLoading = false;

  @action
  merge = (data) => {
    this.data.merge(data);
  };

  @action
  mergeOnlineStatuses = (data) => {
    this.onlineStatuses.merge(data);
  };

  @action
  resetOnlineStatuses = () => {
    this.onlineStatuses.clear();
  };

  @action
  reset = () => {
    this.data.clear();
    this.onlineStatuses.clear();
    this.userContacts = [];
    this.isLoadedContacts = false;
    this.myId = null;
  };

  getUserById = (id) => {
    return this.data.get(id) || null;
  };

  getUserByNickName(nickName) {
    return Array.from(this.data.values()).find(
      (storeUser) => storeUser.nickName.trim() === nickName.trim()
    );
  }

  getOnlineStatusByUserId = (userId) => {
    return this.onlineStatuses.get(userId);
  };

  @action
  setIsUserContactsLoading(isUserContactsLoading) {
    this.isUserContactsLoading = isUserContactsLoading;
  }

  @action
  setContacts(contacts) {
    this.userContacts = contacts.map((contact) => {
      const existingContact = this.userContacts.find(({ id }) => id === contact.id);
      return existingContact || { id: contact.id, updateTs: Date.now() };
    });
  }

  @action
  addContact(newContactId) {
    const isContactExistsInStore = this.userContacts.some(({ id }) => id === newContactId);

    if (!isContactExistsInStore) {
      this.userContacts.push({ id: newContactId, updateTs: Date.now() });
    } else {
      const contactIndex = this.userContacts.findIndex(({ id }) => id === newContactId);
      this.userContacts[contactIndex].updateTs = Date.now();
    }
  }

  @action
  setLoadedContacts = (isLoadedContacts) => {
    this.isLoadedContacts = isLoadedContacts;
  };

  @action
  addMe(data) {
    const prevMe = this.data.get(data.id);
    const me = new Me(this, data);

    if (prevMe?.accountProviders.length) {
      me.setAccountProviders(prevMe.accountProviders);
    }

    this.myId = me.id;
    this.data.set(me.id, me);
  }

  @action
  add(newUsers) {
    const newUsersMap = newUsers.map((user) => {
      if (user.id && parseInt(user.id, 10) < 0) {
        return [user.id, new Bot(this, user)];
      }

      if (user.id === this.myId) {
        return [user.id, new Me(this, user)];
      }

      return [user.id, new User(this, user)];
    });

    this.merge(newUsersMap);
  }

  @action
  delete(userId) {
    this.data.delete(userId);
  }

  @computed
  get Me() {
    return this.data.get(this.myId) || {};
  }

  @computed
  get users() {
    return values(this.data);
  }

  @computed
  get contacts() {
    return this.users
      .filter((contact) => contact.isInPhoneBook && !contact.isMe && !contact.isBlocked)
      .sort((cn1, cn2) => (cn1.nameToSort > cn2.nameToSort ? 1 : -1));
  }

  @computed
  get hasContacts() {
    return this.contacts.length > 0;
  }

  getFilteredContacts(excludedChats, excludedUsers, searchQuery) {
    const contacts = this.contacts.filter(
      (contact) =>
        !excludedChats.includes(contact.id) &&
        !excludedUsers.includes(contact.id) &&
        !contact.isBlockedByMe &&
        !contact.isBlockedMe
    );

    if (!searchQuery || !searchQuery.length) {
      return contacts;
    }

    return contacts
      .filter((contact) => {
        return SearchService.searchInContactData(contact, searchQuery);
      })
      .sort((a, b) => {
        return a.nameToSort > b.nameToSort ? 1 : -1;
      });
  }

  searchContactsByString = (searchString) => {
    const contacts = this.contacts;

    if (searchString.length) {
      return contacts.filter((contact) => {
        const { contactName, userName, nickName, phone, displayPhone } = contact;

        return (
          this.getSearchCondition(contactName, searchString) ||
          this.getSearchCondition(userName, searchString) ||
          this.getSearchCondition(nickName, searchString) ||
          this.getSearchCondition(phone, searchString.replace('+', '')) ||
          this.getSearchCondition(displayPhone, searchString.replace('+', ''))
        );
      });
    }

    return contacts;
  };

  // TODO: move to userService?
  getSearchCondition = (field, searchString) => {
    return field
      ? field.trim().toLowerCase().indexOf(searchString.toLowerCase().trim()) !== -1
      : false;
  };
}

export default UsersStore;
