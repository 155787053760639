import Tasks from 'APP/Tasks';
import { GroupType } from 'APP/model/group/groupModel.types';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';
import getAvatarUrlByType from 'UTILS/getAvatarUrlByType';

const groupTypeToAvatarType = {
  [GroupType.My]: AvatarTypes.Round_10,
  [GroupType.P2P]: AvatarTypes.Round_10,
  [GroupType.Open]: AvatarTypes.Round_10,
  [GroupType.Closed]: AvatarTypes.Round_10,
  [GroupType.ChannelOpen]: AvatarTypes.Square_20,
  [GroupType.ChannelClosed]: AvatarTypes.Square_20,
  [GroupType.MySavedMessages]: AvatarTypes.Round_10,
  [GroupType.SavedMessagesFake]: AvatarTypes.Round_10,
};

export default async (groups) => {
  const promises = groups.reduce((memo, group) => {
    const url = group.groupResponse.avatarUrl;

    if (!url) {
      return memo;
    }

    const type = groupTypeToAvatarType[group.groupResponse.type];

    if (!type) {
      return memo;
    }

    const resizedAvatarUrl = getAvatarUrlByType(type, url);
    const promise = Tasks.app.preloadImage(resizedAvatarUrl);
    memo.push(promise);

    return memo;
  }, []);

  await Promise.all(promises);
};
