import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { useTranslation } from 'APP/packages/translations';
import { useCloseFormPopup } from 'MAIN/PopupManager/hooks/useCloseFormPopup';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { SpaceCollectionForm } from '../../components/SpaceCollectionForm/SpaceCollectionForm';
import {
  AddToSpaceCollectionStep,
  IAddToSpaceCollectionPopupPresenterParams,
  useAddToSpaceCollectionPopupPresenter,
} from './AddToSpaceCollectionPopup.presenter';
import { SpaceCollectionList } from './SpaceCollectionList/SpaceCollectionList';

type IAddToSpaceCollectionPopupProps = IAddToSpaceCollectionPopupPresenterParams;

export const AddToSpaceCollectionPopup: FC<ICommonPopupsProps<IAddToSpaceCollectionPopupProps>> =
  observer((props) => {
    const { t } = useTranslation();
    const { groupId, messageId } = props.params!;
    const presenter = useAddToSpaceCollectionPopupPresenter(props);
    const closeForm = useCloseFormPopup(props, t('common_cancel_action_confirmation'));

    return (
      <Popup
        title={
          presenter.step === AddToSpaceCollectionStep.CollectionList
            ? t('space_add_to_collection')
            : t('space_new_collection_form')
        }
        handlePrevStep={
          presenter.step === AddToSpaceCollectionStep.CreateNewCollection
            ? presenter.onShowCollectionListStep
            : undefined
        }
        isOpened={!!presenter.space}
        onClose={closeForm.closePopup}
      >
        {presenter.space && (
          <>
            {presenter.step === AddToSpaceCollectionStep.CollectionList && (
              <SpaceCollectionList
                space={presenter.space}
                groupId={groupId}
                messageId={messageId}
                onCreateNewCollectionStep={presenter.onCreateNewCollectionStep}
                onSelectCollection={presenter.onSelectCollection}
              />
            )}
            {presenter.step === AddToSpaceCollectionStep.CreateNewCollection && (
              <SpaceCollectionForm
                onFormStateChange={closeForm.onFormStateChange}
                onSubmit={presenter.onCollectionCreate}
              />
            )}
          </>
        )}
      </Popup>
    );
  });
