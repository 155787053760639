import { searchModel } from 'APP/model/model';
import { SpaceUserRole } from 'APP/model/space/spaceModel.types';
import Entities from 'APP/store';
import { groupClassesMap } from 'STORE/Groups';
import { Group } from 'STORE/Groups/Group';
import { Space } from 'STORE/Spaces/Space';
import { User } from 'STORE/Users/User/User';

const GLOBAL_MIN_STRING_LENGTH = 3;
const SEARCH_RESULTS_OFFSET = 15;

const emptyResult = {
  groups: [],
  users: [],
  spaces: [],
  hasMoreResults: false,
};

export interface IGlobalSearchResult {
  users: User[];
  groups: Group[];
  spaces: Space[];
  hasMoreResults: boolean;
}

export async function getGlobalSearchResults(
  searchValue: string,
  offset: number
): Promise<IGlobalSearchResult> {
  if (searchValue.length < GLOBAL_MIN_STRING_LENGTH) {
    return emptyResult;
  }

  try {
    const {
      groups: groupsData,
      users: usersData,
      spaces: spacesData,
      hasMoreResults,
    } = await searchModel.globalSearch({
      query: searchValue,
      limit: SEARCH_RESULTS_OFFSET,
      offset,
    });

    const users = usersData
      .map((user) => new User(Entities.UsersStore, user))
      .filter((user) => !user.isMe);

    const groups = groupsData
      .map((group) => {
        const GroupClass = groupClassesMap[group.type];

        return GroupClass ? new GroupClass(group, Entities.GroupsStore, Entities) : null;
      })
      .filter((group) => group !== null) as Group[];

    const spaces = spacesData.map(
      (space) =>
        new Space({
          space,
          role: SpaceUserRole.Guest,
        })
    );

    return { users, groups, spaces, hasMoreResults };
  } catch {
    return emptyResult;
  }
}
