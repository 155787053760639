import { useAsObservableSource, useLocalStore } from 'mobx-react';

import { SendMessageStatus } from 'APP/model/message/messageModel.types';
import Entities from 'APP/store';
import { Group } from 'STORE/Groups/Group';

interface IGroupStatusIconPresenter {
  status: SendMessageStatus | null;
}

export const useGroupStatusIconPresenter = (group: Group): IGroupStatusIconPresenter => {
  const source = useAsObservableSource({ group });

  const presenter = useLocalStore<IGroupStatusIconPresenter>(() => ({
    get status(): SendMessageStatus | null {
      const message = source.group.lastMessage;

      if (message && message.senderId === Entities.UsersStore.Me.id) {
        if (message.isFailed) return SendMessageStatus.Failed;
        if (message.isSending) return SendMessageStatus.Sending;

        if (message.isP2PGroup) {
          if (message.isSeen) return SendMessageStatus.Seen;
          if (message.isSent) return SendMessageStatus.Sent;
        }
      }
      return null;
    },
  }));

  return presenter;
};
