import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import Article from './Article';
import { ArticlePreview } from './ArticlePreview/ArticlePreview';
import BlackList from './BlackList/BlackList';
import { Monetization } from './Monetization';

const ContextPopups = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.url}/monetization`}>
        <Monetization />
      </Route>
      <Route path={`${match.url}/blacklist`}>
        <BlackList />
      </Route>
      <Route path={`${match.url}/article-preview/:id`}>
        <ArticlePreview groupId={match.params.id} />
      </Route>
      <Route
        exact
        path={`${match.url}/article`}
      >
        <Article groupId={match.params.id} />
      </Route>
      <Route path={`${match.url}/article/:id`}>
        <Article groupId={match.params.id} />
      </Route>

      {/* //not redirect if url is equal to math.url for deep link cases for saving state */}
      <Route
        exact
        path={match.url}
      />
      <Route>
        {/* //TODO: in ideal word that decision should be made on higher level  */}
        {/* //redirects all wrong urls to the chat url */}
        <Redirect to={match.url} />
      </Route>
    </Switch>
  );
};

export default ContextPopups;
