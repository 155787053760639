import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { MainInfoBlock } from 'APP/main/MainInfoBlock/MainInfoBlock';
import { AddKeyPressHandler } from 'APP/packages/keyBoardHandler';
import { BlurChat } from 'MAIN/ChatView/BlurChat/BlurChat';
import { ChatUpdateHandler } from 'MAIN/ChatView/ChatUpdateHandler/ChatUpdateHandler';
import { ChatBackground } from 'MAIN/components/ChatBackground/ChatBackground';

import { ChatDragDrop } from './ChatDragDrop/ChatDragDrop';
import { ChatFooter } from './ChatFooter/ChatFooter';
import { ChatHeader } from './ChatHeader/ChatHeader';
import { useChatViewPresenter } from './ChatView.presenter';
import styles from './ChatView.styles.m.css';
import ContextPopups from './ContextPopups';
import { MessagePin } from './MessagePin/MessagePin';
import { Messages } from './Messages/Messages';
import RightSidebar from './RightSidebar';
import { SearchMessages } from './SearchMessages/SearchMessages';
import { UnknownUser } from './UnknownUser/UnknownUser';

export const ChatView: FC = observer(() => {
  const presenter = useChatViewPresenter();

  if (!presenter.group) {
    return <MainInfoBlock />;
  }

  return (
    <>
      <div
        className={styles.container}
        data-test="chat"
      >
        <AddKeyPressHandler onEsc={presenter.onClose} />
        <ChatBackground />
        <ChatHeader />
        <UnknownUser groupId={presenter.group.id} />

        <ChatDragDrop
          className={styles.content}
          isAllowDrop={presenter.isAllowPostMessage}
          onDropFiles={presenter.onDropFiles}
        >
          <>
            <MessagePin />
            <Messages
              key={presenter.group.id}
              groupId={presenter.group.id}
            />
            <BlurChat groupId={presenter.group.id} />
          </>
        </ChatDragDrop>

        <ChatFooter isAllowPostMessage={presenter.isAllowPostMessage} />
      </div>
      <ChatUpdateHandler />
      <SearchMessages />
      <RightSidebar />
      <ContextPopups />
    </>
  );
});
