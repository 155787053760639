import React from 'react';

import { IconView } from 'APP/packages/UiKit/IconView/IconView';
import { useTranslation } from 'APP/packages/translations';
import IcShared from 'ICONS/ic-shared.svg';

import styles from './styles.m.css';

const SharedScreen = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <IconView
        Icon={IcShared}
        className={styles.icon}
        size="auto"
      />
      {t('sharing_screen')}
    </div>
  );
};

export default SharedScreen;
