import React from 'react';

import { useTranslation } from 'APP/packages/translations';
import RaiseHand from 'ICONS/ic-has_raise_hand.svg';
import { Member } from 'STORE/Calls/Call/Member/Member';
import { Avatar } from 'UIKIT/Avatar/Avatar';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';
import { IconView, IconViewSizes } from 'UIKIT/IconView/IconView';

import styles from './RiseHand.styles.m.css';

interface IRiseHandProps {
  member?: Member;
}

export const RiseHand: React.FC<IRiseHandProps> = ({ member }) => {
  const { t } = useTranslation();

  if (!member) {
    return null;
  }

  const description = t('calls_raise_hand_event_description', { 0: member.displayName });

  return (
    <div className={styles.riseHandContainer}>
      <IconView
        Icon={RaiseHand}
        size={IconViewSizes.Medium}
        className={styles.riseHandIcon}
      />
      <Avatar
        className={styles.avatar}
        url={member.avatarUrl}
        title={member.displayName}
        backgroundColorIndex={member.avatarColorIndex}
        type={AvatarTypes.Round_05}
      />
      {description}
    </div>
  );
};
