import React, { memo } from 'react';

import { IconView } from 'APP/packages/UiKit/IconView/IconView';
import IcPlay16 from 'ICONS/ic-play-16.svg';

import SelectedText from '../../SelectedText';
import styles from './styles.m.scss';

const Video = ({ message }) => {
  return (
    <>
      <IconView
        Icon={IcPlay16}
        size="small"
        className={styles.icon}
      />
      <SelectedText {...message.textChunk} />
    </>
  );
};

export default memo(Video);
