import { runInAction } from 'mobx';

import { IClientConfigProperty } from 'APP/model/auth/authModel.types';
import logger from 'APP/packages/logger';
import Entities from 'APP/store';

export function handleUserPreferences(property: IClientConfigProperty): void {
  try {
    const { categoryIds = [], locales = [], userPreferencesSelected } = JSON.parse(property.value);
    runInAction(() => {
      Entities.userPreferences.setCategoryIds(categoryIds);
      Entities.userPreferences.setLocaleIds(locales);
      Entities.userPreferences.setUserPreferencesSelected(userPreferencesSelected);
    });
  } catch (error) {
    logger.get('Tasks').error('login.clientConfig.userPreferences', error);
  }
}
