import { observer } from 'mobx-react';
import React from 'react';

import { MediaPreview } from 'APP/components/MediaPreview/MediaPreview';
import { MEDIA_PREVIEW_MAX_SIZE } from 'APP/constants/newsfeed';
import PlayIcon from 'ICONS/ic-play-32.svg';

import styles from './PostAlbum.styles.m.css';

export const PostAlbumVideo = observer((props) => {
  const { groupId, payload, mediaId, isPreview, isView, onClick } = props;

  if (!payload?.previewUrl) {
    return null;
  }

  const onOpenGallery = () => {
    onClick(mediaId);
  };

  return (
    <div className={styles.previewContainer}>
      <MediaPreview
        className={styles.preview}
        payload={payload}
        maxWidth={MEDIA_PREVIEW_MAX_SIZE.width}
        maxHeight={MEDIA_PREVIEW_MAX_SIZE.height}
        isPreview={isPreview}
        isView={isView}
        onClick={onOpenGallery}
        payloadId={mediaId}
        groupId={groupId}
      />

      <div className={styles.playButton}>
        <PlayIcon
          className={styles.playIcon}
          onClick={onOpenGallery}
        />
      </div>
    </div>
  );
});
