import { GroupType } from 'APP/model/group/groupModel.types';
import api from 'APP/packages/api';

const ERRORS = {
  IS_EMPTY: 'IS_EMPTY',
  TOO_SHORT: 'TOO_SHORT',
  INCORECT_SYMBOLS: 'INCORECT_SYMBOLS',
  IS_ALREADY_EXIST: 'IS_ALREADY_EXIST',
};

const MIN_FIELD_CHANNEL_ALIAS_LENGTH = 2;
const CHANNEL_ALIAS_REGEXP = /^[a-zA-Z0-9-_]+$/;

const validateCreate = ({ value, values, errorToText }) => {
  if (values.type !== GroupType.ChannelOpen) {
    return null;
  }

  if (value?.length > 0 && value?.length < MIN_FIELD_CHANNEL_ALIAS_LENGTH) {
    return errorToText[ERRORS.TOO_SHORT];
  }

  if (!CHANNEL_ALIAS_REGEXP.test(value)) {
    return errorToText[ERRORS.INCORECT_SYMBOLS];
  }

  return null;
};

const validateEdit = ({ value, values, errorToText }) => {
  if (values.type !== GroupType.ChannelOpen) {
    return null;
  }

  const createError = validateCreate({ value, values, errorToText });

  if (createError) {
    return createError;
  }

  if (!value || value.length === 0) {
    return errorToText[ERRORS.IS_EMPTY];
  }

  return null;
};

const asyncValidate = async ({ value, values, errorToText }) => {
  if (values.type !== GroupType.ChannelOpen) {
    return null;
  }

  try {
    const response = await api.group.deepLinkExists({
      deepLinkAlias: value,
    });

    if (response?.result === false) {
      return null;
    }
  } catch (error) {
    console.error(error);
  }

  return errorToText[ERRORS.IS_ALREADY_EXIST];
};

export default {
  validateCreate,
  validateEdit,
  asyncValidate,
  ERRORS,
};
