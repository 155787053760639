import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import DotLoader from './DotLoader';
import usePresenter from './presenter';
import styles from './styles.m.css';

const GroupStatus = ({ group, children, className = undefined }) => {
  const presenter = usePresenter({ group });

  if (presenter.status) {
    return (
      <div className={classNames(styles.container, className)}>
        <DotLoader className={styles.loader} />
        <span className={styles.status}>{presenter.status}</span>
      </div>
    );
  }

  return children;
};

export default observer(GroupStatus);
