const TAGS_FOR_REMOVE = ['*', '**', '***', '~~', '__', '```', '[', ']'];

export default ({ text, positionToTag }) => {
  const tags = Object.values(positionToTag);

  let startPosition = 0;
  let updatedText = '';

  for (let i = 0; i < tags.length; i++) {
    const item = tags[i];

    // replace only tag, no mention, bot commands
    if (!TAGS_FOR_REMOVE.includes(item.tag)) {
      continue;
    }

    if (startPosition < item.start) {
      const substring = text.substring(startPosition, item.start);
      updatedText += substring;
    }

    startPosition = item.end;
  }

  if (startPosition < text.length) {
    const substring = text.substring(startPosition);
    updatedText += substring;
  }

  return updatedText;
};
