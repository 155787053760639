import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import NoFoundIcon from 'APP/images/gem-mascot-not-found.svg';
import ShopIcon from 'APP/images/gem-mascot-online-shop.svg';
import { AddKeyPressHandler } from 'APP/packages/keyBoardHandler';
import { MarketPlaceholder } from 'APP/packages/placeholders/MarketPlaceholder/MarketPlaceholder';
import { useTranslation } from 'APP/packages/translations';
import { ChatBackground } from 'MAIN/components/ChatBackground/ChatBackground';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';
import { InfoBlock, InfoBlockTypes } from 'UIKIT/InfoBlock/InfoBlock';

import { useSpaceMarketPresenter } from './SpaceMarket.presenter';
import styles from './SpaceMarket.styles.m.css';
import { SpaceMarketHeader } from './SpaceMarketHeader/SpaceMarketHeader';
import { SpaceMarketItem } from './SpaceMarketItem/SpaceMarketItem';

interface ISpaceMarketProps {
  spaceId?: string;
}

export const SpaceMarket: FC<ISpaceMarketProps> = observer(({ spaceId }) => {
  const { t } = useTranslation();
  const presenter = useSpaceMarketPresenter(spaceId);

  return (
    <div className={styles.container}>
      <AddKeyPressHandler onEsc={presenter.onClose} />

      <ChatBackground />

      <SpaceMarketHeader
        showCreateMarketButton={presenter.showCreateMarketButton}
        onOpenAddMarket={presenter.onAddMarket}
      />

      {presenter.showPlaceholder && <MarketPlaceholder />}

      {presenter.hasMarketList && (
        <div className={classNames(styles.list, customScrollStyles.container)}>
          {presenter.markets.map((market) => (
            <SpaceMarketItem
              key={market.id}
              market={market}
              showActions={presenter.isOwner}
              onEdit={presenter.onEditMarket}
              onDelete={presenter.onDeleteMarket}
            ></SpaceMarketItem>
          ))}
        </div>
      )}

      {presenter.showCreateMarketInfo && (
        <InfoBlock
          image={ShopIcon}
          title={t('market_tab_header')}
          descriptions={[t('market_tab_description')]}
          type={presenter.hasBackground ? InfoBlockTypes.ChatWithPattern : InfoBlockTypes.Chat}
          buttonTitle={t('add_button')}
          onClick={presenter.onAddMarket}
        />
      )}

      {presenter.showEmptyInfo && (
        <InfoBlock
          image={NoFoundIcon}
          descriptions={[t('space_store_link_is_not_accessible')]}
          type={presenter.hasBackground ? InfoBlockTypes.ChatWithPattern : InfoBlockTypes.Chat}
          buttonTitle={t('add_button')}
        />
      )}
    </div>
  );
});
