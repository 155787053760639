import { useLocalStore } from 'mobx-react';

import routerConst from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import Entities from 'APP/store';

export const usePresenter = (userId) => {
  const navigateTo = useNavigateTo();
  return useLocalStore(() => ({
    onMemberClick: () => {
      const defaultPersonalizedPermissions =
        Entities.Calls.ongoingCall.permissions.defaultPersonalizedPermissions;

      Entities.Calls.ongoingCall.permissions.setEditedPermissions(
        defaultPersonalizedPermissions,
        userId
      );

      navigateTo({
        query: {
          [routerConst.params.popup]: routerConst.paramsEnum.popup.callMemberPermissions,
          [routerConst.params.memberPermissionsId]: userId,
        },
      });
    },
  }));
};
