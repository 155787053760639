import { DEFAULT_LIMIT_OF_MESSAGES_LOADING } from 'APP/model/message/messageModel.constants';
import Entities from 'APP/store';

export default ({ groupId, messages }) => {
  const group = Entities.GroupsStore.getGroupById(groupId);

  if (!group || group.isFake) {
    return;
  }

  if (messages.length < DEFAULT_LIMIT_OF_MESSAGES_LOADING) {
    group.messagesStore.setLoadedFirstMessages(true);
  }
};
