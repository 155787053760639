import { observer } from 'mobx-react';

import usePresenter from './presenter';

const ForceUpdateHandler = () => {
  usePresenter();

  return null;
};

export default observer(ForceUpdateHandler);
