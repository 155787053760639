import { ReactElement, useMemo } from 'react';

import { IUserMentionData } from 'APP/model/common/commonModel.types';
import { IMessageKeyWord } from 'APP/model/message/messageModel.types';
import { parseToTree } from 'APP/packages/markdown/parseToTree';

import { renderTree } from './renderTree/renderTree';

export interface ITextRendererParams {
  children: string;
  groupId?: string;
  userMentions?: IUserMentionData[];
  keyWords?: IMessageKeyWord[];
  hasBotCommands?: boolean;
  isPlainText?: boolean;
}

interface ITextRendererPresenter {
  text: string;
  content: ReactElement;
}

export const useTextRendererPresenter = (data: ITextRendererParams): ITextRendererPresenter => {
  const { children: text, groupId, userMentions, keyWords, hasBotCommands, isPlainText } = data;

  const content: ReactElement = useMemo(() => {
    const tree = parseToTree({ text, userMentions, keyWords, hasBotCommands, isPlainText });
    return renderTree(tree, { groupId });
  }, [text, userMentions, keyWords, hasBotCommands, isPlainText, groupId]);

  return { text, content };
};
