import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import Tasks from 'APP/Tasks';
import { showToast } from 'APP/Tasks/app/showToast/showToast';
import { checkSlowMode } from 'APP/Tasks/messaging/checkSlowMode/checkSlowMode';
import { useTranslation } from 'APP/packages/translations';
import Entities from 'APP/store';

export const useDraftHeaderPresenter = ({ core }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const source = useAsObservableSource({ core });

  const presenter = useLocalStore(() => ({
    async onCloseDraft() {
      Tasks.app.showConfirm({
        title: t('channels:channel_article_save_changes'),
        text: t('channels:channel_article_save_changes_info'),
        primaryText: t('common_save'),
        secondaryText: t('common:common_discard'),
        async onSuccess() {
          await source.core.handleUpdateDraft();
          presenter.goBack();
          Entities.toast.show(t('common_changes_saved'));
        },
        onCancel() {
          presenter.goBack();
        },
      });
    },

    deleteDraft: async () => {
      const canDelete = await Tasks.app.showConfirm({
        text: t('delete_message'),
        primaryText: t('common_yes'),
        secondaryText: t('common_cancel'),
      });

      if (canDelete) {
        const res = await Tasks.drafts.deleteDraft(source.core.draft);
        if (res) {
          presenter.goBack();
        }
      }
    },

    goBack: () => {
      history.goBack();
    },

    onClick: () => {
      const canSend = checkSlowMode();
      if (!canSend.result) {
        canSend.error && showToast(canSend.error);
        return;
      }
      source.core.handleCreateArticle();
      history.replace(source.core.group.routePath);
    },
  }));

  return presenter;
};
