import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { AddKeyPressHandler } from 'APP/packages/keyBoardHandler';
import { AnimatedBackgroundPanel } from 'MAIN/OngoingCall/Shared/AnimatedBackgroundPanel/AnimatedBackgroundPanel';
import { EventsBar } from 'MAIN/OngoingCall/Shared/EventsBar/EventsBar';
import { OwnVideo } from 'MAIN/OngoingCall/Shared/OwnVideo/OwnVideo';
import Entities from 'STORE';

import styles from './FullScreenCallView.styles.m.css';
import { GroupCall } from './GroupCall/GroupCall';
import { P2PAudioCall } from './P2PAudioCall/P2PAudioCall';
import { P2PVideoCall } from './P2PVideoCall/P2PVideoCall';

export const FullScreenCallView = observer(() => {
  useEffect(() => {
    Entities.OngoingCall.reset();
    return (): void => {
      Entities.OngoingCall.mode.collapse();
    };
  }, []);

  return (
    <div
      className={classNames(styles.container, {
        [styles.withBackgroundPanel]:
          Entities.OngoingCall.mode.isP2PVideo || Entities.OngoingCall.mode.isP2PAudio,
      })}
    >
      {Entities.OngoingCall.mode.isP2PAudio && <P2PAudioCall />}
      {Entities.OngoingCall.mode.isP2PVideo && <P2PVideoCall />}
      {Entities.OngoingCall.mode.isGroup && <GroupCall />}
      <AddKeyPressHandler onEsc={Entities.OngoingCall.mode.collapse} />
      <OwnVideo
        className={styles.ownVideoContainer}
        isFullScreen
      />
      {Entities.OngoingCall.hasEventsBar && <EventsBar fullScreen />}
      {Entities.OngoingCall.mode.isP2PAudio || Entities.OngoingCall.mode.isP2PVideo ? (
        <AnimatedBackgroundPanel />
      ) : null}
    </div>
  );
});
