import removeItemsByIndex from '../utils/removeItemsByIndex';
import syncOpenCloseBrackets from './syncOpenCloseBrackets';

export default ({ symbolsPositions, openBracketPositions, closeBracketPositions }) => {
  // no tags
  if (openBracketPositions.length === 0 && closeBracketPositions.length === 0) {
    return;
  }

  // no open tags but has close tags
  if (openBracketPositions.length === 0) {
    symbolsPositions[']'] = [];
    return;
  }

  // no close tags but has open tags
  if (closeBracketPositions.length === 0) {
    symbolsPositions['['] = [];
    return;
  }

  const { indexesToRemoveOpenBracket, indexesToRemoveCloseBracket } = syncOpenCloseBrackets({
    openBracketPositions,
    closeBracketPositions,
  });

  symbolsPositions['['] = removeItemsByIndex({
    list: openBracketPositions,
    indexesToRemove: indexesToRemoveOpenBracket,
  });

  symbolsPositions[']'] = removeItemsByIndex({
    list: closeBracketPositions,
    indexesToRemove: indexesToRemoveCloseBracket,
  });
};
