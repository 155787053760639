import { TRender } from '../parseTreeToHtml.types';

interface ILinkRenderData {
  link: string;
}

export const linkRender: TRender<ILinkRenderData> = (
  text: string,
  data: ILinkRenderData
): string => {
  if (!text) {
    return '';
  }

  return `<a href="${data.link}" target="_blank">${text}</a>`;
};
