import api, { ServerErrorCode } from 'APP/packages/api';
import { CreateSpaceRequestTypeEnum } from 'APP/packages/api/types/model/createSpaceRequest';
import { UpdateSpaceRequestTypeEnum } from 'APP/packages/api/types/model/updateSpaceRequest';
import logger from 'APP/packages/logger';

import { IBooleanResponse } from '../common/commonModel.types';
import { buildModelError } from '../error/errorBuilder';
import { mapGroupResponse } from '../group/groupModel.mapper';
import { IGroup } from '../group/groupModel.types';
import {
  mapPersonalizedSpaceResponse,
  mapSpaceCollectionItemsResponse,
  mapSpaceCollectionResponse,
  mapSpacePinOrderResponse,
  mapSpaceUserRoleResponse,
} from './spaceModel.mapper';
import {
  IAddSpaceCollectionItems,
  IBannedUsersInSpace,
  IBanUsersInSpaceRequest,
  ICreateSpace,
  ICreateSpaceCollection,
  ICreateSpaceMarket,
  IDeleteSpaceCollectionItems,
  IGetBannedSpaceUsers,
  IGetCollectionIdsWithItems,
  IGetSpaceUserRolesRequest,
  IPersonalizedSpace,
  IPinSpaceResponse,
  ISpaceCollection,
  ISpaceCollectionItem,
  ISpaceCollectionItems,
  ISpaceCollectionRequest,
  ISpaceMarket,
  ISpacePage,
  ISpaceParticipantsChange,
  ISpacesPageRequest,
  ISpaceUserRoleResponse,
  IUpdateGroupVisibilityRequest,
  IUpdateSpace,
  IUpdateSpaceCollection,
  IUpdateSpaceMarket,
  IValidateMarketUrl,
  ISetObsceneFilterRequest,
  ISetKeywordFiltersRequest,
} from './spaceModel.types';

export const getSpace = async (spaceId: string): Promise<IPersonalizedSpace> => {
  try {
    const personalizedSpace = await api.space.getSpace({ spaceId });
    return mapPersonalizedSpaceResponse(personalizedSpace);
  } catch (error) {
    logger.get('API').error('spaces.getSpace', error);
    throw buildModelError(error);
  }
};

export const getSpacesPage = async (data: ISpacesPageRequest): Promise<ISpacePage> => {
  try {
    const spacesPage = await api.space.getSpacesPage(data);

    return {
      ...spacesPage,
      spaces: spacesPage.spaces.map((personalizedSpace) =>
        mapPersonalizedSpaceResponse(personalizedSpace)
      ),
    };
  } catch (error) {
    logger.get('API').error('spaces.getSpacesPage', error);
    throw buildModelError(error);
  }
};

export const createSpace = async (data: ICreateSpace): Promise<IPersonalizedSpace> => {
  try {
    const personalizedSpace = await api.space.createSpace({
      ...data,
      type: data.type as string as CreateSpaceRequestTypeEnum,
    });
    return mapPersonalizedSpaceResponse(personalizedSpace);
  } catch (error) {
    logger.get('API').error('space.createSpace', error);
    throw buildModelError(error);
  }
};

export const updateSpace = async (data: IUpdateSpace): Promise<IPersonalizedSpace> => {
  try {
    const personalizedSpace = await api.space.updateSpace({
      ...data,
      type: data.type as string as UpdateSpaceRequestTypeEnum,
    });
    return mapPersonalizedSpaceResponse(personalizedSpace);
  } catch (error) {
    logger.get('API').error('space.updateSpace', error);
    throw buildModelError(error);
  }
};

export const revokeFromAdmin = async (
  data: ISpaceParticipantsChange
): Promise<IBooleanResponse> => {
  try {
    return api.space.revokeFromAdmin(data);
  } catch (error) {
    logger.get('API').error('space.revokeFromAdmin', error);
    throw buildModelError(error);
  }
};

export const removeParticipants = async (
  data: ISpaceParticipantsChange
): Promise<IBooleanResponse> => {
  try {
    return api.space.removeParticipants(data);
  } catch (error) {
    logger.get('API').error('space.removeParticipants', error);
    throw buildModelError(error);
  }
};

export const promoteToAdmin = (data: ISpaceParticipantsChange): Promise<IBooleanResponse> => {
  try {
    return api.space.promoteToAdmin(data);
  } catch (error) {
    logger.get('API').error('space.promoteToAdmin', error);
    throw buildModelError(error);
  }
};

export const joinSpace = async (spaceId: string): Promise<IPersonalizedSpace> => {
  try {
    const personalizedSpace = await api.space.joinSpace({ spaceId });
    return mapPersonalizedSpaceResponse(personalizedSpace);
  } catch (error) {
    logger.get('API').error('space.joinSpace', error);
    throw buildModelError(error);
  }
};

export const getAvailableGroups = async (spaceId: string): Promise<IGroup[]> => {
  try {
    const response = await api.space.getAvailableGroups({ spaceId });
    return response.groups.map((group) => mapGroupResponse(group));
  } catch (error) {
    logger.get('API').error('spaces.getAvailableGroups', error);
    throw buildModelError(error);
  }
};

export const moveGroupsToSpace = async (
  spaceId: string,
  groupIds: string[]
): Promise<IPersonalizedSpace> => {
  try {
    const personalizedSpace = await api.space.moveGroupsToSpace({ spaceId, groupIds });
    return mapPersonalizedSpaceResponse(personalizedSpace);
  } catch (error) {
    logger.get('API').error('spaces.moveGroupsToSpace', error);
    throw buildModelError(error);
  }
};

export const leaveSpace = async (spaceId: string): Promise<IPersonalizedSpace> => {
  try {
    const personalizedSpace = await api.space.leaveSpace({ spaceId });
    return mapPersonalizedSpaceResponse(personalizedSpace);
  } catch (error) {
    logger.get('API').error('spaces.leaveSpace', error);
    throw buildModelError(error);
  }
};

export const deleteSpace = async (
  spaceId: string,
  groupIds?: string[]
): Promise<IBooleanResponse> => {
  try {
    return await api.space.deleteSpace({ spaceId, groupIds: groupIds || [] });
  } catch (error) {
    logger.get('API').error('spaces.deleteSpace', error);
    throw buildModelError(error);
  }
};

export const unlinkGroups = async (groupIds: string[]): Promise<IBooleanResponse> => {
  try {
    return await api.space.unlinkGroups({ groupIds });
  } catch (error) {
    logger.get('API').error('spaces.unlinkGroups', error);
    throw buildModelError(error);
  }
};

export const getMarkets = async (spaceId: string): Promise<ISpaceMarket[]> => {
  try {
    const response = await api.space.getMarkets({ spaceId });
    return response.markets;
  } catch (error) {
    logger.get('API').error('spaces.getMarkets', error);
    throw buildModelError(error);
  }
};

export const createMarket = async (data: ICreateSpaceMarket): Promise<ISpaceMarket> => {
  try {
    return await api.space.createMarket(data);
  } catch (error) {
    logger.get('API').error('spaces.createMarket', error);
    throw buildModelError(error);
  }
};

export const updateMarket = async (data: IUpdateSpaceMarket): Promise<ISpaceMarket> => {
  try {
    return await api.space.updateMarket(data);
  } catch (error) {
    logger.get('API').error('spaces.updateMarket', error);
    throw buildModelError(error);
  }
};

export const deleteMarket = async (
  spaceId: string,
  marketId: string
): Promise<IBooleanResponse> => {
  try {
    return await api.space.deleteMarket({ spaceId, marketId });
  } catch (error) {
    logger.get('API').error('spaces.deleteMarket', error);
    throw buildModelError(error);
  }
};

export const banUsers = async (data: IBanUsersInSpaceRequest): Promise<IBooleanResponse> => {
  try {
    return await api.space.banUsers(data);
  } catch (error) {
    logger.get('API').error('spaces.banUsers', error);
    throw buildModelError(error);
  }
};

export const unbanUsers = async (data: ISpaceParticipantsChange): Promise<IBooleanResponse> => {
  try {
    return await api.space.unbanUsers(data);
  } catch (error) {
    logger.get('API').error('spaces.unbanUsers', error);
    throw buildModelError(error);
  }
};

export const getBannedUsers = async (data: IGetBannedSpaceUsers): Promise<IBannedUsersInSpace> => {
  try {
    return await api.space.getBannedUsers(data);
  } catch (error) {
    logger.get('API').error('spaces.getBannedUsers', error);
    throw buildModelError(error);
  }
};

export const getCollections = async (spaceId: string): Promise<ISpaceCollection[]> => {
  try {
    const response = await api.space.getCollections({ spaceId });
    return response.collections.map((collection) => mapSpaceCollectionResponse(collection));
  } catch (error) {
    logger.get('API').error('spaces.getCollections', error);
    throw buildModelError(error);
  }
};

export const createCollection = async (data: ICreateSpaceCollection): Promise<ISpaceCollection> => {
  try {
    const collection = await api.space.createCollection(data);
    return mapSpaceCollectionResponse(collection);
  } catch (error) {
    logger.get('API').error('spaces.createCollection', error);
    throw buildModelError(error);
  }
};

export const updateCollection = async (data: IUpdateSpaceCollection): Promise<ISpaceCollection> => {
  try {
    const collection = await api.space.updateCollection(data);
    return mapSpaceCollectionResponse(collection);
  } catch (error) {
    logger.get('API').error('spaces.updateCollection', error);
    throw buildModelError(error);
  }
};

export const addCollectionItems = async (
  data: IAddSpaceCollectionItems
): Promise<ISpaceCollectionItem[]> => {
  try {
    const response = await api.space.addCollectionItems(data);
    return response.items;
  } catch (error) {
    logger.get('API').error('spaces.addCollectionItems', error);
    throw buildModelError(error);
  }
};

export const getCollectionIdsWithItems = async (
  data: IGetCollectionIdsWithItems
): Promise<string[]> => {
  try {
    const response = await api.space.getCollectionIdsWithItems(data);
    return response.collectionIds;
  } catch (error) {
    logger.get('API').error('spaces.getCollectionIdsWithItems', error);
    throw buildModelError(error);
  }
};

export const deleteCollection = async (data: ISpaceCollectionRequest): Promise<boolean> => {
  try {
    const response = await api.space.deleteCollection(data);
    return response.result;
  } catch (error) {
    logger.get('API').error('spaces.deleteCollection', error);
    throw buildModelError(error);
  }
};

export const getCollectionItems = async (
  data: ISpaceCollectionRequest
): Promise<ISpaceCollectionItems> => {
  try {
    const response = await api.space.getCollectionItems(data);
    return mapSpaceCollectionItemsResponse(response);
  } catch (error) {
    logger.get('API').error('spaces.getCollectionItems', error);
    throw buildModelError(error);
  }
};

export const deleteCollectionItems = async (
  data: IDeleteSpaceCollectionItems
): Promise<IBooleanResponse> => {
  try {
    return await api.space.deleteCollectionItems(data);
  } catch (error) {
    logger.get('API').error('spaces.deleteCollectionItems', error);
    throw buildModelError(error);
  }
};

export const validateMarketUrl = async (data: IValidateMarketUrl): Promise<boolean> => {
  try {
    const response = await api.space.validateMarketUrl(data);
    return response.result;
  } catch (error) {
    if (error.message === ServerErrorCode.MarketUrlNotValid) {
      return false;
    }

    logger.get('API').error('spaces.validateMarketUrl', error);
    throw buildModelError(error);
  }
};

export const pinSpaces = async (spaceIds: string[]): Promise<IPinSpaceResponse[]> => {
  try {
    const response = await api.space.pinSpaces({ spaceIds });
    return mapSpacePinOrderResponse(response);
  } catch (error) {
    logger.get('API').error('spaces.pinSpaces', error);
    throw buildModelError(error);
  }
};

export const unpinSpaces = async (spaceIds: string[]): Promise<IPinSpaceResponse[]> => {
  try {
    const response = await api.space.unpinSpaces({ spaceIds });
    return mapSpacePinOrderResponse(response);
  } catch (error) {
    logger.get('API').error('spaces.unpinSpaces', error);
    throw buildModelError(error);
  }
};

export const getUserRoles = async (
  data: IGetSpaceUserRolesRequest
): Promise<ISpaceUserRoleResponse> => {
  try {
    const response = await api.space.getUserRoles(data);
    return mapSpaceUserRoleResponse(response);
  } catch (error) {
    logger.get('API').error('spaces.getUserRoles', error);
    throw buildModelError(error);
  }
};

export const changeOwnership = async (spaceId: string, newOwnerId: string): Promise<boolean> => {
  try {
    const response = await api.space.changeOwnership({ spaceId, newOwnerId });
    return response.result;
  } catch (error) {
    logger.get('API').error('spaces.changeOwnership', error);
    throw buildModelError(error);
  }
};

export const updateGroupVisibility = async (
  data: IUpdateGroupVisibilityRequest
): Promise<boolean> => {
  try {
    const result = await api.space.updateGroupVisibility(data);
    return result.result;
  } catch (error) {
    logger.get('API').error('spaces.updateGroupVisibility', error);
    throw buildModelError(error);
  }
};

export const setObsceneFilter = async (data: ISetObsceneFilterRequest): Promise<boolean> => {
  try {
    const result = await api.space.setObsceneFilter(data);
    return result.result;
  } catch (error) {
    logger.get('API').error('space.setObsceneFilter', error);
    throw buildModelError(error);
  }
};

export async function getKeywordFilters(spaceId: string): Promise<string[]> {
  try {
    const result = await api.space.getKeywordFilters({ spaceId });
    return result.keywords;
  } catch (error) {
    logger.get('API').error('space.getKeywordFilters', error);
    throw buildModelError(error);
  }
}

export async function setKeywordFilters(data: ISetKeywordFiltersRequest): Promise<boolean> {
  try {
    const result = await api.space.setKeywordFilters(data);
    return result.result;
  } catch (error) {
    logger.get('API').error('space.setKeywordFilters', error);
    throw buildModelError(error);
  }
}
