import { useLocalStore } from 'mobx-react';

import { ICallInvitationInfo } from 'APP/model/unauthorizedCall/unauthorizedCallModel.types';
import Entities from 'STORE';

import { ICallDescriptionLocalStore } from './CallDescription.types';

export const useCallDescriptionPresenter = () => {
  return useLocalStore<ICallDescriptionLocalStore>(() => ({
    get invitationInfo(): ICallInvitationInfo | undefined {
      return Entities.callInvitation.invitationInfo;
    },
  }));
};
