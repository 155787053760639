import classNames from 'classnames';
import React, { FC, memo, CSSProperties } from 'react';

import AnimatedPartStyles from '../AnimatedPart/AnimatedPart.styles.m.css';
import styles from './TextPlaceholder.styles.m.css';

interface ITextPlaceholderCSS extends CSSProperties {
  '--end-animation-position': string;
}

interface ITextPlaceholderProps {
  className?: string;
  width?: number;
}

export const TextPlaceholder: FC<ITextPlaceholderProps> = memo(({ className, width = 100 }) => {
  const style: ITextPlaceholderCSS = {
    width: `${width}px`,
    '--end-animation-position': `${width}px`,
  };

  return (
    <div
      style={style}
      className={classNames(
        styles.animatedText,
        {
          [AnimatedPartStyles.animation]: true,
        },
        className
      )}
    />
  );
});
