export enum GroupServerErrorCode {
  GroupDoesNotExist = 'E_GROUP_DOES_NOT_EXIST',
  UserIsNotBannedInTheGroup = 'E_USER_IS_NOT_BANNED_IN_THE_GROUP',
  UserIsBannedInTheGroup = 'E_USER_IS_BANNED_IN_THE_GROUP',
  ParticipantNotInGroup = 'E_PARTICIPANT_NOT_IN_GROUP',
  InvitationsTooManyToRemove = 'E_INVITATIONS_TOO_MANY_PHONES_TO_REMOVE',
  InvitationsTooManyToAdd = 'E_INVITATIONS_TOO_MANY_PHONES_TO_ADD',
  UserGroupRelationshipDoesNotExist = 'E_USER_GROUP_RELATIONSHIP_DOES_NOT_EXIST',
  InvitationsToManyADminsToAdd = 'E_INVITATIONS_TOO_MANY_ADMINS_TO_ADD',
}
