import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Reactions } from 'APP/components/Reactions/Reactions';
import { ChatMessage } from 'APP/store/Messages/Message/ChatMessage/ChatMessage';

import { usePostFooterPresenter } from './PostReactions.presenter';
import styles from './PostReactions.styles.m.css';

interface IPostReactionsProps {
  message: ChatMessage;
  isView: boolean;
}

export const PostReactions: FC<IPostReactionsProps> = observer(({ message, isView }) => {
  const presenter = usePostFooterPresenter(message);

  if (!presenter.isShow) {
    return null;
  }

  return (
    <Reactions
      className={styles.container}
      message={message}
      isVisible={isView}
      theme="1"
    />
  );
});
