import { action, computed, observable } from 'mobx';

import { ICallProviderPublisher } from 'APP/packages/callProviders/callProviders.types';

import { AgoraProvider } from './AgoraProvider';

export class AgoraProviderPublisher implements ICallProviderPublisher {
  private root: AgoraProvider;

  @observable isPlayingVideo = false;

  constructor(root: AgoraProvider) {
    this.root = root;
  }

  @computed
  get hasAudio(): boolean {
    return !!this.root.agoraClient.localAudioTrack;
  }

  @computed
  get hasVideo(): boolean {
    return !!this.root.agoraClient.localVideoTrack;
  }

  @action
  playVideo(element: HTMLDivElement): void {
    if (this.root.agoraClient.localVideoTrack) {
      this.isPlayingVideo = true;
      this.root.agoraClient.localVideoTrack.play(element);
    }
  }

  @action
  stopVideo(): void {
    if (this.root.agoraClient.localVideoTrack) {
      this.isPlayingVideo = false;
      this.root.agoraClient.localVideoTrack?.stop();
    }
  }

  getVolumeLevel(): number {
    if (this.root.agoraClient.localAudioTrack) {
      return this.root.agoraClient.localAudioTrack.getVolumeLevel();
    }
    return 0;
  }
}
