import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Field } from 'APP/packages/form/Field/Field';
import { Form } from 'APP/packages/form/Form/Form';
import { IFormContentProps } from 'APP/packages/form/Form/Form.types';
import MultiLineField from 'APP/packages/form/MultiLineField';
import { RadioButtonGroup } from 'APP/packages/form/RadioButtonGroup/RadioButtonGroup';
import { useTranslation } from 'APP/packages/translations';
import { Button, ButtonTypes } from 'UIKIT/Button/Button';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';
import { Loader } from 'UIKIT/Loader/Loader';
import { PopupFooter } from 'UIKIT/PopupFooter/PopupFooter';

import { useReportFormPresenter } from './ReportForm.presenter';
import styles from './ReportForm.styles.m.css';
import { TSubmitHandler } from './ReportForm.types';

const FORM_ID = 'report-form';

interface IReportFormProps {
  title: string;
  onSubmit: TSubmitHandler;
}

export const ReportForm: FC<IReportFormProps> = observer(({ title, onSubmit }) => {
  const presenter = useReportFormPresenter(onSubmit);
  const { t } = useTranslation();

  if (presenter.isLoading) {
    return (
      <Loader
        className={styles.loader}
        color="base"
        size="medium"
      />
    );
  }

  return (
    <Form
      initialValues={presenter.initialValues}
      subscription={{
        submitting: true,
        pristine: true,
      }}
      onSubmit={presenter.onSubmit}
    >
      {({ handleSubmit, submitting, pristine }: IFormContentProps): React.ReactElement => (
        <>
          <form
            id={FORM_ID}
            className={classNames(styles.form, customScrollStyles.container)}
            onSubmit={handleSubmit}
          >
            <div className={styles.title}>{title}</div>

            <RadioButtonGroup
              name="reasonId"
              options={presenter.reasons}
            />

            {!pristine && (
              <Field
                className={styles.comment}
                name="comment"
                label={t('content_moderation_comment_placeholder')}
                component={MultiLineField}
              />
            )}
          </form>

          <PopupFooter>
            <Button
              form={FORM_ID}
              type={ButtonTypes.Submit}
              theme="1"
              isLoading={submitting}
              isDisabled={pristine}
            >
              {t('send')}
            </Button>
          </PopupFooter>
        </>
      )}
    </Form>
  );
});
