const CLOSE_TAGS = ['***', '**', '*', '~~', '_'];

export default ({ positionToTag }) => {
  const positions = Object.keys(positionToTag);

  if (positions.length === 0) {
    return {};
  }

  const additinalTags = {};
  const listOfOpenedTags = [];

  for (let i = 0; i < positions.length; i++) {
    const position = parseInt(positions[i]);
    const tagData = positionToTag[position];

    if (!CLOSE_TAGS.includes(tagData.tag)) {
      continue;
    }

    const openTagIndex = listOfOpenedTags.lastIndexOf(tagData.tag);

    // it's open tag
    if (openTagIndex === -1) {
      listOfOpenedTags.push(tagData.tag);
      continue;
    }

    // it's close tag
    if (openTagIndex === listOfOpenedTags.length - 1) {
      listOfOpenedTags.pop();
      continue;
    }

    const notClosedTags = listOfOpenedTags.slice(openTagIndex + 1);
    additinalTags[position] = notClosedTags.slice().reverse();
    additinalTags[position + tagData.tag.length] = notClosedTags;
  }

  const missedTagsPositions = Object.keys(additinalTags);

  if (missedTagsPositions.length === 0) {
    return {};
  }

  // map to the positionToTag structure
  for (let i = 0; i < missedTagsPositions.length; i++) {
    const position = missedTagsPositions[i];
    const tags = additinalTags[position];

    additinalTags[position] = [];

    for (let j = 0; j < tags.length; j++) {
      const tag = tags[j];

      additinalTags[position].push({
        tag,
        start: position,
        end: position + tag.length,
      });
    }
  }

  return additinalTags;
};
