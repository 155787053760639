import {
  IDeleteMessagesMode,
  IDeleteMessagesRequest,
  PayloadType,
} from 'APP/model/message/messageModel.types';
import { messageModel } from 'APP/model/model';
import { subscribeSentMessage } from 'APP/packages/bus/adapters/messaging/sentMessage';
import Entities from 'APP/store';
import { ChatMessage } from 'APP/store/Messages/Message/ChatMessage/ChatMessage';
import { MessageError } from 'STORE/Messages/Message/ChatMessage/ChatMessages.types';

async function request(data: IDeleteMessagesRequest): Promise<boolean> {
  try {
    return await messageModel.deleteMessages(data);
  } catch (error) {
    console.error(error);
  }

  return false;
}

interface IDeleteMessageParams {
  groupId: string;
  messages: ChatMessage[];
  mode?: IDeleteMessagesMode;
}

interface IMessageByTypeIds {
  createdMessageIds: string[];
  fakeMessageIds: string[];
}

export const deleteMessages = async ({
  groupId,
  messages,
  mode = IDeleteMessagesMode.All,
}: IDeleteMessageParams): Promise<void> => {
  const group = Entities.GroupsStore.getGroupById(groupId);
  if (!group || messages.length === 0) {
    return;
  }

  const { createdMessageIds, fakeMessageIds } = messages.reduce(
    (acc, message) => {
      if (message.isFake) {
        acc.fakeMessageIds.push(message.id);
      } else {
        acc.createdMessageIds.push(message.id);
      }
      return acc;
    },
    { createdMessageIds: [], fakeMessageIds: [] } as IMessageByTypeIds
  );

  if (mode === IDeleteMessagesMode.Me) {
    messages.forEach((message) => {
      const deletedMessage = new ChatMessage({
        ...message,
        payload: {
          ...message.payload,
          payloadType: PayloadType.MessageWasDeletedByMe,
        },
      });

      group.messagesStore.setEditedPayload(deletedMessage);
    });
  } else {
    group.messagesStore.handleRemoveMessages(messages);
  }

  group.pinnedMessages.setLastPinnedMessageId();

  group.messagesStore.multiSelect.clearSelectedMessages();

  if (fakeMessageIds.length > 0) {
    const unsubscribeSentMessage = subscribeSentMessage(group.id, async (data) => {
      const { messageUuid, messageId, hasError } = data;
      const index = fakeMessageIds.indexOf(messageUuid);

      if (index >= 0) {
        const isSuccess = !hasError && (await request({ groupId, messageIds: [messageId], mode }));
        if (!isSuccess) {
          const restoredMessage = group.messagesStore.getMessageById(messageId);
          if (restoredMessage) {
            restoredMessage.setError(MessageError.Delete);
          }
        }
        fakeMessageIds.splice(index, 1);
      }

      if (!fakeMessageIds.length) {
        unsubscribeSentMessage();
      }
    });
  }

  if (createdMessageIds.length > 0) {
    const isSuccess = await request({ groupId, messageIds: createdMessageIds, mode });

    if (isSuccess) {
      const messagesHaveError = messages.filter((message) => !message.isFake && message.hasError);

      messagesHaveError.forEach((message) => {
        const restoredMessage = group.messagesStore.getMessageById(message.id);
        restoredMessage?.resetError();
      });

      return;
    }

    const restoredMessages = messages.filter((message) => !message.isFake);

    restoredMessages.forEach((message) => {
      const restoredMessage = group.messagesStore.getMessageById(message.id);

      group.messagesStore.removeEditedPayload(message);

      if (!restoredMessage) {
        return;
      }

      restoredMessage.setError(
        mode === IDeleteMessagesMode.All ? MessageError.Delete : MessageError.DeleteByMe
      );
    });

    group.messagesStore.handleRestoreMessages({ messages: restoredMessages });
  }
};
