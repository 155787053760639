export default (array, number) => {
  let i = 0;

  const chunks = [];

  while (i < array.length) {
    chunks.push(array.slice(i, (i += number)));
  }

  return chunks;
};
