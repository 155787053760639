import { MIN_SPLITTABLE_MARKDOWN_LENGTH } from 'APP/model/message/messageModel.constants';
import { PayloadType } from 'APP/model/message/messageModel.types';
import logger from 'APP/packages/logger';
import { parseToTree } from 'APP/packages/markdown/parseToTree';
import { ITreeItem } from 'APP/packages/markdown/parseToTree/parseToTree.types';
import { renderMarkdownFromTree } from 'APP/packages/markdown/renderUtils';
import { langService } from 'MODULE/i18n/service';
import type { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';
import Advertisement from 'STORE/Messages/Message/Payload/Advertisement';
import Album from 'STORE/Messages/Message/Payload/Album';
import Article from 'STORE/Messages/Message/Payload/Article';
import FilePayload from 'STORE/Messages/Message/Payload/File';

interface IExtendTreeItem extends ITreeItem {
  isEmpty?: boolean;
}

interface ITreeChunk {
  tree: IExtendTreeItem[];
  length: number;
}

type TExtendTree = IExtendTreeItem[] | IExtendTreeItem;

/**
 * filter all empty tags
 * @param tree
 */
function normalizeTree(tree: TExtendTree): TExtendTree {
  if (Array.isArray(tree)) {
    const t: IExtendTreeItem[] = tree.map((treeNode) => normalizeTree(treeNode) as IExtendTreeItem);
    return t.filter((treeNode: IExtendTreeItem) => !treeNode.isEmpty);
  } else if (Array.isArray(tree.content)) {
    tree.content = tree.content
      .map((treeNode) => normalizeTree(treeNode) as IExtendTreeItem)
      .filter((treeNode) => !treeNode.isEmpty);
    tree.isEmpty = tree.content.length === 0;
    return tree;
  } else {
    tree.isEmpty = tree.content.length === 0;
    return tree;
  }
}

function splitText(
  text: string,
  length: number,
  maxLength: number,
  minContentLength: number
): [string, string] {
  // if the text is inseparable and it does not fit into the current chunk, provided that it fits into the next chunk and the current chunk is not empty
  if (
    text.length < minContentLength &&
    text.length > length &&
    length !== maxLength &&
    text.length < maxLength
  ) {
    return ['', text];
  }
  let sentences = text.split(/\.\s|\.$/);
  // returning dots to sentences. Last element without a dot. example
  // '123. 123.' = ['123', '123', '']       '123. 123' = ['123', '123']
  for (let i = 0; i < sentences.length - 1; i++) {
    sentences[i] += '.';
  }
  sentences = sentences.filter((x) => x.length);

  let count = 0;
  for (let i = 0; i < sentences.length; i++) {
    // if the sentence does not fit completely in the current chunk and does not exceed the length of the chunk (will be split in the next chunk). Decreases the number of resulting chunks.
    if (sentences[i].length + count > length && sentences[i].length < maxLength) {
      break;
    }

    const words = sentences[i].split(' ');
    let j = 0;
    for (; j < words.length; j++) {
      // if the word is greater than the maximum length of a chunk, we take part of the word into the current chunk. Since it will be divided in the next chunk anyway
      if (words[j].length > maxLength) {
        count = maxLength;
        break;
      }
      const res = count + words[j].length;
      if (res <= length) {
        // trying to return a space in the current chunk
        if (res + 1 <= length && (j !== words.length - 1 || i !== sentences.length - 1)) {
          count = res + 1;
        } else {
          count = res;
        }
      } else {
        break;
      }
    }
    if (j !== words.length) {
      break;
    }
  }

  return [text.slice(0, count), text.slice(count)];
}

export function splitMessageText(
  messageText: string,
  maxLength: number,
  minContentLength = MIN_SPLITTABLE_MARKDOWN_LENGTH
): string[] {
  if (messageText.length <= maxLength) {
    return [messageText];
  }

  const tree: IExtendTreeItem[] = parseToTree({
    text: messageText,
    hasBotCommands: false,
    isPlainText: false,
  });

  const result: ITreeChunk[] = [];
  let lastTreeChunk: ITreeChunk;

  function saveTreeChunk(chunk: ITreeChunk | null): ITreeChunk {
    if (chunk) {
      result.push(chunk);
    }
    return (lastTreeChunk = { tree: [], length: 0 });
  }

  function splitTree(
    rootTree: TExtendTree,
    tree: TExtendTree,
    currentTreeChunk: ITreeChunk,
    currentChunkNode: IExtendTreeItem[]
  ): void {
    if (currentTreeChunk.length >= maxLength) {
      return;
    }

    if (Array.isArray(tree)) {
      tree.forEach((treeNode) => splitTree(rootTree, treeNode, currentTreeChunk, currentChunkNode));
    } else {
      if (tree.isEmpty) {
        return;
      }

      if (Array.isArray(tree.content)) {
        const node: IExtendTreeItem[] = [];
        currentChunkNode.push({
          tag: tree.tag,
          content: node,
          props: tree.props,
        });

        tree.content.forEach((treeNode) => {
          splitTree(rootTree, treeNode, currentTreeChunk, node);
        });
      } else if (
        !['link', 'email', 'keyWord', '@', '/'].includes(tree.tag) &&
        currentTreeChunk.length + tree.content.length >= maxLength
      ) {
        const [first, second] = splitText(
          tree.content,
          maxLength - currentTreeChunk.length,
          maxLength,
          minContentLength
        );
        if (first) {
          currentChunkNode.push({
            tag: tree.tag,
            content: first,
            props: tree.props,
          });
          tree.content = second;
          tree.isEmpty = second.length === 0;
          currentTreeChunk.length = currentTreeChunk.length + first.length;
        }
        const newChunk = saveTreeChunk(currentTreeChunk);
        splitTree(rootTree, rootTree, newChunk, newChunk.tree);
      } else {
        if (
          currentTreeChunk.length + tree.content.length > maxLength &&
          currentTreeChunk.length !== 0
        ) {
          const newChunk = saveTreeChunk(currentTreeChunk);
          splitTree(rootTree, rootTree, newChunk, newChunk.tree);
          return;
        }
        currentChunkNode.push({
          tag: tree.tag,
          content: tree.content,
          props: tree.props,
        });
        currentTreeChunk.length += tree.content.length;
        tree.content = '';
        tree.isEmpty = true;
        if (currentTreeChunk.length >= maxLength) {
          const newChunk = saveTreeChunk(currentTreeChunk);
          splitTree(rootTree, rootTree, newChunk, newChunk.tree);
        }
      }
    }
  }

  const currentTreeChunk: ITreeChunk = { tree: [], length: 0 };
  lastTreeChunk = currentTreeChunk;
  try {
    splitTree(tree, tree, currentTreeChunk, currentTreeChunk.tree);

    result.push(lastTreeChunk);

    const NormalizedTreeChunks = result
      .map((item) => normalizeTree(item.tree) as IExtendTreeItem[])
      .filter((item) => item.length);

    const messages = NormalizedTreeChunks.map((tree: IExtendTreeItem[]) => {
      return renderMarkdownFromTree(tree);
    });

    return messages.filter((message) => Boolean(message.trim().length));
  } catch (e) {
    logger.get('messages').error('split message error', e);
    return [messageText];
  }
}

export const getMessagePayload = (
  message: ChatMessage,
  payloadId: string
): FilePayload | (Album | Advertisement | Article)['payloads'] | undefined => {
  return message?.payload?.payloads
    ? message.payload.payloads.find((p: { id: string }) => p.id === payloadId)?.payload
    : message?.payload;
};

const getTextForCopyByType = (message: ChatMessage): string => {
  const lang = langService.i18n.lang;

  switch (message.payload.payloadType) {
    case PayloadType.RichText:
    case PayloadType.Buttons:
    case PayloadType.ButtonsSelected:
      return message.renderPayload.text;
    case PayloadType.Contact: {
      return `${message.payload.name}\n+${message.payload.phone}`;
    }
    case PayloadType.Image:
      return message.renderPayload.comment || `[${lang.message_desctription_picture}]`;
    case PayloadType.Album:
      return message.renderPayload.comment || `[${lang.message_desctription_album}]`;
    case PayloadType.Video:
      return message.renderPayload.comment || `[${lang.message_desctription_video_file}]`;
    case PayloadType.CircleVideo:
      return message.renderPayload.comment || `[${lang.message_desctription_video_file}]`;
    case PayloadType.File:
      return message.renderPayload.comment || `[${lang.message_desctription_file}]`;
    case PayloadType.VoiceMessage:
      return message.renderPayload.comment || `[${lang.message_desctription_voice_message}]`;
    case PayloadType.AudioMessage:
      return message.renderPayload.comment || '';
    case PayloadType.Location:
      return message.renderPayload.comment || `[${lang.message_desctription_location}]`;
    case PayloadType.Sticker:
      return message.renderPayload.comment || `[${lang.message_desctription_sticker}]`;
    case PayloadType.Advertisement:
      return message.renderPayload.comment;
    default:
      return '';
  }
};

export const getMessageTextForCopy = (message: ChatMessage, isCopySenderName: boolean): string => {
  if (isCopySenderName) {
    return `${message.avatarTitle}:\n${getTextForCopyByType(message)}`;
  }

  return getTextForCopyByType(message);
};
