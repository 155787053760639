import { action, computed, observable } from 'mobx';

import { IDeepLinkInfo } from 'APP/model/common/commonModel.types';
import {
  SpaceType,
  SpaceUserRole,
  SpaceTemplate,
  ISpaceData,
} from 'APP/model/space/spaceModel.types';
import { SpaceCollections } from 'STORE/Spaces/SpaceCollections';
import { TAvatarBackgroundColorIndex } from 'UIKIT/Avatar/Avatar.types';

import { GroupVisibilityData } from './GroupVisibilityData';
import { SpaceMarkets } from './SpaceMarkets';

export class Space {
  id: string;
  @observable name: string;
  @observable description: string;
  @observable avatarUrl?: string;
  @observable deepLinkInfo: IDeepLinkInfo;
  @observable type: SpaceType;
  @observable participantsCount: number;
  @observable muted: boolean;
  @observable role: SpaceUserRole;
  @observable categoryId: string;
  @observable template: SpaceTemplate;
  @observable isJoinProcessing = false;
  @observable marketsCount = 0;
  @observable primaryMarketUrl: string;
  @observable verified: boolean;
  @observable pinOrder: number;
  @observable groupsChangedAt: number | null = null;
  @observable subscribedGroupIds: string[] = [];
  @observable obsceneFilterEnabled: boolean;
  @observable keywordFilters: number;

  markets: SpaceMarkets = new SpaceMarkets();
  collections: SpaceCollections = new SpaceCollections();
  groupVisibility = new GroupVisibilityData();

  constructor(spaceData: ISpaceData) {
    this.update(spaceData);
  }

  @computed
  public get avatarColorIndex(): TAvatarBackgroundColorIndex {
    return this.id.at(-1) as TAvatarBackgroundColorIndex;
  }

  @computed
  public get isPrivate(): boolean {
    return this.type === SpaceType.Private;
  }

  @computed
  public get isPublic(): boolean {
    return this.type === SpaceType.Public;
  }

  @computed
  public get deepLink(): string | null {
    return this.deepLinkInfo?.deepLink || null;
  }

  @action
  public update(spaceData: ISpaceData): void {
    this.muted = !!spaceData.muted;
    this.role = spaceData.role;
    this.pinOrder = spaceData.pinOrder ?? 0;
    this.subscribedGroupIds = spaceData.subscribedGroupIds || [];

    const space = spaceData.space;
    this.id = space.id;
    this.name = space.name;
    this.description = space.description || '';
    this.avatarUrl = space.avatarUrl;
    this.verified = space.verified;
    this.deepLinkInfo = space.deepLinkInfo;
    this.type = space.type;
    this.participantsCount = space.participantsCount;
    this.categoryId = space.categoryId;
    this.template = space.template;
    this.marketsCount = space.marketsCount;
    this.primaryMarketUrl = space.primaryMarketUrl;
    this.obsceneFilterEnabled = space.obsceneFilterEnabled;
    this.keywordFilters = space.keywordFilters;

    if (spaceData.groupsVisibility) {
      this.groupVisibility.update(spaceData.groupsVisibility);
    }
  }

  @computed
  public get isOwner(): boolean {
    return this.role === SpaceUserRole.Owner;
  }

  @computed
  public get isAdmin(): boolean {
    return this.role === SpaceUserRole.Admin;
  }

  @computed
  public get isMember(): boolean {
    return this.role === SpaceUserRole.Member;
  }

  @computed
  public get isGuest(): boolean {
    return this.role === SpaceUserRole.Guest;
  }

  @action
  public updateParticipantsCount(count: number): void {
    this.participantsCount = count;
  }

  @action
  public setRole(role: SpaceUserRole): void {
    this.role = role;
  }

  @action
  public setJoinProcessing(inProcess: boolean): void {
    this.isJoinProcessing = inProcess;
  }

  @action
  public setMarketsCount(count: number): void {
    this.marketsCount = count;
  }

  @computed
  public get isPinned(): boolean {
    return !!this.pinOrder;
  }

  @action
  public reset(): void {
    this.collections.setIsNeedToUpdate(true);
  }

  @action
  public setGroupsChangedAt(groupsChangedAt: number | null): void {
    this.groupsChangedAt = groupsChangedAt;
  }
}
