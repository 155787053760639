import { PayloadType } from 'APP/model/message/messageModel.types';

export const getFirstTextPositionOnPayloads = (payloads) => {
  const payload = payloads.find((payload) => {
    return payload.type === PayloadType.RichText && !payload.data.linkPreview;
  });

  if (!payload) {
    return null;
  }

  return payload.order;
};
