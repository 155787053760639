import { useAsObservableSource, useLocalStore } from 'mobx-react';

import { navigateTo } from 'APP/router/refs/navigateTo';
import Entities from 'STORE';
import { Group } from 'STORE/Groups/Group';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

export interface IAllPinsHeaderPresenter {
  group: Group | null;
  pinnedMessages: ChatMessage[];
  count: number;
  onBack(): void;
}

export const useAllPinsHeaderPresenter = (groupId: string) => {
  const source = useAsObservableSource({ groupId });

  const presenter = useLocalStore<IAllPinsHeaderPresenter>(() => ({
    get group(): Group | null {
      return Entities.GroupsStore.getGroupById(source.groupId);
    },

    get pinnedMessages(): ChatMessage[] {
      return presenter.group?.pinnedMessages.messages || [];
    },

    get count(): number {
      if (presenter.group?.messagesStore.multiSelect.hasSelectedMessages) {
        return presenter.group?.messagesStore.multiSelect.selectedMessages.length;
      }

      return presenter.pinnedMessages.length;
    },

    onBack(): void {
      navigateTo({
        to: presenter.group?.routePath,
      });
    },
  }));

  return presenter;
};
