import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { ALERT_TYPES } from 'APP/constants/app';
import { useTranslation } from 'APP/packages/translations';
import Entities from 'APP/store';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import { useMoveGroupToSpace } from 'MAIN/hooks/space/useMoveGroupToSpace';
import { Group } from 'STORE/Groups/Group';
import { Space } from 'STORE/Spaces/Space';

export interface MoveGroupToSpacePopupParams {
  groupId: string;
}

interface IMoveGroupToSpacePresenter {
  group: Group | null;
  spaces: Space[];
  onSelectSpace(spaceId: string): Promise<void>;
  onClose(): void;
}

export function useMoveGroupToSpacePresenter({
  popupInstance,
  params,
}: ICommonPopupsProps<MoveGroupToSpacePopupParams>): IMoveGroupToSpacePresenter {
  const { t } = useTranslation();
  const { groupId } = params!;
  const moveGroupToSpace = useMoveGroupToSpace(groupId);

  const presenter = useLocalStore<IMoveGroupToSpacePresenter>(() => ({
    get group(): Group | null {
      return Entities.GroupsStore.getGroupById(groupId);
    },

    get spaces(): Space[] {
      return moveGroupToSpace.spaces;
    },

    async onSelectSpace(spaceId: string): Promise<void> {
      presenter.onClose();

      const canMoveGroup = await Tasks.space.confirmSpaceGroupsForMove(spaceId, groupId);

      if (!canMoveGroup) {
        return;
      }

      const success = await Tasks.space.moveGroupsToSpace(spaceId, [groupId]);

      success
        ? Entities.toast.show(t('space_alert_moved'))
        : Tasks.app.addAlert({
            type: ALERT_TYPES.SOMETHING_WRONG_ERROR,
          });
    },

    onClose(): void {
      popupInstance.close();
    },
  }));

  useEffect(() => {
    if (!Entities.GroupsStore.isLoading && !presenter.group) {
      presenter.onClose();
    }
  }, [presenter.group, Entities.GroupsStore.isLoading]);

  return presenter;
}
