import { observable, computed, action } from 'mobx';

import { ICategoryInfo } from 'APP/model/common/commonModel.types';
import { IBotCommand, IBotUserDisplayData } from 'APP/model/user/userModel.types';
import Users from 'STORE/Users';

import { User } from '../User';

export class Bot extends User {
  @observable categoryId?: string;
  @observable categoryInfo?: ICategoryInfo;
  @observable description: string;
  @observable addedToProfile: boolean;
  @observable verified: boolean;
  @observable groupId?: string;
  @observable commands: IBotCommand[];

  constructor(users: Users, data: IBotUserDisplayData) {
    super(users, data);

    this.categoryId = data.categoryId;
    this.categoryInfo = data.categoryInfo;
    this.description = data.description;
    this.addedToProfile = data.addedToProfile;
    this.verified = data.verified;
    this.groupId = data.groupId;
    this.commands = data.commands;
  }

  get isBot(): boolean {
    return true;
  }

  @computed
  get displayName(): string {
    return this.userName;
  }

  @computed
  get avatarTitle(): string {
    return this.displayName;
  }

  @action
  update(data: IBotUserDisplayData): void {
    super.update(data);

    this.categoryId = data.categoryId;
    this.categoryInfo = data.categoryInfo;
    this.description = data.description;
    this.addedToProfile = data.addedToProfile;
    this.verified = data.verified;
    this.groupId = data.groupId;
    this.commands = data.commands;
  }
}
