import Tasks from 'APP/Tasks';
import api from 'APP/packages/api';
import logger from 'APP/packages/logger';
import Entities from 'APP/store';
import { t } from 'MODULE/i18n/service';

export const setActiveStream = async (stream, groupId) => {
  try {
    const { initiatorId, channelId, status, totalViewers = 0, comment = '' } = stream;

    if (Entities.GroupsStore.getGroupById(groupId).withoutMe) {
      Tasks.app.showAlert(t('stream_alert_cant_join_without_subscription'));
      return;
    }

    if (Entities.Calls.hasCalls || Entities.ActiveStream.hasStream) {
      Tasks.app.showAlert(t('calls_join_another_call'));
      return;
    }

    const response = await api.streams.getStreamInfo({ channelId });

    Entities.ActiveStream.setStream({
      initiatorId,
      groupId,
      channelId,
      status,
      totalViewers,
      title: comment,
      ...response,
    });

    Entities.ActiveStream.stream.addInitiator();
    Entities.ActiveStream.stream.setActiveStreamer(response.initiatorUid);

    return Entities.ActiveStream.stream;
  } catch (e) {
    logger.get('API').error('streams.getStreamInfo', e);
    return null;
  }
};
