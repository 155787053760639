import { action, observable, computed, values } from 'mobx';

import { ICallMember } from 'APP/model/call/callModel.types';
import { ProviderUid } from 'APP/packages/callProviders/callProviders.types';

import { Call } from '../Call';
import { Member } from '../Member/Member';

export class Members {
  @observable data = observable.map<ProviderUid, Member>({}, { deep: false });

  call: Call;

  constructor(call: Call) {
    this.call = call;
  }

  @action
  setMembers(members: ICallMember[]): void {
    members.forEach((member) => {
      const newMember = new Member(this.call, member);

      this.data.set(member.providerUserUid, newMember);
    });
  }

  @action
  deleteMember(uid: number): void {
    this.data.delete(uid);
  }

  // Members getters
  @computed
  get memberList(): Member[] {
    return values(this.data)
      .slice()
      .sort((member1, member2) => {
        return (
          Number(member2.isInitiator) * 10000 +
          Number(member2.isMe) * 1000 +
          Number(member2.isOnCall) * 100 -
          Number(member1.isInitiator) * 10000 -
          Number(member1.isMe) * 1000 -
          Number(member1.isOnCall) * 100 +
          (member1.displayName > member2.displayName ? 1 : 0) +
          (member1.displayName < member2.displayName ? -1 : 0)
        );
      });
  }

  @computed
  get memberOnCallList(): Member[] {
    return this.memberList.filter((member) => member.isOnCall);
  }

  getMemberByUid(uid: ProviderUid): Member | null {
    return this.data.get(uid) || null;
  }

  getMemberById(userId: string): Member | null {
    return (
      values(this.data).find((member) => {
        return member.userId === userId;
      }) || null
    );
  }
}
