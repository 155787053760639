/* global Date */
import isValidDate from './isValidDate';

const format = (num) => (num < 10 ? `0${num}` : num);

export default (rawFormatDate) => {
  if (!rawFormatDate) {
    return null;
  }

  const date = new Date(rawFormatDate);
  if (!isValidDate(date)) {
    return null;
  }

  return (
    `${date.getFullYear()}-` +
    `${format(date.getMonth() + 1)}-` +
    `${format(date.getDate())} ` +
    `${format(date.getHours())}:` +
    `${format(date.getMinutes() + 1)}:` +
    `${format(date.getSeconds())}`
  );
};
