import { observer } from 'mobx-react';
import React from 'react';

import IconLink from 'APP/packages/UiKit/IconLink';
import { useTranslation } from 'APP/packages/translations';
import BackIcon from 'ICONS/ic-back-32.svg';

import usePresenter from './presenter';
import styles from './styles.m.css';

const Header = ({ groupId }) => {
  const { t } = useTranslation();
  const presenter = usePresenter({ groupId });

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <IconLink
          className={styles.icon}
          Icon={BackIcon}
          to={presenter.linkToBack}
        />
        <div className={styles.headerTitle}>
          {t('channels:channel_article_drafts')}
          {presenter.count > 0 && <span> ({presenter.count})</span>}
        </div>
      </div>
    </div>
  );
};

export default observer(Header);
