/**
 * Rest API
 * Documentation Rest API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CallCreateV2Request { 
    groupId: string;
    streamType: CallCreateV2RequestStreamTypeEnum;
    callType: CallCreateV2RequestCallTypeEnum;
}
export enum CallCreateV2RequestStreamTypeEnum {
    Audio = 'AUDIO',
    Video = 'VIDEO'
};
export enum CallCreateV2RequestCallTypeEnum {
    P2P = 'P2P',
    Group = 'GROUP',
    Conference = 'CONFERENCE',
    Stream = 'STREAM'
};



