import { checkSlowMode } from 'APP/Tasks/messaging/checkSlowMode/checkSlowMode';
import { messageModel } from 'APP/model/model';
import { ChatMessage } from 'APP/store/Messages/Message/ChatMessage/ChatMessage';
import Entities from 'STORE';
import { MessageError } from 'STORE/Messages/Message/ChatMessage/ChatMessages.types';

export async function reForwardMessage(message: ChatMessage): Promise<boolean> {
  const canForward = checkSlowMode();
  if (!canForward.result) {
    canForward.error && Entities.toast.show(canForward.error);
    return false;
  }

  if (!message.forward) {
    return true;
  }

  message.resetError();

  try {
    await messageModel.forwardMessage({
      sourceGroupId: message.forward.clientGroupSourceId,
      messagesToForward: [
        {
          clientTs: message.clientTime,
          clientUuid: message.clientUuid,
          sourceMessageId: message.forward.messageId,
          targetGroupId: message.groupId,
        },
      ],
    });

    return true;
  } catch {
    const group = Entities.GroupsStore.getGroupById(message.groupId);
    const forwardMessage = group?.messagesStore.getMessageById(message.clientUuid);

    forwardMessage?.setError(MessageError.Forward);

    return false;
  }
}
