import { action, computed, observable } from 'mobx';
import { IReactionDisposer } from 'mobx/lib/internal';

import Tasks from 'APP/Tasks';
import { ALERT_TYPES } from 'APP/constants/app';
import { ClientRole, StreamType } from 'APP/constants/calls';
import { CallProviderType } from 'APP/model/call/callModel.types';
import { AgoraProvider } from 'APP/packages/callProviders/AgoraProvider/AgoraProvider';
import { OpenviduProvider } from 'APP/packages/callProviders/OpenviduProvider/OpenviduProvider';
import {
  ICallProviderFacade,
  ICallProviderPublisher,
  ProviderUid,
} from 'APP/packages/callProviders/callProviders.types';
import { getDefaultDevices, IDevices } from 'APP/packages/deviceInfo/getDefaultDevices';

import { Call } from '../Call';
import { IJoinParams } from './Me.types';
import { handleUnpublishStream } from './Services/handleUnpublishStream/handleUnpublishStream';
import { myVolumeIndicator } from './Services/myVolumeIndicator/myVolumeIndicator';

export class Me {
  audioVideoToken: string;
  audioVideoUid: ProviderUid;
  shareScreenToken: string;
  shareScreenUid: ProviderUid;
  holdMicrophonePromise: Promise<MediaStream> | null = null; //this stream stored permission on microphone
  parent: Call;
  disposeMyVolumeIndicator: () => void;
  disposeHandleUnpublishStream: IReactionDisposer;
  devices: IDevices;

  @observable audioVideoStreamer: ICallProviderFacade;
  @observable isAudioMuted = true;
  @observable isVideoMuted = true;
  @observable isRaiseHand = false;
  @observable isShareMuted = true;
  @observable toggleHandStarted = false;
  @observable volumeLevel = 0;
  @observable id: string;

  constructor(parent: Call, id: string) {
    this.parent = parent;
    this.id = id;

    const Provider =
      parent.providerType === CallProviderType.Openvidu ? OpenviduProvider : AgoraProvider;

    this.audioVideoStreamer = new Provider({
      isDualStream: true,
      withVirtualBackground: !parent.isGuestCall,
    });

    this.initServices();

    getDefaultDevices().then((devices) => {
      this.devices = devices;

      this.audioVideoStreamer.createClient({
        devices,
        handlers: {
          onUserJoined: Tasks.calls.userJoined,
          onUserLeft: Tasks.calls.userLeft,
          onJoin: Tasks.calls.join,
          onLeave: Tasks.calls.leave,
          onLocalAudioTrackEnd: () => this.checkChangeDevices(),
          onLocalAudioTrackStart: (deviceId?: string) => this.holdMicrophone(deviceId),
        },
        role: this.parent.isP2P ? ClientRole.Host : ClientRole.Audience,
      });
    });

    window.navigator.mediaDevices.addEventListener('devicechange', () => this.checkChangeDevices());
  }

  async checkChangeDevices(): Promise<void> {
    const devices = await getDefaultDevices();

    if (this.devices.audioinput?.deviceId !== devices.audioinput?.deviceId) {
      this.devices.audioinput = devices.audioinput;
      await this.stopHoldMicrophone();
      this.audioVideoStreamer.changeMicrophone(devices.audioinput);
    }

    if (this.devices.audiooutput?.deviceId !== devices.audiooutput?.deviceId) {
      this.devices.audiooutput = devices.audiooutput;
    }

    if (this.devices.videoinput?.deviceId !== devices.videoinput?.deviceId) {
      this.devices.videoinput = devices.videoinput;
    }
  }

  initServices(): void {
    this.disposeMyVolumeIndicator = myVolumeIndicator(this);
    this.disposeHandleUnpublishStream = handleUnpublishStream(this);
  }

  async dispose(): Promise<void> {
    this.disposeHandleUnpublishStream();
    this.disposeMyVolumeIndicator();
    await this.audioVideoStreamer.dispose();
    this.stopHoldMicrophone();
    window.navigator.mediaDevices.removeEventListener('devicechange', () =>
      this.checkChangeDevices()
    );
  }

  @computed
  get publisher(): ICallProviderPublisher {
    return this.audioVideoStreamer.publisher;
  }

  @computed
  get channelId(): string | null {
    return this.parent.channelId;
  }

  @computed
  get numberOfLocalStreams(): number {
    return Number(!this.isAudioMuted || !this.isVideoMuted) + Number(!this.isShareMuted);
  }

  @computed
  get isAvailableMuteAudio(): boolean {
    return this.parent.isMeInitiator;
  }

  /**
   * reserve a place for the initiator and screen sharing
   * @returns {number|number|*}
   */
  @computed
  get limitBroadcasters(): number {
    let limit = this.parent.maxBroadcasters;
    if (!this.parent.isScreenSharing) {
      limit--;
    }
    if (this.parent.isMeInitiator && (!this.isAudioMuted || !this.isVideoMuted)) {
      return limit;
    }

    if (!this.parent.initiatorId) {
      return limit;
    }

    const initiatorOpponent = this.parent.opponents.getOpponentById(this.parent.initiatorId);
    if (initiatorOpponent) {
      return limit;
    }

    return limit - 1;
  }

  async canUnmute(type: StreamType): Promise<boolean> {
    if (
      !this.parent.isMeInitiator &&
      type !== StreamType.ScreenSharing &&
      this.isAudioMuted &&
      this.parent.opponents.broadcastersList.length + this.numberOfLocalStreams >=
        this.limitBroadcasters
    ) {
      Tasks.app.addAlert({
        type: ALERT_TYPES.CALL_BROADCASTERS_LIMITATION,
        data: {
          maxBroadcasters: this.parent.maxBroadcasters,
        },
      });
      return false;
    }

    if (type === StreamType.Audio) {
      const hasAudioPermission = await Tasks.permissions.hasMicrophonePermission();
      if (!hasAudioPermission) {
        return false;
      }
    }

    if (type === StreamType.Video) {
      const hasCameraPermission = await Tasks.permissions.hasCameraPermission();
      if (!hasCameraPermission) {
        return false;
      }
    }
    return true;
  }

  @computed
  get isDisabledShareScreen(): boolean {
    return !this.parent.isAnswered;
  }

  // https://www.macworld.com/article/3538798/mac-bluetooth-audio-quality-ways-to-fix-it.html
  async holdMicrophone(deviceId?: string): Promise<void> {
    if (this.holdMicrophonePromise) {
      return;
    }
    try {
      this.holdMicrophonePromise = window.navigator.mediaDevices.getUserMedia({
        audio: deviceId ? { deviceId } : true,
        video: false,
      });
    } catch (e) {
      console.warn(e);
    }
  }

  async stopHoldMicrophone(): Promise<void> {
    if (this.holdMicrophonePromise) {
      const stream = await this.holdMicrophonePromise;
      stream.getTracks().forEach((track) => {
        track.stop();
      });
      this.holdMicrophonePromise = null;
    }
  }

  @action
  async unpublish(): Promise<void> {
    if (this.isUnpublish) {
      await this.audioVideoStreamer.unpublish();
    }
  }

  @action
  async setAudioMuted(isAudioMuted: boolean): Promise<void> {
    this.isAudioMuted = isAudioMuted;
    await this.unpublish();
  }

  @action
  async setVideoMuted(isVideoMuted: boolean): Promise<void> {
    this.isVideoMuted = isVideoMuted;
    await this.unpublish();
  }

  @action
  setVolumeLevel(volume: number): void {
    this.volumeLevel = volume;
  }

  @computed
  get isUnpublish(): boolean {
    // -1 for screenSharing N members = N+1 Opponents
    return (
      this.parent.members.memberOnCallList.length > this.parent.maxBroadcasters - 1 &&
      this.isAudioMuted &&
      this.isVideoMuted
    );
  }

  @action
  setSharedScreen(isSharedScreen: boolean): void {
    this.isShareMuted = !isSharedScreen;
  }

  @computed
  get isSharedScreen(): boolean {
    return !this.isShareMuted;
  }

  @action
  setToggleHandStarted(value: boolean): void {
    this.toggleHandStarted = value;
  }

  @action
  async toggleHand(): Promise<void> {
    this.isRaiseHand = !this.isRaiseHand;
  }

  @action
  async join(params: IJoinParams): Promise<void> {
    const { channelId, audioVideoToken, audioVideoUid, shareScreenToken, shareScreenUid } = params;
    this.audioVideoToken = audioVideoToken;
    this.audioVideoUid = audioVideoUid;
    this.shareScreenToken = shareScreenToken;
    this.shareScreenUid = shareScreenUid;

    await this.audioVideoStreamer.join({
      channelId,
      uid: audioVideoUid,
      screenUid: shareScreenUid,
      token: audioVideoToken,
    });
  }

  @action
  setShareScreenToken(token: string): void {
    this.shareScreenToken = token;
  }

  @action
  async publish({ isVideoMuted = true, isAudioMuted = true }): Promise<void> {
    if (
      this.parent.members.memberList.length <= this.parent.maxBroadcasters - 1 &&
      !this.parent.isConference &&
      isAudioMuted &&
      this.isAudioMuted
    ) {
      // removes the 3 s delay after first turning on the microphone
      await Tasks.calls.audioMuting.unmuteAudio();
      await Tasks.calls.audioMuting.muteAudio();
    }

    if (!isAudioMuted || !this.isAudioMuted) {
      await Tasks.calls.audioMuting.unmuteAudio();
    }

    if (!isVideoMuted || !this.isVideoMuted) {
      await Tasks.calls.videoMuting.unmuteVideo();
    }
  }
}
