import { Me } from '../../Me';

// ToDO: [Call] Rewrite or delete reactions

export const myVolumeIndicator = (store: Me): (() => void) => {
  const smooth: number[] = [];
  const timer = setInterval(() => {
    const volumeLevel = store.publisher.getVolumeLevel();
    if (!volumeLevel) {
      store.setVolumeLevel(0);
      return;
    }
    smooth.push(Math.round(volumeLevel * 100));
    if (smooth.length > 10) {
      smooth.shift();
    }
    store.setVolumeLevel(Math.round(smooth.reduce((acc, x) => (acc += x), 0) / smooth.length));
  }, 100);

  return () => clearInterval(timer);
};
