import Tasks from 'APP/Tasks';
import api from 'APP/packages/api';
import logger from 'APP/packages/logger';
import Entities from 'APP/store';

export default async (groupId, messageIds = []) => {
  if (!messageIds.length) {
    return;
  }

  try {
    const { items: messages } = await api.messaging.increaseViews({
      groupId,
      messageIds,
    });

    const group = Entities.GroupsStore.getGroupById(groupId);
    if (group) {
      //There may be messages from unread groups or messages
      // (forward from unsubscribe group or scroll to an old pinned post)
      messages.forEach((message) => {
        if (group.messagesStore.getMessageById(message.id)) {
          Tasks.messaging.updateOldOrFakeMessages({
            messages: [message],
            groupId,
          });
        }
      });
    }

    Entities.viewedMessages.resetViewedMessages(groupId, messageIds);
  } catch (error) {
    logger.get('API').error('messaging.increaseViews', error);
  }
};
