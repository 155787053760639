import Tasks from 'APP/Tasks';
import { checkIsAvailableCall } from 'APP/Tasks/calls/checkIsAvailableCall';
import { t } from 'MODULE/i18n/service';
import Entities from 'STORE';

export const checkGroupAvailableCall = (groupId: string): boolean => {
  const group = Entities.GroupsStore.getGroupById(groupId);

  if (!group) {
    return false;
  }

  const isAvailableCall = checkIsAvailableCall(group);

  if (!isAvailableCall) {
    return false;
  }

  if (!group?.canInitCall) {
    Tasks.app.showAlert(t('no_permission_alert'));

    return false;
  }

  return true;
};
