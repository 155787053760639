interface ISortedReaction {
  id: string;
  count: number;
}

export const sortReactions = (reaction1: ISortedReaction, reaction2: ISortedReaction): number => {
  if (reaction1.count === reaction2.count) {
    return Number(reaction1.id) - Number(reaction2.id);
  }

  return reaction2.count - reaction1.count;
};
