import { observer } from 'mobx-react';
import React from 'react';

import { InAppView } from 'APP/main/InAppView/InAppView';
import { HubEmptyScreen } from 'MAIN/Hub/Components/HubEmptyScreen/HubEmptyScreen';
import { HubPageType, THubPage } from 'MAIN/Hub/Hub.types';
import Entities from 'STORE';

export const HubGamesPage: THubPage = observer(({ setPage }) => {
  const gameUrl = Entities.marketplace.baseGamesUrl;

  if (!gameUrl) {
    return <HubEmptyScreen setPage={setPage} />;
  }

  return (
    <InAppView
      isLocal={true}
      url={gameUrl}
      goBack={() => setPage(HubPageType.Main)}
    />
  );
});
