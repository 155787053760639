import React from 'react';

import { CommonPayload } from '../CommonPayload/CommonPayload';
import { useCallPayloadPresenter } from './CallPayload.presenter';

export const CallPayload = ({ payload }) => {
  const text = useCallPayloadPresenter(payload);

  return <CommonPayload description={text} />;
};
