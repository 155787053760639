import { observer } from 'mobx-react';
import React from 'react';

import { IconButton } from 'APP/packages/UiKit/IconButton';
import IconClose from 'ICONS/ic-close.svg';
import ViewsIcon from 'ICONS/ic-view-16.svg';
import { Button } from 'UIKIT/Button/Button';

import styles from './styles.m.css';

const Header = ({ presenter }) => {
  return (
    <div className={styles.container}>
      {presenter.isStreamStarted ? (
        <>
          <div className={styles.live}>LIVE</div>
          <Button
            onClick={presenter.toggleViewers}
            size="0"
            rounding={1}
            theme={presenter.isShowViewers ? 13 : 12}
            Icon={ViewsIcon}
            className={styles.viewers}
          >
            {presenter.viewersLiveCount}
          </Button>
        </>
      ) : (
        <IconButton
          className={styles.close}
          Icon={IconClose}
          onClick={presenter.onClose}
          size="big"
          iconSize="medium"
          theme="11"
        />
      )}
    </div>
  );
};

export default observer(Header);
