import UAParser from 'ua-parser-js';

import { DeviceInfoPlatform } from 'APP/model/common/commonModel.types';

import { browsersList } from './browsersList';

export const platformInfo = {
  device: 'unsupported',
  osVersion: '-1',
  isSafari: false,
  isFF: false,
  isChrome: false,
  isElectron: false,
  platform: DeviceInfoPlatform.Web,
  isMobile: false,
  isTablet: false,
};

export function calculatePlatform(userAgent: string): void {
  const browser = browsersList.find((browser) => browser.regex.test(userAgent));

  if (!browser) {
    return;
  }

  const browserInfo = browser.describe(userAgent);

  const parser = new UAParser(userAgent);
  const device = parser.getDevice();

  platformInfo.device = browserInfo.name;

  if (device && device.type === UAParser.DEVICE.MOBILE) {
    platformInfo.isMobile = true;
  }

  if (device && device.type === UAParser.DEVICE.TABLET) {
    platformInfo.isTablet = true;
  }

  if (browserInfo.version) {
    platformInfo.osVersion = browserInfo.version;
    platformInfo.device += ` ${platformInfo.osVersion}`;
  }

  switch (browserInfo.name) {
    case 'Safari': {
      platformInfo.isSafari = true;
      break;
    }
    case 'Firefox': {
      platformInfo.isFF = true;
      break;
    }
    case 'Opera':
    case 'Yandex Browser':
    case 'Vivaldi':
    case 'Chromium':
    case 'Chrome': {
      platformInfo.isChrome = true;
      break;
    }
    case 'Electron': {
      platformInfo.isElectron = true;
      platformInfo.platform = DeviceInfoPlatform.Desktop;
      platformInfo.device = window.electronAPI.device;
      platformInfo.osVersion = window.electronAPI.osVersion;
      break;
    }
  }
}
