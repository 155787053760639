import { useCallback, useEffect, useState } from 'react';

import Entities from 'STORE';

interface IFirstLoginHandlerPresenter {
  isCategoryPopupOpened: boolean;
  onCategoryPopupClose?(): void;
}

export const useFirstLoginHandlerPresenter = (): IFirstLoginHandlerPresenter => {
  const [isCategoryPopupOpened, toggleCategoryPopup] = useState(false);

  const onCategoryPopupClose = useCallback(() => {
    toggleCategoryPopup(false);
  }, []);

  useEffect(() => {
    if (!Entities.userPreferences.userPreferencesSelected) {
      toggleCategoryPopup(true);
    }
  }, [Entities.userPreferences.userPreferencesSelected]);

  return { isCategoryPopupOpened, onCategoryPopupClose };
};
