import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { VoicePlayer } from 'APP/components/VoicePlayer/VoicePlayer';
import { AudioUploadState } from 'MAIN/components/AudioUploadState/AudioUploadState';
import type { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';
import { Loader } from 'UIKIT/Loader/Loader';

import { useVoicePayloadPresenter } from './VoicePayload.presenter';
import styles from './VoicePayload.styles.m.css';

interface IVoicePayloadProps {
  message: ChatMessage;
}

export const VoicePayload: FC<IVoicePayloadProps> = observer(({ message }) => {
  const {
    id: payloadId,
    url,
    userFile,
    duration,
    uploadProgress,
    fileSize,
    histogram,
  } = message.payload;
  const presenter = useVoicePayloadPresenter(message);
  const Icon = presenter.icon;

  return (
    <>
      <div className={styles.container}>
        <VoicePlayer
          src={url || userFile}
          histogram={histogram}
          isLoading={uploadProgress !== null}
          LoadingComponent={() => (
            <AudioUploadState
              uploadFileData={{
                hasError: message.hasError,
                uploadProgress: message.payload.uploadProgress,
                cancelUpload: message.payload.cancelUploadAndDeleteMessage,
              }}
            />
          )}
          payloadId={payloadId || message.id}
          clientUuid={message.clientUuid}
          groupId={message.groupId}
          messageId={message.id}
          duration={duration}
          size={fileSize}
        />
        <button
          onClick={presenter.onClick}
          disabled={presenter.isDisabled}
          className={styles.button}
        >
          {presenter.isProgress && <Loader color="current" />}
          {Icon && <Icon />}
        </button>
      </div>
      {presenter.content && (
        <div
          className={classNames(styles.textContainer, {
            [styles.error]: presenter.hasError,
          })}
        >
          {presenter.content}
        </div>
      )}
    </>
  );
});
