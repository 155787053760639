import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';

import Tasks from 'APP/Tasks';
import { showConfirm } from 'APP/Tasks/app/app';
import { addAlert } from 'APP/Tasks/app/showAlert/showAlert';
import { ALERT_TYPES } from 'APP/constants/app';
import { RouterState } from 'APP/router/constants';
import useGetState from 'APP/router/hooks/useGetState';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import Entities from 'APP/store';

export const useArticlePreviewPresenter = ({ groupId }) => {
  const { t } = useTranslation();
  const navigateTo = useNavigateTo();
  const match = useRouteMatch();
  const history = useHistory();
  const hasGoBack = useGetState(RouterState.hasGoBack);

  const source = useAsObservableSource({ match, navigateTo });

  const presenter = useLocalStore(() => ({
    goBack: () => {
      if (hasGoBack) {
        history.goBack();
        return;
      }

      source.navigateTo({
        to: source.match.url.replace(`/article-preview/${source.match.params.id}`, ''),
      });
    },

    get group() {
      return Entities.GroupsStore.getGroupById(groupId);
    },

    get message() {
      if (!presenter.group) {
        return null;
      }

      return presenter.group.messagesStore.getMessageById(source.match.params.id) || null;
    },

    get isShowFooter() {
      return presenter.message && !presenter.message.isScheduled;
    },

    async delete() {
      const canDelete = await showConfirm({ text: t('delete_message') });

      if (!canDelete) {
        return false;
      }

      const res = await presenter.message.delete();
      if (res) {
        presenter.goBack();
      }
    },

    openMedia: (mediaId) => {
      Tasks.group.openMediaGallery({
        groupId,
        mediaId,
        messageId: presenter.message.id,
        messageIds: [presenter.message.id],
        showMessageInfo: false,
      });
    },

    updateViewsAndCommentsCount() {
      clearTimeout(presenter.timer);
      if (Entities.App.isFocused && presenter.isShowFooter) {
        Tasks.messaging.updateMessages([
          {
            messageId: presenter.message?.id,
            groupId,
          },
        ]);
      }
      presenter.timer = setTimeout(presenter.updateViewsAndCommentsCount, 5000);
    },

    async loadMessage() {
      let message = null;
      if (presenter.group) {
        const loadedMessages = await Tasks.messaging.loadMessagesByIds({
          groupId: presenter.group.id,
          messagesIds: [source.match.params.id],
        });

        message = loadedMessages[0] || null;
      }

      if (!message) {
        addAlert({ type: ALERT_TYPES.MESSAGE_HAS_BEEN_DELETED });
        presenter.goBack();
      }
    },

    init() {
      if (!presenter.message) {
        presenter.loadMessage();
        return;
      }

      if (!presenter.isShowFooter) {
        return;
      }

      presenter.updateViewsAndCommentsCount();
      Tasks.messaging.increaseMessagesView({
        groupId: Entities.GroupsStore.activeGroupId,
        messageIds: [presenter.message?.id],
      });

      const threadId = presenter.message?.threadId;
      if (threadId && !Entities.GroupsStore.getGroupById(threadId)) {
        Tasks.thread.getThreadInfoById(threadId);
      }
    },
  }));

  useEffect(() => {
    presenter.init();
  }, [presenter.message?.id]);

  useEffect(() => {
    presenter.updateViewsAndCommentsCount();
  }, [Entities.App.isFocused]);

  useEffect(() => {
    return () => {
      clearInterval(presenter.timer);
    };
  }, []);

  return presenter;
};
