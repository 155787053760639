import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { useFileUploadStatePresenter } from 'APP/hooks/useFileUploadState/useFileUploadState';
import LoadIcon40 from 'ICONS/ic-load-40.svg';
import LoadIcon from 'ICONS/ic-load.svg';
import { InfiniteUploadIcon } from 'MAIN/components/InfiniteUploadIcon/InfiniteUploadIcon';
import { ProgressLoadIcon } from 'MAIN/components/ProgressLoadIcon/ProgressLoadIcon';

import { IconSize } from './UploaderState.types';

interface IUploaderStateProps {
  size?: IconSize;
  className?: string;
  uploadFileData: {
    uploadProgress: number;
    cancelUpload(): Promise<void>;
    hasError?: boolean;
  };
}

export const ICON = {
  [IconSize.Normal]: LoadIcon,
  [IconSize.Big]: LoadIcon40,
};

export const UploaderState: FC<IUploaderStateProps> = observer((props) => {
  const { uploadFileData, className, children, size = IconSize.Normal } = props;

  const presenter = useFileUploadStatePresenter(uploadFileData);

  if ((presenter.uploadProgress === null && !children) || uploadFileData.hasError) {
    return null;
  }

  const Icon = ICON[size] || LoadIcon;

  return (
    <div className={className}>
      {presenter.isWaitingUploadProcess && (
        <InfiniteUploadIcon
          Icon={Icon}
          onClick={presenter.cancelUpload}
        />
      )}
      {presenter.isUploadProcess && (
        <ProgressLoadIcon
          Icon={Icon}
          progress={presenter.uploadProgress || 0}
          onClick={presenter.cancelUpload}
        />
      )}
      {presenter.uploadProgress === null && children}
    </div>
  );
});
