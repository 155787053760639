import classNames from 'classnames';
import React, { FC, useEffect, useRef } from 'react';
import Draggable from 'react-draggable';

import { ICallProviderPublisher } from 'APP/packages/callProviders/callProviders.types';

import styles from './DraggableVideo.styles.m.css';

interface IDraggableVideoProps {
  className?: string;
  publisher: ICallProviderPublisher;
  isFullScreen?: boolean;
}

export const DraggableVideo: FC<IDraggableVideoProps> = ({
  className,
  publisher,
  isFullScreen,
}) => {
  const container = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (publisher.hasVideo && container.current) {
      publisher.playVideo(container.current);
    }
    return () => {
      if (publisher.hasVideo) {
        publisher.stopVideo();
      }
    };
  }, []);

  return (
    <Draggable bounds={'parent'}>
      <div
        className={classNames(styles.container, className, {
          [styles.fullScreen]: isFullScreen,
        })}
        ref={container}
      />
    </Draggable>
  );
};
