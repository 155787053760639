import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { ISpaceMember } from 'APP/Tasks/space/getSpaceMembers/getSpaceMembers';
import { SpaceUserRole } from 'APP/model/space/spaceModel.types';
import { Space } from 'STORE/Spaces/Space';
import { debounce } from 'UTILS/debounce';
import { CancelPromiseHandler, makeCancellablePromise } from 'UTILS/makeCancellablePromise';

export interface ISpaceMembersListPresenterParams {
  readonly space: Space | null;
  readonly searchText?: string;
}

export interface ISpaceMembersListPresenter {
  members: ISpaceMember[];
  isLoading: boolean;
  cursor: string | null;
  cancelLoadMore: CancelPromiseHandler | null;
  search: () => void;
  init(): void;
  loadMore(): Promise<void>;
  get hasMore(): boolean;
  onMemberRemove(userId: string): void;
  onUpdateMemberRole(userId: string, role: SpaceUserRole): void;
  onReloadMembersList(): void;
}

const SEARCH_DELAY = 200;

export function useSpaceMembersListPresenter({
  space,
  searchText,
}: ISpaceMembersListPresenterParams): ISpaceMembersListPresenter {
  const source = useAsObservableSource({ space, searchText });

  const presenter = useLocalStore<ISpaceMembersListPresenter>(() => ({
    cursor: null,
    isLoading: false,
    members: [],
    cancelLoadMore: null,

    async init(): Promise<void> {
      this.members = [];
      this.cursor = null;
      await presenter.loadMore();
    },

    search: debounce(async () => {
      await presenter.init();
    }, SEARCH_DELAY),

    async loadMore(): Promise<void> {
      if (presenter.cancelLoadMore) {
        presenter.cancelLoadMore();
      }

      presenter.isLoading = true;

      const { promise: getSpaceMembers, cancel } = makeCancellablePromise(
        Tasks.space.getSpaceMembers({
          spaceId: source.space?.id as string,
          searchText: source.searchText,
          cursor: presenter.cursor || '',
        })
      );

      presenter.cancelLoadMore = cancel;

      const spaceMembersData = await getSpaceMembers;

      if (spaceMembersData) {
        const { members, cursor } = spaceMembersData;
        presenter.members.push(...members);
        presenter.cursor = cursor;
      }

      presenter.cancelLoadMore = null;
      presenter.isLoading = false;
    },

    get hasMore(): boolean {
      return !!presenter.cursor;
    },

    onMemberRemove(userId: string): void {
      presenter.members = presenter.members.filter((member) => member.user.id !== userId);
    },

    onUpdateMemberRole(userId: string, role: SpaceUserRole): void {
      const index = presenter.members.findIndex((member) => member.user.id === userId);

      if (index !== -1) {
        presenter.members[index] = {
          ...presenter.members[index],
          role,
        };
      }
    },

    onReloadMembersList(): void {
      presenter.init();
    },
  }));

  useEffect(() => {
    if (space) {
      searchText ? presenter.search() : presenter.init();
    }
  }, [space, searchText]);

  return presenter;
}
