import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { BlockedByMeUserPanel } from './BlockedByMeUserPanel/BlockedByMeUserPanel';
import { BlockedMeUserPanel } from './BlockedMeUserPanel/BlockedByMeUserPanel';
import { useChatFooterPresenter } from './ChatFooter.presenter';
import styles from './ChatFooter.styles.m.css';
import { DisabledMessagedPanel } from './DisabledMessagedPanel/DisabledMessagedPanel';
import { InputPanel } from './InputPanel/InputPanel';

interface IChatFooterProps {
  isAllowPostMessage: boolean;
}

export const ChatFooter: FC<IChatFooterProps> = observer(({ isAllowPostMessage }) => {
  const presenter = useChatFooterPresenter();

  let panelComponent = null;

  if (presenter.isBlockedByMe) {
    panelComponent = <BlockedByMeUserPanel />;
  } else if (presenter.isBlockedMe) {
    panelComponent = <BlockedMeUserPanel />;
  } else if (presenter.isShowDisabledMessages) {
    panelComponent = <DisabledMessagedPanel />;
  } else if (isAllowPostMessage) {
    panelComponent = <InputPanel />;
  }

  return <div className={styles.container}>{panelComponent}</div>;
});
