import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { PayloadType } from 'APP/model/message/messageModel.types';

import getLinkPreview from '../Tasks/getLinkPreview';
import { DEFAULT_PAYLOAD, FOCUS_NAMES, ONLY_MEDIA_PAYLOADS } from '../constants';
import services from '../services';

export const useArticleRedactorPresenter = ({ core }) => {
  const source = useAsObservableSource({ core });

  const presenter = useLocalStore(() => ({
    data: [],
    // might be string (title or creator) or order
    actualFocusPosition: FOCUS_NAMES.TITLE,

    initEditMode: () => {
      presenter.data = services.converter.editModeToArticleSystem(source.core.payloads.data);
      source.core.payloads.data = presenter.data;
    },

    setTitle: (value) => {
      presenter.core.payloads.setTitle(value);
    },

    setPayloadCreatedText: (value) => {
      presenter.core.payloads.setPayloadCreatedText(value);
    },

    addSomethingLoading: (id) => {
      presenter.core.payloads.addleSomethingLoading(id);
    },

    removeSomethingLoading: (id) => {
      presenter.core.payloads.removeSomethingLoading(id);
    },

    setActualPosition: (position) => {
      presenter.actualFocusPosition = position;
    },

    editPayloadByOrder: (payload, order) => {
      presenter.data[order] = {
        ...presenter.data[order],
        order: order,
        data: payload,
        type: payload.payloadType,
        isDragging: false,
        reUpload: payload.reUpload,
      };

      presenter.core.payloads.setData(presenter.data);
    },

    deletePayloadByOrder: (order) => {
      const payload = services.payloads.getPayloadByOrder(presenter.data, order);

      if (ONLY_MEDIA_PAYLOADS.includes(payload.type)) {
        presenter.removeSomethingLoading(payload.id);
      }

      const newFocusPosition = services.focus.getPositionAfterDeleteSomePayload(
        presenter.data,
        order
      );

      presenter.data = services.payloads.getPayloadsDeleteByOrder(presenter.data, order);

      presenter.setActualPosition(newFocusPosition);
      presenter.core.payloads.setData(presenter.data);
    },

    setPayloadToEnd: (payload) => {
      const order = presenter.nextOrder;

      if (
        payload.payloadType === PayloadType.RichText &&
        presenter.actualFocusPosition !== FOCUS_NAMES.CREATOR_PAYLOAD
      ) {
        presenter.setActualPosition(order);
      }

      presenter.data = [
        ...presenter.data,
        {
          order: order,
          type: payload.payloadType,
          data: payload,
          id: uuidv4(),
          isDragging: false,
          reUpload: payload.reUpload,
        },
      ];

      presenter.core.payloads.setData(presenter.data);
    },

    setPayloadsToEnd: (payloads) => {
      const order = presenter.nextOrder;

      if (
        payloads.every((payload) => payload.payloadType === PayloadType.RichText) &&
        presenter.actualFocusPosition !== FOCUS_NAMES.CREATOR_PAYLOAD
      ) {
        presenter.setActualPosition(order);
      }

      presenter.data = [
        ...presenter.data,
        ...payloads.map((payload, index) => ({
          order: order + index,
          type: payload.payloadType,
          data: payload,
          id: uuidv4(),
          isDragging: false,
          reUpload: payload.reUpload,
        })),
      ];

      presenter.core.payloads.setData(presenter.data);
    },

    setPayloadToPosition: (payload, order) => {
      presenter.data = services.payloads.getPayloadsAfterSetPayload(presenter.data, order, {
        order: order,
        type: payload.payloadType,
        data: payload,
        id: uuidv4(),
        isDragging: false,
        reUpload: payload.reUpload,
      });

      presenter.core.payloads.setData(presenter.data);
    },

    setPayloadsToCurrentPosition: (payloads, currentOrder) => {
      const order = currentOrder + 1;

      presenter.data = services.payloads.getPayloadsAfterSetPayloads(
        presenter.data,
        order,
        payloads.map((payload) => {
          return {
            order: order,
            type: payload.payloadType,
            data: payload,
            id: uuidv4(),
            isDragging: false,
            reUpload: payload.reUpload,
          };
        })
      );

      presenter.core.payloads.setData(presenter.data);
    },

    setLocationPayload: (coordinates) => {
      const payload = {
        payloadType: PayloadType.Location,
        ...coordinates,
      };

      presenter.editPayloadByOrder(payload, presenter.actualFocusPosition);
    },

    onEnter: (payload, order) => {
      presenter.editPayloadByOrder(payload, order);

      const newOrder = services.focus.getNextPosition(order);

      presenter.setActualPosition(newOrder);
      presenter.data = services.payloads.getPayloadsAfterSetPayload(presenter.data, newOrder, {
        order: newOrder,
        type: PayloadType.RichText,
        data: DEFAULT_PAYLOAD,
        id: uuidv4(),
        isDragging: false,
      });

      presenter.core.payloads.setData(presenter.data);
      presenter.setLinkPreview(payload, order);
    },

    onDragStart: (event) => {
      const startIndex = event.source.index;

      presenter.data[startIndex] = {
        ...presenter.data[startIndex],
        isDragging: true,
      };
    },

    onDragEnd: (event) => {
      if (!event.destination) {
        return;
      }

      const startIndex = event.source.index;
      const endIndex = event.destination.index;

      presenter.data[startIndex] = {
        ...presenter.data[startIndex],
        isDragging: false,
      };

      presenter.data = services.payloads.getPayloadsOnDrag(presenter.data, startIndex, endIndex);
      presenter.core.payloads.setData(presenter.data);
    },

    handleArrowsKeyboard: (position, action) => {
      const order = services.focus.getPositionArrowsKeyboard(presenter.data, position, action);

      presenter.setActualPosition(order);
    },

    get payloads() {
      return presenter.data.slice().sort((a, b) => a.order - b.order);
    },

    get scroll() {
      return source.core.scroll;
    },

    get core() {
      return source.core;
    },

    get nextOrder() {
      if (!presenter.data.length) {
        return 0;
      }

      return presenter.data[presenter.data.length - 1].order + 1;
    },

    get lastOrder() {
      if (!presenter.data.length) {
        return 0;
      }

      return presenter.data[presenter.data.length - 1].order;
    },

    async setLinkPreview(payload, order) {
      if (payload?.payloadType !== PayloadType.RichText) {
        return;
      }

      const link = await getLinkPreview(payload);

      if (!link) {
        return;
      }

      const linkOrder = services.focus.getNextPosition(order);
      let focusOrder = presenter.actualFocusPosition;
      focusOrder = focusOrder >= linkOrder ? focusOrder + 1 : focusOrder;

      presenter.data = services.payloads.getPayloadsAfterSetPayload(presenter.data, linkOrder, {
        order: linkOrder,
        type: PayloadType.RichText,
        data: { ...DEFAULT_PAYLOAD, text: link.link, linkPreview: link },
        id: uuidv4(),
        isDragging: false,
      });

      presenter.setActualPosition(focusOrder);
      presenter.core.payloads.setData(presenter.data);
    },
  }));

  useEffect(() => {
    source.core.isEditMode && presenter.initEditMode();
  }, []);

  return presenter;
};
