import { addToContact } from 'APP/model/contact/contactModel';
import { IUpdatePhoneBookRequest } from 'APP/model/user/userModel.types';
import Entities from 'APP/store';
import type { User } from 'STORE/Users/User/User';
import { clearPhoneNumber } from 'UTILS/phone';

import { INewContactFormData } from '../../NewContactView/NewContactView.types';

export const createContact = async (values: INewContactFormData): Promise<User | null> => {
  const { contactNameSearch, contactPhone } = values;
  const payload: IUpdatePhoneBookRequest = {
    added: [
      {
        contactName: contactNameSearch.trim(),
        contactPhone: clearPhoneNumber(contactPhone),
        inAppContact: true,
      },
    ],
    last: true,
  };

  try {
    const createdContacts = await addToContact(payload);

    if (createdContacts.length) {
      const createdContact = createdContacts[0];
      Entities.UsersStore.add([createdContact]);
      Entities.UsersStore.addContact(createdContact.id);
      return Entities.UsersStore.getUserById(createdContact.id);
    }
  } catch {
    // do nothing
  }

  return null;
};
