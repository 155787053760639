export function addNewBatch(_batches: number[][], _newBath: [number, number]): number[][] {
  const newBath = [..._newBath].sort();
  const batches = [..._batches].sort((a, b) => a[0] - b[0]);
  const newBatches = batches
    .map((_batch) => {
      const batch = [..._batch].sort();
      if (
        (batch[0] <= newBath[0] && batch[1] >= newBath[0]) ||
        (batch[0] <= newBath[1] && batch[1] >= newBath[1]) ||
        (newBath[0] <= batch[0] && newBath[1] >= batch[0]) ||
        (newBath[0] <= batch[1] && newBath[1] >= batch[1])
      ) {
        newBath[0] = Math.min(batch[0], newBath[0]);
        newBath[1] = Math.max(batch[1], newBath[1]);
        return null;
      }
      return batch;
    })
    .filter((batch) => !!batch);
  return [newBath, ...newBatches].sort((a: [number], b: [number]) => a[0] - b[0]) as number[][];
}
