import { IGroupCounterView } from 'APP/model/counter/counterModel.types';
import { IMessage } from 'APP/model/message/messageModel.types';

import loadUnknownDataFromMessages from '../../relationships/loadUnknownDataFromMessages';
import loadUnknownGroupsByIds from '../../relationships/loadUnknownGroupsByIds';

export async function loadUnknownData(counters: IGroupCounterView[] = []): Promise<void> {
  const groupsIds: string[] = [];
  const messages: IMessage[] = [];

  counters.forEach((counter) => {
    groupsIds.push(counter.groupId);

    if (counter.lastMessage) {
      messages.push(counter.lastMessage);
    }
  });

  await Promise.all([
    loadUnknownGroupsByIds({
      groupsIds,
    }),
    loadUnknownDataFromMessages(
      {
        messages,
      },
      false
    ),
  ]);
}
