import { observer } from 'mobx-react';
import React, { FC, MouseEventHandler } from 'react';

import { useFileDownloadStatePresenter } from 'APP/hooks/useFileDownloadState/useFileDownloadState';
import PlayIcon from 'ICONS/ic-play-32.svg';
import {
  MediaDownloadState,
  TLoadIconSize,
} from 'MAIN/components/MediaDownloadState/MediaDownloadState';
import Image from 'STORE/Messages/Message/Payload/Image';
import Video from 'STORE/Messages/Message/Payload/Video';

import { ImagePreview } from '../ImagePreview/ImagePreview';
import { useMediaPreviewPresenter } from './MediaPreview.presenter';
import styles from './MediaPreview.styles.m.css';

interface IMediaPreviewProps {
  className?: string;
  imageClassName?: string;
  downloadStateClassName?: string;
  payloadId: string;
  groupId: string;
  messageId: string;
  payload: Image | Video;
  maxWidth?: number;
  maxHeight?: number;
  placeholderQuality?: number;
  needCropImage?: boolean;
  loadIconSize?: TLoadIconSize;
  isPreview?: boolean;
  isView?: boolean;
  isPlayIcon?: boolean;
  onClick?: MouseEventHandler<HTMLElement>;
}

export const MediaPreview: FC<IMediaPreviewProps> = observer((props) => {
  const {
    className,
    imageClassName,
    downloadStateClassName,
    payloadId,
    groupId,
    messageId,
    payload,
    maxWidth,
    maxHeight,
    placeholderQuality,
    needCropImage,
    loadIconSize,
    isPreview,
    isView,
    isPlayIcon,
    onClick,
  } = props;

  const presenter = useMediaPreviewPresenter(payload);
  const fileDownloadPresenter = useFileDownloadStatePresenter({ payloadId, groupId, messageId });

  return (
    <ImagePreview
      className={className}
      imageClassName={imageClassName}
      url={presenter.imageUrl}
      graphicSize={presenter.imageGraphicSize}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
      placeholderQuality={placeholderQuality}
      needCropImage={needCropImage}
      isPreview={isPreview}
      isView={isView}
      isLocal={presenter.isImageLocal}
      onClick={onClick}
    >
      {fileDownloadPresenter.downloadProgress && (
        <MediaDownloadState
          groupId={groupId}
          messageId={messageId}
          payloadId={payloadId}
          className={downloadStateClassName}
          loadIconSize={loadIconSize}
        />
      )}

      {isPlayIcon &&
        !fileDownloadPresenter.isWaitingDownloadProcess &&
        !fileDownloadPresenter.isDownloadInProcess && (
          <div className={styles.playIconHolder}>
            <PlayIcon
              className={styles.playIcon}
              onClick={onClick}
            />
          </div>
        )}
    </ImagePreview>
  );
});
