import { useLocalStore, useAsObservableSource } from 'mobx-react';

export const useMyContacts = ({ setSearchValue, contacts, searchContacts, isLoading }) => {
  const source = useAsObservableSource({
    contacts,
    searchContacts,
    isLoading,
  });

  const presenter = useLocalStore(() => ({
    get contacts() {
      return source.contacts;
    },

    get contactsIds() {
      return presenter.contacts.map((contact) => contact.id);
    },

    get searchContacts() {
      return source.searchContacts;
    },

    get isLoading() {
      return source.isLoading;
    },

    onSearchValueChange: (value) => {
      setSearchValue(value);
    },
  }));

  return presenter;
};
