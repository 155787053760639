import { observer } from 'mobx-react';
import React from 'react';
import { FC } from 'react';

import { ActiveSpeakerIcon } from 'APP/components/ActiveSpeakerIcon/ActiveSpeakerIcon';
import { Opponent } from 'STORE/Calls/Call/Opponent/Opponent';

import { MutedBadge } from '../MutedBadge/MutedBadge';

interface IAudioStateProps {
  opponent: Opponent | null;
  className?: string;
}

export const AudioState: FC<IAudioStateProps> = observer(({ opponent, className }) => {
  if (!opponent) {
    return null;
  }

  return (
    <div className={className}>
      {!opponent.hasAudio ? (
        <MutedBadge />
      ) : opponent.isActiveSpeaker ? (
        <ActiveSpeakerIcon isBig />
      ) : null}
    </div>
  );
});
