import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { GroupType } from 'APP/model/group/groupModel.types';
import { IFormContentProps } from 'APP/packages/form/Form/Form.types';
import { TAvatarBackgroundColorIndex } from 'UIKIT/Avatar/Avatar.types';
import { PopupHeader } from 'UIKIT/PopupHeader/PopupHeader';

import { useGroupFormPresenter } from './GroupForm.presenter';
import { IGroupFormInitialValues, TSubmitHandler } from './GroupForm.types';
import { GroupFormGeneral } from './GroupFormGeneral/GroupFormGeneral';
import { GroupFormMembers } from './GroupFormMembers/GroupFormMembers';

interface IGroupFormProps {
  title: string;
  initialValues: IGroupFormInitialValues;
  avatarTitle?: string;
  avatarColor?: TAvatarBackgroundColorIndex;
  isShowMembers?: boolean;
  isEditMode?: boolean;
  onSubmit: TSubmitHandler;
  onClose(): void;
  onFormStateChange(state: Partial<IFormContentProps>): void;
}

export const GroupForm: FC<IGroupFormProps> = observer((props) => {
  const {
    title,
    initialValues,
    avatarTitle,
    avatarColor,
    isShowMembers = false,
    isEditMode = false,
    onSubmit,
    onClose,
    onFormStateChange,
  } = props;

  const presenter = useGroupFormPresenter({ initialValues, isShowMembers, onSubmit });

  return (
    <>
      <PopupHeader
        title={title}
        handlePrevStep={presenter.isShowPrevStep ? presenter.onShowMembers : undefined}
        onClose={onClose}
      />
      {presenter.isShowMembers && (
        <GroupFormMembers
          membersIds={presenter.membersIds}
          onSetMembersIds={presenter.onSetMembersIds}
          onNextStep={presenter.onShowGeneralInfo}
          targetGroupType={GroupType.Closed}
        />
      )}
      {presenter.isShowGeneralInfo && (
        <GroupFormGeneral
          initialValues={presenter.groupData}
          avatarTitle={avatarTitle}
          avatarColor={avatarColor}
          isEditMode={isEditMode}
          membersIds={presenter.membersIds}
          onUpdateGroupData={presenter.onUpdateGroupData}
          onSubmit={presenter.onSubmit}
          onFormStateChange={onFormStateChange}
        />
      )}
    </>
  );
});
