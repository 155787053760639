import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { GroupType } from 'APP/model/group/groupModel.types';
import api from 'APP/packages/api';
import bus from 'APP/packages/bus';
import logger from 'APP/packages/logger';
import { RouterLink, RouterState } from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import Entities from 'APP/store';

export default () => {
  const history = useHistory();
  const navigateTo = useNavigateTo();

  const presenter = useLocalStore(() => ({
    isChecked: false,
    isLoading: false,

    get group() {
      return Entities.GroupsStore.activeGroup;
    },

    get isShow() {
      return presenter.group?.isOwner && presenter.group?.type === GroupType.ChannelOpen;
    },

    onClose() {
      history.goBack();
    },

    onClick() {
      navigateTo({
        to: RouterLink.market,
        state: {
          [RouterState.url]: Entities.marketplace.baseAdsUrl,
        },
      });
    },

    async init() {
      try {
        presenter.isLoading = true;
        const { adsEnabled } = await api.group.getGroupAdvertisementSettings({
          groupId: presenter.group.id,
        });
        this.isChecked = adsEnabled;
      } catch (e) {
        logger.get('API').error('setGroupAdvertisementSettings', e.data);
      }
      presenter.isLoading = false;
    },

    toggle() {
      presenter.isChecked = !presenter.isChecked;
    },

    onSave: () => {
      try {
        api.group.setGroupAdvertisementSettings({
          groupId: presenter.group.id,
          adsEnabled: presenter.isChecked,
        });
      } catch (e) {
        logger.get('API').error('setGroupAdvertisementSettings', e.data);
      }
      presenter.onClose();
    },
  }));

  useEffect(() => {
    presenter.init();
  }, []);

  useEffect(() => {
    return bus.group.subscribeAdsConfigChanges(presenter.group.id, ({ adsEnabled }) => {
      presenter.isChecked = adsEnabled;
    });
  }, [presenter.group.id]);

  return presenter;
};
