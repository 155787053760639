import { useLocalStore } from 'mobx-react';

import Entities from 'APP/store';

export default () => {
  const presenter = useLocalStore(() => ({
    get donationUrl() {
      const channel = Entities.GroupsStore.activeGroup;

      return channel.donationUrl;
    },
  }));

  return presenter;
};
