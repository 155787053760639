import { MEDIA_MAX_SIZE } from 'APP/constants/articles';
import { getFixedSizeImage, getFixedSizeBlurredImage } from 'APP/packages/imageResizer';

export default (url) => {
  const imageUrl = getFixedSizeImage(url, { ...MEDIA_MAX_SIZE });

  const blurredImageUrl = getFixedSizeBlurredImage(url, {
    ...MEDIA_MAX_SIZE,
    isNeedCrop: true,
  });

  return {
    imageUrl,
    blurredImageUrl,
  };
};
