import { DeepLinkResponse } from 'APP/packages/api/types/model/deepLinkResponse';

import { DeepLinkType, IDeepLink } from './deeplinksModel.types';

export const mapDeepLinkResponse = (data: DeepLinkResponse): IDeepLink => {
  return {
    ...data,
    type: data.type as string as DeepLinkType,
  };
};
