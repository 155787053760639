import { useRef, useCallback, useLayoutEffect, RefObject } from 'react';

import { IUserNameBadgeSource } from 'APP/constants/userBadges';
import { useAnimateSticker } from 'APP/packages/stickerAnimation/useAnimateSticker';
import Entities from 'APP/store';

interface IPresenterProps {
  animationUrl?: string;
  animationData?: IUserNameBadgeSource;
  autoplay?: boolean;
  displayUILayer?: number;
  inView: boolean;
  loop?: boolean;
}

interface IUseAnimationPresenter {
  stickerContainerRef: RefObject<HTMLSpanElement>;
}

export const useAnimationPresenter = (props: IPresenterProps): IUseAnimationPresenter => {
  const {
    animationUrl,
    displayUILayer,
    autoplay,
    animationData = undefined,
    loop = true,
    inView,
  } = props;

  const timeout = useRef<ReturnType<typeof setInterval> | null>(null);

  const onLoopComplete = useCallback(() => {
    if (!loop && animationRef.current) {
      animationRef.current.pause();
    }
  }, [loop]);

  const { stickerContainerRef, animationRef } = useAnimateSticker<HTMLSpanElement>({
    autoplay,
    url: animationUrl,
    animationData,
    onLoopComplete,
  });

  const onChangeInView = useCallback(
    (isInViewport) => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }

      if (!animationRef.current?.isLoaded) {
        timeout.current = setTimeout(() => {
          onChangeInView(isInViewport);
        }, 50);
        return;
      }

      if (isInViewport) {
        if (Entities.App.isFocused && displayUILayer === Entities.App.uiLayer) {
          animationRef.current.play();
        }
      } else {
        animationRef.current.pause();
      }
    },
    [animationRef, displayUILayer]
  );

  useLayoutEffect(() => {
    onChangeInView(inView);
    animationRef.current?.updateIsVisible(inView);
  }, [inView, onChangeInView]);

  useLayoutEffect(() => {
    if (Entities.App.isFocused && displayUILayer === Entities.App.uiLayer && inView) {
      animationRef.current?.play();
    } else {
      animationRef.current?.pause();
    }
  }, [animationRef, Entities.App.isFocused, Entities.App.uiLayer, inView, displayUILayer]);

  return {
    stickerContainerRef,
  };
};
