import execCommand from 'execcommand-copy';
import { useLocalStore } from 'mobx-react';

import { RewardProgressInfoRewardEnum } from 'APP/model/userSettings/userSettingsModel.types';
import { useTranslation } from 'APP/packages/translations';
import { IRewardProgressInfo } from 'APP/types/rewards';
import Entities from 'STORE';

export interface IRewardsInvitePresenter {
  rewardProgress: IRewardProgressInfo | null;
  rewardProgressBarPercent: number;
  copyLink(): void;
}

export const useRewardsInvitePresenter = (): IRewardsInvitePresenter => {
  const { t } = useTranslation();

  const presenter = useLocalStore<IRewardsInvitePresenter>(() => ({
    get rewardProgress(): IRewardProgressInfo | null {
      return (
        Entities.rewards.rewardsProgress.get(RewardProgressInfoRewardEnum.ExclusiveBackground1) ||
        null
      );
    },

    get rewardProgressBarPercent(): number {
      if (!presenter.rewardProgress) {
        return 0;
      }

      return (presenter.rewardProgress.currentPoints / presenter.rewardProgress.maxPoints) * 100;
    },

    copyLink(): void {
      // ToDo: add valid link in next story
      execCommand.copy('asd');
      Entities.toast.show(t('common_link_copied'));
    },
  }));

  return presenter;
};
