import { useLocalStore, useAsObservableSource } from 'mobx-react';

import dateService from 'APP/packages/date';
import Entities from 'APP/store';

export const useUserPresenter = ({ userId, closePopup }) => {
  const source = useAsObservableSource({ userId, closePopup });

  const presenter = useLocalStore(() => ({
    get user() {
      return Entities.UsersStore.getUserById(source.userId);
    },

    get avatarTitle() {
      return presenter.user?.avatarTitle || presenter.user?.name;
    },

    get avatarUrl() {
      return presenter.user?.avatarUrl || presenter.user?.avatar || presenter.user?.image;
    },

    get avatarBackgroundColorIndex() {
      return presenter.user?.avatarColorIndex;
    },

    get title() {
      return presenter.user?.displayName || presenter.user?.userName;
    },

    get subtitle() {
      const lastOnlineTs = presenter.user?.lastOnlineTs;
      const isToday = dateService.isToday(lastOnlineTs);
      const dateOptions = isToday
        ? { hour: 'numeric', minute: 'numeric' }
        : {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          };
      const formattedDate = dateService.format(lastOnlineTs, dateOptions);

      return `last seen at ${formattedDate ? formattedDate : ''}`;
    },

    get isDisabled() {
      return presenter.isLoading;
    },
  }));

  return presenter;
};
