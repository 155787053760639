import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { showAlert, showToast } from 'APP/Tasks/app/app';
import { ServerErrorCode } from 'APP/packages/api';
import { AuthServerErrorCode } from 'APP/packages/api/constants/auth.errors';
import { useTranslation } from 'APP/packages/translations';
import { ICountry } from 'APP/types/countryCodes';
import { clearPhoneNumber } from 'APP/utils/phone';

import { ICommonPopupsProps } from '../../PopupManager.types';

const errorMap: Record<string, string> = {
  [AuthServerErrorCode.PhoneNumberInvalid]: 'invalid_phone_number',
  [AuthServerErrorCode.PhoneNumberHasHighRiskScore]: 'account_banned_with_number_alert',
  [AuthServerErrorCode.PhoneHasRecentlyChanged]: 'account_edit_period_alert',
  [AuthServerErrorCode.PhoneNumberIsOccupiedByAnotherUser]: 'account_use_another_user',
  [AuthServerErrorCode.PhoneNumberIsOccupiedByMe]: 'account_use_you',

  [ServerErrorCode.BadData]: 'app_version_out_of_date_error',
  [ServerErrorCode.OutOfDateAppVersion]: 'app_version_out_of_date_error',
};

export enum ChangePhoneStep {
  PhoneNumber = 1,
  VerifySms = 2,
}

interface ChangePhoneData {
  country: string;
  phoneNumber: string;
}

interface IChangePhonePopupPresenter {
  countries: ICountry[];
  step: ChangePhoneStep;
  phoneStep: ChangePhoneData;
  title: string;
  pristine: boolean;

  prevStep(): Promise<void>;
  close(): void;
  init(): Promise<void>;
  submitPhone(data: ChangePhoneData): Promise<void>;
  submitVerifyCode(): void;
  hidePopup(): void;
  showPopup(): void;
}
export const useChangePhonePopupPresenter = ({
  popupInstance,
  onBack,
  onClose,
}: ICommonPopupsProps): IChangePhonePopupPresenter => {
  const { t } = useTranslation();

  const presenter = useLocalStore<IChangePhonePopupPresenter>(() => ({
    countries: [],
    phoneStep: {
      country: '',
      phoneNumber: '',
    },
    pristine: true,
    step: ChangePhoneStep.PhoneNumber,

    get title(): string {
      return presenter.step === ChangePhoneStep.PhoneNumber
        ? t('common_new_number')
        : t('verification_code');
    },

    async init(): Promise<void> {
      const { currentCountry, availableCounties } =
        await Tasks.countriesPhoneCodes.getCountriesPhoneCodes();

      presenter.countries = availableCounties;
      presenter.phoneStep = {
        country: currentCountry.countryCode,
        phoneNumber: currentCountry.phonePrefix,
      };
    },

    async prevStep(): Promise<void> {
      if (presenter.step === ChangePhoneStep.VerifySms) {
        presenter.step = ChangePhoneStep.PhoneNumber;
        await Tasks.authorization.changePhoneAbortConfirmation();
        return;
      }

      onBack?.();
      popupInstance.close();
    },

    close(): void {
      onClose?.();
      popupInstance.close();
    },

    async submitPhone(data: ChangePhoneData): Promise<void> {
      const { error } = await Tasks.authorization.requestChangePhoneVerifyCode({
        phone: clearPhoneNumber(data.phoneNumber),
      });

      if (error && errorMap[error]) {
        showAlert(t(errorMap[error]));
        return;
      }

      if (error) {
        showAlert(t('something_went_wrong'));
        return;
      }

      presenter.phoneStep = data;
      presenter.pristine = false;
      presenter.step = ChangePhoneStep.VerifySms;
    },

    submitVerifyCode(): void {
      showToast(t('account_created_alert'));

      Tasks.users.updateMePhone(presenter.phoneStep.phoneNumber);

      onBack?.();
      popupInstance.close();
    },

    hidePopup(): void {
      popupInstance.hide();
    },

    showPopup(): void {
      popupInstance.show();
    },
  }));

  useEffect(() => {
    presenter.init();
  }, []);

  return presenter;
};
