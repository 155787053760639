import { updateOldOrFakeMessages } from 'APP/Tasks/messaging/updateOldOrFakeMessages/updateOldOrFakeMessages';
import loadUnknownDataFromMessages from 'APP/Tasks/relationships/loadUnknownDataFromMessages';
import api from 'APP/packages/api';
import logger from 'APP/packages/logger';
import Entities from 'APP/store';

async function getMessages(data) {
  try {
    const response = await api.messaging.getRangeAroundMessage(data);

    return response.messages;
  } catch (error) {
    logger.get('API').error('messaging.getRangeAroundMessage', error);
  }

  return [];
}

export const loadRangeAroundMessages = async ({ groupId, messageId, halfLimit }) => {
  const group = Entities.GroupsStore.getGroupById(groupId);
  if (!group) {
    return;
  }

  group.messagesStore.setIsLoadingMessages(true);

  const messages = await getMessages({ groupId, messageId, halfLimit });
  await loadUnknownDataFromMessages({ messages });

  updateOldOrFakeMessages({ messages, groupId });

  group.messagesStore.setIsLoadingMessages(false);

  return messages;
};
