import { useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { ALERT_TYPES } from 'APP/constants/app';
import { ClientRole, StreamType } from 'APP/constants/calls';
import { MAX_COUNT_VIDEOS, STREAM_STATUS } from 'APP/constants/stream';
import AgoraClient from 'APP/packages/callProviders/AgoraClient/AgoraClient';
import { getDefaultDevices } from 'APP/packages/deviceInfo/getDefaultDevices';
import { ElectronApi } from 'APP/packages/electron/ElectronApi';
import logger from 'APP/packages/logger';
import Entities from 'STORE';

export default () => {
  const presenter = useLocalStore(() => ({
    audioVideoStreamer: null,
    shareScreenStreamer: null,

    audioMuted: true,
    videoMuted: true,
    isSharedScreen: false,

    audioProcessing: false,
    videoProcessing: false,
    sharingProcessing: false,

    holdMicrophonePromise: null,
    disposeVolumeIndicator: null,
    devices: {
      audioinput: null,
      audiooutput: null,
      videoinput: null,
    },

    get stream() {
      return Entities.ActiveStream.stream;
    },

    get isStreamStarted() {
      return presenter.stream.status === STREAM_STATUS.STARTED;
    },

    async init({ role }) {
      presenter.audioVideoStreamer = new AgoraClient({
        isScreenSharing: false,
        isDualStream: true,
        withVirtualBackground: false,
      });
      presenter.shareScreenStreamer = new AgoraClient({
        isScreenSharing: true,
        isDualStream: false,
      });

      presenter.devices = await getDefaultDevices();

      await presenter.audioVideoStreamer.createClient({
        role,
        devices: presenter.devices,
        handlers: {
          onUserPublished: presenter.onUserPublished,
          onUserUnpublished: presenter.onUserUnpublished,
          onVolumeIndicator: presenter.onVolumeIndicator,
          onLocalAudioTrackEnd: () => presenter.checkChangeDevices(),
          onLocalAudioTrackStart: (deviceId) => presenter.holdMicrophone(deviceId),
        },
      });
      await presenter.shareScreenStreamer.createClient({ role: ClientRole.Host });

      window.navigator.mediaDevices.addEventListener('devicechange', () =>
        this.checkChangeDevices()
      );
    },

    async join({ token, uid, screenUid, channelId }) {
      await presenter.audioVideoStreamer.join({
        token,
        uid,
        screenUid,
        channelId,
      });
    },

    async checkChangeDevices() {
      const devices = await getDefaultDevices();

      if (presenter.devices.audioinput?.deviceId !== devices.audioinput?.deviceId) {
        presenter.devices.audioinput = devices.audioinput;
        await presenter.stopHoldMicrophone();
        presenter.audioVideoStreamer.changeMicrophone(devices.audioinput);
      }

      if (presenter.devices.audiooutput?.deviceId !== devices.audiooutput?.deviceId) {
        presenter.devices.audiooutput = devices.audiooutput;
      }

      if (presenter.devices.videoinput?.deviceId !== devices.videoinput?.deviceId) {
        presenter.devices.videoinput = devices.videoinput;
      }
    },

    initVolumeIndicator() {
      if (!presenter.stream.uid) {
        return;
      }

      const smooth = [];
      const streamer = presenter.stream.findStreamerByUid(presenter.stream.uid);
      if (!streamer) {
        return;
      }

      const timer = setInterval(() => {
        const audioTrack = presenter.audioVideoStreamer?.localAudioTrack;
        if (!audioTrack) {
          streamer.setVolumeLevel(0);
          return;
        }
        smooth.push(Math.round(audioTrack.getVolumeLevel() * 100));
        if (smooth.length > 10) {
          smooth.shift();
        }
        streamer.setVolumeLevel(
          Math.round(smooth.reduce((acc, x) => (acc += x), 0) / smooth.length)
        );
      }, 100);

      return () => clearInterval(timer);
    },

    publishAudioVideo() {
      presenter.audioVideoStreamer.publish();
    },

    async unpublishAudioVideo() {
      await presenter.audioVideoStreamer.unpublish();
    },

    async publishScreen() {
      await presenter.shareScreenStreamer.publish();
    },

    async unpublishScreen() {
      await presenter.shareScreenStreamer.stopScreenShare();
    },

    onUserPublished(user, mediaType) {
      const streamer = presenter.stream.findStreamerByUid(user.uid);
      logger.get('AGORA').debug('onUserPublished', { user, mediaType, streamer });

      if (!streamer || user.uid === presenter.stream.shareScreenUid) {
        return;
      }

      if (mediaType === StreamType.Video) {
        if (user.uid === streamer.shareScreenUid) {
          streamer.setSharedScreen(user.videoTrack);
          presenter.stream.setActiveStreamer(user.uid, true);
        } else {
          streamer.setVideoTrack({
            track: user.videoTrack,
            hasVideo: user.hasVideo,
          });
        }
      }

      if (mediaType === StreamType.Audio) {
        streamer.setAudioTrack({
          track: user.audioTrack,
          hasAudio: user.hasAudio,
        });

        const viewer = presenter.stream.findViewer(streamer.userId);

        viewer && viewer.unmuteAudio();
      }
    },

    onUserUnpublished(user, mediaType) {
      const streamer = presenter.stream.findStreamerByUid(user.uid);
      logger.get('AGORA').debug('onUserUnpublished', { user, mediaType, streamer });

      if (!streamer || user.uid === presenter.stream.shareScreenUid) {
        return;
      }

      if (mediaType === StreamType.Video) {
        if (user.uid === streamer.shareScreenUid) {
          streamer.removeSharedScreen();
          presenter.stream.setActiveStreamer(user.uid, false);
        } else {
          streamer.removeVideoTrack();
        }
      }

      if (mediaType === StreamType.Audio) {
        streamer.removeAudioTrack();

        const viewer = presenter.stream.findViewer(streamer.userId);

        viewer && viewer.muteAudio();
      }
    },

    onVolumeIndicator(volumes) {
      volumes.forEach((volume) => {
        if (volume.uid === presenter.stream.uid) {
          return;
        }

        const streamer = presenter.stream.findStreamerByUid(volume.uid);

        streamer && streamer.setVolumeLevel(volume.level);
      });
    },

    async unmuteAudio() {
      const hasAudioPermission = await Tasks.permissions.hasMicrophonePermission();
      if (!hasAudioPermission || !presenter.audioMuted || presenter.audioProcessing) {
        presenter.audioProcessing = false;
        return false;
      }
      presenter.audioProcessing = true;
      await presenter.audioVideoStreamer.unmuteAudio(presenter.isStreamStarted);
      presenter.audioMuted = false;
      presenter.audioProcessing = false;

      if (!presenter.stream.uid) {
        return;
      }

      const streamer = presenter.stream.findStreamerByUid(presenter.stream.uid);

      streamer &&
        streamer.setAudioTrack({
          track: presenter.audioVideoStreamer.localAudioTrack,
          hasAudio: true,
        });

      presenter.disposeVolumeIndicator = presenter.initVolumeIndicator();
    },

    async muteAudio() {
      if (presenter.audioMuted || presenter.audioProcessing) {
        return false;
      }
      presenter.audioProcessing = true;
      await presenter.audioVideoStreamer.muteAudio();
      presenter.audioMuted = true;
      presenter.audioProcessing = false;

      if (!presenter.stream.uid) {
        return;
      }

      const streamer = presenter.stream.findStreamerByUid(presenter.stream.uid);

      streamer && streamer.removeAudioTrack();

      presenter.disposeVolumeIndicator && presenter.disposeVolumeIndicator();
      presenter.disposeVolumeIndicator = null;
    },

    holdMicrophone(deviceId) {
      if (presenter.holdMicrophonePromise) {
        return;
      }
      try {
        presenter.holdMicrophonePromise = navigator.mediaDevices.getUserMedia({
          audio: deviceId ? { deviceId } : true,
          video: false,
        });
      } catch (e) {
        console.warn(e);
      }
    },

    async stopHoldMicrophone() {
      if (presenter.holdMicrophonePromise) {
        const stream = await presenter.holdMicrophonePromise;

        stream.getTracks().forEach((track) => {
          track.stop();
        });
        presenter.holdMicrophonePromise = null;
      }
    },

    async unmuteVideo() {
      const hasCameraPermission = await Tasks.permissions.hasCameraPermission();
      if (!hasCameraPermission || !presenter.videoMuted || presenter.videoProcessing) {
        presenter.videoProcessing = false;
        return false;
      }

      if (presenter.stream.uid) {
        const isSomeoneSharingScreen = presenter.stream.streamers.some(
          (streamer) => streamer.isSharedScreen
        );
        const videosCount = presenter.stream.streamers.filter(
          (streamer) => streamer.hasVideo
        ).length;

        if (
          (videosCount === MAX_COUNT_VIDEOS - 1 && isSomeoneSharingScreen) ||
          videosCount === MAX_COUNT_VIDEOS
        ) {
          Tasks.app.addAlert({
            type: ALERT_TYPES.CALL_STREAM_MAX_VIDEO_COUNT,
          });

          return;
        }
      }

      presenter.videoProcessing = true;
      await presenter.audioVideoStreamer.unmuteVideo(presenter.isStreamStarted);
      presenter.videoMuted = false;
      presenter.videoProcessing = false;

      if (!presenter.stream.uid) {
        return;
      }

      const streamer = presenter.stream.findStreamerByUid(presenter.stream.uid);

      streamer &&
        streamer.setVideoTrack({
          track: presenter.audioVideoStreamer.localVideoTrack,
          hasVideo: true,
        });
    },

    async muteVideo() {
      if (presenter.videoMuted || presenter.videoProcessing) {
        return false;
      }
      presenter.videoProcessing = true;
      await presenter.audioVideoStreamer.muteVideo();
      presenter.videoMuted = true;
      presenter.videoProcessing = false;

      if (!presenter.stream.uid) {
        return;
      }

      const streamer = presenter.stream.findStreamerByUid(presenter.stream.uid);

      streamer && streamer.removeVideoTrack();
    },

    async startSharingScreen() {
      if (!presenter.isSharedScreen && !presenter.sharingProcessing) {
        const isSomeoneSharingScreen = presenter.stream.streamers.some(
          (streamer) => streamer.isSharedScreen
        );
        if (isSomeoneSharingScreen) {
          Tasks.app.addAlert({
            type: ALERT_TYPES.CALL_CANNOT_START_SHARING,
          });

          return;
        }

        presenter.sharingProcessing = true;

        let electronSourceId = null;

        if (ElectronApi.isEnabled) {
          electronSourceId = await Tasks.calls.getDesktopScreenForSharing();

          if (!electronSourceId) {
            return;
          }
        }

        const result = await presenter.shareScreenStreamer.startScreenShare({
          needToPublish: presenter.isStreamStarted,
          electronSourceId,
        });

        if (result && presenter.isStreamStarted) {
          await Tasks.streaming.startScreenSharing({
            channelId: presenter.stream.channelId,
          });
          await presenter.shareScreenStreamer.join({
            token: presenter.stream.shareScreenToken,
            uid: presenter.stream.shareScreenUid,
            screenUid: presenter.stream.shareScreenUid,
            channelId: presenter.stream.channelId,
          });
          await presenter.shareScreenStreamer.publish();
        }

        if (result) {
          result.getMediaStreamTrack().onended = () => {
            presenter.stopSharingScreen();
          };
        }
        presenter.isSharedScreen = Boolean(result);
        presenter.sharingProcessing = false;

        if (!presenter.stream.uid || !result) {
          return;
        }

        const streamer = presenter.stream.findStreamerByUid(presenter.stream.uid);

        streamer && streamer.setSharedScreen(presenter.shareScreenStreamer.localScreenTrack);

        presenter.stream.setActiveStreamer(presenter.stream.uid, true);
      }
    },

    async stopSharingScreen() {
      if (presenter.isSharedScreen && !presenter.sharingProcessing) {
        presenter.sharingProcessing = true;
        await presenter.shareScreenStreamer.stopScreenShare();

        if (presenter.isStreamStarted) {
          await presenter.shareScreenStreamer.leave();
          await Tasks.streaming.stopScreenSharing({
            channelId: presenter.stream.channelId,
          });
        }

        presenter.isSharedScreen = false;
        presenter.sharingProcessing = false;

        if (!presenter.stream.uid) {
          return;
        }

        const streamer = presenter.stream.findStreamerByUid(presenter.stream.uid);

        streamer && streamer.removeSharedScreen();
        presenter.stream.setActiveStreamer(presenter.stream.uid, false);
      }
    },

    get activeStreamer() {
      return presenter.stream?.activeStreamer;
    },

    setActiveStreamer(uid, isSharedScreen = false) {
      presenter.stream.setActiveStreamer(uid, isSharedScreen);
    },

    dispose() {
      presenter.audioVideoStreamer.dispose();
      presenter.shareScreenStreamer.dispose();
      presenter.stopHoldMicrophone();
      presenter.disposeVolumeIndicator && presenter.disposeVolumeIndicator();
      window.navigator.mediaDevices.removeEventListener('devicechange', () =>
        this.checkChangeDevices()
      );
    },
  }));

  return presenter;
};
