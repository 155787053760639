import Tasks from 'APP/Tasks';
import { ALERT_TYPES } from 'APP/constants/app';
import Entities from 'STORE';

import { GroupsEvent } from '../GroupsEvent';

export class GroupWasDeleted extends GroupsEvent {
  process() {
    const { groupId } = this.eventPayload;

    const group = Entities.GroupsStore.getGroupById(groupId.toString());
    if (!group) {
      return;
    }
    let alert = { type: ALERT_TYPES.CHAT_IS_NOT_ACCESSIBLE };

    if (group.isChannel) {
      alert = { type: ALERT_TYPES.CHANNEL_IS_NOT_ACCESSIBLE };
    }

    Tasks.group.removeGroupLocally({
      groupId,
      alert,
    });
  }
}
