export enum AnalyticsSpaceEvent {
  SpaceOnboardingSkipped = 'space_onboarding_skipped',
  SpaceOnboardingCompleted = 'space_onboarding_completed',
  SpaceDeleted = 'space_deleted',
  SpaceNewCreated = 'space_new_created',
  SpaceJoined = 'space_joined',
  SpaceLeft = 'space_left',
  SpacePinned = 'space_pinned',
  SpaceUnpined = 'space_unpined',
  SpaceMarkedAsRead = 'space_marked_as_read',
  SpaceChannelCreated = 'space_channel_created',
  SpaceGroupCreated = 'space_group_created',
  SpaceGroupAdded = 'space_group_added',
  SpaceGroupDeleted = 'space_group_deleted',
  SpaceChannelAdded = 'space_channel_added',
  SpaceChannelDeleted = 'space_channel_deleted',
  SpaceMarketAdded = 'space_market_added',
  SpaceMarketDeleted = 'space_market_deleted',
  SpacePopularClicked = 'space_popular_clicked',
  SpaceSuggestedClicked = 'space_suggested_clicked',
  SpaceCollectionCreated = 'space_collection_created',
  SpaceCollectionDeleted = 'space_collection_deleted',
  SpaceAddedToCollection = 'space_added_to_collection',
  SpaceCollectionViewed = 'space_collection_viewed',
  SpaceMemberRemoved = 'space_member_removed',
  SpaceMemberBlocked = 'space_member_blocked',
  SpaceAdminAdded = 'space_admin_added',
  SpaceAdminRemoved = 'space_admin_removed',
  SpaceTransferOwnership = 'space_transfer_ownership',
  SpaceGroupChatVisibleAllChats = 'space_group_chat_visible_allchats',
  SpaceGroupChatInvisibleAllChats = 'space_group_chat_invisible_allchats',
  SpaceChannelVisibleAllChats = 'space_channel_visible_allchats',
  SpaceChannelInvisibleAllChats = 'space_channel_invisible_allchats',
}

export enum SpaceJoinType {
  DeepLink = 'deeplink',
  JoinButton = 'join button',
}
