import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { CallState } from 'MAIN/OngoingCall/Shared/CallState/CallState';
import { Call } from 'STORE/Calls/Call/Call';

import { CompactCallViewHeader } from '../../Shared/CompactCallViewHeader/CompactCallViewHeader';
import styles from './P2PVideoCallHeader.styles.m.css';

interface IP2PVideoCallHeaderProps {
  call: Call;
}

export const P2PVideoCallHeader: FC<IP2PVideoCallHeaderProps> = observer(({ call }) => {
  return (
    <CompactCallViewHeader>
      <div className={styles.info}>
        <div className={styles.groupTitle}>{call.avatar.title}</div>
        <div className={styles.callState}>
          <CallState call={call} />
        </div>
      </div>
    </CompactCallViewHeader>
  );
});
