import { computed, observable, action } from 'mobx';

import Entities from 'APP/store';

class User {
  @observable userId = null;

  constructor(commonStore, { userId }) {
    this.commonStore = commonStore;
    this.userId = userId;
  }

  @computed
  get user() {
    return Entities.UsersStore.getUserById(this.userId);
  }

  get avatarTitle() {
    return this.user.avatarTitle || this.user.name;
  }

  get avatarUrl() {
    return this.user.avatarUrl || this.user.avatar || this.user.image;
  }

  get avatarBackgroundColorIndex() {
    return this.user.avatarColorIndex;
  }

  get title() {
    return this.user.displayName;
  }

  get subtitle() {
    return this.user.displayPhoneOrNickname;
  }

  get isMe() {
    return this.user.isMe;
  }

  @action
  onDelete = () => {
    this.commonStore.deleteMyReactions();
  };
}

export default User;
