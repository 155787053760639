import { useAsObservableSource, useLocalStore } from 'mobx-react';

import { mapUserProfileBadgeToIcon } from 'APP/constants/userBadges';
import { UserProfileUsernameBadge } from 'APP/model/user/userModel.types';
import { IUserNameBadge } from 'APP/types/userNameBadges';
import type { User } from 'STORE/Users/User/User';

interface ISenderTitlePresenter {
  userNameBadge: IUserNameBadge | null;
  verified: boolean;
}

export const useSenderTitlePresenter = (user: User): ISenderTitlePresenter => {
  const source = useAsObservableSource({
    user,
  });

  return useLocalStore<ISenderTitlePresenter>(() => ({
    get userNameBadge(): IUserNameBadge | null {
      if (!source.user.usernameBadge) {
        return null;
      }

      return mapUserProfileBadgeToIcon[source.user.usernameBadge as UserProfileUsernameBadge];
    },

    get verified(): boolean {
      return source.user.verified;
    },
  }));
};
