import { observer } from 'mobx-react';
import React from 'react';

import { IconButton, IconViewSizes } from 'APP/packages/UiKit/IconButton';
import { AddKeyPressHandler } from 'APP/packages/keyBoardHandler';
import { useTranslation } from 'APP/packages/translations';
import CloseIcon from 'ICONS/close.svg';
import CopyIcon from 'ICONS/copy.svg';
import DeleteIcon from 'ICONS/ic-delete-32.svg';
import { Button } from 'UIKIT/Button/Button';

import { useMultiSelectPresenter } from './MultiSelectPanel.presenter';
import styles from './MultiSelectPanel.styles.m.scss';

export const MultiSelectPanel = observer(() => {
  const presenter = useMultiSelectPresenter();
  const { t } = useTranslation();

  const header = t('multiselect_menu_with_options_web', { 0: presenter.amountOfMessages });

  return (
    <>
      <AddKeyPressHandler onEsc={presenter.close} />
      <div className={styles.container}>
        <div className={styles.messageCount}>
          <IconButton
            Icon={CloseIcon}
            onClick={presenter.close}
          />
          <span className={styles.counter}>{header}</span>
        </div>
        {presenter.hasSelectedMessages && (
          <div className={styles.actionContainer}>
            {presenter.canBeDeleted && (
              <IconButton
                Icon={DeleteIcon}
                size={IconViewSizes.Big}
                onClick={presenter.delete}
              />
            )}
            {presenter.canBeCopied && (
              <IconButton
                Icon={CopyIcon}
                className={styles.indent}
                onClick={presenter.copyToClipboard}
              />
            )}
            {presenter.canBeForwarded && (
              <Button
                className={styles.indent}
                theme="6"
                onClick={presenter.forward}
              >
                {t('message_forward')}
              </Button>
            )}
            {presenter.canBeSendNow && (
              <Button
                className={styles.indent}
                theme="6"
                onClick={presenter.sendNow}
              >
                {t('common:common_send_now')}
              </Button>
            )}
          </div>
        )}
      </div>
    </>
  );
});
