import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { defaultAudioPlayerConfig } from 'UIKIT/Media/AudioPlayer/AudioPlayer.constants';
import { useAudioPlayerTrackManager } from 'UIKIT/Media/AudioPlayer/AudioPlayerTrackManager.presenter';
import { AudioPlayerUI } from 'UIKIT/Media/AudioPlayer/AudioPlayerUI/AudioPlayerUI';

import { IMiniPlayerProps } from '../MiniPlayer.types';
import { useMiniRoundVideoPlayerPresenter } from './MiniRoundVideoPlayer.presenter';

export const MiniRoundVideoPlayer: FC<IMiniPlayerProps> = observer((props) => {
  const {
    className,
    track,
    playList,
    isPlaying,
    config,
    progress,
    onConfigUpdate,
    onChangeTrack,
    onTitleClick,
    onClose,
  } = props;

  const presenter = useMiniRoundVideoPlayerPresenter(props);

  const trackPresenter = useAudioPlayerTrackManager({
    activeTrack: track,
    playList,
    onChangeTrack,
  });

  return (
    <AudioPlayerUI
      className={className}
      isPlaying={isPlaying}
      hasPrevious={trackPresenter.hasPrevious}
      hasNext={trackPresenter.hasNext}
      config={{ ...defaultAudioPlayerConfig, ...config }}
      track={track}
      playList={playList}
      progress={progress || 0}
      progressMax={track?.duration || 0}
      onPlay={presenter.onPlay}
      onPause={presenter.onPause}
      onTogglePlay={presenter.onTogglePlay}
      onChangeTrack={onChangeTrack}
      onChangeProgress={presenter.onProgressChange}
      onTitleClick={onTitleClick}
      onChangeConfig={onConfigUpdate}
      onChangeSpeed={presenter.onSpeedChange}
      onPrevious={trackPresenter.onPrevious}
      onNext={trackPresenter.onNext}
      onClose={onClose}
    />
  );
});
