import { useLocalStore } from 'mobx-react';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { showConfirm } from 'APP/Tasks/app/showConfirm/showConfirm';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';

interface IFormState {
  pristine: boolean;
  submitting: boolean;
}

interface ICloseFormPopupOnNavigation {
  isWaitingConfirm: boolean;
  formState: Partial<IFormState> | null;
  onFormStateChange(state: Partial<IFormState> | null): void;
  closePopup(): Promise<boolean>;
  backPopup(): Promise<boolean>;
  tryToClose(): Promise<boolean>;
}

export function useCloseFormPopup(
  { popupInstance, onClose, onBack }: Omit<ICommonPopupsProps, 'params'>,
  promptText: string,
  onNavigation?: (isClosed: boolean) => void
): ICloseFormPopupOnNavigation {
  const location = useLocation();
  const initLocationRef = useRef(location);

  const presenter = useLocalStore<ICloseFormPopupOnNavigation>(() => ({
    formState: null,
    isWaitingConfirm: false,

    onFormStateChange(state: Partial<IFormState>): void {
      presenter.formState = state;
    },

    async closePopup(): Promise<boolean> {
      const isClosed = await presenter.tryToClose();
      isClosed && onClose?.();
      return isClosed;
    },

    async backPopup(): Promise<boolean> {
      const isClosed = await presenter.tryToClose();
      isClosed && onBack?.();
      return isClosed;
    },

    async tryToClose(): Promise<boolean> {
      if (presenter.isWaitingConfirm) {
        return false;
      }

      let canClose = true;

      if (presenter.formState && !presenter.formState.pristine && !presenter.formState.submitting) {
        this.isWaitingConfirm = true;
        canClose = await showConfirm({ text: promptText });
        this.isWaitingConfirm = false;
      }

      onNavigation?.(canClose);

      if (canClose) {
        popupInstance.close();
        return true;
      }

      return false;
    },
  }));

  useEffect(() => {
    if (initLocationRef.current.pathname !== location.pathname) {
      presenter.closePopup();
    }
  }, [location, popupInstance]);

  return presenter;
}
