import React from 'react';

import { useTranslation } from 'APP/packages/translations';

import { MiniatureMessageLayout } from '../components/MiniatureMessageLayout/MiniatureMessageLayout';
import usePresenter from './presenter';

const Advertisement = ({ titleClassName, textClassName, message, title, onClick }) => {
  const imageUrl = usePresenter({ message });
  const { t } = useTranslation();

  if (!message) {
    return null;
  }

  return (
    <MiniatureMessageLayout
      titleClassName={titleClassName}
      textClassName={textClassName}
      imageUrl={imageUrl}
      title={title}
      text={t('calls:common_advertising')}
      messageId={message.id}
      onClick={onClick}
    />
  );
};

export default Advertisement;
