import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { PageType } from 'APP/router/MessengerRoutes/constants';
import { RouterPage } from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import Entities from 'STORE';
import { Group } from 'STORE/Groups/Group';
import { Space } from 'STORE/Spaces/Space';
import { getSortedGroups } from 'UTILS/getSortedGroups';

import { SidebarPanelMode } from './SidebarPanel.constants';

interface ISidebarPanelPresenter {
  panelMode: SidebarPanelMode;
  searchText: string;
  isSearchMode: boolean;
  group: Group | null;
  parentGroup: Group | null;
  space: Space | null;
  isShowHeader: boolean;
  isShowAllGroups: boolean;
  isShowChats: boolean;
  isShowChannels: boolean;
  isShowSpaces: boolean;
  isShowSpace: boolean;
  isShowSearchResults: boolean;
  isShowNewMessageButton: boolean;
  allGroups: Group[];
  chats: Group[];
  channels: Group[];
  onShowSpace(spaceId: string): void;
  onSearchChange(searchText: string): void;
  onPanelModeChange(panelMode: SidebarPanelMode): void;
}

export const useSidebarPanelPresenter = (pageType: PageType): ISidebarPanelPresenter => {
  const navigateTo = useNavigateTo();
  const source = useAsObservableSource({ navigateTo, pageType });

  const presenter = useLocalStore<ISidebarPanelPresenter>(() => ({
    panelMode: SidebarPanelMode.AllGroups,
    searchText: '',

    get isSearchMode(): boolean {
      return presenter.searchText.trim().length > 0;
    },

    get group(): Group | null {
      return Entities.GroupsStore.activeGroup;
    },

    get space(): Space | null {
      return Entities.spacesStore.activeSpace;
    },

    get parentGroup(): Group | null {
      return presenter.group?.parentGroup || null;
    },

    get isShowHeader(): boolean {
      return presenter.panelMode !== SidebarPanelMode.Space;
    },

    get isShowAllGroups(): boolean {
      return !presenter.isShowSearchResults && presenter.panelMode === SidebarPanelMode.AllGroups;
    },

    get isShowChats(): boolean {
      return (
        !presenter.isShowSearchResults &&
        [SidebarPanelMode.None, SidebarPanelMode.Chats].includes(presenter.panelMode)
      );
    },

    get isShowChannels(): boolean {
      return !presenter.isShowSearchResults && presenter.panelMode === SidebarPanelMode.Channels;
    },

    get isShowSpaces(): boolean {
      return !presenter.isShowSearchResults && presenter.panelMode === SidebarPanelMode.Spaces;
    },

    get isShowSpace(): boolean {
      return !presenter.isShowSearchResults && presenter.panelMode === SidebarPanelMode.Space;
    },

    get isShowSearchResults(): boolean {
      return presenter.isSearchMode && presenter.isShowHeader;
    },

    get isShowNewMessageButton(): boolean {
      return (
        presenter.isShowAllGroups ||
        presenter.isShowChats ||
        presenter.isShowChannels ||
        presenter.isShowSpaces
      );
    },

    get allGroups(): Group[] {
      return getSortedGroups(Entities.GroupsStore.groups);
    },

    get chats(): Group[] {
      return getSortedGroups(Entities.GroupsStore.chatGroups);
    },

    get channels(): Group[] {
      return getSortedGroups(Entities.GroupsStore.channels);
    },

    onShowSpace(spaceId: string): void {
      source.navigateTo({ to: `/${RouterPage.Spaces}/${spaceId}` });
      Tasks.space.setActiveSpace(spaceId);
      presenter.panelMode = SidebarPanelMode.Space;
    },

    onSearchChange(searchText: string): void {
      presenter.searchText = searchText;
    },

    onPanelModeChange(panelMode: SidebarPanelMode): void {
      presenter.panelMode = panelMode;
      presenter.searchText = '';
    },
  }));

  useEffect(() => {
    if (presenter.space) {
      presenter.panelMode = SidebarPanelMode.Space;
    } else if (presenter.panelMode === SidebarPanelMode.Space) {
      presenter.panelMode = SidebarPanelMode.Spaces;
    }
  }, [presenter.space]);

  useEffect(() => {
    if (presenter.panelMode !== SidebarPanelMode.Space) {
      Tasks.space.setActiveSpace(null);
    }
  }, [presenter.panelMode]);

  return presenter;
};
