import { observer } from 'mobx-react';
import React from 'react';

import { Image } from './Image';
import { Video } from './Video';
import usePresenter from './presenter';
import styles from './styles.m.css';

const payloadComponent = {
  Video,
  Image,
};

const Album = ({ message, isPreview, isView }) => {
  const presenter = usePresenter({ message });

  return (
    <div className={styles.album}>
      {presenter.layout.map((row) => (
        <div
          key={row.id}
          className={styles.row}
        >
          {row.items.map((item) => {
            const isFromMe = presenter.isFromMe;
            const payload = presenter.getPayload(item.id);
            const Component = payloadComponent[payload.payload.payloadType];

            return (
              <div
                key={item.id}
                className={styles.itemContainer}
                style={{ width: `${item.containerWidth}%` }}
              >
                <div style={{ paddingBottom: `${item.containerRatio * 100}%` }} />
                <Component
                  payload={payload.payload}
                  isLoading={message.isFake}
                  isPreview={isPreview}
                  isView={isView}
                  isFromMe={isFromMe}
                  openGallery={() => {
                    presenter.openMediaGallery({ mediaId: payload.id });
                  }}
                  groupId={message.groupId}
                  messageId={message.id}
                  payloadId={payload.id}
                />
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default observer(Album);
