import { format } from 'bytes';
import classNames from 'classnames';
import React, { FC, memo } from 'react';

import date from 'APP/packages/date';

import styles from './AudioPlayerTrackCaption.styles.m.css';

interface IAudioPlayerTrackCaptionProps {
  className?: string;
  isPlaying: boolean;
  duration: number;
  progress?: number;
  size?: number;
  additionalInfo?: string;
  delimiter?: string;
}

export const AudioPlayerTrackCaption: FC<IAudioPlayerTrackCaptionProps> = memo(
  ({ className, isPlaying, duration, progress, size, additionalInfo, delimiter = ',' }) => {
    const formattedSize = size !== undefined ? format(size, { unitSeparator: ' ' }) : null;
    const formattedDuration = date.secondsToString(duration);
    const formattedProgress = date.secondsToString(progress);

    let text: string;

    if (!isPlaying) {
      text = formattedDuration;

      if (formattedSize) {
        text += `${delimiter} ${formattedSize}`;
      }

      if (additionalInfo) {
        text += ` ${delimiter} ${additionalInfo}`;
      }
    } else {
      text = `${formattedProgress} / ${formattedDuration}`;
    }

    return <div className={classNames(styles.caption, className)}>{text}</div>;
  }
);
