import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { SelectCategoryPopup } from 'MAIN/Popups/GetParametersManager/Popups/SelectCategoryPopup/SelectCategoryPopup';

import { useFirstLoginHandlerPresenter } from './FirstLoginHandler.presenter';

export const FirstLoginHandler: FC = observer(() => {
  const { isCategoryPopupOpened, onCategoryPopupClose } = useFirstLoginHandlerPresenter();

  if (!isCategoryPopupOpened) {
    return null;
  }

  return <SelectCategoryPopup onClose={onCategoryPopupClose} />;
});
