import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useRef, useState } from 'react';
import { InView } from 'react-intersection-observer';

import { Opponent } from 'STORE/Calls/Call/Opponent/Opponent';

import styles from './OpponentVideoView.styles.m.css';

export interface IOpponentVideoViewProps {
  opponent: Opponent;
  className?: string;
}

export const OpponentVideoView: FC<IOpponentVideoViewProps> = observer(
  ({ opponent, className }) => {
    const [inView, setInView] = useState(false);
    const ref = useRef<HTMLDivElement | null>(null);

    const onChangeInView = (isInViewport: boolean): void => {
      setInView(isInViewport);
    };

    const inViewProps = {
      onChange: onChangeInView,
      threshold: 0.2,
      className: classNames(styles.container, className),
    };

    useEffect(() => {
      if (opponent.hasVideo && inView && ref.current) {
        opponent.playVideo(ref.current);
      }

      return () => {
        if (opponent.isPlayingVideo) {
          opponent.stopVideo();
        }
      };
    }, [ref, inView, opponent.uid]);

    return (
      <InView
        as="div"
        {...inViewProps}
      >
        <div
          ref={ref}
          className={classNames(styles.container, className)}
        />
      </InView>
    );
  }
);
