import classNames from 'classnames';
import React, { FC } from 'react';

import styles from './DotLoader.styles.m.css';

interface IDotLoaderProps {
  readonly className?: string;
  readonly itemClassName?: string;
}

export const DotLoader: FC<IDotLoaderProps> = ({ className, itemClassName }) => {
  return (
    <div className={classNames(styles.container, className)}>
      <div className={classNames(styles.item, styles.item1, itemClassName)} />
      <div className={classNames(styles.item, styles.item2, itemClassName)} />
      <div className={classNames(styles.item, styles.item3, itemClassName)} />
    </div>
  );
};
