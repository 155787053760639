import Entities from 'STORE';

import { GroupsEvent } from '../GroupsEvent';

export class SpaceAllVisibilityUpdated extends GroupsEvent {
  async process() {
    const { spaceId, visibleOutsideSpace } = this.eventPayload;

    const space = Entities.spacesStore.getById(spaceId);
    if (!space) {
      return false;
    }

    space.groupVisibility.setAllGroupsVisible(visibleOutsideSpace);
  }
}
