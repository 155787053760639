import Tasks from 'APP/Tasks';
import { AudioSourceType } from 'APP/constants/app';
import { CallType, CallProviderType } from 'APP/model/call/callModel.types';
import { t } from 'MODULE/i18n/service';
import Entities from 'STORE';
import { Call } from 'STORE/Calls/Call/Call';

interface IJoinToCallData {
  groupId?: string;
  callType: CallType;
  channelId: string;
  withAudio: boolean;
  withVideo: boolean;
  providerType: CallProviderType;
}

export const joinToCall = async ({
  groupId,
  callType,
  channelId,
  withAudio,
  withVideo,
  providerType,
}: IJoinToCallData): Promise<void> => {
  if (!groupId) {
    return;
  }

  if (Entities.Calls.hasCalls || Entities.ActiveStream.hasStream) {
    Tasks.app.showAlert(t('calls_join_another_call'));

    return;
  }

  const call = new Call({
    calls: Entities.Calls,
    groupId,
    channelId,
    callType,
    providerType,
  });
  Entities.Calls.add(call.groupId, call);
  Entities.IncomingCalls.delete(groupId, channelId);

  const permissionsRequest = withVideo
    ? Tasks.permissions.hasMediaPermissions
    : Tasks.permissions.hasAudioPermissions;

  const hasPermissions = await permissionsRequest(callType === CallType.P2P);

  if (!hasPermissions) {
    Tasks.calls.decline({
      groupId,
      channelId,
      providerType,
    });

    call.delete();
    return;
  }

  Tasks.app.audioSource.setCurrentSource(AudioSourceType.Call);

  await Tasks.calls.startCall({
    groupId,
    channelId,
    withAudio,
    withVideo,
    callType,
  });
};
