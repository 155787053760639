import { useTranslation } from 'APP/packages/translations';

import channelCategory from '../fields/category';
import channelAlias from '../fields/channelAlias';
import channelName from '../fields/channelName';
import asyncValidation from '../hooks/asyncValidation';
import syncValidation from '../hooks/syncValidation';

export default () => {
  const { t } = useTranslation();

  const channelNameErrorToText = {
    [channelName.ERRORS.IS_EMPTY]: ' ',
    [channelName.ERRORS.TOO_SHORT]: t('username_min_length_error'),
  };

  const channelCategoryErrorToText = {
    [channelCategory.ERRORS.IS_EMPTY]: ' ',
  };

  const channelAliasErrorToText = {
    [channelAlias.ERRORS.IS_EMPTY]: ' ',
    [channelAlias.ERRORS.TOO_SHORT]: t('username_min_length_error'),
    [channelAlias.ERRORS.INCORECT_SYMBOLS]: t('channel_alias_allowed_symbols_error'),
    [channelAlias.ERRORS.IS_ALREADY_EXIST]: t('channel_link_is_not_available'),
  };

  const channelNameValidator = syncValidation();
  const channelCategoryValidator = syncValidation();
  const channelAliasValidator = asyncValidation();

  return {
    channelName: {
      validate: (value, formData, meta) => {
        return channelNameValidator({
          value,
          meta,
          validate: channelName.validate,
          errorToText: channelNameErrorToText,
        });
      },
    },
    channelCategory: {
      validate: (value, formData, meta) => {
        return channelCategoryValidator({
          value,
          meta,
          validate: channelCategory.validate,
          errorToText: channelCategoryErrorToText,
        });
      },
    },
    channelAliasCreate: {
      validate: (value, formData, meta) => {
        return channelAliasValidator({
          value,
          values: formData,
          meta,
          validate: channelAlias.validateCreate,
          asyncValidate: channelAlias.asyncValidate,
          errorToText: channelAliasErrorToText,
        });
      },
    },
    channelAliasEdit: {
      validate: (value, formData, meta) => {
        return channelAliasValidator({
          value,
          values: formData,
          meta,
          validate: channelAlias.validateEdit,
          asyncValidate: channelAlias.asyncValidate,
          errorToText: channelAliasErrorToText,
        });
      },
    },
  };
};
