import { useLocalStore, useAsObservableSource } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { ALERT_TYPES } from 'APP/constants/app';
import { RouterState } from 'APP/router/constants';
import { useUserProfile } from 'MAIN/hooks/userProfile/useUserProfile';
import Entities from 'STORE';
import { Group } from 'STORE/Groups/Group';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';
import { Space } from 'STORE/Spaces/Space';
import { TAvatarBackgroundColorIndex } from 'UIKIT/Avatar/Avatar.types';

import { IForwardMessageLocalStore } from './useForwardMessage.types';

export const useForwardMessage = (message: ChatMessage) => {
  const userProfile = useUserProfile(message.forward?.senderId, {
    groupId: message.groupId,
    messageId: message.id,
  });

  const source = useAsObservableSource({ message });

  const presenter = useLocalStore<IForwardMessageLocalStore>(() => ({
    isLoading: false,

    get avatarTitle(): string {
      return source.message.forward?.avatarTitle || '';
    },

    get avatarUrl(): string {
      return source.message.avatarUrl || '';
    },

    get avatarColorIndex(): TAvatarBackgroundColorIndex {
      return source.message.forward?.avatarColorIndex || '0';
    },

    get group(): Group | null {
      return source.message.forward?.groupFrom || null;
    },

    get space(): Space | null {
      return presenter.group?.spaceId
        ? Entities.spacesStore.getById(presenter.group.spaceId)
        : null;
    },

    async loadGroupData(): Promise<void> {
      if (!source.message.forward || (presenter.group && !presenter.group.withoutMe)) {
        return;
      }

      presenter.isLoading = true;

      await Tasks.group.loadGroupsByIds([source.message.forward.groupId]);

      if (presenter.group?.spaceId && (!presenter.space || presenter.space.isGuest)) {
        await Tasks.space.loadSpace(presenter.group.spaceId);
      }

      presenter.isLoading = false;
    },

    async loadSenderData(): Promise<void> {
      if (!source.message.forward || source.message.forward.senderUser?.id) {
        return;
      }

      presenter.isLoading = true;
      await Tasks.relationships.loadUnknownUsersByIds({
        usersIds: [source.message.forward.senderId],
      });
      presenter.isLoading = false;
    },

    async onGroupClick(): Promise<void> {
      await presenter.loadGroupData();

      if (
        !presenter.group ||
        (presenter.group.isClosed && presenter.group.withoutMe) ||
        (presenter.group.spaceId &&
          (!presenter.space || (presenter.space.isPrivate && presenter.space.isGuest)))
      ) {
        Tasks.app.addAlert({ type: ALERT_TYPES.CHANNEL_IS_NOT_AVAILABLE });
        return;
      }

      await Tasks.group.setActiveGroup({
        groupId: presenter.group.id,
        state: {
          [RouterState.hasGoBack]: true,
          [RouterState.groupId]: source.message.groupId,
          [RouterState.messageId]: source.message.id,
        },
      });
    },

    async onSenderClick(): Promise<void> {
      await presenter.loadSenderData();
      userProfile.show();
    },

    onClick(): void {
      if (presenter.isLoading) {
        return;
      }

      if (source.message.forward?.isFromChannel) {
        presenter.onGroupClick();
      } else {
        presenter.onSenderClick();
      }
    },
  }));

  return presenter;
};
