import { useLocation } from 'react-router-dom';

import { prepareLink } from 'UTILS/prepareLink';

export default () => {
  const location = useLocation();

  return (data = {}) => {
    return prepareLink({ ...data, location });
  };
};
