import React from 'react';
import { NavLink } from 'react-router-dom';

import { RouterLink } from 'APP/router/constants';
import { getHref, getPathname, matchPath } from 'UTILS/url';

import { IRenderer } from '../renderTree.types';

interface ILinkRendererOptions {
  link: string;
}

export const LinkRenderer: IRenderer<ILinkRendererOptions> = (props) => {
  const { children, options } = props;

  if (!children) {
    return null;
  }

  const href = getHref(options.link);
  const pathname = getPathname(options.link);
  const isAppPath = matchPath(RouterLink, pathname);

  if (href.includes(window.location.hostname) && isAppPath) {
    return <NavLink to={pathname}>{children}</NavLink>;
  }

  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  );
};
