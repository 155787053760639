import File from 'STORE/Messages/Message/Payload/File';
import { getAudioFileDuration } from 'UTILS/audio/getAudioFileDuration';

class AudioPayload extends File {
  constructor(data) {
    super(data);

    this.duration = data.duration;
    this.artist = data.artist;
    this.title = data.title;
  }
  toJSON() {
    const data = super.toJSON();

    return {
      ...data,
      duration: this.duration,
      artist: this.artist || null,
      title: this.title || null,
    };
  }

  async upload() {
    await super.upload();
    if (this.isLoaded && !this.duration) {
      this.duration = await getAudioFileDuration(this.url);
    }
  }
}

export default AudioPayload;
