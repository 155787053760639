import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { GridLayout } from 'MAIN/OngoingCall/Shared/GridLayout/GridLayout';
import { FULL_GRID_VIEW_SIZE } from 'MAIN/OngoingCall/constants/views';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';

import styles from './CompactScreenVideoLayout.styles.m.css';

export const CompactScreenVideoLayout = observer(() => {
  const getClassName = (opponentsLength: number): string => {
    const mainClassName =
      opponentsLength >= FULL_GRID_VIEW_SIZE
        ? styles.videoLayoutFullGrid
        : styles[`videoLayout${opponentsLength}`];

    return classNames(styles.videoLayout, mainClassName, customScrollStyles.container);
  };

  return (
    <GridLayout
      avatarLargeType={AvatarTypes.Round_40}
      avatarMediumType={AvatarTypes.Round_40}
      getClassName={getClassName}
    />
  );
});
