import { observer } from 'mobx-react';
import React from 'react';

import { AddKeyPressHandler } from 'APP/packages/keyBoardHandler';
import { useTranslation } from 'APP/packages/translations';
import BackIcon from 'ICONS/ic-back.svg';
import IcDone from 'ICONS/ic-done.svg';

import Border from '../components/Border';
import Button from '../components/Button';
import usePresenter from './presenter';
import styles from './styles.m.scss';

const EnterLink = ({ onBackClick, onAddLink }) => {
  const { t } = useTranslation();

  const { inputRef, presenter } = usePresenter({ onAddLink, onBackClick });

  return (
    <>
      <Button
        iconClassName={styles.back}
        Icon={BackIcon}
        onClick={presenter.onBackClick}
      />
      <input
        value={presenter.value}
        onChange={presenter.onChange}
        ref={inputRef}
        className={styles.input}
        placeholder={t('paste_or_type')}
      />
      <Border />
      <Button
        theme="primary"
        isDisabled={presenter.isDisabledSubmit}
        Icon={IcDone}
        onClick={presenter.onDone}
      />
      <AddKeyPressHandler
        isCaptured
        onEnter={presenter.onEnter}
      />
    </>
  );
};

export default observer(EnterLink);
