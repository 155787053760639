import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { useTranslation } from 'APP/packages/translations';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { ShareDataView } from '../../components/ShareDataView/ShareDataView';
import { useClosePopupOnNavigation } from '../../hooks/useClosePopupOnNavigation';
import {
  IShareContactPopupParams,
  useShareContactPopupPresenter,
} from './ShareContactPopup.presenter';

export const ShareContactPopup: FC<ICommonPopupsProps<IShareContactPopupParams>> = observer(
  (props) => {
    const presenter = useShareContactPopupPresenter(props);
    const { t } = useTranslation();
    useClosePopupOnNavigation(props.popupInstance);

    return (
      <Popup
        title={t('share_contact')}
        isOpened
        handlePrevStep={presenter.hasBackButton ? presenter.onBack : null}
        onClose={presenter.onClose}
      >
        <ShareDataView onChangeSelection={presenter.onSelect} />
      </Popup>
    );
  }
);
