import React from 'react';
import Cropper from 'react-easy-crop';

import ErrorHandler from 'APP/components/ErrorHandler';
import { IMAGE_TYPE } from 'APP/constants';
import { useTranslation } from 'APP/packages/translations';
import { Button } from 'UIKIT/Button/Button';
import { PopupFooter } from 'UIKIT/PopupFooter/PopupFooter';

import Slider from './Slider';
import usePresenter from './presenter';
import styles from './styles.m.scss';

const ImageCropper = ({ image, imageType, onClose, onUploadImage }) => {
  const { t } = useTranslation();
  const {
    crop,
    zoom,
    isLoading,
    onCropperError,
    handleUploadImage,
    onZoomChangeSlider,
    onCropChange,
    onCropComplete,
    onZoomChange,
  } = usePresenter({ image, onClose, onUploadImage });

  const cropAreaClassName =
    imageType === IMAGE_TYPE.SQUARE ? styles.cropAreaSquare : styles.cropAreaRound;

  return (
    <>
      <div className={styles.changeProfilePhoto}>
        <ErrorHandler onError={onCropperError}>
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            cropShape="rect"
            classes={{ cropAreaClassName: cropAreaClassName }}
            aspect={4 / 4}
            showGrid={false}
            onCropChange={onCropChange}
            onCropComplete={onCropComplete}
            onZoomChange={onZoomChange}
          />
        </ErrorHandler>
      </div>
      <div className={styles.control}>
        <Slider
          className={styles.slider}
          name="zoom"
          value={zoom}
          max={3}
          min={1}
          step={0.1}
          onChange={onZoomChangeSlider}
        />
      </div>
      <PopupFooter>
        <Button
          className={styles.button}
          onClick={handleUploadImage}
          isDisabled={isLoading}
          isLoading={isLoading}
          theme="1"
        >
          {t('profile_photo_apply_button')}
        </Button>
      </PopupFooter>
    </>
  );
};
export default ImageCropper;
