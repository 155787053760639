import { useAsObservableSource, useLocalStore } from 'mobx-react';

import Entities from 'APP/store';
import { Group } from 'APP/store/Groups/Group';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

import { ISenderAvatarLocalStore } from './SenderAvatar.types';

export const useSenderAvatarPresenter = (message: ChatMessage): ISenderAvatarLocalStore => {
  const source = useAsObservableSource({ message });

  const presenter = useLocalStore<ISenderAvatarLocalStore>(() => ({
    get isShow(): boolean {
      return source.message.isAvatarShow;
    },

    get isSavedMessages(): boolean {
      return Boolean(presenter.group?.isSavedMessages);
    },

    get isForwardToSavedMessages(): boolean {
      return source.message.isForwardToSavedMessages;
    },

    get senderId(): string {
      return source.message.senderId;
    },

    get group(): Group | null {
      return Entities.GroupsStore.getGroupById(source.message.groupId);
    },

    get withMeInAdmins(): boolean {
      return Boolean(this.group?.withMeInAdmins);
    },
  }));

  return presenter;
};
