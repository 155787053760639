import { observer } from 'mobx-react';
import React from 'react';

import BaseSelectFieldWithPopup from 'APP/components/SelectFieldWithPopup';

export const SelectFieldWithPopup = observer(
  ({
    className,
    input,
    values,
    title,
    placeholder,
    renderItem,
    renderCurrent,
    handleSearch,
    onChange,
    onOpen,
    onClose,
  }) => {
    return (
      <BaseSelectFieldWithPopup
        className={className}
        name={input.name}
        value={input.value}
        values={values}
        title={title}
        placeholder={placeholder}
        renderItem={renderItem}
        renderCurrent={renderCurrent}
        handleSearch={handleSearch}
        onChange={onChange}
        onOpen={onOpen}
        onClose={onClose}
      />
    );
  }
);
