import { action, computed } from 'mobx';

import Tasks from 'APP/Tasks';
import { checkSlowMode } from 'APP/Tasks/messaging/checkSlowMode/checkSlowMode';
import { MAX_MESSAGE_LENGTH } from 'APP/model/message/messageModel.constants';
import { PayloadType } from 'APP/model/message/messageModel.types';
import Entities from 'APP/store';
import { splitMessageText } from 'UTILS/message/message';

class Send {
  constructor(root, parent) {
    this.root = root;
    this.parent = parent;
  }

  @computed
  get groupId() {
    return this.parent.group.isSchedule ? this.parent.group.groupId : this.parent.group.id;
  }

  @computed
  get linkPreview() {
    if (!this.parent.inputPanel.linkPreviewCancelled) {
      return this.parent.inputPanel.linkPreviews.get(this.parent.inputPanel.link) || null;
    }
    return null;
  }

  @computed
  get messagesData() {
    const text = this.parent.inputPanel?.markdownValue || '';
    const messages = splitMessageText(text, MAX_MESSAGE_LENGTH);
    const linkPreview = messages.length === 1 ? this.linkPreview : null;

    return messages.map((message) => {
      return {
        type: PayloadType.RichText,
        data: {
          text: message,
          linkPreview,
          linkPreviewCancelled: this.parent.inputPanel.linkPreviewCancelled,
        },
        groupId: this.groupId,
        quotedMessage: this.parent.quoteMessage,
      };
    });
  }

  @action
  prepareSend = () => {
    if (this.parent.voice.isShowRecordPanel) {
      this.parent.voice.stop();
    }
  };

  @action
  submit = () => {
    if (!this.parent.payloads.isEmpty) {
      this.parent.payloads.submit();
      return;
    }

    if (this.parent.voice.isShowRecordPanel) {
      this.parent.voice.send();
      return;
    }

    if (this.isDisabledSend) {
      return;
    }

    const canSubmit = this.checkGroupPermissions();
    if (!canSubmit.result) {
      canSubmit.error && Entities.toast.show(canSubmit.error);
      return;
    }

    if (this.parent.mention.hasFilter) {
      this.parent.mention.clearFilter();
    }

    if (this.parent.edit.isEditMode) {
      const newText = this.parent.inputPanel?.markdownValue || '';

      const text =
        this.parent.edit.message.payload.payloadType === PayloadType.VoiceMessage
          ? this.parent.edit.message.payload.comment
          : this.parent.edit.message.payload.text;

      if (text !== newText) {
        switch (this.parent.edit.message.payload.payloadType) {
          case PayloadType.RichText: {
            this.parent.edit.message.payload.text = newText.trim();
            this.parent.edit.message.payload.linkPreview = this.linkPreview;
            break;
          }
          case PayloadType.VoiceMessage: {
            this.parent.edit.message.payload.comment = newText.trim();
            break;
          }
          default: {
            //
          }
        }

        Tasks.messaging.editMessage(this.parent.edit.message);
      } else {
        Entities.InputPanelsStore.activeInputPanel.setValue(null);
        Entities.InputPanelsStore.activeInputPanel.setEditMessageId(null);
      }

      Tasks.messaging.clearInputPanel({ groupId: this.parent.group.id, clearDraft: false });
    } else {
      Tasks.messaging.createNewMessages({ messages: this.messagesData });
    }

    Tasks.messaging.postSubmitProcess({ groupId: this.parent.group.id });
  };

  @action
  schedule = async (date) => {
    if (!this.parent.payloads.isEmpty) {
      return await this.parent.payloads.schedule(date);
    }

    if (this.parent.voice.isShowRecordPanel) {
      return await this.parent.voice.schedule(date);
    }

    if (this.isDisabledSend) {
      return false;
    }

    const canSend = this.checkGroupPermissions(false);
    if (!canSend.result) {
      canSend.error && Entities.toast.show(canSend.error);
      return;
    }

    if (this.parent.mention.hasFilter) {
      this.parent.mention.clearFilter();
    }

    let result = false;
    if (this.parent.edit.isEditMode) {
      const newText = this.parent.inputPanel?.markdownValue || '';
      if (this.parent.edit.message.payload.text !== newText) {
        this.parent.edit.message.payload.text = newText;
        this.parent.edit.message.payload.linkPreview = this.linkPreview;

        result = await Tasks.messaging.editScheduledMessage({
          message: this.parent.edit.message,
          date: date || new Date(this.parent.edit.message.serverTime),
        });
      } else {
        Entities.InputPanelsStore.activeInputPanel.setValue(null);
        Entities.InputPanelsStore.activeInputPanel.setEditMessageId(null);
      }

      Tasks.messaging.clearInputPanel({ groupId: this.parent.group.id, clearDraft: false });
    } else {
      const text = this.parent.inputPanel?.markdownValue || '';
      const messages = splitMessageText(text, MAX_MESSAGE_LENGTH);
      const linkPreview = messages.length === 1 ? this.linkPreview : null;

      const promises = messages.map((message, index) => {
        return Tasks.messaging.saveScheduledMessage({
          groupId: this.groupId,
          message: {
            type: PayloadType.RichText,
            data: {
              text: message,
              linkPreview,
              linkPreviewCancelled: this.parent.inputPanel.linkPreviewCancelled,
            },
            groupId: this.groupId,
            quotedMessage: this.parent.quoteMessage,
          },
          date: new Date(date.getTime() + index),
        });
      });

      result = Boolean(await Promise.all(promises));
    }

    if (result) {
      Tasks.messaging.postSubmitProcess({ groupId: this.parent.group.id });
    }

    return result;
  };

  @computed
  get isDisabledSend() {
    const editMessageId = this.root.InputPanelsStore.activeInputPanel?.editMessageId;
    const activeGroup = this.root.GroupsStore.activeGroup;
    const message = activeGroup.messagesStore.getMessageById(editMessageId);

    if (message?.payload?.payloadType === PayloadType.VoiceMessage) {
      return false;
    }

    return this.parent.isEmptyValue;
  }

  checkGroupPermissions(needCheckSlowMode = true) {
    let result = { result: true, error: undefined };

    if (needCheckSlowMode) {
      result = checkSlowMode();
      if (!result.result) {
        return result;
      }
    }

    for (const data of this.messagesData) {
      let payload = Tasks.messaging.getPayloadByType(data.type, data.data);
      if (this.parent.edit.message?.payload?.payloadType === PayloadType.VoiceMessage) {
        payload = {
          ...this.parent.edit.message.payload,
          comment: data.data.text,
        };
      }

      result = Tasks.group.checkSendMessagePermissions({
        groupId: data.groupId,
        isNewMessage: !this.parent.edit.isEditMode,
        message: { id: this.parent.edit.message?.id || '', payload },
      });

      if (!result.result) {
        break;
      }
    }

    return result;
  }
}

export default Send;
