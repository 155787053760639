import classNames from 'classnames';
import React from 'react';

import { IconView } from 'APP/packages/UiKit/IconView/IconView';
import IcSelectDown from 'ICONS/ic-select-down.svg';

import SelectItemPopup from './SelectItemPopup';
import usePresenter from './presenter';
import styles from './styles.m.css';

const SelectFieldWithPopup = ({
  className,
  title,
  placeholder,
  renderItem,
  renderCurrent,
  handleSearch,
  name,
  value,
  values,
  onChange,
  onOpen,
  onClose,
}) => {
  const presenter = usePresenter(onOpen, onClose);

  return (
    <>
      <div className={classNames(styles.field, className)}>
        <input
          type="hidden"
          name={name}
          value={value}
        />
        <button
          type="button"
          className={styles.value}
          data-test="open-popup"
          onClick={presenter.togglePopupVisibility}
        >
          {renderCurrent(values[value])}
        </button>
        <IconView
          Icon={IcSelectDown}
          className={styles.icon}
        />
      </div>
      <SelectItemPopup
        isOpened={presenter.isPopupOpened}
        value={value}
        values={values}
        title={title}
        placeholder={placeholder}
        renderItem={renderItem}
        handleSearch={handleSearch}
        onClose={presenter.togglePopupVisibility}
        onSelect={onChange}
      />
    </>
  );
};

export default SelectFieldWithPopup;
