import { observer } from 'mobx-react';
import React from 'react';

import { CallPermissionView } from 'MAIN/Popups/GetParametersManager/Popups/Permissions/Shared/CallPermissionView';

import { usePresenter } from './presenter';

export const CallMemberPermission = observer(({ permissionType, memberPermissionsId }) => {
  const presenter = usePresenter({ permissionType, memberPermissionsId });

  return (
    <CallPermissionView
      permissionValue={presenter.permissionValue}
      permissionLabel={presenter.permissionLabel}
      onClick={presenter.onClick}
    />
  );
});
