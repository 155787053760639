import { useLocalStore, useAsObservableSource } from 'mobx-react';
import { useHistory, useLocation } from 'react-router-dom';

import { useTranslation } from 'APP/packages/translations';
import { RouterLink } from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import Entities from 'STORE';

export const useChatChannelInfoPresenter = ({ group }) => {
  const { t } = useTranslation();
  const navigateTo = useNavigateTo();
  const history = useHistory();
  const location = useLocation();

  const source = useAsObservableSource({ group, location });

  const presenter = useLocalStore(() => ({
    get id() {
      return source.group.id;
    },

    get title() {
      return source.group.avatarTitle;
    },

    get verified() {
      return source.group.verified;
    },

    get members() {
      return t('amount_of_subscribers_in_a_channel_many').replace('{0}', source.group.usersCount);
    },

    get isShowBackButton() {
      const { hasGoBack, fromDeepLink } = source.location.state || {};
      return hasGoBack || fromDeepLink;
    },

    async onBack() {
      const { fromDeepLink } = source.location.state;

      if (!presenter.isShowBackButton) {
        return;
      }

      if (fromDeepLink) {
        navigateTo({ to: RouterLink.catalog });
        return;
      }

      history.goBack();
    },

    toggleProfileSidebar() {
      Entities.ChatStore.toggleSidebar();
    },
  }));

  return presenter;
};
