import { spaceModel } from 'APP/model/model';
import { spaceAnalytics } from 'APP/packages/analytics/analytics';
import Entities from 'APP/store';

import { removeGroupLocally } from '../../group/removeGroupLocally/removeGroupLocally';
import { removeSpaceLocally } from '../removeSpaceLocally/removeSpaceLocally';

export async function deleteSpace(spaceId: string, groupIds?: string[]): Promise<boolean> {
  try {
    const response = await spaceModel.deleteSpace(spaceId, groupIds);

    if (!response.result) {
      return false;
    }

    const space = Entities.spacesStore.getById(spaceId);
    spaceAnalytics.trackSpaceDeleted(spaceId, space?.template);

    removeSpaceLocally(spaceId);

    if (groupIds?.length) {
      groupIds.forEach((groupId) => removeGroupLocally({ groupId }));
    }

    return true;
  } catch (error) {
    return false;
  }
}
