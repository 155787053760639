import React, { FC, Fragment } from 'react';

import { ITextFormattingMenuViewParams } from '../TextFormattingMenu.types';
import { TextFormattingMenuButton } from '../components/TextFormattingMenuButton/TextFormattingMenuButton';
import { useTextFormattingMainViewPresenter } from './TextFormattingMainView.presenter';
import styles from './TextFormattingMainView.styles.m.css';

type ITextFormattingMainViewProps = ITextFormattingMenuViewParams;

export const TextFormattingMainView: FC<ITextFormattingMainViewProps> = (props) => {
  const presenter = useTextFormattingMainViewPresenter(props);

  return (
    <>
      {presenter.actionList.map(({ key, icon, isActive, hasDivider, onClick }) => (
        <Fragment key={key}>
          {hasDivider && <div className={styles.divider} />}
          <TextFormattingMenuButton
            className={styles.button}
            theme={isActive ? '2' : '1'}
            Icon={icon}
            onClick={onClick}
          />
        </Fragment>
      ))}
    </>
  );
};
