import React, { memo } from 'react';

import { AddKeyPressHandler } from 'APP/packages/keyBoardHandler';
import { OverlayingPopup } from 'UIKIT/OverlayingPopup/OverlayingPopup';

import styles from './styles.m.scss';

const FullScreenImage = ({ url, close }) => {
  return (
    <OverlayingPopup
      isOpened
      onClose={close}
      popupClassName={styles.popup}
    >
      <AddKeyPressHandler onEsc={close} />
      <button
        className={styles.container}
        onClick={close}
      >
        <img
          className={styles.img}
          src={url}
          alt=""
        />
      </button>
    </OverlayingPopup>
  );
};

export default memo(FullScreenImage);
