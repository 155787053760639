import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { FormRenderProps, FormSpy } from 'react-final-form';

import DropDown from 'APP/packages/form/DropDown';
import { Field } from 'APP/packages/form/Field/Field';
import { Form } from 'APP/packages/form/Form/Form';
import { TextField } from 'APP/packages/form/TextField/TextField';
import { useTranslation } from 'APP/packages/translations';
import { Button, ButtonTypes } from 'UIKIT/Button/Button';
import { PopupFooter } from 'UIKIT/PopupFooter/PopupFooter';

import { ScheduledCallsFormFields } from '../../ScheduledCalls.constants';
import { IScheduledCallsFormValues } from '../../ScheduledCalls.types';
import { ScheduledCallsDateTimeField } from './ScheduledCallsDateTimeField/ScheduledCallsDateTimeField';
import { useScheduledCallsFormPresenter } from './ScheduledCallsForm.presenter';
import styles from './ScheduledCallsForm.styles.m.css';

interface IScheduledCallsFormProps {
  initialValues: Partial<IScheduledCallsFormValues>;
  onSubmit(values: IScheduledCallsFormValues): Promise<void>;
}

export const ScheduledCallsForm: FC<IScheduledCallsFormProps> = observer(
  ({ initialValues, ...props }) => {
    const { t } = useTranslation();
    const presenter = useScheduledCallsFormPresenter(props);

    return (
      <Form<IScheduledCallsFormValues>
        onSubmit={presenter.onSubmit}
        initialValues={initialValues}
        validate={presenter.validateForm}
        subscription={{
          validating: true,
          errors: true,
        }}
        mutators={{
          checkSubmitErrorsAndMoveToErrors: presenter.checkSubmitErrorsAndMoveToErrors,
          compareStartEndAndChange: presenter.compareStartEndAndChange,
        }}
      >
        {({
          validating,
          handleSubmit,
          errors,
          form: {
            mutators: { compareStartEndAndChange, checkSubmitErrorsAndMoveToErrors },
          },
        }: FormRenderProps): React.ReactElement => {
          return (
            <form
              onSubmit={handleSubmit}
              className={styles.container}
            >
              <div className={styles.content}>
                <Field
                  name={ScheduledCallsFormFields.Name}
                  label={t('username')}
                  component={TextField}
                  maxLength={50}
                />
                <ScheduledCallsDateTimeField
                  mutator={compareStartEndAndChange}
                  validateForm={presenter.validateForm}
                  label={t('сall_starts')}
                  timeName={ScheduledCallsFormFields.StartTsTime}
                  dateName={ScheduledCallsFormFields.StartTsDate}
                />
                <ScheduledCallsDateTimeField
                  validateForm={presenter.validateForm}
                  label={t('сall_ends')}
                  timeName={ScheduledCallsFormFields.EndTsTime}
                  dateName={ScheduledCallsFormFields.EndTsDate}
                  minDateFieldName={ScheduledCallsFormFields.StartTsDate}
                />
                <Field
                  label={t('сall_reminder')}
                  options={presenter.reminderOptions}
                  noSelectedLabel={'common_none'}
                  component={DropDown}
                  name={ScheduledCallsFormFields.Reminder}
                />
              </div>
              <PopupFooter>
                <Button
                  isLoading={presenter.isLoading}
                  type={ButtonTypes.Submit}
                  isDisabled={!!Object.keys(errors || {}).length || validating}
                  theme="1"
                >
                  {t('сall_to_schedule')}
                </Button>
              </PopupFooter>
              <FormSpy
                onChange={(): void => checkSubmitErrorsAndMoveToErrors()}
                subscription={{ errors: true, submitErrors: true }}
              />
            </form>
          );
        }}
      </Form>
    );
  }
);
