import { useAsObservableSource, useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { STREAM_STATUS } from 'APP/constants/stream';
import Entities from 'APP/store';

export default (message) => {
  const source = useAsObservableSource({ message });

  const presenter = useLocalStore(() => ({
    get group() {
      return Entities.GroupsStore.getGroupById(source.message.groupId);
    },

    get placeholderUrl() {
      return source.message.payload.placeholderUrl;
    },

    get isLive() {
      return source.message.payload.status === STREAM_STATUS.STARTED;
    },

    get previewUrl() {
      return source.message.payload.previewUrl;
    },

    get heightPreview() {
      return source.message.payload.previewGraphicSize?.height;
    },

    get widthPreview() {
      return source.message.payload.previewGraphicSize?.width;
    },

    onClick() {
      if (Entities.ActiveStream.stream?.channelId !== source.message.payload.channelId) {
        Tasks.streaming.setActiveStream(source.message.payload, presenter.group.id);
      }
    },
  }));

  return presenter;
};
