import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import useUploadFile from '../../../hooks/useUploadFile';

export default ({ parentPresenter, payload, order, id }) => {
  const { userFile, fileName, fileSize, payloadType, url: payloadUrl } = payload;
  const { uploadProgress, url, cancelUpload } = useUploadFile({
    userFile: userFile,
  });
  const source = useAsObservableSource({
    uploadProgress,
    url,
    cancelUpload,
    order,
  });

  const presenter = useLocalStore(() => ({
    setPayload: () => {
      parentPresenter.editPayloadByOrder(presenter.payload, source.order);
    },

    cancelUploadAndDeleteMessage: () => {
      source.cancelUpload();

      parentPresenter.removeSomethingLoading(id);
      parentPresenter.deletePayloadByOrder(source.order);
    },

    waitingUrl: () => {
      if (!source.url && !payloadUrl) {
        parentPresenter.addSomethingLoading(id);

        return;
      }

      parentPresenter.removeSomethingLoading(id);
      presenter.setPayload();
    },

    get message() {
      return {
        payload: {
          id,
          type: payloadType,
          fileName: fileName,
          fileSize: fileSize,
          uploadProgress: source.uploadProgress,
          url: source.url,
          cancelUploadAndDeleteMessage: presenter.cancelUploadAndDeleteMessage,
        },
      };
    },

    get payload() {
      return {
        payloadType: payloadType,
        file: userFile,
        url: payloadUrl || source.url,
        comment: '',
      };
    },
  }));

  useEffect(() => {
    presenter.waitingUrl();
  }, [url]);

  return presenter;
};
