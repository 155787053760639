import getLengthWithSpecialSymbols from '../utils/getLengthWithSpecialSymbols';

const ERRORS = {
  IS_EMPTY: 'IS_EMPTY',
  TOO_SHORT: 'TOO_SHORT',
};

const MIN_FIELD_GROUP_NAME_LENGTH = 2;

const validate = ({ value, errorToText }) => {
  if (!value || value.length === 0) {
    return errorToText[ERRORS.IS_EMPTY];
  }

  if (getLengthWithSpecialSymbols(value) < MIN_FIELD_GROUP_NAME_LENGTH) {
    return errorToText[ERRORS.TOO_SHORT];
  }

  return null;
};

export default {
  validate,
  ERRORS,
};
