import { observer } from 'mobx-react';
import React, { FC } from 'react';

import NoChannelImage from 'APP/images/gem-mascot-channel-creation.svg';
import { useTranslation } from 'APP/packages/translations';
import { ChatBackground } from 'MAIN/components/ChatBackground/ChatBackground';
import { InfoBlock, InfoBlockTypes } from 'UIKIT/InfoBlock/InfoBlock';

import { useMainInfoBlockPresenter } from './MainInfoBlock.presenter';
import styles from './MainInfoBlock.styles.m.css';

export const MainInfoBlock: FC = observer(() => {
  const presenter = useMainInfoBlockPresenter();
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <ChatBackground />
      <InfoBlock
        title={t('common_empty_state_title')}
        descriptions={[t('common_empty_state_subtitle')]}
        image={NoChannelImage}
        type={presenter.hasBackgroundImage ? InfoBlockTypes.ChatWithPattern : InfoBlockTypes.Chat}
      />
    </div>
  );
});
