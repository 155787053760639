import Tasks from 'APP/Tasks';
import { unauthorizedCallModel } from 'APP/model/model';
import { IUnauthorizedCallInfo } from 'APP/model/unauthorizedCall/unauthorizedCallModel.types';
import { ServerErrorCode } from 'APP/packages/api';
import logger from 'APP/packages/logger';
import { t } from 'MODULE/i18n/service';
import Entities from 'STORE';

export const getUnauthorizedCallInfo = async (
  entityId: string,
  username: string
): Promise<IUnauthorizedCallInfo | undefined> => {
  try {
    return await unauthorizedCallModel.joinCallMeViaLink(entityId, username);
  } catch (error) {
    if (error.message === ServerErrorCode.CallAlreadyExist) {
      Tasks.app.showAlert(
        t('call_user_busy_alert').replace(
          '{0}',
          Entities.callInvitation?.invitationInfo?.userName || ''
        )
      );
    }

    logger.get('Tasks').error('calls.joinToUnauthorizedCall', error);
    return;
  }
};
