export const SPACE_COLLECTION_AVATAR_ERRORS = {
  IS_EMPTY: 'IS_EMPTY',
};

export function spaceCollectionNameAvatar({ value, errorToText }) {
  if (!value) {
    return errorToText[SPACE_COLLECTION_AVATAR_ERRORS.IS_EMPTY];
  }

  return null;
}
