import { action, observable } from 'mobx';

export class ClientConfig {
  messageKeywords: Map<string, { animationURL: string }> = new Map();
  @observable config: Map<string, string> = new Map();
  @observable slowMode = {
    endSlowModeTs: 0,
    duration: 0,
  };

  @action
  setSlowMode(endSlowModeTs = 0, duration = 0): void {
    this.slowMode = {
      endSlowModeTs,
      duration,
    };
  }

  addMessageKeywords(type: string, animationURL: string): void {
    this.messageKeywords.set(type, { animationURL });
  }

  getMessageKeywords(type: string): { animationURL: string } | null {
    return this.messageKeywords.get(type) || null;
  }

  @action
  setConfig(key: string, value: string): void {
    this.config.set(key, value);
  }

  getConfig(key: string): string | null {
    return this.config.get(key) || null;
  }
}
