import React, { FC } from 'react';

import NoResultsImage from 'APP/images/gem-mascot-calls.svg';
import { useTranslation } from 'APP/packages/translations';
import { InfoBlock, InfoBlockTypes } from 'UIKIT/InfoBlock/InfoBlock';

import styles from './ScheduleCallsEmpty.styles.m.css';

export const ScheduleCallsEmpty: FC = () => {
  const { t } = useTranslation();
  return (
    <InfoBlock
      className={styles.noResults}
      type={InfoBlockTypes.Modal}
      descriptions={[t('call_no_events')]}
      image={NoResultsImage}
    />
  );
};
