import { action, computed, observable, values } from 'mobx';

import Tasks from 'APP/Tasks';
import { IStickerPack, IStickerPackExtend } from 'APP/model/stickers/stickersModel.types';

export type IStickerPacks = Record<IStickerPack['id'], IStickerPackExtend>;

export class Stickers {
  @observable stickersPacks: IStickerPacks = {};
  @observable installedPackIds: IStickerPack['id'][] = [];

  @action
  reset(): void {
    Tasks.stickers.resetEmojiStickers();
    this.stickersPacks = {};
    this.installedPackIds = [];
  }

  @action
  mergePacks(stickersPacks: IStickerPackExtend[]): void {
    stickersPacks.forEach((pack) => {
      pack.stickers.sort((a, b) => a.order - b.order);
    });

    const packs = this.transformToStickerPacks(stickersPacks);

    this.stickersPacks = { ...this.stickersPacks, ...packs };
  }

  @action
  setInstalledPackIds(installedPackIds: IStickerPack['id'][]): void {
    this.installedPackIds = [...installedPackIds];
  }

  @computed
  get packs(): ReadonlyArray<IStickerPackExtend> {
    return values(this.stickersPacks);
  }

  @computed
  get installedPacks(): ReadonlyArray<IStickerPackExtend> {
    return this.installedPackIds
      .map((id) => this.stickersPacks[id])
      .filter((item) => Boolean(item));
  }

  getById(id: IStickerPack['id']): IStickerPackExtend | null {
    return this.stickersPacks[id] || null;
  }

  transformToStickerPacks(stickersPacks: IStickerPackExtend[]): IStickerPacks {
    return stickersPacks.reduce((packs: IStickerPacks, stickerPack) => {
      packs[stickerPack.stickerPack.id] = stickerPack;
      return packs;
    }, {});
  }
}
