import React, { FC, ComponentProps } from 'react';

import BaseTimeField from 'APP/packages/form/TimeField';

interface IDateFieldProps extends ComponentProps<typeof BaseTimeField> {
  hasExternalError: boolean;
  onChange?(value: { [name: string]: string }): void;
}

export const TimeField: FC<IDateFieldProps> = ({
  meta,
  input,
  onChange,
  hasExternalError,
  ...props
}) => {
  const extendedMeta = {
    ...meta,
    modified: hasExternalError || meta.modified,
    invalid: hasExternalError || meta.invalid,
    error: hasExternalError ? ' ' : meta.error,
  };

  const extendedInput = {
    ...input,
    onChange: (value: string): void => {
      input.onChange(value);
      onChange && onChange({ [input.name]: value });
    },
  };

  return (
    <BaseTimeField
      ignoreTrimError={hasExternalError}
      meta={extendedMeta}
      input={extendedInput}
      {...props}
    />
  );
};
