import { useAsObservableSource, useLocalStore } from 'mobx-react';

import { openPopup } from 'APP/Tasks/app/popup/popup';
import DocumentStrokeIcon from 'ICONS/ic-document_stroke.svg';
import LocationStrokeIcon from 'ICONS/ic-location_stroke.svg';
import { SelectLocationPopup } from 'MAIN/PopupManager/Popups/SelectLocationPopup/SelectLocationPopup';
import { usePopover } from 'UIKIT/Popover/usePopover';

import { FOCUS_NAMES } from '../../constants';

export default ({ fileUploadRef, parentPresenter, order, payload, isPayloadCreator }) => {
  const popover = usePopover();

  const source = useAsObservableSource({
    payload,
    order,
    isPayloadCreator,
    isOpened: popover.isOpened,
  });

  const presenter = useLocalStore(() => ({
    openSelectLocation() {
      openPopup(SelectLocationPopup, { params: { onSelect: parentPresenter.setLocationPayload } });
    },

    onClick(event) {
      event.preventDefault();
      event.stopPropagation();

      fileUploadRef.current && fileUploadRef.current.open();
      presenter.togglePopover();
    },

    get items() {
      return [
        {
          key: 'document',
          IconComponent: DocumentStrokeIcon,
          onClick: presenter.onClick,
        },
        {
          key: 'location',
          IconComponent: LocationStrokeIcon,
          onClick: presenter.openSelectLocation,
        },
      ];
    },

    get isShow() {
      if (
        source.isPayloadCreator &&
        parentPresenter.actualFocusPosition === FOCUS_NAMES.CREATOR_PAYLOAD &&
        source.payload.text === ''
      ) {
        return true;
      }

      return parentPresenter.actualFocusPosition === source.order && source.payload.text === '';
    },

    togglePopover() {
      parentPresenter.showPlaceholder = source.isOpened;
      popover.toggle();
    },
  }));

  return { presenter, popover };
};
