import React, { FC } from 'react';

import { useTranslation } from 'APP/packages/translations';
import BackIcon from 'ICONS/ic-back-32.svg';
import CloseIcon from 'ICONS/ic-close-32.svg';
import { IconButton, IconViewSizes } from 'UIKIT/IconButton';

import styles from './BackgroundPanelHeader.styles.m.css';

interface IBackgroundPanelHeaderProps {
  isCompact?: boolean;
  onClose(): void;
}

export const BackgroundPanelHeader: FC<IBackgroundPanelHeaderProps> = (props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.headerContainer}>
      {props.isCompact && (
        <IconButton
          theme={'21'}
          size={IconViewSizes.Big}
          Icon={BackIcon}
          onClick={props.onClose}
          className={styles.backButton}
        />
      )}
      <div className={styles.title}>{t('call_backgrounds')}</div>

      {!props.isCompact && (
        <IconButton
          theme={'12'}
          size={IconViewSizes.Big}
          Icon={CloseIcon}
          onClick={props.onClose}
          className={styles.closeButton}
        />
      )}
    </div>
  );
};
