import { ApplicationTheme } from 'APP/constants/appearance';
import { ElectronApi } from 'APP/packages/electron/ElectronApi';
import { storage } from 'APP/packages/storage';
import Entities from 'STORE';

export function setTheme(theme: ApplicationTheme | null): void {
  Entities.appearance.setTheme(theme);

  if (theme) {
    storage.theme.set(theme);
  } else {
    storage.theme.remove();
  }

  ElectronApi.setTheme(theme);
}
