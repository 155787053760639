import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { openPopup } from 'APP/Tasks/app/popup/popup';
import { showConfirm } from 'APP/Tasks/app/showConfirm/showConfirm';
import { showToast } from 'APP/Tasks/app/showToast/showToast';
import { ISpaceCollection } from 'APP/model/space/spaceModel.types';
import { useTranslation } from 'APP/packages/translations';
import { RouterPage } from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import { EditSpaceCollectionPopup } from 'MAIN/PopupManager/Popups/EditSpaceCollectionPopup/EditSpaceCollectionPopup';
import { useSpaceCollections } from 'MAIN/hooks/space/useSpaceCollections';
import Entities from 'STORE';
import { Space } from 'STORE/Spaces/Space';

interface ISpaceCollectionPresenter {
  space: Space | null;
  collection: ISpaceCollection | null;
  onClose(): void;
  onEdit(): void;
  onDelete(): Promise<void>;
}

export function useSpaceCollectionPresenter(
  spaceId?: string,
  collectionId?: string
): ISpaceCollectionPresenter {
  const navigateTo = useNavigateTo();
  const { t } = useTranslation();
  const source = useAsObservableSource({ spaceId, collectionId, navigateTo });
  const spaceCollections = useSpaceCollections(spaceId);

  const presenter = useLocalStore<ISpaceCollectionPresenter>(() => ({
    get space(): Space | null {
      return source.spaceId ? Entities.spacesStore.getById(source.spaceId) : null;
    },

    get collection(): ISpaceCollection | null {
      return source.collectionId ? spaceCollections.getCollectionById(source.collectionId) : null;
    },

    onEdit(): void {
      if (source.spaceId && source.collectionId) {
        openPopup(EditSpaceCollectionPopup, {
          params: { spaceId: source.spaceId, collectionId: source.collectionId },
        });
      }
    },

    async onDelete(): Promise<void> {
      if (!source.spaceId || !source.collectionId) {
        return;
      }

      const canDelete = await showConfirm({
        text: t('delete_collection_alert'),
      });

      if (!canDelete) {
        return;
      }

      const result = await Tasks.space.deleteSpaceCollection({
        spaceId: source.spaceId,
        collectionId: source.collectionId,
      });

      if (result) {
        showToast(t('space_alert_collection_remove'));
        presenter.onClose();
      } else {
        showToast(t('something_went_wrong'));
      }
    },

    onClose(): void {
      source.navigateTo({
        to: source.spaceId ? `/${RouterPage.Spaces}/${source.spaceId}` : `/${RouterPage.Spaces}`,
      });
    },
  }));

  useEffect(() => {
    if (
      !presenter.collection &&
      !Entities.spacesStore.isLoading &&
      !presenter.space?.collections.isLoading
    ) {
      showToast(t('collection_is_not_accessible'));
      presenter.onClose();
    }
  }, [
    presenter.collection,
    Entities.spacesStore.isLoading,
    presenter.space?.collections.isLoading,
  ]);

  return presenter;
}
