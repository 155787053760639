import { useRef, useEffect } from 'react';

import callbacks from '../../models/callbacks';

export default ({ callbackData }) => {
  const { callback, isCaptured, isPrevent, keyName } = callbackData;

  const value = useRef({
    callback,
    isCaptured,
    isPrevent,
  });

  useEffect(() => {
    value.current = {
      callback,
      isCaptured,
      isPrevent,
    };
  }, [callback, isCaptured, isPrevent]);

  useEffect(() => {
    const remove = callbacks.add(keyName, value);

    return () => {
      remove();
    };
  }, []);
};
