import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import NoDraftsIcon from 'APP/images/gem-mascot-not-found.svg';
import { IconButton } from 'APP/packages/UiKit/IconButton';
import { useTranslation } from 'APP/packages/translations';
import IconClose from 'ICONS/ic-close.svg';
import IconLive from 'ICONS/ic-live.svg';
import IconMute from 'ICONS/ic-mic-off.svg';
import { Avatar } from 'UIKIT/Avatar/Avatar';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';
import { Tooltip } from 'UIKIT/Tooltip/Tooltip';

import MutedBadge from '../../../SharedComponents/MutedBadge';
import usePresenter from './presenter';
import styles from './styles.m.css';

const Viewers = ({ onClose }) => {
  const { t } = useTranslation();
  const presenter = usePresenter();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>{t('stream_viewers_list')}</div>
        <div className={styles.total}>{presenter.viewersLiveTotalTitle}</div>
        <IconButton
          className={styles.close}
          Icon={IconClose}
          onClick={onClose}
          size="big"
          iconSize="medium"
          theme="11"
        />
      </div>
      <div className={classNames(styles.list, customScrollStyles.container)}>
        {presenter.viewersLiveCount === 0 && (
          <div className={styles.noViewers}>
            <img
              src={NoDraftsIcon}
              width={148}
              height={140}
              alt={''}
            />
            <div className={styles.description}>{t('call_streaming_nobody_watching')}</div>
          </div>
        )}
        {presenter.streamers.map((streamer) => (
          <div
            key={streamer.uid}
            className={styles.item}
          >
            <div className={styles.avatarContainer}>
              <Avatar
                type={AvatarTypes.Round_5}
                title={streamer.username}
                url={streamer.avatarUrl}
                backgroundColorIndex={streamer.avatarColorIndex}
              />
              {streamer.isAudioMuted && <MutedBadge className={styles.mutedBadge} />}
            </div>
            <p className={styles.name}>{streamer.username}</p>
            <p className={styles.streamer}>{t('call_streamer_hint')}</p>
            <IconButton
              className={classNames(styles.mute, {
                [styles.muted]: streamer.isAudioMuted,
              })}
              Icon={IconMute}
              onClick={() => presenter.forceMuteStreamer({ userId: streamer.userId })}
              size="big"
              iconSize="medium"
              theme="11"
            />
          </div>
        ))}
        {presenter.viewers.map((viewer) => (
          <div
            key={viewer.uid}
            className={styles.item}
          >
            <div className={styles.avatarContainer}>
              <Avatar
                type={AvatarTypes.Round_5}
                title={viewer.username}
                url={viewer.avatarUrl}
                backgroundColorIndex={viewer.avatarColorIndex}
              />
            </div>
            <p className={styles.name}>{viewer.username}</p>
            <Tooltip
              className={styles.tooltip}
              text={t('call_invite_as_streamer')}
            >
              <div aria-expanded={false}>
                <IconButton
                  className={styles.invite}
                  Icon={IconLive}
                  onClick={() => presenter.inviteAsStreamer(viewer)}
                  size="big"
                  iconSize="medium"
                  theme="11"
                />
              </div>
            </Tooltip>
          </div>
        ))}
      </div>
    </div>
  );
};

export default observer(Viewers);
