import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { useTranslation } from 'APP/packages/translations';
import { useClosePopupOnNavigation } from 'MAIN/PopupManager/hooks/useClosePopupOnNavigation';
import { SearchInput } from 'UIKIT/SearchInput/SearchInput';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { SpaceMembersList } from './SpaceMembersList/SpaceMembersList';
import {
  ISpaceMembersPopupParams,
  useSpaceMembersPopupPresenter,
} from './SpaceMembersPopup.presenter';
import styles from './SpaceMembersPopup.styles.m.css';

export const SpaceMembersPopup: FC<ICommonPopupsProps<ISpaceMembersPopupParams>> = observer(
  (props) => {
    const { t } = useTranslation();
    const presenter = useSpaceMembersPopupPresenter(props);
    useClosePopupOnNavigation(props.popupInstance);

    return (
      <Popup
        title={t('space_member_list')}
        isOpened={!!presenter.space}
        handlePrevStep={presenter.hasBackButton ? presenter.onBack : null}
        onClose={presenter.onClose}
      >
        <SearchInput
          className={styles.search}
          placeholder={t('search_hint')}
          value={presenter.searchText}
          onChange={presenter.onSearch}
        />

        <SpaceMembersList
          space={presenter.space}
          searchText={presenter.searchText}
          onClosePopup={presenter.onClose}
        />
      </Popup>
    );
  }
);
