import { useAsObservableSource, useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { IScheduledEventDetails } from 'APP/model/scheduledEvent/scheduledEventModel.types';
import { useTranslation } from 'APP/packages/translations';
import { CallReminderMap } from 'MAIN/PopupManager/Popups/ScheduledCalls/ScheduledCalls.constants';
import { getScheduledEventTime } from 'MAIN/PopupManager/Popups/ScheduledCalls/ScheduledCalls.utils';
import Entities from 'STORE';
import { TAvatarBackgroundColorIndex } from 'UIKIT/Avatar/Avatar.types';

import { IScheduledCallViewInitialData } from '../ScheduledCallViewPopup.presenter';

interface IScheduledCallViewPopupPresenter {
  time: string;
  isMeAdminOwnerOrInitiator: boolean;
  reminder: string | null;
  groupColorIndex?: TAvatarBackgroundColorIndex;
  handleDeleteEvent(): Promise<void>;
}

interface IScheduledCallViewInfoParams {
  scheduledEvent: IScheduledEventDetails | IScheduledCallViewInitialData;
  onCancel(): void;
}

export function useScheduledCallViewInfoPresenter({
  scheduledEvent,
  onCancel,
}: IScheduledCallViewInfoParams): IScheduledCallViewPopupPresenter {
  const source = useAsObservableSource({ scheduledEvent, onCancel });
  const { t } = useTranslation();
  return useLocalStore<IScheduledCallViewPopupPresenter>(() => ({
    get time(): string {
      return getScheduledEventTime(source.scheduledEvent.startTs, source.scheduledEvent.endTs);
    },

    get groupColorIndex(): TAvatarBackgroundColorIndex | undefined {
      const group = Entities.GroupsStore.getGroupById(source.scheduledEvent.groupId);

      return group ? group.avatarColorIndex : undefined;
    },

    get reminder(): string | null {
      if ('reminder' in source.scheduledEvent) {
        return source.scheduledEvent.reminder
          ? `${t('call_remind')} ${t(CallReminderMap[source.scheduledEvent.reminder])}`
          : t('call_no_reminders');
      }
      return null;
    },

    get isMeAdminOwnerOrInitiator(): boolean {
      const initiatorId = source.scheduledEvent.initiatorId;
      const isMeInitiator = Entities.UsersStore.Me.id === initiatorId;

      const groupId = source.scheduledEvent.groupId;
      const group = Entities.GroupsStore.getGroupById(groupId);

      if (!group) {
        return false;
      }

      if (isMeInitiator) {
        return true;
      }

      return !group.isP2P && group.withMeInAdmins;
    },

    async handleDeleteEvent(): Promise<void> {
      const confirmResult = await Tasks.app.showConfirm({
        text: t('call_events_cancel'),
        primaryText: t('common_yes'),
        secondaryText: t('common_no'),
      });

      if (!confirmResult) {
        return;
      }

      try {
        await Tasks.scheduledEvents.cancelScheduledEvent(source.scheduledEvent.id);
      } catch (e) {
        Entities.toast.show(t('something_went_wrong'));
      }

      source.onCancel();
    },
  }));
}
