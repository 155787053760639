import { useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { showToast } from 'APP/Tasks/app/showToast/showToast';
import { checkSlowMode } from 'APP/Tasks/messaging/checkSlowMode/checkSlowMode';
import { GroupType } from 'APP/model/group/groupModel.types';
import {
  IMessageData,
  IReachTextMessagePayloadData,
  PayloadType,
} from 'APP/model/message/messageModel.types';
import { useTranslation } from 'APP/packages/translations';
import { IShareSelection, TChangeSelectionHandler, TSelectItemHandler } from 'APP/types/share';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import Entities from 'STORE';
import { Group } from 'STORE/Groups/Group';
import { replaceHtmlTagsToSpecialSymbols } from 'UTILS/html/replaceHtmlTagsToSpecialSymbols';

export interface IForwardMessagePopupParams {
  groupId: string;
  messageIds: string[];
}

export interface IForwardMessagePopupPresenter {
  group: Group | null;
  isAvailableToShow: boolean;
  selection: IShareSelection[];
  isSubmitting: boolean;
  hasSelected: boolean;
  canForwardMessage(data: IShareSelection): boolean;
  onClose(): void;
  onSelectItem: TSelectItemHandler;
  onChangeSelection: TChangeSelectionHandler;
  submit(comment: string): Promise<void>;
  send(targetGroupIds: string[], comment: string): Promise<void>;
  getMessagesData(
    targetGroupIds: string[],
    value: string
  ): IMessageData<IReachTextMessagePayloadData>[];
}

const CHECKING_PERMISSION_GROUPS_TYPES = [
  GroupType.Closed,
  GroupType.Open,
  GroupType.ChannelClosed,
  GroupType.ChannelOpen,
];

export function useForwardMessagePresenter({
  params,
  popupInstance,
}: ICommonPopupsProps<IForwardMessagePopupParams>): IForwardMessagePopupPresenter {
  const { t } = useTranslation();
  const groupId = params!.groupId;
  const messageIds = params!.messageIds;

  const presenter = useLocalStore<IForwardMessagePopupPresenter>(() => ({
    isSubmitting: false,
    selection: [],

    get group(): Group | null {
      return Entities.GroupsStore.getGroupById(groupId);
    },

    get isAvailableToShow(): boolean {
      return Boolean(presenter.group) && messageIds.length > 0;
    },

    get hasSelected(): boolean {
      return presenter.selection.length > 0;
    },

    canForwardMessage(data: IShareSelection): boolean {
      if (!data.data.type || !CHECKING_PERMISSION_GROUPS_TYPES.includes(data.data.type)) {
        return true;
      }

      const targetGroup = Entities.GroupsStore.getGroupById(data.data.id);
      if (!targetGroup || !targetGroup.canSendMessages) {
        return false;
      }

      for (const messageId of messageIds) {
        const message = presenter.group!.messagesStore.getMessageById(messageId);
        if (message) {
          const canSelect = Tasks.group.checkSendMessagePermissions({
            groupId: targetGroup.id,
            isNewMessage: true,
            message,
          });

          if (!canSelect.result) {
            return false;
          }
        }
      }

      return true;
    },

    onClose(): void {
      popupInstance.close();
    },

    onSelectItem(data: IShareSelection): boolean {
      if (!presenter.canForwardMessage(data)) {
        showToast(t('moderation_content_forward', { 0: data.data.avatarTitle }));
        return false;
      }

      return true;
    },

    onChangeSelection(data: IShareSelection[]): void {
      presenter.selection = data;
    },

    async submit(comment: string): Promise<void> {
      if (!presenter.group || messageIds.length === 0) {
        return;
      }

      const canSend = checkSlowMode();
      if (!canSend.result) {
        Entities.InputPanel.payloads.clear();
        canSend.error && showToast(canSend.error);
        return;
      }

      presenter.isSubmitting = true;

      const selection = presenter.selection.filter((data) => presenter.canForwardMessage(data));
      const targetGroupIds = await Tasks.forward.getTargetGroupIds(selection);

      presenter.send(targetGroupIds, comment);
      presenter.group.messagesStore.multiSelect.clearSelectedMessages();

      if (!targetGroupIds.length) {
        presenter.onClose();
        return;
      }

      if (targetGroupIds.length > 1) {
        presenter.onClose();
        return;
      }

      if (Entities.GroupsStore.activeGroupId !== targetGroupIds[0]) {
        const targetGroup = Entities.GroupsStore.getGroupById(targetGroupIds[0]);
        if (targetGroup) {
          targetGroup.messagesStore.setSavePosition({ isBottom: true });
        }

        await Tasks.group.setActiveGroup({ groupId: targetGroupIds[0] });
      }

      presenter.onClose();
    },

    getMessagesData(
      targetGroupIds: string[],
      value: string
    ): IMessageData<IReachTextMessagePayloadData>[] {
      return targetGroupIds.map((groupId) => {
        return {
          type: PayloadType.RichText,
          data: {
            text: replaceHtmlTagsToSpecialSymbols(value),
          },
          groupId,
        };
      });
    },

    async send(targetGroupIds: string[], comment: string): Promise<void> {
      if (!presenter.group || messageIds.length === 0) {
        return;
      }

      const result = await Tasks.messaging.forwardMessages(
        messageIds,
        presenter.group.id,
        targetGroupIds
      );
      if (targetGroupIds.length > 1) {
        result ? showToast(t('forwarded_successfully')) : showToast(t('something_went_wrong'));
      }

      if (comment) {
        const messagesData = presenter.getMessagesData(targetGroupIds, comment);
        try {
          await Tasks.messaging.createNewMessages({ messages: messagesData });
        } catch {
          //
        }
      }
    },
  }));

  return presenter;
}
