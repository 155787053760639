import { BotButtonPayloadType } from 'APP/model/bot/botModel.constants';
import {
  IButtonSelectedRequestData,
  IButtonSelectedResponseData,
  ICommandPayload,
} from 'APP/model/bot/botModel.types';
import { PayloadType } from 'APP/model/message/messageModel.types';
import { Payload } from 'STORE/Messages/Message/Payload';
import { CommandButtonPayload } from 'STORE/Messages/Message/Payload/Buttons/CommandButtonPayload';

export class ButtonsSelected extends Payload {
  id: string;
  title: string;
  payload: CommandButtonPayload | null;

  constructor(data: IButtonSelectedResponseData) {
    super(data);

    this.id = data.button.id;
    this.title = data.button.title;
    this.payload = null;
    switch (data.button.payload?.type) {
      case BotButtonPayloadType.Command: {
        this.payload = new CommandButtonPayload(data.button.payload as ICommandPayload);
        break;
      }
    }
  }

  toJSON(): IButtonSelectedRequestData {
    return {
      payloadType: PayloadType.ButtonsSelected,
      button: {
        id: this.id,
        title: this.title,
        payload: this.payload?.toJSON(),
      },
    };
  }

  get text(): string {
    return this.title;
  }
}
