import React from 'react';

import IcMusic16 from 'ICONS/ic-music-16.svg';

import { MiniatureMessageLayout } from '../components/MiniatureMessageLayout/MiniatureMessageLayout';

const Audio = ({ titleClassName, textClassName, message, title, onClick }) => {
  return (
    <MiniatureMessageLayout
      titleClassName={titleClassName}
      textClassName={textClassName}
      Icon={IcMusic16}
      title={title}
      text={message.payload.typeDescription}
      messageId={message.id}
      onClick={onClick}
    />
  );
};

export default Audio;
