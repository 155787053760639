import { useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { openPopup } from 'APP/Tasks/app/popup/popup';
import { PayloadType } from 'APP/model/message/messageModel.types';
import { ISticker, IStickerPackExtend } from 'APP/model/stickers/stickersModel.types';
import { storage } from 'APP/packages/storage';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import Entities from 'APP/store';
import { Schedule } from 'APP/store/Groups/Schedule';
import { StickersSettingsPopup } from 'MAIN/PopupManager/Popups/StickersSettingsPopup/StickersSettingsPopup';
import { sortStickers } from 'UTILS/sortStickers';

interface IStickerPanelPresenter {
  activePackId: string | null;
  packs: IStickerPackExtend[];
  hasPacks: boolean;
  activePack: IStickerPackExtend | null;
  setActivePack(stickerPackId: string): void;
  sendSticker(sticker: ISticker): void;
  checkIsActivePack(stickerPackId: string): boolean;
  openSettings(): void;
}

export const useStickerPanelPresenter = (closePopover: () => void): IStickerPanelPresenter => {
  const navigateTo = useNavigateTo();

  const presenter = useLocalStore<IStickerPanelPresenter>(() => ({
    activePackId: storage.selectedStickerPack.get(),

    get packs(): IStickerPackExtend[] {
      return [...Entities.stickersStore.installedPacks].sort(sortStickers);
    },

    get hasPacks(): boolean {
      return presenter.packs.length > 0;
    },

    get activePack(): IStickerPackExtend | null {
      return (
        presenter.packs.find((pack) => pack.stickerPack.id === presenter.activePackId) ||
        presenter.packs[0] ||
        null
      );
    },

    setActivePack(stickerPackId: string): void {
      presenter.activePackId = stickerPackId;
      storage.selectedStickerPack.set(stickerPackId);
    },

    checkIsActivePack(stickerPackId: string): boolean {
      return presenter.activePackId === stickerPackId;
    },

    sendSticker(sticker: ISticker): void {
      if (!sticker) {
        return;
      }

      const group = Entities.GroupsStore.activeGroup;
      if (!group) {
        return;
      }

      const messages = [
        {
          type: PayloadType.Sticker,
          data: sticker,
          groupId: group instanceof Schedule ? group.groupId : group.id,
          quotedMessage: Entities.InputPanelsStore.activeInputPanel.quoteMessage,
        },
      ];

      Entities.InputPanel.payloads.setPayloads(messages);
      if (group instanceof Schedule && group.isSchedule) {
        Tasks.group.goToScheduleSettings({ navigateTo });
      } else {
        Entities.InputPanel.send.submit();
      }
    },

    openSettings(): void {
      closePopover();

      openPopup(StickersSettingsPopup);
    },
  }));

  return presenter;
};
