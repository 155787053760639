import * as PopperJS from '@popperjs/core';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { Modifier, StrictModifierNames } from 'react-popper';

import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';
import { TPostInstance } from 'STORE/Newsfeed/Newsfeed.types';
import { IPopover } from 'UIKIT/Popover/Popover.types';
import { PopoverMenu } from 'UIKIT/PopoverMenu/PopoverMenu';
import { IPopoverMenuItem } from 'UIKIT/PopoverMenu/PopoverMenu.types';

interface IPostContextMenuProps {
  newsItem: TPostInstance;
  popover: IPopover;
  placement: PopperJS.Placement;
  modifiers?: ReadonlyArray<Modifier<StrictModifierNames>>;
  getMenuItems(message: ChatMessage, newsItem: TPostInstance): IPopoverMenuItem[];
}

export const PostContextMenu: FC<IPostContextMenuProps> = observer((props) => {
  const { newsItem, popover, placement, modifiers, getMenuItems } = props;

  return (
    <PopoverMenu
      menuItems={getMenuItems(newsItem.message as ChatMessage, newsItem)}
      reference={popover.containerRef.current}
      placement={placement}
      modifiers={modifiers}
      isOpened={popover.isOpened}
      onClose={popover.close}
    />
  );
});
