import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { ImagePreview } from 'APP/components/ImagePreview/ImagePreview';
import { CIRCLE_VIDEO_PREVIEW_MAX_SIZE } from 'APP/constants/messages';
import PlayIcon from 'ICONS/ic-play-32.svg';
import type { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';
import { IconView, IconViewSizes } from 'UIKIT/IconView/IconView';
import { Loader } from 'UIKIT/Loader/Loader';

import { useCircleVideoPayloadPresenter } from './CircleVideoPayload.presenter';
import styles from './CircleVideoPayload.styles.m.css';

interface IVideoPayloadProps {
  message: ChatMessage;
  isPreview: boolean;
  isView: boolean;
}

export const CircleVideoPayload: FC<IVideoPayloadProps> = observer((props) => {
  const { message, isPreview, isView } = props;
  const { containerRef, presenter } = useCircleVideoPayloadPresenter({ message, isView });

  const previewClassName = classNames(styles.preview, {
    [styles.my]: !message.fromMe && !message.group?.isChannel,
    [styles.hidden]: !presenter.isShowPreview,
  });

  const videoClassName = classNames(styles.video, {
    [styles.my]: message.fromMe && !message.group?.isChannel,
  });

  return (
    <div className={styles.container}>
      <div
        ref={containerRef}
        className={videoClassName}
        style={message.payload.previewSize}
      />

      <ImagePreview
        className={previewClassName}
        url={message.payload.previewUrl}
        graphicSize={message.payload.graphicSize}
        maxWidth={CIRCLE_VIDEO_PREVIEW_MAX_SIZE.width}
        maxHeight={CIRCLE_VIDEO_PREVIEW_MAX_SIZE.height}
        isPreview={isPreview}
        isView={isView}
        onClick={presenter.onTogglePlay}
      >
        <div className={styles.previewControl}>
          {presenter.isPlaying ? (
            <Loader
              size="medium"
              color="primary"
            />
          ) : (
            <IconView
              className={styles.previewControlIcon}
              size={IconViewSizes.Big}
              Icon={PlayIcon}
            />
          )}
        </div>
      </ImagePreview>
    </div>
  );
});
