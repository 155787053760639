import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import Entities from 'STORE';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';
import { User } from 'STORE/Users/User/User';
import { ITooltipActions } from 'UIKIT/Tooltip/Tooltip';

const USERS_VISIBLE_COUNT = 10;

export interface IReactionUsersPopoverPresenterParams {
  message: ChatMessage;
  reactionId: string;
  tooltip: ITooltipActions;
  onOpenReactionPopup(reactionId: string): void;
}

interface IReactionUsersPopoverPresenter {
  isLoading: boolean;
  count: number;
  users: User[];
  message: ChatMessage;
  isOpened: boolean;
  moreCount: number;
  loadUsers(): Promise<void>;
  reset(): void;
  onMoreClick(): void;
}

export const useReactionUsersPopoverPresenter = (
  params: IReactionUsersPopoverPresenterParams
): IReactionUsersPopoverPresenter => {
  const { message, reactionId, tooltip, onOpenReactionPopup } = params;
  const source = useAsObservableSource({ message, reactionId, tooltip, onOpenReactionPopup });

  const presenter = useLocalStore<IReactionUsersPopoverPresenter>(() => ({
    isLoading: false,
    count: 0,
    users: [],

    get message(): ChatMessage {
      return source.message;
    },

    get isOpened(): boolean {
      return !presenter.isLoading && presenter.users.length > 0;
    },

    get moreCount(): number {
      if (presenter.count > USERS_VISIBLE_COUNT) {
        return presenter.count - USERS_VISIBLE_COUNT;
      }

      return 0;
    },

    async loadUsers(): Promise<void> {
      presenter.isLoading = true;

      const users = await Tasks.reactions.getUsersReactionsForMessage({
        groupId: presenter.message.groupId,
        messageId: presenter.message.id,
        reactionId: source.reactionId,
      });

      const slicedUsers = (users[source.reactionId] || []).slice(0, USERS_VISIBLE_COUNT);
      const usersIds = slicedUsers.map((userId) => userId);

      if (usersIds.length > 0) {
        await Tasks.relationships.loadUnknownUsersByIds({ usersIds });
      }

      presenter.users = slicedUsers
        .map((userId) => Entities.UsersStore.getUserById(userId))
        .filter((user) => user);
      presenter.isLoading = false;
      presenter.count = users[source.reactionId]?.length || 0;

      source.tooltip.update();
    },

    reset(): void {
      presenter.isLoading = false;
      presenter.users = [];
      presenter.count = 0;
    },

    onMoreClick(): void {
      source.onOpenReactionPopup(source.reactionId);
      source.tooltip.hide();
    },
  }));

  useEffect(() => {
    presenter.loadUsers();

    return () => {
      presenter.reset();
    };
  }, []);

  return presenter;
};
