import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { IBaseStory, IStory } from './Stories.types';
import { getStorySize } from './Stories.utils';

const WIDTH_EXTRA_SIZE = 144; // previous, next buttons and paddings
const HEIGHT_EXTRA_SIZE = 64; // paddings

interface IStoriesPresenterOptions {
  stories: IStory[];
  onEnd?: () => void;
}

interface IStoriesPresenter {
  contentRef: RefObject<HTMLDivElement>;
  activeStory: IStory;
  activeStoryIndex: number;
  activeStorySize: IBaseStory['size'] | null;
  hasPrevious: boolean;
  hasNext: boolean;
  progress: number;
  onProgress(progress: number): void;
  onPrevious(): void;
  onNext(): void;
}

export function useStoriesPresenter({
  stories,
  onEnd,
}: IStoriesPresenterOptions): IStoriesPresenter {
  const contentRef = useRef<HTMLDivElement>(null);
  const [activeStoryIndex, setActiveStoryIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const [storiesSizeList, setStoriesSizeList] = useState<(IBaseStory['size'] | null)[]>([]);

  const activeStory = useMemo(() => {
    return stories[activeStoryIndex];
  }, [stories, activeStoryIndex]);

  const activeStorySize = useMemo(() => {
    return storiesSizeList[activeStoryIndex] || null;
  }, [storiesSizeList, activeStoryIndex]);

  const onSetActiveStory = useCallback((index) => {
    setProgress(0);
    setActiveStoryIndex(index);
  }, []);

  const hasPrevious = useMemo(() => {
    return activeStoryIndex > 0;
  }, [activeStoryIndex]);

  const hasNext = useMemo(() => {
    return activeStoryIndex < stories.length - 1;
  }, [stories, activeStoryIndex]);

  const onPrevious = useCallback(() => {
    onSetActiveStory(activeStoryIndex - 1);
  }, [activeStoryIndex]);

  const onNext = useCallback(() => {
    onSetActiveStory(activeStoryIndex + 1);
  }, [activeStoryIndex]);

  const onProgress = useCallback(
    (progress) => {
      setProgress(progress);

      if (progress < 100) {
        return;
      }

      if (stories.length !== activeStoryIndex + 1) {
        onNext();
      } else {
        onEnd?.();
      }
    },
    [stories, activeStoryIndex, onEnd]
  );

  const updateStoriesSize = useCallback(() => {
    if (!contentRef.current) {
      return;
    }

    const { width, height } = contentRef.current.getBoundingClientRect();
    const maxWidth = width - WIDTH_EXTRA_SIZE;
    const maxHeight = height - HEIGHT_EXTRA_SIZE;

    const sizeList = stories.map((story) =>
      getStorySize(maxWidth, maxHeight, story.size, story.stretch)
    );

    setStoriesSizeList(sizeList);
  }, [stories]);

  useEffect(() => {
    updateStoriesSize();
    window.addEventListener('resize', updateStoriesSize);

    return () => {
      window.removeEventListener('resize', updateStoriesSize);
    };
  }, [stories]);

  return {
    contentRef,
    activeStory,
    activeStoryIndex,
    activeStorySize,
    hasPrevious,
    hasNext,
    progress,
    onProgress,
    onPrevious,
    onNext,
  };
}
