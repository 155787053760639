import classNames from 'classnames';
import React, { FC, PropsWithChildren } from 'react';

import { AddKeyPressHandler } from 'APP/packages/keyBoardHandler';
import { OverlayingPopup } from 'UIKIT/OverlayingPopup/OverlayingPopup';
import { PopupHeader } from 'UIKIT/PopupHeader/PopupHeader';

import styles from './Popup.styles.m.css';

interface IPopupProps {
  className?: string;
  overlayClassName?: string;
  overlayContainerClassName?: string;
  isOpened?: boolean;
  isHidden?: boolean;
  title?: string;
  isShowHeader?: boolean;
  isIgnoreClickOutside?: boolean;
  dataTest?: string;
  handlePrevStep?: (() => void) | null;
  headerClassName?: string;
  onClose?(): void;
  onCloseByOverlay?(): void;
  onCloseByKey?(): void;
  onCloseByButton?(): void;
}

export const Popup: FC<PropsWithChildren<IPopupProps>> = (props) => {
  const {
    className,
    headerClassName,
    overlayContainerClassName,
    overlayClassName,
    isOpened,
    isHidden = false,
    title,
    isShowHeader,
    dataTest,
    handlePrevStep,
    onClose,
    onCloseByOverlay,
    onCloseByKey,
    onCloseByButton,
    children,
    isIgnoreClickOutside,
  } = props;

  return (
    <OverlayingPopup
      className={classNames(overlayContainerClassName, { [styles.hide]: isHidden })}
      isOpened={isOpened}
      popupClassName={classNames(styles.container, className)}
      overlayClassName={overlayClassName}
      isIgnoreClickOutside={isIgnoreClickOutside}
      dataTest={dataTest}
      onClose={onCloseByOverlay || onClose}
    >
      <AddKeyPressHandler onEsc={onCloseByKey || onClose} />
      {(title || handlePrevStep || isShowHeader) && (
        <PopupHeader
          className={headerClassName}
          onClose={onCloseByButton || onClose}
          handlePrevStep={handlePrevStep}
          title={title}
        />
      )}
      {children}
    </OverlayingPopup>
  );
};
