import { observer } from 'mobx-react';
import React, { FC, useRef } from 'react';

import Smile from 'ICONS/ic-smile.svg';
import { FAB } from 'UIKIT/FAB/FAB';
import { Popover } from 'UIKIT/Popover/Popover';

import { EmojiStickersPanel } from './EmojiStickersPanel/EmojiStickersPanel';
import { useEmojisStickersButtonPresenter } from './EmojisStickersButton.presenter';
import styles from './EmojisStickersButton.styles.m.css';

export const EmojisStickersButton: FC = observer(() => {
  const presenter = useEmojisStickersButtonPresenter();
  const ref = useRef<HTMLButtonElement>(null);

  return (
    <>
      <FAB
        className={styles.button}
        setRef={ref}
        onClick={presenter.onToggle}
        size="10"
        theme={presenter.isOpened ? '2_1' : '2'}
        Icon={Smile}
      />
      <Popover
        isScrollEnabled
        className={styles.popover}
        isOpened={presenter.isOpened}
        placement="top-end"
        reference={ref.current}
        onClose={presenter.onClose}
      >
        <EmojiStickersPanel closePopover={presenter.onClose} />
      </Popover>
    </>
  );
});
