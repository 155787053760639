import { action, computed } from 'mobx';

import Me from './Me';
import Members from './Members';
import Mode from './Mode';
import PresentVideoMode from './PresentVideoMode';

class OngoingCall {
  constructor(root) {
    this.root = root;

    this.mode = new Mode(root, this);
    this.me = new Me(root, this);
    this.members = new Members(root, this);
    this.presentVideoMode = new PresentVideoMode(this);
  }

  @action
  reset() {
    this.members.reset();
  }

  @computed
  get call() {
    return this.root.Calls.calls[0] || null;
  }

  @computed
  get hasCall() {
    return Boolean(this.call);
  }

  @computed
  get callTitle() {
    if (!this.hasCall) {
      return '';
    }

    return this.call.avatar.title;
  }

  @computed
  get p2pOpponent() {
    if (this.mode.isP2PAudio || this.mode.isP2PVideo) {
      const member = this.call.members.memberList.find(({ userId }) => userId !== this.call.me.id);
      if (member) {
        return this.call.opponents.getOpponent(member.uid);
      }
    }

    return null;
  }

  @computed
  get hasEventsBar() {
    return this.mode.isGroupAudio || this.mode.isGroupVideo;
  }
}

export default OngoingCall;
