import { PayloadType } from 'APP/model/message/messageModel.types';
import { previewBuilder } from 'APP/packages/file-upload/previewBuilder';
import { convertFileToBlobUrl } from 'UTILS/file';

export const isMediaFile = (file) =>
  ['video', 'image'].includes(file.type.split('/')[0]) && file.type !== 'image/svg+xml';

const getVideoPayload = async (file) => {
  const userFileBlobUrl = convertFileToBlobUrl(file);

  try {
    let { thumbnail, width, height, duration } = await previewBuilder.getFirstVideoFrameAndDuration(
      file
    );

    return {
      type: PayloadType.Video,
      data: {
        userFile: file,
        userFileBlobUrl,
        duration,
        graphicSize: { width, height },
        localPreview: thumbnail,
      },
    };
  } catch (e) {
    console.info(`unsupported video file: ${file.name}`);
    return {
      type: PayloadType.File,
      data: { userFile: file, userFileBlobUrl },
    };
  }
};

const getImagePayload = async (file) => {
  return new Promise((resolve) => {
    const img = new Image();

    img.onload = () => {
      resolve({
        type: PayloadType.Image,
        data: {
          userFile: file,
          graphicSize: { width: img.width, height: img.height },
          localPreview: img.src,
        },
      });
      URL.revokeObjectURL(file);
    };

    img.onerror = () => {
      console.info(`unsupported image file: ${file.name}`);
      URL.revokeObjectURL(img.src);
      resolve({
        type: PayloadType.Image,
        data: {
          userFile: file,
          userFileBlobUrl: convertFileToBlobUrl(file),
        },
      });
    };

    img.src = URL.createObjectURL(file);
  });
};

export const getFilePayload = async (file) => {
  if (!isMediaFile(file)) {
    const type = file.type.includes('audio') ? PayloadType.AudioMessage : PayloadType.File;
    const userFileBlobUrl = convertFileToBlobUrl(file);
    return { type, data: { userFile: file, userFileBlobUrl } };
  }
  if (file.type.indexOf('video') === 0) {
    return await getVideoPayload(file);
  } else {
    return await getImagePayload(file);
  }
};
