import { request } from '../request/request';
import { BooleanResponse } from '../types/model/booleanResponse';
import { ComplaintReasonResponse } from '../types/model/complaintReasonResponse';
import { SendComplaintRequest } from '../types/model/sendComplaintRequest';

const ROOT_API = 'moderation';

export const getComplaintReasons = (): Promise<ComplaintReasonResponse[]> => {
  return request<ComplaintReasonResponse[]>({
    rootApi: ROOT_API,
    version: 'v1',
    url: '/getComplaintReasons',
    method: 'POST',
  });
};

export const sendComplaint = (data: SendComplaintRequest): Promise<BooleanResponse> => {
  return request<BooleanResponse>({
    rootApi: ROOT_API,
    version: 'v1',
    url: '/sendComplaint',
    method: 'POST',
    body: data,
  });
};
