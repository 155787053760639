import { useLocalStore, useAsObservableSource } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { RouterState } from 'APP/router/constants';
import Entities from 'APP/store';
import { getFormatNumberToString } from 'UTILS/getFormatNumberToString';

export default ({ message }) => {
  const source = useAsObservableSource({ message });

  const presenter = useLocalStore(() => ({
    get canLeaveComment() {
      return source.message.group.canLeaveComment;
    },

    get counter() {
      return Entities.Counters.getCounterById(source.message.threadId);
    },

    get count() {
      if (!source.message.threadId) {
        return '';
      }

      return presenter.counter
        ? getFormatNumberToString(presenter.counter.totalMessagesCount) || ''
        : '';
    },

    async onClick() {
      const threadGroup = await Tasks.thread.getOrCreateThread(source.message);
      await Tasks.group.setActiveGroup({
        groupId: threadGroup.id,
        state: {
          [RouterState.hasGoBack]: true,
        },
      });
    },
  }));

  return presenter;
};
