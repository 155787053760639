import { useLocalStore } from 'mobx-react';
import { useRef, useEffect } from 'react';

import dateService from 'APP/packages/date';
import validations from 'APP/packages/validations';
import Entities from 'APP/store';

export default ({ common }) => {
  const formValidations = validations.dateTime();
  const timeRef = useRef();

  const presenter = useLocalStore(() => ({
    isSubmitting: false,

    get date() {
      const conf = { month: 'long', day: 'numeric' };
      if (common.datetime.date.getFullYear() !== dateService.now().getFullYear()) {
        conf.year = 'numeric';
      }

      return dateService.format(common.datetime.date, conf);
    },

    get time() {
      return dateService.time(common.datetime.date, true);
    },

    get defaultTime() {
      return dateService.time(common.datetime.defaultDate, true);
    },

    get minTime() {
      if (!dateService.isToday(common.datetime.date)) {
        return null;
      }

      return dateService.time(dateService.now(), true);
    },

    get initialValues() {
      return {
        date: presenter.date,
        time: presenter.time,
      };
    },

    validateTime(value, formData, meta) {
      return formValidations.time.validate(value, formData, meta, {
        minTime: presenter.minTime,
      });
    },

    onDateFocus() {
      common.goToCalendar();
    },

    onChange({ values }) {
      common.datetime.setTime(values.time || presenter.defaultTime);
    },

    onTimeChange(event) {
      if (!event.target.value) {
        event.target.value = presenter.defaultTime;
        presenter.onChange({ values: { time: event.target.value } });
      }
    },

    onClose() {
      common.onClose();
    },

    onSubmit: async (values) => {
      presenter.isSubmitting = true;
      common.datetime.setTime(values.time);

      const result = await Entities.InputPanel.send.schedule(common.datetime.date);

      presenter.isSubmitting = false;

      if (result) {
        if (!common.isScheduleGroup) {
          common.goToScheduleGroup();
          return;
        }
      }
      // !result only canceled sending voice message due to privacy settings
      common.onClose();
    },
  }));

  useEffect(() => {
    const input = timeRef.current;
    input.addEventListener('input', presenter.onTimeChange);

    return () => {
      input.removeEventListener('input', presenter.onTimeChange);
    };
  }, []);

  return { presenter, timeRef };
};
