import { useAsObservableSource, useLocalStore } from 'mobx-react';

import { PayloadType } from 'APP/model/message/messageModel.types';

import { getFilePayload } from '../../Tasks/getMediaPayload';

export const useUploadFiles = ({ parentPresenter, order, isPayloadCreator }) => {
  const source = useAsObservableSource({ order, isPayloadCreator });

  const presenter = useLocalStore(() => ({
    currentPosition: null,

    uploadFiles: (files) => {
      if (!files || !files.length) {
        return;
      }

      presenter.currentPosition = source.order;

      const arrayFiles = Array.from(files);

      arrayFiles.forEach(async (file) => {
        let action = parentPresenter.setPayloadToPosition;
        const payload = await getFilePayload(file);

        if (presenter.currentPosition === source.order) {
          action = parentPresenter.editPayloadByOrder;
        }

        if (source.isPayloadCreator) {
          action = parentPresenter.setPayloadToEnd;
        }

        let actionData = {
          fileName: payload.data.userFile.name,
          fileSize: payload.data.userFile.size,
          userFile: payload.data.userFile,
          userFileBlobUrl: payload.data.userFileBlobUrl,
          payloadType: payload.type,
        };

        if ([PayloadType.Image, PayloadType.Video].includes(payload.type)) {
          actionData = {
            ...actionData,
            graphicSize: payload.data.graphicSize,
            mimeType: payload.data.userFile.type,
            localPreview: payload.data.localPreview,
            duration: payload.type === PayloadType.Video ? payload.data.duration : undefined,
          };
        }

        action(actionData, presenter.currentPosition);
        presenter.incrementPosition();
      });
    },

    // for multiple load files
    incrementPosition: () => {
      presenter.currentPosition = presenter.currentPosition + 1;
    },
  }));

  return presenter;
};
