import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { ALERT_TYPES } from 'APP/constants/app';
import { useTranslation } from 'APP/packages/translations';
import Entities from 'APP/store';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import { Group } from 'STORE/Groups/Group';
import { Space } from 'STORE/Spaces/Space';
import { IChipsItem } from 'UIKIT/Chips/ChipsItem/ChipsItem.types';
import { sortGroups } from 'UTILS/getSortedGroups';

export interface IMoveGroupsToSpacePopupParams {
  spaceId: string;
}

interface IMoveGroupsToSpacePresenter {
  checkedGroups: Set<string>;
  isSubmitting: boolean;
  groups: Group[];
  space: Space | null;
  searchText: string;
  chips: IChipsItem[];
  onSearchChange(searchText: string): void;
  onToggleGroup(groupId: string): void;
  onSubmit(): Promise<void>;
  onRemoveChips(chip: IChipsItem): void;
  onClose(): void;
}

export function useMoveGroupsToSpacePresenter({
  popupInstance,
  params,
}: ICommonPopupsProps<IMoveGroupsToSpacePopupParams>): IMoveGroupsToSpacePresenter {
  const { spaceId } = params!;
  const { t } = useTranslation();

  const presenter = useLocalStore<IMoveGroupsToSpacePresenter>(() => ({
    checkedGroups: new Set<string>(),
    isSubmitting: false,
    searchText: '',

    get groups(): Group[] {
      return Entities.GroupsStore.groups
        .filter(
          (group) =>
            group.isOwner &&
            (group.isChatGroup || group.isChannel) &&
            group.spaceId !== spaceId &&
            (!presenter.searchText ||
              group.name.toLowerCase().includes(presenter.searchText.trim().toLowerCase()))
        )
        .sort(sortGroups);
    },

    get space(): Space | null {
      return spaceId ? Entities.spacesStore.getById(spaceId) : null;
    },

    get chips(): IChipsItem[] {
      return [...presenter.checkedGroups].map((groupId) => {
        const group = Entities.GroupsStore.getGroupById(groupId);

        return {
          id: groupId,
          avatarTitle: group?.avatarTitle || '',
          avatarUrl: group?.avatarUrl || '',
          avatarColorIndex: group?.avatarColorIndex || '',
          title: group?.name || '',
        };
      });
    },

    onSearchChange(searchText: string): void {
      presenter.searchText = searchText;
    },

    onToggleGroup(groupId: string): void {
      const checkedGroups = presenter.checkedGroups;

      checkedGroups.has(groupId) ? checkedGroups.delete(groupId) : checkedGroups.add(groupId);
    },

    async onSubmit(): Promise<void> {
      if (!spaceId) {
        return;
      }

      presenter.isSubmitting = true;

      const groupIds = Array.from(presenter.checkedGroups);

      const canMove =
        groupIds.length === 1
          ? await Tasks.space.confirmSpaceGroupsForMove(spaceId, groupIds[0])
          : true;

      if (!canMove) {
        presenter.isSubmitting = false;
        return;
      }

      const success = await Tasks.space.moveGroupsToSpace(spaceId, groupIds);

      success
        ? Entities.toast.show(t('space_alert_moved'))
        : Tasks.app.addAlert({
            type: ALERT_TYPES.SOMETHING_WRONG_ERROR,
          });

      presenter.onClose();
    },

    onRemoveChips(chip: IChipsItem): void {
      presenter.checkedGroups.delete(chip.id);
    },

    onClose(): void {
      popupInstance.close();
    },
  }));

  useEffect(() => {
    if (!Entities.spacesStore.isLoading && !presenter.space) {
      presenter.onClose();
    }
  }, [presenter, presenter.space]);

  return presenter;
}
