import Tasks from 'APP/Tasks';
import { getRangeAroundNextUserMention } from 'APP/model/message/messageModel';
import { IMessage } from 'APP/model/message/messageModel.types';

export const loadRangeAroundNextUserMention = async (
  groupId: string,
  startTs: number,
  halfLimit = 50
): Promise<void> => {
  try {
    const messages: IMessage[] = await getRangeAroundNextUserMention(groupId, startTs, halfLimit);

    if (messages?.length) {
      await Tasks.relationships.loadUnknownDataFromMessages({ messages });

      Tasks.messaging.handleIsLoadedLastMessages({
        groupId,
        messages,
      });

      Tasks.messaging.updateOldOrFakeMessages({
        messages,
        groupId,
      });
    }
  } catch (error) {
    //
  }
};
