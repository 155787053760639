import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { NewMessagesBadge } from '../NewMessagesBadge/NewMessagesBadge';
import { useSpaceBadgePresenter } from './SpaceBadge.presenter';

interface ISpaceBadgeProps {
  className?: string;
}

export const SpaceBadge: FC<ISpaceBadgeProps> = observer(({ className }) => {
  const presenter = useSpaceBadgePresenter();

  if (presenter.hasNewMessages) {
    return <NewMessagesBadge className={className} />;
  }

  return null;
});
