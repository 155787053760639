import { useEffect, useRef } from 'react';

import Tasks from 'APP/Tasks';
import Entities from 'STORE';
import { Schedule } from 'STORE/Groups/Schedule';

const UPDATE_GROUP_DELAY = 5 * 60 * 1000;

export const useChatUpdateHandlerPresenter = (): void => {
  const updateTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const updateGroup = (): void => {
    const group = Entities.GroupsStore.activeGroup;
    if (group && !(group instanceof Schedule)) {
      const delay = Math.max(0, group.updateTs - Date.now() + UPDATE_GROUP_DELAY);
      updateTimer.current = setTimeout(() => {
        Tasks.group.loadGroupsByIds([group.id]);
      }, delay);
    }
  };

  useEffect(() => {
    const group = Entities.GroupsStore.activeGroup;
    if (group && group.withoutMe && !(group instanceof Schedule)) {
      group.messagesStore.setLoadedLastMessages(false);
    }
  }, [Entities.GroupsStore.activeGroup]);

  useEffect(() => {
    updateGroup();

    return (): void => {
      if (updateTimer.current) {
        clearTimeout(updateTimer.current);
      }
    };
  }, [Entities.GroupsStore.activeGroup?.updateTs]);
};
