import { observer } from 'mobx-react';
import React, { useCallback } from 'react';

import { List } from 'APP/components/List/List';
import { GroupsPlaceholder } from 'APP/packages/placeholders/GroupsPlaceholder/GroupsPlaceholder';
import { useTranslation } from 'APP/packages/translations';

import UserWithCheckbox from '../components/UserWithCheckbox';
import styles from './styles.m.scss';

const ExtendedUsers = ({ extendedUsers, common }) => {
  const { t } = useTranslation();

  const renderItem = useCallback(
    (contact) => {
      return (
        <UserWithCheckbox
          user={contact}
          toggleUser={common.toggleContact}
          getIsSelected={common.getIsSelected}
          canSelectMore={common.canSelectMore}
        />
      );
    },
    [common.canSelectMore]
  );

  const props = {
    className: styles.list,
    isLoading: extendedUsers.isLoading,
    isScrollable: false,
    data: extendedUsers.users,
    keyExtractor: (user) => user.id,
    hasMore: extendedUsers.hasMore,
    onEndReached: extendedUsers.loadMore,
    renderItem,
    SpinnerComponent: (
      <GroupsPlaceholder
        count={extendedUsers.users.length > 0 ? 2 : 10}
        className={styles.placeholders}
      />
    ),
  };

  return (
    <>
      <div className={styles.morePeople}>{t('extended_search_title')}</div>
      <List {...props} />
    </>
  );
};

export default observer(ExtendedUsers);
