import Tasks from 'APP/Tasks';
import { AudioSourceType } from 'APP/constants/app';
import { CallType } from 'APP/model/call/callModel.types';
import Entities from 'APP/store';

// ToDO: UNION_CALL - refactor in future task for union calls
export const startUnauthorizedCall = async (): Promise<void> => {
  const phone = Entities.UsersStore.Me.phone;

  const hasMediaPermissions = await Tasks.permissions.hasMediaPermissions();

  if (!hasMediaPermissions) {
    return;
  }

  Tasks.app.audioSource.setCurrentSource(AudioSourceType.Call);

  Tasks.calls.startCall({
    groupId: phone,
    withAudio: true,
    withVideo: false,
    callType: CallType.P2P,
    isSharedCall: true,
    channelId: null,
  });
};
