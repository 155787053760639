import { RewardStatusEnum } from 'APP/model/userSettings/userSettingsModel.types';
import Entities from 'STORE';

import { GroupsEvent } from '../GroupsEvent';
import { IUserRewardUpdatedData } from './UserRewardUpdated.types';

export class UserRewardUpdated extends GroupsEvent<IUserRewardUpdatedData> {
  async process(): Promise<void> {
    if (!this.eventPayload) {
      return;
    }

    const rewardType = this.eventPayload.rewardType;
    const rewardStatus = this.eventPayload.rewardStatus;

    const reward = Entities.rewards.rewardsProgress.get(rewardType);

    if (!reward) {
      return;
    }
    if (rewardStatus === RewardStatusEnum.Achieved) {
      reward.achieved = true;
    }

    if (rewardStatus === RewardStatusEnum.Acknowledged) {
      reward.acknowledged = true;
    }

    Entities.rewards.updateReward(reward);

    const hasAchievedRewards = Entities.rewards.getSomeRewardNoneAcquired();

    if (hasAchievedRewards) {
      Entities.rewards.setHasAchievedRewards(true);
    }
  }
}
