import { IClientConfigProperty } from 'APP/model/auth/authModel.types';
import logger from 'APP/packages/logger';
import Entities from 'APP/store';

export function handleCoinfideHost(property: IClientConfigProperty): void {
  try {
    const hosts: string[] = JSON.parse(property.value);
    Entities.marketplace.setMarketHosts(hosts);
  } catch (error) {
    logger.get('clientConfig').error('coinfideHost', error);
  }
}
