import { observer } from 'mobx-react';
import React from 'react';

import { useTranslation } from 'APP/packages/translations';
import { Chips } from 'UIKIT/Chips/Chips';
import { SearchInput } from 'UIKIT/SearchInput/SearchInput';

import Users from './Users';
import { useSelectContactsPresenter } from './presenter/SelectContacts.presenter';
import styles from './styles.m.scss';

const SelectContacts = ({
  isLoading = false,
  contacts,
  setSelectedIds,
  selectedContactsIds,
  setSearchValue,
  searchContacts,
  groupId = '',
  children = undefined,
  targetGroupType,
}) => {
  const { myContacts, common, extendedUsers } = useSelectContactsPresenter({
    selectedContactsIds,
    setSelectedIds,
    setSearchValue,
    contacts,
    searchContacts,
    isLoading,
    groupId,
    targetGroupType,
  });

  const { t } = useTranslation();

  return (
    <>
      <SearchInput
        className={styles.search}
        onChange={common.onSearchValueChange}
        value={common.searchValue}
        placeholder={t('main_menu_search_note')}
      />
      <Chips
        chips={common.chips}
        keyExtractor={(item) => item.data.id}
        onRemove={common.removeChips}
      />
      {children}
      <Users
        common={common}
        myContacts={myContacts}
        extendedUsers={extendedUsers}
      />
    </>
  );
};

export default observer(SelectContacts);
