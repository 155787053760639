import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { DeleteButton } from '../../components/DeleteButton/DeleteButton';
import { IItemMediaPresenter } from '../ItemMedia.presenter';
import styles from './MediaMessageImage.styles.m.css';

interface IMediaMessageImageProps {
  presenter: IItemMediaPresenter;
}

export const MediaMessageImage: FC<IMediaMessageImageProps> = observer(({ presenter }) => {
  return (
    <div className={styles.container}>
      <div
        className={styles.backdrop}
        style={{ backgroundImage: `url(${presenter.previewUrl})` }}
      />
      <img
        src={presenter.previewUrl}
        className={styles.image}
        alt={presenter.name}
      />
      {presenter.canBeRemoved && (
        <DeleteButton
          onClick={presenter.remove}
          className={styles.delete}
        />
      )}
    </div>
  );
});
