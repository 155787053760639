import { BOT_COMMAND_REGEX } from 'APP/constants/app';

import getContentPositions from '../../utils/getContentPositions';
import isValidStartPosition from '../../utils/isValidStartPosition';

export default (text, index, symbolMatches) => {
  if (!isValidStartPosition(text, index)) {
    return;
  }

  const { startPosition, content } = getContentPositions(text, index);
  const matches = content.match(BOT_COMMAND_REGEX);

  if (matches && matches[0]) {
    symbolMatches['/'].push({
      start: startPosition,
      end: startPosition + matches[0].length,
    });
  }
};
