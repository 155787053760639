import { OpenVidu, Session, Publisher as OpenviduPublisher } from 'openvidu-browser';

import {
  ICallProviderJoinParams,
  ProviderUid,
} from 'APP/packages/callProviders/callProviders.types';
import logger from 'APP/packages/logger';

export class OpenviduScreenShareProvider {
  private OV: OpenVidu;
  private session: Session;
  private uid: ProviderUid;
  private isPublished = false;
  openviduPublisher: OpenviduPublisher | null;
  isJoined = false;

  constructor() {
    this.OV = new OpenVidu();
    this.session = this.OV.initSession();
  }

  async join(params: ICallProviderJoinParams): Promise<void> {
    const { token, uid } = params;

    try {
      this.uid = uid;
      await this.session.connect(token);
      this.isJoined = true;
    } catch (e) {
      logger.get('CallProviders').error('OpenViduScreenProvider.join', e);
    }
  }

  async startScreenSharing(params: {
    track?: MediaStreamTrack;
    onEnded: () => void;
    needToPublish?: boolean;
  }): Promise<boolean> {
    if (!this.uid) {
      return false;
    }

    try {
      const screenSource = params.track || 'screen';

      const screenPublisher = await this.OV.initPublisherAsync(undefined, {
        videoSource: screenSource,
        audioSource: false,
      });

      this.openviduPublisher = screenPublisher;

      const track = screenPublisher.stream.getMediaStream()?.getVideoTracks()[0];

      if (track && params.onEnded) {
        track.onended = params.onEnded;
      }

      screenPublisher.once('accessAllowed', () => {
        if (!this.session) {
          return;
        }
        if (params.needToPublish) {
          this.isPublished = true;
          this.session.publish(screenPublisher);
        }
      });

      screenPublisher.once('accessDenied', () => {
        params.onEnded();
      });

      return true;
    } catch (e) {
      params.onEnded();
      logger.get('CallProviders').error('openvidu.startScreenShare', e);

      return false;
    }
  }

  async stopScreenShare(): Promise<void> {
    if (this.openviduPublisher && this.isPublished) {
      await this.session.unpublish(this.openviduPublisher);
    }
    this.isPublished = false;
    this.openviduPublisher = null;
    this.session.disconnect();
  }
}
