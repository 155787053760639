import classNames from 'classnames';
import React, { ChangeEventHandler, FC } from 'react';

import popupItemStyles from 'APP/packages/UiKit/PopupItem/styles.m.scss';
import UnionIcon from 'ICONS/union.svg';

import styles from './CheckboxRow.styles.m.scss';

interface ICheckboxRowProps {
  onClick?: ChangeEventHandler<HTMLInputElement>;
  isSelected: boolean;
}

export const CheckboxRow: FC<ICheckboxRowProps> = (props) => {
  const { onClick, isSelected, children } = props;

  return (
    <label
      className={classNames(
        popupItemStyles.container,
        popupItemStyles.interactive,
        styles.container
      )}
    >
      {children}
      <input
        type="checkbox"
        className={styles.checkbox}
        checked={isSelected}
        onChange={onClick}
      />
      <div className={styles.checkboxView}>{isSelected && <UnionIcon />}</div>
    </label>
  );
};
