import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { useTranslation } from 'APP/packages/translations';
import ExternalLink from 'UIKIT/ExternalLink';

import usePresenter from './presenter';
import styles from './styles.m.css';

const DonateButton = ({ className }) => {
  const { t } = useTranslation();
  const presenter = usePresenter();

  return (
    <ExternalLink
      className={classNames(styles.container, className)}
      href={presenter.donationUrl}
      theme="2"
    >
      {t('channel_donate')}
    </ExternalLink>
  );
};

export default observer(DonateButton);
