import { ElementType } from 'react';

import IcAddLink from 'ICONS/ic-add-link.svg';
import IcBold from 'ICONS/ic-bold.svg';
import IcItalic from 'ICONS/ic-italic.svg';
import IcStrikeThrough from 'ICONS/ic-strikethrough.svg';
import IcUnderline from 'ICONS/ic-underline.svg';

import { ITextFormattingMenuViewParams, ViewType } from '../TextFormattingMenu.types';

interface IActionItem {
  key: string;
  icon: ElementType;
  isActive: boolean;
  hasDivider: boolean;
  onClick: () => void;
}

interface ITextFormattingMainViewPresenter {
  actionList: IActionItem[];
  checkRangeToTag(range: Range, tagNames: string[]): boolean;
}

export const useTextFormattingMainViewPresenter = (
  data: ITextFormattingMenuViewParams
): ITextFormattingMainViewPresenter => {
  const {
    selectionRange,
    onBold,
    onItalic,
    onUnderline,
    onStrikeThrough,
    onRemoveLink,
    onChangeView,
  } = data;

  const presenter: ITextFormattingMainViewPresenter = {
    get actionList(): IActionItem[] {
      const isLinkActive = presenter.checkRangeToTag(selectionRange, ['a']);

      return [
        {
          key: 'bold',
          icon: IcBold,
          isActive: presenter.checkRangeToTag(selectionRange, ['b', 'strong']),
          hasDivider: false,
          onClick: onBold,
        },
        {
          key: 'italic',
          icon: IcItalic,
          isActive: presenter.checkRangeToTag(selectionRange, ['i', 'em']),
          hasDivider: false,
          onClick: onItalic,
        },
        {
          key: 'underline',
          icon: IcUnderline,
          isActive: isLinkActive || presenter.checkRangeToTag(selectionRange, ['u']),
          hasDivider: false,
          onClick: onUnderline,
        },
        {
          key: 'strikeThrough',
          icon: IcStrikeThrough,
          isActive: presenter.checkRangeToTag(selectionRange, ['s', 'strike']),
          hasDivider: false,
          onClick: onStrikeThrough,
        },
        {
          key: 'link',
          icon: IcAddLink,
          isActive: isLinkActive,
          hasDivider: true,
          onClick: () => (isLinkActive ? onRemoveLink() : onChangeView(ViewType.Link)),
        },
      ];
    },

    checkRangeToTag(range: Range, tagNames: string[]): boolean {
      if (!range.startContainer.parentNode || !range.endContainer.parentNode) {
        return false;
      }

      return tagNames.some((tag) => {
        const start = (range.startContainer.parentNode as Element).closest(tag);
        const end = (range.endContainer.parentNode as Element).closest(tag);

        if (!start || !end) {
          return false;
        }

        return start === end;
      });
    },
  };

  return presenter;
};
