import { addOpponentToCall } from 'APP/Tasks/calls/addOpponentToCall';
import { ClientRole } from 'APP/constants/calls';
import { ICallProviderOpponent } from 'APP/packages/callProviders/callProviders.types';
import Entities from 'APP/store';

export const userJoined = (providerOpponent: ICallProviderOpponent): void => {
  const call = Entities.Calls.ongoingCall;
  if (!call) {
    return;
  }

  const isScreenSharing = call.members.memberList.some((member) => {
    return member.shareScreenUid === providerOpponent.uid;
  });

  if (call.isScreenSharing && isScreenSharing) {
    // check if started 2 screen sharings at the same time
    return;
  }

  addOpponentToCall({ uid: providerOpponent.uid, providerOpponent, opponentRole: ClientRole.Host });
};
