import api from 'APP/packages/api';
import logger from 'APP/packages/logger';

export const updatePermissionRequestForUser = async ({ channelId, userId, access, status }) => {
  try {
    await api.calls.updatePermissionRequestForUser({
      channelId,
      userId,
      access,
      status,
    });
  } catch (error) {
    logger.get('API').error('calls.updatePermissionRequestForUser', error);
  }
};
