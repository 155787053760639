import { CollectionResponseMessage } from 'APP/packages/api/types/model/collectionResponseMessage';
import { GetGroupMessagesRequest } from 'APP/packages/api/types/model/getGroupMessagesRequest';
import { GetGroupMessagesResponse } from 'APP/packages/api/types/model/getGroupMessagesResponse';
import { GetMessageRequest } from 'APP/packages/api/types/model/getMessageRequest';
import { GetMessagesAroundByTsRequest } from 'APP/packages/api/types/model/getMessagesAroundByTsRequest';
import { GetScheduledMessagesRequest } from 'APP/packages/api/types/model/getScheduledMessagesRequest';
import { GetScheduledMessagesResponse } from 'APP/packages/api/types/model/getScheduledMessagesResponse';
import { MessagesResponse } from 'APP/packages/api/types/model/messagesResponse';

import { request } from '../request/request';
import { BooleanResponse } from '../types/model/booleanResponse';
import { ClearGroupMessageRequest } from '../types/model/clearGroupMessageRequest';
import { ClearGroupMessageResponse } from '../types/model/clearGroupMessageResponse';
import { ConfirmReadGroupMessagesRequest } from '../types/model/confirmReadGroupMessagesRequest';
import { DeleteMessagesRequest } from '../types/model/deleteMessagesRequest';
import { DeleteScheduledMessagesRequest } from '../types/model/deleteScheduledMessagesRequest';
import { ForwardMessagesResponse } from '../types/model/forwardMessagesResponse';
import { ForwardRequest } from '../types/model/forwardRequest';
import { GetAllMessagesResponse } from '../types/model/getAllMessagesResponse';
import { GetMessagesBetweenTsRequest } from '../types/model/getMessagesBetweenTsRequest';
import { GetRangeAroundNextUserMentionRequest } from '../types/model/getRangeAroundNextUserMentionRequest';
import { GetRangeAroundNextUserMentionResponse } from '../types/model/getRangeAroundNextUserMentionResponse';
import { GroupHistoricalMessagesRequest } from '../types/model/groupHistoricalMessagesRequest';
import { Message } from '../types/model/message';
import { SendMessageResponse } from '../types/model/sendMessageResponse';
import { SendNewMessageRequest } from '../types/model/sendNewMessageRequest';
import { UpdateMessageReactionRequest } from '../types/model/updateMessageReactionRequest';
import { UpdateMessageRequest } from '../types/model/updateMessageRequest';

const ROOT_API = 'messaging';

export const turnNotificationInGroup = (data: any): Promise<any> => {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/turnNotificationInGroup',
    method: 'POST',
    body: data,
  });
};

export const getGroupHistoricalMessages = (
  data: GroupHistoricalMessagesRequest
): Promise<GetAllMessagesResponse> => {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/getGroupHistoricalMessages',
    method: 'POST',
    body: data,
  });
};

export const confirmReadGroupMessages = (
  data: ConfirmReadGroupMessagesRequest
): Promise<BooleanResponse> => {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/confirmReadGroupMessages',
    method: 'POST',
    body: data,
  });
};

// TODO: apply interfaces from OPEN Api
export const confirmReadGroupsMessages = (data: {
  confirmReads: ConfirmReadGroupMessagesRequest[];
}): Promise<BooleanResponse> => {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/confirmReadGroupsMessages',
    method: 'POST',
    body: data,
  });
};

export const getGroupMessages = (
  data: GetGroupMessagesRequest
): Promise<GetGroupMessagesResponse> => {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/getGroupMessages',
    method: 'POST',
    body: data,
  });
};

export const deleteMessage = (data: any): Promise<any> => {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/deleteMessage',
    method: 'POST',
    body: data,
  });
};

export const deleteMessages = (data: DeleteMessagesRequest): Promise<BooleanResponse> => {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/deleteMessages',
    method: 'POST',
    body: data,
  });
};

export const updateMessage = (data: UpdateMessageRequest): Promise<Message> => {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/updateMessage',
    method: 'POST',
    body: data,
  });
};

export const forwardMessage = (data: ForwardRequest): Promise<ForwardMessagesResponse> => {
  return request<ForwardMessagesResponse>({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/forwardMessage',
    method: 'POST',
    body: data,
  });
};

export const getMessage = (data: GetMessageRequest): Promise<Message> => {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/getMessage',
    method: 'POST',
    body: data,
  });
};

export const getRangeMessageByTs = (
  data: GetMessagesAroundByTsRequest
): Promise<MessagesResponse> => {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/getRangeMessageByTs',
    method: 'POST',
    body: data,
  });
};

export const clearGroupHistory = (
  data: ClearGroupMessageRequest
): Promise<ClearGroupMessageResponse> => {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/clearGroupHistory',
    method: 'POST',
    body: data,
  });
};

export const leaveGroup = (data: any): Promise<any> => {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/leaveGroup',
    method: 'POST',
    body: data,
  });
};

export const deleteGroup = (data: any): Promise<any> => {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/deleteGroup',
    method: 'POST',
    body: data,
  });
};

export const joinGroup = (data: any): Promise<any> => {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/joinGroup',
    method: 'POST',
    body: data,
  });
};

export const sendNewMessage = (data: SendNewMessageRequest): Promise<SendMessageResponse> => {
  return request<SendMessageResponse>({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/sendNewMessage',
    method: 'POST',
    body: data,
  });
};

export const getPublicGroupByName = (data: any): Promise<any> => {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/getPublicGroupByName',
    method: 'POST',
    body: data,
  });
};

export const clearGroupHistoryForAll = (
  data: ClearGroupMessageRequest
): Promise<ClearGroupMessageResponse> => {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/clearGroupHistoryForAll',
    method: 'POST',
    body: data,
  });
};

export const getRangeAroundMessage = (data: any): Promise<any> => {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/getRangeAroundMessage',
    method: 'POST',
    body: data,
  });
};

export const updateMessageReaction = (data: UpdateMessageReactionRequest): Promise<Message> => {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/updateMessageReaction',
    method: 'POST',
    body: data,
  });
};

export const getLinkPreview = (data: any): Promise<any> => {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/getLinkPreview',
    method: 'POST',
    body: data,
  });
};

export const search = (data: any): Promise<any> => {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/search',
    method: 'POST',
    body: data,
  });
};

export const increaseViews = (data: any): Promise<any> => {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/increaseViews',
    method: 'POST',
    body: data,
  });
};

export const getUsersViewedMessage = (data: any): Promise<any> => {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/getUsersViewedMessage',
    method: 'POST',
    body: data,
  });
};

export const translateMessage = (data: any): Promise<any> => {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/translateMessage',
    method: 'POST',
    body: data,
  });
};

export const transcribeVoiceMessage = (data: any): Promise<any> => {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/transcribeVoiceMessage',
    method: 'POST',
    body: data,
  });
};

export const getDrafts = (data: any): Promise<any> => {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/getDrafts',
    method: 'POST',
    body: data,
  });
};

export const getDraft = (data: any): Promise<any> => {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/getDraft',
    method: 'POST',
    body: data,
  });
};

export const saveDraft = (data: any): Promise<any> => {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/saveDraft',
    method: 'POST',
    body: data,
  });
};

export const updateDraft = (data: any): Promise<any> => {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/updateDraft',
    method: 'POST',
    body: data,
  });
};

export const deleteDrafts = (data: any): Promise<any> => {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/deleteDrafts',
    method: 'POST',
    body: data,
  });
};

export const publishDraft = (data: any): Promise<any> => {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/publishDraft',
    method: 'POST',
    body: data,
  });
};

export const copyImageFromExternalResource = (data: any): Promise<any> => {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/copyImageFromExternalResource',
    method: 'POST',
    body: data,
  });
};

export const saveScheduledMessage = (data: any): Promise<any> => {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/saveScheduledMessage',
    method: 'POST',
    body: data,
  });
};

export const getGroupScheduledMessages = (
  data: GetScheduledMessagesRequest
): Promise<GetScheduledMessagesResponse> => {
  return request<GetScheduledMessagesResponse>({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/getGroupScheduledMessages',
    method: 'POST',
    body: data,
  });
};

export const getAllScheduledMessages = (): Promise<GetScheduledMessagesResponse> => {
  return request<GetScheduledMessagesResponse>({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/getAllScheduledMessages',
    method: 'POST',
  });
};

export const getScheduledMessage = (data: any): Promise<any> => {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/getScheduledMessage',
    method: 'POST',
    body: data,
  });
};

export const deleteScheduledMessages = (
  data: DeleteScheduledMessagesRequest
): Promise<BooleanResponse> => {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/deleteScheduledMessages',
    method: 'POST',
    body: data,
  });
};

export const updateScheduledMessage = (data: any): Promise<any> => {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/updateScheduledMessage',
    method: 'POST',
    body: data,
  });
};

export const getMessagesByMediaTypes = (data: any): Promise<any> => {
  return request({
    rootApi: ROOT_API,
    version: 'v3',
    url: '/getMessagesByMediaTypes',
    method: 'POST',
    body: data,
  });
};

export const getMessagesWithCommentCount = (data: any): Promise<any> => {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/getMessagesWithCommentCount',
    method: 'POST',
    body: data,
  });
};

export const getMessages = (data: any): Promise<any> => {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/getMessages',
    method: 'POST',
    body: data,
  });
};

export const getRangeAroundNextUserMention = (
  data: GetRangeAroundNextUserMentionRequest
): Promise<GetRangeAroundNextUserMentionResponse> => {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/getRangeAroundNextUserMention',
    method: 'POST',
    body: data,
  });
};

export const getMessagesBetweenTs = (
  data: GetMessagesBetweenTsRequest
): Promise<CollectionResponseMessage> => {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/getMessagesBetweenTs',
    method: 'POST',
    body: data,
  });
};
