import { ElectronApi } from 'APP/packages/electron/ElectronApi';
import config from 'CONFIG';

export function isTelegramAuthEnabled(): boolean {
  return ElectronApi.isEnabled
    ? !!config.features.telegramAuthDesktop
    : !!config.features.telegramAuthWeb;
}

export function isGoogleAuthEnabled(): boolean {
  return ElectronApi.isEnabled
    ? !!config.features.googleAuthDesktop
    : !!config.features.googleAuthWeb;
}
