import { RouterState } from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';

export const usePostArticlePresenter = ({ message }) => {
  const navigateTo = useNavigateTo();

  const presenter = {
    get title() {
      return message.renderPayload.title;
    },

    get textPayload() {
      return message.renderPayload.firstTextPayload?.payload || null;
    },

    get mediaPayload() {
      return message.renderPayload.firstMediaPayload?.payload || null;
    },

    async onClick() {
      const { groupId, id: messageId } = message;
      const state = {
        [RouterState.hasGoBack]: true,
      };

      navigateTo({
        to: `/${message.group.route}/${groupId}/article-preview/${messageId}`,
        state,
      });
    },
  };

  return presenter;
};
