import { useLocalStore } from 'mobx-react';

export default ({ common }) => {
  const presenter = useLocalStore(() => ({
    selectedDate: common.datetime.date,

    get date() {
      return common.datetime.date;
    },

    get minDate() {
      return new Date();
    },

    onChange(date) {
      presenter.selectedDate = date;
    },

    onChoose() {
      common.datetime.setDate(presenter.selectedDate);
      common.goToCommon();
    },

    onClose() {
      common.goToCommon();
    },
  }));

  return presenter;
};
