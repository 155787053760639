import classNames from 'classnames';
import React, { FC } from 'react';

import { SpaceAvatar } from 'APP/components/Avatar/SpaceAvatar/SpaceAvatar';
import { VerifiedBadge } from 'APP/components/VerifiedBadge/VerifiedBadge';
import { Space } from 'STORE/Spaces/Space';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';

import styles from './MoveGroupToSpaceList.styles.m.css';

interface IMoveGroupToSpaceList {
  spaces: Space[];
  onSelectSpace(spaceId: string): void;
}

export const MoveGroupToSpaceList: FC<IMoveGroupToSpaceList> = ({ spaces, onSelectSpace }) => {
  return (
    <div className={classNames(styles.list, customScrollStyles.container)}>
      {spaces.map((space) => (
        <div
          className={styles.listItem}
          key={space.id}
          role="button"
          tabIndex={0}
          onClick={(): void => onSelectSpace(space.id)}
        >
          <SpaceAvatar
            className={styles.avatar}
            space={space}
          ></SpaceAvatar>
          <div className={styles.nameWrapper}>
            <h3 className={styles.name}>{space.name}</h3>
            {space.verified && <VerifiedBadge />}
          </div>
        </div>
      ))}
    </div>
  );
};
