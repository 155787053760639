import { MAX_COUNT_BROADCASTERS } from 'APP/constants/calls';
import { AgoraCallMember } from 'APP/packages/api/types/model/agoraCallMember';
import { CallConnectedResponse } from 'APP/packages/api/types/model/callConnectedResponse';
import { CallCreatedResponse } from 'APP/packages/api/types/model/callCreatedResponse';
import { CallInfoV2Response } from 'APP/packages/api/types/model/callInfoV2Response';
import { CallMemberV2 } from 'APP/packages/api/types/model/callMemberV2';
import { GroupCallChanged } from 'APP/packages/api/types/model/groupCallChanged';
import { IncomingCall } from 'APP/packages/api/types/model/incomingCall';
import { StreamChanged } from 'APP/packages/api/types/model/streamChanged';

import { mapCallPermissionsResponse } from '../callPermissions/callPermissionsModel.mapper';
import {
  ICallCreatedInfo,
  ICallInfo,
  ICallMember,
  CallType,
  IIncomingCall,
  IGroupCallChanged,
  IStreamChanged,
} from './callModel.types';

export const mapCallMemberResponse = (data: AgoraCallMember[]): ICallMember[] => {
  return data.map(({ agoraUid, shareScreenUid, ...item }) => {
    return {
      ...item,
      providerUserUid: agoraUid,
      shareScreenUid: shareScreenUid,
    };
  });
};

export function mapActiveStreamsData(data: StreamChanged[]): IStreamChanged[] {
  return data.map(mapStreamChanged);
}

export function mapStreamChanged(data: StreamChanged): IStreamChanged {
  return {
    ...data,
    channelId: data.channel,
  };
}

export function mapActiveCallsData(data: GroupCallChanged[]): IGroupCallChanged[] {
  return data.map(mapGroupCallChanged);
}

export function mapIncomingCall(data: IncomingCall): IIncomingCall {
  return {
    ...data,
    channelId: data.channel,
    callVisibleDuration: Number(data.callVisibleDuration),
  };
}

export function mapGroupCallChanged(data: GroupCallChanged): IGroupCallChanged {
  return {
    ...data,
    channelId: data.channel,
  };
}

export const mapCallInfoResponse = (info: CallConnectedResponse): ICallInfo => {
  const {
    token,
    agoraUid,
    shareScreenUid,
    maxBroadcasters = MAX_COUNT_BROADCASTERS,
    ...rest
  } = info;
  return {
    ...rest,
    maxBroadcasters,
    audioVideoToken: token,
    audioVideoUid: agoraUid,
    shareScreenUid: shareScreenUid,
    callType: info.callType as unknown as CallType,
    members: mapCallMemberResponse(info.members),
    permissions: mapCallPermissionsResponse(info.permissions),
  };
};

export const mapCreatedCallInfoResponse = (response: CallCreatedResponse): ICallCreatedInfo => {
  const {
    token,
    agoraUid,
    shareScreenUid,
    maxBroadcasters = MAX_COUNT_BROADCASTERS,
    ...rest
  } = response;
  return {
    ...rest,
    maxBroadcasters,
    shareScreenUid: shareScreenUid,
    callVisibleDuration: Number(response.callVisibleDuration),
    members: mapCallMemberResponse(response.members),
    audioVideoToken: token,
    audioVideoUid: agoraUid,
    permissions: mapCallPermissionsResponse(response.permissions),
  };
};

export const mapCallV2MemberResponse = (data: CallMemberV2[]): ICallMember[] => {
  return data.map(({ cameraId, screenId, ...item }) => {
    return {
      ...item,
      providerUserUid: cameraId,
      shareScreenUid: screenId,
    };
  });
};

export const mapCallV2InfoResponse = (info: CallInfoV2Response, callType: CallType): ICallInfo => {
  const { cameraId, screenId, cameraToken, screenToken, ...rest } = info;
  return {
    ...rest,
    audioVideoUid: cameraId,
    shareScreenUid: screenId,
    shareScreenToken: screenToken,
    audioVideoToken: cameraToken,
    callType,
    maxBroadcasters: MAX_COUNT_BROADCASTERS,
    currentTs: String(Date.now()),
    members: mapCallV2MemberResponse(info.members),
    permissions: mapCallPermissionsResponse(info.permissions),
  };
};

export const mapCreatedCallV2InfoResponse = (response: CallInfoV2Response): ICallCreatedInfo => {
  const { cameraId, screenId, cameraToken, screenToken, ...rest } = response;
  return {
    ...rest,
    audioVideoUid: cameraId,
    shareScreenUid: screenId,
    shareScreenToken: screenToken,
    audioVideoToken: cameraToken,
    maxBroadcasters: MAX_COUNT_BROADCASTERS,
    callVisibleDuration: 1000000000,
    currentTs: String(Date.now()),
    members: mapCallV2MemberResponse(response.members),
    permissions: mapCallPermissionsResponse(response.permissions),
  };
};
