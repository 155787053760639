import { computed, action } from 'mobx';

import Tasks from 'APP/Tasks';

class Me {
  constructor(root, parent) {
    this.root = root;
    this.parent = parent;
  }

  @computed
  get me() {
    return this.parent.call && this.parent.call.me;
  }

  @computed
  get isAudioMuted() {
    if (!this.me) {
      return true;
    }

    return this.me.isAudioMuted;
  }

  @computed
  get isVideoMuted() {
    if (!this.me) {
      return true;
    }

    return this.me.isVideoMuted;
  }

  @computed
  get isSharedScreen() {
    if (!this.me) {
      return false;
    }

    return this.me.isSharedScreen;
  }

  @computed
  get isAvailableMuteAudio() {
    if (!this.me) {
      return false;
    }

    return this.me.isAvailableMuteAudio;
  }

  @computed
  get isRaiseHand() {
    return this.me.isRaiseHand;
  }

  @computed
  get isDisabledShareScreen() {
    if (!this.me) {
      return true;
    }

    return this.me.isDisabledShareScreen;
  }

  @action
  shareScreen = async (electronSourceId) => {
    await Tasks.calls.screenSharing.shareScreen(electronSourceId);
  };

  @action
  stopSharingScreen = async () => {
    await Tasks.calls.screenSharing.stopSharingScreen();
  };
}

export default Me;
