import { useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { PayloadType } from 'APP/model/message/messageModel.types';
import Entities from 'APP/store';

export default ({ common, focus }) => {
  const presenter = useLocalStore(() => ({
    get inputPanel() {
      return Entities.InputPanelsStore.activeInputPanel;
    },

    onEnter: (event) => {
      event.stopPropagation();
      event.preventDefault();

      const command = common.commands[focus.focusedIndex];

      presenter.send(command);
    },

    send: ({ command }) => {
      Tasks.messaging.createNewMessages({
        messages: [
          {
            type: PayloadType.RichText,
            data: {
              text: command,
            },
            groupId: common.group.id,
          },
        ],
      });

      presenter.inputPanel.bots.hideCommandList();
    },
  }));

  return presenter;
};
