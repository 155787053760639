import classNames from 'classnames';
import React, { FC } from 'react';

import { TextRenderer } from 'APP/components/TextRenderer/TextRenderer';

import { IPayloadProps } from '../Payloads.types';
import { MiniatureMessageLayout } from '../components/MiniatureMessageLayout/MiniatureMessageLayout';
import styles from './CircleVideoPayload.styles.m.css';

export const CircleVideoPayload: FC<IPayloadProps> = (props) => {
  const { titleClassName, textClassName, message, title, onClick } = props;
  const { typeDescription, previewUrl, graphicSize, comment } = message.payload;

  return (
    <MiniatureMessageLayout
      isVideo
      titleClassName={titleClassName}
      textClassName={textClassName}
      imagePreviewClassName={styles.image}
      imageUrl={previewUrl}
      graphicSize={graphicSize}
      title={title}
      text={typeDescription}
      messageId={message.id}
      onClick={onClick}
    >
      {comment && (
        <TextRenderer
          isPlainText
          className={classNames(styles.text, textClassName)}
        >
          {comment}
        </TextRenderer>
      )}
    </MiniatureMessageLayout>
  );
};
