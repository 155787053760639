import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { TextRenderer } from 'APP/components/TextRenderer/TextRenderer';
import { useTranslation } from 'APP/packages/translations';

import usePresenter from './presenter';
import styles from './styles.m.css';

const TextRendererCollapsed = ({ className, textClassName, rows = 0, children, ...props }) => {
  const { t } = useTranslation();
  const { presenter, ref } = usePresenter({ rows });

  return (
    <>
      <div
        className={classNames(styles.container, className)}
        style={presenter.style}
        ref={ref}
      >
        <TextRenderer
          className={classNames(styles.text, textClassName)}
          {...props}
        >
          {children}
        </TextRenderer>
      </div>

      {presenter.isCollapsed && (
        <button
          className={styles.button}
          onClick={presenter.onOpen}
        >
          {t('news_feed_expand')}
        </button>
      )}
    </>
  );
};

export default observer(TextRendererCollapsed);
