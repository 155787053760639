import { DEFAULT_MEDIA_PREVIEW_IMG } from 'APP/constants';

import uploader from './uploader';

export async function getFilePreviewUrl(
  file: File | Blob,
  fileName: string,
  localPreview?: string
): Promise<string> {
  if (file.type.indexOf('video') === 0) {
    try {
      let thumbnailBlob: Blob;
      if (localPreview) {
        thumbnailBlob = uploader.dataURItoBlob(localPreview, {
          type: 'image/png',
        });
      } else {
        const { thumbnail } = await getFirstVideoFrameAndDuration(file, 800, 600);
        thumbnailBlob = uploader.dataURItoBlob(thumbnail, {
          type: 'image/jpeg',
        });
      }

      const { url } = await uploader.getUploadUrl('userFile');

      const rawThumbnail = await uploader.uploadFile(url, thumbnailBlob, 'thumbnail-' + fileName);

      return rawThumbnail[0].url;
    } catch (e) {
      return DEFAULT_MEDIA_PREVIEW_IMG;
    }
  }
  return DEFAULT_MEDIA_PREVIEW_IMG;
}

interface IFrameRequest {
  thumbnail: string;
  width: number;
  height: number;
  duration: number;
}
export async function getFirstVideoFrameAndDuration(
  videoFile: File | Blob,
  maxWidth?: number,
  maxHeight?: number
): Promise<IFrameRequest> {
  return new Promise((resolve, reject) => {
    const url = window.URL.createObjectURL(videoFile);
    const video = document.createElement('video');

    video.onerror = reject;
    video.oncanplay = (): void => {
      video.oncanplay = null;
      video.currentTime = 0.01;
    };
    video.onseeked = (): void => {
      const { videoWidth, videoHeight } = video;
      if (videoWidth === 0 || videoHeight === 0) {
        reject();
        return;
      }
      let sizeQ = 1;
      if (maxWidth && maxHeight) {
        sizeQ = Math.max(videoWidth / maxWidth, videoHeight / maxHeight, 1);
      }
      const endWidth = videoWidth / sizeQ;
      const endHeight = videoHeight / sizeQ;
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      canvas.width = endWidth;
      canvas.height = endHeight;

      ctx?.drawImage(video, 0, 0, endWidth, endHeight);
      resolve({
        thumbnail: canvas.toDataURL('image/jpeg', 0.8),
        width: endWidth,
        height: endHeight,
        duration: video.duration,
      });
      video.src = '';
      window.URL.revokeObjectURL(url);
    };
    video.src = url;
  });
}

export const previewBuilder = {
  getFilePreviewUrl,
  getFirstVideoFrameAndDuration,
};
