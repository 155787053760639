import { mapCounterResponse, mapGroupEvent } from 'APP/model/counter/counterModel.mapper';
import {
  IGroupCounterView,
  IGroupEventResponse,
  IGroupEventsRequestBatch,
} from 'APP/model/counter/counterModel.types';
import api from 'APP/packages/api';
import logger from 'APP/packages/logger';

import { buildModelError } from '../error/errorBuilder';

export async function getGroupsCounters(groupIds: string[]): Promise<IGroupCounterView[]> {
  try {
    const counters = await api.group.getGroupsCounters({ groupIds });
    return counters.map(mapCounterResponse);
  } catch (error) {
    logger.get('API').error('group.getGroupsCounters', error);
    throw buildModelError(error);
  }
}

export async function getGroupCountersPage(
  counterTs: number | null,
  limit = 20
): Promise<{ items: IGroupCounterView[]; endPageTs: number | null }> {
  const data = counterTs !== null ? { counterTs: counterTs.toString(), limit } : { limit };
  try {
    const { items, endPageTs } = await api.group.getGroupsPage(data);
    return {
      items: items.map(mapCounterResponse),
      endPageTs: endPageTs ? Number(endPageTs) : null,
    };
  } catch (error) {
    logger.get('API').error('group.getGroupsPage', error);
    throw buildModelError(error);
  }
}

export async function getGroupsEvents(
  data: IGroupEventsRequestBatch[]
): Promise<IGroupEventResponse[]> {
  if (!data.length) {
    return [];
  }
  try {
    const { responseData = [] } = await api.group.getGroupsEvents({ requestData: data });

    return responseData.map(({ events, eventTs, groupId }) => ({
      events: events.map(mapGroupEvent).filter((e) => e?.type),
      groupId,
      eventTs,
    }));
  } catch (error) {
    logger.get('API').error('group.getGroupsEvents', error);
    throw buildModelError(error);
  }
}
