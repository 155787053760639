import { action, computed, observable } from 'mobx';
import {
  ConnectionEvent,
  OpenVidu,
  Publisher as OpenviduPublisher,
  Session,
  StreamEvent,
  StreamManagerEvent,
  StreamPropertyChangedEvent,
} from 'openvidu-browser';

import { CallBlurType } from 'APP/constants/callBackground';
import { ClientRole } from 'APP/constants/calls';
import { OpenviduProviderPublisher } from 'APP/packages/callProviders/OpenviduProvider/OpenviduProviderPublisher';
import { OpenviduScreenShareProvider } from 'APP/packages/callProviders/OpenviduProvider/OpenviduScreenShareProvider';
import {
  ICallProviderFacade,
  ICallProviderJoinParams,
  IProviderClientHandlers,
  IProviderClientParams,
  ProviderUid,
} from 'APP/packages/callProviders/callProviders.types';
import logger from 'APP/packages/logger';

import { OpenviduProviderOpponent } from './OpenviduProviderOpponent';

const OPENVIDU_MAX_VOLUME_VALUE = 100;

export class OpenviduProvider implements ICallProviderFacade {
  canUseBlur = false;
  needToGetScreenSharingToken = false;
  private shareScreenClient: OpenviduScreenShareProvider | null = null;
  private OV: OpenVidu;
  @observable private session: Session | null;
  private subscribers = new Map<ProviderUid, OpenviduProviderOpponent>();
  private uid: ProviderUid;
  private screenUid: ProviderUid;
  private channelId: string;
  private eventHandlers: Partial<IProviderClientHandlers> = {};
  private isPublished = false;
  private isMediaProcessing = false;
  openviduPublisher: OpenviduPublisher | null;
  @observable hasAudio: boolean;
  @observable hasVideo: boolean;
  @observable isLocalTrackResetting = false;
  @observable blurType = CallBlurType.None;
  @observable publisher = new OpenviduProviderPublisher(this);
  @observable publisherVolumeLevel = 0;
  @observable role = ClientRole.Audience;
  isJoined = false;

  constructor() {
    // initialize openvidu
    this.OV = new OpenVidu();
  }

  // initialize all handlers and session
  async createClient(params: IProviderClientParams): Promise<void> {
    if (!this.session) {
      this.session = this.OV.initSession();
    }

    this.eventHandlers.onUserJoined = params.handlers?.onUserJoined;
    this.eventHandlers.onUserLeft = params.handlers?.onUserLeft;
    this.eventHandlers.onLeave = params.handlers?.onLeave;
    this.eventHandlers.onJoin = params.handlers?.onJoin;

    this.session.on('connectionCreated', this.onConnectionCreated.bind(this));
    this.session.on('streamCreated', this.handleUserJoined.bind(this));
    this.session.on('streamDestroyed', this.handleUserLeft.bind(this));

    this.session.on('exception', (exception) => {
      logger.get('CallProviders').warn('openvidu', exception);
    });
  }

  // check our connection and send Join event to our SSE
  private onConnectionCreated(event: ConnectionEvent): void {
    const connectionId = event.connection.connectionId;
    if (connectionId === this.uid && this.channelId && this.eventHandlers.onJoin) {
      this.eventHandlers.onJoin({ channelId: this.channelId });
    }
    this.isJoined = true;
  }

  // subscribe to remote user
  private async handleUserJoined(event: StreamEvent): Promise<void> {
    if (!this.session) {
      return;
    }
    const openviduSubscriber = await this.session.subscribeAsync(event.stream, '');
    const subscriberId = event.stream.connection.connectionId;

    if (this.eventHandlers.onUserJoined) {
      // after subscribing to user we create our facade class for system with openwidu subscriber
      const subscriber = new OpenviduProviderOpponent({
        subscriber: openviduSubscriber,
        subscriberId,
      });
      // set it to our private map
      this.subscribers.set(subscriberId, subscriber);
      // and send to system
      this.eventHandlers.onUserJoined(subscriber);
    }
  }

  // if user left we remove it from our private store
  private handleUserLeft(event: StreamEvent): void {
    const connectionId = event.stream.connection.connectionId;
    if (this.eventHandlers.onUserLeft) {
      this.eventHandlers.onUserLeft(connectionId);
    }
    this.subscribers.delete(connectionId);
  }

  async leave(): Promise<void> {
    if (!this.session) {
      return;
    }

    this.session.disconnect();
    this.session = null;
    if (this.eventHandlers.onLeave) {
      this.eventHandlers.onLeave({ channelId: this.channelId });
    }
  }

  async join(params: ICallProviderJoinParams): Promise<void> {
    if (!this.session) return;

    const { token, uid, screenUid, channelId } = params;

    try {
      this.uid = uid;
      if (screenUid) {
        this.screenUid = screenUid;
      }
      this.channelId = channelId;
      await this.session.connect(token);
      this.isJoined = true;
    } catch (e) {
      logger.get('CallProviders').error('openvidu.join', e);
    }
  }

  @action
  private async initPublisher({
    hasVideo,
    hasAudio,
  }: {
    hasVideo: boolean;
    hasAudio: boolean;
  }): Promise<void> {
    const publisher = await this.OV.initPublisherAsync(undefined, {
      audioSource: undefined, // The source of audio. If undefined default microphone, if false - not initialise
      videoSource: hasVideo ? undefined : false, // The source of video. If undefined default webcam, if false - not initialise
      publishAudio: hasAudio, // muted or unmuted audio
      publishVideo: hasVideo, // muted and unmuted video
      resolution: '640x480', // The resolution of your video
      frameRate: 30, // The frame rate of your video
      mirror: true, // Whether to mirror your local video or not
    });

    this.openviduPublisher = publisher;
    this.hasAudio = publisher.stream.audioActive;
    this.hasVideo = publisher.stream.videoActive;
    this.openviduPublisher.on('streamPropertyChanged', this.handlePublisherChanged.bind(this));
    this.openviduPublisher.on(
      'streamAudioVolumeChange',
      this.handlePublisherVolumeChanged.bind(this)
    );
  }

  // if publisher mute/unmute video/audio we set media
  @action
  async handlePublisherChanged(event: StreamPropertyChangedEvent): Promise<void> {
    if (event.reason === 'publishAudio') {
      this.hasAudio = event.stream.audioActive;
    } else if (event.reason === 'publishVideo') {
      this.hasVideo = event.stream.videoActive;
    }
  }

  @action
  handlePublisherVolumeChanged(event: StreamManagerEvent): void {
    if (this.hasAudio && event.value && 'newValue' in event.value) {
      const newValue = event.value.newValue as number;
      // Openvidu sends volume value from -100 to 0;
      // so that we use it value to convert level from 0 to 100;
      const newValueWithThreshold =
        newValue < -OPENVIDU_MAX_VOLUME_VALUE ? -OPENVIDU_MAX_VOLUME_VALUE : newValue;
      this.publisherVolumeLevel = OPENVIDU_MAX_VOLUME_VALUE + newValueWithThreshold;
    }
  }

  // method for streaming
  async setSomeUserTypeStream(uid: ProviderUid, type: number): Promise<void> {
    // ToDO: [calls] realize!!!!;
  }

  @action
  async publish(): Promise<void> {
    if (this.openviduPublisher && !this.isPublished) {
      this.role = ClientRole.Host;
      await this.session?.publish(this.openviduPublisher);
      this.isPublished = true;
    }
  }

  // we mute our audio without unpublishing
  async muteAudio(): Promise<void> {
    this.openviduPublisher?.publishAudio(false);
    this.hasAudio = false;
  }

  // unmute audio
  async unmuteAudio(needToPublish?: boolean): Promise<void> {
    if (this.isMediaProcessing || !this.session) {
      return;
    }

    this.isMediaProcessing = true;

    // if provider wasn't initialised we do it
    if (!this.openviduPublisher) {
      await this.initPublisher({ hasVideo: this.hasVideo, hasAudio: true });
    }

    this.openviduPublisher?.publishAudio(true);
    if (needToPublish) {
      await this.publish();
    }
    this.hasAudio = true;
    this.isMediaProcessing = false;
  }

  // unpublish our media
  @action
  async unpublish(): Promise<void> {
    if (!this.openviduPublisher || !this.session) {
      return;
    }
    await this.session.unpublish(this.openviduPublisher);
    this.role = ClientRole.Audience;
    this.hasVideo = false;
    this.hasAudio = false;
    this.isPublished = false;
  }

  async changeMicrophone(): Promise<void> {
    return Promise.resolve();
  }

  @action
  async unmuteVideo(needToPublish?: boolean): Promise<void> {
    if (this.isMediaProcessing || !this.session) {
      return;
    }
    this.isMediaProcessing = true;
    if (!this.openviduPublisher) {
      await this.initPublisher({ hasVideo: true, hasAudio: this.hasAudio });
    } else {
      // we can't turn on the video if our publisher was initialised without video source
      // so that we reinitialise pur publisher
      if (!this.openviduPublisher.stream.hasVideo) {
        this.openviduPublisher.off('streamPropertyChanged');
        this.openviduPublisher.off('streamAudioVolumeChange');
        if (this.isPublished) {
          await this.session.unpublish(this.openviduPublisher);
          this.isPublished = false;
        }
        this.openviduPublisher = null;
        await this.initPublisher({ hasVideo: true, hasAudio: this.hasAudio });
      } else {
        this.openviduPublisher?.publishVideo(true);
      }
    }
    if (needToPublish) {
      await this.publish();
    }
    this.hasVideo = true;
    this.isMediaProcessing = false;
  }

  @action
  async muteVideo(): Promise<void> {
    if (this.isMediaProcessing) {
      return;
    }
    this.isMediaProcessing = true;
    await this.openviduPublisher?.publishVideo(false, true);
    this.hasVideo = false;
    this.isMediaProcessing = false;
  }

  // ToDO: [calls] rewrite part of publishing for streams
  async startScreenShare(params: {
    needToPublish?: boolean;
    token: string;
    uid: ProviderUid;
    channelId: string;
    onStopSharingScreen?: () => void;
    track?: MediaStreamTrack;
  }): Promise<boolean> {
    if (!this.screenUid) {
      return false;
    }

    const shareScreenClient = new OpenviduScreenShareProvider();

    await shareScreenClient.join({
      token: params.token,
      uid: params.uid,
      channelId: params.channelId,
    });

    if (!this.needToGetScreenSharingToken) {
      this.needToGetScreenSharingToken = true;
    }

    const onEnded = async (): Promise<void> => {
      await this.stopScreenShare();
      if (params.onStopSharingScreen) {
        params.onStopSharingScreen();
      }
    };

    this.shareScreenClient = shareScreenClient;

    return await shareScreenClient.startScreenSharing({
      track: params.track,
      needToPublish: params.needToPublish,
      onEnded,
    });
  }

  @action
  async stopScreenShare(): Promise<void> {
    if (this.shareScreenClient) {
      await this.shareScreenClient.stopScreenShare();
    }
  }

  setBlur(blurType: CallBlurType): void {
    // ToDO: [calls] realize for streams
  }

  @computed
  get isAudience(): boolean {
    return this.role === 'audience';
  }

  async dispose(): Promise<boolean> {
    if (!this.session) {
      return false;
    }

    this.session.off('connectionCreated');
    this.session.off('streamCreated');
    this.session.off('connectionDestroyed');
    this.session.off('streamDestroyed');

    this.session.off('exception');

    if (this.shareScreenClient) {
      await this.shareScreenClient.stopScreenShare();
      this.shareScreenClient = null;
    }

    if (this.openviduPublisher) {
      await this.session.unpublish(this.openviduPublisher);
      this.openviduPublisher.off('streamPropertyChanged');
      this.openviduPublisher.off('streamAudioVolumeChange');
      this.openviduPublisher = null;
    }
    await this.leave();

    return true;
  }
}
