import { observer } from 'mobx-react';
import React, { FC } from 'react';

import date from 'APP/packages/date';
import SendIcon from 'ICONS/ic-send.svg';
import { FAB } from 'UIKIT/FAB/FAB';

import { ScheduleOnboarding } from './ScheduleOnboarding/ScheduleOnboarding';
import { useSubmitButton } from './SubmitButton.presenter';
import styles from './SubmitButton.styles.m.css';
import { SubmitButtonMenu } from './SubmitButtonMenu/SubmitButtonMenu';

interface ISubmitButtonProps {
  isSlowMode: boolean;
  remainingSecond: number;
}

export const SubmitButton: FC<ISubmitButtonProps> = observer((props) => {
  const { isSlowMode, remainingSecond } = props;
  const { presenter, submitButtonRef } = useSubmitButton();

  return (
    <div>
      {isSlowMode ? (
        <button
          className={styles.timer}
          onContextMenu={presenter.onToggleMenu}
          ref={submitButtonRef}
        >
          {date.secondsToString(remainingSecond)}
        </button>
      ) : (
        <FAB
          size={'30'}
          onClick={presenter.onSubmit}
          Icon={SendIcon}
          theme={'17'}
          isDisabled={presenter.isDisabled}
          onContextMenu={presenter.onToggleMenu}
          setRef={submitButtonRef}
          dataTest="send-message-button"
        />
      )}
      {presenter.isShowMenu && (
        <SubmitButtonMenu
          reference={submitButtonRef}
          onClose={presenter.onCloseMenu}
        />
      )}

      {presenter.isShowOnboarding && <ScheduleOnboarding reference={submitButtonRef} />}
    </div>
  );
});
