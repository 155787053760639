import Entities from 'STORE';
import { Group } from 'STORE/Groups/Group';
import { Space } from 'STORE/Spaces/Space';
import { User } from 'STORE/Users/User/User';

import { checkSearchGroup } from './checkSeachGroup';
import { checkSearchSpace } from './checkSearchSpace';
import { checkSearchUser } from './checkSearchUser';

const LOCAL_MIN_STRING_LENGTH = 1;

interface ILocalSearchResults {
  groups: Group[];
  spaces: Space[];
  users: User[];
}

function sortGroups(currentGroup: Group, nextGroup: Group): number {
  if (currentGroup.isSavedMessages || nextGroup.isSavedMessages) {
    return +nextGroup.isSavedMessages - +currentGroup.isSavedMessages;
  }

  return nextGroup.lastMessageTs - currentGroup.lastMessageTs;
}

function sortUsers(currentUser: User, nextUser: User): number {
  return currentUser.avatarTitle.localeCompare(nextUser.avatarTitle);
}

function sortSpaces(currentSpace: Space, nextSpace: Space): number {
  return currentSpace.name.localeCompare(nextSpace.name);
}

export function getLocalSearchResults(searchText: string): ILocalSearchResults {
  if (searchText.length < LOCAL_MIN_STRING_LENGTH) {
    return {
      groups: [],
      spaces: [],
      users: [],
    };
  }

  const search = searchText.toLowerCase();
  const groups = Entities.GroupsStore.groups
    .filter((group) => checkSearchGroup(group, search))
    .sort(sortGroups);

  const spaces = Entities.spacesStore.spaces
    .filter((space) => checkSearchSpace(space, searchText))
    .sort(sortSpaces);

  const users = Entities.UsersStore.users
    .filter((user) =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore // TODO: remove when User store refactored on TypeScript
      checkSearchUser(user, search, groups)
    )
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore // TODO: remove when User store refactored on TypeScript
    .sort(sortUsers);

  return {
    groups,
    spaces,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore // TODO: remove when User store refactored on TypeScript
    users,
  };
}
