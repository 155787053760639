import { computed } from 'mobx';

class Mention {
  constructor(root, parent) {
    this.root = root;
    this.parent = parent;
  }

  @computed
  get isAvailable() {
    return this.parent.inputPanel.mention.isAvailable;
  }

  @computed
  get filter() {
    return this.parent.inputPanel.mention.filter;
  }
}

export default Mention;
