import { action, observable } from 'mobx';

import { IMarket } from 'APP/model/market/marketModel.types';

export class Marketplace {
  @observable isAvailable: boolean;
  marketHosts: string[] = [];
  authToken: string;
  baseAdBoardUrl: string;
  baseAdsUrl: string;
  baseFreelanceUrl: string;
  baseGamesUrl: string;
  baseMarketUrl: string;
  baseMarketingUrl: string;
  basePassportUrl: string;
  authHosts: string[] = [];

  @action
  setAvailable(value: boolean): void {
    this.isAvailable = value;
  }

  setMarketHosts(hosts: string[]): void {
    this.marketHosts = hosts;
  }

  get marketConfig(): IMarket {
    return {
      authToken: this.authToken,
      baseAdBoardUrl: this.baseAdBoardUrl,
      baseAdsUrl: this.baseAdsUrl,
      baseFreelanceUrl: this.baseFreelanceUrl,
      baseGamesUrl: this.baseGamesUrl,
      baseMarketUrl: this.baseMarketUrl,
      baseMarketingUrl: this.baseMarketingUrl,
      basePassportUrl: this.basePassportUrl,
      authHosts: this.authHosts,
    };
  }

  setData(data: IMarket): void {
    this.authToken = data.authToken;
    this.baseAdBoardUrl = data.baseAdBoardUrl;
    this.baseAdsUrl = data.baseAdsUrl;
    this.baseFreelanceUrl = data.baseFreelanceUrl;
    this.baseGamesUrl = data.baseGamesUrl;
    this.baseMarketUrl = data.baseMarketUrl;
    this.baseMarketingUrl = data.baseMarketingUrl;
    this.basePassportUrl = data.basePassportUrl;
    this.authHosts = data.authHosts || [];
  }

  reset(): void {
    this.authToken = '';
    this.baseAdBoardUrl = '';
    this.baseAdsUrl = '';
    this.baseFreelanceUrl = '';
    this.baseGamesUrl = '';
    this.baseMarketUrl = '';
    this.baseMarketingUrl = '';
    this.basePassportUrl = '';
    this.authHosts = [];
  }
}
