import { observer } from 'mobx-react';
import React, { FC } from 'react';

import Tasks from 'APP/Tasks';
import { MediaPreview } from 'APP/components/MediaPreview/MediaPreview';
import { useFileDownloadStatePresenter } from 'APP/hooks/useFileDownloadState/useFileDownloadState';
import ErrorIcon from 'ICONS/ic-download-error.svg';
import Entities from 'STORE';
import Image from 'STORE/Messages/Message/Payload/Image';
import Video from 'STORE/Messages/Message/Payload/Video';

import styles from './AllDownloadsMediaItemIcon.styles.m.css';

interface IAllDownloadsMediaItemIconProps {
  payloadId: string;
  groupId: string;
  messageId: string;
}

export const AllDownloadsMediaItemIcon: FC<IAllDownloadsMediaItemIconProps> = observer((props) => {
  const { payloadId, groupId, messageId } = props;
  const { payload, hasError } = useFileDownloadStatePresenter({
    payloadId,
    groupId,
    messageId,
  });

  return (
    <div className={styles.container}>
      <MediaPreview
        key={`all-downloads-media-item-${Entities.App.isOfflineNetworkStatus}`}
        className={styles.mediaPreview}
        downloadStateClassName={styles.downloadState}
        payload={payload as Image | Video}
        maxWidth={40}
        maxHeight={40}
        needCropImage={true}
        onClick={() =>
          Tasks.group.openMediaGallery({
            groupId,
            mediaId: payloadId,
            messageId,
          })
        }
        payloadId={payloadId}
        groupId={groupId}
        messageId={messageId}
        loadIconSize="0"
      />
      {hasError && (
        <>
          <ErrorIcon className={styles.errorIcon} />
          <div className={styles.errorIconBorder} />
        </>
      )}
    </div>
  );
});
