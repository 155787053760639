import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { SystemMessageLayout } from '../../SystemMessageLayout/SystemMessageLayout';
import { useSystemPresenter } from './System.presenter';
import styles from './System.styles.m.css';
import { SystemMessage } from './SystemMessage/SystemMessage';

export const System = observer(({ message, messageViewPageType }) => {
  const { presenter, systemMessageText } = useSystemPresenter(message);

  if (!systemMessageText) {
    return null;
  }

  if (presenter.renderItems) {
    return (
      <SystemMessageLayout
        message={message}
        className={classNames({
          [styles.alignedLeft]: presenter.isLeftAligned,
        })}
        messageViewPageType={messageViewPageType}
      >
        <span className={styles.text}>
          <SystemMessage
            message={message}
            renderItems={presenter.renderItems}
          />
        </span>
      </SystemMessageLayout>
    );
  }

  return (
    <SystemMessageLayout message={message} messageViewPageType={messageViewPageType}>
      <span
        className={styles.text}
        dangerouslySetInnerHTML={{ __html: systemMessageText }}
      />
    </SystemMessageLayout>
  );
});
