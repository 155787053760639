import { useAsObservableSource, useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import api from 'APP/packages/api';
import logger from 'APP/packages/logger';
import { useTranslation } from 'APP/packages/translations';
import Entities from 'APP/store';
import { Group } from 'STORE/Groups/Group';
import type { User } from 'STORE/Users/User/User';
import { IPopoverMenuItem } from 'UIKIT/PopoverMenu/PopoverMenu.types';

export interface IBlackListMenuPresenterParams {
  user: User | null;
  toggleMenu(ref: HTMLElement | null, user: User): void;
}

interface IBlackListMenuPresenter {
  group: Group | null;
  isOpened: boolean;
  menuItems: IPopoverMenuItem[];
  closeMenu(): void;
  unbanUser(): Promise<void>;
}

export const useBlackListMenuPresenter = ({
  toggleMenu,
  user,
}: IBlackListMenuPresenterParams): IBlackListMenuPresenter => {
  const { t } = useTranslation();
  const source = useAsObservableSource({ user });

  const presenter = useLocalStore<IBlackListMenuPresenter>(() => ({
    get group(): Group | null {
      return Entities.GroupsStore.activeGroup;
    },

    closeMenu(): void {
      toggleMenu(null, source.user!);
    },

    get isOpened(): boolean {
      return !!source.user;
    },

    get menuItems(): IPopoverMenuItem[] {
      return [
        {
          key: 'unban',
          title: t('unban_user'),
          onClick: () => presenter.unbanUser(),
        },
      ];
    },

    async unbanUser(): Promise<void> {
      const user = source.user;

      if (!presenter.group || !user) {
        return;
      }

      const canUnban = await Tasks.app.showConfirm({
        text: t('unban_user_alert', { 0: user.displayName }),
      });

      if (canUnban) {
        try {
          await api.group.unbanUsersForGroup({
            groupId: presenter.group!.id,
            usersToUnban: [user.id],
          });
        } catch (error) {
          logger.get('API').error('unbanUsersForGroup', error);
        }
      }

      presenter.closeMenu();
    },
  }));

  return presenter;
};
