import Tasks from 'APP/Tasks';
import { AuthProvider } from 'APP/model/auth/authModel.types';
import { authModel } from 'APP/model/model';
import { platformInfo } from 'APP/packages/platformInfo/platformInfo';
import Entities from 'APP/store';
import { getUserLanguage } from 'UTILS/getUserLanguage';

export const authWithTelegram = async (
  token: string
): Promise<{ success: boolean; error: string | null }> => {
  try {
    const { registrationKey } = await authModel.authWithSocialNetwork({
      token,
      authProviderId: AuthProvider.Telegram,
      deviceInfo: {
        platform: platformInfo.platform,
        appVersion: Entities.App.version,
        locale: getUserLanguage(),
        device: platformInfo.device,
        osVersion: platformInfo.osVersion,
      },
    });

    const { isSuccess, error } = await Tasks.authorization.login(registrationKey);

    if (isSuccess) {
      Entities.AuthStore.setConnectionToken(registrationKey);
      return { success: true, error: null };
    } else {
      return { success: false, error: error?.message || null };
    }
  } catch (error) {
    return { success: false, error: error.message };
  }
};
