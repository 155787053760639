import { observer } from 'mobx-react';
import React from 'react';

import { GroupAvatar } from 'APP/components/Avatar/GroupAvatar/GroupAvatar';
import { useTranslation } from 'APP/packages/translations';

import OfflineStatus from '../components/OfflineStatus';
import styles from './styles.m.scss';

const BotChatInfo = ({ group }) => {
  const { t } = useTranslation();

  return (
    <>
      <GroupAvatar group={group} />
      <div className={styles.content}>
        <span className={styles.title}>{group.avatarTitle}</span>
        <OfflineStatus>
          <span className={styles.bot}>{t('common_bot_label')}</span>
        </OfflineStatus>
      </div>
    </>
  );
};

export default observer(BotChatInfo);
