import { useLocalStore } from 'mobx-react';

import Entities from 'STORE';

export const usePresenter = () => {
  const presenter = useLocalStore(() => ({
    get ongoingCall() {
      return Entities.Calls.ongoingCall;
    },

    get customPermissions() {
      return presenter.ongoingCall?.permissions?.customPermissions;
    },

    get customPermissionsIds() {
      return presenter.ongoingCall?.permissions.customPermissionsIds;
    },
  }));

  return presenter;
};
