import { BotButtonPayloadType } from 'APP/model/bot/botModel.constants';
import { ICommandPayload } from 'APP/model/bot/botModel.types';

export class CommandButtonPayload {
  type: BotButtonPayloadType;
  botId: string;
  id: string;

  constructor(data: ICommandPayload) {
    this.type = data.type;
    this.botId = data.botId;
    this.id = data.id;
  }

  toJSON(): ICommandPayload {
    return {
      type: this.type,
      botId: this.botId,
      id: this.id,
    };
  }
}
