const EVENT_NAME = 'messages.user-typing';

export default (connection) => ({
  send: ({ groupId }) => {
    try {
      return connection.send({
        type: EVENT_NAME,
        groupId,
      });
    } catch (error) {
      // TODO: Make queue for messages we can't send
      console.error(error);
    }
  },
  listen: (callback) => {
    return connection.listen(EVENT_NAME, callback);
  },
});
