import time from '../fields/time';
import syncValidation from '../hooks/syncValidation';

export default () => {
  const timeValidator = syncValidation();

  return {
    time: {
      validate: (value, formData, meta, data) => {
        return timeValidator({
          value,
          meta,
          data,
          validate: time.validate,
        });
      },
    },
  };
};
