import React, { FC } from 'react';

import styles from './InfiniteUploadIcon.styles.m.css';

interface IInfiniteUploadIcon {
  onClick: React.ReactEventHandler<SVGAElement>;
  Icon: FC<{ className: string; onClick: React.ReactEventHandler<SVGAElement> }>;
}

export const InfiniteUploadIcon: FC<IInfiniteUploadIcon> = (props) => {
  const { onClick, Icon } = props;

  return (
    <Icon
      className={styles.icon}
      onClick={onClick}
    />
  );
};
