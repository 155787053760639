import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { CallState } from 'MAIN/OngoingCall/Shared/CallState/CallState';
import { Call } from 'STORE/Calls/Call/Call';

import styles from './CallingPlaceholder.styles.m.css';

interface ICallingPlaceholderProps {
  call: Call;
}

export const CallingPlaceholder: FC<ICallingPlaceholderProps> = observer(({ call }) => {
  if (!call) {
    return null;
  }

  return (
    <div className={styles.callingPlaceholderContainer}>
      <div className={styles.groupTitle}>{call.avatar.title}</div>
      <div className={styles.callingState}>
        <CallState call={call} />
      </div>
    </div>
  );
});
