import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, ReactElement } from 'react';

import { IGroupMember } from 'APP/Tasks/group/participants/getParticipants';
import { List } from 'APP/components/List/List';
import { IconView } from 'APP/packages/UiKit/IconView/IconView';
import { InfoBlock, InfoBlockTypes } from 'APP/packages/UiKit/InfoBlock/InfoBlock';
import { MenuItem } from 'APP/packages/UiKit/MenuItem/MenuItem';
import { MenuItemStyle } from 'APP/packages/UiKit/MenuItem/MenuItem.types';
import { GroupsPlaceholder } from 'APP/packages/placeholders/GroupsPlaceholder/GroupsPlaceholder';
import { useTranslation } from 'APP/packages/translations';
import { Group } from 'APP/store/Groups/Group';
import AddUserIcon from 'ICONS/ic-add-user.svg';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';

import { ParticipantListItem } from '../ParticipantListItem/ParticipantListItem';
import { useParticipantListPresenter } from './ParticipantList.presenter';
import styles from './ParticipantList.styles.m.css';

interface IParticipantListProps {
  group: Group | null;
  searchText?: string;
  onClosePopup(): void;
  onAddParticipantClick(onSuccess: () => void): void;
}

export const ParticipantList: FC<IParticipantListProps> = observer((props) => {
  const { group, searchText, onClosePopup, onAddParticipantClick } = props;

  const { t } = useTranslation();
  const presenter = useParticipantListPresenter({ group, searchText, onAddParticipantClick });

  return (
    <div className={classNames(styles.container, customScrollStyles.container)}>
      {presenter.canAddParticipants && (
        <MenuItem
          className={styles.button}
          titleClassName={styles.buttonTitle}
          title={t('group_chat_add_people_button')}
          icon={
            <IconView
              Icon={AddUserIcon}
              className={styles.listIcon}
            />
          }
          style={MenuItemStyle.Danger}
          onClick={presenter.showAddPeople}
        />
      )}
      <List
        isLoading={presenter.isLoading}
        hasMore={presenter.hasMore}
        data={presenter.participants}
        onEndReached={presenter.loadMore}
        keyExtractor={(participant: IGroupMember): string => participant.user.id}
        renderItem={(participant: IGroupMember): ReactElement => (
          <ParticipantListItem
            participant={participant}
            group={group}
            onClosePopup={onClosePopup}
            onReloadParticipantsList={presenter.onReloadParticipantsList}
            onParticipantRemove={presenter.removeParticipant}
            onUpdateParticipantRole={presenter.updateParticipantRole}
          />
        )}
        ListEmptyComponent={
          <InfoBlock
            descriptions={[t('no_results_found')]}
            type={InfoBlockTypes.Modal}
          />
        }
        SpinnerComponent={
          <GroupsPlaceholder
            className={styles.placeholders}
            count={presenter.participants.length > 0 ? 2 : 10}
          />
        }
      />
    </div>
  );
});
