export enum TranscriptionStatus {
  NotTranscribed = 'NOT_TRANSCRIBED',
  InProgress = 'IN_PROGRESS',
  TranscriptionSuccessful = 'TRANSCRIPTION_SUCCESSFUL',
  TranscriptionFailed = 'TRANSCRIPTION_FAILED',
}

export const MENTION_ALL_NICKNAME = 'all';

export const MEDIA_PREVIEW_MAX_SIZE = { width: 440, height: 560 };
export const ARTICLE_PREVIEW_MAX_SIZE = { width: 440, height: 324 };
export const LINK_PREVIEW_MAX_SIZE = { width: 406, height: 256 };
export const MINIATURE_PREVIEW_MAX_SIZE = { width: 40, height: 40 };
export const CIRCLE_VIDEO_PREVIEW_MAX_SIZE = { width: 340, height: 340 };
