import { openStream } from 'APP/packages/streamApi/stream/stream';

const ROOT_API = 'threads';

export function initThreadListener({ threadId, handleBatchedCounters, onChangeCounter, onError }) {
  return openStream(
    {
      rootApi: `${ROOT_API}/${threadId}`,
    },
    [
      // counters
      { eventName: 'groups.counters.batched', onData: handleBatchedCounters },
      { eventName: 'groups.counters.group-changed', onData: onChangeCounter },
    ],
    onError
  );
}
