import { observer } from 'mobx-react';
import React from 'react';

import { BotProfile } from 'MAIN/Profile/BotProfile/BotProfile';
import { ChannelProfile } from 'MAIN/Profile/ChannelProfile/ChannelProfile';
import { GroupProfile } from 'MAIN/Profile/GroupProfile/GroupProfile';
import SavedMessagesProfile from 'MAIN/Profile/SavedMessagesProfile';
import { UserProfile } from 'MAIN/Profile/UserProfile/UserProfile';

import usePresenter from './presenter';

const Profile = ({ className, userId, groupId, actions, ...rest }) => {
  const presenter = usePresenter({ groupId });

  let Component;
  let props = {};

  if (userId) {
    Component = UserProfile;
    props = {
      actions,
      groupId,
      userId,
      hasActions: false,
      ...rest,
    };
  } else if (!presenter.group) {
    Component = null;
  } else if (presenter.group.isChannel) {
    Component = ChannelProfile;
    props = {
      actions,
      groupId: presenter.group.id,
      ...rest,
    };
  } else if (presenter.group.isP2P && !presenter.group.isBot) {
    Component = UserProfile;
    props = {
      actions,
      userId: presenter.group.opponentId,
      groupId: presenter.group.id,
      hasActions: false,
      ...rest,
    };
  } else if (presenter.group.isP2P && presenter.group.isBot) {
    Component = BotProfile;
    props = {
      actions,
      groupId: presenter.group.id,
      ...rest,
    };
  } else if (presenter.group.isChatGroup) {
    Component = GroupProfile;
    props = {
      actions,
      groupId: presenter.group.id,
      ...rest,
    };
  } else if (presenter.group.isSavedMessages) {
    Component = SavedMessagesProfile;
    props = {
      actions,
      groupId: presenter.group.id,
      ...rest,
    };
  }

  if (!Component) {
    return null;
  }

  // Todo Performance tweaks
  // When group has been changed the profile component unmounting and mount again, not a rerender

  return (
    <Component
      className={className}
      {...props}
    />
  );
};

export default observer(Profile);
