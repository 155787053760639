import Tasks from 'APP/Tasks';
import checkVoiceRecording from 'APP/Tasks/messaging/checkVoiceRecording';
import { IStartEditMessage } from 'APP/Tasks/messaging/startEditMessage/startEditMessage.types';
import { PayloadType } from 'APP/model/message/messageModel.types';
import { parseToHtml } from 'APP/packages/markdown/parseToHtml';
import { RouterLink } from 'APP/router/constants';
import { navigateTo } from 'APP/router/refs/navigateTo';
import Entities from 'APP/store';
import { MediaMessagePopup } from 'MAIN/PopupManager/Popups/MediaMessagePopup/MediaMessagePopup';
import { encodeHtml } from 'UTILS/html/encodeHtml';

const MEDIA_PAYLOADS: (PayloadType | null)[] = [
  PayloadType.Video,
  PayloadType.Image,
  PayloadType.Album,
  PayloadType.File,
  PayloadType.AudioMessage,
];

export const startEditMessage = async ({
  groupId,
  editMessageId,
}: IStartEditMessage): Promise<void> => {
  const inputPanel = Entities.InputPanelsStore.getById(groupId);
  const group = Entities.GroupsStore.getGroupById(groupId);

  if (!group || !inputPanel) {
    return;
  }

  const isVoiceRecordingResult = await checkVoiceRecording();

  if (!isVoiceRecordingResult) {
    return;
  }

  const message = group.messagesStore.getMessageById(editMessageId);

  if (!message) {
    return;
  }

  if (message.payload.payloadType === PayloadType.Article) {
    navigateTo({
      to: RouterLink.articleEdit,
      params: { id: message.groupId, messageId: message.id },
    });

    return;
  }

  if (MEDIA_PAYLOADS.includes(message.payload.payloadType)) {
    Tasks.app.openPopup(MediaMessagePopup, {
      params: {
        files: [],
        caption: message.renderPayload.comment,
        groupId: message.groupId,
        messageId: message.id,
      },
    });

    return;
  }

  if (inputPanel.quoteMessage) {
    inputPanel.setQuoteMessage(null);
  }

  inputPanel.setEditMessageId(editMessageId);

  const text = message.renderPayload.text || message.renderPayload.comment || '';
  const html = parseToHtml(encodeHtml(text));

  if (inputPanel.value !== html) {
    inputPanel.setValue(html);
  }
};
