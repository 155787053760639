import classNames from 'classnames';
import React, { FC } from 'react';

import { Loader } from 'UIKIT/Loader/Loader';

import styles from './CatalogLoader.styles.m.css';

interface ICatalogLoaderProps {
  className?: string;
}

export const CatalogLoader: FC<ICatalogLoaderProps> = ({ className }) => {
  return (
    <div className={classNames(styles.container, className)}>
      <Loader
        color="current"
        size="medium"
      />
    </div>
  );
};
