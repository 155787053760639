import Tasks from 'APP/Tasks';
import api from 'APP/packages/api';
import logger from 'APP/packages/logger';
import Entities from 'APP/store';

export const loadMessagesByIds = async ({ groupId, messagesIds = [] }) => {
  const group = Entities.GroupsStore.getGroupById(groupId);
  if (!group) {
    return [];
  }

  try {
    const result = await api.messaging.getMessages({ groupId, messagesIds });
    if (!result?.items) {
      return [];
    }

    Tasks.messaging.updateOldOrFakeMessages({ groupId, messages: result.items });

    const loadedMessages = [];
    messagesIds.forEach((id) => {
      const message = group.messagesStore.getMessageById(id);
      if (message) {
        loadedMessages.push(message);
      }
    });

    return loadedMessages;
  } catch (error) {
    logger.get('API').error('messaging.getMessages', error);
    return [];
  }
};
