import { CallProviderType } from 'APP/model/call/callModel.types';
import { callModel } from 'APP/model/model';

interface IJoinData {
  channelId: string;
  providerType: CallProviderType;
}

export const join = async ({ channelId, providerType }: IJoinData): Promise<void> => {
  try {
    await callModel.hasJoinedToCall(channelId, providerType);
  } catch (e) {
    console.error(e);
  }
};
