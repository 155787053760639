import Tasks from 'APP/Tasks';
import api, { setConfigApi } from 'APP/packages/api';
import { abortAllRequests } from 'APP/packages/api/request/request';
import logger from 'APP/packages/logger';
import { setSseConfig } from 'APP/packages/streamApi';
import { setWebsocketConfig } from 'APP/packages/websocket';
import Entities from 'APP/store';

async function request(): Promise<void> {
  try {
    await api.authorization.logout();
  } catch (error) {
    logger.get('API').error('authorization.logout', error);
  }
}

export async function logout(resetNotification = false): Promise<void> {
  if (Entities.pushNotifications.isEnabled && resetNotification) {
    await Tasks.pushNotifications.unsubscribeNotifications();
    await Tasks.pushNotifications.clearNotifications();
  }

  const ongoingCall = Entities.Calls.ongoingCall;
  if (ongoingCall?.channelId) {
    await Entities.Calls.delete(ongoingCall.groupId, ongoingCall.channelId);
  }

  Tasks.feedback.resetFeedbackStorage();
  Tasks.userSettings.resetRewardsStorage();

  abortAllRequests();

  await request();
  setConfigApi({ userSession: null });
  setWebsocketConfig({ token: null });
  setSseConfig({ token: null });
  Entities.reset();
}
