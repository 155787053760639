import { useLocalStore, useAsObservableSource } from 'mobx-react';
import { MouseEventHandler } from 'react';

import Tasks from 'APP/Tasks';
import { mapUserProfileBadgeToIcon } from 'APP/constants/userBadges';
import { UserProfileUsernameBadge } from 'APP/model/user/userModel.types';
import dateService from 'APP/packages/date';
import { IUserNameBadge } from 'APP/types/userNameBadges';
import type { User } from 'STORE/Users/User/User';
import { TAvatarBackgroundColorIndex } from 'UIKIT/Avatar/Avatar.types';

export interface IContactPresenter {
  user: User;
  isLoading: boolean;
  avatarTitle: string;
  avatarUrl: string;
  avatarBackgroundColorIndex: TAvatarBackgroundColorIndex;
  title: string;
  subtitle: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  userNameBadge: IUserNameBadge | null;
  verified: boolean;
}

export const useContactPresenter = (
  contactUser: User,
  closePopup: () => void
): IContactPresenter => {
  const source = useAsObservableSource({ contactUser, closePopup });

  const presenter = useLocalStore<IContactPresenter>(() => ({
    get user(): User {
      return source.contactUser;
    },

    get isLoading(): boolean {
      if (!presenter.user) {
        return false;
      }

      return presenter.user.isLoadingBlockedByMe;
    },

    get avatarTitle(): string {
      return presenter.user.avatarTitle;
    },

    get avatarUrl(): string {
      return presenter.user.avatarUrl || '';
    },

    get avatarBackgroundColorIndex(): TAvatarBackgroundColorIndex {
      return presenter.user.avatarColorIndex;
    },

    get title(): string {
      return presenter.user.displayName;
    },

    get userNameBadge(): IUserNameBadge | null {
      if (presenter.user?.usernameBadge) {
        return null;
      }

      return mapUserProfileBadgeToIcon[presenter.user.usernameBadge as UserProfileUsernameBadge];
    },

    get verified(): boolean {
      return presenter.user.verified;
    },

    get subtitle(): string {
      const lastOnlineTs = presenter.user.lastOnlineTs;
      const isToday = dateService.isToday(lastOnlineTs);
      const dateOptions = isToday
        ? { hour: 'numeric', minute: 'numeric' }
        : {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          };
      const formattedDate = dateService.format(lastOnlineTs, dateOptions);

      return `last seen at ${formattedDate ? formattedDate : ''}`;
    },

    get isDisabled(): boolean {
      return presenter.isLoading;
    },

    async onClick(): Promise<void> {
      await Tasks.group.createP2PGroup(presenter.user.id);
      source.closePopup();
    },
  }));

  return presenter;
};
