import { useLocalStore, useAsObservableSource } from 'mobx-react';
import { MouseEvent } from 'react';

import Tasks from 'APP/Tasks';
import dateService from 'APP/packages/date';
import { RouterState } from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import Entities from 'APP/store';
import { useUserProfile } from 'MAIN/hooks/userProfile/useUserProfile';
import { Group } from 'STORE/Groups/Group';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';
import { User } from 'STORE/Users/User/User';
import { TAvatarBackgroundColorIndex } from 'UIKIT/Avatar/Avatar.types';

import { TCloseHandler } from '../../MediaGallery.types';

interface IMediaGalleryInfoCardPresenterParams {
  message: ChatMessage;
  sender: User;
  canNavigate: boolean;
  onClose: TCloseHandler;
}

interface IMediaGalleryInfoCardPresenter {
  group: Group | null;
  avatarUrl: string;
  avatarBackgroundColorIndex: TAvatarBackgroundColorIndex;
  avatarTitle: string;
  subTitle: string;
  goToForwardMessage(): Promise<void>;
  onClick(): void;
  onSubTitleClick(event: MouseEvent<HTMLDivElement>): void;
}

export const useMediaGalleryInfoCardPresenter = (
  data: IMediaGalleryInfoCardPresenterParams
): IMediaGalleryInfoCardPresenter => {
  const { message, sender, canNavigate, onClose } = data;
  const navigateTo = useNavigateTo();
  const userProfile = useUserProfile(sender.id, {
    groupId: message.groupId,
    messageId: message.id,
  });

  const source = useAsObservableSource({ message, sender, canNavigate, onClose });

  const presenter = useLocalStore<IMediaGalleryInfoCardPresenter>(() => ({
    get group(): Group | null {
      return source.message.group;
    },

    get avatarUrl(): string {
      return source.message.avatarUrl;
    },

    get avatarBackgroundColorIndex(): TAvatarBackgroundColorIndex {
      return source.message.avatarColorIndex;
    },

    get avatarTitle(): string {
      return source.message.avatarTitle;
    },

    get subTitle(): string {
      return (
        dateService.format(parseInt(source.message.serverTime, 10), {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: 'numeric',
          minute: 'numeric',
        }) || ''
      );
    },

    async goToForwardMessage(): Promise<void> {
      await Tasks.group.setActiveGroup({
        groupId: source.message.forward?.groupId,
        state: { [RouterState.hasGoBack]: true },
      });

      Tasks.messaging.focusMessage({
        groupId: source.message.forward?.groupId,
        messageId: source.message.forward?.messageId,
      });
    },

    onClick(): void {
      if (!source.canNavigate) {
        return;
      }

      source.onClose();

      if (source.message.isForwardToSavedMessages && source.message.forward?.isFromChannel) {
        presenter.goToForwardMessage();
        return;
      }

      if (presenter.group?.isChannel) {
        Entities.ChatStore.showSidebar();
      } else {
        userProfile.show();
      }
    },

    onSubTitleClick(event: MouseEvent<HTMLDivElement>): void {
      if (!source.canNavigate) {
        return;
      }

      event.stopPropagation();

      if (source.message.isForwardToSavedMessages && source.message.forward?.isFromChannel) {
        presenter.goToForwardMessage();
        return;
      }

      navigateTo({ to: `/${presenter.group?.route}/${presenter.group?.id}` });
      Tasks.messaging.focusMessage({
        groupId: source.message.groupId,
        messageId: source.message.id,
      });
    },
  }));

  return presenter;
};
