import { useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { GroupType } from 'APP/model/group/groupModel.types';
import { Popup } from 'STORE/App/Popups/Popup/Popup';

import { IChannelFormData } from '../../components/ChannelForm/ChannelForm.types';

interface ICreateChannelPresenter {
  initialValues: Partial<IChannelFormData>;
  onSubmit(channelData: IChannelFormData): Promise<void>;
}

export function useCreateChannelPresenter(popupInstance: Popup): ICreateChannelPresenter {
  const presenter = useLocalStore<ICreateChannelPresenter>(() => ({
    initialValues: {
      type: GroupType.ChannelOpen,
    },

    async onSubmit(channelData: IChannelFormData): Promise<void> {
      await Tasks.group.createGroupAndInvite({
        ...channelData,
        deepLinkAlias: channelData.type === GroupType.ChannelOpen ? channelData.deepLinkAlias : '',
      });
      popupInstance.close();
    },
  }));

  return presenter;
}
