import { getFixedSizeImage } from 'APP/packages/imageResizer';
import {
  IPushNotification,
  IPushNotificationData,
} from 'STORE/PushNotifications/PushNotifications.types';

export const prepareNotificationOptions = (
  pushNotification: IPushNotification
): NotificationOptions => {
  const icon = pushNotification.icon
    ? getFixedSizeImage(pushNotification.icon, {
        width: 80,
        height: 80,
        isNeedCrop: true,
      })
    : null;

  const data: IPushNotificationData = {
    pushType: pushNotification.pushType,
    group: pushNotification.group,
    groupType: pushNotification.groupType,
    messageId: pushNotification.messageId,
    spaceId: pushNotification.spaceId,
  };

  return {
    body: pushNotification.body,
    icon: icon ? icon : undefined,
    data,
  };
};
