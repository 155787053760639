import { action, computed, observable } from 'mobx';

import Tasks from 'APP/Tasks';
import { checkSlowMode } from 'APP/Tasks/messaging/checkSlowMode/checkSlowMode';
import Entities from 'STORE';

class Payloads {
  @observable payloadList = [];
  callback = null;
  isEditMode = false;

  constructor(root, parent) {
    this.root = root;
    this.parent = parent;
  }

  @computed
  get isEmpty() {
    return this.payloadList.length === 0;
  }

  @computed
  get payloads() {
    return [...this.payloadList];
  }

  @action
  setPayloads(payloads, callback = null) {
    if (Array.isArray(payloads)) {
      this.payloadList = [...payloads];
      this.callback = callback;
    }
  }

  @action
  clear() {
    this.payloadList = [];
    this.callback = null;
  }

  @action
  preSubmit() {
    // Todo need to review all cases of cleaning the input panel and remove unnecessary
    Tasks.messaging.resetLinkPreviewProcess({ groupId: this.parent.group.id });

    this.parent.voice.reset();
    if (this.parent.mention.hasFilter) {
      this.parent.mention.clearFilter();
    }
  }

  @action
  submit() {
    if (this.isEmpty) {
      return;
    }

    const canSubmit = this.checkGroupPermissions();
    if (!canSubmit.result) {
      this.clear();
      canSubmit.error && Entities.toast.show(canSubmit.error);
      return;
    }

    this.preSubmit();
    if (this.isEditMode) {
      this.payloads.forEach((payload) => {
        Tasks.messaging.editMessage(payload);
      });
    } else {
      Tasks.messaging.createNewMessages({
        messages: this.payloads,
      });
    }

    if (this.callback) {
      this.callback({
        type: 'submit',
        isEditMode: this.isEditMode,
        hasErrors: false,
        payloads: this.payloads,
        errorPayloads: [],
      });
    }

    this.isEditMode = false;
    this.clear();
  }

  @action
  async schedule(date) {
    if (this.isEmpty) {
      return;
    }

    const canSchedule = this.checkGroupPermissions(false);
    if (!canSchedule.result) {
      this.clear();
      canSchedule.error && Entities.toast.show(canSchedule.error);
      return;
    }

    const errorPayloads = [];
    const groupId = this.parent.group.isSchedule ? this.parent.group.groupId : this.parent.group.id;

    this.preSubmit();
    for (let i = 0; i < this.payloads.length; i++) {
      let result = false;

      if (this.isEditMode) {
        result = await Tasks.messaging.editScheduledMessage({
          message: this.payloads[i],
          date,
        });
      } else {
        result = await Tasks.messaging.saveScheduledMessage({
          groupId: groupId,
          message: this.payloads[i],
          date,
        });
      }

      if (!result) {
        errorPayloads.push(this.payloads[i]);
      }
    }

    if (this.callback) {
      this.callback({
        type: 'schedule',
        isEditMode: this.isEditMode,
        hasErrors: errorPayloads.length > 0,
        payloads: this.payloads,
        errorPayloads: errorPayloads,
      });
    }

    if (this.isEditMode || errorPayloads.length === 0) {
      this.isEditMode = false;
      this.clear();
    } else {
      this.setPayloads(errorPayloads);
    }

    return errorPayloads.length === 0;
  }

  checkGroupPermissions(needCheckSlowMode = true) {
    let result = { result: true, error: undefined };

    if (needCheckSlowMode) {
      result = checkSlowMode();
      if (!result.result) {
        return result;
      }
    }

    for (const data of this.payloads) {
      let id = '';
      let payload = Tasks.messaging.getPayloadByType(data.type, data.data);

      if (this.isEditMode) {
        id = data.id;
        payload = data.payload;
      }

      result = Tasks.group.checkSendMessagePermissions({
        groupId: data.groupId,
        isNewMessage: !this.isEditMode,
        message: { id, payload },
      });

      if (!result.result) {
        break;
      }
    }

    return result;
  }
}

export default Payloads;
