import React from 'react';

import { Callback } from '../Callback';
import usePresenter from './presenter';

export const AddKeyPressHandler = (props) => {
  const { callbacks } = usePresenter(props);

  return (
    <>
      {callbacks.map((callbackData) => (
        <Callback
          key={callbackData.keyName}
          callbackData={callbackData}
        />
      ))}
    </>
  );
};
