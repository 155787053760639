import { useEffect, useState, useRef, useLayoutEffect } from 'react';

export interface IRoundVideoPlayerProgressParams {
  isPlaying: boolean;
  progress: number;
  speed: number;
}

interface IRoundVideoPlayerProgressPresenter {
  isAnimatedProgress: boolean;
  progressPercentage: number;
  progressRadius: number;
  progressLength: number;
  progressOffset: number;
  onSetAnimation(isPlaying: boolean, progress: number, speed: number): void;
}

const PROGRESS_STEP = 0.3;
const PROGRESS_RADIUS = 146;
const PROGRESS_LENGTH = 2 * Math.PI * PROGRESS_RADIUS;

export function useRoundVideoPlayerProgressPresenter(
  data: IRoundVideoPlayerProgressParams
): IRoundVideoPlayerProgressPresenter {
  const { isPlaying, progress, speed } = data;

  const progressRef = useRef<number>(progress);
  const [isAnimatedProgress, setIsAnimatedProgress] = useState<boolean>(false);

  const presenter: IRoundVideoPlayerProgressPresenter = {
    isAnimatedProgress,

    get progressPercentage(): number {
      if (!isAnimatedProgress) {
        return progress;
      }

      const diffProgress = Math.abs(progress - progressRef.current);
      return Math.min(progress + diffProgress, 1);
    },

    get progressRadius(): number {
      return PROGRESS_RADIUS;
    },

    get progressLength(): number {
      return PROGRESS_LENGTH;
    },

    get progressOffset(): number {
      return PROGRESS_LENGTH * (1 - presenter.progressPercentage);
    },

    onSetAnimation(isPlaying: boolean, progress: number, speed: number): void {
      setIsAnimatedProgress(
        isPlaying &&
          progress >= progressRef.current &&
          progress - progressRef.current <= PROGRESS_STEP * speed
      );
    },
  };

  useLayoutEffect(() => {
    presenter.onSetAnimation(isPlaying, progress, speed);
  }, [progress, isPlaying, speed]);

  useEffect(() => {
    progressRef.current = progress;
  }, [progress]);

  return presenter;
}
