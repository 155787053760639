import React, { FC, MutableRefObject } from 'react';

import { ChatMessage } from 'APP/store/Messages/Message/ChatMessage/ChatMessage';

import { MessageViewPageType } from '../../Messages.types';
import { ContextMenu } from '../ContextMenu/ContextMenu';
import { useMessageContextMenuPresenter } from './MessageContextMenu.presenter';

interface IMessageContextMenuProps {
  reference: MutableRefObject<HTMLElement | null>;
  messageViewPageType: MessageViewPageType;
  message: ChatMessage;
}

export const MessageContextMenu: FC<IMessageContextMenuProps> = ({
  reference,
  message,
  messageViewPageType,
}) => {
  const presenter = useMessageContextMenuPresenter({ reference });

  if (!presenter.popover.isOpened) {
    return null;
  }

  return (
    <ContextMenu
      message={message}
      placement="bottom-start"
      popover={presenter.popover}
      targetClick={presenter.targetClick}
      messageViewPageType={messageViewPageType}
      modifiers={presenter.modifiers}
    />
  );
};
