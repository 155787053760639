import Entities from 'STORE';

import { GroupsEvent } from '../GroupsEvent';

export class SpaceRoleChanged extends GroupsEvent {
  process() {
    const { spaceId, newRole, userIds } = this.eventPayload;

    const space = Entities.spacesStore.getById(spaceId);
    const user = Entities.UsersStore.Me;

    if (space && newRole && (userIds || []).includes(user?.id)) {
      space.setRole(newRole);
    }
  }
}
