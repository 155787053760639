import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import Tasks from 'APP/Tasks';
import { MediaPreview } from 'APP/components/MediaPreview/MediaPreview';
import { MEDIA_PREVIEW_MAX_SIZE } from 'APP/constants/messages';
import { MediaUploadState } from 'MAIN/components/MediaUploadState/MediaUploadState';
import type { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

import styles from './VideoPayload.styles.m.css';

interface IVideoPayloadProps {
  message: ChatMessage;
  isPreview: boolean;
  isView: boolean;
}

export const VideoPayload: FC<IVideoPayloadProps> = observer((props) => {
  const { message, isPreview, isView } = props;

  const previewClassName = classNames(styles.video, {
    [styles.myVideo]: message.fromMe && !message.group?.isChannel,
  });

  const onClick = (): void => {
    Tasks.group.openMediaGallery({
      groupId: message.groupId,
      mediaId: message.clientUuid,
      messageId: message.id,
      showMessageInfo: !message.isFakeGroup,
    });
  };

  return (
    <div className={styles.container}>
      <MediaPreview
        className={previewClassName}
        payload={message.payload}
        maxWidth={MEDIA_PREVIEW_MAX_SIZE.width}
        maxHeight={MEDIA_PREVIEW_MAX_SIZE.height}
        isPreview={isPreview}
        isView={isView}
        onClick={onClick}
        payloadId={message.id}
        groupId={message.groupId}
        messageId={message.id}
        isPlayIcon={!message.payload.uploadProgress && !message.isFake}
      />
      <MediaUploadState message={message} />
    </div>
  );
});
