import api from 'APP/packages/api';
import { SendComplaintRequestComplaint } from 'APP/packages/api/types/model/sendComplaintRequestComplaint';
import logger from 'APP/packages/logger';

import { IBooleanResponse } from '../common/commonModel.types';
import { buildModelError } from '../error/errorBuilder';
import { IComplaintData, IComplaintReason, ISendComplaint } from './moderationModel.types';

export const getComplaintReasons = async (): Promise<IComplaintReason[]> => {
  try {
    return await api.moderation.getComplaintReasons();
  } catch (error) {
    logger.get('API').error('moderation.getComplaintReasons', error);
    throw buildModelError(error);
  }
};

export const sendComplaint = async <T = IComplaintData>(
  data: ISendComplaint<T>
): Promise<IBooleanResponse> => {
  try {
    return await api.moderation.sendComplaint({
      ...data,
      complaint: data.complaint as SendComplaintRequestComplaint,
    });
  } catch (error) {
    logger.get('API').error('moderation.sendComplaint', error);
    throw buildModelError(error);
  }
};
