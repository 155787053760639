import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { STREAM_STATUS } from 'APP/constants/stream';
import Entities from 'APP/store';

import useStreamer from '../presenter/streamer';
import useStreamStateHandler from './streamStateHandler';

export default () => {
  const streamerPresenter = useStreamer();
  const presenter = useLocalStore(() => ({
    initialStatus: null,
    initialChannelId: null,

    get stream() {
      return Entities.ActiveStream.stream;
    },

    get channel() {
      const groupId = Entities.ActiveStream.stream?.groupId;

      return groupId ? Entities.GroupsStore.getGroupById(groupId) : null;
    },

    get canLeaveComment() {
      return presenter.channel?.memberCustomPermissions?.canLeaveComment;
    },

    get isStreamStarted() {
      return presenter.stream.status === STREAM_STATUS.STARTED;
    },

    get isStreamFinished() {
      return presenter.stream?.status === STREAM_STATUS.FINISHED;
    },

    get isMeStreamer() {
      return Boolean(presenter.stream?.isMeStreamer);
    },

    async init() {
      presenter.stream.addInitiator();
      presenter.stream.setActiveStreamer(presenter.stream.initiatorUid);

      const role = presenter.isMeStreamer ? 'host' : 'audience';

      await streamerPresenter.init({ role });
      await streamerPresenter.join({
        token: presenter.stream.token,
        uid: presenter.stream.uid,
        screenUid: presenter.stream.shareScreenUid,
        channelId: presenter.stream.channelId,
      });
      presenter.initialStatus = presenter.stream.status;
      presenter.initialChannelId = presenter.stream.channelId;
    },

    async makeHost() {
      const streamer = presenter.stream.findStreamerByUid(presenter.stream.uid);

      if (streamer && streamerPresenter.audioVideoStreamer.isAudience) {
        streamerPresenter.publishAudioVideo();
      }
    },

    get primaryVideo() {
      const activeStreamer = streamerPresenter.activeStreamer;
      const videoTrack = activeStreamer.streamer.videoTrack;
      const screenTrack = activeStreamer.streamer.screenTrack;

      return activeStreamer.isSharedScreen ? screenTrack : videoTrack;
    },

    async leave() {
      await streamerPresenter.audioVideoStreamer?.dispose();
      await streamerPresenter.shareScreenStreamer?.dispose();
    },

    get totalViewers() {
      return presenter.stream?.totalViewers || 0;
    },

    async onClose() {
      await presenter.leave();
      await Tasks.streaming.leave({ channelId: presenter.stream.channelId });
      Entities.ActiveStream.reset();
    },

    get hasShowControl() {
      return !presenter.isStreamFinished;
    },

    get viewersLiveCount() {
      return presenter.stream?.viewersLiveCount;
    },

    collapse() {
      presenter.isFullScreen = false;
    },

    expand() {
      presenter.isFullScreen = true;
    },

    get donationUrl() {
      const channel = Entities.GroupsStore.getGroupById(this.stream.groupId);

      return channel.donationUrl;
    },

    dispose() {
      streamerPresenter.dispose();
    },

    checkFeedback() {
      if (presenter.initialStatus !== STREAM_STATUS.FINISHED) {
        Tasks.feedback.checkStarsFeedback(presenter.initialChannelId);
      }
    },
  }));

  useStreamStateHandler({ rootPresenter: presenter, streamerPresenter });

  useEffect(() => {
    presenter.init();

    if (presenter.isMeStreamer) {
      presenter.makeHost();
    }

    return () => {
      presenter.dispose();
      presenter.checkFeedback();
    };
  }, []);

  return { presenter, streamerPresenter };
};
