import React from 'react';
import { Form as BaseForm, FormProps } from 'react-final-form';

export const Form = <FormValues = Record<string, any>, InitialFormValues = Partial<FormValues>>({
  subscription = {},
  children,
  ...props
}: FormProps<FormValues, InitialFormValues>): React.ReactElement => {
  return (
    <BaseForm
      subscription={{
        active: false,
        dirty: false,
        dirtyFields: false,
        dirtyFieldsSinceLastSubmit: false,
        dirtySinceLastSubmit: false,
        error: false,
        errors: false,
        hasSubmitErrors: false,
        hasValidationErrors: false,
        initialValues: true,
        invalid: false,
        modified: false,
        modifiedSinceLastSubmit: false,
        pristine: false,
        submitting: false,
        submitError: false,
        submitErrors: false,
        submitFailed: false,
        submitSucceeded: false,
        touched: false,
        valid: false,
        validating: false,
        values: false,
        visited: false,
        ...subscription,
      }}
      {...props}
    >
      {children}
    </BaseForm>
  );
};
