import { observer } from 'mobx-react';
import React, { FC, ReactElement, useCallback } from 'react';
import { FormSpy } from 'react-final-form';

import { Field } from 'APP/packages/form/Field/Field';
import { Form } from 'APP/packages/form/Form/Form';
import { IFormContentProps } from 'APP/packages/form/Form/Form.types';
import { TextField } from 'APP/packages/form/TextField/TextField';
import { useTranslation } from 'APP/packages/translations';
import IconMedia from 'ICONS/ic-media.svg';
import { Button, ButtonTypes } from 'UIKIT/Button/Button';
import { PopupFooter } from 'UIKIT/PopupFooter/PopupFooter';

import { ImageUploader } from './ImageUploader/ImageUploader';
import styles from './StreamForm.styles.m.css';
import { IStreamFormData } from './StreamForm.types';

interface IStreamFormProps {
  onFormStateChange(state: Partial<IFormContentProps>): void;
  onSubmit(values: IStreamFormData): Promise<void>;
}

export const StreamForm: FC<IStreamFormProps> = observer(({ onFormStateChange, onSubmit }) => {
  const { t } = useTranslation();

  const getPreviewUrl = useCallback(
    (image: Blob) => (image ? URL.createObjectURL(image) : null),
    []
  );

  return (
    <Form
      initialValues={{
        preview: null,
      }}
      subscription={{
        submitting: true,
        invalid: true,
        validating: true,
      }}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, submitting, invalid, validating }: IFormContentProps): ReactElement => (
        <>
          <FormSpy
            subscription={{ submitting: true, pristine: true }}
            onChange={onFormStateChange}
          />

          <form
            onSubmit={handleSubmit}
            id={'createStreaming'}
            className={styles.container}
          >
            <div className={styles.content}>
              <div className={styles.title}>{t('stream_thumbnail')}</div>
              <div className={styles.description}>{t('upload_stream_picture')}</div>
              <div className={styles.imageUploader}>
                <FormSpy subscription={{ values: true }}>
                  {({ values }): ReactElement => {
                    const previewUrl = getPreviewUrl(values.preview);
                    return (
                      <div className={styles.image}>
                        {previewUrl ? (
                          <img
                            alt=""
                            src={previewUrl}
                            className={styles.preview}
                          />
                        ) : (
                          <IconMedia className={styles.icon} />
                        )}
                      </div>
                    );
                  }}
                </FormSpy>
                <Field
                  name="preview"
                  component={ImageUploader}
                />
              </div>
              <Field
                name="title"
                label={t('stream_title_input_field')}
                component={TextField}
                maxLength={130}
                validatingText={''}
                successText={''}
              />
            </div>
          </form>

          <PopupFooter>
            <Button
              type={ButtonTypes.Submit}
              form={'createStreaming'}
              theme="1"
              isLoading={submitting}
              isDisabled={invalid || validating}
            >
              {t('next_button')}
            </Button>
          </PopupFooter>
        </>
      )}
    </Form>
  );
});
