import { useAsObservableSource, useLocalStore } from 'mobx-react';

export interface IFileUploadStateData {
  uploadProgress: number;
  cancelUpload(): Promise<void>;
  hasError?: boolean;
}

interface IFileUploadStateLocalStore {
  uploadFileData: IFileUploadStateData;
  hasError: boolean;
  isWaitingUploadProcess: boolean;
  isUploadProcess: boolean;
  uploadProgress: number | null;
  cancelUpload(): Promise<void>;
}

export const useFileUploadStatePresenter = (uploadFileData: IFileUploadStateData) => {
  const source = useAsObservableSource({ uploadFileData });

  const presenter = useLocalStore<IFileUploadStateLocalStore>(() => ({
    get uploadFileData(): IFileUploadStateData {
      return source.uploadFileData;
    },

    get hasError(): boolean {
      return Boolean(presenter.uploadFileData.hasError);
    },

    get isWaitingUploadProcess(): boolean {
      return !presenter.hasError && presenter.uploadFileData.uploadProgress === 0;
    },

    get uploadProgress(): number | null {
      return presenter.uploadFileData.uploadProgress;
    },

    get isUploadProcess(): boolean {
      return Number(presenter.uploadProgress) > 0;
    },

    async cancelUpload(): Promise<void> {
      await presenter.uploadFileData.cancelUpload?.();
    },
  }));

  return presenter;
};
