import { BooleanResponse } from 'APP/packages/api/types/model/booleanResponse';
import { CallFinishRequest } from 'APP/packages/api/types/model/callFinishRequest';
import { CallInfoV2Request } from 'APP/packages/api/types/model/callInfoV2Request';
import { CallInfoV2Response } from 'APP/packages/api/types/model/callInfoV2Response';
import { CallJoinRequest } from 'APP/packages/api/types/model/callJoinRequest';

import { request } from '../request/request';
import { CallCreateV2Request } from '../types/model/callCreateV2Request';

const ROOT_API = 'calls';

export const createCallV2 = (data: CallCreateV2Request): Promise<CallInfoV2Response> => {
  return request({
    rootApi: ROOT_API,
    url: '/createCall',
    method: 'POST',
    body: data,
    version: 'v2',
  });
};

export const getCallV2Info = (data: CallInfoV2Request): Promise<CallInfoV2Response> => {
  return request({
    rootApi: ROOT_API,
    url: '/getCallInfo',
    method: 'POST',
    body: data,
    version: 'v2',
  });
};

export const finishCallV2 = (data: CallFinishRequest): Promise<BooleanResponse> => {
  return request({
    rootApi: ROOT_API,
    url: '/finishCall',
    method: 'POST',
    body: data,
    version: 'v2',
  });
};

export const joinCallV2 = (data: CallJoinRequest): Promise<BooleanResponse> => {
  return request({
    rootApi: ROOT_API,
    url: '/joinCall',
    method: 'POST',
    body: data,
    version: 'v2',
  });
};
