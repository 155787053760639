import { observer } from 'mobx-react';
import React from 'react';

import { MediaPreview } from 'APP/components/MediaPreview/MediaPreview';
import TextRenderer from 'APP/components/TextRendererCollapsed';
import { MEDIA_PREVIEW_MAX_SIZE } from 'APP/constants/newsfeed';
import PlayIcon from 'ICONS/ic-play-32.svg';

import { PostReactions } from '../components/PostReactions/PostReactions';
import Translate from '../components/Translate';
import { usePostVideoPresenter } from './PostVideo.presenter';
import styles from './PostVideo.styles.m.css';

export const PostVideo = observer(({ message, isPreview, isView }) => {
  const presenter = usePostVideoPresenter({ message });

  return (
    <>
      <div className={styles.imageContainer}>
        <MediaPreview
          className={styles.preview}
          payload={presenter.payload}
          maxWidth={MEDIA_PREVIEW_MAX_SIZE.width}
          maxHeight={MEDIA_PREVIEW_MAX_SIZE.height}
          isPreview={isPreview}
          isView={isView}
          onClick={presenter.onOpenMediaGallery}
          payloadId={message.id}
          groupId={message.groupId}
        />

        <div className={styles.playButton}>
          <PlayIcon
            className={styles.playIcon}
            onClick={presenter.onOpenMediaGallery}
          />
        </div>
      </div>

      <div className={styles.content}>
        {presenter.isShowComment && (
          <>
            <TextRenderer
              className={styles.textWrapper}
              textClassName={styles.text}
              keyWords={presenter.keyWords}
              userMentions={presenter.userMentions}
              groupId={message.groupId}
              rows={4}
            >
              {presenter.comment}
            </TextRenderer>
            <Translate message={message} />
          </>
        )}

        <PostReactions
          message={message}
          isVisible={isView}
        />
      </div>
    </>
  );
});
