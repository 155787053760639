import { observer } from 'mobx-react';
import React from 'react';
import { NavLink } from 'react-router-dom';

import { SpaceAvatar } from 'APP/components/Avatar/SpaceAvatar/SpaceAvatar';
import { useTranslation } from 'APP/packages/translations';
import CopyIcon from 'ICONS/ic-s-copy.svg';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';
import { IconButton } from 'UIKIT/IconButton';

import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';
import { MetaBlock } from '../MetaBlock/MetaBlock';
import { getSpaceLink, getSpaceAbsoluteLink } from './presenter';
import styles from './styles.m.css';

export const SpaceLink = observer(({ className, space }) => {
  const { t } = useTranslation();

  const copyToClipboard = useCopyToClipboard(
    getSpaceAbsoluteLink(space.id),
    t('common_link_copied')
  );

  const SpaceAvatarIcon = ({ className }) => (
    <SpaceAvatar
      className={className}
      space={space}
      type={AvatarTypes.Square_0}
      isShowLockIcon={false}
    />
  );

  return (
    <MetaBlock
      className={className}
      label={t('space_profile_inspace')}
      Icon={SpaceAvatarIcon}
      Actions={
        <>
          <IconButton
            Icon={CopyIcon}
            onClick={copyToClipboard}
          />
        </>
      }
    >
      <NavLink
        className={styles.link}
        to={getSpaceLink(space.id)}
      >
        {space.name}
      </NavLink>
    </MetaBlock>
  );
});
