import { IPersonalizedSpace } from 'APP/model/space/spaceModel.types';
import Entities from 'STORE';

export async function handleSpacesChangedBatched(
  spaces: IPersonalizedSpace[],
  lastTs?: string
): Promise<void> {
  Entities.spacesStore.addSpaces(spaces);
  if (lastTs) {
    Entities.spacesStore.setSpaceTs(lastTs);
  }
}

export async function handleSpacesUpdated(
  spaceData: IPersonalizedSpace,
  updatedTs: string,
  groupsChangedAt?: string
): Promise<void> {
  Entities.spacesStore.updateSpace(spaceData);
  if (updatedTs) {
    Entities.spacesStore.setSpaceTs(updatedTs);
  }

  if (groupsChangedAt) {
    const space = Entities.spacesStore.getById(spaceData.space.id);
    space?.setGroupsChangedAt(groupsChangedAt ? parseInt(groupsChangedAt, 10) : null);
  }
}

export async function handleSpacesCollectionUpdated(
  spaceId: string,
  updatedTs: string
): Promise<void> {
  const space = Entities.spacesStore.getById(spaceId);

  if (updatedTs && space) {
    space.collections.setIsNeedToUpdate(true);
  }
}
