import { observable } from 'mobx';

import { NewsType, INewsItem } from 'APP/model/catalog/catalogModel.types';
import { ICategoryInfo } from 'APP/model/common/commonModel.types';

import { NewsItem } from './NewsItem';

export class Categories extends NewsItem {
  @observable categories: ICategoryInfo[];

  constructor(data: INewsItem) {
    super(NewsType.Categories);

    this.categories = Array.isArray(data?.categories) ? data.categories : [];
  }
}
