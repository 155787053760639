import { OnboardingType } from 'APP/constants/onboarding';
import { storage } from 'APP/packages/storage';
import Entities from 'APP/store';

export function closeOnboarding(type: OnboardingType): void {
  Entities.onboarding.removeFromShow(type);

  if (Entities.onboarding.getIsNeedToShow(type)) {
    Entities.onboarding.addShowedOnboarding(type);
    storage.onboarding.set(Entities.onboarding.showedOnboardings);
  }
}
