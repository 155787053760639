import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, useState } from 'react';
import Draggable from 'react-draggable';
import { Link } from 'react-router-dom';

import { MediumCallAvatar } from 'MAIN/OngoingCall/Shared/Avatars/MediumCallAvatar/MediumCallAvatar';
import { CallActionsPanel } from 'MAIN/OngoingCall/Shared/CallActionsPanel/CallActionsPanel';
import { FullScreenButton } from 'MAIN/OngoingCall/Shared/FullScreenButton/FullScreenButton';
import { OpponentVideo } from 'MAIN/OngoingCall/Shared/OpponentVideo/OpponentVideo';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';

import { CallingPlaceholder } from './CallingPlaceholder/CallingPlaceholder';
import { useMinimizedCallViewPresenter } from './MinimizedCallView.presenter';
import styles from './MinimizedCallView.styles.m.css';

export const MinimizedCallView: FC = observer(() => {
  const [isDragging, setDragging] = useState<boolean>();
  const presenter = useMinimizedCallViewPresenter();

  return (
    <Draggable
      cancel="button"
      bounds={'body'}
      onStart={(): void => setDragging(false)}
      onDrag={(): void => setDragging(true)}
      onStop={(): void => setDragging(false)}
    >
      <div
        className={classNames(styles.container, {
          [styles.offset]: presenter.isOffset,
          [styles.dragging]: isDragging,
        })}
      >
        {!presenter.hasVideo && (
          <>
            <div className={styles.avatarContainer}>
              <MediumCallAvatar avatar={presenter.call.avatar} />
            </div>
            <CallingPlaceholder call={presenter.call} />
          </>
        )}

        {presenter.hasVideo && !!presenter.videoOpponent && (
          <OpponentVideo
            opponent={presenter.videoOpponent}
            avatarType={AvatarTypes.Round_10}
            containerClassName={styles.videoContainer}
            className={styles.video}
          />
        )}

        <div
          className={classNames({
            [styles.hidableContainer]: presenter.hasVideo,
          })}
        >
          {presenter.groupLink && (
            <Link
              to={presenter.groupLink}
              onClick={presenter.onClick}
              className={styles.chatLink}
            />
          )}
          <FullScreenButton className={styles.expandButton} />
          <div className={styles.actionsPanelContainer}>
            <CallActionsPanel className={styles.actionPanel} />
          </div>
        </div>
      </div>
    </Draggable>
  );
});
