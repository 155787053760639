import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { TextFormattingInput } from 'APP/components/TextFormattingInput/TextFormattingInput';
import { useTranslation } from 'APP/packages/translations';

import BotCommandsButton from './BotCommandsButton';
import { EmojisStickersButton } from './EmojisStickersButton/EmojisStickersButton';
import { useInputSectionPresenter } from './InputSection.presenter';
import styles from './InputSection.styles.m.css';
import ScheduleButton from './ScheduleButton';

interface IInputSectionProps {
  className?: string;
}

export const InputSection: FC<IInputSectionProps> = observer(({ className }) => {
  const { presenter, inputRef } = useInputSectionPresenter();
  const { t } = useTranslation();

  return (
    <div className={className}>
      <TextFormattingInput
        inputClassName={styles.input}
        placeholderClassName={styles.inputPlaceholder}
        value={presenter.value}
        placeholder={t('chat_message_input_view_hint')}
        inputPresenterRef={inputRef}
        onChange={presenter.onChangeValue}
        onSubmit={presenter.onSubmitValue}
        onSubmitFiles={presenter.onSubmitFiles}
        onEdit={presenter.onEditLastMessage}
        onSelectMention={presenter.onSelectMention}
      />

      {presenter.isShowCommandsButton && <BotCommandsButton />}
      {presenter.isShowScheduleButton && <ScheduleButton />}
      <EmojisStickersButton />
    </div>
  );
});
