import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { useTranslation } from 'APP/packages/translations';
import { Button } from 'UIKIT/Button/Button';

import { useScheduledCallsStartButtonPresenter } from './ScheduledCallsStartButton.presenter';
import styles from './ScheduledCallsStartButton.styles.m.css';

interface IScheduledCallsStartButtonProps {
  groupId: string;
  initiatorId: string;
  startTs: number;
  onClick?(): void;
}

export const ScheduledCallsStartButton: FC<IScheduledCallsStartButtonProps> = observer((props) => {
  const { t } = useTranslation();
  const presenter = useScheduledCallsStartButtonPresenter(props);
  return (
    <Button
      className={styles.startButton}
      theme={'1'}
      rounding={'1'}
      onClick={presenter.onButtonClick}
    >
      {t(presenter.group?.hasCall ? 'calls_join' : 'start_button')}
    </Button>
  );
});
