import { action, computed, observable, values } from 'mobx';

import { MAX_COUNT_PARTICIPANTS } from 'APP/constants/calls';
import { CallProviderType } from 'APP/model/call/callModel.types';

import Entities from '../index';
import { Call } from './Call/Call';

export class Calls {
  root: typeof Entities;
  @observable data = observable.map<string, Call>({}, { deep: false });
  @observable maxParticipants = MAX_COUNT_PARTICIPANTS;
  @observable callsProvider: CallProviderType = CallProviderType.Agora;

  constructor(root: typeof Entities) {
    this.root = root;
  }

  @action reset(): void {
    Array.from(this.data.values()).forEach((call) => {
      call.dispose();
    });
    this.data.clear();
    this.callsProvider = CallProviderType.Agora;
  }

  @action
  setMaxParticipants(value = MAX_COUNT_PARTICIPANTS): void {
    this.maxParticipants = value;
  }

  @action
  add(groupId: string, call: Call): void {
    this.data.set(groupId, call);
  }

  @action
  setCallsProvider(provider: CallProviderType): void {
    this.callsProvider = provider;
  }

  @action
  async delete(groupId: string, channelId: string | null): Promise<void> {
    const call = this.getCallByGroupId(groupId);

    if (!call || call.isBusy || call.isNoAnswer || call.channelId !== channelId) {
      return;
    }

    this.data.delete(groupId);
    await call.dispose();
  }

  @computed
  get calls(): readonly Call[] {
    return values(this.data);
  }

  @computed
  get hasCalls(): boolean {
    return this.calls.length > 0;
  }

  getCallByGroupId(groupId: string): Call | null {
    return this.data.get(groupId) || null;
  }

  @computed
  get ongoingCall(): Call | null {
    return this.calls[0] || null;
  }
}
