import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { ClientRole } from 'APP/constants/calls';
import { STREAM_STATUS } from 'APP/constants/stream';
import { useTranslation } from 'APP/packages/translations';
import Entities from 'APP/store';

import useStreamer from '../presenter/streamer';
import takePhoto from './helper/takePhoto';
import useStreamStateHandler from './streamStateHandler';

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default () => {
  const { t } = useTranslation();

  const streamerPresenter = useStreamer();
  const presenter = useLocalStore(() => ({
    initialStatus: null,
    initialChannelId: null,

    isFullScreen: true,

    isWaiting: false,
    isConnecting: false,
    isCanceled: false,
    isStarted: false,

    isShowViewers: false,

    isWaitForAction: false,
    isWillBePublished: false,

    get stream() {
      return Entities.ActiveStream.stream;
    },

    get isStreamStarted() {
      return presenter.stream.status === STREAM_STATUS.STARTED;
    },

    get isStreamFinished() {
      return presenter.stream?.status === STREAM_STATUS.FINISHED;
    },

    get channel() {
      const groupId = presenter.stream?.groupId;

      return groupId ? Entities.GroupsStore.getGroupById(groupId) : null;
    },

    get canLeaveComment() {
      return presenter.channel?.memberCustomPermissions?.canLeaveComment;
    },

    async init() {
      await streamerPresenter.init({ role: ClientRole.Host });
      if (presenter.isStreamStarted) {
        await streamerPresenter.join({
          token: presenter.stream.token,
          uid: presenter.stream.uid,
          screenUid: presenter.stream.shareScreenUid,
          channelId: presenter.stream.channelId,
        });
      }

      if (!presenter.isStreamFinished) {
        await streamerPresenter.unmuteAudio();
        await streamerPresenter.unmuteVideo();
      }
      presenter.initialStatus = presenter.stream.status;
      presenter.initialchannelId = presenter.stream.channelId;
    },

    get viewersLiveCount() {
      return presenter.stream?.viewers.length || 0;
    },

    get primaryVideo() {
      const activeStreamer = streamerPresenter.activeStreamer;

      if (activeStreamer?.streamer) {
        const videoTrack = activeStreamer.streamer.videoTrack;
        const screenTrack = activeStreamer.streamer.screenTrack;

        return activeStreamer.isSharedScreen ? screenTrack : videoTrack;
      }

      return streamerPresenter.audioVideoStreamer?.localVideoTrack;
    },

    async goLive() {
      presenter.isWaiting = true;
      await sleep(3000);
      presenter.isWaiting = false;
      const preview = await takePhoto(streamerPresenter.audioVideoStreamer.localVideoTrack);
      presenter.isConnecting = true;
      await sleep(0);
      const stream = await Tasks.streaming.start({
        groupId: presenter.stream.groupId,
        previewImageUrl: presenter.stream.previewImageUrl,
        title: presenter.stream.title,
      });
      if (stream && !presenter.initialChannelId) {
        presenter.initialChannelId = stream.channelId;
      }
      if (stream) {
        await streamerPresenter.join({
          token: stream.token,
          uid: stream.uid,
          screenUid: stream.shareScreenUid,
          channelId: stream.channelId,
        });

        if (!streamerPresenter.audioVideoStreamer.isJoined) {
          await Tasks.streaming.finish({
            channelId: stream.channelId,
          });
          return;
        }
        if (!presenter.isCanceled) {
          streamerPresenter.publishAudioVideo();
        }
        if (!presenter.isCanceled && streamerPresenter.isSharedScreen) {
          await Tasks.streaming.startScreenSharing({
            channelId: stream.channelId,
          });
          await streamerPresenter.shareScreenStreamer.join({
            token: stream.shareScreenToken,
            uid: stream.shareScreenUid,
            screenUid: stream.shareScreenUid,
            channelId: stream.channelId,
          });
          await streamerPresenter.publishScreen();
        }
        presenter.isConnecting = false;
        await sleep(0);
        // if in the process of connecting to the agora you pressed cancel
        if (!presenter.isCanceled) {
          Tasks.streaming.updatePreview({
            preview,
            channelId: stream.channelId,
            fileName: `${stream.channelId}-${new Date().toISOString()}`,
          });
          presenter.stream.updateStream({
            ...stream,
            shareScreenUid: stream.shareScreenUid,
            initiatorShareScreenUid: stream.shareScreenUid,
            initiatorUid: stream.uid,
            status: STREAM_STATUS.STARTED,
          });
          presenter.setupInitiator();
          streamerPresenter.disposeVolumeIndicator = streamerPresenter.initVolumeIndicator();
        }
      }
      if (!presenter.isCanceled) {
        presenter.isStarted = true;
        await sleep(2000);
        presenter.isStarted = false;
      } else {
        await presenter.leave();
      }
    },

    setupInitiator() {
      presenter.stream.addInitiator();
      presenter.stream.initiator.setAudioTrack({
        track: streamerPresenter.audioVideoStreamer?.localAudioTrack,
        hasAudio: !streamerPresenter.audioMuted,
      });
      presenter.stream.initiator.setVideoTrack({
        track: streamerPresenter.audioVideoStreamer?.localVideoTrack,
        hasVideo: !streamerPresenter.videoMuted,
      });
      presenter.stream.initiator.setSharedScreen(
        streamerPresenter.shareScreenStreamer?.localVideoTrack
      );
      presenter.stream.setActiveStreamer(presenter.stream.uid);
    },

    cancel() {
      presenter.isCanceled = true;
      presenter.isConnecting = false;
    },

    reset() {
      presenter.isCanceled = false;
      presenter.isConnecting = false;
      presenter.isWaiting = false;
      presenter.isStarted = false;
    },

    async leave() {
      if (streamerPresenter.audioVideoStreamer) {
        await streamerPresenter.unpublishAudioVideo();
        await streamerPresenter.audioVideoStreamer.leave();
      }
      if (streamerPresenter.shareScreenStreamer) {
        await streamerPresenter.unpublishScreen();
        await streamerPresenter.shareScreenStreamer.leave();
      }
    },

    async finish() {
      const canFinish = await Tasks.app.showConfirm({
        title: t('stream_stop_stream_title'),
        text: t('stream_stop_stream_description'),
        primaryText: t('stop_button'),
      });

      if (canFinish) {
        presenter.isShowViewers = false;
        await Tasks.streaming.finish({
          channelId: presenter.stream.channelId,
        });
        presenter.isWaitForAction = true;
      }
    },

    async publish() {
      await Tasks.streaming.publish({
        channelId: presenter.stream.channelId,
      });
      presenter.isWaitForAction = false;
      presenter.isWillBePublished = true;
    },

    async leaveWithoutSaving() {
      await Tasks.streaming.leaveWithoutSaving({
        channelId: presenter.stream.channelId,
      });

      presenter.isWaitForAction = false;
      presenter.isWillBePublished = false;
    },

    get totalViewers() {
      return presenter.stream?.totalViewers || 0;
    },

    onClose() {
      Entities.ActiveStream.reset();
    },

    get hasShowControl() {
      return (
        !presenter.isCanceled &&
        !presenter.isConnecting &&
        !presenter.isWaiting &&
        !presenter.isStreamFinished
      );
    },

    collapse() {
      presenter.isFullScreen = false;
    },

    expand() {
      presenter.isFullScreen = true;
    },

    toggleViewers() {
      presenter.isShowViewers = !presenter.isShowViewers;
    },

    dispose() {
      streamerPresenter.dispose();
    },

    checkFeedback() {
      if (presenter.initialStatus !== STREAM_STATUS.FINISHED) {
        Tasks.feedback.checkStarsFeedback(presenter.initialChannelId);
      }
    },
  }));

  useStreamStateHandler(presenter);

  useEffect(() => {
    presenter.init();

    return () => {
      presenter.dispose();
      presenter.checkFeedback();
    };
  }, []);

  return { presenter, streamerPresenter };
};
