import { IVideoPlayerConfig } from './VideoPlayer.types';

export enum VideoPlayerType {
  Default = 'default',
  Mini = 'mini',
}

export const defaultVideoPlayerConfig: IVideoPlayerConfig = {
  volume: 1,
  isMuted: false,
  speed: 1,
};

export const VIDEO_PLAYER_VOLUME_STEP = 0.05;
export const VIDEO_PLAYER_VOLUME_MAX = 1;
