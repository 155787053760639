import React, { FC, memo, useState } from 'react';

import { AvatarPlaceholderType } from '../AvatarPlaceholder/AvatarPlaceholder';
import InfoCard from '../InfoCard';
import useSyncAnimation from '../hooks/useSyncAnimations';

export const GROUPS_PLACEHOLDER_ITEM_SIZE = 64;

export interface IGroupsPlaceholderProps {
  className?: string;
  count?: number;
  avatarType?: AvatarPlaceholderType;
}

export const GroupsPlaceholder: FC<IGroupsPlaceholderProps> = memo((props) => {
  const { className, count = 20, avatarType = AvatarPlaceholderType.Round_10 } = props;
  const [list] = useState(
    Array(count)
      .fill('item')
      .map((item, index) => `${item}_${index}`)
  );

  const { isFirstAnimated, isSecondAnimated } = useSyncAnimation({
    firstTimeout: 500,
    secondTimeout: 1000,
  });

  return (
    <>
      {list.map((item) => {
        return (
          <InfoCard
            key={item}
            className={className}
            avatarType={avatarType}
            isSecondAnimated={isSecondAnimated}
            isFirstAnimated={isFirstAnimated}
          />
        );
      })}
    </>
  );
});
