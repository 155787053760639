import api from 'APP/packages/api';
import logger from 'APP/packages/logger';

import { buildModelError } from '../error/errorBuilder';
import { mapDeepLinkResponse } from './deeplinksModel.mapper';
import { IGetDeepLinkRequest, IDeepLink } from './deeplinksModel.types';

export const getDeepLink = async (data: IGetDeepLinkRequest): Promise<IDeepLink> => {
  try {
    const deepLink = await api.deeplinks.getDeepLink(data);
    return mapDeepLinkResponse(deepLink);
  } catch (error) {
    logger.get('API').error('deeplinks.getDeepLink', error);
    throw buildModelError(error);
  }
};
