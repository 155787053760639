import React, { memo } from 'react';

import { RadioButton } from 'UIKIT/RadioButton/RadioButton';

import styles from './FormRadioButton.styles.m.css';

export const FormRadioButton = memo(({ input: { name, checked, onChange, value }, label }) => {
  return (
    <RadioButton
      theme={checked ? '1' : '2'}
      className={styles.button}
      label={label}
      name={name}
      value={value}
      checked={checked}
      onChange={onChange}
    />
  );
});
