import { PayloadType } from 'APP/model/message/messageModel.types';
import { ChatMessage } from 'APP/store/Messages/Message/ChatMessage/ChatMessage';
import Entities from 'STORE';
import { Schedule } from 'STORE/Groups/Schedule';
import { Thread } from 'STORE/Groups/Thread';

import {
  getAlbumPayloadError,
  getArticlePayloadError,
  getAudioPayloadError,
  getFilePayloadError,
  getImagePayloadError,
  getStickerPayloadError,
  getTextPayloadError,
  getVideoPayloadError,
  getCircleVideoPayloadError,
  getVoicePayloadError,
} from './getPayloadsErrors';

interface ICheckSendMessagePermissionsParams {
  groupId: string;
  isNewMessage?: boolean;
  payloadType?: PayloadType;
  message?: ChatMessage;
}

interface ICheckSendMessagePermissions {
  result: boolean;
  error?: string;
}

export const checkSendMessagePermissions = (
  data: ICheckSendMessagePermissionsParams
): ICheckSendMessagePermissions => {
  const { groupId, isNewMessage = true, payloadType, message } = data;

  const type = payloadType || (message?.payload?.payloadType as PayloadType);
  let errorText: string | null = null;
  let group = Entities.GroupsStore.getGroupById(groupId);

  if (group instanceof Thread || group instanceof Schedule) {
    group = group.parentGroup;
  }

  if (!group || !type) {
    return { result: false };
  }

  if (group.withMeInAdmins) {
    return { result: true };
  }

  switch (type) {
    case PayloadType.Sticker:
      errorText = getStickerPayloadError(group);
      break;

    case PayloadType.VoiceMessage:
      errorText = isNewMessage
        ? getVoicePayloadError(group) || getTextPayloadError(group, message?.payload)
        : getTextPayloadError(group, message?.payload);
      break;

    case PayloadType.RichText:
      errorText = getTextPayloadError(group, message?.payload);
      break;

    case PayloadType.Image:
      errorText = isNewMessage
        ? getImagePayloadError(group) || getTextPayloadError(group, message?.payload)
        : getTextPayloadError(group, message?.payload);
      break;

    case PayloadType.Video:
      errorText = isNewMessage
        ? getVideoPayloadError(group) || getTextPayloadError(group, message?.payload)
        : getTextPayloadError(group, message?.payload);
      break;

    case PayloadType.CircleVideo:
      errorText = getCircleVideoPayloadError(group);
      break;

    case PayloadType.AudioMessage:
      errorText = isNewMessage
        ? getAudioPayloadError(group) || getTextPayloadError(group, message?.payload)
        : getTextPayloadError(group, message?.payload);
      break;

    case PayloadType.File:
      errorText = isNewMessage
        ? getFilePayloadError(group) || getTextPayloadError(group, message?.payload)
        : getTextPayloadError(group, message?.payload);
      break;

    case PayloadType.Album:
      errorText = isNewMessage
        ? getAlbumPayloadError(group, message?.payload) ||
          getTextPayloadError(group, message?.payload)
        : getTextPayloadError(group, message?.payload);
      break;

    case PayloadType.Article:
      errorText = getArticlePayloadError(group, message?.payload);
      break;

    default:
      errorText = null;
  }

  return {
    result: !errorText,
    error: errorText || undefined,
  };
};
