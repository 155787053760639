import { observer } from 'mobx-react';
import React, { FC, ReactElement } from 'react';

import { AudioState } from 'MAIN/components/AudioState/AudioState';
import { AudioPlayerTrackCaption } from 'UIKIT/Media/AudioPlayer/AudioPlayerUI/AudioPlayerTrackCaption/AudioPlayerTrackCaption';
import { PlayIconType } from 'UIKIT/PlayIcon/PlayIcon.types';

import { VoiceHistogram } from '../VoiceHistogram/VoiceHistogram';
import { useVoicePresenter } from './VoicePlayer.presenter';
import styles from './VoicePlayer.styles.m.css';

interface IVoicePlayerProps {
  src: string;
  groupId: string;
  messageId: string;
  payloadId: string;
  clientUuid: string;
  isLoading: boolean;
  duration: string;
  size: number;
  histogram: string;
  LoadingComponent: () => ReactElement;
}

export const VoicePlayer: FC<IVoicePlayerProps> = observer((props) => {
  const {
    groupId,
    messageId,
    payloadId,
    clientUuid,
    src,
    duration,
    size,
    histogram,
    isLoading,
    LoadingComponent,
  } = props;

  const {
    isPlaying,
    isActiveTrack,
    progress,
    progressPercentage,
    formattedDuration,
    onTogglePlay,
    onChangeProgress,
  } = useVoicePresenter({
    groupId,
    messageId,
    payloadId,
    clientUuid,
    duration,
    isLoading,
  });

  if (!src) {
    return null;
  }

  return (
    <div className={styles.audio}>
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <div className={styles.playContainer}>
          <AudioState
            groupId={groupId}
            messageId={messageId}
            payloadId={payloadId}
            iconType={isPlaying ? PlayIconType.Pause : PlayIconType.Play}
            onClick={onTogglePlay}
          />
        </div>
      )}
      <div className={styles.controlsContainer}>
        <VoiceHistogram
          histogram={histogram}
          progress={progressPercentage}
          onChangeProgress={onChangeProgress}
        />

        <AudioPlayerTrackCaption
          className={styles.time}
          isPlaying={isActiveTrack}
          duration={formattedDuration}
          progress={progress}
          size={size}
        />
      </div>
    </div>
  );
});
