import { useLocalStore } from 'mobx-react';

import Entities from 'APP/store';

export const VIDEO_TYPE = {
  SCREEN: 'screen',
  CAMERA: 'camera',
};

const sortStreamers =
  (priority) =>
  ({ streamer: first }, { streamer: second }) => {
    if (priority.includes(first.uid)) {
      return -1;
    }

    if (priority.includes(second.uid)) {
      return 1;
    }

    const usernameFirst = first.username.toUpperCase();
    const usernameSecond = second.username.toUpperCase();
    if (usernameFirst < usernameSecond) {
      return -1;
    }
    if (usernameFirst > usernameSecond) {
      return 1;
    }

    return 0;
  };

export default ({ onSetActiveStreamer }) => {
  const presenter = useLocalStore(() => ({
    isShowStreamers: false,

    get priority() {
      return [
        presenter.stream.initiatorUid,
        presenter.stream.activeStreamer.streamer.isSharedScreen
          ? presenter.stream.activeStreamer.streamer.uid
          : false,
        presenter.stream.uid,
      ].filter(Boolean);
    },

    toggleShowStreamers() {
      this.isShowStreamers = !this.isShowStreamers;
    },

    get stream() {
      return Entities.ActiveStream.stream;
    },

    get streamers() {
      return (
        presenter.stream.streamers
          .reduce((acc, streamer) => {
            const isActiveScreenSharing = presenter.stream.activeStreamer.isSharedScreen;
            const isActiveStreamer = streamer.uid === presenter.stream.activeStreamer.streamer.uid;

            if (!isActiveStreamer || (isActiveStreamer && isActiveScreenSharing)) {
              acc.push({
                type: VIDEO_TYPE.CAMERA,
                streamer,
                setActive: () => {
                  onSetActiveStreamer(streamer.uid, false);
                },
              });
            }

            if (streamer.isSharedScreen && !isActiveScreenSharing) {
              acc.push({
                type: VIDEO_TYPE.SCREEN,
                streamer,
                setActive: () => {
                  onSetActiveStreamer(streamer.uid, true);
                },
              });
            }

            return acc;
          }, [])
          .slice()
          .sort(sortStreamers(presenter.priority)) || []
      );
    },
  }));

  return presenter;
};
