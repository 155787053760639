import Tasks from 'APP/Tasks';
import { AudioSourceType } from 'APP/constants/app';
import { FinishCallReason } from 'APP/model/call/callModel.constants';
import { callModel } from 'APP/model/model';
import logger from 'APP/packages/logger';
import Entities from 'APP/store';

export const finishAll = async (params: { groupId: string }): Promise<void> => {
  try {
    const call = Entities.Calls.getCallByGroupId(params.groupId);

    if (!call) {
      return;
    }

    if (call.channelId) {
      Tasks.feedback.checkStarsFeedback(call.channelId);
    }

    call.delete();

    Tasks.app.audioSource.removeSource(AudioSourceType.Call);

    if (call.channelId) {
      await callModel.finishCall(call.channelId, call.providerType, FinishCallReason.FinishAll);
    }
  } catch (e) {
    logger.get('API').error('calls.finishAll', e);
  }
};
