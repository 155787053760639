import classNames from 'classnames';
import React, { FC, ElementType, ReactEventHandler } from 'react';

import styles from './NavigationTab.styles.m.css';

interface INavigationTabProps {
  Icon?: ElementType;
  Badge?: ElementType;
  label: string;
  isActive?: boolean;
  dataTest?: string;
  onClick: ReactEventHandler<HTMLButtonElement>;
}

export const NavigationTab: FC<INavigationTabProps> = (props) => {
  const { Icon = null, Badge = null, label, isActive = false, dataTest = '', onClick } = props;

  const containerClass = classNames(styles.container, {
    [styles.active]: isActive,
  });

  return (
    <button
      className={containerClass}
      data-test={dataTest}
      onClick={onClick}
    >
      <span className={styles.iconContainer}>
        {Icon && <Icon className={styles.icon}></Icon>}
        {Badge && <Badge className={styles.badge} />}
      </span>

      <span className={styles.label}>{label}</span>
    </button>
  );
};
