import { useEffect, useRef } from 'react';

import { usePreviousValue } from 'APP/packages/hooks/usePreviousValue';
import { IChipsItem } from 'UIKIT/Chips/ChipsItem/ChipsItem.types';

export const useScrollToTheEnd = (chips: IChipsItem[]) => {
  const prevChips = usePreviousValue(chips);
  const chipsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (prevChips && chips.length > prevChips.length) {
      const { childNodes } = chipsRef.current as HTMLDivElement;

      (childNodes.item(childNodes.length - 1) as HTMLElement).scrollIntoView({
        inline: 'end',
        behavior: 'auto',
      });
    }
  }, [chips.length, prevChips]);

  return {
    chipsRef,
  };
};
