import { useLocalStore } from 'mobx-react';

import { useTranslation } from 'APP/packages/translations';
import { RouterState } from 'APP/router/constants';
import useClearGetParameters from 'APP/router/hooks/useClearGetParameters';
import useGetState from 'APP/router/hooks/useGetState';
import Entities from 'APP/store';

export default () => {
  const { t } = useTranslation();
  const clearGetParameters = useClearGetParameters();
  const groupId = useGetState(RouterState.groupId);
  const presenter = useLocalStore(() => ({
    groupId,

    get title() {
      return t('shared_media');
    },

    get group() {
      return Entities.GroupsStore.getGroupById(groupId);
    },

    get isLoading() {
      return Entities.GroupsStore.isLoading || !presenter.group;
    },

    onClose: clearGetParameters,
  }));

  return presenter;
};
