import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import Entities from 'STORE';
import { Space } from 'STORE/Spaces/Space';
import { User } from 'STORE/Users/User/User';

interface ISpaceBlackListPresenter {
  users: User[];
  cursor: string | null;
  isLoading: boolean;
  space: Space | null;
  hasMore: boolean;
  hasBackButton: boolean;
  loadMore(): Promise<void>;
  onUnbanUser(userId: string): void;
  onClose(): void;
  onBack(): void;
}

export interface ISpaceBlackListPopupParams {
  spaceId: string;
}

export function useSpaceBlackListPresenter({
  params,
  popupInstance,
  onClose,
  onBack,
}: ICommonPopupsProps<ISpaceBlackListPopupParams>): ISpaceBlackListPresenter {
  const { spaceId } = params!;
  const presenter = useLocalStore<ISpaceBlackListPresenter>(() => ({
    users: [],
    cursor: null,
    isLoading: false,

    get space(): Space | null {
      return spaceId ? Entities.spacesStore.getById(spaceId) : null;
    },

    async loadMore(): Promise<void> {
      if (!presenter.space) {
        return;
      }

      presenter.isLoading = true;

      const spaceBannedUsers = await Tasks.space.getSpaceBannedUsers({
        spaceId: presenter.space.id,
        cursor: presenter.cursor || '',
      });

      if (spaceBannedUsers) {
        const { users, cursor } = spaceBannedUsers;
        presenter.users.push(...users);
        presenter.cursor = cursor;
      }

      presenter.isLoading = false;
    },

    get hasMore(): boolean {
      return !!presenter.cursor;
    },

    get hasBackButton(): boolean {
      return !!onBack;
    },

    onUnbanUser(userId: string): void {
      presenter.users = presenter.users.filter((user) => user.id !== userId);
    },

    onClose(): void {
      popupInstance.close();
      onClose?.();
    },

    onBack(): void {
      popupInstance.close();
      onBack?.();
    },
  }));

  useEffect(() => {
    if ((!Entities.spacesStore.isLoading && !presenter.space) || !presenter.space?.isOwner) {
      presenter.onClose();
    }
  }, [presenter.space, presenter.space?.role, Entities.spacesStore.isLoading]);

  useEffect(() => {
    if (presenter.space) {
      presenter.loadMore();
    }
  }, [presenter.space]);

  return presenter;
}
