import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { withPopupLayer } from 'MAIN/Popups/withPopupLayer';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';
import { FullscreenPopup } from 'UIKIT/FullscreenPopup/FullscreenPopup';

import { ArticleRedactor } from './ArticleRedactor/ArticleRedactor';
import DraftHeader from './DraftHeader';
import Header from './Header';
import usePresenter from './presenter';
import styles from './styles.m.css';

// TODO: Should Be refactoring
const Article = ({ groupId }) => {
  const { core, ref } = usePresenter({ groupId });

  return (
    <FullscreenPopup>
      {core.isLoading ? (
        <>
          {core.draft ? <DraftHeader core={core} /> : <Header core={core} />}
          <div
            ref={ref}
            className={classNames(styles.container, customScrollStyles.container)}
          >
            <div
              className={styles.content}
              data-test={'articles'}
            >
              <ArticleRedactor core={core} />
            </div>
          </div>
        </>
      ) : null}
    </FullscreenPopup>
  );
};

export default withPopupLayer(observer(Article));
