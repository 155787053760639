import { observer } from 'mobx-react';
import React from 'react';

import { IconView } from 'APP/packages/UiKit/IconView/IconView';
import IcHide from 'ICONS/ic-hidden-left.svg';
import IcShow from 'ICONS/ic-open-right.svg';
import { OpponentVideo } from 'MAIN/OngoingCall/Shared/OpponentVideo/OpponentVideo';
import Entities from 'STORE';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';

import { Opponents } from './Opponents/Opponents';
import { SharedScreen } from './SharedScreen/SharedScreen';
import styles from './SpeakerVideo.styles.m.css';

export const SpeakerVideo = observer(() => {
  const ongoingCall = Entities.OngoingCall;
  const activeOpponent = ongoingCall.presentVideoMode.activeVideoOpponent;

  return (
    <div className={styles.container}>
      <Opponents />
      <div className={styles.speaker}>
        <button
          onClick={ongoingCall.presentVideoMode.toggleShowOpponents}
          className={styles.toggleOpponents}
        >
          <IconView
            Icon={ongoingCall.presentVideoMode.isShowOpponents ? IcHide : IcShow}
            className={styles.icon}
          />
        </button>
        {!!activeOpponent && activeOpponent.isSharedScreen && activeOpponent.member.isMe && (
          <SharedScreen />
        )}

        {!!activeOpponent && (!activeOpponent.isSharedScreen || !activeOpponent.member.isMe) && (
          <OpponentVideo
            key={activeOpponent.uid}
            opponent={activeOpponent}
            avatarType={AvatarTypes.Round_50}
            containerClassName={styles.videoContainer}
          />
        )}
      </div>
    </div>
  );
});
