import { useLocalStore } from 'mobx-react';
import React, { ReactNode } from 'react';

import { CallEventTypes } from 'APP/constants/calls';
import {
  PermissionsPersonalizedValue,
  PermissionsTypes,
  PermissionsValue,
} from 'APP/model/callPermissions/callPermissionsModel.constants';
import { ProviderUid } from 'APP/packages/callProviders/callProviders.types';
import dateService from 'APP/packages/date';
import { useTranslation } from 'APP/packages/translations';
import Entities from 'STORE';
import { Call } from 'STORE/Calls/Call/Call';

import {
  PERMISSIONS_GLOBAL_MEMBER_NOTIFICATIONS_TRANSLATIONS,
  PERMISSIONS_MEMBER_NOTIFICATIONS_TRANSLATIONS,
  PERMISSIONS_NOTIFICATIONS_TRANSLATIONS,
  PERMISSIONS_REQUEST_TRANSLATIONS,
  PERMISSIONS_REQUESTS_DECLINED_TRANSLATIONS,
} from './EventsBar.constants';
import { RiseHand } from './components/RiseHand/RiseHand';

interface IItem {
  text: ReactNode;
  timestamp: number;
}

interface IEventsBarPresenter {
  lastEvent: number;
  setLastEvent(timestamp: number): void;
  items: IItem[];
  ongoingCall: Call;
}

interface IListItem {
  type: string;
  timestamp: number;
  initiatorId?: string;
  uid?: ProviderUid;
  userId?: string;
  permissionType?: PermissionsTypes;
  requestType?: PermissionsTypes;
  [key: string]: any;
}

// ToDO - need to refactor after rewriting Events store to TS

export const useEventsBarPresenter = (): IEventsBarPresenter => {
  const { t } = useTranslation();

  const presenter = useLocalStore<IEventsBarPresenter>(() => ({
    lastEvent: +dateService.now(),

    get ongoingCall(): Call {
      return Entities.Calls.ongoingCall!;
    },

    get items(): IItem[] {
      const call = presenter.ongoingCall;
      const list = call.events.list as unknown as IListItem[];
      const filteredList = list.filter((data) => data.timestamp > presenter.lastEvent);
      const eventItems = filteredList.map((data) => {
        let text: ReactNode = '';
        switch (data.type) {
          case CallEventTypes.ForceMute: {
            const initiator = call.members.getMemberById(data.initiatorId!);

            const user = call.members.getMemberById(data.userId!);

            if (!user || !initiator) {
              return null;
            }

            text = t('calls_force_mute_description', {
              0: initiator.displayName,
              1: user.displayName,
            });
            break;
          }
          case CallEventTypes.Finished: {
            if (!data.uid) {
              return null;
            }

            const member = call.members.getMemberByUid(data.uid);
            if (!member) {
              return null;
            }
            text = t('call_left_description', { 0: member.displayName });
            break;
          }
          case CallEventTypes.Joined: {
            const member = call.members.getMemberByUid(data.uid!);
            if (!member) {
              return null;
            }
            text = t('calls_joined_description', { 0: member.displayName });
            break;
          }
          case CallEventTypes.RaiseHand: {
            const user = call.members.getMemberById(data.userId!);
            if (!user) {
              return null;
            }

            text = <RiseHand member={user} />;
            break;
          }
          case CallEventTypes.Added: {
            const user = call.members.getMemberById(data.userId!);
            if (!user) {
              return null;
            }
            text = t('calls_user_added', { 0: user.displayName });
            break;
          }
          case CallEventTypes.Removed: {
            const initiator = call.members.getMemberById(data.initiatorId!);
            const user = call.members.getMemberById(data.userId!);
            if (!user || !initiator) {
              return null;
            }
            text = t('calls_user_removed_from_chat', {
              0: initiator.displayName,
              1: user.displayName,
            });
            break;
          }
          case CallEventTypes.ForceFinish: {
            const initiator = call.members.getMemberById(data.initiatorId!);
            const user = call.members.getMemberById(data.userId!);
            if (!user || !initiator || user === initiator) {
              return null;
            }
            text = t('calls_user_removed_from_call', {
              0: initiator.displayName,
              1: user.displayName,
            });
            break;
          }
          case CallEventTypes.PermissionsGlobalChanged: {
            if (!data.permissionType) {
              return null;
            }
            const translationTypeObject =
              PERMISSIONS_NOTIFICATIONS_TRANSLATIONS[data.permissionType];

            const translationValue = translationTypeObject
              ? translationTypeObject[data.permissionValue as PermissionsValue]
              : '';

            text = t(translationValue);
            break;
          }
          case CallEventTypes.PermissionsGlobalMemberChanged: {
            if (!data.permissionType) {
              return null;
            }
            const translationTypeObject =
              PERMISSIONS_GLOBAL_MEMBER_NOTIFICATIONS_TRANSLATIONS[data.permissionType];
            const translationValue = translationTypeObject
              ? translationTypeObject[data.permissionValue as PermissionsPersonalizedValue]
              : '';

            text = t(translationValue);
            break;
          }
          case CallEventTypes.PermissionsMemberChanged: {
            if (!data.permissionType) {
              return null;
            }
            const translationTypeObject =
              PERMISSIONS_MEMBER_NOTIFICATIONS_TRANSLATIONS[data.permissionType];
            const translationValue = translationTypeObject
              ? translationTypeObject[data.permissionValue as PermissionsPersonalizedValue]
              : '';

            text = t(translationValue);
            break;
          }
          case CallEventTypes.PermissionsRequestDeclined: {
            if (!data.permissionType) {
              return null;
            }
            text = t(PERMISSIONS_REQUESTS_DECLINED_TRANSLATIONS[data.permissionType]);
            break;
          }
          case CallEventTypes.PermissionRequestSent: {
            text = t('call_permissions_request_sent');
            break;
          }
          case CallEventTypes.PermissionRequestAlreadySent: {
            text = t('call_permissions_request_already_exists');
            break;
          }
          case CallEventTypes.PermissionsRequestCreated: {
            const user = call.members.getMemberById(data.userId!);

            if (!user || !data.requestType) {
              return null;
            }

            text = t(PERMISSIONS_REQUEST_TRANSLATIONS[data.requestType], { 0: user.displayName });
            break;
          }
        }

        return { text, timestamp: data.timestamp };
      });

      // ToDO - need to refactor after rewriting Events store to TS
      return eventItems.filter((item) => item !== null) as IItem[];
    },

    setLastEvent(timestamp: number): void {
      presenter.lastEvent = timestamp;
    },
  }));

  return presenter;
};
