import { AckRewardsRequest } from 'APP/packages/api/types/model/ackRewardsRequest';
import { AcquireRewardRequest } from 'APP/packages/api/types/model/acquireRewardRequest';
import { BooleanResponse } from 'APP/packages/api/types/model/booleanResponse';
import { CreateUserProfileRequest } from 'APP/packages/api/types/model/createUserProfileRequest';
import { RewardsProgressResponse } from 'APP/packages/api/types/model/rewardsProgressResponse';
import { SelfPrivacySettingsResponse } from 'APP/packages/api/types/model/selfPrivacySettingsResponse';
import { SelfUserDisplayDataResponse } from 'APP/packages/api/types/model/selfUserDisplayDataResponse';
import { SetPrivacySettingsRequest } from 'APP/packages/api/types/model/setPrivacySettingsRequest';
import { SetSessionsTtlRequest } from 'APP/packages/api/types/model/setSessionsTtlRequest';
import { UpdateUserProfileRequest } from 'APP/packages/api/types/model/updateUserProfileRequest';
import { UserDevicesResponse } from 'APP/packages/api/types/model/userDevicesResponse';
import { ValidateNickNameRequest } from 'APP/packages/api/types/model/validateNickNameRequest';
import { ValidateNickNameResponse } from 'APP/packages/api/types/model/validateNickNameResponse';
import { ValidateUserNameRequest } from 'APP/packages/api/types/model/validateUserNameRequest';
import { ValidateUserNameResponse } from 'APP/packages/api/types/model/validateUserNameResponse';

import { request } from '../request/request';

const ROOT_API = 'usersettings';

export const updateUserProfile = (
  data: UpdateUserProfileRequest
): Promise<SelfUserDisplayDataResponse> => {
  return request<SelfUserDisplayDataResponse>({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/updateUserProfile',
    method: 'POST',
    body: data,
  });
};

export const validateNickName = (
  data: ValidateNickNameRequest
): Promise<ValidateNickNameResponse> => {
  return request<ValidateNickNameResponse>({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/validateNickName',
    method: 'POST',
    body: data,
  });
};

export const validateUserName = (
  data: ValidateUserNameRequest
): Promise<ValidateUserNameResponse> => {
  return request<ValidateUserNameResponse>({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/validateUserName',
    method: 'POST',
    body: data,
  });
};

export const getPrivacySettings = (): Promise<SelfPrivacySettingsResponse> => {
  return request<SelfPrivacySettingsResponse>({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/getPrivacySettings',
    method: 'POST',
  });
};

export const getRewardsProgress = (): Promise<RewardsProgressResponse> => {
  return request<RewardsProgressResponse>({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/getRewardsProgress',
    method: 'POST',
  });
};

export const setPrivacySettings = (
  data: SetPrivacySettingsRequest
): Promise<SelfPrivacySettingsResponse> => {
  return request<SelfPrivacySettingsResponse>({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/setPrivacySettings',
    method: 'POST',
    body: data,
  });
};

export const createUserProfile = (
  data: CreateUserProfileRequest
): Promise<SelfUserDisplayDataResponse> => {
  return request<SelfUserDisplayDataResponse>({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/createUserProfile',
    method: 'POST',
    body: data,
  });
};

export const deleteAccount = (): Promise<BooleanResponse> => {
  return request<BooleanResponse>({
    rootApi: ROOT_API,
    url: '/deleteAccount',
    method: 'POST',
    version: 'v2',
  });
};

export const getDevicesInfo = (): Promise<UserDevicesResponse> => {
  return request<UserDevicesResponse>({
    rootApi: ROOT_API,
    url: '/getDevices',
    method: 'POST',
    version: 'v2',
  });
};

export const ackRewards = (data: AckRewardsRequest): Promise<BooleanResponse> => {
  return request<BooleanResponse>({
    rootApi: ROOT_API,
    url: '/ackRewards',
    method: 'POST',
    version: 'v2',
    body: data,
  });
};

export const acquireReward = (data: AcquireRewardRequest): Promise<BooleanResponse> => {
  return request<BooleanResponse>({
    rootApi: ROOT_API,
    url: '/acquireReward',
    method: 'POST',
    version: 'v2',
    body: data,
  });
};

export const setSessionsTtl = (data: SetSessionsTtlRequest): Promise<BooleanResponse> => {
  return request<BooleanResponse>({
    rootApi: ROOT_API,
    url: '/setSessionsTtl',
    method: 'POST',
    version: 'v2',
    body: data,
  });
};
