import React, { PureComponent } from 'react';

import styles from './styles.m.css';

class FileUploadButton extends PureComponent {
  constructor(props) {
    super(props);
    this.sendObjectInput = React.createRef();
  }

  onChange = (evt) => {
    this.props.onSelectFile(evt.currentTarget.files);
    this.sendObjectInput.current.value = ''; // clear input value to allow same file upload
  };

  open = () => {
    const event = new MouseEvent('click');

    // workaround to fix issue with adding input into button element
    // FF doesn't allows it
    this.sendObjectInput.current.dispatchEvent(event);
  };

  render() {
    return (
      <input
        className={styles.input}
        type="file"
        multiple
        id="sendObjectInput"
        ref={this.sendObjectInput}
        onChange={this.onChange}
      />
    );
  }
}

export default FileUploadButton;
