import handleEmail from './handleEmail';
import handleMention from './handleMention';

export default (userMentions) => (text, index, symbolMatches) => {
  handleEmail(text, index, symbolMatches);

  if (userMentions && userMentions.length > 0) {
    handleMention(userMentions, text, index, symbolMatches);
  }
};
