/* global Date */
import differenceInDay from './differenceInDay';
import differenceInSeconds from './differenceInSeconds';
import format from './format';
import isLastWeek from './isLastWeek';
import isToday from './isToday';
import isValidDate from './isValidDate';
import isYesterday from './isYesterday';
import { now } from './now';

export default (rawFormatDate, t) => {
  if (!rawFormatDate) {
    return null;
  }

  const date = new Date(rawFormatDate);
  if (!isValidDate(date)) {
    return null;
  }

  if (isToday(date)) {
    const minutesAgo = differenceInSeconds(date, now()) / 60;
    if (minutesAgo < 60) {
      return t('news_feed_minutes_ago').replace('{0}', Math.floor(minutesAgo));
    } else {
      return t('news_feed_hours_ago').replace('{0}', Math.floor(minutesAgo / 60));
    }
  }

  if (isYesterday(date)) {
    return t('common_yesterday');
  }

  if (isLastWeek(date)) {
    const daysAgo = differenceInDay(date, now());
    if (daysAgo < 7) {
      return t('news_feed_days_ago').replace('{0}', daysAgo);
    } else {
      return t('news_feed_week_ago');
    }
  }

  return format(date, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};
