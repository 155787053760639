import { isEqualArrays } from './isEqualArrays';

export const isEqualObjects = (obj1: Record<string, any>, obj2: Record<string, any>): boolean => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (!isEqualArrays(keys1, keys2, false)) {
    return false;
  }

  return !keys1.some((key): boolean => {
    const elem1 = obj1[key];
    const elem2 = obj2[key];

    if (typeof elem1 !== typeof elem2) {
      return true;
    }

    if (Array.isArray(elem1)) {
      return !isEqualArrays(elem1, elem2);
    }

    if (elem1 instanceof Function) {
      return elem1 !== elem2;
    }

    if (elem1 instanceof Object && elem1 && elem2) {
      if (elem1.constructor !== elem2.constructor) {
        return true;
      }

      if (elem1 instanceof Date) {
        return elem1.getTime() !== elem2.getTime();
      }

      return !isEqualObjects(elem1, elem2);
    }

    return elem1 !== elem2;
  });
};
