import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { EntireScreenButton } from 'APP/components/EntireScreenButton/EntireScreenButton';
import { CallActionsPanel } from 'MAIN/OngoingCall/Shared/CallActionsPanel/CallActionsPanel';
import { CallState } from 'MAIN/OngoingCall/Shared/CallState/CallState';
import { FullScreenButton } from 'MAIN/OngoingCall/Shared/FullScreenButton/FullScreenButton';
import { PermissionRequestButton } from 'MAIN/OngoingCall/Shared/PermissionRequestsButton/PermissionRequestButton';
import { RecordingPlaceholder } from 'MAIN/OngoingCall/Shared/RecordingPlaceholder/RecordingPlaceholder';
import { ShowMoreButton } from 'MAIN/OngoingCall/Shared/ShowMoreButton/ShowMoreButton';

import { useFullScreenCallViewPanelPresenter } from './FullScreenCallViewPanel.presenter';
import styles from './FullScreenCallViewPanel.styles.m.css';

interface IPanelProps {
  hasTitle?: boolean;
}

export const FullScreenCallViewPanel: FC<IPanelProps> = observer(({ hasTitle }) => {
  const presenter = useFullScreenCallViewPanelPresenter();

  return (
    <div className={styles.container}>
      {hasTitle && (
        <div className={styles.info}>
          <div className={styles.title}>{presenter.callTitle}</div>
          <div className={styles.callState}>
            <CallState call={presenter.ongoingCall} />
          </div>
        </div>
      )}
      <div className={styles.actionsPanel}>
        <CallActionsPanel />
      </div>
      <div className={styles.additionalActions}>
        {presenter.isRecording && <RecordingPlaceholder className={styles.additionalActionsGap} />}
        {presenter.canShowPermissionsRequestsButton && (
          <PermissionRequestButton className={styles.additionalActionsGap} />
        )}
        {!presenter.isGuestCall && (
          <>
            <FullScreenButton className={styles.additionalActionsGap} />
            <EntireScreenButton className={styles.additionalActionsGap} />
          </>
        )}
        <ShowMoreButton className={styles.additionalActionsGap} />
      </div>
    </div>
  );
});
