import { request } from '../request/request';

const ROOT_API = 'streams';

export const startStream = (data) => {
  return request({
    rootApi: ROOT_API,
    url: '/startStream',
    method: 'POST',
    body: data,
  });
};

export const updateStreamInfo = (data) => {
  return request({
    rootApi: ROOT_API,
    url: '/updateStreamInfo',
    method: 'POST',
    body: data,
  });
};

export const getStreamInfo = (data) => {
  return request({
    rootApi: ROOT_API,
    url: '/getStreamInfo',
    method: 'POST',
    body: data,
  });
};

export const leaveFromStream = (data) => {
  return request({
    rootApi: ROOT_API,
    url: '/leaveFromStream',
    method: 'POST',
    body: data,
  });
};

export const finishStream = (data) => {
  return request({
    rootApi: ROOT_API,
    url: '/finishStream',
    method: 'POST',
    body: data,
  });
};

export const streamPostprocessing = (data) => {
  return request({
    rootApi: ROOT_API,
    url: '/streamPostprocessing',
    method: 'POST',
    body: data,
  });
};

export const startScreenSharing = (data) => {
  return request({
    rootApi: ROOT_API,
    url: '/startScreenSharing',
    method: 'POST',
    body: data,
  });
};

export const stopScreenSharing = (data) => {
  return request({
    rootApi: ROOT_API,
    url: '/stopScreenSharing',
    method: 'POST',
    body: data,
  });
};

export const actionOnStream = (data) => {
  return request({
    rootApi: ROOT_API,
    url: '/actionOnStream',
    method: 'POST',
    body: data,
  });
};
