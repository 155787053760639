import api from 'APP/packages/api';

import updatePrivacySettingsLocally from '../updatePrivacySettingsLocally';

export default async () => {
  try {
    const response = await api.userSettings.getPrivacySettings();

    updatePrivacySettingsLocally(response);
  } catch (error) {
    console.error('Task: Privacy Settings error', error);
  }
};
