import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, ReactElement } from 'react';
import { FormSpy } from 'react-final-form';

import { IMAGE_TYPE } from 'APP/constants';
import DropDown from 'APP/packages/form/DropDown';
import { Field } from 'APP/packages/form/Field/Field';
import { Form } from 'APP/packages/form/Form/Form';
import { IFormContentProps } from 'APP/packages/form/Form/Form.types';
import ImageUploader from 'APP/packages/form/ImageUploader';
import MultiLineField from 'APP/packages/form/MultiLineField';
import RadioGroup from 'APP/packages/form/RadioGroup';
import { TextField } from 'APP/packages/form/TextField/TextField';
import { useTranslation } from 'APP/packages/translations';
import { Avatar } from 'UIKIT/Avatar/Avatar';
import { AvatarTypes, TAvatarBackgroundColorIndex } from 'UIKIT/Avatar/Avatar.types';
import { Button } from 'UIKIT/Button/Button';
import { ButtonTypes } from 'UIKIT/Button/Button.types';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';
import { PopupFooter } from 'UIKIT/PopupFooter/PopupFooter';

import { SpaceDeepLinkAliasField } from './SpaceDeepLinkAliasField/SpaceDeepLinkAliasField';
import { useSpaceFormPresenter, ISpaceFormData, CreateSpaceStep } from './SpaceForm.presenter';
import styles from './SpaceForm.styles.m.css';

const FORM_ID = 'space-form';
const SPACE_NAME_MAX_LENGTH = 35;
const SPACE_DESCRIPTION_MAX_LENGTH = 400;

interface ISpaceFormProps {
  initialValues: ISpaceFormData;
  avatarTitle?: string;
  avatarColor?: TAvatarBackgroundColorIndex | null;
  isEditMode?: boolean;
  step?: CreateSpaceStep;
  onStepChange?(step: CreateSpaceStep): void;
  onFormStateChange(state: Partial<IFormContentProps>): void;
  onSubmit(data: ISpaceFormData): Promise<void>;
}

export const SpaceForm: FC<ISpaceFormProps> = observer(
  ({
    initialValues,
    avatarTitle,
    avatarColor,
    isEditMode = false,
    step: newStep,
    onStepChange,
    onFormStateChange,
    onSubmit,
  }) => {
    const { t } = useTranslation();
    const presenter = useSpaceFormPresenter({ initialValues, isEditMode, newStep, onStepChange });

    return (
      <Form
        initialValues={presenter.initialValues}
        subscription={{
          submitting: true,
          pristine: true,
          invalid: true,
          validating: true,
        }}
        onSubmit={presenter.isShowSubmit ? onSubmit : presenter.onShowAdditionalInfo}
      >
        {({
          handleSubmit,
          submitting,
          pristine,
          invalid,
          validating,
        }: IFormContentProps): ReactElement => (
          <>
            <FormSpy
              subscription={{ submitting: true, pristine: true }}
              onChange={onFormStateChange}
            />

            <form
              id={FORM_ID}
              className={classNames(styles.form, customScrollStyles.container)}
              onSubmit={handleSubmit}
            >
              <div
                className={classNames(
                  presenter.isShowGeneralInfo ? null : styles.hide,
                  isEditMode ? styles.sectionIndent : null
                )}
              >
                <Field
                  name="avatarUrl"
                  component={ImageUploader}
                  imageType={IMAGE_TYPE.SQUARE}
                  customNoImage={
                    avatarTitle &&
                    avatarColor && (
                      <Avatar
                        type={AvatarTypes.Square_50}
                        title={avatarTitle}
                        backgroundColorIndex={avatarColor}
                      />
                    )
                  }
                />

                <Field
                  className={styles.textField}
                  name="name"
                  label={t('space_name_hint')}
                  isNeedTrim
                  component={TextField}
                  maxLength={SPACE_NAME_MAX_LENGTH}
                  validate={presenter.nameValidate}
                />

                <Field
                  className={styles.textField}
                  name="categoryId"
                  label={t('chat_details_category_label')}
                  component={DropDown}
                  options={presenter.categories}
                  validate={presenter.categoryValidate}
                />

                <Field
                  isNeedTrim
                  name="description"
                  label={t('space_description_hint')}
                  component={MultiLineField}
                  maxLength={SPACE_DESCRIPTION_MAX_LENGTH}
                />
              </div>

              <div className={presenter.isShowAdditionalInfo ? null : styles.hide}>
                <RadioGroup
                  className={styles.radioGap}
                  name="type"
                  title={t('space_type')}
                  options={presenter.typeOptions}
                  validateFields={['deepLinkAlias']}
                />

                <Field
                  isNeedTrim
                  name="deepLinkAlias"
                  component={SpaceDeepLinkAliasField}
                  validate={presenter.aliasValidate}
                  key={presenter.isShowAdditionalInfo ? 1 : 0}
                />
              </div>
            </form>

            <PopupFooter>
              <Button
                type={ButtonTypes.Submit}
                form={FORM_ID}
                isLoading={submitting}
                isDisabled={pristine || invalid || validating}
                theme="1"
              >
                {presenter.isShowSubmit ? t('common_save') : t('action_next')}
              </Button>
            </PopupFooter>
          </>
        )}
      </Form>
    );
  }
);
