import { useCallback, useEffect, useState } from 'react';

import { IShareSelection } from 'APP/types/share';
import { replaceHtmlTagsToSpecialSymbols } from 'UTILS/html/replaceHtmlTagsToSpecialSymbols';

interface IForwardMessageFooterViewPresenterProps {
  selection: IShareSelection[];
  onSubmit(comment: string): void;
}

interface IForwardMessageFooterViewPresenter {
  commentValue: string;
  onChange(comment: string): void;
  handleSubmit(): void;
}

export const useForwardMessageFooterViewPresenter = ({
  onSubmit,
  selection,
}: IForwardMessageFooterViewPresenterProps): IForwardMessageFooterViewPresenter => {
  const [commentValue, setCommentValue] = useState('');

  useEffect(() => {
    if (!selection.length) {
      setCommentValue('');
    }
  }, [selection.length]);

  const handleSubmit = useCallback((): void => {
    onSubmit(replaceHtmlTagsToSpecialSymbols(commentValue));
  }, [onSubmit, commentValue]);

  const onChange = useCallback(
    (value: string): void => {
      setCommentValue(value);
    },
    [setCommentValue]
  );

  return { onChange, handleSubmit, commentValue };
};
