import classNames from 'classnames';
import React, { memo } from 'react';

import styles from './styles.m.scss';

const FullscreenPopupHeader = ({ children, className }) => {
  return <header className={classNames(className, styles.header)}>{children}</header>;
};

export default memo(FullscreenPopupHeader);
