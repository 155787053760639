function setItem<T extends string>(keyName: string, value: T): void {
  localStorage.setItem(keyName, value);
}

function getItem<T extends string>(keyName: string): T | null {
  return localStorage.getItem(keyName) as T | null;
}

function removeItem(keyName: string): void {
  localStorage.removeItem(keyName);
}

export const localStorageInstance = {
  setItem,
  getItem,
  removeItem,
};
