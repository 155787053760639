import { TFunction } from 'react-i18next';

import {
  TSystemMessageTextHandler,
  ISystemMessageText,
  ISystemMessageTextPresenter,
} from '../useSystemMessageText.types';

export const chatRemovedFromSpaceHandler: TSystemMessageTextHandler = (
  presenter: ISystemMessageTextPresenter,
  t: TFunction
): ISystemMessageText => {
  const text = presenter.isChannel
    ? t('space_channel_removed', { 0: presenter.spaceName, interpolation: { escapeValue: false } })
    : t('space_group_removed', { 0: presenter.spaceName, interpolation: { escapeValue: false } });

  return { html: text };
};
