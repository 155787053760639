import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useRef } from 'react';

import BackIcon from 'ICONS/ic-back.svg';
import CloseIcon from 'ICONS/ic-close-s.svg';
import { useProfileView } from 'MAIN/ChatView/hooks/profileView/useProfileView';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';
import { IconButton } from 'UIKIT/IconButton';

import commonStyles from '../styles.m.css';
import styles from './styles.m.css';

const ProfileView = ({ groupId, onClose }) => {
  const scrollNodeRef = useRef(null);
  const profileView = useProfileView({
    groupId,
    onClose,
    scrollNodeRef,
  });

  const Component = profileView.Component;
  const props = profileView.props;

  return (
    <div className={commonStyles.container}>
      <div className={commonStyles.header}>
        {profileView.hasBackButton && (
          <IconButton
            className={commonStyles.back}
            Icon={BackIcon}
            onClick={profileView.goBack}
            size="big"
            iconSize="medium"
          />
        )}
        <h3 className={commonStyles.title}>{profileView.title}</h3>
        <IconButton
          className={commonStyles.close}
          Icon={CloseIcon}
          onClick={profileView.close}
          size="big"
        />
      </div>
      <div
        className={classNames(commonStyles.body, customScrollStyles.container)}
        ref={scrollNodeRef}
      >
        <Component
          className={classNames({
            [styles.profile]: profileView.isProfileOpened,
            [styles.media]: profileView.isSharedDataOpened,
          })}
          {...props}
        />
      </div>
    </div>
  );
};

export default observer(ProfileView);
