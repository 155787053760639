import React from 'react';

import NoContactImage from 'APP/images/gem-mascot-contacts.svg';
import { useTranslation } from 'APP/packages/translations';

import styles from './styles.m.scss';

const EmptyComponent = ({ isSearchMode }) => {
  const { t } = useTranslation();

  if (isSearchMode) {
    return <div className={styles.noContent}>{t('search_no_results')}</div>;
  }

  return (
    <div className={styles.noContent}>
      <img
        src={NoContactImage}
        width={192}
        height={176}
        alt={''}
        className={styles.img}
      />
      {t('no_contacts_in_gem4me')}
    </div>
  );
};

export default EmptyComponent;
