import { observer } from 'mobx-react';
import React, { FC, HTMLAttributes } from 'react';

import { useDragDropPresenter } from './ChatDragDop.presenter';
import { DropPanel } from './DropPanel/DropPanel';

interface ChatDragDropProps extends HTMLAttributes<HTMLDivElement> {
  isAllowDrop: boolean;
  onDropFiles(files: FileList): void;
}

export const ChatDragDrop: FC<ChatDragDropProps> = observer(
  ({ children, isAllowDrop, onDropFiles, ...htmlDivProps }) => {
    const presenter = useDragDropPresenter({ onDropFiles });

    if (!isAllowDrop) {
      return <div {...htmlDivProps}>{children}</div>;
    }

    return (
      <div
        {...htmlDivProps}
        onDragEnter={presenter.dragEnter}
        onDragLeave={presenter.dragLeave}
        onDragOver={presenter.dragOver}
      >
        {children}
        {presenter.showDropPanel && (
          <DropPanel
            isDragging={presenter.isDragging}
            onDrop={presenter.drop}
          />
        )}
      </div>
    );
  }
);
