import React, { FC, memo } from 'react';

import { useTranslation } from 'APP/packages/translations';
import { Loader } from 'UIKIT/Loader/Loader';

import styles from './ProviderLayoutProcessing.styles.m.css';

export const ProviderLayoutProcessing: FC = memo(() => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <span className={styles.loaderHolder}>
        <Loader className={styles.loader} />
      </span>
      <span>{t('common_processing')}</span>
    </div>
  );
});
