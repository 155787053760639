import React, { FC } from 'react';

import { useTranslation } from 'APP/packages/translations';
import { InfoBlock, InfoBlockTypes } from 'UIKIT/InfoBlock/InfoBlock';

import { friendsSpaceImagesEmptyConfig } from './FriendsSpaceMessagesEmpty.config';

interface IFriendsSpaceMessagesEmptyProps {
  avatarUrl: string;
  hasBackground: boolean;
}

export const FriendsSpaceMessagesEmpty: FC<IFriendsSpaceMessagesEmptyProps> = ({
  avatarUrl,
  hasBackground,
}) => {
  const config = friendsSpaceImagesEmptyConfig[avatarUrl];
  const { t } = useTranslation();

  if (!config) {
    return null;
  }

  return (
    <InfoBlock
      type={hasBackground ? InfoBlockTypes.ChatWithPattern : InfoBlockTypes.Chat}
      title={t(config.titleKey)}
      descriptions={[t(config.descriptionKey)]}
      image={config.image}
    />
  );
};
