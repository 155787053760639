import { setConfigApi } from './config/config';
import { ServerErrorCode } from './constants/constants';
import { request as rawRequest } from './request/request';
import * as authorization from './rest/authorization';
import * as bot from './rest/bot';
import * as botsCatalog from './rest/botsCatalog';
import * as calls from './rest/calls';
import * as callsV2 from './rest/callsV2';
import * as catalog from './rest/catalog';
import * as deeplinks from './rest/deeplinks';
import * as group from './rest/group';
import * as marketplace from './rest/marketplace';
import * as media from './rest/media';
import * as messaging from './rest/messaging';
import * as moderation from './rest/moderation';
import * as push from './rest/push';
import * as reactions from './rest/reactions';
import * as relationships from './rest/relationships';
import * as scheduledEvents from './rest/scheduledEvents';
import * as search from './rest/search';
import * as space from './rest/space';
import * as stickers from './rest/stickers';
import * as streams from './rest/streams';
import * as upload from './rest/upload';
import * as userSettings from './rest/userSettings';

export default {
  authorization,
  messaging,
  group,
  bot,
  calls,
  callsV2,
  deeplinks,
  stickers,
  media,
  push,
  search,
  userSettings,
  upload,
  relationships,
  marketplace,
  botsCatalog,
  catalog,
  streams,
  moderation,
  space,
  reactions,
  scheduledEvents,
};

export { setConfigApi, rawRequest, ServerErrorCode };
