import classNames from 'classnames';
import React, { FC, memo } from 'react';

import AnimatedPartStyles from '../../AnimatedPart/AnimatedPart.styles.m.css';
import useSyncAnimation from '../../hooks/useSyncAnimations';
import styles from './CatalogSearchPlaceholder.styles.m.css';

const BLOCKS_COUNT = 10;

export const CatalogSearchPlaceholder: FC = memo(() => {
  const { isFirstAnimated } = useSyncAnimation({
    firstTimeout: 500,
    secondTimeout: 1000,
  });

  const blocks = Array(BLOCKS_COUNT)
    .fill('block')
    .map((v, i) => `${v}-${i}`);

  const animationClassName = {
    [AnimatedPartStyles.animation]: isFirstAnimated,
  };

  return (
    <div className={styles.container}>
      {blocks.map((id) => (
        <div
          className={styles.block}
          key={id}
        >
          <div className={classNames(styles.image, animationClassName)} />
          <div className={styles.textContainer}>
            <div className={classNames(styles.header, animationClassName)} />
            <div className={classNames(styles.text1, animationClassName)} />
            <div className={classNames(styles.text2, animationClassName)} />
            <div className={classNames(styles.text2, animationClassName)} />
            <div className={classNames(styles.text2, animationClassName)} />
            <div className={classNames(styles.text3, animationClassName)} />
          </div>
        </div>
      ))}
    </div>
  );
});
