import { useLocalStore } from 'mobx-react';

import Entities from 'APP/store';
import { Call } from 'STORE/Calls/Call/Call';
import { Member } from 'STORE/Calls/Call/Member/Member';

interface IMemberListPresenter {
  call: Call;
  members: Member[];
  keyExtractor(member: Member): string;
}

export const useMemberListPresenter = (): IMemberListPresenter => {
  const presenter = useLocalStore<IMemberListPresenter>(() => ({
    get call(): Call {
      return Entities.Calls.ongoingCall!;
    },

    get members(): Member[] {
      return presenter.call.members.memberList || [];
    },

    keyExtractor(member: Member): string {
      return member.userId;
    },
  }));

  return presenter;
};
