import createDraft from './createDraft';
import { deleteDraft } from './deleteDraft/deleteDraft';
import getDraft from './getDraft';
import saveScheduledDraft from './saveScheduledDraft';
import sendDraft from './sendDraft';
import updateDraft from './updateDraft';

export default {
  createDraft,
  deleteDraft,
  getDraft,
  sendDraft,
  updateDraft,
  saveScheduledDraft,
};
