/* global Promise */
import Tasks from 'APP/Tasks';
import Entities from 'APP/store';

import loadUnknownGroupsByIds from '../loadUnknownGroupsByIds';
import { loadUnknownUsersByIds } from '../loadUnknownUsersByIds';
import getIdsFromMessages from './getIdsFromMessages';

export default async ({ messages }, withThreads = true) => {
  const { usersIds, groupsIds } = getIdsFromMessages(messages);

  let messagesWithThreadByGroup = [];
  // get messages with threads by groups
  if (withThreads) {
    messagesWithThreadByGroup = messages.reduce((acc, message) => {
      if (message.threadId) {
        if (acc[message.groupId]) {
          acc[message.groupId].push(message);
        } else {
          acc[message.groupId] = [message];
        }
      }
      return acc;
    }, {});
  }

  const threadInfoRequests = [];
  Object.keys(messagesWithThreadByGroup).forEach((groupId) => {
    const group = Entities.GroupsStore.getGroupById(groupId);
    if (group && group.canLeaveComment) {
      threadInfoRequests.push(
        Tasks.thread.getThreadsInfo({
          messages: messagesWithThreadByGroup[groupId],
          groupId,
        })
      );
    }
  });

  await Promise.all([
    loadUnknownUsersByIds({
      usersIds,
    }),
    loadUnknownGroupsByIds({
      groupsIds,
    }),
    ...threadInfoRequests,
  ]);
};
