import { computed, observable } from 'mobx';

import { PayloadType } from 'APP/model/message/messageModel.types';
import { Payload } from 'STORE/Messages/Message/Payload';

import AudioMessage from '../Audio';
import Contact from '../Contact';
import File from '../File';
import Image from '../Image';
import Location from '../Location';
import RichText from '../Text';
import Video from '../Video';

const MEDIA_TYPES = [PayloadType.Image, PayloadType.Video];

const PayloadClass = {
  Image,
  Video,
  RichText,
  Contact,
  AudioMessage,
  File,
  Location,
};

class Article extends Payload {
  @observable payloads;
  title;

  constructor(data) {
    super(data);

    this.payloads = data.payloads
      .sort((a, b) => a.order - b.order)
      .map(({ id, order, payloadType, payload }) => ({
        id,
        order,
        payloadType,
        payload: new PayloadClass[payload.payloadType]({
          ...payload,
          message: {
            forward: null,
            quotedMessage: null,
            hasError: false,
            setError() {
              this.hasError = true;
            },
          },
        }),
      }));
    this.title = data.title;
    this.userMentions = data.userMentions;
  }

  toJSON() {
    return {
      payloadType: PayloadType.Article,
      title: this.title,
      userMentions: this.userMentions,

      payloads: this.payloads.map((payload, idx) => ({
        id: payload.id,
        payloadType: payload.payloadType,
        order: idx,
        payload: payload.payload.toJSON(),
      })),
    };
  }

  @computed
  get firstMediaPayload() {
    return this.payloads.find(({ payload }) => {
      return MEDIA_TYPES.includes(payload?.payloadType);
    });
  }

  @computed
  get firstTextPayload() {
    return this.payloads.find(({ payload }) => {
      return (
        payload.payloadType === PayloadType.RichText &&
        String(payload?.text || '').trim().length > 0
      );
    });
  }
}

export default Article;
