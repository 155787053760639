import classNames from 'classnames';
import React from 'react';

import CloseLargeIcon from 'ICONS/ic-close-large.svg';
import MiniPlayerOfIcon from 'ICONS/ic-miniplayer-off.svg';
import PauseIcon from 'ICONS/ic-pause.svg';
import PlayIcon from 'ICONS/ic-play.svg';
import { IconButton } from 'UIKIT/IconButton';

import { MediaPlayerProgress } from '../../components/MediaPlayerProgress/MediaPlayerProgress';
import { VideoPlayerType } from '../VideoPlayer.constants';
import { VideoPlayerTime } from '../VideoPlayerTime/VideoPlayerTime';
import styles from './VideoPlayerMiniControls.styles.m.css';

export const VideoPlayerMiniControls = ({
  isPlaying,
  onPlay,
  onPause,
  onTogglePlay,
  progress,
  progressMax,
  progressBuffer,
  onSetProgress,
  onProgressSelection,
  onExpandMiniPlayer,
  onCloseMiniPlayer,
}) => {
  return (
    <div className={styles.controls}>
      <div className={styles.controlsBackground}></div>

      <div className={styles.controlsTop}>
        {onExpandMiniPlayer && (
          <IconButton
            className={styles.actionButton}
            Icon={MiniPlayerOfIcon}
            size="x-small"
            iconSize="medium"
            theme="19"
            onClick={onExpandMiniPlayer}
          />
        )}

        {onCloseMiniPlayer && (
          <IconButton
            className={classNames(styles.actionButton, styles.close)}
            Icon={CloseLargeIcon}
            size="x-small"
            iconSize="medium"
            theme="19"
            onClick={onCloseMiniPlayer}
          />
        )}
      </div>

      <div className={styles.controlsCenter}>
        <IconButton
          className={styles.actionButton}
          Icon={isPlaying ? PauseIcon : PlayIcon}
          size="x-small"
          iconSize="medium"
          theme="19"
          onClick={onTogglePlay}
        />
      </div>

      <div className={styles.controlsBottom}>
        <VideoPlayerTime
          type={VideoPlayerType.Mini}
          progress={progress}
          progressMax={progressMax}
        />

        <MediaPlayerProgress
          className={styles.progress}
          isPlaying={isPlaying}
          progress={progress}
          progressMax={progressMax}
          progressBuffer={progressBuffer}
          onPlay={onPlay}
          onPause={onPause}
          onProgress={onSetProgress}
          onProgressSelection={onProgressSelection}
        />
      </div>
    </div>
  );
};
