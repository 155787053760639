import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { GridLayout } from 'MAIN/OngoingCall/Shared/GridLayout/GridLayout';
import { FULL_GRID_VIEW_SIZE } from 'MAIN/OngoingCall/constants/views';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';

import styles from './FullScreenGridLayout.styles.m.css';

export const FullScreenGridLayout = observer(() => {
  const getClassName = (opponentsLength: number): string => {
    return classNames(
      styles.videoLayout,
      customScrollStyles.container,
      styles[
        `videoLayout${
          opponentsLength > FULL_GRID_VIEW_SIZE ? FULL_GRID_VIEW_SIZE : opponentsLength
        }`
      ]
    );
  };

  return (
    <GridLayout
      getClassName={getClassName}
      avatarLargeType={AvatarTypes.Round_50}
      avatarMediumType={AvatarTypes.Round_40}
    />
  );
});
