import { observer } from 'mobx-react';
import React from 'react';

import { MessageViews } from 'APP/components/MessageViews/MessageViews';
import { ReactionButton } from 'APP/components/ReactionButton/ReactionButton';

import Comments from './Comments';
import styles from './styles.m.css';

const Footer = ({ message }) => {
  if (!message) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <ReactionButton message={message} />
        <Comments message={message} />

        <div className={styles.info}>
          <MessageViews
            message={message}
            className={styles.views}
            showStatus={false}
          />
        </div>
      </div>
    </div>
  );
};

export default observer(Footer);
