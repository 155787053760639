import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import { PayloadType } from 'APP/model/message/messageModel.types';

import { useUploadFiles } from '../presenters/uploadFiles';

const DRAG_AND_DROP_PAYLOADS = [
  PayloadType.Image,
  PayloadType.Video,
  PayloadType.AudioMessage,
  PayloadType.Location,
  PayloadType.File,
];

const DEFAULT_PAYLOAD = PayloadType.RichText;

export default ({ parentPresenter, order, payload, isDragging }) => {
  const uploadFilesPresenter = useUploadFiles({ parentPresenter, order });
  const source = useAsObservableSource({
    payload,
    order,
    isDragging,
    parentPresenter,
  });

  const presenter = useLocalStore(() => ({
    canHover: false,

    onMouseEnter: () => {
      presenter.canHover = true;
    },

    onMouseLeave: () => {
      presenter.canHover = false;
    },

    get canDragAndDrop() {
      if (source.isDragging) {
        return true;
      }

      if (
        source.order === source.parentPresenter.actualFocusPosition &&
        !presenter.isDefaultPayload
      ) {
        return true;
      }

      return (
        (presenter.canHover && source.order !== source.parentPresenter.actualFocusPosition) ||
        (presenter.canHover && DRAG_AND_DROP_PAYLOADS.includes(source.payload.payloadType))
      );
    },

    get canDelete() {
      if (source.order === source.parentPresenter.actualFocusPosition) {
        return true;
      }

      return presenter.canHover;
    },

    get isDefaultPayload() {
      return source.payload.payloadType === DEFAULT_PAYLOAD && source.payload.text === '';
    },
  }));

  useEffect(() => {}, [presenter.canHover, presenter.canDragAndDrop, presenter.canDelete]);

  return { uploadFilesPresenter, presenter };
};
