import { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'APP/packages/translations';
import DoneIcon from 'ICONS/ic-done.svg';
import { usePopover } from 'UIKIT/Popover/usePopover';

import { defaultVideoPlayerConfig } from '../VideoPlayer.constants';

const SPEED_OPTIONS = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];

const getMenuItems = (currentSpeed, onSpeedChange, t) =>
  SPEED_OPTIONS.map((speed) => ({
    key: speed,
    title: speed === defaultVideoPlayerConfig.speed ? t('common_normal') : speed,
    onClick: () => onSpeedChange(speed),
    icon: speed === currentSpeed ? DoneIcon : null,
  }));

export function useVideoPlayerSpeedPresenter({
  currentSpeed,
  onSpeedChange,
  onToggleSpeedPopover,
}) {
  const popover = usePopover();
  const { t } = useTranslation();
  const [menuItems, setMenuItems] = useState([]);

  const togglePopover = useCallback(() => {
    popover.toggle();
    if (onToggleSpeedPopover) {
      onToggleSpeedPopover(!popover.isOpened);
    }
  }, [popover.isOpened, onToggleSpeedPopover]);

  const closePopover = useCallback(() => {
    popover.close();
    if (onToggleSpeedPopover) {
      onToggleSpeedPopover(false);
    }
  }, [popover.isOpened, onToggleSpeedPopover]);

  useEffect(() => {
    setMenuItems(getMenuItems(currentSpeed, onSpeedChange, t));
  }, [currentSpeed, onSpeedChange]);

  return { menuItems, popover, togglePopover, closePopover };
}
