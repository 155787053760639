import { useLocalStore, useAsObservableSource } from 'mobx-react';
import { useEffect, useLayoutEffect } from 'react';

import Tasks from 'APP/Tasks';
import Entities from 'STORE';
import { GlobalVideoPlayerType } from 'STORE/GlobalVideoPlayer/GlobalVideoPlayer.types';

import {
  IMiniPlayerConfig,
  IMiniPlayerProps,
  TConfigUpdateHandler,
  TTogglePlayHandler,
} from '../MiniPlayer.types';

interface IMiniRoundVideoPlayer {
  init(): void;
  destroy(): void;
  isPlayVideo: boolean;
  isPlayAudio: boolean;
  videoProgress: number;
  onConfigUpdate: TConfigUpdateHandler;
  setPlaying: TTogglePlayHandler;
  onPlay(): void;
  onPause(): void;
  onTogglePlay(): void;
  onSpeedChange(speed: number): void;
  onProgressChange(progress: number): void;
  onVideoProgressChange(progress: number): void;
}

export function useMiniRoundVideoPlayerPresenter(data: IMiniPlayerProps): IMiniRoundVideoPlayer {
  const { track, isPlaying, onConfigUpdate, onProgressChange, onTogglePlay } = data;

  const source = useAsObservableSource({ track, isPlaying });

  const presenter = useLocalStore<IMiniRoundVideoPlayer>(() => ({
    init(): void {
      if (!source.track) {
        return;
      }

      Tasks.globalVideoPlayer.initVideoPlayer({
        container: null,
        url: source.track.url,
        previewUrl: source.track.previewUrl,
        type: GlobalVideoPlayerType.MiniRound,
      });
    },

    destroy(): void {
      const roundVideoPlayerTypes = [GlobalVideoPlayerType.Round, GlobalVideoPlayerType.MiniRound];
      if (
        !Entities.GlobalVideoPlayer.type ||
        !roundVideoPlayerTypes.includes(Entities.GlobalVideoPlayer.type)
      ) {
        return;
      }

      Tasks.globalVideoPlayer.closeVideoPlayer();
    },

    get isPlayVideo(): boolean {
      return Entities.GlobalVideoPlayer.isPlaying;
    },

    get isPlayAudio(): boolean {
      return Entities.miniPlayer.isPlaying;
    },

    get videoProgress(): number {
      return Entities.GlobalVideoPlayer.progress;
    },

    setPlaying(isPlaying: boolean): void {
      Entities.GlobalVideoPlayer.setPlaying(isPlaying);
      onTogglePlay(isPlaying);
    },

    onConfigUpdate(config: Partial<IMiniPlayerConfig>): void {
      if (config.speed !== undefined) {
        Entities.GlobalVideoPlayer.setConfig({ speed: config.speed });
      }

      onConfigUpdate(config);
    },

    onSpeedChange(speed: number): void {
      presenter.onConfigUpdate({ speed });
    },

    onProgressChange(progress: number): void {
      if (!source.track) {
        return;
      }

      const progressPercentage = progress / source.track!.duration;
      onProgressChange(progress, progressPercentage);

      if (progressPercentage >= 1) {
        presenter.setPlaying(false);
      }

      if (progress !== presenter.videoProgress) {
        Entities.GlobalVideoPlayer.updateProgress(progress);
      }
    },

    onPlay(): void {
      presenter.setPlaying(true);
    },

    onPause(): void {
      presenter.setPlaying(false);
    },

    onTogglePlay(): void {
      presenter.setPlaying(!source.isPlaying);
    },

    onVideoProgressChange(progress: number): void {
      if (!source.track) {
        return;
      }

      const progressPercentage = progress / source.track.duration;
      Entities.miniPlayer.setProgress(progress, progressPercentage);
      Entities.miniPlayer.updateProgress(progress);
      Entities.miniPlayer.updateProgressPercentage(progressPercentage);

      if (progressPercentage >= 1) {
        presenter.setPlaying(false);
      }
    },
  }));

  useLayoutEffect(() => {
    presenter.init();
    return presenter.destroy;
  }, [track]);

  useEffect(() => {
    if (presenter.isPlayAudio !== presenter.isPlayVideo) {
      onTogglePlay(presenter.isPlayVideo);
    }
  }, [presenter.isPlayVideo]);

  useEffect(() => {
    if (presenter.isPlayAudio !== presenter.isPlayVideo) {
      presenter.setPlaying(presenter.isPlayAudio);
    }
  }, [presenter.isPlayAudio]);

  useEffect(() => {
    presenter.onVideoProgressChange(presenter.videoProgress);
  }, [presenter.videoProgress]);

  return presenter;
}
