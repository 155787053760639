import { useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { PayloadType } from 'APP/model/message/messageModel.types';
import Entities from 'STORE';
import { Group } from 'STORE/Groups/Group';

interface IBotCommandRendererPresenter {
  group: Group | null;
  onClick(): void;
}

export const useBotCommandRendererPresenter = (command: string): IBotCommandRendererPresenter => {
  const presenter = useLocalStore(() => ({
    get group(): Group | null {
      return Entities.GroupsStore.activeGroup;
    },

    onClick(): void {
      if (!presenter.group) {
        return;
      }

      Tasks.messaging.createNewMessages({
        messages: [
          {
            type: PayloadType.RichText,
            data: {
              text: command,
            },
            groupId: presenter.group.id,
          },
        ],
      });
    },
  }));

  return presenter;
};
