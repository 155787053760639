import { observer } from 'mobx-react';
import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';

import routerConst from '../constants';
import usePresenter from './presenter';

const PrivateDomain = ({ path, children }) => {
  const presenter = usePresenter();
  const location = useLocation();

  if (presenter.isShowSkeleton) {
    return null;
  }

  if (!presenter.isAllowedPrivateContent) {
    return (
      <Redirect
        to={{
          pathname: routerConst.link.login,
          search: location.search,
        }}
      />
    );
  }

  return <Route path={path}>{children}</Route>;
};

export default observer(PrivateDomain);
