import { useLocalStore } from 'mobx-react-lite';
import { useEffect } from 'react';

import { previewBuilder } from 'APP/packages/file-upload/previewBuilder';
import uploader from 'APP/packages/file-upload/uploader';

export default ({ userFile }) => {
  const presenter = useLocalStore(() => ({
    userFile: null,
    uploadProgress: null,
    url: null,
    previewUrl: null,
    error: null,
    uploadUrl: null,

    upload: async () => {
      if (!userFile) {
        return;
      }

      presenter.error = false;
      presenter.uploadProgress = 0;

      if (!presenter.url) {
        try {
          const { url } = await uploader.getUploadUrl();

          presenter.uploadUrl = url;

          const response = await uploader.uploadFile(url, userFile, userFile.filename, (event) => {
            presenter.uploadProgress = (event.loaded / event.total) * 100;
          });

          const uploadInfo = response[0];

          if (['video'].includes(userFile.type.split('/')[0])) {
            presenter.previewUrl = await previewBuilder.getFilePreviewUrl(
              userFile,
              userFile.filename
            );
          }

          presenter.url = uploadInfo.url;
          presenter.userFile = null;
        } catch (e) {
          presenter.error = true;
          presenter.uploadProgress = 0;
          return;
        }
      }

      presenter.uploadProgress = null;
    },

    cancelUpload: () => {
      if (presenter.uploadUrl) {
        uploader.cancelUploadFile(presenter.uploadUrl);
      }
    },

    reUpload: () => {
      presenter.upload();
    },
  }));

  useEffect(() => {
    presenter.userFile = userFile;
    presenter.upload();
  }, [userFile]);

  return presenter;
};
