import { stickersModel } from 'APP/model/model';
import Entities from 'STORE';

export async function addStickerPackToProfile(stickerPackId: string): Promise<void> {
  const result = await stickersModel.addStickerPackToProfile({ stickerPackId });

  if (result && !Entities.stickersStore.installedPackIds.includes(stickerPackId)) {
    Entities.stickersStore.setInstalledPackIds([
      ...Entities.stickersStore.installedPackIds,
      stickerPackId,
    ]);
  }
}
