import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { useTranslation } from 'APP/packages/translations';
import BackIcon from 'ICONS/ic-back-32.svg';
import { useAllPinsHeaderPresenter } from 'MAIN/ChatView/AllPins/AllPinsHeader/AllPinsHeader.presenter';
import { FAB } from 'UIKIT/FAB/FAB';

import styles from './AllPinsHeader.styles.m.css';

interface IAllPinsHeaderProps {
  groupId: string;
}

export const AllPinsHeader: FC<IAllPinsHeaderProps> = observer(({ groupId }) => {
  const { t } = useTranslation();
  const presenter = useAllPinsHeaderPresenter(groupId);

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <FAB
          className={styles.back}
          Icon={BackIcon}
          onClick={presenter.onBack}
          size="30"
          theme="8"
        />
        <div className={styles.headerTitle}>{t('multiple_pin_header', { 0: presenter.count })}</div>
      </div>
    </div>
  );
});
