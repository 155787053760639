import { useLocalStore } from 'mobx-react';

import Entities from 'APP/store';

interface ISoftUpdatePresenter {
  hasSoftUpdate: boolean;
  onClick(): void;
}

export const useSoftUpdatePresenter = (): ISoftUpdatePresenter => {
  return useLocalStore<ISoftUpdatePresenter>(() => ({
    get hasSoftUpdate(): boolean {
      return Entities.AuthStore.isNeedSoftUpdate || Entities.AuthStore.isNeedDesktopSoftUpdate;
    },

    onClick(): void {
      if (Entities.AuthStore.isNeedDesktopSoftUpdate) {
        window.electronAPI.installUpdate();
      } else {
        window.location.reload();
      }
    },
  }));
};
