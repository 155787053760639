import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import routerConst from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import Entities from 'APP/store';

export default () => {
  const navigateTo = useNavigateTo();

  const presenter = useLocalStore(() => ({
    get isNeedForceUpdate() {
      return Entities.AuthStore.isNeedForceUpdate;
    },
  }));

  useEffect(() => {
    if (presenter.isNeedForceUpdate) {
      navigateTo({
        to: routerConst.link.forceUpdate,
      });
    }
  }, [presenter.isNeedForceUpdate]);

  return presenter;
};
