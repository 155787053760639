import {
  PermissionsValue,
  PermissionsPersonalizedValue,
  PermissionsTypes,
} from 'APP/model/callPermissions/callPermissionsModel.constants';

export const AGORA_APP_ID = '98739530f26a49a099fe3a0d10f0e79f';

export const MAX_COUNT_PARTICIPANTS = 1000;
export const MAX_COUNT_BROADCASTERS = 50;

export const DEFAULT_ENCODER_CONFIG = {
  width: 848,
  height: 480,
  frameRate: 30,
  bitrate: 930,
};

export const LOW_ENCODER_CONFIG = {
  width: 240,
  height: 180,
  framerate: 15,
  bitrate: 120,
};

export enum CallEventTypes {
  IncomingCall = 'INCOMING_CALL',
  Started = 'STARTED',
  Joined = 'JOINED',
  Declined = 'DECLINED',
  Finished = 'FINISHED',
  FinishAll = 'FINISH_ALL',
  Left = 'LEFT',
  ForceMute = 'FORCE_MUTE',
  Added = 'ADDED',
  Removed = 'REMOVED',
  RaiseHand = 'RAISE_HAND',
  PutHand = 'PUT_HAND',
  ForceFinish = 'FORCE_FINISH',
  PermissionsGlobalChanged = 'PERMISSIONS_GLOBAL_CHANGED',
  PermissionsGlobalMemberChanged = 'PERMISSIONS_GLOBAL_MEMBER_CHANGED',
  PermissionsMemberChanged = 'PERMISSIONS_MEMBER_CHANGED',
  PermissionRequestSent = 'PERMISSION_REQUEST_SENT',
  PermissionRequestAlreadySent = 'PERMISSION_REQUEST_ALREADY_SENT',
  PermissionsRequestCreated = 'PERMISSIONS_REQUEST_CREATED',
  PermissionsRequestDeclined = 'PERMISSIONS_REQUEST_DECLINED',
}

export enum StreamType {
  Audio = 'audio',
  Video = 'video',
  ScreenSharing = 'screenSharing',
}

export enum RecordingType {
  Audio = 'AUDIO',
  AudioVideo = 'AUDIO_VIDEO',
}

export enum RecordingEventTypes {
  RecordingStarted = 'calls.active.recording-started',
  RecordingStopped = 'calls.active.recording-stopped',
}

export enum ClientRole {
  Host = 'host',
  Audience = 'audience',
}

export const PERMISSIONS_TYPES_TRANSLATIONS = {
  [PermissionsTypes.ShareScreen]: 'call_permissions_share_screen',
  [PermissionsTypes.SwitchOnCamera]: 'call_switch_on_camera',
  [PermissionsTypes.SwitchOnMic]: 'call_switch_on_mic',
};

export const PERMISSIONS_DEFAULT_TRANSLATIONS = {
  [PermissionsValue.Everyone]: 'call_permissions_everyone',
  [PermissionsValue.AfterApprove]: 'call_permissions_everyone_afterapproval',
  [PermissionsValue.AdminsAndOwner]: 'call_permissions_admins',
};

export const PERMISSIONS_CUSTOM_TRANSLATIONS = {
  [PermissionsPersonalizedValue.Always]: 'call_permissions_always',
  [PermissionsPersonalizedValue.Never]: 'call_permissions_never',
  [PermissionsPersonalizedValue.AfterApprove]: 'call_permissions_after_approval',
};

export const PERMISSIONS_REQUEST_TYPES = {
  [PermissionsTypes.SwitchOnCamera]: 'CAMERA',
  [PermissionsTypes.SwitchOnMic]: 'MIC',
  [PermissionsTypes.ShareScreen]: 'SCREEN_SHARING',
};
