import Entities from 'STORE';
import { IPushNotification } from 'STORE/PushNotifications/PushNotifications.types';

import { onNotificationClose } from '../utils/onNotificationClose';
import { onScheduledEventClick } from '../utils/onScheduledEventClick';
import { prepareNotificationOptions } from '../utils/prepareNotificationOptions';

export const handleScheduledEvent = (pushNotification: IPushNotification): void => {
  if (!pushNotification.group) {
    return;
  }

  const options = prepareNotificationOptions(pushNotification);

  const notification = new Notification(pushNotification.title || '', options);
  Entities.pushNotifications.addNotification(notification);
  notification.onclick = (): Promise<void> => onScheduledEventClick(pushNotification, notification);
  notification.onclose = (): void => onNotificationClose(notification);
  notification.onerror = (): void => onNotificationClose(notification);
};
