import { v4 as uuidv4 } from 'uuid';

import Entities from 'APP/store';

import { getPayloadByType } from '../../getPayloadByType/getPayloadByType';

export const createMessageData = ({ message, clientTime, index }) => {
  const payload = getPayloadByType(message.type, message.data);
  const clientUuid = message.clientUuid || uuidv4();

  return {
    id: clientUuid,
    clientTime: (clientTime + index).toString(),
    clientUuid,
    groupId: message.groupId || Entities.GroupsStore.activeGroupId,
    senderId: Entities.UsersStore.Me.id,
    quotedMessageId: message.quotedMessage && message.quotedMessage.id,
    quotedMessage: message.quotedMessage,
    payload,
  };
};
