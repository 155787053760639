/* global isFinite, Math */

export function getWidthHeightByMaxValues(
  width: number,
  height: number,
  maxWidth: number,
  maxHeight: number
): {
  width: number;
  height: number;
} {
  const diff: number = maxWidth / width - maxHeight / height;
  const ratio = width / height;

  if (!isFinite(diff) || isNaN(diff) || !isFinite(ratio) || isNaN(ratio)) {
    console.error(
      `[getWidthHeightByMaxValues] incorrect sizes width = ${width} height = ${height}`
    );

    return {
      width: 0,
      height: 0,
    };
  }

  if (diff >= 0) {
    const calculatedHeight = Math.min(maxHeight, height);

    return {
      width: Math.ceil(calculatedHeight * ratio),
      height: calculatedHeight,
    };
  }

  const calculatedWidth = Math.min(maxWidth, width);

  // wide
  return {
    width: calculatedWidth,
    height: Math.ceil(calculatedWidth / ratio),
  };
}
