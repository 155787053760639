import Tasks from 'APP/Tasks';
import { showToast } from 'APP/Tasks/app/showToast/showToast';
import { PayloadType } from 'APP/model/message/messageModel.types';
import Entities from 'STORE';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

import { IMediaMessagePayload, IRawMessage } from '../MediaMessagePopup.types';

// Todo replace any type when InputPanel store will be refactored
const getPayloadFileMessage = (
  groupId: string,
  payload: IMediaMessagePayload,
  comment: string,
  quotedMessage?: ChatMessage | null
): any => {
  const { type, data } = payload;
  return {
    type,
    data: { ...data, comment },
    groupId,
    quotedMessage,
  };
};

// Todo replace any type when InputPanel store will be refactored
const getPayloadTextMessage = (
  groupId: string,
  text: string,
  quotedMessage?: ChatMessage | null
): any => {
  return {
    type: PayloadType.RichText,
    data: {
      text: text,
      linkPreview: null,
      linkPreviewCancelled: true,
    },
    groupId,
    quotedMessage,
  };
};

// Todo replace any type when InputPanel store will be refactored
const getPayloadAlbumMessage = (
  groupId: string,
  payloads: IMediaMessagePayload[],
  comment = '',
  quotedMessage?: ChatMessage | null
): any => {
  return {
    type: PayloadType.Album,
    data: {
      payloads,
      comment,
    },
    groupId,
    quotedMessage,
  };
};

export const sendMediaMessages = async (
  groupId: string,
  rawMessages: IRawMessage[],
  caption: string,
  navigateTo: () => void,
  mustBeScheduled: boolean,
  quotedMessage?: ChatMessage | null
): Promise<boolean> => {
  const messages = [];
  let comment = caption;

  if (caption.length > 0 && rawMessages.length > 1) {
    messages.push(getPayloadTextMessage(groupId, caption, quotedMessage));
    comment = '';
  }

  for (let i = 0; i < rawMessages.length; i++) {
    if (rawMessages[i].type === 'album') {
      messages.push(
        getPayloadAlbumMessage(groupId, rawMessages[i].payloads, comment, quotedMessage)
      );
    } else {
      messages.push(
        getPayloadFileMessage(groupId, rawMessages[i].payloads[0], comment, quotedMessage)
      );
    }
  }
  Entities.InputPanel.payloads.setPayloads(messages);

  const canSend = Entities.InputPanel.payloads.checkGroupPermissions(!mustBeScheduled);
  if (!canSend.result) {
    Entities.InputPanel.payloads.clear();
    canSend.error && showToast(canSend.error);
    return false;
  }

  if (mustBeScheduled) {
    Tasks.group.goToScheduleSettings({ navigateTo });
  } else {
    Entities.InputPanel.send.submit();
  }

  return true;
};
