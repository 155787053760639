import { action, observable, computed, values } from 'mobx';

import { ClientRole } from 'APP/constants/calls';
import { ProviderUid } from 'APP/packages/callProviders/callProviders.types';

import { Call } from '../Call';
import { Opponent } from '../Opponent/Opponent';

export class Opponents {
  @observable data = observable.map<ProviderUid, Opponent>({}, { deep: false });

  call: Call;

  constructor(call: Call) {
    this.call = call;
  }

  @computed
  get opponentList(): readonly Opponent[] {
    return values(this.data);
  }

  @computed
  get broadcastersList(): Opponent[] {
    return values(this.data).filter((opponent) => opponent.role === ClientRole.Host);
  }

  @computed
  get opponentsWithVideo(): Opponent[] {
    return this.opponentList.filter((opponent) => opponent.hasVideo);
  }

  @computed
  get hasOpponents(): boolean {
    return this.opponentList.length > 0;
  }

  @action
  addOpponent = (opponentId: ProviderUid, opponent: Opponent): void => {
    this.data.set(opponentId, opponent);
  };

  @action
  deleteOpponent = (opponentId: ProviderUid): void => {
    const opponent = this.data.get(opponentId);

    if (opponent) {
      this.data.delete(opponentId);
    }
  };

  @action
  setOpponentRole = (opponentId: ProviderUid, role: ClientRole): void => {
    const opponent = this.data.get(opponentId);
    if (opponent) {
      opponent.setRole(role);
    }
  };

  @computed
  get screenSharingOpponent(): Opponent | null {
    return this.opponentList.find((opponent) => opponent.isSharedScreen) || null;
  }

  getOpponent = (uid: ProviderUid): Opponent | null => {
    return this.data.get(uid) || null;
  };

  getOpponentById = (userId: string): Opponent | null => {
    return (
      this.opponentList.find((opponent) => {
        return opponent.member && opponent.member.userId === userId && !opponent.isSharedScreen;
      }) || null
    );
  };
}
