import api from 'APP/packages/api';
import dateService from 'APP/packages/date';

export default async (groupId, msg, callback) => {
  try {
    const now = dateService.now().getTime();

    await api.messaging.updateScheduledMessage({
      groupId,
      messageId: msg.id,
      clientTime: now.toString(),
      delay: (msg.clientTime - now).toString(),
      payload: msg.payload.toJSON(),
    });

    callback(true);
  } catch (error) {
    console.error(error);
    callback(false);
  }
};
