import { observer } from 'mobx-react';
import React from 'react';

import { IconButton } from 'APP/packages/UiKit/IconButton';
import { AddKeyPressHandler } from 'APP/packages/keyBoardHandler';
import BackIcon from 'ICONS/ic-back-32.svg';
import DeleteIcon from 'ICONS/ic-delete.svg';
import EditIcon from 'ICONS/ic-edit.svg';
import ForwardIcon from 'ICONS/ic-forward-article.svg';

import usePresenter from './presenter';
import styles from './styles.m.css';

const Header = ({ message, goBack, onDelete }) => {
  const presenter = usePresenter({ message });

  return (
    <>
      <AddKeyPressHandler onEsc={goBack} />
      <div className={styles.container}>
        <IconButton
          Icon={BackIcon}
          onClick={goBack}
        />

        <div className={styles.rightBlock}>
          {presenter.isShowForwardAction && (
            <IconButton
              className={styles.icon}
              Icon={ForwardIcon}
              onClick={presenter.forward}
            />
          )}

          {presenter.isShowDeleteAction && (
            <IconButton
              className={styles.icon}
              Icon={DeleteIcon}
              onClick={onDelete}
            />
          )}

          {presenter.isShowEditAction && (
            <IconButton
              className={styles.icon}
              Icon={EditIcon}
              onClick={presenter.edit}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default observer(Header);
