import Tasks from 'APP/Tasks';
import { t } from 'MODULE/i18n/service';
import Entities from 'STORE';
import { Group } from 'STORE/Groups/Group';

const getIsAvailableCall = (group: Group): boolean => {
  if (Entities.Calls.hasCalls || Entities.ActiveStream.hasStream) {
    Tasks.app.showAlert(t('calls_join_another_call'));

    return false;
  }

  if (group.backgroundCall) {
    return false;
  }

  if (group.isP2P && !group.isBot) {
    return true;
  }

  if (group.isChatGroup) {
    return !group.withoutMe;
  }

  return false;
};

export const checkIsAvailableCall = (group: Group): boolean => {
  const isAvailableCall = getIsAvailableCall(group);

  if (!isAvailableCall) {
    return false;
  }

  if (group.isP2P && group.groupOpponent?.isBlockedByMe) {
    Tasks.app.showAlert(
      t('block_user_alert_common').replace('{0}', group.groupOpponent.userName || '')
    );

    return false;
  }

  if (group.isP2P && group.groupOpponent?.isBlockedMe) {
    Tasks.app.showAlert(t('blocked_user_alert_common'));

    return false;
  }

  const notAvailableP2PCall =
    group.isP2P && group.groupOpponent && !group.groupOpponent.privacyStatus.callMe;

  if (notAvailableP2PCall) {
    Tasks.app.showAlert(t('privacy_settings:privacy_settings_call_alert'));

    return false;
  }

  return true;
};
