import { useAsObservableSource, useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import Entities from 'STORE';
import { Group } from 'STORE/Groups/Group';

interface IBlurChatPresenter {
  isShowContent: boolean;
  onShow(): void;
  group: Group | null;
}

export const useBlurChatPresenter = (groupId: string): IBlurChatPresenter => {
  const source = useAsObservableSource({ groupId });
  const presenter = useLocalStore<IBlurChatPresenter>(() => ({
    get group(): Group | null {
      return Entities.GroupsStore.getGroupById(source.groupId);
    },

    get isShowContent(): boolean {
      return !!presenter.group?.showContent;
    },

    onShow(): void {
      Tasks.group.showContent(source.groupId);
    },
  }));

  return presenter;
};
