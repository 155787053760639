import { format } from 'bytes';
import { useAsObservableSource, useLocalStore } from 'mobx-react';

import {
  IAllDownloadsItemInfoLocalStore,
  IAllDownloadsItemInfoPresenter,
} from './AllDownloadsItemInfo.types';

export const useAllDownloadsItemInfoPresenter = ({
  progressEvent,
  downloadPercent,
  fileSize,
}: IAllDownloadsItemInfoPresenter) => {
  const source = useAsObservableSource({ progressEvent, downloadPercent });

  const presenter = useLocalStore<IAllDownloadsItemInfoLocalStore>(() => ({
    get loaded(): number {
      return source.progressEvent?.loaded || 0;
    },

    get downloadPercent(): number {
      return source.downloadPercent || 0;
    },

    get formattedBytes(): string {
      const formattedSize = format(fileSize, { unitSeparator: ' ', decimalPlaces: 2 });
      const formattedLoadedBytes = format(presenter.loaded, {
        unitSeparator: ' ',
        decimalPlaces: 2,
        fixedDecimals: true,
      });

      return source.progressEvent &&
        presenter.downloadPercent > 0 &&
        presenter.downloadPercent < 100
        ? `${formattedLoadedBytes} / ${formattedSize}`
        : formattedSize;
    },
  }));

  return presenter;
};
