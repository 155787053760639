import { observer } from 'mobx-react';
import React, { ReactNode } from 'react';

import { Link } from 'APP/packages/UiKit/Link/Link';
import { Field } from 'APP/packages/form/Field/Field';
import { Form } from 'APP/packages/form/Form/Form';
import { TextField } from 'APP/packages/form/TextField/TextField';
import { useTranslation } from 'APP/packages/translations';
import noop from 'APP/utils/noop';

import { useVerifyCodeStepPresenter } from './VerifyCodeStep.presenter';
import styles from './VerifyCodeStep.styles.m.css';

interface IVerifyCodeStepProps {
  phoneNumber: string;
  onSubmit(): void;
}
export const VerifyCodeStep = observer(({ phoneNumber, onSubmit }: IVerifyCodeStepProps) => {
  const presenter = useVerifyCodeStepPresenter(phoneNumber);

  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <p className={styles.description}>
        <span
          dangerouslySetInnerHTML={{
            __html: t('verification_code_enter', {
              0: `<span>${phoneNumber}</span>`,
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </p>

      <Form
        onSubmit={noop}
        initialValues={{
          verificationCode: '',
        }}
        subscription={{
          values: true,
          pristine: true,
          invalid: true,
          validating: true,
        }}
        keepDirtyOnReinitialize={true}
      >
        {({ invalid, pristine, validating }): ReactNode => {
          presenter.checkFormState(onSubmit, invalid, pristine, validating);

          return (
            <form className={styles.form}>
              <div className={styles.fields}>
                <Field
                  setRef={presenter.ref}
                  name="verificationCode"
                  className={styles.code}
                  component={TextField}
                  maxLength={4}
                  validate={presenter.validateCode}
                  successText={' '}
                />
                <div className={styles.description}>
                  <div>{t('didnt_receive_code_label')}</div>
                  <div>
                    {presenter.timerIsFinish && (
                      <Link
                        color={'primary'}
                        isDisabled={presenter.isSending}
                        onClick={presenter.sendAgain}
                      >
                        {t('send_again_button')}
                      </Link>
                    )}
                    {!presenter.timerIsFinish && !presenter.attemptsIsOver && (
                      <span>
                        {t('send_again_button')} <b>{presenter.seconds}</b>
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </form>
          );
        }}
      </Form>
    </div>
  );
});
