import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { useMiniPlayerPresenter } from './MiniPlayer.presenter';
import styles from './MiniPlayer.styles.m.css';

export const MiniPlayer: FC = observer(() => {
  const presenter = useMiniPlayerPresenter();
  const Component = presenter.playerComponent;

  if (!presenter.isOpened || !Component) {
    return null;
  }

  return (
    <Component
      className={styles.audioPlayer}
      track={presenter.track}
      playList={presenter.playList}
      isPlaying={presenter.isPlaying}
      isShowPlayList={presenter.isShowPlayList}
      config={presenter.config}
      progress={presenter.progress}
      progressPercentage={presenter.progressPercentage}
      onConfigUpdate={presenter.onConfigUpdate}
      onChangeTrack={presenter.onChangeTrack}
      onProgressChange={presenter.onProgressChange}
      onTogglePlay={presenter.onTogglePlay}
      onTitleClick={presenter.onTitleClick}
      onClose={presenter.onClose}
    />
  );
});
