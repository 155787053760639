import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import NoResultsImage from 'APP/images/gem-mascot-not-found.svg';
import { MediaGallery } from 'APP/main/components/MediaGallery/MediaGallery';
import { NewsfeedPlaceholder } from 'APP/packages/placeholders/NewsfeedPlaceholder/NewsfeedPlaceholder';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';
import { TNewsItem, TPostInstance } from 'STORE/Newsfeed/Newsfeed.types';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';
import { InfoBlock, InfoBlockTypes } from 'UIKIT/InfoBlock/InfoBlock';
import { Loader } from 'UIKIT/Loader/Loader';
import { IPopoverMenuItem } from 'UIKIT/PopoverMenu/PopoverMenu.types';

import { useNewsfeedContentPresenter } from './NewsfeedContent.presenter';
import styles from './NewsfeedContent.styles.m.css';
import { TSetNewsVisibilityHandler } from './NewsfeedContent.types';

interface INewsfeedContentProps {
  news: TNewsItem[];
  scrollPosition?: number;
  isShowPlaceholder?: boolean;
  isShowLoader?: boolean;
  hasMoreNews?: boolean;
  emptyInfoText?: string;
  hasBackgroundImage?: boolean;
  onReloadNewsfeed(): void;
  onSetNewsVisibility: TSetNewsVisibilityHandler;
  onScroll?(offset: number): void;
  getMenuItems?(message: ChatMessage, newsItem: TPostInstance): IPopoverMenuItem[];
}

export const NewsfeedContent: FC<INewsfeedContentProps> = observer((props) => {
  const {
    news,
    scrollPosition,
    isShowPlaceholder,
    isShowLoader,
    emptyInfoText,
    hasBackgroundImage,
    hasMoreNews = true,
    children,
    onReloadNewsfeed,
    onSetNewsVisibility,
    onScroll,
    getMenuItems,
  } = props;

  const { presenter, scrollRef } = useNewsfeedContentPresenter({
    news,
    scrollPosition,
    onScroll,
  });

  return (
    <>
      <div
        className={classNames(styles.content, customScrollStyles.container)}
        ref={scrollRef}
      >
        {children}

        {isShowPlaceholder ? (
          <NewsfeedPlaceholder />
        ) : (
          <>
            {presenter.items.map(({ Component, key, newsItem, position }) => (
              <Component
                className={styles.news}
                key={key}
                newsItem={newsItem}
                position={position}
                scrollRef={scrollRef}
                onReloadNewsfeed={onReloadNewsfeed}
                onSetNewsVisibility={onSetNewsVisibility}
                getMenuItems={getMenuItems}
              />
            ))}

            {isShowLoader && (
              <div className={styles.loader}>
                <Loader
                  color="current"
                  size="medium"
                />
              </div>
            )}
            {emptyInfoText && !hasMoreNews && !!presenter.items.length && (
              <InfoBlock
                className={styles.noMoreResults}
                type={hasBackgroundImage ? InfoBlockTypes.ChatWithPattern : InfoBlockTypes.Chat}
                descriptions={[emptyInfoText]}
                image={NoResultsImage}
              />
            )}
          </>
        )}
      </div>

      <MediaGallery
        canDelete={false}
        canNavigate={false}
      />
    </>
  );
});
