import { observer } from 'mobx-react';
import React from 'react';
import { CSSTransition } from 'react-transition-group';

import Entities from 'STORE';
import { Portal } from 'UIKIT/Portal/Portal';

import styles from './Toast.styles.m.css';

const ANIMATION_TIMEOUT = 300;

const CSSTransitionClasses = {
  enter: styles.toastEnter,
  enterActive: styles.toastEnterActive,
  exit: styles.toastExit,
  exitActive: styles.toastExitActive,
};

export const Toast = observer(() => {
  return (
    <Portal>
      <CSSTransition
        in={Entities.toast.isVisible}
        classNames={CSSTransitionClasses}
        timeout={ANIMATION_TIMEOUT}
        unmountOnExit
      >
        <div className={styles.container}>{Entities.toast.text}</div>
      </CSSTransition>
    </Portal>
  );
});
