import Tasks from 'APP/Tasks';
import { AudioSourceType } from 'APP/constants/app';
import { CallType } from 'APP/model/call/callModel.types';
import Entities from 'APP/store';

interface IStartAudioCallData {
  groupId: string;
}

export const startAudioCall = async ({ groupId }: IStartAudioCallData): Promise<void> => {
  const group = Entities.GroupsStore.getGroupById(groupId);

  if (!group) {
    return;
  }

  const hasGroupAudioPermissions = Tasks.calls.checkGroupAvailableCall(group.id);
  const isMaxParticipant = Tasks.calls.checkMaxParticipant(group.id);

  if (!hasGroupAudioPermissions || !isMaxParticipant) {
    return;
  }

  const hasAudioPermissions = await Tasks.permissions.hasAudioPermissions();

  if (!hasAudioPermissions) {
    return;
  }

  Tasks.app.audioSource.setCurrentSource(AudioSourceType.Call);

  Tasks.calls.startCall({
    groupId: group.id,
    channelId: null,
    withAudio: !!group?.isP2P,
    withVideo: false,
    callType: group.isP2P ? CallType.P2P : CallType.Group,
  });
};
