import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Tasks from 'APP/Tasks';
import { UnauthorizedCallType } from 'APP/model/unauthorizedCall/unauthorizedCallModel.constants';
import { ICallInvitationInfo } from 'APP/model/unauthorizedCall/unauthorizedCallModel.types';
import { callAnalytics } from 'APP/packages/analytics/analytics';
import { RouterLink } from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import Entities from 'STORE';
import { Call } from 'STORE/Calls/Call/Call';

interface ICallPreviewPresenter {
  invitationInfo?: ICallInvitationInfo;
  ongoingCall?: Call | null;
  selfName?: string;
  isGroupCall: boolean;
  isLoading: boolean;
  onStart(): void;
}

export const useCallPreviewPresenter = (
  onStartCall: () => void,
  isAudioMuted: boolean,
  isVideoMuted: boolean
): ICallPreviewPresenter => {
  const params = useParams<{ id: string; callType: UnauthorizedCallType; channelId?: string }>();
  const navigateTo = useNavigateTo();
  const source = useAsObservableSource({ isAudioMuted, isVideoMuted });

  const presenter = useLocalStore<ICallPreviewPresenter>(() => ({
    isLoading: false,

    get invitationInfo(): ICallInvitationInfo | undefined {
      return Entities.callInvitation.invitationInfo;
    },

    get ongoingCall(): Call | null {
      return Entities.Calls.ongoingCall;
    },

    get selfName(): string | undefined {
      return Entities.callInvitation.selfName;
    },

    get isGroupCall(): boolean {
      return params.callType === UnauthorizedCallType.Call;
    },

    async onStart(): Promise<void> {
      presenter.isLoading = true;
      if (params.id && presenter.selfName) {
        const mediaParams = {
          isAudioMuted: source.isAudioMuted,
          isVideoMuted: source.isVideoMuted,
        };
        await onStartCall();
        const info =
          presenter.isGroupCall && params.channelId
            ? await Tasks.calls.getUnauthorizedGroupCallInfo(params.channelId, presenter.selfName)
            : await Tasks.calls.getUnauthorizedCallInfo(params.id, presenter.selfName);
        presenter.isLoading = false;

        if (!info) {
          return;
        }
        const { sseToken, apiToken, callInfo } = info;
        Tasks.authorization.setAuthTokens({ sseToken, apiToken });
        await Tasks.calls.joinToUnauthorizedCall(callInfo, mediaParams);
        callAnalytics.trackJoinedGuest(presenter.isGroupCall ? 'group' : 'p2p');
      }
    },
  }));

  useEffect(() => {
    if (!presenter.invitationInfo) {
      navigateTo({
        to: presenter.isGroupCall ? RouterLink.callInvitationGroup : RouterLink.callInvitation,
        params: { id: params.id, callType: params.callType, channelId: params.channelId },
      });
    }
  }, [navigateTo, presenter.invitationInfo]);

  return presenter;
};
