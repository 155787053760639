import { ApiError } from 'APP/packages/api/error/ApiError';

import { HttpErrorCode } from './error.constants';
import { BadRequestError } from './errors/BadRequestError';
import { ForbiddenError } from './errors/ForbiddenError';
import { ModelError } from './errors/ModelError';
import { NotFoundError } from './errors/NotFoundError';
import { UnauthorizedError } from './errors/UnauthorizedError';
import { UnhandledError } from './errors/UnhandledError';

export function buildModelError(error: ApiError): ModelError {
  switch (error.code) {
    case HttpErrorCode.BadRequest:
      return new BadRequestError(error.message, error.code, error);
    case HttpErrorCode.Unauthorized:
      return new UnauthorizedError(error.message, error.code, error);
    case HttpErrorCode.Forbidden:
      return new ForbiddenError(error.message, error.code, error);
    case HttpErrorCode.NotFound:
      return new NotFoundError(error.message, error.code, error);
    default:
      return new UnhandledError(error.message, error.code, error);
  }
}
