import { useEffect } from 'react';

import { ApplicationTheme } from 'APP/constants/appearance';
import Entities from 'APP/store';

export function useThemeHandlerPresenter(): void {
  useEffect(() => {
    const onSetSystemTheme = (event: MediaQueryListEvent | MediaQueryList): void => {
      Entities.appearance.setSystemTheme(
        event.matches ? ApplicationTheme.Dark : ApplicationTheme.Light
      );
    };

    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    onSetSystemTheme(darkModeMediaQuery);
    darkModeMediaQuery.addEventListener('change', onSetSystemTheme);

    return () => {
      darkModeMediaQuery.removeEventListener('change', onSetSystemTheme);
    };
  }, []);

  useEffect(() => {
    const currentTheme = Entities.appearance.theme;
    document.documentElement.setAttribute('data-theme', currentTheme);
    const themeEl = document.querySelector('meta[name=theme-color]');
    const computedStyles = window.getComputedStyle(themeEl!);
    const base20 = computedStyles.getPropertyValue('--base-20');
    const base0 = computedStyles.getPropertyValue('--pattern-base-0');
    document.documentElement.style.backgroundColor = base0;
    themeEl!.setAttribute('content', base20);
  }, [Entities.appearance.theme]);
}
