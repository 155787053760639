import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { useTranslation } from 'APP/packages/translations';
import LeaveIcon from 'ICONS/ic-log-out.svg';
import ReportIcon from 'ICONS/ic-report.svg';
import { TPostInstance } from 'STORE/Newsfeed/Newsfeed.types';
import { Button } from 'UIKIT/Button/Button';
import { IconView } from 'UIKIT/IconView/IconView';
import { MenuItem } from 'UIKIT/MenuItem/MenuItem';

import { usePostHiddenContentPresenter } from './PostHiddenContent.presenter';
import styles from './PostHiddenContent.styles.m.css';

interface IPostHiddenContentProps {
  newsItem: TPostInstance;
}

export const PostHiddenContent: FC<IPostHiddenContentProps> = observer(({ newsItem }) => {
  const presenter = usePostHiddenContentPresenter(newsItem);
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.header}>
        <h3 className={styles.title}>{presenter.title}</h3>
        <Button
          className={styles.showButton}
          theme="2"
          onClick={presenter.onShow}
        >
          {t('common_cancel')}
        </Button>
      </div>

      {presenter.isShowFooter && (
        <>
          <hr className={styles.row} />

          <div className={styles.footer}>
            {presenter.isShowLeaveButton && (
              <MenuItem
                title={t('action_over_chat_leavechannel')}
                icon={<IconView Icon={LeaveIcon} />}
                onClick={presenter.onLeave}
              />
            )}

            {presenter.isShowReportButton && (
              <MenuItem
                title={t('common_report')}
                icon={<IconView Icon={ReportIcon} />}
                onClick={presenter.onReport}
              />
            )}
          </div>
        </>
      )}
    </>
  );
});
