import Entities from 'APP/store';

import { IClearInputPanelData } from './types';

export const clearInputPanel = ({
  groupId,
  clearInput = true,
  clearDraft = true,
}: IClearInputPanelData): void => {
  const inputPanel = Entities.InputPanelsStore.getById(groupId);

  if (!inputPanel) {
    return;
  }

  if (inputPanel.value !== null && clearInput) {
    // clear input text
    inputPanel.setValue(null);
  }

  if (inputPanel.editMessageId) {
    inputPanel.setEditMessageId(null);
    inputPanel.setValue(null);
  }

  if (inputPanel.quoteMessage) {
    // remove reply
    inputPanel.setQuoteMessage(null);
  }
};
