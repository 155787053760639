import { loadUnknownUsersByIds } from '../loadUnknownUsersByIds';

export default async ({ groups = [] }) => {
  // load users from group description
  // references need refactoring. Load the user on click, not in advance.
  const usersIds = groups.reduce((memo, group) => {
    (group.descriptionMentions || []).forEach((mention) => {
      memo.push(mention.userId);
    });
    return memo;
  }, []);

  return loadUnknownUsersByIds({ usersIds });
};
