import { Placement } from '@popperjs/core';
import React, { FC, MutableRefObject } from 'react';

import { ChatMessage } from 'APP/store/Messages/Message/ChatMessage/ChatMessage';

import { MessageViewPageType } from '../../Messages.types';
import { ContextMenu } from '../ContextMenu/ContextMenu';
import { useMessageActionsMenuPresenter } from './MessageActionsMenu.presenter';

interface IMessageActionsMenuProps {
  reference: MutableRefObject<HTMLElement | null>;
  messageViewPageType: MessageViewPageType;
  message: ChatMessage;
  placement?: Placement;
  onChange(popoverStatus: boolean): void;
}

export const MessageActionsMenu: FC<IMessageActionsMenuProps> = ({
  reference,
  message,
  messageViewPageType,
  placement = 'right-start',
  onChange,
}) => {
  const presenter = useMessageActionsMenuPresenter({ reference, onChange });

  if (!presenter.popover.isOpened) {
    return null;
  }

  return (
    <ContextMenu
      message={message}
      placement={placement}
      popover={presenter.popover}
      targetClick={null}
      messageViewPageType={messageViewPageType}
      modifiers={presenter.modifiers}
    />
  );
};
