import React, { memo } from 'react';

import styles from '../FileIcon.styles.m.css';
import { TFileTheme } from '../FileIcon.types';

interface IFileIconCompleteProps {
  theme: TFileTheme;
  text: string;
}

export const FileIconComplete = memo<IFileIconCompleteProps>((props) => {
  const { theme, text } = props;

  return (
    <>
      <path
        className={styles[`file-${theme}`]}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 3H31L43 15V29.8321C41.7751 29.2969 40.4222 29 39 29C33.4772 29 29 33.4772 29 39C29 40.4222 29.2969 41.7751 29.8321 43H11C7.68629 43 5 40.3137 5 37V9C5 5.68629 7.68629 3 11 3Z"
      />
      <path
        opacity="0.2"
        d="M43 15L31 3V11C31 13.2091 32.7909 15 35 15H43Z"
        fill="black"
      />
      <circle
        cx="39"
        cy="39"
        r="9"
        className={styles[`file-${theme}`]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.177 36.2831C41.6063 35.8941 42.2848 35.9074 42.6969 36.314C43.1137 36.7252 43.0986 37.3811 42.6643 37.7746L39.1477 41.7146C38.7278 42.0951 38.0686 42.0951 37.6487 41.7146L35.3357 39.6189C34.9014 39.2254 34.8863 38.5695 35.3031 38.1582C35.7152 37.7517 36.3937 37.7384 36.823 38.1274L38.3982 39.5547L41.177 36.2831Z"
        fill="white"
      />
      <text
        className={styles.text}
        x="50%"
        y="50%"
        dominantBaseline="middle"
        textAnchor="middle"
      >
        {text}
      </text>
    </>
  );
});
