import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useRef, useState } from 'react';

import { IconButton } from 'APP/packages/UiKit/IconButton';
import MoreIcon from 'ICONS/dots.svg';
import GoToMessageIcon from 'ICONS/ic-go-to-message.svg';
import Reply from 'ICONS/ic-reply.svg';

import { MessageActionsMenu } from '../../MessageActionsMenu/MessageActionsMenu';
import { disableQuoteAttrs } from '../../presenter/constants';
import Reactions from './Reactions';
import usePresenter from './presenter';
import styles from './styles.m.css';

export const MessageMenu = observer(({ message, messageViewPageType }) => {
  const { reactionsPopover, presenter } = usePresenter({
    message,
    messageViewPageType,
  });

  const buttonRef = useRef(null);

  const [popoverIsOpen, setPopoverIsOpen] = useState(false);

  const isOpened = popoverIsOpen || reactionsPopover.isOpened;

  const classes = classNames(styles.menuPlaceholder, 'message-menu', {
    [styles.opened]: isOpened,
    [styles.right]: !message.isLeftPosition,
  });

  const containerClasses = classNames(styles.itemContainer, {
    [styles.opened]: popoverIsOpen,
  });

  const placement = message.isLeftPosition ? 'right-start' : 'left-start';

  return (
    <div
      className={classes}
      {...disableQuoteAttrs}
    >
      {presenter.canBeQuoted && (
        <IconButton
          Icon={Reply}
          onClick={presenter.startQuote}
          className={styles.itemContainer}
          iconClassName={styles.icon}
          dataTest="reply-button"
          theme="19"
        />
      )}

      {presenter.messageCanBeFocused && (
        <IconButton
          Icon={GoToMessageIcon}
          onClick={presenter.goToMessage}
          className={styles.itemContainer}
          iconClassName={styles.icon}
          dataTest="reply-button"
          theme="19"
        />
      )}
      {message.isAvailableReactions && (
        <Reactions
          message={message}
          popover={reactionsPopover}
        />
      )}
      {message.hasMenuItems && (
        <>
          <IconButton
            setRef={buttonRef}
            Icon={MoreIcon}
            className={containerClasses}
            iconClassName={styles.icon}
            dataTest="message-menu-button"
            theme="19"
          />
          <MessageActionsMenu
            reference={buttonRef}
            placement={placement}
            message={message}
            messageViewPageType={messageViewPageType}
            onChange={setPopoverIsOpen}
          />
        </>
      )}
    </div>
  );
});
