import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { IStickerPackExtend } from 'APP/model/stickers/stickersModel.types';
import IcSettings from 'ICONS/ic-settings-24.svg';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';
import { FAB } from 'UIKIT/FAB/FAB';

import { StickerPack } from './StickerPack/StickerPack';
import styles from './StickerPackList.styles.m.css';

interface StickerPackListProps {
  packs: IStickerPackExtend[];
  checkIsActivePack(stickerPackId: string): boolean;
  onStickerPackClick(stickerPackId: string): void;
  onSettingsClick(): void;
}
export const StickerPackList: FC<StickerPackListProps> = observer(
  ({ packs, checkIsActivePack, onStickerPackClick, onSettingsClick }) => {
    return (
      <div className={styles.container}>
        <div className={classNames(styles.horizontalScroll, customScrollStyles.container)}>
          {packs.map(({ stickerPack }) => {
            return (
              <StickerPack
                key={stickerPack.id}
                stickerPackId={stickerPack.id}
                iconUrl={stickerPack.iconUrl}
                isActive={checkIsActivePack(stickerPack.id)}
                onClick={onStickerPackClick}
              />
            );
          })}
        </div>
        <div className={styles.settings}>
          <FAB
            Icon={IcSettings}
            size="30"
            theme="2"
            onClick={onSettingsClick}
          />
        </div>
      </div>
    );
  }
);
