import React, { FC, useEffect, useRef } from 'react';

import { openPopup } from 'APP/Tasks/app/popup/popup';
import { AlertPopup } from 'MAIN/PopupManager/Popups/AlertPopup/AlertPopup';
import { Popup } from 'STORE/App/Popups/Popup/Popup';

interface IAutoplayingLoopAudioProps {
  permissionText: string;
  src: string;
  volume?: number;
}

export const AutoplayingLoopAudio: FC<IAutoplayingLoopAudioProps> = ({
  permissionText,
  src,
  volume = 1,
}) => {
  const audio = useRef<HTMLAudioElement>(null);
  const alertPopup = useRef<Popup>();

  useEffect(() => {
    let isMounted = true;

    if (audio.current) {
      audio.current.play().catch(async () => {
        if (isMounted) {
          alertPopup.current = openPopup(AlertPopup, {
            params: { text: permissionText },
            onClose: () => audio.current!.play(),
          });
        }
      });

      audio.current.volume = volume;
    }

    return () => {
      isMounted = false;

      if (audio.current) {
        audio.current.volume = 1;
        audio.current.pause();
        audio.current.src = '';
        audio.current.load();
      }

      alertPopup.current?.close();
    };
  }, [audio.current]);

  return (
    <audio
      ref={audio}
      src={src}
      loop
    />
  );
};
