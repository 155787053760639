/* global Date */
import isValidDate from './isValidDate';
import { now } from './now';

export default (rawCompareDate) => {
  const compareDate = new Date(rawCompareDate);

  if (!isValidDate(compareDate)) {
    return false;
  }

  const lastYear = now();
  lastYear.setFullYear(lastYear.getFullYear() - 1);

  return compareDate.getTime() > lastYear.getTime();
};
