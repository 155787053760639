import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import Tasks from 'APP/Tasks';
import { MediaPreview } from 'APP/components/MediaPreview/MediaPreview';
import { MEDIA_PREVIEW_MAX_SIZE } from 'APP/constants/messages';
import { MediaUploadState } from 'MAIN/components/MediaUploadState/MediaUploadState';
import type { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

import styles from './ImagePayload.styles.m.css';

interface IImageProps {
  message: ChatMessage;
  isPreview: boolean;
  isView: boolean;
}

export const ImagePayload: FC<IImageProps> = observer((props) => {
  const { message, isPreview, isView } = props;

  if (!message?.payload?.previewUrl) {
    return null;
  }

  const previewClassName = classNames(styles.image, {
    [styles.myImage]: message.fromMe && !message.group?.isChannel,
  });

  return (
    <div className={styles.container}>
      <MediaPreview
        className={previewClassName}
        payload={message.payload}
        maxWidth={MEDIA_PREVIEW_MAX_SIZE.width}
        maxHeight={MEDIA_PREVIEW_MAX_SIZE.height}
        isPreview={isPreview}
        isView={isView}
        payloadId={message.id}
        groupId={message.groupId}
        messageId={message.id}
        onClick={(): void => {
          Tasks.group.openMediaGallery({
            groupId: message.groupId,
            mediaId: message.clientUuid,
            messageId: message.id,
            showMessageInfo: !message.isFakeGroup,
          });
        }}
      />
      <MediaUploadState message={message} />
    </div>
  );
});
