import Entities from 'STORE';

interface IChatInfoPresenter {
  toggleProfileSidebar(): void;
}

export function useChatInfoPresenter(): IChatInfoPresenter {
  return {
    toggleProfileSidebar: () => Entities.ChatStore.toggleSidebar(),
  };
}
