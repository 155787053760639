import { observer } from 'mobx-react';
import React, { FC, MouseEventHandler } from 'react';

import {
  IFileUploadStateData,
  useFileUploadStatePresenter,
} from 'APP/hooks/useFileUploadState/useFileUploadState';
import { PlayIcon, PlayIconState, PlayIconType } from 'UIKIT/PlayIcon/PlayIcon';

interface IAudioUploadStateProps {
  uploadFileData: IFileUploadStateData;
  className?: string;
  onClick?: MouseEventHandler<SVGSVGElement | SVGGElement>;
  iconType?: PlayIconType;
}

export const AudioUploadState: FC<IAudioUploadStateProps> = observer((props) => {
  const { uploadFileData, className, onClick, iconType = PlayIconType.Play } = props;

  const presenter = useFileUploadStatePresenter(uploadFileData);

  let state;

  if (presenter.hasError) {
    state = PlayIconState.Error;
  } else if (presenter.isWaitingUploadProcess) {
    state = PlayIconState.Infinity;
  } else if (presenter.isUploadProcess) {
    state = PlayIconState.Loading;
  } else {
    state = PlayIconState.Regular;
  }

  return (
    <div className={className}>
      <PlayIcon
        type={iconType}
        state={state}
        progress={presenter.uploadProgress || 0}
        onCancel={presenter.cancelUpload}
        onClick={onClick}
      />
    </div>
  );
});
