import { useLocalStore, useAsObservableSource } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { useTranslation } from 'APP/packages/translations';
import Entities from 'APP/store';
import CalendarIcon from 'ICONS/ic-calendar.svg';
import CallIcon from 'ICONS/ic-call.svg';
import ConferenceIcon from 'ICONS/ic-conference.svg';
import VideoCallIcon from 'ICONS/ic-video-on.svg';
import { IPopover } from 'UIKIT/Popover/Popover.types';
import { usePopover } from 'UIKIT/Popover/usePopover';
import { IPopoverMenuItem } from 'UIKIT/PopoverMenu/PopoverMenu.types';

interface IStartCallButtonParams {
  groupId: string;
  startAudioCall: (() => unknown) | null;
  startVideoCall: (() => unknown) | null;
  startConferenceCall: (() => unknown) | null;
  scheduleCallEvent(): void;
}

interface IStartCallButtonPresenter {
  menuItems: IPopoverMenuItem[];
  onClick(): Promise<void>;
}

interface IUseStartCallButtonPresenter {
  presenter: IStartCallButtonPresenter;
  popover: IPopover<HTMLButtonElement>;
}

export const useStartCallButtonPresenter = (
  data: IStartCallButtonParams
): IUseStartCallButtonPresenter => {
  const { groupId, startAudioCall, startVideoCall, startConferenceCall, scheduleCallEvent } = data;
  const { t } = useTranslation();
  const popover = usePopover<HTMLButtonElement>();
  const source = useAsObservableSource({
    groupId,
    startAudioCall,
    startVideoCall,
    startConferenceCall,
    scheduleCallEvent,
  });

  const presenter = useLocalStore<IStartCallButtonPresenter>(() => ({
    get menuItems(): IPopoverMenuItem[] {
      const items = [];

      if (source.startAudioCall) {
        items.push({
          key: 'audioCall',
          title: t('group_chat_audio_call_button'),
          onClick: source.startAudioCall,
          icon: CallIcon,
          isVisible: true,
        });
      }

      if (source.startVideoCall) {
        items.push({
          key: 'videoCall',
          title: t('group_chat_video_call_button'),
          onClick: source.startVideoCall,
          icon: VideoCallIcon,
          isVisible: true,
        });
      }
      if (source.startConferenceCall) {
        items.push({
          key: 'conferenceCall',
          title: t('calls_conference'),
          onClick: source.startConferenceCall,
          icon: ConferenceIcon,
          isVisible: true,
        });
      }

      items.push({
        key: 'scheduledCall',
        title: t('call_scheduled_call'),
        onClick: source.scheduleCallEvent,
        icon: CalendarIcon,
        isVisible: true,
      });

      return items;
    },

    async onClick(): Promise<void> {
      const group = Entities.GroupsStore.getGroupById(source.groupId);
      if (group && group.isOpen && group.withoutMe) {
        const isNeedJoin = await Tasks.app.showConfirm({
          text: t('public_group_call_alert'),
          primaryText: t('calls_join'),
          secondaryText: t('cancel'),
        });

        if (isNeedJoin) {
          await Tasks.group.joinGroup({ groupId: source.groupId });
        }

        return;
      }

      popover.toggle();
    },
  }));

  return { presenter, popover };
};
