import { useLocalStore, useAsObservableSource } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { CatalogType } from 'APP/model/catalog/catalogModel.types';
import { ICategoryInfo } from 'APP/model/common/commonModel.types';
import Entities from 'STORE';

import { TCategoryChangeHandler } from '../Catalog.types';

interface ICatalogCategoriesLocalStore {
  isLoading: boolean;
  categories: ICategoryInfo[];
  isShowCategories: boolean;
  init(): Promise<void>;
  isActive(id: string): boolean;
  onClick(id: string): void;
}

export const useCatalogCategoriesPresenter = (onCategoryChange: TCategoryChangeHandler) => {
  const source = useAsObservableSource({ onCategoryChange });

  const presenter = useLocalStore<ICatalogCategoriesLocalStore>(() => ({
    isLoading: true,
    categories: [],

    get isShowCategories(): boolean {
      return !presenter.isLoading && Entities.catalogStore.type !== CatalogType.Bot;
    },

    async init(): Promise<void> {
      presenter.categories = await Tasks.search.getAllCategoriesInfo();
      presenter.isLoading = false;

      if (!presenter.categories.length) {
        Entities.catalogStore.setError(true);
      }
    },

    isActive(id: string): boolean {
      return id === Entities.catalogStore.categoryId;
    },

    onClick(id: string): void {
      if (presenter.isActive(id)) {
        source.onCategoryChange(null);
      } else {
        source.onCategoryChange(id);
      }
    },
  }));

  useEffect(() => {
    presenter.init();
  }, []);

  return presenter;
};
