import { useLocalStore } from 'mobx-react';
import { FormEventHandler } from 'react';

import { PrivacyValue } from 'APP/model/userSettings/userSettingsModel.types';

interface IPrivacyPagePresenterProps {
  labels: Record<PrivacyValue, string>;
  value: PrivacyValue;
  setValue(value: PrivacyValue): Promise<void>;
  goBack(): void;
}

interface IPrivacyPagePresenter {
  value: PrivacyValue;
  list: Array<IPrivacyItem>;
  submit: FormEventHandler;
  setValue(value: PrivacyValue): void;
}

interface IPrivacyItem {
  key: PrivacyValue;
  title: string;
  isActive: boolean;
  onChange(): void;
}

export const usePrivacyPagePresenter = ({
  labels,
  setValue,
  value,
  goBack,
}: IPrivacyPagePresenterProps): IPrivacyPagePresenter => {
  const presenter = useLocalStore<IPrivacyPagePresenter>(() => ({
    value: value,

    submit: (event): void => {
      event.preventDefault();
      event.stopPropagation();
      setValue(presenter.value);
      goBack();
    },

    setValue: (value: PrivacyValue): void => {
      presenter.value = value;
    },

    get list(): Array<IPrivacyItem> {
      return [
        {
          key: PrivacyValue.Anyone,
          title: labels[PrivacyValue.Anyone],
          isActive: PrivacyValue.Anyone === presenter.value,
          onChange: () => presenter.setValue(PrivacyValue.Anyone),
        },
        {
          key: PrivacyValue.Contacts,
          title: labels[PrivacyValue.Contacts],
          isActive: PrivacyValue.Contacts === presenter.value,
          onChange: () => presenter.setValue(PrivacyValue.Contacts),
        },
        {
          key: PrivacyValue.Nobody,
          title: labels[PrivacyValue.Nobody],
          isActive: PrivacyValue.Nobody === presenter.value,
          onChange: () => presenter.setValue(PrivacyValue.Nobody),
        },
      ];
    },
  }));

  return presenter;
};
