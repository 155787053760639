import React, { FC } from 'react';

import RemoveIcon from 'ICONS/ic-close.svg';
import { FAB } from 'UIKIT/FAB/FAB';

import styles from './KeywordFilterItem.styles.m.css';

interface IKeywordFilterItemProps {
  value: string;
  onDelete(): void;
}

export const KeywordFilterItem: FC<IKeywordFilterItemProps> = ({ value, onDelete }) => {
  return (
    <div className={styles.itemContainer}>
      <span className={styles.text}>{value}</span>
      <FAB
        className={styles.button}
        Icon={RemoveIcon}
        size="10"
        theme="3_1"
        onClick={onDelete}
      />
    </div>
  );
};
