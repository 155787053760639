import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { SpaceAvatar } from 'APP/components/Avatar/SpaceAvatar/SpaceAvatar';
import { VerifiedBadge } from 'APP/components/VerifiedBadge/VerifiedBadge';
import { useTranslation } from 'APP/packages/translations';
import { Space } from 'STORE/Spaces/Space';
import { getFormatNumberToString } from 'UTILS/getFormatNumberToString';

import styles from './SpaceListGuestItem.styles.m.css';

interface ISpaceListItemProps {
  space: Space;
  onClick(): void;
}

export const SpaceListGuestItem: FC<ISpaceListItemProps> = observer((props) => {
  const { space, onClick } = props;
  const { t } = useTranslation();

  return (
    <>
      <div
        className={styles.listItem}
        role="button"
        tabIndex={0}
        onClick={onClick}
      >
        <SpaceAvatar
          className={styles.avatar}
          space={space}
        />

        <div className={styles.info}>
          <div className={styles.nameWrapper}>
            <h3 className={styles.name}>{space.name}</h3>
            {space.verified && <VerifiedBadge />}
          </div>

          <span className={styles.members}>
            {t('space_members_count').replace(
              '{0}',
              getFormatNumberToString(space.participantsCount)
            )}
          </span>
        </div>
      </div>
    </>
  );
});
