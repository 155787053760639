import Tasks from 'APP/Tasks';
import bus from 'APP/packages/bus';
import { broadcastSentMessage } from 'APP/packages/bus/adapters/messaging/sentMessage';
import Entities from 'APP/store';
import { MessageError } from 'STORE/Messages/Message/ChatMessage/ChatMessages.types';

import { clearInputPanel } from './clearInputPanel';
import { convertMessageToChatMessage } from './convertMessageToChatMessage/convertMessageToChatMessage';
import { sendNewMessageInQueue } from './sendNewMessageInQueue';

export const createNewMessages = async ({ messages = [] }) => {
  if (messages.length === 0) {
    return;
  }

  bus.messaging.broadcastSendMessage();

  const chatMessages = convertMessageToChatMessage({ messages });
  const activeGroupId = Entities.GroupsStore.activeGroupId;

  if (activeGroupId === chatMessages[0].groupId) {
    await Tasks.messaging.addMyFakeNewMessages({
      groupId: activeGroupId,
      messages: chatMessages,
    });

    // Todo need to review all cases of cleaning the input panel and remove unnecessary
    clearInputPanel(activeGroupId, messages);
  }

  chatMessages.forEach(async (chatMessage) => {
    if (chatMessage.payload.upload) {
      await chatMessage.payload.upload();
    }

    if (!chatMessage.hasError) {
      sendNewMessageInQueue({
        message: chatMessage,
        errorCallback: (error) =>
          Tasks.messaging.handleMessageError({
            error,
            message: chatMessage,
            messageErrorType: MessageError.Send,
          }),
      });
    } else {
      broadcastSentMessage(chatMessage.groupId, {
        messageUuid: chatMessage.clientUuid,
        messageId: chatMessage.id,
        isRequestFinished: false,
        hasError: true,
      });
    }
  });
};
