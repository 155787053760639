import Tasks from 'APP/Tasks';
import { IStreamChanged } from 'APP/model/call/callModel.types';
import Entities from 'STORE';

export async function handleBatchedActiveStreams(streams: IStreamChanged[]): Promise<void> {
  Entities.BackgroundStreams.replace(streams);
}

export async function handleChangeActiveStream(stream: IStreamChanged): Promise<void> {
  Tasks.streaming.updateActiveStream(stream);
}
