/**
 * Rest API
 * Documentation Rest API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CallFinishedResponseDataAllOf { 
    initiator?: string;
    initiatorNickName?: string;
    channelId?: string;
    duration?: string;
    reason?: CallFinishedResponseDataAllOfReasonEnum;
    callType?: CallFinishedResponseDataAllOfCallTypeEnum;
    finishTs?: string;
    finishInitiator?: string;
    finishInitiatorNickname?: string;
}
export enum CallFinishedResponseDataAllOfReasonEnum {
    Unanswered = 'UNANSWERED',
    Declined = 'DECLINED',
    Finished = 'FINISHED'
};
export enum CallFinishedResponseDataAllOfCallTypeEnum {
    P2P = 'P2P',
    Group = 'GROUP',
    Conference = 'CONFERENCE',
    Stream = 'STREAM'
};



