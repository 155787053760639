import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import Entities from 'STORE';
import { Space } from 'STORE/Spaces/Space';

export interface ISpaceMembersPopupPresenter {
  searchText: string;
  space: Space | null;
  hasBackButton: boolean;
  onSearch(searchText: string): void;
  onClose(): void;
  onBack(): void;
}

export interface ISpaceMembersPopupParams {
  spaceId: string;
}

export function useSpaceMembersPopupPresenter({
  params,
  onClose,
  onBack,
  popupInstance,
}: ICommonPopupsProps<ISpaceMembersPopupParams>): ISpaceMembersPopupPresenter {
  const { spaceId } = params!;

  const presenter = useLocalStore<ISpaceMembersPopupPresenter>(() => ({
    searchText: '',

    get space(): Space | null {
      return spaceId ? Entities.spacesStore.getById(spaceId) : null;
    },

    get hasBackButton(): boolean {
      return !!onBack;
    },

    onSearch(searchText: string): void {
      presenter.searchText = searchText;
    },

    onClose(): void {
      popupInstance.close();
      onClose?.();
    },

    onBack(): void {
      popupInstance.close();
      onBack?.();
    },
  }));

  useEffect(() => {
    if (
      (!Entities.spacesStore.isLoading && !presenter.space) ||
      !(presenter.space?.isOwner || presenter.space?.isAdmin)
    ) {
      presenter.onClose();
    }
  }, [presenter.space, presenter.space?.role, Entities.spacesStore.isLoading]);

  return presenter;
}
