import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, useRef, useState } from 'react';

import MoreIcon from 'ICONS/dots.svg';
import { MessageViewPageType } from 'MAIN/ChatView/Messages/Messages.types';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';
import { IconButton } from 'UIKIT/IconButton';

import { MessageActionsMenu } from '../../MessageActionsMenu/MessageActionsMenu';
import { disableQuoteAttrs } from '../../presenter/constants';
import styles from './SystemMessageMenu.styles.m.css';

interface SystemMessageMenuProps {
  message: ChatMessage;
  className?: string;
  messageViewPageType: MessageViewPageType;
}

export const SystemMessageMenu: FC<SystemMessageMenuProps> = observer((props) => {
  const { message, className, messageViewPageType } = props;
  const buttonRef = useRef(null);
  const [popoverIsOpen, setPopoverIsOpen] = useState(false);

  const classes = classNames(styles.menuPlaceholder, 'message-menu', className, {
    [styles.opened]: popoverIsOpen,
  });

  const placement = message.isLeftPosition ? 'right-start' : 'left-start';

  return (
    <div
      className={classes}
      {...disableQuoteAttrs}
    >
      {message.hasMenuItems && (
        <>
          <IconButton
            setRef={buttonRef}
            Icon={MoreIcon}
            className={classNames(styles.itemContainer, { [styles.opened]: popoverIsOpen })}
            iconClassName={styles.icon}
            dataTest="message-menu-button"
            theme="19"
          />
          <MessageActionsMenu
            reference={buttonRef}
            placement={placement}
            message={message}
            messageViewPageType={messageViewPageType}
            onChange={setPopoverIsOpen}
          />
        </>
      )}
    </div>
  );
});
