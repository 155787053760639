import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import Entities from 'STORE';
import { Space } from 'STORE/Spaces/Space';

interface ISpaceProfilePopupPresenter {
  space: Space | null;
  onClose(): void;
}

export interface ISpaceProfilePopupParams {
  spaceId: string;
}

export function useSpaceProfilePopupPresenter({
  params,
  popupInstance,
}: ICommonPopupsProps<ISpaceProfilePopupParams>): ISpaceProfilePopupPresenter {
  const { spaceId } = params!;

  const presenter = useLocalStore(() => ({
    get space(): Space | null {
      return spaceId ? Entities.spacesStore.getById(spaceId) : null;
    },

    onClose(): void {
      popupInstance.close();
    },
  }));

  useEffect(() => {
    if ((!Entities.spacesStore.isLoading && !presenter.space) || presenter.space?.isGuest) {
      presenter.onClose();
    }
  }, [presenter.space, Entities.spacesStore.isLoading]);

  return presenter;
}
