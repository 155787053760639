import { action, observable } from 'mobx';

import {
  CatalogType,
  ICatalogWidget,
  ICatalogItem,
  ISupportedLocale,
} from 'APP/model/catalog/catalogModel.types';

export class CatalogStore {
  @observable public widgets: ICatalogWidget[] = [];
  @observable public items: ICatalogItem[] = [];
  @observable public locales: ISupportedLocale[] = [];
  @observable public type: CatalogType = CatalogType.All;
  @observable public categoryId: string | null = null;
  @observable public searchText = '';
  @observable public hasError = false;
  @observable public scrollPosition = 0;
  @observable public widgetsScrollPosition: Record<string, number> = {};
  @observable public cursor: string | null = null;

  getWidgetById(id: string): ICatalogWidget | null {
    return this.widgets.find((widget) => widget.widgetId === id) || null;
  }

  @action
  setWidgets(widgets: ICatalogWidget[]): void {
    this.widgets = widgets;
  }

  @action
  setItems(items: ICatalogItem[]): void {
    this.items = items;
  }

  @action
  setLocales(locales: ISupportedLocale[]): void {
    this.locales = locales;
  }

  @action
  setType(type: CatalogType): void {
    this.type = type;
  }

  @action
  setCategoryId(id: string | null): void {
    this.categoryId = id;
  }

  @action
  setSearchText(text: string): void {
    this.searchText = text;
  }

  @action
  setError(state: boolean): void {
    this.hasError = state;
  }

  @action
  setScrollPosition(scrollTop: number): void {
    this.scrollPosition = scrollTop;
  }

  @action
  setWidgetScrollPosition(id: string, position: number): void {
    this.widgetsScrollPosition[id] = position;
  }

  @action
  setCursor(cursor: string | null): void {
    this.cursor = cursor;
  }

  @action
  resetCatalog(): void {
    this.widgets = [];
    this.items = [];
    this.hasError = false;
    this.scrollPosition = 0;
    this.widgetsScrollPosition = {};
    this.cursor = null;
  }

  @action
  reset(): void {
    this.locales = [];
    this.type = CatalogType.All;
    this.categoryId = null;
    this.searchText = '';

    this.resetCatalog();
  }
}
