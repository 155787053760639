import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { PermissionsTypes } from 'APP/model/callPermissions/callPermissionsModel.constants';
import { platformInfo } from 'APP/packages/platformInfo/platformInfo';
import { useTranslation } from 'APP/packages/translations';

import { CallMemberPermission } from '../Shared/CallMemberPermission';
import { CallPermission } from '../Shared/CallPermission';
import { useCallPermissionsPopupPresenter } from './CallPermissionsPopup.presenter';
import styles from './CallPermissionsPopup.styles.m.css';
import { CallCustomPermissionsBlock } from './components/CallCustomPermissionsBlock';

export const CallPermissionsPopup = observer(() => {
  const { t } = useTranslation();
  const presenter = useCallPermissionsPopupPresenter();

  if (!presenter.ongoingCall) {
    return null;
  }

  return (
    <Popup
      overlayContainerClassName={styles.overlayingPopup}
      className={classNames(styles.popup, { [styles.mobileBorder]: platformInfo.isMobile })}
      title={t('permissions_label')}
      isOpened
      onClose={presenter.onClose}
    >
      <div className={styles.wrapper}>
        {presenter.isViewOnly ? (
          <>
            <CallMemberPermission permissionType={PermissionsTypes.SwitchOnCamera} />
            <CallMemberPermission permissionType={PermissionsTypes.SwitchOnMic} />
            <CallMemberPermission permissionType={PermissionsTypes.ShareScreen} />
          </>
        ) : (
          <>
            <CallPermission permissionType={PermissionsTypes.SwitchOnCamera} />
            <CallPermission permissionType={PermissionsTypes.SwitchOnMic} />
            <CallPermission permissionType={PermissionsTypes.ShareScreen} />
            <CallCustomPermissionsBlock />
          </>
        )}
      </div>
    </Popup>
  );
});
