import { useLocalStore, useAsObservableSource } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { TranscriptionStatus } from 'APP/constants/messages';
import { useTranslation } from 'APP/packages/translations';
import IconText from 'ICONS/ic-text-16.svg';
import IconCollapse from 'ICONS/ic-up-16.svg';
import type { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

export const useVoicePayloadPresenter = (message: ChatMessage) => {
  const { t } = useTranslation();

  const source = useAsObservableSource({ message });

  const presenter = useLocalStore(() => ({
    isCollapsed: true,

    get isDisabled() {
      return source.message.isFake;
    },

    async onClick() {
      if (presenter.isProgress || source.message.isFake) {
        return;
      }
      if (presenter.isCollapsed) {
        presenter.isCollapsed = false;
        await Tasks.messaging.transcribeVoiceMessage(source.message);
        return;
      }
      presenter.isCollapsed = true;
    },

    get icon() {
      if (presenter.isProgress) {
        return null;
      }

      if (!presenter.isCollapsed) {
        return IconCollapse;
      }
      return IconText;
    },

    expand() {
      presenter.isCollapsed = false;
    },

    get isProgress() {
      return (
        source.message.payload.transcriptionStatus === TranscriptionStatus.InProgress &&
        !presenter.isCollapsed
      );
    },

    get hasError() {
      return (
        source.message.payload.transcriptionStatus === TranscriptionStatus.TranscriptionFailed ||
        source.message.payload.transcriptionText === ''
      );
    },

    get content() {
      if (source.message.payload.transcriptionText && !presenter.isCollapsed) {
        return source.message.renderPayload.transcriptionText;
      }

      if (presenter.hasError && !presenter.isCollapsed) {
        return t('common_unable_recognize_text');
      }

      return null;
    },
  }));

  return presenter;
};
