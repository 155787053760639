export const ESCAPE_CODE = 27;
export const UP_ARROW_KEY_CODE = 38;
export const DOWN_ARROW_KEY_CODE = 40;
export const LEFT_ARROW_KEY_CODE = 37;
export const RIGHT_ARROW_KEY_CODE = 39;
export const ENTER_KEY_CODE = 13;
export const K_KEY_CODE = 75;
export const U_KEY_CODE = 85;
export const DELETE_CODE = 8;
export const B_KEY_CODE = 66;
export const I_KEY_CODE = 73;
