import { useState } from 'react';

import { Popup } from 'STORE/App/Popups/Popup/Popup';

export interface IConfirmPopupPresenterParams {
  isChecked?: boolean;
  onSuccess?(isChecked: boolean): Promise<void> | void;
  onCancel?(): void;
  onClose?(): void;
}

interface IConfirmPopupPresenter {
  isLoading: boolean;
  isChecked: boolean;
  onCheckBoxChange(): void;
  onSuccess(): void;
  onCancel(): void;
  onClose(): void;
}

export interface IConfirmPopupResult {
  isConfirmed: boolean;
  isChecked?: boolean;
}

type TConfirmPopupResult = (data: IConfirmPopupResult) => void;

export function useConfirmPopupPresenter(
  {
    isChecked: defaultCheckedState,
    onSuccess: onSuccessCallback,
    onCancel: onCancelCallback,
    onClose: onCloseCallback,
  }: IConfirmPopupPresenterParams,
  popup: Popup,
  onResult?: TConfirmPopupResult
): IConfirmPopupPresenter {
  const [isChecked, setIsChecked] = useState(defaultCheckedState || false);
  const [isLoading, setIsLoading] = useState(false);

  const onCheckBoxChange = (): void => {
    setIsChecked(!isChecked);
  };

  const onSuccess = async (): Promise<void> => {
    if (onSuccessCallback) {
      setIsLoading(true);
      await onSuccessCallback(isChecked);
    }
    onResult?.({ isConfirmed: true, isChecked });
    popup.close();
  };

  const onCancel = (): void => {
    onCancelCallback?.();
    onResult?.({ isConfirmed: false });
    popup.close();
  };

  const onClose = (): void => {
    onCloseCallback?.();
    onResult?.({ isConfirmed: false });
    popup.close();
  };

  return {
    isLoading,
    isChecked,
    onCheckBoxChange,
    onSuccess,
    onCancel,
    onClose,
  };
}
