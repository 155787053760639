import { observer } from 'mobx-react';
import React from 'react';

import { AudioState } from 'MAIN/OngoingCall/Shared/AudioState/AudioState';
import { LargeCallAvatar } from 'MAIN/OngoingCall/Shared/Avatars/LargeCallAvatar/LargeCallAvatar';
import { CallActionsPanel } from 'MAIN/OngoingCall/Shared/CallActionsPanel/CallActionsPanel';
import Entities from 'STORE';

import { CallingPlaceholder } from '../Shared/CallingPlaceholder/CallingPlaceholder';
import { CompactCallViewHeader } from '../Shared/CompactCallViewHeader/CompactCallViewHeader';
import styles from './P2PAudioCall.styles.m.css';

export const P2PAudioCall = observer(() => {
  return (
    <div className={styles.container}>
      <CompactCallViewHeader />
      <div className={styles.content}>
        <div className={styles.avatarContainer}>
          <LargeCallAvatar avatar={Entities.OngoingCall.call.avatar} />
        </div>
        <CallingPlaceholder call={Entities.OngoingCall.call} />
        <AudioState
          opponent={Entities.OngoingCall.p2pOpponent}
          className={styles.audioState}
        />
      </div>
      <CallActionsPanel />
    </div>
  );
});
