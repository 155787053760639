import { observer } from 'mobx-react';
import React from 'react';
import { FormSpy } from 'react-final-form';

import { Field } from 'APP/packages/form/Field/Field';
import { Form } from 'APP/packages/form/Form/Form';
import { TextField } from 'APP/packages/form/TextField/TextField';
import TimeField from 'APP/packages/form/TimeField';
import { useTranslation } from 'APP/packages/translations';
import { Button } from 'UIKIT/Button/Button';
import { PopupFooter } from 'UIKIT/PopupFooter/PopupFooter';

import usePresenter from './presenter';
import styles from './styles.m.css';

const ScheduleSettingsForm = ({ common }) => {
  const { presenter, timeRef } = usePresenter({ common });
  const { t } = useTranslation();

  return (
    <>
      <Form
        onSubmit={presenter.onSubmit}
        initialValues={presenter.initialValues}
        subscription={{ submitting: true, invalid: true, validating: true }}
      >
        {({ handleSubmit, submitting, invalid, validating }) => (
          <>
            <div className={styles.container}>
              <h3 className={styles.title}>{t('common:schedule_message')}</h3>

              <form
                id="scheduleSettingsForm"
                className={styles.content}
                onSubmit={handleSubmit}
              >
                <Field
                  readOnly
                  className={styles.date}
                  component={TextField}
                  name="date"
                  input={{
                    value: presenter.date,
                    onFocus: presenter.onDateFocus,
                  }}
                />
                <Field
                  component={TimeField}
                  name="time"
                  setRef={timeRef}
                  validate={presenter.validateTime}
                />
              </form>
            </div>

            <PopupFooter className={styles.footer}>
              <Button
                theme="6"
                onClick={presenter.onClose}
                isDisabled={submitting}
              >
                {t('common_cancel')}
              </Button>
              <Button
                theme="1"
                type="submit"
                form="scheduleSettingsForm"
                isLoading={submitting}
                isDisabled={invalid || validating}
              >
                {t('common:common_schedule')}
              </Button>
            </PopupFooter>

            <FormSpy
              subscription={{ values: true }}
              onChange={presenter.onChange}
            />
          </>
        )}
      </Form>
    </>
  );
};

export default observer(ScheduleSettingsForm);
