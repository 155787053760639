import { observer } from 'mobx-react';
import React, { FC } from 'react';

import TimeIcon from 'ICONS/ic-clock.svg';
import DoneIcon from 'ICONS/ic-done.svg';
import { FAB } from 'UIKIT/FAB/FAB';

import { useScheduleButtonPresenter } from './ScheduleButton.presenter';
import styles from './ScheduleButton.style.m.css';

interface IScheduleButtonProps {
  className?: string;
}

export const ScheduleButton: FC<IScheduleButtonProps> = observer(({ className }) => {
  const presenter = useScheduleButtonPresenter();

  return (
    <div className={className}>
      <FAB
        size={'30'}
        className={styles.button}
        onClick={presenter.onSubmit}
        Icon={presenter.isEditMode ? DoneIcon : TimeIcon}
        theme={'14'}
        isDisabled={presenter.isDisabled}
        dataTest="send-message-button"
      />
    </div>
  );
});
