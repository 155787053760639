import { subscribe, broadcast } from '../../eventBus';

function getBusStation() {
  return 'scroll-messages';
}

export const subscribeScrollMessages = (callback) => {
  return subscribe(getBusStation(), callback);
};

export const broadcastScrollMessages = () => {
  broadcast(getBusStation());
};
