import { useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import Entities from 'APP/store';

export default () => {
  const navigateTo = useNavigateTo();

  const presenter = useLocalStore(() => ({
    searchString: '',

    setSearchString(value) {
      presenter.searchString = value;
    },

    handleContactClick: async (contact) => {
      if (!contact.group) {
        await Tasks.group.createP2PGroup(contact.id);
      }
      navigateTo({
        to: `/${contact.group.route}/${contact.group.id}`,
      });
    },

    get contacts() {
      return Entities.UsersStore.searchContactsByString(presenter.searchString);
    },

    get isLoading() {
      return Entities.UsersStore.isUserContactsLoading;
    },

    get isSearchMode() {
      return presenter.searchString.length > 0;
    },
  }));

  return presenter;
};
