import { updateUsersDisplayData } from 'APP/Tasks/users/updateUsersDisplayData/updateUsersDisplayData';

import { GroupsEvent } from '../GroupsEvent';

export class UserWasBlockedByMe extends GroupsEvent {
  process() {
    const { userId } = this.eventPayload;

    updateUsersDisplayData([userId]);
  }
}
