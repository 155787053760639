import { AccountInfoResponse } from 'APP/packages/api/types/model/accountInfoResponse';
import { BooleanResponse } from 'APP/packages/api/types/model/booleanResponse';
import { GetClientConfigRequest } from 'APP/packages/api/types/model/getClientConfigRequest';
import { GetClientConfigResponse } from 'APP/packages/api/types/model/getClientConfigResponse';
import { LinkExternalAuthAccountRequest } from 'APP/packages/api/types/model/linkExternalAuthAccountRequest';
import { LinkExternalAuthAccountResponse } from 'APP/packages/api/types/model/linkExternalAuthAccountResponse';
import { RegisterResponse } from 'APP/packages/api/types/model/registerResponse';
import { UnlinkExternalAuthAccountRequest } from 'APP/packages/api/types/model/unlinkExternalAuthAccountRequest';
import { VerifyOauthTokenRequest } from 'APP/packages/api/types/model/verifyOauthTokenRequest';

import { request } from '../request/request';
import { AbortConfirmationRequest } from '../types/model/abortConfirmationRequest';
import { ConfirmBySmsResponse } from '../types/model/confirmBySmsResponse';
import { ConfirmVerifyCodeRequest } from '../types/model/confirmVerifyCodeRequest';
import { VerifyCodeRequest } from '../types/model/verifyCodeRequest';

const ROOT_API = 'authorization';

export const login = (data: any): Promise<any> => {
  return request({
    rootApi: ROOT_API,
    url: '/login',
    method: 'POST',
    body: data,
    version: 'v2',
  });
};

export const requestVerifyOauthToken = (
  data: VerifyOauthTokenRequest
): Promise<RegisterResponse> => {
  return request({
    rootApi: ROOT_API,
    url: '/requestVerifyOauthToken',
    method: 'POST',
    body: data,
    version: 'v2',
  });
};

export const logout = (): Promise<any> => {
  return request({
    rootApi: ROOT_API,
    url: '/logout',
    method: 'POST',
    version: 'v1',
  });
};

export const getConnectionToken = (): Promise<any> => {
  return request({
    rootApi: ROOT_API,
    url: '/getConnectionToken',
    method: 'POST',
    version: 'v1',
  });
};

export const getClientConfig = (data: GetClientConfigRequest): Promise<GetClientConfigResponse> => {
  return request<GetClientConfigResponse>({
    rootApi: ROOT_API,
    url: '/getClientConfig',
    method: 'POST',
    version: 'v2',
    body: data,
  });
};

export const getCountriesPhoneCode = (): Promise<any> => {
  return request({
    rootApi: ROOT_API,
    url: '/countriesPhoneCode',
    method: 'POST',
    version: 'v2',
  });
};

export const validatePhone = (data: any): Promise<any> => {
  return request({
    rootApi: ROOT_API,
    url: '/validatePhone',
    method: 'POST',
    version: 'v1',
    body: data,
  });
};

export const requestVerifyCode = (data: any): Promise<any> => {
  return request({
    rootApi: ROOT_API,
    url: '/requestVerifyCode',
    method: 'POST',
    version: 'v1',
    body: data,
  });
};

export const requestVerifyCodeV2 = (data: any): Promise<any> => {
  return request({
    rootApi: ROOT_API,
    url: '/requestVerifyCode',
    method: 'POST',
    version: 'v2',
    body: data,
  });
};

export const changePhoneRequestVeryCodeV2 = (
  data: VerifyCodeRequest
): Promise<RegisterResponse> => {
  return request({
    rootApi: ROOT_API,
    url: '/changePhone/requestVerifyCode',
    method: 'POST',
    version: 'v2',
    body: data,
  });
};

export const changePhoneConfirmVerifyCode = (
  data: ConfirmVerifyCodeRequest
): Promise<ConfirmBySmsResponse> => {
  return request({
    rootApi: ROOT_API,
    url: '/changePhone/confirmVerifyCode',
    method: 'POST',
    version: 'v2',
    body: data,
  });
};

export const changePhoneAbortConfirmation = (): Promise<BooleanResponse> => {
  return request({
    rootApi: ROOT_API,
    url: '/changePhone/abortConfirmation',
    method: 'POST',
    version: 'v2',
  });
};

export const abortConfirmation = (data: AbortConfirmationRequest): Promise<BooleanResponse> => {
  return request({
    rootApi: ROOT_API,
    url: '/abortConfirmation',
    method: 'POST',
    version: 'v2',
    body: data,
  });
};

export const confirmVerifyCode = (data: any): Promise<any> => {
  return request({
    rootApi: ROOT_API,
    url: '/confirmVerifyCode',
    method: 'POST',
    version: 'v2',
    body: data,
  });
};

export const terminateSessions = (data: any): Promise<any> => {
  return request({
    rootApi: ROOT_API,
    url: '/terminateSessions',
    method: 'POST',
    version: 'v2',
    body: data,
  });
};

export const terminateAllOtherSessions = (): Promise<any> => {
  return request({
    rootApi: ROOT_API,
    url: '/terminateAllOtherSessions',
    method: 'POST',
    version: 'v2',
  });
};

export const getMyAccountInfo = (): Promise<AccountInfoResponse> => {
  return request({
    rootApi: ROOT_API,
    url: '/getMyAccountInfo',
    method: 'POST',
    version: 'v2',
  });
};

export const linkAuthAccount = (
  data: LinkExternalAuthAccountRequest
): Promise<LinkExternalAuthAccountResponse> => {
  return request({
    rootApi: ROOT_API,
    url: '/linkExternalAuthAccount',
    method: 'POST',
    version: 'v2',
    body: data,
  });
};

export const unlinkAccount = (data: UnlinkExternalAuthAccountRequest): Promise<BooleanResponse> => {
  return request({
    rootApi: ROOT_API,
    url: '/unlinkExternalAuthAccount',
    method: 'POST',
    version: 'v2',
    body: data,
  });
};
