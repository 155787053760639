import { v4 as uuidv4 } from 'uuid';

import dateService from 'APP/packages/date';
import Entities from 'APP/store';

import { getPayloadByType } from '../getPayloadByType/getPayloadByType';

export default (groupId, message, date) => {
  const time = dateService.now().getTime();
  const clientUuid = message.clientUuid || uuidv4();

  return {
    id: clientUuid,
    clientUuid,
    clientTime: time.toString(),
    serverTime: time.toString(),
    delay: (date.getTime() - time).toString(),
    groupId: message.groupId || groupId,
    senderId: Entities.UsersStore.Me.id,
    quotedMessageId: message.quotedMessage && message.quotedMessage.id,
    quotedMessage: message.quotedMessage,
    payload: getPayloadByType(message.type, message.data),
  };
};
