import PromiseQueue from 'p-queue/dist';

import Tasks from 'APP/Tasks';
import { ModelError } from 'APP/model/error/errors/ModelError';
import { messageModel } from 'APP/model/model';
import { broadcastSentMessage } from 'APP/packages/bus/adapters/messaging/sentMessage';
import type { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

const queue: Record<string, PromiseQueue> = {};

interface ISendNewMessageInQueueData {
  message: ChatMessage;
  errorCallback: (error: ModelError) => void;
  retry?: boolean;
}

async function sendRequest(data: ISendNewMessageInQueueData): Promise<void> {
  const { message, errorCallback, retry = false } = data;

  try {
    message.setIsProgress(true);
    const response = await messageModel.sendNewMessage({
      ...message.toJSON(),
      retry,
    });

    if (!message.isEditQueue) {
      Tasks.messaging.updateOldOrFakeMessages({
        messages: [response.message],
        groupId: message.groupId,
        prevMessageTs: Number(response.previousMessageTs),
      });
    } else {
      message.setId(response.message.id);
    }

    message.setIsProgress(false);

    broadcastSentMessage(message.groupId, {
      messageUuid: response.message.clientUuid,
      messageId: response.message.id,
      isRequestFinished: true,
      hasError: false,
    });
  } catch (error) {
    errorCallback(error);
    message.setIsProgress(false);

    broadcastSentMessage(message.groupId, {
      messageUuid: message.clientUuid,
      messageId: message.id,
      isRequestFinished: true,
      hasError: true,
    });
  }
}

export const sendNewMessageInQueue = async (data: ISendNewMessageInQueueData): Promise<void> => {
  if (!queue[data.message.groupId]) {
    queue[data.message.groupId] = new PromiseQueue({ concurrency: 1 });
  }

  queue[data.message.groupId].add(() => sendRequest(data));
};
