import { useCallback } from 'react';

import { showConfirm } from 'APP/Tasks/app/showConfirm/showConfirm';
import { useTranslation } from 'APP/packages/translations';
import { OpenUrlButtonPayload } from 'STORE/Messages/Message/Payload/Buttons/OpenUrlButtonPayload';

interface IOpenUrlCommandButtonPresenter {
  onClick(): void;
}

export const useOpenUrlCommandButtonPresenter = (
  buttonPayload: OpenUrlButtonPayload
): IOpenUrlCommandButtonPresenter => {
  const { t } = useTranslation();

  const onClick = useCallback(async () => {
    const confirmResult = await showConfirm({
      text: t('common_open_link_alert', {
        0: buttonPayload.url,
        interpolation: { escapeValue: false },
      }),
      primaryText: t('common_open_action'),
      secondaryText: t('common_cancel'),
    });

    if (confirmResult) {
      window.open(buttonPayload.url, '_blank', 'noreferrer');
    }
  }, [buttonPayload.url]);

  return { onClick };
};
