import { IScheduledMessage } from 'APP/model/message/messageModel.types';
import { messageModel } from 'APP/model/model';
import Entities from 'STORE';

import updateScheduledMessages from '../../messaging/updateScheduledMessages';
import { createScheduledGroup } from '../loadScheduledMessages/loadScheduledMessages';

export async function loadAllScheduledMessages(): Promise<void> {
  try {
    const messages = await messageModel.getAllScheduledMessages();

    const groupedMessages = messages.reduce((result, message) => {
      if (!result[message.groupId]) {
        result[message.groupId] = [];
      }

      result[message.groupId].push(message);

      return result;
    }, {} as Record<string, IScheduledMessage[]>);

    Object.keys(groupedMessages).forEach((groupId) => {
      const group = Entities.GroupsStore.getGroupById(groupId);

      if (group) {
        createScheduledGroup(group);

        updateScheduledMessages({
          groupId,
          messages: groupedMessages[groupId],
        });
      }
    });
  } catch {
    //
  }
}
