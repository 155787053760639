import { useLocalStore } from 'mobx-react';

import dateService from 'APP/packages/date';

export default () => {
  const presenter = useLocalStore(() => ({
    rawDate: dateService.nowWithOffset(0, 0, 10),

    get date() {
      return new Date(presenter.rawDate);
    },

    get defaultDate() {
      return dateService.nowWithOffset(0, 0, 10);
    },

    setDate: (date) => {
      presenter.rawDate = new Date(presenter.rawDate);
      presenter.rawDate.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
    },

    setTime: (time) => {
      const values = dateService.parseTime(time);
      if (values) {
        presenter.rawDate = new Date(presenter.rawDate);
        presenter.rawDate.setHours(values[0], values[1], 0);
      }
    },
  }));

  return presenter;
};
