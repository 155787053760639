import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { MouseEvent } from 'react';

import Tasks from 'APP/Tasks';
import { CallType } from 'APP/model/call/callModel.types';
import { useTranslation } from 'APP/packages/translations';
import Entities from 'STORE';
import { Group } from 'STORE/Groups/Group';

interface IScheduledCallsStartButtonPresenter {
  group: Group | null;
  startCall(): Promise<void>;
  joinToCall(): Promise<void>;
  onButtonClick(e: MouseEvent<HTMLButtonElement>): void;
}

interface IScheduledCallsStartButtonParams {
  groupId: string;
  startTs: number;
  initiatorId: string;
  onClick?(): void;
}

const MAX_START_CALL_GAP = 1000 * 60 * 5;

export function useScheduledCallsStartButtonPresenter({
  groupId,
  onClick,
  initiatorId,
  startTs,
}: IScheduledCallsStartButtonParams): IScheduledCallsStartButtonPresenter {
  const source = useAsObservableSource({ groupId, startTs, initiatorId });
  const { t } = useTranslation();

  const presenter = useLocalStore<IScheduledCallsStartButtonPresenter>(() => ({
    get group(): Group | null {
      return Entities.GroupsStore.getGroupById(source.groupId);
    },

    onButtonClick(e: MouseEvent<HTMLButtonElement>): void {
      e.preventDefault();
      e.stopPropagation();
      presenter.group?.hasCall ? presenter.joinToCall() : presenter.startCall();
    },

    async startCall(): Promise<void> {
      if (!presenter.group) {
        return;
      }

      let canStart = true;

      if (startTs - Date.now() > MAX_START_CALL_GAP) {
        canStart = await Tasks.app.showConfirm({
          text: t(
            Entities.UsersStore.Me.id === initiatorId
              ? 'call_early_start'
              : 'call_start_not_initiator'
          ),
          primaryText: t('common_yes'),
          secondaryText: t('common_no'),
        });
      }

      if (canStart) {
        await Tasks.group.setActiveGroup({
          groupId: presenter.group.id,
        });
        Tasks.calls.startAudioCall({ groupId });
        onClick && onClick();
      }
    },

    async joinToCall(): Promise<void> {
      if (!presenter.group || !presenter.group.backgroundCall) {
        return;
      }

      await Tasks.group.setActiveGroup({
        groupId: presenter.group.id,
      });

      Tasks.calls.joinToCall({
        groupId: presenter.group.id,
        callType: presenter.group.backgroundCall.callType,
        channelId: presenter.group.backgroundCall.channelId,
        withAudio: presenter.group.backgroundCall.callType === CallType.P2P,
        withVideo: false,
        providerType: presenter.group.backgroundCall.callProvider,
      });
      onClick && onClick();
    },
  }));

  return presenter;
}
