import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { GroupType } from 'APP/model/group/groupModel.types';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import Entities from 'STORE';
import { Space } from 'STORE/Spaces/Space';

import { IChannelFormData } from '../../components/ChannelForm/ChannelForm.types';

export interface ICreateSpaceChannelPopupParams {
  spaceId: string;
}

interface ICreateSpaceChannelPopupPresenter {
  initialValues: Partial<IChannelFormData>;
  space: Space | null;
  onSubmit(channelData: IChannelFormData): Promise<void>;
}

export function useCreateSpaceChannelPopupPresenter({
  popupInstance,
  params,
}: ICommonPopupsProps<ICreateSpaceChannelPopupParams>): ICreateSpaceChannelPopupPresenter {
  const { spaceId } = params!;

  const presenter = useLocalStore<ICreateSpaceChannelPopupPresenter>(() => ({
    initialValues: {
      type: GroupType.ChannelOpen,
    },

    get space(): Space | null {
      return Entities.spacesStore.getById(spaceId);
    },

    async onSubmit(channelData: IChannelFormData): Promise<void> {
      await Tasks.group.createGroupAndInvite({
        ...channelData,
        spaceId: presenter.space!.id,
        deepLinkAlias: channelData.type === GroupType.ChannelOpen ? channelData.deepLinkAlias : '',
      });
      popupInstance.close();
    },
  }));

  useEffect(() => {
    if (!presenter.space) {
      popupInstance.close();
    }
  }, [presenter.space]);

  return presenter;
}
