import classNames from 'classnames';
import React, { FC, memo } from 'react';

import { useTranslation } from 'APP/packages/translations';
import IcDeleteIcon from 'ICONS/ic-delete.svg';
import popupItemStyles from 'UIKIT/PopupItem/styles.m.scss';

import { useDeleteAccountPresenter } from './DeleteAccount.presenter';
import styles from './DeleteAccount.styles.m.css';

export const DeleteAccount: FC = memo(() => {
  const { t } = useTranslation();

  const presenter = useDeleteAccountPresenter();

  return (
    <button
      onClick={presenter.onDelete}
      className={classNames(
        styles.container,
        popupItemStyles.container,
        popupItemStyles.interactive
      )}
    >
      <IcDeleteIcon className={styles.icon} />
      <p className={styles.text}>{t('common_delete_account')}</p>
    </button>
  );
});
