import { request } from '../request/request';
import { AddSpaceCollectionItemsRequest } from '../types/model/addSpaceCollectionItemsRequest';
import { BanUsersInSpaceRequest } from '../types/model/banUsersInSpaceRequest';
import { BooleanResponse } from '../types/model/booleanResponse';
import { ChangeSpaceOwnerRequest } from '../types/model/changeSpaceOwnerRequest';
import { CreateMarketRequest } from '../types/model/createMarketRequest';
import { CreateSpaceCollectionRequest } from '../types/model/createSpaceCollectionRequest';
import { CreateSpaceRequest } from '../types/model/createSpaceRequest';
import { DeleteMarketRequest } from '../types/model/deleteMarketRequest';
import { DeleteSpaceCollectionItemsRequest } from '../types/model/deleteSpaceCollectionItemsRequest';
import { DeleteSpaceRequest } from '../types/model/deleteSpaceRequest';
import { GenerateNewSpaceDeepLinkRequest } from '../types/model/generateNewSpaceDeepLinkRequest';
import { GetAvailableGroupsResponse } from '../types/model/getAvailableGroupsResponse';
import { GetBannedSpaceUsersRequest } from '../types/model/getBannedSpaceUsersRequest';
import { GetBannedUsersInSpaceResponse } from '../types/model/getBannedUsersInSpaceResponse';
import { GetKeywordFiltersResponse } from '../types/model/getKeywordFiltersResponse';
import { GetMarketsRequest } from '../types/model/getMarketsRequest';
import { GetSpaceCollectionIdsWithItems } from '../types/model/getSpaceCollectionIdsWithItems';
import { GetSpaceCollectionItemsRequest } from '../types/model/getSpaceCollectionItemsRequest';
import { GetSpaceCollectionItemsResponse } from '../types/model/getSpaceCollectionItemsResponse';
import { GetSpaceCollectionsRequest } from '../types/model/getSpaceCollectionsRequest';
import { GetSpaceKeywordFiltersRequest } from '../types/model/getSpaceKeywordFiltersRequest';
import { GetSpaceRequest } from '../types/model/getSpaceRequest';
import { GetSpaceUserRolesRequest } from '../types/model/getSpaceUserRolesRequest';
import { GetSpacesPageRequest } from '../types/model/getSpacesPageRequest';
import { JoinSpaceRequest } from '../types/model/joinSpaceRequest';
import { LeaveSpaceRequest } from '../types/model/leaveSpaceRequest';
import { MarketResponse } from '../types/model/marketResponse';
import { MarketsResponse } from '../types/model/marketsResponse';
import { MoveGroupsRequest } from '../types/model/moveGroupsRequest';
import { PersonalizedSpaceResponse } from '../types/model/personalizedSpaceResponse';
import { PinSpacesRequest } from '../types/model/pinSpacesRequest';
import { PinSpacesResponse } from '../types/model/pinSpacesResponse';
import { SetSpaceKeywordFiltersRequest } from '../types/model/setSpaceKeywordFiltersRequest';
import { SetSpaceObsceneFilterRequest } from '../types/model/setSpaceObsceneFilterRequest';
import { SpaceCollectionIdsResponse } from '../types/model/spaceCollectionIdsResponse';
import { SpaceCollectionItemsResponse } from '../types/model/spaceCollectionItemsResponse';
import { SpaceCollectionRequest } from '../types/model/spaceCollectionRequest';
import { SpaceCollectionResponse } from '../types/model/spaceCollectionResponse';
import { SpaceCollectionsResponse } from '../types/model/spaceCollectionsResponse';
import { SpaceDeepLinkExistsRequest } from '../types/model/spaceDeepLinkExistsRequest';
import { SpacePageResponse } from '../types/model/spacePageResponse';
import { SpaceParticipantsChangeRequest } from '../types/model/spaceParticipantsChangeRequest';
import { SpaceUserRolesResponse } from '../types/model/spaceUserRolesResponse';
import { UnbanUsersInSpaceRequest } from '../types/model/unbanUsersInSpaceRequest';
import { UnlinkGroups } from '../types/model/unlinkGroups';
import { UpdateGroupVisibilityRequest } from '../types/model/updateGroupVisibilityRequest';
import { UpdateGroupsVisibilityRequest } from '../types/model/updateGroupsVisibilityRequest';
import { UpdateMarketRequest } from '../types/model/updateMarketRequest';
import { UpdateSpaceCollectionRequest } from '../types/model/updateSpaceCollectionRequest';
import { UpdateSpaceRequest } from '../types/model/updateSpaceRequest';
import { ValidateMarketUrlRequest } from '../types/model/validateMarketUrlRequest';

const ROOT_API = 'space';

export const getSpace = (data: GetSpaceRequest): Promise<PersonalizedSpaceResponse> => {
  return request<PersonalizedSpaceResponse>({
    rootApi: ROOT_API,
    version: 'v1',
    url: '/getSpace',
    method: 'POST',
    body: data,
  });
};

export const getSpacesPage = (data: GetSpacesPageRequest): Promise<SpacePageResponse> => {
  return request<SpacePageResponse>({
    rootApi: ROOT_API,
    version: 'v1',
    url: '/getSpacesPage',
    method: 'POST',
    body: data,
  });
};

export const createSpace = (data: CreateSpaceRequest): Promise<PersonalizedSpaceResponse> => {
  return request<PersonalizedSpaceResponse>({
    rootApi: ROOT_API,
    version: 'v1',
    url: '/createSpace',
    method: 'POST',
    body: data,
  });
};

export const updateSpace = (data: UpdateSpaceRequest): Promise<PersonalizedSpaceResponse> => {
  return request<PersonalizedSpaceResponse>({
    rootApi: ROOT_API,
    version: 'v1',
    url: '/updateSpace',
    method: 'POST',
    body: data,
  });
};

export const deepLinkExists = (data: SpaceDeepLinkExistsRequest): Promise<BooleanResponse> => {
  return request<BooleanResponse>({
    rootApi: ROOT_API,
    version: 'v1',
    url: '/deepLinkExists',
    method: 'POST',
    body: data,
  });
};

export const generateNewDeepLink = (
  data: GenerateNewSpaceDeepLinkRequest
): Promise<PersonalizedSpaceResponse> => {
  return request<PersonalizedSpaceResponse>({
    rootApi: ROOT_API,
    version: 'v1',
    url: '/generateNewDeepLink',
    method: 'POST',
    body: data,
  });
};

export const deleteSpace = (data: DeleteSpaceRequest): Promise<BooleanResponse> => {
  return request<BooleanResponse>({
    rootApi: ROOT_API,
    version: 'v1',
    url: '/deleteSpace',
    method: 'POST',
    body: data,
  });
};

export const joinSpace = (data: JoinSpaceRequest): Promise<PersonalizedSpaceResponse> => {
  return request<PersonalizedSpaceResponse>({
    rootApi: ROOT_API,
    version: 'v1',
    url: '/joinSpace',
    method: 'POST',
    body: data,
  });
};

export const leaveSpace = (data: LeaveSpaceRequest): Promise<PersonalizedSpaceResponse> => {
  return request<PersonalizedSpaceResponse>({
    rootApi: ROOT_API,
    version: 'v1',
    url: '/leaveSpace',
    method: 'POST',
    body: data,
  });
};

export const moveGroupsToSpace = (data: MoveGroupsRequest): Promise<PersonalizedSpaceResponse> => {
  return request<PersonalizedSpaceResponse>({
    rootApi: ROOT_API,
    version: 'v1',
    url: '/moveGroupsToSpace',
    method: 'POST',
    body: data,
  });
};

export const unlinkGroups = (data: UnlinkGroups): Promise<BooleanResponse> => {
  return request<BooleanResponse>({
    rootApi: ROOT_API,
    version: 'v1',
    url: '/unlinkGroups',
    method: 'POST',
    body: data,
  });
};

export const getUserRoles = (data: GetSpaceUserRolesRequest): Promise<SpaceUserRolesResponse> => {
  return request<SpaceUserRolesResponse>({
    rootApi: ROOT_API,
    version: 'v1',
    url: '/getUserRoles',
    method: 'POST',
    body: data,
  });
};

export const removeParticipants = (
  data: SpaceParticipantsChangeRequest
): Promise<BooleanResponse> => {
  return request<BooleanResponse>({
    rootApi: ROOT_API,
    version: 'v1',
    url: '/removeParticipants',
    method: 'POST',
    body: data,
  });
};

export const promoteToAdmin = (data: SpaceParticipantsChangeRequest): Promise<BooleanResponse> => {
  return request<BooleanResponse>({
    rootApi: ROOT_API,
    version: 'v1',
    url: '/promoteToAdmin',
    method: 'POST',
    body: data,
  });
};

export const revokeFromAdmin = (data: SpaceParticipantsChangeRequest): Promise<BooleanResponse> => {
  return request<BooleanResponse>({
    rootApi: ROOT_API,
    version: 'v1',
    url: '/revokeFromAdmin',
    method: 'POST',
    body: data,
  });
};

export const updateGroupVisibility = (
  data: UpdateGroupVisibilityRequest
): Promise<BooleanResponse> => {
  return request<BooleanResponse>({
    rootApi: ROOT_API,
    version: 'v1',
    url: '/updateGroupVisibility',
    method: 'POST',
    body: data,
  });
};

export const updateGroupsVisibility = (
  data: UpdateGroupsVisibilityRequest
): Promise<BooleanResponse> => {
  return request<BooleanResponse>({
    rootApi: ROOT_API,
    version: 'v1',
    url: '/updateGroupsVisibility',
    method: 'POST',
    body: data,
  });
};

export const getAvailableGroups = (data: GetSpaceRequest): Promise<GetAvailableGroupsResponse> => {
  return request<GetAvailableGroupsResponse>({
    rootApi: ROOT_API,
    version: 'v1',
    url: '/getAvailableGroups',
    method: 'POST',
    body: data,
  });
};

export const createMarket = (data: CreateMarketRequest): Promise<MarketResponse> => {
  return request<MarketResponse>({
    rootApi: ROOT_API,
    version: 'v1',
    url: '/createMarket',
    method: 'POST',
    body: data,
  });
};

export const getMarkets = (data: GetMarketsRequest): Promise<MarketsResponse> => {
  return request<MarketsResponse>({
    rootApi: ROOT_API,
    version: 'v1',
    url: '/getMarkets',
    method: 'POST',
    body: data,
  });
};

export const updateMarket = (data: UpdateMarketRequest): Promise<MarketResponse> => {
  return request<MarketResponse>({
    rootApi: ROOT_API,
    version: 'v1',
    url: '/updateMarket',
    method: 'POST',
    body: data,
  });
};

export const deleteMarket = (data: DeleteMarketRequest): Promise<BooleanResponse> => {
  return request<BooleanResponse>({
    rootApi: ROOT_API,
    version: 'v1',
    url: '/deleteMarket',
    method: 'POST',
    body: data,
  });
};

export const banUsers = (data: BanUsersInSpaceRequest): Promise<BooleanResponse> => {
  return request<BooleanResponse>({
    rootApi: ROOT_API,
    version: 'v1',
    url: '/banUsers',
    method: 'POST',
    body: data,
  });
};

export const unbanUsers = (data: UnbanUsersInSpaceRequest): Promise<BooleanResponse> => {
  return request<BooleanResponse>({
    rootApi: ROOT_API,
    version: 'v1',
    url: '/unbanUsers',
    method: 'POST',
    body: data,
  });
};

export const getBannedUsers = (
  data: GetBannedSpaceUsersRequest
): Promise<GetBannedUsersInSpaceResponse> => {
  return request<GetBannedUsersInSpaceResponse>({
    rootApi: ROOT_API,
    version: 'v1',
    url: '/getBannedUsers',
    method: 'POST',
    body: data,
  });
};

export const getCollections = (
  data: GetSpaceCollectionsRequest
): Promise<SpaceCollectionsResponse> => {
  return request<SpaceCollectionsResponse>({
    rootApi: ROOT_API,
    version: 'v1',
    url: '/getCollections',
    method: 'POST',
    body: data,
  });
};

export const createCollection = (
  data: CreateSpaceCollectionRequest
): Promise<SpaceCollectionResponse> => {
  return request<SpaceCollectionResponse>({
    rootApi: ROOT_API,
    version: 'v1',
    url: '/createCollection',
    method: 'POST',
    body: data,
  });
};

export const updateCollection = (
  data: UpdateSpaceCollectionRequest
): Promise<SpaceCollectionResponse> => {
  return request<SpaceCollectionResponse>({
    rootApi: ROOT_API,
    version: 'v1',
    url: '/updateCollection',
    method: 'POST',
    body: data,
  });
};

export const addCollectionItems = (
  data: AddSpaceCollectionItemsRequest
): Promise<SpaceCollectionItemsResponse> => {
  return request<SpaceCollectionItemsResponse>({
    rootApi: ROOT_API,
    version: 'v1',
    url: '/addCollectionItems',
    method: 'POST',
    body: data,
  });
};

export const getCollectionIdsWithItems = (
  data: GetSpaceCollectionIdsWithItems
): Promise<SpaceCollectionIdsResponse> => {
  return request<SpaceCollectionIdsResponse>({
    rootApi: ROOT_API,
    version: 'v1',
    url: '/getCollectionIdsWithItems',
    method: 'POST',
    body: data,
  });
};

export const deleteCollection = (data: SpaceCollectionRequest): Promise<BooleanResponse> => {
  return request<BooleanResponse>({
    rootApi: ROOT_API,
    version: 'v1',
    url: '/deleteCollection',
    method: 'POST',
    body: data,
  });
};

export const getCollectionItems = (
  data: GetSpaceCollectionItemsRequest
): Promise<GetSpaceCollectionItemsResponse> => {
  return request<GetSpaceCollectionItemsResponse>({
    rootApi: ROOT_API,
    version: 'v1',
    url: '/getCollectionItems',
    method: 'POST',
    body: data,
  });
};

export const deleteCollectionItems = (
  data: DeleteSpaceCollectionItemsRequest
): Promise<BooleanResponse> => {
  return request<BooleanResponse>({
    rootApi: ROOT_API,
    version: 'v1',
    url: '/deleteCollectionItems',
    method: 'POST',
    body: data,
  });
};

export const validateMarketUrl = (data: ValidateMarketUrlRequest): Promise<BooleanResponse> => {
  return request<BooleanResponse>({
    rootApi: ROOT_API,
    version: 'v1',
    url: '/validateMarketUrl',
    method: 'POST',
    body: data,
  });
};

export const pinSpaces = (data: PinSpacesRequest): Promise<PinSpacesResponse> => {
  return request({
    rootApi: ROOT_API,
    version: 'v1',
    url: '/pinSpaces',
    method: 'POST',
    body: data,
  });
};

export const unpinSpaces = (data: PinSpacesRequest): Promise<PinSpacesResponse> => {
  return request({
    rootApi: ROOT_API,
    version: 'v1',
    url: '/unpinSpaces',
    method: 'POST',
    body: data,
  });
};

export const changeOwnership = (data: ChangeSpaceOwnerRequest): Promise<BooleanResponse> => {
  return request({
    rootApi: ROOT_API,
    version: 'v1',
    url: '/changeOwnership',
    method: 'POST',
    body: data,
  });
};

export function setObsceneFilter(data: SetSpaceObsceneFilterRequest): Promise<BooleanResponse> {
  return request<BooleanResponse>({
    rootApi: ROOT_API,
    version: 'v1',
    url: '/setObsceneFilter',
    method: 'POST',
    body: data,
  });
}

export function getKeywordFilters(
  data: GetSpaceKeywordFiltersRequest
): Promise<GetKeywordFiltersResponse> {
  return request<GetKeywordFiltersResponse>({
    rootApi: ROOT_API,
    version: 'v1',
    url: '/getKeywordFilters',
    method: 'POST',
    body: data,
  });
}

export function setKeywordFilters(data: SetSpaceKeywordFiltersRequest): Promise<BooleanResponse> {
  return request<BooleanResponse>({
    rootApi: ROOT_API,
    version: 'v1',
    url: '/setKeywordFilters',
    method: 'POST',
    body: data,
  });
}
