import { v4 as uuidv4 } from 'uuid';

import logger from 'APP/packages/logger';

import createBus from './bus';
import { websocketConfig } from './config';
import { ConnectionError } from './errors';

const READY_STATES = {
  CONNECTING: 0,
  OPEN: 1,
  CLOSING: 2,
  CLOSED: 3,
};

const prepareGetParams = (token, params = {}) => {
  const query = new URLSearchParams();

  query.set('Authorization', `Bearer ${token}`);

  Object.keys(params).forEach((key) => {
    query.set(key, params[key]);
  });

  return query.toString();
};

const getSourceUrl = (rootApi, params) => {
  const query = prepareGetParams(websocketConfig.token, params);

  return `${websocketConfig.apiUrl}/${websocketConfig.version}/${rootApi}?${query}`;
};

class Connection {
  constructor({ rootApi, onOpen, onError, onClose }) {
    this.rootApi = rootApi;
    this.eventBus = createBus();
    this.queue = new Map();
    this.websocket = null;

    this._handleConnecting = this._handleConnecting.bind(this);
    this._handleOpened = this._handleOpened.bind(this);
    this._handleClosing = this._handleClosing.bind(this);
    this._handleClosed = this._handleClosed.bind(this);

    this.stateHandlers = {
      [READY_STATES.CONNECTING]: this._handleConnecting,
      [READY_STATES.OPEN]: this._handleOpened,
      [READY_STATES.CLOSING]: this._handleClosing,
      [READY_STATES.CLOSED]: this._handleClosed,
    };

    this.onClose = onClose;
    this.onError = onError;
    this.onOpen = onOpen;
  }

  _handleConnecting(data) {
    const key = uuidv4();
    const result = null;
    logger.get('WebSocket').debug(`Send: connecting...`, { key, result, data });

    this.queue.set(key, data);

    return { key, result };
  }

  _handleOpened(data) {
    const key = uuidv4();
    const result = true;

    this.websocket.send(JSON.stringify(data));
    logger.get('WebSocket').debug(`Send: open`, { key, result, data });

    return { key, result };
  }

  _handleClosing(data) {
    logger.get('WebSocket').debug(`Send: when closing...`, data);

    throw new ConnectionError('You are trying to send data when connection is closing');
  }

  _handleClosed(data) {
    logger.get('WebSocket').debug(`Send: when closed`, data);

    throw new ConnectionError('You are trying to send data when connection is closed');
  }

  get isOpened() {
    return [READY_STATES.OPEN, READY_STATES.CLOSING, READY_STATES.CONNECTING].includes(
      this.websocket?.readyState
    );
  }

  open(params = {}) {
    const sourceUrl = getSourceUrl(this.rootApi, params);

    this.websocket = new WebSocket(sourceUrl);

    this.websocket.onopen = (event) => {
      logger.get('WebSocket').debug('Connected', event);

      if (this.queue.size > 0) {
        logger.get('WebSocket').debug('Queue: ', this.queue);
        this.queue.forEach((data, key) => {
          const response = this.send(data);
          logger.get('WebSocket').debug('Send from queue: ', { key, data, response });

          if (response.result !== null) {
            this.queue.delete(key);
          }
        });
      }

      if (this.onOpen) {
        this.onOpen();
      }
    };

    this.websocket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      const eventName = data.event;

      logger.get('WebSocket').debug(`${eventName} received with data`, data);
      this.eventBus.broadcast(eventName, data);
    };

    this.websocket.onclose = (event) => {
      logger.get('WebSocket').debug('Closed', event);

      if (event.wasClean) {
        this.queue.clear();
      }

      if (this.onClose) {
        this.onClose(event);
      }
    };

    this.websocket.onerror = (event) => {
      logger.get('WebSocket').debug('Error', event);

      if (this.onError) {
        this.onError(event);
      }
    };
    return this.websocket;
  }

  listen(eventName, callback) {
    return this.eventBus.subscribe(eventName, callback);
  }

  send(data) {
    const readyState = this.websocket.readyState;
    const stateHandler = this.stateHandlers[readyState];

    return stateHandler(data);
  }

  close() {
    this.websocket.close();
  }
}

export default Connection;
