import { SetSpaceKeywordFiltersRequest } from 'APP/packages/api/types/model/setSpaceKeywordFiltersRequest';
import { SetSpaceObsceneFilterRequest } from 'APP/packages/api/types/model/setSpaceObsceneFilterRequest';

import { IDeepLinkInfo, IUserMentionData } from '../common/commonModel.types';
import { IPersonalizedGroup } from '../group/groupModel.types';
import { IMessageWithCommentCount } from '../message/messageModel.types';

export enum SpaceType {
  Public = 'public',
  Private = 'private',
}

export enum SpaceUserRole {
  Owner = 'owner',
  Admin = 'admin',
  Member = 'member',
  Guest = 'guest',
}

export enum SpaceTemplate {
  General = 'general',
  Family = 'family',
  Friends = 'friends',
}

export interface ISpace {
  avatarUrl?: string;
  categoryId: string;
  deepLinkInfo: IDeepLinkInfo;
  description?: string;
  descriptionMentions: IUserMentionData[];
  id: string;
  marketsCount: number;
  name: string;
  primaryMarketUrl: string;
  participantsCount: number;
  template: SpaceTemplate;
  type: SpaceType;
  verified: boolean;
  obsceneFilterEnabled: boolean;
  keywordFilters: number;
}

export interface ISpaceGroupsVisibility {
  allGroupsVisible: boolean;
  visibleGroupIds: string[];
}

export interface IPersonalizedSpace {
  groupsVisibility: ISpaceGroupsVisibility;
  muted: boolean;
  role: SpaceUserRole;
  space: ISpace;
  subscribedGroupIds: string[];
  pinOrder: number;
}

export interface ISpacesPageRequest {
  startTs?: string;
  limit?: number;
}

export interface ISpacePage {
  hasNext: boolean;
  lastUpdateTs?: string;
  spaces: IPersonalizedSpace[];
}

export interface ICreateSpace {
  name: string;
  description?: string;
  type: SpaceType;
  avatarUrl?: string;
  deepLinkAlias?: string;
  categoryId: string;
}

export interface IUpdateSpace extends ICreateSpace {
  id: string;
}

export interface ISpaceParticipantsChange {
  spaceId: string;
  userIds: string[];
}

export interface IBanUsersInSpaceRequest extends ISpaceParticipantsChange {
  deleteAllMessages?: boolean;
}

export interface ISpaceData extends Partial<Omit<IPersonalizedSpace, 'space' | 'role'>> {
  space: ISpace;
  role: SpaceUserRole;
}

export interface ISpaceMarket {
  avatarUrl?: string;
  description?: string;
  id: string;
  name: string;
  spaceId: string;
  url: string;
}

export interface ICreateSpaceMarket {
  spaceId: string;
  name: string;
  url: string;
  description?: string;
  avatarUrl?: string;
}

export interface IUpdateSpaceMarket {
  id: string;
  spaceId: string;
  name?: string;
  url?: string;
  description?: string;
  avatarUrl?: string;
}

export interface IGetBannedSpaceUsers {
  cursor?: string;
  limit?: number;
  spaceId: string;
}

export interface IBannedUsersInSpace {
  cursor?: string;
  userIds: string[];
}

export interface ISpaceCollectionItem {
  collectionId: string;
  id: string;
  spaceId: string;
}

export interface ISpaceCollection {
  avatarUrl?: string;
  id: string;
  itemsCount: number;
  name: string;
  spaceId: string;
  updatedTs: number;
}

interface ISpaceCollectionItemRequest {
  groupId: string;
  messageId: string;
}

export interface ICreateSpaceCollection {
  spaceId: string;
  name: string;
  avatarUrl?: string;
  items: ISpaceCollectionItemRequest[];
}

export interface IUpdateSpaceCollection {
  spaceId: string;
  collectionId: string;
  name?: string;
  avatarUrl?: string;
}

export interface IAddSpaceCollectionItems {
  spaceId: string;
  collectionId: string;
  items: ISpaceCollectionItemRequest[];
}

export interface IGetCollectionIdsWithItems {
  spaceId: string;
  items: ISpaceCollectionItemRequest[];
}

export interface ISpaceCollectionRequest {
  cursor?: string;
  limit?: number;
  spaceId: string;
  collectionId: string;
}

export interface ISpaceMessageCollectionItem {
  collectionId: string;
  group: IPersonalizedGroup;
  id: string;
  message: IMessageWithCommentCount;
  spaceId: string;
}

export interface ISpaceCollectionItems {
  cursor?: string;
  items: ISpaceMessageCollectionItem[];
}

export interface IDeleteSpaceCollectionItems {
  spaceId: string;
  collectionId: string;
  collectionItemIds: string[];
}

export interface IValidateMarketUrl {
  url: string;
}

export interface IPinSpaceResponse {
  pinOrder: number;
  spaceId: string;
}

export interface IGetSpaceUserRolesRequest {
  spaceId: string;
  cursor?: string;
  limit?: number;
}

export interface ISpaceUserRoleResponse {
  readonly userRoles: { role: SpaceUserRole; userId: string }[];
  readonly cursor: string | null;
}

export interface IUpdateGroupVisibilityRequest {
  spaceId: string;
  groupId: string;
  visible: boolean;
}

export type ISetObsceneFilterRequest = SetSpaceObsceneFilterRequest;

export type ISetKeywordFiltersRequest = SetSpaceKeywordFiltersRequest;
