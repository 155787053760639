import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect, useLayoutEffect, MutableRefObject } from 'react';

import { CatalogType } from 'APP/model/catalog/catalogModel.types';
import { RouterLink } from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import Entities from 'STORE';
import { debounce } from 'UTILS/debounce';

import {
  TTypeChangeHandler,
  TCategoryChangeHandler,
  TSearchTextChangeHandler,
  TCatalogResetHandler,
} from './Catalog.types';

interface ICatalogLocalStore {
  isErrorView: boolean;
  isContentView: boolean;
  isMainView: boolean;
  isCategoryView: boolean;
  isCategoryListView: boolean;
  isSearchView: boolean;
  setScrollPosition(position: number): void;
  onTypeChange: TTypeChangeHandler;
  onCategoryChange: TCategoryChangeHandler;
  onSearchTextChange: TSearchTextChangeHandler;
  onReset: TCatalogResetHandler;
  onScroll: () => void;
  onClose(): void;
}

export const useCatalogPresenter = (scrollRef: MutableRefObject<HTMLDivElement | null>) => {
  const navigateTo = useNavigateTo();
  const source = useAsObservableSource({ navigateTo, scrollRef });

  const presenter = useLocalStore<ICatalogLocalStore>(() => ({
    isSearchView: Entities.catalogStore.searchText.trim().length > 0,

    get isErrorView(): boolean {
      return Entities.catalogStore.hasError;
    },

    get isContentView(): boolean {
      return !presenter.isErrorView;
    },

    get isMainView(): boolean {
      return !presenter.isSearchView && !presenter.isCategoryView;
    },

    get isCategoryView(): boolean {
      return !presenter.isSearchView && Boolean(Entities.catalogStore.categoryId);
    },

    get isCategoryListView(): boolean {
      return presenter.isMainView || presenter.isCategoryView;
    },

    setScrollPosition(position: number): void {
      window.requestAnimationFrame(() => {
        if (source.scrollRef.current) {
          source.scrollRef.current.scrollTo({ top: position });
        }
      });
    },

    onTypeChange(type: CatalogType): void {
      presenter.onReset();
      Entities.catalogStore.setType(type);
    },

    onCategoryChange(id: string | null): void {
      presenter.onReset();
      Entities.catalogStore.setCategoryId(id);
    },

    onSearchTextChange(text: string): void {
      const isSearch = text.trim().length > 0;

      if ((isSearch && !presenter.isSearchView) || (!isSearch && presenter.isSearchView)) {
        presenter.onReset();
      }

      presenter.isSearchView = isSearch;
      Entities.catalogStore.setSearchText(text);
    },

    onReset(): void {
      Entities.catalogStore.resetCatalog();
      presenter.setScrollPosition(0);
    },

    onScroll: debounce(() => {
      if (source.scrollRef?.current) {
        Entities.catalogStore.setScrollPosition(source.scrollRef.current.scrollTop);
      }
    }, 200),

    onClose(): void {
      source.navigateTo({ to: RouterLink.main });
    },
  }));

  useEffect(() => {
    const target = scrollRef?.current;
    target && target.addEventListener('scroll', presenter.onScroll, { passive: true });

    return () => {
      target && target.removeEventListener('scroll', presenter.onScroll);
    };
  }, []);

  useLayoutEffect(() => {
    presenter.setScrollPosition(Entities.catalogStore.scrollPosition);
  }, []);

  return presenter;
};
