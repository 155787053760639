import React from 'react';

import { IRenderer } from '../renderTree.types';

export const StrikethroughRenderer: IRenderer = ({ children }) => {
  if (!children) {
    return null;
  }

  return <s>{children}</s>;
};
