import { setConfigApi } from 'APP/packages/api';
import { setSseConfig } from 'APP/packages/streamApi';
import Entities from 'STORE';

interface ISetAuthTokensParams {
  apiToken: string;
  sseToken: string;
}

export const setAuthTokens = (params: ISetAuthTokensParams): void => {
  setConfigApi({ userSession: params.apiToken });
  setSseConfig({ token: params.sseToken });
  Entities.AuthStore.setStreamToken(params.sseToken);
};
