import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Tabs } from 'APP/packages/UiKit/Tabs/Tabs';
import { useTranslation } from 'APP/packages/translations';

import { EmojiPanel } from './EmojiPanel/EmojiPanel';
import { useEmojiStickersPanelPresenter } from './EmojiStickersPanel.presenter';
import { StickerPanel } from './StickerPanel/StickerPanel';
import styles from './styles.m.css';

const TABS = [EmojiPanel, StickerPanel];

interface IEmojiStickersPanelProps {
  closePopover(): void;
}
export const EmojiStickersPanel: FC<IEmojiStickersPanelProps> = observer(({ closePopover }) => {
  const { t } = useTranslation();
  const presenter = useEmojiStickersPanelPresenter();

  const TabContent = TABS[presenter.activeTab];

  return (
    <div className={styles.container}>
      <Tabs
        className={styles.tabs}
        tabs={[t('emoji'), t('stickers')]}
        selectedTabIndex={presenter.activeTab}
        onChange={presenter.setActiveTab}
      />
      <TabContent closePopover={closePopover} />
    </div>
  );
});
