import { observer } from 'mobx-react';
import React from 'react';

import { Sticker } from 'APP/components/Sticker/Sticker';
import {
  STICKER_FULL_SCREEN_MAX_WIDTH,
  STICKER_FULL_SCREEN_MAX_HEIGHT,
} from 'APP/constants/stickers';
import { AddKeyPressHandler } from 'APP/packages/keyBoardHandler';
import { OverlayingPopup } from 'UIKIT/OverlayingPopup/OverlayingPopup';

import usePresenter from './presenter';
import styles from './styles.m.scss';

const StickerPopup = () => {
  const { sticker, onClose, displayUILayer } = usePresenter();
  return (
    <>
      <AddKeyPressHandler onEsc={onClose} />
      <OverlayingPopup
        isOpened
        onClose={onClose}
        className={styles.overlay}
        popupClassName={styles.container}
      >
        <div
          key={sticker.id}
          className={styles.translateContainer}
          style={{
            transform: `translate(${sticker.translate.left}px, ${sticker.translate.top}px)`,
          }}
        >
          <Sticker
            key={sticker.id}
            className={styles.sticker}
            displayUILayer={displayUILayer}
            sticker={sticker}
            maxWidth={STICKER_FULL_SCREEN_MAX_WIDTH}
            maxHeight={STICKER_FULL_SCREEN_MAX_HEIGHT}
            autoplay
          />
        </div>
      </OverlayingPopup>
    </>
  );
};

export default observer(StickerPopup);
