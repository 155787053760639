import { FC } from 'react';

import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import { withPopupLayer } from 'MAIN/Popups/withPopupLayer';
import Entities from 'STORE';
import { Popup } from 'STORE/App/Popups/Popup/Popup';
import { IPopupOptions } from 'STORE/App/Popups/Popups';

export const openPopup = <TParams, TResult = any>(
  Component: FC<ICommonPopupsProps<TParams, TResult>>,
  options?: IPopupOptions<TParams, TResult>
): Popup<TParams> => {
  return Entities.App.popups.openPopup<TParams>(withPopupLayer(Component), options);
};
