import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useRef } from 'react';

import { Sticker } from 'APP/components/Sticker/Sticker';
import { STICKER_PANEL_MAX_HEIGHT, STICKER_PANEL_MAX_WIDTH } from 'APP/constants/stickers';
import { ISticker, IStickerPackExtend } from 'APP/model/stickers/stickersModel.types';
import { useTranslation } from 'APP/packages/translations';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';

import styles from './StickerList.styles.m.css';

interface IStickerListProps {
  activePack: IStickerPackExtend | null;
  onStickerClick(sticker: ISticker): void;
}

export const StickerList: FC<IStickerListProps> = observer(({ activePack, onStickerClick }) => {
  const { t } = useTranslation();
  const root = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!root.current) {
      return;
    }

    root.current.scrollTop = 0;
  }, [activePack?.stickerPack.id]);

  if (!activePack) {
    return <div className={styles.noResults}>{t('no_stickerpacks_added')}</div>;
  }

  return (
    <div
      className={classNames(styles.container, customScrollStyles.container)}
      ref={root}
    >
      {activePack.stickers.map((sticker) => {
        return (
          <Sticker
            root={root.current}
            displayUILayer={1}
            className={styles.sticker}
            key={sticker.id}
            sticker={sticker}
            maxWidth={STICKER_PANEL_MAX_WIDTH}
            maxHeight={STICKER_PANEL_MAX_HEIGHT}
            stickerPackId={activePack.stickerPack.id}
            onClick={onStickerClick}
          />
        );
      })}
    </div>
  );
});
