import classNames from 'classnames';
import React, { FC, MouseEventHandler } from 'react';

import styles from './Link.styles.m.css';

type TILinkColor = 'base' | 'primary' | 'secondary';

interface ILinkProps {
  className?: string;
  isDisabled?: boolean;
  color?: TILinkColor;
  underline?: boolean;
  onClick?: MouseEventHandler;
}

export const Link: FC<ILinkProps> = ({
  className,
  children,
  isDisabled = false,
  color = 'secondary',
  underline = true,
  onClick,
}) => {
  const classes = classNames(styles.container, className, {
    [styles.secondaryColor]: color === 'secondary',
    [styles.primaryColor]: color === 'primary',
    [styles.base]: color === 'base',
    [styles.underline]: underline,
  });

  return (
    <button
      className={classes}
      disabled={isDisabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
