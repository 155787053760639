import React from 'react';

import dateService from 'APP/packages/date';
import { useTranslation } from 'APP/packages/translations';

const Date = ({ className, date }) => {
  const { t } = useTranslation();

  return (
    <time
      className={className}
      dateTime={dateService.formatISO(date)}
    >
      {dateService.formatText(date, t)}
    </time>
  );
};

export default Date;
