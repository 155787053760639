import React, { useLayoutEffect, useRef } from 'react';

import { RLottieHandler } from 'APP/libs/rLottie/RLottieHandler';

type IUseAnimateStickerProps = {
  url?: string;
  autoplay?: boolean;
  animationData?: {
    id: string;
    data: Record<string, any>;
  };
  loop?: boolean;
  onEnterFrame?(handler: RLottieHandler): void;
  onLoopComplete?(handler: RLottieHandler): void;
};

interface IUseAnimateStickerReturn<TRef> {
  animationRef: React.MutableRefObject<RLottieHandler | undefined>;
  stickerContainerRef: React.RefObject<TRef>;
}

export const useAnimateSticker = <TRef = Element>(
  props: IUseAnimateStickerProps
): IUseAnimateStickerReturn<TRef> => {
  const { url, animationData, autoplay, loop = true, onEnterFrame, onLoopComplete } = props;

  const stickerContainerRef = useRef<TRef>(null);
  const animationRef = useRef<RLottieHandler>();

  useLayoutEffect(() => {
    animationRef.current = new RLottieHandler({
      url,
      loop,
      autoplay,
      onLoopComplete,
      onEnterFrame,
      container: stickerContainerRef.current as HTMLElement,
      animationData: { id: animationData?.id, data: animationData?.data },
    });

    return () => {
      animationRef.current?.destroy();
      animationRef.current = undefined;
    };
  }, [url, animationData]);

  return { animationRef, stickerContainerRef };
};
