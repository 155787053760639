import api from 'APP/packages/api';
import uploader from 'APP/packages/file-upload/uploader';
import logger from 'APP/packages/logger';
import Entities from 'APP/store';

export default async ({ preview, channelId, fileName }) => {
  try {
    if (!Entities.ActiveStream.stream?.previewImageUrl) {
      if (preview) {
        const { url } = await uploader.getUploadUrl();
        if (url) {
          preview.name = channelId;
          const response = await uploader.uploadFile(url, preview, fileName);
          const previewImageUrl = response[0].url;
          await api.streams.updateStreamInfo({
            channelId,
            previewImageUrl,
          });

          Entities.ActiveStream.stream.updateStream({
            previewImageUrl,
          });
        }
      }
    }
  } catch (e) {
    logger.get('API').error('streams.updateStreamInfo', e);
    return null;
  }
};
