import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { IUserMentionData } from 'APP/model/common/commonModel.types';
import { useTranslation } from 'APP/packages/translations';
import InfoIcon from 'ICONS/ic-info.svg';
import CopyIcon from 'ICONS/ic-s-copy.svg';
import { ProfileSeeMoreDescription } from 'MAIN/Profile/components/ProfileSeeMoreDescription/ProfileSeeMoreDescription';
import { IconButton } from 'UIKIT/IconButton';

import { MetaBlock } from '../MetaBlock/MetaBlock';
import { useDescriptionPresenter } from './ProfileDescription.presenter';

interface IProfileDescriptionProps {
  className?: string;
  description: string;
  mentions?: IUserMentionData[];
  groupId?: string;
}

export const ProfileDescription: FC<IProfileDescriptionProps> = observer(
  ({ className, description, mentions, groupId }) => {
    const { t } = useTranslation();
    const { copyToClipboard, handleCopyToClipboard } = useDescriptionPresenter(description);

    return (
      <MetaBlock
        className={className}
        label={t('about_group_chat_block_title')}
        Icon={InfoIcon}
        Actions={
          <IconButton
            Icon={CopyIcon}
            onClick={copyToClipboard}
          />
        }
      >
        <div
          role="button"
          tabIndex={0}
          data-test="profile-description"
          onClick={handleCopyToClipboard}
        >
          <ProfileSeeMoreDescription
            description={description}
            mentions={mentions}
            groupId={groupId}
          />
        </div>
      </MetaBlock>
    );
  }
);
