import { CallStreamType, FinishCallReason } from 'APP/model/call/callModel.constants';
import api from 'APP/packages/api';
import { BooleanResponse } from 'APP/packages/api/types/model/booleanResponse';
import {
  CallCreateRequestCallTypeEnum,
  CallCreateRequestStreamTypeEnum,
} from 'APP/packages/api/types/model/callCreateRequest';
import {
  CallCreateV2RequestCallTypeEnum,
  CallCreateV2RequestStreamTypeEnum,
} from 'APP/packages/api/types/model/callCreateV2Request';
import logger from 'APP/packages/logger';

import { buildModelError } from '../error/errorBuilder';
import {
  mapCreatedCallInfoResponse,
  mapCallInfoResponse,
  mapCallV2InfoResponse,
  mapCreatedCallV2InfoResponse,
} from './callModel.mapper';
import {
  CallType,
  ICallCreatedInfo,
  ICallCreateProps,
  ICallInfo,
  CallProviderType,
} from './callModel.types';

export const createCall = async (props: ICallCreateProps): Promise<ICallCreatedInfo> => {
  try {
    if (props.providerType === CallProviderType.Openvidu) {
      const createdCallInfo = await api.callsV2.createCallV2({
        groupId: props.groupId as string,
        streamType: CallStreamType.Audio as unknown as CallCreateV2RequestStreamTypeEnum,
        callType: props.callType as unknown as CallCreateV2RequestCallTypeEnum,
      });
      return mapCreatedCallV2InfoResponse(createdCallInfo);
    }
    const createdCallInfo = await api.calls.createCall({
      groupId: props.groupId || undefined,
      streamType: CallStreamType.Audio as unknown as CallCreateRequestStreamTypeEnum,
      callType: props.callType as unknown as CallCreateRequestCallTypeEnum,
    });
    return mapCreatedCallInfoResponse(createdCallInfo);
  } catch (error) {
    logger.get('API').error('calls.createCall', error);
    throw buildModelError(error);
  }
};

export const getCallInfo = async (
  props: { channelId: string; providerType: CallProviderType },
  callType: CallType
): Promise<ICallInfo> => {
  try {
    if (props.providerType === CallProviderType.Openvidu) {
      const callInfo = await api.callsV2.getCallV2Info(props);

      return mapCallV2InfoResponse(callInfo, callType);
    }
    const callInfo = await api.calls.getCallInfo(props);

    return mapCallInfoResponse(callInfo);
  } catch (error) {
    logger.get('API').error('calls.getCallInfo', error);
    throw buildModelError(error);
  }
};

export const finishCall = async (
  channelId: string,
  providerType: CallProviderType,
  reason: FinishCallReason
): Promise<BooleanResponse> => {
  if (providerType === CallProviderType.Openvidu) {
    return await api.callsV2.finishCallV2({
      channelId,
      reason,
    });
  }
  return await api.calls.finishCall({
    channelId,
    reason,
  });
};

export const hasJoinedToCall = async (
  channelId: string,
  providerType: CallProviderType
): Promise<BooleanResponse> => {
  if (providerType === CallProviderType.Openvidu) {
    return await api.callsV2.joinCallV2({
      channelId,
    });
  } else {
    return await api.calls.joinCall({
      channelId,
    });
  }
};
