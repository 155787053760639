import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { PayloadType } from 'APP/model/message/messageModel.types';
import Entities from 'APP/store';
import InputPanel from 'STORE/InputPanelsStore/InputPanel';
import type { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

export interface IEditMessagePresenter {
  inputPanel: InputPanel;
  message: ChatMessage | null;
  groupId: string | null;
  payloadType: PayloadType | null;
  cancel(): void;
}

export const useEditMessagePresenter = (): IEditMessagePresenter => {
  const presenter = useLocalStore<IEditMessagePresenter>(() => ({
    get inputPanel(): InputPanel {
      return Entities.InputPanelsStore.getById(Entities.GroupsStore.activeGroupId);
    },

    get message(): ChatMessage | null {
      return presenter.inputPanel.editMessage || null;
    },

    get groupId(): string | null {
      return Entities.GroupsStore.activeGroupId;
    },

    get payloadType(): PayloadType | null {
      return presenter.message?.payload.payloadType || null;
    },

    cancel(): void {
      if (presenter.groupId) {
        Tasks.messaging.endEditMessage(presenter.groupId);
      }
    },
  }));

  useEffect((): void => {
    if (!presenter.message && presenter.inputPanel.editMessageId) {
      presenter.cancel();
    }
  }, [presenter.message, presenter.inputPanel]);

  return presenter;
};
