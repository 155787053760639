import { IAgoraRTCRemoteUser } from 'agora-rtc-sdk-ng';
import { action, observable } from 'mobx';

import { ProviderUid } from 'APP/packages/callProviders/callProviders.types';

import { IAgoraScreenShareUser } from './AgoraProvider.types';

export class AgoraProviderOpponent {
  private agoraUser: IAgoraRTCRemoteUser | IAgoraScreenShareUser;
  uid: ProviderUid;
  @observable hasAudio: boolean;
  @observable hasVideo: boolean;
  @observable isPlayingVideo = false;
  @observable volumeLevel = 0;

  constructor(user: IAgoraRTCRemoteUser | IAgoraScreenShareUser) {
    this.agoraUser = user;
    this.hasAudio = user.hasAudio;
    this.hasVideo = user.hasVideo;
    this.uid = user.uid;
  }

  @action
  setHasAudio(value: boolean): void {
    this.hasAudio = value;
  }

  @action
  setHasVideo(value: boolean): void {
    this.hasVideo = value;
  }

  @action
  playVideo(element: HTMLDivElement): void {
    if (this.agoraUser.videoTrack) {
      this.isPlayingVideo = true;
      this.agoraUser.videoTrack.play(element);
    }
  }

  @action setVolumeLevel(level: number): void {
    this.volumeLevel = level;
  }

  @action
  stopVideo(): void {
    if (this.agoraUser.videoTrack) {
      this.isPlayingVideo = false;
      this.agoraUser.videoTrack?.stop();
    }
  }
}
