import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import { getAudioFileDuration } from 'UTILS/audio/getAudioFileDuration';

import useUploadFile from '../../../hooks/useUploadFile';

export default ({ parentPresenter, payload, order, id }) => {
  const { payloadType, userFile, fileName, fileSize, url: loadedUrl } = payload;
  const { uploadProgress, url, cancelUpload } = useUploadFile({
    userFile: userFile,
  });
  const source = useAsObservableSource({
    uploadProgress,
    url,
    cancelUpload,
    order,
  });

  const presenter = useLocalStore(() => ({
    setPayload: async (url) => {
      const duration = await getAudioFileDuration(url);
      parentPresenter.editPayloadByOrder({ ...presenter.payload, duration }, source.order);
    },

    cancelUploadAndDeleteMessage: () => {
      source.cancelUpload();

      parentPresenter.removeSomethingLoading(id);
      parentPresenter.deletePayloadByOrder(source.order);
    },

    waitingUrl: () => {
      if (!source.url && !loadedUrl) {
        parentPresenter.addSomethingLoading(id);
        return;
      }

      parentPresenter.removeSomethingLoading(id);
      presenter.setPayload(source.url);
    },

    get message() {
      return {
        payload: {
          ...payload,
          id,
          type: payloadType,
          fileName: fileName,
          fileSize: fileSize,
          uploadProgress: source.uploadProgress,
          url: loadedUrl || source.url,
          cancelUploadAndDeleteMessage: presenter.cancelUploadAndDeleteMessage,
        },
      };
    },

    get payload() {
      return {
        payloadType: payloadType,
        file: userFile,
        url: loadedUrl || source.url,
        comment: '',
      };
    },
  }));

  useEffect(() => {
    presenter.waitingUrl();
  }, [url]);

  return presenter;
};
