import Tasks from 'APP/Tasks';
import { ALERT_TYPES } from 'APP/constants/app';
import Entities from 'APP/store';

const SELECTED_MESSAGES_LIMIT = 9;

export default ({ groupId, messageId }) => {
  const group = Entities.GroupsStore.getGroupById(groupId);
  const messagesStore = group?.messagesStore;

  if (!messagesStore) {
    return;
  }

  const message = messagesStore.getMessageById(messageId);

  if (!message) {
    return;
  }

  if (
    !message.isSelected &&
    messagesStore.multiSelect.selectedMessagesIds.size > SELECTED_MESSAGES_LIMIT
  ) {
    Tasks.app.addAlert({
      type: ALERT_TYPES.MULTISELECT_LIMIT_EXCEEDED,
    });

    return;
  }

  if (message.isSelected) {
    messagesStore.multiSelect.unselectMessage(message.id);
  } else {
    messagesStore.multiSelect.selectMessage(message.id);
  }
};
