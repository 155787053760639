import { LocationState } from 'history';
import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect, useRef, MutableRefObject } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Tasks from 'APP/Tasks';
import { PayloadType } from 'APP/model/message/messageModel.types';
import { RouterState } from 'APP/router/constants';
import { IMediaGalleryState } from 'APP/types/mediaGallery';
import Entities from 'STORE';
import { Group } from 'STORE/Groups/Group';

import {
  IMediaGalleryFile,
  IMediaGalleryPreviewFiles,
  TSetSidebarVisabilityHandler,
  TCloseHandler,
} from './MediaGallery.types';

interface IMediaGalleryPresenter {
  _isShowSidebar: boolean;
  galleryState: IMediaGalleryState | null;
  isOpened: boolean;
  mustBeClosed: boolean;
  isLoading: boolean;
  isExistSidebar: boolean;
  isShowSidebar: boolean;
  focusMessageId: string | null;
  group: Group | null;
  file: IMediaGalleryFile | null;
  albumPreviewFiles: IMediaGalleryPreviewFiles | null;
  onSetSidebarVisibility: TSetSidebarVisabilityHandler;
  onClose: TCloseHandler;
}

const GALLERY_PREVIEW_TYPES = [PayloadType.Album, PayloadType.Article];

export const useMediaGalleryPresenter = (): {
  presenter: IMediaGalleryPresenter;
  containerRef: MutableRefObject<HTMLDivElement | null>;
} => {
  const containerRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const history = useHistory();
  const location = useLocation<Record<string, LocationState>>();
  const source = useAsObservableSource({ location, history });

  const presenter = useLocalStore<IMediaGalleryPresenter>(() => ({
    _isShowSidebar: true,

    get galleryState(): IMediaGalleryState | null {
      return (source.location.state?.[RouterState.mediaGallery] as IMediaGalleryState) || null;
    },

    get isOpened(): boolean {
      if (presenter.galleryState) {
        return (
          !!presenter.galleryState.groupId &&
          !!presenter.galleryState.mediaId &&
          !!presenter.galleryState.messageId
        );
      }
      return false;
    },

    get mustBeClosed(): boolean {
      return !presenter.file && !presenter.isLoading;
    },

    get isLoading(): boolean {
      if (presenter.file) {
        return false;
      }

      if (presenter.group?.sharedData.media) {
        return presenter.group.sharedData.media.isLoading;
      }

      return true;
    },

    get isExistSidebar(): boolean {
      return !!presenter.galleryState?.showMessageInfo;
    },

    get isShowSidebar(): boolean {
      return presenter.isExistSidebar && presenter._isShowSidebar;
    },

    get focusMessageId(): string | null {
      return Entities.GroupsStore.activeGroup?.messagesStore.focusedMessageId || null;
    },

    get group(): Group | null {
      if (!presenter.galleryState?.groupId) {
        return null;
      }

      return Entities.GroupsStore.getGroupById(presenter.galleryState.groupId);
    },

    get file(): IMediaGalleryFile | null {
      if (
        !presenter.group ||
        !presenter.galleryState?.messageId ||
        !presenter.galleryState?.mediaId
      ) {
        return null;
      }

      const messageIds = presenter.galleryState?.messageIds || [];

      // get real message id
      const messageId = presenter.group.messagesStore.getMessageById(
        presenter.galleryState?.messageId
      )?.id;
      const mediaId = presenter.galleryState?.mediaId;

      const items = presenter.group.sharedData.media.items.filter((item) => {
        return messageIds.length === 0 || messageIds.includes(item.messageId);
      });

      const currentIndex = items.findIndex(
        (item) => item.id === mediaId && item.messageId === messageId
      );

      const message = presenter.group.sharedData.getMessageById(items[currentIndex]?.messageId);

      if (currentIndex < 0 || !message) {
        return null;
      }

      return {
        ...items[currentIndex],
        nextFile: items[currentIndex + 1] || null,
        prevFile: items[currentIndex - 1] || null,
        message,
      };
    },

    get albumPreviewFiles(): IMediaGalleryPreviewFiles | null {
      if (!presenter.file || !presenter.group) {
        return null;
      }

      const message = presenter.group?.sharedData?.getMessageById(presenter.file.messageId);

      if (!message) {
        return null;
      }

      if (GALLERY_PREVIEW_TYPES.includes(message.payload.payloadType)) {
        let items = presenter.group.sharedData.media.items
          .filter((item) => item.messageId === presenter.file?.messageId)
          .map((item) => ({ ...item, message }));

        if (presenter.galleryState?.reverse) {
          items = items.reverse();
        }

        const currentIndex = items.findIndex((item) => item.id === presenter.file?.id);
        const leftFiles = items.slice(0, currentIndex);
        const rightFiles = items.slice(currentIndex + 1);

        return { leftFiles, rightFiles };
      }

      return null;
    },

    onSetSidebarVisibility(isVisible: boolean): void {
      presenter._isShowSidebar = isVisible;
    },

    onClose(): void {
      Tasks.group.closeMediaGallery();
      presenter._isShowSidebar = true;
    },
  }));

  useEffect(() => {
    if (presenter.isOpened && containerRef.current) {
      containerRef.current.focus();
    }
  }, [presenter.isOpened]);

  useEffect(() => {
    if (presenter.focusMessageId && presenter.isOpened) {
      presenter.onClose();
    }
  }, [presenter.focusMessageId]);

  return { presenter, containerRef };
};
