import { useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { PayloadType } from 'APP/model/message/messageModel.types';
import Entities from 'STORE';

interface IVoiceButtonPresenter {
  isStarted: boolean;
  onStart(): void;
}

export const useVoiceButtonPresenter = (): IVoiceButtonPresenter => {
  const presenter = useLocalStore<IVoiceButtonPresenter>(() => ({
    get isStarted(): boolean {
      return Entities.InputPanel.voice.isStarted;
    },

    onStart(): void {
      const canStart = Tasks.group.checkSendMessagePermissions({
        groupId: Entities.GroupsStore.activeGroupId!,
        payloadType: PayloadType.VoiceMessage,
      });

      if (!canStart.result) {
        canStart.error && Entities.toast.show(canStart.error);
        return;
      }

      Entities.InputPanel.voice.start();
    },
  }));

  return presenter;
};
