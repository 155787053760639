import { useLocalStore, useAsObservableSource } from 'mobx-react';
import { useRef } from 'react';

import { showToast } from 'APP/Tasks/app/showToast/showToast';
import { checkSlowMode } from 'APP/Tasks/messaging/checkSlowMode/checkSlowMode';
import { useTranslation } from 'APP/packages/translations';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';

import saveScheduledMessage from '../../Tasks/saveScheduledMessage';
import updateScheduledMessage from '../../Tasks/updateScheduledMessage';

export default ({ parent }) => {
  const navigateTo = useNavigateTo();
  const { t } = useTranslation();
  const publishButtonRef = useRef();
  const source = useAsObservableSource({ parent, navigateTo });

  const presenter = useLocalStore(() => ({
    isMenuOpen: false,

    get isEditMode() {
      return source.parent.core.isEditMode;
    },

    get isShowMenu() {
      return !presenter.isEditMode && presenter.isMenuOpen;
    },

    get isDisabled() {
      return source.parent.core.isDisabled;
    },

    onCloseMenu() {
      presenter.isMenuOpen = false;
    },

    get title() {
      return presenter.isEditMode ? t('common_save') : t('channels:channel_article_publish');
    },

    onToggleMenu() {
      presenter.isMenuOpen = !presenter.isMenuOpen;
    },

    onSend() {
      const canSend = checkSlowMode();
      if (!canSend.result) {
        canSend.error && showToast(canSend.error);
        return;
      }

      source.parent.core.handleCreateArticle();
      source.parent.goBack();
    },

    onUpdate() {
      if (source.parent.core.message.isScheduled) {
        updateScheduledMessage({
          data: source.parent.core.handleUpdateArticleData,
        });
        source.parent.goBack();
      } else {
        source.parent.core.handleUpdateArticle();
        source.parent.goBack();
      }
    },

    onScheduled() {
      saveScheduledMessage({
        data: source.parent.core.handleCreateArticleData,
        navigateTo: source.navigateTo,
      });
    },

    onClick() {
      if (presenter.isEditMode) {
        presenter.onUpdate();
      } else {
        presenter.onToggleMenu();
      }
    },
  }));

  return { presenter, publishButtonRef };
};
