import { useAsObservableSource, useLocalStore } from 'mobx-react';

import { ISpaceCollection } from 'APP/model/space/spaceModel.types';
import { spaceAnalytics } from 'APP/packages/analytics/analytics';
import { RouterLink } from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import { useSpaceCollections } from 'MAIN/hooks/space/useSpaceCollections';
import { Space } from 'STORE/Spaces/Space';

interface ISpaceCollectionListPresenter {
  collections: ISpaceCollection[];
  isEmpty: boolean;
  isShowOnboarding: boolean;
  onCollectionSelect(collection: ISpaceCollection): void;
}

export function useSpaceCollectionListPresenter(space: Space): ISpaceCollectionListPresenter {
  const navigateTo = useNavigateTo();
  const source = useAsObservableSource({ space, navigateTo });
  const spaceCollections = useSpaceCollections(space.id);

  const presenter = useLocalStore<ISpaceCollectionListPresenter>(() => ({
    get collections(): ISpaceCollection[] {
      return spaceCollections.collections;
    },

    get isEmpty(): boolean {
      return !presenter.collections.length;
    },

    get isShowOnboarding(): boolean {
      return presenter.isEmpty && !spaceCollections.isLoading;
    },

    onCollectionSelect(collection: ISpaceCollection): void {
      navigateTo({
        to: RouterLink.spaceCollection,
        params: {
          id: source.space.id,
          collectionId: collection.id,
        },
      });

      spaceAnalytics.trackSpaceCollectionViewed(source.space.id);
    },
  }));

  return presenter;
}
