import getLengthWithSpecialSymbols from '../utils/getLengthWithSpecialSymbols';

export const SPACE_COLLECTION_NAME_ERRORS = {
  IS_EMPTY: 'IS_EMPTY',
  TOO_SHORT: 'TOO_SHORT',
};

const COLLECTION_NAME_MIN_LENGTH = 2;

export function spaceCollectionNameValidate({ value, errorToText }) {
  if (!value || value.length === 0) {
    return errorToText[SPACE_COLLECTION_NAME_ERRORS.IS_EMPTY];
  }

  if (getLengthWithSpecialSymbols(value) < COLLECTION_NAME_MIN_LENGTH) {
    return errorToText[SPACE_COLLECTION_NAME_ERRORS.TOO_SHORT];
  }

  return null;
}
