import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, ReactElement } from 'react';
import { InView } from 'react-intersection-observer';

import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';

import styles from './List.styles.m.css';
import ListItem from './ListItem';

interface IListProps<T = any> {
  readonly className?: string;
  readonly data: T[];
  readonly renderItem: (props: T) => ReactElement<T>;
  readonly keyExtractor: (item: T, index: number) => React.Key;
  readonly ListEmptyComponent?: ReactElement;
  readonly SpinnerComponent?: ReactElement;
  readonly NotInitedComponent?: ReactElement;
  readonly isLoading?: boolean;
  readonly willChange?: boolean;
  readonly isScrollable?: boolean;
  readonly isInitedContent?: boolean;
  readonly hasMore?: boolean;
  readonly onEndReached?: () => void;
}

export const List: FC<IListProps> = observer((props) => {
  const {
    className,
    data,
    renderItem,
    keyExtractor,
    ListEmptyComponent,
    SpinnerComponent,
    NotInitedComponent,
    isLoading = false,
    willChange = false,
    isScrollable = false,
    isInitedContent = true,
    hasMore = false,
    onEndReached,
  } = props;

  if (!isInitedContent && NotInitedComponent) {
    return NotInitedComponent;
  }

  if (!isLoading && data.length === 0 && !hasMore) {
    return ListEmptyComponent || null;
  }

  const classes = classNames(styles.container, className, {
    [styles.willChange]: willChange,
    [styles.scrollable]: isScrollable,
    [customScrollStyles.container]: isScrollable,
  });

  return (
    <div className={classes}>
      {data.map((item, index) => (
        <ListItem
          key={keyExtractor(item, index)}
          renderItem={renderItem}
          item={item}
        />
      ))}
      {onEndReached && hasMore && !isLoading && (
        <InView>
          {({ inView, ref }) => {
            if (inView) {
              onEndReached();
            }

            return (
              <div
                className={styles.showMore}
                ref={ref}
              />
            );
          }}
        </InView>
      )}
      {(isLoading && SpinnerComponent) || null}
    </div>
  );
});
