import { observer } from 'mobx-react';
import React from 'react';

import { Popup } from 'APP/components/Popup/Popup';

import ChannelInfo from './ChannelInfo';
import usePresenter from './presenter';

const ChannelInfoPopup = () => {
  const presenter = usePresenter();

  return (
    <Popup
      isOpened={presenter.isOpened}
      onClose={presenter.close}
    >
      <ChannelInfo presenter={presenter} />
    </Popup>
  );
};

export default observer(ChannelInfoPopup);
