import Tasks from 'APP/Tasks';
import { IClientConfigProperty } from 'APP/model/auth/authModel.types';
import { IStickerPackExtend } from 'APP/model/stickers/stickersModel.types';
import { storage } from 'APP/packages/storage';
import Entities from 'APP/store';

export function handleStickers(clientConfig: IClientConfigProperty): void {
  const data = storage.publicStickerPacks.get();

  const configDate = parseInt(clientConfig.value);

  if (data === null || !data.lastUpdateTime || configDate !== data.lastUpdateTime) {
    Tasks.stickers.loadStickersPacks(configDate);
    return;
  }

  const packs = Object.values(data.packs).map((pack: IStickerPackExtend) => {
    pack.stickerPack.id = pack.stickerPack.id.toString();
    return pack;
  });

  Entities.stickersStore.mergePacks(packs);
}
