import React, { Component, ReactNode } from 'react';
import { render } from 'react-dom';

interface IMapControlProps {
  map: any;
  controlPosition: number | null;
}

export class MapControl extends Component<IMapControlProps> {
  private renderedOnce = false;
  private el: HTMLDivElement | null = null;

  componentDidMount(): void {
    this.pushComponentControl();
  }

  componentDidUpdate(): void {
    this.pushComponentControl();
  }

  shouldComponentUpdate(nextProps: IMapControlProps): boolean {
    return !this.props.map && nextProps.map;
  }

  componentWillUnmount(): void {
    const { map, controlPosition } = this.props;

    if (!map || controlPosition === null) {
      return;
    }

    const index = map.controls[controlPosition].getArray().indexOf(this.el);

    map.controls[controlPosition].removeAt(index);
  }

  pushComponentControl(): void {
    const { map, controlPosition, children } = this.props;

    if (!map || controlPosition === null) {
      return;
    }

    render(
      <div
        ref={(el): void => {
          if (!this.renderedOnce) {
            this.el = el;
            map.controls[controlPosition].push(el);
          }

          this.renderedOnce = true;
        }}
      >
        {children}
      </div>,
      document.createElement('div')
    );
  }

  render(): ReactNode {
    return null;
  }
}
