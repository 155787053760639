import React from 'react';

import { useUserProfile } from 'MAIN/hooks/userProfile/useUserProfile';

import styles from './UserLink.styles.m.css';

export const UserLink = ({ userId, groupId, messageId, children }) => {
  const userProfile = useUserProfile(userId, { groupId, messageId });

  if (!children) {
    return null;
  }
  return (
    <button
      className={styles.userLink}
      title={children}
      onClick={userProfile.show}
    >
      {children}
    </button>
  );
};
