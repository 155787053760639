import { observer } from 'mobx-react';
import React from 'react';

import { EntireScreenButton } from 'APP/components/EntireScreenButton/EntireScreenButton';
import { ShareScreenButton } from 'APP/components/ShareScreenButton/ShareScreenButton';
import { IconButton } from 'APP/packages/UiKit/IconButton';
import { useTranslation } from 'APP/packages/translations';
import MicOff from 'ICONS/ic-mic-off.svg';
import Mic from 'ICONS/ic-mic-on.svg';
import MinimizeIcon from 'ICONS/ic-minimize.svg';
import VideoOff from 'ICONS/ic-video-off.svg';
import VideoOn from 'ICONS/ic-video-on.svg';
import ExternalLink from 'UIKIT/ExternalLink';

import ThreadButton from '../../SharedComponents/ThreadButton';
import styles from './styles.m.css';

const ControlPanel = ({ presenter, streamerPresenter, isCommentsHidden }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      {presenter.isMeStreamer && (
        <>
          <IconButton
            Icon={streamerPresenter.audioMuted ? MicOff : Mic}
            onClick={
              streamerPresenter.audioMuted
                ? streamerPresenter.unmuteAudio
                : streamerPresenter.muteAudio
            }
            className={styles.button}
            size="large"
            iconSize="medium"
            theme={streamerPresenter.audioMuted ? 12 : 13}
          />
          <IconButton
            Icon={streamerPresenter.isVideoMuted ? VideoOff : VideoOn}
            onClick={
              streamerPresenter.videoMuted
                ? streamerPresenter.unmuteVideo
                : streamerPresenter.muteVideo
            }
            className={styles.button}
            size="large"
            iconSize="medium"
            theme={streamerPresenter.videoMuted ? 12 : 13}
          />
          <ShareScreenButton
            className={styles.button}
            isActive={streamerPresenter.isSharedScreen}
            shareScreen={streamerPresenter.startSharingScreen}
            stopSharingScreen={streamerPresenter.stopSharingScreen}
          />
        </>
      )}
      <div className={styles.right}>
        {presenter.donationUrl && !presenter.isMeStreamer && (
          <ExternalLink
            className={styles.donateButton}
            href={presenter.donationUrl}
            theme="11"
          >
            {t('channel_donate')}
          </ExternalLink>
        )}
        {!isCommentsHidden && (
          <ThreadButton
            className={styles.button}
            onClick={presenter.collapse}
          />
        )}
        {presenter.isStreamStarted ? (
          <IconButton
            Icon={MinimizeIcon}
            onClick={presenter.collapse}
            className={styles.button}
            size="large"
            iconSize="medium"
            theme="12"
          />
        ) : null}
        <EntireScreenButton className={styles.button} />
      </div>
    </div>
  );
};

export default observer(ControlPanel);
