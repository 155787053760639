import { observer } from 'mobx-react';
import React, { FC } from 'react';

import IconMention from 'ICONS/mention.svg';
import PinIcon from 'ICONS/pin2.svg';
import { Space } from 'STORE/Spaces/Space';
import { Badge } from 'UIKIT/Badge/Badge';

import { JoinCallButton } from '../../components/JoinCallButton/JoinCallButton';
import { JoinStreamingButton } from '../../components/JoinStreamingButton/JoinStreamingButton';
import { useSpaceBadgePresenter } from './SpaceBadge.presenter';
import styles from './SpaceBadge.styles.m.css';

interface ISpaceBadgeProps {
  space: Space;
}

export const SpaceBadge: FC<ISpaceBadgeProps> = observer(({ space }) => {
  const presenter = useSpaceBadgePresenter(space);

  const mention = presenter.hasUnreadMention ? <IconMention className={styles.mention} /> : null;

  let messageBadge = null;
  if (presenter.unreadMessagesCount) {
    messageBadge = <Badge quantity={presenter.unreadMessagesCount} />;
  } else if (presenter.mutedMessagesCount) {
    messageBadge = (
      <Badge
        quantity={presenter.mutedMessagesCount}
        isMuted={true}
      />
    );
  }

  let pinnedBadge = null;
  if (!messageBadge && presenter.isPinned) {
    pinnedBadge = <PinIcon className={styles.pin} />;
  }

  let callBadge = null;
  if (presenter.hasBackgroundCall) {
    callBadge = (
      <JoinCallButton
        hasActiveCall={!!presenter.activeCall}
        startTs={presenter.activeCall?.startTs || undefined}
        onClick={presenter.onJoinCall}
      />
    );
  }

  let streamBadge = null;
  if (!callBadge && presenter.hasBackgroundStream) {
    streamBadge = <JoinStreamingButton isActive={presenter.hasActiveStream} />;
  }

  return (
    <div className={styles.badges}>
      {mention}
      {messageBadge}
      {pinnedBadge}
      {callBadge}
      {streamBadge}
    </div>
  );
});
