import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useCallback } from 'react';

import { List } from 'APP/components/List/List';
import NoBlockedUserImage from 'APP/images/gem-mascot-block.svg';
import { GroupsPlaceholder } from 'APP/packages/placeholders/GroupsPlaceholder/GroupsPlaceholder';
import { useTranslation } from 'APP/packages/translations';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';
import { InfoBlock, InfoBlockTypes } from 'UIKIT/InfoBlock/InfoBlock';

import { useBlockListViewPresenter } from './BlockListView.presenter';
import styles from './BlockListView.styles.m.css';
import { BlockedUser } from './BlockedUser/BlockedUser';

export const BlockListView = observer(() => {
  const { t } = useTranslation();
  const presenter = useBlockListViewPresenter();

  const renderItem = useCallback((blockedUser) => {
    return (
      <BlockedUser
        blockedUser={blockedUser}
        blockUser={presenter.blockUser}
        unblockUser={presenter.unblockUser}
      />
    );
  }, []);

  return (
    <div className={classNames(styles.container, customScrollStyles.container)}>
      <div className={styles.innerContainer}>
        <List
          isLoading={presenter.isLoading}
          hasMore={presenter.hasMore}
          data={presenter.blockedUsers}
          keyExtractor={presenter.keyExtractor}
          onEndReached={presenter.loadBlockedUsers}
          renderItem={renderItem}
          ListEmptyComponent={
            <InfoBlock
              className={styles.infoBlock}
              descriptions={[t('block_list_empty')]}
              type={InfoBlockTypes.Modal}
              image={NoBlockedUserImage}
            />
          }
          SpinnerComponent={
            <GroupsPlaceholder
              count={presenter.placeholdersCount}
              className={styles.placeholders}
            />
          }
        />
      </div>
    </div>
  );
});
