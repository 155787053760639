import Tasks from 'APP/Tasks';
import { IGroupCallChanged, IIncomingCall } from 'APP/model/call/callModel.types';
import Entities from 'APP/store';

export async function handleBatchedActiveCalls(calls: IGroupCallChanged[]): Promise<void> {
  Entities.BackgroundCalls.replace(calls);
  Tasks.calls.checkAndRunAwaitCall();
}

export async function handleChangeActiveCall(call: IGroupCallChanged): Promise<void> {
  Tasks.calls.updateActiveCall(call);
}

export async function handleStartedCall(call: IIncomingCall): Promise<void> {
  await Promise.all([
    Tasks.relationships.loadUnknownUsersByIds({
      usersIds: [call.callerId],
    }),
    Tasks.relationships.loadUnknownGroupsByIds({
      groupsIds: [call.groupId],
    }),
  ]);

  const isMeInitiator = Entities.UsersStore.Me.id === call.callerId;

  if (isMeInitiator) {
    return;
  }

  if (Entities.Calls.hasCalls || Entities.ActiveStream.hasStream) {
    Tasks.calls.decline({
      groupId: call.groupId,
      channelId: call.channelId,
      providerType: call.callProvider,
    });
  } else {
    Entities.IncomingCalls.add(call);
  }
}
