import { PayloadType } from 'APP/model/message/messageModel.types';
import { t } from 'MODULE/i18n/service';
import { Payload } from 'STORE/Messages/Message/Payload';

class Sticker extends Payload {
  id;
  name;
  category;
  stickerUrl;
  type;
  animationUrl;

  constructor(data) {
    super(data);

    this.id = data.id;
    this.name = data.name;
    this.stickerPackId = Number(data.stickerPackId);
    this.stickerUrl = data.stickerUrl;
    this.graphicSize = data.graphicSize;
    this.type = data.type;
    this.animationUrl = data.animationUrl;
  }

  toJSON() {
    return {
      payloadType: PayloadType.Sticker,
      id: this.id,
      stickerPackId: this.stickerPackId || null,
      name: this.name,
      stickerUrl: this.stickerUrl,
      type: this.type,
      animationUrl: this.animationUrl || null,
      graphicSize: this.graphicSize,
    };
  }

  get text() {
    return t('message_desctription_sticker');
  }

  get typeDescription() {
    return t('message_desctription_sticker');
  }
}

export default Sticker;
