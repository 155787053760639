import { BotUserDisplayData } from 'APP/packages/api/types/model/botUserDisplayData';
import { DisplayDataResponse } from 'APP/packages/api/types/model/displayDataResponse';
import { UserDisplayData } from 'APP/packages/api/types/model/userDisplayData';

import {
  IBotUserDisplayData,
  IUserDisplayData,
  UserDisplayDataType,
  UserProfileUsernameBadge,
} from './userModel.types';

export const mapUserDisplayDataResponse = (
  user: BotUserDisplayData | DisplayDataResponse | UserDisplayData
): IUserDisplayData => {
  return {
    ...user,
    lastOnline: (user as UserDisplayData).lastOnline || '',
    type: user.type as string as UserDisplayDataType,
    deepLink: user.deepLinkInfo?.deepLink || '',
    usernameBadge: user.usernameBadge
      ? (user.usernameBadge as string as UserProfileUsernameBadge)
      : undefined,
  };
};

export const mapBotUserDisplayDataResponse = (user: BotUserDisplayData): IBotUserDisplayData => {
  return {
    ...user,
    type: user.type as string as UserDisplayDataType,
    deepLink: user.deepLinkInfo?.deepLink || '',
    usernameBadge: user.usernameBadge
      ? (user.usernameBadge as string as UserProfileUsernameBadge)
      : undefined,
  };
};
