import { action, computed, observable, values } from 'mobx';

import Tasks from 'APP/Tasks';
import { OnboardingType } from 'APP/constants/onboarding';
import dateService from 'APP/packages/date';

import { IOnboardingItem } from './Onboarding.types';

export class Onboarding {
  @observable showedOnboardings: IOnboardingItem[] = [];
  @observable needToShow: OnboardingType[] = [];

  @action
  reset(): void {
    this.needToShow = [];
    this.showedOnboardings = [];
    Tasks.app.onboarding.resetOnboarding();
  }

  @action
  setShowedOnboardings(onboardings: IOnboardingItem[]): void {
    this.showedOnboardings = onboardings;
  }

  @action
  addShowedOnboarding(type: OnboardingType): void {
    this.showedOnboardings.push({
      name: type,
      timestamp: dateService.now().getTime(),
    });
  }

  @action
  addToShow(type: OnboardingType): void {
    if (!this.needToShow.includes(type) && this.getIsNeedToShow(type)) {
      this.needToShow.push(type);
    }
  }

  @action
  removeFromShow(type: OnboardingType): void {
    this.needToShow = this.needToShow.filter((item) => item !== type);
  }

  getIsNeedToShow(type: OnboardingType): boolean {
    return !this.showedOnboardings.find((item) => item.name === type);
  }

  isActiveOnboarding(type: OnboardingType): boolean {
    return this.activeOnboarding === type;
  }

  @computed
  get activeOnboarding(): OnboardingType {
    return (values(this.needToShow) as ReadonlyArray<OnboardingType>)[0] || null;
  }
}
