import { subscribe, broadcast } from '../../eventBus';

function getBusStation() {
  return 'select-emoji';
}

export const subscribeSelectEmoji = (callback) => {
  return subscribe(getBusStation(), callback);
};

export const broadcastSelectEmoji = (emoji) => {
  broadcast(getBusStation(), emoji);
};
