import classNames from 'classnames';
import React, { FC, memo } from 'react';

import styles from './styles.m.css';

interface IActiveSpeakerIconProps {
  isBig?: boolean;
  className?: string;
  isSilence?: boolean;
}

export const ActiveSpeakerIcon: FC<IActiveSpeakerIconProps> = memo((props) => {
  const { isBig, className, isSilence } = props;

  return (
    <div
      className={classNames(styles.activeSpeaker, className, {
        [styles.activeSpeakerBig]: isBig,
        [styles.silence]: isSilence,
      })}
    >
      <div className={styles.activeSpeakerIcon} />
    </div>
  );
});
