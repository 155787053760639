import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { useTranslation } from 'APP/packages/translations';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';
import { SwitchMenuItem } from 'UIKIT/SwitchMenuItem/SwitchMenuItem';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { useClosePopupOnNavigation } from '../../hooks/useClosePopupOnNavigation';
import {
  IChangeSpaceVisibilityPopupPresenterParams,
  useChangeSpaceVisibilityPopupPresenter,
} from './ChangeSpaceVisibilityPopup.presenter';
import styles from './ChangeSpaceVisibilityPopup.styles.m.css';
import { GroupVisibilityItem } from './GroupVisibilityItem/GroupVisibilityItem';

type IChangeSpaceVisibilityPopupProps = IChangeSpaceVisibilityPopupPresenterParams;

export const ChangeSpaceVisibilityPopup: FC<ICommonPopupsProps<IChangeSpaceVisibilityPopupProps>> =
  observer((props) => {
    const { t } = useTranslation();
    const presenter = useChangeSpaceVisibilityPopupPresenter(props);
    useClosePopupOnNavigation(props.popupInstance);

    const containerClassName = classNames(styles.container, customScrollStyles.container);

    return (
      <Popup
        title={t('space_visibility_settings')}
        isOpened={presenter.isOpened}
        handlePrevStep={presenter.hasBackButton ? presenter.onBack : null}
        onClose={presenter.onClose}
      >
        <div className={containerClassName}>
          <SwitchMenuItem
            className={styles.switch}
            title={t('visibility_outspace_show_chats')}
            isChecked={presenter.isAllVisible}
            onChange={presenter.onAllToggle}
          />

          <hr className={styles.separator} />
          <div className={styles.groupList}>
            {presenter.groups.map((group) => (
              <GroupVisibilityItem
                key={group.id}
                group={group}
              />
            ))}
          </div>
        </div>
      </Popup>
    );
  });
