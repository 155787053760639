/* eslint-disable react/no-array-index-key */
import classNames from 'classnames';
import React, { memo } from 'react';

import AnimatedPartStyles from '../AnimatedPart/AnimatedPart.styles.m.css';
import { AvatarPlaceholder, AvatarPlaceholderType } from '../AvatarPlaceholder/AvatarPlaceholder';
import useSyncAnimation from '../hooks/useSyncAnimations';
import styles from './styles.m.scss';

const Mentions = ({ count = 5 }) => {
  const list = Array(count).fill('item');

  const { isFirstAnimated, isSecondAnimated } = useSyncAnimation({
    firstTimeout: 500,
    secondTimeout: 1000,
  });

  return list.map((item, index) => {
    return (
      <div
        key={index}
        className={styles.container}
      >
        <AvatarPlaceholder
          type={AvatarPlaceholderType.Square_20}
          isAnimated={isFirstAnimated}
        />
        <div className={styles.content}>
          <div
            className={classNames(styles.firstLine, {
              [AnimatedPartStyles.animation]: isSecondAnimated,
            })}
          />
          <div
            className={classNames(styles.secondLine, {
              [AnimatedPartStyles.animation]: isSecondAnimated,
            })}
          />
        </div>
      </div>
    );
  });
};

export default memo(Mentions);
