import { useLocalStore, useAsObservableSource } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { useTranslation } from 'APP/packages/translations';
import { RouterState } from 'APP/router/constants';
import Entities from 'APP/store';
import { Group } from 'STORE/Groups/Group';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';
import { getFormatNumberToString } from 'UTILS/getFormatNumberToString';

interface IThreadButtonPresenter {
  thread: Group | null;
  caption: string;
  hasUnread: boolean;
  hasUnreadMentions: boolean;
  countUnread: number | null;
  isVisible: boolean;
  onClick(): Promise<void>;
}

export const useThreadButtonPresenter = (message: ChatMessage): IThreadButtonPresenter => {
  const { t } = useTranslation();
  const source = useAsObservableSource({ message });

  const presenter = useLocalStore<IThreadButtonPresenter>(() => ({
    get thread(): Group | null {
      if (source.message.threadId) {
        return Entities.GroupsStore.getGroupById(source.message.threadId);
      }
      return null;
    },

    get caption(): string {
      const totalMessagesCount = presenter.thread?.counter?.totalMessagesCount || 0;
      const formatedMessagesCount = getFormatNumberToString(totalMessagesCount);

      if (totalMessagesCount <= 0) {
        return source.message.group?.isChannel ? t('comments_leave_comment') : '';
      }

      if (totalMessagesCount === 1) {
        return t('comments_comment_counter', { 0: formatedMessagesCount });
      }

      return t('comments_comments_counter', { 0: formatedMessagesCount });
    },

    get hasUnread(): boolean {
      return Boolean(presenter.thread?.counter?.counter);
    },

    get hasUnreadMentions(): boolean {
      return Boolean(presenter.thread?.counter?.unreadUserMentionsCounter);
    },

    get countUnread(): number | null {
      return presenter.thread?.counter?.counter || null;
    },

    get isVisible(): boolean {
      if (source.message.group?.isChannel) {
        return source.message.canBeLeaveComment;
      }

      return Boolean(
        source.message.threadId &&
          source.message.canBeLeaveComment &&
          presenter.thread?.counter?.totalMessagesCount &&
          presenter.thread?.counter?.totalMessagesCount > 0
      );
    },

    async onClick(): Promise<void> {
      const group = await Tasks.thread.getOrCreateThread(source.message);
      if (group) {
        await Tasks.group.setActiveGroup({
          groupId: group.id,
          state: { [RouterState.hasGoBack]: true },
        });
      }
    },
  }));

  return presenter;
};
