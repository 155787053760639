import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { showToast } from 'APP/Tasks/app/showToast/showToast';
import { ISpaceCollection } from 'APP/model/space/spaceModel.types';
import { useTranslation } from 'APP/packages/translations';
import Entities from 'STORE';
import { Space } from 'STORE/Spaces/Space';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { ICollectionFormData } from '../../components/SpaceCollectionForm/SpaceCollectionForm.types';

export interface IEditSpaceCollectionPopupPresenterParams {
  spaceId: string;
  collectionId: string;
}

interface IEditSpaceCollectionPopupPresenter {
  space: Space | null;
  collection: ISpaceCollection | null;
  initialValues?: ICollectionFormData;
  onSubmit(data: ICollectionFormData): Promise<void>;
}

export function useEditSpaceCollectionPopupPresenter({
  params,
  popupInstance,
}: ICommonPopupsProps<IEditSpaceCollectionPopupPresenterParams>): IEditSpaceCollectionPopupPresenter {
  const { t } = useTranslation();
  const { spaceId, collectionId } = params!;

  const presenter = useLocalStore<IEditSpaceCollectionPopupPresenter>(() => ({
    get initialValues(): ICollectionFormData | undefined {
      if (!presenter.collection) {
        return undefined;
      }

      return {
        name: presenter.collection.name,
        avatarUrl: presenter.collection.avatarUrl || '',
      };
    },

    get space(): Space | null {
      return spaceId ? Entities.spacesStore.getById(spaceId) : null;
    },

    get collection(): ISpaceCollection | null {
      return presenter.space?.collections.getCollection(collectionId) || null;
    },

    async onSubmit(data: ICollectionFormData): Promise<void> {
      const collection = await Tasks.space.updateSpaceCollection({
        collectionId,
        spaceId,
        ...data,
      });

      collection
        ? showToast(t('space_alert_collection_edit'))
        : showToast(t('something_went_wrong'));

      popupInstance.close();
    },
  }));

  useEffect(() => {
    if (!Entities.spacesStore.isLoading && (!presenter.space?.isOwner || !presenter.collection)) {
      popupInstance.close();
    }
  }, [presenter.space, presenter.collection, Entities.spacesStore.isLoading]);

  return presenter;
}
