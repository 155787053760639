import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { useTranslation } from 'APP/packages/translations';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { GroupForm } from '../../components/GroupForm/GroupForm';
import { useCloseFormPopup } from '../../hooks/useCloseFormPopup';
import { useCreateChatGroupPopupPresenter } from './CreateChatGroupPopup.presenter';

export const CreateChatGroupPopup: FC<ICommonPopupsProps> = observer((props) => {
  const { t } = useTranslation();
  const presenter = useCreateChatGroupPopupPresenter(props.popupInstance);
  const closeForm = useCloseFormPopup(props, t('cancel_channel_creation_alert'));

  return (
    <Popup
      isOpened
      onClose={closeForm.closePopup}
    >
      <GroupForm
        title={t('new_group_chat')}
        initialValues={presenter.initialValues}
        isShowMembers={true}
        onSubmit={presenter.onSubmit}
        onFormStateChange={closeForm.onFormStateChange}
        onClose={closeForm.closePopup}
      />
    </Popup>
  );
});
