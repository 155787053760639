import { ackRewards } from './ackRewards/ackRewards';
import { acquireReward } from './acquireReward/acquireReward';
import deleteAccount from './deleteAccount';
import getPrivacySettings from './getPrivacySettings';
import { getRewardsProgress } from './getRewardsProgress/getRewardsProgress';
import getSessionsInfo from './getSessionsInfo';
import { resetRewardsStorage } from './resetRewardsStorage/resetRewardsStorage';
import setSessionsTtl from './setSessionsTtl';
import { setUserPreferences } from './setUserPreferences/setUserPreferences';
import terminateAllOtherSessions from './terminateAllOtherSessions';
import terminateSessions from './terminateSessions';
import { updateMyProfile } from './updateMyProfile/updateMyProfile';
import updatePrivacySettingsLocally from './updatePrivacySettingsLocally';

export default {
  getPrivacySettings,
  updatePrivacySettingsLocally,
  deleteAccount,
  getSessionsInfo,
  terminateAllOtherSessions,
  terminateSessions,
  setSessionsTtl,
  getRewardsProgress,
  ackRewards,
  acquireReward,
  setUserPreferences,
  resetRewardsStorage,
  updateMyProfile,
};
