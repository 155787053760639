import { IFileInfoResponse } from 'APP/model/common/commonModel.types';
import api from 'APP/packages/api';
import logger from 'APP/packages/logger';
import { getHash } from 'UTILS/getHash';

const xhrCache: Record<string, XMLHttpRequest> = {};

export async function getUploadUrl(type = 'userFile'): Promise<{ url: string }> {
  return api.upload.getUploadUrl({ type });
}

export async function uploadFile(
  url: string,
  file: File | Blob,
  fileName: string,
  onProgress?: XMLHttpRequest['upload']['onprogress']
): Promise<IFileInfoResponse[]> {
  const formData = new FormData();

  if (file instanceof File) {
    formData.append('userFile', file);
  } else {
    formData.append('blob', file, fileName);
  }

  return new Promise((resolve, reject): void => {
    const xhr = new XMLHttpRequest();
    xhr.open('post', url);
    xhr.setRequestHeader(
      'Content-Disposition',
      `attachment; filename="${getHash(fileName || '')}"`
    );
    xhr.onload = function (): void {
      if (xhr.readyState === xhr.DONE) {
        if (xhr.status === 200) {
          try {
            const response = JSON.parse(xhr.responseText) as IFileInfoResponse[];
            resolve(response);
          } catch (error) {
            logger.get('API').error('api.upload', error);
            reject();
          }
        }
      }
    };
    xhr.onerror = reject;
    xhr.onabort = reject;
    if (onProgress) {
      xhr.upload.onprogress = onProgress;
    }
    xhr.send(formData);

    xhrCache[url] = xhr;
  });
}

async function urlToFile(url: string, filename: string, mimeType: string): Promise<File> {
  const response = await fetch(url);
  const buffer = await response.arrayBuffer();

  return new File([buffer], filename, { type: mimeType });
}

export async function uploadBase64(
  base64: string,
  type = 'userFile',
  mimeType = 'image/png'
): Promise<string> {
  const rawData = base64.split(',');
  const mameType = rawData[0].replace('data:', '').replace(';base64', '');

  const fileName = 'upload-file-' + Date.now() + '.' + mameType.split('/')[0];

  const file = await urlToFile(base64, fileName, mimeType);

  const { url } = await getUploadUrl(type);
  const uploadData = await uploadFile(url, file, fileName);
  return uploadData[0].url;
}

export function cancelUploadFile(url: string): void {
  const xhr = xhrCache[url];

  if (xhr) {
    xhr.abort();
  }
}

export function dataURItoBlob(dataURI: string, option = { type: 'image/jpeg' }): Blob {
  const array = atob(dataURI.split(',')[1])
    .split('')
    .map((char) => char.charCodeAt(0));

  return new Blob([new Uint8Array(array)], option);
}
export async function readFileToBlob(file: File): Promise<File | Blob> {
  return await new Promise((resolve): void => {
    try {
      const reader = new FileReader();

      reader.onload = (e): void => {
        const arrayBuffer = reader.result;
        if (arrayBuffer) {
          const blob = new Blob([arrayBuffer], { type: file.type });
          resolve(blob);
        }
        resolve(file);
      };

      reader.readAsArrayBuffer(file);
    } catch (e) {
      logger.get('SYSTEM').error('readFileToBlob ', e);
      resolve(file);
    }
  });
}

export default {
  getUploadUrl,
  uploadFile,
  uploadBase64,
  cancelUploadFile,
  dataURItoBlob,
  readFileToBlob,
};
