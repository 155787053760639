/* global IS_DEVTOOLS */
import Logger from 'js-logger';

// eslint-disable-next-line react-hooks/rules-of-hooks
Logger.useDefaults({
  defaultLevel: !IS_DEVTOOLS ? Logger.WARN : Logger.TRACE,
  formatter: function (messages, context) {
    const d = new Date();
    const time = d.toTimeString().split(' ')[0] + ':' + d.getMilliseconds();
    messages.unshift(
      `%c${time}%c${context.name ? ` [${context.name}]` : ''}`,
      'font-style: italic;',
      'font-size: 1.1em; font-weight: bold;'
    );
  },
});

export default Logger;
