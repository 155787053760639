import { action, computed, observable } from 'mobx';

import {
  PermissionsTypes,
  PermissionsPersonalizedValue,
  PermissionsValue,
} from 'APP/model/callPermissions/callPermissionsModel.constants';
import {
  ICallPermissionRequests,
  ICallPermissions,
  ICallPermissionsSettings,
  ICallPersonalizedPermissions,
} from 'APP/model/callPermissions/callPermissionsModel.types';
import { Call } from 'STORE/Calls/Call/Call';

import { ICallEditedPermissions } from './CallPermissions.types';

export class CallPermissions {
  call: Call;
  @observable defaultPermissions: ICallPermissions = {};
  @observable personalizedPermissions: ICallPersonalizedPermissions = {};
  @observable defaultPersonalizedPermissions: ICallPersonalizedPermissions = {};
  @observable customPermissions = new Map<string, ICallPersonalizedPermissions>();
  @observable editedPermissions: ICallEditedPermissions = {} as ICallEditedPermissions;
  @observable permissionsRequests = new Map<PermissionsTypes, string[]>();

  constructor(call: Call) {
    this.call = call;
  }

  @computed
  get isPermissionsLoaded(): boolean {
    return !!Object.keys(this.defaultPersonalizedPermissions).length;
  }

  @computed
  get customPermissionsIds(): string[] {
    const keys: string[] = [];

    this.customPermissions.forEach((item, key) => {
      keys.push(key);
    });

    return keys;
  }

  @computed
  get isPermissionsRequestsEmpty(): boolean {
    let size = 0;

    this.permissionsRequests.forEach((value) => (size = size + value.length));

    return !size;
  }

  getMemberPersonalizedPermissions(
    personalizedPermissions: ICallPersonalizedPermissions
  ): ICallPersonalizedPermissions {
    const defaultPersonalizedPermissions = this.defaultPersonalizedPermissions;

    return Object.keys(defaultPersonalizedPermissions).reduce((acc, key: PermissionsTypes) => {
      if (personalizedPermissions[key]) {
        acc[key] = personalizedPermissions[key];
      } else {
        acc[key] = defaultPersonalizedPermissions[key];
      }
      return acc;
    }, {} as ICallPersonalizedPermissions);
  }

  @action
  setPermissions(permissions: ICallPermissionsSettings): void {
    if (permissions.default) {
      this.defaultPermissions = permissions.default;
    }

    if (permissions.personalized) {
      this.personalizedPermissions = permissions.personalized;
    }

    if (permissions.defaultPersonalized) {
      this.defaultPersonalizedPermissions = permissions.defaultPersonalized;
    }

    if (permissions.usersWithCustomPermissions?.length) {
      this.customPermissions = permissions.usersWithCustomPermissions.reduce((acc, item) => {
        acc.set(item.userId, item.personalizedCallPermissions);

        return acc;
      }, new Map());
    }
  }

  @action
  setPersonalizedPermissions(permissions: ICallPersonalizedPermissions): void {
    this.personalizedPermissions = permissions;
  }

  @action
  setDefaultPersonalizedPermissions(permissions: ICallPersonalizedPermissions): void {
    this.defaultPersonalizedPermissions = permissions;
  }

  @action
  setCustomUserPermissions(userId: string, permissions: ICallPersonalizedPermissions): void {
    this.customPermissions.set(userId, permissions);
  }

  @action
  deleteCustomUserPermissions(userId: string): void {
    this.customPermissions.delete(userId);
  }

  @action
  setEditedPermissions(permissions: ICallPersonalizedPermissions, userId: string): void {
    this.editedPermissions = {
      permissions: { ...permissions },
      userId,
    };
  }

  @action
  changeEditedPermission(
    userId: string,
    permissionType: PermissionsTypes,
    value: PermissionsPersonalizedValue
  ): void {
    const permissions = this.editedPermissions?.permissions || ({} as ICallPersonalizedPermissions);
    permissions[permissionType] = value;

    this.editedPermissions = {
      permissions,
      userId,
    };
  }

  @action
  clearEditedPermissions(): void {
    this.editedPermissions = {} as ICallEditedPermissions;
  }

  @action
  setDefaultPermissions(permission: PermissionsTypes, value: PermissionsValue): void {
    this.defaultPermissions[permission] = value;
  }

  @action
  setPermissionsRequests(requests: ICallPermissionRequests): void {
    const newRequests = new Map<PermissionsTypes, string[]>();

    this.permissionsRequests = Object.keys(requests).reduce((acc, key: PermissionsTypes) => {
      acc.set(key, requests[key]);
      return acc;
    }, newRequests);
  }

  @action
  addPermissionsRequest(request: { requestType: PermissionsTypes; userId: string }): void {
    let permissionRequests = this.permissionsRequests.get(request.requestType);

    if (permissionRequests) {
      permissionRequests.push(request.userId);
    } else {
      permissionRequests = [request.userId];
    }

    this.permissionsRequests.set(request.requestType, permissionRequests);
  }

  deletePermissionsRequest(request: { requestType: PermissionsTypes; userId: string }): void {
    const permissionRequests = this.permissionsRequests.get(request.requestType);

    if (!permissionRequests) {
      return;
    }

    this.permissionsRequests.set(
      request.requestType,
      permissionRequests.filter((userId) => userId !== request.userId)
    );
  }
}
