import { useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { showAlert } from 'APP/Tasks/app/showAlert/showAlert';
import { useTranslation } from 'APP/packages/translations';
import Entities from 'APP/store';
import { Call } from 'STORE/Calls/Call/Call';

export interface IRecordingPlaceholderPresenter {
  title: string;
  ongoingCall: Call;
  showDialog(): void;
  onStopRecording(): Promise<void>;
}

export const useRecordingPlaceholderPresenter = (): IRecordingPlaceholderPresenter => {
  const { t } = useTranslation();

  const presenter = useLocalStore<IRecordingPlaceholderPresenter>(() => ({
    get title(): string {
      return t('call_recording_rec_hint');
    },

    get ongoingCall(): Call {
      return Entities.Calls.ongoingCall!;
    },

    showDialog(): void {
      const canStopRecording = presenter.ongoingCall.canStopRecording;

      if (canStopRecording) {
        this.onStopRecording();
      } else {
        showAlert(t('calls_recording_recording_alert'));
      }
    },

    async onStopRecording(): Promise<void> {
      const canStop = await Tasks.app.showConfirm({
        text: t('calls_recording_stop_alert'),
        primaryText: t('common_stop'),
      });

      if (canStop) {
        await Tasks.calls.stopRecording();
      }
    },
  }));

  return presenter;
};
