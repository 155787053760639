import classNames from 'classnames';
import GoogleMapReact from 'google-map-react';
import React from 'react';

import { MapMarker } from 'APP/components/MapMarker/MapMarker';
import { GOOGLE_MAP_KEY } from 'APP/constants';

import styles from './styles.m.scss';

const LocationMessage = ({ message, hasBubble = true }) => {
  const { latitude, longitude, originUrl } = message.payload;
  const bubbleClass = classNames(null, {
    [styles.bubble]: hasBubble,
  });

  return (
    <div className={bubbleClass}>
      <div className={styles.map}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: GOOGLE_MAP_KEY }}
          defaultCenter={{ lat: latitude, lng: longitude }}
          defaultZoom={16}
          options={{
            zoomControl: true,
            panControl: true,
            zoomControlOptions: {
              style: 'SMALL',
            },
          }}
          onClick={() => window.open(originUrl)}
        >
          <MapMarker
            lat={latitude}
            lng={longitude}
          />
        </GoogleMapReact>
      </div>
    </div>
  );
};

export default LocationMessage;
