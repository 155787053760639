import { useLocalStore, useAsObservableSource } from 'mobx-react';
import { useRouteMatch } from 'react-router-dom';

import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import Entities from 'APP/store';
import { isMentionAll } from 'UTILS/isMentionAll';

export default ({ nickname }) => {
  const navigateTo = useNavigateTo();
  const match = useRouteMatch();
  const source = useAsObservableSource({ nickname, match });

  const presenter = useLocalStore(() => ({
    get isMentionAll() {
      const group = Entities.GroupsStore.activeGroup;

      return group && group.canMentionAll && isMentionAll(source.nickname);
    },

    openMention() {
      navigateTo({ to: `${source.match.url}/group-members` });
    },
  }));

  return presenter;
};
