import { observer } from 'mobx-react';
import React from 'react';

import { useContextMenu } from 'MAIN/hooks/useContextMenu/useContextMenu';

import { ContextMenu } from './ContextMenu/ContextMenu';
import usePresenter from './presenter';
import styles from './styles.m.css';

const Item = ({ data, children, sharedDataType }) => {
  const { id, messageId, groupId } = data;
  const { popover, onContextMenu, modifiers } = useContextMenu({
    modifiers: [
      {
        name: 'flip',
        enabled: true,
      },
    ],
  });
  const presenter = usePresenter({ groupId, messageId });

  return (
    <li className={styles.container}>
      <div
        role="button"
        tabIndex={0}
        className={styles.button}
        onContextMenu={onContextMenu}
        ref={popover.containerRef}
        onClick={popover.close}
      >
        {children}
      </div>
      {presenter.message && (
        <ContextMenu
          popover={popover}
          message={presenter.message}
          itemId={id}
          placement="bottom-start"
          modifiers={modifiers}
          sharedDataType={sharedDataType}
        />
      )}
    </li>
  );
};

export default observer(Item);
