import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { AvatarWithFullScreen } from 'APP/components/Avatar/AvatarWithFullScreen/AvatarWithFullScreen';
import { Space } from 'STORE/Spaces/Space';
import { Avatar } from 'UIKIT/Avatar/Avatar';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';

interface ISpaceAvatarProps {
  className?: string;
  space: Space;
  type?: AvatarTypes;
  isShowLockIcon?: boolean;
  withFullScreen?: boolean;
}

export const SpaceAvatar: FC<ISpaceAvatarProps> = observer(
  ({ className, space, type = AvatarTypes.Square_20, isShowLockIcon = true, withFullScreen }) => {
    const avatar = (
      <Avatar
        className={className}
        type={type}
        title={space?.name}
        url={space?.avatarUrl}
        backgroundColorIndex={space?.avatarColorIndex}
        hasLockIcon={isShowLockIcon && space?.isPrivate}
      />
    );

    if (withFullScreen) {
      return (
        <AvatarWithFullScreen
          className={className}
          url={space?.avatarUrl}
        >
          {avatar}
        </AvatarWithFullScreen>
      );
    }

    return avatar;
  }
);
