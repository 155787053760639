import format from './format';

export default (date, isH23 = false) => {
  const conf = { hour: '2-digit', minute: '2-digit' };
  if (isH23) {
    conf.hourCycle = 'h23';
  }

  return format(date, conf);
};
