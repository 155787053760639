import { observer } from 'mobx-react';
import { FC } from 'react';

import { useBadgeHandlerPresenter } from './BadgeHandler.presenter';

export const BadgeHandler: FC = observer(() => {
  useBadgeHandlerPresenter();

  return null;
});
