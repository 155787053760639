import { searchItemsByCategory } from 'APP/model/catalog/catalogModel';
import { CatalogType, ICatalogItemsList } from 'APP/model/catalog/catalogModel.types';

interface ILoadCatalogCategoryData {
  catalogType: CatalogType;
  categoryId: string;
  cursor?: string;
}

const ITEMS_LOAD_COUNT = 20;

export const loadCatalogCategory = async (
  data: ILoadCatalogCategoryData
): Promise<ICatalogItemsList | null> => {
  try {
    return await searchItemsByCategory({ ...data, limit: ITEMS_LOAD_COUNT });
  } catch {
    return null;
  }
};
