import React from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import useClearGetParameters from 'APP/router/hooks/useClearGetParameters';
import { NewUsersPopupView } from 'MAIN/Popups/GetParametersManager/Popups/NewUsersPopup/NewUsersPopupView/NewUsersPopupView';

export const NewUsersPopup = () => {
  const clearGetParameters = useClearGetParameters();

  return (
    <Popup
      isOpened
      onClose={clearGetParameters}
    >
      <NewUsersPopupView />
    </Popup>
  );
};
