import classNames from 'classnames';
import React from 'react';

import { useTranslation } from 'APP/packages/translations';
import IcAddUser from 'ICONS/ic-delete.svg';
import { IconView } from 'UIKIT/IconView/IconView';
import popupItemStyles from 'UIKIT/PopupItem/styles.m.scss';

import styles from './styles.m.css';

export const DeleteMemberPermissions = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={classNames(styles.divider, styles.dividerFooter)} />
      <button
        className={classNames(styles.deleteButton, popupItemStyles.interactive)}
        onClick={props.onDeleteButtonClick}
        tabIndex="0"
      >
        <IconView
          Icon={IcAddUser}
          className={styles.deleteIcon}
        />
        {t('call_delete_permissions')}
      </button>
    </>
  );
};
