import React from 'react';

import { ApplicationTheme } from 'APP/constants/appearance';
import SpeedIcon from 'ICONS/ic-speed.svg';
import { IconButton } from 'UIKIT/IconButton';
import { PopoverMenu } from 'UIKIT/PopoverMenu/PopoverMenu';

import { useVideoPlayerSpeedPresenter } from './VideoPlayerSpeed.presenter';
import styles from './VideoPlayerSpeed.styles.m.css';

export const VideoPlayerSpeed = ({ currentSpeed, onSpeedChange, onToggleSpeedPopover }) => {
  const { menuItems, popover, togglePopover, closePopover } = useVideoPlayerSpeedPresenter({
    currentSpeed,
    onSpeedChange,
    onToggleSpeedPopover,
  });

  return (
    <>
      <IconButton
        className={styles.actionButton}
        setRef={popover.containerRef}
        Icon={SpeedIcon}
        size="x-small"
        iconSize="medium"
        theme="19"
        onClick={togglePopover}
      />

      <PopoverMenu
        className={styles.menu}
        menuClassName={styles.menuItem}
        isOpened={popover.isOpened}
        menuItems={menuItems}
        onClose={closePopover}
        reference={popover.containerRef.current}
        placement="top-end"
        theme={ApplicationTheme.Dark}
      />
    </>
  );
};
