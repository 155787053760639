import classNames from 'classnames';
import React, { FC, JSXElementConstructor } from 'react';

import { AnimatedBadge } from 'APP/components/AnimatedBadge/AnimatedBadge';
import { VerifiedBadge } from 'APP/components/VerifiedBadge/VerifiedBadge';
import { IUserNameBadgeSource } from 'APP/constants/userBadges';
import { IconView } from 'UIKIT/IconView/IconView';
import { IconViewSizes } from 'UIKIT/IconView/IconView.types';

import styles from './ProfileNameBadge.styles.m.css';

interface IProfileNameBadgeProps {
  animated?: boolean;
  className?: string;
  displayUILayer?: number;
  source?: IUserNameBadgeSource | JSXElementConstructor<any>;
  inlineBadge?: boolean;
  verified?: boolean;
}

export const ProfileNameBadge: FC<IProfileNameBadgeProps> = (props) => {
  if (!props.verified && !props.source) {
    return null;
  }

  return (
    <div
      className={classNames(props.className, styles.container, {
        [styles.inlineBadge]: props.inlineBadge,
      })}
    >
      {props.verified && <VerifiedBadge className={styles.verified} />}
      {props.animated && props.source && (
        <AnimatedBadge
          animation={props.source as IUserNameBadgeSource}
          displayUILayer={props.displayUILayer}
        />
      )}
      {!props.animated && props.source && (
        <IconView
          size={IconViewSizes.Auto}
          Icon={props.source as JSXElementConstructor<any>}
        />
      )}
    </div>
  );
};
