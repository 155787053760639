import api from 'APP/packages/api';
import logger from 'APP/packages/logger';

export const getGroupUserRoles = async ({ groupId }) => {
  try {
    const response = await api.group.getGroupUserRoles({ groupId });

    if (response && response.participants && response.participants.length > 0) {
      return response.participants;
    }
  } catch (error) {
    logger.get('API').error('Tasks.group.getGroupUserRoles', error);
  }

  return [];
};
