import { subscribe, broadcast } from '../../eventBus';

function getBusStation(groupId) {
  return `participants-changes-${groupId}`;
}

export const subscribeParticipantsChanges = (groupId, callback) => {
  return subscribe(getBusStation(groupId), callback);
};

export const broadcastParticipantsChanges = (groupId, data) => {
  broadcast(getBusStation(groupId), data);
};
