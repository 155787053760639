import { useLocalStore } from 'mobx-react';

import { CallBlurType } from 'APP/constants/callBackground';
import { ICallProviderPublisher } from 'APP/packages/callProviders/callProviders.types';
import Entities from 'STORE';
import { Call } from 'STORE/Calls/Call/Call';

interface IBackgroundPanelPresenter {
  publisher: ICallProviderPublisher | null;
  activeBlurType: CallBlurType | null;
  ongoingCall: Call;
  onBlurClick(blurType: CallBlurType): void;
  onClose(): void;
}

export const useBackgroundPanelPresenter = (): IBackgroundPanelPresenter => {
  const presenter = useLocalStore<IBackgroundPanelPresenter>(() => ({
    get ongoingCall(): Call {
      return Entities.Calls.ongoingCall!;
    },

    get publisher(): ICallProviderPublisher | null {
      return presenter.ongoingCall.backgroundPanelShown &&
        presenter.ongoingCall.me.publisher.hasVideo
        ? presenter.ongoingCall.me.publisher
        : null;
    },

    get activeBlurType(): CallBlurType | null {
      return presenter.ongoingCall.me.audioVideoStreamer.blurType || null;
    },

    onBlurClick(blurType: CallBlurType): void {
      presenter.ongoingCall.me.audioVideoStreamer.setBlur(blurType);
    },

    onClose(): void {
      presenter.ongoingCall.hideBackgroundPanel();
    },
  }));

  return presenter;
};
