import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { MessageViewPageType } from 'MAIN/ChatView/Messages/Messages.types';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

import { MessageLayout } from '../../MessageLayout/MessageLayout';
import { ForwardMessage } from '../components/ForwardMessage/ForwardMessage';
import { QuotedMessage } from '../components/QuotedMessage/QuotedMessage';
import StatusBar from '../components/StatusBar';
import { ThreadButton } from '../components/ThreadButton/ThreadButton';
import { useCircleVideoBubblePresenter } from './CircleVideoBubble.presenter';
import styles from './CircleVideoBubble.styles.m.css';

interface ICircleVideoBubbleProps {
  message: ChatMessage;
  children: React.ReactNode;
  messageViewPageType: MessageViewPageType;
  isView: boolean;
}

// Todo will be add all functionality later
export const CircleVideoBubble: FC<ICircleVideoBubbleProps> = observer((props) => {
  const { message, children, messageViewPageType, isView } = props;
  const presenter = useCircleVideoBubblePresenter(message);

  const contentClasses = classNames(styles.content, {
    [styles.my]: message.fromMe,
  });

  return (
    <MessageLayout
      message={message}
      className={styles.bubble}
      containerClassName={styles.container}
      messageViewPageType={messageViewPageType}
      isView={isView}
    >
      {presenter.isShowForward && (
        <ForwardMessage
          className={styles.header}
          theme={'2'}
          message={message}
        />
      )}

      {message.quotedMessage && (
        <QuotedMessage
          className={styles.header}
          theme={'2'}
          message={message.quotedMessage}
        />
      )}

      <div className={contentClasses}>
        {children}
        <div className={classNames(styles.statusBar, styles.durationBar)}>{presenter.duration}</div>
        <StatusBar
          className={classNames(styles.statusBar, styles.viewBar)}
          message={message}
        />
      </div>

      <ThreadButton
        className={styles.thread}
        theme={'2'}
        message={message}
      />
    </MessageLayout>
  );
});
