import classNames from 'classnames';
import React, { FC, memo } from 'react';

import AnimatedPartStyles from '../../AnimatedPart/AnimatedPart.styles.m.css';
import useSyncAnimation from '../../hooks/useSyncAnimations';
import styles from './CatalogCategoriesPlaceholder.styles.m.css';

export const CatalogCategoriesPlaceholder: FC = memo(() => {
  const { isFirstAnimated } = useSyncAnimation({
    firstTimeout: 500,
    secondTimeout: 1000,
  });

  const loaderClassName = classNames(styles.loader, {
    [AnimatedPartStyles.animation]: isFirstAnimated,
  });

  return (
    <div className={styles.container}>
      <div className={classNames(loaderClassName, styles.size1)} />
      <div className={classNames(loaderClassName, styles.size2)} />
      <div className={classNames(loaderClassName, styles.size3)} />
      <div className={classNames(loaderClassName, styles.size4)} />
      <div className={classNames(loaderClassName, styles.size5)} />
      <div className={classNames(loaderClassName, styles.size6)} />
      <div className={classNames(loaderClassName, styles.size7)} />
    </div>
  );
});
