import { observer } from 'mobx-react';
import { FC } from 'react';

import { useChatUpdateHandlerPresenter } from './ChatUpdateHandler.presenter';

export const ChatUpdateHandler: FC = observer(() => {
  useChatUpdateHandlerPresenter();

  return null;
});
