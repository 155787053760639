import { STREAM_STATUS } from 'APP/constants/stream';
import { useTranslation } from 'APP/packages/translations';

export function useStreamPayloadPresenter(payload) {
  const { t } = useTranslation();

  const presenter = {
    get isActive() {
      return payload.status === STREAM_STATUS.STARTED;
    },

    get text() {
      if (presenter.isActive) {
        return t('stream_started');
      }

      return t('stream_finished_title');
    },
  };

  return presenter;
}
