import logger from 'APP/packages/logger';
import Entities from 'APP/store';

export function handleMessageKeywords(property) {
  try {
    const { settings = [] } = JSON.parse(property.value);
    settings.forEach((item) => {
      Entities.clientConfig.addMessageKeywords(item.keyWordType, item.animationUrl);
    });
  } catch (error) {
    logger.get('Tasks').error('login.clientConfig.messageKeyword', error);
  }
}
