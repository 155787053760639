import Tasks from 'APP/Tasks';
import { ALERT_TYPES } from 'APP/constants/app';
import { ServerErrorCode } from 'APP/model/error/error.constants';
import api from 'APP/packages/api';
import logger from 'APP/packages/logger';
import Entities from 'STORE';

export const addCallMembers = async (groupId: string, userIds: string[]): Promise<void> => {
  try {
    const group = Entities.Calls.getCallByGroupId(groupId);

    if (!group) {
      return;
    }

    await api.calls.addCallMembers({
      channelId: group.channelId,
      userIds,
    });
  } catch (error) {
    logger.get('API').error('messaging.addCallMembers', error);
    if (error?.message === ServerErrorCode.TooManyInvitedMembersInShortTime) {
      Tasks.app.addAlert({
        type: ALERT_TYPES.TOO_MANY_INVITED_MEMBERS,
      });
    } else {
      Tasks.app.addAlert({
        type: ALERT_TYPES.SOMETHING_WRONG_ERROR,
      });
    }
  }
};
