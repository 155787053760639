/// <reference lib="webworker" />
import type InputPanel from 'STORE/InputPanelsStore/InputPanel';

import { Encoder } from './Voice.encoder';

export enum WorkerMessageType {
  CreateEncoder = 'createEncoder',
  Encode = 'encode',
  FinalBuffer = 'finalBuffer',
  ClearBuffer = 'clearBuffer',
}

export interface VoiceWorkerMessage {
  type: WorkerMessageType;
  data: unknown;
}

export interface VoiceServiceWorkerLocalScope extends ServiceWorkerGlobalScope {
  lameEncoder: Encoder;
  postMessage(message: VoiceWorkerMessage): void;
  addEventListener: ServiceWorkerGlobalScope['addEventListener'];
}

export interface EncoderConfig {
  sampleRate: number;
  bitRate?: number;
  numberOfChannels?: 1 | 2;
}

export interface IMessageData {
  type: string;
  data: { file: File; histogram: string; duration: number };
  groupId: string;
  quotedMessage: InputPanel['quoteMessage'];
}
