import React, { memo } from 'react';

import styles from '../FileIcon.styles.m.css';
import { TFileTheme } from '../FileIcon.types';

interface IFileIconRegularProps {
  theme: TFileTheme;
  text: string;
}

export const FileIconRegular = memo<IFileIconRegularProps>((props) => {
  const { theme, text } = props;

  return (
    <>
      <path
        className={styles[`file-${theme}`]}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31 3H11C7.68629 3 5 5.68629 5 9V37C5 40.3137 7.68629 43 11 43H37C40.3137 43 43 40.3137 43 37V15L31 3Z"
      />
      <path
        opacity="0.2"
        d="M43 15L31 3V11C31 13.2091 32.7909 15 35 15H43Z"
        fill="black"
      />
      <text
        className={styles.text}
        x="50%"
        y="50%"
        dominantBaseline="middle"
        textAnchor="middle"
      >
        {text}
      </text>
    </>
  );
});
