import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { IGroupMember } from 'APP/Tasks/group/participants/getParticipants';
import { GroupUserRole } from 'APP/model/group/groupModel.types';
import { Group } from 'APP/store/Groups/Group';
import { debounce } from 'APP/utils/debounce';
import { makeCancellablePromise } from 'APP/utils/makeCancellablePromise';

export interface IParticipantListPresenterParams {
  readonly group: Group | null;
  readonly searchText?: string;
  onAddParticipantClick(onSuccess: () => void): void;
}

export interface IParticipantListPresenter {
  participants: IGroupMember[];
  isLoading: boolean;
  cursor: string | null;
  cancelLoadMore: (() => void) | null;
  hasMore: boolean;
  canAddParticipants: boolean;

  search: () => void;
  init(): void;
  loadMore(): Promise<void>;
  onReloadParticipantsList(): void;
  removeParticipant(userId: string): void;
  updateParticipantRole(userId: string, role: GroupUserRole): void;
  showAddPeople(): void;
}

const SEARCH_DELAY = 200;
const PARTICIPANT_LIMIT = 20;

export function useParticipantListPresenter({
  group,
  searchText,
  onAddParticipantClick,
}: IParticipantListPresenterParams): IParticipantListPresenter {
  const source = useAsObservableSource({ group, searchText });

  const presenter = useLocalStore<IParticipantListPresenter>(() => ({
    cursor: null,
    isLoading: false,
    participants: [],
    cancelLoadMore: null,

    get canAddParticipants(): boolean {
      return source.group?.canInviteUsers || false;
    },

    get hasMore(): boolean {
      return !!presenter.cursor;
    },

    search: debounce(async () => {
      await presenter.init();
    }, SEARCH_DELAY),

    async init(): Promise<void> {
      this.participants = [];
      this.cursor = null;
      await presenter.loadMore();
    },

    async loadMore(): Promise<void> {
      if (!source.group) {
        return;
      }

      if (presenter.cancelLoadMore) {
        presenter.cancelLoadMore();
      }

      presenter.isLoading = true;

      const { promise: getParticipants, cancel } = makeCancellablePromise(
        Tasks.group.getParticipants({
          groupId: source.group.id,
          searchText: source.searchText,
          cursor: presenter.cursor || '',
          limit: PARTICIPANT_LIMIT,
        })
      );

      presenter.cancelLoadMore = cancel;

      const groupParticipantsData = await getParticipants;

      if (groupParticipantsData) {
        const { participants, cursor } = groupParticipantsData;
        presenter.participants.push(...participants);
        presenter.cursor = cursor;
      }

      presenter.cancelLoadMore = null;
      presenter.isLoading = false;
    },

    removeParticipant(userId: string): void {
      presenter.participants = presenter.participants.filter(
        (participant) => participant.user.id !== userId
      );
    },

    updateParticipantRole(userId: string, role: GroupUserRole): void {
      const participant = presenter.participants.find(
        (participant) => participant.user.id === userId
      );
      if (participant) {
        participant.role = role;
      }
    },

    showAddPeople(): void {
      const onSuccess = (): void => {
        searchText ? presenter.search() : presenter.init();
      };

      onAddParticipantClick(onSuccess);
    },

    onReloadParticipantsList(): void {
      presenter.init();
    },
  }));

  useEffect(() => {
    if (group) {
      searchText ? presenter.search() : presenter.init();
    }
  }, [group, searchText]);

  return presenter;
}
