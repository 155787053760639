import { Placement } from '@popperjs/core/lib/enums';
import { TippyProps } from '@tippyjs/react';
import Tippy from '@tippyjs/react/headless';
import React, { FC, forwardRef, ReactElement, ReactNode, Ref, useState } from 'react';
import { followCursor } from 'tippy.js/headless';

import { platformInfo } from 'APP/packages/platformInfo/platformInfo';

import { TooltipContent } from './TooltipContent/TooltipContent';

export interface ITooltipActions {
  hide(): void;
  update(): void;
}

interface ITooltipProps {
  className?: string;
  render?: (tooltip: ITooltipActions) => ReactNode;
  text?: string;
  arrow?: boolean;
  placement?: Placement;
  delay?: number;
  disabled?: boolean;
  hideOnClick?: boolean;
  followCursor?: TippyProps['followCursor'];
}

const DEFAULT_TIPPY_OPTIONS: TippyProps = {
  appendTo: () => document.body,
  interactive: true,
  hideOnClick: false,
  placement: 'top',
};

export const Tooltip: FC<ITooltipProps> = forwardRef((props, ref) => {
  const { className, render, text, arrow, ...tippyOptions } = props;
  const [mounted, setMounted] = useState(false);

  const lazyPlugin = {
    fn: () => ({
      onMount: () => setMounted(true),
      onHidden: () => setMounted(false),
    }),
  };

  const tippyProps = {
    ...DEFAULT_TIPPY_OPTIONS,
    ...tippyOptions,
    children: props.children,
  } as TippyProps;

  tippyProps.plugins = [lazyPlugin, ...(tippyProps.followCursor ? [followCursor] : [])];

  tippyProps.render = (attrs, _, instance): ReactElement | null => {
    if (mounted) {
      const tooltip: ITooltipActions = {
        hide: () => instance?.hide(),
        update: () => {
          if (platformInfo.isSafari) {
            setTimeout(() => {
              const contentEl = instance?.popper.firstChild as HTMLDivElement;

              // This is fix for Safari browser to force browser to rerender content element,
              if (contentEl) {
                contentEl.setAttribute('style', '');
                contentEl.removeAttribute('style');
              }

              instance?.popperInstance?.update();
            }, 0);
          } else {
            instance?.popperInstance?.update();
          }
        },
      };

      return (
        <TooltipContent
          className={className}
          attrs={attrs}
          arrow={arrow}
        >
          {render?.(tooltip) || text}
        </TooltipContent>
      );
    } else {
      return null;
    }
  };

  return (
    <Tippy
      {...tippyProps}
      ref={ref as Ref<Element>}
    />
  );
});
