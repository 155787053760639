import { useLocalStore } from 'mobx-react';
import { useEffect, useRef } from 'react';

import patterns from 'APP/packages/patterns';

const linkRegex = new RegExp(patterns.AUTOLINK_WEB_URL, 'iu');

export default ({ onAddLink, onBackClick }) => {
  const inputRef = useRef();

  const presenter = useLocalStore(() => ({
    value: '',

    onChange: (event) => {
      presenter.value = event.target.value;
    },

    get isDisabledSubmit() {
      return !linkRegex.test(presenter.value);
    },

    onBackClick: (event) => {
      event.stopPropagation();
      onBackClick();
    },

    onDone: (event) => {
      event.stopPropagation();

      onAddLink(presenter.value);
    },

    onEnter: (event) => {
      event.stopPropagation();
      event.preventDefault();

      if (presenter.isDisabledSubmit) {
        return;
      }

      onAddLink(presenter.value);
    },
  }));

  useEffect(() => {
    // autoFocus detach the position of popper
    inputRef.current.focus();
  }, []);

  return { presenter, inputRef };
};
