import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, ReactElement } from 'react';

import { List } from 'APP/components/List/List';
import { Popup } from 'APP/components/Popup/Popup';
import MascotBlockImage from 'APP/images/gem-mascot-block.svg';
import { UsersPlaceholder } from 'APP/packages/placeholders/UsersPlaceholder/UsersPlaceholder';
import { useTranslation } from 'APP/packages/translations';
import { User } from 'STORE/Users/User/User';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';
import { InfoBlock, InfoBlockTypes } from 'UIKIT/InfoBlock/InfoBlock';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { useClosePopupOnNavigation } from '../../hooks/useClosePopupOnNavigation';
import { ISpaceBlackListPopupParams, useSpaceBlackListPresenter } from './SpaceBlackList.presenter';
import styles from './SpaceBlackList.styles.m.css';
import { SpaceBlackListItem } from './SpaceBlackListItem/SpaceBlackListItem';

export const SpaceBlackList: FC<ICommonPopupsProps<ISpaceBlackListPopupParams>> = observer(
  (props) => {
    const { t } = useTranslation();
    const presenter = useSpaceBlackListPresenter(props);
    useClosePopupOnNavigation(props.popupInstance);

    return (
      <Popup
        isOpened
        title={t('balck_list_title')}
        handlePrevStep={presenter.hasBackButton ? presenter.onBack : null}
        onClose={presenter.onClose}
      >
        <div className={classNames(styles.container, customScrollStyles.container)}>
          <List
            isLoading={presenter.isLoading}
            hasMore={presenter.hasMore}
            data={presenter.users}
            onEndReached={presenter.loadMore}
            keyExtractor={(user: User): string => user.id}
            renderItem={(user: User): ReactElement => (
              <SpaceBlackListItem
                space={presenter.space}
                user={user}
                onUnbanUser={presenter.onUnbanUser}
                onClosePopup={presenter.onClose}
              />
            )}
            ListEmptyComponent={
              <InfoBlock
                className={styles.infoBlock}
                descriptions={[t('ban_empty_ban_list')]}
                type={InfoBlockTypes.Modal}
                image={MascotBlockImage}
              />
            }
            SpinnerComponent={
              <UsersPlaceholder
                className={styles.placeholders}
                count={presenter.users.length > 0 ? 2 : 10}
              />
            }
          />
        </div>
      </Popup>
    );
  }
);
