import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { MessageViews } from 'APP/components/MessageViews/MessageViews';
import { ReactionButton } from 'APP/components/ReactionButton/ReactionButton';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

import CommentButton from './CommentButton';
import ForwardButton from './ForwardButton';
import { usePostFooterPresenter } from './PostFooter.presenter';
import styles from './PostFooter.styles.m.css';

interface IPostFooterProps {
  message: ChatMessage;
}

export const PostFooter: FC<IPostFooterProps> = observer(({ message }) => {
  const presenter = usePostFooterPresenter(message);

  return (
    <div className={styles.container}>
      <hr className={styles.row} />

      <div className={styles.content}>
        <div className={styles.actions}>
          {presenter.isShowReactionButton && <ReactionButton message={message} />}
          <CommentButton message={message} />
          <ForwardButton message={message} />
        </div>

        <MessageViews
          message={message}
          className={styles.views}
          showStatus={false}
        />
      </div>
    </div>
  );
});
