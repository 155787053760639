import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { BotButtonPayloadType } from 'APP/model/bot/botModel.constants';
import { Buttons as ButtonsPayload } from 'STORE/Messages/Message/Payload/Buttons/Buttons';

import { BaseCommandButton } from './BaseCommandButton/BaseCommandButton';
import styles from './CommandButtons.styles.m.css';
import { DefaultCommandButton } from './DefaultCommandButton/DefaultCommandButton';
import { OpenUrlCommandButton } from './OpenUrlCommandButton/OpenUrlCommandButton';
import { ShareCardCommandButton } from './ShareCardCommandButton/ShareCardCommandButton';

const buttonMap = {
  [BotButtonPayloadType.ShareCard]: ShareCardCommandButton,
  [BotButtonPayloadType.OpenUrl]: OpenUrlCommandButton,
  [BotButtonPayloadType.Command]: BaseCommandButton,
};

interface ICommandButtonsProps {
  payload: ButtonsPayload;
  groupId: string;
}

export const CommandButtons: FC<ICommandButtonsProps> = observer((props) => {
  const { payload, groupId } = props;
  const { layout, buttons } = payload;

  if (!layout || !layout.length) {
    return null;
  }

  if (!buttons || !buttons.length) {
    return null;
  }

  let current = 0;
  let rowNumber = 0;

  return (
    <>
      {layout.map((count) => {
        const next = current + parseInt(count);
        const row = buttons.slice(current, next);
        current = next;

        return (
          <div
            key={++rowNumber}
            className={styles.row}
          >
            {row.map((button) => {
              const ButtonComponent =
                button.payload && buttonMap[button.payload.type]
                  ? buttonMap[button.payload.type]
                  : DefaultCommandButton;
              return (
                <ButtonComponent
                  key={button.id}
                  button={button}
                  groupId={groupId}
                />
              );
            })}
          </div>
        );
      })}
    </>
  );
});
