import { SpaceType } from 'APP/model/space/spaceModel.types';
import api from 'APP/packages/api';
import logger from 'APP/packages/logger';

const ERRORS = {
  IS_EMPTY: 'IS_EMPTY',
  TOO_SHORT: 'TOO_SHORT',
  INCORRECT_SYMBOLS: 'INCORRECT_SYMBOLS',
  IS_ALREADY_EXIST: 'IS_ALREADY_EXIST',
};

const SPACE_ALIAS_MIN_LENGTH = 2;
const SPACE_ALIAS_REGEXP = /^[a-zA-Z0-9-_]+$/;

const validateCreate = ({ value, values, errorToText }) => {
  if (values.type !== SpaceType.Public) {
    return null;
  }

  if (value?.length > 0 && value?.length < SPACE_ALIAS_MIN_LENGTH) {
    return errorToText[ERRORS.TOO_SHORT];
  }

  if (!SPACE_ALIAS_REGEXP.test(value)) {
    return errorToText[ERRORS.INCORRECT_SYMBOLS];
  }

  return null;
};

const validateEdit = ({ value, values, errorToText }) => {
  if (values.type !== SpaceType.Public) {
    return null;
  }

  if (!value || value.length === 0) {
    return errorToText[ERRORS.IS_EMPTY];
  }

  return validateCreate({ value, values, errorToText });
};

const asyncValidate = async ({ value, values, errorToText }) => {
  if (values.type !== SpaceType.Public) {
    return null;
  }

  try {
    const response = await api.space.deepLinkExists({
      alias: value,
    });

    if (response?.result === false) {
      return null;
    }
  } catch (error) {
    logger.get('API').error('space.deepLinkExists', error);
  }

  return errorToText[ERRORS.IS_ALREADY_EXIST];
};

export default {
  validateCreate,
  validateEdit,
  asyncValidate,
  ERRORS,
};
