import React, { FC } from 'react';

import { openPopup } from 'APP/Tasks/app/app';
import { AddParticipantsPopup } from 'APP/main/PopupManager/Popups/AddParticipantsPopup/AddParticipantsPopup';
import { useTranslation } from 'APP/packages/translations';
import AddUserIcon from 'ICONS/ic-add-user.svg';
import { IconView } from 'UIKIT/IconView/IconView';

import styles from './AddPeopleLink.styles.m.css';

interface IAddPeopleLinkProps {
  groupId: string;
}

export const AddPeopleLink: FC<IAddPeopleLinkProps> = (props) => {
  const { t } = useTranslation();

  const onClick = (): void => {
    openPopup(AddParticipantsPopup, { params: { groupId: props.groupId } });
  };

  return (
    <button
      onClick={onClick}
      className={styles.link}
    >
      <IconView
        Icon={AddUserIcon}
        className={styles.icon}
      />
      <span className={styles.title}>{t('common_add_people')}</span>
    </button>
  );
};
