import hasAudioPermissions from 'APP/Tasks/permissions/hasAudioPermissions';
import hasMediaPermissions from 'APP/Tasks/permissions/hasMediaPermissions';

import hasCameraPermission from './hasCameraPermission';
import hasMicrophonePermission from './hasMicrophonePermission';

export default {
  hasMicrophonePermission,
  hasCameraPermission,
  hasAudioPermissions,
  hasMediaPermissions,
};
