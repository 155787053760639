import classNames from 'classnames';
import React, { FC, memo } from 'react';

import { FileName } from 'UIKIT/FileName/FileName';

import styles from './AudioPlayerTitle.styles.m.css';

interface IAudioPlayerTitleProps {
  className?: string;
  title: string;
  description: string;
  onClick(): void;
}

export const AudioPlayerTitle: FC<IAudioPlayerTitleProps> = memo(
  ({ className, title, description, onClick }) => {
    return (
      <button
        className={classNames(className, styles.container)}
        title={title}
        onClick={onClick}
      >
        {description ? (
          <span
            className={styles.title}
            title={title}
          >
            {title}
          </span>
        ) : (
          <FileName
            className={styles.title}
            name={title}
          />
        )}
        {description && <span className={styles.description}>{description}</span>}
      </button>
    );
  }
);
