function at(n) {
  n = Math.trunc(n) || 0;
  if (n < 0) {
    n += this.length;
  }
  if (n < 0 || n >= this.length) {
    return undefined;
  }
  return this[n];
}

const TypedArray = Reflect.getPrototypeOf(Int8Array);
for (const Type of [Array, String, TypedArray]) {
  if (!Type.prototype.at) {
    Object.defineProperty(Type.prototype, 'at', {
      value: at,
      writable: true,
      enumerable: false,
      configurable: true,
    });
  }
}
