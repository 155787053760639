export function prepareLink({
  location,
  to = '',
  query = {},
  state = {},
  keepOldQuery = false,
  keepOldState = false,
  keepOldPathname = false,
  params = {},
}) {
  const pathname = keepOldPathname ? location?.pathname + to : to || location?.pathname;

  const newQuery = keepOldQuery ? new URLSearchParams(location?.search) : new URLSearchParams();

  Object.entries(query).forEach(([key, value]) => {
    newQuery.append(key, value);
  });
  const newState = keepOldState ? { ...location.state, ...state } : state;

  return {
    pathname: Object.keys(params).length ? replaceWithParams(pathname, params) : pathname,
    search: newQuery.toString() ? `?${newQuery.toString()}` : '',
    state: newState,
  };
}

export function replaceWithParams(path, params) {
  const paramsKeys = Object.keys(params);

  if (!paramsKeys.length) {
    return path;
  }

  const pathParts = path.split('/');

  for (const key of paramsKeys) {
    for (let i = 0; i < pathParts.length; i++) {
      if (pathParts[i] === `:${key}`) {
        pathParts[i] = params[key];
      }
    }
  }

  return pathParts.join('/');
}
