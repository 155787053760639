import { useAsObservableSource, useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { openPopup } from 'APP/Tasks/app/popup/popup';
import { useTranslation } from 'APP/packages/translations';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import Entities from 'APP/store';
import FileIcon from 'ICONS/file-medium.svg';
import ArticleIcon from 'ICONS/ic-article.svg';
import LocationIcon from 'ICONS/location.svg';
import { SelectLocationPopup } from 'MAIN/PopupManager/Popups/SelectLocationPopup/SelectLocationPopup';

export default (fileUploadRef) => {
  const { t } = useTranslation();
  const navigateTo = useNavigateTo();
  const source = useAsObservableSource({
    navigateTo,
  });

  const presenter = useLocalStore(() => ({
    get group() {
      return Entities.GroupsStore.activeGroup;
    },

    get menuItems() {
      return [
        {
          key: 'Article',
          icon: ArticleIcon,
          title: t('channels:channel_article'),
          onClick: presenter.openArticle,
          isVisible: presenter.group.isChannel && presenter.group.withMeInAdmins,
        },
        {
          key: 'File',
          icon: FileIcon,
          title: t('message_desctription_file'),
          onClick: () => fileUploadRef.current && fileUploadRef.current.open(),
          isVisible: true,
        },
        {
          key: 'Location',
          icon: LocationIcon,
          title: t('message_desctription_location'),
          onClick: presenter.openSelectLocationPopup,
          isVisible: true,
        },
      ].filter((item) => item.isVisible);
    },

    openArticle() {
      source.navigateTo({
        keepOldPathname: true,
        to: '/article',
      });
    },

    openSelectLocationPopup() {
      openPopup(SelectLocationPopup, {
        params: {
          groupId: presenter.group.id,
          onSelect(location) {
            Tasks.messaging.sentLocationMessage(presenter.group.id, location);
          },
        },
      });
    },
  }));

  return presenter;
};
