import Tasks from 'APP/Tasks';
import api from 'APP/packages/api';
import logger from 'APP/packages/logger';
import { langService } from 'MODULE/i18n/service';

const terminateSessions = async (registrationKey) => {
  try {
    const confirmation = await Tasks.app.showConfirm({
      text: langService.i18n.lang.common_terminate_sessions_alert,
      primaryText: langService.i18n.lang.common_yes,
      secondaryText: langService.i18n.lang.common_cancel,
    });

    if (confirmation) {
      await api.authorization.terminateSessions({
        registrationKeys: [registrationKey],
      });
    }
  } catch (error) {
    logger.get('API').error('userSettings.terminateSessions', error);
  }
};

export default terminateSessions;
