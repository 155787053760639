import Tasks from 'APP/Tasks';
import api from 'APP/packages/api';
import logger from 'APP/packages/logger';
import Entities from 'APP/store';
import { t } from 'MODULE/i18n/service';

export const stopRecording = async (): Promise<void> => {
  try {
    const call = Entities.Calls.ongoingCall;

    if (!call) {
      return;
    }

    const channelId = call.channelId;
    const recordingId = call.recordingId;
    logger.get('API').debug('calls.stopRecording', { channelId, recordingId });

    await api.calls.stopRecording({
      channelId,
      recordingId,
    });
  } catch (e) {
    logger.get('API').error('calls.stopRecording', e);

    Tasks.app.showAlert(t('call_record_can_not_be_stopped_message'));
  }
};
