import Tasks from 'APP/Tasks';
import { addAlert } from 'APP/Tasks/app/showAlert/showAlert';
import { ALERT_TYPES } from 'APP/constants/app';
import { AuthProvider } from 'APP/model/auth/authModel.types';
import { ServerErrorCode } from 'APP/model/error/error.constants';
import { authModel } from 'APP/model/model';
import { platformInfo } from 'APP/packages/platformInfo/platformInfo';
import Entities from 'APP/store';
import { getUserLanguage } from 'UTILS/getUserLanguage';

export const authWithGoogle = async (
  accessToken: string,
  reCaptchaToken?: string
): Promise<void> => {
  try {
    const { registrationKey } = await authModel.authWithSocialNetwork({
      token: accessToken,
      authProviderId: AuthProvider.Google,
      captchaToken: reCaptchaToken,
      deviceInfo: {
        platform: platformInfo.platform,
        appVersion: Entities.App.version,
        locale: getUserLanguage(),
        device: platformInfo.device,
        osVersion: platformInfo.osVersion,
      },
    });

    const { isSuccess, error } = await Tasks.authorization.login(registrationKey);

    if (isSuccess) {
      Entities.AuthStore.setConnectionToken(registrationKey);
    } else {
      throw error;
    }
  } catch (error: any) {
    if (error.message === ServerErrorCode.PhoneHasHighRiskScore) {
      addAlert({ type: ALERT_TYPES.PHONE_HAS_HIGH_RISK_SCORE });
    } else if (error.message === ServerErrorCode.AttemptsExceeded) {
      addAlert({ type: ALERT_TYPES.ATTEMPTS_EXCEEDED });
    } else {
      addAlert({ type: ALERT_TYPES.SOMETHING_WRONG_ERROR });
    }
  }
};
