import { useLocalStore } from 'mobx-react';

import Entities from 'APP/store';

export default () => {
  const presenter = useLocalStore(() => ({
    get inputPanel() {
      return Entities.InputPanelsStore.activeInputPanel;
    },

    get isOpened() {
      if (!presenter.inputPanel) {
        return false;
      }

      return presenter.inputPanel.bots.isOpenedCommandList;
    },

    toggle() {
      if (!presenter.inputPanel) {
        return;
      }

      presenter.inputPanel.bots.toggleOpenedCommandList();
    },
  }));

  return presenter;
};
