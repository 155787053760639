import Tasks from 'APP/Tasks';
import { t } from 'MODULE/i18n/service';
import { BackgroundCall } from 'STORE/BackgroundCalls/BackgroundCall/BackgroundCall';

interface IShowConfirmRunCallData {
  call: BackgroundCall;
  groupId: string;
}

export const showConfirmRunCall = async ({
  call,
  groupId,
}: IShowConfirmRunCallData): Promise<void> => {
  const res = await Tasks.app.showConfirm({
    text: t('calls_active_call_alert'),
    primaryText: t('common_yes'),
    secondaryText: t('common_no'),
  });

  if (res && call.channelId) {
    Tasks.calls.joinToCall({
      groupId: groupId,
      callType: call.callType,
      channelId: call.channelId,
      withAudio: false,
      withVideo: false,
      providerType: call.callProvider,
    });
  }
};
