import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

import { GroupAvatar } from 'APP/components/Avatar/GroupAvatar/GroupAvatar';
import { useTranslation } from 'APP/packages/translations';
import { Group } from 'STORE/Groups/Group';

import { ISearchResultItemProps } from '../SearchResultItem.types';
import styles from './SearchResultSavedMessages.style.m.css';

export const SearchResultSavedMessages: FC<ISearchResultItemProps<Group>> = observer(
  ({ entity: group, className, activeClassName }) => {
    const { t } = useTranslation();

    return (
      <NavLink
        className={className}
        activeClassName={activeClassName}
        to={`/${group.route}/${group.id}`}
      >
        <GroupAvatar group={group} />
        <div className={styles.content}>
          <div className={styles.top}>
            <div className={styles.header}>{t('saved_message_chat_tittle')}</div>
          </div>
          <div className={styles.bottom}>
            <div className={styles.description}>{t('saved_messages_chat_description_web')}</div>
          </div>
        </div>
      </NavLink>
    );
  }
);
