import { useLocalStore, useAsObservableSource } from 'mobx-react';

import date from 'APP/packages/date';
import Entities from 'APP/store';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

interface ICircleVideoBubblePresenter {
  isShowForward: boolean;
  commentsCount: number;
  duration: string;
}
export const useCircleVideoBubblePresenter = (
  message: ChatMessage
): ICircleVideoBubblePresenter => {
  const source = useAsObservableSource({ message });

  const presenter = useLocalStore<ICircleVideoBubblePresenter>(() => ({
    get isShowForward(): boolean {
      return Boolean(source.message.forward && !source.message.isSavedMessagesLeft);
    },

    get commentsCount(): number {
      if (source.message.threadId) {
        return Entities.Counters.getCounterById(source.message.threadId)?.totalMessagesCount || 0;
      }
      return 0;
    },

    get duration(): string {
      return date.secondsToString(source.message.payload.duration / 1000);
    },
  }));

  return presenter;
};
