import classNames from 'classnames';
import React, { FC } from 'react';

import { ISticker, StickerType } from 'APP/model/stickers/stickersModel.types';
import { getWidthHeightByMaxValues } from 'APP/packages/imageResizer';

import { AnimatedSticker } from './AnimatedSticker/AnimatedSticker';
import { StaticSticker } from './StaticSticker/StaticSticker';
import { useStickerPresenter } from './Sticker.presenter';
import styles from './Sticker.styles.m.css';

interface IStickerProps {
  sticker: ISticker;
  maxWidth: number;
  maxHeight: number;
  autoplay?: boolean;
  stickerPackId: string | null;
  displayUILayer?: number;
  className?: string;
  root?: HTMLElement | null;
  onClick?: (sticker: ISticker) => void;
}

export const Sticker: FC<IStickerProps> = ({
  className,
  maxWidth,
  maxHeight,
  sticker,
  stickerPackId,
  autoplay,
  displayUILayer,
  root,
  onClick,
}) => {
  const { width, height } = getWidthHeightByMaxValues(
    sticker.graphicSize.width,
    sticker.graphicSize.height,
    maxWidth,
    maxHeight
  );

  const presenter = useStickerPresenter(stickerPackId, sticker, onClick);

  const stickerProps = {
    className: classNames(styles.sticker, className),
    width,
    height,
  };

  return (
    <button
      {...presenter}
      className={classNames(styles.sticker, className)}
    >
      {sticker.type === StickerType.Animated && (
        <AnimatedSticker
          {...stickerProps}
          animationUrl={sticker.animationUrl}
          autoplay={autoplay}
          displayUILayer={displayUILayer}
          root={root}
        />
      )}
      {sticker.type === StickerType.Static && (
        <StaticSticker
          {...stickerProps}
          staticUrl={sticker.stickerUrl}
        />
      )}
    </button>
  );
};
