import { observer } from 'mobx-react';
import React from 'react';

import { BottomPopup } from 'APP/components/BottomPopup/BottomPopup';
import { useTranslation } from 'APP/packages/translations';
import StarIcon from 'ICONS/ic-star-48.svg';
import { Checkbox } from 'UIKIT/Checkbox';
import { IconView } from 'UIKIT/IconView/IconView';
import { IconViewSizes } from 'UIKIT/IconView/IconView.types';
import { Portal } from 'UIKIT/Portal/Portal';

import { useStarsFeedbackPresenter } from './StarsFeedback.presenter';
import styles from './styles.m.css';

export const StarsFeedback = observer(() => {
  const { t } = useTranslation();
  const presenter = useStarsFeedbackPresenter();

  if (!presenter.isShown) {
    return null;
  }

  return (
    <Portal>
      <BottomPopup
        onClose={presenter.onClose}
        className={styles.popup}
      >
        <div className={styles.title}>{t('heading_howd_the_call_go')}</div>
        <div className={styles.subTitle}>{t('description_tell_us_call_experience')}</div>
        <div className={styles.starsContainer}>
          {presenter.starsArray.map((star) => (
            <button
              key={star}
              className={styles.star}
              onClick={() => presenter.onStarClick(star)}
            >
              <IconView
                Icon={StarIcon}
                size={IconViewSizes.XXLarge}
              />
            </button>
          ))}
        </div>
        <label className={styles.dontShowContainer}>
          <Checkbox
            className={styles.checkbox}
            onChange={presenter.onDontShowChange}
            isChecked={presenter.isDontShowChecked}
          />
          {t('button_dont_show_me_again') as string}
        </label>
      </BottomPopup>
    </Portal>
  );
});
