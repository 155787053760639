import Tasks from 'APP/Tasks';
import { openPopup } from 'APP/Tasks/app/popup/popup';
import { SessionsPopup } from 'MAIN/PopupManager/Popups/Sessions/SessionsPopup/SessionsPopup';
import Entities from 'STORE';
import { IPushNotification } from 'STORE/PushNotifications/PushNotifications.types';

export const onNewSessionClick = async (
  pushNotification: IPushNotification,
  notification: Notification
): Promise<void> => {
  if (notification && pushNotification.group) {
    Tasks.app.fullscreen.closeFullscreen();
    Tasks.app.focusApp();

    openPopup(SessionsPopup);
    Entities.pushNotifications.removeNotificationByRef(notification);
  }
};
