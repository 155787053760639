import { useLocalStore, useAsObservableSource } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { AvatarPlaceholderType } from 'APP/packages/placeholders/AvatarPlaceholder/AvatarPlaceholder';
import { useTranslation } from 'APP/packages/translations';
import { SidebarPanelMode } from 'MAIN/SidebarPanel/SidebarPanel.constants';
import Entities from 'STORE';
import { Group } from 'STORE/Groups/Group';
import { getVisibleGroups } from 'UTILS/getVisibleGroups';

interface IGroupListDelimiter {
  isDelimiter: boolean;
}

type IGroupListItem = { group: Group; isRecommended: boolean } | IGroupListDelimiter;

export interface IGroupListPresenterParams {
  groups: Group[];
  panelMode: SidebarPanelMode;
}

export function isDelimiterItem(item: IGroupListItem): item is IGroupListDelimiter {
  return typeof (item as IGroupListDelimiter)?.isDelimiter === 'boolean';
}

interface IGroupListPresenter {
  recommendedLoading: boolean;
  recommendedChannels: Group[];
  visibleGroups: Group[];
  visibleRecommendedChannels: Group[];
  recommendedTitle: string;
  listItems: IGroupListItem[];
  itemsCount: number;
  isShowPlaceholder: boolean;
  avatarType: AvatarPlaceholderType;
  groupHash: string;
  updateRecommendedChannels(): Promise<void>;
}

const GROUPS_COUNT_FOR_LOAD_RECCOMENDED = 7;

export const useGroupListPresenter = (data: IGroupListPresenterParams): IGroupListPresenter => {
  const { t } = useTranslation();
  const { groups, panelMode } = data;
  const source = useAsObservableSource({ groups, panelMode });

  const presenter = useLocalStore<IGroupListPresenter>(() => ({
    recommendedLoading: false,
    recommendedChannels: [],

    get visibleGroups(): Group[] {
      return getVisibleGroups(source.groups);
    },

    get listItems(): IGroupListItem[] {
      if (Entities.spacesStore.isLoading) {
        return [];
      }

      const groups: IGroupListItem[] = presenter.visibleGroups.map((group) => ({
        group,
        isRecommended: false,
      }));

      const recommended: IGroupListItem[] = presenter.visibleRecommendedChannels.map((group) => ({
        group,
        isRecommended: true,
      }));

      return recommended.length ? groups.concat([{ isDelimiter: true }], recommended) : groups;
    },

    get visibleRecommendedChannels(): Group[] {
      if (
        source.panelMode === SidebarPanelMode.Channels &&
        presenter.visibleGroups.length <= GROUPS_COUNT_FOR_LOAD_RECCOMENDED &&
        presenter.recommendedChannels.length > 0
      ) {
        const groupsIds = source.groups.map((group) => group.id);
        return presenter.recommendedChannels.filter((group) => !groupsIds.includes(group.id));
      }

      return [];
    },

    get itemsCount(): number {
      return presenter.listItems.length;
    },

    get isShowPlaceholder(): boolean {
      return (
        Entities.GroupsStore.isLoading ||
        Entities.spacesStore.isLoading ||
        presenter.recommendedLoading
      );
    },

    get avatarType(): AvatarPlaceholderType {
      return source.panelMode === SidebarPanelMode.Channels
        ? AvatarPlaceholderType.Square_0
        : AvatarPlaceholderType.Round_10;
    },

    get recommendedTitle(): string {
      return Entities.userPreferences.categoryIds.length
        ? t('channel_catalog_suggested')
        : t('channel_catalog_most_popular');
    },

    // TODO: this getter should be removed, after initGroups method refactored
    // This method triggers list to redrawn a huge number of times
    get groupHash(): string {
      if (Entities.GroupsStore.isLoading) {
        return presenter.listItems.length.toString();
      }

      return presenter.listItems.reduce((result, item) => {
        result += '_' + (!isDelimiterItem(item) ? item.group.id : 'delemeter');
        return result;
      }, '');
    },

    async updateRecommendedChannels(): Promise<void> {
      if (
        !Entities.GroupsStore.isLoading &&
        !Entities.spacesStore.isLoading &&
        source.panelMode === SidebarPanelMode.Channels &&
        presenter.visibleGroups.length <= GROUPS_COUNT_FOR_LOAD_RECCOMENDED
      ) {
        presenter.recommendedLoading = true;
        presenter.recommendedChannels = await Tasks.group.getAvailableChannels();
        presenter.recommendedLoading = false;

        return;
      }

      presenter.recommendedChannels = [];
    },
  }));

  useEffect(() => {
    presenter.updateRecommendedChannels();
  }, [panelMode, Entities.GroupsStore.isLoading, Entities.spacesStore.isLoading]);

  return presenter;
};
