import { CallBlurType } from 'APP/constants/callBackground';

import { localStorageInstance } from '../instances/localStorageInstance';

export const BLUR_BACKGROUND_TYPE = 'blurBackgroundType';

export const setBlurBackgroundType = (value: CallBlurType): void => {
  localStorageInstance.setItem(BLUR_BACKGROUND_TYPE, JSON.stringify(value));
};

export const getBlurBackgroundType = (): CallBlurType | null => {
  const value = localStorageInstance.getItem(BLUR_BACKGROUND_TYPE);

  try {
    return value ? JSON.parse(value) : null;
  } catch {
    return null;
  }
};
