import React, { FC } from 'react';

import MyLocationIcon from 'ICONS/ic-my-location.svg';

import styles from './CenterPinButton.styles.m.css';

interface ICenterPinButtonProps {
  centerPin(): void;
}

export const CenterPinButton: FC<ICenterPinButtonProps> = ({ centerPin }) => {
  return (
    <button
      className={styles.container}
      onClick={centerPin}
    >
      <MyLocationIcon />
    </button>
  );
};
