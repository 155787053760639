import { ApiError } from 'APP/packages/api/error/ApiError';

const MODEL_ERROR_NAME = 'MODEL_ERROR';

export class ModelError extends Error {
  public readonly name: string = MODEL_ERROR_NAME;

  constructor(message: string, public code: number, public apiError: ApiError) {
    super(message);
  }
}
