import { useAsObservableSource, useLocalStore } from 'mobx-react';

import dateService from 'APP/packages/date';
import Entities from 'STORE';
import { TAvatarBackgroundColorIndex } from 'UIKIT/Avatar/Avatar.types';

import { IScheduledEventInfoWithDate } from '../ScheduledCallsList.types';

interface IScheduledCallsListPopupItemPresenter {
  time: string;
  groupColorIndex?: TAvatarBackgroundColorIndex;
}

interface IScheduledCallsListPopupPresenterParams {
  scheduledEvent: IScheduledEventInfoWithDate;
}

export function useScheduledCallsListPopupItemPresenter({
  scheduledEvent,
}: IScheduledCallsListPopupPresenterParams): IScheduledCallsListPopupItemPresenter {
  const source = useAsObservableSource({ scheduledEvent });

  return useLocalStore<IScheduledCallsListPopupItemPresenter>(() => ({
    get groupColorIndex(): TAvatarBackgroundColorIndex | undefined {
      const group = Entities.GroupsStore.getGroupById(source.scheduledEvent.groupId);

      return group ? group.avatarColorIndex : undefined;
    },

    get time(): string {
      return `${dateService.time(source.scheduledEvent.startTs, false)} - ${dateService.time(
        source.scheduledEvent.endTs,
        false
      )}`;
    },
  }));
}
