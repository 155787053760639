import { BanUsersForGroupRequest } from 'APP/packages/api/types/model/banUsersForGroupRequest';
import { ChangeOwnershipRequest } from 'APP/packages/api/types/model/changeOwnershipRequest';
import { GetGroupUserRolesRequest } from 'APP/packages/api/types/model/getGroupUserRolesRequest';
import { GroupParticipantChangeRequest } from 'APP/packages/api/types/model/groupParticipantChangeRequest';
import { GroupUserRolesResponse } from 'APP/packages/api/types/model/groupUserRolesResponse';
import { SearchUsersInGroupResponse } from 'APP/packages/api/types/model/searchUsersInGroupResponse';
import { SetGroupKeywordFiltersRequest } from 'APP/packages/api/types/model/setGroupKeywordFiltersRequest';
import { SetGroupObsceneFilterRequest } from 'APP/packages/api/types/model/setGroupObsceneFilterRequest';

import { ICategoryInfo, IDeepLinkInfo, IUserMentionData } from '../common/commonModel.types';
import { IMessage } from '../message/messageModel.types';
import { IBotUserDisplayData, IUserDisplayData } from '../user/userModel.types';

export enum GroupUserRole {
  Owner = 'OWNER',
  Admin = 'ADMIN',
  Member = 'MEMBER',
  Guest = 'GUEST',
  Banned = 'BANNED',
}

export enum GroupType {
  My = 'group.my',
  P2P = 'group.private.single',
  Open = 'group.open',
  Closed = 'group.private',
  ChannelOpen = 'group.public.open',
  ChannelClosed = 'group.public.closed',
  MySavedMessages = 'group.my.saved_messages',
  SavedMessagesFake = 'fakeSavedMessagesGroup',
  Thread = 'group.thread',
  Schedule = 'group.schedule',
}

export interface ISupportedMediaTypes {
  canSendImage: boolean;
  canSendVideo: boolean;
  canSendSticker: boolean;
  canSendAudio: boolean;
  canSendVoice: boolean;
  canSendFile: boolean;
  canSendLink: boolean;
  cansSendCircleVideo: boolean;
}

export interface IMemberCustomPermissions {
  canInitCall: boolean;
  canInitConference: boolean;
  canInviteUsers: boolean;
  canLeaveComment: boolean;
  canPinMessage: boolean;
  canSendMessages: boolean;
  canDisplayMembers: boolean;
  supportedMediaTypes: ISupportedMediaTypes;
  updatedAt: string;
}

export interface IMemberCustomPermissionsRequest {
  groupId: string;
  canInviteUsers: boolean;
  canInitCall: boolean;
  canInitConference: boolean;
  canPinMessage: boolean;
  canLeaveComment: boolean;
  canSendMessages: boolean;
  canDisplayMembers: boolean;
  canInitSystemMessage?: boolean;
  supportedMediaTypes: ISupportedMediaTypes;
}

export interface IDraftInput {
  groupId: string;
  text?: string;
  updateTs: number;
}

export interface IGroupSettings {
  systemMessagesEnabled: boolean;
}

export interface ISetGroupSettings {
  groupId: string;
  systemMessagesEnabled?: boolean;
}

export interface IGroup {
  avatarUrl?: string;
  categoryId: string;
  categoryInfo: ICategoryInfo;
  deepLinkInfo: IDeepLinkInfo;
  deleted: boolean;
  description?: string;
  descriptionMentions: IUserMentionData[];
  donationUrl?: string;
  id: string;
  initiator: string;
  memberCustomPermissions: IMemberCustomPermissions;
  name: string;
  pinnedMessages: IMessage[];
  spaceId?: string;
  type: string;
  usersCount: number;
  verified: boolean;
  settings?: IGroupSettings;
  obsceneFilterEnabled: boolean;
  keywordFilters: number;
}

export interface IPersonalizedGroup {
  botOpponent?: IBotUserDisplayData;
  draftInput?: IDraftInput;
  groupResponse: IGroup;
  muted: boolean;
  opponent?: IUserDisplayData;
  pinOrder: number;
  unread: boolean;
  showContent?: boolean;
  userGroupRole: GroupUserRole;
}

export type IGroupData = IGroup & Partial<Omit<IPersonalizedGroup, 'groupResponse'>>;

export interface IPinMessagesRequest {
  groupId: string;
  messageIds: string[];
}

export interface IGroupIdRequest {
  groupId: string;
}

export interface ISearchUsersInGroupResponse {
  cursor: string | null;
  participants: SearchUsersInGroupResponse['users'];
}

export type IGetGroupUserRolesRequest = GetGroupUserRolesRequest;

export type IGroupUserRolesResponse = GroupUserRolesResponse;

export type IChangeOwnershipRequest = ChangeOwnershipRequest;

export type IBanUsersForGroupRequest = BanUsersForGroupRequest;

export type IGroupParticipantChangeRequest = GroupParticipantChangeRequest;

export type ISetObsceneFilterRequest = SetGroupObsceneFilterRequest;

export type ISetKeywordFiltersRequest = SetGroupKeywordFiltersRequest;
