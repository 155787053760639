import React, { FC } from 'react';

import { CompactCallView } from '../CompactCallView/CompactCallView';
import styles from './MobileCompactCallView.styles.m.css';

export const MobileCompactCallView: FC = () => {
  return (
    <div className={styles.container}>
      <CompactCallView className={styles.mobileCompactCallView} />
    </div>
  );
};
