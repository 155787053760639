export const GOOGLE_MAP_KEY = 'AIzaSyBgU2waVW3VDhT_poyiO55kfjuw-hbS7Lg';

export const IMAGE_TYPE = {
  ROUND: 'round',
  SQUARE: 'square',
};

export const INACTIVE_PERIODS = {
  ONE_WEEK: 'ONE_WEEK',
  ONE_MONTH: 'ONE_MONTH',
  THREE_MONTHS: 'THREE_MONTHS',
  SIX_MONTHS: 'SIX_MONTHS',
};

export const MAP_PERIOD_TO_LABEL = {
  [INACTIVE_PERIODS.ONE_WEEK]: 'common_one_week',
  [INACTIVE_PERIODS.ONE_MONTH]: 'common_one_month',
  [INACTIVE_PERIODS.THREE_MONTHS]: 'common_three_months',
  [INACTIVE_PERIODS.SIX_MONTHS]: 'common_six_months',
};

export const PLATFORMS = {
  WEB: 'web',
  ANDROID: 'android',
  IOS: 'ios',
  DESKTOP: 'desktop',
};

export const DIRECTION = {
  NEWEST: 'NEWEST',
  OLDEST: 'OLDEST',
};

export const DEFAULT_MEDIA_PREVIEW_IMG =
  'https://storage.googleapis.com/gem-im-public/ic-web-broken-image.png';
