import { request } from '../request/request';

const ROOT_API = 'media';

export const getGroupMessagesByMediaTypesCount = (data) => {
  return request({
    rootApi: ROOT_API,
    url: '/getGroupMessagesByMediaTypesCount',
    method: 'POST',
    body: data,
  });
};
