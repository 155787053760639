export enum AudioPlayerOrder {
  Direct = 'direct',
  Reverse = 'reverse',
  Shuffle = 'shuffle',
}

export enum AudioPlayerRepeat {
  None = 'none',
  Track = 'track',
  List = 'list',
}

export interface IAudioPlayerConfig {
  speed: number;
  order: AudioPlayerOrder;
  repeat: AudioPlayerRepeat;
}

export interface IAudioPlayerTrack {
  id: string;
  url: string;
  title: string;
  description?: string;
  size: number;
  duration: number;
}
