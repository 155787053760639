import { useLocalStore } from 'mobx-react';

import Entities from 'APP/store';

export default () => {
  const presenter = useLocalStore(() => ({
    get inputPanel() {
      return Entities.InputPanelsStore.activeInputPanel;
    },

    get hasFilter() {
      return presenter.inputPanel.mention.hasFilter;
    },

    get isAvailableMentions() {
      return presenter.inputPanel.mention.isAvailable && presenter.hasFilter;
    },
  }));

  return presenter;
};
