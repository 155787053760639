import { useLocalStore, useAsObservableSource } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { ALERT_TYPES } from 'APP/constants/app';
import { GroupType } from 'APP/model/group/groupModel.types';
import Entities from 'APP/store';

const CHIPS_LIMIT = 50;

export const useCommon = ({
  myContacts,
  extendedUsers,
  setSelectedIds,
  selectedContactsIds,
  targetGroupType,
}) => {
  const source = useAsObservableSource({ selectedContactsIds });

  const presenter = useLocalStore(() => ({
    searchValue: '',
    isChannel: [GroupType.ChannelOpen, GroupType.ChannelClosed].includes(targetGroupType),
    get selectedContactsIds() {
      return source.selectedContactsIds;
    },

    get chips() {
      return presenter.selectedContactsIds.map((id) => {
        const contact = Entities.UsersStore.getUserById(id);

        return {
          data: contact,
          avatarTitle: contact.displayName,
          avatarUrl: contact.avatarUrl || contact.avatar || contact.image,
          title: contact.avatarTitle || contact.name,
          avatarColorIndex: contact.avatarColorIndex,
        };
      });
    },

    get isSearchMode() {
      return presenter.searchValue.length > 0;
    },

    get canSelectMore() {
      return presenter.selectedContactsIds.length < CHIPS_LIMIT;
    },

    get noResults() {
      if (myContacts.isLoading) {
        return false;
      }

      if (presenter.isSearchMode && extendedUsers.isLoading) {
        return false;
      }

      if (presenter.isSearchMode) {
        return myContacts.searchContacts.length === 0 && extendedUsers.users.length === 0;
      }

      return myContacts.contacts.length === 0;
    },

    toggleContact: (candidate) => {
      if (
        (!presenter.isChannel && !candidate.canAddToGroupMe) ||
        (presenter.isChannel && !candidate.canAddToPublicMe)
      ) {
        Tasks.app.addAlert({ type: ALERT_TYPES.PRIVACY_ADD_TO_GROUP });
        return;
      }

      const isExist = presenter.selectedContactsIds.includes(candidate.id);

      if (isExist) {
        setSelectedIds(presenter.selectedContactsIds.filter((id) => id !== candidate.id));
      } else {
        setSelectedIds([...presenter.selectedContactsIds, candidate.id]);
      }
    },

    getIsSelected: (candidate) => {
      return presenter.selectedContactsIds.includes(candidate.id);
    },

    removeChips: (item) => {
      setSelectedIds(presenter.selectedContactsIds.filter((id) => id !== item.data.id));
    },

    onSearchValueChange: (value) => {
      presenter.searchValue = value;

      myContacts.onSearchValueChange(value);
      extendedUsers.onSearchValueChange(value);
    },
  }));

  return presenter;
};
