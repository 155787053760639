import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Entities from 'APP/store';

export default () => {
  const presenter = useLocalStore(() => ({
    startOnline() {
      Entities.App.setOfflineNetworkStatus(false);
    },
    startOffline() {
      Entities.App.setOfflineNetworkStatus(true);
    },
  }));

  useEffect(() => {
    window.addEventListener('online', presenter.startOnline);
    window.addEventListener('offline', presenter.startOffline);

    return () => {
      window.removeEventListener('online', presenter.startOnline);
      window.removeEventListener('offline', presenter.startOffline);
    };
  }, []);
};
