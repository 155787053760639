import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useCallback } from 'react';

import { List } from 'APP/components/List/List';
import { Popup } from 'APP/components/Popup/Popup';
import NoBlockedUserImage from 'APP/images/gem-mascot-block.svg';
import { GroupsPlaceholder } from 'APP/packages/placeholders/GroupsPlaceholder/GroupsPlaceholder';
import { useTranslation } from 'APP/packages/translations';
import PopupUserWithMenuItem from 'MAIN/Popups/GetParametersManager/components/PopupUserWithMenuItem';
import { withPopupLayer } from 'MAIN/Popups/withPopupLayer';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';
import { InfoBlock, InfoBlockTypes } from 'UIKIT/InfoBlock/InfoBlock';

import { useBlackListPresenter } from './BlackList.presenter';
import styles from './BlackList.styles.m.css';
import { BlackListMenu } from './BlackListMenu/BlackListMenu';

const BlackListPopup = () => {
  const { t } = useTranslation();
  const presenter = useBlackListPresenter();

  const renderItem = useCallback((user) => {
    return (
      <PopupUserWithMenuItem
        user={user}
        toggleMenu={presenter.toggleMenu}
        isOpenedMenu={presenter.isOpenedMenu}
        onClick={user.startChat}
      />
    );
  }, []);

  return (
    <Popup
      isOpened={presenter.isOpened}
      title={t('balck_list_title')}
      onClose={presenter.close}
    >
      <div className={classNames(styles.container, customScrollStyles.container)}>
        <div className={styles.innerContainer}>
          <List
            isLoading={presenter.isLoading}
            hasMore={presenter.hasMore}
            data={presenter.users}
            onEndReached={presenter.onEndReached}
            keyExtractor={presenter.keyExtractor}
            renderItem={renderItem}
            ListEmptyComponent={
              <InfoBlock
                className={styles.infoBlock}
                descriptions={[t('ban_empty_ban_list')]}
                type={InfoBlockTypes.Modal}
                image={NoBlockedUserImage}
              />
            }
            SpinnerComponent={
              <GroupsPlaceholder
                count={presenter.users.length > 0 ? 2 : 10}
                className={styles.placeholders}
              />
            }
          />
          <BlackListMenu
            user={presenter.activeUser}
            reference={presenter.menuRef}
            toggleMenu={presenter.toggleMenu}
          />
        </div>
      </div>
    </Popup>
  );
};

export default withPopupLayer(observer(BlackListPopup));
