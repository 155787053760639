import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { useTranslation } from 'APP/packages/translations';
import { PopupUserItem } from 'MAIN/Popups/GetParametersManager/components/PopupUserItem/PopupUserItem';
import PopupUserWithMenuItem from 'MAIN/Popups/GetParametersManager/components/PopupUserWithMenuItem';
import { PopoverMenu } from 'UIKIT/PopoverMenu/PopoverMenu';

import {
  ISpaceMembersListItemPresenterParams,
  useSpaceMembersListItemPresenter,
} from './SpaceMembersListItem.presenter';
import styles from './SpaceMembersListItem.styles.m.css';

type ISpaceMembersListItemProps = ISpaceMembersListItemPresenterParams;

export const SpaceMembersListItem: FC<ISpaceMembersListItemProps> = observer((props) => {
  const { member } = props;
  const { t } = useTranslation();
  const presenter = useSpaceMembersListItemPresenter(props);

  let hint = '';
  if (presenter.isOwner) {
    hint = t('common_owner_label');
  } else if (presenter.isAdmin) {
    hint = t('common_admin_label');
  }

  const onOpenUserChat = presenter.canStartUserChat ? presenter.onOpenUserChat : undefined;

  if (!presenter.menuItems.length) {
    return (
      <PopupUserItem
        user={member.user}
        hintClassName={styles.hintWithoutMenu}
        hint={hint}
        onClick={onOpenUserChat}
      />
    );
  }

  return (
    <>
      <PopupUserWithMenuItem
        user={member.user}
        hint={hint}
        isOpenedMenu={presenter.isOpenedMenu}
        isMenuLoading={presenter.isMenuLoading}
        toggleMenu={presenter.onToggleMenu}
        onClick={onOpenUserChat}
      />

      <PopoverMenu
        isOpened={presenter.isOpenedMenu}
        placement="bottom-end"
        menuItems={presenter.menuItems}
        reference={presenter.menuReference}
        onClose={presenter.onCloseMenu}
      />
    </>
  );
});
