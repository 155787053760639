import { useLocalStore, useAsObservableSource } from 'mobx-react';

import Entities from 'APP/store';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

interface IStickerBubblePresenter {
  isShowForward: boolean;
  commentsCount: number;
}
export const useStickerBubblePresenter = (message: ChatMessage): IStickerBubblePresenter => {
  const source = useAsObservableSource({ message });

  const presenter = useLocalStore<IStickerBubblePresenter>(() => ({
    get isShowForward(): boolean {
      return Boolean(source.message.forward && !source.message.isSavedMessagesLeft);
    },

    get commentsCount(): number {
      if (source.message.threadId) {
        return Entities.Counters.getCounterById(source.message.threadId)?.totalMessagesCount || 0;
      }
      return 0;
    },
  }));

  return presenter;
};
