import { useLocalStore } from 'mobx-react';

import Entities from 'APP/store';
import { Group } from 'APP/store/Groups/Group';

interface IChatHeaderPresenter {
  group: Group | null;
  hasSelectedMessages: boolean;
}

export function useChatHeaderPresenter(): IChatHeaderPresenter {
  const presenter = useLocalStore<IChatHeaderPresenter>(() => ({
    get group(): Group | null {
      return Entities.GroupsStore.activeGroup;
    },

    get hasSelectedMessages(): boolean {
      return !!presenter.group?.messagesStore.multiSelect.hasSelectedMessages;
    },
  }));

  return presenter;
}
