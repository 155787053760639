import { useAsObservableSource, useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { UpdateReason } from 'APP/constants/scroll';
import { IUserReactions } from 'APP/model/message/messageModel.types';
import { getFormatNumberToString } from 'APP/utils/getFormatNumberToString';
import Entities from 'STORE';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';
import { sortReactions } from 'UTILS/sortReactions';

import { IReactionItem } from './useReactions.types';

export interface IReactionsPresenter {
  message: ChatMessage;
  hasReactions: boolean;
  reactionsMessage: IReactionItem[];
  reactionsShortMenu: IReactionItem[];
  reactionsFullMenu: IReactionItem[];
  currentMyReaction: IReactionItem | null;
  getOptimisticReactions(reactionId: string): IUserReactions;
  isNeedScrolling(optimisticReactions: IUserReactions): boolean;
  onClick(reactionId: string): Promise<void>;
}

export const useReactions = (message: ChatMessage): IReactionsPresenter => {
  const source = useAsObservableSource({ message });

  const presenter = useLocalStore<IReactionsPresenter>(() => ({
    get message(): ChatMessage {
      return source.message;
    },

    get hasReactions(): boolean {
      return presenter.reactionsMessage.length > 0;
    },

    get reactionsMessage(): IReactionItem[] {
      const messageReactions =
        presenter.message.optimisticReactions || presenter.message.usersReactions;

      if (!messageReactions) {
        return [];
      }

      const usersReactions = Object.keys(messageReactions.usersReactions);
      const reactions = Entities.reactionsStore.allReactions
        .filter((reaction) => {
          return usersReactions.includes(reaction.id);
        })
        .sort((reaction1, reaction2) => {
          return sortReactions(
            { id: reaction1.id, count: messageReactions.usersReactions[reaction1.id] || 0 },
            { id: reaction2.id, count: messageReactions.usersReactions[reaction2.id] || 0 }
          );
        });

      return reactions.map((reaction) => ({
        ...reaction,
        counter: getFormatNumberToString(messageReactions.usersReactions[reaction.id]),
        hasMyReaction: reaction.id === messageReactions.currentUserReactionId,
        onClick: () => presenter.onClick(reaction.id),
      }));
    },

    get reactionsShortMenu(): IReactionItem[] {
      return Entities.reactionsStore.shortReactions.map((reaction) => ({
        ...reaction,
        counter: '',
        hasMyReaction: reaction.id === presenter.message.usersReactions.currentUserReactionId,
        onClick: () => presenter.onClick(reaction.id),
      }));
    },

    get reactionsFullMenu(): IReactionItem[] {
      return Entities.reactionsStore.allReactions.map((reaction) => ({
        ...reaction,
        counter: '',
        hasMyReaction: reaction.id === presenter.message.usersReactions.currentUserReactionId,
        onClick: () => presenter.onClick(reaction.id),
      }));
    },

    get currentMyReaction(): IReactionItem | null {
      return presenter.reactionsMessage.find((x) => x.hasMyReaction) || null;
    },

    getOptimisticReactions(reactionId: string): IUserReactions {
      let currentUserReactionId = presenter.message.usersReactions.currentUserReactionId;
      const usersReactions = { ...presenter.message.usersReactions.usersReactions };

      if (!currentUserReactionId) {
        currentUserReactionId = reactionId;
        usersReactions[currentUserReactionId] = usersReactions[currentUserReactionId]
          ? usersReactions[currentUserReactionId] + 1
          : 1;
      } else if (currentUserReactionId === reactionId) {
        usersReactions[currentUserReactionId] = usersReactions[currentUserReactionId]
          ? usersReactions[currentUserReactionId] - 1
          : 0;
        currentUserReactionId = null;
      } else if (currentUserReactionId !== reactionId) {
        usersReactions[currentUserReactionId] = usersReactions[currentUserReactionId]
          ? usersReactions[currentUserReactionId] - 1
          : 0;
        usersReactions[reactionId] = usersReactions[reactionId]
          ? usersReactions[reactionId] + 1
          : 1;
        currentUserReactionId = reactionId;
      }

      Entities.reactionsStore.allReactions.forEach((reaction) => {
        if (!usersReactions[reaction.id]) {
          delete usersReactions[reaction.id];
        }
      });

      return { currentUserReactionId, usersReactions };
    },

    isNeedScrolling(optimisticReactions: IUserReactions): boolean {
      const { usersReactions: optimisticUsersReactions } = optimisticReactions;
      const { usersReactions } = presenter.message.usersReactions;

      return (
        !Object.keys(usersReactions).length && Object.keys(optimisticUsersReactions).length === 1
      );
    },

    async onClick(reactionId: string): Promise<void> {
      const optimisticReactions = presenter.getOptimisticReactions(reactionId);

      presenter.message.setOptimisticReactions(optimisticReactions);

      if (presenter.isNeedScrolling(optimisticReactions)) {
        Entities.ChatStore.setScrollChanged(UpdateReason.EditMessage, {
          messageIds: [presenter.message.id],
          isAnimated: true,
        });
      }

      await Tasks.messaging.updateMessageReaction({
        groupId: presenter.message.groupId,
        messageId: presenter.message.id,
        reactionId:
          presenter.message.usersReactions.currentUserReactionId === reactionId
            ? undefined
            : reactionId,
      });

      const message = presenter.message.messages.getMessageById(presenter.message.id);

      message?.setOptimisticReactions(null);
    },
  }));

  return presenter;
};
