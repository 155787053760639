import { useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';

import { SearchResultEntity } from './SearchResult.types';
import { ISearchResultLocalPresenter } from './SearchResultLocal.presenter';
import {
  filterGroupsDuplicates,
  filterSpacesDuplicates,
  filterUsersDuplicates,
} from './getLocalSearchResults/filterSearchDuplicates';

const SEARCH_RESULTS_OFFSET = 15;

interface ISearchResultGlobalPresenter {
  results: SearchResultEntity[];
  offset: number;
  isLoading: boolean;
  hasMoreResults: boolean;
  requestHash: string | null;
  showDelimiter: boolean;
  noResults: boolean;
  showLoadMore: boolean;
  calculateRequestHash(searchText: string): string;
  loadResults(searchText: string): Promise<void>;
  search(searchText: string): Promise<void>;
  loadMore(searchText: string): Promise<void>;
  reset(): void;
}

export const useSearchResultGlobalPresenter = (
  localSearchPresenter: ISearchResultLocalPresenter
): ISearchResultGlobalPresenter => {
  const presenter = useLocalStore<ISearchResultGlobalPresenter>(() => ({
    results: [],
    offset: 0,
    isLoading: false,
    hasMoreResults: false,
    requestHash: null,

    calculateRequestHash(searchText: string): string {
      return searchText + presenter.offset.toString();
    },

    async loadResults(searchText: string): Promise<void> {
      presenter.isLoading = true;

      presenter.requestHash = presenter.calculateRequestHash(searchText);

      const { groups, spaces, users, hasMoreResults } = await Tasks.search.getGlobalSearchResults(
        searchText,
        presenter.offset
      );

      if (presenter.requestHash !== presenter.calculateRequestHash(searchText)) {
        return;
      }

      const { localGroups, localSpaces, localUsers } = localSearchPresenter;
      const filteredGroups = filterGroupsDuplicates(groups, localGroups);
      const filteredSpaces = filterSpacesDuplicates(spaces, localSpaces);
      const filteredUsers = filterUsersDuplicates(users, localUsers, localGroups);

      presenter.results.push(...filteredGroups, ...filteredUsers, ...filteredSpaces);

      presenter.hasMoreResults = hasMoreResults;
      presenter.isLoading = false;
    },

    async search(searchText: string): Promise<void> {
      presenter.reset();
      await presenter.loadResults(searchText);
    },

    async loadMore(searchText: string): Promise<void> {
      if (!presenter.hasMoreResults || presenter.isLoading) {
        return;
      }

      presenter.offset += SEARCH_RESULTS_OFFSET;
      await presenter.loadResults(searchText);
    },

    get showDelimiter(): boolean {
      return presenter.results.length > 0 || presenter.isLoading;
    },

    get noResults(): boolean {
      return presenter.results.length === 0 && !presenter.isLoading;
    },

    get showLoadMore(): boolean {
      return presenter.results.length > 0 && !presenter.isLoading && presenter.hasMoreResults;
    },

    reset(): void {
      presenter.results = [];
      presenter.offset = 0;
      presenter.hasMoreResults = false;
    },
  }));

  return presenter;
};
