import { clearGetParameters } from './clearGetParameters';
import { navigateTo } from './navigateTo';
import { replaceTo } from './replaceTo';

let history = null;
let location = null;

export function getHistory() {
  return history;
}

export function getLocation() {
  return location;
}

export function setHistory(ref) {
  history = ref;
}

export function setLocation(ref) {
  location = ref;
}

export default {
  navigateTo,
  replaceTo,
  clearGetParameters,
};
