import React, { FC } from 'react';

const VIDEO_MIME_TYPES = ['video/mp4', 'video/webm', 'video/ogg'];

interface IVideoPlayerSourceProps {
  url: string | null;
}

export const VideoPlayerSource: FC<IVideoPlayerSourceProps> = ({ url }) => {
  const videoSrc = url || undefined;

  return (
    <>
      {VIDEO_MIME_TYPES.map((videoType) => (
        <source
          key={videoType}
          src={videoSrc}
          type={videoType}
        />
      ))}
    </>
  );
};
