function getFirstMatch(regexp: RegExp, userAgent: string): string {
  const match = userAgent.match(regexp);
  return (match && match.length > 0 && match[1]) || '';
}

function getSecondMatch(regexp: RegExp, userAgent: string): string {
  const match = userAgent.match(regexp);
  return (match && match.length > 1 && match[2]) || '';
}

const commonVersionIdentifier = /version\/(\d+(\.?_?\d+)+)/i;

interface IBrowserInfo {
  name: string;
  version?: string;
}
// copy paste from
// https://github.com/lancedikson/bowser/blob/master/src/parser-browsers.js#L30
export const browsersList = [
  /* Opera > 13.0 */
  {
    regex: /opr\/|opios/i,
    describe(userAgent: string): IBrowserInfo {
      const browser: IBrowserInfo = {
        name: 'Opera',
      };

      const version =
        getFirstMatch(/(?:opr|opios)[\s/](\S+)/i, userAgent) ||
        getFirstMatch(commonVersionIdentifier, userAgent);

      if (version) {
        browser.version = version;
      }

      return browser;
    },
  },
  {
    regex: /yabrowser/i,
    describe(userAgent: string): IBrowserInfo {
      const browser: IBrowserInfo = {
        name: 'Yandex Browser',
      };
      const version =
        getFirstMatch(/(?:yabrowser)[\s/](\d+(\.?_?\d+)+)/i, userAgent) ||
        getFirstMatch(commonVersionIdentifier, userAgent);

      if (version) {
        browser.version = version;
      }

      return browser;
    },
  },
  {
    regex: /\sedg\//i,
    describe(userAgent: string): IBrowserInfo {
      const browser: IBrowserInfo = {
        name: 'Microsoft Edge',
      };

      const version = getFirstMatch(/\sedg\/(\d+(\.?_?\d+)+)/i, userAgent);

      if (version) {
        browser.version = version;
      }

      return browser;
    },
  },
  {
    regex: /edg([ea]|ios)/i,
    describe(userAgent: string): IBrowserInfo {
      const browser: IBrowserInfo = {
        name: 'Microsoft Edge',
      };

      const version = getSecondMatch(/edg([ea]|ios)\/(\d+(\.?_?\d+)+)/i, userAgent);

      if (version) {
        browser.version = version;
      }

      return browser;
    },
  },
  {
    regex: /vivaldi/i,
    describe(userAgent: string): IBrowserInfo {
      const browser: IBrowserInfo = {
        name: 'Vivaldi',
      };
      const version = getFirstMatch(/vivaldi\/(\d+(\.?_?\d+)+)/i, userAgent);

      if (version) {
        browser.version = version;
      }

      return browser;
    },
  },
  {
    regex: /firefox|iceweasel|fxios/i,
    describe(userAgent: string): IBrowserInfo {
      const browser: IBrowserInfo = {
        name: 'Firefox',
      };
      const version = getFirstMatch(/(?:firefox|iceweasel|fxios)[\s/](\d+(\.?_?\d+)+)/i, userAgent);

      if (version) {
        browser.version = version;
      }

      return browser;
    },
  },
  {
    regex: /electron/i,
    describe(userAgent: string): IBrowserInfo {
      const browser: IBrowserInfo = {
        name: 'Electron',
      };
      const version = getFirstMatch(/(?:electron)\/(\d+(\.?_?\d+)+)/i, userAgent);

      if (version) {
        browser.version = version;
      }

      return browser;
    },
  },
  {
    regex: /chromium/i,
    describe(userAgent: string): IBrowserInfo {
      const browser: IBrowserInfo = {
        name: 'Chromium',
      };
      const version =
        getFirstMatch(/(?:chromium)[\s/](\d+(\.?_?\d+)+)/i, userAgent) ||
        getFirstMatch(commonVersionIdentifier, userAgent);

      if (version) {
        browser.version = version;
      }

      return browser;
    },
  },
  {
    regex: /chrome|crios|crmo/i,
    describe(userAgent: string): IBrowserInfo {
      const browser: IBrowserInfo = {
        name: 'Chrome',
      };
      const version = getFirstMatch(/(?:chrome|crios|crmo)\/(\d+(\.?_?\d+)+)/i, userAgent);

      if (version) {
        browser.version = version;
      }

      return browser;
    },
  },
  {
    regex: /safari|applewebkit/i,
    describe(userAgent: string): IBrowserInfo {
      const browser: IBrowserInfo = {
        name: 'Safari',
      };
      const version = getFirstMatch(commonVersionIdentifier, userAgent);

      if (version) {
        browser.version = version;
      }

      return browser;
    },
  },
];
