import Tasks from 'APP/Tasks';
import { ALERT_TYPES } from 'APP/constants/app';
import { GroupUserRole } from 'APP/model/group/groupModel.types';
import Entities from 'STORE';

import { GroupsEvent } from '../GroupsEvent';

export class LeaveGroup extends GroupsEvent {
  process() {
    const groupId = this.eventPayload.groupId.toString();
    const group = Entities.GroupsStore.getGroupById(groupId);

    if (!group) {
      return;
    }

    const call = Entities.Calls.ongoingCall;

    if (call && call.groupId === groupId) {
      Tasks.feedback.checkStarsFeedback(call.channelId);
      call.delete();
    }

    if ((group.isChatGroup || group.isChannel) && group.isOpen) {
      group.setUserGroupRole(GroupUserRole.Guest);
      return;
    }

    let alert = { type: ALERT_TYPES.CHAT_IS_NOT_ACCESSIBLE };
    if (group.isChannel) {
      alert = { type: ALERT_TYPES.CHANNEL_IS_NOT_ACCESSIBLE };
    }

    Tasks.group.removeGroupLocally({ groupId: groupId, alert });
  }
}
