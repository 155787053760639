import React from 'react';

import { AddKeyPressHandler } from 'APP/packages/keyBoardHandler';
import { useTranslation } from 'APP/packages/translations';
import { RouterLink } from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import AdBoardIcon from 'ICONS/ic-channel-32.svg';
import GameIcon from 'ICONS/ic-games-32.svg';
import FreelanceIcon from 'ICONS/ic-service-32.svg';
import MarketIcon from 'ICONS/ic-store-32.svg';

import { HubPageType, THubPage } from '../Hub.types';
import { HubCard } from './HubCard/HubCard';
import styles from './HubMainPage.styles.m.css';

export const HubMainPage: THubPage = ({ setPage }) => {
  const { t } = useTranslation();
  const navigateTo = useNavigateTo();

  return (
    <>
      <AddKeyPressHandler onEsc={(): void => navigateTo({ to: RouterLink.main })} />
      <div className={styles.header}>
        <div className={styles.title}>{t('common_hub')}</div>
      </div>

      <div className={styles.content}>
        <HubCard
          title={t('common_market')}
          onClick={(): void => setPage(HubPageType.Market)}
          Icon={MarketIcon}
          color={'1'}
        />
        <HubCard
          title={t('common_adboard')}
          onClick={(): void => setPage(HubPageType.AdBoard)}
          Icon={AdBoardIcon}
          color={'2'}
        />
        <br />
        <HubCard
          title={t('common_freelance')}
          onClick={(): void => setPage(HubPageType.Freelance)}
          Icon={FreelanceIcon}
          color={'3'}
        />
        <HubCard
          title={t('common_games')}
          onClick={(): void => setPage(HubPageType.Games)}
          Icon={GameIcon}
          color={'4'}
        />
      </div>
    </>
  );
};
