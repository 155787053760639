import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { useTranslation } from 'APP/packages/translations';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';
import popupItemStyles from 'UIKIT/PopupItem/styles.m.scss';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { useClosePopupOnNavigation } from '../../hooks/useClosePopupOnNavigation';
import { NotificationsSettings } from './NotificationsSettings/NotificationsSettings';
import { useUserSettingsPopupPresenter } from './UserSettingsPopup.presenter';
import styles from './UserSettingsPopup.styles.m.css';
import { UserSettingsPopupItemInfo } from './UserSettingsPopupItemInfo/UserSettingsPopupItemInfo';

export const UserSettingsPopup: FC<ICommonPopupsProps> = observer((props) => {
  const { t } = useTranslation();
  const presenter = useUserSettingsPopupPresenter(props);
  useClosePopupOnNavigation(props.popupInstance);

  const clickableClasses = classNames(
    popupItemStyles.container,
    popupItemStyles.interactive,
    styles.item
  );

  return (
    <Popup
      title={t('common_settings')}
      isOpened
      isHidden={props.popupInstance.isHidden}
      handlePrevStep={presenter.hasBackButton ? presenter.onBack : null}
      onClose={presenter.onClose}
    >
      <div className={classNames(styles.container, customScrollStyles.container)}>
        <NotificationsSettings className={clickableClasses} />

        {presenter.linkMenuItems.map((item) => (
          <button
            className={clickableClasses}
            key={item.title}
            data-test={item.dataTest}
            onClick={item.onClick}
          >
            <UserSettingsPopupItemInfo
              title={item.title}
              Icon={item.Icon}
            />
            {item.info && <div className={styles.info}>{item.info}</div>}
          </button>
        ))}
      </div>
    </Popup>
  );
});
