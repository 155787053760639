import { action, computed, observable } from 'mobx';

import { RewardProgressInfoRewardEnum } from 'APP/model/userSettings/userSettingsModel.types';
import { IRewardProgressInfo } from 'APP/types/rewards';

export class Rewards {
  @observable rewardsProgress = new Map<RewardProgressInfoRewardEnum, IRewardProgressInfo>();
  @observable isRewardsLoading = true;
  @observable hasAchievedRewards: boolean;
  @observable hasNewRewards: boolean;

  @computed
  get rewardsProgressKeys(): RewardProgressInfoRewardEnum[] {
    return Array.from(this.rewardsProgress.keys());
  }

  getSomeRewardNoneRead(): boolean {
    let isNoneRead = false;

    this.rewardsProgress.forEach((value) => {
      if (!value.acknowledged) {
        isNoneRead = true;
      }
    });

    return isNoneRead;
  }

  getSomeRewardNoneAcquired(): boolean {
    let isNoneAcquired = false;

    this.rewardsProgress.forEach((value) => {
      if (!value.acquired && value.achieved) {
        isNoneAcquired = true;
      }
    });

    return isNoneAcquired;
  }

  @action setRewardsProgress(rewards: IRewardProgressInfo[]): void {
    const newRewards = new Map<RewardProgressInfoRewardEnum, IRewardProgressInfo>();

    rewards.forEach((item) => {
      newRewards.set(item.reward, item);
    });

    this.rewardsProgress = newRewards;
  }

  @action updateReward(reward: IRewardProgressInfo): void {
    this.rewardsProgress.set(reward.reward, reward);
  }

  @action setRewardsProgressLoading(val: boolean): void {
    this.isRewardsLoading = val;
  }

  @action setHasAchievedRewards(val: boolean): void {
    this.hasAchievedRewards = val;
  }

  @action setHasNewRewards(val: boolean): void {
    this.hasNewRewards = val;
  }
}
