import Tasks from 'APP/Tasks';
import { GroupType } from 'APP/model/group/groupModel.types';
import Entities from 'APP/store';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

const groupTypes = [
  GroupType.ChannelClosed,
  GroupType.ChannelOpen,
  GroupType.Closed,
  GroupType.Open,
  GroupType.Thread,
];

interface IMessageKey {
  groupId: string;
  messageId: string;
}

function getMessageKeysToUpdate(): IMessageKey[] {
  return Entities.GroupsStore.rawGroups.reduce<IMessageKey[]>((acc, group) => {
    if (groupTypes.includes(group.type) && !group.isFake) {
      group.messagesStore.inViewMessageIds.forEach((id) => {
        acc.push({ groupId: group.id, messageId: id });
      });
    }
    return acc;
  }, []);
}

function checkIsSequenceMessage(groupId: string, messagesTs: number[]): boolean {
  const group = Entities.GroupsStore.getGroupById(groupId);
  if (group && messagesTs.length) {
    const minTs = Math.min(...messagesTs);
    const maxTs = Math.max(...messagesTs);
    const messages = group.messagesStore
      .getBatchMessages(messagesTs[0])
      .filter((x: ChatMessage) => x.serverTime <= maxTs && x.serverTime >= minTs);
    return (
      messages.length === messagesTs.length &&
      messages.every((x: ChatMessage) => messagesTs.includes(x.serverTime))
    );
  }
  return false;
}

export function updateViews(): void {
  let inViewMessageKeys = getMessageKeysToUpdate();

  // for the active group, we load the message by range to exclude missed messages
  if (Entities.GroupsStore.activeGroup) {
    const group = Entities.GroupsStore.activeGroup;
    const messagesTS = group.messagesStore.inViewMessageIds.reduce<number[]>((acc, id) => {
      const message = group?.messagesStore.getMessageById(id);
      if (message) {
        acc.push(message.serverTime);
      }
      return acc;
    }, []);
    if (messagesTS.length > 1 && checkIsSequenceMessage(group.id, messagesTS)) {
      inViewMessageKeys = inViewMessageKeys.filter((x) => x.groupId !== group.id);
      const maxTS = Math.max(...messagesTS);
      if (
        maxTS === group.messagesStore.lastMessage?.serverTime &&
        group.messagesStore.isLoadedLastMessages
      ) {
        messagesTS.push(Date.now());
      }
      Tasks.messaging.updateMessagesBetweenTs(
        group.id,
        Math.min(...messagesTS),
        Math.max(...messagesTS)
      );
    }
  }

  // can get a 429 error if there are many forwards from different groups
  Entities.viewedMessages.viewedMessageByGroup.forEach((messageIds, groupId) => {
    const _messageIds = Array.from(messageIds);
    if (_messageIds.length) {
      Tasks.messaging.increaseMessagesView(groupId, _messageIds);
    }
  });

  if (inViewMessageKeys.length) {
    Tasks.messaging.updateMessages(inViewMessageKeys);
  }
}
