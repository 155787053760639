import { useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';

export default () => {
  const presenter = useLocalStore(() => ({
    CURSOR_STEP: 50,
    cursor: 0,
    isLoading: true,
    selectedTab: null,

    userReactions: [],

    updateCursor: async () => {
      presenter.isLoading = true;

      const nextCursor = presenter.cursor + presenter.CURSOR_STEP;

      const userReactions = presenter.userReactionsBySelectedTab.slice(
        presenter.cursor,
        nextCursor
      );

      const usersIds = userReactions.map((user) => user.userId);

      if (usersIds.length > 0) {
        await Tasks.relationships.loadUnknownUsersByIds({
          usersIds,
        });
      }

      presenter.cursor = nextCursor;
      presenter.isLoading = false;
    },

    onEndReached() {
      presenter.updateCursor();
    },

    get slicedUserReactions() {
      return presenter.userReactionsBySelectedTab.slice(0, presenter.cursor).filter((x) => x.user);
    },

    get userReactionsBySelectedTab() {
      return presenter.userReactions.find((reaction) => reaction.key === presenter.selectedTab)
        .list;
    },

    get hasMore() {
      return presenter.cursor < presenter.userReactionsBySelectedTab.length;
    },
  }));

  return presenter;
};
