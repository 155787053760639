import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { IconView } from 'APP/packages/UiKit/IconView/IconView';

import styles from './styles.m.scss';

const Button = ({ theme = 'base', onClick, isActive = false, Icon, isDisabled }) => {
  return (
    <button
      disabled={isDisabled}
      className={classNames(styles.button, styles[`theme-${theme}`], {
        [styles.active]: isActive,
      })}
      onClick={onClick}
    >
      <IconView
        Icon={Icon}
        className={styles.icon}
      />
    </button>
  );
};

export default observer(Button);
