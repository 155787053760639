import removeItemsByIndex from './utils/removeItemsByIndex';

export default ({ positions, conflictPositionsList }) => {
  const indexesToRemove = [];

  positions_for: for (let i = 0; i < positions.length; i++) {
    const position = positions[i];

    for (let j = 0; j < conflictPositionsList.length; j++) {
      const conflictPositions = conflictPositionsList[j];

      for (let k = 0; k < conflictPositions.length; k++) {
        const conflictPosition = conflictPositions[k];

        // loop optimization
        if (conflictPosition.end <= position.start) {
          continue;
        }

        // loop optimization
        if (position.end <= conflictPosition.start) {
          break;
        }

        if (conflictPosition.start <= position.start || position.end <= conflictPosition.end) {
          indexesToRemove.push(i);
          continue positions_for;
        }
      }
    }
  }

  return removeItemsByIndex({
    list: positions,
    indexesToRemove,
  });
};
