import Entities from 'APP/store';

export default ({ groupId }) => {
  const inputPanel = groupId
    ? Entities.InputPanelsStore.getById(groupId)
    : Entities.InputPanelsStore.activeInputPanel;

  if (inputPanel && inputPanel.quoteMessage) {
    inputPanel.setQuoteMessage(null);
  }
};
