import { useAsObservableSource, useLocalStore } from 'mobx-react';

import { openPopup } from 'APP/Tasks/app/popup/popup';
import { IScheduledEventDetails } from 'APP/model/scheduledEvent/scheduledEventModel.types';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';

import { ScheduledCallViewPopup } from '../ScheduledCallViewPopup/ScheduledCallViewPopup';
import { getScheduledEventTime } from '../ScheduledCalls.utils';

export interface IScheduledCallCreatePopupParams {
  eventDetails: IScheduledEventDetails;
  eventId: string;
}

interface IScheduledCallCreatePopupPresenter {
  timeText: string;
  onClose(): void;
  onDetailsClick(): void;
}

export function useScheduledCallCreatePopupPresenter({
  popupInstance,
  params,
}: ICommonPopupsProps<IScheduledCallCreatePopupParams>): IScheduledCallCreatePopupPresenter {
  const source = useAsObservableSource({
    eventDetails: params?.eventDetails,
    eventId: params?.eventId,
  });

  const presenter = useLocalStore<IScheduledCallCreatePopupPresenter>(() => ({
    get timeText(): string {
      if (!source.eventDetails?.startTs || !source.eventDetails?.endTs) {
        return '';
      }

      return getScheduledEventTime(source.eventDetails.startTs, source.eventDetails.endTs);
    },

    onClose(): void {
      popupInstance.close();
    },

    onDetailsClick(): void {
      if (!source.eventId || !source.eventDetails) {
        return;
      }

      presenter.onClose();

      openPopup(ScheduledCallViewPopup, {
        params: {
          eventId: source.eventId,
          initialData: {
            id: source.eventDetails.id,
            initiatorId: source.eventDetails.initiatorId,
            groupId: source.eventDetails.groupId,
            endTs: source.eventDetails.endTs,
            startTs: source.eventDetails.startTs,
            name: source.eventDetails.name,
            groupName: source.eventDetails.groupName,
            groupAvatar: source.eventDetails.groupAvatar,
          },
        },
      });
    },
  }));

  return presenter;
}
