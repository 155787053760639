import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { CategoryButton } from 'APP/components/CategoryButton/CategoryButton';
import { useTranslation } from 'APP/packages/translations';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';

import { useSelectCategoryFormPresenter } from './SelectCategoryForm.presenter';
import styles from './SelectCategoryForm.styles.m.css';

interface ISelectCategoryFormProps {
  readonly selected: Set<string>;
  toggleSelection(categoryId: string): void;
}

export const SelectCategoryForm: FC<ISelectCategoryFormProps> = observer(
  ({ selected, toggleSelection }) => {
    const { t } = useTranslation();
    const presenter = useSelectCategoryFormPresenter();

    return (
      <div className={classNames(styles.content, customScrollStyles.container)}>
        <h3 className={styles.title}>{t('channels_catalog_interested_categories')}</h3>

        <h6 className={styles.subTitle}>{t('channels_catalog_interested_categories_choose')}</h6>

        <div className={styles.list}>
          {presenter.categories.map((category) => (
            <CategoryButton
              key={category.id}
              category={category}
              className={styles.category}
              isDisabled={false}
              isSelected={selected.has(category.id)}
              onClick={() => toggleSelection(category.id)}
            />
          ))}
        </div>
      </div>
    );
  }
);
