import { v4 as uuidv4 } from 'uuid';

import { IForwardRequest, IMessage } from 'APP/model/message/messageModel.types';
import { messageModel } from 'APP/model/model';
import dateService from 'APP/packages/date';
import { ChatMessage } from 'APP/store/Messages/Message/ChatMessage/ChatMessage';
import Entities from 'STORE';
import { MessageError } from 'STORE/Messages/Message/ChatMessage/ChatMessages.types';

import { updateOldOrFakeMessages } from '../updateOldOrFakeMessages/updateOldOrFakeMessages';

export async function forwardMessages(
  messageIds: string[],
  sourceGroupId: string,
  targetGroupIds: string[]
): Promise<boolean> {
  const clientTime = dateService.now().getTime() - messageIds.length;

  const sourceGroup = Entities.GroupsStore.getGroupById(sourceGroupId);

  const me = Entities.UsersStore.Me;

  const hashMessageIds: Record<string, string> = {};
  const dateNow = Date.now();

  const messagesToForward = targetGroupIds.reduce(
    (messagesToForward: IForwardRequest['messagesToForward'], targetGroupId) => {
      const messages: IMessage[] = [];

      const targetGroup = Entities.GroupsStore.getGroupById(targetGroupId);

      const lastMessageServerTime = targetGroup?.lastMessage?.expectedServerTime || dateNow;

      messageIds
        .map((messageId) => sourceGroup?.messagesStore.getMessageById(messageId))
        .filter((message: ChatMessage | null) => message?.id)
        .sort((a: ChatMessage, b: ChatMessage) => a.expectedServerTime - b.expectedServerTime)
        .forEach((message: ChatMessage, index) => {
          const clientUuid = uuidv4();

          hashMessageIds[targetGroupId + message.id] = clientUuid;

          const sender = Entities.UsersStore.getUserById(message?.senderId);

          if (message && sender && sourceGroup) {
            messages.push({
              clientUuid: clientUuid,
              forwardedMessage: message.forward
                ? {
                    ...message.forward.toJSON(),
                    messageId: message.id,
                    clientGroupSourceId: sourceGroupId,
                  }
                : {
                    from: sourceGroup.isChannel ? sourceGroup.name : sender.displayName,
                    groupId: sourceGroup.isChannel ? sourceGroup.id : undefined,
                    messageId: message.id,
                    senderId: message.senderId,
                    clientGroupSourceId: sourceGroupId,
                  },
              payload: message.payload.toJSON(),
              id: clientUuid,
              groupId: targetGroupId,
              senderId: me.id,
              clientTime: (dateNow + index).toString(),
              serverTime: (dateNow + index).toString(),
              editTime: '',
              usersReactions: {
                usersReactions: {},
              },
              links: [],
              views: '0',
            });
          }

          messagesToForward.push({
            clientTs: clientTime + index,
            clientUuid: clientUuid,
            sourceMessageId: message.id,
            targetGroupId,
          });
        });

      updateOldOrFakeMessages({
        messages,
        groupId: targetGroupId,
        prevMessageTs: lastMessageServerTime,
      });

      return messagesToForward;
    },
    []
  );

  if (!messagesToForward.length) {
    return false;
  }

  //set of clientUuid + groupId
  const createdMessagesUUID = new Set<string>();
  let result = true;
  try {
    const { forwardedMessages } = await messageModel.forwardMessage({
      sourceGroupId,
      messagesToForward,
    });

    forwardedMessages.forEach(({ groupId, messages }) => {
      messages.forEach((message) => createdMessagesUUID.add(message.clientUuid + groupId));
    });
  } catch {
    result = false;
  }

  targetGroupIds.forEach((targetGroupId) => {
    const targetGroup = Entities.GroupsStore.getGroupById(targetGroupId);

    messageIds.forEach((messageId) => {
      const clientUuid = hashMessageIds[targetGroupId + messageId];
      if (!createdMessagesUUID.has(clientUuid + targetGroupId)) {
        const message = targetGroup?.messagesStore.getMessageById(clientUuid);
        message?.isFake ? message?.setError(MessageError.Forward) : message?.resetError();
      }
    });
  });

  return result;
}
