import { useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import Entities from 'APP/store';

export default () => {
  const presenter = useLocalStore(() => ({
    async onClick() {
      const group = Entities.GroupsStore.activeGroup;
      if (group && group.canBeScheduled) {
        await Tasks.group.setActiveGroup({ groupId: group.scheduleGroupId });
      }
    },
  }));

  return presenter;
};
