import Tasks from 'APP/Tasks';
import api from 'APP/packages/api';
import logger from 'APP/packages/logger';
import { langService } from 'MODULE/i18n/service';
import Entities from 'STORE';

const terminateAllOtherSessions = async () => {
  try {
    const confirmation = await Tasks.app.showConfirm({
      text: langService.i18n.lang.common_terminate_all_sessions_alert,
      primaryText: langService.i18n.lang.common_yes,
      secondaryText: langService.i18n.lang.common_cancel,
    });

    if (confirmation) {
      await api.authorization.terminateAllOtherSessions();
      Entities.sessionsStore.clearSessions();
    }
  } catch (error) {
    logger.get('API').error('userSettings.terminateAllOtherSessions', error);
  }
};

export default terminateAllOtherSessions;
