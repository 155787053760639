import { useLocalStore, useAsObservableSource } from 'mobx-react';

import { GroupType } from 'APP/model/group/groupModel.types';
import { UserWithDisplayedName } from 'APP/packages/api/types/model/userWithDisplayedName';
import Entities from 'STORE';
import { Group } from 'STORE/Groups/Group';
import System from 'STORE/Messages/Message/Payload/System';
import { User } from 'STORE/Users/User/User';
import { encodeHtml } from 'UTILS/html/encodeHtml';

import { ISystemMessageTextPresenter } from './useSystemMessageText.types';

export const useSystemMessageTextPresenter = (
  group: Group,
  payload: System
): ISystemMessageTextPresenter => {
  const source = useAsObservableSource({ group, payload });

  const presenter = useLocalStore<ISystemMessageTextPresenter>(() => ({
    get group(): Group {
      return source.group;
    },

    get payload(): System {
      return source.payload;
    },

    get isPublicGroup(): boolean {
      return presenter.group.isChatGroup && presenter.group.isOpen;
    },

    get isChannel(): boolean {
      return presenter.group.isChannel;
    },

    get groupName(): string {
      if (!presenter.payload) {
        return '';
      }

      return encodeHtml(presenter.payload.groupName);
    },

    get initiator(): User | null {
      if (!presenter.payload) {
        return null;
      }

      return Entities.UsersStore.getUserById(presenter.payload.initiator);
    },

    get isInitiatorMe(): boolean {
      return Entities.UsersStore.Me.id === presenter.initiator?.id;
    },

    get users(): UserWithDisplayedName[] {
      if (!presenter.payload || !presenter.payload.users) {
        return [];
      }

      return presenter.payload.users;
    },

    get hasUsers(): boolean {
      return presenter.users.length > 0;
    },

    get initiatorDisplayName(): string {
      if (presenter.initiator) {
        return encodeHtml(presenter.initiator.displayName);
      }

      return encodeHtml(presenter.payload?.initiatorNickName || '');
    },

    get oldName(): string {
      if (!presenter.payload) {
        return '';
      }

      return encodeHtml(presenter.payload.oldName);
    },

    get newName(): string {
      if (!presenter.payload) {
        return '';
      }

      return encodeHtml(presenter.payload.newName);
    },

    get newType(): GroupType | null {
      if (!presenter.payload) {
        return null;
      }

      return presenter.payload.newType;
    },

    get firstUserDisplayedName(): string {
      if (!presenter.hasUsers) {
        return '';
      }
      const user = Entities.UsersStore.getUserById(presenter.users[0].id);
      return encodeHtml(user?.displayName || presenter.users[0].displayedName);
    },

    get firstUserId(): string | null {
      if (!presenter.hasUsers) {
        return null;
      }
      return presenter.users[0].id;
    },

    get contactName(): string {
      const contactId = presenter?.payload?.contactId;
      if (contactId) {
        return encodeHtml(Entities.UsersStore.getUserById(contactId)?.displayName || '');
      }
      return '';
    },

    get spaceName(): string {
      return presenter.payload ? encodeHtml(presenter.payload.spaceName) : '';
    },
  }));

  return presenter;
};
