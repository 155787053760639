import { observer } from 'mobx-react';
import React from 'react';

import usePresenter from './presenter';

const BugsnagWrapper = ({ children }) => {
  const presenter = usePresenter();

  if (!presenter.isBugsnagEnabled) {
    return children;
  }

  const ErrorBoundary = presenter.bugsnagClient.getPlugin('react');

  return <ErrorBoundary>{children}</ErrorBoundary>;
};

export default observer(BugsnagWrapper);
