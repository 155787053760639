import Tasks from 'APP/Tasks';
import { ElectronApi } from 'APP/packages/electron/ElectronApi';
import Entities from 'STORE';

interface INotificationsSettingsPresenter {
  isEnabled: boolean;
  settingsLink: string | null;
  onClick(): void;
}

const WINDOWS_SETTINGS_LINK = 'ms-settings:notifications';
const MAC_SETTINGS_LINK = 'x-apple.systempreferences:com.apple.preference.notifications';

export function useNotificationsSettingsPresenter(): INotificationsSettingsPresenter {
  let settingsLink: string | null = null;

  if (ElectronApi.isEnabled) {
    if (ElectronApi.isMac) {
      settingsLink = MAC_SETTINGS_LINK;
    } else if (ElectronApi.isWindows) {
      settingsLink = WINDOWS_SETTINGS_LINK;
    }
  }

  return {
    isEnabled: Entities.pushNotifications.isEnabled,
    settingsLink: settingsLink,
    onClick: Tasks.pushNotifications.toggleUserNotifications,
  };
}
