import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { useTranslation } from 'APP/packages/translations';
import HomeIcon from 'ICONS/ic-home.svg';
import MarketSpaceIcon from 'ICONS/ic-marketspace-fill.svg';
import { Space } from 'STORE/Spaces/Space';
import { SidebarMenuItem } from 'UIKIT/SidebarMenuItem/SidebarMenuItem';

import { useSpacePanelPresenter } from './SpacePanel.presenter';
import styles from './SpacePanel.styles.m.css';
import { SpacePanelActionsButton } from './SpacePanelActionsButton/SpacePanelActionsButton';
import { SpacePanelGroups } from './SpacePanelGroups/SpacePanelGroups';
import { SpacePanelHeader } from './SpacePanelHeader/SpacePanelHeader';

interface ISpacePanelProps {
  space: Space | null;
}

export const SpacePanel: FC<ISpacePanelProps> = observer(({ space }) => {
  const presenter = useSpacePanelPresenter(space);
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <SpacePanelHeader space={space} />

      <SidebarMenuItem
        title={t('space_main')}
        link={presenter.spaceRoute}
        exact={true}
        Icon={HomeIcon}
      />

      {presenter.isShowMarketsRoute && (
        <SidebarMenuItem
          title={t('space_market_tab')}
          link={presenter.marketsRoute}
          exact={true}
          Icon={MarketSpaceIcon}
        />
      )}

      {presenter.isShowPrimaryMarketRoute && (
        <SidebarMenuItem
          title={t('space_market_tab')}
          link={presenter.marketRoute}
          exact={true}
          Icon={MarketSpaceIcon}
        />
      )}

      <div className={styles.divider} />

      {space && <SpacePanelGroups space={space} />}

      {space && <SpacePanelActionsButton space={space} />}
    </div>
  );
});
