import { UpdateReason } from 'APP/constants/scroll';
import Entities from 'APP/store';

export default ({ groupId, messageId, isAnimated = true }) => {
  const group = Entities.GroupsStore.getGroupById(groupId);
  if (!group) {
    return;
  }

  group.messagesStore.focusMessage(messageId);

  if (group.isActive) {
    Entities.ChatStore.setScrollChanged(UpdateReason.FocusMessage, {
      messageId: messageId,
      isAnimated,
    });
  }
};
