import { observer } from 'mobx-react';
import React from 'react';

import { CallState } from 'MAIN/OngoingCall/Shared/CallState/CallState';

import { CompactCallViewHeader } from '../../Shared/CompactCallViewHeader/CompactCallViewHeader';
import { useGroupVideoCallHeaderPresenter } from './GroupVideoCallHeader.presenter';
import styles from './GroupVideoCallHeader.styles.m.css';

export const GroupVideoCallHeader = observer(() => {
  const presenter = useGroupVideoCallHeaderPresenter();

  return (
    <CompactCallViewHeader>
      <div className={styles.info}>
        <div className={styles.groupTitle}>{presenter.call.avatar.title}</div>
        <div className={styles.callState}>
          <CallState call={presenter.call} />
        </div>
      </div>
    </CompactCallViewHeader>
  );
});
