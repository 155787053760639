import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import { PARTICIPANT_ROLE } from 'APP/constants/stream';
import logger from 'APP/packages/logger';
import { initStreamListener } from 'APP/packages/streamApi';
import { useTranslation } from 'APP/packages/translations';
import Entities from 'APP/store';

export default ({ rootPresenter, streamerPresenter }) => {
  const { t } = useTranslation();
  const presenter = useLocalStore(() => ({
    disposeListener: null,

    get stream() {
      return Entities.ActiveStream.stream;
    },

    onUserJoined: (data) => {
      presenter.stream.updateStream({
        viewersLiveCount: data.liveViewersCount,
      });
    },

    onUserLeft: (data) => {
      presenter.stream.updateStream({
        viewersLiveCount: data.liveViewersCount,
      });
    },

    onNewBroadcaster: async (data) => {
      logger.get('ParticipantView.streamStateHandler').debug('onNewBroadcaster', data);
      const streamers = [presenter._mapStreamer(data)];

      presenter.stream.mergeViewers(streamers);

      if (data.userId === Entities.UsersStore.Me.id) {
        Entities.toast.show(t('call_streaming_rights_hint'));
        presenter.stream.updateStream({
          initiatorId: data.initiatorId,
          token: data.token,
          shareScreenUid: data.shareScreenUid,
          shareScreenToken: data.shareScreenToken,
        });

        await rootPresenter.makeHost();
      }
    },

    onBroadcasters: async (data) => {
      logger.get('ParticipantView.streamStateHandler').debug('onBroadcasters', data);
      const streamers = data.map(presenter._mapStreamer);

      presenter.stream.mergeViewers(streamers);

      const streamer = streamers.find((streamer) => streamer.userId === Entities.UsersStore.Me.id);

      if (streamer) {
        await rootPresenter.makeHost();
      }
    },

    _mapStreamer(data) {
      return {
        uid: data.uid,
        initiatorId: data.initiatorId || presenter.stream.initiatorId,
        userId: data.userId,
        username: data.username,
        avatarUrl: data.avatarUrl,
        shareScreenUid: data.shareScreenUid,
        platform: data.platform,
        role: PARTICIPANT_ROLE.STREAMER,
      };
    },

    onUserForceMute: (data) => {
      if (data.userId === Entities.UsersStore.Me.id) {
        streamerPresenter.muteAudio();
      }
    },

    connect() {
      if (presenter.stream?.channelId && !presenter.disposeListener) {
        presenter.disposeListener = initStreamListener({
          channelId: presenter.stream.channelId,
          onUserLeftAnonymously: presenter.onUserLeft,
          onUserJoinedAnonymously: presenter.onUserJoined,
          onError: presenter.onError,
          onNewBroadcaster: presenter.onNewBroadcaster,
          onBroadcasters: presenter.onBroadcasters,
          onUserForceMute: presenter.onUserForceMute,
        });
      }
    },

    disconnect() {
      if (presenter.disposeListener) {
        presenter.disposeListener();
        presenter.disposeListener = null;
      }
    },

    async onError(err) {
      try {
        presenter.disconnect();
        // Check for error code in sse. I did not find another possibility in the specification
        // https://html.spec.whatwg.org/multipage/server-sent-events.html#the-eventsource-interface
        const controller = new AbortController();
        const { signal } = controller;
        const { status } = await fetch(err.target.url, { signal });
        controller.abort();
        if (status === 403) {
          await rootPresenter.finish();
        } else {
          setTimeout(() => {
            presenter.connect();
          }, 3000);
        }
      } catch (e) {
        console.error(e);
      }
    },
  }));

  useEffect(() => {
    presenter.connect();
    return () => {
      presenter.disconnect();
    };
  }, [presenter.stream?.channelId]);

  useEffect(() => {
    if (Entities.AuthStore.streamToken) {
      presenter.connect();
    }
    if (!Entities.AuthStore.streamToken) {
      presenter.disconnect();
    }
  }, [Entities.AuthStore.streamToken]);
};
