import { observer } from 'mobx-react';
import React from 'react';

import { MediaPreview } from 'APP/components/MediaPreview/MediaPreview';
import { MEDIA_PREVIEW_THUMBNAIL_SIZE } from 'APP/constants/sharedData';
import DocumentIcon from 'APP/icons/ic-document_stroke.svg';

import { useArticleItemPresenter } from './ArticleItem.presenter';
import styles from './ArticleItem.styles.m.css';

export const ArticleItem = observer(({ id, payload, messageId, groupId }) => {
  const presenter = useArticleItemPresenter({ payload, messageId });

  return (
    <div className={styles.container}>
      {presenter.article.media ? (
        <div className={styles.previewImage}>
          <MediaPreview
            className={styles.image}
            payload={presenter.article.media.payload}
            maxHeight={MEDIA_PREVIEW_THUMBNAIL_SIZE}
            maxWidth={MEDIA_PREVIEW_THUMBNAIL_SIZE}
            needCropImage={true}
            payloadId={id}
            groupId={groupId}
            messageId={messageId}
            onClick={presenter.openArticle}
          />
        </div>
      ) : (
        <button
          className={styles.previewImage}
          onClick={presenter.openArticle}
        >
          <DocumentIcon className={styles.icon} />
        </button>
      )}
      <div className={styles.info}>
        <button
          className={styles.title}
          onClick={presenter.openArticle}
        >
          {presenter.article.title}
        </button>
        {presenter.article.description && (
          <p className={styles.description}>{presenter.article.description}</p>
        )}
      </div>
    </div>
  );
});
