import Tasks from 'APP/Tasks';
import { broadcastMessagesChanges } from 'APP/packages/bus/adapters/messaging/changes';

import { GroupsEvent } from '../GroupsEvent';

export class ClearHistoryForAll extends GroupsEvent {
  process() {
    const groupId = this.groupId;

    if (!groupId) {
      return;
    }

    Tasks.messaging.clearHistoryLocally({
      groupId,
      timestamp: this.ts,
    });

    broadcastMessagesChanges(groupId, { eventType: this.type });
  }
}
