import React, { memo } from 'react';

import { PopupManager } from 'MAIN/PopupManager/PopupManager';

import { DetailedFeedback } from './DetailedFeedback/DetailedFeedback';
import { GetParametersManager } from './GetParametersManager/GetParametersManager';
import RequestPermission from './RequestPermission';
import { StarsFeedback } from './StarsFeedback/StarsFeedback';
import { Toast } from './Toast/Toast';

const Popups = () => {
  return (
    <>
      <GetParametersManager />
      <PopupManager />
      <RequestPermission />
      <Toast />
      <StarsFeedback />
      <DetailedFeedback />
    </>
  );
};

export default memo(Popups);
