import React from 'react';
import { NavLink } from 'react-router-dom';

import { IRenderer } from '../renderTree.types';
import { useHiddenLinkRendererPresenter } from './HiddenLinkRenderer.presenter';

interface IHiddenLinkOptions {
  link: string;
}

export const HiddenLinkRenderer: IRenderer<IHiddenLinkOptions> = (props) => {
  const { children, options } = props;

  const presenter = useHiddenLinkRendererPresenter(options.link);

  if (presenter.href.includes(window.location.hostname) && presenter.isAppPath) {
    return <NavLink to={presenter.pathname}>{children}</NavLink>;
  }

  return (
    <a
      href={presenter.href}
      target="_blank"
      rel="noreferrer"
      onClick={!presenter.isWhitelistUrl ? presenter.onClickHandler : undefined}
    >
      {children}
    </a>
  );
};
