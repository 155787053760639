import React, { FC } from 'react';

import {
  GROUPS_PLACEHOLDER_ITEM_SIZE,
  GroupsPlaceholder,
  IGroupsPlaceholderProps,
} from '../GroupsPlaceholder/GroupsPlaceholder';

type ISpacesPlaceholderProps = IGroupsPlaceholderProps;

export const SPACES_PLACEHOLDER_ITEM_SIZE = GROUPS_PLACEHOLDER_ITEM_SIZE;

export const SpacesPlaceholder: FC<ISpacesPlaceholderProps> = (props) => {
  return <GroupsPlaceholder {...props} />;
};
