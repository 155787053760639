import { useLocalStore } from 'mobx-react';

import { IShareSelection } from 'APP/types/share';

export interface IButtonsShareCardPopupPresenterProps {
  onSubmit(selection: IShareSelection[]): Promise<void>;
}

export interface IButtonsShareCardPopupPresenter {
  selection: IShareSelection[];
  isSubmitting: boolean;
  hasSelected: boolean;
  onSelect(selection: IShareSelection[]): void;
  submit(): void;
}

export const useButtonsShareCardPresenter = ({
  onSubmit,
}: IButtonsShareCardPopupPresenterProps) => {
  const presenter = useLocalStore<IButtonsShareCardPopupPresenter>(() => ({
    isSubmitting: false,
    selection: [],

    get hasSelected(): boolean {
      return presenter.selection.length > 0;
    },

    async onSelect(data) {
      presenter.selection = data;
    },

    async submit() {
      presenter.isSubmitting = true;
      await onSubmit(presenter.selection);
      presenter.isSubmitting = false;
    },
  }));

  return presenter;
};
