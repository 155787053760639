import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { AvatarWithFullScreen } from 'APP/components/Avatar/AvatarWithFullScreen/AvatarWithFullScreen';
import { IUserProfileInitiatorSource } from 'MAIN/Profile/UserProfile/UserProfile.presenter';
import { Avatar } from 'UIKIT/Avatar/Avatar';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';

import { useUserAvatarPresenter } from './UserAvatar.presenter';

interface IUserAvatarProps {
  className: string;
  id?: string;
  type?: AvatarTypes;
  withFullScreen?: boolean;
  withNavigate?: boolean;
  initiatorSource?: IUserProfileInitiatorSource;
  dataTest?: string;
}

export const UserAvatar: FC<IUserAvatarProps> = observer(
  ({
    className,
    id,
    type = AvatarTypes.Round_0,
    withFullScreen,
    withNavigate,
    initiatorSource,
    dataTest,
  }) => {
    const presenter = useUserAvatarPresenter(id, initiatorSource);

    const avatar = (
      <Avatar
        className={!(withFullScreen || withNavigate) ? className : undefined}
        type={type}
        url={presenter.avatarUrl}
        title={presenter.avatarTitle}
        backgroundColorIndex={presenter.avatarColorIndex}
      />
    );

    if (withNavigate) {
      return (
        <button
          className={className}
          data-test={dataTest}
          onClick={presenter.openUserProfile}
        >
          {avatar}
        </button>
      );
    }

    if (withFullScreen) {
      return (
        <AvatarWithFullScreen
          className={className}
          url={presenter.avatarUrl}
          dataTest={dataTest}
        >
          {avatar}
        </AvatarWithFullScreen>
      );
    }

    return avatar;
  }
);
