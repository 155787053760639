import Tasks from 'APP/Tasks';
import { PayloadType } from 'APP/model/message/messageModel.types';
import Entities from 'APP/store';

export default (data) => {
  try {
    Tasks.messaging.createNewMessages({
      messages: [
        {
          type: PayloadType.Article,
          clientUuid: data.clientUuid,
          data: data.payload,
          groupId: data.groupId,
          quotedMessage: Entities.InputPanelsStore.activeInputPanel.quoteMessage,
        },
      ],
    });

    const group = Entities.GroupsStore.getGroupById(data.groupId);

    if (!group) {
      return;
    }

    group.draftsStore.removeDraftById(data.id);
  } catch (error) {
    console.error(error);
    return [];
  }
};
