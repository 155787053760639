import { botCatalogModel } from 'APP/model/model';
import { IBotUserDisplayData } from 'APP/model/user/userModel.types';

export async function addBotToProfile(botId: string): Promise<IBotUserDisplayData | null> {
  try {
    return await botCatalogModel.addBotToProfile(botId);
  } catch {
    return null;
  }
}
