import { useLocalStore } from 'mobx-react';

import Entities from 'STORE';
import { Group } from 'STORE/Groups/Group';
import { Schedule } from 'STORE/Groups/Schedule';
import { Thread } from 'STORE/Groups/Thread';

import { friendsSpaceImagesEmptyConfig } from './FriendsSpaceMessagesEmpty/FriendsSpaceMessagesEmpty.config';

interface IMessagesEmptyPresenter {
  group: Group | null;
  isShowSavedMessagesEmpty: boolean;
  isShowScheduleEmpty: boolean;
  isShowThreadEmpty: boolean;
  isShowFriendsSpaceEmpty: boolean;
  hasBackground: boolean;
}

export const useMessagesEmptyPresenter = (): IMessagesEmptyPresenter => {
  const presenter = useLocalStore<IMessagesEmptyPresenter>(() => ({
    get group(): Group | null {
      return Entities.GroupsStore.activeGroup;
    },

    get isShowSavedMessagesEmpty(): boolean {
      const group = presenter.group;

      return (
        !!group &&
        group.isSavedMessages &&
        group.messagesStore.messages.length === 0 &&
        !group.messagesStore.isLoading
      );
    },

    get isShowScheduleEmpty(): boolean {
      const group = presenter.group;

      return !!group && group instanceof Schedule && group.messagesStore.messages.length === 0;
    },

    get isShowThreadEmpty(): boolean {
      const group = presenter.group;

      return (
        !!group &&
        group instanceof Thread &&
        group.messagesStore.messages.length === 0 &&
        !group.messagesStore.isLoading
      );
    },

    get isShowFriendsSpaceEmpty(): boolean {
      const group = presenter.group;

      return (
        !!group?.spaceId &&
        !group.messagesStore.isLoading &&
        !group.messagesStore.messages.length &&
        !!friendsSpaceImagesEmptyConfig[group.avatarUrl || '']
      );
    },

    get hasBackground(): boolean {
      return Entities.appearance.hasChatBackground;
    },
  }));

  return presenter;
};
