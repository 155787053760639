import { RewardProgressInfoRewardEnum } from 'APP/model/userSettings/userSettingsModel.types';

import { localStorageInstance } from '../instances/localStorageInstance';

export const IS_SHOW_REWARD_ACHIEVED_ANIMATION = 'isShowRewardAchievedAnimation';

export const DONT_SHOW_BANNERS = 'DONT_SHOW_BANNERS';

export enum ShowedRewardBannerTypes {
  newMission = 'newMission',
  newReward = 'newReward',
}

export const setRewardAnimationShown = (value: boolean): void => {
  localStorageInstance.setItem(IS_SHOW_REWARD_ACHIEVED_ANIMATION, JSON.stringify(value));
};

export const getRewardAnimationShown = (): boolean => {
  const value = localStorageInstance.getItem(IS_SHOW_REWARD_ACHIEVED_ANIMATION);

  try {
    return value ? JSON.parse(value) : false;
  } catch {
    return false;
  }
};

export const getShowedRewardBanners = (
  bannerType: ShowedRewardBannerTypes
): RewardProgressInfoRewardEnum[] => {
  const valueObjString = localStorageInstance.getItem(DONT_SHOW_BANNERS);

  if (!valueObjString) {
    return [];
  }

  try {
    const valueObj = JSON.parse(valueObjString);

    if (typeof valueObj !== 'object') {
      return [];
    }

    const bannerTypeObject = valueObj[bannerType];

    if (!bannerTypeObject) {
      return [];
    }

    return Object.keys(bannerTypeObject).reduce<RewardProgressInfoRewardEnum[]>(
      (acc, value: RewardProgressInfoRewardEnum) => {
        if (bannerTypeObject[value]) {
          acc.push(value);
        }
        return acc;
      },
      []
    );
  } catch {
    return [];
  }
};

export const setDontShowRewardBanner = (
  bannerType: ShowedRewardBannerTypes,
  rewardType: RewardProgressInfoRewardEnum
): void => {
  const valueObjString = localStorageInstance.getItem(DONT_SHOW_BANNERS);
  const defaultObject = {
    [bannerType]: {
      [rewardType]: true,
    },
  };

  if (!valueObjString) {
    localStorageInstance.setItem(DONT_SHOW_BANNERS, JSON.stringify(defaultObject));

    return;
  }

  try {
    const valueObj = JSON.parse(valueObjString);

    if (typeof valueObj !== 'object') {
      localStorageInstance.setItem(DONT_SHOW_BANNERS, JSON.stringify(defaultObject));
    } else {
      valueObj[bannerType][rewardType] = true;
      localStorageInstance.setItem(DONT_SHOW_BANNERS, JSON.stringify(valueObj));
    }
  } catch {
    localStorageInstance.setItem(DONT_SHOW_BANNERS, JSON.stringify(defaultObject));
  }
};

export const clearDontShowRewardBanner = (): void => {
  localStorageInstance.setItem(DONT_SHOW_BANNERS, '');
};
