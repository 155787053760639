import { useEffect, useState } from 'react';

import secondsToString from 'APP/packages/date/helpers/secondsToString';

function getTime(startTs: number): string {
  return secondsToString((Date.now() - startTs) / 1000);
}

const DEFAULT_TIME = '00:00';
const TIME_TIMEOUT = 1000;

export function useJoinCallButtonPresenter(startTs?: number) {
  const [time, setTime] = useState(DEFAULT_TIME);

  useEffect(() => {
    let timer: number | null = null;

    if (startTs) {
      setTime(getTime(startTs));

      timer = setInterval(() => {
        setTime(getTime(startTs));
      }, TIME_TIMEOUT) as unknown as number;
    } else {
      setTime(DEFAULT_TIME);
    }

    return () => {
      timer && clearInterval(timer);
    };
  }, [startTs]);

  return { time };
}
