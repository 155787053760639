import Tasks from 'APP/Tasks';
import { useTranslation } from 'APP/packages/translations';
import { Group } from 'STORE/Groups/Group';

interface IChannelAvailableItemPresenter {
  onJoin(): Promise<void>;
  onClick(): Promise<void>;
}

export const useChannelAvailableItemPresenter = (group: Group): IChannelAvailableItemPresenter => {
  const { t } = useTranslation();

  const presenter: IChannelAvailableItemPresenter = {
    async onJoin(): Promise<void> {
      const joinResult = await Tasks.group.joinGroup({ groupId: group.id });
      if (!joinResult) {
        Tasks.app.showToast(t('common_somethings_wrong_error'));
      }
    },

    async onClick(): Promise<void> {
      return await Tasks.group.setActiveGroup({ groupId: group.id });
    },
  };

  return presenter;
};
