import Tasks from 'APP/Tasks';
import { IGroupCounterState, IGroupCounterView } from 'APP/model/counter/counterModel.types';
import { counterModel } from 'APP/model/model';
import Entities from 'STORE';

export async function handleBatchedGroupUpdates(updates: IGroupCounterState[] = []): Promise<void> {
  const groupIds = updates
    .filter(({ groupId, updatedTs }) => {
      const counter = Entities.Counters.getCounterById(groupId);
      return !counter || counter.counterTs < Number(updatedTs);
    })
    .map(({ groupId }) => groupId);

  if (groupIds.length && !Entities.GroupsStore.isLoading) {
    try {
      const counters = await counterModel.getGroupsCounters(groupIds);
      await Tasks.group.addCounters(counters);
    } catch (e) {
      //
    }
  }
}

export async function handleBatchedCounters(counters: IGroupCounterView[]): Promise<void> {
  Tasks.group.addCounters(counters);
}

export async function handleChangeCounter(counter: IGroupCounterView): Promise<void> {
  Tasks.group.addCounters([counter]);
}
