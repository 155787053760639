import { observable } from 'mobx';

import { PayloadType } from 'APP/model/message/messageModel.types';
import { Payload } from 'STORE/Messages/Message/Payload';

class Text extends Payload {
  @observable text;
  userMentions;

  constructor(data) {
    super(data);

    this.text = data.text;
    this.userMentions = data.userMentions;
    this.keyWord = data.keyWord;
    this.keyWords = data.keyWord ? [data.keyWord] : [];

    this.linkPreview = data.linkPreview;
    this.linkPreviewCancelled = data.linkPreviewCancelled;
  }

  toJSON() {
    return {
      payloadType: PayloadType.RichText,
      text: this.text,
      userMentions: this.userMentions,
      keyWord: this.keyWord || null,
      linkPreview: this.linkPreview || null,
      linkPreviewCancelled: this.linkPreviewCancelled || null,
    };
  }
}

export default Text;
