import classNames from 'classnames';
import React, { FC } from 'react';

import { IconView } from 'UIKIT/IconView/IconView';
import { Loader } from 'UIKIT/Loader/Loader';

import styles from './Button.styles.m.css';
import { buttonIconSizeMap, ButtonTypes, IButton } from './Button.types';

export { ButtonTypes };

interface IButtonProps extends IButton<HTMLButtonElement> {
  children: React.ReactNode;
  isDisabled?: boolean;
  form?: string;
  type?: ButtonTypes;
}

export const Button: FC<IButtonProps> = (props) => {
  const {
    Icon,
    form,
    setRef,
    onClick,
    onContextMenu,
    tabIndex,
    dataTest,
    children,
    className,
    theme = '1',
    rounding = '0',
    isLoading = false,
    isDisabled = false,
    size = '10',
    type = ButtonTypes.Button,
  } = props;

  const containerClasses = classNames(
    styles.container,
    styles[`size-${size}`],
    styles[`rounding-${rounding}`],
    styles[`theme-${theme}`],
    className
  );

  return (
    <button
      type={type}
      form={form}
      ref={setRef}
      className={containerClasses}
      onClick={onClick}
      onContextMenu={onContextMenu}
      disabled={isDisabled || isLoading}
      tabIndex={tabIndex}
      data-test={dataTest}
    >
      <span className={classNames(styles.content, { [styles.hidden]: isLoading })}>
        {Icon && (
          <IconView
            Icon={Icon}
            size={buttonIconSizeMap[size]}
            className={styles.icon}
          />
        )}
        <div className={styles.text}>{children}</div>
      </span>
      {isLoading && <Loader className={styles.loader} />}
    </button>
  );
};
