import { useAsObservableSource, useLocalStore } from 'mobx-react';

import { openPopup } from 'APP/Tasks/app/popup/popup';
import { useTranslation } from 'APP/packages/translations';
import ChannelIcon from 'ICONS/ic-channel.svg';
import PlusIcon from 'ICONS/ic-plus.svg';
import UsersIcon from 'ICONS/ic-users.svg';
import { CreateSpaceChannelPopup } from 'MAIN/PopupManager/Popups/CreateSpaceChannelPopup/CreateSpaceChannelPopup';
import { CreateSpaceGroupPopup } from 'MAIN/PopupManager/Popups/CreateSpaceGroupPopup/CreateSpaceGroupPopup';
import { MoveGroupsToSpacePopup } from 'MAIN/PopupManager/Popups/MoveGroupsToSpacePopup/MoveGroupsToSpacePopup';
import { Space } from 'STORE/Spaces/Space';
import { IPopoverMenuItem } from 'UIKIT/PopoverMenu/PopoverMenu.types';

interface ISpacePanelActionsButtonPresenter {
  menuItems: IPopoverMenuItem[];
  canCreateAndMoveGroups: boolean;
  moveGroups(): void;
  newGroupChat(): void;
  newChannel(): void;
}

export function useSpacePanelActionsButtonPresenter(
  space: Space
): ISpacePanelActionsButtonPresenter {
  const { t } = useTranslation();
  const source = useAsObservableSource({ space });

  const presenter = useLocalStore<ISpacePanelActionsButtonPresenter>(() => ({
    get menuItems(): IPopoverMenuItem[] {
      return [
        {
          key: 'move-groups',
          title: t('space_add_group_channel_button'),
          icon: PlusIcon,
          isVisible: presenter.canCreateAndMoveGroups,
          onClick: presenter.moveGroups,
        },
        {
          key: 'new-group-chat',
          title: t('new_group_chat'),
          icon: UsersIcon,
          isVisible: presenter.canCreateAndMoveGroups,
          onClick: presenter.newGroupChat,
        },
        {
          key: 'new-channel',
          title: t('new_channel'),
          icon: ChannelIcon,
          isVisible: presenter.canCreateAndMoveGroups,
          onClick: presenter.newChannel,
        },
      ].filter((item) => item.isVisible);
    },

    get canCreateAndMoveGroups(): boolean {
      return source.space.isOwner || source.space.isAdmin;
    },

    moveGroups(): void {
      openPopup(MoveGroupsToSpacePopup, {
        params: { spaceId: source.space.id },
      });
    },

    newGroupChat(): void {
      openPopup(CreateSpaceGroupPopup, {
        params: { spaceId: source.space.id },
      });
    },

    newChannel(): void {
      openPopup(CreateSpaceChannelPopup, {
        params: { spaceId: source.space.id },
      });
    },
  }));

  return presenter;
}
