import Tasks from 'APP/Tasks';
import uploader from 'APP/packages/file-upload/uploader';
import { t } from 'MODULE/i18n/service';
import Entities from 'STORE';

interface ICreateStreamData {
  groupId: string;
  preview: File | null;
  title: string | null;
}

export async function createStream(data: ICreateStreamData): Promise<boolean> {
  let previewImageUrl: string | null = null;
  if (data.preview) {
    try {
      const { url } = await uploader.getUploadUrl();
      if (!url) {
        throw new Error('uploadUrl not received');
      }
      const response = await uploader.uploadFile(url, data.preview, data.preview.name);
      previewImageUrl = response[0].url;
    } catch {
      const canUpload = await Tasks.app.showConfirm({
        title: t('uploading_thumbnail_error_title'),
        text: t('uploading_thumbnail_error'),
        primaryText: t('next_button'),
      });

      if (!canUpload) {
        return false;
      }
    }
  }

  Entities.ActiveStream.createStream({
    groupId: data.groupId,
    title: data.title,
    previewImageUrl,
  });

  return true;
}
