import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { MessageViewPageType } from 'MAIN/ChatView/Messages/Messages.types';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

import { MessageSelect } from '../components/MessageSelect/MessageSelect';
import { disableQuoteAttrs } from '../presenter/constants';
import { useSystemMessageLayoutPresenter } from './SystemMessageLayout.presenter';
import styles from './SystemMessageLayout.styles.m.css';
import { SystemMessageMenu } from './SystemMessageMenu/SystemMessageMenu';

interface SystemMessageLayoutProps {
  message: ChatMessage;
  className?: string;
  children: React.ReactNode;
  messageViewPageType: MessageViewPageType;
}

export const SystemMessageLayout: FC<SystemMessageLayoutProps> = observer((props) => {
  const { message, className, children, messageViewPageType } = props;

  const presenter = useSystemMessageLayoutPresenter(message);

  const menuMessage = presenter.isShowMenu ? (
    <SystemMessageMenu
      key="system-message-menu"
      message={message}
      className={styles.systemMessageMenu}
      messageViewPageType={messageViewPageType}
    />
  ) : null;

  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.innerContainer}>
        {presenter.withMeInAdmins && <div className={styles.menuPlaceholder}>{menuMessage}</div>}
        <div {...disableQuoteAttrs}>{children}</div>
        {presenter.withMeInAdmins && <div className={styles.menuPlaceholder} />}
      </div>
      {presenter.withMeInAdmins && (
        <MessageSelect
          key="select"
          message={message}
          onMessageSelect={presenter.onMessageSelect}
          labelClassName={styles.selectLabel}
        />
      )}
    </div>
  );
});
