import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { SeeMoreText } from 'APP/components/SeeMoreText/SeeMoreText';
import { ISpaceMarket } from 'APP/model/space/spaceModel.types';
import { useTranslation } from 'APP/packages/translations';
import MoreIcon from 'ICONS/dots.svg';
import MarketIcon from 'ICONS/ic-market.svg';
import { Avatar } from 'UIKIT/Avatar/Avatar';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';
import { Button } from 'UIKIT/Button/Button';
import { IconButton, IconViewSizes } from 'UIKIT/IconButton';
import { usePopover } from 'UIKIT/Popover/usePopover';
import { PopoverMenu } from 'UIKIT/PopoverMenu/PopoverMenu';

import { useSpaceMarketItemPresenter } from './SpaceMarketItem.presenter';
import styles from './SpaceMarketItem.styles.m.css';

export const MAX_DESCRIPTION_LENGTH = 140;

interface ISpaceMarketItemProps {
  market: ISpaceMarket;
  showActions: boolean;
  onEdit(market: ISpaceMarket): void;
  onDelete(market: ISpaceMarket): void;
}

export const SpaceMarketItem: FC<ISpaceMarketItemProps> = observer(
  ({ market, showActions, onEdit, onDelete }) => {
    const { t } = useTranslation();
    const popover = usePopover<HTMLButtonElement>();
    const { menuItems, onOpenMarket } = useSpaceMarketItemPresenter(market, onEdit, onDelete);

    return (
      <div
        className={styles.listItem}
        key={market.id}
      >
        {market.avatarUrl && (
          <Avatar
            className={styles.avatar}
            type={AvatarTypes.Square_50}
            title={market.name}
            url={market.avatarUrl}
          />
        )}

        {!market.avatarUrl && (
          <div className={styles.emptyAvatar}>
            <MarketIcon className={styles.img} />
          </div>
        )}

        <div>
          <h3 className={styles.title}>{market.name}</h3>

          {market.description && (
            <SeeMoreText
              className={styles.description}
              text={market.description}
              maxTextLength={MAX_DESCRIPTION_LENGTH}
            />
          )}

          <div className={styles.actions}>
            <Button
              theme="1"
              onClick={onOpenMarket}
            >
              {t('space_go_to_shop')}
            </Button>

            {showActions && (
              <>
                <IconButton
                  setRef={popover.containerRef}
                  Icon={MoreIcon}
                  size={IconViewSizes.Big}
                  theme="6"
                  onClick={popover.toggle}
                />

                <PopoverMenu
                  isOpened={popover.isOpened}
                  placement="bottom-end"
                  menuItems={menuItems}
                  reference={popover.containerRef.current}
                  onClose={popover.close}
                />
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
);
