import { useLocalStore } from 'mobx-react';

import Entities from 'APP/store';
import { useShareScreenToggle } from 'MAIN/OngoingCall/hooks/useShareScreenToggle/useShareScreenToggle';
import OngoingCall from 'STORE/ViewModels/Calls/OngoingCall';

export interface IGroupCallPresenter {
  opponentsLength: number;
  ongoingCall: OngoingCall;
  isGridLayoutView: boolean;
  isSpeakerView: boolean;
}

export const useGroupCallPresenter = (): IGroupCallPresenter => {
  const presenter = useLocalStore<IGroupCallPresenter>(() => ({
    get opponentsLength(): number {
      return Entities.Calls.ongoingCall?.opponents.opponentList.length || 0;
    },

    get ongoingCall(): OngoingCall {
      return Entities.OngoingCall;
    },

    get isGridLayoutView(): boolean {
      return (
        presenter.ongoingCall.mode.isGroup &&
        (presenter.ongoingCall.presentVideoMode?.isGridLayout || presenter.opponentsLength < 2)
      );
    },

    get isSpeakerView(): boolean {
      return (
        presenter.ongoingCall.mode.isGroup &&
        !presenter.ongoingCall.presentVideoMode?.isGridLayout &&
        presenter.opponentsLength > 1
      );
    },
  }));

  useShareScreenToggle();

  return presenter;
};
