import { observer } from 'mobx-react';
import React from 'react';
import { Popper } from 'react-popper';

import { ClickOutside } from 'UIKIT/ClickOutside/ClickOutside';

import Content from './Content';
import usePresenter from './presenter';

/**
 * @deprecated Used only in artucles
 */

const TextFormattingMenu = ({ reference, textFormattingMenuInstance }) => {
  const { ref, setRef, contentInstance, presenter } = usePresenter({
    textFormattingMenuInstance,
  });

  if (!presenter.isOpened) {
    return null;
  }

  return (
    <ClickOutside
      reference={ref}
      target={reference}
      onClickOutside={presenter.onClickOutside}
    >
      <Popper
        innerRef={setRef}
        referenceElement={presenter.virtualReference}
        placement="top"
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
        ]}
      >
        {(popoverInstance) => {
          return (
            <Content
              popoverInstance={popoverInstance}
              contentInstance={contentInstance}
              onClose={presenter.onClose}
            />
          );
        }}
      </Popper>
    </ClickOutside>
  );
};

export default observer(TextFormattingMenu);
