import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { useTranslation } from 'APP/packages/translations';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';

import { ScheduledCallsForm } from '../components/ScheduledCallsForm/ScheduledCallsForm';
import {
  useScheduledCallCreatePopupPresenter,
  IScheduledCallCreatePopupProps,
} from './ScheduledCallCreatePopup.presenter';

export const ScheduledCallCreatePopup: FC<ICommonPopupsProps<IScheduledCallCreatePopupProps>> =
  observer((props) => {
    const { t } = useTranslation();
    const presenter = useScheduledCallCreatePopupPresenter(props);

    return (
      <Popup
        title={t('call_scheduled_call')}
        isOpened
        handlePrevStep={presenter.hasBackButton ? presenter.onBack : null}
        onClose={presenter.onClose}
      >
        <ScheduledCallsForm
          onSubmit={presenter.onSubmit}
          initialValues={presenter.initialValues}
        />
      </Popup>
    );
  });
