import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { IconButton } from 'APP/packages/UiKit/IconButton';
import { IMediaGalleryState } from 'APP/types/mediaGallery';
import BackArrowIcon from 'ICONS/ic-back.svg';
import ForwardIcon from 'ICONS/ic-forward-stroke.svg';
import TrashIcon from 'ICONS/ic-s-delete.svg';
import DownIcon from 'ICONS/ic-toggle_down.svg';
import { IconViewSizes } from 'UIKIT/IconView/IconView.types';

import {
  IMediaGalleryFile,
  TSetSidebarVisabilityHandler,
  TCloseHandler,
} from '../MediaGallery.types';
import { MediaGalleryDownload } from './MediaGalleryDownloadState/MediaGalleryDownload';
import { useMediaGalleryHeaderPresenter } from './MediaGalleryHeader.presenter';
import styles from './MediaGalleryHeader.style.m.css';
import { MediaGalleryInfoCard } from './MediaGalleryInfoCard/MediaGalleryInfoCard';

interface IMediaGalleryHeaderPresenterParams {
  file: IMediaGalleryFile;
  canDelete: boolean;
  canNavigate: boolean;
  canOpenSidebar: boolean;
  isShowSidebar: boolean;
  onSetSidebarVisibility: TSetSidebarVisabilityHandler;
  onClose: TCloseHandler;
  galleryState: IMediaGalleryState;
}

export const MediaGalleryHeader: FC<IMediaGalleryHeaderPresenterParams> = observer((props) => {
  const {
    file,
    canDelete,
    canNavigate,
    canOpenSidebar,
    isShowSidebar,
    onSetSidebarVisibility,
    onClose,
    galleryState,
  } = props;

  const presenter = useMediaGalleryHeaderPresenter({
    file,
    canDelete,
    isShowSidebar,
    onSetSidebarVisibility,
    galleryState,
  });

  const sidebarButtonClassName = classNames(styles.control, {
    [styles.rotatedControl]: isShowSidebar,
  });

  return (
    <div className={styles.container}>
      <IconButton
        Icon={BackArrowIcon}
        size={IconViewSizes.Big}
        iconSize={IconViewSizes.Medium}
        theme="11"
        onClick={onClose}
      />

      <div className={styles.rightPanel}>
        <div className={styles.controls}>
          <MediaGalleryDownload
            className={styles.control}
            message={presenter.message}
            payloadId={file.id}
          />

          {presenter.canBeDelete && (
            <>
              <IconButton
                className={styles.control}
                Icon={TrashIcon}
                size={IconViewSizes.Big}
                iconSize={IconViewSizes.Medium}
                theme="11"
                onClick={presenter.onDelete}
              />
            </>
          )}

          {presenter.canBeForward && (
            <IconButton
              className={styles.control}
              Icon={ForwardIcon}
              size={IconViewSizes.Big}
              iconSize={IconViewSizes.Medium}
              theme="11"
              onClick={presenter.onForward}
            />
          )}
        </div>

        <div className={styles.messageInfoPanel}>
          {!isShowSidebar && <div className={styles.divider} />}

          <MediaGalleryInfoCard
            message={presenter.message}
            sender={presenter.sender}
            canNavigate={canNavigate}
            onClose={onClose}
          />

          {canOpenSidebar && (
            <IconButton
              className={sidebarButtonClassName}
              Icon={DownIcon}
              size={IconViewSizes.Big}
              iconSize={IconViewSizes.Medium}
              theme="12"
              onClick={presenter.onToggleSidebar}
            />
          )}
        </div>
      </div>
    </div>
  );
});
