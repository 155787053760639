import { observer } from 'mobx-react';
import React, { FC, useCallback } from 'react';

import { List } from 'APP/components/List/List';
import { GroupsPlaceholder } from 'APP/packages/placeholders/GroupsPlaceholder/GroupsPlaceholder';
import { useTranslation } from 'APP/packages/translations';
import type { User } from 'STORE/Users/User/User';
import { SearchInput } from 'UIKIT/SearchInput/SearchInput';

import { Contact } from '../Contact/Contact';
import { NewContactButton } from '../NewContactButton/NewContactButton';
import { NoContacts } from '../NoContacts/NoContacts';
import { useContactsViewPresenter } from './ContactsView.presenter';
import styles from './ContactsView.styles.m.css';

interface IContactsViewProps {
  onOpenNewContact(): void;
  onClose(): void;
}

export const ContactsView: FC<IContactsViewProps> = observer(({ onOpenNewContact, onClose }) => {
  const { t } = useTranslation();
  const presenter = useContactsViewPresenter();

  const renderItem = useCallback(
    (contactUser: User) => {
      return (
        <Contact
          contactUser={contactUser}
          closePopup={onClose}
        />
      );
    },
    [onClose]
  );

  return (
    <div className={styles.contactsView}>
      <SearchInput
        className={styles.search}
        onChange={presenter.onSearch}
        value={presenter.searchString}
        placeholder={t('main_menu_search_note')}
      />
      <NewContactButton
        className={styles.newContact}
        onClick={onOpenNewContact}
      />
      <List
        isScrollable
        className={styles.list}
        data={presenter.filteredUsers}
        isLoading={presenter.loading}
        keyExtractor={presenter.keyExtractor}
        renderItem={renderItem}
        ListEmptyComponent={!presenter.searchString ? <NoContacts /> : undefined}
        SpinnerComponent={
          <GroupsPlaceholder
            count={presenter.placeholdersCount}
            className={styles.placeholders}
          />
        }
      />
    </div>
  );
});
