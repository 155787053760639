/**
 * Rest API
 * Documentation Rest API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserDeviceResponse { 
    platform: UserDeviceResponsePlatformEnum;
    appVersion: string;
    osVersion: string;
    ipAddress: string;
    country: string;
    city: string;
    device: string;
    lastOnline: string;
    registrationKey: string;
}
export enum UserDeviceResponsePlatformEnum {
    Android = 'android',
    Huawei = 'huawei',
    Web = 'web',
    Ios = 'ios',
    Safari = 'safari',
    Desktop = 'desktop'
};



