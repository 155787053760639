import { MutableRefObject, useEffect, useRef } from 'react';

import Tasks from 'APP/Tasks';
import { ALERT_TYPES } from 'APP/constants/app';
import Entities from 'STORE';
import VideoPayload from 'STORE/Messages/Message/Payload/Video';

const isMediaFileExists = (groupId: string, mediaId: string): boolean => {
  const group = Entities.GroupsStore.getGroupById(groupId);

  if (!group) {
    return false;
  }
  return !!group.sharedData.media.getItemById(mediaId);
};

interface IMediaGalleryVideoPresenterParams {
  id: string;
  messageId: string;
  groupId: string;
  payload: VideoPayload;
}

export const useMediaGalleryVideoPresenter = (
  params: IMediaGalleryVideoPresenterParams
): { containerRef: MutableRefObject<HTMLDivElement | null> } => {
  const { id, messageId, groupId, payload } = params;
  const containerRef = useRef<HTMLDivElement | null>(null);
  const userFileBlobUrlRef = useRef<string | null>(null);

  useEffect(() => {
    userFileBlobUrlRef.current = (payload.userFileBlobUrl as string) || null;
  }, [id]);

  useEffect(() => {
    if (containerRef.current) {
      Tasks.miniPlayer.closeMiniPlayer();
      Tasks.globalVideoPlayer.initVideoPlayer({
        container: containerRef.current,
        url: userFileBlobUrlRef.current || payload.url,
        previewUrl: payload.previewUrl,
        onExpandMini: () => {
          if (isMediaFileExists(groupId, id)) {
            Tasks.group.openMediaGallery({
              mediaId: id,
              messageId: messageId,
              groupId,
            });
          } else {
            Tasks.app.addAlert({
              type: ALERT_TYPES.SOMETHING_WRONG_ERROR,
            });
          }
        },
        onShowMini: () => Tasks.group.closeMediaGallery(),
      });
    }
  }, [id, messageId, groupId, payload]);

  useEffect(() => {
    return () => {
      if (!Entities.GlobalVideoPlayer.isMiniOpened) {
        Tasks.globalVideoPlayer.closeVideoPlayer();
      }
    };
  }, []);

  return { containerRef };
};
