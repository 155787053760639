/**
 * Rest API
 * Documentation Rest API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DeleteMessagesRequest { 
    groupId: string;
    messageIds: Array<string>;
    mode: DeleteMessagesRequestModeEnum;
}
export enum DeleteMessagesRequestModeEnum {
    All = 'FOR_ALL',
    Me = 'FOR_ME'
};



