import { TFunction } from 'react-i18next';

import {
  TSystemMessageTextHandler,
  ISystemMessageText,
  ISystemMessageTextPresenter,
} from '../useSystemMessageText.types';

export const chatRenamedHandler: TSystemMessageTextHandler = (
  presenter: ISystemMessageTextPresenter,
  t: TFunction
): ISystemMessageText => {
  const result: ISystemMessageText = { html: '' };

  if (presenter.isPublicGroup || presenter.isChannel) {
    result.html = t('notification_chat_renamed_without_name', {
      0: `<span class="system-message-title">${presenter.oldName}</span>`,
      1: `<span class="system-message-title">${presenter.newName}</span>`,
      interpolation: { escapeValue: false },
    });
  } else {
    result.html = t('notification_chat_renamed', {
      0: `<span class="system-message-title">${presenter.initiatorDisplayName}</span>`,
      1: `<span class="system-message-title">${presenter.oldName}</span>`,
      2: `<span class="system-message-title">${presenter.newName}</span>`,
      interpolation: { escapeValue: false },
    });
    result.sourceText = t('notification_chat_renamed');
    result.params = {
      '{0}': { text: presenter.initiatorDisplayName, userId: presenter.initiator?.id },
      '{1}': { text: presenter.oldName },
      '{2}': { text: presenter.newName },
    };
  }

  return result;
};
