import { observer } from 'mobx-react';
import React from 'react';

import { GroupAvatar } from 'APP/components/Avatar/GroupAvatar/GroupAvatar';
import { useTranslation } from 'APP/packages/translations';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';

import usePresenter from './presenter';
import styles from './styles.m.css';

const Stream = ({ message }) => {
  const presenter = usePresenter(message);
  const { t } = useTranslation();

  return (
    <>
      <button
        className={styles.container}
        onClick={presenter.onClick}
      >
        {presenter.placeholderUrl && (
          <img
            className={styles.placeholder}
            src={presenter.placeholderUrl}
            height={presenter.heightPreview}
            loading="lazy"
            alt=""
          />
        )}
        {presenter.previewUrl ? (
          <img
            className={styles.image}
            src={presenter.previewUrl}
            height={presenter.heightPreview}
            width={presenter.widthPreview}
            loading="lazy"
            alt=""
          />
        ) : (
          <GroupAvatar
            className={styles.avatar}
            group={presenter.group}
            type={AvatarTypes.Square_50}
          />
        )}

        {presenter.isLive ? (
          <div className={styles.live}>LIVE</div>
        ) : (
          <div className={styles.finish}>{t('stream_finished_title')}</div>
        )}
      </button>
    </>
  );
};

export default observer(Stream);
