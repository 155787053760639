import classNames from 'classnames';
import React from 'react';

import { AddKeyPressHandler } from 'APP/packages/keyBoardHandler';
import { useTranslation } from 'APP/packages/translations';
import CheckMarkIcon from 'ICONS/check-mark.svg';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';

import styles from './styles.m.css';

const Options = ({ onChange, onClose, value, options, noSelectedLabel }) => {
  const { t } = useTranslation();
  const listClass = classNames(styles.list, customScrollStyles.container);

  const handleChange = (id) => {
    onClose();
    onChange(id);
  };

  const nameNoSelectedContainer = classNames(styles.name, {
    [styles.nameSelected]: !value,
  });

  return (
    <>
      <AddKeyPressHandler onEsc={onClose} />
      <div className={listClass}>
        <button
          className={styles.item}
          onClick={() => handleChange(null)}
        >
          <span className={styles.markContainer}>
            {!value && <CheckMarkIcon className={styles.mark} />}
          </span>
          <span className={nameNoSelectedContainer}>
            {t(noSelectedLabel || 'category_no_selected')}
          </span>
        </button>
        {options.map((option) => {
          const isSelected = value === option.value;

          const nameContainer = classNames(styles.name, {
            [styles.nameSelected]: isSelected,
          });

          return (
            <button
              key={option.value}
              className={styles.item}
              onClick={() => handleChange(option.value)}
            >
              <span className={styles.markContainer}>
                {isSelected && <CheckMarkIcon className={styles.mark} />}
              </span>
              <span className={nameContainer}>{option.label}</span>
            </button>
          );
        })}
      </div>
    </>
  );
};

export default Options;
