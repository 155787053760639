import { RefObject, useCallback, useEffect } from 'react';

interface IMediaPlayerSpeedOptions {
  url: string | null;
  mediaRef: RefObject<HTMLMediaElement>;
  config: { speed: number };
  onConfigUpdate?({ speed }: { speed: number }): void;
}

interface IMediaPlayerSpeed {
  setSpeed(speed: number): void;
}

export function useMediaPlayerSpeed({
  url,
  mediaRef,
  config,
  onConfigUpdate,
}: IMediaPlayerSpeedOptions): IMediaPlayerSpeed {
  const setSpeed = useCallback(
    (newSpeed) => {
      onConfigUpdate?.({ speed: newSpeed });
    },
    [onConfigUpdate]
  );

  useEffect(() => {
    if (mediaRef.current) {
      mediaRef.current.playbackRate = config.speed;
    }
  }, [config.speed, url]);

  return { setSpeed };
}
