import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, ReactElement } from 'react';

import { List } from 'APP/components/List/List';
import { SharedDataType } from 'APP/constants/sharedData';
import { ISharedDataItem } from 'APP/model/sharedData/sharedDataModel.types';
import { CircleVideo } from 'STORE/Messages/Message/Payload/CircleVideo/CircleVideo';
import VoicePayload from 'STORE/Messages/Message/Payload/Voice';
import { Loader } from 'UIKIT/Loader/Loader';

import { useSharedDataPresenter } from '../SharedData.presenter';
import styles from '../SharedData.styles.m.css';
import { EmptyContentStub } from '../components/EmptyContentStub/EmptyContentStub';
import { GroupByDateItems } from '../components/GroupByDateItems';
import { CircleVideoItem } from './CircleVideoItem/CircleVideoItem';
import { VoiceMessageItem } from './VoiceMessageItem/VoiceMessageItem';

interface IVoicesProps {
  className?: string;
  groupId: string;
}

const renderVoiceItem = (item: ISharedDataItem<VoicePayload | CircleVideo>): ReactElement => {
  if (item.payload instanceof CircleVideo) {
    return (
      <CircleVideoItem
        key={`${item.id}_${item.messageId}`}
        {...(item as ISharedDataItem<CircleVideo>)}
      />
    );
  }

  return (
    <VoiceMessageItem
      key={`${item.id}_${item.messageId}`}
      {...(item as ISharedDataItem<VoicePayload>)}
    />
  );
};

export const Voices: FC<IVoicesProps> = observer(({ className, groupId }) => {
  const presenter = useSharedDataPresenter({ groupId, type: SharedDataType.Voices });

  return (
    <List
      isLoading={presenter.isLoading}
      className={classNames(styles.container, className)}
      data={presenter.dateList}
      hasMore={presenter.hasMore}
      onEndReached={presenter.loadMore}
      keyExtractor={(date): string => date}
      renderItem={(date): ReactElement => (
        <GroupByDateItems
          key={date}
          title={date}
          data={presenter.groupFilesByDate[date]}
          groupId={groupId}
          sharedDataType={SharedDataType.Voices}
          renderItem={renderVoiceItem}
        />
      )}
      SpinnerComponent={
        <div className={styles.loader}>
          <Loader color={'base'} />
        </div>
      }
      ListEmptyComponent={<EmptyContentStub />}
    />
  );
});
