import { observable, action } from 'mobx';

import { SharedMessages } from '../SharedMessages/SharedMessages';

// Todo Remove numeric type of ts fields after the SharedData store will be moving to typeScript

export class SharedDataItem {
  messages: SharedMessages;
  @observable isLoading = false;
  @observable cursorTs = 0;
  @observable lastMessageTs = 0;
  @observable hasMore = true;
  @observable _count = 0;

  constructor(messages: SharedMessages) {
    this.messages = messages;
  }

  @action
  setLoading = (isLoading: boolean): void => {
    this.isLoading = isLoading;
  };

  @action
  setCursorTs(cursorTs: number): void {
    this.cursorTs = cursorTs;
  }

  @action
  setLastMessageTs(cursorTs: number): void {
    this.lastMessageTs = cursorTs;
  }

  @action
  setHasMore(hasMore: boolean): void {
    this.hasMore = hasMore;
  }

  @action
  updateCount(newValue: number): void {
    this._count = newValue;
  }
}
