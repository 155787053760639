import { useCallback, useEffect, useRef, useState } from 'react';

import MaxVolumeIcon from 'ICONS/ic-max-volume.svg';
import MinVolumeIcon from 'ICONS/ic-min-volume.svg';
import VolumeOffIcon from 'ICONS/ic-volume-off.svg';
import { debounce } from 'UTILS/debounce';

import { VIDEO_PLAYER_VOLUME_MAX, VIDEO_PLAYER_VOLUME_STEP } from '../VideoPlayer.constants';

const ACTION_TO_KEY_CODE_MAP = {
  Decrease: 'ArrowLeft',
  Increase: 'ArrowRight',
};

const VOLUME_UPDATE_DELAY = 100;

const getVolumeIcon = (volume, isMuted = false) => {
  if (isMuted || !volume) {
    return VolumeOffIcon;
  } else if (volume > 0.5) {
    return MaxVolumeIcon;
  } else {
    return MinVolumeIcon;
  }
};

const getPercentage = (volume, isMuted = false, volumeSelecting = false) => {
  return (isMuted && !volumeSelecting ? 0 : volume) * 100;
};

export function useVideoPlayerVolumePresenter({ volume, isMuted, onSetVolume, onVolumeSelection }) {
  const volumeRef = useRef(null);
  const currentVolumeRef = useRef(volume);

  const [volumeSelecting, setVolumeSelecting] = useState(false);
  const [volumeIcon, setVolumeIcon] = useState({ icon: MaxVolumeIcon });
  const [percentage, setPercentage] = useState(getPercentage(volume, isMuted));

  const onSetVolumeDebounce = useCallback(debounce(onSetVolume, VOLUME_UPDATE_DELAY), []);

  const onMouseDown = useCallback((event) => {
    if (event.button !== 0) {
      return;
    }

    onMouseMove(event);
    setVolumeSelecting(true);
    onVolumeSelection(true);

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  }, []);

  const onMouseMove = useCallback((event) => {
    const { clientX } = event;
    const {
      x: volumeLeft,
      right: volumeRight,
      width: volumeWidth,
    } = volumeRef.current.getBoundingClientRect();
    let newVolume;

    if (volumeLeft >= clientX) {
      newVolume = 0;
    } else if (volumeRight <= clientX) {
      newVolume = VIDEO_PLAYER_VOLUME_MAX;
    } else {
      const percentage = ((clientX - volumeLeft) * 100) / volumeWidth;
      newVolume = percentage / 100;
    }

    setVolumeIcon({
      icon: getVolumeIcon(newVolume),
    });
    setPercentage(getPercentage(newVolume, false, false));
    onSetVolumeDebounce(newVolume);
  }, []);

  const onMouseUp = useCallback(() => {
    setVolumeSelecting(false);
    onVolumeSelection(false);
    stopListening();
  }, []);

  const stopListening = useCallback(() => {
    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', onMouseUp);
  }, []);

  useEffect(() => {
    setVolumeIcon({
      icon: getVolumeIcon(volume, isMuted),
    });
    setPercentage(getPercentage(volume, isMuted));
  }, [volume, isMuted]);

  useEffect(() => stopListening, []);

  useEffect(() => {
    currentVolumeRef.current = volume;
  }, [volume]);

  useEffect(() => {
    const onKeyDown = (event) => {
      if (volumeRef.current !== document.activeElement) {
        return;
      }

      switch (event.key) {
        case ACTION_TO_KEY_CODE_MAP.Decrease: {
          const newVolume = currentVolumeRef.current - VIDEO_PLAYER_VOLUME_STEP;
          onSetVolume(newVolume > 0 ? newVolume : 0);
          event.stopPropagation();
          break;
        }
        case ACTION_TO_KEY_CODE_MAP.Increase: {
          const newVolume = currentVolumeRef.current + VIDEO_PLAYER_VOLUME_STEP;
          onSetVolume(newVolume > VIDEO_PLAYER_VOLUME_MAX ? VIDEO_PLAYER_VOLUME_MAX : newVolume);
          event.stopPropagation();
          break;
        }
      }
    };

    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  return { volumeRef, onMouseDown, volumeSelecting, volumeIcon, percentage };
}
