import Tasks from 'APP/Tasks';
import Entities from 'STORE';
import InputPanel from 'STORE/InputPanelsStore/InputPanel';

export const searchMentions = async (inputPanel: InputPanel, mention: string): Promise<boolean> => {
  try {
    inputPanel.mention.setIsLoading(true);
    inputPanel.mention.setFilter(mention);

    const response = await Tasks.search.searchUsersForMention({
      inputPanel,
      filter: mention,
    });

    const usersForMentions = response.usersForMention;

    inputPanel.mention.setMentions(usersForMentions);
    inputPanel.mention.setCursor(response.cursor);
    inputPanel.mention.setIsLoading(false);

    Entities.UsersStore.add(usersForMentions);

    return true;
  } catch {
    Tasks.search.resetMentions({ inputPanel });

    return false;
  }
};
