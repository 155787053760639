import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { useTranslation } from 'APP/packages/translations';
import ArrowIcon from 'ICONS/ic-arrow_up-16.svg';
import { Checkbox } from 'UIKIT/Checkbox';
import { IconView, IconViewSizes } from 'UIKIT/IconView/IconView';
import { Switch } from 'UIKIT/Switch/Switch';

import {
  useSupportedMediaTypesPresenter,
  ISupportedMediaTypesParams,
} from './SupportedMediaTypes.presenter';
import styles from './SupportedMediaTypes.styles.m.css';

type TSupportedMediaTypesProps = ISupportedMediaTypesParams;

export const SupportedMediaTypes: FC<TSupportedMediaTypesProps> = observer((props) => {
  const { t } = useTranslation();
  const presenter = useSupportedMediaTypesPresenter(props);

  const iconClassName = classNames(styles.headerIcon, {
    [styles.headerOpenedIcon]: presenter.isOpen,
  });

  return (
    <>
      <div className={styles.header}>
        <div
          role="button"
          tabIndex={0}
          className={classNames(styles.listItem, styles.headerTitle)}
          onClick={presenter.onToggleOpen}
        >
          {t('moderation_content_limit')}
          <span className={styles.headerCounter}>
            {`${presenter.countCheckedItems}/${presenter.items.length}`}
          </span>
          <IconView
            Icon={ArrowIcon}
            size={IconViewSizes.Small}
            className={iconClassName}
          />
        </div>

        <label className={styles.listItem}>
          <Switch
            isChecked={presenter.isAllChecked}
            onChange={presenter.onToggleAll}
          />
        </label>
      </div>

      {presenter.isOpen && (
        <>
          {presenter.items.map(({ id, title, isChecked, onChange }) => {
            return (
              <label
                key={id}
                className={styles.listItem}
              >
                <Checkbox
                  isChecked={isChecked}
                  onChange={onChange}
                  className={styles.checkbox}
                />
                {title}
              </label>
            );
          })}
        </>
      )}
    </>
  );
});
