import Tasks from 'APP/Tasks';
import { mapCounterResponse } from 'APP/model/counter/counterModel.mapper';
import { spaceAnalytics } from 'APP/packages/analytics/analytics';
import api from 'APP/packages/api';
import logger from 'APP/packages/logger';
import Entities from 'APP/store';

async function request(data) {
  try {
    // SERVER IS SENSITIVE TO DEFAULT VALUES, DON'T CHANGE THEM
    const response = await api.group.createGroupAndInvite({
      name: (data.name || '').trim() || null,
      description: data.description || '',
      type: data.type,
      members: data.members,
      admins: data.admins || [],
      avatarUrl: data.avatarUrl || null,
      categoryId: data.categoryId,
      deepLinkAlias: data.deepLinkAlias || '', // generate new deep link
      donationUrl: data.donationUrl || '',
      spaceId: data.spaceId || null,
    });

    if (response?.personalizedGroup?.groupResponse) {
      return {
        group: response.personalizedGroup,
        counter: response.groupCounter,
      };
    }
  } catch (error) {
    logger.get('API').error('group.createGroupAndInvite', error);
  }

  return null;
}

function trackGroupCreateEvent(group) {
  if (!group.spaceId) {
    return;
  }

  const space = Entities.spacesStore.getById(group.spaceId);

  if (space) {
    group.isChatGroup
      ? spaceAnalytics.trackSpaceGroupCreated(space.id, space.type, group.id, group.type)
      : spaceAnalytics.trackSpaceChannelCreated(space.id, space.type, group.id, group.type);
  }
}

export async function createGroupAndInvite(data, shouldBeSetAsActive = true) {
  const response = await request(data);

  if (!response) {
    return null;
  }

  const { group, counter } = response;

  const opponent = group.opponent || group.botOpponent;
  if (opponent) {
    Entities.UsersStore.add([opponent]);
  }

  Entities.GroupsStore.merge([group]);

  const newGroup = Entities.GroupsStore.getGroupById(group.groupResponse.id);
  if (!newGroup) {
    return null;
  }

  if (shouldBeSetAsActive) {
    await Tasks.group.setActiveGroup({ groupId: newGroup.id });
  }
  await Tasks.group.addCounters([mapCounterResponse(counter)]);

  trackGroupCreateEvent(newGroup);

  return newGroup;
}
