import Tasks from 'APP/Tasks';
import { GroupType } from 'APP/model/group/groupModel.types';
import Entities from 'STORE';
import { Group } from 'STORE/Groups/Group';

export const goToSavedMessages = async (state = {}): Promise<Group | null> => {
  let group = Entities.GroupsStore.savedMessagesGroup;
  if (group) {
    await Tasks.group.setActiveGroup({ groupId: group.id, state });
    return group;
  }

  group = await Tasks.group.createGroupAndInvite({ type: GroupType.MySavedMessages }, false);
  if (group) {
    await Tasks.group.setActiveGroup({ groupId: group.id, state });
    return group;
  }

  return null;
};
