import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { NewMessagesBadge } from '../NewMessagesBadge/NewMessagesBadge';
import { GroupBadgeType } from './GroupBadge.constants';
import { useGroupBadgePresenter } from './GroupBadge.presenter';

interface IGroupBadgeProps {
  className?: string;
  readonly type: GroupBadgeType;
}

export const GroupBadge: FC<IGroupBadgeProps> = observer(({ className, type }) => {
  const presenter = useGroupBadgePresenter(type);

  if (presenter.hasNewMessages) {
    return <NewMessagesBadge className={className} />;
  }

  return null;
});
