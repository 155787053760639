import api from 'APP/packages/api';
import logger from 'APP/packages/logger';
import Entities from 'STORE';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

import { GroupsEvent } from '../GroupsEvent';

export class DraftWasUpdated extends GroupsEvent {
  async process() {
    const { draftId, groupId } = this.eventPayload;
    let draft = null;
    try {
      draft = await api.messaging.getDraft({
        groupId: groupId.toString(),
        draftId: draftId.toString(),
      });
    } catch (e) {
      logger.get('API').error('messaging.getDraft', e);
    }

    if (!draft) {
      return;
    }

    const group = Entities.GroupsStore.getGroupById(groupId.toString());

    if (!group) {
      return;
    }

    const targetDraft = group.draftsStore.getDraftById(draft.id);
    const chatDraft = new ChatMessage(draft, group.messagesStore);

    if (!targetDraft) {
      group.draftsStore.setDraft(chatDraft);

      return;
    }

    group.draftsStore.updateDraft(chatDraft);
  }
}
