import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { useTranslation } from 'APP/packages/translations';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { useClosePopupOnNavigation } from '../../hooks/useClosePopupOnNavigation';
import { INewContactPopupParams, useNewContactPopupPresenter } from './NewContactPopup.presenter';
import { NewContactView } from './NewContactView/NewContactView';

export const NewContactPopup: FC<ICommonPopupsProps<INewContactPopupParams | undefined>> = observer(
  (props) => {
    const { t } = useTranslation();
    const presenter = useNewContactPopupPresenter(props);
    useClosePopupOnNavigation(props.popupInstance);

    return (
      <Popup
        title={t('new_contact')}
        isOpened
        handlePrevStep={presenter.hasBackButton ? presenter.onBack : undefined}
        onClose={presenter.onClose}
      >
        <NewContactView
          navigateToActiveGroup={props.params?.navigateToActiveGroup}
          onBack={presenter.onBack}
          onClose={presenter.onClose}
        />
      </Popup>
    );
  }
);
