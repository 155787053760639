import React, { FC } from 'react';

import { IconView, IconViewSizes } from 'APP/packages/UiKit/IconView/IconView';
import { useTranslation } from 'APP/packages/translations';
import DeleteIcon from 'ICONS/ic-delete-16.svg';

import styles from './DeletedByMePayload.styles.m.css';

export const DeletedByMePayload: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.text}>
      <IconView
        Icon={DeleteIcon}
        className={styles.icon}
        size={IconViewSizes.Small}
      />
      {t('common_message_deleted_for_me')}
    </div>
  );
};
