import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { useTranslation } from 'APP/packages/translations';

interface ILinkAppleProviderProps {
  setIsProcessing(isProcessing: boolean): void;
}

export const LinkAppleProvider: FC<ILinkAppleProviderProps> = observer((props) => {
  const { t } = useTranslation();

  return <div>{t('account_link_iphone')}</div>;
});
