import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import { ICatalogItem } from 'APP/model/catalog/catalogModel.types';
import Entities from 'STORE';
import { isEqualArrays } from 'UTILS/isEqualArrays';

import { TCatalogResetHandler } from '../Catalog.types';
import { searchCatalog } from '../Tasks/searchCatalog';

interface ICatalogSearchContentLocalStore {
  searchText: string;
  localeIds: string[];
  isLoading: boolean;
  isLoadingView: boolean;
  isLoadingMoreView: boolean;
  isEmptyView: boolean;
  isContentView: boolean;
  viewObserverKey: string;
  items: ICatalogItem[];
  load(cursor?: string): Promise<void>;
  loadMore(): void;
  onViewObserver(isView: boolean): void;
}

const SEARCH_MIN_CHARS = 3;

export const useCatalogSearchContentPresenter = (onCatalogReset: TCatalogResetHandler) => {
  const presenter = useLocalStore<ICatalogSearchContentLocalStore>(() => ({
    searchText: Entities.catalogStore.searchText,
    localeIds: Entities.userPreferences.localeIds,
    isLoading: false,

    get isLoadingView(): boolean {
      return presenter.isLoading && presenter.items.length === 0;
    },

    get isLoadingMoreView(): boolean {
      return presenter.isLoading && presenter.items.length > 0;
    },

    get isEmptyView(): boolean {
      return !presenter.isLoadingView && presenter.items.length === 0;
    },

    get isContentView(): boolean {
      return !presenter.isLoadingView && !presenter.isEmptyView;
    },

    get viewObserverKey(): string {
      return `viewObserver-${presenter.items.length}`;
    },

    get items(): ICatalogItem[] {
      return Entities.catalogStore.items;
    },

    async load(cursor?: string): Promise<void> {
      if (presenter.searchText.trim().length < SEARCH_MIN_CHARS) {
        return;
      }

      presenter.isLoading = true;

      const prevSearchText = presenter.searchText;
      const prevLocaleIds = presenter.localeIds;
      const result = await searchCatalog({ query: presenter.searchText, cursor });

      if (
        prevSearchText !== presenter.searchText ||
        prevLocaleIds !== presenter.localeIds ||
        (cursor && cursor !== Entities.catalogStore.cursor)
      ) {
        return;
      }

      if (!result) {
        Entities.catalogStore.setError(true);
        presenter.isLoading = false;
        return;
      }

      Entities.catalogStore.setItems([...Entities.catalogStore.items, ...result.items]);
      Entities.catalogStore.setCursor(result.cursor || null);
      presenter.isLoading = false;
    },

    loadMore(): void {
      if (presenter.isLoading || !Entities.catalogStore.cursor) {
        return;
      }

      presenter.load(Entities.catalogStore.cursor);
    },

    onViewObserver(isView: boolean): void {
      if (isView) {
        presenter.loadMore();
      }
    },
  }));

  useEffect(() => {
    if (presenter.items.length === 0) {
      presenter.load();
    }
  }, []);

  useEffect(() => {
    let hasChanges = false;

    if (presenter.searchText.trim() !== Entities.catalogStore.searchText.trim()) {
      presenter.searchText = Entities.catalogStore.searchText;
      hasChanges = true;
    }

    if (!isEqualArrays(presenter.localeIds, Entities.userPreferences.localeIds, false)) {
      presenter.localeIds = Entities.userPreferences.localeIds;
      hasChanges = true;
    }

    if (hasChanges) {
      onCatalogReset();
      presenter.load();
    }
  }, [Entities.catalogStore.searchText, Entities.userPreferences.localeIds]);

  return presenter;
};
