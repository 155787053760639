import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { PayloadType } from 'APP/model/message/messageModel.types';

import { IItemMediaPresenter, useItemMediaPresenter } from './ItemMedia.presenter';
import styles from './ItemMedia.styles.m.scss';
import { TItemMediaPayloadType } from './ItemMedia.types';
import { MediaMessageFile } from './MediaMessageFile/MediaMessageFile';
import { MediaMessageImage } from './MediaMessageImage/MediaMessageImage';
import { MediaMessageVideo } from './MediaMessageVideo/MediaMessageVideo';

const MAP_COMPONENTS: Record<
  TItemMediaPayloadType,
  FC<{ presenter: IItemMediaPresenter; onRemove?(): void }>
> = {
  [PayloadType.Image]: MediaMessageImage,
  [PayloadType.File]: MediaMessageFile,
  [PayloadType.AudioMessage]: MediaMessageFile,
  [PayloadType.Video]: MediaMessageVideo,
};

export const ItemMedia = observer(({ payload, onRemove, className }) => {
  const presenter = useItemMediaPresenter({ payload, onRemove });

  const Component = MAP_COMPONENTS[presenter.payloadType];
  return (
    <div className={classNames(styles.container, className)}>
      <Component
        presenter={presenter}
        onRemove={onRemove}
      />
    </div>
  );
});
