import React, { FocusEvent, useMemo, ComponentProps, FC, useCallback } from 'react';

import Tasks from 'APP/Tasks';
import dateService from 'APP/packages/date';
import { TextField } from 'APP/packages/form/TextField/TextField';
import noop from 'UTILS/noop';

import { DateFieldCalendar } from './DateFieldCalendar/DateFieldCalendar';

interface IDateFieldProps extends Omit<ComponentProps<typeof TextField>, 'input'> {
  minDate?: Date;
  input: Omit<ComponentProps<typeof TextField>['input'], 'value' | 'onChange'> & {
    onChange: (value: Date) => void;
    value: Date;
  };
}

export const DateField: FC<IDateFieldProps> = ({ input, minDate, ...props }) => {
  const inputValue = useMemo(() => {
    const conf: { month: string; day: string; year?: string } = {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    };

    return dateService.format(input.value, conf) || '';
  }, [input.value]);

  const handleChooseDate = useCallback(
    (date: Date): void => {
      new Date().setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
      input.onChange(date);
    },
    [input]
  );

  const onFocus = useCallback(
    (e: FocusEvent<HTMLInputElement>): FocusEvent<HTMLInputElement> => {
      Tasks.app.openPopup(DateFieldCalendar, {
        params: { value: input.value, onChoose: handleChooseDate, minDate },
      });
      return e;
    },
    [input.value, handleChooseDate, minDate]
  );

  const onChangeInput = useCallback(noop, []);

  return (
    <TextField
      {...props}
      input={{ ...input, onChange: onChangeInput, onFocus, value: inputValue }}
    />
  );
};
