import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { ChangePhoneStep, useChangePhonePopupPresenter } from './ChangePhonePopup.presenter';
import styles from './ChangePhonePopup.styles.m.css';
import { PhoneNumberStep } from './PhoneNumberStep/PhoneNumberStep';
import { VerifyCodeStep } from './VerifyCodeStep/VerifyCodeStep';

export const ChangePhonePopup: FC<ICommonPopupsProps> = observer((props) => {
  const presenter = useChangePhonePopupPresenter(props);

  return (
    <Popup
      isOpened
      isHidden={props.popupInstance.isHidden}
      handlePrevStep={presenter.prevStep}
      title={presenter.title}
      onClose={presenter.close}
    >
      <div className={styles.container}>
        {presenter.step === ChangePhoneStep.PhoneNumber && (
          <PhoneNumberStep
            country={presenter.phoneStep.country}
            phoneNumber={presenter.phoneStep.phoneNumber}
            countries={presenter.countries}
            pristine={presenter.pristine}
            onSubmit={presenter.submitPhone}
            onCountryPopupOpen={presenter.hidePopup}
            onCountryPopupClose={presenter.showPopup}
          />
        )}
        {presenter.step === ChangePhoneStep.VerifySms && (
          <VerifyCodeStep
            phoneNumber={presenter.phoneStep.phoneNumber}
            onSubmit={presenter.submitVerifyCode}
          />
        )}
      </div>
    </Popup>
  );
});
