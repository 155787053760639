import { spaceModel } from 'APP/model/model';
import { SpaceUserRole } from 'APP/model/space/spaceModel.types';
import { spaceAnalytics } from 'APP/packages/analytics/analytics';
import Entities from 'STORE';

export async function changeSpaceOwnership(spaceId: string, newOwnerId: string): Promise<boolean> {
  try {
    const result = await spaceModel.changeOwnership(spaceId, newOwnerId);

    if (result) {
      const space = Entities.spacesStore.getById(spaceId);
      space?.setRole(SpaceUserRole.Admin);

      spaceAnalytics.trackSpaceTransferOwnership(spaceId, newOwnerId);
    }

    return result;
  } catch {
    return false;
  }
}
