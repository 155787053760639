import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import { Group } from 'APP/store/Groups/Group';
import Entities from 'STORE';

import { ICommonPopupsProps } from '../../PopupManager.types';

export interface IAddParticipantsPopupParams {
  groupId: string;
  onSuccess?(): void;
}

interface IAddParticipantsPopupPresenter {
  group: Group | null;

  onClose(): void;
  onBack(): void;
  onSuccess(): void;
}

export const useAddParticipantsPopupPresenter = ({
  popupInstance,
  onClose,
  onBack,
  params,
}: ICommonPopupsProps<IAddParticipantsPopupParams>): IAddParticipantsPopupPresenter => {
  const { groupId, onSuccess } = params!;

  const presenter = useLocalStore<IAddParticipantsPopupPresenter>(() => ({
    get group(): Group | null {
      return groupId ? Entities.GroupsStore.getGroupById(groupId) : null;
    },

    onClose(): void {
      popupInstance.close();
      onClose?.();
    },

    onBack(): void {
      popupInstance.close();
      onBack?.();
    },

    onSuccess(): void {
      popupInstance.close();
      onSuccess?.();
      onBack ? onBack() : onClose?.();
    },
  }));

  useEffect(() => {
    if ((!Entities.GroupsStore.isLoading && !presenter.group) || !presenter.group?.canInviteUsers) {
      popupInstance.close();

      onBack ? onBack() : onClose?.();
    }
  }, [presenter.group, presenter.group?.userGroupRole, Entities.GroupsStore.isLoading]);

  return presenter;
};
