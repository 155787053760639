import { observer } from 'mobx-react';
import React from 'react';

import { useTranslation } from 'APP/packages/translations';
import LinkIcon from 'ICONS/ic-link.svg';
import CopyIcon from 'ICONS/ic-s-copy.svg';
import { IconButton } from 'UIKIT/IconButton';

import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';
import { MetaBlock } from '../MetaBlock/MetaBlock';
import styles from './styles.m.css';

const InviteLink = ({ className, deepLink, metaBlockRef, Actions }) => {
  const { t } = useTranslation();
  const copyToClipboard = useCopyToClipboard(deepLink, t('common_link_copied'));

  return (
    <MetaBlock
      className={className}
      label={t('common_invite_link')}
      containerRef={metaBlockRef}
      Icon={LinkIcon}
      Actions={
        <>
          {Actions}
          <IconButton
            Icon={CopyIcon}
            onClick={copyToClipboard}
          />
        </>
      }
    >
      <button
        className={styles.link}
        onClick={copyToClipboard}
        data-test="invite-link"
      >
        {deepLink}
      </button>
    </MetaBlock>
  );
};

export default observer(InviteLink);
