import { BotButtonPayloadType } from 'APP/model/bot/botModel.constants';
import { IOpenUrlPayload } from 'APP/model/bot/botModel.types';

export class OpenUrlButtonPayload {
  type: BotButtonPayloadType;
  url: string;

  constructor(data: IOpenUrlPayload) {
    this.type = data.type;
    this.url = data.url;
  }

  toJSON(): IOpenUrlPayload {
    return {
      type: this.type,
      url: this.url,
    };
  }
}
