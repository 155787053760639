import { observer } from 'mobx-react';
import React from 'react';

import { IconButton, IconViewSizes } from 'APP/packages/UiKit/IconButton';
import ICPermissionRequest from 'ICONS/ic-permission-request.svg';

import { usePermissionRequestsButtonPresenter } from './PermissionRequestsButton.presenter';

export const PermissionRequestButton = observer(({ className }) => {
  const presenter = usePermissionRequestsButtonPresenter();

  return (
    <IconButton
      Icon={ICPermissionRequest}
      onClick={presenter.onClick}
      size={IconViewSizes.Big}
      theme="12"
      iconSize={IconViewSizes.Medium}
      className={className}
    />
  );
});
