import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { useTranslation } from 'APP/packages/translations';
import { PopupFooter } from 'UIKIT/PopupFooter/PopupFooter';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { ShareDataView } from '../../components/ShareDataView/ShareDataView';
import { useClosePopupOnNavigation } from '../../hooks/useClosePopupOnNavigation';
import { ForwardMessageFooterView } from './ForwardMessageFooterView/ForwardMessageFooterView';
import {
  IForwardMessagePopupParams,
  useForwardMessagePresenter,
} from './ForwardMessagePopup.presenter';
import styles from './ForwardMessagePopup.styles.m.css';

export const ForwardMessagePopup: FC<ICommonPopupsProps<IForwardMessagePopupParams>> = observer(
  (props) => {
    const { t } = useTranslation();
    const presenter = useForwardMessagePresenter(props);
    useClosePopupOnNavigation(props.popupInstance);

    return (
      <Popup
        isOpened={presenter.isAvailableToShow}
        title={t('forward_screen_title')}
        onClose={presenter.onClose}
      >
        <ShareDataView
          multiselect
          onSelectItem={presenter.onSelectItem}
          onChangeSelection={presenter.onChangeSelection}
        />
        <PopupFooter
          className={classNames(styles.forwardMessageFooter, {
            [styles.withInput]: !!presenter.selection.length,
          })}
        >
          <ForwardMessageFooterView
            selection={presenter.selection}
            isDisabled={!presenter.hasSelected}
            isLoading={presenter.isSubmitting}
            onSubmit={presenter.submit}
          />
        </PopupFooter>
      </Popup>
    );
  }
);
