import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { openPopup } from 'APP/Tasks/app/popup/popup';
import Entities from 'STORE';
import { Popup } from 'STORE/App/Popups/Popup/Popup';
import { Bot } from 'STORE/Users/User/Bot/Bot';

import { BotDetailedInfoPopup } from '../BotDetailedInfoPopup/BotDetailedInfoPopup';

interface IBotsCatalogPopupPresenter {
  childPopup: Popup | null;
  isLoading: boolean;
  isShowPlaceholder: boolean;
  isShowLoader: boolean;
  cursor: string | undefined;
  bots: Bot[];
  loadData(): Promise<void>;
  onLoadMore(isInView: boolean): void;
  onShowDetails(bot: Bot): void;
}

export function useBotsCatalogPopupPresenter(popupInstance: Popup): IBotsCatalogPopupPresenter {
  const presenter = useLocalStore<IBotsCatalogPopupPresenter>(() => ({
    childPopup: null,
    isLoading: false,
    cursor: undefined,
    bots: [],

    get isShowPlaceholder(): boolean {
      return this.isLoading && !this.bots.length;
    },

    get isShowLoader(): boolean {
      return this.isLoading && !!this.bots.length;
    },

    async loadData(): Promise<void> {
      presenter.isLoading = true;

      const { bots, cursor } = await Tasks.botsCatalog.getAllBots({
        cursor: presenter.cursor,
      });

      presenter.bots = [...presenter.bots, ...bots.map((bot) => new Bot(Entities.UsersStore, bot))];
      presenter.cursor = cursor;

      presenter.isLoading = false;
    },

    onLoadMore(isInView: boolean): void {
      if (isInView && presenter.cursor) {
        presenter.loadData();
      }
    },

    onShowDetails(bot: Bot): void {
      presenter.childPopup = openPopup(BotDetailedInfoPopup, {
        params: {
          bot,
          onOpenBot() {
            popupInstance.close();
          },
        },
      });
    },
  }));

  useEffect(() => {
    presenter.loadData();

    return () => presenter.childPopup?.close();
  }, []);

  return presenter;
}
