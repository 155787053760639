import { request } from 'APP/packages/api/request/request';
import { BooleanResponse } from 'APP/packages/api/types/model/booleanResponse';
import { CancelScheduledEventRequest } from 'APP/packages/api/types/model/cancelScheduledEventRequest';
import { CreateScheduledEventRequest } from 'APP/packages/api/types/model/createScheduledEventRequest';
import { GetInviteesRequest } from 'APP/packages/api/types/model/getInviteesRequest';
import { GetScheduledEventRequest } from 'APP/packages/api/types/model/getScheduledEventRequest';
import { GetScheduledEventsRequest } from 'APP/packages/api/types/model/getScheduledEventsRequest';
import { GetScheduledEventsResponse } from 'APP/packages/api/types/model/getScheduledEventsResponse';
import { InviteesResponse } from 'APP/packages/api/types/model/inviteesResponse';
import { ScheduledEventDetailsResponse } from 'APP/packages/api/types/model/scheduledEventDetailsResponse';

const ROOT_API = 'scheduledEvents';

export const getScheduledEvent = (
  data: GetScheduledEventRequest
): Promise<ScheduledEventDetailsResponse> => {
  return request({
    rootApi: ROOT_API,
    url: '/getEvent',
    method: 'POST',
    body: data,
  });
};

export const createScheduledEvent = (
  data: CreateScheduledEventRequest
): Promise<ScheduledEventDetailsResponse> => {
  return request({
    rootApi: ROOT_API,
    url: '/createEvent',
    method: 'POST',
    body: data,
  });
};

export const getScheduledEvents = (
  data: GetScheduledEventsRequest
): Promise<GetScheduledEventsResponse> => {
  return request({
    rootApi: ROOT_API,
    url: '/getEvents',
    method: 'POST',
    body: data,
  });
};

export const getScheduledEventInvitees = (data: GetInviteesRequest): Promise<InviteesResponse> => {
  return request({
    rootApi: ROOT_API,
    url: '/getInvitees',
    method: 'POST',
    body: data,
  });
};

export const cancelScheduledEvent = (
  data: CancelScheduledEventRequest
): Promise<BooleanResponse> => {
  return request({
    rootApi: ROOT_API,
    url: '/cancelEvent',
    method: 'POST',
    body: data,
  });
};
