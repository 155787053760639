import { useEffect } from 'react';

import {
  VIDEO_PLAYER_VOLUME_MAX,
  VIDEO_PLAYER_VOLUME_STEP,
  VideoPlayerType,
} from '../VideoPlayer.constants';

const ACTION_TO_KEY_CODE_MAP = {
  FullScreen: 'KeyF',
  MiniPlayer: 'KeyI',
  Mute: 'KeyM',
  Play: 'Space',
  DecreaseVolume: 'ArrowDown',
  IncreaseVolume: 'ArrowUp',
};

const isInputFocused = () => {
  const activeElement = document.activeElement;

  return activeElement?.isContentEditable || activeElement?.tagName === 'INPUT';
};

const isButtonFocused = () => {
  return document.activeElement?.tagName === 'BUTTON';
};

export function useVideoPlayerHotKeys({
  url,
  type,
  toggleFullScreenMode,
  onShowMiniPlayer,
  onExpandMiniPlayer,
  toggleMute,
  togglePlay,
  volume,
  setVolume,
}) {
  useEffect(() => {
    const onKeyDown = (event) => {
      if (isInputFocused()) {
        return;
      }

      switch (event.code) {
        case ACTION_TO_KEY_CODE_MAP.FullScreen:
          if (!event.repeat && type === VideoPlayerType.Default) {
            toggleFullScreenMode();
          }
          break;
        case ACTION_TO_KEY_CODE_MAP.MiniPlayer:
          if (event.repeat) {
            return;
          }

          if (type === VideoPlayerType.Default && onShowMiniPlayer) {
            onShowMiniPlayer();
          } else if (type === VideoPlayerType.Mini && onExpandMiniPlayer) {
            onExpandMiniPlayer();
          }
          break;
        case ACTION_TO_KEY_CODE_MAP.Mute:
          if (!event.repeat) {
            toggleMute();
          }
          break;
        case ACTION_TO_KEY_CODE_MAP.Play:
          if (!event.repeat && !isButtonFocused()) {
            togglePlay();
          }
          break;
        case ACTION_TO_KEY_CODE_MAP.DecreaseVolume:
          if (event.shiftKey) {
            const newVolume = volume - VIDEO_PLAYER_VOLUME_STEP;
            setVolume(newVolume > 0 ? newVolume : 0);
          }
          break;
        case ACTION_TO_KEY_CODE_MAP.IncreaseVolume:
          if (event.shiftKey) {
            const newVolume = volume + VIDEO_PLAYER_VOLUME_STEP;
            setVolume(newVolume < VIDEO_PLAYER_VOLUME_MAX ? newVolume : VIDEO_PLAYER_VOLUME_MAX);
          }
          break;
      }
    };

    if (url) {
      document.addEventListener('keydown', onKeyDown);
    }

    return () => {
      if (url) {
        document.removeEventListener('keydown', onKeyDown);
      }
    };
  }, [
    url,
    type,
    toggleFullScreenMode,
    onShowMiniPlayer,
    onExpandMiniPlayer,
    toggleMute,
    volume,
    setVolume,
  ]);
}
