import { authModel } from 'APP/model/model';

export const changePhoneAbortConfirmation = async (): Promise<{ error: string | null }> => {
  try {
    await authModel.changePhoneAbortConfirmation();
    return { error: null };
  } catch (error) {
    return { error: error.message };
  }
};
