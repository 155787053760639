interface ISseConfig {
  apiUrl?: string;
  token?: string | null;
  version?: string;
}

export const sseConfig: ISseConfig = {
  apiUrl: '',
  token: null,
  version: 'v3',
};

export function setSseConfig({ apiUrl, token }: ISseConfig): void {
  if (apiUrl !== undefined) {
    sseConfig.apiUrl = apiUrl;
  }

  if (token !== undefined) {
    sseConfig.token = token;
  }
}
