import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { useTranslation } from 'APP/packages/translations';
import DeleteIcon from 'ICONS/ic-delete-16.svg';

import { CommonPayload } from '../CommonPayload/CommonPayload';
import styles from './DeletedByMePayload.styles.m.css';

interface IDeleteByMePayloadProps {
  name: string;
}

export const DeletedByMePayload: FC<IDeleteByMePayloadProps> = observer(({ name }) => {
  const { t } = useTranslation();

  return (
    <CommonPayload
      name={name}
      description={t('common_message_deleted')}
      iconClassName={styles.icon}
      Icon={DeleteIcon}
    />
  );
});
