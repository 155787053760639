import Entities from 'STORE';

import { GroupsEvent } from '../GroupsEvent';

export class DraftInputWasUpdated extends GroupsEvent {
  process() {
    const { groupId, draftInput, updateTs } = this.eventPayload;

    const group = Entities.GroupsStore.getGroupById(groupId.toString());

    if (!group || (group.draftInput && group.draftInput.updateTs >= updateTs)) {
      return;
    }

    group.setDraftInput({
      groupId,
      updateTs,
      text: draftInput,
    });
  }
}
