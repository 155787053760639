import { PayloadType } from 'APP/model/message/messageModel.types';
import {
  getFixedSizeBlurredImage,
  getFullScreenImage,
  getImageByLargestSize,
} from 'APP/packages/imageResizer';
import { t } from 'MODULE/i18n/service';

import File from '../File';

class Image extends File {
  graphicSize;
  stretchedByWidth;

  static MIN_IMAGE_MESSAGE_WIDTH = 100;
  static MIN_IMAGE_MESSAGE_HEIGHT = 40;

  static MIN_FORWARD_IMAGE_MESSAGE_WIDTH = 200;

  constructor(data) {
    super(data);

    this.graphicSize = data.graphicSize || {};
    this.localPreview = data.localPreview;
    this.stretchedByWidth = data.stretchedByWidth;
  }

  toJSON() {
    const data = super.toJSON();

    return {
      ...data,
      payloadType: PayloadType.Image,
      graphicSize: this.graphicSize,
      stretchedByWidth: this.stretchedByWidth,
    };
  }

  get originUrl() {
    return this.localPreview || getFullScreenImage(this.url);
  }

  get placeholderUrl() {
    return this.localPreview
      ? null
      : getFixedSizeBlurredImage(
          this.url,
          {
            width: File.PREVIEW_MAX_WIDTH,
            height: 0, //scaled by width
          },
          this.graphicSize
        );
  }

  get hasPlaceholder() {
    return Boolean(this.placeholderUrl);
  }

  get previewUrl() {
    return this.localPreview || getImageByLargestSize(this.url, File.PREVIEW_MAX_WIDTH);
  }

  get previewSize() {
    const { width, height } = this.graphicSize;

    if (!width || !height) {
      return { width, height };
    }
    return File.calculatePreviewSize(
      width,
      height,
      File.PREVIEW_MAX_WIDTH,
      File.PREVIEW_MAX_HEIGHT
    );
  }

  get typeDescription() {
    return t('message_desctription_picture');
  }
}

export default Image;
