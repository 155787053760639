import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { Prompt } from 'react-router-dom';

import { useTranslation } from 'APP/packages/translations';
import { InputPanelButtons } from 'MAIN/ChatView/ChatFooter/InputPanel/InputPanelButtons/InputPanelButtons';

import AttachmentsButton from './AttachmentsButton';
import BotCommandsList from './BotCommandsList';
import { CancelVoiceButton } from './CancelVoiceButton/CancelVoiceButton';
import { EditMessage } from './EditMessage/EditMessage';
import { useInputPanelPresenter } from './InputPanel.presenter';
import styles from './InputPanel.styles.m.css';
import { InputSection } from './InputSection/InputSection';
import LinkPreview from './LinkPreview';
import MentionList from './MentionList';
import QuotedMessage from './QuotedMessage';
import { PreviewRecord } from './RecordingSection/PreviewRecord/PreviewRecord';
import ProgressRecord from './RecordingSection/ProgressRecord';

export const InputPanel: FC = observer(() => {
  const presenter = useInputPanelPresenter();
  const { t } = useTranslation();

  return (
    <>
      {presenter.hasBotCommands && <BotCommandsList />}
      <MentionList />
      <LinkPreview />
      <QuotedMessage className={styles.topPanel} />
      <EditMessage className={styles.topPanel} />
      <div className={styles.container}>
        {presenter.inputPanel.voice.isRecording && (
          <>
            <CancelVoiceButton />
            <ProgressRecord className={styles.view} />
          </>
        )}
        {presenter.inputPanel.voice.isPreviewMode && (
          <>
            <CancelVoiceButton />
            <PreviewRecord className={styles.view} />
          </>
        )}

        {presenter.inputPanel.voice.isRecording && (
          <Prompt
            when={true}
            message={t('common_voice_message_cancel_confirmation')}
          />
        )}
        {presenter.inputPanel.showInputPanel && (
          <>
            <AttachmentsButton />
            <InputSection className={styles.view} />
          </>
        )}
        <InputPanelButtons />
      </div>
    </>
  );
});
