import React, { FC, memo } from 'react';

import MapMarkerIcon from 'ICONS/map-marker.svg';

import styles from './MapMarker.styles.m.css';

interface IMapMarkerProps {
  lat: number | null;
  lng: number | null;
}

export const MapMarker: FC<IMapMarkerProps> = memo(() => {
  return <MapMarkerIcon className={styles.marker} />;
});
