import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { GroupAvatar } from 'APP/components/Avatar/GroupAvatar/GroupAvatar';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';
import { IconView } from 'UIKIT/IconView/IconView';
import { MenuItem } from 'UIKIT/MenuItem/MenuItem';

import { ProfileDescription } from '../components/ProfileDescription/ProfileDescription';
import { ProfileNickname } from '../components/ProfileNickname/ProfileNickname';
import { useBotProfilePresenter } from './BotProfile.presenter';
import styles from './BotProfile.styles.m.css';

export const BotProfile = observer(({ className, groupId, actions }) => {
  const presenter = useBotProfilePresenter({ groupId, actions });

  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.header}>
        <GroupAvatar
          className={styles.avatar}
          group={presenter.group}
          type={AvatarTypes.Round_20}
          withFullScreen={true}
        />
        <div className={styles.info}>
          <h3 className={styles.title}>{presenter.title}</h3>
          <p className={styles.subtitle}>{presenter.subtitle}</p>
        </div>
      </div>
      {presenter.description && (
        <ProfileDescription
          className={styles.contact}
          groupId={groupId}
          mentions={presenter.group.descriptionMentions}
          description={presenter.description}
        />
      )}
      {presenter.bot && (
        <ProfileNickname
          className={styles.contact}
          user={presenter.bot}
        />
      )}
      {presenter.childPagesList.length > 0 && <hr className={styles.dividerBackground} />}
      {presenter.childPagesList.map(({ key, title, Icon, onClick, count }) => (
        <MenuItem
          className={styles.listItem}
          titleClassName={styles.listItemTitle}
          key={key}
          title={title}
          count={count}
          icon={
            <IconView
              Icon={Icon}
              className={styles.listIcon}
            />
          }
          onClick={onClick}
        />
      ))}
      <hr className={styles.dividerBackground} />
      {presenter.actionItems.map(({ key, title, Icon, onClick }) => (
        <MenuItem
          className={styles.listItem}
          titleClassName={styles.listItemTitle}
          key={key}
          title={title}
          icon={
            <IconView
              Icon={Icon}
              className={styles.listIcon}
            />
          }
          onClick={onClick}
        />
      ))}
    </div>
  );
});
