import { observer } from 'mobx-react';
import React from 'react';

import { GroupAvatar } from 'APP/components/Avatar/GroupAvatar/GroupAvatar';
import { VerifiedBadge } from 'APP/components/VerifiedBadge/VerifiedBadge';
import { useTranslation } from 'APP/packages/translations';
import GroupStatus from 'MAIN/components/GroupStatus';

import OfflineStatus from '../components/OfflineStatus';
import styles from './styles.m.css';

const GroupChatInfo = ({ group }) => {
  const { t } = useTranslation();

  return (
    <>
      <GroupAvatar group={group} />
      <div className={styles.content}>
        <div className={styles.titleWrapper}>
          <span className={styles.title}>{group.avatarTitle}</span>
          {group.verified && <VerifiedBadge />}
        </div>
        <OfflineStatus>
          <GroupStatus group={group}>
            <span className={styles.membersCount}>
              {t('amount_of_members_in_a_group_many').replace('{0}', group.usersCount)}
            </span>
          </GroupStatus>
        </OfflineStatus>
      </div>
    </>
  );
};

export default observer(GroupChatInfo);
