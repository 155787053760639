import classNames from 'classnames';
import React, { FC } from 'react';

import ArrowBottomIcon from 'ICONS/ic-arrow_back-16.svg';

import styles from './AllDownloadsIcon.styles.m.css';

interface IAllDownloadsIconProps {
  progress?: number;
}

export const AllDownloadsIcon: FC<IAllDownloadsIconProps> = (props) => {
  const { progress = 0 } = props;

  return (
    <div className={styles.container}>
      <div className={styles.iconWrapper}>
        <ArrowBottomIcon className={styles.icon} />
      </div>
      <div className={classNames(styles.bar, { [styles.downloadEnded]: progress === 100 })}>
        <div
          className={styles.progressBar}
          style={{ width: progress < 100 ? `${progress}%` : 0 }}
        />
      </div>
    </div>
  );
};
