import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { AudioSourceType } from 'APP/constants/app';

import { IMiniPlayerProps, TTogglePlayHandler } from '../MiniPlayer.types';

interface IMiniAudioPlayer {
  removeSource: (() => void) | null;
  onTogglePlay: TTogglePlayHandler;
  onRemoveAudioSource(): void;
}

export function useMiniAudioPlayerPresenter(data: IMiniPlayerProps): IMiniAudioPlayer {
  const { onTogglePlay } = data;

  const presenter = useLocalStore<IMiniAudioPlayer>(() => ({
    removeSource: null,

    onTogglePlay(isPlaying: boolean): void {
      onTogglePlay(isPlaying);

      if (isPlaying && !presenter.removeSource) {
        presenter.removeSource = Tasks.app.audioSource.setCurrentSource(AudioSourceType.Audio, () =>
          presenter.onTogglePlay(false)
        );
      }

      if (!isPlaying) {
        presenter.onRemoveAudioSource();
      }
    },

    onRemoveAudioSource(): void {
      if (presenter.removeSource) {
        presenter.removeSource();
        presenter.removeSource = null;
      }
    },
  }));

  useEffect(() => {
    return () => {
      presenter.onRemoveAudioSource();
    };
  }, []);

  return presenter;
}
