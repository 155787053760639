import { useLocalStore } from 'mobx-react';

import { PayloadType } from 'APP/model/message/messageModel.types';
import { replaceHtmlTagsToSpecialSymbols } from 'UTILS/html/replaceHtmlTagsToSpecialSymbols';

import { MAX_TITLE_LENGTH } from '../constants';
import services from '../services';
import getActualSortedOrder from '../services/payloads/getActualSortedOrder';

export default () => {
  const presenter = useLocalStore(() => ({
    data: [],
    title: '',
    isChanged: false,
    somethingLoading: new Set(),
    payloadCreatedText: '',

    setData: (data) => {
      presenter.data = data;
      presenter.isChanged = true;
    },

    setTitle: (value) => {
      presenter.title = value;
      presenter.isChanged = true;
    },

    setPayloadCreatedText: (value) => {
      presenter.payloadCreatedText = value;
      presenter.isChanged = true;
    },

    addleSomethingLoading: (id) => {
      presenter.somethingLoading.add(id);
    },

    removeSomethingLoading: (id) => {
      presenter.somethingLoading.delete(id);
    },

    loadData: (message) => {
      if (message) {
        presenter.data = message.payload.payloads.map((payload, index) => {
          return { ...payload, order: index };
        });
        presenter.title = message.payload.title;
      }
    },

    getFinalPayloads: ({ isEditMode, isDraft }) => {
      let payloads = presenter.data;
      let requestField = 'data';

      payloads = payloads.filter(({ id }) => !presenter.somethingLoading.has(id));
      payloads = getActualSortedOrder(payloads);

      if (presenter.payloadCreatedText) {
        payloads = services.converter.getPayloadWithCreatedText(
          payloads,
          presenter.payloadCreatedText
        );
      }

      if (isEditMode && !isDraft) {
        payloads = services.converter.articleSystemToEditMode(payloads);
        requestField = 'payload';
      }

      return payloads.map((payload) => {
        return {
          ...payload,
          [requestField]: services.payloads.replaceHtmlTags(payload[requestField]),
        };
      });
    },

    get somethingTextPayload() {
      const payloadCreatedText = replaceHtmlTagsToSpecialSymbols(
        presenter.payloadCreatedText
      ).trim();

      if (payloadCreatedText.length && payloadCreatedText !== '') {
        return true;
      }

      return presenter.data.some((payload) => {
        const { type, data } = payload;

        if (type === PayloadType.RichText) {
          const text = replaceHtmlTagsToSpecialSymbols(data.text).trim();

          return text.length && text !== '';
        }

        return false;
      });
    },

    get isTitleValid() {
      return presenter.title.trim().length && presenter.title.length <= MAX_TITLE_LENGTH;
    },
  }));

  return presenter;
};
