import { ICountry } from 'APP/types/countryCodes';

const PRIORITY_COUNTRIES = ['US', 'RU', 'IT', 'GB', 'NO', 'AU', 'MA', 'FI', 'GP', 'CW'];

export const sortCountriesByPriority = (a: ICountry, b: ICountry) => {
  if (PRIORITY_COUNTRIES.includes(a.countryCode)) {
    return -1;
  }

  if (PRIORITY_COUNTRIES.includes(b.countryCode)) {
    return 1;
  }

  return 0;
};
