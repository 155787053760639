import { useLocalStore } from 'mobx-react';

import Entities from 'APP/store';
import { User } from 'STORE/Users/User/User';

export interface IGroupFormMembersPresenter {
  searchValue: string;
  candidates: User[];
  isLoading: boolean;
  searchContacts: User[];
  setSearchValue(value: string): void;
}

export const useGroupFormMembersPresenter = (): IGroupFormMembersPresenter => {
  const presenter = useLocalStore<IGroupFormMembersPresenter>(() => ({
    searchValue: '',

    get candidates(): User[] {
      // Todo Remove convertation "as unknown[] as User[]" after user store refactoring
      return Entities.UsersStore.contacts as unknown[] as User[];
    },

    get isLoading(): boolean {
      return Entities.UsersStore.isUserContactsLoading;
    },

    get searchContacts(): User[] {
      // Todo Remove convertation "as unknown[] as User[]" after user store refactoring
      return Entities.UsersStore.searchContactsByString(
        presenter.searchValue
      ) as unknown[] as User[];
    },

    setSearchValue(value: string): void {
      presenter.searchValue = value;
    },
  }));

  return presenter;
};
