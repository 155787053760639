import { observer } from 'mobx-react';
import React from 'react';

import { useImageResizer } from 'APP/main/hooks/useImageResizer/useImageResizer';
import { PayloadType } from 'APP/model/message/messageModel.types';

import styles from './styles.m.css';

const MAX_WIDTH = 440;
const MAX_HEIGHT = 324;

const Media = ({ payload }) => {
  const { graphicSize, previewUrl, url, payloadType } = payload;
  const imageUrl = payloadType === PayloadType.Video ? previewUrl : url;
  const presenter = useImageResizer({
    url: imageUrl,
    graphicSize: graphicSize,
    maxWidth: MAX_WIDTH,
    maxHeight: MAX_HEIGHT,
  });

  return (
    <div
      className={styles.mediaContainer}
      style={presenter.containerStyle}
    >
      <img
        className={styles.image}
        src={presenter.url}
        alt=""
      />
    </div>
  );
};

export default observer(Media);
