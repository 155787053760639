import { spaceModel } from 'APP/model/model';
import { IPinSpaceResponse } from 'APP/model/space/spaceModel.types';
import { spaceAnalytics } from 'APP/packages/analytics/analytics';
import Entities from 'STORE';

export async function pinSpaces(spaceIds: string[]): Promise<void> {
  let maxPinOrder = Entities.spacesStore.maxPinOrder;

  // Optimistic UI
  [...spaceIds].reverse().forEach((spaceId) => {
    const space = Entities.spacesStore.getById(spaceId);

    if (space) {
      space.pinOrder = ++maxPinOrder;
    }
  });

  try {
    const result = await spaceModel.pinSpaces(spaceIds);
    updateSpacesPinOrder(result);

    spaceIds.forEach((spaceId) => spaceAnalytics.trackSpacePinned(spaceId));
  } catch {
    // Optimistic UI fallback
    spaceIds.forEach((spaceId) => {
      const space = Entities.spacesStore.getById(spaceId);

      if (space) {
        space.pinOrder = 0;
      }
    });
  }
}

export async function unpinSpaces(spaceIds: string[]): Promise<void> {
  const spaceOrderMap: Record<string, number> = {};

  // Optimistic UI
  spaceIds.forEach((spaceId) => {
    const space = Entities.spacesStore.getById(spaceId);

    if (space) {
      spaceOrderMap[spaceId] = space.pinOrder;
      space.pinOrder = 0;
    }
  });

  try {
    const result = await spaceModel.unpinSpaces(spaceIds);
    updateSpacesPinOrder(result);

    spaceIds.forEach((spaceId) => spaceAnalytics.trackSpaceUnpined(spaceId));
  } catch {
    // Optimistic UI fallback
    Object.keys(spaceOrderMap).forEach((spaceId) => {
      const space = Entities.spacesStore.getById(spaceId);

      if (space) {
        space.pinOrder = spaceOrderMap[spaceId];
      }
    });
  }
}

export function updateSpacesPinOrder(pinOrders: IPinSpaceResponse[]): void {
  const pinMap = pinOrders.reduce((result, item) => {
    result[item.spaceId] = item.pinOrder;
    return result;
  }, {} as Record<string, number>);

  Entities.spacesStore.spaces.forEach((space) => {
    space.pinOrder = pinMap[space.id] || 0;
  });
}
