import { TFunction } from 'react-i18next';

import {
  TSystemMessageTextHandler,
  ISystemMessageText,
  ISystemMessageTextPresenter,
} from '../useSystemMessageText.types';

export const threadNewlyCreatedWelcomeHandler: TSystemMessageTextHandler = (
  presenter: ISystemMessageTextPresenter,
  t: TFunction
): ISystemMessageText => {
  const result: ISystemMessageText = { html: '' };

  result.html = t('comments_status_discussion_started');

  return result;
};
