import { loadUnknownUsersByIds } from 'APP/Tasks/relationships/loadUnknownUsersByIds';
import { GroupUserRole } from 'APP/model/group/groupModel.types';
import { groupModel, searchModel } from 'APP/model/model';
import { User } from 'APP/store/Users/User/User';
import Entities from 'STORE';

interface IParticipantsParams {
  groupId: string;
  searchText?: string;
  cursor?: string;
  limit?: number;
}

interface IParticipantsData {
  readonly participants: IGroupMember[];
  readonly cursor: string | null;
}

export interface IGroupMember {
  user: User;
  role: GroupUserRole;
}

export async function getParticipants({
  groupId,
  searchText,
  cursor,
  limit,
}: IParticipantsParams): Promise<IParticipantsData | null> {
  try {
    const response = searchText
      ? await searchModel.searchUserInGroup({ groupId, searchQuery: searchText, cursor, limit })
      : await groupModel.getGroupUserRoles({ groupId, cursor, limit });

    const loadUsersIds = response.participants.reduce((userIds: string[], userRole) => {
      if (!Entities.UsersStore.getUserById(userRole.userId)) {
        userIds.push(userRole.userId);
      }

      return userIds;
    }, []);

    if (loadUsersIds.length) {
      await loadUnknownUsersByIds({ usersIds: loadUsersIds });
    }

    const participants = response.participants.reduce((users: IGroupMember[], userRole) => {
      const user = Entities.UsersStore.getUserById(userRole.userId);

      if (user) {
        users.push({
          user,
          role: userRole.role as unknown as GroupUserRole,
        });
      }

      return users;
    }, []);

    return {
      participants,
      cursor: response.cursor || null,
    };
  } catch {
    return null;
  }
}
