import React from 'react';

import IcContact16 from 'ICONS/ic-contact-16.svg';

import { MiniatureMessageLayout } from '../components/MiniatureMessageLayout/MiniatureMessageLayout';

const Contact = ({ titleClassName, textClassName, iconClassName, message, title, onClick }) => {
  return (
    <MiniatureMessageLayout
      titleClassName={titleClassName}
      textClassName={textClassName}
      iconClassName={iconClassName}
      Icon={IcContact16}
      title={title}
      text={message.payload.typeDescription}
      messageId={message.id}
      onClick={onClick}
    />
  );
};

export default Contact;
