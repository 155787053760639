import useUserOnlineStatusText from 'MAIN/hooks/userOnlineStatusText';

import { ISharedDataViewItemData } from '../ShareDataView.types';

interface ISharedDataContactItemPresenter {
  userOnlineStatusText: string;
}

export const useSharedDataContactItemPresenter = (
  data: ISharedDataViewItemData
): ISharedDataContactItemPresenter => {
  const userOnlineStatusText = useUserOnlineStatusText({ user: data });

  return {
    userOnlineStatusText,
  };
};
