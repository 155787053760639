import { clearGetParameters as clearGetParametersUntil } from 'UTILS/clearGetParameters';

import { getHistory, getLocation, setLocation } from './index';

export const clearGetParameters = (fields = []) => {
  const history = getHistory();
  const location = getLocation();

  if (history && location) {
    history.push(clearGetParametersUntil({ location, fields }));
    setLocation(history.location);
  }
};
