import { computed } from 'mobx';

import { IGroupData } from 'APP/model/group/groupModel.types';
import { t } from 'MODULE/i18n/service';
import { Root } from 'STORE';
import { Groups } from 'STORE/Groups';

import { Group } from '../Group';

const DEFAULT_AVATAR = `data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48' fill='none'%3E%3Cpath fill='url(%23pattern0)' d='M48 24c0 13.255-10.745 24-24 24S0 37.255 0 24 10.745 0 24 0s24 10.745 24 24Z'/%3E%3Cpath fill='%23FF4D4D' d='M48 24c0 13.255-10.745 24-24 24S0 37.255 0 24 10.745 0 24 0s24 10.745 24 24Z'/%3E%3Cpath fill='%23fff' d='M16.955 14.995a3.32 3.32 0 0 1 2.37-.995h9.35c.9 0 1.75.364 2.37.995.617.629.955 1.47.955 2.338v15.238c0 .54-.31 1.034-.8 1.276a1.477 1.477 0 0 1-1.52-.128L24 29.587l-5.68 4.132c-.441.322-1.03.371-1.52.128-.49-.242-.8-.736-.8-1.276V17.333c0-.867.338-1.71.955-2.338Z'/%3E%3C/svg%3E`;

export class SavedMessages extends Group {
  constructor(group: IGroupData, groups: Groups, root: Root) {
    super(group, groups, root);
    this.name = t('saved_message_chat_tittle') as string;
    this.description = t('saved_messages_chat_description_web') as string;
    this.muted = false;
    this._avatarUrl = DEFAULT_AVATAR;
  }

  @computed
  get canInviteUsers(): boolean {
    return false;
  }

  @computed
  get avatarUrl(): string | null {
    return this._avatarUrl;
  }

  set avatarUrl(_: string | null) {
    this._avatarUrl = DEFAULT_AVATAR;
  }

  get isSavedMessages(): boolean {
    return true;
  }

  get notificationsAvailable(): boolean {
    return false;
  }

  @computed
  get avatarTitle() {
    return t('saved_message_chat_tittle');
  }

  get canMentionAll(): boolean {
    return false;
  }

  get canBeScheduled(): boolean {
    return true;
  }

  @computed
  get canLeaveComment(): boolean {
    return false;
  }
}
