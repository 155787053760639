import logger from 'APP/packages/logger';
import { platformInfo } from 'APP/packages/platformInfo/platformInfo';

export default async () => {
  if (!platformInfo.isElectron && 'serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register(`/service-worker.js?&version=${window.app.version}`)
        .then((registration) => {
          logger.get('MAIN').log('SW registered: ', registration);
        })
        .catch((registrationError) => {
          logger.get('MAIN').error('SW registration failed: ', registrationError);
        });
    });
  }

  return Promise.resolve();
};
