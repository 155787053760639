import { observer } from 'mobx-react';
import React from 'react';

import { LinkRenderer } from 'APP/components/TextRenderer/renderTree/LinkRenderer/LinkRenderer';
import LinkIcon from 'APP/icons/ic-link.svg';

import styles from './LinkItem.m.css';
import { useLinkItemPresenter } from './LinkItem.presenter';

export const LinkItem = observer(({ payload, links }) => {
  const presenter = useLinkItemPresenter({ payload, links });

  return (
    <div className={styles.container}>
      {presenter.link.imageUrl ? (
        <div className={styles.previewImage}>
          <LinkRenderer options={{ link: presenter.link.links[0] }}>
            {
              <img
                className={styles.image}
                src={presenter.link.imageUrl}
                alt=""
              />
            }
          </LinkRenderer>
        </div>
      ) : (
        <div className={styles.previewImage}>
          <LinkIcon className={styles.icon} />
        </div>
      )}
      <div className={styles.info}>
        <h6 className={styles.title}>{presenter.link.title}</h6>
        {presenter.link.description && (
          <p className={styles.description}>{presenter.link.description}</p>
        )}
        <ul className={styles.list}>
          {presenter.link.links.map((link) => (
            <li
              key={link}
              className={styles.listItem}
            >
              <LinkRenderer options={{ link: link }}>{link}</LinkRenderer>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
});
