import { useAsObservableSource, useLocalStore } from 'mobx-react';
import React from 'react';

import Tasks from 'APP/Tasks';
import { showAlert } from 'APP/Tasks/app/app';
import { showToast } from 'APP/Tasks/app/showToast/showToast';
import { ServerErrorCode } from 'APP/packages/api';
import { GroupServerErrorCode } from 'APP/packages/api/constants/group.errors';
import { useTranslation } from 'APP/packages/translations';
import UserBanIcon from 'ICONS/ic-user-ban.svg';
import { useUserProfile } from 'MAIN/hooks/userProfile/useUserProfile';
import type { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';
import { Avatar } from 'UIKIT/Avatar/Avatar';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';
import { IPopoverMenuItem } from 'UIKIT/PopoverMenu/PopoverMenu.types';

const knownError: Record<string, string> = {
  [ServerErrorCode.HasNoPermissionsToPerformAction]: 'no_permission_alert',
  [ServerErrorCode.HasNoPermissionToRemoveSpaceOwner]: 'space_admin_kick_owner_alert',
};

interface ISenderPopoverMenuPresenterParams {
  message: ChatMessage;
  senderId: string;
}

interface ISenderPopoverMenuPresenter {
  onShowProfile(): void;
  banParticipant(): void;
  menuItems: IPopoverMenuItem[];
}

export const useSenderPopoverMenuForAdminPresenter = ({
  message,
  senderId,
}: ISenderPopoverMenuPresenterParams): ISenderPopoverMenuPresenter => {
  const { t } = useTranslation();
  const source = useAsObservableSource({ message, senderId });
  const userProfile = useUserProfile(senderId, { groupId: message.groupId, messageId: message.id });

  const presenter = useLocalStore<ISenderPopoverMenuPresenter>(() => ({
    get menuItems(): IPopoverMenuItem[] {
      return [
        {
          key: 'user-profile',
          title: source.message.avatarTitle,
          onClick: presenter.onShowProfile,
          customIcon: (
            <Avatar
              type={AvatarTypes.Round_0}
              url={source.message.avatarUrl}
              title={source.message.avatarTitle}
              backgroundColorIndex={source.message.avatarColorIndex || '0'}
            />
          ),
          isVisible: true,
        },
        {
          key: 'ban',
          title: t('ban_user'),
          onClick: presenter.banParticipant,
          icon: UserBanIcon,
          isVisible: true,
        },
      ].filter((item) => item.isVisible);
    },

    onShowProfile(): void {
      userProfile.show();
    },

    async banParticipant(): Promise<void> {
      const { isConfirmed, isChecked: deleteAllMessages } = await Tasks.app.showConfirmWithCheckbox(
        {
          text: t('ban_user_alert', { 0: source.message.avatarTitle }),
          checkBoxText: t('checkbox_delete_messages'),
        }
      );

      if (!isConfirmed) {
        return;
      }

      const { error } = await Tasks.group.banParticipants(
        source.message.groupId,
        [source.senderId],
        deleteAllMessages
      );

      if (error) {
        if (error === GroupServerErrorCode.UserIsBannedInTheGroup) {
          return;
        }

        showAlert(t(knownError[error] || 'something_went_wrong'));
        return;
      }

      deleteAllMessages
        ? showToast(t('delete_banned_user_messages_snackbar'))
        : showToast(
            t('common_user_banned_snackbar', {
              0: source.message.senderUser?.displayName || '',
            })
          );
    },
  }));

  return presenter;
};
