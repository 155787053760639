import { catalogModel } from 'APP/model/model';
import Entities from 'STORE';
import { Channel } from 'STORE/Groups/Channel';

export async function getAvailableChannels(): Promise<Channel[]> {
  try {
    const groups = await catalogModel.getChannelsStartPage();
    return groups.map((group) => new Channel(group, Entities.GroupsStore, Entities));
  } catch {
    return [];
  }
}
