import { observer } from 'mobx-react';
import React from 'react';

import { IconButton } from 'APP/packages/UiKit/IconButton';
import { AddKeyPressHandler } from 'APP/packages/keyBoardHandler';
import { useTranslation } from 'APP/packages/translations';
import CloseIcon from 'ICONS/ic-close.svg';
import DeleteIcon from 'ICONS/ic-delete.svg';
import { Button } from 'UIKIT/Button/Button';

import { useDraftHeaderPresenter } from './DraftHeader.presenter';
import styles from './styles.m.css';

const DraftHeader = ({ core }) => {
  const presenter = useDraftHeaderPresenter({ core });
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.leftBlock}>
        <IconButton
          className={styles.icon}
          Icon={CloseIcon}
          onClick={presenter.onCloseDraft}
        />
        <AddKeyPressHandler onEsc={presenter.onCloseDraft} />
        <div className={styles.title}>{t('edit_article_draft')}</div>
      </div>
      <div className={styles.rightBlock}>
        <IconButton
          className={styles.deleteIcon}
          Icon={DeleteIcon}
          onClick={presenter.deleteDraft}
        />
        <Button
          onClick={presenter.onClick}
          isDisabled={core.isDisabled}
          theme="1"
        >
          {t('channels:channel_article_publish')}
        </Button>
      </div>
    </div>
  );
};

export default observer(DraftHeader);
