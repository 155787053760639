import { observer } from 'mobx-react';
import React from 'react';

import { useTranslation } from 'APP/packages/translations';

import { DefaultDescription } from './DefaultDescription/DefaultDescription';
import { DraftMessageDescription } from './DraftMessageDescription/DraftMessageDescription';
import { useGroupDescriptionPresenter } from './GroupDescription.presenter';
import { LastMessageDescription } from './LastMessageDescription/LastMessageDescription';

export const GroupDescription = observer(({ group }) => {
  const { t } = useTranslation();

  const presenter = useGroupDescriptionPresenter(group);

  if (group.isShowDraft) {
    return <DraftMessageDescription text={presenter.draftText} />;
  }

  if (!presenter.lastMessage) {
    return <DefaultDescription text={t('chat_list_last_no_messages')} />;
  }
  return (
    <LastMessageDescription
      name={presenter.sender}
      group={group}
      payload={presenter.payload}
    />
  );

  return null;
});
