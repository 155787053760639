import * as app from './app/app';
import * as appearance from './appearance/appearance';
import * as authorization from './authorization/authorization';
import * as botsCatalog from './botsCatalog/botsCatalog';
import * as cache from './cache/cache';
import calls from './calls';
import * as countriesPhoneCodes from './countriesPhoneCodes';
import deeplinks from './deeplinks';
import { download, downloadMessageFile } from './download/download';
import drafts from './drafts';
import feedback from './feedback';
import * as forward from './forward';
import * as globalVideoPlayer from './globalVideoPlayer/globalVideoPlayer';
import group from './group';
import market from './market';
import media from './media';
import { messaging } from './messaging';
import * as miniPlayer from './miniPlayer/miniPlayer';
import newsfeed from './newsfeed';
import permissions from './permissions';
import * as pushNotifications from './pushNotifications/pushNotifications';
import * as reactions from './reactions/reactions';
import relationships from './relationships';
import scheduledEvents from './scheduledEvents';
import search from './search';
import * as space from './space/space';
import * as stickers from './stickers/stickers';
import streaming from './streaming';
import thread from './thread';
import userSettings from './userSettings';
import * as users from './users';

export default {
  app,
  appearance,
  authorization,
  permissions,
  calls,
  deeplinks,
  relationships,
  messaging,
  stickers,
  group,
  search,
  userSettings,
  drafts,
  download,
  downloadMessageFile,
  streaming,
  thread,
  pushNotifications,
  globalVideoPlayer,
  media,
  users,
  space,
  newsfeed,
  feedback,
  countriesPhoneCodes,
  miniPlayer,
  forward,
  market,
  reactions,
  cache,
  botsCatalog,
  scheduledEvents,
};
