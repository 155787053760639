import getRanges from './getRanges';
import removePositionsInRanges from './removePositionsInRanges';

export default ({ symbolsPositions, tag, ignoreTags }) => {
  const positions = symbolsPositions[tag];
  const ranges = tag === '@' ? positions : getRanges(positions);

  if (ranges.length === 0) {
    return;
  }

  const tags = Object.keys(symbolsPositions).filter((item) => !ignoreTags.includes(item));

  for (let i = 0; i < tags.length; i++) {
    const tag = tags[i];

    symbolsPositions[tag] = removePositionsInRanges(symbolsPositions[tag], ranges);
  }
};
