import React, { FC, MouseEvent } from 'react';

import IcClose from 'ICONS/ic-close-24.svg';
import { IconButton, IconViewSizes } from 'UIKIT/IconButton';

import styles from './RewardsBanner.styles.m.css';

interface IRewardsBannerViewProps {
  image: string;
  title: string;
  description: string;
  onClick(): void;
  onClose(): void;
}

export const RewardsBannerView: FC<IRewardsBannerViewProps> = (props) => {
  const { image, title, description, onClick, onClose } = props;

  const onButtonClose = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClose();
  };

  return (
    <div
      className={styles.container}
      onClick={onClick}
      role="button"
      tabIndex={0}
    >
      <img
        alt=""
        className={styles.image}
        src={image}
      />
      <div className={styles.textContainer}>
        <div className={styles.title}>{title}</div>
        <div className={styles.description}>{description}</div>
      </div>
      <IconButton
        Icon={IcClose}
        size={IconViewSizes.Small}
        iconSize={IconViewSizes.Auto}
        onClick={onButtonClose}
        className={styles.close}
      />
    </div>
  );
};
