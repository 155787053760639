import Tasks from 'APP/Tasks';
import { useTranslation } from 'APP/packages/translations';
import { RouterState } from 'APP/router/constants';
import Entities from 'APP/store';
import { ChatMessage } from 'APP/store/Messages/Message/ChatMessage/ChatMessage';
import { TPostInstance } from 'STORE/Newsfeed/Newsfeed.types';

interface IPostHeaderPresenter {
  isShowTitle: boolean;
  isShowLockIcon: boolean;
  canBeSubscribed: boolean;
  canOpenGroup: boolean;
  title: string;
  message: ChatMessage;
  onSubscribe(): Promise<void>;
  onClick(): Promise<void>;
}

export const usePostHeaderPresenter = (newsItem: TPostInstance): IPostHeaderPresenter => {
  const { t } = useTranslation();

  const presenter: IPostHeaderPresenter = {
    get isShowTitle(): boolean {
      return Boolean(
        presenter.title &&
          String(presenter.title).trim().length > 0 &&
          presenter.message.group?.withoutMe
      );
    },

    get isShowLockIcon(): boolean {
      return Boolean(presenter.message.group?.isChannel && presenter.message.group?.isClosed);
    },

    get canBeSubscribed(): boolean {
      const group = presenter.message.group;
      return !!(group?.withoutMe && group?.isOpen);
    },

    get title(): string {
      return newsItem.title;
    },

    get message(): ChatMessage {
      return newsItem.message as ChatMessage;
    },

    async onSubscribe(): Promise<void> {
      const result = await Tasks.group.joinGroup({ groupId: newsItem.groupId });
      if (result) {
        Entities.toast.show(t('news_feed_you_subscribed'));
      }
    },

    get canOpenGroup(): boolean {
      const group = presenter.message.group;
      return !!(group?.withoutMe && !group?.isOpen);
    },

    async onClick(): Promise<void> {
      if (presenter.canOpenGroup) {
        Entities.toast.show(t('space_alert_post_private'));
        return;
      }

      Tasks.messaging.focusMessage({
        groupId: newsItem.groupId,
        messageId: newsItem.messageId,
      });

      await Tasks.group.setActiveGroup({
        groupId: newsItem.groupId,
        state: {
          [RouterState.hasGoBack]: true,
        },
      });
    },
  };

  return presenter;
};
