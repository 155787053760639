import {
  subscribe,
  broadcast,
  TEventSubscribeHandler,
  TEventUnsubscribeHandler,
} from '../../eventBus';

function getBusStation(groupId: string, messageId: string): string {
  return `message-delete-${groupId}-${messageId}`;
}

export function subscribeDeleteMessage(
  groupId: string,
  messageId: string,
  callback: TEventSubscribeHandler
): TEventUnsubscribeHandler {
  return subscribe(getBusStation(groupId, messageId), callback);
}

export function broadcastDeleteMessage(groupId: string, messageId: string): void {
  broadcast(getBusStation(groupId, messageId));
}
