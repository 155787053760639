import React, { FC } from 'react';

import NoContactImage from 'APP/images/gem-mascot-contacts.svg';
import { useTranslation } from 'APP/packages/translations';

import styles from './NoContacts.styles.m.css';

export const NoContacts: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.noContent}>
      <img
        src={NoContactImage}
        alt={''}
        className={styles.img}
      />
      {t('no_contacts_yet') as string}
    </div>
  );
};

export default NoContacts;
