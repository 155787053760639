import { observer } from 'mobx-react';
import React from 'react';

import { ActiveSpaceHandler } from './ActiveSpaceHandler/ActiveSpaceHandler';
import { BadgeHandler } from './BadgeHandler/BadgeHandler';
import CallStateHandler from './CallStateHandler';
import { ChangeActiveGroupHandler } from './ChangeActiveGroupHandler/ChangeActiveGroupHandler';
import ConfirmUnreadMessages from './ConfirmUnreadMessages';
import { DeepLinkHandler } from './DeepLinkHandler/DeepLinkHandler';
import { EventsHandler } from './EventsHandler/EventHandler';
import { FirstLoginHandler } from './FirstLoginHandler/FirstLoginHandler';
import ForceUpdateHandler from './ForceUpdateHandler';
import IsFocusedAppHandler from './IsFocusedAppHandler';
import MainConnectionHandler from './MainConnectionHandler';
import OfflineStatusHandler from './OfflineStatusHandler';
import ThreadCounterHandler from './ThreadCounterHandler';
import UpdateThreadsHandler from './UpdateThreadsHandler';
import UpdateViewsHandler from './UpdateViewsHandler';
import UserTypingHandler from './UserTypingHandler';
import usePresenter from './presenter';

const Modules = ({ pageType }) => {
  const presenter = usePresenter();

  return (
    <>
      <ChangeActiveGroupHandler pageType={pageType} />
      <OfflineStatusHandler />
      <EventsHandler />
      <IsFocusedAppHandler />
      <ForceUpdateHandler />
      <BadgeHandler />
      <UpdateThreadsHandler />
      <MainConnectionHandler />
      <UserTypingHandler />
      <ThreadCounterHandler />
      {presenter.hasCall && <CallStateHandler />}
      {presenter.isNeedHandleDeepLink && (
        <DeepLinkHandler finishDeepLinkHandling={presenter.finishDeepLinkHandling} />
      )}
      <UpdateViewsHandler />
      <ConfirmUnreadMessages />
      <ActiveSpaceHandler />
      <FirstLoginHandler />
    </>
  );
};

export default observer(Modules);
