export enum PushNotificationType {
  GroupRenamed = 'GROUP_RENAMED',
  GroupAvatarChanged = 'GROUP_AVATAR_CHANGED',
  GroupTypeChanged = 'GROUP_TYPE_CHANGED',
  GroupCategoryChanged = 'GROUP_CATEGORY_CHANGED',
  GroupDescriptionChanged = 'GROUP_DESCRIPTION_CHANGED',

  // channel catalog notifications
  GroupCatalogChanged = 'GROUP_CATALOG_CHANGED',

  // group remove notifications
  GroupDeleted = 'GROUP_DELETED',

  // user
  UserNewJoined = 'USER_NEW_JOINED',

  // group participants notifications
  GroupUserJoin = 'GROUP_USER_JOIN',
  GroupUserLeave = 'GROUP_USER_LEAVE',

  GroupAdminsAdded = 'GROUP_ADMINS_ADDED',
  GroupAdminsRemoved = 'GROUP_ADMINS_REMOVED',

  GroupOwnerChanged = 'GROUP_OWNER_CHANGED',

  GroupUserRemoved = 'GROUP_USER_REMOVED',
  GroupUsersRemoved = 'GROUP_USERS_REMOVED',
  GroupUserBanned = 'GROUP_USER_BANNED',
  GroupUsersBanned = 'GROUP_USERS_BANNED',

  // scheduled event push notifications
  ScheduledEventCreated = 'SCHEDULED_EVENT_CREATED',
  ScheduledEventReminder = 'SCHEDULED_EVENT_REMINDER',

  // group participants notifications for self
  GroupAdminAdded = 'GROUP_ADMIN_ADDED',
  GroupUserAdded = 'GROUP_USER_ADDED',

  // message notifications
  MessageNew = 'MESSAGE_NEW',
  MessageUserMentioned = 'MESSAGE_USER_MENTIONED',
  UserRepliedOnMessage = 'USER_REPLIED_ON_MESSAGE',
  NewThreadMessage = 'NEW_THREAD_MESSAGE',
  NewCommentOnMessage = 'NEW_COMMENT_ON_MESSAGE',
  UserReactedOnMessage = 'USER_REACTED_ON_MESSAGE',
  MessageForward = 'MESSAGE_FORWARD',
  MessagePin = 'MESSAGE_PIN',
  ScheduledMessageSent = 'SCHEDULED_MESSAGE_SENT',
  MessageConfirm = 'MESSAGE_CONFIRM',
  MessageDelete = 'MESSAGE_DELETE',
  AgoraCall = 'AGORA_CALL',
  StreamStarted = 'STREAM_STARTED',
  DeviceAdded = 'DEVICE_ADDED',

  // SPACE
  GroupAddedToSpace = 'GROUP_ADDED_TO_SPACE',
  GroupRemovedFromSpace = 'GROUP_REMOVED_FROM_SPACE',
  SpaceAdminLeft = 'SPACE_ADMIN_LEFT',
  SpaceGroupDeleted = 'SPACE_GROUP_DELETED',
}

interface IPushNotificationCallData {
  readonly channelId: string;
  readonly callerName: string;
  readonly callerAvatarUrl: string;
  readonly callVisibleDuration: string;
  readonly callType: string;
}

interface IPushNotificationScheduledEventData {
  endTs: string;
  eventId: string;
  groupId: string;
  inviteesCount: 2;
  name: string;
  startTs: string;
}

export interface IPushNotification {
  readonly pushType: PushNotificationType;
  readonly user: string;
  readonly group?: string;
  readonly groupType?: string;
  readonly messageId?: string;
  readonly messageDatetime?: string;
  readonly title?: string;
  readonly subTitle?: string;
  readonly body?: string;
  readonly badge: number;
  readonly icon?: string;
  readonly timestamp: string;
  readonly callData?: IPushNotificationCallData;
  readonly scheduledEventData?: IPushNotificationScheduledEventData;
  readonly event: string;
  readonly spaceId?: string;
}

export interface IPushNotificationData {
  readonly pushType?: PushNotificationType;
  readonly group?: string;
  readonly groupType?: string;
  readonly messageId?: string;
  readonly spaceId?: string;
  readonly userId?: string;
}
