import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { AddKeyPressHandler } from 'APP/packages/keyBoardHandler';
import { ChatBackground } from 'MAIN/components/ChatBackground/ChatBackground';

import { useSpaceContentPresenter } from './SpaceContent.presenter';
import styles from './SpaceContent.styles.m.css';
import { SpaceHeader } from './SpaceHeader/SpaceHeader';
import { SpaceNewsfeed } from './SpaceNewsfeed/SpaceNewsfeed';

interface ISpaceMarketProps {
  spaceId?: string;
}

export const SpaceContent: FC<ISpaceMarketProps> = observer(({ spaceId }) => {
  const presenter = useSpaceContentPresenter(spaceId);

  return (
    <div className={styles.container}>
      <AddKeyPressHandler onEsc={presenter.onClose} />

      <ChatBackground />

      <SpaceHeader />

      <SpaceNewsfeed space={presenter.space} />
    </div>
  );
});
