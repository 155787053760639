import { useAsObservableSource, useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { showToast } from 'APP/Tasks/app/showToast/showToast';
import { ISpaceMember } from 'APP/Tasks/space/getSpaceMembers/getSpaceMembers';
import { ALERT_TYPES } from 'APP/constants/app';
import { SpaceUserRole } from 'APP/model/space/spaceModel.types';
import { useTranslation } from 'APP/packages/translations';
import DeleteAdminIcon from 'ICONS/ic-delete-admin.svg';
import OwnerIcon from 'ICONS/ic-owner.svg';
import DeleteIcon from 'ICONS/ic-s-delete.svg';
import UserAdminIcon from 'ICONS/ic-user-admin.svg';
import BanIcon from 'ICONS/ic-user-ban.svg';
import { Space } from 'STORE/Spaces/Space';
import { MenuItemStyle } from 'UIKIT/MenuItem/MenuItem.types';
import { IPopoverMenuItem } from 'UIKIT/PopoverMenu/PopoverMenu.types';

export interface ISpaceMembersListItemPresenterParams {
  space: Space | null;
  member: ISpaceMember;
  onMemberRemove(userId: string): void;
  onUpdateMemberRole(userId: string, role: SpaceUserRole): void;
  onReloadMembersList(): void;
  onClosePopup(): void;
}

interface ISpaceMembersListItemPresenter {
  isOwner: boolean;
  isAdmin: boolean;
  isMember: boolean;
  isBot: boolean;
  canStartUserChat: boolean;
  menuReference: HTMLElement | null;
  menuItems: IPopoverMenuItem[];
  canTransferOwnership: boolean;
  canAddToAdmins: boolean;
  canRemoveFromAdmins: boolean;
  canBanMember: boolean;
  canRemoveMember: boolean;
  isOpenedMenu: boolean;
  isMenuLoading: boolean;
  transferOwnership(): Promise<void>;
  addToAdmins(): Promise<void>;
  removeFromAdmins(): Promise<void>;
  banMember(): Promise<void>;
  removeMember(): Promise<void>;
  onToggleMenu(element: HTMLElement): void;
  onCloseMenu(): void;
  onOpenUserChat(): Promise<void>;
  showErrorMessage(): void;
}

export function useSpaceMembersListItemPresenter({
  space,
  member,
  onMemberRemove,
  onUpdateMemberRole,
  onReloadMembersList,
  onClosePopup,
}: ISpaceMembersListItemPresenterParams): ISpaceMembersListItemPresenter {
  const source = useAsObservableSource({ space, member, onMemberRemove, onClosePopup });
  const { t } = useTranslation();

  const presenter = useLocalStore<ISpaceMembersListItemPresenter>(() => ({
    menuReference: null,
    isMenuLoading: false,

    get isOwner(): boolean {
      return source.member.role === SpaceUserRole.Owner;
    },

    get isAdmin(): boolean {
      return source.member.role === SpaceUserRole.Admin;
    },

    get isMember(): boolean {
      return source.member.role === SpaceUserRole.Member;
    },

    get isBot(): boolean {
      return source.member.user.isBot;
    },

    get canStartUserChat(): boolean {
      return !source.member.user.isMe && !!source.member.user.startChat;
    },

    async onOpenUserChat(): Promise<void> {
      await source.member.user.startChat();
      source.onClosePopup();
    },

    onToggleMenu(menuReference: HTMLElement): void {
      if (presenter.isOpenedMenu) {
        presenter.onCloseMenu();
        return;
      }
      presenter.menuReference = menuReference;
    },

    onCloseMenu(): void {
      this.menuReference = null;
    },

    get isOpenedMenu(): boolean {
      return !!presenter.menuReference;
    },

    get menuItems(): IPopoverMenuItem[] {
      return [
        {
          key: 'transferOwnership',
          isVisible: presenter.canTransferOwnership,
          title: t('space_transfer_ownership'),
          onClick: presenter.transferOwnership,
          icon: OwnerIcon,
        },
        {
          key: 'addToAdmins',
          isVisible: presenter.canAddToAdmins,
          title: t('add_to_admins'),
          onClick: presenter.addToAdmins,
          icon: UserAdminIcon,
        },
        {
          key: 'removeFromAdmins',
          isVisible: presenter.canRemoveFromAdmins,
          title: t('remove_from_admins'),
          onClick: presenter.removeFromAdmins,
          icon: DeleteAdminIcon,
        },
        {
          key: 'ban',
          isVisible: presenter.canBanMember,
          title: t('ban'),
          onClick: presenter.banMember,
          icon: BanIcon,
        },
        {
          key: 'remove',
          isVisible: presenter.canRemoveMember,
          title: t('space_remove_user'),
          onClick: presenter.removeMember,
          icon: DeleteIcon,
          style: MenuItemStyle.Danger,
        },
      ].filter((item) => item.isVisible);
    },

    get canTransferOwnership(): boolean {
      return !!source.space?.isOwner && !presenter.isOwner && !presenter.isBot;
    },

    async transferOwnership(): Promise<void> {
      presenter.isMenuLoading = true;

      const canTransfer = await Tasks.app.showConfirm({
        text: t('space_transfer_ownership_alert', { 0: source.member.user.displayName }),
        primaryText: t('common_yes'),
        secondaryText: t('common_cancel'),
      });

      if (!canTransfer) {
        presenter.isMenuLoading = false;
        return;
      }

      const userId = source.member.user.id;
      const result = await Tasks.space.changeSpaceOwnership(source.space!.id, userId);

      if (result) {
        onReloadMembersList();
      } else {
        presenter.showErrorMessage();
      }

      presenter.isMenuLoading = false;
    },

    get canAddToAdmins(): boolean {
      return !!(source.space?.isOwner && presenter.isMember);
    },

    async addToAdmins(): Promise<void> {
      presenter.isMenuLoading = true;

      const canAddToAdmin = await Tasks.app.showConfirm({
        text: t('confirmation_text_add_admin', { 0: source.member.user.displayName }),
        primaryText: t('common_yes'),
        secondaryText: t('common_cancel'),
      });

      if (!canAddToAdmin) {
        presenter.isMenuLoading = false;
        return;
      }

      const userId = source.member.user.id;
      const result = await Tasks.space.promoteAdminToSpace({
        spaceId: source.space!.id,
        userIds: [userId],
      });

      if (result) {
        onUpdateMemberRole(userId, SpaceUserRole.Admin);
      } else {
        presenter.showErrorMessage();
      }

      presenter.isMenuLoading = false;
    },

    get canRemoveFromAdmins(): boolean {
      return !!(source.space?.isOwner && presenter.isAdmin);
    },

    async removeFromAdmins(): Promise<void> {
      const canRemove =
        source.member.role !== SpaceUserRole.Admin ||
        (await Tasks.app.showConfirm({
          text: t('space_kick_admin_alert'),
          primaryText: t('common_yes'),
          secondaryText: t('common_no'),
        }));

      if (!canRemove) {
        return;
      }

      presenter.isMenuLoading = true;

      const userId = source.member.user.id;
      const result = await Tasks.space.revokeAdminFromSpace({
        spaceId: source.space!.id,
        userIds: [userId],
      });

      if (result) {
        onUpdateMemberRole(userId, SpaceUserRole.Member);
      } else {
        presenter.showErrorMessage();
      }

      presenter.isMenuLoading = false;
    },

    get canBanMember(): boolean {
      return (
        !presenter.isOwner &&
        !!(source.space?.isOwner || source.space?.isAdmin) &&
        !source.member.user.isMe
      );
    },

    async banMember(): Promise<void> {
      const banConfirmText =
        source.member.role === SpaceUserRole.Admin
          ? t('space_ban_admin_alert')
          : t('ban_user_alert', { 0: source.member.user.displayName });

      const confirmResult = await Tasks.app.showConfirmWithCheckbox({
        text: banConfirmText,
        checkBoxText: t('checkbox_delete_messages'),
      });

      if (!confirmResult.isConfirmed) {
        return;
      }

      presenter.isMenuLoading = true;

      const userId = source.member.user.id;

      const result = await Tasks.space.banSpaceMembers({
        spaceId: source.space!.id,
        userIds: [userId],
        deleteAllMessages: confirmResult.isChecked,
      });

      if (result) {
        confirmResult.isChecked
          ? showToast(t('delete_banned_user_messages_snackbar'))
          : showToast(t('common_user_banned_snackbar', { 0: source.member.user.displayName }));

        source.onMemberRemove(userId);
      } else {
        presenter.showErrorMessage();
      }

      presenter.isMenuLoading = false;
    },

    get canRemoveMember(): boolean {
      return !!(
        !presenter.isOwner &&
        (source.space?.isOwner || (source.space?.isAdmin && presenter.isMember))
      );
    },

    async removeMember(): Promise<void> {
      const canRemove =
        source.member.role !== SpaceUserRole.Admin ||
        (await Tasks.app.showConfirm({
          text: t('space_remove_admin_alert'),
          primaryText: t('common_yes'),
          secondaryText: t('common_no'),
        }));

      if (!canRemove) {
        return;
      }

      presenter.isMenuLoading = true;

      const userId = source.member.user.id;

      const result = await Tasks.space.removeParticipants({
        spaceId: source.space!.id,
        userIds: [userId],
      });

      if (result) {
        source.onMemberRemove(userId);
      } else {
        presenter.showErrorMessage();
      }

      presenter.isMenuLoading = false;
    },

    showErrorMessage(): void {
      Tasks.app.addAlert({
        type: ALERT_TYPES.SOMETHING_WRONG_ERROR,
      });
    },
  }));

  return presenter;
}
