import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import { DeepLinkType } from 'APP/model/deeplinks/deeplinksModel.types';
import { UnauthorizedCallType } from 'APP/model/unauthorizedCall/unauthorizedCallModel.constants';
import { RouterLink, RouterParams } from 'APP/router/constants';
import useGetParameter from 'APP/router/hooks/useGetParameter';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import Entities from 'STORE';

import { IUnauthorizedDeepLinkHandlerStore } from './UnauthorizedDeepLinkHandler.types';

export const useUnauthorizedDeepLinkHandlerPresenter = async (): Promise<void> => {
  const navigateTo = useNavigateTo();

  const isDeepLink = useGetParameter(RouterParams.deepLink);
  const deepLinkType = useGetParameter(RouterParams.type) as DeepLinkType;
  const entityId = useGetParameter(RouterParams.entityId);
  const channelId = useGetParameter(RouterParams.channelId);

  const presenter = useLocalStore<IUnauthorizedDeepLinkHandlerStore>(() => ({
    isNeedHandleDeepLink: true,

    get isAuthFinished(): boolean {
      return Entities.AuthStore.isFirstAuthDone;
    },

    get isAuthenticated(): boolean {
      return Entities.AuthStore.isAuthenticated;
    },

    finishDeepLinkHandling(): void {
      presenter.isNeedHandleDeepLink = false;
    },

    handleDeepLink(): void {
      if (
        isDeepLink &&
        (deepLinkType === DeepLinkType.CallMe || deepLinkType === DeepLinkType.Call) &&
        entityId
      ) {
        const callType =
          deepLinkType === DeepLinkType.CallMe
            ? UnauthorizedCallType.CallMe
            : UnauthorizedCallType.Call;

        Entities.callInvitation.setEntityId(entityId);
        Entities.callInvitation.setCallType(callType);
        Entities.callInvitation.setChannelId(channelId || null);

        navigateTo({
          to:
            deepLinkType === DeepLinkType.Call
              ? RouterLink.callInvitationGroup
              : RouterLink.callInvitation,
          params: { callType: deepLinkType, id: entityId, channelId },
        });
      }
      presenter.finishDeepLinkHandling();
    },
  }));

  useEffect(() => {
    if (presenter.isAuthFinished && !presenter.isAuthenticated) {
      presenter.handleDeepLink();
    }
  }, [presenter.isAuthFinished]);
};
