import Entities from 'APP/store';

import loadGroupsByIds from '../../group/loadGroupsByIds';

export default async ({ groupsIds }) => {
  const unknownGroupsIds = groupsIds.filter((groupId) => {
    const group = Entities.GroupsStore.getGroupById(groupId);
    return !group || (group.withoutMe && !group?.isThread);
  });

  if (!unknownGroupsIds.length) {
    return;
  }

  await loadGroupsByIds(unknownGroupsIds);
};
