import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import NoSpaceNewsfeedImage from 'APP/images/gem-mascot-article.svg';
import { useTranslation } from 'APP/packages/translations';
import { SpaceGuestProfile } from 'MAIN/Profile/SpaceGuestProfile/SpaceGuestProfile';
import { NewsfeedContent } from 'MAIN/components/NewsfeedContent/NewsfeedContent';
import { usePostMenuItems } from 'MAIN/components/NewsfeedContent/NewsfeedPostItem/PostContextMenu/usePostMenuItems';
import { Space } from 'STORE/Spaces/Space';
import { InfoBlock, InfoBlockTypes } from 'UIKIT/InfoBlock/InfoBlock';

import { SpaceCollectionsList } from '../SpaceCollectionList/SpaceCollectionsList';
import { useSpaceNewsfeedPresenter } from './SpaceNewsfeed.presenter';
import styles from './SpaceNewsfeed.styles.m.css';

interface ISpaceNewsfeedProps {
  readonly space: Space | null;
}

export const SpaceNewsfeed: FC<ISpaceNewsfeedProps> = observer(({ space }) => {
  const { t } = useTranslation();
  const presenter = useSpaceNewsfeedPresenter(space);
  const menuItems = usePostMenuItems({ isShowHide: false, isShowAddCollectionItem: true });

  let infoBlock = null;
  if (presenter.isShowEmptyChannels) {
    infoBlock = (
      <InfoBlock
        type={presenter.hasBackgroundImage ? InfoBlockTypes.ChatWithPattern : InfoBlockTypes.Chat}
        descriptions={[t('space_posts_inform_message')]}
        image={NoSpaceNewsfeedImage}
        buttonTitle={t('space_create_channel_button')}
        onClick={presenter.onOpenChannelCreate}
      />
    );
  }

  if (presenter.isShowEmptyPosts) {
    infoBlock = (
      <InfoBlock
        type={presenter.hasBackgroundImage ? InfoBlockTypes.ChatWithPattern : InfoBlockTypes.Chat}
        descriptions={[t('space_posts_inform_message')]}
        image={NoSpaceNewsfeedImage}
        buttonTitle={t('space_create_posts_button')}
        onClick={presenter.onOpenChannel}
      />
    );
  }

  if (presenter.isShowEmptyInfo) {
    infoBlock = (
      <InfoBlock
        type={presenter.hasBackgroundImage ? InfoBlockTypes.ChatWithPattern : InfoBlockTypes.Chat}
        descriptions={[t('space_posts_inform_message')]}
        image={NoSpaceNewsfeedImage}
      />
    );
  }

  return (
    <div className={styles.contentWrapper}>
      <NewsfeedContent
        news={presenter.newsfeed.news}
        isShowPlaceholder={presenter.isShowPlaceholder}
        isShowLoader={presenter.isLoading}
        emptyInfoText={t('newsfeed_no_more_news')}
        hasBackgroundImage={presenter.hasBackgroundImage}
        hasMoreNews={presenter.hasMoreNews}
        getMenuItems={menuItems.getMenuItems}
        onReloadNewsfeed={presenter.onReload}
        onSetNewsVisibility={presenter.onSetNewsVisibility}
      >
        {presenter.isShowGuestProfile && space && (
          <SpaceGuestProfile
            className={classNames(styles.guestProfile, {
              [styles.isEmpty]: infoBlock !== null,
            })}
            space={space}
          />
        )}

        {space && (
          <SpaceCollectionsList
            className={infoBlock !== null ? styles.isEmpty : undefined}
            space={space}
          />
        )}

        {infoBlock}
      </NewsfeedContent>
    </div>
  );
});
