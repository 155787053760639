import { createStream } from './createStream';
import finish from './finish';
import forceMute from './forceMute';
import inviteAsStreamer from './inviteAsStreamer';
import leave from './leave';
import leaveWithoutSaving from './leaveWithoutSaving';
import { openCreateStreamPopup } from './openCreateStreamPopup';
import publish from './publish';
import { setActiveStream } from './setActiveStream';
import start from './start';
import startScreenSharing from './startScreenSharing';
import stopScreenSharing from './stopScreenSharing';
import updateActiveStream from './updateActiveStream';
import updatePreview from './updatePreview';

export default {
  createStream,
  updateActiveStream,
  start,
  finish,
  leave,
  updatePreview,
  setActiveStream,
  openCreateStreamPopup,
  publish,
  leaveWithoutSaving,
  startScreenSharing,
  stopScreenSharing,
  inviteAsStreamer,
  forceMute,
};
