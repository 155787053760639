import { useLocalStore, useAsObservableSource } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { RouterState, RouterPage } from 'APP/router/constants';
import useClearGetParameters from 'APP/router/hooks/useClearGetParameters';
import useGetState from 'APP/router/hooks/useGetState';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';

export default () => {
  const channel = JSON.parse(useGetState(RouterState.channel));
  const clearGetParameters = useClearGetParameters();
  const navigateTo = useNavigateTo();

  const source = useAsObservableSource({ channel });

  const presenter = useLocalStore(() => ({
    isLoading: false,

    get group() {
      return source.channel;
    },

    get isOpened() {
      return presenter.group;
    },

    subscribe: async () => {
      presenter.isLoading = true;

      await Tasks.group.joinGroup({ groupId: presenter.group.id });

      presenter.isLoading = false;

      navigateTo({
        to: `/${RouterPage.Channels}/${presenter.group.id}`,
      });
    },

    close: clearGetParameters,
  }));

  useEffect(() => {
    if (!presenter.group) {
      presenter.close();
    }
  }, []);

  return presenter;
};
