import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { IconView } from 'APP/packages/UiKit/IconView/IconView';
import IcHide from 'ICONS/ic-hidden-left.svg';
import IcShow from 'ICONS/ic-open-right.svg';
import { OpponentVideo } from 'MAIN/OngoingCall/Shared/OpponentVideo/OpponentVideo';
import Entities from 'STORE';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';

import { Opponents } from './Opponents/Opponents';
import styles from './SpeakerVideo.styles.m.css';

export const SpeakerVideo = observer(() => {
  const OngoingCall = Entities.OngoingCall;

  return (
    <div className={styles.container}>
      <div
        className={classNames(styles.speakerContainer, {
          [styles.speakerContainerWithOpponents]: OngoingCall.presentVideoMode.isShowOpponents,
        })}
      >
        {!!OngoingCall.presentVideoMode.activeVideoOpponent && (
          <OpponentVideo
            key={OngoingCall.presentVideoMode.activeVideoOpponent.uid}
            opponent={OngoingCall.presentVideoMode.activeVideoOpponent}
            avatarType={AvatarTypes.Round_50}
            containerClassName={styles.speaker}
          />
        )}
        {OngoingCall.presentVideoMode.hasPreviewOpponents && (
          <button
            onClick={OngoingCall.presentVideoMode.toggleShowOpponents}
            className={styles.toggleOpponents}
          >
            <IconView
              Icon={OngoingCall.presentVideoMode.isShowOpponents ? IcHide : IcShow}
              className={styles.icon}
            />
          </button>
        )}
      </div>
      <Opponents />
    </div>
  );
});
