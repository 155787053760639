import classNames from 'classnames';
import React, { FC } from 'react';

import { useStoriesProgressPresenter } from './StoriesProgress.presenter';
import styles from './StoriesProgress.style.m.css';

interface IStoriesDurationProps {
  className?: string;
  count: number;
  activeIndex: number;
  activeProgress: number;
}

export const StoriesProgress: FC<IStoriesDurationProps> = ({
  className,
  count,
  activeIndex,
  activeProgress,
}) => {
  const { progressItems, getProgressWidth } = useStoriesProgressPresenter(count);

  return (
    <div className={classNames(styles.container, className)}>
      {progressItems.map((item, index) => (
        <div
          className={styles.item}
          key={item}
        >
          <div
            className={classNames(styles.itemProgress, {
              [styles.animated]: index === activeIndex,
            })}
            style={{
              width: `${getProgressWidth(index, activeIndex, activeProgress)}%`,
            }}
          ></div>
        </div>
      ))}
    </div>
  );
};
