import { action, observable } from 'mobx';

import { IPrivacySettings, PrivacyValue } from 'APP/model/userSettings/userSettingsModel.types';

export class PrivacySettings implements IPrivacySettings {
  @observable updateTs = 0;
  @observable addToGroup = PrivacyValue.Anyone;
  @observable addToPublic = PrivacyValue.Anyone;
  @observable callMe = PrivacyValue.Anyone;
  @observable showLastSeen = PrivacyValue.Anyone;
  @observable searchMe = PrivacyValue.Anyone;
  @observable sendVoiceMessage = PrivacyValue.Anyone;
  @observable showPhoneNumber = PrivacyValue.Anyone;
  @observable showAvatar = PrivacyValue.Anyone;

  @action
  reset(): void {
    this.setUpdateTs(0);
  }

  @action
  setUpdateTs(updateTs: number): void {
    this.updateTs = updateTs;
  }

  @action
  setSettings(data: IPrivacySettings): void {
    this.addToGroup = data.addToGroup || this.addToGroup;
    this.addToPublic = data.addToPublic || this.addToPublic;
    this.callMe = data.callMe || this.callMe;
    this.showLastSeen = data.showLastSeen || this.showLastSeen;
    this.searchMe = data.searchMe || this.searchMe;
    this.sendVoiceMessage = data.sendVoiceMessage || this.sendVoiceMessage;
    this.showPhoneNumber = data.showPhoneNumber || this.showPhoneNumber;
    this.showAvatar = data.showAvatar || this.showAvatar;
  }
}
