import removeItemsByIndex from './utils/removeItemsByIndex';

function getPreviousIndex({ i, indexesToRemove }) {
  let step = 0;
  let previousIndex;
  let previousRemovedIndex;

  // we need to ignore removed indexes
  do {
    step++;
    previousIndex = i - step;
    previousRemovedIndex = indexesToRemove[indexesToRemove.length - step];
  } while (previousRemovedIndex === previousIndex);

  return previousIndex;
}

function getIsOpenTag({ i, indexesToRemove }) {
  return (i - indexesToRemove.length) % 2 === 0;
}

export default ({ positions }) => {
  const indexesToRemove = [];

  // i = 1, because i = 0 doesn't have previous position
  for (let i = 1; i < positions.length; i++) {
    const previousIndex = getPreviousIndex({ i, indexesToRemove });
    const isOpenTag = getIsOpenTag({ i, indexesToRemove });

    const prevPosition = positions[previousIndex];
    const currentPosition = positions[i];

    // we don't need to try find content between close and open tags
    // but we need to remove cross tags "***1******2***"
    if (isOpenTag && prevPosition.end > currentPosition.start) {
      indexesToRemove.push(i);
    } else if (!isOpenTag && prevPosition.end >= currentPosition.start) {
      indexesToRemove.push(i);
    }
  }

  return removeItemsByIndex({
    list: positions,
    indexesToRemove,
  });
};
