import { useAsObservableSource, useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { MAX_PIN_GROUPS } from 'APP/constants/group';
import { useTranslation } from 'APP/packages/translations';
import Entities from 'APP/store';
import IcClear from 'ICONS/ic-clear.svg';
import IcClose from 'ICONS/ic-close-32.svg';
import IcPinStroke from 'ICONS/ic-pin-stroke.svg';

import useSharedDataItems from '../hooks/sharedDataItems';

export const useBotProfilePresenter = ({ groupId, actions }) => {
  const { t } = useTranslation();
  const sharedDataItems = useSharedDataItems({ groupId, actions });
  const source = useAsObservableSource({ groupId });

  const presenter = useLocalStore(() => ({
    get group() {
      return Entities.GroupsStore.getGroupById(source.groupId);
    },

    get bot() {
      return presenter.group.groupOpponent;
    },

    get title() {
      return presenter.group.avatarTitle;
    },

    get subtitle() {
      return t('common_bot_label');
    },

    get description() {
      return presenter.bot.description;
    },

    get nickname() {
      return presenter.bot.displayNickname;
    },

    get childPagesList() {
      return sharedDataItems.items;
    },

    get actionItems() {
      const items = [
        {
          key: 'pin-bot',
          isVisible: presenter.canPinBot,
          Icon: IcPinStroke,
          title: t('group_chat_pin'),
          onClick: presenter.pinBot,
        },
        {
          key: 'unpin-bot',
          isVisible: presenter.canUnpinBot,
          Icon: IcPinStroke,
          title: t('group_chat_unpin'),
          onClick: presenter.unpinBot,
        },
        {
          key: 'clear-messages',
          isVisible: presenter.canClearHistory,
          Icon: IcClear,
          title: t('profile_clear_messages'),
          onClick: presenter.clearHistory,
        },
        {
          key: 'deactivate-bot',
          isVisible: presenter.canDeactivateBot,
          Icon: IcClose,
          title: t('bot_deactivate'),
          onClick: presenter.deactivateBot,
        },
      ];

      return items.filter((item) => item.isVisible);
    },

    get canPinMore() {
      return Entities.GroupsStore.pinnedChatGroups.length < MAX_PIN_GROUPS;
    },

    get canPinBot() {
      if (!presenter.group || !presenter.group.isBot || presenter.group.withoutMe) {
        return false;
      }

      return presenter.canPinMore && !presenter.group.isPinned;
    },

    pinBot() {
      if (!presenter.group) {
        return;
      }

      Tasks.group.pinGroup({ groupId: presenter.group.id });
    },

    get canUnpinBot() {
      if (!presenter.group || !presenter.group.isBot || presenter.group.withoutMe) {
        return false;
      }

      return presenter.group.isPinned;
    },

    unpinBot() {
      if (!presenter.group) {
        return;
      }

      Tasks.group.unpinGroup({ groupId: presenter.group.id });
    },

    get canClearHistory() {
      if (!presenter.group || !presenter.group.hasMessages) {
        return false;
      }

      return !presenter.group.isChannel || presenter.group.isOwner;
    },

    async clearHistory() {
      if (!presenter.group) {
        return;
      }

      const isConfirmed = await Tasks.app.showConfirm({
        text: t('clear_history_alert', { 0: presenter.bot.displayName }),
      });

      if (isConfirmed) {
        Tasks.group.clearHistory({
          groupId: presenter.group.id,
        });
      }
    },

    get canDeactivateBot() {
      if (!presenter.group) {
        return false;
      }

      return presenter.group.isBot;
    },

    deactivateBot() {
      Tasks.app.showConfirm({
        text: t('bot_deactivate_alert'),
        async onSuccess() {
          if (!presenter.group) {
            return;
          }

          const botId = presenter.bot.id;

          await Tasks.group.deleteGroup({
            groupId: presenter.group.id,
          });

          await Tasks.botsCatalog.removeBotFromProfile(botId);
        },
      });
    },
  }));

  return presenter;
};
