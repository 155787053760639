import { useCallback, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import Tasks from 'APP/Tasks';
import { RouterPage } from 'APP/router/constants';
import Entities from 'APP/store';

export function useActiveSpaceHandlerPresenter(): void {
  const params = useParams<{ id: string }>();
  const location = useLocation();

  const isSpaceRoute = useCallback(
    () => location.pathname.includes(RouterPage.Spaces) && params.id,
    [params.id, location.pathname]
  );

  useEffect(() => {
    if (isSpaceRoute()) {
      Tasks.space.setActiveSpace(params.id);
    }
  }, [params.id, location.pathname]);

  useEffect(() => {
    const group = Entities.GroupsStore.activeGroup;
    const spaceId = group?.parentGroup?.spaceId || group?.spaceId;

    if (!isSpaceRoute() && Entities.spacesStore.activeSpaceId !== spaceId) {
      Tasks.space.setActiveSpace(null);
    }
  }, [Entities.GroupsStore.activeGroup, params.id, location.pathname]);
}
