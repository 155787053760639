import classNames from 'classnames';
import React, { memo, MouseEventHandler } from 'react';

import styles from 'UIKIT/FileIcon/FileIcon.styles.m.css';

import { FileIconState, TFileTheme } from './FileIcon.types';
import { FileIconComplete } from './FileIconComplete/FileIconComplete';
import { FileIconError } from './FileIconError/FileIconError';
import { FileIconHover } from './FileIconHover/FileIconHover';
import { FileIconLoading } from './FileIconLoading/FileIconLoading';
import { FileIconLoadingInfinity } from './FileIconLoadingInfinity/FileIconLoadingInfinity';
import { FileIconRegular } from './FileIconRegular/FileIconRegular';

export * from './FileIcon.types';
export * from './FileIcon.utils';

interface IFileIconProps {
  className?: string;
  text: string;
  theme: TFileTheme;
  state?: FileIconState;
  progress?: number;
  onClick?: MouseEventHandler<SVGSVGElement | SVGGElement>;
  onCancel?: MouseEventHandler<SVGSVGElement | SVGGElement>;
  onMouseEnter?: MouseEventHandler<SVGSVGElement>;
  onMouseLeave?: MouseEventHandler<SVGSVGElement>;
}

export const FileIcon = memo<IFileIconProps>((props) => {
  const {
    text,
    theme,
    progress,
    state = FileIconState.Regular,
    onClick,
    onCancel,
    className,
    onMouseEnter,
    onMouseLeave,
  } = props;

  const maxTextLength = 4;
  const handledText = text.length > maxTextLength ? `${text.substring(0, 3)}...` : text;

  const commonProps = { theme, text: handledText, onCancel };

  const getIcon = () => {
    switch (state) {
      case FileIconState.Error: {
        return <FileIconError {...commonProps} />;
      }
      case FileIconState.Completed: {
        return <FileIconComplete {...commonProps} />;
      }
      case FileIconState.Hover: {
        return <FileIconHover {...commonProps} />;
      }
      case FileIconState.Loading: {
        return <FileIconLoading {...commonProps} />;
      }
      case FileIconState.Infinity: {
        return <FileIconLoadingInfinity {...commonProps} />;
      }
      default: {
        return <FileIconRegular {...commonProps} />;
      }
    }
  };

  return (
    <svg
      style={{ strokeDashoffset: 100 - (progress || 0) / 2.765 }}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={classNames(styles.icon, className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      {getIcon()}
    </svg>
  );
});
