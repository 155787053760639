import { computed } from 'mobx';

import { IGroupData } from 'APP/model/group/groupModel.types';
import { Root } from 'STORE';
import { Groups } from 'STORE/Groups';

import { Group } from '../Group';

export class Fake extends Group {
  constructor(group: IGroupData, groups: Groups, root: Root) {
    super(group, groups, root);

    this.messagesStore.setLoadedFirstMessages(true);
    this.messagesStore.setLoadedLastMessages(true);
  }

  get isFake(): boolean {
    return true;
  }

  get numberOfUnread(): number {
    return 0;
  }

  get unreadUserMentionsCounter(): number {
    return 0;
  }

  @computed
  get seenTs(): number {
    return 0;
  }

  get canInviteUsers(): boolean {
    return false;
  }

  get canLeaveComment(): boolean {
    return false;
  }

  get canPinMessage(): boolean {
    return false;
  }
}
