import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, ReactElement, useCallback } from 'react';

import { VirtualizedList } from 'APP/components/VirtualizedList/VirtualizedList';
import {
  SPACES_PLACEHOLDER_ITEM_SIZE,
  SpacesPlaceholder,
} from 'APP/packages/placeholders/SpacesPlaceholder/SpacesPlaceholder';
import { useTranslation } from 'APP/packages/translations';
import { SpaceListGuestItem } from 'MAIN/SidebarPanel/SpacesList/SpaceListGuestItem/SpaceListGuestItem';
import { SpaceListItem } from 'MAIN/SidebarPanel/SpacesList/SpaceListItem/SpaceListItem';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';
import { InfoBlock, InfoBlockTypes } from 'UIKIT/InfoBlock/InfoBlock';

import {
  ISpacesListPresenterParams,
  useSpacesListPresenter,
  isDelimiterItem,
} from './SpacesList.presenter';
import styles from './SpacesList.styles.m.css';

const DELIMETER_HEIGHT = 34;
const GROUP_ITEM_HEIGHT = 64;
const PLACEHOLDER_ITEMS_COUNT = 14;

type ISpacesListProps = ISpacesListPresenterParams;

export const SpacesList: FC<ISpacesListProps> = observer((props) => {
  const presenter = useSpacesListPresenter(props);
  const { t } = useTranslation();

  const getItemSize = useCallback(
    (index: number): number => {
      const item = presenter.listItems[index];
      return isDelimiterItem(item) ? DELIMETER_HEIGHT : GROUP_ITEM_HEIGHT;
    },
    [presenter.listItems]
  );

  const getListItem = useCallback(
    (index: number): ReactElement => {
      const item = presenter.listItems[index];

      if (isDelimiterItem(item)) {
        return <h3 className={styles.recommendedTitle}>{presenter.recommendedTitle}</h3>;
      } else if (item.isRecommended) {
        return (
          <SpaceListGuestItem
            space={item.space}
            onClick={(): void => presenter.onItemClick(item)}
          />
        );
      } else {
        return (
          <SpaceListItem
            space={item.space}
            onClick={(): void => presenter.onItemClick(item)}
          />
        );
      }
    },
    [presenter.listItems]
  );

  return (
    <div className={classNames(styles.container, customScrollStyles.container)}>
      {!presenter.isShowEmptyInfo && (
        <VirtualizedList
          itemCount={presenter.itemsCount}
          getListItem={getListItem}
          itemSize={getItemSize}
          isShowLoader={presenter.isShowPlaceholder}
          Loader={<SpacesPlaceholder count={PLACEHOLDER_ITEMS_COUNT} />}
          loaderSize={PLACEHOLDER_ITEMS_COUNT * SPACES_PLACEHOLDER_ITEM_SIZE}
        />
      )}

      {presenter.isShowEmptyInfo && (
        <InfoBlock
          descriptions={[t('space_no_spaces_hint')]}
          type={InfoBlockTypes.Sidebar}
        />
      )}
    </div>
  );
});
