import { observer } from 'mobx-react';
import React from 'react';

import { useTranslation } from 'APP/packages/translations';

import { CallAddCustomPermissionsLink } from '../CallAddCustomPermissionsLink';
import { CallMemberPermissionsItem } from '../CallMemberPermissionsItem';
import { usePresenter } from './presenter';
import styles from './styles.m.css';

export const CallCustomPermissionsBlock = observer(() => {
  const presenter = usePresenter();
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.divider} />
      <div className={styles.title}>{t('call_custom_permissions')}</div>
      <CallAddCustomPermissionsLink />
      {!!presenter.customPermissions.size && (
        <>
          {presenter.customPermissionsIds.map((id) => (
            <CallMemberPermissionsItem
              key={id}
              userId={id}
            />
          ))}
        </>
      )}
    </>
  );
});
