import { observer } from 'mobx-react';
import React from 'react';

import { IconView } from 'APP/packages/UiKit/IconView/IconView';
import TranslateIcon from 'ICONS/ic-translate-16.svg';

import usePresenter from './presenter';
import styles from './styles.m.scss';

const Translate = ({ message }) => {
  const presenter = usePresenter({ message });

  if (!presenter.isShow) {
    return null;
  }

  return (
    <div className={styles.container}>
      <IconView
        Icon={TranslateIcon}
        className={styles.icon}
        size={'small'}
      />

      {presenter.isShowLabel && <span className={styles.label}>{presenter.label}</span>}

      {presenter.isShowToggle && (
        <button
          className={styles.button}
          onClick={presenter.onToggleClick}
        >
          {presenter.toggleCaption}
        </button>
      )}
    </div>
  );
};

export default observer(Translate);
