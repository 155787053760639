export enum ClientConfigKeys {
  MessagesKeywords = 'messages.keywords',
  CallParticipantsMax = 'calls.participants.max',
  ReactionsLastUpdateTimestamp = 'reactions.last.update.timestamp',
  StickersLastUpdateTimestamp = 'stickers.last.update.timestamp',
  UserStickersLastUpdateTimestamp = 'user.stickers.last.update.timestamp',
  UserPreferences = 'user.preferences',
  FeatureMarketSpace = 'feature.marketspace',
  RewardsStatus = 'rewards.status',
  CoinfideHost = 'coinfideHost',
  Onboarding = 'onboarding',
  WhitelistUrls = 'whitelist.urls',
  TelegramAuth = 'telegram.auth',
  SupportEmail = 'supportEmail',
  CallsProvider = 'calls.provider',
}
