import { observer } from 'mobx-react';
import React from 'react';

import ProfileView from './ProfileView';
import usePresenter from './presenter';

const RightSidebar = () => {
  const presenter = usePresenter();
  if (!presenter.isAvailableToShow) {
    return null;
  }

  return (
    <ProfileView
      groupId={presenter.group.id}
      onClose={presenter.onClose}
    />
  );
};

export default observer(RightSidebar);
