import classNames from 'classnames';
import React, { FC, memo } from 'react';

import animatedPartStyles from '../../../placeholders/AnimatedPart/AnimatedPart.styles.m.css';
import styles from './MarketPlaceholderItem.styles.m.css';

interface IMarketPlaceholderItemProps {
  isAnimated: boolean;
}

export const MarketPlaceholderItem: FC<IMarketPlaceholderItemProps> = memo(({ isAnimated }) => {
  const animationClass = {
    [animatedPartStyles.animation]: isAnimated,
  };

  return (
    <div className={styles.item}>
      <div className={classNames(styles.avatar, animationClass)}></div>
      <div>
        <div className={classNames(styles.title, animationClass)}></div>

        <div className={classNames(styles.description, animationClass)}></div>
        <div className={classNames(styles.description, animationClass)}></div>
        <div className={classNames(styles.description, animationClass)}></div>
        <div className={classNames(styles.description, styles.short, animationClass)}></div>

        <div className={classNames(styles.action, animationClass)}></div>
      </div>
    </div>
  );
});
