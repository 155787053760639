import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { showToast } from 'APP/Tasks/app/showToast/showToast';
import { useTranslation } from 'APP/packages/translations';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import Entities from 'STORE';
import { Space } from 'STORE/Spaces/Space';

import { ISpaceMarketFormData } from '../../components/SpaceMarketForm/SpaceMarketForm.types';

export interface IAddSpaceMarketPopupPresenterParams {
  spaceId: string;
}

interface IAddSpaceMarketPopupPresenter {
  initialValues: ISpaceMarketFormData;
  space: Space | null;
  onSubmit(marketData: ISpaceMarketFormData): Promise<void>;
}

export function useAddSpaceMarketPopupPresenter({
  params,
  popupInstance,
}: ICommonPopupsProps<IAddSpaceMarketPopupPresenterParams>): IAddSpaceMarketPopupPresenter {
  const { t } = useTranslation();
  const { spaceId } = params!;

  const presenter = useLocalStore<IAddSpaceMarketPopupPresenter>(() => ({
    initialValues: {
      name: '',
      url: '',
    },

    get space(): Space | null {
      return spaceId ? Entities.spacesStore.getById(spaceId) : null;
    },

    async onSubmit(marketData: ISpaceMarketFormData): Promise<void> {
      try {
        await Tasks.space.createSpaceMarket({
          ...marketData,
          spaceId,
        });

        popupInstance.close();
      } catch {
        showToast(t('something_went_wrong'));
      }
    },
  }));

  useEffect(() => {
    if (!Entities.spacesStore.isLoading && !presenter.space?.isOwner) {
      popupInstance.close();
    }
  }, [presenter.space, Entities.spacesStore.isLoading]);

  return presenter;
}
