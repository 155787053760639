import Tasks from 'APP/Tasks';
import { CallType } from 'APP/model/call/callModel.types';
import { ServerErrorCode } from 'APP/model/error/error.constants';
import { callModel } from 'APP/model/model';
import Entities from 'APP/store';
import { t } from 'MODULE/i18n/service';
import { Call } from 'STORE/Calls/Call/Call';

const createNewCall = async (
  call: Call,
  isAudioMuted: boolean,
  isVideoMuted: boolean
): Promise<void> => {
  try {
    const response = await callModel.createCall({
      groupId: call.groupId,
      callType: call.callType,
      providerType: call.providerType,
    });

    await Tasks.calls.setCallInfoAndPublish({
      callInfo: response,
      call,
      isVideoMuted,
      isAudioMuted,
      withoutTimer: call.callType !== CallType.P2P,
    });
  } catch (e) {
    call.delete();
    if (e?.message === ServerErrorCode.HasNoPermissionsToPerformAction) {
      Tasks.app.showAlert(t('no_permission_alert'));
      return;
    }

    if (e?.message === ServerErrorCode.HasNoPermissionsToCall) {
      Tasks.app.showAlert(t('privacy_settings:privacy_settings_call_alert'));
      return;
    }
    Tasks.app.showAlert(t('calls_connection_error'));
  }
};

const createExistingCall = async (
  call: Call,
  isAudioMuted: boolean,
  isVideoMuted: boolean
): Promise<void> => {
  try {
    const channelId = call.channelId!;

    const response = await callModel.getCallInfo(
      {
        channelId,
        providerType: call.providerType,
      },
      call.callType
    );

    const canUserConnect = await Tasks.calls.checkCanUserConnectToCall(response);

    if (!canUserConnect) {
      call.delete();
      return;
    }

    await Tasks.calls.setCallInfoAndPublish({
      callInfo: response,
      call,
      isVideoMuted,
      isAudioMuted,
      withoutTimer: call.callType !== CallType.P2P,
    });
  } catch (e) {
    console.error(e);
    call.delete();
    Tasks.app.showAlert(t('calls_connection_error'));
  }
};

interface ICreateCallProps {
  groupId: string;
  channelId: string | null;
  withAudio: boolean;
  withVideo: boolean;
  callType: CallType;
  isSharedCall?: boolean;
}

export const startCall = async ({
  groupId,
  channelId = null,
  withAudio,
  withVideo,
  callType,
  isSharedCall,
}: ICreateCallProps): Promise<void> => {
  Entities.IncomingCalls.delete(groupId, channelId);
  let call = Entities.Calls.getCallByGroupId(groupId);

  if (!call) {
    call = new Call({
      calls: Entities.Calls,
      groupId,
      channelId,
      callType,
      isSharedCall,
      providerType: Entities.Calls.callsProvider,
    });
    Entities.Calls.add(call.groupId, call);
  }

  if (call.channelId) {
    await createExistingCall(call, !withAudio, !withVideo);
  } else {
    await createNewCall(call, !withAudio, !withVideo);
  }

  if (callType === CallType.Group || callType === CallType.Conference) {
    await Tasks.calls.getCallPermissions(call.channelId!);
  }

  const isMeInitiatorOwnerOrAdmin = call.isMeInitiatorOwnerOrAdmin;

  if (
    (callType === CallType.Group || callType === CallType.Conference) &&
    isMeInitiatorOwnerOrAdmin
  ) {
    await Tasks.calls.getPermissionRequests(call.channelId!);
  }

  call.setStarted(true);
};
