import { UserProfileUsernameBadge } from 'APP/model/user/userModel.types';

export enum RewardProgressInfoRewardEnum {
  UsernameBadge1 = 'USERNAME_BADGE_1',
  ExclusiveBackground1 = 'EXCLUSIVE_BACKGROUND_1',
  ExclusiveStickerPack1 = 'EXCLUSIVE_STICKER_PACK_1',
}

export enum RewardStatusEnum {
  Acknowledged = 'ACKNOWLEDGED',
  Achieved = 'ACHIEVED',
}

export enum PrivacyValue {
  Nobody = 'NONE',
  Anyone = 'ANYONE',
  Contacts = 'CONTACTS',
}

export interface IPrivacySettings {
  showPhoneNumber: PrivacyValue;
  showAvatar: PrivacyValue;
  addToGroup: PrivacyValue;
  addToPublic: PrivacyValue;
  callMe: PrivacyValue;
  showLastSeen: PrivacyValue;
  searchMe: PrivacyValue;
  sendVoiceMessage: PrivacyValue;
  updateTs?: number;
}

export interface IUpdateUserDisplayData {
  userName: string;
  avatarUrl?: string;
  nickName: string;
  bio?: string;
  usernameBadge?: UserProfileUsernameBadge;
}

export interface ISelfUserDisplayData {
  id: string;
  phone?: string;
  userName: string;
  avatarUrl?: string;
  nickName: string;
  bio?: string;
  usernameBadge?: UserProfileUsernameBadge;
  verified: boolean;
  updateTs: string;
}
