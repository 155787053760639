import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { IconButton } from 'APP/packages/UiKit/IconButton';
import PlusIcon from 'ICONS/ic-plus.svg';
import { Popover } from 'UIKIT/Popover/Popover';

import usePresenter from './presenter';
import styles from './styles.m.css';

const Menu = ({ fileUploadRef, parentPresenter, payload, order, isPayloadCreator }) => {
  const { presenter, popover } = usePresenter({
    parentPresenter,
    fileUploadRef,
    payload,
    order,
    isPayloadCreator,
  });

  if (!presenter.isShow) {
    return null;
  }

  const containerClass = classNames(styles.container, {
    [styles.popoverOpened]: popover.isOpened,
  });

  return (
    <>
      <IconButton
        onClick={presenter.togglePopover}
        setRef={popover.containerRef}
        Icon={PlusIcon}
        className={containerClass}
        iconClassName={styles.icon}
      />
      {popover.isOpened && (
        <Popover
          isOpened
          onClose={presenter.togglePopover}
          reference={popover.containerRef.current}
          placement="right"
          className={styles.popover}
        >
          {presenter.items.map((item) => {
            const { key, onClick, IconComponent } = item;

            return (
              <button
                key={key}
                className={styles.item}
                onClick={onClick}
              >
                <IconComponent className={styles.icon} />
              </button>
            );
          })}
        </Popover>
      )}
    </>
  );
};

export default observer(Menu);
