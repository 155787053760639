import { observer } from 'mobx-react';
import React from 'react';

import BotIcon from 'ICONS/ic-bot.svg';
import { FAB } from 'UIKIT/FAB/FAB';

import usePresenter from './presenter';

const BotCommandsButton = () => {
  const presenter = usePresenter();

  return (
    <FAB
      size="10"
      Icon={BotIcon}
      theme={presenter.isOpened ? '2_1' : '2'}
      onClick={presenter.toggle}
    />
  );
};

export default observer(BotCommandsButton);
