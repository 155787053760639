import { observer } from 'mobx-react';
import React from 'react';

import { IRenderer } from 'APP/components/TextRenderer/renderTree/renderTree.types';
import { getHref } from 'APP/utils/url';

import { useMarketplaceLinkRendererPresenter } from './MarketplaceLinkRenderer.presenter';

interface IMarketplaceLinkProps {
  link: string;
}

export const MarketplaceLinkRenderer: IRenderer<IMarketplaceLinkProps> = observer(
  ({ children, options: { link } }) => {
    const { onClick } = useMarketplaceLinkRendererPresenter(link);

    const href = getHref(link);

    if (!children) {
      return null;
    }

    return (
      <a
        href={href}
        target="_self"
        rel="noreferrer"
        onClick={onClick}
      >
        {children}
      </a>
    );
  }
);
