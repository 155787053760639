import { FC } from 'react';

import Tasks from 'APP/Tasks';
import { openPopup } from 'APP/Tasks/app/popup/popup';
import { CatalogWidgetType, ICatalogItem } from 'APP/model/catalog/catalogModel.types';
import { IGroup } from 'APP/model/group/groupModel.types';
import { ISpace } from 'APP/model/space/spaceModel.types';
import { IBotUserDisplayData } from 'APP/model/user/userModel.types';
import { useTranslation } from 'APP/packages/translations';
import { RouterLink, RouterState } from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import BotIcon from 'ICONS/ic-bot-16.svg';
import ChannelIcon from 'ICONS/ic-channel-16.svg';
import SpaceIcon from 'ICONS/ic-space-16.svg';
import { BotDetailedInfoPopup } from 'MAIN/PopupManager/Popups/BotDetailedInfoPopup/BotDetailedInfoPopup';
import Entities from 'STORE';
import { Bot } from 'STORE/Users/User/Bot/Bot';
import { TAvatarBackgroundColorIndex } from 'UIKIT/Avatar/Avatar.types';
import { getFormatNumberToString } from 'UTILS/getFormatNumberToString';

import { IWidgetItem, ICatalogWidgetComponentProps } from './CatalogWidget.types';
import { SliderWidget } from './SliderWidget/SliderWidget';
import { ThreeColumnWidget } from './ThreeColumnWidget/ThreeColumnWidget';
import { TwoColumnWidget } from './TwoColumnWidget/TwoColumnWidget';

interface ICatalogWidgetParams {
  type: CatalogWidgetType;
  showItemType: boolean;
  items: ICatalogItem[];
}

interface ICatalogWidgetPresenter {
  Component: FC<ICatalogWidgetComponentProps> | null;
  widgetItems: IWidgetItem[];
  channelToWidgetItem(channel: IGroup): IWidgetItem;
  spaceToWidgetItem(space: ISpace): IWidgetItem;
  botToWidgetItem(bot: IBotUserDisplayData): IWidgetItem;
  getAvatarColorIndex(id: string): TAvatarBackgroundColorIndex;
  onChannelClick(id: string): void;
  onSpaceClick(id: string): void;
  onBotClick(bot: Bot): void;
}

const componentsMap = {
  [CatalogWidgetType.Type1]: SliderWidget,
  [CatalogWidgetType.Type2]: ThreeColumnWidget,
  [CatalogWidgetType.Type3]: TwoColumnWidget,
};

export const useCatalogWidgetPresenter = (data: ICatalogWidgetParams): ICatalogWidgetPresenter => {
  const { type, showItemType, items } = data;
  const navigateTo = useNavigateTo();
  const { t } = useTranslation();

  const presenter: ICatalogWidgetPresenter = {
    get Component(): FC<ICatalogWidgetComponentProps> | null {
      return componentsMap[type] || null;
    },

    get widgetItems(): IWidgetItem[] {
      return items.reduce<IWidgetItem[]>((acc, item) => {
        if (item.channel) {
          acc.push(presenter.channelToWidgetItem(item.channel));
        } else if (item.space) {
          acc.push(presenter.spaceToWidgetItem(item.space));
        } else if (item.bot) {
          acc.push(presenter.botToWidgetItem(item.bot));
        }

        return acc;
      }, []);
    },

    channelToWidgetItem(channel: IGroup): IWidgetItem {
      return {
        id: channel.id,
        icon: showItemType ? ChannelIcon : null,
        avatarUrl: channel.avatarUrl,
        avatarColorIndex: presenter.getAvatarColorIndex(channel.id),
        title: channel.name,
        subscribers: t('channel_subscribers', { 0: getFormatNumberToString(channel.usersCount) }),
        description: channel.description || '',
        verified: channel.verified,
        onClick: () => presenter.onChannelClick(channel.id),
      };
    },

    spaceToWidgetItem(space: ISpace): IWidgetItem {
      return {
        id: space.id,
        icon: showItemType ? SpaceIcon : null,
        avatarUrl: space.avatarUrl,
        avatarColorIndex: presenter.getAvatarColorIndex(space.id),
        title: space.name,
        subscribers: t('space_member', { 0: getFormatNumberToString(space.participantsCount) }),
        description: space.description || '',
        verified: space.verified,
        onClick: () => presenter.onSpaceClick(space.id),
      };
    },

    botToWidgetItem(bot: IBotUserDisplayData): IWidgetItem {
      const botModel = new Bot(Entities.UsersStore, bot);

      return {
        id: bot.id,
        icon: showItemType ? BotIcon : null,
        avatarUrl: bot.avatarUrl,
        avatarColorIndex: presenter.getAvatarColorIndex(bot.id),
        title: bot.userName,
        subscribers: t('catalog_bot_installs', { 0: getFormatNumberToString(bot.installs) }),
        description: bot.description,
        onClick: () => presenter.onBotClick(botModel),
      };
    },

    getAvatarColorIndex(id: string): TAvatarBackgroundColorIndex {
      return id.at(-1) as TAvatarBackgroundColorIndex;
    },

    onChannelClick(id: string): void {
      Tasks.group.setActiveGroup({
        groupId: id,
        isChannel: true,
        showAlert: true,
        state: {
          [RouterState.hasGoBack]: true,
        },
      });
    },

    onSpaceClick(id: string): void {
      navigateTo({
        to: RouterLink.space,
        params: { id },
        state: { [RouterState.goBackUrl]: RouterLink.catalog },
      });
    },

    onBotClick(bot: Bot): void {
      const botFromStore = Entities.UsersStore.getUserById(bot.id);
      openPopup(BotDetailedInfoPopup, {
        params: { bot: botFromStore || bot },
      });
    },
  };

  return presenter;
};
