import React, { FC } from 'react';

import { AudioState } from 'MAIN/components/AudioState/AudioState';
import { PlayIconType } from 'UIKIT/PlayIcon/PlayIcon.types';

import { AllDownloadsItemInfo } from '../AllDownloadsItemInfo/AllDownloadsItemInfo';

export interface IAllDownloadsAudioItemProps {
  className?: string;
  payloadId: string;
  groupId: string;
  messageId: string;
}

export const AllDownloadsAudioItem: FC<IAllDownloadsAudioItemProps> = (props) => {
  const { className, payloadId, groupId, messageId } = props;

  return (
    <div className={className}>
      <AudioState
        groupId={groupId}
        messageId={messageId}
        payloadId={payloadId}
        iconType={PlayIconType.Play}
      />
      <AllDownloadsItemInfo
        payloadId={payloadId}
        groupId={groupId}
        messageId={messageId}
      />
    </div>
  );
};
