import api from 'APP/packages/api';
import logger from 'APP/packages/logger';

import { IBooleanResponse } from '../common/commonModel.types';
import { IUpdateDraftInputRequest } from '../draft/draftModel.types';
import { buildModelError } from '../error/errorBuilder';

export const updateDraftInput = async (
  data: IUpdateDraftInputRequest
): Promise<IBooleanResponse> => {
  try {
    return api.group.updateDraftInput(data);
  } catch (error) {
    logger.get('API').error('group.updateDraftInput', error);
    throw buildModelError(error);
  }
};
