import { entries } from 'mobx';
import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import { SHOW_COMPLETE_DOWNLOAD_TIME } from 'APP/constants/app';
import { useTranslation } from 'APP/packages/translations';
import { IDownloadProgress } from 'APP/store/Messages/DownloadProgress/DownloadProgress.types';
import Entities from 'STORE';
import Advertisement from 'STORE/Messages/Message/Payload/Advertisement';
import Album from 'STORE/Messages/Message/Payload/Album';
import Article from 'STORE/Messages/Message/Payload/Article';
import FilePayload from 'STORE/Messages/Message/Payload/File';
import { getMessagePayload } from 'UTILS/message/message';

export interface IAllDownload {
  groupId: string;
  payloadId: string;
  messageId: string;
  downloadProgress: IDownloadProgress;
}
interface IAllDownloadsPresenter {
  hidePanelTimeoutId: ReturnType<typeof setTimeout> | null;
  downloads: IAllDownload[];
  downloadsText: string;
  isAllCompletedSuccessfully: boolean;
  isFiledDownloads: boolean;
  isListOpen: boolean;
  activeDownloads: IAllDownload[];
  completedDownloads: IAllDownload[];
  failedDownloads: IAllDownload[];
  allDownloadsPercent: number;
  lastPanelVisibleTs: number;
  isShowPanel: boolean;
  panelCanBeHidden: boolean;
  panelCanBeShown: boolean;
  closeList(): void;
  toggleListOpen(): void;
  getPayload(download: IAllDownload): FilePayload | (Album | Advertisement | Article)['payloads'];
  setHidePanelTimeout(): void;
  clearHidePanelTimeout(): void;
}

export const useAllDownloadsPresenter = (): IAllDownloadsPresenter => {
  const { t } = useTranslation();

  const presenter = useLocalStore<IAllDownloadsPresenter>(() => ({
    isListOpen: false,
    hidePanelTimeoutId: null,
    isShowPanel: false,
    lastPanelVisibleTs: 0,

    get downloads(): IAllDownload[] {
      const groupsDownloads = Entities.GroupsStore.groups.reduce((acc, group) => {
        const messagesStore = group.messagesStore;

        if (messagesStore.downloadProgressPerPayload.downloads.length) {
          const downloadData = entries(messagesStore.downloadProgressPerPayload.data).map(
            ([payloadId, downloadProgress]) => ({
              payloadId,
              groupId: downloadProgress.groupId,
              messageId: downloadProgress.messageId,
              downloadProgress,
            })
          );

          return [...acc, ...downloadData];
        }

        return [...acc];
      }, []);

      return groupsDownloads
        .filter(({ downloadProgress }) => {
          return downloadProgress.startDownloadTs > presenter.lastPanelVisibleTs;
        })
        .sort((a, b) => {
          return b.downloadProgress.startDownloadTs - a.downloadProgress.startDownloadTs;
        });
    },

    get activeDownloads(): IAllDownload[] {
      return presenter.downloads.filter((download: IAllDownload) => {
        return (
          !download.downloadProgress.hasError &&
          (download.downloadProgress.downloadPercent || 0) > 0 &&
          (download.downloadProgress.downloadPercent || 0) < 100
        );
      });
    },

    get completedDownloads(): IAllDownload[] {
      return presenter.downloads.filter((download: IAllDownload) => {
        const payload = presenter.getPayload(download);
        const isFileEmpty = payload.fileSize === 0;

        if (!download.downloadProgress.hasError && isFileEmpty) {
          return true;
        }

        return (
          !download.downloadProgress.hasError && download.downloadProgress.downloadPercent === 100
        );
      });
    },

    get failedDownloads(): IAllDownload[] {
      return presenter.downloads.filter((download: IAllDownload) =>
        Boolean(download.downloadProgress.hasError)
      );
    },

    get allDownloadsPercent(): number {
      const downloadsData = [...presenter.activeDownloads, ...presenter.completedDownloads].reduce(
        (acc, download) => {
          const loaded = download.downloadProgress.progressEvent?.loaded || 0;
          const total = download.downloadProgress.progressEvent?.total || 0;
          const allFilesSizes = acc.allFilesSizes + total;
          const allFilesLoaded = acc.allFilesLoaded + loaded;

          return { ...acc, allFilesSizes, allFilesLoaded };
        },
        { allFilesLoaded: 0, allFilesSizes: 0 }
      );

      return (downloadsData.allFilesLoaded / downloadsData.allFilesSizes) * 100;
    },

    get isAllCompletedSuccessfully(): boolean {
      return (
        Boolean(presenter.downloads.length) &&
        presenter.activeDownloads.length === 0 &&
        presenter.completedDownloads.length > 0 &&
        presenter.failedDownloads.length === 0
      );
    },

    get isFiledDownloads(): boolean {
      return (
        Boolean(presenter.downloads.length) &&
        presenter.activeDownloads.length === 0 &&
        presenter.completedDownloads.length >= 0 &&
        presenter.failedDownloads.length > 0
      );
    },

    get downloadsText(): string {
      const isAllDownloadsFailed = presenter.downloads.length === presenter.failedDownloads.length;

      if (presenter.allDownloadsPercent === 100 || isAllDownloadsFailed) {
        if (presenter.isFiledDownloads) {
          const filedDownloadsLength = presenter.failedDownloads.length;

          return filedDownloadsLength > 1
            ? t('common_downloads_failed_few', { 0: filedDownloadsLength })
            : t('common_download_failed_one', { 0: filedDownloadsLength });
        }

        if (presenter.isAllCompletedSuccessfully) {
          const completeDownloadsLength = presenter.completedDownloads.length;

          return completeDownloadsLength > 1
            ? t('common_downloads_completed_few', { 0: completeDownloadsLength })
            : t('common_download_completed_one', { 0: completeDownloadsLength });
        }
      }

      const activeDownloadsLength = presenter.downloads.length;
      return activeDownloadsLength > 1
        ? t('common_file_few', { 0: activeDownloadsLength })
        : t('common_file_one', { 0: activeDownloadsLength });
    },

    get panelCanBeHidden(): boolean {
      return (
        presenter.isShowPanel &&
        !presenter.isListOpen &&
        !presenter.activeDownloads.length &&
        !presenter.failedDownloads.length
      );
    },

    get panelCanBeShown(): boolean {
      return Boolean(
        presenter.activeDownloads.length ||
          presenter.completedDownloads.length ||
          presenter.failedDownloads.length
      );
    },

    getPayload(
      download: IAllDownload
    ): FilePayload | (Album | Advertisement | Article)['payloads'] {
      const group = Entities.GroupsStore.getGroupById(download.downloadProgress.groupId);
      const message = group?.messagesStore.getMessageById(download.downloadProgress.messageId);
      if (!message) {
        return {};
      }
      return getMessagePayload(message, download.payloadId) || {};
    },

    closeList(): void {
      presenter.isListOpen = false;
    },

    toggleListOpen(): void {
      presenter.isListOpen = !presenter.isListOpen;
    },

    setHidePanelTimeout(): void {
      presenter.clearHidePanelTimeout();

      presenter.hidePanelTimeoutId = setTimeout(() => {
        presenter.isShowPanel = false;
        presenter.lastPanelVisibleTs = Date.now();
      }, SHOW_COMPLETE_DOWNLOAD_TIME * 1000);
    },

    clearHidePanelTimeout(): void {
      if (presenter.hidePanelTimeoutId) {
        clearTimeout(presenter.hidePanelTimeoutId);
      }
    },
  }));

  useEffect(() => {
    if (presenter.panelCanBeHidden) {
      presenter.setHidePanelTimeout();
    } else {
      presenter.clearHidePanelTimeout();
    }

    if (!presenter.downloads.length) {
      presenter.clearHidePanelTimeout();
      presenter.isListOpen = false;
      presenter.isShowPanel = false;
    }
  }, [presenter.panelCanBeHidden, presenter.downloads.length]);

  useEffect(() => {
    if (presenter.panelCanBeShown) {
      presenter.isShowPanel = true;
    }
  }, [presenter.panelCanBeShown]);

  return presenter;
};
