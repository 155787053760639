import { getLayout } from './getLayout';

export { MAX_ITEMS_PER_ROW, MAX_ROW_COUNT } from './constants';

export interface IImageSize {
  w: number;
  h: number;
  id: number;
  containerWidth: number;
  containerRatio: number;
}

/**
 * @typedef {Object} Input
 * @property {number} w - element width
 * @property {number} y - element height
 * @property {number} id - element id
 */

/**
 * @typedef {Object} Output
 * @property {number} w - element width
 * @property {number} y - element height
 * @property {number} id - element id
 * @property {number} containerWidth - the relative width of the element in the row
 * @property {number} containerRatio - the calculated aspect ratio of the element
 * may differ from the original
 */

/**
 * @param {Input[]} list
 * @return {Output[][]} two-dimensional array
 */
export const getAlbumLayout = (list: IImageSize[]): IImageSize[][] => {
  const layout = getLayout(list);
  // delete empty cells and rows
  return layout
    .map((row) => (row as IImageSize[]).filter((x) => x !== null))
    .filter((row) => row.length);
};
