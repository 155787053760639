import { FC, useEffect, useRef } from 'react';

import { openPopup } from 'APP/Tasks/app/popup/popup';
import { ICommonPopupsProps } from 'APP/main/PopupManager/PopupManager.types';
import { Popup } from 'APP/store/App/Popups/Popup/Popup';

import {
  ChangePhoneAlertPopup,
  IChangePhoneAlertPopupParams,
} from '../../ChangePhoneAlertPopup/ChangePhoneAlertPopup';
import { ChangePhonePopup } from '../../ChangePhonePopup/ChangePhonePopup';

interface IPhoneProviderPresenter {
  onChangeNumber(): void;
  onAddPhoneNumber(): void;
}

interface IPhoneProviderPresenterProps {
  popupInstance: Popup;
  phoneNumber: string;
}

export const usePhoneProviderPresenter = ({
  popupInstance,
  phoneNumber,
}: IPhoneProviderPresenterProps): IPhoneProviderPresenter => {
  const childPopupRef = useRef<Popup>();

  const onAddPhoneNumber = (): void => {
    openChildPopup(ChangePhonePopup);
  };

  const onChangeNumber = (): void => {
    childPopupRef.current = openPopup<IChangePhoneAlertPopupParams>(ChangePhoneAlertPopup, {
      params: { phoneNumber, onChangeClick: () => openChildPopup(ChangePhonePopup) },
    });
  };

  const openChildPopup = (Component: FC<ICommonPopupsProps>): void => {
    popupInstance.hide();

    childPopupRef.current = openPopup(Component, {
      onClose: () => popupInstance?.show(),
      onBack: () => popupInstance?.show(),
    });
  };

  useEffect(() => {
    return () => childPopupRef.current?.close();
  }, []);

  return {
    onChangeNumber,
    onAddPhoneNumber,
  };
};
