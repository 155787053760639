import { action, computed, observable } from 'mobx';

import { Group } from 'STORE/Groups/Group';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

export class PinnedMessages {
  @observable data = observable.array<string>([]);
  @observable currentPinnedMessageId: string | null = null;

  group: Group;

  constructor(group: Group) {
    this.group = group;
  }

  getCurrentPinnedMessageId(): string | null {
    if (!this.currentPinnedMessageId) {
      this.setLastPinnedMessageId();
      return this.currentPinnedMessageId;
    }

    return this.currentPinnedMessageId;
  }

  @action
  setCurrentPinnedMessageId(id: string | null): void {
    this.currentPinnedMessageId = id;
  }

  @action
  removeId(id: string): void {
    if (this.data.includes(id)) {
      this.removeByIds([id]);
    }
  }

  @action
  removeByIds(removeIds: string[]): void {
    this.data.replace(this.data.filter((id) => !removeIds.includes(id)));

    if (this.currentPinnedMessageId && removeIds.includes(this.currentPinnedMessageId)) {
      this.setLastPinnedMessageId();
    }

    if (!this.data.length) {
      this.setCurrentPinnedMessageId(null);
    }
  }

  @action
  set(ids: string[]): void {
    this.data.push(...ids);
    this.setLastPinnedMessageId();
  }

  @action
  setLastPinnedMessageId(): void {
    if (this.messages.length) {
      const lastPinnedMessage = this.messages[this.messages.length - 1];

      if (lastPinnedMessage) {
        this.setCurrentPinnedMessageId(lastPinnedMessage.id);
      }
    } else {
      this.setCurrentPinnedMessageId(null);
    }
  }

  @computed
  get messages(): ChatMessage[] {
    return this.data
      .map((id) => this.group.messagesStore.getMessageById(id))
      .filter((message) => message !== null)
      .sort(
        (a: ChatMessage, b: ChatMessage) => a.expectedServerTime - b.expectedServerTime
      ) as ChatMessage[];
  }

  @computed
  get listIds(): string[] {
    return this.messages.map((message) => message.id);
  }

  @action
  setPrevMessageIdOnFocusBased(id: string): void {
    this.setCurrentPinnedMessageId(id);
    this.setPrevPinnedMessageId();
  }

  @action
  setPrevPinnedMessageId(): void {
    const pinnedMessages = this.messages;
    const currentPinMessageIndex = pinnedMessages.findIndex((pinMessage) => {
      return pinMessage.id === this.getCurrentPinnedMessageId();
    });

    if (currentPinMessageIndex !== -1) {
      let index = currentPinMessageIndex - 1;

      if (index < 0) {
        index = pinnedMessages.length - 1;
      }

      const prevPinMessage = pinnedMessages[index];
      this.setCurrentPinnedMessageId(prevPinMessage.id);
    }
  }

  @computed
  get currentPinnedMessage(): ChatMessage | null {
    const currentPin = this.messages.find((pinMessage) => {
      return pinMessage.id === this.getCurrentPinnedMessageId();
    });

    return currentPin || null;
  }

  @action
  clear(): void {
    this.data.clear();
  }
}
