import { openStream } from 'APP/packages/streamApi/stream/stream';

const ROOT_API = 'calls';
const GUEST_API = 'unauthorized/calls';

export function initCallListener({
  channelId,
  isGuestCall = false,
  onActionCallData = undefined,
  onUserCallData,
  onActiveCallData,
  onRecordingData = undefined,
  onPermissionsData = undefined,
  onMemberPermissionsData = undefined,
  onCreatePermissionsRequestData = undefined,
  onChangePermissionRequestData = undefined,
  onStatusCallChanged = undefined,
  onError = undefined,
}) {
  return openStream(
    {
      rootApi: `${isGuestCall ? GUEST_API : ROOT_API}/${channelId}`,
    },
    [
      { eventName: 'calls.status-changed', onData: onStatusCallChanged },
      { eventName: 'calls.active.call-action', onData: onActionCallData },
      { eventName: 'calls.active.call-changed', onData: onActiveCallData },
      { eventName: 'calls.active.user-changed', onData: onUserCallData },
      { eventName: 'calls.active.recording-started', onData: onRecordingData },
      { eventName: 'calls.active.recording-stopped', onData: onRecordingData },
      {
        eventName: 'calls.active.permissions-changed',
        onData: onPermissionsData,
      },
      {
        eventName: 'calls.active.user-permissions-changed',
        onData: onMemberPermissionsData,
      },
      {
        eventName: 'calls.active.permission-request-created',
        onData: onCreatePermissionsRequestData,
      },
      {
        eventName: 'calls.active.permission-request-changed',
        onData: onChangePermissionRequestData,
      },
    ],
    onError
  );
}
