export default ({ openBracketPositions, closeBracketPositions }) => {
  let indexesToRemoveOpenBracket = [];
  let indexesToRemoveCloseBracket = [];

  let openBracketIndex = 0;
  let closeBracketIndex = 0;

  while (
    openBracketIndex < openBracketPositions.length ||
    closeBracketIndex < closeBracketPositions.length
  ) {
    const currentOpenBracket = openBracketPositions[openBracketIndex];
    const nextOpenBracket = openBracketPositions[openBracketIndex + 1];

    const currentCloseBracket = closeBracketPositions[closeBracketIndex];

    // exist open bracket and not exist close tag
    if (currentOpenBracket && !currentCloseBracket) {
      indexesToRemoveOpenBracket.push(openBracketIndex);
      openBracketIndex++;
      continue;
    }

    // not exist open bracket and exist close tag
    if (!currentOpenBracket && currentCloseBracket) {
      indexesToRemoveCloseBracket.push(closeBracketIndex);
      closeBracketIndex++;
      continue;
    }

    // next start bracket earlier than close bracket
    if (nextOpenBracket && nextOpenBracket.start < currentCloseBracket.start) {
      indexesToRemoveOpenBracket.push(openBracketIndex);
      openBracketIndex++;
      continue;
    }

    // open bracket from right side of close tag
    if (currentOpenBracket.start > currentCloseBracket.start) {
      indexesToRemoveCloseBracket.push(closeBracketIndex);
      closeBracketIndex++;
      continue;
    }

    // has no content between tags
    if (currentOpenBracket.end === currentCloseBracket.start) {
      indexesToRemoveOpenBracket.push(openBracketIndex);
      openBracketIndex++;
      continue;
    }

    // link close tag
    currentOpenBracket.closeTag = currentCloseBracket;

    // all fine
    openBracketIndex++;
    closeBracketIndex++;
  }

  return {
    indexesToRemoveOpenBracket,
    indexesToRemoveCloseBracket,
  };
};
