import classNames from 'classnames';
import React, { FC, memo } from 'react';

import styles from './Loader.styles.m.css';

type LoaderSize = 'small' | 'medium' | 'big';
export type LoaderColor = 'primary' | 'current' | 'base' | 'secondary';

interface ILoaderProps {
  readonly className?: string;
  readonly size?: LoaderSize;
  readonly color?: LoaderColor;
}

export const Loader: FC<ILoaderProps> = memo(({ className, size = 'small', color = 'primary' }) => {
  // if two loaders with different colors on the page at the same time
  // we have conflict between ids
  const id = `gradient-${Math.floor(Math.random() * 1000)}`;

  return (
    <svg
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(styles.loader, className, styles[`color--${color}`], styles[size])}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14C11.3137 14 14 11.3137 14 8H16C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0V2Z"
        fill={`url(#${id})`}
      />
      <defs>
        <linearGradient
          id={id}
          x1="0.222222"
          y1="3.72222"
          x2="15.3889"
          y2="6.05556"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop
            offset="1"
            stopOpacity="0"
          />
        </linearGradient>
      </defs>
    </svg>
  );
});
