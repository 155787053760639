import { useLocalStore } from 'mobx-react';

import Entities from 'APP/store';

export default ({ groupId }) => {
  const presenter = useLocalStore(() => ({
    linkToBack: () => {
      return presenter.group ? `/${presenter.group.route}/${groupId}` : '';
    },

    get group() {
      return Entities.GroupsStore.getGroupById(groupId);
    },

    get count() {
      return presenter.group && presenter.group.draftsStore.drafts.length;
    },
  }));

  return presenter;
};
