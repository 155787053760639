import { buildModelError } from 'APP/model/error/errorBuilder';
import { mapUserDisplayDataResponse } from 'APP/model/user/userModel.mapper';
import {
  IGetUsersDisplayDataRequest,
  IMarkAsUnknownRequest,
  IUserDisplayData,
} from 'APP/model/user/userModel.types';
import api from 'APP/packages/api';
import logger from 'APP/packages/logger';

export const markAsUnknown = async (data: IMarkAsUnknownRequest): Promise<void> => {
  try {
    await api.relationships.markAsUnknown({
      userId: data.userId,
      isUnknown: data.isUnknown,
    });
  } catch (error) {
    logger.get('API').error('user.markAsUnknown', error);
    throw buildModelError(error);
  }
};

export async function getUsersDisplayData(
  data: IGetUsersDisplayDataRequest
): Promise<IUserDisplayData[]> {
  try {
    const { usersDisplayData } = await api.relationships.getUsersDisplayData({
      userIds: data.userIds,
    });

    return usersDisplayData.map(mapUserDisplayDataResponse);
  } catch (error) {
    logger.get('API').error('getUsersDisplayData', error);
    throw buildModelError(error);
  }
}
