export enum CallBlurType {
  None = 'none',
  Small = 'small',
  Large = 'large',
}

export const BlurOptions = {
  [CallBlurType.Small]: { type: 'blur', blurDegree: 1 },
  [CallBlurType.Large]: { type: 'blur', blurDegree: 3 },
};
