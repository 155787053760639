export default (text, index) => {
  let startPosition = index > 0 ? index - 1 : index;
  let endPosition = index < text.length ? index + 1 : index;

  while (startPosition > 0) {
    if ([' ', '\n'].includes(text[startPosition])) {
      startPosition++;
      break;
    }

    startPosition--;
  }

  while (endPosition < text.length) {
    if ([' ', '\n'].includes(text[endPosition])) {
      break;
    }

    endPosition++;
  }

  return {
    startPosition,
    endPosition,
    content: text.substring(startPosition, endPosition),
  };
};
