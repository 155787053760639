import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { AddKeyPressHandler } from 'APP/packages/keyBoardHandler';
import { SpaceCollectionItems } from 'MAIN/SpaceView/SpaceCollection/SpaceCollectionItems/SpaceCollectionItems';
import { ChatBackground } from 'MAIN/components/ChatBackground/ChatBackground';

import { useSpaceCollectionPresenter } from './SpaceCollection.presenter';
import styles from './SpaceCollection.styles.m.css';
import { SpaceCollectionHeader } from './SpaceCollectionHeader/SpaceCollectionHeader';

interface ISpaceCollectionProps {
  spaceId?: string;
  collectionId?: string;
}

export const SpaceCollection: FC<ISpaceCollectionProps> = observer(({ spaceId, collectionId }) => {
  const presenter = useSpaceCollectionPresenter(spaceId, collectionId);

  return (
    <div className={styles.container}>
      <AddKeyPressHandler onEsc={presenter.onClose} />

      <ChatBackground />

      <SpaceCollectionHeader
        space={presenter.space}
        collection={presenter.collection}
        onBack={presenter.onClose}
        onEdit={presenter.onEdit}
        onDelete={presenter.onDelete}
      />

      {presenter.space && presenter.collection && (
        <SpaceCollectionItems
          space={presenter.space}
          collectionId={presenter.collection.id}
        />
      )}
    </div>
  );
});
