import React, { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { RouterLink } from 'APP/router/constants';
import { SpaceCollection } from 'MAIN/SpaceView/SpaceCollection/SpaceCollection';

import { SpaceContent } from './SpaceContent/SpaceContent';
import { SpaceMarket } from './SpaceMarket/SpaceMarket';
import { SpacePrimaryMarket } from './SpacePrimaryMarket/SpacePrimaryMarket';

interface ISpaceViewProps {
  spaceId?: string;
}

export const SpaceView: FC<ISpaceViewProps> = ({ spaceId }) => {
  const match = useRouteMatch<{ collectionId?: string }>({ path: RouterLink.spaceCollection });

  return (
    <Switch>
      <Route path={RouterLink.spaceMarket}>
        <SpacePrimaryMarket spaceId={spaceId} />
      </Route>
      <Route path={RouterLink.spaceMarkets}>
        <SpaceMarket spaceId={spaceId} />
      </Route>
      <Route path={RouterLink.spaceCollection}>
        <SpaceCollection
          spaceId={spaceId}
          collectionId={match?.params.collectionId}
        />
      </Route>
      <Route path={RouterLink.space}>
        <SpaceContent spaceId={spaceId} />
      </Route>
    </Switch>
  );
};
