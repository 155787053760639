import classNames from 'classnames';
import React, { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { useTranslation } from 'APP/packages/translations';
import CloseIcon from 'ICONS/close.svg';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import { useClosePopupOnNavigation } from 'MAIN/PopupManager/hooks/useClosePopupOnNavigation';
import { MyProfile } from 'MAIN/Profile/MyProfile/MyProfile';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';
import { IconButton } from 'UIKIT/IconButton';
import { Link } from 'UIKIT/Link/Link';

import { useMyProfilePopupPresenter } from './MyProfilePopup.presenter';
import styles from './MyProfilePopup.styles.m.css';

export const MyProfilePopup: FC<ICommonPopupsProps> = ({ popupInstance }) => {
  const { t } = useTranslation();
  const presenter = useMyProfilePopupPresenter(popupInstance);
  useClosePopupOnNavigation(popupInstance);

  return (
    <Popup
      isOpened
      isHidden={popupInstance.isHidden}
      onClose={presenter.onClose}
    >
      <div
        className={classNames(styles.scrollContainer, customScrollStyles.container)}
        data-test="my-profile-popup"
      >
        <IconButton
          Icon={CloseIcon}
          className={styles.close}
          data-test="close-popup"
          onClick={presenter.onClose}
        />
        <MyProfile
          metaBlockClassName={styles.profileMetaBlock}
          popupInstance={popupInstance}
        />
        <div className={styles.about}>
          <Link
            className={styles.link}
            color="primary"
            underline={false}
            onClick={presenter.onOpenAbout}
          >
            {t('about_gem4me')}
          </Link>
          <p className={styles.version}>{presenter.appVersion}</p>
        </div>
      </div>
    </Popup>
  );
};
