import React from 'react';

import Tasks from 'APP/Tasks';
import { useTranslation } from 'APP/packages/translations';
import IcStopPoll from 'ICONS/ic-stop-poll.svg';
import { IconView } from 'UIKIT/IconView/IconView';

import styles from './styles.m.css';

const SessionsTerminateButton = () => {
  const { t } = useTranslation();

  return (
    <button
      onClick={Tasks.userSettings.terminateAllOtherSessions}
      className={styles.button}
    >
      <IconView
        Icon={IcStopPoll}
        className={styles.icon}
      />
      {t('common_terminate_all_sessions')}
    </button>
  );
};

export default SessionsTerminateButton;
