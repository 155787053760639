import { computed } from 'mobx';

import { ISharedDataItem } from 'APP/model/sharedData/sharedDataModel.types';
import { Payload } from 'STORE/Messages/Message/Payload';

import { SharedDataItem } from '../SharedDataItem/SharedDataItem';

type TSharedAudioItem = ISharedDataItem<Payload>;

export class SharedLinks extends SharedDataItem {
  @computed
  get items(): TSharedAudioItem[] {
    const messages = this.messages.getFilteredMessages((message) => message.links.length > 0);

    return messages.map((message) => ({
      id: message.clientUuid,
      messageId: message.id,
      groupId: message.groupId,
      payload: message.payload,
      links: message.links,
      serverTime: message.serverTime,
    }));
  }

  @computed
  get count(): number {
    return this._count || this.items.length;
  }
}
