import { observer } from 'mobx-react';
import React from 'react';

import { GroupAvatar } from 'APP/components/Avatar/GroupAvatar/GroupAvatar';
import { Avatar } from 'UIKIT/Avatar/Avatar';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';

import usePresenter from './presenter';
import styles from './styles.m.css';

const NoVideo = () => {
  const presenter = usePresenter();
  if (!presenter.group) {
    return null;
  }

  if (presenter.streamer) {
    return (
      <div className={styles.container}>
        <Avatar
          type={AvatarTypes.Square_70}
          title={presenter.streamer.username}
          url={presenter.streamer.avatarUrl}
          backgroundColorIndex={presenter.streamer.avatarColorIndex}
        />
        <div className={styles.title}>{presenter.streamer.username}</div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <GroupAvatar
        group={presenter.group}
        type={AvatarTypes.Square_70}
      />
      <div className={styles.title}>{presenter.title}</div>
    </div>
  );
};

export default observer(NoVideo);
