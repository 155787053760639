import { observer } from 'mobx-react';
import React, { FC, MutableRefObject } from 'react';

import { OnboardingType } from 'APP/constants/onboarding';
import { useOnboardingManager } from 'APP/main/hooks/onboardingManager/useOnboardingManager';
import { useTranslation } from 'APP/packages/translations';
import { OnboardingPopover } from 'UIKIT/OnboardingPopover/OnboardingPopover';

import styles from './styles.m.css';

interface ScheduleOnboardingProps {
  reference: MutableRefObject<HTMLButtonElement | null>;
}

export const ScheduleOnboarding: FC<ScheduleOnboardingProps> = observer(({ reference }) => {
  const { t } = useTranslation();
  const presenter = useOnboardingManager(OnboardingType.ScheduleMessage);

  if (!presenter.isNeedToShow) {
    return null;
  }

  return (
    <OnboardingPopover
      className={styles.container}
      reference={reference}
      description={t('channels:onboarding_postponed_sending_web')}
      isOpened={presenter.isOpened}
      handleClose={presenter.handleClose}
    />
  );
});
