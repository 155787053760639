import { useAsObservableSource, useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import Entities from 'APP/store';

interface IScheduleButtonPresenter {
  isEditMode: boolean;
  isDisabled: boolean;
  onSubmit(): void;
}

export const useScheduleButtonPresenter = (): IScheduleButtonPresenter => {
  const navigateTo = useNavigateTo();
  const source = useAsObservableSource({ navigateTo });

  const presenter = useLocalStore<IScheduleButtonPresenter>(() => ({
    get isEditMode(): boolean {
      return Entities.InputPanel.edit.isEditMode;
    },

    get isDisabled(): boolean {
      return Entities.InputPanel.isDisabled;
    },

    onSubmit(): void {
      const canSubmit = Entities.InputPanel.send.checkGroupPermissions(false);
      if (!canSubmit.result) {
        canSubmit.error && Entities.toast.show(canSubmit.error);
        return;
      }

      if (presenter.isEditMode && Entities.InputPanel.showInputPanel) {
        Entities.InputPanel.send.schedule();
      } else {
        Tasks.group.goToScheduleSettings({ navigateTo: source.navigateTo });
      }
    },
  }));

  return presenter;
};
