import { useAsObservableSource, useLocalStore } from 'mobx-react';

import Entities from 'APP/store';

import { GroupBadgeType } from './GroupBadge.constants';

export function useGroupBadgePresenter(type: GroupBadgeType) {
  const source = useAsObservableSource({ type });

  return useLocalStore(() => ({
    get hasNewMessages() {
      return source.type === GroupBadgeType.Chat
        ? Entities.GroupsStore.hasNewGroupMessagesWithoutMute
        : Entities.GroupsStore.hasNewChannelMessagesWithoutMute;
    },
  }));
}
