import { observer } from 'mobx-react';
import React from 'react';

import { CallActionsPanel } from 'MAIN/OngoingCall/Shared/CallActionsPanel/CallActionsPanel';

import { CompactScreenVideoLayout } from '../Shared/CompactScreenVideoLayout/CompactScreenVideoLayout';
import { SpeakerVideo } from '../Shared/SpeakerVideo/SeakerVideo';
import { useGroupVideoCallPresenter } from './GroupVideoCall.presenter';
import styles from './GroupVideoCall.styles.m.css';
import { GroupVideoCallHeader } from './GroupVideoCallHeader/GroupVideoCallHeader';
import { MembersPanel } from './MembersPanel/MembersPanel';

export const GroupVideoCall = observer(() => {
  const { ongoingCall } = useGroupVideoCallPresenter();

  return (
    <>
      {!ongoingCall.members.isMembersShown && (
        <div className={styles.container}>
          <GroupVideoCallHeader />
          {ongoingCall.presentVideoMode.isGridLayout ? (
            <CompactScreenVideoLayout />
          ) : (
            <SpeakerVideo />
          )}
          <CallActionsPanel />
        </div>
      )}
      {ongoingCall.members.isMembersShown && (
        <div className={styles.membersPanelContainer}>
          <MembersPanel call={ongoingCall.call} />
        </div>
      )}
    </>
  );
});
