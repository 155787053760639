import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import { CatalogType, ICatalogItem } from 'APP/model/catalog/catalogModel.types';
import Entities from 'STORE';
import { isEqualArrays } from 'UTILS/isEqualArrays';

import { TCatalogResetHandler } from '../Catalog.types';
import { loadCatalogCategory } from '../Tasks/loadCatalogCategory';

interface ICatalogCategoryContentLocalStore {
  catalogType: CatalogType;
  categoryId: string | null;
  localeIds: string[];
  isLoading: boolean;
  isLoadingView: boolean;
  isLoadingMoreView: boolean;
  isEmptyView: boolean;
  isContentView: boolean;
  isShowItemType: boolean;
  viewObserverKey: string;
  items: ICatalogItem[];
  load(cursor?: string): Promise<void>;
  loadMore(): void;
  onViewObserver(isView: boolean): void;
}

export const useCatalogCategoryContentPresenter = (onCatalogReset: TCatalogResetHandler) => {
  const presenter = useLocalStore<ICatalogCategoryContentLocalStore>(() => ({
    catalogType: Entities.catalogStore.type,
    categoryId: Entities.catalogStore.categoryId,
    localeIds: Entities.userPreferences.localeIds,
    isLoading: false,

    get isLoadingView(): boolean {
      return presenter.isLoading && presenter.items.length === 0;
    },

    get isLoadingMoreView(): boolean {
      return presenter.isLoading && presenter.items.length > 0;
    },

    get isEmptyView(): boolean {
      return !presenter.isLoadingView && presenter.items.length === 0;
    },

    get isContentView(): boolean {
      return !presenter.isLoadingView && !presenter.isEmptyView;
    },

    get isShowItemType(): boolean {
      return Entities.catalogStore.type === CatalogType.All;
    },

    get viewObserverKey(): string {
      return `viewObserver-${presenter.items.length}`;
    },

    get items(): ICatalogItem[] {
      return Entities.catalogStore.items;
    },

    async load(cursor?: string): Promise<void> {
      if (!presenter.categoryId) {
        return;
      }

      presenter.isLoading = true;

      const prevCatalogType = presenter.catalogType;
      const prevCategoryId = presenter.categoryId;
      const prevLocaleIds = presenter.localeIds;
      const result = await loadCatalogCategory({
        catalogType: presenter.catalogType,
        categoryId: presenter.categoryId,
        cursor,
      });

      if (
        prevCatalogType !== presenter.catalogType ||
        prevCategoryId !== presenter.categoryId ||
        prevLocaleIds !== presenter.localeIds ||
        (cursor && cursor !== Entities.catalogStore.cursor) ||
        Entities.catalogStore.searchText
      ) {
        return;
      }

      if (!result) {
        Entities.catalogStore.setError(true);
        presenter.isLoading = false;
        return;
      }

      Entities.catalogStore.setItems([...Entities.catalogStore.items, ...result.items]);
      Entities.catalogStore.setCursor(result.cursor || null);
      presenter.isLoading = false;
    },

    loadMore(): void {
      if (presenter.isLoading || !Entities.catalogStore.cursor) {
        return;
      }

      presenter.load(Entities.catalogStore.cursor);
    },

    onViewObserver(isView: boolean): void {
      if (isView) {
        presenter.loadMore();
      }
    },
  }));

  useEffect(() => {
    if (presenter.items.length === 0) {
      presenter.load();
    }
  }, []);

  useEffect(() => {
    let hasChanges = false;

    if (presenter.catalogType !== Entities.catalogStore.type) {
      presenter.catalogType = Entities.catalogStore.type;
      hasChanges = true;
    }

    if (presenter.categoryId !== Entities.catalogStore.categoryId) {
      presenter.categoryId = Entities.catalogStore.categoryId;
      hasChanges = true;
    }

    if (!isEqualArrays(presenter.localeIds, Entities.userPreferences.localeIds, false)) {
      presenter.localeIds = Entities.userPreferences.localeIds;
      hasChanges = true;
    }

    if (hasChanges) {
      onCatalogReset();
      presenter.load();
    }
  }, [
    Entities.catalogStore.type,
    Entities.catalogStore.categoryId,
    Entities.userPreferences.localeIds,
  ]);

  return presenter;
};
