import React, { FC, memo } from 'react';

import { ISpaceCollection } from 'APP/model/space/spaceModel.types';
import CollectionIcon from 'ICONS/ic-collection.svg';
import { Avatar, AvatarTypes } from 'UIKIT/Avatar/Avatar';
import { getFormatNumberToString } from 'UTILS/getFormatNumberToString';

import styles from './SpaceCollectionsListItem.styles.m.css';

interface ISpaceCollectionItemProps {
  collection: ISpaceCollection;
  onClick(): void;
}

export const SpaceCollectionsListItem: FC<ISpaceCollectionItemProps> = memo(
  ({ collection, onClick }) => {
    return (
      <div
        className={styles.container}
        role="button"
        tabIndex={0}
        onClick={onClick}
      >
        <div className={styles.avatarWrapper}>
          <Avatar
            className={styles.avatar}
            type={AvatarTypes.Square_50}
            url={collection.avatarUrl}
          />

          <div className={styles.count}>
            <CollectionIcon className={styles.countIcon} />
            <span className={styles.countText}>
              {getFormatNumberToString(collection.itemsCount)}
            </span>
          </div>
        </div>

        <h6
          className={styles.name}
          title={collection.name}
        >
          {collection.name}
        </h6>
      </div>
    );
  }
);
