import { observer } from 'mobx-react';
import React from 'react';
import { FC } from 'react';

import { TextRenderer } from 'APP/components/TextRenderer/TextRenderer';
import { useTranslation } from 'APP/packages/translations';

import styles from './UnsupportedMessagePayload.styles.m.css';

interface IUnknownMessageProps {
  text: string;
}

export const UnsupportedMessagePayload: FC<IUnknownMessageProps> = observer(() => {
  const { t } = useTranslation();

  return (
    <TextRenderer
      className={styles.container}
      isPlainText
    >
      {t('message_type_unsupported')}
    </TextRenderer>
  );
});
