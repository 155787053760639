import { platformInfo } from 'APP/packages/platformInfo/platformInfo';

async function getMediaDevices() {
  try {
    const devices = await navigator.mediaDevices.enumerateDevices();

    return {
      hasMicrophone: Boolean(devices.find((device) => device.kind === 'audioinput')),
      hasCamera: Boolean(devices.find((device) => device.kind === 'videoinput')),
      // safari and FF not supported kind === 'audiooutput'. Chrome - 70% of users
      hasSpeaker:
        !platformInfo.isChrome || Boolean(devices.find((device) => device.kind === 'audiooutput')),
    };
  } catch (e) {
    return {
      hasMicrophone: false,
      hasCamera: false,
      hasSpeaker: false,
    };
  }
}

export default {
  getMediaDevices,
};
