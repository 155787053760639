import { observer } from 'mobx-react';
import React from 'react';

import UiCalendar from 'APP/packages/UiKit/Calendar';
import { useTranslation } from 'APP/packages/translations';
import { Button } from 'UIKIT/Button/Button';
import { PopupFooter } from 'UIKIT/PopupFooter/PopupFooter';

import usePresenter from './presenter';
import styles from './styles.m.css';

const Calendar = ({ common }) => {
  const presenter = usePresenter({ common });
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.container}>
        <UiCalendar
          value={presenter.date}
          minDate={presenter.minDate}
          onChange={presenter.onChange}
        />
      </div>

      <PopupFooter className={styles.footer}>
        <Button
          theme="6"
          onClick={presenter.onClose}
        >
          {t('common_cancel')}
        </Button>
        <Button
          theme="1"
          onClick={presenter.onChoose}
        >
          {t('common:common_apply')}
        </Button>
      </PopupFooter>
    </>
  );
};

export default observer(Calendar);
