import api from 'APP/packages/api';
import logger from 'APP/packages/logger';
import Entities from 'STORE';

const setSessionsTtl = async (duration) => {
  try {
    await api.userSettings.setSessionsTtl({ duration });
    Entities.sessionsStore.setSessionTtl(duration);
  } catch (error) {
    logger.get('API').error('userSettings.setSessionsTtl', error);
  }
};

export default setSessionsTtl;
