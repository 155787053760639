import { Sticker } from 'APP/packages/api/types/model/sticker';
import { StickerPack } from 'APP/packages/api/types/model/stickerPack';
import { StickerPackRequest } from 'APP/packages/api/types/model/stickerPackRequest';
import { StickerPackResponse } from 'APP/packages/api/types/model/stickerPackResponse';
import { StickerPackUpdatedEventPayloadResponse } from 'APP/packages/api/types/model/stickerPackUpdatedEventPayloadResponse';
import { StickerPacksListResponse } from 'APP/packages/api/types/model/stickerPacksListResponse';
import { UserStickerPacksResponse } from 'APP/packages/api/types/model/userStickerPacksResponse';
import { UserStickerPacksUpdatedEventPayloadResponse } from 'APP/packages/api/types/model/userStickerPacksUpdatedEventPayloadResponse';

import { IDeepLinkInfo, IGraphicSize } from '../common/commonModel.types';

export interface IStickerPack extends Omit<StickerPack, 'deepLink'> {
  deepLink: IDeepLinkInfo;
}

export enum StickerType {
  Animated = 'ANIMATED',
  Static = 'STATIC',
}

export interface ISticker extends Omit<Sticker, 'graphicSize' | 'type'> {
  graphicSize: IGraphicSize;
  type: StickerType;
}

export interface IStickerPacksList extends Omit<StickerPacksListResponse, 'items' | 'cursor'> {
  cursor: string | null;
  items: IStickerPackExtend[];
}

export interface IStickerPackExtend extends Omit<StickerPackResponse, 'stickerPack' | 'stickers'> {
  stickerPack: IStickerPack;
  stickers: ISticker[];
}

export interface IUserStickerPacks extends Omit<UserStickerPacksResponse, 'items'> {
  items: IStickerPackExtend[];
}

export type IStickerPackRequest = StickerPackRequest;

export type IUserStickerPacksUpdatedEventPayload = UserStickerPacksUpdatedEventPayloadResponse;
export type IStickerPackUpdatedEventPayload = StickerPackUpdatedEventPayloadResponse;
