import React, { memo } from 'react';

import { IconView } from 'APP/packages/UiKit/IconView/IconView';
import IcAlbum from 'ICONS/ic-album.svg';

import SelectedText from '../../SelectedText';
import styles from './styles.m.scss';

const Album = ({ message }) => {
  return (
    <>
      <IconView
        Icon={IcAlbum}
        size="small"
        className={styles.icon}
      />
      <SelectedText {...message.textChunk} />
    </>
  );
};

export default memo(Album);
