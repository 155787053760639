import { observer } from 'mobx-react';
import React, { ComponentProps, FC, ReactElement, useMemo } from 'react';

import { ReactionsPopover } from 'APP/components/ReactionsPopover/ReactionsPopover';
import { ApplicationTheme } from 'APP/constants/appearance';
import { IconButton } from 'APP/packages/UiKit/IconButton';
import { TIconButtonTheme } from 'APP/packages/UiKit/IconButton/types';
import IconHeart from 'ICONS/ic-wish.svg';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

import { IconCurrentReaction } from './IconCurrentReaction/IconCurrentReaction';
import { useReactionButtonPresenter } from './ReactionButton.presenter';

interface IReactionButtonProps {
  message: ChatMessage;
  buttonTheme?: TIconButtonTheme;
  popoverTheme?: ApplicationTheme;
}

export const ReactionButton: FC<IReactionButtonProps> = observer((props) => {
  const { message, buttonTheme = '2', popoverTheme } = props;
  const { popover, presenter } = useReactionButtonPresenter(message);

  const ReactionIcon = useMemo(() => {
    if (!presenter.userReaction) {
      return IconHeart;
    }

    return (props: ComponentProps<'img'>): ReactElement => (
      <IconCurrentReaction
        src={presenter.userReaction?.previewURL || ''}
        {...props}
      />
    );
  }, [presenter.userReaction]);

  return (
    <>
      <IconButton
        setRef={popover.containerRef}
        Icon={ReactionIcon}
        theme={buttonTheme}
        onMouseEnter={popover.mouseOver}
        onMouseLeave={popover.mouseOut}
        onClick={presenter.onClick}
      />

      {popover.isOpened && (
        <ReactionsPopover
          message={message}
          reference={popover.containerRef.current}
          theme={popoverTheme}
          onMouseEnter={popover.mouseOver}
          onMouseLeave={popover.mouseOut}
          onClose={popover.close}
        />
      )}
    </>
  );
});
