import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import secondsToString from 'APP/packages/date/helpers/secondsToString';
import Entities from 'APP/store';

const UPDATE_TIME_TIMEOUT = 1000;

export default () => {
  const getTime = () => secondsToString(Entities.InputPanel.voice.duration);

  const presenter = useLocalStore(() => ({
    intervalId: null,
    time: getTime(),

    stop() {
      Entities.InputPanel.voice.stop();
    },

    startTimer: () => {
      presenter.intervalId = setInterval(() => (presenter.time = getTime()), UPDATE_TIME_TIMEOUT);
    },

    stopTimer: () => {
      clearInterval(presenter.intervalId);
    },
  }));

  useEffect(() => {
    presenter.startTimer();

    return () => presenter.stopTimer();
  }, []);

  return presenter;
};
