import { GetUserReactionsResponse } from 'APP/packages/api/types/model/getUserReactionsResponse';
import { GetUsersReactionsForMessageRequest } from 'APP/packages/api/types/model/getUsersReactionsForMessageRequest';
import { GetUsersReactionsForMessageResponse } from 'APP/packages/api/types/model/getUsersReactionsForMessageResponse';

import { request } from '../request/request';

const ROOT_API = 'reactions';

export const getUserReactions = (): Promise<GetUserReactionsResponse> => {
  return request({
    rootApi: ROOT_API,
    version: 'v1',
    url: '/getUserReactions',
    method: 'GET',
  });
};

export const getUsersReactionsForMessage = (
  data: GetUsersReactionsForMessageRequest
): Promise<GetUsersReactionsForMessageResponse> => {
  return request({
    rootApi: ROOT_API,
    version: 'v1',
    url: '/getUsersReactionsForMessage',
    method: 'POST',
    body: data,
  });
};
