import { GetPermissionRequestsResponse } from 'APP/packages/api/types/model/getPermissionRequestsResponse';
import { PersonalizedCallPermissionsSettingsResponse } from 'APP/packages/api/types/model/personalizedCallPermissionsSettingsResponse';
import { PersonalizedCallPermissionsSettingsWithUsersResponse } from 'APP/packages/api/types/model/personalizedCallPermissionsSettingsWithUsersResponse';

import {
  ICallCustomPermissions,
  ICallPermissionRequests,
  ICallPermissions,
  ICallPermissionsSettings,
  ICallPersonalizedPermissions,
} from './callPermissionsModel.types';

export const mapCallPermissionsResponse = (
  permissions:
    | PersonalizedCallPermissionsSettingsResponse
    | PersonalizedCallPermissionsSettingsWithUsersResponse
): ICallPermissionsSettings => {
  const mappedPermissions: ICallPermissionsSettings = {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    default: permissions.default as unknown as ICallPermissions,
    personalized: permissions.personalized as unknown as ICallPersonalizedPermissions,
    defaultPersonalized: permissions.defaultPersonalized as unknown as ICallPersonalizedPermissions,
  };

  if ('usersWithCustomPermissions' in permissions) {
    mappedPermissions.usersWithCustomPermissions =
      permissions.usersWithCustomPermissions as unknown as ICallCustomPermissions[];
  }

  return mappedPermissions;
};

export const mapCallPermissionsRequestsResponse = (
  permissionsRequests: GetPermissionRequestsResponse
): ICallPermissionRequests => {
  return permissionsRequests;
};
