import { useLocalStore, useAsObservableSource } from 'mobx-react';

import Tasks from 'APP/Tasks';
import Entities from 'APP/store';

export const useMessageLayoutPresenter = (message, hasActions) => {
  const source = useAsObservableSource({ message });

  const presenter = useLocalStore(() => ({
    get group() {
      return Entities.GroupsStore.getGroupById(message.groupId);
    },

    get hasSelectedMessages() {
      if (!presenter.group) {
        return false;
      }

      return presenter.group.messagesStore.multiSelect.hasSelectedMessages;
    },

    onMessageSelect: () => {
      if (hasActions) {
        Tasks.messaging.selectMessage({
          groupId: source.message.group.id,
          messageId: source.message.id,
        });
      }
    },
  }));

  return presenter;
};
