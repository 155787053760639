import Tasks from 'APP/Tasks';
import api from 'APP/packages/api';
import { t } from 'MODULE/i18n/service';
import Entities from 'STORE';

const copyExistingLink = (): void => {
  window.navigator.clipboard.writeText(Entities.Calls.ongoingCall?.inviteLink || '');
  Entities.toast.show(t('common_link_copied'));
};

export const copyUnionCallLink = (): void => {
  if (Entities.Calls.ongoingCall?.inviteLink) {
    copyExistingLink();
    return;
  }

  const channelId = Entities.Calls.ongoingCall?.channelId;

  if (!channelId) {
    return;
  }

  Tasks.app.asyncCopyToClipboard({
    method: () => api.calls.getUnionCallLink({ channelId }),
    adapter: (response) => {
      Entities.Calls.ongoingCall?.setInviteLink(response);

      return response;
    },
    message: t('common_link_copied'),
  });
};
