import { ICategoryInfo } from 'APP/model/common/commonModel.types';
import {
  OnlineUserData,
  OnlineUserDataStatusEnum,
} from 'APP/packages/api/types/model/onlineUserData';

export enum UserDisplayDataType {
  Bot = 'BOT',
  User = 'USER',
}

export enum UserProfileUsernameBadge {
  Badge1 = 'BADGE_1',
  Badge2 = 'BADGE_2',
  Badge3 = 'BADGE_3',
  Badge4 = 'BADGE_4',
  Badge5 = 'BADGE_5',
}

export interface IUserBlockStatus {
  ts: string;
  value: boolean;
}

export interface IBotCommand {
  command: string;
  description: string;
}

export interface IUserPrivacyStatus {
  addToGroup: boolean;
  addToPublic: boolean;
  callMe: boolean;
  searchMe: boolean;
  sendVoiceMessage: boolean;
  showLastSeen: boolean;
  updateTs: string;
}

export interface IDisplayDataResponse {
  avatarUrl?: string;
  blockedByMe: IUserBlockStatus;
  blockedMe: IUserBlockStatus;
  contactName?: string;
  deepLink: string;
  id: string;
  isFriend: boolean;
  isUnknown: boolean;
  nickName: string;
  phone?: string;
  privacyStatus: IUserPrivacyStatus;
  type: UserDisplayDataType;
  userName: string;
  verified: boolean;
}

export interface IUserDisplayData {
  avatarUrl?: string;
  blockedByMe: IUserBlockStatus;
  blockedMe: IUserBlockStatus;
  contactName?: string;
  deepLink: string;
  id: string;
  isFriend: boolean;
  lastOnline: string;
  nickName: string;
  phone?: string;
  privacyStatus: IUserPrivacyStatus;
  type: UserDisplayDataType;
  userName: string;
  usernameBadge?: UserProfileUsernameBadge;
  verified: boolean;
  isUnknown: boolean;
  bio?: string;
}

export interface IGetUsersDisplayDataRequest {
  userIds: Array<string>;
}

export interface IBotUserDisplayData extends IUserDisplayData {
  addedToProfile: boolean;
  categoryId?: string;
  categoryInfo: ICategoryInfo;
  commands: IBotCommand[];
  description: string;
  groupId?: string;
  installs: number;
}

export interface IUserMention {
  userId: string;
  nickname: string;
}

export interface IMarkAsUnknownRequest {
  userId: string;
  isUnknown: boolean;
}

export interface IPhoneRecord {
  contactId?: string;
  contactName?: string;
  contactPhone?: string;
  inAppContact?: boolean;
}

export interface IUpdatePhoneBookRequest {
  added?: Array<IPhoneRecord>;
  last: boolean;
  removed?: string[];
}

export type IOnlineUserData = OnlineUserData;

export const UserOnlineStatus = {
  ...OnlineUserDataStatusEnum,
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type UserOnlineStatus = OnlineUserDataStatusEnum;
