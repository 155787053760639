import { Component } from 'react';

class ErrorHandler extends Component {
  componentDidCatch(error) {
    this.props.onError(error);
  }

  render() {
    return this.props.children;
  }
}

export default ErrorHandler;
