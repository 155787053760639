const NOT_VALID_SYMBOLS_FOR_LINK = ['\n', '('];

export default (text, index, symbolMatches) => {
  if (text[index + 1] !== '(') {
    return;
  }

  for (let i = index + 2; i < text.length; i++) {
    const char = text[i];

    if (NOT_VALID_SYMBOLS_FOR_LINK.includes(char)) {
      break;
    }

    if (char === ')') {
      symbolMatches[']'].push({
        start: index,
        end: i + 1,
        link: text.substring(index + 2, i),
      });
      break;
    }
  }
};
