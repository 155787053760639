import { useLocalStore } from 'mobx-react';
import { MutableRefObject, useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { openPopup } from 'APP/Tasks/app/popup/popup';
import { IScheduledEventInfo } from 'APP/model/scheduledEvent/scheduledEventModel.types';
import dateService from 'APP/packages/date';
import { useTranslation } from 'APP/packages/translations';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import { ScheduledCallViewPopup } from 'MAIN/PopupManager/Popups/ScheduledCalls/ScheduledCallViewPopup/ScheduledCallViewPopup';
import Entities from 'STORE';
import { Popup } from 'STORE/App/Popups/Popup/Popup';

import { IScheduledEventInfoWithDate } from './ScheduledCallsList.types';

interface IScheduledCallsListPopupPresenter {
  childPopup: Popup | null;
  hasBackButton: boolean;
  isLoading: boolean;
  cursor?: string;
  scheduledEventsList: IScheduledEventInfo[];
  hasMore: boolean;
  firsDataCount: number | null;
  onItemClick(eventId: string): void;
  onClose(): void;
  onBack(): void;
  getCountPlaceholders(): number;
  keyExtractor(item: IScheduledEventInfo): string;
  getList(): Promise<void>;
  getNextCursor(): void;
  clearPartialStore(): void;
}

interface IScheduledCallsListPopupPresenterParams extends ICommonPopupsProps {
  listRef: MutableRefObject<HTMLDivElement | null>;
}

const PLACEHOLDER_HEIGHT = 166;

export function useScheduledCallsListPopupPresenter({
  popupInstance,
  onClose,
  onBack,
  listRef,
}: IScheduledCallsListPopupPresenterParams): IScheduledCallsListPopupPresenter {
  const { t } = useTranslation();
  const presenter = useLocalStore<IScheduledCallsListPopupPresenter>(() => ({
    childPopup: null,
    isLoading: false,
    firsDataCount: null,

    get hasBackButton(): boolean {
      return !!onBack;
    },

    get scheduledEventsList(): IScheduledEventInfo[] {
      let lastDateText: string;
      return [...Entities.scheduledEvents.scheduledEvents]
        .sort((item1, item2) => {
          return item1.startTs - item2.startTs;
        })
        .map((item) => {
          const date = new Date(item.startTs);
          let currentDateText: string | null;

          if (dateService.isToday(date)) {
            currentDateText = t('today');
          } else {
            currentDateText = dateService.format(date, {
              year: dateService.isLastYear(date) ? undefined : 'numeric',
              weekday: 'short',
              day: 'numeric',
              month: 'short',
            });
          }

          const itemWithDate: IScheduledEventInfoWithDate = { ...item };

          if (currentDateText && lastDateText !== currentDateText) {
            itemWithDate.extendedDate = currentDateText;
            lastDateText = currentDateText;
          }

          return itemWithDate;
        });
    },

    get cursor(): string | undefined {
      return Entities.scheduledEvents.cursor;
    },

    get hasMore(): boolean {
      return Boolean(presenter.cursor);
    },

    keyExtractor({ id }: IScheduledEventInfo): string {
      return id;
    },

    getNextCursor(): void {
      presenter.getList();
    },

    getCountPlaceholders(): number {
      if (listRef.current && !presenter.cursor) {
        const count =
          Math.floor(listRef.current.offsetHeight / PLACEHOLDER_HEIGHT) -
          presenter.scheduledEventsList.length;

        return count < 1 ? 1 : count;
      }

      return 1;
    },

    onItemClick(eventId: string): void {
      const event = Entities.scheduledEvents.getScheduledEventById(eventId);

      if (!event) {
        return;
      }

      popupInstance?.hide();

      presenter.childPopup = openPopup(ScheduledCallViewPopup, {
        params: {
          eventId,
          initialData: {
            id: event.id,
            initiatorId: event.initiatorId,
            groupId: event.groupId,
            endTs: event.endTs,
            startTs: event.startTs,
            name: event.name,
            groupName: event.groupName,
            groupAvatar: event.groupAvatar,
          },
        },
        onClose: () => popupInstance?.close(),
        onBack: () => popupInstance?.show(),
      });
    },

    onClose(): void {
      popupInstance.close();
      onClose?.();
    },

    onBack(): void {
      popupInstance.close();
      onBack?.();
    },

    async getList(): Promise<void> {
      presenter.isLoading = !presenter.scheduledEventsList.length;
      await Tasks.scheduledEvents.getScheduledEvents();
      presenter.isLoading = false;
    },

    clearPartialStore(): void {
      if (presenter.firsDataCount) {
        Entities.scheduledEvents.clearPartialStore(presenter.firsDataCount);
      }
    },
  }));

  useEffect(() => {
    if (presenter.scheduledEventsList.length && !presenter.firsDataCount) {
      presenter.firsDataCount = presenter.scheduledEventsList.length;
    }
  }, [presenter.scheduledEventsList]);

  useEffect(() => {
    presenter.getList();

    return () => {
      presenter.firsDataCount = null;
      presenter.clearPartialStore();
      presenter.childPopup?.close();
    };
  }, []);
  return presenter;
}
