import { SendFeedbackProblemEnum } from 'APP/constants/feedback';

export enum FeedbackIssueType {
  Device = 'DEVICE',
  Video = 'VIDEO',
  Audio = 'AUDIO',
}

export const mapFeedbackIssueTypeToTranslation = {
  [FeedbackIssueType.Device]: 'calls_issue_device',
  [FeedbackIssueType.Video]: 'calls_issue_video',
  [FeedbackIssueType.Audio]: 'calls_issue_audio',
};

export const FeedbackIssueTypeProblems = {
  [FeedbackIssueType.Device]: [
    SendFeedbackProblemEnum.DeviceHot,
    SendFeedbackProblemEnum.DeviceBattery,
    SendFeedbackProblemEnum.DeviceCrash,
    SendFeedbackProblemEnum.SmthElse,
  ],
  [FeedbackIssueType.Video]: [
    SendFeedbackProblemEnum.VideoBlurry,
    SendFeedbackProblemEnum.VideoFroze,
    SendFeedbackProblemEnum.VideoBlack,
    SendFeedbackProblemEnum.VideoSync,
    SendFeedbackProblemEnum.VideoCantStart,
    SendFeedbackProblemEnum.SmthElse,
  ],
  [FeedbackIssueType.Audio]: [
    SendFeedbackProblemEnum.AudioFroze,
    SendFeedbackProblemEnum.AudioCantHear,
    SendFeedbackProblemEnum.AudioCantHearMe,
    SendFeedbackProblemEnum.AudioVideoSync,
    SendFeedbackProblemEnum.SmthElse,
  ],
};

export const MapFeedbackProblemToTranslation = {
  [SendFeedbackProblemEnum.DeviceHot]: 'calls_problem_device_hot',
  [SendFeedbackProblemEnum.DeviceBattery]: 'calls_problem_device_battery',
  [SendFeedbackProblemEnum.DeviceCrash]: 'calls_problem_device_crash',

  [SendFeedbackProblemEnum.VideoBlurry]: 'calls_problem_video_blurry',
  [SendFeedbackProblemEnum.VideoFroze]: 'calls_problem_video_froze',
  [SendFeedbackProblemEnum.VideoBlack]: 'calls_problem_video_black',
  [SendFeedbackProblemEnum.VideoSync]: 'calls_problem_audio_video_sync',
  [SendFeedbackProblemEnum.VideoCantStart]: 'calls_problem_video_cant_start',

  [SendFeedbackProblemEnum.AudioFroze]: 'calls_problem_audio_froze',
  [SendFeedbackProblemEnum.AudioCantHear]: 'calls_problem_audio_cant_hear',
  [SendFeedbackProblemEnum.AudioCantHearMe]: 'calls_problem_audio_cant_hear_me',
  [SendFeedbackProblemEnum.AudioVideoSync]: 'calls_problem_audio_video_sync',

  [SendFeedbackProblemEnum.SmthElse]: 'calls_problem_something_else',
};
