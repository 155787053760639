import { MouseEvent } from 'react';

import Tasks from 'APP/Tasks';
import { RouterState } from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';

interface IDeepLinkRendererPresenterParams {
  link: string;
  groupId: string;
}

interface IDeepLinkRendererPresenter {
  onClick(event: MouseEvent<HTMLAnchorElement>): void;
}

export const useDeepLinkRendererPresenter = (
  data: IDeepLinkRendererPresenterParams
): IDeepLinkRendererPresenter => {
  const { link, groupId } = data;
  const navigateTo = useNavigateTo();

  const presenter: IDeepLinkRendererPresenter = {
    onClick(event: MouseEvent<HTMLAnchorElement>): void {
      event.preventDefault();

      const state: Record<string, boolean> = {};
      if (groupId) {
        state[RouterState.hasGoBack] = true;
      }

      Tasks.deeplinks.handleDeepLink({ link, navigateTo, state });
    },
  };

  return presenter;
};
