export function getFormatNumberToString(num: number): string {
  if (num <= 0) {
    return '';
  }

  if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'K';
  }

  return String(num);
}
