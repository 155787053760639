import { UpdateReason } from 'APP/constants/scroll';
import Entities from 'APP/store';
import { MessageError } from 'STORE/Messages/Message/ChatMessage/ChatMessages.types';

import sendMessage from './sendMessage';
import updateMessage from './updateMessage';

const sendMessageHandler = (message, result) => {
  if (!result && message) {
    message.setError(MessageError.Edit);
  }
};

export default ({ message, date }) => {
  const scheduledGroup = Entities.GroupsStore.getGroupById(message.groupId);
  if (!scheduledGroup) {
    return false;
  }

  const group = Entities.GroupsStore.getGroupById(scheduledGroup.groupId);
  if (!group) {
    return false;
  }

  const inputPanel = Entities.InputPanelsStore.getById(scheduledGroup.id);
  inputPanel.setValue(null);
  inputPanel.setEditMessageId(null);

  const newMsg = updateMessage(scheduledGroup, message, date);
  sendMessage(group.id, newMsg, sendMessageHandler.bind(this, newMsg));
  if (group.isActive) {
    Entities.ChatStore.setScrollChanged(UpdateReason.EditMessage, {
      messageIds: [message.id],
      isAnimated: true,
    });
  }
  return true;
};
