import { observer } from 'mobx-react';
import React, { FC } from 'react';

import IcEmail from 'ICONS/ic-email.svg';
import { FAB } from 'UIKIT/FAB/FAB';

import { useScrollToMentionPresenter } from './ScrollToMentionButton.presenter';
import styles from './ScrollToMentionButton.styles.m.css';

export const ScrollToMentionButton: FC = observer(() => {
  const presenter = useScrollToMentionPresenter();

  if (!presenter.hasScrollToMentionButton) {
    return null;
  }

  return (
    <FAB
      className={styles.button}
      size={'30'}
      onClick={presenter.moveToMention}
      badge={presenter.badgeCount}
      Icon={IcEmail}
    />
  );
});
