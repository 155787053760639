import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { useTranslation } from 'APP/packages/translations';
import { SearchInput } from 'UIKIT/SearchInput/SearchInput';

import { TSearchTextChangeHandler } from '../Catalog.types';
import { useCatalogHeaderPresenter } from './CatalogHeader.presenter';
import styles from './CatalogHeader.styles.m.css';
import { CatalogLocales } from './CatalogLocales/CatalogLocales';

interface ICatalogHeaderProps {
  onSearchTextChange: TSearchTextChangeHandler;
}

export const CatalogHeader: FC<ICatalogHeaderProps> = observer((props) => {
  const { onSearchTextChange } = props;
  const presenter = useCatalogHeaderPresenter(onSearchTextChange);
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <h2 className={styles.header}>{t('statistics_catalog')}</h2>

      <SearchInput
        className={styles.search}
        onChange={presenter.onSearchChange}
        value={presenter.searchText}
        placeholder={t('channels:channel_catalog_search')}
      />

      <CatalogLocales />
    </div>
  );
});
