import { mapAuthSocialNetworkRequest } from 'APP/model/auth/authModel.mapper';
import {
  IAbortConfirmationRequest,
  IAccountProvider,
  IChangePhoneConfirmVerifyRequest,
  IChangePhoneConfirmVerifyResponse,
  IChangePhoneVerifyCodeRequest,
  IChangePhoneVerifyCodeResponse,
  IClientConfig,
  ILinkExternalAuthAccountRequest,
  ILinkExternalAuthAccountResponse,
  IRegisterResponse,
  IUnlinkExternalAuthAccountRequest,
  IVerifyOauthTokenRequest,
} from 'APP/model/auth/authModel.types';
import { buildModelError } from 'APP/model/error/errorBuilder';
import api from 'APP/packages/api';
import { DeviceInfoPlatformEnum } from 'APP/packages/api/types/model/deviceInfo';
import { DeviceInfoV2PlatformEnum } from 'APP/packages/api/types/model/deviceInfoV2';
import { ExternalAuthProviderId } from 'APP/packages/api/types/model/externalAuthProviderId';
import logger from 'APP/packages/logger';
import { platformInfo } from 'APP/packages/platformInfo/platformInfo';
import Entities from 'STORE';
import { getUserLanguage } from 'UTILS/getUserLanguage';

import { IBooleanResponse } from '../common/commonModel.types';

export const authWithSocialNetwork = async (
  data: IVerifyOauthTokenRequest
): Promise<IRegisterResponse> => {
  try {
    return await api.authorization.requestVerifyOauthToken(mapAuthSocialNetworkRequest(data));
  } catch (error) {
    logger.get('API').error('authorization.authWithSocialNetwork', error);
    throw buildModelError(error);
  }
};

export async function getMyAccountInfo(): Promise<IAccountProvider[]> {
  try {
    const { externalAuthProviderUsers } = await api.authorization.getMyAccountInfo();
    return externalAuthProviderUsers;
  } catch (error) {
    logger.get('API').error('getMyAccountInfo', error);
    throw buildModelError(error);
  }
}

export async function linkAccount(
  data: ILinkExternalAuthAccountRequest
): Promise<ILinkExternalAuthAccountResponse> {
  try {
    return await api.authorization.linkAuthAccount({
      token: data.token,
      authProviderId: data.authProviderId as string as ExternalAuthProviderId,
      userInfo: data.userInfo,
    });
  } catch (error) {
    logger.get('API').error('linkAccount', error);
    throw buildModelError(error);
  }
}

export async function requestChangePhoneVerifyCode(
  data: IChangePhoneVerifyCodeRequest
): Promise<IChangePhoneVerifyCodeResponse> {
  try {
    return await api.authorization.changePhoneRequestVeryCodeV2({
      ...data,
      deviceInfo: {
        ...data.deviceInfo,
        platform: data.deviceInfo.platform as string as DeviceInfoPlatformEnum,
      },
    });
  } catch (error) {
    logger.get('API').error('changePhoneRequestVeryCode', error);
    throw buildModelError(error);
  }
}

export async function changePhoneConfirmVerifyCode(
  data: IChangePhoneConfirmVerifyRequest
): Promise<IChangePhoneConfirmVerifyResponse> {
  try {
    return await api.authorization.changePhoneConfirmVerifyCode({
      ...data,
      device: {
        ...data.device,
        platform: data.device.platform as string as DeviceInfoV2PlatformEnum,
      },
    });
  } catch (error) {
    logger.get('API').error('changePhoneConfirmVerifyCode', error);
    throw buildModelError(error);
  }
}

export async function changePhoneAbortConfirmation(): Promise<IBooleanResponse> {
  try {
    return await api.authorization.changePhoneAbortConfirmation();
  } catch (error) {
    logger.get('API').error('changePhoneAbortConfirmation', error);
    throw buildModelError(error);
  }
}

export async function abortConfirmation(
  data: IAbortConfirmationRequest
): Promise<IBooleanResponse> {
  try {
    return await api.authorization.abortConfirmation(data);
  } catch (error) {
    logger.get('API').error('abortConfirmation', error);
    throw buildModelError(error);
  }
}

export async function unlinkAccount(data: IUnlinkExternalAuthAccountRequest): Promise<void> {
  try {
    await api.authorization.unlinkAccount({
      authProviderId: data.authProviderId as string as ExternalAuthProviderId,
    });
  } catch (error) {
    logger.get('API').error('unlinkAccount', error);
    throw buildModelError(error);
  }
}

export async function getClientConfig(): Promise<IClientConfig> {
  try {
    const { clientConfig } = await api.authorization.getClientConfig({
      deviceInfo: {
        platform: platformInfo.platform as string as DeviceInfoPlatformEnum,
        appVersion: Entities.App.version,
        locale: getUserLanguage(),
        device: platformInfo.device,
        osVersion: platformInfo.osVersion,
        uniqueDeviceId: '-1', // web platform doesn't have any unique device id
      },
    });
    return clientConfig;
  } catch (error) {
    logger.get('API').error('authorization.getClientConfig', error);
    throw buildModelError(error);
  }
}
