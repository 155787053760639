import { IMemberCustomPermissions } from 'APP/model/group/groupModel.types';
import Entities from 'STORE';

import { GroupsEvent } from '../GroupsEvent';

export class PermissionsChanged extends GroupsEvent<IMemberCustomPermissions> {
  process(): void {
    const payload = this.eventPayload;
    const group = Entities.GroupsStore.getGroupById(this.groupId);

    if (!payload || !group) {
      return;
    }

    group.setMemberCustomPermissions({
      canInitCall: payload.canInitCall,
      canInitConference: payload.canInitConference,
      canInviteUsers: payload.canInviteUsers,
      canLeaveComment: payload.canLeaveComment,
      canPinMessage: payload.canPinMessage,
      canSendMessages: payload.canSendMessages,
      canDisplayMembers: payload.canDisplayMembers,
      supportedMediaTypes: { ...payload.supportedMediaTypes },
      updatedAt: payload.updatedAt,
    });
  }
}
