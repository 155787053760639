import { useLocalStore } from 'mobx-react';

import { openPopup } from 'APP/Tasks/app/popup/popup';
import { useTranslation } from 'APP/packages/translations';
import { AboutPopup } from 'MAIN/PopupManager/Popups/AboutPopup/AboutPopup';
import { Popup } from 'STORE/App/Popups/Popup/Popup';

interface IMyProfilePopupPresenter {
  appVersion: string;
  onOpenAbout(): void;
  onClose(): void;
}

export function useMyProfilePopupPresenter(popupInstance: Popup): IMyProfilePopupPresenter {
  const { t } = useTranslation();

  const presenter = useLocalStore<IMyProfilePopupPresenter>(() => ({
    get appVersion(): string {
      return t('about_version', { 0: window.app.version });
    },

    onOpenAbout(): void {
      popupInstance.hide();
      openPopup(AboutPopup, {
        onClose: () => popupInstance.close(),
        onBack: () => popupInstance.show(),
      });
    },

    onClose(): void {
      popupInstance.close();
    },
  }));

  return presenter;
}
