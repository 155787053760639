import Tasks from 'APP/Tasks';
import { GroupType } from 'APP/model/group/groupModel.types';
import Entities from 'APP/store';
import { Group } from 'STORE/Groups/Group';

export const createP2PGroup = async (
  userId: string,
  shouldBeSetAsActive = true
): Promise<Group | null> => {
  const opponent = Entities.UsersStore.getUserById(userId);

  if (opponent && opponent.group) {
    shouldBeSetAsActive && Tasks.group.setActiveGroup({ groupId: opponent.group.id });
    return opponent.group;
  }

  return await Tasks.group.createGroupAndInvite(
    {
      members: [Entities.UsersStore.Me.id, userId],
      type: GroupType.P2P,
    },
    shouldBeSetAsActive
  );
};
