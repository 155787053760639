import { observer } from 'mobx-react';
import React, { FC, ReactElement } from 'react';
import { FormSpy } from 'react-final-form';

import { Field } from 'APP/packages/form/Field/Field';
import { Form } from 'APP/packages/form/Form/Form';
import { IFormContentProps } from 'APP/packages/form/Form/Form.types';
import RadioGroup from 'APP/packages/form/RadioGroup';
import { TextField } from 'APP/packages/form/TextField/TextField';
import { useTranslation } from 'APP/packages/translations';
import { Button, ButtonTypes } from 'UIKIT/Button/Button';
import { PopupFooter } from 'UIKIT/PopupFooter/PopupFooter';

import { useNewRecordingPresenter, INewRecordingParams } from './NewRecording.presenter';
import styles from './NewRecording.styles.m.css';

type INewRecordingProps = INewRecordingParams;

export const NewRecording: FC<INewRecordingProps> = observer(({ onSuccess }) => {
  const { t } = useTranslation();
  const presenter = useNewRecordingPresenter({ onSuccess });

  return (
    <>
      <Form
        onSubmit={presenter.onSubmit}
        initialValues={presenter.initialValues}
      >
        {({ handleSubmit }: IFormContentProps): ReactElement => (
          <form
            onSubmit={handleSubmit}
            id={'newRecording'}
            className={styles.container}
          >
            <div className={styles.content}>
              <Field
                isNeedTrim
                name="title"
                label={t('calls_recording_title_recording_screen')}
                component={TextField}
                maxLength={300}
                validatingText={''}
                successText={''}
              />
              <RadioGroup
                className={styles.recordingType}
                name="recordingType"
                title={t('calls_recording_type_title_recording_screen')}
                options={presenter.recordingOptions}
              />
            </div>
            <FormSpy onChange={presenter.checkValid} />
          </form>
        )}
      </Form>
      <PopupFooter>
        <Button
          type={ButtonTypes.Submit}
          form={'newRecording'}
          theme="1"
          isDisabled={!presenter.isValid}
        >
          {t('calls_recording_start_button')}
        </Button>
      </PopupFooter>
    </>
  );
});
