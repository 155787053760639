import { spaceModel } from 'APP/model/model';
import { ISpaceMarket } from 'APP/model/space/spaceModel.types';
import { spaceAnalytics } from 'APP/packages/analytics/analytics';
import Entities from 'STORE';

export async function deleteSpaceMarket(market: ISpaceMarket): Promise<boolean> {
  try {
    const response = await spaceModel.deleteMarket(market.spaceId, market.id);

    if (response.result) {
      const space = Entities.spacesStore.getById(market.spaceId);
      space?.markets.deleteMarket(market.id);
      space?.setMarketsCount(space.marketsCount - 1);

      spaceAnalytics.trackSpaceMarketDeleted(market.spaceId, market.id);

      return true;
    }
  } catch {
    //
  }

  return false;
}
