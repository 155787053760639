import { useLocalStore } from 'mobx-react';

import { useTranslation } from 'APP/packages/translations';

import { SharedDataViewTabsType, tabsMap } from './SharedDataViewTabs.types';

interface ISharedDataTabsPresenter {
  activeTab: number;
  tabs: SharedDataViewTabsType[];
  tabsNames: string[];
  onChangeTab(index: number): void;
}

export const useSharedDataTabsPresenter = (): ISharedDataTabsPresenter => {
  const { t } = useTranslation();
  const presenter = useLocalStore<ISharedDataTabsPresenter>(() => ({
    activeTab: 0,

    get tabs(): SharedDataViewTabsType[] {
      return [
        SharedDataViewTabsType.All,
        SharedDataViewTabsType.Chats,
        SharedDataViewTabsType.Channels,
      ];
    },

    get tabsNames(): string[] {
      return presenter.tabs.map((tab) => t(tabsMap[tab]));
    },

    onChangeTab(index: number): void {
      presenter.activeTab = index;
    },
  }));

  return presenter;
};
