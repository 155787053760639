import * as PopperJS from '@popperjs/core';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';
import { Modifier, StrictModifierNames } from 'react-popper';

import { MessageViewPageType } from 'MAIN/ChatView/Messages/Messages.types';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';
import { IPopover } from 'UIKIT/Popover/Popover.types';
import { PopoverMenu } from 'UIKIT/PopoverMenu/PopoverMenu';

import { useContextMenuPresenter } from './ContextMenu.presenter';

interface IContextMenuProps {
  message: ChatMessage;
  targetClick: HTMLElement | null;
  placement: PopperJS.Placement;
  modifiers?: ReadonlyArray<Modifier<StrictModifierNames>>;
  popover: IPopover;
  messageViewPageType: MessageViewPageType;
}

export const ContextMenu: FC<IContextMenuProps> = observer((props) => {
  const { message, popover, placement, modifiers, messageViewPageType, targetClick } = props;

  const { menuItems } = useContextMenuPresenter({
    message,
    messageViewPageType,
    targetClick,
  });

  useEffect(() => {
    if (!menuItems.length) {
      popover.close();
    }
  }, [menuItems]);

  return (
    <PopoverMenu
      isOpened
      menuItems={menuItems}
      reference={popover.containerRef.current}
      placement={placement}
      modifiers={modifiers}
      onClose={popover.close}
    />
  );
});
