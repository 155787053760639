import { LocationState } from 'history';
import { DragEvent, MouseEvent, RefObject, useCallback, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { ISticker } from 'APP/model/stickers/stickersModel.types';
import { RouterState, RouterStatePopup } from 'APP/router/constants';

interface IStickerPresenter {
  ref: RefObject<HTMLButtonElement>;
  onMouseDown(e: MouseEvent<HTMLButtonElement>): void;
  onMouseUp(e: MouseEvent<HTMLButtonElement>): void;
  onMouseEnter(): void;
  onDragStart(e: DragEvent<HTMLButtonElement>): void;
  onTouchStart(): void;
  onTouchEnd(): void;
  onClick(): void;
}

export function useStickerPresenter(
  stickerPackId: string | null,
  sticker: ISticker,
  onClick?: (sticker: ISticker) => void
): IStickerPresenter {
  const isLongPress = useRef(false);
  const longPressTimer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const nodeRef = useRef<HTMLButtonElement>(null);
  const history = useHistory();
  const location = useLocation<Record<string, LocationState>>();

  const goToStickerPopup = useCallback(
    (hasTranslate = true) => {
      isLongPress.current = true;
      const translate = { left: 0, top: 0 };
      if (hasTranslate && nodeRef.current) {
        const { left, top, bottom, right } = nodeRef.current.getBoundingClientRect();
        translate.left = (left + right) / 2 - window.innerWidth / 2;
        translate.top = (top + bottom) / 2 - window.innerHeight / 2;
      }

      history.replace(history.location.pathname + history.location.search, {
        [RouterState.popup]: RouterStatePopup.sticker,
        [RouterState.sticker]: {
          type: sticker.type,
          id: sticker.id,
          animationUrl: sticker.animationUrl,
          stickerUrl: sticker.stickerUrl,
          graphicSize: {
            width: sticker.graphicSize.width,
            height: sticker.graphicSize.height,
          },
          translate,
        },
      });
    },
    [history, sticker]
  );

  const toggleSticker = useCallback(
    (startLongPress): void => {
      if (startLongPress) {
        isLongPress.current = false;
        longPressTimer.current = setTimeout(goToStickerPopup, 300);
      } else {
        if (longPressTimer.current) {
          clearTimeout(longPressTimer.current);
        }
      }
    },
    [goToStickerPopup, longPressTimer]
  );

  const onMouseDown = useCallback(
    (e: MouseEvent<HTMLButtonElement>): void => {
      if (e.nativeEvent.button === 0) {
        toggleSticker(true);
      }
    },
    [toggleSticker]
  );

  const onMouseEnter = useCallback((): void => {
    if (location.state?.[RouterStatePopup.sticker]) {
      goToStickerPopup(false);
    }
  }, [goToStickerPopup, location]);

  const onDragStart = useCallback((e: DragEvent<HTMLButtonElement>): void => {
    e.preventDefault();
  }, []);

  const onMouseUp = useCallback((): void => {
    toggleSticker(false);
  }, [toggleSticker]);

  const onTouchStart = useCallback((): void => {
    toggleSticker(true);
  }, [toggleSticker]);

  const onClickHandler = useCallback(() => {
    if (!isLongPress.current && stickerPackId && onClick) {
      onClick(sticker);
    }
  }, [isLongPress, onClick, stickerPackId, sticker]);

  return {
    ref: nodeRef,
    onMouseDown,
    onMouseUp,
    onMouseEnter,
    onDragStart,
    onTouchStart,
    onTouchEnd: onMouseUp,
    onClick: onClickHandler,
  };
}
