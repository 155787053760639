import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { MutableRefObject, useEffect } from 'react';

import Tasks from 'APP/Tasks';
import {
  IInvitee,
  IScheduledEventDetails,
} from 'APP/model/scheduledEvent/scheduledEventModel.types';

interface IScheduledCallsInviteesListPresenter {
  isMoreLoading: boolean;
  isListLoading: boolean;
  cursor?: string;
  hasMore: boolean;
  inviteesList: IInvitee[];
  getCountPlaceholders(): number;
  keyExtractor(item: IInvitee): string;
  getList(): Promise<void>;
  getNextCursor(): void;
}

interface IScheduledCallsInviteesListParams {
  listRef: MutableRefObject<HTMLDivElement | null>;
  isDetailsLoading: boolean;
  scheduledEventDetails: IScheduledEventDetails | null;
}

const PLACEHOLDER_HEIGHT = 48;

export function useScheduledCallsInviteesListPresenter({
  isDetailsLoading,
  scheduledEventDetails,
  listRef,
}: IScheduledCallsInviteesListParams): IScheduledCallsInviteesListPresenter {
  const source = useAsObservableSource({
    listRef: listRef.current,
    isDetailsLoading,
    scheduledEventDetails,
  });

  const presenter = useLocalStore<IScheduledCallsInviteesListPresenter>(() => ({
    isMoreLoading: false,
    inviteesList: [],
    cursor: undefined,

    get isListLoading(): boolean {
      return source.isDetailsLoading || presenter.isMoreLoading;
    },

    get hasMore(): boolean {
      return Boolean(presenter.cursor);
    },

    keyExtractor(data: IInvitee): string {
      return data.user.id;
    },

    getNextCursor(): void {
      presenter.getList();
    },

    getCountPlaceholders(): number {
      if (source.listRef && !presenter.cursor) {
        const count =
          Math.floor(source.listRef.offsetHeight / PLACEHOLDER_HEIGHT) -
          presenter.inviteesList.length;

        return count < 0 ? 0 : count;
      }

      return 1;
    },

    async getList(): Promise<void> {
      if (!source.scheduledEventDetails) {
        return;
      }

      presenter.isMoreLoading = true;
      const response = await Tasks.scheduledEvents.getScheduledEventInvitees({
        cursor: presenter.cursor,
        eventId: source.scheduledEventDetails.id,
      });
      if (!response) {
        return;
      }
      presenter.cursor = response.cursor;
      presenter.inviteesList = [...presenter.inviteesList, ...response.values];
      presenter.isMoreLoading = false;
    },
  }));

  useEffect(() => {
    if (!isDetailsLoading && scheduledEventDetails) {
      presenter.inviteesList = scheduledEventDetails.invitees.values;
      presenter.cursor = scheduledEventDetails.invitees.cursor;
    }
  }, [isDetailsLoading]);

  return presenter;
}
