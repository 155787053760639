import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import Entities from 'APP/store';

const LIMIT = 10;
const OFFSET = 0;

export const useMessageViewsPopoverPresenter = ({ message, tooltip, onOpenReactionPopup }) => {
  const source = useAsObservableSource({ message, onOpenReactionPopup });

  const presenter = useLocalStore(() => ({
    isLoading: false,
    users: [],

    loadViews: async () => {
      presenter.isLoading = true;

      const { users } = await Tasks.messaging.getUsersViewedMessage({
        groupId: presenter.message.groupId,
        messageId: presenter.message.id,
        limit: LIMIT,
        offset: OFFSET,
      });

      const usersIds = users.map((userId) => userId);

      if (usersIds.length > 0) {
        await Tasks.relationships.loadUnknownUsersByIds({
          usersIds,
        });
      }

      presenter.users = users
        .map((userId) => {
          return Entities.UsersStore.getUserById(userId);
        })
        .filter((x) => x);
      presenter.isLoading = false;

      tooltip.update();
    },

    onMoreClick: () => {
      source.onOpenReactionPopup();
      tooltip.hide();
    },

    reset: () => {
      presenter.isLoading = false;
      presenter.users = [];
    },

    get message() {
      return source.message;
    },

    get isOpened() {
      return !presenter.isLoading && presenter.users.length > 0;
    },

    get moreCount() {
      if (presenter.message.views > LIMIT) {
        return presenter.message.views - presenter.users.length;
      }

      return null;
    },
  }));

  useEffect(() => {
    presenter.loadViews();

    return () => {
      presenter.reset();
    };
  }, [message.id]);

  return presenter;
};
