import { BotButtonPayloadType } from 'APP/model/bot/botModel.constants';
import { IShareCardPayload } from 'APP/model/bot/botModel.types';

export class ShareCardButtonPayload {
  type: BotButtonPayloadType;
  imageUrl: string;
  cardDeepLink: string;
  botDeepLink: string;
  caption?: string;

  constructor(data: IShareCardPayload) {
    this.type = data.type;
    this.imageUrl = data.imageUrl;
    this.cardDeepLink = data.cardDeepLink;
    this.botDeepLink = data.botDeepLink;
    this.caption = data.caption;
  }

  toJSON(): IShareCardPayload {
    return {
      type: this.type,
      imageUrl: this.imageUrl,
      cardDeepLink: this.cardDeepLink,
      botDeepLink: this.botDeepLink,
      caption: this.caption,
    };
  }
}
