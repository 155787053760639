import { observer } from 'mobx-react';
import { FC } from 'react';

import { PageType } from 'APP/router/MessengerRoutes/constants';

import { useChangeActiveGroupPresenter } from './ChangeActiveGroupHandler.presenter';

export const ChangeActiveGroupHandler: FC<{ pageType: PageType }> = observer(({ pageType }) => {
  useChangeActiveGroupPresenter(pageType);

  return null;
});
