import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import { openPopup } from 'APP/Tasks/app/popup/popup';
import { ReactionsDetailedPopup } from 'MAIN/PopupManager/Popups/ReactionsDetailedPopup/ReactionsDetailedPopup';
import { getFormatNumberToString } from 'UTILS/getFormatNumberToString';

export const useMessageViewsPresenter = ({ message }) => {
  const source = useAsObservableSource({ message });

  const presenter = useLocalStore(() => ({
    childPopup: null,

    get message() {
      return source.message;
    },

    get isShow() {
      return (
        !(presenter.message.group.withoutMe && presenter.message.group.isClosed) &&
        !presenter.message.isFake &&
        !presenter.message.isFailed &&
        !presenter.message.group.isFake &&
        !presenter.message.group.isP2P &&
        !presenter.message.group.isSavedMessages &&
        Number(presenter.message.views) > 1
      );
    },

    get canBeShowPopover() {
      const group = source.message.group;

      if (group.isP2P || group.isBlockedMe) {
        return false;
      }

      if (group.isChannel && !group.withMeInAdmins) {
        return false;
      }
      if (group.isThread && group.parentGroup?.isChannel && !group.parentGroup?.withMeInAdmins) {
        return false;
      }

      return true;
    },

    get views() {
      return getFormatNumberToString(presenter.message.views);
    },

    onOpenReactionPopup() {
      presenter.childPopup = openPopup(ReactionsDetailedPopup, {
        params: { groupId: presenter.message.groupId, messageId: presenter.message.id },
      });
    },
  }));

  useEffect(() => {
    return () => presenter.childPopup?.close();
  }, []);

  return presenter;
};
