import classNames from 'classnames';
import React, { ElementType, FC, memo } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { IconView } from '../IconView/IconView';
import styles from './PopupLink.styles.m.css';

interface IPopupLinkProps {
  className?: string;
  title: string;
  Icon: ElementType;
  to?: LinkProps['to'];
  onClick?(): void;
}

export const PopupLink: FC<IPopupLinkProps> = memo(({ className, Icon, title, to, onClick }) => {
  const classes = classNames(styles.container, className);
  const content = (
    <>
      <IconView
        Icon={Icon}
        className={styles.icon}
      />
      <span className={styles.title}>{title}</span>
    </>
  );

  if (onClick) {
    return (
      <button
        onClick={onClick}
        className={classes}
      >
        {content}
      </button>
    );
  }

  if (to) {
    return (
      <Link
        to={to}
        className={classes}
      >
        {content}
      </Link>
    );
  }

  return null;
});
