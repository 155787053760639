import Entities from 'APP/store';

import updateMessagePayload from './updateMessagePayload';

export default ({ data }) => {
  const schGroup = Entities.GroupsStore.getGroupById(data.groupId);
  if (!schGroup) {
    return;
  }

  const message = updateMessagePayload({
    message: schGroup.messagesStore.getMessageById(data.messageId),
    title: data.title,
    payloads: data.payloads,
  });
  if (!message) {
    return;
  }

  Entities.InputPanel.payloads.isEditMode = true;
  Entities.InputPanel.payloads.setPayloads([message]);
  Entities.InputPanel.send.schedule(new Date(message.serverTime));
};
