import { useLocalStore } from 'mobx-react';
import { MouseEvent } from 'react';

import Tasks from 'APP/Tasks';
import { IStickerPackExtend } from 'APP/model/stickers/stickersModel.types';
import { useTranslation } from 'APP/packages/translations';
import Entities from 'APP/store';

interface IStickerPackPresenter {
  pack: IStickerPackExtend;
  avatarTitle: string;
  avatarUrl: string;
  title: string;
  subtitle: string;
  isLoading: boolean;
  isAddedPack: boolean;
  toggle(event: MouseEvent<HTMLButtonElement>): Promise<void>;
  onClick(): void;
}

interface IStickerPackPresenterParams {
  pack: IStickerPackExtend;
  onStickerPackClick(stickerPack: IStickerPackExtend): void;
}

export const useStickerPackPresenter = ({
  pack,
  onStickerPackClick,
}: IStickerPackPresenterParams): IStickerPackPresenter => {
  const { t } = useTranslation();

  const presenter = useLocalStore<IStickerPackPresenter>(() => ({
    pack: pack,
    isLoading: false,

    get avatarTitle(): string {
      return this.title;
    },

    get title(): string {
      return presenter.pack.stickerPack.displayName;
    },

    get avatarUrl(): string {
      return this.pack.stickerPack.iconUrl;
    },

    get subtitle(): string {
      const length = this.pack ? this.pack.stickers.length : 0;
      return t('amount_of_stickers_in_a_pack_few', { 0: length });
    },

    get isAddedPack(): boolean {
      if (!this.pack) {
        return false;
      }

      return Entities.stickersStore.installedPackIds.includes(presenter.pack.stickerPack.id);
    },

    onClick(): void {
      onStickerPackClick(pack);
    },

    async toggle(event: MouseEvent<HTMLButtonElement>): Promise<void> {
      event.stopPropagation();
      event.preventDefault();

      presenter.isLoading = true;

      if (presenter.isAddedPack) {
        await Tasks.stickers.removeStickerPackFromProfile(presenter.pack.stickerPack.id);
      } else {
        await Tasks.stickers.addStickerPackToProfile(presenter.pack.stickerPack.id);
      }

      presenter.isLoading = false;
    },
  }));

  return presenter;
};
