import React from 'react';

import { Avatar } from 'UIKIT/Avatar/Avatar';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';

import styles from './styles.m.css';

const NoVideoPlaceholder = ({ streamer }) => {
  return (
    <Avatar
      className={styles.container}
      type={AvatarTypes.Round_25}
      title={streamer.username}
      url={streamer.avatarUrl}
      backgroundColorIndex={streamer.avatarColorIndex}
    />
  );
};

export default NoVideoPlaceholder;
