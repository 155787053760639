import { observer } from 'mobx-react';
import React, { FC, MutableRefObject } from 'react';
import { InView } from 'react-intersection-observer';

import EmptyImage from 'APP/images/gem-mascot-not-found.svg';
import { CatalogWidgetType } from 'APP/model/catalog/catalogModel.types';
import { CatalogSearchPlaceholder } from 'APP/packages/placeholders/CatalogPlaceholder/CatalogSearchPlaceholder/CatalogSearchPlaceholder';
import { useTranslation } from 'APP/packages/translations';
import { InfoBlock, InfoBlockTypes } from 'UIKIT/InfoBlock/InfoBlock';

import { TCatalogResetHandler } from '../Catalog.types';
import { CatalogLoader } from '../components/CatalogLoader/CatalogLoader';
import { CatalogWidget } from '../components/CatalogWidget/CatalogWidget';
import { useCatalogCategoryContentPresenter } from './CatalogCategoryContent.presenter';

interface ICatalogCategoryContentProps {
  scrollRef: MutableRefObject<HTMLDivElement | null>;
  onCatalogReset: TCatalogResetHandler;
}

export const CatalogCategoryContent: FC<ICatalogCategoryContentProps> = observer((props) => {
  const { scrollRef, onCatalogReset } = props;
  const presenter = useCatalogCategoryContentPresenter(onCatalogReset);
  const { t } = useTranslation();

  return (
    <>
      {presenter.isLoadingView && <CatalogSearchPlaceholder />}

      {presenter.isEmptyView && (
        <InfoBlock
          descriptions={[t('nothing_found')]}
          type={InfoBlockTypes.Page}
          image={EmptyImage}
        />
      )}

      {presenter.isContentView && (
        <CatalogWidget
          type={CatalogWidgetType.Type3}
          showItemType={presenter.isShowItemType}
          items={presenter.items}
        />
      )}

      {presenter.isLoadingMoreView && <CatalogLoader />}

      <InView
        key={presenter.viewObserverKey}
        root={scrollRef.current}
        rootMargin="0px 0px 400px 0px"
        onChange={presenter.onViewObserver}
      />
    </>
  );
});
