import React, { FC, ReactEventHandler } from 'react';

import { useTranslation } from 'APP/packages/translations';

import styles from './PhoneProviderAddPhone.styles.m.css';

interface IAddPhone {
  onClick: ReactEventHandler<HTMLButtonElement>;
}

export const PhoneProviderAddPhone: FC<IAddPhone> = (props) => {
  const { onClick } = props;
  const { t } = useTranslation();

  return (
    <button
      className={styles.container}
      onClick={onClick}
    >
      {t('account_phone_number_add')}
    </button>
  );
};
