import React from 'react';
import { Field as BaseField, FieldProps, FieldRenderProps } from 'react-final-form';

interface IBaseFieldProps<
  FieldValue = any,
  RP extends FieldRenderProps<FieldValue, T> = FieldRenderProps<FieldValue, HTMLElement>,
  T extends HTMLElement = HTMLElement
> extends FieldProps<FieldValue, RP, T> {
  isNeedTrim?: boolean;
}

export function Field<
  FieldValue = any,
  RP extends FieldRenderProps<FieldValue, T> = FieldRenderProps<FieldValue, HTMLElement>,
  T extends HTMLElement = HTMLElement
>({
  subscription = {},
  isNeedTrim = false,
  ...props
}: IBaseFieldProps<FieldValue, RP, T>): React.ReactElement {
  const fieldProps: Partial<FieldProps<FieldValue, RP, T>> = {
    validateFields: [],
    subscription: {
      data: false,
      initial: true,
      length: false,
      active: true,
      dirty: true,
      dirtySinceLastSubmit: false,
      error: true,
      invalid: true,
      modified: true,
      modifiedSinceLastSubmit: false,
      pristine: false,
      submitError: false,
      submitFailed: false,
      submitSucceeded: false,
      submitting: false,
      touched: false,
      valid: true,
      validating: true,
      value: true,
      visited: false,
      ...subscription,
    },
  };

  if (isNeedTrim) {
    // helps detect is dirty
    fieldProps.isEqual = (a: any, b: any): boolean => {
      if (!a && !b) {
        return true;
      }

      if (typeof a === 'undefined' || typeof b === 'undefined') {
        return false;
      }

      return a.trim() === b.trim();
    };
  }

  return (
    <BaseField
      {...fieldProps}
      {...props}
    />
  );
}
