import {
  useEffect,
  useLayoutEffect,
  useState,
  MutableRefObject,
  MouseEvent,
  MouseEventHandler,
} from 'react';

import { RLottieHandler } from 'APP/libs/rLottie/RLottieHandler';
import { useAnimateSticker } from 'APP/packages/stickerAnimation/useAnimateSticker';
import { IReactionItem } from 'MAIN/hooks/useReactions/useReactions.types';

interface IReactionItemParams {
  reaction: IReactionItem;
  isAnimated: boolean;
  isAnimatedLoop: boolean;
  isVisible: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

interface IReactionItemPresenter {
  isShowCounter: boolean;
  destroy(): void;
  startAnimation(): void;
  stopAnimation(): void;
  onClick(event: MouseEvent<HTMLButtonElement>): void;
  onHoverIn(): void;
  onHoverOut(): void;
}

interface IUseReactionItemPresenter {
  presenter: IReactionItemPresenter;
  stickerContainerRef: MutableRefObject<HTMLSpanElement | null>;
}

const onStopAnimation = (handler: RLottieHandler): void => {
  handler.reset();
};

export const useReactionItemPresenter = (data: IReactionItemParams): IUseReactionItemPresenter => {
  const { reaction, isAnimated, isAnimatedLoop, onClick, isVisible } = data;

  const [isHover, setIsHover] = useState(false);
  const { animationRef, stickerContainerRef } = useAnimateSticker<HTMLSpanElement>({
    autoplay: false,
    loop: false,
    url: reaction.animationUrl,
    onLoopComplete: onStopAnimation,
  });

  const presenter: IReactionItemPresenter = {
    get isShowCounter(): boolean {
      return !!reaction.counter;
    },

    destroy(): void {
      animationRef.current?.destroy();
      animationRef.current = undefined;
    },

    startAnimation(): void {
      if (!animationRef.current?.playing) {
        animationRef.current?.play();
      }
    },

    stopAnimation(): void {
      if (animationRef.current?.playing) {
        animationRef.current?.pause();
        animationRef.current?.reset();
      }
    },

    onClick(event: MouseEvent<HTMLButtonElement>): void {
      onClick ? onClick(event) : reaction.onClick();
    },

    onHoverIn(): void {
      setIsHover(true);
    },

    onHoverOut(): void {
      setIsHover(false);
    },
  };

  useEffect(() => {
    return () => presenter.destroy();
  }, []);

  useEffect(() => {
    animationRef.current?.updateIsVisible(isVisible);
  }, [isVisible]);

  useLayoutEffect(() => {
    if (isAnimated || isAnimatedLoop || isHover) {
      presenter.startAnimation();
    } else {
      presenter.stopAnimation();
    }
  }, [isAnimated, isAnimatedLoop, isHover]);

  useLayoutEffect(() => {
    animationRef.current?.setLoop(isAnimatedLoop || isHover);
  }, [isAnimatedLoop, isHover]);

  return { stickerContainerRef, presenter };
};
