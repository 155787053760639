export default (timeStr) => {
  if (!timeStr) {
    return null;
  }

  const vals = String(timeStr).split(':');

  if (vals.length < 2 || vals.length > 3) {
    return null;
  }

  const lastVal = vals[vals.length - 1].toUpperCase();
  const isAm = lastVal.substring(lastVal.length - 2) === 'AM';
  const isPm = !isAm && lastVal.substring(lastVal.length - 2) === 'PM';

  if (isAm || isPm) {
    vals[vals.length - 1] = lastVal.substring(0, lastVal.length - 2);
  }

  const isInvalid = vals.some((val, i) => {
    const num = Number(val.trim());

    if (isNaN(num) || !Number.isInteger(num) || num < 0) {
      return true;
    }

    if (i === 0) {
      if (!isAm && !isPm && num > 23) {
        return true;
      }
      if ((isAm || isPm) && (num < 1 || num > 12)) {
        return true;
      }
    }

    if (i > 0 && num > 59) {
      return true;
    }

    vals[i] = num;
    return false;
  });

  if (isInvalid) {
    return null;
  }

  vals[0] = isAm && vals[0] === 12 ? 0 : vals[0];
  vals[0] = isPm && vals[0] !== 12 ? vals[0] + 12 : vals[0];
  return vals;
};
