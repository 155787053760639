import api from 'APP/packages/api';
import logger from 'APP/packages/logger';
import Entities from 'APP/store';

export const getFriends = async () => {
  try {
    Entities.UsersStore.setIsUserContactsLoading(true);

    const response = await api.relationships.getFriendsData();
    const { users } = response;
    const contacts = users.filter((x) => x.id !== Entities.UsersStore.Me.id);

    Entities.UsersStore.add(contacts);

    Entities.UsersStore.setContacts(contacts);
    Entities.UsersStore.setIsUserContactsLoading(false);
  } catch (error) {
    logger.get('API').error('api.relationships.getFriendsData', error);
  } finally {
    Entities.UsersStore.setLoadedContacts(true);
  }
};
