import React, { FC } from 'react';

import VideoPayload from 'STORE/Messages/Message/Payload/Video';

import { useMediaGalleryVideoPresenter } from './MediaGalleryVideo.presenter';
import styles from './MediaGalleryVideo.styles.m.css';

interface IMediaGalleryVideoProps {
  id: string;
  messageId: string;
  groupId: string;
  payload: VideoPayload;
}
export const MediaGalleryVideo: FC<IMediaGalleryVideoProps> = (props) => {
  const { id, messageId, groupId, payload } = props;
  const { containerRef } = useMediaGalleryVideoPresenter({ id, messageId, groupId, payload });

  return (
    <div
      className={styles.container}
      ref={containerRef}
    />
  );
};
