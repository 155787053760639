import Entities from 'STORE';

import { GroupsEvent } from '../GroupsEvent';

export class DraftsWereDeleted extends GroupsEvent {
  async process() {
    const { draftIds, groupId } = this.eventPayload;

    if (!draftIds || !groupId) {
      return;
    }

    const group = Entities.GroupsStore.getGroupById(groupId.toString());

    if (!group) {
      return;
    }

    if (group) {
      draftIds.forEach((draftId) => {
        group.draftsStore.removeDraftById(draftId.toString());
      });
    }
  }
}
