import classNames from 'classnames';
import React, { FC, memo } from 'react';

import AnimatedPartStyles from '../../AnimatedPart/AnimatedPart.styles.m.css';
import useSyncAnimation from '../../hooks/useSyncAnimations';
import styles from './CatalogMainPlaceholder.styles.m.css';

const BLOCKS_COUNT = 3;
const ITEMS_COUNT = 4;

export const CatalogMainPlaceholder: FC = memo(() => {
  const { isFirstAnimated } = useSyncAnimation({
    firstTimeout: 500,
    secondTimeout: 1000,
  });

  const blocks = Array(BLOCKS_COUNT)
    .fill('block')
    .map((v, i) => `${v}-${i}`);
  const items = Array(ITEMS_COUNT)
    .fill('item')
    .map((v, i) => `${v}-${i}`);

  const animationClassName = {
    [AnimatedPartStyles.animation]: isFirstAnimated,
  };

  return (
    <div className={styles.container}>
      {blocks.map((blockId) => (
        <div
          className={styles.block}
          key={blockId}
        >
          <div className={classNames(styles.header, animationClassName)} />
          <div className={styles.row}>
            {items.map((itemId) => (
              <div key={itemId}>
                <div className={classNames(styles.image, animationClassName)} />
                <div className={classNames(styles.caption, animationClassName)} />
                <div className={classNames(styles.text, animationClassName)} />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
});
