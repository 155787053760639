import { observer } from 'mobx-react';
import React from 'react';

import { GroupAvatar } from 'APP/components/Avatar/GroupAvatar/GroupAvatar';

import OfflineStatus from '../components/OfflineStatus';
import styles from './styles.m.scss';

const SavedMessagesInfo = ({ group }) => {
  return (
    <div className={styles.container}>
      <GroupAvatar group={group} />
      <div className={styles.content}>
        <span className={styles.title}>{group.avatarTitle}</span>
        <OfflineStatus>
          <span className={styles.description}>{group.description}</span>
        </OfflineStatus>
      </div>
    </div>
  );
};

export default observer(SavedMessagesInfo);
