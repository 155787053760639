import { observer } from 'mobx-react';
import React from 'react';

import { TextRenderer } from 'APP/components/TextRenderer/TextRenderer';

import { ArticleYouTubePreview } from '../../../components/ArticleYouTubePreview/ArticleYouTubePreview';
import { useTextPayloadPresenter } from './TextPayload.presenter';
import styles from './TextPayload.styles.m.scss';

export const TextPayload = observer(({ message }) => {
  const presenter = useTextPayloadPresenter(message.payload);

  return (
    <div className={styles.container}>
      {presenter.isShowText && (
        <TextRenderer
          className={styles.text}
          keyWords={message.payload.keyWords}
          userMentions={message.payload.userMentions}
        >
          {message.payload.text}
        </TextRenderer>
      )}

      {presenter.isShowLinkPreview && (
        <ArticleYouTubePreview
          id={message.id}
          payload={message.payload}
        />
      )}
    </div>
  );
});
