import Tasks from 'APP/Tasks';
import { updateMessageReaction as updateMessageReactionModel } from 'APP/model/message/messageModel';
import { IUpdateMessageReaction } from 'APP/model/message/messageModel.types';

export const updateMessageReaction = async (data: IUpdateMessageReaction): Promise<void> => {
  try {
    const message = await updateMessageReactionModel(data);
    Tasks.messaging.updateOldOrFakeMessages({
      messages: [message],
      groupId: message.groupId,
    });
  } catch (error) {
    // do nothing
  }
};
