import React, { FC } from 'react';

import { AddKeyPressHandler } from 'APP/packages/keyBoardHandler';
import { OverlayingPopup } from 'UIKIT/OverlayingPopup/OverlayingPopup';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { useClosePopupOnNavigation } from '../../hooks/useClosePopupOnNavigation';
import styles from './AvatarPopup.styles.m.css';

interface IAvatarPopupProps {
  url: string;
}

export const AvatarPopup: FC<ICommonPopupsProps<IAvatarPopupProps>> = ({
  params,
  popupInstance,
}) => {
  const { url } = params!;
  useClosePopupOnNavigation(popupInstance);

  return (
    <OverlayingPopup
      popupClassName={styles.container}
      isOpened
      onClose={popupInstance.close}
    >
      <AddKeyPressHandler onEsc={popupInstance.close} />
      <button
        className={styles.button}
        onClick={popupInstance.close}
      >
        <img
          className={styles.image}
          alt=""
          src={url}
        />
      </button>
    </OverlayingPopup>
  );
};
