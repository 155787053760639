import { reaction } from 'mobx';
import { IReactionDisposer } from 'mobx/lib/internal';

import { Call } from '../../Call';

export const updateAvatar = (store: Call): IReactionDisposer => {
  return reaction(
    () => {
      const group = store.group;
      if (group) {
        return {
          title: group.avatarTitle,
          url: group.avatarUrl,
          colorIndex: group.avatarColorIndex,
        };
      }
      return null;
    },
    (avatar) => {
      if (avatar) {
        store.setAvatar(avatar);
      }
    },
    { fireImmediately: true }
  );
};
