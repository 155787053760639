import classNames from 'classnames';
import React, { useCallback, useRef, memo, FC, FocusEventHandler } from 'react';

import CloseIcon from 'ICONS/ic-round-close-16.svg';
import SearchIcon from 'ICONS/search-small.svg';

import styles from './SearchInput.styles.m.css';

interface ISearchInputProps {
  className?: string;
  hasSearchIcon?: boolean;
  placeholder?: string;
  value?: string | number;
  autoFocus?: boolean;
  dataTest?: string;
  onFocus?: FocusEventHandler;
  onChange(value: string): void;
}

export const SearchInput: FC<ISearchInputProps> = memo(
  ({
    className,
    hasSearchIcon = true,
    placeholder,
    value = '',
    autoFocus = false,
    dataTest,
    onFocus,
    onChange,
  }) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const isEmpty = value.toString().length > 0;

    const handleChange = useCallback((event) => {
      onChange(event.target.value);
    }, []);

    const handleClear = useCallback(() => {
      onChange('');
      inputRef.current?.focus();
    }, []);

    return (
      <div className={classNames(styles.container, className)}>
        <label className={styles.label}>
          {hasSearchIcon && <SearchIcon className={styles.searchIcon} />}
          <input
            ref={inputRef}
            onFocus={onFocus}
            onChange={handleChange}
            type="text"
            className={styles.input}
            placeholder={placeholder}
            value={value}
            data-test={dataTest}
            autoFocus={autoFocus}
          />
        </label>
        {isEmpty && (
          <button
            onClick={handleClear}
            className={styles.crossContainer}
          >
            <CloseIcon className={styles.crossIcon} />
          </button>
        )}
      </div>
    );
  }
);
