import { useTranslation } from 'APP/packages/translations';

import {
  groupAliasCreateValidation,
  groupAliasEditValidation,
  groupAliasAsyncValidation,
  GROUP_ALIAS_ERRORS,
} from '../fields/groupAliasValidationField';
import groupName from '../fields/groupName';
import asyncValidation from '../hooks/asyncValidation';
import syncValidation from '../hooks/syncValidation';

export const useCreateEditChatGroupValidations = () => {
  const { t } = useTranslation();

  const groupNameErrorToText = {
    [groupName.ERRORS.IS_EMPTY]: ' ',
    [groupName.ERRORS.TOO_SHORT]: t('username_min_length_error'),
  };

  const groupAliasErrorToText = {
    [GROUP_ALIAS_ERRORS.IS_EMPTY]: ' ',
    [GROUP_ALIAS_ERRORS.TOO_SHORT]: t('username_min_length_error'),
    [GROUP_ALIAS_ERRORS.INCORECT_SYMBOLS]: t('channel_alias_allowed_symbols_error'),
    [GROUP_ALIAS_ERRORS.IS_ALREADY_EXIST]: t('channel_link_is_not_available'),
  };

  const groupNameValidator = syncValidation();
  const groupAliasValidator = asyncValidation();

  return {
    groupName: {
      validate: (value, formData, meta) => {
        return groupNameValidator({
          value,
          meta,
          validate: groupName.validate,
          errorToText: groupNameErrorToText,
        });
      },
    },
    groupAliasCreate: {
      validate: (value, formData, meta) => {
        return groupAliasValidator({
          value,
          values: formData,
          meta,
          validate: groupAliasCreateValidation,
          asyncValidate: groupAliasAsyncValidation,
          errorToText: groupAliasErrorToText,
        });
      },
    },
    groupAliasEdit: {
      validate: (value, formData, meta) => {
        return groupAliasValidator({
          value,
          values: formData,
          meta,
          validate: groupAliasEditValidation,
          asyncValidate: groupAliasAsyncValidation,
          errorToText: groupAliasErrorToText,
        });
      },
    },
  };
};
