import { action, computed, observable } from 'mobx';

import {
  ApplicationTheme,
  ChatBackgroundPattern,
  DEFAULT_APPLICATION_THEME,
} from 'APP/constants/appearance';
import { storage } from 'APP/packages/storage';

export class Appearance {
  @observable public activeTheme: ApplicationTheme | null = storage.theme.get();
  @observable public chatBackground: ChatBackgroundPattern = storage.chatBackground.get();
  @observable private systemTheme: ApplicationTheme | null = null;

  get theme(): ApplicationTheme {
    if (!this.activeTheme) {
      return this.systemTheme || DEFAULT_APPLICATION_THEME;
    }

    return this.activeTheme;
  }

  @computed
  get isDark(): boolean {
    return this.theme === ApplicationTheme.Dark;
  }

  @action
  setTheme(theme: ApplicationTheme | null): void {
    this.activeTheme = theme;
  }

  @action
  setSystemTheme(theme: ApplicationTheme): void {
    this.systemTheme = theme;
  }

  @computed
  get hasChatBackground(): boolean {
    return this.chatBackground !== ChatBackgroundPattern.WithoutPattern;
  }

  @action
  setChatBackground(chatBackground: ChatBackgroundPattern): void {
    this.chatBackground = chatBackground;
  }
}
