import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { ApplicationTheme } from 'APP/constants/appearance';
import { useContextMenu } from 'MAIN/hooks/useContextMenu/useContextMenu';
import { PopoverMenu } from 'UIKIT/PopoverMenu/PopoverMenu';

import StreamerMeta from '../../../SharedComponents/StreamerMeta';
import Video from '../../../SharedComponents/Video';
import NoVideoPlaceholder from './NoVideoPlaceholder';
import usePresenter from './presenter';
import styles from './styles.m.css';

const StreamerVideo = ({ streamer, containerClassName, className, isMinimize, onClick }) => {
  const { popover, onContextMenu, modifiers } = useContextMenu();
  const presenter = usePresenter({ streamer, onClick, popover });

  if (!streamer) {
    return null;
  }

  return (
    <>
      <div
        className={classNames(styles.container, containerClassName)}
        onClick={presenter.onClick}
        role="button"
        tabIndex={0}
        onContextMenu={presenter.actions.length ? onContextMenu : null}
        ref={popover.containerRef}
      >
        {streamer.hasVideo ? (
          <Video
            videoTrack={streamer.videoTrack}
            className={classNames(styles.video, className)}
          />
        ) : (
          <NoVideoPlaceholder streamer={streamer} />
        )}
        <StreamerMeta
          isMinimize={isMinimize}
          streamer={streamer}
        />
      </div>
      <PopoverMenu
        isOpened={popover.isOpened}
        menuItems={presenter.actions}
        onClose={popover.close}
        reference={popover.containerRef.current}
        placement="bottom-start"
        modifiers={modifiers}
        theme={ApplicationTheme.Dark}
      />
    </>
  );
};

export default observer(StreamerVideo);
