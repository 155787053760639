import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Button } from 'UIKIT/Button/Button';

import { useJoinButtonPresenter } from './JoinButton.presenter';

interface IJoinButtonProps {
  className?: string;
  groupId: string;
}

export const JoinButton: FC<IJoinButtonProps> = observer((props) => {
  const { className, groupId } = props;
  const presenter = useJoinButtonPresenter(groupId);

  return (
    <Button
      className={className}
      onClick={presenter.onClick}
      isLoading={presenter.isLoading}
      theme="1"
    >
      {presenter.title}
    </Button>
  );
});
