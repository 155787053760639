import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Trans, useTranslation } from 'APP/packages/translations';
import MuteIcon from 'ICONS/ic-mute.svg';
import { Switch } from 'UIKIT/Switch/Switch';

import { UserSettingsPopupItemInfo } from '../UserSettingsPopupItemInfo/UserSettingsPopupItemInfo';
import { useNotificationsSettingsPresenter } from './NotificationsSettings.presenter';
import styles from './NotificationsSettings.styles.m.css';

interface INotificationsSettingsProps {
  className?: string;
}

export const NotificationsSettings: FC<INotificationsSettingsProps> = observer(({ className }) => {
  const { t } = useTranslation();
  const { isEnabled, settingsLink, onClick } = useNotificationsSettingsPresenter();

  return (
    <>
      <label className={classNames(className, styles.switchContainer)}>
        <UserSettingsPopupItemInfo
          Icon={MuteIcon}
          title={t('notifications')}
        />
        <Switch
          className={styles.switch}
          isChecked={isEnabled}
          onChange={onClick}
        />
      </label>

      {settingsLink && (
        <>
          <div className={styles.deviceSystemActions}>
            <Trans
              translateKey="settins_system_push"
              keyParams={{
                0: (
                  <a
                    className={styles.deviceSystemLink}
                    href={settingsLink}
                  >
                    {t('settins_system_push_device')}
                  </a>
                ),
              }}
            />
          </div>
          <div className={styles.divider}></div>
        </>
      )}
    </>
  );
});
