import { mapSpaceResponse } from 'APP/model/space/spaceModel.mapper';
import api from 'APP/packages/api';
import { GetCatalogRequest } from 'APP/packages/api/types/model/getCatalogRequest';
import { SpaceItemResponse } from 'APP/packages/api/types/model/spaceItemResponse';
import logger from 'APP/packages/logger';

import { IBooleanResponse } from '../common/commonModel.types';
import { buildModelError } from '../error/errorBuilder';
import { IGroup } from '../group/groupModel.types';
import {
  mapGetCatalogRequest,
  mapGetCatalogResponse,
  mapItemsResponse,
  mapNewsfeedResponse,
  mapChannelsStartPageResponse,
} from './catalogModel.mapper';
import {
  ICatalog,
  ICatalogItemsList,
  ISupportedLocalesList,
  IGetCatalog,
  IGetCatalogWidget,
  ISearchCatalogItemsByName,
  ISearchCatalogItemsByCategory,
  IUserLocaleChange,
  IGetNewsfeed,
  INewsfeed,
  IHideNewsfeedPosts,
  IUnhideNewsfeedPosts,
  IGetStartPageItems,
  IStartPageItems,
  CatalogType,
  RecommendationType,
  StartPageItemType,
} from './catalogModel.types';

export const getCatalog = async (data: IGetCatalog): Promise<ICatalog> => {
  try {
    const catalog = await api.catalog.getCatalog(mapGetCatalogRequest(data));
    return mapGetCatalogResponse(catalog);
  } catch (error) {
    logger.get('API').error('catalog.getCatalog', error);
    throw buildModelError(error);
  }
};

export const getWidgetItems = async (data: IGetCatalogWidget): Promise<ICatalogItemsList> => {
  try {
    const itemsList = await api.catalog.getWidgetItems(data);
    return mapItemsResponse(itemsList);
  } catch (error) {
    logger.get('API').error('catalog.getWidgetItems', error);
    throw buildModelError(error);
  }
};

export const searchItemsByName = async (
  data: ISearchCatalogItemsByName
): Promise<ICatalogItemsList> => {
  try {
    const itemsList = await api.catalog.searchItemsByName(data);
    return mapItemsResponse(itemsList);
  } catch (error) {
    logger.get('API').error('catalog.searchItemsByName', error);
    throw buildModelError(error);
  }
};

export const searchItemsByCategory = async (
  data: ISearchCatalogItemsByCategory
): Promise<ICatalogItemsList> => {
  try {
    const itemsList = await api.catalog.searchItemsByCategory(data);
    return mapItemsResponse(itemsList);
  } catch (error) {
    logger.get('API').error('catalog.searchItemsByCategory', error);
    throw buildModelError(error);
  }
};

export const getSupportedLocales = async (): Promise<ISupportedLocalesList> => {
  try {
    return await api.catalog.getSupportedLocales();
  } catch (error) {
    logger.get('API').error('catalog.getSupportedLocales', error);
    throw buildModelError(error);
  }
};

export const updateUserLocalePreferences = async (
  data: IUserLocaleChange
): Promise<IBooleanResponse> => {
  try {
    return await api.catalog.updateUserLocalePreferences(data);
  } catch (error) {
    logger.get('API').error('catalog.updateUserLocalePreferences', error);
    throw buildModelError(error);
  }
};

export const getNewsFeed = async (data: IGetNewsfeed): Promise<INewsfeed> => {
  try {
    const newsfeed = await api.catalog.getNewsFeed(data);
    return mapNewsfeedResponse(newsfeed);
  } catch (error) {
    logger.get('API').error('catalog.getNewsFeed', error);
    throw buildModelError(error);
  }
};

export const hideNewsfeedPosts = async (data: IHideNewsfeedPosts): Promise<IBooleanResponse> => {
  try {
    return await api.catalog.hidePostsInNewsFeed(data);
  } catch (error) {
    logger.get('API').error('catalog.hidePostsInNewsFeed', error);
    throw buildModelError(error);
  }
};

export const unhideNewsfeedPosts = async (
  data: IUnhideNewsfeedPosts
): Promise<IBooleanResponse> => {
  try {
    return await api.catalog.unhidePostsInNewsFeed(data);
  } catch (error) {
    logger.get('API').error('catalog.unhidePostsInNewsFeed', error);
    throw buildModelError(error);
  }
};

export const getStartPageItems = async <T extends StartPageItemType>(
  data: IGetStartPageItems
): Promise<IStartPageItems<T>> => {
  try {
    const response = await api.catalog.getStartPageItems(data as unknown as GetCatalogRequest);

    let items: T[] = [];

    switch (data.catalogType) {
      case CatalogType.Space:
        items = response.items.map((item: SpaceItemResponse) =>
          mapSpaceResponse(item.space)
        ) as T[];
        break;
    }

    return {
      recommendationType: response.recommendationType as RecommendationType,
      items,
    };
  } catch (error) {
    logger.get('API').error('catalog.getStartPageItems', error);
    throw buildModelError(error);
  }
};

export const getChannelsStartPage = async (): Promise<IGroup[]> => {
  try {
    const data = await api.catalog.getChannelsStartPage();
    return mapChannelsStartPageResponse(data);
  } catch (error) {
    logger.get('API').error('catalog.getChannelsStartPage', error);
    throw buildModelError(error);
  }
};
