import { observable, computed } from 'mobx';

import { NewsType, INewsItem } from 'APP/model/catalog/catalogModel.types';
import Entities from 'APP/store';
import { Group } from 'APP/store/Groups/Group';
import { ChatMessage } from 'APP/store/Messages/Message/ChatMessage/ChatMessage';

import { NewsItem } from './NewsItem';

export class Post extends NewsItem {
  @observable title: string;
  @observable groupId: string | null;
  @observable messageId: string | null;

  constructor(data: INewsItem) {
    super(NewsType.Post);

    this.title = data.title || '';
    this.groupId = data.group?.groupResponse?.id || null;
    this.messageId = data.message?.message?.id || null;
  }

  @computed
  get group(): Group | null {
    return this.groupId ? Entities.GroupsStore.getGroupById(this.groupId) || null : null;
  }

  @computed
  get message(): ChatMessage | null {
    return this.messageId
      ? this.group?.messagesStore?.getMessageById(this.messageId) || null
      : null;
  }
}
