import Tasks from 'APP/Tasks';
import { addAlert } from 'APP/Tasks/app/showAlert/showAlert';
import { ALERT_TYPES } from 'APP/constants/app';
import api from 'APP/packages/api';
import logger from 'APP/packages/logger';
import Entities from 'STORE';

async function loadGroup(id, isChannel) {
  try {
    const response = await api.group.getGroupsByIds({ groupIds: [id] });
    if ((response?.userBannedInGroups || []).includes(id)) {
      addAlert({
        type: ALERT_TYPES.USER_BANNED_IN_CHANNEL,
      });
      return;
    }
    if (response?.items?.[0]) {
      Entities.GroupsStore.merge([response.items[0]]);
      await Tasks.relationships.loadUnknownDataFromGroups({
        groups: [Entities.GroupsStore.getGroupById(id)],
      });
      return Entities.GroupsStore.getGroupById(id);
    }
    addAlert({
      type: isChannel ? ALERT_TYPES.CHANNEL_IS_NOT_ACCESSIBLE : ALERT_TYPES.CHAT_IS_NOT_ACCESSIBLE,
    });
  } catch (error) {
    logger.get('API').error('group.getGroupsByIds', error);
  }
}

export async function handleGroup({ groupId, isChannel = false }) {
  if (!groupId) {
    return null;
  }
  let group = Entities.GroupsStore.getGroupById(groupId);

  if (!group) {
    group = await loadGroup(groupId, isChannel);
    if (group?.isChatGroup) {
      if (group?.isClosed) {
        await Tasks.group.joinGroup({ groupId });
      }
      group = Entities.GroupsStore.getGroupById(groupId);
    }
  }

  if (!group) {
    return null;
  }

  return {
    groupRoute: `/${group.route}/${group.id}`,
    spaceId: group.spaceId,
  };
}
