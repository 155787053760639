import { observer } from 'mobx-react';
import React, { FC, useRef } from 'react';

import { ApplicationTheme } from 'APP/constants/appearance';
import MoreIcon from 'ICONS/ic-more.svg';
import { IconButton, IconViewSizes } from 'UIKIT/IconButton';
import { PopoverMenu } from 'UIKIT/PopoverMenu/PopoverMenu';

import { useShowMoreButtonPresenter } from './ShowMoreButton.presenter';

interface IShowMoreButtonProps {
  className?: string;
}

export const ShowMoreButton: FC<IShowMoreButtonProps> = observer(({ className }) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const presenter = useShowMoreButtonPresenter();

  return (
    <>
      {!!presenter.menu.length && (
        <IconButton
          Icon={MoreIcon}
          onClick={presenter.togglePopover}
          size={IconViewSizes.Big}
          theme="12"
          iconSize={IconViewSizes.Medium}
          className={className}
          setRef={buttonRef}
        />
      )}
      <PopoverMenu
        isOpened={presenter.isPopoverOpened}
        placement="top-end"
        menuItems={presenter.menu}
        reference={buttonRef.current}
        onClose={presenter.closePopover}
        theme={ApplicationTheme.Dark}
      />
    </>
  );
});
