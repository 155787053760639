import { useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { ApplicationTheme, ChatBackgroundPattern } from 'APP/constants/appearance';
import Entities from 'STORE';

import { ICommonPopupsProps } from '../../PopupManager.types';

interface IAppearanceSettingsPopupPresenter {
  activeTheme: ApplicationTheme | null;
  activeChatBackground: ChatBackgroundPattern;
  hasBackButton: boolean;
  onThemeChange(theme: ApplicationTheme | null): void;
  onChatBackgroundChange(chatBackground: ChatBackgroundPattern): void;
  onClose(): void;
  onBack(): void;
}

export function useAppearanceSettingsPopupPresenter({
  popupInstance,
  onClose,
  onBack,
}: ICommonPopupsProps): IAppearanceSettingsPopupPresenter {
  const presenter = useLocalStore<IAppearanceSettingsPopupPresenter>(() => ({
    get activeTheme(): ApplicationTheme | null {
      return Entities.appearance.activeTheme;
    },

    get activeChatBackground(): ChatBackgroundPattern {
      return Entities.appearance.chatBackground;
    },

    get hasBackButton(): boolean {
      return !!onBack;
    },

    onThemeChange(theme: ApplicationTheme | null): void {
      Tasks.appearance.setTheme(theme);
    },

    onChatBackgroundChange(chatBackground: ChatBackgroundPattern): void {
      Tasks.appearance.setChatBackground(chatBackground);
    },

    onClose(): void {
      popupInstance.close();
      onClose?.();
    },

    onBack(): void {
      popupInstance.close();
      onBack?.();
    },
  }));

  return presenter;
}
