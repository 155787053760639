import Tasks from 'APP/Tasks';
import { ALERT_TYPES } from 'APP/constants/app';
import deviceInfo from 'APP/packages/deviceInfo';

const hasAudioPermissions = async (checkMicrophone = true) => {
  const { hasSpeaker } = await deviceInfo.getMediaDevices();

  if (!hasSpeaker) {
    Tasks.app.addAlert({
      type: ALERT_TYPES.CALL_NO_DYNAMIC_CONNECTED,
    });
    return false;
  }

  if (!checkMicrophone) {
    return true;
  }

  return await Tasks.permissions.hasMicrophonePermission();
};

export default hasAudioPermissions;
