import { CoinfideUrlResponse } from 'APP/packages/api/types/model/coinfideUrlResponse';

import { request } from '../request/request';

const ROOT_API = 'integrationCoinfide';

export const getCoinfideUrl = (): Promise<CoinfideUrlResponse> => {
  return request({
    rootApi: ROOT_API,
    url: '/getCoinfideUrl',
    method: 'POST',
  });
};
