import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, MouseEventHandler, ReactNode } from 'react';

import imgStab from 'APP/images/img-stub.png';
import { IGraphicSize } from 'APP/model/common/commonModel.types';

import { useImagePreviewPresenter } from './ImagePreview.presenter';
import styles from './ImagePreview.styles.m.css';

interface IImagePreviewProps {
  className?: string;
  imageClassName?: string;
  children?: ReactNode;
  url: string;
  graphicSize: IGraphicSize;
  maxWidth?: number;
  maxHeight?: number;
  placeholderQuality?: number;
  needCropImage?: boolean;
  isPreview?: boolean;
  isView?: boolean;
  isLocal?: boolean;
  onClick?: MouseEventHandler<HTMLElement>;
}

export const ImagePreview: FC<IImagePreviewProps> = observer((props) => {
  const { className, imageClassName, onClick, children } = props;
  const presenter = useImagePreviewPresenter(props);

  if (!presenter.isShow) {
    return (
      <div
        role="button"
        tabIndex={0}
        className={classNames(styles.container, className)}
        style={presenter.resizedImage.placeholderStyle}
        onClick={onClick}
      >
        <img
          className={styles.errorImage}
          style={presenter.resizedImage.imageStyle}
          src={imgStab}
          alt=""
        />

        {children}
      </div>
    );
  }

  return (
    <div
      role="button"
      tabIndex={0}
      className={classNames(styles.container, className)}
      style={presenter.resizedImage.placeholderStyle}
      onClick={onClick}
    >
      {presenter.hasPlaceholder && (
        <img
          className={classNames(styles.placeholder, styles.hidden)}
          src={presenter.placeholderUrl}
          alt=""
          onLoad={presenter.onLoad}
        />
      )}

      <img
        className={classNames(styles.image, styles.hidden, imageClassName)}
        style={presenter.resizedImage.imageStyle}
        src={presenter.imageUrl}
        alt=""
        onLoad={presenter.onLoad}
        onError={presenter.onLoadError}
      />

      {children}
    </div>
  );
});
