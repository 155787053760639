import { getCoinFide } from 'APP/model/market/marketModel';
import { IMarket } from 'APP/model/market/marketModel.types';
import Entities from 'APP/store';

let tokenRequest: Promise<IMarket> | null = null;
let timer: ReturnType<typeof setInterval> | null = null;
const RESET_TOKEN_DELAY = 1000 * 60 * 30; // 30min

export function stopUpdateMarketConfig(): void {
  if (timer) {
    clearTimeout(timer);
  }
  tokenRequest = null;
}

async function updateMarketConfig(): Promise<IMarket> {
  try {
    const data = await getCoinFide();
    Entities.marketplace.setData(data);
    return data;
  } catch {
    return Entities.marketplace.marketConfig;
  }
}

export async function getMarketConfig(reset: boolean): Promise<IMarket> {
  if (tokenRequest) {
    const data: IMarket = await tokenRequest;
    Entities.marketplace.setData(data);
  }
  if (reset) {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      getMarketConfig(true);
    }, RESET_TOKEN_DELAY);
    tokenRequest = updateMarketConfig();
  }
  return Entities.marketplace.marketConfig;
}
