import dateService from 'APP/packages/date';
import { useTranslation } from 'APP/packages/translations';

export default ({ date }) => {
  const { t } = useTranslation();

  if (!date) {
    return t('last_seen_a_long_time_ago');
  }

  if (dateService.isToday(date)) {
    const minutesHours = dateService.format(date, {
      hour: 'numeric',
      minute: 'numeric',
    });

    return t('last_seen_at').replace('{0}', minutesHours);
  }

  if (dateService.isLastYear(date)) {
    const monthYear = dateService.format(date, {
      month: 'short',
      day: 'numeric',
    });

    const minutesHours = dateService.format(date, {
      hour: 'numeric',
      minute: 'numeric',
    });

    return t('last_seen_more_than_a_day_ago')
      .replace('{0}', monthYear)
      .replace('{1}', minutesHours);
  }

  return t('last_seen_a_long_time_ago');
};
