import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { GroupType } from 'APP/model/group/groupModel.types';
import Entities from 'APP/store';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import { Group } from 'STORE/Groups/Group';
import { TAvatarBackgroundColorIndex } from 'UIKIT/Avatar/Avatar.types';

import { IChannelFormData } from '../../components/ChannelForm/ChannelForm.types';

export interface IEditChannelPopupPresenterParams {
  groupId: string;
}

interface IEditChannelPopupPresenter {
  initialValues: Partial<IChannelFormData>;
  group: Group | null;
  avatarTitle?: string;
  avatarColor?: TAvatarBackgroundColorIndex;
  onSubmit(channelData: IChannelFormData): Promise<void>;
}

export function useEditChannelPopupPresenter({
  params,
  popupInstance,
}: ICommonPopupsProps<IEditChannelPopupPresenterParams>): IEditChannelPopupPresenter {
  const { groupId } = params!;

  const presenter = useLocalStore<IEditChannelPopupPresenter>(() => ({
    get initialValues(): Partial<IChannelFormData> {
      return {
        type: presenter.group?.type,
        name: presenter.group?.name,
        description: presenter.group?.description,
        avatarUrl: presenter.group?.avatarUrl || undefined,
        categoryId: presenter.group?.categoryId || undefined,
        donationUrl: presenter.group?.donationUrl,
        deepLinkAlias: presenter.group?.deepLinkInfo?.deepLinkAlias || '',
      };
    },

    get group(): Group | null {
      return Entities.GroupsStore.getGroupById(groupId);
    },

    get avatarTitle(): string | undefined {
      return presenter.group?.avatarTitle || undefined;
    },

    get avatarColor(): TAvatarBackgroundColorIndex | undefined {
      return presenter.group?.avatarColorIndex;
    },

    async onSubmit(channelData: IChannelFormData): Promise<void> {
      await Tasks.group.updateGroupInfo({
        ...channelData,
        groupId: groupId,
        deepLinkAlias: channelData.type === GroupType.ChannelOpen ? channelData.deepLinkAlias : '',
      });

      popupInstance.close();
    },
  }));

  useEffect(() => {
    if (
      !Entities.GroupsStore.isLoading &&
      !(presenter.group?.isChannel && presenter.group.withMeInAdmins)
    ) {
      popupInstance.close();
    }
  }, [presenter.group, Entities.GroupsStore.isLoading]);

  return presenter;
}
