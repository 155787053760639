import { RefObject, useRef } from 'react';

const TOP_PADDING = 14;
const STICKY_CLASS_NAME = 'sticky-date';

interface IStickyDate {
  scrollRef: RefObject<HTMLDivElement>;
  stickyClassName: string;
  onScroll: () => void;
}

export const useStickyDate = (): IStickyDate => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const timer = useRef<ReturnType<typeof setTimeout>>();

  const stopScroll = (): void => {
    if (scrollRef.current) {
      const allElements = scrollRef.current.querySelectorAll<HTMLElement>(`.${STICKY_CLASS_NAME}`);
      const containerTop = scrollRef.current.scrollTop;

      const currentDate = Array.from(allElements).find((el) => {
        const { offsetTop, offsetHeight } = el;
        const top = offsetTop - containerTop;
        return -offsetHeight <= top && top <= TOP_PADDING;
      });

      if (currentDate) {
        currentDate.classList.add('stuck');
      }
      scrollRef.current.classList.remove('is-scrolling');
    }
  };

  const onScroll = (): void => {
    if (scrollRef.current) {
      if (!scrollRef.current.classList.contains('is-scrolling')) {
        scrollRef.current.classList.add('is-scrolling');
      }
      const currentStuck = document.querySelector('.stuck');
      if (currentStuck) {
        currentStuck.classList.remove('stuck');
      }

      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(stopScroll, 1000);
    }
  };

  return { scrollRef, stickyClassName: STICKY_CLASS_NAME, onScroll };
};
