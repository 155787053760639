import Tasks from 'APP/Tasks';
import { TargetForwardType } from 'APP/constants/forward';
import { GroupType } from 'APP/model/group/groupModel.types';
import { IShareSelection } from 'APP/types/share';
import Entities from 'STORE';

export const getTargetGroupIds = async (selection: IShareSelection[]) => {
  const promises = selection.map(async (item) => {
    const { type, data } = item;

    switch (type) {
      case TargetForwardType.Chat: {
        if (data.type === GroupType.SavedMessagesFake) {
          const group = await Tasks.group.createGroupAndInvite(
            {
              type: GroupType.MySavedMessages,
            },
            false
          );

          return group ? group.id : null;
        } else {
          return data.id;
        }
      }
      case TargetForwardType.Channel: {
        return data.id;
      }
      case TargetForwardType.Contact: {
        if (!data.group) {
          const group = await Tasks.group.createGroupAndInvite(
            {
              members: [Entities.UsersStore.Me.id, data.id],
              type: GroupType.P2P,
            },
            false
          );

          return group ? group.id : null;
        } else {
          return data.group.id;
        }
      }
      default: {
        return null;
      }
    }
  });

  const targetGroupIds = (await Promise.all(promises)) as string[];

  return targetGroupIds.filter((id) => Boolean(id));
};
