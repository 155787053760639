import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { AddKeyPressHandler } from 'APP/packages/keyBoardHandler';
import { FullscreenPopup } from 'UIKIT/FullscreenPopup/FullscreenPopup';
import { Loader } from 'UIKIT/Loader/Loader';

import { useMediaGalleryPresenter } from './MediaGallery.presenter';
import styles from './MediaGallery.styles.m.css';
import { MediaGalleryContent } from './MediaGalleryContent/MediaGalleryContent';
import { MediaGalleryFooter } from './MediaGalleryFooter/MediaGalleryFooter';
import { MediaGalleryHeader } from './MediaGalleryHeader/MediaGalleryHeader';
import { MediaGallerySidebar } from './MediaGallerySidebar/MediaGallerySidebar';

interface IMediaGalleryProps {
  canDelete?: boolean;
  canNavigate?: boolean;
}

export const MediaGallery: FC<IMediaGalleryProps> = observer((props) => {
  const { canDelete = true, canNavigate = true } = props;
  const { presenter, containerRef } = useMediaGalleryPresenter();

  if (!presenter.isOpened) {
    return null;
  }
  if (presenter.mustBeClosed) {
    presenter.onClose();
    return null;
  }

  if (presenter.isLoading) {
    return (
      <FullscreenPopup>
        <AddKeyPressHandler onEsc={presenter.onClose} />
        <div
          className={styles.wrapper}
          ref={containerRef}
          tabIndex={-1}
        >
          <div className={styles.loader}>
            <Loader />
          </div>
        </div>
      </FullscreenPopup>
    );
  }

  return (
    <FullscreenPopup>
      <AddKeyPressHandler onEsc={presenter.onClose} />
      <div
        className={styles.wrapper}
        ref={containerRef}
        tabIndex={-1}
      >
        {presenter.file && (
          <>
            <MediaGalleryHeader
              file={presenter.file}
              canDelete={canDelete}
              canNavigate={canNavigate}
              canOpenSidebar={presenter.isExistSidebar}
              isShowSidebar={presenter.isShowSidebar}
              onSetSidebarVisibility={presenter.onSetSidebarVisibility}
              onClose={presenter.onClose}
              galleryState={presenter.galleryState!}
            />

            <div className={styles.content}>
              <MediaGalleryContent
                file={presenter.file}
                galleryState={presenter.galleryState!}
              />
              {presenter.albumPreviewFiles && (
                <MediaGalleryFooter
                  file={presenter.file}
                  albumPreviewFiles={presenter.albumPreviewFiles}
                />
              )}
            </div>

            {presenter.isShowSidebar && (
              <MediaGallerySidebar
                file={presenter.file}
                onClose={presenter.onClose}
              />
            )}
          </>
        )}
      </div>
    </FullscreenPopup>
  );
});
