import { PayloadType } from 'APP/model/message/messageModel.types';
import Entities from 'APP/store';

export default (messages) => {
  const usersIds = new Set();
  const groupsIds = new Set();

  messages.forEach((message) => {
    if (message.senderId) {
      usersIds.add(message.senderId);
    }

    if (message.quotedMessage && message.quotedMessage.senderId) {
      usersIds.add(message.quotedMessage.senderId);
    }

    if (message.payload?.initiator) {
      usersIds.add(message.payload?.initiator);
    }

    if (message.payload?.users) {
      message.payload.users.forEach((user) => usersIds.add(user.id));
    }

    const userMentions = message.payload.userMentions;

    if (userMentions && userMentions.length) {
      userMentions.forEach((mention) => {
        // ignore @all mentionId
        if (mention.userId > 0) {
          usersIds.add(mention.userId);
        }
      });
    }

    const group = Entities.GroupsStore.getGroupById(message.groupId);

    // load forward data to the saved messages group
    if (group?.isSavedMessages && message.forwardedMessage) {
      if (message.forwardedMessage.senderId) {
        usersIds.add(message.forwardedMessage.senderId);
      }

      if (message.forwardedMessage.groupId) {
        groupsIds.add(message.forwardedMessage.groupId);
      }
    }

    if (message.payload.payloadType === PayloadType.Contact) {
      usersIds.add(message.payload.userId);
    }

    // articles and albums
    if (message.payload.payloads) {
      message.payload.payloads.forEach((parentPayload) => {
        if (parentPayload.payload.payloadType === PayloadType.Contact) {
          usersIds.add(parentPayload.payload.userId);
        }

        const userMentions = parentPayload.payload.userMentions;

        if (userMentions && userMentions.length) {
          userMentions.forEach((mention) => {
            if (mention.userId > 0) {
              usersIds.add(mention.userId);
            }
          });
        }
      });
    }
  });

  return {
    usersIds: Array.from(usersIds.values()),
    groupsIds: Array.from(groupsIds.values()),
  };
};
