import { buildModelError } from 'APP/model/error/errorBuilder';
import { mapCoinfideUrlResponse } from 'APP/model/market/marketModel.mapper';
import { IMarket } from 'APP/model/market/marketModel.types';
import api from 'APP/packages/api';
import { CoinfideUrlResponse } from 'APP/packages/api/types/model/coinfideUrlResponse';
import logger from 'APP/packages/logger';

export const getCoinFide = async (): Promise<IMarket> => {
  try {
    const marketData: CoinfideUrlResponse = await api.marketplace.getCoinfideUrl();
    return mapCoinfideUrlResponse(marketData);
  } catch (error) {
    logger.get('API').error('pi.marketplace.getCoinfideUrl', error);
    throw buildModelError(error);
  }
};
