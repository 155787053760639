import { GroupEventsRequestBatch } from 'APP/packages/api/types/model/groupEventsRequestBatch';

import { IMessage } from '../message/messageModel.types';

export interface IGroupCounterView {
  counter: number;
  groupId: string;
  lastEvent?: IGroupEvent;
  lastEventTs?: number;
  lastMessage?: IMessage;
  lastMessageTs?: number;
  lastUserMentionTs: number;
  opponentSeenTs?: number;
  previousEventTs?: number;
  previousMessageTs?: number;
  seenTs: number;
  totalMessagesCount?: number;
  unreadUserMentionsCounter: number;
}

export interface IGroupEventResponse {
  groupId: string;
  eventTs?: string;
  events: IGroupEvent[];
}

export interface IGroupEvent<T = object> {
  eventPayload?: T;
  groupId: string;
  id: string;
  ts: number;
  type: EventType;
}

export type IGroupEventsRequestBatch = GroupEventsRequestBatch;
export interface IGroupEventRequest {
  groupId: string;
  eventTs?: number;
  lastEventTs?: number;
}

export interface IGroupCounterState {
  groupId: string;
  updatedTs: string;
}

export interface IGroupCounterUpdates {
  events: string;
  updates: IGroupCounterState[];
}

export enum EventType {
  ClearHistory = 'clear.history',
  ClearHistoryForAll = 'clear.history.for.all',
  ConfigGroupAds = 'config.group.ads',
  ConfigGroupPin = 'config.group.pin',
  ConfigGroupUnread = 'config.group.unread',
  DeleteMessagesFromUser = 'delete.messages.from.user',
  DraftWasUpdated = 'draft.was.updated',
  DraftInputWasUpdated = 'draft.input.was.updated',
  DraftsWereDeleted = 'drafts.were.deleted',
  Group = 'group',
  GroupChanged = 'group.changed',
  GroupSelfLeave = 'group.self.leave',
  GroupUserDisplayDataUpdated = 'group.user.display.data.updated',
  GroupWasDeleted = 'group.was.deleted',
  GroupParticipantsChanged = 'group.participants.changed',
  LinkPreviewWasCreated = 'link.preview.was.created',
  MessageReactionsWasUpdated = 'message.reactions.was.updated',
  MessageWasUpdated = 'message.was.updated',
  MessagesWereDeleted = 'messages.were.deleted',
  PermissionsWereChanged = 'permissions.were.changed',
  PhonebookWasUpdated = 'phonebook.was.updated',
  RolesWereChanged = 'roles.were.changed',
  ScheduledMessageWasUpdated = 'scheduled.message.was.updated',
  ScheduledMessagesWereDeleted = 'scheduled.messages.were.deleted',
  SpaceGroupVisibilityUpdated = 'space.group.visibility.updated',
  SpaceGroupsVisibilityUpdated = 'space.groups.visibility.updated',
  SpaceRolesWereChanged = 'space.roles.were.changed',
  SpaceSelfLeave = 'space.self.leave',
  SpaceUserWasBanned = 'space.user.was.banned',
  SpaceUserWasRemoved = 'space.user.was.removed',
  SpaceWasDeleted = 'space.was.deleted',
  SpacesPinChanged = 'spaces.pin.changed',
  StickerPackUpdated = 'sticker.pack.updated',
  UserBanStatusesWereChanged = 'user.ban.statuses.were.changed',
  UserBlockedMe = 'user.blocked.me',
  UserDisplayDataUpdated = 'user.display.data.updated',
  UserMarkedUnknownByMe = 'user.marked.unknown.by.me',
  UserPreferencesChanged = 'user.preferences.changed',
  UserPrivacySettingsUpdated = 'user.privacy.settings.updated',
  UserRewardUpdated = 'user.reward.updated',
  UserStickerPacksUpdated = 'user.sticker.packs.updated',
  UserUnblockedMe = 'user.unblocked.me',
  UserWasBanned = 'user.was.banned',
  UserWasBlockedByMe = 'user.was.blocked.by.me',
  UserWasRemoved = 'user.was.removed',
  UserWasUnblockedByMe = 'user.was.unblocked.by.me',
}
