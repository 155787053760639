import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { GroupType } from 'APP/model/group/groupModel.types';
import { useTranslation } from 'APP/packages/translations';
import validations from 'APP/packages/validations';

import { IChannelFormData } from '../../components/ChannelForm/ChannelForm.types';

enum CreateChannelStep {
  GeneralInfo = 'general-info',
  Privacy = 'privacy',
}

interface IRadioOption {
  value: GroupType.ChannelOpen | GroupType.ChannelClosed;
  label: string;
  description: string;
}

interface IChannelFormPresenter {
  step: CreateChannelStep;
  categories: { label: string; value: string }[];
  initialValues: Partial<IChannelFormData>;
  nameValidate: any;
  categoryValidate: any;
  radioOptions: IRadioOption[];
  aliasValidate: any | null;
  isGeneralInfo: boolean;
  isPrivacy: boolean;
  isShowGeneralInfo: boolean;
  isShowPrivacy: boolean;
  isShowPrevStep: boolean;
  isShowSubmit: boolean;
  loadCategories(): Promise<void>;
  onShowGeneralInfo(): void;
  onShowPrivacy(): void;
  forceUpdateForm(): void;
}

export function useChannelFormPresenter(
  initialValues: Partial<IChannelFormData>,
  isEditMode: boolean
): IChannelFormPresenter {
  const { t } = useTranslation();
  const formValidations = validations.createEditChannel();
  const source = useAsObservableSource({ isEditMode });

  const presenter = useLocalStore<IChannelFormPresenter>(() => ({
    step: CreateChannelStep.GeneralInfo,
    categories: [],
    initialValues,

    nameValidate: formValidations.channelName.validate,
    categoryValidate: formValidations.channelCategory.validate,

    radioOptions: [
      {
        value: GroupType.ChannelOpen,
        label: t('channel_public_option'),
        description: t('channel_public_option_subhint'),
      },
      {
        value: GroupType.ChannelClosed,
        label: t('channel_private_option'),
        description: t('channel_private_option_subhint'),
      },
    ],

    get aliasValidate(): any | null {
      if (source.isEditMode) {
        return formValidations.channelAliasEdit.validate;
      }

      if (presenter.isPrivacy) {
        return formValidations.channelAliasCreate.validate;
      }

      return null;
    },

    async loadCategories(): Promise<void> {
      const categories = await Tasks.search.getAllCategoriesInfo();

      presenter.categories = categories.map((category) => ({
        label: category.displayName,
        value: category.id,
      }));

      presenter.forceUpdateForm();
    },

    get isGeneralInfo(): boolean {
      return presenter.step === CreateChannelStep.GeneralInfo;
    },

    get isPrivacy(): boolean {
      return presenter.step === CreateChannelStep.Privacy;
    },

    get isShowGeneralInfo(): boolean {
      return source.isEditMode || presenter.isGeneralInfo;
    },

    get isShowPrivacy(): boolean {
      return source.isEditMode || presenter.isPrivacy;
    },

    get isShowPrevStep(): boolean {
      return !source.isEditMode && presenter.isPrivacy;
    },

    get isShowSubmit(): boolean {
      return source.isEditMode || presenter.isPrivacy;
    },

    onShowGeneralInfo(): void {
      presenter.step = CreateChannelStep.GeneralInfo;
    },

    onShowPrivacy(): void {
      presenter.step = CreateChannelStep.Privacy;
    },

    /* force rerender fields, when data changes: when categories loaded */
    forceUpdateForm(): void {
      presenter.initialValues = { ...presenter.initialValues };
    },
  }));

  useEffect(() => {
    presenter.loadCategories();
  }, []);

  useEffect(() => {
    presenter.initialValues = initialValues;
  }, [initialValues]);

  return presenter;
}
