/**
 * Rest API
 * Documentation Rest API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * system.call
 */
export interface SystemCallResponseData { 
    payloadType: string;
    initiator: string;
    initiatorNickName?: string;
    channelId: string;
    callType: SystemCallResponseDataCallTypeEnum;
    startTs: string;
    finishTs?: string;
    duration?: string;
    finishReason?: SystemCallResponseDataFinishReasonEnum;
    finishInitiator?: string;
    finishInitiatorNickname?: string;
}
export enum SystemCallResponseDataCallTypeEnum {
    P2P = 'P2P',
    Group = 'GROUP',
    Conference = 'CONFERENCE',
    Stream = 'STREAM'
};
export enum SystemCallResponseDataFinishReasonEnum {
    Unanswered = 'UNANSWERED',
    Declined = 'DECLINED',
    Finished = 'FINISHED'
};



