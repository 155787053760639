import format from './format';

export default (byBrowserLocale = false) => {
  const date = new Date();
  const time = byBrowserLocale
    ? date.toLocaleTimeString()
    : format(date, { hour: '2-digit', minute: '2-digit' });

  return /((AM)|(PM))$/i.test(time);
};
