import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import Item from './Item';
import styles from './styles.m.css';

export const GroupByDateItems = observer(
  ({ listClassName, data, title, renderItem, sharedDataType }) => {
    return (
      <>
        <p className={styles.title}>{title}</p>
        <ul className={classNames(styles.list, listClassName)}>
          {data.map((item) => (
            <Item
              key={`${item.id}_${item.messageId}`}
              data={item}
              sharedDataType={sharedDataType}
            >
              {renderItem(item)}
            </Item>
          ))}
        </ul>
      </>
    );
  }
);
