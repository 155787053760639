import { computed } from 'mobx';

import { CallType, CallProviderType } from 'APP/model/call/callModel.types';
import { now } from 'APP/packages/date/helpers/now';
import Entities from 'STORE';
import { Group } from 'STORE/Groups/Group';

interface IBackgroundCallConstructorParams {
  groupId: string;
  channelId: string;
  callType: CallType;
  callProvider: CallProviderType;
}

export class BackgroundCall {
  groupId: string;
  channelId: string;
  callType: CallType;
  startTs: number;
  callProvider: CallProviderType;

  constructor({ groupId, channelId, callType, callProvider }: IBackgroundCallConstructorParams) {
    this.groupId = groupId;
    this.channelId = channelId;
    this.callType = callType;
    this.startTs = now().valueOf();
    this.callProvider = callProvider;
  }

  @computed
  get group(): Group | null {
    return Entities.GroupsStore.getGroupById(this.groupId);
  }

  @computed
  get isGroup(): boolean {
    return !!this.group?.isChatGroup;
  }
}
