import Tasks from 'APP/Tasks';

import { GroupsEvent } from '../GroupsEvent';

export class PrivacySettingsUpdate extends GroupsEvent {
  process() {
    const payload = this.eventPayload;
    Tasks.userSettings.updatePrivacySettingsLocally(payload);
  }
}
