import React from 'react';

import { Contact as ContactPayload } from 'APP/main/ChatView/Messages/Message/Payload/Contact';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';

import styles from './styles.m.scss';

const Contact = ({ message }) => {
  return (
    <div className={styles.container}>
      <ContactPayload
        message={message}
        className={styles.contact}
        avatarType={AvatarTypes.Round_5}
      />
    </div>
  );
};

export default Contact;
