import { useLocalStore, useAsObservableSource } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { ALERT_TYPES } from 'APP/constants/app';
import Entities from 'APP/store';
import { ScheduledCallCreatePopup } from 'MAIN/PopupManager/Popups/ScheduledCalls/ScheduledCallCreatePopup/ScheduledCallCreatePopup';
import { BackgroundCall } from 'STORE/BackgroundCalls/BackgroundCall/BackgroundCall';
import { Channel } from 'STORE/Groups/Channel';
import { Group } from 'STORE/Groups/Group';

interface IDefaultHeaderActionsPresenter {
  call: BackgroundCall | null;
  isAvailableConference: boolean;
  isAvailableJoinCall: boolean;
  isShowSearch: boolean;
  isArticleDrafts: boolean;
  isGroupJoinButton: boolean;
  isCreateCallButton: boolean;
  isAvailableStartStream: boolean;
  isStreamStarting: boolean;
  hasDonateButton: boolean;
  audioCall(): void;
  conferenceCall(): void;
  videoCall(): void;
  joinToCall(): void;
  startStream(): Promise<void>;
  showSearch(): void;
  onToggleMute(): void;
  scheduleCallEvent(): void;
}

export const useDefaultHeaderActionsPresenter = (group: Group): IDefaultHeaderActionsPresenter => {
  const source = useAsObservableSource({ group });

  const presenter = useLocalStore<IDefaultHeaderActionsPresenter>(() => ({
    isStreamStarting: false,

    get call(): BackgroundCall | null {
      return Entities.BackgroundCalls.getCallByGroupId(source.group.id);
    },

    get isAvailableConference(): boolean {
      return !source.group.isP2P;
    },

    get isAvailableJoinCall(): boolean {
      const activeCall = Entities.Calls.getCallByGroupId(source.group.id);
      if (activeCall) {
        return false;
      }

      return Boolean(source.group.backgroundCall);
    },

    get isShowSearch(): boolean {
      return Entities.ChatStore.isShowSearch;
    },

    get isArticleDrafts(): boolean {
      return Boolean(source.group.isChannel && source.group.withMeInAdmins);
    },

    get isGroupJoinButton(): boolean {
      return source.group.withoutMe;
    },

    get isCreateCallButton(): boolean {
      return (
        !source.group.isChannel &&
        !source.group.isBot &&
        !source.group.isSavedMessages &&
        !source.group.activeCall
      );
    },

    get isAvailableStartStream(): boolean {
      if (
        !source.group.isChannel ||
        !source.group.withMeInAdmins ||
        Entities.BackgroundStreams.getByGroupId(source.group.id)
      ) {
        return false;
      }

      return true;
    },

    get hasDonateButton(): boolean {
      return Boolean(
        source.group instanceof Channel && !source.group.withMeInAdmins && source.group.donationUrl
      );
    },

    audioCall(): void {
      Tasks.calls.startAudioCall({ groupId: source.group.id });
    },

    conferenceCall(): void {
      Tasks.calls.startConferenceCall({ groupId: source.group.id });
    },

    videoCall(): void {
      Tasks.calls.startVideoCall({ groupId: source.group.id });
    },

    joinToCall(): void {
      if (!presenter.call) {
        return;
      }

      Tasks.calls.joinToCall({
        groupId: source.group.id,
        channelId: presenter.call.channelId,
        withAudio: !!source.group.isP2P,
        withVideo: false,
        providerType: presenter.call.callProvider,
        callType: presenter.call.callType,
      });
    },

    async startStream(): Promise<void> {
      if (presenter.isStreamStarting) {
        return;
      }

      presenter.isStreamStarting = true;
      await Tasks.streaming.openCreateStreamPopup(source.group.id);
      presenter.isStreamStarting = false;
    },

    scheduleCallEvent(): void {
      if (!source.group.canInitCall) {
        Tasks.app.addAlert({
          type: ALERT_TYPES.NO_PERMISSIONS_TO_PERFORM_ACTION,
        });
        return;
      }
      Tasks.app.openPopup(ScheduledCallCreatePopup, { params: { groupId: source.group.id } });
    },

    showSearch(): void {
      Entities.ChatStore.showSearch();
    },

    onToggleMute(): void {
      source.group.muted ? source.group.unmute() : source.group.mute();
    },
  }));

  return presenter;
};
