import { format } from 'bytes';
import { useLocalStore, useAsObservableSource } from 'mobx-react';

import { IMediaMessagePayload } from '../MediaMessagePopup.types';
import { IItemMediaTypes, TItemMediaPayloadType } from './ItemMedia.types';

export interface IItemMediaPresenter {
  name: string;
  size: string;
  canBeRemoved: boolean;
  payload: IMediaMessagePayload;
  payloadType: TItemMediaPayloadType;
  previewUrl: string | undefined;
  remove(): void;
}

export const useItemMediaPresenter = ({
  payload,
  onRemove,
}: IItemMediaTypes): IItemMediaPresenter => {
  const source = useAsObservableSource({ payload });

  const presenter = useLocalStore<IItemMediaPresenter>(() => ({
    get payload(): IMediaMessagePayload {
      return source.payload;
    },

    get previewUrl(): string | undefined {
      return presenter.payload.data.localPreview || presenter.payload.data.previewUrl;
    },

    get size(): string {
      return format(presenter.payload.data.file?.size || presenter.payload.data.fileSize || 0, {
        unitSeparator: ' ',
      });
    },

    get payloadType(): TItemMediaPayloadType {
      return presenter.payload.type;
    },

    get name(): string {
      return presenter.payload.data.fileName || presenter.payload.data.fileName || '';
    },

    get canBeRemoved(): boolean {
      return Boolean(onRemove);
    },

    remove(): void {
      onRemove(presenter.payload);
    },
  }));

  return presenter;
};
