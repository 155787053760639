import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { FC, RefObject, useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { subscribeMessagesChanges } from 'APP/packages/bus/adapters/messaging/changes';
import { useTranslation } from 'APP/packages/translations';
import Entities from 'APP/store';
import Profile from 'MAIN/ChatView/Profile';
import { IUserProfileInitiatorSource } from 'MAIN/Profile/UserProfile/UserProfile.presenter';
import { Articles } from 'MAIN/SharedData/Articles/Articles';
import { Audio } from 'MAIN/SharedData/Audio/Audio';
import { SharedDataFiles } from 'MAIN/SharedData/Files/Files';
import { Links } from 'MAIN/SharedData/Links/Links';
import { Media } from 'MAIN/SharedData/Media/Media';
import { Voices } from 'MAIN/SharedData/Voices/Voices';
import { User } from 'STORE/Users/User/User';

enum ProfileView {
  Profile = 'profile',
  Media = 'media',
  Links = 'links',
  Files = 'files',
  Audio = 'audio',
  Voices = 'voices',
  Articles = 'articles',
}

const COMPONENTS: Record<ProfileView, FC> = {
  [ProfileView.Profile]: Profile as FC,
  [ProfileView.Media]: Media,
  [ProfileView.Links]: Links,
  [ProfileView.Files]: SharedDataFiles,
  [ProfileView.Audio]: Audio,
  [ProfileView.Voices]: Voices,
  [ProfileView.Articles]: Articles,
};

interface IProfileViewParams {
  userId: string;
  groupId: string;
  initiatorSource?: IUserProfileInitiatorSource;
  scrollNodeRef?: RefObject<HTMLElement>;
  onClose(): void;
}

interface IProfileViewActions {
  openMedia(): void;
  openLinks(): void;
  openFiles(): void;
  openAudioFiles(): void;
  openVoices(): void;
  openArticles(): void;
}

interface IProfileView extends IProfileViewActions {
  activeView: ProfileView;
  title: string;
  actions: IProfileViewActions;
  user: User | null;
  hasBackButton: boolean;
  isProfileOpened: boolean;
  isSharedDataOpened: boolean;
  Component: FC;
  props: any; // TODO: add typings
  goBack(): void;
  close(): void;
}

export function useProfileView({
  userId,
  groupId,
  initiatorSource = {},
  onClose,
  scrollNodeRef,
}: IProfileViewParams): IProfileView {
  const { t } = useTranslation();
  const TITLES: Record<ProfileView, string> = {
    [ProfileView.Profile]: t('profile'),
    [ProfileView.Media]: t('common_media'),
    [ProfileView.Links]: t('common_links'),
    [ProfileView.Files]: t('common_files'),
    [ProfileView.Audio]: t('common_audio_files'),
    [ProfileView.Voices]: t('common_voice'),
    [ProfileView.Articles]: t('common_articles'),
  };

  const source = useAsObservableSource({ userId, groupId, initiatorSource });

  const presenter = useLocalStore<IProfileView>(() => ({
    activeView: ProfileView.Profile,

    get title(): string {
      const group = Entities.GroupsStore.getGroupById(groupId);

      if (group?.isSavedMessages && presenter.activeView === ProfileView.Profile) {
        return t('profile_saved_attachments');
      }

      return TITLES[presenter.activeView];
    },

    openMedia(): void {
      presenter.activeView = ProfileView.Media;
    },

    openLinks(): void {
      presenter.activeView = ProfileView.Links;
    },

    openFiles(): void {
      presenter.activeView = ProfileView.Files;
    },

    openAudioFiles(): void {
      presenter.activeView = ProfileView.Audio;
    },

    openVoices(): void {
      presenter.activeView = ProfileView.Voices;
    },

    openArticles(): void {
      presenter.activeView = ProfileView.Articles;
    },

    get user(): User | null {
      if (source.userId) {
        return Entities.UsersStore.getUserById(source.userId);
      }
      return null;
    },

    get actions(): IProfileViewActions {
      return {
        openMedia: presenter.openMedia,
        openFiles: presenter.openFiles,
        openLinks: presenter.openLinks,
        openAudioFiles: presenter.openAudioFiles,
        openVoices: presenter.openVoices,
        openArticles: presenter.openArticles,
      };
    },

    get hasBackButton(): boolean {
      return presenter.activeView !== ProfileView.Profile;
    },

    goBack(): void {
      presenter.activeView = ProfileView.Profile;
    },

    close(): void {
      onClose();
      presenter.goBack();
    },

    get Component(): FC {
      return COMPONENTS[presenter.activeView];
    },

    // TODO: add typings
    get props(): any {
      switch (presenter.activeView) {
        case ProfileView.Profile:
          return {
            userId: source.userId,
            groupId: source.groupId,
            initiatorSource: source.initiatorSource,
            actions: presenter.actions,
          };
        default:
          return {
            groupId: source.groupId,
          };
      }
    },

    get isProfileOpened(): boolean {
      return presenter.activeView === ProfileView.Profile;
    },

    get isSharedDataOpened(): boolean {
      return !presenter.isProfileOpened;
    },
  }));

  useEffect(() => {
    const unsubscribeMessagesChanges = subscribeMessagesChanges(groupId, () => {
      Tasks.media.updateSharedDataCount({ groupId });
    });
    Tasks.media.updateSharedDataCount({ groupId });

    return () => {
      unsubscribeMessagesChanges();
    };
  }, []);

  useEffect(() => {
    if (scrollNodeRef?.current) {
      scrollNodeRef.current.scrollTo(0, 0);
    }
  }, [presenter.activeView]);

  useEffect(() => {
    if (userId) {
      Tasks.users.updateUsersDisplayData([userId]);
    }
  }, [userId]);

  return presenter;
}
