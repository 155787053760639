import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { RouterLink } from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import Entities from 'STORE';
import { Space } from 'STORE/Spaces/Space';

export interface ISpacePrimaryMarketPresenter {
  space: Space | null;
  primaryMarketUrl: string | null;
}

export function useSpacePrimaryMarketPresenter(spaceId?: string): ISpacePrimaryMarketPresenter {
  const navigateTo = useNavigateTo();

  const presenter = useLocalStore<ISpacePrimaryMarketPresenter>(() => ({
    get space(): Space | null {
      if (spaceId) {
        return Entities.spacesStore.getById(spaceId) || null;
      }
      return null;
    },

    get primaryMarketUrl(): string | null {
      const url = presenter.space?.primaryMarketUrl;
      if (url && Tasks.market.isMarketUrl(url)) {
        return url;
      }
      return null;
    },
  }));

  useEffect(() => {
    if (!presenter.primaryMarketUrl) {
      navigateTo({ to: RouterLink.space.replace(':id', spaceId || '') });
    }
  }, [presenter.primaryMarketUrl]);
  return presenter;
}
