import { spaceModel } from 'APP/model/model';
import { IBanUsersInSpaceRequest } from 'APP/model/space/spaceModel.types';
import { spaceAnalytics } from 'APP/packages/analytics/analytics';

export async function banSpaceMembers(data: IBanUsersInSpaceRequest): Promise<boolean> {
  try {
    const response = await spaceModel.banUsers(data);

    data.userIds.forEach((userId) => spaceAnalytics.trackSpaceMemberBlocked(data.spaceId, userId));

    return response.result;
  } catch {
    return false;
  }
}
