import { localStorageInstance } from '../../instances/localStorageInstance';
import { ILocalStorageCountryCodes } from './types';

const KEY_NAME = 'countries_phone_codes';

export const set = (data: ILocalStorageCountryCodes): void => {
  return localStorageInstance.setItem(KEY_NAME, JSON.stringify(data));
};

export const get = (): ILocalStorageCountryCodes | null => {
  const data = localStorageInstance.getItem(KEY_NAME);

  if (data) {
    return JSON.parse(data);
  }

  return null;
};

export const remove = (): void => {
  return localStorageInstance.removeItem(KEY_NAME);
};
