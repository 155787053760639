import logger from 'APP/packages/logger';

import {
  IAnalyticsAdapter,
  IAnalyticsConfig,
  IAnalyticsEvent,
  IAnalyticsOptions,
  IAnalyticsUserConfig,
} from './AnalyticsClient.types';
import { AmplitudeAdapter } from './adapters/AmplitudeAdapter';
import { GtmAdapter } from './adapters/GtmAdapter';

class AnalyticsClient {
  private adapters: IAnalyticsAdapter[] = [];
  private trackOptions: IAnalyticsOptions = {};

  public initialize(config: IAnalyticsConfig): void {
    this.adapters.push(new AmplitudeAdapter(config.amplitudeApiKey));
    this.adapters.push(new GtmAdapter(config.gtmId));
  }

  public setUserConfig(user: IAnalyticsUserConfig): void {
    this.adapters.forEach((adapter) => adapter.setUserConfig(user));
    this.trackOptions = {
      userId: user.id,
    };
  }

  public track(event: IAnalyticsEvent): void {
    this.adapters.forEach((adapter) => adapter.track(event, this.trackOptions));
    logger.get('Analytics').log('track', event);
  }
}

export const analyticsClient = new AnalyticsClient();
