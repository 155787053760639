import api from 'APP/packages/api';
import dateService from 'APP/packages/date';

export default async (groupId, msg, callback) => {
  try {
    const now = dateService.now().getTime();

    if (msg.payload.upload) {
      await msg.payload.upload();
    }

    await api.messaging.saveScheduledMessage({
      ...msg.toJSON(),
      groupId,
      clientTime: now.toString(),
      delay: (msg.clientTime - now).toString(),
    });

    callback(true);
  } catch (error) {
    console.error(error);
    callback(false);
  }
};
