import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { AudioPlayer } from 'UIKIT/Media/AudioPlayer/AudioPlayer';

import { IMiniPlayerProps } from '../MiniPlayer.types';
import { useMiniAudioPlayerPresenter } from './MiniAudioPlayer.presenter';

export const MiniAudioPlayer: FC<IMiniPlayerProps> = observer((props) => {
  const presenter = useMiniAudioPlayerPresenter(props);

  return (
    <AudioPlayer
      autoPlay
      className={props.className}
      track={props.track || undefined}
      playList={props.playList}
      isPlaying={props.isPlaying}
      isShowPlayList={props.isShowPlayList}
      config={props.config}
      progress={props.progress}
      progressPercentage={props.progressPercentage}
      onConfigUpdate={props.onConfigUpdate}
      onChangeTrack={props.onChangeTrack}
      onProgress={props.onProgressChange}
      onTogglePlay={presenter.onTogglePlay}
      onTitleClick={props.onTitleClick}
      onClose={props.onClose}
    />
  );
});
