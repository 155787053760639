import Entities from 'STORE';

import { GroupsEvent } from '../GroupsEvent';

export class UserDisplayDataUpdate extends GroupsEvent {
  process() {
    const payload = this.eventPayload;
    const user = Entities.UsersStore.getUserById(payload.userId);
    const isPrivacyDataShouldBeUpdated =
      Number(payload.privacyUpdateTs || 0) >= Number(user.privacyStatus?.updateTs || 0);

    if (user) {
      user.update({
        phone: isPrivacyDataShouldBeUpdated ? payload.phone : user.phone,
        avatarUrl: isPrivacyDataShouldBeUpdated ? payload.avatarUrl : user.avatarUrl,
        nickName: payload.nickName,
        userName: payload.userName,
        verified: payload.verified,
        bio: payload.bio,
        usernameBadge: payload.usernameBadge,
        privacyStatus: {
          addToGroup: payload.addToGroup,
          addToPublic: payload.addToPublic,
          callMe: payload.callMe,
          searchMe: payload.searchMe,
          sendVoiceMessage: payload.sendVoiceMessage,
          showLastSeen: payload.showLastSeen,
          updateTs: payload.privacyUpdateTs,
        },
      });
    }
  }
}
