import { action, computed, observable, ObservableMap, values } from 'mobx';

import Tasks from 'APP/Tasks';
import { GroupType, IGroupData, IPersonalizedGroup } from 'APP/model/group/groupModel.types';
import { langService } from 'MODULE/i18n/service';
import type { Root } from 'STORE';
import { Group } from 'STORE/Groups/Group';
import { IScheduleData } from 'STORE/Groups/Schedule/types';
import { IStrangeServerGroup } from 'STORE/Groups/types';
import { getVisibleGroups } from 'UTILS/getVisibleGroups';

import { Channel } from './Channel';
import { ChatGroup } from './ChatGroup';
import { My } from './My';
import { P2P } from './P2P';
import { SavedMessages } from './SavedMessages';
import { SavedMessagesFake } from './SavedMessagesFake';
import { Schedule } from './Schedule';
import { Thread } from './Thread';

export const groupClassesMap: Record<string, typeof Group> = {
  [GroupType.My]: My,
  [GroupType.P2P]: P2P,
  [GroupType.Open]: ChatGroup,
  [GroupType.Closed]: ChatGroup,
  [GroupType.ChannelOpen]: Channel,
  [GroupType.ChannelClosed]: Channel,
  [GroupType.MySavedMessages]: SavedMessages,
  [GroupType.SavedMessagesFake]: SavedMessagesFake,
  [GroupType.Thread]: Thread,
};

const getNewMessagesCountInNotMutedGroups = (groups: Group[]): number => {
  return groups.reduce((result, group) => {
    if (group.muted) {
      return result;
    } else if (group.numberOfUnread > 0) {
      return result + group.numberOfUnread;
    } else if (group.unread) {
      return ++result;
    } else {
      return result;
    }
  }, 0);
};

const hasUnreadMessagesInNotMutedGroups = (groups: Group[]): boolean => {
  return groups.some((group) => {
    return (group.numberOfUnread > 0 || group.unread) && !group.muted;
  });
};

export class Groups {
  @observable data = new ObservableMap<Group['id'], Group>();
  @observable isLoading = true;
  @observable activeGroupId: string | null = null;

  root: Root;

  constructor(root: Root) {
    this.root = root;
  }

  getGroupById(id: string): Group | null {
    return this.data.get(id) || null;
  }

  getGroupsBySpaceId(spaceId: string): Group[] {
    return values(this.data).filter(
      (group) => !group.isFake && !(group as Thread).isThread && group.spaceId === spaceId
    );
  }

  findGroupByUserId(id: string): Group | null {
    let p2pGroupWithUser: Group | null = null;
    this.data.forEach((group) => {
      if (group.opponentId !== id) {
        return;
      }
      p2pGroupWithUser = group;
    });
    return p2pGroupWithUser;
  }

  @computed
  get activeGroup(): Group | null {
    const group = this.getGroupById(this.activeGroupId as string);

    if (!group || group.isMy) {
      return null;
    }

    return group;
  }

  @action
  setActiveGroup(groupId: string | null): void {
    this.activeGroupId = groupId;
  }

  @action
  setLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
  }

  merge(groups: IPersonalizedGroup[] | IStrangeServerGroup[]): void {
    if (!groups || !groups.length) {
      return;
    }

    const mappedGroups = this.mapGroups(groups);

    this.mergeExistingGroups(mappedGroups);
    this.addNewGroups(mappedGroups as IGroupData[]);
  }

  mapGroups(
    groups: IPersonalizedGroup[] | IStrangeServerGroup[]
  ): (IStrangeServerGroup | IGroupData)[] {
    return groups.map(this.mapStrangeServerGroupToGroup);
  }

  mapStrangeServerGroupToGroup(
    group: IGroupData | IPersonalizedGroup | IStrangeServerGroup
  ): IStrangeServerGroup | IGroupData {
    if (!(group as Pick<IPersonalizedGroup, 'groupResponse'>).groupResponse) {
      return group as IStrangeServerGroup;
    }

    const personalizedGroup = group as IPersonalizedGroup;

    return {
      ...personalizedGroup.groupResponse,
      opponent: personalizedGroup.opponent,
      botOpponent: personalizedGroup.botOpponent,
      muted: personalizedGroup.muted,
      pinOrder: personalizedGroup.pinOrder,
      unread: personalizedGroup.unread,
      userGroupRole: personalizedGroup.userGroupRole,
      draftInput: personalizedGroup.draftInput,
      showContent: personalizedGroup.showContent,
    };
  }

  @action
  mergeExistingGroups(groups: (IGroupData | IStrangeServerGroup)[]): void {
    groups.forEach((group) => {
      if (!this.data.has(group.id)) {
        return;
      }
      const mergedGroup = this.data.get(group.id);

      if (mergedGroup) {
        mergedGroup.merge(group);
      }
    });
  }

  @action
  addNewGroups(groups: (IGroupData | IScheduleData)[]): void {
    const groupsForAdd = groups.reduce<[[IGroupData['id'], Group]?]>((acc, group) => {
      if (this.data.has(group.id)) {
        return acc;
      }

      if (group.type === GroupType.Schedule) {
        acc.push([group.id, new Schedule(group as IScheduleData, this, this.root)]);
      } else {
        const GroupClass = groupClassesMap[group.type];
        if (GroupClass) {
          acc.push([group.id, new GroupClass(group, this, this.root)]);
        }
      }

      return acc;
    }, []);

    this.data.merge(groupsForAdd);
  }

  @computed
  get rawGroups(): readonly Group[] {
    return values(this.data);
  }

  // list of real group p2p, chat, open/closed channel
  // Todo rename to groupsWithMe
  @computed
  get groups(): Group[] {
    return values(this.data).filter(
      (group) => !group.isFake && !group.withoutMe && !(group as Thread).isThread
    );
  }

  // Todo rename to chatGroupsWithMe
  @computed
  get chatGroups(): Group[] {
    return this.groups.filter(
      (group) => group.isP2P || group.isChatGroup || group.isSavedMessages || group.isBot
    );
  }

  // Todo rename to channelsWithMe
  @computed
  get channels(): Group[] {
    return this.groups.filter((group) => group.isChannel);
  }

  @computed
  get hasNewGroupMessagesWithoutMute(): boolean {
    return hasUnreadMessagesInNotMutedGroups(getVisibleGroups(this.chatGroups));
  }

  @computed
  get newGroupMessagesWithoutMuteCount(): number {
    return getNewMessagesCountInNotMutedGroups(this.chatGroups);
  }

  @computed
  get hasNewChannelMessagesWithoutMute(): boolean {
    return hasUnreadMessagesInNotMutedGroups(getVisibleGroups(this.channels));
  }

  @computed
  get newChannelMessagesWithoutMuteCount(): number {
    return getNewMessagesCountInNotMutedGroups(this.channels);
  }

  @computed
  get pinnedGroups(): Group[] {
    return this.groups.filter((group) => group.isPinned);
  }

  @computed
  get pinnedChatGroups(): Group[] {
    return this.pinnedGroups.filter((group) => !group.isChannel);
  }

  @computed
  get pinnedChannels(): Group[] {
    return this.pinnedGroups.filter((group) => group.isChannel);
  }

  @action
  reset(): void {
    this.data.clear();
    this.isLoading = true;
    this.activeGroupId = null;
  }

  @action
  delete(groupId: string): void {
    const group = this.getGroupById(groupId);
    if (group) {
      group.destroy();
    }
    this.data.delete(groupId);
  }

  updatePinnedGroups(groups: ConcatArray<number>): void {
    const pinnedLocally = this.pinnedGroups.map((group) => parseInt(group.id, 10));

    const mergePinnedGroups = pinnedLocally.concat(groups);

    Tasks.group.loadGroupsByIds(mergePinnedGroups);
  }

  @computed
  get savedMessagesGroup(): Group | null {
    return this.groups.find((group) => group.isSavedMessages) || null;
  }

  @computed
  get hasSavedMessagesGroup(): boolean {
    return Boolean(this.savedMessagesGroup);
  }

  // TODO seems to be this code is out of date and not use anymore. Should be checked
  get fakeSavedMessagesGroup(): SavedMessagesFake {
    return new SavedMessagesFake(
      {
        displayData: {
          name: langService.i18n.lang.saved_message_chat_tittle,
          description: langService.i18n.lang.saved_messages_chat_description_web,
          type: GroupType.SavedMessagesFake,
        },
      } as any,
      this,
      this.root
    );
  }
}
