import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { useInAppViewPresenter } from './InAppView.presenter';
import styles from './InAppView.styles.m.css';
import { InAppViewContent } from './InAppViewContent/InAppViewContent';

interface MarketplacePopupProps {
  url?: string;
  isLocal?: boolean;
  goBack(): void;
}

export const InAppView: FC<MarketplacePopupProps> = observer((props) => {
  const { url, isLocal, goBack } = props;
  const presenter = useInAppViewPresenter({ url, isLocal });

  if (presenter.isOpen === undefined) {
    return null;
  }

  return (
    <div
      className={classNames(styles.container, {
        [styles.hidden]: !presenter.isOpen,
      })}
    >
      <InAppViewContent
        isOpen={presenter.isOpen}
        key={presenter.url}
        url={presenter.url}
        hasError={presenter.hasError}
        onLoad={presenter.onLoad}
        isLoading={presenter.isLoading}
        onReload={presenter.loadMarket}
        goBack={goBack}
      />
    </div>
  );
});
