import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { useTranslation } from 'APP/packages/translations';
import EmailIcon from 'ICONS/ic-email.svg';
import CopyIcon from 'ICONS/ic-s-copy.svg';
import { User } from 'STORE/Users/User/User';
import { IconButton } from 'UIKIT/IconButton';

import { MetaBlock } from '../MetaBlock/MetaBlock';
import { useProfileNicknamePresenter } from './ProfileNickname.presenter';
import styles from './ProfileNickname.styles.m.css';

interface IProfileNicknameProps {
  className?: string;
  user: User;
}

export const ProfileNickname: FC<IProfileNicknameProps> = observer(({ className, user }) => {
  const { t } = useTranslation();
  const { copyToClipboard } = useProfileNicknamePresenter(user);

  return (
    <MetaBlock
      className={className}
      label={t('profile_nickname_hint')}
      Icon={EmailIcon}
      Actions={
        <IconButton
          Icon={CopyIcon}
          onClick={copyToClipboard}
        />
      }
    >
      <button
        className={styles.link}
        onClick={copyToClipboard}
        data-test="nickname"
      >
        {user.displayNickname}
      </button>
    </MetaBlock>
  );
});
