import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { openPopup } from 'APP/Tasks/app/popup/popup';
import { PayloadType } from 'APP/model/message/messageModel.types';
import { useTranslation } from 'APP/packages/translations';
import { IMediaGalleryState } from 'APP/types/mediaGallery';
import { ForwardMessagePopup } from 'MAIN/PopupManager/Popups/ForwardMessagePopup/ForwardMessagePopup';
import Entities from 'STORE';
import { Popup } from 'STORE/App/Popups/Popup/Popup';
import { Group } from 'STORE/Groups/Group';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';
import { User } from 'STORE/Users/User/User';

import { IMediaGalleryFile, TSetSidebarVisabilityHandler } from '../MediaGallery.types';

interface IMediaGalleryHeaderPresenterParams {
  file: IMediaGalleryFile;
  canDelete: boolean;
  isShowSidebar: boolean;
  onSetSidebarVisibility: TSetSidebarVisabilityHandler;
  galleryState: IMediaGalleryState;
}

interface IMediaGalleryHeaderPresenter {
  childPopup: Popup | null;
  group: Group | null;
  message: ChatMessage;
  sender: User;
  canBeDelete: boolean;
  canBeForward: boolean;
  onToggleSidebar(): void;
  onDelete(): Promise<void>;
  onForward(): void;
}

export const useMediaGalleryHeaderPresenter = (
  data: IMediaGalleryHeaderPresenterParams
): IMediaGalleryHeaderPresenter => {
  const { file, canDelete, isShowSidebar, onSetSidebarVisibility, galleryState } = data;
  const { t } = useTranslation();

  const source = useAsObservableSource({
    file,
    canDelete,
    isShowSidebar,
    onSetSidebarVisibility,
    galleryState,
  });

  const presenter = useLocalStore<IMediaGalleryHeaderPresenter>(() => ({
    childPopup: null,

    get group(): Group | null {
      if (!source.galleryState.groupId) {
        return null;
      }

      return Entities.GroupsStore.getGroupById(source.galleryState.groupId);
    },

    get message(): ChatMessage {
      return source.file.message;
    },

    get sender(): User {
      return presenter.message.senderUser;
    },

    get canBeDelete(): boolean {
      return (
        source.canDelete &&
        presenter.message.canBeDelete &&
        ![PayloadType.Album, PayloadType.Article].includes(presenter.message.payload.payloadType)
      );
    },

    get canBeForward(): boolean {
      return presenter.message.canBeForward;
    },

    onToggleSidebar(): void {
      source.onSetSidebarVisibility(!source.isShowSidebar);
    },

    async onDelete(): Promise<void> {
      const result = await Tasks.app.showConfirm({
        text: t('chat_gallery_confirm_delete'),
        primaryText: t('common_ok'),
        secondaryText: t('common_cancel'),
      });

      if (!result) {
        return;
      }

      const deleteMessage = presenter.message;
      const otherFile = source.file.nextFile || source.file.prevFile;

      if (otherFile) {
        Tasks.group.updateMediaGallery({
          groupId: otherFile.groupId,
          messageId: otherFile.messageId,
          mediaId: otherFile.id,
        });
      }

      const data = { groupId: deleteMessage.groupId, messages: [deleteMessage] };
      if (deleteMessage.isScheduled) {
        Tasks.messaging.deleteScheduledMessages(data);
      } else {
        Tasks.messaging.deleteMessages(data);
      }

      if (!otherFile) {
        Tasks.group.closeMediaGallery();
      }
    },

    onForward(): void {
      presenter.childPopup = openPopup(ForwardMessagePopup, {
        params: { groupId: presenter.message.groupId, messageIds: [presenter.message.id] },
      });
    },
  }));

  useEffect(() => {
    return (): void => presenter.childPopup?.close();
  }, []);

  return presenter;
};
