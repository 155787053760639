/**
 * Rest API
 * Documentation Rest API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface GetCatalogRequest { 
    catalogType: GetCatalogRequestCatalogTypeEnum;
}
export enum GetCatalogRequestCatalogTypeEnum {
    All = 'all',
    Channel = 'channel',
    Space = 'space',
    Bot = 'bot'
};



