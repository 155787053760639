import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Tasks from 'APP/Tasks';
import { PERMISSIONS_CUSTOM_TRANSLATIONS } from 'APP/constants/calls';
import { PermissionsPersonalizedValue } from 'APP/model/callPermissions/callPermissionsModel.constants';
import { useTranslation } from 'APP/packages/translations';
import routerConst from 'APP/router/constants';
import useClearGetParameters from 'APP/router/hooks/useClearGetParameters';
import useGetParameter from 'APP/router/hooks/useGetParameter';
import Entities from 'STORE';

export const usePresenter = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const clearGetParameters = useClearGetParameters();
  const memberPermissionsId = useGetParameter(routerConst.params.memberPermissionsId);
  const permissionType = useGetParameter(routerConst.params.permissionType);

  const presenter = useLocalStore(() => ({
    onClose: () => {
      clearGetParameters();
    },

    goBack: () => {
      history.goBack();
    },

    setValue: (value) => {
      if (value) {
        presenter.ongoingCall.permissions.changeEditedPermission(
          memberPermissionsId,
          permissionType,
          value
        );
      }
    },

    get ongoingCall() {
      return Entities.Calls.ongoingCall;
    },

    get isNewMemberPermissions() {
      return !presenter.ongoingCall?.permissions.customPermissions.has(memberPermissionsId);
    },

    get editedPermissions() {
      return presenter.ongoingCall?.permissions.editedPermissions?.permissions;
    },

    get permissions() {
      const personalizedPermissions = presenter.editedPermissions;

      return presenter.ongoingCall?.permissions.getMemberPersonalizedPermissions(
        personalizedPermissions
      );
    },

    get channelId() {
      return presenter.ongoingCall?.channelId;
    },

    get permissionType() {
      return permissionType;
    },

    get permissionValues() {
      return Object.values(PermissionsPersonalizedValue).map((item) => ({
        key: item,
        title: t(PERMISSIONS_CUSTOM_TRANSLATIONS[item]),
        isActive: item === presenter.permissions[permissionType],
        onChange: () => presenter.setValue(item),
      }));
    },
  }));

  useEffect(() => {
    if (!presenter.ongoingCall || !presenter.ongoingCall?.isStarted) {
      presenter.onClose();
    }
  }, [presenter.ongoingCall?.isStarted]);

  useEffect(() => {
    return () => {
      if (!presenter.isNewMemberPermissions && presenter.ongoingCall) {
        Tasks.calls.setCallPermissionsForUser({
          channelId: presenter.channelId,
          userId: memberPermissionsId,
          permissions: presenter.editedPermissions,
        });
      }
    };
  }, []);

  return presenter;
};
