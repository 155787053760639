import { v4 as uuidv4 } from 'uuid';

import { PayloadType } from 'APP/model/message/messageModel.types';

import { DEFAULT_PAYLOAD } from '../../constants';
import getPayloadsAfterSetPayload from '../payloads/getPayloadsAfterSetPayload';

export default (payloads, text) => {
  const order = payloads.length + 1;
  const payload = {
    id: uuidv4(),
    order: order,
    type: PayloadType.RichText,
    data: {
      ...DEFAULT_PAYLOAD,
      text: text,
    },
  };

  return getPayloadsAfterSetPayload(payloads, order, payload);
};
