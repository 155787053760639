import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { Reactions } from 'APP/components/Reactions/Reactions';

import { MessageSelect } from '../components/MessageSelect/MessageSelect';
import { disableQuoteAttrs } from '../presenter/constants';
import { useMessageLayoutPresenter } from './MessageLayout.presenter';
import styles from './MessageLayout.styles.m.css';
import { MessageMenu } from './MessageMenu';
import { SenderAvatar } from './SenderAvatar/SenderAvatar';

// added keys to avoid remount
export const MessageLayout = observer((props) => {
  const {
    message,
    className,
    children,
    containerClassName,
    messageViewPageType,
    isView,
    hasActions = true,
  } = props;

  const presenter = useMessageLayoutPresenter(message, hasActions);

  const menuMessage =
    !presenter.hasSelectedMessages && hasActions ? (
      <MessageMenu
        key="menu"
        message={message}
        messageViewPageType={messageViewPageType}
      />
    ) : null;

  if (message.isLeftPosition) {
    const bubbleClassList = classNames(styles.bubble, className, {
      [styles.myBubble]: message.fromMe && !message.group.isChannel,
      [styles.squaredTopLeftCorner]: !message.isLocalFirst,
      [styles.squaredBottomLeftCorner]: !message.isLocalLast,
    });

    return (
      <div
        className={classNames(
          styles.container,
          {
            [styles.messageIsSelected]: presenter.hasSelectedMessages,
            [styles.messageIsNotSelected]:
              presenter.group.isChannel && !presenter.hasSelectedMessages,
          },
          containerClassName
        )}
      >
        {!presenter.group.isChannel && (
          <div
            className={styles.senderAvatarContainer}
            {...disableQuoteAttrs}
          >
            <SenderAvatar message={message} />
          </div>
        )}
        <div
          className={classNames(styles.bubbleWrapper, {
            [styles.channelBubble]: presenter.group.isChannel,
          })}
          {...disableQuoteAttrs}
        >
          <div className={bubbleClassList}>{children}</div>
          {message.isAvailableReactions && (
            <Reactions
              className={styles.reactions}
              message={message}
              isVisible={isView}
              theme="1"
            />
          )}
        </div>
        {menuMessage}
        <MessageSelect
          key="select"
          message={message}
          onMessageSelect={presenter.onMessageSelect}
          isChannel={presenter.group.isChannel}
          className={classNames({ [styles.hide]: !hasActions })}
        />
      </div>
    );
  }

  const bubbleClassList = classNames(styles.bubble, className, {
    [styles.myBubble]: message.fromMe,
    [styles.squaredTopRightCorner]: !message.isLocalFirst,
    [styles.squaredBottomRightCorner]: !message.isLocalLast,
  });

  return (
    <div className={classNames(styles.rightContainer, containerClassName)}>
      {menuMessage}
      <div
        className={styles.bubbleWrapper}
        {...disableQuoteAttrs}
      >
        <div className={bubbleClassList}>{children}</div>
        {message.isAvailableReactions && (
          <Reactions
            className={styles.reactions}
            message={message}
            isVisible={isView}
            theme="2"
          />
        )}
      </div>
      <MessageSelect
        key="select"
        message={message}
        onMessageSelect={presenter.onMessageSelect}
        className={classNames({ [styles.hide]: !hasActions })}
      />
    </div>
  );
});
