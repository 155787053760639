import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect, useRef } from 'react';

import {
  DELETE_CODE,
  DOWN_ARROW_KEY_CODE,
  ENTER_KEY_CODE,
  UP_ARROW_KEY_CODE,
} from 'APP/constants/keyCodes';
import { PayloadType } from 'APP/model/message/messageModel.types';
import copyPasteConverter from 'APP/packages/copyPasteConverter';

import { DEFAULT_PAYLOAD, FOCUS_NAMES } from '../../../constants';
import useTextHelper from '../../presenters/textHelper';

export const useTextPayloadPresenter = (data) => {
  const textHelper = useTextHelper();
  const textFormattingMenuInstance = useRef();

  const { parentPresenter, payload, order, ref, isPayloadCreator } = data;
  const source = useAsObservableSource({
    payload,
    order,
    isPayloadCreator,
    parentPresenter,
  });

  const presenter = useLocalStore(() => ({
    isShowFormattingMenu: false,
    value: source.payload.text,

    get isShowLinkPreview() {
      return !!source.payload?.linkPreview;
    },

    get isShowText() {
      return !presenter.isShowLinkPreview;
    },

    clear() {
      ref.current.innerHTML = '';
      presenter.value = '';
    },

    setValue: (event) => {
      // GEM-18167
      // This is strange bug. The insertText command fire the onInput event
      // as many times as many a \n characters in the text.
      if (event.target.innerHTML === presenter.value) {
        return;
      }

      presenter.value = event.target.innerHTML;
      source.parentPresenter.editPayloadByOrder(presenter.payload, source.order);

      if (source.isPayloadCreator) {
        presenter.clear();
      }

      textFormattingMenuInstance.current.update();
    },

    onKeyDown: (event) => {
      if (event.keyCode === ENTER_KEY_CODE && event.shiftKey === false) {
        event.preventDefault();
        event.stopPropagation();

        source.parentPresenter.onEnter(presenter.payload, source.order);

        if (source.isPayloadCreator) {
          presenter.clear();
          source.parentPresenter.scroll.scrollToRef(ref);
        }

        return;
      }

      if (event.keyCode === DOWN_ARROW_KEY_CODE && !source.isPayloadCreator) {
        event.preventDefault();
        source.parentPresenter.handleArrowsKeyboard(source.order + 1, 'down');
      }

      if (event.keyCode === UP_ARROW_KEY_CODE) {
        event.preventDefault();
        source.parentPresenter.handleArrowsKeyboard(source.order - 1, 'up');
      }

      if (presenter.value === '' && event.keyCode === DELETE_CODE) {
        source.parentPresenter.deletePayloadByOrder(source.order);
      }

      textFormattingMenuInstance.current.update();
    },

    onPaste: (event) => {
      event.preventDefault();

      const html = copyPasteConverter(event.clipboardData.getData('text/html'));

      if (html) {
        const items = html.split(/\n\n+/).filter((item) => item !== '');

        const payloads = items.map((item) => {
          if (item.substring(0, 3) === 'img') {
            const [, width, height, src] = item.split(',');

            return {
              comment: '',
              payloadType: PayloadType.LoadImageByLink,
              url: src,
              graphicSize: {
                width: parseInt(width),
                height: parseInt(height),
              },
            };
          }

          return {
            ...DEFAULT_PAYLOAD,
            text: item,
          };
        });

        source.parentPresenter.setPayloadsToCurrentPosition(payloads, source.order);

        return;
      }

      textHelper.onPaste(event);
      source.parentPresenter.scroll.scrollToRef(ref);
    },

    onFocus: () => {
      if (source.isPayloadCreator) {
        source.parentPresenter.setActualPosition(FOCUS_NAMES.CREATOR_PAYLOAD);
        return;
      }

      source.parentPresenter.setActualPosition(source.order);
    },

    onDrop: (event) => {
      event.preventDefault();
    },

    onClick: () => {
      textFormattingMenuInstance.current.update();
    },

    setFocusToTheEnd: () => {
      if (ref.current === document.activeElement) {
        return;
      }
      textHelper.setFocusToTheEnd(ref);
    },

    onMouseUp: () => {
      window.removeEventListener('mouseup', presenter.onMouseUp);
      textFormattingMenuInstance.current.update();
    },

    onMouseDown: () => {
      window.addEventListener('mouseup', presenter.onMouseUp);
    },

    get payload() {
      return {
        payloadType: PayloadType.RichText,
        text: presenter.value,
        keyWord: null,
        userMentions: [],
        linkPreview: source.payload?.linkPreview || null,
        linkPreviewCancelled: source.payload?.linkPreviewCancelled || false,
      };
    },
  }));

  useEffect(() => {
    if (presenter.isShowText) {
      ref.current.innerHTML = payload.text;
    }
  }, []);

  useEffect(() => {
    if (source.parentPresenter.actualFocusPosition === source.order) {
      presenter.setFocusToTheEnd();
    }
  }, [source.parentPresenter.actualFocusPosition, source.order]);

  useEffect(() => {
    source.parentPresenter.scroll.postRender();
  });

  return {
    presenter,
    textFormattingMenuInstance,
  };
};
