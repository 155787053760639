import { useLocalStore } from 'mobx-react';

import routerConst from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';

interface IPermissionRequestsButtonPresenter {
  onClick(): void;
}

export const usePermissionRequestsButtonPresenter = (): IPermissionRequestsButtonPresenter => {
  const navigateTo = useNavigateTo();
  return useLocalStore<IPermissionRequestsButtonPresenter>(() => ({
    onClick(): void {
      navigateTo({
        query: {
          [routerConst.params.popup]: routerConst.paramsEnum.popup.callPermissionRequests,
        },
      });
    },
  }));
};
