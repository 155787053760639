import { useLocalStore } from 'mobx-react';

import { storage } from 'APP/packages/storage';

interface IEmojiStickersPanelPresenter {
  activeTab: number;
  setActiveTab(tabIndex: number): void;
}

export const useEmojiStickersPanelPresenter = (): IEmojiStickersPanelPresenter => {
  const presenter = useLocalStore<IEmojiStickersPanelPresenter>(() => ({
    activeTab: storage.emojiStickersTab.get(),

    setActiveTab(activeTab: number): void {
      presenter.activeTab = activeTab;
      storage.emojiStickersTab.set(activeTab);
    },
  }));

  return presenter;
};
