import { ICallCreatedInfo, ICallInfo } from 'APP/model/call/callModel.types';
import { Call } from 'STORE/Calls/Call/Call';

interface ISetCallInfoAndPublishProps {
  callInfo: ICallCreatedInfo | ICallInfo;
  call: Call;
  isVideoMuted: boolean;
  isAudioMuted: boolean;
  withoutTimer?: boolean;
}

export const setCallInfoAndPublish = async (props: ISetCallInfoAndPublishProps): Promise<void> => {
  const { callInfo, call, isVideoMuted, isAudioMuted, withoutTimer } = props;

  call.setInitiatorId(callInfo.initiatorId);
  call.permissions.setPermissions(callInfo.permissions);
  call.setMaxBroadcasters(callInfo.maxBroadcasters);
  call.members.setMembers(callInfo.members);

  if ('callVisibleDuration' in callInfo && !withoutTimer) {
    call.setCallingTimer(callInfo.callVisibleDuration);
  }

  if ('recordingId' in callInfo) {
    call.startRecording(callInfo.recordingId!);
  }

  await call.joinAndPublish({
    channelId: callInfo.channelId,
    audioVideoToken: callInfo.audioVideoToken,
    audioVideoUid: callInfo.audioVideoUid,
    shareScreenToken: callInfo.shareScreenToken,
    shareScreenUid: callInfo.shareScreenUid,
    isVideoMuted,
    isAudioMuted,
  });
};
