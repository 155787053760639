import Tasks from 'APP/Tasks';
import { broadcastMessagesChanges } from 'APP/packages/bus/adapters/messaging/changes';

import { GroupsEvent } from '../GroupsEvent';

export class ClearHistory extends GroupsEvent {
  process() {
    Tasks.messaging.clearHistoryLocally({
      groupId: this.eventPayload.groupId,
      timestamp: this.ts,
    });

    broadcastMessagesChanges(this.groupId, { eventType: this.type });
  }
}
