import { useAsObservableSource, useLocalStore } from 'mobx-react';

import Entities from 'APP/store';

export default ({ groupId }) => {
  const source = useAsObservableSource({ groupId });
  const presenter = useLocalStore(() => ({
    get group() {
      return Entities.GroupsStore.getGroupById(source.groupId);
    },
  }));

  return presenter;
};
