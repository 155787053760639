import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import NotFound from 'APP/images/gem-mascot-not-found.svg';
import { greetingCardAnalytics } from 'APP/packages/analytics/analytics';
import { useTranslation } from 'APP/packages/translations';
import { Button } from 'UIKIT/Button/Button';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';
import { InfoBlock, InfoBlockTypes } from 'UIKIT/InfoBlock/InfoBlock';

import styles from './GreetingCard.styles.m.css';
import { GreetingCardBanner } from './GreetingCardBanner/GreetingCardBanner';

interface IGreetingCardQueryParams {
  deepLink: boolean;
  type: string;
  userDeepLink: string;
  caption: string;
  username: string;
  imageUrl: string;
}

export const GreetingCard: FC = observer(() => {
  const { t } = useTranslation();
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const queryParams = Object.fromEntries(
    urlSearchParams.entries()
  ) as unknown as IGreetingCardQueryParams;

  useEffect(() => {
    greetingCardAnalytics.trackOpenCardPage();
  }, []);

  if (!(queryParams.imageUrl && queryParams.userDeepLink)) {
    return (
      <>
        <GreetingCardBanner />
        <InfoBlock
          className={styles.notFound}
          title={t('bot_cards_something_wrong')}
          descriptions={[t('bot_cards_card_unavailable')]}
          type={InfoBlockTypes.Page}
          image={NotFound}
        />
      </>
    );
  }

  return (
    <div className={classNames(styles.container, customScrollStyles.container)}>
      <GreetingCardBanner />
      <div className={styles.innerContainer}>
        <div className={styles.card}>
          <img
            className={styles.cardImg}
            src={queryParams.imageUrl}
            alt="Greeting card"
          />
          <p className={styles.caption}>{t('bot_cards_sender', { 0: queryParams.username })}</p>
          <Button
            onClick={(): void => {
              greetingCardAnalytics.trackTapReply();
              window.open(queryParams.userDeepLink, '_blank');
            }}
            className={styles.replyBtn}
            theme="1"
            size="20"
          >
            {t('message_reply')}
          </Button>
        </div>
      </div>
    </div>
  );
});
