import { ItemsResponseItemsInner } from 'APP/packages/api/types/model/itemsResponseItemsInner';

import { request } from '../request/request';
import { BooleanResponse } from '../types/model/booleanResponse';
import { CatalogResponse } from '../types/model/catalogResponse';
import { GeWidgetItemsRequest } from '../types/model/geWidgetItemsRequest';
import { GetCatalogRequest } from '../types/model/getCatalogRequest';
import { HidePostsRequest } from '../types/model/hidePostsRequest';
import { ItemsResponse } from '../types/model/itemsResponse';
import { NewsFeedCursorRequest } from '../types/model/newsFeedCursorRequest';
import { NewsFeedResponse } from '../types/model/newsFeedResponse';
import { SearchItemsByCategoryRequest } from '../types/model/searchItemsByCategoryRequest';
import { SearchItemsByNameRequest } from '../types/model/searchItemsByNameRequest';
import { SupportedLocalesResponse } from '../types/model/supportedLocalesResponse';
import { UserLocalePreferencesRequest } from '../types/model/userLocalePreferencesRequest';
import { UserPreferencesRequest } from '../types/model/userPreferencesRequest';
import { WidgetResponse } from '../types/model/widgetResponse';

// Todo Remove OLD_API when catalog api will be moved to new url
const OLD_API = 'channelCatalog';
const ROOT_API = 'catalog';

export const getCatalog = (data: GetCatalogRequest): Promise<CatalogResponse> => {
  return request({
    rootApi: ROOT_API,
    url: '/getCatalog',
    method: 'POST',
    body: data,
  });
};

export const searchItemsByName = (data: SearchItemsByNameRequest): Promise<ItemsResponse> => {
  return request({
    rootApi: ROOT_API,
    url: '/searchItemsByName',
    method: 'POST',
    body: data,
  });
};

export const getWidgetItems = (data: GeWidgetItemsRequest): Promise<ItemsResponse> => {
  return request({
    rootApi: ROOT_API,
    url: '/getWidgetItems',
    method: 'POST',
    body: data,
  });
};

export const searchItemsByCategory = (
  data: SearchItemsByCategoryRequest
): Promise<ItemsResponse> => {
  return request({
    rootApi: ROOT_API,
    url: '/searchItemsByCategory',
    method: 'POST',
    body: data,
  });
};

export const setUserPreferences = (data: UserPreferencesRequest): Promise<BooleanResponse> => {
  return request({
    rootApi: OLD_API,
    url: '/setUserPreferences',
    method: 'POST',
    body: data,
  });
};

export const updateUserLocalePreferences = (
  data: UserLocalePreferencesRequest
): Promise<BooleanResponse> => {
  return request({
    rootApi: OLD_API,
    url: '/updateUserLocalePreferences',
    method: 'POST',
    body: data,
  });
};

export const getSupportedLocales = (): Promise<SupportedLocalesResponse> => {
  return request({
    rootApi: OLD_API,
    url: '/getSupportedLocales',
    method: 'POST',
  });
};

export const getNewsFeed = (data: NewsFeedCursorRequest): Promise<NewsFeedResponse> => {
  return request({
    rootApi: OLD_API,
    url: '/getNewsFeed',
    method: 'POST',
    body: data,
  });
};

export const unhidePostsInNewsFeed = (data: HidePostsRequest): Promise<BooleanResponse> => {
  return request({
    rootApi: OLD_API,
    url: '/unhidePostsInNewsFeed',
    method: 'POST',
    body: data,
  });
};

export const hidePostsInNewsFeed = (data: HidePostsRequest): Promise<BooleanResponse> => {
  return request({
    rootApi: OLD_API,
    url: '/hidePostsInNewsFeed',
    method: 'POST',
    body: data,
  });
};

export const getStartPageItems = (
  data: GetCatalogRequest
): Promise<{
  recommendationType: 'most_popular' | 'suggested';
  items: ItemsResponseItemsInner[];
}> => {
  return request({
    rootApi: ROOT_API,
    url: '/getStartPageItems',
    method: 'POST',
    body: data,
  });
};

export const getChannelsStartPage = (): Promise<WidgetResponse> => {
  return request({
    rootApi: OLD_API,
    url: '/getChannelsStartPage',
    method: 'POST',
  });
};
