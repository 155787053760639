import { RefObject, useCallback, useEffect, useRef, useState } from 'react';

import Tasks from 'APP/Tasks';
import { AudioSourceType } from 'APP/constants/app';

interface IArticleRedactorAudioPlayerPresenter {
  player: {
    step: number;
    canPlay: boolean;
    isPaused: boolean;
    isStarted: boolean;
    currentTime: number;
    duration: number;
  };
  controls: {
    handlePlay(): void;
    handlePause(): void;
    onTimeUpdate(): void;
    onEnded(): void;
    onCanPlay(): void;
    onChangeTime(event: Event): void;
  };
}

export function useArticleRedactorAudioPlayerPresenter(
  audioRef: RefObject<HTMLAudioElement>
): IArticleRedactorAudioPlayerPresenter {
  const step = 0.01;
  const [canPlay, setCanPlay] = useState(false);
  const [isStarted, setIsStarted] = useState(false);
  const [isPaused, setIsPaused] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const removeAudioSourceRef = useRef<(() => void) | null>(null);

  const handlePause = useCallback(() => {
    removeAudioSource();
    audioRef.current?.pause();
    setIsPaused(true);
  }, [audioRef]);

  const handlePlay = useCallback(() => {
    removeAudioSourceRef.current = Tasks.app.audioSource.setCurrentSource(
      AudioSourceType.Audio,
      handlePause
    );
    audioRef.current?.play();
    setIsStarted(true);
    setIsPaused(false);
  }, [audioRef]);

  const onChangeTime = useCallback(
    (event) => {
      if (audioRef.current) {
        audioRef.current.currentTime = event.target.value;
      }
      setCurrentTime(event.target.value);
    },
    [audioRef]
  );

  const onCanPlay = useCallback(() => {
    setCanPlay(true);
    setCurrentTime(getCurrentTimeIgnoreSmallStepFromAudio());
    setDuration(audioRef.current?.duration || 0);
  }, [audioRef]);

  const onTimeUpdate = useCallback(() => {
    setCurrentTime(getCurrentTimeIgnoreSmallStepFromAudio());
  }, [audioRef]);

  const onEnded = useCallback(() => {
    removeAudioSource();
    setCurrentTime(audioRef.current?.duration || 0);
    setIsStarted(false);
    setIsPaused(true);
  }, [audioRef]);

  const getCurrentTimeIgnoreSmallStepFromAudio = (): number => {
    const currentTime = audioRef.current?.currentTime || 0;

    return currentTime > step ? currentTime : 0;
  };

  const removeAudioSource = useCallback(() => {
    if (removeAudioSourceRef.current) {
      removeAudioSourceRef.current();
      removeAudioSourceRef.current = null;
    }
  }, []);

  useEffect(() => {
    return (): void => removeAudioSource();
  }, []);

  return {
    player: {
      step,
      canPlay,
      isPaused,
      isStarted,
      currentTime,
      duration,
    },
    controls: {
      handlePlay,
      handlePause,
      onTimeUpdate,
      onEnded,
      onCanPlay,
      onChangeTime,
    },
  };
}
