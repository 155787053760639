import { action, computed, observable, values } from 'mobx';

import { PARTICIPANT_ROLE, STREAM_STATUS } from 'APP/constants/stream';
import Entities from 'STORE';

import ActiveStreamer from './ActiveStreamer';
import Streamer from './Streamer';
import Viewer from './Viewer';

class Stream {
  @observable isDisposed = true;
  @observable channelId = null;
  @observable messageId = null;
  @observable title = null;
  @observable previewImageUrl = null;
  @observable totalViewers = 0;
  @observable viewersLiveCount = 0;
  @observable status = STREAM_STATUS.INITIATED;
  @observable activeStreamer = new ActiveStreamer();
  @observable viewersLive = new Map();
  @observable streamersLive = new Map();

  uid = null;
  initiatorUid = null;
  initiatorShareScreenUid = null;
  token = null;
  shareScreenUid = null;
  shareScreenToken = null;

  initiatorId = null;
  groupId = null;
  startTs = null;

  constructor(data) {
    this.updateStream(data);
  }

  @action
  updateStream(data) {
    this.uid = data.uid || this.uid;
    this.initiatorUid = data.initiatorUid || this.initiatorUid;
    this.initiatorShareScreenUid = data.initiatorShareScreenUid || this.initiatorShareScreenUid;
    this.token = data.token || this.token;
    this.shareScreenUid = data.shareScreenUid || this.shareScreenUid;
    this.shareScreenToken = data.shareScreenToken || this.shareScreenToken;

    this.initiatorId = data.initiatorId || this.initiatorId;
    this.groupId = data.groupId || this.groupId;
    this.channelId = data.channelId || this.channelId;
    this.messageId = data.messageId || this.messageId;
    this.title = data.title || this.title;
    this.previewImageUrl = data.previewImageUrl || this.previewImageUrl;
    this.totalViewers = data.totalViewers || this.totalViewers;
    this.viewersLiveCount = data.viewersLiveCount || this.viewersLiveCount;
    this.status = data.status || this.status || STREAM_STATUS.INITIATED;

    if (data.viewersLive) {
      this.mergeViewers(
        data.viewersLive.map((user) => ({
          ...user,
          role: PARTICIPANT_ROLE.VIEWER,
        }))
      );
    }

    if (data.streamers) {
      this.mergeViewers(
        data.streamers.map((user) => ({
          ...user,
          role: PARTICIPANT_ROLE.STREAMER,
        }))
      );
    }
  }

  @action
  addInitiator() {
    const group = Entities.GroupsStore.getGroupById(this.groupId);
    const initiatorUid = this.initiatorUid.toString();

    this.streamersLive.set(
      initiatorUid,
      new Streamer({
        uid: this.initiatorUid,
        userId: this.initiatorId,
        initiatorId: this.initiatorId,
        username: group.title,
        avatarUrl: group.avatarUrl,
        shareScreenUid: this.initiatorShareScreenUid,
        shareScreenToken: this.shareScreenToken,
      })
    );
  }

  @computed
  get initiator() {
    return this.streamersLive.get(this.initiatorUid.toString());
  }

  @action
  mergeViewers(viewers = []) {
    viewers.forEach((user) => {
      const initiatorId = user.initiatorId?.toString() || this.initiatorId?.toString();
      const userId = user.userId.toString();

      if (initiatorId !== userId) {
        this.viewersLive.set(userId, new Viewer(user));

        if (user.role === PARTICIPANT_ROLE.STREAMER) {
          const uid = user.uid.toString();

          this.streamersLive.set(uid, new Streamer(user));
        }
      }
    });
  }

  @action
  removeViewer(userId) {
    this.viewersLive.delete(userId.toString());
  }

  @computed
  get viewers() {
    return values(this.viewersLive)
      .slice()
      .sort((user1, user2) => user1.username.localeCompare(user2.username));
  }

  @action
  findViewer(userId) {
    const key = userId?.toString();

    return this.viewersLive.get(key) || null;
  }

  @action
  findStreamerByUid(uid) {
    const key = uid.toString();
    const streamer = this.streamersLive.get(key) || null;

    if (!streamer) {
      return (
        this.streamers.find((streamer) => {
          const shareScreenUid = streamer.shareScreenUid.toString();

          return shareScreenUid === key;
        }) || null
      );
    }

    return streamer;
  }

  @computed
  get streamers() {
    return values(this.streamersLive)
      .slice()
      .sort((user1, user2) => user1.username.localeCompare(user2.username));
  }

  @computed
  get isMeStreamer() {
    const id = Entities.UsersStore.Me.id.toString();
    const viewer = this.viewersLive.get(id);

    return viewer && viewer.isStreamer;
  }

  @action
  setActiveStreamer(uid, isSharedScreen = false) {
    this.activeStreamer.update({
      streamer: this.findStreamerByUid(uid),
      isSharedScreen,
    });
  }

  @action
  destroy() {
    this.viewersLive.clear();
    this.streamersLive.clear();
  }
}

export default Stream;
