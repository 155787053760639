import classNames from 'classnames';
import React, { FC, ReactEventHandler } from 'react';

import { IconView } from 'UIKIT/IconView/IconView';
import { Loader } from 'UIKIT/Loader/Loader';

import styles from '../Button/Button.styles.m.css';
import { buttonIconSizeMap, IButton } from '../Button/Button.types';
import radioButtonStyles from './RadioButton.styles.m.css';

interface IButtonLabelProps extends IButton<HTMLLabelElement> {
  form?: string;
  label?: string;
  name: string;
  onChange: ReactEventHandler<HTMLInputElement>;
  checked: boolean;
  value: string;
}

export const RadioButton: FC<IButtonLabelProps> = (props) => {
  const {
    Icon,
    form,
    setRef,
    tabIndex,
    dataTest,
    className,
    theme = '1',
    rounding = '0',
    isLoading = false,
    size = '10',
    label,
    name,
    onChange,
    checked,
    value,
  } = props;

  const containerClasses = classNames(
    styles.container,
    styles[`size-${size}`],
    styles[`rounding-${rounding}`],
    styles[`theme-${theme}`],
    className
  );

  return (
    <label
      form={form}
      ref={setRef}
      className={containerClasses}
      tabIndex={tabIndex}
      data-test={dataTest}
    >
      <span className={classNames(styles.content, { [styles.hidden]: isLoading })}>
        {Icon && (
          <IconView
            Icon={Icon}
            size={buttonIconSizeMap[size]}
            className={styles.icon}
          />
        )}
        <div className={styles.text}>
          {label}
          <input
            type="radio"
            className={radioButtonStyles.radio}
            value={value}
            name={name}
            onChange={onChange}
            checked={checked}
          />
        </div>
      </span>
      {isLoading && <Loader className={styles.loader} />}
    </label>
  );
};
