/**
 * Rest API
 * Documentation Rest API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CallHistoryResponse { 
    initiatorId: string;
    groupId: string;
    avatarUrl?: string;
    opponentName: string;
    opponentUsernameBadge?: CallHistoryResponseOpponentUsernameBadgeEnum;
    verified?: boolean;
    start: string;
    end: string;
    status: CallHistoryResponseStatusEnum;
    callType: CallHistoryResponseCallTypeEnum;
    channelId: string;
}
export enum CallHistoryResponseOpponentUsernameBadgeEnum {
    _1 = 'BADGE_1',
    _2 = 'BADGE_2',
    _3 = 'BADGE_3',
    _4 = 'BADGE_4',
    _5 = 'BADGE_5'
};
export enum CallHistoryResponseStatusEnum {
    Unanswered = 'UNANSWERED',
    Declined = 'DECLINED',
    Finished = 'FINISHED'
};
export enum CallHistoryResponseCallTypeEnum {
    P2P = 'P2P',
    Group = 'GROUP',
    Conference = 'CONFERENCE',
    Stream = 'STREAM'
};



