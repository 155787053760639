import { observer } from 'mobx-react';
import React from 'react';

import { UsersPopover } from 'APP/components/UsersPopover/UsersPopover';

import { useUsersPopoverPresenter } from './UsersPopover.presenter';

export const Popover = observer((props) => {
  const { usersIds, groupId, messageId, tooltip, interactive, placement } = props;
  const presenter = useUsersPopoverPresenter({
    usersIds,
    tooltip,
    groupId,
    messageId,
  });

  if (!presenter.isOpened) {
    return null;
  }

  return (
    <UsersPopover
      isOpened={presenter.isOpened}
      data={presenter.users}
      moreCount={presenter.moreCount}
      onClick={presenter.onMoreClick}
      onClickUser={presenter.onUserClick}
      messageId={messageId}
      groupId={groupId}
      interactive={interactive}
      popupPlacement={placement}
    />
  );
});
