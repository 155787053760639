import { computed } from 'mobx';

import { Group } from '../Group';

export class Channel extends Group {
  get isChannel(): boolean {
    return true;
  }

  @computed
  get canInviteUsers(): boolean {
    if (this.isClosed) {
      return this.withMeInAdmins;
    }

    return this.withMeInAdmins || this.isMember;
  }

  get canMentionAll(): boolean {
    return false;
  }

  get canBeScheduled(): boolean {
    return true;
  }
}
