import { runInAction } from 'mobx';

import bus from 'APP/packages/bus';
import Entities from 'STORE';

export function deleteGroupMessages(groupId: string, messageIds: string[]): void {
  if (!messageIds.length) {
    return;
  }

  const group = Entities.GroupsStore.getGroupById(groupId.toString());
  if (!group) {
    return;
  }

  runInAction(() => {
    messageIds.forEach((messageId) => {
      group.pinnedMessages.removeId(messageId);
      bus.messaging.broadcastDeleteMessage(groupId, messageId);
    });

    group.messagesStore.handleRemoveMessages(group.messagesStore.getMessageByIds(messageIds));

    bus.messaging.broadcastMessagesChanges(groupId);
  });
}
