import Listener from './listener';

export class Callbacks {
  callbacks = {};

  listener;
  capturedListener;

  constructor() {
    this.listener = new Listener(false, this.callbacks);
    this.capturedListener = new Listener(true, this.callbacks);
  }

  add = (keyName, callbackItem) => {
    if (Array.isArray(this.callbacks[keyName])) {
      this.callbacks[keyName].push(callbackItem);
    } else {
      this.callbacks[keyName] = [callbackItem];
    }

    if (callbackItem.current.isCaptured) {
      this.capturedListener.add();
    } else {
      this.listener.add();
    }

    return () => this.remove(keyName, callbackItem);
  };

  remove = (keyName, callbackItem) => {
    const index = this.callbacks[keyName].findIndex((item) => item === callbackItem);
    this.callbacks[keyName].splice(index, 1);

    if (callbackItem.current.isCaptured) {
      this.capturedListener.remove();
    } else {
      this.listener.remove();
    }
  };
}

export default new Callbacks();
