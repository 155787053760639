import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { MemberList } from 'MAIN/OngoingCall/Shared/MemberList/MemberList';
import Entities from 'STORE';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';

import styles from './RightPanel.styles.m.css';
import { RightPanelHeader } from './RightPanelHeader/RightPanelHeader';

export const RightPanel = observer(() => {
  const call = Entities.Calls.ongoingCall;

  if (!call) {
    return null;
  }

  return (
    <div className={styles.container}>
      <RightPanelHeader />
      <div className={classNames(styles.memberListContainer, customScrollStyles.container)}>
        <MemberList />
      </div>
    </div>
  );
});
