import classNames from 'classnames';
import React, { FC, useEffect } from 'react';
import { FormSpy } from 'react-final-form';

import { IMAGE_TYPE } from 'APP/constants';
import { Field } from 'APP/packages/form/Field/Field';
import { Form } from 'APP/packages/form/Form/Form';
import { IFormContentProps } from 'APP/packages/form/Form/Form.types';
import ImageUploader from 'APP/packages/form/ImageUploader';
import { TextField } from 'APP/packages/form/TextField/TextField';
import { useTranslation } from 'APP/packages/translations';
import { useCreateSpaceCollectionValidation } from 'APP/packages/validations/form/createSpaceCollection';
import { Button, ButtonTypes } from 'UIKIT/Button/Button';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';
import { PopupFooter } from 'UIKIT/PopupFooter/PopupFooter';

import styles from './SpaceCollectionForm.styles.m.css';
import { ICollectionFormData } from './SpaceCollectionForm.types';

const FORM_ID = 'collection-form';
const COLLECTION_NAME_MAX_LENGTH = 35;

interface ICreateNewCollectionProps {
  initialValues?: ICollectionFormData;
  onFormStateChange(state: Partial<IFormContentProps> | null): void;
  onSubmit(data: ICollectionFormData): Promise<void>;
}

export const SpaceCollectionForm: FC<ICreateNewCollectionProps> = ({
  initialValues,
  onFormStateChange,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const validation = useCreateSpaceCollectionValidation();

  useEffect(() => {
    return () => onFormStateChange(null);
  }, []);

  return (
    <Form
      initialValues={initialValues}
      subscription={{
        submitting: true,
        pristine: true,
        invalid: true,
        validating: true,
      }}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        submitting,
        pristine,
        invalid,
        validating,
      }: IFormContentProps): React.ReactElement => (
        <>
          <FormSpy
            subscription={{ submitting: true, pristine: true }}
            onChange={onFormStateChange}
          />

          <form
            id={FORM_ID}
            className={classNames(styles.form, customScrollStyles.container)}
            onSubmit={handleSubmit}
          >
            <Field
              name="avatarUrl"
              component={ImageUploader}
              imageType={IMAGE_TYPE.SQUARE}
              validate={validation.avatar.validate}
            />

            <Field
              name="name"
              label={t('space_collection_name_hint')}
              isNeedTrim
              component={TextField}
              maxLength={COLLECTION_NAME_MAX_LENGTH}
              validate={validation.name.validate}
            />
          </form>

          <PopupFooter>
            <Button
              type={ButtonTypes.Submit}
              form={FORM_ID}
              isLoading={submitting}
              isDisabled={pristine || invalid || validating}
              theme="1"
            >
              {initialValues ? t('save') : t('common_create')}
            </Button>
          </PopupFooter>
        </>
      )}
    </Form>
  );
};
