import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { IconButton } from 'APP/packages/UiKit/IconButton';
import { AddKeyPressHandler } from 'APP/packages/keyBoardHandler';
import { IMediaGalleryState } from 'APP/types/mediaGallery';
import IconLeft from 'ICONS/ic-left-32.svg';
import IconRight from 'ICONS/ic-right-32.svg';
import { IconViewSizes } from 'UIKIT/IconView/IconView.types';

import { IMediaGalleryFile } from '../MediaGallery.types';
import { useMediaGalleryContentPresenter } from './MediaGalleryContent.presenter';
import styles from './MediaGalleryContent.styles.m.css';
import { MediaGalleryFile } from './MediaGalleryFile/MediaGalleryFile';

interface IMediaGalleryContentProps {
  file: IMediaGalleryFile;
  galleryState: IMediaGalleryState;
}

export const MediaGalleryContent: FC<IMediaGalleryContentProps> = observer(
  ({ file, galleryState }) => {
    const { presenter, contentRef } = useMediaGalleryContentPresenter(file, galleryState);

    return (
      <div
        ref={contentRef}
        className={styles.container}
      >
        <AddKeyPressHandler
          onArrowLeft={presenter.toPrev}
          onArrowRight={presenter.toNext}
        />
        {presenter.isPrevButton && (
          <IconButton
            onClick={presenter.toPrev}
            className={classNames(styles.navigate, { [styles.show]: presenter.isShowNavigation })}
            Icon={IconLeft}
            isLoading={presenter.isPrevLoading}
            loaderColor={'primary'}
            size={IconViewSizes.XLarge}
          />
        )}
        <MediaGalleryFile
          id={file.id}
          messageId={file.message.id}
          groupId={file.message.groupId}
          payload={file.payload}
        />
        {presenter.isNextButton && (
          <IconButton
            onClick={presenter.toNext}
            size={IconViewSizes.XLarge}
            Icon={IconRight}
            className={classNames(styles.navigate, { [styles.show]: presenter.isShowNavigation })}
            loaderColor={'primary'}
            isLoading={presenter.isNextLoading}
          />
        )}
      </div>
    );
  }
);
