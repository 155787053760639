import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import { PARTICIPANT_ROLE } from 'APP/constants/stream';
import logger from 'APP/packages/logger';
import { initStreamListener } from 'APP/packages/streamApi';
import Entities from 'APP/store';

export default (rootPresenter) => {
  const presenter = useLocalStore(() => ({
    disposeListener: null,

    get stream() {
      return Entities.ActiveStream.stream;
    },

    onUserJoined: (data) => {
      logger.get('InitiatorView.streamStateHandler').debug('onUserJoined', data);
      presenter.stream.updateStream({
        viewersLiveCount: data.liveViewersCount,
      });

      const isStreamer = presenter.stream.findStreamerByUid(data.uid);

      presenter.stream.mergeViewers([
        {
          userId: data.userId,
          uid: data.uid,
          username: data.username,
          avatarUrl: data.avatarUrl,
          shareScreenUid: data.shareScreenUid,
          role: isStreamer ? PARTICIPANT_ROLE.STREAMER : PARTICIPANT_ROLE.VIEWER,
        },
      ]);
    },

    onUserLeft: (data) => {
      logger.get('InitiatorView.streamStateHandler').debug('onUserLeft', data);
      presenter.stream.updateStream({
        viewersLiveCount: data.liveViewersCount,
      });

      presenter.stream.removeViewer(data.userId);
    },

    onNewBroadcaster: (data) => {
      logger.get('InitiatorView.streamStateHandler').debug('onNewBroadcaster', data);
      const streamers = [presenter._mapStreamer(data)];

      presenter.stream.mergeViewers(streamers);
    },

    onBroadcasters: (data) => {
      logger.get('InitiatorView.streamStateHandler').debug('onBroadcasters', data);
      const streamers = data.map(presenter._mapStreamer);

      presenter.stream.mergeViewers(streamers);
    },

    _mapStreamer(data) {
      return {
        uid: data.uid,
        initiatorId: data.initiatorId,
        userId: data.userId,
        username: data.username,
        avatarUrl: data.avatarUrl,
        shareScreenUid: data.shareScreenUid,
        platform: data.platform,
        role: PARTICIPANT_ROLE.STREAMER,
      };
    },

    connect() {
      if (presenter.stream?.channelId && !presenter.disposeListener) {
        presenter.disposeListener = initStreamListener({
          channelId: presenter.stream.channelId,
          onUserLeft: presenter.onUserLeft,
          onUserJoined: presenter.onUserJoined,
          onNewBroadcaster: presenter.onNewBroadcaster,
          onBroadcasters: presenter.onBroadcasters,
          onError: presenter.onError,
        });
      }
    },

    disconnect() {
      if (presenter.disposeListener) {
        presenter.disposeListener();
        presenter.disposeListener = null;
      }
    },

    async onError(err) {
      try {
        presenter.disconnect();
        // Check for error code in sse. I did not find another possibility in the specification
        // https://html.spec.whatwg.org/multipage/server-sent-events.html#the-eventsource-interface
        const controller = new AbortController();
        const { signal } = controller;
        const { status } = await fetch(err.target.url, { signal });
        controller.abort();
        if (status === 403) {
          await rootPresenter.finish();
        } else {
          setTimeout(() => {
            presenter.connect();
          }, 3000);
        }
      } catch (e) {
        console.error(e);
      }
    },
  }));

  useEffect(() => {
    presenter.connect();
    return () => {
      presenter.disconnect();
    };
  }, [presenter.stream?.channelId]);

  useEffect(() => {
    if (Entities.AuthStore.streamToken) {
      presenter.connect();
    }
    if (!Entities.AuthStore.streamToken) {
      presenter.disconnect();
    }
  }, [Entities.AuthStore.streamToken]);
};
