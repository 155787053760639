import { EmojiCategoryName } from 'APP/constants/emojis';

import { localStorageInstance } from '../../instances/localStorageInstance';

const KEY_NAME = 'selected-emoji-category';

export const set = (selectedCategory: EmojiCategoryName): void =>
  localStorageInstance.setItem(KEY_NAME, selectedCategory);

export const get = (): EmojiCategoryName | null => localStorageInstance.getItem(KEY_NAME);

export const remove = (): void => localStorageInstance.removeItem(KEY_NAME);
