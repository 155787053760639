export default (positions) => {
  const ranges = [];
  let startPosition = null;

  for (let i = 0; i < positions.length; i++) {
    const position = positions[i];

    if (position.closeTag) {
      ranges.push({ start: position.start, end: position.closeTag.end });
      continue;
    }

    if (startPosition === null) {
      startPosition = position.start;
      continue;
    }

    ranges.push({ start: startPosition, end: position.end });
    startPosition = null;
  }

  return ranges;
};
