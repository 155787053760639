import { useLocalStore } from 'mobx-react';

import { ProviderUid } from 'APP/packages/callProviders/callProviders.types';
import Entities from 'APP/store';
import { Call } from 'STORE/Calls/Call/Call';
import { Opponent } from 'STORE/Calls/Call/Opponent/Opponent';

interface IOpponentVideoItem {
  opponentPosition: number;
  opponent: Opponent | null;
  ongoingCall: Call;
  onOpponentClick(): void;
}

export const useOpponentVideoItem = (
  opponentUid: ProviderUid,
  isGridLayout?: boolean
): IOpponentVideoItem => {
  const presenter = useLocalStore<IOpponentVideoItem>(() => ({
    get ongoingCall(): Call {
      return Entities.Calls.ongoingCall!;
    },

    get opponentPosition(): number {
      return presenter.ongoingCall.opponentsPositions.opponentsPositions[opponentUid];
    },

    get opponent(): Opponent | null {
      return presenter.ongoingCall.opponents.getOpponent(opponentUid) || null;
    },

    onOpponentClick(): void {
      isGridLayout
        ? Entities.OngoingCall.presentVideoMode.toggleGridLayout(presenter.opponent)
        : Entities.OngoingCall.presentVideoMode.setActiveOpponent(presenter.opponent);
    },
  }));

  return presenter;
};
