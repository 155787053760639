import { observer } from 'mobx-react';
import React from 'react';

import { PopoverMenu } from 'UIKIT/PopoverMenu/PopoverMenu';

import usePresenter from './presenter';
import styles from './styles.m.css';

const Menu = ({ isOpened, onClose, fileUploadRef, attachButtonRef }) => {
  const presenter = usePresenter(fileUploadRef);

  return (
    <PopoverMenu
      isOpened={isOpened}
      placement="top-start"
      menuItems={presenter.menuItems}
      reference={attachButtonRef.current}
      onClose={onClose}
      className={styles.container}
    />
  );
};

export default observer(Menu);
