import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useRef, useEffect } from 'react';

import { ICallProviderPublisher } from 'APP/packages/callProviders/callProviders.types';

import styles from './PreviewVideo.styles.m.css';

interface IPreviewVideoProps {
  publisher: ICallProviderPublisher;
}
export const PreviewVideo: React.FC<IPreviewVideoProps> = observer(({ publisher }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (publisher.hasVideo) {
      if (publisher.isPlayingVideo) {
        publisher.stopVideo();
      }
      if (ref.current) {
        publisher.playVideo(ref.current);
      }
    }
    return () => {
      if (publisher.hasVideo && publisher.isPlayingVideo) {
        publisher.stopVideo();
      }
    };
  }, [ref, publisher]);

  return (
    <div
      ref={ref}
      className={classNames(styles.container)}
    />
  );
});
