import Tasks from 'APP/Tasks';
import Entities from 'STORE';
import { IPushNotification } from 'STORE/PushNotifications/PushNotifications.types';

export const onSpaceAdminNotificationClick = async (
  pushNotification: IPushNotification,
  notification: Notification
): Promise<void> => {
  await Tasks.group.createP2PGroup(pushNotification.user);

  if (notification) {
    Entities.pushNotifications.removeNotificationByRef(notification);
  }

  Tasks.app.focusApp();
};
