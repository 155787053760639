import { GOOGLE_MAP_KEY } from 'APP/constants';
import { PayloadType } from 'APP/model/message/messageModel.types';
import { t } from 'MODULE/i18n/service';
import { Payload } from 'STORE/Messages/Message/Payload';

class Location extends Payload {
  latitude;
  longitude;

  constructor(data) {
    super(data);

    this.latitude = data.latitude;
    this.longitude = data.longitude;
  }

  toJSON() {
    return {
      payloadType: PayloadType.Location,
      latitude: this.latitude,
      longitude: this.longitude,
    };
  }

  get text() {
    return t('message_desctription_location');
  }

  get originUrl() {
    const link = `https://www.google.com/maps/search/?api=1&query=${this.latitude},${this.longitude}`;

    return link;
  }

  get url() {
    return `https://maps.googleapis.com/maps/api/staticmap?center=${this.latitude},${this.longitude}&maptype=roadmap&zoom=10&style=feature:all|element:labels|visibility:off&key=${GOOGLE_MAP_KEY}`;
  }

  get typeDescription() {
    return t('message_desctription_location');
  }
}

export default Location;
