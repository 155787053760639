import React, { FC } from 'react';

import {
  RouterParams,
  RouterParamsPopup,
  RouterState,
  RouterStatePopup,
} from 'APP/router/constants';
import useGetParameters from 'APP/router/hooks/useGetParameters';
import useGetState from 'APP/router/hooks/useGetState';

import { withPopupLayer } from '../withPopupLayer';
import { AddMembersPopup } from './Popups/AddMembersPopup/AddMembersPopup';
import ChannelInfoPopup from './Popups/ChannelInfoPopup';
import NewMessagePopup from './Popups/NewMessagePopup';
import { NewUsersPopup } from './Popups/NewUsersPopup/NewUsersPopup';
import { CallChangeDefaultPermissionPopup } from './Popups/Permissions/CallChangeDefaultPermissionPopup';
import { CallChangeMemberPermissionPopup } from './Popups/Permissions/CallChangeMemberPermissionPopup';
import { CallMemberPermissionsPopup } from './Popups/Permissions/CallMemberPermissionsPopup';
import { CallPermissionsPopup } from './Popups/Permissions/CallPermissionsPopup/CallPermissionsPopup';
import { CallSelectMemberPopup } from './Popups/Permissions/CallSelectMemberPopup';
import { RequestedPermissionsPopup } from './Popups/Permissions/RequestedPermissionsPopup';
import ScheduleSettings from './Popups/ScheduleSettings';
import { SelectCategoryPopup } from './Popups/SelectCategoryPopup/SelectCategoryPopup';
import ShareMessageTo from './Popups/ShareMessageTo';
import SharedMedia from './Popups/SharedMedia';
import Sticker from './Popups/Sticker';

const popups: Record<string, FC> = {
  [RouterParamsPopup.newMessage]: withPopupLayer(NewMessagePopup),
  [RouterParamsPopup.addMembers]: withPopupLayer(AddMembersPopup),
  [RouterStatePopup.shareMessageTo]: withPopupLayer(ShareMessageTo),
  [RouterStatePopup.newUsers]: withPopupLayer(NewUsersPopup),
  [RouterStatePopup.channelInfo]: withPopupLayer(ChannelInfoPopup),
  [RouterStatePopup.sticker]: withPopupLayer(Sticker),
  [RouterStatePopup.scheduleSettings]: withPopupLayer(ScheduleSettings),
  [RouterStatePopup.sharedMedia]: withPopupLayer(SharedMedia),
  [RouterParamsPopup.callPermissions]: withPopupLayer(CallPermissionsPopup),
  [RouterParamsPopup.callChangeMemberPermission]: withPopupLayer(CallChangeMemberPermissionPopup),
  [RouterParamsPopup.callChangeDefaultPermission]: withPopupLayer(CallChangeDefaultPermissionPopup),
  [RouterParamsPopup.callSelectMember]: withPopupLayer(CallSelectMemberPopup),
  [RouterParamsPopup.callMemberPermissions]: withPopupLayer(CallMemberPermissionsPopup),
  [RouterParamsPopup.callPermissionRequests]: withPopupLayer(RequestedPermissionsPopup),
  [RouterParamsPopup.selectCategory]: withPopupLayer(SelectCategoryPopup),
};

export const GetParametersManager: FC = () => {
  const popupNames = useGetParameters(RouterParams.popup);
  const popupNameState = useGetState<string>(RouterState.popup);

  if (popupNameState) {
    popupNames.push(popupNameState);
  }

  return (
    <>
      {popupNames.map((name: RouterParamsPopup) => {
        const Component = popups[name];

        if (!Component) {
          return null;
        }

        return <Component key={name} />;
      })}
    </>
  );
};
