import classNames from 'classnames';
import React from 'react';

import { useTranslation } from 'APP/packages/translations';
import useLastSeenStatusText from 'MAIN/hooks/useLastSeenStatusText';
import { IconView } from 'UIKIT/IconView/IconView';
import popupItemStyles from 'UIKIT/PopupItem/styles.m.scss';

import matchIconToPlatform from '../../utils/matchIconToPlatofrm';
import matchPlatformNameToPlatform from '../../utils/matchPlatformNameToPlatofrm';
import styles from './styles.m.css';

const SessionItem = ({ isActive = false, session, onOpenSession }) => {
  const { t } = useTranslation();

  const platformName = matchPlatformNameToPlatform(session.platform);

  const lastOnline = useLastSeenStatusText({ date: +session.lastOnline });

  const location =
    session.city || session.country ? `${session.city} ${session.country}` : 'Unknown';

  return (
    <div
      onClick={!isActive ? onOpenSession : undefined}
      className={classNames(styles.container, {
        [popupItemStyles.interactive]: !isActive,
      })}
    >
      <IconView
        Icon={matchIconToPlatform(session.platform)}
        className={styles.icon}
      />
      <div className={styles.deviceInfo}>
        <div className={styles.deviceName}>{session.device}</div>
        <div className={styles.deviceClient}>
          Gem Space{` ${platformName} ${session.appVersion}`}
        </div>
        <div className={styles.location}>
          {location} {location ? <>&#x2022;</> : ''} {isActive ? t('common_online') : lastOnline}
        </div>
      </div>
    </div>
  );
};

export default SessionItem;
