export const getSafariScreenSharingTrack = async (): Promise<MediaStreamTrack | undefined> => {
  const mediaStream = await navigator.mediaDevices.getDisplayMedia();

  if (!mediaStream) {
    return;
  }

  const tracks = mediaStream.getVideoTracks();

  return tracks ? tracks[0] : undefined;
};
