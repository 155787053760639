import Tasks from 'APP/Tasks';
import { spaceModel } from 'APP/model/model';
import { spaceAnalytics } from 'APP/packages/analytics/analytics';
import { t } from 'MODULE/i18n/service';
import Entities from 'STORE';

export const confirmSpaceGroupsForMove = async (
  spaceId: string,
  groupId: string
): Promise<boolean> => {
  const space = Entities.spacesStore.getById(spaceId);
  const group = Entities.GroupsStore.getGroupById(groupId);

  if (!space?.isPrivate || !group?.isOpen) {
    return true;
  }

  return await Tasks.app.showConfirm({
    title: group.isChannel
      ? t('private_space_move_alert_title_forchannnel')
      : t('private_space_move_alert_title_forgroup'),
    text: group.isChannel
      ? t('private_space_move_alert_subtitle_forchannnel')
      : t('private_space_move_alert_subtitle_forgroup'),
    primaryText: t('space_move_button'),
    secondaryText: t('cancel'),
  });
};

function trackMoveEvent(groupIds: string[]): void {
  groupIds.forEach((groupId) => {
    const group = Entities.GroupsStore.getGroupById(groupId);
    const space = group?.spaceId ? Entities.spacesStore.getById(group.spaceId) : null;

    if (!group || !space) {
      return;
    }

    if (group.isChatGroup) {
      spaceAnalytics.trackSpaceGroupAdded(space.id, space.role, groupId, group.type);
    } else if (group.isChannel) {
      spaceAnalytics.trackSpaceChannelAdded(space.id, space.role, groupId, group.type);
    }
  });
}

export async function moveGroupsToSpace(spaceId: string, groupIds: string[]): Promise<boolean> {
  try {
    await spaceModel.moveGroupsToSpace(spaceId, groupIds);

    groupIds.forEach((groupId) => Entities.GroupsStore.getGroupById(groupId)?.setSpaceId(spaceId));

    trackMoveEvent(groupIds);

    return true;
  } catch {
    return false;
  }
}
