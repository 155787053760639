import classNames from 'classnames';
import React, { FC, ReactNode, RefObject, UIEventHandler } from 'react';

import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';

import styles from './MessagesContentWrapper.styles.m.css';

interface IMessagesContentWrapperProps {
  children: ReactNode;
  onScroll: UIEventHandler<HTMLDivElement>;
  scrollRef: RefObject<HTMLDivElement>;
}

export const MessagesContentWrapper: FC<IMessagesContentWrapperProps> = (props) => {
  const { children, onScroll, scrollRef } = props;
  const containerClassNames = classNames(styles.messages, customScrollStyles.container);

  return (
    <div
      className={containerClassNames}
      id={'messages-container'}
      data-test={'messages-container'}
      ref={scrollRef}
      onScroll={onScroll}
    >
      {children}
    </div>
  );
};
