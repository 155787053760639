import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import useClearGetParameters from 'APP/router/hooks/useClearGetParameters';
import Entities from 'STORE';
import { Call } from 'STORE/Calls/Call/Call';

interface ICallPermissionsPopupPresenter {
  ongoingCall: Call | null;
  isViewOnly: boolean;
  customPermissionsIds: string[];
  onClose(): void;
}

export const useCallPermissionsPopupPresenter = (): ICallPermissionsPopupPresenter => {
  const clearGetParameters = useClearGetParameters();

  const presenter = useLocalStore<ICallPermissionsPopupPresenter>(() => ({
    get ongoingCall(): Call | null {
      return Entities.Calls.ongoingCall;
    },

    get isViewOnly(): boolean {
      return !presenter.ongoingCall?.isMeInitiatorOwnerOrAdmin;
    },

    get customPermissionsIds(): string[] {
      return presenter.ongoingCall?.permissions.customPermissionsIds || [];
    },

    onClose(): void {
      clearGetParameters();
    },
  }));

  useEffect(() => {
    if (!presenter.ongoingCall || !presenter.ongoingCall?.isStarted) {
      presenter.onClose();
    }
  }, [presenter.ongoingCall?.isStarted]);

  return presenter;
};
