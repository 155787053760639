import Tasks from 'APP/Tasks';
import { ALERT_TYPES } from 'APP/constants/app';
import * as authModel from 'APP/model/auth/authModel';
import Entities from 'STORE';

export const getMyAccounts = async (): Promise<any> => {
  try {
    const accountProviders = await authModel.getMyAccountInfo();

    Entities.UsersStore.Me.setAccountProviders(accountProviders);
  } catch (error: any) {
    Tasks.app.addAlert({
      type: ALERT_TYPES.SOMETHING_WRONG_ERROR,
    });
  }
};
