import React, {
  useState,
  useRef,
  useEffect,
  FC,
  Children,
  isValidElement,
  cloneElement,
  ReactNode,
} from 'react';

import { CarouselControls } from '../CarouselControls/CarouselControls';
import styles from './CarouselInfinite.styles.m.css';

const ANIMATION_DURATION = 1000; // 1 sec

function easeOutQuint(t: number): number {
  return 1 - Math.pow(1 - t, 5);
}

function cloneChildrenWithKeys(childrenToClone: ReactNode[], prefix: string): ReactNode[] {
  return Children.map(childrenToClone, (child, index) => {
    if (isValidElement(child)) {
      return cloneElement(child, {
        key: `${prefix}-${child.key || index}`,
      });
    }
    return child;
  })!;
}

interface CarouselInfiniteProps {
  itemsToShow: number;
  itemWidth: number;
  gap: number;
  withShadow?: boolean;
  activeIndex?: number;
  onChangeActiveIndex?: (index: number) => void;
}

export const CarouselInfinite: FC<CarouselInfiniteProps> = ({
  children,
  itemsToShow,
  itemWidth,
  gap,
  withShadow,
  activeIndex,
  onChangeActiveIndex,
}) => {
  const [currentIndex, setCurrentIndex] = useState(activeIndex || 0);
  const carouselRef = useRef<HTMLDivElement>(null);
  const animationRef = useRef<number | null>(null);
  const targetPositionRef = useRef(0);
  const currentPositionRef = useRef(0);

  const childrenArray = Children.toArray(children);
  const totalItems = childrenArray.length;
  const showNavigation = totalItems > itemsToShow;
  const totalWidth = totalItems * (itemWidth + gap);

  const clonedChildren = showNavigation
    ? [
        ...cloneChildrenWithKeys(childrenArray.slice(-itemsToShow), 'prefix'),
        ...childrenArray,
        ...cloneChildrenWithKeys(childrenArray.slice(0, itemsToShow), 'suffix'),
      ]
    : childrenArray;

  const getPosition = (index: number): number => {
    if (!showNavigation) {
      return 0;
    }

    return -((index + itemsToShow) * (itemWidth + gap));
  };

  const startTimeRef = useRef<number | null>(null);
  const startPositionRef = useRef<number>(0);

  const animate = (timestamp: number): void => {
    if (!startTimeRef.current) {
      startTimeRef.current = timestamp;
      startPositionRef.current = currentPositionRef.current;
    }
    const elapsedTime = timestamp - startTimeRef.current;

    if (elapsedTime < ANIMATION_DURATION) {
      const progress = elapsedTime / ANIMATION_DURATION;
      const easeProgress = easeOutQuint(progress);

      currentPositionRef.current =
        startPositionRef.current +
        (targetPositionRef.current - startPositionRef.current) * easeProgress;

      if (carouselRef.current) {
        carouselRef.current.style.transform = `translateX(${currentPositionRef.current}px)`;
      }
      animationRef.current = requestAnimationFrame(animate);
    } else {
      currentPositionRef.current = targetPositionRef.current;
      if (carouselRef.current) {
        carouselRef.current.style.transform = `translateX(${currentPositionRef.current}px)`;
      }
      animationRef.current = null;
      startTimeRef.current = null;
    }
  };

  const stopAnimation = (): void => {
    if (animationRef.current !== null) {
      cancelAnimationFrame(animationRef.current);
      animationRef.current = null;
    }
    startTimeRef.current = null;
    startPositionRef.current = 0;
  };

  const moveToIndex = (index: number): void => {
    const normalizedIndex = ((index % totalItems) + totalItems) % totalItems;
    setCurrentIndex(normalizedIndex);
    onChangeActiveIndex?.(normalizedIndex);

    stopAnimation();

    targetPositionRef.current = getPosition(index);

    // check whether we have reached the end or the beginning, and if so, adjust the position
    if (index !== normalizedIndex) {
      const adjustWidth = index > 0 ? totalWidth : -totalWidth;

      currentPositionRef.current += adjustWidth;
      targetPositionRef.current += adjustWidth;
    }

    animationRef.current = requestAnimationFrame(animate);
  };

  const handleNext = (): void => moveToIndex(currentIndex + 1);
  const handlePrev = (): void => moveToIndex(currentIndex - 1);

  useEffect(() => {
    const newPosition = getPosition(currentIndex);
    currentPositionRef.current = newPosition;
    targetPositionRef.current = newPosition;
    if (carouselRef.current) {
      carouselRef.current.style.transform = `translateX(${newPosition}px)`;
    }
  }, [itemWidth, gap, itemsToShow]);

  useEffect(() => {
    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (activeIndex !== undefined && activeIndex !== currentIndex) {
      moveToIndex(activeIndex);
    }
  }, [activeIndex]);

  return (
    <>
      <div className={styles.carouselWrapper}>
        <div
          ref={carouselRef}
          className={styles.carousel}
        >
          {Children.map(clonedChildren, (child) => (
            <div
              className={styles.carouselItem}
              style={{
                width: `${itemWidth}px`,
                marginRight: `${gap}px`,
              }}
            >
              {child}
            </div>
          ))}
        </div>
      </div>

      <CarouselControls
        showPrevButton={showNavigation}
        showNextButton={showNavigation}
        withShadow={withShadow}
        onPrev={handlePrev}
        onNext={handleNext}
      />
    </>
  );
};
