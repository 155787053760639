import Entities from 'APP/store';
import { TCloseFullscreenCallback } from 'STORE/App/App.types';

export function registerFullscreen(closeFullscreenCallback: TCloseFullscreenCallback): void {
  closeFullscreen();
  Entities.App.fullscreenCloseCallback = closeFullscreenCallback;
}

export function unregisterFullscreen(closeFullscreenCallback?: TCloseFullscreenCallback): void {
  if (
    !closeFullscreenCallback ||
    Entities.App.fullscreenCloseCallback === closeFullscreenCallback
  ) {
    Entities.App.fullscreenCloseCallback = null;
  }
}

export function closeFullscreen(): void {
  Entities.App.fullscreenCloseCallback?.();
  Entities.App.fullscreenCloseCallback = null;
}
