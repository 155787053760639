import {
  subscribe,
  broadcast,
  TEventUnsubscribeHandler,
  TEventSubscribeHandler,
} from '../../eventBus';

interface IBroadcastSentMessageData {
  messageUuid: string;
  messageId: string;
  isRequestFinished: boolean;
  hasError: boolean;
}

function getBusStation(groupId: string): string {
  return `sent-message-${groupId}`;
}

export function subscribeSentMessage(
  groupId: string,
  callback: TEventSubscribeHandler
): TEventUnsubscribeHandler {
  return subscribe(getBusStation(groupId), callback);
}

export function broadcastSentMessage(groupId: string, data: IBroadcastSentMessageData): void {
  broadcast(getBusStation(groupId), data);
}
