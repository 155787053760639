import { observer } from 'mobx-react';
import React from 'react';

import ImagePlaceholder from 'APP/packages/placeholders/Image';

import usePresenter from './presenter';

// this payload needs only for client
const LoadImageByLink = ({ parentPresenter, payload, order, id }) => {
  const presenter = usePresenter({ parentPresenter, payload, order, id });

  return (
    <ImagePlaceholder
      width={presenter.width}
      height={presenter.height}
    />
  );
};

export default observer(LoadImageByLink);
