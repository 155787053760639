import Tasks from 'APP/Tasks';
import { UpdateReason } from 'APP/constants/scroll';
import api from 'APP/packages/api';
import Entities from 'STORE';

import { GroupsEvent } from '../GroupsEvent';

export class ScheduledMessageWasUpdated extends GroupsEvent {
  async process() {
    const { groupId, messageId } = this.eventPayload;

    const group = Entities.GroupsStore.getGroupById(groupId.toString());
    if (!group) {
      return;
    }

    try {
      const message = await api.messaging.getScheduledMessage({
        groupId: group.id,
        messageId: messageId.toString(),
      });
      if (!message) {
        return;
      }

      Tasks.messaging.updateScheduledMessages({
        groupId: group.id,
        messages: [message],
      });

      if (group.isActive) {
        Entities.ChatStore.setScrollChanged(UpdateReason.EditMessage, {
          messageIds: [message.id],
          isAnimated: true,
        });
      }
    } catch (error) {
      if (!error?.code || error.code !== 400) {
        console.error(error);
      }
    }
  }
}
