import { observer } from 'mobx-react';
import React, { FC } from 'react';

import PinIcon from 'ICONS/pin2.svg';
import { Group } from 'STORE/Groups/Group';
import { Badge } from 'UIKIT/Badge/Badge';

import { GroupDescription } from '../../components/GroupDescription/GroupDescription';
import { GroupListItem } from '../../components/GroupListItem/GroupListItem';
import { JoinStreamingButton } from '../../components/JoinStreamingButton/JoinStreamingButton';
import { useChannelItemPresenter } from './ChannelItem.presenter';
import styles from './ChannelItem.styles.m.css';

interface IChannelItemProps {
  group: Group;
  isShowSpaceIcon: boolean;
}

export const ChannelItem: FC<IChannelItemProps> = observer(({ group, isShowSpaceIcon }) => {
  const presenter = useChannelItemPresenter(group);

  return (
    <GroupListItem
      group={group}
      isShowSpaceIcon={isShowSpaceIcon}
      isIconsCentered={!!presenter.backgroundStream}
      description={<GroupDescription group={group} />}
      badges={
        <>
          {presenter.hasUnread && (
            <Badge
              quantity={presenter.numberOfUnread || null}
              isMuted={group.muted}
            />
          )}
          {presenter.hasPinedIcon && <PinIcon className={styles.pin} />}
          {!!presenter.backgroundStream && (
            <JoinStreamingButton
              isActive={presenter.hasActiveStream}
              onClick={presenter.onJoinToStream}
            />
          )}
        </>
      }
    />
  );
});
