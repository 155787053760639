import { action, computed, observable } from 'mobx';

import { RecommendationType } from 'APP/model/catalog/catalogModel.types';
import { ISpace, SpaceUserRole } from 'APP/model/space/spaceModel.types';

import { Space } from './Space';
import { SpacesStore } from './Spaces';

export class SpaceRecommendations {
  @observable public type: RecommendationType | null = null;
  @observable public updateTs = 0;

  @observable private spaceData: ISpace[] = [];

  private spacesStore: SpacesStore;

  constructor(spacesStore: SpacesStore) {
    this.spacesStore = spacesStore;
  }

  @action
  public update(spaces: ISpace[], type: RecommendationType): void {
    this.spaceData = spaces;
    this.type = type;
    this.updateTs = Date.now();
  }

  @computed
  public get spaces(): Space[] {
    return this.spaceData
      .filter((item) => {
        const space = this.spacesStore.getById(item.id);
        return !space || space.isGuest;
      })
      .map(
        (space) =>
          new Space({
            space,
            role: SpaceUserRole.Guest,
          })
      );
  }
}
