import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { IScheduledEventDetails } from 'APP/model/scheduledEvent/scheduledEventModel.types';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import Entities from 'STORE';

export type IScheduledCallViewInitialData = Pick<
  IScheduledEventDetails,
  'endTs' | 'startTs' | 'groupId' | 'initiatorId' | 'name' | 'id' | 'groupName' | 'groupAvatar'
>;

export interface IScheduledCallViewPopupParams {
  eventId: string;
  initialData: IScheduledCallViewInitialData;
}

interface IScheduledCallViewPopupPresenter {
  hasBackButton: boolean;
  isLoading: boolean;
  scheduledEventDetails: IScheduledEventDetails | null;
  commonScheduledEvent: IScheduledEventDetails | IScheduledCallViewInitialData | null;
  getScheduledEvent(): Promise<void>;
  onClose(): void;
  onBack(): void;
}

export function useScheduledCallViewPopupPresenter({
  popupInstance,
  onClose,
  onBack,
  params,
}: ICommonPopupsProps<IScheduledCallViewPopupParams>): IScheduledCallViewPopupPresenter {
  const source = useAsObservableSource({ initialEventData: params?.initialData });
  const presenter = useLocalStore<IScheduledCallViewPopupPresenter>(() => ({
    isLoading: false,

    get hasBackButton(): boolean {
      return !!onBack;
    },

    get scheduledEventDetails(): IScheduledEventDetails | null {
      if (!params?.eventId) {
        return null;
      }

      return Entities.scheduledEvents.getScheduledEventDetailsById(params.eventId);
    },

    // following design and requirements we have to show main details (from list) without loading
    // so that we take data from the list until we receive the details
    get commonScheduledEvent(): IScheduledEventDetails | IScheduledCallViewInitialData | null {
      return presenter.scheduledEventDetails || source.initialEventData || null;
    },

    async getScheduledEvent(): Promise<void> {
      if (!params?.eventId) {
        return;
      }
      presenter.isLoading = true;
      await Tasks.scheduledEvents.getScheduledEventDetails(params.eventId);
      presenter.isLoading = false;
    },

    onClose(): void {
      popupInstance.close();
      onClose?.();
    },

    onBack(): void {
      popupInstance.close();
      onBack?.();
    },
  }));

  useEffect(() => {
    presenter.getScheduledEvent();
  }, []);

  useEffect(() => {
    if (!presenter.commonScheduledEvent) {
      presenter.onClose();
    }
  }, [presenter.commonScheduledEvent]);

  return presenter;
}
