import classNames from 'classnames';
import React, { FC, MouseEvent, ReactNode } from 'react';

import styles from './CategoryButton.styles.m.css';

interface ICategoryButtonProps {
  className?: string;
  children: ReactNode;
  isSelected: boolean;
  onClick(event?: MouseEvent<HTMLButtonElement>): void;
}

export const CategoryButton: FC<ICategoryButtonProps> = (props) => {
  const { className, children, isSelected, onClick } = props;
  const buttonClass = classNames(styles.button, className, {
    [styles.selected]: isSelected,
  });

  return (
    <button
      className={buttonClass}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
