import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { useTranslation } from 'APP/packages/translations';
import { Button } from 'UIKIT/Button/Button';

import styles from './SpaceMarketHeader.styles.m.css';

interface ISpaceMarketHeaderProps {
  showCreateMarketButton: boolean;
  onOpenAddMarket(): void;
}

export const SpaceMarketHeader: FC<ISpaceMarketHeaderProps> = observer(
  ({ showCreateMarketButton, onOpenAddMarket }) => {
    const { t } = useTranslation();

    return (
      <div className={styles.container}>
        <h3 className={styles.title}>{t('space_market_tab')}</h3>

        {showCreateMarketButton && (
          <Button
            theme="1"
            onClick={onOpenAddMarket}
          >
            {t('add_store_link_button')}
          </Button>
        )}
      </div>
    );
  }
);
