import { subscribe, broadcast } from '../../eventBus';

function getBusStation() {
  return 'select-mention';
}

export const subscribeSelectMention = (callback) => {
  return subscribe(getBusStation(), callback);
};

export const broadcastSelectMention = (mention) => {
  broadcast(getBusStation(), mention);
};
