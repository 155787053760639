import { useAsObservableSource, useLocalStore } from 'mobx-react';

import dateService from 'APP/packages/date';

export function usePresenter({ message }) {
  const source = useAsObservableSource({ message });

  const presenter = useLocalStore(() => ({
    get message() {
      return source.message;
    },

    get payloads() {
      return presenter.message.renderPayload.payloads;
    },

    get title() {
      return presenter.message.renderPayload.title;
    },

    get date() {
      const day = presenter.message.serverTime || presenter.message.clientTime;

      return dateService.format(day, {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      });
    },
  }));

  return presenter;
}
