import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { FileState as SharedFileIcon } from 'MAIN/components/FileState/FileState';
import FilePayload from 'STORE/Messages/Message/Payload/File';
import { FileName } from 'UIKIT/FileName/FileName';

import { useFileItemPresenter } from './FileItem.presenter';
import styles from './FileItem.styles.m.css';

interface IFileItem {
  id: string;
  messageId: string;
  groupId: string;
  payload: FilePayload;
  serverTime: string;
}

export const FileItem: FC<IFileItem> = observer((props) => {
  const { id: payloadId, groupId, payload, serverTime, messageId } = props;

  const { fileName } = payload;
  const presenter = useFileItemPresenter({
    serverTime,
    payload,
    payloadId,
    groupId,
    messageId,
  });

  return (
    <div className={styles.container}>
      <SharedFileIcon
        groupId={groupId}
        messageId={messageId}
        payloadId={payloadId}
        onClick={presenter.onDownload}
      />
      <div className={styles.info}>
        <FileName
          className={styles.title}
          name={fileName}
        />
        <p className={styles.description}>{presenter.formattedBytes}</p>
      </div>
    </div>
  );
});
