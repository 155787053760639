import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Group } from 'STORE/Groups/Group';
import { Avatar } from 'UIKIT/Avatar/Avatar';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';

import { AvatarWithFullScreen } from '../AvatarWithFullScreen/AvatarWithFullScreen';

interface IGroupAvatarProps {
  className?: string;
  group: Group | null;
  type?: AvatarTypes;
  isShowOnlineIndicator?: boolean;
  hasLockIcon?: boolean;
  withFullScreen?: boolean;
}

export const GroupAvatar: FC<IGroupAvatarProps> = observer(
  ({
    className,
    group,
    type = AvatarTypes.Round_10,
    isShowOnlineIndicator,
    hasLockIcon,
    withFullScreen,
  }) => {
    const avatar = (
      <Avatar
        className={!withFullScreen ? className : undefined}
        type={type}
        url={group?.avatarUrl}
        title={group?.avatarTitle || undefined}
        backgroundColorIndex={group?.avatarColorIndex}
        isOnline={isShowOnlineIndicator && group?.isOnlineOpponent}
        hasLockIcon={hasLockIcon}
      />
    );

    if (withFullScreen && !group?.isSavedMessages) {
      return (
        <AvatarWithFullScreen
          className={className}
          url={group?.avatarUrl || undefined}
        >
          {avatar}
        </AvatarWithFullScreen>
      );
    }

    return avatar;
  }
);
