import { useLocalStore } from 'mobx-react';

import { openPopup } from 'APP/Tasks/app/popup/popup';
import { scheduledEventModel } from 'APP/model/model';
import dateService from 'APP/packages/date';
import { useTranslation } from 'APP/packages/translations';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';

import { ScheduledCallSuccessPopup } from '../ScheduledCallSuccessPopup/ScheduledCallSuccessPopup';
import {
  CallReminderTime,
  END_DATE_GAP,
  ScheduledCallsFormFields,
} from '../ScheduledCalls.constants';
import { IScheduledCallsFormValues } from '../ScheduledCalls.types';
import { getClosestQuoterTime, getDateWithTime } from '../ScheduledCalls.utils';

export interface IScheduledCallCreatePopupProps {
  groupId: string;
}

interface IScheduledCallCreatePopupPresenter {
  hasBackButton: boolean;
  initialValues: Partial<IScheduledCallsFormValues>;
  onSubmit(values: IScheduledCallsFormValues): Promise<void>;
  onClose(): void;
  onBack(): void;
}

export function useScheduledCallCreatePopupPresenter({
  popupInstance,
  onClose,
  onBack,
  params,
}: ICommonPopupsProps<IScheduledCallCreatePopupProps>): IScheduledCallCreatePopupPresenter {
  const { t } = useTranslation();

  const presenter = useLocalStore<IScheduledCallCreatePopupPresenter>(() => ({
    get initialValues(): Partial<IScheduledCallsFormValues> {
      const closestQuoterTimeDate = getClosestQuoterTime();

      const startTime = dateService.time(closestQuoterTimeDate, true);
      const endDate = new Date(closestQuoterTimeDate.getTime() + END_DATE_GAP);
      const endTime = dateService.time(endDate, true);

      return {
        [ScheduledCallsFormFields.StartTsDate]: closestQuoterTimeDate,
        [ScheduledCallsFormFields.StartTsTime]: startTime || '',
        [ScheduledCallsFormFields.EndTsDate]: endDate,
        [ScheduledCallsFormFields.EndTsTime]: endTime || '',
        [ScheduledCallsFormFields.Reminder]: CallReminderTime.TenMinutes,
      };
    },

    async onSubmit(values: IScheduledCallsFormValues): Promise<void> {
      if (!params?.groupId) {
        return;
      }

      const startTs = getDateWithTime(
        values[ScheduledCallsFormFields.StartTsDate],
        values[ScheduledCallsFormFields.StartTsTime]
      );
      const endTs = getDateWithTime(
        values[ScheduledCallsFormFields.EndTsDate],
        values[ScheduledCallsFormFields.EndTsTime]
      );

      const startDate = values[ScheduledCallsFormFields.StartTsDate];

      const isInThisYear = startDate.getFullYear() !== dateService.now().getFullYear();
      const nameDate = dateService.format(startTs, {
        year: isInThisYear ? 'numeric' : undefined,
        weekday: 'short',
        day: 'numeric',
        month: 'long',
        hour: 'numeric',
        minute: 'numeric',
      });

      const name =
        values[ScheduledCallsFormFields.Name] ||
        `${t('call_scheduled_call')} ${nameDate} ${dateService.getDurationText(endTs - startTs)}`;

      const data = {
        name: values[ScheduledCallsFormFields.Name] || name,
        startTs,
        endTs,
        reminder: values[ScheduledCallsFormFields.Reminder],
        groupId: params.groupId,
      };

      const response = await scheduledEventModel.createScheduledEvent(data);
      presenter.onClose();
      openPopup(ScheduledCallSuccessPopup, {
        params: { eventId: response.id, eventDetails: response },
      });
    },

    get hasBackButton(): boolean {
      return !!onBack;
    },

    onClose(): void {
      popupInstance.close();
      onClose?.();
    },

    onBack(): void {
      popupInstance.close();
      onBack?.();
    },
  }));

  return presenter;
}
