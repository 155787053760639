import { GetUploadUrlRequest } from 'APP/packages/api/types/model/getUploadUrlRequest';
import { UploadUrlResponse } from 'APP/packages/api/types/model/uploadUrlResponse';

import { request } from '../request/request';

const ROOT_API = 'upload';

export const getUploadUrl = (data: GetUploadUrlRequest): Promise<UploadUrlResponse> => {
  return request<UploadUrlResponse>({
    rootApi: ROOT_API,
    url: '/getUploadUrl',
    method: 'POST',
    body: data,
  });
};
