import { IOnboardingItem } from 'STORE/Onboarding/Onboarding.types';

import { localStorageInstance } from '../instances/localStorageInstance';

const KEY_NAME = 'onboarding';

export const set = (data: IOnboardingItem[]): void => {
  return localStorageInstance.setItem(KEY_NAME, JSON.stringify(data));
};

export const get = (): IOnboardingItem[] => {
  const data = localStorageInstance.getItem(KEY_NAME);

  if (data) {
    return JSON.parse(data);
  }

  return [];
};

export const remove = (): void => {
  return localStorageInstance.removeItem(KEY_NAME);
};
