import { useLocalStore } from 'mobx-react';
import { FC, useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { openPopup } from 'APP/Tasks/app/popup/popup';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import Entities from 'STORE';
import { Popup } from 'STORE/App/Popups/Popup/Popup';

import { SessionsItemDetailsPopup } from '../SessionsItemDetailsPopup/SessionsItemDetailsPopup';
import { SessionsSelfDestructPopup } from '../SessionsSelfDestructPopup/SessionsSelfDestructPopup';

interface ISessionsPopupPresenter {
  childPopup: Popup | null;
  sessions: any[]; // TODO: add typings
  currentSession: any;
  isLoading: boolean;
  sessionTtl: any;
  hasBackButton: boolean;
  onClose(): void;
  onBack(): void;
  onOpenSession(session: any): void;
  onOpenSelfDestruct(session: any): void;
  openChildPopup<T>(Component: FC<ICommonPopupsProps<T>>, params?: T): void;
}

export function useSessionsPopupPresenter({
  popupInstance,
  onClose,
  onBack,
}: ICommonPopupsProps): ISessionsPopupPresenter {
  const presenter = useLocalStore<ISessionsPopupPresenter>(() => ({
    childPopup: null,

    get sessions(): any[] {
      return Entities.sessionsStore.sessions.sort(
        (a, b) => parseInt(b.lastOnline) - parseInt(a.lastOnline)
      );
    },

    get currentSession(): any {
      return Entities.sessionsStore.currentSession;
    },

    get isLoading(): boolean {
      return !Entities.sessionsStore.firstTimeLoaded;
    },

    get sessionTtl(): any {
      return Entities.sessionsStore.sessionTTl;
    },

    get hasBackButton(): boolean {
      return !!onBack;
    },

    onClose(): void {
      popupInstance.close();
      onClose?.();
    },

    onBack(): void {
      popupInstance.close();
      onBack?.();
    },

    onOpenSession(session: any): void {
      presenter.openChildPopup(SessionsItemDetailsPopup, { sessionKey: session.registrationKey });
    },

    onOpenSelfDestruct(): void {
      presenter.openChildPopup(SessionsSelfDestructPopup);
    },

    openChildPopup<T>(Component: FC<ICommonPopupsProps<T>>, params?: T): void {
      popupInstance.hide();

      presenter.childPopup = openPopup(Component, {
        params,
        onClose: () => presenter.onClose,
        onBack: () => popupInstance.show(),
      });
    },
  }));

  useEffect(() => {
    Tasks.userSettings.getSessionsInfo();

    return () => presenter.childPopup?.close();
  }, []);

  return presenter;
}
