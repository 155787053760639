import { showConfirm } from 'APP/Tasks/app/showConfirm/showConfirm';
import { langService } from 'MODULE/i18n/service';
import Entities from 'STORE';

export default async () => {
  const isVoiceRecording = Entities.InputPanel.voice.isRecording;
  const isVoiceRecorded = Entities.InputPanel.voice.isPreviewMode;

  if (!isVoiceRecording && !isVoiceRecorded) {
    return true;
  }

  const confirmResult = await showConfirm({
    text: langService.i18n.lang.common_voice_message_cancel_confirmation,
    primaryText: langService.i18n.lang.common_ok,
    secondaryText: langService.i18n.lang.cancel,
  });

  if (confirmResult) {
    Entities.InputPanel.voice.reset();
    return true;
  }

  return false;
};
