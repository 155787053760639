import Tasks from 'APP/Tasks';
import { showConfirm } from 'APP/Tasks/app/showConfirm/showConfirm';
import { useTranslation } from 'APP/packages/translations';
import { RouterLink } from 'APP/router/constants';
import { navigateTo } from 'APP/router/refs/navigateTo';

interface IDeleteAccountPresenter {
  onDelete(): Promise<void>;
}

export const useDeleteAccountPresenter = (): IDeleteAccountPresenter => {
  const { t } = useTranslation();

  const onDelete = async (): Promise<void> => {
    const isConfirm = await showConfirm({
      text: t('delete_account_confirmation'),
      primaryText: t('delete'),
      secondaryText: t('common_cancel'),
    });

    if (isConfirm) {
      await Tasks.userSettings.deleteAccount();

      navigateTo({
        to: RouterLink.login,
      });
    }
  };

  return { onDelete };
};
