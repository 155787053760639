import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { addAlert } from 'APP/Tasks/app/showAlert/showAlert';
import { ALERT_TYPES } from 'APP/constants/app';
import {
  CallEventTypes,
  ClientRole,
  PERMISSIONS_REQUEST_TYPES,
  RecordingEventTypes,
} from 'APP/constants/calls';
import { mapCallMemberResponse } from 'APP/model/call/callModel.mapper';
import { PermissionsRequestStatuses } from 'APP/model/call/callModel.types';
import { PermissionsTypes } from 'APP/model/callPermissions/callPermissionsModel.constants';
import { UnauthorizedCallType } from 'APP/model/unauthorizedCall/unauthorizedCallModel.constants';
import logger from 'APP/packages/logger';
import { initCallListener } from 'APP/packages/streamApi';
import { useTranslation } from 'APP/packages/translations';
import { RouterLink } from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import Entities from 'APP/store';
import { MemberState } from 'STORE/Calls/Call/Member/MemberState';

const SSE_RECONNECT_INTERVAL = 3 * 1000;

export default () => {
  const { t } = useTranslation();
  const navigateTo = useNavigateTo();
  const presenter = useLocalStore(() => ({
    disposeListener: null,
    timeout: null,

    get call() {
      return Entities.Calls.ongoingCall;
    },

    onActiveCallData: (activeCall) => {
      const { channel, status, groupId, callType } = activeCall;

      Tasks.calls.updateActiveCall({
        status,
        groupId: groupId.toString(),
        channelId: channel,
        callType,
      });
    },

    onActionData: (data) => {
      const userId = data.userId.toString();
      const platforms = data.platforms || [];
      const call = presenter.call;

      switch (data.action) {
        case CallEventTypes.ForceMute: {
          Tasks.calls.audioMuting.forceMute(data);
          break;
        }
        case CallEventTypes.RaiseHand: {
          call.events.addEvent({ ...data, type: data.action });
          call.members.getMemberById(userId).setRaiseHand(true);
          break;
        }
        case CallEventTypes.PutHand: {
          const member = call.members.getMemberById(userId);

          if (member) {
            member.setRaiseHand(false);
          }
          break;
        }
        case CallEventTypes.ForceFinish: {
          if (userId === presenter.call.me.id && platforms.includes('web')) {
            Tasks.feedback.checkStarsFeedback(call.channelId);
            call.delete();
            if (call.isGuestCall) {
              navigateTo({
                to: call.isP2P ? RouterLink.callFinished : RouterLink.callFinishedGroup,
                params: {
                  id: call.groupId,
                  callType: call.isP2P ? UnauthorizedCallType.CallMe : UnauthorizedCallType.Call,
                  channelId: call.channelId,
                },
              });
            }
          } else {
            call.events.addEvent({ ...data, type: CallEventTypes.ForceFinish });
          }
          break;
        }
      }
    },

    onUserData: async (data) => {
      const { status, userId } = data;
      const call = presenter.call;
      if (!call) {
        return;
      }
      const member = call.members.getMemberById(userId.toString());
      switch (status) {
        case CallEventTypes.Joined: {
          if (member) {
            if (member.state !== MemberState.OnCall) {
              call.events.addEvent({
                uid: member.uid,
                type: CallEventTypes.Joined,
              });
            }

            if (!member.isMe) {
              Tasks.calls.addOpponentToCall({ uid: member.uid, opponentRole: ClientRole.Audience });
            }
            member.setState(MemberState.OnCall);
          }
          break;
        }
        case CallEventTypes.Declined: {
          if (member) {
            member.setState(MemberState.Declined);
            if (call.isP2P && !member.isMe && member.state !== MemberState.OnCall) {
              call.startBusy();
            }
            if (
              call.isGuestCall &&
              call.isP2P &&
              !member.isMe &&
              member.state !== MemberState.OnCall
            ) {
              addAlert({
                type: ALERT_TYPES.TRY_CALL_AGAIN_LATER,
              });
            }
          }
          break;
        }
        case CallEventTypes.Finished: {
          if (member) {
            const opponent = call.opponents.getOpponent(member.uid);
            // sometimes the server sends a false FINISH event,
            // so it is necessary to check if the user has really left
            // (agora has changed the opponent's ROLE to a listener on 'left' event in Tasks.calls.userLeft)
            if (opponent && opponent.role === ClientRole.Audience) {
              call.opponents.deleteOpponent(member.uid);
              if (member.state === MemberState.OnCall) {
                call.events.addEvent({
                  uid: member.uid,
                  type: CallEventTypes.Finished,
                });
              }

              member.setState(MemberState.Left);
            }
            if (call.isP2P && !member.isMe) {
              Tasks.feedback.checkStarsFeedback(call.channelId);
              call.delete();
              if (call.isGuestCall) {
                navigateTo({
                  to: call.isP2P ? RouterLink.callFinished : RouterLink.callFinishedGroup,
                  params: {
                    id: call.groupId,
                    callType: call.isP2P ? UnauthorizedCallType.CallMe : UnauthorizedCallType.Call,
                    channelId: call.channelId,
                  },
                });
              }
            }
          }
          break;
        }
        case CallEventTypes.Added: {
          const userId = data.userId.toString();

          try {
            await Tasks.relationships.loadUnknownUsersByIds({
              usersIds: [userId],
            });
            call.members.setMembers(mapCallMemberResponse([{ ...data.user, userId }]));
            call.events.addEvent({ userId, type: CallEventTypes.Added });
          } catch (e) {
            logger.get('CallStateHandler').debug('presenter.loadUnknownUsersByIds', e);
          }
          break;
        }
        case CallEventTypes.Removed: {
          const member = call.members.getMemberById(data.userId.toString());
          if (!member) {
            break;
          }

          if (member.isMe && call.isGuestCall) {
            call.delete();
            navigateTo({
              to: RouterLink.callFinishedGroup,
              params: {
                id: call.groupId,
                callType: UnauthorizedCallType.Call,
                channelId: call.channelId,
              },
            });
            break;
          }

          call.events.addEvent({ ...data, type: CallEventTypes.Removed });

          if (member.shareScreenUid) {
            call.opponents.deleteOpponent(member.shareScreenUid);
          }

          call.opponents.deleteOpponent(member.uid);
          call.members.deleteMember(member.uid);
          break;
        }
      }
    },

    async onRecordingData(data) {
      const { event, recordingId, recorderId } = data;

      const call = presenter.call;
      if (!call) {
        return null;
      }

      switch (event) {
        case RecordingEventTypes.RecordingStarted:
          {
            const recorder = Entities.UsersStore.getUserById(recorderId);
            if (recorder) {
              const snackbarText = t('calls_recording_started_snackbar').replace(
                '{0}',
                recorder.userName
              );

              Entities.toast.show(snackbarText, { durationMs: 5000 });
            }

            call.startRecording(recordingId);

            if (call.me.isSharedScreen) {
              await Tasks.calls.sendStartScreenSharingEvent({
                channelId: call.channelId,
                recordingId,
              });
            }
          }
          break;

        case RecordingEventTypes.RecordingStopped:
          call.stopRecording();
          break;
      }
    },

    onPermissionsData: (data) => {
      const changedPermissionType = Object.values(PermissionsTypes).find((type) => !!data[type]);
      if (!changedPermissionType) {
        return;
      }

      presenter.call.permissions.setPermissions(data);

      if (presenter.call.isMeInitiatorOwnerOrAdmin) {
        presenter.call.events.addEvent({
          type: CallEventTypes.PermissionsGlobalChanged,
          permissionType: changedPermissionType,
          permissionValue: data.default[changedPermissionType],
        });
      } else {
        presenter.call.events.addEvent({
          type: CallEventTypes.PermissionsGlobalMemberChanged,
          permissionType: changedPermissionType,
          permissionValue: data[changedPermissionType],
        });
      }
    },

    setCustomMemberPermissions: (data) => {
      const customPermissions = presenter.call.permissions.customPermissions.get(data.userId);

      const currentPermissions = customPermissions ? { ...customPermissions } : {};

      Object.values(PermissionsTypes).forEach((key) => {
        if (data[key]) {
          currentPermissions[key] = data[key];
        }
      });

      presenter.call.permissions.setCustomUserPermissions(data.userId, currentPermissions);
    },

    setPersonalizedMemberPermissions: (data) => {
      const permissions = presenter.call.permissions.personalizedPermissions;
      const changedPermissions = {};

      Object.values(PermissionsTypes).forEach((key) => {
        if (data[key]) {
          permissions[key] = data[key];
          changedPermissions[key] = data[key];
        }
      });

      presenter.call.permissions.setPersonalizedPermissions(permissions);

      return changedPermissions;
    },

    onMemberPermissionsData: (data) => {
      if (presenter.call.isMeInitiatorOwnerOrAdmin) {
        if (data.purged) {
          presenter.call.permissions.deleteCustomUserPermissions(data.userId);
          return;
        }

        presenter.setCustomMemberPermissions(data);
      } else {
        if (data.purged) {
          presenter.call.permissions.setPersonalizedPermissions({});

          return;
        }

        const changedPermissions = presenter.setPersonalizedMemberPermissions(data);

        Object.keys(changedPermissions).forEach((item) => {
          presenter.call.events.addEvent({
            type: CallEventTypes.PermissionsMemberChanged,
            permissionType: item,
            permissionValue: changedPermissions[item],
          });
        });
      }
    },

    onCreatePermissionsRequestData: (data) => {
      const requestType = Object.keys(PERMISSIONS_REQUEST_TYPES).find((type) => {
        return data.access === PERMISSIONS_REQUEST_TYPES[type];
      });

      if (!requestType || !presenter.call.isMeInitiatorOwnerOrAdmin) {
        return;
      }

      presenter.call.permissions.addPermissionsRequest({
        userId: data.userId,
        requestType,
      });

      presenter.call.events.addEvent({
        type: CallEventTypes.PermissionsRequestCreated,
        userId: data.userId,
        requestType,
      });
    },

    onChangePermissionRequestData: (data) => {
      const requestTypes = Object.keys(PERMISSIONS_REQUEST_TYPES).reduce((acc, type) => {
        if (data[type]) {
          acc.push(type);
        }
        return acc;
      }, []);

      if (!requestTypes.length) {
        return;
      }

      if (presenter.call.isMeInitiatorOwnerOrAdmin) {
        requestTypes.forEach((type) => {
          presenter.call.permissions.deletePermissionsRequest({
            userId: data.userId,
            requestType: type,
          });
        });

        if (data.status === PermissionsRequestStatuses.Approved) {
          presenter.setCustomMemberPermissions(data);
        }
      } else {
        const changedPermissions = presenter.setPersonalizedMemberPermissions(data);

        Object.keys(changedPermissions).forEach((item) => {
          presenter.call.events.addEvent({
            type:
              data.status === PermissionsRequestStatuses.Approved
                ? CallEventTypes.PermissionsMemberChanged
                : CallEventTypes.PermissionsRequestDeclined,
            permissionType: item,
            permissionValue: changedPermissions[item],
          });
        });
      }
    },

    onStatusCallChanged(data) {
      // TODO: [calls] check ability to move logic for common call from /events/call to here
      const { status } = data;
      const call = presenter.call;

      if (status !== CallEventTypes.Finished || !call || !call.isGuestCall) {
        return;
      }
      Tasks.feedback.checkStarsFeedback(call.channelId);
      call.delete();
      navigateTo({
        to: call.isP2P ? RouterLink.callFinished : RouterLink.callFinishedGroup,
        params: {
          id: call.groupId,
          callType: call.isP2P ? UnauthorizedCallType.CallMe : UnauthorizedCallType.Call,
          channelId: call.channelId,
        },
      });
    },

    connect() {
      if (presenter.call?.channelId && !presenter.disposeListener) {
        presenter.disposeListener = initCallListener({
          channelId: presenter.call.channelId,
          isGuestCall: presenter.call.isGuestCall,
          onActionCallData: presenter.onActionData,
          onUserCallData: presenter.onUserData,
          onActiveCallData: presenter.onActiveCallData,
          onRecordingData: presenter.onRecordingData,
          onPermissionsData: presenter.onPermissionsData,
          onMemberPermissionsData: presenter.onMemberPermissionsData,
          onCreatePermissionsRequestData: presenter.onCreatePermissionsRequestData,
          onChangePermissionRequestData: presenter.onChangePermissionRequestData,
          onStatusCallChanged: presenter.onStatusCallChanged,
          onError: presenter.onError,
        });
      }
    },

    disconnect() {
      if (presenter.disposeListener) {
        presenter.disposeListener();
        presenter.disposeListener = null;
      }
    },

    async onError(err) {
      try {
        presenter.disconnect();
        // Check for error code in sse. I did not find another possibility in the specification
        // https://html.spec.whatwg.org/multipage/server-sent-events.html#the-eventsource-interface
        const controller = new AbortController();
        const { signal } = controller;
        const { status } = await fetch(err.target.url, { signal });
        controller.abort();
        if (status === 403 && presenter.call) {
          Tasks.feedback.checkStarsFeedback(presenter.call.channelId);
          presenter.call.delete();
        } else {
          setTimeout(() => {
            presenter.connect();
          }, SSE_RECONNECT_INTERVAL);
        }
      } catch (e) {
        if (presenter.call) {
          setTimeout(() => {
            presenter.connect();
          }, SSE_RECONNECT_INTERVAL);
        }
        logger.get('SSE').error('CallStateHandler.onError', e);
      }
    },
  }));

  useEffect(() => {
    presenter.connect();
    return () => {
      presenter.disconnect();
    };
  }, [presenter.call?.channelId]);

  useEffect(() => {
    if (Entities.AuthStore.streamToken) {
      presenter.connect();
    }
    if (!Entities.AuthStore.streamToken) {
      presenter.call.delete();
    }
  }, [Entities.AuthStore.streamToken]);
};
