import { CreateMessageDeepLinkRequest } from 'APP/packages/api/types/model/createMessageDeepLinkRequest';
import { CreateMessageDeepLinkResponse } from 'APP/packages/api/types/model/createMessageDeepLinkResponse';
import { DeepLinkResponse } from 'APP/packages/api/types/model/deepLinkResponse';
import { DeepLinkUrlRequest } from 'APP/packages/api/types/model/deepLinkUrlRequest';

import { request } from '../request/request';

const ROOT_API = 'deeplinks';

export const getDeepLink = (data: DeepLinkUrlRequest): Promise<DeepLinkResponse> => {
  return request({
    rootApi: ROOT_API,
    url: '/getDeepLink',
    method: 'POST',
    body: data,
  });
};

export const createMessageDeepLink = (
  data: CreateMessageDeepLinkRequest
): Promise<CreateMessageDeepLinkResponse> => {
  return request({
    rootApi: ROOT_API,
    url: '/createMessageDeepLink',
    method: 'POST',
    body: data,
  });
};
