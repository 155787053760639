export enum IconViewSizes {
  Auto = 'auto',
  Big = 'big',
  Large = 'large',
  XLarge = 'xlarge',
  XXLarge = 'xxlarge',
  Medium = 'medium',
  Small = 'small',
  XSmall = 'xsmall',
}
