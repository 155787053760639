import React from 'react';

import CancelFullScreenIcon from 'ICONS/ic-cancel-full-screen.svg';
import FullScreenIcon from 'ICONS/ic-full-screen.svg';
import MiniPlayerIcon from 'ICONS/ic-miniplayer-web.svg';
import PauseIcon from 'ICONS/ic-pause.svg';
import PlayIcon from 'ICONS/ic-play.svg';
import { IconButton } from 'UIKIT/IconButton';

import { MediaPlayerProgress } from '../../components/MediaPlayerProgress/MediaPlayerProgress';
import { VideoPlayerType } from '../VideoPlayer.constants';
import { VideoPlayerSpeed } from '../VideoPlayerSpeed/VideoPlayerSpeed';
import { VideoPlayerTime } from '../VideoPlayerTime/VideoPlayerTime';
import { VideoPlayerVolume } from '../VideoPlayerVolume/VideoPlayerVolume';
import styles from './VideoPlayerDefaultControls.styles.m.css';

export const VideoPlayerDefaultControls = ({
  isPlaying,
  onPlay,
  onPause,
  onTogglePlay,
  progress,
  progressMax,
  progressBuffer,
  onSetProgress,
  onProgressSelection,
  isMuted,
  volume,
  onToggleMute,
  onSetVolume,
  onVolumeSelection,
  speed,
  setSpeed,
  onToggleSpeedPopover,
  onShowMiniPlayer,
  isFullScreenMode,
  onToggleFullScreenMode,
}) => {
  return (
    <div className={styles.controls}>
      <MediaPlayerProgress
        className={styles.progress}
        isPlaying={isPlaying}
        progress={progress}
        progressMax={progressMax}
        progressBuffer={progressBuffer}
        onPlay={onPlay}
        onPause={onPause}
        onProgress={onSetProgress}
        onProgressSelection={onProgressSelection}
      />

      <div className={styles.actionsPanel}>
        <div className={styles.actions}>
          <IconButton
            className={styles.actionButton}
            Icon={isPlaying ? PauseIcon : PlayIcon}
            size="x-small"
            iconSize="medium"
            theme="19"
            onClick={onTogglePlay}
          />

          <VideoPlayerVolume
            isMuted={isMuted}
            volume={volume}
            onToggleMute={onToggleMute}
            onSetVolume={onSetVolume}
            onVolumeSelection={onVolumeSelection}
          />

          <VideoPlayerTime
            type={VideoPlayerType.Default}
            progress={progress}
            progressMax={progressMax}
          />
        </div>

        <div className={styles.actions}>
          <VideoPlayerSpeed
            currentSpeed={speed}
            onSpeedChange={setSpeed}
            onToggleSpeedPopover={onToggleSpeedPopover}
          />

          {onShowMiniPlayer && (
            <IconButton
              className={styles.actionButton}
              Icon={MiniPlayerIcon}
              size="x-small"
              iconSize="medium"
              theme="19"
              onClick={onShowMiniPlayer}
            />
          )}

          <IconButton
            className={styles.actionButton}
            Icon={!isFullScreenMode ? FullScreenIcon : CancelFullScreenIcon}
            size="x-small"
            iconSize="medium"
            theme="19"
            onClick={onToggleFullScreenMode}
          />
        </div>
      </div>
    </div>
  );
};
