import React, { memo } from 'react';

import { TPlayIconTheme } from 'UIKIT/PlayIcon/PlayIcon.types';

import styles from '../PlayIcon.styles.m.css';

interface IPlayIconRegularProps {
  theme: TPlayIconTheme;
}

export const PlayIconRegular = memo<IPlayIconRegularProps>((props) => {
  const { theme } = props;

  return (
    <>
      <circle
        cx="24"
        cy="24"
        r="20"
        className={styles[`play-icon-bkg-${theme}`]}
      />
    </>
  );
});
