import { spaceModel } from 'APP/model/model';
import { spaceAnalytics } from 'APP/packages/analytics/analytics';
import Entities from 'STORE';
import { Group } from 'STORE/Groups/Group';
import { Space } from 'STORE/Spaces/Space';

interface ITrackItem {
  group: Group | null;
  space: Space | null;
}

function trackRemoveEvent(trackData: ITrackItem[]): void {
  trackData.forEach((item) => {
    if (!item.group || !item.space) {
      return;
    }

    if (item.group.isChatGroup) {
      spaceAnalytics.trackSpaceGroupDeleted(
        item.space.id,
        item.space.role,
        item.group.id,
        item.group.type
      );
    } else if (item.group.isChannel) {
      spaceAnalytics.trackSpaceChannelDeleted(
        item.space.id,
        item.space.role,
        item.group.id,
        item.group.type
      );
    }
  });
}

export async function removeGroupsFromSpace(groupIds: string[]): Promise<boolean> {
  try {
    const amplitudeData: ITrackItem[] = groupIds.map((groupId) => {
      const group = Entities.GroupsStore.getGroupById(groupId);
      const space = group?.spaceId ? Entities.spacesStore.getById(group.spaceId) : null;
      return { group, space };
    });

    const response = await spaceModel.unlinkGroups(groupIds);

    if (!response.result) {
      return false;
    }

    trackRemoveEvent(amplitudeData);

    groupIds.forEach((groupId) => Entities.GroupsStore.getGroupById(groupId)?.setSpaceId(null));

    return true;
  } catch {
    return false;
  }
}
