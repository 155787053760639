import React, { FC } from 'react';

import { AddKeyPressHandler } from 'APP/packages/keyBoardHandler';
import ArrowLeftIcon from 'ICONS/ic-arrow-left.svg';
import ArrowRightIcon from 'ICONS/ic-arrow-right.svg';
import CloseIcon from 'ICONS/ic-close.svg';
import { FAB } from 'UIKIT/FAB/FAB';
import { FullscreenPopup } from 'UIKIT/FullscreenPopup/FullscreenPopup';
import { IconButton, IconViewSizes } from 'UIKIT/IconButton';

import { useStoriesPresenter } from './Stories.presenter';
import styles from './Stories.styles.m.css';
import { IStory } from './Stories.types';
import { StoriesProgress } from './StoriesProgress/StoriesProgress';
import { Story } from './Story/Story';

interface IStoriesProps {
  stories: IStory[];
  onEnd?(): void;
  onClose?(): void;
}

export const Stories: FC<IStoriesProps> = ({ stories, onEnd, onClose }) => {
  const {
    contentRef,
    activeStory,
    activeStoryIndex,
    activeStorySize,
    hasNext,
    hasPrevious,
    progress,
    onPrevious,
    onNext,
    onProgress,
  } = useStoriesPresenter({ stories, onEnd });

  return (
    <FullscreenPopup className={styles.fullscreenPopup}>
      <AddKeyPressHandler onEsc={onClose} />

      <div
        className={styles.content}
        ref={contentRef}
      >
        <FAB
          className={!hasPrevious ? styles.hidden : undefined}
          Icon={ArrowLeftIcon}
          size="40"
          theme="2_i"
          onClick={onPrevious}
        />

        <div
          className={styles.story}
          style={
            activeStorySize
              ? { width: `${activeStorySize.width}px`, height: `${activeStorySize.height}px` }
              : undefined
          }
        >
          <StoriesProgress
            className={styles.progress}
            count={stories.length}
            activeIndex={activeStoryIndex}
            activeProgress={progress}
          />

          <Story
            story={activeStory}
            onProgress={onProgress}
          />
        </div>

        <FAB
          className={!hasNext ? styles.hidden : undefined}
          Icon={ArrowRightIcon}
          size="40"
          theme="2_i"
          onClick={onNext}
        />
      </div>

      <IconButton
        className={styles.closeButton}
        Icon={CloseIcon}
        size={IconViewSizes.Big}
        iconSize={IconViewSizes.Medium}
        theme="11"
        onClick={onClose}
      />
    </FullscreenPopup>
  );
};
