import Tasks from 'APP/Tasks';
import Entities from 'STORE';

export const findGroupCallAndRun = (groupId: string): void => {
  const groupCall = Entities.BackgroundCalls.getCallByGroupId(groupId);

  if (groupCall) {
    Tasks.calls.showConfirmRunCall({
      call: groupCall,
      groupId,
    });
  }
};
