import React, { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { useClosePopupOnNavigation } from '../../hooks/useClosePopupOnNavigation';
import { useUserAccountsPopupPresenter } from './UserAccountsPopup.presenter';
import { UserAccountsPopupView } from './UserAccountsPopupView/UserAccountsPopupView';

export const UserAccountsPopup: FC<ICommonPopupsProps> = (props) => {
  const presenter = useUserAccountsPopupPresenter(props);
  useClosePopupOnNavigation(props.popupInstance);

  return (
    <Popup
      isOpened
      isHidden={props.popupInstance.isHidden}
      dataTest="user-accounts-popup"
      onClose={presenter.onCloseHandler}
    >
      <UserAccountsPopupView
        popupInstance={props.popupInstance}
        onBack={presenter.hasBackButton ? presenter.onBackHandler : null}
        onClose={presenter.onCloseHandler}
      />
    </Popup>
  );
};
