import classNames from 'classnames';
import React, { FC } from 'react';

import { Opponent } from 'STORE/Calls/Call/Opponent/Opponent';
import { Avatar, AvatarTypes } from 'UIKIT/Avatar/Avatar';

import styles from './NoVideoPlaceholder.styles.m.css';

interface INoVideoPlaceholderProps {
  opponent: Opponent | null;
  type: AvatarTypes;
  className?: string;
}

export const NoVideoPlaceholder: FC<INoVideoPlaceholderProps> = ({ opponent, type, className }) => {
  if (!opponent) {
    return null;
  }

  return (
    <Avatar
      className={classNames(styles.container, className)}
      type={type}
      title={opponent.member?.displayName}
      url={opponent.member?.avatarUrl}
      backgroundColorIndex={opponent.member?.avatarColorIndex}
    />
  );
};
