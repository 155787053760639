import { action, observable, computed } from 'mobx';

import { replaceHtmlTagsToSpecialSymbols } from 'UTILS/html/replaceHtmlTagsToSpecialSymbols';

import Bots from './Bots';
import Mention from './Mention';

class InputPanel {
  @observable value = null;
  @observable editMessageId = null;
  @observable quoteMessage = null;

  @observable link = null;
  @observable linkPreviews = new Map();
  @observable isLoadingPreview = false;
  @observable linkPreviewCancelled = false;
  @observable isShowLinkPreview = false;

  constructor(root, groupId) {
    this.root = root;
    this.groupId = groupId;

    this.mention = new Mention(root, this);
    this.bots = new Bots(root, this);
  }

  @action
  setValue = (value) => {
    this.value = value;
  };

  @action
  setEditMessageId = (messageId) => {
    this.editMessageId = messageId;
  };

  @action
  setQuoteMessage = (message) => {
    this.quoteMessage = message;
  };

  @computed
  get isEmptyValue() {
    if (!this.value || !this.value.trim()) {
      return true;
    }

    // remove tags from text
    const div = document.createElement('div');
    div.innerHTML = this.value;
    const text = div.innerText;

    return !text || !text.trim();
  }

  @computed
  get markdownValue() {
    return replaceHtmlTagsToSpecialSymbols(this.value);
  }

  @computed
  get group() {
    return this.root.GroupsStore.getGroupById(this.groupId);
  }

  @computed
  get editMessage() {
    return this.group && this.group.messagesStore.getMessageById(this.editMessageId);
  }

  @computed
  get isEditMode() {
    return Boolean(this.editMessage);
  }

  @action
  setLink = (link) => {
    this.link = link;
  };

  @action
  setIsLoadingPreview = (isLoading) => {
    this.isLoadingPreview = isLoading;
  };

  @action
  setIsLinkPreviewCancelled = (value) => {
    this.linkPreviewCancelled = value;
  };

  @action
  setIsShowLinkPreview = (value) => {
    this.isShowLinkPreview = value;
  };
}

export default InputPanel;
