import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Tasks from 'APP/Tasks';
import { openPopup } from 'APP/Tasks/app/popup/popup';
import { ICategoryInfo } from 'APP/model/common/commonModel.types';
import { useTranslation } from 'APP/packages/translations';
import { RouterState } from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import LinkIcon from 'ICONS/ic-link.svg';
import ReportIcon from 'ICONS/ic-report.svg';
import { ReportSpacePopup } from 'MAIN/PopupManager/Popups/ReportSpacePopup/ReportSpacePopup';
import { useCopyToClipboard } from 'MAIN/Profile/hooks/useCopyToClipboard';
import { useSpaceActions } from 'MAIN/hooks/space/useSpaceActions';
import Entities from 'STORE';
import { Popup } from 'STORE/App/Popups/Popup/Popup';
import { Space } from 'STORE/Spaces/Space';
import { IPopoverMenuItem } from 'UIKIT/PopoverMenu/PopoverMenu.types';

interface ISpaceGuestProfileStore {
  childPopup: Popup | null;
  menuItems: IPopoverMenuItem[];
  category: ICategoryInfo | null;
  isJoining: boolean;
  isLeaving: boolean;
  canJoinSpace: boolean;
  canLeaveSpace: boolean;
  canReportSpace: boolean;
  loadCategory(): Promise<void>;
  onJoin(): Promise<void>;
  onLeave(): Promise<void>;
  onCopyLink(): void;
  onReport(): void;
}

export function useSpaceGuestProfilePresenter(space: Space): ISpaceGuestProfileStore {
  const { t } = useTranslation();
  const navigateTo = useNavigateTo();
  const source = useAsObservableSource({ space, navigateTo });
  const location = useLocation<Record<string, boolean | undefined>>();
  const copyToClipboard = useCopyToClipboard(space.deepLink || '', t('common_link_copied'));
  const spaceActions = useSpaceActions(space);

  const presenter = useLocalStore<ISpaceGuestProfileStore>(() => ({
    childPopup: null,
    category: null,
    isJoining: false,
    isLeaving: false,

    get menuItems(): IPopoverMenuItem[] {
      return [
        {
          key: 'copyLink',
          title: t('common_copy_invite_link'),
          icon: LinkIcon,
          onClick: presenter.onCopyLink,
          isVisible: !!source.space.deepLink,
        },
        {
          key: 'report',
          title: t('common_report'),
          icon: ReportIcon,
          onClick: presenter.onReport,
          isVisible: presenter.canReportSpace,
        },
      ].filter((item) => item.isVisible);
    },

    async loadCategory(): Promise<void> {
      presenter.category = await Tasks.search.getCategoryInfoById(source.space.categoryId);
    },

    get canJoinSpace(): boolean {
      if (!source.space.isGuest) {
        return false;
      }

      if (source.space.isJoinProcessing && !presenter.isJoining) {
        return false;
      }

      return true;
    },

    async onJoin(): Promise<void> {
      presenter.isJoining = true;

      const joinResult = await Tasks.space.joinSpace(
        source.space.id,
        !!location.state?.[RouterState.fromDeepLink]
      );

      if (!joinResult) {
        Entities.toast.show(t('common_somethings_wrong_error'));
      }

      presenter.isJoining = false;
    },

    get canLeaveSpace(): boolean {
      return spaceActions.canLeave;
    },

    async onLeave(): Promise<void> {
      presenter.isLeaving = true;
      await spaceActions.onLeave();
      presenter.isLeaving = false;
    },

    onCopyLink(): void {
      copyToClipboard();
    },

    get canReportSpace(): boolean {
      return source.space.isMember;
    },

    onReport(): void {
      presenter.childPopup = openPopup(ReportSpacePopup, {
        params: { spaceId: source.space.id },
      });
    },
  }));

  useEffect(() => {
    presenter.loadCategory();
  }, [space]);

  useEffect(() => {
    return () => presenter.childPopup?.close();
  }, []);

  return presenter;
}
