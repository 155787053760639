import { InviteeStatus } from 'APP/model/scheduledEvent/scheduledEventModel.constants';
import { CreateScheduledEventRequest } from 'APP/packages/api/types/model/createScheduledEventRequest';
import { GetScheduledEventsResponse } from 'APP/packages/api/types/model/getScheduledEventsResponse';
import { InviteeResponse } from 'APP/packages/api/types/model/inviteeResponse';
import { InviteesResponse } from 'APP/packages/api/types/model/inviteesResponse';
import { ScheduledEventDetailsResponse } from 'APP/packages/api/types/model/scheduledEventDetailsResponse';
import { ScheduledEventResponse } from 'APP/packages/api/types/model/scheduledEventResponse';
import { CallReminderTime } from 'MAIN/PopupManager/Popups/ScheduledCalls/ScheduledCalls.constants';

import {
  ICreateScheduledEventParams,
  IInvitee,
  IInvitees,
  IScheduledEventDetails,
  IScheduledEventInfo,
  IScheduledEventsList,
} from './scheduledEventModel.types';

export const mapScheduledEventInvitees = (invitees: InviteeResponse[]): IInvitee[] => {
  return invitees.map((item) => ({
    status: item.status as unknown as InviteeStatus,
    user: item.user,
  }));
};

export const mapScheduledEventInfo = (
  info: ScheduledEventDetailsResponse
): IScheduledEventDetails => {
  const { invitees, startTs, endTs, createdAt, updatedAt, ...rest } = info;
  return {
    ...rest,
    reminder: info.reminder ? (Number(info.reminder) as unknown as CallReminderTime) : null,
    startTs: Number(startTs),
    endTs: Number(endTs),
    createdAt: Number(createdAt),
    updatedAt: Number(updatedAt),
    invitees: {
      ...info.invitees,
      values: mapScheduledEventInvitees(info.invitees.values),
    },
  };
};

export const mapCreateScheduledEventParams = (
  params: ICreateScheduledEventParams
): CreateScheduledEventRequest => {
  return {
    ...params,
    startTs: String(params.startTs),
    endTs: String(params.endTs),
    reminder: params.reminder ? String(params.reminder) : undefined,
  };
};

export const mapScheduledEventListItem = (item: ScheduledEventResponse): IScheduledEventInfo => {
  return {
    ...item,
    startTs: Number(item.startTs),
    endTs: Number(item.endTs),
    createdAt: Number(item.createdAt),
    updatedAt: Number(item.updatedAt),
  };
};

export const mapScheduledEventListInfo = (
  info: GetScheduledEventsResponse
): IScheduledEventsList => {
  const { events, cursor } = info;

  return {
    cursor,
    events: events.map(mapScheduledEventListItem),
  };
};

export const mapScheduledEventInviteesResponse = (response: InviteesResponse): IInvitees => {
  return {
    cursor: response.cursor,
    values: mapScheduledEventInvitees(response.values),
  };
};
