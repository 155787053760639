import { useRef } from 'react';

import delayedCallback from '../utils/delayedCallback';

export default () => {
  const wasModified = useRef(false);
  const timeout = useRef();

  return ({ meta, value, values, validate, errorToText, data }) => {
    if (wasModified.current) {
      return validate({
        value,
        values,
        errorToText,
        data,
      });
    }

    // we need to async validate at the first time typing
    return delayedCallback(timeout, () => {
      if (!wasModified.current) {
        wasModified.current = meta.modified;
      }

      return validate({
        value,
        values,
        errorToText,
        data,
      });
    });
  };
};
