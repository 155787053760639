import { observer } from 'mobx-react';
import React, { useRef } from 'react';

import { FAB } from 'UIKIT/FAB/FAB';

import FileUploadButton from './FileUploadButton';
import FirstArticleOnboarding from './FirstArticleOnboarding';
import Menu from './Menu';
import usePresenter from './presenter';

const AttachmentsButton = () => {
  const { presenter, attachmentsButtonRef } = usePresenter();
  const fileUploadRef = useRef();

  return (
    <div>
      <FileUploadButton
        onSelectFile={presenter.uploadFile}
        ref={fileUploadRef}
      />
      <FAB
        size={'30'}
        onClick={presenter.toggleMenu}
        dataTest="attach-button"
        Icon={presenter.Icon}
        theme={presenter.isMenuOpen ? '8_1' : '8'}
        setRef={attachmentsButtonRef}
      />
      <Menu
        isOpened={presenter.isMenuOpen}
        fileUploadRef={fileUploadRef}
        attachButtonRef={attachmentsButtonRef}
        onClose={presenter.closeMenu}
      />
      {presenter.isShowOnboarding && <FirstArticleOnboarding reference={attachmentsButtonRef} />}
    </div>
  );
};

export default observer(AttachmentsButton);
