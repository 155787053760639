import { stickersModel } from 'APP/model/model';
import { storage } from 'APP/packages/storage';
import Entities from 'APP/store';

export async function loadUserInstalledStickerPacks(lastUpdateTime: number): Promise<void> {
  const { items = [] } = await stickersModel.getUserInstalledStickerPacks();

  const installedPackIds = items.map(({ stickerPack }) => stickerPack.id);

  Entities.stickersStore.mergePacks(items);

  Entities.stickersStore.setInstalledPackIds(installedPackIds);

  const packs = Entities.stickersStore.transformToStickerPacks(items);

  storage.installedStickerPacks.set({
    lastUpdateTime,
    packs,
  });
}
