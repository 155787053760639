import React from 'react';

import { IRenderer } from '../renderTree.types';

export const BoldAndItalicRenderer: IRenderer = ({ children }) => {
  if (!children) {
    return null;
  }

  return (
    <strong>
      <em>{children}</em>
    </strong>
  );
};
