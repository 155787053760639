import React, { memo } from 'react';

import { TPlayIconTheme } from 'UIKIT/PlayIcon/PlayIcon.types';

import styles from '../PlayIcon.styles.m.css';

interface IPlayIconErrorProps {
  theme: TPlayIconTheme;
}

export const PlayIconError = memo<IPlayIconErrorProps>((props) => {
  const { theme } = props;

  return (
    <>
      <circle
        cx="39"
        cy="39"
        r="9"
        className={styles[`play-icon-error-${theme}`]}
      />
      <path
        d="M40 42C40 42.5523 39.5523 43 39 43C38.4477 43 38 42.5523 38 42C38 41.4477 38.4477 41 39 41C39.5523 41 40 41.4477 40 42ZM40 39C40 39.5523 39.5523 40 39 40C38.4477 40 38 39.5523 38 39V36C38 35.4477 38.4477 35 39 35C39.5523 35 40 35.4477 40 36V39Z"
        fill="white"
      />
    </>
  );
});
