import { useLocalStore, useAsObservableSource } from 'mobx-react';
import { MouseEvent, MouseEventHandler } from 'react';

import Entities from 'APP/store';

interface ICategoryButtonPresenter {
  isDark: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
}

export const useCategoryButtonPresenter = (
  onClick: (event?: MouseEvent<HTMLButtonElement>) => void
): ICategoryButtonPresenter => {
  const source = useAsObservableSource({ onClick });

  const presenter = useLocalStore<ICategoryButtonPresenter>(() => ({
    onClick(event: MouseEvent<HTMLButtonElement>): void {
      event.preventDefault();
      event.stopPropagation();
      event.currentTarget.blur();

      source.onClick(event);
    },

    get isDark(): boolean {
      return Entities.appearance.isDark;
    },
  }));

  return presenter;
};
