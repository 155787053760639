import classNames from 'classnames';
import React, { FC } from 'react';

import { IconView, IconViewSizes } from 'APP/packages/UiKit/IconView/IconView';
import MicOff from 'ICONS/ic-mic-off-16.svg';

import styles from './MutedBadge.styles.m.css';

interface IMutedBadgeProps {
  className?: string;
}

export const MutedBadge: FC<IMutedBadgeProps> = ({ className }) => {
  return (
    <div className={classNames(styles.iconContainer, className)}>
      <IconView
        Icon={MicOff}
        size={IconViewSizes.Small}
        className={styles.icon}
      />
    </div>
  );
};
