import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Tasks from 'APP/Tasks';
import { openPopup } from 'APP/Tasks/app/popup/popup';
import { OnboardingType } from 'APP/constants/onboarding';
import { generalAnalytics } from 'APP/packages/analytics/analytics';
import { TabType, TopTabType } from 'APP/packages/analytics/general/generalAnalytics.types';
import { RouterLink } from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import Entities from 'APP/store';
import { SpaceDemoPopup } from 'MAIN/PopupManager/Popups/SpaceDemoPopup/SpaceDemoPopup';

import { SidebarPanelMode } from '../SidebarPanel.constants';

export interface ISidebarPanelNavigationPresenterParams {
  panelMode: SidebarPanelMode;
  onPanelModeChange(panelMode: SidebarPanelMode): void;
}

interface ISidebarPanelNavigationPresenter {
  isActiveAllGroups: boolean;
  isActiveChat: boolean;
  isActiveSpaces: boolean;
  isActiveChannels: boolean;
  isShowMarketplace: boolean;
  isShowSpaceBanner: boolean;
  initSpaceOnboarding(): void;
  setActiveAllGroups(): void;
  setActiveChats(): void;
  setActiveChannels(): void;
  setActiveSpaces(): void;
  trackTopTabOpened(panelMode: SidebarPanelMode, topTabType: TopTabType): void;
  openCatalog(): void;
  openNewsfeed(): void;
  openHub(): void;
}

export const useSidebarPanelNavigationPresenter = (
  data: ISidebarPanelNavigationPresenterParams
): ISidebarPanelNavigationPresenter => {
  const location = useLocation();
  const navigateTo = useNavigateTo();
  const source = useAsObservableSource({
    location,
    navigateTo,
    panelMode: data.panelMode,
    onPanelModeChange: data.onPanelModeChange,
  });

  const presenter = useLocalStore<ISidebarPanelNavigationPresenter>(() => ({
    get isActiveAllGroups(): boolean {
      return source.panelMode === SidebarPanelMode.AllGroups;
    },

    get isActiveChat(): boolean {
      return [SidebarPanelMode.None, SidebarPanelMode.Chats].includes(source.panelMode);
    },

    get isActiveSpaces(): boolean {
      return [SidebarPanelMode.Spaces, SidebarPanelMode.Space].includes(source.panelMode);
    },

    get isActiveChannels(): boolean {
      return source.panelMode === SidebarPanelMode.Channels;
    },

    get isShowMarketplace(): boolean {
      return Entities.marketplace.isAvailable;
    },

    get isShowSpaceBanner(): boolean {
      return Entities.onboarding.getIsNeedToShow(OnboardingType.SpaceDemo);
    },

    initSpaceOnboarding(): void {
      Entities.onboarding.addToShow(OnboardingType.SpaceDemo);
    },

    setActiveAllGroups(): void {
      source.onPanelModeChange(SidebarPanelMode.AllGroups);
      presenter.trackTopTabOpened(SidebarPanelMode.AllGroups, TopTabType.All);
    },

    setActiveChats(): void {
      source.onPanelModeChange(SidebarPanelMode.Chats);
      presenter.trackTopTabOpened(SidebarPanelMode.Chats, TopTabType.Chats);
    },

    setActiveChannels(): void {
      source.onPanelModeChange(SidebarPanelMode.Channels);
      presenter.trackTopTabOpened(SidebarPanelMode.Channels, TopTabType.Channels);
    },

    setActiveSpaces(): void {
      if (presenter.isShowSpaceBanner) {
        openPopup(SpaceDemoPopup);
      }

      source.onPanelModeChange(SidebarPanelMode.Spaces);
      presenter.trackTopTabOpened(SidebarPanelMode.Spaces, TopTabType.Spaces);
    },

    trackTopTabOpened(panelMode: SidebarPanelMode, topTabType: TopTabType): void {
      if (source.panelMode !== panelMode) {
        generalAnalytics.trackTopMenuOpened(topTabType);
      }
    },

    openCatalog(): void {
      if (source.location.pathname !== RouterLink.catalog) {
        Entities.catalogStore.reset();
        source.navigateTo({ to: RouterLink.catalog });
        generalAnalytics.trackBottomMenuOpened(TabType.Catalog);
      }
    },

    openNewsfeed(): void {
      if (source.location.pathname !== RouterLink.newsfeed) {
        Tasks.newsfeed.goToNewsfeed();
        generalAnalytics.trackBottomMenuOpened(TabType.Feed);
      }
    },

    openHub(): void {
      if (source.location.pathname !== RouterLink.hub) {
        source.navigateTo({ to: RouterLink.hub });
        generalAnalytics.trackBottomMenuOpened(TabType.Hub);
      }
    },
  }));

  useEffect(() => {
    presenter.initSpaceOnboarding();
  }, []);

  return presenter;
};
