import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { useTranslation } from 'APP/packages/translations';
import InfoIcon from 'ICONS/ic-info.svg';

import { MetaBlock } from '../MetaBlock/MetaBlock';
import styles from './ProfileBio.styles.m.css';

interface IProfileBioProps {
  className?: string;
  text: string;
}

export const ProfileBio: FC<IProfileBioProps> = observer(({ className, text }) => {
  const { t } = useTranslation();

  return (
    <MetaBlock
      className={className}
      label={t('common_about_profile')}
      Icon={InfoIcon}
    >
      <div className={styles.text}>{text}</div>
    </MetaBlock>
  );
});
