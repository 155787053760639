import { observer } from 'mobx-react';
import React from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { useTranslation } from 'APP/packages/translations';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import { Button } from 'UIKIT/Button/Button';
import UiCalendar from 'UIKIT/Calendar';
import { PopupFooter } from 'UIKIT/PopupFooter/PopupFooter';

import {
  IDateFieldCalendarPresenterProps,
  useDateFieldCalendarPresenter,
} from './DateFieldCalendar.presenter';
import styles from './DateFieldCalendar.styles.m.css';

export const DateFieldCalendar: React.FC<ICommonPopupsProps<IDateFieldCalendarPresenterProps>> =
  observer((props) => {
    const presenter = useDateFieldCalendarPresenter(props);
    const { t } = useTranslation();

    return (
      <Popup
        isOpened
        onClose={presenter.onClose}
        className={styles.popup}
      >
        <div className={styles.container}>
          <UiCalendar
            value={presenter.selectedDate}
            minDate={presenter.minDate}
            onChange={presenter.onChange}
          />
        </div>

        <PopupFooter className={styles.footer}>
          <Button
            theme="6"
            onClick={presenter.onClose}
          >
            {t('common_cancel')}
          </Button>
          <Button
            theme="1"
            onClick={presenter.onChoose}
          >
            {t('common:common_apply')}
          </Button>
        </PopupFooter>
      </Popup>
    );
  });
