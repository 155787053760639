import { useLocalStore } from 'mobx-react';

import Entities from 'APP/store';

export default ({ groupId, messageId }) => {
  const presenter = useLocalStore(() => ({
    get message() {
      const group = Entities.GroupsStore.getGroupById(groupId);

      if (!group) {
        return null;
      }

      return group.sharedData.getMessageById(messageId) || null;
    },
  }));

  return presenter;
};
