// only one activity for user in group
import { action, computed, observable, values } from 'mobx';

const REMOVE_AFTER_MS = 4000;

class UserTyping {
  @observable data = new Map();

  @action
  add({ userId, type, payload = {} }) {
    const timerId = setTimeout(() => {
      this.remove(userId);
    }, REMOVE_AFTER_MS);

    clearTimeout(this.data.get(userId)?._timerId);

    this.data.set(userId, {
      type,
      payload,
      userId,
      _timerId: timerId,
      _ts: Date.now(),
    });
  }

  @action
  remove(userId) {
    clearTimeout(this.data.get(userId)?._timerId);
    this.data.delete(userId);
  }

  @computed
  get events() {
    return values(this.data).map(({ type, userId, payload }) => {
      return { type, userId, payload };
    });
  }
}

export default UserTyping;
