import { spaceModel } from 'APP/model/model';
import { spaceAnalytics } from 'APP/packages/analytics/analytics';
import Entities from 'STORE';
import { Group } from 'STORE/Groups/Group';

function trackChangeVisibilityEvent(group: Group, isVisible: boolean): void {
  if (isVisible) {
    if (group.isChatGroup) {
      spaceAnalytics.trackSpaceGroupChatVisibleAllChats(group.spaceId!, group.id);
    } else if (group.isChannel) {
      spaceAnalytics.trackSpaceChannelVisibleAllChats(group.spaceId!, group.id);
    }
  } else {
    if (group.isChatGroup) {
      spaceAnalytics.trackSpaceGroupChatInvisibleAllChats(group.spaceId!, group.id);
    } else if (group.isChannel) {
      spaceAnalytics.trackSpaceChannelInvisibleAllChats(group.spaceId!, group.id);
    }
  }
}

export async function changeGroupVisibility(groupId: string, isVisible: boolean): Promise<boolean> {
  const group = Entities.GroupsStore.getGroupById(groupId);
  if (!group || !group.space) {
    return false;
  }

  group.space.groupVisibility.setGroupVisible(group.id, isVisible);

  const result = await spaceModel.updateGroupVisibility({
    spaceId: group.spaceId!,
    groupId: group.id,
    visible: isVisible,
  });

  if (result) {
    trackChangeVisibilityEvent(group, isVisible);
  } else {
    group.space.groupVisibility.setGroupVisible(group.id, !isVisible);
  }

  return result;
}
