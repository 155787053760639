import Tasks from 'APP/Tasks';
import compareUrls from 'UTILS/compareUrls';
import { replaceHtmlTagsToSpecialSymbols } from 'UTILS/html/replaceHtmlTagsToSpecialSymbols';

export default async ({ text, linkPreview = null }) => {
  // Get last link
  const value = replaceHtmlTagsToSpecialSymbols(text).trim();
  const link = Tasks.app.searchLinks(value).pop();
  if (!link) {
    return null;
  }

  // The link is not located at the end of the text
  const position = value.lastIndexOf(link);
  if (position + link.length !== value.length) {
    return null;
  }

  if (compareUrls(linkPreview?.link, link)) {
    return linkPreview;
  }

  // We must display the previews to only youtube video
  const linkResponse = await Tasks.messaging.getLinkPreview({ link });
  if (linkResponse?.video?.type !== 'youtube') {
    return null;
  }

  return linkResponse;
};
