import classNames from 'classnames';
import React, { ChangeEventHandler, FocusEventHandler, MutableRefObject } from 'react';
import { FC } from 'react';

import styles from './TextField.styles.m.css';
import { getHelperText } from './helperText';

interface ITextFieldProps {
  className?: string;
  input: {
    name: string;
    onChange: ChangeEventHandler<HTMLInputElement>;
    onFocus: FocusEventHandler<HTMLInputElement>;
    onBlur: FocusEventHandler<HTMLInputElement>;
    value: string;
  };
  meta: {
    active: boolean;
    error: string;
    invalid: boolean;
    valid: boolean;
    dirty: boolean;
    modified: boolean;
    validating: boolean;
  };
  label: string;
  placeholder: string;
  maxLength?: number;
  isDisabled?: boolean;
  helperText?: string;
  validatingText?: string;
  successText?: string;
  setRef?: MutableRefObject<HTMLInputElement>;
  readOnly?: boolean;
  hasExternalError?: boolean;
  ignoreTrimError?: boolean;
}

export const TextField: FC<ITextFieldProps> = ({
  className,
  input: { name, onChange, value, onFocus, onBlur },
  meta: { active, error, invalid, valid, dirty, modified, validating },
  label,
  placeholder,
  maxLength,
  isDisabled = false,
  helperText,
  validatingText,
  successText,
  setRef,
  readOnly = false,
  ignoreTrimError,
}) => {
  const hasError =
    !validating && modified && invalid && Boolean(ignoreTrimError ? error : error.trim());
  const hasSuccess = Boolean(successText) && !validating && dirty && valid;
  const isValidating = Boolean(validatingText) && dirty && validating;

  const contentClasses = classNames(styles.content, {
    [styles.focused]: active,
    [styles.error]: hasError,
    [styles.success]: hasSuccess,
    [styles.disabled]: isDisabled,
    [styles.withLabel]: label,
    [styles.withPlaceholder]: label && placeholder,
  });

  const labelClasses = classNames(styles.label, {
    [styles.smallLabel]: active || Boolean(placeholder) || Boolean(value),
    [styles.labelOver]: label && placeholder,
  });

  const placeholderClasses = classNames(styles.placeholder, {
    [styles.withPlaceholderLabel]: label && placeholder,
  });

  return (
    <div className={className}>
      <label className={contentClasses}>
        {label ? <span className={labelClasses}>{label}</span> : null}
        {placeholder && <span className={placeholderClasses}>{placeholder}</span>}
        <div className={styles.resizer}>
          <input
            name={name}
            onFocus={onFocus}
            onBlur={onBlur}
            className={styles.input}
            value={value}
            onChange={onChange}
            maxLength={maxLength}
            disabled={isDisabled}
            autoComplete="off"
            ref={setRef}
            readOnly={readOnly}
          />
          {placeholder && <span className={styles.hiddenPlaceholder}>{value}</span>}
        </div>
      </label>
      <div
        className={styles.helperText}
        data-test={`${name}-helper-text`}
      >
        {getHelperText({
          isValidating,
          validatingText,
          hasError,
          errorText: error,
          hasSuccess,
          successText,
          helperText,
        })}
      </div>
    </div>
  );
};
