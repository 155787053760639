import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import usePresenter from './presenter';
import styles from './styles.m.scss';

const MentionAllPopover = ({ title, mention, className, noMentionClassName = undefined }) => {
  const presenter = usePresenter(mention);

  if (!presenter.isMentionAll) {
    return <div className={classNames(styles.inline, noMentionClassName)}>{title}</div>;
  }

  return (
    <button
      className={classNames(styles.button, className)}
      onClick={presenter.openMention}
    >
      {title}
    </button>
  );
};

export default observer(MentionAllPopover);
