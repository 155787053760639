import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useRef } from 'react';

import Video from '../../../SharedComponents/Video';
import styles from './styles.m.css';

const StreamerScreen = ({ streamer, containerClassName, className, onClick }) => {
  const containerRef = useRef();
  if (!streamer) {
    return null;
  }

  return (
    <>
      <button
        className={classNames(styles.container, containerClassName)}
        onClick={onClick}
        ref={containerRef}
      >
        <Video
          videoTrack={streamer.screenTrack}
          className={classNames(styles.video, className)}
        />
      </button>
    </>
  );
};

export default observer(StreamerScreen);
