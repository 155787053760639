import { GroupType, IGroupData } from 'APP/model/group/groupModel.types';
import { Root } from 'STORE';
import { Groups } from 'STORE/Groups';

import { SavedMessages } from '../SavedMessages';

export class SavedMessagesFake extends SavedMessages {
  constructor(group: IGroupData, groups: Groups, root: Root) {
    super(group, groups, root);
    this.type = GroupType.SavedMessagesFake;
  }
}
