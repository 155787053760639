import api from 'APP/packages/api';
import logger from 'APP/packages/logger';

export const updatePermissionRequestsForAll = async ({ channelId, status }) => {
  try {
    await api.calls.updatePermissionRequestsForAll({ channelId, status });
  } catch (error) {
    logger.get('API').error('calls.updatePermissionRequestsForAll', error);
  }
};
