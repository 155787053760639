import { DIRECTION } from 'APP/constants';
import { SHARED_DATA_MEDIA_TYPES_MAP } from 'APP/constants/sharedData';
import api from 'APP/packages/api';
import Entities from 'APP/store';

import loadUnknownDataFromMessages from '../../relationships/loadUnknownDataFromMessages';

export default async ({ groupId, cursorTs = 0, type, direction, isShowLoading = true }) => {
  const group = Entities.GroupsStore.getGroupById(groupId);
  const sharedData = group.sharedData;
  const sharedDataType = sharedData[type];

  if (isShowLoading) {
    sharedDataType.setLoading(true);
  }

  cursorTs = cursorTs || Date.now();

  try {
    const res = await api.messaging.getMessagesByMediaTypes({
      groupId,
      mediaTypes: SHARED_DATA_MEDIA_TYPES_MAP[type],
      direction,
      cursorTs,
    });

    if (res.messages) {
      await loadUnknownDataFromMessages({ messages: res.messages });

      sharedData.addMessages(res.messages);
    }

    sharedDataType.setCursorTs(res.cursorTs);

    if (isShowLoading) {
      sharedDataType.setLoading(false);
    }

    if (!res.cursorTs && direction === DIRECTION.OLDEST) {
      sharedDataType.setLastMessageTs(Math.max(cursorTs, sharedDataType.lastMessageTs));
      sharedDataType.setHasMore(false);
    }

    if (direction === DIRECTION.NEWEST) {
      sharedDataType.setLastMessageTs(res.cursorTs || group.lastMessageTs);
    }

    return res;
  } catch (error) {
    if (isShowLoading) {
      sharedDataType.setLoading(false);
    }

    return {};
  }
};
