import { useLocation, useHistory } from 'react-router-dom';

import { clearGetParameters } from 'UTILS/clearGetParameters';

/**
 * remove all or some parameters
 * @param {{string|string[]}[]} fields - array of keys or [key, values]
 * @returns {(...args: any[]) => any}
 */
export default (fields = []) => {
  const location = useLocation();
  const history = useHistory();

  return () => {
    history.push(clearGetParameters({ location, fields }));
  };
};
