import Tasks from 'APP/Tasks';
import { platformInfo } from 'APP/packages/platformInfo/platformInfo';
import Entities from 'APP/store';
import { t } from 'MODULE/i18n/service';

export async function endEditMessage(groupId: string): Promise<boolean> {
  const inputPanel = Entities.InputPanelsStore.getById(groupId);
  if (inputPanel.editMessage) {
    const editText =
      inputPanel.editMessage.payload.text || inputPanel.editMessage.payload.comment || '';

    if (inputPanel.markdownValue !== editText) {
      const res = await Tasks.app.showConfirm({
        text: t('common_discard_changes_alert'),
        primaryText: t('common_yes'),
        secondaryText: t('common_no'),
      });
      if (!res) {
        return res;
      }
    }
  }

  // TODO review input logic for avoiding remove ranges explicitly
  if (platformInfo.isSafari) {
    const selection = window.getSelection();
    selection?.removeAllRanges();
  }

  if (inputPanel) {
    inputPanel.setEditMessageId(null);
    inputPanel.setValue(null);
  }

  return true;
}
