import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { useTranslation } from 'APP/packages/translations';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { GroupForm } from '../../components/GroupForm/GroupForm';
import { useCloseFormPopup } from '../../hooks/useCloseFormPopup';
import {
  IEditChatGroupPopupPresenterParams,
  useEditChatGroupPopupPresenter,
} from './EditChatGroupPopup.presenter';

type IEditChatGroupPopupProps = IEditChatGroupPopupPresenterParams;

export const EditChatGroupPopup: FC<ICommonPopupsProps<IEditChatGroupPopupProps>> = observer(
  (props) => {
    const { t } = useTranslation();
    const presenter = useEditChatGroupPopupPresenter(props);
    const closeForm = useCloseFormPopup(props, t('cancel_channel_creation_alert'));

    return (
      <Popup
        isOpened={!!presenter.group}
        onClose={closeForm.closePopup}
      >
        <GroupForm
          title={t('edit_group_chat_profile_title')}
          initialValues={presenter.initialValues}
          avatarTitle={presenter.avatarTitle}
          avatarColor={presenter.avatarColor}
          isEditMode={true}
          onSubmit={presenter.onSubmit}
          onFormStateChange={closeForm.onFormStateChange}
          onClose={closeForm.closePopup}
        />
      </Popup>
    );
  }
);
