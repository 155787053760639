export default (nodes) => {
  // true for macos
  if (
    nodes[0]?.tagName.toLowerCase() === 'meta' &&
    nodes[1]?.tagName.toLowerCase() === 'meta' &&
    nodes[2]?.tagName.toLowerCase() === 'b' &&
    nodes[2]?.id?.includes('docs-internal-guid')
  ) {
    return true;
  }

  // true for windows
  if (
    nodes[0]?.tagName.toLowerCase() === 'meta' &&
    nodes[1]?.tagName.toLowerCase() === 'b' &&
    nodes[1]?.id?.includes('docs-internal-guid')
  ) {
    return true;
  }

  return false;
};
