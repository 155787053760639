import { observer } from 'mobx-react';
import React from 'react';

import { useTranslation } from 'APP/packages/translations';
import CloseIcon from 'ICONS/ic-close-32.svg';
import { CloseButton } from 'MAIN/OngoingCall/Shared/CloseButton/CloseButton';
import Entities from 'STORE';

import styles from './RightPanelHeader.styles.m.css';

export const RightPanelHeader = observer(() => {
  const call = Entities.Calls.ongoingCall;
  const ongoingCall = Entities.OngoingCall;

  const { t } = useTranslation();

  const membersQuantity = t('calls_users_on_call', {
    0: call?.members.memberOnCallList.length || 0,
    1: call?.members.memberList.length || 0,
  });

  return (
    <div className={styles.headerContainer}>
      <div className={styles.content}>
        <div className={styles.title}>{t('common_participants')}</div>
        <div className={styles.description}>{membersQuantity}</div>
      </div>
      <div>
        <CloseButton
          Icon={CloseIcon}
          onClick={ongoingCall.members.hideMembers}
        />
      </div>
    </div>
  );
});
