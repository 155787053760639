import { useMemo } from 'react';

import dateService from 'APP/packages/date';

export function useSharedVoicePlayerPresenter(serverTime: number): string {
  return useMemo(() => {
    return (
      dateService.format(serverTime, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }) || ''
    );
  }, [serverTime]);
}
