import { useLocalStore } from 'mobx-react';
import { useEffect, useState } from 'react';

import Tasks from 'APP/Tasks';
import { RewardProgressInfoRewardEnum } from 'APP/model/userSettings/userSettingsModel.types';
import { getSuccessAnimation } from 'APP/packages/api/rest/static';
import { storage } from 'APP/packages/storage';
import Entities from 'APP/store';
import { IRewardProgressInfo } from 'APP/types/rewards';

import { IEditProfileBadgePresenter } from './EditProfileBadge.types';

const HIDE_LOCK_DELAY = 1000;

interface EditProfileBadgePresenterReturnType {
  presenter: IEditProfileBadgePresenter;
  animationData?: { id: string; data: Record<string, any> };
}

export const useEditProfileBadgePresenter = (): EditProfileBadgePresenterReturnType => {
  const [animationData, setAnimationData] =
    useState<EditProfileBadgePresenterReturnType['animationData']>();

  const presenter = useLocalStore<IEditProfileBadgePresenter>(() => ({
    animationPlayed: storage.rewards.getRewardAnimationShown(),
    showLock: Entities.rewards.rewardsProgress.get(RewardProgressInfoRewardEnum.UsernameBadge1)
      ? !Entities.rewards.rewardsProgress.get(RewardProgressInfoRewardEnum.UsernameBadge1)?.acquired
      : false,
    isDataLoaded: !!Entities.rewards.rewardsProgress.get(
      RewardProgressInfoRewardEnum.UsernameBadge1
    ),

    get badgeRewardProgress(): IRewardProgressInfo | undefined {
      return Entities.rewards.rewardsProgress.get(RewardProgressInfoRewardEnum.UsernameBadge1);
    },

    get isRewardAchieved(): boolean {
      return !!presenter.badgeRewardProgress?.achieved;
    },

    get isRewardAcquired(): boolean {
      return !!presenter.badgeRewardProgress?.acquired;
    },

    get showAnimation(): boolean {
      return (
        !presenter.animationPlayed && presenter.isRewardAchieved && !presenter.isRewardAcquired
      );
    },

    onCompleteAnimation(): void {
      presenter.animationPlayed = true;
      storage.rewards.setRewardAnimationShown(true);
      Tasks.userSettings.acquireReward(RewardProgressInfoRewardEnum.UsernameBadge1);
      setTimeout(() => {
        presenter.showLock = false;
      }, HIDE_LOCK_DELAY);
    },
  }));

  useEffect(() => {
    if (
      !presenter.isDataLoaded &&
      presenter.badgeRewardProgress &&
      !presenter.badgeRewardProgress.acquired
    ) {
      presenter.showLock = true;
      presenter.isDataLoaded = true;
    }
  }, [presenter.badgeRewardProgress]);

  useEffect(() => {
    if (presenter.showAnimation) {
      getSuccessAnimation().then((data) =>
        setAnimationData({ id: 'reward-success-animation', data })
      );
    }
  }, [presenter.showAnimation]);

  return { presenter, animationData };
};
