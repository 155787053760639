export const KEY_NAMES = {
  ESC: 'esc',
  ENTER: 'enter',
  ARROW_UP: 'arrowUp',
  ARROW_DOWN: 'arrowDown',
  ARROW_LEFT: 'arrowLeft',
  ARROW_RIGHT: 'arrowRight',
};

export const KEY_CODES_TO_KEY_NAME = {
  27: KEY_NAMES.ESC,
  13: KEY_NAMES.ENTER,
  38: KEY_NAMES.ARROW_UP,
  40: KEY_NAMES.ARROW_DOWN,
  37: KEY_NAMES.ARROW_LEFT,
  39: KEY_NAMES.ARROW_RIGHT,
};
