import classNames from 'classnames';
import React from 'react';

import { TextRenderer } from 'APP/components/TextRenderer/TextRenderer';
import { IconView } from 'APP/packages/UiKit/IconView/IconView';

import styles from './CommonPayload.style.m.css';

export const CommonPayload = ({
  Icon,
  name,
  description,
  iconClassName = '',
  textClassName = '',
}) => {
  return (
    <>
      {name && <span className={styles.header}>{name}:</span>}
      {Icon && (
        <IconView
          className={classNames(styles.icon, iconClassName)}
          Icon={Icon}
          size="xsmall"
        />
      )}
      {description && (
        <TextRenderer
          isPlainText
          className={classNames(styles.text, textClassName)}
        >
          {description}
        </TextRenderer>
      )}
    </>
  );
};
