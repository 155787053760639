import { action, computed, observable } from 'mobx';

import { ChatMessage } from '../Message/ChatMessage/ChatMessage';
import { MessagesStore } from '../Messages';

export class MultiSelect {
  public messages: MessagesStore | null = null;

  @observable selectedMessagesIds = new Set<string>();

  constructor(messages: MessagesStore) {
    this.messages = messages;
  }

  @computed
  get hasSelectedMessages(): boolean {
    return this.selectedMessagesIds.size > 0;
  }

  @computed
  get selectedMessages(): ChatMessage[] {
    return Array.from(this.selectedMessagesIds)
      .map((id) => this.messages?.getMessageById(id))
      .filter((message) => !!message) as ChatMessage[];
  }

  @action
  selectMessage(messageId: string): void {
    this.selectedMessagesIds.add(messageId);
  }

  @action
  unselectMessage(messageId: string): void {
    this.selectedMessagesIds.delete(messageId);
  }

  @action
  clearSelectedMessages(): void {
    this.selectedMessagesIds.clear();
  }
}
