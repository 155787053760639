import { GroupType } from 'APP/model/group/groupModel.types';
import { Group } from 'STORE/Groups/Group';

import { checkSearchString } from './checkSearchString';

const VALID_GROUPS = [
  GroupType.P2P,
  GroupType.Open,
  GroupType.Closed,
  GroupType.ChannelOpen,
  GroupType.ChannelClosed,
  GroupType.MySavedMessages,
];

export const checkSearchGroup = (group: Group, searchText: string): boolean => {
  return (
    VALID_GROUPS.includes(group.type) &&
    !group.isBot &&
    !group.withoutMe &&
    (checkSearchString(group.name, searchText) || checkSearchString(group.description, searchText))
  );
};
