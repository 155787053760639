import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import Entities from 'APP/store';

export default () => {
  useEffect(() => {
    const group = Entities.GroupsStore.activeGroup;
    if (Entities.App.isFocused && group && !group.withoutMe) {
      let lastUnreadMessage = null;
      group.messagesStore.inViewMessageIds.forEach((id) => {
        const message = group.messagesStore.getMessageById(id);
        if (message?.unread && message.serverTime > (lastUnreadMessage?.serverTime || 0)) {
          lastUnreadMessage = message;
        }
        if (lastUnreadMessage) {
          Tasks.messaging.confirmReadMessages({
            groupId: group.id,
            messageId: lastUnreadMessage.id,
          });
        }
      });
    }
  }, [Entities.App.isFocused]);
};
