import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { useSenderAvatarPresenter } from './SenderAvatar.presenter';
import { SenderAvatarForAdmins } from './SenderAvatarForAdmins/SenderAvatarForAdmins';
import { SenderAvatarForUser } from './SenderAvatarForUser/SenderAvatarForUser';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

interface ISenderAvatarProps {
  message: ChatMessage;
}

export const SenderAvatar: FC<ISenderAvatarProps> = observer(({ message }) => {
  const presenter = useSenderAvatarPresenter(message);

  if (!presenter.isShow) {
    return null;
  }

  if (presenter.withMeInAdmins && !presenter.isSavedMessages) {
    return <SenderAvatarForAdmins message={message} />;
  }

  return <SenderAvatarForUser message={message} />;
});
