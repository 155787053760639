import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { AddKeyPressHandler } from 'APP/packages/keyBoardHandler';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';

import Command from './Command';
import usePresenter from './presenter';
import styles from './styles.m.css';

const BotCommandsList = () => {
  const { containerRef, common, focus, submit } = usePresenter();

  if (!common.isOpened) {
    return null;
  }

  return (
    <>
      <AddKeyPressHandler
        isCaptured
        onArrowUp={focus.onKeyUp}
        onArrowDown={focus.onKeyDown}
        onEnter={submit.onEnter}
      />
      <div
        className={classNames(styles.container, customScrollStyles.container)}
        ref={containerRef}
      >
        {common.commands.map((item, index) => {
          return (
            <Command
              key={item.command}
              index={index}
              command={item}
              bot={common.bot}
              isFocused={index === focus.focusedIndex}
              onSelect={submit.send}
              onHover={focus.onHover}
              onChangeViewState={focus.onChangeViewState}
            />
          );
        })}
      </div>
    </>
  );
};

export default observer(BotCommandsList);
