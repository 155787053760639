import { Group } from 'STORE/Groups/Group';

export const getVisibleGroups = (groups: Group[]): Group[] => {
  return groups.filter((group: Group): boolean => {
    if (!group.space) {
      return true;
    }

    return group.space.groupVisibility.isGroupVisible(group.id);
  });
};
