import classNames from 'classnames';
import React, { memo, ReactEventHandler, JSX } from 'react';

import { ApplicationTheme } from 'APP/constants/appearance';
import { MenuItemStyle } from 'UIKIT/MenuItem/MenuItem.types';

import styles from './MenuItem.styles.m.css';

interface IMenuItemProps {
  title: string;
  icon?: JSX.Element;
  onClick: ReactEventHandler<HTMLButtonElement>;
  theme?: ApplicationTheme;
  style?: MenuItemStyle;
  className?: string;
  titleClassName?: string;
  children?: React.ReactNode;
  isDisabled?: boolean;
  dataTest?: string;
  withDot?: boolean;
  count?: number;
}

export const MenuItem = memo<IMenuItemProps>((props) => {
  const {
    title,
    icon,
    onClick,
    theme,
    style,
    className,
    titleClassName,
    children,
    isDisabled,
    dataTest,
    withDot,
    count,
  } = props;

  return (
    <button
      onClick={onClick}
      className={classNames(
        styles.container,
        className,
        styles[`container--${theme}`],
        styles[`container--${style}`]
      )}
      disabled={isDisabled}
      data-test={dataTest}
    >
      {icon && (
        <span className={styles.icon}>
          {icon}
          {withDot && (
            <div className={styles.dotContainer}>
              <div className={styles.dot} />
            </div>
          )}
        </span>
      )}
      <span
        className={classNames(styles.title, titleClassName, styles[`title--${theme}`])}
        title={title}
      >
        {title}
      </span>
      {children}
      {count !== undefined && <span className={styles.count}>{count}</span>}
    </button>
  );
});
