import { IAgoraRTCRemoteUser } from 'agora-rtc-sdk-ng';
import { computed } from 'mobx';

import { CallBlurType } from 'APP/constants/callBackground';
import {
  ProviderMediaType,
  ProviderRemoteStreamType,
} from 'APP/packages/callProviders/callProviders.constants';

import AgoraClient from '../AgoraClient/AgoraClient';
import {
  ICallProviderConstructorParams,
  ICallProviderFacade,
  ICallProviderJoinParams,
  IProviderClientHandlers,
  IProviderClientParams,
  ProviderUid,
} from '../callProviders.types';
import { AgoraProviderOpponent } from './AgoraProviderOpponent';
import { AgoraProviderPublisher } from './AgoraProviderPublisher';
import { AgoraShareScreenProvider } from './AgoraShareScreenProvider';

export class AgoraProvider implements ICallProviderFacade {
  canUseBlur = true;
  needToGetScreenSharingToken = false;
  private shareScreenClient: AgoraShareScreenProvider | null;
  private providerOpponents = new Map<ProviderUid, AgoraProviderOpponent>();
  private eventHandlers: Partial<IProviderClientHandlers> = {};
  agoraClient: AgoraClient;
  publisher = new AgoraProviderPublisher(this);

  constructor(props: ICallProviderConstructorParams) {
    this.agoraClient = new AgoraClient({ ...props, isScreenSharing: false });
  }

  async createClient(params: IProviderClientParams): Promise<void> {
    this.eventHandlers.onUserJoined = params.handlers?.onUserJoined;
    this.eventHandlers.onUserLeft = params.handlers?.onUserLeft;
    this.eventHandlers.onLeave = params.handlers?.onLeave;
    this.eventHandlers.onJoin = params.handlers?.onJoin;

    await this.agoraClient.createClient({
      role: params.role,
      devices: params.devices,
      handlers: {
        onUserJoined: this.handleUserJoined.bind(this),
        onUserLeft: this.handleUserLeft.bind(this),
        onVolumeIndicator: this.handleChangeOpponentVolumeLevel.bind(this),
        onLeave: params.handlers?.onLeave,
        onJoin: params.handlers?.onJoin,
        onUserMediaChanged: this.handleUserMediaChanged.bind(this),
        onLocalAudioTrackEnd: params.handlers?.onLocalAudioTrackEnd,
        onLocalAudioTrackStart: params.handlers?.onLocalAudioTrackStart,
      },
    });
  }

  private handleChangeOpponentVolumeLevel(volumes: { uid: ProviderUid; level: number }[]): void {
    volumes.forEach((volume) => {
      const providerOpponent = this.providerOpponents.get(volume.uid);
      if (providerOpponent) {
        providerOpponent.setVolumeLevel(volume.level);
      }
    });
  }

  private handleUserMediaChanged(
    userUid: ProviderUid,
    mediaType: ProviderMediaType,
    value: boolean
  ): void {
    const providerOpponent = this.providerOpponents.get(userUid);
    if (!providerOpponent) {
      return;
    }

    if (mediaType === ProviderMediaType.Audio) {
      providerOpponent.setHasAudio(value);
    } else {
      providerOpponent.setHasVideo(value);
    }
  }

  private handleUserJoined(user: IAgoraRTCRemoteUser): void {
    if (user.uid === this.agoraClient.screenUid) {
      return;
    }
    const providerOpponent = new AgoraProviderOpponent(user);
    this.providerOpponents.set(user.uid, providerOpponent);
    if (this.eventHandlers.onUserJoined) {
      this.eventHandlers.onUserJoined(providerOpponent);
    }
  }

  private handleUserLeft(uid: ProviderUid): void {
    this.providerOpponents.delete(uid);
    if (this.eventHandlers.onUserLeft) {
      this.eventHandlers.onUserLeft(uid);
    }
  }

  @computed
  get blurType(): CallBlurType {
    return this.agoraClient.blurType;
  }

  @computed
  get isJoined(): boolean {
    return this.agoraClient.isJoined;
  }

  @computed
  get isAudience(): boolean {
    return this.agoraClient.isAudience;
  }

  async join(params: ICallProviderJoinParams): Promise<void> {
    await this.agoraClient.join(params);
  }

  async setSomeUserTypeStream(uid: ProviderUid, type: ProviderRemoteStreamType): Promise<void> {
    await this.agoraClient.setSomeUserTypeStream(uid, type);
  }

  async leave(): Promise<void> {
    await this.agoraClient.leave();
  }

  async publish(): Promise<void> {
    await this.agoraClient.publish();
  }

  async muteAudio(): Promise<void> {
    await this.agoraClient.muteAudio();
  }

  async unmuteAudio(needToPublish?: boolean): Promise<void> {
    await this.agoraClient.unmuteAudio(needToPublish);
  }

  async changeMicrophone(device: MediaDeviceInfo | null): Promise<void> {
    if (!device) {
      return;
    }
    await this.agoraClient.changeMicrophone(device);
  }

  async startScreenShare(params: {
    channelId: string;
    token: string;
    uid: ProviderUid;
    needToPublish?: boolean;
    onStopSharingScreen: () => void;
    track?: MediaStreamTrack;
  }): Promise<boolean> {
    if (!this.agoraClient.screenUid) {
      return false;
    }

    const shareScreenClient = new AgoraShareScreenProvider();

    await shareScreenClient.join({
      token: params.token,
      uid: params.uid,
      channelId: params.channelId,
    });

    const onEnded = async (): Promise<void> => {
      await this.stopScreenShare();
      if (params.onStopSharingScreen) {
        params.onStopSharingScreen();
      }
    };

    const providerOpponent = await shareScreenClient.startScreenSharing({
      needToPublish: params.needToPublish,
      onEnded,
      track: params.track,
    });

    if (!providerOpponent) {
      return false;
    }

    if (this.eventHandlers.onUserJoined) {
      this.eventHandlers.onUserJoined(providerOpponent);
    }

    this.shareScreenClient = shareScreenClient;

    return true;
  }

  async stopScreenShare(): Promise<void> {
    if (this.shareScreenClient) {
      await this.shareScreenClient.stopScreenShare();
      this.shareScreenClient = null;
    }
  }

  async muteVideo(): Promise<void> {
    await this.agoraClient.unpublishVideo();
  }

  async unpublish(): Promise<void> {
    await this.agoraClient.unpublish();
  }

  async unmuteVideo(needToPublish?: boolean): Promise<void> {
    await this.agoraClient.unmuteVideo(needToPublish);
  }

  setBlur(blurType: CallBlurType): void {
    this.agoraClient.setBlur(blurType);
  }

  async dispose(): Promise<boolean> {
    if (this.shareScreenClient) {
      await this.shareScreenClient.stopScreenShare();
    }
    this.shareScreenClient = null;
    await this.agoraClient.dispose();

    return false;
  }
}
