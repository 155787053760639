import api from 'APP/packages/api';
import logger from 'APP/packages/logger';

interface IStartScreenSharingData {
  channelId: string;
  recordingId: string;
}

export const sendStartScreenSharingEvent = async ({
  channelId,
  recordingId,
}: IStartScreenSharingData): Promise<void> => {
  try {
    logger.get('API').debug('calls.startScreenSharing', {
      channelId,
      recordingId,
    });

    await api.calls.startScreenSharing({
      channelId,
      recordingId,
    });
  } catch (e) {
    logger.get('API').error('calls.sendStartScreenSharingEvent', e);
  }
};
