import React, { FC } from 'react';

import { DotLoader } from 'APP/packages/UiKit/DotLoader/DotLoader';
import CallIcon from 'ICONS/ic-call-32.svg';
import { Button } from 'UIKIT/Button/Button';
import { IconView } from 'UIKIT/IconView/IconView';
import { IconViewSizes } from 'UIKIT/IconView/IconView.types';

import styles from './JoinCallButton.styles.m.css';

interface IJoinCallButtonProps {
  onClick(): void;
}

export const JoinCallButton: FC<IJoinCallButtonProps> = ({ onClick }) => {
  return (
    <Button
      className={styles.activeCallPlaceholderContainer}
      onClick={onClick}
      theme="10"
    >
      <div className={styles.activeCallInnerContainer}>
        <IconView
          Icon={CallIcon}
          size={IconViewSizes.Big}
          className={styles.icon}
        />
        <DotLoader itemClassName={styles.loaderItem} />
      </div>
    </Button>
  );
};
