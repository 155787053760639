import React, { memo } from 'react';

import { ProviderUid } from 'APP/packages/callProviders/callProviders.types';

import { OpponentVideoItem } from '../OpponentVideoItem/OpponentVideoItem';

interface IOpponentsViewProps {
  opponentsKeys: ProviderUid[];
}

export const OpponentsView = memo<IOpponentsViewProps>(({ opponentsKeys }) => {
  return (
    <>
      {opponentsKeys.map((uid) => (
        <OpponentVideoItem
          key={uid}
          opponentUid={uid}
        />
      ))}
    </>
  );
});
