import { PLATFORMS } from 'APP/constants';
import IcAndroid from 'ICONS/ic-android.svg';
import IcGlobe from 'ICONS/ic-globe.svg';
import IcIOs from 'ICONS/ic-ios.svg';
import IcMonitor from 'ICONS/ic-monitor.svg';

const matchIconToPlatform = (platform) => {
  switch (platform) {
    case PLATFORMS.ANDROID:
      return IcAndroid;
    case PLATFORMS.IOS:
      return IcIOs;
    case PLATFORMS.WEB:
      return IcGlobe;
    default:
      return IcMonitor;
  }
};

export default matchIconToPlatform;
