import { subscribe, broadcast } from '../../eventBus';

function getBusStation() {
  return 'open-bots-catalog';
}

export const subscribeOpenBotsCatalog = (callback) => {
  return subscribe(getBusStation(), callback);
};

export const broadcastOpenBotsCatalog = () => {
  broadcast(getBusStation());
};
