import { action, computed, observable, ObservableMap, values } from 'mobx';

import Entities from 'APP/store';

import { Counter } from './Counter';

export class CountersStore {
  @observable data = new ObservableMap<string, Counter>();

  @action
  add(counter: Counter): void {
    this.data.set(counter.groupId, counter);
  }

  @action
  delete(groupId: string): void {
    this.data.delete(groupId);
  }

  @action
  reset(): void {
    this.data.clear();
  }

  @computed
  get countersList(): readonly Counter[] {
    return values(this.data);
  }

  getCounterById(id: string): Counter | null {
    const counter = this.data.get(id);
    if (counter) {
      return counter;
    }
    const group = Entities.GroupsStore.getGroupById(id);
    // counter for channel and public group without subscription
    if ((group?.isChatGroup || group?.isChannel) && group.withoutMe) {
      return new Counter({
        counter: 0,
        groupId: id,
        lastEventTs: 0,
        lastMessageTs: Date.now(),
        lastUserMentionTs: 0,
        opponentSeenTs: 0,
        seenTs: Date.now(),
        unreadUserMentionsCounter: 0,
      });
    }

    return null;
  }
}
