import { useLocalStore } from 'mobx-react';
import { ElementType } from 'react';

import Tasks from 'APP/Tasks';
import { CallEventTypes } from 'APP/constants/calls';
import Entities from 'APP/store';
import MicOff from 'ICONS/ic-mic-off.svg';
import RemoveUser from 'ICONS/ic-remove-user.svg';
import { Call } from 'STORE/Calls/Call/Call';
import { Member } from 'STORE/Calls/Call/Member/Member';

interface IMemberCardActions {
  key: string;
  icon: ElementType;
  onClick(): void;
}

interface IMemberCardPresenter {
  call: Call;
  muteMember(): void;
  actions: IMemberCardActions[];
  removeMember(): void;
}

export const useMemberCardPresenter = (member: Member): IMemberCardPresenter => {
  const presenter = useLocalStore<IMemberCardPresenter>(() => ({
    get call(): Call {
      return Entities.Calls.ongoingCall!;
    },

    muteMember(): void {
      if (presenter.call.channelId) {
        Tasks.calls.setActionCall({
          action: CallEventTypes.ForceMute,
          channelId: presenter.call.channelId,
          userId: member.userId,
        });
      }
    },

    removeMember(): void {
      if (!presenter.call.channelId) {
        return;
      }

      Tasks.calls.removeMemberFromCall(member.userId, presenter.call.channelId);
    },

    get actions(): IMemberCardActions[] {
      const actions = [];
      const call = presenter.call;

      if (call.me.isAvailableMuteAudio && member.isMuting) {
        actions.push({
          key: 'mute',
          icon: MicOff,
          onClick: presenter.muteMember,
        });
      }

      if (call.isMeInitiator && member.isRemoving) {
        actions.push({
          key: 'remove',
          icon: RemoveUser,
          onClick: presenter.removeMember,
        });
      }

      return actions;
    },
  }));

  return presenter;
};
