import Tasks from 'APP/Tasks';
import Entities from 'APP/store';
import { t } from 'MODULE/i18n/service';

export const checkMaxParticipant = (groupId: string): boolean => {
  const group = Entities.GroupsStore.getGroupById(groupId);

  if (!group) {
    return false;
  }

  if (!group.isP2P && group.usersCount > Entities.Calls.maxParticipants) {
    Tasks.app.showAlert(
      t('calls_group_call_amount_alert').replace('{0}', String(Entities.Calls.maxParticipants))
    );

    return false;
  }
  return true;
};
