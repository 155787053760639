/**
 * Rest API
 * Documentation Rest API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpdatePermissionRequestForUser { 
    channelId: string;
    userId: string;
    access: UpdatePermissionRequestForUserAccessEnum;
    status: UpdatePermissionRequestForUserStatusEnum;
}
export enum UpdatePermissionRequestForUserAccessEnum {
    Camera = 'CAMERA',
    Mic = 'MIC',
    ScreenSharing = 'SCREEN_SHARING'
};
export enum UpdatePermissionRequestForUserStatusEnum {
    Approved = 'APPROVED',
    Rejected = 'REJECTED'
};



