import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { useTranslation } from 'APP/packages/translations';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import { Dialog } from 'UIKIT/Dialog/Dialog';

import {
  IConfirmPopupPresenterParams,
  IConfirmPopupResult,
  useConfirmPopupPresenter,
} from './ConfirmPopup.presenter';

export interface IConfirmPopupParams extends IConfirmPopupPresenterParams {
  text?: string;
  title?: string;
  primaryText?: string;
  secondaryText?: string | null;
  checkBoxText?: string;
  isChecked?: boolean;
  hasCloseButton?: boolean;
}
export const ConfirmPopup: FC<ICommonPopupsProps<IConfirmPopupParams, IConfirmPopupResult>> =
  observer((props) => {
    const { t } = useTranslation();
    const { popupInstance, params, onClose: onResult } = props;
    const {
      text,
      title,
      primaryText,
      secondaryText,
      checkBoxText,
      hasCloseButton,
      ...presenterParams
    } = params!;
    const { isLoading, isChecked, onCheckBoxChange, onSuccess, onCancel, onClose } =
      useConfirmPopupPresenter(presenterParams, popupInstance, onResult);

    return (
      <Dialog
        isOpened
        onClose={onClose}
        title={title}
        text={text}
        primaryText={primaryText || t('common_yes')}
        primaryOnClick={onSuccess}
        isSelected={isChecked}
        isLoading={isLoading}
        checkBoxText={checkBoxText}
        checkBoxChange={onCheckBoxChange}
        secondaryText={secondaryText === null ? undefined : secondaryText || t('common_cancel')}
        secondaryOnClick={onCancel}
        hasCloseButton={hasCloseButton}
      />
    );
  });
