import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import { SearchResultEntity } from 'MAIN/SidebarPanel/SearchResult/presenter/SearchResult.types';
import trimValue from 'UTILS/trimValue';

import { useSearchResultGlobalPresenter } from './SearchResultGlobal.presenter';
import { useSearchResultLocalPresenter } from './SearchResultLocal.presenter';

interface ISearchDelimiter {
  isDelimiter: boolean;
}

type ISearchResultItem = SearchResultEntity | ISearchDelimiter;

export function isDelimiterItem(item: ISearchResultItem): item is ISearchDelimiter {
  return typeof (item as ISearchDelimiter)?.isDelimiter === 'boolean';
}

interface ISearchResultPresenter {
  searchText: string;
  results: ISearchResultItem[];
  localResultsCount: number;
  isLoading: boolean;
  showLoadMore: boolean;
  showEmptyNote: boolean;
  showNoResults: boolean;
  setSearchValue(searchText: string): void;
  loadMore(): void;
}

export const useSearchResultPresenter = (searchText: string): ISearchResultPresenter => {
  const localSearchPresenter = useSearchResultLocalPresenter();
  const globalSearchPresenter = useSearchResultGlobalPresenter(localSearchPresenter);

  const presenter = useLocalStore<ISearchResultPresenter>(() => ({
    searchText: '',

    get results(): ISearchResultItem[] {
      const results: ISearchResultItem[] = [...localSearchPresenter.results];

      if (globalSearchPresenter.showDelimiter) {
        results.push({ isDelimiter: true });
      }

      return results.concat(globalSearchPresenter.results);
    },

    get localResultsCount(): number {
      return localSearchPresenter.results.length;
    },

    get isLoading(): boolean {
      return globalSearchPresenter.isLoading;
    },

    get showLoadMore(): boolean {
      return globalSearchPresenter.showLoadMore;
    },

    get showEmptyNote(): boolean {
      return !presenter.searchText;
    },

    get showNoResults(): boolean {
      return (
        globalSearchPresenter.noResults &&
        localSearchPresenter.noResults &&
        !!presenter.searchText.length
      );
    },

    setSearchValue(searchText: string): void {
      const trimmedValue = trimValue(searchText);

      if (presenter.searchText === trimmedValue) {
        return;
      }

      presenter.searchText = trimmedValue;

      globalSearchPresenter.search(trimmedValue);
      localSearchPresenter.search(trimmedValue);
    },

    loadMore(): void {
      globalSearchPresenter.loadMore(presenter.searchText);
    },
  }));

  useEffect(() => {
    presenter.setSearchValue(searchText);
  }, [searchText]);

  return presenter;
};
