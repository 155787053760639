import React from 'react';

import MentionAllPopover from 'APP/main/ChatView/components/MentionAllPopover';
import { IUserMentionData } from 'APP/model/common/commonModel.types';
import { useUserProfile } from 'MAIN/hooks/userProfile/useUserProfile';
import { isMentionAll } from 'UTILS/isMentionAll';

import { IRenderer } from '../renderTree.types';
import styles from './MentionRenderer.styles.m.css';

interface IMentionRendererOptions {
  mention: IUserMentionData;
}

export const MentionRenderer: IRenderer<IMentionRendererOptions> = (props) => {
  const { children, options } = props;
  const userProfile = useUserProfile(options.mention?.userId);

  if (!children) {
    return null;
  }

  if (isMentionAll(options.mention.nickname)) {
    return (
      <span>
        <MentionAllPopover
          title={children}
          mention={options.mention}
          className={styles.mentionedUser}
        />
      </span>
    );
  }

  return (
    <span>
      <button
        className={styles.mentionedUser}
        title={children as string}
        onClick={userProfile.show}
      >
        {children}
      </button>
    </span>
  );
};
