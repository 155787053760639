import Entities from 'STORE';

export function removeSpaceLocally(spaceId: string): void {
  if (Entities.spacesStore.activeSpaceId === spaceId) {
    Entities.spacesStore.setActiveSpace(null);
  }

  Entities.spacesStore.delete(spaceId);

  Entities.GroupsStore.rawGroups.forEach((group) => {
    if (group.spaceId === spaceId) {
      group.setSpaceId(null);
    }
  });
}
