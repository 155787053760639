import classNames from 'classnames';
import React from 'react';

import { useTranslation } from 'APP/packages/translations';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';

import styles from './ReactionTabs.style.m.css';

export const ReactionTabs = ({ tabs }) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.container, customScrollStyles.container)}>
      {tabs.map((tab) => {
        const tabClass = classNames(styles.tab, {
          [styles.button]: tab.hasTitle,
          [styles.active]: tab.isActive,
        });

        return (
          <button
            key={tab.key}
            className={tabClass}
            onClick={tab.onClick}
            disabled={tab.isDisabled}
          >
            {tab.hasTitle && t('reactions_window_message_seen')}
            {tab.path && (
              <img
                className={styles.image}
                alt={tab.alt}
                src={tab.path}
              />
            )}
            {!!tab.counter && tab.counter}
          </button>
        );
      })}
    </div>
  );
};
