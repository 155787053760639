import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';

interface IUserAccountsPopupPresenter {
  hasBackButton: boolean;
  onCloseHandler(): void;
  onBackHandler(): void;
}

export function useUserAccountsPopupPresenter({
  popupInstance,
  onClose,
  onBack,
}: ICommonPopupsProps): IUserAccountsPopupPresenter {
  const onCloseHandler = (): void => {
    popupInstance.close();
    onClose?.();
  };

  const onBackHandler = (): void => {
    popupInstance.close();
    onBack?.();
  };

  return { hasBackButton: !!onBack, onCloseHandler, onBackHandler };
}
