import Tasks from 'APP/Tasks';
import Entities from 'APP/store';

const convertPayload = (draft) => {
  // eslint-disable-next-line no-unused-vars
  const { clientTime, clientUuid, payload, ...rest } = draft;
  return {
    ...rest,
    data: payload,
    type: payload.payloadType,
  };
};

export default ({ draft, navigateTo }) => {
  const group = Entities.GroupsStore.getGroupById(draft.groupId);
  if (!group) {
    return;
  }

  const data = convertPayload(draft.toJSON());
  data.data.payloads = data.data.payloads.map((item) => convertPayload(item));

  Entities.InputPanel.payloads.setPayloads([data], ({ hasErrors }) => {
    if (!hasErrors) {
      Tasks.drafts.deleteDraft(draft);
    }
  });

  Tasks.group.goToScheduleSettings({ navigateTo, groupId: draft.groupId });
};
