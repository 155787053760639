import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, MouseEventHandler, ReactElement } from 'react';

import { useFileDownloadStatePresenter } from 'APP/hooks/useFileDownloadState/useFileDownloadState';
import { PlayIcon, PlayIconState, PlayIconType } from 'UIKIT/PlayIcon/PlayIcon';

import styles from './AudioState.styles.m.css';

interface IAudioStateProps {
  className?: string;
  onClick?: MouseEventHandler<SVGSVGElement | SVGGElement>;
  iconType: PlayIconType;
  payloadId: string;
  messageId?: string;
  groupId?: string;
  Icon?: ReactElement;
}

export const AudioState: FC<IAudioStateProps> = observer((props) => {
  const { payloadId, messageId, groupId, className, onClick, iconType, Icon } = props;

  const presenter = useFileDownloadStatePresenter({ payloadId, messageId, groupId });

  let state;

  if (presenter.hasError) {
    state = PlayIconState.Error;
  } else if (presenter.isDownloadInProcess) {
    state = PlayIconState.Loading;
  } else if (presenter.isDownloadComplete) {
    state = PlayIconState.Completed;
  } else if (presenter.isWaitingDownloadProcess) {
    state = PlayIconState.Infinity;
  } else {
    state = PlayIconState.Regular;
  }

  return (
    <div className={classNames(styles.container, className)}>
      {Icon && <span className={styles.icon}>{Icon}</span>}

      <PlayIcon
        className={styles.button}
        type={iconType}
        state={state}
        theme={Icon ? '1' : '0'}
        progress={presenter.downloadPercent}
        onCancel={(e): void => {
          e.stopPropagation();
          presenter.onAbort();
        }}
        onClick={onClick}
      />
    </div>
  );
});
