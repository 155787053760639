import { useEffect, useRef, useState } from 'react';

import { IPopover } from './Popover.types';

type TTimeout = ReturnType<typeof setTimeout> | null;

interface IPopoverParams {
  mouseOverTimeout?: number;
  mouseOutTimeout?: number;
}

const HOVER_TIMEOUT = 200;

export const usePopover = <T extends HTMLElement = HTMLElement>(
  data: IPopoverParams = {}
): IPopover<T> => {
  const containerRef = useRef<T | null>(null);
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [mouseOverTimer, setMouseOverTimer] = useState<TTimeout>(null);
  const [mouseOutTimer, setMouseOutTimer] = useState<TTimeout>(null);
  const { mouseOverTimeout = HOVER_TIMEOUT, mouseOutTimeout = HOVER_TIMEOUT } = data;

  const presenter: IPopover<T> = {
    isOpened,
    containerRef,

    toggle() {
      setIsOpened((isOpened) => !isOpened);
    },

    close() {
      setIsOpened(false);
    },

    open() {
      setIsOpened(true);
    },

    mouseOut() {
      if (mouseOverTimer) {
        clearTimeout(mouseOverTimer);
      }
      setMouseOutTimer(setTimeout(presenter.close, mouseOutTimeout));
    },

    mouseOver() {
      if (mouseOutTimer) {
        clearTimeout(mouseOutTimer);
      }
      setMouseOverTimer(setTimeout(presenter.open, mouseOverTimeout));
    },
  };

  useEffect(() => {
    return () => {
      if (mouseOverTimer) {
        clearTimeout(mouseOverTimer);
      }
      if (mouseOutTimer) {
        clearTimeout(mouseOutTimer);
      }
    };
  }, []);

  return presenter;
};
