import { EventWorker } from 'SERVICES/EventWorker';

class RLottieComposer {
  private mapWorkers: Map<string, EventWorker>;

  constructor() {
    this.mapWorkers = new Map();
  }

  public getWorker(id: string): EventWorker | null {
    return this.mapWorkers.get(id) || null;
  }

  public addWorker(id: string, worker: EventWorker): void {
    if (!this.mapWorkers.has(id) && worker) {
      this.mapWorkers.set(id, worker);
    }
  }

  public removeWorker(id: string): void {
    if (this.mapWorkers.has(id)) {
      this.mapWorkers.delete(id);
    }
  }
}

export const rLottieComposer = new RLottieComposer();
