export const handleUnderline = (
  text: string,
  index: number,
  symbolMatches: { [key: string]: { start: number; end: number }[] }
): void => {
  const underlineTag = text.substring(index, index + 2);

  if (underlineTag === '__') {
    symbolMatches['__'].push({
      start: index,
      end: index + 2,
    });
  }
};
