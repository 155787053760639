import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useRef, useEffect } from 'react';

import styles from './styles.m.css';

const Video = ({ className, videoTrack }) => {
  const ref = useRef();

  useEffect(() => {
    if (videoTrack) {
      if (videoTrack.isPlaying) {
        videoTrack.stop();
      }
      videoTrack.play(ref.current, { fit: 'contain' });
    }
    return () => {
      if (videoTrack) {
        if (videoTrack.isPlaying) {
          videoTrack.stop();
        }
      }
    };
  }, [ref, videoTrack]);

  return (
    <div
      ref={ref}
      className={classNames(styles.container, className)}
    />
  );
};

export default observer(Video);
