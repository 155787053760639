import { observer } from 'mobx-react';
import React, { FC } from 'react';

import Entities from 'STORE';

export const PopupManager: FC = observer(() => {
  return (
    <>
      {Entities.App.popups.data.map((popupInstance) => {
        const { id, Component, options } = popupInstance;

        if (!Component) {
          return null;
        }

        return (
          <Component
            key={id}
            popupInstance={popupInstance}
            {...options}
          />
        );
      })}
    </>
  );
});
