import { useAsObservableSource, useLocalStore } from 'mobx-react';

import { IForwardTargetData, ISharedDataViewItemData } from '../../ShareDataView.types';
import { useSharedDataViewCommonPresenter } from '../../presenters/useSharedDataViewCommonPresenter';

interface ISharedDataViewTabChannelsPresenterParams {
  selection: ISharedDataViewItemData[];
  searchValue: string;
  onClickItem(item: ISharedDataViewItemData): void;
}

interface ISharedDataViewTabChannelsPresenter {
  content: IForwardTargetData[];
  infoBlockTitle: string;
  infoBlockDescriptions: string[];
  keyExtractor(item: IForwardTargetData): string;
}

export const useSharedDataViewTabChannelsPresenter = (
  data: ISharedDataViewTabChannelsPresenterParams
): ISharedDataViewTabChannelsPresenter => {
  const { searchValue, selection, onClickItem } = data;

  const commonPresenter = useSharedDataViewCommonPresenter(searchValue);

  const source = useAsObservableSource({ searchValue, selection, onClickItem });

  const presenter = useLocalStore<ISharedDataViewTabChannelsPresenter>(() => ({
    get content(): IForwardTargetData[] {
      return commonPresenter.channelsItems.map((sharedItem) => {
        const isSelected = source.selection.some((it) => sharedItem.itemId === it.itemId);

        return {
          data: sharedItem,
          isSelected,
          onClick: () => source.onClickItem(sharedItem),
        };
      });
    },

    get infoBlockTitle(): string {
      return commonPresenter.infoBlockTitle;
    },

    get infoBlockDescriptions(): string[] {
      return commonPresenter.infoBlockDescriptions;
    },

    keyExtractor(item: IForwardTargetData): string {
      return commonPresenter.keyExtractor(item);
    },
  }));

  return presenter;
};
