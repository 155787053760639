import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useMemo } from 'react';

import Tasks from 'APP/Tasks';
import { Popup } from 'APP/components/Popup/Popup';
import { ALERT_TYPES } from 'APP/constants/app';
import CloseIcon from 'ICONS/close.svg';
import { useProfileView } from 'MAIN/ChatView/hooks/profileView/useProfileView';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import { useClosePopupOnNavigation } from 'MAIN/PopupManager/hooks/useClosePopupOnNavigation';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';
import { IconButton } from 'UIKIT/IconButton';

import {
  IUserProfilePopupParams,
  useUserProfilePopupPresenter,
} from './UserProfilePopup.presenter';
import styles from './UserProfilePopup.styles.m.css';

export const UserProfilePopup: FC<ICommonPopupsProps<IUserProfilePopupParams>> = observer(
  (props) => {
    const presenter = useUserProfilePopupPresenter(props);
    const popupInstance = props.popupInstance;
    const { userId, initiatorSource } = props.params!;
    const profileView = useProfileView({
      userId,
      initiatorSource,
      groupId: presenter.groupId,
      onClose: presenter.onClose,
    });
    useClosePopupOnNavigation(props.popupInstance);

    const childProps = useMemo(() => {
      if (profileView.isProfileOpened) {
        return {
          ...profileView.props,
          className: styles.profile,
          metaBlockClassName: styles.profileMetaBlock,
          userId,
          popupInstance,
          hasActions: true,
          onStartCall: presenter.onClose,
        };
      }

      if (profileView.isSharedDataOpened) {
        return {
          ...profileView.props,
          className: styles.sharedData,
        };
      }

      return profileView.props;
    }, [profileView.isSharedDataOpened]);

    useEffect(() => {
      if (!profileView.user) {
        Tasks.app.addAlert({ type: ALERT_TYPES.DELETED_USER_PROFILE });
        presenter.onClose();
      }
    }, [profileView.user]);

    return (
      <Popup
        className={styles.container}
        title={profileView.isProfileOpened ? '' : profileView.title}
        isOpened={!!profileView.user}
        isHidden={popupInstance.isHidden}
        handlePrevStep={profileView.isProfileOpened ? null : profileView.goBack}
        onClose={profileView.close}
      >
        <div className={classNames(styles.scrollContainer, customScrollStyles.container)}>
          {profileView.isProfileOpened && (
            <IconButton
              Icon={CloseIcon}
              onClick={profileView.close}
              className={styles.close}
              data-test="close-popup"
            />
          )}
          <profileView.Component {...childProps} />
        </div>
      </Popup>
    );
  }
);
