import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { showAlert } from 'APP/Tasks/app/showAlert/showAlert';
import { SpaceType } from 'APP/model/space/spaceModel.types';
import { IFormContentProps } from 'APP/packages/form/Form/Form.types';
import { useTranslation } from 'APP/packages/translations';
import { useCloseFormPopup } from 'MAIN/PopupManager/hooks/useCloseFormPopup';
import Entities from 'STORE';
import { Space } from 'STORE/Spaces/Space';
import { TAvatarBackgroundColorIndex } from 'UIKIT/Avatar/Avatar.types';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { ISpaceFormData } from '../../components/SpaceForm/SpaceForm.presenter';

interface IEditSpacePopupPresenter {
  space: Space | null;
  initialValues: ISpaceFormData;
  avatarTitle: string;
  avatarColor?: TAvatarBackgroundColorIndex | null;
  hasBackButton: boolean;
  onSubmit(spaceData: ISpaceFormData): Promise<void>;
  onFormStateChange(state: Partial<IFormContentProps>): void;
  onClose(): Promise<void>;
  onBack(): Promise<void>;
}

export interface IEditSpacePopupParams {
  spaceId: string;
}

export function useEditSpacePopupPresenter(
  props: ICommonPopupsProps<IEditSpacePopupParams>
): IEditSpacePopupPresenter {
  const { t } = useTranslation();
  const { params, onBack } = props;
  const { spaceId } = params!;
  const closeForm = useCloseFormPopup(props, t('space_cancel_creation_alert'));

  const presenter = useLocalStore<IEditSpacePopupPresenter>(() => ({
    get space(): Space | null {
      return spaceId ? Entities.spacesStore.getById(spaceId) : null;
    },

    get initialValues(): ISpaceFormData {
      return {
        name: presenter.space?.name || '',
        description: presenter.space?.description,
        type: presenter.space?.type || SpaceType.Private,
        avatarUrl: presenter.space?.avatarUrl,
        deepLinkAlias: presenter.space?.deepLinkInfo.deepLinkAlias,
        categoryId: presenter.space?.categoryId || '',
      };
    },

    get avatarTitle(): string {
      return presenter.space?.name || '';
    },

    get avatarColor(): TAvatarBackgroundColorIndex | null {
      return presenter.space?.avatarColorIndex || null;
    },

    get hasBackButton(): boolean {
      return !!onBack;
    },

    async onSubmit(spaceData: ISpaceFormData): Promise<void> {
      if (!spaceId) {
        return;
      }

      const space = await Tasks.space.updateSpace({
        ...spaceData,
        id: spaceId,
        avatarUrl: spaceData.avatarUrl || '',
        deepLinkAlias: spaceData.type === SpaceType.Public ? spaceData.deepLinkAlias : undefined,
        description: spaceData.description || '',
      });

      if (!space) {
        showAlert(t('common_somethings_wrong_error'));
      }

      presenter.onClose();
    },

    onFormStateChange(state: Partial<IFormContentProps>): void {
      closeForm.onFormStateChange(state);
    },

    async onClose(): Promise<void> {
      closeForm.closePopup();
    },

    async onBack(): Promise<void> {
      closeForm.backPopup();
    },
  }));

  useEffect(() => {
    if (
      !Entities.spacesStore.isLoading &&
      !(presenter.space?.isOwner || presenter.space?.isAdmin)
    ) {
      presenter.onClose();
    }
  }, [presenter.space, Entities.spacesStore.isLoading]);

  return presenter;
}
