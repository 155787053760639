export default (text, index, symbolMatches) => {
  symbolMatches['*'].push({
    start: index,
    end: index + 1,
  });

  const boldTag = text.substring(index, index + 2);

  if (boldTag === '**') {
    symbolMatches['**'].push({
      start: index,
      end: index + 2,
    });
  }

  const boldAndItalicTag = text.substring(index, index + 3);

  if (boldAndItalicTag === '***') {
    symbolMatches['***'].push({
      start: index,
      end: index + 3,
    });
  }
};
