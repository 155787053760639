import { action, computed, observable } from 'mobx';

import { ISpaceMarket } from 'APP/model/space/spaceModel.types';

export class SpaceMarkets {
  @observable data: ISpaceMarket[] = [];

  @computed
  public get count(): number {
    return this.data.length;
  }

  @action
  public getMarket(id: string): ISpaceMarket | null {
    return this.data.find((market) => market.id === id) || null;
  }

  @action
  public addMarket(market: ISpaceMarket): void {
    this.data.push(market);
  }

  @action
  public setMarkets(markets: ISpaceMarket[]): void {
    this.data = markets;
  }

  @action
  public updateMarket(updatedMarket: ISpaceMarket): void {
    this.data = this.data.map((market) =>
      market.id === updatedMarket.id ? updatedMarket : market
    );
  }

  @action
  public deleteMarket(marketId: string): void {
    this.data = this.data.filter((market) => market.id !== marketId);
  }
}
