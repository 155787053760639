import { observer } from 'mobx-react';
import React from 'react';

import { MediaPreview } from 'APP/components/MediaPreview/MediaPreview';
import { MEDIA_PREVIEW_MAX_SIZE } from 'APP/constants/articles';

import styles from './styles.m.css';

export const Image = observer(({ message, openMedia }) => {
  return (
    <MediaPreview
      className={styles.container}
      payload={message.payload}
      maxWidth={MEDIA_PREVIEW_MAX_SIZE.width}
      maxHeight={MEDIA_PREVIEW_MAX_SIZE.height}
      placeholderQuality={0.3}
      onClick={() => {
        openMedia(message.id);
      }}
      payloadId={message.payload?.id || message.id}
      groupId={message.groupId}
      messageId={message.messageId || message.id}
    />
  );
});
