import { action, computed, observable, values } from 'mobx';

import { CallStatus, IGroupCallChanged } from 'APP/model/call/callModel.types';

import { BackgroundCall } from './BackgroundCall/BackgroundCall';

export class BackgroundCalls {
  @observable data = observable.map<string, BackgroundCall>({}, { deep: false });

  @action reset(): void {
    this.data.clear();
  }

  @action
  add(call: IGroupCallChanged): void {
    const backgroundCall = new BackgroundCall({
      groupId: call.groupId,
      channelId: call.channelId,
      callType: call.callType,
      callProvider: call.callProvider,
    });

    this.data.set(call.groupId, backgroundCall);
  }

  @action
  replace(calls: IGroupCallChanged[]): void {
    this.data.clear();
    calls
      .filter(({ status }) => status === CallStatus.Started)
      .forEach((call) => {
        this.add(call);
      });
  }

  @action
  delete(groupId: string): void {
    this.data.delete(groupId);
  }

  @computed
  get hasCall(): boolean {
    return !!this.data.size;
  }

  get calls(): readonly BackgroundCall[] {
    return values(this.data);
  }

  getCallByGroupId(groupId: string): BackgroundCall | null {
    return this.data.get(groupId) || null;
  }
}
