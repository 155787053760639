import classNames from 'classnames';
import React, { FC } from 'react';

import { useTranslation } from 'APP/packages/translations';
import { Avatar } from 'UIKIT/Avatar/Avatar';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';

import { useCallDescriptionPresenter } from './CallDescription.presenter';
import styles from './CallDescription.styles.m.css';

interface ICallDescriptionProps {
  className?: string;
  isGroupCall: boolean;
}

export const CallDescription: FC<ICallDescriptionProps> = ({ className, isGroupCall }) => {
  const presenter = useCallDescriptionPresenter();
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.container, className)}>
      <Avatar
        className={styles.image}
        type={AvatarTypes.Round_50}
        title={presenter.invitationInfo?.userName}
        url={presenter.invitationInfo?.imageUrl}
      />
      <div className={styles.textBlock}>
        <h2 className={styles.title}>{presenter.invitationInfo?.title}</h2>
        <h3 className={styles.subTitle}>
          {isGroupCall
            ? t('call_invite_link_group')
            : t('call_with_username', { 0: presenter.invitationInfo?.userName })}
        </h3>
      </div>
    </div>
  );
};
