import execCommand from 'execcommand-copy';
import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { showConfirm } from 'APP/Tasks/app/app';
import { openPopup } from 'APP/Tasks/app/popup/popup';
import { showToast } from 'APP/Tasks/app/showToast/showToast';
import { NewsType } from 'APP/model/catalog/catalogModel.types';
import { useTranslation } from 'APP/packages/translations';
import { RouterState } from 'APP/router/constants';
import { ChatMessage } from 'APP/store/Messages/Message/ChatMessage/ChatMessage';
import CloseIcon from 'ICONS/ic-close-large.svg';
import HideIcon from 'ICONS/ic-eye-slash.svg';
import GoToMessageIcon from 'ICONS/ic-go-to-message.svg';
import InfoIcon from 'ICONS/ic-info.svg';
import LinkIcon from 'ICONS/ic-link.svg';
import LogOutIcon from 'ICONS/ic-log-out.svg';
import ReportIcon from 'ICONS/ic-report.svg';
import CopyIcon from 'ICONS/ic-s-copy.svg';
import DeleteIcon from 'ICONS/ic-s-delete.svg';
import ForwardIcon from 'ICONS/ic-s-forward.svg';
import StarIcon from 'ICONS/ic-star-s.svg';
import TranslateIcon from 'ICONS/ic-translate.svg';
import { AddToSpaceCollectionPopup } from 'MAIN/PopupManager/Popups/AddToSpaceCollectionPopup/AddToSpaceCollectionPopup';
import { ForwardMessagePopup } from 'MAIN/PopupManager/Popups/ForwardMessagePopup/ForwardMessagePopup';
import { ReactionsDetailedPopup } from 'MAIN/PopupManager/Popups/ReactionsDetailedPopup/ReactionsDetailedPopup';
import { ReportMessagePopup } from 'MAIN/PopupManager/Popups/ReportMessagePopup/ReportMessagePopup';
import { Popup } from 'STORE/App/Popups/Popup/Popup';
import { TPostInstance } from 'STORE/Newsfeed/Newsfeed.types';
import { MenuItemStyle } from 'UIKIT/MenuItem/MenuItem.types';
import { IPopoverMenuItem } from 'UIKIT/PopoverMenu/PopoverMenu.types';
import { getMessageTextForCopy } from 'UTILS/message/message';

interface IPostMenuItemsParams {
  isShowHide?: boolean;
  isShowAddCollectionItem?: boolean;
  isShowRemoveCollectionItem?: boolean;
  onRemoveCollectionItem?(message: ChatMessage): Promise<void>;
}

interface IPostContextMenuPresenter {
  childPopup: Popup | null;
  getMenuItems(message: ChatMessage, newsItem: TPostInstance): IPopoverMenuItem[];
  onForward(message: ChatMessage): void;
  addToSpaceCollection(newsItem: ChatMessage): void;
  onCopy(message: ChatMessage): void;
  onCopyLink(message: ChatMessage): void;
  onViewInChat(message: ChatMessage): Promise<void>;
  onShowInfo(message: ChatMessage): void;
  onTranslate(message: ChatMessage): void;
  onHide(newsItem: TPostInstance): Promise<void>;
  onReport(message: ChatMessage): void;
  onDelete(message: ChatMessage): void;
  onLeave(message: ChatMessage): Promise<void>;
}

export function usePostMenuItems(params: IPostMenuItemsParams = {}): IPostContextMenuPresenter {
  const { t } = useTranslation();
  const source = useAsObservableSource(params);

  const presenter = useLocalStore<IPostContextMenuPresenter>(() => ({
    childPopup: null,

    getMenuItems(message: ChatMessage, newsItem: TPostInstance): IPopoverMenuItem[] {
      const items = [
        {
          key: 'forward',
          title: t('message_forward'),
          icon: ForwardIcon,
          isVisible: message.canBeForward,
          onClick: () => presenter.onForward(message),
        },
        {
          key: 'add-to-space-collection',
          title: t('space_add_to_collection'),
          icon: StarIcon,
          isVisible: source.isShowAddCollectionItem && message.canAddToSpaceCollection,
          onClick: () => presenter.addToSpaceCollection(message),
        },
        {
          key: 'copy',
          title: t('message_copy'),
          icon: CopyIcon,
          isVisible: message.canBeCopied,
          onClick: () => presenter.onCopy(message),
        },
        {
          key: 'deeplink',
          title: t('channel_deeplink_copy_link'),
          icon: LinkIcon,
          isVisible: message.canBeCreateLink,
          onClick: () => presenter.onCopyLink(message),
        },
        {
          key: 'view-in-chat',
          title: t('space_view_chat_button'),
          icon: GoToMessageIcon,
          isVisible: true,
          onClick: () => presenter.onViewInChat(message),
        },
        {
          key: 'info',
          title: t('group_chat_actions_sheet_info'),
          icon: InfoIcon,
          isVisible: message.canBeReactionsOrViews,
          onClick: () => presenter.onShowInfo(message),
        },
        {
          key: 'translate',
          title: t('common_translate'),
          icon: TranslateIcon,
          isVisible: message.canBeTranslated,
          onClick: () => presenter.onTranslate(message),
        },
        {
          key: 'hide',
          title:
            newsItem.type === NewsType.GuestPost
              ? t('newsfeed_not_interested')
              : t('newsfeed_hide'),
          icon: HideIcon,
          isVisible: !newsItem.isShowHiddenInfo && source.isShowHide,
          onClick: () => presenter.onHide(newsItem),
        },
        {
          key: 'report',
          title: t('common_report'),
          icon: ReportIcon,
          isVisible: message.canBeReported,
          onClick: () => presenter.onReport(message),
        },
        {
          key: 'removeFromCollection',
          title: t('space_remove_post_collection'),
          icon: CloseIcon,
          style: MenuItemStyle.Danger,
          isVisible: !!source.isShowRemoveCollectionItem,
          onClick: () => source.onRemoveCollectionItem!(message),
        },
        {
          key: 'delete',
          title: t('chat_list_delete_single_delete_button'),
          icon: DeleteIcon,
          style: MenuItemStyle.Danger,
          isVisible: message.canBeDelete,
          onClick: () => presenter.onDelete(message),
        },
        {
          key: 'leave',
          title: t('channel_leave'),
          icon: LogOutIcon,
          style: MenuItemStyle.Danger,
          isVisible: message.group?.isMember,
          onClick: () => presenter.onLeave(message),
        },
      ];

      return items.filter((item) => item.isVisible);
    },

    onForward(message: ChatMessage): void {
      presenter.childPopup = openPopup(ForwardMessagePopup, {
        params: { groupId: message.groupId, messageIds: [message.id] },
      });
    },

    addToSpaceCollection(message: ChatMessage): void {
      openPopup(AddToSpaceCollectionPopup, {
        params: { groupId: message.groupId, messageId: message.id },
      });
    },

    onCopy(message: ChatMessage): void {
      execCommand.copy(getMessageTextForCopy(message, false));
    },

    onCopyLink(message: ChatMessage): void {
      Tasks.messaging.copyDeepLink(message);
    },

    async onViewInChat(message: ChatMessage): Promise<void> {
      if (message.group?.withoutMe && !message.group?.isOpen) {
        showToast(t('space_alert_post_private'));
        return;
      }

      Tasks.messaging.focusMessage({
        groupId: message.groupId,
        messageId: message.id,
      });

      await Tasks.group.setActiveGroup({
        groupId: message.groupId,
        state: {
          [RouterState.hasGoBack]: true,
        },
      });
    },

    onShowInfo(message: ChatMessage): void {
      presenter.childPopup = openPopup(ReactionsDetailedPopup, {
        params: { groupId: message.groupId, messageId: message.id },
      });
    },

    onTranslate(message: ChatMessage): void {
      message.translate();
    },

    async onHide(newsItem: TPostInstance): Promise<void> {
      const result = await Tasks.newsfeed.hideNewsfeedPost(newsItem);
      if (!result) {
        showToast(t('something_went_wrong'));
      }
    },

    onReport(message: ChatMessage): void {
      presenter.childPopup = openPopup(ReportMessagePopup, {
        params: { groupId: message.groupId, messageId: message.id },
      });
    },

    async onDelete(message: ChatMessage): Promise<void> {
      const canDelete = await showConfirm({ text: t('delete_message') });

      if (!canDelete) {
        return;
      }

      message.delete();
    },

    async onLeave(message: ChatMessage): Promise<void> {
      const result = await Tasks.group.leaveGroup({
        groupId: message.groupId,
      });

      if (!result?.error) {
        showToast(t('news_feed_you_unsubscribed'));
      }
    },
  }));

  useEffect(() => {
    return () => presenter.childPopup?.close();
  }, []);

  return presenter;
}
