import { useLocalStore } from 'mobx-react';

export default (message) => {
  const presenter = useLocalStore(() => ({
    get payload() {
      return message.payload.payloads[0].payload;
    },
    get imageUrl() {
      if (presenter.payload.payloadType === 'Image') {
        return presenter.payload.url;
      }
      return presenter.payload.previewUrl;
    },

    get graphicSize() {
      return presenter.payload.graphicSize;
    },
  }));

  return presenter;
};
