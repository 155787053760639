import api from 'APP/packages/api';
import Entities from 'APP/store';

async function removeGroupAvatarRequest(data) {
  try {
    await api.group.removeGroupAvatar(data);
  } catch (error) {
    console.error(error);
  }

  return null;
}

async function updateRequest(data) {
  try {
    return await api.group.updateGroupInfo(data);
  } catch (error) {
    console.error(error);
  }

  return null;
}

export const updateGroupInfo = async (data) => {
  const group = Entities.GroupsStore.getGroupById(data.groupId);

  if (group && group.avatarUrl && !data.avatarUrl) {
    await removeGroupAvatarRequest({
      groupId: data.groupId,
    });
  }

  // SERVER IS SENSITIVE TO NULL VALUES, DON'T CHANGE THEM
  const personalizedGroup = await updateRequest({
    groupId: data.groupId,
    avatarUrl: data.avatarUrl || null,
    name: (data.name || '').trim(),
    description: data.description || '',
    groupType: data.type,
    categoryId: data.categoryId || '',
    donationUrl: data.donationUrl || '',
    tags: [],
    deepLinkAlias: data.deepLinkAlias || null, // keep previous deep link
  });

  if (personalizedGroup) {
    Entities.GroupsStore.merge([personalizedGroup]);
  }
};
