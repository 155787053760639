import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import popupItemStyles from 'UIKIT/PopupItem/styles.m.scss';

import { CallMember } from '../../../Shared/CallMember';
import { usePresenter } from './presenter';

export const CallMemberItem = observer(({ userId }) => {
  const presenter = usePresenter(userId);

  const classes = classNames(popupItemStyles.container, popupItemStyles.interactive);

  return (
    <CallMember
      userId={userId}
      onClick={presenter.onMemberClick}
      className={classes}
    />
  );
});
