import { action, observable } from 'mobx';

import { IGroupCounterView } from 'APP/model/counter/counterModel.types';
import { Message } from 'STORE/Messages/Message/Message';

export class Counter {
  groupId: string;
  lastEventId: string | null = null;
  @observable counter = 0;
  @observable unreadUserMentionsCounter = 0;
  @observable lastUserMentionTs = 0;
  @observable seenTs = 0;
  @observable opponentSeenTs = 0;
  @observable lastEventTs = 0;
  @observable lastMessageTs = 0;
  @observable lastMessage: Message | null = null;
  @observable counterTs = 0;
  @observable totalMessagesCount = 0;

  constructor(data: IGroupCounterView) {
    this.groupId = data.groupId.toString();
    this.counter = data.counter || 0;

    this.unreadUserMentionsCounter = data.unreadUserMentionsCounter || 0;

    this.lastUserMentionTs = data.lastUserMentionTs || 0;

    this.seenTs = data.seenTs || 0;

    this.opponentSeenTs = data.opponentSeenTs || 0;

    this.lastEventTs = data.lastEventTs || 0;

    this.lastEventId = data.lastEvent?.id || null;

    this.lastMessageTs = data.lastMessageTs || 0;

    this.totalMessagesCount = data.totalMessagesCount || 0;

    this.lastMessage = data.lastMessage ? new Message(data.lastMessage) : null;

    this.counterTs = Math.max(
      this.lastEventTs,
      this.lastMessageTs,
      this.seenTs,
      this.opponentSeenTs
    );
  }

  @action
  clear(): void {
    this.counter = 0;
    this.unreadUserMentionsCounter = 0;
    this.lastUserMentionTs = 0;
    // seenTs does not change when clearing history
    //this.seenTs = 0;
    this.opponentSeenTs = 0;
    // we can't get the latest events
    //this.lastEventTs = 0;
    this.lastMessageTs = 0;
    this.lastMessage = null;
  }

  @action
  setSeenTs(time: number): void {
    const newTime = time;

    if (newTime > this.seenTs) {
      this.seenTs = newTime;
    }
    if (this.lastMessageTs <= newTime) {
      this.unreadUserMentionsCounter = 0;
      this.counter = 0;
    }
  }

  @action
  setTotalMessagesCount(count: number): void {
    this.totalMessagesCount = count;
  }
}
