import React from 'react';

import MSLogo from 'ICONS/ms-log.svg';

import styles from './InAppViewLoader.styles.m.css';

export const Loader = () => {
  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <MSLogo />
      </div>
    </div>
  );
};
