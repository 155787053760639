import GlobalTasks from 'APP/Tasks';
import { DEFAULT_ACCOUNT_COUNTRY } from 'APP/constants/countryCodes';
import Entities from 'APP/store';
import { ICountry } from 'APP/types/countryCodes';
import { sortCountriesByPriority } from 'UTILS/countries';
import { replacePhoneTemplate } from 'UTILS/phone';

export const getAccountCountry = async (phoneNumber: string): Promise<ICountry> => {
  const { availableCounties } = await GlobalTasks.countriesPhoneCodes.getCountriesPhoneCodes();
  const accountCountry = availableCounties
    .slice()
    .sort(sortCountriesByPriority)
    .find(({ phonePrefix }) => `+${phoneNumber}`.indexOf(phonePrefix) === 0);

  if (accountCountry) {
    accountCountry.phoneTemplate = replacePhoneTemplate(accountCountry.phoneTemplate);
    Entities.App.setAccountCountry(accountCountry);
    return accountCountry;
  }
  return DEFAULT_ACCOUNT_COUNTRY;
};
