import classNames from 'classnames';
import React, { FC } from 'react';

import { ImagePreview } from 'APP/components/ImagePreview/ImagePreview';
import { MINIATURE_PREVIEW_MAX_SIZE } from 'APP/constants/messages';
import { AudioState } from 'MAIN/components/AudioState/AudioState';
import { AudioPlayerTrackCaption } from 'UIKIT/Media/AudioPlayer/AudioPlayerUI/AudioPlayerTrackCaption/AudioPlayerTrackCaption';
import { PlayIconType } from 'UIKIT/PlayIcon/PlayIcon';

import { useSharedCircleVideoPlayerPresenter } from './SharedCircleVideoPlayer.presenter';
import styles from './SharedCircleVideoPlayer.styles.m.css';

interface ISharedCircleVideoPlayerProps {
  className?: string;
  messageId: string;
  groupId: string;
  payloadId: string;
  fileName: string;
  size: number;
  isPlaying: boolean;
  isActiveTrack: boolean;
  duration: number;
  progress: number;
  serverTime: number;
  onTogglePlay(): void;
}

export const SharedCircleVideoPlayer: FC<ISharedCircleVideoPlayerProps> = (props) => {
  const {
    className,
    messageId,
    groupId,
    payloadId,
    fileName,
    size,
    isPlaying,
    isActiveTrack,
    duration,
    progress,
    serverTime,
    onTogglePlay,
  } = props;

  const presenter = useSharedCircleVideoPlayerPresenter({ groupId, messageId, serverTime });

  return (
    <div className={classNames(styles.container, className)}>
      <AudioState
        className={styles.button}
        groupId={groupId}
        messageId={messageId}
        payloadId={payloadId}
        iconType={isPlaying ? PlayIconType.Pause : PlayIconType.Play}
        onClick={onTogglePlay}
        Icon={
          <ImagePreview
            className={styles.icon}
            url={presenter.payload!.previewUrl}
            graphicSize={presenter.payload!.graphicSize}
            maxWidth={MINIATURE_PREVIEW_MAX_SIZE.width}
            maxHeight={MINIATURE_PREVIEW_MAX_SIZE.height}
            needCropImage
          />
        }
      />
      <div>
        <div className={styles.title}>{fileName}</div>

        <AudioPlayerTrackCaption
          isPlaying={isActiveTrack}
          duration={duration}
          progress={progress}
          size={size}
          additionalInfo={presenter.dateTime}
          delimiter={' ⋅'}
        />
      </div>
    </div>
  );
};
