import { BaseCache } from 'APP/cache/baseCache';

export class AnimationsCache extends BaseCache {
  protected dbName = 'animations';

  protected lottieObjectStoreName = 'lottieData';
  protected framesStoreName = 'frames';

  constructor() {
    super();

    this.openDb();
  }

  protected initDb(): void {
    this.createStore(this.lottieObjectStoreName);
    this.createStore(this.framesStoreName);
  }

  public getLottieObject(id: string): Promise<Record<string, any> | null> {
    return this.get(this.lottieObjectStoreName, id);
  }

  public putLottieObject(
    id: string,
    jsonString: Record<string, any>
  ): IDBRequest<IDBValidKey> | null {
    return this.put(this.lottieObjectStoreName, id, jsonString);
  }

  public getFrame(id: string): Promise<ImageData | null> {
    return this.get(this.framesStoreName, id);
  }

  public putFrame(id: string, imageData: ImageData): IDBRequest<IDBValidKey> | null {
    return this.put(this.framesStoreName, id, imageData);
  }
}

export const animationsCache = new AnimationsCache();
