import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { TextRenderer } from 'APP/components/TextRenderer/TextRenderer';

import { IPayloadProps } from '../Payloads.types';
import { MiniatureMessageLayout } from '../components/MiniatureMessageLayout/MiniatureMessageLayout';
import styles from './ButtonsPayload.styles.m.scss';

export const ButtonsPayload: FC<IPayloadProps> = observer((props) => {
  const { titleClassName, textClassName, message, title, onClick } = props;
  const { text, typeDescription } = message.payload;

  if (!message) {
    return null;
  }

  return (
    <MiniatureMessageLayout
      titleClassName={titleClassName}
      title={title}
      messageId={message.id}
      onClick={onClick}
    >
      <TextRenderer
        isPlainText
        className={classNames(styles.text, textClassName)}
      >
        {text || typeDescription}
      </TextRenderer>
    </MiniatureMessageLayout>
  );
});
