import { useLocalStore, useAsObservableSource } from 'mobx-react';
import { useEffect } from 'react';

export interface ILocalesListLocalPresenter {
  selectedCodes: Set<string>;
  onSelect(codes: Set<string>): void;
  onClose(): void;
}

interface ILocalesListLocalStore {
  selectedCodes: Set<string>;
  canChoose: boolean;
  isSelected(code: string): boolean;
  onToggleSelection(code: string): void;
  onClose(): void;
  onChoose(): void;
}

const SELECTED_COUNT_MAX = 7;

export const useLocalesListPresenter = ({
  selectedCodes,
  onSelect,
  onClose,
}: ILocalesListLocalPresenter) => {
  const source = useAsObservableSource({ onSelect, onClose });

  const presenter = useLocalStore<ILocalesListLocalStore>(() => ({
    selectedCodes: new Set(),

    get canChoose(): boolean {
      return presenter.selectedCodes.size <= SELECTED_COUNT_MAX;
    },

    isSelected(code: string): boolean {
      return presenter.selectedCodes.has(code);
    },

    onToggleSelection(code: string): void {
      if (presenter.selectedCodes.has(code)) {
        presenter.selectedCodes.delete(code);
      } else {
        presenter.selectedCodes.add(code);
      }
    },

    onClose(): void {
      source.onClose();
    },

    onChoose(): void {
      if (presenter.canChoose) {
        presenter.onClose();
        source.onSelect(presenter.selectedCodes);
      }
    },
  }));

  useEffect(() => {
    presenter.selectedCodes = new Set(selectedCodes);
  }, [selectedCodes]);

  return presenter;
};
