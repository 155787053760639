import classNames from 'classnames';
import React, {useEffect, useMemo, useRef, useState} from 'react';

import IcLock from 'ICONS/ic-lock-16-alt.svg';
import { IconView } from 'UIKIT/IconView/IconView';
import { IconViewSizes } from 'UIKIT/IconView/IconView.types';

import styles from './EditProfileBadgeLock.styles.m.css';

interface IEditProfileBadgeLockProps {
  isVisible?: boolean;
  onHidingEnd?(): void;
}

const ANIMATION_DURATION = 500;

export const EditProfileBadgeLock: React.FC<IEditProfileBadgeLockProps> = (props) => {
  const [isVisible, setIsVisible] = useState(true);
  const isLoadedRef = useRef<boolean>(false);

  useEffect(() => {
    if (!isLoadedRef.current) {
      isLoadedRef.current = true;

      return;
    }

    if (!props.isVisible) {
      setIsVisible(false);
      setTimeout(
        () => {
          props.onHidingEnd && props.onHidingEnd();
        }, ANIMATION_DURATION
      )
    }
  }, [props.isVisible]);

  const style = useMemo(
    () => !isVisible ? ({ transition: `all, ${ANIMATION_DURATION}ms`}) : {}, [isVisible]
  );

  return (
    <div
      className={classNames(styles.iconContainer, { [styles.hidden]: !isVisible })}
      style={style}
    >
      <IconView
        className={styles.icon}
        Icon={IcLock}
        size={IconViewSizes.Small}
      />
    </div>
  );
};
