import { useLocalStore } from 'mobx-react';
import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { RouterLink } from 'APP/router/constants';

import { HubPageType } from './Hub.types';

interface IHubPresenter {
  isHidden: boolean;
  currenPage: HubPageType;
  setPage(page: HubPageType): void;
  setIsHidden(isHidden: boolean): void;
}

export const useHubPresenter = () => {
  const location = useLocation();
  const presenter = useLocalStore<IHubPresenter>(() => ({
    isHidden: true,
    currenPage: HubPageType.Main,

    setPage(page: HubPageType) {
      presenter.currenPage = page;
    },

    setIsHidden(isHidden: boolean) {
      presenter.isHidden = isHidden;
    },
  }));

  useLayoutEffect(() => {
    if (location.pathname === RouterLink.hub) {
      if (!presenter.isHidden && presenter.currenPage !== HubPageType.Main) {
        presenter.setPage(HubPageType.Main);
      }
      presenter.setIsHidden(false);
    }

    if (!presenter.isHidden && location.pathname !== RouterLink.hub) {
      presenter.setIsHidden(true);
    }
  }, [location]);

  return presenter;
};
