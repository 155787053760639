import classNames from 'classnames';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { RENDER_TYPES } from 'MAIN/ChatView/Messages/Message/Payload/System/System.presenter';

import { UserLink } from '../UserLink/UserLink';
import { UsersLink } from '../UsersLink/UsersLink';
import styles from './SystemMessage.styles.m.css';

export const SystemMessage = ({ renderItems, message }) => {
  const isMultipleUsers = renderItems.some((item) => item.type === RENDER_TYPES.USERS);

  if (isMultipleUsers) {
    return (
      <span>
        {renderItems.map((item) => {
          if (item.type === RENDER_TYPES.USERS) {
            return (
              <UsersLink
                key={uuidv4()}
                usersIds={item.usersIds}
                groupId={message.groupId}
                messageId={message.id}
              >
                <span className={classNames('system-message-title', styles.usersLink)}>
                  {item.text}
                </span>
              </UsersLink>
            );
          }
          return <span key={uuidv4()}>{item.text}</span>;
        })}
      </span>
    );
  }

  return renderItems.map((item) => {
    if (item.type === RENDER_TYPES.USER && item.userId) {
      return (
        <UserLink
          key={item.userId}
          userId={item.userId}
          groupId={message.groupId}
          messageId={message.id}
        >
          {item.text}
        </UserLink>
      );
    }
    return item.text;
  });
};
