import { IChangePhoneConfirmVerifyBody } from 'APP/model/auth/authModel.types';
import { authModel } from 'APP/model/model';
import { AuthServerErrorCode } from 'APP/packages/api/constants/auth.errors';
import { platformInfo } from 'APP/packages/platformInfo/platformInfo';
import { getUserLanguage } from 'APP/utils/getUserLanguage';
import Entities from 'STORE';

export const changePhoneConfirmVerifyCode = async ({
  verificationCode,
}: IChangePhoneConfirmVerifyBody): Promise<{
  error: string | null;
  remainAttempts: number | null;
}> => {
  try {
    const { remainAttempts, success } = await authModel.changePhoneConfirmVerifyCode({
      registrationKey: '',
      verificationCode,
      device: {
        platform: platformInfo.platform,
        appVersion: Entities.App.version,
        locale: getUserLanguage(),
        device: platformInfo.device,
        osVersion: platformInfo.osVersion,
      },
    });

    if (!success) {
      return { remainAttempts, error: AuthServerErrorCode.InvalidVerificationCode };
    }
    return { remainAttempts, error: null };
  } catch (error) {
    return { remainAttempts: null, error: error.message };
  }
};
