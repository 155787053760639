import Entities from 'STORE';

async function getAndCopyText<TResponse>(
  method: () => Promise<TResponse>,
  adapter: (response: TResponse) => string,
  message: string
): Promise<void> {
  const response = await method();
  const text = adapter(response);
  window.navigator.clipboard.writeText(text);
  Entities.toast.show(message);
}

function getAndCopyTextThroughClipboardItem<TResponse>(
  method: () => Promise<TResponse>,
  adapter: (response: TResponse) => string,
  message: string
): void {
  const text = new ClipboardItem({
    'text/plain': method()
      .then(adapter)
      .then((text) => new Blob([text], { type: 'text/plain' })),
  });
  window.navigator.clipboard.write([text]);
  Entities.toast.show(message);
}

interface IAsyncCopyToClipboardParams<TResponse> {
  method: () => Promise<TResponse>;
  adapter: (response: TResponse) => string;
  message: string;
}

// Safari does not allow asynchronous copying to the clipboard,
// so the ClipboardItem interface is used for this action (see "getAndCopyTextThroughClipboardItem" func)

// But firefox doesn't support the ClipboardItem, so we use classic
// window.navigator.clipboard.writeText(text) after async action (see "getAndCopyText" func)
export function asyncCopyToClipboard<TResponse>({
  method,
  adapter,
  message,
}: IAsyncCopyToClipboardParams<TResponse>): void {
  if (!window.ClipboardItem) {
    getAndCopyText(method, adapter, message);
    return;
  }

  getAndCopyTextThroughClipboardItem(method, adapter, message);
}
