import api from 'APP/packages/api';
import logger from 'APP/packages/logger';

export const createPermissionRequest = async ({ channelId, access }) => {
  try {
    return await api.calls.createPermissionRequest({
      channelId,
      access,
    });
  } catch (error) {
    logger.get('API').error('calls.createPermissionRequest', error);
    return error;
  }
};
