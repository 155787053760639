// format phone number.
// '375291111111' -> '+375 29 111 11 11' with template '+XXX XX XX-XX-XX'
// '375291' -> '+375 29 1' with template '+XXX XX XX-XX-XX'
import { DEFAULT_PHONE_MASK } from 'APP/constants/countryCodes';

export const parsePhone = (number: string, phoneTemplate: string) => {
  const phoneNumber = number;
  const chunks = phoneTemplate.replace(/[-]/g, ' ').slice(1).split(' ');
  //phoneNumber = phoneNumber.slice(0, chunks.join('').length);
  let start = 0;
  const formatNumber = chunks.reduce((acc, chunk, index) => {
    const x = phoneNumber.slice(start, start + chunk.length);
    start += chunk.length;
    if (x) {
      if (index === 0) {
        return x;
      }
      if (index === 1 && chunks.length > 2) {
        return `${acc} ${x}`;
      }
      return `${acc} ${x}`;
    }
    return acc;
  }, '');
  return `+${formatNumber}${phoneNumber.slice(start)}`;
};

export const replacePhoneTemplate = (phoneTemplate?: string, replaceValue = '0') => {
  if (!phoneTemplate) {
    return DEFAULT_PHONE_MASK;
  }
  return phoneTemplate.replace(/[-]/g, ' ').replace(/[X]/g, replaceValue);
};

export const clearPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) {
    return phoneNumber;
  }
  return phoneNumber.replace(/[^\d]/g, '');
};
