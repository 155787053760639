import { useLocalStore, useAsObservableSource } from 'mobx-react';

import { useTranslation } from 'APP/packages/translations';
import type { User } from 'STORE/Users/User/User';
import { TAvatarBackgroundColorIndex } from 'UIKIT/Avatar/Avatar.types';

interface IBlockedUserPresenter {
  user: User;
  avatarTitle: string;
  avatarUrl: string;
  title: string;
  subtitle: string;
  isLoading: boolean;
  avatarBackgroundColorIndex: TAvatarBackgroundColorIndex;
  isDisabled: boolean;
  buttonText: string;
  toggleBlockUser(): void;
}

export const useBlockedUserPresenter = (
  blockedUser: User,
  blockUser: (id: string) => void,
  unblockUser: (id: string) => void
): IBlockedUserPresenter => {
  const { t } = useTranslation();

  const source = useAsObservableSource({ blockedUser });

  const presenter = useLocalStore<IBlockedUserPresenter>(() => ({
    get user(): User {
      return source.blockedUser;
    },

    get isLoading(): boolean {
      if (!presenter.user) {
        return false;
      }

      return presenter.user.isLoadingBlockedByMe;
    },

    toggleBlockUser(): void {
      if (presenter.user.isBlockedByMe) {
        unblockUser(presenter.user.id);
      } else {
        blockUser(presenter.user.id);
      }
    },

    get avatarTitle(): string {
      return presenter.user.avatarTitle;
    },

    get avatarUrl(): string {
      return presenter.user.avatarUrl || '';
    },

    get avatarBackgroundColorIndex(): TAvatarBackgroundColorIndex {
      return presenter.user.avatarColorIndex;
    },

    get title(): string {
      return presenter.user.displayName;
    },

    get subtitle(): string {
      return presenter.user.displayPhoneOrNickname;
    },

    get isDisabled(): boolean {
      return presenter.isLoading;
    },

    get buttonText(): string {
      return presenter.user.isBlockedByMe ? t('unblock_user') : t('block_user');
    },
  }));

  return presenter;
};
