import getActualSortedOrder from './getActualSortedOrder';
import getPayloadByOrder from './getPayloadByOrder';
import getPayloadsAfterSetPayload from './getPayloadsAfterSetPayload';
import getPayloadsAfterSetPayloads from './getPayloadsAfterSetPayloads';
import getPayloadsDeleteByOrder from './getPayloadsDeleteByOrder';
import getPayloadsOnDrag from './getPayloadsOnDrag';
import replaceHtmlTags from './replaceHtmlTags';

export default {
  getPayloadsAfterSetPayload,
  getPayloadsAfterSetPayloads,
  getActualSortedOrder,
  getPayloadsDeleteByOrder,
  getPayloadsOnDrag,
  getPayloadByOrder,
  replaceHtmlTags,
};
