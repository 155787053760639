import api from 'APP/packages/api';
import dateService from 'APP/packages/date';
import logger from 'APP/packages/logger';
import Entities from 'APP/store';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

import { createMessageData } from '../../messaging/createNewMessages/convertMessageToChatMessage/createMessageData';

export default async ({ message }) => {
  const group = Entities.GroupsStore.activeGroup;

  if (!message || !group) {
    return;
  }

  const messageData = createMessageData({ message });

  try {
    const draft = await api.messaging.saveDraft({
      ...messageData,
      clientTime: dateService.now().getTime(),
    });

    const chatDraft = new ChatMessage(draft, group.messagesStore);

    group.draftsStore.setDraft(chatDraft);
  } catch (e) {
    logger.get('API').debug('messaging.saveDraft', e);
  }
};
