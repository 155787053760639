import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { AddKeyPressHandler } from 'APP/packages/keyBoardHandler';
import { ChatBackground } from 'MAIN/components/ChatBackground/ChatBackground';

import { PinMessages } from '../Messages/PinMessages/PinMessages';
import { useAllPinsPresenter } from './AllPins.presenter';
import styles from './AllPins.styles.m.css';
import { AllPinsFooter } from './AllPinsFooter/AllPinsFooter';
import { AllPinsHeader } from './AllPinsHeader/AllPinsHeader';

interface IAllPinsProps {
  groupId: string;
}

export const AllPins: FC<IAllPinsProps> = observer(({ groupId }) => {
  const presenter = useAllPinsPresenter(groupId);

  if (!presenter.group) {
    return null;
  }

  return (
    <div className={styles.container}>
      <AddKeyPressHandler onEsc={presenter.onBack} />
      <ChatBackground />
      <AllPinsHeader groupId={groupId} />
      <PinMessages groupId={groupId} />
      <AllPinsFooter groupId={groupId} />
    </div>
  );
});
