import React from 'react';

import { CommonPayload } from '../CommonPayload/CommonPayload';
import { useStreamPayloadPresenter } from './StreamPayload.presenter';

export const StreamPayload = ({ payload }) => {
  const presenter = useStreamPayloadPresenter(payload);

  return <CommonPayload description={presenter.text} />;
};
