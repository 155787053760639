import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Group } from 'STORE/Groups/Group';
import { Schedule } from 'STORE/Groups/Schedule';
import { Thread } from 'STORE/Groups/Thread';

import BotChatInfo from './BotChatInfo';
import { ChannelChatInfo } from './ChannelChatInfo/ChannelChatInfo';
import { useChatInfoPresenter } from './ChatInfo.presenter';
import styles from './ChatInfo.styles.m.css';
import GroupChatInfo from './GroupChatInfo';
import { P2PChatInfo } from './P2PChatInfo/P2PChatInfo';
import SavedMessagesInfo from './SavedMessagesInfo';
import ScheduleInfo from './ScheduleInfo';
import { ThreadInfo } from './ThreadInfo/ThreadInfo';

interface IChatInfoProps {
  group: Group;
}

export const ChatInfo: FC<IChatInfoProps> = observer(({ group }) => {
  const presenter = useChatInfoPresenter();

  let Component;
  if (group instanceof Thread) {
    return <ThreadInfo />;
  } else if (group.isChannel) {
    return <ChannelChatInfo group={group} />;
  } else if (group instanceof Schedule) {
    return <ScheduleInfo group={group} />;
  }

  if (group.isP2P && group.isBot) {
    Component = BotChatInfo;
  } else if (group.isP2P && !group.isBot) {
    Component = P2PChatInfo;
  } else if (group.isChatGroup) {
    Component = GroupChatInfo;
  } else if (group.isSavedMessages) {
    Component = SavedMessagesInfo;
  }

  if (!Component) {
    return null;
  }

  return (
    <button
      className={styles.container}
      onClick={presenter.toggleProfileSidebar}
    >
      <Component group={group} />
    </button>
  );
});
