import { STREAM_STATUS } from 'APP/constants/stream';
import Entities from 'APP/store';

function handleBackgroundStream(stream) {
  if (stream.status === STREAM_STATUS.STARTED) {
    Entities.BackgroundStreams.add(stream);
  } else if (stream.status === STREAM_STATUS.FINISHED) {
    Entities.BackgroundStreams.delete(stream.groupId);
  }
}

function handleActiveStream(stream) {
  if (Entities.ActiveStream.stream && Entities.ActiveStream.stream.channelId === stream.channelId) {
    Entities.ActiveStream.stream.updateStream({
      ...stream,
    });
  }
}

export default (stream) => {
  handleBackgroundStream(stream);
  handleActiveStream(stream);
};
