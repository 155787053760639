import Entities from 'APP/store';

export default async ({ groupId, messageId }) => {
  const group = Entities.GroupsStore.getGroupById(groupId);
  if (!group || group.messagesStore.inViewMessageIds.includes(messageId)) {
    return;
  }

  group.messagesStore.setInViewMessageIds([...group.messagesStore.inViewMessageIds, messageId]);
};
