import Tasks from 'APP/Tasks';
import { CallEventTypes } from 'APP/constants/calls';
import api, { ServerErrorCode } from 'APP/packages/api';
import logger from 'APP/packages/logger';
import { t } from 'MODULE/i18n/service';

const showErrorNotAdmin = (): void => {
  Tasks.app.showConfirm({
    text: t('calls_user_not_admin'),
    primaryText: t('common_ok'),
    secondaryText: null,
  });
};

const onRemove = async (
  userId: string,
  channelId: string,
  alsoRemoveFromChat: boolean
): Promise<void> => {
  if (alsoRemoveFromChat) {
    try {
      await api.calls.removeCallMember({
        channelId,
        userId,
      });
    } catch (e) {
      if (e?.message === ServerErrorCode.HasNoPermissionsToPerformAction) {
        showErrorNotAdmin();
      }
    }
  } else {
    try {
      await Tasks.calls.setActionCall({
        action: CallEventTypes.ForceFinish,
        channelId,
        userId,
      });
    } catch (e) {
      logger.get('Tasks').error('calls.removeMemberFromCall', e);
      console.error(e);
    }
  }
};

export const removeMemberFromCall = (userId: string, channelId: string): void => {
  Tasks.app.showConfirmWithCheckbox({
    checkBoxText: t('calls_also_remove_from_chat'),
    primaryText: t('common_remove'),
    secondaryText: t('common_cancel'),
    async onSuccess(alsoRemoveFromChat: boolean) {
      await onRemove(userId, channelId, alsoRemoveFromChat);
    },
  });
};
