import { DeviceInfoPlatform } from 'APP/model/common/commonModel.types';
import { AbortConfirmationRequest } from 'APP/packages/api/types/model/abortConfirmationRequest';
import { ClientConfig } from 'APP/packages/api/types/model/clientConfig';
import { ClientConfigProperty } from 'APP/packages/api/types/model/clientConfigProperty';
import { ConfirmBySmsResponse } from 'APP/packages/api/types/model/confirmBySmsResponse';
import { ConfirmVerifyCodeRequest } from 'APP/packages/api/types/model/confirmVerifyCodeRequest';
import { DeviceInfo } from 'APP/packages/api/types/model/deviceInfo';
import { ExternalAuthProviderId } from 'APP/packages/api/types/model/externalAuthProviderId';
import { RegisterResponse } from 'APP/packages/api/types/model/registerResponse';
import {
  VerifyCodeRequest,
  VerifyCodeRequestVerifyFlowEnum,
} from 'APP/packages/api/types/model/verifyCodeRequest';

export interface IRegisterResponse {
  registrationKey: string;
}

export interface IDeviceInfoV2 {
  platform: DeviceInfoPlatform;
  device: string;
  osVersion: string;
  appVersion: string;
  locale: string;
}

export interface IDeviceInfo extends Omit<DeviceInfo, 'platform'> {
  platform: DeviceInfoPlatform;
}

export interface IVerifyOauthTokenRequest {
  token: string;
  authProviderId: AuthProvider;
  deviceInfo: IDeviceInfoV2;
  captchaToken?: string;
  referralLink?: string;
}

export interface IAccountProvider {
  authProviderId: AuthProvider;
  displayName: string;
  userId: string;
}

export interface IExternalAuthClientUserInfo {
  userName?: string;
  email?: string;
}

export interface ILinkExternalAuthAccountRequest {
  token: string;
  authProviderId: AuthProvider;
  userInfo?: IExternalAuthClientUserInfo;
}

export interface ILinkExternalAuthAccountResponse {
  linkedAccount: IAccountProvider;
  autoLinkedAccounts: IAccountProvider[];
}

export interface IChangeRequestVerifyCodeBody {
  phone: string;
}

export type IChangePhoneVerifyCodeRequest = Omit<
  VerifyCodeRequest,
  'deviceInfo' | 'VerifyCodeFlow'
> & {
  deviceInfo: IDeviceInfo;
  verifyFlow: VerifyCodeFlow;
};
export type IChangePhoneVerifyCodeResponse = RegisterResponse;

export type IChangePhoneConfirmVerifyRequest = Omit<ConfirmVerifyCodeRequest, 'device'> & {
  device: IDeviceInfoV2;
};

export interface IChangePhoneConfirmVerifyBody {
  verificationCode: string;
}

export type IAbortConfirmationRequest = AbortConfirmationRequest;

export type IChangePhoneConfirmVerifyResponse = ConfirmBySmsResponse;

export interface IUnlinkExternalAuthAccountRequest {
  authProviderId: AuthProvider;
}

export type IClientConfigProperty = ClientConfigProperty;
export type IClientConfig = ClientConfig;

export type VerifyCodeFlow = VerifyCodeRequestVerifyFlowEnum;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VerifyCodeFlow = VerifyCodeRequestVerifyFlowEnum;

export type AuthProvider = ExternalAuthProviderId;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AuthProvider = ExternalAuthProviderId;
