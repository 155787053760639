import { RouterState } from 'APP/router/constants';
import { getLocation } from 'APP/router/refs';
import { replaceTo } from 'APP/router/refs/replaceTo';

export const closeMediaGallery = (): void => {
  const location = getLocation();

  const prevState = location.state || {};
  delete prevState[RouterState.mediaGallery];

  replaceTo({
    state: prevState,
    keepOldQuery: true,
    keepOldState: true,
    keepOldPathname: true,
  });
};
