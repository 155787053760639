import { useLocalStore, useAsObservableSource } from 'mobx-react';

import { useImageResizer } from 'APP/main/hooks/useImageResizer/useImageResizer';

export const useYouTubePreviewPresenter = ({
  id,
  data,
  maxWidth = 1280,
  maxHeight = 720,
  onStartPlay,
}) => {
  const resizedImage = useImageResizer({
    url: data.image?.url,
    maxWidth: maxWidth,
    maxHeight: maxHeight,
    graphicSize: data?.imageGraphicSize || data.image?.graphicSize || { width: 0, height: 0 },
  });

  const source = useAsObservableSource({
    id,
    link: data?.link || '',
    title: data.title || '',
    video: data.video || {},
    previewUrl: data.image?.url,
    previewGraphicSize: data?.imageGraphicSize || data.image?.graphicSize,
    onStartPlay: onStartPlay || null,
  });

  const presenter = useLocalStore(() => ({
    isPlay: false,

    get title() {
      return source.title;
    },

    get videoId() {
      return source.video.videoId;
    },

    get playerId() {
      return `youtube-${source.id}-${source.video.videoId}`;
    },

    get videoUrl() {
      let url = null;
      if (source.link) {
        try {
          url = new URL(source.link);
        } catch (error) {
          url = null;
        }
      }

      return url;
    },

    get timecode() {
      return presenter.videoUrl?.searchParams?.get('t') || null;
    },

    get resizedImage() {
      return resizedImage;
    },

    get isShow() {
      return source.video.videoId && source.video.type === 'youtube';
    },

    get isShowPlayer() {
      return presenter.isPlay || !presenter.hasPreview;
    },

    get isAutoplay() {
      return presenter.isPlay;
    },

    get hasPreview() {
      return !!source.previewUrl && !!source.previewGraphicSize;
    },

    get containerStyle() {
      if (presenter.hasPreview) {
        return resizedImage.containerStyle;
      }

      return { height: maxHeight };
    },

    onStartPlay() {
      presenter.isPlay = true;
      if (source.onStartPlay) {
        source.onStartPlay();
      }
    },
  }));

  return presenter;
};
