import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { MediaPreview } from 'APP/components/MediaPreview/MediaPreview';
import { IconView } from 'APP/packages/UiKit/IconView/IconView';
import PlayIcon from 'ICONS/ic-play-16.svg';

import {
  useMediaItemPresenter,
  MEDIA_PREVIEW_SIZE,
  PLACEHOLDER_QUALITY,
} from './MediaItem.presenter';
import styles from './MediaItem.styles.m.css';

export const MediaItem = observer((props) => {
  const { className, id: payloadId, messageId, groupId, payload } = props;
  const { payloadType } = payload;
  const presenter = useMediaItemPresenter({
    payloadType,
    groupId,
    messageId,
    mediaId: payloadId,
  });

  return (
    <>
      <MediaPreview
        className={classNames(styles.container, className)}
        uploadStateClassName={styles.uploadState}
        payload={payload}
        placeholderQuality={PLACEHOLDER_QUALITY}
        maxWidth={MEDIA_PREVIEW_SIZE.width}
        maxHeight={MEDIA_PREVIEW_SIZE.height}
        onClick={presenter.openMediaGallery}
        needCropImage={true}
        payloadId={payloadId}
        groupId={groupId}
        messageId={messageId}
      />
      {presenter.isVideo && (
        <div className={styles.videoIcon}>
          <IconView
            size={'small'}
            Icon={PlayIcon}
          />
        </div>
      )}
    </>
  );
});
