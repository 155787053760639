import { spaceModel } from 'APP/model/model';
import { ISpaceParticipantsChange } from 'APP/model/space/spaceModel.types';

export async function unbanSpaceMembers(data: ISpaceParticipantsChange): Promise<boolean> {
  try {
    const response = await spaceModel.unbanUsers(data);

    return response.result;
  } catch {
    return false;
  }
}
