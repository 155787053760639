import { PayloadType } from 'APP/model/message/messageModel.types';
import Entities from 'STORE';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

import { IMediaMessagePayload } from '../MediaMessagePopup.types';

export async function editMediaMessage(
  message: ChatMessage,
  payloads: IMediaMessagePayload[],
  comment: string,
  mustBeScheduled: boolean
): Promise<boolean> {
  if (!message.group) {
    return false;
  }

  const oldComment = message.payload.comment;
  const oldPayloads = message.payload.payloads;

  message.payload.comment = comment;
  if (message.payload.payloadType === PayloadType.Album) {
    const payloadIds = payloads.map((x) => x.id);
    message.payload.payloads = message.payload.payloads
      .filter((x: { id: string }) => payloadIds.includes(x.id))
      .map((x: { id: string }, idx: number) => ({
        ...x,
        order: idx,
      }));
  }

  Entities.InputPanel.payloads.isEditMode = true;
  Entities.InputPanel.payloads.setPayloads([message]);

  const canEdit = Entities.InputPanel.payloads.checkGroupPermissions(!mustBeScheduled);
  if (!canEdit.result) {
    message.payload.comment = oldComment;
    message.payload.payloads = oldPayloads;
    Entities.InputPanel.payloads.clear();
    Entities.InputPanel.payloads.isEditMode = false;
    canEdit.error && Entities.toast.show(canEdit.error);
    return false;
  }

  if (mustBeScheduled) {
    Entities.InputPanel.send.schedule(new Date(message.serverTime));
  } else {
    Entities.InputPanel.send.submit();
  }

  return true;
}
