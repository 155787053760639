import Tasks from 'APP/Tasks';
import { ALERT_TYPES } from 'APP/constants/app';
import Entities from 'STORE';

import { GroupsEvent } from '../GroupsEvent';

export class UserWasBanned extends GroupsEvent {
  process() {
    const { groupId } = this.eventPayload;
    const group = Entities.GroupsStore.getGroupById(groupId.toString());
    if (!group) {
      return;
    }
    let alert = {
      type: ALERT_TYPES.YOU_WERE_BANNED,
      data: {
        groupName: group.name,
      },
    };

    if (group.isChannel) {
      alert = { type: ALERT_TYPES.USER_BANNED_IN_CHANNEL };
    }

    Tasks.group.removeGroupLocally({
      groupId: group.id,
      alert,
    });
  }
}
