import { useAsObservableSource, useLocalStore } from 'mobx-react';

import { mapUserProfileBadgeToIcon } from 'APP/constants/userBadges';
import { FinishReasons } from 'APP/model/call/callModel.types';
import dateService from 'APP/packages/date';
import { getDurationText } from 'APP/packages/date/helpers/getDurationText';
import { useTranslation } from 'APP/packages/translations';
import Entities from 'APP/store';

export default (payload) => {
  const source = useAsObservableSource({ payload });
  const { t } = useTranslation();
  const presenter = useLocalStore(() => ({
    get isLeftAligned() {
      return Entities.ChatStore.isOnlyLeftSideMessages;
    },

    get time() {
      return dateService.format(Number(source.payload.finishTs || source.payload.startTs), {
        hour: 'numeric',
        minute: 'numeric',
      });
    },

    get isIncoming() {
      return Entities.UsersStore.Me.id !== source.payload.startInitiatorId;
    },

    get isMeInitiator() {
      return Entities.UsersStore.Me.id === source.payload.initiatorId;
    },

    get duration() {
      if (source.payload.duration) {
        return getDurationText(source.payload.duration);
      }
      return '';
    },

    get reason() {
      if (
        source.payload.isFinishCall &&
        [FinishReasons.Declined, FinishReasons.Unanswered].includes(source.payload.finishReason)
      ) {
        return presenter.isIncoming ? 'missed' : 'cancel';
      }
      return null;
    },

    get isShowInitiator() {
      if (
        source.payload.isP2P &&
        source.payload.isFinishCall &&
        source.payload.finishReason === FinishReasons.Finished
      ) {
        return Boolean(source.payload.initiatorId);
      }
      if (!source.payload.isP2P) {
        if (source.payload.isFinishCall) {
          return Boolean(source.payload.initiatorId);
        }
        return true;
      }
      return false;
    },

    get initiatorId() {
      return source.payload.initiatorId;
    },

    get initiatorName() {
      if (presenter.isMeInitiator) {
        return t('chat_details_myself');
      }
      return (
        Entities.UsersStore.getUserById(source.payload.initiatorId)?.displayName ||
        source.payload.initiatorNickName
      );
    },

    get userNameBadge() {
      if (presenter.isMeInitiator) {
        return null;
      }
      const user = Entities.UsersStore.getUserById(source.payload.initiatorId);

      return user?.usernameBadge ? mapUserProfileBadgeToIcon[user.usernameBadge] : null;
    },

    get verified() {
      if (presenter.isMeInitiator) {
        return false;
      }

      const user = Entities.UsersStore.getUserById(source.payload.initiatorId);

      return user?.verified;
    },

    get string() {
      if (source.payload.isP2P) {
        if (source.payload.isFinishCall) {
          switch (source.payload.finishReason) {
            case FinishReasons.Declined: {
              return t('system_messages:p2p_call_declined');
            }
            case FinishReasons.Unanswered: {
              return presenter.isIncoming
                ? t('system_messages:p2p_missed_call')
                : t('system_messages:p2p_call_no_answer');
            }
            default: {
              if (source.payload.initiatorId) {
                return t('system_messages:p2p_call_finished');
              }
              return t('system_messages:group_call_finished');
            }
          }
        }
        return presenter.isIncoming
          ? t('system_messages:p2p_incoming_call')
          : t('system_messages:p2p_outgoing_call');
      }

      if (presenter.isMeInitiator) {
        if (source.payload.isFinishCall) {
          return t('system_messages:group_call_finished_for_initiator');
        }
        return t('system_messages:group_call_started_for_initiator');
      }

      if (source.payload.isFinishCall) {
        if (source.payload.initiatorId) {
          return t('system_messages:group_call_finished_for_receiver');
        }
        return t('system_messages:group_call_finished');
      }

      return t('system_messages:group_call_started_for_receiver');
    },

    get text() {
      const chunks = presenter.string.split('{0}');
      return {
        before: chunks[0],
        after: chunks[1] || '',
      };
    },
  }));

  return presenter;
};
