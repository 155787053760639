import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { ISpaceCollection } from 'APP/model/space/spaceModel.types';
import { useTranslation } from 'APP/packages/translations';
import BackIcon from 'ICONS/ic-back-32.svg';
import MoreIcon from 'ICONS/ic-more.svg';
import { Space } from 'STORE/Spaces/Space';
import { Avatar, AvatarTypes } from 'UIKIT/Avatar/Avatar';
import { FAB } from 'UIKIT/FAB/FAB';
import { usePopover } from 'UIKIT/Popover/usePopover';
import { PopoverMenu } from 'UIKIT/PopoverMenu/PopoverMenu';

import { useSpaceCollectionHeaderPresenter } from './SpaceCollectionHeader.presenter';
import styles from './SpaceCollectionHeader.styles.m.css';

interface ISpaceCollectionHeaderProps {
  space: Space | null;
  collection: ISpaceCollection | null;
  onBack(): void;
  onEdit(): void;
  onDelete(): void;
}

export const SpaceCollectionHeader: FC<ISpaceCollectionHeaderProps> = observer(
  ({ space, collection, onBack, onEdit, onDelete }) => {
    const menuItems = useSpaceCollectionHeaderPresenter({ space, onEdit, onDelete });
    const { t } = useTranslation();
    const popover = usePopover<HTMLButtonElement>();

    return (
      <div className={styles.container}>
        <div className={styles.information}>
          <FAB
            className={styles.backButton}
            Icon={BackIcon}
            theme="2"
            size="10"
            onClick={onBack}
          />

          {collection && (
            <>
              <Avatar
                className={styles.avatar}
                url={collection.avatarUrl}
                type={AvatarTypes.Square_20}
              />

              <div>
                <h6 className={styles.name}>{collection.name}</h6>
                <span className={styles.count}>
                  {t('space_amount_post_collection', { 0: collection.itemsCount })}
                </span>
              </div>
            </>
          )}
        </div>

        {!!menuItems.length && (
          <>
            <FAB
              setRef={popover.containerRef}
              Icon={MoreIcon}
              theme="2"
              size="30"
              onClick={popover.toggle}
            />

            <PopoverMenu
              isOpened={popover.isOpened}
              placement="bottom-end"
              menuItems={menuItems}
              reference={popover.containerRef.current}
              onClose={popover.close}
            />
          </>
        )}
      </div>
    );
  }
);
