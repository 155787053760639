import { PayloadType } from 'APP/model/message/messageModel.types';
import { t } from 'MODULE/i18n/service';
import { Payload } from 'STORE/Messages/Message/Payload';

class Contact extends Payload {
  userId;
  phone;
  name;
  avatarUrl;
  appContact;

  constructor(data) {
    super(data);

    this.userId = data.userId;
    this.phone = data.phone;
    this.name = data.name;
    this.avatarUrl = data.avatarUrl;
    this.appContact = data.appContact;
  }

  toJSON() {
    return {
      payloadType: PayloadType.Contact,
      userId: this.userId || null,
      phone: this.phone || null,
      name: this.name,
      avatarUrl: this.avatarUrl || null,
      appContact: this.appContact,
    };
  }

  get text() {
    return t('share_contact_quoted_type');
  }

  get user() {
    return this.root.UsersStore.getUserById(this.userId);
  }

  get typeDescription() {
    return t('share_contact_quoted_type');
  }

  get showStartChatButton() {
    return (
      (!this.message.fromMe || this.message.isSavedMessagesLeft) &&
      this.root.UsersStore.Me.id !== this.userId &&
      this.appContact
    );
  }
}

export default Contact;
