import { observer } from 'mobx-react';
import React from 'react';

import { PopoverMenu } from 'UIKIT/PopoverMenu/PopoverMenu';

import { useDraftsContextMenuItems } from './useDraftsContextMenuItems';

export const DraftsContextMenu = observer(({ draft, popover, placement, modifiers }) => {
  const presenter = useDraftsContextMenuItems({
    draft,
    popover,
  });

  if (!popover.isOpened) {
    return null;
  }

  return (
    <>
      <PopoverMenu
        menuItems={presenter.items}
        isOpened={popover.isOpened}
        onClose={popover.close}
        reference={popover.containerRef.current}
        placement={placement}
        modifiers={modifiers}
      />
    </>
  );
});
