import { UpdateReason } from 'APP/constants/scroll';
import Entities from 'APP/store';

export default () => {
  if (Entities.App.isFocused) {
    Entities.ChatStore.setScrollChanged(UpdateReason.NewMessage, {
      isAnimated: true,
    });
  } else {
    Entities.ChatStore.setScrollChanged(UpdateReason.ScrollUnfocusedChat, {
      isAnimated: true,
    });
  }
};
