import { ackRewards as ackRewardsModel } from 'APP/model/userSettings/userSettingsModel';
import { RewardProgressInfoRewardEnum } from 'APP/model/userSettings/userSettingsModel.types';
import Entities from 'STORE';

export const ackRewards = async (reward: RewardProgressInfoRewardEnum): Promise<boolean> => {
  try {
    await ackRewardsModel([reward]);
    const rewardProgress = Entities.rewards.rewardsProgress.get(reward);

    if (!rewardProgress) {
      return false;
    }

    rewardProgress.acknowledged = true;
    Entities.rewards.updateReward(rewardProgress);

    const hasAskRewards = Entities.rewards.getSomeRewardNoneRead();
    if (!hasAskRewards) {
      Entities.rewards.setHasNewRewards(false);
    }

    return true;
  } catch {
    return false;
  }
};
