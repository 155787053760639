import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { useTranslation } from 'APP/packages/translations';
import { Button } from 'UIKIT/Button/Button';
import { usePopover } from 'UIKIT/Popover/usePopover';
import { PopoverMenu } from 'UIKIT/PopoverMenu/PopoverMenu';

import { useUnknownUserBlockPresenter } from './UnknownUserBlock.presenter';

interface IUnknownUserBlockProps {
  className?: string;
  groupId: string;
  userId: string;
}

export const UnknownUserBlock: FC<IUnknownUserBlockProps> = observer((props) => {
  const { className, groupId, userId } = props;
  const { t } = useTranslation();
  const popover = usePopover<HTMLButtonElement>();

  const presenter = useUnknownUserBlockPresenter({ userId, groupId, popover });

  return (
    <>
      <Button
        setRef={popover.containerRef}
        onClick={popover.toggle}
        className={className}
        theme="5"
      >
        {t('common_block')}
      </Button>
      <PopoverMenu
        placement="bottom-start"
        menuItems={presenter.menuItems}
        reference={popover.containerRef.current}
        isOpened={popover.isOpened}
        onClose={popover.close}
      />
    </>
  );
});
