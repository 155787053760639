import { observer } from 'mobx-react';
import React, { FC, useMemo } from 'react';

import { AuthProvider } from 'APP/model/auth/authModel.types';
import { FAB } from 'APP/packages/UiKit/FAB/FAB';
import { useTranslation } from 'APP/packages/translations';
import DeleteIcon from 'ICONS/ic-delete.svg';
import TelegramIcon from 'ICONS/ic-telegram_round.svg';
import popupItemStyles from 'UIKIT/PopupItem/styles.m.scss';

import { ProviderLayout } from '../ProviderLayout/ProviderLayout';
import { useProvidersPresenter } from '../hooks/useProviders';
import { LinkTelegramProvider } from './LinkTelegramProvider/LinkTelegramProvider';
import styles from './TelegramProvider.styles.m.css';

interface ITelegramProviderProps {
  displayName: string;
  onProcessingChange(active: boolean, provider: AuthProvider): void;
}
export const TelegramProvider: FC<ITelegramProviderProps> = observer(
  ({ displayName, onProcessingChange }) => {
    const { t } = useTranslation();

    const presenter = useProvidersPresenter();

    const linkProvider = useMemo(() => {
      if (presenter.isDesktop) {
        return t('account_desktop_hint');
      }

      return (
        <LinkTelegramProvider
          onProcessingChange={(active: boolean): void =>
            onProcessingChange(active, AuthProvider.Telegram)
          }
        />
      );
    }, []);

    return (
      <div className={popupItemStyles.container}>
        <ProviderLayout
          disabled={presenter.isDesktop}
          isProcessing={presenter.isProcessing}
          icon={<TelegramIcon />}
          title={t('account_telegram')}
          description={displayName || linkProvider}
          actions={
            displayName ? (
              <FAB
                size="20"
                theme="3"
                className={styles.deleteIcon}
                Icon={DeleteIcon}
                onClick={(): void => {
                  presenter.onDelete(AuthProvider.Telegram);
                }}
              />
            ) : null
          }
        />
      </div>
    );
  }
);
