import { CallConnectedResponse } from 'APP/packages/api/types/model/callConnectedResponse';
import { CallTokenRequest } from 'APP/packages/api/types/model/callTokenRequest';
import { GetPermissionRequestsResponse } from 'APP/packages/api/types/model/getPermissionRequestsResponse';

import { request } from '../request/request';
import { CallCreateRequest } from '../types/model/callCreateRequest';
import { CallCreatedResponse } from '../types/model/callCreatedResponse';
import { CallViaLinkInfoResponse } from '../types/model/callViaLinkInfoResponse';
import { GetCallInfoViaLinkRequest } from '../types/model/getCallInfoViaLinkRequest';
import { GetCallPermissionsRequest } from '../types/model/getCallPermissionsRequest';
import { JoinCallMeViaLinkRequest } from '../types/model/joinCallMeViaLinkRequest';
import { JoinGroupCallViaLinkRequest } from '../types/model/joinGroupCallViaLinkRequest';
import { PersonalizedCallPermissionsSettingsWithUsersResponse } from '../types/model/personalizedCallPermissionsSettingsWithUsersResponse';
import { UnauthorizedCallInfoResponse } from '../types/model/unauthorizedCallInfoResponse';

const ROOT_API = 'calls';

export const createCall = (data: CallCreateRequest): Promise<CallCreatedResponse> => {
  return request({
    rootApi: ROOT_API,
    url: '/createCall',
    method: 'POST',
    body: data,
  });
};

export const getCallInfo = (data: CallTokenRequest): Promise<CallConnectedResponse> => {
  return request({
    rootApi: ROOT_API,
    url: '/getCallInfo',
    method: 'POST',
    body: data,
  });
};

export const finishCall = (data: any): any => {
  return request({
    rootApi: ROOT_API,
    url: '/finishCall',
    method: 'POST',
    body: data,
  });
};

export const actionCall = (data: any): any => {
  return request({
    rootApi: ROOT_API,
    url: '/actionCall',
    method: 'POST',
    body: data,
  });
};

export const joinCall = (data: any): any => {
  return request({
    rootApi: ROOT_API,
    url: '/joinCall',
    method: 'POST',
    body: data,
  });
};

export const addCallMembers = (data: any): any => {
  return request({
    rootApi: ROOT_API,
    url: '/addCallMembers',
    method: 'POST',
    body: data,
  });
};

export const removeCallMember = (data: any): any => {
  return request({
    rootApi: ROOT_API,
    url: '/removeCallMember',
    method: 'POST',
    body: data,
  });
};

export const startRecording = (data: any): any => {
  return request({
    rootApi: ROOT_API,
    url: '/startRecording',
    method: 'POST',
    body: data,
  });
};

export const startScreenSharing = (data: any): any => {
  return request({
    rootApi: ROOT_API,
    url: '/startScreenSharing',
    method: 'POST',
    body: data,
  });
};

export const stopRecording = (data: any): any => {
  return request({
    rootApi: ROOT_API,
    url: '/stopRecording',
    method: 'POST',
    body: data,
  });
};

export const stopScreenSharing = (data: any): any => {
  return request({
    rootApi: ROOT_API,
    url: '/stopScreenSharing',
    method: 'POST',
    body: data,
  });
};

export const getPermissionRequests = (
  channelId: string
): Promise<GetPermissionRequestsResponse> => {
  return request({
    rootApi: ROOT_API,
    url: '/getPermissionRequests',
    method: 'POST',
    body: {
      channelId: channelId,
    },
  });
};

export const setCallPermissionsForAll = (body: any): any => {
  return request({
    rootApi: ROOT_API,
    url: '/setPermissionsForAll',
    method: 'POST',
    body,
  });
};

export const updatePermissionRequestsForAll = (body: any): any => {
  return request({
    rootApi: ROOT_API,
    url: '/updatePermissionRequestsForAll',
    method: 'POST',
    body,
  });
};

export const updatePermissionRequestForUser = (body: any): any => {
  return request({
    rootApi: ROOT_API,
    url: '/updatePermissionRequestForUser',
    method: 'POST',
    body,
  });
};

export const setPermissionsForUser = (body: any): any => {
  return request({
    rootApi: ROOT_API,
    url: '/setPermissionsForUser',
    method: 'POST',
    body,
  });
};

export const clearPermissionsForUser = (body: any): any => {
  return request({
    rootApi: ROOT_API,
    url: '/clearPermissionsForUser',
    method: 'POST',
    body,
  });
};

export const createPermissionRequest = (body: any): any => {
  return request({
    rootApi: ROOT_API,
    url: '/createPermissionRequest',
    method: 'POST',
    body,
  });
};

export const sendFeedback = (body: any): any => {
  return request({
    rootApi: ROOT_API,
    url: '/sendFeedback',
    method: 'POST',
    body,
  });
};

export const getCallInfoViaLink = (
  body: GetCallInfoViaLinkRequest
): Promise<CallViaLinkInfoResponse> => {
  return request({
    rootApi: ROOT_API,
    url: '/getCallInfoViaLink',
    method: 'POST',
    body,
  });
};

export const joinCallMeViaLink = (
  body: JoinCallMeViaLinkRequest
): Promise<UnauthorizedCallInfoResponse> => {
  return request({
    rootApi: ROOT_API,
    url: '/joinCallMeViaLink',
    method: 'POST',
    body,
  });
};

export const joinGroupCallViaLink = (
  body: JoinGroupCallViaLinkRequest
): Promise<UnauthorizedCallInfoResponse> => {
  return request({
    rootApi: ROOT_API,
    url: '/joinGroupCallViaLink',
    method: 'POST',
    body,
  });
};

export const getPermissions = (
  body: GetCallPermissionsRequest
): Promise<PersonalizedCallPermissionsSettingsWithUsersResponse> => {
  return request({
    rootApi: ROOT_API,
    url: '/getPermissions',
    method: 'POST',
    body,
  });
};

export const getUnionCallLink = (body: { channelId: string }): Promise<string> => {
  return request({
    rootApi: ROOT_API,
    url: '/getCallLink',
    method: 'POST',
    body,
  });
};
