import { updateUserProfile } from 'APP/model/userSettings/userSettingsModel';
import { IUpdateUserDisplayData } from 'APP/model/userSettings/userSettingsModel.types';
import Entities from 'APP/store';

export const updateMyProfile = async (data: IUpdateUserDisplayData): Promise<boolean> => {
  try {
    const response = await updateUserProfile(data);
    if (!response) {
      return false;
    }

    Entities.UsersStore.Me.updateDisplayData(response);
    return true;
  } catch {
    return false;
  }
};
