import classNames from 'classnames';
import React, { FC, useState, DetailedHTMLProps, InputHTMLAttributes } from 'react';

import styles from './TextField.style.m.css';

interface FieldProps
  extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: string;
  helperText?: string;
  hasError?: boolean;
  hasSuccess?: boolean;
  isDisabled?: boolean;
}

export const TextField: FC<FieldProps> = (props) => {
  const {
    className,
    value,
    label,
    placeholder,
    helperText,
    hasError = false,
    hasSuccess = false,
    isDisabled = false,
    ...inputProps
  } = props;

  const [isFocused, setFocused] = useState<boolean>(false);

  const contentClasses = classNames(styles.content, {
    [styles.focused]: isFocused,
    [styles.error]: hasError,
    [styles.success]: hasSuccess,
    [styles.disabled]: isDisabled,
  });

  const labelClasses = classNames(styles.label, {
    [styles.smallLabel]: isFocused || Boolean(placeholder) || Boolean(value),
  });

  const inputClasses = classNames(styles.input, {
    [styles.inputWithLabel]: !!label,
  });

  return (
    <div className={className}>
      <label className={contentClasses}>
        {label && <span className={labelClasses}>{label}</span>}
        <input
          {...inputProps}
          className={inputClasses}
          value={value}
          placeholder={placeholder}
          disabled={isDisabled}
          autoComplete="off"
          onFocus={(): void => setFocused(true)}
          onBlur={(): void => setFocused(false)}
        />
      </label>
      {helperText && <div className={styles.helperText}>{helperText}</div>}
    </div>
  );
};
