import { useLocalStore } from 'mobx-react';
import { ElementType } from 'react';

import { SupportEmailKey } from 'APP/Tasks/authorization/login/handleClientConfig/handleSupportEmail';
import { useTranslation } from 'APP/packages/translations';
import Entities from 'APP/store';
import config from 'CONFIG';
import IcFAQ from 'ICONS/ic-FAQ.svg';
import IcFacebook from 'ICONS/ic-facebook.svg';
import IcInstagram from 'ICONS/ic-instagram.svg';
import IcLink from 'ICONS/ic-site.svg';
import IcSupport from 'ICONS/ic-support.svg';
import IcTerms from 'ICONS/ic-terms.svg';
import IcVK from 'ICONS/ic-vk.svg';

import styles from './AboutContent.styles.m.css';

interface IAboutItem {
  className?: string;
  key: string;
  Icon: ElementType;
  title: string;
  href: string;
  isOpenInNewTab: boolean;
}

interface IAboutContentPresenter {
  version: string;
  supportEmail: string;
  items: IAboutItem[];
}

export function useAboutContentPresenter(): IAboutContentPresenter {
  const { t } = useTranslation();

  const presenter = useLocalStore<IAboutContentPresenter>(() => ({
    supportEmail: Entities.clientConfig.getConfig(SupportEmailKey) || config.supportEmail,

    get version(): string {
      return Entities.App.version;
    },

    get items(): IAboutItem[] {
      return [
        {
          key: 'facebook',
          Icon: IcFacebook,
          title: t('about_facebook'),
          href: t('facebook_about_link'),
          isOpenInNewTab: true,
        },
        {
          key: 'instagram',
          Icon: IcInstagram,
          title: t('about_instagram'),
          href: t('instagram_about_link'),
          isOpenInNewTab: true,
        },
        {
          key: 'vk',
          Icon: IcVK,
          title: t('about_vk'),
          href: t('vk_about_link'),
          isOpenInNewTab: true,
          className: styles.gap,
        },
        {
          key: 'contact-support',
          Icon: IcSupport,
          title: t('about_contact_support'),
          href: `mailto:${presenter.supportEmail}?subject=${t('support_email_subject_text')}`,
          isOpenInNewTab: false,
        },
        {
          key: 'faq',
          Icon: IcFAQ,
          title: t('about_faq'),
          href: t('about_faq_link'),
          isOpenInNewTab: true,
        },
        {
          key: 'policy',
          Icon: IcTerms,
          title: t('about_policy'),
          href: t('about_policy_link'),
          isOpenInNewTab: true,
        },
        {
          key: 'website',
          Icon: IcLink,
          title: t('about_website'),
          href: t('about_link'),
          isOpenInNewTab: true,
        },
        {
          key: 'website',
          Icon: IcLink,
          title: t('about_website_eu'),
          href: t('about_website_eu_link'),
          isOpenInNewTab: true,
          className: styles.gap,
        },
      ];
    },
  }));

  return presenter;
}
