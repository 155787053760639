import api from 'APP/packages/api';
import { RewardsProgressResponse } from 'APP/packages/api/types/model/rewardsProgressResponse';
import logger from 'APP/packages/logger';
import Entities from 'STORE';

export const getRewardsProgress = async () => {
  try {
    const response: RewardsProgressResponse = await api.userSettings.getRewardsProgress();

    Entities.rewards.setRewardsProgress(response.rewardsInfo);
    Entities.rewards.setRewardsProgressLoading(false);
  } catch (error) {
    logger.get('API').error('userSettings.getRewardsProgress', error);
  }
};
