import React from 'react';

import Logo from 'APP/icons/gem-logo-48.svg';
import { greetingCardAnalytics } from 'APP/packages/analytics/analytics';
import { useTranslation } from 'APP/packages/translations';
import config from 'CONFIG';
import { Button } from 'UIKIT/Button/Button';

import styles from './GreetingCardBanner.styles.m.css';

export const GreetingCardBanner = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.appInfo}>
        <Logo className={styles.logo} />
        <div className={styles.descriptions}>
          <h4 className={styles.appTitle}>{t('common_title')}</h4>
          <p className={styles.description}>{t('common_description')}</p>
        </div>
      </div>
      <Button
        onClick={() => {
          greetingCardAnalytics.trackTapBanner();
          const href = `${config.deeplinkHost}/invite/cardsbot`;
          window.open(href, '_blank');
        }}
        theme="1"
        size="0"
      >
        {t('common_view')}
      </Button>
    </div>
  );
};
