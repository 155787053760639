import React, { FC } from 'react';

import { GroupType } from 'APP/model/group/groupModel.types';
import DeepLinkAliasField from 'APP/packages/form/DeepLinkAliasField';
import { useTranslation } from 'APP/packages/translations';

import { IGroupFormInitialValues } from '../../GroupForm.types';

export const GroupFormGeneralDeepLinkField: FC = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <DeepLinkAliasField
      title={t('common_invite_link')}
      description={t('group_creation_open')}
      placeholder={t('common_link')}
      validatingText={t('checking_deeplink')}
      successText={t('channel_link_is_available')}
      isShowLink={(values: IGroupFormInitialValues): boolean => values.type === GroupType.Open}
      {...props}
    />
  );
};
