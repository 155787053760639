import {
  mapActiveCallsData,
  mapActiveStreamsData,
  mapGroupCallChanged,
  mapIncomingCall,
  mapStreamChanged,
} from 'APP/model/call/callModel.mapper';
import { IGroupCallChanged, IIncomingCall, IStreamChanged } from 'APP/model/call/callModel.types';
import { ISlowModeUpdate } from 'APP/model/common/commonModel.types';
import { mapCounterResponse } from 'APP/model/counter/counterModel.mapper';
import {
  IGroupCounterState,
  IGroupCounterUpdates,
  IGroupCounterView,
} from 'APP/model/counter/counterModel.types';
import { EventName } from 'APP/model/events/eventsModel.types';
import { IUserDeviceResponse } from 'APP/model/session/sessionModel.types';
import { mapPersonalizedSpaceResponse } from 'APP/model/space/spaceModel.mapper';
import { IPersonalizedSpace } from 'APP/model/space/spaceModel.types';
import { IOnlineUserData } from 'APP/model/user/userModel.types';
import { DeviceAdded } from 'APP/packages/api/types/model/deviceAdded';
import { DeviceDeleted } from 'APP/packages/api/types/model/deviceDeleted';
import { GroupCallChanged } from 'APP/packages/api/types/model/groupCallChanged';
import { GroupCounterData } from 'APP/packages/api/types/model/groupCounterData';
import { GroupsCountersData } from 'APP/packages/api/types/model/groupsCountersData';
import { IncomingCall } from 'APP/packages/api/types/model/incomingCall';
import { OnlineUserData } from 'APP/packages/api/types/model/onlineUserData';
import { SpaceCollectionUpdated } from 'APP/packages/api/types/model/spaceCollectionUpdated';
import { SpaceUpdated } from 'APP/packages/api/types/model/spaceUpdated';
import { SpacesChanged } from 'APP/packages/api/types/model/spacesChanged';
import { StreamChanged } from 'APP/packages/api/types/model/streamChanged';
import { openStream } from 'APP/packages/streamApi/stream/stream';
import { IPushNotification } from 'STORE/PushNotifications/PushNotifications.types';

const ROOT_API = 'events';

interface IEventListeners {
  [EventName.CallsActiveBatched]: (data: IGroupCallChanged[]) => Promise<void>;
  [EventName.CallsActiveCallChanged]: (data: IGroupCallChanged) => Promise<void>;
  [EventName.CallsActiveCallStarted]: (data: IIncomingCall) => Promise<void>;

  [EventName.StreamsActiveBatched]: (data: IStreamChanged[]) => Promise<void>;
  [EventName.StreamsActiveStreamChanged]: (data: IStreamChanged) => Promise<void>;

  [EventName.GroupsCountersBatched]: (data: IGroupCounterView[]) => Promise<void>;
  [EventName.GroupsCountersGroupChanged]: (data: IGroupCounterView) => Promise<void>;
  [EventName.GroupsUpdatesBatched]: (data: IGroupCounterState[]) => Promise<void>;

  [EventName.UsersOnlineBatched]: (data: IOnlineUserData[]) => Promise<void>;
  [EventName.UsersOnlineUserChanged]: (data: IOnlineUserData) => Promise<void>;

  [EventName.DevicesAdded]: (data: IUserDeviceResponse) => Promise<void>;
  [EventName.DevicesDeleted]: (data: string) => Promise<void>;

  [EventName.NotificationsDesktopSent]: (data: IPushNotification) => Promise<void>;

  [EventName.SpacesChangedBatched]: (
    spaces: IPersonalizedSpace[],
    lastTs?: string
  ) => Promise<void>;
  [EventName.SpacesUpdated]: (
    spaceData: IPersonalizedSpace,
    updatedTs: string,
    groupsChangedAt?: string
  ) => Promise<void>;
  [EventName.SpacesCollectionUpdated]: (spaceId: string, updatedTs: string) => Promise<void>;

  [EventName.SlowModeUpdate]: (data: ISlowModeUpdate) => Promise<void>;
}

export function initEventsListener(
  counterTs: number,
  spaceTs: number,
  handlers: IEventListeners,
  onError: (data: { target: EventSource }) => void
): (() => void) | null {
  return openStream(
    {
      rootApi: ROOT_API,
      params: {
        counterTs: counterTs.toString(),
        spaceTs: spaceTs.toString(),
      },
    },
    [
      {
        eventName: EventName.CallsActiveBatched,
        onData: (data: GroupCallChanged[]): void => {
          handlers[EventName.CallsActiveBatched]?.(mapActiveCallsData(data));
        },
      },
      {
        eventName: EventName.CallsActiveCallChanged,
        onData: (data: GroupCallChanged): void => {
          handlers[EventName.CallsActiveCallChanged]?.(mapGroupCallChanged(data));
        },
      },
      {
        eventName: EventName.CallsActiveCallStarted,
        onData: (data: IncomingCall): void => {
          handlers[EventName.CallsActiveCallStarted]?.(mapIncomingCall(data));
        },
      },
      {
        eventName: EventName.StreamsActiveBatched,
        onData: (data: StreamChanged[]): void => {
          handlers[EventName.StreamsActiveBatched]?.(mapActiveStreamsData(data));
        },
      },
      {
        eventName: EventName.StreamsActiveStreamChanged,
        onData: (data: StreamChanged): void => {
          handlers[EventName.StreamsActiveStreamChanged]?.(mapStreamChanged(data));
        },
      },
      {
        eventName: EventName.GroupsCountersBatched,
        onData: (data: GroupsCountersData): void => {
          handlers[EventName.GroupsCountersBatched]?.(data.counters.map(mapCounterResponse));
        },
      },
      {
        eventName: EventName.GroupsCountersGroupChanged,
        onData: (data: GroupCounterData): void => {
          handlers[EventName.GroupsCountersGroupChanged]?.(mapCounterResponse(data.counter));
        },
      },
      {
        eventName: EventName.GroupsUpdatesBatched,
        onData: (data: IGroupCounterUpdates): void => {
          handlers[EventName.GroupsUpdatesBatched]?.(data.updates);
        },
      },
      {
        eventName: EventName.UsersOnlineBatched,
        onData: (data: OnlineUserData[]): void => {
          handlers[EventName.UsersOnlineBatched]?.(data);
        },
      },
      {
        eventName: EventName.UsersOnlineUserChanged,
        onData: (data: OnlineUserData): void => {
          handlers[EventName.UsersOnlineUserChanged]?.(data);
        },
      },
      {
        eventName: EventName.DevicesAdded,
        onData: (data: DeviceAdded): void => {
          handlers[EventName.DevicesAdded]?.(data.device);
        },
      },
      {
        eventName: EventName.DevicesDeleted,
        onData: (data: DeviceDeleted): void => {
          handlers[EventName.DevicesDeleted]?.(data.registrationKey);
        },
      },
      {
        eventName: EventName.NotificationsDesktopSent,
        onData: (data: IPushNotification): void => {
          handlers[EventName.NotificationsDesktopSent]?.(data);
        },
      },
      {
        eventName: EventName.SpacesChangedBatched,
        onData: (data: SpacesChanged): void => {
          handlers[EventName.SpacesChangedBatched]?.(
            data.spaces.map(mapPersonalizedSpaceResponse),
            data.lastTs
          );
        },
      },
      {
        eventName: EventName.SpacesUpdated,
        onData: (data: SpaceUpdated): void => {
          handlers[EventName.SpacesUpdated]?.(
            mapPersonalizedSpaceResponse(data.spaceData),
            data.updatedTs,
            data.groupsChangedAt
          );
        },
      },
      {
        eventName: EventName.SpacesCollectionUpdated,
        onData: (data: SpaceCollectionUpdated): void => {
          handlers[EventName.SpacesCollectionUpdated]?.(data.spaceId, data.updatedTs);
        },
      },
      {
        eventName: EventName.SlowModeUpdate,
        onData: (data: ISlowModeUpdate): void => {
          handlers[EventName.SlowModeUpdate]?.(data);
        },
      },
    ],
    onError
  );
}
