import { reaction } from 'mobx';

import { storage } from 'APP/packages/storage';

function updateConnectionToken(token) {
  if (token) {
    storage.connectionToken.set(token);
  } else {
    storage.connectionToken.remove();
  }
}

export const cachingConnectionToken = async (store) => {
  const connectionToken = await storage.connectionToken.get();

  if (connectionToken) {
    store.setConnectionToken(connectionToken);
  }

  reaction(() => store.connectionToken, updateConnectionToken);
};
