import useUserOnlineStatusText from 'APP/main/hooks/userOnlineStatusText';

import { IPopupUserItemPresenter, IPopupUserItemLocalStore } from './PopupUserItem.types';

export const usePopupUserItemPresenter = (data: IPopupUserItemPresenter) => {
  const userOnlineStatusText: string = useUserOnlineStatusText(data);

  return <IPopupUserItemLocalStore>{
    userOnlineStatusText,
  };
};
