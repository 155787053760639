import {
  IPushNotification,
  PushNotificationType,
} from 'STORE/PushNotifications/PushNotifications.types';

import { handleCall } from './handlers/handleCall';
import { handleDefaultNotification } from './handlers/handleDefaultNotification';
import { handleDefaultSpaceNotification } from './handlers/handleDefaultSpaceNotification';
import { handleDeleteGroup } from './handlers/handleDeleteGroup';
import { handleDeleteMessage } from './handlers/handleDeleteMessage';
import { handleDeviceAdded } from './handlers/handleDeviceAdded';
import { handleMessageConfirm } from './handlers/handleMessageConfirm';
import { handleReactionNotification } from './handlers/handleReactionNotification';
import { handleScheduledEvent } from './handlers/handleScheduledEvent';
import { handleSpaceAdminNotification } from './handlers/handleSpaceAdminNotification';

const notificationTypeToHandler: Record<
  PushNotificationType,
  (notification: IPushNotification) => void
> = {
  /* GROUP/MESSAGES HANDLERS */
  [PushNotificationType.GroupRenamed]: handleDefaultNotification,
  [PushNotificationType.GroupAvatarChanged]: handleDefaultNotification,
  [PushNotificationType.GroupTypeChanged]: handleDefaultNotification,
  [PushNotificationType.GroupCategoryChanged]: handleDefaultNotification,
  [PushNotificationType.GroupDescriptionChanged]: handleDefaultNotification,
  [PushNotificationType.GroupCatalogChanged]: handleDefaultNotification,
  [PushNotificationType.UserNewJoined]: handleDefaultNotification,
  [PushNotificationType.GroupUserJoin]: handleDefaultNotification,
  [PushNotificationType.GroupUserLeave]: handleDefaultNotification,
  [PushNotificationType.GroupAdminsAdded]: handleDefaultNotification,
  [PushNotificationType.GroupAdminsRemoved]: handleDefaultNotification,
  [PushNotificationType.GroupOwnerChanged]: handleDefaultNotification,
  [PushNotificationType.GroupUserRemoved]: handleDefaultNotification,
  [PushNotificationType.GroupUsersRemoved]: handleDefaultNotification,
  [PushNotificationType.GroupUserBanned]: handleDefaultNotification,
  [PushNotificationType.GroupUsersBanned]: handleDefaultNotification,
  [PushNotificationType.GroupAdminAdded]: handleDefaultNotification,
  [PushNotificationType.GroupUserAdded]: handleDefaultNotification,
  [PushNotificationType.MessageNew]: handleDefaultNotification,
  [PushNotificationType.MessageUserMentioned]: handleDefaultNotification,
  [PushNotificationType.UserRepliedOnMessage]: handleDefaultNotification,
  [PushNotificationType.NewThreadMessage]: handleDefaultNotification,
  [PushNotificationType.NewCommentOnMessage]: handleDefaultNotification,
  [PushNotificationType.UserReactedOnMessage]: handleReactionNotification,
  [PushNotificationType.MessageForward]: handleDefaultNotification,
  [PushNotificationType.MessagePin]: handleDefaultNotification,
  [PushNotificationType.ScheduledMessageSent]: handleDefaultNotification,
  [PushNotificationType.StreamStarted]: handleDefaultNotification,

  /* CUSTOM MESSAGES HANDLERS */
  [PushNotificationType.MessageConfirm]: handleMessageConfirm,
  [PushNotificationType.MessageDelete]: handleDeleteMessage,
  [PushNotificationType.GroupDeleted]: handleDeleteGroup,
  [PushNotificationType.AgoraCall]: handleCall,
  [PushNotificationType.DeviceAdded]: handleDeviceAdded,
  [PushNotificationType.ScheduledEventCreated]: handleScheduledEvent,
  [PushNotificationType.ScheduledEventReminder]: handleScheduledEvent,

  /* SPACE HANDLERS */
  [PushNotificationType.GroupAddedToSpace]: handleDefaultNotification,
  [PushNotificationType.GroupRemovedFromSpace]: handleDefaultSpaceNotification,
  [PushNotificationType.SpaceAdminLeft]: handleSpaceAdminNotification,
  [PushNotificationType.SpaceGroupDeleted]: handleSpaceAdminNotification,
};

export const handleNewNotification = (notification: IPushNotification): void => {
  notificationTypeToHandler[notification.pushType]?.(notification);
};
