import { useState, CSSProperties } from 'react';

import { IGraphicSize } from 'APP/model/common/commonModel.types';
import {
  getWidthHeightByMaxValues,
  getFixedSizeImage,
  getFullScreenImage,
  getFixedSizeBlurredImage,
} from 'APP/packages/imageResizer';

interface IImageResizerPresenter {
  url: string;
  graphicSize: IGraphicSize;
  maxWidth: number;
  maxHeight: number;
  placeholderQuality?: number;
  needCropImage?: boolean;
}

export interface IImageResizerLocalStore {
  isShowFullScreen: boolean;
  imageSize: IGraphicSize;
  url: string;
  fullScreenUrl: string;
  placeholderUrl: string;
  containerStyle: CSSProperties;
  imageStyle: CSSProperties;
  placeholderStyle: CSSProperties;
  openFullScreen(): void;
  closeFullScreen(): void;
}

export const useImageResizer = (data: IImageResizerPresenter): IImageResizerLocalStore => {
  const {
    url,
    graphicSize,
    maxWidth,
    maxHeight,
    placeholderQuality = 1,
    needCropImage = false,
  } = data;

  const [isFullScreen, setIsFullScreen] = useState(false);

  const presenter = {
    get isShowFullScreen(): boolean {
      return isFullScreen;
    },

    get imageSize(): IGraphicSize {
      if (needCropImage) {
        return {
          width: maxWidth,
          height: maxHeight,
        };
      }

      return getWidthHeightByMaxValues(graphicSize.width, graphicSize.height, maxWidth, maxHeight);
    },

    get url(): string {
      return getFixedSizeImage(url, {
        ...presenter.imageSize,
        isNeedCrop: needCropImage,
      });
    },

    get fullScreenUrl(): string {
      return getFullScreenImage(url);
    },

    get placeholderUrl(): string {
      const { width, height } = presenter.imageSize;
      let quality = Number(placeholderQuality) || 1;
      quality = quality > 1 ? 1 : quality <= 0 ? 0.1 : quality;

      return getFixedSizeBlurredImage(
        url,
        { width: width * quality, height: height * quality },
        graphicSize
      );
    },

    get containerStyle(): CSSProperties {
      const { height } = presenter.imageSize;
      const style = {
        maxWidth: `${maxWidth}px`,
        maxHeight: `${maxHeight}px`,
        height: `${height}px`,
        backgroundImage: `url(${presenter.placeholderUrl})`,
      };

      return style;
    },

    get imageStyle(): CSSProperties {
      const { width, height } = presenter.imageSize;
      return { width: `${width}px`, height: `${height}px` };
    },

    get placeholderStyle(): CSSProperties {
      const { height } = presenter.imageSize;
      const style = {
        maxWidth: `${maxWidth}px`,
        height: `${height}px`,
      };

      return style;
    },

    openFullScreen(): void {
      setIsFullScreen(true);
    },

    closeFullScreen(): void {
      setIsFullScreen(false);
    },
  };

  return presenter;
};
