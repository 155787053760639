import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import bus from 'APP/packages/bus';
import Entities from 'STORE';
import { debounce } from 'UTILS/debounce';

export type TSearchChangeHandler = (value: string) => void;

interface ISidebarPanelHeaderLocalStore {
  currentUserId: string;
  searchValue: string;
  onSearchChange(value: string): void;
  onSearchFocus(): void;
  setDebouncedSearchValue(value: string): void;
  searchReset(): void;
}

const SEARCH_DEBOUNCE = 300;

export const useSidebarPanelHeaderPresenter = (onSearchChange: TSearchChangeHandler) => {
  const presenter = useLocalStore<ISidebarPanelHeaderLocalStore>(() => ({
    searchValue: '',

    get currentUserId(): string {
      return Entities.UsersStore.Me.id;
    },

    onSearchChange(value: string): void {
      presenter.setDebouncedSearchValue(value);
      presenter.searchValue = value;
    },

    onSearchFocus(): void {
      if (presenter.searchValue) {
        onSearchChange(presenter.searchValue);
      }
    },

    setDebouncedSearchValue: debounce((value: string): void => {
      onSearchChange(value);
    }, SEARCH_DEBOUNCE),

    searchReset(): void {
      presenter.onSearchChange('');
    },
  }));

  useEffect(() => {
    const unsubscribe = bus.bots.subscribeOpenBotsCatalog(presenter.searchReset);

    return () => {
      unsubscribe();
      presenter.searchReset();
    };
  }, []);

  return presenter;
};
