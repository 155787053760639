import { useCallback, useMemo } from 'react';

import Tasks from 'APP/Tasks';
import Entities from 'APP/store';

interface IAudioPlayerPresenter {
  isPlaying: boolean;
  isActiveTrack: boolean;
  progress: number;
  formattedDuration: number;
  onTogglePlay(): void;
}

interface IAudioPlayerPresenterOptions {
  groupId: string;
  messageId: string;
  payloadId: string;
  clientUuid: string;
  duration: number;
}

export function useAudioPlayerPresenter({
  groupId,
  messageId,
  payloadId,
  clientUuid,
  duration,
}: IAudioPlayerPresenterOptions): IAudioPlayerPresenter {
  const player = Entities.miniPlayer;

  const isActiveTrack = useMemo((): boolean => {
    return player.payloadId === payloadId || player.payloadId === clientUuid;
  }, [player.payloadId, payloadId, clientUuid]);

  const onTogglePlay = useCallback((): void => {
    if (isActiveTrack) {
      player.setIsPlaying(!player.isPlaying);
    } else {
      Tasks.miniPlayer.openMiniPlayer(groupId, messageId, clientUuid || payloadId);
    }
  }, [groupId, messageId, payloadId, clientUuid, isActiveTrack]);

  const isPlaying = useMemo((): boolean => {
    return player.isPlaying && isActiveTrack;
  }, [player.isPlaying, player.payloadId, payloadId, clientUuid]);

  const formattedDuration = useMemo((): number => {
    return duration / 1000;
  }, [duration]);

  return {
    isPlaying,
    isActiveTrack,
    progress: player.progress,
    formattedDuration,
    onTogglePlay,
  };
}
