import { observer } from 'mobx-react';
import React from 'react';

import SeenIcon from 'ICONS/ic-s-read-done-16.svg';
import SendingIcon from 'ICONS/ic-s-time.svg';
import ErrorIcon from 'ICONS/message-error.svg';
import SentIcon from 'ICONS/sent.svg';
import { IconView } from 'UIKIT/IconView/IconView';

import styles from './MessageViewsStatusIcon.styles.m.css';

export const getIconInfo = (message) => {
  if (message.isFailed) {
    return {
      Icon: ErrorIcon,
      className: styles.failed,
      dataTest: 'status-failed',
    };
  }

  if (message.isSeen) {
    return {
      Icon: SeenIcon,
      className: styles.default,
      dataTest: 'status-seen',
    };
  }

  if (message.isSending) {
    return {
      Icon: SendingIcon,
      className: styles.sending,
      dataTest: 'status-sending',
    };
  }

  if (message.isSent) {
    return {
      Icon: SentIcon,
      className: styles.default,
      dataTest: 'status-sent',
    };
  }

  return null;
};

export const MessageViewsStatusIcon = observer(({ message }) => {
  const info = getIconInfo(message);

  if (!info) {
    return null;
  }

  const { Icon, className, dataTest } = info;

  return (
    <IconView
      Icon={Icon}
      size="small"
      className={className}
      dataTest={dataTest}
    />
  );
});
