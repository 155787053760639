import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, ReactEventHandler, useState } from 'react';

import { useFileUploadStatePresenter } from 'APP/hooks/useFileUploadState/useFileUploadState';
import type { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';
import { FileIcon, FileIconState, getFileIconThemeByExt } from 'UIKIT/FileIcon/FileIcon';
import { getFileExt } from 'UTILS/mime';

import styles from './FileUploadState.styles.m.css';

interface IUploadStateIconProps {
  message: ChatMessage;
  className?: string;
  onClick: ReactEventHandler<SVGSVGElement | SVGGElement | HTMLAnchorElement>;
}

export const FileUploadState: FC<IUploadStateIconProps> = observer((props) => {
  const { message, onClick, className } = props;
  const [hover, setHover] = useState(false);

  const presenter = useFileUploadStatePresenter({
    hasError: message.hasError,
    uploadProgress: message.payload.uploadProgress,
    cancelUpload: message.payload.cancelUploadAndDeleteMessage,
  });

  const fileExt = getFileExt(message.payload.fileName);

  let state;

  if (presenter.hasError) {
    state = FileIconState.Error;
  } else if (presenter.isWaitingUploadProcess) {
    state = FileIconState.Infinity;
  } else if (presenter.isUploadProcess) {
    state = FileIconState.Loading;
  } else {
    state = hover ? FileIconState.Hover : FileIconState.Regular;
  }

  return (
    <div className={className}>
      <FileIcon
        className={classNames({
          [styles.pointer]: !presenter.isWaitingUploadProcess && !presenter.isUploadProcess,
        })}
        text={fileExt}
        theme={getFileIconThemeByExt(fileExt)}
        state={state}
        onClick={onClick}
        onCancel={presenter.cancelUpload}
        progress={presenter.uploadProgress || 0}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      />
    </div>
  );
});
