import classNames from 'classnames';
import React, { memo } from 'react';

import styles from './FileName.styles.m.css';

const NUMBER_SAVED_SYMBOLS = 12;

interface IFileNameProps {
  name: string;
  className?: string;
}

export const FileName = memo<IFileNameProps>((props) => {
  const { name = '', className } = props;

  let leftPart = name.slice(0, Math.max(0, name.length - NUMBER_SAVED_SYMBOLS));
  // flexbox not displayed ending spaces
  if (leftPart[leftPart.length - 1] === ' ') {
    leftPart = `${leftPart.slice(0, -1)}\u00A0`;
  }
  return (
    <div
      className={classNames(styles.fileName, className)}
      title={name}
    >
      <span className={styles.leftPart}>{leftPart}</span>
      <span className={styles.rightPart}>{name.slice(-NUMBER_SAVED_SYMBOLS)}</span>
    </div>
  );
});
