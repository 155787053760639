import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { showToast } from 'APP/Tasks/app/showToast/showToast';
import { ISpaceCollection } from 'APP/model/space/spaceModel.types';
import { useTranslation } from 'APP/packages/translations';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import Entities from 'STORE';
import { Group } from 'STORE/Groups/Group';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';
import { Space } from 'STORE/Spaces/Space';

import { ICollectionFormData } from '../../components/SpaceCollectionForm/SpaceCollectionForm.types';
import { ISpaceCollectionData } from './SpaceCollectionList/SpaceCollectionList.presenter';

export enum AddToSpaceCollectionStep {
  CollectionList,
  CreateNewCollection,
}

export interface IAddToSpaceCollectionPopupPresenterParams {
  groupId: string;
  messageId: string;
}

interface IAddToSpaceCollectionPopupPresenter {
  group: Group | null;
  message: ChatMessage | null;
  space: Space | null;
  step: AddToSpaceCollectionStep;
  onCreateNewCollectionStep(): void;
  onShowCollectionListStep(): void;
  onSelectCollection(collection: ISpaceCollection): Promise<void>;
  onCollectionCreate(data: ICollectionFormData): Promise<void>;
}

export function useAddToSpaceCollectionPopupPresenter({
  params,
  popupInstance,
}: ICommonPopupsProps<IAddToSpaceCollectionPopupPresenterParams>): IAddToSpaceCollectionPopupPresenter {
  const { t } = useTranslation();
  const { groupId, messageId } = params!;

  const presenter = useLocalStore<IAddToSpaceCollectionPopupPresenter>(() => ({
    step: AddToSpaceCollectionStep.CollectionList,

    get group(): Group | null {
      return groupId ? Entities.GroupsStore.getGroupById(groupId) : null;
    },

    get message(): ChatMessage | null {
      return presenter.group?.messagesStore.getMessageById(messageId) || null;
    },

    get space(): Space | null {
      const spaceId = presenter.group?.spaceId;
      return spaceId ? Entities.spacesStore.getById(spaceId) : null;
    },

    onCreateNewCollectionStep(): void {
      presenter.step = AddToSpaceCollectionStep.CreateNewCollection;
    },

    onShowCollectionListStep(): void {
      presenter.step = AddToSpaceCollectionStep.CollectionList;
    },

    async onSelectCollection(collection: ISpaceCollectionData): Promise<void> {
      if (!collection.canAddMessage) {
        showToast(t('space_alert_post_has_added'));
        return;
      }

      popupInstance.close();

      const items = await Tasks.space.addSpaceCollectionItems({
        collectionId: collection.id,
        spaceId: presenter.space!.id,
        items: [
          {
            groupId,
            messageId,
          },
        ],
      });

      items !== null ? showToast(t('space_alert_post_add')) : showToast(t('something_went_wrong'));
    },

    async onCollectionCreate(data: ICollectionFormData): Promise<void> {
      const collection = await Tasks.space.createSpaceCollection({
        ...data,
        spaceId: presenter.space!.id,
        items: [
          {
            groupId,
            messageId,
          },
        ],
      });

      collection
        ? showToast(t('space_alert_collection_add'))
        : showToast(t('something_went_wrong'));

      popupInstance.close();
    },
  }));

  useEffect(() => {
    if (
      (!Entities.spacesStore.isLoading && !presenter.space?.isOwner) ||
      (!Entities.GroupsStore.isLoading && (!presenter.group || !presenter.message))
    ) {
      popupInstance.close();
    }
  }, [
    presenter.space,
    Entities.spacesStore.isLoading,
    presenter.group,
    Entities.GroupsStore.isLoading,
    presenter.message,
  ]);

  return presenter;
}
