import { CatalogType } from 'APP/model/catalog/catalogModel.types';
import { catalogModel } from 'APP/model/model';
import { ISpace } from 'APP/model/space/spaceModel.types';
import Entities from 'STORE';

// ONE DAY
const RECOMMENDATIONS_CACHE_SAVE_TS = 24 * 60 * 60 * 1000;

export async function loadRecommendedSpaces(): Promise<void> {
  const recommendations = Entities.spacesStore.recommendations;

  if (Date.now() - recommendations.updateTs < RECOMMENDATIONS_CACHE_SAVE_TS) {
    return;
  }

  try {
    const result = await catalogModel.getStartPageItems<ISpace>({ catalogType: CatalogType.Space });

    recommendations.update(result.items, result.recommendationType);
  } catch {
    //
  }
}
