import { useEffect, useRef } from 'react';

import Tasks from 'APP/Tasks';
import bus from 'APP/packages/bus';
import Entities from 'APP/store';

const INTERVAL_FOR_THREADS_REQUEST = 5000;

export default () => {
  const timerId = useRef(null);
  const activeGroupTimerId = useRef(null);

  function updateThreads() {
    clearTimeout(timerId.current);
    clearTimeout(activeGroupTimerId.current);
    const group = Entities.GroupsStore.activeGroup;
    if (group && group.canLeaveComment && Entities.App.isFocused) {
      const messages = group.messagesStore.getMessageByIds(group.messagesStore.inViewMessageIds);
      Tasks.thread.getThreadsInfo({ messages, groupId: group.id });
    }
    timerId.current = setTimeout(updateThreads, INTERVAL_FOR_THREADS_REQUEST);
  }

  useEffect(() => {
    const unsubscribe = bus.ui.subscribeScrollMessages(updateThreads);

    return () => {
      unsubscribe();
      clearTimeout(timerId.current);
      clearTimeout(activeGroupTimerId.current);
    };
  }, []);

  useEffect(() => {
    // await 2s for load and render messages
    const group = Entities.GroupsStore.activeGroup;
    if (group && group.messagesStore.messages.length) {
      activeGroupTimerId.current = setTimeout(updateThreads, 2000);
    } else {
      activeGroupTimerId.current = setTimeout(updateThreads, 500);
    }
  }, [Entities.GroupsStore.activeGroupId]);

  useEffect(() => {
    updateThreads();
  }, [Entities.App.isFocused]);
};
