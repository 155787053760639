import api from 'APP/packages/api';
import logger from 'APP/packages/logger';
import Entities from 'APP/store';

export const deleteDraft = async (draft) => {
  try {
    await api.messaging.deleteDrafts({
      groupId: draft.groupId,
      draftIds: [draft.id],
    });

    const group = Entities.GroupsStore.getGroupById(draft.groupId);

    if (group) {
      group.draftsStore.removeDraftById(draft.id);
    }
    return true;
  } catch (error) {
    logger.get('API').error('messaging.deleteDrafts', error);
    return false;
  }
};
