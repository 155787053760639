import { reaction } from 'mobx';
import { IReactionDisposer } from 'mobx/lib/internal';

import { Call } from '../../Call';

const process = async (store: Call): Promise<void> => {
  if ((!store.isP2P || store.members.memberOnCallList.length > 1) && !store.startTs) {
    store.clearCallingTimer();
    store.startConversationTimer();
  }
};

export const startTimer = (store: Call): IReactionDisposer => {
  return reaction(
    () => store.members.memberOnCallList,
    () => process(store)
  );
};
