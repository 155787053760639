import createEditChannel from './form/createEditChannel';
import createEditSpace from './form/createEditSpace';
import dateTime from './form/dateTime';
import editProfileForm from './form/editProfile';
import loginForm from './form/login';
import newContact from './form/newContact';
import { useCreateEditChatGroupValidations } from './form/useCreateEditChatGroupValidations';

export default {
  editProfileForm,
  loginForm,
  createEditChannel,
  createEditSpace,
  dateTime,
  newContact,
  useCreateEditChatGroupValidations,
};
