import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { useTranslation } from 'APP/packages/translations';
import IconEyeOff from 'ICONS/ic-eye_off-40.svg';
import { Button } from 'UIKIT/Button/Button';

import { useBlurChatPresenter } from './BlurChat.presenter';
import styles from './BlurChat.styles.m.css';

export const BlurChat: FC<{ groupId: string }> = observer(({ groupId }) => {
  const { t } = useTranslation();
  const presenter = useBlurChatPresenter(groupId);

  if (presenter.isShowContent) {
    return null;
  }

  return (
    <div className={styles.container}>
      <IconEyeOff className={styles.icon} />
      <div className={styles.label}>{t('chat_unknown_sender_warning')}</div>
      <Button
        theme="15"
        onClick={presenter.onShow}
      >
        {t('common_show')}
      </Button>
    </div>
  );
});
