import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { CallEventTypes, PERMISSIONS_REQUEST_TYPES } from 'APP/constants/calls';
import {
  PermissionsTypes,
  PermissionsPersonalizedValue,
} from 'APP/model/callPermissions/callPermissionsModel.constants';
import { ICallPersonalizedPermissions } from 'APP/model/callPermissions/callPermissionsModel.types';
import { ServerErrorCode } from 'APP/packages/api';
import { ElectronApi } from 'APP/packages/electron/ElectronApi';
import { useTranslation } from 'APP/packages/translations';
import Entities from 'STORE';
import { Call } from 'STORE/Calls/Call/Call';
import { Me } from 'STORE/Calls/Call/Me/Me';

interface ICallActionsPanelPresenter {
  ongoingCall: Call;
  permissions: ICallPersonalizedPermissions | null;
  cameraPermissions: PermissionsPersonalizedValue | null;
  audioPermissions: PermissionsPersonalizedValue | null;
  screenPermissions: PermissionsPersonalizedValue | null;
  me: Me | null;
  isUnauthorisedCall: boolean;
  isShowRiseHand: boolean;
  isVideoMuted: boolean;
  isAudioMuted: boolean;
  isSharedScreen: boolean;
  isCallStarted: boolean;
  isGuestCall: boolean;
  channelId: string | null;
  isMeInitiatorOwnerOrAdmin: boolean;
  isRaiseHand: boolean;
  checkAndSendPermissionRequest(permissionType: PermissionsTypes): Promise<boolean>;
  unmuteAudio(): Promise<void>;
  muteAudio(): void;
  unmuteVideo(): Promise<void>;
  muteVideo(): void;
  shareScreen(track?: MediaStreamTrack): Promise<void>;
  stopSharingScreen(): Promise<void>;
  toggleHand(): void;
}

export const useCallActionsPanelPresenter = (): ICallActionsPanelPresenter => {
  const { t } = useTranslation();

  const presenter = useLocalStore<ICallActionsPanelPresenter>(() => ({
    get ongoingCall(): Call {
      return Entities.Calls.ongoingCall!;
    },

    get permissions(): ICallPersonalizedPermissions | null {
      const permissions = presenter.ongoingCall.permissions;

      return (
        permissions?.getMemberPersonalizedPermissions(permissions.personalizedPermissions) || null
      );
    },

    get cameraPermissions(): PermissionsPersonalizedValue | null {
      return presenter.permissions && presenter.permissions[PermissionsTypes.SwitchOnCamera]
        ? presenter.permissions[PermissionsTypes.SwitchOnCamera]
        : null;
    },

    get audioPermissions(): PermissionsPersonalizedValue | null {
      return presenter.permissions && presenter.permissions[PermissionsTypes.SwitchOnMic]
        ? presenter.permissions[PermissionsTypes.SwitchOnMic]
        : null;
    },

    get screenPermissions(): PermissionsPersonalizedValue | null {
      return presenter.permissions && presenter.permissions[PermissionsTypes.ShareScreen]
        ? presenter.permissions[PermissionsTypes.ShareScreen]
        : null;
    },

    get me(): Me | null {
      return presenter.ongoingCall.me || null;
    },

    // ToDO: UNION_CALL - test functional for unauthorized calls
    get isUnauthorisedCall(): boolean {
      return Entities.UsersStore.Me.phone === presenter.ongoingCall.groupId;
    },

    get isShowRiseHand(): boolean {
      return !Entities.OngoingCall.mode.isMinimized && !presenter.ongoingCall.isP2P;
    },

    get isVideoMuted(): boolean {
      if (!presenter.me) {
        return true;
      }

      return presenter.me.isVideoMuted;
    },

    get isAudioMuted(): boolean {
      if (!presenter.me) {
        return true;
      }

      return presenter.me.isAudioMuted;
    },

    get isSharedScreen(): boolean {
      if (!presenter.me) {
        return true;
      }

      return presenter.me.isSharedScreen;
    },

    get isCallStarted(): boolean {
      return presenter.ongoingCall.isStarted;
    },

    get isGuestCall(): boolean {
      return presenter.ongoingCall.isGuestCall;
    },

    get channelId(): string | null {
      return presenter.ongoingCall.channelId || null;
    },

    get isMeInitiatorOwnerOrAdmin(): boolean {
      return presenter.ongoingCall.isMeInitiatorOwnerOrAdmin;
    },

    get isRaiseHand(): boolean {
      return !!presenter.me?.isRaiseHand;
    },

    async checkAndSendPermissionRequest(permissionType: PermissionsTypes): Promise<boolean> {
      const permissionValue = presenter.permissions
        ? presenter.permissions[permissionType]
        : undefined;

      if (!permissionValue) {
        return false;
      }

      if (
        permissionValue === PermissionsPersonalizedValue.Always ||
        presenter.isMeInitiatorOwnerOrAdmin
      ) {
        return true;
      }

      if (permissionValue === PermissionsPersonalizedValue.Never) {
        Tasks.app.showAlert(t('call_no_permission'));

        return false;
      }

      const res = await Tasks.app.showConfirm({
        text: t('call_want_ask_for_permission'),
        primaryText: t('common_yes'),
        secondaryText: t('common_no'),
      });

      if (!res) {
        return false;
      }

      const response = await Tasks.calls.createPermissionRequest({
        access: PERMISSIONS_REQUEST_TYPES[permissionType],
        channelId: presenter.channelId,
      });

      if (
        'message' in response &&
        response.message === ServerErrorCode.PermissionAlreadySentError
      ) {
        presenter.ongoingCall.events.addEvent({
          type: CallEventTypes.PermissionRequestAlreadySent,
        });
      } else {
        presenter.ongoingCall.events.addEvent({
          type: CallEventTypes.PermissionRequestSent,
        });
      }

      return false;
    },

    async unmuteAudio(): Promise<void> {
      const canUnmuteMicrophone = await presenter.checkAndSendPermissionRequest(
        PermissionsTypes.SwitchOnMic
      );

      if (canUnmuteMicrophone) {
        await Tasks.calls.audioMuting.unmuteAudio();
      }
    },

    muteAudio(): void {
      Tasks.calls.audioMuting.muteAudio();
    },

    muteVideo(): void {
      Tasks.calls.videoMuting.muteVideo();
    },

    async unmuteVideo(): Promise<void> {
      const canUnmuteVideo = await presenter.checkAndSendPermissionRequest(
        PermissionsTypes.SwitchOnCamera
      );

      if (canUnmuteVideo) {
        await Tasks.calls.videoMuting.unmuteVideo();
      }
    },

    async shareScreen(): Promise<void> {
      const canShareScreen = await presenter.checkAndSendPermissionRequest(
        PermissionsTypes.ShareScreen
      );

      if (!canShareScreen) {
        return;
      }

      let electronSourceId: string | null = null;

      if (ElectronApi.isEnabled) {
        electronSourceId = await Tasks.calls.getDesktopScreenForSharing();

        if (!electronSourceId) {
          return;
        }
      }

      await Tasks.calls.screenSharing.shareScreen(electronSourceId || undefined);
    },

    async stopSharingScreen(): Promise<void> {
      await Tasks.calls.screenSharing.stopSharingScreen();
    },

    toggleHand(): void {
      Tasks.calls.toggleHand();
    },
  }));

  useEffect(() => {
    if (
      presenter.isSharedScreen &&
      presenter.screenPermissions !== PermissionsPersonalizedValue.Always &&
      !presenter.isMeInitiatorOwnerOrAdmin
    ) {
      presenter.stopSharingScreen();
    }
  }, [presenter.isSharedScreen, presenter.screenPermissions, presenter.isMeInitiatorOwnerOrAdmin]);

  useEffect(() => {
    if (
      !presenter.isVideoMuted &&
      presenter.cameraPermissions !== PermissionsPersonalizedValue.Always &&
      !presenter.isMeInitiatorOwnerOrAdmin
    ) {
      presenter.muteVideo();
    }
  }, [presenter.isVideoMuted, presenter.cameraPermissions, presenter.isMeInitiatorOwnerOrAdmin]);

  useEffect(() => {
    if (
      !presenter.isAudioMuted &&
      presenter.audioPermissions !== PermissionsPersonalizedValue.Always &&
      !presenter.isMeInitiatorOwnerOrAdmin
    ) {
      presenter.muteAudio();
    }
  }, [presenter.isAudioMuted, presenter.audioPermissions, presenter.isMeInitiatorOwnerOrAdmin]);

  return presenter;
};
