import Tasks from 'APP/Tasks';
import { getGroupHistoricalMessages } from 'APP/model/message/messageModel';
import logger from 'APP/packages/logger';
import Entities from 'APP/store';

export async function loadMessagesAboveTheTime(
  groupId: string,
  startTs: number,
  hasLoader = true,
  limit?: number
): Promise<boolean> {
  const group = Entities.GroupsStore.getGroupById(groupId);
  if (!group || group.isFake) {
    return false;
  }

  if (hasLoader) {
    group.messagesStore.setIsLoadingMessages(true);
  }

  try {
    const { messages, users, groups } = await getGroupHistoricalMessages(
      group.id,
      startTs + 1,
      limit
    );

    Entities.UsersStore.add(users);
    Entities.GroupsStore.merge(groups);

    await Tasks.relationships.loadUnknownDataFromMessages({ messages });
    Tasks.messaging.handleIsLoadedFirstMessages({ groupId, messages });
    Tasks.messaging.handleIsLoadedLastMessages({ groupId, messages });

    Tasks.messaging.updateOldOrFakeMessages({ messages, groupId, prevMessageTs: startTs });

    if (hasLoader) {
      group.messagesStore.setIsLoadingMessages(false);
    }

    return true;
  } catch (e) {
    if (hasLoader) {
      group.messagesStore.setIsLoadingMessages(false);
    }
    logger.get('TASK').error('messaging.loadMessagesBelowTheTime', e);
    return false;
  }
}
