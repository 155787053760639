import { request } from '../request/request';
import { CategoryListResponse } from '../types/model/categoryListResponse';
import { GlobalSearchResponse } from '../types/model/globalSearchResponse';
import { SearchRequest } from '../types/model/searchRequest';
import { SearchUsersInGroupRequest } from '../types/model/searchUsersInGroupRequest';
import { SearchUsersInGroupResponse } from '../types/model/searchUsersInGroupResponse';
import { SearchUsersInSpaceRequest } from '../types/model/searchUsersInSpaceRequest';
import { SearchUsersInSpaceResponse } from '../types/model/searchUsersInSpaceResponse';

const ROOT_API = 'search';

export const getAllCategoriesInfo = (data: any): Promise<CategoryListResponse> => {
  return request({
    rootApi: ROOT_API,
    url: '/getAllCategoriesInfo',
    method: 'POST',
    body: data,
  });
};

export const globalSearch = (data: SearchRequest): Promise<GlobalSearchResponse> => {
  return request({
    rootApi: ROOT_API,
    url: '/globalSearch',
    method: 'POST',
    body: data,
  });
};

export const searchUsersForMention = (data: any): Promise<any> => {
  return request({
    rootApi: ROOT_API,
    url: '/searchUsersForMention',
    method: 'POST',
    body: data,
  });
};

export const searchUsersAddToGroup = (data: any): Promise<any> => {
  return request({
    rootApi: ROOT_API,
    url: '/searchUsersAddToGroup',
    method: 'POST',
    body: data,
  });
};

export const searchUserInGroup = (
  data: SearchUsersInGroupRequest
): Promise<SearchUsersInGroupResponse> => {
  return request({
    rootApi: ROOT_API,
    url: '/searchUserInGroup',
    method: 'POST',
    body: data,
  });
};

export const searchUserInSpace = (
  data: SearchUsersInSpaceRequest
): Promise<SearchUsersInSpaceResponse> => {
  return request({
    rootApi: ROOT_API,
    url: '/searchUserInSpace',
    method: 'POST',
    body: data,
  });
};
