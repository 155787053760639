import { KEY_CODES_TO_KEY_NAME } from '../constants';

export default class Listener {
  hasCallbacks = false;
  isCaptured = false;
  callbacks;

  constructor(isCaptured, callbacks) {
    this.isCaptured = isCaptured;
    this.callbacks = callbacks;
  }

  onKeyDown = (event) => {
    const keyName = KEY_CODES_TO_KEY_NAME[event.keyCode];

    if (keyName) {
      this.call(event, keyName);
    }
  };

  call = (event, keyName) => {
    const callbacks = this.callbacks[keyName];

    if (!callbacks || callbacks.length === 0) {
      return;
    }

    const callback = callbacks[callbacks.length - 1];

    if (callback.current.isCaptured === this.isCaptured) {
      if (callback.current.isPrevent) {
        event.preventDefault();
        event.stopPropagation();
      }

      callback.current.callback(event);
    }
  };

  add = () => {
    if (this.hasCallbacks) {
      return;
    }

    this.hasCallbacks = true;

    window.addEventListener('keydown', this.onKeyDown, {
      capture: this.isCaptured,
    });
  };

  remove = () => {
    const keyNames = Object.keys(this.callbacks);

    this.hasCallbacks = keyNames.some((keyName) => {
      const callbacks = this.callbacks[keyName];

      const lastItem = callbacks[callbacks.length - 1];

      if (!lastItem) {
        return false;
      }

      return lastItem.current.isCaptured === this.isCaptured;
    });

    if (this.hasCallbacks) {
      return;
    }

    window.removeEventListener('keydown', this.onKeyDown, {
      capture: this.isCaptured,
    });
  };
}
