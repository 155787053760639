import { useAsObservableSource, useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { openPopup } from 'APP/Tasks/app/popup/popup';
import { MAX_PIN_GROUPS } from 'APP/constants/group';
import { AddParticipantsPopup } from 'APP/main/PopupManager/Popups/AddParticipantsPopup/AddParticipantsPopup';
import { ParticipantsPopup } from 'APP/main/PopupManager/Popups/ParticipantsPopup/ParticipantsPopup';
import { useTranslation } from 'APP/packages/translations';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import Entities from 'APP/store';
import AddUserIcon from 'ICONS/ic-add-user.svg';
import BlockIcon from 'ICONS/ic-block.svg';
import ChangeOrderIcon from 'ICONS/ic-change-order.svg';
import ClearIcon from 'ICONS/ic-clear.svg';
import CloseIcon from 'ICONS/ic-close.svg';
import DeleteIcon from 'ICONS/ic-delete.svg';
import EditIcon from 'ICONS/ic-edit.svg';
import LeaveIcon from 'ICONS/ic-log-out.svg';
import PinStrokeIcon from 'ICONS/ic-pin-stroke.svg';
import PrivacyIcon from 'ICONS/ic-privacy.svg';
import ReportIcon from 'ICONS/ic-report.svg';
import UsersIcon from 'ICONS/ic-users.svg';
import { EditChatGroupPopup } from 'MAIN/PopupManager/Popups/EditChatGroupPopup/EditChatGroupPopup';
import { GroupPermissionsPopup } from 'MAIN/PopupManager/Popups/GroupPermissionsPopup/GroupPermissionsPopup';
import { ReportGroupPopup } from 'MAIN/PopupManager/Popups/ReportGroupPopup/ReportGroupPopup';
import { useMoveGroupToSpace } from 'MAIN/hooks/space/useMoveGroupToSpace';

import useSharedDataItems from '../hooks/sharedDataItems';

export const useGroupProfilePresenter = ({ groupId, actions }) => {
  const { t } = useTranslation();
  const navigateTo = useNavigateTo();
  const sharedDataItems = useSharedDataItems({ groupId, actions });
  const source = useAsObservableSource({ groupId });
  const moveGroupToSpace = useMoveGroupToSpace(groupId);

  const presenter = useLocalStore(() => ({
    get group() {
      return Entities.GroupsStore.getGroupById(source.groupId);
    },

    get verified() {
      return presenter.group.verified;
    },

    get space() {
      const spaceId = presenter.group.spaceId;

      return spaceId ? Entities.spacesStore.getById(spaceId) : null;
    },

    get title() {
      return presenter.group.avatarTitle;
    },

    get subtitle() {
      return t('amount_of_members_in_a_group_many').replace('{0}', presenter.group.usersCount);
    },

    get description() {
      return presenter.group.description;
    },

    get isShowInviteLink() {
      if (presenter.group.isOpen) {
        return true;
      }

      return presenter.group.canInviteUsers;
    },

    get childPagesList() {
      return [
        {
          key: 'group-members',
          Icon: UsersIcon,
          title: t('group_chat_people_list_button'),
          onClick: presenter.openGroupMembers,
          count: presenter.group.usersCount,
          isVisible: presenter.group.canDisplayMembers,
        },
        ...sharedDataItems.items,
      ].filter((item) => item.isVisible);
    },

    openGroupMembers() {
      openPopup(ParticipantsPopup, { params: { groupId: source.groupId } });
    },

    get actions() {
      const items = [
        {
          key: 'blacklist',
          isVisible: presenter.isVisibleBlacklist,
          Icon: BlockIcon,
          title: t('balck_list_title'),
          onClick: presenter.showBlacklist,
        },
        {
          key: 'permissions',
          isVisible: presenter.isVisiblePermissions,
          Icon: PrivacyIcon,
          title: t('permissions_label'),
          onClick: presenter.showPermissions,
        },
      ];

      return items.filter((item) => item.isVisible);
    },

    get isVisibleBlacklist() {
      return presenter.group.withMeInAdmins;
    },

    showBlacklist() {
      navigateTo({
        keepOldPathname: true,
        to: '/blacklist',
      });
    },

    get isVisiblePermissions() {
      return presenter.group.withMeInAdmins;
    },

    showPermissions() {
      presenter.childPopup = openPopup(GroupPermissionsPopup, {
        params: { groupId: presenter.group.id },
      });
    },

    get actionItems() {
      const items = [
        {
          key: 'add-people',
          isVisible: presenter.canAddPeople,
          Icon: AddUserIcon,
          title: t('group_chat_add_people_button'),
          onClick: presenter.addPeople,
        },
        {
          key: 'pin-group',
          isVisible: presenter.canPinGroup,
          Icon: PinStrokeIcon,
          title: t('group_chat_pin'),
          onClick: presenter.pinGroup,
        },
        {
          key: 'unpin-group',
          isVisible: presenter.canUnpinGroup,
          Icon: PinStrokeIcon,
          title: t('group_chat_unpin'),
          onClick: presenter.unpinGroup,
        },
        {
          key: 'edit-group',
          isVisible: presenter.canEditGroup,
          Icon: EditIcon,
          title: t('edit_group_chat_profile_title'),
          onClick: presenter.editGroup,
        },
        {
          key: 'move-to-space',
          isVisible: presenter.canMoveToSpace,
          Icon: ChangeOrderIcon,
          title: t('space_moveto_space_button'),
          onClick: presenter.moveToSpace,
        },
        {
          key: 'remove-from-space',
          isVisible: presenter.canRemoveFromSpace,
          Icon: CloseIcon,
          title: t('space_remove_from_button', { 0: presenter.space?.name }),
          onClick: presenter.removeFromSpace,
        },
        {
          key: 'report-group',
          isVisible: presenter.canReportGroup,
          Icon: ReportIcon,
          title: t('common_report'),
          onClick: presenter.reportGroup,
        },
        {
          key: 'clear-messages',
          isVisible: presenter.canClearHistory,
          Icon: ClearIcon,
          title: t('profile_clear_messages'),
          onClick: presenter.clearHistory,
        },
        {
          key: 'delete-group',
          isVisible: presenter.canDeleteGroup,
          Icon: DeleteIcon,
          title: t('delete_group'),
          onClick: presenter.deleteGroup,
        },
        {
          key: 'leave-group',
          isVisible: presenter.canLeaveGroup,
          Icon: LeaveIcon,
          title: t('leave_group_chat'),
          onClick: presenter.leaveGroup,
        },
      ];

      return items.filter((item) => item.isVisible);
    },

    get canAddPeople() {
      if (!presenter.group) {
        return false;
      }

      return presenter.group.canInviteUsers;
    },

    addPeople() {
      openPopup(AddParticipantsPopup, { params: { groupId: source.groupId } });
    },

    get canPinMore() {
      return Entities.GroupsStore.pinnedChatGroups.length < MAX_PIN_GROUPS;
    },

    get canPinGroup() {
      if (!presenter.group || presenter.group.withoutMe) {
        return false;
      }

      return presenter.canPinMore && !presenter.group.isPinned;
    },

    pinGroup() {
      if (!presenter.group) {
        return;
      }

      Tasks.group.pinGroup({ groupId: presenter.group.id });
    },

    get canUnpinGroup() {
      if (!presenter.group || presenter.group.withoutMe) {
        return false;
      }

      return presenter.group.isPinned;
    },

    unpinGroup() {
      if (!presenter.group) {
        return;
      }

      Tasks.group.unpinGroup({ groupId: presenter.group.id });
    },

    get canEditGroup() {
      if (!presenter.group) {
        return false;
      }

      return presenter.group.isChatGroup && presenter.group.withMeInAdmins;
    },

    editGroup() {
      openPopup(EditChatGroupPopup, {
        params: { groupId: source.groupId },
      });
    },

    get canMoveToSpace() {
      return moveGroupToSpace.canMoveToSpace;
    },

    moveToSpace() {
      moveGroupToSpace.moveToSpace();
    },

    get canRemoveFromSpace() {
      return moveGroupToSpace.canRemoveFromSpace;
    },

    removeFromSpace() {
      moveGroupToSpace.removeFromSpace();
    },

    get canReportGroup() {
      if (!presenter.group) {
        return false;
      }

      return !presenter.group.isOwner && presenter.group.isChatGroup;
    },

    reportGroup() {
      openPopup(ReportGroupPopup, {
        params: { groupId: presenter.group.id },
      });
    },

    get canClearHistory() {
      if (!presenter.group) {
        return false;
      }

      return presenter.group.isOwner;
    },

    async clearHistory() {
      if (!presenter.group || presenter.group.withoutMe) {
        return;
      }

      if (presenter.group.isChatGroup && presenter.group.isOwner) {
        const { isConfirmed, isChecked = false } = await Tasks.app.showConfirmWithCheckbox({
          title: t('group_chat_clear_history_alert_headline', { 0: presenter.group.name }),
          text: t('chat_details_clear_history_message'),
          checkBoxText: t('common_clear_history_for_all_members'),
        });
        if (isConfirmed) {
          Tasks.group.clearHistory({
            groupId: presenter.group.id,
            isClearForAll: isChecked,
          });
        }
      } else {
        const isConfirmed = await Tasks.app.showConfirm({
          text: t('group_chat_clear_history_alert'),
        });

        if (isConfirmed) {
          Tasks.group.clearHistory({
            groupId: presenter.group.id,
          });
        }
      }
    },

    get canDeleteGroup() {
      if (!presenter.group) {
        return false;
      }

      return presenter.group.isChatGroup && presenter.group.isOwner;
    },

    deleteGroup() {
      Tasks.app.showConfirm({
        text: t('delete_group_chat_alert'),
        async onSuccess() {
          await Tasks.group.deleteGroup({
            groupId: presenter.group.id,
          });
        },
      });
    },

    get canLeaveGroup() {
      if (!presenter.group || presenter.group.withoutMe) {
        return false;
      }

      return (
        !presenter.group.isOwner && presenter.group.isChatGroup && presenter.group.usersCount > 1
      );
    },

    leaveGroup() {
      if (!presenter.group) {
        return;
      }

      Tasks.app.showConfirm({
        text: t('leave_group_chat_alert'),
        async onSuccess() {
          await Tasks.group.leaveGroup({
            groupId: presenter.group.id,
          });
        },
      });
    },
  }));

  return presenter;
};
