import { useLocalStore, useAsObservableSource } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { useTranslation } from 'APP/packages/translations';
import Entities from 'STORE';

import updateDeepLink from './Tasks/updateDeepLink';

export default ({ group }) => {
  const { t } = useTranslation();
  const source = useAsObservableSource({ group });

  const presenter = useLocalStore(() => ({
    get isAvailableUpdateDeepLinkHash() {
      if (!source.group || !source.group.withMeInAdmins) {
        return false;
      }

      return source.group.isChatGroup || (source.group.isChannel && source.group.isOpen);
    },

    async onUpdateDeepLink() {
      Tasks.app.showConfirm({
        text: t('new_deeplink_confirm_message'),
        primaryText: t('common_yes'),
        async onSuccess() {
          await updateDeepLink({ groupId: source.group.id });
          Entities.toast.show(t('deeplink_created_message'));
        },
      });
    },
  }));

  return presenter;
};
