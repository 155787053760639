import { useTranslation } from 'APP/packages/translations';
import useLastSeenStatusText from 'MAIN/hooks/useLastSeenStatusText';

export default ({ user }) => {
  const { t } = useTranslation();
  const statusText = useLastSeenStatusText({ date: user?.lastOnlineTs || null });

  if (!user) {
    return t('last_seen_a_long_time_ago');
  }

  if (user.isBlockedMe) {
    return t('last_seen_a_long_time_ago');
  }

  if (user.isBlockedByMe) {
    return t('user_blocked');
  }

  if (user.isOnline) {
    return t('common_online');
  }

  return statusText;
};
