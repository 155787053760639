import { observer } from 'mobx-react';
import React, { FC } from 'react';

import PopupUserWithMenuItem from 'MAIN/Popups/GetParametersManager/components/PopupUserWithMenuItem';
import { PopoverMenu } from 'UIKIT/PopoverMenu/PopoverMenu';

import {
  ISpaceMembersListItemPresenterOptions,
  useSpaceBlackListItemPresenter,
} from './SpaceBlackListItem.presenter';

type ISpaceBlackListItemProps = ISpaceMembersListItemPresenterOptions;

export const SpaceBlackListItem: FC<ISpaceBlackListItemProps> = observer((props) => {
  const presenter = useSpaceBlackListItemPresenter(props);

  return (
    <>
      <PopupUserWithMenuItem
        user={props.user}
        isOpenedMenu={presenter.isOpenedMenu}
        isMenuLoading={presenter.isMenuLoading}
        toggleMenu={presenter.onToggleMenu}
        onClick={presenter.canStartUserChat ? presenter.onOpenUserChat : undefined}
      />

      <PopoverMenu
        isOpened={presenter.isOpenedMenu}
        placement="bottom-end"
        menuItems={presenter.menuItems}
        reference={presenter.menuReference}
        onClose={presenter.onCloseMenu}
      />
    </>
  );
});
