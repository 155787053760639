import Article from 'STORE/Messages/Message/Payload/Article';

export default ({ message, title = '', payloads = [] }) => {
  if (!message) {
    return null;
  }

  const payload = {
    ...message.payload.toJSON(),
    title,
    payloads,
  };

  message.payload = new Article(payload);
  return message;
};
