import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Field } from 'APP/packages/form/Field/Field';
import { SelectFieldWithPopup } from 'APP/packages/form/SelectFieldWithPopup/SelectFieldWithPopup';
import { TextField } from 'APP/packages/form/TextField/TextField';
import { useTranslation } from 'APP/packages/translations';
import { ICountry } from 'APP/types/countryCodes';

import { CountryItem } from './CountryItem/CountryItem';
import { useCountryPhoneFieldsPresenter } from './CountryPhoneFields.presenter';
import styles from './CountryPhoneFields.styles.m.css';

interface ICountryPhoneFieldsProps {
  countries: ICountry[];
  country: string;
  phoneNumber: string;
  onPhoneNumberChange(phoneNumber: string): void;
  onCountryChange(countryCode: string): void;
  onCountryPopupOpen?(): void;
  onCountryPopupClose?(): void;
}

export const CountryPhoneFields: FC<ICountryPhoneFieldsProps> = observer(
  ({
    countries,
    country,
    phoneNumber,
    onPhoneNumberChange,
    onCountryChange,
    onCountryPopupOpen,
    onCountryPopupClose,
  }) => {
    const { t } = useTranslation();

    const presenter = useCountryPhoneFieldsPresenter({
      countries,
      country,
      phoneNumber,
      onPhoneNumberChange,
      onCountryChange,
    });

    return (
      <>
        <Field
          value={country}
          values={presenter.countries}
          name="country"
          title={t('select_country_heading')}
          placeholder={t('main_menu_search_note')}
          renderItem={CountryItem}
          renderCurrent={presenter.currentCountryName}
          handleSearch={presenter.filterCountries}
          component={SelectFieldWithPopup}
          onChange={presenter.changeCountryPhone}
          onOpen={onCountryPopupOpen}
          onClose={onCountryPopupClose}
        />
        <Field
          setRef={presenter.phoneRef}
          className={styles.phone}
          value={phoneNumber}
          name="phoneNumber"
          component={TextField}
          placeholder={presenter.placeholder}
          validate={presenter.validatePhone}
          parse={presenter.parsePhoneNumber}
          validatingText={' '}
          successText={' '}
        />
      </>
    );
  }
);
