import stores from 'STORE/index';

class SearchService {
  offset = 0;
  query = '';

  _stringFound = (str, query) => str && str.toLowerCase().indexOf(query) !== -1;

  findLocalGroup(query, lastResults) {
    const groups = lastResults || stores.GroupsStore.groups;
    const lowerQuery = query.toLowerCase();
    const foundGroup = [];

    for (const group of groups) {
      if (group.isBot) {
        const bot = stores.UsersStore.getUserById(group.botOpponent);

        if (
          bot &&
          (this._stringFound(bot.name, lowerQuery) || this._stringFound(bot.nickName, lowerQuery))
        ) {
          foundGroup.push(group);
        }
      } else if (group.isP2P) {
        const opponent = stores.UsersStore.getUserById(group.opponentId);

        if (opponent && this.searchInUserData(opponent, lowerQuery)) {
          foundGroup.push(group);
        }
      } else if (this._stringFound(group.name, lowerQuery)) {
        foundGroup.push(group);
      }
    }
    return foundGroup;
  }

  searchInUserData = (contact, lowerQuery) => {
    const localContact = stores.UsersStore.getUserById(contact.id);

    return (
      (localContact &&
        (this._stringFound(localContact.contactName, lowerQuery) ||
          this._stringFound(localContact.nickName, lowerQuery) ||
          this._stringFound(localContact.userName, lowerQuery))) ||
      this._stringFound(localContact.displayPhone, lowerQuery)
    );
  };

  searchInContactData = (contact, lowerQuery) => this.searchInUserData(contact, lowerQuery);

  searchInCandidates = (candidates, query = '') => {
    return candidates.filter((candidate) => {
      const user = candidate.user || candidate;

      return (
        this._stringFound(user.userName, query) ||
        this._stringFound(user.displayNickname, query) ||
        this._stringFound(user.contactName, query) ||
        this._stringFound(user.displayPhone, query.replace('+', ''))
      );
    });
  };
}

export default new SearchService();
