import { useCallback, useMemo } from 'react';

import { IAudioPlayerTrack } from './AudioPlayer.types';

interface IAudioPlayerTrackManagerOptions {
  activeTrack: IAudioPlayerTrack | null;
  playList: IAudioPlayerTrack[];
  onChangeTrack(track: IAudioPlayerTrack): void;
}

interface IAudioPlayerTrackManager {
  hasPrevious: boolean;
  hasNext: boolean;
  onPrevious(): void;
  onNext(): void;
}

export function useAudioPlayerTrackManager({
  activeTrack,
  playList,
  onChangeTrack,
}: IAudioPlayerTrackManagerOptions): IAudioPlayerTrackManager {
  const trackIndex = useMemo(() => {
    return activeTrack ? playList.findIndex((track) => track.id === activeTrack.id) : -1;
  }, [playList, activeTrack]);

  const hasPrevious = useMemo(() => {
    return trackIndex > 0;
  }, [trackIndex]);

  const onPrevious = useCallback(() => {
    if (playList.length) {
      onChangeTrack(playList[trackIndex - 1] || playList[0]);
    }
  }, [activeTrack, playList]);

  const hasNext = useMemo(() => {
    return trackIndex !== playList.length - 1;
  }, [trackIndex, playList]);

  const onNext = useCallback(() => {
    if (playList.length) {
      onChangeTrack(playList[trackIndex + 1] || playList[0]);
    }
  }, [activeTrack, playList]);

  return {
    hasPrevious,
    hasNext,
    onPrevious,
    onNext,
  };
}
