import Tasks from 'APP/Tasks';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';
import getAvatarUrlByType from 'UTILS/getAvatarUrlByType';

export default async (users) => {
  if (users.length === 0) {
    return;
  }

  const promises = users.reduce((memo, item) => {
    if (!item.avatarUrl) {
      return memo;
    }

    const url = getAvatarUrlByType(AvatarTypes.Round_0, item.avatarUrl);
    const promise = Tasks.app.preloadImage(url);
    memo.push(promise);

    return memo;
  }, []);

  await Promise.all(promises);
};
