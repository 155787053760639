import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { DotLoader } from 'APP/packages/UiKit/DotLoader/DotLoader';
import { useTranslation } from 'APP/packages/translations';
import { Call } from 'STORE/Calls/Call/Call';

import styles from './CallState.styles.m.css';
import { CallTime } from './CallTime/CallTime';

interface ICallStateProps {
  call: Call | null;
}

export const CallState: FC<ICallStateProps> = observer(({ call }) => {
  const { t } = useTranslation();

  if (!call) {
    return null;
  }

  if (call.isBusy) {
    return t('calls_busy');
  }

  if (call.isNoAnswer) {
    return t('calls_no_answer');
  }

  if (call.isCalling) {
    return (
      <>
        {t('calls_calling_status')}
        <DotLoader className={styles.loader} />
      </>
    );
  }

  if (call.isJoining) {
    return (
      <>
        {t('calls_connecting_status')}
        <DotLoader className={styles.loader} />
      </>
    );
  }

  if (call.isAnswered) {
    return <CallTime call={call} />;
  }

  return null;
});
