import Tasks from 'APP/Tasks';
import { t } from 'MODULE/i18n/service';
import Entities from 'STORE';

export const checkConferenceCallPermissions = (groupId: string): boolean => {
  const group = Entities.GroupsStore.getGroupById(groupId);

  if (!group) {
    return false;
  }

  const hasAudioPermissions = Tasks.calls.checkIsAvailableCall(group);

  if (!hasAudioPermissions) {
    return false;
  }

  if (!group?.canInitConference) {
    Tasks.app.showAlert(t('no_permission_alert'));

    return false;
  }

  return true;
};
