import { IStickerPacks } from 'APP/store/Stickers/Stickers';

import { localStorageInstance } from '../../instances/localStorageInstance';

const KEY_NAME = 'public_sticker_packs';

interface IPublicStickerPack {
  packs: IStickerPacks;
  lastUpdateTime: number;
}

export const get = (): IPublicStickerPack | null => {
  const data: string | null = localStorageInstance.getItem(KEY_NAME);

  if (!data) {
    return null;
  }

  try {
    const { lastUpdateTime, packs } = JSON.parse(data);

    return {
      lastUpdateTime: Number(lastUpdateTime),
      packs,
    };
  } catch (error) {
    return null;
  }
};

export const set = ({ packs, lastUpdateTime }: IPublicStickerPack): void => {
  const data = JSON.stringify({
    lastUpdateTime,
    packs,
  });

  return localStorageInstance.setItem(KEY_NAME, data);
};

export const remove = (): void => localStorageInstance.removeItem(KEY_NAME);
