import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { Field } from '../Field/Field';
import RadioItem from '../RadioItem';
import styles from './style.m.scss';

const RadioGroup = ({ className, title, name, options, validateFields = [] }) => {
  return (
    <fieldset className={classNames(styles.container, className)}>
      <div className={styles.title}>{title}</div>
      {options.map((option) => {
        return (
          <Field
            key={option.value}
            type="radio"
            name={name}
            value={option.value}
            label={option.label}
            description={option.description}
            component={RadioItem}
            validateFields={validateFields}
          />
        );
      })}
    </fieldset>
  );
};

export default observer(RadioGroup);
