import { useLocalStore, useAsObservableSource } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { getAlbumLayout } from 'APP/packages/album';

export default ({ message }) => {
  const source = useAsObservableSource({
    isFromMe: message.fromMe && !message.group.isChannel,
    payloads: message.payload.payloads,
    groupId: message.groupId,
    messageId: message.id,
    isFakeGroup: message.isFakeGroup,
  });

  const presenter = useLocalStore(() => ({
    get layout() {
      const sizes = source.payloads.map(({ payload }, idx) => ({
        w: payload.graphicSize.width,
        h: payload.graphicSize.height,
        id: idx,
      }));

      return getAlbumLayout(sizes).map((row, idx) => ({ id: idx, items: row }));
    },

    get isFromMe() {
      return source.isFromMe;
    },

    getPayload(id) {
      return source.payloads[id];
    },

    openMediaGallery({ mediaId }) {
      Tasks.group.openMediaGallery({
        groupId: source.groupId,
        messageId: source.messageId,
        mediaId,
        showMessageInfo: !source.isFakeGroup,
      });
    },
  }));

  return presenter;
};
