import Tasks from 'APP/Tasks';
import { AudioSourceType } from 'APP/constants/app';
import { FinishCallReason } from 'APP/model/call/callModel.constants';
import { callModel } from 'APP/model/model';
import { UnauthorizedCallType } from 'APP/model/unauthorizedCall/unauthorizedCallModel.constants';
import logger from 'APP/packages/logger';
import { RouterLink } from 'APP/router/constants';
import { navigateTo } from 'APP/router/refs/navigateTo';
import Entities from 'APP/store';

export const finishCall = async ({ groupId }: { groupId: string }): Promise<void> => {
  try {
    const call = Entities.Calls.getCallByGroupId(groupId);

    Tasks.app.audioSource.removeSource(AudioSourceType.Call);

    if (!call) {
      return;
    }

    if (call.isP2P && call.channelId) {
      await callModel.finishCall(
        call.channelId,
        call.providerType,
        call.isGuestCall ? FinishCallReason.Left : FinishCallReason.FinishAll
      );
    }

    if (call.isGuestCall) {
      navigateTo({
        to: call.isP2P ? RouterLink.callFinished : RouterLink.callFinishedGroup,
        params: {
          id: groupId,
          channelId: call.channelId,
          callType: call.isP2P ? UnauthorizedCallType.CallMe : UnauthorizedCallType.Call,
        },
      });
    }

    if (call.channelId) {
      Tasks.feedback.checkStarsFeedback(call.channelId);
    }
    call.delete();
  } catch (e) {
    logger.get('API').error('calls.finishCall', e);
  }
};
