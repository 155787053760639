import { useLocalStore } from 'mobx-react';

import Entities from 'APP/store';

export default () => {
  const presenter = useLocalStore(() => ({
    get inputPanel() {
      return Entities.InputPanelsStore.activeInputPanel;
    },

    get linkPreviewData() {
      if (presenter.inputPanel?.link) {
        return presenter.inputPanel.linkPreviews.get(presenter.inputPanel.link) || null;
      }
      return null;
    },

    get isShowLinkPreview() {
      return (
        presenter.inputPanel &&
        presenter.inputPanel.isShowLinkPreview &&
        !presenter.inputPanel.linkPreviewCancelled &&
        presenter.linkPreviewData
      );
    },

    closePreview: () => {
      presenter.inputPanel.setLink(null);
      presenter.inputPanel.linkPreviews.clear();
      presenter.inputPanel.setIsLinkPreviewCancelled(true);
    },
  }));

  return presenter;
};
