import api from 'APP/packages/api';
import Entities from 'APP/store';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

export default async (data) => {
  try {
    const group = Entities.GroupsStore.activeGroup;
    const draft = await api.messaging.getDraft(data);

    const chatDraft = new ChatMessage(draft, group.messagesStore);

    return chatDraft || null;
  } catch (error) {
    return null;
  }
};
