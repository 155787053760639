import { useLocalStore, useAsObservableSource } from 'mobx-react';

import { RouterPage, RouterState } from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';

export function usePresenter({ message }) {
  const navigateTo = useNavigateTo();
  const source = useAsObservableSource({ message, navigateTo });

  const presenter = useLocalStore(() => ({
    openPreview: () => {
      if (source.message.isFake) {
        return;
      }

      source.navigateTo({
        to: `/${source.message.group.route}/${source.message.group.id}/article-preview/${source.message.id}`,
        state: {
          [RouterState.hasGoBack]: true,
        },
      });
    },

    editDraft: () => {
      navigateTo({
        to: `/${RouterPage.Channels}/${presenter.message.groupId}/article/${presenter.message.id}`,
      });
    },

    get title() {
      return presenter.payload.title;
    },

    get text() {
      return presenter.payload.firstTextPayload;
    },

    get media() {
      return presenter.payload.firstMediaPayload;
    },

    get message() {
      return source.message;
    },

    get isShowEditButton() {
      return presenter.message.isArticleDraft;
    },

    get payload() {
      return presenter.message.renderPayload;
    },

    get isFromMe() {
      return source.message.fromMe && !source.message.group.isChannel;
    },
  }));

  return presenter;
}
