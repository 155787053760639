import { observer } from 'mobx-react';
import React from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { useTranslation } from 'APP/packages/translations';
import { Button } from 'UIKIT/Button/Button';
import { ButtonTypes } from 'UIKIT/Button/Button.types';
import { MultiLineField } from 'UIKIT/MultiLineField/MultiLineField';

import { FeedbackIssueType, mapFeedbackIssueTypeToTranslation } from './DetailedFeedback.constants';
import { useDetailedFeedbackPresenter } from './DetailedFeedback.presenter';
import styles from './DetailedFeedback.styles.m.css';
import { FeedbackIssueButton } from './components/FeedbackIssueButton/FeedbackIssueButton';
import { FeedbackProblemButtons } from './components/FeedbackProblemButtons/FeedbackProblemButtons';

export const DetailedFeedback = observer(() => {
  const { t } = useTranslation();
  const presenter = useDetailedFeedbackPresenter();

  if (!presenter.isShown) {
    return null;
  }

  return (
    <Popup
      title={t('heading_give_feedback')}
      isOpened
      onClose={presenter.onClose}
      isIgnoreClickOutside
    >
      <div className={styles.wrapper}>
        <div className={styles.subTitle}>{t('heading_give_feedback') as string}</div>
        <div className={styles.issuesTabsContainer}>
          {Object.values(FeedbackIssueType).map((type) => (
            <FeedbackIssueButton
              key={type}
              issueType={type}
              isActive={presenter.activeIssueType === type}
              text={t(mapFeedbackIssueTypeToTranslation[type])}
              onClick={presenter.onIssueTypeButtonClick}
            />
          ))}
        </div>
        {!!presenter.activeIssueType && (
          <>
            <div className={styles.subTitle}>{t('heading_what_happened')}</div>
            <div className={styles.problemsTypesContainer}>
              <FeedbackProblemButtons
                activeProblemType={presenter.activeProblemType}
                activeIssueType={presenter.activeIssueType}
                onButtonClick={presenter.onProblemTypeButtonClick}
              />
            </div>
          </>
        )}
        <MultiLineField
          setRef={presenter.inputRef}
          value={presenter.descriptionValue}
          label={t('input_name_problem')}
          className={styles.input}
          onChange={presenter.onDescriptionChange}
        />
      </div>
      <Button
        theme="1"
        isDisabled={!presenter.activeProblemType && !presenter.descriptionValue}
        type={ButtonTypes.Button}
        className={styles.button}
        onClick={presenter.onSend}
      >
        {t('send')}
      </Button>
    </Popup>
  );
});
