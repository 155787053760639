import api from 'APP/packages/api';
import { GetCallInfoViaLinkRequestDeepLinkTypeEnum } from 'APP/packages/api/types/model/getCallInfoViaLinkRequest';
import logger from 'APP/packages/logger';

import { buildModelError } from '../error/errorBuilder';
import {
  mapCallInvitationInfoResponse,
  mapUnauthorizedCallInfoResponse,
} from './unauthorizedCallModel.mapper';
import {
  ICallInvitationInfo,
  IGetCallInfoViaLinkParams,
  IUnauthorizedCallInfo,
} from './unauthorizedCallModel.types';

export const getCallInfoViaLink = async (
  params: IGetCallInfoViaLinkParams
): Promise<ICallInvitationInfo> => {
  try {
    const body = {
      ...params,
      deepLinkType: params.deepLinkType as unknown as GetCallInfoViaLinkRequestDeepLinkTypeEnum,
    };

    const callInfo = await api.calls.getCallInfoViaLink(body);
    return mapCallInvitationInfoResponse(callInfo);
  } catch (error) {
    logger.get('API').error('calls.getCallInfoViaLink', error);
    throw buildModelError(error);
  }
};

export const joinCallMeViaLink = async (
  entityId: string,
  username: string
): Promise<IUnauthorizedCallInfo> => {
  try {
    const callInfo = await api.calls.joinCallMeViaLink({
      inviterUserId: entityId,
      username,
    });
    return mapUnauthorizedCallInfoResponse(callInfo);
  } catch (error) {
    logger.get('API').error('model.unauthorizedCall.joinCallMeViaLink', error);
    throw buildModelError(error);
  }
};

export const joinGroupCallViaLink = async (
  channelId: string,
  username: string
): Promise<IUnauthorizedCallInfo> => {
  try {
    const callInfo = await api.calls.joinGroupCallViaLink({
      channelId,
      username,
    });
    return mapUnauthorizedCallInfoResponse(callInfo);
  } catch (error) {
    logger.get('API').error('model.unauthorizedCall.joinGroupCallViaLink', error);
    throw buildModelError(error);
  }
};
