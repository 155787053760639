import { IVerifyOauthTokenRequest } from 'APP/model/auth/authModel.types';
import { DeviceInfoV2PlatformEnum } from 'APP/packages/api/types/model/deviceInfoV2';
import { ExternalAuthProviderId } from 'APP/packages/api/types/model/externalAuthProviderId';
import { VerifyOauthTokenRequest } from 'APP/packages/api/types/model/verifyOauthTokenRequest';

export const mapAuthSocialNetworkRequest = (
  data: IVerifyOauthTokenRequest
): VerifyOauthTokenRequest => {
  return {
    ...data,
    authProviderId: data.authProviderId as ExternalAuthProviderId,
    deviceInfo: {
      ...data.deviceInfo,
      platform: data.deviceInfo.platform as DeviceInfoV2PlatformEnum,
    },
  };
};
