import { useLocalStore } from 'mobx-react';

import Entities from 'APP/store';

export default () => {
  const interactor = useLocalStore(() => ({
    focusApp() {
      Entities.App.setFocused(true);
    },

    unfocusApp() {
      Entities.App.setFocused(false);
    },
  }));

  return interactor;
};
