import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { showToast } from 'APP/Tasks/app/showToast/showToast';
import { useTranslation } from 'APP/packages/translations';
import Entities from 'STORE';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';
import { Post } from 'STORE/Newsfeed/NewsItems/Post';
import { Newsfeed } from 'STORE/Newsfeed/Newsfeed';
import { Space } from 'STORE/Spaces/Space';
import { CancelPromiseHandler, makeCancellablePromise } from 'UTILS/makeCancellablePromise';

const PRELOAD_POSITION = 5;

export interface ISpaceCollectionItemsPresenterParams {
  space: Space;
  collectionId: string;
}

export interface ISpaceCollectionItemsPresenter {
  collectionItems: Newsfeed;
  collectionItemMessageIdMap: Record<string, string>;
  isLoading: boolean;
  cursor: string | null;
  cancelLoad: CancelPromiseHandler | null;
  readonly isEmpty: boolean;
  readonly isShowPlaceholder: boolean;
  readonly hasBackgroundImage: boolean;
  hasMoreItems: boolean;
  load(): Promise<void>;
  onReload(): void;
  onSetNewsVisibility(position: number, isView: boolean): void;
  checkAndLoadMore(position: number): void;
  setViewMessage(position: number, isView: boolean): void;
  onRemoveCollectionItem(message: ChatMessage): Promise<void>;
}

export function useSpaceCollectionItemsPresenter({
  space,
  collectionId,
}: ISpaceCollectionItemsPresenterParams): ISpaceCollectionItemsPresenter {
  const { t } = useTranslation();
  const source = useAsObservableSource({ space, collectionId });

  const presenter = useLocalStore<ISpaceCollectionItemsPresenter>(() => ({
    collectionItems: new Newsfeed(),
    collectionItemMessageIdMap: {},
    isLoading: false,
    cursor: null,
    hasMoreItems: true,
    cancelLoad: null,

    async load(): Promise<void> {
      presenter.isLoading = true;

      const { promise: loadCollectionItems, cancel } = makeCancellablePromise(
        Tasks.space.getSpaceCollectionItems({
          spaceId: source.space.id,
          collectionId: source.collectionId,
          cursor: presenter.cursor || undefined,
        })
      );

      presenter.cancelLoad = cancel;

      const collectionItemsResponse = await loadCollectionItems;

      if (collectionItemsResponse) {
        presenter.collectionItems.addNews(collectionItemsResponse.items);
        presenter.cursor = collectionItemsResponse.cursor;
        collectionItemsResponse.items.forEach((item) => {
          if (item.message?.message.id) {
            presenter.collectionItemMessageIdMap[item.message?.message.id] = item.id;
          }
        });
      }

      if (!presenter.cursor) {
        presenter.hasMoreItems = false;
      }

      presenter.cancelLoad = null;
      presenter.isLoading = false;
    },

    onReload(): void {
      presenter.collectionItems.reset();
      presenter.collectionItemMessageIdMap = {};
      presenter.cursor = null;
      presenter.cancelLoad?.();
      presenter.load();
    },

    get hasBackgroundImage(): boolean {
      return Entities.appearance.hasChatBackground;
    },

    onSetNewsVisibility(position: number, isView: boolean): void {
      if (isView) {
        presenter.checkAndLoadMore(position);
      }
      presenter.setViewMessage(position, isView);
    },

    setViewMessage(position: number, isView: boolean): void {
      const news = presenter.collectionItems.getPostByPosition(position);
      if (news) {
        isView ? Tasks.messaging.inViewMessage(news) : Tasks.messaging.outViewMessage(news);
      }
    },

    checkAndLoadMore(position: number): void {
      const targetPosition = presenter.collectionItems.news.length - PRELOAD_POSITION;

      if (!presenter.isLoading && this.cursor && position >= targetPosition) {
        presenter.load();
      }
    },

    get isEmpty(): boolean {
      return !presenter.collectionItems.news.length;
    },

    get isShowPlaceholder(): boolean {
      return presenter.isLoading && presenter.isEmpty;
    },

    async onRemoveCollectionItem(message: ChatMessage): Promise<void> {
      const messageId = message.id;
      const collectionItemId = presenter.collectionItemMessageIdMap[messageId];

      if (!collectionItemId) {
        return;
      }

      const result = await Tasks.space.deleteSpaceCollectionItems({
        spaceId: source.space.id,
        collectionId: source.collectionId,
        collectionItemIds: [collectionItemId],
      });

      if (result) {
        presenter.collectionItems.news
          .find((item) => (item as Post).messageId === messageId)
          ?.setVisibility(false);

        showToast(t('space_alert_post_removed'));
      } else {
        showToast(t('something_went_wrong'));
      }
    },
  }));

  useEffect(() => {
    if (space && collectionId) {
      presenter.onReload();
    }
  }, [space, collectionId]);

  return presenter;
}
