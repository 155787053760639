import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import connection from 'APP/packages/connection';
import logger from 'APP/packages/logger';
import Entities from 'APP/store';

export default () => {
  const presenter = useLocalStore(() => ({
    subscriptions: [],

    subscribe() {
      const handleUserTyping = ({ event, groupId, userId, username }) => {
        const group = Entities.GroupsStore.getGroupById(groupId);
        if (!group) {
          return;
        }
        const activity = {
          userId,
          type: event,
          payload: { event, groupId, userId, username },
        };
        group.userTyping.add(activity);
        logger.get('UserTypingHandler').debug('handleUserTyping', { group, activity });
      };

      presenter.subscriptions.push(connection.main.userTyping.listen(handleUserTyping));

      const handleMessagesSent = ({ groupId, userId }) => {
        logger.get('UserTypingHandler').debug('clearUserTypingInGroup', { groupId, userId });

        const group = Entities.GroupsStore.getGroupById(groupId);
        if (group) {
          group.userTyping.remove(userId);
        }
      };

      presenter.subscriptions.push(connection.main.sent.listen(handleMessagesSent));
    },

    unsubscribe() {
      presenter.subscriptions.forEach((unsubscribe) => unsubscribe());
    },
  }));

  useEffect(() => {
    presenter.subscribe();

    return () => {
      presenter.unsubscribe();
    };
  }, []);
};
