// Emoji may have the length more than one
/**
 * @deprecated Use getStringCharactersCount function
 */
const getLengthWithSpecialSymbols = (name) => {
  const trimmedName = name.trim();

  if (!trimmedName) {
    return 0;
  }

  return trimmedName.match(/./gu).length;
};

export default getLengthWithSpecialSymbols;
