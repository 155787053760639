import { action, computed, observable } from 'mobx';

import { AuthProvider, IAccountProvider } from 'APP/model/auth/authModel.types';

import { User } from '../User';

export class Me extends User {
  @observable isUserValid = false;
  @observable accountProviders: IAccountProvider[] = [];

  get isMe(): boolean {
    return true;
  }

  @action
  setAccountProviders(providers: IAccountProvider[]): void {
    this.accountProviders = providers;
  }

  @action
  addAccountProvider(account: IAccountProvider): void {
    this.accountProviders.push(account);
  }

  @action
  updateAccountProvider(account: IAccountProvider): void {
    this.accountProviders = this.accountProviders.map((accountProvider) => {
      if (accountProvider.authProviderId === account.authProviderId) {
        return account;
      }
      return accountProvider;
    });
  }

  @action
  removeAccountProvider(authProviderId: AuthProvider): void {
    const accountIndex = this.accountProviders.findIndex(
      (account) => account.authProviderId === authProviderId
    );

    if (accountIndex !== -1) {
      this.accountProviders.splice(accountIndex, 1);
    }
  }

  @action
  setIsValid(isValid: boolean): void {
    this.isUserValid = isValid;
  }

  @action
  updatePhone(phone: string): void {
    this.phone = phone.replace('+', '');
  }

  @computed
  get displayPhone(): string {
    return this.phone ? `+${this.phone}` : '';
  }
}
