import Tasks from 'APP/Tasks';
import { PayloadType } from 'APP/model/message/messageModel.types';
import api from 'APP/packages/api';
import Entities from 'APP/store';

import updateMessagePayload from './updateMessagePayload';

export default async (data) => {
  try {
    const group = Entities.GroupsStore.getGroupById(data.groupId);
    if (!group) {
      return;
    }

    const message = updateMessagePayload({
      message: group.messagesStore.getMessageById(data.messageId),
      title: data.title,
      payloads: data.payloads,
    });
    if (!message) {
      return;
    }

    const updatedMessage = await api.messaging.updateMessage({
      messageId: data.messageId,
      groupId: data.groupId,
      payload: {
        payloadType: PayloadType.Article,
        title: data.title,
        payloads: data.payloads,
      },
    });

    Tasks.messaging.updateOldOrFakeMessages({
      messages: [updatedMessage],
      groupId: data.groupId,
    });
  } catch (e) {
    return;
  }
};
