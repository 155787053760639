import { useCallback } from 'react';

import { openPopup } from 'APP/Tasks/app/popup/popup';
import { StickerPackPopup } from 'MAIN/PopupManager/Popups/StickerPackPopup/StickerPackPopup';

export default () => {
  const onOpenStickerPack = useCallback((sticker) => {
    openPopup(StickerPackPopup, { params: { stickerPackId: sticker.stickerPackId } });
  }, []);

  return { onOpenStickerPack };
};
