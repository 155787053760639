import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useField } from 'react-final-form';

import { mapUserProfileBadgeToIcon } from 'APP/constants/userBadges';
import { UserProfileUsernameBadge } from 'APP/model/user/userModel.types';
import { useTranslation } from 'APP/packages/translations';
import IcBlock from 'ICONS/ic-block.svg';

import { EditProfileBadgeItem } from '../EditProfileBadgeItem/EditProfileBadgeItem';
import { EditProfileMissionSuccess } from '../EditProfileMissionSuccess/EditProfileMissionSuccess';
import { useEditProfileBadgePresenter } from './EditProfileBadge.presenter';
import styles from './EditProfileBadge.styles.m.css';

interface IEditProfileBadgeProps {
  onOpenRewards(): void;
}

export const EditProfileBadge: FC<IEditProfileBadgeProps> = observer(({ onOpenRewards }) => {
  const { t } = useTranslation();
  const { input } = useField<UserProfileUsernameBadge | undefined>('usernameBadge');
  const { presenter, animationData } = useEditProfileBadgePresenter();

  return (
    <>
      <div className={styles.container}>
        <h3 className={styles.title}>{t('common_profile_username_badge')}</h3>
        <div className={styles.subTitle}>{t('common_profile_username_badge_hint')}</div>
        <div className={styles.badgesContainer}>
          <EditProfileBadgeItem
            source={IcBlock}
            className={styles.defaultBadge}
            isActive={!input.value}
            isDisabled={!presenter.isRewardAchieved}
            onClick={input.onChange}
            onOpenRewards={onOpenRewards}
          />

          {Object.values(UserProfileUsernameBadge).map((item) => {
            const val = mapUserProfileBadgeToIcon[item];

            return (
              <EditProfileBadgeItem
                key={item}
                animated={val.animated}
                badgeType={item}
                source={val.source}
                isActive={item === input.value}
                hasLock={presenter.showLock}
                isDisabled={!presenter.isRewardAchieved}
                onClick={input.onChange}
                onOpenRewards={onOpenRewards}
              />
            );
          })}
        </div>
      </div>
      {animationData && presenter.showAnimation && (
        <EditProfileMissionSuccess
          animationData={animationData}
          onComplete={presenter.onCompleteAnimation}
        />
      )}
    </>
  );
});
