import { draftModel } from 'APP/model/model';
import Entities from 'APP/store';
import { replaceHtmlTagsToSpecialSymbols } from 'UTILS/html/replaceHtmlTagsToSpecialSymbols';

export async function updateDraftInput(groupId: string, value: string): Promise<void> {
  const group = Entities.GroupsStore.getGroupById(groupId);

  if (!group || group.isFake) {
    return;
  }

  const clearValue = replaceHtmlTagsToSpecialSymbols(value);
  const inputPanel = Entities.InputPanelsStore.getById(groupId);

  if (inputPanel?.isEditMode || (group.draftInput?.text || '') === clearValue) {
    return;
  }

  try {
    group.setDraftInput({ groupId, text: clearValue, updateTs: Date.now() });
    await draftModel.updateDraftInput({
      groupId,
      draftInput: clearValue.trim() ? clearValue : undefined,
    });
  } catch {
    // Do nothing
  }
}
