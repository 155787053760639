import React, { FC } from 'react';

import Entities from 'APP/store';
import DeleteIcon from 'ICONS/ic-delete.svg';
import { FAB } from 'UIKIT/FAB/FAB';

export const CancelVoiceButton: FC = () => {
  return (
    <FAB
      size={'30'}
      onClick={Entities.InputPanel.voice.reset}
      Icon={DeleteIcon}
      theme={'8'}
    />
  );
};
