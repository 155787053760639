import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { RewardProgressInfoRewardEnum } from 'APP/model/userSettings/userSettingsModel.types';
import { IRewardProgressInfo } from 'APP/types/rewards';
import Entities from 'STORE';

export interface IRewardsBaseItemPresenter {
  rewardProgress: IRewardProgressInfo | null;
  isInView: boolean;
  onChangeInView(val: boolean): void;
}

export const useRewardsBaseItemPresenter = (
  rewardType: RewardProgressInfoRewardEnum
): IRewardsBaseItemPresenter => {
  const presenter = useLocalStore<IRewardsBaseItemPresenter>(() => ({
    isInView: false,

    get rewardProgress(): IRewardProgressInfo | null {
      return Entities.rewards.rewardsProgress.get(rewardType) || null;
    },

    onChangeInView: (isInViewport: boolean): void => {
      presenter.isInView = isInViewport;
    },
  }));

  useEffect(() => {
    if (presenter.isInView && !presenter.rewardProgress?.acknowledged) {
      Tasks.userSettings.ackRewards(rewardType);
    }
  }, [presenter.isInView, presenter.rewardProgress]);

  return presenter;
};
