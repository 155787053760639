import { SpaceTemplate } from 'APP/model/space/spaceModel.types';

import { analyticsClient } from '../client/AnalyticsClient';
import { AnalyticsSpaceEvent, SpaceJoinType } from './spaceAnalytics.types';

export function trackSpaceOnboardingSkipped(stepNumber: number): void {
  analyticsClient.track({
    eventType: AnalyticsSpaceEvent.SpaceOnboardingSkipped,
    eventProperties: {
      step_number: stepNumber,
    },
  });
}

export function trackSpaceOnboardingCompleted(): void {
  analyticsClient.track({
    eventType: AnalyticsSpaceEvent.SpaceOnboardingCompleted,
  });
}

export function trackSpaceDeleted(spaceId: string, spaceTemplate?: SpaceTemplate): void {
  analyticsClient.track({
    eventType: AnalyticsSpaceEvent.SpaceDeleted,
    eventProperties: {
      space_id: spaceId,
      space_auto_type: spaceTemplate,
    },
  });
}

export function trackSpaceNewCreated(spaceType: string): void {
  analyticsClient.track({
    eventType: AnalyticsSpaceEvent.SpaceNewCreated,
    eventProperties: {
      space_type: spaceType,
    },
  });
}

export function trackSpaceJoined(spaceId: string, type: SpaceJoinType): void {
  analyticsClient.track({
    eventType: AnalyticsSpaceEvent.SpaceJoined,
    eventProperties: {
      space_id: spaceId,
      space_join_type: type,
    },
  });
}

export function trackSpaceLeft(spaceId: string): void {
  analyticsClient.track({
    eventType: AnalyticsSpaceEvent.SpaceLeft,
    eventProperties: {
      space_id: spaceId,
    },
  });
}

export function trackSpacePinned(spaceId: string): void {
  analyticsClient.track({
    eventType: AnalyticsSpaceEvent.SpacePinned,
    eventProperties: {
      space_id: spaceId,
    },
  });
}

export function trackSpaceUnpined(spaceId: string): void {
  analyticsClient.track({
    eventType: AnalyticsSpaceEvent.SpaceUnpined,
    eventProperties: {
      space_id: spaceId,
    },
  });
}

export function trackSpaceMarkedAsRead(spaceId: string): void {
  analyticsClient.track({
    eventType: AnalyticsSpaceEvent.SpaceMarkedAsRead,
    eventProperties: {
      space_id: spaceId,
    },
  });
}

export function trackSpaceChannelCreated(
  spaceId: string,
  spaceType: string,
  channelId: string,
  channelType: string
): void {
  analyticsClient.track({
    eventType: AnalyticsSpaceEvent.SpaceChannelCreated,
    eventProperties: {
      space_id: spaceId,
      space_type: spaceType,
      channel_id: channelId,
      channel_type: channelType,
    },
  });
}

export function trackSpaceGroupCreated(
  spaceId: string,
  spaceType: string,
  groupId: string,
  groupType: string
): void {
  analyticsClient.track({
    eventType: AnalyticsSpaceEvent.SpaceGroupCreated,
    eventProperties: {
      space_id: spaceId,
      space_type: spaceType,
      group_id: groupId,
      group_type: groupType,
    },
  });
}

export function trackSpaceGroupAdded(
  spaceId: string,
  spaceRole: string,
  groupId: string,
  groupType: string
): void {
  analyticsClient.track({
    eventType: AnalyticsSpaceEvent.SpaceGroupAdded,
    eventProperties: {
      space_id: spaceId,
      space_member_type: spaceRole,
      group_id: groupId,
      group_type: groupType,
    },
  });
}

export function trackSpaceGroupDeleted(
  spaceId: string,
  spaceRole: string,
  groupId: string,
  groupType: string
): void {
  analyticsClient.track({
    eventType: AnalyticsSpaceEvent.SpaceGroupDeleted,
    eventProperties: {
      space_id: spaceId,
      space_member_type: spaceRole,
      group_id: groupId,
      group_type: groupType,
    },
  });
}

export function trackSpaceChannelAdded(
  spaceId: string,
  spaceRole: string,
  channelId: string,
  channelType: string
): void {
  analyticsClient.track({
    eventType: AnalyticsSpaceEvent.SpaceChannelAdded,
    eventProperties: {
      space_id: spaceId,
      space_member_type: spaceRole,
      channel_id: channelId,
      channel_type: channelType,
    },
  });
}

export function trackSpaceChannelDeleted(
  spaceId: string,
  spaceRole: string,
  channelId: string,
  channelType: string
): void {
  analyticsClient.track({
    eventType: AnalyticsSpaceEvent.SpaceChannelDeleted,
    eventProperties: {
      space_id: spaceId,
      space_member_type: spaceRole,
      channel_id: channelId,
      channel_type: channelType,
    },
  });
}

export function trackSpaceMarketAdded(spaceId: string, marketId: string): void {
  analyticsClient.track({
    eventType: AnalyticsSpaceEvent.SpaceMarketAdded,
    eventProperties: {
      space_id: spaceId,
      shop_id: marketId,
    },
  });
}

export function trackSpaceMarketDeleted(spaceId: string, marketId: string): void {
  analyticsClient.track({
    eventType: AnalyticsSpaceEvent.SpaceMarketDeleted,
    eventProperties: {
      space_id: spaceId,
      shop_id: marketId,
    },
  });
}

export function trackSpacePopularClicked(spaceId: string): void {
  analyticsClient.track({
    eventType: AnalyticsSpaceEvent.SpacePopularClicked,
    eventProperties: {
      space_id: spaceId,
    },
  });
}

export function trackSpaceSuggestedClicked(spaceId: string): void {
  analyticsClient.track({
    eventType: AnalyticsSpaceEvent.SpaceSuggestedClicked,
    eventProperties: {
      space_id: spaceId,
    },
  });
}

export function trackSpaceCollectionCreated(spaceId: string): void {
  analyticsClient.track({
    eventType: AnalyticsSpaceEvent.SpaceCollectionCreated,
    eventProperties: {
      space_id: spaceId,
    },
  });
}

export function trackSpaceCollectionDeleted(spaceId: string): void {
  analyticsClient.track({
    eventType: AnalyticsSpaceEvent.SpaceCollectionDeleted,
    eventProperties: {
      space_id: spaceId,
    },
  });
}

export function trackSpaceAddedToCollection(spaceId: string): void {
  analyticsClient.track({
    eventType: AnalyticsSpaceEvent.SpaceAddedToCollection,
    eventProperties: {
      space_id: spaceId,
    },
  });
}

export function trackSpaceCollectionViewed(spaceId: string): void {
  analyticsClient.track({
    eventType: AnalyticsSpaceEvent.SpaceCollectionViewed,
    eventProperties: {
      space_id: spaceId,
    },
  });
}

export function trackSpaceMemberRemoved(spaceId: string, memberId: string): void {
  analyticsClient.track({
    eventType: AnalyticsSpaceEvent.SpaceMemberRemoved,
    eventProperties: {
      space_id: spaceId,
      member_id: memberId,
    },
  });
}

export function trackSpaceMemberBlocked(spaceId: string, memberId: string): void {
  analyticsClient.track({
    eventType: AnalyticsSpaceEvent.SpaceMemberBlocked,
    eventProperties: {
      space_id: spaceId,
      member_id: memberId,
    },
  });
}

export function trackSpaceAdminAdded(spaceId: string, memberId: string): void {
  analyticsClient.track({
    eventType: AnalyticsSpaceEvent.SpaceAdminAdded,
    eventProperties: {
      space_id: spaceId,
      member_id: memberId,
    },
  });
}

export function trackSpaceAdminRemoved(spaceId: string, memberId: string): void {
  analyticsClient.track({
    eventType: AnalyticsSpaceEvent.SpaceAdminRemoved,
    eventProperties: {
      space_id: spaceId,
      member_id: memberId,
    },
  });
}

export function trackSpaceTransferOwnership(spaceId: string, memberId: string): void {
  analyticsClient.track({
    eventType: AnalyticsSpaceEvent.SpaceTransferOwnership,
    eventProperties: {
      space_id: spaceId,
      member_id: memberId,
    },
  });
}

export function trackSpaceGroupChatVisibleAllChats(spaceId: string, groupId: string): void {
  analyticsClient.track({
    eventType: AnalyticsSpaceEvent.SpaceGroupChatVisibleAllChats,
    eventProperties: {
      space_id: spaceId,
      group_id: groupId,
    },
  });
}

export function trackSpaceGroupChatInvisibleAllChats(spaceId: string, groupId: string): void {
  analyticsClient.track({
    eventType: AnalyticsSpaceEvent.SpaceGroupChatInvisibleAllChats,
    eventProperties: {
      space_id: spaceId,
      group_id: groupId,
    },
  });
}

export function trackSpaceChannelVisibleAllChats(spaceId: string, groupId: string): void {
  analyticsClient.track({
    eventType: AnalyticsSpaceEvent.SpaceChannelVisibleAllChats,
    eventProperties: {
      space_id: spaceId,
      group_id: groupId,
    },
  });
}

export function trackSpaceChannelInvisibleAllChats(spaceId: string, groupId: string): void {
  analyticsClient.track({
    eventType: AnalyticsSpaceEvent.SpaceChannelInvisibleAllChats,
    eventProperties: {
      space_id: spaceId,
      group_id: groupId,
    },
  });
}
