import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { Avatar } from 'UIKIT/Avatar/Avatar';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';
import popupItemStyles from 'UIKIT/PopupItem/styles.m.scss';

import { usePresenter } from './presenter';
import styles from './styles.m.css';

export const CallMemberPermissionsItem = observer(({ userId }) => {
  const presenter = usePresenter(userId);

  return (
    <button
      tabIndex="0"
      onClick={presenter.onMemberClick}
      className={classNames(styles.container, popupItemStyles.interactive)}
    >
      <Avatar
        type={AvatarTypes.Round_10}
        title={presenter.member.displayName}
        url={presenter.member.avatarUrl}
      />
      <div className={styles.memberInfo}>
        <div className={styles.memberName}>{presenter.member.displayName}</div>
        <div className={styles.memberPermissions}>{presenter.permissionsTranslations}</div>
      </div>
    </button>
  );
});
