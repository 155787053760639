import classNames from 'classnames';
import React, { FC } from 'react';
import { Placement } from 'tippy.js';

import styles from './TooltipContent.styles.m.css';

interface ITooltipContentProps {
  className?: string;
  attrs: {
    'data-placement': Placement;
    'data-reference-hidden'?: string;
    'data-escaped'?: string;
  };
  arrow?: boolean;
}

export const TooltipContent: FC<ITooltipContentProps> = ({ className, attrs, arrow, children }) => {
  return (
    <div
      className={classNames(className, styles.tooltip)}
      tabIndex={-1}
      role="tooltip"
      {...attrs}
    >
      <div className={styles.content}>{children}</div>

      {arrow && (
        <div
          className={styles.arrow}
          data-popper-arrow=""
        ></div>
      )}
    </div>
  );
};
