import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { PopoverMenu } from 'UIKIT/PopoverMenu/PopoverMenu';

import {
  IBlackListMenuPresenterParams,
  useBlackListMenuPresenter,
} from './BlackListMenu.presenter';

interface IBlackListMenuProps extends IBlackListMenuPresenterParams {
  reference: HTMLElement | null;
}

export const BlackListMenu: FC<IBlackListMenuProps> = observer(({ reference, ...props }) => {
  const presenter = useBlackListMenuPresenter(props);

  return (
    <PopoverMenu
      isOpened={presenter.isOpened}
      placement="bottom-end"
      menuItems={presenter.menuItems}
      reference={reference}
      onClose={presenter.closeMenu}
    />
  );
});
