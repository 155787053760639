import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import Entities from 'STORE';
import { Call } from 'STORE/Calls/Call/Call';

interface IPermissionsRequestHandlerPresenter {
  prevInitiatorOwnerOrAdmin: boolean | null;
  call: Call;
  isMeInitiatorOwnerOrAdmin: boolean;
  isCallStarted: boolean;
  channelId: string | null;
  getPermissionsData(): void;
  checkRoleChanged(): void;
}

export const usePermissionsRequestHandlerPresenter = (): void => {
  const presenter = useLocalStore<IPermissionsRequestHandlerPresenter>(() => ({
    prevInitiatorOwnerOrAdmin: null,

    get call(): Call {
      return Entities.Calls.ongoingCall!;
    },

    get isMeInitiatorOwnerOrAdmin(): boolean {
      return presenter.call.isMeInitiatorOwnerOrAdmin;
    },

    get isCallStarted(): boolean {
      return presenter.call.isStarted;
    },

    get channelId(): string | null {
      return presenter.call.channelId || null;
    },

    getPermissionsData(): void {
      if (presenter.channelId) {
        Tasks.calls.getPermissionRequests(presenter.channelId);
        Tasks.calls.getCallPermissions(presenter.channelId);
      }
    },

    checkRoleChanged(): void {
      if (
        presenter.isCallStarted &&
        presenter.prevInitiatorOwnerOrAdmin !== presenter.isMeInitiatorOwnerOrAdmin
      ) {
        presenter.getPermissionsData();
      }
      presenter.prevInitiatorOwnerOrAdmin = presenter.isMeInitiatorOwnerOrAdmin;
    },
  }));

  useEffect(() => {
    presenter.checkRoleChanged();
  }, [presenter.isMeInitiatorOwnerOrAdmin, presenter.isCallStarted]);
};
