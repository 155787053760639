import Tasks from 'APP/Tasks';
import { getRangeMessageByTs } from 'APP/model/message/messageModel';
import { DEFAULT_LIMIT_OF_MESSAGES_LOADING } from 'APP/model/message/messageModel.constants';
import logger from 'APP/packages/logger';
import Entities from 'APP/store';

export async function loadRangeMessageByTs(
  groupId: string,
  timestamp: number,
  halfLimit: number = DEFAULT_LIMIT_OF_MESSAGES_LOADING / 2
): Promise<void> {
  const group = Entities.GroupsStore.getGroupById(groupId);
  if (!group || group.isFake) {
    return;
  }

  group.messagesStore.setIsLoadingMessages(true);

  try {
    const res = await getRangeMessageByTs(groupId, timestamp, halfLimit);

    if (res.messages) {
      Entities.UsersStore.add(res.users);
      Entities.GroupsStore.merge(res.groups);

      await Tasks.relationships.loadUnknownDataFromMessages({
        messages: res.messages,
      });

      const indexTargetMessage = res.messages.findIndex(
        (message) => Number(message.serverTime) === timestamp
      );

      if (indexTargetMessage < halfLimit) {
        group.messagesStore.setLoadedFirstMessages(true);
      }

      if (res.messages.length - indexTargetMessage < halfLimit) {
        group.messagesStore.setLoadedLastMessages(true);
      }

      Tasks.messaging.updateOldOrFakeMessages({
        messages: res.messages,
        groupId: group.id,
      });
    }
  } catch (error) {
    logger.get('TASK').error('messaging.loadRangeMessageByTs', error);
  } finally {
    group.messagesStore.setIsLoadingMessages(false);
  }
}
