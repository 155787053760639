import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import useClearGetParameters from 'APP/router/hooks/useClearGetParameters';
import Entities from 'STORE';

export const usePresenter = () => {
  const clearGetParameters = useClearGetParameters();

  const presenter = useLocalStore(() => ({
    onClose: () => {
      clearGetParameters();
    },

    get channelId() {
      return Entities.Calls.ongoingCall?.channelId;
    },

    get ongoingCall() {
      return Entities.Calls.ongoingCall;
    },

    get permissionsRequests() {
      return Entities.Calls.ongoingCall?.permissionsRequests;
    },

    get isPermissionsRequestsEmpty() {
      return Entities.Calls.ongoingCall?.isPermissionsRequestsEmpty;
    },
  }));

  useEffect(() => {
    if (!presenter.ongoingCall || !presenter.ongoingCall?.isStarted) {
      presenter.onClose();
    }
  }, [presenter.ongoingCall?.isStarted]);

  useEffect(() => {
    if (presenter.isPermissionsRequestsEmpty) {
      presenter.onClose();
    }
  }, [presenter.isPermissionsRequestsEmpty]);

  return presenter;
};
