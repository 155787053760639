import { observer } from 'mobx-react';
import React from 'react';

import { AddKeyPressHandler } from 'APP/packages/keyBoardHandler';
import { useTranslation } from 'APP/packages/translations';
import BackIcon from 'ICONS/ic-back.svg';
import { IconButton } from 'UIKIT/IconButton';

import usePresenter from './presenter';
import styles from './styles.m.css';

const ScheduleInfo = ({ group }) => {
  const presenter = usePresenter({ group });
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <AddKeyPressHandler onEsc={presenter.onBack} />
      <IconButton
        className={styles.back}
        Icon={BackIcon}
        onClick={presenter.onBack}
        size="big"
        iconSize="medium"
        theme="8"
      />
      {t('common:scheduled_messages')}
    </div>
  );
};

export default observer(ScheduleInfo);
