import { ALERT_TYPES } from 'APP/constants/app';
import { getDeepLink } from 'APP/model/deeplinks/deeplinksModel';
import {
  DeepLinkType,
  IDeepLink,
  IGetDeepLinkRequest,
} from 'APP/model/deeplinks/deeplinksModel.types';
import Entities from 'STORE';

import { addAlert } from '../../app/showAlert/showAlert';
import { handleGroup } from './handlers/handleGroup';
import { handleSpace } from './handlers/handleSpace';

interface IGetDeepLinkParams {
  link?: string;
  deepLink?: IDeepLink | null;
}

interface IDeepLinkData {
  userId?: string;
  groupId?: string;
  spaceId?: string;
  messageId?: string;
  stickerPackId?: string;
  groupRoute?: string;
  spaceRoute?: string;
  isAlreadyJoined?: boolean;
}

async function request(data: IGetDeepLinkRequest): Promise<IDeepLink | null> {
  try {
    return await getDeepLink(data);
  } catch (error) {
    return null;
  }
}

export async function getParamsByDeepLink(data: IGetDeepLinkParams): Promise<IDeepLinkData | null> {
  let { deepLink } = data;

  if (!deepLink && data.link) {
    deepLink = await request({ url: data.link });
  }

  if (deepLink?.expired) {
    addAlert({ type: ALERT_TYPES.DEEP_LINK_EXPIRED });
    return null;
  }

  if (!deepLink?.type) {
    return null;
  }

  switch (deepLink.type) {
    case DeepLinkType.CallMe: {
      return { userId: deepLink.entityId };
    }

    case DeepLinkType.StickerPack: {
      return { stickerPackId: deepLink.entityId };
    }

    case DeepLinkType.User: {
      return { userId: deepLink.entityId };
    }

    case DeepLinkType.Call:
    case DeepLinkType.Group: {
      const group = Entities.GroupsStore.getGroupById(deepLink.entityId);
      const result = await handleGroup({ groupId: deepLink.entityId });

      return {
        groupId: deepLink.entityId,
        spaceId: result?.spaceId || undefined,
        groupRoute: result?.groupRoute,
        isAlreadyJoined: !!group?.isMember,
      };
    }

    case DeepLinkType.Message: {
      const result = await handleGroup({ groupId: deepLink.parentId, isChannel: true });

      return {
        groupRoute: result?.groupRoute,
        messageId: deepLink.entityId,
        groupId: deepLink.parentId,
      };
    }

    case DeepLinkType.SpaceLocal: {
      return { spaceRoute: (await handleSpace(deepLink.entityId)) || undefined };
    }

    case DeepLinkType.Card: {
      window.open(data.link, '_blank');
      return null;
    }

    default: {
      return null;
    }
  }
}
