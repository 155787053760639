import classNames from 'classnames';
import React from 'react';

import UnionIcon from 'ICONS/union.svg';

import styles from './styles.m.css';

export interface ICheckboxProps {
  className?: string;
  isChecked?: boolean;
  isDisabled?: boolean;
  onChange(value: boolean): void;
}

export const Checkbox: React.FC<ICheckboxProps> = ({
  className,
  isChecked = false,
  isDisabled = false,
  onChange,
}) => {
  const checkboxClass = classNames(className, styles.checkboxWrapper, {
    [styles.selected]: isChecked,
  });

  return (
    <div className={checkboxClass}>
      <input
        type="checkbox"
        className={styles.checkbox}
        onChange={(event) => onChange(event.target.checked)}
        checked={isChecked}
        disabled={isDisabled}
      />
      {isChecked && <UnionIcon />}
    </div>
  );
};
