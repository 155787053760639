import { useLocalStore } from 'mobx-react';

import { openPopup } from 'APP/Tasks/app/popup/popup';
import { OnboardingType } from 'APP/constants/onboarding';
import MascotSpace1Image from 'APP/images/gem-mascot-space-1.svg';
import MascotSpace2Image from 'APP/images/gem-mascot-space-2.svg';
import MascotSpace3Image from 'APP/images/gem-mascot-space-3.svg';
import MascotSpace4Image from 'APP/images/gem-mascot-space-4.svg';
import { spaceAnalytics } from 'APP/packages/analytics/analytics';
import { useTranslation } from 'APP/packages/translations';
import { CreateSpacePopup } from 'MAIN/PopupManager/Popups/CreateSpacePopup/CreateSpacePopup';
import { useOnboardingManager } from 'MAIN/hooks/onboardingManager/useOnboardingManager';
import { Popup } from 'STORE/App/Popups/Popup/Popup';

export interface ISpaceDemoPopupSlide {
  readonly title: string;
  readonly description: string;
  readonly image: string;
  readonly buttonTitle?: string;
  onButtonClick?(): void;
}

export interface ISpaceDemoPopupPresenter {
  currentSlideIndex: number;
  timeoutId: number | null;
  slides: ISpaceDemoPopupSlide[];
  slide: ISpaceDemoPopupSlide;
  isShowPrevButton: boolean;
  isShowNextButton: boolean;
  onSelectSlide(slideIndex: number): void;
  stopSlideChange(): void;
  onPrevSlide(): void;
  onNextSlide(): void;
  onSkipOnboarding(): void;
  onCreateSpace(): void;
  onClose(): void;
}

export function useSpaceDemoPopupPresenter(popupInstance: Popup): ISpaceDemoPopupPresenter {
  const { t } = useTranslation();
  const onboardingManager = useOnboardingManager(OnboardingType.SpaceDemo);

  const presenter = useLocalStore<ISpaceDemoPopupPresenter>(() => ({
    currentSlideIndex: 0,
    timeoutId: null,

    get slides(): ISpaceDemoPopupSlide[] {
      return [
        {
          title: t('space_first_story_title'),
          description: t('space_first_story_description'),
          image: MascotSpace1Image,
        },
        {
          title: t('space_second_story_title'),
          description: t('space_second_story_description'),
          image: MascotSpace4Image,
        },
        {
          title: t('space_third_story_title'),
          description: t('space_third_story_description'),
          image: MascotSpace3Image,
        },
        {
          title: t('space_fourth_story_title'),
          description: t('space_fourth_story_description'),
          image: MascotSpace2Image,
          buttonTitle: t('space_create_button'),
          onButtonClick: presenter.onCreateSpace,
        },
      ];
    },

    get slide(): ISpaceDemoPopupSlide {
      return presenter.slides[presenter.currentSlideIndex];
    },

    onSelectSlide(slideIndex): void {
      presenter.currentSlideIndex = slideIndex;
    },

    stopSlideChange(): void {
      if (presenter.timeoutId) {
        clearTimeout(presenter.timeoutId);
        presenter.timeoutId = null;
      }
    },

    get isShowPrevButton(): boolean {
      return presenter.currentSlideIndex !== 0;
    },

    onPrevSlide(): void {
      presenter.currentSlideIndex--;
    },

    get isShowNextButton(): boolean {
      return presenter.currentSlideIndex !== presenter.slides.length - 1;
    },

    onNextSlide(): void {
      presenter.currentSlideIndex++;
    },

    onSkipOnboarding(): void {
      presenter.onClose();
      spaceAnalytics.trackSpaceOnboardingSkipped(presenter.currentSlideIndex + 1);
    },

    onCreateSpace(): void {
      presenter.onClose();
      openPopup(CreateSpacePopup);
      spaceAnalytics.trackSpaceOnboardingCompleted();
    },

    onClose(): void {
      popupInstance.close();
      onboardingManager.handleClose();
    },
  }));

  return presenter;
}
