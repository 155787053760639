import classNames from 'classnames';
import React, { FC, ElementType, MouseEvent } from 'react';

import { IconView } from 'APP/packages/UiKit/IconView/IconView';

import styles from './TextFormattingMenuButton.styles.m.css';

type TTheme = '1' | '2' | '3';

interface ITextFormattingMenuButtonProps {
  className?: string;
  theme: TTheme;
  Icon: ElementType;
  isDisabled?: boolean;
  onClick(event: MouseEvent<HTMLButtonElement>): void;
}

export const TextFormattingMenuButton: FC<ITextFormattingMenuButtonProps> = (props) => {
  const { className, theme, Icon, isDisabled, onClick } = props;

  return (
    <button
      className={classNames(styles.button, styles[`theme-${theme}`], className)}
      disabled={isDisabled}
      onClick={onClick}
    >
      <IconView
        className={styles.icon}
        Icon={Icon}
      />
    </button>
  );
};
