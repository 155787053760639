import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { IStickerPackExtend } from 'APP/model/stickers/stickersModel.types';
import { IconButton } from 'APP/packages/UiKit/IconButton';
import PopupInfoCard from 'APP/packages/UiKit/PopupInfoCard';
import popupItemStyles from 'APP/packages/UiKit/PopupItem/styles.m.scss';
import IcDelete from 'ICONS/ic-delete.svg';
import IcPlus from 'ICONS/ic-plus.svg';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';

import { useStickerPackPresenter } from './StickerPack.presenter';
import styles from './StickerPack.styles.m.scss';

interface StickerPacksItemProps {
  data: IStickerPackExtend;
  onStickerPackClick(stickerPack: IStickerPackExtend): void;
}
export const StickerPack: FC<StickerPacksItemProps> = observer(({ data, onStickerPackClick }) => {
  const presenter = useStickerPackPresenter({ pack: data, onStickerPackClick });

  return (
    <div
      className={classNames(
        popupItemStyles.container,
        popupItemStyles.interactive,
        styles.container
      )}
      role="button"
      tabIndex={0}
      onClick={presenter.onClick}
    >
      <PopupInfoCard
        type={AvatarTypes.Round_10}
        avatarTitle={presenter.avatarTitle}
        avatarUrl={presenter.avatarUrl}
        avatarBackgroundColorIndex="1"
        title={presenter.title}
        subtitle={presenter.subtitle}
      />
      <IconButton
        isLoading={presenter.isLoading}
        className={styles.icon}
        Icon={presenter.isAddedPack ? IcDelete : IcPlus}
        onClick={presenter.toggle}
      />
    </div>
  );
});
