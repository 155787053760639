import { observer } from 'mobx-react';
import React from 'react';

import { ActiveSpeakerIcon } from 'APP/components/ActiveSpeakerIcon/ActiveSpeakerIcon';

import MutedBadge from '../MutedBadge';

const AudioState = ({ streamer, className }) => {
  if (!streamer) {
    return null;
  }

  return (
    <div className={className}>
      {streamer.isAudioMuted ? (
        <MutedBadge />
      ) : streamer.isActiveSpeaker ? (
        <ActiveSpeakerIcon isBig />
      ) : null}
    </div>
  );
};

export default observer(AudioState);
