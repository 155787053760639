import classNames from 'classnames';
import React, { ElementType, FC, MutableRefObject, ReactElement } from 'react';

import { IconView } from 'UIKIT/IconView/IconView';

import styles from './MetaBlock.styles.m.css';

interface IMetaBlockProps {
  className?: string;
  label: string;
  containerRef?: MutableRefObject<HTMLDivElement>;
  Icon: ElementType;
  Actions?: ReactElement;
}

export const MetaBlock: FC<IMetaBlockProps> = ({
  className,
  label,
  children,
  containerRef,
  Icon,
  Actions,
}) => {
  return (
    <div
      className={classNames(styles.container, className)}
      ref={containerRef}
    >
      <IconView
        className={styles.icon}
        Icon={Icon}
      />
      <div className={styles.info}>
        <h6 className={styles.label}>{label}</h6>
        <div className={styles.content}>{children}</div>
      </div>
      {Actions && (
        <div className={styles.actions}>
          <div className={styles.shadow} />
          <div className={styles.actionsBackground}>{Actions}</div>
        </div>
      )}
    </div>
  );
};
