import { useTranslation } from 'APP/packages/translations';
import { useCopyToClipboard } from 'MAIN/Profile/hooks/useCopyToClipboard';
import { User } from 'STORE/Users/User/User';

interface IProfileNicknamePresenter {
  copyToClipboard(): void;
}

export function useProfileNicknamePresenter(user: User): IProfileNicknamePresenter {
  const { t } = useTranslation();
  const copyData = user.isMe ? user.deepLink : user.displayNickname;
  const message = user.isMe ? t('common_link_copied') : t('profile_user_nickname_copied');

  const copyToClipboard = useCopyToClipboard(copyData || '', message);

  return {
    copyToClipboard,
  };
}
