import { GoogleOAuthProvider } from '@react-oauth/google';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import envConfig from 'CONFIG';

import { useLinkGoogleProviderPresenter } from './LinkGoogleProvider.presenter';
import { LinkGoogleProviderButton } from './LinkGoogleProviderButton/LinkGoogleProviderButton';

interface ILinkGoogleProviderProps {
  setIsProcessing(isProcessing: boolean): void;
}

export const LinkGoogleProvider: FC<ILinkGoogleProviderProps> = observer((props) => {
  const { setIsProcessing } = props;

  const presenter = useLinkGoogleProviderPresenter({ setIsProcessing });

  return (
    <GoogleOAuthProvider clientId={envConfig.googleClientId}>
      <LinkGoogleProviderButton
        onSuccess={presenter.onSuccess}
        onError={presenter.onError}
      />
    </GoogleOAuthProvider>
  );
});
