import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { MessageViewPageType } from 'MAIN/ChatView/Messages/Messages.types';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

import { MessageLayout } from '../../MessageLayout/MessageLayout';
import { ForwardMessage } from '../components/ForwardMessage/ForwardMessage';
import { QuotedMessage } from '../components/QuotedMessage/QuotedMessage';
import StatusBar from '../components/StatusBar';
import { ThreadButton } from '../components/ThreadButton/ThreadButton';
import { useStickerBubblePresenter } from './StickerBubble.presenter';
import styles from './StickerBubble.styles.m.css';

interface StickerBubbleProps {
  message: ChatMessage;
  children: React.ReactNode;
  messageViewPageType: MessageViewPageType;
  isView: boolean;
}

export const StickerBubble: FC<StickerBubbleProps> = observer((props) => {
  const { message, children, messageViewPageType, isView } = props;
  const presenter = useStickerBubblePresenter(message);

  return (
    <MessageLayout
      message={message}
      className={styles.bubble}
      containerClassName={styles.container}
      messageViewPageType={messageViewPageType}
      isView={isView}
    >
      {presenter.isShowForward && (
        <ForwardMessage
          className={styles.header}
          theme={'2'}
          message={message}
        />
      )}

      {message.quotedMessage && (
        <QuotedMessage
          className={styles.header}
          theme={'2'}
          message={message.quotedMessage}
        />
      )}

      <div className={styles.content}>
        {children}
        <StatusBar
          className={styles.statusBar}
          message={message}
        />
      </div>

      <ThreadButton
        className={styles.thread}
        theme={'2'}
        message={message}
      />
    </MessageLayout>
  );
});
