import { useLocalStore } from 'mobx-react';
import { useHistory, useLocation } from 'react-router-dom';

import Tasks from 'APP/Tasks';
import { GroupType } from 'APP/model/group/groupModel.types';
import { PayloadType } from 'APP/model/message/messageModel.types';

export default () => {
  const location = useLocation();
  const history = useHistory();
  const message = location.state.message;

  const presenter = useLocalStore(() => ({
    shareToChat(groupId) {
      Tasks.messaging.createNewMessages({
        messages: [
          {
            type: PayloadType.RichText,
            data: { text: message },
            groupId,
          },
        ],
      });
    },
    send: async (data) => {
      let { type, data: target } = data;
      switch (type) {
        case 'chat': {
          if (target.type === GroupType.SavedMessagesFake) {
            target = await Tasks.group.createGroupAndInvite({
              type: GroupType.MySavedMessages,
            });
          }
          presenter.shareToChat(target.id);
          break;
        }
        case 'channel': {
          presenter.shareToChat(target.id);
          break;
        }
        case 'contact': {
          if (!target.group) {
            target = await Tasks.group.createP2PGroup(target.id);
            presenter.shareToChat(target.id);
          } else {
            presenter.shareToChat(target.group.id);
          }
          break;
        }
        default:
          break;
      }

      return target.group ? target.group : target;
    },
    get isAvailableToShow() {
      return Boolean(message);
    },
    onClose() {
      history.goBack();
    },
    onSelect: async (selection) => {
      const target = await presenter.send(selection[0]);
      presenter.onClose(target);
    },
    onSuccess(target) {
      let { route, id } = target;
      history.push(`/${route}/${id}`);
    },
  }));

  return presenter;
};
