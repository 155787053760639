import classNames from 'classnames';
import React, { FC } from 'react';

import styles from './PopupFooter.style.m.scss';

interface IPopupFooterProps {
  readonly className?: string;
}

export const PopupFooter: FC<IPopupFooterProps> = ({ className, children }) => {
  return <div className={classNames(styles.container, className)}>{children}</div>;
};
