import { observer } from 'mobx-react';
import React, { FC, MutableRefObject } from 'react';
import { InView } from 'react-intersection-observer';

import EmptyImage from 'APP/images/gem-mascot-not-found.svg';
import { CatalogMainPlaceholder } from 'APP/packages/placeholders/CatalogPlaceholder/CatalogMainPlaceholder/CatalogMainPlaceholder';
import { useTranslation } from 'APP/packages/translations';
import { InfoBlock, InfoBlockTypes } from 'UIKIT/InfoBlock/InfoBlock';

import { TCatalogResetHandler } from '../Catalog.types';
import { CatalogLoader } from '../components/CatalogLoader/CatalogLoader';
import { CatalogWidget } from '../components/CatalogWidget/CatalogWidget';
import { useCatalogMainContentPresenter } from './CatalogMainContent.presenter';
import styles from './CatalogMainContent.styles.m.css';

interface ICatalogMainContentProps {
  scrollRef: MutableRefObject<HTMLDivElement | null>;
  onCatalogReset: TCatalogResetHandler;
}

export const CatalogMainContent: FC<ICatalogMainContentProps> = observer((props) => {
  const { scrollRef, onCatalogReset } = props;
  const presenter = useCatalogMainContentPresenter(onCatalogReset);
  const { t } = useTranslation();

  return (
    <>
      {presenter.isLoadingView && <CatalogMainPlaceholder />}

      {presenter.isEmptyView && (
        <InfoBlock
          descriptions={[t('nothing_found')]}
          type={InfoBlockTypes.Page}
          image={EmptyImage}
        />
      )}

      {presenter.isContentView && (
        <>
          {presenter.widgets.map((widget) => (
            <CatalogWidget
              key={widget.widgetId}
              className={styles.widget}
              type={widget.viewType}
              showItemType={presenter.isShowItemType}
              title={widget.title}
              items={widget.items}
              scrollPosition={presenter.getWidgetScrollPosition(widget.widgetId)}
              onScrollPositionChange={(position: number): void => {
                presenter.onWidgetScrollPositionChange(widget.widgetId, position);
              }}
            />
          ))}
        </>
      )}

      {presenter.isLoadingMoreView && <CatalogLoader />}

      <InView
        key={presenter.viewObserverKey}
        root={scrollRef.current}
        rootMargin="0px 0px 400px 0px"
        onChange={presenter.onViewObserver}
      />
    </>
  );
});
