export async function requestPermission(): Promise<string> {
  if (window.Notification) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return window.Notification.requestPermission();
  }
  return 'default';
}

export function getCurrentPermission(): string {
  if (window.Notification) {
    return window.Notification.permission;
  }
  return 'default';
}
