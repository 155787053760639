import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Tasks from 'APP/Tasks';
import { useTranslation } from 'APP/packages/translations';
import { RouterLink, RouterPage } from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import Entities from 'STORE';
import { Group } from 'STORE/Groups/Group';
import { Space } from 'STORE/Spaces/Space';

interface IDeleteSpacePopupPresenter {
  isLoading: boolean;
  isSubmitting: boolean;
  selectedGroups: Set<string>;
  space: Space | null;
  groups: Group[];
  isEmpty: boolean;
  showDivider: boolean;
  showGroupList: boolean;
  hasBackButton: boolean;
  init(): void;
  onDeselectAll(): void;
  onToggleGroup(groupId: string): void;
  onSubmit(): Promise<void>;
  onClose(): void;
  onBack(): void;
}

export interface IDeleteSpacePopupParams {
  spaceId: string;
}

export function useDeleteSpacePopupPresenter({
  params,
  popupInstance,
  onClose,
  onBack,
}: ICommonPopupsProps<IDeleteSpacePopupParams>): IDeleteSpacePopupPresenter {
  const { spaceId } = params!;
  const { t } = useTranslation();
  const navigateTo = useNavigateTo();
  const location = useLocation();

  const source = useAsObservableSource({ location, navigateTo });

  const presenter = useLocalStore<IDeleteSpacePopupPresenter>(() => ({
    isLoading: true,
    isSubmitting: false,
    selectedGroups: new Set<string>(),

    get space(): Space | null {
      return Entities.spacesStore.getById(spaceId);
    },

    get groups(): Group[] {
      return Entities.GroupsStore.getGroupsBySpaceId(spaceId).filter((group) => group.isOwner);
    },

    get isEmpty(): boolean {
      return !presenter.isLoading && !presenter.groups.length;
    },

    get showDivider(): boolean {
      return !presenter.isEmpty;
    },

    get showGroupList(): boolean {
      return !presenter.isLoading && !!presenter.groups.length;
    },

    get hasBackButton(): boolean {
      return !!onBack;
    },

    init(): void {
      presenter.isLoading = false;
      presenter.selectedGroups = new Set<string>(presenter.groups.map((group) => group.id));
    },

    onDeselectAll(): void {
      presenter.selectedGroups.clear();
    },

    onToggleGroup(groupId: string): void {
      const checkedGroups = presenter.selectedGroups;
      checkedGroups.has(groupId) ? checkedGroups.delete(groupId) : checkedGroups.add(groupId);
    },

    async onSubmit(): Promise<void> {
      if (!spaceId) {
        return;
      }

      presenter.isSubmitting = true;

      const success = await Tasks.space.deleteSpace(spaceId, Array.from(presenter.selectedGroups));

      if (success) {
        if (source.location.pathname.includes(`/${RouterPage.Spaces}/${spaceId}`)) {
          source.navigateTo({ to: RouterLink.main });
        }

        presenter.onClose();
      } else {
        Entities.toast.show(t('something_went_wrong'));
      }

      presenter.isSubmitting = false;
    },

    onClose(): void {
      popupInstance.close();
      onClose?.();
    },

    onBack(): void {
      popupInstance.close();
      onBack?.();
    },
  }));

  useEffect(() => {
    if (!Entities.spacesStore.isLoading && !presenter.space?.isOwner) {
      presenter.onClose();
    }
  }, [presenter.space, Entities.spacesStore.isLoading]);

  useEffect(() => {
    if (!Entities.spacesStore.isLoading && !Entities.GroupsStore.isLoading) {
      presenter.init();
    }
  }, [Entities.spacesStore.isLoading, Entities.GroupsStore.isLoading]);

  return presenter;
}
