import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import Entities from 'APP/store';
import { Group } from 'STORE/Groups/Group';
import { Space } from 'STORE/Spaces/Space';
import { debounce } from 'UTILS/debounce';
import { getSortedGroups } from 'UTILS/getSortedGroups';

import { ICommonPopupsProps } from '../../PopupManager.types';

export interface IChangeSpaceVisibilityPopupPresenterParams {
  spaceId: string;
}

interface IChangeSpaceVisibilityPopupPresenter {
  isOpened: boolean;
  space: Space | null;
  groups: Group[];
  isAllVisible: boolean;
  hasBackButton: boolean;
  onAllToggle(): void;
  changeAllVisibility(spaceId: string, isVisible: boolean): void;
  onClose(): void;
  onBack(): void;
}

export const useChangeSpaceVisibilityPopupPresenter = ({
  params,
  popupInstance,
  onClose,
  onBack,
}: ICommonPopupsProps<IChangeSpaceVisibilityPopupPresenterParams>): IChangeSpaceVisibilityPopupPresenter => {
  const { spaceId } = params!;

  const presenter = useLocalStore<IChangeSpaceVisibilityPopupPresenter>(() => ({
    get isOpened(): boolean {
      return !!presenter.space;
    },

    get space(): Space | null {
      return Entities.spacesStore.getById(spaceId);
    },

    get groups(): Group[] {
      if (!presenter.space) {
        return [];
      }

      const spaceId = presenter.space.id;
      return getSortedGroups(
        Entities.GroupsStore.groups.filter((group) => group.spaceId === spaceId)
      );
    },

    get isAllVisible(): boolean {
      return presenter.space ? presenter.space.groupVisibility.allGroupsVisible : false;
    },

    onAllToggle(): void {
      const newValue = !presenter.isAllVisible;
      presenter.space!.groupVisibility.setAllGroupsVisible(newValue);
      presenter.changeAllVisibility(presenter.space!.id, newValue);
    },

    changeAllVisibility: debounce((spaceId: string, isVisible: boolean): void => {
      Tasks.space.changeAllGroupsVisibility({ spaceId, isVisible });
    }, 1000),

    get hasBackButton(): boolean {
      return !!onBack;
    },

    onClose(): void {
      popupInstance.close();
      onClose && onClose();
    },

    onBack(): void {
      popupInstance.close();
      onBack && onBack();
    },
  }));

  useEffect(() => {
    if (!Entities.spacesStore.isLoading && !presenter.space) {
      presenter.onClose();
    }
  }, [presenter.space, Entities.spacesStore.isLoading]);

  return presenter;
};
