import Tasks from 'APP/Tasks';
import { useTranslation } from 'APP/packages/translations';
import Entities from 'STORE';
import { Popup } from 'STORE/App/Popups/Popup/Popup';

import { INewRecordingFormValues } from './NewRecording/NewRecording.presenter';

interface IStartRecordingPopupPresenter {
  onSuccess(data: INewRecordingFormValues): Promise<void>;
}

export function useStartRecordingPopupPresenter(
  groupId: string,
  popupInstance: Popup
): IStartRecordingPopupPresenter {
  const { t } = useTranslation();

  const onSuccess = async (data: INewRecordingFormValues): Promise<void> => {
    const group = Entities.GroupsStore.getGroupById(groupId);

    if (!group?.canSendMessages) {
      Entities.toast.show(t('moderation_disable_send_message'));
      return;
    }

    await Tasks.calls.startRecording(data);
    popupInstance.close();
  };

  return {
    onSuccess,
  };
}
