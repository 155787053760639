import classNames from 'classnames';
import React, { FC } from 'react';

import IcVerified from 'ICONS/ic-verification-16.svg';
import { IconView, IconViewSizes } from 'UIKIT/IconView/IconView';

import styles from './VerifiedBadge.styles.m.css';

interface IVerifiedBadgeProps {
  className?: string;
}

export const VerifiedBadge: FC<IVerifiedBadgeProps> = (props) => {
  return (
    <IconView
      className={classNames(styles.verified, props.className)}
      size={IconViewSizes.Small}
      Icon={IcVerified}
    />
  );
};
