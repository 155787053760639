import { spaceModel } from 'APP/model/model';

export const SpaceMarketLinkError = {
  IsEmpty: 'isEmpty',
  Invalid: 'invalid',
};

export function spaceMarketLinkValidate({ value, errorToText }) {
  if (!value || value.length === 0) {
    return errorToText[SpaceMarketLinkError.IsEmpty];
  }

  return null;
}

export const spaceMarketLinkAsyncValidate = async ({ value, errorToText }) => {
  let isValid;

  try {
    isValid = await spaceModel.validateMarketUrl({
      url: value,
    });
  } catch {
    isValid = false;
  }

  return isValid ? null : errorToText[SpaceMarketLinkError.Invalid];
};
