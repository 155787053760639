import { observer } from 'mobx-react';
import React, { FC, MutableRefObject } from 'react';
import { InView } from 'react-intersection-observer';

import { CatalogWidgetType } from 'APP/model/catalog/catalogModel.types';
import { CatalogSearchPlaceholder } from 'APP/packages/placeholders/CatalogPlaceholder/CatalogSearchPlaceholder/CatalogSearchPlaceholder';
import { useTranslation } from 'APP/packages/translations';
import { InfoBlock, InfoBlockTypes } from 'UIKIT/InfoBlock/InfoBlock';

import { TCatalogResetHandler } from '../Catalog.types';
import { CatalogLoader } from '../components/CatalogLoader/CatalogLoader';
import { CatalogWidget } from '../components/CatalogWidget/CatalogWidget';
import { useCatalogSearchContentPresenter } from './CatalogSearchContent.presenter';

interface ICatalogSearchContentProps {
  scrollRef: MutableRefObject<HTMLDivElement | null>;
  onCatalogReset: TCatalogResetHandler;
}

export const CatalogSearchContent: FC<ICatalogSearchContentProps> = observer((props) => {
  const { scrollRef, onCatalogReset } = props;
  const presenter = useCatalogSearchContentPresenter(onCatalogReset);
  const { t } = useTranslation();

  return (
    <>
      {presenter.isLoadingView && <CatalogSearchPlaceholder />}

      {presenter.isEmptyView && (
        <InfoBlock
          title={t('no_results_found')}
          descriptions={[t('catalog_search_no_results')]}
          type={InfoBlockTypes.Page}
        />
      )}

      {presenter.isContentView && (
        <CatalogWidget
          type={CatalogWidgetType.Type3}
          showItemType={true}
          items={presenter.items}
        />
      )}

      {presenter.isLoadingMoreView && <CatalogLoader />}

      <InView
        key={presenter.viewObserverKey}
        root={scrollRef.current}
        rootMargin="0px 0px 400px 0px"
        onChange={presenter.onViewObserver}
      />
    </>
  );
});
