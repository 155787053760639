import React from 'react';

import styles from './MediaGalleryFooter.styles.m.css';
import { MediaGalleryPreviewItem } from './MediaGalleryPreviewItem/MediaGalleryPreviewItem';

export const MediaGalleryFooter = ({ file, albumPreviewFiles }) => {
  return (
    <div className={styles.container}>
      <div className={styles.previewBlockWrapper}>
        <div className={styles.previewBlock}>
          {albumPreviewFiles.leftFiles.map((previewFile) => (
            <MediaGalleryPreviewItem
              file={previewFile}
              key={previewFile.id}
            />
          ))}
        </div>
      </div>

      <MediaGalleryPreviewItem
        selected
        file={file}
      />

      <div className={styles.previewBlockWrapper}>
        <div className={styles.previewBlock}>
          {albumPreviewFiles.rightFiles.map((previewFile) => (
            <MediaGalleryPreviewItem
              file={previewFile}
              key={previewFile.id}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
