import React from 'react';

import { MediaGalleryImage } from './MediaGalleryImage/MediaGalleryImage';
import { MediaGalleryVideo } from './MediaGalleryVideo/MediaGalleryVideo';

const FILE_COMPONENTS = {
  Image: MediaGalleryImage,
  Video: MediaGalleryVideo,
};

export const MediaGalleryFile = ({ id, messageId, groupId, payload }) => {
  const FileComponent = FILE_COMPONENTS[payload.payloadType];

  if (!FileComponent) {
    return null;
  }

  return (
    <FileComponent
      id={id}
      messageId={messageId}
      groupId={groupId}
      payload={payload}
    />
  );
};
