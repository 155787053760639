interface ILanguages {
  [key: string]: ILanguages;
}

// ToDO: add normal TS types and rewrite class;

interface II18n {
  lang: ILanguages;
  langs: ILanguages;
  currentLanguage: string;
}

class LangService {
  i18n = {
    lang: null,
    langs: null,
    currentLanguage: null,
  } as unknown as II18n;

  setLangs(langs: ILanguages, currentLanguage: string): void {
    this.i18n.langs = langs;
    this.i18n.lang = langs.strings || {};
    this.i18n.currentLanguage = currentLanguage;
  }
}

export const langService = new LangService();

export const t = (key: string): string => {
  const keys = key.split(':');
  let result;

  if (keys.length > 1) {
    result = keys.reduce((acc, key) => acc[key], langService.i18n.langs);
  } else {
    result = langService.i18n.lang[key];
  }

  // TODO: fix typings in this file
  return result ? (result as unknown as string) : key;
};
