const config = {
  language: 'en-US',
};

export const configure = ({ language }) => {
  if (language) {
    config.language = language;
  }
};

export default config;
