import { DEFAULT_ENCODER_CONFIG } from 'APP/constants/calls';
import { platformInfo } from 'APP/packages/platformInfo/platformInfo';

async function getPermission({
  hasAudio,
  hasVideo,
}: {
  hasAudio?: boolean;
  hasVideo?: boolean;
}): Promise<boolean> {
  let stream;

  try {
    stream = await navigator.mediaDevices.getUserMedia({
      audio: hasAudio,
      video: hasVideo && DEFAULT_ENCODER_CONFIG,
    });
    return true;
  } catch (error) {
    console.error(error);
    return false;
  } finally {
    if (stream) {
      stream.getTracks().forEach((t) => {
        t.stop();
      });
    }
  }
}

export async function getAudioPermission(): Promise<boolean> {
  if (platformInfo.isElectron) {
    return await window.electronAPI.getMediaPermission({
      hasAudio: true,
      hasVideo: false,
    });
  }

  return await getPermission({
    hasAudio: true,
    hasVideo: false,
  });
}

export async function getVideoPermission(): Promise<boolean> {
  if (platformInfo.isElectron) {
    return await window.electronAPI.getMediaPermission({
      hasAudio: false,
      hasVideo: true,
    });
  }

  return await getPermission({
    hasAudio: false,
    hasVideo: true,
  });
}

export async function getMediaPermission(): Promise<boolean> {
  if (platformInfo.isElectron) {
    return await window.electronAPI.getMediaPermission({
      hasAudio: true,
      hasVideo: true,
    });
  }

  return await getPermission({
    hasAudio: true,
    hasVideo: true,
  });
}
