import React, { FC } from 'react';

import { useTranslation } from 'APP/packages/translations';

import styles from './BlockedByMeUserPanel.styles.m.css';

export const BlockedMeUserPanel: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <span className={styles.description}>{t('blocked_user_alert_common')}</span>
    </div>
  );
};
