import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { useTranslation } from 'APP/packages/translations';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';
import { PopupHeader } from 'UIKIT/PopupHeader/PopupHeader';

import { PrivacyItem } from './PrivacyItem/PrivacyItem';
import { PrivacyPage } from './PrivacyPage/PrivacyPage';
import { useUserPrivacyViewPresenter } from './UserPrivacyView.presenter';
import styles from './UserPrivacyView.styles.m.scss';

interface IUserPrivacyViewProps {
  onBack: (() => void) | null;
  onClose(): void;
}

export const UserPrivacyView: FC<IUserPrivacyViewProps> = observer((props) => {
  const { onBack, onClose } = props;
  const { t } = useTranslation();
  const presenter = useUserPrivacyViewPresenter();

  const activePage = presenter.activePage;

  if (activePage) {
    const activePageParams = presenter.pageParams[activePage];

    return (
      <div className={styles.container}>
        <PrivacyPage
          value={presenter.settings[activePage]}
          title={activePageParams.title}
          subtitle={activePageParams.subtitle}
          labels={activePageParams.labels}
          setValue={activePageParams.setValue}
          goBack={presenter.openPrivacy}
          onClose={onClose}
        />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <PopupHeader
        title={t('privacy_title')}
        handlePrevStep={onBack ? onBack : null}
        onClose={onClose}
      />
      <div className={classNames(styles.content, customScrollStyles.container)}>
        <div className={styles.title}>{t('privacy_title')}</div>
        <div className={styles.list}>
          {presenter.list.map((item) => {
            return (
              <PrivacyItem
                key={item.key}
                title={item.title}
                description={item.description}
                onClick={item.onClick}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
});
