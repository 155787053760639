import { IBaseStory } from './Stories.types';

export function getStorySize(
  maxWidth: number,
  maxHeight: number,
  initialSize: IBaseStory['size'],
  stretch?: boolean
): IBaseStory['size'] | null {
  if (!initialSize) {
    return null;
  }

  let height = initialSize.height;
  let width = initialSize.width;
  const scale = height / width;

  if (
    (height < maxHeight && width < maxWidth && stretch !== false) ||
    (height > maxHeight && width > maxWidth)
  ) {
    height = maxHeight;
    width = height / scale;
  }

  if (height > maxHeight) {
    height = maxHeight;
    width = height / scale;
  }

  if (width > maxWidth) {
    width = maxWidth;
    height = width * scale;
  }

  return { height, width };
}
