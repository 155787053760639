import { TFunction } from 'react-i18next';

import { GroupType } from 'APP/model/group/groupModel.types';

import {
  TSystemMessageTextHandler,
  ISystemMessageText,
  ISystemMessageTextPresenter,
} from '../useSystemMessageText.types';

const PublicGroupTypes = [GroupType.Open, GroupType.ChannelOpen];

export const chatTypeChangedHandler: TSystemMessageTextHandler = (
  presenter: ISystemMessageTextPresenter,
  t: TFunction
): ISystemMessageText => {
  const result: ISystemMessageText = { html: '' };

  const newTypeText =
    presenter.newType && PublicGroupTypes.includes(presenter.newType)
      ? t('create_public_chat_type_public')
      : t('create_public_chat_type_closed');

  if (presenter.isPublicGroup || presenter.isChannel) {
    result.html = t('notification_chat_type_changed_without_name', {
      0: `<span class="system-message-title">${newTypeText}</span>`,
      interpolation: { escapeValue: false },
    });
  } else {
    result.html = t('notification_chat_type_changed', {
      0: `<span class="system-message-title">${presenter.initiatorDisplayName}</span>`,
      1: `<span class="system-message-title">${newTypeText}</span>`,
      interpolation: { escapeValue: false },
    });
    result.sourceText = t('notification_chat_type_changed');
    result.params = {
      '{0}': { text: presenter.initiatorDisplayName, userId: presenter.initiator?.id },
      '{1}': { text: newTypeText },
    };
  }

  return result;
};
