import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, SyntheticEvent, useCallback } from 'react';

import Tasks from 'APP/Tasks';
import { useFileDownloadStatePresenter } from 'APP/hooks/useFileDownloadState/useFileDownloadState';
import { useFileUploadStatePresenter } from 'APP/hooks/useFileUploadState/useFileUploadState';
import DownloadIcon from 'ICONS/ic-download.svg';
import LoadIcon from 'ICONS/ic-load-24.svg';
import { InfiniteUploadIcon } from 'MAIN/components/InfiniteUploadIcon/InfiniteUploadIcon';
import { ProgressLoadIcon } from 'MAIN/components/ProgressLoadIcon/ProgressLoadIcon';
import type { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';
import { IconButton, IconViewSizes } from 'UIKIT/IconButton';
import { getMessagePayload } from 'UTILS/message/message';

import styles from './MediaGalleryDownloadState.styles.m.css';

interface IMediaGalleryDownloadStateProps {
  className?: string;
  payloadId: string;
  message: ChatMessage;
}

export const MediaGalleryDownload: FC<IMediaGalleryDownloadStateProps> = observer((props) => {
  const { className, payloadId, message } = props;

  const payload = getMessagePayload(message, payloadId);

  const fileUploadPresenter = useFileUploadStatePresenter({
    uploadProgress: payload.uploadProgress,
    cancelUpload: payload.cancelUploadAndDeleteMessage,
    hasError: message.hasError,
  });

  const fileDownloadPresenter = useFileDownloadStatePresenter({
    payloadId,
    groupId: message.groupId,
    messageId: message.id,
  });

  const onCancel = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      if (fileUploadPresenter.uploadProgress !== null) {
        fileUploadPresenter.cancelUpload();
      } else {
        fileDownloadPresenter.onAbort();
      }
    },
    [fileUploadPresenter.uploadProgress]
  );

  if (fileUploadPresenter.isUploadProcess || fileDownloadPresenter.isDownloadInProcess) {
    return (
      <button className={classNames(styles.icon, className)}>
        <ProgressLoadIcon
          Icon={LoadIcon}
          progress={
            fileUploadPresenter.uploadProgress || fileDownloadPresenter.downloadPercent || 0
          }
          onClick={onCancel}
        />
      </button>
    );
  }

  if (
    fileUploadPresenter.isWaitingUploadProcess ||
    fileDownloadPresenter.isWaitingDownloadProcess
  ) {
    return (
      <button className={classNames(styles.icon, className)}>
        <InfiniteUploadIcon
          Icon={LoadIcon}
          onClick={onCancel}
        />
      </button>
    );
  }

  return (
    <IconButton
      className={className}
      Icon={DownloadIcon}
      size={IconViewSizes.Big}
      iconSize={IconViewSizes.Medium}
      theme="11"
      onClick={() => {
        Tasks.downloadMessageFile({
          groupId: message.groupId,
          messageId: message.id,
          payloadId,
        });
      }}
    />
  );
});
