export enum AuthServerErrorCode {
  PhoneNumberIsOccupiedByAnotherUser = 'E_PHONE_IS_OCCUPIED_BY_ANOTHER_USER',
  PhoneNumberIsOccupiedByMe = 'E_PHONE_IS_OCCUPIED_BY_ME',
  PhoneNumberInvalid = 'E_PHONE_INVALID',
  PhoneNumberHasHighRiskScore = 'E_PHONE_HAS_HIGH_RISK_SCORE',
  InvalidVerificationCode = 'E_INVALID_VERIFICATION_CODE',
  AttemptsExceeded = 'E_ATTEMPTS_EXCEEDED',
  VerificationCodeExpired = 'E_VERIFICATION_CODE_EXPIRED',
  PhoneHasRecentlyChanged = 'E_PHONE_WAS_RECENTLY_CHANGED',
  InvalidDelay = 'E_INVALID_DELAY',
  TooManyRequests = 'E_TOO_MANY_REQUESTS',
  CaptchaIsInvalid = 'E_CAPTCHA_IS_INVALID',
}
