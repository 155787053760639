import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { ApplicationTheme } from 'APP/constants/appearance';
import { useContextMenu } from 'MAIN/hooks/useContextMenu/useContextMenu';
import { Opponent } from 'STORE/Calls/Call/Opponent/Opponent';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';
import { PopoverMenu } from 'UIKIT/PopoverMenu/PopoverMenu';

import { NoVideoPlaceholder } from './NoVideoPlaceholder/NoVideoPlaceholder';
import { OpponentMeta } from './OpponentMeta/OpponentMeta';
import { useOpponentVideoPresenter } from './OpponentVideo.presenter';
import styles from './OpponentVideo.styles.m.css';
import { OpponentVideoView } from './OpponentVideoView/OpponentVideoView';

interface IOpponentVideoProps {
  opponent: Opponent;
  avatarType: AvatarTypes;
  containerClassName?: string;
  className?: string;
  isMinimize?: boolean;
  isHighlightAudio?: boolean;
  opponentPosition?: number;
  onClick?(): void;
}

export const OpponentVideo: FC<IOpponentVideoProps> = observer(
  ({
    opponent,
    avatarType,
    containerClassName,
    className,
    isMinimize,
    onClick,
    isHighlightAudio,
    opponentPosition,
  }) => {
    const { popover, onContextMenu, modifiers } = useContextMenu<HTMLDivElement>();
    const presenter = useOpponentVideoPresenter({ opponent, popover, onClick });

    const positionStyles = typeof opponentPosition === 'number' ? { order: opponentPosition } : {};

    return (
      <>
        <div
          className={classNames(styles.container, containerClassName, {
            [styles.withAudio]: opponent.hasAudio && isHighlightAudio,
            [styles.withRiseHand]:
              !opponent.hasAudio && opponent.member?.isRaiseHand && isHighlightAudio,
          })}
          style={positionStyles}
          onContextMenu={presenter.actions.length ? onContextMenu : undefined}
          role="button"
          tabIndex={0}
          onClick={presenter.onClick}
          ref={popover.containerRef}
        >
          {opponent.hasVideo ? (
            <OpponentVideoView
              opponent={opponent}
              className={className}
            />
          ) : (
            <NoVideoPlaceholder
              opponent={opponent}
              type={avatarType}
            />
          )}
          <OpponentMeta
            isMinimize={isMinimize}
            opponent={opponent}
          />
        </div>
        <PopoverMenu
          isOpened={popover.isOpened}
          menuItems={presenter.actions}
          onClose={popover.close}
          reference={popover.containerRef.current}
          placement="bottom-start"
          modifiers={modifiers}
          theme={ApplicationTheme.Dark}
        />
      </>
    );
  }
);
