import Tasks from 'APP/Tasks';
import { PayloadType } from 'APP/model/message/messageModel.types';
import Entities from 'APP/store';

const convertPayload = (data) => {
  const { payload, ...rest } = data;
  return {
    ...rest,
    data: payload,
    type: PayloadType.Article,
  };
};

export default ({ data, navigateTo }) => {
  const group = Entities.GroupsStore.getGroupById(data.groupId);
  if (!group) {
    return;
  }

  Entities.InputPanel.payloads.setPayloads([convertPayload(data)]);
  Tasks.group.goToScheduleSettings({ navigateTo, groupId: data.groupId });
};
