import { messageModel } from 'APP/model/model';
import Entities from 'APP/store';

export async function readAllGroupsMessages(groupIds: string[]): Promise<boolean> {
  const groupsMessagesData: {
    groupId: string;
    lastMessageId: string;
    lastMessageTs: number;
    counter: number;
  }[] = [];

  groupIds.forEach((groupId) => {
    const group = Entities.GroupsStore.getGroupById(groupId);

    if (group?.counter?.lastMessage) {
      groupsMessagesData.push({
        groupId,
        lastMessageId: group.counter.lastMessage.id,
        lastMessageTs: group.counter.lastMessageTs,
        counter: group.counter.counter,
      });

      // Optimistic UI
      group.counter.counter = 0;
    }
  });

  try {
    if (groupsMessagesData.length) {
      await messageModel.confirmReadGroupsMessages(
        groupsMessagesData.map(({ groupId, lastMessageId }) => ({
          groupId,
          messageId: lastMessageId,
        }))
      );

      groupsMessagesData.forEach(({ groupId }) =>
        Entities.pushNotifications.clearNotificationsByGroupId(groupId)
      );
    }

    return true;
  } catch {
    // Optimistic UI fallback
    groupsMessagesData.forEach((item) => {
      const counter = Entities.GroupsStore.getGroupById(item.groupId)?.counter;

      if (counter && counter.lastMessageTs === item.lastMessageTs) {
        counter.counter = item.counter;
      }
    });

    return false;
  }
}
