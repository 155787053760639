import { useMemo } from 'react';

import { openPopup } from 'APP/Tasks/app/popup/popup';
import { RouterLink, RouterState } from 'APP/router/constants';
import useGetState from 'APP/router/hooks/useGetState';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import { SpaceProfilePopup } from 'MAIN/PopupManager/Popups/SpaceProfilePopup/SpaceProfilePopup';
import { Space } from 'STORE/Spaces/Space';

interface ISpacePanelHeaderPresenter {
  backUrl: string | null;
  canOpenProfile: boolean;
  verified: boolean;
  onOpenProfile(): void;
  onClose(): void;
}

export function useSpacePanelHeaderPresenter(space: Space | null): ISpacePanelHeaderPresenter {
  const navigateTo = useNavigateTo();
  const backUrl = useGetState<string>(RouterState.goBackUrl) || null;

  const presenter: ISpacePanelHeaderPresenter = {
    // The back url must be static and equal to value at initial state
    backUrl: useMemo<string | null>(() => backUrl, []),

    get canOpenProfile(): boolean {
      return !space?.isGuest;
    },

    onOpenProfile(): void {
      if (space) {
        openPopup(SpaceProfilePopup, {
          params: { spaceId: space.id },
        });
      }
    },

    get verified(): boolean {
      return !!space?.verified;
    },

    onClose(): void {
      navigateTo({ to: presenter.backUrl || RouterLink.main });
    },
  };

  return presenter;
}
