import { observer } from 'mobx-react';
import React from 'react';

import CommentsIcon from 'ICONS/ic-comments.svg';
import { IconButton } from 'UIKIT/IconButton';

import usePresenter from './presenter';

const ThreadButton = ({ className, onClick }) => {
  const presenter = usePresenter({ onClick });

  if (!presenter.message) {
    return null;
  }

  return (
    <IconButton
      Icon={CommentsIcon}
      onClick={presenter.goToThread}
      className={className}
      size="large"
      iconSize="medium"
      theme="12"
      text={presenter.commentsCount}
    />
  );
};

export default observer(ThreadButton);
