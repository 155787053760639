import { storage } from 'APP/packages/storage';
import { RouterParams } from 'APP/router/constants';
import useClearGetParameters from 'APP/router/hooks/useClearGetParameters';
import useGetParameter from 'APP/router/hooks/useGetParameter';

export function usePresenter() {
  const connectionToken = useGetParameter(RouterParams.reconnectKey);
  const clearConnectionToken = useClearGetParameters([RouterParams.reconnectKey]);

  if (window.location.hostname.includes('gem4me.com')) {
    const connectionToken = storage.connectionToken.get();
    let href = window.location.href.replace('gem4me.com', 'gemspace.com');
    if (connectionToken) {
      href = `${href}${href.includes('?') ? '&' : '?'}${
        RouterParams.reconnectKey
      }=${connectionToken}`;
    }
    window.location.replace(href);
  } else {
    if (connectionToken) {
      if (!storage.connectionToken.get()) {
        storage.connectionToken.set(connectionToken);
      }
      clearConnectionToken();
    }
  }
}
