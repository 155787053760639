import bus from 'APP/packages/bus';

import { GroupsEvent } from '../GroupsEvent';

export class ConfigGroupAds extends GroupsEvent {
  process() {
    const { groupId, adsEnabled } = this.eventPayload;
    if (groupId) {
      bus.group.broadcastAdsConfigChanges(groupId, { adsEnabled });
    }
  }
}
