import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { GroupAvatar } from 'APP/components/Avatar/GroupAvatar/GroupAvatar';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';

import usePresenter from './presenter';
import styles from './styles.m.css';

const NoVideo = ({ className }) => {
  const presenter = usePresenter();
  if (!presenter.group) {
    return null;
  }
  return (
    <div className={classNames(styles.container, className)}>
      <GroupAvatar
        group={presenter.group}
        type={AvatarTypes.Square_40}
      />
      <div className={styles.title}>{presenter.title}</div>
    </div>
  );
};

export default observer(NoVideo);
