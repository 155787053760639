import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { ProviderUid } from 'APP/packages/callProviders/callProviders.types';
import { OpponentVideo } from 'MAIN/OngoingCall/Shared/OpponentVideo/OpponentVideo';
import { useOpponentVideoItem } from 'MAIN/OngoingCall/hooks/useOpponentVideoItem/useOpponentVideoItem';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';

import styles from './OpponentVideoItem.styles.m.css';

interface IOpponentVideoItemProps {
  opponentUid: ProviderUid;
}

export const OpponentVideoItem: FC<IOpponentVideoItemProps> = observer(({ opponentUid }) => {
  const { opponent, opponentPosition, onOpponentClick } = useOpponentVideoItem(opponentUid);

  if (!opponent) {
    return null;
  }

  return (
    <OpponentVideo
      isHighlightAudio
      opponent={opponent}
      avatarType={AvatarTypes.Round_10}
      containerClassName={styles.videoContainer}
      isMinimize
      opponentPosition={opponentPosition}
      onClick={onOpponentClick}
    />
  );
});
