import Tasks from 'APP/Tasks';
import { ALERT_TYPES } from 'APP/constants/app';
import { STREAM_ACTION } from 'APP/constants/stream';
import { ServerErrorCode } from 'APP/model/error/error.constants';
import api from 'APP/packages/api';
import logger from 'APP/packages/logger';

export default async ({ channelId, userId }) => {
  try {
    logger.get('Tasks').debug('streaming.inviteAsStreamer', { channelId, userId });

    const { result } = await api.streams.actionOnStream({
      channelId,
      userId,
      action: STREAM_ACTION.INVITE_AS_STREAMER,
    });

    return result;
  } catch (e) {
    logger.get('Tasks').error('streaming.inviteAsStreamer', e);

    if (e?.message === ServerErrorCode.OnlyWebPlatformSupportedManyBroadcasters) {
      Tasks.app.addAlert({
        type: ALERT_TYPES.ONLY_WEB_PLATFORM_SUPPORTED_MANY_BROADCASTERS,
      });

      return false;
    }

    Tasks.app.addAlert({
      type: ALERT_TYPES.CALL_ERROR_APPEARED,
    });

    return false;
  }
};
