import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Group } from 'STORE/Groups/Group';

import { GroupAvailableItem } from '../../../components/GroupAvailableItem/GroupAvailableItem';
import { useSpaceGroupAvailableItemPresenter } from './SpaceGroupAvailableItem.presenter';

interface ISpaceGroupAvailableItemProps {
  group: Group;
}

export const SpaceGroupAvailableItem: FC<ISpaceGroupAvailableItemProps> = observer(({ group }) => {
  const presenter = useSpaceGroupAvailableItemPresenter(group);

  return (
    <GroupAvailableItem
      group={group}
      menuItems={presenter.menuItems}
      onJoin={presenter.onJoin}
      onClick={presenter.onClick}
    />
  );
});
