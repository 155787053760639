import Tasks from 'APP/Tasks';
import { openPopup } from 'APP/Tasks/app/popup/popup';
import { ScheduledCallViewPopup } from 'MAIN/PopupManager/Popups/ScheduledCalls/ScheduledCallViewPopup/ScheduledCallViewPopup';
import Entities from 'STORE';
import { IPushNotification } from 'STORE/PushNotifications/PushNotifications.types';

export const onScheduledEventClick = async (
  pushNotification: IPushNotification,
  notification: Notification
): Promise<void> => {
  if (notification && pushNotification.group && pushNotification.scheduledEventData) {
    Tasks.app.fullscreen.closeFullscreen();

    openPopup(ScheduledCallViewPopup, {
      params: {
        eventId: pushNotification.scheduledEventData.eventId,
        initialData: {
          id: pushNotification.scheduledEventData.eventId,
          initiatorId: pushNotification.user,
          groupId: pushNotification.scheduledEventData.groupId,
          endTs: Number(pushNotification.scheduledEventData.endTs),
          startTs: Number(pushNotification.scheduledEventData.startTs),
          name: pushNotification.scheduledEventData.name,
          groupName: pushNotification.title || '',
          groupAvatar: pushNotification.icon,
        },
      },
    });

    Tasks.app.focusApp();

    Entities.pushNotifications.removeNotificationByRef(notification);
  }
};
