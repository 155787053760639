import { FieldState, FormApi } from 'final-form';
import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { MutableRefObject, useEffect, useRef } from 'react';

import GlobalTasks from 'APP/Tasks';
import { GroupType } from 'APP/model/group/groupModel.types';
import { useTranslation } from 'APP/packages/translations';
import validations from 'APP/packages/validations';
import Entities from 'APP/store';
import { ICountry } from 'APP/types/countryCodes';
import { clearPhoneNumber, replacePhoneTemplate } from 'UTILS/phone';
import { parsePhone } from 'UTILS/phone';

import { createContact } from '../Tasks/createContact/createContact';
import { INewContactFormData } from './NewContactView.types';

export interface INewContactPresenterParams {
  navigateToActiveGroup?: boolean;
  onBack(): void;
  onClose(): void;
}

export interface NewContactViewPresenter {
  isLoading: boolean;
  initialValue: INewContactFormData;
  userNameRef: MutableRefObject<HTMLInputElement | undefined>;
  countries: ICountry[];
  currentCountry: ICountry | null;
  placeholder: string;
  keepDirtyOnReinitialize: boolean;
  onSubmit(data: INewContactFormData, form: FormApi, cb: (errors: any) => void): void;
  usernameValidate(): boolean;
  init(): Promise<void>;
  parsePhoneNumber(value: string): string;
  validatePhone(
    value: string,
    values: string[],
    fieldState?: FieldState<string | undefined>
  ): Promise<boolean>;
  changeCountry(number: string): void;
  isPristinePhoneField(form: FormApi): boolean;
}

export const useNewContactViewPresenter = ({
  navigateToActiveGroup = false,
  onBack,
  onClose,
}: INewContactPresenterParams): NewContactViewPresenter => {
  const userNameRef = useRef<HTMLInputElement>();
  const formValidations = validations.newContact();
  const { t } = useTranslation();

  const source = useAsObservableSource({ onBack, onClose });

  const presenter = useLocalStore<NewContactViewPresenter>(() => ({
    isLoading: false,
    usernameValidate: formValidations.userName.validate,
    countries: [],
    currentCountry: Entities.App.accountCountry,
    keepDirtyOnReinitialize: true,

    get initialValue(): INewContactFormData {
      return {
        contactNameSearch: '',
        contactPhone: presenter.currentCountry?.phonePrefix || '+',
      };
    },

    get userNameRef(): MutableRefObject<HTMLInputElement | undefined> {
      return userNameRef;
    },

    get placeholder(): string {
      return replacePhoneTemplate(presenter.currentCountry?.phoneTemplate);
    },

    init: async (): Promise<void> => {
      const { availableCounties } = await GlobalTasks.countriesPhoneCodes.getCountriesPhoneCodes();
      presenter.countries = availableCounties;
    },

    onSubmit: async (values: INewContactFormData, form: FormApi): Promise<void> => {
      if (presenter.isLoading) return;

      presenter.isLoading = true;

      const createdContact = await createContact(values);

      if (!createdContact) {
        presenter.isLoading = false;
        presenter.keepDirtyOnReinitialize = false;

        const confirmResult = await GlobalTasks.app.showConfirm({
          text: t('new_contact_user_not_join', { 0: values.contactNameSearch }),
          primaryText: t('add_another_one_button'),
          secondaryText: t('common_cancel'),
        });

        if (confirmResult) {
          form.reset(presenter.initialValue as INewContactFormData);
          presenter.keepDirtyOnReinitialize = true;

          if (userNameRef.current) {
            userNameRef.current.focus();
          }
        }
        return;
      }

      const isMyPhoneNumber = createdContact.phone === Entities.UsersStore.Me.phone;
      if (navigateToActiveGroup) {
        if (isMyPhoneNumber) {
          await GlobalTasks.group.createGroupAndInvite({
            type: GroupType.MySavedMessages,
          });
        } else {
          await GlobalTasks.group.createP2PGroup(createdContact.id);
        }
        source.onClose();
      } else {
        source.onBack();
      }
      presenter.isLoading = false;
    },

    validatePhone: async (
      value: string,
      values: string[],
      fieldState: FieldState<string>
    ): Promise<boolean> => {
      if (fieldState.dirty) {
        return formValidations.phoneNumber.validate(value, values, fieldState, {
          phoneTemplate: presenter.currentCountry?.phoneTemplate,
        });
      }

      return false;
    },

    isPristinePhoneField(form: FormApi): boolean {
      const phoneFieldState = form.getFieldState('contactPhone');
      return phoneFieldState?.value === presenter.initialValue.contactPhone;
    },

    changeCountry(number: string): void {
      const country = presenter.countries.find(({ phonePrefix }) => {
        return number.indexOf(phonePrefix) === 0;
      });

      presenter.currentCountry = country || null;
    },

    parsePhoneNumber(value: string): string {
      const number = clearPhoneNumber(value);

      presenter.changeCountry(`+${number}`);
      if (presenter.currentCountry?.phoneTemplate) {
        return parsePhone(number, presenter.currentCountry.phoneTemplate);
      }

      return `+${number}`;
    },
  }));

  useEffect(() => {
    presenter.init();

    if (userNameRef.current) {
      userNameRef.current.focus();
    }
  }, []);

  return presenter;
};
