import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { useAllPinsFooterPresenter } from 'MAIN/ChatView/AllPins/AllPinsFooter/AllPinsFooter.presenter';

import styles from './AllPinsFooter.styles.m.css';

interface IAllPinsFooterProps {
  groupId: string;
}

export const AllPinsFooter: FC<IAllPinsFooterProps> = observer(({ groupId }) => {
  const presenter = useAllPinsFooterPresenter(groupId);

  if (presenter.count === 0) {
    return null;
  }

  return (
    <div className={styles.container}>
      <button
        className={styles.unpinAll}
        onClick={presenter.unpin}
      >
        {presenter.text}
      </button>
    </div>
  );
});
