import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Stories } from 'APP/components/Stories/Stories';
import CollectionImage from 'APP/images/gem-mascot-collection.svg';
import { useTranslation } from 'APP/packages/translations';
import { Space } from 'STORE/Spaces/Space';
import { Button } from 'UIKIT/Button/Button';

import { useSpaceCollectionOnboardingPresenter } from './SpaceCollectionOnboarding.presenter';
import styles from './SpaceCollectionOnboarding.styles.m.css';

interface ISpaceCollectionOnboarding {
  className?: string;
  space: Space;
}

export const SpaceCollectionOnboarding: FC<ISpaceCollectionOnboarding> = observer(
  ({ className, space }) => {
    const { t } = useTranslation();
    const presenter = useSpaceCollectionOnboardingPresenter(space);

    if (!presenter.isShow) {
      return null;
    }

    return (
      <div className={classNames(styles.container, className)}>
        <img
          className={styles.image}
          src={CollectionImage}
          alt=""
        />

        <div>
          <h3 className={styles.title}>{t('space_banner_collection')}</h3>

          <div className={styles.description}>{t('space_banner_collection_description')}</div>

          <Button
            theme="1"
            onClick={presenter.onOpenStories}
          >
            {t('space_banner_details')}
          </Button>
        </div>

        {presenter.isStoriesOpened && (
          <Stories
            stories={presenter.stories}
            onClose={presenter.onCloseStories}
            onEnd={presenter.onEndStories}
          />
        )}
      </div>
    );
  }
);
