import classNames from 'classnames';
import React, { FC } from 'react';

import PauseIcon from 'ICONS/ic-pause-32.svg';
import PlayIcon from 'ICONS/ic-play-32.svg';
import { IconView, IconViewSizes } from 'UIKIT/IconView/IconView';
import { Loader } from 'UIKIT/Loader/Loader';

import styles from './RoundVideoPlayerControls.styles.m.css';

interface IRoundVideoPlayerControlsProps {
  className?: string;
  isPlaying: boolean;
  isLoading: boolean;
  onTogglePlay(): void;
}

export const RoundVideoPlayerControls: FC<IRoundVideoPlayerControlsProps> = (props) => {
  const { className, isPlaying, isLoading, onTogglePlay } = props;

  const iconContainerClass = classNames(styles.iconContainer, {
    [styles.hidden]: isPlaying,
  });

  return (
    <div
      className={classNames(styles.controls, className)}
      role="button"
      tabIndex={0}
      onClick={onTogglePlay}
    >
      <div className={iconContainerClass}>
        {isLoading ? (
          <Loader
            size="medium"
            color="primary"
          />
        ) : (
          <IconView
            className={styles.icon}
            size={IconViewSizes.Big}
            Icon={isPlaying ? PauseIcon : PlayIcon}
          />
        )}
      </div>
    </div>
  );
};
