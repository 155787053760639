import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useRef, FC } from 'react';

import { IconView } from 'APP/packages/UiKit/IconView/IconView';
import IcSelectDown from 'ICONS/ic-select-down.svg';

import { useCatalogLocalesPresenter } from './CatalogLocales.presenter';
import styles from './CatalogLocales.styles.m.css';
import { LocalesList } from './LocalesList/LocalesList';

export const CatalogLocales: FC = observer(() => {
  const buttonRef = useRef(null);
  const presenter = useCatalogLocalesPresenter();

  const buttonClass = classNames(styles.button, {
    [styles.active]: presenter.isOpened,
  });

  return (
    <>
      <button
        className={buttonClass}
        ref={buttonRef}
        onClick={presenter.onToggle}
      >
        <span className={styles.title}>
          {presenter.flag && <span className={styles.flag}>{presenter.flag}</span>}
          {presenter.title}
        </span>
        <IconView
          Icon={IcSelectDown}
          className={styles.icon}
        />
      </button>

      {presenter.isOpened && (
        <LocalesList
          setRef={buttonRef}
          selectedCodes={presenter.selectedCodes}
          locales={presenter.locales}
          onSelect={presenter.onSelect}
          onClose={presenter.onToggle}
        />
      )}
    </>
  );
});
