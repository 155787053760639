import { observer } from 'mobx-react';
import React from 'react';

import Entities from 'STORE';

import { CallView } from './CallView/CallView';
import { PermissionsRequestHandler } from './PermissionsRequestHandler/PermissionsRequestHandler';
import PushNotification from './PushNotification';

export const OngoingCall = observer(() => {
  const ongoingCall = Entities.Calls.ongoingCall;

  if (!ongoingCall) {
    return null;
  }

  return (
    <>
      <PermissionsRequestHandler />
      <CallView />
      {ongoingCall.isMeInitiator && <PushNotification />}
    </>
  );
});
