import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { useTranslation } from 'APP/packages/translations';
import CloseIcon from 'ICONS/ic-close-16.svg';
import { useUnknownUserPresenter } from 'MAIN/ChatView/UnknownUser/UnknownUser.presenter';
import { Button } from 'UIKIT/Button/Button';
import { FAB } from 'UIKIT/FAB/FAB';

import styles from './UnknownUser.styles.m.css';
import { UnknownUserBlock } from './UnknownUserBlock/UnknownUserBlock';

interface IUnknownUserProps {
  groupId: string;
}

export const UnknownUser: FC<IUnknownUserProps> = observer((props) => {
  const { groupId } = props;
  const { t } = useTranslation();

  const presenter = useUnknownUserPresenter(groupId);

  if (!presenter.isShowUnknownUserBanner) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.username}>
        {t('common_user_in_not_contact', {
          0: presenter.user?.displayName,
          interpolation: { escapeValue: false },
        })}
      </div>
      <div className={styles.actions}>
        <UnknownUserBlock
          userId={presenter.opponentId!}
          className={styles.action}
          groupId={groupId}
        />
        <Button
          onClick={presenter.onAddToContact}
          className={styles.action}
          theme="6"
        >
          {t('common_add_to_contact')}
        </Button>
        <FAB
          className={styles.close}
          Icon={CloseIcon}
          size="20"
          theme="2"
          onClick={presenter.onClose}
        />
      </div>
    </div>
  );
});
