import { UserProfileUsernameBadge } from 'APP/model/user/userModel.types';
import {
  AckRewardsRequest,
  AckRewardsRequestRewardsEnum,
} from 'APP/packages/api/types/model/ackRewardsRequest';
import {
  AcquireRewardRequest,
  AcquireRewardRequestRewardEnum,
} from 'APP/packages/api/types/model/acquireRewardRequest';
import { SelfPrivacySettingsResponse } from 'APP/packages/api/types/model/selfPrivacySettingsResponse';
import { SelfUserDisplayDataResponse } from 'APP/packages/api/types/model/selfUserDisplayDataResponse';
import {
  SetPrivacySettingsRequest,
  SetPrivacySettingsRequestSendVoiceMessageEnum,
  SetPrivacySettingsRequestSearchMeEnum,
  SetPrivacySettingsRequestShowLastSeenEnum,
  SetPrivacySettingsRequestCallMeEnum,
  SetPrivacySettingsRequestAddToPublicEnum,
  SetPrivacySettingsRequestShowPhoneNumberEnum,
  SetPrivacySettingsRequestShowAvatarEnum,
  SetPrivacySettingsRequestAddToGroupEnum,
} from 'APP/packages/api/types/model/setPrivacySettingsRequest';
import {
  UpdateUserProfileRequest,
  UpdateUserProfileRequestUsernameBadgeEnum,
} from 'APP/packages/api/types/model/updateUserProfileRequest';

import {
  RewardProgressInfoRewardEnum,
  IPrivacySettings,
  PrivacyValue,
  ISelfUserDisplayData,
  IUpdateUserDisplayData,
} from './userSettingsModel.types';

export const mapUpdateUserProfileRequest = (
  data: IUpdateUserDisplayData
): UpdateUserProfileRequest => {
  return {
    ...data,
    usernameBadge: data.usernameBadge
      ? (data.usernameBadge as string as UpdateUserProfileRequestUsernameBadgeEnum)
      : undefined,
  };
};

export const mapSelfUserDisplayDataResponse = (
  data: SelfUserDisplayDataResponse
): ISelfUserDisplayData => {
  return {
    ...data,
    usernameBadge: data.usernameBadge
      ? (data.usernameBadge as string as UserProfileUsernameBadge)
      : undefined,
  };
};

export const mapSetPrivacySettingsRequest = (data: IPrivacySettings): SetPrivacySettingsRequest => {
  return {
    showPhoneNumber: data.showPhoneNumber as string as SetPrivacySettingsRequestShowPhoneNumberEnum,
    showAvatar: data.showAvatar as string as SetPrivacySettingsRequestShowAvatarEnum,
    addToGroup: data.addToGroup as string as SetPrivacySettingsRequestAddToGroupEnum,
    addToPublic: data.addToPublic as string as SetPrivacySettingsRequestAddToPublicEnum,
    callMe: data.callMe as string as SetPrivacySettingsRequestCallMeEnum,
    showLastSeen: data.showLastSeen as string as SetPrivacySettingsRequestShowLastSeenEnum,
    searchMe: data.searchMe as string as SetPrivacySettingsRequestSearchMeEnum,
    sendVoiceMessage:
      data.sendVoiceMessage as string as SetPrivacySettingsRequestSendVoiceMessageEnum,
  };
};

export const mapPrivacySettingsResponse = (data: SelfPrivacySettingsResponse): IPrivacySettings => {
  return {
    showPhoneNumber: data.showPhoneNumber as string as PrivacyValue,
    showAvatar: data.showAvatar as string as PrivacyValue,
    addToGroup: data.addToGroup as string as PrivacyValue,
    addToPublic: data.addToPublic as string as PrivacyValue,
    callMe: data.callMe as string as PrivacyValue,
    showLastSeen: data.showLastSeen as string as PrivacyValue,
    searchMe: data.searchMe as string as PrivacyValue,
    sendVoiceMessage: data.sendVoiceMessage as string as PrivacyValue,
    updateTs: Number(data.updateTs),
  };
};

export const mapAckRewardsRequest = (
  rewards: RewardProgressInfoRewardEnum[]
): AckRewardsRequest => {
  return {
    rewards: rewards.map((rewardItem) => rewardItem as string as AckRewardsRequestRewardsEnum),
  };
};

export const mapAcquireRewardRequest = (
  reward: RewardProgressInfoRewardEnum
): AcquireRewardRequest => {
  return {
    reward: reward as string as AcquireRewardRequestRewardEnum,
  };
};
