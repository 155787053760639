import Entities from 'APP/store';

export default async ({ groupId, timestamp }) => {
  if (timestamp < 0) {
    return;
  }

  const group = Entities.GroupsStore.getGroupById(groupId);

  if (!group) {
    return;
  }
  group.handleClearHistory();

  const messagesForDeleting = group.messagesStore.messages.filter(
    (message) => message.serverTime <= timestamp
  );

  group.messagesStore.handleRemoveMessages(messagesForDeleting);
};
