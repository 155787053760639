import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { DIRECTION } from 'APP/constants';
import { SharedDataType } from 'APP/constants/sharedData';
import { ISharedDataItem } from 'APP/model/sharedData/sharedDataModel.types';
import dateService from 'APP/packages/date';
import Entities from 'APP/store';
import { TExtendedSharedDataType } from 'MAIN/SharedData/SharedData.types';
import { Group } from 'STORE/Groups/Group';
import type { Payload } from 'STORE/Messages/Message/Payload';

interface ISharedDataPresenterParams {
  groupId: string;
  type: SharedDataType;
}

interface ISharedDataPresenter<T> {
  groupId: string;
  group: Group | null;
  sharedData: TExtendedSharedDataType | null;
  items: TExtendedSharedDataType['items'];
  groupFilesByDate: Record<string, ISharedDataItem<T>[]>;
  isLoading: boolean;
  hasMore: boolean;
  dateList: string[];
  loadMore(): void;
}

export const useSharedDataPresenter = <T extends Payload>(
  data: ISharedDataPresenterParams
): ISharedDataPresenter<T> => {
  const { groupId, type } = data;

  const presenter = useLocalStore<ISharedDataPresenter<T>>(() => ({
    groupId,

    get group(): Group | null {
      return Entities.GroupsStore.getGroupById(presenter.groupId);
    },

    get sharedData(): TExtendedSharedDataType | null {
      return presenter.group?.sharedData?.[type] || null;
    },

    get hasMore(): boolean {
      return Boolean(presenter.sharedData?.hasMore);
    },

    loadMore(): void {
      if (presenter.sharedData?.hasMore) {
        Tasks.messaging.getMessagesByMediaTypes({
          groupId: presenter.groupId,
          cursorTs: presenter.sharedData.cursorTs,
          type,
          direction: DIRECTION.OLDEST,
        });
      }
    },

    get isLoading(): boolean {
      return Entities.GroupsStore.isLoading || Boolean(presenter.sharedData?.isLoading);
    },

    get items(): TExtendedSharedDataType['items'] {
      return presenter.sharedData?.items || [];
    },

    get groupFilesByDate(): Record<string, ISharedDataItem<T>[]> {
      const times: Record<string, ISharedDataItem<T>[]> = {};

      presenter.items.forEach((item: ISharedDataItem<T>) => {
        const date = dateService.format(parseInt(item.serverTime, 10), {
          month: 'long',
          year: 'numeric',
        });

        if (date) {
          times[date] = times[date] || [];
          times[date].push(item);
        }
      });

      return times;
    },

    get dateList(): string[] {
      return Object.keys(presenter.groupFilesByDate);
    },
  }));

  useEffect((): void => {
    if (!presenter.sharedData?.lastMessageTs) {
      Tasks.messaging.getMessagesByMediaTypes({
        groupId,
        direction: DIRECTION.OLDEST,
        // TODO suppresses will be removed after getMessagesByMediaTypes will be rewritten on typescript
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        cursorTs: presenter.group?.counter?.lastMessageTs + 1,
        type,
      });
    } else if (
      presenter.sharedData.lastMessageTs < (presenter.group?.counter?.lastMessageTs || 0)
    ) {
      Tasks.messaging.getMessagesByMediaTypes({
        groupId,
        direction: DIRECTION.NEWEST,
        // TODO suppresses will be removed after getMessagesByMediaTypes will be rewritten on typescript
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        cursorTs: presenter.sharedData?.lastMessageTs,
        type,
        isShowLoading: false,
      });
    }
  }, [presenter.group?.counter?.lastMessageTs, groupId, presenter.sharedData?.lastMessageTs, type]);

  return presenter;
};
