import { groupModel } from 'APP/model/model';

export async function transferOwnership(
  groupId: string,
  newOwnerId: string
): Promise<{ error: string | null }> {
  try {
    await groupModel.transferOwnership({ groupId, newOwnerId });

    return { error: null };
  } catch (error) {
    return { error: error.message };
  }
}
