import { action, computed, observable } from 'mobx';

import { IDeepLinkInfo, IUserMentionData } from 'APP/model/common/commonModel.types';
import {
  GroupType,
  GroupUserRole,
  IDraftInput,
  IGroupData,
  IGroupSettings,
  IMemberCustomPermissions,
  IPersonalizedGroup,
} from 'APP/model/group/groupModel.types';
import api from 'APP/packages/api';
import { RouterPage } from 'APP/router/constants';
import Entities, { Root } from 'APP/store';
import { MessagesStore } from 'APP/store/Messages/Messages';
import ArticleDrafts from 'STORE/ArticleDrafts';
import { BackgroundCall } from 'STORE/BackgroundCalls/BackgroundCall/BackgroundCall';
import { Call } from 'STORE/Calls/Call/Call';
import { Counter } from 'STORE/CountersStore/Counter';
import { Groups } from 'STORE/Groups';
import { buildScheduleGroupId } from 'STORE/Groups/Group/Group.utils';
import { IStrangeServerGroup } from 'STORE/Groups/types';
import InputPanel from 'STORE/InputPanelsStore/InputPanel';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';
import { Message } from 'STORE/Messages/Message/Message';
import { PinnedMessages } from 'STORE/Messages/PinnedMessages/PinnedMessages';
import { SharedData } from 'STORE/SharedData/SharedData';
import { Space } from 'STORE/Spaces/Space';
import UserTyping from 'STORE/UserTyping';
import { User } from 'STORE/Users/User/User';
import { TAvatarBackgroundColorIndex } from 'UIKIT/Avatar/Avatar.types';
import { debounce } from 'UTILS/debounce';

import { updateDirectFields, updateIndirectFields } from './fieldsUpdater';

const TOGGLE_NOTIFICATIONS_DELAY = 2000;

export class Group {
  id: string;
  opponentId: string | null = null;
  botOpponentId: string | null = null;

  @observable initiator = null;
  @observable name = '';
  @observable type: GroupType;
  @observable description = '';
  @observable descriptionMentions: IUserMentionData[] = [];
  @observable _avatarUrl: string | null = '';
  @observable categoryId: string | null = null;
  @observable categoryInfo = {};
  @observable muted = false;
  @observable usersCount = 0;
  @observable pinOrder = 0;
  @observable pinnedMessages: PinnedMessages;
  @observable messagesStore: MessagesStore;
  @observable sharedData: SharedData;
  @observable deepLinkInfo: IDeepLinkInfo | null = null;
  @observable userGroupRole: GroupUserRole | null = null;
  @observable draftsStore: ArticleDrafts;
  @observable draftInput: IDraftInput | null = null;
  @observable unread = false;
  @observable spaceId: string | null = null;
  @observable memberCustomPermissions: IMemberCustomPermissions | null;
  @observable isAwaitCall = false;
  @observable _verified: boolean;
  @observable userTyping = new UserTyping();
  @observable settings: IGroupSettings | null = null;
  @observable showContent = true;
  @observable donationUrl = '';
  @observable updateTs = 0;
  @observable obsceneFilterEnabled = false;
  @observable keywordFilters = 0;

  groups: Groups;
  root: Root;

  // TODO: remove this code after scheduled messages refactoring: GEM-23214
  isSheduledMessagesLoading = false;

  constructor(group: IGroupData, groups: Groups, root: Root) {
    this.groups = groups;
    this.root = root;
    this.type = group.type as GroupType;
    updateDirectFields(group, this);

    this.messagesStore = new MessagesStore(this);
    this.sharedData = new SharedData(this);
    this.draftsStore = new ArticleDrafts(this);
    this.pinnedMessages = new PinnedMessages(this);
    this.updateTs = Date.now();

    updateIndirectFields(group, this);
  }

  destroy(): void {
    this.messagesStore.clear();
  }

  @action
  setIsAwaitCall = (flag: boolean): void => {
    this.isAwaitCall = flag;
  };

  handleClearHistory = (): void => {
    this.pinnedMessages.clear();
    this.counter?.clear();
    this.sharedData.clear();
  };

  @action
  merge = (group: IGroupData | IPersonalizedGroup | IStrangeServerGroup): void => {
    const mappedGroup = this.groups.mapStrangeServerGroupToGroup(group);

    updateDirectFields(mappedGroup, this);
    updateIndirectFields(mappedGroup, this);
    this.updateTs = Date.now();
  };

  // TODO: Move to separate task
  debouncedTurnNotificationInGroup = debounce((data: { groupId: string; mute: boolean }) => {
    api.messaging.turnNotificationInGroup(data);
  }, TOGGLE_NOTIFICATIONS_DELAY);

  @computed
  get avatarUrl(): string | null {
    return this._avatarUrl;
  }

  set avatarUrl(avatarUrl: string | null) {
    this.setAvatarUrl(avatarUrl);
  }

  @computed
  get hasDraft(): boolean {
    return Boolean((this.draftInput?.text || '').trim());
  }

  @computed
  get avatarColorIndex(): TAvatarBackgroundColorIndex {
    const id = this.id ? this.id.toString() : '0';
    return id[id.length - 1] as TAvatarBackgroundColorIndex;
  }

  @action
  setAvatarUrl(avatarUrl: string | null): void {
    this._avatarUrl = avatarUrl;
  }

  @action
  setUnread(unread: boolean): void {
    this.unread = unread;
  }

  @action
  setUsersCount(usersCount: number): void {
    this.usersCount = usersCount || 0;
  }

  @action
  setDraftInput(draftInput: IDraftInput | null): void {
    this.draftInput = draftInput;
  }

  @action
  setSpaceId(spaceId: string | null): void {
    this.spaceId = spaceId;
  }

  @computed
  get inputPanel(): InputPanel {
    return this.root.InputPanelsStore.getById(this.id);
  }

  @computed
  get activeCall(): Call | null {
    return this.root.Calls.getCallByGroupId(this.id);
  }

  @computed
  get backgroundCall(): BackgroundCall | null {
    return this.root.BackgroundCalls.getCallByGroupId(this.id);
  }

  @computed
  get hasCall(): boolean {
    return Boolean(this.activeCall || this.backgroundCall);
  }

  @computed
  get deepLink(): IDeepLinkInfo['deepLink'] | null {
    return this.deepLinkInfo && this.deepLinkInfo.deepLink;
  }

  // private property, don't use outside group
  @computed
  get counter(): Counter | null {
    return this.root.Counters.getCounterById(this.id);
  }

  @computed
  get numberOfUnread(): number {
    return this.counter ? this.counter.counter : 0;
  }

  @computed
  get unreadUserMentionsCounter(): number {
    return this.counter ? this.counter.unreadUserMentionsCounter : 0;
  }

  @computed
  get lastUserMentionTs(): number {
    return this.counter ? this.counter.lastUserMentionTs : 0;
  }

  @computed
  get seenTs(): number {
    return this.counter ? this.counter.seenTs : 0;
  }

  @computed
  get lastSeenMessage(): ChatMessage | null {
    return this.messagesStore.getMessageByTs(this.seenTs);
  }

  @computed
  get opponentSeenTs(): number {
    // seenTs for threads === 0 before the first view
    return this.counter ? this.counter.opponentSeenTs || this.counter.lastMessageTs : 0;
  }

  @computed
  get lastEventTs(): number {
    return this.counter ? this.counter.lastEventTs : 0;
  }

  @computed
  get lastMessageTs(): number {
    const lastMessageTs = this.counter ? this.counter.lastMessageTs : 0;

    if (
      this.messagesStore.lastMessage &&
      this.messagesStore.lastMessage.serverTime > lastMessageTs
    ) {
      return this.messagesStore.lastMessage.serverTime;
    }

    return lastMessageTs;
  }

  @computed
  get lastMessage(): Message | null {
    const lastMessageTs = this.counter ? this.counter.lastMessageTs : 0;
    const lastMessage = this.counter ? this.counter.lastMessage : null;

    if (
      this.messagesStore.lastMessage &&
      this.messagesStore.lastMessage.serverTime >= lastMessageTs
    ) {
      return this.messagesStore.lastMessage;
    }

    return lastMessage;
  }

  @computed
  get isActive(): boolean {
    return this.groups.activeGroupId === this.id;
  }

  @computed
  get isPinned(): boolean {
    return Boolean(this.pinOrder);
  }

  @computed
  get isShowDraft(): boolean {
    return !this.isActive && !this.inputPanel.isEditMode && this.hasDraft;
  }

  @computed
  get isAdmin(): boolean {
    return this.userGroupRole === GroupUserRole.Admin;
  }

  @computed
  get isMember(): boolean {
    return this.userGroupRole === GroupUserRole.Member;
  }

  @computed
  get isOwner(): boolean {
    return this.userGroupRole === GroupUserRole.Owner;
  }

  @computed
  get withMeInAdmins(): boolean {
    return this.isAdmin || this.isOwner;
  }

  @computed
  get withoutMe(): boolean {
    return (
      this.userGroupRole === GroupUserRole.Guest || this.userGroupRole === GroupUserRole.Banned
    );
  }

  get route(): string {
    return this.isChannel ? RouterPage.Channels : RouterPage.Chats;
  }

  get routePath(): string {
    return `/${this.route}/${this.id}`;
  }

  get groupOpponent(): User | null {
    return null;
  }

  get blockedMe(): boolean {
    return false;
  }

  get blockedByMe(): boolean {
    return false;
  }

  get notificationsAvailable(): boolean {
    return (
      this.userGroupRole !== GroupUserRole.Guest && this.userGroupRole !== GroupUserRole.Banned
    );
  }

  @computed
  get avatarTitle(): string | null {
    return this.name;
  }

  @computed
  get title(): string {
    return this.name;
  }

  get isChannel(): boolean {
    return false;
  }

  get isChatGroup(): boolean {
    return false;
  }

  get isP2P(): boolean {
    return false;
  }

  get isGroup(): boolean {
    return true;
  }

  get isBot(): boolean {
    return false;
  }

  get isMy(): boolean {
    return false;
  }

  get isSavedMessages(): boolean {
    return false;
  }

  get isOnlineOpponent(): boolean {
    return false;
  }

  @computed
  get verified(): boolean {
    return this._verified;
  }

  @computed
  get isOpen(): boolean {
    return [GroupType.ChannelOpen, GroupType.Open].includes(this.type);
  }

  @computed
  get isClosed(): boolean {
    return [GroupType.ChannelClosed, GroupType.Closed].includes(this.type);
  }

  setSeenTs(time: number): void {
    this.counter && this.counter.setSeenTs(time);
  }

  @action
  setMemberCustomPermissions(permissions: IMemberCustomPermissions): void {
    this.memberCustomPermissions = permissions;
  }

  @action
  setUserGroupRole(newRole: GroupUserRole): void {
    this.userGroupRole = newRole;
  }

  @computed
  get canPinMessage(): boolean {
    return (
      this.withMeInAdmins || (this.isMember && Boolean(this.memberCustomPermissions?.canPinMessage))
    );
  }

  @computed
  get canInviteUsers(): boolean {
    return (
      this.withMeInAdmins ||
      (this.isMember && Boolean(this.memberCustomPermissions?.canInviteUsers))
    );
  }

  @computed
  get canInitConference(): boolean {
    return (
      this.withMeInAdmins ||
      (this.isMember && Boolean(this.memberCustomPermissions?.canInitConference))
    );
  }

  @computed
  get canInitCall(): boolean {
    return (
      this.withMeInAdmins || (this.isMember && Boolean(this.memberCustomPermissions?.canInitCall))
    );
  }

  @computed
  get canLeaveComment(): boolean {
    return (
      Boolean(this.memberCustomPermissions?.canLeaveComment) && !(this.withoutMe && !this.isOpen)
    );
  }

  @computed
  get canDisplayMembers(): boolean {
    return (
      this.withMeInAdmins ||
      !this.memberCustomPermissions ||
      !!this.memberCustomPermissions?.canDisplayMembers
    );
  }

  @computed
  get canSendMessages(): boolean {
    const group = this.parentGroup || this;

    return (
      !group.isChatGroup ||
      group.withMeInAdmins ||
      !group.memberCustomPermissions ||
      !!group.memberCustomPermissions?.canSendMessages
    );
  }

  sendToggleNotification = (): void => {
    try {
      this.debouncedTurnNotificationInGroup({
        groupId: this.id,
        mute: this.muted,
      });
    } catch (error) {
      console.error(error);
    }
  };

  @action
  mute = (): void => {
    this.muted = true;
    this.sendToggleNotification();
  };

  @action
  unmute = (): void => {
    this.muted = false;
    this.sendToggleNotification();
  };

  @action
  setShowContent(value: boolean): void {
    this.showContent = value;
  }

  get canMentionAll(): boolean {
    return true;
  }

  get canBeScheduled(): boolean {
    return true;
  }

  get hasMessages(): boolean {
    return this.messagesStore.messages.length > 0;
  }

  @computed
  get scheduleGroupId(): string {
    return buildScheduleGroupId(this.id);
  }

  get isFake(): boolean {
    return false;
  }

  get parentGroup(): Group | null {
    return null;
  }

  @computed
  get space(): Space | null {
    if (!this.spaceId) {
      return null;
    }

    return Entities.spacesStore.getById(this.spaceId) || null;
  }
}
