export enum SendFeedbackProblemEnum {
  DeviceHot = 'DEVICE_HOT',
  DeviceBattery = 'DEVICE_BATTERY',
  DeviceCrash = 'DEVICE_CRASH',
  VideoBlurry = 'VIDEO_BLURRY',
  VideoFroze = 'VIDEO_FROZE',
  VideoBlack = 'VIDEO_BLACK',
  VideoSync = 'VIDEO_SYNC',
  VideoCantStart = 'VIDEO_CANT_START',
  AudioFroze = 'AUDIO_FROZE',
  AudioCantHear = 'AUDIO_CANT_HEAR',
  AudioCantHearMe = 'AUDIO_CANT_HEAR_ME',
  AudioVideoSync = 'AUDIO_VIDEO_SYNC',
  SmthElse = 'SMTH_ELSE',
}
