import { useLocalStore } from 'mobx-react';

import { moderationModel } from 'APP/model/model';
import { ComplaintType, IGroupComplaint } from 'APP/model/moderation/moderationModel.types';
import { useTranslation } from 'APP/packages/translations';
import Entities from 'APP/store';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import { Group } from 'STORE/Groups/Group';

import { IReportFormData } from '../../components/ReportForm/ReportForm.types';

interface IReportGroupPresenter {
  group: Group | null;
  onSubmit(formData: IReportFormData): Promise<void>;
  onClose(): void;
}

export interface IReportGroupPopupParams {
  groupId: string;
}

export function useReportGroupPresenter({
  params,
  popupInstance,
}: ICommonPopupsProps<IReportGroupPopupParams>): IReportGroupPresenter {
  const { groupId } = params!;
  const { t } = useTranslation();

  const presenter = useLocalStore<IReportGroupPresenter>(() => ({
    get group(): Group | null {
      return Entities.GroupsStore.getGroupById(groupId);
    },

    async onSubmit(formData: IReportFormData): Promise<void> {
      try {
        await moderationModel.sendComplaint<IGroupComplaint>({
          ...formData,
          complaint: {
            complaintType: ComplaintType.Group,
            groupId: groupId,
          },
        });

        Entities.toast.show(t('content_moderation_sent_message'));
      } catch {
        Entities.toast.show(t('something_went_wrong'));
      }

      presenter.onClose();
    },

    onClose(): void {
      popupInstance.close();
    },
  }));

  return presenter;
}
