import { UpdateReason } from 'APP/constants/scroll';
import Entities from 'APP/store';

export const moveToBottom = async () => {
  const group = Entities.GroupsStore.activeGroup;

  if (!group || !group.counter) {
    return;
  }

  group.messagesStore.setNewMessagesPlaceholderTs(group.seenTs);
  group.messagesStore.unfocusMessage();

  const lastInViewMessageTS = Math.max(
    ...group.messagesStore.inViewMessageIds.map(
      (id) => group.messagesStore.getMessageById(id).serverTime
    )
  );

  // if TS of last message in viewPort less lastSeenTS scrolled to lastSeenTs messages
  // otherwise scroll to bottom
  if (lastInViewMessageTS < group.seenTs && group.seenTs < group.lastMessageTs) {
    Entities.ChatStore.setScrollChanged(UpdateReason.ScrollToNewMessage);
    return;
  }

  Entities.ChatStore.setScrollChanged(UpdateReason.ScrollToBottom, { isAnimated: true });
};
