import React, { memo, MouseEventHandler } from 'react';

import styles from './PrivacyItem.styles.m.css';

interface IPrivacyItemProps {
  title: string;
  description: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
}

export const PrivacyItem = memo<IPrivacyItemProps>((props) => {
  const { title, description, onClick } = props;

  return (
    <button
      className={styles.container}
      onClick={onClick}
      data-test={`privacy-item-${title}`}
    >
      <span className={styles.title}>{title}</span>
      <span className={styles.description}>{description}</span>
    </button>
  );
});
