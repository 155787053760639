import classNames from 'classnames';
import React, { ElementType, FC } from 'react';

import styles from './HubCard.styles.m.css';

interface IHubCardProps {
  title: string;
  onClick(): void;
  Icon: ElementType;
  color: '1' | '2' | '3' | '4';
}
export const HubCard: FC<IHubCardProps> = (props) => {
  const { title, onClick, Icon, color } = props;

  return (
    <button
      className={classNames(styles[`color-${color}`], styles.hubCard)}
      onClick={onClick}
    >
      <div className={styles.icon}>
        <Icon />
      </div>
      <div className={styles.title}>{title}</div>
    </button>
  );
};
