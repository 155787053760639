/* eslint-disable react/no-array-index-key */
import classNames from 'classnames';
import React, { memo, useState } from 'react';

import InfoCard from 'APP/packages/placeholders/InfoCard';

import AnimatedPartStyles from '../AnimatedPart/AnimatedPart.styles.m.css';
import useSyncAnimation from '../hooks/useSyncAnimations';
import styles from './styles.m.scss';

const StickerPack = ({ count = 25 }) => {
  const [list] = useState(Array(count).fill('item'));

  const { isFirstAnimated, isSecondAnimated } = useSyncAnimation({
    firstTimeout: 500,
    secondTimeout: 1000,
  });

  return (
    <>
      <div className={styles.header}>
        <InfoCard
          isSecondAnimated={isSecondAnimated}
          isFirstAnimated={isFirstAnimated}
        />
      </div>
      <div className={styles.container}>
        {list.map((item, index) => {
          return (
            <div
              key={index}
              className={classNames(styles.sticker, {
                [AnimatedPartStyles.animation]: isSecondAnimated,
              })}
            />
          );
        })}
      </div>
      <div className={styles.footer}>
        <div
          className={classNames(styles.button, {
            [AnimatedPartStyles.animation]: isSecondAnimated,
          })}
        />
      </div>
    </>
  );
};

export default memo(StickerPack);
