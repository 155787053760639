const ID_SCRIPT_API = 'youtube-iframe-api';

const callbackList = [];
let isLoadedApi = false;

const createScriptApi = () => {
  const script = document.createElement('script');
  script.src = 'https://www.youtube.com/iframe_api';
  script.id = ID_SCRIPT_API;
  script.async = true;

  document.body.appendChild(script);
};

const onLoadApi = () => {
  isLoadedApi = true;
  while (callbackList.length > 0) {
    try {
      callbackList.shift()();
    } catch (e) {
      console.error(e);
    }
  }
};

const removeListener = (callback) => {
  const index = callbackList.indexOf(callback);
  if (index >= 0) {
    callbackList.splice(index, 1);
  }
};

export const loadYouTubeApi = (callback) => {
  callbackList.push(callback);

  if (isLoadedApi) {
    onLoadApi();
  } else {
    const isExistScript = document.getElementById(ID_SCRIPT_API);
    if (!isExistScript) {
      window.onYouTubeIframeAPIReady = onLoadApi;
      createScriptApi();
    }
  }

  return removeListener.bind(this, callback);
};
