import { useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { loadRangeAroundNextUserMention } from 'APP/Tasks/messaging/loadRangeAroundNextUserMention/loadRangeAroundNextUserMention';
import { MENTION_ALL_NICKNAME } from 'APP/constants/messages';
import { UpdateReason } from 'APP/constants/scroll';
import { IUserMention } from 'APP/model/user/userModel.types';
import type { Group } from 'APP/store/Groups/Group';
import Entities from 'STORE';
import type { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';
import { formatCounter } from 'UTILS/formatCounter';

export interface IScrollToMentionPresenter {
  isLoading: boolean;
  group: Group;
  badgeCount: string;
  hasScrollToMentionButton: boolean;
  moveToMention(): Promise<void>;
  findMentionMessage(): ChatMessage | null;
}

export const useScrollToMentionPresenter = (): IScrollToMentionPresenter => {
  const presenter = useLocalStore<IScrollToMentionPresenter>(() => ({
    isLoading: false,
    get group(): Group {
      return Entities.GroupsStore.activeGroup!;
    },

    get hasScrollToMentionButton(): boolean {
      return Boolean(presenter.group.counter?.unreadUserMentionsCounter);
    },

    get badgeCount(): string {
      const count = presenter.group.counter?.unreadUserMentionsCounter;
      if (count) {
        return formatCounter(count);
      }
      return '';
    },

    findMentionMessage(): ChatMessage | null {
      return (
        presenter.group.messagesStore.messages.find(
          (message: ChatMessage) =>
            message.serverTime > presenter.group.seenTs &&
            message.payload.userMentions?.find(
              (x: IUserMention) =>
                x.userId === Entities.UsersStore.Me.id || x.nickname === MENTION_ALL_NICKNAME
            )
        ) || null
      );
    },

    async moveToMention(): Promise<void> {
      if (presenter.isLoading) {
        return;
      }
      presenter.isLoading = true;
      let message = presenter.findMentionMessage();
      if (!message) {
        presenter.group.messagesStore.setIsLoadingMessages(true);
        await loadRangeAroundNextUserMention(presenter.group.id, presenter.group.seenTs);
        presenter.group.messagesStore.setIsLoadingMessages(false);
        message = presenter.findMentionMessage();
      }
      if (message) {
        Tasks.messaging.focusMessage({
          groupId: presenter.group.id,
          messageId: message.id,
        });
      } else {
        Entities.ChatStore.setScrollChanged(UpdateReason.ScrollToNewMessage);
      }
      presenter.isLoading = false;
    },
  }));

  return presenter;
};
