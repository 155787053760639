import { observer } from 'mobx-react';

import { usePermissionsRequestHandlerPresenter } from './PermissionsRequestHandler.presenter';

/**
 * PermissionsRequestHandler is only used to request permissions when the user's role changes.
 * The initial request is triggered in the "startCall" (Tasks.calls.startCall) task.
 */

export const PermissionsRequestHandler = observer(() => {
  usePermissionsRequestHandlerPresenter();

  return null;
});
