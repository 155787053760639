import React, { FC, ReactNode } from 'react';
import { Popper } from 'react-popper';

import { AddKeyPressHandler } from 'APP/packages/keyBoardHandler';
import { ClickOutside } from 'UIKIT/ClickOutside/ClickOutside';

import { TextFormattingLinkView } from './TextFormattingLinkView/TextFormattingLinkView';
import { TextFormattingMainView } from './TextFormattingMainView/TextFormattingMainView';
import { useTextFormattingMenuPresenter } from './TextFormattingMenu.presenter';
import styles from './TextFormattingMenu.styles.m.css';
import { ITextFormattingMenuParams, ViewType } from './TextFormattingMenu.types';

type ITextFormattingMenuProps = ITextFormattingMenuParams;

export const TextFormattingMenu: FC<ITextFormattingMenuProps> = (props) => {
  const { onClose } = props;
  const { presenter, popperRef, setPopperRef } = useTextFormattingMenuPresenter(props);

  return (
    <ClickOutside
      reference={popperRef}
      onClickOutside={onClose}
    >
      <AddKeyPressHandler onEsc={onClose} />

      <Popper
        innerRef={setPopperRef}
        referenceElement={presenter.referenceElement}
        placement="top"
        modifiers={[{ name: 'offset', options: { offset: [0, 8] } }]}
      >
        {({ ref, style }): ReactNode => (
          <div
            ref={ref}
            className={styles.container}
            style={style}
          >
            {presenter.viewType === ViewType.Main && (
              <TextFormattingMainView
                {...props}
                onChangeView={presenter.onChangeViewType}
              />
            )}

            {presenter.viewType === ViewType.Link && (
              <TextFormattingLinkView
                {...props}
                onChangeView={presenter.onChangeViewType}
              />
            )}
          </div>
        )}
      </Popper>
    </ClickOutside>
  );
};
