import api from 'APP/packages/api';
import { storage } from 'APP/packages/storage';
import Entities from 'APP/store';
import { ICountryCodes } from 'APP/types/countryCodes';

export const getCountriesPhoneCodes = async (force = false): Promise<ICountryCodes> => {
  const localStorageCountryCodes = storage.countriesPhoneCodes.get();
  const isAppVersionDifferent = Entities.App.version !== localStorageCountryCodes?.appVersion;

  if (!localStorageCountryCodes || isAppVersionDifferent || force) {
    const serverCountryCodes = (await api.authorization.getCountriesPhoneCode()) as ICountryCodes;

    storage.countriesPhoneCodes.set({
      appVersion: Entities.App.version,
      ...serverCountryCodes,
    });

    return {
      currentCountry: serverCountryCodes.currentCountry,
      availableCounties: serverCountryCodes.availableCounties,
    };
  }

  return {
    currentCountry: localStorageCountryCodes.currentCountry,
    availableCounties: localStorageCountryCodes.availableCounties,
  };
};
