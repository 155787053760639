import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { SpaceAvatar } from 'APP/components/Avatar/SpaceAvatar/SpaceAvatar';
import { useTranslation } from 'APP/packages/translations';
import MoreIcon from 'ICONS/ic-more.svg';
import { Space } from 'STORE/Spaces/Space';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';
import { Button } from 'UIKIT/Button/Button';
import { IconButton, IconViewSizes } from 'UIKIT/IconButton';
import { usePopover } from 'UIKIT/Popover/usePopover';
import { PopoverMenu } from 'UIKIT/PopoverMenu/PopoverMenu';
import { Tag } from 'UIKIT/Tag/Tag';
import { getFormatNumberToString } from 'UTILS/getFormatNumberToString';

import { ProfileSeeMoreDescription } from '../components/ProfileSeeMoreDescription/ProfileSeeMoreDescription';
import { useSpaceGuestProfilePresenter } from './SpaceGuestProfile.presenter';
import styles from './SpaceGuestProfile.styles.m.css';

interface ILocalSpaceGuestProfile {
  className?: string;
  space: Space;
}

export const SpaceGuestProfile: FC<ILocalSpaceGuestProfile> = observer(({ className, space }) => {
  const { t } = useTranslation();
  const presenter = useSpaceGuestProfilePresenter(space);
  const popover = usePopover<HTMLButtonElement>();

  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.content}>
        <h3 className={styles.title}>{space.name}</h3>

        <div className={styles.members}>
          {t('space_members_count').replace(
            '{0}',
            getFormatNumberToString(space.participantsCount)
          )}
        </div>

        {space.description && (
          <ProfileSeeMoreDescription
            className={styles.description}
            description={space.description}
          />
        )}

        {presenter.category && <Tag className={styles.tag}>{presenter.category.displayName}</Tag>}

        <div className={styles.actions}>
          {presenter.canJoinSpace && (
            <Button
              className={styles.actionButton}
              onClick={presenter.onJoin}
              isLoading={presenter.isJoining}
              theme="1"
            >
              {t('common_join')}
            </Button>
          )}

          {presenter.canLeaveSpace && (
            <Button
              className={styles.actionButton}
              onClick={presenter.onLeave}
              isLoading={presenter.isLeaving}
              theme="2"
            >
              {t('coomon_leave')}
            </Button>
          )}

          {!!presenter.menuItems.length && (
            <>
              <IconButton
                setRef={popover.containerRef}
                Icon={MoreIcon}
                size={IconViewSizes.Big}
                iconSize={IconViewSizes.Medium}
                theme="6"
                onClick={popover.toggle}
              />
              <PopoverMenu
                isOpened={popover.isOpened}
                menuItems={presenter.menuItems}
                reference={popover.containerRef.current}
                onClose={popover.close}
              />
            </>
          )}
        </div>
      </div>

      <SpaceAvatar
        space={space}
        type={AvatarTypes.Square_50}
      />
    </div>
  );
});
