import Entities from 'STORE';

import { loadSpace } from '../loadSpace/loadSpace';

export const setActiveSpace = async (spaceId: string | null = null): Promise<void> => {
  if (Entities.spacesStore.activeSpaceId === spaceId) {
    return;
  }

  if (!spaceId) {
    Entities.spacesStore.setActiveSpace(null);
    return;
  }

  Entities.spacesStore.setActiveSpace(spaceId);
  if (!Entities.spacesStore.getById(spaceId)) {
    await loadSpace(spaceId);
  }
};
