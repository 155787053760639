import { observer } from 'mobx-react';
import React, { FC, MutableRefObject } from 'react';

import { OnboardingType } from 'APP/constants/onboarding';
import { useTranslation } from 'APP/packages/translations';
import { useOnboardingManager } from 'MAIN/hooks/onboardingManager/useOnboardingManager';
import { OnboardingPopover } from 'UIKIT/OnboardingPopover/OnboardingPopover';

interface ISpaceLinkOnboardingProps {
  reference: MutableRefObject<HTMLDivElement | null>;
}

export const SpaceLinkOnboarding: FC<ISpaceLinkOnboardingProps> = observer(({ reference }) => {
  const { t } = useTranslation();
  const presenter = useOnboardingManager(OnboardingType.SpaceLink);

  if (!presenter.isNeedToShow) {
    return null;
  }

  return (
    <OnboardingPopover
      reference={reference}
      description={t('space_onboarding_deep_link')}
      handleClose={presenter.handleClose}
      isOpened={presenter.isOpened}
    />
  );
});
