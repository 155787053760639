import { useLocalStore } from 'mobx-react';
import { useCallback, useEffect, useRef } from 'react';

import Tasks from 'APP/Tasks';
import { AudioSourceType } from 'APP/constants/app';
import { CallType } from 'APP/model/call/callModel.types';
import { useTranslation } from 'APP/packages/translations';
import useStores from 'STORE/hooks/useStores';

export default () => {
  const { IncomingCalls } = useStores();
  const removeSourceRef = useRef(null);
  const { t } = useTranslation();

  const IncomingCall = useLocalStore(() => ({
    get call() {
      return IncomingCalls.calls[0];
    },

    get isAvailableJoinCallWithVideo() {
      return IncomingCall.call.callType === CallType.P2P;
    },

    get hasCall() {
      return Boolean(this.call);
    },

    get avatar() {
      const group = this.call.group;

      return {
        title: this.call.initiatorUser ? group.avatarTitle : t('call_guest_call_title'),
        url: group.avatarUrl,
        colorIndex: group.avatarColorIndex,
      };
    },

    get group() {
      if (!IncomingCall.hasCall) {
        return null;
      }

      return this.call.group;
    },

    decline() {
      Tasks.calls.decline({
        groupId: IncomingCall.group ? IncomingCall.group.id : null,
        channelId: IncomingCall.call.channelId,
        providerType: IncomingCall.call.callProvider,
      });
    },

    joinToCall: function () {
      Tasks.calls.joinToCall({
        groupId: IncomingCall.group ? IncomingCall.group.id : null,
        channelId: IncomingCall.call.channelId,
        withAudio: !!IncomingCall.group?.isP2P,
        withVideo: false,
        callType: IncomingCall.call.callType,
        providerType: IncomingCall.call.callProvider,
      });
    },

    joinToCallWithVideo: function () {
      Tasks.calls.joinToCall({
        groupId: IncomingCall.group ? IncomingCall.group.id : null,
        channelId: IncomingCall.call.channelId,
        withAudio: !!IncomingCall.group?.isP2P,
        withVideo: true,
        callType: IncomingCall.call.callType,
        providerType: IncomingCall.call.callProvider,
      });
    },

    get callTitle() {
      if (!this.hasCall) {
        return '';
      }

      if (this.group.isGroup) {
        return this.call.initiatorUser
          ? `${this.call.initiatorUser?.avatarTitle} - ${this.group?.avatarTitle}`
          : t('call_guest_call_title');
      }

      return this.group?.avatarTitle;
    },
  }));

  const removeAudioSource = useCallback(() => {
    if (removeSourceRef.current) {
      removeSourceRef.current();
      removeSourceRef.current = null;
    }
  }, []);

  useEffect(() => {
    if (IncomingCall.hasCall) {
      // close all popover
      document.dispatchEvent(new Event('click'));

      removeSourceRef.current = Tasks.app.audioSource.setCurrentSource(
        AudioSourceType.IncomingCall
      );
    } else {
      removeAudioSource();
    }
  }, [IncomingCall.hasCall]);

  useEffect(() => {
    return () => removeAudioSource();
  }, []);

  return {
    IncomingCall,
  };
};
