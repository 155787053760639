import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { List } from 'APP/components/List/List';
import { SharedDataType } from 'APP/constants/sharedData';
import { Loader } from 'UIKIT/Loader/Loader';

import { useSharedDataPresenter } from '../SharedData.presenter';
import styles from '../SharedData.styles.m.css';
import { EmptyContentStub } from '../components/EmptyContentStub/EmptyContentStub';
import { GroupByDateItems } from '../components/GroupByDateItems';
import mediaStyles from './Media.styles.m.css';
import { MediaItem } from './MediaItem/MediaItem';

export const Media = observer(({ className, groupId }) => {
  const presenter = useSharedDataPresenter({
    groupId,
    type: SharedDataType.Media,
  });

  return (
    <List
      isLoading={presenter.isLoading}
      className={classNames(styles.container, className)}
      data={presenter.dateList}
      hasMore={presenter.hasMore}
      onEndReached={presenter.loadMore}
      keyExtractor={(date) => date}
      renderItem={(date) => (
        <GroupByDateItems
          listClassName={mediaStyles.list}
          key={date}
          title={date}
          data={presenter.groupFilesByDate[date]}
          groupId={groupId}
          sharedDataType={SharedDataType.Media}
          renderItem={(item) => (
            <MediaItem
              key={`${item.id}_${item.messageId}`}
              className={mediaStyles.item}
              {...item}
            />
          )}
        />
      )}
      SpinnerComponent={
        <div className={styles.loader}>
          <Loader color={'base'} />
        </div>
      }
      ListEmptyComponent={<EmptyContentStub />}
    />
  );
});
