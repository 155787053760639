import { observer } from 'mobx-react';
import React from 'react';

import { MediaPreview } from 'APP/components/MediaPreview/MediaPreview';
import { MEDIA_PREVIEW_MAX_SIZE } from 'APP/constants/newsfeed';

import styles from './PostAlbum.styles.m.css';

export const PostAlbumImage = observer((props) => {
  const { groupId, payload, mediaId, isPreview, isView, onClick } = props;

  if (!payload?.previewUrl) {
    return null;
  }

  return (
    <div className={styles.previewContainer}>
      <MediaPreview
        className={styles.preview}
        imageClassName={styles.mediaImage}
        payload={payload}
        maxWidth={MEDIA_PREVIEW_MAX_SIZE.width}
        maxHeight={MEDIA_PREVIEW_MAX_SIZE.height}
        isPreview={isPreview}
        isView={isView}
        onClick={() => onClick(mediaId)}
        payloadId={mediaId}
        groupId={groupId}
      />
    </div>
  );
});
