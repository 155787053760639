import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import { openPopup } from 'APP/Tasks/app/popup/popup';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import { RewardsPopup } from 'MAIN/PopupManager/Popups/RewardsPopup/RewardsPopup';
import { Popup } from 'STORE/App/Popups/Popup/Popup';

interface IEditProfilePopupPresenter {
  childPopup: Popup | null;
  hasBackButton: boolean;
  openRewardsPopup(): void;
  onClose(): void;
  onBack(): void;
}

export function useEditProfilePopupPresenter({
  popupInstance,
  onClose,
  onBack,
}: ICommonPopupsProps): IEditProfilePopupPresenter {
  const presenter = useLocalStore<IEditProfilePopupPresenter>(() => ({
    childPopup: null,

    get hasBackButton(): boolean {
      return !!onBack;
    },

    openRewardsPopup(): void {
      popupInstance.hide();

      presenter.childPopup = openPopup(RewardsPopup, {
        onBack: () => popupInstance.show(),
        onClose: () => presenter.onClose(),
      });
    },

    onClose(): void {
      popupInstance.close();
      onClose?.();
    },

    onBack(): void {
      popupInstance.close();
      onBack?.();
    },
  }));

  useEffect(() => {
    return () => presenter.childPopup?.close();
  }, []);

  return presenter;
}
