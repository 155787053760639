import api from 'APP/packages/api';
import logger from 'APP/packages/logger';

export const setCallPermissionsForAll = async (channelId, permissions) => {
  try {
    await api.calls.setCallPermissionsForAll({ channelId, ...permissions });
  } catch (error) {
    logger.get('API').error('calls.setCallPermissionsForAll', error);
  }
};
