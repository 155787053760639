import Entities from 'STORE';
import { IPushNotification } from 'STORE/PushNotifications/PushNotifications.types';

import { isInActiveChat } from '../utils/isInActiveChat';
import { onGroupNotificationClick } from '../utils/onGroupNotificationClick';
import { onNotificationClose } from '../utils/onNotificationClose';
import { prepareNotificationOptions } from '../utils/prepareNotificationOptions';

export const handleCall = (pushNotification: IPushNotification): void => {
  if (!pushNotification.group || isInActiveChat(pushNotification)) {
    return;
  }

  const options = prepareNotificationOptions(pushNotification);

  const notification = new Notification(pushNotification.title || '', options);
  Entities.pushNotifications.addNotification(notification);
  notification.onclick = (): Promise<void> =>
    onGroupNotificationClick(pushNotification, notification);
  notification.onclose = (): void => onNotificationClose(notification);
  notification.onerror = (): void => onNotificationClose(notification);
};
