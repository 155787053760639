import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { useTranslation } from 'APP/packages/translations';
import Entities from 'STORE';
import { Call } from 'STORE/Calls/Call/Call';

interface IAnimatedBackgroundPanelPresenterStore {
  isPanelShown: boolean;
  isVideoMuted: boolean;
  ongoingCall: Call;
  hidePanel(): void;
}

export const useAnimatedBackgroundPanelPresenter = (): IAnimatedBackgroundPanelPresenterStore => {
  const { t } = useTranslation();

  const presenter = useLocalStore<IAnimatedBackgroundPanelPresenterStore>(() => ({
    get ongoingCall(): Call {
      return Entities.Calls.ongoingCall!;
    },

    get isPanelShown(): boolean {
      return presenter.ongoingCall.backgroundPanelShown;
    },
    get isVideoMuted(): boolean {
      return presenter.ongoingCall.me.isVideoMuted;
    },

    hidePanel(): void {
      presenter.ongoingCall.hideBackgroundPanel();
    },
  }));

  useEffect(() => {
    if (presenter.isPanelShown && presenter.isVideoMuted) {
      presenter.hidePanel();
      Tasks.app.showAlert(t('call_switch_on_your_camera'));
    }
  }, [presenter.isPanelShown, presenter.isVideoMuted]);

  return presenter;
};
