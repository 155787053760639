import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { HubPageType, THubPage } from 'MAIN/Hub/Hub.types';

import { useHubPresenter } from './Hub.presenter';
import styles from './Hub.styles.m.css';
import { HubAdBoardPage } from './HubAdBoardPage/HubAdBoardPage';
import { HubFreelancePage } from './HubFreelancePage/HubFreelancePage';
import { HubGamesPage } from './HubGamesPage/HubGamesPage';
import { HubMainPage } from './HubMainPage/HubMainPage';
import { HubMarketPage } from './HubMarketPage/HubMarketPage';

const PageComponent: Record<HubPageType, THubPage> = {
  [HubPageType.Main]: HubMainPage,
  [HubPageType.Games]: HubGamesPage,
  [HubPageType.Freelance]: HubFreelancePage,
  [HubPageType.Market]: HubMarketPage,
  [HubPageType.AdBoard]: HubAdBoardPage,
};
export const Hub: FC = observer(() => {
  const presenter = useHubPresenter();

  const Component = PageComponent[presenter.currenPage];
  return (
    <div
      className={classNames(styles.container, {
        [styles.hidden]: presenter.isHidden,
      })}
    >
      <Component setPage={presenter.setPage} />
    </div>
  );
});
