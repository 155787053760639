export const isEqualArrays = (arr1: any[], arr2: any[], withOrder = true): boolean => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  if (withOrder) {
    return arr1.toString() === arr2.toString();
  } else {
    return arr1.slice().sort().toString() === arr2.slice().sort().toString();
  }
};
