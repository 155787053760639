import { BUCKET_MEDIA_HOST } from './constants';

export function getImageByLargestSize(url: string, size: number): string {
  const pixelRatio = window.devicePixelRatio || 1;
  if (url && url.includes(BUCKET_MEDIA_HOST) && size) {
    const params = [`s${Math.floor(size * pixelRatio)}`];
    if (window.supportsWEBP) {
      params.push('rw');
    }

    return url.split('=')[0] + '=' + params.join('-');
  }
  return url;
}
