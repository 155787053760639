import React, { FC } from 'react';

import { GroupType } from 'APP/model/group/groupModel.types';
import DeepLinkAliasField from 'APP/packages/form/DeepLinkAliasField';
import { useTranslation } from 'APP/packages/translations';

import { IChannelFormData } from '../ChannelForm.types';

export const ChannelDeepLinkAliasField: FC = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <DeepLinkAliasField
      title={t('common_invite_link')}
      description={t('channel_deeplink_prompt')}
      placeholder={t('common_link')}
      validatingText={t('checking_deeplink')}
      successText={t('channel_link_is_available')}
      isShowLink={(values: IChannelFormData): boolean => values.type === GroupType.ChannelOpen}
      {...props}
    ></DeepLinkAliasField>
  );
};
