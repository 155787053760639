import { ReactNode } from 'react';

import { splitByParams } from 'APP/utils/i18n/splitByParams';

import useTranslation from '../hooks/useTranslation';

interface ITransPresenter {
  chunks: string[];
  renderChunk(chunk: string): ReactNode;
}

interface ITransPresenterProps {
  translateKey: string;
  keyParams: Record<string | number, ReactNode>;
}

const transformParams = (
  keyParams: Record<string | number, ReactNode>
): Record<string, ReactNode> =>
  Object.keys(keyParams).reduce((params: Record<string, ReactNode>, key) => {
    params[`{${key}}`] = keyParams[key];
    return params;
  }, {});

export const useTransPresenter = ({
  translateKey,
  keyParams,
}: ITransPresenterProps): ITransPresenter => {
  const { t } = useTranslation();
  const translate = t(translateKey);
  const params = transformParams(keyParams);

  return {
    chunks: splitByParams(translate),
    renderChunk(chunk: string): ReactNode {
      const keys = Object.keys(params);
      return keys.includes(chunk) ? params[chunk] : chunk;
    },
  };
};
