import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import { MEDIA_MAX_SIZE } from 'APP/constants/articles';
import { getWidthHeightByMaxValues } from 'APP/packages/imageResizer';

import useUploadFile from '../../../hooks/useUploadFile';
import prepareImageUrls from '../../../services/prepareImageUrls';

export default ({ parentPresenter, payload, order, id }) => {
  const { payloadType, userFile, graphicSize, duration, localPreview, _previewUrl, url } = payload;

  const {
    error,
    uploadProgress,
    url: uploadUrl,
    previewUrl,
    cancelUpload,
  } = useUploadFile({ userFile: userFile });

  const source = useAsObservableSource({
    uploadProgress,
    url,
    uploadUrl,
    localPreview,
    previewUrl,
    cancelUpload,
    order,
  });

  const presenter = useLocalStore(() => ({
    setPayload: () => {
      parentPresenter.editPayloadByOrder(presenter.payload, source.order);
    },

    cancelUploadAndDeleteMessage: () => {
      source.cancelUpload();

      parentPresenter.removeSomethingLoading(id);
      parentPresenter.deletePayloadByOrder(source.order);
    },

    waitingUrl: () => {
      if (!source.uploadUrl && !source.url && !_previewUrl) {
        parentPresenter.addSomethingLoading(id);
        return;
      }

      parentPresenter.removeSomethingLoading(id);
      presenter.setPayload();
    },

    get message() {
      return {
        payload: {
          ...presenter.payload,
          id,
          previewSize: { ...graphicSize },
          uploadProgress: source.uploadProgress,
          url: presenter.payloadUrl,
          cancelUploadAndDeleteMessage: presenter.cancelUploadAndDeleteMessage,
        },
        hasError: Boolean(error),
      };
    },

    get previewUrl() {
      return _previewUrl || source.previewUrl || source.localPreview;
    },

    get payloadUrl() {
      return source.url || source.uploadUrl || source.localPreview || _previewUrl;
    },

    get containerStyle() {
      const { height } = getWidthHeightByMaxValues(
        graphicSize.width,
        graphicSize.height,
        MEDIA_MAX_SIZE.width,
        MEDIA_MAX_SIZE.height
      );

      const { blurredImageUrl } = prepareImageUrls(presenter.previewUrl);

      return {
        maxHeight: `${MEDIA_MAX_SIZE.height}px`,
        height: `${height}px`,
        backgroundImage: `url(${blurredImageUrl})`,
      };
    },

    get url() {
      const { imageUrl } = prepareImageUrls(presenter.previewUrl);
      return imageUrl;
    },

    get payload() {
      return {
        payloadType: payloadType,
        comment: '',
        previewUrl: presenter.previewUrl,
        localPreview: presenter.previewUrl,
        url: presenter.payloadUrl,
        graphicSize: { ...graphicSize },
        file: userFile,
        duration,
      };
    },
  }));

  useEffect(() => {
    presenter.waitingUrl();
  }, [url, uploadUrl]);

  return presenter;
};
