import { useCallback, useEffect, useRef, useState } from 'react';

const HIDE_CONTROLS_DELAY = 3000;

export function useVideoActiveState() {
  const [isControlsHidden, setControlsHidden] = useState(false);
  const [isControlsActive, setControlsActive] = useState(false);
  const timeoutRef = useRef(null);

  const onMouseMove = useCallback(() => {
    setControlsHidden(false);
    clearCheckInactive();
    checkInactive();
  }, []);

  const checkInactive = useCallback(() => {
    timeoutRef.current = setTimeout(() => {
      setControlsHidden(true);
    }, HIDE_CONTROLS_DELAY);
  }, []);

  const clearCheckInactive = useCallback(() => {
    clearTimeout(timeoutRef.current);
  }, []);

  const onToggleControlsActiveState = useCallback((isOpened) => {
    setControlsActive(isOpened);
  }, []);

  useEffect(() => {
    checkInactive();

    return () => clearCheckInactive();
  }, []);

  return {
    isControlsHidden,
    onMouseMove,
    isControlsActive,
    onToggleControlsActiveState,
  };
}
