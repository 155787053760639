import { localStorageInstance } from '../../instances/localStorageInstance';

const KEY_NAME = 'emoji-sticker-tab-index';

export const set = (activeTab: number): void =>
  localStorageInstance.setItem(KEY_NAME, activeTab.toString());

export const get = (): number => {
  const activeTab = Number(localStorageInstance.getItem(KEY_NAME));

  if (isNaN(activeTab)) {
    return 0;
  }

  return activeTab;
};

export const remove = (): void => localStorageInstance.removeItem(KEY_NAME);
