import { useTranslation } from 'APP/packages/translations';

import nickname from '../fields/nickname';
import { userBioFieldValidation } from '../fields/userBioFieldValidation';
import username from '../fields/username';
import asyncValidation from '../hooks/asyncValidation';
import syncValidation from '../hooks/syncValidation';
import { ErrorType } from '../validation.types';

export default () => {
  const { t } = useTranslation();

  const usernameErrorToText = {
    [username.ERRORS.IS_EMPTY]: ' ',
    [username.ERRORS.TOO_SHORT]: t('username_min_length_error'),
  };

  const nicknameErrorToText = {
    [nickname.ERRORS.IS_EMPTY]: ' ',
    [nickname.ERRORS.TOO_SHORT]: t('nickanme_min_length_error'),
    [nickname.ERRORS.INCORRECT_SYMBOLS]: t('nickanme_hint'),
    [nickname.ERRORS.INCORRECT_EXTREME_SYMBOLS]: t('common_nickname_symbols_error'),
    [nickname.ERRORS.IS_ALREADY_EXIST]: t('user_profile_nick_name_already_exists_error'),
  };

  const bioErrorToText = {
    [ErrorType.TooLong]: t('common_about_max_count_validation'),
  };

  const usernameValidator = syncValidation();
  const nicknameValidator = asyncValidation();
  const bioValidator = syncValidation();

  return {
    username: {
      validate: (value, formData, meta) => {
        return usernameValidator({
          value,
          meta,
          validate: username.validate,
          errorToText: usernameErrorToText,
        });
      },
    },
    nickname: {
      validate: (value, formData, meta) => {
        return nicknameValidator({
          value,
          meta,
          validate: nickname.validate,
          asyncValidate: nickname.asyncValidate,
          errorToText: nicknameErrorToText,
        });
      },
    },
    bio: {
      validate: (value, formData, meta) => {
        return bioValidator({
          value,
          meta,
          validate: userBioFieldValidation,
          errorToText: bioErrorToText,
        });
      },
    },
  };
};
