import { platformInfo } from '../platformInfo/platformInfo';

export class ElectronApi {
  private static api: typeof window.electronAPI = window.electronAPI;

  public static get isEnabled(): boolean {
    return platformInfo.isElectron && !!this.api;
  }

  public static get isMac(): boolean {
    return this.isEnabled && this.api.isMac;
  }

  public static get isWindows(): boolean {
    return this.isEnabled && this.api.isWindows;
  }

  public static async getScreenRecordingPermission(): Promise<boolean> {
    return this.isEnabled ? this.api.getScreenRecordingPermission?.() : false;
  }

  public static focusApp(): void {
    if (this.isEnabled) {
      this.api.focusApp?.();
    }
  }

  public static async getGoogleAccessToken(clientId: string): Promise<string | null> {
    if (this.isEnabled) {
      return this.api.getGoogleAccessToken?.(clientId) || null;
    } else {
      return null;
    }
  }

  public static setBadge(count: number): void {
    if (this.isEnabled) {
      this.api.setBadge?.(count);
    }
  }

  public static hideLoader(): void {
    if (this.isEnabled) {
      this.api.hideLoader?.();
    }
  }

  public static setTheme(theme: string | null): void {
    if (this.isEnabled) {
      this.api.setTheme?.(theme);
    }
  }
}
