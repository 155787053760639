import { useLocalStore } from 'mobx-react';

import Entities from 'APP/store';
import type { User } from 'STORE/Users/User/User';
import { IUserContact } from 'STORE/Users/types';

import { IContactViewLocalStore } from './ContactsView.types';

const PLACEHOLDER_COUNT = 7;

export const useContactsViewPresenter = (): IContactViewLocalStore => {
  const presenter = useLocalStore<IContactViewLocalStore>(() => ({
    searchString: '',

    get loading(): boolean {
      return Entities.UsersStore.isUserContactsLoading && !presenter.searchString;
    },

    get contactUsers(): User[] {
      return (Entities.UsersStore.userContacts as IUserContact[])
        .slice()
        .sort((a, b) => b.updateTs - a.updateTs)
        .map(({ id }: IUserContact) => Entities.UsersStore.getUserById(id))
        .filter((contact) => Boolean(contact));
    },

    get filteredUsers(): User[] {
      if (presenter.searchString !== '') {
        return presenter.contactUsers.filter((user) => {
          return user.displayName.toLowerCase().includes(presenter.searchString.toLowerCase());
        });
      }
      return presenter.contactUsers;
    },

    get placeholdersCount(): number {
      return !presenter.contactUsers.length ? PLACEHOLDER_COUNT : 0;
    },

    keyExtractor(contactUser: User): string {
      return contactUser.id;
    },

    onSearch(value: string): void {
      presenter.searchString = value;
    },
  }));

  return presenter;
};
