import Tasks from 'APP/Tasks';
import { setPrivacySettings } from 'APP/model/userSettings/userSettingsModel';
import { IPrivacySettings, PrivacyValue } from 'APP/model/userSettings/userSettingsModel.types';
import Entities from 'APP/store';

const updatePrivacy = async (request: Partial<IPrivacySettings>): Promise<void> => {
  try {
    const response = await setPrivacySettings({
      showPhoneNumber: request.showPhoneNumber || Entities.PrivacySettings.showPhoneNumber,
      showAvatar: request.showAvatar || Entities.PrivacySettings.showAvatar,
      addToGroup: request.addToGroup || Entities.PrivacySettings.addToGroup,
      addToPublic: request.addToPublic || Entities.PrivacySettings.addToPublic,
      callMe: request.callMe || Entities.PrivacySettings.callMe,
      showLastSeen: request.showLastSeen || Entities.PrivacySettings.showLastSeen,
      searchMe: request.searchMe || Entities.PrivacySettings.searchMe,
      sendVoiceMessage: request.sendVoiceMessage || Entities.PrivacySettings.sendVoiceMessage,
    });

    Tasks.userSettings.updatePrivacySettingsLocally(response);
  } catch {
    // do nothing
  }
};

export const setPrivacyPhoneNumber = async (value: PrivacyValue): Promise<void> => {
  await updatePrivacy({ showPhoneNumber: value });
};

export const setPrivacyShowAvatar = async (value: PrivacyValue): Promise<void> => {
  await updatePrivacy({ showAvatar: value });
};

export const setPrivacyGroups = async (value: PrivacyValue): Promise<void> => {
  await updatePrivacy({ addToGroup: value });
};

export const setPrivacyChannels = async (value: PrivacyValue): Promise<void> => {
  await updatePrivacy({ addToPublic: value });
};

export const setPrivacyCalls = async (value: PrivacyValue): Promise<void> => {
  await updatePrivacy({ callMe: value });
};

export const setPrivacyVoice = async (value: PrivacyValue): Promise<void> => {
  await updatePrivacy({ sendVoiceMessage: value });
};

export const setPrivacySearch = async (value: PrivacyValue): Promise<void> => {
  await updatePrivacy({ searchMe: value });
};

export const setPrivacyStatus = async (value: PrivacyValue): Promise<void> => {
  await updatePrivacy({ showLastSeen: value });
};
