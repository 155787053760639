import Tasks from 'APP/Tasks';
import api from 'APP/packages/api';
import Entities from 'STORE';

async function request(data) {
  try {
    await api.messaging.deleteGroup(data);
    return {
      error: null,
    };
  } catch (error) {
    return {
      error: error.message,
    };
  }
}

export default async ({ groupId }) => {
  if (groupId === Entities.GroupsStore.activeGroupId) {
    Tasks.group.setActiveGroup({ groupId: null });
  }

  const { error } = await request({ groupId });

  if (error) {
    return {
      error,
    };
  }

  Tasks.group.removeGroupLocally({ groupId });

  return {
    error: null,
  };
};
