import { observer } from 'mobx-react';
import React from 'react';

import { EmojiCategoryList } from './EmojiCategoryList/EmojiCategoryList';
import { EmojiList } from './EmojiList/EmojiList';
import { useEmojiPanelPresenter } from './EmojiPanel.presenter';

export const EmojiPanel = observer(() => {
  const presenter = useEmojiPanelPresenter();

  return (
    <>
      <EmojiList
        emojis={presenter.emojis}
        onSelect={presenter.broadcastSelectEmoji}
      />
      <EmojiCategoryList
        categories={presenter.categories}
        checkIsActiveCategory={presenter.checkIsActiveCategory}
        onEmojiCategoryClick={presenter.setSelectedCategory}
      />
    </>
  );
});
