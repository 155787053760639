import { observer } from 'mobx-react';
import React from 'react';

import usePresenter from './presenter';
import styles from './styles.m.scss';

const LoadingBar = () => {
  const presenter = usePresenter();

  if (!presenter.isShow) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div
        className={styles.progress}
        style={{ width: presenter.progress + '%' }}
      />
    </div>
  );
};

export default observer(LoadingBar);
