import classNames from 'classnames';
import React, { ChangeEvent, FC } from 'react';

import styles from './Switch.styles.m.css';

interface ISwitchProps {
  className?: string;
  isChecked?: boolean;
  isDisabled?: boolean;
  onChange(event: ChangeEvent<HTMLInputElement>): void;
}

export const Switch: FC<ISwitchProps> = ({ className, isChecked, isDisabled, onChange }) => {
  const classes = classNames(styles.container, className, {
    [styles.activeContainer]: isChecked,
    [styles.disabledContainer]: isDisabled,
  });

  const thumbClasses = classNames(styles.thumb, {
    [styles.activeThumb]: isChecked,
    [styles.disabledThumb]: isDisabled,
  });

  return (
    <div className={classes}>
      <div className={thumbClasses}>
        <input
          type="checkbox"
          onChange={onChange}
          checked={isChecked}
          className={styles.checkbox}
          disabled={isDisabled}
        />
      </div>
    </div>
  );
};
