import api from 'APP/packages/api';
import logger from 'APP/packages/logger';
import { platformInfo } from 'APP/packages/platformInfo/platformInfo';
import Entities from 'STORE';
import { getUserLanguage } from 'UTILS/getUserLanguage';

const ERRORS = {
  E_INVALID_VERIFICATION_CODE: 'E_INVALID_VERIFICATION_CODE',
  E_ATTEMPTS_EXCEEDED: 'E_ATTEMPTS_EXCEEDED',
  E_VERIFICATION_CODE_EXPIRED: 'E_VERIFICATION_CODE_EXPIRED',
};

const validate = ({ value = '', errorToText }) => {
  if (value.length < 4) {
    return errorToText[ERRORS.E_INVALID_VERIFICATION_CODE];
  }
  return null;
};

const asyncValidate = async ({ value, errorToText, data }) => {
  try {
    const { success } = await api.authorization.confirmVerifyCode({
      device: {
        locale: getUserLanguage(),
        platform: platformInfo.platform,
        device: platformInfo.device,
        osVersion: platformInfo.osVersion,
        appVersion: Entities.App.version,
      },
      registrationKey: data.registrationKey,
      verificationCode: value,
    });
    if (success) {
      return null;
    }
    return errorToText[ERRORS.E_INVALID_VERIFICATION_CODE];
  } catch (error) {
    logger.get('API').error('authorization.confirmVerifyCode', error);
    return errorToText[error.message];
  }
};

export default {
  validate,
  asyncValidate,
  ERRORS,
};
