import { observer } from 'mobx-react';
import React from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { AddKeyPressHandler } from 'APP/packages/keyBoardHandler';
import { useTranslation } from 'APP/packages/translations';
import { withPopupLayer } from 'MAIN/Popups/withPopupLayer';
import { Button } from 'UIKIT/Button/Button';
import { Loader } from 'UIKIT/Loader/Loader';
import { Switch } from 'UIKIT/Switch/Switch';

import usePresenter from './presenter';
import styles from './styles.m.css';

export const Monetization = withPopupLayer(
  observer(() => {
    const { t } = useTranslation();
    const presenter = usePresenter();

    if (!presenter.isShow) {
      presenter.close();
      return null;
    }

    return (
      <Popup
        isOpened
        onClose={presenter.onClose}
        title={t('common:common_monetization')}
        className={styles.container}
      >
        <AddKeyPressHandler onEsc={presenter.onClose} />
        <div className={styles.content}>
          <label className={styles.item}>
            {t('common:common_monetization')}
            {presenter.isLoading ? (
              <Loader
                color={'base'}
                className={styles.loader}
              />
            ) : (
              <Switch
                isChecked={presenter.isChecked}
                onChange={presenter.toggle}
              />
            )}
          </label>
          <div className={styles.caption}>{t('common:common_monetization_tip')}</div>
          <div className={styles.caption}>
            {t('common:common_monetization_system_link').split('{0}')[0]}
            <button
              className={styles.link}
              onClick={presenter.onClick}
            >
              {t('common:common_gem4me_ads')}
            </button>
            {t('common:common_monetization_system_link').split('{0}')[1]}
          </div>
        </div>
        <div className={styles.footer}>
          <Button
            onClick={presenter.onSave}
            className={styles.button}
            theme="1"
          >
            {t('save')}
          </Button>
        </div>
      </Popup>
    );
  })
);
