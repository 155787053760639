/**
 * Stream API
 * Documentation Stream API v1.0
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IncomingCallCallVisibleDuration } from './incomingCallCallVisibleDuration';


/**
 * calls.active.call-started
 */
export interface IncomingCall { 
    groupId: string;
    channel: string;
    callVisibleDuration: IncomingCallCallVisibleDuration;
    callerId: string;
    callerName: string;
    callerAvatarUrl: string;
    callType: IncomingCallCallTypeEnum;
    callProvider: IncomingCallCallProviderEnum;
    event: string;
}
export enum IncomingCallCallTypeEnum {
    P2P = 'P2P',
    Group = 'GROUP',
    Conference = 'CONFERENCE',
    Stream = 'STREAM'
};
export enum IncomingCallCallProviderEnum {
    Agora = 'AGORA',
    Openvidu = 'OPENVIDU'
};



