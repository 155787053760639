export class EventWorker extends EventTarget {
  private worker: Worker;

  constructor(worker: Worker) {
    super();
    this.worker = worker;
    this.worker.onmessage = this.onMessage.bind(this);
  }

  private onMessage(event: MessageEvent): void {
    if (event.data?.type) {
      const customEvent = new CustomEvent(event.data.type, { detail: event.data.data });
      this.dispatchEvent(customEvent);
    }
  }

  public postMessage<T>(message: T): void {
    this.worker.postMessage(message);
  }
}
