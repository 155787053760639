import { TFunction } from 'react-i18next';

import {
  TSystemMessageTextHandler,
  ISystemMessageText,
  ISystemMessageTextPresenter,
} from '../useSystemMessageText.types';

const getSingleUserAdded: TSystemMessageTextHandler = (
  presenter: ISystemMessageTextPresenter,
  t: TFunction
): ISystemMessageText => {
  const result: ISystemMessageText = { html: '' };

  result.html = t('message_chat_joined', {
    0: `<span class="system-message-title">${presenter.initiatorDisplayName}</span>`,
    1: `<span class="system-message-title">${presenter.firstUserDisplayedName}</span>`,
    interpolation: { escapeValue: false },
  });
  result.sourceText = t('message_chat_joined');
  result.params = {
    '{0}': { text: presenter.initiatorDisplayName, userId: presenter.initiator?.id },
    '{1}': { text: presenter.firstUserDisplayedName, userId: presenter.firstUserId },
  };

  return result;
};

const getMultipleUsersAdded: TSystemMessageTextHandler = (
  presenter: ISystemMessageTextPresenter,
  t: TFunction
): ISystemMessageText => {
  const result: ISystemMessageText = { html: '' };

  result.html = t('many_users_group_chat_join_system_message', {
    0: `<span class="system-message-title">${presenter.users.length}</span>`,
    interpolation: { escapeValue: false },
  });
  result.sourceText = t('group_chat_join_system_message');
  result.params = {
    '{0}': {
      text: `${t('users_many', { 0: String(presenter.users.length) })}`,
      usersIds: presenter.users.map((user) => user.id),
    },
  };

  return result;
};

export const chatAddedHandler: TSystemMessageTextHandler = (
  presenter: ISystemMessageTextPresenter,
  t: TFunction
): ISystemMessageText => {
  if (presenter.payload.users.length > 1) {
    return getMultipleUsersAdded(presenter, t);
  } else {
    return getSingleUserAdded(presenter, t);
  }
};
