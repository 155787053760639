import React, { FC } from 'react';

import { SendFeedbackProblemEnum } from 'APP/constants/feedback';
import { useTranslation } from 'APP/packages/translations';

import {
  FeedbackIssueType,
  FeedbackIssueTypeProblems,
  MapFeedbackProblemToTranslation,
} from '../../DetailedFeedback.constants';
import { FeedbackIssueButton } from '../FeedbackIssueButton/FeedbackIssueButton';

interface FeedbackProblemButtons {
  activeProblemType?: SendFeedbackProblemEnum;
  activeIssueType: FeedbackIssueType;
  onButtonClick(val: SendFeedbackProblemEnum): void;
}

export const FeedbackProblemButtons: FC<FeedbackProblemButtons> = (props) => {
  const { t } = useTranslation();

  const { activeProblemType, onButtonClick, activeIssueType } = props;

  const problemsArray = FeedbackIssueTypeProblems[activeIssueType];

  return (
    <>
      {problemsArray.map((type) => (
        <FeedbackIssueButton
          key={type}
          issueType={type}
          isActive={activeProblemType === type}
          text={t(MapFeedbackProblemToTranslation[type])}
          onClick={onButtonClick}
        />
      ))}
    </>
  );
};
