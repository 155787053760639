import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { IconView } from 'APP/packages/UiKit/IconView/IconView';
import { useTranslation } from 'APP/packages/translations';
import ForwardIcon from 'ICONS/ic-forward.svg';
import { useForwardMessage } from 'MAIN/ChatView/hooks/useForwardMessage/useForwardMessage';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';
import { IconViewSizes } from 'UIKIT/IconView/IconView.types';
import { Loader } from 'UIKIT/Loader/Loader';

import styles from './ForwardMessage.styles.m.css';

type TForwardMessageTheme = '1' | '2';

interface IForwardMessageProps {
  className?: string;
  theme?: TForwardMessageTheme;
  message: ChatMessage;
}

export const ForwardMessage: FC<IForwardMessageProps> = observer((props) => {
  const { className, theme = '1', message } = props;

  const { t } = useTranslation();
  const presenter = useForwardMessage(message);

  return (
    <div className={classNames(styles.container, styles[`theme-${theme}`], className)}>
      <IconView
        Icon={ForwardIcon}
        size={IconViewSizes.Small}
        className={styles.icon}
      />
      <span className={styles.title}>{t('message_forwarded_from')}</span>

      <button
        className={styles.senderName}
        title={presenter.avatarTitle}
        onClick={presenter.onClick}
      >
        {presenter.avatarTitle}
        {presenter.isLoading && (
          <Loader
            className={styles.loader}
            color="current"
          />
        )}
      </button>
    </div>
  );
});
