import { subscribe, broadcast } from '../../eventBus';

function getBusStation() {
  return 'send-message';
}

export const subscribeSendMessage = (callback) => {
  return subscribe(getBusStation(), callback);
};

export const broadcastSendMessage = () => {
  broadcast(getBusStation());
};
