const ERRORS = {
  IS_EMPTY: 'IS_EMPTY',
};

const validate = ({ value, errorToText }) => {
  if (!value || value.length === 0) {
    return errorToText[ERRORS.IS_EMPTY];
  }

  return null;
};

export default {
  validate,
  ERRORS,
};
