import Tasks from 'APP/Tasks';

export default (group, msg, date) => {
  msg.clientTime = date.getTime();
  msg.serverTime = msg.clientTime;

  Tasks.messaging.updateOldOrFakeMessages({
    messages: [msg],
    groupId: group.id,
  });

  return group.messagesStore.getMessageById(msg.id);
};
