import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useCallback } from 'react';

import { List } from 'APP/components/List/List';
import { GroupsPlaceholder } from 'APP/packages/placeholders/GroupsPlaceholder/GroupsPlaceholder';
import { useTranslation } from 'APP/packages/translations';
import { RouterState } from 'APP/router/constants';
import useClearGetParameters from 'APP/router/hooks/useClearGetParameters';
import useGetState from 'APP/router/hooks/useGetState';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';
import { PopupHeader } from 'UIKIT/PopupHeader/PopupHeader';

import { NewUsersPopupUserItem } from '../NewUsersPopupUserItem/NewUsersPopupUserItem';
import { useNewUsersViewPresenter } from './NewUsersPopupView.presenter';
import styles from './NewUsersPopupView.styles.m.css';

export const NewUsersPopupView = observer(() => {
  const { t } = useTranslation();

  const usersIds = useGetState(RouterState.usersIds);
  const groupId = useGetState(RouterState.groupId);
  const messageId = useGetState(RouterState.messageId);

  const presenter = useNewUsersViewPresenter({ usersIds });
  const clearGetParameters = useClearGetParameters();

  const renderItem = useCallback(
    (userId) => {
      return (
        <NewUsersPopupUserItem
          userId={userId}
          groupId={groupId}
          messageId={messageId}
          closePopup={clearGetParameters}
        />
      );
    },
    [clearGetParameters]
  );

  return (
    <>
      <PopupHeader
        title={t('common_new_users')}
        onClose={clearGetParameters}
      />
      <div className={classNames(styles.container, customScrollStyles.container)}>
        <List
          isScrollable
          isLoading={presenter.isLoading}
          data={usersIds}
          keyExtractor={presenter.keyExtractor}
          renderItem={renderItem}
          SpinnerComponent={
            <GroupsPlaceholder
              count={presenter.placeholdersCount}
              className={styles.placeholders}
            />
          }
        />
      </div>
    </>
  );
});
