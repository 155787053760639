import { useLocalStore } from 'mobx-react';

import Entities from 'APP/store';

export function useSpaceBadgePresenter() {
  return useLocalStore(() => ({
    get hasNewMessages() {
      return Entities.GroupsStore.rawGroups.some(
        (group) => group.spaceId && (group.numberOfUnread > 0 || group.unread) && !group.muted
      );
    },
  }));
}
