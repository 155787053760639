import { TFunction } from 'react-i18next';

import {
  TSystemMessageTextHandler,
  ISystemMessageText,
  ISystemMessageTextPresenter,
} from '../useSystemMessageText.types';

export const chatLeftHandler: TSystemMessageTextHandler = (
  presenter: ISystemMessageTextPresenter,
  t: TFunction
): ISystemMessageText => {
  const result: ISystemMessageText = { html: '' };

  result.html = t('message_chat_left', {
    0: `<span class="system-message-title">${presenter.initiatorDisplayName}</span>`,
    interpolation: { escapeValue: false },
  });
  result.sourceText = t('message_chat_left');
  result.params = {
    '{0}': { text: presenter.initiatorDisplayName, userId: presenter.initiator?.id },
  };

  return result;
};
