import { action, computed, observable } from 'mobx';

import { PLATFORMS } from 'APP/constants';
import { PARTICIPANT_ROLE } from 'APP/constants/stream';

class Viewer {
  @observable role = null;
  @observable isAudioMuted = true;

  uid = null;
  userId = null;
  username = null;
  avatarUrl = null;
  shareScreenUid = null;
  platform = null;

  constructor(data) {
    this.uid = data.uid || this.uid;
    this.userId = data.userId || this.userId;
    this.username = data.username || this.username;
    this.avatarUrl = data.avatarUrl || this.avatarUrl;
    this.shareScreenUid = data.shareScreenUid || this.shareScreenUid;
    this.platform = data.platform || this.platform;
    this.role = data.role;
  }

  @computed
  get avatarColorIndex() {
    return (this.uid.toString() + '0')[0];
  }

  @computed
  get canBeStreamer() {
    return this.role === PARTICIPANT_ROLE.VIEWER && this.platform === PLATFORMS.WEB;
  }

  @computed
  get isStreamer() {
    return this.role === PARTICIPANT_ROLE.STREAMER;
  }

  get isViewer() {
    return this.role === PARTICIPANT_ROLE.VIEWER;
  }

  @action
  muteAudio() {
    if (this.isStreamer) {
      this.isAudioMuted = true;
    }
  }

  @action
  unmuteAudio() {
    if (this.isStreamer) {
      this.isAudioMuted = false;
    }
  }
}

export default Viewer;
