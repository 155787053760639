import { useAsObservableSource, useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { REACTION_POPOVER_HIDE_DELAY } from 'APP/constants/animations';
import { useTranslation } from 'APP/packages/translations';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import { MessageViewPageType } from 'MAIN/ChatView/Messages/Messages.types';
import Entities from 'STORE';
import { usePopover } from 'UIKIT/Popover/usePopover';

export default ({ message, messageViewPageType }) => {
  const navigateTo = useNavigateTo();
  const source = useAsObservableSource({ message });
  const { t } = useTranslation();

  const reactionsPopover = usePopover({ mouseOutTimeout: REACTION_POPOVER_HIDE_DELAY });

  const presenter = useLocalStore(() => ({
    startQuote() {
      if (!source.message.group?.canSendMessages) {
        Entities.toast.show(t('moderation_disable_send_message'));
        return;
      }

      Tasks.messaging.startQuoteMessage(source.message);
    },

    get isAllPinsPage() {
      return messageViewPageType === MessageViewPageType.AllPins;
    },

    get canBeQuoted() {
      return source.message.canBeQuoted && !presenter.isAllPinsPage;
    },

    get messageCanBeFocused() {
      return presenter.isAllPinsPage;
    },

    goToMessage() {
      if (source.message.group) {
        source.message.group.pinnedMessages.setPrevMessageIdOnFocusBased(source.message.id);

        Tasks.messaging.focusMessage({
          groupId: source.message.groupId,
          messageId: source.message.id,
          isAnimated: false,
        });
        navigateTo({ to: source.message.group.routePath });
      }
    },
  }));

  return { reactionsPopover, presenter };
};
