import * as PopperJS from '@popperjs/core';
import classNames from 'classnames';
import React, { FC, MouseEventHandler, ReactElement, UIEvent, useState } from 'react';
import { Popper } from 'react-popper';

import { ApplicationTheme } from 'APP/constants/appearance';

import { ClickOutside } from '../ClickOutside/ClickOutside';
import { DisableScroll } from '../DisableScroll/DisableScroll';
import { Portal } from '../Portal/Portal';
import styles from './Popover.styles.m.css';
import { TPopoverModifiers } from './Popover.types';

interface IPopoverProps {
  isOpened: boolean;
  onClose(e: MouseEvent): void;
  reference: HTMLElement | null;
  placement?: PopperJS.Placement;
  modifiers?: TPopoverModifiers;
  isScrollEnabled?: boolean;
  theme?: ApplicationTheme;
  className?: string;
  onMouseEnter?: MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: MouseEventHandler<HTMLDivElement>;
}

export const Popover: FC<IPopoverProps> = (props) => {
  const [popperRef, setRef] = useState<HTMLElement>();
  const {
    isOpened,
    onClose,
    reference,
    placement,
    modifiers = [],
    children,
    isScrollEnabled = false,
    theme,
    className,
    ...rest
  } = props;

  const handleStopPropagation = (event: UIEvent<HTMLDivElement, Event>): void => {
    event.stopPropagation();
  };

  if (!isOpened) {
    return null;
  }

  return (
    <Portal>
      <ClickOutside
        reference={popperRef}
        target={reference}
        onClickOutside={onClose}
      >
        <Popper
          innerRef={(node): void => setRef(node)}
          referenceElement={reference || undefined}
          placement={placement}
          modifiers={[
            {
              name: 'offset',
              options: { offset: [0, 8] },
            },
            ...modifiers,
          ]}
        >
          {({ ref, style, placement }): ReactElement => (
            <div
              ref={ref}
              style={style}
              className={classNames(styles.popover, styles[`popover--${theme}`], className)}
              data-placement={placement}
              //TODO remove those handler after upgrading react 17+
              onScroll={handleStopPropagation}
              onClick={handleStopPropagation}
              onDoubleClick={handleStopPropagation}
              {...rest}
            >
              {children}
            </div>
          )}
        </Popper>
      </ClickOutside>
      {!isScrollEnabled && <DisableScroll node={popperRef} />}
    </Portal>
  );
};
