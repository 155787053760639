import { spaceModel } from 'APP/model/model';
import { spaceAnalytics } from 'APP/packages/analytics/analytics';

import { removeSpaceLocally } from '../removeSpaceLocally/removeSpaceLocally';

export async function leaveSpace(spaceId: string): Promise<boolean> {
  try {
    await spaceModel.leaveSpace(spaceId);
    removeSpaceLocally(spaceId);
    spaceAnalytics.trackSpaceLeft(spaceId);
    return true;
  } catch {
    return false;
  }
}
