import { observer } from 'mobx-react';
import React from 'react';

import ScheduleIcon from 'ICONS/ic-schedule.svg';
import { FAB } from 'UIKIT/FAB/FAB';

import usePresenter from './presenter';
import styles from './styles.m.css';

const ScheduleButton = () => {
  const presenter = usePresenter();

  return (
    <FAB
      className={styles.container}
      Icon={ScheduleIcon}
      onClick={presenter.onClick}
      size="10"
      theme="2"
    />
  );
};

export default observer(ScheduleButton);
