import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import Entities from 'APP/store';
import { getFormatNumberToString } from 'UTILS/getFormatNumberToString';

export default ({ onClick }) => {
  const navigateTo = useNavigateTo();
  const source = useAsObservableSource({ navigateTo });

  const presenter = useLocalStore(() => ({
    get stream() {
      return Entities.ActiveStream.stream;
    },

    get group() {
      return Entities.GroupsStore.getGroupById(presenter.stream.groupId);
    },

    get message() {
      if (presenter.stream.messageId) {
        return presenter.group.messagesStore.getMessageById(presenter.stream.messageId);
      }
      return null;
    },

    get commentsCount() {
      if (presenter.message?.threadId) {
        const group = Entities.GroupsStore.getGroupById(presenter.message.threadId);
        if (group && group.counter?.totalMessagesCount) {
          return getFormatNumberToString(group.counter?.totalMessagesCount);
        }
      }
      return null;
    },

    updateThreadInfo() {
      if (Entities.App.isFocused && presenter.message?.threadId) {
        Tasks.thread.getThreadInfoById(presenter.message?.threadId);
      }
    },

    async goToThread() {
      if (presenter.message) {
        const group = await Tasks.thread.getOrCreateThread(presenter.message);
        if (group) {
          source.navigateTo({
            to: `/${group.route}/${group.id}`,
          });
          if (onClick) {
            onClick();
          }
        }
      }
    },
  }));

  useEffect(() => {
    const timer = setInterval(presenter.updateThreadInfo, 5000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return presenter;
};
