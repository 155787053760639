import { action, computed, observable } from 'mobx';

import InputPanel from './InputPanel';

class InputPanelsStore {
  @observable inputPanels = new Map();

  constructor(root) {
    this.root = root;
  }

  @action
  reset() {
    this.inputPanels.clear();
  }

  getById(groupId) {
    if (groupId && !this.inputPanels.has(groupId)) {
      this.inputPanels.set(groupId, new InputPanel(this.root, groupId));
    }

    return this.inputPanels.get(groupId);
  }

  deleteById(groupId) {
    if (!this.inputPanels.has(groupId)) {
      return;
    }

    this.inputPanels.delete(groupId);
  }

  @computed
  get activeInputPanel() {
    if (!this.root.GroupsStore.activeGroupId) {
      return null;
    }

    return this.getById(this.root.GroupsStore.activeGroupId);
  }
}

export default InputPanelsStore;
