import classNames from 'classnames';
import React, { FC } from 'react';

import StreamIcon from 'ICONS/ic-streaming-16.svg';
import { IconView, IconViewSizes } from 'UIKIT/IconView/IconView';

import styles from './JoinStreamingButton.styles.m.css';

interface IJoinStreamingButtonProps {
  className?: string;
  isActive?: boolean;
  onClick?(): void;
}

export const JoinStreamingButton: FC<IJoinStreamingButtonProps> = ({
  className,
  isActive,
  onClick,
}) => {
  return (
    <button
      className={classNames(styles.button, className, {
        [styles.canPress]: !!onClick,
      })}
      type="button"
      disabled={isActive}
      onClick={onClick}
    >
      <IconView
        className={styles.icon}
        Icon={StreamIcon}
        size={IconViewSizes.Small}
      />
      <span className={styles.label}>LIVE</span>
    </button>
  );
};
