import { action, computed, observable, ObservableMap, values } from 'mobx';

import { removeActiveMessageDownloads } from 'APP/Tasks/download/download';
import { DownloadProgress } from 'APP/store/Messages/DownloadProgress/DownloadProgress';
import { addNewBatch } from 'APP/utils/addNewBatch';

import { Group } from '../Groups/Group';
import { ChatMessage } from './Message/ChatMessage/ChatMessage';
import { MultiSelect } from './MultiSelect/MultiSelect';

export interface IMessagesScrollState {
  firstViewedMessageTs: number | null;
  offsetPosition: number | null;
  isBottom: boolean;
}

export class MessagesStore {
  @observable _messages = new ObservableMap<string, ChatMessage>();
  @observable batches: number[][] = []; // [ startTs, endTs ][]

  @observable _translations = new ObservableMap<string, ChatMessage>(); // key = message.id + message.editTime
  @observable _editedPayloads = new ObservableMap<string, ChatMessage['payload']>();
  @observable textMessage = '';
  @observable editedMessage = null;
  @observable isLoadingMessages = false;
  @observable focusedMessageId: string | null = null;
  @observable isLoadedFirstMessages = false;
  @observable isLoadedLastMessages = false;
  @observable newMessagesPlaceholderTs = 0;
  @observable saveStatePosition: Partial<IMessagesScrollState> = {
    firstViewedMessageTs: null,
    offsetPosition: null,
    isBottom: true,
  };

  public inViewMessageIds: string[] = [];

  private group: Group;

  public multiSelect: MultiSelect;
  public downloadProgressPerPayload: DownloadProgress;

  constructor(group: Group) {
    this.group = group;
    this.multiSelect = new MultiSelect(this);
    this.downloadProgressPerPayload = new DownloadProgress();
  }

  setSavePosition(scrollState: Partial<IMessagesScrollState>): void {
    this.saveStatePosition = scrollState;
  }

  @action
  handleRemoveMessages(messages: ChatMessage[]): void {
    messages.forEach((message) => {
      if (message.groupId !== this.group.id) {
        return;
      }

      this.removeMessageById(message.id);
    });
  }

  @action
  handleRestoreMessages(payload: ChatMessage['payload']): void {
    payload.messages.forEach((message: ChatMessage) => {
      if (message.groupId !== this.group.id) {
        return;
      }

      this.restoreMessage(message);
    });
  }

  @computed
  get messages(): ChatMessage[] {
    return values(this._messages)
      .slice()
      .sort((a, b) => a.expectedServerTime - b.expectedServerTime);
  }

  getBatch(ts: number): number[] | null {
    const batch = this.batches.find(([startTs, endTs]) => ts >= startTs && ts <= endTs);
    // if ts is less than the first batch and the first message is loaded
    if (!batch && this.isLoadedFirstMessages && this.batches.length && ts < this.batches[0][0]) {
      return this.batches[0];
    }

    // if ts is greater than the last match and the last message is loaded
    if (
      !batch &&
      this.isLoadedLastMessages &&
      this.batches.length &&
      ts > this.batches[this.batches.length - 1][1]
    ) {
      return this.batches[this.batches.length - 1];
    }

    return batch || null;
  }

  getBatchMessages(messageTs: number | null): ChatMessage[] {
    if (this.group.isFake) {
      return this.messages;
    }

    if (!messageTs) {
      return [];
    }

    const batch = this.getBatch(messageTs);

    if (!batch) {
      return [];
    }

    return (
      this.messages.filter(
        ({ expectedServerTime }) => expectedServerTime >= batch[0] && expectedServerTime <= batch[1]
      ) || []
    );
  }

  @action
  addBatch([startTS, endTS]: [number, number]): void {
    this.batches = addNewBatch(this.batches, [startTS, endTS]);
  }

  findMessageByClientUuid(clientUuid: string): ChatMessage | null {
    return values(this._messages).find((message) => message.clientUuid === clientUuid) || null;
  }

  getMessageById(id: string): ChatMessage | null {
    return this._messages.get(id) || this.findMessageByClientUuid(id) || null;
  }

  get translations(): ObservableMap<string, ChatMessage> {
    return this._translations;
  }

  get editedPayloads(): ObservableMap<string, ChatMessage['payload']> {
    return this._editedPayloads;
  }

  getMessageByIds(ids: string[]): ChatMessage[] {
    return ids.reduce((acc: ChatMessage[], id) => {
      const message = this._messages.get(id);
      if (message) {
        acc.push(message);
      }

      return acc;
    }, []);
  }

  getMessageByTs(messageTs: number | null): ChatMessage | null {
    if (!messageTs || messageTs <= 0) {
      return null;
    }

    let message = this.messages.find((message) => {
      return message.expectedServerTime === messageTs || message.serverTime === messageTs;
    });

    if (!message) {
      const batch = this.getBatch(messageTs);

      if (!batch) {
        return null;
      }

      // if there is a required batch, we take the neighboring message (prev or next by TS)
      const [startTs, endTs] = batch;
      message = this.messages.find((message, index) => {
        if (message.expectedServerTime >= startTs && message.expectedServerTime <= endTs) {
          // if first message in batch
          if (message.expectedServerTime > messageTs && messageTs <= startTs) {
            return message;
          }
          const nextMessage = this.messages[index + 1];
          // if communication should be between neighbors
          if (
            nextMessage &&
            message.expectedServerTime < messageTs &&
            nextMessage.expectedServerTime > messageTs
          ) {
            return message;
          }
          // if last message in batch
          if (!nextMessage) {
            return message;
          }
        }
        return false;
      });
    }
    return message || null;
  }

  getMessagesBetweenTs(startTs: number, endTs: number): ChatMessage[] {
    if (!startTs || !endTs) {
      return [];
    }

    const batchMessages = this.getBatchMessages(startTs);
    if (!batchMessages) {
      return [];
    }

    return batchMessages.filter(
      (message) => message.serverTime >= startTs && message.serverTime <= endTs
    );
  }

  @computed
  get isLoading(): boolean {
    return this.group.isActive && this.isLoadingMessages;
  }

  @computed
  get lastMessage(): ChatMessage {
    return this.messages[this.messages.length - 1];
  }

  @action
  setIsLoadingMessages(isLoadingMessages: boolean): void {
    this.isLoadingMessages = isLoadingMessages;
  }

  @action
  restoreMessage(message: ChatMessage): void {
    if (this._messages.has(message.id)) {
      return;
    }

    this._messages.set(message.id, message);
  }

  @action
  addMessage(message: ChatMessage): ChatMessage | null {
    if (message.isFake) {
      this._messages.set(message.id, message);
    } else {
      this._messages.set(message.id, new ChatMessage(message, this));
    }
    return this._messages.get(message.id) || null;
  }

  @action
  addTranslation(message: ChatMessage): void {
    this._translations.set(
      `${message.id}${message.editTime || ''}`,
      new ChatMessage(message, this)
    );
  }

  @action
  removeTranslation(message: ChatMessage): void {
    this._translations.delete(`${message.id}${message.editTime || ''}`);
  }

  @action
  setEditedPayload(message: ChatMessage): void {
    this._editedPayloads.set(message.id, message.payload);
  }

  @action
  removeEditedPayload(message: ChatMessage): void {
    this._editedPayloads.delete(message.id);
  }

  @action
  setLoadedFirstMessages(isLoadedFirstMessages: boolean): void {
    this.isLoadedFirstMessages = isLoadedFirstMessages;
  }

  @action
  setLoadedLastMessages(isLoadedLastMessages: boolean): void {
    this.isLoadedLastMessages = isLoadedLastMessages;
  }

  @action
  removeMessageById(messageId: string): void {
    const message = this._messages.get(messageId);

    if (!message) {
      return;
    }

    // remove empty batch
    if (message && this.getBatchMessages(message.serverTime).length === 1) {
      const batch = this.getBatch(message.serverTime);
      this.batches = this.batches.filter((x) => x !== batch);
    }

    this._messages.delete(messageId);

    if (message?.payload.cancelUploadFile) {
      message.payload.cancelUploadFile();
    }

    this.removeTranslation(message);
    this.removeEditedPayload(message);
    this.inViewMessageIds = this.inViewMessageIds.filter((id) => id !== messageId);
    removeActiveMessageDownloads(messageId, this.group.id);
    this.multiSelect.unselectMessage(messageId);
  }

  @action
  clear(): void {
    this._messages.clear();
    this.batches = [];
    this._translations.clear();
    this._editedPayloads.clear();
    this.setInViewMessageIds([]);
    this.unfocusMessage();
    this.setSavePosition({ isBottom: true });
    this.setLoadedFirstMessages(false);
    this.setLoadedLastMessages(false);
  }

  @action
  focusMessage(id: string): void {
    this.focusedMessageId = id;
  }

  @action
  unfocusMessage(): void {
    this.focusedMessageId = null;
  }

  @action
  setNewMessagesPlaceholderTs(newMessagesPlaceholderTs: number): void {
    this.newMessagesPlaceholderTs = newMessagesPlaceholderTs;
  }

  @action
  setInViewMessageIds(messageIds: string[]): void {
    this.inViewMessageIds = messageIds;
  }

  @action
  resetExpectedServerTime(): void {
    this.messages.forEach(
      (message) => (message.expectedServerTime = message.serverTime || message.expectedServerTime)
    );
  }
}
