import classNames from 'classnames';
import React, { ElementType, FC } from 'react';

import { IconButton } from 'APP/packages/UiKit/IconButton';

import styles from './CloseButton.styles.m.css';

interface ICloseButtonProps {
  className?: string;
  Icon: ElementType;
  onClick(): void;
}

export const CloseButton: FC<ICloseButtonProps> = ({ className, Icon, onClick }) => {
  return (
    <IconButton
      className={classNames(styles.buttonContainer, className)}
      iconClassName={styles.buttonIcon}
      Icon={Icon}
      onClick={onClick}
    />
  );
};
