import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { AudioPlayer } from 'APP/components/AudioPlayer/AudioPlayer';
import { AudioComponent } from 'APP/constants/audio';
import { ISharedDataItem } from 'APP/model/sharedData/sharedDataModel.types';
import { CircleVideo } from 'STORE/Messages/Message/Payload/CircleVideo/CircleVideo';

import { useCircleVideoItemPresenter } from './CircleVideoItem.presenter';

export const CircleVideoItem: FC<ISharedDataItem<CircleVideo>> = observer((props) => {
  const { id: payloadId, payload, groupId, messageId, serverTime } = props;
  const presenter = useCircleVideoItemPresenter({ groupId, messageId });

  if (!presenter.message) {
    return null;
  }

  return (
    <AudioPlayer
      fileName={presenter.title}
      serverTime={Number(serverTime)}
      duration={payload.duration}
      size={payload.fileSize}
      componentName={AudioComponent.SharedCircleVideo}
      messageId={messageId}
      groupId={groupId}
      payloadId={payloadId}
      clientUuid={payload.message.clientUuid}
      uploadProgress={null}
    />
  );
});
