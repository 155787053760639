import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { useTranslation } from 'APP/packages/translations';
import IcDeletePB from 'ICONS/ic-delete-pb.svg';

import styles from './NewContactButton.styles.m.css';

interface NewContactButtonProps {
  className?: string;
  onClick(): void;
}

export const NewContactButton: FC<NewContactButtonProps> = observer((props) => {
  const { className, onClick } = props;
  const { t } = useTranslation();

  return (
    <button
      className={classNames(styles.newContact, className)}
      onClick={onClick}
    >
      <IcDeletePB className={styles.newContactIcon} />
      <span className={styles.text}>{t('new_contact')}</span>
    </button>
  );
});
