import React, { FC } from 'react';

import PopupInfoCard from 'APP/packages/UiKit/PopupInfoCard';
import popupItemStyles from 'APP/packages/UiKit/PopupItem/styles.m.scss';
import { useTranslation } from 'APP/packages/translations';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';

interface IStickerCategoryProps {
  iconUrl: string;
  displayName: string;
  stickersCount: number;
}
export const StickerCategory: FC<IStickerCategoryProps> = ({
  iconUrl,
  displayName,
  stickersCount,
}) => {
  const { t } = useTranslation();

  return (
    <div className={popupItemStyles.container}>
      <PopupInfoCard
        type={AvatarTypes.Round_10}
        avatarTitle={displayName}
        avatarUrl={iconUrl}
        avatarBackgroundColorIndex="1"
        title={displayName}
        subtitle={t('amount_of_stickers_in_a_pack_few', { 0: stickersCount })}
      />
    </div>
  );
};
