import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, MouseEventHandler, useState } from 'react';

import { useFileDownloadStatePresenter } from 'APP/hooks/useFileDownloadState/useFileDownloadState';
import { FileIcon, FileIconState, getFileIconThemeByExt } from 'UIKIT/FileIcon/FileIcon';
import { getFileExt } from 'UTILS/mime';

import styles from './FileState.styles.m.css';

interface IFileStateProps {
  groupId: string;
  messageId: string;
  payloadId: string;
  className?: string;
  fileName?: string;
  onClick?: MouseEventHandler<SVGSVGElement | SVGGElement>;
}

export const FileState: FC<IFileStateProps> = observer((props) => {
  const { className, fileName, onClick, groupId, messageId, payloadId } = props;
  const [hover, setHover] = useState(false);

  const presenter = useFileDownloadStatePresenter({
    payloadId,
    groupId,
    messageId,
  });

  const fileExt = getFileExt(presenter.payload?.fileName || fileName);

  let state;

  if (presenter.hasError) {
    state = hover && onClick ? FileIconState.Hover : FileIconState.Error;
  } else if (presenter.isWaitingDownloadProcess) {
    state = FileIconState.Infinity;
  } else if (presenter.isDownloadInProcess) {
    state = FileIconState.Loading;
  } else if (presenter.isDownloadComplete) {
    state = hover && onClick ? FileIconState.Hover : FileIconState.Completed;
  } else {
    state = hover && onClick ? FileIconState.Hover : FileIconState.Regular;
  }

  return (
    <div className={className}>
      <FileIcon
        className={classNames({
          [styles.pointer]: !presenter.isDownloadInProcess && !presenter.isWaitingDownloadProcess,
        })}
        text={fileExt}
        theme={getFileIconThemeByExt(fileExt)}
        state={state}
        onClick={onClick}
        progress={presenter.downloadPercent}
        onCancel={(e): void => {
          e.stopPropagation();
          presenter.onAbort();
        }}
        onMouseEnter={(): void => setHover(true)}
        onMouseLeave={(): void => setHover(false)}
      />
    </div>
  );
});
