import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import { useLocalStore } from 'mobx-react';
import React, { useEffect } from 'react';

import { BUGSNAG_KEY } from 'APP/constants/app';
import * as features from 'APP/packages/features/features';
import Entities from 'APP/store';

export default () => {
  const presenter = useLocalStore(() => {
    if (!features.isBugsnagEnabled()) {
      return {
        isBugsnagEnabled: false,
        bugsnagClient: null,
      };
    }

    const bugsnagClient = bugsnag(BUGSNAG_KEY);
    bugsnagClient.use(bugsnagReact, React);
    bugsnagClient.app.version = Entities.App.version;

    if (Entities.UsersStore.Me.id) {
      bugsnagClient.user = { id: Entities.UsersStore.Me.id };
    }

    return {
      isBugsnagEnabled: true,
      bugsnagClient,
    };
  });

  useEffect(() => {
    if (presenter.isBugsnagEnabled && Entities.UsersStore.Me.id) {
      presenter.bugsnagClient.user = { id: Entities.UsersStore.Me.id };
    }
  }, [Entities.UsersStore.Me.id]);

  return presenter;
};
