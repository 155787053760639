import { observer } from 'mobx-react';
import React from 'react';

import ArticlePayload from 'APP/main/ChatView/Messages/Message/Payload/Article';
import Time from 'APP/main/ChatView/Messages/Message/bubbles/components/StatusBar/Time';
import { useContextMenu } from 'MAIN/hooks/useContextMenu/useContextMenu';

import { DraftsContextMenu } from '../ContextMenu/DraftsContextMenu';
import Menu from './Menu';
import styles from './styles.m.css';

const Item = ({ draft }) => {
  const { popover, onContextMenu, modifiers } = useContextMenu();
  return (
    <div
      className={styles.layout}
      ref={popover.containerRef}
      onContextMenu={onContextMenu}
      onClick={popover.close}
      role="button"
      tabIndex={0}
    >
      <div
        className={styles.draft}
        data-test="article-draft"
      >
        <div className={styles.container}>
          <div className={styles.bubble}>
            <ArticlePayload message={draft} />
            <div className={styles.footer}>
              <Time time={draft.editTime || draft.serverTime} />
            </div>
          </div>
        </div>
        <Menu
          key="menu"
          draft={draft}
        />
      </div>
      <DraftsContextMenu
        draft={draft}
        popover={popover}
        placement="bottom-start"
        modifiers={modifiers}
      />
    </div>
  );
};

export default observer(Item);
