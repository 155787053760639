import classNames from 'classnames';
import React, { ReactElement, useCallback } from 'react';

import styles from './FeedbackIssueButton.styles.m.css';

interface IFeedbackIssueButtonProps<TType> {
  issueType: TType;
  isActive?: boolean;
  text: string;
  onClick(value: TType): void;
}

export const FeedbackIssueButton = <TType extends string>(
  props: IFeedbackIssueButtonProps<TType>
): ReactElement => {
  const { issueType, isActive, onClick, text } = props;

  const onCLick = useCallback(() => {
    onClick(issueType);
  }, [issueType]);

  return (
    <button
      onClick={onCLick}
      className={classNames(styles.button, { [styles.active]: isActive })}
    >
      {text}
    </button>
  );
};
