import React, { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { useTranslation } from 'APP/packages/translations';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { useClosePopupOnNavigation } from '../../hooks/useClosePopupOnNavigation';
import { useBlockListPopupPresenter } from './BlockListPopup.presenter';
import { BlockListView } from './BlockListView/BlockListView';

export const BlockListPopup: FC<ICommonPopupsProps> = (props) => {
  const { t } = useTranslation();
  const presenter = useBlockListPopupPresenter(props);
  useClosePopupOnNavigation(props.popupInstance);

  return (
    <Popup
      title={t('settings_activity_block_list')}
      isOpened
      handlePrevStep={presenter.hasBackButton ? presenter.onBack : null}
      onClose={presenter.onClose}
    >
      <BlockListView />
    </Popup>
  );
};
