import { useAsObservableSource, useLocalStore } from 'mobx-react';

import { OnboardingFeatures } from 'APP/Tasks/authorization/login/handleClientConfig/handleOnboarding';
import { IStoryVideo } from 'APP/components/Stories/Stories.types';
import Entities from 'STORE';
import { Space } from 'STORE/Spaces/Space';

const COLLECTION_VIDEO_SIZE: IStoryVideo['size'] = { height: 1920, width: 1080 };

interface ISpaceCollectionOnboardingPresenter {
  isStoriesOpened: boolean;
  isShow: boolean;
  stories: IStoryVideo[];
  onOpenStories(): void;
  onCloseStories(): void;
  onEndStories(): void;
}

export function useSpaceCollectionOnboardingPresenter(
  space: Space
): ISpaceCollectionOnboardingPresenter {
  const source = useAsObservableSource({ space });

  const presenter = useLocalStore<ISpaceCollectionOnboardingPresenter>(() => ({
    isStoriesOpened: false,

    get isShow(): boolean {
      return source.space.isOwner && !!presenter.stories.length;
    },

    get stories(): IStoryVideo[] {
      const videoUrl = Entities.clientConfig.getConfig(OnboardingFeatures.CollectionsIntro);
      return videoUrl ? [{ url: videoUrl, size: COLLECTION_VIDEO_SIZE }] : [];
    },

    onOpenStories(): void {
      presenter.isStoriesOpened = true;
    },

    onCloseStories(): void {
      presenter.isStoriesOpened = false;
    },

    onEndStories(): void {
      presenter.isStoriesOpened = false;
    },
  }));

  return presenter;
}
