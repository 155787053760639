import React, { FC } from 'react';

import { useTranslation } from 'APP/packages/translations';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import { Dialog } from 'UIKIT/Dialog/Dialog';

export interface IAlertPopupParams {
  text?: string;
  title?: string;
  html?: string;
  primaryText?: string;
  hasCloseButton?: boolean;
}

export const AlertPopup: FC<ICommonPopupsProps<IAlertPopupParams>> = ({
  params,
  popupInstance,
  onClose,
}) => {
  const { t } = useTranslation();
  const { title, text, html, primaryText, hasCloseButton } = params!;

  const handleClose = (): void => {
    onClose?.();
    popupInstance.close();
  };

  return (
    <Dialog
      isOpened
      title={title}
      text={text}
      html={html}
      hasCloseButton={hasCloseButton}
      primaryText={primaryText || t('common_ok')}
      primaryOnClick={handleClose}
      onClose={handleClose}
    />
  );
};
