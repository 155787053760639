import { STREAM_STATUS } from 'APP/constants/stream';
import api from 'APP/packages/api';
import logger from 'APP/packages/logger';
import Entities from 'APP/store';

export default async ({ channelId }) => {
  try {
    const response = await api.streams.leaveFromStream({
      channelId,
    });

    Entities.ActiveStream.stream.updateStream({
      totalViewers: response.viewersTotal,
      status: STREAM_STATUS.FINISHED,
    });

    return response;
  } catch (e) {
    logger.get('API').error('streams.startStream', e);
    return null;
  }
};
