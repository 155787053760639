import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { useTranslation } from 'APP/packages/translations';
import { Button } from 'UIKIT/Button/Button';
import { PopupFooter } from 'UIKIT/PopupFooter/PopupFooter';

import { SelectCategoryForm } from './SelectCategoryForm/SelectCategoryForm';
import { useSelectCategoryPopupPresenter } from './SelectCategoryPopup.presenter';
import styles from './SelectCategoryPopup.styles.m.css';

interface IAddCategoryPopupProps {
  onClose?(): void;
}

export const SelectCategoryPopup: FC<IAddCategoryPopupProps> = observer(({ onClose }) => {
  const { t } = useTranslation();
  const presenter = useSelectCategoryPopupPresenter(onClose);

  return (
    <Popup
      className={styles.popup}
      isOpened={true}
      isShowHeader={true}
      onClose={presenter.onClose}
    >
      <SelectCategoryForm
        selected={presenter.selected}
        toggleSelection={presenter.toggleSelection}
      ></SelectCategoryForm>

      <PopupFooter>
        <Button
          isLoading={presenter.isSubmitting}
          isDisabled={presenter.isSubmitDisabled}
          onClick={presenter.onSubmit}
          theme="1"
        >
          {t('done')}
        </Button>
      </PopupFooter>
    </Popup>
  );
});
