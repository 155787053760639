import React, { FC } from 'react';

import { AddKeyPressHandler } from 'APP/packages/keyBoardHandler';
import { useTranslation } from 'APP/packages/translations';
import BackIcon from 'ICONS/ic-back.svg';
import IcDone from 'ICONS/ic-done.svg';

import { ITextFormattingMenuViewParams } from '../TextFormattingMenu.types';
import { TextFormattingMenuButton } from '../components/TextFormattingMenuButton/TextFormattingMenuButton';
import { TextFormattingMenuInput } from '../components/TextFormattingMenuInput/TextFormattingMenuInput';
import { useTextFormattingLinkViewPresenter } from './TextFormattingLinkView.presenter';
import styles from './TextFormattingLinkView.styles.m.css';

type ITextFormattingLinkViewProps = ITextFormattingMenuViewParams;

export const TextFormattingLinkView: FC<ITextFormattingLinkViewProps> = (props) => {
  const { t } = useTranslation();
  const presenter = useTextFormattingLinkViewPresenter(props);

  return (
    <>
      <AddKeyPressHandler
        isCaptured
        onEnter={presenter.onEnterKey}
      />

      <TextFormattingMenuButton
        theme={'1'}
        Icon={BackIcon}
        onClick={presenter.onBack}
      />
      <TextFormattingMenuInput
        className={styles.input}
        value={presenter.link}
        placeholder={t('paste_or_type')}
        onChange={presenter.onLinkChange}
      />
      <div className={styles.divider} />
      <TextFormattingMenuButton
        theme={'3'}
        Icon={IcDone}
        isDisabled={!presenter.canSubmit}
        onClick={presenter.onSubmit}
      />
    </>
  );
};
