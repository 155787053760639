import { localStorageInstance } from '../instances/localStorageInstance';

const KEY_NAME = 'GEM_VIDEO_PLAYER_CONFIG';

const set = (config) => {
  return localStorageInstance.setItem(KEY_NAME, config);
};

const get = () => {
  return localStorageInstance.getItem(KEY_NAME);
};

export default {
  set,
  get,
};
