import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Tasks from 'APP/Tasks';
import { PageType } from 'APP/router/MessengerRoutes/constants';
import Entities from 'APP/store';
import checkId from 'UTILS/checkId';

const groupPageTypes: PageType[] = [
  PageType.Chat,
  PageType.Channel,
  PageType.Thread,
  PageType.Schedule,
  PageType.AllPins,
];

export const useChangeActiveGroupPresenter = (pageType: PageType): void => {
  const params = useParams<{ id?: string }>();

  // HANDLE CHANGE URL
  useEffect(() => {
    const activeGroupId = Entities.GroupsStore.activeGroupId;

    if ((!params.id || !groupPageTypes.includes(pageType)) && activeGroupId !== null) {
      Tasks.group.setActiveGroup({ groupId: null });
      return;
    }

    if (
      groupPageTypes.includes(pageType) &&
      checkId.isId(params.id) &&
      params.id !== activeGroupId
    ) {
      Tasks.group.setActiveGroup({
        groupId: params.id,
        showAlert: true,
        isChannel: pageType === PageType.Channel,
      });
    }
  }, [params, pageType]);
};
