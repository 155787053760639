import React, { FC } from 'react';

import NoSavedMessageImage from 'APP/images/gem-mascot-saved-messages.svg';
import { useTranslation } from 'APP/packages/translations';
import { InfoBlock, InfoBlockTypes } from 'UIKIT/InfoBlock/InfoBlock';

import styles from './SavedMessagesEmpty.styles.m.css';

interface ISavedMessagesEmptyProps {
  hasBackground: boolean;
}

export const SavedMessagesEmpty: FC<ISavedMessagesEmptyProps> = ({ hasBackground }) => {
  const { t } = useTranslation();

  return (
    <InfoBlock
      className={styles.infoBlock}
      iconClassName={styles.icon}
      type={hasBackground ? InfoBlockTypes.ChatWithPattern : InfoBlockTypes.Chat}
      image={NoSavedMessageImage}
    >
      <h3 className={styles.title}>{t('saved_messages_description_title')}</h3>

      <ul className={styles.list}>
        <li className={styles.listItem}>
          <div className={styles.bullet} />
          {t('saved_messages_description_third')}
        </li>
        <li className={styles.listItem}>
          <div className={styles.bullet} />
          {t('saved_messages_description_one')}
        </li>
        <li className={styles.listItem}>
          <div className={styles.bullet} />
          {t('saved_messages_description_second')}
        </li>
      </ul>
    </InfoBlock>
  );
};
