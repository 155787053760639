import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, ReactElement } from 'react';
import { FormSpy } from 'react-final-form';

import { IMAGE_TYPE } from 'APP/constants';
import { Field } from 'APP/packages/form/Field/Field';
import { Form } from 'APP/packages/form/Form/Form';
import { IFormContentProps } from 'APP/packages/form/Form/Form.types';
import ImageUploader from 'APP/packages/form/ImageUploader';
import MultiLineField from 'APP/packages/form/MultiLineField';
import RadioGroup from 'APP/packages/form/RadioGroup';
import { TextField } from 'APP/packages/form/TextField/TextField';
import { useTranslation } from 'APP/packages/translations';
import { Avatar } from 'UIKIT/Avatar/Avatar';
import { AvatarTypes, TAvatarBackgroundColorIndex } from 'UIKIT/Avatar/Avatar.types';
import { Button } from 'UIKIT/Button/Button';
import { ButtonTypes } from 'UIKIT/Button/Button.types';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';
import { PopupFooter } from 'UIKIT/PopupFooter/PopupFooter';

import {
  IGroupFormInitialValues,
  TFormSubmitHandler,
  TFormUpdateHandler,
} from '../GroupForm.types';
import { useGroupFormGeneralPresenter } from './GroupFormGeneral.presenter';
import styles from './GroupFormGeneral.styles.m.css';
import { GroupFormGeneralDeepLinkField } from './GroupFormGeneralDeepLinkField/GroupFormGeneralDeepLinkField';
import { GroupFormGeneralMembers } from './GroupFormGeneralMembers/GroupFormGeneralMembers';

const FORM_ID = 'group-form';
const GROUP_NAME_MAX_LENGTH = 35;
const GROUP_DESCRIPTION_MAX_LENGTH = 400;

interface IGroupFormGeneralProps {
  initialValues: IGroupFormInitialValues;
  membersIds: string[];
  avatarTitle?: string;
  avatarColor?: TAvatarBackgroundColorIndex;
  isEditMode?: boolean;
  onUpdateGroupData: TFormUpdateHandler;
  onSubmit: TFormSubmitHandler;
  onFormStateChange(state: Partial<IFormContentProps>): void;
}

export const GroupFormGeneral: FC<IGroupFormGeneralProps> = observer((props) => {
  const {
    initialValues,
    membersIds,
    avatarTitle,
    avatarColor,
    isEditMode = false,
    onUpdateGroupData,
    onSubmit,
    onFormStateChange,
  } = props;

  const presenter = useGroupFormGeneralPresenter({ initialValues, isEditMode });
  const { t } = useTranslation();

  return (
    <Form
      initialValues={presenter.initialValues}
      subscription={{
        submitting: true,
        pristine: presenter.pristine,
        invalid: true,
        validating: true,
      }}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        submitting,
        pristine,
        invalid,
        validating,
      }: IFormContentProps): ReactElement => (
        <>
          <FormSpy
            subscription={{ submitting: true, pristine: presenter.pristine }}
            onChange={onFormStateChange}
          />

          <form
            id={FORM_ID}
            className={classNames(styles.form, customScrollStyles.container)}
            onSubmit={handleSubmit}
          >
            <div className={styles.fields}>
              <Field
                name="avatarUrl"
                component={ImageUploader}
                imageType={IMAGE_TYPE.ROUND}
                customNoImage={
                  avatarTitle &&
                  avatarColor && (
                    <Avatar
                      type={AvatarTypes.Round_40}
                      title={avatarTitle}
                      backgroundColorIndex={avatarColor}
                    />
                  )
                }
              />

              <Field
                isNeedTrim
                name="name"
                label={t('group_name_hint')}
                component={TextField}
                className={styles.gap}
                maxLength={GROUP_NAME_MAX_LENGTH}
                validate={presenter.nameValidate}
              />
              <Field
                isNeedTrim
                name="description"
                label={t('channel_description_hint')}
                component={MultiLineField}
                className={styles.gap}
                maxLength={GROUP_DESCRIPTION_MAX_LENGTH}
              />

              <RadioGroup
                className={styles.radioGap}
                name="type"
                title={t('group_creation_type')}
                options={presenter.radioOptions}
                validateFields={['deepLinkAlias']}
              />
              <Field
                isNeedTrim
                name="deepLinkAlias"
                component={GroupFormGeneralDeepLinkField}
                validate={presenter.aliasValidate}
              />
            </div>

            <GroupFormGeneralMembers membersIds={membersIds} />
          </form>

          <PopupFooter>
            <Button
              type={ButtonTypes.Submit}
              form={FORM_ID}
              isLoading={submitting}
              isDisabled={pristine || invalid || validating}
              theme="1"
            >
              {isEditMode ? t('common_save') : t('create_new_group_chat_button')}
            </Button>
          </PopupFooter>

          <FormSpy
            subscription={{ values: true }}
            onChange={onUpdateGroupData}
          />
        </>
      )}
    </Form>
  );
});
