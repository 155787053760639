import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { UserAvatar } from 'APP/components/Avatar/UserAvatar/UserAvatar';
import { useForwardMessage } from 'MAIN/ChatView/hooks/useForwardMessage/useForwardMessage';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';
import { Avatar } from 'UIKIT/Avatar/Avatar';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';

import { useSenderAvatarPresenter } from '../SenderAvatar.presenter';
import styles from './SenderAvatarForUser.styles.m.css';

interface ISenderUserAvatarProps {
  message: ChatMessage;
}

export const SenderAvatarForUser: FC<ISenderUserAvatarProps> = observer((props) => {
  const { message } = props;
  const presenter = useSenderAvatarPresenter(message);
  const forwardPresenter = useForwardMessage(message);

  if (presenter.isForwardToSavedMessages) {
    return (
      <button
        className={classNames({ [styles.avatar]: presenter.senderId })}
        onClick={forwardPresenter.onClick}
      >
        <Avatar
          type={AvatarTypes.Round_0}
          url={forwardPresenter.avatarUrl}
          title={forwardPresenter.avatarTitle}
          backgroundColorIndex={forwardPresenter.avatarColorIndex}
        />
      </button>
    );
  }

  return (
    <UserAvatar
      className={styles.avatar}
      id={presenter.senderId}
      withNavigate={true}
      initiatorSource={{ groupId: message.groupId, messageId: message.id }}
    />
  );
});
