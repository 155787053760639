import { observable } from 'mobx';

class CachedImages {
  @observable cachedImages = {};

  addCachedImage = (url, state = true) => {
    this.cachedImages[url] = state;
  };

  isCachedImage = (url) => {
    return this.cachedImages[url];
  };
}

export default CachedImages;
