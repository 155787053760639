import { useLocalStore } from 'mobx-react';

import { TSelectItemHandler, TChangeSelectionHandler } from 'APP/types/share';
import { IChipsItem } from 'UIKIT/Chips/ChipsItem/ChipsItem.types';

import { ISharedDataViewItemData } from './ShareDataView.types';

export interface ISharedDataViewParams {
  multiselect?: boolean;
  onSelectItem?: TSelectItemHandler;
  onChangeSelection: TChangeSelectionHandler;
}

interface ISharedDataViewPresenter {
  searchValue: string;
  selection: ISharedDataViewItemData[];
  onSearch(value: string): void;
  unSelectItem(item: IChipsItem | ISharedDataViewItemData): void;
  selectItem(item: ISharedDataViewItemData): void;
  onClickItem(item: ISharedDataViewItemData): void;
  onBeforeSelect(item: ISharedDataViewItemData): boolean;
  onSelect(): void;
}

const MAX_COUNT = 25;

export const useSharedDataViewPresenter = (
  data: ISharedDataViewParams
): ISharedDataViewPresenter => {
  const { multiselect, onSelectItem, onChangeSelection } = data;

  const presenter = useLocalStore<ISharedDataViewPresenter>(() => ({
    selection: [],
    searchValue: '',

    onSearch(value: string): void {
      presenter.searchValue = value;
    },

    onClickItem(item: ISharedDataViewItemData): void {
      const isSelected = presenter.selection.some((it) => item.id === it.id);
      if (isSelected && multiselect) {
        presenter.unSelectItem(item);
      } else {
        presenter.selectItem(item);
      }
    },

    unSelectItem(item: IChipsItem | ISharedDataViewItemData): void {
      presenter.selection = presenter.selection.filter((select) => select.id !== item.id);
      presenter.onSelect();
    },

    selectItem(item: ISharedDataViewItemData): void {
      if (presenter.selection.length >= MAX_COUNT) {
        return;
      }

      if (!presenter.onBeforeSelect(item)) {
        return;
      }

      if (!multiselect) {
        presenter.selection.length = 0;
      }

      presenter.selection.push(item);
      presenter.onSelect();
    },

    onBeforeSelect(item: ISharedDataViewItemData): boolean {
      if (!onSelectItem) {
        return true;
      }

      return onSelectItem({ data: item, type: item.itemType });
    },

    onSelect(): void {
      const selection = presenter.selection.map((item) => ({
        data: item,
        type: item.itemType,
      }));

      onChangeSelection(selection);
    },
  }));

  return presenter;
};
