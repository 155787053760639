import { MutableRefObject, useRef } from 'react';

import { useCreateEditSpaceMarketValidations } from 'APP/packages/validations/form/useCreateEditSpaceMarketValidations';

interface ISpaceMarketFormPresenter {
  marketNameValidateRef: MutableRefObject<any>; // TODO: fix any when validation will be re-write on TS
  marketLinkValidateRef: MutableRefObject<any>;
}

export function useSpaceMarketFormPresenter(): ISpaceMarketFormPresenter {
  const { marketName, marketLink } = useCreateEditSpaceMarketValidations();
  const marketNameValidateRef = useRef(marketName.validate);
  const marketLinkValidateRef = useRef(marketLink.validate);

  return { marketNameValidateRef, marketLinkValidateRef };
}
