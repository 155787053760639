import { useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { PayloadType } from 'APP/model/message/messageModel.types';

export const MEDIA_PREVIEW_SIZE = { width: 116, height: 116 };
export const PLACEHOLDER_QUALITY = 0.3;

export const useMediaItemPresenter = ({ payloadType, messageId, groupId, mediaId }) => {
  const presenter = useLocalStore(() => ({
    get isVideo() {
      return payloadType === PayloadType.Video;
    },

    openMediaGallery() {
      Tasks.group.openMediaGallery({
        groupId,
        mediaId,
        messageId,
        reverse: false,
      });
    },
  }));

  return presenter;
};
