import { useLocalStore } from 'mobx-react';

import routerConst from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import Entities from 'APP/store';

const LIMIT = 10;

export const useUsersPopoverPresenter = ({ usersIds, tooltip, groupId, messageId }) => {
  const navigateTo = useNavigateTo();

  const presenter = useLocalStore(() => ({
    get users() {
      return usersIds
        .slice(0, LIMIT)
        .map((userId) => Entities.UsersStore.getUserById(userId))
        .filter((x) => x);
    },

    get isOpened() {
      return presenter.users.length > 0;
    },

    get moreCount() {
      if (usersIds.length > LIMIT) {
        return usersIds.length - LIMIT;
      }

      return null;
    },

    onMoreClick() {
      const { state, stateEnum } = routerConst;

      navigateTo({
        state: {
          [state.popup]: stateEnum.popup.newUsers,
          [state.usersIds]: usersIds,
          [state.groupId]: groupId,
          [state.messageId]: messageId,
        },
      });

      tooltip.hide();
    },

    onUserClick() {
      tooltip.hide();
    },
  }));

  return presenter;
};
