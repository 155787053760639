import classNames from 'classnames';
import React, { FC, MouseEventHandler, MutableRefObject } from 'react';

import { ButtonTypes } from 'UIKIT/Button/Button.types';
import { IconViewSizes } from 'UIKIT/IconView/IconView.types';

import { IconView } from '../IconView/IconView';
import { Loader, LoaderColor } from '../Loader/Loader';
import styles from './styles.m.scss';
import { TIconButtonTheme } from './types';

export { IconViewSizes };

interface IIconButtonProps {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onContextMenu?(): void;
  onMouseEnter?(): void;
  onMouseLeave?(): void;
  isDisabled?: boolean;
  isLoading?: boolean;
  Icon: React.ElementType;
  hasBadge?: boolean;
  className?: string;
  iconClassName?: string;
  isActive?: boolean;
  size?: IconViewSizes;
  type?: ButtonTypes;
  iconSize?: IconViewSizes;
  theme?: TIconButtonTheme;
  loaderColor?: LoaderColor;
  setRef?: MutableRefObject<HTMLButtonElement | null>;
  dataTest?: string;
  title?: string;
  text?: string;
}

export const IconButton: FC<IIconButtonProps> = (props) => {
  const {
    onClick,
    onContextMenu,
    onMouseEnter,
    onMouseLeave,
    isDisabled,
    isLoading,
    Icon,
    hasBadge,
    className,
    iconClassName,
    isActive,
    size = IconViewSizes.Medium,
    type = ButtonTypes.Button,
    iconSize,
    theme = '2',
    loaderColor = 'secondary',
    setRef,
    dataTest,
    title,
    text,
  } = props;
  const containerClasses = classNames(
    styles.container,
    styles[size],
    {
      [styles.disabled]: isDisabled,
      [styles.active]: isActive,
    },
    styles[`theme-${theme}`],
    className
  );

  const iconClasses = classNames(styles.icon, iconClassName, {
    [styles.disabledIcon]: isDisabled || isLoading,
    [styles.hiddenIcon]: isLoading,
  });

  return (
    <button
      className={containerClasses}
      onClick={onClick}
      onContextMenu={onContextMenu}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      disabled={isDisabled || isLoading}
      ref={setRef}
      type={type}
      data-test={dataTest}
      title={title}
    >
      {hasBadge && <div className={styles.dotBadge} />}
      <IconView
        Icon={Icon}
        size={iconSize || size}
        className={iconClasses}
      />
      {text && <span className={styles.text}>{text}</span>}
      {isLoading && (
        <Loader
          color={loaderColor}
          className={styles.loader}
        />
      )}
    </button>
  );
};
