import { format } from 'bytes';
import { useAsObservableSource, useLocalStore } from 'mobx-react';

import { useFileDownloadStatePresenter } from 'APP/hooks/useFileDownloadState/useFileDownloadState';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';
import File from 'STORE/Messages/Message/Payload/File';

interface IFilePresenter {
  message: ChatMessage & { messageId: string };
}

export interface IFileLocalStore {
  formattedBytes: string;
}

export const useFilePresenter = ({ message }: IFilePresenter) => {
  const fileDownloadPresenter = useFileDownloadStatePresenter({
    groupId: message.groupId,
    messageId: message.messageId || message.id,
    payloadId: message.payload?.id || message.id,
  });

  const source = useAsObservableSource({ message, fileDownloadPresenter });

  const presenter = useLocalStore<IFileLocalStore>(() => ({
    get formattedBytes(): string {
      const { fileSize } = source.message.payload as File;
      const formattedSize = format(fileSize, { unitSeparator: ' ', decimalPlaces: 2 });
      const formattedLoadedBytes = format(source.fileDownloadPresenter.loaded, {
        unitSeparator: ' ',
        decimalPlaces: 2,
        fixedDecimals: true,
      });

      return source.fileDownloadPresenter.isDownloadInProcess
        ? `${formattedLoadedBytes} / ${formattedSize}`
        : formattedSize;
    },
  }));

  return { presenter, fileDownloadPresenter };
};
