/* global FileReader */
import classNames from 'classnames';
import React, { useState } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { IMAGE_TYPE } from 'APP/constants';
import { useTranslation } from 'APP/packages/translations';
import ImageCropper from 'MAIN/Popups/ContextPopups/ImageCropper';
import { Avatar } from 'UIKIT/Avatar/Avatar';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';
import { Button } from 'UIKIT/Button/Button';

import NoImage from './NoImage';
import styles from './styles.m.css';

const ACCEPTED_IMAGE_FORMATS = 'image/jpeg,image/png,image/jpg,image/svg+xml,image/x-icon';

const ImageUploader = ({
  className = '',
  input: { name, onChange, value },
  customNoImage = null,
  imageType = IMAGE_TYPE.ROUND,
}) => {
  const [cropImage, setCropImage] = useState(null);
  const { t } = useTranslation();

  const handleAddImage = (event) => {
    const [file] = event.target.files;
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      setCropImage(reader.result);
    };

    // need to upload the same file twice
    event.target.value = null;
  };

  const handleRemoveImage = () => onChange(undefined);
  const closeCropImagePopup = () => setCropImage(null);

  const hasImage = Boolean(value);
  const noImage = customNoImage || <NoImage type={imageType} />;
  const avatarType = imageType === IMAGE_TYPE.SQUARE ? AvatarTypes.Square_50 : AvatarTypes.Round_40;

  return (
    <>
      <div className={classNames(styles.container, className)}>
        <div className={styles.image}>
          {hasImage ? (
            <Avatar
              url={value}
              type={avatarType}
            />
          ) : (
            noImage
          )}
        </div>
        <div className={styles.content}>
          <div>
            <Button
              theme="1"
              className={styles.upload}
              size="0"
              tabIndex={-1}
            >
              <input
                name={name}
                onChange={handleAddImage}
                className={styles.fileInput}
                accept={ACCEPTED_IMAGE_FORMATS}
                type="file"
              />
              {hasImage ? t('avatar_update_button') : t('group_chat_photo_upload_button')}
            </Button>
            {hasImage && (
              <Button
                className={styles.removeAvatar}
                onClick={handleRemoveImage}
                size="0"
                theme="3"
              >
                {t('avatar_delete_button')}
              </Button>
            )}
          </div>
          <div className={styles.description}>{t('avatar_update_hint')}</div>
        </div>
      </div>
      <Popup
        isOpened={Boolean(cropImage)}
        onClose={closeCropImagePopup}
        title={t('change_profile_photo_pop_up_title')}
        overlayClassName={styles.overlay}
      >
        <ImageCropper
          image={cropImage}
          onClose={closeCropImagePopup}
          imageType={imageType}
          onUploadImage={(url) => {
            onChange(url);
            closeCropImagePopup();
          }}
        />
      </Popup>
    </>
  );
};

export default ImageUploader;
