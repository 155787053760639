import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Entities from 'STORE';

export const useResizeListenerPresenter = () => {
  const presenter = useLocalStore(() => ({
    updateSize: () => {
      const width = window.innerWidth || document.body.clientWidth;
      const height = window.innerHeight || document.body.clientHeight;

      Entities.App.setWidth(width);
      Entities.App.setHeight(height);

      const doc = document.documentElement;
      doc.style.setProperty('--app-width', `${width}px`);
      doc.style.setProperty('--app-height', `${height}px`);
    },
  }));

  useEffect(() => {
    presenter.updateSize();
    window.addEventListener('resize', presenter.updateSize);

    return () => {
      window.removeEventListener('resize', presenter.updateSize);
    };
  }, []);
};
