import { action, observable } from 'mobx';

import { TranscriptionStatus } from 'APP/constants/messages';
import { PayloadType } from 'APP/model/message/messageModel.types';
import { t } from 'MODULE/i18n/service';

import File from '../File';

class Voice extends File {
  histogram = '';
  @observable transcriptionText = null;
  @observable transcriptionStatus = TranscriptionStatus.TranscriptionSuccessful;

  constructor(data) {
    super(data);

    this.duration = data.duration;
    this.histogram = data.histogram;
    this.transcriptionText = data.transcriptionText;
    this.transcriptionStatus = data.transcriptionStatus || TranscriptionStatus.NotTranscribed;
  }

  @action
  setTranslationStatus(status) {
    this.transcriptionStatus = status;
  }

  get typeDescription() {
    return t('message_desctription_voice_message');
  }

  toJSON() {
    const data = super.toJSON();

    return {
      ...data,
      payloadType: PayloadType.VoiceMessage,
      duration: this.duration,
      url: this.url,
      histogram: this.histogram,
      transcriptionText: this.transcriptionText || null,
      transcriptionStatus: this.transcriptionStatus,
    };
  }
}

export default Voice;
