export function getHost(url: string): string {
  try {
    if (!/^https?:\/\//i.test(url)) {
      url = `https://${url}`;
    }

    const parsedUrl = new URL(url);
    return parsedUrl.hostname;
  } catch (err: any) {
    return '';
  }
}

export function getPathname(url: string): string {
  try {
    if (!/^https?:\/\//i.test(url)) {
      url = `https://${url}`;
    }

    const parsedUrl = new URL(url);
    return parsedUrl.pathname;
  } catch (err: any) {
    return '';
  }
}

export function matchPath(urls: Record<string, string>, link: string): boolean {
  return Object.values(urls).some((url) => {
    const urlParts = url.split('/');
    const linkParts = link.split('/');

    if (urlParts.length !== linkParts.length) {
      return false;
    }

    return urlParts.every((urlPart, index) => {
      const linkPart = linkParts[index];

      if (urlPart.startsWith(':') && linkPart) {
        return true;
      }

      return urlPart === linkPart;
    });
  });
}

export function getHref(link: string): string {
  return link.indexOf('//') >= 0 ? link : `//${link}`;
}
