const tagsForRemove = ['meta', 'b'];
const newLineTags = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'div', 'p'];

export default (htmlText, options) => {
  for (let tag of newLineTags) {
    htmlText = htmlText.replace(new RegExp(`<${tag}(.*?)>`, 'g'), '');
    htmlText = htmlText.replace(new RegExp(`</${tag}>`, 'g'), '\n');
  }

  htmlText = htmlText.replace(new RegExp(`<br(.*?)>`, 'g'), '\n');

  for (let tag of tagsForRemove) {
    htmlText = htmlText.replace(new RegExp(`<${tag}(.*?)>`, 'g'), '');
    htmlText = htmlText.replace(new RegExp(`</${tag}>`, 'g'), '');
  }

  const div = document.createElement('div');
  div.innerHTML = htmlText;
  const nodes = div.getElementsByTagName('*');

  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];

    const lowercaseTag = node.tagName.toLowerCase();

    // ignore empty nodes
    if ((options.ignoreImage || lowercaseTag !== 'img') && node.textContent === '') {
      htmlText = htmlText.replace(new RegExp(`<${lowercaseTag}(.*?)>`), '');
      htmlText = htmlText.replace(new RegExp(`</${lowercaseTag}>`), '');
      continue;
    }

    if (lowercaseTag === 'span') {
      let openTags = '';
      let closeTags = '';

      if (node.style['font-weight'] === '700') {
        openTags += '<b>';
        closeTags = '</b>' + closeTags;
      }

      if (node.style['font-style'] === 'italic') {
        openTags += '<i>';
        closeTags = '</i>' + closeTags;
      }

      if (node.style['text-decoration'].includes('underline')) {
        openTags += '<u>';
        closeTags = '</u>' + closeTags;
      }

      if (node.style['text-decoration'].includes('line-through')) {
        openTags += '<strike>';
        closeTags = '</strike>' + closeTags;
      }

      htmlText = htmlText.replace(new RegExp(`<span(.*?)>`), openTags);
      htmlText = htmlText.replace(new RegExp(`</span>`), closeTags);
      continue;
    }

    if (lowercaseTag === 'a') {
      htmlText = htmlText.replace(
        new RegExp(`<a(.*?)>`),
        `<a href="${node.getAttribute('href')}">`
      );
      continue;
    }

    if (!options.ignoreImage && lowercaseTag === 'img') {
      const replaceValue = ['img', node.width, node.height, node.src].join(',');
      htmlText = htmlText.replace(new RegExp(`<img(.*?) />`), `\n\n${replaceValue}\n\n`);
      continue;
    }

    htmlText = htmlText.replace(new RegExp(`<${lowercaseTag}(.*?)>`, 'g'), '');
    htmlText = htmlText.replace(new RegExp(`</${lowercaseTag}>`, 'g'), '');
  }

  return htmlText;
};
