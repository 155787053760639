import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { setHistory, setLocation } from 'APP/router/refs';

export function usePresenter() {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    setHistory(history);
  }, [history]);

  useEffect(() => {
    setLocation(location);
  }, [location]);
}
