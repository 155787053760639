import { useState } from 'react';

export default (onOpen, onClose) => {
  const [isPopupOpened, setIsOpen] = useState();

  const togglePopupVisibility = () => {
    setIsOpen((prevState) => {
      if (!prevState && onOpen) {
        onOpen();
      }

      if (prevState && onClose) {
        onClose();
      }

      return !prevState;
    });
  };

  return { isPopupOpened, togglePopupVisibility };
};
