import { ALERT_TYPES } from 'APP/constants/app';
import { MAX_COUNT_BROADCASTERS, MAX_COUNT_VIDEOS } from 'APP/constants/stream';
import Entities from 'APP/store';
import { t } from 'MODULE/i18n/service';

/**
 * @deprecated Do not add new code here!
 */
export function getAddAlertText(type: string, data?: any): string {
  const ALERT_TEXTS: Record<string, string> = {
    [ALERT_TYPES.SOMETHING_WRONG_ERROR]: t('common_somethings_wrong_error'),
    [ALERT_TYPES.SWITCH_ON_YOUR_CAMERA]: t('call_switch_on_your_camera'),
    [ALERT_TYPES.COMMON_UPLOAD_BROKEN_FILE]: t('common_upload_broken_file'),
    [ALERT_TYPES.CHAT_IS_NOT_ACCESSIBLE]: t('common_chat_is_not_accessible'),
    [ALERT_TYPES.CHANNEL_IS_NOT_ACCESSIBLE]: t('common_channel_is_not_accessible'),
    [ALERT_TYPES.MESSAGE_HAS_BEEN_DELETED]: t('message_has_been_deleted'),
    [ALERT_TYPES.DEEP_LINK_EXPIRED]: t('deep_link_expired'),
    [ALERT_TYPES.MULTISELECT_LIMIT_EXCEEDED]: t('multiselect_limit_exceeded'),
    [ALERT_TYPES.CALL_CONNECTION_ERROR]: t('calls_connection_error'),
    [ALERT_TYPES.CALL_LIMIT_EXCEED]: t('calls_group_call_limit_exceed_alert'),
    [ALERT_TYPES.CALL_JOIN_ON_ANOTHER_PLATFORM]: t('calls_join_on_another_platform'),
    [ALERT_TYPES.CALL_NO_MICROPHONE_CONNECTED]: t('calls_no_microphone_connected'),
    [ALERT_TYPES.CALL_NO_DYNAMIC_CONNECTED]: t('calls_no_dynamic_connected'),
    [ALERT_TYPES.CALL_NO_CAMERA_CONNECTED]: t('calls_no_camera_connected'),
    [ALERT_TYPES.CALL_NO_MICROPHONE_PERMISSIONS]: t('calls_no_microphone_permissions'),
    [ALERT_TYPES.CALL_NO_CAMERA_PERMISSIONS]: t('calls_no_camera_permissions'),
    [ALERT_TYPES.CALL_CANNOT_START_SHARING]: t('calls_sharing_alert'),
    [ALERT_TYPES.CALL_JOIN_ANOTHER_CALL]: t('calls_join_another_call'),
    [ALERT_TYPES.CALL_ERROR_APPEARED]: t('calls_error_appeared'),
    [ALERT_TYPES.P2P_CALL_NO_PERMISSION]: t('privacy_settings:privacy_settings_call_alert'),
    [ALERT_TYPES.NO_PERMISSIONS_TO_PERFORM_ACTION]: t('no_permission_alert'),
    [ALERT_TYPES.BANNED_USER_DEEPLINK_ERROR]: t('banned_user_deeplink_error'),
    [ALERT_TYPES.CHANNEL_IS_NOT_AVAILABLE]: t('channel_is_not_available'),
    [ALERT_TYPES.USER_BANNED_IN_CHANNEL]: t('banned_user_deeplink_error'),
    [ALERT_TYPES.DELETED_USER_PROFILE]: t('deleted_user_profile'),
    [ALERT_TYPES.STICKERS_WERE_DELETED]: t('stickers_were_deleted_alert'),
    [ALERT_TYPES.STREAMING_SUBSCRIPTION]: t('stream_alert_cant_join_without_subscription'),
    [ALERT_TYPES.RECORDING_IS_STARTED_ALREADY]: t('call_record_started_already_message'),
    [ALERT_TYPES.NO_PERMISSIONS_TO_RECORD_A_CALL]: t('call_record_no_permissions_to_start_message'),
    [ALERT_TYPES.SCREEN_SHARING_IS_RUNNING_BY_ANOTHER_USER]: t(
      'call_screensharing_is_already_running_message'
    ),
    [ALERT_TYPES.CHANNEL_CLOSED]: t('call_record_completed_call_message'),
    [ALERT_TYPES.RECORD_CAN_NOT_BE_STOPPED]: t('call_record_can_not_be_stopped_message'),
    [ALERT_TYPES.INVALID_PHONE_NUMBER]: t('invalid_phone_number'),
    [ALERT_TYPES.APP_VERSION_OUT_OF_DATE]: t('app_version_out_of_date_error'),
    [ALERT_TYPES.YOU_WERE_BANNED_ERROR]: t('you_were_banned'),
    [ALERT_TYPES.SMS_LIMIT_EXCEEDED]: t('sms_limit_exceeded'),
    [ALERT_TYPES.TOO_MANY_INVITED_MEMBERS]: t('common_invitation_daily_limit_alert'),
    [ALERT_TYPES.DAILY_SMS_LIMIT_EXCEEDED]: t('calls:daily_sms_limit_exceeded'),
    [ALERT_TYPES.PRIVATE_STICKER_ALERT]: t('common_private_stickers_alert'),
    [ALERT_TYPES.ONLY_WEB_PLATFORM_SUPPORTED_MANY_BROADCASTERS]: t(
      'call_invite_as_streamer_mobile_alert'
    ),
    [ALERT_TYPES.CALL_STREAMER_MAX_COUNT]: t('call_streamer_max_count_alert').replace(
      '{0}',
      `${MAX_COUNT_BROADCASTERS}`
    ),
    [ALERT_TYPES.CALL_STREAM_MAX_VIDEO_COUNT]: t('call_stream_max_video_alert').replace(
      '{0}',
      `${MAX_COUNT_VIDEOS}`
    ),
    [ALERT_TYPES.ARTICLE_WAS_PUBLISHED_ALERT]: t('channels:channel_schedule_editing_alert'),
    [ALERT_TYPES.SPACE_IS_NOT_ACCESSIBLE]: t('space_is_not_accessible'),
    [ALERT_TYPES.CALL_NO_PERMISSION]: t('call_no_permission'),
    [ALERT_TYPES.SPACE_ADMIN_KICK_OWNER]: t('space_admin_kick_owner_alert'),
    [ALERT_TYPES.SPACE_ADMIN_OWNERSHIP_ALERT]: t('space_admin_ownership_alert'),
    [ALERT_TYPES.ACCOUNT_UNLINK_ALERT]: t('account_unlink_alert'),
    [ALERT_TYPES.ACCOUNT_ALREADY_LINKED]: t('account_linked_another_device'),
    [ALERT_TYPES.ACCOUNT_LINKED_BY_ANOTHER_GEM_ACCOUNT]: t('account_linked_another_gem_user'),
    [ALERT_TYPES.ACCOUNT_ALREADY_UNLINKED]: t('account_is_already_unlinked'),
    [ALERT_TYPES.ACCOUNT_ADD_PHONE_UNAVAILABLE_NOW]: t('account_alert_add_phone_number'),
    [ALERT_TYPES.PRIVACY_ADD_TO_GROUP]: t('privacy_settings_add_contact_alert'),
    [ALERT_TYPES.PHONE_HAS_HIGH_RISK_SCORE]: t('you_were_banned'),
    [ALERT_TYPES.ATTEMPTS_EXCEEDED]: t('daily_sms_limit_exceeded'),
  };

  const text = ALERT_TEXTS[type];

  if (text) {
    return text;
  }

  switch (type) {
    case ALERT_TYPES.CALL_MAX_MEMBER_AMOUNT: {
      return t('calls_group_call_amount_alert').replace('{0}', data?.maxParticipants);
    }
    case ALERT_TYPES.CALL_BROADCASTERS_LIMITATION: {
      return t('calls_broadcasters_limitation').replace('{0}', data?.maxBroadcasters);
    }
    case ALERT_TYPES.YOU_WERE_BANNED: {
      return t('you_were_banned_alert').replace('{0}', data?.groupName || '');
    }
    case ALERT_TYPES.PRIVACY_VOICE_ALERT: {
      return t('common_privacy_voice_message_alert').replace('{0}', data?.userName || '');
    }
    case ALERT_TYPES.TRY_CALL_AGAIN_LATER: {
      return t('call_user_busy_alert').replace(
        '{0}',
        Entities.callInvitation?.invitationInfo?.userName || ''
      );
    }
    case ALERT_TYPES.P2P_CALL_OPPONENT_BLOCKED_BY_ME: {
      return t('block_user_alert_common').replace('{0}', data?.userName || '');
    }
    case ALERT_TYPES.P2P_CALL_OPPONENT_BLOCKED_ME: {
      return t('blocked_user_alert_common');
    }
  }

  return '';
}
