import React, { memo } from 'react';

import dateService from 'APP/packages/date';

import styles from './styles.m.css';

const Time = ({ time }) => {
  if (!time) {
    return null;
  }

  return (
    <span className={styles.time}>
      {dateService.format(time, {
        hour: 'numeric',
        minute: 'numeric',
      })}
    </span>
  );
};

export default memo(Time);
