import { IDisplayDataResponse, UserDisplayDataType } from 'APP/model/user/userModel.types';
import { DisplayDataResponse } from 'APP/packages/api/types/model/displayDataResponse';

export const mapAddToContactResponse = (
  usersDisplayData: DisplayDataResponse[]
): IDisplayDataResponse[] => {
  return usersDisplayData.map((userDisplayData) => ({
    ...userDisplayData,
    type: userDisplayData.type as string as UserDisplayDataType,
    deepLink: userDisplayData.deepLinkInfo?.deepLink || '',
  }));
};
