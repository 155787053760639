import Tasks from 'APP/Tasks';
import { RouterPage } from 'APP/router/constants';
import { navigateTo } from 'APP/router/refs/navigateTo';
import Entities from 'STORE';
import { IPushNotification } from 'STORE/PushNotifications/PushNotifications.types';

export const onSpaceNotificationClick = async (
  pushNotification: IPushNotification,
  notification: Notification
): Promise<void> => {
  Tasks.app.fullscreen.closeFullscreen();

  navigateTo({ to: `/${RouterPage.Spaces}/${pushNotification.spaceId}` });

  if (notification) {
    Entities.pushNotifications.removeNotificationByRef(notification);
  }

  Tasks.app.focusApp();
};
