import { request } from '../request/request';
import { BooleanResponse } from '../types/model/booleanResponse';
import { CursorRequest } from '../types/model/cursorRequest';
import { StickerPackRequest } from '../types/model/stickerPackRequest';
import { StickerPackResponse } from '../types/model/stickerPackResponse';
import { StickerPacksListResponse } from '../types/model/stickerPacksListResponse';
import { UserStickerPacksResponse } from '../types/model/userStickerPacksResponse';

const ROOT_API = 'stickers';

export const getPublicStickerPacks = (data: CursorRequest): Promise<StickerPacksListResponse> => {
  return request<StickerPacksListResponse>({
    rootApi: ROOT_API,
    url: '/getPublicStickerPacks',
    method: 'POST',
    body: data,
  });
};

export const getUserInstalledStickerPacks = (): Promise<UserStickerPacksResponse> => {
  return request<UserStickerPacksResponse>({
    rootApi: ROOT_API,
    url: '/getUserInstalledStickerPacks',
    method: 'POST',
  });
};

export const addStickerPackToProfile = (data: StickerPackRequest): Promise<BooleanResponse> => {
  return request<BooleanResponse>({
    rootApi: ROOT_API,
    url: '/addStickerPackToProfile',
    method: 'POST',
    body: data,
  });
};

export const getStickerPackById = (data: StickerPackRequest): Promise<StickerPackResponse> => {
  return request<StickerPackResponse>({
    rootApi: ROOT_API,
    url: '/getStickerPackById',
    method: 'POST',
    body: data,
  });
};

export const removeStickerPackFromProfile = (
  data: StickerPackRequest
): Promise<BooleanResponse> => {
  return request<BooleanResponse>({
    rootApi: ROOT_API,
    url: '/removeStickerPackFromProfile',
    method: 'POST',
    body: data,
  });
};
