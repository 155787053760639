import React from 'react';

import LocationPayload from 'APP/main/ChatView/Messages/Message/Payload/Location';

import usePresenter from './presenter';

const Location = ({ payload }) => {
  const presenter = usePresenter({ payload });

  return (
    <LocationPayload
      message={presenter.message}
      hasBubble={false}
    />
  );
};

export default Location;
