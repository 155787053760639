import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import ReactDOM from 'react-dom';
import Draggable from 'react-draggable';

import { RoundVideoPlayer } from 'UIKIT/Media/RoundVideoPlayer/RoundVideoPlayer';
import { VideoPlayer } from 'UIKIT/Media/VideoPlayer/VideoPlayer';

import { useGlobalVideoPlayerPresenter } from './GlobalVideoPlayer.presenter';
import styles from './GlobalVideoPlayer.styles.m.css';

const PREVENT_DRAG_SELECTORS = 'button, [role="progressbar"]';

export const GlobalVideoPlayer = observer(() => {
  const presenter = useGlobalVideoPlayerPresenter();

  const containerClass = classNames(styles.container, {
    [styles.mini]: presenter.isMini,
    [styles.round]: presenter.isRound,
    [styles.hidden]: presenter.isHidden,
  });

  if (!presenter.url) {
    return null;
  }

  return ReactDOM.createPortal(
    <Draggable
      cancel={PREVENT_DRAG_SELECTORS}
      bounds={'body'}
      disabled={!presenter.isDraggable}
      position={presenter.position}
      onStop={presenter.onDragEnd}
    >
      <div
        className={containerClass}
        onClickCapture={presenter.onChildrenClick}
      >
        {presenter.isRound ? (
          <RoundVideoPlayer
            url={presenter.url}
            previewUrl={presenter.previewUrl || undefined}
            isPlaying={presenter.isPlaying}
            progress={presenter.progress}
            config={presenter.playerConfig}
            onTogglePlay={presenter.onTogglePlay}
            onProgressUpdate={presenter.onProgressUpdate}
            onLoadedFirstFrame={presenter.onLoadedFirstFrame}
            onLoadedError={presenter.onLoadedError}
          />
        ) : (
          <VideoPlayer
            url={presenter.url}
            previewUrl={presenter.previewUrl}
            autoPlay={true}
            isPlaying={presenter.isPlaying}
            isFullScreenMode={presenter.isFullScreenMode}
            type={presenter.playerType}
            progress={presenter.progress}
            config={presenter.playerConfig}
            onTogglePlay={presenter.onTogglePlay}
            onToggleFullScreenMode={presenter.onToggleFullScreenMode}
            onConfigUpdate={presenter.setPlayerConfig}
            onShowMiniPlayer={presenter.canShowMiniPlayer ? presenter.onShowMiniPlayer : undefined}
            onExpandMiniPlayer={
              presenter.canExpandMiniPlayer ? presenter.onExpandMiniPlayer : undefined
            }
            onCloseMiniPlayer={presenter.onCloseMiniPlayer}
            onProgressUpdate={presenter.onProgressUpdate}
            onLoadedFirstFrame={presenter.onLoadedFirstFrame}
            onLoadedError={presenter.onLoadedError}
          />
        )}
      </div>
    </Draggable>,
    presenter.container
  );
});
