import React, { FC, memo } from 'react';

import { ApplicationTheme } from 'APP/constants/appearance';
import { useTranslation } from 'APP/packages/translations';
import { RadioLabel } from 'UIKIT/RadioLabel/RadioLabel';

import styles from './ThemeSelection.styles.m.css';

interface IThemeItem {
  labelKey: string;
  theme: ApplicationTheme | null;
}

const THEME_ITEMS: IThemeItem[] = [
  {
    labelKey: 'settings_dark_mode',
    theme: ApplicationTheme.Dark,
  },
  {
    labelKey: 'settings_light_mode',
    theme: ApplicationTheme.Light,
  },
  {
    labelKey: 'settings_system_mode',
    theme: null,
  },
];

interface IThemeSelectionProps {
  activeTheme: ApplicationTheme | null;
  onChange(theme: ApplicationTheme | null): void;
}

export const ThemeSelection: FC<IThemeSelectionProps> = memo(({ activeTheme, onChange }) => {
  const { t } = useTranslation();

  return (
    <div>
      <h6 className={styles.title}>{t('common_mode')}</h6>

      <div className={styles.items}>
        {THEME_ITEMS.map((item) => (
          <RadioLabel
            name="privacy-setting"
            key={item.theme}
            title={t(item.labelKey)}
            titleClassName={styles.label}
            isActive={item.theme === activeTheme}
            onChange={(): void => onChange(item.theme)}
          />
        ))}
      </div>
    </div>
  );
});
