import { memo } from 'react';

import usePresenter from './presenter';

const IsFocusedAppHandler = () => {
  usePresenter();

  return null;
};

export default memo(IsFocusedAppHandler);
