import React, { FC } from 'react';

import noop from 'UTILS/noop';

import { useMediaPlayerProgress } from '../hooks/useMediaPlayerProgress';
import { useMediaPlayerSpeed } from '../hooks/useMediaPlayerSpeed';
import { defaultAudioPlayerConfig } from './AudioPlayer.constants';
import { useAudioPlayerPresenter } from './AudioPlayer.presenter';
import { IAudioPlayerConfig, IAudioPlayerTrack } from './AudioPlayer.types';
import { useAudioPlayerTrackManager } from './AudioPlayerTrackManager.presenter';
import { AudioPlayerUI } from './AudioPlayerUI/AudioPlayerUI';

export * from './AudioPlayer.types';

export interface IAudioPlayerProps {
  className?: string;
  track?: IAudioPlayerTrack;
  autoPlay?: boolean;
  isPlaying?: boolean;
  config?: Partial<IAudioPlayerConfig>;
  isShowPlayList?: boolean;
  playList?: IAudioPlayerTrack[];
  isShowOrder?: boolean;
  isShowRepeat?: boolean;
  hasPrevious?: boolean;
  hasNext?: boolean;
  progress?: number | null;
  progressPercentage?: number | null;
  isPlayListOpened?: boolean;
  onTogglePlay?(isPlaying: boolean): void;
  onPrevious?(): void;
  onNext?(): void;
  onConfigUpdate?(config: Partial<IAudioPlayerConfig>): void;
  onClose?(): void;
  onTitleClick?(): void;
  onProgress?(progress: number, progressPercentage: number): void;
  onChangeTrack?(track: IAudioPlayerTrack): void;
  onTogglePlayList?(isOpened: boolean): void;
}

export const AudioPlayer: FC<IAudioPlayerProps> = ({
  className,
  track = null,
  autoPlay = false,
  isPlaying: newIsPlayingState = false,
  config: playerConfig,
  isShowPlayList = false,
  playList = [],
  isShowOrder = false,
  isShowRepeat = false,
  progress: newProgress = null,
  progressPercentage: newProgressPercentage = null,
  isPlayListOpened: newIsPlayListOpenedState = false,
  onTogglePlay = noop,
  onConfigUpdate = noop,
  onTitleClick = noop,
  onClose,
  onProgress = noop,
  onChangeTrack = noop,
  onTogglePlayList = noop,
}) => {
  const config = {
    ...defaultAudioPlayerConfig,
    ...playerConfig,
  };

  const {
    audioRef,
    isPlaying,
    isPlayListOpened,
    togglePlay,
    togglePlayList,
    closePlayList,
    play,
    pause,
  } = useAudioPlayerPresenter({
    track,
    autoPlay,
    newIsPlayingState,
    newIsPlayListOpenedState,
    onTogglePlay,
    onTogglePlayList,
  });

  const { progress, progressMax, setProgress, updateProgressMax, updateProgress } =
    useMediaPlayerProgress({
      url: track?.url || '',
      mediaRef: audioRef,
      newProgress,
      newProgressPercentage,
      onProgress,
    });

  const { setSpeed } = useMediaPlayerSpeed({
    url: track?.url || '',
    mediaRef: audioRef,
    config,
    onConfigUpdate,
  });

  const { hasNext, hasPrevious, onNext, onPrevious } = useAudioPlayerTrackManager({
    activeTrack: track,
    playList,
    onChangeTrack,
  });

  return (
    <AudioPlayerUI
      className={className}
      isPlaying={isPlaying}
      isShowPlayList={isShowPlayList}
      isPlayListOpened={isPlayListOpened}
      isShowOrder={isShowOrder}
      isShowRepeat={isShowRepeat}
      hasPrevious={hasPrevious}
      hasNext={hasNext}
      config={config}
      track={track}
      playList={playList}
      progress={progress}
      progressMax={progressMax}
      onPlay={play}
      onPause={pause}
      onTogglePlay={togglePlay}
      onTogglePlayList={togglePlayList}
      onClosePlayList={closePlayList}
      onChangeTrack={onChangeTrack}
      onChangeProgress={setProgress}
      onTitleClick={onTitleClick}
      onChangeConfig={onConfigUpdate}
      onChangeSpeed={setSpeed}
      onPrevious={onPrevious}
      onNext={onNext}
      onClose={onClose}
    >
      <audio
        src={track?.url}
        autoPlay={autoPlay}
        ref={audioRef}
        onLoadedMetadata={updateProgressMax}
        onTimeUpdate={updateProgress}
      />
    </AudioPlayerUI>
  );
};
