import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { CategoryButton } from 'APP/components/CategoryButton/CategoryButton';
import { useTranslation } from 'APP/packages/translations';
import { Button } from 'UIKIT/Button/Button';

import usePresenter from './presenter';
import styles from './styles.m.css';

const Personalize = ({ className, newsItem, onReloadNewsfeed }) => {
  const presenter = usePresenter({ newsItem, onReloadNewsfeed });
  const { t } = useTranslation();

  if (!presenter.isView) {
    return null;
  }

  return (
    <div className={classNames(className, styles.container)}>
      <div className={styles.header}>
        <div className={styles.head}>
          <h3 className={styles.title}>{t('news_feed_personalize_feed')}</h3>
          <p className={styles.description}>{t('channels_catalog_interested_categories_choose')}</p>
        </div>

        {presenter.isRefreshView && (
          <Button
            theme="1"
            size="20"
            className={styles.button}
            isLoading={presenter.isDisabled}
            isDisabled={presenter.isDisabled}
            onClick={presenter.onRefresh}
          >
            {t('news_feed_refresh_feed')}
          </Button>
        )}
      </div>

      <div className={styles.categoryList}>
        {presenter.categories.map((cat) => (
          <CategoryButton
            key={cat.id}
            category={cat}
            className={styles.category}
            isDisabled={presenter.isDisabled}
            isSelected={presenter.isSelected(cat.id)}
            onClick={presenter.getToggleSelectionHandler(cat.id)}
          />
        ))}
      </div>
    </div>
  );
};

export default observer(Personalize);
