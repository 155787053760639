function analyze({ text, positionToTag, startContent, endContent, tagsPositions }) {
  const tree = [];
  let innerTagPositions = [];
  let openedTag = null;
  let currentContentStartPosition = startContent;

  for (let i = 0; i < tagsPositions.length; i++) {
    const tagPosition = parseInt(tagsPositions[i]);
    const tagData = positionToTag[tagPosition];

    if (openedTag === null) {
      if (currentContentStartPosition < tagPosition) {
        tree.push({
          tag: 'text',
          content: text.substring(currentContentStartPosition, tagPosition),
        });

        currentContentStartPosition = tagPosition;
      }

      if (tagData.keyWord) {
        tree.push({
          tag: 'keyWord',
          content: text.substring(tagData.start, tagData.end),
          props: { type: tagData.keyWord.keyWordType },
        });

        currentContentStartPosition = tagData.end;
      } else if (tagData.tag === '@') {
        tree.push({
          tag: tagData.tag,
          content: text.substring(tagData.start, tagData.end),
          props: { mention: tagData.userMention },
        });

        currentContentStartPosition = tagData.end;
      } else if (tagData.tag === '/') {
        tree.push({
          tag: tagData.tag,
          content: text.substring(tagData.start, tagData.end),
          props: {},
        });

        currentContentStartPosition = tagData.end;
      } else if (tagData.tag === 'link') {
        tree.push({
          tag: tagData.tag,
          content: tagData.link,
          props: { link: tagData.link },
        });

        currentContentStartPosition = tagData.end;
      } else if (tagData.tag === 'email') {
        tree.push({
          tag: tagData.tag,
          content: tagData.email,
          props: { email: tagData.email },
        });

        currentContentStartPosition = tagData.end;
      } else {
        openedTag = {
          position: tagPosition,
          tag: tagData.tag,
        };
      }

      continue;
    }

    if (openedTag.tag !== '[' && openedTag.tag !== tagData.tag) {
      innerTagPositions.push(tagPosition);
      continue;
    }

    if (openedTag.tag === '[' && tagData.tag !== ']') {
      innerTagPositions.push(tagPosition);
      continue;
    }

    currentContentStartPosition = tagPosition + (tagData.end - tagData.start);

    const childStartContent = openedTag.position + openedTag.tag.length;
    const childEndContent = tagPosition;

    const props = {};

    if (tagData.link !== undefined) {
      props.link = tagData.link;
    }

    if (innerTagPositions.length === 0) {
      tree.push({
        tag: openedTag.tag,
        content: text.substring(childStartContent, childEndContent),
        props,
      });
    } else {
      tree.push({
        tag: openedTag.tag,
        content: analyze({
          text,
          positionToTag,
          startContent: childStartContent,
          endContent: childEndContent,
          tagsPositions: innerTagPositions,
        }),
        props,
      });

      innerTagPositions = [];
    }

    openedTag = null;
  }

  if (currentContentStartPosition < endContent) {
    tree.push({
      tag: 'text',
      content: text.substring(currentContentStartPosition, endContent),
    });
  }

  return tree;
}

export default ({ text, positionToTag }) => {
  const tagsPositions = Object.keys(positionToTag);

  if (tagsPositions.length === 0) {
    return [
      {
        tag: 'text',
        content: text,
      },
    ];
  }

  return analyze({
    text,
    positionToTag,
    startContent: 0,
    endContent: text.length,
    tagsPositions,
  });
};
