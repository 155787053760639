import React from 'react';

import { List } from 'APP/components/List/List';
import { GroupsPlaceholder } from 'APP/packages/placeholders/GroupsPlaceholder/GroupsPlaceholder';
import { useTranslation } from 'APP/packages/translations';

import styles from './style.m.scss';

const ContactsListWithStubs = (props) => {
  const { t } = useTranslation();
  const {
    isLoading,
    isSearchMode,
    NoContactsPlaceholder,
    contacts,
    renderItem,
    keyExtractor,
    willChange = false,
  } = props;

  return (
    <List
      isLoading={isLoading}
      willChange={willChange}
      data={contacts}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      SpinnerComponent={
        <GroupsPlaceholder
          count={10}
          className={styles.placeholders}
        />
      }
      ListEmptyComponent={
        <div className={styles.noContent}>
          {isSearchMode ? (
            <div className={styles.noContent}>{t('search_no_results')}</div>
          ) : (
            <NoContactsPlaceholder />
          )}
        </div>
      }
    />
  );
};

export default ContactsListWithStubs;
