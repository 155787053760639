import logger from 'APP/packages/logger';

export const CLIPBOARD_SUPPORTED = !!(window.navigator.clipboard && window.ClipboardItem);

const textCopyElement = document.createElement('textarea');
textCopyElement.setAttribute('readonly', '');
textCopyElement.tabIndex = -1;
textCopyElement.style.position = 'absolute';
textCopyElement.style.width = '0';
textCopyElement.style.height = '0';
textCopyElement.style.overflow = 'hidden';
textCopyElement.style.zIndex = '-1';

export const copyTextToClipboardFallback = (text: string): void => {
  textCopyElement.value = text;

  document.body.appendChild(textCopyElement);
  const selection = document.getSelection();

  if (selection) {
    const rangeToRestore = selection.rangeCount > 0 && selection.getRangeAt(0);
    textCopyElement.select();
    document.execCommand('copy');

    if (rangeToRestore) {
      selection.removeAllRanges();
      selection.addRange(rangeToRestore);
    }
  }

  document.body.removeChild(textCopyElement);
};

export const copyTextToClipboard = async (text: string): Promise<boolean> => {
  if (!CLIPBOARD_SUPPORTED) {
    copyTextToClipboardFallback(text);
    return true;
  }

  try {
    await window.navigator.clipboard.write([
      new ClipboardItem({
        'text/plain': new Blob([text], { type: 'text/plain' }),
      }),
    ]);
    return true;
  } catch (error) {
    logger.get('clipboard').error('copy text to clipboard', error);
    return false;
  }
};

export const copyHtmlTextToClipboard = async (html: string, text: string): Promise<boolean> => {
  if (!CLIPBOARD_SUPPORTED) {
    copyTextToClipboardFallback(text);
    return true;
  }

  try {
    await window.navigator.clipboard.write([
      new ClipboardItem({
        'text/plain': new Blob([text], { type: 'text/plain' }),
        'text/html': new Blob([html], { type: 'text/html' }),
      }),
    ]);
    return true;
  } catch (error) {
    logger.get('clipboard').error('copy html and text to clipboard', error);
    return false;
  }
};

export const copyImageToClipboard = async (imgUrl: string): Promise<boolean> => {
  if (!imgUrl || !CLIPBOARD_SUPPORTED) {
    return false;
  }

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const imageElement = new Image();

  imageElement.setAttribute('crossorigin', 'anonymous');

  const loadImage = (): Promise<null | string> =>
    new Promise((resolve, reject) => {
      imageElement.onload = (e: Event): void => {
        if (!ctx || !e.currentTarget) {
          return reject();
        }

        const img = e.currentTarget as HTMLImageElement;
        canvas.width = img.naturalWidth;
        canvas.height = img.naturalHeight;

        ctx.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight);
        canvas.toBlob(
          async (blob: Blob | null) => {
            const result = await copyBlobToClipboard(blob);
            if (!result) {
              return reject('cannot write blob to clipboard');
            }
            resolve(null);
          },
          'image/png',
          1
        );
      };

      imageElement.onerror = (): void => reject('cannot load image');

      imageElement.src = imgUrl;
    });

  try {
    await loadImage();
    return true;
  } catch (error) {
    logger.get('clipboard').error('copy image to clipboard', error);
    return false;
  }
};

export const copyBlobToClipboard = async (blob: Blob | null): Promise<boolean> => {
  if (!blob || !CLIPBOARD_SUPPORTED) {
    return false;
  }

  try {
    await window.navigator.clipboard.write([
      new ClipboardItem({
        [blob.type]: blob,
      }),
    ]);

    return true;
  } catch (error) {
    logger.get('clipboard').error('write blob format', error);

    return false;
  }
};
