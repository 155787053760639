import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { useTranslation } from 'APP/packages/translations';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import { useCloseFormPopup } from 'MAIN/PopupManager/hooks/useCloseFormPopup';

import {
  useCreateStreamPopupPresenter,
  ICreateStreamPopupPresenterParams,
} from './CreateStreamPopup.presenter';
import styles from './CreateStreamPopup.styles.m.css';
import { StreamForm } from './StreamForm/StreamForm';

type ICreateStreamPopupProps = ICreateStreamPopupPresenterParams;

export const CreateStreamPopup: FC<ICommonPopupsProps<ICreateStreamPopupProps>> = observer(
  (props) => {
    const { t } = useTranslation();
    const presenter = useCreateStreamPopupPresenter(props);
    const closeForm = useCloseFormPopup(props, t('common_cancel_action_confirmation'));

    return (
      <Popup
        className={styles.formPopup}
        isOpened
        title={t('new_stream_setting_title')}
        onClose={closeForm.closePopup}
      >
        <StreamForm
          onFormStateChange={closeForm.onFormStateChange}
          onSubmit={presenter.onSubmit}
        />
      </Popup>
    );
  }
);
