import { observer } from 'mobx-react';
import React, { FC } from 'react';

import MicIcon from 'ICONS/ic-mic-on.svg';
import { FAB } from 'UIKIT/FAB/FAB';

import { useVoiceButtonPresenter } from './VoiceButton.presenter';

interface IVoiceButtonProps {
  className?: string;
}

export const VoiceButton: FC<IVoiceButtonProps> = observer(({ className }) => {
  const presenter = useVoiceButtonPresenter();

  return (
    <FAB
      size={'30'}
      className={className}
      onClick={presenter.onStart}
      Icon={MicIcon}
      theme={'8'}
      isDisabled={presenter.isStarted}
    />
  );
});
