const padZero = (t) => {
  return t < 10 ? '0' + t : t;
};

export default (duration, currentTime) => {
  const allSeconds = duration ? Math.floor(duration) : 0;
  const currentSecond = currentTime ? Math.floor(currentTime) : 0;
  const res = allSeconds - currentSecond;

  const hours = parseInt((res / (60 * 60)) % 24, 10);
  const minutes = parseInt((res / 60) % 60, 10);
  const seconds = parseInt(res % 60, 10);
  // https://stackoverflow.com/questions/41073607/converting-audio-currenttime-to-accurate-time-display
  const result = `${hours ? `${padZero(hours)}:` : ''}${padZero(minutes)}:${padZero(seconds)}`;
  return result;
};
