import Entities from 'STORE';

import { ILoadNewsfeedData, loadNewsfeed } from '../loadNewsfeed/loadNewsfeed';

export async function preloadNewsfeed(data: ILoadNewsfeedData): Promise<boolean> {
  Entities.newsfeed.setError(false);
  Entities.newsfeed.setLoading(true);

  const newsResponse = await loadNewsfeed(data);

  if (!newsResponse) {
    Entities.newsfeed.setLoading(false);
    Entities.newsfeed.setError(true);

    return false;
  }

  Entities.newsfeed.addNews(newsResponse.news);
  Entities.newsfeed.setCursor(newsResponse.cursor);
  Entities.newsfeed.setLoading(false);

  return true;
}
