import { observer } from 'mobx-react';
import React, { FC } from 'react';

import GemMascotBadgeImage from 'APP/images/gem-mascot-badge.svg';
import { RewardProgressInfoRewardEnum } from 'APP/model/userSettings/userSettingsModel.types';
import { useTranslation } from 'APP/packages/translations';

import { RewardsBaseItem } from '../RewardsBaseItem/RewardsBaseItem';
import { useRewardsActiveUserPresenter } from './RewardsActiveUser.presenter';

interface IRewardsActiveUserProps {
  onOpenEditProfile(): void;
}

export const RewardsActiveUser: FC<IRewardsActiveUserProps> = observer(({ onOpenEditProfile }) => {
  const { t } = useTranslation();
  const presenter = useRewardsActiveUserPresenter();

  return (
    <RewardsBaseItem
      rewardType={RewardProgressInfoRewardEnum.UsernameBadge1}
      image={GemMascotBadgeImage}
      imageAlt={'get-mascot-badge'}
      title={t('common_active_user_reward_heading')}
      subTitle={t('common_active_user_reward_description')}
      progressPercent={presenter.rewardProgressPercent}
      maxPoints={presenter.rewardProgress?.maxPoints}
      currentPoints={presenter.rewardProgress?.currentPoints}
      achieved={presenter.rewardProgress?.achieved}
      onGetReward={onOpenEditProfile}
    />
  );
});
