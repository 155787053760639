const isId = (id) => {
  const numId = Number(id);
  return !isNaN(numId) && isFinite(numId) && numId > 0;
};

const isReal = (id) => {
  return isId(id) && id.toString().indexOf('.') === -1;
};

const isFake = (id) => {
  return isId(id) && id.toString().indexOf('.') > 0;
};

export default { isId, isReal, isFake };
