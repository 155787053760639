import { FC } from 'react';

import { ISharedDataViewItemData } from '../ShareDataView.types';

export enum SharedDataViewTabsType {
  All = 'all',
  Chats = 'chats',
  Channels = 'channels',
}

export type TContentComponent = FC<{
  searchValue: string;
  selection: ISharedDataViewItemData[];
  multiselect: boolean;
  onClickItem(item: ISharedDataViewItemData): void;
}>;

export const tabsMap: Record<SharedDataViewTabsType, string> = {
  [SharedDataViewTabsType.All]: 'chat_details_pending_filter_all',
  [SharedDataViewTabsType.Chats]: 'chats',
  [SharedDataViewTabsType.Channels]: 'channels',
};
