import { observer } from 'mobx-react';
import React, { useRef } from 'react';

import TextFormattingMenu from 'APP/components/TextFormattingMenu';

import { ArticleYouTubePreview } from '../../../../components/ArticleYouTubePreview/ArticleYouTubePreview';
import { useTextPayloadPresenter } from './TextPayload.presenter';
import styles from './TextPayload.styles.m.css';

export const TextPayload = observer((props) => {
  const ref = useRef();
  const { id, parentPresenter, payload, order, placeholder = null, isPayloadCreator } = props;
  const { presenter, textFormattingMenuInstance } = useTextPayloadPresenter({
    parentPresenter,
    payload,
    order,
    ref,
    isPayloadCreator,
    id,
  });

  return (
    <div className={styles.container}>
      {presenter.isShowText && (
        <>
          <div
            role="textbox"
            tabIndex="0"
            className={styles.text}
            ref={ref}
            placeholder={placeholder}
            contentEditable
            data-test="article-text-field"
            onInput={presenter.setValue}
            onKeyDown={presenter.onKeyDown}
            onFocus={presenter.onFocus}
            onDrop={presenter.onDrop}
            onPaste={presenter.onPaste}
            onClick={presenter.onClick}
            onMouseDown={presenter.onMouseDown}
          />

          <TextFormattingMenu
            reference={ref.current}
            textFormattingMenuInstance={textFormattingMenuInstance}
          />
        </>
      )}

      {presenter.isShowLinkPreview && (
        <ArticleYouTubePreview
          id={id}
          payload={presenter.payload}
        />
      )}
    </div>
  );
});
