import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { UserAvatar } from 'APP/components/Avatar/UserAvatar/UserAvatar';
import { useTranslation } from 'APP/packages/translations';
import { SearchInput } from 'UIKIT/SearchInput/SearchInput';

import {
  useSidebarPanelHeaderPresenter,
  TSearchChangeHandler,
} from './SidebarPanelHeader.presenter';
import styles from './SidebarPanelHeader.styles.m.css';

interface ISidebarPanelHeaderProps {
  onSearchChange: TSearchChangeHandler;
}

export const SidebarPanelHeader: FC<ISidebarPanelHeaderProps> = observer(({ onSearchChange }) => {
  const { t } = useTranslation();
  const presenter = useSidebarPanelHeaderPresenter(onSearchChange);

  return (
    <div className={styles.container}>
      <SearchInput
        className={styles.search}
        hasSearchIcon={false}
        value={presenter.searchValue}
        placeholder={t('main_menu_search_note')}
        dataTest={'group-search'}
        onChange={presenter.onSearchChange}
        onFocus={presenter.onSearchFocus}
      />

      <UserAvatar
        className={styles.profile}
        id={presenter.currentUserId}
        withNavigate={true}
        dataTest="my-profile"
      />
    </div>
  );
});
