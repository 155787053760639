import { observer } from 'mobx-react';
import React from 'react';

import { PopoverMenu } from 'UIKIT/PopoverMenu/PopoverMenu';

import usePresenter from './presenter';

const Menu = ({ parent, reference }) => {
  const presenter = usePresenter({ parent });

  return (
    <PopoverMenu
      isOpened={true}
      placement="top-start"
      menuItems={presenter.menuItems}
      reference={reference.current}
      onClose={parent.onCloseMenu}
    />
  );
};

export default observer(Menu);
