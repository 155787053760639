/**
 * Stream API
 * Documentation Stream API v1.0
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * users.online.user-changed
 */
export interface OnlineUserData { 
    userId: string;
    status: OnlineUserDataStatusEnum;
    lastOnlineTs: string;
    event: string;
}
export enum OnlineUserDataStatusEnum {
    Online = 'ONLINE',
    Offline = 'OFFLINE'
};



