import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, ReactElement } from 'react';
import { FormSpy } from 'react-final-form';

import { IMAGE_TYPE } from 'APP/constants';
import DropDown from 'APP/packages/form/DropDown';
import { Field } from 'APP/packages/form/Field/Field';
import { Form } from 'APP/packages/form/Form/Form';
import { IFormContentProps } from 'APP/packages/form/Form/Form.types';
import ImageUploader from 'APP/packages/form/ImageUploader';
import MultiLineField from 'APP/packages/form/MultiLineField';
import RadioGroup from 'APP/packages/form/RadioGroup';
import { TextField } from 'APP/packages/form/TextField/TextField';
import { useTranslation } from 'APP/packages/translations';
import { Avatar, TAvatarBackgroundColorIndex } from 'UIKIT/Avatar/Avatar';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';
import { Button } from 'UIKIT/Button/Button';
import { ButtonTypes } from 'UIKIT/Button/Button.types';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';
import { PopupFooter } from 'UIKIT/PopupFooter/PopupFooter';
import { PopupHeader } from 'UIKIT/PopupHeader/PopupHeader';

import { ChannelDeepLinkAliasField } from './ChannelDeepLinkAliasField/ChannelDeepLinkAliasField';
import { useChannelFormPresenter } from './ChannelForm.presenter';
import styles from './ChannelForm.styles.m.css';
import { IChannelFormData } from './ChannelForm.types';

const FORM_ID = 'channel-form';
const CHANNEL_NAME_MAX_LENGTH = 35;
const CHANNEL_DESCRIPTION_MAX_LENGTH = 400;
const CHANNEL_DONATION_URL_MAX_LENGTH = 75;

interface IChannelFormProps {
  title: string;
  initialValues: Partial<IChannelFormData>;
  avatarTitle?: string;
  avatarColor?: TAvatarBackgroundColorIndex;
  isEditMode?: boolean;
  onSubmit(channelData: IChannelFormData): void;
  onFormStateChange(state: Partial<IFormContentProps>): void;
  onClose(): void;
}

export const ChannelForm: FC<IChannelFormProps> = observer(
  ({
    title,
    initialValues,
    avatarTitle,
    avatarColor,
    isEditMode = false,
    onSubmit,
    onClose,
    onFormStateChange,
  }) => {
    const presenter = useChannelFormPresenter(initialValues, isEditMode);
    const { t } = useTranslation();

    return (
      <>
        <PopupHeader
          title={title}
          handlePrevStep={presenter.isShowPrevStep ? presenter.onShowGeneralInfo : undefined}
          onClose={onClose}
        />

        <Form
          initialValues={presenter.initialValues}
          subscription={{
            active: true,
            submitting: true,
            pristine: true,
            invalid: true,
            validating: true,
          }}
          onSubmit={presenter.isShowSubmit ? onSubmit : presenter.onShowPrivacy}
        >
          {({
            handleSubmit,
            submitting,
            pristine,
            invalid,
            validating,
          }: IFormContentProps): ReactElement => (
            <>
              <FormSpy
                subscription={{ submitting: true, pristine: true }}
                onChange={onFormStateChange}
              />

              <form
                id={FORM_ID}
                className={classNames(styles.form, customScrollStyles.container)}
                onSubmit={handleSubmit}
              >
                <div className={styles.fields}>
                  <div
                    className={classNames(
                      presenter.isShowGeneralInfo ? null : styles.hide,
                      isEditMode ? styles.sectionIndent : null
                    )}
                  >
                    <Field
                      name="avatarUrl"
                      component={ImageUploader}
                      imageType={IMAGE_TYPE.SQUARE}
                      customNoImage={
                        avatarTitle &&
                        avatarColor && (
                          <Avatar
                            type={AvatarTypes.Square_50}
                            title={avatarTitle}
                            backgroundColorIndex={avatarColor}
                          />
                        )
                      }
                    />
                    <Field
                      className={styles.gap}
                      isNeedTrim
                      name="name"
                      label={t('channel_name_hint')}
                      component={TextField}
                      maxLength={CHANNEL_NAME_MAX_LENGTH}
                      validate={presenter.nameValidate}
                    />
                    <Field
                      className={styles.gap}
                      name="categoryId"
                      label={t('chat_details_category_label')}
                      component={DropDown}
                      options={presenter.categories}
                      validate={presenter.categoryValidate}
                    />
                    <Field
                      className={styles.gap}
                      isNeedTrim
                      name="donationUrl"
                      label={t('channel_donation_link')}
                      component={TextField}
                      maxLength={CHANNEL_DONATION_URL_MAX_LENGTH}
                    />
                    <Field
                      isNeedTrim
                      name="description"
                      label={t('channel_description_hint')}
                      component={MultiLineField}
                      maxLength={CHANNEL_DESCRIPTION_MAX_LENGTH}
                    />
                  </div>
                  <div className={presenter.isShowPrivacy ? null : styles.hide}>
                    <RadioGroup
                      className={styles.radioGap}
                      name="type"
                      title={t('channel_type')}
                      options={presenter.radioOptions}
                      validateFields={['deepLinkAlias']}
                    />
                    <Field
                      isNeedTrim
                      name="deepLinkAlias"
                      component={ChannelDeepLinkAliasField}
                      validate={presenter.aliasValidate}
                      key={presenter.isShowPrivacy ? 1 : 0}
                    />
                  </div>
                </div>
              </form>
              <PopupFooter>
                <Button
                  type={ButtonTypes.Submit}
                  form={FORM_ID}
                  isLoading={submitting}
                  isDisabled={pristine || invalid || validating}
                  theme="1"
                >
                  {presenter.isShowSubmit ? t('common_save') : t('action_next')}
                </Button>
              </PopupFooter>
            </>
          )}
        </Form>
      </>
    );
  }
);
