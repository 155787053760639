import Tasks from 'APP/Tasks';
import { createScheduledGroup } from 'APP/Tasks/group/loadScheduledMessages/loadScheduledMessages';
import { UpdateReason } from 'APP/constants/scroll';
import Entities from 'APP/store';
import { MessageError } from 'STORE/Messages/Message/ChatMessage/ChatMessages.types';

import { clearInputPanel } from '../clearInputPanel';
import convertMessage from './convertMessage';
import sendMessage from './sendMessage';

const sendMessageHandler = (msg, result) => {
  if (!result && msg) {
    msg.setError(MessageError.Schedule);
  }
};

export const saveScheduledMessage = ({ message, date, groupId }) => {
  const group = Entities.GroupsStore.getGroupById(groupId);
  if (!group) {
    return false;
  }

  const scheduledGroup = createScheduledGroup(group);
  if (!scheduledGroup) {
    return false;
  }

  const msgData = convertMessage(groupId, message, date);
  Tasks.messaging.updateScheduledMessages({ groupId, messages: [msgData] });

  const newMsg = scheduledGroup.messagesStore.getMessageById(msgData.id);
  sendMessage(groupId, newMsg, sendMessageHandler.bind(this, newMsg));

  clearInputPanel({
    groupId: Entities.GroupsStore.activeGroupId,
    clearInput: true,
  });

  Entities.ChatStore.setScrollChanged(UpdateReason.ScrollToBottom, { isAnimated: true });

  return true;
};
