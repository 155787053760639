import classNames from 'classnames';
import React, { ElementType, FC } from 'react';

import { IconView } from 'APP/packages/UiKit/IconView/IconView';
import popupItemStyles from 'APP/packages/UiKit/PopupItem/styles.m.scss';

import styles from './AboutContentItem.styles.m.css';

interface IAboutContentItem {
  className?: string;
  Icon: ElementType;
  title: string;
  href: string;
  isOpenInNewTab: boolean;
}

export const AboutContentItem: FC<IAboutContentItem> = ({
  className,
  Icon,
  title,
  href,
  isOpenInNewTab,
}) => {
  const linkProps = {
    className: classNames(
      popupItemStyles.container,
      popupItemStyles.interactive,
      styles.container,
      className
    ),
    href,
    target: '',
    rel: '',
  };

  if (isOpenInNewTab) {
    linkProps.target = '_blank';
    linkProps.rel = 'noopener noreferrer';
  }

  return (
    <a {...linkProps}>
      <IconView
        Icon={Icon}
        className={styles.icon}
      />
      <span className={styles.title}>{title}</span>
    </a>
  );
};
