import { spaceModel } from 'APP/model/model';
import { ISpacePage, ISpacesPageRequest } from 'APP/model/space/spaceModel.types';
import Entities from 'STORE';

const SPACE_LOAD_LIMIT = 100;

async function request(data: ISpacesPageRequest): Promise<ISpacePage> {
  try {
    return await spaceModel.getSpacesPage(data);
  } catch {
    return { spaces: [], hasNext: false };
  }
}

export async function initSpaces(): Promise<void> {
  Entities.spacesStore.setLoading(true);

  const data: ISpacesPageRequest = { limit: SPACE_LOAD_LIMIT };
  let hasNext = true;

  while (hasNext) {
    const response = await request(data);
    hasNext = response.hasNext;
    data.startTs = response.lastUpdateTs;

    Entities.spacesStore.addSpaces(response.spaces);
    Entities.spacesStore.setSpaceTs(response.lastUpdateTs || '');
  }

  Entities.spacesStore.setLoading(false);
}
