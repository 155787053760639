import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

import { GroupAvatar } from 'APP/components/Avatar/GroupAvatar/GroupAvatar';
import { VerifiedBadge } from 'APP/components/VerifiedBadge/VerifiedBadge';
import { useTranslation } from 'APP/packages/translations';
import { RouterPage } from 'APP/router/constants';
import { Group } from 'STORE/Groups/Group';

import { ISearchResultItemProps } from '../SearchResultItem.types';
import styles from './SearchResultChannel.style.m.css';

export const SearchResultChannel: FC<ISearchResultItemProps<Group>> = observer(
  ({ entity: searchGroup, className, activeClassName }) => {
    const { t } = useTranslation();

    return (
      <NavLink
        className={className}
        activeClassName={activeClassName}
        to={`/${RouterPage.Channels}/${searchGroup.id}`}
      >
        <GroupAvatar group={searchGroup} />
        <div className={styles.content}>
          <div className={styles.top}>
            <div className={styles.header}>{searchGroup.avatarTitle}</div>
            {searchGroup.verified && <VerifiedBadge />}
          </div>
          <div className={styles.bottom}>
            <div className={styles.description}>
              {t('amount_of_subscribers_in_a_channel_many').replace(
                '{0}',
                searchGroup.usersCount.toString()
              )}
            </div>
          </div>
        </div>
      </NavLink>
    );
  }
);
