import React from 'react';

import CallIcon from 'ICONS/ic-call-32.svg';
import { IconButton } from 'UIKIT/IconButton';
import { IconViewSizes } from 'UIKIT/IconView/IconView.types';

import styles from './UserProfileJoinCallButton.m.css';

export const UserProfileJoinCallButton = ({ onClick }) => {
  return (
    <IconButton
      theme="10"
      Icon={CallIcon}
      size={IconViewSizes.Big}
      onClick={onClick}
      className={styles.activeCallButton}
    />
  );
};
