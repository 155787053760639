import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { ReactionItem } from 'APP/components/Reactions/ReactionItem/ReactionItem';
import { ApplicationTheme } from 'APP/constants/appearance';
import MoreIcon from 'ICONS/ic-more-vertical.svg';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';
import { FAB } from 'UIKIT/FAB/FAB';
import { Popover } from 'UIKIT/Popover/Popover';

import { useReactionsPopoverPresenter } from './ReactionsPopover.presenter';
import styles from './ReactionsPopover.styles.m.css';

interface IReactionsPopoverProps {
  message: ChatMessage;
  reference: HTMLElement | null;
  theme?: ApplicationTheme;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onClose: () => void;
}

export const ReactionsPopover: FC<IReactionsPopoverProps> = observer((props) => {
  const { message, reference, theme, onMouseEnter, onMouseLeave, onClose } = props;
  const presenter = useReactionsPopoverPresenter({ message, onClose });

  const containerClasses = classNames(styles.container, {
    [styles.fullViewContainer]: presenter.isFullView,
  });

  return (
    <Popover
      isOpened
      isScrollEnabled
      className={containerClasses}
      reference={reference}
      theme={theme}
      placement="top"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClose={onClose}
      modifiers={[
        {
          name: 'flip',
          enabled: false,
        },
        {
          name: 'preventOverflow',
          options: { padding: 8 },
        },
        {
          name: 'eventListeners',
          effect: (data) => presenter.onScroll(data),
          options: { scroll: true, resize: false },
        },
        {
          name: 'offset',
          options: { offset: (data) => presenter.onCalculateOffset(data) },
        },
      ]}
    >
      <div className={classNames(styles.content, customScrollStyles.container)}>
        {presenter.reactions.map((reaction) => (
          <ReactionItem
            key={reaction.id}
            reaction={reaction}
            theme="6"
            isAnimated={true}
            isAnimatedLoop={presenter.isFullView}
            onClick={(event): void => presenter.onClick(event, reaction.onClick)}
          />
        ))}

        {!presenter.isFullView && (
          <FAB
            size={'10'}
            Icon={MoreIcon}
            theme={'15'}
            onClick={presenter.onToggleView}
          />
        )}
      </div>
    </Popover>
  );
});
