import { spaceModel } from 'APP/model/model';
import { IAddSpaceCollectionItems, ISpaceCollectionItem } from 'APP/model/space/spaceModel.types';
import { spaceAnalytics } from 'APP/packages/analytics/analytics';

export async function addSpaceCollectionItems(
  data: IAddSpaceCollectionItems
): Promise<ISpaceCollectionItem[] | null> {
  try {
    const result = await spaceModel.addCollectionItems(data);

    spaceAnalytics.trackSpaceAddedToCollection(data.spaceId);

    return result;
  } catch {
    return null;
  }
}
