import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, MutableRefObject, useRef } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import { Popup } from 'APP/components/Popup/Popup';
import ArrowLeftIcon from 'ICONS/ic-arrow_left-16.svg';
import ArrowRightIcon from 'ICONS/ic-arrow_right-16.svg';
import CloseIcon from 'ICONS/ic-close.svg';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import { Button } from 'UIKIT/Button/Button';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';
import { IconButton } from 'UIKIT/IconButton';
import { IconViewSizes } from 'UIKIT/IconView/IconView.types';

import { useSpaceDemoPopupPresenter } from './SpaceDemoPopup.presenter';
import styles from './SpaceDemoPopup.styles.m.css';

const sliderTransitions = {
  enter: styles.slideEnter,
  enterActive: styles.slideEnterActive,
  exit: styles.slideExit,
  exitActive: styles.slideExitActive,
};

export const SpaceDemoPopup: FC<ICommonPopupsProps> = observer(({ popupInstance }) => {
  const presenter = useSpaceDemoPopupPresenter(popupInstance);
  const nodeRef: MutableRefObject<HTMLDivElement | null> = useRef(null);

  return (
    <Popup
      className={styles.popup}
      isOpened={true}
      onClose={presenter.onSkipOnboarding}
    >
      <SwitchTransition>
        <CSSTransition
          classNames={sliderTransitions}
          nodeRef={nodeRef}
          key={presenter.currentSlideIndex}
          addEndListener={(done): void =>
            nodeRef.current?.addEventListener('transitionend', done, false)
          }
        >
          <div
            className={classNames(styles.slide, customScrollStyles.container)}
            ref={nodeRef}
          >
            <img
              className={styles.image}
              src={presenter.slide.image}
              alt=""
            />

            <h3 className={styles.title}>{presenter.slide.title}</h3>

            <div className={styles.description}>{presenter.slide.description}</div>

            {presenter.slide.buttonTitle && (
              <Button
                className={styles.action}
                size="10"
                theme="14"
                onClick={presenter.slide.onButtonClick}
              >
                {presenter.slide.buttonTitle}
              </Button>
            )}
          </div>
        </CSSTransition>
      </SwitchTransition>

      <div className={styles.stepper}>
        {presenter.slides.map((stepConfig, index) => (
          <button
            className={classNames(styles.step, {
              [styles.active]: index === presenter.currentSlideIndex,
            })}
            key={stepConfig.title}
            type="button"
            onClick={(): void => presenter.onSelectSlide(index)}
          >
            <div className={styles.stepPin}></div>
          </button>
        ))}
      </div>

      {presenter.isShowPrevButton && (
        <IconButton
          className={styles.leftButton}
          Icon={ArrowLeftIcon}
          size={IconViewSizes.Big}
          iconSize={IconViewSizes.Medium}
          theme="23"
          onClick={presenter.onPrevSlide}
        />
      )}

      {presenter.isShowNextButton && (
        <IconButton
          className={styles.rightButton}
          Icon={ArrowRightIcon}
          size={IconViewSizes.Big}
          iconSize={IconViewSizes.Medium}
          theme="23"
          onClick={presenter.onNextSlide}
        />
      )}

      <IconButton
        className={styles.close}
        Icon={CloseIcon}
        theme="21"
        onClick={presenter.onSkipOnboarding}
      />
    </Popup>
  );
});
