import { useLocalStore } from 'mobx-react';

import { ProviderUid } from 'APP/packages/callProviders/callProviders.types';
import Entities from 'APP/store';
import { Call } from 'STORE/Calls/Call/Call';
import { Opponent } from 'STORE/Calls/Call/Opponent/Opponent';

interface IOpponentsVideo {
  opponentsKeys: ProviderUid[];
  ongoingCall: Call;
}

export const useOpponentsVideo = (isGridLayout?: boolean): IOpponentsVideo => {
  const presenter = useLocalStore<IOpponentsVideo>(() => ({
    get ongoingCall(): Call {
      return Entities.Calls.ongoingCall!;
    },

    get opponentsKeys(): ProviderUid[] {
      const activeOpponent = Entities.OngoingCall.presentVideoMode.activeVideoOpponent;

      const opponentsInitialPositions = isGridLayout
        ? presenter.ongoingCall.opponents.opponentList
        : presenter.ongoingCall.opponents.opponentList.filter(
            (opponent: Opponent) => opponent.uid !== activeOpponent?.uid
          );

      return opponentsInitialPositions.map((item: Opponent) => item.uid).sort();
    },
  }));

  return presenter;
};
