import classNames from 'classnames';
import React, { FC } from 'react';

import noop from 'UTILS/noop';

import { useMediaPlayerSpeed } from '../hooks/useMediaPlayerSpeed';
import { useRoundVideoPlayerPresenter } from './RoundVideoPlayer.presenter';
import styles from './RoundVideoPlayer.styles.m.css';
import { IRoundVideoPlayerConfig } from './RoundVideoPlayer.types';
import { RoundVideoPlayerControls } from './RoundVideoPlayerControls/RoundVideoPlayerControls';
import { RoundVideoPlayerProgress } from './RoundVideoPlayerProgress/RoundVideoPlayerProgress';
import { RoundVideoPlayerSource } from './RoundVideoPlayerSource/RoundVideoPlayerSource';

interface IRoundVideoPlayerProps {
  className?: string;
  url: string;
  previewUrl?: string;
  isPlaying?: boolean;
  progress?: number | null;
  config?: Partial<IRoundVideoPlayerConfig>;
  onTogglePlay?(isPlaying: boolean): void;
  onProgressUpdate?(progress: number): void;
  onLoadedFirstFrame?(): void;
  onLoadedError?(): void;
}

const defaultRoundVideoPlayerConfig: IRoundVideoPlayerConfig = {
  speed: 1,
};

export const RoundVideoPlayer: FC<IRoundVideoPlayerProps> = (props) => {
  const {
    className,
    url,
    previewUrl,
    isPlaying = false,
    progress = null,
    config = {},
    onTogglePlay = noop,
    onProgressUpdate = noop,
    onLoadedFirstFrame = noop,
    onLoadedError = noop,
  } = props;

  const playerConfig = {
    ...defaultRoundVideoPlayerConfig,
    ...config,
  };

  const { videoRef, presenter } = useRoundVideoPlayerPresenter({
    url,
    isPlaying,
    progress,
    onTogglePlay,
    onProgressUpdate,
    onLoadedFirstFrame,
    onLoadedError,
  });

  useMediaPlayerSpeed({
    url,
    mediaRef: videoRef,
    config: playerConfig,
  });

  return (
    <div className={classNames(styles.container, className)}>
      <video
        className={styles.video}
        poster={previewUrl}
        ref={videoRef}
        preload="metadata"
        onLoadedData={presenter.onLoadedVideoData}
        onTimeUpdate={presenter.onUpdateProgress}
        onError={presenter.onLoadVideoError}
        onPlaying={presenter.onPlayingLoadedVideo}
        onWaiting={presenter.onWaitingLoadVideo}
        onEnded={presenter.onEndedVideo}
      >
        <RoundVideoPlayerSource url={url} />
      </video>

      <RoundVideoPlayerProgress
        className={styles.controls}
        isPlaying={presenter.isPlaying}
        progress={presenter.progress}
        speed={playerConfig.speed}
      />

      <RoundVideoPlayerControls
        className={styles.controls}
        isPlaying={presenter.isPlaying}
        isLoading={presenter.isLoading}
        onTogglePlay={presenter.onTogglePlay}
      />
    </div>
  );
};
