import { useTranslation } from 'APP/packages/translations';

export function usePresenter({ message }) {
  const { t } = useTranslation();

  return {
    get isShow() {
      return !message.fromMe && !message.isArticleDraft && !message.isFakeGroup;
    },

    get isShowButton() {
      return !message.isLoadingTranslate;
    },

    get isShowLabel() {
      return message.isLoadingTranslate || (message.hasTranslation && message.isShowTranslate);
    },

    get buttonLabel() {
      if (!message.hasTranslation) {
        return t('common_translate');
      }

      return message.isShowTranslate ? t('show_original_button') : t('common_translate');
    },

    get label() {
      let label = null;
      if (message.isLoadingTranslate) {
        label = t('common_loading');
      } else if (message.isShowTranslate) {
        label = t('translated_label');
      }

      return label;
    },

    onTranslate() {
      if (message.hasTranslation) {
        message.toggleTranslate();
      } else {
        message.translate();
      }
    },
  };
}
