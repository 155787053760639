import { mapFileIconThemeByFileExt, TFileTheme } from 'UIKIT/FileIcon/FileIcon.types';

export const getFileIconThemeByExt = (ext: string): TFileTheme => {
  const theme = mapFileIconThemeByFileExt[ext];

  if (!theme) {
    return '130';
  }

  return theme;
};
