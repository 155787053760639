import { DeleteAllMessagesFromUserEventPayloadResponse } from 'APP/packages/api/types/model/deleteAllMessagesFromUserEventPayloadResponse';
import Entities from 'STORE';
import { Group } from 'STORE/Groups/Group';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

import { deleteGroupMessages } from '../../helpers/deleteGroupMessages';
import { GroupsEvent } from '../GroupsEvent';

export class DeleteMessagesFromUser extends GroupsEvent<DeleteAllMessagesFromUserEventPayloadResponse> {
  process(): void {
    const { groupId, userId } = this.eventPayload || {};

    if (!groupId || !userId) {
      return;
    }

    const group = Entities.GroupsStore.getGroupById(groupId);

    if (!group) {
      return;
    }

    this.deleteMessagesForGroup(group, userId);

    const threadIds: string[] = group.messagesStore.messages
      .filter((message: ChatMessage) => !!message.threadId)
      //TODO remove when ChatMessage will be moved to TS
      .map((message: ChatMessage) => message.threadId as unknown as string);

    threadIds.forEach((threadId: string) => {
      const thread = Entities.GroupsStore.getGroupById(threadId);

      if (thread) {
        this.deleteMessagesForGroup(thread, userId);
      }
    });
  }

  private deleteMessagesForGroup(group: Group, userId: string): void {
    const messageIds = group.messagesStore.messages
      .filter((message: ChatMessage) => message.senderId === userId)
      .map((message: ChatMessage) => message.id);

    deleteGroupMessages(group.id, messageIds);
  }
}
