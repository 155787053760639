import React, { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { Button, ButtonTypes } from 'APP/packages/UiKit/Button/Button';
import { useTranslation } from 'APP/packages/translations';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { useChangePhoneAlertPopupPresenter } from './ChangePhoneAlertPopup.presenter';
import styles from './ChangePhoneAlertPopup.styles.m.css';

export interface IChangePhoneAlertPopupParams {
  phoneNumber: string;
  onChangeClick(): void;
}

export const ChangePhoneAlertPopup: FC<ICommonPopupsProps<IChangePhoneAlertPopupParams>> = ({
  popupInstance,
  params,
}) => {
  const { t } = useTranslation();

  const presenter = useChangePhoneAlertPopupPresenter({
    popupInstance,
    onChangeClick: params!.onChangeClick,
  });

  return (
    <Popup
      isOpened
      isShowHeader
      headerClassName={styles.headerPopup}
      className={styles.popup}
      onClose={presenter.closePopup}
    >
      <div className={styles.container}>
        <div className={styles.info}>
          <h3 className={styles.phone}>{params!.phoneNumber}</h3>
          <p className={styles.description}>{t('account_phone_number_change_text')}</p>
        </div>
        <div>
          <Button
            size="20"
            type={ButtonTypes.Button}
            theme="1"
            onClick={presenter.changePhoneSubmit}
          >
            {t('account_phone_number_change')}
          </Button>
        </div>
      </div>
    </Popup>
  );
};
