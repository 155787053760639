import { readAllGroupsMessages } from 'APP/Tasks/messaging/readAllGroupsMessages/readAllGroupsMessages';
import { spaceAnalytics } from 'APP/packages/analytics/analytics';
import Entities from 'STORE';

import { markGroupsAsUnread } from '../../group/markGroupsAsUnread/markGroupsAsUnread';

export async function markSpaceAsRead(spaceId: string): Promise<boolean> {
  const markAsReadIds: string[] = [];
  const readMessagesIds: string[] = [];

  Entities.GroupsStore.getGroupsBySpaceId(spaceId).forEach((group) => {
    if (group.unread) {
      markAsReadIds.push(group.id);
    }
    if (group.lastMessage?.unread) {
      readMessagesIds.push(group.id);
    }
  });

  try {
    await Promise.all([
      markAsReadIds.length ? markGroupsAsUnread(markAsReadIds, false) : Promise.resolve(),
      readMessagesIds.length ? readAllGroupsMessages(readMessagesIds) : Promise.resolve(),
    ]);

    spaceAnalytics.trackSpaceMarkedAsRead(spaceId);

    return true;
  } catch {
    return false;
  }
}
