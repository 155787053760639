import { useLocalStore } from 'mobx-react';

import { PayloadType } from 'APP/model/message/messageModel.types';
import { useTranslation } from 'APP/packages/translations';

import { DEFAULT_PAYLOAD, FOCUS_NAMES } from '../../constants';
import services from '../../services';
import { useUploadFiles } from '../presenters/uploadFiles';

export const usePayloadCreatorPresenter = ({ parentPresenter }) => {
  const { t } = useTranslation();
  const uploadFilesPresenter = useUploadFiles({
    parentPresenter,
    order: parentPresenter.lastOrder,
    isPayloadCreator: true,
  });

  const presenter = useLocalStore(() => ({
    data: { ...DEFAULT_PAYLOAD },
    showPlaceholder: true,

    editPayloadByOrder: (payload, order = null) => {
      presenter.data = { ...presenter.data, ...DEFAULT_PAYLOAD };

      parentPresenter.setPayloadToEnd({ ...payload });
      parentPresenter.setPayloadCreatedText('');

      const position = order === null ? parentPresenter.lastOrder : order;
      presenter.setActualPosition(position);
    },

    onEnter: (payload) => {
      presenter.editPayloadByOrder(payload, FOCUS_NAMES.CREATOR_PAYLOAD);
    },

    setPayloadsToCurrentPosition: (payloads) => {
      parentPresenter.setPayloadsToEnd(payloads);
    },

    deletePayloadByOrder: () => {
      if (presenter.payload.text !== '') {
        return;
      }

      const payload = services.payloads.getPayloadByOrder(
        parentPresenter.data,
        parentPresenter.lastOrder
      );

      if (!payload) {
        parentPresenter.setActualPosition(FOCUS_NAMES.TITLE);
        return;
      }

      if (payload.type === PayloadType.RichText) {
        parentPresenter.setActualPosition(parentPresenter.lastOrder);
        return;
      }

      parentPresenter.deletePayloadByOrder(parentPresenter.lastOrder, true);
    },

    setActualPosition: (position) => {
      parentPresenter.setActualPosition(position);
    },

    setLocationPayload: (coordinates) => {
      const payload = {
        payloadType: PayloadType.Location,
        ...coordinates,
      };

      parentPresenter.setPayloadToEnd(payload);
    },

    handleArrowsKeyboard: () => {
      if (parentPresenter.data.length === 0) {
        return FOCUS_NAMES.TITLE;
      }

      const order = services.focus.getPositionArrowsKeyboard(
        parentPresenter.data,
        parentPresenter.lastOrder,
        'up'
      );

      presenter.setActualPosition(order);
    },

    get payload() {
      return presenter.data;
    },

    get scroll() {
      return parentPresenter.scroll;
    },

    get actualFocusPosition() {
      return parentPresenter.actualFocusPosition;
    },

    get placeholder() {
      return presenter.showPlaceholder ? t('channels:channel_article_thoughts') : null;
    },
  }));

  return { uploadFilesPresenter, presenter };
};
