import classNames from 'classnames';
import React, { FC } from 'react';

import BackIcon from 'ICONS/ic-back-2.svg';
import NextIcon from 'ICONS/ic-next.svg';
import { FAB } from 'UIKIT/FAB/FAB';

import styles from './CarouselControls.styles.m.css';

interface ICarouselControlsProps {
  showPrevButton: boolean;
  showNextButton: boolean;
  withShadow?: boolean;
  onPrev(): void;
  onNext(): void;
}

export const CarouselControls: FC<ICarouselControlsProps> = ({
  showPrevButton,
  showNextButton,
  withShadow,
  onPrev,
  onNext,
}) => {
  return (
    <>
      {showPrevButton && (
        <div
          className={classNames(
            styles.buttonWrapper,
            styles.prev,
            withShadow ? styles.withShadow : undefined
          )}
        >
          <FAB
            className={classNames(styles.button, styles.prev)}
            Icon={BackIcon}
            theme="7"
            size="30"
            onClick={onPrev}
          />
        </div>
      )}
      {showNextButton && (
        <div
          className={classNames(
            styles.buttonWrapper,
            styles.next,
            withShadow ? styles.withShadow : undefined
          )}
        >
          <FAB
            className={classNames(styles.button, styles.next)}
            Icon={NextIcon}
            theme="7"
            size="30"
            onClick={onNext}
          />
        </div>
      )}
    </>
  );
};
