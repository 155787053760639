import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { FriendsSpaceMessagesEmpty } from './FriendsSpaceMessagesEmpty/FriendsSpaceMessagesEmpty';
import { useMessagesEmptyPresenter } from './MessagesEmpty.presenter';
import { SavedMessagesEmpty } from './SavedMessagesEmpty/SavedMessagesEmpty';
import { ScheduledMessagesEmpty } from './ScheduledMessagesEmpty/ScheduledMessagesEmpty';
import { ThreadEmpty } from './ThreadEmpty/ThreadEmpty';

export const MessagesEmpty: FC = observer(() => {
  const presenter = useMessagesEmptyPresenter();

  if (presenter.isShowSavedMessagesEmpty) {
    return <SavedMessagesEmpty hasBackground={presenter.hasBackground} />;
  }

  if (presenter.isShowScheduleEmpty) {
    return <ScheduledMessagesEmpty hasBackground={presenter.hasBackground} />;
  }

  if (presenter.isShowThreadEmpty) {
    return <ThreadEmpty hasBackground={presenter.hasBackground} />;
  }

  if (presenter.isShowFriendsSpaceEmpty) {
    return (
      <FriendsSpaceMessagesEmpty
        avatarUrl={presenter.group?.avatarUrl || ''}
        hasBackground={presenter.hasBackground}
      />
    );
  }

  return null;
});
