import { prepareLink } from 'UTILS/prepareLink';

import { getHistory, getLocation } from './index';

export function replaceTo(data = {}) {
  const history = getHistory();
  const location = getLocation();

  if (history && location) {
    history.replace(prepareLink({ ...data, location }));
  }
}
