import { useAsObservableSource, useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { showAlert, showConfirm, showToast } from 'APP/Tasks/app/app';
import { IGroupMember } from 'APP/Tasks/group/participants/getParticipants';
import { GroupUserRole } from 'APP/model/group/groupModel.types';
import { MenuItemStyle } from 'APP/packages/UiKit/MenuItem/MenuItem.types';
import { ServerErrorCode } from 'APP/packages/api';
import { GroupServerErrorCode } from 'APP/packages/api/constants/group.errors';
import { useTranslation } from 'APP/packages/translations';
import { Group } from 'APP/store/Groups/Group';
import DeleteAdminIcon from 'ICONS/ic-delete-admin.svg';
import OwnerIcon from 'ICONS/ic-owner.svg';
import DeleteIcon from 'ICONS/ic-s-delete.svg';
import UserAdminIcon from 'ICONS/ic-user-admin.svg';
import UserBanIcon from 'ICONS/ic-user-ban.svg';
import { IPopoverMenuItem } from 'UIKIT/PopoverMenu/PopoverMenu.types';

const knownError: Record<string, string> = {
  [GroupServerErrorCode.GroupDoesNotExist]: 'common_chat_is_not_accessible',
  [ServerErrorCode.HasNoPermissionsToPerformAction]: 'no_permission_alert',
  [GroupServerErrorCode.UserIsNotBannedInTheGroup]: 'common_group_alert_user_not_ban',
  [GroupServerErrorCode.UserIsBannedInTheGroup]: 'common_group_alert_user_ban',
  [GroupServerErrorCode.UserGroupRelationshipDoesNotExist]:
    'common_group_alert_relationship_not_exit',
  [GroupServerErrorCode.InvitationsTooManyToRemove]: 'common_group_alert_user_many_remove',
  [ServerErrorCode.HasNoPermissionToRemoveSpaceOwner]: 'space_admin_kick_owner_alert',
  [GroupServerErrorCode.ParticipantNotInGroup]: 'common_group_alert_member_not_group',
  [ServerErrorCode.UserIsNotSpaceManager]: 'space_admin_ownership_alert',
  [ServerErrorCode.SpaceDoesNotExist]: 'common_group_alert_space_not_exit',
  [GroupServerErrorCode.InvitationsToManyADminsToAdd]: '',
};

export interface ParticipantListItemPresenterParams {
  group: Group | null;
  participant: IGroupMember;
  onParticipantRemove(userId: string): void;
  onUpdateParticipantRole(userId: string, role: GroupUserRole): void;
  onReloadParticipantsList(): void;
  onClosePopup(): void;
}

interface IParticipantListItemPresenter {
  isOwner: boolean;
  isAdmin: boolean;
  isMember: boolean;
  isBot: boolean;
  canStartUserChat: boolean;
  menuReference: HTMLElement | null;
  menuItems: IPopoverMenuItem[];
  canTransferOwnership: boolean;
  canAddToAdmins: boolean;
  canRemoveFromAdmins: boolean;
  canBanMember: boolean;
  canRemoveMember: boolean;
  isOpenedMenu: boolean;
  isMenuLoading: boolean;
  hint: string;

  transferOwnership(): Promise<void>;
  addToAdmins(): Promise<void>;
  removeFromAdmins(): Promise<void>;
  banParticipant(): Promise<void>;
  removeParticipant(): Promise<void>;
  onToggleMenu(element: HTMLElement): void;
  onCloseMenu(): void;
  onOpenUserChat(): Promise<void>;
  showErrorMessage(error: string): void;
}

export const useParticipantListItemPresenter = ({
  group,
  participant,
  onReloadParticipantsList,
  onParticipantRemove,
  onUpdateParticipantRole,
  onClosePopup,
}: ParticipantListItemPresenterParams): IParticipantListItemPresenter => {
  const { t } = useTranslation();

  const source = useAsObservableSource({ group, participant, onClosePopup });

  const presenter = useLocalStore<IParticipantListItemPresenter>(() => ({
    menuReference: null,
    isMenuLoading: false,

    get isOwner(): boolean {
      return source.participant.role === GroupUserRole.Owner;
    },

    get isAdmin(): boolean {
      return source.participant.role === GroupUserRole.Admin;
    },

    get isMember(): boolean {
      return source.participant.role === GroupUserRole.Member;
    },

    get isBot(): boolean {
      return source.participant.user.isBot;
    },

    get canTransferOwnership(): boolean {
      return !!(source.group?.isOwner && !presenter.isBot && !source.participant.user.isMe);
    },

    get canAddToAdmins(): boolean {
      return !!(source.group?.isOwner && presenter.isMember);
    },

    get canRemoveFromAdmins(): boolean {
      return !!(source.group?.isOwner && presenter.isAdmin);
    },

    get canBanMember(): boolean {
      if (source.participant.user.isMe) {
        return false;
      }

      return !!source.group?.isOwner || !!(source.group?.isAdmin && presenter.isMember);
    },

    get canRemoveMember(): boolean {
      if (source.participant.user.isMe) {
        return false;
      }

      return !!source.group?.isOwner || !!(source.group?.isAdmin && presenter.isMember);
    },

    get canStartUserChat(): boolean {
      return !source.participant.user.isMe && !!source.participant.user.startChat;
    },

    get isOpenedMenu(): boolean {
      return !!presenter.menuReference;
    },

    get menuItems(): IPopoverMenuItem[] {
      return [
        {
          key: 'transferOwnership',
          isVisible: presenter.canTransferOwnership,
          title: t('common_transfer_ownership'),
          onClick: presenter.transferOwnership,
          icon: OwnerIcon,
        },
        {
          key: 'add',
          isVisible: presenter.canAddToAdmins,
          title: t('add_to_admins'),
          onClick: presenter.addToAdmins,
          icon: UserAdminIcon,
        },
        {
          key: 'removeAdmin',
          isVisible: presenter.canRemoveFromAdmins,
          title: t('remove_from_admins'),
          onClick: presenter.removeFromAdmins,
          icon: DeleteAdminIcon,
        },
        {
          key: 'ban',
          isVisible: presenter.canBanMember,
          title: t('ban_user'),
          onClick: presenter.banParticipant,
          icon: UserBanIcon,
        },
        {
          key: 'delete',
          isVisible: presenter.canRemoveMember,
          title: t('delete_user'),
          onClick: presenter.removeParticipant,
          icon: DeleteIcon,
          style: MenuItemStyle.Danger,
        },
      ].filter((x) => x.isVisible);
    },

    get hint(): string {
      if (source.group?.isMember && source.group?.isOpen) {
        return '';
      }

      if (presenter.isOwner) {
        return t('common_owner_label');
      }
      if (presenter.isAdmin) {
        return t('common_admin_label');
      }
      return '';
    },

    onToggleMenu(menuReference: HTMLElement): void {
      if (presenter.isOpenedMenu) {
        presenter.onCloseMenu();
        return;
      }
      presenter.menuReference = menuReference;
    },

    onCloseMenu(): void {
      this.menuReference = null;
    },

    showErrorMessage(error: string): void {
      showAlert(t(knownError[error] || 'common_somethings_wrong_error'));
    },

    async onOpenUserChat(): Promise<void> {
      await source.participant.user.startChat();
      source.onClosePopup();
    },

    async transferOwnership(): Promise<void> {
      const canTransfer = await showConfirm({
        text: t(
          source.group?.verified
            ? 'badge_transfer_ownership_alert'
            : 'common_transfer_ownership_alert',
          { 0: participant.user.displayName }
        ),
      });

      if (!canTransfer) {
        return;
      }
      const userId = source.participant.user.id;

      presenter.isMenuLoading = true;

      const { error } = await Tasks.group.transferOwnership(source.group!.id, userId);

      presenter.isMenuLoading = false;

      if (error) {
        presenter.showErrorMessage(error);
        return;
      }

      onReloadParticipantsList();
    },

    async addToAdmins(): Promise<void> {
      const canAddToAdmins = await showConfirm({
        text: t('confirmation_text_add_admin', { 0: source.participant.user.displayName }),
      });

      if (!canAddToAdmins) {
        return;
      }

      const userId = source.participant.user.id;

      presenter.isMenuLoading = true;

      const { error } = await Tasks.group.addGroupAdmins(source.group!.id, [userId]);

      presenter.isMenuLoading = false;

      if (error) {
        presenter.showErrorMessage(error);
        return;
      }

      onUpdateParticipantRole(userId, GroupUserRole.Admin);
    },

    async removeFromAdmins(): Promise<void> {
      const canRemoveFromAdmins = await showConfirm({
        text: t('confirmation_text_remove_admin', { 0: source.participant.user.displayName }),
      });

      if (!canRemoveFromAdmins) {
        return;
      }

      const userId = source.participant.user.id;

      presenter.isMenuLoading = true;

      const { error } = await Tasks.group.removeGroupAdmins(source.group!.id, [userId]);

      presenter.isMenuLoading = false;

      if (error) {
        presenter.showErrorMessage(error);
        return;
      }

      onUpdateParticipantRole(userId, GroupUserRole.Member);
    },

    async banParticipant(): Promise<void> {
      const { isConfirmed: canRemoveFromAdmins, isChecked: deleteAllMessages } =
        await Tasks.app.showConfirmWithCheckbox({
          text: t('ban_user_alert', { 0: participant.user.displayName }),
          checkBoxText: t('checkbox_delete_messages'),
        });

      if (!canRemoveFromAdmins) {
        return;
      }
      const userId = source.participant.user.id;

      presenter.isMenuLoading = true;

      const { error } = await Tasks.group.banParticipants(
        source.group!.id,
        [userId],
        deleteAllMessages
      );

      presenter.isMenuLoading = false;

      if (error) {
        presenter.showErrorMessage(error);
        return;
      }

      onParticipantRemove(userId);

      deleteAllMessages
        ? showToast(t('delete_banned_user_messages_snackbar'))
        : showToast(t('common_user_banned_snackbar', { 0: participant.user.displayName }));
    },

    async removeParticipant(): Promise<void> {
      const canRemove = await showConfirm({
        text: source.group!.isChannel
          ? t('delete_user_from_channel_alert')
          : t('delete_user_from_group_alert'),
      });

      if (!canRemove) {
        return;
      }

      const userId = source.participant.user.id;

      presenter.isMenuLoading = true;

      const { error } = await Tasks.group.removeParticipants(source.group!.id, [userId]);

      presenter.isMenuLoading = false;

      if (error) {
        presenter.showErrorMessage(error);
        return;
      }

      onParticipantRemove(userId);
    },
  }));

  return presenter;
};
