import { useLocalStore } from 'mobx-react';

import Entities from 'APP/store';

export default () => {
  const presenter = useLocalStore(() => ({
    get isOfflineNetworkStatus() {
      return Entities.App.isOfflineNetworkStatus;
    },
  }));

  return presenter;
};
