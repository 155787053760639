import { observable } from 'mobx';

import { NewsType, INewsItem } from 'APP/model/catalog/catalogModel.types';
import { IGroup } from 'APP/model/group/groupModel.types';

import { NewsItem } from './NewsItem';

export class SuggestedChannels extends NewsItem {
  @observable channels: IGroup[];

  constructor(data: INewsItem) {
    super(NewsType.SuggestedChannels);

    this.channels = Array.isArray(data?.channels) ? data.channels : [];
  }
}
