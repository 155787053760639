import { UpdateReason } from 'APP/constants/scroll';
import api from 'APP/packages/api';
import Entities from 'APP/store';
import { langService } from 'MODULE/i18n/service';

export async function translateMessage({ groupId, messageId }) {
  const { currentLanguage } = langService.i18n;

  const group = Entities.GroupsStore.getGroupById(groupId);
  if (!group) {
    return;
  }

  let message = group.messagesStore.getMessageById(messageId);
  if (!message) {
    return;
  }

  try {
    message.isLoadingTranslate = true;

    const translation = await api.messaging.translateMessage({
      groupId,
      messageId,
      targetLang: currentLanguage,
    });

    // During translation, the message object may change
    message = group.messagesStore.getMessageById(messageId);
    if (!message) {
      return;
    }

    message.isLoadingTranslate = false;
    if (translation) {
      message.isShowTranslate = true;
      group.messagesStore.addTranslation(translation);
    }

    if (group.isActive) {
      Entities.ChatStore.setScrollChanged(UpdateReason.EditMessage, {
        messageIds: [messageId],
        isAnimated: true,
      });
    }
  } catch (error) {
    message = group.messagesStore.getMessageById(messageId);
    if (message) {
      message.isLoadingTranslate = false;
    }

    console.error(error);
  }
}
