import { action, computed, observable } from 'mobx';

import { NewsType, INewsItem } from 'APP/model/catalog/catalogModel.types';

import { Categories } from './NewsItems/Categories';
import { GuestPost } from './NewsItems/GuestPost';
import { Post } from './NewsItems/Post';
import { SuggestedChannels } from './NewsItems/SuggestedChannels';
import { TNewsItem, TPostInstance } from './Newsfeed.types';

const newsClasses = {
  [NewsType.Post]: Post,
  [NewsType.GuestPost]: GuestPost,
  [NewsType.Categories]: Categories,
  [NewsType.SuggestedChannels]: SuggestedChannels,
};

export class Newsfeed {
  @observable data: TNewsItem[] = [];
  @observable cursor: string | null = null;
  @observable isLoading = false;
  @observable isPreloading = false;
  @observable isNeedReload = false;
  @observable isError = false;
  @observable scrollPosition = 0;

  @computed
  get news(): TNewsItem[] {
    return this.data;
  }

  @computed
  get posts(): TPostInstance[] {
    const posts = this.data.filter((item) => item instanceof Post || item instanceof GuestPost);
    return posts as TPostInstance[];
  }

  getPostByPosition(position: number): TPostInstance | null {
    return this.posts[position] || null;
  }

  getPostsByGroupId(groupId: string): TPostInstance[] {
    return this.posts.filter((post) => post.groupId === groupId);
  }

  @action
  setCursor(cursor: string | null): void {
    this.cursor = cursor;
  }

  @action
  setLoading(state: boolean): void {
    this.isLoading = state;
  }

  @action
  setPreloading(state: boolean): void {
    this.isPreloading = state;
  }

  @action
  addNewsItem(item: INewsItem) {
    if (item.elementType && newsClasses[item.elementType]) {
      this.data.push(new newsClasses[item.elementType](item));
    }
  }

  @action
  addNews(newsList: INewsItem[]): void {
    newsList.forEach((item) => this.addNewsItem(item));
  }

  @action
  setNeedReload(state: boolean): void {
    this.isNeedReload = state;
  }

  @action
  setError(state: boolean): void {
    this.isError = state;
  }

  @action
  setScrollPosition(scrollTop: number): void {
    this.scrollPosition = scrollTop || 0;
  }

  @action
  reset(): void {
    this.data = [];
    this.cursor = null;
    this.isLoading = false;
    this.isPreloading = false;
    this.isNeedReload = true;
    this.isError = false;
    this.scrollPosition = 0;
  }
}
