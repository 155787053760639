import { useLocalStore } from 'mobx-react';

import {
  PERMISSIONS_CUSTOM_TRANSLATIONS,
  PERMISSIONS_TYPES_TRANSLATIONS,
} from 'APP/constants/calls';
import routerConst from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import Entities from 'STORE';

export const usePresenter = ({ permissionType, memberPermissionsId }) => {
  const navigateTo = useNavigateTo();

  const presenter = useLocalStore(() => ({
    get permissions() {
      const callPermissions = Entities.Calls.ongoingCall?.permissions;

      const personalizedPermissions = memberPermissionsId
        ? callPermissions.editedPermissions.permissions
        : callPermissions.personalizedPermissions;

      return callPermissions.getMemberPersonalizedPermissions(personalizedPermissions);
    },

    get permissionLabel() {
      return PERMISSIONS_TYPES_TRANSLATIONS[permissionType];
    },

    get permissionValue() {
      if (!presenter.permissions) {
        return null;
      }

      const permissionsValue = presenter.permissions[permissionType];

      return PERMISSIONS_CUSTOM_TRANSLATIONS[permissionsValue];
    },

    onClick: () => {
      if (!presenter.permissionValue || !memberPermissionsId) {
        return;
      }

      const query = {
        [routerConst.params.popup]: routerConst.paramsEnum.popup.callChangeMemberPermission,
        [routerConst.params.permissionType]: permissionType,
        [routerConst.params.memberPermissionsId]: memberPermissionsId,
      };

      navigateTo({
        query,
      });
    },
  }));

  return presenter;
};
