import execCommand from 'execcommand-copy';
import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { useTranslation } from 'APP/packages/translations';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import Entities from 'STORE';

export interface ISessionsItemDetailsPopupParams {
  sessionKey: string;
}

interface ISessionsItemDetailsPopupPresenter {
  session: any; // TODO: add typings
  hasBackButton: boolean;
  removeSession(): void;
  terminateSession(): Promise<void>;
  onCopyIp(): void;
  onClose(): void;
  onBack(): void;
}

export function useSessionsItemDetailsPopupPresenter({
  popupInstance,
  params,
  onClose,
  onBack,
}: ICommonPopupsProps<ISessionsItemDetailsPopupParams>): ISessionsItemDetailsPopupPresenter {
  const sessionKey = params!.sessionKey;
  const { t } = useTranslation();
  const presenter = useLocalStore<ISessionsItemDetailsPopupPresenter>(() => ({
    get session(): any {
      return Entities.sessionsStore.getSessionByKey(sessionKey);
    },

    get hasBackButton(): boolean {
      return !!onBack;
    },

    removeSession(): void {
      Entities.sessionsStore.removeSession(sessionKey);
    },

    async terminateSession(): Promise<void> {
      await Tasks.userSettings.terminateSessions(sessionKey);
      presenter.removeSession();
      presenter.onBack();
    },

    onCopyIp(): void {
      execCommand.copy(presenter.session.ipAddress);
      Entities.toast.show(t('common_copied'));
    },

    onClose(): void {
      popupInstance.close();
      onClose?.();
    },

    onBack(): void {
      popupInstance.close();
      onBack?.();
    },
  }));

  useEffect(() => {
    if (!presenter.session) {
      Tasks.userSettings.getSessionsInfo();
    }
  }, [presenter.session]);

  return presenter;
}
