import { useAsObservableSource, useLocalStore } from 'mobx-react';

import Entities from 'STORE';

import { SEEN_TAB_NAME } from '../constants';

export default ({ userReactionsList, userSeen, tab }) => {
  const source = useAsObservableSource({ userSeen });

  const presenter = useLocalStore(() => ({
    tabs: [],
    selectedTab: null,

    initTabs: (tabs) => {
      const tabList = tabs.filter((tab) => tab.list.length);

      presenter.tabs = tabList;
      presenter.setSelectedTab(tab || tabList[0]?.key || null);
    },

    setSelectedTab: (tab) => {
      presenter.selectedTab = tab;

      if (tab !== SEEN_TAB_NAME) {
        userReactionsList.selectedTab = tab;
        userReactionsList.cursor = 0;
        userReactionsList.updateCursor();
      }
    },

    get tabsList() {
      const reactionImages = Entities.reactionsStore.allReactions.reduce((acc, reaction) => {
        acc[reaction.id] = reaction.previewImage || reaction.previewURL;
        return acc;
      }, {});

      return presenter.tabs.map((tab) => {
        const { key, list } = tab;

        if (key === SEEN_TAB_NAME) {
          return {
            key: key,
            alt: key,
            list: presenter.userSeen,
            hasTitle: true,
            isActive: key === presenter.selectedTab,
            onClick: () => presenter.setSelectedTab(key),
          };
        }

        return {
          key: key,
          alt: key,
          path: reactionImages[key],
          onClick: () => presenter.setSelectedTab(key),
          isActive: key === presenter.selectedTab,
          counter: list.length,
          list: userReactionsList,
          isDisabled: list.isLoading,
        };
      });
    },

    get currentTab() {
      return presenter.tabsList.find((tab) => tab.key === presenter.selectedTab);
    },

    get hasTabs() {
      return presenter.tabsList.length >= 1;
    },

    get userSeen() {
      return source.userSeen;
    },
  }));

  return presenter;
};
