import { action, computed, observable, values } from 'mobx';

import { STREAM_STATUS } from 'APP/constants/stream';

import { BackgroundStream } from './BackgroundStream';

class BackgroundStreams {
  @observable data = new Map();

  @action reset() {
    this.data.clear();
  }

  @action
  add(stream) {
    this.data.set(
      stream.groupId,
      new BackgroundStream(stream.groupId, stream.channelId, stream.status, stream.startTs)
    );
  }

  @action
  replace(streams = []) {
    this.data.clear();
    streams
      .filter(({ status }) => status === STREAM_STATUS.STARTED)
      .forEach((stream) => {
        this.add(stream);
      });
  }

  @action
  delete(groupId) {
    this.data.delete(groupId);
  }

  @computed
  get hasStream() {
    return !!this.data.size;
  }

  @computed
  get hasStreamWithoutMute() {
    return this.streams.some((call) => !call.group?.muted);
  }

  @computed
  get streams() {
    return values(this.data);
  }

  getByGroupId(groupId) {
    return this.data.get(groupId) || null;
  }
}

export default BackgroundStreams;
