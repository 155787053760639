import Tasks from 'APP/Tasks';
import { getNewsFeed } from 'APP/model/catalog/catalogModel';
import { INewsItem, NewsType } from 'APP/model/catalog/catalogModel.types';
import { GroupUserRole, IPersonalizedGroup } from 'APP/model/group/groupModel.types';
import { IMessage, IMessageWithCommentCount } from 'APP/model/message/messageModel.types';
import Entities from 'APP/store';
import { Counter } from 'APP/store/CountersStore/Counter';

export interface ILoadNewsfeedData {
  readonly cursor?: string | null;
  readonly spaceId?: string;
  readonly limit?: number;
}

interface INewsfeedData {
  readonly news: INewsItem[];
  readonly cursor: string | null;
}

const DEFAULT_LOAD_LIMIT = 20;

export function updateGroupAndMessageData(
  group: IPersonalizedGroup,
  messageWithCommentCount: IMessageWithCommentCount
): void {
  const message = messageWithCommentCount.message;
  const commentCount = messageWithCommentCount.commentCount;

  Entities.GroupsStore.merge([group]);
  Tasks.messaging.updateOldOrFakeMessages({
    messages: [message],
    groupId: group.groupResponse.id,
  });

  if (message.threadId) {
    const counter = Entities.Counters.getCounterById(message.threadId);
    if (counter) {
      counter.setTotalMessagesCount(commentCount);
    } else {
      Entities.Counters.add(
        new Counter({
          counter: 0,
          groupId: message.threadId,
          lastEventTs: 0,
          lastMessageTs: Date.now(),
          lastUserMentionTs: 0,
          opponentSeenTs: 0,
          seenTs: Date.now(),
          unreadUserMentionsCounter: 0,
          totalMessagesCount: commentCount,
        })
      );
    }
  }
}

export async function loadNewsfeed(data: ILoadNewsfeedData): Promise<INewsfeedData | null> {
  try {
    const news: INewsItem[] = [];
    const messages: IMessage[] = [];

    const response = await getNewsFeed({
      ...data,
      cursor: data.cursor || undefined,
      limit: DEFAULT_LOAD_LIMIT,
    });

    response.values.forEach((item) => {
      if (item.group && item.message) {
        updateGroupAndMessageData(item.group, item.message);
        messages.push(item.message.message);
        news.push({
          ...item,
          elementType:
            item.group.userGroupRole === GroupUserRole.Guest ? NewsType.GuestPost : NewsType.Post,
        });
      } else {
        news.push(item);
      }
    });

    if (messages.length > 0) {
      await Tasks.relationships.loadUnknownDataFromMessages({ messages });
    }

    return {
      news,
      cursor: response.cursor || null,
    };
  } catch (error) {
    return null;
  }
}
