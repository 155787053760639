import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import NoResultImage from 'APP/images/gem-mascot-not-found.svg';
import { GroupsPlaceholder } from 'APP/packages/placeholders/GroupsPlaceholder/GroupsPlaceholder';
import { useTranslation } from 'APP/packages/translations';
import { Space } from 'STORE/Spaces/Space';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';
import { InfoBlock, InfoBlockTypes } from 'UIKIT/InfoBlock/InfoBlock';

import { SpaceGroupAvailableItem } from './SpaceGroupAvailableItem/SpaceGroupAvailableItem';
import { SpaceGroupItem } from './SpaceGroupItem/SpaceGroupItem';
import { useSpacePanelGroupsPresenter } from './SpacePanelGroups.presenter';
import styles from './SpacePanelGroups.styles.m.css';

const GROUP_PLACEHOLDER_COUNT = 14;

interface ISpacePanelGroupsProps {
  space: Space;
}

export const SpacePanelGroups: FC<ISpacePanelGroupsProps> = observer(({ space }) => {
  const presenter = useSpacePanelGroupsPresenter(space);
  const { t } = useTranslation();

  if (presenter.isEmpty) {
    return (
      <InfoBlock
        className={classNames(styles.empty, customScrollStyles.container)}
        type={InfoBlockTypes.Sidebar}
        descriptions={[t('space_alert_donot_group_channel')]}
        image={NoResultImage}
      />
    );
  }

  return (
    <div className={classNames(styles.list, customScrollStyles.container)}>
      {presenter.subscribedGroups.map((group) => (
        <SpaceGroupItem
          key={group.id}
          group={group}
        />
      ))}

      {!presenter.isGroupsLoading &&
        presenter.availableGroups.map((group) => (
          <SpaceGroupAvailableItem
            key={group.id}
            group={group}
          />
        ))}

      {presenter.isGroupsLoading && <GroupsPlaceholder count={GROUP_PLACEHOLDER_COUNT} />}
    </div>
  );
});
