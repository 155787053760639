import { KEY_NAMES } from '../../constants';

export default ({
  isCaptured = false,
  isPrevent = false,
  onEsc,
  onEnter,
  onArrowUp,
  onArrowDown,
  onArrowLeft,
  onArrowRight,
}) => {
  const callbacks = [
    {
      isCaptured: true,
      isPrevent: true,
      callback: onEsc,
      keyName: KEY_NAMES.ESC,
    },
    {
      isCaptured,
      isPrevent,
      callback: onEnter,
      keyName: KEY_NAMES.ENTER,
    },
    {
      isCaptured,
      isPrevent,
      callback: onArrowUp,
      keyName: KEY_NAMES.ARROW_UP,
    },
    {
      isCaptured,
      isPrevent,
      callback: onArrowDown,
      keyName: KEY_NAMES.ARROW_DOWN,
    },
    {
      isCaptured,
      isPrevent,
      callback: onArrowLeft,
      keyName: KEY_NAMES.ARROW_LEFT,
    },
    {
      isCaptured,
      isPrevent,
      callback: onArrowRight,
      keyName: KEY_NAMES.ARROW_RIGHT,
    },
  ].filter((item) => item.callback);

  return {
    callbacks,
  };
};
