import api from 'APP/packages/api';
import logger from 'APP/packages/logger';

import { buildModelError } from '../error/errorBuilder';
import {
  IUserReaction,
  IGetUsersReactionsForMessage,
  IUsersReactionsForMessage,
} from './reactionsModel.types';

export const getUserReactions = async (): Promise<IUserReaction[]> => {
  try {
    const { reactions } = await api.reactions.getUserReactions();
    return reactions;
  } catch (error) {
    logger.get('API').error('reactions.getUserReactions', error);
    throw buildModelError(error);
  }
};

export async function getUsersReactionsForMessage(
  data: IGetUsersReactionsForMessage
): Promise<IUsersReactionsForMessage> {
  try {
    const { usersReactions } = await api.reactions.getUsersReactionsForMessage(data);
    return usersReactions;
  } catch (error) {
    logger.get('API').error('messaging.getUsersReactionsForMessage', error);
    throw buildModelError(error);
  }
}
