import { action, observable } from 'mobx';

import uploader from 'APP/packages/file-upload/uploader';
import { MessageError } from 'STORE/Messages/Message/ChatMessage/ChatMessages.types';
import { Payload } from 'STORE/Messages/Message/Payload';
import { convertFileToBlobUrl } from 'UTILS/file';

class File extends Payload {
  fileName;
  fileSize;
  url;
  mimeType;
  userMentions;
  @observable comment;
  @observable uploadProgress = null;
  userFile;
  userFileBlobUrl;
  duration;

  static THUMBNAIL_MAX_WIDTH = 50;
  static THUMBNAIL_MAX_HEGHT = 50;
  static PREVIEW_MAX_WIDTH = 440;
  static PREVIEW_MAX_HEIGHT = 440;
  static MIN_QUOTE_WIDTH = 200;

  constructor(data) {
    super(data);

    this.payloadType = data.payloadType;

    this.fileName = data.fileName;
    this.fileSize = Number(data.fileSize);
    this.url = data.url;
    this.mimeType = data.mimeType;
    this.comment = data.comment;
    this.userMentions = data.userMentions;
    this.keyWord = data.keyWord;

    this.keyWords = data.keyWord ? [data.keyWord] : [];
    this.userFile = data.userFile || null;
    this.userFileBlobUrl = convertFileToBlobUrl(this.userFile);
  }

  toJSON() {
    return {
      payloadType: this.payloadType,
      fileName: this.fileName,
      fileSize: this.fileSize,
      url: this.url,
      mimeType: this.mimeType,
      comment: this.comment,
      userMentions: this.userMentions,
      keyWord: this.keyWord || null,
    };
  }

  get getTypeForSend() {
    return this.payloadType;
  }

  static calculatePreviewSize(width, height, maxWidth, maxHeight) {
    let ratio = width / height;

    if (ratio <= 0 || !isFinite(ratio)) {
      ratio = 1;
    }

    if (ratio > 1) {
      // wide
      return {
        width: maxWidth < width ? maxWidth : width,
        height: maxWidth < width ? Math.ceil(maxHeight / ratio) : height,
      };
    }

    return {
      width: maxHeight < height ? Math.ceil(maxWidth * ratio) : width,
      height: maxHeight < height ? maxHeight : height,
    };
  }

  get isLoaded() {
    return this.url;
  }

  get typeDescription() {
    return `${this.fileName}`;
  }

  delete = async () => {
    const res = await this.message.delete();
    this.isDeleted = res;

    return res;
  };

  @action
  setUploadProgress = (value) => {
    this.uploadProgress = value;
  };

  @action
  cancelUploadAndDeleteMessage = async () => {
    const res = await this.delete();
    if (res) {
      this.cancelUploadFile();
    }
  };

  cancelUploadFile() {
    uploader.cancelUploadFile(this.uploadUrl);
  }

  @action
  async upload() {
    this.setUploadProgress(0);
    if (!this.isLoaded) {
      try {
        const { url } = await uploader.getUploadUrl();

        if (!url || this.isDeleted) {
          throw Error('uploadUrl not received');
        }

        this.uploadUrl = url;
        const response = await uploader.uploadFile(url, this.userFile, this.fileName, (event) => {
          this.setUploadProgress((event.loaded / event.total) * 100);
        });

        this.url = response[0].url;
      } catch (e) {
        this.setUploadProgress(0);
        this.message.setError(MessageError.Send);
        return;
      }
    }
    this.setUploadProgress(null);
  }
}

export default File;
