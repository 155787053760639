import { observer } from 'mobx-react';

import usePresenter from './presenter';

const UserTypingHandler = () => {
  usePresenter();

  return null;
};

export default observer(UserTypingHandler);
