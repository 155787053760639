export type TAvatarBackgroundColorIndex =
  | ''
  | '0'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9';

export enum AvatarTypes {
  Round_05 = 'round-05',
  Round_0 = 'round-0',
  Round_5 = 'round-5',
  Round_10 = 'round-10',
  Round_20 = 'round-20',
  Round_25 = 'round-25',
  Round_30 = 'round-30',
  Round_40 = 'round-40',
  Round_50 = 'round-50',
  Square_0 = 'square-0',
  Square_10 = 'square-10',
  Square_20 = 'square-20',
  Square_30 = 'square-30',
  Square_40 = 'square-40',
  Square_50 = 'square-50',
  Square_60 = 'square-60',
  Square_70 = 'square-70',
  Additional_0 = 'additional-0',
}
