import classNames from 'classnames';
import React, { FC, memo } from 'react';

import styles from './RadioLabel.styles.m.css';

interface IRadioLabelProps {
  isActive: boolean;
  title: string;
  titleClassName?: string;
  value?: string;
  description?: string;
  name?: string;
  onChange(): void;
}

export const RadioLabel: FC<IRadioLabelProps> = memo(
  ({ isActive, value = '', title, titleClassName, description = '', onChange, name }) => {
    const classes = classNames(styles.label, {
      [styles.active]: isActive,
    });

    return (
      <label className={classes}>
        <div className={styles.container}>
          <div className={styles.circle} />
          <input
            type="radio"
            className={styles.radio}
            value={value}
            name={name}
            onChange={onChange}
            checked={isActive}
          />
        </div>
        <div className={styles.block}>
          {title && <div className={classNames(styles.title, titleClassName)}>{title}</div>}
          {description && <div className={styles.description}>{description}</div>}
        </div>
      </label>
    );
  }
);
