import baseConfig from './baseConfig';

export default Object.freeze({
  ...baseConfig,
  env: {
    ...baseConfig.env,
    name: 'stage-2',
    isStage2: true,
  },
  features: {
    bugsnag: false,
    unionCall: true,
    openvidu: false,
    telegramAuthWeb: true,
    telegramAuthDesktop: false,
    googleAuthWeb: true,
    googleAuthDesktop: false,
  },
  amplitudeApiKey: 'ed0ed8a9fa353e625ee9cece1bb83184',
  reCaptchaKey: '6LdNywUjAAAAAAAAqkISESQqIavPy0Fk9vbbSXe3',
  deeplinkHost: 'https://ms8s.test-app.link',
  googleClientId: '1055671339421-empp6bn91oi68j808pnkqj2vtbc2sm60.apps.googleusercontent.com',
});
