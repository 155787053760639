import { useLocalStore, useAsObservableSource } from 'mobx-react';

import { OTHER_CATEGORY_ID } from 'APP/constants/categories';
import Entities from 'APP/store';

import postCategories from './Tasks/postCategories';

export default ({ newsItem, onReloadNewsfeed }) => {
  const source = useAsObservableSource({ newsItem });

  const presenter = useLocalStore(() => ({
    categoryIds: new Set(),
    isDisabled: false,

    get isView() {
      return source.newsItem.categories?.length > 0;
    },

    get isRefreshView() {
      return presenter.categoryIds.size >= 1;
    },

    get categories() {
      return source.newsItem.categories.filter((cat) => cat.id !== OTHER_CATEGORY_ID);
    },

    isSelected(id) {
      return presenter.categoryIds.has(id);
    },

    getToggleSelectionHandler(id) {
      return presenter.onToggleSelection.bind(presenter, id);
    },

    onToggleSelection(id) {
      if (presenter.categoryIds.has(id)) {
        presenter.categoryIds.delete(id);
      } else {
        presenter.categoryIds.add(id);
      }
    },

    async onRefresh() {
      if (!presenter.isRefreshView || presenter.isDisabled) {
        return;
      }

      const categoryIds = Array.from(presenter.categoryIds);
      presenter.isDisabled = true;

      await postCategories({ categoryIds });
      Entities.userPreferences.setCategoryIds(categoryIds);
      onReloadNewsfeed();
    },
  }));

  return presenter;
};
