import React, { FC } from 'react';

import { IStoryComponent } from '../../Stories.types';
import { useStorySimpleTimePresenter } from '../StorySimpleTimer/StorySimpleTimer.presenter';
import styles from './StoryComponent.styles.m.css';

interface IStoryComponentProps {
  story: IStoryComponent;
  onProgress(progress: number): void;
}

export const StoryComponent: FC<IStoryComponentProps> = (props) => {
  const { onMouseDown } = useStorySimpleTimePresenter(props);

  return (
    <div
      className={styles.container}
      role="button"
      tabIndex={0}
      onMouseDown={onMouseDown}
    >
      <props.story.Component {...props.story.payload} />
    </div>
  );
};
