import { action, computed, observable } from 'mobx';

import Tasks from 'APP/Tasks';
import { PayloadType } from 'APP/model/message/messageModel.types';

class ChatStore {
  @observable isAutoScrollDisabled = false;
  @observable scrollChanged = null;
  @observable isShowSearch = false;
  @observable isShowSidebar = false;

  scrollOptions = {};

  constructor(root) {
    this.root = root;
  }

  @computed
  get isDisableToWrite() {
    const group = this.root.GroupsStore.activeGroup;
    if (!group) {
      return true;
    }

    if (group.isChatGroup) {
      return group.withoutMe;
    }
    if (group.isChannel) {
      return !group.withMeInAdmins;
    }

    return false;
  }

  @computed
  get isOnlyLeftSideMessages() {
    return this.root.App.width > 1440;
  }

  @action
  showSearch = () => {
    this.hideSidebar();
    this.isShowSearch = true;
  };

  @action
  hideSearch = () => {
    this.isShowSearch = false;
  };

  @action
  toggleSidebar() {
    this.isShowSidebar ? this.hideSidebar() : this.showSidebar();
  }

  @action
  showSidebar = () => {
    this.hideSearch();
    this.isShowSidebar = true;
  };

  @action
  hideSidebar = () => {
    this.isShowSidebar = false;
  };

  @action
  setScrollChanged(value, options) {
    this.scrollChanged = value;
    this.scrollOptions = options || {};
  }

  @action
  setAutoScrollDisabled = (isDisabled) => {
    this.isAutoScrollDisabled = isDisabled;
  };

  @action
  uploadFile = (file, comment = '') => {
    const type = file.type.includes('audio') ? PayloadType.AudioMessage : PayloadType.File;
    Tasks.messaging.createNewMessages({
      messages: [
        {
          type,
          data: { file, comment },
          groupId: this.root.GroupsStore.activeGroup.id,
          quotedMessage: this.root.GroupsStore.activeGroup.inputPanel.quoteMessage,
        },
      ],
    });
  };

  @action
  initBotGroup = async (botId, command, groupId) => {
    await Tasks.group.createP2PGroup(botId);

    Tasks.messaging.createNewMessages({
      messages: [
        {
          type: PayloadType.RichText,
          data: { text: command },
          groupId,
        },
      ],
    });
  };

  @action
  reset = () => {
    this.hideSearch();
    this.hideSidebar();
  };
}

export default ChatStore;
