import classNames from 'classnames';
import React, { useMemo, FC } from 'react';

import { CallBlurType } from 'APP/constants/callBackground';
import { IconView, IconViewSizes } from 'UIKIT/IconView/IconView';

import styles from './BackgroundItem.styles.m.css';

interface IBackgroundItemProps {
  background?: string;
  icon: React.ElementType;
  isActive?: boolean;
  blurType: CallBlurType;
  onClick(type: CallBlurType): void;
}

export const BackgroundItem: FC<IBackgroundItemProps> = (props) => {
  const { background, icon, onClick, isActive, blurType } = props;

  const backgroundStyle = useMemo(
    () =>
      background
        ? {
            background: `url(${background})`,
          }
        : {},
    [background]
  );

  const onItemClick = () => {
    if (isActive) {
      return;
    }
    onClick(blurType);
  };

  return (
    <button
      className={classNames(styles.blurItem, {
        [styles.blurItemActive]: isActive,
      })}
      style={backgroundStyle}
      onClick={onItemClick}
    >
      <IconView
        Icon={icon}
        size={IconViewSizes.Medium}
      />
    </button>
  );
};
