/* global Map */
import { action, observable, computed, values } from 'mobx';

class Mention {
  constructor(root, parent) {
    this.root = root;
    this.parent = parent;
  }

  @observable _mentions = new Map();
  @observable isLoading = false;
  @observable filter = null;
  @observable cursor = null;

  @computed
  get isAvailable() {
    if (!this.parent.group) {
      return false;
    }

    return (
      this.parent.group.isChannel || this.parent.group.isChatGroup || this.parent.group.isThread
    );
  }

  @computed
  get mentions() {
    return values(this._mentions);
  }

  @computed
  get hasMentions() {
    return this.mentions.length > 0;
  }

  @computed
  get hasFilter() {
    return typeof this.filter === 'string';
  }

  get isLoadedAllMentions() {
    return this.cursor === null;
  }

  @action
  setIsLoading = (isLoading) => {
    this.isLoading = isLoading;
  };

  @action
  setMentions = (mentions) => {
    this._mentions.clear();
    this.addMentions(mentions);
  };

  @action
  addMentions = (mentions) => {
    this._mentions.merge(mentions.map((mention) => [mention.id, mention]));
  };

  @action
  setFilter = (filter) => {
    this.filter = filter;
  };

  @action
  setCursor = (cursor) => {
    this.cursor = cursor;
  };
}

export default Mention;
