import { action, computed, observable } from 'mobx';

import { IUserReaction } from 'APP/model/reactions/reactionsModel.types';

const SHORT_LIST_SIZE = 6;

export class ReactionsStore {
  @observable data: IUserReaction[] = [];

  @computed
  get allReactions(): IUserReaction[] {
    return this.data;
  }

  @computed
  get shortReactions(): IUserReaction[] {
    return this.data.slice(0, SHORT_LIST_SIZE);
  }

  @action
  setReactions(reactions: IUserReaction[]) {
    this.data = [...reactions];
  }

  @action
  reset(): void {
    this.data = [];
  }
}
