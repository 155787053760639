import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { CatalogCategoriesPlaceholder } from 'APP/packages/placeholders/CatalogPlaceholder/CatalogCategoriesPlaceholder/CatalogCategoriesPlaceholder';

import { TCategoryChangeHandler, TTypeChangeHandler } from '../Catalog.types';
import { CategoryButton } from '../components/CategoryButton/CategoryButton';
import { useCatalogCategoriesPresenter } from './CatalogCategories.presenter';
import styles from './CatalogCategories.styles.m.css';
import { CatalogTypes } from './CatalogTypes/CatalogTypes';

interface ICatalogCategoriesProps {
  onCategoryChange: TCategoryChangeHandler;
  onTypeChange: TTypeChangeHandler;
}

export const CatalogCategories: FC<ICatalogCategoriesProps> = observer((props) => {
  const { onCategoryChange, onTypeChange } = props;
  const presenter = useCatalogCategoriesPresenter(onCategoryChange);

  return (
    <div className={styles.container}>
      <CatalogTypes
        onCategoryChange={onCategoryChange}
        onTypeChange={onTypeChange}
      />

      {presenter.isLoading && (
        <>
          <div className={styles.divider} />
          <CatalogCategoriesPlaceholder />
        </>
      )}

      {presenter.isShowCategories && (
        <>
          <div className={styles.divider} />
          {presenter.categories.map((item) => (
            <CategoryButton
              key={item.id}
              className={styles.button}
              isSelected={presenter.isActive(item.id)}
              onClick={() => presenter.onClick(item.id)}
            >
              {item.displayName}
            </CategoryButton>
          ))}
        </>
      )}
    </div>
  );
});
