import { callPermissionsModel } from 'APP/model/model';
import logger from 'APP/packages/logger';
import Entities from 'APP/store';

export const getPermissionRequests = async (channelId: string): Promise<void> => {
  try {
    const response = await callPermissionsModel.getCallPermissionsRequests(channelId);
    if (Entities.Calls.ongoingCall) {
      Entities.Calls.ongoingCall.permissions.setPermissionsRequests(response);
    }
  } catch (error) {
    logger.get('API').error('calls.getPermissionRequests', error);
  }
};
