import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { useTranslation } from 'APP/packages/translations';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import { useCloseFormPopup } from 'MAIN/PopupManager/hooks/useCloseFormPopup';

import { SpaceForm } from '../../components/SpaceForm/SpaceForm';
import { useCreateSpacePopupPresenter } from './CreateSpacePopup.presenter';

export const CreateSpacePopup: FC<ICommonPopupsProps> = observer((props) => {
  const { t } = useTranslation();
  const presenter = useCreateSpacePopupPresenter(props);
  const closeForm = useCloseFormPopup(props, t('space_cancel_creation_alert'));

  return (
    <Popup
      title={t('space_new_space')}
      isOpened
      handlePrevStep={presenter.isShowPrevStep ? presenter.onShowPrevStep : undefined}
      onClose={closeForm.closePopup}
    >
      <SpaceForm
        initialValues={presenter.initialValues}
        step={presenter.step}
        onStepChange={presenter.onStepChange}
        onFormStateChange={closeForm.onFormStateChange}
        onSubmit={presenter.onSubmit}
      ></SpaceForm>
    </Popup>
  );
});
