import { loadUnknownUsersByIds } from 'APP/Tasks/relationships/loadUnknownUsersByIds';
import { spaceModel, searchModel } from 'APP/model/model';
import { SpaceUserRole } from 'APP/model/space/spaceModel.types';
import Entities from 'STORE';
import { User } from 'STORE/Users/User/User';

const SPACE_MEMBERS_LIMIT = 20;

interface IGetSpaceMembersData {
  readonly spaceId: string;
  readonly searchText?: string;
  readonly cursor?: string;
  readonly limit?: number;
}

export interface ISpaceMember {
  readonly user: User;
  readonly role: SpaceUserRole;
}

interface ISpaceMembersPagination {
  readonly members: ISpaceMember[];
  readonly cursor: string | null;
}

export async function getSpaceMembers({
  spaceId,
  searchText,
  cursor = '',
  limit = SPACE_MEMBERS_LIMIT,
}: IGetSpaceMembersData): Promise<ISpaceMembersPagination | null> {
  try {
    const response = searchText
      ? await searchModel.searchUserInSpace({ spaceId, searchQuery: searchText, cursor, limit })
      : await spaceModel.getUserRoles({ spaceId, cursor, limit });

    const loadUsersIds = response.userRoles.reduce((userIds: string[], userRole) => {
      if (!Entities.UsersStore.getUserById(userRole.userId)) {
        userIds.push(userRole.userId);
      }

      return userIds;
    }, []);

    if (loadUsersIds.length) {
      await loadUnknownUsersByIds({ usersIds: loadUsersIds });
    }

    const members = response.userRoles.reduce((users: ISpaceMember[], userRole) => {
      const user = Entities.UsersStore.getUserById(userRole.userId);

      if (user) {
        users.push({
          user,
          role: userRole.role,
        });
      }

      return users;
    }, []);

    return {
      members,
      cursor: response.cursor || null,
    };
  } catch {
    return null;
  }
}
