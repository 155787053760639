import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { GroupType } from 'APP/model/group/groupModel.types';
import { Space } from 'APP/store/Spaces/Space';
import Entities from 'STORE';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { IGroupFormInitialValues } from '../../components/GroupForm/GroupForm.types';

interface ICreateSpaceGroupPopupParams {
  spaceId: string;
}

interface ICreateSpaceGroupPopupPresenter {
  initialValues: IGroupFormInitialValues;
  space: Space | null;
  onSubmit(groupData: IGroupFormInitialValues): Promise<void>;
}

export function useCreateSpaceGroupPopupPresenter({
  popupInstance,
  params,
}: ICommonPopupsProps<ICreateSpaceGroupPopupParams>): ICreateSpaceGroupPopupPresenter {
  const { spaceId } = params!;

  const presenter = useLocalStore<ICreateSpaceGroupPopupPresenter>(() => ({
    initialValues: { type: GroupType.Closed },

    get space(): Space | null {
      return Entities.spacesStore.getById(spaceId);
    },

    async onSubmit(groupData: IGroupFormInitialValues): Promise<void> {
      await Tasks.group.createGroupAndInvite({
        ...groupData,
        spaceId: presenter.space?.id,
      });
      popupInstance.close();
    },
  }));

  useEffect(() => {
    if (!presenter.space) {
      popupInstance.close();
    }
  }, [presenter.space, Entities.spacesStore.isLoading]);

  return presenter;
}
