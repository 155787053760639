import { useCallback, useState } from 'react';

import { MAX_DESCRIPTION_LENGTH } from 'APP/constants/group';
import { useTranslation } from 'APP/packages/translations';

export function useSeeMoreDescriptionPresenter(description: string) {
  const { t } = useTranslation();
  const maxDescriptionLength = MAX_DESCRIPTION_LENGTH - t('group_description_more').length;

  const [hasShowMore, setHasShowMore] = useState(description.length > maxDescriptionLength);

  const showMore = useCallback(
    (event) => {
      event.stopPropagation();
      setHasShowMore(false);
    },
    [setHasShowMore]
  );

  return {
    description: hasShowMore ? description.slice(0, maxDescriptionLength) + ' ' : description,
    showMore,
    hasShowMore,
  };
}
