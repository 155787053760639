import { PayloadType } from 'APP/model/message/messageModel.types';

const MEDIA_TYPES = [PayloadType.Image, PayloadType.Video];

export default ({ message }) => {
  const payload = message.payload.payloads.find((typeObject) =>
    MEDIA_TYPES.includes(typeObject.payload.payloadType)
  );

  if (payload) {
    const { previewUrl, url, payloadType } = payload.payload;
    return payloadType === PayloadType.Video ? previewUrl : url;
  }

  return null;
};
