import api from 'APP/packages/api';
import logger from 'APP/packages/logger';

export const setActionCall = async (params: {
  userId: string;
  action: string;
  channelId: string;
}): Promise<void> => {
  try {
    await api.calls.actionCall({
      action: params.action,
      channelId: params.channelId,
      userId: params.userId,
    });
  } catch (error) {
    logger.get('API').error('calls.setActionCall', error);
  }
};
