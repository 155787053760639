import { ICountry } from 'APP/types/countryCodes';

export const DEFAULT_PHONE_MASK = '+000 000000000';
export const DEFAULT_ACCOUNT_COUNTRY: ICountry = {
  countryCode: '',
  countryName: '',
  flag: '',
  phonePrefix: '',
  phoneTemplate: DEFAULT_PHONE_MASK,
};
