import { subscribe, broadcast } from '../../eventBus';

function getBusStation(groupId) {
  return `messages-changes-${groupId}`;
}

export const subscribeMessagesChanges = (groupId, callback) => {
  return subscribe(getBusStation(groupId), callback);
};

export const broadcastMessagesChanges = (groupId) => {
  broadcast(getBusStation(groupId));
};
