import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { SenderTitle } from 'MAIN/ChatView/Messages/Message/bubbles/components/SenderName/SenderTitle/SenderTitle';
import { SenderPopoverMenuForAdmin } from 'MAIN/ChatView/Messages/Message/components/SenderPopoverMenuForAdmin/SenderPopoverMenuForAdmin';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';
import { usePopover } from 'UIKIT/Popover/usePopover';

import { useSenderNamePresenter } from '../SenderName.presenter';

interface ISenderChannelAvatarProps {
  className?: string;
  message: ChatMessage;
}

export const SenderNameForAdmins: FC<ISenderChannelAvatarProps> = observer((props) => {
  const { message } = props;
  const popover = usePopover<HTMLDivElement>();

  const commonPresenter = useSenderNamePresenter(message);

  return (
    <>
      <div ref={popover.containerRef}>
        <SenderTitle
          user={commonPresenter.senderUser}
          onClick={popover.toggle}
        />
      </div>
      {commonPresenter.senderUser.id && (
        <SenderPopoverMenuForAdmin
          message={message}
          senderId={commonPresenter.senderUser.id}
          popover={popover}
        />
      )}
    </>
  );
});
