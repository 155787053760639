import { useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { GroupType } from 'APP/model/group/groupModel.types';
import { Popup } from 'STORE/App/Popups/Popup/Popup';

import { IGroupFormInitialValues } from '../../components/GroupForm/GroupForm.types';

interface ICreateChatGroupPopupPresenter {
  initialValues: IGroupFormInitialValues;
  onSubmit(groupData: IGroupFormInitialValues): Promise<void>;
}

export function useCreateChatGroupPopupPresenter(
  popupInstance: Popup
): ICreateChatGroupPopupPresenter {
  const presenter = useLocalStore<ICreateChatGroupPopupPresenter>(() => ({
    initialValues: { type: GroupType.Closed },

    async onSubmit(groupData: IGroupFormInitialValues): Promise<void> {
      await Tasks.group.createGroupAndInvite({ ...groupData });
      popupInstance.close();
    },
  }));

  return presenter;
}
