import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { PopupUserItem } from 'APP/main/Popups/GetParametersManager/components/PopupUserItem/PopupUserItem';
import PopupUserWithMenuItem from 'APP/main/Popups/GetParametersManager/components/PopupUserWithMenuItem';
import { PopoverMenu } from 'APP/packages/UiKit/PopoverMenu/PopoverMenu';

import {
  ParticipantListItemPresenterParams,
  useParticipantListItemPresenter,
} from './ParticipantListItem.presenter';
import styles from './ParticipantListItem.styles.m.css';

type IParticipantListItemParams = ParticipantListItemPresenterParams;

export const ParticipantListItem: FC<IParticipantListItemParams> = observer((props) => {
  const { participant } = props;

  const presenter = useParticipantListItemPresenter(props);

  const onOpenUserChat = presenter.canStartUserChat ? presenter.onOpenUserChat : undefined;

  if (!presenter.menuItems.length) {
    return (
      <PopupUserItem
        user={participant.user}
        hintClassName={styles.hintWithoutMenu}
        hint={presenter.hint}
        onClick={onOpenUserChat}
      />
    );
  }

  return (
    <>
      <PopupUserWithMenuItem
        user={participant.user}
        hint={presenter.hint}
        isOpenedMenu={presenter.isOpenedMenu}
        isMenuLoading={presenter.isMenuLoading}
        toggleMenu={presenter.onToggleMenu}
        onClick={onOpenUserChat}
      />

      <PopoverMenu
        isOpened={presenter.isOpenedMenu}
        placement="bottom-end"
        menuItems={presenter.menuItems}
        reference={presenter.menuReference}
        onClose={presenter.onCloseMenu}
      />
    </>
  );
});
