import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { IconButton } from 'APP/packages/UiKit/IconButton';
import { useTranslation } from 'APP/packages/translations';
import SearchIcon from 'ICONS/ic-search.svg';
import StreamingIcon from 'ICONS/ic-streaming.svg';
import MuteIcon from 'ICONS/mute.svg';
import UnmuteIcon from 'ICONS/unmute.svg';
import { JoinCallButton } from 'MAIN/OngoingCall/Shared/JoinCallButton/JoinCallButton';
import { Group } from 'STORE/Groups/Group';
import { Schedule } from 'STORE/Groups/Schedule';
import { Thread } from 'STORE/Groups/Thread';
import { IconViewSizes } from 'UIKIT/IconView/IconView.types';

import ArticleDraftsButton from './ArticleDraftsButton';
import { ChatSettings } from './ChatSettings/ChatSettings';
import { useDefaultHeaderActionsPresenter } from './DefaultHeaderActions.presenter';
import styles from './DefaultHeaderActions.styles.m.css';
import DonateButton from './DonateButton';
import { JoinButton } from './JoinButton/JoinButton';
import { StartCallButton } from './StartCallButton/StartCallButton';

interface IDefaultHeaderActionsProps {
  group: Group;
}

export const DefaultHeaderActions: FC<IDefaultHeaderActionsProps> = observer(({ group }) => {
  const presenter = useDefaultHeaderActionsPresenter(group);
  const { t } = useTranslation();

  if (group instanceof Schedule) {
    return null;
  }

  if (group instanceof Thread) {
    return (
      <div className={styles.container}>
        {!presenter.isShowSearch && (
          <IconButton
            className={styles.iconContainer}
            Icon={SearchIcon}
            onClick={presenter.showSearch}
            size={IconViewSizes.Big}
          />
        )}
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {presenter.isGroupJoinButton && (
        <JoinButton
          className={styles.iconContainer}
          groupId={group.id}
        />
      )}

      {presenter.isArticleDrafts && (
        <ArticleDraftsButton
          className={styles.iconContainer}
          groupId={group.id}
        />
      )}

      {presenter.hasDonateButton && <DonateButton className={styles.actionButton} />}

      {presenter.isAvailableStartStream && (
        <IconButton
          className={styles.startStreaming}
          Icon={StreamingIcon}
          onClick={presenter.startStream}
          title={t('stream_go_live_button')}
          size={IconViewSizes.Big}
        />
      )}

      {!presenter.isShowSearch && (
        <IconButton
          className={styles.iconContainer}
          Icon={SearchIcon}
          onClick={presenter.showSearch}
          size={IconViewSizes.Big}
        />
      )}

      {presenter.isAvailableJoinCall && <JoinCallButton onClick={presenter.joinToCall} />}

      {!presenter.isAvailableJoinCall && presenter.isCreateCallButton && (
        <StartCallButton
          className={styles.iconContainer}
          groupId={group.id}
          startVideoCall={presenter.videoCall}
          startAudioCall={presenter.audioCall}
          scheduleCallEvent={presenter.scheduleCallEvent}
          startConferenceCall={presenter.isAvailableConference ? presenter.conferenceCall : null}
        />
      )}

      {group.notificationsAvailable && (
        <IconButton
          className={styles.iconContainer}
          Icon={group.muted ? UnmuteIcon : MuteIcon}
          onClick={presenter.onToggleMute}
          size={IconViewSizes.Big}
        />
      )}
      <ChatSettings />
    </div>
  );
});
