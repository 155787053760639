import { spaceModel } from 'APP/model/model';
import { IDeleteSpaceCollectionItems } from 'APP/model/space/spaceModel.types';
import Entities from 'STORE';

export async function deleteSpaceCollectionItems(
  data: IDeleteSpaceCollectionItems
): Promise<boolean> {
  try {
    const response = await spaceModel.deleteCollectionItems(data);

    if (response) {
      const space = Entities.spacesStore.getById(data.spaceId);
      const collection = space?.collections.getCollection(data.collectionId);
      space?.collections.updateCollection({
        ...collection,
        itemsCount: collection?.itemsCount
          ? collection?.itemsCount - data.collectionItemIds.length
          : 0,
      });
    }

    return response.result;
  } catch {
    return false;
  }
}
