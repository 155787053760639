import { useState, ChangeEvent, KeyboardEvent } from 'react';

import patterns from 'APP/packages/patterns';

import { ITextFormattingMenuViewParams, ViewType } from '../TextFormattingMenu.types';

interface ITextFormattingLinkViewPresenter {
  link: string;
  canSubmit: boolean;
  onLinkChange(event: ChangeEvent<HTMLInputElement>): void;
  onBack(): void;
  onSubmit(): void;
  onEnterKey(event: KeyboardEvent): void;
}

const linkRegex = new RegExp(patterns.AUTOLINK_WEB_URL, 'iu');

export const useTextFormattingLinkViewPresenter = (
  data: ITextFormattingMenuViewParams
): ITextFormattingLinkViewPresenter => {
  const { onClose, onAddLink, onChangeView } = data;

  const [link, setLink] = useState<string>('');

  const presenter: ITextFormattingLinkViewPresenter = {
    link,

    get canSubmit(): boolean {
      return linkRegex.test(link);
    },

    onLinkChange(event: ChangeEvent<HTMLInputElement>): void {
      setLink(event.target.value);
    },

    onBack(): void {
      onChangeView(ViewType.Main);
    },

    onSubmit(): void {
      if (!presenter.canSubmit) {
        return;
      }

      onAddLink(link);
      onClose();
    },

    onEnterKey(event: KeyboardEvent): void {
      event.stopPropagation();
      event.preventDefault();

      presenter.onSubmit();
    },
  };

  return presenter;
};
