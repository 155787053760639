import { useAsObservableSource, useLocalStore } from 'mobx-react';

export default ({ payload }) => {
  const source = useAsObservableSource({
    payload,
  });

  const presenter = useLocalStore(() => ({
    get message() {
      return {
        payload: {
          latitude: source.payload.latitude,
          longitude: source.payload.longitude,
        },
      };
    },
  }));

  return presenter;
};
