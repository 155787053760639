import { useAsObservableSource, useLocalStore } from 'mobx-react';

export const useArticleYouTubePreviewPresenter = ({ payload }) => {
  const source = useAsObservableSource({ payload });

  const presenter = useLocalStore(() => ({
    isShowTitle: true,

    get linkPreview() {
      return source.payload?.linkPreview || {};
    },

    get isShow() {
      return (
        !source.payload?.linkPreviewCancelled &&
        source.payload?.linkPreview?.video?.videoId &&
        source.payload?.linkPreview?.video?.type === 'youtube'
      );
    },

    onPlay() {
      presenter.isShowTitle = false;
    },
  }));

  return presenter;
};
