import Entities from 'APP/store';

export default ({ groupId, messageIds = [] }) => {
  const group = Entities.GroupsStore.getGroupById(groupId);
  if (!group) {
    return;
  }

  const schGroup = Entities.GroupsStore.getGroupById(group.scheduleGroupId);
  if (!schGroup) {
    return;
  }

  messageIds.forEach((id) => {
    schGroup.messagesStore.multiSelect.unselectMessage(id);
  });

  schGroup.messagesStore.handleRemoveMessages(schGroup.messagesStore.getMessageByIds(messageIds));
};
