import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { useTranslation } from 'APP/packages/translations';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import { useStickersSettingsPopupPresenter } from 'MAIN/PopupManager/Popups/StickersSettingsPopup/StickersSettingsPopup.presenter';

import { StickersSettingsContent } from './StickersSettingsContent/StickersSettingsContent';

export const StickersSettingsPopup: FC<ICommonPopupsProps> = observer((props) => {
  const { t } = useTranslation();
  const presenter = useStickersSettingsPopupPresenter(props);

  return (
    <Popup
      title={t('settings')}
      isOpened
      isHidden={props.popupInstance.isHidden}
      onClose={presenter.onClose}
    >
      <StickersSettingsContent onStickerPackClick={presenter.openStickerPackPopup} />
    </Popup>
  );
});
