import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Tasks from 'APP/Tasks';
import { GroupUserRole } from 'APP/model/group/groupModel.types';
import useClearGetParameters from 'APP/router/hooks/useClearGetParameters';
import Entities from 'STORE';

export const usePresenter = () => {
  const history = useHistory();
  const clearGetParameters = useClearGetParameters();

  const presenter = useLocalStore(() => ({
    searchValue: '',
    userRolesLoading: true,
    userRoles: {},

    onChangeSearchValue: (newValue) => {
      presenter.searchValue = newValue;
    },

    onClose: () => {
      clearGetParameters();
    },

    goBack: () => {
      history.goBack();
    },

    getGroupUserRoles: async () => {
      const groupId = Entities.Calls.ongoingCall.groupId;
      const userRoles = await Tasks.group.getGroupUserRoles({ groupId });
      presenter.userRolesLoading = false;
      presenter.userRoles = userRoles.reduce((acc, item) => {
        acc[item.userId] = item.role;
        return acc;
      }, {});
    },

    get customPermissionsIds() {
      return Entities.Calls.ongoingCall.permissions.customPermissionsIds;
    },

    get participants() {
      return Entities.Calls.ongoingCall?.members.memberList.filter((item) => {
        return (
          !presenter.customPermissionsIds.includes(item.userId) &&
          !item.isMe &&
          !item.isInitiator &&
          presenter.userRoles[item.userId] !== GroupUserRole.Admin &&
          presenter.userRoles[item.userId] !== GroupUserRole.Owner &&
          item.displayName.toLowerCase().includes(presenter.searchValue.toLowerCase())
        );
      });
    },

    get ongoingCall() {
      return Entities.Calls.ongoingCall;
    },
  }));

  useEffect(() => {
    if (!presenter.ongoingCall || !presenter.ongoingCall?.isStarted) {
      presenter.onClose();
    }
  }, [presenter.ongoingCall?.isStarted]);

  useEffect(() => {
    if (presenter.ongoingCall) {
      presenter.getGroupUserRoles();
    }
  }, []);

  return presenter;
};
