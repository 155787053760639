import React from 'react';

import NoMediaImage from 'APP/images/gem-mascot-shared-data.svg';
import { useTranslation } from 'APP/packages/translations';
import { InfoBlock, InfoBlockTypes } from 'UIKIT/InfoBlock/InfoBlock';

import styles from './EmptyContentStub.styles.m.css';

export const EmptyContentStub = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <InfoBlock
        descriptions={[t('profile_media_no_attachments')]}
        type={InfoBlockTypes.Sidebar}
        image={NoMediaImage}
      />
    </div>
  );
};
