import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { openPopup } from 'APP/Tasks/app/popup/popup';
import { ALERT_TYPES } from 'APP/constants/app';
import { ISpaceMarket } from 'APP/model/space/spaceModel.types';
import { useTranslation } from 'APP/packages/translations';
import { RouterLink } from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import { AddSpaceMarketPopup } from 'MAIN/PopupManager/Popups/AddSpaceMarketPopup/AddSpaceMarketPopup';
import { EditSpaceMarketPopup } from 'MAIN/PopupManager/Popups/EditSpaceMarketPopup/EditSpaceMarketPopup';
import Entities from 'STORE';
import { Space } from 'STORE/Spaces/Space';

export interface ILocalSpaceMarketStore {
  markets: ISpaceMarket[];
  space: Space | null;
  isLoading: boolean;
  hasMarketList: boolean;
  isOwner: boolean;
  showCreateMarketInfo: boolean;
  showEmptyInfo: boolean;
  showPlaceholder: boolean;
  showCreateMarketButton: boolean;
  isSpaceLoading: boolean;
  hasBackground: boolean;
  loadMarkets(): Promise<void>;
  onAddMarket(): void;
  onEditMarket(market: ISpaceMarket): void;
  onDeleteMarket(market: ISpaceMarket): Promise<void>;
  onClose(): void;
}

export function useSpaceMarketPresenter(spaceId?: string): ILocalSpaceMarketStore {
  const { t } = useTranslation();
  const navigateTo = useNavigateTo();
  const source = useAsObservableSource({ navigateTo, spaceId });

  const presenter = useLocalStore<ILocalSpaceMarketStore>(() => ({
    isLoading: false,

    get markets(): ISpaceMarket[] {
      return presenter.space?.markets.data || [];
    },

    get space(): Space | null {
      return source.spaceId ? Entities.spacesStore.getById(source.spaceId) : null;
    },

    get hasMarketList(): boolean {
      return !!presenter.space?.marketsCount;
    },

    get isOwner(): boolean {
      return !!presenter.space?.isOwner;
    },

    get isSpaceLoading(): boolean {
      return !presenter.space && Entities.spacesStore.isLoading;
    },

    get showCreateMarketInfo(): boolean {
      return !presenter.hasMarketList && presenter.isOwner && !presenter.isSpaceLoading;
    },

    get showEmptyInfo(): boolean {
      return !presenter.hasMarketList && !presenter.isOwner && !presenter.isSpaceLoading;
    },

    get showPlaceholder(): boolean {
      return (presenter.isLoading && !presenter.markets.length) || presenter.isSpaceLoading;
    },

    get showCreateMarketButton(): boolean {
      return presenter.hasMarketList && presenter.isOwner && !presenter.isSpaceLoading;
    },

    get hasBackground(): boolean {
      return Entities.appearance.hasChatBackground;
    },

    async loadMarkets(): Promise<void> {
      if (presenter.space) {
        presenter.isLoading = true;

        await Tasks.space.loadSpaceMarkets(presenter.space.id);

        presenter.isLoading = false;
      }
    },

    onAddMarket(): void {
      if (presenter.space) {
        openPopup(AddSpaceMarketPopup, { params: { spaceId: presenter.space.id } });
      }
    },

    onEditMarket(market: ISpaceMarket): void {
      openPopup(EditSpaceMarketPopup, { params: { spaceId: market.spaceId, marketId: market.id } });
    },

    async onDeleteMarket(deleteMarket: ISpaceMarket): Promise<void> {
      const canDelete = await Tasks.app.showConfirm({
        text: t('delete_store_alert'),
        primaryText: t('common_yes'),
        secondaryText: t('common_cancel'),
      });

      if (!canDelete) {
        return;
      }

      const response = await Tasks.space.deleteSpaceMarket(deleteMarket);

      if (!response) {
        Tasks.app.addAlert({
          type: ALERT_TYPES.SOMETHING_WRONG_ERROR,
        });
      }
    },

    onClose(): void {
      navigateTo({ to: RouterLink.main });
    },
  }));

  useEffect(() => {
    if (presenter.space) {
      presenter.loadMarkets();
    }
  }, [presenter.space]);

  useEffect(() => {
    if (
      !presenter.isLoading &&
      presenter.space &&
      presenter.space.marketsCount !== presenter.space.markets.count
    ) {
      presenter.loadMarkets();
    }
  }, [presenter.space?.marketsCount]);

  return presenter;
}
