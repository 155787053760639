import { observer } from 'mobx-react';
import React, { FC, ReactNode } from 'react';

import { ChatMessage } from 'APP/store/Messages/Message/ChatMessage/ChatMessage';

import { MessageViewPageType } from '../../../Messages.types';
import { MessageLayout } from '../../MessageLayout/MessageLayout';
import { SenderName } from '../components/SenderName/SenderName';
import { ThreadButton } from '../components/ThreadButton/ThreadButton';
import styles from './DeletedByMeBubble.styles.m.scss';

interface DeletedByMeBubbleProps {
  message: ChatMessage;
  children: ReactNode;
  messageViewPageType: MessageViewPageType;
  isView: boolean;
}
export const DeletedByMeBubble: FC<DeletedByMeBubbleProps> = observer((props) => {
  const { message, isView, messageViewPageType, children } = props;
  return (
    <MessageLayout
      message={message}
      className={styles.bubble}
      messageViewPageType={messageViewPageType}
      isView={isView}
    >
      <SenderName
        message={message}
        className={styles.senderName}
      />

      <div
        className={styles.content}
        data-test="text-message"
      >
        {children}
      </div>

      <ThreadButton
        message={message}
        className={styles.threadButton}
      />
    </MessageLayout>
  );
});
