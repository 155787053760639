import { MouseEvent, useState } from 'react';

import { IPopover, TPopoverModifiers } from 'UIKIT/Popover/Popover.types';
import { usePopover } from 'UIKIT/Popover/usePopover';

interface IContextMenuParams {
  skipNativeElements?: boolean;
  skipSelection?: boolean;
  modifiers?: TPopoverModifiers;
}

interface IContextMenu<T extends HTMLElement> {
  popover: IPopover<T>;
  modifiers: TPopoverModifiers;
  onContextMenu(event: MouseEvent<T>): void;
}

const NATIVE_ELEMENT_TAG_LIST = ['a'];

const checkNativeElementClick = <T>(event: MouseEvent<T>): boolean => {
  const target = event.target as HTMLElement;

  return NATIVE_ELEMENT_TAG_LIST.includes(target.tagName.toLowerCase());
};

const checkSelectionClick = <T>(event: MouseEvent<T>): boolean => {
  const selection = window.getSelection();
  const target = event.target as HTMLElement;

  return selection?.type === 'Range' && selection.containsNode(target, true);
};

export const useContextMenu = <T extends HTMLElement = HTMLElement>(
  data: IContextMenuParams = {}
): IContextMenu<T> => {
  const { skipNativeElements = false, skipSelection = false, modifiers: dataModifiers = [] } = data;

  const popover = usePopover<T>();
  const [modifiers, setModifiers] = useState<TPopoverModifiers>([]);

  const presenter: IContextMenu<T> = {
    popover,
    modifiers,

    onContextMenu(event: MouseEvent<T>): void {
      if (!popover.containerRef.current) {
        return;
      }

      const node = popover.containerRef.current;
      const target = event.target as HTMLElement;

      if (
        !node.contains(target) ||
        (skipNativeElements && checkNativeElementClick<T>(event)) ||
        (skipSelection && checkSelectionClick<T>(event))
      ) {
        return;
      }

      if (!popover.isOpened) {
        event.preventDefault();
        const { top = 0, left = 0, height = 0 } = node.getBoundingClientRect();

        setModifiers([
          {
            name: 'offset',
            options: { offset: [event.clientX - left, event.clientY - top - height] },
          },
          {
            name: 'preventOverflow',
            options: { mainAxis: true, altAxis: true, padding: 10 },
          },
          {
            name: 'flip',
            enabled: false,
          },
          ...dataModifiers,
        ]);
      }

      popover.toggle();
    },
  };

  return presenter;
};
