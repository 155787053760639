import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { useTranslation } from 'APP/packages/translations';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';

import { useSelectDesktopSharingScreenPopupPresenter } from './SelectDesktopSharingScreenPopup.presenter';
import styles from './SelectDesktopSharingScreenPopup.styles.m.css';

export const SelectDesktopSharingScreenPopup: FC<ICommonPopupsProps> = observer((props) => {
  const presenter = useSelectDesktopSharingScreenPopupPresenter(props);
  const { t } = useTranslation();

  return (
    <Popup
      title={t('common_screen_sharing')}
      isOpened
      onClose={presenter.onClose}
    >
      <div className={classNames(styles.container, customScrollStyles.container)}>
        <div className={styles.grid}>
          {presenter.sources.map((source) => {
            return (
              <button
                key={source.id}
                onClick={(): void => presenter.onSelect(source.id)}
                className={styles.item}
              >
                <div className={styles.imageContainer}>
                  <img
                    src={source.thumbnailUrl}
                    className={styles.image}
                    alt=""
                  />
                </div>
                <p className={styles.title}>{source.name}</p>
              </button>
            );
          })}
        </div>
      </div>
    </Popup>
  );
});
