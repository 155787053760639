import { observer } from 'mobx-react';
import React, { FC, useMemo } from 'react';

import { AuthProvider } from 'APP/model/auth/authModel.types';
import { DeviceInfoPlatform } from 'APP/model/common/commonModel.types';
import { platformInfo } from 'APP/packages/platformInfo/platformInfo';
import { useTranslation } from 'APP/packages/translations';
import DeleteIcon from 'ICONS/ic-delete.svg';
import GoogleIcon from 'ICONS/ic-google.svg';
import { FAB } from 'UIKIT/FAB/FAB';
import popupItemStyles from 'UIKIT/PopupItem/styles.m.scss';

import { ProviderLayout } from '../ProviderLayout/ProviderLayout';
import { useProvidersPresenter } from '../hooks/useProviders';
import styles from './GoogleProvider.styles.m.css';
import { LinkGoogleProvider } from './LinkGoogleProvider/LinkGoogleProvider';

interface IGoogleProviderProps {
  displayName: string;
  userId: string;
}

export const GoogleProvider: FC<IGoogleProviderProps> = observer((props) => {
  const { displayName } = props;
  const { t } = useTranslation();

  const presenter = useProvidersPresenter();
  const isDesktop = platformInfo.platform === DeviceInfoPlatform.Desktop;

  const linkProvider = useMemo(() => {
    if (!isDesktop) {
      return <LinkGoogleProvider setIsProcessing={presenter.setIsProcessing} />;
    }

    return t('account_desktop_hint');
  }, [presenter.setIsProcessing]);

  return (
    <div className={popupItemStyles.container}>
      <ProviderLayout
        disabled={isDesktop}
        isProcessing={presenter.isProcessing}
        icon={<GoogleIcon className={styles.icon} />}
        title={t('account_google')}
        description={displayName || linkProvider}
        actions={
          displayName ? (
            <FAB
              size="20"
              theme="3"
              className={styles.deleteIcon}
              Icon={DeleteIcon}
              onClick={(): void => {
                presenter.onDelete(AuthProvider.Google);
              }}
            />
          ) : null
        }
      />
    </div>
  );
});
