import { insertItemToConcreteIndex } from '../helper';
import getActualSortedOrder from './getActualSortedOrder';

export default (payloads, startIndex, endIndex) => {
  const payload = payloads.find((payload) => payload.order === startIndex);

  const filteredPayloads = payloads.filter((payload) => payload.order !== startIndex);
  const data = insertItemToConcreteIndex(filteredPayloads, endIndex, payload);

  return getActualSortedOrder(data);
};
