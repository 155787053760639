import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { MessageViewPageType } from 'MAIN/ChatView/Messages/Messages.types';
import type { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

import { MessageLayout } from '../../MessageLayout/MessageLayout';
import { ForwardMessage } from '../components/ForwardMessage/ForwardMessage';
import { QuotedMessage } from '../components/QuotedMessage/QuotedMessage';
import { SenderName } from '../components/SenderName/SenderName';
import StatusBar from '../components/StatusBar';
import styles from './ButtonsBubble.styles.m.css';
import { CommandButtons } from './CommandButtons/CommandButtons';

interface IButtonsBubbleProps {
  message: ChatMessage;
  messageViewPageType: MessageViewPageType;
  isView: boolean;
}

export const ButtonsBubble: FC<IButtonsBubbleProps> = observer((props) => {
  const { message, children, messageViewPageType, isView } = props;
  const isShowForward = Boolean(message.forward && !message.isSavedMessagesLeft);

  const senderNameClasses = classNames(styles.senderName, {
    [styles.wideGap]: message.quotedMessage || isShowForward,
  });

  return (
    <MessageLayout
      message={message}
      className={styles.bubble}
      messageViewPageType={messageViewPageType}
      isView={isView}
    >
      <div className={styles.header}>
        <SenderName
          message={message}
          className={senderNameClasses}
        />
        {message.quotedMessage && (
          <QuotedMessage
            className={styles.quote}
            message={message.quotedMessage}
          />
        )}
        {isShowForward && (
          <ForwardMessage
            className={styles.forward}
            message={message}
          />
        )}
        <div
          className={styles.content}
          data-test="text-message"
        >
          {children}
          <StatusBar
            className={styles.statusBar}
            message={message}
          />
        </div>
      </div>
      <CommandButtons
        payload={message.payload}
        groupId={message.groupId}
      />
    </MessageLayout>
  );
});
