import { BlockStatusResponse } from 'APP/packages/api/types/model/blockStatusResponse';
import { BlockUserRequest } from 'APP/packages/api/types/model/blockUserRequest';
import { BooleanResponse } from 'APP/packages/api/types/model/booleanResponse';
import { CollectionResponseUserIds } from 'APP/packages/api/types/model/collectionResponseUserIds';
import { CursorPageUserDisplayData } from 'APP/packages/api/types/model/cursorPageUserDisplayData';
import { CursorRequest } from 'APP/packages/api/types/model/cursorRequest';
import { DisplayDataResponse } from 'APP/packages/api/types/model/displayDataResponse';
import { GetFriendsWebResponse } from 'APP/packages/api/types/model/getFriendsWebResponse';
import { GetUsersDisplayDataRequest } from 'APP/packages/api/types/model/getUsersDisplayDataRequest';
import { GetUsersDisplayDataResponse } from 'APP/packages/api/types/model/getUsersDisplayDataResponse';
import { MarkAsUnknownUserRequest } from 'APP/packages/api/types/model/markAsUnknownUserRequest';
import { UpdatePhoneBookRequest } from 'APP/packages/api/types/model/updatePhoneBookRequest';

import { request } from '../request/request';

const ROOT_API = 'relationships';

export const getFriendsData = (): Promise<GetFriendsWebResponse> => {
  return request({
    rootApi: ROOT_API,
    url: '/getFriendsData',
    method: 'POST',
  });
};

export const getBlockedUsers = (data: CursorRequest): Promise<CursorPageUserDisplayData> => {
  return request({
    rootApi: ROOT_API,
    url: '/getBlockedUsers',
    method: 'POST',
    body: data,
  });
};

export const getContacts = (): Promise<CollectionResponseUserIds> => {
  return request({
    rootApi: ROOT_API,
    url: '/getFriends',
    method: 'POST',
  });
};

export const updatePhoneBook = (data: UpdatePhoneBookRequest): Promise<DisplayDataResponse[]> => {
  return request({
    rootApi: ROOT_API,
    version: 'v1',
    url: '/updatePhoneBook',
    method: 'POST',
    body: data,
  });
};

export const unblockUser = (data: BlockUserRequest): Promise<BlockStatusResponse> => {
  return request({
    rootApi: ROOT_API,
    url: '/unblockUser',
    method: 'POST',
    body: data,
  });
};

export const blockUser = (data: BlockUserRequest): Promise<BlockStatusResponse> => {
  return request({
    rootApi: ROOT_API,
    url: '/blockUser',
    method: 'POST',
    body: data,
  });
};

export const getUsersDisplayData = (
  data: GetUsersDisplayDataRequest
): Promise<GetUsersDisplayDataResponse> => {
  return request({
    rootApi: ROOT_API,
    url: '/getUsersDisplayData',
    method: 'POST',
    body: data,
  });
};

export const markAsUnknown = (data: MarkAsUnknownUserRequest): Promise<BooleanResponse> => {
  return request({
    rootApi: ROOT_API,
    url: '/markAsUnknown',
    method: 'POST',
    body: data,
  });
};
