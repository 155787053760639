import { IUserMentionData } from 'APP/model/common/commonModel.types';
import { IMessageKeyWord } from 'APP/model/message/messageModel.types';

import addMissedTags from './parseToTree/addMissedTags';
import generateTree from './parseToTree/generateTree';
import getMissedTags from './parseToTree/getMissedTags';
import getSymbolsPositions from './parseToTree/getSymbolsPositions';
import mergeTagsInList from './parseToTree/mergeTagsInList';
import { TTree } from './parseToTree/parseToTree.types';
import removeTags from './parseToTree/removeTags';

interface IParseToTreeData {
  text: string;
  hasBotCommands?: boolean;
  isPlainText?: boolean;
  keyWords?: IMessageKeyWord[];
  userMentions?: IUserMentionData[];
}

export const parseToTree = (data: IParseToTreeData): TTree => {
  const {
    text,
    hasBotCommands = false,
    isPlainText = false,
    userMentions = [],
    keyWords = [],
  } = data;

  try {
    const sortedUserMentions = userMentions.sort((a, b) => b.nickname.length - a.nickname.length);
    const { hasSymbols, symbolsPositions } = getSymbolsPositions({
      userMentions: sortedUserMentions,
      hasBotCommands,
      text,
      keyWords,
    });

    if (!hasSymbols) {
      return [
        {
          tag: 'text',
          content: text,
        },
      ];
    }

    const positionToTag = mergeTagsInList({ symbolsPositions });

    // we should remove tags and show as plain text for pin / reply
    if (isPlainText) {
      const plainText = removeTags({ text, positionToTag });

      return [
        {
          tag: 'text',
          content: plainText,
        },
      ];
    }

    const missedTags = getMissedTags({ positionToTag });

    const { updatedText, updatedPositionToTag } = addMissedTags({
      text,
      positionToTag,
      missedTags,
    });

    const tree = generateTree({
      text: updatedText,
      positionToTag: updatedPositionToTag,
    });

    return tree;
  } catch (e) {
    return [
      {
        tag: 'text',
        content: text,
      },
    ];
  }
};
