import classNames from 'classnames';
import React, { FC } from 'react';

import { AudioState } from 'MAIN/components/AudioState/AudioState';
import { AudioPlayerTrackCaption } from 'UIKIT/Media/AudioPlayer/AudioPlayerUI/AudioPlayerTrackCaption/AudioPlayerTrackCaption';
import { PlayIconType } from 'UIKIT/PlayIcon/PlayIcon';

import { useSharedVoicePlayerPresenter } from './SharedVoicePlayer.presenter';
import styles from './SharedVoicePlayer.styles.m.css';

interface ISharedVoicePlayerProps {
  className?: string;
  messageId: string;
  groupId: string;
  payloadId: string;
  fileName: string;
  size: number;
  isPlaying: boolean;
  isActiveTrack: boolean;
  duration: number;
  progress: number;
  serverTime: number;
  onTogglePlay(): void;
}

export const SharedVoicePlayer: FC<ISharedVoicePlayerProps> = (props) => {
  const {
    className,
    messageId,
    groupId,
    payloadId,
    fileName,
    size,
    isPlaying,
    isActiveTrack,
    duration,
    progress,
    serverTime,
    onTogglePlay,
  } = props;

  const dateTime = useSharedVoicePlayerPresenter(serverTime);

  return (
    <div className={classNames(styles.container, className)}>
      <AudioState
        className={styles.icon}
        groupId={groupId}
        messageId={messageId}
        payloadId={payloadId}
        iconType={isPlaying ? PlayIconType.Pause : PlayIconType.Play}
        onClick={onTogglePlay}
      />
      <div>
        <div className={styles.title}>{fileName}</div>

        <AudioPlayerTrackCaption
          isPlaying={isActiveTrack}
          duration={duration}
          progress={progress}
          size={size}
          additionalInfo={dateTime}
          delimiter={' ⋅'}
        />
      </div>
    </div>
  );
};
