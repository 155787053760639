import { observer } from 'mobx-react';
import React from 'react';

import { useSystemMessageText } from 'MAIN/hooks/systemMessageText/useSystemMessageText';

import styles from './SystemPayload.styles.m.css';

export const SystemPayload = observer(({ group, payload }) => {
  const { html: systemMessageText } = useSystemMessageText(group, payload);

  return (
    <span
      className={styles.text}
      dangerouslySetInnerHTML={{ __html: systemMessageText }}
    />
  );
});
