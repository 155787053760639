import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { ReactionsPopover } from 'APP/components/ReactionsPopover/ReactionsPopover';
import { IconButton } from 'APP/packages/UiKit/IconButton';
import ReactionIcon from 'ICONS/reaction.svg';

import styles from '../styles.m.css';

const Reactions = ({ message, popover }) => {
  const containerClasses = classNames(styles.itemContainer, {
    [styles.opened]: popover.isOpened,
  });

  return (
    <>
      <IconButton
        Icon={ReactionIcon}
        setRef={popover.containerRef}
        className={containerClasses}
        iconClassName={styles.icon}
        theme="19"
        onMouseEnter={popover.mouseOver}
        onMouseLeave={popover.mouseOut}
      />
      {popover.isOpened && (
        <ReactionsPopover
          message={message}
          reference={popover.containerRef.current}
          onMouseEnter={popover.mouseOver}
          onMouseLeave={popover.mouseOut}
          onClose={popover.close}
        />
      )}
    </>
  );
};

export default observer(Reactions);
