import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { EmojiCategoryName } from 'APP/constants/emojis';

import { EmojiCategory } from './EmojiCategory/EmojiCategory';
import styles from './EmojiCategoryList.styles.m.css';

interface ICategoryListProps {
  categories: EmojiCategoryName[];
  checkIsActiveCategory(category: EmojiCategoryName): boolean;
  onEmojiCategoryClick(category: EmojiCategoryName): void;
}

export const EmojiCategoryList: FC<ICategoryListProps> = observer(
  ({ categories, checkIsActiveCategory, onEmojiCategoryClick }) => {
    return (
      <div className={styles.container}>
        {categories.map((category) => (
          <EmojiCategory
            key={category}
            category={category}
            isActive={checkIsActiveCategory(category)}
            onEmojiCategoryClick={onEmojiCategoryClick}
          />
        ))}
      </div>
    );
  }
);
