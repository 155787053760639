import { observer } from 'mobx-react';
import React from 'react';

import { useOpponentsVideo } from 'MAIN/OngoingCall/hooks/useOpponentsVideo/useOpponentsVideo';

import { OpponentsView } from '../OpponentsView/OpponentsView';
import styles from './OpponentList.styles.m.css';

export const OpponentList = observer(() => {
  const { opponentsKeys } = useOpponentsVideo();

  return (
    <div className={styles.container}>
      <OpponentsView opponentsKeys={opponentsKeys} />
    </div>
  );
});
