import React, { FC } from 'react';

import { Tab } from './Tab/Tab';

interface ITabsProps {
  tabs: string[];
  selectedTabIndex: number;
  onChange(index: number): void;
  className?: string;
  tabClassName?: string;
  tabTitleClassName?: string;
  underlineClassName?: string;
}

export const Tabs: FC<ITabsProps> = (props) => {
  const {
    tabs,
    selectedTabIndex,
    onChange,
    className,
    tabClassName,
    tabTitleClassName,
    underlineClassName,
  } = props;

  return (
    <div className={className}>
      {tabs.map((tab, index) => {
        return (
          <Tab
            key={tab}
            title={tab}
            value={index}
            activeValue={selectedTabIndex}
            onChange={onChange}
            className={tabClassName}
            tabTitleClassName={tabTitleClassName}
            underlineClassName={underlineClassName}
          />
        );
      })}
    </div>
  );
};
