import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { AddKeyPressHandler } from 'APP/packages/keyBoardHandler';
import { useTranslation } from 'APP/packages/translations';
import { Button } from 'UIKIT/Button/Button';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';
import { MenuItem } from 'UIKIT/MenuItem/MenuItem';
import { SwitchMenuItem } from 'UIKIT/SwitchMenuItem/SwitchMenuItem';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { useClosePopupOnNavigation } from '../../hooks/useClosePopupOnNavigation';
import {
  useSpacePermissionsPopupPresenter,
  ISpacePermissionsPopupParams,
} from './SpacePermissionsPopup.presenter';
import styles from './SpacePermissionsPopup.styles.m.css';

type TSpacePermissionsPopupProps = ICommonPopupsProps<ISpacePermissionsPopupParams>;

export const SpacePermissionsPopup: FC<TSpacePermissionsPopupProps> = observer((props) => {
  const { t } = useTranslation();
  const presenter = useSpacePermissionsPopupPresenter(props);
  useClosePopupOnNavigation(props.popupInstance);

  return (
    <Popup
      title={t('permissions_label')}
      isOpened
      isHidden={props.popupInstance.isHidden}
      handlePrevStep={presenter.hasBackButton ? presenter.onBack : null}
      onClose={presenter.onClose}
    >
      <AddKeyPressHandler onEsc={presenter.onClose} />
      <div className={classNames(styles.content, customScrollStyles.container)}>
        <h6 className={styles.caption}>{t('content_moderation_section')}</h6>
        {presenter.isShowChangeObsceneFilter && (
          <SwitchMenuItem
            className={styles.switchWithDescription}
            title={t('hide_offencive_messages')}
            description={t('сontent_moderation_hide_description')}
            isChecked={presenter.obsceneFilterEnabled}
            onChange={presenter.onToggleObsceneFilter}
          />
        )}
        <MenuItem
          className={styles.switch}
          title={t('manage_offencive_messages')}
          count={presenter.keywordsCount > 0 ? presenter.keywordsCount : undefined}
          onClick={presenter.onOpenKeywordFiltersPopup}
        />
      </div>

      {presenter.isShowFooter && (
        <div className={styles.footer}>
          <Button
            onClick={presenter.onSave}
            className={styles.button}
            isDisabled={!presenter.isEdited}
            theme="1"
          >
            {t('save')}
          </Button>
        </div>
      )}
    </Popup>
  );
});
