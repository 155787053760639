import { action } from 'mobx';

import Tasks from 'APP/Tasks';
import Entities from 'STORE';
import { IncomingCall } from 'STORE/IncomingCalls/IncomingCall/IncomingCall';

export class CallVisibleDurationService {
  store: IncomingCall;
  timer: ReturnType<typeof setTimeout>;

  constructor(store: IncomingCall) {
    this.store = store;

    if (this.store.isGroup) {
      this.timer = setTimeout(this.delete, this.store.callVisibleDuration);
    }
  }

  dispose(): void {
    clearTimeout(this.timer);
  }

  @action
  delete = (): void => {
    Tasks.feedback.checkStarsFeedback(this.store.channelId);
    Entities.IncomingCalls.delete(this.store.groupId, this.store.channelId);
  };
}
