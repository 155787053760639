import { IGraphicSize } from 'APP/model/common/commonModel.types';

import { TItemMediaPayloadType } from './ItemMedia/ItemMedia.types';

export interface IMediaMessagePayloadData {
  duration?: number;
  graphicSize?: IGraphicSize;
  localPreview?: string;
  file?: File | Blob;
  fileSize?: number;
  fileName?: string;
  previewUrl?: string;
}

export interface IMediaMessagePayload {
  id?: string;
  type: TItemMediaPayloadType;
  data: IMediaMessagePayloadData;
}

export enum ILocalMessageType {
  Album = 'album',
  Media = 'media',
  File = 'file',
}

export interface IRawMessage {
  id: number;
  type: ILocalMessageType;
  payloads: IMediaMessagePayload[];
}
