import { useLocalStore, useAsObservableSource } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { PayloadType } from 'APP/model/message/messageModel.types';
import { getAlbumLayout } from 'APP/packages/album';

import { PostAlbumImage } from './PostAlbumImage';
import { PostAlbumVideo } from './PostAlbumVideo';

const payloadComponents = {
  [PayloadType.Video]: PostAlbumVideo,
  [PayloadType.Image]: PostAlbumImage,
};

export const usePostAlbumPresenter = ({ message }) => {
  const source = useAsObservableSource({
    id: message.id,
    groupId: message.groupId,
    payloads: message.payload.payloads,
    comment: message.renderPayload?.comment || '',
    userMentions: message.payload?.userMentions,
  });

  const presenter = useLocalStore(() => ({
    get isShowComment() {
      return presenter.comment && presenter.comment.length;
    },

    get layout() {
      const sizes = source.payloads.map(({ payload }, idx) => ({
        w: payload.graphicSize.width,
        h: payload.graphicSize.height,
        id: idx,
      }));

      return getAlbumLayout(sizes).map((row, idx) => ({ id: idx, items: row }));
    },

    get comment() {
      return source.comment;
    },

    get userMentions() {
      return source.userMentions;
    },

    getPayload(id) {
      return source.payloads[id];
    },

    getPayloadComponent(type) {
      return payloadComponents[type] || null;
    },

    onOpenMediaGallery(mediaId) {
      Tasks.group.openMediaGallery({
        mediaId,
        groupId: source.groupId,
        messageId: source.id,
        messageIds: [source.id],
      });
    },
  }));

  return presenter;
};
