import classNames from 'classnames';
import React, { FC } from 'react';

import { MessageViews } from 'APP/components/MessageViews/MessageViews';
import { ReactionButton } from 'APP/components/ReactionButton/ReactionButton';
import { Reactions } from 'APP/components/Reactions/Reactions';
import TextRenderer from 'APP/components/TextRendererCollapsed';
import { IconButton } from 'APP/packages/UiKit/IconButton';
import CommentIcon from 'ICONS/ic-comments.svg';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';

import { IMediaGalleryFile, TCloseHandler } from '../MediaGallery.types';
import { useMediaGallerySidebarPresenter } from './MediaGallerySidebar.presenter';
import styles from './MediaGallerySidebar.styles.m.css';

interface IMediaGallerySidebarProps {
  file: IMediaGalleryFile;
  onClose: TCloseHandler;
}

export const MediaGallerySidebar: FC<IMediaGallerySidebarProps> = ({ file, onClose }) => {
  const presenter = useMediaGallerySidebarPresenter({ file, onClose });

  return (
    <div className={styles.container}>
      <div className={classNames(styles.content, customScrollStyles.container)}>
        {presenter.description && (
          <TextRenderer
            className={styles.descriptionWrapper}
            textClassName={styles.description}
            keyWords={presenter.payload?.keyWords}
            userMentions={presenter.payload?.userMentions}
            groupId={file.message.groupId}
          >
            {presenter.description}
          </TextRenderer>
        )}

        <Reactions
          message={file.message}
          className={styles.reactions}
          theme="3"
        />

        {presenter.isShowFooter && (
          <>
            <hr className={styles.divider} />
            <div className={styles.footer}>
              <ReactionButton
                message={file.message}
                buttonTheme="21"
              />

              {presenter.canLeaveComment && (
                <IconButton
                  className={styles.commentsButton}
                  Icon={CommentIcon}
                  theme="21"
                  text={presenter.commentsCount}
                  onClick={presenter.onCommentsClick}
                />
              )}

              <MessageViews
                message={file.message}
                className={styles.views}
                showStatus={false}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
