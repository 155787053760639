import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useLocation } from 'react-router-dom';

import { isMarketUrl } from 'APP/Tasks/market/isMarketUrl';
import { RouterLink } from 'APP/router/constants';
import { Space } from 'STORE/Spaces/Space';

interface ISpacePanelPresenter {
  spaceRoute: string;
  marketsRoute: string;
  marketRoute: string;
  isShowMarketsRoute: boolean;
  isShowPrimaryMarketRoute: boolean;
}

export function useSpacePanelPresenter(space: Space | null): ISpacePanelPresenter {
  const location = useLocation();
  const source = useAsObservableSource({ location, space });

  const presenter = useLocalStore<ISpacePanelPresenter>(() => ({
    get spaceRoute(): string {
      return RouterLink.space.replace(':id', source.space?.id || '');
    },

    get marketRoute(): string {
      return RouterLink.spaceMarket.replace(':id', source.space?.id || '');
    },

    get marketsRoute(): string {
      return RouterLink.spaceMarkets.replace(':id', source.space?.id || '');
    },

    get isShowMarketsRoute(): boolean {
      return (
        (!!source.space?.isOwner ||
          !!source.space?.marketsCount ||
          source.location.pathname.includes(presenter.marketsRoute)) &&
        !presenter.isShowPrimaryMarketRoute
      );
    },

    get isShowPrimaryMarketRoute(): boolean {
      return (
        !source.space?.isOwner &&
        source.space?.marketsCount === 1 &&
        !!source.space?.primaryMarketUrl &&
        isMarketUrl(source.space?.primaryMarketUrl)
      );
    },
  }));

  return presenter;
}
