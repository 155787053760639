import React, { FC } from 'react';

import type { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';
import { IPopover } from 'UIKIT/Popover/Popover.types';
import { PopoverMenu } from 'UIKIT/PopoverMenu/PopoverMenu';

import { useSenderPopoverMenuForAdminPresenter } from './SenderPopoverMenuForAdmin.presenter';

interface ISenderPopoverMenuProps {
  message: ChatMessage;
  senderId: string;
  popover: IPopover;
}

export const SenderPopoverMenuForAdmin: FC<ISenderPopoverMenuProps> = (props) => {
  const { message, senderId, popover } = props;

  const presenter = useSenderPopoverMenuForAdminPresenter({ message, senderId });

  return (
    <PopoverMenu
      isOpened={popover.isOpened}
      menuItems={presenter.menuItems}
      onClose={popover.close}
      reference={popover.containerRef.current}
      placement="top-start"
    />
  );
};
