import { useLocalStore } from 'mobx-react';

import Entities from 'APP/store';

interface IMainInfoBlockPresenter {
  hasBackgroundImage: boolean;
}

export const useMainInfoBlockPresenter = (): IMainInfoBlockPresenter => {
  const presenter = useLocalStore<IMainInfoBlockPresenter>(() => ({
    get hasBackgroundImage(): boolean {
      return Entities.appearance.hasChatBackground;
    },
  }));

  return presenter;
};
