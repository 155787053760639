import Tasks from 'APP/Tasks';
import Entities from 'APP/store';

import loadPreview from './loadPreview';

let timeoutId = null;

function checkLink() {
  const inputPanel = Entities.InputPanelsStore.activeInputPanel;

  if (!inputPanel) {
    return;
  }

  const value = inputPanel.value;

  if (!value) {
    Tasks.messaging.resetLinkPreviewProcess({
      groupId: inputPanel.groupId,
    });

    return;
  }

  if (inputPanel.linkPreviewCancelled || inputPanel.isLoadingPreview) {
    return;
  }

  const links = Tasks.app.searchLinks(value);
  const link = links[0] || null;

  if (!link) {
    inputPanel.setIsShowLinkPreview(false);
    inputPanel.setLink(null);

    return false;
  }

  inputPanel.setLink(link);
  if (inputPanel.linkPreviews.get(link)) {
    inputPanel.setIsShowLinkPreview(true);
  } else if (!inputPanel.linkPreviews.has(link)) {
    loadPreview({ inputPanel, link });
  }
}

export default async () => {
  if (timeoutId) {
    clearTimeout(timeoutId);
  }

  timeoutId = setTimeout(() => {
    checkLink();
  }, 500);
};
