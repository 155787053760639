export const useTextPayloadPresenter = (payload) => {
  return {
    get isShowLinkPreview() {
      return !!payload?.linkPreview;
    },

    get isShowText() {
      return !payload?.linkPreview;
    },
  };
};
