import { StreamType } from 'APP/constants/calls';
import Entities from 'APP/store';

let isVideoMuting = false;

export const muteVideo = async (): Promise<void> => {
  if (!isVideoMuting) {
    isVideoMuting = true;
    const call = Entities.Calls.calls[0];
    if (call) {
      await call.me.audioVideoStreamer.muteVideo();
      await call.me.setVideoMuted(true);
    }
    isVideoMuting = false;
  }
};

let isVideoUnmuting = false;

export const unmuteVideo = async (): Promise<void> => {
  if (!isVideoUnmuting) {
    isVideoUnmuting = true;
    const call = Entities.Calls.calls[0];

    if (call) {
      const canUnmute = await call.me.canUnmute(StreamType.Video);

      if (canUnmute) {
        await call.me.audioVideoStreamer.unmuteVideo(true);
        await call.me.setVideoMuted(false);
      }
    }
    isVideoUnmuting = false;
  }
};
