import { observer } from 'mobx-react';
import React, { FC } from 'react';

import Entities from 'STORE';

import { BackgroundPattern } from './BackgroundPattern/BackgroundPattern';

export const ChatBackground: FC = observer(() => {
  return <BackgroundPattern pattern={Entities.appearance.chatBackground} />;
});
