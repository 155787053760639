import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { UserAvatar } from 'APP/components/Avatar/UserAvatar/UserAvatar';
import { ProfileNameBadge } from 'APP/components/ProfileNameBadge/ProfileNameBadge';
import { useTranslation } from 'APP/packages/translations';
import { Popup } from 'STORE/App/Popups/Popup/Popup';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';
import { Button } from 'UIKIT/Button/Button';
import { IconView } from 'UIKIT/IconView/IconView';
import { MenuItem } from 'UIKIT/MenuItem/MenuItem';

import { PhoneNumber } from '../components/PhoneNumber/PhoneNumber';
import { ProfileBio } from '../components/ProfileBio/ProfileBio';
import { ProfileNickname } from '../components/ProfileNickname/ProfileNickname';
import { useMyProfilePresenter } from './MyProfile.presenter';
import styles from './MyProfile.styles.m.css';

interface IMyProfileProps {
  className?: string;
  metaBlockClassName?: string;
  popupInstance?: Popup;
}

export const MyProfile: FC<IMyProfileProps> = observer(
  ({ className, metaBlockClassName, popupInstance }) => {
    const { t } = useTranslation();
    const presenter = useMyProfilePresenter(popupInstance);

    return (
      <div className={className}>
        <div className={styles.header}>
          <UserAvatar
            className={styles.avatar}
            id={presenter.me?.id}
            type={AvatarTypes.Round_30}
            withFullScreen={true}
          />
          <div className={styles.info}>
            <h3
              className={styles.title}
              data-test="username"
            >
              {presenter.me?.userName}
              <ProfileNameBadge
                verified={presenter.isVerified}
                inlineBadge
                source={presenter.userNameBadge?.source}
                animated={presenter.userNameBadge?.animated}
                displayUILayer={1}
              />
            </h3>
            <Button
              className={styles.actionButton}
              onClick={presenter.openEditProfile}
              theme="2"
            >
              {t('edit_profile')}
            </Button>
          </div>
        </div>

        {presenter.me && (
          <ProfileNickname
            className={classNames(styles.contact, metaBlockClassName)}
            user={presenter.me}
          />
        )}
        {presenter.me?.displayPhone && (
          <PhoneNumber
            className={classNames(styles.contact, metaBlockClassName)}
            phoneNumber={presenter.me.displayPhone}
            onChangePhone={presenter.openChangePhone}
          />
        )}
        {presenter.me?.bio && (
          <ProfileBio
            className={classNames(styles.contact, metaBlockClassName)}
            text={presenter.me.bio}
          />
        )}

        <div className={styles.dividerBackground} />
        {presenter.menuItems.map(({ key, title, Icon, onClick, withDot }) => (
          <MenuItem
            className={styles.listItem}
            titleClassName={styles.listItemTitle}
            key={key}
            title={title}
            icon={
              <IconView
                Icon={Icon}
                className={styles.listIcon}
              />
            }
            onClick={onClick}
            withDot={withDot}
          />
        ))}

        <div className={styles.dividerBackground} />
        {presenter.additionalMenuItems.map(({ key, title, Icon, onClick }) => (
          <MenuItem
            className={styles.listItem}
            titleClassName={styles.listItemTitle}
            key={key}
            title={title}
            icon={
              <IconView
                Icon={Icon}
                className={styles.listIcon}
              />
            }
            onClick={onClick}
          />
        ))}
      </div>
    );
  }
);
