import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { TextFormattingInput } from 'APP/components/TextFormattingInput/TextFormattingInput';
import { useTranslation } from 'APP/packages/translations';
import { useCloseMediaMessageOnNavigation } from 'MAIN/PopupManager/Popups/MediaMessagePopup/hooks/useCloseMediaMessageOnNavigation';
import { useContextMenu } from 'MAIN/hooks/useContextMenu/useContextMenu';
import { Button } from 'UIKIT/Button/Button';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';
import { Loader } from 'UIKIT/Loader/Loader';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { ItemMedia } from './ItemMedia/ItemMedia';
import { MediaMessageAlbum } from './MediaMessageAlbum/MediaMessageAlbum';
import {
  IMediaMessagePopupPresenterParams,
  useMediaMessagePopupPresenter,
} from './MediaMessagePopup.presenter';
import styles from './MediaMessagePopup.styles.m.css';
import { ILocalMessageType } from './MediaMessagePopup.types';
import { MediaMessageSubmitMenu } from './MediaMessageSubmitMenu/MediaMessageSubmitMenu';
import { MediaMessageCheckbox } from './components/MediaMessageCheckbox/MediaMessageCheckbox';

type IMediaMessagePopopProps = IMediaMessagePopupPresenterParams;

export const MediaMessagePopup: FC<ICommonPopupsProps<IMediaMessagePopopProps>> = observer(
  (props) => {
    const { t } = useTranslation();
    const presenter = useMediaMessagePopupPresenter(props);
    const { popover, onContextMenu } = useContextMenu<HTMLButtonElement>();
    useCloseMediaMessageOnNavigation(props.popupInstance, props.params!.groupId);

    return (
      <Popup
        isOpened
        onClose={presenter.onClose}
        title={presenter.popupTitle}
        className={styles.container}
      >
        <div className={classNames(styles.content, customScrollStyles.container)}>
          {presenter.isLoading && (
            <div className={styles.loader}>
              <Loader color={'base'} />
            </div>
          )}

          {presenter.rawMessages.map((message) => {
            return message.type === ILocalMessageType.Album ? (
              <MediaMessageAlbum
                payloads={message.payloads}
                key={message.id}
                onRemove={presenter.canBeRemove ? presenter.remove : undefined}
              />
            ) : (
              <ItemMedia
                key={message.id}
                payload={message.payloads[0]}
                onRemove={presenter.canBeRemove && presenter.remove}
              />
            );
          })}
        </div>

        <div className={styles.footer}>
          {presenter.isShowSendMediaAsFile && (
            <MediaMessageCheckbox
              className={styles.sendAsFileCheckbox}
              onChange={presenter.onChangeSendAsFile}
              isChecked={presenter.isSendMediaAsFile}
              label={t('attach_send_as_file')}
              isDisabled={presenter.isRecalculateProcess}
            />
          )}

          <TextFormattingInput
            className={styles.inputContainer}
            inputClassName={styles.input}
            placeholder={t('common_caption')}
            value={presenter.caption}
            onChange={presenter.onChange}
            onSubmit={presenter.onInputSubmit}
          />

          <Button
            setRef={popover.containerRef}
            className={styles.button}
            theme="1"
            onClick={(): Promise<void> => presenter.onSubmit()}
            onContextMenu={onContextMenu}
          >
            {presenter.successTitle}
          </Button>
        </div>

        {presenter.isShowSubmitMenu && (
          <MediaMessageSubmitMenu
            popover={popover}
            onSubmit={presenter.onSubmit}
          />
        )}
      </Popup>
    );
  }
);
