import { useLocalStore } from 'mobx-react';

import Entities from 'APP/store';

export default () => {
  const presenter = useLocalStore(() => ({
    get group() {
      return Entities.GroupsStore.getGroupById(Entities.ActiveStream.stream?.groupId);
    },

    get title() {
      return Entities.ActiveStream.stream?.title || presenter.group.title;
    },
  }));

  return presenter;
};
