import { observer } from 'mobx-react';
import React from 'react';

import { HugeCallAvatar } from 'MAIN/OngoingCall/Shared/Avatars/HugeCallAvatar/HugeCallAvatar';

import { AnimatedBackgroundPanel } from '../../Shared/AnimatedBackgroundPanel/AnimatedBackgroundPanel';
import { AnimatedRightPanel } from '../Shared/AnimatedRightPanel/AnimatedRightPanel';
import { CallingPlaceholder } from '../Shared/CallingPlaceholder/CallingPlaceholder';
import { FullScreenCallViewPanel } from '../Shared/FullScreenCallViewPanel/FullScreenCallViewPanel';
import { FullScreenGridLayout } from '../Shared/FullScreenGridLayout/FullScreenGridLayout';
import { SpeakerVideo } from '../Shared/SpeakerVideo/SpeakerVideo';
import { useGroupCallPresenter } from './GroupCall.presenter';
import styles from './GroupCall.styles.m.css';

export const GroupCall = observer(() => {
  const presenter = useGroupCallPresenter();

  return (
    <div className={styles.container}>
      <div className={styles.callContent}>
        {!presenter.opponentsLength && (
          <div className={styles.centerContent}>
            <div className={styles.avatarContainer}>
              <HugeCallAvatar avatar={presenter.ongoingCall.call.avatar} />
            </div>
            <CallingPlaceholder call={presenter.ongoingCall.call} />
          </div>
        )}

        {!!presenter.opponentsLength && presenter.isGridLayoutView && <FullScreenGridLayout />}

        {!!presenter.opponentsLength && presenter.isSpeakerView && <SpeakerVideo />}

        <FullScreenCallViewPanel hasTitle={!!presenter.opponentsLength} />
      </div>
      <AnimatedRightPanel />
      <AnimatedBackgroundPanel />
    </div>
  );
});
