import execCommand from 'execcommand-copy';
import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { MouseEvent, useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { ALERT_TYPES } from 'APP/constants/app';
import { PayloadType } from 'APP/model/message/messageModel.types';
import { ISticker, IStickerPackExtend } from 'APP/model/stickers/stickersModel.types';
import { useTranslation } from 'APP/packages/translations';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import Entities from 'APP/store';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import { Group } from 'STORE/Groups/Group';
import { Schedule } from 'STORE/Groups/Schedule';

export interface IStickerPackPopupParams {
  stickerPackId: string;
}

interface IStickerPackPopupPresenter {
  isLoading: boolean;
  isProgress: boolean;
  isAvailableToShow: boolean;
  pack: IStickerPackExtend | null;
  isSharable: boolean;
  isAddedPack: boolean;
  hasBackButton: boolean;
  copyLink(): void;
  toggle(event: MouseEvent): Promise<void>;
  onBack(): void;
  onClose(): void;
  sendSticker(sticker: ISticker): void;
  loadStickerPack(): Promise<void>;
}

export function useStickerPackPopupPresenter({
  popupInstance,
  params,
  onBack,
  onClose,
}: ICommonPopupsProps<IStickerPackPopupParams>): IStickerPackPopupPresenter {
  const { stickerPackId } = params!;
  const navigateTo = useNavigateTo();
  const { t } = useTranslation();

  const source = useAsObservableSource({ navigateTo });

  const presenter = useLocalStore<IStickerPackPopupPresenter>(() => ({
    isLoading: false,
    isProgress: false,

    get isAvailableToShow(): boolean {
      return !!presenter.pack || presenter.isLoading;
    },

    get pack(): IStickerPackExtend | null {
      return Entities.stickersStore.getById(stickerPackId);
    },

    get isSharable(): boolean {
      return presenter.pack?.stickerPack.isSharable || false;
    },

    get isAddedPack(): boolean {
      if (!presenter.pack) {
        return false;
      }

      return Entities.stickersStore.installedPackIds.includes(presenter.pack.stickerPack.id);
    },

    get hasBackButton(): boolean {
      return !!onBack;
    },

    copyLink(): void {
      if (
        presenter.pack &&
        presenter.pack.stickerPack.isSharable &&
        presenter.pack.stickerPack.deepLink
      ) {
        execCommand.copy(presenter.pack.stickerPack.deepLink.deepLink);
        Entities.toast.show(t('common_link_copied'));
        return;
      }
      Tasks.app.addAlert({
        type: ALERT_TYPES.PRIVATE_STICKER_ALERT,
      });
    },

    async toggle(event: MouseEvent): Promise<void> {
      event.stopPropagation();
      event.preventDefault();

      if (!presenter.pack) {
        return;
      }

      presenter.isProgress = true;

      if (presenter.isAddedPack) {
        await Tasks.stickers.removeStickerPackFromProfile(presenter.pack.stickerPack.id);
      } else {
        await Tasks.stickers.addStickerPackToProfile(presenter.pack.stickerPack.id);
      }

      presenter.isProgress = false;
    },

    onClose(): void {
      popupInstance.close();
      onClose?.();
    },

    onBack(): void {
      popupInstance.close();
      onBack?.();
    },

    sendSticker(sticker: ISticker): void {
      const group: Group | null = Entities.GroupsStore.activeGroup;
      if (!group) {
        return;
      }

      const payloadType = PayloadType.Sticker;
      const groupId = group instanceof Schedule ? group.groupId! : group.id;

      const canSend = Tasks.group.checkSendMessagePermissions({ groupId, payloadType });
      if (!canSend.result) {
        canSend.error && Entities.toast.show(canSend.error);
        return;
      }

      const messages = [
        {
          type: payloadType,
          data: sticker,
          groupId,
          quotedMessage: Entities.InputPanelsStore.activeInputPanel.quoteMessage,
        },
      ];

      Entities.InputPanel.payloads.setPayloads(messages);
      if (group instanceof Schedule) {
        Tasks.group.goToScheduleSettings({ navigateTo: source.navigateTo });
      } else {
        Entities.InputPanel.send.submit();
      }
      presenter.onClose();
    },

    async loadStickerPack(): Promise<void> {
      if (presenter.pack) {
        return;
      }

      presenter.isLoading = true;
      const isLoaded = await Tasks.stickers.loadStickerPackById(stickerPackId);
      presenter.isLoading = false;

      if (!isLoaded) {
        presenter.onClose();
        Tasks.app.addAlert({
          type: ALERT_TYPES.STICKERS_WERE_DELETED,
        });
      }
    },
  }));

  useEffect(() => {
    presenter.loadStickerPack();
  }, []);

  return presenter;
}
