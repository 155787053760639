import { observer } from 'mobx-react';
import React from 'react';

import { AddKeyPressHandler } from 'APP/packages/keyBoardHandler';
import { useTranslation } from 'APP/packages/translations';
import { FullscreenPopup } from 'UIKIT/FullscreenPopup/FullscreenPopup';
import { Portal } from 'UIKIT/Portal/Portal';

import NoVideo from '../SharedComponents/NoVideo';
import SharedScreen from '../SharedComponents/SharedScreen';
import StreamerMeta from '../SharedComponents/StreamerMeta';
import Streamers from '../SharedComponents/Streamers';
import Video from '../SharedComponents/Video';
import ControlPanel from './ControlPanel';
import Header from './Header';
import MinimizeView from './MinimizeView';
import usePresenter from './presenter';
import styles from './styles.m.css';

const ParticipantView = () => {
  const { presenter, streamerPresenter } = usePresenter();
  const { t } = useTranslation();

  if (presenter.isFullScreen) {
    return (
      <FullscreenPopup className={styles.fullScreen}>
        <AddKeyPressHandler onEsc={presenter.collapse} />
        <div className={styles.container}>
          <div className={styles.main}>
            {presenter.isStreamStarted && (
              <Streamers
                className={styles.streamers}
                onSetActiveStreamer={streamerPresenter.setActiveStreamer}
              />
            )}
            <div className={styles.speaker}>
              {presenter.primaryVideo ? (
                streamerPresenter.isSharedScreen &&
                presenter.stream.activeStreamer.isSharedScreen ? (
                  <SharedScreen />
                ) : (
                  <Video
                    key={presenter.primaryVideo.getTrackId()}
                    videoTrack={presenter.primaryVideo}
                  />
                )
              ) : (
                <NoVideo />
              )}
              {presenter.isStreamStarted && streamerPresenter.activeStreamer.streamer && (
                <StreamerMeta
                  className={styles.meta}
                  streamer={streamerPresenter.activeStreamer.streamer}
                  isMinimize={false}
                />
              )}
            </div>
            {presenter.hasShowControl && (
              <ControlPanel
                presenter={presenter}
                streamerPresenter={streamerPresenter}
                isCommentsHidden={!presenter.canLeaveComment}
              />
            )}
            {presenter.isStreamFinished && (
              <div className={styles.finished}>
                <div className={styles.finishTitle}>{t('stream_finished_title')}</div>
              </div>
            )}
            <Header presenter={presenter} />
          </div>
        </div>
      </FullscreenPopup>
    );
  } else {
    return (
      <Portal>
        <MinimizeView
          presenter={presenter}
          isFinished={presenter.isStreamFinished}
        />
      </Portal>
    );
  }
};

export default observer(ParticipantView);
