import { RouterState } from 'APP/router/constants';
import { getLocation } from 'APP/router/refs';
import { replaceTo } from 'APP/router/refs/replaceTo';
import { IMediaGalleryState } from 'APP/types/mediaGallery';

export const updateMediaGallery = (mediaState: Partial<IMediaGalleryState>): void => {
  const location = getLocation();

  const prevMediaState = location.state[RouterState.mediaGallery];

  replaceTo({
    state: {
      [RouterState.mediaGallery]: {
        groupId: mediaState.groupId || prevMediaState.groupId,
        mediaId: mediaState.mediaId || prevMediaState.mediaId,
        messageId: mediaState.messageId || prevMediaState.messageId,
        messageIds: mediaState.messageIds || prevMediaState.messageIds || [],
        reverse: mediaState.reverse !== undefined ? mediaState.reverse : prevMediaState.reverse,
        showMessageInfo:
          mediaState.showMessageInfo !== undefined
            ? mediaState.showMessageInfo
            : prevMediaState.showMessageInfo,
      },
    },
    keepOldQuery: true,
    keepOldState: true,
    keepOldPathname: true,
  });
};
