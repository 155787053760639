import React, { FC } from 'react';

import { VerifiedBadge } from 'APP/components/VerifiedBadge/VerifiedBadge';
import { Avatar } from 'UIKIT/Avatar/Avatar';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';
import { IconView, IconViewSizes } from 'UIKIT/IconView/IconView';

import { ICatalogWidgetComponentProps } from '../CatalogWidget.types';
import styles from './ThreeColumnWidget.styles.m.css';

export const ThreeColumnWidget: FC<ICatalogWidgetComponentProps> = (props) => {
  const { className, title, items } = props;

  if (items.length === 0) {
    return null;
  }

  return (
    <div className={className}>
      {title && <h3 className={styles.title}>{title}</h3>}

      <div className={styles.content}>
        {items.map((item) => (
          <div
            key={item.id}
            role="button"
            tabIndex={0}
            className={styles.item}
            onClick={item.onClick}
          >
            <Avatar
              className={styles.itemAvatar}
              type={AvatarTypes.Square_40}
              title={item.title}
              url={item.avatarUrl}
              backgroundColorIndex={item.avatarColorIndex}
            />

            <div className={styles.itemContent}>
              <h6 className={styles.itemTitleContainer}>
                {item.icon && (
                  <IconView
                    className={styles.itemIcon}
                    size={IconViewSizes.Small}
                    Icon={item.icon}
                  />
                )}
                <span className={styles.itemTitle}>{item.title}</span>
                {item.verified && <VerifiedBadge />}
              </h6>
              <div className={styles.itemSubscribers}>{item.subscribers}</div>
            </div>
          </div>
        ))}

        <div className={styles.bottomLine} />
      </div>
    </div>
  );
};
