import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Tasks from 'APP/Tasks';
import api from 'APP/packages/api';
import bus from 'APP/packages/bus';
import logger from 'APP/packages/logger';
import Entities from 'APP/store';

import { IBlackListLocalStore } from './BlackList.types';

export const useBlackListPresenter = (): IBlackListLocalStore => {
  const history = useHistory();

  const presenter = useLocalStore<IBlackListLocalStore>(() => ({
    CURSOR_STEP: 50,
    cursor: 0,
    isLoading: true,
    activeUser: null,
    menuRef: null,

    ids: [],

    get group(): IBlackListLocalStore['group'] {
      return Entities.GroupsStore.activeGroup;
    },

    get isOpened(): IBlackListLocalStore['isOpened'] {
      if (!presenter.group) {
        return false;
      }

      return presenter.group.withMeInAdmins;
    },

    init: async (): Promise<void> => {
      presenter.isLoading = true;
      presenter.ids = [];
      try {
        const response = await api.group.getBannedUsersForGroup({
          groupId: presenter.group?.id,
        });
        presenter.ids = [];
        if (response?.bannedUserIds?.length > 0) {
          presenter.ids = response.bannedUserIds;
        }
      } catch (error) {
        presenter.ids = [];
        logger.get('API').error('getGroupUserRoles', error);
      }

      presenter.cursor = 0;
      presenter.updateCursor();
    },

    updateCursor: async (): Promise<void> => {
      presenter.isLoading = true;

      const nextCursor = presenter.cursor + presenter.CURSOR_STEP;

      const usersIds = presenter.ids.slice(presenter.cursor, nextCursor);

      if (usersIds.length > 0) {
        await Tasks.relationships.loadUnknownUsersByIds({
          usersIds,
        });
      }
      presenter.cursor = nextCursor;
      presenter.isLoading = false;
    },

    onEndReached(): void {
      presenter.updateCursor();
    },

    get users(): IBlackListLocalStore['users'] {
      return presenter.ids.slice(0, presenter.cursor).map((id) => {
        return Entities.UsersStore.getUserById(id.toString());
      });
    },

    toggleMenu(ref, user): void {
      if (presenter.activeUser !== user && ref) {
        presenter.menuRef = ref;
        presenter.activeUser = user;
      } else {
        presenter.menuRef = null;
        presenter.activeUser = null;
      }
    },

    get isOpenedMenu(): IBlackListLocalStore['isOpenedMenu'] {
      return !!presenter.activeUser;
    },

    get hasMore(): IBlackListLocalStore['hasMore'] {
      return presenter.cursor < presenter.ids.length;
    },

    close: (): void => {
      history.goBack();
    },

    keyExtractor(blockedUser): string {
      return blockedUser.id;
    },
  }));

  useEffect(() => {
    if (!presenter.isOpened) {
      presenter.close();
    } else {
      presenter.init();
    }
  }, [presenter.isOpened]);

  useEffect(() => {
    const unsubscribe = bus.group.subscribeParticipantsChanges(presenter.group?.id, () => {
      presenter.init();
    });

    return (): void => {
      unsubscribe();
    };
  }, [presenter.group?.id]);

  return presenter;
};
