import api from 'APP/packages/api';
import logger from 'APP/packages/logger';

interface IClearCallPermissionsForUserData {
  channelId: string;
  userId: string;
}

export const clearCallPermissionsForUser = async ({
  channelId,
  userId,
}: IClearCallPermissionsForUserData): Promise<void> => {
  try {
    await api.calls.clearPermissionsForUser({
      channelId,
      userId,
    });
  } catch (error) {
    logger.get('API').error('calls.clearCallPermissionsForUser', error);
  }
};
