import Tasks from 'APP/Tasks';
import { showConfirm, showConfirmWithCheckbox } from 'APP/Tasks/app/app';
import { openPopup } from 'APP/Tasks/app/popup/popup';
import { showToast } from 'APP/Tasks/app/showToast/showToast';
import { IDeleteMessagesMode, PayloadType } from 'APP/model/message/messageModel.types';
import { getOriginalSizeImage } from 'APP/packages/imageResizer';
import { BUCKET_MEDIA_HOST } from 'APP/packages/imageResizer/constants';
import { platformInfo } from 'APP/packages/platformInfo/platformInfo';
import { useTranslation } from 'APP/packages/translations';
import { RouterPage, RouterState } from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import Entities from 'APP/store';
import {
  CLIPBOARD_SUPPORTED,
  copyImageToClipboard,
  copyTextToClipboard,
} from 'APP/utils/clipboard/clipboard';
import CopyLink from 'ICONS/ic-add-link.svg';
import CalendarIcon from 'ICONS/ic-calendar.svg';
import CopyImageIcon from 'ICONS/ic-copy-image-24.svg';
import SelectIcon from 'ICONS/ic-done.svg';
import DownloadIcon from 'ICONS/ic-download.svg';
import GoToMessageIcon from 'ICONS/ic-go-to-message.svg';
import InfoIcon from 'ICONS/ic-info.svg';
import LinkIcon from 'ICONS/ic-link.svg';
import ResendIcon from 'ICONS/ic-messages-resend.svg';
import IconComment from 'ICONS/ic-messages.svg';
import SendIcon from 'ICONS/ic-publish.svg';
import ReportIcon from 'ICONS/ic-report.svg';
import CopyIcon from 'ICONS/ic-s-copy.svg';
import DeleteIcon from 'ICONS/ic-s-delete.svg';
import EditIcon from 'ICONS/ic-s-edit.svg';
import ForwardIcon from 'ICONS/ic-s-forward.svg';
import PinIcon from 'ICONS/ic-s-pin.svg';
import ReplayIcon from 'ICONS/ic-s-reply.svg';
import UnPinIcon from 'ICONS/ic-s-unpin.svg';
import StarIcon from 'ICONS/ic-star-s.svg';
import TranslateIcon from 'ICONS/ic-translate.svg';
import { MessageViewPageType } from 'MAIN/ChatView/Messages/Messages.types';
import { AddToSpaceCollectionPopup } from 'MAIN/PopupManager/Popups/AddToSpaceCollectionPopup/AddToSpaceCollectionPopup';
import { ForwardMessagePopup } from 'MAIN/PopupManager/Popups/ForwardMessagePopup/ForwardMessagePopup';
import { ReactionsDetailedPopup } from 'MAIN/PopupManager/Popups/ReactionsDetailedPopup/ReactionsDetailedPopup';
import { ReportMessagePopup } from 'MAIN/PopupManager/Popups/ReportMessagePopup/ReportMessagePopup';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';
import { MenuItemStyle } from 'UIKIT/MenuItem/MenuItem.types';
import { IPopoverMenuItem } from 'UIKIT/PopoverMenu/PopoverMenu.types';
import { getMessageTextForCopy } from 'UTILS/message/message';

export interface IContextMenuPresenterParams {
  message: ChatMessage;
  messageViewPageType: MessageViewPageType;
  targetClick: HTMLElement | null;
}

interface IContextMenuPresenter {
  menuItems: IPopoverMenuItem[];
}

export const useContextMenuPresenter = ({
  message,
  messageViewPageType,
  targetClick,
}: IContextMenuPresenterParams): IContextMenuPresenter => {
  const navigateTo = useNavigateTo();
  const { t } = useTranslation();

  const isLink = targetClick?.tagName.toLowerCase() === 'a';

  const startForward = (): void => {
    openPopup(ForwardMessagePopup, {
      params: { groupId: message.groupId, messageIds: [message.id] },
    });
  };

  const isTextSelected = (): boolean => {
    const selection = window.getSelection();

    if (!targetClick || !selection) {
      return false;
    }

    return selection?.type === 'Range' && selection.containsNode(targetClick, true);
  };

  const canCopyImage = (): boolean => {
    if (!CLIPBOARD_SUPPORTED || platformInfo.isSafari) {
      return false;
    }

    const isImage = targetClick?.tagName.toLowerCase() === 'img';

    if (!isImage) {
      return (
        message.payload.payloadType === PayloadType.Image &&
        (message.payload.url?.includes(BUCKET_MEDIA_HOST) ||
          message.payload.localPreview?.includes('blob:'))
      );
    }

    const src = (targetClick as HTMLImageElement).src;

    return src.includes(BUCKET_MEDIA_HOST);
  };

  const isMediaMessage = (): boolean => {
    if (
      ![PayloadType.Album, PayloadType.Video, PayloadType.Image].includes(
        message.payload.payloadType
      )
    ) {
      return false;
    }
    return !!message.payload.comment;
  };

  const copySelectedText = async (): Promise<void> => {
    const selection = window.getSelection();
    if (!selection) {
      return;
    }

    await copyTextToClipboard(selection.toString());
  };

  const copyMessageText = async (): Promise<void> => {
    await copyTextToClipboard(getMessageTextForCopy(message, false));
  };

  const copyImage = async (): Promise<void> => {
    const src = targetClick
      ? (targetClick as HTMLImageElement).src
      : message.payload.payloadType === PayloadType.Image &&
        (message.payload.url || message.payload.localPreview);

    if (!src) {
      return;
    }

    const imageUrl = getOriginalSizeImage(src);

    const result = await copyImageToClipboard(imageUrl);
    if (result) {
      Entities.toast.show(t('common_copy_this_media_success'));
    }
  };

  const copyLink = async (): Promise<void> => {
    const href = (targetClick as HTMLLinkElement).href;
    await copyTextToClipboard(href);
  };

  const messageInfoPopup = (): void => {
    openPopup(ReactionsDetailedPopup, {
      params: { groupId: message.groupId, messageId: message.id },
    });
  };

  const pinUnpin = async (): Promise<void> => {
    const canPinUnpin = await Tasks.app.showConfirm({
      text: message.isPinned ? t('unpin_message_confirmation') : t('pin_message_confirmation'),
    });

    if (canPinUnpin) {
      Tasks.messaging.pinUnpinMessage(message);
    }
  };

  const copyDeepLink = (): void => {
    Tasks.messaging.copyDeepLink(message);
  };

  const leaveComment = async (): Promise<void> => {
    const group = await Tasks.thread.getOrCreateThread(message);
    if (group) {
      navigateTo({
        to: group.routePath,
        state: {
          [RouterState.hasGoBack]: true,
        },
      });
    }
  };

  const selectMessage = (): void => {
    Tasks.messaging.selectMessage({
      groupId: message.group?.id,
      messageId: message.id,
    });
  };

  const editMessage = (): void => {
    if (!message.group?.canSendMessages) {
      showToast(t('moderation_disable_send_message'));
      return;
    }

    if (message.payload.payloadType === PayloadType.Article) {
      navigateTo({
        to: `/${RouterPage.Channels}/${message.groupId}/article/${message.id}`,
      });

      return;
    }

    // TODO review input logic for avoiding remove ranges explicitly
    if (platformInfo.isSafari) {
      const selection = window.getSelection();
      selection?.removeAllRanges();
    }

    Tasks.messaging.startEditMessage({
      groupId: message.groupId,
      editMessageId: message.id,
    });
  };

  const deleteMessage = async (): Promise<void> => {
    let canDelete = false;
    let deleteMode = IDeleteMessagesMode.All;

    if (message.canBeDelete && message.canBeDeleteForMe) {
      const { isConfirmed, isChecked } = await showConfirmWithCheckbox({
        text: t('delete_message'),
        checkBoxText: t('delete_for_me'),
      });

      canDelete = isConfirmed;
      deleteMode = isChecked ? IDeleteMessagesMode.Me : IDeleteMessagesMode.All;
    }

    if (message.canBeDelete && !message.canBeDeleteForMe) {
      canDelete = await showConfirm({ text: t('delete_message') });
    }

    if (!message.canBeDelete && message.canBeDeleteForMe) {
      canDelete = await showConfirm({ text: t('common_delete_for_me_alert') });
      deleteMode = IDeleteMessagesMode.Me;
    }

    if (!canDelete || !message.group) {
      return;
    }

    message.delete(deleteMode);
  };

  const report = (): void => {
    openPopup(ReportMessagePopup, {
      params: { groupId: message.groupId, messageId: message.id },
    });
  };

  const quoteMessage = (): void => {
    Tasks.messaging.startQuoteMessage(message);
  };

  const addToSpaceCollection = (): void => {
    openPopup(AddToSpaceCollectionPopup, {
      params: { groupId: message.groupId, messageId: message.id },
    });
  };

  const downloadMessage = (): void => {
    Tasks.downloadMessageFile({
      payloadId: message.id,
      groupId: message.groupId,
      messageId: message.id,
    });
  };

  const sendNowMessage = (): void => {
    Tasks.messaging.sendNowScheduledMessages({
      groupId: message.groupId,
      messages: [message],
    });
  };

  const scheduleMessage = (): void => {
    const panel = Entities.InputPanel;

    panel.payloads.isEditMode = true;
    panel.payloads.setPayloads([message]);
    Tasks.group.goToScheduleSettings({ navigateTo, messageId: message.id });
  };

  const goToPinnedMessage = (): void => {
    if (message.group) {
      message.group.pinnedMessages.setPrevMessageIdOnFocusBased(message.id);

      Tasks.messaging.focusMessage({
        groupId: message.groupId,
        messageId: message.id,
        isAnimated: false,
      });
      navigateTo({ to: message.group.routePath });
    }
  };

  const isAllPinsPage = messageViewPageType === MessageViewPageType.AllPins;

  const menuItems = [
    {
      key: 'goToMessage',
      isVisible: message.isPinned && isAllPinsPage,
      title: t('common_go_to_message'),
      onClick: goToPinnedMessage,
      icon: GoToMessageIcon,
    },
    {
      key: 'sendnow',
      isVisible: message.canBeSendNow,
      title: t('common:common_send_now'),
      onClick: sendNowMessage,
      icon: SendIcon,
    },
    {
      key: 'schedule',
      isVisible: message.canBeSchedule,
      title: t('common:common_reschedule'),
      onClick: scheduleMessage,
      icon: CalendarIcon,
    },
    {
      key: 'resend',
      isVisible: message.canBeResend,
      title: t('message_resend'),
      onClick: message.resend,
      icon: ResendIcon,
    },
    {
      key: 'reschedule',
      isVisible: message.canBeReschedule,
      title: t('message_resend'),
      onClick: message.reschedule,
      icon: ResendIcon,
    },
    {
      key: 'copy',
      isVisible: message.canBeCopied,
      title: t(isMediaMessage() ? 'common_copy_text' : 'message_copy'),
      onClick: copyMessageText,
      icon: CopyIcon,
    },
    {
      key: 'copy_selected',
      isVisible: isTextSelected(),
      title: t('common_copy_selected_text'),
      onClick: copySelectedText,
      icon: CopyIcon,
    },
    {
      key: 'copy_link',
      isVisible: isLink,
      title: t('common_copy_link'),
      onClick: copyLink,
      icon: CopyLink,
    },
    {
      key: 'copy_media',
      isVisible: canCopyImage(),
      title: t('common_copy_this_media'),
      onClick: copyImage,
      icon: CopyImageIcon,
    },
    {
      key: 'quote',
      isVisible: message.canBeQuoted && !isAllPinsPage,
      title: t('message_reply'),
      onClick: quoteMessage,
      icon: ReplayIcon,
    },
    {
      key: 'add-to-space-collection',
      isVisible: message.canAddToSpaceCollection,
      title: t('space_add_to_collection'),
      onClick: addToSpaceCollection,
      icon: StarIcon,
    },
    {
      key: 'edit',
      isVisible: message.canBeEdit && !isAllPinsPage,
      title: t('message_edit'),
      onClick: editMessage,
      icon: EditIcon,
    },
    {
      key: 'pinned',
      isVisible: message.canBePinned,
      title: message.isPinned ? t('common_unpin') : t('common_pin'),
      onClick: pinUnpin,
      icon: message.isPinned ? UnPinIcon : PinIcon,
    },
    {
      key: 'forward',
      isVisible: message.canBeForward,
      title: t('message_forward'),
      onClick: startForward,
      icon: ForwardIcon,
    },
    {
      key: 'info',
      isVisible: message.canBeReactionsOrViews,
      title: t('group_chat_actions_sheet_info'),
      onClick: messageInfoPopup,
      icon: InfoIcon,
    },
    {
      key: 'translate',
      isVisible: message.canBeTranslated,
      title: t('common_translate'),
      onClick: message.translate,
      icon: TranslateIcon,
    },
    {
      key: 'deeplink',
      isVisible: message.canBeCreateLink,
      title: t('channel_deeplink_copy_link'),
      onClick: copyDeepLink,
      icon: LinkIcon,
    },
    {
      key: 'report',
      isVisible: message.canBeReported,
      title: t('common_report'),
      onClick: report,
      icon: ReportIcon,
    },
    {
      key: 'select',
      isVisible: message.canBeSelected,
      title: t('multiselect_context_menu_option'),
      onClick: selectMessage,
      icon: SelectIcon,
    },
    {
      key: 'comment',
      isVisible: message.canBeLeaveComment,
      title: t('comments_leave_comment'),
      onClick: leaveComment,
      icon: IconComment,
    },
    {
      key: 'download',
      isVisible: message.canBeDownloaded,
      title: t('common_download'),
      onClick: downloadMessage,
      icon: DownloadIcon,
    },
    {
      key: 'delete',
      isVisible: message.canBeDeleteForMe || message.canBeDelete,
      title: t('chat_list_delete_single_delete_button'),
      onClick: deleteMessage,
      icon: DeleteIcon,
      style: MenuItemStyle.Danger,
    },
  ].filter((item) => item.isVisible);

  return {
    menuItems,
  };
};
