import { useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { ALERT_TYPES } from 'APP/constants/app';
import { MAX_COUNT_BROADCASTERS } from 'APP/constants/stream';
import { useTranslation } from 'APP/packages/translations';
import Entities from 'STORE';

export default () => {
  const { t } = useTranslation();
  const presenter = useLocalStore(() => ({
    inviteUsername: null,

    get stream() {
      return Entities.ActiveStream.stream;
    },

    get viewersLiveTotalTitle() {
      return presenter.viewersLiveCount
        ? t('stream_number_of_viewers').replace('{0}', presenter.viewersLiveCount)
        : t('call_streaming_no_viewers');
    },

    get viewersLiveCount() {
      return presenter.stream?.viewers.length || 0;
    },

    get viewers() {
      if (!presenter.stream) {
        return [];
      }

      return presenter.stream.viewers.filter((user) => user.isViewer);
    },

    get streamers() {
      if (!presenter.stream) {
        return [];
      }

      return presenter.stream.viewers.filter((user) => user.isStreamer);
    },

    async inviteAsStreamer({ userId, username }) {
      const streamersCount = presenter.stream.viewers.filter((viewer) => viewer.isStreamer).length;

      if (streamersCount === MAX_COUNT_BROADCASTERS) {
        Tasks.app.addAlert({
          type: ALERT_TYPES.CALL_STREAMER_MAX_COUNT,
        });

        return;
      }

      presenter.inviteUsername = username;

      const canInvite = Tasks.app.showConfirm({
        text: t('call_invite_as_streamer_confirmation', { 0: presenter.inviteUsername }),
        primaryText: t('common_invite'),
      });

      if (canInvite) {
        await Tasks.streaming.inviteAsStreamer({
          channelId: presenter.stream.channelId,
          userId,
        });
        presenter.inviteUsername = null;
      }
    },

    async forceMuteStreamer({ userId }) {
      await Tasks.streaming.forceMute({
        channelId: presenter.stream.channelId,
        userId,
      });
    },
  }));

  return presenter;
};
