import { ClientRole } from 'APP/constants/calls';
import { ICallProviderOpponent, ProviderUid } from 'APP/packages/callProviders/callProviders.types';
import Entities from 'APP/store';
import { Opponent } from 'STORE/Calls/Call/Opponent/Opponent';

interface IAddOpponentToCallParams {
  uid: ProviderUid;
  providerOpponent?: ICallProviderOpponent;
  opponentRole: ClientRole;
}

export const addOpponentToCall = (data: IAddOpponentToCallParams): void => {
  const call = Entities.Calls.ongoingCall;

  if (!call) {
    return;
  }

  const uid = data.uid;
  const opponent = call.opponents.getOpponent(uid);

  if (!opponent || opponent.role === ClientRole.Audience) {
    call.opponents.addOpponent(
      uid,
      new Opponent({
        uid,
        call,
        providerOpponent: data.providerOpponent,
        sortIndex: call.sortIndex++,
        role: data.opponentRole,
      })
    );
  }
};
