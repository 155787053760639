import { observer } from 'mobx-react';
import React from 'react';

import TextRenderer from 'APP/components/TextRendererCollapsed';

import { PostReactions } from '../components/PostReactions/PostReactions';
import Translate from '../components/Translate';
import LinkPreview from './LinkPreview';
import styles from './PostText.styles.m.css';

export const PostText = observer(({ message, isView }) => {
  const {
    id,
    payload,
    renderPayload: { text, linkPreview },
  } = message;

  if (!text) {
    return null;
  }

  return (
    <div className={styles.container}>
      <TextRenderer
        className={styles.textWrapper}
        textClassName={styles.text}
        keyWords={payload.keyWords}
        userMentions={payload.userMentions}
        groupId={message.groupId}
        rows={4}
      >
        {text}
      </TextRenderer>

      {linkPreview && (
        <LinkPreview
          data={linkPreview}
          messageId={id}
        />
      )}
      <Translate message={message} />
      <PostReactions
        message={message}
        isVisible={isView}
      />
    </div>
  );
});
