import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { CallBlurType } from 'APP/constants/callBackground';
import BlurImage1 from 'APP/images/blur-image-1.png';
import BlurImage2 from 'APP/images/blur-image-2.png';
import { useTranslation } from 'APP/packages/translations';
import IcEmpty from 'ICONS/ic-block.svg';
import IcProfile from 'ICONS/ic-profile.svg';

import { BackgroundItem } from '../BackgroundItem/BackgroundItem';
import { BackgroundPanelHeader } from '../BackgroundPanelHeader/BackgroundPanelHeader';
import { BackgroundPanelVideo } from '../BackgroundPanelVideo/BackgroundPanelVideo';
import { useBackgroundPanelPresenter } from './BackgroundPanel.presenter';
import styles from './BackgroundPanel.styles.m.css';

interface IBackgroundPanelProps {
  isCompact?: boolean;
}
export const BackgroundPanel: FC<IBackgroundPanelProps> = observer((props) => {
  const presenter = useBackgroundPanelPresenter();
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <BackgroundPanelHeader
        onClose={presenter.onClose}
        isCompact={props.isCompact}
      />
      <div className={styles.content}>
        {!!presenter.publisher && <BackgroundPanelVideo publisher={presenter.publisher} />}
        <div className={styles.blurBlock}>
          <h3 className={styles.blurTitle}>{t('call_no_effects_and_blur')}</h3>
          <div className={styles.blurList}>
            <BackgroundItem
              icon={IcEmpty}
              isActive={presenter.activeBlurType === CallBlurType.None}
              onClick={presenter.onBlurClick}
              blurType={CallBlurType.None}
            />
            <BackgroundItem
              icon={IcProfile}
              isActive={presenter.activeBlurType === CallBlurType.Small}
              background={BlurImage1}
              onClick={presenter.onBlurClick}
              blurType={CallBlurType.Small}
            />
            <BackgroundItem
              icon={IcProfile}
              isActive={presenter.activeBlurType === CallBlurType.Large}
              background={BlurImage2}
              onClick={presenter.onBlurClick}
              blurType={CallBlurType.Large}
            />
          </div>
        </div>
      </div>
    </div>
  );
});
