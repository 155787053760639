import React, { FC } from 'react';

import { ApplicationTheme } from 'APP/constants/appearance';
import WelcomeDarkImage from 'APP/images/gem-mascot-welcome-dark.svg';
import WelcomeLightImage from 'APP/images/gem-mascot-welcome-light.svg';

import styles from './AuthImage.styles.m.css';

const AVAILABLE_IMAGES = {
  [ApplicationTheme.Dark]: WelcomeDarkImage,
  [ApplicationTheme.Light]: WelcomeLightImage,
};

interface IAuthImageProps {
  theme: ApplicationTheme | null;
}

export const AuthImage: FC<IAuthImageProps> = ({ theme }) => {
  const imageSrc = AVAILABLE_IMAGES[theme || ApplicationTheme.Light];

  return (
    <div className={styles.container}>
      <img
        className={styles.image}
        src={imageSrc}
        alt={''}
      />
    </div>
  );
};
