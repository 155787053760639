import { computed } from 'mobx';

import { ProviderUid } from 'APP/packages/callProviders/callProviders.types';
import { Call } from 'STORE/Calls/Call/Call';

const getSortValueOfCall = (opponent: Partial<IOpponentMeta>): number => {
  if (opponent.isSharedScreen) {
    return 6;
  }

  if (opponent.isWhoSharedScreen && opponent.hasVideo) {
    return 5;
  }

  if (opponent.isRaiseHand) {
    return 4;
  }

  if (opponent.hasVideo && opponent.hasAudio) {
    return 3;
  }

  if (opponent.hasAudio) {
    return 2;
  }

  if (opponent.hasVideo) {
    return 1;
  }

  return 0;
};

const callOpponentsSort = (opponent1: IOpponentMeta, opponent2: IOpponentMeta): number => {
  if (opponent1.sortIndex === opponent2.sortIndex) {
    return opponent2.timeStamp - opponent1.timeStamp;
  }
  return opponent2.sortIndex - opponent1.sortIndex;
};

interface IOpponentMeta {
  uid: ProviderUid;
  hasVideo: boolean;
  hasAudio: boolean;
  isSharedScreen: boolean;
  isWhoSharedScreen: boolean;
  isRaiseHand: boolean;
  timeStamp: number;
  sortIndex: number;
}

export class OpponentsPositions {
  prevOpponentsMeta: Record<string, IOpponentMeta> = {};
  call: Call;

  constructor(call: Call) {
    this.call = call;
  }

  @computed
  get opponentsMeta(): Record<ProviderUid, IOpponentMeta> {
    const opponents = this.call.opponents.opponentList.reduce<Record<ProviderUid, IOpponentMeta>>(
      (acc, item) => {
        const meta = {
          uid: item.uid,
          hasVideo: item.hasVideo,
          hasAudio: item.hasAudio,
          isSharedScreen: item.isSharedScreen,
          isWhoSharedScreen: !item.isSharedScreen && !!item.member?.hasScreenSharingOpponent,
          isRaiseHand: !!item.member?.isRaiseHand,
        };

        const sortIndex = getSortValueOfCall(meta);

        const timeStamp =
          !this.prevOpponentsMeta[item.uid] ||
          this.prevOpponentsMeta[item.uid].sortIndex !== sortIndex
            ? new Date().getTime()
            : this.prevOpponentsMeta[item.uid].timeStamp;

        acc[item.uid] = {
          ...meta,
          sortIndex,
          timeStamp,
        };

        return acc;
      },
      {}
    );

    this.prevOpponentsMeta = opponents;

    return opponents;
  }

  @computed
  get opponentsPositions(): Record<string, number> {
    return Object.values(this.opponentsMeta)
      .sort(callOpponentsSort)
      .reduce<Record<string, number>>((acc, item, index) => {
        acc[item.uid] = index;

        return acc;
      }, {});
  }
}
