/**
 * Rest API
 * Documentation Rest API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PersonalizedCallPermissionsSettingsResponse } from './personalizedCallPermissionsSettingsResponse';
import { AgoraCallMember } from './agoraCallMember';


export interface CallConnectedResponse { 
    /**
     * the unique id of call
     */
    channelId: string;
    groupId: string;
    /**
     * the key for connection to Agora
     */
    token: string;
    /**
     * the current time on the server
     */
    currentTs: string;
    members: Array<AgoraCallMember>;
    /**
     * unique id for the user in Agora call
     */
    agoraUid: number;
    /**
     * unique id for share screen in Agora call
     */
    shareScreenUid: number;
    /**
     * the key to connection for screen sharing
     */
    shareScreenToken: string;
    initiatorId: string;
    isAdmin: boolean;
    callType: CallConnectedResponseCallTypeEnum;
    maxBroadcasters: number;
    recordingId?: string;
    permissions: PersonalizedCallPermissionsSettingsResponse;
}
export enum CallConnectedResponseCallTypeEnum {
    P2P = 'P2P',
    Group = 'GROUP',
    Conference = 'CONFERENCE',
    Stream = 'STREAM'
};



