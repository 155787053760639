import { loadSpace } from 'APP/Tasks/space/loadSpace/loadSpace';
import { spaceModel } from 'APP/model/model';
import Entities from 'STORE';

export async function loadSpaceCollections(spaceId: string): Promise<void> {
  const space = Entities.spacesStore.getById(spaceId) || (await loadSpace(spaceId));

  if (!space) {
    return;
  }

  space.collections.isLoading = true;

  try {
    const collections = await spaceModel.getCollections(spaceId);
    space.collections.setCollections(collections);
    space.collections.setIsNeedToUpdate(false);
  } catch {
    space.collections.setCollections([]);
  }

  space.collections.isLoading = false;
}
