import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import Draggable from 'react-draggable';

import { IconButton } from 'APP/packages/UiKit/IconButton';
import ExpandIcon from 'ICONS/ic-maximize.svg';

import NoVideoMinimize from '../../SharedComponents/NoVideoMinimize';
import Video from '../../SharedComponents/Video';
import styles from './styles.m.css';

const MinimizeView = ({ presenter, isFinished }) => {
  const [isDragging, setDragging] = useState();
  useEffect(() => {
    if (isFinished) {
      presenter.onClose();
    }
  }, [isFinished]);
  return (
    <Draggable
      cancel="button"
      bounds={'body'}
      onStart={() => setDragging(false)}
      onDrag={() => setDragging(true)}
      onStop={() => setDragging(false)}
    >
      <div
        className={classNames(styles.container, {
          [styles.dragging]: isDragging,
          [styles.showControls]: !presenter.primaryVideo,
        })}
      >
        {presenter.primaryVideo ? (
          <Video videoTrack={presenter.primaryVideo} />
        ) : (
          <NoVideoMinimize />
        )}
        <IconButton
          Icon={ExpandIcon}
          onClick={presenter.expand}
          className={styles.expand}
          size="large"
          iconSize="medium"
          theme="12"
        />
        {presenter.isStreamStarted && <div className={styles.live}>LIVE</div>}
      </div>
    </Draggable>
  );
};

export default observer(MinimizeView);
