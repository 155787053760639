import { useTranslation } from 'APP/packages/translations';

import phoneNumber from '../fields/phoneNumber';
import verificationCode from '../fields/verificationCode';
import asyncValidation from '../hooks/asyncValidation';

export default () => {
  const { t } = useTranslation();

  const phoneNumberErrorToText = {
    [phoneNumber.ERRORS.E_PHONE_INVALID]: t('invalid_phone_number'),
  };

  const verificationCodeErrorToText = {
    [verificationCode.ERRORS.E_INVALID_VERIFICATION_CODE]: t('invalid_sms_code_alert'),
    [verificationCode.ERRORS.E_ATTEMPTS_EXCEEDED]: t('sms_limit_exceeded'),
    [verificationCode.ERRORS.E_VERIFICATION_CODE_EXPIRED]: t('sms_limit_exceeded'),
  };

  const phoneNumberValidator = asyncValidation();
  const verificationCodeValidator = asyncValidation();

  return {
    phoneNumber: {
      validate: (value, formData, meta, data) => {
        return phoneNumberValidator({
          value,
          meta,
          validate: phoneNumber.validate,
          asyncValidate: phoneNumber.asyncValidate,
          errorToText: phoneNumberErrorToText,
          data,
        });
      },
    },
    verificationCode: {
      validate: (value, formData, meta, data) => {
        return verificationCodeValidator({
          value,
          meta,
          validate: verificationCode.validate,
          asyncValidate: verificationCode.asyncValidate,
          errorToText: verificationCodeErrorToText,
          data,
        });
      },
    },
  };
};
