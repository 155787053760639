import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { useTranslation } from 'APP/packages/translations';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { useClosePopupOnNavigation } from '../../hooks/useClosePopupOnNavigation';
import { MoveGroupToSpaceList } from './MoveGroupToSpaceList/MoveGroupToSpaceList';
import {
  MoveGroupToSpacePopupParams,
  useMoveGroupToSpacePresenter,
} from './MoveGroupToSpacePopup.presenter';

export const MoveGroupToSpacePopup: FC<ICommonPopupsProps<MoveGroupToSpacePopupParams>> = observer(
  (props) => {
    const { t } = useTranslation();
    const presenter = useMoveGroupToSpacePresenter(props);
    useClosePopupOnNavigation(props.popupInstance);

    return (
      <Popup
        title={t('space_moveto_space_screen')}
        isOpened={!!presenter.group}
        onClose={presenter.onClose}
      >
        <MoveGroupToSpaceList
          spaces={presenter.spaces}
          onSelectSpace={presenter.onSelectSpace}
        ></MoveGroupToSpaceList>
      </Popup>
    );
  }
);
