import React from 'react';

import { IRenderer } from '../renderTree.types';
import { useBotCommandRendererPresenter } from './BotCommandRenderer.presenter';
import styles from './BotCommandRenderer.styles.m.css';

export const BotCommandRenderer: IRenderer = ({ children }) => {
  const presenter = useBotCommandRendererPresenter(children as string);

  if (!children) {
    return null;
  }

  return (
    <button
      className={styles.link}
      onClick={presenter.onClick}
    >
      {children}
    </button>
  );
};
