import React, { FC } from 'react';

import { TextFormattingInput } from 'APP/components/TextFormattingInput/TextFormattingInput';
import { useTranslation } from 'APP/packages/translations';
import { IShareSelection } from 'APP/types/share';
import { Button } from 'UIKIT/Button/Button';
import { ButtonTypes } from 'UIKIT/Button/Button.types';

import { useForwardMessageFooterViewPresenter } from './ForwardMessageFooterView.presenter';
import styles from './ForwardMessageFooterView.styles.m.css';

interface IForwardMessageCommentFormProps {
  selection: IShareSelection[];
  isDisabled: boolean;
  isLoading: boolean;
  onSubmit(comment: string): void;
}

export const ForwardMessageFooterView: FC<IForwardMessageCommentFormProps> = (props) => {
  const { onSubmit, isDisabled, isLoading, selection } = props;
  const { t } = useTranslation();

  const presenter = useForwardMessageFooterViewPresenter({ onSubmit, selection });

  return (
    <div className={styles.container}>
      {selection.length > 0 && (
        <TextFormattingInput
          className={styles.inputContainer}
          inputClassName={styles.input}
          placeholder={t('common_comment')}
          value={presenter.commentValue}
          onChange={presenter.onChange}
          onSubmit={presenter.handleSubmit}
        />
      )}

      <div className={styles.submitBtn}>
        <Button
          type={ButtonTypes.Button}
          isDisabled={isDisabled}
          isLoading={isLoading}
          theme="1"
          onClick={presenter.handleSubmit}
        >
          {t('send')}
        </Button>
      </div>
    </div>
  );
};
