import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { FC } from 'react';

import { useForwardMessage } from 'MAIN/ChatView/hooks/useForwardMessage/useForwardMessage';
import { useUserProfile } from 'MAIN/hooks/userProfile/useUserProfile';
import type { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';
import { Loader } from 'UIKIT/Loader/Loader';

import { useSenderNamePresenter } from '../SenderName.presenter';
import styles from '../SenderName.styles.m.css';
import { SenderTitle } from '../SenderTitle/SenderTitle';

interface ISenderNameForUserProps {
  className?: string;
  message: ChatMessage;
}

export const SenderNameForUser: FC<ISenderNameForUserProps> = observer((props) => {
  const { message, className } = props;

  const commonPresenter = useSenderNamePresenter(message);
  const forwardPresenter = useForwardMessage(message);
  const userProfile = useUserProfile(commonPresenter.senderUser.id, {
    groupId: message.groupId,
    messageId: message.id,
  });

  if (!commonPresenter.isShow) {
    return null;
  }

  if (commonPresenter.isForwardToSavedMessages) {
    const classes = classNames(
      styles.senderName,
      styles['color' + forwardPresenter.avatarColorIndex]
    );

    return (
      <div className={className}>
        <button
          className={classes}
          onClick={forwardPresenter.onClick}
        >
          {forwardPresenter.avatarTitle}
          {forwardPresenter.isLoading && (
            <Loader
              className={styles.loader}
              color="current"
            />
          )}
        </button>
      </div>
    );
  }

  return (
    <div className={className}>
      <SenderTitle
        user={commonPresenter.senderUser}
        onClick={userProfile.show}
      />
    </div>
  );
});
