import { computed } from 'mobx';

import { PayloadType } from 'APP/model/message/messageModel.types';
import { ISharedDataItem } from 'APP/model/sharedData/sharedDataModel.types';
import ArticlePayload from 'STORE/Messages/Message/Payload/Article';

import { SharedDataItem } from '../SharedDataItem/SharedDataItem';

type TSharedArticleItem = ISharedDataItem<ArticlePayload>;

export class SharedArticles extends SharedDataItem {
  @computed
  get items(): TSharedArticleItem[] {
    const messages = this.messages.getFilteredMessages(
      (message) => message.payload.payloadType === PayloadType.Article
    );

    return messages.map((message) => ({
      id: message.clientUuid,
      messageId: message.id,
      groupId: message.groupId,
      payload: message.payload,
      serverTime: message.serverTime,
    }));
  }

  @computed
  get count(): number {
    return this._count || this.items.length;
  }
}
