import { action, computed, observable } from 'mobx';

export class MiniPlayer {
  @observable groupId: string | null = null;
  @observable messageId: string | null = null;
  @observable payloadId: string | null = null;
  @observable isPlaying = false;
  @observable progress = 0;
  @observable progressPercentage = 0;
  @observable newProgress: number | null = null;
  @observable newProgressPercentage: number | null = null;

  @computed
  public get isOpened(): boolean {
    return !!(this.groupId && this.messageId && this.payloadId);
  }

  @action
  public play(groupId: string, messageId: string, payloadId: string): void {
    this.reset();

    this.groupId = groupId;
    this.messageId = messageId;
    this.payloadId = payloadId;
    this.isPlaying = true;
  }

  @action
  public setMessageId(messageId: string | null): void {
    this.messageId = messageId;
  }

  @action
  public setPayloadId(payloadId: string | null): void {
    this.payloadId = payloadId;
  }

  @action
  public setProgress(progress: number, progressPercentage: number): void {
    this.progress = progress;
    this.progressPercentage = progressPercentage;
  }

  @action
  public setIsPlaying(isPlaying: boolean): void {
    this.isPlaying = isPlaying;
  }

  @action
  public updateProgress(progress: number): void {
    this.newProgress = progress;
  }

  @action
  public updateProgressPercentage(progressPercentage: number): void {
    this.newProgressPercentage = progressPercentage;
  }

  @action
  public reset(): void {
    this.groupId = null;
    this.messageId = null;
    this.payloadId = null;
    this.isPlaying = false;
    this.newProgress = null;
    this.newProgressPercentage = null;
    this.setProgress(0, 0);
  }
}
