import { useAsObservableSource, useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { Group } from 'STORE/Groups/Group';
import { debounce } from 'UTILS/debounce';

interface IGroupVisibilityItemPresenter {
  isVisible: boolean;
  onToggle(): void;
  changeVisibility(groupId: string, isVisible: boolean): void;
}

export const useGroupVisibilityItemPresenter = (group: Group): IGroupVisibilityItemPresenter => {
  const source = useAsObservableSource({ group });

  const presenter = useLocalStore<IGroupVisibilityItemPresenter>(() => ({
    get isVisible(): boolean {
      const space = source.group.space;
      if (!space) {
        return false;
      }

      return space.groupVisibility.isGroupVisible(source.group.id);
    },

    onToggle(): void {
      const space = source.group.space;
      if (!space) {
        return;
      }

      const newValue = !presenter.isVisible;
      space.groupVisibility.setGroupVisible(group.id, newValue);
      presenter.changeVisibility(group.id, newValue);
    },

    changeVisibility: debounce((groupId: string, isVisible: boolean) => {
      Tasks.space.changeGroupVisibility(groupId, isVisible);
    }, 1000),
  }));

  return presenter;
};
