import classNames from 'classnames';
import React, { FC } from 'react';

import { IconButton } from 'APP/packages/UiKit/IconButton';
import CloseIcon from 'ICONS/close.svg';
import BackArrow from 'ICONS/ic-back.svg';

import styles from './PopupHeader.style.m.css';

interface IPopupHeaderProps {
  readonly className?: string;
  readonly title?: string;
  readonly handlePrevStep?: (() => void) | null;
  onClose?(): void;
}

export const PopupHeader: FC<IPopupHeaderProps> = ({
  title,
  handlePrevStep,
  onClose,
  className,
}) => {
  return (
    <div
      className={classNames(styles.container, className, {
        [styles.hasActions]: !!(handlePrevStep || onClose),
      })}
    >
      {handlePrevStep && (
        <div className={styles.arrowBlock}>
          <IconButton
            Icon={BackArrow}
            onClick={handlePrevStep}
          />
        </div>
      )}
      {title && (
        <div
          className={styles.title}
          title={title}
        >
          {title}
        </div>
      )}
      {onClose && (
        <div className={styles.close}>
          <IconButton
            Icon={CloseIcon}
            dataTest="close-popup"
            onClick={onClose}
          />
        </div>
      )}
    </div>
  );
};
