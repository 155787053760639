import { useLocalStore } from 'mobx-react';

import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';

interface IUserPrivacyPopupPresenter {
  hasBackButton: boolean;
  onClose(): void;
  onBack(): void;
}

export function useUserPrivacyPopupPresenter({
  popupInstance,
  onClose,
  onBack,
}: ICommonPopupsProps): IUserPrivacyPopupPresenter {
  return useLocalStore<IUserPrivacyPopupPresenter>(() => ({
    get hasBackButton(): boolean {
      return !!onBack;
    },

    onClose(): void {
      popupInstance.close();
      onClose?.();
    },

    onBack(): void {
      popupInstance.close();
      onBack?.();
    },
  }));
}
