import { useAsObservableSource, useLocalStore } from 'mobx-react';

import { RouterLink } from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import Entities from 'STORE';
import { Space } from 'STORE/Spaces/Space';

export interface ISpaceContentPresenter {
  readonly space: Space | null;
  onClose(): void;
}

export function useSpaceContentPresenter(spaceId?: string): ISpaceContentPresenter {
  const navigateTo = useNavigateTo();
  const source = useAsObservableSource({ navigateTo, spaceId });

  return useLocalStore<ISpaceContentPresenter>(() => ({
    get space(): Space | null {
      return source.spaceId ? Entities.spacesStore.getById(source.spaceId) : null;
    },

    onClose(): void {
      source.navigateTo({ to: RouterLink.main });
    },
  }));
}
