import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import { openPopup } from 'APP/Tasks/app/popup/popup';
import { RouterPage } from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import { ForwardMessagePopup } from 'MAIN/PopupManager/Popups/ForwardMessagePopup/ForwardMessagePopup';

export default ({ message }) => {
  const navigateTo = useNavigateTo();

  const source = useAsObservableSource({
    message,
    navigateTo,
  });

  const presenter = useLocalStore(() => ({
    childPopup: null,

    forward: () => {
      presenter.childPopup = openPopup(ForwardMessagePopup, {
        params: { groupId: presenter.message.groupId, messageIds: [presenter.message.id] },
      });
    },

    edit: () => {
      navigateTo({
        to: `/${RouterPage.Channels}/${presenter.message.groupId}/article/${presenter.message.id}`,
      });
    },

    get message() {
      return source.message;
    },

    get isShowDeleteAction() {
      return presenter.message && presenter.message.group.withMeInAdmins;
    },

    get isShowEditAction() {
      return presenter.message?.canBeEdit || false;
    },

    get isShowForwardAction() {
      return presenter.message && !presenter.message.isScheduled;
    },
  }));

  useEffect(() => {
    return () => presenter.childPopup?.close();
  }, []);

  return presenter;
};
