import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import { RouterLink } from 'APP/router/constants';
import { CallFinished as CallFinishedPage } from 'APP/unauthorized/CallFinished/CallFinished';
import { CallInvitation as CallInvitationPage } from 'APP/unauthorized/CallInvitation/CallInvitation';
import { CallPreview as CallPreviewPage } from 'APP/unauthorized/CallPreview/CallPreview';
import { PopupManager } from 'MAIN/PopupManager/PopupManager';
import { GetParametersManager } from 'MAIN/Popups/GetParametersManager/GetParametersManager';

import styles from './UnauthorizedView.styles.m.css';

export const UnauthorizedView: FC = observer(() => {
  return (
    <>
      <div className={styles.container}>
        <Switch>
          <Route path={RouterLink.callInvitationGroup}>
            <CallInvitationPage />
          </Route>
          <Route path={RouterLink.callPreviewGroup}>
            <CallPreviewPage />
          </Route>
          <Route path={RouterLink.callFinishedGroup}>
            <CallFinishedPage />
          </Route>
          <Route path={RouterLink.callInvitation}>
            <CallInvitationPage />
          </Route>
          <Route path={RouterLink.callPreview}>
            <CallPreviewPage />
          </Route>
          <Route path={RouterLink.callFinished}>
            <CallFinishedPage />
          </Route>
        </Switch>
      </div>
      <PopupManager />
      <GetParametersManager />
    </>
  );
});
