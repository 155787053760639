import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { Popup } from 'STORE/App/Popups/Popup/Popup';

export function useClosePopupOnNavigation(popupInstance: Popup): void {
  const location = useLocation();
  const initLocationRef = useRef(location);

  useEffect(() => {
    // isHidden flag means, that current popup has childs popup
    if (initLocationRef.current !== location && !popupInstance.isHidden) {
      popupInstance.close();
    }
  }, [location, popupInstance]);
}
