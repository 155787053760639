import { request } from '../request/request';
import { BanUsersForGroupRequest } from '../types/model/banUsersForGroupRequest';
import { BooleanResponse } from '../types/model/booleanResponse';
import { ChangeOwnershipRequest } from '../types/model/changeOwnershipRequest';
import { CounterRequest } from '../types/model/counterRequest';
import { GetGroupKeywordFiltersRequest } from '../types/model/getGroupKeywordFiltersRequest';
import { GetGroupUserRolesRequest } from '../types/model/getGroupUserRolesRequest';
import { GetGroupsCountersRequest } from '../types/model/getGroupsCountersRequest';
import { GetGroupsEventsRequest } from '../types/model/getGroupsEventsRequest';
import { GetGroupsEventsResponse } from '../types/model/getGroupsEventsResponse';
import { GetKeywordFiltersResponse } from '../types/model/getKeywordFiltersResponse';
import { GroupCounterView } from '../types/model/groupCounterView';
import { GroupIdRequest } from '../types/model/groupIdRequest';
import { GroupParticipantChangeRequest } from '../types/model/groupParticipantChangeRequest';
import { GroupSettingsResponse } from '../types/model/groupSettingsResponse';
import { GroupUserRolesResponse } from '../types/model/groupUserRolesResponse';
import { GroupsPageResponse } from '../types/model/groupsPageResponse';
import { MemberCustomPermissionsRequest } from '../types/model/memberCustomPermissionsRequest';
import { MemberCustomPermissionsResponse } from '../types/model/memberCustomPermissionsResponse';
import { PinMessagesRequest } from '../types/model/pinMessagesRequest';
import { SetGroupKeywordFiltersRequest } from '../types/model/setGroupKeywordFiltersRequest';
import { SetGroupObsceneFilterRequest } from '../types/model/setGroupObsceneFilterRequest';
import { SetGroupSettingsRequest } from '../types/model/setGroupSettingsRequest';
import { ShowContentRequest } from '../types/model/showContentRequest';

const ROOT_API = 'group';

export function getFriendsNotInvitedToGroup(data: any): Promise<any> {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/getFriendsNotInvitedToGroup',
    method: 'POST',
    body: data,
  });
}

export function pinGroup(data: any): Promise<any> {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/pinGroup',
    method: 'POST',
    body: data,
  });
}

export function unpinGroup(data: any): Promise<any> {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/unpinGroup',
    method: 'POST',
    body: data,
  });
}

export function pinMessages(data: PinMessagesRequest): Promise<BooleanResponse> {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/pinMessages',
    method: 'POST',
    body: data,
  });
}

export function unpinMessages(data: PinMessagesRequest): Promise<BooleanResponse> {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/unpinMessages',
    method: 'POST',
    body: data,
  });
}

export function unpinAllMessages(data: GroupIdRequest): Promise<BooleanResponse> {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/unpinAllMessages',
    method: 'POST',
    body: data,
  });
}

export function getGroupsByIds(data: any): Promise<any> {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/getGroupsByIds',
    method: 'POST',
    body: data,
  });
}

export function banUsersForGroup(data: BanUsersForGroupRequest): Promise<BooleanResponse> {
  return request<BooleanResponse>({
    rootApi: ROOT_API,
    version: 'v3',
    url: '/banUsersForGroup',
    method: 'POST',
    body: data,
  });
}

export function unbanUsersForGroup(data: any): Promise<any> {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/unbanUsersForGroup',
    method: 'POST',
    body: data,
  });
}

export function transferOwnership(data: ChangeOwnershipRequest): Promise<BooleanResponse> {
  return request<BooleanResponse>({
    rootApi: ROOT_API,
    version: 'v3',
    url: '/changeOwnership',
    method: 'POST',
    body: data,
  });
}

export const addGroupParticipants = (
  data: GroupParticipantChangeRequest
): Promise<BooleanResponse> => {
  return request({
    rootApi: ROOT_API,
    version: 'v3',
    url: '/addGroupParticipants',
    method: 'POST',
    body: data,
  });
};

export const removeGroupParticipants = (
  data: GroupParticipantChangeRequest
): Promise<BooleanResponse> => {
  return request({
    rootApi: ROOT_API,
    version: 'v3',
    url: '/removeGroupParticipants',
    method: 'POST',
    body: data,
  });
};

export const addGroupAdmins = (data: GroupParticipantChangeRequest): Promise<BooleanResponse> => {
  return request({
    rootApi: ROOT_API,
    version: 'v3',
    url: '/addGroupAdmins',
    method: 'POST',
    body: data,
  });
};

export const removeGroupAdmins = (
  data: GroupParticipantChangeRequest
): Promise<BooleanResponse> => {
  return request({
    rootApi: ROOT_API,
    version: 'v3',
    url: '/revokeGroupAdmins',
    method: 'POST',
    body: data,
  });
};

export function getBannedUsersForGroup(data: any): Promise<any> {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/getBannedUsersForGroup',
    method: 'POST',
    body: data,
  });
}

export function getGroupsPage(data: CounterRequest): Promise<GroupsPageResponse> {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/getGroupsPage',
    method: 'POST',
    body: data,
  });
}

export function createGroupAndInvite(data: any): Promise<any> {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/createGroupAndInvite',
    method: 'POST',
    body: data,
  });
}

export function getGroupUserRoles(data: GetGroupUserRolesRequest): Promise<GroupUserRolesResponse> {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/getGroupUserRoles',
    method: 'POST',
    body: data,
  });
}

export function updateGroupInfo(data: any): Promise<any> {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/updateGroupInfo',
    method: 'POST',
    body: data,
  });
}

export function deleteGroupDeepLink(data: any): Promise<any> {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/deleteGroupDeepLink',
    method: 'POST',
    body: data,
  });
}

export function deepLinkExists(data: any): Promise<any> {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/deepLinkExists',
    method: 'POST',
    body: data,
  });
}

export function removeGroupAvatar(data: any): Promise<any> {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/removeGroupAvatar',
    method: 'POST',
    body: data,
  });
}

export function updateDraftInput(data: any): Promise<any> {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/updateDraftInput',
    method: 'POST',
    body: data,
  });
}

// TODO: apply interfaces from OPEN Api
export function markGroupsAsUnread(data: {
  groupIds: string[];
  unread: boolean;
}): Promise<{ markUnreadResults: { groupId: string; result: boolean } }> {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/markGroupsAsUnread',
    method: 'POST',
    body: data,
  });
}

export function setMemberPermissions(
  data: MemberCustomPermissionsRequest
): Promise<MemberCustomPermissionsResponse> {
  return request<MemberCustomPermissionsResponse>({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/setMemberPermissions',
    method: 'POST',
    body: data,
  });
}

export function getOrCreateThread(data: any): Promise<any> {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/getOrCreateThread',
    method: 'POST',
    body: data,
  });
}

export function getThreadsInfo(data: any): Promise<any> {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/getThreadsInfo',
    method: 'POST',
    body: data,
  });
}

export function getThreadInfoById(data: any): Promise<any> {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/getThreadInfoById',
    method: 'POST',
    body: data,
  });
}

export function setGroupAdvertisementSettings(data: any): Promise<any> {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/setGroupAdvertisementSettings',
    method: 'POST',
    body: data,
  });
}

export function getGroupAdvertisementSettings(data: any): Promise<any> {
  return request({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/getGroupAdvertisementSettings',
    method: 'POST',
    body: data,
  });
}

export function getGroupsEvents(data: GetGroupsEventsRequest): Promise<GetGroupsEventsResponse> {
  return request<GetGroupsEventsResponse>({
    rootApi: 'messaging',
    version: 'v2',
    url: '/getGroupsEvents',
    method: 'POST',
    body: data,
  });
}

export function getGroupsCounters(data: GetGroupsCountersRequest): Promise<GroupCounterView[]> {
  return request<GroupCounterView[]>({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/getGroupsCounters',
    method: 'POST',
    body: data,
  });
}

export function setGroupSettings(data: SetGroupSettingsRequest): Promise<GroupSettingsResponse> {
  return request<GroupSettingsResponse>({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/setGroupSettings',
    method: 'POST',
    body: data,
  });
}

export function setObsceneFilter(data: SetGroupObsceneFilterRequest): Promise<BooleanResponse> {
  return request<BooleanResponse>({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/setObsceneFilter',
    method: 'POST',
    body: data,
  });
}

export function showContent(data: ShowContentRequest): Promise<BooleanResponse> {
  return request<BooleanResponse>({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/showContent',
    method: 'POST',
    body: data,
  });
}

export function getKeywordFilters(
  data: GetGroupKeywordFiltersRequest
): Promise<GetKeywordFiltersResponse> {
  return request<GetKeywordFiltersResponse>({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/getKeywordFilters',
    method: 'POST',
    body: data,
  });
}

export function setKeywordFilters(data: SetGroupKeywordFiltersRequest): Promise<BooleanResponse> {
  return request<BooleanResponse>({
    rootApi: ROOT_API,
    version: 'v2',
    url: '/setKeywordFilters',
    method: 'POST',
    body: data,
  });
}
