import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { useTranslation } from 'APP/packages/translations';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { NewRecording } from './NewRecording/NewRecording';
import { useStartRecordingPopupPresenter } from './StartRecordingPopup.presenter';
import styles from './StartRecordingPopup.styles.m.css';

interface IStartRecordingPopupProps {
  groupId: string;
}

export const StartRecordingPopup: FC<ICommonPopupsProps<IStartRecordingPopupProps>> = observer(
  ({ popupInstance, params }) => {
    const { groupId } = params!;
    const { t } = useTranslation();
    const presenter = useStartRecordingPopupPresenter(groupId, popupInstance);

    return (
      <Popup
        title={t('calls_recording_screen_title')}
        className={styles.container}
        isOpened
        onClose={popupInstance.close}
      >
        <NewRecording onSuccess={presenter.onSuccess} />
      </Popup>
    );
  }
);
