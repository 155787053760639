import { observer } from 'mobx-react';
import React from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { useTranslation } from 'APP/packages/translations';
import { ShareDataView } from 'MAIN/PopupManager/components/ShareDataView/ShareDataView';

import usePresenter from './presenter';

const ShareMessageToPopup = () => {
  const { t } = useTranslation();

  const presenter = usePresenter();

  return (
    <Popup
      isOpened={presenter.isAvailableToShow}
      onClose={presenter.onClose}
      title={t('share_to')}
    >
      <ShareDataView onChangeSelection={presenter.onSelect} />
    </Popup>
  );
};

export default observer(ShareMessageToPopup);
