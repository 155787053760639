import { action, computed, observable, values } from 'mobx';

import { CallType, CallProviderType } from 'APP/model/call/callModel.types';

import Entities from '../index';
import { IncomingCall } from './IncomingCall/IncomingCall';

interface IIncomingCallsAddParams {
  groupId: string;
  channelId: string;
  callerId: string;
  callVisibleDuration: number;
  callType: CallType;
  callProvider: CallProviderType;
}

export class IncomingCalls {
  @observable data = observable.map<string, IncomingCall>({}, { deep: false });
  root: typeof Entities;

  constructor(root: typeof Entities) {
    this.root = root;
  }

  @action reset(): void {
    this.data.forEach((call: IncomingCall) => {
      call.dispose();
    });
    this.data.clear();
  }

  @action
  add({
    groupId,
    channelId,
    callerId,
    callVisibleDuration,
    callType,
    callProvider,
  }: IIncomingCallsAddParams): void {
    const incomingCall = new IncomingCall({
      groupId,
      channelId,
      initiatorId: callerId,
      callVisibleDuration,
      callType,
      callProvider,
    });

    this.data.set(groupId, incomingCall);
  }

  @action
  delete(groupId: string, channelId: string | null): void {
    const call = this.getCallByGroupId(groupId);

    if (call && call.channelId === channelId) {
      call.dispose();
      this.data.delete(groupId);
    }
  }

  @computed
  get calls(): readonly IncomingCall[] {
    return values(this.data);
  }

  @computed
  get hasCalls(): boolean {
    return !!this.calls.length;
  }

  getCallByGroupId(groupId: string): IncomingCall | null {
    return this.data.get(groupId) || null;
  }
}
