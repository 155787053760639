import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';
import { User } from 'STORE/Users/User/User';
import { Avatar } from 'UIKIT/Avatar/Avatar';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';

import { TCloseHandler } from '../../MediaGallery.types';
import { useMediaGalleryInfoCardPresenter } from './MediaGalleryInfoCard.presenter';
import styles from './MediaGalleryInfoCard.styles.m.css';

interface IMediaGalleryInfoCardProps {
  message: ChatMessage;
  sender: User;
  canNavigate: boolean;
  onClose: TCloseHandler;
}

export const MediaGalleryInfoCard: FC<IMediaGalleryInfoCardProps> = observer((props) => {
  const { message, sender, canNavigate, onClose } = props;
  const presenter = useMediaGalleryInfoCardPresenter({ message, sender, canNavigate, onClose });

  const containerClass = classNames(styles.container, {
    [styles.noNavigation]: !canNavigate,
  });
  const titleClass = classNames(styles.title, {
    [styles.noNavigation]: !canNavigate,
  });
  const subtitleClass = classNames(styles.subtitle, {
    [styles.noNavigation]: !canNavigate,
  });

  return (
    <button
      className={containerClass}
      tabIndex={canNavigate ? 0 : -1}
      onClick={presenter.onClick}
    >
      <Avatar
        className={styles.avatar}
        title={presenter.avatarTitle}
        url={presenter.avatarUrl}
        backgroundColorIndex={presenter.avatarBackgroundColorIndex}
        type={AvatarTypes.Round_10}
      />

      <div className={styles.info}>
        <h3 className={titleClass}>{presenter.avatarTitle}</h3>

        {presenter.subTitle && (
          <>
            {canNavigate ? (
              <div
                className={subtitleClass}
                role="button"
                tabIndex={-1}
                onClick={presenter.onSubTitleClick}
              >
                {presenter.subTitle}
              </div>
            ) : (
              <span className={subtitleClass}>{presenter.subTitle}</span>
            )}
          </>
        )}
      </div>
    </button>
  );
});
