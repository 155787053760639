import { useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import bus from 'APP/packages/bus';

export default ({ focusHandler, list, common }) => {
  const presenter = useLocalStore(() => ({
    onSelectMention() {
      const selectedMention = list.mentions[focusHandler.focusedIndex];

      bus.messaging.broadcastSelectMention(selectedMention);
      Tasks.search.resetMentions({ inputPanel: common.inputPanel });
    },
  }));

  return presenter;
};
