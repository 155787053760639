import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Tasks from 'APP/Tasks';
import { TargetForwardType } from 'APP/constants/forward';
import { GroupType } from 'APP/model/group/groupModel.types';
import { PayloadType } from 'APP/model/message/messageModel.types';
import { IShareSelection } from 'APP/types/share';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import Entities from 'STORE';
import { Group } from 'STORE/Groups/Group';
import { User } from 'STORE/Users/User/User';

export interface IShareContactPopupParams {
  userId: string;
}

interface IShareContactPopupPresenter {
  user: User | null;
  hasBackButton: boolean;
  onSelect(selection: IShareSelection[]): Promise<void>;
  send(data: IShareSelection): Promise<Group | null>;
  getCreateSavedMessage(): Promise<Group | null>;
  shareContact(groupId: string): void;
  onClose(): void;
  onBack(): void;
}

export function useShareContactPopupPresenter({
  params,
  popupInstance,
  onClose,
  onBack,
}: ICommonPopupsProps<IShareContactPopupParams>): IShareContactPopupPresenter {
  const { userId } = params!;
  const history = useHistory();

  const presenter = useLocalStore(() => ({
    get user(): User | null {
      return Entities.UsersStore.getUserById(userId);
    },

    get hasBackButton(): boolean {
      return !!onBack;
    },

    async onSelect(selection: IShareSelection[]): Promise<void> {
      const group = await presenter.send(selection[0]);
      history.push(`/${group.route}/${group.id}`);
      presenter.onClose();
    },

    // TODO: add typings
    async send(data: IShareSelection): Promise<any> {
      const type = data.type;
      let target: any = data.data;

      switch (type) {
        case TargetForwardType.Chat: {
          if (target.type === GroupType.SavedMessagesFake) {
            target = await presenter.getCreateSavedMessage();
          }
          presenter.shareContact(target.id);
          break;
        }
        case TargetForwardType.Channel: {
          presenter.shareContact(target.id);
          break;
        }
        case TargetForwardType.Contact: {
          if (!target.group) {
            target = await Tasks.group.createP2PGroup(target.id);
            presenter.shareContact(target.id);
          } else {
            presenter.shareContact(target.group.id);
          }
          break;
        }
      }

      return target.group ? target.group : target;
    },

    async getCreateSavedMessage(): Promise<Group | null> {
      return await Tasks.group.createGroupAndInvite({
        type: GroupType.MySavedMessages,
      });
    },

    shareContact(groupId: string): void {
      Tasks.messaging.createNewMessages({
        messages: [
          {
            type: PayloadType.Contact,
            data: presenter.user,
            groupId,
          },
        ],
      });
    },

    onClose(): void {
      popupInstance.close();
      onClose?.();
    },

    onBack(): void {
      popupInstance.close();
      onBack?.();
    },
  }));

  useEffect(() => {
    if (!presenter.user) {
      presenter.onClose();
    }
  }, []);

  return presenter;
}
