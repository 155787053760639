import { observer } from 'mobx-react';
import React from 'react';
import { CSSTransition } from 'react-transition-group';

import Entities from 'STORE';

import styles from './AnimatedRightPanel.styles.m.css';
import transitions from './AnimatedRightPanel.transitions.m.css';
import { RightPanel } from './RightPanel/RightPanel';

const MemberListCSSTransitionClasses = {
  enter: transitions.memberListEnter,
  enterActive: transitions.memberListEnterActive,
  exit: transitions.memberListExit,
  exitActive: transitions.memberListExitActive,
};

export const AnimatedRightPanel = observer(() => {
  const ongoingCall = Entities.OngoingCall;

  return (
    <CSSTransition
      in={ongoingCall.members.isMembersShown}
      classNames={MemberListCSSTransitionClasses}
      timeout={100}
      mountOnEnter
      unmountOnExit
    >
      <div className={styles.container}>
        <RightPanel />
      </div>
    </CSSTransition>
  );
});
