import { IBooleanResponse } from 'APP/model/common/commonModel.types';
import { buildModelError } from 'APP/model/error/errorBuilder';
import {
  IBanUsersForGroupRequest,
  IChangeOwnershipRequest,
  IGetGroupUserRolesRequest,
  IGroupIdRequest,
  IGroupParticipantChangeRequest,
  IGroupSettings,
  IGroupUserRolesResponse,
  IMemberCustomPermissions,
  IMemberCustomPermissionsRequest,
  IPinMessagesRequest,
  ISetGroupSettings,
  ISetObsceneFilterRequest,
  ISetKeywordFiltersRequest,
} from 'APP/model/group/groupModel.types';
import api from 'APP/packages/api';
import logger from 'APP/packages/logger';

export const pinMessages = async (data: IPinMessagesRequest): Promise<IBooleanResponse> => {
  try {
    return api.group.pinMessages({
      groupId: data.groupId,
      messageIds: data.messageIds,
    });
  } catch (error) {
    logger.get('API').error('group.pinMessages', error);
    throw buildModelError(error);
  }
};

export const unpinMessages = async (data: IPinMessagesRequest): Promise<IBooleanResponse> => {
  try {
    return api.group.unpinMessages({
      groupId: data.groupId,
      messageIds: data.messageIds,
    });
  } catch (error) {
    logger.get('API').error('group.unpinMessages', error);
    throw buildModelError(error);
  }
};

export const unpinAllMessages = async (data: IGroupIdRequest): Promise<IBooleanResponse> => {
  try {
    return api.group.unpinAllMessages({ groupId: data.groupId });
  } catch (error) {
    logger.get('API').error('group.unpinAllMessages', error);
    throw buildModelError(error);
  }
};

export const setMemberPermissions = async (
  data: IMemberCustomPermissionsRequest
): Promise<IMemberCustomPermissions> => {
  try {
    return api.group.setMemberPermissions(data);
  } catch (error) {
    logger.get('API').error('setMemberPermissions', error);
    throw buildModelError(error);
  }
};

export const setGroupSettings = async (data: ISetGroupSettings): Promise<IGroupSettings> => {
  try {
    return api.group.setGroupSettings(data);
  } catch (error) {
    logger.get('API').error('setGroupSettings', error);
    throw buildModelError(error);
  }
};

export const setObsceneFilter = async (data: ISetObsceneFilterRequest): Promise<boolean> => {
  try {
    const result = await api.group.setObsceneFilter(data);
    return result.result;
  } catch (error) {
    logger.get('API').error('group.setObsceneFilter', error);
    throw buildModelError(error);
  }
};

export const markGroupsAsUnread = async (groupIds: string[], unread = true): Promise<boolean> => {
  try {
    await api.group.markGroupsAsUnread({ groupIds, unread });
    return true;
  } catch (error) {
    logger.get('API').error('group.markGroupsAsUnread', error);
    throw buildModelError(error);
  }
};

export const showContent = async (groupId: string): Promise<void> => {
  try {
    await api.group.showContent({ groupId });
  } catch (error) {
    logger.get('API').error('group.showContent', error);
    throw buildModelError(error);
  }
};

export const banUsersForGroup = async (
  data: IBanUsersForGroupRequest
): Promise<IBooleanResponse> => {
  try {
    return await api.group.banUsersForGroup(data);
  } catch (error) {
    logger.get('API').error('group.banUsersForGroup', error);
    throw buildModelError(error);
  }
};

export const getGroupUserRoles = async (
  data: IGetGroupUserRolesRequest
): Promise<IGroupUserRolesResponse> => {
  try {
    return await api.group.getGroupUserRoles(data);
  } catch (error) {
    logger.get('API').error('group.getGroupUserRoles', error);
    throw buildModelError(error);
  }
};

export const transferOwnership = async (
  data: IChangeOwnershipRequest
): Promise<IBooleanResponse> => {
  try {
    return await api.group.transferOwnership(data);
  } catch (error) {
    logger.get('API').error('group.transferOwnership', error);
    throw buildModelError(error);
  }
};

export async function removeGroupParticipants(
  data: IGroupParticipantChangeRequest
): Promise<IBooleanResponse> {
  try {
    return await api.group.removeGroupParticipants(data);
  } catch (error) {
    logger.get('API').error('group.removeGroupParticipants', error);
    throw buildModelError(error);
  }
}

export async function addGroupParticipants(
  data: IGroupParticipantChangeRequest
): Promise<IBooleanResponse> {
  try {
    return await api.group.addGroupParticipants(data);
  } catch (error) {
    logger.get('API').error('group.addGroupParticipants', error);
    throw buildModelError(error);
  }
}

export async function addGroupAdmins(
  data: IGroupParticipantChangeRequest
): Promise<IBooleanResponse> {
  try {
    return await api.group.addGroupAdmins(data);
  } catch (error) {
    logger.get('API').error('group.addGroupAdmins', error);
    throw buildModelError(error);
  }
}

export async function removeGroupAdmins(
  data: IGroupParticipantChangeRequest
): Promise<IBooleanResponse> {
  try {
    return await api.group.removeGroupAdmins(data);
  } catch (error) {
    logger.get('API').error('group.removeGroupAdmins', error);
    throw buildModelError(error);
  }
}

export async function getKeywordFilters(groupId: string): Promise<string[]> {
  try {
    const result = await api.group.getKeywordFilters({ groupId });
    return result.keywords;
  } catch (error) {
    logger.get('API').error('group.getKeywordFilters', error);
    throw buildModelError(error);
  }
}

export async function setKeywordFilters(data: ISetKeywordFiltersRequest): Promise<boolean> {
  try {
    const result = await api.group.setKeywordFilters(data);
    return result.result;
  } catch (error) {
    logger.get('API').error('group.setKeywordFilters', error);
    throw buildModelError(error);
  }
}
