import Tasks from 'APP/Tasks';
import api from 'APP/packages/api';
import logger from 'APP/packages/logger';
import Entities from 'APP/store';
import { Message } from 'APP/store/Messages/Message/Message';

const timeout: Record<string, { timerId: ReturnType<typeof setTimeout>; messageId: string }> = {};

export async function confirmReadMessages({
  groupId,
  messageId,
}: {
  groupId: string;
  messageId: string;
}): Promise<void> {
  const group = Entities.GroupsStore.getGroupById(groupId);

  if (!group) {
    return;
  }

  let message: Message | null = group.messagesStore.getMessageById(messageId);

  if (!message && messageId === group.lastMessage?.id) {
    message = group.lastMessage;
  }

  if (!message?.unread) {
    return;
  }

  if (timeout[groupId]?.messageId) {
    const prevMessage = group.messagesStore.getMessageById(timeout[groupId]?.messageId);
    if (prevMessage?.serverTime > message.serverTime) {
      return;
    }
    clearTimeout(timeout[groupId]?.timerId);
  }

  const timerId = setTimeout(async () => {
    if (!message) {
      return;
    }
    const seenTs = message.serverTime;
    group.setSeenTs(parseInt(seenTs, 10) || 0);
    try {
      await api.messaging.confirmReadGroupMessages({
        groupId: group.id,
        messageId: messageId,
      });
      if (group.lastMessage?.id === messageId) {
        Entities.pushNotifications.clearNotificationsByGroupId(group.id);
      } else {
        Entities.pushNotifications.removeNotificationByMessageId(group.id, messageId);
      }
      if (group.unread) {
        await Tasks.group.markGroupsAsUnread([group.id], false);
      }
    } catch (error) {
      logger.get('API').error('messaging.confirmReadGroupMessages', error);
    }
  }, 300);

  timeout[groupId] = { messageId, timerId };
}
