import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { IconButton } from 'APP/packages/UiKit/IconButton';
import CallIcon32 from 'ICONS/ic-call-32.svg';
import { IconViewSizes } from 'UIKIT/IconView/IconView.types';
import { PopoverMenu } from 'UIKIT/PopoverMenu/PopoverMenu';

import { useStartCallButtonPresenter } from './StartCallButton.presenter';
import styles from './StartCallButton.styles.m.css';

interface IStartCallButtonProps {
  className?: string;
  groupId: string;
  startAudioCall: (() => unknown) | null;
  startVideoCall: (() => unknown) | null;
  startConferenceCall: (() => unknown) | null;
  scheduleCallEvent(): void;
}

export const StartCallButton: FC<IStartCallButtonProps> = observer((props) => {
  const {
    groupId,
    startAudioCall,
    startVideoCall,
    startConferenceCall,
    className,
    scheduleCallEvent,
  } = props;
  const { presenter, popover } = useStartCallButtonPresenter({
    groupId,
    startAudioCall,
    startVideoCall,
    startConferenceCall,
    scheduleCallEvent,
  });

  if (!presenter.menuItems.length) {
    return null;
  }

  return (
    <>
      <IconButton
        className={className}
        setRef={popover.containerRef}
        Icon={CallIcon32}
        onClick={presenter.onClick}
        size={IconViewSizes.Big}
      />
      <PopoverMenu
        isOpened={popover.isOpened}
        placement="bottom-end"
        menuItems={presenter.menuItems}
        reference={popover.containerRef.current}
        className={styles.menu}
        onClose={popover.close}
      />
    </>
  );
});
