import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { ISpaceCollection } from 'APP/model/space/spaceModel.types';
import Entities from 'STORE';
import { Space } from 'STORE/Spaces/Space';

interface ISpaceCollections {
  isLoading: boolean;
  space: Space | null;
  collections: ISpaceCollection[];
  loadCollections(): void;
  getCollectionById(id: string): ISpaceCollection | null;
}

export function useSpaceCollections(spaceId?: string): ISpaceCollections {
  const source = useAsObservableSource({ spaceId });

  const presenter = useLocalStore<ISpaceCollections>(() => ({
    get space(): Space | null {
      return source.spaceId ? Entities.spacesStore.getById(source.spaceId) : null;
    },

    get collections(): ISpaceCollection[] {
      return presenter.space?.collections.data || [];
    },

    get isLoading(): boolean {
      return !!presenter.space?.collections.isLoading;
    },

    async loadCollections(): Promise<void> {
      if (!presenter.space) {
        return;
      }

      await Tasks.space.loadSpaceCollections(presenter.space.id);
    },

    getCollectionById(id: string): ISpaceCollection | null {
      return presenter.space?.collections.getCollection(id) || null;
    },
  }));

  useEffect(() => {
    if (presenter.space?.collections.isNeedToUpdate) {
      presenter.loadCollections();
    }
  }, [presenter.space?.collections.isNeedToUpdate]);

  return presenter;
}
