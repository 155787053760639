import { useAsObservableSource, useLocalStore } from 'mobx-react';

import { mapUserProfileBadgeToIcon } from 'APP/constants/userBadges';
import { UserProfileUsernameBadge } from 'APP/model/user/userModel.types';
import { IUserNameBadge } from 'APP/types/userNameBadges';
import Entities from 'STORE';
import { Group } from 'STORE/Groups/Group';

interface IP2PChatInfoPresenter {
  userNameBadge: IUserNameBadge | null;
  verified: boolean;
}

export const useP2PChatInfoPresenter = (group: Group): IP2PChatInfoPresenter => {
  const source = useAsObservableSource({
    group,
  });

  return useLocalStore<IP2PChatInfoPresenter>(() => ({
    get userNameBadge(): IUserNameBadge | null {
      const isP2p = source.group.isP2P;

      if (!isP2p) {
        return null;
      }

      const opponentId = source.group.opponentId;

      const user = Entities.UsersStore.getUserById(opponentId);

      if (!user || !user.usernameBadge) {
        return null;
      }

      return mapUserProfileBadgeToIcon[user.usernameBadge as UserProfileUsernameBadge];
    },

    get verified(): boolean {
      const isP2p = source.group.isP2P;

      if (!isP2p) {
        return false;
      }

      const opponentId = source.group.opponentId;
      const user = Entities.UsersStore.getUserById(opponentId);

      return user?.verified;
    },
  }));
};
