import { observer } from 'mobx-react';
import React from 'react';

import { DomainMigrateHandler } from './DomainMigrateHandler/DomainMigrateHandler';
import { IsBackNavigationHandler } from './IsBackNavigationHandler/IsBackNavigationHandler';
import NavigationHandler from './NavigationHandler';
import { ResizeListener } from './ResizeListener/ResizeListener';
import { ThemeHandler } from './ThemeHandler/ThemeHandler';
import { UnauthorizedDeepLinkHandler } from './UnauthorizedDeepLinkHandler/UnauthorizedDeepLinkHandler';

const Modules = () => {
  return (
    <>
      <UnauthorizedDeepLinkHandler />
      <DomainMigrateHandler />
      <NavigationHandler />
      <ThemeHandler />
      <ResizeListener />
      <IsBackNavigationHandler />
    </>
  );
};

export default observer(Modules);
