import { useCallback, useEffect, useState } from 'react';

import { exitFullScreen, enterFullScreen, isFullscreen } from 'UTILS/fullScreen';

export function useVideoPlayerFullscreenMode({
  videoContainerRef,
  newIsFullScreenModeState,
  onToggleFullScreenMode,
}) {
  const [isFullScreenMode, setFullScreenMode] = useState(false);

  const toggleFullScreenMode = useCallback(() => {
    isFullscreen() ? exitFullscreenMode() : enterFullscreenMode();
  }, []);

  const enterFullscreenMode = useCallback(async () => {
    await enterFullScreen(videoContainerRef.current);
    updateFullscreenMode();
  }, []);

  const exitFullscreenMode = useCallback(async () => {
    await exitFullScreen();
    updateFullscreenMode();
  }, []);

  const updateFullscreenMode = useCallback(() => {
    setFullScreenMode(isFullscreen());
    onToggleFullScreenMode(isFullscreen());
  }, []);

  useEffect(() => {
    if (newIsFullScreenModeState && !isFullScreenMode) {
      enterFullscreenMode();
    }

    if (!newIsFullScreenModeState && isFullScreenMode) {
      exitFullscreenMode();
    }
  }, [newIsFullScreenModeState]);

  useEffect(() => {
    document.addEventListener('fullscreenchange', updateFullscreenMode);

    return () => {
      document.addEventListener('fullscreenchange', updateFullscreenMode);
    };
  }, []);

  return { isFullScreenMode, toggleFullScreenMode };
}
