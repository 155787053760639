import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { openPopup } from 'APP/Tasks/app/popup/popup';
import { useTranslation } from 'APP/packages/translations';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import Entities from 'APP/store';
import IcBlock from 'ICONS/ic-block.svg';
import IcInfo from 'ICONS/ic-info.svg';
import { ReportUserPopup } from 'MAIN/PopupManager/Popups/ReportUserPopup/ReportUserPopup';
import { Popup } from 'STORE/App/Popups/Popup/Popup';
import type { User } from 'STORE/Users/User/User';
import { IPopover } from 'UIKIT/Popover/Popover.types';
import { IPopoverMenuItem } from 'UIKIT/PopoverMenu/PopoverMenu.types';

interface IUnknownUserBlockPresenterParams {
  userId: string;
  groupId: string;
  popover: IPopover;
}

interface IUnknownUserBlockPresenter {
  childPopup: Popup | null;
  menuItems: IPopoverMenuItem[];
  user: User | null;
  onBlockUser(): Promise<void>;
  onBlockAndReportUser(): void;
}

export const useUnknownUserBlockPresenter = (
  data: IUnknownUserBlockPresenterParams
): IUnknownUserBlockPresenter => {
  const { userId, groupId, popover } = data;
  const { t } = useTranslation();
  const navigateTo = useNavigateTo();
  const source = useAsObservableSource({ groupId, userId, navigateTo });

  const presenter = useLocalStore<IUnknownUserBlockPresenter>(() => ({
    childPopup: null,

    get user(): User | null {
      return Entities.UsersStore.getUserById(source.userId);
    },

    get menuItems(): IPopoverMenuItem[] {
      return [
        {
          key: 'block',
          icon: IcBlock,
          title: t('block_userprofile'),
          onClick: presenter.onBlockUser,
        },
        {
          key: 'block-and-report',
          icon: IcInfo,
          title: t('common_block_and_report'),
          onClick: presenter.onBlockAndReportUser,
        },
      ];
    },

    onBlockAndReportUser(): void {
      presenter.onBlockUser();
      openPopup(ReportUserPopup, {
        params: { userId: source.userId, groupId: source.groupId },
      });
    },

    async onBlockUser(): Promise<void> {
      await Tasks.relationships.blockUser(source.userId);
      popover.close();
      presenter.user?.setIsUnknown(false);
    },
  }));

  useEffect(() => {
    return () => presenter.childPopup?.close();
  }, []);

  return presenter;
};
