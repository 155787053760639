import classNames from 'classnames';
import React, { FC, JSXElementConstructor, useCallback, useEffect, useState } from 'react';

import { ProfileNameBadge } from 'APP/components/ProfileNameBadge/ProfileNameBadge';
import { IUserNameBadgeSource } from 'APP/constants/userBadges';
import { UserProfileUsernameBadge } from 'APP/model/user/userModel.types';

import { EditProfileBadgeLock } from '../EditProfileBadgeLock/EditProfileBadgeLock';
import styles from './EditProfileBadgeItem.styles.m.css';

interface IEditProfileBadgeItemProps {
  animated?: boolean;
  className?: string;
  badgeType?: UserProfileUsernameBadge;
  source: IUserNameBadgeSource | JSXElementConstructor<any>;
  isActive: boolean;
  hasLock?: boolean;
  isDisabled?: boolean;
  onClick(badge?: UserProfileUsernameBadge): void;
  onOpenRewards(): void;
}

export const EditProfileBadgeItem: FC<IEditProfileBadgeItemProps> = (props) => {
  const [showLock, setShowLock] = useState(!!props.hasLock);

  useEffect(() => {
    if (props.hasLock && !showLock) {
      setShowLock(true);
    }
  }, [props.hasLock, showLock]);

  const onLockHidingEnd = useCallback(() => {
    setShowLock(false);
  }, []);

  const onClick = useCallback(() => {
    if (!props.isDisabled) {
      props.onClick(props.badgeType);
    } else {
      props.onOpenRewards();
    }
  }, [props.badgeType, props.isDisabled, props.onOpenRewards]);

  return (
    <button
      type={'button'}
      onClick={onClick}
      className={classNames(styles.badge, props.className, {
        [styles.badgeActive]: props.isActive,
      })}
    >
      <ProfileNameBadge
        source={props.source}
        animated={props.animated}
        displayUILayer={1}
      />
      {showLock && (
        <EditProfileBadgeLock
          onHidingEnd={onLockHidingEnd}
          isVisible={props.hasLock}
        />
      )}
    </button>
  );
};
