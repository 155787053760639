import Entities from 'STORE';

import { GroupsEvent } from '../GroupsEvent';

interface IUserMarkedUnknownByMeData {
  userId: number;
  isUnknown: boolean;
}

export class UserMarkedUnknownByMe extends GroupsEvent<IUserMarkedUnknownByMeData> {
  process() {
    if (!this.eventPayload) {
      return;
    }

    const user = Entities.UsersStore.getUserById(this.eventPayload.userId.toString());

    if (user) {
      user.setIsUnknown(this.eventPayload.isUnknown);
    }
  }
}
