import { useLocalStore, useAsObservableSource } from 'mobx-react';
import { useEffect } from 'react';

import { MEDIA_MAX_SIZE } from 'APP/constants/articles';
import { PayloadType } from 'APP/model/message/messageModel.types';
import { getWidthHeightByMaxValues } from 'APP/packages/imageResizer';

import uploadImageByLink from './tasks/uploadImageByLink';

export default ({ parentPresenter, payload, order, id }) => {
  const source = useAsObservableSource({
    parentPresenter,
    payload,
    order,
    id,
  });

  const presenter = useLocalStore(() => ({
    get width() {
      return MEDIA_MAX_SIZE.width;
    },

    get height() {
      const { height } = getWidthHeightByMaxValues(
        source.payload.graphicSize.width,
        source.payload.graphicSize.height,
        MEDIA_MAX_SIZE.width,
        MEDIA_MAX_SIZE.height
      );

      return height;
    },

    uploadImage: async () => {
      parentPresenter.addSomethingLoading(id);
      const { url, graphicSize } = await uploadImageByLink(payload.url);

      if (url && graphicSize) {
        const newPayload = {
          comment: '',
          payloadType: PayloadType.Image,
          url,
          graphicSize,
        };
        source.parentPresenter.editPayloadByOrder(newPayload, source.order);
      }

      parentPresenter.removeSomethingLoading(id);
    },
  }));

  useEffect(() => {
    presenter.uploadImage();
  }, []);

  return presenter;
};
