import { action, observable } from 'mobx';
import { FC } from 'react';

import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import { Popup } from 'STORE/App/Popups/Popup/Popup';

export interface IPopupOptions<TParams = any, TResult = any> {
  params?: TParams;
  onClose?(data?: TResult): void;
  onBack?(data?: TResult): void;
}

export class Popups {
  @observable data: Popup[] = [];

  @action
  openPopup<T>(Component: FC<ICommonPopupsProps<T>>, options?: IPopupOptions<T>): Popup<T> {
    const popup = new Popup<T>(this, Component, options);
    this.data.push(popup);
    return popup;
  }

  @action
  closePopup(popup: Popup<any>): void {
    this.data = this.data.filter((p) => p !== popup);
  }

  @action reset(): void {
    this.data = [];
  }
}
