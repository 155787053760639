import { TFunction } from 'react-i18next';

import {
  TSystemMessageTextHandler,
  ISystemMessageText,
  ISystemMessageTextPresenter,
} from '../useSystemMessageText.types';

export const chatMessagesSettingsChanged: TSystemMessageTextHandler = (
  presenter: ISystemMessageTextPresenter,
  t: TFunction
): ISystemMessageText => {
  if (presenter.isPublicGroup) {
    return {
      html: presenter.payload.systemMessagesEnabled
        ? t('permissions_switch_on_system_message_public')
        : t('permissions_switch_off_system_message_public'),
    };
  }

  if (presenter.payload.systemMessagesEnabled) {
    return {
      html: t('permissions_switch_on_system_message_private', {
        0: `<span class="system-message-title">${presenter.initiatorDisplayName}</span>`,
        interpolation: { escapeValue: false },
      }),
      sourceText: t('permissions_switch_on_system_message_private'),
      params: {
        '{0}': { text: presenter.initiatorDisplayName, userId: presenter.initiator?.id },
      },
    };
  } else {
    return {
      html: t('permissions_switch_off_system_message_private', {
        0: `<span class="system-message-title">${presenter.initiatorDisplayName}</span>`,
        interpolation: { escapeValue: false },
      }),
      sourceText: t('permissions_switch_off_system_message_private'),
      params: {
        '{0}': { text: presenter.initiatorDisplayName, userId: presenter.initiator?.id },
      },
    };
  }
};
