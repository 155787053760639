import { observable, action } from 'mobx';

class ViewedMessages {
  @observable numberViewedMessages = 0;
  cacheViewedMessageByGroup = new Map();
  viewedMessageByGroup = new Map();

  @action reset() {
    this.numberViewedMessages = 0;
    this.cacheViewedMessageByGroup.clear();
    this.viewedMessageByGroup.clear();
  }

  viewedMessage = (groupId, messageId) => {
    if (this.cacheViewedMessageByGroup.has(groupId)) {
      return this.cacheViewedMessageByGroup.get(groupId).has(messageId);
    }
    return null;
  };

  saveViewedMessages = (groupId, messageIds = []) => {
    let viewedMessageIds = this.cacheViewedMessageByGroup.get(groupId);
    if (!viewedMessageIds) {
      viewedMessageIds = this.cacheViewedMessageByGroup.set(groupId, new Set()).get(groupId);
    }
    messageIds.forEach((id) => viewedMessageIds.add(id));
  };

  @action
  increaseViewMessages = (groupId, messageIds = []) => {
    let _messageIds = this.viewedMessageByGroup.get(groupId);
    if (!_messageIds) {
      _messageIds = this.viewedMessageByGroup.set(groupId, new Set()).get(groupId);
    }
    messageIds.forEach((id) => _messageIds.add(id));

    this.saveViewedMessages(groupId, messageIds);
    this.numberViewedMessages += messageIds.length;
  };

  @action
  resetViewedMessages = (groupId, messageIds = []) => {
    let _messageIds = this.viewedMessageByGroup.get(groupId);
    if (_messageIds) {
      messageIds.forEach((id) => _messageIds.delete(id));
    }
    this.numberViewedMessages -= messageIds.length;
  };
}

export default ViewedMessages;
