import { action, computed, observable, values } from 'mobx';

import { ISpaceData } from 'APP/model/space/spaceModel.types';
import { SpaceRecommendations } from 'STORE/Spaces/SpaceRecommendations';

import { Space } from './Space';

export class SpacesStore {
  @observable public isLoading = true;
  @observable public activeSpaceId: string | null = null;

  public recommendations: SpaceRecommendations;
  public spaceTs: string = new Date().valueOf().toString();

  @observable private data: Map<string, Space> = new Map();

  constructor() {
    this.recommendations = new SpaceRecommendations(this);
  }

  @action
  public reset(): void {
    this.data.clear();
    this.isLoading = true;
    this.activeSpaceId = null;
  }

  public getById(id: string): Space | null {
    return this.data.get(id) || null;
  }

  @action
  public setLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
  }

  @action
  public addSpace(newSpace: ISpaceData): void {
    this.addSpaces([newSpace]);
  }

  @action
  public addSpaces(newSpaces: ISpaceData[]): void {
    newSpaces.forEach((spaceData) => {
      const spaceId = spaceData.space.id;
      const space = this.getById(spaceId);

      if (space) {
        space.update(spaceData);
      } else {
        this.data.set(spaceId, new Space(spaceData));
      }
    });
  }

  @action
  public updateSpace(spaceData: ISpaceData): void {
    const space = this.getById(spaceData.space.id);

    if (space) {
      space.update(spaceData);
    }
  }

  @action
  public delete(spaceId: string): void {
    this.data.delete(spaceId);
  }

  @action
  public setActiveSpace(spaceId: string | null): void {
    this.activeSpaceId = spaceId;
  }

  @computed
  public get activeSpace(): Space | null {
    return this.activeSpaceId ? this.getById(this.activeSpaceId) : null;
  }

  @computed
  public get isEmpty(): boolean {
    return !this.data.size;
  }

  @computed
  public get spaces(): Space[] {
    return values(this.data) as unknown as Space[];
  }

  @computed
  public get maxPinOrder(): number {
    return Math.max(...this.spaces.map((space) => space.pinOrder));
  }

  public setSpaceTs(spaceTs: string): void {
    if (spaceTs) {
      this.spaceTs = spaceTs;
    }
  }
}
