import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import dateService from 'APP/packages/date';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import Entities from 'APP/store';
import ArticleDrafts from 'STORE/ArticleDrafts';
import { Group } from 'STORE/Groups/Group';

import getDrafts from './Tasks/getDrafts';

interface IDraftRequestResult {
  groupId: string;
  cursorTs: number;
}

interface IArticlesDraftPresenter {
  isLoading: boolean;
  count: number;
  drafts: ArticleDrafts;
  group: Group | null;
  getDraftsRequest: IDraftRequestResult;
  hasBackground: boolean;
  isLoaded: number | null;
  loadDrafts(): Promise<void>;
  init(): Promise<void>;
  onClose(): void;
}

export const useArticlesDraftPresenter = (groupId: string): IArticlesDraftPresenter => {
  const navigateTo = useNavigateTo();
  const source = useAsObservableSource({
    groupId,
  });

  const presenter = useLocalStore<IArticlesDraftPresenter>(() => ({
    isLoading: true,

    async init(): Promise<void> {
      presenter.isLoading = true;

      !presenter.count && (await presenter.loadDrafts());

      presenter.isLoading = false;
    },

    async loadDrafts(): Promise<void> {
      await getDrafts(presenter.getDraftsRequest);
    },

    get hasBackground(): boolean {
      return Entities.appearance.hasChatBackground;
    },

    get group(): Group | null {
      return Entities.GroupsStore.getGroupById(source.groupId);
    },

    get drafts(): ArticleDrafts {
      return presenter.group && presenter.group.draftsStore.drafts;
    },

    get isLoaded(): number | null {
      return presenter.group && presenter.count;
    },

    get count(): number {
      return presenter.drafts && presenter.group?.draftsStore.drafts.length;
    },

    get getDraftsRequest(): IDraftRequestResult {
      return {
        groupId: source.groupId,
        cursorTs: dateService.now().getTime(),
      };
    },

    onClose(): void {
      if (presenter.group) {
        navigateTo({ to: `/${presenter.group.route}/${groupId}` });
      }
    },
  }));

  useEffect(() => {
    presenter.init();
  }, [presenter.group]);

  return presenter;
};
