import { action, observable, ObservableMap, values } from 'mobx';

import dateService from 'APP/packages/date';

import { ChatMessage } from '../Messages/Message/ChatMessage/ChatMessage';

class ArticleDrafts {
  @observable data = new ObservableMap();
  @observable nextTs = null;
  @observable selectedDraftIds = [];

  constructor(group) {
    this.group = group;
  }

  @action
  setDrafts = (drafts) => {
    drafts.forEach((draft) => {
      this.data.set(draft.id, new ChatMessage(draft, this.group.messagesStore));
    });
  };

  @action
  getDraftById = (id) => {
    return this.data.get(id);
  };

  @action
  removeDraftById = (id) => {
    this.data.delete(id);
  };

  @action
  setDraft = (draft) => {
    this.data.set(draft.id, draft);
  };

  @action
  updateDraft = (draft) => {
    this.data.set(draft.id, draft);
  };

  @action
  isNextDay = (index) => {
    if (index === 0) {
      return true;
    }

    const currentMessage = this.drafts[index];
    const prevMessage = this.drafts[index - 1];

    return !dateService.isSameDay(prevMessage.serverTime, currentMessage.serverTime);
  };

  get drafts() {
    return values(this.data).sort((a, b) => {
      const aSortProps = this.getSortTimeByMessage(a);
      const bSortProps = this.getSortTimeByMessage(b);

      return aSortProps - bSortProps;
    });
  }

  getSortTimeByMessage = (draft) => {
    if (draft.serverTime) {
      return draft.serverTime;
    }

    return parseInt(draft.clientTime, 10);
  };
}

export default ArticleDrafts;
