import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { CatalogWidgetType, ICatalogItem } from 'APP/model/catalog/catalogModel.types';
import noop from 'APP/utils/noop';

import { useCatalogWidgetPresenter } from './CatalogWidget.presenter';
import { TScrollPositionChangeHandler } from './CatalogWidget.types';

interface ICatalogWidgetProps {
  className?: string;
  type: CatalogWidgetType;
  showItemType: boolean;
  title?: string;
  items: ICatalogItem[];
  scrollPosition?: number;
  onScrollPositionChange?: TScrollPositionChangeHandler;
}

export const CatalogWidget: FC<ICatalogWidgetProps> = observer((props) => {
  const {
    className,
    type,
    showItemType,
    title,
    items,
    scrollPosition = 0,
    onScrollPositionChange = noop,
  } = props;

  const presenter = useCatalogWidgetPresenter({ type, showItemType, items });
  const Component = presenter.Component;

  if (!Component) {
    return null;
  }

  return (
    <Component
      className={className}
      title={title}
      items={presenter.widgetItems}
      scrollPosition={scrollPosition}
      onScrollPositionChange={onScrollPositionChange}
    />
  );
});
