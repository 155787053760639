import Tasks from 'APP/Tasks';
import { AudioSourceType } from 'APP/constants/app';
import { CallType } from 'APP/model/call/callModel.types';
import { callModel } from 'APP/model/model';
import { ServerErrorCode } from 'APP/packages/api';
import { t } from 'MODULE/i18n/service';
import Entities from 'STORE';
import { Call } from 'STORE/Calls/Call/Call';
import { Group } from 'STORE/Groups/Group';

export const startSharedCallAndCreateGroup = async (): Promise<Group | null> => {
  if (Entities.Calls.hasCalls || Entities.ActiveStream.hasStream) {
    Tasks.app.showAlert(t('calls_join_another_call'));
    return null;
  }

  const hasAudioPermissions = await Tasks.permissions.hasAudioPermissions();

  if (!hasAudioPermissions) {
    return null;
  }

  Tasks.app.audioSource.setCurrentSource(AudioSourceType.Call);
  const date = new Date();
  const callId = String(date.getTime());
  const providerType = Entities.Calls.callsProvider;
  const call = new Call({
    calls: Entities.Calls,
    groupId: callId,
    channelId: null,
    callType: CallType.Group,
    providerType,
    isSharedCall: true,
  });

  // ToDo: [calls] temporary workaround for creating calling placeholder till requests will be finished
  Entities.Calls.add(callId, call);

  try {
    const response = await callModel.createCall({
      groupId: null,
      callType: call.callType,
      providerType,
    });
    const channelId = response.channelId;
    const { groupId } = response;

    const group = await Tasks.group.loadNewCallGroup(groupId);

    call.setGroupId(groupId);
    // ToDo: [calls] temporary workaround for creating calling placeholder till requests will be finished
    Entities.Calls.data.delete(callId);

    Entities.Calls.add(groupId, call);
    await Tasks.calls.setCallInfoAndPublish({
      callInfo: response,
      call,
      isVideoMuted: true,
      isAudioMuted: true,
      withoutTimer: true,
    });

    await Tasks.calls.getCallPermissions(channelId);
    await Tasks.calls.getPermissionRequests(channelId);

    call.setStarted(true);

    return group;
  } catch (e) {
    call.delete();
    if (e?.message === ServerErrorCode.HasNoPermissionsToPerformAction) {
      Tasks.app.showAlert(t('no_permission_alert'));

      return null;
    }

    if (e?.message === ServerErrorCode.HasNoPermissionsToCall) {
      Tasks.app.showAlert(t('privacy_settings:privacy_settings_call_alert'));
      return null;
    }

    Tasks.app.showAlert(t('calls_connection_error'));

    return null;
  }
};
