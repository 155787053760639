import { observer } from 'mobx-react';
import React from 'react';

import { useTranslation } from 'APP/packages/translations';
import { Button } from 'UIKIT/Button/Button';

import { useBlockedByMeUserPanelPresenter } from './BlockedByMeUserPanel.presenter';
import styles from './BlockedByMeUserPanel.styles.m.css';

export const BlockedByMeUserPanel = observer(() => {
  const { t } = useTranslation();
  const presenter = useBlockedByMeUserPanelPresenter();

  return (
    <div className={styles.container}>
      <Button
        isDisabled={presenter.isDisabled}
        isLoading={presenter.isLoading}
        onClick={presenter.unblock}
        theme="4"
      >
        {t('unblock_user')}
      </Button>
    </div>
  );
});
