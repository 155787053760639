import { spaceModel } from 'APP/model/model';
import { ISpaceParticipantsChange } from 'APP/model/space/spaceModel.types';
import { spaceAnalytics } from 'APP/packages/analytics/analytics';

export async function revokeAdminFromSpace(data: ISpaceParticipantsChange): Promise<boolean> {
  try {
    await spaceModel.revokeFromAdmin(data);

    data.userIds.forEach((userId) => spaceAnalytics.trackSpaceAdminRemoved(data.spaceId, userId));

    return true;
  } catch {
    return false;
  }
}
