import React, { memo } from 'react';

import SinglePlaceholder from './SinglePlaceholder';

const SessionsPlaceholder = () => {
  return (
    <>
      <SinglePlaceholder />
      <SinglePlaceholder />
      <SinglePlaceholder />
    </>
  );
};

export default memo(SessionsPlaceholder);
