import { TFunction } from 'react-i18next';

import {
  TSystemMessageTextHandler,
  ISystemMessageText,
  ISystemMessageTextPresenter,
} from '../useSystemMessageText.types';

export const chatAvatarChangedHandler: TSystemMessageTextHandler = (
  presenter: ISystemMessageTextPresenter,
  t: TFunction
): ISystemMessageText => {
  const result: ISystemMessageText = { html: '' };

  if (presenter.isPublicGroup) {
    result.html = t('admin_changed_group_chat_avatar');
  } else if (presenter.isChannel) {
    result.html = t('message_chat_changed_picture_without_name');
  } else {
    result.html = t('message_chat_changed_picture', {
      0: `<span class="system-message-title">${presenter.initiatorDisplayName}</span>`,
      interpolation: { escapeValue: false },
    });
    result.sourceText = t('message_chat_changed_picture');
    result.params = {
      '{0}': { text: presenter.initiatorDisplayName, userId: presenter.initiator?.id },
    };
  }

  return result;
};
