import { useLocalStore, useAsObservableSource } from 'mobx-react';

import { PayloadType } from 'APP/model/message/messageModel.types';

const INCLUDED_TYPES = [PayloadType.Image, PayloadType.Video, PayloadType.RichText];

const MEDIA_TYPES = [PayloadType.Image, PayloadType.Video];

export default ({ message }) => {
  const source = useAsObservableSource({ message });

  const presenter = useLocalStore(() => ({
    get title() {
      return presenter.payload.title;
    },

    get text() {
      return presenter.payload.payloads.find(
        (typeObject) =>
          PayloadType.RichText === typeObject.payload.payloadType &&
          typeObject.order === Math.min(typeObject.order)
      );
    },

    get media() {
      return presenter.payload.payloads.find(
        (typeObject) =>
          MEDIA_TYPES.includes(typeObject.payload.payloadType) &&
          typeObject.order === Math.min(typeObject.order)
      );
    },

    get message() {
      return source.message;
    },

    get payload() {
      return presenter.message.payload;
    },

    get payloads() {
      return presenter.payload.payloads.filter((typeObject) => {
        return INCLUDED_TYPES.includes(typeObject.payload.payloadType);
      });
    },

    get imageUrl() {
      if (presenter.media) {
        const { previewUrl, url, payloadType } = presenter.media.payload;

        return payloadType === PayloadType.Video ? previewUrl : url;
      }

      return null;
    },
  }));

  return presenter;
};
