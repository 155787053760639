import Tasks from 'APP/Tasks';
import { AudioSourceType } from 'APP/constants/app';
import { CallEventTypes } from 'APP/constants/calls';
import { GroupUserRole } from 'APP/model/group/groupModel.types';
import api from 'APP/packages/api';
import Entities from 'APP/store';

async function request(data) {
  try {
    await api.messaging.leaveGroup(data);
    return {
      error: null,
    };
  } catch (error) {
    return {
      error: error.message,
    };
  }
}

export const leaveGroup = async ({ groupId }) => {
  if (groupId === Entities.GroupsStore.activeGroup?.id) {
    Tasks.group.setActiveGroup({ groupId: null });
  }

  const call = Entities.Calls.getCallByGroupId(groupId);

  if (call) {
    Tasks.app.audioSource.removeSource(AudioSourceType.Call);

    const isFinishToAll = call.isP2P || call.isMeInitiatorOwnerOrAdmin;

    if (isFinishToAll) {
      await api.calls.finishCall({
        channelId: call.channelId,
        reason: CallEventTypes.FinishAll,
      });
    }

    call.delete();
  }

  const { error } = await request({ groupId });
  if (error) {
    return { error };
  }

  const group = Entities.GroupsStore.getGroupById(groupId);

  if (!group) {
    return { error: null };
  }

  if ((group.isChatGroup || group.isChannel) && group.isOpen) {
    group.setUserGroupRole(GroupUserRole.Guest);
    Entities.Counters.delete(groupId);
  } else {
    Tasks.group.removeGroupLocally({ groupId });
  }

  return { error: null };
};
