import AgoraRTC, { IAgoraRTCClient, ILocalVideoTrack } from 'agora-rtc-sdk-ng';

import { AGORA_APP_ID, ClientRole } from 'APP/constants/calls';
import logger from 'APP/packages/logger';

import { AGORA_CLIENT_CALL_SETTINGS } from '../AgoraClient/AgoraClient.constants';
import { ProviderUid, ICallProviderOpponent } from '../callProviders.types';
import { AgoraProviderOpponent } from './AgoraProviderOpponent';

// ToDo: after implementing OpenviduProvider create common config
// see packages/core/node_modules/agora-rtc-sdk-ng/rtc-sdk_en.d.ts
const SCREEN_SHARING_VIDEO_CONFIG = '1080p_2';

export class AgoraShareScreenProvider {
  private client: IAgoraRTCClient;
  private localScreenTrack: ILocalVideoTrack | null = null;
  isJoined = false;
  uid: ProviderUid | null;

  constructor() {
    this.client = AgoraRTC.createClient({ ...AGORA_CLIENT_CALL_SETTINGS, role: ClientRole.Host });
  }

  async join(params: { token: string; uid: ProviderUid; channelId: string }): Promise<void> {
    const { token, uid, channelId } = params;

    try {
      this.uid = uid;
      await this.client.join(AGORA_APP_ID, channelId, token, uid);
      this.isJoined = true;
    } catch (e) {
      logger.get('AgoraCallProviders').error('AgoraShareScreenProvider.join', e);
    }
  }

  async createScreenSharingTrack(
    mediaStreamTrack?: MediaStreamTrack
  ): Promise<ILocalVideoTrack | null> {
    let localTrack: ILocalVideoTrack | null;

    if (mediaStreamTrack) {
      localTrack = AgoraRTC.createCustomVideoTrack({
        mediaStreamTrack,
      });
    } else {
      const track = await AgoraRTC.createScreenVideoTrack({
        encoderConfig: SCREEN_SHARING_VIDEO_CONFIG,
      });
      localTrack = Array.isArray(track) ? track[0] : track;
    }

    return localTrack;
  }

  async startScreenSharing(params: {
    onEnded?: () => void;
    needToPublish?: boolean;
    track?: MediaStreamTrack;
  }): Promise<ICallProviderOpponent | null> {
    if (!this.uid || !this.isJoined) {
      return null;
    }

    try {
      const track = await this.createScreenSharingTrack(params.track);

      if (!track) {
        return null;
      }

      if (params.onEnded) {
        track.getMediaStreamTrack().onended = params.onEnded;
      }

      if (params.needToPublish) {
        await this.client.publish(track);
      }

      this.localScreenTrack = track;

      return new AgoraProviderOpponent({
        videoTrack: track,
        hasAudio: false,
        hasVideo: true,
        uid: this.uid,
      });
    } catch (e) {
      await this.stopScreenShare();
      logger.get('AgoraCallProviders').error('AgoraShareScreenProvider.startScreenSharing', e);

      return null;
    }
  }

  async stopScreenShare(): Promise<void> {
    if (this.localScreenTrack) {
      if (this.isJoined) {
        await this.client.unpublish(this.localScreenTrack);
      }
      this.localScreenTrack.stop();
      this.localScreenTrack.close();
      this.localScreenTrack = null;
    }
    if (this.isJoined) {
      await this.client.leave();
      this.isJoined = false;
    }
  }
}
