import React from 'react';

import ReactionsList from './ReactionsList';
import SeenList from './SeenList';
import usePresenter from './presenter';

const UserList = ({ tabs }) => {
  const presenter = usePresenter({ tabs });

  return presenter.isUserSeenList ? (
    <SeenList list={tabs.currentTab.list} />
  ) : (
    <ReactionsList list={tabs.currentTab.list} />
  );
};

export default UserList;
