import { useCallback } from 'react';

import { AudioPlayerOrder, AudioPlayerRepeat, IAudioPlayerConfig } from '../../AudioPlayer.types';

const orderOptions = [AudioPlayerOrder.Direct, AudioPlayerOrder.Shuffle];
const repeatOptions = [AudioPlayerRepeat.None, AudioPlayerRepeat.Track, AudioPlayerRepeat.List];

function getNextOption<T>(current: T, options: T[]): T {
  const index = options.indexOf(current);
  return options[index + 1] || options[0];
}

interface IAudioPlayerControlsPresenterOptions {
  config: IAudioPlayerConfig;
  onConfigUpdate(config: Partial<IAudioPlayerConfig>): void;
}

interface IAudioPlayerControlsPresenter {
  changeOrder(): void;
  changeRepeat(): void;
}

export function useAudioPlayerControlsPresenter({
  config,
  onConfigUpdate,
}: IAudioPlayerControlsPresenterOptions): IAudioPlayerControlsPresenter {
  const changeOrder = useCallback(() => {
    onConfigUpdate({ order: getNextOption(config.order, orderOptions) });
  }, [config.order, onConfigUpdate]);

  const changeRepeat = useCallback(() => {
    onConfigUpdate({ repeat: getNextOption(config.repeat, repeatOptions) });
  }, [config.repeat, onConfigUpdate]);

  return { changeOrder, changeRepeat };
}
