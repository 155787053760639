import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { UserAvatar } from 'APP/components/Avatar/UserAvatar/UserAvatar';
import { ProfileNameBadge } from 'APP/components/ProfileNameBadge/ProfileNameBadge';
import { useTranslation } from 'APP/packages/translations';
import IcCall from 'ICONS/ic-call.svg';
import IcMute from 'ICONS/ic-mute.svg';
import IcVideoCall from 'ICONS/ic-video-on.svg';
import { Popup } from 'STORE/App/Popups/Popup/Popup';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';
import { Button } from 'UIKIT/Button/Button';
import { IconButton } from 'UIKIT/IconButton';
import { IconView } from 'UIKIT/IconView/IconView';
import { MenuItem } from 'UIKIT/MenuItem/MenuItem';
import { Switch } from 'UIKIT/Switch/Switch';

import { PhoneNumber } from '../components/PhoneNumber/PhoneNumber';
import { ProfileBio } from '../components/ProfileBio/ProfileBio';
import { ProfileNickname } from '../components/ProfileNickname/ProfileNickname';
import { IUserProfileInitiatorSource, useUserProfilePresenter } from './UserProfile.presenter';
import styles from './UserProfile.styles.m.css';
import { UserProfileJoinCallButton } from './UserProfileJoinCallButton/UserProfileJoinCallButton';

interface IUserProfileProps {
  className: string;
  metaBlockClassName: string;
  userId: string;
  groupId: string;
  initiatorSource?: IUserProfileInitiatorSource;
  hasActions?: boolean;
  actions: any;
  popupInstance?: Popup;
  onStartCall(): void;
}

export const UserProfile: FC<IUserProfileProps> = observer(
  ({
    className,
    metaBlockClassName,
    userId,
    groupId,
    initiatorSource,
    hasActions = true,
    actions,
    popupInstance,
    onStartCall,
  }) => {
    const { t } = useTranslation();
    const presenter = useUserProfilePresenter({
      userId,
      groupId,
      initiatorSource,
      actions,
      popupInstance,
      onStartCall,
    });

    //Todo modify the output of the remote user presenter.user === null
    if (!presenter.user) {
      return null;
    }

    return (
      <div className={classNames(styles.container, className)}>
        <div className={styles.header}>
          <UserAvatar
            className={styles.avatar}
            id={presenter.user.id}
            type={AvatarTypes.Round_20}
            withFullScreen={true}
          />
          <div className={styles.info}>
            <h3 className={styles.title}>
              {presenter.user?.displayName}
              <ProfileNameBadge
                verified={presenter.verified}
                className={styles.badge}
                inlineBadge
                source={presenter.userNameBadge?.source}
                animated={presenter.userNameBadge?.animated}
                displayUILayer={1}
              />
            </h3>
            <p className={styles.subtitle}>{presenter.userOnlineStatusText}</p>
          </div>
        </div>

        {hasActions && (
          <>
            <div className={styles.actions}>
              <Button
                theme="1"
                onClick={presenter.startChat}
              >
                {t('profile_user_send_message')}
              </Button>
              {presenter.canJoinToCall && (
                <UserProfileJoinCallButton onClick={presenter.joinToBackgroundCall} />
              )}
              {presenter.canStartCall && (
                <>
                  <IconButton
                    theme="6"
                    Icon={IcCall}
                    onClick={presenter.startAudioCall}
                    className={styles.actionButton}
                    iconClassName={styles.actionButtonIcon}
                    title={t('call_profile_audio_call')}
                  />
                  <IconButton
                    theme="6"
                    Icon={IcVideoCall}
                    onClick={presenter.startVideoCall}
                    className={styles.actionButton}
                    iconClassName={styles.actionButtonIcon}
                    title={t('action_over_chat_videcall')}
                  />
                </>
              )}
              {presenter.hasActiveCall && (
                <UserProfileJoinCallButton onClick={presenter.goToChat} />
              )}
            </div>
            <hr className={styles.dividerBackground} />
          </>
        )}

        {presenter.user && (
          <ProfileNickname
            className={classNames(styles.contact, metaBlockClassName)}
            user={presenter.user}
          />
        )}
        {presenter.user?.displayPhone && (
          <PhoneNumber
            className={classNames(styles.contact, metaBlockClassName)}
            phoneNumber={presenter.user.displayPhone}
          />
        )}
        {presenter.user?.bio && (
          <ProfileBio
            className={classNames(styles.contact, metaBlockClassName)}
            text={presenter.user.bio}
          />
        )}

        {hasActions &&
          presenter.hasConversationWithUser &&
          presenter.group?.notificationsAvailable && (
            <div className={styles.notifications}>
              <hr className={styles.divider} />
              <MenuItem
                className={styles.listItem}
                titleClassName={styles.listItemTitle}
                title={t('common_notifications')}
                icon={
                  <IconView
                    Icon={IcMute}
                    className={styles.listIcon}
                  />
                }
                onClick={presenter.toggleNotifications}
              >
                <Switch
                  className={styles.switch}
                  isChecked={presenter.isNotificationsEnabled}
                  onChange={presenter.toggleNotifications}
                />
              </MenuItem>
            </div>
          )}

        {presenter.childPagesList.length > 0 && <hr className={styles.dividerBackground} />}
        {presenter.childPagesList.map(({ key, title, Icon, onClick, count }) => (
          <MenuItem
            className={styles.listItem}
            titleClassName={styles.listItemTitle}
            key={key}
            title={title}
            count={count}
            icon={
              <IconView
                Icon={Icon}
                className={styles.listIcon}
              />
            }
            onClick={onClick}
          />
        ))}

        <hr className={styles.dividerBackground} />
        {presenter.actionItems.map(({ key, title, Icon, onClick }) => (
          <MenuItem
            className={styles.listItem}
            titleClassName={styles.listItemTitle}
            key={key}
            title={title}
            icon={
              <IconView
                Icon={Icon}
                className={styles.listIcon}
              />
            }
            onClick={onClick}
          />
        ))}
      </div>
    );
  }
);
