import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { showToast } from 'APP/Tasks/app/showToast/showToast';
import { checkSlowMode } from 'APP/Tasks/messaging/checkSlowMode/checkSlowMode';
import { useTranslation } from 'APP/packages/translations';
import { RouterPage } from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import Entities from 'APP/store';
import CalendarIcon from 'ICONS/ic-calendar.svg';
import PublishIcon from 'ICONS/ic-publish.svg';
import DeleteIcon from 'ICONS/ic-s-delete.svg';
import EditIcon from 'ICONS/ic-s-edit.svg';

import canPublishDraft from '../../Tasks/canPublishDraft';
import canScheduledDraft from '../../Tasks/canScheduledDraft';
import sendDraft from '../../Tasks/sendDraft';

export const useDraftsContextMenuItems = ({ draft, popover }) => {
  const navigateTo = useNavigateTo();
  const { t } = useTranslation();
  const source = useAsObservableSource({
    draft,
  });

  const presenter = useLocalStore(() => ({
    publishNow: async () => {
      const canSend = checkSlowMode();
      if (!canSend.result) {
        canSend.error && showToast(canSend.error);
        return;
      }

      await sendDraft(source.draft);
    },

    scheduledNow: async () => {
      Tasks.drafts.saveScheduledDraft({ draft: source.draft, navigateTo });
    },

    get canPublish() {
      return canPublishDraft(source.draft);
    },

    get canScheduled() {
      return canScheduledDraft(source.draft);
    },

    edit: () => {
      navigateTo({
        to: `/${RouterPage.Channels}/${source.draft.groupId}/article/${source.draft.id}`,
      });
    },

    delete: async () => {
      popover.close();

      const canDelete = await Tasks.app.showConfirm({
        text: t('delete_message'),
        primaryText: t('common_yes'),
        secondaryText: t('common_cancel'),
      });

      if (canDelete) {
        await Tasks.drafts.deleteDraft(source.draft);
      }
    },

    get items() {
      return [
        {
          key: 'publish',
          title: t('common:common_publish_now'),
          onClick: presenter.publishNow,
          icon: PublishIcon,
          isVisible: presenter.canPublish,
        },
        {
          key: 'scheduled',
          title: t('common:common_publish_on_date'),
          onClick: presenter.scheduledNow,
          icon: CalendarIcon,
          isVisible: presenter.canScheduled,
        },
        {
          key: 'edit',
          title: t('message_edit'),
          onClick: presenter.edit,
          icon: EditIcon,
          isVisible: true,
        },
        {
          key: 'delete',
          title: t('chat_list_delete_single_delete_button'),
          onClick: presenter.delete,
          closeByClick: false,
          icon: DeleteIcon,
          style: 'danger',
          isVisible: true,
        },
      ].filter((item) => item.isVisible);
    },

    get store() {
      return Entities.GroupsStore.activeGroup.draftsStore;
    },
  }));

  useEffect(() => {
    if (!presenter.items.length) {
      popover.close();
    }
  }, [presenter.items]);

  return presenter;
};
