import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { MessageViews } from 'APP/components/MessageViews/MessageViews';
import { IconView } from 'APP/packages/UiKit/IconView/IconView';
import EditedIcon from 'ICONS/edited.svg';

import Time from './Time';
import styles from './styles.m.css';

const StatusBar = ({ message, className = '', viewsClassName = '', showEditIcon = true }) => {
  const showEdit = Boolean(showEditIcon && (message.editedPayload || message.editTime));

  return (
    <div className={classNames(styles.statusBar, className)}>
      {showEdit && (
        <IconView
          Icon={EditedIcon}
          size="small"
          className={styles.edited}
        />
      )}
      <Time time={message.displayTime} />
      <MessageViews
        message={message}
        className={viewsClassName}
      />
    </div>
  );
};

export default observer(StatusBar);
