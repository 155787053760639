import React, { FC } from 'react';

import { AllDownloadsItemInfo } from '../AllDownloadsItemInfo/AllDownloadsItemInfo';
import { AllDownloadsMediaItemIcon } from './AllDownloadsMediaItemIcon/AllDownloadsMediaItemIcon';

export interface IAllDownloadsMediaItemProps {
  className?: string;
  payloadId: string;
  groupId: string;
  messageId: string;
}

export const AllDownloadsMediaItem: FC<IAllDownloadsMediaItemProps> = (props) => {
  const { className, payloadId, groupId, messageId } = props;

  return (
    <div className={className}>
      <AllDownloadsMediaItemIcon
        payloadId={payloadId}
        groupId={groupId}
        messageId={messageId}
      />
      <AllDownloadsItemInfo
        payloadId={payloadId}
        groupId={groupId}
        messageId={messageId}
      />
    </div>
  );
};
