import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useRef, MouseEvent, MutableRefObject } from 'react';

import { useTranslation } from 'APP/packages/translations';
import Entities from 'STORE';
import { Group } from 'STORE/Groups/Group';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';
import { IPopoverMenuItem } from 'UIKIT/PopoverMenu/PopoverMenu.types';

export interface IGroupAvailableItemParams {
  group: Group;
  menuItems?: IPopoverMenuItem[];
  onJoin(): Promise<void>;
  onClick(): void;
}

export interface IGroupAvailableItemPresenter {
  isLoadingJoin: boolean;
  isActive: boolean;
  avatarType: AvatarTypes;
  subTitle: string;
  verified: boolean;
  hasContextMenu: boolean;
  menuItems: IPopoverMenuItem[];
  onJoin(): Promise<void>;
  onClick(event: MouseEvent<HTMLElement>): void;
}

export interface IUseGroupAvailableItemPresenter {
  presenter: IGroupAvailableItemPresenter;
  joinButtonRef: MutableRefObject<HTMLButtonElement | null>;
}

export function useGroupAvailableItemPresenter(
  data: IGroupAvailableItemParams
): IUseGroupAvailableItemPresenter {
  const { group, menuItems, onJoin, onClick } = data;

  const { t } = useTranslation();
  const joinButtonRef = useRef<HTMLButtonElement>(null);
  const source = useAsObservableSource({ group, menuItems, onJoin, onClick });

  const presenter = useLocalStore<IGroupAvailableItemPresenter>(() => ({
    isLoadingJoin: false,

    get hasContextMenu(): boolean {
      return !!presenter.menuItems.length;
    },

    get verified(): boolean {
      return source.group.verified;
    },

    get isActive(): boolean {
      const activeGroup = Entities.GroupsStore.activeGroup;
      if (!activeGroup) {
        return false;
      }

      if (activeGroup.parentGroup) {
        return source.group.id === activeGroup.parentGroup.id;
      } else {
        return source.group.id === activeGroup.id;
      }
    },

    get avatarType(): AvatarTypes {
      return source.group.isChannel ? AvatarTypes.Square_20 : AvatarTypes.Round_10;
    },

    get subTitle(): string {
      const usersCount = source.group.usersCount.toString();

      if (source.group.isChannel) {
        return t('amount_of_subscribers_in_a_channel_many', { 0: usersCount });
      } else {
        return t('amount_of_members_in_a_group_many', { 0: usersCount });
      }
    },

    get menuItems(): IPopoverMenuItem[] {
      return source.menuItems || [];
    },

    async onJoin(): Promise<void> {
      presenter.isLoadingJoin = true;
      await source.onJoin();
      presenter.isLoadingJoin = false;
    },

    async onClick(event: MouseEvent<HTMLElement>): Promise<void> {
      if (joinButtonRef.current?.contains(event.target as Node)) {
        return;
      }

      source.onClick();
    },
  }));

  return { presenter, joinButtonRef };
}
