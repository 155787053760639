import { configure } from './config';
import differenceInDay from './helpers/differenceInDay';
import differenceInSeconds from './helpers/differenceInSeconds';
import differenceWithNow from './helpers/differenceWithNow';
import format from './helpers/format';
import formatDuration from './helpers/formatDuration';
import formatISO from './helpers/formatISO';
import formatText from './helpers/formatText';
import { getDurationText } from './helpers/getDurationText';
import { getNextMinute } from './helpers/getNextMinute';
import isHour12 from './helpers/isHour12';
import isLastWeek from './helpers/isLastWeek';
import isLastYear from './helpers/isLastYear';
import isSameDay from './helpers/isSameDay';
import isToday from './helpers/isToday';
import isYesterday from './helpers/isYesterday';
import millisecondToEndOfDay from './helpers/millisecondToEndOfDay';
import { now } from './helpers/now';
import nowWithOffset from './helpers/nowWithOffset';
import parseTime from './helpers/parseTime';
import secondsToString from './helpers/secondsToString';
import time from './helpers/time';

export default {
  now,
  isToday,
  isYesterday,
  isLastWeek,
  isLastYear,
  format,
  formatISO,
  formatText,
  differenceWithNow,
  differenceInSeconds,
  differenceInDay,
  isSameDay,
  secondsToString,
  formatDuration,
  nowWithOffset,
  time,
  parseTime,
  isHour12,
  millisecondToEndOfDay,
  getDurationText,
  getNextMinute,
};

export { configure };
