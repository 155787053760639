import { ICallPermissionsSettings } from 'APP/model/callPermissions/callPermissionsModel.types';
import { DeviceInfoPlatform } from 'APP/model/common/commonModel.types';
import { CallConnectedResponseCallTypeEnum } from 'APP/packages/api/types/model/callConnectedResponse';
import { CallCreateRequestCallTypeEnum } from 'APP/packages/api/types/model/callCreateRequest';
import { CallCreateV2RequestCallTypeEnum } from 'APP/packages/api/types/model/callCreateV2Request';
import { CallDataCallTypeEnum } from 'APP/packages/api/types/model/callData';
import {
  CallFinishedResponseDataCallTypeEnum,
  CallFinishedResponseDataReasonEnum,
} from 'APP/packages/api/types/model/callFinishedResponseData';
import { CallFinishedResponseDataAllOfReasonEnum } from 'APP/packages/api/types/model/callFinishedResponseDataAllOf';
import {
  CallHistoryResponseCallTypeEnum,
  CallHistoryResponseStatusEnum,
} from 'APP/packages/api/types/model/callHistoryResponse';
import { CallResponseDataReasonEnum } from 'APP/packages/api/types/model/callResponseData';
import { CallResponseDataAllOfReasonEnum } from 'APP/packages/api/types/model/callResponseDataAllOf';
import { CallStartedResponseDataCallTypeEnum } from 'APP/packages/api/types/model/callStartedResponseData';
import {
  GroupCallChanged,
  GroupCallChangedCallProviderEnum,
  GroupCallChangedCallTypeEnum,
  GroupCallChangedStatusEnum,
} from 'APP/packages/api/types/model/groupCallChanged';
import {
  IncomingCall,
  IncomingCallCallProviderEnum,
  IncomingCallCallTypeEnum,
} from 'APP/packages/api/types/model/incomingCall';
import {
  StatusChangedCallProviderEnum,
  StatusChangedCallTypeEnum,
  StatusChangedStatusEnum,
} from 'APP/packages/api/types/model/statusChanged';
import { StreamChanged } from 'APP/packages/api/types/model/streamChanged';
import {
  SystemCallResponseDataCallTypeEnum,
  SystemCallResponseDataFinishReasonEnum,
} from 'APP/packages/api/types/model/systemCallResponseData';
import { SystemCallResponseDataAllOfFinishReasonEnum } from 'APP/packages/api/types/model/systemCallResponseDataAllOf';
import { UpdatePermissionRequestForUserStatusEnum } from 'APP/packages/api/types/model/updatePermissionRequestForUser';
import { UpdatePermissionsRequestForAllStatusEnum } from 'APP/packages/api/types/model/updatePermissionsRequestForAll';
import { ProviderUid } from 'APP/packages/callProviders/callProviders.types';

import { CallMemberStatus } from './callModel.constants';

export interface ICallMember {
  providerUserUid: ProviderUid;
  avatarUrl?: string;
  hasPermissionRequest: boolean;
  isHandRaised: boolean;
  platform?: DeviceInfoPlatform;
  shareScreenUid: ProviderUid;
  status: CallMemberStatus;
  userId: string;
  username: string;
}

export interface ICallInfo {
  audioVideoUid: ProviderUid;
  shareScreenUid: ProviderUid;
  callType: CallType;
  channelId: string;
  currentTs: string;
  groupId: string;
  initiatorId: string;
  isAdmin: boolean;
  maxBroadcasters: number;
  members: ICallMember[];
  permissions: ICallPermissionsSettings;
  recordingId?: string;
  shareScreenToken: string;
  audioVideoToken: string;
}

export interface ICallCreatedInfo {
  audioVideoUid: ProviderUid;
  shareScreenUid: ProviderUid;
  callVisibleDuration: number;
  channelId: string;
  currentTs: string;
  groupId: string;
  initiatorId: string;
  isAdmin: boolean;
  maxBroadcasters: number;
  members: ICallMember[];
  permissions: ICallPermissionsSettings;
  shareScreenToken: string;
  audioVideoToken: string;
}

export interface ICallCreateProps {
  groupId: string | null;
  callType: CallType;
  providerType: CallProviderType;
}

export interface IIncomingCall extends Omit<IncomingCall, 'channel' | 'callVisibleDuration'> {
  channelId: string;
  callVisibleDuration: number;
}
export interface IGroupCallChanged extends Omit<GroupCallChanged, 'channel' | 'status'> {
  channelId: string;
  status: CallStatus;
}

export interface IStreamChanged extends Omit<StreamChanged, 'channel'> {
  channelId: string;
}

export const CallType = {
  ...CallConnectedResponseCallTypeEnum,
  ...CallCreateRequestCallTypeEnum,
  ...CallCreateV2RequestCallTypeEnum,
  ...CallDataCallTypeEnum,
  ...CallFinishedResponseDataCallTypeEnum,
  ...CallHistoryResponseCallTypeEnum,
  ...CallStartedResponseDataCallTypeEnum,
  ...GroupCallChangedCallTypeEnum,
  ...IncomingCallCallTypeEnum,
  ...StatusChangedCallTypeEnum,
  ...SystemCallResponseDataCallTypeEnum,
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type CallType =
  | CallConnectedResponseCallTypeEnum
  | CallCreateRequestCallTypeEnum
  | CallCreateV2RequestCallTypeEnum
  | CallDataCallTypeEnum
  | CallFinishedResponseDataCallTypeEnum
  | CallHistoryResponseCallTypeEnum
  | CallStartedResponseDataCallTypeEnum
  | GroupCallChangedCallTypeEnum
  | IncomingCallCallTypeEnum
  | StatusChangedCallTypeEnum
  | SystemCallResponseDataCallTypeEnum;

export const CallStatus = {
  ...GroupCallChangedStatusEnum,
  ...StatusChangedStatusEnum,
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type CallStatus = GroupCallChangedStatusEnum | StatusChangedStatusEnum;

export const CallProviderType = {
  ...GroupCallChangedCallProviderEnum,
  ...IncomingCallCallProviderEnum,
  ...StatusChangedCallProviderEnum,
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type CallProviderType =
  | GroupCallChangedCallProviderEnum
  | IncomingCallCallProviderEnum
  | StatusChangedCallProviderEnum;

export const FinishReasons = {
  ...CallFinishedResponseDataReasonEnum,
  ...CallFinishedResponseDataAllOfReasonEnum,
  ...CallHistoryResponseStatusEnum,
  ...SystemCallResponseDataFinishReasonEnum,
  ...CallResponseDataReasonEnum,
  ...CallResponseDataAllOfReasonEnum,
  ...SystemCallResponseDataAllOfFinishReasonEnum,
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type FinishReasons =
  | CallFinishedResponseDataReasonEnum
  | CallFinishedResponseDataAllOfReasonEnum
  | CallHistoryResponseStatusEnum
  | SystemCallResponseDataFinishReasonEnum
  | CallResponseDataReasonEnum
  | CallResponseDataAllOfReasonEnum
  | SystemCallResponseDataAllOfFinishReasonEnum;

export const PermissionsRequestStatuses = {
  ...UpdatePermissionRequestForUserStatusEnum,
  ...UpdatePermissionsRequestForAllStatusEnum,
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type PermissionsRequestStatuses =
  | UpdatePermissionRequestForUserStatusEnum
  | UpdatePermissionsRequestForAllStatusEnum;
