export async function getAudioFileDuration(source: File | Blob | string): Promise<number> {
  return new Promise((resolve) => {
    const url = typeof source === 'string' ? source : URL.createObjectURL(source);

    const audio = new Audio(url);

    audio.addEventListener(
      'loadedmetadata',
      () => {
        resolve(Math.floor(audio.duration * 1000));
      },
      { once: true }
    );
  });
}
