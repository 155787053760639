import { memo, useEffect } from 'react';

const KEYBOARD_KEYS = [32, 33, 34, 35, 36, 37, 38, 39, 40];

export const DisableScroll = memo(({ node }: { node: HTMLElement | undefined }): null => {
  useEffect(() => {
    const preventKeydown = (event: KeyboardEvent): void => {
      if (KEYBOARD_KEYS.includes(event.keyCode)) {
        event.preventDefault();
      }
    };

    const preventDefault = (event: Event): void => {
      if (node?.contains(event.target as HTMLElement)) {
        return;
      }
      event.preventDefault();
    };

    document.addEventListener('wheel', preventDefault, {
      passive: false,
    });
    document.addEventListener('DOMMouseScroll', preventDefault, false);
    document.onkeydown = preventKeydown;
    window.ontouchmove = preventDefault;

    return (): void => {
      document.removeEventListener('wheel', preventDefault, {
        passive: false,
      } as EventListenerOptions);
      document.removeEventListener('DOMMouseScroll', preventDefault, false);
      document.onkeydown = null;
      window.ontouchmove = null;
    };
  }, [node]);

  return null;
});
