/* global Date */
import isValidDate from './isValidDate';

export default (firstRawDate, secondRawDate) => {
  const firstDate = new Date(firstRawDate);

  if (!isValidDate(firstDate)) {
    return false;
  }

  const secondDate = new Date(secondRawDate);

  if (!isValidDate(secondDate)) {
    return false;
  }

  return firstDate.toDateString() === secondDate.toDateString();
};
