import { observer } from 'mobx-react';
import React from 'react';

import DoneIcon from 'ICONS/ic-done-32.svg';
import PlayIcon from 'ICONS/ic-play-32.svg';
import { UploaderState } from 'MAIN/ChatView/components/UploaderState/UploaderState';

import usePresenter from './presenter';
import styles from './styles.m.scss';

const Video = ({ parentPresenter, payload, order, id }) => {
  const presenter = usePresenter({ parentPresenter, payload, order, id });

  const { url, uploadProgress } = presenter.message.payload;

  if (!url) {
    return null;
  }

  return (
    <div
      className={styles.container}
      style={presenter.containerStyle}
    >
      <img
        className={styles.image}
        src={presenter.url}
        loading="lazy"
        alt=""
      />
      <UploaderState
        className={styles.uploader}
        uploadFileData={{
          uploadProgress: presenter.message.payload.uploadProgress,
          cancelUpload: presenter.message.payload.cancelUploadAndDeleteMessage,
          hasError: presenter.message.hasError,
        }}
      >
        {uploadProgress ? (
          <DoneIcon className={styles.icon} />
        ) : (
          <PlayIcon className={styles.icon} />
        )}
      </UploaderState>
    </div>
  );
};

export default observer(Video);
