import { observer } from 'mobx-react';
import React from 'react';

import { List } from 'APP/components/List/List';
import { Popup } from 'APP/components/Popup/Popup';
import { GroupsPlaceholder } from 'APP/packages/placeholders/GroupsPlaceholder/GroupsPlaceholder';
import { useTranslation } from 'APP/packages/translations';
import { SearchInput } from 'UIKIT/SearchInput/SearchInput';

import { CallMemberItem } from './components/CallMemberItem';
import { usePresenter } from './presenter';
import styles from './styles.m.css';

export const CallSelectMemberPopup = observer(() => {
  const { t } = useTranslation();
  const presenter = usePresenter();

  const props = {
    className: styles.list,
    isScrollable: true,
    data: presenter.userRolesLoading ? [] : presenter.participants,
    isLoading: presenter.userRolesLoading,
    SpinnerComponent: (
      <GroupsPlaceholder
        count={4}
        className={styles.placeholders}
      />
    ),
    keyExtractor: (contact) => contact.userId,
    renderItem: (member) => <CallMemberItem userId={member.userId} />,
  };

  if (!presenter.ongoingCall) {
    return null;
  }

  return (
    <Popup
      title={t('common_participants')}
      isOpened
      handlePrevStep={presenter.goBack}
      onClose={presenter.onClose}
    >
      <SearchInput
        className={styles.search}
        onChange={presenter.onChangeSearchValue}
        value={presenter.searchValue}
        placeholder={t('main_menu_search_note')}
      />
      <List {...props} />
    </Popup>
  );
});
