/**
 * Rest API
 * Documentation Rest API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CallResponseData { 
    payloadType: string;
    initiatorId: string;
    callerId: string;
    channelId: string;
    startTs: string;
    duration: string;
    reason: CallResponseDataReasonEnum;
    callType?: CallResponseDataCallTypeEnum;
}
export enum CallResponseDataReasonEnum {
    Unanswered = 'UNANSWERED',
    Declined = 'DECLINED',
    Finished = 'FINISHED'
};
export enum CallResponseDataCallTypeEnum {
    P2P = 'P2P',
    Group = 'GROUP',
    Conference = 'CONFERENCE',
    Stream = 'STREAM'
};



