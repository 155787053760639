import React from 'react';

import { useTranslation } from 'APP/packages/translations';
import NothingIcon from 'ICONS/nothing.svg';

import styles from './SearchResultEmpty.styles.m.css';

export const SearchResultEmpty = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <NothingIcon className={styles.icon} />
      <div className={styles.text}>{t('search_no_results')}</div>
    </div>
  );
};
