import { action, observable } from 'mobx';

export class Feedback {
  @observable isStarsShown = false;
  @observable isDescriptionShown = false;
  @observable starsRate: null | number = null;
  @observable channelId: null | string = null;

  @action
  reset(): void {
    this.isStarsShown = false;
    this.isDescriptionShown = false;
    this.starsRate = null;
    this.channelId = null;
  }

  @action
  setStarsRate(rate: number): void {
    this.starsRate = rate;
  }

  @action
  setChannelId(channelId: string): void {
    this.channelId = channelId;
  }

  @action
  setIsStarsShown(val: boolean): void {
    this.isStarsShown = val;
  }

  @action
  setIsDescriptionShown(val: boolean): void {
    this.isDescriptionShown = val;
  }
}
