import { mapAddToContactResponse } from 'APP/model/contact/createContact.mapper';
import { buildModelError } from 'APP/model/error/errorBuilder';
import { IDisplayDataResponse, IUpdatePhoneBookRequest } from 'APP/model/user/userModel.types';
import api from 'APP/packages/api';
import logger from 'APP/packages/logger';

export const addToContact = async (
  data: IUpdatePhoneBookRequest
): Promise<IDisplayDataResponse[]> => {
  try {
    const response = await api.relationships.updatePhoneBook({
      added: data.added,
      last: data.last,
      removed: data.removed,
    });
    return mapAddToContactResponse(response);
  } catch (error) {
    logger.get('API').error('contact.createContact', error);
    throw buildModelError(error);
  }
};
