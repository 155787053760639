import { observer } from 'mobx-react';
import React from 'react';

import { CallActionsPanel } from 'MAIN/OngoingCall/Shared/CallActionsPanel/CallActionsPanel';
import Entities from 'STORE';

import { CompactScreenVideoLayout } from '../Shared/CompactScreenVideoLayout/CompactScreenVideoLayout';
import { SpeakerVideo } from '../Shared/SpeakerVideo/SeakerVideo';
import styles from './P2PVideoCall.styles.m.css';
import { P2PVideoCallHeader } from './P2PVideoCallHeader/P2PVideoCallHeader';

export const P2PVideoCall = observer(() => {
  return (
    <div className={styles.container}>
      <P2PVideoCallHeader call={Entities.OngoingCall.call} />
      {Entities.OngoingCall.presentVideoMode.isGridLayout ? (
        <CompactScreenVideoLayout />
      ) : (
        <SpeakerVideo />
      )}
      <CallActionsPanel />
    </div>
  );
});
