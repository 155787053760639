import { useLocalStore } from 'mobx-react';

import { PayloadType } from 'APP/model/message/messageModel.types';
import { parseToTree } from 'APP/packages/markdown/parseToTree';
import { renderTextFromTree } from 'APP/packages/markdown/renderUtils';
import { RouterState } from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';

const getTextFromPayload = (payload) => {
  if (payload.payloadType === PayloadType.Article) {
    return payload.payloads.reduce((acc, item) => {
      if (item.payload.payloadType === PayloadType.RichText) {
        return `${acc}${item.payload.text} `;
      }

      return acc;
    }, '');
  }

  return payload.text;
};

const getShortDescription = (description) => {
  const MAX_DESCRIPTION_LENGTH = 100;

  return description.length > MAX_DESCRIPTION_LENGTH
    ? `${description.substring(0, MAX_DESCRIPTION_LENGTH)}…`
    : description;
};

const MEDIA_TYPES = [PayloadType.Image, PayloadType.Video];

export const useArticleItemPresenter = ({ payload, messageId }) => {
  const navigateTo = useNavigateTo();
  const presenter = useLocalStore(() => ({
    get article() {
      const media = payload.payloads.find((typeObject) =>
        MEDIA_TYPES.includes(typeObject.payload.payloadType)
      );
      const { title } = payload;
      const text = getTextFromPayload(payload);
      const tree = parseToTree({
        text,
        hasBotCommands: false,
        keyWords: [],
        isPlainText: true,
      });
      const renderedText = renderTextFromTree({ tree });
      const description = getShortDescription(renderedText);

      if (media) {
        return {
          media,
          title,
          description,
        };
      }

      return {
        media: null,
        title,
        description,
      };
    },

    async openArticle() {
      const group = payload.message.group;

      if (group) {
        navigateTo({
          to: `/${group.route}/${group.id}/article-preview/${messageId}`,
          state: {
            [RouterState.hasGoBack]: true,
          },
        });
      }
    },
  }));

  return presenter;
};
