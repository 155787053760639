import { observer } from 'mobx-react';
import React from 'react';

import { Button } from 'UIKIT/Button/Button';

import Menu from './Menu';
import usePresenter from './presenter';

const SaveArticleButton = ({ parent }) => {
  const { presenter, publishButtonRef } = usePresenter({ parent });

  return (
    <>
      <Button
        theme="1"
        setRef={publishButtonRef}
        isDisabled={presenter.isDisabled}
        onClick={presenter.onClick}
        dataTest="publish"
      >
        {presenter.title}
      </Button>

      {presenter.isShowMenu && (
        <Menu
          parent={presenter}
          reference={publishButtonRef}
        />
      )}
    </>
  );
};

export default observer(SaveArticleButton);
