import { updateUserLocalePreferences } from 'APP/model/catalog/catalogModel';

export const updateUserLocales = async (locales: string[]): Promise<boolean> => {
  try {
    const result = await updateUserLocalePreferences({ locales });
    return result.result;
  } catch {
    return false;
  }
};
