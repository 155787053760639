import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { useTranslation } from 'APP/packages/translations';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import { Button } from 'UIKIT/Button/Button';

import {
  useScheduledCallCreatePopupPresenter,
  IScheduledCallCreatePopupParams,
} from './ScheduledCallSuccessPopup.presenter';
import styles from './ScheduledCallSuccessPopup.styles.m.css';

export const ScheduledCallSuccessPopup: FC<ICommonPopupsProps<IScheduledCallCreatePopupParams>> =
  observer((props) => {
    const { t } = useTranslation();
    const presenter = useScheduledCallCreatePopupPresenter(props);

    return (
      <Popup
        className={styles.popup}
        headerClassName={styles.popupHeader}
        isShowHeader
        isOpened
        onClose={presenter.onClose}
      >
        <div className={styles.container}>
          <h1 className={styles.title}>{t('сall_has_been_scheduled')}</h1>
          <div className={styles.subTitle}>{presenter.timeText}</div>
          <Button
            onClick={presenter.onDetailsClick}
            theme={'1'}
          >
            {t('сall_details')}
          </Button>
        </div>
      </Popup>
    );
  });
