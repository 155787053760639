export default (list, size = 1) => {
  if (!list.length) {
    return [];
  }

  let index = 0;

  const result = [];

  while (index < list.length) {
    result.push(list.slice(index, index + size));
    index += size;
  }

  return result;
};
