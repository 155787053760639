import { openPopup } from 'APP/Tasks/app/popup/popup';
import { MyProfilePopup } from 'MAIN/PopupManager/Popups/MyProfilePopup/MyProfilePopup';
import { UserProfilePopup } from 'MAIN/PopupManager/Popups/UserProfilePopup/UserProfilePopup';
import { IUserProfileInitiatorSource } from 'MAIN/Profile/UserProfile/UserProfile.presenter';
import Entities from 'STORE';

interface IUserProfile {
  show(): void;
}

export function useUserProfile(
  userId?: string,
  initiatorSource?: IUserProfileInitiatorSource
): IUserProfile {
  return {
    // TODO: add close popup on parent component destroy
    show(): void {
      if (userId && userId !== Entities.UsersStore.Me.id) {
        openPopup(UserProfilePopup, { params: { userId, initiatorSource } });
      } else {
        openPopup(MyProfilePopup);
      }
    },
  };
}
