const TIMER_START_DELAY = 200;

export class StorySimpleTimer {
  private requestId: number | null;
  private startDelayId: ReturnType<typeof setTimeout> | null;
  private startTime = 0;
  private pauseTime: number | null = null;
  private duration: number;
  private onTick: (progress: number) => void;

  public init(duration: number, onTick: (progress: number) => void): void {
    this.duration = duration;
    this.onTick = onTick;
  }

  public start(): void {
    this.startTime = Date.now();
    this.startDelayId = setTimeout(() => {
      this.tick();
    }, TIMER_START_DELAY);
  }

  public pause(): void {
    this.stop();
    this.pauseTime = Date.now();
  }

  public resume(): void {
    if (this.pauseTime !== null) {
      this.startTime = Date.now() - (this.pauseTime - this.startTime);
      this.pauseTime = null;
      this.tick();
    }
  }

  public stop(): void {
    if (this.requestId) {
      cancelAnimationFrame(this.requestId);
      this.requestId = null;
    }

    if (this.startDelayId) {
      clearTimeout(this.startDelayId);
      this.startDelayId = null;
    }

    this.pauseTime = null;
  }

  private tick(): void {
    if (this.pauseTime !== null) {
      return;
    }

    const timeDiff = Date.now() - this.startTime;
    const progress = timeDiff > this.duration ? 100 : (timeDiff * 100) / this.duration;

    this.onTick(progress);

    if (progress < 100) {
      this.requestId = requestAnimationFrame(() => this.tick());
    }
  }
}
