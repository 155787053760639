import { useLocalStore } from 'mobx-react';

import Entities from 'APP/store';

interface IKeyWordRendererPresenter {
  isOpened: boolean;
  url: string;
  onOpen(): void;
  onClose(): void;
}

export const useKeyWordRendererPresenter = (type: string): IKeyWordRendererPresenter => {
  const presenter = useLocalStore(() => ({
    isOpened: false,

    get url(): string {
      return Entities.clientConfig.getMessageKeywords(type)?.animationURL || '';
    },

    onOpen(): void {
      presenter.isOpened = true;
    },

    onClose(): void {
      presenter.isOpened = false;
    },
  }));

  return presenter;
};
