import { ISpaceUserRoleResponse } from 'APP/model/space/spaceModel.types';
import api from 'APP/packages/api';
import logger from 'APP/packages/logger';

import { buildModelError } from '../error/errorBuilder';
import { mapGroupResponse } from '../group/groupModel.mapper';
import { ISearchUsersInGroupResponse } from '../group/groupModel.types';
import { mapSpaceResponse, mapSpaceUserRoleResponse } from '../space/spaceModel.mapper';
import { mapUserDisplayDataResponse } from '../user/userModel.mapper';
import {
  IGlobalSearch,
  IGlobalSearchOptions,
  ISearchUsersInGroupRequest,
  ISearchUsersInSpaceRequest,
} from './searchModel.types';

export const globalSearch = async (searchOptions: IGlobalSearchOptions): Promise<IGlobalSearch> => {
  try {
    const globalSearch = await api.search.globalSearch({
      searchOptions: {
        ...searchOptions,
        offset: searchOptions.offset.toString(),
        limit: searchOptions.limit.toString(),
      },
    });
    return {
      groups: globalSearch.groups.map((group) => mapGroupResponse(group)),
      users: globalSearch.users.map((user) => mapUserDisplayDataResponse(user)),
      spaces: globalSearch.spaces.map((space) => mapSpaceResponse(space)),
      hasMoreResults: globalSearch.hasMoreResults,
    };
  } catch (error) {
    logger.get('API').error('search.globalSearch', error);
    throw buildModelError(error);
  }
};

export const searchUserInSpace = async (
  data: ISearchUsersInSpaceRequest
): Promise<ISpaceUserRoleResponse> => {
  try {
    const response = await api.search.searchUserInSpace(data);
    return mapSpaceUserRoleResponse({ cursor: response.cursor, userRoles: response.users });
  } catch (error) {
    logger.get('API').error('search.searchUserInSpace', error);
    throw buildModelError(error);
  }
};

export const searchUserInGroup = async (
  data: ISearchUsersInGroupRequest
): Promise<ISearchUsersInGroupResponse> => {
  try {
    const response = await api.search.searchUserInGroup(data);

    return {
      cursor: response.cursor || null,
      participants: response.users,
    };
  } catch (error) {
    logger.get('API').error('search.searchUserInGroup', error);
    throw buildModelError(error);
  }
};
