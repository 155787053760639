import { showAlert } from 'APP/Tasks/app/app';
import { ClientConfigKeys } from 'APP/constants/users';
import { getClientConfig } from 'APP/model/auth/authModel';
import { t } from 'APP/module/i18n/service';
import logger from 'APP/packages/logger';

interface ITelegramConfig {
  uri: string | null;
  token: string | null;
}
export async function getTelegramConfig(): Promise<ITelegramConfig> {
  try {
    const clientConfig = await getClientConfig();

    const telegramConfig = clientConfig.properties.find(
      ({ key }) => key === ClientConfigKeys.TelegramAuth
    );

    if (!telegramConfig) {
      return { uri: null, token: null };
    }
    const { uri, token } = JSON.parse(telegramConfig.value);
    return { uri, token };
  } catch (error) {
    showAlert(t('common_somethings_wrong_error'));

    logger.get('Tasks').error('login.clientConfig.telegramAuth', error);

    return { uri: null, token: null };
  }
}
