import { AudioSourceType } from 'APP/constants/app';
import Entities from 'APP/store';

export function removeSource(type: AudioSourceType, callback: (() => void) | null = null): void {
  const filteredAudioSources = Entities.App.currentAudioSources.filter(
    (source) => !(source.type === type && (!callback || source.callback === callback))
  );

  Entities.App.setCurrentAudioSources([...filteredAudioSources]);
}
