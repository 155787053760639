import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { openPopup } from 'APP/Tasks/app/popup/popup';
import { MAX_PIN_GROUPS } from 'APP/constants/group';
import { AddParticipantsPopup } from 'APP/main/PopupManager/Popups/AddParticipantsPopup/AddParticipantsPopup';
import { useTranslation } from 'APP/packages/translations';
import Entities from 'APP/store';
import AddUserIcon from 'ICONS/ic-add-user.svg';
import ClearIcon from 'ICONS/ic-clear.svg';
import CloseIcon from 'ICONS/ic-close-32.svg';
import DeleteIcon from 'ICONS/ic-delete.svg';
import EditIcon from 'ICONS/ic-edit.svg';
import LogOutIcon from 'ICONS/ic-log-out.svg';
import PinStrokeIcon from 'ICONS/ic-pin-stroke.svg';
import ReportIcon from 'ICONS/ic-report.svg';
import { EditChannelPopup } from 'MAIN/PopupManager/Popups/EditChannelPopup/EditChannelPopup';
import { EditChatGroupPopup } from 'MAIN/PopupManager/Popups/EditChatGroupPopup/EditChatGroupPopup';
import { ReportGroupPopup } from 'MAIN/PopupManager/Popups/ReportGroupPopup/ReportGroupPopup';
import { ReportUserPopup } from 'MAIN/PopupManager/Popups/ReportUserPopup/ReportUserPopup';
import { Popup } from 'STORE/App/Popups/Popup/Popup';
import { Group } from 'STORE/Groups/Group';
import { MenuItemStyle } from 'UIKIT/MenuItem/MenuItem.types';
import { IPopoverMenuItem } from 'UIKIT/PopoverMenu/PopoverMenu.types';

interface IChatSettingsPresenter {
  childPopup: Popup | null;
  group: Group | null;
  menuItems: IPopoverMenuItem[];
  canPinMoreChats: boolean;
  canPinMoreChannels: boolean;
  canPinChat: boolean;
  canPinBot: boolean;
  canPinChannel: boolean;
  canUnpinChat: boolean;
  canUnpinBot: boolean;
  canUnpinChannel: boolean;
  canEditGroup: boolean;
  canAddMember: boolean;
  canClearHistory: boolean;
  clearHistoryConfirmText: string;
  canReportP2P: boolean;
  canReportGroup: boolean;
  canLeaveP2P: boolean;
  canLeavePrivateGroupOrChannel: boolean;
  leaveGroupConfirmText: string;
  canDeletePrivateGroup: boolean;
  canDeleteChannel: boolean;
  canDeactivateBot: boolean;
  deleteGroupConfirmText: string;
  pinGroup(): void;
  unpinGroup(): void;
  editGroup(): void;
  addMembers(): void;
  clearHistory(): void;
  reportUser(): void;
  reportGroup(): void;
  leaveGroup(): void;
  deleteGroup(): void;
  deactivateBot(): void;
}

export function useChatSettingsPresenter(): IChatSettingsPresenter {
  const { t } = useTranslation();

  const presenter = useLocalStore<IChatSettingsPresenter>(() => ({
    childPopup: null,

    get group(): Group | null {
      return Entities.GroupsStore.activeGroup;
    },

    get menuItems(): IPopoverMenuItem[] {
      if (!presenter.group) {
        return [];
      }

      return [
        {
          key: 'pin-chat',
          isVisible: presenter.canPinChat,
          icon: PinStrokeIcon,
          title: t('group_chat_pin'),
          onClick: presenter.pinGroup,
        },
        {
          key: 'unpin-chat',
          isVisible: presenter.canUnpinChat,
          icon: PinStrokeIcon,
          title: t('group_chat_unpin'),
          onClick: presenter.unpinGroup,
        },
        {
          key: 'pin-bot',
          isVisible: presenter.canPinBot,
          icon: PinStrokeIcon,
          title: t('group_chat_pin'),
          onClick: presenter.pinGroup,
        },
        {
          key: 'unpin-bot',
          isVisible: presenter.canUnpinBot,
          icon: PinStrokeIcon,
          title: t('group_chat_unpin'),
          onClick: presenter.unpinGroup,
        },
        {
          key: 'pin-channel',
          isVisible: presenter.canPinChannel,
          icon: PinStrokeIcon,
          title: t('channel_pin'),
          onClick: presenter.pinGroup,
        },
        {
          key: 'unpin-channel',
          isVisible: presenter.canUnpinChannel,
          icon: PinStrokeIcon,
          title: t('channel_unpin'),
          onClick: presenter.unpinGroup,
        },
        {
          key: 'edit',
          isVisible: presenter.canEditGroup,
          icon: EditIcon,
          title: presenter.group.isChatGroup
            ? t('edit_group_chat_profile_title')
            : t('channel_edit'),
          onClick: presenter.editGroup,
        },
        {
          key: 'add-user',
          isVisible: presenter.canAddMember,
          icon: AddUserIcon,
          title: t('group_chat_add_people_button'),
          onClick: presenter.addMembers,
        },
        {
          key: 'clear',
          isVisible: presenter.canClearHistory,
          icon: ClearIcon,
          title: t('profile_clear_messages'),
          onClick: presenter.clearHistory,
        },
        {
          key: 'report-p2p',
          isVisible: presenter.canReportP2P,
          icon: ReportIcon,
          title: t('common_report'),
          onClick: presenter.reportUser,
        },
        {
          key: 'report-group',
          isVisible: presenter.canReportGroup,
          icon: ReportIcon,
          title: t('common_report'),
          onClick: presenter.reportGroup,
        },
        {
          key: 'leave-p2p',
          isVisible: presenter.canLeaveP2P,
          icon: DeleteIcon,
          title: t('chat_details_delete_chat'),
          onClick: presenter.leaveGroup,
          style: MenuItemStyle.Danger,
        },
        {
          key: 'leave-group',
          isVisible: presenter.canLeavePrivateGroupOrChannel,
          icon: LogOutIcon,
          title: presenter.group.isChatGroup ? t('leave_group_chat') : t('channel_leave'),
          onClick: presenter.leaveGroup,
        },
        {
          key: 'delete_group',
          isVisible: presenter.canDeletePrivateGroup,
          icon: DeleteIcon,
          title: presenter.group.isSavedMessages
            ? t('chat_details_delete_chat')
            : t('delete_group'),
          onClick: presenter.deleteGroup,
          style: MenuItemStyle.Danger,
        },
        {
          key: 'delete-channel',
          isVisible: presenter.canDeleteChannel,
          icon: DeleteIcon,
          title: t('channel_delete'),
          onClick: presenter.deleteGroup,
          style: MenuItemStyle.Danger,
        },
        {
          key: 'deactivate-bot',
          isVisible: presenter.canDeactivateBot,
          icon: CloseIcon,
          title: t('bot_deactivate'),
          onClick: presenter.deactivateBot,
          style: MenuItemStyle.Danger,
        },
      ].filter((item) => item.isVisible);
    },

    get canPinMoreChats(): boolean {
      return Entities.GroupsStore.pinnedChatGroups.length < MAX_PIN_GROUPS;
    },

    get canPinChat(): boolean {
      if (
        !presenter.group ||
        presenter.group.isBot ||
        presenter.group.isChannel ||
        presenter.group.withoutMe
      ) {
        return false;
      }

      return presenter.canPinMoreChats && !presenter.group.isPinned;
    },

    get canPinBot(): boolean {
      if (!presenter.group || !presenter.group.isBot || presenter.group.withoutMe) {
        return false;
      }

      return presenter.canPinMoreChats && !presenter.group.isPinned;
    },

    get canPinMoreChannels(): boolean {
      return Entities.GroupsStore.pinnedChannels.length < MAX_PIN_GROUPS;
    },

    get canPinChannel(): boolean {
      if (!presenter.group || !presenter.group.isChannel || presenter.group.withoutMe) {
        return false;
      }

      return presenter.canPinMoreChannels && !presenter.group.isPinned;
    },

    pinGroup(): void {
      if (presenter.group) {
        Tasks.group.pinGroup({ groupId: presenter.group.id });
      }
    },

    get canUnpinChat(): boolean {
      if (
        !presenter.group ||
        presenter.group.isBot ||
        presenter.group.isChannel ||
        presenter.group.withoutMe
      ) {
        return false;
      }

      return presenter.group.isPinned;
    },

    get canUnpinBot(): boolean {
      if (!presenter.group || !presenter.group.isBot || presenter.group.withoutMe) {
        return false;
      }

      return presenter.group.isPinned;
    },

    get canUnpinChannel(): boolean {
      if (!presenter.group || !presenter.group.isChannel || presenter.group.withoutMe) {
        return false;
      }

      return presenter.group.isPinned;
    },

    unpinGroup(): void {
      if (presenter.group) {
        Tasks.group.unpinGroup({ groupId: presenter.group.id });
      }
    },

    get canEditGroup(): boolean {
      if (!presenter.group) {
        return false;
      }

      return (
        (presenter.group.isChatGroup || presenter.group.isChannel) && presenter.group.withMeInAdmins
      );
    },

    editGroup(): void {
      if (!presenter.group) {
        return;
      }

      if (presenter.group.isChatGroup) {
        presenter.childPopup = openPopup(EditChatGroupPopup, {
          params: { groupId: presenter.group.id },
        });
      } else {
        presenter.childPopup = openPopup(EditChannelPopup, {
          params: { groupId: presenter.group.id },
        });
      }
    },

    get canAddMember(): boolean {
      if (!presenter.group) {
        return false;
      }

      return (
        (presenter.group.isChannel && presenter.group.withMeInAdmins) ||
        presenter.group.canInviteUsers
      );
    },

    addMembers(): void {
      if (!presenter.group) {
        return;
      }

      openPopup(AddParticipantsPopup, { params: { groupId: presenter.group.id } });
    },

    get canClearHistory(): boolean {
      if (!presenter.group || !presenter.group.hasMessages || presenter.group.withoutMe) {
        return false;
      }

      return !presenter.group.isChannel || presenter.group.isOwner;
    },

    get clearHistoryConfirmText(): string {
      if (presenter.group?.isChannel) {
        return t('channel_clear_history_alert');
      }

      if (presenter.group?.isBot) {
        return t('clear_history_alert', { 0: presenter.group.groupOpponent?.displayName });
      }

      if (presenter.group?.isP2P) {
        return t('chat_details_clear_history_message');
      }

      return t('group_chat_clear_history_alert');
    },

    async clearHistory(): Promise<void> {
      if (!presenter.group) {
        return;
      }

      if (presenter.group.isChatGroup && presenter.group.isOwner) {
        const { isConfirmed, isChecked = false } = await Tasks.app.showConfirmWithCheckbox({
          title: t('group_chat_clear_history_alert_headline', { 0: presenter.group.name }),
          text: t('group_chat_clear_history_owner_alert'),
          checkBoxText: t('common_clear_history_for_all_members'),
        });

        if (isConfirmed) {
          Tasks.group.clearHistory({
            groupId: presenter.group!.id,
            isClearForAll: isChecked,
          });
        }
      } else {
        const isConfirmed = await Tasks.app.showConfirm({
          text: presenter.clearHistoryConfirmText,
        });

        if (isConfirmed) {
          Tasks.group.clearHistory({
            groupId: presenter.group!.id,
            isClearForAll: presenter.group!.isChannel && presenter.group!.isOwner,
          });
        }
      }
    },

    get canReportP2P(): boolean {
      if (!presenter.group) {
        return false;
      }

      return presenter.group.isP2P && !presenter.group.isBot;
    },

    reportUser(): void {
      presenter.childPopup = openPopup(ReportUserPopup, {
        params: { userId: presenter.group!.groupOpponent!.id, groupId: presenter.group!.id },
      });
    },

    get canReportGroup(): boolean {
      if (!presenter.group) {
        return false;
      }

      return !presenter.group.isOwner && (presenter.group.isChatGroup || presenter.group.isChannel);
    },

    reportGroup(): void {
      presenter.childPopup = openPopup(ReportGroupPopup, {
        params: { groupId: presenter.group!.id },
      });
    },

    get canLeaveP2P(): boolean {
      if (!presenter.group) {
        return false;
      }

      return presenter.group.isP2P && !presenter.group.isBot;
    },

    get canLeavePrivateGroupOrChannel(): boolean {
      if (!presenter.group || presenter.group.withoutMe) {
        return false;
      }

      return (
        !presenter.group.isOwner &&
        (presenter.group.isChatGroup || presenter.group.isChannel) &&
        presenter.group.usersCount > 1
      );
    },

    get leaveGroupConfirmText(): string {
      if (presenter.group?.isChannel) {
        return t('leave_channel_alert');
      }

      if (presenter.group?.isP2P) {
        return t('delete_chat_alert', { 0: presenter.group.avatarTitle });
      }

      return t('leave_group_chat_alert');
    },

    leaveGroup(): void {
      Tasks.app.showConfirm({
        text: presenter.leaveGroupConfirmText,
        async onSuccess() {
          if (presenter.group) {
            await Tasks.group.leaveGroup({
              groupId: presenter.group.id,
            });
          }
        },
      });
    },

    get canDeletePrivateGroup(): boolean {
      if (!presenter.group) {
        return false;
      }

      return (
        (presenter.group.isChatGroup && presenter.group.isOwner) || presenter.group.isSavedMessages
      );
    },

    get canDeleteChannel(): boolean {
      if (!presenter.group) {
        return false;
      }

      return presenter.group.isChannel && presenter.group.isOwner;
    },

    get deleteGroupConfirmText(): string {
      if (presenter.group?.isChannel) {
        return t('delete_channel_alert');
      }

      if (presenter.group?.isBot) {
        return t('bot_deactivate_alert');
      }

      if (presenter.group?.isP2P) {
        return t('chat_delete_alert');
      }

      if (presenter.group?.isSavedMessages) {
        return t('saved_messages_delete_chat_alert', { 0: presenter.group.name });
      }

      return t('delete_group_chat_alert');
    },

    deleteGroup(): void {
      Tasks.app.showConfirm({
        text: presenter.deleteGroupConfirmText,
        async onSuccess() {
          if (presenter.group) {
            await Tasks.group.deleteGroup({
              groupId: presenter.group.id,
            });
          }
        },
      });
    },

    get canDeactivateBot(): boolean {
      if (!presenter.group) {
        return false;
      }

      return presenter.group.isBot;
    },

    deactivateBot(): void {
      Tasks.app.showConfirm({
        text: presenter.deleteGroupConfirmText,
        async onSuccess() {
          if (presenter.group) {
            const botId = presenter.group.groupOpponent!.id;

            await Tasks.group.deleteGroup({
              groupId: presenter.group.id,
            });

            await Tasks.botsCatalog.removeBotFromProfile(botId);
          }
        },
      });
    },
  }));

  useEffect(() => {
    return () => {
      presenter.childPopup?.close();
    };
  }, []);

  return presenter;
}
