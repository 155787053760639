import Tasks from 'APP/Tasks';

import { GroupsEvent } from '../GroupsEvent';

export class ScheduledMessageWereDeleted extends GroupsEvent {
  async process() {
    const { messageIds = [], groupId } = this.eventPayload;

    if (messageIds?.length) {
      Tasks.messaging.clearScheduledMessages({
        groupId: groupId.toString(),
        messageIds: messageIds.map((id) => id.toString()),
      });
    }
  }
}
