import { useLocalStore } from 'mobx-react';

import Entities from 'STORE';

export default () => {
  const presenter = useLocalStore(() => ({
    get isAllowedPrivateContent() {
      return Entities.AuthStore.isAuthenticated;
    },

    get isShowSkeleton() {
      return !Entities.AuthStore.isFirstAuthDone;
    },
  }));

  return presenter;
};
