import { spaceModel } from 'APP/model/model';
import { ISpaceParticipantsChange } from 'APP/model/space/spaceModel.types';
import { spaceAnalytics } from 'APP/packages/analytics/analytics';
import Entities from 'STORE';

export async function removeParticipants(data: ISpaceParticipantsChange): Promise<boolean> {
  try {
    await spaceModel.removeParticipants(data);

    const space = Entities.spacesStore.getById(data.spaceId);
    space?.updateParticipantsCount(space.participantsCount - 1);

    data.userIds.forEach((userId) => spaceAnalytics.trackSpaceMemberRemoved(data.spaceId, userId));

    return true;
  } catch {
    return false;
  }
}
