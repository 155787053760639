import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, useRef } from 'react';

import ArrowIcon from 'ICONS/ic-up-32.svg';
import { ClickOutside } from 'UIKIT/ClickOutside/ClickOutside';

import { useAllDownloadsPresenter } from './AllDownloads.presenter';
import styles from './AllDownloads.styles.m.css';
import { AllDownloadsIcon } from './AllDownloadsIcon/AllDownloadsIcon';
import { AllDownloadsList } from './AllDownloadsList/AllDownloadsList';
import { AllDownloadsText } from './AllDownloadsText/AllDownloadsText';

export const AllDownloads: FC = observer(() => {
  const containerRef = useRef<HTMLDivElement>(null);
  const presenter = useAllDownloadsPresenter();

  if (!presenter.downloads.length) {
    return null;
  }

  return (
    <ClickOutside
      reference={containerRef.current}
      onClickOutside={presenter.closeList}
    >
      <div
        className={classNames(styles.container, {
          [styles.show]: presenter.isShowPanel,
          [styles.listOpen]: presenter.isListOpen,
        })}
        ref={containerRef}
      >
        <AllDownloadsList
          open={presenter.isListOpen}
          downloads={presenter.downloads}
        />
        <button
          onClick={presenter.toggleListOpen}
          className={classNames(styles.panel, { [styles.listOpen]: presenter.isListOpen })}
        >
          <div className={styles.panelInnerContainer}>
            <AllDownloadsIcon progress={presenter.allDownloadsPercent} />
            <AllDownloadsText
              className={styles.panelText}
              text={presenter.downloadsText}
            />
            <ArrowIcon
              className={classNames(styles.arrow, { [styles.listOpen]: presenter.isListOpen })}
            />
          </div>
        </button>
      </div>
    </ClickOutside>
  );
});
