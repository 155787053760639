import { spaceModel } from 'APP/model/model';
import { ICreateSpaceMarket, ISpaceMarket } from 'APP/model/space/spaceModel.types';
import { spaceAnalytics } from 'APP/packages/analytics/analytics';
import Entities from 'STORE';

export async function createSpaceMarket(data: ICreateSpaceMarket): Promise<ISpaceMarket | null> {
  try {
    const market = await spaceModel.createMarket(data);

    const space = Entities.spacesStore.getById(data.spaceId);
    space?.markets.addMarket(market);
    space?.setMarketsCount(space.marketsCount + 1);

    spaceAnalytics.trackSpaceMarketAdded(data.spaceId, market.id);

    return market;
  } catch {
    return null;
  }
}
