export enum ServerErrorCode {
  HasNoPermissionsToPerformAction = 'E_HAS_NO_PERMISSIONS_TO_PERFORM_ACTION',
  SessionExpired = 'E_SESSION_EXPIRED',
  TokenExpired = 'E_TOKEN_EXPIRED',
  SearchIndexingInProgress = 'E_SEARCH_INDEXING_IN_PROGRESS',

  HasNoPermissionsToCall = 'E_HAS_NO_PERMISSIONS_TO_CALL',
  Unauthorized = 'E_UNAUTHORIZED',
  RecordingIsStartedAlready = 'E_RECORDING_IS_STARTED_ALREADY',
  NoPermissionsToRecordACall = 'E_NO_PERMISSIONS_TO_RECORD_A_CALL',
  ScreenSharingIsRunningByAnotherUser = 'E_SCREEN_SHARING_IS_RUNNING_BY_ANOTHER_USER',
  ChannelClosed = 'E_CHANNEL_CLOSED',
  OnlyWebPlatformSupportedManyBroadcasters = 'E_ONLY_WEB_PLATFORM_SUPPORTED_MANY_BROADCASTERS',
  TooManyInvitedMembersInShortTime = 'E_TOO_MANY_INVITED_MEMBERS_IN_SHORT_TIME',
  MarketUrlNotValid = 'E_MARKET_URL_NOT_VALID',
  CallAlreadyExist = 'E_CALL_ALREADY_EXIST',
  PermissionAlreadySentError = 'E_PERMISSION_REQUEST_ALREADY_SENT',
  HasNoPermissionToRemoveSpaceOwner = 'E_HAS_NO_PERMISSION_TO_REMOVE_SPACE_OWNER',
  UserIsNotSpaceManager = 'E_USER_IS_NOT_SPACE_MANAGER',
  SpaceDoesNotExist = 'E_SPACE_DOES_NOT_EXIST',
  AccountAlreadyLinked = 'E_EXTERNAL_ACCOUNT_IS_ALREADY_LINKED',
  AccountLinkedByAnotherGemAccount = 'E_EXTERNAL_ACCOUNT_IS_LINKED_BY_ANOTHER_GEM_USER',
  AccountAlreadyUnlinked = 'E_EXTERNAL_ACCOUNT_IS_ALREADY_UNLINKED',
  AccountHasNoPermissions = 'E_HAS_NO_PERMISSIONS',
  PhoneHasHighRiskScore = 'E_PHONE_HAS_HIGH_RISK_SCORE',
  AttemptsExceeded = 'E_ATTEMPTS_EXCEEDED',
  OutOfDateAppVersion = 'E_OUT_OF_DATE_APP_VERSION',
  BadData = 'E_BAD_DATA',
  InvalidSignature = 'E_INVALID_SIGNATURE',

  FilterTriggered = 'E_MESSAGE_FILTER_TRIGGERED',
}
