/**
 * Stream API
 * Documentation Stream API v1.0
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CallData { 
    channelId: string;
    callerName: string;
    callerAvatarUrl: string;
    callVisibleDuration: string;
    callType: CallDataCallTypeEnum;
}
export enum CallDataCallTypeEnum {
    P2P = 'P2P',
    Group = 'GROUP',
    Conference = 'CONFERENCE',
    Stream = 'STREAM'
};



