import { computed } from 'mobx';

import { CallType, CallProviderType } from 'APP/model/call/callModel.types';
import Entities from 'STORE';
import { Group } from 'STORE/Groups/Group';
import { User } from 'STORE/Users/User/User';

import { CallVisibleDurationService } from './Services/CallVisibleDurationService/CallVisibleDurationService';
import { callStreamService } from './Services/callStreamService/callStreamService';

interface IIncomingCallConstructorParams {
  groupId: string;
  channelId: string;
  initiatorId: string;
  callVisibleDuration: number;
  callType: CallType;
  callProvider: CallProviderType;
}

export class IncomingCall {
  groupId: string;
  channelId: string;
  initiatorId: string;
  callType: CallType;
  callProvider: CallProviderType;
  callVisibleDuration: number;
  callVisisbleDurationService: CallVisibleDurationService;
  disposeCallStream: () => void;

  constructor({
    groupId,
    channelId,
    initiatorId,
    callVisibleDuration,
    callType,
    callProvider,
  }: IIncomingCallConstructorParams) {
    this.groupId = groupId;
    this.channelId = channelId;
    this.initiatorId = initiatorId;
    this.callType = callType;
    this.callProvider = callProvider;
    this.callVisibleDuration = callVisibleDuration;

    this.callVisisbleDurationService = new CallVisibleDurationService(this);
    this.disposeCallStream = callStreamService(this);
  }

  dispose = (): void => {
    this.callVisisbleDurationService.dispose();
    this.disposeCallStream();
  };

  @computed
  get group(): Group | null {
    return Entities.GroupsStore.getGroupById(this.groupId);
  }

  @computed
  get isGroup(): boolean {
    return !!this.group?.isChatGroup;
  }

  @computed
  get isP2P(): boolean {
    return !!this.group?.isP2P;
  }

  @computed
  get initiatorUser(): User {
    return Entities.UsersStore.getUserById(this.initiatorId);
  }
}
