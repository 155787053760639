export enum PageType {
  Main = 'main',
  Chat = 'chat',
  Channel = 'channel',
  Thread = 'thread',
  Schedule = 'schedule',
  Space = 'space',
  Newsfeed = 'newsfeed',
  Catalog = 'catalog',
  AllPins = 'allPins',
  Hub = 'hub',
  Market = 'market',
}
