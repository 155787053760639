import React from 'react';

import { Button } from 'UIKIT/Button/Button';

import Media from './Media';
import usePresenter from './presenter';
import styles from './styles.m.css';

const Article = ({ message }) => {
  const presenter = usePresenter({ message });

  return (
    <>
      {presenter.media && <Media payload={presenter.media} />}
      <div className={styles.content}>
        <span className={styles.badge} />
        {message.payload.comment}

        <Button
          theme="7"
          className={styles.button}
          rounding="1"
          onClick={presenter.onClick}
        >
          {message.payload.buttonCaption}
        </Button>
      </div>
    </>
  );
};

export default Article;
