import Tasks from 'APP/Tasks';
import { AudioSourceType } from 'APP/constants/app';
import { CallType } from 'APP/model/call/callModel.types';
import Entities from 'APP/store';

interface IStartConferenceCallData {
  groupId: string;
}

export const startConferenceCall = async ({ groupId }: IStartConferenceCallData): Promise<void> => {
  const group = Entities.GroupsStore.getGroupById(groupId);

  if (!group) {
    return;
  }

  const hasConferenceCallPermissions = Tasks.calls.checkConferenceCallPermissions(group.id);
  const isMaxParticipant = Tasks.calls.checkMaxParticipant(group.id);

  if (!hasConferenceCallPermissions || !isMaxParticipant) {
    return;
  }

  const hasAudioPermissions = await Tasks.permissions.hasAudioPermissions();

  if (!hasAudioPermissions) {
    return;
  }

  Tasks.app.audioSource.setCurrentSource(AudioSourceType.Call);

  Tasks.calls.startCall({
    groupId: group.id,
    channelId: null,
    withAudio: !!group?.isP2P,
    withVideo: false,
    callType: CallType.Conference,
  });
};
