import { PayloadType } from 'APP/model/message/messageModel.types';

export const DEFAULT_PAYLOAD = {
  text: '',
  keyWord: null,
  linkPreview: null,
  userMentions: [],
  linkPreviewCancelled: false,
  payloadType: PayloadType.RichText,
};

export const FOCUS_NAMES = {
  CREATOR_PAYLOAD: 'creatorPayload',
  TITLE: 'title',
};

export const ONLY_MEDIA_PAYLOADS = [
  PayloadType.Video,
  PayloadType.Image,
  PayloadType.File,
  PayloadType.AudioMessage,
];

export const MAX_TITLE_LENGTH = 200;
