import classNames from 'classnames';
import React from 'react';

import { IconButton } from 'UIKIT/IconButton';

import { useVideoPlayerVolumePresenter } from './VideoPlayerVolume.presenter';
import styles from './VideoPlayerVolume.styles.m.css';

export const VideoPlayerVolume = ({
  volume,
  isMuted,
  onToggleMute,
  onSetVolume,
  onVolumeSelection,
}) => {
  const { volumeRef, onMouseDown, volumeSelecting, volumeIcon, percentage } =
    useVideoPlayerVolumePresenter({
      volume,
      isMuted,
      onSetVolume,
      onVolumeSelection,
    });

  const volumeClass = classNames(styles.volume, {
    [styles.active]: volumeSelecting,
  });

  return (
    <div className={styles.container}>
      <IconButton
        className={styles.actionButton}
        Icon={volumeIcon.icon}
        size="x-small"
        iconSize="medium"
        theme="19"
        onClick={onToggleMute}
      />

      <div
        className={volumeClass}
        ref={volumeRef}
        onMouseDown={onMouseDown}
        role="progressbar"
        tabIndex={0}
      >
        <div className={styles.volumeBackground}></div>
        <div
          className={styles.volumeBar}
          style={{ width: `${percentage}%` }}
        ></div>
        <div
          className={styles.volumePin}
          style={{
            left: `${percentage}%`,
          }}
        ></div>
      </div>
    </div>
  );
};
