import { openPopup } from 'APP/Tasks/app/popup/popup';
import {
  ConfirmPopup,
  IConfirmPopupParams,
} from 'MAIN/PopupManager/Popups/ConfirmPopup/ConfirmPopup';
import { IConfirmPopupResult } from 'MAIN/PopupManager/Popups/ConfirmPopup/ConfirmPopup.presenter';

export interface IShowConfirmParams
  extends Omit<
    IConfirmPopupParams,
    'isChecked' | 'checkBoxText' | 'onSuccess' | 'hasCloseButton' | 'onClose'
  > {
  onSuccess?(): Promise<void> | void;
}

export type IShowConfirmWithCheckboxParams = Omit<
  IConfirmPopupParams,
  'hasCloseButton' | 'onClose'
>;

export type IShowConfirmWithCheckboxResult = IConfirmPopupResult;

export interface IShowConfirmWithCloseBtnParams
  extends Omit<IConfirmPopupParams, 'isChecked' | 'checkBoxText' | 'onSuccess' | 'hasCloseButton'> {
  onSuccess?(): Promise<void> | void;
}

export function showConfirm(params: IShowConfirmParams): Promise<boolean> {
  return new Promise((resolve) => {
    openPopup(ConfirmPopup, {
      params: {
        ...params,
        onClose: params.onCancel,
      },
      onClose(data: IConfirmPopupResult) {
        resolve(data.isConfirmed);
      },
    });
  });
}

export function showConfirmWithCheckbox(
  params: IShowConfirmWithCheckboxParams
): Promise<IShowConfirmWithCheckboxResult> {
  return new Promise((resolve) => {
    openPopup(ConfirmPopup, {
      params: {
        ...params,
        onClose: params.onCancel,
      },
      onClose(data: IConfirmPopupResult) {
        resolve(data);
      },
    });
  });
}

export function showConfirmWithCloseBtn(params: IShowConfirmWithCloseBtnParams): Promise<boolean> {
  return new Promise((resolve) => {
    openPopup(ConfirmPopup, {
      params: {
        ...params,
        hasCloseButton: true,
      },
      onClose(data: IConfirmPopupResult) {
        resolve(data.isConfirmed);
      },
    });
  });
}
