import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { Avatar } from 'UIKIT/Avatar/Avatar';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';

/* TODO: GEM-19289 Temporarily hiding
 * import { getFormatNumberToString } from 'APP/services/getFormatNumberToString';
 */
import usePresenter from './presenter';
import styles from './styles.m.css';

const Channel = ({ channel }) => {
  const presenter = usePresenter({ channel });

  const subscribeButtonClassName = classNames(styles.subscribeButton, {
    [styles.subscribed]: presenter.isSubscribed,
  });

  return (
    <div className={styles.container}>
      <button
        className={styles.avatar}
        onClick={presenter.onClick}
      >
        <Avatar
          type={AvatarTypes.Square_40}
          title={channel.name}
          url={channel.avatarUrl}
          backgroundColorIndex={presenter.avatarColorIndex}
        />
      </button>

      <div className={styles.info}>
        <button
          className={styles.name}
          onClick={presenter.onClick}
        >
          {channel.name}
        </button>

        {presenter.isViewDescription && <p className={styles.description}>{channel.description}</p>}

        <div className={styles.more}>
          <button
            className={subscribeButtonClassName}
            disabled={presenter.isDisabled}
            onClick={presenter.onSubscribe}
          >
            {presenter.subscribeCaption}
          </button>
          {/* TODO: GEM-19289 Temporarily hiding
           * <div className={styles.counter}>
           *   {getFormatNumberToString(channel.usersCount)}
           * </div>
           */}
        </div>
      </div>
    </div>
  );
};

export default observer(Channel);
