import { observer } from 'mobx-react';
import React from 'react';

import { IconButton } from 'APP/packages/UiKit/IconButton';
import IconBack from 'ICONS/ic-back.svg';
import ViewsIcon from 'ICONS/ic-view-16.svg';
import { Button } from 'UIKIT/Button/Button';

import styles from './styles.m.css';

const Header = ({ presenter }) => {
  return (
    <div className={styles.container}>
      <IconButton
        className={styles.close}
        Icon={IconBack}
        onClick={presenter.onClose}
        size="big"
        iconSize="medium"
        theme="11"
      />
      {presenter.isStreamStarted && (
        <>
          <div className={styles.live}>LIVE</div>
          <Button
            size="0"
            rounding="1"
            theme="12"
            Icon={ViewsIcon}
            className={styles.viewers}
          >
            {presenter.viewersLiveCount}
          </Button>
        </>
      )}
    </div>
  );
};

export default observer(Header);
