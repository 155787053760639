import React, { FC } from 'react';

import GemMascotNotFound from 'APP/images/gem-mascot-not-found.svg';
import { useTranslation } from 'APP/packages/translations';

import styles from './LinkExpired.styles.m.css';

export const LinkExpired: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <img
        className={styles.image}
        alt=""
        src={GemMascotNotFound}
      />
      <h1 className={styles.title}>{t('call_expired_link')}</h1>
      <h2 className={styles.subTitle}>{t('call_expired_link_hint')}</h2>
    </div>
  );
};
