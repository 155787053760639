import { useLocalStore } from 'mobx-react';

import { moderationModel } from 'APP/model/model';
import { ComplaintType, ISpaceComplaint } from 'APP/model/moderation/moderationModel.types';
import { useTranslation } from 'APP/packages/translations';
import Entities from 'APP/store';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';

import { IReportFormData } from '../../components/ReportForm/ReportForm.types';

interface IReportSpacePopupPresenter {
  hasBackButton: boolean;
  onSubmit(formData: IReportFormData): Promise<void>;
  onBack(): void;
  onClose(): void;
}

export interface IReportSpacePopupParams {
  spaceId: string;
}

export function useReportSpacePresenter({
  params,
  popupInstance,
  onClose,
  onBack,
}: ICommonPopupsProps<IReportSpacePopupParams>): IReportSpacePopupPresenter {
  const { spaceId } = params!;
  const { t } = useTranslation();

  const presenter = useLocalStore<IReportSpacePopupPresenter>(() => ({
    get hasBackButton(): boolean {
      return !!onBack;
    },

    async onSubmit(formData: IReportFormData): Promise<void> {
      try {
        await moderationModel.sendComplaint<ISpaceComplaint>({
          ...formData,
          complaint: {
            complaintType: ComplaintType.Space,
            spaceId,
          },
        });

        Entities.toast.show(t('content_moderation_sent_message'));
      } catch {
        Entities.toast.show(t('something_went_wrong'));
      }

      presenter.onClose();
    },

    onClose(): void {
      popupInstance.close();
      onClose?.();
    },

    onBack(): void {
      popupInstance.close();
      onBack?.();
    },
  }));

  return presenter;
}
