import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { GroupType } from 'APP/model/group/groupModel.types';
import { Space } from 'STORE/Spaces/Space';
import { User } from 'STORE/Users/User/User';

import { SearchResultEntity } from '../presenter/SearchResult.types';
import { SearchResultChannel } from './SearchResultChannel/SearchResultChannel';
import { SearchResultGroup } from './SearchResultGroup/SearchResultGroup';
import styles from './SearchResultItem.styles.m.css';
import { SearchResultP2P } from './SearchResultP2P/SearchResultP2P';
import { SearchResultSavedMessages } from './SearchResultSavedMessages/SearchResultSavedMessages';
import { SearchResultSpace } from './SearchResultSpace/SearchResultSpace';
import { SearchResultUser } from './SearchResultUser/SearchResultUser';

interface ISearchResultItemProps {
  entity: SearchResultEntity;
  isLocalResult: boolean;
}

const groupTypeToComponent: Record<
  string,
  | typeof SearchResultP2P
  | typeof SearchResultGroup
  | typeof SearchResultChannel
  | typeof SearchResultSavedMessages
> = {
  [GroupType.P2P]: SearchResultP2P,
  [GroupType.Open]: SearchResultGroup,
  [GroupType.Closed]: SearchResultGroup,
  [GroupType.ChannelOpen]: SearchResultChannel,
  [GroupType.ChannelClosed]: SearchResultChannel,
  [GroupType.MySavedMessages]: SearchResultSavedMessages,
  [GroupType.SavedMessagesFake]: SearchResultSavedMessages,
};

export const SearchResultItem: FC<ISearchResultItemProps> = observer(
  ({ entity, isLocalResult }) => {
    const props = {
      className: styles.groupContainer,
      activeClassName: styles.active,
      isLocalResult,
    };

    if (entity instanceof User) {
      return (
        <SearchResultUser
          entity={entity}
          {...props}
        />
      );
    }

    if (entity instanceof Space) {
      return (
        <SearchResultSpace
          entity={entity}
          {...props}
        />
      );
    }

    const Component = groupTypeToComponent[entity.type] || null;

    if (Component) {
      return (
        <Component
          entity={entity}
          {...props}
        />
      );
    }

    return null;
  }
);
