import classNames from 'classnames';
import React, { FC, memo } from 'react';

import { Portal } from '../Portal/Portal';
import styles from './styles.m.scss';

interface IFullscreenPopupProps {
  className?: string;
}

export const FullscreenPopup: FC<IFullscreenPopupProps> = memo(({ children, className }) => {
  return (
    <Portal>
      <div className={classNames(styles.popup, className)}>{children}</div>
    </Portal>
  );
});
