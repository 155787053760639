import { observer } from 'mobx-react';
import React from 'react';

import { ChatInfo } from './ChatInfo/ChatInfo';
import { DefaultHeaderActions } from './DefaultHeaderActions/DefaultHeaderActions';
import LoadingBar from './LoadingBar';
import styles from './styles.m.css';

const DefaultHeader = ({ group }) => {
  if (!group) {
    return null;
  }

  return (
    <div
      className={styles.container}
      data-test="chat-header"
    >
      <ChatInfo group={group} />
      <DefaultHeaderActions group={group} />
      <LoadingBar />
    </div>
  );
};

export default observer(DefaultHeader);
