import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { useTranslation } from 'APP/packages/translations';
import { Button } from 'UIKIT/Button/Button';

import { useSoftUpdatePresenter } from './SoftUpdate.presenter';
import styles from './styles.m.css';

export const SoftUpdate: FC = observer(() => {
  const presenter = useSoftUpdatePresenter();
  const { t } = useTranslation();

  if (!presenter.hasSoftUpdate) {
    return null;
  }

  return (
    <Button
      theme="1"
      className={styles.container}
      onClick={presenter.onClick}
    >
      {t('common_update_gem4me_action')}
    </Button>
  );
});
