import { useLocalStore, useAsObservableSource } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { RouterState } from 'APP/router/constants';
import Entities from 'APP/store';
import { getFormatNumberToString } from 'UTILS/getFormatNumberToString';

export default ({ message }) => {
  const source = useAsObservableSource({ message });

  const presenter = useLocalStore(() => ({
    get thread() {
      if (source.message.threadId) {
        return Entities.GroupsStore.getGroupById(source.message.threadId);
      }
      return null;
    },

    get canLeaveComment() {
      return source.message.group.canLeaveComment;
    },

    get commentsCount() {
      return getFormatNumberToString(presenter.thread?.counter?.totalMessagesCount || 0);
    },

    onClick: async () => {
      const threadGroup = await Tasks.thread.getOrCreateThread(source.message);
      if (threadGroup) {
        await Tasks.group.setActiveGroup({
          groupId: threadGroup.id,
          state: {
            [RouterState.hasGoBack]: true,
          },
        });
      }
    },
  }));

  return presenter;
};
