import classNames from 'classnames';
import React, { FC, useMemo } from 'react';

import noop from 'UTILS/noop';

import { useVoiceHistogramPresenter } from './VoiceHistogram.presenter';
import styles from './VoiceHistogram.styles.m.css';

const DEFAULT_HEIGHT = 24;

interface IVoiceHistogramProps {
  className?: string;
  histogram: string;
  progress?: number;
  height?: number;
  isMirrored?: boolean;
  onChangeProgress?(progress: number): void;
}

export const VoiceHistogram: FC<IVoiceHistogramProps> = ({
  className,
  histogram,
  progress = 0,
  height = DEFAULT_HEIGHT,
  isMirrored = false,
  onChangeProgress = noop,
}) => {
  const { containerRef, path, width, gradientId, onSVGClick } = useVoiceHistogramPresenter({
    histogram,
    height,
    isMirrored,
    onChangeProgress,
  });

  const svgProgress = useMemo(() => {
    return `${Math.round(100 * progress)}%`;
  }, [progress]);

  return (
    <div
      className={classNames(styles.container, className)}
      ref={containerRef}
    >
      <svg
        className={styles.bars}
        viewBox={`0 0 ${width} ${height}`}
        onClick={onSVGClick}
      >
        <linearGradient id={gradientId}>
          <stop
            className={styles.barProgressColor}
            offset="0%"
          />
          <stop
            className={styles.barProgressColor}
            offset={svgProgress}
          />
          <stop
            className={styles.barColor}
            offset={svgProgress}
          />
          <stop
            className={styles.barColor}
            offset="100%"
          />
        </linearGradient>

        <path
          d={path}
          fill={`url(#${gradientId})`}
        />
      </svg>
    </div>
  );
};
