import { PayloadType } from 'APP/model/message/messageModel.types';
import { previewBuilder } from 'APP/packages/file-upload/previewBuilder';
import { readFileToBlob } from 'APP/packages/file-upload/uploader';
import logger from 'APP/packages/logger';
import { getAudioFileDuration } from 'UTILS/audio/getAudioFileDuration';

import { IMediaMessagePayload } from './MediaMessagePopup.types';

const MAX_SIZE_TO_BLOB = 100 * 1024 * 1024; //100MB

export function isMediaFile(fileType: string): boolean {
  return (
    ['video', 'image', 'audio'].includes(fileType.split('/')[0]) && fileType !== 'image/svg+xml'
  );
}

export function isMediaPayload(payload: IMediaMessagePayload): boolean {
  return [PayloadType.Video, PayloadType.Image].includes(payload.type);
}

async function getAudioPayload(file: File | Blob, fileName: string): Promise<IMediaMessagePayload> {
  try {
    const duration = await getAudioFileDuration(file);

    return {
      type: PayloadType.AudioMessage,
      data: {
        file,
        fileName,
        duration,
      },
    };
  } catch (error) {
    logger.get('MediaMessage').warn(`\`unsupported audio file: ${fileName}`, error);
    return {
      type: PayloadType.AudioMessage,
      data: { file, fileName },
    };
  }
}

async function getVideoPayload(file: File | Blob, fileName: string): Promise<IMediaMessagePayload> {
  try {
    const { thumbnail, width, height, duration } =
      await previewBuilder.getFirstVideoFrameAndDuration(file);

    return {
      type: PayloadType.Video,
      data: {
        file,
        fileName,
        duration,
        graphicSize: { width, height },
        localPreview: thumbnail,
      },
    };
  } catch (error) {
    logger.get('MediaMessage').warn(`unsupported video file: ${fileName}`, error);
    return {
      type: PayloadType.File,
      data: { file, fileName },
    };
  }
}

async function getImagePayload(file: File | Blob, fileName: string): Promise<IMediaMessagePayload> {
  return new Promise((resolve) => {
    const img = new Image();

    img.onload = (): void => {
      resolve({
        type: PayloadType.Image,
        data: {
          file,
          fileName,
          graphicSize: { width: img.width, height: img.height },
          localPreview: img.src,
        },
      });
    };

    img.onerror = (error): void => {
      logger.get('MediaMessage').warn(`unsupported image file: ${fileName}`, error);
      URL.revokeObjectURL(img.src);
      resolve({
        type: PayloadType.File,
        data: { file, fileName },
      });
    };

    img.src = URL.createObjectURL(file);
  });
}

export async function getFilePayload(
  file: File | Blob,
  fileName: string,
  isSendAsFile?: boolean
): Promise<IMediaMessagePayload> {
  const fileType = file.type;
  let _file: File | Blob = file;

  if (file.size <= MAX_SIZE_TO_BLOB && file instanceof File) {
    _file = await readFileToBlob(file);
  }

  if (!isMediaFile(fileType) || isSendAsFile) {
    const type = fileType.includes('audio') ? PayloadType.AudioMessage : PayloadType.File;
    return { type, data: { file: _file, fileName } };
  }

  switch (0) {
    case fileType.indexOf('video'):
      return await getVideoPayload(_file, fileName);
    case fileType.indexOf('audio'):
      return await getAudioPayload(_file, fileName);
    default:
      return await getImagePayload(_file, fileName);
  }
}
