import { useEffect, useState } from 'react';

import { ChatBackgroundPattern } from 'APP/constants/appearance';
import Entities from 'STORE';

import styles from '../ChatBackground.styles.m.css';

const PREFIX = 'chat-background';

export function getChatBackgroundClassName(pattern: ChatBackgroundPattern): string {
  return `${PREFIX}-${pattern}`;
}

export function useChatBackgroundClassName(): string {
  const [className, setClassName] = useState(styles.withoutPattern);

  useEffect(() => {
    setClassName(styles[getChatBackgroundClassName(Entities.appearance.chatBackground)]);
  }, [Entities.appearance.chatBackground]);

  return className;
}
