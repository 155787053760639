import { localStorageInstance } from '../../instances/localStorageInstance';

export const SKIP_RESET_STORAGE_NAME = 'feedbackSkipCounter';
export const DONT_SHOW_STORAGE_NAME = 'isFeedbackDontShow';
export const SKIP_RESET_COUNTER = 3;

export const SKIP_START_COUNTER = 1;

export const setDontShowValue = (value: boolean): void => {
  localStorageInstance.setItem(DONT_SHOW_STORAGE_NAME, JSON.stringify(value));
};

export const getDontShowValue = (): boolean | null => {
  const value = localStorageInstance.getItem(DONT_SHOW_STORAGE_NAME);

  try {
    return value ? JSON.parse(value) : false;
  } catch {
    return false;
  }
};

export const getSkipValue = (): number | undefined => {
  const value = localStorageInstance.getItem(SKIP_RESET_STORAGE_NAME);

  return value ? Number(value) : undefined;
};

export const setStartSkipValue = (): void => {
  localStorageInstance.setItem(SKIP_RESET_STORAGE_NAME, String(SKIP_START_COUNTER));
};

export const resetSkipValue = (): void => {
  localStorageInstance.setItem(SKIP_RESET_STORAGE_NAME, '');
};

export const increaseSkipValue = (): void => {
  const value = getSkipValue();

  if (!value) {
    return;
  }

  const increasedCounter = value + 1;
  localStorageInstance.setItem(
    SKIP_RESET_STORAGE_NAME,
    increasedCounter >= SKIP_RESET_COUNTER ? '' : String(increasedCounter)
  );
};
