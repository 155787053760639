import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { IButtonPayloadModel } from 'STORE/Messages/Message/Payload/Buttons/Buttons';

import { CommandButton } from '../CommandButton/CommandButton';
import { useDefaultCommandButtonPresenter } from './DefaultCommandButton.presenter';

interface IDefaultBubbleButtonProps {
  groupId: string;
  button: IButtonPayloadModel;
}

export const DefaultCommandButton: FC<IDefaultBubbleButtonProps> = observer((props) => {
  const { button, groupId } = props;

  const presenter = useDefaultCommandButtonPresenter({ groupId, button });

  return (
    <CommandButton
      onClick={presenter.onClick}
      title={button.title}
    />
  );
});
