import React from 'react';

import { SpaceType } from 'APP/model/space/spaceModel.types';
import DeepLinkAliasField from 'APP/packages/form/DeepLinkAliasField';
import { useTranslation } from 'APP/packages/translations';

export const SpaceDeepLinkAliasField = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <DeepLinkAliasField
      title={t('space_permanent_link')}
      description={t('space_deeplink_prompt')}
      placeholder={t('common_link')}
      validatingText={t('checking_deeplink')}
      successText={t('space_link_is_available')}
      // TODO: remove any after refactor DeepLinkAliasField on TypeScript
      isShowLink={(values: any) => values.type === SpaceType.Public}
      {...props}
    ></DeepLinkAliasField>
  );
};
