import { action, computed, observable } from 'mobx';

import {
  IScheduledEventDetails,
  IScheduledEventInfo,
  IScheduledEventsList,
} from 'APP/model/scheduledEvent/scheduledEventModel.types';

export class ScheduledEvents {
  @observable cursor: string | undefined;
  @observable data = new Map<string, IScheduledEventInfo>();
  @observable detailsData = new Map<string, IScheduledEventDetails>();

  @action reset(): void {
    this.cursor = undefined;
    this.data.clear();
    this.detailsData.clear();
  }

  @action clearPartialStore(firstDataPartLength: number): void {
    if (this.data.size <= firstDataPartLength) {
      return;
    }
    const newDataMap = new Map<string, IScheduledEventInfo>();
    Array.from(this.data.values()).forEach((item, index) => {
      if (index < firstDataPartLength) {
        newDataMap.set(item.id, item);
      }
    });

    this.data = newDataMap;
  }

  @computed
  get scheduledEvents(): IScheduledEventInfo[] {
    return Array.from(this.data.values());
  }

  getScheduledEventById = (id: string): IScheduledEventInfo | null => {
    return this.data.get(id) || null;
  };

  getScheduledEventDetailsById = (id: string): IScheduledEventDetails | null => {
    return this.detailsData.get(id) || null;
  };

  @action
  setScheduledEvents = (data: IScheduledEventsList): void => {
    this.cursor = data.cursor;
    data.events.forEach((item) => {
      this.data.set(item.id, item);
    });
  };

  @action
  addScheduledEvent = (event: IScheduledEventInfo): void => {
    this.data.set(event.id, event);
  };

  @action
  addScheduledEventDetails = (event: IScheduledEventDetails): void => {
    this.detailsData.set(event.id, event);
  };

  @action
  removeScheduledEvent = (id: string): void => {
    if (this.data.has(id)) {
      this.data.delete(id);
    }
    if (this.detailsData.has(id)) {
      this.detailsData.delete(id);
    }
  };
}
