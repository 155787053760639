import Entities from 'APP/store';
import { Payload } from 'STORE/Messages/Message/Payload';

class System extends Payload {
  initiator;
  initiatorNickName;
  contactId;
  users;
  groupName;
  systemMessagesEnabled;
  spaceName;

  constructor(data) {
    super(data);

    this.initiator = data.initiator;
    this.initiatorNickName = data.initiatorNickName;
    this.contactId = data.contactId;
    this.users = data.users;
    this.groupName = data.groupName;
    this.oldName = data.oldName;
    this.newName = data.newName;
    this.newType = data.newType;
    this.systemMessagesEnabled = data.systemMessagesEnabled;
    this.spaceName = data.spaceName;
  }

  toJSON() {
    return {
      initiator: this.initiator,
      initiatorNickName: this.initiatorNickName,
      contactId: this.contactId,
      users: this.users,
      groupName: this.groupName,
      oldName: this.oldName,
      newName: this.newName,
      newType: this.newType,
      systemMessagesEnabled: this.systemMessagesEnabled,
      spaceName: this.spaceName,
    };
  }

  get isSystem() {
    return true;
  }

  get canBeDelete() {
    return Entities.GroupsStore.getGroupById(this.message.groupId).withMeInAdmins;
  }
}

export default System;
