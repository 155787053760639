import { useEffect, useRef } from 'react';

import { ElectronApi } from 'APP/packages/electron/ElectronApi';
import Entities from 'APP/store';

const FAVICON_DEFAULT = 'favicon.ico';
const FAVICON_NOTIFY = 'favicon-notify.ico';

function setBadge(count: number): void {
  if ('setAppBadge' in navigator && 'clearAppBadge' in navigator) {
    if (count > 0) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      navigator.setAppBadge(count);
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      navigator.clearAppBadge();
    }
  }
}

function setFavicon(faviconElement: HTMLLinkElement, count: number): void {
  const isDefaultFavicon = faviconElement.href.indexOf(FAVICON_DEFAULT) !== -1;
  let newIcon: string | null = null;

  if (count > 0 && isDefaultFavicon) {
    newIcon = FAVICON_NOTIFY;
  } else if (count <= 0 && !isDefaultFavicon) {
    newIcon = FAVICON_DEFAULT;
  }

  if (newIcon) {
    faviconElement.setAttribute('href', `${window.location.origin}/${newIcon}`);
  }
}

export function useBadgeHandlerPresenter(): void {
  const faviconRef = useRef(document.getElementById('favicon'));

  useEffect(() => {
    const count =
      Entities.GroupsStore.newGroupMessagesWithoutMuteCount +
      Entities.GroupsStore.newChannelMessagesWithoutMuteCount;

    if (ElectronApi.isEnabled) {
      ElectronApi.setBadge(count > 0 ? count : 0);
      return;
    }

    setBadge(count);
    if (faviconRef.current) {
      setFavicon(faviconRef.current as HTMLLinkElement, count);
    }
  }, [
    Entities.GroupsStore.newGroupMessagesWithoutMuteCount,
    Entities.GroupsStore.newChannelMessagesWithoutMuteCount,
  ]);

  useEffect(() => {
    return () => {
      if (ElectronApi.isEnabled) {
        ElectronApi.setBadge(0);
        return;
      }

      setBadge(0);
      if (faviconRef.current) {
        faviconRef.current && setFavicon(faviconRef.current as HTMLLinkElement, 0);
      }
    };
  }, []);
}
