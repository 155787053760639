import { useTranslation } from 'APP/packages/translations';

import {
  spaceCollectionNameAvatar,
  SPACE_COLLECTION_AVATAR_ERRORS,
} from '../fields/spaceCollectionAvatar';
import {
  spaceCollectionNameValidate,
  SPACE_COLLECTION_NAME_ERRORS,
} from '../fields/spaceCollectionName';
import syncValidation from '../hooks/syncValidation';

export function useCreateSpaceCollectionValidation() {
  const { t } = useTranslation();

  const avatarErrorToText = {
    [SPACE_COLLECTION_AVATAR_ERRORS.IS_EMPTY]: ' ',
  };

  const nameErrorToText = {
    [SPACE_COLLECTION_NAME_ERRORS.IS_EMPTY]: ' ',
    [SPACE_COLLECTION_NAME_ERRORS.TOO_SHORT]: t('username_min_length_error'),
  };

  const avatarValidator = syncValidation();
  const nameValidator = syncValidation();

  return {
    avatar: {
      validate: (value, formData, meta) => {
        return avatarValidator({
          value,
          meta,
          validate: spaceCollectionNameAvatar,
          errorToText: avatarErrorToText,
        });
      },
    },
    name: {
      validate: (value, formData, meta) => {
        return nameValidator({
          value,
          meta,
          validate: spaceCollectionNameValidate,
          errorToText: nameErrorToText,
        });
      },
    },
  };
}
