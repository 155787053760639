import { IMarket } from 'APP/model/market/marketModel.types';
import { CoinfideUrlResponse } from 'APP/packages/api/types/model/coinfideUrlResponse';

export const mapCoinfideUrlResponse = (data: CoinfideUrlResponse): IMarket => {
  return {
    authToken: data.authToken,
    baseAdBoardUrl: data.baseAdboardUrl,
    baseAdsUrl: data.baseAdsUrl,
    baseFreelanceUrl: data.baseFreelanceUrl,
    baseGamesUrl: data.baseGamesUrl,
    baseMarketUrl: data.baseMarketUrl,
    baseMarketingUrl: data.baseMarketingUrl,
    basePassportUrl: data.basePassportUrl,
    authHosts: data.authHosts,
  };
};
