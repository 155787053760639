import classNames from 'classnames';
import React, { memo, ReactElement } from 'react';

import { IconView, IconViewSizes } from 'APP/packages/UiKit/IconView/IconView';
import LockIcon from 'ICONS/ic-lock-16.svg';
import getAvatarUrlByType from 'UTILS/getAvatarUrlByType';

import { AsyncImage } from '../AsyncImage/AsyncImage';
import styles from './Avatar.styles.m.css';
import { AvatarTypes, TAvatarBackgroundColorIndex } from './Avatar.types';

export * from './Avatar.types';

interface IAvatarProps {
  className?: string;
  avatarClassName?: string;
  url?: string | null;
  title?: string;
  backgroundColorIndex?: TAvatarBackgroundColorIndex;
  type: AvatarTypes;
  isOnline?: boolean;
  hasLockIcon?: boolean;
  children?: ReactElement;
}

export const Avatar = memo<IAvatarProps>((props) => {
  const {
    className,
    avatarClassName,
    url,
    title,
    backgroundColorIndex = '0',
    children,
    type,
    isOnline = false,
    hasLockIcon,
  } = props;
  // way to extract emoji or letter from string
  const trimmedTitle = Array.from((title || '').trim())
    .slice(0, 1)
    .join('');

  const containerClasses = classNames(styles.container, styles[type], className);
  const avatarClasses = classNames(styles.avatar, avatarClassName);
  const defaultAvatarClasses = classNames(
    styles.defaultAvatar,
    styles[`background-${backgroundColorIndex}`],
    avatarClassName
  );

  const defaultContent = children || (
    <div
      className={defaultAvatarClasses}
      data-test="default-avatar"
    >
      {trimmedTitle}
    </div>
  );

  if (!url) {
    return (
      <div className={containerClasses}>
        {defaultContent}
        {isOnline && <div className={styles.online} />}
        {hasLockIcon && (
          <div className={styles.lock}>
            <IconView
              className={styles.lockIcon}
              Icon={LockIcon}
              size={IconViewSizes.Small}
            />
          </div>
        )}
      </div>
    );
  }

  const resizedUrl = getAvatarUrlByType(type, url);

  return (
    <div className={containerClasses}>
      <AsyncImage
        className={avatarClasses}
        src={resizedUrl}
        alt=""
        dataTest="avatar"
      >
        {defaultContent}
      </AsyncImage>
      {isOnline && <div className={styles.online} />}
      {hasLockIcon && (
        <div className={styles.lock}>
          <IconView
            className={styles.lockIcon}
            Icon={LockIcon}
            size={IconViewSizes.Small}
          />
        </div>
      )}
    </div>
  );
});
