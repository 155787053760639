import { useRef } from 'react';

import delayedCallback from '../utils/delayedCallback';

function runValidations({
  value,
  values,
  validate,
  asyncValidate,
  errorToText,
  asyncTimeout,
  data,
  meta,
}) {
  const error = validate({
    value,
    values,
    errorToText,
    data,
  });

  if (error) {
    return error;
  }

  // we don't need to do async validation
  // if value is equal to init value
  if (meta.pristine) {
    return null;
  }

  return delayedCallback(asyncTimeout, () => {
    return asyncValidate({
      value,
      values,
      errorToText,
      data,
    });
  });
}

export default () => {
  const timeout = useRef();
  const asyncTimeout = useRef();

  return ({ value, values, validate, asyncValidate, errorToText, data, meta }) => {
    // we need to async validate at the first time typing
    return delayedCallback(timeout, () => {
      return runValidations({
        value,
        values,
        validate,
        asyncValidate,
        errorToText,
        asyncTimeout,
        data,
        meta,
      });
    });
  };
};
