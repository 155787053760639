import * as amplitude from '@amplitude/analytics-browser';

import {
  IAnalyticsAdapter,
  IAnalyticsEvent,
  IAnalyticsOptions,
  IAnalyticsUserConfig,
} from '../AnalyticsClient.types';

export class AmplitudeAdapter implements IAnalyticsAdapter {
  private readonly isInitialized: boolean = false;

  constructor(amplitudeApiKey?: string) {
    if (amplitudeApiKey) {
      amplitude.init(amplitudeApiKey, {
        defaultTracking: {
          fileDownloads: false,
          pageViews: false,
          formInteractions: false,
        },
      });

      this.isInitialized = true;
    }
  }

  public setUserConfig(user: IAnalyticsUserConfig): void {
    if (this.isInitialized) {
      amplitude.setUserId(user.id);

      const identifyEvent = new amplitude.Identify();
      user.userName && identifyEvent.set('username', user.userName);
      user.nickName && identifyEvent.set('nickname', user.nickName);

      amplitude.identify(identifyEvent);
    }
  }

  public track(event: IAnalyticsEvent, options: IAnalyticsOptions): void {
    if (this.isInitialized) {
      amplitude.track({
        event_type: event.eventType,
        event_properties: event.eventProperties || {},
      });
    }
  }
}
