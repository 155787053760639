import Tasks from 'APP/Tasks';
import { updateGroupAndMessageData } from 'APP/Tasks/newsfeed/loadNewsfeed/loadNewsfeed';
import { INewsItem, NewsType } from 'APP/model/catalog/catalogModel.types';
import { GroupUserRole } from 'APP/model/group/groupModel.types';
import { IMessage } from 'APP/model/message/messageModel.types';
import { spaceModel } from 'APP/model/model';
import { ISpaceCollectionRequest } from 'APP/model/space/spaceModel.types';

interface ICollectionItem extends INewsItem {
  id: string;
}

interface ISpaceCollectionItemsData {
  readonly items: ICollectionItem[];
  readonly cursor: string | null;
}

const DEFAULT_LOAD_LIMIT = 20;

export async function getSpaceCollectionItems(
  data: ISpaceCollectionRequest
): Promise<ISpaceCollectionItemsData | null> {
  try {
    const items: ICollectionItem[] = [];
    const messages: IMessage[] = [];

    const response = await spaceModel.getCollectionItems({
      ...data,
      limit: data.limit || DEFAULT_LOAD_LIMIT,
    });

    response.items.forEach((item) => {
      updateGroupAndMessageData(item.group, item.message);
      messages.push(item.message.message);
      items.push({
        ...item,
        elementType:
          item.group.userGroupRole === GroupUserRole.Guest ? NewsType.GuestPost : NewsType.Post,
      });
    });

    if (messages.length > 0) {
      await Tasks.relationships.loadUnknownDataFromMessages({ messages });
    }

    return {
      items,
      cursor: response.cursor || null,
    };
  } catch {
    return null;
  }
}
