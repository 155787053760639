import classNames from 'classnames';
import React, { FC } from 'react';

import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

import { ForwardMessage } from '../ForwardMessage/ForwardMessage';
import { QuotedMessage } from '../QuotedMessage/QuotedMessage';
import { SenderName } from '../SenderName/SenderName';
import styles from './BubbleHeader.styles.m.css';

interface IBubbleHeaderProps {
  className?: string;
  message: ChatMessage;
  isShowForward: boolean;
}

export const BubbleHeader: FC<IBubbleHeaderProps> = (props) => {
  const { className, message, isShowForward } = props;

  const senderNameClasses = classNames(styles.senderName, {
    [styles.wideGap]: message.quotedMessage || isShowForward,
    [styles.dynamicLength]: !message.quotedMessage && !isShowForward,
  });

  return (
    <div className={classNames(styles.header, className)}>
      <SenderName
        message={message}
        className={senderNameClasses}
      />
      {message.quotedMessage && <QuotedMessage message={message.quotedMessage} />}
      {isShowForward && (
        <ForwardMessage
          className={styles.forward}
          message={message}
        />
      )}
    </div>
  );
};
