import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { FormSpy } from 'react-final-form';

import { Field } from 'APP/packages/form/Field/Field';
import { Form } from 'APP/packages/form/Form/Form';
import { IFormContentProps } from 'APP/packages/form/Form/Form.types';
import { TextField } from 'APP/packages/form/TextField/TextField';
import { useTranslation } from 'APP/packages/translations';
import { Button } from 'UIKIT/Button/Button';
import { ButtonTypes } from 'UIKIT/Button/Button.types';

import { useNameBlockPresenter } from './NameBlock.presenter';
import styles from './NameBlock.styles.m.css';

export const NameBlock: FC = observer(() => {
  const { t } = useTranslation();
  const presenter = useNameBlockPresenter();

  return (
    <div className={styles.container}>
      <h6 className={styles.subTitle}>{t('username_screen_question')}</h6>

      <Form
        onSubmit={presenter.onSubmit}
        initialValue={presenter.initialValue}
      >
        {(props: IFormContentProps): React.ReactElement => (
          <form
            className={styles.form}
            onSubmit={props.handleSubmit}
          >
            <div className={styles.content}>
              <Field
                className={styles.username}
                isNeedTrim
                name="userName"
                label={t('profile_username_hint')}
                component={TextField}
                maxLength={50}
                setRef={presenter.userNameRef}
                validate={presenter.usernameValidate}
              />
            </div>
            <div className={styles.footer}>
              <FormSpy
                subscription={{
                  pristine: true,
                  invalid: true,
                  validating: true,
                }}
              >
                {({ pristine, invalid, validating }): React.ReactElement => (
                  <Button
                    size="20"
                    type={ButtonTypes.Submit}
                    theme="1"
                    isLoading={presenter.loading}
                    isDisabled={pristine || invalid || validating}
                  >
                    {t('done')}
                  </Button>
                )}
              </FormSpy>
            </div>
          </form>
        )}
      </Form>
    </div>
  );
});
