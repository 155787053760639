import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { IconView, IconViewSizes } from 'APP/packages/UiKit/IconView/IconView';
import { useTranslation } from 'APP/packages/translations';
import DeleteIcon from 'ICONS/ic-delete-16.svg';

import { IPayloadProps } from '../Payloads.types';
import { MiniatureMessageLayout } from '../components/MiniatureMessageLayout/MiniatureMessageLayout';
import styles from './DeletedByMePayload.styles.m.scss';

export const DeletedByMePayload: FC<IPayloadProps> = observer((props) => {
  const { titleClassName, textClassName, iconClassName, message, title, onClick } = props;
  const { t } = useTranslation();

  return (
    <MiniatureMessageLayout
      titleClassName={titleClassName}
      title={title}
      messageId={message.id}
      onClick={onClick}
    >
      <div className={classNames(styles.text, textClassName)}>
        <IconView
          Icon={DeleteIcon}
          className={classNames(styles.icon, iconClassName)}
          size={IconViewSizes.Small}
        />
        {t('common_message_deleted')}
      </div>
    </MiniatureMessageLayout>
  );
});
