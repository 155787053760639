import Tasks from 'APP/Tasks';
import { showConfirm, showConfirmWithCheckbox } from 'APP/Tasks/app/app';
import { openPopup } from 'APP/Tasks/app/popup/popup';
import { SharedDataType } from 'APP/constants/sharedData';
import { IDeleteMessagesMode, PayloadType } from 'APP/model/message/messageModel.types';
import { useTranslation } from 'APP/packages/translations';
import Entities from 'APP/store';
import DownloadIcon from 'ICONS/ic-download.svg';
import PreviewIcon from 'ICONS/ic-preview.svg';
import DeleteIcon from 'ICONS/ic-s-delete.svg';
import ForwardIcon from 'ICONS/ic-s-forward.svg';
import { ForwardMessagePopup } from 'MAIN/PopupManager/Popups/ForwardMessagePopup/ForwardMessagePopup';

const downloadableSharedTypes = [
  SharedDataType.Media,
  SharedDataType.Files,
  SharedDataType.Audio,
  SharedDataType.Voices,
];

export const useContextMenuPresenter = ({ message, itemId, sharedDataType }) => {
  const { t } = useTranslation();

  const focusMessage = async () => {
    Tasks.messaging.focusMessage({
      groupId: message.group.id,
      messageId: message.id,
    });
    if (message.group.id !== Entities.GroupsStore.activeGroupId) {
      await Tasks.group.setActiveGroup({ groupId: message.group.id });
    }
  };

  const deleteMessage = async () => {
    let canDelete = false;
    let deleteMode = IDeleteMessagesMode.All;

    if (message.canBeDelete && message.canBeDeleteForMe) {
      const { isConfirmed, isChecked } = await showConfirmWithCheckbox({
        text: t('delete_message'),
        checkBoxText: t('delete_for_me'),
      });

      canDelete = isConfirmed;
      deleteMode = isChecked ? IDeleteMessagesMode.Me : IDeleteMessagesMode.All;
    }

    if (message.canBeDelete && !message.canBeDeleteForMe) {
      canDelete = await showConfirm({ text: t('delete_message') });
    }

    if (!message.canBeDelete && message.canBeDeleteForMe) {
      canDelete = await showConfirm({ text: t('common_delete_for_me_alert') });
      deleteMode = IDeleteMessagesMode.Me;
    }

    if (!canDelete || !message.group) {
      return;
    }

    message.delete(deleteMode);
  };

  const startForward = () => {
    openPopup(ForwardMessagePopup, {
      params: { groupId: message.group.id, messageIds: [message.id] },
    });
  };

  const isLinkDataTypeFromArticle = () => {
    return (
      sharedDataType === SharedDataType.Links && message.payload.payloadType === PayloadType.Article
    );
  };

  const isMessageCanBeForwarded = (message) => {
    return message.canBeForward && message.clientUuid === itemId && !isLinkDataTypeFromArticle();
  };

  const isMessageCanBeDeleted = (message) => {
    return (
      (message.canBeDelete || message.canBeDeleteForMe) &&
      message.clientUuid === itemId &&
      !isLinkDataTypeFromArticle()
    );
  };

  const onDownload = () => {
    Tasks.downloadMessageFile({
      payloadId: itemId,
      groupId: message.groupId,
      messageId: message.id,
    });
  };

  const menuItems = [
    {
      key: 'focusMessage',
      isVisible: true,
      title: t('profile_media_show_message'),
      onClick: focusMessage,
      icon: PreviewIcon,
    },
    {
      key: 'forward',
      isVisible: isMessageCanBeForwarded(message),
      title: t('message_forward'),
      onClick: startForward,
      icon: ForwardIcon,
    },
    {
      key: 'download',
      isVisible: downloadableSharedTypes.includes(sharedDataType),
      title: t('common_download'),
      onClick: onDownload,
      icon: DownloadIcon,
    },
    {
      key: 'delete',
      isVisible: isMessageCanBeDeleted(message),
      title: t('chat_list_delete_single_delete_button'),
      onClick: deleteMessage,
      icon: DeleteIcon,
      style: 'danger',
    },
  ];

  return {
    menuItems: menuItems.filter((item) => item.isVisible),
  };
};
