import { IClientConfigProperty } from 'APP/model/auth/authModel.types';
import logger from 'APP/packages/logger';
import Entities from 'APP/store';

export enum TelegramConfig {
  Uri = 'telegram_uri',
  Token = 'telegram_token',
}

export function handleTelegramConfig(property: IClientConfigProperty): void {
  try {
    const { uri, token } = JSON.parse(property.value);

    Entities.clientConfig.setConfig(TelegramConfig.Token, token);
    Entities.clientConfig.setConfig(TelegramConfig.Uri, uri);
  } catch (error) {
    logger.get('Tasks').error('login.clientConfig.telegram', error);
  }
}
