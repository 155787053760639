import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { MediaPreview } from 'APP/components/MediaPreview/MediaPreview';
import { TextRenderer } from 'APP/components/TextRenderer/TextRenderer';
import { ARTICLE_PREVIEW_MAX_SIZE } from 'APP/constants/messages';
import { useTranslation } from 'APP/packages/translations';
import { Button } from 'UIKIT/Button/Button';

import { usePresenter } from './presenter';
import styles from './styles.m.css';

const Article = ({ message, isPreview, isView }) => {
  const { t } = useTranslation();
  const presenter = usePresenter({ message });

  const previewClassName = classNames(styles.preview, {
    [styles.myPreview]: presenter.isFromMe,
  });

  return (
    <>
      {presenter.media && (
        <MediaPreview
          className={previewClassName}
          payload={presenter.media.payload}
          maxWidth={ARTICLE_PREVIEW_MAX_SIZE.width}
          maxHeight={ARTICLE_PREVIEW_MAX_SIZE.height}
          isPreview={isPreview}
          isView={isView}
          onClick={presenter.openPreview}
          groupId={message.groupId}
          messageId={message.id}
          payloadId={message.id}
        />
      )}

      <div className={styles.content}>
        <button
          className={styles.clickable}
          onClick={presenter.openPreview}
        >
          <TextRenderer
            className={styles.title}
            isPlainText
          >
            {presenter.title || t('channels:channel_article_untitled_article')}
          </TextRenderer>
        </button>
        {presenter.text && (
          <TextRenderer
            className={styles.text}
            keyWords={presenter.text.payload.keyWords}
            userMentions={presenter.text.payload.userMentions}
            groupId={message.groupId}
          >
            {presenter.text.payload.text}
          </TextRenderer>
        )}
        {presenter.isShowEditButton ? (
          <Button
            className={styles.button}
            onClick={presenter.editDraft}
            theme="7"
            rounding="1"
          >
            {t('channels:channel_article_edit_article')}
          </Button>
        ) : (
          <Button
            className={styles.button}
            onClick={presenter.openPreview}
            theme="7"
            rounding="1"
          >
            {t('channels:channel_article_read_article')}
          </Button>
        )}
      </div>
    </>
  );
};

export default observer(Article);
