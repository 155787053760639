import { useEffect, useRef } from 'react';

import Tasks from 'APP/Tasks';
import bus from 'APP/packages/bus';
import Entities from 'APP/store';

const INTERVAL_FOR_VIEWS_REQUEST = 60000;
const MAX_VIEWED_MESSAGES = 50;

export default () => {
  const timerId = useRef(null);
  const activeGroupTimerId = useRef(null);

  function updateViews() {
    clearTimeout(timerId.current);
    clearTimeout(activeGroupTimerId.current);
    if (Entities.App.isFocused) {
      Tasks.messaging.updateViews();
    }
    timerId.current = setTimeout(updateViews, INTERVAL_FOR_VIEWS_REQUEST);
  }

  useEffect(() => {
    const unsubscribe = bus.ui.subscribeScrollMessages(updateViews);

    return () => {
      unsubscribe();
      clearTimeout(timerId.current);
      clearTimeout(activeGroupTimerId.current);
    };
  }, []);

  useEffect(() => {
    const group = Entities.GroupsStore.activeGroup;
    if (group && group.messagesStore.messages.length) {
      // await 2s for load and render messages
      activeGroupTimerId.current = setTimeout(updateViews, 2000);
    } else {
      // await 0.5s for render messages
      activeGroupTimerId.current = setTimeout(updateViews, 500);
    }
  }, [Entities.GroupsStore.activeGroupId]);

  useEffect(() => {
    updateViews();
  }, [Entities.App.isFocused]);

  useEffect(() => {
    if (Entities.viewedMessages.numberViewedMessages > MAX_VIEWED_MESSAGES) {
      updateViews();
    }
  }, [Entities.viewedMessages.numberViewedMessages]);
};
