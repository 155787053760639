import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { CallEventTypes } from 'APP/constants/calls';
import { useTranslation } from 'APP/packages/translations';
import Entities from 'APP/store';
import MicOffIcon from 'ICONS/ic-mic-off.svg';
import RemoveIcon from 'ICONS/ic-remove-user.svg';
import { Call } from 'STORE/Calls/Call/Call';
import { Opponent } from 'STORE/Calls/Call/Opponent/Opponent';
import { IPopover } from 'UIKIT/Popover/Popover.types';
import { IPopoverMenuItem } from 'UIKIT/PopoverMenu/PopoverMenu.types';

interface IOpponentVideoPresenterParams {
  opponent: Opponent | null;
  popover: IPopover<HTMLDivElement>;
  onClick?(): void;
}

interface IOpponentVideoPresenter {
  activeCall: Call;
  hasShowMute: boolean;
  hasShowRemove: boolean;
  actions: IPopoverMenuItem[];
  mute(): void;
  remove(): void;
  onClick(): void;
}

export const useOpponentVideoPresenter = ({
  opponent,
  popover,
  onClick,
}: IOpponentVideoPresenterParams): IOpponentVideoPresenter => {
  const { t } = useTranslation();
  const source = useAsObservableSource({ opponent, popover, onClick });

  const presenter = useLocalStore<IOpponentVideoPresenter>(() => ({
    get activeCall(): Call {
      return Entities.Calls.ongoingCall!;
    },

    get hasShowMute(): boolean {
      return presenter.activeCall.me.isAvailableMuteAudio && !!source.opponent?.member?.isMuting;
    },

    get hasShowRemove(): boolean {
      return presenter.activeCall.isMeInitiator && !!source.opponent?.member?.isRemoving;
    },

    mute(): void {
      if (presenter.activeCall.channelId && source.opponent?.member?.userId) {
        Tasks.calls.setActionCall({
          action: CallEventTypes.ForceMute,
          channelId: presenter.activeCall.channelId,
          userId: source.opponent?.member.userId,
        });
      }
    },

    remove(): void {
      const channelId = presenter.activeCall.channelId;
      const userId = opponent?.member?.userId;

      if (!channelId || !userId) {
        return;
      }

      Tasks.calls.removeMemberFromCall(userId, channelId);
    },

    onClick(): void {
      popover.close();

      if (onClick) {
        onClick();
      }
    },

    get actions(): IPopoverMenuItem[] {
      const actions = [];
      if (presenter.hasShowMute) {
        actions.push({
          key: 'mute',
          title: t('calls_mute_microphone'),
          onClick: presenter.mute,
          icon: MicOffIcon,
        });
      }
      if (presenter.hasShowRemove) {
        actions.push({
          key: 'remove',
          title: t('common_remove'),
          onClick: presenter.remove,
          icon: RemoveIcon,
        });
      }
      return actions;
    },
  }));

  useEffect(() => {
    if (presenter.actions.length === 0) {
      popover.close();
    }
  }, [presenter.actions]);

  return presenter;
};
