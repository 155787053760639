import { useLocalStore, useAsObservableSource } from 'mobx-react';
import { useLocation } from 'react-router-dom';

import Tasks from 'APP/Tasks';
import { PayloadType } from 'APP/model/message/messageModel.types';
import { RouterState } from 'APP/router/constants';
import Entities from 'APP/store';
import { Counter } from 'STORE/CountersStore/Counter';
import AlbumPayload from 'STORE/Messages/Message/Payload/Album';
import ImagePayload from 'STORE/Messages/Message/Payload/Image';
import TextPayload from 'STORE/Messages/Message/Payload/Text';
import VideoPayload from 'STORE/Messages/Message/Payload/Video';
import { getFormatNumberToString } from 'UTILS/getFormatNumberToString';

import { IMediaGalleryFile, TCloseHandler } from '../MediaGallery.types';

type TMediaPayload = AlbumPayload | ImagePayload | VideoPayload;

interface IMediaGallerySidebarPresenterParams {
  file: IMediaGalleryFile;
  onClose: TCloseHandler;
}

interface IMediaGallerySidebarPresenter {
  isShowFooter: boolean;
  payload: TMediaPayload | TextPayload | null;
  counter: Counter | null;
  description: string;
  canLeaveComment: boolean;
  commentsCount: string;
  onCommentsClick(): Promise<void>;
}

export const useMediaGallerySidebarPresenter = (
  data: IMediaGallerySidebarPresenterParams
): IMediaGallerySidebarPresenter => {
  const { file, onClose } = data;
  const location = useLocation();

  const source = useAsObservableSource({ file, location, onClose });

  const presenter = useLocalStore<IMediaGallerySidebarPresenter>(() => ({
    get isShowFooter(): boolean {
      return !source.file.message?.isFake || false;
    },

    get payload(): TMediaPayload | TextPayload | null {
      if (source.file.message.payload.payloadType === PayloadType.Article) {
        return source.file.message.renderPayload.firstTextPayload?.payload || null;
      }

      return source.file.message.renderPayload;
    },

    get counter(): Counter | null {
      if (!source.file.message.threadId) {
        return null;
      }

      return Entities.Counters.getCounterById(source.file.message.threadId);
    },

    get description(): string {
      const payload = presenter.payload;

      if (!payload) {
        return '';
      }

      if (source.file.message.payload.payloadType === PayloadType.Article) {
        return ((payload as TextPayload)?.text || '').trim();
      }

      return ((payload as TMediaPayload).comment || '').trim();
    },

    get canLeaveComment(): boolean {
      return Boolean(source.file.message.group?.canLeaveComment);
    },

    get commentsCount(): string {
      if (!source.file.message.threadId) {
        return '';
      }

      return presenter.counter
        ? getFormatNumberToString(presenter.counter.totalMessagesCount) || ''
        : '';
    },

    async onCommentsClick(): Promise<void> {
      const threadGroup = await Tasks.thread.getOrCreateThread(source.file.message);

      if (!threadGroup) {
        return;
      }

      await Tasks.group.setActiveGroup({
        groupId: threadGroup.id,
        state: {
          [RouterState.hasGoBack]: true,
        },
      });
    },
  }));

  return presenter;
};
