import execCommand from 'execcommand-copy';

import api from 'APP/packages/api';
import Entities from 'APP/store';
import { langService } from 'MODULE/i18n/service';

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const request = async (message) => {
  const { deepLink = null } = await api.deeplinks.createMessageDeepLink({
    groupId: message.groupId,
    messageId: message.id,
  });
  message.setDeepLink(deepLink);
};

export default async (message) => {
  const { lang } = langService.i18n;
  if (!message.deepLink) {
    try {
      request(message);
      const currentTime = Date.now();
      // this is a hack for safari. Blocks buffer insertion
      // breaks copy when async request or sleep > 900ms
      do {
        await sleep(100);
      } while (!message.deepLink && Date.now() - currentTime < 900);
    } catch (e) {
      Entities.toast.show(lang['something_went_wrong']);
      return;
    }
  }

  if (message.deepLink && execCommand.copy(message.deepLink)) {
    Entities.toast.show(lang['common_link_copied']);
  } else {
    Entities.toast.show(lang['something_went_wrong']);
  }
};
