import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { ALERT_TYPES } from 'APP/constants/app';
import { mapCounterResponse } from 'APP/model/counter/counterModel.mapper';
import { initThreadListener } from 'APP/packages/streamApi';
import Entities from 'APP/store';

export default () => {
  const presenter = useLocalStore(() => ({
    disposeListener: null,

    get group() {
      return Entities.GroupsStore.activeGroup;
    },

    connect() {
      if (Entities.GroupsStore.activeGroup?.isThread) {
        presenter.disposeListener = initThreadListener({
          threadId: presenter.group.id,
          handleBatchedCounters: presenter.handleBatchedCounters,
          onChangeCounter: presenter.onChangeCounter,
          onError: presenter.onError,
        });
      }
    },

    handleBatchedCounters: (data) => {
      presenter.addCounters(data.counters);
    },

    onChangeCounter: (data) => {
      presenter.addCounters([data.counter]);
    },

    addCounters: (counters) => {
      //Todo remove mapCounterResponse after migrate initThreadListener in model layer
      Tasks.group.addCounters(counters.map(mapCounterResponse));
    },

    disconnect() {
      if (presenter.disposeListener) {
        presenter.disposeListener();
        presenter.disposeListener = null;
      }
    },

    async onError(err) {
      try {
        presenter.disconnect();
        // Check for error code in sse. I did not find another possibility in the specification
        // https://html.spec.whatwg.org/multipage/server-sent-events.html#the-eventsource-interface
        const controller = new AbortController();
        const { signal } = controller;
        const { status } = await fetch(err.target.url, { signal });
        controller.abort();
        if (status === 403) {
          Tasks.group.setActiveGroup({ groupId: null });
          if (presenter.group?.parentGroup?.isChannel) {
            Tasks.App.addAlert({
              type: ALERT_TYPES.CHANNEL_IS_NOT_ACCESSIBLE,
            });
          } else {
            Tasks.App.addAlert({
              type: ALERT_TYPES.CHAT_IS_NOT_ACCESSIBLE,
            });
          }
        } else {
          setTimeout(() => {
            presenter.connect();
          }, 3000);
        }
      } catch (e) {
        console.error(e);
      }
    },
  }));

  useEffect(() => {
    presenter.connect();
    return () => {
      presenter.disconnect();
    };
  }, [Entities.GroupsStore.activeGroup]);

  useEffect(() => {
    if (Entities.AuthStore.streamToken && !presenter.disposeListener) {
      presenter.connect();
    }
    return () => {
      presenter.disconnect();
    };
  }, [Entities.AuthStore.streamToken]);
};
