import Tasks from 'APP/Tasks';
import api from 'APP/packages/api';
import Entities from 'APP/store';

async function request() {
  try {
    await api.userSettings.deleteAccount();
  } catch (error) {
    console.error(error);
  }
}

export default async () => {
  if (Entities.pushNotifications.isEnabled) {
    await Tasks.pushNotifications.unsubscribeNotifications();
    await Tasks.pushNotifications.clearNotifications();
  }
  await request();
  Entities.reset();
  Entities.AuthStore.setDeleted(true);
};
