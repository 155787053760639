import { useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { useTranslation } from 'APP/packages/translations';
import { RouterLink } from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import Entities from 'APP/store';
import { TNewsItem } from 'STORE/Newsfeed/Newsfeed.types';

interface INewsfeedPresenter {
  readonly isShowError: boolean;
  readonly isShowEmpty: boolean;
  readonly isShowPlaceholder: boolean;
  readonly isLoading: boolean;
  readonly isPreloading: boolean;
  readonly news: TNewsItem[];
  readonly scrollPosition: number;
  readonly hasBackgroundImage: boolean;
  hasMoreNews: boolean;
  load(cursor?: string | null): Promise<void>;
  preload(): Promise<void>;
  reload(): Promise<void>;
  setNewsVisibility(position: number, isView: boolean): void;
  checkAndLoadMore(position: number): void;
  onView(position: number): void;
  onHide(position: number): void;
  onScroll(offset: number): void;
  onClose(): void;
}

const PRELOAD_POSITION = 5;

export const useNewsfeedPresenter = (): INewsfeedPresenter => {
  const { t } = useTranslation();
  const navigateTo = useNavigateTo();

  const presenter = useLocalStore<INewsfeedPresenter>(() => ({
    hasMoreNews: true,

    get scrollPosition(): number {
      return Entities.newsfeed.scrollPosition;
    },

    get isShowError(): boolean {
      return Entities.newsfeed.isError && !Entities.newsfeed.cursor;
    },

    get isShowEmpty(): boolean {
      if (presenter.isLoading && !presenter.isPreloading) {
        return false;
      } else {
        return !presenter.isShowError && !presenter.news.length;
      }
    },

    get isShowPlaceholder(): boolean {
      return presenter.isLoading && !presenter.isPreloading && !presenter.isShowError;
    },

    get isLoading(): boolean {
      return Entities.newsfeed.isLoading;
    },

    get isPreloading(): boolean {
      return Entities.newsfeed.isPreloading;
    },

    get news(): TNewsItem[] {
      return Entities.newsfeed.news;
    },

    get hasBackgroundImage(): boolean {
      return Entities.appearance.hasChatBackground;
    },

    async load(cursor: string | null = null): Promise<void> {
      const result = await Tasks.newsfeed.preloadNewsfeed({ cursor });
      if (!result && cursor) {
        Entities.toast.show(t('newsfeed_error'));
      }
    },

    async preload(): Promise<void> {
      if (Entities.newsfeed.cursor) {
        Entities.newsfeed.setPreloading(true);
        await presenter.load(Entities.newsfeed.cursor);
        Entities.newsfeed.setPreloading(false);
      } else {
        presenter.hasMoreNews = false;
      }
    },

    async reload(): Promise<void> {
      Entities.newsfeed.reset();
      await presenter.load();
    },

    setNewsVisibility(position: number, isView: boolean): void {
      if (isView) {
        presenter.onView(position);
        presenter.checkAndLoadMore(position);
      } else {
        presenter.onHide(position);
      }
    },

    checkAndLoadMore(position: number): void {
      const targetPosition = presenter.news.length - PRELOAD_POSITION;

      if (!presenter.isLoading && position >= targetPosition) {
        presenter.preload();
      }
    },

    onView(position): void {
      const news = Entities.newsfeed.getPostByPosition(position);
      if (news) {
        Tasks.messaging.inViewMessage(news);
      }
    },

    onHide(position): void {
      const news = Entities.newsfeed.getPostByPosition(position);
      if (news) {
        Tasks.messaging.outViewMessage(news);
      }
    },

    onScroll(offset: number): void {
      Entities.newsfeed.setScrollPosition(offset);
    },

    onClose(): void {
      navigateTo({ to: RouterLink.main });
    },
  }));

  return presenter;
};
