import api from 'APP/packages/api';
import Entities from 'APP/store';

export default async (draft) => {
  try {
    await api.messaging.sendNewMessage(draft.toJSON());

    const group = Entities.GroupsStore.getGroupById(draft.groupId);

    if (!group) {
      return;
    }

    group.draftsStore.removeDraftById(draft.id);
  } catch (error) {
    console.error(error);
    return [];
  }
};
