import React from 'react';

import { IRenderer } from '../renderTree.types';

export const ItalicRenderer: IRenderer = ({ children }) => {
  if (!children) {
    return null;
  }

  return <em>{children}</em>;
};
