import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { AuthImage } from 'APP/components/AuthImage/AuthImage';
import GemLogo from 'ICONS/gem-logo-56.svg';
import { IconView } from 'UIKIT/IconView/IconView';
import { IconViewSizes } from 'UIKIT/IconView/IconView.types';

import { useCallFinishedPresenter } from './CallFinished.presenter';
import styles from './CallFinished.styles.m.css';
import { CallFinishedInfo } from './CallFinishedInfo/CallFinishedInfo';

export const CallFinished: FC = observer(() => {
  const presenter = useCallFinishedPresenter();

  return (
    <div className={styles.container}>
      <AuthImage theme={presenter.currentTheme} />
      <div className={styles.content}>
        <IconView
          Icon={GemLogo}
          size={IconViewSizes.Auto}
          className={styles.logo}
        />
        <CallFinishedInfo
          isGroupCall={presenter.isGroupCall}
          invitationInfo={presenter.invitationInfo}
        />
      </div>
    </div>
  );
});
