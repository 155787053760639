import { action, observable, reaction, computed } from 'mobx';
import OpusMediaRecorder from 'opus-media-recorder';
import OpusWorker from 'opus-media-recorder/encoderWorker.js';

import Tasks from 'APP/Tasks';
import { ALERT_TYPES, AudioSourceType } from 'APP/constants/app';
import { TIME_RESTRICTION } from 'APP/constants/voice';
import { PayloadType } from 'APP/model/message/messageModel.types';
import dateService from 'APP/packages/date';
import Entities, { Root } from 'APP/store';
import { InputPanel } from 'STORE/ViewModels/InputPanel';
import {
  EncoderConfig,
  IMessageData,
  WorkerMessageType,
} from 'STORE/ViewModels/InputPanel/Voice/Voice.types';
import { isMediaRecorderSupportedWavFormat } from 'UTILS/browser';
import { convertBufferToBase64 } from 'UTILS/voice/convertBufferToBase64';
import { convertBufferToPeaks } from 'UTILS/voice/convertBufferToPeaks';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore // typescript unable to see that it is as a constructor
import VoiceWorker from './Voice.worker';

const MIN_SECONDS_TO_SAVE_MESSAGE = 0.5;
const CHECK_TIME_RESTRICTIONS_TIMEOUT = 1000;

const workerOptions = {
  encoderWorkerFactory: (): unknown => new OpusWorker(),
};

const recorderMimeType = 'audio/wav';
const fileMimeType = 'audio/mp3';
const downloadFileExt = '.mp3';

export class Voice {
  @observable isStarted = false;
  @observable isRecording = false;
  @observable isPreviewMode = false;

  @observable blob: Blob | null = null;
  @observable src: string | null = null;
  @observable peaks: Uint8Array | null = null;

  root: Root;
  parent: InputPanel;
  recorder: MediaRecorder | typeof OpusMediaRecorder | null = null;
  isSend = false;
  stream: MediaStream;

  interval: ReturnType<typeof setTimeout> | null = null;
  changeActiveGroupReactionDispose: (() => void) | null = null;
  removeAudioSource: (() => void) | null = null;

  constructor(root: Root, parent: InputPanel) {
    this.root = root;
    this.parent = parent;
  }

  @computed
  get groupId(): string {
    return this.parent.group.isSchedule ? this.parent.group.groupId : this.parent.group.id;
  }

  @action
  start = async (): Promise<void> => {
    const hasAudioPermission = await Tasks.permissions.hasMicrophonePermission();

    if (!hasAudioPermission || !this.checkPrivacy()) {
      return;
    }

    this.isStarted = true;

    this.stream = await (navigator as Navigator).mediaDevices.getUserMedia({ audio: true });

    if (isMediaRecorderSupportedWavFormat()) {
      this.recorder = new MediaRecorder(this.stream, { mimeType: recorderMimeType });
    } else {
      this.recorder = new OpusMediaRecorder(
        this.stream,
        { mimeType: recorderMimeType },
        workerOptions
      );
    }

    this.onStart = this.onStart.bind(this);
    this.onDataAvailable = this.onDataAvailable.bind(this);
    this.onStop = this.onStop.bind(this);

    this.recorder.addEventListener('start', this.onStart);
    this.recorder.addEventListener('dataavailable', this.onDataAvailable);
    this.recorder.addEventListener('stop', this.onStop);

    try {
      await this.recorder.start();
    } catch ({ name }) {
      this.isStarted = false;

      if (name === 'NotAllowedError') {
        Tasks.app.addAlert({
          type: ALERT_TYPES.CALL_NO_MICROPHONE_PERMISSIONS,
        });
        this.reset();
      }

      if (name === 'NotReadableError') {
        Tasks.app.addAlert({
          type: ALERT_TYPES.CALL_NO_MICROPHONE_CONNECTED,
        });
        this.reset();
      }
    }
  };

  @action
  send = async (): Promise<void> => {
    this.isSend = true;
    if (this.isRecording && this.recorder.state !== 'inactive') {
      this.recorder.stop();
    } else {
      await this.sendMessage();
    }
  };

  @action
  schedule = async (date: string): Promise<boolean> => {
    if (!this.checkPrivacy() || !this.canSaveMessage()) {
      return false;
    }

    const data = await this.getMessageData();
    const result = await Tasks.messaging.saveScheduledMessage({
      groupId: this.groupId,
      message: data,
      date,
    });

    if (result) {
      this.reset();
    }

    return result;
  };

  @action
  stop = async (): Promise<void> => {
    if (this.recorder.state !== 'inactive') {
      this.recorder.stop();
    }
    this.stopStreamTracks();
  };

  @action
  setPeaks = (peaks: Uint8Array): void => {
    this.peaks = peaks;
  };

  @action
  reset = (): void => {
    this.recorder?.removeEventListener('start', this.onStart);
    this.recorder?.removeEventListener('dataavailable', this.onDataAvailable);
    this.recorder?.removeEventListener('stop', this.onStop);

    if (this.isRecording && this.recorder.state !== 'inactive') {
      this.recorder.stop();
    }
    this.isRecording = false;
    this.stopCheckTimeRestrictions();
    this.isPreviewMode = false;
    this.blob = null;
    this.src = null;
    this.recorder = null;
    this.changeActiveGroupReactionDispose?.();
    this.changeActiveGroupReactionDispose = null;
    this.clearAudioSource();
    this.isSend = false;
    this.isRecording = false;
    this.stopStreamTracks();
  };

  @computed
  get isShowRecordPanel(): boolean {
    return this.isPreviewMode || this.isRecording;
  }

  get duration(): number {
    return this.recorder?.context.currentTime || 0;
  }

  stopStreamTracks(): void {
    if (this.stream) {
      this.stream.getTracks().forEach((track) => track.stop());
    }
  }

  async audioBufferToFloat32Array(
    buffer: ArrayBuffer
  ): Promise<{ float32Array: Float32Array; audioBuffer: AudioBuffer }> {
    const audioBuffer = await this.recorder.context.decodeAudioData(buffer.slice(0));
    const channelData = audioBuffer.getChannelData(0); // we'll only use the first channel
    return { float32Array: new Float32Array(channelData), audioBuffer };
  }

  getMessageData(): Promise<IMessageData> {
    return new Promise(async (resolve) => {
      const fileName = dateService.now().toISOString() + downloadFileExt;
      const arrayBuffer = (await this.getArrayBuffer()) as ArrayBuffer;
      const { float32Array, audioBuffer } = await this.audioBufferToFloat32Array(arrayBuffer);

      const voiceWorker = new VoiceWorker();
      const encoderConfig: EncoderConfig = { sampleRate: audioBuffer.sampleRate, bitRate: 128 };

      voiceWorker.postMessage({ type: WorkerMessageType.CreateEncoder, data: encoderConfig });
      voiceWorker.postMessage({ type: WorkerMessageType.Encode, data: float32Array });
      voiceWorker.postMessage({ type: WorkerMessageType.FinalBuffer });

      voiceWorker.onmessage = async (event: MessageEvent): Promise<void> => {
        if (event.data.type === WorkerMessageType.FinalBuffer) {
          const mp3blob = new Blob(event.data.data, { type: fileMimeType });
          const mp3File = new File([mp3blob], fileName, { type: fileMimeType });

          const peaks = await convertBufferToPeaks(arrayBuffer);
          const base64Peaks = convertBufferToBase64(peaks);

          voiceWorker.postMessage({ type: WorkerMessageType.ClearBuffer });

          resolve({
            type: PayloadType.VoiceMessage,
            data: { file: mp3File, histogram: base64Peaks, duration: this.duration * 1000 },
            groupId: this.groupId,
            quotedMessage: this.parent.quoteMessage,
          });
        }
      };
    });
  }

  checkPrivacy = (): boolean => {
    const group = Entities.GroupsStore.getGroupById(this.groupId);
    if (group && group.isP2P && !group.groupOpponent?.privacyStatus.sendVoiceMessage) {
      Tasks.app.addAlert({
        type: ALERT_TYPES.PRIVACY_VOICE_ALERT,
        data: {
          userName: group.groupOpponent?.displayName,
        },
      });
      return false;
    }
    return true;
  };

  async sendMessage(): Promise<void> {
    if (!this.checkPrivacy() || !this.canSaveMessage()) {
      return;
    }
    const data = await this.getMessageData();
    Tasks.messaging.createNewMessages({ messages: [data] });

    this.reset();
  }

  async onDataAvailable(e: BlobEvent): Promise<void> {
    if (this.recorder.state === 'inactive') {
      this.blob = new Blob([e.data], { type: recorderMimeType });
      this.src = URL.createObjectURL(this.blob);
    }
  }

  onStart(): void {
    this.isStarted = false;
    this.checkTimeRestrictions();
    this.isRecording = true;
    this.changeActiveGroupReactionDispose = reaction(
      () => this.root.GroupsStore.activeGroup,
      this.reset
    );

    this.removeAudioSource = Tasks.app.audioSource.setCurrentSource(
      AudioSourceType.VoiceRecording,
      this.stop
    );
  }

  async onStop(): Promise<void> {
    if (this.duration < MIN_SECONDS_TO_SAVE_MESSAGE) {
      return this.reset();
    }

    if (!this.isRecording) {
      return;
    }

    if (this.isSend) {
      await this.sendMessage();
    } else {
      this.isRecording = false;
      this.stopCheckTimeRestrictions();
      this.clearAudioSource();
      this.isPreviewMode = true;
    }
  }

  clearAudioSource(): void {
    if (this.removeAudioSource) {
      this.removeAudioSource();
      this.removeAudioSource = null;
    }
  }

  canSaveMessage = (): boolean => {
    return !!this.recorder;
  };

  checkTimeRestrictions = (): void => {
    this.interval = setInterval(() => {
      if (this.duration >= TIME_RESTRICTION) {
        this.send();
      }
    }, CHECK_TIME_RESTRICTIONS_TIMEOUT);
  };

  stopCheckTimeRestrictions(): void {
    if (this.interval !== null) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  // All browsers support
  getArrayBuffer = async (): Promise<string | ArrayBuffer | null> => {
    return new Promise((resolve) => {
      const fileReader = new FileReader();

      fileReader.onloadend = function (): void {
        resolve(fileReader.result);
      };

      if (!this.blob) {
        throw new Error('Can not get array buffer. No blob');
      }

      fileReader.readAsArrayBuffer(this.blob);
    });
  };

  async getAudioHistogram(): Promise<string> {
    const arrayBuffer = await this.getArrayBuffer();
    const peaks = await convertBufferToPeaks(arrayBuffer as ArrayBuffer);
    return convertBufferToBase64(peaks);
  }
}

export default Voice;
