import api from 'APP/packages/api';
import logger from 'APP/packages/logger';

import {
  mapCallPermissionsRequestsResponse,
  mapCallPermissionsResponse,
} from '../callPermissions/callPermissionsModel.mapper';
import { buildModelError } from '../error/errorBuilder';
import { ICallPermissionRequests, ICallPermissionsSettings } from './callPermissionsModel.types';

export const getCallPermissions = async (channelId: string): Promise<ICallPermissionsSettings> => {
  try {
    const callInfo = await api.calls.getPermissions({ channelId });
    return mapCallPermissionsResponse(callInfo);
  } catch (error) {
    logger.get('API').error('calls.permissions.getCallPermissions', error);
    throw buildModelError(error);
  }
};

export const getCallPermissionsRequests = async (
  channelId: string
): Promise<ICallPermissionRequests> => {
  try {
    const response = await api.calls.getPermissionRequests(channelId);
    return mapCallPermissionsRequestsResponse(response);
  } catch (error) {
    logger.get('API').error('calls.permissions.getCallPermissionsRequests', error);
    throw buildModelError(error);
  }
};
