import classNames from 'classnames';
import React, { FC, PropsWithChildren } from 'react';

import { AvatarTypes, TAvatarBackgroundColorIndex } from 'UIKIT/Avatar/Avatar.types';

import { Avatar } from '../Avatar/Avatar';
import styles from './style.m.scss';

interface IPopupInfoCardProps {
  className?: string;
  avatarTitle?: string;
  avatarUrl?: string;
  avatarBackgroundColorIndex?: TAvatarBackgroundColorIndex;
  title?: string;
  subtitle?: string;
  type: AvatarTypes;
  isOnline?: boolean;
  badge?: React.ReactNode;
}

const PopupInfoCard: FC<PropsWithChildren<IPopupInfoCardProps>> = ({
  className,
  avatarTitle,
  avatarUrl,
  avatarBackgroundColorIndex,
  title,
  subtitle,
  children,
  type,
  isOnline = undefined,
  badge = undefined,
}) => {
  return (
    <div className={classNames(styles.container, styles[type], className)}>
      {children || (
        <Avatar
          title={avatarTitle || ''}
          url={avatarUrl}
          backgroundColorIndex={avatarBackgroundColorIndex}
          type={type}
          isOnline={isOnline}
        />
      )}
      <div className={styles.info}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>{title}</div>
          {badge}
        </div>
        {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
      </div>
    </div>
  );
};

export default PopupInfoCard;
