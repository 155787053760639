import { useLocalStore } from 'mobx-react';

import Entities from 'APP/store';
import { Call } from 'STORE/Calls/Call/Call';

interface IGroupVideoCallHeaderPresenter {
  call: Call;
}

export const useGroupVideoCallHeaderPresenter = (): IGroupVideoCallHeaderPresenter => {
  return useLocalStore<IGroupVideoCallHeaderPresenter>(() => ({
    get call(): Call {
      return Entities.Calls.ongoingCall!;
    },
  }));
};
