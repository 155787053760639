import { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { RouterStatePopup } from 'APP/router/constants';
import useGetState from 'APP/router/hooks/useGetState';
import Entities from 'STORE';

export default () => {
  const sticker = useGetState(RouterStatePopup.sticker);
  const history = useHistory();

  const onClose = useCallback(() => {
    history.replace(history.location.pathname + history.location.search, null);
  }, []);

  useEffect(() => {
    window.addEventListener('mouseup', onClose);
    return () => {
      window.removeEventListener('mouseup', onClose);
    };
  }, [onClose]);

  return {
    onClose,
    sticker,
    displayUILayer: Entities.App.uiLayer,
  };
};
