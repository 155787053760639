import { useLocalStore } from 'mobx-react';

import { useTranslation } from 'APP/packages/translations';
import Entities from 'STORE';
import { Call } from 'STORE/Calls/Call/Call';

interface IGroupAudioCallPresenter {
  membersQuantityText: string;
  call: Call;
}

export const useGroupAudioCallPresenter = (): IGroupAudioCallPresenter => {
  const { t } = useTranslation();

  const presenter = useLocalStore<IGroupAudioCallPresenter>(() => ({
    get call(): Call {
      return Entities.Calls.ongoingCall!;
    },

    get membersQuantityText(): string {
      return t('calls_users_on_call', {
        0: presenter.call.members.memberOnCallList.length,
        1: presenter.call.members.memberList.length,
      });
    },
  }));

  return presenter;
};
