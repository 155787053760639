import { TTree } from '../parseToTree/parseToTree.types';
import { TRender } from './parseTreeToHtml.types';
import { boldAndItalicRender } from './renders/boldAndItalicRender';
import { boldRender } from './renders/boldRender';
import { italicRender } from './renders/italicRender';
import { linkRender } from './renders/linkRender';
import { strikethroughRender } from './renders/strikethroughRender';
import { textRender } from './renders/textRender';
import { underlineRender } from './renders/underlineRender';

const tagRenders: Record<string, TRender<any>> = {
  text: textRender,
  '@': textRender,
  '/': textRender,
  keyWord: textRender,
  email: textRender,
  '***': boldAndItalicRender,
  '**': boldRender,
  '*': italicRender,
  '~~': strikethroughRender,
  __: underlineRender,
  ']': linkRender,
  '[': linkRender,
  link: textRender,
  '```': textRender,
};

export const parseTreeToHtml = (tree: TTree): string => {
  return tree.reduce((acc, treeItem) => {
    const content = Array.isArray(treeItem.content)
      ? parseTreeToHtml(treeItem.content)
      : treeItem.content;

    const render = tagRenders[treeItem.tag] || textRender;
    return acc + render(content, treeItem.props);
  }, '');
};
