import { spaceModel } from 'APP/model/model';
import { ICreateSpaceCollection, ISpaceCollection } from 'APP/model/space/spaceModel.types';
import { spaceAnalytics } from 'APP/packages/analytics/analytics';
import Entities from 'STORE';

export async function createSpaceCollection(
  data: ICreateSpaceCollection
): Promise<ISpaceCollection | null> {
  try {
    const collection = await spaceModel.createCollection(data);

    const space = Entities.spacesStore.getById(data.spaceId);
    space?.collections.addCollection(collection);

    spaceAnalytics.trackSpaceCollectionCreated(data.spaceId);
    spaceAnalytics.trackSpaceAddedToCollection(data.spaceId);

    return collection;
  } catch {
    return null;
  }
}
