import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { GroupedMessages } from 'MAIN/ChatView/Messages/GroupedMessages/GroupedMessages';
import { MessagesContentWrapper } from 'MAIN/ChatView/Messages/MessagesContentWrapper/MessagesContentWrapper';
import { useStickyDate } from 'MAIN/hooks/stickyDate';

import { LoadAboveObserver } from './LoadAboveObserver/LoadAboveObserver';
import { LoadBelowObserver } from './LoadBelowObserver/LoadBelowObserver';
import { LOAD_OFFSET, useMessagesPresenter } from './Messages.presenter';
import styles from './Messages.styles.m.css';
import { MessagesEmpty } from './MessagesEmpty/MessagesEmpty';
import { ScrollToBottomButton } from './ScrollToBottomButton/ScrollToBottomButton';
import { ScrollToMentionButton } from './ScrollToMentionButton/ScrollToMentionButton';

interface IMessagesProps {
  groupId: string;
}

export const Messages: FC<IMessagesProps> = observer(({ groupId }) => {
  const { scrollRef, stickyClassName, onScroll } = useStickyDate();
  const { newMessagesPlaceholderRef, containerRef, presenter } = useMessagesPresenter({
    scrollRef,
    groupId,
  });

  return (
    <>
      <div
        className={styles.container}
        ref={containerRef}
      >
        <MessagesEmpty />

        {presenter.messages.length > 0 && (
          <MessagesContentWrapper
            scrollRef={scrollRef}
            onScroll={onScroll}
          >
            <LoadAboveObserver
              groupId={groupId}
              firstMessage={presenter.firstMessage}
              rootElement={scrollRef.current}
              offset={LOAD_OFFSET}
              isDisabled={presenter.isScrollingWithAnimation}
            />
            {presenter.messagesGroups.map(([date, groupedMessages]) => (
              <GroupedMessages
                key={date}
                date={date}
                groupedMessages={groupedMessages}
                scrollRef={scrollRef}
                newMessagesPlaceholderRef={newMessagesPlaceholderRef}
                stickyClassName={stickyClassName}
              />
            ))}

            <LoadBelowObserver
              groupId={groupId}
              lastMessage={presenter.lastMessage}
              rootElement={scrollRef.current}
              offset={LOAD_OFFSET}
              isDisabled={presenter.isScrollingWithAnimation}
            />
          </MessagesContentWrapper>
        )}
      </div>
      <ScrollToMentionButton />
      <ScrollToBottomButton lastMessage={presenter.lastMessage} />
    </>
  );
});
