import { FormState } from 'final-form';
import { useLocalStore } from 'mobx-react';
import { useRef, useLayoutEffect, MutableRefObject } from 'react';

import Tasks from 'APP/Tasks';
import { IUpdateUserDisplayData } from 'APP/model/userSettings/userSettingsModel.types';
import { useTranslation } from 'APP/packages/translations';
import validations from 'APP/packages/validations';
import Entities from 'APP/store';
import { TAvatarBackgroundColorIndex } from 'UIKIT/Avatar/Avatar.types';

interface IEditProfileViewPresenter {
  initialValues: IUpdateUserDisplayData;
  bioValue: string;
  isVerified: boolean;
  avatarTitle: string;
  avatarColorIndex: TAvatarBackgroundColorIndex;
  usernameValidate(): boolean;
  nicknameValidate(): Promise<boolean>;
  bioValidate(): boolean;
  onChange(data: FormState<IUpdateUserDisplayData>): void;
  onSubmitSuccess(data: IUpdateUserDisplayData): Promise<void>;
  onSubmit(data: IUpdateUserDisplayData): void;
}

interface IUseEditProfileViewPresenter {
  presenter: IEditProfileViewPresenter;
  bioRef: MutableRefObject<HTMLTextAreaElement | null>;
}

export const useEditProfileViewPresenter = (onSave: () => void): IUseEditProfileViewPresenter => {
  const { t } = useTranslation();
  const formValidations = validations.editProfileForm();
  const bioRef = useRef<HTMLTextAreaElement>(null);

  const presenter = useLocalStore<IEditProfileViewPresenter>(() => ({
    initialValues: {
      avatarUrl: Entities.UsersStore.Me.avatarUrl,
      userName: Entities.UsersStore.Me.userName,
      nickName: Entities.UsersStore.Me.nickName,
      bio: Entities.UsersStore.Me.bio,
      usernameBadge: Entities.UsersStore.Me.usernameBadge,
    },

    bioValue: Entities.UsersStore.Me.bio,

    usernameValidate: formValidations.username.validate,
    nicknameValidate: formValidations.nickname.validate,
    bioValidate: formValidations.bio.validate,

    get avatarTitle(): string {
      return Entities.UsersStore.Me.avatarTitle;
    },

    get avatarColorIndex(): TAvatarBackgroundColorIndex {
      return Entities.UsersStore.Me.avatarColorIndex || '';
    },

    get isVerified(): boolean {
      return Entities.UsersStore.Me.verified;
    },

    onChange(data: FormState<IUpdateUserDisplayData>): void {
      presenter.bioValue = data.values.bio || '';
    },

    async onSubmitSuccess(data: IUpdateUserDisplayData): Promise<void> {
      await Tasks.userSettings.updateMyProfile({
        ...data,
        userName: data.userName.trim(),
        bio: (data.bio || '').trim(),
      });

      onSave();
    },

    onSubmit(data: IUpdateUserDisplayData): void {
      Tasks.app.showConfirm({
        title: t('save_changes_pop_up_title'),
        text: t(presenter.isVerified ? 'badge_loss_alert' : 'save_changes_pop_up_message'),
        primaryText: t('save_changes_pop_up_apply'),
        secondaryText: t('save_changes_pop_up_cancel'),
        onSuccess: () => presenter.onSubmitSuccess(data),
      });
    },
  }));

  useLayoutEffect(() => {
    if (bioRef.current) {
      bioRef.current.style.height = 'auto';
      bioRef.current.style.height = `${bioRef.current.scrollHeight}px`;
    }
  }, [presenter.bioValue, bioRef.current]);

  return { presenter, bioRef };
};
