import { observer } from 'mobx-react';
import React from 'react';

import { TextRenderer } from 'APP/components/TextRenderer/TextRenderer';

const Buttons = ({ message }) => {
  const { text } = message.payload;

  if (!text) {
    return null;
  }

  return <TextRenderer hasBotCommands>{text}</TextRenderer>;
};

export default observer(Buttons);
