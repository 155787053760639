import { observer } from 'mobx-react';
import React, { FC } from 'react';

import IconMention from 'ICONS/mention.svg';
import PinIcon from 'ICONS/pin2.svg';
import GroupStatus from 'MAIN/components/GroupStatus';
import { Group } from 'STORE/Groups/Group';
import { Badge } from 'UIKIT/Badge/Badge';

import { GroupDescription } from '../../components/GroupDescription/GroupDescription';
import { GroupListItem } from '../../components/GroupListItem/GroupListItem';
import { JoinCallButton } from '../../components/JoinCallButton/JoinCallButton';
import { useGroupItemPresenter } from './GroupItem.presenter';
import styles from './GroupItem.styles.m.css';

interface IGroupItemProps {
  group: Group;
  isShowSpaceIcon: boolean;
}

export const GroupItem: FC<IGroupItemProps> = observer(({ group, isShowSpaceIcon }) => {
  const presenter = useGroupItemPresenter(group);

  return (
    <GroupListItem
      group={group}
      isShowSpaceIcon={isShowSpaceIcon}
      isIconsCentered={!!presenter.backgroundCall}
      description={
        <GroupStatus
          group={group}
          className={styles.status}
        >
          <GroupDescription group={group} />
        </GroupStatus>
      }
      badges={
        <>
          {presenter.hasMention && <IconMention className={styles.mention} />}
          {presenter.hasUnread && (
            <Badge
              quantity={presenter.numberOfUnread || null}
              isMuted={group.muted}
            />
          )}
          {presenter.hasPinedIcon && <PinIcon className={styles.pin} />}
          {!!presenter.backgroundCall && (
            <JoinCallButton
              hasActiveCall={!!presenter.activeCall}
              startTs={Number(presenter.activeCall?.startTs) || 0}
              onClick={presenter.onJoinToCall}
            />
          )}
        </>
      }
    />
  );
});
