import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useRef } from 'react';

import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';

import { Emoji } from './Emoji/Emoji';
import styles from './EmojiList.styles.m.css';

interface IEmojiListProps {
  emojis: string[];
  onSelect(emoji: string): void;
}
export const EmojiList: FC<IEmojiListProps> = observer(({ emojis, onSelect }) => {
  const root = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (root.current === null) {
      return;
    }

    root.current.scrollTop = 0;
  }, [emojis]);

  return (
    <div
      className={classNames(styles.container, customScrollStyles.container)}
      ref={root}
    >
      <div className={styles.category}>
        {emojis.map((emoji) => {
          return (
            <Emoji
              key={emoji}
              value={emoji}
              onSelect={onSelect}
            />
          );
        })}
      </div>
    </div>
  );
});
