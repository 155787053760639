import { scheduledEventModel } from 'APP/model/model';
import Entities from 'STORE';

export const getScheduledEvents = async (): Promise<void> => {
  try {
    const cursor = Entities.scheduledEvents.cursor;
    const response = await scheduledEventModel.getScheduledEvents({ cursor });

    Entities.scheduledEvents.setScheduledEvents(response);
  } catch (error) {
    console.error(error);
  }
};
