import classNames from 'classnames';
import React, { FC } from 'react';

import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';

import { IAllDownload } from '../AllDownloads.presenter';
import { AllDownloadsItem } from '../AllDownloadsItem/AllDownloadsItem';
import styles from './AllDownloadsList.styles.m.css';

interface IAllDownloadsListProps {
  open: boolean;
  downloads: IAllDownload[];
}

const ITEM_HEIGHT = 64;
const MAX_CONTAINER_HEIGHT = 339;

export const AllDownloadsList: FC<IAllDownloadsListProps> = (props) => {
  const { open, downloads } = props;

  const itemsHeight = downloads.length * ITEM_HEIGHT;

  return (
    <div
      style={{
        maxHeight: open
          ? `${itemsHeight < MAX_CONTAINER_HEIGHT ? itemsHeight : MAX_CONTAINER_HEIGHT}px`
          : 0,
      }}
      className={classNames(styles.container, { [styles.open]: open })}
    >
      <div className={classNames(styles.innerContainer, customScrollStyles.container)}>
        {downloads.map(({ groupId, payloadId, messageId }) => (
          <AllDownloadsItem
            key={payloadId}
            groupId={groupId}
            payloadId={payloadId}
            messageId={messageId}
          />
        ))}
      </div>
    </div>
  );
};
