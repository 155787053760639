import { FieldState } from 'final-form';
import { useLocalStore } from 'mobx-react';
import { RefObject, useEffect, useRef } from 'react';

import { DEFAULT_PHONE_MASK } from 'APP/constants/countryCodes';
import { useTranslation } from 'APP/packages/translations';
import validations from 'APP/packages/validations';
import { ICountry } from 'APP/types/countryCodes';
import arrayToObjectWithKey from 'APP/utils/arrayToObjectWithKey';
import { sortCountriesByPriority } from 'APP/utils/countries';
import { clearPhoneNumber, parsePhone, replacePhoneTemplate } from 'APP/utils/phone';

const sortCountries = (a: ICountry, b: ICountry): number => {
  const nameA = a.countryName.toUpperCase();
  const nameB = b.countryName.toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  return 0;
};

interface ICountryPhoneFieldsPresenter {
  countries: Record<string, ICountry>;
  country: ICountry | null;
  placeholder: string;
  phoneRef: RefObject<HTMLInputElement | undefined>;

  init(): void;
  setCountries(countries: ICountry[]): void;
  changeCountryPhone(countryCode: string): void;
  changeCountry(countryCode: string): void;
  changePhone(phone: string): void;
  currentCountryName(country: ICountry): string;
  parsePhoneNumber(phoneNumber: string): string;
  validatePhone(value: string, values: FormData, fieldState: FieldState<string>): Promise<boolean>;
  filterCountries(country: ICountry, searchString: string): boolean;
}

interface ICountryPhoneFieldsPresenterParams {
  phoneNumber: string;
  country: string;
  countries: ICountry[];

  onPhoneNumberChange(phoneNumber: string): void;
  onCountryChange(countryCode: string): void;
}

export function useCountryPhoneFieldsPresenter({
  phoneNumber,
  country,
  countries,
  onPhoneNumberChange,
  onCountryChange,
}: ICountryPhoneFieldsPresenterParams): ICountryPhoneFieldsPresenter {
  const { t } = useTranslation();

  const phoneRef = useRef<HTMLInputElement>();

  const formValidations = validations.loginForm();

  const presenter = useLocalStore<ICountryPhoneFieldsPresenter>(() => ({
    countries: {},
    country: null,

    setCountries(countries: ICountry[]): void {
      const sortedCountries = countries.slice().sort(sortCountries);

      presenter.countries = arrayToObjectWithKey(sortedCountries, 'countryCode');
    },

    init(): void {
      presenter.phoneRef.current?.focus();
      if (phoneNumber) {
        const value = presenter.parsePhoneNumber(phoneNumber);
        presenter.changePhone(value);
        return;
      }
    },

    changePhone(phone: string): void {
      onPhoneNumberChange(phone);
    },

    changeCountry(countryCode: string): void {
      const country = presenter.countries[countryCode];

      if (country === presenter.country) {
        return;
      }
      presenter.country = country;
      onCountryChange(countryCode);
    },

    changeCountryPhone(countryCode: string): void {
      presenter.phoneRef.current?.focus();
      presenter.changeCountry(countryCode);

      presenter.changePhone(presenter.country ? presenter.country.phonePrefix : '+');
    },

    filterCountries(country: ICountry, searchString: string): boolean {
      return (
        country.countryName.toLowerCase().startsWith(searchString.toLowerCase()) ||
        country.phonePrefix.toLowerCase().includes(searchString.toLowerCase())
      );
    },

    currentCountryName(country: ICountry): string {
      if (country) {
        return `${country.flag} ${country.countryName}`;
      }

      return t('select_country_heading');
    },

    validatePhone: async (
      value: string,
      values: FormData,
      fieldState: FieldState<string>
    ): Promise<boolean> => {
      if (fieldState.dirty) {
        return formValidations.phoneNumber.validate(value, values, fieldState, {
          phoneTemplate: presenter.country?.phoneTemplate,
        });
      }
      return false;
    },

    parsePhoneNumber(phoneNumber: string): string {
      const number = clearPhoneNumber(phoneNumber);

      const countries = Object.values(presenter.countries).sort(sortCountriesByPriority);

      const country = countries.find(({ phonePrefix }) => `+${number}`.indexOf(phonePrefix) === 0);

      if (country?.countryCode !== presenter.country?.countryCode) {
        presenter.changeCountry(country?.countryCode || '');
      }

      if (presenter.country?.phoneTemplate) {
        return parsePhone(number, presenter.country.phoneTemplate);
      }

      return `+${number}`;
    },

    get phoneRef(): RefObject<HTMLInputElement | undefined> {
      return phoneRef;
    },

    get placeholder(): string {
      const phoneTemplate = presenter.country?.phoneTemplate || DEFAULT_PHONE_MASK;
      return replacePhoneTemplate(phoneTemplate);
    },
  }));

  useEffect(() => {
    presenter.setCountries(countries);
  }, [countries]);

  useEffect(() => {
    if (country) {
      presenter.changeCountry(country);
    }
  }, [country]);

  useEffect(() => {
    presenter.init();
  }, []);

  return presenter;
}
