import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import Tasks from 'APP/Tasks';
import { PayloadType } from 'APP/model/message/messageModel.types';
import logger from 'APP/packages/logger';
import { RouterState, RouterStatePopup } from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';

enum EventType {
  OpenChatByDeepLink = 'openChatByDeepLink',
}

interface IInAppViewContentPresenterParams {
  onLoad(): void;
  goBack(): void;
  onReload(currentUrl: string): Promise<void>;
  url: string;
  isOpen: boolean;
}

interface IInAppViewContentPresenter {
  uuid: string;
  createChatWithSeller(url: string, userId: string): Promise<void>;
  shareMessageTo(message: string): void;
  handleMessage(event: MessageEvent): Promise<void>;
}

export const useInAppViewContentPresenter = (
  options: IInAppViewContentPresenterParams
): IInAppViewContentPresenter => {
  const { onLoad, onReload, url, isOpen, goBack } = options;
  const navigateTo = useNavigateTo();

  const source = useAsObservableSource({ navigateTo, url, isOpen, goBack });

  const presenter = useLocalStore<IInAppViewContentPresenter>(() => ({
    uuid: uuidv4(),
    async createChatWithSeller(url: string, userId: string): Promise<void> {
      try {
        const group = await Tasks.group.createP2PGroup(userId);

        await Tasks.messaging.createNewMessages({
          messages: [
            {
              type: PayloadType.RichText,
              data: { text: url },
              groupId: group?.id,
            },
          ],
        });

        Tasks.group.setActiveGroup({ groupId: group?.id });
      } catch (error) {
        logger.get('MS').error('createChatWithSeller', error);
      }
    },

    shareMessageTo(message): void {
      source.navigateTo({
        state: {
          [RouterState.popup]: RouterStatePopup.shareMessageTo,
          [RouterState.message]: message,
        },
      });
    },

    async handleMessage(event: MessageEvent): Promise<void> {
      const iframe = document.querySelector<HTMLIFrameElement>(
        `iframe[data-id="${presenter.uuid}"]`
      );

      if (iframe?.contentWindow !== event.source) {
        return;
      }

      if (event && Tasks.market.isMarketUrl(event.origin) && source.isOpen) {
        logger.get('Marketplace').log('iframe Event', event.data);
        const { url, userID, title, goToMessenger, hideLoadingScreen, reloadPage, type } =
          event.data || {};

        if (type === EventType.OpenChatByDeepLink) {
          const { deeplink, text } = event.data;
          const group = await Tasks.deeplinks.handleDeepLink({
            link: deeplink,
            navigateTo: source.navigateTo,
          });

          if (group) {
            await Tasks.messaging.createNewMessages({
              messages: [
                {
                  type: PayloadType.RichText,
                  data: { text },
                  groupId: group.id,
                },
              ],
            });
          }
        }

        if (goToMessenger) {
          source.goBack();
        }

        if (hideLoadingScreen) {
          onLoad();
        }

        if (userID && url) {
          presenter.createChatWithSeller(url, userID);
        }

        if (url && !userID) {
          const message = `${title} ${url}`;

          presenter.shareMessageTo(message);
        }

        if (reloadPage) {
          onReload(source.url);
        }
      }
    },
  }));

  useEffect(() => {
    window.addEventListener('message', presenter.handleMessage);
    return (): void => {
      window.removeEventListener('message', presenter.handleMessage);
    };
  }, []);

  return presenter;
};
