import { useLocalStore } from 'mobx-react';

import { moderationModel } from 'APP/model/model';
import { ComplaintType, IUserComplaint } from 'APP/model/moderation/moderationModel.types';
import { useTranslation } from 'APP/packages/translations';
import Entities from 'APP/store';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';

import { IReportFormData } from '../../components/ReportForm/ReportForm.types';

export interface IReportUserPopupParams {
  userId: string;
  groupId?: string;
  messageId?: string;
}

interface IReportUserPresenter {
  hasBackButton: boolean;
  onSubmit(formData: IReportFormData): Promise<void>;
  onClose(): void;
  onBack(): void;
}

export function useReportUserPresenter({
  params,
  popupInstance,
  onClose,
  onBack,
}: ICommonPopupsProps<IReportUserPopupParams>): IReportUserPresenter {
  const { userId, groupId, messageId } = params!;
  const { t } = useTranslation();

  const presenter = useLocalStore<IReportUserPresenter>(() => ({
    get hasBackButton(): boolean {
      return !!onBack;
    },

    async onSubmit(formData: IReportFormData): Promise<void> {
      try {
        await moderationModel.sendComplaint<IUserComplaint>({
          ...formData,
          complaint: {
            complaintType: ComplaintType.User,
            groupId,
            userId,
            messageId,
          },
        });

        Entities.toast.show(t('content_moderation_sent_message'));
      } catch {
        Entities.toast.show(t('something_went_wrong'));
      }

      presenter.onClose();
    },

    onClose(): void {
      popupInstance.close();
      onClose?.();
    },

    onBack(): void {
      popupInstance.close();
      onBack?.();
    },
  }));

  return presenter;
}
