import { REACTION_POPOVER_HIDE_DELAY } from 'APP/constants/animations';
import { DEFAULT_REACTION_ID } from 'APP/model/reactions/reactionsModel.constants';
import { useReactions } from 'MAIN/hooks/useReactions/useReactions';
import { IReactionItem } from 'MAIN/hooks/useReactions/useReactions.types';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';
import { IPopover } from 'UIKIT/Popover/Popover.types';
import { usePopover } from 'UIKIT/Popover/usePopover';

interface IReactionButtonPresenter {
  userReaction: IReactionItem | null;
  onClick(): void;
}

interface IUseReactionButtonPresenter {
  presenter: IReactionButtonPresenter;
  popover: IPopover<HTMLButtonElement>;
}

export const useReactionButtonPresenter = (message: ChatMessage): IUseReactionButtonPresenter => {
  const reactions = useReactions(message);
  const popover = usePopover<HTMLButtonElement>({ mouseOutTimeout: REACTION_POPOVER_HIDE_DELAY });

  const presenter: IReactionButtonPresenter = {
    get userReaction(): IReactionItem | null {
      return reactions.currentMyReaction;
    },

    onClick(): void {
      popover.close();

      if (reactions.currentMyReaction) {
        reactions.onClick(reactions.currentMyReaction.id);
      } else {
        reactions.onClick(DEFAULT_REACTION_ID);
      }
    },
  };

  return { popover, presenter };
};
