import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { showAlert } from 'APP/Tasks/app/app';
import { ServerErrorCode } from 'APP/packages/api';
import { GroupServerErrorCode } from 'APP/packages/api/constants/group.errors';
import { useTranslation } from 'APP/packages/translations';
import Entities from 'APP/store';
import { Group } from 'APP/store/Groups/Group';
import { User } from 'APP/store/Users/User/User';

interface IParticipantSelectList {
  participants: User[];
  participantIds: string[];
  selectedParticipantIds: string[];
  searchContacts: User[];
  searchValue: string;
  isLoading: boolean;
  isSubmitting: boolean;
  isAddButtonDisabled: boolean;
  buttonText: string;

  loadParticipants(): Promise<void>;
  setSelectedIds(ids: string[]): void;
  addParticipants(): Promise<void>;
  setSearchValue(searchValue: string): void;
}

const knownError: Record<string, string> = {
  [GroupServerErrorCode.InvitationsTooManyToAdd]: 'common_group_alert_user_many_add',

  [ServerErrorCode.HasNoPermissionsToPerformAction]: 'no_permission_alert',
  [GroupServerErrorCode.GroupDoesNotExist]: 'common_chat_is_not_accessible',
  [GroupServerErrorCode.UserIsBannedInTheGroup]: 'common_group_alert_user_ban',
  [GroupServerErrorCode.UserGroupRelationshipDoesNotExist]:
    'common_group_alert_relationship_not_exit',
  [ServerErrorCode.SpaceDoesNotExist]: 'common_group_alert_space_not_exit',
  [ServerErrorCode.TooManyInvitedMembersInShortTime]: 'common_invitation_daily_limit_alert',
};

export interface IParticipantSelectListPresenterParams {
  group: Group;
  onSuccess?(): void;
}

export const useParticipantSelectList = ({
  group,
  onSuccess,
}: IParticipantSelectListPresenterParams): IParticipantSelectList => {
  const { t } = useTranslation();

  const source = useAsObservableSource({ group });

  const presenter = useLocalStore<IParticipantSelectList>(() => ({
    participantIds: [],
    selectedParticipantIds: [],
    searchValue: '',
    isLoading: true,
    isSubmitting: false,

    get buttonText(): string {
      return source.group.isChannel ? t('common_invite') : t('add_button');
    },

    get isAddButtonDisabled(): boolean {
      return presenter.selectedParticipantIds.length === 0;
    },

    get participants(): User[] {
      return presenter.participantIds
        .map((id) => Entities.UsersStore.getUserById(id))
        .filter((user) => user && !user.isBlocked);
    },

    get searchContacts(): User[] {
      const searchValue = presenter.searchValue.trim().toLowerCase();
      if (!searchValue) {
        return [];
      }

      return presenter.participants.filter(
        (user) =>
          user.userName.toLowerCase().includes(searchValue) ||
          user.displayNickname.toLowerCase().includes(searchValue) ||
          user.contactName?.toLowerCase().includes(searchValue) ||
          user.displayPhone?.toLowerCase().includes(searchValue)
      );
    },

    async loadParticipants(): Promise<void> {
      presenter.participantIds = await Tasks.group.getFriendsNotInvitedToGroup({
        groupId: source.group.id,
      });

      presenter.isLoading = false;
    },

    setSelectedIds: (ids: string[]): void => {
      presenter.selectedParticipantIds = ids;
    },

    setSearchValue: (searchValue: string): void => {
      presenter.searchValue = searchValue;
    },

    addParticipants: async (): Promise<void> => {
      presenter.isSubmitting = true;

      const { error } = await Tasks.group.addParticipants(
        source.group.id,
        presenter.selectedParticipantIds
      );

      presenter.isSubmitting = false;

      if (error) {
        showAlert(t(knownError[error] || 'common_somethings_wrong_error'));
        return;
      }

      onSuccess?.();
    },
  }));

  useEffect(() => {
    presenter.loadParticipants();
  }, []);

  return presenter;
};
