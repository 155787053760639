import { useLocalStore } from 'mobx-react';

import Entities from 'APP/store';
import { User } from 'STORE/Users/User/User';

interface IGroupFormGeneralMembersPresenterParams {
  membersIds: string[];
}

interface IGroupFormGeneralMembersPresenter {
  members: User[];
  keyExtractor(contact: User): string;
}

export const useGroupFormGeneralMembersPresenter = (
  data: IGroupFormGeneralMembersPresenterParams
): IGroupFormGeneralMembersPresenter => {
  const { membersIds } = data;

  const presenter = useLocalStore<IGroupFormGeneralMembersPresenter>(() => ({
    get members(): User[] {
      return membersIds.map((id) => Entities.UsersStore.getUserById(id));
    },

    keyExtractor(contact: User): string {
      return contact.id;
    },
  }));

  return presenter;
};
