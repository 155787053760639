import { RouterState } from 'APP/router/constants';
import { replaceTo } from 'APP/router/refs/replaceTo';
import { IMediaGalleryState } from 'APP/types/mediaGallery';

export const openMediaGallery = (mediaState: IMediaGalleryState): void => {
  replaceTo({
    state: {
      [RouterState.mediaGallery]: {
        groupId: mediaState.groupId,
        mediaId: mediaState.mediaId,
        messageId: mediaState.messageId,
        messageIds: mediaState.messageIds || [],
        reverse: mediaState.reverse !== undefined ? mediaState.reverse : true,
        showMessageInfo:
          mediaState.showMessageInfo !== undefined ? mediaState.showMessageInfo : true,
      },
    },
    keepOldQuery: true,
    keepOldState: true,
    keepOldPathname: true,
  });
};
