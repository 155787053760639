import Tasks from 'APP/Tasks';
import bus from 'APP/packages/bus';

import { GroupsEvent } from '../GroupsEvent';

export class ParticipantsWereUpdated extends GroupsEvent {
  process() {
    bus.group.broadcastParticipantsChanges(this.groupId);

    const payload = this.eventPayload;
    Tasks.group.updateUserGroupRole(this.groupId, payload.roleChanges || {});
  }
}
