import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { IconView } from 'APP/packages/UiKit/IconView/IconView';
import { AddKeyPressHandler } from 'APP/packages/keyBoardHandler';
import { useTranslation } from 'APP/packages/translations';
import ViewsIcon from 'ICONS/ic-view-16.svg';
import { Button } from 'UIKIT/Button/Button';
import { FullscreenPopup } from 'UIKIT/FullscreenPopup/FullscreenPopup';

import NoVideo from '../../SharedComponents/NoVideo';
import SharedScreen from '../../SharedComponents/SharedScreen';
import StreamerMeta from '../../SharedComponents/StreamerMeta';
import Streamers from '../../SharedComponents/Streamers';
import Video from '../../SharedComponents/Video';
import ControlPanel from './ControlPanel';
import Header from './Header';
import Viewers from './Viewers';
import styles from './styles.m.css';

const FullScreenView = ({ presenter, streamerPresenter, isFinished }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (isFinished) {
      presenter.leave();
    }
  }, [isFinished]);

  return (
    <FullscreenPopup className={styles.fullScreen}>
      <AddKeyPressHandler onEsc={presenter.collapse} />
      <div className={styles.container}>
        <div className={styles.main}>
          {presenter.isStreamStarted && (
            <Streamers
              className={styles.streamers}
              onSetActiveStreamer={streamerPresenter.setActiveStreamer}
            />
          )}
          <div className={styles.speaker}>
            {presenter.primaryVideo ? (
              streamerPresenter.isSharedScreen && presenter.stream.activeStreamer.isSharedScreen ? (
                <SharedScreen />
              ) : (
                <Video videoTrack={presenter.primaryVideo} />
              )
            ) : (
              <NoVideo />
            )}
            {presenter.isStreamStarted && streamerPresenter.activeStreamer.streamer && (
              <StreamerMeta
                className={styles.meta}
                streamer={streamerPresenter.activeStreamer.streamer}
                isMinimize={false}
              />
            )}
          </div>
          {presenter.isStarted && <div className={styles.started}>{t('stream_you_are_live')}</div>}
          {presenter.hasShowControl && <Header presenter={presenter} />}
          {presenter.hasShowControl ? (
            <ControlPanel
              isCommentsHidden={!presenter.canLeaveComment}
              presenter={presenter}
              streamerPresenter={streamerPresenter}
            />
          ) : null}
          {presenter.isWaiting && (
            <div className={styles.waiting}>
              <div>3</div>
              <div>2</div>
              <div>1</div>
            </div>
          )}
          {presenter.isConnecting && (
            <div className={styles.connecting}>
              {t('stream_checking_connection')}
              <Button
                onClick={presenter.cancel}
                size="20"
                theme="11"
                className={styles.button}
              >
                {t('common_cancel')}
              </Button>
            </div>
          )}
          {presenter.isCanceled && (
            <div className={styles.connecting}>
              {t('stream_canceled')}
              <Button
                onClick={presenter.reset}
                size="20"
                theme="11"
                className={styles.button}
              >
                {t('common_ok')}
              </Button>
            </div>
          )}
          {presenter.isStreamFinished && !presenter.isWillBePublished && (
            <div className={styles.finished}>
              <div className={styles.finishTitle}>{t('stream_finished_title')}</div>
              <div className={styles.finishCaption}>
                <IconView
                  Icon={ViewsIcon}
                  className={styles.finishIcon}
                  size={'small'}
                />{' '}
                {t('stream_viewers').replace('{0}', presenter.totalViewers)}
              </div>
              {presenter.isWaitForAction ? (
                <div className={styles.finishActions}>
                  <Button
                    className={styles.publishButton}
                    onClick={presenter.publish}
                    size="20"
                    theme="1"
                  >
                    {t('stream_publish_stream_button')}
                  </Button>
                  <Button
                    onClick={presenter.leaveWithoutSaving}
                    size="20"
                    theme="11"
                  >
                    {t('stream_leave_button')}
                  </Button>
                </div>
              ) : (
                <Button
                  className={styles.closeButton}
                  onClick={presenter.onClose}
                  size="20"
                  theme="11"
                >
                  {t('common_ok')}
                </Button>
              )}
            </div>
          )}
          {presenter.isWillBePublished && (
            <div className={styles.published}>
              <div className={styles.publishedTitle}>{t('stream_over_title')}</div>
              <p className={styles.publishedDescription}>{t('stream_over_description')}</p>
              <Button
                onClick={presenter.onClose}
                size="20"
                theme="11"
              >
                {t('common_ok')}
              </Button>
            </div>
          )}
        </div>
        {presenter.isShowViewers && <Viewers onClose={presenter.toggleViewers} />}
      </div>
    </FullscreenPopup>
  );
};

export default observer(FullScreenView);
