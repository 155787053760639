import classNames from 'classnames';
import React from 'react';

import CirclePlaceholder from 'APP/packages/placeholders/CirclePlaceholder';
import { TextPlaceholder } from 'APP/packages/placeholders/TextPlaceholder/TextPlaceholder';

import commonStyles from '../SessionsItemDetailsPopup.styles.m.css';
import styles from './styles.m.css';

export const SessionItemDetailsPlaceholder = () => {
  return (
    <>
      <div className={commonStyles.header}>
        <CirclePlaceholder
          size="56px"
          className={styles.avatar}
        />
        <TextPlaceholder
          className={classNames(commonStyles.deviceName, styles.gap)}
          width={150}
        />
        <TextPlaceholder
          className={commonStyles.lastOnline}
          width={120}
        />
      </div>
      <div className={commonStyles.body}>
        <TextPlaceholder
          width={80}
          className={classNames(commonStyles.itemLabel, styles.gap)}
        />
        <TextPlaceholder
          width={100}
          className={commonStyles.itemValue}
        />

        <TextPlaceholder
          width={80}
          className={classNames(commonStyles.itemLabel, styles.gap)}
        />
        <TextPlaceholder
          width={100}
          className={commonStyles.itemValue}
        />

        <TextPlaceholder
          width={80}
          className={classNames(commonStyles.itemLabel, styles.gap)}
        />
        <TextPlaceholder
          width={100}
          className={commonStyles.itemValue}
        />
      </div>
    </>
  );
};

export default SessionItemDetailsPlaceholder;
