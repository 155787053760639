import React, { FC, useRef } from 'react';

import Slider from 'APP/components/AudioPlayer/Slider';
import { AudioState } from 'MAIN/components/AudioState/AudioState';
import { AudioUploadState } from 'MAIN/components/AudioUploadState/AudioUploadState';
import { FileName } from 'UIKIT/FileName/FileName';
import { AudioPlayerTrackCaption } from 'UIKIT/Media/AudioPlayer/AudioPlayerUI/AudioPlayerTrackCaption/AudioPlayerTrackCaption';
import { PlayIconType } from 'UIKIT/PlayIcon/PlayIcon';

import { useArticleRedactorAudioPlayerPresenter } from './ArticleRedactorAudioPlayer.presenter';
import styles from './ArticleRedactorAudioPlayer.styles.m.css';

interface IArticleRedactorAudioPlayerProps {
  src: string;
  fileName: string;
  fileSize: number;
  payloadId: string;
  uploadProgress: number | null;
  cancelUploadAndDeleteMessage(): Promise<void>;
}

export const ArticleRedactorAudioPlayer: FC<IArticleRedactorAudioPlayerProps> = ({
  src,
  fileName,
  fileSize,
  payloadId,
  uploadProgress,
  cancelUploadAndDeleteMessage,
}) => {
  const audioRef = useRef<HTMLAudioElement>(null);

  const { player, controls } = useArticleRedactorAudioPlayerPresenter(audioRef);

  return (
    <div className={styles.container}>
      <div className={styles.player}>
        {uploadProgress !== null ? (
          <AudioUploadState
            uploadFileData={{
              uploadProgress,
              cancelUpload: cancelUploadAndDeleteMessage,
            }}
            iconType={player.isPaused ? PlayIconType.Play : PlayIconType.Pause}
            onClick={player.isPaused ? controls.handlePlay : controls.handlePause}
          />
        ) : (
          <AudioState
            payloadId={payloadId}
            iconType={player.isPaused ? PlayIconType.Play : PlayIconType.Pause}
            onClick={player.isPaused ? controls.handlePlay : controls.handlePause}
          />
        )}
      </div>
      <div className={styles.controlsContainer}>
        <div className={styles.description}>
          <FileName
            className={styles.title}
            name={fileName}
          />
          <AudioPlayerTrackCaption
            isPlaying={!player.isPaused}
            duration={player.duration}
            progress={player.currentTime}
            size={fileSize}
          />
        </div>
        <div className={styles.sliderContainer}>
          <Slider
            currentTime={player.currentTime}
            duration={player.duration}
            canPlay={player.canPlay}
            changeTime={controls.onChangeTime}
            step={player.step}
          />
        </div>
      </div>
      <audio
        src={src}
        ref={audioRef}
        onCanPlay={controls.onCanPlay}
        onTimeUpdate={controls.onTimeUpdate}
        onEnded={controls.onEnded}
      />
    </div>
  );
};
