import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useRef } from 'react';

import { List } from 'APP/components/List/List';
import { GroupsPlaceholder } from 'APP/packages/placeholders/GroupsPlaceholder/GroupsPlaceholder';
import { useTranslation } from 'APP/packages/translations';
import CloseIcon from 'ICONS/ic-close-s.svg';
import { IconButton } from 'UIKIT/IconButton';
import { SearchInput } from 'UIKIT/SearchInput/SearchInput';

import { EmptyResult } from './EmptyResult/EmptyResult';
import Message from './Message';
import { useSearchMessagesPresenter } from './SearchMessages.presenter';
import styles from './SearchMessages.styles.m.css';

export const SearchMessages = observer(() => {
  const listRef = useRef();
  const presenter = useSearchMessagesPresenter({ listRef });
  const { t } = useTranslation();

  useEffect(() => {
    presenter.reset();
  }, [presenter.group]);

  const renderItem = useCallback((message) => {
    return (
      <Message
        onClick={presenter.goToMessage}
        message={message}
      />
    );
  }, []);

  if (!presenter.isShow) {
    return null;
  }
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>{presenter.title}</div>
        <IconButton
          Icon={CloseIcon}
          onClick={presenter.close}
          size="big"
        />
      </div>
      <SearchInput
        className={styles.search}
        hasSearchIcon={false}
        onChange={presenter.onChange}
        value={presenter.value}
        placeholder={t('main_menu_search_note')}
        autoFocus={true}
      />
      <div
        ref={listRef}
        className={styles.list}
      >
        <List
          isScrollable
          isLoading={presenter.isLoading || presenter.isIndexing}
          className={styles.results}
          hasMore={presenter.hasMore}
          data={presenter.results}
          keyExtractor={presenter.keyExtractor}
          onEndReached={presenter.getNextCursor}
          renderItem={renderItem}
          ListEmptyComponent={<EmptyResult message={presenter.noResultsMessage} />}
          SpinnerComponent={
            <GroupsPlaceholder
              count={presenter.getCountPlaceholders()}
              className={styles.placeholders}
            />
          }
        />
      </div>
    </div>
  );
});
