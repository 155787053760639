import React, { FC, memo, ComponentProps } from 'react';

export interface IIconCurrentReactionProps extends ComponentProps<'img'> {
  src: string;
}

export const IconCurrentReaction: FC<IIconCurrentReactionProps> = memo(({ src, ...props }) => {
  return (
    <img
      src={src}
      alt=""
      {...props}
    />
  );
});
