import { observer } from 'mobx-react';
import React from 'react';

import { platformInfo } from 'APP/packages/platformInfo/platformInfo';
import { FullscreenPopup } from 'UIKIT/FullscreenPopup/FullscreenPopup';
import { Portal } from 'UIKIT/Portal/Portal';

import { CompactCallView } from '../CompactCallView/CompactCallView';
import { FullScreenCallView } from '../FullScreenCallView/FullScreenCallView';
import { MinimizedCallView } from '../MinimizedCallView/MinimizedCallView';
import { MobileCompactCallView } from '../MobileCompactCallView/MobileCompactCallView';
import { useCallViewPresenter } from './CallView.presenter';

export const CallView = observer(() => {
  const presenter = useCallViewPresenter();

  if (platformInfo.isMobile) {
    return <MobileCompactCallView />;
  }

  if (presenter.ongoingCall.mode.isMinimized) {
    return (
      <Portal>
        <MinimizedCallView />
      </Portal>
    );
  }

  if (presenter.ongoingCall.mode.isFullScreen) {
    return (
      <FullscreenPopup>
        <FullScreenCallView />
      </FullscreenPopup>
    );
  }

  if (presenter.ongoingCall.mode.isCompact) {
    return <CompactCallView />;
  }

  return null;
});
