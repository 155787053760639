/**
 * Rest API
 * Documentation Rest API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * system.call.finished
 */
export interface CallFinishedResponseData { 
    payloadType: string;
    initiator: string;
    initiatorNickName?: string;
    channelId: string;
    duration: string;
    reason: CallFinishedResponseDataReasonEnum;
    callType: CallFinishedResponseDataCallTypeEnum;
    finishTs: string;
    finishInitiator?: string;
    finishInitiatorNickname?: string;
}
export enum CallFinishedResponseDataReasonEnum {
    Unanswered = 'UNANSWERED',
    Declined = 'DECLINED',
    Finished = 'FINISHED'
};
export enum CallFinishedResponseDataCallTypeEnum {
    P2P = 'P2P',
    Group = 'GROUP',
    Conference = 'CONFERENCE',
    Stream = 'STREAM'
};



