import React from 'react';

import { INACTIVE_PERIODS, MAP_PERIOD_TO_LABEL } from 'APP/constants';
import { TextPlaceholder } from 'APP/packages/placeholders/TextPlaceholder/TextPlaceholder';
import { useTranslation } from 'APP/packages/translations';

import styles from './styles.m.css';

const UserSessionsSelDestructLink = ({ sessionTtl, isLoading, onClick }) => {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <div className={styles.linkContainer}>
        <TextPlaceholder width={200} />
        <TextPlaceholder
          width={80}
          className={styles.valueSkeleton}
        />
      </div>
    );
  }

  return (
    <button
      className={styles.linkContainer}
      onClick={onClick}
    >
      {t('common_if_inactive_for')}{' '}
      <span className={styles.timePeriod}>
        {t(MAP_PERIOD_TO_LABEL[INACTIVE_PERIODS[sessionTtl]])}
      </span>
    </button>
  );
};

export default UserSessionsSelDestructLink;
