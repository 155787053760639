import { observer } from 'mobx-react';
import React, { FC, useCallback } from 'react';

import { List } from 'APP/components/List/List';

import { MemberCard } from './MemberCard/MemberCard';
import { useMemberListPresenter } from './MemberList.presenter';
import styles from './MemberList.styles.m.css';

export const MemberList: FC = observer(() => {
  const presenter = useMemberListPresenter();

  const renderItem = useCallback((member) => <MemberCard member={member} />, []);

  return (
    <div className={styles.container}>
      <List
        data={presenter.members}
        keyExtractor={presenter.keyExtractor}
        renderItem={renderItem}
      />
    </div>
  );
});
