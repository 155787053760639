import React, { memo } from 'react';

import styles from '../styles.m.scss';

function SelectedText({ before, select, after }) {
  return (
    <>
      {before}
      {select.length > 0 && <span className={styles.selection}>{select}</span>}
      {after && SelectedText(after)}
    </>
  );
}

export default memo(SelectedText);
