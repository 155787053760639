import React from 'react';

import ErrorImg from 'APP/images/gem-mascot-under-maintenance.svg';
import { useTranslation } from 'APP/packages/translations';
import BackIcon from 'ICONS/ic-back-32.svg';
import { THubPage, HubPageType } from 'MAIN/Hub/Hub.types';
import { FAB } from 'UIKIT//FAB/FAB';
import { InfoBlock, InfoBlockTypes } from 'UIKIT/InfoBlock/InfoBlock';

import styles from './HubEmptyScreen.styles.m.css';

export const HubEmptyScreen: THubPage = ({ setPage }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <FAB
          size={'30'}
          Icon={BackIcon}
          theme={'3'}
          onClick={() => setPage(HubPageType.Main)}
        />
      </div>
      <div className={styles.content}>
        <InfoBlock
          title={t('coming_soon')}
          descriptions={[t('сoming_soon_description')]}
          image={ErrorImg}
          type={InfoBlockTypes.Page}
        />
      </div>
    </div>
  );
};
