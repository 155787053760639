import { observer } from 'mobx-react';
import React from 'react';

import { AutoplayingLoopAudio } from 'APP/components/AutoplayingLoopAudio/AutoplayingLoopAudio';
import { CallType } from 'APP/model/call/callModel.types';
import { IconButton } from 'APP/packages/UiKit/IconButton';
import { AddKeyPressHandler } from 'APP/packages/keyBoardHandler';
import { useTranslation } from 'APP/packages/translations';
import CallIcon from 'ICONS/ic-call-32.svg';
import DeclineCallIcon from 'ICONS/ic-cancel-call.svg';
import VideoCallIcon from 'ICONS/ic-video-on-32.svg';
import { LargeCallAvatar } from 'MAIN/OngoingCall/Shared/Avatars/LargeCallAvatar/LargeCallAvatar';
import callingSound from 'ROOT/www/sound/ringtone.mp3';
import { OverlayingPopup } from 'UIKIT/OverlayingPopup/OverlayingPopup';

import usePresenter from './presenter';
import styles from './styles.m.scss';

const callLabel = {
  [CallType.P2P]: 'calls_incoming_call_status',
  [CallType.Group]: 'calls_group_call',
  [CallType.Conference]: 'calls_conference_call',
};

const IncomingCall = () => {
  const { IncomingCall } = usePresenter();
  const { t } = useTranslation();

  if (!IncomingCall.hasCall) {
    return null;
  }

  return (
    <OverlayingPopup
      popupClassName={styles.incomingCallContainer}
      isOpened
    >
      <AddKeyPressHandler onEsc={IncomingCall.decline} />
      <LargeCallAvatar avatar={IncomingCall.avatar} />

      <div className={styles.groupTitle}>{IncomingCall.callTitle}</div>
      <div className={styles.callingState}>{t(callLabel[IncomingCall.call.callType])}</div>

      <div className={styles.actionsPanel}>
        <IconButton
          size={'xlarge'}
          theme={9}
          Icon={DeclineCallIcon}
          className={styles.button}
          onClick={IncomingCall.decline}
        />
        {IncomingCall.isAvailableJoinCallWithVideo && (
          <IconButton
            size={'xlarge'}
            theme={10}
            Icon={VideoCallIcon}
            className={styles.button}
            onClick={IncomingCall.joinToCallWithVideo}
          />
        )}
        <IconButton
          size={'xlarge'}
          theme={10}
          Icon={CallIcon}
          className={styles.button}
          onClick={IncomingCall.joinToCall}
        />
      </div>
      <AutoplayingLoopAudio
        src={callingSound}
        permissionText={t('calls_hear_incoming_call')}
      />
    </OverlayingPopup>
  );
};

export default observer(IncomingCall);
