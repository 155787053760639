import Tasks from 'APP/Tasks';
import { getMessages } from 'APP/model/message/messageModel';
import { DEFAULT_LIMIT_OF_MESSAGES_LOADING } from 'APP/model/message/messageModel.constants';
import logger from 'APP/packages/logger';
import Entities from 'APP/store';

export async function loadMessagesBelowTheTime(
  groupId: string,
  startTs: number,
  hasLoader = true,
  limit: number = DEFAULT_LIMIT_OF_MESSAGES_LOADING
): Promise<void> {
  const group = Entities.GroupsStore.getGroupById(groupId);
  if (!group || group.isFake) {
    return;
  }

  if (hasLoader) {
    group.messagesStore.setIsLoadingMessages(true);
  }
  try {
    const { messages, users, groups } = await getMessages(group.id, startTs - 1, limit);

    Entities.UsersStore.add(users);
    Entities.GroupsStore.merge(groups);

    await Tasks.relationships.loadUnknownDataFromMessages({ messages });
    Tasks.messaging.handleIsLoadedLastMessages({ groupId, messages });

    group.messagesStore.setLoadedLastMessages(messages.length < limit);

    Tasks.messaging.updateOldOrFakeMessages({ messages, groupId, prevMessageTs: startTs });
  } catch (e) {
    logger.get('TASK').error('messaging.loadMessagesBelowTheTime', e);
  }

  if (hasLoader) {
    group.messagesStore.setIsLoadingMessages(false);
  }
}
