import { useLocalStore } from 'mobx-react';

import Entities from 'APP/store';
import { Group } from 'STORE/Groups/Group';
import { InputPanel } from 'STORE/ViewModels/InputPanel';

interface IInputPanelPresenter {
  inputPanel: InputPanel;
  group: Group | null;
  hasBotCommands: boolean;
}

export function useInputPanelPresenter(): IInputPanelPresenter {
  const presenter = useLocalStore<IInputPanelPresenter>(() => ({
    get inputPanel(): InputPanel {
      return Entities.InputPanel;
    },

    get group(): Group | null {
      return Entities.GroupsStore.activeGroup;
    },

    get hasBotCommands(): boolean {
      return Entities.InputPanelsStore.activeInputPanel?.bots.hasCommands || false;
    },
  }));

  return presenter;
}
