import classNames from 'classnames';
import React, { FC, useRef } from 'react';

import { Sticker } from 'APP/components/Sticker/Sticker';
import { STICKER_POPUP_MAX_HEIGHT, STICKER_POPUP_MAX_WIDTH } from 'APP/constants/stickers';
import { ISticker } from 'APP/model/stickers/stickersModel.types';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';

import styles from './Stickers.styles.m.scss';

interface IStickersProps {
  stickers: ISticker[];
  stickerPackId: string;
  onClick: (sticker: ISticker) => void;
}

export const Stickers: FC<IStickersProps> = ({ stickerPackId, stickers, onClick }) => {
  const root = useRef<HTMLDivElement>(null);

  return (
    <div
      className={classNames(styles.container, customScrollStyles.container)}
      ref={root}
    >
      {stickers.map((sticker) => (
        <Sticker
          key={sticker.id}
          sticker={sticker}
          displayUILayer={1}
          maxWidth={STICKER_POPUP_MAX_WIDTH}
          maxHeight={STICKER_POPUP_MAX_HEIGHT}
          stickerPackId={stickerPackId}
          root={root.current}
          onClick={onClick}
        />
      ))}
    </div>
  );
};
