import { useAsObservableSource, useLocalStore } from 'mobx-react';

import { IForwardTargetData, ISharedDataViewItemData } from '../../ShareDataView.types';
import { useSharedDataViewCommonPresenter } from '../../presenters/useSharedDataViewCommonPresenter';

interface ISharedDataViewTabChatsPresenterParams {
  selection: ISharedDataViewItemData[];
  searchValue: string;
  onClickItem(item: ISharedDataViewItemData): void;
}

interface ISharedDataViewTabChatsPresenter {
  content: IForwardTargetData[];
  infoBlockTitle: string;
  infoBlockDescriptions: string[];
  keyExtractor(item: IForwardTargetData): string;
}

export const useSharedDataViewTabChatsPresenter = (
  data: ISharedDataViewTabChatsPresenterParams
): ISharedDataViewTabChatsPresenter => {
  const { searchValue, selection, onClickItem } = data;

  const commonPresenter = useSharedDataViewCommonPresenter(searchValue);

  const source = useAsObservableSource({ searchValue, selection, onClickItem });

  const presenter = useLocalStore<ISharedDataViewTabChatsPresenter>(() => ({
    get content(): IForwardTargetData[] {
      return [...commonPresenter.chatsItems, ...commonPresenter.contactsItems].map((sharedItem) => {
        const isSelected = source.selection.some((it) => sharedItem.itemId === it.itemId);

        return {
          data: sharedItem,
          isSelected,
          onClick: () => source.onClickItem(sharedItem),
        };
      });
    },

    get infoBlockTitle(): string {
      return commonPresenter.infoBlockTitle;
    },

    get infoBlockDescriptions(): string[] {
      return commonPresenter.infoBlockDescriptions;
    },

    keyExtractor(item: IForwardTargetData): string {
      return commonPresenter.keyExtractor(item);
    },
  }));

  return presenter;
};
