import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';

import { IStoryImage, IStoryComponent } from '../../Stories.types';
import { StorySimpleTimer } from './StorySimpleTimer';

interface IStorySimpleTimerPresenterOptions {
  story: IStoryImage | IStoryComponent;
  onProgress(progress: number): void;
}

export interface IStorySimpleTimePresenter {
  onMouseDown(): void;
}

export function useStorySimpleTimePresenter({
  story,
  onProgress,
}: IStorySimpleTimerPresenterOptions): IStorySimpleTimePresenter {
  const unsubscribeFunctionsRef = useRef<() => void>();
  const timerRef = useRef(new StorySimpleTimer());

  const onMouseUp = useCallback(() => {
    timerRef.current.resume();

    unsubscribeFunctionsRef.current?.();
  }, []);

  const onMouseDown = useCallback(() => {
    timerRef.current.pause();

    document.addEventListener('mouseup', onMouseUp);

    unsubscribeFunctionsRef.current = () => {
      document.removeEventListener('mouseup', onMouseUp);
    };
  }, []);

  useLayoutEffect(() => {
    timerRef.current.stop();
    timerRef.current.init(story.duration, onProgress);
    timerRef.current.start();

    return () => {
      timerRef.current.stop();
      unsubscribeFunctionsRef.current?.();
    };
  }, [story, onProgress]);

  useEffect(() => {
    return () => {
      timerRef.current.stop();
      unsubscribeFunctionsRef.current?.();
    };
  }, []);

  return { onMouseDown };
}
