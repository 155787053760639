import { Group } from 'STORE/Groups/Group';
import { User } from 'STORE/Users/User/User';

import { checkSearchString } from './checkSearchString';

export const checkUserToPrivacy = (user: User): boolean => {
  if (user.isBlockedMe) {
    return false;
  }

  if (!user.group && (!user.isInPhoneBook || !user.privacyStatus.searchMe)) {
    return false;
  }

  return true;
};

export const checkUserAndGroupsDuplicates = (user: User, groups: Group[]): boolean => {
  if (!user.group) {
    return true;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore // TODO: remove when User store refactored on TypeScript
  if (groups.some((group) => group.id === user.group?.id)) {
    return false;
  }

  return true;
};

export const checkSearchUser = (user: User, searchText: string, searchGroups: Group[]): boolean => {
  return (
    !user.isMe &&
    !user.isBot &&
    (checkSearchString(user.contactName || '', searchText) ||
      checkSearchString(user.userName, searchText) ||
      checkSearchString(user.displayNickname, searchText) ||
      checkSearchString(user.displayPhone, searchText)) &&
    checkUserAndGroupsDuplicates(user, searchGroups) &&
    checkUserToPrivacy(user)
  );
};
