import { useLocalStore, useAsObservableSource } from 'mobx-react';

import { useTranslation } from 'APP/packages/translations';
import CalendarIcon from 'ICONS/ic-calendar.svg';
import SendIcon from 'ICONS/ic-publish.svg';

export default ({ parent }) => {
  const { t } = useTranslation();
  const source = useAsObservableSource({ parent });

  const presenter = useLocalStore(() => ({
    get menuItems() {
      return [
        {
          key: 'send',
          icon: SendIcon,
          title: t('common:common_publish_now'),
          onClick: source.parent.onSend,
          isVisible: true,
        },
        {
          key: 'scheduled',
          icon: CalendarIcon,
          title: t('common:common_publish_on_date'),
          onClick: source.parent.onScheduled,
          isVisible: true,
        },
      ];
    },
  }));

  return presenter;
};
