import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import { useClosePopupOnNavigation } from 'MAIN/PopupManager/hooks/useClosePopupOnNavigation';

import { ScheduledCallViewInfo } from './ScheduledCallViewInfo/ScheduledCallViewInfo';
import {
  useScheduledCallViewPopupPresenter,
  IScheduledCallViewPopupParams,
} from './ScheduledCallViewPopup.presenter';
import styles from './ScheduledCallViewPopup.styles.m.css';
import { ScheduledCallsInviteesList } from './ScheduledCallsInviteesList/ScheduledCallsInviteesList';

export const ScheduledCallViewPopup: FC<ICommonPopupsProps<IScheduledCallViewPopupParams>> =
  observer((props) => {
    const presenter = useScheduledCallViewPopupPresenter(props);
    useClosePopupOnNavigation(props.popupInstance);

    return (
      <Popup
        isShowHeader
        isOpened
        handlePrevStep={presenter.hasBackButton ? presenter.onBack : null}
        onClose={presenter.onClose}
      >
        <div className={styles.container}>
          {presenter.commonScheduledEvent && (
            <ScheduledCallViewInfo
              onStartClick={presenter.onClose}
              onCancel={presenter.hasBackButton ? presenter.onBack : presenter.onClose}
              scheduledEvent={presenter.commonScheduledEvent}
            />
          )}
          <div className={styles.divider} />
          <ScheduledCallsInviteesList
            scheduledEventDetails={presenter.scheduledEventDetails}
            isLoading={presenter.isLoading}
          />
        </div>
      </Popup>
    );
  });
