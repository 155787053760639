import Tasks from 'APP/Tasks';
import { CallEventTypes } from 'APP/constants/calls';
import logger from 'APP/packages/logger';
import Entities from 'STORE';

export const toggleHand = async (): Promise<void> => {
  const call = Entities.Calls.ongoingCall;
  const me = call?.me;
  const channelId = call?.channelId;

  if (!channelId || !me || me.toggleHandStarted) {
    return;
  }

  try {
    me.setToggleHandStarted(true);
    await Tasks.calls.setActionCall({
      action: me.isRaiseHand ? CallEventTypes.PutHand : CallEventTypes.RaiseHand,
      channelId,
      userId: me.id,
    });

    me.toggleHand();
  } catch (e) {
    logger.get('TASKS').error('calls.toggleHand', e);
  }
  me.setToggleHandStarted(false);
};
