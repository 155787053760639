import { Group } from 'STORE/Groups/Group';
import { Space } from 'STORE/Spaces/Space';
import { User } from 'STORE/Users/User/User';

export function filterUsersDuplicates(
  globalUsers: User[],
  localUsers: User[],
  localGroups: Group[]
): User[] {
  return globalUsers.filter((globalUser) => {
    const isDuplicateUser = localUsers.some((localUser) => globalUser.id === localUser.id);

    if (isDuplicateUser) {
      return false;
    }

    const isDuplicateGroupOpponent = localGroups.some(
      (localGroup) => globalUser.id === localGroup.opponentId
    );

    if (isDuplicateGroupOpponent) {
      return false;
    }

    return true;
  });
}

export function filterGroupsDuplicates(globalGroups: Group[], localGroups: Group[]): Group[] {
  return globalGroups.filter(
    (globalGroup) => !localGroups.some((localGroup) => globalGroup.id === localGroup.id)
  );
}

export function filterSpacesDuplicates(globalSpaces: Space[], localSpaces: Space[]): Space[] {
  return globalSpaces.filter(
    (globalSpace) => !localSpaces.some((localSpace) => globalSpace.id === localSpace.id)
  );
}
