import { CIRCLE_VIDEO_PREVIEW_MAX_SIZE } from 'APP/constants/messages';
import { IGraphicSize } from 'APP/model/common/commonModel.types';
import { PayloadType } from 'APP/model/message/messageModel.types';
import { ICircleVideoPayloadData } from 'APP/model/message/messageModel.types';
import { getFixedSizeBlurredImage } from 'APP/packages/imageResizer';
import { t } from 'MODULE/i18n/service';

import File from '../File';

export class CircleVideo extends File {
  graphicSize: IGraphicSize;
  previewUrl: string;
  duration: number;

  constructor(data: ICircleVideoPayloadData) {
    super(data);

    this.graphicSize = data.graphicSize || {};
    this.previewUrl = data.previewUrl;
    this.duration = data.duration;
  }

  // Todo Remove when the file payload will be refactored
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  toJSON(): ICircleVideoPayloadData {
    return {
      payloadType: PayloadType.CircleVideo,
      fileName: this.fileName,
      fileSize: this.fileSize,
      url: this.url,
      mimeType: this.mimeType,
      graphicSize: this.graphicSize,
      previewUrl: this.previewUrl,
      duration: this.duration,
    };
  }

  get previewSize(): IGraphicSize {
    const { width, height } = this.graphicSize;

    if (!width || !height) {
      return { width, height };
    }

    return File.calculatePreviewSize(
      width,
      height,
      CIRCLE_VIDEO_PREVIEW_MAX_SIZE.width,
      CIRCLE_VIDEO_PREVIEW_MAX_SIZE.height
    );
  }

  get placeholderUrl(): string {
    return getFixedSizeBlurredImage(
      this.previewUrl,
      {
        width: File.PREVIEW_MAX_WIDTH,
        height: 0,
      },
      this.graphicSize
    );
  }

  get typeDescription(): string {
    return t('Video_Message');
  }
}
