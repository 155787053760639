import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Avatar } from 'UIKIT/Avatar/Avatar';
import { AvatarTypes, TAvatarBackgroundColorIndex } from 'UIKIT/Avatar/Avatar.types';

interface IHugeCallAvatarProps {
  avatar: {
    title?: string;
    url?: string;
    colorIndex?: TAvatarBackgroundColorIndex;
  };
}
export const HugeCallAvatar: FC<IHugeCallAvatarProps> = observer(({ avatar }) => {
  return (
    <Avatar
      type={AvatarTypes.Round_50}
      title={avatar.title}
      url={avatar.url}
      backgroundColorIndex={avatar.colorIndex}
    />
  );
});
