import { useLocalStore } from 'mobx-react';

import Entities from 'APP/store';

export default () => {
  const presenter = useLocalStore(() => ({
    isNeedHandleDeepLink: true,

    get hasCall() {
      return Entities.Calls.calls[0] && Entities.Calls.calls[0].isStarted;
    },

    finishDeepLinkHandling() {
      presenter.isNeedHandleDeepLink = false;
    },
  }));

  return presenter;
};
