// TODO: remove this file after scheduled messages refactoring: GEM-23214
export function buildScheduleGroupId(groupId: string): string {
  return `${groupId}.1`;
}

export function parseScheduleGroupId(groupId: string): {
  groupId: string;
  scheduleGroupId: string | null;
} {
  const parsed = groupId.split('.');

  return {
    groupId: parsed[0],
    scheduleGroupId: parsed[1] ? groupId : null,
  };
}

export function isScheduleGroup(groupId: string): boolean {
  return !!parseScheduleGroupId(groupId).scheduleGroupId;
}
