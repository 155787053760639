import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { ActiveSpeakerIcon } from 'APP/components/ActiveSpeakerIcon/ActiveSpeakerIcon';
import { IconView } from 'APP/packages/UiKit/IconView/IconView';
import Entities from 'APP/store';
import MicOff from 'ICONS/ic-mic-off-16.svg';
import IcScreenSharing from 'ICONS/ic-screen-sharing.svg';

import AudioState from '../AudioState';
import styles from './styles.m.css';

const StreamerMeta = ({ className, streamer, isMinimize }) => {
  const activeStreamer = Entities.ActiveStream.stream.activeStreamer;

  return (
    <>
      <div className={classNames(styles.container, { [styles.minimize]: isMinimize }, className)}>
        {streamer.isSharedScreen && isMinimize && (
          <IconView
            Icon={IcScreenSharing}
            className={styles.sharingIcon}
            size="small"
          />
        )}
        {!activeStreamer.isSharedScreen &&
          activeStreamer.streamer.isSharedScreen &&
          !isMinimize && (
            <IconView
              Icon={IcScreenSharing}
              className={styles.sharingIcon}
              size="small"
            />
          )}
        {!isMinimize && (
          <div className={styles.metaGroup}>
            <div className={styles.name}>{streamer.username}</div>
            {streamer.isAudioMuted ? (
              <IconView
                Icon={MicOff}
                size="small"
                className={styles.muted}
              />
            ) : (
              <ActiveSpeakerIcon
                isBig
                isSilence={!streamer.isActiveSpeaker}
              />
            )}
          </div>
        )}
      </div>
      {isMinimize && !streamer.isSharedScreen && (
        <AudioState
          streamer={streamer}
          className={styles.audioState}
        />
      )}
    </>
  );
};

export default observer(StreamerMeta);
