import classNames from 'classnames';
import React from 'react';

import styles from './styles.m.css';

const DotLoader = ({ className, itemClassName }) => {
  return (
    <div className={classNames(styles.container, className)}>
      <div className={classNames(styles.item, styles.item1, itemClassName)} />
      <div className={classNames(styles.item, styles.item2, itemClassName)} />
      <div className={classNames(styles.item, styles.item3, itemClassName)} />
    </div>
  );
};

export default DotLoader;
