import { useLocalStore, useAsObservableSource } from 'mobx-react';
import { useEffect } from 'react';

export default ({ parentPresenter: parent, scrollRef }) => {
  const source = useAsObservableSource({ parent });

  const presenter = useLocalStore(() => ({
    isNextDay: (index) => {
      return source.parent.group.draftsStore.isNextDay(index);
    },

    get drafts() {
      return source.parent.drafts;
    },
  }));

  useEffect(() => {
    scrollRef.current.scrollTo({
      top: scrollRef.current.scrollHeight,
      behavior: 'instant',
    });
  }, []);

  return presenter;
};
