import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { useFileDownloadStatePresenter } from 'APP/hooks/useFileDownloadState/useFileDownloadState';
import LoadIconSmall from 'ICONS/ic-load-24.svg';
import LoadIconNormal from 'ICONS/ic-load.svg';
import { InfiniteUploadIcon } from 'MAIN/components/InfiniteUploadIcon/InfiniteUploadIcon';
import { ProgressLoadIcon } from 'MAIN/components/ProgressLoadIcon/ProgressLoadIcon';

import styles from './MediaDownloadState.styles.m.css';

export type TLoadIconSize = '0' | '10';

interface IMediaDownloadStateProps {
  groupId: string;
  messageId: string;
  payloadId: string;
  className?: string;
  loadIconSize?: TLoadIconSize;
}

export const MediaDownloadState: FC<IMediaDownloadStateProps> = observer((props) => {
  const { className, groupId, messageId, payloadId, loadIconSize = '10' } = props;

  const presenter = useFileDownloadStatePresenter({ payloadId, groupId, messageId });

  const LoadIcon = loadIconSize === '0' ? LoadIconSmall : LoadIconNormal;

  if (presenter.isDownloadInProcess) {
    return (
      <div className={classNames(styles.container, className)}>
        <ProgressLoadIcon
          Icon={LoadIcon}
          progress={presenter.downloadPercent}
          onClick={(e) => {
            e.stopPropagation();
            presenter.onAbort();
          }}
        />
      </div>
    );
  } else if (presenter.isWaitingDownloadProcess) {
    return (
      <div className={classNames(styles.container, className)}>
        <InfiniteUploadIcon
          Icon={LoadIcon}
          onClick={(e) => {
            e.stopPropagation();
            presenter.onAbort();
          }}
        />
      </div>
    );
  }

  return null;
});
