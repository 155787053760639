import Tasks from 'APP/Tasks';
import { UpdateReason } from 'APP/constants/scroll';
import Entities from 'STORE';

import { GroupsEvent } from '../GroupsEvent';

export class LinkPreviewWasCreated extends GroupsEvent {
  async process() {
    const { linkPreview, messageId, groupId } = this.eventPayload;

    const group = Entities.GroupsStore.getGroupById(groupId.toString());

    if (!group) {
      return;
    }

    const message = group.messagesStore.getMessageById(messageId.toString());

    if (!message) {
      return;
    }

    message.payload.linkPreview = linkPreview;
    Tasks.messaging.updateOldOrFakeMessages({
      messages: [message],
      groupId: group.id,
    });

    if (group.isActive) {
      Entities.ChatStore.setScrollChanged(UpdateReason.EditMessage, {
        messageIds: [message.id],
        isAnimated: true,
      });
    }
  }
}
