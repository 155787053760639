import { FinishCallReason } from 'APP/model/call/callModel.constants';
import { CallProviderType } from 'APP/model/call/callModel.types';
import { callModel } from 'APP/model/model';

interface ILeaveData {
  channelId: string;
  providerType: CallProviderType;
}

export const leave = async ({ channelId, providerType }: ILeaveData): Promise<void> => {
  try {
    await callModel.finishCall(channelId, providerType, FinishCallReason.Left);
  } catch (e) {
    console.error(e);
  }
};
