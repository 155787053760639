import { action, computed, observable } from 'mobx';

import { ICallMember } from 'APP/model/call/callModel.types';
import { ProviderUid } from 'APP/packages/callProviders/callProviders.types';
import { TAvatarBackgroundColorIndex } from 'UIKIT/Avatar/Avatar.types';

import { Call } from '../Call';
import { Opponent } from '../Opponent/Opponent';
import { mapState, MemberState } from './MemberState';

export class Member {
  call: Call;
  userId: string;
  uid: ProviderUid;
  shareScreenUid: ProviderUid;
  displayName: string;
  avatarUrl: string | undefined;
  @observable _state = MemberState.NotOnCall;
  @observable isRaiseHand = false;

  constructor(call: Call, data: ICallMember) {
    this.call = call;
    this.userId = data.userId;
    this.uid = data.providerUserUid;
    this.shareScreenUid = data.shareScreenUid;
    this.isRaiseHand = data.isHandRaised;
    this.displayName = data.username;
    this.avatarUrl = data.avatarUrl;
    this._state = mapState[data.status];
  }

  @computed
  get avatarColorIndex(): TAvatarBackgroundColorIndex {
    const index = this.userId ? Number(this.userId[this.userId.length - 1]) : 0;

    return String(index) as TAvatarBackgroundColorIndex;
  }

  @computed
  get isMe(): boolean {
    if (!this.call.me) {
      return false;
    }
    return this.call.me.id === this.userId;
  }

  @computed
  get opponent(): Opponent | null {
    return this.call.opponents.getOpponent(this.uid) || null;
  }

  @computed
  get screenSharingOpponent(): Opponent | null {
    return this.call.opponents.getOpponent(this.shareScreenUid);
  }

  @computed
  get hasScreenSharingOpponent(): boolean {
    return Boolean(this.screenSharingOpponent);
  }

  @action
  setState(state: MemberState): void {
    this._state = state;
  }

  @action
  setRaiseHand(state: boolean): void {
    this.isRaiseHand = state;
  }

  @computed
  get state(): MemberState {
    if (this.isMe) {
      return MemberState.OnCall;
    }
    if (this.opponent) {
      return MemberState.OnCall;
    }

    return this._state;
  }

  @computed
  get isOnCall(): boolean {
    return this.state === MemberState.OnCall;
  }

  @computed
  get isAudioMuted(): boolean {
    if (this.isMe) {
      return this.call.me.isAudioMuted;
    }
    if (!this.isOnCall) {
      return false;
    }
    return !this.opponent || !this.opponent.hasAudio;
  }

  @computed
  get isMuting(): boolean {
    return !this.isMe && this.isOnCall && !this.isAudioMuted;
  }

  @computed
  get isUnMuting(): boolean {
    return !this.isMe && this.isOnCall && this.isAudioMuted;
  }

  @computed
  get isRemoving(): boolean {
    return !this.isMe && this.isOnCall;
  }

  @computed
  get isActiveSpeaker(): boolean {
    if (this.isMe) {
      return !this.call.me.isAudioMuted && this.call.me.volumeLevel > 1;
    }
    return !!this.opponent && this.opponent.isActiveSpeaker;
  }

  @computed
  get volumeLevel(): number {
    if (this.isMe) {
      return this.call.me.volumeLevel;
    }
    return (this.opponent && this.opponent.volumeLevel) || 0;
  }

  @computed
  get isInitiator(): boolean {
    return this.call.initiatorId === this.userId;
  }
}
