import { TFunction } from 'react-i18next';

import {
  TSystemMessageTextHandler,
  ISystemMessageText,
  ISystemMessageTextPresenter,
} from '../useSystemMessageText.types';

export const chatJoinedHandler: TSystemMessageTextHandler = (
  presenter: ISystemMessageTextPresenter,
  t: TFunction
): ISystemMessageText => {
  const result: ISystemMessageText = { html: '' };

  result.html = t('user_joined_chat_via_link', {
    0: `<span class="system-message-title">${presenter.firstUserDisplayedName}</span>`,
    interpolation: { escapeValue: false },
  });
  result.sourceText = t('user_joined_chat_via_link');
  result.params = {
    '{0}': { text: presenter.firstUserDisplayedName, userId: presenter.firstUserId },
  };

  return result;
};
