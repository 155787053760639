import { observer } from 'mobx-react';
import React from 'react';

import { YouTubePreview } from 'APP/components/YouTubePreview/YouTubePreview';
import { MEDIA_PREVIEW_MAX_SIZE } from 'APP/constants/articles';

import { useArticleYouTubePreviewPresenter } from './ArticleYouTubePreview.presenter';
import styles from './ArticleYouTubePreview.styles.m.css';

export const ArticleYouTubePreview = observer(({ id, payload }) => {
  const presenter = useArticleYouTubePreviewPresenter({ payload });

  if (!presenter.isShow) {
    return null;
  }

  return (
    <div className={styles.container}>
      <YouTubePreview
        id={id}
        className={styles.youtube}
        data={presenter.linkPreview}
        maxWidth={MEDIA_PREVIEW_MAX_SIZE.width}
        maxHeight={MEDIA_PREVIEW_MAX_SIZE.height}
        onStartPlay={presenter.onPlay}
      />

      {presenter.isShowTitle && <h6 className={styles.title}>{presenter.linkPreview.title}</h6>}
    </div>
  );
});
