import { unauthorizedCallModel } from 'APP/model/model';
import { IGetCallInfoViaLinkParams } from 'APP/model/unauthorizedCall/unauthorizedCallModel.types';
import Entities from 'STORE';

export const getCallInfoViaLink = async (
  params: IGetCallInfoViaLinkParams
): Promise<{ isLinkExpired: boolean }> => {
  try {
    const callInfo = await unauthorizedCallModel.getCallInfoViaLink(params);

    Entities.callInvitation.setInvitationInfo(callInfo);

    return {
      isLinkExpired: false,
    };
  } catch {
    return { isLinkExpired: true };
  }
};
