import { useLocalStore } from 'mobx-react';
import { useRef, useEffect } from 'react';

import loadMore from './Tasks/loadMore';

export default ({ common }) => {
  const presenter = useLocalStore(() => ({
    isLoading: false,

    get hasMentions() {
      return common.inputPanel.mention.hasMentions;
    },

    get mentions() {
      return common.inputPanel.mention.mentions;
    },

    get isLoadedAllMentions() {
      return common.inputPanel.mention.isLoadedAllMentions;
    },

    get cursor() {
      return common.inputPanel.mention.cursor;
    },

    loadMore(inView) {
      if (!inView || !common.hasFilter) {
        return;
      }

      loadMore({ inputPanel: common.inputPanel });
    },
  }));

  const timeout = useRef();

  useEffect(() => {
    clearTimeout(timeout.current);

    if (!presenter.isLoading && common.inputPanel?.mention.isLoading) {
      timeout.current = setTimeout(() => {
        presenter.isLoading = true;
      }, 1000);
    } else if (presenter.isLoading && !common.inputPanel?.mention.isLoading) {
      presenter.isLoading = false;
    }
  }, [common.inputPanel.mention.isLoading]);

  return presenter;
};
