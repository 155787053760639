/* global Date */
import isValidDate from './isValidDate';
import { now } from './now';

export default (rawDiffDate) => {
  const diffDate = new Date(rawDiffDate);

  if (!isValidDate(diffDate)) {
    return -1;
  }

  return now().getTime() - diffDate.getTime();
};
