import classNames from 'classnames';
import React, { FC } from 'react';

import { VerifiedBadge } from 'APP/components/VerifiedBadge/VerifiedBadge';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';
import PopupInfoCard from 'UIKIT/PopupInfoCard';
import popupItemStyles from 'UIKIT/PopupItem/styles.m.scss';

import { CheckboxRow } from '../CheckboxRow/CheckboxRow';
import { ISharedDataViewItemData } from '../ShareDataView.types';

interface IGroupItemProps {
  data: ISharedDataViewItemData;
  onClick?(): void;
  isSelected: boolean;
  withCheckbox: boolean;
}

export const SharedDataGroupItem: FC<IGroupItemProps> = (props) => {
  const { data, onClick, isSelected, withCheckbox } = props;

  const avatar = (
    <PopupInfoCard
      type={AvatarTypes.Round_10}
      avatarTitle={data.avatarTitle}
      avatarUrl={data.avatarUrl}
      avatarBackgroundColorIndex={data.avatarColorIndex}
      title={data.avatarTitle}
      badge={data.verified && <VerifiedBadge />}
    />
  );

  if (withCheckbox) {
    return (
      <CheckboxRow
        onClick={onClick}
        isSelected={isSelected}
      >
        {avatar}
      </CheckboxRow>
    );
  }

  return (
    <button
      onClick={onClick}
      className={classNames(popupItemStyles.container, popupItemStyles.interactive)}
    >
      {avatar}
    </button>
  );
};
