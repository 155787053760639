import { observer } from 'mobx-react';
import React from 'react';

import DownIcon from 'ICONS/ic-toggle_down.svg';
import { FAB } from 'UIKIT/FAB/FAB';

import { useScrollToBottomPresenter } from './ScrollToBottomButton.presenter';
import styles from './ScrollToBottomButton.styles.m.css';

export const ScrollToBottomButton = observer(({ lastMessage }) => {
  const presenter = useScrollToBottomPresenter(lastMessage);

  if (!presenter.hasScrollToBottomButton) {
    return null;
  }

  return (
    <FAB
      className={styles.button}
      size={'30'}
      onClick={presenter.moveToBottom}
      badge={presenter.badgeCount}
      Icon={DownIcon}
    />
  );
});
