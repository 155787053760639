import { showAlert } from 'APP/Tasks/app/app';
import * as authModel from 'APP/model/auth/authModel';
import { AuthProvider } from 'APP/model/auth/authModel.types';
import { t } from 'APP/module/i18n/service';
import { ServerErrorCode } from 'APP/packages/api';
import Entities from 'STORE';

export const linkAccount = async (
  token: string,
  authProviderId: AuthProvider
): Promise<{ success: boolean; error: string | null }> => {
  try {
    const accountProvider = await authModel.linkAccount({ token, authProviderId });

    Entities.UsersStore.Me.addAccountProvider(accountProvider.linkedAccount);

    accountProvider.autoLinkedAccounts.forEach((autoLinkedAccount) => {
      if (autoLinkedAccount.authProviderId === AuthProvider.Phone) {
        Entities.UsersStore.Me.updatePhone(autoLinkedAccount.displayName);
      }
      Entities.UsersStore.Me.addAccountProvider(autoLinkedAccount);
    });

    return { success: true, error: null };
  } catch (error) {
    if (error.message === ServerErrorCode.AccountAlreadyLinked) {
      showAlert(t('account_linked_another_device'));
      return { success: false, error: null };
    }

    if (error.message === ServerErrorCode.AccountLinkedByAnotherGemAccount) {
      showAlert(t('account_linked_another_gem_user'));
      return { success: false, error: null };
    }
    return { success: false, error: error.message };
  }
};
