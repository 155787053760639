import { observer } from 'mobx-react';
import React from 'react';

import { VerifiedBadge } from 'APP/components/VerifiedBadge/VerifiedBadge';
import PopupInfoCard from 'APP/packages/UiKit/PopupInfoCard';
import popupItemStyles from 'APP/packages/UiKit/PopupItem/styles.m.scss';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';
import { Button } from 'UIKIT/Button/Button';

import { useBlockedUserPresenter } from './BlockedUser.presenter';

export const BlockedUser = observer(({ blockedUser, blockUser, unblockUser }) => {
  const presenter = useBlockedUserPresenter(blockedUser, blockUser, unblockUser);

  return (
    <div className={popupItemStyles.container}>
      <PopupInfoCard
        type={AvatarTypes.Round_10}
        className={popupItemStyles.card}
        avatarTitle={presenter.avatarTitle}
        avatarUrl={presenter.avatarUrl}
        avatarBackgroundColorIndex={presenter.avatarBackgroundColorIndex}
        title={presenter.title}
        subtitle={presenter.subtitle}
        badge={blockedUser.verified && <VerifiedBadge />}
      />
      <Button
        onClick={presenter.toggleBlockUser}
        isDisabled={presenter.isDisabled}
        isLoading={presenter.isLoading}
        theme="4"
      >
        {presenter.buttonText}
      </Button>
    </div>
  );
});
