import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import connection from 'APP/packages/connection';
import logger from 'APP/packages/logger';

interface IUserTypingPresenter {
  sendTimeout: ReturnType<typeof setTimeout> | null;
  send(): void;
  reset(): void;
}

const SEND_TIMEOUT = 1000;

export const useUserTyping = (groupId: string): IUserTypingPresenter => {
  const source = useAsObservableSource({ groupId });

  const presenter = useLocalStore<IUserTypingPresenter>(() => ({
    sendTimeout: null,

    send(): void {
      if (presenter.sendTimeout) {
        return;
      }

      presenter.sendTimeout = setTimeout(() => {
        logger.get('useInput').debug('sendUserTyping', { groupId: source.groupId });
        connection.main.userTyping.send({ groupId: source.groupId });
        presenter.reset();
      }, SEND_TIMEOUT);
    },

    reset(): void {
      if (presenter.sendTimeout) {
        clearTimeout(presenter.sendTimeout);
        presenter.sendTimeout = null;
      }
    },
  }));

  useEffect(() => {
    return () => presenter.reset();
  }, [groupId]);

  return presenter;
};
