import React, { FC } from 'react';

import { AnimatedSticker } from 'APP/components/Sticker/AnimatedSticker/AnimatedSticker';
import { IUserNameBadgeSource } from 'APP/constants/userBadges';

import styles from './AnimatedBadge.m.css';

export interface IAnimatedBadgeProps {
  animation: IUserNameBadgeSource;
  displayUILayer?: number;
}

export const AnimatedBadge: FC<IAnimatedBadgeProps> = (props) => {
  const { animation, displayUILayer } = props;

  return (
    <AnimatedSticker
      loop={true}
      animationData={animation}
      width={20}
      height={20}
      autoplay
      displayUILayer={displayUILayer}
      className={styles.container}
    />
  );
};
