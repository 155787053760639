import React, { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { useTranslation } from 'APP/packages/translations';

import { ICommonPopupsProps } from '../../PopupManager.types';
import {
  IAddParticipantsPopupParams,
  useAddParticipantsPopupPresenter,
} from './AddParticipantsPopup.presenter';
import { ParticipantSelectList } from './ParticipantSelectList/ParticipantSelectList';

export const AddParticipantsPopup: FC<ICommonPopupsProps<IAddParticipantsPopupParams>> = (
  props
) => {
  const { t } = useTranslation();

  const presenter = useAddParticipantsPopupPresenter(props);

  if (!presenter.group) {
    return null;
  }

  return (
    <Popup
      title={t('add_people_screen_title')}
      isOpened={!!presenter.group}
      handlePrevStep={props.onBack ? presenter.onBack : null}
      onClose={presenter.onClose}
    >
      <ParticipantSelectList
        group={presenter.group}
        onSuccess={presenter.onSuccess}
      />
    </Popup>
  );
};
