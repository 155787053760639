import classNames from 'classnames';
import React, { FC } from 'react';

import DeleteIcon from 'ICONS/ic-delete.svg';
import { FAB } from 'UIKIT/FAB/FAB';

import styles from './DeleteButton.styles.m.css';

interface IDeleteButtonProps {
  onClick(): void;
  className?: string;
}

export const DeleteButton: FC<IDeleteButtonProps> = ({ onClick, className }) => {
  return (
    <FAB
      className={classNames(styles.delete, className)}
      Icon={DeleteIcon}
      onClick={onClick}
      size="30"
      theme="12_1"
    />
  );
};
