import { observer } from 'mobx-react';
import React, { FC, ReactElement } from 'react';

import { VirtualizedList } from 'APP/components/VirtualizedList/VirtualizedList';
import {
  GROUPS_PLACEHOLDER_ITEM_SIZE,
  GroupsPlaceholder,
} from 'APP/packages/placeholders/GroupsPlaceholder/GroupsPlaceholder';
import { useTranslation } from 'APP/packages/translations';

import {
  BOTS_CATALOG_ITEM_HEIGHT,
  OpenBotsCatalogButton,
} from './OpenBotsCatalogButton/OpenBotsCatalogButton';
import styles from './SearchResult.styles.m.css';
import { SearchResultEmpty } from './SearchResultEmpty/SearchResultEmpty';
import { SearchResultInfo } from './SearchResultInfo/SearchResultInfo';
import { SearchResultItem } from './SearchResultItem/SearchResultItem';
import { isDelimiterItem, useSearchResultPresenter } from './presenter/SearchResult.presenter';

const PLACEHOLDER_ITEMS_COUNT = 5;

const DELIMETER_ITEM_HEIGHT = 56;
const RESULT_ITEM_HEIGHT = 64;

interface ISearchResultProps {
  searchText: string;
}

export const SearchResult: FC<ISearchResultProps> = observer(({ searchText }) => {
  const presenter = useSearchResultPresenter(searchText);
  const { t } = useTranslation();

  if (presenter.showEmptyNote) {
    return (
      <div>
        <OpenBotsCatalogButton />
        <SearchResultInfo />
      </div>
    );
  }

  if (presenter.showNoResults) {
    return (
      <div>
        <OpenBotsCatalogButton />
        <SearchResultEmpty />
      </div>
    );
  }

  const getItemSize = (index: number): number => {
    const resultIndex = index - 1;
    const item = presenter.results[resultIndex];

    if (index === 0) {
      return BOTS_CATALOG_ITEM_HEIGHT;
    } else if (isDelimiterItem(item)) {
      return DELIMETER_ITEM_HEIGHT;
    } else {
      return RESULT_ITEM_HEIGHT;
    }
  };

  const getListItem = (index: number): ReactElement => {
    const resultIndex = index - 1;
    const item = presenter.results[resultIndex];

    if (index === 0) {
      return <OpenBotsCatalogButton />;
    } else if (isDelimiterItem(item)) {
      return <div className={styles.resultsDelimiter}>{t('extended_search_title')}</div>;
    } else {
      return (
        <SearchResultItem
          entity={item}
          isLocalResult={resultIndex < presenter.localResultsCount}
        />
      );
    }
  };

  return (
    <VirtualizedList
      // one item is bot catalog link
      itemCount={presenter.results.length + 1}
      getListItem={getListItem}
      itemSize={getItemSize}
      isShowLoader={presenter.isLoading}
      Loader={<GroupsPlaceholder count={PLACEHOLDER_ITEMS_COUNT} />}
      loaderSize={PLACEHOLDER_ITEMS_COUNT * GROUPS_PLACEHOLDER_ITEM_SIZE}
      hasMoreItems={presenter.showLoadMore}
      onLoadMore={presenter.loadMore}
    />
  );
});
