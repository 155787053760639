import { observer } from 'mobx-react';
import React, { FC, ReactNode } from 'react';

import { CountryPhoneFields } from 'APP/components/CountryPhoneFields/CountryPhoneFields';
import { Button, ButtonTypes } from 'APP/packages/UiKit/Button/Button';
import { Form } from 'APP/packages/form/Form/Form';
import { useTranslation } from 'APP/packages/translations';
import { ICountry } from 'APP/types/countryCodes';

import styles from './PhoneNumberStep.styles.m.css';

interface IPhoneNumberStepProps {
  country: string;
  phoneNumber: string;
  countries: ICountry[];
  pristine: boolean;

  onSubmit(data: { phoneNumber: string; country: string }): void;
  onCountryPopupClose(): void;
  onCountryPopupOpen(): void;
}

export const PhoneNumberStep: FC<IPhoneNumberStepProps> = observer(
  ({
    country,
    phoneNumber,
    countries,
    pristine,
    onSubmit,
    onCountryPopupOpen,
    onCountryPopupClose,
  }) => {
    const { t } = useTranslation();

    return (
      <div className={styles.container}>
        <p className={styles.description}>{t('common_edit_phone_hint')}</p>

        <Form
          onSubmit={onSubmit}
          initialValues={{
            country,
            phoneNumber,
          }}
          subscription={{
            values: true,
            pristine,
            submitting: true,
            invalid: true,
            validating: true,
          }}
          keepDirtyOnReinitialize={true}
        >
          {({
            handleSubmit,
            values,
            form,
            invalid,
            pristine,
            validating,
            submitting,
          }): ReactNode => (
            <form
              className={styles.form}
              onSubmit={handleSubmit}
            >
              <div className={styles.fields}>
                <CountryPhoneFields
                  countries={countries}
                  country={values.country}
                  phoneNumber={values.phoneNumber}
                  onPhoneNumberChange={(phone): void => form.change('phoneNumber', phone)}
                  onCountryChange={(countryCode): void => form.change('country', countryCode)}
                  onCountryPopupClose={onCountryPopupClose}
                  onCountryPopupOpen={onCountryPopupOpen}
                />
              </div>

              <div className={styles.footer}>
                <Button
                  size="10"
                  type={ButtonTypes.Submit}
                  theme="1"
                  isLoading={submitting}
                  isDisabled={pristine || invalid || validating}
                >
                  {t('continue_button')}
                </Button>
              </div>
            </form>
          )}
        </Form>
      </div>
    );
  }
);
