import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { ProfileNameBadge } from 'APP/components/ProfileNameBadge/ProfileNameBadge';
import type { User } from 'STORE/Users/User/User';

import styles from '../SenderName.styles.m.css';
import { useSenderTitlePresenter } from './SenderTitle.presenter';

interface ISenderTitleProps {
  user: User;
  onClick: React.ReactEventHandler<HTMLButtonElement>;
}

export const SenderTitle: FC<ISenderTitleProps> = observer((props) => {
  const { user, onClick } = props;
  const presenter = useSenderTitlePresenter(user);

  const classes = classNames(styles.senderName, styles['color' + user.avatarColorIndex]);

  return (
    <button
      className={classes}
      title={user.avatarTitle}
      onClick={onClick}
    >
      <span className={styles.name}>{user.avatarTitle}</span>
      <ProfileNameBadge
        inlineBadge
        verified={presenter.verified}
        source={presenter.userNameBadge?.source}
        animated={presenter.userNameBadge?.animated}
        displayUILayer={0}
      />
    </button>
  );
});
