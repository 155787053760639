import { Popup } from 'APP/store/App/Popups/Popup/Popup';

interface IChangePhoneAlertPopupPresenterParams {
  popupInstance: Popup;
  onChangeClick(): void;
}

interface IChangePhoneAlertPopupPresenter {
  closePopup(): void;
  changePhoneSubmit(): void;
}

export const useChangePhoneAlertPopupPresenter = ({
  popupInstance,
  onChangeClick,
}: IChangePhoneAlertPopupPresenterParams): IChangePhoneAlertPopupPresenter => {
  const closePopup = (): void => {
    popupInstance.close();
  };

  const changePhoneSubmit = (): void => {
    popupInstance.close();
    onChangeClick();
  };

  return {
    closePopup,
    changePhoneSubmit,
  };
};
