import classNames from 'classnames';
import React from 'react';

import CirclePlaceholder from 'APP/packages/placeholders/CirclePlaceholder';
import { TextPlaceholder } from 'APP/packages/placeholders/TextPlaceholder/TextPlaceholder';

import styles from './styles.m.css';

const SinglePlaceholder = () => {
  return (
    <div className={classNames(styles.container)}>
      <CirclePlaceholder size="24px" />
      <div className={styles.content}>
        <TextPlaceholder
          className={styles.gap}
          width={140}
        />
        <TextPlaceholder
          className={styles.gap}
          width={110}
        />
        <TextPlaceholder
          className={styles.gap}
          width={110}
        />
      </div>
    </div>
  );
};

export default SinglePlaceholder;
