import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { MAX_PIN_GROUPS } from 'APP/constants/group';
import { mapUserProfileBadgeToIcon } from 'APP/constants/userBadges';
import { useTranslation } from 'APP/packages/translations';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import ChangeOrderIcon from 'ICONS/ic-change-order.svg';
import CloseIcon from 'ICONS/ic-close.svg';
import DeleteIcon from 'ICONS/ic-delete.svg';
import MarkReadIcon from 'ICONS/ic-mark-as-read.svg';
import MarkUnreadIcon from 'ICONS/ic-mark-as-unread.svg';
import MuteIcon from 'ICONS/ic-mute.svg';
import PinStrokeIcon from 'ICONS/ic-pin-stroke.svg';
import UnmuteIcon from 'ICONS/ic-unmute.svg';
import { useMoveGroupToSpace } from 'MAIN/hooks/space/useMoveGroupToSpace';
import Entities from 'STORE';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';

export const useGroupListItemPresenter = (group, isShowSpaceIcon, popover) => {
  const { t } = useTranslation();
  const navigateTo = useNavigateTo();
  const source = useAsObservableSource({
    group,
    navigateTo,
  });
  const moveGroupToSpace = useMoveGroupToSpace(group.id);

  const presenter = useLocalStore(() => ({
    childPopup: null,

    onClick() {
      popover.close();
      source.navigateTo({
        to: `/${source.group.route}/${source.group.id}`,
      });
      if (Entities.GroupsStore.activeGroupId === source.group.id) {
        Tasks.messaging.scrollToLastMessage();
      }
    },

    get isActive() {
      if (Entities.GroupsStore.activeGroup) {
        if (
          Entities.GroupsStore.activeGroup.isThread &&
          Entities.GroupsStore.activeGroup.threadInfo
        ) {
          return source.group.id === Entities.GroupsStore.activeGroup.threadInfo.groupId;
        }
        return source.group.id === Entities.GroupsStore.activeGroup.id;
      }
      return false;
    },

    get space() {
      return source.group?.spaceId ? Entities.spacesStore.getById(source.group.spaceId) : null;
    },

    get isShowSpaceIcon() {
      return isShowSpaceIcon && presenter.space;
    },

    get actions() {
      const actions = [];

      if (source.group.unread || source.group.numberOfUnread > 0) {
        actions.push({
          key: 'read',
          title: t('action_over_chat_mark_as_read'),
          icon: MarkReadIcon,
          onClick() {
            if (source.group.unread) {
              Tasks.group.markGroupsAsUnread([source.group.id], false);
            }
            if (source.group.lastMessage?.unread) {
              Tasks.messaging.confirmReadMessages({
                groupId: source.group.id,
                messageId: source.group.lastMessage.id,
              });
            }
          },
        });
      } else {
        actions.push({
          key: 'unread',
          title: t('action_over_chat_mark_as_unread'),
          icon: MarkUnreadIcon,
          onClick() {
            Tasks.group.markGroupsAsUnread([source.group.id], true);
          },
        });
      }

      if (presenter.canPin && !source.group.isPinned) {
        actions.push({
          key: 'pin',
          title: source.group.isBot
            ? t('bot_pin')
            : source.group.isChannel
            ? t('channel_pin')
            : t('group_chat_pin'),
          icon: PinStrokeIcon,
          onClick() {
            Tasks.group.pinGroup({ groupId: source.group.id });
          },
        });
      }

      if (source.group.isPinned) {
        actions.push({
          key: 'unpin',
          title: source.group.isBot
            ? t('bot_unpin')
            : source.group.isChannel
            ? t('channel_unpin')
            : t('group_chat_unpin'),
          icon: PinStrokeIcon,
          onClick() {
            Tasks.group.unpinGroup({ groupId: source.group.id });
          },
        });
      }

      if (!source.group.muted && !source.group.isSavedMessages) {
        actions.push({
          key: 'mute',
          title: t('action_over_chat_mute'),
          icon: MuteIcon,
          onClick() {
            source.group.mute();
          },
        });
      }

      if (source.group.muted && !source.group.isSavedMessages) {
        actions.push({
          key: 'unmute',
          title: t('action_over_chat_unmute'),
          icon: UnmuteIcon,
          onClick() {
            source.group.unmute();
          },
        });
      }

      if (moveGroupToSpace.canMoveToSpace) {
        actions.push({
          key: 'move-to-space',
          title: t('space_moveto_space_button'),
          icon: ChangeOrderIcon,
          onClick: () => (presenter.childPopup = moveGroupToSpace.moveToSpace()),
        });
      }

      if (moveGroupToSpace.canRemoveFromSpace) {
        actions.push({
          key: 'remove-from-space',
          title: t('space_remove_from_button', { 0: presenter.space.name }),
          icon: CloseIcon,
          onClick: () => moveGroupToSpace.removeFromSpace(),
        });
      }

      if (presenter.canDeleteGroup) {
        let title;

        if (source.group.isSavedMessages || source.group.isP2P) {
          title = t('chat_details_delete_chat');
        } else if (source.group.isChannel) {
          title = t('channel_delete');
        } else {
          title = t('delete_group');
        }

        actions.push({
          key: 'delete_group',
          title,
          icon: DeleteIcon,
          style: 'danger',
          onClick: presenter.deleteGroup,
        });
      }

      return actions;
    },

    get canPin() {
      const pinnedCount = source.group.isChannel
        ? Entities.GroupsStore.pinnedChannels.length
        : Entities.GroupsStore.pinnedChatGroups.length;

      return pinnedCount < MAX_PIN_GROUPS;
    },

    get isShowBotIcon() {
      return source.group.isBot;
    },

    get isShowMutedIcon() {
      return source.group.muted && !source.group.isSavedMessages;
    },

    get userNameBadge() {
      const isP2p = source.group.isP2P;

      if (!isP2p) {
        return null;
      }

      const opponentId = source.group.opponentId;

      const user = Entities.UsersStore.getUserById(opponentId);

      if (!user || !user.usernameBadge) {
        return null;
      }

      return mapUserProfileBadgeToIcon[user.usernameBadge];
    },

    get verified() {
      const isP2p = source.group.isP2P;

      if (!isP2p) {
        return source.group.verified;
      }

      const opponentId = source.group.opponentId;

      const user = Entities.UsersStore.getUserById(opponentId);

      return user?.verified | false;
    },

    get avatarType() {
      return source.group.isChannel ? AvatarTypes.Square_20 : AvatarTypes.Round_10;
    },

    get hasAvatarLockIcon() {
      return source.group.isChannel && source.group.isClosed;
    },

    get canDeleteGroup() {
      return (
        ((source.group.isChatGroup || source.group.isChannel) && source.group.isOwner) ||
        source.group.isSavedMessages ||
        (source.group.isP2P && !source.group.isBot)
      );
    },

    get lastMessageTs() {
      return source.group.lastMessage?.displayTime || 0;
    },

    async deleteGroup() {
      let text;

      if (source.group.isP2P) {
        text = t('delete_chat_alert', { 0: source.group.avatarTitle });
      } else if (source.group.isSavedMessages) {
        text = t('saved_messages_delete_chat_alert', { 0: source.group.name });
      } else if (source.group.isChannel) {
        text = t('delete_channel_alert');
      } else {
        text = t('delete_group_chat_alert');
      }

      const canDelete = await Tasks.app.showConfirm({
        text,
        primaryText: t('common_ok'),
        secondaryText: t('common_cancel'),
      });

      if (!canDelete) {
        return;
      }

      let error;
      if (source.group.isP2P) {
        error = (
          await Tasks.group.leaveGroup({
            groupId: source.group.id,
          })
        ).error;
      } else {
        error = (
          await Tasks.group.deleteGroup({
            groupId: source.group.id,
          })
        ).error;
      }

      if (error) {
        Entities.toast.show(t('something_went_wrong'));
      }
    },
  }));

  useEffect(() => {
    return () => presenter.childPopup?.close();
  }, []);

  return presenter;
};
