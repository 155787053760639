import { observer } from 'mobx-react';
import React from 'react';

import { IconButton } from 'APP/packages/UiKit/IconButton';
import CommentIcon from 'ICONS/ic-comments.svg';

import usePresenter from './presenter';
import styles from './styles.m.css';

const Comments = ({ message }) => {
  const presenter = usePresenter({ message });
  if (!presenter.canLeaveComment) {
    return null;
  }

  return (
    <IconButton
      className={styles.button}
      Icon={CommentIcon}
      text={presenter.commentsCount || ''}
      onClick={presenter.onClick}
    />
  );
};

export default observer(Comments);
