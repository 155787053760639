import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { CSSTransition } from 'react-transition-group';

import IcHide from 'ICONS/ic-hidden-left.svg';
import IcShow from 'ICONS/ic-open-right.svg';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';
import { IconView } from 'UIKIT/IconView/IconView';

import StreamerScreen from './StreamerScreen';
import StreamerVideo from './StreamerVideo';
import usePresenter, { VIDEO_TYPE } from './presenter';
import styles from './styles.m.css';

const CSSTransitionClasses = {
  enter: styles.enter,
  enterActive: styles.enterActive,
  exit: styles.exit,
  exitActive: styles.exitActive,
};

const Streamers = ({ className, onSetActiveStreamer }) => {
  const presenter = usePresenter({ onSetActiveStreamer });

  return (
    <div
      className={classNames(
        styles.root,
        {
          [styles.isVisible]: presenter.streamers.length > 0,
        },
        className
      )}
    >
      <CSSTransition
        in={presenter.isShowStreamers}
        classNames={CSSTransitionClasses}
        timeout={100}
        mountOnEnter
        unmountOnExit
      >
        <div className={classNames(styles.container, customScrollStyles.container)}>
          {presenter.streamers.map(({ type, streamer, setActive }) => {
            if (type === VIDEO_TYPE.SCREEN) {
              return (
                <StreamerScreen
                  key={streamer.shareScreenUid}
                  streamer={streamer}
                  containerClassName={styles.videoContainer}
                  onClick={setActive}
                />
              );
            } else if (type === VIDEO_TYPE.CAMERA) {
              return (
                <StreamerVideo
                  key={streamer.uid}
                  streamer={streamer}
                  containerClassName={styles.videoContainer}
                  onClick={setActive}
                  isMinimize
                />
              );
            }

            return null;
          })}
        </div>
      </CSSTransition>
      <button
        onClick={presenter.toggleShowStreamers}
        className={styles.toggleOpponents}
      >
        <IconView
          Icon={presenter.isShowStreamers ? IcHide : IcShow}
          className={styles.icon}
        />
      </button>
    </div>
  );
};

export default observer(Streamers);
