/**
 * Stream API
 * Documentation Stream API v1.0
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * calls.active.call-changed
 */
export interface GroupCallChanged { 
    groupId: string;
    channel: string;
    status: GroupCallChangedStatusEnum;
    callType: GroupCallChangedCallTypeEnum;
    callProvider: GroupCallChangedCallProviderEnum;
    event: string;
}
export enum GroupCallChangedStatusEnum {
    Started = 'STARTED',
    Finished = 'FINISHED',
    Initiated = 'INITIATED'
};
export enum GroupCallChangedCallTypeEnum {
    P2P = 'P2P',
    Group = 'GROUP',
    Conference = 'CONFERENCE',
    Stream = 'STREAM'
};
export enum GroupCallChangedCallProviderEnum {
    Agora = 'AGORA',
    Openvidu = 'OPENVIDU'
};



