import { getUserPermission } from './getUserPermission';
import { registerNotifications } from './registerNotifications';

export const subscribeNotifications = async (): Promise<void> => {
  const permission = await getUserPermission();

  if (permission !== 'granted') {
    return;
  }

  registerNotifications('default');
  registerNotifications('voip');
};
