import { observer } from 'mobx-react';
import React, { FC, useMemo } from 'react';

import { AuthProvider } from 'APP/model/auth/authModel.types';
import { useTranslation } from 'APP/packages/translations';
import AppleIcon from 'ICONS/ic-apple.svg';
import DeleteIcon from 'ICONS/ic-delete.svg';
import { FAB } from 'UIKIT/FAB/FAB';
import popupItemStyles from 'UIKIT/PopupItem/styles.m.scss';

import { ProviderLayout } from '../ProviderLayout/ProviderLayout';
import { useProvidersPresenter } from '../hooks/useProviders';
import styles from './AppleProvider.styles.m.css';
import { LinkAppleProvider } from './LinkAppleProvider/LinkAppleProvider';

interface IAppleProviderProps {
  displayName: string;
  userId: string;
}

export const AppleProvider: FC<IAppleProviderProps> = observer((props) => {
  const { displayName, userId } = props;
  const { t } = useTranslation();

  const presenter = useProvidersPresenter();

  const linkProvider = useMemo(() => {
    return userId ? (
      t('account_apple_linked')
    ) : (
      <LinkAppleProvider setIsProcessing={presenter.setIsProcessing} />
    );
  }, [userId, presenter.setIsProcessing]);

  return (
    <div className={popupItemStyles.container}>
      <ProviderLayout
        disabled={!userId}
        isProcessing={presenter.isProcessing}
        icon={<AppleIcon className={styles.icon} />}
        title={t('account_apple')}
        description={displayName || linkProvider}
        actions={
          userId ? (
            <FAB
              size="20"
              theme="3"
              className={styles.deleteIcon}
              Icon={DeleteIcon}
              onClick={(): void => {
                presenter.onDelete(AuthProvider.Apple);
              }}
            />
          ) : null
        }
      />
    </div>
  );
});
