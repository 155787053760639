import { scheduledEventModel } from 'APP/model/model';
import {
  IGetScheduledEventInviteesParams,
  IInvitees,
} from 'APP/model/scheduledEvent/scheduledEventModel.types';

export const getScheduledEventInvitees = async (
  params: IGetScheduledEventInviteesParams
): Promise<IInvitees | undefined> => {
  try {
    return await scheduledEventModel.getScheduledEventInvitees(params);
  } catch (error) {
    return;
  }
};
