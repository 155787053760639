/**
 * Rest API
 * Documentation Rest API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Referrer } from './referrer';
import { DeviceInfo } from './deviceInfo';


export interface VerifyCodeRequest { 
    phone: string;
    deviceUDID: string;
    referrer?: Referrer;
    deviceInfo: DeviceInfo;
    verifyFlow: VerifyCodeRequestVerifyFlowEnum;
    signature: string;
    captchaToken?: string;
}
export enum VerifyCodeRequestVerifyFlowEnum {
    SmsFirst = 'SMS_FIRST',
    MissedCallFirst = 'MISSED_CALL_FIRST',
    WebFlow = 'WEB_FLOW',
    AndroidCaptchaFlow = 'ANDROID_CAPTCHA_FLOW',
    HuaweiCaptchaFlow = 'HUAWEI_CAPTCHA_FLOW',
    HCaptchaFlow = 'H_CAPTCHA_FLOW',
    ExternalProviderFlow = 'EXTERNAL_PROVIDER_FLOW'
};



