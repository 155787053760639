import { observer } from 'mobx-react';
import React from 'react';

import { IconButton } from 'APP/packages/UiKit/IconButton';
import { useTranslation } from 'APP/packages/translations';
import CloseIcon from 'ICONS/close.svg';
import { Avatar } from 'UIKIT/Avatar/Avatar';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';
import { Button } from 'UIKIT/Button/Button';

import styles from './style.m.scss';

const ChannelInfo = ({ presenter }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <IconButton
        Icon={CloseIcon}
        onClick={presenter.close}
        className={styles.close}
      />
      <div className={styles.groupInfo}>
        <Avatar
          type={AvatarTypes.Square_60}
          className={styles.avatar}
          title={presenter.group.name}
          url={presenter.group.avatarUrl}
          backgroundColorIndex={presenter.group.avatarColorIndex}
        />
        <div className={styles.details}>
          <div className={styles.name}>{presenter.group.name}</div>
          {presenter.group.categoryInfo && (
            <div className={styles.info}>{presenter.group.categoryInfo.displayName}</div>
          )}
          <div className={styles.members}>
            {t('amount_of_subscribers_in_a_channel_many').replace(
              '{0}',
              presenter.group.usersCount
            )}
          </div>
          <div className={styles.button}>
            <Button
              onClick={presenter.subscribe}
              isLoading={presenter.isLoading}
              theme="1"
            >
              {t('invitation_subscribe')}
            </Button>
          </div>
        </div>
      </div>
      {presenter.group.description && (
        <div className={styles.description}>
          <div className={styles.descriptionLabel}>{t('about_group_chat_block_title')}</div>
          <div className={styles.descriptionContent}>{presenter.group.description}</div>
        </div>
      )}
    </div>
  );
};

export default observer(ChannelInfo);
