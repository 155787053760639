import WebSocket from 'APP/packages/websocket';

import sent from './messages/sent';
import userTyping from './messages/userTyping';

const ROOT_API = 'subscribe';

const RECONNECT_ATTEMPT_DELAY = 1000;
const RECONNECT_MAX_DELAY = 10000;

// After calling the open method, a new socket instance is created.
// And to us onClose of the old instance, we need to read its old needReconnect flag
const needReconnectFlags = new WeakMap();

class MainConnection {
  constructor() {
    this.websocket = new WebSocket({
      rootApi: ROOT_API,
      onError: this.onError,
      onClose: this.onClose,
      onOpen: this.onOpen,
    });
    this.attempt = 0;
    this.currentConnection = null;

    this.userTyping = userTyping(this.websocket);
    this.sent = sent(this.websocket);
  }

  open() {
    this.attempt++;
    if (this.currentConnection) {
      this.close(false);
    }
    this.currentConnection = this.websocket.open();
    needReconnectFlags.set(this.currentConnection, true);
  }

  get isOpened() {
    return this.websocket.isOpened;
  }

  close(needReconnect = true) {
    needReconnectFlags.set(this.currentConnection, needReconnect);
    this.websocket.close();
  }

  onClose = (event) => {
    if (needReconnectFlags.get(event.target)) {
      setTimeout(() => {
        this.open();
      }, Math.min(this.attempt * RECONNECT_ATTEMPT_DELAY, RECONNECT_MAX_DELAY));
    }
  };

  onOpen = () => {
    this.attempt = 1;
  };

  onError = () => {
    this.close();
  };
}

const connection = new MainConnection();

export default connection;
