import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { DraggableVideo } from './DraggableVideo/DraggableVideo';
import { useOwnVideoPresenter } from './OwnVideo.presenter';

interface IOwnVideoProps {
  className?: string;
  isFullScreen?: boolean;
}

export const OwnVideo: FC<IOwnVideoProps> = observer(({ className, isFullScreen = false }) => {
  const presenter = useOwnVideoPresenter();

  if (!presenter.publisher) {
    return null;
  }

  return (
    <DraggableVideo
      publisher={presenter.publisher}
      className={className}
      isFullScreen={isFullScreen}
    />
  );
});
