import Entities from 'STORE';

import { GroupsEvent } from '../GroupsEvent';

export class UserPreferencesChanged extends GroupsEvent {
  process() {
    const { categoryIds = [], locales = [] } = this.eventPayload;

    Entities.userPreferences.setCategoryIds(categoryIds);
    Entities.userPreferences.setLocaleIds(locales);
  }
}
