import { stickersModel } from 'APP/model/model';
import { IStickerPacksList } from 'APP/model/stickers/stickersModel.types';
import { storage } from 'APP/packages/storage';
import Entities from 'APP/store';

export async function loadStickersPacks(lastUpdateTime: number): Promise<void> {
  let cursor: string | undefined = undefined;
  do {
    const data: IStickerPacksList = await stickersModel.getPublicStickerPacks({
      cursor,
    });
    cursor = data.cursor || undefined;

    Entities.stickersStore.mergePacks(data.items);
  } while (cursor);

  storage.publicStickerPacks.set({
    lastUpdateTime,
    packs: Entities.stickersStore.stickersPacks,
  });
}
