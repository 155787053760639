import { observer } from 'mobx-react';
import React from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { PermissionsRequestStatuses } from 'APP/model/call/callModel.types';
import { PermissionsTypes } from 'APP/model/callPermissions/callPermissionsModel.constants';
import { useTranslation } from 'APP/packages/translations';

import { GlobalRequestButton } from './components/GlobalRequestButton';
import { RequestsPermissionsBlock } from './components/RequestsPermissionsBlock';
import { usePresenter } from './presenter';
import styles from './styles.m.css';

export const RequestedPermissionsPopup = observer(() => {
  const { t } = useTranslation();
  const presenter = usePresenter();

  if (!presenter.ongoingCall) {
    return null;
  }

  return (
    <Popup
      title={t('permissions_label')}
      isOpened
      onClose={presenter.onClose}
    >
      <div className={styles.wrapper}>
        <GlobalRequestButton
          onSuccess={presenter.onClose}
          status={PermissionsRequestStatuses.Approved}
        />
        <GlobalRequestButton
          onSuccess={presenter.onClose}
          status={PermissionsRequestStatuses.Rejected}
        />
        {Object.values(PermissionsTypes).map(
          (type) =>
            presenter.permissionsRequests.get(type) &&
            !!presenter.permissionsRequests.get(type).length && (
              <RequestsPermissionsBlock
                key={type}
                permissionType={type}
              />
            )
        )}
      </div>
    </Popup>
  );
});
