import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import { CatalogType, ICatalogWidget } from 'APP/model/catalog/catalogModel.types';
import Entities from 'STORE';
import { isEqualArrays } from 'UTILS/isEqualArrays';

import { TCatalogResetHandler } from '../Catalog.types';
import { loadCatalog } from '../Tasks/loadCatalog';
import { loadCatalogWidget } from '../Tasks/loadCatalogWidget';

interface ICatalogMainContentLocalStore {
  catalogType: CatalogType;
  localeIds: string[];
  isLoading: boolean;
  lastWidget: ICatalogWidget | null;
  isLoadingView: boolean;
  isLoadingMoreView: boolean;
  isEmptyView: boolean;
  isContentView: boolean;
  isShowItemType: boolean;
  viewObserverKey: string;
  widgets: ICatalogWidget[];
  getWidgetScrollPosition(widgetId: string): number;
  load(): Promise<void>;
  loadMore(): Promise<void>;
  onViewObserver(isView: boolean): void;
  onWidgetScrollPositionChange(widgetId: string, position: number): void;
}

export const useCatalogMainContentPresenter = (
  onCatalogReset: TCatalogResetHandler
): ICatalogMainContentLocalStore => {
  const presenter = useLocalStore<ICatalogMainContentLocalStore>(() => ({
    catalogType: Entities.catalogStore.type,
    localeIds: Entities.userPreferences.localeIds,
    isLoading: false,
    lastWidget: null,

    get isLoadingView(): boolean {
      return presenter.isLoading && Entities.catalogStore.widgets.length === 0;
    },

    get isLoadingMoreView(): boolean {
      return presenter.isLoading && Entities.catalogStore.widgets.length > 0;
    },

    get isEmptyView(): boolean {
      return !presenter.isLoadingView && Entities.catalogStore.widgets.length === 0;
    },

    get isContentView(): boolean {
      return !presenter.isLoadingView && !presenter.isEmptyView;
    },

    get isShowItemType(): boolean {
      return Entities.catalogStore.type === CatalogType.All;
    },

    get viewObserverKey(): string {
      return `viewObserver-${presenter.lastWidget?.items?.length || 0}`;
    },

    get widgets(): ICatalogWidget[] {
      return Entities.catalogStore.widgets
        .filter((widget) => widget.items.length > 0)
        .sort((widgetA, widgetB) => widgetA.order - widgetB.order);
    },

    getWidgetScrollPosition(widgetId: string): number {
      return Entities.catalogStore.widgetsScrollPosition[widgetId] || 0;
    },

    async load(): Promise<void> {
      presenter.isLoading = true;

      const prevCatalogType = presenter.catalogType;
      const prevLocaleIds = presenter.localeIds;
      const result = await loadCatalog(Entities.catalogStore.type);

      if (
        prevCatalogType !== presenter.catalogType ||
        prevLocaleIds !== presenter.localeIds ||
        Entities.catalogStore.categoryId ||
        Entities.catalogStore.searchText
      ) {
        return;
      }

      if (!result) {
        Entities.catalogStore.setError(true);
        presenter.isLoading = false;
        return;
      }

      Entities.catalogStore.setWidgets(result.widgets);
      presenter.isLoading = false;
    },

    async loadMore(): Promise<void> {
      if (presenter.isLoading || !presenter.lastWidget?.widgetId || !presenter.lastWidget?.cursor) {
        return;
      }

      presenter.isLoading = true;

      const prevWidgetId = presenter.lastWidget.widgetId;
      const prevCursor = presenter.lastWidget.cursor;
      const prevLocaleIds = presenter.localeIds;
      const result = await loadCatalogWidget({
        widgetId: presenter.lastWidget.widgetId,
        cursor: presenter.lastWidget.cursor,
      });

      if (
        !presenter.lastWidget ||
        prevWidgetId !== presenter.lastWidget.widgetId ||
        prevCursor !== presenter.lastWidget.cursor ||
        prevLocaleIds !== presenter.localeIds ||
        Entities.catalogStore.searchText
      ) {
        return;
      }

      if (!result) {
        Entities.catalogStore.setError(true);
        presenter.isLoading = false;
        return;
      }

      presenter.lastWidget.items = [...presenter.lastWidget.items, ...result.items];
      presenter.lastWidget.cursor = result.cursor || null;
      presenter.isLoading = false;
    },

    onViewObserver(isView: boolean): void {
      if (isView) {
        presenter.loadMore();
      }
    },

    onWidgetScrollPositionChange(widgetId: string, position: number): void {
      Entities.catalogStore.setWidgetScrollPosition(widgetId, position);
    },
  }));

  useEffect(() => {
    if (presenter.widgets.length === 0) {
      presenter.load();
    }
  }, []);

  useEffect(() => {
    let hasChanges = false;

    if (presenter.catalogType !== Entities.catalogStore.type) {
      presenter.catalogType = Entities.catalogStore.type;
      hasChanges = true;
    }

    if (!isEqualArrays(presenter.localeIds, Entities.userPreferences.localeIds, false)) {
      presenter.localeIds = Entities.userPreferences.localeIds;
      hasChanges = true;
    }

    if (hasChanges) {
      onCatalogReset();
      presenter.load();
    }
  }, [Entities.catalogStore.type, Entities.userPreferences.localeIds]);

  useEffect(() => {
    presenter.lastWidget = presenter.widgets.at(-1) || null;
  }, [presenter.widgets]);

  return presenter;
};
