import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { useTranslation } from 'APP/packages/translations';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { ReportForm } from '../../components/ReportForm/ReportForm';
import { useClosePopupOnNavigation } from '../../hooks/useClosePopupOnNavigation';
import { IReportUserPopupParams, useReportUserPresenter } from './ReportUserPopup.presenter';

export const ReportUserPopup: FC<ICommonPopupsProps<IReportUserPopupParams>> = observer((props) => {
  const { t } = useTranslation();
  const presenter = useReportUserPresenter(props);
  useClosePopupOnNavigation(props.popupInstance);

  return (
    <Popup
      title={t('common_report')}
      isOpened
      handlePrevStep={presenter.hasBackButton ? presenter.onBack : null}
      onClose={presenter.onClose}
    >
      <ReportForm
        title={t('content_moderation_user')}
        onSubmit={presenter.onSubmit}
      />
    </Popup>
  );
});
