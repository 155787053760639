import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import { RouterParams } from 'APP/router/constants';
import useClearGetParameters from 'APP/router/hooks/useClearGetParameters';
import useGetParameter from 'APP/router/hooks/useGetParameter';
import Entities from 'APP/store';
import { Group } from 'STORE/Groups/Group';

interface IAddMembersPopupPresenter {
  group: Group | null;
  isAvailableToShow: boolean;
  onClose(): void;
}

export const useAddMembersPopupPresenter = (): IAddMembersPopupPresenter => {
  const clearGetParameters = useClearGetParameters();
  const groupId = useGetParameter(RouterParams.groupId);

  const presenter = useLocalStore<IAddMembersPopupPresenter>(() => ({
    get group(): Group | null {
      return groupId ? Entities.GroupsStore.getGroupById(groupId) : null;
    },

    get isAvailableToShow(): boolean {
      if (!presenter.group || !presenter.group?.activeCall) {
        return false;
      }

      if (!Entities.UsersStore.isLoadedContacts) {
        return false;
      }

      return presenter.group.isChatGroup || presenter.group.isChannel;
    },

    onClose: clearGetParameters,
  }));

  useEffect(() => {
    if (!presenter.group) {
      presenter.onClose();
    }
  }, []);
  return presenter;
};
