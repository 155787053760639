import Entities from 'STORE';
import { Group } from 'STORE/Groups/Group';
import Stream from 'STORE/LiveStreaming/Stream';

export const sortGroups = (group1: Group, group2: Group): number => {
  if (group1.activeCall || group2.activeCall) {
    return (group2.activeCall ? 1 : 0) - (group1.activeCall ? 1 : 0);
  }

  if (group1.backgroundCall || group2.backgroundCall) {
    return (group2.backgroundCall?.startTs || 0) - (group1.backgroundCall?.startTs || 0);
  }

  const activeStream: Stream | null = Entities.ActiveStream.stream;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore TODO: remove when Stream typings will be ready
  const isActive1 = activeStream?.groupId === group1.id;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore TODO: remove when Stream typings will be ready
  const isActive2 = activeStream?.groupId === group2.id;

  if (isActive1 || isActive2) {
    return (isActive2 ? 1 : 0) - (isActive1 ? 1 : 0);
  }

  const stream1 = Entities.BackgroundStreams.getByGroupId(group1.id);
  const stream2 = Entities.BackgroundStreams.getByGroupId(group2.id);

  if (stream1 || stream2) {
    return (stream2?.startTs || 0) - (stream1?.startTs || 0);
  }

  if (group1.pinOrder || group2.pinOrder) {
    return group2.pinOrder - group1.pinOrder;
  }

  if (group1.hasDraft || group2.hasDraft) {
    const updateTs1 = group1.hasDraft ? group1.draftInput?.updateTs || 0 : 0;
    const updateTs2 = group2.hasDraft ? group2.draftInput?.updateTs || 0 : 0;

    return updateTs2 - updateTs1;
  }

  const lastTs1 = Math.max(group1.lastMessageTs, group1.seenTs);
  const lastTs2 = Math.max(group2.lastMessageTs, group2.seenTs);

  return lastTs2 - lastTs1;
};

export const getSortedGroups = (groups: Group[]): Group[] => {
  return groups.slice().sort(sortGroups);
};
