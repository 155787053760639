import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { useTextRendererPresenter, ITextRendererParams } from './TextRenderer.presenter';
import styles from './TextRenderer.styles.m.css';

interface ITextRendererProps extends ITextRendererParams {
  className?: string;
}

export const TextRenderer: FC<ITextRendererProps> = observer((props) => {
  const presenter = useTextRendererPresenter(props);

  return <div className={classNames(styles.container, props.className)}>{presenter.content}</div>;
});
