import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { Reactions } from 'APP/components/Reactions/Reactions';
import { withPopupLayer } from 'MAIN/Popups/withPopupLayer';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';
import { FullscreenPopup } from 'UIKIT/FullscreenPopup/FullscreenPopup';

import { useArticlePreviewPresenter } from './ArticlePreview.presenter';
import styles from './ArticlePreview.styles.m.css';
import Footer from './Footer';
import Header from './Header';
import Payloads from './Payloads';

export const ArticlePreview = withPopupLayer(
  observer(({ groupId }) => {
    const presenter = useArticlePreviewPresenter({ groupId });

    return (
      <FullscreenPopup className={styles.popup}>
        <Header
          goBack={presenter.goBack}
          onDelete={presenter.delete}
          message={presenter.message}
        />
        <div className={classNames(styles.container, customScrollStyles.container)}>
          <div
            className={styles.content}
            data-test={'articles'}
          >
            {presenter.message && (
              <>
                <Payloads
                  message={presenter.message}
                  openMedia={presenter.openMedia}
                />
                <Reactions
                  message={presenter.message}
                  className={styles.reactions}
                  theme="1"
                />
              </>
            )}
          </div>
        </div>

        {presenter.isShowFooter && <Footer message={presenter.message} />}
      </FullscreenPopup>
    );
  })
);
