import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import Entities from 'STORE';

import { IBlockListViewLocalStore } from './BlockListView.types';
import { loadBlockedUsers } from './Tasks/loadBlockedUsers';

export const useBlockListViewPresenter = () => {
  const presenter = useLocalStore<IBlockListViewLocalStore>(() => ({
    isLoading: false,
    cursor: null,
    hasMore: false,
    blockedUsersIds: [],

    get blockedUsers(): IBlockListViewLocalStore['blockedUsers'] {
      return presenter.blockedUsersIds.map((id) => {
        return Entities.UsersStore.getUserById(id);
      });
    },

    get placeholdersCount(): IBlockListViewLocalStore['placeholdersCount'] {
      return presenter.blockedUsers.length > 0 ? 2 : 10;
    },

    loadBlockedUsers: async () => {
      presenter.isLoading = true;

      const { blockedUsersIds, nextCursor } = await loadBlockedUsers(presenter.cursor);

      presenter.hasMore = Boolean(nextCursor);
      presenter.cursor = nextCursor;
      presenter.blockedUsersIds = [...presenter.blockedUsersIds, ...blockedUsersIds];

      presenter.isLoading = false;
    },

    blockUser: (userId) => {
      return Tasks.relationships.blockUser(userId);
    },

    unblockUser: (userId) => {
      return Tasks.relationships.unblockUser(userId);
    },

    keyExtractor(blockedUser) {
      return blockedUser.id;
    },
  }));

  useEffect(() => {
    presenter.loadBlockedUsers();
  }, []);

  return presenter;
};
