import handleBotCommands from './handlers/handleBotCommands';
import handleCloseSquareBracket from './handlers/handleCloseSquareBracket';
import handleDog from './handlers/handleDog';
import handleEscapedText from './handlers/handleEscapedText';
import handleKeyWords from './handlers/handleKeyWords';
import handleLink from './handlers/handleLink';
import handleStars from './handlers/handleStars';
import handleStroke from './handlers/handleStroke';
import { handleUnderline } from './handlers/handleUnderline/handleUnderline';

export default ({ hasBotCommands, userMentions, keyWords }) => {
  const symbolsChecks = {
    '*': handleStars,
    '~': handleStroke,
    _: handleUnderline,
    '.': handleLink,
    '[': (text, index, symbolMatches) => {
      symbolMatches['['].push({
        start: index,
        end: index + 1,
      });
    },
    ']': handleCloseSquareBracket,
    '@': handleDog(userMentions),
    '`': handleEscapedText,
  };

  if (hasBotCommands) {
    symbolsChecks['/'] = handleBotCommands;
  }

  // it should be the last handler
  // as it could rewrite already existing handlers
  if (keyWords && keyWords.length > 0) {
    handleKeyWords(keyWords, symbolsChecks);
  }

  return symbolsChecks;
};
