import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useRef } from 'react';
import { InView } from 'react-intersection-observer';
import { CSSTransition } from 'react-transition-group';

import styles from './Message.styles.m.css';
import { MessageContextMenu } from './MessageContextMenu/MessageContextMenu';
import { Payload as PayloadComponent } from './Payload/Payload';
import { usePresenter } from './presenter';

const CSSTransitionClasses = {
  enterActive: styles.focusMessage,
};

export const Message = observer(({ message, scrollRef, messageViewPageType }) => {
  const messageContainerRef = useRef(null);

  const presenter = usePresenter({ message, messageViewPageType });

  const messageClassName = classNames(styles.message, styles[`type-${presenter.payloadType}`], {
    [styles.topGap]: presenter.message.isLocalFirst,
    [styles.isSelected]: presenter.message.isSelected,
  });

  return (
    <CSSTransition
      in={presenter.hasAnimation}
      timeout={2000}
      classNames={CSSTransitionClasses}
      onEntered={presenter.onFinishAnimation}
    >
      <div
        className={messageClassName}
        data-message-id={presenter.message.id}
        ref={messageContainerRef}
        role="button"
        tabIndex={0}
        onDoubleClick={presenter.message.canBeQuoted ? presenter.onMessageDoubleClick : null}
      >
        {presenter.hasViewObserver && (
          <InView
            key="viewObserver"
            className={styles.viewObserver}
            root={scrollRef.current}
            onChange={presenter.onView}
          />
        )}

        {presenter.hasPreviewObserver && (
          <InView
            key="preViewObserver"
            className={styles.preViewObserver}
            root={scrollRef.current}
            onChange={presenter.onPreview}
          />
        )}
        {presenter.hasContextMenu && (
          <MessageContextMenu
            reference={messageContainerRef}
            message={presenter.message}
            messageViewPageType={messageViewPageType}
          />
        )}

        <div className={styles.wrapper}>
          <PayloadComponent
            common={presenter}
            messageViewPageType={messageViewPageType}
          />
        </div>
      </div>
    </CSSTransition>
  );
});
