import { getCatalog } from 'APP/model/catalog/catalogModel';
import { CatalogType, ICatalog } from 'APP/model/catalog/catalogModel.types';

export const loadCatalog = async (type: CatalogType): Promise<ICatalog | null> => {
  try {
    return await getCatalog({ catalogType: type });
  } catch {
    return null;
  }
};
