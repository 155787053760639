import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { withRouter } from 'react-router-dom';

import NoContactImage from 'APP/images/gem-mascot-contacts.svg';
import { useTranslation } from 'APP/packages/translations';
import ContactsListWithStubs from 'MAIN/Popups/GetParametersManager/components/ContactsListWithStubs';
import { PopupUserItem } from 'MAIN/Popups/GetParametersManager/components/PopupUserItem/PopupUserItem';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';
import { InfoBlock, InfoBlockTypes } from 'UIKIT/InfoBlock/InfoBlock';
import { SearchInput } from 'UIKIT/SearchInput/SearchInput';

import usePresenter from './presenter';
import styles from './style.m.scss';

const NewMessage = () => {
  const { t } = useTranslation();
  const presenter = usePresenter();

  const isLoading = presenter.isLoading;

  return (
    <>
      <SearchInput
        className={styles.search}
        onChange={presenter.setSearchString}
        placeholder={t('main_menu_search_note')}
        value={presenter.searchString}
      />
      <div className={classNames(styles.newMessage, customScrollStyles.container)}>
        <ContactsListWithStubs
          isLoading={isLoading}
          isSearchMode={presenter.isSearchMode}
          contacts={presenter.contacts}
          keyExtractor={(contact) => contact.id}
          NoContactsPlaceholder={() => (
            <InfoBlock
              title={t('no_contacts_yet')}
              descriptions={[t('contacts_empty_description')]}
              type={InfoBlockTypes.Modal}
              image={NoContactImage}
            />
          )}
          renderItem={(contact) => {
            return (
              <PopupUserItem
                user={contact}
                onClick={presenter.handleContactClick}
              />
            );
          }}
        />
      </div>
    </>
  );
};

export default withRouter(observer(NewMessage));
