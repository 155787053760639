import { observer } from 'mobx-react';
import React, { FC } from 'react';

import SelectContacts from 'APP/components/SelectContacts';
import { Button } from 'UIKIT/Button/Button';
import { PopupFooter } from 'UIKIT/PopupFooter/PopupFooter';

import { useContentPresenter } from './Content.presenter';
import { ShareInviteLink } from './ShareInviteLink/ShareInviteLink';

interface IContentProps {
  onClose(): void;
  groupId: string;
}

export const Content: FC<IContentProps> = observer(({ onClose, groupId }) => {
  const presenter = useContentPresenter({ onClose, groupId });

  return (
    <>
      <SelectContacts
        groupId={presenter.group.id}
        contacts={presenter.candidates}
        selectedContactsIds={presenter.selectedCandidatesIds}
        isLoading={presenter.isLoadingCandidates}
        setSelectedIds={presenter.setSelectedIds}
        setSearchValue={presenter.setSearchValue}
        searchContacts={presenter.searchContacts}
        targetGroupType={presenter.group.type}
      >
        {/* ToDO: remove after rewriting SelectContacts to typescript */}
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/*  @ts-ignore */}
        <ShareInviteLink />
      </SelectContacts>
      <PopupFooter>
        <Button
          isLoading={presenter.isSubmitting}
          isDisabled={presenter.isDisabled}
          onClick={presenter.onSubmit}
          theme="1"
        >
          {presenter.buttonText}
        </Button>
      </PopupFooter>
    </>
  );
});
