import { useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { PERMISSIONS_REQUEST_TYPES } from 'APP/constants/calls';
import { PermissionsRequestStatuses } from 'APP/model/call/callModel.types';
import { useTranslation } from 'APP/packages/translations';
import IcClose from 'ICONS/ic-close-24.svg';
import IcDone from 'ICONS/ic-done.svg';
import Entities from 'STORE';

export const usePresenter = (permissionType, userId) => {
  const { t } = useTranslation();

  const presenter = useLocalStore(() => ({
    isPopoverOpened: false,

    togglePopover() {
      this.isPopoverOpened = !this.isPopoverOpened;
    },

    closePopover() {
      this.isPopoverOpened = false;
    },

    onAction: (status) => {
      Tasks.calls.updatePermissionRequestForUser({
        channelId: presenter.channelId,
        access: PERMISSIONS_REQUEST_TYPES[permissionType],
        status,
        userId,
      });
      presenter.closePopover();
    },

    get channelId() {
      return Entities.Calls.ongoingCall?.channelId;
    },

    get menu() {
      return [
        {
          key: 'approve',
          icon: IcDone,
          title: t('call_permissions_approve_request'),
          onClick: () => presenter.onAction(PermissionsRequestStatuses.Approved),
        },
        {
          key: 'decline',
          icon: IcClose,
          title: t('call_permissions_decline_request'),
          onClick: () => presenter.onAction(PermissionsRequestStatuses.Rejected),
          style: 'danger',
        },
      ];
    },
  }));

  return presenter;
};
