import { BUCKET_MEDIA_HOST } from './constants';

export function getFixedSizeBlurredImage(
  url: string,
  options: {
    width: number;
    height: number;
  },
  size?: {
    width: number;
    height: number;
  }
): string {
  const { width, height } = options;
  const pixelRatio = window.devicePixelRatio || 1;
  if (url && url.includes(BUCKET_MEDIA_HOST) && (width || height)) {
    const params = [
      `w${Math.floor(width * pixelRatio)}`,
      `h${Math.floor(height * pixelRatio)}`,
      'fSoften=1,100,0',
    ];
    // found experimentally.
    // Resizer breaks at ratio of less than 0.3 and more than 3.3
    if (size && size.width && size.height) {
      const ratio = size.width / size.height;
      if (ratio > 0.03 && ratio < 33.3) {
        params.push('n');
      }
    }
    if (window.supportsWEBP) {
      params.push('rw');
    }
    return `${url.split('=')[0]}=${params.join('-')}`;
  }
  return url;
}
