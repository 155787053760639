import React, { PureComponent } from 'react';

import styles from './styles.m.scss';

class Slider extends PureComponent {
  render() {
    const { currentTime, duration, canPlay, changeTime, step } = this.props;
    const fillFixer = ((currentTime - duration / 2) / duration) * 2; // avoid gap between thumb and filled track
    const filled = (currentTime / duration) * 100 - fillFixer;

    return (
      <>
        <div
          className={styles.filled}
          style={{ width: `${filled}%` }}
        />
        <input
          className={styles.slider}
          type="range"
          name="volume"
          min="0"
          value={currentTime}
          max={duration}
          step={step}
          disabled={!canPlay}
          onInput={changeTime}
          onChange={changeTime}
        />
      </>
    );
  }
}

export default Slider;
