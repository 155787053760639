import { useLocalStore } from 'mobx-react';
import { RefObject, useEffect, useLayoutEffect, useRef } from 'react';

import Tasks from 'APP/Tasks';
import { showAlert } from 'APP/Tasks/app/showAlert/showAlert';
import { UpdateReason } from 'APP/constants/scroll';
import bus from 'APP/packages/bus';
import { platformInfo } from 'APP/packages/platformInfo/platformInfo';
import { useTranslation } from 'APP/packages/translations';
import Entities from 'APP/store';
import { Group } from 'STORE/Groups/Group';
import type { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';
import { IMessagesScrollState } from 'STORE/Messages/Messages';
import { debounce } from 'UTILS/debounce';
import { CancelPromiseHandler, makeCancellablePromise } from 'UTILS/makeCancellablePromise';

import { calculateIsLocalFlags, groupMessages, IGroupedMessages } from './Messages.utils';

const MIN_NUMBER_RENDER_MESSAGES = 25;
const MESSAGES_LOAD_LIMIT = 100;

const MIN_BOTTOM_OFFSET = 20;
export const LOAD_OFFSET = 300; // px

const MIN_MESSAGE_HEIGHT_CHAT = 30;
const MIN_MESSAGE_HEIGHT_CHANNEL = 40;

const SCROLL_DEBOUNCE = 200;
const SCROLL_SMOOTH_ANIMATION = 500;

interface IRenderRange {
  lastTs: number;
  firstTs: number;
}

interface IScrollOptions {
  top: number;
  bottom: number;
  offset: number;
  messageId: string;
  isAnimated: boolean;
  newMessage: boolean;
  forceContentRepaint: boolean;
}

interface IMessagesPresenterParams {
  groupId: string;
  scrollRef: RefObject<HTMLDivElement>;
}

interface IMessagesPresenter {
  group: Group;
  messages: ChatMessage[];
  batchHash: string;
  lastMessage: ChatMessage;
  firstMessage: ChatMessage;
  renderRange: IRenderRange | null;
  batchMessages: ChatMessage[];
  currentBatchTs: number | null;
  scrollOptions: IScrollOptions | null;
  messagesGroups: [string, IGroupedMessages[]][];
  cancelLoadMessages: CancelPromiseHandler | null;
  isScrollingWithAnimation: boolean;
  init(): Promise<void>;
  setRenderRange(targetMessageTs: number | null): void;
  onScroll(): void;
  onDebounceScroll(): void;
  getMessagesScrollState(minBottomOffset?: number | null): Partial<IMessagesScrollState> | null;
  scrollToMessageState(
    scrollState: Partial<IMessagesScrollState>,
    options?: Partial<IScrollOptions>
  ): void;
  handleLoadAbove(): Promise<void>;
  handleLoadBelow(): Promise<void>;
  handleNewMessage(): Promise<void>;
  handleEditMessage(): void;
  handleFocusMessage(): Promise<void>;
  handleScrollToBottom(): Promise<void>;
  handleScrollToPrevState(): Promise<void>;
  handleScrollUnfocusedChat(): Promise<void>;
  handleScrollToNewMessages(): Promise<void>;
  handleScrollChanged(): Promise<void>;
  getLastBatchMessages(): Promise<boolean>;
  getBatchByMessageId(messageId: string): Promise<boolean>;
  getBatchByMessageTs(messageTs: number): Promise<boolean>;
  setScrollOptions(options: Partial<IScrollOptions> | null): void;
  updateScrollPosition(): void;
  handleScrollWithAnimation(): void;
  forceContentRepaint(): void;
}

interface IUseMessagesPresenter {
  newMessagesPlaceholderRef: RefObject<HTMLDivElement>;
  containerRef: RefObject<HTMLDivElement>;
  presenter: IMessagesPresenter;
}

export const useMessagesPresenter = (data: IMessagesPresenterParams): IUseMessagesPresenter => {
  const { t } = useTranslation();
  const { scrollRef, groupId } = data;
  const newMessagesPlaceholderRef = useRef<HTMLDivElement>(null);
  const scrollTop = useRef<number>(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const isScrolling = useRef(false);

  const presenter = useLocalStore<IMessagesPresenter>(() => ({
    currentBatchTs: null,
    scrollOptions: null,
    renderRange: null,
    cancelLoadMessages: null,
    isScrollingWithAnimation: false,

    get group(): Group {
      return Entities.GroupsStore.getGroupById(groupId)!;
    },

    get newMessagesPlaceholderTs(): number {
      return presenter.group.messagesStore.newMessagesPlaceholderTs;
    },

    get batchMessages(): ChatMessage[] {
      return presenter.group.messagesStore.getBatchMessages(presenter.currentBatchTs);
    },

    get messages(): ChatMessage[] {
      if (!presenter.renderRange) {
        return [];
      }

      return presenter.batchMessages
        .filter(
          (message) =>
            message.expectedServerTime >= presenter.renderRange!.firstTs &&
            message.expectedServerTime <= presenter.renderRange!.lastTs
        )
        .sort((a: ChatMessage, b: ChatMessage) => a.expectedServerTime - b.expectedServerTime);
    },

    get batchHash(): string {
      const batchMessages = presenter.batchMessages;
      return `${batchMessages[0]?.expectedServerTime || ''}_${batchMessages.length}_${
        batchMessages[batchMessages.length - 1]?.expectedServerTime || ''
      }_${presenter.group.messagesStore.newMessagesPlaceholderTs}`;
    },

    get lastMessage(): ChatMessage {
      return presenter.messages[presenter.messages.length - 1] || null;
    },

    get firstMessage(): ChatMessage {
      return presenter.messages[0] || null;
    },

    get messagesGroups(): [string, IGroupedMessages[]][] {
      return groupMessages(presenter.messages);
    },

    async init(): Promise<void> {
      presenter.currentBatchTs = null;
      presenter.renderRange = null;

      const group = presenter.group;
      group.messagesStore.setNewMessagesPlaceholderTs(group.seenTs);

      if (group.messagesStore.focusedMessageId) {
        Entities.ChatStore.setScrollChanged(UpdateReason.FocusMessage, {
          messageId: group.messagesStore.focusedMessageId,
          isAnimated: false,
        });
        presenter.handleScrollChanged();
        return;
      }

      if (group.seenTs && group.seenTs < group.lastMessageTs && !Entities.App.isBackPage) {
        Entities.ChatStore.setScrollChanged(UpdateReason.ScrollToNewMessage, {
          isAnimated: false,
        });
        return;
      }

      if (group.messagesStore.saveStatePosition.firstViewedMessageTs) {
        Entities.ChatStore.setScrollChanged(UpdateReason.ScrollToPreviousState, {
          isAnimated: false,
        });
        presenter.handleScrollChanged();
        return;
      }

      Entities.ChatStore.setScrollChanged(UpdateReason.ScrollToBottom, {
        isAnimated: false,
      });
      presenter.handleScrollChanged();
    },

    setScrollOptions(options: IScrollOptions | null): void {
      presenter.scrollOptions = options;
    },

    setRenderRange(targetMessageTs: number | null): void {
      const batchMessages = presenter.batchMessages;

      if (!batchMessages.length || targetMessageTs === null) {
        presenter.renderRange = null;
        return;
      }

      const targetMessage = presenter.group.messagesStore.getMessageByTs(targetMessageTs);
      const messageIndex = batchMessages.findIndex((message) => message.id === targetMessage?.id);

      if (messageIndex === -1) {
        presenter.renderRange = null;
        return;
      }

      // get render limit depending on screen size
      const minMessageHeight = presenter.group.isChannel
        ? MIN_MESSAGE_HEIGHT_CHANNEL
        : MIN_MESSAGE_HEIGHT_CHAT;
      const limitMultiplier = 1.2;
      const limitByScreen =
        Math.ceil(
          (((containerRef.current?.offsetHeight || 0) / minMessageHeight) * limitMultiplier) / 10
        ) * 10;
      const minLimit = MIN_NUMBER_RENDER_MESSAGES;
      const messageRenderLimit = Math.max(limitByScreen, minLimit);

      const firstIndex = Math.max(0, messageIndex - messageRenderLimit);
      const lastIndex = Math.min(batchMessages.length - 1, messageIndex + messageRenderLimit);

      presenter.renderRange = {
        firstTs: batchMessages[firstIndex].expectedServerTime,
        lastTs: batchMessages[lastIndex].expectedServerTime,
      };
    },

    onScroll(): void {
      isScrolling.current = true;
      presenter.onDebounceScroll();
    },

    onDebounceScroll: debounce((): void => {
      if (!scrollRef.current) {
        return;
      }
      if (Math.abs(scrollTop.current - scrollRef.current.scrollTop) > 2) {
        bus.ui.broadcastScrollMessages();
      }
      scrollTop.current = scrollRef.current.scrollTop;

      const scrollState = presenter.getMessagesScrollState(MIN_BOTTOM_OFFSET);

      if (scrollState) {
        presenter.group.messagesStore.setSavePosition(scrollState);
      }

      isScrolling.current = false;
    }, SCROLL_DEBOUNCE),

    getMessagesScrollState(
      minBottomOffset: number | null = null
    ): Partial<IMessagesScrollState> | null {
      if (!scrollRef.current) {
        return null;
      }

      const scrollBottom =
        scrollRef.current.scrollHeight -
        scrollRef.current.scrollTop -
        scrollRef.current.offsetHeight;
      const firstVisibleMessage = presenter.group.messagesStore.inViewMessageIds
        .map((id) => presenter.group.messagesStore.getMessageById(id))
        .filter((message) => message !== null)
        .sort((a: ChatMessage, b: ChatMessage) => a.expectedServerTime - b.expectedServerTime)[0];

      if (firstVisibleMessage && (minBottomOffset === null || scrollBottom >= minBottomOffset)) {
        const node = scrollRef.current.querySelector(
          `[data-message-id="${firstVisibleMessage.id}"]`
        );
        if (node) {
          const { top } = node.getBoundingClientRect();
          const { top: parentTop } = scrollRef.current.getBoundingClientRect();
          return {
            firstViewedMessageTs: firstVisibleMessage.expectedServerTime,
            offsetPosition: parentTop - top,
          };
        }
      }

      return { isBottom: true };
    },

    scrollToMessageState(
      scrollState: Partial<IMessagesScrollState>,
      options: Partial<IScrollOptions> = {}
    ): void {
      const message =
        typeof scrollState.firstViewedMessageTs === 'number' &&
        presenter.group.messagesStore.getMessageByTs(scrollState.firstViewedMessageTs);
      if (message) {
        presenter.setScrollOptions({
          messageId: message.id,
          offset: scrollState.offsetPosition!,
          ...options,
        });
      } else {
        presenter.setScrollOptions({
          bottom: 0,
          ...options,
        });
      }
    },

    async getBatchByMessageId(messageId: string): Promise<boolean> {
      presenter.cancelLoadMessages?.();

      const group = presenter.group;
      const message = group.messagesStore.getMessageById(messageId);
      const messages = message
        ? group.messagesStore.getBatchMessages(message.expectedServerTime)
        : [];

      if (
        messages.length <= MIN_NUMBER_RENDER_MESSAGES &&
        (!group.messagesStore.isLoadedLastMessages || !group.messagesStore.isLoadedFirstMessages)
      ) {
        const { promise: loadRangeAroundMessages, cancel } = makeCancellablePromise(
          Tasks.messaging.loadRangeAroundMessages({
            groupId: group.id,
            messageId,
            halfLimit: MESSAGES_LOAD_LIMIT / 2,
          })
        );

        presenter.cancelLoadMessages = cancel;
        const newMessages = await loadRangeAroundMessages;

        if (!newMessages.length) {
          return false;
        }
      }

      presenter.currentBatchTs = group.messagesStore.getMessageById(messageId)?.expectedServerTime;
      presenter.setRenderRange(presenter.currentBatchTs);

      return true;
    },

    async getBatchByMessageTs(messageTs: number): Promise<boolean> {
      presenter.cancelLoadMessages?.();

      const group = presenter.group;
      const messages = group.messagesStore.getBatchMessages(messageTs);
      // if the batch is empty or one message from the newsfeed
      let hasLoadMessages =
        messages.length <= MIN_NUMBER_RENDER_MESSAGES &&
        (!group.messagesStore.isLoadedLastMessages || !group.messagesStore.isLoadedFirstMessages);
      // if the messages below are not loaded
      hasLoadMessages =
        hasLoadMessages ||
        (messages[messages.length - 1]?.expectedServerTime <= messageTs &&
          group.lastMessageTs > messageTs);
      if (hasLoadMessages) {
        const { promise: loadRangeMessageByTs, cancel } = makeCancellablePromise(
          Tasks.messaging.loadRangeMessageByTs(group.id, messageTs, MESSAGES_LOAD_LIMIT / 2)
        );

        presenter.cancelLoadMessages = cancel;
        await loadRangeMessageByTs;
      }
      presenter.currentBatchTs = messageTs;
      presenter.setRenderRange(messageTs);

      return true;
    },

    async getLastBatchMessages(): Promise<boolean> {
      presenter.cancelLoadMessages?.();

      const group = presenter.group;
      let messages = [];
      if (group.lastMessageTs) {
        messages = group.messagesStore.getBatchMessages(group.lastMessageTs);
      } else {
        // group without counter (guest channel)
        messages = group.messagesStore.getBatchMessages(
          group.messagesStore.lastMessage?.expectedServerTime
        );
      }

      if (
        messages.length <= MIN_NUMBER_RENDER_MESSAGES &&
        (!group.messagesStore.isLoadedLastMessages || !group.messagesStore.isLoadedFirstMessages)
      ) {
        const { promise: loadMessagesAboveTheTime, cancel } = makeCancellablePromise(
          Tasks.messaging.loadMessagesAboveTheTime(group.id, Date.now(), true, MESSAGES_LOAD_LIMIT)
        );

        presenter.cancelLoadMessages = cancel;
        const result = await loadMessagesAboveTheTime;

        if (result) {
          const isLoadedLastMessages = group.messagesStore.lastMessage
            ? group.lastMessageTs <= group.messagesStore.lastMessage.serverTime
            : true;
          group.messagesStore.setLoadedLastMessages(isLoadedLastMessages);
          messages = group.messagesStore.getBatchMessages(
            group.messagesStore.lastMessage?.expectedServerTime
          );
        }
      }
      const lastMessages = messages[messages.length - 1];
      presenter.currentBatchTs = Number(lastMessages?.expectedServerTime) || Date.now();
      presenter.setRenderRange(presenter.currentBatchTs);

      return true;
    },

    async handleLoadAbove(): Promise<void> {
      presenter.cancelLoadMessages?.();

      Entities.ChatStore.setScrollChanged(null, null);

      const firstRenderMessages = presenter.messages[0];

      if (!firstRenderMessages) {
        return;
      }

      // load previews messages
      if (firstRenderMessages.expectedServerTime <= presenter.batchMessages[0].expectedServerTime) {
        const { promise: loadMessagesAboveTheTime, cancel } = makeCancellablePromise(
          Tasks.messaging.loadMessagesAboveTheTime(
            presenter.group.id,
            firstRenderMessages?.expectedServerTime,
            true,
            MESSAGES_LOAD_LIMIT
          )
        );

        presenter.cancelLoadMessages = cancel;
        await loadMessagesAboveTheTime;
      }

      const scrollState = this.getMessagesScrollState();

      presenter.setRenderRange(firstRenderMessages.expectedServerTime);

      if (scrollState) {
        presenter.scrollToMessageState(scrollState, { forceContentRepaint: true });
      }
    },

    async handleLoadBelow(): Promise<void> {
      presenter.cancelLoadMessages?.();

      Entities.ChatStore.setScrollChanged(null, null);
      const messages = presenter.messages;
      const lastRenderMessage = messages[messages.length - 1];

      if (!lastRenderMessage) {
        return;
      }

      // load news messages
      if (
        lastRenderMessage.expectedServerTime >=
        presenter.batchMessages[presenter.batchMessages.length - 1].expectedServerTime
      ) {
        const { promise: loadMessagesBelowTheTime, cancel } = makeCancellablePromise(
          Tasks.messaging.loadMessagesBelowTheTime(
            presenter.group.id,
            lastRenderMessage?.serverTime,
            true,
            MESSAGES_LOAD_LIMIT
          )
        );

        presenter.cancelLoadMessages = cancel;
        await loadMessagesBelowTheTime;
      }

      const scrollState = this.getMessagesScrollState();

      presenter.setRenderRange(lastRenderMessage.expectedServerTime);

      if (scrollState) {
        presenter.scrollToMessageState(scrollState, { forceContentRepaint: true });
      }
    },

    handleEditMessage(): void {
      if (scrollTop.current !== scrollRef.current?.scrollTop) {
        return;
      }

      const { isAnimated } = Entities.ChatStore.scrollOptions as IScrollOptions;

      presenter.scrollToMessageState(presenter.group.messagesStore.saveStatePosition, {
        isAnimated,
      });

      Entities.ChatStore.setScrollChanged(null, null);
    },

    async handleFocusMessage(): Promise<void> {
      const { messageId, isAnimated } = Entities.ChatStore.scrollOptions as IScrollOptions;
      Entities.ChatStore.setScrollChanged(null, null);
      const message = presenter.messages.find((x) => x.id === messageId);

      if (!message) {
        const res = await presenter.getBatchByMessageId(messageId);
        if (!res) {
          showAlert(t('message_has_been_deleted'));
          presenter.group.messagesStore.unfocusMessage();
          await presenter.init();
          return;
        }
      }

      presenter.setScrollOptions({
        messageId,
        isAnimated,
      });
      presenter.onScroll();
    },

    async handleScrollToPrevState(): Promise<void> {
      // render prev viewed messages (save state)
      Entities.ChatStore.setScrollChanged(null, null);
      await presenter.getBatchByMessageTs(
        presenter.group.messagesStore.saveStatePosition.firstViewedMessageTs!
      );

      presenter.scrollToMessageState(presenter.group.messagesStore.saveStatePosition);
    },

    async handleScrollUnfocusedChat(): Promise<void> {
      const { isAnimated } = Entities.ChatStore.scrollOptions as IScrollOptions;
      Entities.ChatStore.setScrollChanged(null, null);
      presenter.group.messagesStore.unfocusMessage();
      presenter.group.messagesStore.setNewMessagesPlaceholderTs(presenter.group.seenTs);

      if (
        presenter.group.messagesStore.saveStatePosition.isBottom &&
        !Entities.ChatStore.isAutoScrollDisabled
      ) {
        await presenter.getBatchByMessageTs(presenter.group.seenTs);
        presenter.setScrollOptions({
          newMessage: true,
          isAnimated,
        });
      }
    },

    async handleNewMessage(): Promise<void> {
      Entities.ChatStore.setScrollChanged(null, null);
      presenter.group.messagesStore.unfocusMessage();

      if (
        presenter.group.messagesStore.saveStatePosition.isBottom &&
        !Entities.ChatStore.isAutoScrollDisabled &&
        !isScrolling.current
      ) {
        await presenter.getLastBatchMessages();
        presenter.setScrollOptions({
          bottom: 0,
          isAnimated: true,
        });
      }
    },

    async handleScrollToBottom(): Promise<void> {
      const { isAnimated } = Entities.ChatStore.scrollOptions as IScrollOptions;
      Entities.ChatStore.setScrollChanged(null, null);
      presenter.group.messagesStore.unfocusMessage();
      await presenter.getLastBatchMessages();
      presenter.setScrollOptions({
        bottom: 0,
        isAnimated,
      });
      presenter.group.messagesStore.setSavePosition({ isBottom: true });
    },

    async handleScrollToNewMessages(): Promise<void> {
      const { isAnimated } = Entities.ChatStore.scrollOptions as IScrollOptions;
      Entities.ChatStore.setScrollChanged(null, null);
      presenter.group.messagesStore.unfocusMessage();
      presenter.group.messagesStore.setNewMessagesPlaceholderTs(presenter.group.seenTs);
      await presenter.getBatchByMessageTs(presenter.group.seenTs);

      if (presenter.group.seenTs >= presenter.group.lastMessageTs) {
        presenter.setScrollOptions({
          bottom: 0,
          isAnimated,
        });
      } else {
        presenter.setScrollOptions({
          newMessage: true,
          isAnimated,
        });
      }
    },

    async handleScrollChanged(): Promise<void> {
      switch (Entities.ChatStore.scrollChanged as UpdateReason | null) {
        case UpdateReason.LoadAbove: {
          await presenter.handleLoadAbove();
          break;
        }
        case UpdateReason.LoadBelow: {
          await presenter.handleLoadBelow();
          break;
        }
        case UpdateReason.EditMessage: {
          presenter.handleEditMessage();
          break;
        }
        case UpdateReason.FocusMessage: {
          await presenter.handleFocusMessage();
          break;
        }
        case UpdateReason.ScrollToPreviousState: {
          await presenter.handleScrollToPrevState();
          break;
        }
        case UpdateReason.ScrollUnfocusedChat: {
          await presenter.handleScrollUnfocusedChat();
          break;
        }
        case UpdateReason.NewMessage: {
          await presenter.handleNewMessage();
          break;
        }
        case UpdateReason.ScrollToBottom: {
          await presenter.handleScrollToBottom();
          break;
        }
        case UpdateReason.ScrollToNewMessage: {
          await presenter.handleScrollToNewMessages();
          break;
        }
        default: {
          return;
        }
      }
    },

    updateScrollPosition(): void {
      if (!presenter.scrollOptions || !scrollRef.current) {
        return;
      }

      const {
        top,
        bottom = 0,
        messageId,
        isAnimated,
        offset,
        newMessage = false,
        forceContentRepaint,
      } = presenter.scrollOptions;

      if (isAnimated) {
        presenter.handleScrollWithAnimation();
      }

      if (forceContentRepaint) {
        presenter.forceContentRepaint();
      }

      if (newMessage) {
        const node = newMessagesPlaceholderRef.current;
        if (node) {
          node.scrollIntoView({
            behavior: isAnimated ? 'smooth' : undefined,
            block: 'center',
          });
          return;
        }
      }
      if (messageId) {
        const node = scrollRef.current.querySelector<HTMLElement>(
          `[data-message-id="${messageId}"]`
        );
        if (node && isNaN(Number(offset))) {
          const height = node.offsetHeight;
          const parentHeight = scrollRef.current.offsetHeight;
          node.scrollIntoView({
            behavior: isAnimated ? 'smooth' : undefined,
            block: height < parentHeight ? 'center' : 'start',
          });
          return;
        }
        if (node) {
          const scrollTop = node.offsetTop + offset;
          scrollRef.current.scrollTo({
            top: scrollTop,
            behavior: isAnimated ? 'smooth' : undefined,
          });
          return;
        }
      }

      if (!isNaN(Number(top))) {
        scrollRef.current.scrollTo({
          top,
          behavior: isAnimated ? 'smooth' : undefined,
        });
        return;
      }

      const scrollTo = scrollRef.current.scrollHeight - scrollRef.current.offsetHeight - bottom;
      scrollRef.current.scrollTo({
        top: scrollTo,
        behavior: isAnimated ? 'smooth' : undefined,
      });
    },

    /* we need to disable load observable, when scrolling with animation
       so that they do not publish events for loading new messages */
    handleScrollWithAnimation(): void {
      presenter.isScrollingWithAnimation = true;

      setTimeout(() => {
        presenter.isScrollingWithAnimation = false;
      }, SCROLL_SMOOTH_ANIMATION);
    },

    /* This is fix for Safari browser to force browser to rerender content.
       Sometimes, after 'messagesGroups' prop changes, content changes are not reflected in safari browser
       In my case this happened on events from intersection observer. */
    forceContentRepaint(): void {
      if (platformInfo.isSafari) {
        document.body.style.visibility = 'hidden';
        void document.body.offsetHeight;
        document.body.style.visibility = 'visible';
      }
    },
  }));

  useLayoutEffect(() => {
    if (presenter.batchMessages.length) {
      calculateIsLocalFlags(presenter.batchMessages, presenter.group.isChannel);
    }
  }, [presenter.batchHash]);

  useLayoutEffect(() => {
    presenter.updateScrollPosition();
  }, [presenter.scrollOptions]);

  useLayoutEffect(() => {
    presenter.handleScrollChanged();
  }, [Entities.ChatStore.scrollChanged]);

  useEffect(() => {
    if (scrollRef.current) {
      presenter.onScroll();
      scrollRef.current.addEventListener('scroll', presenter.onScroll, {
        passive: true,
      });
    }
    return () => {
      if (scrollRef.current) {
        scrollRef.current.removeEventListener('scroll', presenter.onScroll);
      }
    };
  }, [scrollRef.current]);

  useEffect(() => {
    presenter.init();
  }, [Entities.GroupsStore.activeGroupId]);

  return { newMessagesPlaceholderRef, containerRef, presenter };
};
