import { action, observable } from 'mobx';
import { FC } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import { IPopupOptions, Popups } from 'STORE/App/Popups/Popups';

export class Popup<T = any> {
  id: string;
  @observable isHidden = false;
  popups: Popups;
  openTs: number;
  options: IPopupOptions<T>;
  Component: FC<ICommonPopupsProps<T>>;

  constructor(
    popups: Popups,
    Component: FC<ICommonPopupsProps<T>>,
    options: IPopupOptions<T> = {}
  ) {
    this.id = uuidv4();
    this.popups = popups;
    this.options = options;
    this.openTs = Date.now();
    this.Component = Component;

    this.hide = this.hide.bind(this);
    this.show = this.show.bind(this);
    this.close = this.close.bind(this);
  }

  @action
  hide(): void {
    this.isHidden = true;
  }

  @action
  show(): void {
    this.isHidden = false;
  }

  @action
  close(): void {
    this.popups.closePopup(this);
  }
}
