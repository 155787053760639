import { useLocalStore } from 'mobx-react';

import Entities from 'STORE';
import { Call } from 'STORE/Calls/Call/Call';

interface IFullScreenCallViewPanelPresenter {
  opponentsLength: number;
  ongoingCall: Call | null;
  callTitle: string;
  canShowPermissionsRequestsButton: boolean;
  isRecording: boolean;
  isGuestCall: boolean;
}

export const useFullScreenCallViewPanelPresenter = (): IFullScreenCallViewPanelPresenter => {
  const presenter = useLocalStore<IFullScreenCallViewPanelPresenter>(() => ({
    get ongoingCall(): Call | null {
      return Entities.Calls.ongoingCall;
    },

    get opponentsLength(): number {
      return presenter.ongoingCall?.opponents.opponentList.length || 0;
    },

    get callTitle(): string {
      return presenter.ongoingCall?.avatar?.title || '';
    },

    get canShowPermissionsRequestsButton(): boolean {
      if (!presenter.ongoingCall) {
        return false;
      }

      return (
        presenter.ongoingCall.isMeInitiatorOwnerOrAdmin &&
        presenter.ongoingCall.isGroupCall &&
        !presenter.ongoingCall.isPermissionsRequestsEmpty
      );
    },

    get isRecording(): boolean {
      return !!presenter.ongoingCall?.isRecording;
    },

    get isGuestCall(): boolean {
      return !!presenter.ongoingCall?.isGuestCall;
    },
  }));

  return presenter;
};
