import React, { useCallback, FC } from 'react';
import { useParams } from 'react-router-dom';

import { UnauthorizedCallType } from 'APP/model/unauthorizedCall/unauthorizedCallModel.constants';
import { ICallInvitationInfo } from 'APP/model/unauthorizedCall/unauthorizedCallModel.types';
import { TextPlaceholder } from 'APP/packages/placeholders/TextPlaceholder/TextPlaceholder';
import { useTranslation } from 'APP/packages/translations';
import { RouterLink } from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import { Avatar } from 'UIKIT/Avatar/Avatar';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';
import { Button } from 'UIKIT/Button/Button';

import styles from './JoinBlock.styles.m.css';

interface IJoinBlockProps {
  invitationInfo: ICallInvitationInfo | null;
  isGroupCall?: boolean;
}

export const JoinBlock: FC<IJoinBlockProps> = ({ invitationInfo, isGroupCall }) => {
  const { t } = useTranslation();
  const navigateTo = useNavigateTo();
  const params = useParams<{ id: string; channelId?: string; callType: UnauthorizedCallType }>();

  const onJoinAsGuestClick = useCallback(() => {
    navigateTo({
      to: `${isGroupCall ? RouterLink.callInvitationGroup : RouterLink.callInvitation}/name`,
      params: {
        id: params.id,
        callType: params.callType,
        channelId: params.channelId,
      },
    });
  }, [navigateTo, params.id]);

  return (
    <div className={styles.container}>
      <Avatar
        className={styles.avatar}
        type={AvatarTypes.Round_50}
        title={invitationInfo?.userName}
        url={invitationInfo?.imageUrl}
      />
      {invitationInfo ? (
        <>
          <h2 className={styles.title}>
            {t(isGroupCall ? 'call_invite_link_title' : 'call_invitation')}
          </h2>
          <h3 className={styles.subTitle}>
            {isGroupCall
              ? t('call_invite_link_group')
              : t('call_invite_link_p2p', { 0: invitationInfo?.userName })}
          </h3>
        </>
      ) : (
        <>
          <TextPlaceholder
            className={styles.titleSkeleton}
            width={180}
          />
          <TextPlaceholder
            className={styles.subTitleSkeleton}
            width={260}
          />
        </>
      )}
      <Button
        onClick={onJoinAsGuestClick}
        className={styles.joinAsGuestButton}
        size="20"
        theme="2"
      >
        {t('calls_join')}
      </Button>
    </div>
  );
};
