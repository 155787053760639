import { observer } from 'mobx-react';
import React, { Suspense } from 'react';
import { hot } from 'react-hot-loader';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { GreetingCard } from 'APP/GreetingCard/GreetingCard';
import { LinkExpired as LinkExpiredPage } from 'APP/pages/LinkExpired/LinkExpired';
import { UnauthorizedView } from 'APP/unauthorized/UnauthorizedView';

import Modules from '../Modules';
import { MessengerRoutes } from './MessengerRoutes/MessengerRoutes';
import PrivateDomain from './PrivateDomain';
import { RouterLink } from './constants';
import usePresenter from './presenter';

const LoginPage = React.lazy(() => import('APP/pages/Login/Login'));

const AppRouter = () => {
  const presenter = usePresenter();

  return (
    <Router
      basename="/"
      getUserConfirmation={presenter.getUserConfirmation}
    >
      <Modules />
      <Switch>
        <Route path={RouterLink.login}>
          <Suspense fallback={<div />}>
            <LoginPage />
          </Suspense>
        </Route>
        <Route path={RouterLink.linkExpired}>
          <Suspense fallback={<div />}>
            <LinkExpiredPage />
          </Suspense>
        </Route>
        <Route path={RouterLink.unauthorized}>
          <Suspense fallback={<div />}>
            <UnauthorizedView />
          </Suspense>
        </Route>
        <Route path={RouterLink.greetingCard}>
          <Suspense fallback={<div />}>
            <GreetingCard />
          </Suspense>
        </Route>
        <PrivateDomain path="/">
          <MessengerRoutes />
        </PrivateDomain>
      </Switch>
    </Router>
  );
};

export default hot(module)(observer(AppRouter));
