import classNames from 'classnames';
import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';

import styles from './Carousel.styles.m.css';
import { CarouselInfinite } from './CarouselInfinite/CarouselInfinite';
import { CarouselScroll } from './CarouselScroll/CarouselScroll';

interface CarouselProps {
  className?: string;
  children: ReactNode[];
  isInfinite?: boolean;
  activeIndex?: number;
  itemsToShow: number;
  gap: number;
  withShadow?: boolean;
  onActiveIndexChange?(index: number): void;
}

export const Carousel: FC<CarouselProps> = ({
  className,
  children,
  isInfinite = false,
  activeIndex,
  itemsToShow,
  gap,
  withShadow,
  onActiveIndexChange,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [itemWidth, setItemWidth] = useState(0);

  useEffect(() => {
    const updateItemWidth = (): void => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.clientWidth;
        const newItemWidth = (containerWidth - (itemsToShow - 1) * gap) / itemsToShow;
        setItemWidth(newItemWidth);
      }
    };

    updateItemWidth();
    window.addEventListener('resize', updateItemWidth);

    return () => {
      window.removeEventListener('resize', updateItemWidth);
    };
  }, [itemsToShow, gap]);

  return (
    <div
      ref={containerRef}
      className={classNames(className, styles.container)}
    >
      {isInfinite && (
        <CarouselInfinite
          activeIndex={activeIndex}
          itemsToShow={itemsToShow}
          itemWidth={itemWidth}
          gap={gap}
          withShadow={withShadow}
          onChangeActiveIndex={onActiveIndexChange}
        >
          {children}
        </CarouselInfinite>
      )}
      {!isInfinite && (
        <CarouselScroll
          itemsToShow={itemsToShow}
          itemWidth={itemWidth}
          gap={gap}
          withShadow={withShadow}
        >
          {children}
        </CarouselScroll>
      )}
    </div>
  );
};
