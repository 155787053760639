import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { ApplicationTheme } from 'APP/constants/appearance';
import { IconButton, IconViewSizes } from 'APP/packages/UiKit/IconButton';
import { useTranslation } from 'APP/packages/translations';
import FinishCall from 'ICONS/ic-cancel-call.svg';
import { PopoverMenu } from 'UIKIT/PopoverMenu/PopoverMenu';

import { useFinishCallButtonPresenter } from './presenter/FinishCallButton.presenter';

export const FinishCallButton: FC = observer(() => {
  const { presenter, buttonRef } = useFinishCallButtonPresenter();
  const { t } = useTranslation();

  return (
    <>
      <IconButton
        isDisabled={presenter.disabledFinishCall}
        setRef={buttonRef}
        Icon={FinishCall}
        onClick={presenter.finishCall}
        size={IconViewSizes.Large}
        iconSize={IconViewSizes.Medium}
        theme="9"
      />
      <PopoverMenu
        isOpened={presenter.isOpened}
        placement="top-end"
        menuItems={[
          {
            key: 'finishAll',
            title: t('calls_end_for_all'),
            onClick: presenter.finishAll,
          },
          {
            key: 'leave',
            title: t('calls_leave_meeting'),
            onClick: presenter.leaveCall,
          },
        ]}
        reference={buttonRef.current}
        onClose={presenter.closePopover}
        theme={ApplicationTheme.Dark}
      />
    </>
  );
});
