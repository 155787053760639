import React from 'react';

import { useTranslation } from 'APP/packages/translations';

import { CommonPayload } from '../CommonPayload/CommonPayload';

export const UnsupportedPayload = ({ name }) => {
  const { t } = useTranslation();

  return (
    <CommonPayload
      name={name}
      description={t('message_type_unsupported')}
    />
  );
};
