import { IUserReaction } from 'APP/model/reactions/reactionsModel.types';

import { localStorageInstance } from '../instances/localStorageInstance';

const KEY_NAME = 'reactions_packs';

interface IReactionPack {
  lastUpdateTime: number;
  pack: IUserReaction[];
}

export const getReactionPack = (): IReactionPack | null => {
  const data = localStorageInstance.getItem(KEY_NAME);
  if (!data) {
    return null;
  }

  try {
    const { lastUpdateTime, pack = [] } = JSON.parse(data);
    return { lastUpdateTime, pack };
  } catch (error) {
    return null;
  }
};

export const setReactionPack = (pack: IReactionPack): void => {
  const data = JSON.stringify({ ...pack });
  return localStorageInstance.setItem(KEY_NAME, data);
};

export const removeReactionPack = (): void => {
  localStorageInstance.removeItem(KEY_NAME);
};
