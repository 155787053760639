import { RefObject, useCallback, useEffect, useRef, useState } from 'react';

import { IAudioPlayerTrack } from './AudioPlayer.types';

interface IAudioPlayerPresenterOptions {
  track: IAudioPlayerTrack | null;
  autoPlay: boolean;
  newIsPlayingState: boolean;
  newIsPlayListOpenedState: boolean;
  onTogglePlay(isPlaying: boolean): void;
  onTogglePlayList(isOpened: boolean): void;
}

interface IAudioPlayerPresenter {
  audioRef: RefObject<HTMLAudioElement>;
  isPlaying: boolean;
  isPlayListOpened: boolean;
  togglePlay(): void;
  togglePlayList(): void;
  closePlayList(): void;
  play(): void;
  pause(): void;
}

const isAudioPlaying = (audio: HTMLAudioElement | null): boolean => {
  return !(audio?.paused || audio?.ended);
};

export function useAudioPlayerPresenter({
  track,
  autoPlay,
  newIsPlayingState,
  newIsPlayListOpenedState,
  onTogglePlay,
  onTogglePlayList,
}: IAudioPlayerPresenterOptions): IAudioPlayerPresenter {
  const audioRef = useRef<HTMLAudioElement>(null);
  const playPromise = useRef<Promise<void>>(Promise.resolve());

  const [isPlaying, setIsPlayingState] = useState(autoPlay);
  const [isPlayListOpened, setPlayListState] = useState(false);

  const setIsPlaying = useCallback(
    (state) => {
      setIsPlayingState(state);
      onTogglePlay(state);
    },
    [onTogglePlay]
  );

  const play = useCallback(() => {
    const result = audioRef.current?.play() || Promise.resolve();
    playPromise.current = result.catch((error) => {
      if (!(error instanceof DOMException && error.name === 'AbortError')) {
        throw error;
      }
    });
  }, [track]);

  const pause = useCallback(() => {
    playPromise.current.then(() => {
      audioRef.current?.pause();
    });
  }, [track]);

  const togglePlay = useCallback(() => {
    isAudioPlaying(audioRef.current) ? pause() : play();
  }, [track]);

  const togglePlayList = useCallback(() => {
    const isOpened = !isPlayListOpened;
    setPlayListState(isOpened);
    onTogglePlayList(isOpened);
  }, [isPlayListOpened, onTogglePlayList]);

  const closePlayList = useCallback(() => {
    if (isPlayListOpened) {
      setPlayListState(false);
      onTogglePlayList(false);
    }
  }, [isPlayListOpened, onTogglePlayList]);

  useEffect(() => {
    const isPlaying = isAudioPlaying(audioRef.current);

    if (newIsPlayingState && !isPlaying) {
      play();
    } else if (!newIsPlayingState && isPlaying) {
      pause();
    }
  }, [newIsPlayingState]);

  useEffect(() => {
    if (newIsPlayListOpenedState !== isPlayListOpened) {
      setPlayListState(newIsPlayListOpenedState);
      onTogglePlayList(newIsPlayListOpenedState);
    }
  }, [newIsPlayListOpenedState]);

  useEffect(() => {
    const onPlay = (): void => setIsPlaying(true);
    const onPause = (): void => setIsPlaying(false);

    audioRef.current?.addEventListener('play', onPlay);
    audioRef.current?.addEventListener('pause', onPause);

    return () => {
      audioRef.current?.removeEventListener('play', onPlay);
      audioRef.current?.removeEventListener('pause', onPause);
    };
  }, []);

  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.src = '';
        audioRef.current.remove();
      }
    };
  }, []);

  return {
    audioRef,
    isPlaying,
    isPlayListOpened,
    togglePlay,
    togglePlayList,
    closePlayList,
    play,
    pause,
  };
}
