import Entities from 'STORE';

import { GroupsEvent } from '../GroupsEvent';

interface IParticipantsChangedPayload {
  groupId: string;
  usersCount: string;
}

export class GroupParticipantsChanged extends GroupsEvent<IParticipantsChangedPayload> {
  process(): void {
    const { groupId, usersCount } = this.eventPayload || {};
    if (groupId) {
      const group = Entities.GroupsStore.getGroupById(groupId);
      if (group) {
        group.setUsersCount(Number(usersCount) || 0);
      }
    }
  }
}
