import bus from 'APP/packages/bus';
import Entities from 'APP/store';

import { addMessagesToStore } from './addMessagesToStore';
import handleScrollPosition from './handleScrollPosition';

export const handleNewMessages = async ({ messages = [], previousMessageTs }) => {
  if (messages.length === 0) {
    return false;
  }

  const groupId = messages[0].groupId;
  const group = Entities.GroupsStore.getGroupById(groupId);
  bus.messaging.broadcastMessagesChanges(groupId, { eventType: 'message.new' });

  if (!group || group.isFake || (!group.messagesStore.isLoadedLastMessages && previousMessageTs)) {
    return false;
  }

  if (
    group !== Entities.GroupsStore.activeGroup &&
    (group.messagesStore.lastMessage?.serverTime || 0) < previousMessageTs
  ) {
    group.messagesStore.setLoadedLastMessages(false);
    return false;
  }

  await addMessagesToStore({ previousMessageTs, messages, group });

  if (group === Entities.GroupsStore.activeGroup) {
    handleScrollPosition();
  }

  return true;
};
