import escapeAllTagsInside from './escapeAllTagsInside';
import removeConflictPositions from './removeConflictPositions';
import removeNotClosedTagPosition from './removeNotClosedTagPosition';
import removeTagsWithoutContent from './removeTagsWithoutContent';
import syncSquareBrackets from './syncSquareBrackets';

export default ({ symbolsPositions }) => {
  /* --- ``` --- */
  symbolsPositions['```'] = removeTagsWithoutContent({
    positions: symbolsPositions['```'],
  });

  symbolsPositions['```'] = removeNotClosedTagPosition({
    positions: symbolsPositions['```'],
  });

  escapeAllTagsInside({
    symbolsPositions,
    tag: '```',
    ignoreTags: ['```'],
  });

  /* --- user mention --- */
  escapeAllTagsInside({
    symbolsPositions,
    tag: '@',
    ignoreTags: ['@'],
  });

  /* --- []() --- */
  syncSquareBrackets({
    symbolsPositions,
    openBracketPositions: symbolsPositions['['],
    closeBracketPositions: symbolsPositions[']'],
  });

  escapeAllTagsInside({
    symbolsPositions,
    tag: '[',
    ignoreTags: ['[', ']'],
  });

  /* --- link --- */
  symbolsPositions['link'] = removeConflictPositions({
    positions: symbolsPositions['link'],
    conflictPositionsList: [
      symbolsPositions[']'],
      symbolsPositions['email'],
      symbolsPositions['@'],
    ],
  });

  /* --- *** --- */
  symbolsPositions['***'] = removeConflictPositions({
    positions: symbolsPositions['***'],
    conflictPositionsList: [
      symbolsPositions[']'],
      symbolsPositions['['],
      symbolsPositions['link'],
      symbolsPositions['email'],
    ],
  });

  symbolsPositions['***'] = removeTagsWithoutContent({
    positions: symbolsPositions['***'],
  });

  symbolsPositions['***'] = removeNotClosedTagPosition({
    positions: symbolsPositions['***'],
  });

  /* --- ** --- */
  symbolsPositions['**'] = removeConflictPositions({
    positions: symbolsPositions['**'],
    conflictPositionsList: [
      symbolsPositions[']'],
      symbolsPositions['['],
      symbolsPositions['link'],
      symbolsPositions['email'],
      symbolsPositions['***'],
    ],
  });

  symbolsPositions['**'] = removeTagsWithoutContent({
    positions: symbolsPositions['**'],
  });

  symbolsPositions['**'] = removeNotClosedTagPosition({
    positions: symbolsPositions['**'],
  });

  /* --- * --- */
  symbolsPositions['*'] = removeConflictPositions({
    positions: symbolsPositions['*'],
    conflictPositionsList: [
      symbolsPositions[']'],
      symbolsPositions['['],
      symbolsPositions['link'],
      symbolsPositions['email'],
      symbolsPositions['***'],
      symbolsPositions['**'],
    ],
  });

  symbolsPositions['*'] = removeTagsWithoutContent({
    positions: symbolsPositions['*'],
  });

  symbolsPositions['*'] = removeNotClosedTagPosition({
    positions: symbolsPositions['*'],
  });

  /* --- ~~ --- */
  symbolsPositions['~~'] = removeConflictPositions({
    positions: symbolsPositions['~~'],
    conflictPositionsList: [
      symbolsPositions[']'],
      symbolsPositions['['],
      symbolsPositions['link'],
      symbolsPositions['email'],
    ],
  });

  symbolsPositions['~~'] = removeTagsWithoutContent({
    positions: symbolsPositions['~~'],
  });

  symbolsPositions['~~'] = removeNotClosedTagPosition({
    positions: symbolsPositions['~~'],
  });

  /* --- __ --- */
  symbolsPositions['__'] = removeConflictPositions({
    positions: symbolsPositions['__'],
    conflictPositionsList: [
      symbolsPositions[']'],
      symbolsPositions['['],
      symbolsPositions['link'],
      symbolsPositions['email'],
    ],
  });

  symbolsPositions['__'] = removeTagsWithoutContent({
    positions: symbolsPositions['__'],
  });

  symbolsPositions['__'] = removeNotClosedTagPosition({
    positions: symbolsPositions['__'],
  });

  return symbolsPositions;
};
