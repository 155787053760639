import api from 'APP/packages/api';
import logger from 'APP/packages/logger';

export default async ({ link }) => {
  try {
    return await api.messaging.getLinkPreview({ link });
  } catch (error) {
    /**
     * The 404 server error is not the app error.
     * It's server error says what the link preview is not available, but this is still a link.
     */
    if (error?.code !== 404) {
      logger.get('API').error('messaging.getLinkPreview', error);
    }
  }

  return null;
};
