import { mapPersonalizedGroupResponse } from 'APP/model/group/groupModel.mapper';
import { DEFAULT_LIMIT_OF_MESSAGES_LOADING } from 'APP/model/message/messageModel.constants';
import {
  mapMessageToImessage,
  mapSendNewMessageRequest,
} from 'APP/model/message/messageModel.mapper';
import { mapUserDisplayDataResponse } from 'APP/model/user/userModel.mapper';
import api from 'APP/packages/api';
import logger from 'APP/packages/logger';

import { buildModelError } from '../error/errorBuilder';
import {
  IClearGroupMessageRequest,
  IConfirmReadGroupMessages,
  IDeleteMessagesRequest,
  IDeleteScheduledMessagesRequest,
  IForwardMessagesResponse,
  IForwardRequest,
  IMessage,
  IMessagesResponse,
  IScheduledMessage,
  ISendMessageResponse,
  ISendNewMessageRequest,
  IUpdateMessageReaction,
  IUpdateMessageRequest,
} from './messageModel.types';

export async function getMessagesBetweenTs(
  groupId: string,
  startTs: number,
  endTs: number
): Promise<IMessage[]> {
  try {
    const { items } = await api.messaging.getMessagesBetweenTs({
      groupId,
      startTs: startTs.toString(),
      endTs: endTs.toString(),
    });
    return items.map(mapMessageToImessage);
  } catch (error) {
    logger.get('API').error('messaging.getMessagesBetweenTs', error);
    throw buildModelError(error);
  }
}

export async function getMessages(
  groupId: string,
  startTs: number,
  count: number = DEFAULT_LIMIT_OF_MESSAGES_LOADING
): Promise<IMessagesResponse> {
  try {
    const response = await api.messaging.getGroupMessages({
      groupId,
      startTs: startTs.toString(),
      count,
    });

    return {
      messages: response.messages.map(mapMessageToImessage),
      users: response.users.map(mapUserDisplayDataResponse),
      groups: response.groups.map(mapPersonalizedGroupResponse),
    };
  } catch (error) {
    logger.get('API').error('messaging.getGroupMessages', error);
    throw buildModelError(error);
  }
}
export async function getMessage(groupId: string, messageId: string): Promise<IMessage> {
  try {
    const message = await api.messaging.getMessage({
      groupId,
      messageId,
    });
    return mapMessageToImessage(message);
  } catch (error) {
    logger.get('API').error('messaging.getMessage', error);
    throw buildModelError(error);
  }
}

export async function getRangeMessageByTs(
  groupId: string,
  timestamp: number,
  halfLimit: number = DEFAULT_LIMIT_OF_MESSAGES_LOADING
): Promise<IMessagesResponse> {
  try {
    const response = await api.messaging.getRangeMessageByTs({
      groupId,
      timestamp: timestamp.toString(),
      halfLimit,
    });
    return {
      messages: response.messages.map(mapMessageToImessage),
      users: response.users.map(mapUserDisplayDataResponse),
      groups: response.groups.map(mapPersonalizedGroupResponse),
    };
  } catch (error) {
    logger.get('API').error('messaging.getRangeMessageByTs', error);
    throw buildModelError(error);
  }
}

export async function getGroupHistoricalMessages(
  groupId: string,
  timestamp: number,
  limit: number = DEFAULT_LIMIT_OF_MESSAGES_LOADING
): Promise<IMessagesResponse> {
  try {
    const response = await api.messaging.getGroupHistoricalMessages({
      group: groupId,
      startTs: timestamp.toString(),
      limit,
    });
    return {
      messages: response.messages.map(mapMessageToImessage),
      users: response.users.map(mapUserDisplayDataResponse),
      groups: response.groups.map(mapPersonalizedGroupResponse),
    };
  } catch (error) {
    logger.get('API').error('messaging.getGroupHistoricalMessages', error);
    throw buildModelError(error);
  }
}

export async function getRangeAroundNextUserMention(
  groupId: string,
  startTs: number,
  halfLimit = 50
): Promise<IMessage[]> {
  try {
    const { messages } = await api.messaging.getRangeAroundNextUserMention({
      startTs: startTs.toString(),
      groupId,
      rangeCount: halfLimit,
    });
    return messages.map(mapMessageToImessage);
  } catch (error) {
    logger.get('API').error('messaging.getRangeAroundNextUserMention', error);
    throw buildModelError(error);
  }
}

export async function updateMessageReaction(data: IUpdateMessageReaction): Promise<IMessage> {
  try {
    return mapMessageToImessage(await api.messaging.updateMessageReaction(data));
  } catch (error) {
    logger.get('API').error('messaging.updateMessageReaction', error);
    throw buildModelError(error);
  }
}

export async function updateMessage(message: IUpdateMessageRequest): Promise<IMessage> {
  try {
    return mapMessageToImessage(await api.messaging.updateMessage(message));
  } catch (error) {
    logger.get('API').error('messaging.updateMessage', error);
    throw buildModelError(error);
  }
}

export async function forwardMessage(data: IForwardRequest): Promise<IForwardMessagesResponse> {
  try {
    return await api.messaging.forwardMessage({
      messagesToForward: data.messagesToForward.map((item) => ({
        ...item,
        clientTs: item.clientTs.toString(),
      })),
      sourceGroupId: data.sourceGroupId,
    });
  } catch (error) {
    logger.get('API').error('messaging.forwardMessage', error);
    throw buildModelError(error);
  }
}

export async function confirmReadGroupsMessages(
  confirmReads: IConfirmReadGroupMessages[]
): Promise<boolean> {
  try {
    const result = await api.messaging.confirmReadGroupsMessages({
      confirmReads,
    });
    return result.result;
  } catch (error) {
    logger.get('API').error('messaging.confirmReadGroupsMessages', error);
    throw buildModelError(error);
  }
}

export async function deleteScheduledMessages(
  data: IDeleteScheduledMessagesRequest
): Promise<boolean> {
  try {
    const result = await api.messaging.deleteScheduledMessages(data);
    return result.result;
  } catch (error) {
    logger.get('API').error('messaging.deleteScheduledMessages', error);
    throw buildModelError(error);
  }
}

export async function deleteMessages(data: IDeleteMessagesRequest): Promise<boolean> {
  try {
    const result = await api.messaging.deleteMessages(data);
    return result.result;
  } catch (error) {
    logger.get('API').error('messaging.deleteMessages', error);
    throw buildModelError(error);
  }
}

export async function clearGroupHistory(data: IClearGroupMessageRequest): Promise<number> {
  try {
    const response = await api.messaging.clearGroupHistory(data);
    return response.timestamp ? Number(response.timestamp) : -1;
  } catch (error) {
    logger.get('API').error('messaging.clearGroupHistory', error);
    throw buildModelError(error);
  }
}
export async function clearGroupHistoryForAll(data: IClearGroupMessageRequest): Promise<number> {
  try {
    const response = await api.messaging.clearGroupHistoryForAll(data);
    return response.timestamp ? Number(response.timestamp) : -1;
  } catch (error) {
    logger.get('API').error('messaging.clearGroupHistoryForAll', error);
    throw buildModelError(error);
  }
}

export async function getGroupScheduledMessages(groupId: string): Promise<IScheduledMessage[]> {
  try {
    const response = await api.messaging.getGroupScheduledMessages({ groupId });
    return response.messages;
  } catch (error) {
    logger.get('API').error('messaging.getGroupScheduledMessages', error);
    throw buildModelError(error);
  }
}

export async function getAllScheduledMessages(): Promise<IScheduledMessage[]> {
  try {
    const response = await api.messaging.getAllScheduledMessages();
    return response.messages;
  } catch (error) {
    logger.get('API').error('messaging.getAllScheduledMessages', error);
    throw buildModelError(error);
  }
}

export async function sendNewMessage(data: ISendNewMessageRequest): Promise<ISendMessageResponse> {
  try {
    return await api.messaging.sendNewMessage(mapSendNewMessageRequest(data));
  } catch (error) {
    logger.get('API').error('messaging.sendNewMessage', error);
    throw buildModelError(error);
  }
}
