import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { ProfileNameBadge } from 'APP/components/ProfileNameBadge/ProfileNameBadge';
import { IconView } from 'APP/packages/UiKit/IconView/IconView';
import incomingIcon from 'ICONS/arrow-down-s-16.svg';
import outgoingIcon from 'ICONS/arrow-up-s-16.svg';
import { SystemMessageLayout } from 'MAIN/ChatView/Messages/Message/SystemMessageLayout/SystemMessageLayout';
import { useUserProfile } from 'MAIN/hooks/userProfile/useUserProfile';

import usePresenter from './presenter';
import styles from './styles.m.scss';

const Call = ({ message }) => {
  const presenter = usePresenter(message.payload);
  const userProfile = useUserProfile(presenter.initiatorId, {
    groupId: message.groupId,
    messageId: message.id,
  });

  return (
    <SystemMessageLayout
      message={message}
      className={classNames(styles.wrapper, {
        [styles.left]: presenter.isLeftAligned,
      })}
    >
      <div className={styles.container}>
        <div className={styles.title}>
          {presenter.text.before}
          {presenter.isShowInitiator && (
            <button
              className={styles.initiator}
              onClick={userProfile.show}
            >
              <span className={styles.name}>{presenter.initiatorName}</span>
              <ProfileNameBadge
                verified={presenter.verified}
                inlineBadge
                source={presenter.userNameBadge?.source}
                animated={presenter.userNameBadge?.animated}
                displayUILayer={0}
              />
            </button>
          )}
          {presenter.text.after}
        </div>
        <div className={styles.meta}>
          <IconView
            Icon={presenter.isIncoming ? incomingIcon : outgoingIcon}
            className={classNames(styles.icon, styles[presenter.reason])}
            size="small"
          />
          {presenter.time}
          {presenter.duration}
        </div>
      </div>
    </SystemMessageLayout>
  );
};

export default observer(Call);
