import { ALERT_TYPES } from 'APP/constants/app';
import { SpaceUserRole } from 'APP/model/space/spaceModel.types';
import api from 'APP/packages/api';
import { GroupServerErrorCode } from 'APP/packages/api/constants/group.errors';
import logger from 'APP/packages/logger';
import Entities from 'APP/store';

import { addAlert } from '../../app/showAlert/showAlert';
import { loadSpace } from '../../space/loadSpace/loadSpace';
import { setActiveGroup } from '../setActiveGroup/setActiveGroup';

async function request(data) {
  try {
    const response = await api.messaging.joinGroup(data);

    if (response) {
      return response.personalizedGroup;
    }
  } catch (error) {
    if (error.message === GroupServerErrorCode.UserIsBannedInTheGroup) {
      addAlert({
        type: ALERT_TYPES.BANNED_USER_DEEPLINK_ERROR,
      });
    } else if (error.message === GroupServerErrorCode.GroupDoesNotExist) {
      addAlert({
        type: ALERT_TYPES.CHAT_IS_NOT_ACCESSIBLE,
      });
    }
    if (data.groupId === Entities.GroupsStore.activeGroupId) {
      setActiveGroup({ groupId: null });
    }
    logger.get('API').error('joinGroup', error);
  }

  return null;
}

export async function joinGroup({ groupId }) {
  const personalizedGroup = await request({ groupId });

  if (!personalizedGroup) {
    return false;
  }

  Entities.GroupsStore.merge([personalizedGroup]);

  const spaceId = personalizedGroup.groupResponse?.spaceId;

  if (spaceId) {
    const space = Entities.spacesStore.getById(spaceId);

    if (space) {
      space.isGuest && space.setRole(SpaceUserRole.Member);
    } else {
      await loadSpace(spaceId, false);
    }
  }

  return true;
}
