import React, { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { useTranslation } from 'APP/packages/translations';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { useClosePopupOnNavigation } from '../../hooks/useClosePopupOnNavigation';
import { useContactsPopupPresenter } from './ContactsPopup.presenter';
import { ContactsView } from './ContactsView/ContactsView';

export const ContactsPopup: FC<ICommonPopupsProps> = (props) => {
  const { t } = useTranslation();
  const presenter = useContactsPopupPresenter(props);
  useClosePopupOnNavigation(props.popupInstance);

  return (
    <Popup
      title={t('contacts')}
      isOpened
      isHidden={props.popupInstance.isHidden}
      handlePrevStep={presenter.hasBackButton ? presenter.onBack : null}
      onClose={presenter.onClose}
    >
      <ContactsView
        onOpenNewContact={presenter.onOpenNewContact}
        onClose={presenter.onClose}
      />
    </Popup>
  );
};
