import { useLocalStore, useAsObservableSource } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { RouterPage } from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import { Bot } from 'STORE/Users/User/Bot/Bot';

export interface IBotButtonsPresenterParams {
  bot: Bot;
  onOpenBot(): void;
}

interface IBotButtonsPresenter {
  isAddedToProfile: boolean;
  isActivating: boolean;
  activateBot(): Promise<void>;
  goToBot(): void;
}

export function useBotButtonsPresenter({
  bot,
  onOpenBot,
}: IBotButtonsPresenterParams): IBotButtonsPresenter {
  const navigateTo = useNavigateTo();
  const source = useAsObservableSource({ bot, onOpenBot });

  const presenter = useLocalStore<IBotButtonsPresenter>(() => ({
    isActivating: false,

    get isAddedToProfile(): boolean {
      return source.bot.addedToProfile;
    },

    async activateBot(): Promise<void> {
      presenter.isActivating = true;

      const result = await Tasks.botsCatalog.addBotToProfile(source.bot.id);
      if (result) {
        source.bot.update(result);
      }

      presenter.isActivating = false;
    },

    goToBot(): void {
      navigateTo({ to: `/${RouterPage.Chats}/${source.bot.groupId}` });
      source.onOpenBot();
    },
  }));

  return presenter;
}
