import React, { FC, memo } from 'react';

import useSyncAnimation from '../hooks/useSyncAnimations';
import styles from './MarketPlaceholder.styles.m.css';
import { MarketPlaceholderItem } from './MarketPlaceholderItem/MarketPlaceholderItem';

interface IMarketPlaceholderProps {
  itemCount?: number;
}

export const MarketPlaceholder: FC<IMarketPlaceholderProps> = memo(({ itemCount = 4 }) => {
  const { isFirstAnimated, isSecondAnimated } = useSyncAnimation({
    firstTimeout: 1000,
    secondTimeout: 1000,
  });

  return (
    <div className={styles.container}>
      {[...Array(itemCount).keys()].map((item) => (
        <MarketPlaceholderItem
          key={item}
          isAnimated={item % 2 ? isSecondAnimated : isFirstAnimated}
        />
      ))}
    </div>
  );
});
