import classNames from 'classnames';
import React, { DragEvent, FC } from 'react';

import { useTranslation } from 'APP/packages/translations';
import MediaIcon from 'ICONS/ic-media.svg';

import styles from './DropPanel.styles.m.css';

interface DropPanelProps {
  isDragging: boolean;
  onDrop?(event: DragEvent): void;
}
export const DropPanel: FC<DropPanelProps> = ({ isDragging, onDrop }) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(styles.container, {
        [styles.dragging]: isDragging,
      })}
      onDrop={onDrop}
    >
      <MediaIcon className={styles.icon} />
      {t('drop_to_send')}
    </div>
  );
};
