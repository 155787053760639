import getLengthWithSpecialSymbols from '../utils/getLengthWithSpecialSymbols';

export const SpaceMarketNameError = {
  IsEmpty: 'isEmpty',
  TooShort: 'tooShort',
};

const MARKET_NAME_MIN_LENGTH = 2;

export function spaceMarketNameValidate({ value, errorToText }) {
  if (!value || value.length === 0) {
    return errorToText[SpaceMarketNameError.IsEmpty];
  }

  if (getLengthWithSpecialSymbols(value) < MARKET_NAME_MIN_LENGTH) {
    return errorToText[SpaceMarketNameError.TooShort];
  }

  return null;
}
