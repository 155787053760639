import { useAsObservableSource, useLocalStore } from 'mobx-react';

import { IForwardTargetData, ISharedDataViewItemData } from '../../ShareDataView.types';
import { useSharedDataViewCommonPresenter } from '../../presenters/useSharedDataViewCommonPresenter';

interface ISharedDataViewTabAllPresenterParams {
  selection: ISharedDataViewItemData[];
  searchValue: string;
  onClickItem(item: ISharedDataViewItemData): void;
}

interface ISharedDataViewTabAllPresenter {
  content: IForwardTargetData[];
  infoBlockTitle: string;
  infoBlockDescriptions: string[];
  keyExtractor(item: IForwardTargetData): string;
}

export const useSharedDataViewTabAllPresenter = (
  data: ISharedDataViewTabAllPresenterParams
): ISharedDataViewTabAllPresenter => {
  const { selection, searchValue, onClickItem } = data;

  const commonPresenter = useSharedDataViewCommonPresenter(searchValue);
  const source = useAsObservableSource({ selection, searchValue, onClickItem });

  const presenter = useLocalStore<ISharedDataViewTabAllPresenter>(() => ({
    get content(): IForwardTargetData[] {
      return [...commonPresenter.groupsItems, ...commonPresenter.contactsItems].map(
        (sharedItem) => {
          const isSelected = source.selection.some((it) => sharedItem.itemId === it.itemId);

          return {
            data: sharedItem,
            isSelected,
            onClick: () => source.onClickItem(sharedItem),
          };
        }
      );
    },

    get infoBlockTitle(): string {
      return commonPresenter.infoBlockTitle;
    },

    get infoBlockDescriptions(): string[] {
      return commonPresenter.infoBlockDescriptions;
    },

    keyExtractor(item: IForwardTargetData): string {
      return commonPresenter.keyExtractor(item);
    },
  }));

  return presenter;
};
