import { useEffect } from 'react';

import useCommon from './common';
import useList from './list';
import useSeen from './seen';
import useTabs from './tabs';

export default (params, popupInstance) => {
  const userReactionsList = useList();
  const userSeen = useSeen(params);

  const tabs = useTabs({
    userReactionsList,
    userSeen,
    tab: params.tab,
  });

  const common = useCommon({
    userReactionsList,
    userSeen,
    tabs,
    popupInstance,
    groupId: params.groupId,
    messageId: params.messageId,
  });

  useEffect(() => {
    common.initUserReactions();
  }, []);

  return { tabs, common };
};
