import Tasks from 'APP/Tasks';
import { RouterPage } from 'APP/router/constants';

export async function handleSpace(spaceId: string): Promise<string | null> {
  const space = await Tasks.space.loadSpace(spaceId, false);

  if (!space) {
    return null;
  }

  if (space.isPrivate) {
    await Tasks.space.joinSpace(spaceId, true);
  }

  return `/${RouterPage.Spaces}/${spaceId}`;
}
