import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, useRef } from 'react';

import { SpaceAvatar } from 'APP/components/Avatar/SpaceAvatar/SpaceAvatar';
import { useTranslation } from 'APP/packages/translations';
import UpdateIcon from 'ICONS/ic-update.svg';
import { Space } from 'STORE/Spaces/Space';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';
import { IconButton } from 'UIKIT/IconButton';
import { getFormatNumberToString } from 'UTILS/getFormatNumberToString';

import InviteLink from '../../components/InviteLink';
import { ProfileDescription } from '../../components/ProfileDescription/ProfileDescription';
import { SpaceLinkOnboarding } from '../SpaceLinkOnboarding/SpaceLinkOnboarding';
import { useSpaceInfoPresenter } from './SpaceInfo.presenter';
import styles from './SpaceInfo.styles.m.css';

interface ISpaceInfoProps {
  metaBlockClassName?: string;
  space: Space;
  isShowOnboarding: boolean;
  canUpdateDeepLink: boolean;
  onUpdateDeepLink(): void;
}

export const SpaceInfo: FC<ISpaceInfoProps> = observer(
  ({ metaBlockClassName, space, isShowOnboarding, canUpdateDeepLink, onUpdateDeepLink }) => {
    const { t } = useTranslation();
    const inviteLinkRef = useRef<HTMLDivElement>(null);
    const { categoryInfo } = useSpaceInfoPresenter(space);

    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <SpaceAvatar
            className={styles.avatar}
            space={space}
            type={AvatarTypes.Square_40}
            withFullScreen={true}
          />

          <div className={styles.infoBlock}>
            <div className={styles.name}>{space.name}</div>

            {categoryInfo && <div className={styles.category}>{categoryInfo.displayName}</div>}

            <div className={styles.members}>
              {t('space_members_count').replace(
                '{0}',
                getFormatNumberToString(space.participantsCount)
              )}
            </div>
          </div>
        </div>

        {space.description && (
          <ProfileDescription
            className={classNames(styles.description, metaBlockClassName)}
            description={space.description}
          />
        )}

        {space.deepLink && (
          <>
            <InviteLink
              className={classNames(styles.inviteLink, metaBlockClassName)}
              deepLink={space.deepLink}
              metaBlockRef={inviteLinkRef}
              Actions={
                canUpdateDeepLink && (
                  <IconButton
                    Icon={UpdateIcon}
                    onClick={onUpdateDeepLink}
                  />
                )
              }
            />

            {isShowOnboarding && <SpaceLinkOnboarding reference={inviteLinkRef} />}
          </>
        )}
      </div>
    );
  }
);
