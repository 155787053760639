import { useLocalStore } from 'mobx-react';

export default ({ focusHandler, actions }) => {
  const presenter = useLocalStore(() => ({
    onKeyUp(event) {
      event.stopPropagation();
      event.preventDefault();

      focusHandler.prevFocus();
      focusHandler.scrollToFocusedItem({
        position: 'start',
      });
    },

    onKeyDown(event) {
      event.stopPropagation();
      event.preventDefault();

      focusHandler.nextFocus();
      focusHandler.scrollToFocusedItem({
        position: 'end',
      });
    },

    onEnter(event) {
      event.stopPropagation();
      event.preventDefault();

      actions.onSelectMention();
    },
  }));

  return presenter;
};
