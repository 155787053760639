import { useAsObservableSource, useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { ALERT_TYPES } from 'APP/constants/app';
import { RouterParams, RouterParamsPopup } from 'APP/router/constants';
import useClearGetParameters from 'APP/router/hooks/useClearGetParameters';
import Entities from 'STORE';

const MIN_CATEGORY_SELECTION_COUNT = 1;

export const useSelectCategoryPopupPresenter = (onClose?: () => void) => {
  const clearGetParameters = useClearGetParameters([
    [RouterParams.popup, RouterParamsPopup.selectCategory],
  ]);
  const source = useAsObservableSource({ clearGetParameters });

  const presenter = useLocalStore(() => ({
    selected: new Set<string>(Entities.userPreferences.categoryIds),
    isSubmitting: false,

    get isSubmitDisabled(): boolean {
      return presenter.selected.size < MIN_CATEGORY_SELECTION_COUNT;
    },

    toggleSelection(categoryId: string): void {
      presenter.selected.has(categoryId)
        ? presenter.selected.delete(categoryId)
        : presenter.selected.add(categoryId);
    },

    async onSubmit(): Promise<void> {
      presenter.isSubmitting = true;

      const result = await Tasks.userSettings.setUserPreferences(Array.from(presenter.selected));

      if (result) {
        presenter.onClose();
      } else {
        Tasks.app.addAlert({
          type: ALERT_TYPES.SOMETHING_WRONG_ERROR,
        });
      }

      presenter.isSubmitting = false;
    },

    onClose(): void {
      source.clearGetParameters();
      onClose && onClose();
    },
  }));

  return presenter;
};
