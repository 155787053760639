import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { MiniatureMessage } from 'APP/main/ChatView/components/MiniatureMessage/MiniatureMessage';
import { IconButton, IconViewSizes } from 'APP/packages/UiKit/IconButton';
import IcClose16 from 'ICONS/ic-close-16.svg';
import PinMenuIcon from 'ICONS/ic-pin-menu.svg';

import { useMessagePinPresenter } from './MessagePin.presenter';
import styles from './MessagePin.styles.m.css';
import { MessagePinTitle } from './MessagePinTitle/MessagePinTitle';

export const MessagePin: FC = observer(() => {
  const presenter = useMessagePinPresenter();

  if (!presenter.pinMessages?.length) {
    return null;
  }

  return (
    <div className={styles.container}>
      <MiniatureMessage
        message={presenter.currentPinnedMessage}
        title={
          <MessagePinTitle
            pinMessagesCount={presenter.pinMessages.length}
            currentMessageIndex={presenter.currentMessageIndex}
          />
        }
        onClick={presenter.onClick}
      />
      {presenter.isShowSingleMessageUnpin && (
        <IconButton
          Icon={IcClose16}
          size={IconViewSizes.Small}
          onClick={presenter.unpin}
          iconClassName={styles.remove}
        />
      )}
      {presenter.pinMessages.length > 1 && (
        <PinMenuIcon
          className={styles.pinMenuIcon}
          onClick={presenter.goToAllPins}
        />
      )}
    </div>
  );
});
