import type { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

export interface IGroupedMessages {
  message: ChatMessage;
  hasDatePlaceholder: boolean;
}

function getHasNewMessagePlaceholder(
  message: ChatMessage,
  prevMessage: ChatMessage | null
): boolean {
  const messageStore = message.messages;

  if (messageStore.newMessagesPlaceholderTs === 0) {
    return false;
  }

  if (
    !prevMessage &&
    messageStore.isLoadedFirstMessages &&
    messageStore.messages[0].id === message.id
  ) {
    return messageStore.newMessagesPlaceholderTs < message.expectedServerTime;
  }

  return (
    messageStore.newMessagesPlaceholderTs >= prevMessage?.expectedServerTime &&
    messageStore.newMessagesPlaceholderTs < message.expectedServerTime
  );
}

export function groupMessages(messages: ChatMessage[]): [string, IGroupedMessages[]][] {
  return messages.reduce<[string, IGroupedMessages[]][]>((acc, message, index, messages) => {
    const hasDatePlaceholder = message.isNextDay(messages[index - 1]);

    if (hasDatePlaceholder || acc.length === 0) {
      acc.push([new Date(message.displayTime).toLocaleDateString(), []]);
    }

    acc[acc.length - 1][1].push({
      hasDatePlaceholder,
      message,
    });

    return acc;
  }, []);
}

export function calculateIsLocalFlags(messages: ChatMessage[], ignore = false): void {
  const countMessages = messages.length;
  messages.forEach((message, index) => {
    let prevMessage: ChatMessage | null = messages[index - 1] || null;

    if (prevMessage === message) {
      prevMessage = null;
    }

    const hasNewMessagePlaceholder = getHasNewMessagePlaceholder(message, prevMessage);
    message.hasNewMessagePlaceholder = hasNewMessagePlaceholder;

    if (ignore && !message.payload.isSystem) {
      message.isLocalFirst = true;
      message.isLocalLast = true;
      return;
    }

    message.isLocalLast = index === countMessages - 1;

    if (!prevMessage || message.isNextDay(prevMessage) || hasNewMessagePlaceholder) {
      message.isLocalFirst = true;
      if (prevMessage) {
        prevMessage.isLocalLast = true;
      }
      return;
    }

    if (message.payload.isCall || prevMessage.payload.isCall) {
      message.isLocalFirst = true;
      prevMessage.isLocalLast = true;
      return;
    }

    if (message.payload.isSystem && prevMessage.payload.isSystem) {
      message.isLocalFirst = false;
      prevMessage.isLocalLast = false;
      return;
    }
    if (message.payload.isSystem && !prevMessage.payload.isSystem) {
      message.isLocalFirst = true;
      prevMessage.isLocalLast = true;
      return;
    }
    if (!message.payload.isSystem && prevMessage.payload.isSystem) {
      message.isLocalFirst = true;
      prevMessage.isLocalLast = true;
      return;
    }

    message.isLocalFirst =
      prevMessage.senderId !== message.senderId || prevMessage.isSavedMessagesLeft || index === 0;

    prevMessage.isLocalLast =
      prevMessage.senderId !== message.senderId ||
      message.isSavedMessagesLeft ||
      /* in savedMessage  my messages !== formard  my messages */
      (!message.isSavedMessagesLeft && prevMessage.isSavedMessagesLeft);
  });
}
