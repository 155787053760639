import Entities from 'STORE';

import { GroupsEvent } from '../GroupsEvent';

export class ConfigGroupUnread extends GroupsEvent {
  process() {
    const { groupId, unread = false } = this.eventPayload;
    const group = Entities.GroupsStore.getGroupById(groupId.toString());
    if (group) {
      group.setUnread(unread);
    }
  }
}
