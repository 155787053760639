import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { PreviewActionsPanel } from 'APP/unauthorized/CallPreview/Preview/PreviewActionsPanel/PreviewActionsPanel';
import { Avatar } from 'UIKIT/Avatar/Avatar';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';

import { IPreviewPresenter } from '../CallPreview.Preview.presenter';
import styles from './Preview.styles.m.css';
import { PreviewVideo } from './PreviewVideo/PreviewVideo';

interface IPreviewProps {
  presenter: IPreviewPresenter;
}

export const Preview: FC<IPreviewProps> = observer((props) => {
  const presenter = props.presenter;

  return (
    <div className={styles.container}>
      {presenter.audioVideoStreamer?.publisher.hasVideo && (
        <PreviewVideo publisher={presenter.audioVideoStreamer.publisher} />
      )}
      <div className={styles.content}>
        {!presenter.audioVideoStreamer?.publisher.hasVideo && (
          <Avatar
            type={AvatarTypes.Round_50}
            className={styles.avatar}
            title={presenter.selfName}
          />
        )}
        <PreviewActionsPanel
          isAudioMuted={presenter.audioMuted}
          isVideoMuted={presenter.videoMuted}
          unmuteAudio={presenter.unmuteAudio}
          muteAudio={presenter.muteAudio}
          unmuteVideo={presenter.unmuteVideo}
          muteVideo={presenter.muteVideo}
        />
      </div>
    </div>
  );
});
