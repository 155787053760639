import { openPopup } from 'APP/Tasks/app/popup/popup';
import { getAddAlertText } from 'APP/Tasks/app/showAlert/addAlertText';
import { AlertPopup, IAlertPopupParams } from 'MAIN/PopupManager/Popups/AlertPopup/AlertPopup';

export interface IAlertData<T> {
  type: string;
  data?: T;
}

/**
 * @deprecated Use newFunction instead.
 */
export function addAlert<T>(alert: IAlertData<T>): void {
  const text = getAddAlertText(alert.type, alert.data);
  showAlert({
    html: text,
  });
}

export function showAlert(data: IAlertPopupParams | string): Promise<void> {
  const params: IAlertPopupParams =
    typeof data === 'string'
      ? {
          text: data,
        }
      : data;

  return new Promise((resolve) =>
    openPopup(AlertPopup, {
      params,
      onClose(): void {
        resolve();
      },
    })
  );
}
