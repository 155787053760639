import { BUCKET_MEDIA_HOST } from './constants';

export function getFullScreenImage(url: string): string {
  const pixelRatio = window.devicePixelRatio || 1;
  if (url && url.includes(BUCKET_MEDIA_HOST)) {
    const width = Math.floor(window.innerWidth * pixelRatio);
    const height = Math.floor(window.innerHeight * pixelRatio);
    return `${url.split('=')[0]}=w${width}-h${height}-nu${window.supportsWEBP ? '-rw' : ''}`;
  }
  return url;
}
