import { subscribe, broadcast } from '../../eventBus';

function getBusStation(groupId) {
  return `adsConfig-changes-${groupId}`;
}

export const subscribeAdsConfigChanges = (groupId, callback) => {
  return subscribe(getBusStation(groupId), callback);
};

export const broadcastAdsConfigChanges = (groupId, data) => {
  broadcast(getBusStation(groupId), data);
};
