import { handleRewardsStatus } from 'APP/Tasks/authorization/login/handleClientConfig/handleRewardsStatus';
import { handleWhitelistHosts } from 'APP/Tasks/authorization/login/handleClientConfig/handleWhitelistHosts';
import { ClientConfigKeys } from 'APP/constants/users';

import { handleCallsProvider } from './handleCallsProvider';
import { handleCoinfideHost } from './handleCoinfideHost';
import { handleForceUpdate } from './handleForceUpdate';
import { handleMarketspaceAvailable } from './handleMarketspaceAvailable';
import { handleMaxParticipants } from './handleMaxParticipants';
import { handleMessageKeywords } from './handleMessageKeywords';
import { handleMyStickers } from './handleMyStickers';
import { handleOnboarding } from './handleOnboarding';
import { handleReactions } from './handleReactions';
import { handleSoftUpdate } from './handleSoftUpdate';
import { handleStickers } from './handleStickers';
import { handleSupportEmail } from './handleSupportEmail';
import { handleTelegramConfig } from './handleTelegramConfig';
import { handleUserPreferences } from './handleUserPreferences';

const HANDLERS = {
  ForcedUpdate: handleForceUpdate,
  SoftUpdate: handleSoftUpdate,
  [ClientConfigKeys.MessagesKeywords]: handleMessageKeywords,
  [ClientConfigKeys.CallParticipantsMax]: handleMaxParticipants,
  [ClientConfigKeys.ReactionsLastUpdateTimestamp]: handleReactions,
  [ClientConfigKeys.StickersLastUpdateTimestamp]: handleStickers,
  [ClientConfigKeys.UserStickersLastUpdateTimestamp]: handleMyStickers,
  [ClientConfigKeys.UserPreferences]: handleUserPreferences,
  [ClientConfigKeys.FeatureMarketSpace]: handleMarketspaceAvailable,
  [ClientConfigKeys.RewardsStatus]: handleRewardsStatus,
  [ClientConfigKeys.CoinfideHost]: handleCoinfideHost,
  [ClientConfigKeys.Onboarding]: handleOnboarding,
  [ClientConfigKeys.WhitelistUrls]: handleWhitelistHosts,
  [ClientConfigKeys.TelegramAuth]: handleTelegramConfig,
  [ClientConfigKeys.SupportEmail]: handleSupportEmail,
  [ClientConfigKeys.CallsProvider]: handleCallsProvider,
};

export default (clientConfig) => {
  if (!clientConfig || !clientConfig.properties) {
    return;
  }

  clientConfig.properties.forEach((property) => {
    const handler = HANDLERS[property.key];

    if (!handler) {
      return;
    }

    handler(property);
  });
};
