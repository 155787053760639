import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import { openPopup } from 'APP/Tasks/app/popup/popup';
import { RewardProgressInfoRewardEnum } from 'APP/model/userSettings/userSettingsModel.types';
import { storage } from 'APP/packages/storage';
import { RewardsPopup } from 'MAIN/PopupManager/Popups/RewardsPopup/RewardsPopup';
import Entities from 'STORE';
import { Popup } from 'STORE/App/Popups/Popup/Popup';

export interface INewMissionBannerPresenter {
  childPopup: Popup | null;
  dontShowBannersArray: RewardProgressInfoRewardEnum[];
  isView: boolean;
  onClick(): void;
  onClose(): void;
}

export const useNewMissionBannerPresenter = (): INewMissionBannerPresenter => {
  const presenter = useLocalStore<INewMissionBannerPresenter>(() => ({
    childPopup: null,

    dontShowBannersArray: storage.rewards.getShowedRewardBanners(
      storage.rewards.ShowedRewardBannerTypes.newMission
    ),

    get isView(): boolean {
      const rewards = Entities.rewards.rewardsProgress;

      let hasAcknowledgedReward = false;

      rewards.forEach((reward) => {
        if (reward.achieved) {
          return;
        }

        const dontShowReward = presenter.dontShowBannersArray.includes(reward.reward);

        if (!dontShowReward) {
          hasAcknowledgedReward = true;
        }
      });

      return hasAcknowledgedReward;
    },

    onClose(): void {
      const rewards = Entities.rewards.rewardsProgress;

      rewards.forEach((reward) => {
        if (reward.acknowledged) {
          return;
        }

        const dontShowReward = presenter.dontShowBannersArray.includes(reward.reward);

        if (!dontShowReward) {
          storage.rewards.setDontShowRewardBanner(
            storage.rewards.ShowedRewardBannerTypes.newMission,
            reward.reward
          );
          presenter.dontShowBannersArray.push(reward.reward);
        }
      });
    },

    onClick(): void {
      presenter.childPopup = openPopup(RewardsPopup);
    },
  }));

  useEffect(() => {
    return () => presenter.childPopup?.close();
  }, []);

  return presenter;
};
