import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { openPopup } from 'APP/Tasks/app/popup/popup';
import Entities from 'APP/store';
import { AvatarPopup } from 'MAIN/PopupManager/Popups/AvatarPopup/AvatarPopup';

interface IAvatarWithFullScreenPresenter {
  isClickable: boolean;
  openFullScreen(): void;
  preloadFullScreenImage(): void;
}

export function useAvatarWithFullScreenPresenter(url?: string): IAvatarWithFullScreenPresenter {
  const source = useAsObservableSource({ url });

  const presenter = useLocalStore<IAvatarWithFullScreenPresenter>(() => ({
    get isClickable(): boolean {
      return Entities.CachedImages.isCachedImage(source.url);
    },

    openFullScreen(): void {
      openPopup(AvatarPopup, { params: { url: source.url! } });
    },

    preloadFullScreenImage(): void {
      if (source.url) {
        Tasks.app.preloadImage(source.url);
      }
    },
  }));

  useEffect(() => {
    presenter.preloadFullScreenImage();
  }, [url]);

  return presenter;
}
