import { observer } from 'mobx-react';
import React, { FC } from 'react';

import GemGift from 'APP/images/gem-mascot-gift.svg';
import GemConfetti from 'APP/images/gem-mascot-sticker-sonfetti.svg';
import { useTranslation } from 'APP/packages/translations';

import { RewardsBannerView } from './RewardsBanner.view';
import { useNewGiftBannerPresenter } from './presenters/new-gift/RewardsBanner.newGift.presenter';
import { useNewMissionBannerPresenter } from './presenters/new-mission/RewardsBanner.newMission.presenter';

export const RewardsBanner: FC = observer(() => {
  const newGiftPresenter = useNewGiftBannerPresenter();
  const newMissionPresenter = useNewMissionBannerPresenter();

  const { t } = useTranslation();

  if (newGiftPresenter.isView) {
    return (
      <RewardsBannerView
        image={GemConfetti}
        onClick={newGiftPresenter.onClick}
        onClose={newGiftPresenter.onClose}
        title={t('common_rewards_congratulation')}
        description={t('common_rewards_banner_description')}
      />
    );
  }

  if (newMissionPresenter.isView) {
    return (
      <RewardsBannerView
        image={GemGift}
        onClick={newMissionPresenter.onClick}
        onClose={newMissionPresenter.onClose}
        title={t('common_rewards_gifts')}
        description={t('common_rewards_gifts_description')}
      />
    );
  }

  return null;
});
