import { openStream } from 'APP/packages/streamApi/stream/stream';

const ROOT_API = 'streams';

export const initStreamListener = ({
  channelId,
  onUserJoined,
  onUserLeft,
  onError,
  onUserLeftAnonymously,
  onUserJoinedAnonymously,
  onBroadcasters,
  onUserForceMute,
  onNewBroadcaster,
}) => {
  const callbacks = [];
  if (onUserJoined) {
    callbacks.push({
      eventName: 'streams.active.user-joined',
      onData: onUserJoined,
    });
  }
  if (onUserLeft) {
    callbacks.push({
      eventName: 'streams.active.user-left',
      onData: onUserLeft,
    });
  }
  if (onUserJoinedAnonymously) {
    callbacks.push({
      eventName: 'streams.active.user-joined-anonymously',
      onData: onUserJoinedAnonymously,
    });
  }
  if (onUserLeftAnonymously) {
    callbacks.push({
      eventName: 'streams.active.user-left-anonymously',
      onData: onUserLeftAnonymously,
    });
  }
  if (onBroadcasters) {
    callbacks.push({
      eventName: 'streams.active.broadcasters',
      onData: onBroadcasters,
    });
  }
  if (onUserForceMute) {
    callbacks.push({
      eventName: 'streams.active.user-force-mute',
      onData: onUserForceMute,
    });
  }
  if (onNewBroadcaster) {
    callbacks.push({
      eventName: 'streams.active.new-broadcaster',
      onData: onNewBroadcaster,
    });
  }

  return openStream(
    {
      rootApi: `${ROOT_API}/${channelId}`,
    },
    callbacks,
    onError
  );
};
