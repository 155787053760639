import { groupModel } from 'APP/model/model';
import Entities from 'STORE';

export async function markGroupsAsUnread(groupIds: string[], unread = true): Promise<boolean> {
  const groupIdsRequest: string[] = [];

  groupIds.forEach((groupId) => {
    const group = Entities.GroupsStore.getGroupById(groupId);

    if (group && group.unread !== unread) {
      // Optimistic UI
      group.setUnread(unread);
      groupIdsRequest.push(groupId);
    }
  });

  try {
    if (groupIdsRequest.length) {
      await groupModel.markGroupsAsUnread(groupIdsRequest, unread);
    }
    return true;
  } catch {
    // Optimistic UI fallback
    groupIdsRequest.forEach((groupId) => {
      Entities.GroupsStore.getGroupById(groupId)?.setUnread(!unread);
    });

    return false;
  }
}
