import classNames from 'classnames';
import React, { memo } from 'react';

import AnimatedPartStyles from '../AnimatedPart/AnimatedPart.styles.m.css';
import styles from './styles.m.scss';

const CirclePlaceholder = ({ isAnimated, className, size }) => {
  return (
    <div
      style={{ width: size, height: size, '--end-animation-position': size }}
      className={classNames(styles.container, className, {
        [AnimatedPartStyles.animation]: isAnimated,
      })}
    />
  );
};

export default memo(CirclePlaceholder);
