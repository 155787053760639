import { MouseEvent, MutableRefObject, useEffect, useRef, useState } from 'react';

import Tasks from 'APP/Tasks';
import { showAlert } from 'APP/Tasks/app/app';
import { AuthServerErrorCode } from 'APP/packages/api/constants/auth.errors';
import secondsToString from 'APP/packages/date/helpers/secondsToString';
import { useTranslation } from 'APP/packages/translations';
import { clearPhoneNumber } from 'APP/utils/phone';

const SMS_DURATION = 120;

const errorMap: Record<string, string> = {
  [AuthServerErrorCode.InvalidVerificationCode]: 'invalid_sms_code_alert',
  [AuthServerErrorCode.AttemptsExceeded]: 'daily_sms_limit_exceeded',
  [AuthServerErrorCode.VerificationCodeExpired]: 'sms_limit_exceeded',
  [AuthServerErrorCode.PhoneNumberIsOccupiedByAnotherUser]: 'account_use_another_user',
  [AuthServerErrorCode.PhoneNumberIsOccupiedByMe]: 'account_use_you',
  [AuthServerErrorCode.PhoneHasRecentlyChanged]: 'account_edit_period_alert',
};

interface IVerifyCodeStepPresenter {
  timerIsFinish: boolean;
  attemptsIsOver: boolean;
  ref: MutableRefObject<HTMLInputElement | null>;
  isSending: boolean;
  seconds: string;

  sendAgain(event: MouseEvent<HTMLButtonElement>): Promise<void>;
  validateCode(value: string): Promise<string | null>;

  checkFormState(
    handleSubmit: () => void,
    pristine: boolean,
    invalid: boolean,
    validating: boolean
  ): void;
}

export const useVerifyCodeStepPresenter = (phoneNumber: string): IVerifyCodeStepPresenter => {
  const { t } = useTranslation();
  const [seconds, setSeconds] = useState(0);

  const [timerIsFinish, setTimerIsFinish] = useState(false);
  const [attemptsIsOver, setAttemptsIsOver] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const ref = useRef<HTMLInputElement>(null);
  const timer = useRef<ReturnType<typeof setTimeout>>();

  const initTimer = (): void => {
    timer.current = setTimeout(() => setSeconds((prevState) => prevState + 1), 1000);
  };

  const sendAgain = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
    event.preventDefault();
    setIsSending(true);

    setTimerIsFinish(false);
    setSeconds(0);

    ref.current?.focus();

    const { error } = await Tasks.authorization.requestChangePhoneVerifyCode({
      phone: clearPhoneNumber(phoneNumber),
    });

    setIsSending(false);

    if (error) {
      showAlert(t('something_went_wrong'));
      setTimerIsFinish(true);
      return;
    }
  };

  const validateCode = async (value: string): Promise<string | null> => {
    if (!value || value.trim().length < 4) {
      return t('invalid_sms_code_alert');
    }

    const { error } = await Tasks.authorization.changePhoneConfirmVerifyCode({
      verificationCode: value,
    });

    if (error && error === AuthServerErrorCode.AttemptsExceeded) {
      setAttemptsIsOver(true);
    }

    if (error) {
      return errorMap[error] ? t(errorMap[error]) : t('something_went_wrong');
    }

    return null;
  };

  const checkFormState = (
    handleSubmit: () => void,
    pristine: boolean,
    invalid: boolean,
    validating: boolean
  ): void => {
    if (!pristine && !invalid && !validating) {
      handleSubmit();
    }
  };

  useEffect(() => {
    timer.current && clearTimeout(timer.current);

    if (SMS_DURATION - seconds <= 0) {
      setTimerIsFinish(true);
      return;
    }

    initTimer();
  }, [seconds]);

  useEffect(() => {
    ref.current?.focus();

    if (!timer.current) {
      initTimer();
    }

    return () => {
      timer.current && clearTimeout(timer.current);
    };
  }, []);

  return {
    ref,
    timerIsFinish,
    attemptsIsOver,
    seconds: secondsToString(SMS_DURATION, seconds),
    sendAgain,
    isSending,
    validateCode,
    checkFormState,
  };
};
