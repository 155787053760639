import classNames from 'classnames';
import React, { FC, memo, useState } from 'react';

import AnimatedPartStyles from 'APP/packages/placeholders/AnimatedPart/AnimatedPart.styles.m.css';
import {
  AvatarPlaceholder,
  AvatarPlaceholderType,
} from 'APP/packages/placeholders/AvatarPlaceholder/AvatarPlaceholder';
import useSyncAnimation from 'APP/packages/placeholders/hooks/useSyncAnimations';

import styles from './SchedulesCallListPlaceholder.styles.m.css';

export interface ISchedulesCallListPlaceholderProps {
  count?: number;
}

export const SchedulesCallListPlaceholder: FC<ISchedulesCallListPlaceholderProps> = memo(
  (props) => {
    const { count = 20 } = props;
    const [list] = useState(
      Array(count)
        .fill('item')
        .map((item, index) => `${item}_${index}`)
    );

    const { isFirstAnimated, isSecondAnimated } = useSyncAnimation({
      firstTimeout: 500,
      secondTimeout: 1000,
    });

    return (
      <>
        {count > 1 && (
          <div
            className={classNames(styles.dateLine, {
              [AnimatedPartStyles.animation]: isSecondAnimated,
            })}
          />
        )}
        {list.map((item) => {
          return (
            <div
              className={styles.wrapper}
              key={item}
            >
              <div
                className={classNames(styles.firstLine, {
                  [AnimatedPartStyles.animation]: isSecondAnimated,
                })}
              />
              <div
                className={classNames(styles.secondLine, {
                  [AnimatedPartStyles.animation]: isSecondAnimated,
                })}
              />

              <div className={styles.avatar}>
                <AvatarPlaceholder
                  type={AvatarPlaceholderType.Round_05}
                  isAnimated={isFirstAnimated}
                />
                <div
                  className={classNames(styles.avatarLine, {
                    [AnimatedPartStyles.animation]: isSecondAnimated,
                  })}
                />
              </div>
              <div
                className={classNames(styles.button, {
                  [AnimatedPartStyles.animation]: isSecondAnimated,
                })}
              />
            </div>
          );
        })}
      </>
    );
  }
);
