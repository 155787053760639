import React, { memo } from 'react';

import { RadioLabel } from 'UIKIT/RadioLabel/RadioLabel';

const RadioItem = ({ input: { name, checked, onChange, value }, label, description }) => {
  return (
    <RadioLabel
      value={value}
      isActive={checked}
      title={label}
      description={description}
      onChange={onChange}
      name={name}
    />
  );
};

export default memo(RadioItem);
