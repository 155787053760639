import classNames from 'classnames';
import React, { memo } from 'react';

import styles from './AllDownloadsText.styles.m.css';

interface IAllDownloadsTextProps {
  className?: string;
  text: string;
}

export const AllDownloadsText = memo<IAllDownloadsTextProps>((props) => {
  const { className, text } = props;

  return <div className={classNames(styles.container, className)}>{text}</div>;
});
