import api from 'APP/packages/api';

const ERRORS = {
  IS_EMPTY: 'IS_EMPTY',
  TOO_SHORT: 'TOO_SHORT',
  INCORRECT_SYMBOLS: 'INCORRECT_SYMBOLS',
  IS_ALREADY_EXIST: 'IS_ALREADY_EXIST',
  INCORRECT_EXTREME_SYMBOLS: 'INCORRECT_EXTREME_SYMBOLS',
};

const MIN_FIELD_NICKNAME_LENGTH = 3;
const NICKNAME_REGEXP = /^[a-zA-Z0-9._]+$/;
const TRIM_SYMBOL_REGEXP = /^[._ ]+|[._ ]+$/g;

const validate = ({ value, errorToText }) => {
  if (!value || value.length === 0) {
    return errorToText[ERRORS.IS_EMPTY];
  }

  if (value.length < MIN_FIELD_NICKNAME_LENGTH) {
    return errorToText[ERRORS.TOO_SHORT];
  }

  if (!NICKNAME_REGEXP.test(value)) {
    return errorToText[ERRORS.INCORRECT_SYMBOLS];
  }

  if (value !== value.replace(TRIM_SYMBOL_REGEXP, '')) {
    return errorToText[ERRORS.INCORRECT_EXTREME_SYMBOLS];
  }

  return null;
};

const asyncValidate = async ({ value, errorToText }) => {
  try {
    const response = await api.userSettings.validateNickName({
      nickName: value,
    });

    if (response && response.isAvailable) {
      return null;
    }
  } catch (error) {
    console.error(error);
  }

  return errorToText[ERRORS.IS_ALREADY_EXIST];
};

export default {
  validate,
  asyncValidate,
  ERRORS,
};
