import React, { useState, useRef, useEffect, FC, Children } from 'react';

import { CarouselControls } from '../CarouselControls/CarouselControls';
import styles from './CarouselScroll.styles.m.css';

interface CarouselScrollProps {
  itemsToShow: number;
  itemWidth: number;
  gap: number;
  withShadow?: boolean;
  activeIndex?: number;
  onChangeActiveIndex?: (index: number) => void;
}

export const CarouselScroll: FC<CarouselScrollProps> = ({
  children,
  itemsToShow,
  itemWidth,
  gap,
  withShadow,
  activeIndex,
  onChangeActiveIndex,
}) => {
  const childrenArray = Children.toArray(children);
  const [showPrevButton, setShowPrevButton] = useState(false);
  const [showNextButton, setShowNextButton] = useState(childrenArray.length > itemsToShow);
  const carouselRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const updateButtonVisibility = (): void => {
    if (carouselRef.current && contentRef.current) {
      const { scrollLeft, clientWidth, scrollWidth } = carouselRef.current;
      setShowPrevButton(scrollLeft > 0);
      setShowNextButton(scrollLeft + clientWidth < scrollWidth);
    }
  };

  const handleScroll = (): void => {
    updateButtonVisibility();
    if (carouselRef.current) {
      const { scrollLeft } = carouselRef.current;
      const itemTotalWidth = itemWidth + gap;
      const currentIndex = Math.round(scrollLeft / itemTotalWidth);
      onChangeActiveIndex?.(currentIndex);
    }
  };

  useEffect(() => {
    const carousel = carouselRef.current;
    if (carousel) {
      carousel.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (carousel) {
        carousel.removeEventListener('scroll', handleScroll);
      }
    };
  }, [itemWidth, gap, onChangeActiveIndex]);

  const scrollToIndex = (index: number): void => {
    if (carouselRef.current) {
      const itemTotalWidth = itemWidth + gap;
      const targetScrollLeft = index * itemTotalWidth;
      carouselRef.current.scrollTo({
        left: targetScrollLeft,
        behavior: 'smooth',
      });
    }
  };

  const handleSlide = (direction: 'next' | 'prev'): void => {
    if (carouselRef.current) {
      const { scrollLeft } = carouselRef.current;
      const itemTotalWidth = itemWidth + gap;
      const currentIndex = Math.round(scrollLeft / itemTotalWidth);
      const targetIndex =
        direction === 'next'
          ? Math.min(currentIndex + 1, childrenArray.length - itemsToShow)
          : Math.max(currentIndex - 1, 0);

      scrollToIndex(targetIndex);
    }
  };

  useEffect(() => {
    updateButtonVisibility();
  }, [childrenArray.length, itemsToShow, itemWidth, gap]);

  useEffect(() => {
    if (activeIndex !== undefined) {
      scrollToIndex(activeIndex);
    }
  }, [activeIndex]);

  return (
    <>
      <div
        ref={carouselRef}
        className={styles.carouselWrapper}
      >
        <div
          ref={contentRef}
          className={styles.carousel}
        >
          {Children.map(childrenArray, (child, index) => (
            <div
              className={styles.carouselItem}
              style={{
                flex: `0 0 ${itemWidth}px`,
                marginRight: index < childrenArray.length - 1 ? `${gap}px` : '0',
              }}
            >
              {child}
            </div>
          ))}
        </div>
      </div>

      <CarouselControls
        showPrevButton={showPrevButton}
        showNextButton={showNextButton}
        withShadow={withShadow}
        onPrev={(): void => handleSlide('prev')}
        onNext={(): void => handleSlide('next')}
      />
    </>
  );
};
