import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useCallback } from 'react';

import { List } from 'APP/components/List/List';
import { GroupsPlaceholder } from 'APP/packages/placeholders/GroupsPlaceholder/GroupsPlaceholder';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';

import User from '../../components/User';
import styles from './styles.m.scss';

const UserReactionsList = ({ list }) => {
  const renderItem = useCallback((user) => {
    return (
      <User
        user={user}
        ignoreIsMe={false}
      />
    );
  }, []);

  const props = {
    isLoading: list.isLoading,
    hasMore: list.hasMore,
    data: list.slicedUserReactions,
    onEndReached: list.onEndReached,
    keyExtractor: (user) => {
      return user.userId;
    },
    renderItem,
    SpinnerComponent: (
      <GroupsPlaceholder
        count={10}
        className={styles.placeholders}
      />
    ),
  };

  return (
    <div className={classNames(styles.container, customScrollStyles.container)}>
      <List {...props} />
    </div>
  );
};

export default observer(UserReactionsList);
