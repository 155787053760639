import dateService from 'APP/packages/date';
import { useTranslation } from 'APP/packages/translations';
import { ChatMessage } from 'APP/store/Messages/Message/ChatMessage/ChatMessage';

export function useDatePlaceholderPresenter(message: ChatMessage): string {
  const { t } = useTranslation();

  const day = message.displayTime;

  const isToday = dateService.isToday(day);

  const isScheduled = message?.messages?.group?.isSchedule;

  const date = isToday
    ? t('today')
    : dateService.format(day, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });

  if (date === null) {
    return '';
  }

  if (isScheduled) {
    return t('common:scheduled_date', { 0: date });
  }

  return date;
}
