import { MouseEvent, useCallback } from 'react';

import { useTranslation } from 'APP/packages/translations';
import { useCopyToClipboard } from 'MAIN/Profile/hooks/useCopyToClipboard';

interface IDescriptionPresenter {
  handleCopyToClipboard(event: MouseEvent): void;
  copyToClipboard(): void;
}

export const useDescriptionPresenter = (description: string): IDescriptionPresenter => {
  const { t } = useTranslation();

  const copyToClipboard = useCopyToClipboard(description, t('chat_description_coppied'));

  const handleCopyToClipboard = useCallback(
    (event) => {
      const nodeNames = ['BUTTON', 'A'];

      if (!nodeNames.includes(event.target.nodeName)) {
        copyToClipboard();
      }
    },
    [copyToClipboard]
  );

  return {
    handleCopyToClipboard,
    copyToClipboard,
  };
};
