import { useMemo } from 'react';

import dateService from 'APP/packages/date';
import Entities from 'STORE';
import { CircleVideo } from 'STORE/Messages/Message/Payload/CircleVideo/CircleVideo';

interface ISharedCircleVideoPlayerData {
  groupId: string;
  messageId: string;
  serverTime: number;
}

interface ISharedCircleVideoPlayerPresenter {
  dateTime: string;
  payload: CircleVideo | null;
}

export function useSharedCircleVideoPlayerPresenter(
  data: ISharedCircleVideoPlayerData
): ISharedCircleVideoPlayerPresenter {
  const { groupId, messageId, serverTime } = data;

  const presenter: ISharedCircleVideoPlayerPresenter = {
    dateTime: useMemo(() => {
      return (
        dateService.format(serverTime, {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        }) || ''
      );
    }, [serverTime]),

    payload: useMemo(() => {
      const group = Entities.GroupsStore.getGroupById(groupId);
      return group?.messagesStore?.getMessageById(messageId)?.payload || null;
    }, [groupId, messageId]),
  };

  return presenter;
}
