import { observable, action } from 'mobx';

import { ISpaceGroupsVisibility } from 'APP/model/space/spaceModel.types';

export class GroupVisibilityData {
  @observable public allGroupsVisible: boolean = false;
  @observable private visibleGroupIds: Set<string> = new Set();

  constructor(data?: ISpaceGroupsVisibility) {
    if (data) {
      this.update(data);
    }
  }

  public isGroupVisible(groupId: string): boolean {
    return this.visibleGroupIds.has(String(groupId));
  }

  @action
  public update(data: ISpaceGroupsVisibility): void {
    const visibleGroupIds = data.visibleGroupIds.map((id) => String(id));

    this.allGroupsVisible = Boolean(data.allGroupsVisible);
    this.visibleGroupIds = new Set(visibleGroupIds);
  }

  @action
  public setAllGroupsVisible(isVisible: boolean): void {
    this.allGroupsVisible = Boolean(isVisible);
  }

  @action
  public setGroupVisible(groupId: string, isVisible: boolean): void {
    if (isVisible) {
      this.visibleGroupIds.add(String(groupId));
    } else {
      this.visibleGroupIds.delete(String(groupId));
    }
  }
}
