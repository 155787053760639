import api from 'APP/packages/api';
import logger from 'APP/packages/logger';
import Entities from 'APP/store';

export default async (message) => {
  let group = null;
  if (message.threadId) {
    group = Entities.GroupsStore.getGroupById(message.threadId);
  }
  if (!group) {
    try {
      const response = await api.group.getOrCreateThread({
        groupId: message.groupId,
        messageId: message.id,
      });

      Entities.GroupsStore.merge([response]);
      group = Entities.GroupsStore.getGroupById(response.groupResponse.id);
    } catch (error) {
      logger.get('API').error('group.getOrCreateThread', error);
    }
  }
  if (group) {
    group.setThreadInfo({
      groupId: message.groupId,
      messageId: message.id,
    });
  }
  return group;
};
