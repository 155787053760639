import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { InView } from 'react-intersection-observer';

import { AddKeyPressHandler } from 'APP/packages/keyBoardHandler';
import BotsPlaceholder from 'APP/packages/placeholders/Bots';
import { useTranslation } from 'APP/packages/translations';
import CloseIcon from 'ICONS/close.svg';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';
import { FullscreenPopup } from 'UIKIT/FullscreenPopup/FullscreenPopup';
import FullscreenPopupHeader from 'UIKIT/FullscreenPopupHeader';
import { IconButton } from 'UIKIT/IconButton';
import { Loader } from 'UIKIT/Loader/Loader';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { useBotsCatalogPopupPresenter } from '../../Popups/BotsCatalogPopup/BotsCatalogPopup.presenter';
import { useClosePopupOnNavigation } from '../../hooks/useClosePopupOnNavigation';
import { BotsCatalogItem } from './BotsCatalogItem/BotsCatalogItem';
import styles from './BotsCatalogPopup.styles.m.css';

export const BotsCatalogPopup: FC<ICommonPopupsProps> = observer(({ popupInstance }) => {
  const presenter = useBotsCatalogPopupPresenter(popupInstance);
  const { t } = useTranslation();
  useClosePopupOnNavigation(popupInstance);

  return (
    <FullscreenPopup>
      <FullscreenPopupHeader className={styles.header}>
        <IconButton
          Icon={CloseIcon}
          className={styles.close}
          dataTest="close-bots-catalog"
          onClick={popupInstance.close}
        />
        <h1 className={styles.title}>{t('common_bot_catalog')}</h1>
      </FullscreenPopupHeader>

      <section
        className={classNames(styles.content, customScrollStyles.container)}
        data-test="bots-catalog"
      >
        <div className={styles.container}>
          {presenter.isShowPlaceholder && <BotsPlaceholder />}
          {presenter.bots.map((bot) => (
            <BotsCatalogItem
              key={bot.id}
              bot={bot}
              onShowDetails={(): void => presenter.onShowDetails(bot)}
              onOpenBot={popupInstance.close}
            />
          ))}
        </div>

        <InView onChange={presenter.onLoadMore} />

        {presenter.isShowLoader && (
          <Loader
            className={styles.loader}
            color="secondary"
          />
        )}
      </section>

      <AddKeyPressHandler onEsc={popupInstance.close} />
    </FullscreenPopup>
  );
});
