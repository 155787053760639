import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import { navigateTo } from 'APP/router/refs/navigateTo';
import Entities from 'STORE';
import { Group } from 'STORE/Groups/Group';

interface IAllPinsPresenter {
  group: Group | null;
  onBack(): void;
}

export const useAllPinsPresenter = (groupId: string): IAllPinsPresenter => {
  const history = useHistory();
  const source = useAsObservableSource({ groupId, history });

  const presenter = useLocalStore<IAllPinsPresenter>(() => ({
    get group(): Group | null {
      return Entities.GroupsStore.getGroupById(source.groupId);
    },

    onBack(): void {
      navigateTo({
        to: presenter.group?.routePath,
      });
    },
  }));

  return presenter;
};
