import { observable } from 'mobx';

import { PayloadType } from 'APP/model/message/messageModel.types';
import { t } from 'MODULE/i18n/service';
import { Payload } from 'STORE/Messages/Message/Payload';

import Image from '../Image';
import Video from '../Video';

const PayloadClass = {
  Image,
  Video,
};

class Advertisement extends Payload {
  @observable payloads;
  comment;
  buttonCaption;
  buttonUrl;

  constructor(data) {
    super(data);
    this.payloads = data.payloads
      .sort((a, b) => a.order - b.order)
      .map(({ id, order, payloadType, payload }) => ({
        id,
        order,
        payloadType,
        payload: new PayloadClass[payload.payloadType]({
          ...payload,
          message: {
            forward: null,
            quotedMessage: null,
            hasError: false,
            setError() {
              this.hasError = true;
            },
          },
        }),
      }));
    this.comment = data.comment;
    this.buttonCaption = data.buttonCaption;
    this.buttonUrl = data.buttonUrl;
  }

  toJSON() {
    return {
      payloadType: PayloadType.Advertisement,
      comment: this.comment || null,
      buttonCaption: this.buttonCaption || null,
      buttonUrl: this.buttonUrl || null,

      payloads: this.payloads.map((payload, idx) => ({
        id: payload.id,
        payloadType: payload.payloadType,
        order: idx,
        payload: payload.payload.toJSON(),
      })),
    };
  }

  get canBeDelete() {
    return false;
  }

  get text() {
    return t('common_advertisement');
  }
}

export default Advertisement;
