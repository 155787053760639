import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { useUserProfile } from 'MAIN/hooks/userProfile/useUserProfile';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';
import PopupInfoCard from 'UIKIT/PopupInfoCard';
import popupItemStyles from 'UIKIT/PopupItem/styles.m.scss';

import { useUserPresenter } from './NewUsersPopupUserItem.presenter';

export const NewUsersPopupUserItem = observer(({ userId, closePopup, groupId, messageId }) => {
  const userProfile = useUserProfile(userId, { groupId, messageId });
  const presenter = useUserPresenter({ userId, closePopup });
  const containerProps = classNames(popupItemStyles.container, popupItemStyles.interactive);

  return (
    <button
      className={containerProps}
      onClick={userProfile.show}
    >
      <PopupInfoCard
        type={AvatarTypes.Round_10}
        className={popupItemStyles.card}
        avatarTitle={presenter.avatarTitle}
        avatarUrl={presenter.avatarUrl}
        avatarBackgroundColorIndex={presenter.avatarBackgroundColorIndex}
        title={presenter.title}
        subtitle={presenter.subtitle}
      />
    </button>
  );
});
