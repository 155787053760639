import React, { FC, memo } from 'react';

import styles from './Emoji.styles.m.css';

interface IEmojiProps {
  value: string;
  onSelect(emoji: string): void;
}
export const Emoji: FC<IEmojiProps> = memo(({ value, onSelect }) => {
  const handleClick = (): void => onSelect(value);

  return (
    <button
      data-value={value}
      className={styles.emoji}
      onClick={handleClick}
    >
      {value}
    </button>
  );
});
