import { spaceModel } from 'APP/model/model';
import { IUpdateSpace } from 'APP/model/space/spaceModel.types';
import Entities from 'STORE';
import { Space } from 'STORE/Spaces/Space';

export async function updateSpace(data: IUpdateSpace): Promise<Space | null> {
  try {
    const spaceData = await spaceModel.updateSpace(data);

    Entities.spacesStore.updateSpace(spaceData);
    return Entities.spacesStore.getById(spaceData.space.id);
  } catch {
    return null;
  }
}
