import { action, computed, observable } from 'mobx';

class Mode {
  @observable isFullScreenValue = false;

  constructor(root, parent) {
    this.root = root;
    this.parent = parent;
  }

  @computed
  get isMinimized() {
    return (
      this.parent.hasCall &&
      !this.isFullScreenValue &&
      (!this.parent.call.group ||
        !this.parent.call.group?.isActive ||
        this.root.ChatStore.isShowSearch ||
        this.root.ChatStore.isShowSidebar)
    );
  }

  @computed
  get isCompact() {
    return (
      this.parent.hasCall &&
      !this.isFullScreenValue &&
      this.parent.call.group &&
      this.parent.call.group?.isActive
    );
  }

  @computed
  get isFullScreen() {
    return this.parent.hasCall && this.isFullScreenValue;
  }

  @computed
  get isScreenSharing() {
    return (
      this.parent.hasCall &&
      this.parent.call.isScreenSharing &&
      this.parent.call.opponents.screenSharingOpponent.hasVideo
    );
  }

  @computed
  get isP2PAudio() {
    return this.parent.hasCall && this.parent.call.isP2P && !this.parent.call.isVideoCall;
  }

  @computed
  get isP2PVideo() {
    return this.parent.hasCall && this.parent.call.isP2P && this.parent.call.isVideoCall;
  }

  @computed
  get isGroupAudio() {
    return this.parent.hasCall && this.parent.call.isGroup && !this.parent.call.isVideoCall;
  }

  @computed
  get isGroupVideo() {
    return this.parent.hasCall && this.parent.call.isGroup && this.parent.call.isVideoCall;
  }

  @computed
  get isGroup() {
    return this.parent.hasCall && this.parent.call.isGroup;
  }

  get isP2P() {
    return this.isP2PAudio || this.isP2PVideo;
  }

  @action
  collapse = () => {
    this.isFullScreenValue = false;
  };

  @action
  expand = () => {
    this.isFullScreenValue = true;
  };
}

export default Mode;
