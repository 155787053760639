import { getStringCharactersCount } from 'UTILS/getStringCharactersCount';

import { IFieldValidationParams, ErrorType } from '../validation.types';

const MAX_FIELD_LENGTH = 70;

export const userBioFieldValidation = (data: IFieldValidationParams): string | null => {
  const { value, errorToText } = data;

  if (value && getStringCharactersCount(value) > MAX_FIELD_LENGTH) {
    return errorToText[ErrorType.TooLong];
  }

  return null;
};
