import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import useClearGetParameters from 'APP/router/hooks/useClearGetParameters';
import Entities from 'STORE';

export const usePresenter = (requestType) => {
  const clearGetParameters = useClearGetParameters();

  const presenter = useLocalStore(() => ({
    onClose: () => {
      clearGetParameters();
    },

    get permissionsRequests() {
      return Entities.Calls.ongoingCall?.permissionsRequests.get(requestType);
    },

    get OngoingCall() {
      return Entities.Calls.ongoingCall;
    },
  }));

  useEffect(() => {
    if (!presenter.OngoingCall) {
      presenter.onClose();
    }
  }, []);

  return presenter;
};
