import classNames from 'classnames';
import React from 'react';

import { useUserPresenter } from './UserPopoverItem.presenter';
import styles from './UserPopoverItem.styles.m.css';

export const UserPopoverItem = ({
  id,
  groupId,
  messageId,
  displayName,
  interactive = false,
  onClick = undefined,
}) => {
  const presenter = useUserPresenter({
    userId: id,
    onClick,
    interactive,
    groupId,
    messageId,
  });

  const userClasses = classNames(styles.name, {
    [styles.interact]: interactive,
  });

  return (
    <div
      className={userClasses}
      onClick={presenter.onClickUser}
      role={interactive ? 'button' : undefined}
    >
      {displayName}
    </div>
  );
};
