import React from 'react';

import { List } from 'APP/components/List/List';
import { useTranslation } from 'APP/packages/translations';

import { UserPopoverItem } from './UserPopoverItem/UserPopoverItem';
import styles from './UsersPopover.styles.m.css';

export const UsersPopover = ({
  isOpened,
  moreCount,
  data,
  onClick,
  onClickUser = undefined,
  interactive = false,
  groupId = undefined,
  messageId = undefined,
}) => {
  const { t } = useTranslation();
  const hasMore = Boolean(moreCount && moreCount > 0);

  return (
    <div className={styles.container}>
      <List
        className={isOpened && hasMore ? styles.listWithCount : undefined}
        data={data}
        keyExtractor={(user) => user.id}
        renderItem={(user) => (
          <UserPopoverItem
            key={user.id}
            id={user.id}
            groupId={groupId}
            messageId={messageId}
            displayName={user.displayName}
            interactive={interactive}
            onClick={onClickUser}
          />
        )}
      />
      {isOpened && hasMore && (
        <button
          onClick={onClick}
          className={styles.more}
        >
          {t('reactions_window_message_more').replace('{0}', moreCount)}
        </button>
      )}
    </div>
  );
};
