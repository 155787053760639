import { useLocalStore } from 'mobx-react';
import { MouseEvent, MutableRefObject, useRef } from 'react';

import Tasks from 'APP/Tasks';
import { OnboardingType } from 'APP/constants/onboarding';
import { platformInfo } from 'APP/packages/platformInfo/platformInfo';
import Entities from 'APP/store';

interface SubmitButtonPresenter {
  isMenuOpen: boolean;
  isShowMenu: boolean;
  isShowOnboarding: boolean;
  canBeScheduled: boolean;
  isDisabled: boolean;
  closeOnboarding(): void;
  onCloseMenu(): void;
  onToggleMenu(event: MouseEvent<HTMLButtonElement>): void;
  onSubmit(): void;
}

export const useSubmitButton = (): {
  presenter: SubmitButtonPresenter;
  submitButtonRef: MutableRefObject<HTMLButtonElement | null>;
} => {
  const submitButtonRef = useRef<HTMLButtonElement | null>(null);

  const presenter = useLocalStore(() => ({
    isMenuOpen: false,

    get canBeScheduled(): boolean {
      return (
        !!Entities.GroupsStore.activeGroup?.canBeScheduled &&
        !Entities.InputPanel.inputPanel?.isEditMode
      );
    },

    get isDisabled(): boolean {
      return Entities.InputPanel.isDisabled;
    },

    get isShowMenu(): boolean {
      return presenter.canBeScheduled && presenter.isMenuOpen;
    },

    get isShowOnboarding(): boolean {
      return presenter.canBeScheduled;
    },

    closeOnboarding(): void {
      Tasks.app.onboarding.closeOnboarding(OnboardingType.ScheduleMessage);
    },

    onCloseMenu(): void {
      presenter.isMenuOpen = false;
    },

    onToggleMenu(event: MouseEvent<HTMLButtonElement>): void {
      event.preventDefault();
      presenter.closeOnboarding();
      presenter.isMenuOpen = !presenter.isMenuOpen;
    },

    onSubmit(): void {
      // TODO review input logic for avoiding remove ranges explicitly
      if (platformInfo.isSafari) {
        const selection = window.getSelection();
        selection?.removeAllRanges();
      }

      presenter.closeOnboarding();
      presenter.onCloseMenu();
      Entities.InputPanel.send.submit();
    },
  }));

  return { presenter, submitButtonRef };
};
