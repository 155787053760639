import { observable, computed, action } from 'mobx';

import { cachingConnectionToken } from './Services/cachingConnectionToken';

class AuthStore {
  @observable isDeleted = false;
  @observable connectionToken = null;
  @observable isFirstAuthDone = false; // check if auth was processed 1st time
  @observable session = null;
  @observable streamToken = null; // token3 from api
  @observable isNeedForceUpdate = false;
  @observable isNeedSoftUpdate = false;
  @observable isNeedDesktopSoftUpdate = false;

  constructor(root) {
    this.root = root;

    cachingConnectionToken(this);
  }

  @action
  setDeleted(value) {
    this.isDeleted = value;
  }

  @computed
  get isAuthenticated() {
    return Boolean(this.session && this.streamToken);
  }

  @action
  setConnectionToken = (token) => {
    this.connectionToken = token;
  };

  @action
  setForceUpdate = (value) => {
    this.isNeedForceUpdate = value;
  };

  @action
  setSoftUpdate = (value) => {
    this.isNeedSoftUpdate = value;
  };

  @action
  setDesktopSoftUpdate = (value) => {
    this.isNeedDesktopSoftUpdate = value;
  };

  @action
  setSession = (sessionId) => {
    this.session = sessionId;
  };

  @action
  setStreamToken = (streamToken) => {
    this.streamToken = streamToken;
  };

  @action
  finishFirstLogin = () => {
    this.isFirstAuthDone = true;
  };

  @action
  reset = () => {
    this.setDeleted(false);
    this.session = null;
    this.connectionToken = null;
    this.streamToken = null;
  };
}

export default AuthStore;
