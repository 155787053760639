import React, { FC } from 'react';

import { IconButton, IconViewSizes } from 'APP/packages/UiKit/IconButton';
import ShareScreenIcon from 'ICONS/ic-share-screen.svg';

import {
  IShareScreenButtonPresenterParams,
  useShareScreenButtonPresenter,
} from './ShareScreenButton.presenter';

interface IShareScreenButtonProps extends IShareScreenButtonPresenterParams {
  className?: string;
  isDisabled: boolean;
}

export const ShareScreenButton: FC<IShareScreenButtonProps> = ({
  className,
  isDisabled,
  isActive,
  shareScreen,
  stopSharingScreen,
}) => {
  const { isProcessing, onClick } = useShareScreenButtonPresenter({
    isActive,
    shareScreen,
    stopSharingScreen,
  });

  return (
    <IconButton
      className={className}
      Icon={ShareScreenIcon}
      size={IconViewSizes.Large}
      iconSize={IconViewSizes.Medium}
      theme={isActive ? '13' : '12'}
      isDisabled={isDisabled}
      isLoading={isProcessing}
      onClick={onClick}
    />
  );
};
