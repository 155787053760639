import { observer } from 'mobx-react';
import React, { FC } from 'react';

import CloseIcon from 'ICONS/ic-close-large.svg';
import NewChatIcon from 'ICONS/ic-new-chat.svg';
import { Space } from 'STORE/Spaces/Space';
import { IconButton, IconViewSizes } from 'UIKIT/IconButton';
import { usePopover } from 'UIKIT/Popover/usePopover';
import { PopoverMenu } from 'UIKIT/PopoverMenu/PopoverMenu';

import { useSpacePanelActionsButtonPresenter } from './SpacePanelActionsButton.presenter';
import styles from './SpacePanelActionsButton.styles.m.css';

interface ISpacePanelActionsButtonProps {
  space: Space;
}

export const SpacePanelActionsButton: FC<ISpacePanelActionsButtonProps> = observer(({ space }) => {
  const popover = usePopover<HTMLButtonElement>();
  const presenter = useSpacePanelActionsButtonPresenter(space);

  if (!presenter.menuItems.length) {
    return null;
  }

  return (
    <>
      <IconButton
        className={styles.button}
        iconClassName={styles.icon}
        setRef={popover.containerRef}
        Icon={popover.isOpened ? CloseIcon : NewChatIcon}
        size={IconViewSizes.Large}
        iconSize={IconViewSizes.Medium}
        theme="20"
        onClick={popover.toggle}
      />

      <PopoverMenu
        className={styles.menu}
        isOpened={popover.isOpened}
        menuItems={presenter.menuItems}
        onClose={popover.close}
        reference={popover.containerRef.current}
        placement="top-end"
      />
    </>
  );
});
