import Tasks from 'APP/Tasks';
import { checkSlowMode } from 'APP/Tasks/messaging/checkSlowMode/checkSlowMode';
import { sendNewMessageInQueue } from 'APP/Tasks/messaging/createNewMessages/sendNewMessageInQueue';
import Entities from 'STORE';
import type { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';
import { MessageError } from 'STORE/Messages/Message/ChatMessage/ChatMessages.types';

export const resendChatMessage = async (message: ChatMessage): Promise<void> => {
  const canSend = checkSlowMode();
  if (!canSend.result) {
    canSend.error && Entities.toast.show(canSend.error);
    return;
  }

  message.resetError();

  if (message.payload.upload) {
    await message.payload.upload();
  }

  if (!message.hasError) {
    sendNewMessageInQueue({
      message,
      retry: true,
      errorCallback: (error) =>
        Tasks.messaging.handleMessageError({
          error,
          message,
          messageErrorType: MessageError.Send,
        }),
    });
  }
};
