import { useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { showConfirm } from 'APP/Tasks/app/showConfirm/showConfirm';
import { unlinkAccount } from 'APP/Tasks/authorization/unlinkAccount/unlinkAccount';
import { ALERT_TYPES } from 'APP/constants/app';
import { AuthProvider, IAccountProvider } from 'APP/model/auth/authModel.types';
import { DeviceInfoPlatform } from 'APP/model/common/commonModel.types';
import { platformInfo } from 'APP/packages/platformInfo/platformInfo';
import { useTranslation } from 'APP/packages/translations';
import Entities from 'STORE';

const AVAILABLE_PROVIDERS: IAccountProvider[] = [
  { authProviderId: AuthProvider.Phone, displayName: '', userId: '' },
  { authProviderId: AuthProvider.Google, displayName: '', userId: '' },
  { authProviderId: AuthProvider.Telegram, displayName: '', userId: '' },
  { authProviderId: AuthProvider.Apple, displayName: '', userId: '' },
];

interface IProvidersPresenter {
  isProcessing: boolean;
  linkedProviders: IAccountProvider[];
  accountProviders: (IAccountProvider & { isLinked: boolean })[];
  myAccountProviders: IAccountProvider[];
  isDesktop: boolean;

  onDelete(authProviderId: AuthProvider): Promise<void>;
  setIsProcessing(isProcessing: boolean): void;
}

export const useProvidersPresenter = (): IProvidersPresenter => {
  const { t } = useTranslation();

  const presenter = useLocalStore<IProvidersPresenter>(() => ({
    isProcessing: false,
    isDesktop: platformInfo.platform === DeviceInfoPlatform.Desktop,

    get myAccountProviders(): IAccountProvider[] {
      return Entities.UsersStore.Me.accountProviders;
    },

    get accountProviders(): (IAccountProvider & { isLinked: boolean })[] {
      const providersByProviderId = presenter.myAccountProviders.reduce<{
        [key: string]: IAccountProvider;
      }>((acc, account) => {
        acc[account.authProviderId] = account;
        return acc;
      }, {});

      return AVAILABLE_PROVIDERS.map((availableProvider) => {
        const myProvider = providersByProviderId[availableProvider.authProviderId];

        return myProvider
          ? { ...myProvider, isLinked: true }
          : { ...availableProvider, isLinked: false };
      });
    },

    get linkedProviders(): IAccountProvider[] {
      return presenter.accountProviders.filter((account) => account.isLinked);
    },

    setIsProcessing(isProcessing: boolean): void {
      presenter.isProcessing = isProcessing;
    },

    async onDelete(authProviderId): Promise<void> {
      if (presenter.linkedProviders.length === 1) {
        Tasks.app.addAlert({ type: ALERT_TYPES.ACCOUNT_UNLINK_ALERT });
      } else {
        const confirmResult = await showConfirm({
          text: t('account_unlink_confirmation_alert'),
          primaryText: t('common_yes'),
          secondaryText: t('common_no'),
        });
        if (confirmResult) {
          presenter.setIsProcessing(true);
          await unlinkAccount(authProviderId);
          presenter.setIsProcessing(false);
        }
      }
    },
  }));

  return presenter;
};
