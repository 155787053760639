import { useAsObservableSource, useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { openPopup } from 'APP/Tasks/app/popup/popup';
import * as features from 'APP/packages/features/features';
import { useTranslation } from 'APP/packages/translations';
import routerConst, { RouterParamsPopup } from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import IcCall from 'ICONS/ic-add-call.svg';
import ChannelIcon from 'ICONS/ic-channel.svg';
import IcDeletePB from 'ICONS/ic-delete-pb.svg';
import SpaceIcon from 'ICONS/ic-space.svg';
import UserIcon from 'ICONS/ic-user.svg';
import UsersIcon from 'ICONS/ic-users.svg';
import { CreateChannelPopup } from 'MAIN/PopupManager/Popups/CreateChannelPopup/CreateChannelPopup';
import { CreateChatGroupPopup } from 'MAIN/PopupManager/Popups/CreateChatGroupPopup/CreateChatGroupPopup';
import { CreateSpacePopup } from 'MAIN/PopupManager/Popups/CreateSpacePopup/CreateSpacePopup';
import { NewContactPopup } from 'MAIN/PopupManager/Popups/NewContactPopup/NewContactPopup';
import Entities from 'STORE';
import { IPopover } from 'UIKIT/Popover/Popover.types';
import { usePopover } from 'UIKIT/Popover/usePopover';
import { IPopoverMenuItem } from 'UIKIT/PopoverMenu/PopoverMenu.types';

interface INewMessageButtonPresenter {
  menuItems: IPopoverMenuItem[];
  myPhone: string;
  newSharedCall(): Promise<void>;
  newUnauthorizedCall(): void;
  newMessage(): void;
  newContact(): void;
  newGroupChat(): void;
  newSpace(): void;
  newChannel(): void;
}

interface IUseNewMessageButtonPresenter {
  presenter: INewMessageButtonPresenter;
  popover: IPopover<HTMLButtonElement>;
}

export function useNewMessageButtonPresenter(): IUseNewMessageButtonPresenter {
  const popover = usePopover<HTMLButtonElement>();
  const { t } = useTranslation();
  const navigateTo = useNavigateTo();
  const source = useAsObservableSource({ navigateTo });

  const presenter = useLocalStore<INewMessageButtonPresenter>(() => ({
    get menuItems(): IPopoverMenuItem[] {
      const items = [
        {
          key: 'new-message',
          title: t('new_message'),
          onClick: presenter.newMessage,
          icon: UserIcon,
        },
        {
          key: 'new-shared-call',
          title: t('call_start_new'),
          onClick: presenter.newSharedCall,
          icon: IcCall,
        },
        {
          key: 'new-contact',
          title: t('new_contact'),
          onClick: presenter.newContact,
          icon: IcDeletePB,
        },
        {
          key: 'new-group-chat',
          title: t('new_group_chat'),
          onClick: presenter.newGroupChat,
          icon: UsersIcon,
        },
        {
          key: 'new-space',
          title: t('space_new_space'),
          onClick: presenter.newSpace,
          icon: SpaceIcon,
        },
        {
          key: 'new-channel',
          title: t('new_channel'),
          onClick: presenter.newChannel,
          icon: ChannelIcon,
        },
      ];

      // ToDO: UNION_CALL - refactor in future task for union calls
      if (features.isUnionCallEnabled()) {
        items.push({
          key: 'new-call',
          title: 'Новый неавторизованный звонок',
          onClick: presenter.newUnauthorizedCall,
          icon: IcCall,
        });
      }

      return items;
    },

    get myPhone(): string {
      return Entities.UsersStore.Me.phone;
    },

    async newSharedCall(): Promise<void> {
      const group = await Tasks.calls.startSharedCallAndCreateGroup();

      if (group) {
        navigateTo({
          to: `/${group.route}/${group.id}`,
        });
      }
    },

    newUnauthorizedCall(): void {
      Tasks.calls.startUnauthorizedCall();
    },

    newMessage(): void {
      source.navigateTo({
        query: {
          [routerConst.params.popup]: RouterParamsPopup.newMessage,
        },
      });
    },

    newContact(): void {
      openPopup(NewContactPopup, {
        params: { navigateToActiveGroup: true },
      });
    },

    newGroupChat(): void {
      openPopup(CreateChatGroupPopup, {
        params: { navigateToActiveGroup: true },
      });
    },

    newSpace(): void {
      openPopup(CreateSpacePopup);
    },

    newChannel(): void {
      openPopup(CreateChannelPopup);
    },
  }));

  return { presenter, popover };
}
