import { useAsObservableSource, useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import Entities from 'STORE';
import { BackgroundCall } from 'STORE/BackgroundCalls/BackgroundCall/BackgroundCall';
import { Call } from 'STORE/Calls/Call/Call';
import { Space } from 'STORE/Spaces/Space';

export interface ISpaceBadgePresenter {
  unreadMessagesCount: number;
  mutedMessagesCount: number;
  hasUnreadMention: boolean;
  isPinned: boolean;
  hasBackgroundCall: boolean;
  backgroundCalls: BackgroundCall[];
  activeCall: Call | null;
  hasBackgroundStream: boolean;
  hasActiveStream: boolean;
  getNewMessagesCount(isMuted?: boolean): number;
  onJoinCall(): void;
}

export function useSpaceBadgePresenter(space: Space): ISpaceBadgePresenter {
  const source = useAsObservableSource({ space });

  const presenter = useLocalStore<ISpaceBadgePresenter>(() => ({
    get unreadMessagesCount(): number {
      return presenter.getNewMessagesCount();
    },

    get mutedMessagesCount(): number {
      return presenter.getNewMessagesCount(true);
    },

    getNewMessagesCount(isMuted = false): number {
      return Entities.GroupsStore.getGroupsBySpaceId(source.space.id).reduce(
        (result, group) =>
          (group.numberOfUnread > 0 || group.unread) && (isMuted ? group.muted : !group.muted)
            ? ++result
            : result,
        0
      );
    },

    get hasUnreadMention(): boolean {
      return Entities.GroupsStore.getGroupsBySpaceId(source.space.id).some(
        (group) => group.unreadUserMentionsCounter > 0
      );
    },

    get isPinned(): boolean {
      return source.space.isPinned;
    },

    get hasBackgroundCall(): boolean {
      return !!presenter.backgroundCalls.length;
    },

    get backgroundCalls(): BackgroundCall[] {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore TODO: remove after BackgroundStreams store will be rewritten on TypeScript
      return Entities.BackgroundCalls.calls.filter(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (call) => call.group?.spaceId === source.space.id
      );
    },

    get activeCall(): Call | null {
      return Entities.Calls.calls.find((call) => call.group?.spaceId === source.space.id) || null;
    },

    get hasBackgroundStream(): boolean {
      return Entities.BackgroundStreams.streams.some(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TODO: remove after BackgroundStreams store will be rewritten on TypeScript
        (call) => call.group?.spaceId === source.space.id
      );
    },

    get hasActiveStream(): boolean {
      const activeStream = Entities.ActiveStream.stream;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore TODO: remove after ActiveStream store will be rewritten on TypeScript
      const group = activeStream ? Entities.GroupsStore.getGroupById(activeStream.groupId) : null;

      return group?.spaceId === source.space.id;
    },

    onJoinCall(): void {
      if (presenter.backgroundCalls.length === 1) {
        const backgroundCall = presenter.backgroundCalls[0];
        const group = Entities.GroupsStore.getGroupById(backgroundCall.groupId || '');

        Tasks.calls.joinToCall({
          groupId: backgroundCall.groupId,
          channelId: backgroundCall.channelId,
          withAudio: !!group?.isP2P,
          withVideo: false,
          callType: backgroundCall.callType,
          providerType: backgroundCall.callProvider,
        });
      }
    },
  }));

  return presenter;
}
