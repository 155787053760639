import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { IButtonPayloadModel } from 'STORE/Messages/Message/Payload/Buttons/Buttons';
import { OpenUrlButtonPayload } from 'STORE/Messages/Message/Payload/Buttons/OpenUrlButtonPayload';

import { CommandButton } from '../CommandButton/CommandButton';
import { useOpenUrlCommandButtonPresenter } from './OpenUrlCommandButton.presenter';

interface IOpenUrlCommandButtonProps {
  groupId: string;
  button: IButtonPayloadModel;
}

export const OpenUrlCommandButton: FC<IOpenUrlCommandButtonProps> = observer((props) => {
  const { button } = props;

  const presenter = useOpenUrlCommandButtonPresenter(button.payload as OpenUrlButtonPayload);

  return (
    <CommandButton
      onClick={presenter.onClick}
      title={button.title}
    />
  );
});
