import { observer } from 'mobx-react';
import React, { FC, useState, useCallback } from 'react';
import { InView } from 'react-intersection-observer';

import { IUserNameBadgeSource } from 'APP/constants/userBadges';

import styles from '../AnimatedSticker.styles.m.css';
import { useAnimationPresenter } from './Animation.presenter';

interface IAnimationProps {
  animationUrl?: string;
  animationData?: IUserNameBadgeSource;
  width: number;
  height: number;
  autoplay?: boolean;
  displayUILayer?: number;
  loop?: boolean;
}
export const Animation: FC<IAnimationProps> = observer((props) => {
  const {
    animationUrl = undefined,
    animationData = undefined,
    width,
    height,
    autoplay = false,
    displayUILayer = 0,
    loop,
  } = props;

  const [inView, setInView] = useState(false);
  const { stickerContainerRef } = useAnimationPresenter({
    autoplay,
    animationUrl,
    animationData,
    displayUILayer,
    loop,
    inView,
  });

  const onViewChange = useCallback((inView: boolean) => setInView(inView), [setInView]);

  return (
    <InView onChange={onViewChange}>
      <span
        className={styles.sticker}
        ref={stickerContainerRef}
        style={{
          width,
          height,
        }}
      />
    </InView>
  );
});
