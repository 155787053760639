import { useLocalStore } from 'mobx-react';

import { Group } from 'STORE/Groups/Group';
import { Space } from 'STORE/Spaces/Space';
import { User } from 'STORE/Users/User/User';

import { SearchResultEntity } from './SearchResult.types';
import { getLocalSearchResults } from './getLocalSearchResults/getLocalSearchResults';

export interface ISearchResultLocalPresenter {
  localGroups: Group[];
  localSpaces: Space[];
  localUsers: User[];
  results: SearchResultEntity[];
  noResults: boolean;
  search(searchText: string): void;
}

export const useSearchResultLocalPresenter = (): ISearchResultLocalPresenter => {
  const presenter = useLocalStore<ISearchResultLocalPresenter>(() => ({
    localGroups: [],
    localSpaces: [],
    localUsers: [],

    search(searchText: string): void {
      const { groups, spaces, users } = getLocalSearchResults(searchText);

      presenter.localGroups = groups;
      presenter.localSpaces = spaces;
      presenter.localUsers = users;
    },

    get results(): SearchResultEntity[] {
      return [...presenter.localGroups, ...presenter.localUsers, ...presenter.localSpaces];
    },

    get noResults(): boolean {
      return presenter.results.length === 0;
    },
  }));

  return presenter;
};
