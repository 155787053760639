import { useLocalStore } from 'mobx-react';

import Entities from 'APP/store';
import { useShareScreenToggle } from 'MAIN/OngoingCall/hooks/useShareScreenToggle/useShareScreenToggle';
import OngoingCall from 'STORE/ViewModels/Calls/OngoingCall';

interface IGroupVideoCallPresenter {
  ongoingCall: OngoingCall;
}

export const useGroupVideoCallPresenter = () => {
  useShareScreenToggle();

  return useLocalStore<IGroupVideoCallPresenter>(() => ({
    get ongoingCall(): OngoingCall {
      return Entities.OngoingCall;
    },
  }));
};
