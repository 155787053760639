import api from 'APP/packages/api';
import logger from 'APP/packages/logger';
import { clearPhoneNumber } from 'UTILS/phone';

const ERRORS = {
  E_PHONE_INVALID: 'E_PHONE_INVALID',
};

const validate = ({ value = '', errorToText, data: { phoneTemplate } }) => {
  const phone = '+' + clearPhoneNumber(value);
  if (
    phone === '+' ||
    (phoneTemplate && phone.length - 1 < phoneTemplate.replace(/[^X]/g, '').length - 1)
  ) {
    return errorToText[ERRORS.E_PHONE_INVALID];
  }
  return null;
};

const asyncValidate = async ({ value, errorToText }) => {
  const phone = '+' + clearPhoneNumber(value);
  try {
    const { result } = await api.authorization.validatePhone({ phone });
    if (result) {
      return null;
    }
  } catch (error) {
    logger.get('API').error('authorization.validatePhone', error);
  }
  return errorToText[ERRORS.E_PHONE_INVALID];
};

export default {
  validate,
  asyncValidate,
  ERRORS,
};
