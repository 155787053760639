import { observer } from 'mobx-react';
import React from 'react';

import UpdateIcon from 'ICONS/ic-update.svg';
import { IconButton } from 'UIKIT/IconButton';

import usePresenter from './presenter';

const GroupUpdateLinkButton = ({ group }) => {
  const presenter = usePresenter({ group });

  if (!presenter.isAvailableUpdateDeepLinkHash) {
    return null;
  }

  return (
    <IconButton
      Icon={UpdateIcon}
      onClick={presenter.onUpdateDeepLink}
    />
  );
};

export default observer(GroupUpdateLinkButton);
