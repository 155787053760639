import api from 'APP/packages/api';
import logger from 'APP/packages/logger';

export const registerNotifications = async (tokenType: string): Promise<void> => {
  try {
    await api.push.registerForPushNotifications({
      deviceToken: null,
      platform: 'desktop',
      tokenType,
    });

    logger
      .get('Push Notifications')
      .info(`${tokenType} - push notification service was registered on the server`);
  } catch (error) {
    logger
      .get('Push Notifications')
      .error(`${tokenType} - push notification service was not registered on the server`, error);
  }
};
