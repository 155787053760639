import React, { memo } from 'react';

import styles from '../FileIcon.styles.m.css';
import { TFileTheme } from '../FileIcon.types';

interface IFileIconHoverProps {
  theme: TFileTheme;
}

export const FileIconHover = memo<IFileIconHoverProps>((props) => {
  const { theme } = props;

  return (
    <>
      <path
        className={styles[`file-${theme}`]}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31 3H11C7.68629 3 5 5.68629 5 9V37C5 40.3137 7.68629 43 11 43H37C40.3137 43 43 40.3137 43 37V15L31 3Z"
      />
      <path
        opacity="0.2"
        d="M43 15L31 3V11C31 13.2091 32.7909 15 35 15H43Z"
        fill="black"
      />
      <path
        d="M31 31H17C16.4 31 16 31.4 16 32C16 32.6 16.4 33 17 33H31C31.6 33 32 32.6 32 32C32 31.4 31.6 31 31 31Z"
        fill="white"
      />
      <path
        d="M23.3 28.7C23.4 28.8 23.5 28.9 23.6 28.9C23.7 28.9 23.8 29 24 29C24.1 29 24.3 29 24.4 28.9C24.5 28.8 24.6 28.8 24.7 28.7L29.7 23.7C30.1 23.3 30.1 22.7 29.7 22.3C29.3 21.9 28.7 21.9 28.3 22.3L25 25.6V16C25 15.4 24.6 15 24 15C23.4 15 23 15.4 23 16V25.6L19.7 22.3C19.3 21.9 18.7 21.9 18.3 22.3C17.9 22.7 17.9 23.3 18.3 23.7L23.3 28.7Z"
        fill="white"
      />
    </>
  );
});
