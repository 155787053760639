import Entities from 'STORE';
import { IPushNotification } from 'STORE/PushNotifications/PushNotifications.types';

export const handleDeleteMessage = (pushNotification: IPushNotification): void => {
  const { group, messageId } = pushNotification;

  if (group && messageId) {
    Entities.pushNotifications.removeNotificationByMessageId(group, messageId);
  }
};
