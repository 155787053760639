import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { IPopover } from 'UIKIT/Popover/Popover.types';
import { PopoverMenu } from 'UIKIT/PopoverMenu/PopoverMenu';

import {
  IMediaMessageSubmitMenuParams,
  useMediaMessageSubmitMenuPresenter,
} from './MediaMessageSubmitMenu.presenter';

interface IMediaMessageSubmitMenuProps extends IMediaMessageSubmitMenuParams {
  popover: IPopover;
}

export const MediaMessageSubmitMenu: FC<IMediaMessageSubmitMenuProps> = observer(
  ({ popover, ...props }) => {
    const presenter = useMediaMessageSubmitMenuPresenter(props);

    return (
      <PopoverMenu
        menuItems={presenter.menuItems}
        reference={popover.containerRef.current}
        placement="top-end"
        isOpened={popover.isOpened}
        onClose={popover.close}
      />
    );
  }
);
