import Tasks from 'APP/Tasks';
import api from 'APP/packages/api';
import logger from 'APP/packages/logger';
import Entities from 'APP/store';

const updateMessage = (message, commentCount) => {
  const group = Entities.GroupsStore.getGroupById(message.groupId);
  if (!group || !group.messagesStore.getMessageById(message.id)) {
    return;
  }

  Tasks.messaging.updateOldOrFakeMessages({
    messages: [message],
    groupId: message.groupId,
  });

  if (message.threadId) {
    const counter = Entities.Counters.getCounterById(message.threadId);
    if (counter) {
      counter.setTotalMessagesCount(commentCount);
    }
  }
};

const removeMessage = (groupId, messageId) => {
  const group = Entities.GroupsStore.getGroupById(groupId);
  if (group) {
    group.messagesStore.handleRemoveMessages(group.messagesStore.getMessageByIds([messageId]));
  }
};

export default async (messageKeys = []) => {
  try {
    if (!messageKeys.length) {
      return;
    }

    const { messages } = await api.messaging.getMessagesWithCommentCount({
      messageKeys,
    });

    // There may be messages from unread groups or messages
    // (forward from unsubscribe group or scroll to an old pinned post)
    const updatedIds = [];
    messages.forEach(({ commentCount, message }) => {
      updateMessage(message, commentCount);
      updatedIds.push(message.id);
    });

    // Remove messages from store which aren't returned from server
    const needRemove = messageKeys.filter(({ messageId }) => updatedIds.indexOf(messageId) < 0);
    needRemove.forEach(({ groupId, messageId }) => {
      removeMessage(groupId, messageId);
    });
  } catch (error) {
    logger.get('API').error('messaging.getMessages', error);
  }
};
