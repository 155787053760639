import { action, computed, observable } from 'mobx';

import { ClientRole } from 'APP/constants/calls';
import { ProviderRemoteStreamType } from 'APP/packages/callProviders/callProviders.constants';
import { ICallProviderOpponent, ProviderUid } from 'APP/packages/callProviders/callProviders.types';
import { Member } from 'STORE/Calls/Call/Member/Member';

import { Call } from '../Call';

interface IOpponentConstructorParams {
  call: Call;
  sortIndex: number;
  role: ClientRole;
  providerOpponent?: ICallProviderOpponent;
  uid: ProviderUid;
}

export class Opponent {
  uid: ProviderUid;
  call: Call;
  @observable private providerOpponent: ICallProviderOpponent | null;
  @observable role: ClientRole;
  @observable sortIndex = 0;

  constructor({ call, sortIndex, role, providerOpponent, uid }: IOpponentConstructorParams) {
    this.call = call;
    this.uid = uid;
    this.sortIndex = sortIndex;
    this.role = role;
    this.providerOpponent = providerOpponent || null;
  }

  get member(): Member | null {
    const member = this.call.members.getMemberByUid(this.uid);

    if (member) {
      return member;
    }

    return (
      this.call.members.memberList.find((member) => {
        return member.shareScreenUid === this.uid;
      }) || null
    );
  }

  @action
  setSortIndex(sortIndex: number): void {
    this.sortIndex = sortIndex;
  }

  @action
  setRole(role: ClientRole): void {
    this.role = role;
  }

  playVideo(element: HTMLDivElement): void {
    this.providerOpponent?.playVideo(element);
  }

  stopVideo(): void {
    this.providerOpponent?.stopVideo();
  }

  @computed
  get volumeLevel(): number {
    return this.providerOpponent?.volumeLevel || 0;
  }

  @computed
  get isPlayingVideo(): boolean {
    return !!this.providerOpponent?.isPlayingVideo;
  }

  @computed
  get hasVideo(): boolean {
    return !!this.providerOpponent?.hasVideo;
  }

  @computed
  get hasAudio(): boolean {
    return !!this.providerOpponent?.hasAudio;
  }

  @computed
  get isActiveSpeaker(): boolean {
    return this.volumeLevel > 1 && this.hasAudio;
  }

  async changeVideoStreamQuality(isLowQuality: boolean): Promise<void> {
    const streamType = isLowQuality
      ? ProviderRemoteStreamType.LowStream
      : ProviderRemoteStreamType.HighStream;
    await this.call.me.audioVideoStreamer.setSomeUserTypeStream(this.uid, streamType);
  }

  @computed
  get isSharedScreen(): boolean {
    if (!this.member) {
      return false;
    }

    return this.member.shareScreenUid === this.uid;
  }
}
