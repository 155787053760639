import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { GroupAvatar } from 'APP/components/Avatar/GroupAvatar/GroupAvatar';
import { ProfileNameBadge } from 'APP/components/ProfileNameBadge/ProfileNameBadge';
import GroupStatus from 'MAIN/components/GroupStatus';
import useUserOnlineStatusText from 'MAIN/hooks/userOnlineStatusText';
import { Group } from 'STORE/Groups/Group';

import OfflineStatus from '../components/OfflineStatus';
import { useP2PChatInfoPresenter } from './P2PChatInfo.presenter';
import styles from './P2PChatInfo.styles.m.css';

interface IP2PChatInfoProps {
  group: Group;
}

export const P2PChatInfo: FC<IP2PChatInfoProps> = observer(({ group }) => {
  const presenter = useP2PChatInfoPresenter(group);
  const userOnlineStatusText = useUserOnlineStatusText({
    user: group.groupOpponent,
  });

  return (
    <>
      <GroupAvatar group={group} />
      <div className={styles.content}>
        <div className={styles.title}>
          <span className={styles.name}>{group.avatarTitle}</span>
          <ProfileNameBadge
            inlineBadge
            verified={presenter.verified}
            source={presenter.userNameBadge?.source}
            animated={presenter.userNameBadge?.animated}
            displayUILayer={0}
          />
        </div>
        <OfflineStatus>
          <GroupStatus group={group}>
            <span className={styles.onlineStatus}>{userOnlineStatusText}</span>
          </GroupStatus>
        </OfflineStatus>
      </div>
    </>
  );
});
