import { observer } from 'mobx-react';
import React from 'react';

import FullScreenImage from 'APP/main/Popups/ContextPopups/FullScreenImage';

import styles from './styles.m.css';

const ImagePreview = ({ resizedImage, data }) => {
  return (
    <>
      {resizedImage.isShowFullScreen && (
        <FullScreenImage
          url={resizedImage.url}
          close={resizedImage.closeFullScreen}
        />
      )}
      <button
        onClick={resizedImage.openFullScreen}
        className={styles.container}
        style={resizedImage.containerStyle}
      >
        <img
          className={styles.image}
          src={resizedImage.url}
          alt={data.title}
        />
      </button>
    </>
  );
};

export default observer(ImagePreview);
