import { observer } from 'mobx-react';
import React, { FC, ReactElement } from 'react';

import PlayIcon from 'ICONS/ic-play-16.svg';

import { IMediaMessagePayload } from '../MediaMessagePopup.types';
import { DeleteButton } from '../components/DeleteButton/DeleteButton';
import { useMediaMessageAlbumPresenter } from './MediaMessageAlbum.presenter';
import styles from './MediaMessageAlbum.styles.m.css';

interface IAlbumProps {
  payloads: IMediaMessagePayload[];
  onRemove?(payload: IMediaMessagePayload): void;
}

export const MediaMessageAlbum: FC<IAlbumProps> = observer(({ payloads, onRemove }) => {
  const presenter = useMediaMessageAlbumPresenter(payloads, onRemove);

  return (
    <div className={styles.album}>
      {presenter.layout.map((row) => (
        <div
          key={row.id}
          className={styles.row}
        >
          {row.items.map(
            (item): ReactElement => (
              <div
                key={item.id}
                className={styles.item}
                style={{ width: `${item.containerWidth}%` }}
              >
                <div style={{ paddingBottom: `${item.containerRatio * 100}%` }} />
                <img
                  className={styles.img}
                  src={presenter.previewUrl(item.id)}
                  alt=""
                />
                {presenter.hasPlayIcon(item.id) ? (
                  <div className={styles.duration}>
                    <PlayIcon className={styles.playIcon} />
                    <span className={styles.time}>{presenter.getDuration(item.id)}</span>
                  </div>
                ) : null}
                {presenter.canBeRemoved && (
                  <DeleteButton
                    onClick={(): void => presenter.onRemove(item.id)}
                    className={styles.delete}
                  />
                )}
              </div>
            )
          )}
        </div>
      ))}
    </div>
  );
});
