import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import styles from './RecordingPlaceholder.m.css';
import { useRecordingPlaceholderPresenter } from './RecordingPlaceholder.presenter';

interface IRecordingPlaceholderProps {
  className?: string;
}

export const RecordingPlaceholder: FC<IRecordingPlaceholderProps> = observer(({ className }) => {
  const presenter = useRecordingPlaceholderPresenter();

  return (
    <>
      <button
        type="button"
        onClick={presenter.showDialog}
        className={classNames(styles.container, className)}
      >
        <div className={styles.icon} />
        <span className={styles.title}>{presenter.title}</span>
      </button>
    </>
  );
});
