import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { openPopup } from 'APP/Tasks/app/popup/popup';
import { showToast } from 'APP/Tasks/app/showToast/showToast';
import { AddParticipantsPopup } from 'APP/main/PopupManager/Popups/AddParticipantsPopup/AddParticipantsPopup';
import { CallType } from 'APP/model/call/callModel.types';
import { useTranslation } from 'APP/packages/translations';
import routerConst from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import CopyLink from 'ICONS/ic-add-link.svg';
import AddUserIcon from 'ICONS/ic-add-user.svg';
import IcBackgroundEffect from 'ICONS/ic-background-effect.svg';
import LockIcon from 'ICONS/ic-lock-small.svg';
import StartRecordingIcon from 'ICONS/ic-start-recording.svg';
import StopRecordingIcon from 'ICONS/ic-stop-recording.svg';
import MembersIcon from 'ICONS/ic-users.svg';
import GridIcon from 'ICONS/ic-video-grid.svg';
import ListIcon from 'ICONS/ic-video-list.svg';
import { StartRecordingPopup } from 'MAIN/PopupManager/Popups/StartRecordingPopup/StartRecordingPopup';
import Entities from 'STORE';
import { Popup } from 'STORE/App/Popups/Popup/Popup';
import { Call } from 'STORE/Calls/Call/Call';
import { Group } from 'STORE/Groups/Group';
import { IPopoverMenuItem } from 'UIKIT/PopoverMenu/PopoverMenu.types';

interface IShowMoreButtonPresenter {
  isPopoverOpened: boolean;
  isVisibleStopRecordingDialog: boolean;
  ongoingCall: Call;
  group: Group | null;
  title: string;
  menu: IPopoverMenuItem[];
  childPopup: Popup | null;
  togglePopover(): void;
  closePopover(): void;
  copyInviteLinkToClipboard(): void;
  onStopRecording(): Promise<void>;
  showMembersList(): void;
  showAddMembersPopup(): void;
  showBackgroundPanel(): void;
  openStartRecordingPopup(): void;
  showPermissionsPopup(): void;
  changeLayout(): void;
}

export const useShowMoreButtonPresenter = (): IShowMoreButtonPresenter => {
  const { t } = useTranslation();
  const navigateTo = useNavigateTo();
  const source = useAsObservableSource({ navigateTo });

  const presenter = useLocalStore<IShowMoreButtonPresenter>(() => ({
    isPopoverOpened: false,
    isVisibleStopRecordingDialog: false,
    childPopup: null,

    get ongoingCall(): Call {
      return Entities.OngoingCall.call!;
    },

    get group(): Group | null {
      return presenter.ongoingCall.group || null;
    },

    get title(): string {
      return t('call_recording_rec_hint');
    },

    get menu(): IPopoverMenuItem[] {
      const list = [];
      const call = presenter.ongoingCall;

      const hasMembersButton = call.isGroup && call.isAnswered;
      if (hasMembersButton) {
        const participantsCount = call.members.memberOnCallList.length;
        const membersCount = call.members.memberList.length;
        list.push({
          key: 'show-members',
          icon: MembersIcon,
          title: `${t('common_participants')} (${participantsCount} / ${membersCount})`,
          onClick: presenter.showMembersList,
        });
      }

      const hasAddMembersButton = hasMembersButton && call.isMeInitiator;
      if (hasAddMembersButton) {
        list.push({
          key: 'add-people',
          icon: AddUserIcon,
          title: t('common_add_people'),
          onClick: presenter.showAddMembersPopup,
        });
      }

      if (!call.isGuestCall && call.me.audioVideoStreamer.canUseBlur) {
        list.push({
          key: 'apply-background',
          icon: IcBackgroundEffect,
          title: t('call_apply_background'),
          onClick: presenter.showBackgroundPanel,
        });
      }

      if (call.canStopRecording) {
        list.push({
          key: 'stop-recording',
          icon: StopRecordingIcon,
          title: t('calls_recording_stop_button'),
          onClick: presenter.onStopRecording,
        });
      }

      if (call.canStartRecording && !call.isGuestCall) {
        list.push({
          key: 'start-recording',
          icon: StartRecordingIcon,
          title: t('calls_recording_start_button'),
          onClick: presenter.openStartRecordingPopup,
        });
      }

      if (call.isGroupCall) {
        list.push({
          key: 'permissions',
          icon: LockIcon,
          title: t('permissions_label'),
          onClick: presenter.showPermissionsPopup,
        });
      }

      if (call.callType === CallType.Group && presenter.group?.canInviteUsers) {
        list.push({
          key: 'copy-invite-link',
          icon: CopyLink,
          title: t('common_copy_link'),
          onClick: Tasks.calls.copyUnionCallLink,
        });
      }

      // hide layoutButton for compact view in audio groupCall
      const hasLayoutButton =
        Entities.OngoingCall.mode.isGroup &&
        presenter.ongoingCall.opponents.opponentList.length > 1 &&
        (!Entities.OngoingCall.mode.isCompact || Entities.OngoingCall.mode.isGroupVideo);

      if (hasLayoutButton) {
        const isGridLayout = Entities.OngoingCall.presentVideoMode.isGridLayout;
        list.push({
          key: 'change-layout',
          icon: isGridLayout ? ListIcon : GridIcon,
          title: isGridLayout ? t('calls_speaker_view') : t('calls_grid_view'),
          onClick: presenter.changeLayout,
        });
      }

      return list;
    },

    togglePopover(): void {
      this.isPopoverOpened = !this.isPopoverOpened;
    },

    closePopover(): void {
      this.isPopoverOpened = false;
    },

    copyInviteLinkToClipboard(): void {
      Tasks.calls.copyUnionCallLink();
    },

    async onStopRecording(): Promise<void> {
      presenter.closePopover();

      const canStop = await Tasks.app.showConfirm({
        text: t('calls_recording_stop_alert'),
        primaryText: t('common_stop'),
      });

      if (canStop) {
        await Tasks.calls.stopRecording();
      }
    },

    showMembersList(): void {
      presenter.closePopover();

      if (presenter.ongoingCall.backgroundPanelShown) {
        presenter.ongoingCall.hideBackgroundPanel();
      }
      Entities.OngoingCall.members.toggleShowMembersSection();
    },

    showAddMembersPopup(): void {
      presenter.closePopover();

      presenter.childPopup = openPopup(AddParticipantsPopup, {
        params: { groupId: presenter.ongoingCall.groupId },
      });
    },

    showBackgroundPanel(): void {
      if (presenter?.ongoingCall.me.isVideoMuted) {
        Tasks.app.showAlert(t('call_switch_on_your_camera'));

        return;
      }

      if (Entities.OngoingCall.members.isMembersShown) {
        Entities.OngoingCall.members.hideMembers();
      }

      presenter.closePopover();
      presenter.ongoingCall.toggleBackgroundPanel();
    },

    openStartRecordingPopup(): void {
      presenter.closePopover();

      if (
        !presenter.group?.withMeInAdmins &&
        !presenter.group?.memberCustomPermissions?.supportedMediaTypes?.canSendVideo &&
        !presenter.group?.memberCustomPermissions?.supportedMediaTypes?.canSendAudio
      ) {
        showToast(t('moderation_content_limit_media'));
        return;
      }

      if (!presenter.group?.canSendMessages) {
        showToast(t('moderation_disable_send_message'));
        return;
      }

      presenter.childPopup = openPopup(StartRecordingPopup, {
        params: { groupId: presenter.group.id },
      });
    },

    showPermissionsPopup(): void {
      presenter.closePopover();
      source.navigateTo({
        query: {
          [routerConst.params.popup]: routerConst.paramsEnum.popup.callPermissions,
        },
      });
    },

    changeLayout(): void {
      presenter.closePopover();
      Entities.OngoingCall.presentVideoMode.toggleGridLayout();
    },
  }));

  useEffect(() => {
    return () => {
      presenter.childPopup?.close();
    };
  }, []);

  return presenter;
};
