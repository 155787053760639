import { useLocalStore, useAsObservableSource } from 'mobx-react';

import { SEEN_TAB_NAME } from '../constants';

export default ({ tabs }) => {
  const source = useAsObservableSource({ tabs });

  const presenter = useLocalStore(() => ({
    get tabs() {
      return source.tabs;
    },

    get isUserSeenList() {
      return presenter.tabs.selectedTab === SEEN_TAB_NAME;
    },
  }));

  return presenter;
};
