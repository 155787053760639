import { storage } from 'APP/packages/storage';
import Entities from 'APP/store';

export const checkStarsFeedback = (channelId: string) => {
  const dontShowFeedback = storage.feedback.getDontShowValue();
  const feedbackSkipCounter = storage.feedback.getSkipValue();

  if (!channelId || dontShowFeedback) {
    return;
  }

  if (!feedbackSkipCounter) {
    Entities.feedback.setChannelId(channelId);
    Entities.feedback.setIsStarsShown(true);

    return;
  } else {
    storage.feedback.increaseSkipValue();
  }
};
