import { useLocalStore } from 'mobx-react';
import { useMemo } from 'react';

import { PrivacyValue } from 'APP/model/userSettings/userSettingsModel.types';
import { useTranslation } from 'APP/packages/translations';
import Entities from 'STORE';
import { PrivacySettings } from 'STORE/PrivacySettings/PrivacySettings';

import * as Tasks from './userPrivacyViewTasks/userPrivacyViewTasks';

enum UserPrivacySetting {
  ShowPhoneNumber = 'showPhoneNumber',
  ShowAvatar = 'showAvatar',
  AddToGroup = 'addToGroup',
  AddToPublic = 'addToPublic',
  CallMe = 'callMe',
  ShowLastSeen = 'showLastSeen',
  SearchMe = 'searchMe',
  SendVoiceMessage = 'sendVoiceMessage',
}

interface IUserPrivacyPageParam {
  title: string;
  subtitle: string;
  labels: Record<PrivacyValue, string>;
  setValue(value: PrivacyValue): Promise<void>;
}

type TUserPrivacyDescription = Record<UserPrivacySetting, Record<PrivacyValue, string>>;
type TUserPrivacyPageParams = Record<UserPrivacySetting, IUserPrivacyPageParam>;

interface IUserPrivacyList {
  key: UserPrivacySetting;
  title: string;
  description: string;
  onClick(): void;
}

interface IUserPrivacyViewPresenter {
  description: TUserPrivacyDescription;
  pageParams: TUserPrivacyPageParams;
  settings: PrivacySettings;
  activePage: UserPrivacySetting | null;
  list: Array<IUserPrivacyList>;
  handleItemClick(page: UserPrivacySetting): void;
  openPrivacy(): void;
}

export const useUserPrivacyViewPresenter = (): IUserPrivacyViewPresenter => {
  const { t } = useTranslation();

  const labels: Record<PrivacyValue, string> = useMemo(
    () => ({
      [PrivacyValue.Anyone]: t('common_privacy_everybody'),
      [PrivacyValue.Contacts]: t('common_privacy_my_contacts'),
      [PrivacyValue.Nobody]: t('common_privacy_no_one'),
    }),
    []
  );

  const presenter = useLocalStore<IUserPrivacyViewPresenter>(() => ({
    activePage: null,

    get description(): TUserPrivacyDescription {
      return {
        [UserPrivacySetting.ShowPhoneNumber]: {
          [PrivacyValue.Anyone]: t('privacy_phone_number_everybody'),
          [PrivacyValue.Contacts]: t('privacy_phone_number_my_contacts'),
          [PrivacyValue.Nobody]: t('privacy_phone_number_no_one'),
        },
        [UserPrivacySetting.ShowAvatar]: {
          [PrivacyValue.Anyone]: t('privacy_avatar_everybody'),
          [PrivacyValue.Contacts]: t('privacy_avatar_my_contacts'),
          [PrivacyValue.Nobody]: t('privacy_avatar_no_one'),
        },
        [UserPrivacySetting.AddToGroup]: {
          [PrivacyValue.Anyone]: t('privacy_groups_channels_everybody'),
          [PrivacyValue.Contacts]: t('privacy_groups_channels_my_contacts'),
          [PrivacyValue.Nobody]: t('privacy_groups_channels_no_one'),
        },
        [UserPrivacySetting.AddToPublic]: {
          [PrivacyValue.Anyone]: t('privacy_groups_channels_everybody'),
          [PrivacyValue.Contacts]: t('privacy_groups_channels_my_contacts'),
          [PrivacyValue.Nobody]: t('privacy_groups_channels_no_one'),
        },
        [UserPrivacySetting.CallMe]: {
          [PrivacyValue.Anyone]: t('privacy_settings:privacy_settings_everybody_call_hint'),
          [PrivacyValue.Contacts]: t('privacy_settings:privacy_settings_my_contacts_call_hint'),
          [PrivacyValue.Nobody]: t('privacy_settings:privacy_settings_no_one_call_hint'),
        },
        [UserPrivacySetting.ShowLastSeen]: {
          [PrivacyValue.Anyone]: t('common_privacy_online_status_everybody_option'),
          [PrivacyValue.Contacts]: t('common_privacy_online_status_my_contacts_option'),
          [PrivacyValue.Nobody]: t('common_privacy_online_status_no_one_option'),
        },
        [UserPrivacySetting.SearchMe]: {
          [PrivacyValue.Anyone]: t('common_privacy_global_search_everybody_option'),
          [PrivacyValue.Contacts]: t('common_privacy_global_search_my_contacts_option'),
          [PrivacyValue.Nobody]: t('common_privacy_global_search_no_one_option'),
        },
        [UserPrivacySetting.SendVoiceMessage]: {
          [PrivacyValue.Anyone]: t('common_privacy_voice_messages_everybody'),
          [PrivacyValue.Contacts]: t('common_privacy_voice_messages_my_contacts'),
          [PrivacyValue.Nobody]: t('common_privacy_voice_messages_no_one'),
        },
      };
    },

    get pageParams(): TUserPrivacyPageParams {
      return {
        [UserPrivacySetting.ShowPhoneNumber]: {
          title: t('privacy_phone_number'),
          subtitle: t('privacy_phone_number_who_can_see'),
          labels,
          setValue: Tasks.setPrivacyPhoneNumber,
        },
        [UserPrivacySetting.ShowAvatar]: {
          title: t('privacy_avatar'),
          subtitle: t('privacy_avatar_who_can_see'),
          labels,
          setValue: Tasks.setPrivacyShowAvatar,
        },
        [UserPrivacySetting.AddToGroup]: {
          title: t('groups'),
          subtitle: t('privacy_groups_channels_who_can_add'),
          labels,
          setValue: Tasks.setPrivacyGroups,
        },
        [UserPrivacySetting.AddToPublic]: {
          title: t('channels'),
          subtitle: t('privacy_groups_channels_who_can_add'),
          labels,
          setValue: Tasks.setPrivacyChannels,
        },
        [UserPrivacySetting.CallMe]: {
          title: t('privacy_settings:privacy_settings_calls_label'),
          subtitle: t('privacy_settings:privacy_settings_call_question'),
          labels,
          setValue: Tasks.setPrivacyCalls,
        },
        [UserPrivacySetting.ShowLastSeen]: {
          title: t('common_privacy_online_status'),
          subtitle: t('common_privacy_online_status_question'),
          labels,
          setValue: Tasks.setPrivacyStatus,
        },
        [UserPrivacySetting.SearchMe]: {
          title: t('common_privacy_global_search'),
          subtitle: t('privacy_global_search_who_can_find'),
          labels,
          setValue: Tasks.setPrivacySearch,
        },
        [UserPrivacySetting.SendVoiceMessage]: {
          title: t('common_privacy_voice_messages'),
          subtitle: t('common_privacy_voice_messages_question'),
          labels,
          setValue: Tasks.setPrivacyVoice,
        },
      };
    },

    get settings(): PrivacySettings {
      return Entities.PrivacySettings;
    },

    get list(): Array<IUserPrivacyList> {
      return [
        {
          key: UserPrivacySetting.ShowPhoneNumber,
          title: t('privacy_phone_number'),
          description:
            presenter.description.showPhoneNumber[Entities.PrivacySettings.showPhoneNumber],
          onClick: () => presenter.handleItemClick(UserPrivacySetting.ShowPhoneNumber),
        },
        {
          key: UserPrivacySetting.ShowAvatar,
          title: t('privacy_avatar'),
          description: presenter.description.showAvatar[Entities.PrivacySettings.showAvatar],
          onClick: () => presenter.handleItemClick(UserPrivacySetting.ShowAvatar),
        },
        {
          key: UserPrivacySetting.AddToGroup,
          title: t('groups'),
          description: presenter.description.addToGroup[Entities.PrivacySettings.addToGroup],
          onClick: () => presenter.handleItemClick(UserPrivacySetting.AddToGroup),
        },
        {
          key: UserPrivacySetting.AddToPublic,
          title: t('channels'),
          description: presenter.description.addToPublic[Entities.PrivacySettings.addToPublic],
          onClick: () => presenter.handleItemClick(UserPrivacySetting.AddToPublic),
        },
        {
          key: UserPrivacySetting.CallMe,
          title: t('privacy_settings:privacy_settings_calls_label'),
          description: presenter.description.callMe[Entities.PrivacySettings.callMe],
          onClick: () => presenter.handleItemClick(UserPrivacySetting.CallMe),
        },
        {
          key: UserPrivacySetting.SearchMe,
          title: t('common_privacy_global_search'),
          description: presenter.description.searchMe[Entities.PrivacySettings.searchMe],
          onClick: () => presenter.handleItemClick(UserPrivacySetting.SearchMe),
        },
        {
          key: UserPrivacySetting.SendVoiceMessage,
          title: t('common_privacy_voice_messages'),
          description:
            presenter.description.sendVoiceMessage[Entities.PrivacySettings.sendVoiceMessage],
          onClick: () => presenter.handleItemClick(UserPrivacySetting.SendVoiceMessage),
        },
        {
          key: UserPrivacySetting.ShowLastSeen,
          title: t('common_privacy_online_status'),
          description: presenter.description.showLastSeen[Entities.PrivacySettings.showLastSeen],
          onClick: () => presenter.handleItemClick(UserPrivacySetting.ShowLastSeen),
        },
      ];
    },

    handleItemClick(page: UserPrivacySetting): void {
      presenter.activePage = page;
    },

    openPrivacy(): void {
      presenter.activePage = null;
    },
  }));

  return presenter;
};
