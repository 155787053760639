import { IOnlineUserData } from 'APP/model/user/userModel.types';
import Entities from 'APP/store';

function handleUpdate(statuses: IOnlineUserData[] = [], clear = false): void {
  if (clear) {
    Entities.UsersStore.resetOnlineStatuses();
  }

  const filteredStatuses = statuses.filter((status) => {
    const currentOnlineStatus = Entities.UsersStore.getOnlineStatusByUserId(status.userId);

    if (!currentOnlineStatus) {
      return true;
    }

    return status.lastOnlineTs > currentOnlineStatus.lastOnlineTs;
  });

  const mappedStatuses = filteredStatuses.map((status) => [status.userId, status]);

  Entities.UsersStore.mergeOnlineStatuses(mappedStatuses);
}

export async function handleBatchedOnlineStatuses(statuses: IOnlineUserData[]): Promise<void> {
  handleUpdate(statuses, true);
}

export async function handleChangeOnlineStatus(status: IOnlineUserData): Promise<void> {
  handleUpdate([status]);
}
