import { observer } from 'mobx-react';
import React, { FC, MutableRefObject } from 'react';

import { PopoverMenu } from 'UIKIT/PopoverMenu/PopoverMenu';

import { useSubmitButtonMenuPresenter } from './SubmitButtonMenu.presenter';

interface SubmitButtonMenuProps {
  reference: MutableRefObject<HTMLButtonElement | null>;
  onClose(): void;
}

export const SubmitButtonMenu: FC<SubmitButtonMenuProps> = observer(({ reference, onClose }) => {
  const presenter = useSubmitButtonMenuPresenter();

  return (
    <PopoverMenu
      isOpened={true}
      placement="top-start"
      menuItems={presenter.menuItems}
      reference={reference.current}
      onClose={onClose}
    />
  );
});
