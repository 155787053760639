import { loadUnknownUsersByIds } from 'APP/Tasks/relationships/loadUnknownUsersByIds';
import { spaceModel } from 'APP/model/model';
import { IGetBannedSpaceUsers } from 'APP/model/space/spaceModel.types';
import Entities from 'STORE';
import { User } from 'STORE/Users/User/User';

const SPACE_BANNED_USERS_LIMIT = 20;

interface ISpaceBannedUsersPagination {
  readonly users: User[];
  readonly cursor: string | null;
}

export async function getSpaceBannedUsers(
  data: IGetBannedSpaceUsers
): Promise<ISpaceBannedUsersPagination | null> {
  const response = await spaceModel.getBannedUsers({ limit: SPACE_BANNED_USERS_LIMIT, ...data });

  if (!response) {
    return null;
  }

  const loadUsersIds = response.userIds.reduce((userIds: string[], userId) => {
    if (!Entities.UsersStore.getUserById(userId)) {
      userIds.push(userId);
    }

    return userIds;
  }, []);

  if (loadUsersIds.length) {
    await loadUnknownUsersByIds({ usersIds: loadUsersIds });
  }

  const users = response.userIds.reduce((users: User[], userId) => {
    const user = Entities.UsersStore.getUserById(userId);

    if (user) {
      users.push(user);
    }

    return users;
  }, []);

  return {
    users,
    cursor: response.cursor || null,
  };
}
