import Tasks from 'APP/Tasks';
import { UpdateReason } from 'APP/constants/scroll';
import { getMessage } from 'APP/model/message/messageModel';
import { broadcastMessagesChanges } from 'APP/packages/bus/adapters/messaging/changes';
import Entities from 'STORE';

import { GroupsEvent } from '../GroupsEvent';

export class MessageWasUpdated extends GroupsEvent {
  async process() {
    const { groupId, messageGroup, messageId } = this.eventPayload;

    const group = Entities.GroupsStore.getGroupById((messageGroup || groupId).toString());

    if (!group) {
      return;
    }

    const targetMessage = group.messagesStore.getMessageById(messageId.toString());
    if (!targetMessage) {
      return;
    }

    try {
      const message = await getMessage(group.id, messageId);

      if (!message) {
        return;
      }
      await Tasks.relationships.loadUnknownDataFromMessages({
        messages: [message],
      });
      Tasks.messaging.updateOldOrFakeMessages({
        messages: [message],
        groupId: group.id,
      });

      if (group.isActive) {
        Entities.ChatStore.setScrollChanged(UpdateReason.EditMessage, {
          messageIds: [message.id],
          isAnimated: true,
        });
      }

      broadcastMessagesChanges(groupId, { eventType: this.type });
    } catch {
      //
    }
  }
}
