import Tasks from 'APP/Tasks';
import Entities from 'STORE';
import { IPushNotification } from 'STORE/PushNotifications/PushNotifications.types';

export const onGroupNotificationClick = async (
  pushNotification: IPushNotification,
  notification: Notification
): Promise<void> => {
  await Tasks.group.setActiveGroup({ groupId: pushNotification.group || null });
  if (pushNotification.messageId) {
    Tasks.app.fullscreen.closeFullscreen();

    Tasks.messaging.focusMessage({
      groupId: pushNotification.group,
      messageId: pushNotification.messageId,
    });
  }

  Tasks.app.focusApp();

  if (notification && pushNotification.group) {
    Entities.pushNotifications.removeNotificationByRef(notification);
  }
};
