import { useLocalStore, useAsObservableSource } from 'mobx-react';
import { DragEvent } from 'react';

interface IDragDropPresenter {
  isDragging: boolean;
  dragCounter: number;
  showDropPanel: boolean;
  dragStart(event: DragEvent): void;
  dragEnter(event: DragEvent): void;
  dragLeave(event: DragEvent): void;
  dragOver(event: DragEvent): void;
  drop(event: DragEvent): void;
}

interface DraDropPresenterProps {
  onDropFiles(files: FileList): void;
}

export function useDragDropPresenter({ onDropFiles }: DraDropPresenterProps): IDragDropPresenter {
  const source = useAsObservableSource({ onDropFiles });

  const presenter = useLocalStore<IDragDropPresenter>(() => ({
    isDragging: false,
    dragCounter: 0,

    dragStart(e: DragEvent): void {
      e.dataTransfer.clearData();
    },

    dragEnter(e: DragEvent): void {
      e.preventDefault();
      e.stopPropagation();
      presenter.dragCounter++;
      if (e.dataTransfer.types.includes('Files')) {
        presenter.isDragging = true;
      }
    },

    dragLeave(e: DragEvent): void {
      e.preventDefault();
      e.stopPropagation();
      presenter.dragCounter--;
      if (presenter.dragCounter === 0) {
        presenter.isDragging = false;
      }
    },

    dragOver(e: DragEvent): void {
      e.preventDefault();
      e.stopPropagation();
    },

    drop(e: DragEvent): void {
      const files = e.dataTransfer.files;
      presenter.isDragging = false;
      presenter.dragCounter = 0;
      source.onDropFiles(files);
    },

    get showDropPanel(): boolean {
      return presenter.isDragging;
    },
  }));

  return presenter;
}
