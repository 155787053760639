import { observer } from 'mobx-react';
import React, { useCallback } from 'react';

import { List } from 'APP/components/List/List';
import { GroupsPlaceholder } from 'APP/packages/placeholders/GroupsPlaceholder/GroupsPlaceholder';

import UserWithCheckbox from '../components/UserWithCheckbox';
import styles from './styles.m.scss';

const MyContacts = ({ myContacts, common }) => {
  const renderItem = useCallback(
    (contact) => {
      return (
        <UserWithCheckbox
          user={contact}
          toggleUser={common.toggleContact}
          getIsSelected={common.getIsSelected}
          canSelectMore={common.canSelectMore}
        />
      );
    },
    [common.canSelectMore]
  );

  const props = {
    className: styles.list,
    isLoading: myContacts.isLoading,
    isScrollable: false,
    data: common.isSearchMode ? myContacts.searchContacts : myContacts.contacts,
    keyExtractor: (contact) => contact.id,
    renderItem,
    SpinnerComponent: (
      <GroupsPlaceholder
        count={10}
        className={styles.placeholders}
      />
    ),
  };

  return <List {...props} />;
};

export default observer(MyContacts);
