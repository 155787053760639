import { spaceModel } from 'APP/model/model';
import { ISpaceMarket, IUpdateSpaceMarket } from 'APP/model/space/spaceModel.types';
import Entities from 'STORE';

export async function updateSpaceMarket(data: IUpdateSpaceMarket): Promise<ISpaceMarket | null> {
  const market = await spaceModel.updateMarket(data);

  const space = Entities.spacesStore.getById(data.spaceId);
  space?.markets.updateMarket(market);

  return market;
}
