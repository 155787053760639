import { action, computed, observable } from 'mobx';
import { IReactionDisposer } from 'mobx/lib/internal';

import { MAX_COUNT_BROADCASTERS } from 'APP/constants/calls';
import { CallType, CallProviderType } from 'APP/model/call/callModel.types';
import { PermissionsTypes } from 'APP/model/callPermissions/callPermissionsModel.constants';
import Entities from 'APP/store';
import { Group } from 'STORE/Groups/Group';

import { Calls } from '../Calls';
import { ICallAvatar, ICallConstructorParams, ICallJoinAndPublishParams } from './Call.types';
import { CallPermissions } from './CallPermissions/CallPermissions';
import Events from './Events';
import { Me } from './Me/Me';
import { Members } from './Members/Members';
import { Opponents } from './Opponents/Opponents';
import { OpponentsPositions } from './OpponentsPositions/OpponentsPositions';
import { HandleUpdateResolution } from './Services/HandleUpdateResolution/HandleUpdateResolution';
import { startTimer } from './Services/startTimer/startTimer';
import { updateAvatar } from './Services/updateAvatar/updateAvatar';

export class Call {
  providerType: CallProviderType;
  @observable groupId: string;
  @observable isStarted = false;
  @observable maxBroadcasters = MAX_COUNT_BROADCASTERS;
  @observable channelId: string | null = null;
  @observable initiatorId: string | null = null;
  @observable avatar: ICallAvatar = {
    title: null,
    url: null,
    colorIndex: null,
  };
  @observable members = new Members(this);
  @observable opponents = new Opponents(this);
  @observable activeOpponentUid: number | null = null;
  @observable startTs: number | null = null;

  @observable isBusy = false;
  @observable isNoAnswer = false;

  @observable myVolumeLevel = 0;

  @observable recordingId: string | null = null;
  @observable opponentsPositions = new OpponentsPositions(this);
  @observable permissions = new CallPermissions(this);
  @observable me: Me;
  @observable backgroundPanelShown = false;
  @observable isGuestCall = false;
  @observable isSharedCall = false;
  @observable inviteLink = '';
  isDisposed = false;
  sortIndex = 1;
  calls: Calls;
  callType: CallType;
  isP2P: boolean;
  isGroup: boolean;
  isConference: boolean;
  events: Events;
  handleUpdateResolution: HandleUpdateResolution;
  disposeUpdateAvatar: IReactionDisposer;
  disposeStartTimer: IReactionDisposer;
  callingTimer: ReturnType<typeof setInterval> | null;

  constructor(params: ICallConstructorParams) {
    const {
      calls,
      groupId,
      channelId,
      callType,
      providerType,
      isGuestCall = false,
      isSharedCall = false,
    } = params;
    this.calls = calls;
    this.providerType = providerType;
    this.groupId = groupId;
    this.channelId = channelId || null;
    this.callType = callType;

    // ToDO: UNION_CALL - check conference logic and unlink from group logic
    const group = this.group;

    this.isP2P = group?.isP2P || callType === CallType.P2P;
    this.isGroup = group?.isChatGroup || callType === CallType.Group;
    this.isConference = callType === CallType.Conference;
    this.isGuestCall = isGuestCall;
    this.events = new Events(this);
    this.isSharedCall = isSharedCall;
    this.initClient();
  }

  initClient(): void {
    this.me = new Me(this, Entities.UsersStore.Me.id);
    this.handleUpdateResolution = new HandleUpdateResolution(this);
    this.disposeUpdateAvatar = updateAvatar(this);
    this.disposeStartTimer = startTimer(this);
  }

  @computed
  get isMeInitiator(): boolean {
    return !this.channelId || this.initiatorId === Entities.UsersStore.Me.id;
  }

  @computed
  get isAnswered(): boolean {
    return Boolean(this.startTs);
  }

  @computed
  get isJoining(): boolean {
    return !this.isMeInitiator && !this.isAnswered && !this.isBusy && !this.isNoAnswer;
  }

  @computed
  get isCalling(): boolean {
    return this.isMeInitiator && !this.isAnswered;
  }

  @computed
  get isVideoCall(): boolean {
    return Boolean(this.opponents.opponentsWithVideo.length);
  }

  @action
  setStarted(isStarted: boolean): void {
    this.isStarted = isStarted;
  }

  @action
  hideBackgroundPanel(): void {
    this.backgroundPanelShown = false;
  }

  @action
  showBackgroundPanel(): void {
    this.backgroundPanelShown = true;
  }

  @action
  toggleBackgroundPanel(): void {
    if (this.backgroundPanelShown) {
      this.hideBackgroundPanel();
    } else {
      this.showBackgroundPanel();
    }
  }

  @action
  dispose = async (): Promise<void> => {
    this.isDisposed = true;
    this.isStarted = false;
    await this.me.dispose();
    this.events.dispose();
    this.clearCallingTimer();
    this.handleUpdateResolution.dispose();
    this.disposeUpdateAvatar();
    this.disposeStartTimer();
  };

  @action
  startBusy = (): void => {
    this.isBusy = true;
    this.dispose();
    setTimeout(this.finishBusy, 3000);
  };

  @action
  finishBusy = (): void => {
    this.isBusy = false;
    this.delete();
  };

  @action
  startNoAnswer = (): void => {
    this.isNoAnswer = true;
    this.dispose();
    setTimeout(this.finishNoAnswer, 3000);
  };

  @action
  finishNoAnswer = (): void => {
    this.isNoAnswer = false;
    this.delete();
  };

  @action
  delete = (): void => {
    this.calls.delete(this.groupId, this.channelId);
  };

  @action
  setInitiatorId = (initiatorId: string): void => {
    this.initiatorId = initiatorId;
  };

  @computed
  get group(): Group | null {
    return this.calls.root.GroupsStore.getGroupById(this.groupId);
  }

  @computed
  get isScreenSharing(): boolean {
    return Boolean(this.opponents.screenSharingOpponent);
  }

  @action
  setMaxBroadcasters(maxBroadcasters: number): void {
    this.maxBroadcasters = maxBroadcasters;
  }

  setCallingTimer = (duration: number): void => {
    this.callingTimer = setTimeout(this.startNoAnswer, duration);
  };

  clearCallingTimer = (): void => {
    if (!this.callingTimer) {
      return;
    }

    clearTimeout(this.callingTimer);
  };

  startConversationTimer(): void {
    if (this.startTs) {
      return;
    }

    this.startTs = Number(new Date());
  }

  @action
  setAvatar(avatar: ICallAvatar): void {
    this.avatar = avatar;
  }

  @action
  async joinAndPublish(params: ICallJoinAndPublishParams): Promise<void> {
    const {
      channelId,
      audioVideoToken,
      audioVideoUid,
      shareScreenToken,
      shareScreenUid,
      isVideoMuted,
      isAudioMuted,
    } = params;

    this.channelId = channelId;

    await this.me.join({
      channelId,
      audioVideoToken,
      audioVideoUid,
      shareScreenToken,
      shareScreenUid,
    });

    await this.me.publish({
      isVideoMuted,
      isAudioMuted,
    });
  }

  @action
  startRecording(recordingId: string): void {
    this.recordingId = recordingId;
  }

  @action
  setGroupId(groupId: string): void {
    this.groupId = groupId;
  }

  @action
  stopRecording(): void {
    this.recordingId = null;
  }

  @computed
  get isRecording(): boolean {
    return this.recordingId !== null;
  }

  @computed
  get isGroupVideo(): boolean {
    return this.isGroup && this.isVideoCall;
  }
  @computed
  get permissionsRequests(): Map<PermissionsTypes, string[]> {
    return this.permissions.permissionsRequests;
  }

  @computed
  get isPermissionsRequestsEmpty(): boolean {
    return this.permissions.isPermissionsRequestsEmpty;
  }

  @computed
  get isMeInitiatorOwnerOrAdmin(): boolean {
    if (this.isGroup) {
      return this.group?.isOwner || this.group?.isAdmin || this.isMeInitiator;
    }

    return false;
  }

  @computed
  get canStartRecording(): boolean {
    return !this.isRecording;
  }

  @computed
  get isGroupCall(): boolean {
    return this.isGroup;
  }

  @computed
  get canStopRecording(): boolean {
    return this.isRecording;
  }

  @action
  setInviteLink(link: string): void {
    this.inviteLink = link;
  }
}
