import { observer } from 'mobx-react';
import React from 'react';
import { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { useTranslation } from 'APP/packages/translations';
import { Button } from 'UIKIT/Button/Button';
import { ButtonTypes } from 'UIKIT/Button/Button.types';
import { Chips } from 'UIKIT/Chips/Chips';
import { PopupFooter } from 'UIKIT/PopupFooter/PopupFooter';
import { SearchInput } from 'UIKIT/SearchInput/SearchInput';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { useClosePopupOnNavigation } from '../../hooks/useClosePopupOnNavigation';
import { MoveGroupsToSpaceList } from './MoveGroupsToSpaceList/MoveGroupsToSpaceList';
import {
  IMoveGroupsToSpacePopupParams,
  useMoveGroupsToSpacePresenter,
} from './MoveGroupsToSpacePopup.presenter';
import styles from './MoveGroupsToSpacePopup.styles.m.css';

export const MoveGroupsToSpacePopup: FC<ICommonPopupsProps<IMoveGroupsToSpacePopupParams>> =
  observer((props) => {
    const { t } = useTranslation();
    const presenter = useMoveGroupsToSpacePresenter(props);
    useClosePopupOnNavigation(props.popupInstance);

    return (
      <Popup
        title={t('space_addition_list')}
        isOpened={!!presenter.space}
        onClose={presenter.onClose}
      >
        <SearchInput
          className={styles.search}
          placeholder={t('main_menu_search_note')}
          value={presenter.searchText}
          onChange={presenter.onSearchChange}
        />

        <Chips
          chips={presenter.chips}
          onRemove={presenter.onRemoveChips}
        />

        {presenter.space && (
          <MoveGroupsToSpaceList
            space={presenter.space}
            groups={presenter.groups}
            selectedGroups={presenter.checkedGroups}
            hasSearch={!!presenter.searchText}
            onToggleGroup={presenter.onToggleGroup}
          />
        )}

        <PopupFooter>
          <Button
            type={ButtonTypes.Button}
            isDisabled={presenter.isSubmitting || !presenter.checkedGroups.size}
            isLoading={presenter.isSubmitting}
            onClick={presenter.onSubmit}
            theme="1"
          >
            {t('add_button')}
          </Button>
        </PopupFooter>
      </Popup>
    );
  });
