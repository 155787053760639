import { observer } from 'mobx-react';
import React from 'react';

import MicOff from 'ICONS/ic-mic-off.svg';
import Mic from 'ICONS/ic-mic-on.svg';
import VideoOff from 'ICONS/ic-video-off.svg';
import VideoOn from 'ICONS/ic-video-on.svg';
import { IconButton, IconViewSizes } from 'UIKIT/IconButton';

import styles from './PreviewActionsPanel.styles.m.css';

interface IPreviewActionsPanelProps {
  isAudioMuted: boolean;
  isVideoMuted: boolean;
  unmuteAudio(): void;
  muteAudio(): void;
  unmuteVideo(): void;
  muteVideo(): void;
}
export const PreviewActionsPanel: React.FC<IPreviewActionsPanelProps> = observer((props) => {
  const { isAudioMuted, isVideoMuted, unmuteAudio, muteAudio, unmuteVideo, muteVideo } = props;

  return (
    <div className={styles.container}>
      <IconButton
        Icon={isAudioMuted ? MicOff : Mic}
        onClick={isAudioMuted ? unmuteAudio : muteAudio}
        className={styles.buttonContainer}
        size={IconViewSizes.Large}
        iconSize={IconViewSizes.Medium}
        theme={isAudioMuted ? '12' : '13'}
      />
      <IconButton
        Icon={isVideoMuted ? VideoOff : VideoOn}
        onClick={isVideoMuted ? unmuteVideo : muteVideo}
        className={styles.buttonContainer}
        size={IconViewSizes.Large}
        iconSize={IconViewSizes.Medium}
        theme={isVideoMuted ? '12' : '13'}
      />
    </div>
  );
});
