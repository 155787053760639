import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { InView } from 'react-intersection-observer';

import { AddKeyPressHandler } from 'APP/packages/keyBoardHandler';
import MentionsPlaceholder from 'APP/packages/placeholders/Mentions';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';

import Mention from './Mention';
import usePresenter from './presenter';
import styles from './styles.m.css';

const MentionList = () => {
  const { containerRef, common, list, focusHandler, actions, keyboardHandler } = usePresenter();

  if (!common.isAvailableMentions) {
    return null;
  }

  if (list.isLoading) {
    return (
      <div className={classNames(styles.container, customScrollStyles.container)}>
        <MentionsPlaceholder />
      </div>
    );
  }

  if (!list.hasMentions) {
    return null;
  }

  return (
    <>
      <AddKeyPressHandler
        isCaptured
        onArrowDown={keyboardHandler.onKeyDown}
        onArrowUp={keyboardHandler.onKeyUp}
        onEnter={keyboardHandler.onEnter}
      />
      <div
        className={classNames(styles.container, customScrollStyles.container)}
        ref={containerRef}
      >
        {list.mentions.map((item, index) => {
          return (
            <Mention
              key={item.id}
              index={index}
              mention={item}
              onSelectMention={actions.onSelectMention}
              isFocused={focusHandler.getIsFocused(index)}
              onHoverMention={focusHandler.onHoverMention}
              onChangeViewState={focusHandler.onChangeViewState}
            />
          );
        })}
        {!list.isLoadedAllMentions && (
          // backend sends sometimes duplicates of mentions
          // and sometimes all mentions have already exist
          // and we need to change "key" to recall loadMore with updated cursor
          <InView
            key={list.cursor}
            onChange={list.loadMore}
            className={styles.loadMore}
          />
        )}
      </div>
    </>
  );
};

export default observer(MentionList);
