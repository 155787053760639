import React, { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { RouterLink } from 'APP/router/constants';
import { AllPins } from 'MAIN/ChatView/AllPins/AllPins';
import { ChatView } from 'MAIN/ChatView/ChatView';
import { MediaGallery } from 'MAIN/components/MediaGallery/MediaGallery';

export const ChatRouter: FC = () => {
  const match = useRouteMatch<{ id: string }>();

  const allPinsPaths = [RouterLink.allChatPins, RouterLink.allChannelPins];

  return (
    <>
      <Switch>
        {allPinsPaths.map((path) => (
          <Route
            key={path}
            path={path}
          >
            <AllPins groupId={match.params.id} />
          </Route>
        ))}
        <Route>
          <ChatView />
        </Route>
      </Switch>
      <MediaGallery />
    </>
  );
};
