import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { GridLayoutList } from 'MAIN/OngoingCall/Shared/GridLayout/components/GridLayoutList/GridLayoutList';
import { useOpponentsVideo } from 'MAIN/OngoingCall/hooks/useOpponentsVideo/useOpponentsVideo';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';

interface IGridLayoutProps {
  avatarLargeType: AvatarTypes;
  avatarMediumType: AvatarTypes;
  getClassName(length: number): string;
}

export const GridLayout: FC<IGridLayoutProps> = observer(
  ({ avatarLargeType, avatarMediumType, getClassName }) => {
    const { opponentsKeys } = useOpponentsVideo(true);

    return (
      <div className={getClassName(opponentsKeys.length)}>
        <GridLayoutList
          opponentsKeys={opponentsKeys}
          avatarType={opponentsKeys.length > 2 ? avatarMediumType : avatarLargeType}
        />
      </div>
    );
  }
);
