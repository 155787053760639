import React, { FC, ReactNode } from 'react';

import styles from './CountryItem.styles.m.css';

interface ICountryItemProps {
  flag: ReactNode;
  countryName: string;
  phonePrefix: string;
}

export const CountryItem: FC<ICountryItemProps> = ({ flag, countryName, phonePrefix }) => {
  return (
    <span className={styles.container}>
      <span className={styles.prepend}>{flag}</span>
      <span>{countryName}</span>
      <span className={styles.append}>{phonePrefix}</span>
    </span>
  );
};
