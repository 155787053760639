import { useCallback, useEffect } from 'react';

export function useVideoPlayerVolume({ videoRef, config, onConfigUpdate }) {
  const setVolume = useCallback((volumeValue) => {
    onConfigUpdate({
      volume: volumeValue,
      isMuted: !volumeValue,
    });
  }, []);

  const toggleMute = useCallback(() => {
    onConfigUpdate({ isMuted: !config.isMuted });
  }, [config.isMuted]);

  useEffect(() => {
    videoRef.current.volume = config.volume;
  }, [config.volume]);

  return { setVolume, toggleMute };
}
