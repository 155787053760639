import { useAsObservableSource, useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { ALERT_TYPES } from 'APP/constants/app';
import { useTranslation } from 'APP/packages/translations';
import UnBanIcon from 'ICONS/ic-user-unban.svg';
import { Space } from 'STORE/Spaces/Space';
import { User } from 'STORE/Users/User/User';
import { IPopoverMenuItem } from 'UIKIT/PopoverMenu/PopoverMenu.types';

export interface ISpaceMembersListItemPresenterOptions {
  space: Space | null;
  user: User;
  onUnbanUser(userId: string): void;
  onClosePopup(): void;
}

interface SpaceBlackListItemPresenter {
  canStartUserChat: boolean;
  menuReference: HTMLElement | null;
  menuItems: IPopoverMenuItem[];
  isOpenedMenu: boolean;
  isMenuLoading: boolean;
  unbanMember(): Promise<void>;
  onToggleMenu(element: HTMLElement): void;
  onCloseMenu(): void;
  onOpenUserChat(): Promise<void>;
}

export function useSpaceBlackListItemPresenter({
  space,
  user,
  onUnbanUser,
  onClosePopup,
}: ISpaceMembersListItemPresenterOptions): SpaceBlackListItemPresenter {
  const source = useAsObservableSource({ space, user, onUnbanUser, onClosePopup });
  const { t } = useTranslation();

  const presenter = useLocalStore<SpaceBlackListItemPresenter>(() => ({
    menuReference: null,
    isMenuLoading: false,

    get canStartUserChat(): boolean {
      return !!source.user.startChat;
    },

    async onOpenUserChat(): Promise<void> {
      await source.user.startChat();
      source.onClosePopup();
    },

    onToggleMenu(menuReference: HTMLElement): void {
      presenter.menuReference = menuReference;
    },

    onCloseMenu(): void {
      this.menuReference = null;
    },

    get isOpenedMenu(): boolean {
      return !!presenter.menuReference;
    },

    get menuItems(): IPopoverMenuItem[] {
      return [
        {
          key: 'unban',
          title: t('unban'),
          icon: UnBanIcon,
          onClick: presenter.unbanMember,
        },
      ];
    },

    async unbanMember(): Promise<void> {
      if (!source.space) {
        return;
      }

      const canUnban = await Tasks.app.showConfirm({
        text: t('unban_user_alert', { 0: source.user.displayName }),
        primaryText: t('common_yes'),
        secondaryText: t('common_cancel'),
      });

      if (!canUnban) {
        return;
      }

      presenter.isMenuLoading = true;

      const result = await Tasks.space.unbanSpaceMembers({
        spaceId: source.space.id,
        userIds: [source.user.id],
      });

      if (result) {
        source.onUnbanUser(source.user.id);
      } else {
        Tasks.app.addAlert({
          type: ALERT_TYPES.SOMETHING_WRONG_ERROR,
        });
      }

      presenter.isMenuLoading = false;
    },
  }));

  return presenter;
}
