import { spaceModel } from 'APP/model/model';
import { IPersonalizedSpace, SpaceType, SpaceUserRole } from 'APP/model/space/spaceModel.types';
import Entities from 'STORE';
import { Space } from 'STORE/Spaces/Space';

const isPrivateSpaceWithoutMe = (response: IPersonalizedSpace): boolean => {
  return response.space.type === SpaceType.Private && response.role === SpaceUserRole.Guest;
};

export async function loadSpace(spaceId: string, skipPrivate = true): Promise<Space | null> {
  let space = Entities.spacesStore.getById(spaceId);
  if (space && !space.isGuest) {
    return space;
  }

  try {
    const personalizedSpace = await spaceModel.getSpace(spaceId);

    if (skipPrivate && isPrivateSpaceWithoutMe(personalizedSpace)) {
      Entities.spacesStore.delete(spaceId);
      return null;
    }

    Entities.spacesStore.addSpace(personalizedSpace);
    space = Entities.spacesStore.getById(spaceId);
    space?.reset();
    return space;
  } catch {
    return null;
  }
}
