import { observer } from 'mobx-react';
import React, { FC } from 'react';

import Tasks from 'APP/Tasks';
import { useTranslation } from 'APP/packages/translations';
import IcLink from 'ICONS/ic-link.svg';
import { IconButton, IconViewSizes } from 'UIKIT/IconButton';

import styles from './ShareInviteLink.styles.m.css';

export const ShareInviteLink: FC = observer(() => {
  const { t } = useTranslation();

  return (
    <IconButton
      onClick={Tasks.calls.copyUnionCallLink}
      className={styles.link}
      iconClassName={styles.icon}
      Icon={IcLink}
      theme="1"
      text={t('common_copy_invite_link')}
      size={IconViewSizes.Large}
      iconSize={IconViewSizes.Auto}
    />
  );
});
