import { PayloadType } from 'APP/model/message/messageModel.types';

import { FOCUS_NAMES } from '../../constants';
import payloadService from '../payloads';
import { getFirstTextPositionOnPayloads } from './getFirstTextPositionOnPayloads';

const PAYLOADS_WHICH_NOT_CHANGE_CURSOR = [
  PayloadType.Image,
  PayloadType.Video,
  PayloadType.Contact,
  PayloadType.AudioMessage,
];

export const getPositionAfterDeleteSomePayload = (payloads, order) => {
  const payload = payloadService.getPayloadByOrder(payloads, order);

  if (PAYLOADS_WHICH_NOT_CHANGE_CURSOR.includes(payload.type)) {
    return null;
  }

  const position = getFirstTextPositionOnPayloads(payloads.slice(0, order).reverse());
  return position !== null ? position : FOCUS_NAMES.TITLE;
};
