import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { VerifiedBadge } from 'APP/components/VerifiedBadge/VerifiedBadge';
import PopupInfoCard from 'APP/packages/UiKit/PopupInfoCard';
import popupItemStyles from 'APP/packages/UiKit/PopupItem/styles.m.scss';
import { User } from 'STORE/Users/User/User';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';

import { usePopupUserItemPresenter } from './PopupUserItem.presenter';
import styles from './PopupUserItem.styles.m.css';
import { PopupUserItemClickHandler } from './PopupUserItem.types';

interface IPopupUserItemProps {
  user: User;
  hint?: string;
  hintClassName?: string;
  onClick?: PopupUserItemClickHandler;
}

export const PopupUserItem: FC<IPopupUserItemProps> = observer((props) => {
  const { user, hint, hintClassName, onClick } = props;
  const { userOnlineStatusText } = usePopupUserItemPresenter({ user });

  const classes = classNames(popupItemStyles.container, {
    [popupItemStyles.interactive]: Boolean(onClick),
  });

  const content = (
    <>
      <PopupInfoCard
        type={AvatarTypes.Round_10}
        className={popupItemStyles.card}
        avatarTitle={user.avatarTitle}
        avatarUrl={user.avatarUrl || undefined}
        avatarBackgroundColorIndex={user.avatarColorIndex}
        title={user.displayName}
        subtitle={userOnlineStatusText}
        badge={user.verified && <VerifiedBadge />}
      />
      {hint && (
        <span className={classNames(popupItemStyles.hint, styles.hint, hintClassName)}>{hint}</span>
      )}
    </>
  );

  if (!onClick) {
    return <div className={classes}>{content}</div>;
  }

  return (
    <button
      onClick={(): void => onClick(user)}
      className={classes}
    >
      {content}
    </button>
  );
});
