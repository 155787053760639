import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Tasks from 'APP/Tasks';
import { AudioSourceType } from 'APP/constants/app';
import date from 'APP/packages/date';
import Entities from 'STORE';

interface IPreviewRecordPresenter {
  audioRef: RefObject<HTMLAudioElement>;
  src: string;
  histogram: string;
  isPlaying: boolean;
  time: string;
  progress: number;
  handlePlay(): void;
  handlePause(): void;
  onTimeUpdate(): void;
  onEnded(): void;
  onCanPlay(): void;
  onChangeProgress(progressPercentage: number): void;
}

export function usePreviewRecordPresenter(): IPreviewRecordPresenter {
  const audioRef = useRef<HTMLAudioElement>(null);

  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [progress, setProgress] = useState(0);
  const [histogram, setHistogram] = useState<string>('');
  const removeAudioSourceRef = useRef<(() => void) | null>(null);
  const playPromise = useRef<Promise<void>>(Promise.resolve());

  const handlePlay = useCallback(() => {
    playPromise.current = audioRef.current?.play() || Promise.resolve();
  }, []);

  const handlePause = useCallback(() => {
    playPromise.current.then(() => {
      audioRef.current?.pause();
    });
  }, []);

  const onTimeUpdate = useCallback(() => {
    setCurrentTime(audioRef.current?.currentTime || 0);

    const progress =
      ((audioRef.current?.currentTime || 0) * 100) / (audioRef.current?.duration || 0);
    setProgress(!isNaN(progress) ? progress / 100 : 0);
  }, []);

  const onEnded = useCallback(() => {
    removeAudioSource();
    setCurrentTime(0);
    setProgress(0);
  }, []);

  const onCanPlay = useCallback(() => {
    setCurrentTime(audioRef.current?.currentTime || 0);
    setDuration(audioRef.current?.duration || 0);
  }, []);

  const onChangeProgress = useCallback((progressPercentage) => {
    if (audioRef.current) {
      audioRef.current.currentTime = audioRef.current.duration * progressPercentage;
    }
  }, []);

  const time = useMemo(() => {
    return date.secondsToString(duration, currentTime);
  }, [duration, currentTime]);

  const removeAudioSource = useCallback(() => {
    if (removeAudioSourceRef.current) {
      removeAudioSourceRef.current();
      removeAudioSourceRef.current = null;
    }
  }, []);

  useEffect(() => {
    return () => removeAudioSource();
  }, []);

  useEffect(() => {
    Entities.InputPanel.voice.getAudioHistogram().then(setHistogram);
  }, []);

  useEffect(() => {
    const onPlay = (): void => {
      setIsPlaying(true);
      removeAudioSourceRef.current = Tasks.app.audioSource.setCurrentSource(
        AudioSourceType.Voice,
        handlePause
      );
    };
    const onPause = (): void => {
      setIsPlaying(false);
      removeAudioSource();
    };

    audioRef.current?.addEventListener('play', onPlay);
    audioRef.current?.addEventListener('pause', onPause);
    return () => {
      audioRef.current?.removeEventListener('play', onPlay);
      audioRef.current?.removeEventListener('pause', onPause);
    };
  }, []);

  return {
    audioRef,
    src: Entities.InputPanel.voice.src || '',
    histogram,
    isPlaying,
    time,
    progress,
    handlePlay,
    handlePause,
    onTimeUpdate,
    onEnded,
    onCanPlay,
    onChangeProgress,
  };
}
