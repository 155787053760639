import dateService from 'APP/packages/date';
import { t } from 'MODULE/i18n/service';

const ONE_HOUR = 1000 * 60 * 60;

export const getClosestQuoterTime = (): Date => {
  let date = new Date();
  const time = date.getMinutes();
  if (time >= 0 && time <= 14) {
    date.setMinutes(15);
  } else if (time >= 15 && time <= 29) {
    date.setMinutes(30);
  } else if (time >= 30 && time <= 44) {
    date.setMinutes(45);
  } else {
    date.setMinutes(0);
    date = new Date(date.getTime() + ONE_HOUR);
  }

  return date;
};

export const getDateWithTime = (date: Date, time: string): number => {
  const startTimeArray = time.split(':');

  return date.setHours(Number(startTimeArray[0]), Number(startTimeArray[1]), 0);
};

export const getScheduledEventTime = (startTs: number, endTs: number): string => {
  const startDate = new Date(startTs);
  const endDate = new Date(endTs);

  const isTheSameDay = startDate.toDateString() === endDate.toDateString();
  const isToday = dateService.isToday(startDate);
  const isInThisYear = startDate.getFullYear() !== dateService.now().getFullYear();

  if (isToday && isTheSameDay) {
    return `${t('call_today_at')} ${dateService.time(startDate, false)}-${dateService.time(
      endDate,
      false
    )}`;
  }

  if (isToday && !isTheSameDay) {
    return `${t('call_today_at')} ${dateService.time(startDate, false)} - ${dateService.format(
      endDate,
      {
        weekday: 'short',
        day: 'numeric',
        month: 'long',
        hour: 'numeric',
        minute: 'numeric',
      }
    )}`;
  }

  if (!isToday && isTheSameDay) {
    return `${dateService.format(startDate, {
      year: isInThisYear ? 'numeric' : undefined,
      weekday: 'short',
      day: 'numeric',
      month: 'long',
      hour: 'numeric',
      minute: 'numeric',
    })} - ${dateService.time(endDate, false)}`;
  }

  return `${dateService.format(startDate, {
    year: isInThisYear ? 'numeric' : undefined,
    weekday: 'short',
    day: 'numeric',
    month: 'long',
    hour: 'numeric',
    minute: 'numeric',
  })} - ${dateService.format(endDate, {
    year: isInThisYear ? 'numeric' : undefined,
    weekday: 'short',
    day: 'numeric',
    month: 'long',
    hour: 'numeric',
    minute: 'numeric',
  })}`;
};
