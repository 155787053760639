import { computed } from 'mobx';

import { User } from 'STORE/Users/User/User';
import { TAvatarBackgroundColorIndex } from 'UIKIT/Avatar/Avatar.types';

import { Group } from '../Group';

export class P2P extends Group {
  get isP2P(): boolean {
    return true;
  }

  get isGroup(): boolean {
    return false;
  }

  get isBot(): boolean {
    return Boolean(this.botOpponentId);
  }

  @computed
  get canInviteUsers(): boolean {
    return false;
  }

  @computed
  get avatarColorIndex(): TAvatarBackgroundColorIndex {
    if (this.groupOpponent) {
      return this.groupOpponent.avatarColorIndex;
    }
    return '1';
  }

  @computed
  get verified(): boolean {
    if (this.groupOpponent) {
      return this.groupOpponent.verified;
    }

    return this._verified;
  }

  @computed
  get avatarTitle(): string | null {
    if (this.groupOpponent) {
      return this.groupOpponent.avatarTitle;
    }

    return null;
  }

  @computed
  get avatarUrl(): string | null {
    if (this.groupOpponent) {
      return this.groupOpponent.avatarUrl || null;
    }

    return null;
  }

  set avatarUrl(avatarUrl: string | null) {
    this.setAvatarUrl(avatarUrl);
  }

  get groupOpponentId(): string | null {
    return this.opponentId || this.botOpponentId;
  }

  @computed
  get groupOpponent(): User {
    return this.root.UsersStore.getUserById(this.groupOpponentId);
  }

  @computed
  get isBlockedMe(): boolean {
    return this.groupOpponent ? this.groupOpponent.isBlockedMe : false;
  }

  @computed
  get isBlockedByMe(): boolean {
    return this.groupOpponent ? this.groupOpponent.isBlockedByMe : false;
  }

  @computed
  get isOnlineOpponent(): boolean {
    if (!this.groupOpponent) {
      return false;
    }

    return this.groupOpponent.isOnline;
  }

  get canMentionAll(): boolean {
    return false;
  }

  get canBeScheduled(): boolean {
    return true;
  }

  @computed
  get canLeaveComment(): boolean {
    return false;
  }
}
