import { useLocalStore } from 'mobx-react';
import { useRef, useEffect } from 'react';

export default ({ containerRef, list, common }) => {
  const timeout = useRef();

  const presenter = useLocalStore(() => ({
    isDisabledMouseEvent: false,
    focusedIndex: 0,
    inViewState: {},

    getIsFocused(index) {
      return presenter.focusedIndex === index;
    },

    onHoverMention(index) {
      if (!presenter.isDisabledMouseEvent) {
        presenter.focusedIndex = index;
      }
    },

    onChangeViewState(index, inView) {
      presenter.inViewState[index] = inView;
    },

    resetFocusedIndex() {
      if (this.focusedIndex !== 0) {
        this.focusedIndex = 0;
      }
    },

    // disabled mention focus by mouse enter
    // when focus changes by arrow navigation
    disableMouseEvent() {
      presenter.isDisabledMouseEvent = true;

      if (timeout.current) {
        clearTimeout(timeout.current);
      }

      timeout.current = setTimeout(() => {
        presenter.isDisabledMouseEvent = false;
      }, 100);
    },

    prevFocus() {
      presenter.disableMouseEvent();

      if (presenter.focusedIndex === 0) {
        presenter.focusedIndex = list.mentions.length - 1;
        return;
      }

      presenter.focusedIndex = presenter.focusedIndex - 1;
    },

    nextFocus() {
      presenter.disableMouseEvent();

      if (list.mentions.length - 1 === presenter.focusedIndex && !list.isLoadedAllMentions) {
        return;
      }

      if (list.mentions.length - 1 === presenter.focusedIndex && list.isLoadedAllMentions) {
        presenter.focusedIndex = 0;
        return;
      }

      presenter.focusedIndex = presenter.focusedIndex + 1;
    },

    scrollToFocusedItem({ position }) {
      if (!presenter.inViewState[presenter.focusedIndex] && containerRef.current.hasChildNodes()) {
        const node = containerRef.current.childNodes[presenter.focusedIndex];
        node.scrollIntoView({
          behavior: 'instant',
          block: position,
        });
      }
    },
  }));

  useEffect(() => {
    presenter.resetFocusedIndex();
  }, [common.inputPanel.mention.filter]);

  return presenter;
};
