import classNames from 'classnames';
import React, { FC, MouseEvent, MouseEventHandler, MutableRefObject } from 'react';

import styles from './FAB.styles.m.css';

export type TFABTheme =
  | '1'
  | '2'
  | '2_1'
  | '2_i'
  | '3'
  | '3_1'
  | '3_2'
  | '6'
  | '7'
  | '8'
  | '8_1'
  | '12'
  | '12_1'
  | '14'
  | '15'
  | '16'
  | '17'
  | '21';
//                     24px  32px   36px   40px   48px   52px   64px
export type TFABSize = '0' | '10' | '20' | '30' | '40' | '50' | '60';

interface IFABProps {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onContextMenu?(event: MouseEvent<HTMLButtonElement>): void;
  isDisabled?: boolean;
  title?: string;
  Icon: React.ElementType;
  badge?: string;
  size?: TFABSize;
  iconSize?: TFABSize;
  theme?: TFABTheme;
  dataTest?: string;
  className?: string;
  setRef?: MutableRefObject<HTMLButtonElement | null>;
}

export const FAB: FC<IFABProps> = (props) => {
  const {
    onClick,
    isDisabled,
    title,
    Icon,
    badge,
    className,
    size = '40',
    iconSize,
    theme = '7',
    dataTest,
    setRef,
    onContextMenu,
  } = props;

  const containerClasses = classNames(
    styles.container,
    styles[`size-${size}`],
    styles[`icon-size-${iconSize || size}`],
    styles[`theme-${theme}`],
    className
  );

  return (
    <button
      ref={setRef}
      className={containerClasses}
      title={title}
      onClick={onClick}
      disabled={isDisabled}
      data-test={dataTest}
      onContextMenu={onContextMenu}
    >
      {!!badge && <span className={styles.badge}>{badge}</span>}
      <Icon className={styles.icon} />
    </button>
  );
};
