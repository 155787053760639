import { observer } from 'mobx-react';
import React from 'react';

import { PERMISSIONS_TYPES_TRANSLATIONS } from 'APP/constants/calls';
import { useTranslation } from 'APP/packages/translations';

import { PermissionRequestItem } from '../PermissionRequestItem';
import { usePresenter } from './presenter';
import styles from './styles.m.css';

export const RequestsPermissionsBlock = observer((props) => {
  const { t } = useTranslation();
  const presenter = usePresenter(props.permissionType);

  return (
    <>
      <div className={styles.title}>{t(PERMISSIONS_TYPES_TRANSLATIONS[props.permissionType])}</div>
      {presenter.permissionsRequests.map((userId) => (
        <PermissionRequestItem
          key={userId}
          permissionType={props.permissionType}
          userId={userId}
        />
      ))}
    </>
  );
});
