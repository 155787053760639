import { observer } from 'mobx-react';
import React from 'react';

import ForceUpdateImg from 'APP/images/gem-mascot-new-version.svg';
import { useTranslation } from 'APP/packages/translations';
import { Button } from 'UIKIT/Button/Button';

import usePresenter from './presenter';
import styles from './style.m.scss';

const ForceUpdate = () => {
  const presenter = usePresenter();
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.logo}>
          <img
            src={ForceUpdateImg}
            width={189}
            height={176}
            alt={''}
          />
        </div>
        <div className={styles.title}>{t('forced_update_os_min_supp_version_headline')}</div>
        <div className={styles.description}>{t('force_upgrade_text')}</div>
        <div className={styles.button}>
          <Button
            theme="1"
            onClick={presenter.handleUpdate}
          >
            {t('force_upgrade_update_button_title')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default observer(ForceUpdate);
