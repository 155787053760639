/* global Intl, Date */
import config from '../config';
import isValidDate from './isValidDate';

export default (rawFormatDate, options = {}) => {
  if (!rawFormatDate) {
    return null;
  }

  const formatDate = new Date(rawFormatDate);

  if (!isValidDate(formatDate)) {
    return null;
  }

  return new Intl.DateTimeFormat(config.language, options).format(formatDate);
};
