import React from 'react';

import { getFullScreenImage } from 'APP/packages/imageResizer';

import styles from './MediaGalleryImage.styles.m.css';

export const MediaGalleryImage = ({ payload }) => {
  return (
    <div className={styles.container}>
      <img
        className={styles.image}
        src={getFullScreenImage(payload.url || payload.previewUrl)}
        alt=""
      />
    </div>
  );
};
