import { observer } from 'mobx-react';
import React from 'react';

import NoDraftsIcon from 'APP/images/gem-mascot-not-found.svg';
import { AddKeyPressHandler } from 'APP/packages/keyBoardHandler';
import { useTranslation } from 'APP/packages/translations';
import { ChatBackground } from 'MAIN/components/ChatBackground/ChatBackground';
import { InfoBlock, InfoBlockTypes } from 'UIKIT/InfoBlock/InfoBlock';

import { useArticlesDraftPresenter } from './ArticlesDraft.presenter';
import styles from './ArticlesDraft.styles.m.css';
import Drafts from './Drafts';
import Header from './Header';

export const ArticlesDraft = observer(({ groupId }) => {
  const presenter = useArticlesDraftPresenter(groupId);
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <AddKeyPressHandler onEsc={presenter.onClose} />
      <ChatBackground />
      <Header groupId={groupId} />
      <div className={styles.content}>
        {presenter.isLoaded ? (
          <Drafts parentPresenter={presenter} />
        ) : (
          <>
            <InfoBlock
              descriptions={[t('channels:channel_article_no_drafts_yet')]}
              type={presenter.hasBackground ? InfoBlockTypes.ChatWithPattern : InfoBlockTypes.Chat}
              image={NoDraftsIcon}
            />
          </>
        )}
      </div>
    </div>
  );
});
