import { NewsType, INewsItem } from 'APP/model/catalog/catalogModel.types';

import { Post } from './Post';

export class GuestPost extends Post {
  constructor(data: INewsItem) {
    super(data);

    this.type = NewsType.GuestPost;
  }
}
