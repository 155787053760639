import { action, computed, observable } from 'mobx';

import { ICallProviderPublisher } from 'APP/packages/callProviders/callProviders.types';

import { OpenviduProvider } from './OpenviduProvider';

export class OpenviduProviderPublisher implements ICallProviderPublisher {
  private root: OpenviduProvider;
  @observable isPlayingVideo = false;

  constructor(root: OpenviduProvider) {
    this.root = root;
  }

  @computed
  get hasAudio(): boolean {
    return this.root.hasAudio;
  }

  @computed
  get hasVideo(): boolean {
    return this.root.hasVideo;
  }

  @action
  playVideo(element: HTMLDivElement): void {
    if (!this.root.openviduPublisher) {
      return;
    }
    const video = this.root.openviduPublisher.createVideoElement(element);
    video.muted = true;
    this.isPlayingVideo = true;
  }

  @action
  stopVideo(): void {
    this.isPlayingVideo = false;
  }

  getVolumeLevel(): number {
    return this.hasAudio ? this.root.publisherVolumeLevel || 0 : 0;
  }
}
