import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { GroupsPlaceholder } from 'APP/packages/placeholders/GroupsPlaceholder/GroupsPlaceholder';
import { useTranslation } from 'APP/packages/translations';
import { Button, ButtonTypes } from 'UIKIT/Button/Button';
import { PopupFooter } from 'UIKIT/PopupFooter/PopupFooter';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { useClosePopupOnNavigation } from '../../hooks/useClosePopupOnNavigation';
import { DeleteSpaceGroupsList } from './DeleteSpaceGroupsList/DeleteSpaceGroupsList';
import {
  IDeleteSpacePopupParams,
  useDeleteSpacePopupPresenter,
} from './DeleteSpacePopup.presenter';
import styles from './DeleteSpacePopup.styles.m.css';

export const DeleteSpacePopup: FC<ICommonPopupsProps<IDeleteSpacePopupParams>> = observer(
  (props) => {
    const { t } = useTranslation();
    const presenter = useDeleteSpacePopupPresenter(props);
    useClosePopupOnNavigation(props.popupInstance);

    return (
      <Popup
        className={presenter.isEmpty ? styles.popupEmptyGroups : undefined}
        title={t('space_delete_space')}
        isOpened={!!presenter.space}
        handlePrevStep={presenter.hasBackButton ? presenter.onBack : null}
        onClose={presenter.onClose}
      >
        <h6 className={styles.title}>
          {t('space_delete_community_subhint', { 0: presenter.space?.name })}
        </h6>

        {presenter.showDivider && <div className={styles.divider}></div>}

        {presenter.showGroupList && (
          <>
            <div className={styles.header}>
              <span className={styles.headerTitle}>
                {t('space_number_chat_delete', { 0: presenter.selectedGroups.size })}
              </span>

              <button
                className={styles.deselectButton}
                type="button"
                disabled={!presenter.selectedGroups.size}
                onClick={presenter.onDeselectAll}
              >
                {t('space_deselect_button')}
              </button>
            </div>

            <DeleteSpaceGroupsList
              groups={presenter.groups}
              selectedGroups={presenter.selectedGroups}
              onToggleGroup={presenter.onToggleGroup}
            />
          </>
        )}

        {presenter.isLoading && (
          <div className={styles.placeholder}>
            <GroupsPlaceholder
              className={styles.placeholderItem}
              count={10}
            />
          </div>
        )}

        <PopupFooter>
          <Button
            type={ButtonTypes.Button}
            isDisabled={presenter.isSubmitting || presenter.isLoading}
            isLoading={presenter.isSubmitting}
            onClick={presenter.onSubmit}
            theme="1"
          >
            {t('delete')}
          </Button>
        </PopupFooter>
      </Popup>
    );
  }
);
