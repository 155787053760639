import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Entities from 'STORE';
import { InputPanel } from 'STORE/ViewModels/InputPanel';

interface IInputPanelButtonsPresenter {
  timer: ReturnType<typeof setInterval> | null;
  isSlowMode: boolean;
  remainingSecond: number;
  checkSlowMode(): void;
  showVoiceButton: boolean;
  showSubmitButton: boolean;
  showScheduleButton: boolean;
}

export const useInputPanelButtonsPresenter = (): IInputPanelButtonsPresenter => {
  const presenter = useLocalStore<IInputPanelButtonsPresenter>(() => ({
    timer: null,
    isSlowMode: false,
    remainingSecond: 0,

    checkSlowMode(): void {
      if (presenter.timer) {
        clearTimeout(presenter.timer);
      }
      presenter.isSlowMode = false;
      const remainingTime = Entities.clientConfig.slowMode.endSlowModeTs - Date.now();
      if (remainingTime > 0) {
        presenter.isSlowMode = true;
        presenter.remainingSecond = Math.floor(remainingTime / 1000) + 1; // +1 do not show 00:00
        presenter.timer = setTimeout(presenter.checkSlowMode, 1000);
      }
    },

    get showVoiceButton(): boolean {
      return (
        !presenter.isSlowMode &&
        !Entities.InputPanel.edit.isEditMode &&
        !Entities.InputPanel.voice.isShowRecordPanel &&
        Entities.InputPanel.send.isDisabledSend
      );
    },

    get showSubmitButton(): boolean {
      return !this.showVoiceButton && !Entities.InputPanel.group?.isSchedule;
    },

    get showScheduleButton(): boolean {
      return !this.showVoiceButton && Entities.InputPanel.group?.isSchedule;
    },

    get inputPanel(): InputPanel {
      return Entities.InputPanel;
    },
  }));

  useEffect(() => {
    presenter.checkSlowMode();
    return () => {
      if (presenter.timer) {
        clearTimeout(presenter.timer);
      }
    };
  }, [Entities.clientConfig.slowMode]);

  return presenter;
};
