import React, { FC, memo } from 'react';

import { STICKER_BUBBLE_MAX_WIDTH } from 'APP/constants/stickers';
import { getImageByLargestSize } from 'APP/packages/imageResizer/getImageByLargestSize';

interface IStaticStickerProps {
  staticUrl: string;
  width: number;
  height: number;
}
export const StaticSticker: FC<IStaticStickerProps> = memo((props) => {
  const { staticUrl, width, height } = props;
  // the same url as in sticker bubble
  const resizedUrl = getImageByLargestSize(staticUrl, STICKER_BUBBLE_MAX_WIDTH);

  return (
    <img
      src={resizedUrl}
      alt=""
      width={width}
      height={height}
    />
  );
});
