import React, { FC } from 'react';

import { ChannelItem } from 'MAIN/SidebarPanel/GroupList/ChannelItem/ChannelItem';
import { GroupItem } from 'MAIN/SidebarPanel/GroupList/GroupItem/GroupItem';
import { Group } from 'STORE/Groups/Group';

interface ISpaceGroupItemProps {
  group: Group;
}

export const SpaceGroupItem: FC<ISpaceGroupItemProps> = ({ group }) => {
  return group.isChannel ? (
    <ChannelItem
      group={group}
      isShowSpaceIcon={false}
    />
  ) : (
    <GroupItem
      group={group}
      isShowSpaceIcon={false}
    />
  );
};
