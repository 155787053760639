import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { PopoverMenu } from 'UIKIT/PopoverMenu/PopoverMenu';

import { useContextMenuPresenter } from './ContextMenu.presenter';

export const ContextMenu = observer(
  ({ message, itemId, popover, placement, modifiers, sharedDataType }) => {
    const { menuItems } = useContextMenuPresenter({
      message,
      itemId,
      popover,
      sharedDataType,
    });

    useEffect(() => {
      if (!menuItems.length) {
        popover.close();
      }
    }, [menuItems]);

    if (!popover.isOpened) {
      return null;
    }

    return (
      <>
        <PopoverMenu
          isOpened={popover.isOpened}
          menuItems={menuItems}
          onClose={popover.close}
          reference={popover.containerRef.current}
          placement={placement}
          modifiers={modifiers}
        />
      </>
    );
  }
);
