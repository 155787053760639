import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useRef } from 'react';

import Tasks from 'APP/Tasks';
import { OnboardingType } from 'APP/constants/onboarding';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import Entities from 'APP/store';
import AttachIcon from 'ICONS/ic-attach.svg';
import PlusIcon from 'ICONS/ic-plus-large.svg';

export default () => {
  const navigateTo = useNavigateTo();
  const attachmentsButtonRef = useRef();
  const source = useAsObservableSource({ navigateTo });

  const presenter = useLocalStore(() => ({
    isMenuOpen: false,

    closeMenu() {
      presenter.isMenuOpen = false;
    },

    toggleMenu() {
      presenter.isMenuOpen = !presenter.isMenuOpen;

      Tasks.app.onboarding.closeOnboarding(OnboardingType.CreateFirstArticle);
    },

    uploadFile(files) {
      if (!files || !files.length) {
        return;
      }

      Tasks.messaging.sendFiles({
        groupId: presenter.group.id,
        navigateTo: source.navigateTo,
        files,
      });
    },

    get Icon() {
      return presenter.group.isChannel ? PlusIcon : AttachIcon;
    },

    get group() {
      return Entities.GroupsStore.activeGroup;
    },

    get isShowOnboarding() {
      return presenter.group.isChannel && presenter.group.withMeInAdmins;
    },
  }));

  return { presenter, attachmentsButtonRef };
};
