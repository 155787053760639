import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { storage } from 'APP/packages/storage';
import { useTranslation } from 'APP/packages/translations';
import Entities from 'APP/store';
import { IStarsFeedbackPresenter } from 'MAIN/Popups/StarsFeedback/StarsFeedback.types';

const MAX_NEGATIVE_RATE = 4;

const MAX_LIVE_TIME = 60000;

export const useStarsFeedbackPresenter = () => {
  const { t } = useTranslation();

  const presenter = useLocalStore<IStarsFeedbackPresenter>(() => ({
    starsArray: [5, 4, 3, 2, 1],
    isDontShowChecked: false,

    get isShown() {
      return Entities.feedback.isStarsShown;
    },

    get channelId() {
      return Entities.feedback.channelId;
    },

    onDontShowChange: (value: boolean) => {
      if (value) {
        Entities.toast.show(t('feedback_form_not_received'));
      }
      presenter.isDontShowChecked = value;
    },

    onClose: () => {
      if (presenter.isDontShowChecked) {
        storage.feedback.setDontShowValue(true);
      }
      Entities.feedback.reset();
    },

    onStarClick: (star: number) => {
      if (!presenter.channelId) {
        return;
      }

      if (presenter.isDontShowChecked) {
        storage.feedback.setDontShowValue(true);
      }

      if (star > MAX_NEGATIVE_RATE) {
        Tasks.feedback.sendFeedback({ rating: String(star), channelId: presenter.channelId });
        Entities.feedback.reset();
      } else {
        Entities.feedback.setStarsRate(star);
        Entities.feedback.setIsDescriptionShown(true);
        Entities.feedback.setIsStarsShown(false);
      }
    },
  }));

  useEffect(() => {
    setTimeout(presenter.onClose, MAX_LIVE_TIME);
  }, []);

  return presenter;
};
