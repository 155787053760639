import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { ProfileNameBadge } from 'APP/components/ProfileNameBadge/ProfileNameBadge';
import type { User } from 'STORE/Users/User/User';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';
import PopupInfoCard from 'UIKIT/PopupInfoCard';
import popupItemStyles from 'UIKIT/PopupItem/styles.m.scss';

import { useContactPresenter } from './Contact.presenter';
import styles from './Contact.styles.m.css';

interface ContactProps {
  contactUser: User;
  closePopup: () => void;
}

export const Contact: FC<ContactProps> = observer((props) => {
  const { contactUser, closePopup } = props;
  const presenter = useContactPresenter(contactUser, closePopup);

  return (
    <button
      className={classNames(popupItemStyles.container, popupItemStyles.interactive)}
      onClick={presenter.onClick}
    >
      <PopupInfoCard
        type={AvatarTypes.Round_10}
        className={classNames(popupItemStyles.card)}
        avatarTitle={presenter.avatarTitle}
        avatarUrl={presenter.avatarUrl}
        avatarBackgroundColorIndex={presenter.avatarBackgroundColorIndex}
        title={presenter.title}
        subtitle={presenter.subtitle}
        badge={
          <ProfileNameBadge
            verified={presenter.verified}
            className={styles.badge}
            source={presenter.userNameBadge?.source}
            animated={presenter.userNameBadge?.animated}
            displayUILayer={0}
          />
        }
      />
    </button>
  );
});
