import classNames from 'classnames';
import React, { FC, memo } from 'react';

import { useTranslation } from 'APP/packages/translations';
import { Link } from 'UIKIT/Link/Link';

import { useSeeMoreTextPresenter } from './SeeMoreText.presenter';
import styles from './SeeMoreText.styles.m.css';

interface ISeeMoreTextProps {
  className?: string;
  text: string;
  maxTextLength: number;
}

export const SeeMoreText: FC<ISeeMoreTextProps> = memo(({ className, text, maxTextLength }) => {
  const { t } = useTranslation();
  const { textToShow, hasShowMore, setHasShowMore } = useSeeMoreTextPresenter(text, maxTextLength);

  return (
    <div className={classNames(styles.content, className)}>
      {textToShow}

      <Link
        className={classNames(styles.link, styles.showMore, {
          [styles.hide]: !hasShowMore,
        })}
        onClick={() => setHasShowMore(false)}
      >
        {t('group_description_more')}
      </Link>
    </div>
  );
});
