import { request } from '../request/request';
import { AddBotToProfileRequest } from '../types/model/addBotToProfileRequest';
import { BotCursorPage } from '../types/model/botCursorPage';
import { BotUserDisplayData } from '../types/model/botUserDisplayData';
import { CursorRequest } from '../types/model/cursorRequest';
import { GroupWithBlockStatusResponse } from '../types/model/groupWithBlockStatusResponse';
import { RemoveBotFromProfileRequest } from '../types/model/removeBotFromProfileRequest';

const ROOT_API = 'botscatalog';

export const getAllBots = (data: CursorRequest): Promise<BotCursorPage> => {
  return request<BotCursorPage>({
    rootApi: ROOT_API,
    url: '/getAllBots',
    method: 'POST',
    body: data,
  });
};

export const addBotToProfile = (
  data: AddBotToProfileRequest
): Promise<GroupWithBlockStatusResponse> => {
  return request<GroupWithBlockStatusResponse>({
    rootApi: ROOT_API,
    url: '/addBotToProfile',
    method: 'POST',
    body: data,
  });
};

export const removeBotFromProfile = (
  data: RemoveBotFromProfileRequest
): Promise<BotUserDisplayData> => {
  return request({
    rootApi: ROOT_API,
    url: '/removeBotFromProfile',
    method: 'POST',
    body: data,
  });
};
