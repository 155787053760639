import { analyticsClient } from '../client/AnalyticsClient';
import { greetingCardAnalyticsEvent } from './greetingCardAnalytics.types';

export function trackOpenCardPage(): void {
  analyticsClient.track({
    eventType: greetingCardAnalyticsEvent.CardOpen,
  });
}

export function trackTapReply(): void {
  analyticsClient.track({
    eventType: greetingCardAnalyticsEvent.TapReply,
  });
}

export function trackTapBanner(): void {
  analyticsClient.track({
    eventType: greetingCardAnalyticsEvent.TapBanner,
  });
}

export function trackCardSent(): void {
  analyticsClient.track({
    eventType: greetingCardAnalyticsEvent.CardSent,
  });
}
