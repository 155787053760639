import Tasks from 'APP/Tasks';

export default async ({ inputPanel }) => {
  try {
    const response = await Tasks.search.searchUsersForMention({
      cursor: inputPanel.mention.cursor,
      filter: inputPanel.mention.filter,
      inputPanel,
    });

    inputPanel.mention.addMentions(response.usersForMention);
    inputPanel.mention.setCursor(response.cursor);
  } catch (error) {
    console.error(error);
    Tasks.search.resetMentions({ inputPanel });
  }
};
