import { observer } from 'mobx-react';
import React from 'react';

import { YouTubePreview } from 'APP/components/YouTubePreview/YouTubePreview';
import { LINK_PREVIEW_MAX_SIZE } from 'APP/constants/newsfeed';

import ImagePreview from './ImagePreview';
import usePresenter from './presenter';
import styles from './styles.m.css';

const LinkPreview = ({ data, messageId }) => {
  const presenter = usePresenter({ data });

  return (
    <div className={styles.container}>
      <p className={styles.domain}>{data.domain}</p>
      <h6 className={styles.title}>{data.title}</h6>
      {data.description && <p className={styles.description}>{data.description}</p>}

      {presenter.isImagePreview && (
        <ImagePreview
          resizedImage={presenter.resizedImage}
          data={data}
        />
      )}

      {presenter.isYouTubeVideo && (
        <YouTubePreview
          id={messageId}
          className={styles.youtube}
          data={data}
          maxWidth={LINK_PREVIEW_MAX_SIZE.width}
          maxHeight={LINK_PREVIEW_MAX_SIZE.height}
        />
      )}
    </div>
  );
};

export default observer(LinkPreview);
