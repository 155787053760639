import { useState } from 'react';

export interface IShareScreenButtonPresenterParams {
  isActive: boolean;
  shareScreen(): Promise<void>;
  stopSharingScreen(): void;
}

interface IShareScreenButtonPresenter {
  isProcessing: boolean;
  onClick(): Promise<void>;
}

export function useShareScreenButtonPresenter({
  isActive,
  shareScreen,
  stopSharingScreen,
}: IShareScreenButtonPresenterParams): IShareScreenButtonPresenter {
  const [isProcessing, setIsProcessing] = useState(false);

  const onClick = async (): Promise<void> => {
    if (isActive) {
      stopSharingScreen();
      return;
    }

    setIsProcessing(true);
    await shareScreen();
    setIsProcessing(false);
  };

  return { isProcessing, onClick };
}
