import { useLocalStore, useAsObservableSource } from 'mobx-react';

import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

interface IPostFooterPresenter {
  isShowReactionButton: boolean;
}

export const usePostFooterPresenter = (message: ChatMessage): IPostFooterPresenter => {
  const source = useAsObservableSource({ message });

  const presenter = useLocalStore<IPostFooterPresenter>(() => ({
    get isShowReactionButton(): boolean {
      const group = source.message.group;
      if (!group) {
        return false;
      }

      return source.message.isAvailableReactions && (group.isOpen || !group.withoutMe);
    },
  }));

  return presenter;
};
