import React, { FC } from 'react';

import { Carousel } from 'APP/components/Carousel/Carousel';
import { VerifiedBadge } from 'APP/components/VerifiedBadge/VerifiedBadge';
import { Avatar } from 'UIKIT/Avatar/Avatar';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';
import { IconView, IconViewSizes } from 'UIKIT/IconView/IconView';

import { ICatalogWidgetComponentProps } from '../CatalogWidget.types';
import styles from './SliderWidget.styles.m.css';

const ITEMS_SHOW_COUNT = 4;
const ITEMS_GAP = 24;

export const SliderWidget: FC<ICatalogWidgetComponentProps> = (props) => {
  const { className, title, items, scrollPosition, onScrollPositionChange } = props;

  if (items.length === 0) {
    return null;
  }

  return (
    <div className={className}>
      {title && <h3 className={styles.title}>{title}</h3>}

      <div className={styles.content}>
        <Carousel
          className={styles.carousel}
          activeIndex={scrollPosition}
          itemsToShow={ITEMS_SHOW_COUNT}
          gap={ITEMS_GAP}
          isInfinite={true}
          onActiveIndexChange={onScrollPositionChange}
        >
          {items.map((item) => (
            <div
              key={item.id}
              role="button"
              tabIndex={0}
              className={styles.item}
              onClick={item.onClick}
            >
              <Avatar
                className={styles.itemAvatar}
                avatarClassName={styles.itemAvatarImage}
                type={AvatarTypes.Square_60}
                title={item.title}
                url={item.avatarUrl}
                backgroundColorIndex={item.avatarColorIndex}
              />

              <h6 className={styles.itemTitleContainer}>
                {item.icon && (
                  <IconView
                    className={styles.itemIcon}
                    size={IconViewSizes.Small}
                    Icon={item.icon}
                  />
                )}
                <span className={styles.itemTitle}>{item.title}</span>
                {item.verified && <VerifiedBadge />}
              </h6>

              <div className={styles.itemSubscribers}>{item.subscribers}</div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};
