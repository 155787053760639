import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import Entities from 'APP/store';
import { Group } from 'STORE/Groups/Group';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { IStreamFormData } from './StreamForm/StreamForm.types';

export interface ICreateStreamPopupPresenterParams {
  groupId: string;
}

interface ICreateStreamPopupPresenter {
  group: Group | null;
  isAvailableToShow: boolean;
  onSubmit(values: IStreamFormData): Promise<void>;
}

export function useCreateStreamPopupPresenter({
  popupInstance,
  params,
}: ICommonPopupsProps<ICreateStreamPopupPresenterParams>): ICreateStreamPopupPresenter {
  const { groupId } = params!;

  const presenter = useLocalStore<ICreateStreamPopupPresenter>(() => ({
    get group(): Group | null {
      return Entities.GroupsStore.getGroupById(groupId);
    },

    get isAvailableToShow(): boolean {
      return !!(presenter.group?.isChannel && presenter.group.withMeInAdmins);
    },

    async onSubmit(values: IStreamFormData): Promise<void> {
      const created = await Tasks.streaming.createStream({
        groupId,
        ...values,
      });

      if (created) {
        popupInstance.close();
      }
    },
  }));

  useEffect(() => {
    if (!presenter.isAvailableToShow) {
      popupInstance.close();
    }
  }, [presenter.group]);

  return presenter;
}
