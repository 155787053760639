import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { useTranslation } from 'APP/packages/translations';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { useClosePopupOnNavigation } from '../../hooks/useClosePopupOnNavigation';
import { ReactionTabs } from './ReactionTabs/ReactionTabs';
import UserList from './UserList';
import usePresenter from './presenter';

interface IReactionsDetailedPopupParams {
  groupId: string;
  messageId: string;
  tab?: string;
}

export const ReactionsDetailedPopup: FC<ICommonPopupsProps<IReactionsDetailedPopupParams>> =
  observer(({ params, popupInstance }) => {
    const { t } = useTranslation();
    const { tabs } = usePresenter(params, popupInstance);
    useClosePopupOnNavigation(popupInstance);

    return (
      <Popup
        title={t('group_chat_actions_sheet_info')}
        isOpened
        onClose={popupInstance.close}
      >
        {tabs.hasTabs && (
          <>
            <ReactionTabs tabs={tabs.tabsList} />
            <UserList tabs={tabs} />
          </>
        )}
      </Popup>
    );
  });
