import * as PopperJS from '@popperjs/core';
import classNames from 'classnames';
import React, { memo, ReactEventHandler } from 'react';
import { Modifier, StrictModifierNames } from 'react-popper';

import { ApplicationTheme } from 'APP/constants/appearance';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';
import { IconView } from 'UIKIT/IconView/IconView';
import { MenuItem } from 'UIKIT/MenuItem/MenuItem';
import { Popover } from 'UIKIT/Popover/Popover';
import { IPopoverMenuItem } from 'UIKIT/PopoverMenu/PopoverMenu.types';

import styles from './PopoverMenu.styles.m.css';

interface IPopoverMenuProps {
  className?: string;
  menuClassName?: string;
  onClose(): void;
  isOpened: boolean;
  menuItems: IPopoverMenuItem[];
  reference: HTMLElement | null;
  placement?: PopperJS.Placement;
  modifiers?: ReadonlyArray<Modifier<StrictModifierNames>>;
  theme?: ApplicationTheme;
}

export const PopoverMenu = memo<IPopoverMenuProps>((props) => {
  const {
    className = '',
    menuClassName,
    onClose,
    isOpened,
    menuItems,
    reference,
    placement,
    modifiers,
    theme,
  } = props;

  const close = (): void => {
    onClose?.();
  };

  const onClick =
    (
      handler: IPopoverMenuItem['onClick'],
      closeByClick = true
    ): ReactEventHandler<HTMLButtonElement> =>
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      if (closeByClick) {
        close();
      }

      if (handler) {
        handler(event);
      }
    };

  return (
    <Popover
      className={classNames(styles.container, customScrollStyles.container, className)}
      isOpened={isOpened}
      onClose={close}
      theme={theme}
      reference={reference}
      placement={placement}
      modifiers={modifiers}
    >
      {menuItems.map((item) => {
        return (
          <MenuItem
            className={menuClassName}
            key={item.key}
            title={item.title}
            onClick={onClick(item.onClick, item.closeByClick)}
            icon={item.customIcon || (item.icon && <IconView Icon={item.icon} />)}
            dataTest={`menu-item-${item.key}`}
            theme={theme}
            style={item.style}
            isDisabled={item.isDisabled}
            count={item.count}
          />
        );
      })}
    </Popover>
  );
});
