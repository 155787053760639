import classNames from 'classnames';
import React from 'react';

import IcCopy from 'ICONS/ic-s-copy.svg';
import { IconButton } from 'UIKIT/IconButton';

import styles from './styles.m.css';

const CopyIpButton = ({ className, onClick }) => {
  return (
    <div className={classNames(styles.container, className)}>
      <IconButton
        Icon={IcCopy}
        onClick={onClick}
      />
    </div>
  );
};

export default CopyIpButton;
