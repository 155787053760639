import { useTranslation } from 'APP/packages/translations';

export default ({ message }) => {
  const { t } = useTranslation();

  const presenter = {
    get isShow() {
      return message.hasTranslation;
    },

    get isShowLabel() {
      return message.isLoadingTranslate || message.isShowTranslate;
    },

    get isShowToggle() {
      return !message.isLoadingTranslate;
    },

    get label() {
      return message.isLoadingTranslate ? t('common_loading') : t('translated_label');
    },

    get toggleCaption() {
      return message.isShowTranslate ? t('show_original_button') : t('show_translation_button');
    },

    onToggleClick() {
      message.toggleTranslate();
    },
  };

  return presenter;
};
