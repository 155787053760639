import { MessageError } from 'STORE/Messages/Message/ChatMessage/ChatMessages.types';

import sendMessage from '../saveScheduledMessage/sendMessage';

const sendMessageHandler = (msg, result) => {
  if (!result && msg) {
    msg.setError(MessageError.Schedule);
  }
};

export default async (msg) => {
  msg.resetError();
  sendMessage(msg.group.groupId, msg, sendMessageHandler.bind(this, msg));
};
