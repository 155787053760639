import { action, observable } from 'mobx';

import { DEFAULT_ACCOUNT_COUNTRY } from 'APP/constants/countryCodes';
import patterns from 'APP/packages/patterns';
import { ICountry } from 'APP/types/countryCodes';

import { version } from '../../../../package.json';
import { IAudioSource, TCloseFullscreenCallback } from './App.types';
import { Popups } from './Popups/Popups';

export class App {
  version = version;
  isBackPage = false;
  @observable isFocused = true;
  @observable uiLayer = 0; // number of open popups
  @observable isOfflineNetworkStatus = false;
  @observable width: number | null = null;
  @observable height: number | null = null;
  @observable popups: Popups = new Popups();
  @observable currentAudioSources: IAudioSource[] = [];
  @observable linkRegex = new RegExp(patterns.AUTOLINK_WEB_URL, 'igu');
  accountCountry = DEFAULT_ACCOUNT_COUNTRY;
  whitelistHosts: string[] = [];
  fullscreenCloseCallback: TCloseFullscreenCallback | null = null;

  setWhitelistHosts(whitelistHosts: string[]): void {
    this.whitelistHosts = whitelistHosts;
  }

  @action reset(): void {
    this.isFocused = true;
    this.isOfflineNetworkStatus = false;
    this.width = null;
    this.height = null;
    this.currentAudioSources = [];
    this.accountCountry = DEFAULT_ACCOUNT_COUNTRY;
    this.popups.reset();
  }

  @action
  setFocused = (isFocused: boolean): void => {
    this.isFocused = isFocused;
  };

  @action
  addLayer = (): void => {
    this.uiLayer = this.uiLayer + 1;
  };

  @action
  removeLayer = (): void => {
    this.uiLayer = Math.max(0, this.uiLayer - 1);
  };

  @action
  setOfflineNetworkStatus = (isOfflineNetworkStatus: boolean): void => {
    this.isOfflineNetworkStatus = isOfflineNetworkStatus;
  };

  @action
  setWidth(width: number | null): void {
    this.width = width;
  }

  @action
  setHeight(height: number | null): void {
    this.height = height;
  }

  @action
  setCurrentAudioSources(sources: IAudioSource[]): void {
    this.currentAudioSources = sources;
  }

  @action
  setLinkRegex = (linkRegex: RegExp): void => {
    this.linkRegex = linkRegex;
  };

  setIsBackPage(isBack: boolean): void {
    this.isBackPage = isBack;
  }

  setAccountCountry(accountCountry: ICountry): void {
    this.accountCountry = accountCountry;
  }
}
