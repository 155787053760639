import classNames from 'classnames';
import React, { FC, memo } from 'react';

import { Carousel } from 'APP/components/Carousel/Carousel';
import { ChatBackgroundPattern } from 'APP/constants/appearance';
import EmptyIcon from 'ICONS/ic-empty.svg';
import { BackgroundPattern } from 'MAIN/components/ChatBackground/BackgroundPattern/BackgroundPattern';
import chatBackgroundStyles from 'MAIN/components/ChatBackground/ChatBackground.styles.m.css';
import { getChatBackgroundClassName } from 'MAIN/components/ChatBackground/hooks/useChatBackgroundClassName';
import { IconView } from 'UIKIT/IconView/IconView';

import styles from './ChatBackgroundSelection.styles.m.css';

const ITEMS_GAP = 8;
const ITEMS_SHOW_COUNT = 4;
const MASK_POSITION = '116px 112px';
const MASK_SIZE = '180%';

interface IChatBackgroundSelectionProps {
  activeChatBackground: ChatBackgroundPattern;
  onChatBackgroundChange(chatBackground: ChatBackgroundPattern): void;
}

export const ChatBackgroundSelection: FC<IChatBackgroundSelectionProps> = memo(
  ({ activeChatBackground, onChatBackgroundChange }) => {
    const patterns = Object.values(ChatBackgroundPattern);

    return (
      <div className={styles.container}>
        <Carousel
          className={styles.carousel}
          itemsToShow={ITEMS_SHOW_COUNT}
          gap={ITEMS_GAP}
          withShadow={true}
        >
          {patterns.map((pattern) => (
            <div
              className={classNames(styles.item, {
                [styles.active]: pattern === activeChatBackground,
              })}
              key={pattern}
              onClick={(): void => onChatBackgroundChange(pattern)}
            >
              <div
                className={classNames(
                  styles.itemWrapper,
                  chatBackgroundStyles[getChatBackgroundClassName(pattern)]
                )}
              >
                {pattern === ChatBackgroundPattern.WithoutPattern && (
                  <div className={styles.withoutPattern}>
                    <IconView
                      className={styles.emptyIcon}
                      Icon={EmptyIcon}
                    />
                  </div>
                )}
                {pattern !== ChatBackgroundPattern.WithoutPattern && (
                  <BackgroundPattern
                    pattern={pattern}
                    maskPosition={MASK_POSITION}
                    maskSize={MASK_SIZE}
                  />
                )}
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    );
  }
);
