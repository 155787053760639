export const BUGSNAG_KEY = '4c86a1716b4d28754a9dc196aafb81c3';

export const ALERT_TYPES = {
  SOMETHING_WRONG_ERROR: 'SOMETHING_WRONG_ERROR',
  COMMON_UPLOAD_BROKEN_FILE: 'COMMON_UPLOAD_BROKEN_FILE',
  SMS_LIMIT_EXCEEDED: 'SMS_LIMIT_EXCEEDED',
  CHAT_IS_NOT_ACCESSIBLE: 'CHAT_IS_NOT_ACCESSIBLE',
  CHANNEL_IS_NOT_ACCESSIBLE: 'CHANNEL_IS_NOT_ACCESSIBLE',
  MESSAGE_HAS_BEEN_DELETED: 'MESSAGE_HAS_BEEN_DELETED',
  YOU_WERE_BANNED: 'YOU_WERE_BANNED',
  BANNED_USER_DEEPLINK_ERROR: 'BANNED_USER_DEEPLINK_ERROR',
  DEEP_LINK_EXPIRED: 'DEEP_LINK_EXPIRED',
  MULTISELECT_LIMIT_EXCEEDED: 'MULTISELECT_LIMIT_EXCEEDED',
  CALL_MAX_MEMBER_AMOUNT: 'CALL_MAX_MEMBER_AMOUNT',
  CALL_CONNECTION_ERROR: 'CALL_CONNECTION_ERROR',
  CALL_LIMIT_EXCEED: 'CALL_LIMIT_EXCEED',
  CALL_JOIN_ON_ANOTHER_PLATFORM: 'CALL_JOIN_ON_ANOTHER_PLATFORM',
  CALL_BROADCASTERS_LIMITATION: 'CALL_BROADCASTERS_LIMITATION',
  CALL_NO_MICROPHONE_CONNECTED: 'CALL_NO_MICROPHONE_CONNECTED',
  CALL_NO_DYNAMIC_CONNECTED: 'CALL_NO_DYNAMIC_CONNECTED',
  CALL_NO_CAMERA_CONNECTED: 'CALL_NO_CAMERA_CONNECTED',
  CALL_NO_MICROPHONE_PERMISSIONS: 'CALL_NO_MICROPHONE_PERMISSIONS',
  CALL_NO_CAMERA_PERMISSIONS: 'CALL_NO_CAMERA_PERMISSIONS',
  CALL_CANNOT_START_SHARING: 'CALL_CANNOT_START_SHARING',
  CALL_JOIN_ANOTHER_CALL: 'CALL_JOIN_ANOTHER_CALL',
  CALL_ERROR_APPEARED: 'CALL_ERROR_APPEARED',
  P2P_CALL_NO_PERMISSION: 'P2P_CALL_NO_PERMISSION',
  P2P_CALL_OPPONENT_BLOCKED_BY_ME: 'P2P_CALL_OPPONENT_BLOCKED_BY_ME',
  P2P_CALL_OPPONENT_BLOCKED_ME: 'P2P_CALL_OPPONENT_BLOCKED_ME',
  NO_PERMISSIONS_TO_PERFORM_ACTION: 'NO_PERMISSIONS_TO_PERFORM_ACTION',
  CHANNEL_IS_NOT_AVAILABLE: 'CHANNEL_IS_NOT_AVAILABLE',
  USER_BANNED_IN_CHANNEL: 'USER_BANNED_IN_CHANNEL',
  DELETED_USER_PROFILE: 'DELETED_USER_PROFILE',
  STICKERS_WERE_DELETED: 'STICKERS_WERE_DELETED',
  STREAMING_SUBSCRIPTION: 'STREAMING_SUBSCRIPTION',
  RECORDING_IS_STARTED_ALREADY: 'RECORDING_IS_STARTED_ALREADY',
  NO_PERMISSIONS_TO_RECORD_A_CALL: 'NO_PERMISSIONS_TO_RECORD_A_CALL',
  SCREEN_SHARING_IS_RUNNING_BY_ANOTHER_USER: 'SCREEN_SHARING_IS_RUNNING_BY_ANOTHER_USER',
  CHANNEL_CLOSED: 'CHANNEL_CLOSED',
  RECORD_CAN_NOT_BE_STOPPED: 'RECORD_CAN_NOT_BE_STOPPED',
  SWITCH_ON_YOUR_CAMERA: 'SWITCH_ON_YOUR_CAMERA',
  INVALID_PHONE_NUMBER: 'INVALID_PHONE_NUMBER',
  APP_VERSION_OUT_OF_DATE: 'APP_VERSION_OUT_OF_DATE',
  YOU_WERE_BANNED_ERROR: 'YOU_WERE_BANNED_ERROR',
  DAILY_SMS_LIMIT_EXCEEDED: 'DAILY_SMS_LIMIT_EXCEEDED',
  ONLY_WEB_PLATFORM_SUPPORTED_MANY_BROADCASTERS: 'ONLY_WEB_PLATFORM_SUPPORTED_MANY_BROADCASTERS',
  CALL_STREAMER_MAX_COUNT: 'CALL_STREAMER_MAX_COUNT',
  CALL_STREAM_MAX_VIDEO_COUNT: 'CALL_STREAM_MAX_VIDEO_COUNT',
  PRIVATE_STICKER_ALERT: 'PRIVATE_STICKER_ALERT',
  ARTICLE_WAS_PUBLISHED_ALERT: 'ARTICLE_WAS_PUBLISHED_ALERT',
  PRIVACY_VOICE_ALERT: 'PRIVACY_VOICE_ALERT',
  TOO_MANY_INVITED_MEMBERS: 'TOO_MANY_INVITED_MEMBERS',
  SPACE_IS_NOT_ACCESSIBLE: 'SPACE_IS_NOT_ACCESSIBLE',
  CALL_NO_PERMISSION: 'CALL_NO_PERMISSION',
  TRY_CALL_AGAIN_LATER: 'TRY_CALL_AGAIN_LATER',
  SPACE_ADMIN_KICK_OWNER: 'SPACE_ADMIN_KICK_OWNER',
  SPACE_ADMIN_OWNERSHIP_ALERT: 'SPACE_ADMIN_OWNERSHIP_ALERT',
  ACCOUNT_UNLINK_ALERT: 'ACCOUNT_UNLINK_ALERT',
  ACCOUNT_ALREADY_LINKED: 'ACCOUNT_ALREADY_LINKED',
  ACCOUNT_LINKED_BY_ANOTHER_GEM_ACCOUNT: 'ACCOUNT_LINKED_BY_ANOTHER_GEM_ACCOUNT',
  ACCOUNT_ALREADY_UNLINKED: 'ACCOUNT_ALREADY_UNLINKED',
  ACCOUNT_ADD_PHONE_UNAVAILABLE_NOW: 'ACCOUNT_ADD_PHONE_UNAVAILABLE_NOW',
  PRIVACY_ADD_TO_GROUP: 'PRIVACY_ADD_TO_GROUP',
  PHONE_HAS_HIGH_RISK_SCORE: 'PHONE_HAS_HIGH_RISK_SCORE',
  ATTEMPTS_EXCEEDED: 'ATTEMPTS_EXCEEDED',
};

export enum AudioSourceType {
  Audio = 'audio',
  Call = 'call',
  IncomingCall = 'incoming-call',
  Video = 'video',
  Voice = 'voice',
  VoiceRecording = 'voice-recording',
  Youtube = 'YouTube',
}

export const BOT_COMMAND_REGEX = /\/([A-Za-z0-9]{1,19}(?=$|[\s,?!.:;"']))/i;
export const SHOW_COMPLETE_DOWNLOAD_TIME = 10; // seconds;
