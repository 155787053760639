export type TEventUnsubscribeHandler = () => void;
export type TEventSubscribeHandler = (...args: any) => void;

const subscriptions: Record<string, Set<TEventSubscribeHandler>> = {};

export function subscribe(
  eventName: string,
  handler: TEventSubscribeHandler
): TEventUnsubscribeHandler {
  if (!subscriptions[eventName]) {
    subscriptions[eventName] = new Set();
  }

  const handlers = subscriptions[eventName];

  handlers.add(handler);

  return () => {
    handlers.delete(handler);

    if (handlers.size === 0) {
      delete subscriptions[eventName];
    }
  };
}

export function broadcast(eventName: string, ...args: any[]): void {
  if (!subscriptions[eventName]) {
    return;
  }

  const handlers = subscriptions[eventName];

  for (const handler of handlers) {
    handler(...args);
  }
}
