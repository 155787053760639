import { observer } from 'mobx-react';
import React from 'react';

import { TextRenderer } from 'APP/components/TextRenderer/TextRenderer';

import LinkPreview from './LinkPreview';

const Text = ({ message }) => {
  const { text, linkPreview } = message.renderPayload;

  if (!text) {
    return null;
  }

  return (
    <>
      <TextRenderer
        userMentions={message.payload.userMentions}
        hasBotCommands={message.payload.message.group.isBot}
        keyWords={message.payload.keyWords}
        groupId={message.payload.message.groupId}
      >
        {text}
      </TextRenderer>
      {linkPreview && (
        <LinkPreview
          data={linkPreview}
          messageId={message.id}
        />
      )}
    </>
  );
};

export default observer(Text);
