import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { useTranslation } from 'APP/packages/translations';
import SearchService from 'SERVICES/search';
import Entities from 'STORE';
import { Group } from 'STORE/Groups/Group';
import { User } from 'STORE/Users/User/User';

import { addCallMembers } from './Tasks/addCallMembers';

interface IContentPresenterProps {
  groupId: string;
  onClose(): void;
}

interface IContentPresenter {
  candidatesIds: string[];
  selectedCandidatesIds: string[];
  searchCandidates: string[];
  isLoadingCandidates: boolean;
  isSubmitting: boolean;
  group: Group;
  buttonText: string;
  isDisabled: boolean;
  candidates: User[];
  searchContacts: User[];
  loadCandidates(): Promise<void>;
  setSelectedIds(ids: string[]): void;
  onSubmit(): Promise<void>;
  setSearchValue(value: string): void;
}

export const useContentPresenter = ({
  onClose,
  groupId,
}: IContentPresenterProps): IContentPresenter => {
  const { t } = useTranslation();
  const presenter = useLocalStore<IContentPresenter>(() => ({
    candidatesIds: [],
    selectedCandidatesIds: [],
    searchCandidates: [],
    isLoadingCandidates: true,
    isSubmitting: false,
    searchContacts: [],

    get group(): Group {
      return Entities.GroupsStore.getGroupById(groupId)!;
    },

    get buttonText(): string {
      if (!presenter.group) {
        return '';
      }

      return presenter.group.isChannel ? t('common_invite') : t('add_button');
    },

    get isDisabled(): boolean {
      return presenter.selectedCandidatesIds.length === 0;
    },

    get candidates(): User[] {
      return presenter.candidatesIds
        .map((id) => Entities.UsersStore.getUserById(id))
        .filter((user) => {
          if (!user || user.isBlocked) {
            return false;
          }

          return true;
        });
    },

    async loadCandidates(): Promise<void> {
      if (!presenter.group) {
        return;
      }

      presenter.candidatesIds = await Tasks.group.getFriendsNotInvitedToGroup({
        groupId: presenter.group.id,
      });

      presenter.isLoadingCandidates = false;
    },

    setSelectedIds: (ids): void => {
      presenter.selectedCandidatesIds = ids;
    },

    onSubmit: async (): Promise<void> => {
      if (!presenter.group) {
        return;
      }

      presenter.isSubmitting = true;

      await addCallMembers(presenter.group.id, presenter.selectedCandidatesIds);

      presenter.isSubmitting = false;
      onClose();
    },

    setSearchValue: (searchValue: string): void => {
      const trimedSearchValue = searchValue.toString().trim().toLowerCase();

      presenter.searchContacts = SearchService.searchInCandidates(
        presenter.candidates,
        trimedSearchValue
      );
    },
  }));

  useEffect(() => {
    presenter.loadCandidates();
  }, []);

  return presenter;
};
