import { observer } from 'mobx-react';
import React, { FC } from 'react';

import Entities from 'APP/store';
import { FullScreenButton } from 'MAIN/OngoingCall/Shared/FullScreenButton/FullScreenButton';
import { PermissionRequestButton } from 'MAIN/OngoingCall/Shared/PermissionRequestsButton/PermissionRequestButton';
import { RecordingPlaceholder } from 'MAIN/OngoingCall/Shared/RecordingPlaceholder/RecordingPlaceholder';
import { ShowMoreButton } from 'MAIN/OngoingCall/Shared/ShowMoreButton/ShowMoreButton';

import styles from './CompactCallViewHeader.styles.m.css';

export const CompactCallViewHeader: FC = observer(({ children }) => {
  const call = Entities.Calls.ongoingCall;

  const canShowPermissionsRequestsButton =
    call?.isMeInitiatorOwnerOrAdmin && call.isGroupCall && !call.isPermissionsRequestsEmpty;

  return (
    <div className={styles.container}>
      {children}
      {Entities.OngoingCall.call.isRecording && <RecordingPlaceholder />}
      {canShowPermissionsRequestsButton && <PermissionRequestButton className={styles.button} />}
      {!call?.isGuestCall && <FullScreenButton className={styles.button} />}
      <ShowMoreButton className={styles.button} />
    </div>
  );
});
