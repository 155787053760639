import { spaceModel } from 'APP/model/model';
import { ICreateSpace } from 'APP/model/space/spaceModel.types';
import { spaceAnalytics } from 'APP/packages/analytics/analytics';
import Entities from 'STORE';
import { Space } from 'STORE/Spaces/Space';

export async function createSpace(data: ICreateSpace): Promise<Space | null> {
  try {
    const spaceResponse = await spaceModel.createSpace(data);

    spaceAnalytics.trackSpaceNewCreated(spaceResponse.space.type);

    Entities.spacesStore.addSpace(spaceResponse);
    return Entities.spacesStore.getById(spaceResponse.space.id);
  } catch {
    return null;
  }
}
