import Entities from 'APP/store';

import getContentPositions from '../../utils/getContentPositions';
import isLastDot from './isLastDot';
import isValidDot from './isValidDot';

export default (text, index, symbolMatches) => {
  if (!isValidDot(text, index)) {
    return;
  }

  if (!isLastDot(text, index)) {
    return;
  }

  const { startPosition, content } = getContentPositions(text, index);

  const matches = content.matchAll(Entities.App.linkRegex);

  for (const link of matches) {
    symbolMatches['link'].push({
      start: startPosition + link.index,
      end: startPosition + link.index + link[0].length,
      link: link[0],
    });
  }
};
