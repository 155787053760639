/**
 * Rest API
 * Documentation Rest API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DeviceInfo { 
    device?: string;
    platform: DeviceInfoPlatformEnum;
    osVersion: string;
    vendorId?: string;
    advertiserId?: string;
    /**
     * Application version, need for getting client configs
     */
    appVersion: string;
    uniqueDeviceId: string;
    ipAddress?: string;
    /**
     * User locale ISO 639-1
     */
    locale?: string;
    androidSdk?: number;
}
export enum DeviceInfoPlatformEnum {
    Android = 'android',
    Huawei = 'huawei',
    Web = 'web',
    Ios = 'ios',
    Safari = 'safari',
    Desktop = 'desktop'
};



