import { useLocalStore } from 'mobx-react';
import { useEffect, ChangeEvent, KeyboardEvent } from 'react';

import { ENTER_KEY_CODE } from 'APP/constants/keyCodes';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';

export interface IKeywordFiltersPopupParams {
  loadBlackList(): Promise<string[]>;
  onChange(list: string[]): void;
}

interface IKeywordFiltersPopupPresenter {
  values: Set<string>;
  addValue: string;
  isLoading: boolean;
  hasBackButton: boolean;
  canAddValue: boolean;
  list: string[];
  init(): Promise<void>;
  onAddValueChange(event: ChangeEvent<HTMLInputElement>): void;
  onAddValueKeyDown(event: KeyboardEvent<HTMLInputElement>): void;
  onAddValue(): void;
  onDeleteValue(value: string): void;
  onClose(): void;
  onBack(): void;
}

export function useKeywordFiltersPopupPresenter({
  popupInstance,
  onClose,
  onBack,
  params,
}: ICommonPopupsProps<IKeywordFiltersPopupParams>): IKeywordFiltersPopupPresenter {
  const { loadBlackList, onChange } = params!;

  const presenter = useLocalStore(() => ({
    values: new Set<string>(),
    addValue: '',
    isLoading: true,

    get hasBackButton(): boolean {
      return !!onBack;
    },

    get canAddValue(): boolean {
      return presenter.addValue.trim().length > 0;
    },

    get list(): string[] {
      return [...presenter.values];
    },

    async init(): Promise<void> {
      presenter.isLoading = true;
      const list = await loadBlackList();
      presenter.values = new Set(list);
      presenter.isLoading = false;
    },

    onAddValueChange(event: ChangeEvent<HTMLInputElement>): void {
      presenter.addValue = event.target.value;
    },

    onAddValueKeyDown(event: KeyboardEvent<HTMLInputElement>): void {
      if (event.keyCode === ENTER_KEY_CODE) {
        presenter.onAddValue();
      }
    },

    onAddValue(): void {
      if (!presenter.canAddValue) {
        return;
      }

      const values = presenter.addValue
        .split(',')
        .map((val) => val.trim())
        .filter((val) => val.length > 0);

      presenter.addValue = '';
      presenter.values = new Set([...values, ...presenter.values]);
      onChange(presenter.list);
    },

    onDeleteValue(value: string): void {
      presenter.values.delete(value);
      onChange(presenter.list);
    },

    onClose(): void {
      popupInstance.close();
      onClose?.();
    },

    onBack(): void {
      popupInstance.close();
      onBack?.();
    },
  }));

  useEffect(() => {
    presenter.init();
  }, []);

  return presenter;
}
