import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { StickerList } from './StickerList/StickerList';
import { StickerPackList } from './StickerPackList/StickerPackList';
import { useStickerPanelPresenter } from './StickerPanel.presenter';

interface IStickerPanelProps {
  closePopover: () => void;
}
export const StickerPanel: FC<IStickerPanelProps> = observer(({ closePopover }) => {
  const presenter = useStickerPanelPresenter(closePopover);

  return (
    <>
      <StickerList
        activePack={presenter.activePack}
        onStickerClick={presenter.sendSticker}
      />
      <StickerPackList
        packs={presenter.packs}
        checkIsActivePack={presenter.checkIsActivePack}
        onStickerPackClick={presenter.setActivePack}
        onSettingsClick={presenter.openSettings}
      />
    </>
  );
});
