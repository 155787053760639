import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { AudioPlayer } from 'APP/components/AudioPlayer/AudioPlayer';
import { AudioComponent } from 'APP/constants/audio';
import { ISharedDataItem } from 'APP/model/sharedData/sharedDataModel.types';
import VoicePayload from 'STORE/Messages/Message/Payload/Voice';

import { useVoiceMessageItemPresenter } from './VoiceMessageItem.presenter';

export const VoiceMessageItem: FC<ISharedDataItem<VoicePayload>> = observer((props) => {
  const { id: payloadId, payload, groupId, messageId, serverTime } = props;
  const presenter = useVoiceMessageItemPresenter({ groupId, messageId });

  if (!presenter.message) {
    return null;
  }

  return (
    <AudioPlayer
      fileName={presenter.title}
      serverTime={Number(serverTime)}
      duration={Number(payload.duration)}
      size={payload.fileSize}
      componentName={AudioComponent.SharedVoice}
      messageId={messageId}
      groupId={groupId}
      payloadId={payloadId}
      clientUuid={payload.message.clientUuid}
      uploadProgress={null}
    />
  );
});
