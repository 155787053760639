import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, Key, ReactElement, ReactNode } from 'react';

import { List } from 'APP/components/List/List';
import { SharedDataType } from 'APP/constants/sharedData';
import { ISharedDataItem } from 'APP/model/sharedData/sharedDataModel.types';
import FilePayload from 'STORE/Messages/Message/Payload/File';
import { Loader } from 'UIKIT/Loader/Loader';

import { useSharedDataPresenter } from '../SharedData.presenter';
import styles from '../SharedData.styles.m.css';
import { EmptyContentStub } from '../components/EmptyContentStub/EmptyContentStub';
import { GroupByDateItems } from '../components/GroupByDateItems';
import { FileItem } from './FileItem/FileItem';

interface IFilesProps {
  className?: string;
  groupId: string;
}

export const SharedDataFiles: FC<IFilesProps> = observer(({ className, groupId }) => {
  const presenter = useSharedDataPresenter<FilePayload>({
    groupId,
    type: SharedDataType.Files,
  });

  return (
    <List
      isLoading={presenter.isLoading}
      className={classNames(styles.container, className)}
      data={presenter.dateList}
      hasMore={presenter.hasMore}
      onEndReached={presenter.loadMore}
      keyExtractor={(date: number): Key => date}
      renderItem={(date: number): ReactElement => (
        <GroupByDateItems
          key={date}
          title={date}
          data={presenter.groupFilesByDate[date]}
          groupId={groupId}
          sharedDataType={SharedDataType.Files}
          renderItem={(item: ISharedDataItem<FilePayload>): ReactNode => (
            <FileItem
              key={`${item.id}_${item.messageId}`}
              {...item}
            />
          )}
        />
      )}
      SpinnerComponent={
        <div className={styles.loader}>
          <Loader color={'base'} />
        </div>
      }
      ListEmptyComponent={<EmptyContentStub />}
    />
  );
});
