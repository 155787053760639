import { computed } from 'mobx';

import Base from 'STORE/base';

export class Forward extends Base {
  from;
  senderId;
  // could be negative if it's not from channel
  groupId;
  messageId;

  // needs for using in negative scenarios (for example, re-forward )
  clientGroupSourceId;
  message;

  constructor(data) {
    super(data);
    this.message = data.message;

    this.from = data.from || '';
    this.senderId = data.senderId ? data.senderId.toString() : null;
    this.groupId = data.groupId ? data.groupId.toString() : null;
    this.messageId = data.messageId ? data.messageId.toString() : null;
    this.clientGroupSourceId = data.clientGroupSourceId
      ? data.clientGroupSourceId.toString()
      : null;
  }

  toJSON() {
    return {
      from: this.from,
      senderId: this.senderId,
      groupId: this.groupId,
      messageId: this.messageId,
    };
  }

  @computed
  get senderUser() {
    const user = this.root.UsersStore.getUserById(this.senderId);
    if (user) {
      return user;
    } else {
      return {
        avatarUrl: null,
        avatarTitle: this.from,
        avatarColorIndex: this.senderId.slice(-1),
      };
    }
  }

  @computed
  get groupFrom() {
    return this.root.GroupsStore.getGroupById(this.groupId);
  }

  @computed
  get isFromChannel() {
    return Boolean(this.groupId);
  }

  @computed
  get avatarTitle() {
    if (this.isFromChannel) {
      return this.from;
    }
    return this.senderUser?.avatarTitle || this.from;
  }

  @computed
  get avatarColorIndex() {
    if (this.isFromChannel) {
      return this.groupId.slice(-1);
    } else if (this.senderId) {
      return this.senderId.slice(-1);
    }

    return '0';
  }
}
