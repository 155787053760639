import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { CSSTransition } from 'react-transition-group';

import { useAnimatedBackgroundPanelPresenter } from './AnimatedBackgroundPanel.presenter';
import styles from './AnimatedBackgroundPanel.styles.m.css';
import transitions from './AnimatedBackgroundPanel.transitions.m.css';
import { BackgroundPanel } from './components/BackgroundPanel/BackgroundPanel';

const MemberListCSSTransitionClasses = {
  enter: transitions.memberListEnter,
  enterActive: transitions.memberListEnterActive,
  exit: transitions.memberListExit,
  exitActive: transitions.memberListExitActive,
};

interface IAnimatedBackgroundPanelProps {
  isCompact?: boolean;
}

export const AnimatedBackgroundPanel: FC<IAnimatedBackgroundPanelProps> = observer((props) => {
  const presenter = useAnimatedBackgroundPanelPresenter();

  return (
    <CSSTransition
      in={presenter.isPanelShown}
      classNames={MemberListCSSTransitionClasses}
      timeout={100}
      mountOnEnter
      unmountOnExit
    >
      <div
        className={classNames(styles.container, {
          [styles.fixed]: props.isCompact,
        })}
      >
        <BackgroundPanel isCompact={props.isCompact} />
      </div>
    </CSSTransition>
  );
});
