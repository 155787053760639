import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { UnauthorizedCallType } from 'APP/model/unauthorizedCall/unauthorizedCallModel.constants';
import { useTranslation } from 'APP/packages/translations';
import { RouterLink } from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import { CallDescription } from 'APP/unauthorized/CallPreview/CallDescription/CallDescription';
import { Button } from 'UIKIT/Button/Button';

import styles from './StartCall.styles.m.css';

interface IStartCallProps {
  isGroupCall: boolean;
  isLoading: boolean;
  onStart(): void;
}

export const StartCall: React.FC<IStartCallProps> = ({ onStart, isGroupCall, isLoading }) => {
  const { t } = useTranslation();
  const navigateTo = useNavigateTo();
  const params = useParams<{ id: string; callType: UnauthorizedCallType; channelId?: string }>();

  const onCancel = useCallback(() => {
    navigateTo({
      to: isGroupCall ? RouterLink.callInvitationGroup : RouterLink.callInvitation,
      params: { id: params.id, callType: params.callType, channelId: params.channelId },
    });
  }, [navigateTo, params.id]);

  return (
    <div className={styles.container}>
      <CallDescription
        isGroupCall={isGroupCall}
        className={styles.description}
      />
      <Button
        isLoading={isLoading}
        onClick={onStart}
        className={styles.joinButton}
        size="20"
        theme="1"
      >
        {t('calls_join')}
      </Button>
      <Button
        onClick={onCancel}
        className={styles.cancelButton}
        size="20"
        theme="2"
      >
        {t('cancel')}
      </Button>
    </div>
  );
};
