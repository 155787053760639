import { observer } from 'mobx-react';
import React from 'react';

import { useTranslation } from 'APP/packages/translations';
import { MemberState } from 'STORE/Calls/Call/Member/MemberState';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';
import PopupInfoCard from 'UIKIT/PopupInfoCard';
import popupItemStyles from 'UIKIT/PopupItem/styles.m.scss';

import { usePresenter } from './presenter';

const stateTranslations = {
  [MemberState.NotOnCall]: 'calls_not_on_call',
  [MemberState.OnCall]: 'calls_on_call',
  [MemberState.Declined]: 'calls_declined_call',
  [MemberState.Left]: 'calls_left',
};

export const CallMemberComponent = ({ userId, onClick, className, children }) => {
  const { t } = useTranslation();
  const presenter = usePresenter(userId);

  const additionalProps = onClick ? { role: 'button', tabIndex: '0', onClick } : {};

  if (!presenter.member) {
    return null;
  }

  return (
    <div
      {...additionalProps}
      className={className}
    >
      <PopupInfoCard
        type={AvatarTypes.Round_10}
        className={popupItemStyles.card}
        avatarTitle={presenter.member.displayName}
        avatarUrl={presenter.member.avatarUrl}
        avatarBackgroundColorIndex={presenter.member.avatarColorIndex}
        title={presenter.member.displayName}
        subtitle={t(stateTranslations[presenter.member.state])}
      />
      {children}
    </div>
  );
};

export const CallMember = observer(CallMemberComponent);
