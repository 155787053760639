import Tasks from 'APP/Tasks';
import { showToast } from 'APP/Tasks/app/showToast/showToast';
import { PayloadType } from 'APP/model/message/messageModel.types';
import { navigateTo } from 'APP/router/refs/navigateTo';
import Entities from 'STORE';
import { Schedule } from 'STORE/Groups/Schedule';

export function sentLocationMessage(
  groupId: string,
  location: {
    latitude: number | null;
    longitude: number | null;
  }
): void {
  const group = Entities.GroupsStore.getGroupById(groupId);

  if (!group) {
    return;
  }

  const mustBeScheduled = group instanceof Schedule;

  const canSend = Entities.InputPanel.payloads.checkGroupPermissions(!mustBeScheduled);

  if (!canSend.result) {
    canSend.error && showToast(canSend.error);
    return;
  }

  const messageData = {
    type: PayloadType.Location,
    data: location,
    groupId: group.id,
    quotedMessage: group.inputPanel.quoteMessage,
  };

  Entities.InputPanel.payloads.setPayloads([messageData]);

  if (mustBeScheduled) {
    Tasks.group.goToScheduleSettings({ navigateTo });
  } else {
    Entities.InputPanel.send.submit();
  }
}
