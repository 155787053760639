import { EventWorker } from 'SERVICES/EventWorker';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import RLottieWorker from '../rLottie.worker';

export const MAX_WORKERS = Math.min(navigator.hardwareConcurrency || 4, 4);

let instances: EventWorker[];

export const launchMediaWorkers = (): EventWorker[] => {
  if (!instances) {
    instances = new Array(MAX_WORKERS).fill(undefined).map(() => {
      const worker = new RLottieWorker();
      return new EventWorker(worker);
    });
  }

  return instances;
};
