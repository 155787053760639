import { RouterLink } from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';

export default ({ newsItem }) => {
  const navigateTo = useNavigateTo();

  const presenter = {
    get isView() {
      return newsItem.channels?.length > 0;
    },

    get channels() {
      return newsItem.channels || [];
    },

    onSeeAll() {
      navigateTo({ to: RouterLink.catalog });
    },
  };

  return presenter;
};
