import api from 'APP/packages/api';
import logger from 'APP/packages/logger';

export default async ({ groupId, previewImageUrl = null, title = null }) => {
  try {
    const response = await api.streams.startStream({
      groupId,
      previewImageUrl,
      title,
    });

    return response;
  } catch (e) {
    logger.get('API').error('streams.startStream', e);
    return null;
  }
};
