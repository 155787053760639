import { action, observable } from 'mobx';

import { PayloadType } from 'APP/model/message/messageModel.types';
import { t } from 'MODULE/i18n/service';
import { MessageError } from 'STORE/Messages/Message/ChatMessage/ChatMessages.types';
import { Payload } from 'STORE/Messages/Message/Payload';

import Image from '../Image';
import Video from '../Video';

const PayloadClass = {
  Image,
  Video,
};

class Album extends Payload {
  @observable payloads;
  @observable comment;

  constructor(data) {
    super(data);
    this.payloads = data.payloads
      .sort((a, b) => a.order - b.order)
      .map(({ id, order, payloadType, payload }) => ({
        id,
        order,
        payloadType,
        payload: new PayloadClass[payload.payloadType]({
          ...payload,
          message: {
            delete: () => this.delete(payload.userFile),
            forward: null,
            quotedMessage: null,
            hasError: false,
            setError() {
              this.hasError = true;
            },
          },
        }),
      }));
    this.comment = data.comment;
    this.keyWord = data.keyWord;
    this.keyWords = data.keyWord ? [data.keyWord] : [];
    this.userMentions = data.userMentions;
  }

  toJSON() {
    return {
      payloadType: PayloadType.Album,
      comment: this.comment || null,
      userMentions: this.userMentions || null,
      keyWord: this.keyWord || null,

      payloads: this.payloads.map((payload, idx) => ({
        id: payload.id,
        payloadType: payload.payloadType,
        order: idx,
        payload: payload.payload.toJSON(),
      })),
    };
  }

  get text() {
    return t('message_desctription_album');
  }

  get typeDescription() {
    return t('message_desctription_album');
  }

  @action
  delete = async (file) => {
    const filteredPayloads = this.payloads.filter(({ payload }) => payload.userFile !== file);
    if (filteredPayloads.length === 0) {
      const res = await this.message.delete();

      if (res) {
        this.payloads = filteredPayloads;
      }
    } else {
      this.payloads = filteredPayloads;
    }

    if (this.message.canBeResend) {
      this.message.resend();
    }
  };

  @action
  upload = async () => {
    await Promise.allSettled(this.payloads.map(({ payload }) => payload.upload()));
    const hasError =
      this.payloads.length === 0 || this.payloads.some(({ payload }) => !payload.isLoaded);
    if (hasError) {
      this.message.setError(MessageError.Send);
    }
  };
}

export default Album;
