import { observer } from 'mobx-react';
import React from 'react';

import { Portal } from 'UIKIT/Portal/Portal';

import FullScreenView from './FullScreenView';
import MinimizeView from './MinimizeView';
import usePresenter from './presenter';

const InitiatorView = () => {
  const { presenter, streamerPresenter } = usePresenter();

  if (presenter.isFullScreen) {
    return (
      <FullScreenView
        presenter={presenter}
        streamerPresenter={streamerPresenter}
        isFinished={presenter.isStreamFinished}
      />
    );
  } else {
    return (
      <Portal>
        <MinimizeView
          presenter={presenter}
          streamerPresenter={streamerPresenter}
          isFinished={presenter.isStreamFinished}
        />
      </Portal>
    );
  }
};

export default observer(InitiatorView);
