import { FormState } from 'final-form';
import { useLocalStore } from 'mobx-react';

import { RecordingType } from 'APP/constants/calls';
import { useTranslation } from 'APP/packages/translations';
import Entities from 'STORE';
import { Group } from 'STORE/Groups/Group';

export interface INewRecordingFormValues {
  title: string;
  recordingType: RecordingType;
}

interface INewRecordingFormOption {
  label: string;
  value: RecordingType;
}

export interface INewRecordingParams {
  onSuccess(data: INewRecordingFormValues): void;
}

interface INewRecordingPresenter {
  isValid: boolean;
  pristine: boolean;
  initialValues: INewRecordingFormValues;
  recordingOptions: INewRecordingFormOption[];
  group: Group | null;
  checkValid(data: FormState<INewRecordingFormValues>): void;
  onSubmit(values: INewRecordingFormValues): void;
}

export const useNewRecordingPresenter = (data: INewRecordingParams): INewRecordingPresenter => {
  const { onSuccess } = data;
  const { t } = useTranslation();

  const presenter = useLocalStore<INewRecordingPresenter>(() => ({
    isValid: false,
    pristine: false,

    initialValues: {
      title: '',
      recordingType: RecordingType.AudioVideo,
    },

    get recordingOptions(): INewRecordingFormOption[] {
      return [
        {
          value: RecordingType.AudioVideo,
          label: t('calls_recording_video_and_audio'),
        },
        {
          value: RecordingType.Audio,
          label: t('calls_recording_only_audio'),
        },
      ];
    },

    get group(): Group | null {
      return Entities.OngoingCall.call?.group || null;
    },

    checkValid(data: FormState<INewRecordingFormValues>): void {
      presenter.pristine = data.pristine;
      presenter.isValid = !data.invalid && !data.validating;
    },

    onSubmit(values: INewRecordingFormValues): void {
      if (!presenter.group?.withMeInAdmins) {
        if (
          values.recordingType === RecordingType.Audio &&
          !presenter.group?.memberCustomPermissions?.supportedMediaTypes?.canSendAudio
        ) {
          Entities.toast.show(t('moderation_content_limit_audio'));
          return;
        }

        if (
          values.recordingType === RecordingType.AudioVideo &&
          !presenter.group?.memberCustomPermissions?.supportedMediaTypes?.canSendVideo
        ) {
          Entities.toast.show(t('moderation_content_limit_video'));
          return;
        }
      }

      onSuccess({
        recordingType: values.recordingType,
        title: values.title.trim(),
      });
    },
  }));

  return presenter;
};
