import classNames from 'classnames';
import React, { useEffect, useRef, FC } from 'react';

import { ICallProviderPublisher } from 'APP/packages/callProviders/callProviders.types';

import styles from './BackgroundPanelVideo.styles.m.css';

export interface IBackgroundPanelVideoProps {
  className?: string;
  publisher: ICallProviderPublisher;
}

export const BackgroundPanelVideo: FC<IBackgroundPanelVideoProps> = ({ className, publisher }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (publisher.hasVideo && containerRef.current) {
      publisher.playVideo(containerRef.current);
    }
    return () => {
      publisher.stopVideo();
    };
  }, []);

  return (
    <div
      className={classNames(styles.container, className)}
      ref={containerRef}
    />
  );
};
