export const clearGetParameters = ({ location, fields = [] }) => {
  if (fields.length === 0) {
    return location.pathname;
  }

  const query = new URLSearchParams(location.search);

  fields.forEach((field) => {
    if (Array.isArray(field)) {
      const values = query.getAll(field[0]);
      query.delete(field[0]);
      values.forEach((value) => {
        if (value !== field[1]) {
          query.append(field[0], value);
        }
      });
    } else {
      query.delete(field);
    }
  });

  return query.toString() ? `${location.pathname}?${query.toString()}` : location.pathname;
};
