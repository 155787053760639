import { observer } from 'mobx-react';
import React from 'react';

import styles from './styles.m.css';

const ButtonsSelected = ({ message }) => {
  const { text } = message.payload;
  return <span className={styles.text}>{text}</span>;
};

export default observer(ButtonsSelected);
