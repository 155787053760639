import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import { loadUsers } from '../Tasks/loadUsers';

export const useNewUsersViewPresenter = ({ usersIds }) => {
  const presenter = useLocalStore(() => ({
    isLoading: true,

    get placeholdersCount() {
      return usersIds.length <= 10 ? usersIds.length : 10;
    },

    async loadUsersData() {
      presenter.isLoading = true;

      await loadUsers(usersIds);

      presenter.isLoading = false;
    },

    keyExtractor(userId) {
      return userId;
    },

    onSearch(value) {
      presenter.searchString = value;
    },
  }));

  useEffect(() => {
    presenter.loadUsersData();
  }, []);

  return presenter;
};
