import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { GroupAvatar } from 'APP/components/Avatar/GroupAvatar/GroupAvatar';
import { VerifiedBadge } from 'APP/components/VerifiedBadge/VerifiedBadge';
import AddIcon from 'ICONS/ic-ad.svg';
import { useContextMenu } from 'MAIN/hooks/useContextMenu/useContextMenu';
import { Group } from 'STORE/Groups/Group';
import { IconButton } from 'UIKIT/IconButton';
import { PopoverMenu } from 'UIKIT/PopoverMenu/PopoverMenu';
import { IPopoverMenuItem } from 'UIKIT/PopoverMenu/PopoverMenu.types';

import { useGroupAvailableItemPresenter } from './GroupAvailableItem.presenter';
import styles from './GroupAvailableItem.styles.m.css';

interface IGroupAvailableItemProps {
  group: Group;
  menuItems?: IPopoverMenuItem[];
  onJoin(): Promise<void>;
  onClick(): void;
}

export const GroupAvailableItem: FC<IGroupAvailableItemProps> = observer((props) => {
  const { group, menuItems, onJoin, onClick } = props;

  const { popover, onContextMenu, modifiers } = useContextMenu<HTMLDivElement>();
  const { presenter, joinButtonRef } = useGroupAvailableItemPresenter({
    group,
    menuItems,
    onJoin,
    onClick,
  });

  const groupContainerClassName = classNames(styles.container, {
    [styles.active]: presenter.isActive,
  });

  return (
    <>
      <div
        className={groupContainerClassName}
        ref={popover.containerRef}
        role="button"
        tabIndex={0}
        data-test="group-link"
        onClick={presenter.onClick}
        onContextMenu={presenter.hasContextMenu ? onContextMenu : undefined}
      >
        <GroupAvatar
          group={group}
          type={presenter.avatarType}
          hasLockIcon={group.isClosed}
        />

        <div className={styles.content}>
          <div className={styles.titleWrapper}>
            <h6 className={styles.title}>{group.avatarTitle}</h6>
            {presenter.verified && <VerifiedBadge />}
          </div>
          <div className={styles.subTitle}>{presenter.subTitle}</div>
        </div>

        <IconButton
          setRef={joinButtonRef}
          Icon={AddIcon}
          theme="1"
          loaderColor="current"
          isLoading={presenter.isLoadingJoin}
          onClick={presenter.onJoin}
        />
      </div>

      {presenter.hasContextMenu && (
        <PopoverMenu
          reference={popover.containerRef.current}
          menuItems={presenter.menuItems}
          isOpened={popover.isOpened}
          placement="bottom-start"
          modifiers={modifiers}
          onClose={popover.close}
        />
      )}
    </>
  );
});
