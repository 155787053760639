import Tasks from 'APP/Tasks';
import Entities from 'STORE';

export const checkAndRunAwaitCall = async (): Promise<void> => {
  for (const call of Entities.BackgroundCalls.data.values()) {
    const group = Entities.GroupsStore.getGroupById(call.groupId);

    if (group?.isAwaitCall) {
      await Tasks.calls.showConfirmRunCall({
        call,
        groupId: group.id,
      });
    }
  }

  Entities.GroupsStore.groups.forEach((group) => {
    group.setIsAwaitCall(false);
  });
};
