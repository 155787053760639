import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { LargeCallAvatar } from 'MAIN/OngoingCall/Shared/Avatars/LargeCallAvatar/LargeCallAvatar';
import { CallActionsPanel } from 'MAIN/OngoingCall/Shared/CallActionsPanel/CallActionsPanel';
import { MemberList } from 'MAIN/OngoingCall/Shared/MemberList/MemberList';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';

import { CallingPlaceholder } from '../Shared/CallingPlaceholder/CallingPlaceholder';
import { CompactCallViewHeader } from '../Shared/CompactCallViewHeader/CompactCallViewHeader';
import { AddPeopleLink } from './AddPeopleLink/AddPeopleLink';
import { useGroupAudioCallPresenter } from './GroupAudioCall.presenter';
import styles from './GroupAudioCall.styles.m.css';

export const GroupAudioCall = observer(() => {
  const presenter = useGroupAudioCallPresenter();

  return (
    <div className={styles.container}>
      <CompactCallViewHeader />
      <div className={styles.avatarContainer}>
        <LargeCallAvatar avatar={presenter.call.avatar} />
      </div>
      <CallingPlaceholder call={presenter.call} />
      <div className={styles.membersQuantity}>{presenter.membersQuantityText}</div>
      <div className={classNames(styles.memberListContainer, customScrollStyles.container)}>
        {presenter.call.groupId && presenter.call.isMeInitiator && (
          <AddPeopleLink groupId={presenter.call.groupId} />
        )}
        <MemberList />
      </div>
      <CallActionsPanel className={styles.actionsPanel} />
    </div>
  );
});
