import patterns from 'APP/packages/patterns';
import Entities from 'APP/store';

const globalEmailRegex = new RegExp(patterns.EMAIL_ADDRESS, 'g');
const mentionRegex = new RegExp(patterns.MENTION, 'ig');
const mentionWrappedRegex = new RegExp(patterns.WRAPPED_MENTION, 'ig');
const escapeRegex = patterns.ESCAPE_BLOCK;

const searchLinksInString = (text: string): string[] => {
  const linkRegex = Entities.App.linkRegex;

  let updatedValue = text.replace(globalEmailRegex, '');
  updatedValue = updatedValue.replace(mentionRegex, '');
  updatedValue = updatedValue.replace(mentionWrappedRegex, '');
  updatedValue = updatedValue.replace(escapeRegex, '');

  const linkMatch = updatedValue.match(linkRegex);
  if (!linkMatch || linkMatch.length === 0) {
    return [];
  }

  return linkMatch.input ? [linkMatch[0]] : linkMatch;
};

export function searchLinks(text: string): string[] {
  if (!text) {
    return [];
  }

  return searchLinksInString(text);
}
