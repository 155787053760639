import { useLocalStore } from 'mobx-react';

import Entities from 'APP/store';
import { Group } from 'STORE/Groups/Group';
import { P2P } from 'STORE/Groups/P2P';
import { Thread } from 'STORE/Groups/Thread';

interface IChatFooterPresenter {
  group: Group | null;
  isBlockedByMe: boolean;
  isBlockedMe: boolean;
  isShowDisabledMessages: boolean;
}

export function useChatFooterPresenter(): IChatFooterPresenter {
  const presenter = useLocalStore<IChatFooterPresenter>(() => ({
    get group(): Group | null {
      return Entities.GroupsStore.activeGroup;
    },

    get isBlockedByMe(): boolean {
      return presenter.group instanceof P2P && presenter.group.isBlockedByMe;
    },

    get isBlockedMe(): boolean {
      return (
        presenter.group instanceof P2P &&
        presenter.group.isBlockedMe &&
        !presenter.group.isBlockedByMe
      );
    },

    get isShowDisabledMessages(): boolean {
      const group = presenter.group?.parentGroup || presenter.group;

      if (group?.withoutMe && !(presenter.group instanceof Thread)) {
        return false;
      }

      return !group?.canSendMessages;
    },
  }));

  return presenter;
}
