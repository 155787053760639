export default ({ text, positionToTag, missedTags }) => {
  const additionalTagsPositions = Object.keys(missedTags);

  if (additionalTagsPositions.length === 0) {
    return {
      updatedText: text,
      updatedPositionToTag: positionToTag,
    };
  }

  const tagsPositions = Object.keys(positionToTag);
  const updatedPositionToTag = {};

  let shift = 0;
  let updatedText = '';
  let previousPosition = 0;

  for (let i = 0; i < additionalTagsPositions.length; i++) {
    const additionalTagsPosition = parseInt(additionalTagsPositions[i]);
    const tagsData = missedTags[additionalTagsPosition];

    // update positionToTag
    for (let j = 0; j < tagsPositions.length; j++) {
      const position = parseInt(tagsPositions[j]);
      if (previousPosition > position) {
        continue;
      }

      if (position >= additionalTagsPosition) {
        break;
      }

      const tagData = positionToTag[position];

      updatedPositionToTag[position + shift] = {
        ...tagData,
        start: tagData.start + shift,
        end: tagData.end + shift,
      };
    }

    // update text
    updatedText += text.slice(previousPosition, additionalTagsPosition);

    for (let j = 0; j < tagsData.length; j++) {
      const tagData = tagsData[j];

      updatedText += tagData.tag;
    }

    let tagsShift = 0;
    for (let j = 0; j < tagsData.length; j++) {
      const tagData = tagsData[j];
      updatedPositionToTag[additionalTagsPosition + shift + tagsShift] = {
        ...tagData,
        start: tagData.start + shift + tagsShift,
        end: tagData.end + shift + tagsShift,
      };
      tagsShift += tagData.tag.length;
    }

    shift += tagsShift;
    previousPosition = additionalTagsPosition;
  }

  // add remaining tags
  for (let j = 0; j < tagsPositions.length; j++) {
    const position = parseInt(tagsPositions[j]);

    if (previousPosition > position) {
      continue;
    }

    const tagData = positionToTag[position];

    updatedPositionToTag[position + shift] = {
      ...tagData,
      start: tagData.start + shift,
      end: tagData.end + shift,
    };
  }

  // add end of the text
  updatedText += text.slice(previousPosition);

  return {
    updatedText,
    updatedPositionToTag,
  };
};
