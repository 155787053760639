import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { IMemberCustomPermissions, ISupportedMediaTypes } from 'APP/model/group/groupModel.types';
import { groupModel } from 'APP/model/model';
import { useTranslation } from 'APP/packages/translations';
import { KeywordFiltersPopup } from 'MAIN/PopupManager/Popups/KeywordFiltersPopup/KeywordFiltersPopup';
import Entities from 'STORE';
import { Popup } from 'STORE/App/Popups/Popup/Popup';
import { Group } from 'STORE/Groups/Group';
import { isEqualObjects } from 'UTILS/isEqualObjects';

import { ICommonPopupsProps } from '../../PopupManager.types';
import {
  IGroupPermissionsPopupParams,
  IGroupPermissionItem,
  TChangeSupportedMediaTypesHandler,
} from './GroupPermissionsPopup.types';

type TPermissionsObject = Omit<IMemberCustomPermissions, 'updatedAt'>;

const getGroupPermissions = (group: Group | null): TPermissionsObject => {
  const permissions = group?.memberCustomPermissions;
  const mediaTypes = permissions?.supportedMediaTypes || {
    canSendImage: !group?.isOpen,
    canSendVideo: !group?.isOpen,
    canSendSticker: true,
    canSendAudio: true,
    canSendVoice: true,
    canSendFile: true,
    canSendLink: !group?.isOpen,
    cansSendCircleVideo: true,
  };

  return {
    canInitCall: permissions?.canInitCall || false,
    canInitConference: permissions?.canInitConference || false,
    canInviteUsers: permissions?.canInviteUsers || false,
    canLeaveComment: permissions?.canLeaveComment || false,
    canPinMessage: permissions?.canPinMessage || false,
    canSendMessages: permissions?.canSendMessages || false,
    canDisplayMembers: permissions?.canDisplayMembers || false,
    supportedMediaTypes: { ...mediaTypes },
  };
};

const getGroupSystemMessagesEnabled = (group: Group | null): boolean => {
  return group?.settings?.systemMessagesEnabled || false;
};

const getGroupObsceneFilterEnabled = (group: Group | null): boolean => {
  return group?.obsceneFilterEnabled || false;
};

interface IGroupPermissionsPopupPresenter {
  systemMessagesEnabled: boolean;
  permissions: TPermissionsObject;
  obsceneFilterEnabled: boolean;
  childPopup: Popup | null;
  group: Group | null;
  isChannel: boolean;
  isShowSystemMessageSection: boolean;
  isShowChangeObsceneFilter: boolean;
  isPermissionsEdited: boolean;
  isSystemMessagesEdited: boolean;
  isObsceneFilterEdited: boolean;
  isEdited: boolean;
  canChangeObsceneFilter: boolean;
  keywordsCount: number;
  permissionItems: IGroupPermissionItem[];
  init(): void;
  onClose(): void;
  onClickObsceneFilter(): void;
  onTogglePermission(key: keyof TPermissionsObject): void;
  onToggleSystemMessage(): void;
  onToggleObsceneFilter(): void;
  onChangeSupportedMediaTypes: TChangeSupportedMediaTypesHandler;
  onChangeKeywordFilters(list: string[]): Promise<void>;
  onOpenKeywordFiltersPopup(): void;
  onSave(): void;
}

export function useGroupPermissionsPopupPresenter({
  popupInstance,
  onClose,
  params,
}: ICommonPopupsProps<IGroupPermissionsPopupParams>): IGroupPermissionsPopupPresenter {
  const { groupId } = params!;
  const { t } = useTranslation();

  const presenter = useLocalStore<IGroupPermissionsPopupPresenter>(() => ({
    permissions: getGroupPermissions(null),
    systemMessagesEnabled: false,
    obsceneFilterEnabled: false,
    childPopup: null,

    get group(): Group | null {
      return Entities.GroupsStore.getGroupById(groupId);
    },

    get isChannel(): boolean {
      return presenter.group?.isChannel || false;
    },

    get isShowSystemMessageSection(): boolean {
      return !presenter.isChannel;
    },

    get isShowChangeObsceneFilter(): boolean {
      if (!presenter.group) {
        return false;
      }

      return presenter.group.isOpen && (!presenter.group.space || presenter.group.space.isPublic);
    },

    get isPermissionsEdited(): boolean {
      return !isEqualObjects(presenter.permissions, getGroupPermissions(presenter.group));
    },

    get isSystemMessagesEdited(): boolean {
      return presenter.systemMessagesEnabled !== getGroupSystemMessagesEnabled(presenter.group);
    },

    get isObsceneFilterEdited(): boolean {
      return presenter.obsceneFilterEnabled !== getGroupObsceneFilterEnabled(presenter.group);
    },

    get isEdited(): boolean {
      return (
        presenter.isPermissionsEdited ||
        presenter.isSystemMessagesEdited ||
        presenter.isObsceneFilterEdited
      );
    },

    get canChangeObsceneFilter(): boolean {
      return !presenter.group?.spaceId;
    },

    get keywordsCount(): number {
      return presenter.group?.keywordFilters || 0;
    },

    get permissionItems(): IGroupPermissionItem[] {
      const permissions: IGroupPermissionItem[] = [];

      if (!presenter.isChannel) {
        permissions.push({
          id: 'invite',
          title: t('permissions_invitations'),
          isChecked: presenter.permissions.canInviteUsers,
          onChange: () => presenter.onTogglePermission('canInviteUsers'),
        });
        permissions.push({
          id: 'calls',
          title: t('permissions_start_calls'),
          isChecked: presenter.permissions.canInitCall,
          onChange: () => presenter.onTogglePermission('canInitCall'),
        });
        permissions.push({
          id: 'conference',
          title: t('permissions_create_conference'),
          isChecked: presenter.permissions.canInitConference,
          onChange: () => presenter.onTogglePermission('canInitConference'),
        });
        permissions.push({
          id: 'pin',
          title: t('permissions_pin_message'),
          isChecked: presenter.permissions.canPinMessage,
          onChange: () => presenter.onTogglePermission('canPinMessage'),
        });
      }

      permissions.push({
        id: 'comments',
        title: t('permissions_leave_comments'),
        isChecked: presenter.permissions.canLeaveComment,
        onChange: () => presenter.onTogglePermission('canLeaveComment'),
      });

      if (!presenter.isChannel) {
        permissions.push({
          id: 'seeMembers',
          title: t('moderation_see_members_permission'),
          isChecked: presenter.permissions.canDisplayMembers,
          onChange: () => presenter.onTogglePermission('canDisplayMembers'),
        });
        permissions.push({
          id: 'sendMessage',
          title: t('moderation_send_permission'),
          isChecked: presenter.permissions.canSendMessages,
          onChange: () => presenter.onTogglePermission('canSendMessages'),
        });
      }

      return permissions;
    },

    init(): void {
      presenter.permissions = getGroupPermissions(presenter.group);
      presenter.systemMessagesEnabled = getGroupSystemMessagesEnabled(presenter.group);
      presenter.obsceneFilterEnabled = getGroupObsceneFilterEnabled(presenter.group);
    },

    onClose(): void {
      presenter.childPopup?.close();
      popupInstance.close();
      onClose?.();
    },

    onClickObsceneFilter(): void {
      if (!presenter.canChangeObsceneFilter) {
        Tasks.app.showToast(t('comment_hide_offensive_word'));
      }
    },

    onTogglePermission(key: keyof Omit<TPermissionsObject, 'supportedMediaTypes'>): void {
      presenter.permissions[key] = !presenter.permissions[key];
    },

    onToggleSystemMessage(): void {
      presenter.systemMessagesEnabled = !presenter.systemMessagesEnabled;
    },

    onToggleObsceneFilter(): void {
      presenter.obsceneFilterEnabled = !presenter.obsceneFilterEnabled;
    },

    onChangeSupportedMediaTypes(data: ISupportedMediaTypes): void {
      presenter.permissions.supportedMediaTypes = data;
    },

    async onChangeKeywordFilters(list: string[]): Promise<void> {
      try {
        await groupModel.setKeywordFilters({
          groupId: presenter.group!.id,
          keywords: list,
        });
      } catch {
        Tasks.app.showToast(t('common_somethings_wrong_error'));
      }
    },

    onOpenKeywordFiltersPopup(): void {
      popupInstance?.hide();

      presenter.childPopup = Tasks.app.openPopup(KeywordFiltersPopup, {
        onClose: () => presenter.onClose(),
        onBack: () => popupInstance?.show(),
        params: {
          loadBlackList: () => groupModel.getKeywordFilters(presenter.group!.id),
          onChange: presenter.onChangeKeywordFilters,
        },
      });
    },

    async onSave(): Promise<void> {
      presenter.onClose();

      if (!presenter.group) {
        return;
      }

      try {
        const updateRequests: Promise<unknown>[] = [];

        if (presenter.isPermissionsEdited) {
          updateRequests.push(
            groupModel.setMemberPermissions({
              ...presenter.permissions,
              groupId: presenter.group.id,
            })
          );
        }

        if (presenter.isSystemMessagesEdited) {
          updateRequests.push(
            groupModel.setGroupSettings({
              systemMessagesEnabled: presenter.systemMessagesEnabled,
              groupId: presenter.group.id,
            })
          );
        }

        if (presenter.isObsceneFilterEdited) {
          updateRequests.push(
            groupModel.setObsceneFilter({
              groupId: presenter.group.id,
              enabled: presenter.obsceneFilterEnabled,
            })
          );
        }

        await Promise.all(updateRequests);
      } catch {
        Tasks.app.showToast(t('something_went_wrong'));
      }
    },
  }));

  useEffect(() => {
    presenter.init();
  }, []);

  useEffect(() => {
    if (!presenter.group?.withMeInAdmins) {
      presenter.onClose();
    }
  }, [presenter.group?.withMeInAdmins]);

  return presenter;
}
