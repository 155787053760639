import Tasks from 'APP/Tasks';
import { addAlert } from 'APP/Tasks/app/showAlert/showAlert';
import { ALERT_TYPES } from 'APP/constants/app';
import { GroupType, GroupUserRole } from 'APP/model/group/groupModel.types';
import api from 'APP/packages/api';
import Entities from 'APP/store';

import preloadGroupsAvatars from './preloadGroupsAvatars';

const privateGroupTypes = [
  GroupType.ChannelClosed,
  GroupType.P2P,
  GroupType.Closed,
  GroupType.MySavedMessages,
];

async function getGroups(groupIds, showAlert, isChannel) {
  try {
    if (!groupIds?.length) {
      return [];
    }

    const response = await api.group.getGroupsByIds({ groupIds });

    if (groupIds.length === 1 && showAlert) {
      if ((response?.userBannedInGroups || []).includes(groupIds[0])) {
        addAlert({
          type: ALERT_TYPES.USER_BANNED_IN_CHANNEL,
        });
        return [];
      }
    }

    if (response && response.items && response.items.length > 0) {
      return response.items;
    }

    if (showAlert && groupIds.length === 1) {
      addAlert({
        type: isChannel
          ? ALERT_TYPES.CHANNEL_IS_NOT_ACCESSIBLE
          : ALERT_TYPES.CHAT_IS_NOT_ACCESSIBLE,
      });
    }
  } catch (error) {
    console.error(error);
  }

  return [];
}

function filteredPrivateGroupsWithoutMe(group) {
  if (
    privateGroupTypes.includes(group.groupResponse.type) &&
    group.userGroupRole === GroupUserRole.Guest
  ) {
    return false;
  }
  return true;
}

function getUsers(groups) {
  return groups.reduce((memo, group) => {
    const user = group.opponent || group.botOpponent;

    if (user) {
      memo.push(user);
    }

    return memo;
  }, []);
}

/**
 *
 * @param groupIds
 * @param showAlert show alerts for groups if the user is banned or the group is not available.
 * Will work at the time of updating or loading information about the group
 * @param isChannel
 * @returns {Promise<void>}
 */
export default async (groupIds, showAlert = false, isChannel = false) => {
  let groups = await getGroups(groupIds, showAlert, isChannel);
  groups = groups.filter(filteredPrivateGroupsWithoutMe);

  if (!groups.length) {
    return;
  }

  await preloadGroupsAvatars(groups);

  const opponents = getUsers(groups);

  if (opponents.length > 0) {
    Entities.UsersStore.add(opponents);
  }

  Entities.GroupsStore.merge(groups);
  const localGroups = groups.map((group) =>
    Entities.GroupsStore.getGroupById(group.groupResponse.id.toString())
  );
  await Tasks.relationships.loadUnknownDataFromGroups({ groups: localGroups });
};
