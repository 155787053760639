import { TFunction } from 'react-i18next';

import {
  TSystemMessageTextHandler,
  ISystemMessageText,
  ISystemMessageTextPresenter,
} from '../useSystemMessageText.types';

export const chatUsersBannedHandler: TSystemMessageTextHandler = (
  presenter: ISystemMessageTextPresenter,
  t: TFunction
): ISystemMessageText => {
  const result: ISystemMessageText = { html: '' };

  if (presenter.isPublicGroup) {
    result.html = t('system_message_public_group_banned', {
      0: `<span class="system-message-title">${presenter.firstUserDisplayedName}</span>`,
      interpolation: { escapeValue: false },
    });
    result.sourceText = t('system_message_public_group_banned');
    result.params = {
      '{0}': { text: presenter.firstUserDisplayedName, userId: presenter.firstUserId },
    };
  } else {
    result.html = t('system_messages:group_chat_user_was_banned', {
      0: `<span class="system-message-title">${presenter.initiatorDisplayName}</span>`,
      1: `<span class="system-message-title">${presenter.firstUserDisplayedName}</span>`,
      interpolation: { escapeValue: false },
    });
    result.sourceText = t('system_messages:group_chat_user_was_banned');
    result.params = {
      '{0}': { text: presenter.initiatorDisplayName, userId: presenter.initiator?.id },
      '{1}': { text: presenter.firstUserDisplayedName, userId: presenter.firstUserId },
    };
  }

  return result;
};
