import { t } from 'MODULE/i18n/service';

import formatDuration from './formatDuration';

export const getDurationText = (durationNumber: number): string => {
  let duration = '';

  if (durationNumber) {
    const durationArray = formatDuration(durationNumber);
    const result: string[] = [];
    if (durationArray[0] !== 0) {
      result.push(t('call_duration_hour').replace('{0}', durationArray[0]));
    }
    if (durationArray[1] !== 0) {
      result.push(t('call_duration_min').replace('{0}', durationArray[1]));
    }
    if (durationArray[2] !== 0) {
      result.push(t('call_duration_sec').replace('{0}', durationArray[2]));
    }
    duration = `, ${result.join(' ')}`;
  }

  return duration;
};
