import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import { spaceModel } from 'APP/model/model';
import { ISpaceCollection } from 'APP/model/space/spaceModel.types';
import { useSpaceCollections } from 'MAIN/hooks/space/useSpaceCollections';
import { Space } from 'STORE/Spaces/Space';

export interface ISpaceCollectionData extends ISpaceCollection {
  canAddMessage: boolean;
}

interface ISpaceCollectionListPresenterParams {
  space: Space;
  groupId: string;
  messageId: string;
}

interface ISpaceCollectionListPresenter {
  isLoading: boolean;
  isCollectionIdsLoading: boolean;
  collections: ISpaceCollectionData[];
  includeCollectionIds: string[];
  initialize(): Promise<void>;
}

export function useSpaceCollectionListPresenter({
  space,
  groupId,
  messageId,
}: ISpaceCollectionListPresenterParams): ISpaceCollectionListPresenter {
  const source = useAsObservableSource({ space, groupId, messageId });
  const spaceCollections = useSpaceCollections(space.id);

  const presenter = useLocalStore<ISpaceCollectionListPresenter>(() => ({
    includeCollectionIds: [],
    isCollectionIdsLoading: false,

    get collections(): ISpaceCollectionData[] {
      return spaceCollections.collections.map((collection) => ({
        ...collection,
        canAddMessage: !presenter.includeCollectionIds.includes(collection.id),
      }));
    },

    get isLoading(): boolean {
      return presenter.isCollectionIdsLoading || spaceCollections.isLoading;
    },

    async initialize(): Promise<void> {
      presenter.isCollectionIdsLoading = true;

      presenter.includeCollectionIds = await spaceModel.getCollectionIdsWithItems({
        spaceId: source.space.id,
        items: [
          {
            groupId: source.groupId,
            messageId: source.messageId,
          },
        ],
      });

      presenter.isCollectionIdsLoading = false;
    },
  }));

  useEffect(() => {
    presenter.initialize();
  }, [space, groupId, messageId]);

  return presenter;
}
