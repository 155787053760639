import dateService from 'APP/packages/date';

const validate = ({ value, data }) => {
  const time = dateService.parseTime(value);
  if (!time) {
    return ' ';
  }

  const minTime = dateService.parseTime(data.minTime || '');
  const maxTime = dateService.parseTime(data.maxTime || '');
  if (!minTime && !maxTime) {
    return null;
  }

  const isIncorrect = time.some((v, i) => {
    if (
      minTime &&
      time[i] < minTime[i] &&
      !Array(i)
        .fill(0)
        .some((v, j) => minTime[j] !== time[j])
    ) {
      return true;
    }

    if (
      maxTime &&
      time[i] > maxTime[i] &&
      !Array(i)
        .fill(0)
        .some((v, j) => maxTime[j] !== time[j])
    ) {
      return true;
    }

    return false;
  });

  return isIncorrect ? data.errorText || ' ' : null;
};

export default {
  validate,
};
