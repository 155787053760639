import classNames from 'classnames';
import React, { memo } from 'react';

import AnimatedPartStyles from '../AnimatedPart/AnimatedPart.styles.m.css';
import { AvatarPlaceholder, AvatarPlaceholderType } from '../AvatarPlaceholder/AvatarPlaceholder';
import styles from './styles.m.scss';

const InfoCard = ({
  className,
  avatarType = AvatarPlaceholderType.Round_10,
  isFirstAnimated,
  isSecondAnimated,
}) => {
  return (
    <div className={classNames(styles.container, className)}>
      <AvatarPlaceholder
        type={avatarType}
        isAnimated={isFirstAnimated}
      />
      <div className={styles.content}>
        <div
          className={classNames(styles.firstLine, {
            [AnimatedPartStyles.animation]: isSecondAnimated,
          })}
        />
        <div
          className={classNames(styles.secondLine, {
            [AnimatedPartStyles.animation]: isSecondAnimated,
          })}
        />
      </div>
    </div>
  );
};

export default memo(InfoCard);
