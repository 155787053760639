import { updateOldOrFakeMessages } from 'APP/Tasks/messaging/updateOldOrFakeMessages/updateOldOrFakeMessages';
import { langService } from 'MODULE/i18n/service';

const fieldsToDirectMap = [
  'id',
  'name',
  'avatarUrl',
  'initiator',
  'categoryId',
  'usersCount',
  'type',
  'deepLinkInfo',
  'userGroupRole',
  'categoryInfo',
  'draftInput',
  'memberCustomPermissions',
  'descriptionMentions',
  'unread',
  'spaceId',
  'settings',
  'showContent',
  'obsceneFilterEnabled',
  'keywordFilters',
];

const textFieldsToDirectMap = ['description', 'donationUrl'];

export const updateDirectFields = (sourceObject, targetObject) => {
  const target = targetObject;

  fieldsToDirectMap.forEach((field) => {
    if (sourceObject[field] !== undefined) {
      target[field] = sourceObject[field];
    }
  });

  textFieldsToDirectMap.forEach((field) => {
    if (typeof sourceObject[field] === 'string') {
      target[field] = sourceObject[field];
    }
  });
};

export const updateIndirectFields = (sourceObject, targetObject) => {
  const target = targetObject;

  target.avatarUrl = sourceObject.avatarUrl;
  target._verified = sourceObject.verified;
  if (sourceObject.opponent) {
    target.opponentId = sourceObject.opponent.id;
    target.avatarUrl = sourceObject.opponent.avatarUrl;
  }

  if (sourceObject.botOpponent) {
    target.botOpponentId = sourceObject.botOpponent.id;
    target.avatarUrl = sourceObject.botOpponent.avatarUrl;
  }

  if (sourceObject.botOpponent && sourceObject.botOpponent.deleted) {
    target.description = langService.i18n.lang.deleted_bot_input_menu;
  }

  target.muted = sourceObject.muted;
  target.pinOrder = sourceObject.pinOrder;
  target.blurred = sourceObject.blurred;

  if (sourceObject.pinnedMessages?.length) {
    sourceObject.pinnedMessages.forEach((message) => {
      updateOldOrFakeMessages({
        messages: [message],
        groupId: sourceObject.id,
        targetGroup: targetObject,
      });
    });
    target.pinnedMessages.clear();
    target.pinnedMessages.set(sourceObject.pinnedMessages.map((message) => message.id));
  } else {
    target.pinnedMessages.clear();
  }
};
