import Tasks from 'APP/Tasks';
import { IUserStickerPacksUpdatedEventPayload } from 'APP/model/stickers/stickersModel.types';
import { storage } from 'APP/packages/storage';
import Entities from 'STORE';

import { GroupsEvent } from '../GroupsEvent';

export class StickerPacksUpdated extends GroupsEvent<IUserStickerPacksUpdatedEventPayload> {
  async process(): Promise<void> {
    if (!this.eventPayload) {
      //TODO: ask about reject error
      return;
    }

    const { stickerPacks: stickerPackIds } = this.eventPayload;

    const unknownStickerPackIds = stickerPackIds.filter(
      (id) => !Entities.stickersStore.getById(id)
    );

    const promises = unknownStickerPackIds.map((newStickerPackId) =>
      Tasks.stickers.loadStickerPackById(newStickerPackId)
    );

    await Promise.all(promises);

    Entities.stickersStore.setInstalledPackIds(stickerPackIds);

    const packs = Entities.stickersStore.transformToStickerPacks([
      ...Entities.stickersStore.installedPacks,
    ]);

    storage.installedStickerPacks.set({
      lastUpdateTime: this.ts,
      packs,
    });
  }
}
