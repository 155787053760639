import Tasks from 'APP/Tasks';
import { PayloadType } from 'APP/model/message/messageModel.types';
import { RouterLink, RouterState } from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';

const MEDIA_TYPES = [PayloadType.Image, PayloadType.Video];

export default ({ message }) => {
  const navigateTo = useNavigateTo();

  const media = message.payload.payloads.find((typeObject) =>
    MEDIA_TYPES.includes(typeObject.payload.payloadType)
  );

  const onClick = async () => {
    const link = message.payload.buttonUrl;
    if (!link) {
      return;
    }

    if (Tasks.deeplinks.isDeepLink(link)) {
      Tasks.deeplinks.handleDeepLink(link, navigateTo);
      return;
    }

    if (Tasks.market.isMarketUrl(link || '')) {
      navigateTo({
        to: RouterLink.market,
        state: {
          [RouterState.url]: link,
        },
      });
      return;
    }

    window.open(link, '_blank');
  };
  return { media: media && media.payload, onClick };
};
