import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { Media } from 'MAIN/SharedData/Media/Media';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';

import usePresenter from './presenter';
import styles from './styles.m.css';

const SharedMedia = () => {
  const presenter = usePresenter();

  if (presenter.isLoading) {
    return null;
  }

  return (
    <Popup
      isOpened
      title={presenter.title}
      onClose={presenter.onClose}
    >
      <div className={classNames(styles.container, customScrollStyles.container)}>
        <Media groupId={presenter.groupId} />
      </div>
    </Popup>
  );
};

export default observer(SharedMedia);
