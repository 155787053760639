import classNames from 'classnames';
import React, { FC } from 'react';

import { Checkbox } from 'UIKIT/Checkbox';

import styles from './MediaMessageCheckbox.styles.m.css';

interface ICompressionCheckboxProps {
  className?: string;
  onChange(value: boolean): void;
  isChecked: boolean;
  label?: string;
  isDisabled?: boolean;
}

export const MediaMessageCheckbox: FC<ICompressionCheckboxProps> = (props) => {
  const { onChange, isChecked, className, label, isDisabled } = props;

  return (
    <label className={classNames(styles.container, className)}>
      <Checkbox
        className={styles.checkbox}
        onChange={onChange}
        isChecked={isChecked}
        isDisabled={isDisabled}
      />
      {label && <span className={styles.label}>{label}</span>}
    </label>
  );
};
