import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { IButtonPayloadModel } from 'STORE/Messages/Message/Payload/Buttons/Buttons';
import { ShareCardButtonPayload } from 'STORE/Messages/Message/Payload/Buttons/ShareCardButtonPayload';

import { CommandButton } from '../CommandButton/CommandButton';
import { useShareCardCommandButtonPresenter } from './ShareCardCommandButton.presenter';
import styles from './ShareCardCommandButton.styles.m.css';

interface IShareCardCommandButtonProps {
  groupId: string;
  button: IButtonPayloadModel;
}

export const ShareCardCommandButton: FC<IShareCardCommandButtonProps> = observer((props) => {
  const { button } = props;

  const presenter = useShareCardCommandButtonPresenter(button.payload as ShareCardButtonPayload);

  return (
    <CommandButton
      onClick={presenter.onClick}
      title={button.title}
      icon={<span className={styles.emoji}>&#10145;&#65039;</span>}
    />
  );
});
