import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { ICreateSpace, SpaceType } from 'APP/model/space/spaceModel.types';
import { useTranslation } from 'APP/packages/translations';
import validations from 'APP/packages/validations';

export type ISpaceFormData = ICreateSpace;

export enum CreateSpaceStep {
  GeneralInfo = 'general-info',
  AdditionalInfo = 'additional-info',
}

interface ISpaceFormTypeOption {
  value: SpaceType;
  label: string;
  description: string;
}

interface ISpaceFormPresenterParams {
  initialValues: ISpaceFormData;
  isEditMode: boolean;
  newStep?: CreateSpaceStep;
  onStepChange?(step: CreateSpaceStep): void;
}

interface ISpaceFormPresenter {
  categories: {
    label: string;
    value: string;
  }[];
  step: CreateSpaceStep;
  initialValues: ISpaceFormData;
  nameValidate: any; // TODO: fix any after validation typings
  categoryValidate: any; // TODO: fix any after validation typings
  typeOptions: ISpaceFormTypeOption[];
  aliasValidate: any | null; // TODO: fix any after validation typings
  isGeneralInfo: boolean;
  isAdditionalInfo: boolean;
  isShowGeneralInfo: boolean;
  isShowAdditionalInfo: boolean;
  isShowPrevStep: boolean;
  isShowSubmit: boolean;
  onShowAdditionalInfo(): void;
  loadCategories(): Promise<void>;
  forceUpdateForm(): void;
}

export function useSpaceFormPresenter({
  initialValues,
  isEditMode,
  newStep,
  onStepChange,
}: ISpaceFormPresenterParams): ISpaceFormPresenter {
  const { t } = useTranslation();
  const formValidations = validations.createEditSpace();
  const source = useAsObservableSource({ isEditMode, onStepChange });

  const presenter = useLocalStore<ISpaceFormPresenter>(() => ({
    categories: [],
    step: CreateSpaceStep.GeneralInfo,
    initialValues,

    nameValidate: formValidations.spaceName.validate,
    categoryValidate: formValidations.spaceCategory.validate,

    typeOptions: [
      {
        value: SpaceType.Public,
        label: t('space_public_option'),
        description: t('space_public_option_subhint'),
      },
      {
        value: SpaceType.Private,
        label: t('space_private_option'),
        description: t('space_private_option_subhint'),
      },
    ],

    get aliasValidate(): any | null {
      if (source.isEditMode) {
        return formValidations.spaceAliasEdit.validate;
      }

      if (presenter.isAdditionalInfo) {
        return formValidations.spaceAliasCreate.validate;
      }

      return null;
    },

    get isGeneralInfo(): boolean {
      return presenter.step === CreateSpaceStep.GeneralInfo;
    },

    get isAdditionalInfo(): boolean {
      return presenter.step === CreateSpaceStep.AdditionalInfo;
    },

    get isShowGeneralInfo(): boolean {
      return source.isEditMode || presenter.isGeneralInfo;
    },

    get isShowAdditionalInfo(): boolean {
      return source.isEditMode || presenter.isAdditionalInfo;
    },

    get isShowPrevStep(): boolean {
      return !source.isEditMode && presenter.isAdditionalInfo;
    },

    get isShowSubmit(): boolean {
      return source.isEditMode || presenter.isAdditionalInfo;
    },

    onShowAdditionalInfo(): void {
      presenter.step = CreateSpaceStep.AdditionalInfo;
      source.onStepChange?.(presenter.step);
    },

    async loadCategories(): Promise<void> {
      const categories = await Tasks.search.getAllCategoriesInfo();

      presenter.categories = categories.map((category) => ({
        label: category.displayName,
        value: category.id,
      }));

      presenter.forceUpdateForm();
    },

    /* force rerender fields, when data changes: when categories loaded */
    forceUpdateForm(): void {
      presenter.initialValues = { ...presenter.initialValues };
    },
  }));

  useEffect(() => {
    presenter.loadCategories();
  }, []);

  useEffect(() => {
    presenter.initialValues = initialValues;
  }, [initialValues]);

  useEffect(() => {
    if (newStep) {
      presenter.step = newStep;
    }
  }, [newStep]);

  return presenter;
}
