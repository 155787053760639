import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { useTranslation } from 'APP/packages/translations';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { SpaceCollectionForm } from '../../components/SpaceCollectionForm/SpaceCollectionForm';
import { useCloseFormPopup } from '../../hooks/useCloseFormPopup';
import {
  IEditSpaceCollectionPopupPresenterParams,
  useEditSpaceCollectionPopupPresenter,
} from './EditSpaceCollectionPopup.presenter';

type IEditSpaceCollectionPopupProps = IEditSpaceCollectionPopupPresenterParams;

export const EditSpaceCollectionPopup: FC<ICommonPopupsProps<IEditSpaceCollectionPopupProps>> =
  observer((props) => {
    const { t } = useTranslation();
    const presenter = useEditSpaceCollectionPopupPresenter(props);
    const closeForm = useCloseFormPopup(props, t('common_cancel_action_confirmation'));

    return (
      <Popup
        title={t('message_edit')}
        isOpened={!!presenter.space}
        onClose={closeForm.closePopup}
      >
        <SpaceCollectionForm
          initialValues={presenter.initialValues}
          onFormStateChange={closeForm.onFormStateChange}
          onSubmit={presenter.onSubmit}
        />
      </Popup>
    );
  });
