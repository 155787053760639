import { spaceModel } from 'APP/model/model';
import Entities from 'STORE';

export async function loadSpaceMarkets(spaceId: string): Promise<void> {
  const space = Entities.spacesStore.getById(spaceId);

  if (!space) {
    return;
  }

  if (!space.marketsCount) {
    space.markets.setMarkets([]);
    return;
  }

  try {
    const markets = await spaceModel.getMarkets(spaceId);
    space.markets.setMarkets(markets);
  } catch {
    space.markets.setMarkets([]);
  }
}
