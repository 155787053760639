import React from 'react';

import CloseIcon from 'ICONS/ic-close-large.svg';
import IcPlus from 'ICONS/ic-plus.svg';
import { IconButton, IconViewSizes } from 'UIKIT/IconButton';
import { PopoverMenu } from 'UIKIT/PopoverMenu/PopoverMenu';

import { useNewMessageButtonPresenter } from './NewMessageButton.presenter';
import styles from './NewMessageButton.styles.m.css';

export const NewMessageButton = () => {
  const { presenter, popover } = useNewMessageButtonPresenter();

  return (
    <>
      <IconButton
        className={styles.button}
        iconClassName={styles.icon}
        setRef={popover.containerRef}
        Icon={popover.isOpened ? CloseIcon : IcPlus}
        size={IconViewSizes.Large}
        iconSize={IconViewSizes.Medium}
        theme="20"
        dataTest="new-message-button"
        onClick={popover.toggle}
      />

      <PopoverMenu
        className={styles.menu}
        isOpened={popover.isOpened}
        menuItems={presenter.menuItems}
        onClose={popover.close}
        reference={popover.containerRef.current}
        placement="top-end"
      />
    </>
  );
};
