import { observer } from 'mobx-react';
import React from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { PermissionsTypes } from 'APP/model/callPermissions/callPermissionsModel.constants';
import { useTranslation } from 'APP/packages/translations';
import { Button } from 'UIKIT/Button/Button';

import { CallMember } from '../Shared/CallMember';
import { CallMemberPermission } from '../Shared/CallMemberPermission';
import { DeleteMemberPermissions } from './components/DeleteMemberPermissions';
import { usePresenter } from './presenter';
import styles from './styles.m.css';

export const CallMemberPermissionsPopup = observer(() => {
  const { t } = useTranslation();
  const presenter = usePresenter();

  if (!presenter.ongoingCall) {
    return null;
  }

  return (
    <Popup
      title={t('call_custom_permissions')}
      isOpened
      onClose={presenter.onClose}
      handlePrevStep={presenter.goBack}
    >
      <div className={styles.wrapper}>
        <CallMember
          className={styles.member}
          userId={presenter.userId}
        />
        <div className={styles.divider} />
        <div className={styles.title}>{t('call_what_can_participant_do')}</div>
        <CallMemberPermission
          permissionType={PermissionsTypes.SwitchOnCamera}
          memberPermissionsId={presenter.userId}
        />
        <CallMemberPermission
          permissionType={PermissionsTypes.SwitchOnMic}
          memberPermissionsId={presenter.userId}
        />
        <CallMemberPermission
          permissionType={PermissionsTypes.ShareScreen}
          memberPermissionsId={presenter.userId}
        />
      </div>
      {presenter.isNewMemberPermissions ? (
        <Button
          className={styles.addButton}
          onClick={presenter.onAddClick}
          theme="1"
        >
          {t('add_button')}
        </Button>
      ) : (
        <DeleteMemberPermissions onDeleteButtonClick={presenter.onDeleteButtonClick} />
      )}
    </Popup>
  );
});
