import { useEffect } from 'react';

import connection from 'APP/packages/connection';
import Entities from 'APP/store';

export default () => {
  useEffect(() => {
    if (Entities.AuthStore.streamToken && !connection.main.isOpened) {
      connection.main.open();
    }
    if (!Entities.AuthStore.streamToken) {
      connection.main.close();
    }
  }, [Entities.AuthStore.streamToken]);
};
