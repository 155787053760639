import { observable, action } from 'mobx';

import { NewsType } from 'APP/model/catalog/catalogModel.types';

export class NewsItem {
  type: NewsType;
  @observable isVisible = true;
  @observable isShowHiddenInfo = false;

  constructor(type: NewsType) {
    this.type = type;
  }

  @action
  setVisibility(isVisible: boolean): void {
    this.isVisible = isVisible;
  }

  @action
  setHiddenInfoVisibility(isVisible: boolean): void {
    this.isShowHiddenInfo = isVisible;
  }
}
