import { action, computed, observable } from 'mobx';

import { ISpaceCollection } from 'APP/model/space/spaceModel.types';

export class SpaceCollections {
  @observable data: ISpaceCollection[] = [];
  @observable isLoading = false;
  @observable isNeedToUpdate = true;

  @computed
  public get count(): number {
    return this.data.length;
  }

  public getCollection(id: string): ISpaceCollection | null {
    return this.data.find((collection) => collection.id === id) || null;
  }

  @action
  public addCollection(collection: ISpaceCollection): void {
    this.data.push(collection);
  }

  @action
  public setCollections(collections: ISpaceCollection[]): void {
    this.data = collections;
  }

  @action
  public updateCollection(updatedCollection: Partial<ISpaceCollection>): void {
    this.data = this.data.map((collection) =>
      collection.id === updatedCollection.id
        ? {
            ...collection,
            ...updatedCollection,
          }
        : collection
    );
  }

  @action
  public deleteCollection(id: string): void {
    this.data = this.data.filter((collection) => collection.id !== id);
  }

  @action
  public setIsNeedToUpdate(isNeedToUpdate: boolean): void {
    this.isNeedToUpdate = isNeedToUpdate;
  }
}
