import { VirtualElement } from '@popperjs/core';
import { useState, Dispatch, SetStateAction } from 'react';

import { ITextFormattingMenuParams, ViewType } from './TextFormattingMenu.types';

interface ITextFormattingMenuPresenter {
  viewType: ViewType;
  referenceElement: VirtualElement;
  onChangeViewType(view: ViewType): void;
}

interface IUseTextFormattingMenuPresenter {
  presenter: ITextFormattingMenuPresenter;
  popperRef: HTMLElement | null;
  setPopperRef: Dispatch<SetStateAction<HTMLElement>>;
}

export const useTextFormattingMenuPresenter = (
  data: ITextFormattingMenuParams
): IUseTextFormattingMenuPresenter => {
  const { selectionRange } = data;
  const [popperRef, setPopperRef] = useState<HTMLElement | null>(null);
  const [viewType, setViewType] = useState<ViewType>(ViewType.Main);

  const presenter: ITextFormattingMenuPresenter = {
    viewType,

    get referenceElement(): VirtualElement {
      return {
        getBoundingClientRect: () => selectionRange.getBoundingClientRect(),
      };
    },

    onChangeViewType(view: ViewType): void {
      // This hack is need to ClickOutside component.
      // When we change view, the clicked button hide and ClickOutside think what we clicked in outside of component.
      // We need change view after the ClickOutside component will be run.
      setTimeout(() => setViewType(view), 0);
    },
  };

  return { presenter, popperRef, setPopperRef };
};
