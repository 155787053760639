export enum WorkerMessageType {
  CreateRLottieInstance = 'createRLottieInstance',
  RLottieInstanceCreated = 'rLottieInstanceCreated',

  RequestFrame = 'requestFrame',
  RenderFrame = 'renderFrame',
  Destroy = 'destroy',

  UpdateIsVisible = 'updateIsVisible',
}

export interface RLottieWorkerMessage<T> {
  type: string;
  data?: T;
}

export interface ICreateRLottieInstanceRequest {
  id: string;
  localId: string;
  lottieObject: string;
}

export interface IUpdateIsVisibleRequest {
  id: string;
  localId: string;
  isVisible: boolean;
}

export interface IDestroyRequest {
  id: string;
  localId: string;
}

export interface ICreateRLottieInstanceResponse {
  totalFrame: number;
}

export interface IRenderFrameRequest {
  id: string;
  localId: string;
  curFrame: number;
  totalFrame: number;
  width: number;
  height: number;
  immediatelyRender?: boolean;
}

export interface IRenderFrameResponse {
  curFrame: number;
  clampedArray: Uint8ClampedArray;
  immediatelyRender?: boolean;
}
