function extractShortLanguage(language) {
  if (!language || !language.includes('-')) {
    return language;
  }

  return language.split('-')[0];
}

export default (language, defaultLanguage) => {
  return {
    shortLanguage: extractShortLanguage(language),
    shortDefaultLanguage: extractShortLanguage(defaultLanguage),
  };
};
