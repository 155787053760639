import classNames from 'classnames';
import React, { ElementType, FC } from 'react';

import IcClose16 from 'ICONS/ic-close-16.svg';
import { IconButton } from 'UIKIT/IconButton';
import { IconViewSizes } from 'UIKIT/IconView/IconView.types';

import styles from './Onboarding.styles.m.css';

interface IOnboardingProps {
  className?: string;
  title?: string;
  description: string;
  Icon?: ElementType;
  arrowOffset?: number;
  handleClose(): void;
}

export const Onboarding: FC<IOnboardingProps> = ({
  className,
  title,
  description,
  Icon,
  arrowOffset = 0,
  handleClose,
}) => {
  return (
    <div className={classNames(styles.container, className)}>
      {Icon && (
        <div className={styles.image}>
          <Icon className={styles.icon} />
        </div>
      )}

      <div className={styles.content}>
        {title && <div className={styles.title}>{title}</div>}
        <div className={styles.description}>{description}</div>
      </div>

      <div className={styles.close}>
        <IconButton
          Icon={IcClose16}
          onClick={handleClose}
          theme="14"
          size={IconViewSizes.Small}
        />
      </div>

      <div
        className={styles.arrow}
        style={{ marginLeft: arrowOffset }}
      />
    </div>
  );
};
