export interface IDevices {
  audioinput: MediaDeviceInfo | null;
  audiooutput: MediaDeviceInfo | null;
  videoinput: MediaDeviceInfo | null;
}

export const getDefaultDevices = async (): Promise<IDevices> => {
  const defaultDevices: {
    audioinput: MediaDeviceInfo | null;
    audiooutput: MediaDeviceInfo | null;
    videoinput: MediaDeviceInfo | null;
  } = { audioinput: null, audiooutput: null, videoinput: null };

  return navigator.mediaDevices
    .enumerateDevices()
    .then((devices) => {
      devices.forEach((device) => {
        if (!defaultDevices[device.kind]) {
          defaultDevices[device.kind] = device;
          return;
        }

        if (
          defaultDevices[device.kind]?.deviceId === 'default' &&
          device.groupId === defaultDevices[device.kind]?.groupId
        ) {
          defaultDevices[device.kind] = device;
          return;
        }
      });

      return defaultDevices;
    })
    .catch(() => {
      return defaultDevices;
    });
};
