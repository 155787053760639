import { useAsObservableSource, useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { RouterLink, RouterPage } from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import Entities from 'APP/store';
import { Group } from 'STORE/Groups/Group';
import { P2P } from 'STORE/Groups/P2P';

import { useHandleClosePushNotifications } from './hooks/handleClosePushNotifications/handleClosePushNotifications';

interface IChatViewPresenter {
  group: Group | null;
  isAuthenticated: boolean;
  isBlockedByMe: boolean;
  isBlockedMe: boolean;
  isAllowPostMessage: boolean;
  onDropFiles(files: FileList): void;
  onClose(): void;
}

export function useChatViewPresenter(): IChatViewPresenter {
  const navigateTo = useNavigateTo();
  const source = useAsObservableSource({ navigateTo });

  useHandleClosePushNotifications();

  const presenter = useLocalStore<IChatViewPresenter>(() => ({
    isDragging: false,
    dragCounter: 0,

    get group(): Group | null {
      return Entities.GroupsStore.activeGroup;
    },

    get isAuthenticated(): boolean {
      return Entities.AuthStore.isAuthenticated;
    },

    onClose(): void {
      const space = Entities.spacesStore.activeSpace;
      if (space) {
        source.navigateTo({ to: `/${RouterPage.Spaces}/${space.id}` });
        return;
      }

      source.navigateTo({ to: RouterLink.main });
    },

    get isBlockedByMe(): boolean {
      return presenter.group instanceof P2P && presenter.group.isBlockedByMe;
    },

    get isBlockedMe(): boolean {
      return (
        presenter.group instanceof P2P &&
        presenter.group.isBlockedMe &&
        !presenter.group.isBlockedByMe
      );
    },

    onDropFiles(files: FileList): void {
      if (!presenter.group) {
        return;
      }

      Tasks.messaging.sendFiles({
        groupId: presenter.group.id,
        files: Array.from(files),
      });
    },

    get isAllowPostMessage(): boolean {
      const res =
        !presenter.isBlockedMe && !presenter.isBlockedByMe && !!presenter.group?.canSendMessages;

      if (res && presenter.group?.isChatGroup) {
        return !presenter.group.withoutMe;
      }
      if (res && presenter.group?.isChannel) {
        return presenter.group.withMeInAdmins;
      }

      return res;
    },
  }));

  return presenter;
}
