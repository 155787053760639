import { useLocalStore } from 'mobx-react';
import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { UnauthorizedCallType } from 'APP/model/unauthorizedCall/unauthorizedCallModel.constants';
import validations from 'APP/packages/validations';
import { RouterLink } from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import Entities from 'STORE';

import { NameBlockLocalStore } from './NameBlock.types';

export const useNameBlockPresenter = () => {
  const userNameRef = useRef<HTMLInputElement>(null);
  const params = useParams<{ id: string; channelId?: string; callType: UnauthorizedCallType }>();
  const formValidations = validations.editProfileForm();
  const navigateTo = useNavigateTo();
  const presenter = useLocalStore<NameBlockLocalStore>(() => ({
    loading: false,
    usernameValidate: formValidations.username.validate,
    initialValue: {
      userName: '',
    },

    get userNameRef() {
      return userNameRef;
    },

    get isGroupCall() {
      return params.callType === UnauthorizedCallType.Call;
    },

    async onSubmit(data) {
      if (presenter.loading) {
        return;
      }

      Entities.callInvitation.setSelfName(data.userName);
      navigateTo({
        to: presenter.isGroupCall ? RouterLink.callPreviewGroup : RouterLink.callPreview,
        params: { id: params.id, callType: params.callType, channelId: params.channelId },
      });
    },
  }));

  useEffect(() => {
    if (userNameRef.current) {
      userNameRef.current.focus();
    }
  }, []);

  return presenter;
};
