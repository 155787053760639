import { action, computed, observable } from 'mobx';

import { GlobalVideoPlayerType, IGlobalVideoPlayerConfig } from './GlobalVideoPlayer.types';

export class GlobalVideoPlayer {
  @observable url: string | null = null;
  @observable previewUrl: string | null = null;
  @observable container: HTMLElement | null = null;
  @observable type: GlobalVideoPlayerType | null = null;
  @observable isPlaying = true;
  @observable isFirstFrameLoaded = false;
  @observable progress = 0;
  @observable newProgress: number | null = null;
  @observable config: Partial<IGlobalVideoPlayerConfig> = {};
  @observable onShowMiniHandler: (() => void) | null = null;
  @observable onExpandMiniHandler: (() => void) | null = null;

  @action
  setUrl(url: string | null): void {
    this.url = url;
  }

  @action
  setPreviewUrl(previewUrl: string | null): void {
    this.previewUrl = previewUrl;
  }

  @action
  setContainer(container: HTMLElement | null): void {
    this.container = container;
  }

  @action
  setType(type: GlobalVideoPlayerType | null): void {
    this.type = type;
  }

  @action
  setPlaying(isPlaying: boolean): void {
    this.isPlaying = isPlaying;
  }

  @action
  setFirstFrameLoaded(isLoaded: boolean): void {
    this.isFirstFrameLoaded = isLoaded;
  }

  @action
  public setProgress(progress: number): void {
    this.progress = progress;
  }

  @action
  public updateProgress(progress: number | null): void {
    this.newProgress = progress;
  }

  @action
  reset(): void {
    this.setType(null);
    this.setUrl(null);
    this.setPreviewUrl(null);
    this.setContainer(null);
    this.setPlaying(true);
    this.setFirstFrameLoaded(false);
    this.setProgress(0);
    this.updateProgress(null);
    this.setShowMiniHandler(null);
    this.setExpandMiniHandler(null);
  }

  @action
  setShowMiniHandler(onShowMiniHandler: (() => void) | null): void {
    this.onShowMiniHandler = onShowMiniHandler;
  }

  @action
  setExpandMiniHandler(onExpandMiniHandler: (() => void) | null): void {
    this.onExpandMiniHandler = onExpandMiniHandler;
  }

  @action
  setConfig(newConfig: Partial<IGlobalVideoPlayerConfig>): void {
    this.config = {
      ...this.config,
      ...newConfig,
    };
  }

  @computed
  get isOpened(): boolean {
    return !!this.url;
  }

  @computed
  get isMiniOpened(): boolean {
    return this.isOpened && this.type === GlobalVideoPlayerType.Mini;
  }
}
