import api from 'APP/packages/api';
import logger from 'APP/packages/logger';

import { buildModelError } from '../error/errorBuilder';
import {
  mapCreateScheduledEventParams,
  mapScheduledEventInfo,
  mapScheduledEventListInfo,
  mapScheduledEventInviteesResponse,
} from './scheduledEventModel.mapper';
import {
  ICancelScheduledEventParams,
  ICreateScheduledEventParams,
  IGetScheduledEventInviteesParams,
  IInvitees,
  IScheduledEventDetails,
  IScheduledEventsList,
} from './scheduledEventModel.types';

export const getScheduledEventDetails = async (
  eventId: string
): Promise<IScheduledEventDetails> => {
  try {
    const scheduledEventInfo = await api.scheduledEvents.getScheduledEvent({ eventId });
    return mapScheduledEventInfo(scheduledEventInfo);
  } catch (error) {
    logger.get('API').error('scheduledEvents.getScheduledEvent', error);
    throw buildModelError(error);
  }
};

export const createScheduledEvent = async (
  props: ICreateScheduledEventParams
): Promise<IScheduledEventDetails> => {
  try {
    const data = mapCreateScheduledEventParams(props);
    const response = await api.scheduledEvents.createScheduledEvent(data);
    return mapScheduledEventInfo(response);
  } catch (error) {
    logger.get('API').error('scheduledEvents.createScheduledEvent', error);
    throw buildModelError(error);
  }
};

export const getScheduledEvents = async (props: {
  cursor: string | undefined;
}): Promise<IScheduledEventsList> => {
  try {
    const info = await api.scheduledEvents.getScheduledEvents(props);

    return mapScheduledEventListInfo(info);
  } catch (error) {
    logger.get('API').error('scheduledEvents.getScheduledEvents', error);
    throw buildModelError(error);
  }
};

export const getScheduledEventInvitees = async (
  params: IGetScheduledEventInviteesParams
): Promise<IInvitees> => {
  try {
    const info = await api.scheduledEvents.getScheduledEventInvitees(params);

    return mapScheduledEventInviteesResponse(info);
  } catch (error) {
    logger.get('API').error('scheduledEvents.getScheduledEventInvitees', error);
    throw buildModelError(error);
  }
};

export const cancelScheduledEvent = async (params: ICancelScheduledEventParams): Promise<void> => {
  try {
    await api.scheduledEvents.cancelScheduledEvent(params);
  } catch (error) {
    logger.get('API').error('scheduledEvents.cancelScheduledEvent', error);
    throw buildModelError(error);
  }
};
