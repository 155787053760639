import isValidDot from './isValidDot';

export default (text, index) => {
  if (index + 1 === text.length) {
    return false;
  }

  for (let i = index + 1; i < text.length; i++) {
    const char = text[i];

    if (char === '.' && isValidDot(text, i)) {
      return false;
    }

    if ([' ', '\n'].includes(char)) {
      return true;
    }
  }

  return true;
};
