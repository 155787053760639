import { observer } from 'mobx-react';
import React from 'react';

import SelectContacts from 'APP/components/SelectContacts';
import { Button } from 'UIKIT/Button/Button';
import { PopupFooter } from 'UIKIT/PopupFooter/PopupFooter';

import {
  IParticipantSelectListPresenterParams,
  useParticipantSelectList,
} from './ParticipantSelectList.presenter';

export type IParticipantSelectListParams = IParticipantSelectListPresenterParams;

export const ParticipantSelectList = observer(
  ({ onSuccess, group }: IParticipantSelectListParams) => {
    const presenter = useParticipantSelectList({ onSuccess, group });

    return (
      <>
        <SelectContacts
          targetGroupType={group.type}
          groupId={group.id}
          contacts={presenter.participants}
          selectedContactsIds={presenter.selectedParticipantIds}
          isLoading={presenter.isLoading}
          setSelectedIds={presenter.setSelectedIds}
          setSearchValue={presenter.setSearchValue}
          searchContacts={presenter.searchContacts}
        />
        <PopupFooter>
          <Button
            isLoading={presenter.isSubmitting}
            isDisabled={presenter.isAddButtonDisabled}
            onClick={presenter.addParticipants}
            theme="1"
          >
            {presenter.buttonText}
          </Button>
        </PopupFooter>
      </>
    );
  }
);
