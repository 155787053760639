import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { PayloadType } from 'APP/model/message/messageModel.types';
import DeleteIcon from 'ICONS/ic-delete.svg';
import { FAB } from 'UIKIT/FAB/FAB';
import { FileIcon } from 'UIKIT/FileIcon/FileIcon';
import { FileIconState } from 'UIKIT/FileIcon/FileIcon.types';
import { getFileIconThemeByExt } from 'UIKIT/FileIcon/FileIcon.utils';
import { FileName } from 'UIKIT/FileName/FileName';
import { PlayIcon } from 'UIKIT/PlayIcon/PlayIcon';
import { PlayIconState, PlayIconType } from 'UIKIT/PlayIcon/PlayIcon.types';
import { getFileExt } from 'UTILS/mime';

import { IItemMediaPresenter } from '../ItemMedia.presenter';
import styles from './MediaMessageFile.styles.m.css';

interface IMediaMessageFileProps {
  presenter: IItemMediaPresenter;
}

export const MediaMessageFile: FC<IMediaMessageFileProps> = observer(({ presenter }) => {
  const fileExt = getFileExt(presenter.name);

  return (
    <div className={styles.file}>
      {presenter.payloadType === PayloadType.AudioMessage ? (
        <PlayIcon
          type={PlayIconType.Play}
          state={PlayIconState.Regular}
        />
      ) : (
        <FileIcon
          text={fileExt}
          theme={getFileIconThemeByExt(fileExt)}
          state={FileIconState.Regular}
        />
      )}
      <div className={styles.content}>
        <FileName name={presenter.name} />
        <div className={styles.size}>{presenter.size}</div>
      </div>
      {presenter.canBeRemoved && (
        <div className={styles.deletePanel}>
          <FAB
            className={styles.delete}
            Icon={DeleteIcon}
            onClick={presenter.remove}
            size="30"
            theme="6"
          />
        </div>
      )}
    </div>
  );
});
