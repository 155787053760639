import { action } from 'mobx';

import Tasks from 'APP/Tasks';
import { Group } from 'STORE/Groups/Group';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

type TFilterHandler = (message: ChatMessage) => boolean;

export class SharedMessages {
  group: Group;

  constructor(group: Group) {
    this.group = group;
  }

  getMessageById(id: string): ChatMessage | null {
    return this.group.messagesStore.getMessageById(id);
  }

  getFilteredMessages(filterFunction: TFilterHandler): ChatMessage[] {
    return this.group.messagesStore.messages
      .filter(filterFunction)
      .sort((a: ChatMessage, b: ChatMessage) => b.serverTime - a.serverTime);
  }

  @action
  addMessages(messages: ChatMessage[]): void {
    messages.forEach((message) => {
      Tasks.messaging.updateOldOrFakeMessages({
        messages: [message],
        groupId: this.group.id,
      });
    });
  }
}
