import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { ICallAvatar } from 'STORE/Calls/Call/Call.types';
import { Avatar } from 'UIKIT/Avatar/Avatar';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';

import styles from './MediumCallAvatar.styles.m.css';

interface IMediumCallAvatarProps {
  avatar: ICallAvatar;
}

export const MediumCallAvatar: FC<IMediumCallAvatarProps> = observer(({ avatar }) => {
  return (
    <Avatar
      className={styles.avatar}
      title={avatar.title || ''}
      url={avatar.url}
      backgroundColorIndex={avatar.colorIndex || '0'}
      type={AvatarTypes.Round_10}
    />
  );
});
