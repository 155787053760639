import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Tasks from 'APP/Tasks';
import { PERMISSIONS_DEFAULT_TRANSLATIONS } from 'APP/constants/calls';
import { PermissionsValue } from 'APP/model/callPermissions/callPermissionsModel.constants';
import { useTranslation } from 'APP/packages/translations';
import routerConst from 'APP/router/constants';
import useClearGetParameters from 'APP/router/hooks/useClearGetParameters';
import useGetParameter from 'APP/router/hooks/useGetParameter';
import Entities from 'STORE';

export const usePresenter = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const clearGetParameters = useClearGetParameters();
  const permissionType = useGetParameter(routerConst.params.permissionType);

  const presenter = useLocalStore(() => ({
    permissionValue: null,

    onClose: () => {
      clearGetParameters();
    },

    goBack: () => {
      history.goBack();
    },

    get ongoingCall() {
      return Entities.Calls.ongoingCall;
    },

    get defaultPermissions() {
      return presenter.ongoingCall?.permissions.defaultPermissions;
    },

    get channelId() {
      return presenter.ongoingCall?.channelId;
    },

    setValue: (value) => {
      if (value) {
        presenter.permissionValue = value;
      }
    },

    get permissionValues() {
      return Object.values(PermissionsValue).map((item) => ({
        key: item,
        title: t(PERMISSIONS_DEFAULT_TRANSLATIONS[item]),
        isActive: item === presenter.permissionValue,
        onChange: () => presenter.setValue(item),
      }));
    },

    get permissionType() {
      return permissionType;
    },
  }));

  useEffect(() => {
    if (!presenter.ongoingCall || !presenter.ongoingCall?.isStarted) {
      presenter.onClose();
    }
  }, [presenter.ongoingCall?.isStarted]);

  useEffect(() => {
    presenter.setValue(presenter.defaultPermissions[permissionType]);

    return () => {
      if (presenter.ongoingCall && presenter.defaultPermissions) {
        const newPermissions = {
          ...presenter.defaultPermissions,
          [permissionType]: presenter.permissionValue,
        };

        Tasks.calls.setCallPermissionsForAll(presenter.channelId, newPermissions);
      }
    };
  }, []);

  return presenter;
};
