import { observer } from 'mobx-react';
import React from 'react';

import { getHref } from 'APP/utils/url';

import { IRenderer } from '../renderTree.types';
import { useDeepLinkRendererPresenter } from './DeepLinkRenderer.presenter';

interface IDeepLinkRendererProps {
  link: string;
  groupId: string;
}

export const DeepLinkRenderer: IRenderer<IDeepLinkRendererProps> = observer((props) => {
  const { children, options } = props;
  const presenter = useDeepLinkRendererPresenter(options);

  const href = getHref(options.link);

  if (!children) {
    return null;
  }

  return (
    <a
      href={href}
      target="_self"
      rel="noreferrer"
      onClick={presenter.onClick}
    >
      {children}
    </a>
  );
});
