import { useLocalStore, useAsObservableSource } from 'mobx-react';

import { CatalogType } from 'APP/model/catalog/catalogModel.types';
import Entities from 'STORE';

import { TTypeChangeHandler, TCategoryChangeHandler } from '../../Catalog.types';

interface ICatalogTypesPresenter {
  onCategoryChange: TCategoryChangeHandler;
  onTypeChange: TTypeChangeHandler;
}

interface ICatalogTypesLocalStore {
  catalogType: CatalogType;
  isActiveChannels: boolean;
  isActiveSpaces: boolean;
  isActiveBots: boolean;
  onClick(type: CatalogType): void;
}

export const useCatalogTypesPresenter = (data: ICatalogTypesPresenter) => {
  const source = useAsObservableSource({
    onCategoryChange: data.onCategoryChange,
    onTypeChange: data.onTypeChange,
  });

  const presenter = useLocalStore<ICatalogTypesLocalStore>(() => ({
    get catalogType(): CatalogType {
      return Entities.catalogStore.type;
    },

    get isActiveChannels(): boolean {
      return presenter.catalogType === CatalogType.Channel;
    },

    get isActiveSpaces(): boolean {
      return presenter.catalogType === CatalogType.Space;
    },

    get isActiveBots(): boolean {
      return presenter.catalogType === CatalogType.Bot;
    },

    onClick(type: CatalogType): void {
      if (type !== presenter.catalogType) {
        source.onTypeChange(type);
      } else {
        source.onTypeChange(CatalogType.All);
      }

      if (type === CatalogType.Bot) {
        source.onCategoryChange(null);
      }
    },
  }));

  return presenter;
};
