import classNames from 'classnames';
import React, { memo, useCallback } from 'react';

import { IconView } from 'APP/packages/UiKit/IconView/IconView';
import DoneIcon from 'ICONS/ic-done.svg';

import styles from './styles.m.css';

const SelectableItem = ({
  className,
  children,
  dataTest = undefined,
  isActive = false,
  isDisabled = false,
  onSelect,
}) => {
  const classes = classNames(styles.container, {
    [styles.active]: isActive,
    [styles.disabled]: isDisabled,
    [className]: true,
  });

  const onClick = useCallback(() => {
    if (onSelect) {
      onSelect(!isActive);
    }
  }, [isActive, onSelect]);

  return (
    <button
      className={classes}
      disabled={isDisabled}
      data-test={dataTest}
      onClick={onClick}
    >
      <div className={styles.content}>{children}</div>
      {isActive && (
        <IconView
          Icon={DoneIcon}
          className={styles.icon}
          size="medium"
        />
      )}
    </button>
  );
};

export default memo(SelectableItem);
