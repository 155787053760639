import { Message } from 'APP/packages/api/types/model/message';
import { SendNewMessageRequest } from 'APP/packages/api/types/model/sendNewMessageRequest';

import { IMessage, ISendNewMessageRequest } from './messageModel.types';

export function mapMessageToImessage(message: Message): IMessage {
  return {
    ...message,
  };
}

export function mapSendNewMessageRequest(data: ISendNewMessageRequest): SendNewMessageRequest {
  return {
    ...data,
    clientTime: String(data.clientTime),
  };
}
