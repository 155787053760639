import React from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'APP/packages/translations';
import routerConst from 'APP/router/constants';
import useGetLink from 'APP/router/hooks/useGetLink';
import IcAddUser from 'ICONS/ic-add-user.svg';
import { IconView } from 'UIKIT/IconView/IconView';

import styles from './styles.m.css';

export const CallAddCustomPermissionsLink = () => {
  const { t } = useTranslation();

  const linkToSelectMember = useGetLink({
    query: {
      [routerConst.params.popup]: routerConst.paramsEnum.popup.callSelectMember,
    },
  });

  return (
    <Link
      className={styles.link}
      to={linkToSelectMember}
    >
      <IconView
        Icon={IcAddUser}
        className={styles.icon}
      />
      {t('call_add_custom_permissions')}
    </Link>
  );
};
