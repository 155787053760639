import classNames from 'classnames';
import React from 'react';

import { IMAGE_TYPE } from 'APP/constants';
import { IconView } from 'APP/packages/UiKit/IconView/IconView';
import CameraIcon from 'ICONS/camera.svg';

import styles from './styles.m.css';

const NoImage = ({ type = IMAGE_TYPE.ROUND }) => {
  const imageClassName = classNames(styles.image, {
    [styles.round]: type === IMAGE_TYPE.ROUND,
    [styles.square]: type === IMAGE_TYPE.SQUARE,
  });

  return (
    <div className={imageClassName}>
      <IconView
        Icon={CameraIcon}
        size="big"
        className={styles.cameraIcon}
      />
    </div>
  );
};

export default NoImage;
