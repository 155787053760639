import { observer } from 'mobx-react';
import React from 'react';

import TextRenderer from 'APP/components/TextRendererCollapsed';

import { PostReactions } from '../components/PostReactions/PostReactions';
import Translate from '../components/Translate';
import { usePostAlbumPresenter } from './PostAlbum.presenter';
import styles from './PostAlbum.styles.m.css';

export const PostAlbum = observer(({ message, isPreview, isView }) => {
  const presenter = usePostAlbumPresenter({ message });

  return (
    <>
      <div className={styles.album}>
        {presenter.layout.map((row) => (
          <div
            key={row.id}
            className={styles.row}
          >
            {row.items.map((item) => {
              const payload = presenter.getPayload(item.id);
              const Component = presenter.getPayloadComponent(payload.payload.payloadType);

              if (!Component) {
                return null;
              }

              return (
                <div
                  key={item.id}
                  className={styles.itemContainer}
                  style={{ width: `${item.containerWidth}%` }}
                >
                  <div style={{ paddingBottom: `${item.containerRatio * 100}%` }} />
                  <Component
                    mediaId={payload.id}
                    payload={payload.payload}
                    isPreview={isPreview}
                    isView={isView}
                    onClick={presenter.onOpenMediaGallery}
                    groupId={message.groupId}
                  />
                </div>
              );
            })}
          </div>
        ))}
      </div>

      <div className={styles.content}>
        {presenter.isShowComment && (
          <>
            <TextRenderer
              className={styles.textWrapper}
              textClassName={styles.text}
              keyWords={message.payload.keyWords}
              userMentions={presenter.userMentions}
              groupId={message.groupId}
              rows={4}
            >
              {presenter.comment}
            </TextRenderer>
            <Translate message={message} />
          </>
        )}

        <PostReactions
          message={message}
          isVisible={isView}
        />
      </div>
    </>
  );
});
