import React, { memo } from 'react';
import { FormSpy } from 'react-final-form';

import TextFieldWithPrefix from '../TextFieldWithPrefix';
import styles from './styles.m.css';

const INPUT_LABEL = 'links.gemspace.com/join/';
const INPUT_MAX_LENGTH = 35;

const DeepLinkAliasField = ({
  title,
  description,
  label = INPUT_LABEL,
  maxLength = INPUT_MAX_LENGTH,
  placeholder,
  validatingText,
  successText,
  isShowLink,
  input = undefined,
  meta = undefined,
}) => {
  return (
    <FormSpy
      subscription={{
        values: true,
      }}
    >
      {({ values }) => {
        if (isShowLink && !isShowLink(values)) {
          return null;
        }

        return (
          <div className={styles.container}>
            <p className={styles.title}>{title}</p>
            <p className={styles.description}>{description}</p>
            <TextFieldWithPrefix
              label={label}
              placeholder={placeholder}
              component={TextFieldWithPrefix}
              maxLength={maxLength}
              validatingText={validatingText}
              successText={successText}
              input={input}
              meta={meta}
            />
          </div>
        );
      }}
    </FormSpy>
  );
};

export default memo(DeepLinkAliasField);
