import { computed } from 'mobx';

import { now } from 'APP/packages/date/helpers/now';
import Entities from 'APP/store';

export class BackgroundStream {
  groupId;
  channelId;
  status;
  startTs;

  constructor(groupId, channelId, status, startTs = now().valueOf()) {
    this.groupId = groupId;
    this.channelId = channelId;
    this.status = status;
    this.startTs = startTs;
  }

  @computed
  get group() {
    return Entities.GroupsStore.getGroupById(this.groupId);
  }
}
