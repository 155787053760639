import { useAsObservableSource, useLocalStore } from 'mobx-react';

import { useTranslation } from 'APP/packages/translations';
import { Group } from 'APP/store/Groups/Group';
import { ChatMessage } from 'APP/store/Messages/Message/ChatMessage/ChatMessage';
import { Message } from 'APP/store/Messages/Message/Message';

interface IGroupDescriptionPresenter {
  lastMessage: ChatMessage | Message | null;
  sender: string | null;
  isShowDraft: boolean;
  draftText: string;
  payload: ChatMessage['payload'] | Message['payload'] | null;
}

export const useGroupDescriptionPresenter = (group: Group): IGroupDescriptionPresenter => {
  const { t } = useTranslation();
  const source = useAsObservableSource({ group });

  const presenter = useLocalStore<IGroupDescriptionPresenter>(() => ({
    get lastMessage(): ChatMessage | Message | null {
      return source.group.lastMessage;
    },

    get payload(): ChatMessage['payload'] | Message['payload'] | null {
      if (presenter.lastMessage instanceof ChatMessage) {
        return presenter.lastMessage.renderPayload;
      }

      if (presenter.lastMessage instanceof Message) {
        return presenter.lastMessage.payload;
      }

      return null;
    },

    get sender(): string | null {
      if (!presenter.lastMessage || !source.group.isChatGroup) {
        return null;
      }

      return presenter.lastMessage.senderUser && presenter.lastMessage.senderUser.isMe
        ? t('chat_details_myself')
        : presenter.lastMessage.lastMessageSenderName;
    },
    get isShowDraft(): boolean {
      return source.group.isShowDraft;
    },

    get draftText(): string {
      return source.group.draftInput?.text || '';
    },
  }));

  return presenter;
};
