import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

import { SpaceAvatar } from 'APP/components/Avatar/SpaceAvatar/SpaceAvatar';
import { VerifiedBadge } from 'APP/components/VerifiedBadge/VerifiedBadge';
import { useTranslation } from 'APP/packages/translations';
import { RouterPage } from 'APP/router/constants';
import { Space } from 'STORE/Spaces/Space';
import { getFormatNumberToString } from 'UTILS/getFormatNumberToString';

import { ISearchResultItemProps } from '../SearchResultItem.types';
import styles from './SearchResultSpace.styles.m.css';

export const SearchResultSpace: FC<ISearchResultItemProps<Space>> = observer(
  ({ entity: space, className, activeClassName }) => {
    const { t } = useTranslation();
    const spaceLink = `/${RouterPage.Spaces}/${space.id}`;

    return (
      <NavLink
        className={className}
        activeClassName={activeClassName}
        to={spaceLink}
      >
        <SpaceAvatar
          className={styles.avatar}
          space={space}
          isShowLockIcon={false}
        />

        <div className={styles.info}>
          <div className={styles.titleWrapper}>
            <h3 className={styles.name}>{space.name}</h3>
            {space.verified && <VerifiedBadge />}
          </div>

          <span className={styles.members}>
            {t('space_members_count').replace(
              '{0}',
              getFormatNumberToString(space.participantsCount)
            )}
          </span>
        </div>
      </NavLink>
    );
  }
);
