import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { VerifiedBadge } from 'APP/components/VerifiedBadge/VerifiedBadge';
import PopupInfoCard from 'APP/packages/UiKit/PopupInfoCard';
import popupItemStyles from 'APP/packages/UiKit/PopupItem/styles.m.scss';
import UnionIcon from 'ICONS/union.svg';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';

import usePresenter from './presenter';
import styles from './style.m.scss';

const UserWithCheckbox = ({ getIsSelected, toggleUser, user, canSelectMore }) => {
  const { userOnlineStatusText } = usePresenter({ user });

  const classes = classNames(popupItemStyles.container, popupItemStyles.interactive);

  const isSelected = getIsSelected(user);

  const checkboxClass = classNames(styles.checkboxView, {
    [styles.selected]: isSelected,
  });

  return (
    <label className={classes}>
      <PopupInfoCard
        type={AvatarTypes.Round_10}
        className={popupItemStyles.card}
        avatarTitle={user.avatarTitle || user.name}
        avatarUrl={user.avatarUrl || user.avatar || user.image}
        avatarBackgroundColorIndex={user.avatarColorIndex}
        title={user.displayName}
        subtitle={userOnlineStatusText}
        badge={user.verified && <VerifiedBadge />}
      />
      <div className={checkboxClass}>
        <input
          type="checkbox"
          className={styles.checkbox}
          onChange={() => toggleUser(user)}
          value={isSelected}
          disabled={!canSelectMore && !isSelected}
        />
        {isSelected && <UnionIcon />}
      </div>
    </label>
  );
};

export default observer(UserWithCheckbox);
