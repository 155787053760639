import classNames from 'classnames';
import React, { memo } from 'react';
import { InView } from 'react-intersection-observer';

import PopupInfoCard from 'APP/packages/UiKit/PopupInfoCard';
import popupItemStyles from 'APP/packages/UiKit/PopupItem/styles.m.scss';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';

import styles from './Command.styles.m.css';

const Command = ({ index, command, bot, onSelect, isFocused, onHover, onChangeViewState }) => {
  const classes = classNames(popupItemStyles.smallContainer, {
    [popupItemStyles.active]: isFocused,
  });

  const onClick = () => {
    onSelect(command);
  };

  const onMouseEnter = () => {
    onHover(index);
  };

  const onChangeInView = (inView) => {
    onChangeViewState(index, inView);
  };

  return (
    <InView
      as="button"
      className={classes}
      onChange={onChangeInView}
      threshold={1}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
    >
      <div className={styles.container}>
        <PopupInfoCard
          className={styles.infoCard}
          type={AvatarTypes.Round_0}
          avatarTitle={command.command}
          avatarUrl={bot.avatarUrl}
          avatarBackgroundColorIndex={bot.id[bot.id.length - 1]}
          title={command.command}
          subtitle={command.description}
        />
      </div>
    </InView>
  );
};

export default memo(Command);
