import { stickersModel } from 'APP/model/model';
import { IStickerPackExtend } from 'APP/model/stickers/stickersModel.types';
import Entities from 'APP/store';

export async function loadStickerPackById(stickerPackId: string): Promise<IStickerPackExtend> {
  const result = await stickersModel.getStickerPackById({ stickerPackId });

  if (result && result.stickerPack) {
    Entities.stickersStore.mergePacks([result]);
  }

  return result;
}
