import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Tasks from 'APP/Tasks';
import { ApplicationTheme } from 'APP/constants/appearance';
import { UnauthorizedCallType } from 'APP/model/unauthorizedCall/unauthorizedCallModel.constants';
import { ICallInvitationInfo } from 'APP/model/unauthorizedCall/unauthorizedCallModel.types';
import { RouterLink } from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import Entities from 'STORE';

interface ICallInvitationPresenter {
  currentTheme: ApplicationTheme | null;
  invitationInfo: ICallInvitationInfo | null;
  checkInvitationInfo(): void;
  isGroupCall: boolean;
}

export const useCallInvitationPresenter = (): ICallInvitationPresenter => {
  const params = useParams<{ id: string; callType: UnauthorizedCallType; channelId?: string }>();
  const navigateTo = useNavigateTo();

  const presenter = useLocalStore<ICallInvitationPresenter>(() => ({
    get currentTheme(): ApplicationTheme | null {
      return Entities.appearance.theme;
    },

    get invitationInfo(): ICallInvitationInfo | null {
      return Entities.callInvitation.invitationInfo || null;
    },

    get isGroupCall(): boolean {
      return params.callType === UnauthorizedCallType.Call;
    },

    async checkInvitationInfo(): Promise<void> {
      if (presenter.invitationInfo) {
        return;
      }
      Entities.callInvitation.setEntityId(params.id);
      Entities.callInvitation.setCallType(params.callType);
      Entities.callInvitation.setChannelId(params.channelId || null);

      const { isLinkExpired } = await Tasks.calls.getCallInfoViaLink({
        entityId: params.id,
        deepLinkType: params.callType,
        channelId: params.channelId,
      });

      if (isLinkExpired) {
        navigateTo({
          to: RouterLink.linkExpired,
        });
      }
    },
  }));

  useEffect(() => {
    presenter.checkInvitationInfo();
  }, []);

  return presenter;
};
