interface IWsConfig {
  apiUrl?: string;
  token?: string | null;
  version?: string;
}

export const websocketConfig: IWsConfig = {
  apiUrl: '',
  token: null,
  version: 'v3',
};

export function setWebsocketConfig({ apiUrl, token }: IWsConfig): void {
  if (apiUrl !== undefined) {
    websocketConfig.apiUrl = apiUrl;
  }

  if (token !== undefined) {
    websocketConfig.token = token;
  }
}
