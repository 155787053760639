import { useLocalStore } from 'mobx-react';

import {
  PERMISSIONS_DEFAULT_TRANSLATIONS,
  PERMISSIONS_TYPES_TRANSLATIONS,
} from 'APP/constants/calls';
import routerConst from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import Entities from 'STORE';

export const usePresenter = ({ permissionType }) => {
  const navigateTo = useNavigateTo();

  const presenter = useLocalStore(() => ({
    get permissions() {
      const callPermissions = Entities.Calls.ongoingCall?.permissions;

      return callPermissions.defaultPermissions;
    },

    get permissionLabel() {
      return PERMISSIONS_TYPES_TRANSLATIONS[permissionType];
    },

    get permissionValue() {
      if (!presenter.permissions) {
        return null;
      }

      const permissionsValue = presenter.permissions[permissionType];

      return PERMISSIONS_DEFAULT_TRANSLATIONS[permissionsValue];
    },

    onPermissionClick: () => {
      if (!presenter.permissionValue) {
        return;
      }

      const paramsPopup = routerConst.paramsEnum.popup.callChangeDefaultPermission;

      const query = {
        [routerConst.params.popup]: paramsPopup,
        [routerConst.params.permissionType]: permissionType,
      };

      navigateTo({
        query,
      });
    },
  }));

  return presenter;
};
