import Tasks from 'APP/Tasks';
import { storage } from 'APP/packages/storage';
import Entities from 'STORE';

export function toggleUserNotifications(): void {
  const newValue = !Entities.pushNotifications.isEnabled;

  storage.isPushNotificationsEnabled.set(Entities.UsersStore.Me.id, newValue);

  Entities.pushNotifications.setEnabled(newValue);

  if (newValue) {
    Tasks.pushNotifications.subscribeNotifications();
  } else {
    Tasks.pushNotifications.unsubscribeNotifications();
  }
}
