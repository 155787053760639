export default ({ symbolsPositions }) => {
  const symbols = Object.keys(symbolsPositions);
  const listOfTags = {};

  for (let i = 0; i < symbols.length; i++) {
    const symbol = symbols[i];
    const tagsData = symbolsPositions[symbol];

    for (let j = 0; j < tagsData.length; j++) {
      const tagData = tagsData[j];

      listOfTags[tagData.start] = {
        ...tagData,
        tag: symbol,
      };
    }
  }

  return listOfTags;
};
