import Entities from 'APP/store';

export default (data) => {
  const updateTs = parseInt(data.updateTs, 10);

  if (updateTs < Entities.PrivacySettings.updateTs) {
    return;
  }

  Entities.PrivacySettings.setUpdateTs(updateTs);
  Entities.PrivacySettings.setSettings(data);
};
