import Entities from 'STORE';

import { GroupsEvent } from '../GroupsEvent';

export class SpaceVisibilityUpdated extends GroupsEvent {
  async process() {
    const { groupId, visibleOutsideSpace } = this.eventPayload;

    const group = Entities.GroupsStore.getGroupById(groupId);
    if (!group || !group.space) {
      return false;
    }

    group.space.groupVisibility.setGroupVisible(groupId, visibleOutsideSpace);
  }
}
