import api from 'APP/packages/api';
import logger from 'APP/packages/logger';
import Entities from 'STORE';

export const setUserPreferences = async (
  categoryIds?: string[],
  locales?: string[]
): Promise<boolean> => {
  const newCategoryIds = categoryIds || Entities.userPreferences.categoryIds;
  const newLocales = locales || Entities.userPreferences.localeIds;

  try {
    await api.catalog.setUserPreferences({
      categoryIds: newCategoryIds,
      locales: newLocales,
    });

    Entities.userPreferences.setCategoryIds(newCategoryIds);
    Entities.userPreferences.setLocaleIds(newLocales);

    return true;
  } catch (error) {
    logger.get('API').error('catalog.setUserPreferences', error);
    return false;
  }
};
