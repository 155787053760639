import api from 'APP/packages/api';

const MENTIONS_LIMIT = 15;

export default async ({ inputPanel, filter, cursor = null }) => {
  const response = await api.search.searchUsersForMention({
    cursor,
    groupId: inputPanel.groupId,
    limit: MENTIONS_LIMIT,
    prefix: filter,
  });

  const newCursor =
    response.usersForMention.length === 0 || response.cursor === inputPanel.mention.cursor
      ? null
      : response.cursor;

  return {
    ...response,
    cursor: newCursor,
  };
};
