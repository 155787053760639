/* global Date */
import isValidDate from './isValidDate';

export default (rawDate1, rawDate2) => {
  const date1 = new Date(rawDate1);
  const date2 = new Date(rawDate2);

  if (!isValidDate(date1) || !isValidDate(date2)) {
    return -1;
  }

  date1.setHours(0, 0, 0, 0);
  date2.setHours(0, 0, 0, 0);

  const diff = Math.abs(date1.getTime() - date2.getTime());
  return Math.floor(diff / 1000 / 60 / 60 / 24);
};
