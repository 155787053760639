import { ApplicationTheme } from 'APP/constants/appearance';

import { localStorageInstance } from '../instances/localStorageInstance';

const KEY_NAME = 'theme';

export const set = (theme: ApplicationTheme): void => {
  return localStorageInstance.setItem(KEY_NAME, theme);
};

export const get = (): ApplicationTheme | null => {
  const theme: ApplicationTheme | null = localStorageInstance.getItem(KEY_NAME);
  return theme && Object.values(ApplicationTheme).includes(theme) ? theme : null;
};

export const remove = (): void => {
  localStorageInstance.removeItem(KEY_NAME);
};
