import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { IInvitee } from 'APP/model/scheduledEvent/scheduledEventModel.types';
import { useTranslation } from 'APP/packages/translations';
import { Avatar, AvatarTypes } from 'UIKIT/Avatar/Avatar';

import styles from './ScheduledCallsInviteeItem.styles.m.css';

interface IScheduledCallsInviteeItemProps {
  invitee: IInvitee;
  initiatorId?: string;
}

export const ScheduledCallsInviteeItem: FC<IScheduledCallsInviteeItemProps> = observer(
  ({ invitee, initiatorId }) => {
    const { t } = useTranslation();

    const initiatorLabel = invitee.user.id === initiatorId ? ` (${t('call_initiator')})` : '';

    return (
      <div className={styles.container}>
        <Avatar
          className={styles.avatar}
          type={AvatarTypes.Round_0}
          title={`${invitee.user.userName}${initiatorLabel}`}
          url={invitee.user.avatarUrl}
        />
        <div className={styles.groupName}>{`${invitee.user.userName}${initiatorLabel}`}</div>
      </div>
    );
  }
);
