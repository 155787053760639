import { observer } from 'mobx-react';
import React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { PayloadType } from 'APP/model/message/messageModel.types';

import { useArticleRedactorPresenter } from './ArticleRedactor.presenter';
import styles from './ArticleRedactor.styles.m.scss';
import { PayloadCreator } from './PayloadCreator/PayloadCreator';
import PayloadWrapper from './PayloadWrapper';
import { Audio } from './Payloads/Audio';
import File from './Payloads/File';
import Image from './Payloads/Image';
import LoadImageByLink from './Payloads/LoadImageByLink';
import Location from './Payloads/Location';
import { TextPayload } from './Payloads/TextPayload/TextPayload';
import Video from './Payloads/Video';
import Title from './Title';

const payloadComponents = {
  [PayloadType.File]: File,
  [PayloadType.Image]: Image,
  [PayloadType.LoadImageByLink]: LoadImageByLink,
  [PayloadType.AudioMessage]: Audio,
  [PayloadType.Video]: Video,
  [PayloadType.Location]: Location,
  [PayloadType.RichText]: TextPayload,
};

export const ArticleRedactor = observer(({ core }) => {
  const presenter = useArticleRedactorPresenter({ core });

  return (
    <div>
      <Title parentPresenter={presenter} />
      {presenter.payloads.length ? (
        <DragDropContext
          onDragEnd={presenter.onDragEnd}
          onDragStart={presenter.onDragStart}
        >
          <Droppable droppableId="droppable">
            {(provided) => {
              return (
                <div
                  className={styles.payloads}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  {presenter.payloads.map(({ data: payload, order, id, isDragging }) => {
                    const PayloadComponent = payloadComponents[payload.payloadType] || null;

                    if (!PayloadComponent) {
                      return null;
                    }

                    return (
                      <PayloadWrapper
                        key={id}
                        dragKey={id}
                        parentPresenter={presenter}
                        order={order}
                        payload={payload}
                        isDragging={isDragging}
                      >
                        <PayloadComponent
                          parentPresenter={presenter}
                          payload={payload}
                          order={order}
                          id={id}
                        />
                      </PayloadWrapper>
                    );
                  })}
                  {provided.placeholder}
                </div>
              );
            }}
          </Droppable>
        </DragDropContext>
      ) : null}
      <PayloadCreator parentPresenter={presenter} />
    </div>
  );
});
