import { useLocalStore } from 'mobx-react';
import { useEffect, useRef } from 'react';

export default ({ rows }) => {
  const ref = useRef();

  const presenter = useLocalStore(() => ({
    isCollapsed: true,

    init() {
      if (rows <= 0) {
        presenter.isCollapsed = false;
        return;
      }

      if (ref.current && ref.current?.childNodes?.length > 0) {
        presenter.isCollapsed = ref.current.offsetHeight < ref.current.childNodes[0].offsetHeight;
      } else {
        presenter.isCollapsed = false;
      }
    },

    get style() {
      if (presenter.isCollapsed) {
        return { '--rows': rows };
      } else {
        return {
          WebkitLineClamp: 'unset',
          maxHeight: 'none',
        };
      }
    },

    onOpen() {
      presenter.isCollapsed = false;
    },
  }));

  useEffect(() => {
    presenter.init();
  }, []);

  return { presenter, ref };
};
