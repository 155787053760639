import { FinishCallReason } from 'APP/model/call/callModel.constants';
import { CallProviderType } from 'APP/model/call/callModel.types';
import { callModel } from 'APP/model/model';
import Entities from 'APP/store';

interface IDeclineData {
  groupId: string;
  channelId: string;
  providerType: CallProviderType;
}

export const decline = async ({
  groupId,
  channelId,
  providerType,
}: IDeclineData): Promise<void> => {
  try {
    Entities.IncomingCalls.delete(groupId, channelId);
    await callModel.finishCall(channelId, providerType, FinishCallReason.Declined);
  } catch (e) {
    console.error(e);
  }
};
