import { CallEventTypes } from 'APP/constants/calls';
import { initCallListener } from 'APP/packages/streamApi';
import Entities from 'STORE';
import { IncomingCall } from 'STORE/IncomingCalls/IncomingCall/IncomingCall';

const getStream = (
  channelId: string,
  onUserCallData: (data: any) => void,
  onActiveCallData: (data: any) => void
): (() => void) | null =>
  initCallListener({
    channelId: channelId,
    onUserCallData,
    onActiveCallData,
  });

export const callStreamService = (call: IncomingCall): (() => void) => {
  const onUserCallData = (data: any): void => {
    const userId = data.userId.toString();
    if (
      userId === Entities.UsersStore.Me.id &&
      (data.status === CallEventTypes.Joined || data.status === CallEventTypes.Declined)
    ) {
      Entities.IncomingCalls.delete(call.groupId, call.channelId);
    }
  };

  const onActiveCallData = (data: any): void => {
    if (data.status === CallEventTypes.Finished) {
      Entities.IncomingCalls.delete(call.groupId, call.channelId);
    }
  };

  const disposeCallStream = getStream(call.channelId, onUserCallData, onActiveCallData);

  return () => {
    if (disposeCallStream) {
      disposeCallStream();
    }
  };
};
