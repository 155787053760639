import classNames from 'classnames';
import React from 'react';

import { useMediaGalleryPreviewPresenter } from './MediaGalleryPreviewItem.presenter';
import styles from './MediaGalleryPreviewItem.styles.m.css';

export const MediaGalleryPreviewItem = ({ file, selected }) => {
  const { previewUrl, onSelectFile } = useMediaGalleryPreviewPresenter(file);

  return (
    <div
      className={classNames(styles.container, {
        [styles.selected]: selected,
      })}
      onClick={!selected ? onSelectFile : undefined}
    >
      <img
        className={styles.image}
        src={previewUrl}
        alt=""
      />
      <div className={styles.overlay}></div>
    </div>
  );
};
