import { useLocalStore } from 'mobx-react';
import { useLayoutEffect, useRef } from 'react';

import Tasks from 'APP/Tasks';
import Entities from 'STORE';

import { IDetailedFeedbackStore } from './DetailedFeedback.types';

export const useDetailedFeedbackPresenter = () => {
  const inputRef = useRef<HTMLTextAreaElement | null>(null);

  const presenter = useLocalStore<IDetailedFeedbackStore>(() => ({
    activeIssueType: undefined,
    activeProblemType: undefined,
    descriptionValue: '',

    get isShown() {
      return Entities.feedback.isDescriptionShown;
    },

    get inputRef() {
      return inputRef;
    },

    resetLocalValues: () => {
      presenter.activeIssueType = undefined;
      presenter.activeProblemType = undefined;
      presenter.descriptionValue = '';
    },

    onClose: () => {
      Entities.feedback.reset();
      presenter.resetLocalValues();
    },

    onDescriptionChange(value) {
      presenter.descriptionValue = value;
    },

    onIssueTypeButtonClick: (issueType) => {
      if (presenter.activeIssueType === issueType) {
        presenter.activeIssueType = undefined;
      } else {
        presenter.activeIssueType = issueType;
      }
      presenter.activeProblemType = undefined;
    },

    onProblemTypeButtonClick: (problemType) => {
      if (presenter.activeProblemType === problemType) {
        presenter.activeProblemType = undefined;
      } else {
        presenter.activeProblemType = problemType;
      }
    },

    get channelId() {
      return Entities.feedback.channelId;
    },

    get starsRate() {
      return Entities.feedback.starsRate;
    },

    onSend: () => {
      if (presenter.starsRate && presenter.channelId) {
        Tasks.feedback.sendFeedback({
          rating: String(presenter.starsRate),
          channelId: presenter.channelId,
          problem: presenter.activeProblemType,
          problemDescription: presenter.descriptionValue,
        });
      }

      Entities.feedback.reset();
      presenter.resetLocalValues();
    },
  }));

  // TODO: Copy-past from MediaMessage/presenter/index. Move to MultiLineField
  useLayoutEffect(() => {
    if (presenter.inputRef.current) {
      const ref = presenter.inputRef.current;
      const len = ref.value.length;
      ref.focus();
      ref.setSelectionRange(len, len);
    }
  }, []);

  useLayoutEffect(() => {
    const ref = presenter.inputRef.current;
    if (ref) {
      ref.style.height = 'auto';
      ref.style.height = `${ref.scrollHeight}px`;
    }
  }, [presenter.descriptionValue]);

  return presenter;
};
