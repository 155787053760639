import { SyntheticEvent, useEffect, useState } from 'react';

import {
  IImageResizerLocalStore,
  useImageResizer,
} from 'APP/main/hooks/useImageResizer/useImageResizer';
import { IGraphicSize } from 'APP/model/common/commonModel.types';

interface IImagePreviewPresenter {
  url: string;
  graphicSize: IGraphicSize;
  maxWidth?: number;
  maxHeight?: number;
  placeholderQuality?: number;
  needCropImage?: boolean;
  isPreview?: boolean;
  isView?: boolean;
  isLocal?: boolean;
}

interface IImagePreviewLocalStore {
  isShow: boolean;
  hasPlaceholder: boolean;
  resizedImage: IImageResizerLocalStore;
  placeholderUrl: string;
  imageUrl: string;
  onLoad(event: SyntheticEvent): void;
  onLoadError(): void;
}

const DEFAULT_IMAGE_MAX_SIZE = { width: 1280, height: 720 };

export const useImagePreviewPresenter = (data: IImagePreviewPresenter): IImagePreviewLocalStore => {
  const {
    url,
    graphicSize,
    maxWidth = DEFAULT_IMAGE_MAX_SIZE.width,
    maxHeight = DEFAULT_IMAGE_MAX_SIZE.height,
    placeholderQuality = 0.5,
    needCropImage = false,
    isPreview = true,
    isView = true,
    isLocal = false,
  } = data;

  const [isPreviewed, setIsPreviewed] = useState(isPreview);
  const [isViewed, setIsViewed] = useState(isView);
  const [isLoadError, setIsLoadError] = useState(false);

  const resizedImage = useImageResizer({
    url,
    maxWidth,
    maxHeight,
    placeholderQuality,
    needCropImage,
    graphicSize: {
      width: graphicSize.width || maxWidth,
      height: graphicSize.height || maxHeight,
    },
  });

  const presenter: IImagePreviewLocalStore = {
    get isShow(): boolean {
      return !!resizedImage.url && !isLoadError;
    },

    get hasPlaceholder(): boolean {
      return Boolean(!isLocal && resizedImage.placeholderUrl);
    },

    get resizedImage(): IImageResizerLocalStore {
      return resizedImage;
    },

    get placeholderUrl(): string {
      return presenter.hasPlaceholder && isPreviewed ? resizedImage.placeholderUrl : '';
    },

    get imageUrl(): string {
      return isViewed ? resizedImage.url : '';
    },

    onLoad(event: SyntheticEvent): void {
      const target = event.target as Element;
      target.classList.add('loaded');
    },

    onLoadError(): void {
      if (isViewed) {
        setIsLoadError(true);
      }
    },
  };

  useEffect(() => {
    if (isPreview || isView || isLocal) {
      setIsPreviewed(true);
    }

    if (isView || isLocal) {
      setIsViewed(true);
    }
  }, [isPreview, isView, isLocal]);

  useEffect(() => {
    setIsLoadError(false);
  }, [presenter.imageUrl]);

  return presenter;
};
