import { mapBotUserDisplayDataResponse } from 'APP/model/user/userModel.mapper';
import { IBotUserDisplayData } from 'APP/model/user/userModel.types';
import api from 'APP/packages/api';
import logger from 'APP/packages/logger';

import { ICursorRequest } from '../common/commonModel.types';
import { buildModelError } from '../error/errorBuilder';
import { IAllBotsPage } from './botCatalogModel.types';

export const getAllBots = async (data: ICursorRequest): Promise<IAllBotsPage> => {
  try {
    const response = await api.botsCatalog.getAllBots({
      cursor: data.cursor || undefined,
      limit: data.limit,
    });
    return {
      bots: response.bots.map((bot) => mapBotUserDisplayDataResponse(bot)),
      cursor: response.cursor,
    };
  } catch (error) {
    logger.get('API').error('botsCatalog.getAllBots', error);
    throw buildModelError(error);
  }
};

export const addBotToProfile = async (botId: string): Promise<IBotUserDisplayData | null> => {
  try {
    const response = await api.botsCatalog.addBotToProfile({ botId });
    return response.personalizedGroup.botOpponent
      ? mapBotUserDisplayDataResponse(response.personalizedGroup.botOpponent)
      : null;
  } catch (error) {
    logger.get('API').error('botsCatalog.addBotToProfile', error);
    throw buildModelError(error);
  }
};

export const removeBotFromProfile = async (botId: string): Promise<IBotUserDisplayData> => {
  try {
    const response = await api.botsCatalog.removeBotFromProfile({ botId });
    return mapBotUserDisplayDataResponse(response);
  } catch (error) {
    logger.get('API').error('botsCatalog.addBotToProfile', error);
    throw buildModelError(error);
  }
};
