import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { showConfirm } from 'APP/Tasks/app/showConfirm/showConfirm';
import { ServerErrorCode } from 'APP/model/error/error.constants';
import Entities from 'STORE';

export default () => {
  const presenter = useLocalStore(() => ({
    didMount: async () => {
      await presenter.login();
    },

    login: async () => {
      const { isSuccess, error } = await Tasks.authorization.login();
      Entities.AuthStore.finishFirstLogin();
      if (!isSuccess && error?.message === ServerErrorCode.Unauthorized) {
        await Tasks.authorization.logout();
      }
    },

    async getUserConfirmation(message, callback) {
      const isCofirmed = await showConfirm({
        text: message,
      });

      callback(isCofirmed);
    },
  }));

  useEffect(() => {
    presenter.didMount();
  }, []);

  return presenter;
};
