import { useLocalStore } from 'mobx-react';

import Entities from 'APP/store';
import { debounce } from 'UTILS/debounce';

import { searchUsersAddToGroup } from './Tasks/searchUsersAddToGroup';

const MIN_SEARCH_LENGTH = 3;
const SEARCH_DEBOUNCE = 1000;
const OFFSET_STEP = 50;

export const useExtendedUsers = ({ myContacts, groupId }) => {
  const presenter = useLocalStore(() => ({
    offset: 0,
    hasMore: false,
    usersIds: [],
    isLoading: false,
    searchValue: '',

    get group() {
      return Entities.GroupsStore.getGroupById(groupId);
    },

    get users() {
      return presenter.usersIds
        .map((id) => Entities.UsersStore.getUserById(id))
        .filter((user) => {
          if (!user) {
            return false;
          }

          if (myContacts.contactsIds.includes(user.id)) {
            return false;
          }

          return true;
        })
        .sort((user1, user2) => {
          const user1HasGroup = user1?.group && user1?.group?.isP2P ? 1 : 0;
          const user2HasGroup = user2?.group && user2?.group?.isP2P ? 1 : 0;

          if (!user1HasGroup || !user2HasGroup) {
            return user2HasGroup - user1HasGroup;
          } else {
            return user2.group.lastMessageTs - user1.group.lastMessageTs;
          }
        });
    },

    get isShowExtendedUsers() {
      return presenter.isLoading || presenter.users.length > 0;
    },

    onSearchValueChange(value) {
      presenter.resetSearch();
      presenter.searchValue = value;

      if (value.length < MIN_SEARCH_LENGTH) {
        return;
      }

      presenter.isLoading = true;
      presenter.loadUsers(value);
    },

    resetSearch() {
      presenter.offset = 0;
      presenter.hasMore = false;
      presenter.usersIds = [];
      presenter.isLoading = false;
    },

    isNeedSearch(searchValue) {
      return presenter.searchValue === searchValue;
    },

    loadUsers: debounce(async (searchValue) => {
      // ignore if the search value was changed during debounce execution
      if (!presenter.isNeedSearch(searchValue)) {
        return;
      }

      const { usersIds, hasMore } = await searchUsersAddToGroup({
        searchValue,
        offset: 0,
        group: presenter.group,
      });

      // ignore if the search value was changed during the task execution
      if (!presenter.isNeedSearch(searchValue)) {
        return;
      }

      presenter.usersIds = usersIds;
      presenter.hasMore = hasMore;
      presenter.isLoading = false;
    }, SEARCH_DEBOUNCE),

    async loadMore() {
      if (!presenter.hasMore || presenter.isLoading) {
        return;
      }

      const offset = presenter.offset + OFFSET_STEP;
      const searchValue = presenter.searchValue;
      presenter.isLoading = true;

      const { usersIds, hasMore } = await searchUsersAddToGroup({
        searchValue,
        offset,
        group: presenter.group,
      });

      // ignore if the search value was changed during the task execution
      if (!presenter.isNeedSearch(searchValue)) {
        return;
      }

      presenter.usersIds = [...presenter.usersIds, ...usersIds];
      presenter.offset = offset;
      presenter.hasMore = hasMore;
      presenter.isLoading = false;
    },
  }));

  return presenter;
};
