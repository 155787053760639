import { PayloadType } from 'APP/model/message/messageModel.types';

import { PostAlbum } from './PostAlbum/PostAlbum';
import { PostArticle } from './PostArticle/PostArticle';
import { PostImage } from './PostImage/PostImage';
import { PostText } from './PostText/PostText';
import { PostVideo } from './PostVideo/PostVideo';

const payloadComponents = {
  [PayloadType.Image]: PostImage,
  [PayloadType.Album]: PostAlbum,
  [PayloadType.RichText]: PostText,
  [PayloadType.Video]: PostVideo,
  [PayloadType.Article]: PostArticle,
};

export const usePostMessagePresenter = ({ payloadType }) => {
  const presenter = {
    get payloadComponent() {
      return payloadComponents[payloadType] || null;
    },
  };

  return presenter;
};
