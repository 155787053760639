import React from 'react';

import Icon from 'ICONS/messages/ic-article-16.svg';

import { CommonPayload } from '../CommonPayload/CommonPayload';

export const ArticlePayload = ({ name, payload }) => {
  return (
    <CommonPayload
      Icon={Icon}
      name={name}
      description={payload?.title}
    />
  );
};
