import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { useTranslation } from 'APP/packages/translations';
import { SearchInput } from 'UIKIT/SearchInput/SearchInput';

import { useSharedDataViewPresenter, ISharedDataViewParams } from './ShareDataView.presenter';
import styles from './ShareDataView.styles.m.css';
import { SharedDataViewTabs } from './SharedDataViewTabs/SharedDataViewTabs';

type TShareDataViewProps = ISharedDataViewParams;

export const ShareDataView: FC<TShareDataViewProps> = observer((props) => {
  const { multiselect = false } = props;

  const { t } = useTranslation();
  const presenter = useSharedDataViewPresenter(props);

  return (
    <>
      <SearchInput
        className={styles.search}
        placeholder={t('main_menu_search_note')}
        onChange={presenter.onSearch}
        value={presenter.searchValue}
      />
      <SharedDataViewTabs
        searchValue={presenter.searchValue}
        selection={presenter.selection}
        multiselect={multiselect}
        onClickItem={presenter.onClickItem}
      />
    </>
  );
});
