import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ApplicationTheme } from 'APP/constants/appearance';
import { UnauthorizedCallType } from 'APP/model/unauthorizedCall/unauthorizedCallModel.constants';
import { ICallInvitationInfo } from 'APP/model/unauthorizedCall/unauthorizedCallModel.types';
import { RouterLink } from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import Entities from 'STORE';

interface ICallFinishedPresenter {
  currentTheme: ApplicationTheme;
  invitationInfo?: ICallInvitationInfo;
  isGroupCall: boolean;
}

export const useCallFinishedPresenter = (): ICallFinishedPresenter => {
  const params = useParams<{ id: string; callType: UnauthorizedCallType; channelId?: string }>();
  const navigateTo = useNavigateTo();

  const presenter = useLocalStore<ICallFinishedPresenter>(() => ({
    get isGroupCall(): boolean {
      return params.callType === UnauthorizedCallType.Call;
    },

    get currentTheme(): ApplicationTheme {
      return Entities.appearance.theme;
    },

    get invitationInfo(): ICallInvitationInfo | undefined {
      return Entities.callInvitation.invitationInfo;
    },
  }));

  useEffect(() => {
    if (!presenter.invitationInfo) {
      navigateTo({
        to: presenter.isGroupCall ? RouterLink.callInvitationGroup : RouterLink.callInvitation,
        params: { id: params.id, callType: params.callType, channelId: params.channelId },
      });
    }
  }, []);

  return presenter;
};
