import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { ActiveSpeakerIcon } from 'APP/components/ActiveSpeakerIcon/ActiveSpeakerIcon';
import { IconView, IconViewSizes } from 'APP/packages/UiKit/IconView/IconView';
import { useTranslation } from 'APP/packages/translations';
import RaiseHand from 'ICONS/ic-has_raise_hand.svg';
import MicOff from 'ICONS/ic-mic-off-16.svg';
import IcScreenSharing from 'ICONS/ic-screen-sharing.svg';
import { AudioState } from 'MAIN/OngoingCall/Shared/AudioState/AudioState';
import { Opponent } from 'STORE/Calls/Call/Opponent/Opponent';

import styles from './OpponentMeta.styles.m.css';

interface IOpponentMetaProps {
  opponent: Opponent | null;
  isMinimize?: boolean;
}

export const OpponentMeta: FC<IOpponentMetaProps> = observer(({ opponent, isMinimize }) => {
  const { t } = useTranslation();

  if (!opponent) {
    return null;
  }

  return (
    <>
      <div className={classNames(styles.meta, { [styles.minimize]: isMinimize })}>
        {!isMinimize && (
          <div className={styles.metaGroup}>
            <div className={styles.name}>
              {opponent.member?.isRaiseHand && (
                <IconView
                  Icon={RaiseHand}
                  size={IconViewSizes.Medium}
                  className={classNames(styles.raiseHand)}
                />
              )}
              {opponent.isSharedScreen && t('calls:sharing_screen')} {opponent.member?.displayName}
            </div>
            {!opponent.isSharedScreen &&
              (!opponent.hasAudio ? (
                <IconView
                  Icon={MicOff}
                  size={IconViewSizes.Small}
                  className={styles.muted}
                />
              ) : (
                <ActiveSpeakerIcon
                  isBig
                  isSilence={!opponent.isActiveSpeaker}
                />
              ))}
          </div>
        )}
        {isMinimize && opponent.member?.isRaiseHand && (
          <IconView
            Icon={RaiseHand}
            size={IconViewSizes.Medium}
            className={classNames(styles.raiseHand, styles.raiseHandBackground)}
          />
        )}
        {opponent.member?.hasScreenSharingOpponent && !opponent.isSharedScreen && (
          <IconView
            Icon={IcScreenSharing}
            className={styles.sharingIcon}
            size={IconViewSizes.Small}
          />
        )}
      </div>
      {isMinimize && !opponent.isSharedScreen && (
        <AudioState
          opponent={opponent}
          className={styles.audioState}
        />
      )}
    </>
  );
});
