export type TFileTheme =
  | '0'
  | '10'
  | '20'
  | '30'
  | '40'
  | '50'
  | '60'
  | '70'
  | '80'
  | '90'
  | '100'
  | '110'
  | '120'
  | '130';

export enum FileIconState {
  Error = 'error',
  Completed = 'completed',
  Loading = 'loading',
  Hover = 'hover',
  Regular = 'regular',
  Infinity = 'infinity',
}

export const mapFileIconThemeByFileExt: Record<string, TFileTheme> = {
  doc: '0',
  docx: '0',
  xls: '10',
  xlsx: '10',
  pdf: '20',
  txt: '30',
  zip: '40',
  rar: '40',
  '7z': '40',
  gzip: '40',
  html: '50',
  htm: '50',
  mht: '50',
  ppt: '60',
  pptx: '60',
  mdb: '70',
  accdb: '70',
  exe: '80',
  psd: '90',
  ai: '100',
  eps: '100',
  cdr: '110',
  svg: '120',
};
