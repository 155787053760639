import { GroupParticipantsChanged } from 'APP/Tasks/group/handleGroupEvents/GroupsEvent/GroupParticipantsChanged/GroupParticipantsChanged';
import { getGroupsEvents } from 'APP/model/counter/counterModel';
import { EventType, IGroupEvent, IGroupEventRequest } from 'APP/model/counter/counterModel.types';

import { BanStatusChanged } from './GroupsEvent/BanStatusChanged';
import { ClearHistory } from './GroupsEvent/ClearHistory';
import { ClearHistoryForAll } from './GroupsEvent/ClearHistoryForAll';
import { ConfigGroupAds } from './GroupsEvent/ConfigGroupAds';
import { ConfigGroupPin } from './GroupsEvent/ConfigGroupPin';
import { ConfigGroupUnread } from './GroupsEvent/ConfigGroupUnread';
import { DeleteMessagesFromUser } from './GroupsEvent/DeleteMessagesFromUser/DeleteMessagesFromUser';
import { DraftInputWasUpdated } from './GroupsEvent/DraftInputWasUpdated';
import { DraftWasUpdated } from './GroupsEvent/DraftWasUpdated';
import { DraftsWereDeleted } from './GroupsEvent/DraftsWereDeleted';
import { Group } from './GroupsEvent/Group';
import { GroupChanged } from './GroupsEvent/GroupChanged';
import { GroupUserDisplayDataUpdate } from './GroupsEvent/GroupUserDisplayDataUpdate';
import { GroupWasDeleted } from './GroupsEvent/GroupWasDeleted';
import { GroupsEvent } from './GroupsEvent/GroupsEvent';
import { LeaveGroup } from './GroupsEvent/LeaveGroup';
import { LinkPreviewWasCreated } from './GroupsEvent/LinkPreviewWasCreated';
import { MessageWasUpdated } from './GroupsEvent/MessageWasUpdated';
import { MessageWereDeleted } from './GroupsEvent/MessageWereDeleted/MessageWereDeleted';
import { ParticipantsWereUpdated } from './GroupsEvent/ParticipantsWereUpdated';
import { PermissionsChanged } from './GroupsEvent/PermissionsChanged/PermissionsChanged';
import { PhonebookWasUpdated } from './GroupsEvent/PhonebookWasUpdated';
import { PrivacySettingsUpdate } from './GroupsEvent/PrivacySettingsUpdate';
import { ScheduledMessageWasUpdated } from './GroupsEvent/ScheduledMessageWasUpdated';
import { ScheduledMessageWereDeleted } from './GroupsEvent/ScheduledMessageWereDeleted';
import { SpaceAllVisibilityUpdated } from './GroupsEvent/SpaceAllVisibilityUpdated/SpaceAllVisibilityUpdated';
import { SpaceDelete } from './GroupsEvent/SpaceDelete';
import { SpaceRoleChanged } from './GroupsEvent/SpaceRoleChanged/SpaceRoleChanged';
import { SpaceUserWasBanned } from './GroupsEvent/SpaceUserWasBanned/SpaceUserWasBanned';
import { SpaceVisibilityUpdated } from './GroupsEvent/SpaceVisibilityUpdated/SpaceVisibilityUpdated';
import { SpacesPinChanged } from './GroupsEvent/SpacesPinChanged/SpacesPinChanged';
import { StickerPackUpdated } from './GroupsEvent/StickerPackUpdated/StickerPackUpdated';
import { StickerPacksUpdated } from './GroupsEvent/StickerPacksUpdated/StickerPacksUpdated';
import { UserBlockedMe } from './GroupsEvent/UserBlockedMe';
import { UserDisplayDataUpdate } from './GroupsEvent/UserDisplayDataUpdate';
import { UserMarkedUnknownByMe } from './GroupsEvent/UserMarkedUnknownByMe/UserMarkedUnknownByMe';
import { UserPreferencesChanged } from './GroupsEvent/UserPreferencesChanged';
import { UserRewardUpdated } from './GroupsEvent/UserRewardUpdated/UserRewardUpdated';
import { UserUnblockedMe } from './GroupsEvent/UserUnblockedMe';
import { UserWasBanned } from './GroupsEvent/UserWasBanned';
import { UserWasBlockedByMe } from './GroupsEvent/UserWasBlockedByMe';
import { UserWasRemoved } from './GroupsEvent/UserWasRemoved';
import { UserWasUnblockedByMe } from './GroupsEvent/UserWasUnblockedByMe';

const eventsClasses: Record<string, typeof GroupsEvent<unknown>> = {
  [EventType.ClearHistory]: ClearHistory,
  [EventType.ClearHistoryForAll]: ClearHistoryForAll,
  [EventType.ConfigGroupAds]: ConfigGroupAds,
  [EventType.ConfigGroupPin]: ConfigGroupPin,
  [EventType.ConfigGroupUnread]: ConfigGroupUnread,
  [EventType.DeleteMessagesFromUser]: DeleteMessagesFromUser,
  [EventType.DraftInputWasUpdated]: DraftInputWasUpdated,
  [EventType.DraftWasUpdated]: DraftWasUpdated,
  [EventType.DraftsWereDeleted]: DraftsWereDeleted,
  [EventType.Group]: Group,
  [EventType.GroupChanged]: GroupChanged,
  [EventType.GroupSelfLeave]: LeaveGroup,
  [EventType.GroupUserDisplayDataUpdated]: GroupUserDisplayDataUpdate,
  [EventType.GroupParticipantsChanged]: GroupParticipantsChanged,
  [EventType.GroupWasDeleted]: GroupWasDeleted,
  [EventType.LinkPreviewWasCreated]: LinkPreviewWasCreated,
  [EventType.MessageReactionsWasUpdated]: MessageWasUpdated,
  [EventType.MessageWasUpdated]: MessageWasUpdated,
  [EventType.MessagesWereDeleted]: MessageWereDeleted,
  [EventType.PermissionsWereChanged]: PermissionsChanged,
  [EventType.PhonebookWasUpdated]: PhonebookWasUpdated,
  [EventType.RolesWereChanged]: ParticipantsWereUpdated,
  [EventType.ScheduledMessageWasUpdated]: ScheduledMessageWasUpdated,
  [EventType.ScheduledMessagesWereDeleted]: ScheduledMessageWereDeleted,
  [EventType.SpaceGroupVisibilityUpdated]: SpaceVisibilityUpdated,
  [EventType.SpaceGroupsVisibilityUpdated]: SpaceAllVisibilityUpdated,
  [EventType.SpaceRolesWereChanged]: SpaceRoleChanged,
  [EventType.SpaceSelfLeave]: SpaceDelete,
  [EventType.SpaceUserWasBanned]: SpaceUserWasBanned,
  [EventType.SpaceUserWasRemoved]: SpaceDelete,
  [EventType.SpaceWasDeleted]: SpaceDelete,
  [EventType.SpacesPinChanged]: SpacesPinChanged,
  [EventType.StickerPackUpdated]: StickerPackUpdated,
  [EventType.UserBanStatusesWereChanged]: BanStatusChanged,
  [EventType.UserBlockedMe]: UserBlockedMe,
  [EventType.UserDisplayDataUpdated]: UserDisplayDataUpdate,
  [EventType.UserMarkedUnknownByMe]: UserMarkedUnknownByMe,
  [EventType.UserPreferencesChanged]: UserPreferencesChanged,
  [EventType.UserPrivacySettingsUpdated]: PrivacySettingsUpdate,
  [EventType.UserRewardUpdated]: UserRewardUpdated,
  [EventType.UserStickerPacksUpdated]: StickerPacksUpdated,
  [EventType.UserUnblockedMe]: UserUnblockedMe,
  [EventType.UserWasBanned]: UserWasBanned,
  [EventType.UserWasBlockedByMe]: UserWasBlockedByMe,
  [EventType.UserWasRemoved]: UserWasRemoved,
  [EventType.UserWasUnblockedByMe]: UserWasUnblockedByMe,
};

export async function loadGroupEvents(data: IGroupEventRequest[]): Promise<void> {
  try {
    const response = await getGroupsEvents(
      data.map(({ groupId, eventTs }) => ({ groupId, eventTs: eventTs?.toString() }))
    );

    const mapLastEvent: Record<string, number> = {};
    response.forEach(({ events, groupId, eventTs }): void => {
      mapLastEvent[groupId] = Number(eventTs) || Date.now();
      events.forEach((data): void => {
        const EventClass = eventsClasses[data.type];
        const event = EventClass ? new EventClass(data) : new GroupsEvent(data);
        event.process();
      });
    });

    const nextPageRequest = data
      .filter(({ groupId, lastEventTs }) => {
        if (lastEventTs) {
          return mapLastEvent[groupId] < lastEventTs;
        }
        return false;
      })
      .map(
        ({ groupId, lastEventTs }): IGroupEventRequest => ({
          groupId,
          lastEventTs,
          eventTs: mapLastEvent[groupId],
        })
      );

    if (nextPageRequest.length) {
      await loadGroupEvents(nextPageRequest);
    }
  } catch {
    //
  }
}

export async function handleGroupEvents(events: IGroupEvent[]): Promise<void> {
  try {
    events.forEach((data) => {
      const EventClass = eventsClasses[data.type];
      const event = EventClass ? new EventClass(data) : new GroupsEvent(data);
      event.process();
    });
  } catch {
    //
  }
}
