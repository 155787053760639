import { useEffect, useState, RefObject, useRef } from 'react';

import { IMakeCancellablePromise, makeCancellablePromise } from 'UTILS/makeCancellablePromise';
import noop from 'UTILS/noop';

interface IRoundVideoPlayerData {
  url: string;
  isPlaying?: boolean;
  progress: number | null;
  onTogglePlay(isPlaying: boolean): void;
  onProgressUpdate(progress: number): void;
  onLoadedFirstFrame(): void;
  onLoadedError(): void;
}

interface IRoundVideoPlayerPresenter {
  progress: number;
  isLoading: boolean;
  isPlaying: boolean;
  isStarted: boolean;
  init(isPlaying: boolean): void;
  detroy(): void;
  onTogglePlay(): void;
  onPlay(): void;
  onPause(): void;
  onUpdateProgress(): void;
  onSetProgress(progress: number | null): void;
  onLoadedVideoData(): void;
  onLoadVideoError(): void;
  onPlayingLoadedVideo(): void;
  onWaitingLoadVideo(): void;
  onEndedVideo(): void;
}

interface IUseRoundVideoPlayerPresenter {
  videoRef: RefObject<HTMLVideoElement>;
  presenter: IRoundVideoPlayerPresenter;
}

type TPlayPromise = IMakeCancellablePromise<void>;

export function useRoundVideoPlayerPresenter(
  data: IRoundVideoPlayerData
): IUseRoundVideoPlayerPresenter {
  const videoRef = useRef<HTMLVideoElement>(null);
  const playPromise = useRef<TPlayPromise | null>(null);
  const [videoProgress, setVideoProgress] = useState<number>(0);
  const [hasVideoError, setHasVideoError] = useState<boolean>(false);
  const [isLoadingVideo, setIsLoadingVideo] = useState<boolean>(false);
  const [isPlayingVideo, setIsPlayingVideo] = useState<boolean>(false);

  const {
    url,
    isPlaying,
    progress,
    onTogglePlay: onTogglePlayCallback,
    onProgressUpdate: onProgressUpdateCallback,
    onLoadedFirstFrame: onLoadedFirstFrameCallback,
    onLoadedError: onLoadedErrorCallback,
  } = data;

  const presenter: IRoundVideoPlayerPresenter = {
    progress: videoProgress,
    isLoading: isLoadingVideo,
    isPlaying: isPlayingVideo,

    get isStarted(): boolean {
      return presenter.isLoading || presenter.isPlaying;
    },

    init(isPlaying: boolean): void {
      videoRef.current!.load();
      if (isPlaying) {
        presenter.onPlay();
      }
    },

    detroy(): void {
      if (presenter.isStarted) {
        playPromise.current?.cancel();
        playPromise.current = null;

        presenter.onPause();
      }

      setHasVideoError(false);
      setIsLoadingVideo(false);
      setIsPlayingVideo(false);
      setVideoProgress(0);
    },

    onTogglePlay(): void {
      if (presenter.isStarted) {
        presenter.onPause();
      } else {
        presenter.onPlay();
      }
    },

    onPlay(): void {
      if (hasVideoError) {
        presenter.detroy();
        presenter.init(false);
      }

      playPromise.current = makeCancellablePromise(videoRef.current!.play());
      playPromise.current.promise.catch(noop);

      presenter.onUpdateProgress();
      onTogglePlayCallback(true);
    },

    onPause(): void {
      if (playPromise.current) {
        playPromise.current.promise.then(() => videoRef.current?.pause());
      }

      setIsLoadingVideo(false);
      setIsPlayingVideo(false);
      onTogglePlayCallback(false);
    },

    onUpdateProgress(): void {
      const duration = videoRef.current!.duration;

      setVideoProgress(duration ? videoRef.current!.currentTime / duration : 0);
      onProgressUpdateCallback(videoRef.current!.currentTime);
    },

    onSetProgress(progress: number | null): void {
      if (videoRef.current && typeof progress === 'number') {
        videoRef.current.currentTime = progress;
      }
    },

    onLoadedVideoData(): void {
      onLoadedFirstFrameCallback();
    },

    onLoadVideoError(): void {
      setHasVideoError(true);
      setIsLoadingVideo(false);
      setIsPlayingVideo(false);
      onLoadedErrorCallback();
    },

    onPlayingLoadedVideo(): void {
      setIsLoadingVideo(false);
      setIsPlayingVideo(Boolean(isPlaying));
    },

    onWaitingLoadVideo(): void {
      setIsLoadingVideo(Boolean(isPlaying));
      setIsPlayingVideo(false);
    },

    onEndedVideo(): void {
      setIsLoadingVideo(false);
      setIsPlayingVideo(false);
    },
  };

  useEffect(() => {
    presenter.init(isPlaying || false);

    return () => {
      presenter.detroy();
    };
  }, [url]);

  useEffect(() => {
    if (isPlaying && !presenter.isStarted) {
      presenter.onPlay();
    }

    if (!isPlaying && presenter.isStarted) {
      presenter.onPause();
    }
  }, [isPlaying]);

  useEffect(() => {
    presenter.onSetProgress(progress);
  }, [progress]);

  return { videoRef, presenter };
}
