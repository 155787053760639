import React, { FC, ComponentProps } from 'react';

import { DateField as BaseDateField } from 'APP/packages/form/DateField/DateField';

interface IDateFieldProps extends ComponentProps<typeof BaseDateField> {
  hasExternalError: boolean;
  onChange?(value: { [name: string]: Date }): void;
}

export const DateField: FC<IDateFieldProps> = ({
  meta,
  input,
  hasExternalError,
  onChange,
  ...props
}) => {
  const extendedMeta = {
    ...meta,
    modified: hasExternalError || meta.modified,
    invalid: hasExternalError || meta.invalid,
    error: hasExternalError ? ' ' : meta.error,
  };

  const extendedInput = {
    ...input,
    onChange: (value: Date): void => {
      input.onChange(value);
      onChange && onChange({ [input.name]: value });
    },
  };

  return (
    <BaseDateField
      ignoreTrimError={hasExternalError}
      meta={extendedMeta}
      input={extendedInput}
      {...props}
    />
  );
};
