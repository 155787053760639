import { useAsObservableSource, useLocalStore } from 'mobx-react';

import { useTranslation } from 'APP/packages/translations';
import Entities from 'APP/store';

export default ({ group }) => {
  const { t } = useTranslation();
  const source = useAsObservableSource({ group });

  const presenter = useLocalStore(() => ({
    get status() {
      const events = source.group.userTyping.events;

      if (events.length === 0) {
        return null;
      }

      const lastIndex = events.length - 1;
      const { type } = events[lastIndex];

      const eventsFilteredByType = events.filter((event) => event.type === type);
      const users = eventsFilteredByType.map((event) => {
        const user = Entities.UsersStore.getUserById(event.userId);

        if (user) {
          return user.displayName;
        }

        return event.payload.username;
      });

      const usersCount = users.length;
      if (usersCount === 0) {
        return null;
      }

      switch (type) {
        case 'messages.user-typing': {
          if (source.group.isP2P) {
            return t('p2p_typing_placeholder');
          }

          const placeholder = {
            1: t('is_typing_placeholder').replace('{0}', users[0]),
            2: t('two_users_typing_placeholder').replace('{0}', users[0]).replace('{1}', users[1]),
            DEFAULT: t('several_users_typing_placeholder'),
          };

          return placeholder[usersCount] || placeholder.DEFAULT;
        }
        default:
          return null;
      }
    },
  }));

  return presenter;
};
