import { analyticsClient } from '../client/AnalyticsClient';
import { AnalyticsGeneralEvent, TabType, TopTabType } from './generalAnalytics.types';

export function trackTopMenuOpened(topTabType: TopTabType): void {
  analyticsClient.track({
    eventType: AnalyticsGeneralEvent.TopMenuOpened,
    eventProperties: {
      top_tab_type: topTabType,
    },
  });
}

export function trackBottomMenuOpened(tabType: TabType): void {
  analyticsClient.track({
    eventType: AnalyticsGeneralEvent.BottomMenuOpened,
    eventProperties: {
      tab_type: tabType,
    },
  });
}
