import React from 'react';

import { Popup } from 'APP/components/Popup/Popup';

import SelectItemsList from './SelectItemsList';

const SelectItemPopup = ({
  value,
  title,
  placeholder,
  isOpened,
  onClose,
  onSelect,
  values,
  renderItem,
  handleSearch,
}) => {
  return (
    <Popup
      isOpened={isOpened}
      title={title}
      onClose={onClose}
    >
      <SelectItemsList
        value={value}
        values={values}
        onSelect={(value) => {
          onSelect(value);
          onClose();
        }}
        placeholder={placeholder}
        ItemComponent={renderItem}
        handleSearch={handleSearch}
      />
    </Popup>
  );
};

export default SelectItemPopup;
