import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import Entities from 'STORE';
import { Group } from 'STORE/Groups/Group';
import { Space } from 'STORE/Spaces/Space';
import { getSortedGroups } from 'UTILS/getSortedGroups';

export interface ISpacePanelGroupsPresenter {
  isAvailableGroupsLoading: boolean;
  isGroupsLoading: boolean;
  isEmpty: boolean;
  groups: Group[];
  subscribedGroups: Group[];
  availableGroups: Group[];
  loadSpaceAvailableGroups(): Promise<void>;
}

export function useSpacePanelGroupsPresenter(space: Space): ISpacePanelGroupsPresenter {
  const source = useAsObservableSource({ space });

  const presenter = useLocalStore<ISpacePanelGroupsPresenter>(() => ({
    isAvailableGroupsLoading: false,

    get isGroupsLoading(): boolean {
      return Entities.GroupsStore.isLoading;
    },

    get isEmpty(): boolean {
      return (
        !(presenter.isGroupsLoading || presenter.isAvailableGroupsLoading) &&
        !presenter.subscribedGroups.length &&
        !presenter.availableGroups.length
      );
    },

    get groups(): Group[] {
      return getSortedGroups(Entities.GroupsStore.getGroupsBySpaceId(source.space.id));
    },

    get subscribedGroups(): Group[] {
      return presenter.groups.filter((group) => !group.withoutMe);
    },

    get availableGroups(): Group[] {
      return presenter.groups.filter(
        (group) => group.withoutMe && (source.space.isOwner || group.isOpen)
      );
    },

    async loadSpaceAvailableGroups(): Promise<void> {
      presenter.isAvailableGroupsLoading = true;
      await Tasks.space.loadSpaceAvailableGroups(source.space.id);
      presenter.isAvailableGroupsLoading = false;
    },
  }));

  useEffect(() => {
    presenter.loadSpaceAvailableGroups();
  }, [space.groupsChangedAt, space.role]);

  return presenter;
}
