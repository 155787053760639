import React, { useEffect } from 'react';

import { useAnimateSticker } from 'APP/packages/stickerAnimation/useAnimateSticker';

import styles from './EditProfileMissionSuccess.styles.m.css';

interface IEditProfileMissionSuccessProps {
  onComplete(): void;
  animationData: { id: string; data: Record<string, any> };
}

const ANIMATION_DELAY = 2000;

export const EditProfileMissionSuccess: React.FC<IEditProfileMissionSuccessProps> = (props) => {
  const { stickerContainerRef, animationRef } = useAnimateSticker<HTMLDivElement>({
    autoplay: true,
    animationData: props.animationData,
    loop: false,
    onLoopComplete: props.onComplete,
  });

  useEffect(() => {
    if (animationRef.current) {
      setTimeout(() => animationRef.current?.play(), ANIMATION_DELAY);
    }
  }, []);

  return (
    <div
      className={styles.animation}
      ref={stickerContainerRef}
    />
  );
};
