import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { ScheduleButton } from 'MAIN/ChatView/ChatFooter/InputPanel/ScheduleButton/ScheduleButton';
import { SubmitButton } from 'MAIN/ChatView/ChatFooter/InputPanel/SubmitButton/SubmitButton';
import { VoiceButton } from 'MAIN/ChatView/ChatFooter/InputPanel/VoiceButton/VoiceButton';

import { useInputPanelButtonsPresenter } from './InputPanelButtons.presenter';

export const InputPanelButtons: FC = observer(() => {
  const presenter = useInputPanelButtonsPresenter();

  if (presenter.showVoiceButton) {
    return <VoiceButton />;
  }

  if (presenter.showScheduleButton) {
    return <ScheduleButton />;
  }

  if (presenter.showSubmitButton) {
    return (
      <SubmitButton
        isSlowMode={presenter.isSlowMode}
        remainingSecond={presenter.remainingSecond}
      />
    );
  }

  return null;
});
