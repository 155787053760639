import PromiseQueue from 'p-queue/dist';

import Tasks from 'APP/Tasks';
import {
  handleGroupEvents,
  loadGroupEvents,
} from 'APP/Tasks/group/handleGroupEvents/handleGroupEvents';
import {
  IGroupCounterView,
  IGroupEvent,
  IGroupEventRequest,
} from 'APP/model/counter/counterModel.types';
import Entities from 'APP/store';
import { Counter } from 'APP/store/CountersStore/Counter';

import { handleNewMessages } from '../../messaging/handleNewMessages';
import { loadUnknownData } from './loadUnknownData';

const queue: Record<string, PromiseQueue> = {};

async function handleCounter(counters: IGroupCounterView[] = []): Promise<void> {
  const groupEventsRequest: IGroupEventRequest[] = [];
  const groupsEvents: IGroupEvent[] = [];

  const promises = counters.map(async (counter): Promise<void> => {
    const prevCounter = Entities.Counters.getCounterById(counter.groupId);

    const hasMissedEvents =
      prevCounter && prevCounter.lastEventTs < Number(counter.previousEventTs);

    if (hasMissedEvents) {
      groupEventsRequest.push({
        groupId: counter.groupId,
        eventTs: prevCounter?.lastEventTs,
        lastEventTs: counter.lastEventTs,
      });
    }

    if (
      !hasMissedEvents &&
      prevCounter &&
      counter.lastEvent &&
      (counter?.lastEvent.id !== prevCounter?.lastEventId ||
        prevCounter.lastEventTs !== Number(counter.lastEventTs))
    ) {
      groupsEvents.push(counter.lastEvent);
    }

    Entities.Counters.add(new Counter(counter));
    const group = Entities.GroupsStore.getGroupById(counter.groupId);
    if (
      group &&
      counter.lastMessage &&
      counter.lastMessage?.id !== prevCounter?.lastMessage?.id &&
      !group.messagesStore.getMessageById(counter.lastMessage?.id)
    ) {
      await handleNewMessages({
        messages: [counter.lastMessage],
        previousMessageTs:
          Number(counter.previousMessageTs) || group.messagesStore.lastMessage?.serverTime,
      });
    }

    Tasks.messaging.handleIsLoadedLastMessages({
      groupId: counter.groupId,
      messages: group?.messagesStore?.messages,
    });
  });
  promises.push(handleGroupEvents(groupsEvents));
  promises.push(loadGroupEvents(groupEventsRequest));
  await Promise.allSettled(promises);
}

export async function addCounters(counters: IGroupCounterView[]): Promise<void> {
  await loadUnknownData(counters);
  // many counters from different groups (getGroupsPage, getGroupsCounters);
  if (counters.length > 1) {
    await handleCounter(counters);
    return;
  }

  counters.forEach((counter) => {
    if (!queue[counter.groupId]) {
      queue[counter.groupId] = new PromiseQueue({ concurrency: 1 });
    }

    // it helps to resolve counters in the right order for the group
    queue[counter.groupId].add(() => handleCounter([counter]));
  });
}
