import { useLocalStore } from 'mobx-react';
import { MutableRefObject, useRef } from 'react';

import Entities from 'APP/store';
import { Call } from 'STORE/Calls/Call/Call';
import Mode from 'STORE/ViewModels/Calls/OngoingCall/Mode';

import { finishAll as finishAllTask } from './Tasks/finishAll';
import { finishCall as finishCallTask } from './Tasks/finishCall';

interface IFinishCallButtonPresenter {
  isOpened: boolean;
  mode: Mode;
  call: Call;
  disabledFinishCall: boolean;
  isGuestCall: boolean;
  togglePopover(): void;
  closePopover(): void;
  leaveCall(): void;
  finishCall(): void;
  finishAll(): void;
}

export const useFinishCallButtonPresenter = (): {
  buttonRef: MutableRefObject<HTMLButtonElement | null>;
  presenter: IFinishCallButtonPresenter;
} => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const presenter = useLocalStore<IFinishCallButtonPresenter>(() => ({
    isOpened: false,

    get mode(): Mode {
      return Entities.OngoingCall.mode;
    },

    get call(): Call {
      return Entities.Calls.ongoingCall!;
    },

    get disabledFinishCall(): boolean {
      return !presenter.call.channelId;
    },

    get isGuestCall(): boolean {
      return presenter.call.isGuestCall;
    },

    togglePopover(): void {
      presenter.isOpened = !presenter.isOpened;
    },

    closePopover(): void {
      presenter.isOpened = false;
    },

    leaveCall(): void {
      finishCallTask({
        groupId: presenter.call.groupId,
      });
    },

    finishCall(): void {
      if (
        (presenter.mode.isGroupAudio || presenter.mode.isGroupVideo) &&
        presenter.call.isMeInitiatorOwnerOrAdmin
      ) {
        presenter.togglePopover();
      } else {
        presenter.leaveCall();
      }
    },

    finishAll(): void {
      finishAllTask({
        groupId: presenter.call.groupId,
      });
    },
  }));

  return {
    presenter,
    buttonRef,
  };
};
