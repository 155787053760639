import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { PayloadType } from 'APP/model/message/messageModel.types';
import Entities from 'APP/store';
import { DISABLE_QUOTE_ON_DOUBLE_CLICK_ATTR_NAME } from 'MAIN/ChatView/Messages/Message/presenter/constants';
import { MessageViewPageType } from 'MAIN/ChatView/Messages/Messages.types';

const mediaPayloadTypes = [
  PayloadType.Image,
  PayloadType.Album,
  PayloadType.Video,
  PayloadType.CircleVideo,
  PayloadType.Article,
];

export function usePresenter({ message, messageViewPageType }) {
  const source = useAsObservableSource({ message });

  const presenter = useLocalStore(() => ({
    timer: null,
    viewTimer: null,
    isPreviewMessage: false,
    isViewMessage: false,
    isFocused: false,

    get message() {
      return source.message;
    },

    get hasContextMenu() {
      const isUnsupportedMessage = !Object.values(PayloadType).some((payloadType) => {
        return payloadType === presenter.message.payload.payloadType;
      });

      if (isUnsupportedMessage) {
        return false;
      }

      return (
        presenter.message &&
        (!presenter.message.payload.isSystem || presenter.message.payload.isStream)
      );
    },

    get group() {
      return Entities.GroupsStore.getGroupById(presenter.message.groupId);
    },

    get hasAnimation() {
      return presenter.isFocused && presenter.isViewMessage;
    },

    get isReading() {
      return presenter.message.unread && Entities.App.isFocused && !presenter.group.withoutMe;
    },

    get payloadType() {
      return presenter.message?.payload?.payloadType;
    },

    get isPreview() {
      return presenter.group?.isFake || presenter.message?.isFake || presenter.isPreviewMessage;
    },

    get isView() {
      return presenter.group?.isFake || presenter.message?.isFake || presenter.isViewMessage;
    },

    get hasViewObserver() {
      return (
        presenter.message && presenter.group && !presenter.group.isFake && !presenter.message.isFake
      );
    },

    get isMainMessageViewPage() {
      return messageViewPageType === MessageViewPageType.Main;
    },

    get hasPreviewObserver() {
      if (!presenter.hasViewObserver) {
        return false;
      }

      if (mediaPayloadTypes.indexOf(presenter.payloadType) < 0) {
        return false;
      }

      if (presenter.payloadType === PayloadType.Article) {
        return !!presenter.message.payload.firstMediaPayload;
      }

      return true;
    },

    onFinishAnimation() {
      presenter.isFocused = false;
      presenter.unFocus();
    },

    unFocus() {
      if (presenter.message.isFocused && presenter.isMainMessageViewPage) {
        presenter.message.unfocusMessage();
      }
    },

    viewMessage() {
      Tasks.messaging.viewMessage({
        groupId: presenter.message.groupId,
        messageId: presenter.message.id,
      });
    },

    onView(isView) {
      if (!isView && presenter.isViewMessage) {
        presenter.isFocused = false;
      }
      presenter.isViewMessage = isView;

      if (isView && presenter.isReading) {
        Tasks.messaging.confirmReadMessages({
          groupId: presenter.message.group.id,
          messageId: presenter.message.id,
        });
      }

      if (isView) {
        presenter.unFocus();
        presenter.viewTimer = setTimeout(presenter.viewMessage, 1000);
        Tasks.messaging.inViewMessage({
          groupId: presenter.message.groupId,
          messageId: presenter.message.id,
        });
      } else {
        clearTimeout(presenter.viewTimer);
        Tasks.messaging.outViewMessage({
          groupId: presenter.message.groupId,
          messageId: presenter.message.id,
        });
      }
    },

    onPreview(isView) {
      presenter.isPreviewMessage = isView;
    },

    onMessageDoubleClick(event) {
      if (
        presenter.message.canBeQuoted &&
        !event.target.closest(`[${DISABLE_QUOTE_ON_DOUBLE_CLICK_ATTR_NAME}]`)
      ) {
        Tasks.messaging.startQuoteMessage(presenter.message);
      }
    },
  }));

  useEffect(() => {
    if (presenter.message.isFocused) {
      presenter.isFocused = true;
    }
  }, [presenter.message.isFocused]);

  useEffect(() => {
    return () => {
      clearTimeout(presenter.viewTimer);
      Tasks.messaging.outViewMessage({
        groupId: presenter.message.groupId,
        messageId: presenter.message.id,
      });
      presenter.unFocus();
    };
  }, []);

  return presenter;
}
