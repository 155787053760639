import { useRef } from 'react';

import Tasks from 'APP/Tasks';
import { openPopup } from 'APP/Tasks/app/popup/popup';
import {
  IFileMessagePayloadData,
  IMessageData,
  PayloadType,
} from 'APP/model/message/messageModel.types';
import { greetingCardAnalytics } from 'APP/packages/analytics/analytics';
import logger from 'APP/packages/logger';
import { useTranslation } from 'APP/packages/translations';
import { IShareSelection } from 'APP/types/share';
import {
  IShareCardPopupParams,
  ShareCardPopup,
} from 'MAIN/PopupManager/Popups/ShareCardPopup/ShareCardPopup';
import Entities from 'STORE';
import { Popup } from 'STORE/App/Popups/Popup/Popup';
import { ShareCardButtonPayload } from 'STORE/Messages/Message/Payload/Buttons/ShareCardButtonPayload';
import { replaceHtmlTagsToSpecialSymbols } from 'UTILS/html/replaceHtmlTagsToSpecialSymbols';

interface IShareCardCommandButtonPresenter {
  onClick(): void;
}

export const useShareCardCommandButtonPresenter = (
  buttonPayload: ShareCardButtonPayload
): IShareCardCommandButtonPresenter => {
  const { t } = useTranslation();
  const popupRef = useRef<Popup<IShareCardPopupParams>>();

  const createMessagesData = (
    targetGroupIds: string[],
    value?: string
  ): IMessageData<IFileMessagePayloadData>[] => {
    return targetGroupIds.map((groupId) => ({
      type: PayloadType.Image,
      groupId,
      data: {
        comment: replaceHtmlTagsToSpecialSymbols(value),
        url: buttonPayload.imageUrl,
      },
    }));
  };

  // TODO: move this logic inside ShareCardPopup component
  const onSubmit = async (selection: IShareSelection[]): Promise<void> => {
    if (!buttonPayload.imageUrl) {
      return;
    }

    try {
      const targetGroupIds = await Tasks.forward.getTargetGroupIds(selection);
      const messagesData = createMessagesData(targetGroupIds, buttonPayload.caption);

      await Tasks.messaging.createNewMessages({ messages: messagesData });

      messagesData.forEach(() => {
        greetingCardAnalytics.trackCardSent();
      });

      if (targetGroupIds.length === 1) {
        if (Entities.GroupsStore.activeGroupId !== targetGroupIds[0]) {
          const targetGroup = Entities.GroupsStore.getGroupById(targetGroupIds[0]);
          if (targetGroup) {
            targetGroup.messagesStore.setSavePosition({ isBottom: true });
          }
          await Tasks.group.setActiveGroup({ groupId: targetGroupIds[0] });
        }
      }
      popupRef.current?.close();
    } catch (err: any) {
      logger.get('ShareCardCommandButton').error('createNewMessages', err);
      Entities.toast.show(t('something_went_wrong'));
    }
  };

  const onClick = (): void => {
    popupRef.current = openPopup(ShareCardPopup, {
      params: { onSubmit, cardDeepLink: buttonPayload.cardDeepLink },
    });
  };

  return { onClick };
};
