import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { useOpponentsVideo } from 'MAIN/OngoingCall/hooks/useOpponentsVideo/useOpponentsVideo';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';

import { OpponentsView } from '../OpponentsView/OpponentsView';
import styles from './OpponentList.styles.m.css';

export const OpponentList = observer(() => {
  const { opponentsKeys } = useOpponentsVideo();

  return (
    <div className={classNames(styles.container, customScrollStyles.container)}>
      <OpponentsView opponentsKeys={opponentsKeys} />
    </div>
  );
});
