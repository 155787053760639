/*
 ************ Example: *****************
 *
 * interface SomeDataType {
 *   groupId: this.id,
 *   mute: this.muted,
 * }
 *
 * const debouncedFn = debounce((data: SomeDataType) => {
 *   console.log(data) // SomeDataType
 *  });
 *
 * debouncedFn({ groupId: this.id, mute: this.muted });
 *
 * */

export function debounce<T extends (...args: any[]) => void>(
  func: T,
  wait: number,
  immediate?: boolean
): (...args: Parameters<T>) => void {
  let timeout: null | ReturnType<typeof setTimeout> = null;

  return function executedFunction(this: ThisParameterType<T>, ...args: Parameters<T>): void {
    const later = (): void => {
      timeout = null;
      if (!immediate) func.apply(this, args);
    };

    const callNow = immediate && !timeout;

    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(this, args);
  };
}
