import { useUserProfile } from 'MAIN/hooks/userProfile/useUserProfile';

export const useUserPresenter = ({ userId, onClick, interactive, groupId, messageId }) => {
  const userProfile = useUserProfile(userId, { groupId, messageId });

  const onClickUser = () => {
    if (!interactive) {
      return;
    }
    userProfile.show();
    onClick && onClick();
  };

  return { onClickUser };
};
