import { RefObject, ElementType, MouseEventHandler } from 'react';

import { IconViewSizes } from 'UIKIT/IconView/IconView.types';

export enum ButtonTypes {
  Button = 'button',
  Submit = 'submit',
  Reset = 'reset',
}

export type TButtonTheme =
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '10'
  | '11'
  | '12'
  | '13'
  | '14'
  | '15';
export type TButtonRounding = '0' | '1' | '2';
export type TButtonSize = '0' | '10' | '20' | '30';

export const buttonIconSizeMap: Record<TButtonSize, IconViewSizes> = {
  '0': IconViewSizes.XSmall,
  '10': IconViewSizes.Small,
  '20': IconViewSizes.Medium,
  '30': IconViewSizes.Large,
};

export interface IButton<T> {
  tabIndex?: number;
  onClick?: MouseEventHandler<T>;
  onContextMenu?: MouseEventHandler<T>;
  className?: string;
  Icon?: ElementType;
  setRef?: RefObject<T>;
  size?: TButtonSize;
  rounding?: TButtonRounding;
  theme?: TButtonTheme;
  dataTest?: string;
  isLoading?: boolean;
}
