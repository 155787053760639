import { Group } from '../Group';

export class My extends Group {
  get isMy(): boolean {
    return true;
  }
  get isFake(): boolean {
    return true;
  }
}
