import { useEffect, useRef } from 'react';

import { openPopup } from 'APP/Tasks/app/popup/popup';
import { ForwardMessagePopup } from 'MAIN/PopupManager/Popups/ForwardMessagePopup/ForwardMessagePopup';

export default ({ message }) => {
  const childPopupRef = useRef();

  const presenter = {
    get isShow() {
      return message.canBeForward;
    },

    onClick() {
      childPopupRef.current = openPopup(ForwardMessagePopup, {
        params: { groupId: message.groupId, messageIds: [message.id] },
      });
    },
  };

  useEffect(() => {
    return () => childPopupRef.current?.close();
  }, []);

  return presenter;
};
