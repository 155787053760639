import { observer } from 'mobx-react';
import React, { FC, ReactElement } from 'react';

import { List } from 'APP/components/List/List';
import NoContacts from 'APP/images/gem-mascot-contacts.svg';
import NotFoundImage from 'APP/images/gem-mascot-not-found.svg';
import { InfoBlock, InfoBlockTypes } from 'UIKIT/InfoBlock/InfoBlock';

import { IForwardTargetData, ISharedDataViewItemData } from '../../ShareDataView.types';
import { ShareDataViewListContainer } from '../../ShareDataViewListContainer/ShareDataViewListContainer';
import { ITEM_COMPONENT } from '../../SharedDataViewTabs/SharedDataViewTabs';
import { useSharedDataViewTabChannelsPresenter } from './SharedDataViewTabChannels.presenter';

interface ISharedDataViewTabChannelsProps {
  multiselect: boolean;
  onClickItem(item: ISharedDataViewItemData): void;
  searchValue: string;
  selection: ISharedDataViewItemData[];
}

export const SharedDataViewTabChannels: FC<ISharedDataViewTabChannelsProps> = observer((props) => {
  const { searchValue, selection, multiselect, onClickItem } = props;

  const presenter = useSharedDataViewTabChannelsPresenter({ selection, searchValue, onClickItem });
  const renderItem = (item: IForwardTargetData): ReactElement => {
    const Component = ITEM_COMPONENT[item.data.itemType];

    return (
      <Component
        data={item.data}
        isSelected={item.isSelected}
        withCheckbox={multiselect}
        onClick={item.onClick}
      />
    );
  };

  return (
    <ShareDataViewListContainer>
      <List
        data={presenter.content}
        keyExtractor={presenter.keyExtractor}
        renderItem={renderItem}
        ListEmptyComponent={
          <InfoBlock
            title={presenter.infoBlockTitle}
            descriptions={presenter.infoBlockDescriptions}
            type={InfoBlockTypes.Modal}
            image={!searchValue ? NoContacts : NotFoundImage}
          />
        }
      />
    </ShareDataViewListContainer>
  );
});
