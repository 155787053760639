import { AREAS, SDK_CODEC, SDK_MODE } from 'agora-rtc-sdk-ng';

export const AGORA_CLIENT_CALL_SETTINGS: {
  mode: SDK_MODE;
  codec: SDK_CODEC;
  areaCode: AREAS[];
} = {
  mode: 'live',
  codec: 'vp8',
  areaCode: [AREAS.GLOBAL],
};
