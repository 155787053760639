export class ConnectionError extends Error {
  constructor(message) {
    super(message);

    this.name = 'ConnectionError';
    this.message = message;

    Object.setPrototypeOf(this, ConnectionError.prototype);
  }
}
