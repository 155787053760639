import React, { FC, memo, ReactNode, useEffect } from 'react';

import styles from './Event.styles.m.css';

const SHOW_DURATION = 3000;

interface IEventProps {
  text: ReactNode;
  onHide(timestamp: number): void;
  timestamp: number;
}

export const Event: FC<IEventProps> = memo(({ text, onHide, timestamp }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onHide(timestamp);
    }, SHOW_DURATION);
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  return <div className={styles.item}>{text}</div>;
});
