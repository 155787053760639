import { spaceModel } from 'APP/model/model';
import { ISpaceCollection, IUpdateSpaceCollection } from 'APP/model/space/spaceModel.types';
import Entities from 'STORE';

export async function updateSpaceCollection(
  data: IUpdateSpaceCollection
): Promise<ISpaceCollection | null> {
  try {
    const collection = await spaceModel.updateCollection(data);

    if (collection) {
      const space = Entities.spacesStore.getById(data.spaceId);
      space?.collections.updateCollection(collection);
    }

    return collection || null;
  } catch {
    return null;
  }
}
