import Tasks from 'APP/Tasks';

export const usePostVideoPresenter = ({ message }) => {
  const presenter = {
    payload: message.payload,
    keyWords: message.payload?.keyWords,
    userMentions: message.payload?.userMentions,
    comment: message.renderPayload?.comment,

    get isShowComment() {
      return presenter.comment && presenter.comment.length;
    },

    onOpenMediaGallery() {
      Tasks.group.openMediaGallery({
        groupId: message.groupId,
        mediaId: message.clientUuid,
        messageId: message.id,
        messageIds: [message.id],
      });
    },
  };

  return presenter;
};
