import { useLocalStore } from 'mobx-react';

import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';

export interface IDateFieldCalendarPresenterProps {
  value: Date;
  minDate?: Date;
  onChoose(date?: Date): void;
}

interface IDateFieldCalendarPresenter {
  selectedDate?: Date;
  date: Date;
  minDate: Date;
  onChange(date: Date): void;
  onChoose(): void;
  onClose(): void;
}

export const useDateFieldCalendarPresenter = ({
  popupInstance,
  params,
}: ICommonPopupsProps<IDateFieldCalendarPresenterProps>): IDateFieldCalendarPresenter => {
  const presenter = useLocalStore<IDateFieldCalendarPresenter>(() => ({
    selectedDate: params?.value,

    get date(): Date {
      return new Date();
    },

    get minDate(): Date {
      return params?.minDate || new Date();
    },

    onChange(date: Date): void {
      presenter.selectedDate = date;
    },

    onChoose(): void {
      params?.onChoose(presenter.selectedDate);
      popupInstance.close();
    },

    onClose(): void {
      popupInstance.close();
    },
  }));

  return presenter;
};
