import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import useInteractor from './interactor';

export default () => {
  const interactor = useInteractor();

  const presenter = useLocalStore(() => ({
    focusApp: interactor.focusApp,
    unfocusApp: interactor.unfocusApp,
  }));

  useEffect(() => {
    window.addEventListener('focus', presenter.focusApp);
    window.addEventListener('blur', presenter.unfocusApp);

    return () => {
      window.removeEventListener('focus', presenter.focusApp);
      window.removeEventListener('blur', presenter.unfocusApp);
    };
  }, []);

  return presenter;
};
