import bots from './bots';
import group from './group';
import messaging from './messaging';
import ui from './ui';

export default {
  ui,
  bots,
  group,
  messaging,
};
