import Tasks from 'APP/Tasks';

export default async ({ inputPanel, link }) => {
  inputPanel.setIsLoadingPreview(true);

  const response = await Tasks.messaging.getLinkPreview({ link });

  if (inputPanel.link === link) {
    inputPanel.linkPreviews.set(link, response);
    inputPanel.setIsShowLinkPreview(!!response);
  }

  inputPanel.setIsLoadingPreview(false);
};
