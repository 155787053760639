import { observer } from 'mobx-react';
import React, { FC, MutableRefObject } from 'react';

import { OnboardingType } from 'APP/constants/onboarding';
import { useTranslation } from 'APP/packages/translations';
import { useOnboardingManager } from 'MAIN/hooks/onboardingManager/useOnboardingManager';
import { OnboardingPopover } from 'UIKIT/OnboardingPopover/OnboardingPopover';

interface ISpaceFriendsOnboardingProps {
  reference: MutableRefObject<HTMLElement | null>;
}

export const SpaceFriendsOnboarding: FC<ISpaceFriendsOnboardingProps> = observer(
  ({ reference }) => {
    const { t } = useTranslation();
    const presenter = useOnboardingManager(OnboardingType.SpaceFriends);

    if (!presenter.isNeedToShow) {
      return null;
    }

    return (
      <OnboardingPopover
        reference={reference}
        description={t('space_onboarding_friends')}
        handleClose={presenter.handleClose}
        isOpened={presenter.isOpened}
      />
    );
  }
);
