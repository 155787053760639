import { useAsObservableSource, useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { openPopup } from 'APP/Tasks/app/popup/popup';
import { showAlert } from 'APP/Tasks/app/showAlert/showAlert';
import { showConfirm } from 'APP/Tasks/app/showConfirm/showConfirm';
import { showToast } from 'APP/Tasks/app/showToast/showToast';
import { useTranslation } from 'APP/packages/translations';
import { RouterLink } from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import { MoveGroupToSpacePopup } from 'MAIN/PopupManager/Popups/MoveGroupToSpacePopup/MoveGroupToSpacePopup';
import Entities from 'STORE';
import { Popup } from 'STORE/App/Popups/Popup/Popup';
import { Group } from 'STORE/Groups/Group';
import { Space } from 'STORE/Spaces/Space';

interface IMoveGroupToSpace {
  group: Group | null;
  space: Space | null;
  spaces: Space[];
  canMoveToSpace: boolean;
  canRemoveFromSpace: boolean;
  moveToSpace(): Popup;
  removeFromSpace(): Promise<void>;
}

export function useMoveGroupToSpace(groupId: string | null): IMoveGroupToSpace {
  const navigateTo = useNavigateTo();
  const { t } = useTranslation();
  const source = useAsObservableSource({ groupId, navigateTo });

  const presenter = useLocalStore<IMoveGroupToSpace>(() => ({
    get group(): Group | null {
      return source.groupId ? Entities.GroupsStore.getGroupById(source.groupId) : null;
    },

    get space(): Space | null {
      return presenter.group?.spaceId
        ? Entities.spacesStore.getById(presenter.group.spaceId)
        : null;
    },

    get spaces(): Space[] {
      return Entities.spacesStore.spaces.filter(
        (space) => (space.isOwner || space.isAdmin) && space.id !== presenter.group?.spaceId
      );
    },

    get canMoveToSpace(): boolean {
      return !!(
        (presenter.group?.isChatGroup || presenter.group?.isChannel) &&
        presenter.group?.isOwner &&
        presenter.spaces.length
      );
    },

    moveToSpace(): Popup {
      return openPopup(MoveGroupToSpacePopup, {
        params: { groupId: presenter.group!.id },
      });
    },

    get canRemoveFromSpace(): boolean {
      return !!presenter.space?.isOwner || !!(presenter.space?.isAdmin && presenter.group?.isOwner);
    },

    async removeFromSpace(): Promise<void> {
      const canRemove = await showConfirm({
        text: presenter.group?.isChannel
          ? t('space_remove_channel_alert')
          : t('space_remove_group_alert'),
      });

      if (!canRemove) {
        return;
      }

      const success = await Tasks.space.removeGroupsFromSpace([presenter.group!.id]);

      if (success) {
        showToast(t('space_alert_removed'));

        if (presenter.group!.isClosed && presenter.group!.withoutMe) {
          Entities.spacesStore.activeSpaceId
            ? source.navigateTo({
                to: RouterLink.space,
                params: { id: Entities.spacesStore.activeSpaceId },
              })
            : Tasks.group.setActiveGroup({ groupId: null });
        }
      } else {
        showAlert(t('common_somethings_wrong_error'));
      }
    },
  }));

  return presenter;
}
