import { observer } from 'mobx-react';
import React, { useRef } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { IconButton } from 'APP/packages/UiKit/IconButton';
import DeleteIcon from 'ICONS/ic-close.svg';
import DragAndDropIcon from 'ICONS/ic-drag-and-drop.svg';

import FileUploadButton from '../FileUploadButton';
import Menu from '../Menu';
import usePresenter from './presenter';
import styles from './styles.m.css';

const PayloadWrapper = ({ parentPresenter, order, children, payload, dragKey, isDragging }) => {
  const { uploadFilesPresenter, presenter } = usePresenter({
    parentPresenter,
    order,
    payload,
    isDragging,
  });
  const fileUploadRef = useRef();

  // TODO: it's temporary solution
  const getItemStyle = (isDragging, draggableStyle) => ({
    background: isDragging ? 'var(--base-17)' : '',
    borderRadius: isDragging ? '8px' : '',
    boxShadow: isDragging ? 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px' : '',
    ...draggableStyle,
  });

  return (
    <Draggable
      key={dragKey}
      draggableId={dragKey}
      index={order}
    >
      {(provided, snapshot) => (
        <div
          className={styles.container}
          ref={provided.innerRef}
          onMouseEnter={presenter.onMouseEnter}
          onMouseLeave={presenter.onMouseLeave}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
          data-test="article-payload-item"
        >
          <FileUploadButton
            ref={fileUploadRef}
            uploadFiles={uploadFilesPresenter.uploadFiles}
          />
          {presenter.canDragAndDrop ? (
            <DragAndDropIcon className={styles.dragAndDropIcon} />
          ) : (
            <div className={styles.menu}>
              <Menu
                payload={payload}
                parentPresenter={parentPresenter}
                fileUploadRef={fileUploadRef}
                order={order}
              />
            </div>
          )}
          <div className={styles.payload}>{children}</div>
          {presenter.canDelete && (
            <div
              className={styles.delete}
              data-test="article-payload-delete-button"
            >
              <IconButton
                onClick={() => parentPresenter.deletePayloadByOrder(order)}
                Icon={DeleteIcon}
                className={styles.deleteButton}
              />
            </div>
          )}
        </div>
      )}
    </Draggable>
  );
};

export default observer(PayloadWrapper);
