import { CallEventTypes } from 'APP/constants/calls';
import Entities from 'APP/store';

function handleBackgroundCall(call) {
  if (call.status === CallEventTypes.Started) {
    Entities.BackgroundCalls.add(call);
  } else if (call.status === CallEventTypes.Finished) {
    Entities.BackgroundCalls.delete(call.groupId);
  }
}

function handleIncomingCall(call) {
  if (call.status !== CallEventTypes.Finished) {
    return;
  }

  Entities.IncomingCalls.delete(call.groupId, call.channelId);
}

function handleCall(call) {
  if (call.status !== CallEventTypes.Finished) {
    return;
  }

  const targetCall = Entities.Calls.getCallByGroupId(call.groupId);

  if (!targetCall) {
    return;
  }

  targetCall.delete();
}

function handleConfirmAwaitCall(call) {
  const group = Entities.GroupsStore.getGroupById(call.groupId);

  if (group?.isAwaitCall && Entities.App.confirm && call.status === CallEventTypes.Finished) {
    Entities.App.confirm.onClose();
  }
}

export function updateActiveCall(call) {
  handleBackgroundCall(call);
  handleIncomingCall(call);
  handleCall(call);
  handleConfirmAwaitCall(call);
}
