import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { CatalogType } from 'APP/model/catalog/catalogModel.types';
import { useTranslation } from 'APP/packages/translations';

import { TTypeChangeHandler, TCategoryChangeHandler } from '../../Catalog.types';
import { CategoryButton } from '../../components/CategoryButton/CategoryButton';
import { useCatalogTypesPresenter } from './CatalogTypes.presenter';
import styles from './CatalogTypes.styles.m.css';

interface ICatalogTypesProps {
  onCategoryChange: TCategoryChangeHandler;
  onTypeChange: TTypeChangeHandler;
}

export const CatalogTypes: FC<ICatalogTypesProps> = observer((props) => {
  const { onCategoryChange, onTypeChange } = props;
  const presenter = useCatalogTypesPresenter({ onCategoryChange, onTypeChange });
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <CategoryButton
        className={styles.button}
        isSelected={presenter.isActiveChannels}
        onClick={() => presenter.onClick(CatalogType.Channel)}
      >
        {t('channels')}
      </CategoryButton>

      <CategoryButton
        className={styles.button}
        isSelected={presenter.isActiveSpaces}
        onClick={() => presenter.onClick(CatalogType.Space)}
      >
        {t('space_spaces')}
      </CategoryButton>

      <CategoryButton
        className={styles.button}
        isSelected={presenter.isActiveBots}
        onClick={() => presenter.onClick(CatalogType.Bot)}
      >
        {t('bots_global')}
      </CategoryButton>
    </div>
  );
});
