import { useLocalStore } from 'mobx-react';
import { ElementType, FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Tasks from 'APP/Tasks';
import { openPopup } from 'APP/Tasks/app/popup/popup';
import { IUserNameBadgeIcon, mapUserProfileBadgeToIcon } from 'APP/constants/userBadges';
import {
  ChangePhoneAlertPopup,
  IChangePhoneAlertPopupParams,
} from 'APP/main/PopupManager/Popups/ChangePhoneAlertPopup/ChangePhoneAlertPopup';
import { ChangePhonePopup } from 'APP/main/PopupManager/Popups/ChangePhonePopup/ChangePhonePopup';
import { GroupType } from 'APP/model/group/groupModel.types';
import { UserProfileUsernameBadge } from 'APP/model/user/userModel.types';
import { useTranslation } from 'APP/packages/translations';
import { RouterLink, RouterState } from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import Entities from 'APP/store';
import IcAds from 'ICONS/ic-ads.svg';
import IcBlock from 'ICONS/ic-block.svg';
import IcCalendar from 'ICONS/ic-calendar.svg';
import IcGift from 'ICONS/ic-gift.svg';
import IcLogOut from 'ICONS/ic-log-out.svg';
import IcBot from 'ICONS/ic-m-stroke-bot.svg';
import IcSaveStroke from 'ICONS/ic-save-stroke.svg';
import IcSettings from 'ICONS/ic-settings-24.svg';
import IcUsers from 'ICONS/ic-users.svg';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import { BlockListPopup } from 'MAIN/PopupManager/Popups/BlockListPopup/BlockListPopup';
import { BotsCatalogPopup } from 'MAIN/PopupManager/Popups/BotsCatalogPopup/BotsCatalogPopup';
import { ContactsPopup } from 'MAIN/PopupManager/Popups/ContactsPopup/ContactsPopup';
import { EditProfilePopup } from 'MAIN/PopupManager/Popups/EditProfilePopup/EditProfilePopup';
import { RewardsPopup } from 'MAIN/PopupManager/Popups/RewardsPopup/RewardsPopup';
import { ScheduledCallsListPopup } from 'MAIN/PopupManager/Popups/ScheduledCalls/ScheduledCallsListPopup/ScheduledCallsListPopup';
import { UserSettingsPopup } from 'MAIN/PopupManager/Popups/UserSettingsPopup/UserSettingsPopup';
import { Popup } from 'STORE/App/Popups/Popup/Popup';
import { User } from 'STORE/Users/User/User';

interface IMenuItem {
  key: string;
  Icon: ElementType;
  title: string;
  withDot?: boolean;
  onClick(): void;
}

interface IMyProfilePresenter {
  childPopup: Popup | null;
  me: User | null;
  isRewardDotShowed: boolean;
  userNameBadge: IUserNameBadgeIcon | null;
  isVerified: boolean;
  menuItems: IMenuItem[];
  additionalMenuItems: IMenuItem[];
  adsUrl: string;
  openEditProfile(): void;
  openSavedMessagesChat(): Promise<void>;
  openBlackList(): void;
  openContacts(): void;
  openAds(): void;
  openSettings(): void;
  openRewards(): void;
  openScheduledCalls(): void;
  openChangePhone(): void;
  openBotCatalog(): void;
  logout(): void;
  openChildPopup(component: FC<ICommonPopupsProps>): void;
}

export function useMyProfilePresenter(popupInstance?: Popup): IMyProfilePresenter {
  const { t } = useTranslation();
  const navigateTo = useNavigateTo();
  const history = useHistory();

  const presenter = useLocalStore<IMyProfilePresenter>(() => ({
    childPopup: null,

    get me(): User | null {
      return Entities.UsersStore.Me;
    },

    openEditProfile(): void {
      presenter.openChildPopup(EditProfilePopup);
    },

    get isRewardDotShowed(): boolean {
      return Entities.rewards.hasAchievedRewards || Entities.rewards.hasNewRewards;
    },

    get userNameBadge(): IUserNameBadgeIcon | null {
      return presenter.me?.usernameBadge
        ? mapUserProfileBadgeToIcon[presenter.me?.usernameBadge as UserProfileUsernameBadge] || null
        : null;
    },

    get isVerified(): boolean {
      return presenter.me?.verified || false;
    },

    get menuItems(): IMenuItem[] {
      return [
        {
          key: 'scheduledEvents',
          Icon: IcCalendar,
          title: t('call_events'),
          onClick: presenter.openScheduledCalls,
          isVisible: true,
        },
        {
          key: 'rewards',
          Icon: IcGift,
          title: t('common_rewards'),
          onClick: presenter.openRewards,
          isVisible: true,
          withDot: presenter.isRewardDotShowed,
        },
        {
          key: 'saved-messages',
          Icon: IcSaveStroke,
          title: t('saved_messages'),
          onClick: presenter.openSavedMessagesChat,
          isVisible: true,
        },
        {
          key: 'contacts',
          Icon: IcUsers,
          title: t('contacts'),
          onClick: presenter.openContacts,
          isVisible: true,
        },
        {
          key: 'block-list',
          Icon: IcBlock,
          title: t('block_list'),
          onClick: presenter.openBlackList,
          isVisible: true,
        },
        {
          key: 'settings',
          Icon: IcSettings,
          title: t('common_settings'),
          onClick: presenter.openSettings,
          isVisible: true,
        },
        {
          key: 'ads',
          Icon: IcAds,
          title: t('common:common_gem4me_ads'),
          onClick: presenter.openAds,
          isVisible: Boolean(presenter.adsUrl),
        },
        {
          key: 'bot-catalog',
          Icon: IcBot,
          title: t('common_bot_catalog'),
          onClick: presenter.openBotCatalog,
          isVisible: true,
        },
      ].filter((item) => item.isVisible);
    },

    async openSavedMessagesChat(): Promise<void> {
      if (Entities.GroupsStore.savedMessagesGroup) {
        navigateTo({
          to: `/chats/${Entities.GroupsStore.savedMessagesGroup.id}`,
        });
        popupInstance?.close();
        return;
      }

      const group = await Tasks.group.createGroupAndInvite({
        type: GroupType.MySavedMessages,
      });

      if (group) {
        navigateTo({
          to: `/chats/${group.id}`,
        });
        popupInstance?.close();
      }
    },

    openBlackList(): void {
      presenter.openChildPopup(BlockListPopup);
    },

    openContacts(): void {
      presenter.openChildPopup(ContactsPopup);
    },

    get adsUrl(): string {
      return Entities.marketplace.baseAdsUrl;
    },

    openAds(): void {
      const adsUrl = presenter.adsUrl;

      if (adsUrl) {
        navigateTo({
          to: RouterLink.market,
          state: {
            [RouterState.url]: adsUrl,
          },
        });
        popupInstance?.close();
      }
    },

    openSettings(): void {
      presenter.openChildPopup(UserSettingsPopup);
    },

    openRewards(): void {
      presenter.openChildPopup(RewardsPopup);
    },

    openScheduledCalls(): void {
      presenter.openChildPopup(ScheduledCallsListPopup);
    },

    openChangePhone(): void {
      if (!presenter.me) {
        return;
      }
      presenter.childPopup = openPopup<IChangePhoneAlertPopupParams>(ChangePhoneAlertPopup, {
        params: {
          phoneNumber: presenter.me.displayPhone,
          onChangeClick: () => presenter.openChildPopup(ChangePhonePopup),
        },
      });
    },

    openBotCatalog(): void {
      openPopup(BotsCatalogPopup);
      popupInstance?.close();
    },

    get additionalMenuItems(): IMenuItem[] {
      return [
        {
          key: 'logout',
          Icon: IcLogOut,
          title: t('settings_activity_exit'),
          onClick: presenter.logout,
        },
      ];
    },

    logout(): void {
      Tasks.app.showConfirm({
        text: t('exit_alert'),
        async onSuccess(): Promise<void> {
          await Tasks.authorization.logout(true);
          history.replace(RouterLink.login);
        },
      });
    },

    openChildPopup(component: FC<ICommonPopupsProps>): void {
      popupInstance?.hide();

      presenter.childPopup = openPopup(component, {
        onClose: () => popupInstance?.close(),
        onBack: () => popupInstance?.show(),
      });
    },
  }));

  useEffect(() => {
    return () => presenter.childPopup?.close();
  }, []);

  return presenter;
}
