import {
  mapBotUserDisplayDataResponse,
  mapUserDisplayDataResponse,
} from 'APP/model/user/userModel.mapper';
import { GroupResponse } from 'APP/packages/api/types/model/groupResponse';
import { PersonalizedGroup } from 'APP/packages/api/types/model/personalizedGroup';

import { GroupUserRole, IGroup, IPersonalizedGroup } from './groupModel.types';

export const mapGroupResponse = (group: GroupResponse): IGroup => {
  return {
    ...group,
    pinnedMessages: group.pinnedMessages || [],
  };
};

export const mapPersonalizedGroupResponse = (group: PersonalizedGroup): IPersonalizedGroup => {
  return {
    ...group,
    groupResponse: mapGroupResponse(group.groupResponse),
    botOpponent: group.botOpponent ? mapBotUserDisplayDataResponse(group.botOpponent) : undefined,
    draftInput: group.draftInput
      ? {
          ...group.draftInput,
          updateTs: parseInt(group.draftInput?.updateTs, 10),
        }
      : undefined,
    opponent: group.opponent ? mapUserDisplayDataResponse(group.opponent) : undefined,
    userGroupRole: group.userGroupRole as string as GroupUserRole,
  };
};
