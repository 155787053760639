import React, { FC } from 'react';

import { openPopup } from 'APP/Tasks/app/popup/popup';
import bus from 'APP/packages/bus';
import { useTranslation } from 'APP/packages/translations';
import IcBot from 'ICONS/ic-m-stroke-bot.svg';
import { BotsCatalogPopup } from 'MAIN/PopupManager/Popups/BotsCatalogPopup/BotsCatalogPopup';
import { PopupLink } from 'UIKIT/PopupLink/PopupLink';

import styles from './OpenBotsCatalogButton.styles.m.css';

export const BOTS_CATALOG_ITEM_HEIGHT = 48;

export const OpenBotsCatalogButton: FC = () => {
  const { t } = useTranslation();

  const onClick = (): void => {
    bus.bots.broadcastOpenBotsCatalog();
    openPopup(BotsCatalogPopup);
  };

  return (
    <PopupLink
      className={styles.container}
      title={t('common_bot_catalog')}
      Icon={IcBot}
      onClick={onClick}
    />
  );
};
