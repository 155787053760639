import Entities from 'APP/store';

function checkIsLoadedLastMessages(group, messages) {
  if (!group.counter) {
    return false;
  }
  let lastMessage;
  let index = messages.length;
  do {
    lastMessage = messages[--index];
  } while (lastMessage?.isFake);

  return (
    lastMessage?.serverTime >= group.counter.lastMessageTs ||
    group.messagesStore.lastMessage?.serverTime >= group.counter.lastMessageTs ||
    group.messagesStore.isLoadedLastMessages
  );
}

export default ({ groupId, messages }) => {
  const group = Entities.GroupsStore.getGroupById(groupId);

  if (!group || group.isFake) {
    return;
  }

  const isLoadedLastMessages = checkIsLoadedLastMessages(group, messages);
  group.messagesStore.setLoadedLastMessages(isLoadedLastMessages);
};
