import { observer } from 'mobx-react';
import React from 'react';

import { IconButton } from 'APP/packages/UiKit/IconButton';
import { AddKeyPressHandler } from 'APP/packages/keyBoardHandler';
import { useTranslation } from 'APP/packages/translations';
import CloseIcon from 'ICONS/ic-close.svg';

import SaveArticleButton from './SaveArticleButton';
import usePresenter from './presenter';
import styles from './styles.m.css';

const Header = ({ core }) => {
  const presenter = usePresenter({ core });
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.leftBlock}>
        <IconButton
          className={styles.icon}
          Icon={CloseIcon}
          onClick={presenter.onCloseArticle}
          dataTest="close-button"
        />
        <AddKeyPressHandler onEsc={presenter.onCloseArticle} />
        <div className={styles.title}>
          {core.isEditMode
            ? t('channels:channel_article_edit_article')
            : t('channels:channel_new_article')}
        </div>
      </div>
      <div className={styles.rightBlock}>
        <SaveArticleButton parent={presenter} />
      </div>
    </div>
  );
};

export default observer(Header);
