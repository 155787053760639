import classNames from 'classnames';
import React, { FC } from 'react';
import { CSSTransition } from 'react-transition-group';

import { IChipsItem } from 'UIKIT/Chips/ChipsItem/ChipsItem.types';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';

import styles from './Chips.styles.m.scss';
import { ChipsItem } from './ChipsItem/ChipsItem';
import { useScrollToTheEnd } from './useScrollToTheEnd';

const ContainerCSSTransitionClasses = {
  enterActive: styles.enterActive,
  exitActive: styles.exitActive,
};

interface IChipsProps {
  chips: IChipsItem[];
  keyExtractor?(item: IChipsItem): string;
  onRemove(item: IChipsItem): void;
}

export const Chips: FC<IChipsProps> = ({ chips = [], keyExtractor, onRemove }) => {
  const { chipsRef } = useScrollToTheEnd(chips);

  return (
    <CSSTransition
      in={chips.length > 0}
      classNames={ContainerCSSTransitionClasses}
      timeout={300}
    >
      <div
        className={classNames(styles.container, {
          [styles.hasContent]: chips.length > 0,
        })}
      >
        <div
          className={classNames(styles.chips, customScrollStyles.container)}
          ref={chipsRef}
        >
          {chips.map((item) => {
            return (
              <ChipsItem
                key={keyExtractor ? keyExtractor(item) : item.id}
                item={item}
                onRemove={onRemove}
              />
            );
          })}
        </div>
      </div>
    </CSSTransition>
  );
};
