import classNames from 'classnames';
import React, { FC, ReactElement } from 'react';

import styles from './ProviderLayout.styles.m.css';
import { ProviderLayoutProcessing } from './ProviderLayoutProcessing/ProviderLayoutProcessing';

interface IProviderLayoutProps {
  icon: ReactElement;
  title: string;
  description: string | ReactElement;
  actions?: ReactElement | null;
  isProcessing?: boolean;
  disabled?: boolean;
}

export const ProviderLayout: FC<IProviderLayoutProps> = (props) => {
  const { icon, title, description, actions, isProcessing, disabled = false } = props;

  return (
    <div className={classNames(styles.container, { [styles.disabled]: disabled })}>
      <div className={styles.innerContainer}>
        <div className={styles.icon}>{icon}</div>
        <div>
          <h3 className={styles.title}>{title}</h3>
          <div className={styles.description}>
            {isProcessing ? <ProviderLayoutProcessing /> : description}
          </div>
        </div>
      </div>
      <div>{actions}</div>
    </div>
  );
};
