import Tasks from 'APP/Tasks';
import { IStickerPackUpdatedEventPayload } from 'APP/model/stickers/stickersModel.types';
import { storage } from 'APP/packages/storage';
import Entities from 'STORE';

import { GroupsEvent } from '../GroupsEvent';

export class StickerPackUpdated extends GroupsEvent<IStickerPackUpdatedEventPayload> {
  async process(): Promise<void> {
    if (!this.eventPayload) {
      //TODO: ask about reject error
      return;
    }

    const { stickerPackId } = this.eventPayload;
    await Tasks.stickers.loadStickerPackById(stickerPackId);

    const packs = Entities.stickersStore.transformToStickerPacks([
      ...Entities.stickersStore.installedPacks,
    ]);

    storage.installedStickerPacks.set({
      lastUpdateTime: this.ts,
      packs,
    });
  }
}
