import { useLocalStore, useAsObservableSource } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { useTranslation } from 'APP/packages/translations';
import Entities from 'APP/store';

export interface IJoinButtonPresenter {
  isLoading: boolean;
  title: string;
  onClick(): Promise<void>;
}

export const useJoinButtonPresenter = (groupId: string): IJoinButtonPresenter => {
  const { t } = useTranslation();
  const source = useAsObservableSource({ groupId });

  const presenter = useLocalStore<IJoinButtonPresenter>(() => ({
    isLoading: false,

    get title(): string {
      if (Entities.GroupsStore.getGroupById(source.groupId)?.isChannel) {
        return t('channels:channel_catalog_subscribe');
      }

      return t('calls_join');
    },

    async onClick(): Promise<void> {
      presenter.isLoading = true;
      await Tasks.group.joinGroup({ groupId: source.groupId });
      presenter.isLoading = false;
    },
  }));

  return presenter;
};
