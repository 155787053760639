import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Tasks from 'APP/Tasks';
import { ALERT_TYPES } from 'APP/constants/app';
import bus from 'APP/packages/bus';
import { useTranslation } from 'APP/packages/translations';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import Entities from 'APP/store';
import { Thread } from 'STORE/Groups/Thread';

interface IThreadInfoPresenter {
  thread: Thread | null;
  title: string;
  onBack(): Promise<void>;
  onDeleteThreadMessage(): Promise<void>;
}

export const useThreadInfoPresenter = (): IThreadInfoPresenter => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation<{ hasGoBack?: boolean }>();
  const navigateTo = useNavigateTo();

  const source = useAsObservableSource({ history, navigateTo, location });

  const presenter = useLocalStore<IThreadInfoPresenter>(() => ({
    get thread(): Thread | null {
      const activeGroup = Entities.GroupsStore.activeGroup;
      return activeGroup instanceof Thread ? activeGroup : null;
    },

    async onBack(): Promise<void> {
      const { hasGoBack } = source.location.state || {};

      if (hasGoBack) {
        source.history.goBack();
        return;
      }

      if (presenter.thread?.threadInfo) {
        const { groupId } = presenter.thread.threadInfo;
        await Tasks.group.setActiveGroup({ groupId });
      }
    },

    get title(): string {
      const count = presenter.thread?.counter?.totalMessagesCount || 0;
      return count ? t('comments_comments', { 0: count }) : t('comments_comment');
    },

    async onDeleteThreadMessage(): Promise<void> {
      const { groupId } = presenter.thread?.threadInfo || {};
      if (groupId) {
        await Tasks.group.setActiveGroup({ groupId });
        Tasks.app.addAlert({ type: ALERT_TYPES.MESSAGE_HAS_BEEN_DELETED });
      }
    },
  }));

  useEffect(() => {
    if (!presenter.thread?.threadInfo) {
      return;
    }

    const unsubscribe = bus.messaging.subscribeDeleteMessage(
      presenter.thread.threadInfo.groupId,
      presenter.thread.threadInfo.messageId,
      presenter.onDeleteThreadMessage
    );

    return () => {
      unsubscribe();
    };
  }, [presenter.thread?.threadInfo]);

  return presenter;
};
