export enum UpdateReason {
  LoadAbove = 'LOAD_ABOVE',
  LoadBelow = 'LOAD_BELOW',

  ScrollUnfocusedChat = 'SCROLL_UNFOCUSED_CHAT',

  // scroll to bottom if scroll position in bottom before edit
  EditMessage = 'EDIT_MESSAGE',
  ScrollToPreviousState = 'SCROLL_TO_PREVIOUS_STATE',

  ScrollToBottom = 'SCROLL_TO_BOTTOM',
  ScrollToNewMessage = 'SCROLL_TO_NEW_MESSAGES',
  FocusMessage = 'FOCUS_MESSAGE',
  NewMessage = 'NEW_MESSAGE',
}
