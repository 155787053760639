import React, { FC } from 'react';

import { IStoryImage } from 'APP/components/Stories/Stories.types';

import { useStorySimpleTimePresenter } from '../StorySimpleTimer/StorySimpleTimer.presenter';
import styles from './StoryImage.styles.m.css';

export interface IStoryImageProps {
  story: IStoryImage;
  onProgress(progress: number): void;
}

export const StoryImage: FC<IStoryImageProps> = (props) => {
  const { onMouseDown } = useStorySimpleTimePresenter(props);

  return (
    <div
      className={styles.container}
      role="button"
      tabIndex={0}
      onMouseDown={onMouseDown}
    >
      <img
        className={styles.image}
        alt={''}
        src={props.story.url}
        draggable={false}
      />
    </div>
  );
};
