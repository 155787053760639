import { ALERT_TYPES } from 'APP/constants/app';
import { ServerErrorCode } from 'APP/model/error/error.constants';
import { groupModel } from 'APP/model/model';
import Entities from 'APP/store';
import type { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

import { addAlert } from '../../app/showAlert/showAlert';

async function pin(message: ChatMessage): Promise<void> {
  const group = Entities.GroupsStore.getGroupById(message.groupId);
  const oldPinMessagesIds = group?.pinnedMessages.listIds || [];
  group?.pinnedMessages.set([message.id]);

  try {
    await groupModel.pinMessages({
      groupId: message.groupId,
      messageIds: [...oldPinMessagesIds, message.id],
    });
  } catch (e) {
    if (e?.message === ServerErrorCode.HasNoPermissionsToPerformAction) {
      addAlert({ type: ALERT_TYPES.NO_PERMISSIONS_TO_PERFORM_ACTION });
    }
    group?.pinnedMessages.clear();
    group?.pinnedMessages.set(oldPinMessagesIds);
  }
}

async function unpin(message: ChatMessage): Promise<void> {
  const group = Entities.GroupsStore.getGroupById(message.groupId);
  const oldPinMessagesIds = group?.pinnedMessages.listIds || [];
  const removePinMessagesIds = oldPinMessagesIds.filter(
    (pinMessageId) => pinMessageId === message.id
  );

  group?.pinnedMessages.removeByIds(removePinMessagesIds);

  try {
    await groupModel.unpinMessages({
      groupId: message.groupId,
      messageIds: removePinMessagesIds,
    });
  } catch (e) {
    if (e?.message === ServerErrorCode.HasNoPermissionsToPerformAction) {
      addAlert({ type: ALERT_TYPES.NO_PERMISSIONS_TO_PERFORM_ACTION });
    }
    group?.pinnedMessages.clear();
    group?.pinnedMessages.set(oldPinMessagesIds);
  }
}

export async function unpinMessages(groupId: string, messages: ChatMessage[]): Promise<void> {
  const group = Entities.GroupsStore.getGroupById(groupId);
  const oldPinMessagesIds = group?.pinnedMessages.listIds || [];
  const messagesIds = messages.map((message) => message.id);
  const removePinMessagesIds = oldPinMessagesIds.filter((pinMessageId) =>
    messagesIds.includes(pinMessageId)
  );

  group?.pinnedMessages.removeByIds(removePinMessagesIds);

  try {
    await groupModel.unpinMessages({
      groupId,
      messageIds: removePinMessagesIds,
    });
  } catch (e) {
    if (e?.message === ServerErrorCode.HasNoPermissionsToPerformAction) {
      addAlert({ type: ALERT_TYPES.NO_PERMISSIONS_TO_PERFORM_ACTION });
    }
    group?.pinnedMessages.clear();
    group?.pinnedMessages.set(oldPinMessagesIds);
  }
}

export async function unpinAll(groupId: string): Promise<void> {
  const group = Entities.GroupsStore.getGroupById(groupId);
  const oldPinMessagesIds = group?.pinnedMessages.listIds || [];
  group?.pinnedMessages.clear();

  try {
    if (oldPinMessagesIds.length) {
      await groupModel.unpinAllMessages({ groupId });
    }
  } catch (e) {
    if (e?.message === ServerErrorCode.HasNoPermissionsToPerformAction) {
      addAlert({ type: ALERT_TYPES.NO_PERMISSIONS_TO_PERFORM_ACTION });
    }
    group?.pinnedMessages.set(oldPinMessagesIds);
  }
}

export const pinUnpinMessage = (message: ChatMessage | null): void => {
  if (!message) {
    return;
  }

  if (message.isPinned) {
    unpin(message);
  } else {
    pin(message);
  }
};
