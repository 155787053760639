import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { GroupType } from 'APP/model/group/groupModel.types';
import Entities from 'APP/store';
import { Group } from 'APP/store/Groups/Group';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import { TAvatarBackgroundColorIndex } from 'UIKIT/Avatar/Avatar.types';

import { IGroupFormInitialValues } from '../../components/GroupForm/GroupForm.types';

export interface IEditChatGroupPopupPresenterParams {
  groupId: string;
}

export interface IEditChatGroupPopupPresenter {
  initialValues: IGroupFormInitialValues;
  group: Group | null;
  avatarTitle: string;
  avatarColor: TAvatarBackgroundColorIndex;
  onSubmit(groupData: IGroupFormInitialValues): Promise<void>;
}

export function useEditChatGroupPopupPresenter({
  params,
  popupInstance,
}: ICommonPopupsProps<IEditChatGroupPopupPresenterParams>): IEditChatGroupPopupPresenter {
  const { groupId } = params!;

  const presenter = useLocalStore<IEditChatGroupPopupPresenter>(() => ({
    get initialValues(): IGroupFormInitialValues {
      return {
        type: presenter.group?.type || GroupType.Closed,
        avatarUrl: presenter.group?.avatarUrl || '',
        name: presenter.group?.name,
        description: presenter.group?.description,
        deepLinkAlias: presenter.group?.deepLinkInfo?.deepLinkAlias || '',
      };
    },

    get group(): Group | null {
      return Entities.GroupsStore.getGroupById(groupId);
    },

    get avatarTitle(): string {
      return presenter.group?.avatarTitle || '';
    },

    get avatarColor(): TAvatarBackgroundColorIndex {
      return presenter.group?.avatarColorIndex || '';
    },

    async onSubmit(groupData: IGroupFormInitialValues): Promise<void> {
      await Tasks.group.updateGroupInfo({
        groupId: presenter.group?.id,
        ...groupData,
      });

      popupInstance.close();
    },
  }));

  useEffect(() => {
    if (
      !Entities.GroupsStore.isLoading &&
      !(presenter.group?.isChatGroup && presenter.group?.withMeInAdmins)
    ) {
      popupInstance.close();
    }
  }, [presenter.group, Entities.GroupsStore.isLoading]);

  return presenter;
}
