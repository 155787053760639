import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import Entities from 'STORE';
import { PushNotificationType } from 'STORE/PushNotifications/PushNotifications.types';

export const useHandleClosePushNotifications = () => {
  useEffect(() => {
    if (
      Entities.App.isFocused &&
      Entities.GroupsStore.activeGroupId &&
      !Entities.GroupsStore.activeGroup?.numberOfUnread
    ) {
      Tasks.pushNotifications.clearGroupNotificationByType(
        Entities.GroupsStore.activeGroupId,
        PushNotificationType.UserReactedOnMessage
      );
    }
  }, [
    Entities.App.isFocused,
    Entities.GroupsStore.activeGroupId,
    Entities.GroupsStore.activeGroup?.numberOfUnread,
  ]);
};
