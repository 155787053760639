import { useEffect, useState } from 'react';

import Tasks from 'APP/Tasks';
import { ICategoryInfo } from 'APP/model/common/commonModel.types';
import { Space } from 'STORE/Spaces/Space';

interface ISpaceInfoPresenter {
  categoryInfo: ICategoryInfo | null;
}

export function useSpaceInfoPresenter(space: Space): ISpaceInfoPresenter {
  const [categoryInfo, setCategoryInfo] = useState<ICategoryInfo | null>(null);

  useEffect(() => {
    if (space.categoryId) {
      Tasks.search.getCategoryInfoById(space.categoryId).then((info) => setCategoryInfo(info));
    }
  }, [space.categoryId]);

  return { categoryInfo };
}
