import { format } from 'bytes';
import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { SyntheticEvent } from 'react';

import Tasks from 'APP/Tasks';
import dateService from 'APP/packages/date';
import Entities from 'APP/store';
import { IDownloadProgress } from 'APP/store/Messages/DownloadProgress/DownloadProgress.types';
import { MessagesStore } from 'APP/store/Messages/Messages';
import FilePayload from 'STORE/Messages/Message/Payload/File';

export interface IFileItemProps {
  payloadId: string;
  groupId: string;
  messageId: string;
  payload: FilePayload;
  serverTime: string;
}

export interface IFileItemPresenter {
  messagesStore: MessagesStore | null;
  downloadProgress: IDownloadProgress | null;
  formattedBytes: string;
  formattedLoaded: string;
  formattedSize: string;
  dateTime: string | null;
  downloadPercent: number;
  onDownload(e: SyntheticEvent<SVGSVGElement | SVGGElement>): void;
}

export const useFileItemPresenter = (props: IFileItemProps): IFileItemPresenter => {
  const { payloadId, groupId, payload, serverTime, messageId } = props;
  const { fileSize } = payload;

  const source = useAsObservableSource({ payloadId, groupId, messageId });

  const presenter = useLocalStore<IFileItemPresenter>(() => ({
    get messagesStore(): MessagesStore | null {
      return Entities.GroupsStore.getGroupById(source.groupId)?.messagesStore || null;
    },

    get downloadProgress(): IDownloadProgress | null {
      return presenter.messagesStore?.downloadProgressPerPayload.get(source.payloadId) || null;
    },

    get formattedSize(): string {
      return format(fileSize, { unitSeparator: ' ' });
    },

    get formattedLoaded(): string {
      return format(presenter.downloadProgress?.progressEvent?.loaded || 0, {
        unitSeparator: ' ',
        decimalPlaces: 2,
        fixedDecimals: true,
      });
    },

    get dateTime(): string | null {
      return dateService.format(serverTime, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      });
    },

    get downloadPercent(): number {
      return presenter.downloadProgress?.downloadPercent || 0;
    },

    get formattedBytes(): string {
      return presenter.downloadPercent > 0 && presenter.downloadPercent < 100
        ? `${presenter.formattedLoaded} / ${presenter.formattedSize}`
        : `${presenter.formattedSize} ⋅ ${presenter.dateTime}`;
    },

    onDownload(e: SyntheticEvent<SVGSVGElement | SVGGElement>): void {
      e.stopPropagation();
      Tasks.downloadMessageFile({ payloadId, groupId, messageId });
    },
  }));

  return presenter;
};
