import { FinishReasons } from 'APP/model/call/callModel.types';
import { getDurationText } from 'APP/packages/date/helpers/getDurationText';
import { useTranslation } from 'APP/packages/translations';
import Entities from 'APP/store';

export function useCallPayloadPresenter(payload) {
  const { t } = useTranslation();
  const isMeInitiator = Entities.UsersStore.Me.id === payload.initiatorId;
  const isIncoming = Entities.UsersStore.Me.id !== payload.startInitiatorId;
  let duration = getDurationText(payload.duration);

  const initiator = isMeInitiator
    ? t('chat_details_myself')
    : Entities.UsersStore.getUserById(payload.initiatorId)?.displayName ||
      payload.initiatorNickName;

  if (payload.isP2P) {
    if (payload.isFinishCall) {
      switch (payload.reason) {
        case FinishReasons.Declined: {
          return t('system_messages:p2p_call_declined');
        }
        case FinishReasons.Unanswered: {
          return isIncoming
            ? t('system_messages:p2p_missed_call')
            : t('system_messages:p2p_call_no_answer');
        }
        default: {
          if (payload.initiatorId) {
            return t('system_messages:p2p_call_finished').replace('{0}', initiator) + duration;
          }
          return t('system_messages:group_call_finished');
        }
      }
    }
    return isIncoming
      ? t('system_messages:p2p_incoming_call')
      : t('system_messages:p2p_outgoing_call');
  }

  if (isMeInitiator) {
    if (payload.isFinishCall) {
      return (
        t('system_messages:group_call_finished_for_initiator').replace(
          '{0}',
          t('chat_details_myself')
        ) + duration
      );
    }
    return t('system_messages:group_call_started_for_initiator').replace(
      '{0}',
      t('chat_details_myself')
    );
  }

  if (payload.isFinishCall) {
    if (payload.initiatorId) {
      return (
        t('system_messages:group_call_finished_for_receiver').replace('{0}', initiator) + duration
      );
    }
    return t('system_messages:group_call_finished') + duration;
  }
  return t('system_messages:group_call_started_for_receiver').replace('{0}', initiator);
}
