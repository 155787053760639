import { format } from 'bytes';
import { observer } from 'mobx-react';
import React from 'react';

import { AudioPlayer } from 'APP/components/AudioPlayer/AudioPlayer';
import { AudioComponent } from 'APP/constants/audio';

export const AudioItem = observer((props) => {
  const { id: payloadId, messageId, groupId, payload, serverTime } = props;
  const { url, fileName, fileSize, duration, message } = payload;
  const formattedSize = format(fileSize, { unitSeparator: ' ' });

  return (
    <AudioPlayer
      src={url}
      fileName={fileName}
      size={fileSize}
      duration={Number(duration)}
      formattedSize={formattedSize}
      serverTime={serverTime}
      componentName={AudioComponent.SharedAudio}
      messageId={messageId}
      groupId={groupId || messageId}
      payloadId={payloadId}
      clientUuid={message.clientUuid}
    />
  );
});
