import classNames from 'classnames';
import React, { FC } from 'react';

import { CLICK_OUTSIDE_IGNORE_ATTR_NAME } from '../ClickOutside/constants';
import { Portal } from '../Portal/Portal';
import styles from './styles.m.scss';

interface IOverlayingPopupProps {
  overlayClassName?: string;
  popupClassName?: string;
  className?: string;
  isIgnoreClickOutside?: boolean;
  isOpened?: boolean;
  dataTest?: string;
  onClose?(): void;
}

export const OverlayingPopup: FC<IOverlayingPopupProps> = ({
  children,
  onClose,
  className = undefined,
  popupClassName,
  overlayClassName,
  isIgnoreClickOutside = false,
  isOpened,
  dataTest,
}) => {
  if (!isOpened) {
    return null;
  }

  return (
    <Portal>
      <div
        className={classNames(styles.container, className)}
        role="dialog"
        data-test={dataTest}
        {...{ [CLICK_OUTSIDE_IGNORE_ATTR_NAME]: isIgnoreClickOutside }}
      >
        <div
          className={classNames(styles.overlay, overlayClassName)}
          role="button"
          tabIndex={0}
          onClick={!isIgnoreClickOutside ? onClose : undefined}
        />
        <div className={classNames(styles.popup, popupClassName)}>{children}</div>
      </div>
    </Portal>
  );
};
