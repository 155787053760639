import classNames from 'classnames';
import React, { FC, memo } from 'react';

import animatedPartStyles from '../../AnimatedPart/AnimatedPart.styles.m.css';
import styles from './NewsfeedPlaceholderItem.styles.m.css';

interface INewsfeedPlaceholderItemProps {
  isAnimated: boolean;
}

export const NewsfeedPlaceholderItem: FC<INewsfeedPlaceholderItemProps> = memo(({ isAnimated }) => {
  const classesObj = {
    [animatedPartStyles.animation]: isAnimated,
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={classNames(styles.avatar, classesObj)} />
        <div>
          <div className={classNames(styles.title, classesObj)} />
          <div className={classNames(styles.description, classesObj)} />
        </div>
      </div>

      <div className={classNames(styles.image, classesObj)} />

      <div className={styles.content}>
        <div className={classNames(styles.text1, classesObj)} />
        <div className={classNames(styles.text2, classesObj)} />
        <div className={classNames(styles.text3, classesObj)} />
        <div className={classNames(styles.text4, classesObj)} />
      </div>

      <div className={styles.line} />

      <div className={styles.footer}>
        <div className={classNames(styles.action1, classesObj)} />
        <div className={classNames(styles.action2, classesObj)} />
        <div className={classNames(styles.action3, classesObj)} />
        <div className={classNames(styles.button, classesObj)} />
      </div>
    </div>
  );
});
