import classNames from 'classnames';
import React from 'react';
import ReactCalendar from 'react-calendar';

import LeftIcon from 'APP/icons/ic-left.svg';
import RightIcon from 'APP/icons/ic-right.svg';

import styles from './styles.m.css';

const Calendar = ({ className = undefined, value, maxDate = undefined, minDate, onChange }) => {
  return (
    <ReactCalendar
      className={classNames(styles.calendar, className)}
      defaultValue={value}
      maxDate={maxDate}
      minDate={minDate}
      nextLabel={<RightIcon />}
      next2Label={null}
      prevLabel={<LeftIcon />}
      prev2Label={null}
      view="month"
      onChange={onChange}
    />
  );
};

export default Calendar;
