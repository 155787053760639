/* global YT */
import { useLocalStore, useAsObservableSource } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { AudioSourceType } from 'APP/constants/app';

import { loadYouTubeApi } from './Tasks/loadYouTubeApi';

export const useYouTubePlayerPresenter = ({ id, videoId, isAutoplay, timecode }) => {
  const source = useAsObservableSource({ id, videoId, isAutoplay, timecode });

  const presenter = useLocalStore(() => ({
    player: null,
    isReadyPlayer: false,
    isRegisteredVideo: false,
    removeAudioSource: null,
    stopApiLoading: null,

    get videoUrl() {
      return (
        `https://www.youtube.com/embed/${source.videoId}` +
        `?enablejsapi=1` +
        `&origin=${window.origin}` +
        `&autoplay=${source.isAutoplay ? 1 : 0}` +
        `&start=${presenter.startTime}`
      );
    },

    get allows() {
      const allowList = [
        'accelerometer',
        'clipboard-write',
        'encrypted-media',
        'gyroscope',
        'picture-in-picture',
      ];

      if (source.isAutoplay) {
        allowList.push('autoplay');
      }

      return allowList.join('; ');
    },

    get startTime() {
      const value = parseFloat(source.timecode);
      if (!value) {
        return 0;
      }

      let factor = 1;
      const unit = String(source.timecode).slice(-1);
      if (unit === 'm') {
        factor = 60;
      } else if (unit === 'h') {
        factor = 60 * 60;
      }

      return Math.round(value * factor);
    },

    createYouTubeInstance() {
      if (!YT) {
        return;
      }

      presenter.player = new YT.Player(source.id);
      presenter.player.addEventListener('onReady', presenter.onReadyPlayer);
    },

    destroyYouTubeInstance() {
      if (presenter.stopApiLoading) {
        presenter.stopApiLoading();
      }

      if (presenter.isRegisteredVideo) {
        presenter.clearAudioSource();
      }

      if (presenter.isReadyPlayer) {
        presenter.player.removeEventListener('onStateChange', presenter.onStateChange);
      }
    },

    onReadyPlayer() {
      presenter.isReadyPlayer = true;
      presenter.player.addEventListener('onStateChange', presenter.onStateChange);
    },

    onStateChange(event) {
      switch (event.data) {
        case YT.PlayerState.ENDED:
        case YT.PlayerState.PAUSED: {
          if (presenter.isRegisteredVideo) {
            presenter.clearAudioSource();
            presenter.unregisterYouTubeStream();
          }
          break;
        }
        case YT.PlayerState.PLAYING: {
          if (!presenter.isRegisteredVideo) {
            presenter.registerYouTubeStream();
          }
          break;
        }
      }
    },

    unregisterYouTubeStream() {
      presenter.isRegisteredVideo = false;
      if (presenter.isReadyPlayer) {
        presenter.player.pauseVideo();
      }
      presenter.clearAudioSource();
    },

    registerYouTubeStream() {
      presenter.isRegisteredVideo = true;
      presenter.removeAudioSource = Tasks.app.audioSource.setCurrentSource(
        AudioSourceType.Youtube,
        presenter.unregisterYouTubeStream
      );
    },

    clearAudioSource() {
      if (presenter.removeAudioSource) {
        presenter.removeAudioSource();
        presenter.removeAudioSource = null;
      }
    },
  }));

  useEffect(() => {
    presenter.stopApiLoading = loadYouTubeApi(presenter.createYouTubeInstance);
    return presenter.destroyYouTubeInstance;
  }, []);

  return presenter;
};
