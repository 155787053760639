import { observer } from 'mobx-react';
import React from 'react';
import { CSSTransition } from 'react-transition-group';

import Entities from 'STORE';

import styles from './Opponents.styles.m.css';
import { OpponentList } from './components/OpponentList/OpponentList';

const OpponentsCSSTransitionClasses = {
  enter: styles.opponentsEnter,
  enterActive: styles.opponentsEnterActive,
  exit: styles.opponentsExit,
  exitActive: styles.opponentsExitActive,
};

export const Opponents = observer(() => {
  return (
    <CSSTransition
      in={Entities.OngoingCall.presentVideoMode.isShowOpponents}
      classNames={OpponentsCSSTransitionClasses}
      timeout={100}
      mountOnEnter
      unmountOnExit
    >
      <OpponentList />
    </CSSTransition>
  );
});
