import { action, computed, observable, values } from 'mobx';

import dateService from 'APP/packages/date';

class Events {
  @observable data = new Map();

  constructor(call) {
    this.call = call;
  }

  @computed
  get list() {
    return values(this.data);
  }

  @action
  addEvent = (data) => {
    let key = +dateService.now();
    if (this.data.has(key)) {
      key++;
    }
    this.data.set(key, { timestamp: key, ...data });
  };

  @action
  deleteEvent(key) {
    this.data.delete(key);
  }

  dispose() {
    this.data.clear();
  }
}

export default Events;
