import { useAsObservableSource, useLocalStore } from 'mobx-react';

import { TargetForwardType } from 'APP/constants/forward';
import { useTranslation } from 'APP/packages/translations';
import SearchService from 'SERVICES/search';
import Entities from 'STORE';
import { Group } from 'STORE/Groups/Group';
import { P2P } from 'STORE/Groups/P2P';
import { User } from 'STORE/Users/User/User';
import { getSortedGroups } from 'UTILS/getSortedGroups';

import { IForwardTargetData, ISharedDataViewItemData } from '../ShareDataView.types';

interface ISharedDataViewCommonPresenter {
  chats: Group[];
  channels: Group[];
  contacts: User[];
  chatsItems: ISharedDataViewItemData[];
  channelsItems: ISharedDataViewItemData[];
  contactsItems: ISharedDataViewItemData[];
  groupsItems: ISharedDataViewItemData[];
  infoBlockTitle: string;
  infoBlockDescriptions: string[];
  keyExtractor(item: IForwardTargetData): string;
  filterGroupsBySearch(groups: Group[], searchValue: string): Group[];
  sortGroups(groups: Group[]): Group[];
  filterContactsByGroupOpponent(contacts: User[], groups: Group[]): User[];
  mapSharedDataViewItem(itemType: TargetForwardType, item: Group | User): ISharedDataViewItemData;
}

export const useSharedDataViewCommonPresenter = (
  searchValue: string
): ISharedDataViewCommonPresenter => {
  const { t } = useTranslation();

  const source = useAsObservableSource({ searchValue });

  const presenter = useLocalStore<ISharedDataViewCommonPresenter>(() => ({
    get chats(): Group[] {
      let filteredChats = Entities.GroupsStore.chatGroups.filter((group) => {
        if (group instanceof P2P) {
          return !group.isBlockedByMe && !group.isBlockedMe && !group.isBot;
        }
        return true;
      });

      filteredChats = presenter.filterGroupsBySearch(filteredChats, source.searchValue);
      const sortedChats = presenter.sortGroups(filteredChats);

      if (!Entities.GroupsStore.hasSavedMessagesGroup) {
        sortedChats.unshift(Entities.GroupsStore.fakeSavedMessagesGroup);
      }

      return sortedChats;
    },

    get channels(): Group[] {
      let filteredChannels = Entities.GroupsStore.channels.filter(
        (channel) => channel.withMeInAdmins
      );
      filteredChannels = presenter.filterGroupsBySearch(filteredChannels, source.searchValue);

      return presenter.sortGroups(filteredChannels);
    },

    get contacts(): User[] {
      const users = Entities.UsersStore.getFilteredContacts(
        [],
        [Entities.UsersStore.Me.id],
        source.searchValue.toLowerCase()
        // TODO remove as unknown as User[] when User store will be refactored on typescript
      ) as unknown as User[];

      return presenter.filterContactsByGroupOpponent(users, presenter.chats);
    },

    get chatsItems(): ISharedDataViewItemData[] {
      return presenter.chats.map((chat) =>
        presenter.mapSharedDataViewItem(TargetForwardType.Chat, chat)
      );
    },

    get channelsItems(): ISharedDataViewItemData[] {
      return presenter.channels.map((channel) =>
        presenter.mapSharedDataViewItem(TargetForwardType.Channel, channel)
      );
    },

    get contactsItems(): ISharedDataViewItemData[] {
      return presenter.contacts.map((contact) =>
        presenter.mapSharedDataViewItem(TargetForwardType.Contact, contact)
      );
    },

    get groupsItems(): ISharedDataViewItemData[] {
      const sortGroups = presenter.sortGroups([...presenter.chats, ...presenter.channels]);
      return sortGroups.map((group) =>
        presenter.mapSharedDataViewItem(
          group.isChannel ? TargetForwardType.Channel : TargetForwardType.Chat,
          group
        )
      );
    },

    get infoBlockTitle(): string {
      return !source.searchValue ? t('no_chats_yet') : '';
    },

    get infoBlockDescriptions(): string[] {
      return !source.searchValue ? [t('common_no_chat_to_forward')] : [t('no_results_found')];
    },

    keyExtractor(item: IForwardTargetData): string {
      return item.data.itemId;
    },

    filterGroupsBySearch(groups: Group[], searchValue: string): Group[] {
      return searchValue ? SearchService.findLocalGroup(searchValue, groups) : groups;
    },

    sortGroups(groups: Group[]): Group[] {
      return getSortedGroups(groups)
        .slice()
        .sort((a, b) => {
          if (a.isSavedMessages) return -1;
          if (b.isSavedMessages) return 1;
          return 0;
        });
    },

    filterContactsByGroupOpponent(contacts: User[], groups: Group[]): User[] {
      const groupOpponentsIds = groups.map((group) => group.opponentId);

      return contacts.filter((contact) => !groupOpponentsIds.includes(contact.id));
    },

    mapSharedDataViewItem(
      itemType: TargetForwardType,
      item: Group | User
    ): ISharedDataViewItemData {
      return {
        itemType,
        id: item.id,
        type: item instanceof Group ? item.type : undefined,
        itemId: `${itemType}-${item.id}`,
        route: item.route,
        avatarTitle: item.avatarTitle || '',
        avatarUrl: item.avatarUrl || '',
        avatarColorIndex: item.avatarColorIndex,
        blockedByMe: item instanceof User ? item.isBlockedByMe : item.blockedByMe,
        blockedMe: item instanceof User ? item.isBlockedMe : item.blockedMe,
        opponentId: item instanceof Group ? item.opponentId : null,
        verified: item.verified,
      };
    },
  }));

  return presenter;
};
