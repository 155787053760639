import Tasks from 'APP/Tasks';
import { ALERT_TYPES } from 'APP/constants/app';
import { STREAM_ACTION } from 'APP/constants/stream';
import api from 'APP/packages/api';
import logger from 'APP/packages/logger';

export default async ({ channelId, userId }) => {
  try {
    logger.get('Tasks').debug('streaming.forceMute', { channelId, userId });

    await api.streams.actionOnStream({
      channelId,
      userId,
      action: STREAM_ACTION.FORCE_MUTE,
    });
  } catch (e) {
    logger.get('Tasks').error('streaming.forceMute', e);
    Tasks.app.addAlert({
      type: ALERT_TYPES.CALL_ERROR_APPEARED,
    });
  }
};
