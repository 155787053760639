export enum ApplicationTheme {
  Dark = 'dark',
  Light = 'light',
}

export const DEFAULT_APPLICATION_THEME = ApplicationTheme.Light;

export enum ChatBackgroundPattern {
  WithoutPattern = 'without-pattern',
  Pattern10 = 'pattern-10',
  Pattern20 = 'pattern-20',
  Pattern30 = 'pattern-30',
  Pattern40 = 'pattern-40',
  Pattern50 = 'pattern-50',
  Pattern60 = 'pattern-60',
  Pattern70 = 'pattern-70',
  Pattern80 = 'pattern-80',
  Pattern90 = 'pattern-90',
  Pattern100 = 'pattern-100',
  Pattern110 = 'pattern-110',
}

export const DEFAULT_CHAT_BACKGROUND_PATTERN = ChatBackgroundPattern.Pattern10;
