export default (strings, currentLanguage, defaultLanguage) => {
  const resources = {};

  resources[currentLanguage] = strings[0];

  if (strings[1]) {
    resources[defaultLanguage] = strings[1];
  }

  return resources;
};
