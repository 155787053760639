import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { TextPlaceholder } from 'APP/packages/placeholders/TextPlaceholder/TextPlaceholder';
import { useTranslation } from 'APP/packages/translations';
import popupItemStyles from 'UIKIT/PopupItem/styles.m.scss';

import styles from './styles.m.css';

export const CallPermissionView = observer(({ permissionLabel, permissionValue, onClick }) => {
  const { t } = useTranslation();

  return (
    <div
      role="button"
      tabIndex="0"
      onClick={onClick}
      className={classNames(styles.container, popupItemStyles.interactive)}
    >
      {permissionValue ? (
        <>
          <div className={styles.label}>{t(permissionLabel)}</div>
          <div className={styles.value}>{t(permissionValue)}</div>
        </>
      ) : (
        <>
          <TextPlaceholder
            className={styles.gap}
            width={140}
          />
          <TextPlaceholder
            className={styles.gap}
            width={140}
          />
        </>
      )}
    </div>
  );
});
