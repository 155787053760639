import { GroupType } from 'APP/model/group/groupModel.types';
import api from 'APP/packages/api';

const MIN_FIELD_CHANNEL_ALIAS_LENGTH = 2;
const ALIAS_REGEXP = /^[a-zA-Z0-9-_]+$/;

export const GROUP_ALIAS_ERRORS = {
  IS_EMPTY: 'IS_EMPTY',
  TOO_SHORT: 'TOO_SHORT',
  INCORECT_SYMBOLS: 'INCORECT_SYMBOLS',
  IS_ALREADY_EXIST: 'IS_ALREADY_EXIST',
};

export const groupAliasCreateValidation = ({ value, values, errorToText }) => {
  if (values.type !== GroupType.Open) {
    return null;
  }

  if (value?.length > 0 && value?.length < MIN_FIELD_CHANNEL_ALIAS_LENGTH) {
    return errorToText[GROUP_ALIAS_ERRORS.TOO_SHORT];
  }

  if (!ALIAS_REGEXP.test(value)) {
    return errorToText[GROUP_ALIAS_ERRORS.INCORECT_SYMBOLS];
  }

  return null;
};

export const groupAliasEditValidation = ({ value, values, errorToText }) => {
  if (values.type !== GroupType.Open) {
    return null;
  }

  const createError = groupAliasCreateValidation({ value, values, errorToText });

  if (createError) {
    return createError;
  }

  if (!value || value.length === 0) {
    return errorToText[GROUP_ALIAS_ERRORS.IS_EMPTY];
  }

  return null;
};

export const groupAliasAsyncValidation = async ({ value, values, errorToText }) => {
  if (values.type !== GroupType.Open) {
    return null;
  }

  try {
    const response = await api.group.deepLinkExists({
      deepLinkAlias: value,
    });

    if (response?.result === false) {
      return null;
    }
  } catch (error) {
    console.error(error);
  }

  return errorToText[GROUP_ALIAS_ERRORS.IS_ALREADY_EXIST];
};
