import { useLocalStore, useAsObservableSource } from 'mobx-react';

import Tasks from 'APP/Tasks';

export default ({ group }) => {
  const source = useAsObservableSource({ group });

  const presenter = useLocalStore(() => ({
    async onBack() {
      if (source.group?.groupId) {
        await Tasks.group.setActiveGroup({ groupId: source.group?.groupId });
      }
    },
  }));

  return presenter;
};
