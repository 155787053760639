import { openPopup } from 'APP/Tasks/app/popup/popup';
import { showAlert } from 'APP/Tasks/app/showAlert/showAlert';
import { ElectronApi } from 'APP/packages/electron/ElectronApi';
import { SelectDesktopSharingScreenPopup } from 'MAIN/PopupManager/Popups/SelectDesktopSharingScreenPopup/SelectDesktopSharingScreenPopup';
import { t } from 'MODULE/i18n/service';

export async function getDesktopScreenForSharing(): Promise<string | null> {
  if (ElectronApi.isMac) {
    const hasPermission = await ElectronApi.getScreenRecordingPermission();

    if (!hasPermission) {
      showAlert({
        html: t('system_preferences_screen_sharing').replace(
          '{0}',
          `<a href="x-apple.systempreferences:com.apple.preference.security?Privacy_ScreenCapture">${t(
            'system_preferences_common'
          )}</a>`
        ),
      });

      return null;
    }
  }

  return new Promise((resolve) => {
    openPopup(SelectDesktopSharingScreenPopup, {
      async onClose(electronSourceId?: string): Promise<void> {
        resolve(electronSourceId || null);
      },
    });
  });
}
