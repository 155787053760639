import { action, observable } from 'mobx';

import { IPushNotificationData, PushNotificationType } from './PushNotifications.types';

export class PushNotifications {
  @observable isEnabled = false;

  notifications: Notification[] = [];

  @action
  public reset(): void {
    this.notifications = [];
    this.isEnabled = false;
  }

  @action
  public setEnabled(isEnabled: boolean): void {
    this.isEnabled = isEnabled;
  }

  public addNotification(notification: Notification): void {
    this.notifications.push(notification);
  }

  public removeNotificationByRef(notification: Notification): void {
    this.notifications = this.notifications.filter((item) => item !== notification);
    notification.close();
  }

  public removeNotificationByMessageId(groupId: string, messageId: string): void {
    this.clearNotificationsByComparison(
      (data) => data.group === groupId && data.messageId === messageId
    );
  }

  public clearNotificationsByGroupId(groupId: string): void {
    this.clearNotificationsByComparison((data) => data.group === groupId);
  }

  public clearNotificationByGroupIdAndType(groupId: string, type: PushNotificationType): void {
    this.clearNotificationsByComparison((data) => data.group === groupId && data.pushType === type);
  }

  public clearNotifications(): void {
    this.notifications.forEach((notification) => notification.close());
    this.notifications = [];
  }

  private clearNotificationsByComparison(callback: (data: IPushNotificationData) => boolean): void {
    this.notifications = this.notifications.filter((notification) => {
      if (!notification?.data || callback(notification.data as IPushNotificationData)) {
        notification?.close();
        return false;
      }
      return true;
    });
  }
}
