import api from 'APP/packages/api';
import dateService from 'APP/packages/date';

export default (groupId, messages, callback) => {
  const now = dateService.now().getTime();
  messages.forEach(async (msg, i) => {
    try {
      await api.messaging.sendNewMessage({
        ...msg.toJSON(),
        groupId,
        id: msg.clientUuid,
        clientTime: now + i,
      });

      callback(msg, true);
    } catch (error) {
      console.error(error);
      callback(msg, false);
    }
  });
};
