import { useAsObservableSource, useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { sortReactions } from 'UTILS/sortReactions';

import { SEEN_TAB_NAME } from '../constants';
import User from './Entities/User';

export default ({ userReactionsList, userSeen, tabs, popupInstance, groupId, messageId }) => {
  const source = useAsObservableSource({ userSeen });

  const presenter = useLocalStore(() => ({
    initUserReactions: async () => {
      const users = await source.userSeen.loadUsersViewedMessage();
      const userReactions = await Tasks.reactions.getUsersReactionsForMessage({
        groupId: groupId,
        messageId: messageId,
      });

      if (!Object.keys(userReactions).length && !users.length) {
        popupInstance.close();
        return;
      }

      const sortedReactions = Object.keys(userReactions).sort((reactionId1, reactionId2) => {
        return sortReactions(
          { id: reactionId1, count: userReactions[reactionId1]?.length || 0 },
          { id: reactionId2, count: userReactions[reactionId2]?.length || 0 }
        );
      });

      const list = sortedReactions.map((reactionId) => {
        return {
          key: reactionId,
          list: userReactions[reactionId].map((userId) => {
            return presenter.createUser({ userId, groupId, messageId });
          }),
        };
      });

      userReactionsList.userReactions = list;
      tabs.initTabs([{ key: SEEN_TAB_NAME, list: users }, ...list]);
    },

    createUser: (data) => {
      return new User(presenter, data);
    },

    deleteMyReactions: async () => {
      await Tasks.messaging.updateMessageReaction({ groupId: groupId, messageId: messageId });
      await presenter.initUserReactions();
    },
  }));

  return presenter;
};
