import { BUCKET_MEDIA_HOST } from './constants';

export function getFixedSizeImage(
  url: string,
  options: {
    width: number;
    height: number;
    isNeedCrop?: boolean;
  },
  size?: {
    width: number;
    height: number;
  }
): string {
  const pixelRatio = window.devicePixelRatio || 1;
  const { width, height } = options;
  if (url && url.includes(BUCKET_MEDIA_HOST) && (width || height)) {
    const params = [];

    if (width) {
      params.push(`w${Math.floor(width * pixelRatio)}`);
    }

    if (height) {
      params.push(`h${Math.floor(height * pixelRatio)}`);
    }

    if (options.isNeedCrop) {
      // found experimentally.
      // Resizer breaks at ratio of less than 0.3 and more than 3.3
      if (size && size.width && size.height) {
        const ratio = size.width / size.height;
        if (ratio > 0.03 && ratio < 33.3) {
          params.push('n');
        }
      } else {
        params.push('n');
      }
    }

    if (window.supportsWEBP) {
      params.push('rw');
    }

    return url.split('=')[0] + '=' + params.join('-');
  }
  return url;
}
