import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { SpaceProfile } from 'MAIN/Profile/SpaceProfile/SpaceProfile';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { useClosePopupOnNavigation } from '../../hooks/useClosePopupOnNavigation';
import {
  ISpaceProfilePopupParams,
  useSpaceProfilePopupPresenter,
} from './SpaceProfilePopup.presenter';
import styles from './SpaceProfilePopup.styles.m.css';

export const SpaceProfilePopup: FC<ICommonPopupsProps<ISpaceProfilePopupParams>> = observer(
  (props: ICommonPopupsProps<ISpaceProfilePopupParams>) => {
    const presenter = useSpaceProfilePopupPresenter(props);
    useClosePopupOnNavigation(props.popupInstance);

    return (
      <Popup
        isOpened={!!presenter.space}
        isHidden={props.popupInstance.isHidden}
        onClose={presenter.onClose}
      >
        {presenter.space && (
          <SpaceProfile
            className={styles.profile}
            metaBlockClassName={styles.profileMetaBlock}
            space={presenter.space}
            popupInstance={props.popupInstance}
            onClose={presenter.onClose}
          />
        )}
      </Popup>
    );
  }
);
