import { observer } from 'mobx-react';
import React from 'react';

import { FilePayload } from 'MAIN/ChatView/Messages/Message/Payload/FilePayload/FilePayload';

import styles from './File.styles.m.css';
import usePresenter from './presenter';

const ArticleRedactorFile = ({ parentPresenter, payload, order, id }) => {
  const presenter = usePresenter({ parentPresenter, payload, order, id });

  return (
    <FilePayload
      className={styles.container}
      message={presenter.message}
    />
  );
};

export default observer(ArticleRedactorFile);
