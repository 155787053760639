import { useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { pushNotifications } from 'APP/packages/permissions/permissions';
import { platformInfo } from 'APP/packages/platformInfo/platformInfo';

const getShowDefault = () => {
  if (platformInfo.isFF) {
    return pushNotifications.getCurrentPermission() === 'default';
  }

  return false;
};

export default () => {
  const presenter = useLocalStore(() => ({
    isShowDialog: getShowDefault(),
    onClose() {
      presenter.isShowDialog = false;
      Tasks.pushNotifications.requestPermission();
    },
  }));

  return presenter;
};
