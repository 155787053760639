import { RLottieHandler } from 'APP/libs/rLottie/RLottieHandler';

class RLottieRunner {
  private rafId: number;
  private isRunning: boolean;
  private handlers: RLottieHandler[];
  private rerenderHandlers: Record<string, () => void>;

  constructor() {
    this.handlers = [];
    this.rerenderHandlers = {};
    this.isRunning = false;

    this.loop = this.loop.bind(this);
    this.addHandler = this.addHandler.bind(this);
    this.removeHandler = this.removeHandler.bind(this);

    document.addEventListener('visibilitychange', () => this.visibilityChangeHandler());
  }

  public addHandler(handler: RLottieHandler): void {
    if (!this.handlers.includes(handler)) {
      this.handlers.push(handler);
    }

    if (!this.isRunning) {
      this.isRunning = true;
      this.loop();
    }
  }

  public addRerenderHandler(id: string, handler: () => void): void {
    this.rerenderHandlers[id] = handler;
  }

  public removeRerenderHandler(id: string): void {
    delete this.rerenderHandlers[id];
  }

  public removeHandler(handler: RLottieHandler): void {
    const handlerIndex = this.handlers.findIndex((h) => h === handler);

    if (handlerIndex !== -1) {
      this.handlers.splice(handlerIndex, 1);
    }

    if (this.isRunning && this.handlers.length === 0) {
      this.stop();
    }
  }

  public stop(): void {
    this.isRunning = false;
    if (this.rafId) {
      window.cancelAnimationFrame(this.rafId);
    }
  }

  private loop(): void {
    if (this.isRunning) {
      this.handlers.forEach((handler) => handler.onLoop());
      this.rafId = window.requestAnimationFrame(this.loop);
    }
  }

  private visibilityChangeHandler(): void {
    if (document.hidden) {
      return;
    }

    Object.values(this.rerenderHandlers).forEach((handler) => handler());
  }
}

export const rLottieRunner = new RLottieRunner();
