import Base from 'STORE/base';

export class Payload extends Base {
  payloadType;
  message;

  constructor(data) {
    super(data);

    this.payloadType = data.payloadType;
    this.message = data.message;
  }

  get typeDescription() {
    return 'Message';
  }

  get isSystem() {
    return false;
  }

  get isCall() {
    return false;
  }

  get canBeDelete() {
    return true;
  }
}
