import { searchItemsByName } from 'APP/model/catalog/catalogModel';
import { CatalogType, ICatalogItemsList } from 'APP/model/catalog/catalogModel.types';

interface ISearchCatalogData {
  query: string;
  cursor?: string;
}

const ITEMS_LOAD_COUNT = 20;

export const searchCatalog = async (
  data: ISearchCatalogData
): Promise<ICatalogItemsList | null> => {
  try {
    return await searchItemsByName({
      ...data,
      catalogType: CatalogType.All,
      limit: ITEMS_LOAD_COUNT,
    });
  } catch {
    return null;
  }
};
