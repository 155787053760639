import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { useFileDownloadStatePresenter } from 'APP/hooks/useFileDownloadState/useFileDownloadState';
import { FileState } from 'MAIN/components/FileState/FileState';

import { AllDownloadsItemInfo } from '../AllDownloadsItemInfo/AllDownloadsItemInfo';

export interface IAllDownloadsFileItemProps {
  className?: string;
  payloadId: string;
  groupId: string;
  messageId: string;
}

export const AllDownloadsFileItem: FC<IAllDownloadsFileItemProps> = observer((props) => {
  const { className, payloadId, groupId, messageId } = props;

  const presenter = useFileDownloadStatePresenter({ groupId, messageId, payloadId });

  return (
    <div className={className}>
      <FileState
        groupId={groupId}
        messageId={messageId}
        payloadId={payloadId}
        onClick={presenter.onDownload}
      />
      <AllDownloadsItemInfo
        payloadId={payloadId}
        groupId={groupId}
        messageId={messageId}
      />
    </div>
  );
});
