import { useLocalStore } from 'mobx-react';
import { useEffect, FC } from 'react';

import Tasks from 'APP/Tasks';
import { PayloadType } from 'APP/model/message/messageModel.types';
import { ISharedDataItem } from 'APP/model/sharedData/sharedDataModel.types';
import { useTranslation } from 'APP/packages/translations';
import Entities from 'STORE';
import { Group } from 'STORE/Groups/Group';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';
import AudioPayload from 'STORE/Messages/Message/Payload/Audio';
import { CircleVideo } from 'STORE/Messages/Message/Payload/CircleVideo/CircleVideo';
import VoicePayload from 'STORE/Messages/Message/Payload/Voice';
import { MiniPlayer } from 'STORE/MiniPlayer/MiniPlayer';

import { MiniAudioPlayer } from './MiniAudioPlayer/MiniAudioPlayer';
import {
  IMiniPlayerTrack,
  IMiniPlayerConfig,
  IMiniPlayerProps,
  TConfigUpdateHandler,
  TChangeTrackHandler,
  TProgressChangeHandler,
  TTogglePlayHandler,
  TTitleClickHandler,
  TCloseHandler,
} from './MiniPlayer.types';
import { getVoiceMessageDescription } from './MiniPlayer.utils';
import { MiniRoundVideoPlayer } from './MiniRoundVideoPlayer/MiniRoundVideoPlayer';

type TSharedDataItem = ISharedDataItem<AudioPayload | VoicePayload | CircleVideo>;

interface IMiniPlayer {
  track: IMiniPlayerTrack | null;
  config: IMiniPlayerConfig;
  player: MiniPlayer;
  group: Group | null;
  message: ChatMessage | null;
  isPlaying: boolean;
  isOpened: boolean;
  isShowPlayList: boolean;
  isVoicePlayList: boolean;
  playList: IMiniPlayerTrack[];
  sharedDataItems: TSharedDataItem[];
  progress: number | null;
  progressPercentage: number | null;
  playerComponent: FC<IMiniPlayerProps> | null;
  init(): void;
  destroy(): void;
  onSharedItemsChange(): void;
  onConfigUpdate: TConfigUpdateHandler;
  onChangeTrack: TChangeTrackHandler;
  onProgressChange: TProgressChangeHandler;
  onTogglePlay: TTogglePlayHandler;
  onTitleClick: TTitleClickHandler;
  onClose: TCloseHandler;
}

export function useMiniPlayerPresenter(): IMiniPlayer {
  const { t } = useTranslation();

  const presenter = useLocalStore<IMiniPlayer>(() => ({
    track: null,
    config: { speed: 1 },

    get player(): MiniPlayer {
      return Entities.miniPlayer;
    },

    get group(): Group | null {
      return presenter.player.groupId
        ? Entities.GroupsStore.getGroupById(presenter.player.groupId)
        : null;
    },

    get message(): ChatMessage | null {
      if (!presenter.player.messageId) {
        return null;
      }

      return presenter.group?.messagesStore?.getMessageById(presenter.player.messageId) || null;
    },

    get isPlaying(): boolean {
      return presenter.player.isPlaying;
    },

    get isOpened(): boolean {
      return presenter.player.isOpened;
    },

    get isShowPlayList(): boolean {
      return !presenter.isVoicePlayList;
    },

    get isVoicePlayList(): boolean {
      if (!presenter.message) {
        return false;
      }

      return [PayloadType.VoiceMessage, PayloadType.CircleVideo].includes(
        presenter.message.payload.payloadType
      );
    },

    get playList(): IMiniPlayerTrack[] {
      const isVoicePayload = presenter.isVoicePlayList;

      const playList = presenter.sharedDataItems.map((item) => {
        // TODO: solve this problem inside AudioPlayer component
        const id = item.payload.message.clientUuid || item.id;
        let title: string;

        if (isVoicePayload) {
          title = presenter.group?.isChannel
            ? presenter.group?.avatarTitle
            : item.payload.message.avatarTitle;
        } else {
          title = item.payload.fileName;
        }

        return {
          id,
          title,
          url: item.payload.userFileBlobUrl || `${item.payload.url}?id=${id}`,
          previewUrl:
            item.payload.payloadType === PayloadType.CircleVideo
              ? (item.payload as CircleVideo).previewUrl
              : null,
          description: isVoicePayload
            ? getVoiceMessageDescription(item.serverTime, t('statistics_voice_at'))
            : '',
          size: item.payload.fileSize,
          duration: parseInt(item.payload.duration, 10) / 1000,
          payload: {
            groupId: item.groupId,
            messageId: item.messageId,
          },
        };
      });

      return playList.reverse();
    },

    get sharedDataItems(): TSharedDataItem[] {
      const group = presenter.group;

      if (!group) {
        return [];
      }

      return (presenter.isVoicePlayList ? group.sharedData.voices : group.sharedData.audio).items;
    },

    get progress(): number | null {
      return presenter.player.newProgress;
    },

    get progressPercentage(): number | null {
      return presenter.player.newProgressPercentage;
    },

    get playerComponent(): FC<IMiniPlayerProps> | null {
      if (!presenter.message) {
        return null;
      }

      let component: FC<IMiniPlayerProps> | null = null;

      switch (presenter.message.payload.payloadType) {
        case PayloadType.CircleVideo:
          component = MiniRoundVideoPlayer;
          break;
        default:
          component = MiniAudioPlayer;
      }

      return component;
    },

    init(): void {
      const group = presenter.player.groupId
        ? Entities.GroupsStore.getGroupById(presenter.player.groupId)
        : null;

      if (!group) {
        return;
      }

      presenter.track =
        presenter.playList.find((track) => track.id === presenter.player.payloadId) || null;
    },

    destroy(): void {
      presenter.track = null;
    },

    onSharedItemsChange(): void {
      if (!presenter.player.isOpened || !presenter.track) {
        return;
      }

      const isMessageRemoved = !presenter.sharedDataItems.find(
        (item) =>
          item.id === presenter.track?.id || item.payload.message.clientUuid === presenter.track?.id
      );

      if (isMessageRemoved) {
        presenter.onClose();
      }
    },

    onConfigUpdate(config: Partial<IMiniPlayerConfig>): void {
      presenter.config = {
        ...presenter.config,
        ...config,
      };
    },

    onChangeTrack(track: IMiniPlayerTrack): void {
      if (track.payload?.messageId) {
        presenter.player.setMessageId(track.payload.messageId);
      }
      presenter.player.setPayloadId(track.id);
    },

    onProgressChange(progress: number, progressPercentage: number): void {
      presenter.player.setProgress(progress, progressPercentage);
    },

    onTogglePlay(isPlaying: boolean): void {
      presenter.player.setIsPlaying(isPlaying);
    },

    async onTitleClick(): Promise<void> {
      await Tasks.group.setActiveGroup({ groupId: presenter.player.groupId });
      Tasks.messaging.focusMessage({
        groupId: presenter.player.groupId,
        messageId: presenter.player.messageId,
      });
    },

    onClose(): void {
      presenter.player.reset();
    },
  }));

  useEffect(() => {
    if (presenter.player.isOpened) {
      presenter.init();
    }

    return presenter.destroy;
  }, [presenter.player.groupId, presenter.player.messageId, presenter.player.payloadId]);

  useEffect(() => {
    return () => {
      presenter.player.reset();
    };
  }, []);

  useEffect(() => {
    presenter.onSharedItemsChange();
  }, [presenter.sharedDataItems]);

  useEffect(() => {
    if (presenter.track?.id) {
      presenter.onTogglePlay(true);
    }
  }, [presenter.track?.id]);

  return presenter;
}
