import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { UsersPopover } from 'APP/components/UsersPopover/UsersPopover';

import {
  IReactionUsersPopoverPresenterParams,
  useReactionUsersPopoverPresenter,
} from './ReactionUsersPopover.presenter';

type IReactionUsersPopoverProps = IReactionUsersPopoverPresenterParams;

export const ReactionUsersPopover: FC<IReactionUsersPopoverProps> = observer((props) => {
  const presenter = useReactionUsersPopoverPresenter(props);

  if (!presenter.isOpened) {
    return null;
  }

  return (
    <UsersPopover
      isOpened={presenter.isOpened}
      data={presenter.users}
      moreCount={presenter.moreCount}
      onClick={presenter.onMoreClick}
    />
  );
});
