import { getSupportedLocales } from 'APP/model/catalog/catalogModel';
import Entities from 'APP/store';

export const loadLocales = async (): Promise<boolean> => {
  try {
    const locales = await getSupportedLocales();
    Entities.catalogStore.setLocales(locales.locales);

    return true;
  } catch {
    return false;
  }
};
