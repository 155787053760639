import { action, observable } from 'mobx';

import { IToastOptions } from 'STORE/Popups/Toast/Toast.types';

const DEFAULT_OPTIONS: IToastOptions = {
  durationMs: 2000,
};

export class Toast {
  @observable isVisible = false;
  @observable text: string | null = null;

  timeout: ReturnType<typeof setTimeout> | null = null;

  @action
  show(text: string, options: Partial<IToastOptions> = DEFAULT_OPTIONS): void {
    this.reset();

    const { durationMs } = {
      ...DEFAULT_OPTIONS,
      ...options,
    };

    this.text = text;
    this.isVisible = true;

    this.timeout = setTimeout(() => {
      this.hide();
    }, durationMs);
  }

  @action
  hide(): void {
    this.isVisible = false;
  }

  @action
  reset(): void {
    this.text = null;
    this.isVisible = false;

    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  }
}
