import { action, observable } from 'mobx';
import { StreamManagerEvent, StreamPropertyChangedEvent } from 'openvidu-browser';
import { Subscriber as OpenviduSubscriber } from 'openvidu-browser/lib/OpenVidu/Subscriber';

import { ICallProviderOpponent, ProviderUid } from 'APP/packages/callProviders/callProviders.types';

const OPENVIDU_MAX_VOLUME_VALUE = -100;

export class OpenviduProviderOpponent implements ICallProviderOpponent {
  private openviduSubscriber: OpenviduSubscriber;
  private isVideoMounted = false;
  @observable hasAudio: boolean;
  @observable hasVideo: boolean;
  @observable isPlayingVideo = false;
  @observable volumeLevel = 0;
  uid: ProviderUid;

  constructor(params: { subscriber: OpenviduSubscriber; subscriberId: ProviderUid }) {
    this.openviduSubscriber = params.subscriber;

    this.uid = params.subscriberId;
    this.hasAudio = params.subscriber.stream.audioActive;
    this.hasVideo = params.subscriber.stream.videoActive;

    params.subscriber.on('streamPropertyChanged', this.handlePublisherChanged.bind(this));
    params.subscriber.on('streamAudioVolumeChange', this.handleVolumeLevelChanged.bind(this));
    if (this.hasAudio) {
      this.startAudioTrack();
    }
  }

  @action
  playVideo(element: HTMLDivElement): void {
    if (!this.isVideoMounted) {
      const video = this.openviduSubscriber.createVideoElement(element);
      video.muted = true;
    }
    const stream = this.openviduSubscriber.stream?.getMediaStream();
    if (stream) {
      this.openviduSubscriber.subscribeToVideo(true);
    }
    this.isPlayingVideo = true;
  }

  @action
  stopVideo(): void {
    const stream = this.openviduSubscriber.stream?.getMediaStream();
    if (stream) {
      this.openviduSubscriber.subscribeToVideo(false);
    }
    this.isPlayingVideo = false;
  }

  @action
  private handlePublisherChanged(event: StreamPropertyChangedEvent): void {
    if (event.reason === 'publishAudio') {
      this.hasAudio = event.stream.audioActive;
      if (event.stream.audioActive) {
        this.startAudioTrack();
      }
    } else if (event.reason === 'publishVideo') {
      this.hasVideo = event.stream.videoActive;
    }
  }

  @action
  private handleVolumeLevelChanged(event: StreamManagerEvent): void {
    if (this.hasAudio && event.value && 'newValue' in event.value) {
      const newValue = event.value.newValue as number;
      const newValueWithThreshold =
        newValue < OPENVIDU_MAX_VOLUME_VALUE ? OPENVIDU_MAX_VOLUME_VALUE : newValue;
      this.volumeLevel = 100 + newValueWithThreshold;
    }
  }

  private startAudioTrack(): void {
    const stream = this.openviduSubscriber.stream?.getMediaStream();

    if (!stream) {
      return;
    }

    const tracks = stream.getAudioTracks();
    const track = tracks?.[0];

    if (!track) {
      return;
    }

    const audio = new Audio();
    audio.autoplay = true;
    audio.srcObject = this.openviduSubscriber.stream?.getMediaStream();
  }
}
