import { values } from 'mobx';

import Tasks from 'APP/Tasks';
import { ICallInfo, CallProviderType } from 'APP/model/call/callModel.types';
import { platformInfo } from 'APP/packages/platformInfo/platformInfo';
import Entities from 'STORE';
import { Call } from 'STORE/Calls/Call/Call';

interface IMediaParams {
  isVideoMuted: boolean;
  isAudioMuted: boolean;
}

export const joinToUnauthorizedCall = async (
  callInfo: ICallInfo,
  mediaParams: IMediaParams
): Promise<void> => {
  const { audioVideoUid, groupId, channelId, callType } = callInfo;

  const me = callInfo.members.find((item) => item.providerUserUid === audioVideoUid);

  if (me) {
    Entities.UsersStore.addMe({
      id: me.userId,
      avatarUrl: me.avatarUrl,
      userName: me.username,
    });
  }

  const call = new Call({
    calls: Entities.Calls,
    groupId,
    channelId,
    callType,
    isGuestCall: true,
    // ToDo: now we can join only to Agora call.
    //  it is necessary to take information on the call from callInfo or URL in future
    providerType: CallProviderType.Agora,
  });
  // ToDo убрать после решения проблемы в асинхронностью в конструкторе ME.ts
  await Tasks.permissions.hasAudioPermissions();
  if (!platformInfo.isMobile) {
    Entities.OngoingCall?.mode?.expand();
  }

  Entities.Calls.add(groupId, call);

  await Tasks.calls.setCallInfoAndPublish({
    callInfo,
    call,
    isVideoMuted: mediaParams.isVideoMuted,
    isAudioMuted: mediaParams.isAudioMuted,
    withoutTimer: true,
  });

  const linkInfo = Entities.callInvitation.invitationInfo;

  if (linkInfo?.groupName) {
    call.setAvatar({
      title: linkInfo.groupName,
      url: linkInfo?.imageUrl || null,
      colorIndex: null,
    });
  } else {
    const member = values(call.members.data).find((member) => {
      return !member.isMe;
    });

    call.setAvatar({
      title: member?.displayName || null,
      url: member?.avatarUrl || null,
      colorIndex: null,
    });
  }

  Entities.Calls.add(groupId, call);

  call.setStarted(true);
};
