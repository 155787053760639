/**
 * Rest API
 * Documentation Rest API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SystemCallResponseDataAllOf { 
    initiator?: string;
    initiatorNickName?: string;
    channelId?: string;
    callType?: SystemCallResponseDataAllOfCallTypeEnum;
    startTs?: string;
    finishTs?: string;
    duration?: string;
    finishReason?: SystemCallResponseDataAllOfFinishReasonEnum;
    finishInitiator?: string;
    finishInitiatorNickname?: string;
}
export enum SystemCallResponseDataAllOfCallTypeEnum {
    P2P = 'P2P',
    Group = 'GROUP',
    Conference = 'CONFERENCE',
    Stream = 'STREAM'
};
export enum SystemCallResponseDataAllOfFinishReasonEnum {
    Unanswered = 'UNANSWERED',
    Declined = 'DECLINED',
    Finished = 'FINISHED'
};



