import { useLocalStore } from 'mobx-react';
import { ElementType, FC, useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { openPopup } from 'APP/Tasks/app/popup/popup';
import { useTranslation } from 'APP/packages/translations';
import AppearanceIcon from 'ICONS/ic-appearance.svg';
import DevicesIcon from 'ICONS/ic-devices.svg';
import PrivacyIcon from 'ICONS/ic-privacy.svg';
import UserIcon from 'ICONS/ic-user.svg';
import Entities from 'STORE';
import { Popup } from 'STORE/App/Popups/Popup/Popup';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { AppearanceSettingsPopup } from '../AppearanceSettingsPopup/AppearanceSettingsPopup';
import { SessionsPopup } from '../Sessions/SessionsPopup/SessionsPopup';
import { UserAccountsPopup } from '../UserAccountsPopup/UserAccountsPopup';
import { UserPrivacyPopup } from '../UserPrivacyPopup/UserPrivacyPopup';

interface IMenuItem {
  title: string;
  Icon: ElementType;
  onClick(): void;
}

interface ILinkMenuItem extends IMenuItem {
  info?: string;
  dataTest?: string;
}

interface IUserSettingsPopupPresenter {
  childPopup: Popup | null;
  hasBackButton: boolean;
  linkMenuItems: ILinkMenuItem[];
  sessionsCount: number;
  openAppearance(): void;
  openPrivacy(): void;
  openAccount(): void;
  openDevices(): void;
  openChildPopup(Component: FC<ICommonPopupsProps>): void;
  onClose(): void;
  onBack(): void;
}

export function useUserSettingsPopupPresenter({
  popupInstance,
  onClose,
  onBack,
}: ICommonPopupsProps): IUserSettingsPopupPresenter {
  const { t } = useTranslation();

  const presenter = useLocalStore<IUserSettingsPopupPresenter>(() => ({
    childPopup: null,

    get hasBackButton(): boolean {
      return !!onBack;
    },

    get sessionsCount(): number {
      return Entities.sessionsStore.sessions.length + 1;
    },

    get linkMenuItems(): ILinkMenuItem[] {
      return [
        {
          title: t('chat_appearance'),
          Icon: AppearanceIcon,
          onClick: presenter.openAppearance,
        },
        {
          title: t('privacy_title'),
          Icon: PrivacyIcon,
          dataTest: 'privacy-settings',
          onClick: presenter.openPrivacy,
        },
        {
          title: t('account'),
          Icon: UserIcon,
          dataTest: 'account',
          onClick: presenter.openAccount,
        },
        {
          title: t('common_devices'),
          Icon: DevicesIcon,
          info: presenter.sessionsCount.toString(),
          onClick: presenter.openDevices,
        },
      ];
    },

    openAppearance(): void {
      presenter.openChildPopup(AppearanceSettingsPopup);
    },

    openPrivacy(): void {
      presenter.openChildPopup(UserPrivacyPopup);
    },

    openAccount(): void {
      presenter.openChildPopup(UserAccountsPopup);
    },

    openDevices(): void {
      presenter.openChildPopup(SessionsPopup);
    },

    onClose(): void {
      popupInstance.close();
      onClose?.();
    },

    onBack(): void {
      popupInstance.close();
      onBack?.();
    },

    openChildPopup(Component: FC<ICommonPopupsProps>): void {
      popupInstance.hide();

      presenter.childPopup = openPopup(Component, {
        onClose: () => presenter.onClose(),
        onBack: () => popupInstance.show(),
      });
    },
  }));

  useEffect(() => {
    Tasks.userSettings.getSessionsInfo();

    return () => presenter.childPopup?.close();
  }, []);

  return presenter;
}
