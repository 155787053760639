import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { Fragment } from 'react';

import { DatePlaceholder } from 'APP/components/DatePlaceholder/DatePlaceholder';
import dateService from 'APP/packages/date';
import { useStickyDate } from 'MAIN/hooks/stickyDate';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';

import Item from './Item';
import usePresenter from './presenter';
import styles from './styles.m.css';

const Drafts = ({ parentPresenter }) => {
  const { scrollRef, stickyClassName, onScroll } = useStickyDate();
  const presenter = usePresenter({ parentPresenter, scrollRef });

  return (
    <div
      className={classNames(styles.drafts, customScrollStyles.container)}
      ref={scrollRef}
      onScroll={onScroll}
    >
      {presenter.drafts
        .reduce((acc, draft, index) => {
          const hasDatePlaceholder = presenter.isNextDay(index);

          if (hasDatePlaceholder || acc.length === 0) {
            acc.push([
              dateService.format(draft.serverTime, {
                month: 'numeric',
                day: 'numeric',
                year: 'numeric',
              }),
              [],
            ]);
          }

          acc[acc.length - 1][1].push(
            <Fragment key={draft.id}>
              {presenter.isNextDay(index) && (
                <DatePlaceholder
                  message={draft}
                  selectorName={stickyClassName}
                />
              )}
              <Item draft={draft} />
            </Fragment>
          );
          return acc;
        }, [])
        .map(([key, children]) => (
          <div key={key}>{children}</div>
        ))}
    </div>
  );
};

export default observer(Drafts);
