import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, MouseEventHandler } from 'react';

import { IReactionItem } from 'MAIN/hooks/useReactions/useReactions.types';

import { TReactionTheme } from '../Reactions.types';
import { useReactionItemPresenter } from './ReactionItem.presenter';
import styles from './ReactionItem.styles.m.css';

interface IReactionItemProps {
  reaction: IReactionItem;
  className?: string;
  theme?: TReactionTheme;
  isAnimated?: boolean;
  isAnimatedLoop?: boolean;
  isVisible?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export const ReactionItem: FC<IReactionItemProps> = observer((props) => {
  const {
    reaction,
    className,
    theme = '1',
    isAnimated = false,
    isAnimatedLoop = false,
    onClick,
    isVisible = true,
  } = props;

  const { presenter, stickerContainerRef } = useReactionItemPresenter({
    reaction,
    isAnimated,
    isAnimatedLoop,
    onClick,
    isVisible,
  });

  const buttonClasses = classNames(styles.container, styles[`theme-${theme}`], className, {
    [styles.selected]: reaction.hasMyReaction,
  });

  return (
    <button
      className={buttonClasses}
      onClick={presenter.onClick}
      onMouseEnter={presenter.onHoverIn}
      onMouseLeave={presenter.onHoverOut}
    >
      <span
        className={styles.icon}
        ref={stickerContainerRef}
      />

      {presenter.isShowCounter && <span className={styles.counter}>{reaction.counter}</span>}
    </button>
  );
});
