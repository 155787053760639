import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, useCallback } from 'react';

import { List } from 'APP/components/List/List';
import { AuthProvider, IAccountProvider } from 'APP/model/auth/authModel.types';
import { GroupsPlaceholder } from 'APP/packages/placeholders/GroupsPlaceholder/GroupsPlaceholder';
import { useTranslation } from 'APP/packages/translations';
import { Popup } from 'APP/store/App/Popups/Popup/Popup';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';
import { PopupHeader } from 'UIKIT/PopupHeader/PopupHeader';

import { AppleProvider } from '../AppleProvider/AppleProvider';
import { DeleteAccount } from '../DeleteAccount/DeleteAccount';
import { GoogleProvider } from '../GoogleProvider/GoogleProvider';
import { PhoneProvider } from '../PhoneProvider/PhoneProvider';
import { TelegramProvider } from '../TelegramProvider/TelegramProvider';
import { useUserAccountsPopupViewPresenter } from './UserAccountsPopupView.presenter';
import styles from './UserAccountsPopupView.styles.m.scss';

interface IUserAccountsPopupViewProps {
  popupInstance: Popup;

  onBack: (() => void) | null;
  onClose(): void;
}

interface ProviderProps {
  displayName: string;
  userId: string;
  popupInstance: Popup;
  onProcessingChange(active: boolean, provider: AuthProvider): void;
}

const PROVIDERS: Record<AuthProvider, FC<ProviderProps>> = {
  [AuthProvider.Phone]: PhoneProvider,
  [AuthProvider.Google]: GoogleProvider,
  [AuthProvider.Apple]: AppleProvider,
  [AuthProvider.Telegram]: TelegramProvider,
};

const Noop: FC = () => null;

export const UserAccountsPopupView: FC<IUserAccountsPopupViewProps> = observer((props) => {
  const { popupInstance, onBack, onClose } = props;

  const { t } = useTranslation();

  const presenter = useUserAccountsPopupViewPresenter({ onBack, onClose });

  const renderItem = useCallback((provider: IAccountProvider) => {
    const ProviderComponent = PROVIDERS[provider.authProviderId] || Noop;

    return (
      <ProviderComponent
        displayName={provider.displayName}
        userId={provider.userId}
        popupInstance={popupInstance}
        onProcessingChange={presenter.processingChange}
      />
    );
  }, []);

  return (
    <div className={styles.container}>
      <PopupHeader
        title={t('account_screen')}
        handlePrevStep={presenter.back}
        onClose={presenter.close}
      />
      <div className={classNames(styles.innerContainer, customScrollStyles.container)}>
        <List
          isLoading={presenter.isLoading}
          data={presenter.accountProviders}
          keyExtractor={presenter.keyExtractor}
          renderItem={renderItem}
          SpinnerComponent={
            <GroupsPlaceholder
              count={presenter.placeholdersCount}
              className={styles.placeholders}
            />
          }
        />
        <hr className={styles.dividerBackground} />
        <DeleteAccount />
      </div>
    </div>
  );
});
