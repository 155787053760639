import { CallViaLinkInfoResponse } from 'APP/packages/api/types/model/callViaLinkInfoResponse';
import { UnauthorizedCallInfoResponse } from 'APP/packages/api/types/model/unauthorizedCallInfoResponse';

import { mapCallInfoResponse } from '../call/callModel.mapper';
import { ICallInvitationInfo, IUnauthorizedCallInfo } from './unauthorizedCallModel.types';

export const mapCallInvitationInfoResponse = (
  info: CallViaLinkInfoResponse
): ICallInvitationInfo => {
  return {
    ...info,
    userName: info.username,
  };
};

export const mapUnauthorizedCallInfoResponse = (
  info: UnauthorizedCallInfoResponse
): IUnauthorizedCallInfo => {
  return {
    ...info,
    callInfo: mapCallInfoResponse(info.callInfo),
  };
};
