import { observer } from 'mobx-react';
import React, { FC, ReactElement } from 'react';
import { FormSpy } from 'react-final-form';

import { Field } from 'APP/packages/form/Field/Field';
import { Form } from 'APP/packages/form/Form/Form';
import { IFormContentProps } from 'APP/packages/form/Form/Form.types';
import { TextField } from 'APP/packages/form/TextField/TextField';
import { useTranslation } from 'APP/packages/translations';
import { Button } from 'UIKIT/Button/Button';
import { ButtonTypes } from 'UIKIT/Button/Button.types';

import { INewContactPresenterParams, useNewContactViewPresenter } from './NewContactView.presenter';
import styles from './NewContactView.styles.m.css';

type INewContactViewProps = INewContactPresenterParams;

export const NewContactView: FC<INewContactViewProps> = observer((props) => {
  const { t } = useTranslation();
  const presenter = useNewContactViewPresenter(props);
  const isLoading = presenter.isLoading;

  return (
    <div className={styles.container}>
      <Form
        onSubmit={presenter.onSubmit}
        initialValues={presenter.initialValue}
        keepDirtyOnReinitialize={presenter.keepDirtyOnReinitialize}
      >
        {({ handleSubmit }: IFormContentProps): ReactElement => (
          <form
            className={styles.form}
            onSubmit={handleSubmit}
          >
            <div>
              <Field
                className={styles.userNameField}
                isNeedTrim
                name="contactNameSearch" // 'Search' word at the end of the name is trick for disabling safari autofill. Inspired by https://bytes.grubhub.com/disabling-safari-autofill-for-a-single-line-address-input-b83137b5b1c7
                label={t('profile_username_hint')}
                component={TextField}
                maxLength={50}
                setRef={presenter.userNameRef}
                validate={presenter.usernameValidate}
                successText={' '}
              />
              <Field
                name="contactPhone"
                label={t('new_contact_phone')}
                component={TextField}
                placeholder={presenter.placeholder}
                validate={presenter.validatePhone}
                parse={presenter.parsePhoneNumber}
                validatingText={' '}
                successText={' '}
              />
            </div>
            <div className={styles.footer}>
              <FormSpy
                subscription={{
                  pristine: true,
                  invalid: true,
                  validating: true,
                }}
              >
                {({ pristine, invalid, validating, form }): ReactElement => (
                  <Button
                    size="20"
                    type={ButtonTypes.Submit}
                    isLoading={isLoading}
                    isDisabled={
                      pristine || invalid || validating || presenter.isPristinePhoneField(form)
                    }
                    className={styles.createButton}
                    theme="1"
                  >
                    {t('common_create')}
                  </Button>
                )}
              </FormSpy>
            </div>
          </form>
        )}
      </Form>
    </div>
  );
});
