import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, MouseEventHandler, useCallback, useState } from 'react';
import { InView } from 'react-intersection-observer';

import { IUserNameBadgeSource } from 'APP/constants/userBadges';

import styles from './AnimatedSticker.styles.m.css';
import { Animation } from './Animation/Animation';

interface IAnimatedStickerProps {
  animationUrl?: string;
  animationData?: IUserNameBadgeSource;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  width: number;
  height: number;
  autoplay?: boolean;
  displayUILayer?: number;
  className?: string;
  root?: HTMLElement | null;
  loop?: boolean;
}

export const AnimatedSticker: FC<IAnimatedStickerProps> = observer((props) => {
  const {
    animationUrl,
    animationData,
    onClick,
    width,
    height,
    autoplay = false,
    displayUILayer = 0,
    className,
    root = null,
    loop,
  } = props;

  const [isInit, setIsInit] = useState(false);
  const onViewChange = useCallback(
    (inView: boolean) => {
      if (inView && !isInit) {
        setIsInit(inView);
      }
    },
    [setIsInit, isInit]
  );

  const screenHeight = window.innerHeight;

  const inViewProps = {
    onChange: onViewChange,
    triggerOnce: true,
    rootMargin: `${screenHeight}px ${screenHeight}1px`,
    root,
    threshold: 0,
    className: classNames(styles.container, className),
    style: {
      width,
      height,
    },
    onClick: onClick,
  };

  return (
    <InView
      as={onClick ? 'button' : 'div'}
      {...inViewProps}
    >
      {isInit ? (
        <Animation
          autoplay={autoplay}
          animationUrl={animationUrl}
          animationData={animationData}
          displayUILayer={displayUILayer}
          loop={loop}
          width={width}
          height={height}
        />
      ) : null}
    </InView>
  );
});
