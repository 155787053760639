import React, { FC, useRef, ReactElement } from 'react';
import { FormSpy } from 'react-final-form';

import { Field } from 'APP/packages/form/Field/Field';
import { useTranslation } from 'APP/packages/translations';
import { IScheduledCallsFormValues } from 'MAIN/PopupManager/Popups/ScheduledCalls/ScheduledCalls.types';

import { ScheduledCallsFormFields } from '../../../ScheduledCalls.constants';
import { DateField } from '../DateField/DateField';
import { TimeField } from '../TimeField/TimeField';
import styles from './ScheduledCallsDateTimeField.styles.m.css';

interface IScheduledCallsDateTimeFieldProps {
  label: string;
  timeName: ScheduledCallsFormFields;
  dateName: ScheduledCallsFormFields;
  minDateFieldName?: ScheduledCallsFormFields;
  mutator?(value: Partial<IScheduledCallsFormValues>): void;
  validateForm(values: IScheduledCallsFormValues): { [key: string]: string } | undefined;
}

export const ScheduledCallsDateTimeField: FC<IScheduledCallsDateTimeFieldProps> = ({
  label,
  minDateFieldName,
  timeName,
  dateName,
  validateForm,
  mutator,
}) => {
  const { t } = useTranslation();
  const errorRef = useRef<string | null>(null);

  return (
    <FormSpy<IScheduledCallsFormValues>
      subscription={{ errors: true, values: true }}
      render={({ form, values }): ReactElement => {
        const errors = validateForm(values);
        const dateError = (errors && errors[dateName]) || null;
        const timeError = (errors && errors[timeName]) || null;
        if (!dateError && !timeError && errorRef.current) {
          form.resetFieldState(timeName);
          form.resetFieldState(dateName);
        }

        errorRef.current = dateError || timeError;

        return (
          <div className={styles.dateTime}>
            <div className={styles.label}>{t(label)}</div>
            <Field
              onChange={mutator}
              hasExternalError={!!errorRef.current}
              readOnly
              className={styles.date}
              component={DateField}
              minDate={(minDateFieldName && values && values[minDateFieldName]) || null}
              name={dateName}
            />
            <Field
              onChange={mutator}
              hasExternalError={!!errorRef.current}
              component={TimeField}
              name={timeName}
            />
            {errorRef.current && <div className={styles.errorText}>{errorRef.current}</div>}
          </div>
        );
      }}
    />
  );
};
