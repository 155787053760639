import { BotButtonPayloadType } from 'APP/model/bot/botModel.constants';
import {
  IBotButtonsResponseData,
  ICommandPayload,
  IOpenUrlPayload,
  IShareCardPayload,
} from 'APP/model/bot/botModel.types';
import { IGraphicSize } from 'APP/model/common/commonModel.types';
import { PayloadType } from 'APP/model/message/messageModel.types';
import { t } from 'MODULE/i18n/service';
import type { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';
import { Payload } from 'STORE/Messages/Message/Payload';
import { CommandButtonPayload } from 'STORE/Messages/Message/Payload/Buttons/CommandButtonPayload';
import { OpenUrlButtonPayload } from 'STORE/Messages/Message/Payload/Buttons/OpenUrlButtonPayload';
import { ShareCardButtonPayload } from 'STORE/Messages/Message/Payload/Buttons/ShareCardButtonPayload';

export interface IButtonPayloadModel {
  payload: ShareCardButtonPayload | OpenUrlButtonPayload | CommandButtonPayload | null;
  id: string;
  title: string;
}

export class Buttons extends Payload {
  text: string;
  buttons: IButtonPayloadModel[];
  layout: string[];
  imageUrl?: string;
  graphicSize?: IGraphicSize;

  constructor(data: IBotButtonsResponseData & { message: ChatMessage }) {
    super(data);

    this.text = data.text;
    this.imageUrl = data.imageUrl;
    this.graphicSize = data.graphicSize;

    this.buttons = data.buttons.map((button) => {
      let payload = null;
      switch (button.payload?.type) {
        case BotButtonPayloadType.ShareCard: {
          payload = new ShareCardButtonPayload(button.payload as IShareCardPayload);
          break;
        }
        case BotButtonPayloadType.OpenUrl: {
          payload = new OpenUrlButtonPayload(button.payload as IOpenUrlPayload);
          break;
        }
        case BotButtonPayloadType.Command: {
          payload = new CommandButtonPayload(button.payload as ICommandPayload);
          break;
        }
      }

      return { ...button, payload };
    });
    this.layout = data.layout;
  }

  get typeDescription(): string {
    return t('bot_message');
  }

  toJSON(): IBotButtonsResponseData {
    return {
      payloadType: PayloadType.Buttons,
      text: this.text,
      buttons: this.buttons.map((button) => ({
        id: button.id,
        title: button.title,
        payload: button.payload?.toJSON(),
      })),
      layout: this.layout,
      imageUrl: this.imageUrl,
      graphicSize: this.graphicSize,
    };
  }
}
