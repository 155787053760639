import { useLocalStore } from 'mobx-react';

import Entities from 'APP/store';

export const usePresenter = (userId) => {
  return useLocalStore(() => ({
    get member() {
      return Entities.Calls.ongoingCall.members.getMemberById(userId);
    },
  }));
};
