import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { useTranslation } from 'APP/packages/translations';

import styles from './SpaceHeader.styles.m.css';

export const SpaceHeader: FC = observer(() => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{t('space_main')}</h3>
    </div>
  );
});
