import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { MediaPreview } from 'APP/components/MediaPreview/MediaPreview';
import { MEDIA_PREVIEW_MAX_SIZE } from 'APP/constants/messages';
import DoneIcon from 'ICONS/ic-done-32.svg';
import { UploaderState } from 'MAIN/ChatView/components/UploaderState/UploaderState';

import styles from './styles.m.css';

export const Image = observer(
  ({
    payloadId,
    groupId,
    messageId,
    payload,
    isLoading,
    isPreview,
    isView,
    isFromMe,
    openGallery,
  }) => {
    if (!payload?.previewUrl) {
      return null;
    }

    const previewClassName = classNames(styles.preview, {
      [styles.myPreview]: isFromMe,
    });

    return (
      <div className={styles.item}>
        <MediaPreview
          className={previewClassName}
          payload={payload}
          maxWidth={MEDIA_PREVIEW_MAX_SIZE.width}
          maxHeight={MEDIA_PREVIEW_MAX_SIZE.height}
          isPreview={isPreview}
          isView={isView}
          onClick={openGallery}
          payloadId={payloadId}
          groupId={groupId}
          messageId={messageId}
          imageClassName={styles.mediaImage}
        />

        <UploaderState
          className={styles.uploader}
          uploadFileData={{
            uploadProgress: payload.uploadProgress,
            cancelUpload: payload.cancelUploadAndDeleteMessage,
          }}
        >
          {isLoading && <DoneIcon className={styles.icon} />}
        </UploaderState>
      </div>
    );
  }
);
