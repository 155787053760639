import classNames from 'classnames';
import React, { FC, memo } from 'react';

import AnimatedPartStyles from '../AnimatedPart/AnimatedPart.styles.m.css';
import styles from './AvatarPlaceholder.styles.m.css';

export enum AvatarPlaceholderType {
  Round_05 = 'round-05',
  Round_0 = 'round-0',
  Round_10 = 'round-10',
  Square_0 = 'square-0',
  Square_8 = 'square-8',
  Square_20 = 'square-20',
  Square_40 = 'square-40',
  Square_60 = 'square-60',
}

interface IAvatarPlaceholderProps {
  className?: string;
  type: AvatarPlaceholderType;
  isAnimated?: boolean;
}

export const AvatarPlaceholder: FC<IAvatarPlaceholderProps> = memo(
  ({ type, isAnimated, className }) => {
    return (
      <div
        className={classNames(styles.container, className, styles[type], {
          [AnimatedPartStyles.animation]: isAnimated,
        })}
      />
    );
  }
);
