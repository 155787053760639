import React, { useState, useRef, useCallback } from 'react';

import { useTranslation } from 'APP/packages/translations';
import { Button } from 'UIKIT/Button/Button';

import styles from './ImageUploader.style.m.css';

// TODO: refactored and replace on form/ImageUploader component
export const ImageUploader = ({ input: { name, onChange, value } }) => {
  const [sizeError, setSizeError] = useState(false);
  const inputFile = useRef();
  const { t } = useTranslation();
  const handleRemoveImage = useCallback(() => {
    onChange(null);
    setSizeError(false);
    if (inputFile.current) {
      inputFile.current.value = '';
    }
  }, [onChange]);

  const handleAddImage = (event) => {
    const [file] = event.target.files;
    if (!file) {
      return;
    }
    if (file.size > 1024 * 1024 * 2) {
      setSizeError(true);
      return;
    }
    setSizeError(false);
    onChange(file);
  };

  const hasImage = Boolean(value);

  return (
    <>
      <div className={styles.content}>
        <div>
          <Button
            className={styles.upload}
            size="0"
            tabIndex={-1}
            theme="1"
          >
            <input
              ref={inputFile}
              name={name}
              onChange={handleAddImage}
              className={styles.fileInput}
              accept="image/*"
              type="file"
            />
            {hasImage ? t('common_update') : t('stream_picture_upload_button')}
          </Button>
          {hasImage && (
            <Button
              className={styles.removeAvatar}
              onClick={handleRemoveImage}
              size="0"
              theme="3"
            >
              {t('delete')}
            </Button>
          )}
        </div>
        <div className={styles.description}>{t('stream_thumbnail_parameters')}</div>
        {sizeError ? <div className={styles.error}>{t('stream_thumbnail_wrong_size')}</div> : null}
      </div>
    </>
  );
};
