import { useLocalStore } from 'mobx-react';

const getShortDescription = (description) => {
  const MAX_DESCRIPTION_LENGTH = 100;

  return description.length > MAX_DESCRIPTION_LENGTH
    ? `${description.substring(0, MAX_DESCRIPTION_LENGTH)}…`
    : description;
};

const getDomainNameFromLink = (link) => {
  try {
    const url = new URL(link);
    return url.hostname;
  } catch (e) {
    return null;
  }
};

export const useLinkItemPresenter = ({ payload, links }) => {
  const presenter = useLocalStore(() => ({
    get link() {
      if (payload.linkPreview) {
        const { title, domain, image, description } = payload.linkPreview;

        return {
          imageUrl: image?.url,
          title: title || domain,
          description: getShortDescription(description),
          links,
        };
      }

      return {
        imageUrl: null,
        title: getDomainNameFromLink(links[0]) || links[0] || '',
        description: null,
        links,
      };
    },
  }));

  return presenter;
};
