import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, MutableRefObject } from 'react';
import { InView } from 'react-intersection-observer';

import { useContextMenu } from 'MAIN/hooks/useContextMenu/useContextMenu';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';
import { TPostInstance } from 'STORE/Newsfeed/Newsfeed.types';
import { IPopoverMenuItem } from 'UIKIT/PopoverMenu/PopoverMenu.types';

import { TSetNewsVisibilityHandler } from '../NewsfeedContent.types';
import { useNewsfeedPostItemPresenter } from './NewsfeedPostItem.presenter';
import styles from './NewsfeedPostItem.styles.m.css';
import { PostContextMenu } from './PostContextMenu/PostContextMenu';
import { PostFooter } from './PostFooter/PostFooter';
import { PostHeader } from './PostHeader/PostHeader';
import { PostHiddenContent } from './PostHiddenContent/PostHiddenContent';
import { PostMessage } from './PostMessage/PostMessage';

interface INewsfeedPostItemProps {
  className?: string;
  newsItem: TPostInstance;
  position: number;
  scrollRef: MutableRefObject<HTMLDivElement>;
  onSetNewsVisibility: TSetNewsVisibilityHandler;
  getMenuItems?(message: ChatMessage, newsItem: TPostInstance): IPopoverMenuItem[];
}

export const NewsfeedPostItem: FC<INewsfeedPostItemProps> = observer((props) => {
  const { className, newsItem, position, scrollRef, onSetNewsVisibility, getMenuItems } = props;
  const presenter = useNewsfeedPostItemPresenter({ newsItem, position, onSetNewsVisibility });
  const { popover, modifiers, onContextMenu } = useContextMenu<HTMLDivElement>();

  if (!presenter.isShow) {
    return null;
  }

  if (presenter.isShowHiddenInfo) {
    return (
      <div className={classNames(className, styles.container)}>
        <PostHiddenContent newsItem={newsItem} />
      </div>
    );
  }

  return (
    <div
      className={classNames(className, styles.container)}
      ref={popover.containerRef}
      role="button"
      tabIndex={0}
      data-message-id={newsItem.messageId}
      onContextMenu={onContextMenu}
      onClick={popover.close}
    >
      <InView
        root={scrollRef.current}
        rootMargin="100% 0%"
        onChange={presenter.onPreview}
      >
        <InView
          root={scrollRef.current}
          onChange={presenter.onView}
        >
          <PostHeader
            newsItem={newsItem}
            getMenuItems={getMenuItems}
          />
          <PostMessage
            message={presenter.message}
            isPreview={presenter.isPreview}
            isView={presenter.isView}
          />
          <PostFooter message={presenter.message} />
        </InView>
      </InView>

      {getMenuItems && (
        <PostContextMenu
          newsItem={newsItem}
          popover={popover}
          placement="bottom-start"
          modifiers={modifiers}
          getMenuItems={getMenuItems}
        />
      )}
    </div>
  );
});
