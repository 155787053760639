import React, { FC } from 'react';

import { Avatar, AvatarTypes, TAvatarBackgroundColorIndex } from 'UIKIT/Avatar/Avatar';

import styles from './ScheduledCallGroupAvatar.styles.m.css';

interface IScheduledCallGroupAvatarProps {
  groupName: string;
  groupAvatar?: string;
  groupColorIndex?: TAvatarBackgroundColorIndex;
}

export const ScheduledCallGroupAvatar: FC<IScheduledCallGroupAvatarProps> = ({
  groupName,
  groupAvatar,
  groupColorIndex,
}) => {
  return (
    <div className={styles.group}>
      <Avatar
        backgroundColorIndex={groupColorIndex}
        className={styles.avatar}
        type={AvatarTypes.Round_05}
        title={groupName}
        url={groupAvatar}
      />
      <div className={styles.groupName}>{groupName}</div>
    </div>
  );
};
