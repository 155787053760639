import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import CloseIcon from 'ICONS/close.svg';
import { Popup } from 'STORE/App/Popups/Popup/Popup';
import { Space } from 'STORE/Spaces/Space';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';
import { IconButton } from 'UIKIT/IconButton';
import { IconView } from 'UIKIT/IconView/IconView';
import { MenuItem } from 'UIKIT/MenuItem/MenuItem';

import { SpaceInfo } from './SpaceInfo/SpaceInfo';
import { useSpaceProfilePresenter } from './SpaceProfile.presenter';
import styles from './SpaceProfile.styles.m.css';

interface ISpaceProfileProps {
  className?: string;
  metaBlockClassName?: string;
  space: Space;
  popupInstance?: Popup;
  onClose?(): void;
}

export const SpaceProfile: FC<ISpaceProfileProps> = observer(
  ({ className, metaBlockClassName, space, popupInstance, onClose }) => {
    const presenter = useSpaceProfilePresenter({ space, popupInstance });

    return (
      <div className={classNames(className, styles.container, customScrollStyles.container)}>
        <SpaceInfo
          metaBlockClassName={metaBlockClassName}
          space={space}
          isShowOnboarding={presenter.isShowOnboarding}
          canUpdateDeepLink={presenter.canUpdateDeepLink}
          onUpdateDeepLink={presenter.onUpdateDeepLink}
        />

        {presenter.menu.length > 0 && (
          <>
            <div className={styles.divider} />
            {presenter.menu.map(({ key, title, count, icon, onClick }) => (
              <MenuItem
                className={styles.menuItem}
                key={key}
                title={title}
                count={count}
                icon={
                  <IconView
                    Icon={icon}
                    className={styles.menuItemIcon}
                  />
                }
                onClick={onClick}
              />
            ))}
          </>
        )}

        {presenter.actions.length > 0 && (
          <>
            <div className={styles.divider} />
            {presenter.actions.map(({ key, title, icon, onClick }) => (
              <MenuItem
                className={styles.menuItem}
                key={key}
                title={title}
                icon={
                  <IconView
                    Icon={icon}
                    className={styles.menuItemIcon}
                  />
                }
                onClick={onClick}
              />
            ))}
          </>
        )}

        {onClose && (
          <IconButton
            className={styles.close}
            Icon={CloseIcon}
            onClick={onClose}
          />
        )}
      </div>
    );
  }
);
