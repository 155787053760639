import { MouseEvent, useCallback } from 'react';

import { RouterLink, RouterState } from 'APP/router/constants';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';

interface MarketplaceLinkRendererPresenter {
  onClick(event: MouseEvent<HTMLAnchorElement>): void;
}

export function useMarketplaceLinkRendererPresenter(
  link: string
): MarketplaceLinkRendererPresenter {
  const navigateTo = useNavigateTo();

  const onClick = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();

      navigateTo({
        to: RouterLink.market,
        state: {
          [RouterState.url]: link,
        },
      });
    },
    [navigateTo, link]
  );

  return {
    onClick,
  };
}
