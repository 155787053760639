import { useEffect, useRef } from 'react';

import Tasks from 'APP/Tasks';
import { openPopup } from 'APP/Tasks/app/popup/popup';
import { NewsType } from 'APP/model/catalog/catalogModel.types';
import { useTranslation } from 'APP/packages/translations';
import Entities from 'APP/store';
import { ReportMessagePopup } from 'MAIN/PopupManager/Popups/ReportMessagePopup/ReportMessagePopup';
import { Popup } from 'STORE/App/Popups/Popup/Popup';
import { TPostInstance } from 'STORE/Newsfeed/Newsfeed.types';

interface IPostHiddenContentPresenter {
  title: string;
  isShowLeaveButton: boolean;
  isShowReportButton: boolean;
  isShowFooter: boolean;
  onShow(): Promise<void>;
  onLeave(): Promise<void>;
  onReport(): void;
}

export const usePostHiddenContentPresenter = (
  newsItem: TPostInstance
): IPostHiddenContentPresenter => {
  const { t } = useTranslation();
  const childPopupRef = useRef<Popup>();

  const presenter: IPostHiddenContentPresenter = {
    get title(): string {
      return newsItem.type === NewsType.GuestPost
        ? t('newsfeed_not_interested_text', { 0: newsItem.group?.avatarTitle || '' })
        : t('newsfeed_hide_text', { 0: newsItem.group?.avatarTitle || '' });
    },

    get isShowLeaveButton(): boolean {
      return Boolean(newsItem.type === NewsType.Post && !newsItem.group?.isOwner);
    },

    get isShowReportButton(): boolean {
      return Boolean(newsItem.type === NewsType.GuestPost && newsItem.message?.canBeReported);
    },

    get isShowFooter(): boolean {
      return presenter.isShowLeaveButton || presenter.isShowReportButton;
    },

    async onShow(): Promise<void> {
      const result = await Tasks.newsfeed.unhideNewsfeedPost(newsItem);
      if (!result) {
        Entities.toast.show(t('something_went_wrong'));
      }
    },

    async onLeave(): Promise<void> {
      const result = await Tasks.group.leaveGroup({
        groupId: newsItem.groupId,
      });

      if (!result?.error) {
        newsItem.setVisibility(false);
        Entities.toast.show(t('news_feed_you_unsubscribed'));
      }
    },

    onReport(): void {
      childPopupRef.current = openPopup(ReportMessagePopup, {
        params: { groupId: newsItem.groupId!, messageId: newsItem.messageId! },
      });
    },
  };

  useEffect(() => {
    return () => childPopupRef.current?.close();
  }, []);

  return presenter;
};
