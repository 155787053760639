import { now } from 'APP/packages/date/helpers/now';

import { IRequestData } from '../request/request.types';
import { ErrorResponse } from '../types/model/errorResponse';

const API_ERROR_NAME = 'API_ERROR';

export function isApiErrorResponse(errorResponse: any): errorResponse is ErrorResponse {
  return errorResponse?.error && errorResponse?.error?.code && errorResponse?.error?.message;
}

export class ApiError<TData = any> extends Error {
  public readonly name: string = API_ERROR_NAME;
  public readonly time: number = now().getTime();

  constructor(
    message: string,
    public code: number,
    public requestData: IRequestData<TData>,
    public responseData: ErrorResponse | null = null,
    public responseHeaders: Headers | null = null,
    public originalError: Error | null = null
  ) {
    super(message);
  }
}
