import { useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';

import User from './Entities/User';

const OFFSET = 10;
const LIMIT = 10;

export default ({ groupId, messageId }) => {
  const presenter = useLocalStore(() => ({
    isLoading: false,
    offset: 0,
    hasMoreResults: false,
    users: [],

    loadUsersViewedMessage: async (loadMore = false) => {
      const { users, hasMoreResults } = await Tasks.messaging.getUsersViewedMessage(
        presenter.usersViewedMessageRequest
      );

      if (!users.length) {
        presenter.reset();

        return [];
      }

      await Tasks.relationships.loadUnknownUsersByIds({
        usersIds: users,
      });

      const list = users
        .map((userId) => {
          return presenter.createUser({ userId, groupId, messageId });
        })
        .filter((x) => x.user);

      if (loadMore) {
        presenter.users = [...presenter.users, ...list];
      } else {
        presenter.users = list;
      }

      presenter.hasMoreResults = hasMoreResults;
      presenter.isLoading = false;
      return presenter.users;
    },

    loadMore: () => {
      if (presenter.hasMoreResults) {
        presenter.offset = presenter.offset + OFFSET;
        presenter.loadUsersViewedMessage(true);
      }
    },

    reset: () => {
      presenter.isLoading = false;
      presenter.offset = 0;
      presenter.hasMoreResults = false;
    },

    createUser: (data) => {
      return new User(presenter, data);
    },

    get usersViewedMessageRequest() {
      return {
        groupId: groupId,
        messageId: messageId,
        limit: LIMIT,
        offset: presenter.offset,
      };
    },
  }));

  return presenter;
};
