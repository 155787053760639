export const getElectronScreenSharingTrack = async (
  electronSourceId: string
): Promise<MediaStreamTrack | undefined> => {
  const stream = await (navigator as Navigator).mediaDevices.getUserMedia({
    audio: false,
    video: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      mandatory: {
        chromeMediaSource: 'desktop',
        chromeMediaSourceId: electronSourceId,
      },
    },
  });

  if (!stream) {
    return;
  }

  const tracks = stream.getVideoTracks();

  return tracks ? tracks[0] : undefined;
};
