import classNames from 'classnames';
import React, { memo, useState, useRef } from 'react';

import SelectArrowIcon from 'ICONS/select-arrow.svg';
import { Popover } from 'UIKIT/Popover/Popover';

import Options from './Options';
import styles from './styles.m.css';

const DropDown = ({
  className = undefined,
  input: { onChange, value },
  meta: { error = undefined },
  label = undefined,
  options = [],
  noSelectedLabel = undefined,
}) => {
  const [isListVisible, setListVisible] = useState(false);
  const ref = useRef();

  const containerClass = classNames(styles.container, className);

  const onClick = (event) => {
    event.stopPropagation();
    setListVisible(!isListVisible);
  };

  const onClose = () => setListVisible(false);

  const selectedOption = options.find((option) => option.value === value);

  return (
    <>
      <div
        ref={ref}
        role="button"
        tabIndex="0"
        className={containerClass}
        onClick={onClick}
      >
        <div className={styles.value}>{selectedOption ? selectedOption.label : label}</div>
        <SelectArrowIcon className={styles.arrow} />
      </div>
      <div className={styles.helperText}>{error}</div>
      <Popover
        isScrollEnabled
        isOpened={isListVisible}
        onClose={onClose}
        reference={ref.current}
        placement="bottom-start"
      >
        <Options
          noSelectedLabel={noSelectedLabel}
          onChange={onChange}
          onClose={onClose}
          value={value}
          options={options}
        />
      </Popover>
    </>
  );
};

export default memo(DropDown);
