import { MouseEvent, MutableRefObject, useCallback, useEffect, useRef } from 'react';

import { useContextMenu } from 'APP/main/hooks/useContextMenu/useContextMenu';
import { IPopover, TPopoverModifiers } from 'APP/packages/UiKit/Popover/Popover.types';

interface IMessageContextMenuPresenterProps {
  reference: MutableRefObject<HTMLElement | null>;
}

interface IMessageContextMenuPresenter {
  popover: IPopover<HTMLElement>;
  modifiers: TPopoverModifiers;
  targetClick: HTMLElement | null;
}

export const useMessageContextMenuPresenter = ({
  reference,
}: IMessageContextMenuPresenterProps): IMessageContextMenuPresenter => {
  const { onContextMenu, modifiers, popover } = useContextMenu();

  const targetClickRef = useRef<HTMLElement | null>(null);

  const contextMenuHandler = useCallback(
    (event: Event) => {
      targetClickRef.current = event.target as HTMLElement;

      onContextMenu(event as unknown as MouseEvent<HTMLDivElement>);
    },
    [popover.isOpened]
  );

  useEffect(() => {
    if (!reference.current) {
      return;
    }

    popover.containerRef.current = reference.current;

    reference.current.addEventListener('contextmenu', contextMenuHandler);
    reference.current.addEventListener('click', popover.close);

    return () => {
      if (!reference.current) {
        return;
      }

      reference.current.removeEventListener('contextmenu', contextMenuHandler);
      reference.current.removeEventListener('click', popover.close);
    };
  }, [reference, contextMenuHandler]);

  return {
    popover,
    modifiers,
    targetClick: targetClickRef.current,
  };
};
