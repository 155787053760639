import { getClientConfig } from 'APP/model/auth/authModel';

export async function checkForceUpdate(): Promise<void> {
  try {
    const clientConfig = await getClientConfig();
    const isForceUpdate =
      clientConfig.properties.find(({ key }) => key === 'ForcedUpdate')?.value === 'true';

    if (isForceUpdate) {
      window.location.reload();
    }
  } catch {
    // do nothing
  }
}
