import { IClientConfigProperty } from 'APP/model/auth/authModel.types';
import logger from 'APP/packages/logger';
import Entities from 'APP/store';

interface RewardsClientConfig {
  hasAchievedRewards: boolean;
  hasNewRewards: boolean;
}

export const handleRewardsStatus = (property: IClientConfigProperty): void => {
  try {
    const config: RewardsClientConfig = JSON.parse(property.value);

    Entities.rewards.setHasAchievedRewards(config.hasAchievedRewards);
    Entities.rewards.setHasNewRewards(config.hasNewRewards);
  } catch (error) {
    logger.get('Tasks').error('login.clientConfig.handleRewardsStatus', error);
  }
};
