import classNames from 'classnames';
import React, { FC } from 'react';

import styles from './Tag.styles.m.css';

interface ITagProps {
  readonly className?: string;
}

export const Tag: FC<ITagProps> = (props) => {
  const { className, children } = props;

  return <span className={classNames(styles.container, className)}>{children}</span>;
};
