import { useLocalStore, useAsObservableSource } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { RouterState } from 'APP/router/constants';
import useGetState from 'APP/router/hooks/useGetState';
import { clearGetParameters } from 'APP/router/refs/clearGetParameters';
import Entities from 'APP/store';

export default ({ datetime }) => {
  const source = useAsObservableSource({
    groupId: useGetState(RouterState.groupId),
    messageId: useGetState(RouterState.messageId),
  });

  const presenter = useLocalStore(() => ({
    currentStep: 'common',

    init() {
      if (Entities.InputPanel.isEmpty) {
        Entities.InputPanel.payloads.clear();
        clearGetParameters();
        return;
      }

      if (!source.groupId || !source.messageId) {
        return;
      }

      const group = presenter.scheduleGroup;
      const message = group?.messagesStore?.getMessageById(source.messageId);
      if (message) {
        presenter.datetime.rawDate = new Date(message.serverTime);
      }
    },

    get datetime() {
      return datetime;
    },

    get isOpened() {
      return !Entities.InputPanel.isEmpty;
    },

    get showCommon() {
      return presenter.currentStep === 'common';
    },

    get showCalendar() {
      return presenter.currentStep === 'calendar';
    },

    get isScheduleGroup() {
      return Entities.GroupsStore.activeGroup?.isSchedule || false;
    },

    get group() {
      return Entities.GroupsStore.getGroupById(source.groupId);
    },

    get scheduleGroup() {
      return Entities.GroupsStore.getGroupById(presenter.group?.scheduleGroupId);
    },

    goToCommon() {
      presenter.currentStep = 'common';
    },

    goToCalendar() {
      presenter.currentStep = 'calendar';
    },

    goToScheduleGroup() {
      presenter.onClose();
      if (presenter.scheduleGroup) {
        Entities.InputPanel.payloads.clear();
        Tasks.group.setActiveGroup({ groupId: presenter.scheduleGroup.id });
      }
    },

    onClose() {
      if (presenter.showCalendar) {
        presenter.goToCommon();
      } else {
        Entities.InputPanel.payloads.clear();
        clearGetParameters();
      }
    },
  }));

  useEffect(() => {
    presenter.init();
  }, []);

  return presenter;
};
