import dateService from 'APP/packages/date';

export default (value, min, max) => {
  const time = dateService.parseTime(value);
  const minTime = dateService.parseTime(min);
  const maxTime = dateService.parseTime(max);

  if (!time || (!minTime && !maxTime)) {
    return value;
  }

  let isLess = false;
  let isMore = false;

  time.some((v, i) => {
    if (
      minTime &&
      time[i] < minTime[i] &&
      !Array(i)
        .fill(0)
        .some((v, j) => minTime[j] !== time[j])
    ) {
      isLess = true;
      return true;
    }

    if (
      maxTime &&
      time[i] > maxTime[i] &&
      !Array(i)
        .fill(0)
        .some((v, j) => maxTime[j] !== time[j])
    ) {
      isMore = true;
      return true;
    }

    return false;
  });

  if (isLess || isMore) {
    return isLess ? min : max;
  }

  return value;
};
