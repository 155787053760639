import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { RefObject, useEffect, useLayoutEffect } from 'react';

import Entities from 'STORE';
import { Group } from 'STORE/Groups/Group';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

import { calculateIsLocalFlags, groupMessages, IGroupedMessages } from '../Messages.utils';

interface IPinMessagesPresenterParams {
  groupId: string;
  scrollRef: RefObject<HTMLDivElement>;
}

interface IPinMessagesPresenter {
  group: Group | null;
  pinnedMessages: ChatMessage[];
  messagesGroups: [string, IGroupedMessages[]][];
}

export const usePinMessagesPresenter = ({
  groupId,
  scrollRef,
}: IPinMessagesPresenterParams): IPinMessagesPresenter => {
  const source = useAsObservableSource({ groupId });

  const presenter = useLocalStore<IPinMessagesPresenter>(() => ({
    get group(): Group | null {
      return Entities.GroupsStore.getGroupById(source.groupId) || null;
    },

    get pinnedMessages(): ChatMessage[] {
      return presenter.group?.pinnedMessages.messages || [];
    },

    get messagesGroups(): [string, IGroupedMessages[]][] {
      return groupMessages(presenter.pinnedMessages);
    },
  }));

  useEffect(() => {
    if (presenter.group?.messagesStore.multiSelect.hasSelectedMessages) {
      presenter.group?.messagesStore.multiSelect.clearSelectedMessages();
    }
  }, []);

  useLayoutEffect(() => {
    scrollRef.current?.scrollTo({ top: scrollRef.current?.scrollHeight });
  }, [scrollRef.current]);

  useLayoutEffect(() => {
    calculateIsLocalFlags(presenter.pinnedMessages, true);
  }, [presenter.pinnedMessages.length]);

  return presenter;
};
