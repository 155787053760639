import { storage } from 'APP/packages/storage';
import Entities from 'APP/store';

import { subscribeNotifications } from '../subscribeNotifications/subscribeNotifications';

export const initNotifications = async (): Promise<void> => {
  if (Entities.UsersStore?.Me?.id) {
    const isPushNotificationsEnabled = storage.isPushNotificationsEnabled.get(
      Entities.UsersStore.Me.id
    );

    Entities.pushNotifications.setEnabled(isPushNotificationsEnabled);

    if (isPushNotificationsEnabled !== false) {
      subscribeNotifications();
    }
  }
};
