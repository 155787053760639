import { action, observable, computed } from 'mobx';

class Bots {
  constructor(root, parent) {
    this.root = root;
    this.parent = parent;
  }

  @observable isOpenedCommandList = false;

  @computed
  get bot() {
    if (!this.parent.group || !this.parent.group.isBot) {
      return null;
    }

    return this.parent.group.groupOpponent;
  }

  @computed
  get commands() {
    if (!this.bot) {
      return [];
    }

    return this.bot.commands || [];
  }

  @computed
  get hasCommands() {
    return this.commands && this.commands.length > 0;
  }

  @action
  toggleOpenedCommandList = () => {
    this.isOpenedCommandList = !this.isOpenedCommandList;
  };

  @action
  hideCommandList = () => {
    this.isOpenedCommandList = false;
  };
}

export default Bots;
