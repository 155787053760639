import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, DragEvent } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

import { MainInfoBlock } from 'APP/main/MainInfoBlock/MainInfoBlock';
import { PageType } from 'APP/router/MessengerRoutes/constants';
import { RouterLink } from 'APP/router/constants';
import { ChatRouter } from 'MAIN/ChatRouter/ChatRouter';
import { InAppView } from 'MAIN/InAppView/InAppView';
import { useChatBackgroundClassName } from 'MAIN/components/ChatBackground/hooks/useChatBackgroundClassName';

import { ArticlesDraft } from './ArticlesDraft/ArticlesDraft';
import AudioSources from './AudioSources';
import { Catalog } from './Catalog/Catalog';
import { GlobalVideoPlayer } from './GlobalVideoPlayer/GlobalVideoPlayer';
import { Hub } from './Hub/Hub';
import IncomingCall from './IncomingCall';
import LiveStreaming from './LiveStreaming';
import styles from './MainView.styles.m.css';
import Modules from './Modules';
import { Newsfeed } from './Newsfeed/Newsfeed';
import { OngoingCall } from './OngoingCall/OngoingCall';
import Popups from './Popups';
import { SidebarPanel } from './SidebarPanel/SidebarPanel';
import { SpaceView } from './SpaceView/SpaceView';

interface IMainViewProps {
  pageType: PageType;
}

const prevent = (e: DragEvent<HTMLDivElement>): void => {
  e.stopPropagation();
  e.preventDefault();
};

export const MainView: FC<IMainViewProps> = observer(({ pageType }) => {
  const match = useRouteMatch<{ id?: string }>();
  const history = useHistory();
  const chatBackgroundClassName = useChatBackgroundClassName();

  return (
    <>
      <div
        className={classNames(styles.container, chatBackgroundClassName)}
        onDrop={prevent}
        onDragOver={prevent}
      >
        <SidebarPanel pageType={pageType} />

        <Switch>
          <Route
            exact
            path={RouterLink.main}
          >
            <MainInfoBlock />
          </Route>
          <Route path={RouterLink.catalog}>
            <Catalog />
          </Route>
          <Route path={RouterLink.newsfeed}>
            <Newsfeed />
          </Route>
          <Route path={RouterLink.hub} />
          <Route path={RouterLink.market} />
          <Route path={RouterLink.space}>
            <SpaceView spaceId={match.params.id} />
          </Route>
          <Route path={`${match.url}/article-drafts`}>
            <ArticlesDraft groupId={match.params.id} />
          </Route>
          <Route>
            <ChatRouter />
          </Route>
        </Switch>
        <Hub />
        <InAppView goBack={history.goBack} />
        <OngoingCall />
      </div>

      <Modules pageType={pageType} />

      <Popups />
      <IncomingCall />
      <AudioSources />
      <LiveStreaming />
      <GlobalVideoPlayer />
    </>
  );
});
