import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

import { GroupAvatar } from 'APP/components/Avatar/GroupAvatar/GroupAvatar';
import useUserOnlineStatusText from 'MAIN/hooks/userOnlineStatusText';
import { Group } from 'STORE/Groups/Group';

import { ISearchResultItemProps } from '../SearchResultItem.types';
import styles from './SearchResultP2P.style.m.css';

export const SearchResultP2P: FC<ISearchResultItemProps<Group>> = observer(
  ({ isLocalResult, entity: group, className, activeClassName }) => {
    const userOnlineStatusText = useUserOnlineStatusText({
      user: group.groupOpponent,
    });

    return (
      <NavLink
        className={className}
        activeClassName={activeClassName}
        to={`/${group.route}/${group.id}`}
      >
        <GroupAvatar group={group} />
        <div className={styles.content}>
          <div className={styles.top}>
            <div className={styles.header}>{group.avatarTitle}</div>
          </div>
          <div className={styles.bottom}>
            <div className={styles.description}>
              {isLocalResult ? userOnlineStatusText : group.groupOpponent?.avatarSubTitle}
            </div>
          </div>
        </div>
      </NavLink>
    );
  }
);
