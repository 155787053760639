import { ChatBackgroundPattern, DEFAULT_CHAT_BACKGROUND_PATTERN } from 'APP/constants/appearance';

import { localStorageInstance } from '../instances/localStorageInstance';

const KEY_NAME = 'chatBackground';

export const set = (chatBackground: ChatBackgroundPattern): void => {
  localStorageInstance.setItem(KEY_NAME, chatBackground);
};

export const get = (): ChatBackgroundPattern => {
  const value: ChatBackgroundPattern | string | null = localStorageInstance.getItem(KEY_NAME);

  if (
    value === 'true' ||
    !Object.values(ChatBackgroundPattern).includes(value as ChatBackgroundPattern)
  ) {
    return DEFAULT_CHAT_BACKGROUND_PATTERN;
  }

  if (value === 'false') {
    return ChatBackgroundPattern.WithoutPattern;
  }

  return value as ChatBackgroundPattern;
};

export const remove = (): void => {
  localStorageInstance.removeItem(KEY_NAME);
};
