import Entities from 'APP/store';

export default (groupId, roleChanges) => {
  const myRole = roleChanges[Entities.UsersStore.Me.id];
  const group = Entities.GroupsStore.getGroupById(groupId);

  if (!group || !myRole) {
    return;
  }

  group.setUserGroupRole(myRole.newRole);
  group.messagesStore.messages.forEach((message) => {
    if (message?.threadId) {
      const thread = Entities.GroupsStore.getGroupById(message.threadId);
      if (thread) {
        thread.setUserGroupRole(myRole.newRole);
      }
    }
  });
};
