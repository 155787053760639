import { groupModel } from 'APP/model/model';

export async function banParticipants(
  groupId: string,
  usersToBan: string[],
  deleteAllMessages = false
): Promise<{ error: string | null }> {
  try {
    await groupModel.banUsersForGroup({
      groupId,
      usersToBan,
      deleteAllMessages,
    });
    return { error: null };
  } catch (error) {
    return { error: error.message };
  }
}
