import classNames from 'classnames';
import React from 'react';

import { IconButton } from 'APP/packages/UiKit/IconButton';
import PopupInfoCard from 'APP/packages/UiKit/PopupInfoCard';
import popupItemStyles from 'APP/packages/UiKit/PopupItem/styles.m.scss';
import DeleteIcon from 'ICONS/ic-delete.svg';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';

import styles from './style.m.scss';

const User = ({ user, ignoreIsMe }) => {
  const containerProps = classNames(popupItemStyles.container);

  return (
    <div className={containerProps}>
      <PopupInfoCard
        type={AvatarTypes.Round_10}
        className={popupItemStyles.card}
        avatarTitle={user.avatarTitle}
        avatarUrl={user.avatarUrl}
        avatarBackgroundColorIndex={user.avatarBackgroundColorIndex}
        title={user.title}
        subtitle={user.subtitle}
      />
      {user.isMe && !ignoreIsMe && (
        <IconButton
          onClick={user.onDelete}
          iconClassName={styles.icon}
          Icon={DeleteIcon}
        />
      )}
    </div>
  );
};

export default User;
