import React from 'react';

import { IRenderer } from '../renderTree.types';

interface IEmailRendererOptions {
  email: string;
}

export const EmailRenderer: IRenderer<IEmailRendererOptions> = ({ children, options }) => {
  if (!children) {
    return null;
  }

  return <a href={`mailto: ${options.email}`}>{children}</a>;
};
