import React, { FC } from 'react';

import CloseSmallIcon from 'ICONS/close-s.svg';
import { Avatar } from 'UIKIT/Avatar/Avatar';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';

import styles from './ChipsItem.styles.m.scss';
import { IChipsItem } from './ChipsItem.types';

export * from './ChipsItem.types';

interface IChipsItemProps {
  item: IChipsItem;
  onRemove(item: IChipsItem): void;
}

export const ChipsItem: FC<IChipsItemProps> = ({ item, onRemove }) => {
  return (
    <div className={styles.container}>
      <Avatar
        type={AvatarTypes.Round_0}
        title={item.avatarTitle}
        url={item.avatarUrl}
        backgroundColorIndex={item.avatarColorIndex}
      />
      <div className={styles.title}>{item.title}</div>
      <button
        onClick={() => onRemove(item)}
        className={styles.removeContainer}
      >
        <CloseSmallIcon className={styles.removeIcon} />
      </button>
    </div>
  );
};
