import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { AuthProvider, IAccountProvider } from 'APP/model/auth/authModel.types';
import { useTranslation } from 'APP/packages/translations';

import { useProvidersPresenter } from '../hooks/useProviders';

interface IUserPrivacyViewPresenter {
  accountProviders: IAccountProvider[];
  isLoading: boolean;
  processing: Record<string, boolean>;
  placeholdersCount: number;

  keyExtractor(account: IAccountProvider): string;
  processingChange(active: boolean, provider: AuthProvider): void;
  canLeave(): Promise<boolean>;
  back(): Promise<void>;
  close(): Promise<void>;
}

interface IUserAccountsPopupViewPresenterParams {
  onBack: (() => void) | null;
  onClose(): void;
}

export const useUserAccountsPopupViewPresenter = ({
  onBack,
  onClose,
}: IUserAccountsPopupViewPresenterParams): IUserPrivacyViewPresenter => {
  const { t } = useTranslation();
  const accountsPresenter = useProvidersPresenter();

  const source = useAsObservableSource({ accountProviders: accountsPresenter.accountProviders });

  const presenter = useLocalStore<IUserPrivacyViewPresenter>(() => ({
    isLoading: true,
    processing: {},

    get accountProviders(): IAccountProvider[] {
      return !presenter.isLoading ? source.accountProviders : [];
    },

    get placeholdersCount(): number {
      return source.accountProviders.length;
    },

    keyExtractor(accountProvider): string {
      return accountProvider.authProviderId;
    },

    processingChange(active: boolean, provider: AuthProvider): void {
      presenter.processing[provider] = active;
    },

    async canLeave(): Promise<boolean> {
      if (presenter.processing[AuthProvider.Telegram]) {
        return await Tasks.app.showConfirm({
          text: t('telegram_account_not_link_alert'),
          primaryText: t('common_ok'),
          secondaryText: t('common_cancel'),
        });
      }
      return true;
    },

    async back(): Promise<void> {
      const canBack = await presenter.canLeave();
      canBack && onBack?.();
    },

    async close(): Promise<void> {
      const canClose = await presenter.canLeave();
      canClose && onClose();
    },
  }));

  useEffect(() => {
    Tasks.authorization.getMyAccounts().then(() => {
      presenter.isLoading = false;
    });
  }, []);

  return presenter;
};
