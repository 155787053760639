import { observer } from 'mobx-react';
import React, { FC, useCallback, useRef } from 'react';

import { List } from 'APP/components/List/List';
import {
  IInvitee,
  IScheduledEventDetails,
} from 'APP/model/scheduledEvent/scheduledEventModel.types';
import { TextPlaceholder } from 'APP/packages/placeholders/TextPlaceholder/TextPlaceholder';
import { useTranslation } from 'APP/packages/translations';

import { ScheduledCallsInviteeItem } from './ScheduledCallsInviteeItem/ScheduledCallsInviteeItem';
import { useScheduledCallsInviteesListPresenter } from './ScheduledCallsInviteesList.presenter';
import styles from './ScheduledCallsInviteesList.styles.m.css';
import { SchedulesCallInviteesPlaceholder } from './SchedulesCallInviteesPlaceholder/SchedulesCallInviteesPlaceholder';

interface IScheduledCallsInviteesListProps {
  isLoading: boolean;
  scheduledEventDetails: IScheduledEventDetails | null;
}

export const ScheduledCallsInviteesList: FC<IScheduledCallsInviteesListProps> = observer(
  (props) => {
    const listRef = useRef<HTMLDivElement | null>(null);
    const { t } = useTranslation();
    const presenter = useScheduledCallsInviteesListPresenter({
      scheduledEventDetails: props.scheduledEventDetails,
      isDetailsLoading: props.isLoading,
      listRef,
    });

    const renderItem = useCallback(
      (data: IInvitee) => (
        <ScheduledCallsInviteeItem
          initiatorId={props.scheduledEventDetails?.initiatorId}
          invitee={data}
        />
      ),
      [props.scheduledEventDetails?.initiatorId]
    );

    return (
      <div className={styles.container}>
        {!props.isLoading || props.scheduledEventDetails ? (
          <div className={styles.participants}>
            {t('call_events_participants_qty', { 0: props.scheduledEventDetails?.inviteesCount })}
          </div>
        ) : (
          <TextPlaceholder
            className={styles.placeholder}
            width={136}
          />
        )}
        <div
          ref={listRef}
          className={styles.list}
        >
          <List
            isScrollable
            isLoading={presenter.isListLoading}
            className={styles.results}
            hasMore={presenter.hasMore}
            data={presenter.inviteesList}
            keyExtractor={presenter.keyExtractor}
            onEndReached={presenter.getNextCursor}
            renderItem={renderItem}
            SpinnerComponent={
              <SchedulesCallInviteesPlaceholder count={presenter.getCountPlaceholders()} />
            }
          />
        </div>
      </div>
    );
  }
);
