import { callPermissionsModel } from 'APP/model/model';
import logger from 'APP/packages/logger';
import Entities from 'APP/store';

export const getCallPermissions = async (channelId: string): Promise<void> => {
  try {
    const response = await callPermissionsModel.getCallPermissions(channelId);

    if (Entities.Calls.ongoingCall) {
      Entities.Calls.ongoingCall.permissions.setPermissions(response);
    }
  } catch (error) {
    logger.get('API').error('calls.permissions.getCallPermissions', error);
  }
};
