import { observer } from 'mobx-react';
import React from 'react';

import { MessageLayout } from '../../MessageLayout/MessageLayout';
import { BubbleHeader } from '../components/BubbleHeader/BubbleHeader';
import StatusBar from '../components/StatusBar';
import { ThreadButton } from '../components/ThreadButton/ThreadButton';
import Translate from '../components/Translate';
import styles from './ArticleBubble.styles.m.css';

export const ArticleBubble = observer((props) => {
  const { message, children, messageViewPageType, isView } = props;
  const isShowForward = Boolean(message.forward && !message.isSavedMessagesLeft);
  const isExistHeader = Boolean(message.isSenderNameShow || message.quotedMessage || isShowForward);

  return (
    <MessageLayout
      message={message}
      className={styles.bubble}
      messageViewPageType={messageViewPageType}
      isView={isView}
    >
      {isExistHeader && (
        <BubbleHeader
          message={message}
          isShowForward={isShowForward}
        />
      )}

      <div className={styles.content}>
        {children}
        <StatusBar
          className={styles.statusBar}
          message={message}
        />
      </div>

      <ThreadButton
        message={message}
        className={styles.threadButton}
      />
      <Translate
        message={message}
        className={styles.translate}
      />
    </MessageLayout>
  );
});
