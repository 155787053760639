import patterns from 'APP/packages/patterns';

import getContentPositions from '../../utils/getContentPositions';

const emailRegexp = new RegExp(patterns.EMAIL_ADDRESS);

export default (text, index, symbolMatches) => {
  const { startPosition, content } = getContentPositions(text, index);

  const matches = content.match(emailRegexp);

  if (matches && matches[0]) {
    symbolMatches.email.push({
      start: startPosition + matches.index,
      end: startPosition + matches.index + matches[0].length,
      email: matches[0],
    });
  }
};
