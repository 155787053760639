import { observer } from 'mobx-react';
import React from 'react';

import { GroupAvatar } from 'APP/components/Avatar/GroupAvatar/GroupAvatar';
import { VerifiedBadge } from 'APP/components/VerifiedBadge/VerifiedBadge';
import { AddKeyPressHandler } from 'APP/packages/keyBoardHandler';
import BackIcon from 'ICONS/ic-back.svg';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';
import { IconButton } from 'UIKIT/IconButton';

import OfflineStatus from '../components/OfflineStatus';
import { useChatChannelInfoPresenter } from './ChannelChatInfo.presenter';
import styles from './ChannelChatInfo.styles.m.css';

export const ChannelChatInfo = observer(({ group }) => {
  const presenter = useChatChannelInfoPresenter({ group });

  return (
    <div className={styles.container}>
      {presenter.isShowBackButton && (
        <>
          <AddKeyPressHandler onEsc={presenter.onBack} />
          <IconButton
            className={styles.backButton}
            Icon={BackIcon}
            onClick={presenter.onBack}
            size="big"
            iconSize="medium"
            theme="8"
          />
        </>
      )}

      <button
        className={styles.groupInfo}
        onClick={presenter.toggleProfileSidebar}
      >
        <GroupAvatar
          group={group}
          type={AvatarTypes.Square_20}
          hasLockIcon={group.isClosed}
        />

        <div className={styles.content}>
          <div className={styles.titleWrapper}>
            <span className={styles.title}>{presenter.title}</span>
            {presenter.verified && <VerifiedBadge />}
          </div>
          <OfflineStatus>
            <span className={styles.membersCount}>{presenter.members}</span>
          </OfflineStatus>
        </div>
      </button>
    </div>
  );
});
