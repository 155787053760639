import { goToNewsfeed } from './goToNewsfeed';
import { hideNewsfeedPost } from './hideNewsfeedPost/hideNewsfeedPost';
import { loadNewsfeed } from './loadNewsfeed/loadNewsfeed';
import { preloadNewsfeed } from './preloadNewsfeed/preloadNewsfeed';
import { unhideNewsfeedPost } from './unhideNewsfeedPost/unhideNewsfeedPost';

export default {
  loadNewsfeed,
  preloadNewsfeed,
  goToNewsfeed,
  hideNewsfeedPost,
  unhideNewsfeedPost,
};
