import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { ClientRole } from 'APP/constants/calls';
import { AgoraProvider } from 'APP/packages/callProviders/AgoraProvider/AgoraProvider';
import { getDefaultDevices } from 'APP/packages/deviceInfo/getDefaultDevices';
import Entities from 'STORE';

export interface IPreviewPresenter {
  audioVideoStreamer: AgoraProvider | null;
  audioMuted: boolean;
  videoMuted: boolean;
  audioProcessing: boolean;
  videoProcessing: boolean;
  selfName: string | undefined;
  canInit: boolean;
  onCallStart(): Promise<void>;
  unmuteAudio(): void;
  muteAudio(): void;
  unmuteVideo(): void;
  muteVideo(): void;
  init(): void;
  dispose(): void;
}

export const usePreviewPresenter = (): IPreviewPresenter => {
  const presenter = useLocalStore<IPreviewPresenter>(() => ({
    audioVideoStreamer: null,

    audioMuted: true,
    videoMuted: true,

    audioProcessing: false,
    videoProcessing: false,

    get selfName(): string | undefined {
      return Entities.callInvitation?.selfName;
    },

    get canInit(): boolean {
      return !!Entities.callInvitation.invitationInfo;
    },

    async init(): Promise<void> {
      presenter.audioVideoStreamer = new AgoraProvider({
        isDualStream: false,
        withVirtualBackground: false,
      });

      const devices = await getDefaultDevices();

      await presenter.audioVideoStreamer.createClient({
        role: ClientRole.Host,
        devices,
      });
      await presenter.unmuteAudio();
      await presenter.unmuteVideo();
    },

    async unmuteAudio(): Promise<void> {
      const hasAudioPermission = await Tasks.permissions.hasMicrophonePermission();

      if (!hasAudioPermission || !presenter.audioMuted || presenter.audioProcessing) {
        presenter.audioProcessing = false;
        return;
      }
      presenter.audioProcessing = true;
      await presenter.audioVideoStreamer?.unmuteAudio();
      presenter.audioMuted = false;
      presenter.audioProcessing = false;
    },

    async muteAudio(): Promise<void> {
      if (presenter.audioMuted || presenter.audioProcessing) {
        return;
      }
      presenter.audioProcessing = true;
      await presenter.audioVideoStreamer?.muteAudio();
      presenter.audioMuted = true;
      presenter.audioProcessing = false;
    },

    async unmuteVideo(): Promise<void> {
      const hasCameraPermission = await Tasks.permissions.hasCameraPermission();
      if (!hasCameraPermission || !presenter.videoMuted || presenter.videoProcessing) {
        presenter.videoProcessing = false;
        return;
      }

      presenter.videoProcessing = true;
      await presenter.audioVideoStreamer?.unmuteVideo();
      presenter.videoMuted = false;
      presenter.videoProcessing = false;
    },

    async muteVideo(): Promise<void> {
      if (presenter.videoMuted || presenter.videoProcessing) {
        return;
      }
      presenter.videoProcessing = true;
      await presenter.audioVideoStreamer?.muteVideo();
      presenter.videoMuted = true;
      presenter.videoProcessing = false;
    },

    async onCallStart(): Promise<void> {
      if (!presenter.videoMuted) {
        await presenter.muteVideo();
      }
      if (!presenter.audioMuted) {
        await presenter.muteAudio();
      }
    },

    async dispose(): Promise<void> {
      if (presenter.audioVideoStreamer) {
        await presenter.audioVideoStreamer.dispose();
        presenter.audioVideoStreamer = null;
      }
    },
  }));

  useEffect(() => {
    if (presenter.canInit) {
      presenter.init();
    }

    return (): void => {
      presenter.dispose();
    };
  }, [presenter.canInit]);

  return presenter;
};
