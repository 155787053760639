import React, { FC } from 'react';

import ErrorImg from 'APP/images/gem-mascot-under-maintenance.svg';
import { useTranslation } from 'APP/packages/translations';
import BackIcon from 'ICONS/ic-back-32.svg';
import { FAB } from 'UIKIT//FAB/FAB';
import { InfoBlock, InfoBlockTypes } from 'UIKIT/InfoBlock/InfoBlock';

import { Loader } from '../InAppViewLoader/InAppViewLoader';
import styles from './InAppViewEmpty.styles.m.css';

interface InAppViewEmptyProps {
  isLoading: boolean;
  hasError: boolean;
  goBack(): void;
}

export const InAppViewEmpty: FC<InAppViewEmptyProps> = (props) => {
  const { isLoading, hasError, goBack } = props;
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <FAB
          size={'30'}
          Icon={BackIcon}
          theme={'3'}
          onClick={goBack}
        />
      </div>
      <div className={styles.content}>
        {isLoading && !hasError ? <Loader /> : null}
        {hasError ? (
          <InfoBlock
            title={t('ms_maintenance_heading')}
            descriptions={[t('ms_maintenance_description')]}
            image={ErrorImg}
            type={InfoBlockTypes.Page}
          />
        ) : null}
      </div>
    </div>
  );
};
