import { request } from '../request/request';

const ROOT_API = 'push';

export const registerForPushNotifications = (data) => {
  return request({
    rootApi: ROOT_API,
    url: '/registerForPushNotifications',
    method: 'POST',
    body: data,
  });
};

export const unregisterForPushNotifications = (data) => {
  return request({
    rootApi: ROOT_API,
    url: '/unregisterForPushNotifications',
    method: 'POST',
    body: data,
  });
};
