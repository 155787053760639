import React from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { useTranslation } from 'APP/packages/translations';
import useClearGetParameters from 'APP/router/hooks/useClearGetParameters';

import NewMessageView from './NewMessageView';

const NewMessagePopup = () => {
  const { t } = useTranslation();
  const clearGetParameters = useClearGetParameters();

  return (
    <Popup
      isOpened
      title={t('new_message_title')}
      onClose={clearGetParameters}
    >
      <NewMessageView />
    </Popup>
  );
};

export default NewMessagePopup;
