import { observer } from 'mobx-react';
import React from 'react';

import { MediaPreview } from 'APP/components/MediaPreview/MediaPreview';
import { TextRenderer } from 'APP/components/TextRenderer/TextRenderer';
import { MEDIA_PREVIEW_MAX_SIZE } from 'APP/constants/newsfeed';
import { useTranslation } from 'APP/packages/translations';
import { Button } from 'UIKIT/Button/Button';

import { PostReactions } from '../components/PostReactions/PostReactions';
import Translate from '../components/Translate';
import { usePostArticlePresenter } from './PostArticle.presenter';
import styles from './PostArticle.styles.m.css';

export const PostArticle = observer(({ message, isPreview, isView }) => {
  const { t } = useTranslation();
  const presenter = usePostArticlePresenter({ message });

  return (
    <>
      {presenter.mediaPayload && (
        <MediaPreview
          className={styles.preview}
          payload={presenter.mediaPayload}
          maxWidth={MEDIA_PREVIEW_MAX_SIZE.width}
          maxHeight={MEDIA_PREVIEW_MAX_SIZE.height}
          isPreview={isPreview}
          isView={isView}
          onClick={presenter.onClick}
          payloadId={message.id}
          groupId={message.groupId}
        />
      )}

      <div className={styles.content}>
        <button
          className={styles.clickable}
          onClick={presenter.onClick}
        >
          <TextRenderer
            className={styles.title}
            isPlainText
          >
            {presenter.title}
          </TextRenderer>
        </button>

        {presenter.textPayload && (
          <TextRenderer
            className={styles.text}
            keyWords={presenter.textPayload.keyWords}
            userMentions={presenter.textPayload.userMentions}
            groupId={message.groupId}
          >
            {presenter.textPayload.text}
          </TextRenderer>
        )}

        <Translate message={message} />
        <PostReactions
          message={message}
          isVisible={isView}
        />

        <Button
          className={styles.button}
          theme="2"
          rounding="1"
          onClick={presenter.onClick}
        >
          {t('channels:channel_article_read_article')}
        </Button>
      </div>
    </>
  );
});
