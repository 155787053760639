import { ModifierArguments, Rect } from '@popperjs/core/lib/types';
import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { useEffect, MouseEvent } from 'react';

import { useReactions } from 'MAIN/hooks/useReactions/useReactions';
import { IReactionItem } from 'MAIN/hooks/useReactions/useReactions.types';
import Entities from 'STORE';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

interface TOffsetData {
  popper: Rect;
  reference: Rect;
}

interface IReactionsPopoverPresenterParams {
  message: ChatMessage;
  onClose: () => void;
}

interface IReactionsPopoverPresenter {
  isFullView: boolean;
  reactions: IReactionItem[];
  onClick(event: MouseEvent<HTMLButtonElement>, clickHandler: () => void): void;
  onCalculateOffset(data: TOffsetData): [number, number];
  onScroll(mod: ModifierArguments<object>): (() => void) | void;
  onToggleView(event: MouseEvent<HTMLButtonElement>): void;
}

const DEFAULT_PADDING = 8;
const POPOVER_SIZE = 48;

export const useReactionsPopoverPresenter = (
  data: IReactionsPopoverPresenterParams
): IReactionsPopoverPresenter => {
  const { message, onClose } = data;

  const reactionsPresenter = useReactions(message);
  const source = useAsObservableSource({ onClose });

  const presenter = useLocalStore<IReactionsPopoverPresenter>(() => ({
    isFullView: false,

    get reactions(): IReactionItem[] {
      if (presenter.isFullView) {
        return reactionsPresenter.reactionsFullMenu;
      }

      return reactionsPresenter.reactionsShortMenu;
    },

    onClick(event: MouseEvent<HTMLButtonElement>, clickHandler: () => void): void {
      event.preventDefault();
      event.stopPropagation();

      source.onClose();
      clickHandler();
    },

    onCalculateOffset(data: TOffsetData): [number, number] {
      // Calculating the popover position
      // Popover with a full view should be in the center of a popover with a short view.
      const { popper, reference } = data;

      // If view of the popover is short
      if (!presenter.isFullView) {
        // If the short popover extends beyond the top of the screen
        if (reference.y - POPOVER_SIZE < DEFAULT_PADDING * 2) {
          const bottomPosition = DEFAULT_PADDING + POPOVER_SIZE;
          return [0, reference.y - bottomPosition];
        }

        return [0, DEFAULT_PADDING];
      }

      // If view of the popover is full
      const bottomPosition = reference.y - POPOVER_SIZE / 2 + popper.height / 2;

      // If the full popover extends beyond the top of the screen
      if (bottomPosition - popper.height < DEFAULT_PADDING * 2) {
        const diff = DEFAULT_PADDING - (bottomPosition - popper.height);
        return [0, reference.y - (bottomPosition + diff)];
      }

      // If the full popover extends beyond the bottom of the screen
      if (bottomPosition > window.innerHeight - DEFAULT_PADDING) {
        const diff = bottomPosition - (window.innerHeight - DEFAULT_PADDING);
        return [0, reference.y - (bottomPosition - diff)];
      }

      return [0, reference.y + DEFAULT_PADDING - bottomPosition];
    },

    onScroll(mod: ModifierArguments<object>): (() => void) | void {
      // The popover closing when the user scrolls the page
      const parents = mod?.state?.scrollParents?.reference;
      if (Array.isArray(parents) && parents.length > 0) {
        parents[0].addEventListener('scroll', source.onClose);

        return () => {
          parents[0].removeEventListener('scroll', source.onClose);
        };
      }
    },

    onToggleView(event: MouseEvent<HTMLButtonElement>): void {
      event.stopPropagation();
      event.preventDefault();

      // This hack need to stopped closing popover
      // The ClickOutside component listens for clicks on the document,
      // and the stopPropagation function cannot stop this listening.
      // This is a react bug https://github.com/facebook/react/issues/12518
      // When ClickOutside is triggered, the popover has already been re-rendered
      // and the button disappears. The ClickOutside component considers that the button
      // does not belong to the popover and closes the popover
      setTimeout(() => {
        presenter.isFullView = !presenter.isFullView;
      }, 0);
    },
  }));

  useEffect(() => {
    Entities.ChatStore.setAutoScrollDisabled(true);
    return () => Entities.ChatStore.setAutoScrollDisabled(false);
  }, []);

  return presenter;
};
