import { TTree } from './parseToTree/parseToTree.types';

export const renderTextFromTree = ({ tree }: { tree: TTree }): string => {
  return tree.reduce((acc, currentValue) => {
    if (Array.isArray(currentValue.content)) {
      return `${acc}${renderTextFromTree({ tree: currentValue.content })}`;
    }
    return `${acc}${currentValue.content}`;
  }, '');
};

export const excludedFromRenderTags = ['text', 'link', 'keyWord', 'email', '@', '[', ']'];

export const renderMarkdownFromTree = (trees: TTree): string => {
  return trees.reduce((acc, tree) => {
    const content = Array.isArray(tree.content)
      ? renderMarkdownFromTree(tree.content)
      : tree.content;

    if (!excludedFromRenderTags.includes(tree.tag)) {
      return acc + `${tree.tag}${content}${tree.tag}`;
    } else {
      if (tree.tag === '[' && tree.props?.link) {
        return acc + `[${content}](${tree.props.link})`;
      }
      return acc + content;
    }
  }, '');
};
