import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { IconButton } from 'APP/packages/UiKit/IconButton';
import MoreIcon from 'ICONS/dots.svg';
import { usePopover } from 'UIKIT/Popover/usePopover';

import { DraftsContextMenu } from '../../ContextMenu/DraftsContextMenu';
import styles from './styles.m.css';

const Menu = ({ draft }) => {
  const popover = usePopover();

  const containerClasses = classNames(styles.container, 'message-menu', {
    [styles.opened]: popover.isOpened,
  });

  return (
    <div className={containerClasses}>
      <IconButton
        onClick={popover.toggle}
        onContextMenu={popover.close}
        setRef={popover.containerRef}
        Icon={MoreIcon}
        className={styles.button}
        dataTest="draft-menu-button"
        size="small"
        iconSize="medium"
        theme="19"
      />
      <DraftsContextMenu
        draft={draft}
        popover={popover}
        placement="bottom-end"
      />
    </div>
  );
};

export default observer(Menu);
