import React from 'react';

import IcAddLink from 'ICONS/ic-add-link.svg';

import Border from '../components/Border';
import Button from '../components/Button';

const Menu = ({ presenter }) => {
  return (
    <>
      {presenter.options.map((option) => (
        <Button
          key={option.key}
          onClick={option.onClick}
          isActive={presenter.activeKeys[option.key]}
          Icon={option.icon}
        />
      ))}
      <Border />
      <Button
        onClick={presenter.toggleLinkIcon}
        isActive={presenter.activeKeys.link}
        Icon={IcAddLink}
      />
    </>
  );
};

export default Menu;
