import { IStickerPackExtend } from 'APP/model/stickers/stickersModel.types';

export const sortStickers = (aPack: IStickerPackExtend, bPack: IStickerPackExtend): number => {
  if (aPack.stickerPack.order < bPack.stickerPack.order) {
    return -1;
  }

  if (aPack.stickerPack.order > bPack.stickerPack.order) {
    return 1;
  }

  // in case if the order is the same we will sort by name

  if (aPack.stickerPack.displayName < bPack.stickerPack.displayName) {
    return -1;
  }

  if (aPack.stickerPack.displayName > bPack.stickerPack.displayName) {
    return 1;
  }

  return 0;
};
