import React from 'react';

import { useTranslation } from 'APP/packages/translations';

import styles from './SearchResultInfo.styles.m.css';

export const SearchResultInfo = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.text}>{t('main_search_label')}</div>
    </div>
  );
};
