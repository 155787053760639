import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { VerifiedBadge } from 'APP/components/VerifiedBadge/VerifiedBadge';
import { IconButton } from 'APP/packages/UiKit/IconButton';
import PopupInfoCard from 'APP/packages/UiKit/PopupInfoCard';
import popupItemStyles from 'APP/packages/UiKit/PopupItem/styles.m.scss';
import MoreIcon from 'ICONS/dots.svg';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';

import usePresenter from './presenter';
import styles from './styles.m.scss';

const PopupUserWithMenuItem = ({
  user,
  hint = '',
  onClick,
  toggleMenu,
  isOpenedMenu,
  isMenuLoading = false,
}) => {
  const { menuRef, presenter, userOnlineStatusText } = usePresenter({
    user,
    toggleMenu,
    onClick,
  });

  const containerProps = {
    className: classNames(popupItemStyles.container, {
      [popupItemStyles.interactive]: Boolean(onClick),
    }),
  };

  if (onClick) {
    containerProps.onClick = presenter.onClick;
    containerProps.tabIndex = 0;
    containerProps.role = 'button';
  }

  return (
    <div {...containerProps}>
      <PopupInfoCard
        type={AvatarTypes.Round_10}
        className={popupItemStyles.card}
        avatarTitle={user.avatarTitle || user.name}
        avatarUrl={user.avatarUrl || user.avatar || user.image}
        avatarBackgroundColorIndex={user.avatarColorIndex}
        title={user.displayName}
        badge={user.verified && <VerifiedBadge />}
        subtitle={userOnlineStatusText}
      />
      {Boolean(hint) && (
        <span className={classNames(popupItemStyles.hint, styles.hint)}>{hint}</span>
      )}
      {Boolean(toggleMenu) && (
        <IconButton
          setRef={menuRef}
          onClick={presenter.onMenuClick}
          className={classNames({
            [styles.opened]: isOpenedMenu,
          })}
          iconClassName={styles.icon}
          Icon={MoreIcon}
          isLoading={isMenuLoading}
        />
      )}
    </div>
  );
};

export default observer(PopupUserWithMenuItem);
