import Text from '../Text/index';

export class DeletedByMe extends Text {
  text = '';
  userMentions = null;
  keyWord = null;
  keyWords = [];
  linkPreview = undefined;
  linkPreviewCancelled = false;
}
