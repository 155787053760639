import { useAsObservableSource, useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import { useTranslation } from 'APP/packages/translations';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import CalendarIcon from 'ICONS/ic-calendar.svg';
import Entities from 'STORE';
import { IPopoverMenuItem } from 'UIKIT/PopoverMenu/PopoverMenu.types';

interface ISubmitButtonMenuPresenter {
  menuItems: IPopoverMenuItem[];
  onMessageSchedule(): void;
}

export const useSubmitButtonMenuPresenter = (): ISubmitButtonMenuPresenter => {
  const { t } = useTranslation();
  const navigateTo = useNavigateTo();
  const source = useAsObservableSource({ navigateTo });

  const presenter = useLocalStore<ISubmitButtonMenuPresenter>(() => ({
    get menuItems(): IPopoverMenuItem[] {
      return [
        {
          key: 'scheduleMessage',
          icon: CalendarIcon,
          title: t('common:schedule_message'),
          onClick: presenter.onMessageSchedule,
          isVisible: true,
        },
      ];
    },

    onMessageSchedule(): void {
      const canSchedule = Entities.InputPanel.send.checkGroupPermissions(false);
      if (!canSchedule.result) {
        canSchedule.error && Entities.toast.show(canSchedule.error);
        return;
      }

      Tasks.group.goToScheduleSettings({ navigateTo: source.navigateTo });
    },
  }));

  return presenter;
};
