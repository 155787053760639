import Tasks from 'APP/Tasks';
import Entities from 'APP/store';
import { MessageError } from 'STORE/Messages/Message/ChatMessage/ChatMessages.types';

import sendMessages from './sendMessages';

const sendMessageHandler = (groupId, msg, result) => {
  if (result) {
    Tasks.messaging.clearScheduledMessages({ groupId, messageIds: [msg.id] });
  } else {
    msg.setError(MessageError.Send);
  }
};

export default ({ groupId, messages }) => {
  const scheduledGroup = Entities.GroupsStore.getGroupById(groupId);
  if (!scheduledGroup) {
    return;
  }

  const group = Entities.GroupsStore.getGroupById(scheduledGroup.groupId);
  if (!group) {
    return;
  }

  sendMessages(group.id, messages, sendMessageHandler.bind(this, group.id));
};
