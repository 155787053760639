import classNames from 'classnames';
import React, { FC, useEffect, useRef, ChangeEvent } from 'react';

import styles from './TextFormattingMenuInput.styles.m.css';

interface ITextFormattingMenuInputProps {
  className?: string;
  value: string;
  placeholder?: string;
  onChange(event: ChangeEvent<HTMLInputElement>): void;
}

export const TextFormattingMenuInput: FC<ITextFormattingMenuInputProps> = (props) => {
  const { className, value, placeholder, onChange } = props;
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    ref.current?.focus();
  }, []);

  return (
    <input
      className={classNames(styles.input, className)}
      ref={ref}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
};
