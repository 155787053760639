import { useCallback, useEffect, useMemo, useRef } from 'react';

import Tasks from 'APP/Tasks';
import Entities from 'STORE';

interface IVoicePresenterParams {
  groupId: string;
  messageId: string;
  payloadId: string;
  clientUuid: string;
  duration: string;
  isLoading: boolean;
}

interface IVoicePresenter {
  isPlaying: boolean;
  isActiveTrack: boolean;
  formattedDuration: number;
  progress: number;
  progressPercentage: number;
  onTogglePlay(): void;
  onChangeProgress(progress: number): void;
}

export function useVoicePresenter({
  groupId,
  messageId,
  payloadId,
  clientUuid,
  duration,
  isLoading,
}: IVoicePresenterParams): IVoicePresenter {
  const player = Entities.miniPlayer;
  const isLoadingRef = useRef(isLoading);

  const isActiveTrack = useMemo((): boolean => {
    return player.payloadId === payloadId || player.payloadId === clientUuid;
  }, [player.payloadId, payloadId, clientUuid]);

  const onChangeProgress = useCallback(
    (progressPercentage): void => {
      if (isLoadingRef.current) {
        return;
      }

      if (isActiveTrack) {
        player.updateProgressPercentage(progressPercentage);
      } else {
        Tasks.miniPlayer.openMiniPlayer(groupId, messageId, clientUuid || payloadId);
      }
    },
    [groupId, messageId, payloadId, clientUuid, player.payloadId]
  );

  const onTogglePlay = useCallback((): void => {
    if (isActiveTrack) {
      player.setIsPlaying(!player.isPlaying);
    } else {
      Tasks.miniPlayer.openMiniPlayer(groupId, messageId, clientUuid || payloadId);
    }
  }, [groupId, messageId, payloadId, clientUuid, isActiveTrack]);

  const isPlaying = useMemo((): boolean => {
    return player.isPlaying && isActiveTrack;
  }, [player.isPlaying, player.payloadId, payloadId, clientUuid]);

  const formattedDuration = useMemo((): number => {
    return parseInt(duration, 10) / 1000;
  }, [duration]);

  const progressPercentage = useMemo((): number => {
    return isActiveTrack ? player.progressPercentage : 0;
  }, [player.progressPercentage, isActiveTrack]);

  useEffect(() => {
    isLoadingRef.current = isLoading;
  }, [isLoading]);

  return {
    isPlaying,
    isActiveTrack,
    progress: player.progress,
    progressPercentage,
    formattedDuration,
    onTogglePlay,
    onChangeProgress,
  };
}
