import { FC } from 'react';

export interface IBaseStory {
  size?: { width: number; height: number };
  stretch?: boolean; // Default: true
}

export interface IStoryComponent<T = object> extends IBaseStory {
  Component: FC<T>;
  duration: number;
  payload?: T;
}

export interface IStoryVideo extends IBaseStory {
  url: string;
  previewUrl?: string;
}

export interface IStoryImage extends IBaseStory {
  url: string;
  duration: number;
}

export type IStory = IStoryComponent | IStoryVideo | IStoryImage;

export function storyIsComponent(story: IBaseStory): story is IStoryComponent {
  return !!(story as IStoryComponent).Component;
}

export function storyIsImage(story: IBaseStory): story is IStoryImage {
  return !!(story as IStoryImage).url && (story as IStoryImage).duration !== undefined;
}

export function storyIsVideo(story: IBaseStory): story is IStoryVideo {
  return !storyIsComponent(story) && !storyIsImage(story);
}
