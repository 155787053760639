import Entities from 'APP/store';

export default async ({ groupId, messageId }) => {
  const group = Entities.GroupsStore.getGroupById(groupId);
  if (!group) {
    return;
  }

  const messageIds = group.messagesStore.inViewMessageIds.filter((id) => id !== messageId);

  group.messagesStore.setInViewMessageIds(messageIds);
};
