import Tasks from 'APP/Tasks';
import { BotButtonPayloadType } from 'APP/model/bot/botModel.constants';
import { PayloadType } from 'APP/model/message/messageModel.types';
import { IButtonPayloadModel } from 'STORE/Messages/Message/Payload/Buttons/Buttons';
import { CommandButtonPayload } from 'STORE/Messages/Message/Payload/Buttons/CommandButtonPayload';

interface IBaseCommandButtonPresenterProps {
  groupId: string;
  button: IButtonPayloadModel;
}

interface IBaseCommandButtonPresenter {
  onClick(): void;
}

export const useBaseCommandButtonPresenter = ({
  button,
  groupId,
}: IBaseCommandButtonPresenterProps): IBaseCommandButtonPresenter => {
  const onClick = (): void => {
    const payload = button.payload as CommandButtonPayload;
    Tasks.messaging.createNewMessages({
      messages: [
        {
          type: PayloadType.ButtonsSelected,
          data: {
            button: {
              title: button.title,
              id: payload.id,
              payload: {
                type: BotButtonPayloadType.Command,
                botId: payload.botId,
                id: payload.id,
              },
            },
          },
          groupId,
        },
      ],
    });
  };

  return { onClick };
};
