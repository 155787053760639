import { useAsObservableSource, useLocalStore } from 'mobx-react';

import { showConfirm } from 'APP/Tasks/app/showConfirm/showConfirm';
import { unpinAll, unpinMessages } from 'APP/Tasks/messaging/pinUnpinMessage';
import { useTranslation } from 'APP/packages/translations';
import { navigateTo } from 'APP/router/refs/navigateTo';
import Entities from 'STORE';
import { Group } from 'STORE/Groups/Group';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

interface IAllPinsFooterPresenter {
  group: Group | null;
  pinnedMessages: ChatMessage[];
  count: number;
  text: string;
  onBack(): void;
  unpin(): Promise<void>;
}

export const useAllPinsFooterPresenter = (groupId: string): IAllPinsFooterPresenter => {
  const { t } = useTranslation();

  const source = useAsObservableSource({ groupId });

  const presenter = useLocalStore<IAllPinsFooterPresenter>(() => ({
    get group(): Group | null {
      return Entities.GroupsStore.getGroupById(source.groupId);
    },

    get pinnedMessages(): ChatMessage[] {
      return presenter.group?.pinnedMessages.messages || [];
    },

    get count(): number {
      if (presenter.group?.messagesStore.multiSelect.hasSelectedMessages) {
        return presenter.group?.messagesStore.multiSelect.selectedMessages.length;
      }

      return presenter.pinnedMessages.length;
    },

    get text(): string {
      if (presenter.count > 1) {
        return t('multiple_pin_all', { 0: presenter.count });
      }

      return t('multiple_pin_single');
    },

    onBack(): void {
      navigateTo({
        to: presenter.group?.routePath,
      });
    },

    async unpin(): Promise<void> {
      const confirmResult = await showConfirm({
        text: t('common_unpin_all_messages_confirmation'),
        primaryText: t('common_yes'),
        secondaryText: t('common_no'),
      });

      if (confirmResult) {
        if (presenter.group?.messagesStore.multiSelect.hasSelectedMessages) {
          const selectedMessages = presenter.group?.messagesStore.multiSelect.selectedMessages;
          const isAllSelected = presenter.pinnedMessages.length === selectedMessages.length;

          unpinMessages(groupId, selectedMessages);

          presenter.group.messagesStore.multiSelect.clearSelectedMessages();
          if (isAllSelected) {
            presenter.onBack();
          }
        } else {
          unpinAll(groupId);
          Entities.toast.show(t('common_unpin_all_messages'));
          presenter.onBack();
        }
      }
    },
  }));

  return presenter;
};
