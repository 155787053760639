export enum DeepLinkType {
  Group = 'group',
  StickerPack = 'sticker.pack',
  Message = 'message',
  User = 'user',
  SpaceLocal = 'space.local',
  Call = 'call',
  CallMe = 'callMe',
  Card = 'card',
  Unknown = 'unknown',
}

export interface IDeepLinkTargetUrls {
  desktopUrl?: string;
  androidUrl?: string;
  iosUrl?: string;
}

export interface IGetDeepLinkRequest {
  url: string;
}

export interface IDeepLink {
  type: DeepLinkType;
  entityId: string;
  parentId?: string;
  url?: string;
  alias?: string;
  expired: boolean;
  title?: string;
  description?: string;
  imageUrl?: string;
  targetUrls?: IDeepLinkTargetUrls;
}
