import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { useTranslation } from 'APP/packages/translations';
import Entities from 'APP/store';
import MicOffIcon from 'ICONS/ic-mic-off.svg';

export default ({ streamer, popover, onClick }) => {
  const { t } = useTranslation();

  const presenter = useLocalStore(() => ({
    async mute() {
      popover.close();
      await Tasks.streaming.forceMute({
        channelId: Entities.ActiveStream.stream.channelId,
        userId: streamer.userId,
      });
    },

    onClick(e) {
      popover.close();
      if (onClick) {
        onClick(e);
      }
    },

    get contextMenuModifiers() {
      return [
        {
          name: 'offset',
          options: {
            offset: [presenter.offset.x, presenter.offset.y],
          },
        },
        {
          name: 'preventOverflow',
          options: {
            mainAxis: true,
            altAxis: true,
            padding: 10,
          },
        },
        {
          name: 'flip',
          enabled: false,
        },
      ];
    },

    get isOpenActions() {
      return presenter._isOpenActions && !streamer.isSharedScreen;
    },

    get actions() {
      const actions = [];
      if (
        !streamer.isAudioMuted &&
        !streamer.isInitiator &&
        Entities.ActiveStream.stream?.initiatorId === Entities.UsersStore.Me.id
      ) {
        actions.push({
          key: 'mute',
          title: t('calls_mute_microphone'),
          onClick: presenter.mute,
          icon: MicOffIcon,
        });
      }

      return actions;
    },
  }));

  useEffect(() => {
    if (presenter.actions.length === 0) {
      popover.close();
    }
  }, [presenter.actions]);

  return presenter;
};
