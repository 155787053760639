import classNames from 'classnames';
import React, { memo } from 'react';
import { InView } from 'react-intersection-observer';

import PopupInfoCard from 'APP/packages/UiKit/PopupInfoCard';
import popupItemStyles from 'APP/packages/UiKit/PopupItem/styles.m.scss';
import { useTranslation } from 'APP/packages/translations';
import { AvatarTypes } from 'UIKIT/Avatar/Avatar.types';
import { isMentionAll } from 'UTILS/isMentionAll';

import styles from './styles.m.css';

const Mention = ({
  index,
  mention,
  isFocused,
  onHoverMention,
  onSelectMention,
  onChangeViewState,
}) => {
  const { t } = useTranslation();

  const name = isMentionAll(mention.nickName)
    ? t('common_all_members')
    : mention.contactName || mention.userName;
  const classes = classNames(popupItemStyles.smallContainer, {
    [popupItemStyles.active]: isFocused,
  });

  return (
    <InView
      as="button"
      className={classes}
      threshold={1}
      onClick={() => onSelectMention(mention)}
      onMouseEnter={() => onHoverMention(index)}
      onChange={(inView) => onChangeViewState(index, inView)}
    >
      <div className={styles.container}>
        <PopupInfoCard
          className={styles.infoCard}
          type={AvatarTypes.Round_0}
          avatarTitle={name}
          avatarUrl={mention.avatarUrl}
          avatarBackgroundColorIndex={mention.id.toString().at(-1)}
          title={name}
          subtitle={`@${mention.nickName}`}
        />
      </div>
    </InView>
  );
};

export default memo(Mention);
