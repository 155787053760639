import { useCallback } from 'react';

import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import { Bot } from 'STORE/Users/User/Bot/Bot';

export interface IBotDetailedInfoPopupParams {
  bot: Bot;
  onOpenBot?(): void;
}

interface IBotDetailedInfoPopupPresenter {
  onOpenBot(): void;
}

export function useBotDetailedInfoPopupPresenter({
  popupInstance,
  params,
}: ICommonPopupsProps<IBotDetailedInfoPopupParams>): IBotDetailedInfoPopupPresenter {
  const onOpenBot = useCallback(() => {
    popupInstance.close();
    params!.onOpenBot?.();
  }, []);

  return { onOpenBot };
}
